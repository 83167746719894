// eslint-disable-next-line yooi/no-restricted-dependency
import type { PlatformConfigurationStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
// eslint-disable-next-line yooi/no-restricted-dependency
import { MasterDetailMode } from 'yooi-modules/modules/platformConfigurationModule';
// eslint-disable-next-line yooi/no-restricted-dependency
import { CurrentPlatformConfiguration, PlatformConfiguration_MasterDetailMode } from 'yooi-modules/modules/platformConfigurationModule/ids';
// eslint-disable-next-line yooi/no-restricted-dependency
import useStore from '../store/useStore';
import { SessionStorageKeys, useSessionStorageState } from './useSessionStorage';

const useFilterPanelSessionStorage = (): [{ open: boolean }, (value: { open: boolean }) => void, (value: Partial<{ open: boolean }>) => void, () => void] => {
  const store = useStore();

  const currentPlatformConfiguration = store.getObject<PlatformConfigurationStoreObject>(CurrentPlatformConfiguration);
  const masterDetailMode = currentPlatformConfiguration[PlatformConfiguration_MasterDetailMode] ?? MasterDetailMode.openedByDefault;

  return useSessionStorageState<{ open: boolean }>(SessionStorageKeys.filterPanel, { open: masterDetailMode === MasterDetailMode.openedByDefault });
};

export default useFilterPanelSessionStorage;
