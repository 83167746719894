import type { LineChartViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import { DimensionDisplayAxis, ViewType } from 'yooi-modules/modules/dashboardModule';
import { filterNullOrUndefined } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import i18n from '../../../../utils/i18n';
import type { CommonViewResolvedDefinition } from '../common/commonViewResolvedDefinition';
import type { DimensionsDisplayOptionsFeatureResolvedDefinition } from '../common/dimensions/dimensionDisplayOptionsFeatureDefinition';
import { getViewDefinitionDimensionDisplayOption, getViewDefinitionDimensionsDisplayOptions } from '../common/dimensions/viewWithDimensionDisplayOptionsFeatureUtils';
import type { SeriesFeatureResolvedDefinition } from '../common/series/seriesFeatureDefinition';
import { getSeriesError, getViewDefinitionSeries, getViewDimensionsAsParameterDefinitions } from '../common/series/viewWithSeriesFeatureUtils';
import type { StackedSeriesFeatureResolvedDefinition } from '../common/stackedSeries/stackedSeriesFeatureDefinition';
import type { TemporalFeatureResolvedDefinition } from '../common/temporal/temporalFeatureResolvedDefinition';
import { getTemporalError } from '../common/temporal/viewWithTemporalFeatureUtils';
import type { ValueRangeFeatureResolvedDefinition } from '../common/valueRange/valueRangeFeatureResolvedDefinition';
import { registerView } from '../viewDsl';
import type { ViewDefinitionHandler } from '../viewDsl';
import LineChartViewBlock from './LineChartViewBlock';
import LineChartViewDefinitionOptions from './LineChartViewDefinitionOptions';
import type { LineChartViewResolution } from './lineChartViewResolution';
import { resolveLineChartView } from './lineChartViewResolution';
import LineChartViewWidget from './LineChartViewWidget';

export interface LineChartViewResolvedDefinition extends CommonViewResolvedDefinition,
  DimensionsDisplayOptionsFeatureResolvedDefinition,
  ValueRangeFeatureResolvedDefinition,
  StackedSeriesFeatureResolvedDefinition,
  SeriesFeatureResolvedDefinition,
  TemporalFeatureResolvedDefinition {
  type: ViewType.LineChart,
}

export type LineChartViewDefinitionHandler = ViewDefinitionHandler<LineChartViewStoredDefinition, LineChartViewResolvedDefinition, LineChartViewResolution>;

export const lineChartViewHandler = registerView<LineChartViewStoredDefinition, LineChartViewResolvedDefinition, LineChartViewResolution>({
  type: ViewType.LineChart,
  icon: IconName.show_chart,
  getLabel: () => i18n`Line Chart`,
  optionType: undefined,
  withFilters: true,
  withExport: false,
  resolveView: (store, viewDefinition, { viewDimensions, parametersMapping, filterConfiguration }) => (
    resolveLineChartView(store, viewDimensions, viewDefinition, parametersMapping, filterConfiguration)
  ),
  renderBlock: (_, viewDefinition, { viewDimensions, viewFilters, layoutParametersMapping, widgetDisplay }) => (
    <LineChartViewBlock
      viewDimensions={viewDimensions}
      viewFilters={viewFilters}
      viewDefinition={viewDefinition}
      parametersMapping={layoutParametersMapping}
      widgetDisplay={widgetDisplay}
    />
  ),
  renderWidget: (_, viewDefinition, { viewDimensions, viewFilters, parametersMapping, width, height }) => (
    <LineChartViewWidget
      viewDimensions={viewDimensions}
      viewFilters={viewFilters}
      viewDefinition={viewDefinition}
      parametersMapping={parametersMapping}
      width={width}
      height={height}
    />
  ),
  renderDefinitionOptions: (_, viewDefinition, { viewDimensions, updateViewDefinition, readOnly, parameterDefinitions }) => (
    <LineChartViewDefinitionOptions
      viewDimensions={viewDimensions}
      viewDefinition={viewDefinition}
      updateViewDefinition={updateViewDefinition}
      readOnly={readOnly}
      parameterDefinitions={parameterDefinitions}
    />
  ),
  getDefinitionErrors: (store, viewDefinition, viewDimensions, parameters) => {
    const errors = [
      getTemporalError(viewDefinition),
      getSeriesError(store, viewDefinition, [...parameters, ...getViewDimensionsAsParameterDefinitions(store, viewDimensions)], viewDefinition.type),
    ].filter(filterNullOrUndefined);
    return errors.length === 0 ? undefined : errors;
  },
  resolveDefinition: (_, viewDefinition) => ({
    id: viewDefinition.id,
    type: viewDefinition.type,
    label: viewDefinition.label !== undefined && viewDefinition.label !== '' ? viewDefinition.label : i18n`Line Chart`,
    readOnly: viewDefinition.readOnly ?? false,
    getDimensionsDisplay: (viewDimensions) => getViewDefinitionDimensionsDisplayOptions(viewDefinition, viewDimensions),
    getDimensionDisplay: (viewDimension) => getViewDefinitionDimensionDisplayOption(viewDefinition, viewDimension),
    minValue: viewDefinition.minValue,
    rangeValues: viewDefinition.rangeValues ?? [],
    maxValue: viewDefinition.maxValue,
    seriesAxis: viewDefinition.seriesAxis ?? DimensionDisplayAxis.x,
    series: getViewDefinitionSeries(viewDefinition),
    dateRange: viewDefinition.dateRange,
  }),
  getInitialStoredDefinition: (id) => ({ id, type: ViewType.LineChart }),
});
