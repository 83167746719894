import base, { Opacity } from './base';
import { colorWithAlpha } from './colorUtils';
import fontDefinition from './fontDefinition';
import type { RawTheme } from './themeUtils';

export default {
  name: 'light',
  color: {
    // Alias tokens
    transparent: base.color.gray['0'],
    background: {
      neutral: {
        default: base.color.gray['100'],
        subtle: base.color.gray['200'],
        muted: base.color.gray['300'],
        dark: base.color.gray['900'],
      },
      primary: {
        default: base.color.brand['700'],
        hover: base.color.brand['800'],
        pressed: base.color.brand['900'],
      },
      primarylight: {
        default: base.color.brand['100'],
        hover: base.color.brand['200'],
        pressed: base.color.brand['300'],
      },
      danger: {
        light: base.color.red['100'],
        default: base.color.red['700'],
        hover: base.color.red['800'],
        pressed: base.color.red['900'],
      },
      info: {
        default: base.color.blue['700'],
        light: base.color.blue['100'],
      },
      success: {
        light: base.color.green['100'],
        default: base.color.green['700'],
        hover: base.color.green['800'],
        pressed: base.color.green['900'],
      },
      warning: {
        light: base.color.yellow['100'],
        default: base.color.yellow['700'],
        hover: base.color.yellow['800'],
        pressed: base.color.yellow['900'],
      },
    },
    text: {
      primary: base.color.gray['800'],
      secondary: base.color.gray['600'],
      disabled: base.color.gray['400'],
      white: base.color.gray['100'],
      brand: base.color.brand['700'],
      info: base.color.blue['700'],
      danger: base.color.red['700'],
      warning: base.color.yellow['700'],
      success: base.color.green['700'],
    },
    border: {
      default: base.color.gray['300'],
      hover: base.color.gray['400'],
      dark: base.color.gray['500'],
      white: base.color.gray['100'],
      primary: base.color.brand['700'],
      primarylight: base.color.brand['300'],
      focus: base.color.blue['300'],
      danger: base.color.red['300'],
      warning: base.color.yellow['300'],
      info: base.color.blue['300'],
      success: base.color.green['300'],
      button: colorWithAlpha(base.color.gray['900'], Opacity.ten),
    },
    icon: {
      secondary: base.color.gray['700'],
    },
    toolbar: {
      background: {
        default: base.color.gray['900'],
        hover: base.color.gray['800'],
        selected: base.color.gray['800'],
      },
      foreground: {
        default: base.color.gray['500'],
        hover: base.color.gray['500'],
        selected: base.color.gray['200'],
      },
    },

    // Component-specific tokens
    link: {
      default: base.color.blue['800'],
      pressed: base.color.blue['900'],
      disabled: base.color.blue['300'],
    },
  },
  font: fontDefinition,
} satisfies RawTheme;
