import classnames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import type { PathStep } from 'yooi-modules/modules/conceptModule';
import { getConceptUrl, getPathLastFieldInformation, isMappingStep, ParsedDimensionType, parseDimensionMapping } from 'yooi-modules/modules/conceptModule';
import { Field_Title } from 'yooi-modules/modules/conceptModule/ids';
import type { GraphChartSubStepDisplay } from 'yooi-modules/modules/dashboardModule/fields/graphChartField';
import { CardDisplayMode } from 'yooi-modules/modules/dashboardModule/fields/graphChartField';
import { DashboardParameter_Label } from 'yooi-modules/modules/dashboardModule/ids';
import { joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import useStore from '../../../../store/useStore';
import { Opacity } from '../../../../theme/base';
import { generateColorFromOpacity } from '../../../../theme/colorUtils';
import { spacingRem } from '../../../../theme/spacingDefinition';
import type { Theme } from '../../../../theme/themeUtils';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { remToPx } from '../../../../utils/sizeUtils';
import useNavigation from '../../../../utils/useNavigation';
import useTheme from '../../../../utils/useTheme';
import { getChipOptionWithUnknown } from '../../modelTypeUtils';
import { resolveBody, resolveColor, resolveBoolean, resolveHeader, resolveIcon } from '../../views/cards/cardsViewResolution';
import { buildInstanceCardBody, buildInstanceCardHeader } from '../../views/cards/cardsViewUtils';
import InstanceCard from '../../views/cards/InstanceCard';
import { getDefaultLabel } from '../../views/common/viewUtils';
import ArcherElement from './archer/ArcherElement';
import type { RelationType } from './archer/types';
import type { GroupCollapseHandler, IsHighlighted, Item, OnHighlight } from './GraphChart';
import GraphChartArrowLabel from './GraphChartArrowLabel';
import GraphChartChip from './GraphChartChip';
import { HighlightedType } from './graphChartUtils';

const useStyles = makeStyles({
  opacity: {
    opacity: 0.3,
  },
}, 'graphChartItemList');

interface GraphChartItemListProps {
  blockLabel: string,
  blockId: string,
  isHighlighted: IsHighlighted,
  items: Item[],
  onHighlight: OnHighlight,
  removeHighlight: () => void,
  hasHighlightedItem: boolean,
  display: GraphChartSubStepDisplay,
  groupCollapseHandler: GroupCollapseHandler,
  borderColor?: string,
  width: number,
}

export const getRelations = (
  sourceKey: string,
  items: Item[],
  hasHighlightedItem: boolean,
  isHighlighted: IsHighlighted,
  theme: Theme,
  isGroupCollapse: GroupCollapseHandler['isGroupCollapsed'],
  onHighlight?: OnHighlight
): Array<RelationType> => {
  const getStrokeColor = (faded: boolean, color: string | undefined) => {
    if (faded) {
      return generateColorFromOpacity(color ?? theme.color.border.default, theme?.color.background.neutral.default, Opacity.twenty);
    } else {
      return color ?? theme.color.border.default;
    }
  };
  const targetMap = new Map();
  items.forEach((item) => {
    item.previousItems.forEach((previousItem) => {
      const isTargetCollapsed = previousItem.group && isGroupCollapse(previousItem.blockId, previousItem.group?.arrowKey);
      const isSourceCollapsed = item.group && isGroupCollapse(item.blockId, item.group?.arrowKey);
      const targetKey = previousItem.group && isTargetCollapsed ? previousItem.group.arrowKey : previousItem.arrowKey;
      const faded = hasHighlightedItem && !(isHighlighted(targetKey) && isHighlighted(sourceKey));
      let label: string;
      let strokeColor: string | undefined;
      if (item.group && isSourceCollapsed) {
        label = item.group.resolveArrowLabel(isTargetCollapsed ? {} : previousItem.dimensionsMapping);
        strokeColor = item.group.resolveArrowColor(isTargetCollapsed ? {} : previousItem.dimensionsMapping);
      } else {
        label = item.resolveArrowLabel(previousItem.dimensionsMapping);
        strokeColor = item.resolveArrowColor(previousItem.dimensionsMapping);
      }
      const relation: RelationType = {
        targetId: targetKey,
        targetAnchor: 'right',
        sourceAnchor: 'left',
        label: (<GraphChartArrowLabel label={label} />),
        lineType: {
          marker: { arrowLength: 10, arrowThickness: 6 },
          strokeWidth: 1,
          strokeColor: getStrokeColor(faded, strokeColor),
          layer: faded ? 0 : 1,
          withArrowLabel: Boolean(isHighlighted(targetKey) && isHighlighted(sourceKey)),
          arrowClickHandler: onHighlight && !faded ? {
            onArrowClick: () => onHighlight({
              type: HighlightedType.arrow,
              target: { type: isTargetCollapsed ? HighlightedType.group : HighlightedType.item, key: targetKey },
              source: { type: isSourceCollapsed ? HighlightedType.group : HighlightedType.item, key: sourceKey },
            }),
            clicked: Boolean(isHighlighted(targetKey) && isHighlighted(sourceKey)),
            clickableStrokeWidth: 10,
            hoveredStrokeWidth: 2,
          } : undefined,
        },
      };
      targetMap.set(targetKey, relation);
    });
  });
  return [...targetMap.values()];
};

const GraphChartItemList: FunctionComponent<GraphChartItemListProps> = ({
  blockLabel,
  blockId,
  isHighlighted,
  hasHighlightedItem,
  onHighlight,
  removeHighlight,
  items,
  groupCollapseHandler,
  display,
  borderColor,
  width,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const navigation = useNavigation();

  return items.map((item) => {
    const key = `${blockId}_${item.instanceId}`;
    const option = getChipOptionWithUnknown(store, item.instanceId);
    const highlightedAs = isHighlighted(key);

    const getNavigationPayload = () => {
      // The openButton function check that this is a monoDim line
      const parsedMapping = parseDimensionMapping(item.dimensionsMapping);
      if (parsedMapping.type === ParsedDimensionType.MonoDimensional) {
        return navigation.createNavigationPayload(
          item.instanceId,
          {
            pathname: getConceptUrl(store, parsedMapping.objectId),
          }
        );
      } else {
        return undefined;
      }
    };
    let node: ReactNode;
    const isFaded = hasHighlightedItem && !highlightedAs;
    if (display.type === 'chip') {
      node = (
        <GraphChartChip
          isActive={highlightedAs?.type === HighlightedType.item}
          isHigh={Boolean(highlightedAs)}
          isFaded={isFaded}
          option={option}
          width={width - remToPx(spacingRem.xxs)}
          onClick={() => (highlightedAs?.type === HighlightedType.item ? removeHighlight() : onHighlight({ type: HighlightedType.item, key }))}
        />
      );
    } else {
      const getSeriesLabel = (label: string | undefined, seriesIndex: number, path: PathStep[]): string => {
        if (label) {
          return getDefaultLabel(label, seriesIndex, i18n`Series`);
        }
        if (path.length === 2) {
          const lastStep = path[1];
          if (isMappingStep(lastStep)) {
            if (lastStep.mapping.id === blockId) {
              return getDefaultLabel(blockLabel, seriesIndex, i18n`Series`);
            }
            const parameterValues = Object.values(item.parametersMapping);
            const parameterId = parameterValues.find(({ id }) => id === lastStep.mapping.id)?.id;
            if (parameterId) {
              return getDefaultLabel(store.getObjectOrNull(parameterId)?.[DashboardParameter_Label] as string | undefined, seriesIndex, i18n`Series`);
            }
          }
        }
        const lastFieldStepId = getPathLastFieldInformation(path)?.fieldId;
        const field = lastFieldStepId ? store.getObjectOrNull(lastFieldStepId) ?? undefined : undefined;
        return field && field[Field_Title] ? field[Field_Title] as string : getDefaultLabel(label, seriesIndex, i18n`Series`);
      };

      const body = resolveBody(
        store,
        display.body?.map((b) => (joinObjects(b, { displayOptions: b.displayOptions ?? {} }))),
        (label, index, path) => getSeriesLabel(label, index, path)
      );
      const instanceCardBody = buildInstanceCardBody(store, body, true, 32, 100);
      const header = resolveHeader(
        store,
        display.header?.map((h) => (joinObjects(h, { displayOptions: h.displayOptions ?? {} }))),
        (index, path) => getSeriesLabel(undefined, index, path)
      );
      const instanceCardHeader = buildInstanceCardHeader(store, header, true, 32, 100);
      const color = resolveColor(store, display.color);
      const icon = resolveIcon(store, display.icon);
      const boolean = resolveBoolean(store, display.boolean);

      node = (
        <div className={classnames({ [classes.opacity]: isFaded })}>
          <InstanceCard
            borderColor={highlightedAs ? borderColor : undefined}
            header={instanceCardHeader}
            body={instanceCardBody}
            color={color}
            icon={icon}
            boolean={boolean}
            readOnly
            parametersMapping={item.parametersMapping}
            collapsable
            initialIsCollapsed={display.defaultDisplay !== CardDisplayMode.Opened}
            actions={isFaded ? undefined : [
              {
                type: 'toggle',
                key: 'visibility',
                icon: highlightedAs?.type === HighlightedType.item ? IconName.location_disabled : IconName.my_location,
                onClick: highlightedAs?.type === HighlightedType.item ? () => removeHighlight() : () => onHighlight({ type: HighlightedType.item, key }),
                tooltip: highlightedAs?.type === HighlightedType.item ? i18n`Hide path` : i18n`Show path`,
                active: Boolean(highlightedAs?.type === HighlightedType.item),
              },
            ]}
            getNavigationPayload={isFaded ? undefined : getNavigationPayload}
          />
        </div>
      );
    }

    return (
      <ArcherElement
        key={key}
        ids={[key]}
        relations={getRelations(item.arrowKey, [item], hasHighlightedItem, isHighlighted, theme, groupCollapseHandler.isGroupCollapsed, onHighlight)}
      >
        {node}
      </ArcherElement>
    );
  });
};

export default GraphChartItemList;
