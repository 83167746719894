import classnames from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';
import React, { useCallback, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { v4 as uuid } from 'uuid';
import type { Filters, PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { FilterConditionOperators, isFilterNode } from 'yooi-modules/modules/conceptModule';
import { joinObjects } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../../components/atoms/Button';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import type { CompositeDropdownSection, CompositeFieldVariants, CompositeLine } from '../../../../components/molecules/CompositeField';
import CompositeField, { CompositeFieldCloseReasons } from '../../../../components/molecules/CompositeField';
import type { EditableButtonVariant } from '../../../../components/molecules/EditableWithDropdown';
import OverflowMenu from '../../../../components/molecules/OverflowMenu';
import Tabs from '../../../../components/molecules/Tabs';
import type { FrontObjectStore } from '../../../../store/useStore';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import useDerivedState from '../../../../utils/useDerivedState';
import { SizeContextProvider, SizeVariant } from '../../../../utils/useSizeContext';
import { getRenderersFromFilterPath } from '../../FrontFilterRenderers';
import type { DropPlacement, FrontFilters } from '../filterUtils';
import {
  addNewFilterNodeToFilters,
  addNewFilterToFilters,
  canDropFilter,
  countValidFilters,
  dropFilter,
  duplicateFilter,
  isConditionSimple,
  isFrontFilterNode,
  simplifyFilter,
} from '../filterUtils';
import DragAndDropManager from './DragAndDropManager';
import DragItem from './DragItem';
import DropItem from './DropItem';
import FilterCondition from './FilterCondition';
import FilterNode from './FilterNode';

interface Definition<T extends Filters> {
  filter: T | undefined,
  title?: string,
  subtitle?: string,
}

export interface FilterDefinition {
  updateFilters: (filters: Filters[]) => void,
  definition: Definition<Filters>[],
}

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    columnGap: spacingRem.s,
  },
  title: {
    marginBottom: spacingRem.xs,
    marginTop: spacingRem.xs,
    display: 'flex',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
  subtitleBase: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.color.text.primary,
    paddingLeft: spacingRem.s,
  },
  subtitleTabs: {
    paddingTop: spacingRem.m,
    paddingBottom: spacingRem.splus,
  },
  subtitle: {
    paddingBottom: spacingRem.s,
  },
  buttons: {
    display: 'flex',
    gap: spacingRem.s,
    marginTop: spacingRem.m,
  },
  buttonGroup: {
    display: 'flex',
    gap: spacingRem.xs,
  },
}), 'filterComposite');

const getEmptyNode = (): FrontFilters => ({
  id: uuid(),
  condition: FilterConditionOperators.OR,
});

const getDefaultFilterValue = (store: FrontObjectStore, parameterDefinitions: SingleParameterDefinition[], defaultFilterPath: PathStep[] | undefined): FrontFilters => {
  if (defaultFilterPath === undefined) {
    return {
      id: uuid(),
      isSimpleCondition: false,
    };
  } else {
    const filterConditionsRenderers = getRenderersFromFilterPath(store, defaultFilterPath, parameterDefinitions);
    const defaultOperator = filterConditionsRenderers ? Object.keys(filterConditionsRenderers)?.[0] : undefined;
    return {
      id: uuid(),
      isSimpleCondition: true,
      leftValue: defaultFilterPath,
      operator: defaultOperator,
    };
  }
};

const toFrontFilters = (filters: Filters, basePath: PathStep[] | undefined): FrontFilters => {
  if (isFilterNode(filters)) {
    return joinObjects(filters, { id: uuid(), children: filters.children?.map((child) => toFrontFilters(child, basePath)) });
  } else {
    return joinObjects(filters, { id: uuid(), isSimpleCondition: isConditionSimple(filters, basePath) });
  }
};

interface FilterCompositeProps {
  filtersDefinition: FilterDefinition,
  requiredConceptDefinitionId?: string,
  parameterDefinitions?: SingleParameterDefinition[],
  readOnly?: boolean,
  variant?: CompositeFieldVariants,
  buttonVariant?: EditableButtonVariant,
  onOpen?: () => void,
  onClose?: (reason: CompositeFieldCloseReasons) => void,
  suggestedPaths?: { label: string, path: PathStep[] }[],
  rootPath?: { label: string, path: PathStep[] },
  renderCompositeTitle?: (inDropdown: boolean) => ReactElement,
  getCustomCompositeLabel?: (filterCount: number) => string,
  showDropdown?: boolean,
  isEditing?: boolean,
}

const FilterComposite: FunctionComponent<FilterCompositeProps> = ({
  showDropdown,
  isEditing,
  filtersDefinition,
  requiredConceptDefinitionId,
  parameterDefinitions = [],
  readOnly = false,
  variant,
  buttonVariant,
  onOpen,
  onClose,
  suggestedPaths,
  rootPath,
  renderCompositeTitle,
  getCustomCompositeLabel,
}) => {
  const classes = useStyles();

  const store = useStore();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const filterParameters = parameterDefinitions
    // .filter(({ type }) => type !== 'parameterList')
    .filter(({ id }, index, arr) => arr.findIndex(({ id: currentId }) => currentId === id) === index);
  const basePath = rootPath ?? (suggestedPaths?.length === 1 ? suggestedPaths[0] : undefined);
  const defaultFilterValue = getDefaultFilterValue(store, filterParameters, basePath?.path);

  const filters = filtersDefinition.definition.map(({ filter }) => filter);
  const [currentFilters, setCurrentFilters, resetFilters] = useDerivedState<FrontFilters[]>(
    () => filters.map((filter) => (filter ? toFrontFilters(filter, basePath?.path) : defaultFilterValue), basePath?.path),
    [filters]
  );
  const canDrop = useCallback(canDropFilter, [canDropFilter]);
  const onDrop = useCallback((index: number, droppedFilterId: string, targetFilterId: string, placement: DropPlacement) => {
    setCurrentFilters((current) => current.map((filter, currentFilterIndex) => {
      if (index === currentFilterIndex) {
        return dropFilter(droppedFilterId, targetFilterId, filter, placement);
      }
      return filter;
    }));
  }, [setCurrentFilters]);

  const requiresTabs = filters.length > 1;

  const getFiltersLines = (currentFilter: FrontFilters, index: number, subtitle?: string): CompositeLine[] => [
    {
      id: 'subtitle',
      render: (
        <div
          className={classnames({
            [classes.subtitleBase]: true,
            [classes.subtitleTabs]: requiresTabs,
            [classes.subtitle]: !requiresTabs,
          })}
        >
          <Typo>{subtitle}</Typo>
          {
            filtersDefinition.definition.length > 1
              ? (
                <SizeContextProvider sizeVariant={SizeVariant.small}>
                  <IconOnlyButton
                    tooltip={i18n`Clear filters`}
                    variant={IconOnlyButtonVariants.danger}
                    iconName={IconName.delete}
                    onClick={() => {
                      setCurrentFilters((current) => (current.map((f, i) => (i === index ? getDefaultFilterValue(store, filterParameters, basePath?.path) : f))));
                    }}
                  />
                </SizeContextProvider>
              )
              : null
          }
        </div>
      ),
    },
    {
      id: 'filter',
      render: (
        <DragAndDropManager
          onDrop={(droppedFilterId, targetFilterId, placement) => onDrop(index, droppedFilterId, targetFilterId, placement)}
          canDrop={(droppedFilterId, targetFilterId) => canDrop(currentFilter, targetFilterId, droppedFilterId)}
        >
          {isFrontFilterNode(currentFilter) ? (
            <FilterNode
              filters={currentFilter}
              level={0}
              filterParameterDefinitions={filterParameters}
              onChange={(newFilter) => setCurrentFilters((current) => current.map((filter, currentFilterIndex) => (index === currentFilterIndex ? newFilter : filter)))}
              onDelete={() => setCurrentFilters((current) => current.map((filter, currentFilterIndex) => (index === currentFilterIndex ? getEmptyNode() : filter)))}
              onDuplicate={() => setCurrentFilters((current) => current.map((filter, currentFilterIndex) => (index === currentFilterIndex ? duplicateFilter(filter) : filter)))}
              defaultFilterValue={defaultFilterValue}
              suggestedPaths={suggestedPaths}
              rootPath={rootPath}
              requiredConceptDefinitionId={requiredConceptDefinitionId}
              readOnly={readOnly}
            />
          ) : (
            <DropItem filters={currentFilter}>
              {({ dropRef }) => (
                <DragItem item={currentFilter}>
                  {({ connectDragSourceRef }) => (
                    <FilterCondition
                      ref={dropRef}
                      connectDragSource={connectDragSourceRef}
                      filters={currentFilter}
                      filterParameterDefinitions={filterParameters}
                      onChange={(newFilter) => setCurrentFilters((current) => current.map((filter, currentFilterIndex) => (index === currentFilterIndex ? newFilter : filter)))}
                      onDelete={() => setCurrentFilters((current) => current.map((filter, currentFilterIndex) => (index === currentFilterIndex ? getEmptyNode() : filter)))}
                      onDuplicate={() => setCurrentFilters((current) => current.map((
                        filter,
                        currentFilterIndex
                      ) => (index === currentFilterIndex ? duplicateFilter(filter) : filter)))}
                      suggestedPaths={suggestedPaths}
                      rootPath={rootPath}
                      requiredConceptDefinitionId={requiredConceptDefinitionId}
                      readOnly={readOnly}
                    />
                  )}
                </DragItem>
              )}
            </DropItem>
          )}
        </DragAndDropManager>
      ),
    },
    {
      id: 'add-filter-block',
      render: !readOnly ? (
        <div className={classes.buttons}>
          <div className={classes.buttonGroup}>
            <Button
              iconName={IconName.add}
              title={i18n`Add condition`}
              variant={ButtonVariant.secondary}
              onClick={() => setCurrentFilters((current) => current.map((filter, currentFilterIndex) => {
                if (index === currentFilterIndex) {
                  return addNewFilterToFilters(filter, defaultFilterValue);
                }
                return filter;
              }))}
            />
            <OverflowMenu
              iconName={IconName.expand_more}
              menuItems={[
                {
                  key: `${currentFilter.id}_add`,
                  name: i18n`Add advanced condition`,
                  icon: IconName.add,
                  onClick: () => {
                    setCurrentFilters((current) => current.map((filter, currentFilterIndex) => {
                      if (index === currentFilterIndex) {
                        return addNewFilterToFilters(filter, {
                          id: uuid(),
                          isSimpleCondition: false,
                        });
                      }
                      return filter;
                    }));
                  },
                },
              ]}
            />
          </div>
          <Button
            iconName={IconName.library_add}
            variant={ButtonVariant.tertiary}
            title={i18n`Add condition group`}
            onClick={() => setCurrentFilters((current) => current.map((filter, currentFilterIndex) => {
              if (index === currentFilterIndex) {
                return addNewFilterNodeToFilters(filter, defaultFilterValue);
              }
              return filter;
            }))}
          />
        </div>
      ) : null,
    },
  ];

  const getTab = (title: string, currentFilter: FrontFilters, index: number, subtitle?: string) => ({
    key: `${currentFilter.id}_key`,
    name: title,
    render: () => getFiltersLines(currentFilter, index, subtitle)
      .map((compositeLine) => <React.Fragment key={compositeLine.id}>{compositeLine.render}</React.Fragment>),
  });

  const getDropdownSection = (): CompositeDropdownSection[] => {
    const tabs = currentFilters.map((currentFilter, index) => {
      const { title } = filtersDefinition.definition[index];
      const tabSubtitle = filtersDefinition.definition[index].subtitle;
      const tabTitle = title ?? i18n`Filter...`;
      return getTab(tabTitle, currentFilter, index, tabSubtitle);
    });

    let { subtitle } = filtersDefinition.definition[0];
    if (!subtitle && !requiresTabs) {
      if (isFilterNode(currentFilters[0]) && (!currentFilters[0].children || currentFilters[0].children.length === 0)) {
        subtitle = i18n`No filter condition applied yet`;
      } else if (requiredConceptDefinitionId) {
        subtitle = i18n`Show instances only if...`;
      } else {
        subtitle = i18n`Filter...`;
      }
    }
    const lines = requiresTabs ? [
      {
        id: 'tabs',
        render: (
          <div>
            <Tabs
              tabs={tabs}
              selectedTabIndex={selectedTabIndex !== -1 ? selectedTabIndex : 0}
              onSelectedIndexChanged={(index) => {
                setSelectedTabIndex(index);
              }}
            />
            {tabs[selectedTabIndex].render()}
          </div>
        ),
      },
    ] : getFiltersLines(currentFilters[0], 0, subtitle);

    return [{ id: 'dropDownId', lines }];
  };

  return (
    <DndProvider backend={TouchBackend} options={{ enableTouchEvents: false, enableMouseEvents: true, enableKeyboardEvents: true }}>
      <CompositeField
        variant={variant}
        buttonVariant={buttonVariant}
        dropdownMinWidth="60rem"
        showDropdown={showDropdown}
        isEditing={isEditing}
        onOpenDropdown={onOpen}
        onCloseDropdown={(reason) => {
          if (reason === CompositeFieldCloseReasons.validate) {
            filtersDefinition.updateFilters(currentFilters.map((filter) => simplifyFilter(store, filter) ?? defaultFilterValue));
          }
          resetFilters();
          onClose?.(reason);
        }}
        headerLinesRenderers={[
          {
            id: 'title',
            render: (inDropdown) => {
              const filterCount = currentFilters.reduce((acc, filter) => acc + countValidFilters(store, filter), 0);

              const label = getCustomCompositeLabel ? getCustomCompositeLabel(filterCount) : i18n`Filters (${filterCount})`;
              return (
                <div className={classes.titleContainer}>
                  {
                    renderCompositeTitle
                      ? (renderCompositeTitle(inDropdown))
                      : (
                        <span className={classes.title}>
                          <Icon colorVariant={IconColorVariant.alternative} name={IconName.filter_alt} />
                          <Tooltip title={label}>
                            <Typo maxLine={1}>{label}</Typo>
                          </Tooltip>
                        </span>
                      )
                  }
                  {
                    inDropdown && !readOnly
                      ? (
                        <SizeContextProvider sizeVariant={SizeVariant.small}>
                          <IconOnlyButton
                            tooltip={i18n`Clear all filters`}
                            variant={IconOnlyButtonVariants.danger}
                            iconName={IconName.delete}
                            onClick={() => {
                              setCurrentFilters((current) => (current.map(() => getDefaultFilterValue(store, filterParameters, basePath?.path))));
                            }}
                          />
                        </SizeContextProvider>
                      )
                      : null
                  }
                </div>
              );
            },
          },
        ]}
        getDropdownSectionDefinitions={getDropdownSection}
      />
    </DndProvider>
  );
};

export default FilterComposite;
