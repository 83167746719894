import type { WorkflowEntryStoreObject, WorkflowFieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { getFieldDimensionOfModelType, workflowFieldHandler } from 'yooi-modules/modules/conceptModule';
import {
  Workflow_TargetedConceptDefinition,
  Workflow_Transitions, WorkflowEntry, WorkflowEntry_Rank, WorkflowEntry_Role_Concept, WorkflowEntry_Role_Workflow,
  WorkflowField_Workflow,
  WorkflowFieldFields,
  WorkflowFieldFields_Role_Field,
  WorkflowFieldFields_Role_WorkflowField,
  WorkflowTransition_From,
  WorkflowTransition_To,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { compareRank, extractAndCompareValue, ranker } from 'yooi-utils';

export const listFieldIdsOfWorkflowField = (store: ObjectStoreWithTimeseries, workflowFieldId: string): string[] => (
  store.withAssociation(WorkflowFieldFields)
    .withRole(WorkflowFieldFields_Role_WorkflowField, workflowFieldId)
    .list()
    .map((assoc) => assoc.role(WorkflowFieldFields_Role_Field))
);

export const hasTransitionAvailable = (store: ObjectStoreWithTimeseries, workflowFieldId: string, conceptId: string, subFieldId?: string): boolean => {
  const workflowField = store.getObjectOrNull(workflowFieldId);
  const workflow = workflowField?.navigateOrNull(WorkflowField_Workflow);
  const transitions = workflow?.navigateBack(Workflow_Transitions) ?? [];
  const concept = store.getObject(conceptId);
  const fieldHandler = workflowFieldHandler(store, workflowFieldId);
  const workflowTargetedConceptDefinition = workflow ? workflow.navigateOrNull(Workflow_TargetedConceptDefinition) : undefined;
  const dimensionId = getFieldDimensionOfModelType(store, workflowFieldId, concept[Instance_Of] as string);
  if (!dimensionId || !workflowTargetedConceptDefinition) {
    return false;
  }
  const { value: { value: workflowValue, fieldValues } } = fieldHandler.getValueResolution({ [dimensionId]: conceptId });
  const value = subFieldId ? fieldValues[subFieldId] : workflowValue;
  return transitions
    .filter((transition) => transition[WorkflowTransition_From] === value?.id)
    .filter((transition) => transition.navigateOrNull(WorkflowTransition_To))
    .filter((transition) => isInstanceOf(transition.navigate(WorkflowTransition_To), workflowTargetedConceptDefinition.id))
    .length > 0;
};

export const getWorkflowFieldFirstEntryId = (store: ObjectStoreWithTimeseries, workflowFieldId: string): string | undefined => {
  const mainWorkflowId = store.getObjectOrNull<WorkflowFieldStoreObject>(workflowFieldId)?.[WorkflowField_Workflow];
  return mainWorkflowId
    ? ranker.decorateList(
      store.withAssociation(WorkflowEntry)
        .withRole(WorkflowEntry_Role_Workflow, mainWorkflowId)
        .list<WorkflowEntryStoreObject>()
        .sort(extractAndCompareValue((item) => item.object[WorkflowEntry_Rank] as string, compareRank)),
      (item) => item.object[WorkflowEntry_Rank] as string
    ).at(0)?.item.role(WorkflowEntry_Role_Concept)
    : undefined;
};
