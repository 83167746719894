import { equals } from 'ramda';
import type { CSSProperties, ForwardedRef, ReactElement } from 'react';
import { useImperativeHandle, useLayoutEffect, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import type { ListChildComponentProps, VariableSizeList } from 'react-window';
import { VariableSizeList as List } from 'react-window';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';

const useStyles = makeStyles({
  list: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
  },
}, 'itemList');

const ItemListRenderer = ({ data, index, style }: ListChildComponentProps<((style: CSSProperties) => ReactElement)[]>): ReactElement => data[index](style);

export interface ListItem {
  key: string,
  getSize: () => number,
  renderRow: (style: CSSProperties, onNode: (node: ItemHandler | null) => void, onHover: () => void, initialIsActive: boolean) => ReactElement,
  withKeyNavigation?: boolean,
  onSelect?: () => void,
  scrollOnMount?: boolean,
}

export interface ListNavigationHandler {
  onEnter: () => void,
  onUp: () => void,
  onDown: () => void,
  resetNavigation: () => void,
}

export interface ItemHandler {
  triggerActive: (isActive: boolean) => void,
  scrollIntoView: () => void,
}

interface ItemListProps<Item extends ListItem> {
  estimatedItemHeight: number,
  items: Item[],
  lazyMinWidth?: number,
  navigationRef: ForwardedRef<ListNavigationHandler>,
}

const ItemList = <Item extends ListItem>({ estimatedItemHeight, items, lazyMinWidth, navigationRef }: ItemListProps<Item>): ReactElement => {
  const classes = useStyles();

  const itemHandlerRefs = useRef<Map<number, ItemHandler>>(new Map());
  const selectedIndexRef = useRef<number | undefined>(undefined);
  const itemsKeysRef = useRef<string[]>([]);
  const newItemsKeys = items.map(({ key }) => key);
  if (!equals(itemsKeysRef.current, newItemsKeys)) {
    itemsKeysRef.current = newItemsKeys;
    selectedIndexRef.current = undefined;
  }

  const listContainerRef = useRef<HTMLDivElement>(null);
  const lazyListContainerRef = useRef<VariableSizeList>(null);
  const initialScrollOnMount = useRef(true);

  const onHoverItem = (index?: number) => {
    selectedIndexRef.current = index;
    itemHandlerRefs.current.forEach(({ triggerActive }, activeIndex) => triggerActive?.(index === activeIndex));
  };

  const hoverAndScrollToItem = (index?: number) => {
    onHoverItem(index);
    const lazyListContainerElement = lazyListContainerRef.current;
    if (lazyListContainerElement) {
      lazyListContainerElement.scrollToItem(index ?? -1, 'smart');
    } else {
      const listContainerElement = listContainerRef.current;
      if (listContainerElement) {
        const { scrollHeight, offsetHeight } = listContainerElement;
        // This does not work with variable size items as it consider all items to have the same height
        const scrollTop = (((index ?? 0) + 0.5) * (scrollHeight / items.length)) - (offsetHeight / 2);
        listContainerElement.scrollTop = Math.max(0, Math.min(scrollTop, scrollHeight));
      }
    }
  };

  const findNextIndex = <I extends unknown>(array: I[], search: (item: I) => boolean, startIndex = -1) => {
    for (let i = startIndex + 1; i < array.length; i += 1) {
      if (search(array[i])) {
        return i;
      }
    }
    return undefined;
  };
  const firstNavigableItemIndex = items.findIndex((item) => item.withKeyNavigation);
  const findPreviousIndex = <I extends unknown>(array: I[], search: (item: I) => boolean, startIndex?: number) => {
    for (let i = (startIndex ?? array.length) - 1; i >= 0; i -= 1) {
      if (search(array[i])) {
        return i;
      }
    }
    return undefined;
  };

  // Handle key list navigation from outside.
  useImperativeHandle(navigationRef, () => ({
    onEnter: () => {
      const selectedIndex = selectedIndexRef.current;
      const item = selectedIndex !== undefined ? items[selectedIndex] : undefined;
      if (item) {
        item.onSelect?.();
      }
    },
    onUp: () => {
      const selectedIndex = selectedIndexRef.current ?? firstNavigableItemIndex;
      const previousIndex = findPreviousIndex(items, (item) => Boolean(item.withKeyNavigation), selectedIndex);
      hoverAndScrollToItem(previousIndex ?? firstNavigableItemIndex);
    },
    onDown: () => {
      const nextItemIndex = findNextIndex(items, (item) => Boolean(item.withKeyNavigation), selectedIndexRef.current);
      if (nextItemIndex !== undefined) {
        hoverAndScrollToItem(nextItemIndex);
      }
    },
    resetNavigation: () => {
      hoverAndScrollToItem();
    },
  }));

  useLayoutEffect(() => {
    const list = lazyListContainerRef.current;
    if (list) {
      list.resetAfterIndex(0);
    }
    if (initialScrollOnMount.current) { // first render, init with initial selected item
      const index = items.findIndex(({ scrollOnMount }) => Boolean(scrollOnMount));
      initialScrollOnMount.current = false;
      if (index !== -1) {
        hoverAndScrollToItem(index);
      }
    } else if (selectedIndexRef.current === undefined) { // all render, if nothing selected, go to the first selectable item in the list
      hoverAndScrollToItem(firstNavigableItemIndex);
    }
  });

  const onHover = (index: number) => () => onHoverItem(index);

  const onNode = (index: number) => (node: ItemHandler | null) => {
    if (node) {
      itemHandlerRefs.current.set(index, node);
    } else {
      itemHandlerRefs.current.delete(index);
    }
  };

  const maxHeightInPx = remToPx(estimatedItemHeight) * 5.5;
  let fullHeightInPx = 0;
  items.some((item) => {
    fullHeightInPx += item.getSize();
    return fullHeightInPx > maxHeightInPx;
  });
  const finalHeightInPx = Math.min(maxHeightInPx, fullHeightInPx);

  if (items.length > 20) {
    return (
      <div style={{ height: `${finalHeightInPx}px`, minWidth: lazyMinWidth ? `${lazyMinWidth}rem` : undefined }}>
        <AutoSizer>
          {({ height = 0, width = 0 }) => (
            <List<((style: CSSProperties) => ReactElement)[]>
              ref={lazyListContainerRef}
              height={height}
              width={width}
              itemCount={items.length}
              itemData={items.map(({ renderRow }, index) => ((style) => renderRow(style, onNode(index), onHover(index), index === selectedIndexRef.current)))}
              itemSize={(index) => items[index].getSize()}
              estimatedItemSize={remToPx(estimatedItemHeight)}
            >
              {ItemListRenderer}
            </List>
          )}
        </AutoSizer>
      </div>
    );
  } else {
    return (
      <div
        ref={listContainerRef}
        style={{ height: `${finalHeightInPx}px` }}
        className={classes.list}
      >
        {items.map((item, index) => item.renderRow({}, onNode(index), onHover(index), index === selectedIndexRef.current))}
      </div>
    );
  }
};

export default ItemList;
