import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import Button, { ButtonVariant } from '../../../../components/atoms/Button';
import { IconName } from '../../../../components/atoms/Icon';
import Typo, { TypoAlign, TypoVariant } from '../../../../components/atoms/Typo';
import base from '../../../../theme/base';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { SizeVariant } from '../../../../utils/useSizeContext';
import useTheme from '../../../../utils/useTheme';

const useStyles = makeStyles({
  emptyBlockContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacingRem.s,
    height: '4rem',
    borderRadius: base.borderRadius.medium,
    borderStyle: 'dashed',
    borderWidth: '0.1rem',
    padding: `${spacingRem.s}`,
  },
  emptyBlockEmptyContent: {
    justifyContent: 'center',
  },
  emptyBlockEmptyContentWithFilter: {
    justifyContent: 'space-between',
  },
}, 'graphChartEmptyBlock');

interface GraphChartEmptyBlockProps {
  blockLabel: string,
  borderColor: string | undefined,
  onReset: (() => void) | undefined,
}

const GraphChartEmptyBlock: FunctionComponent<GraphChartEmptyBlockProps> = ({ blockLabel, borderColor, onReset }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div style={{ borderColor }} className={classnames(classes.emptyBlockContainer, onReset ? classes.emptyBlockEmptyContentWithFilter : classes.emptyBlockEmptyContent)}>
      <Typo variant={TypoVariant.blockInlineTitle} color={theme.color.text.disabled} align={TypoAlign.center}>{onReset ? i18n`No ${blockLabel} found` : i18n`No ${blockLabel}`}</Typo>
      {onReset ? (
        <Button
          variant={ButtonVariant.secondary}
          title={i18n`Clear all filters`}
          onClick={onReset}
          iconName={IconName.clear_all}
          sizeVariant={SizeVariant.small}
        />
      ) : null}
    </div>
  );
};

export default GraphChartEmptyBlock;
