import classnames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import { useMemo } from 'react';
import { filterNullOrUndefined } from 'yooi-utils';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import useBlockContext, { BlockContextProvider } from '../../utils/useBlockContext';
import { useChipBlockContext } from '../../utils/useChipBlockContext';
import useHighlight from '../../utils/useHighlight';

export enum HorizontalBlockVariant {
  main = 'main',
  compact = 'compact',
  compactWithoutFirstColumn = 'compactWithoutFirstColumn',
  compactInCard = 'compactInCard',
  compactInCardWithoutFirstColumn = 'compactInCardWithoutFirstColumn',
  compactWithFilters = 'compactWithFilters',
}

const useStyles = makeStyles({
  block: {
    display: 'grid',
    minHeight: '3.2rem',
  },
  compactLine: {
    gridTemplateColumns: `${spacingRem.blockLeftColumnSpacing} 18rem ${spacingRem.blockLeftColumnSpacing} minmax(20rem, 68.6rem) 1fr ${spacingRem.blockRightColumnSpacing}`,
    alignItems: 'start',
  },
  compactLineWithFilters: {
    gridTemplateColumns: `${spacingRem.blockLeftColumnSpacing} 18rem ${spacingRem.blockLeftColumnSpacing} minmax(20rem, 50rem) 1fr ${spacingRem.blockRightColumnSpacing}`,
    alignItems: 'start',
  },
  compactWithoutFirstColumn: {
    gridTemplateColumns: `0 20.6rem ${spacingRem.blockLeftColumnSpacing} minmax(20rem, 68.6rem) 1fr ${spacingRem.blockRightColumnSpacing}`,
    alignItems: 'start',
  },
  compactInCard: {
    gridTemplateColumns: `${spacingRem.blockLeftColumnSpacing} 11.9rem ${spacingRem.blockLeftColumnSpacing} minmax(20rem, 68.6rem) 1fr ${spacingRem.blockRightColumnSpacing}`,
    alignItems: 'start',
  },
  compactInCardWithoutFirstColumn: {
    gridTemplateColumns: `0 15.4rem ${spacingRem.blockLeftColumnSpacing} minmax(20rem, 68.6rem) 1fr ${spacingRem.blockRightColumnSpacing}`,
    alignItems: 'start',
  },
  standard: {
    gridTemplateColumns: `${spacingRem.blockLeftColumnSpacing} minmax(20rem, 55rem) ${spacingRem.blockLeftColumnSpacing} minmax(20rem, 68.6rem) 1fr ${spacingRem.blockRightColumnSpacing}`,
    alignItems: 'start',
  },
  verticalFullWidth: {
    gridColumnStart: 1,
    gridColumnEnd: 'last',
  },
  horizontalFullWidth: {
    gridColumnStart: 3,
    gridColumnEnd: 'last',
  },
  compact: {
    rowGap: spacingRem.xs,
  },
}, 'horizontalBlock');

interface HorizontalBlockProps {
  children: ReactNode,
  withHighlightBlock?: string,
  asBlockContent?: boolean,
  compact?: boolean,
  variant?: HorizontalBlockVariant,
}

const HorizontalBlock: FunctionComponent<HorizontalBlockProps> = ({
  children,
  withHighlightBlock,
  asBlockContent = false,
  compact = false,
  variant = HorizontalBlockVariant.main,
}) => {
  const classes = useStyles();
  const { isVertical, level } = useBlockContext();

  const chipContext = useChipBlockContext();
  const currentContext = useMemo(() => [chipContext.context, withHighlightBlock].filter(filterNullOrUndefined), [chipContext.context, withHighlightBlock]);
  const highlightRef = useHighlight<HTMLDivElement>(currentContext, withHighlightBlock ? undefined : {});

  const value = useMemo(() => ({ level: level + 1, isVertical: false }), [level]);

  return (
    <BlockContextProvider context={value}>
      <div
        ref={highlightRef}
        className={classnames({
          [classes.block]: true,
          [classes.standard]: variant === HorizontalBlockVariant.main,
          [classes.compactLine]: variant === HorizontalBlockVariant.compact,
          [classes.compactLineWithFilters]: variant === HorizontalBlockVariant.compactWithFilters,
          [classes.compactWithoutFirstColumn]: variant === HorizontalBlockVariant.compactWithoutFirstColumn,
          [classes.compactInCard]: variant === HorizontalBlockVariant.compactInCard,
          [classes.compactInCardWithoutFirstColumn]: variant === HorizontalBlockVariant.compactInCardWithoutFirstColumn,
          [classes.horizontalFullWidth]: asBlockContent && !isVertical,
          [classes.verticalFullWidth]: asBlockContent && isVertical,
          [classes.compact]: compact,
        })}
      >
        {children}
      </div>
    </BlockContextProvider>
  );
};

export default HorizontalBlock;
