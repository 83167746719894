const HOSTNAME_PATTERN = /^(?<domain>.+)\.sharepoint\.com/;
const PATH_NAME_PATTERN = /\/:(?<fileType>[a-z]):\/[a-z]\/(?<library>.+)\/(?<key>.+)\?/;
const PATH_NAME_SHARE_PATTERN = /\/:(?<fileType>[a-z]):\/[a-z]\/sites\/(?<library>\w+)(.\/)*\/.*?share=(?<key>.+)/;

/**
 * A URI wrapper that can parse out information about an SharePoint URI
 *
 * @param {String} rawUri - the URI to parse
 */
const parseSharePointURI = (rawUri: string) => {
  const uri = new URL(rawUri);
  if (uri.search?.includes('sourcedoc')) {
    return undefined;
  }
  if (uri.protocol !== 'https:') {
    return undefined;
  }
  if (!uri.host) {
    return undefined;
  }
  if (uri.port) {
    return undefined;
  }
  if (!uri.hostname) {
    return undefined;
  }
  const hostNameMatches = uri.hostname.match(HOSTNAME_PATTERN);
  if (!hostNameMatches) {
    return undefined;
  }
  const { domain } = hostNameMatches.groups ?? {};
  const pathNameMatches = `${uri.pathname}${uri.search}`.match(uri.searchParams.get('share') ? PATH_NAME_SHARE_PATTERN : PATH_NAME_PATTERN);
  if (!pathNameMatches) {
    return undefined;
  }
  const { fileType, library, key } = pathNameMatches.groups ?? {};
  return { domain, fileType, library, key };
};

const match = (url: string): {
  type: string,
  assetKey: { key: string, fileType: string, domain: string, library: string },
  generateUrl: () => string | undefined,
} | undefined => {
  const parsedUrl = parseSharePointURI(url);
  if (parsedUrl) {
    const { key, fileType, domain, library } = parsedUrl;
    return {
      assetKey: {
        key,
        fileType,
        domain,
        library,
      },
      generateUrl: () => `https://${encodeURIComponent(domain)}.sharepoint.com/:${fileType}:/s/${encodeURIComponent(library)}/${encodeURIComponent(key)}`,
      type: 'SHAREPOINT',
    };
  }
  return undefined;
};

export default {
  name: 'SHAREPOINT',
  match,
};
