import type { FunctionComponent, ReactElement } from 'react';
import { useState } from 'react';
import { joinObjects } from 'yooi-utils';
import Button from '../../../../components/atoms/Button';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import ColorWithDropdown, { ColorWithDropdownVariant } from '../../../../components/molecules/ColorWithDropdown';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { formatOrUndef } from '../../../../utils/stringUtils';
import type { NavigationPayload } from '../../../../utils/useNavigation';
import useTheme from '../../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../../utils/useUsageContext';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: spacingRem.s,
    rowGap: spacingRem.s,
  },
  fieldsContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
  fieldColor: {
    color: theme.color.text.info,
  },
}), 'workflowFieldColorRenderer');

const actionToButton = ({ key, title, disabled, onClick }: { key: string, title: string, disabled: boolean, onClick: () => void }): ReactElement => (
  <Button
    key={key}
    maxLine={1}
    title={title}
    disabled={disabled}
    onClick={onClick}
  />
);

interface Option {
  id: string,
  label: string,
  color?: string,
  to?: string,
  state?: NavigationPayload['state'],
  tooltip?: string,
  icon?: IconName | { name: IconName, colorVariant: IconColorVariant } | { name: IconName, color: string },
  squareColor?: string,
}

interface WorkflowFieldColorRendererProps {
  selectedOption: Option | undefined,
  computeOptions: () => Option[],
  onSelect: (option: { id: string } | null) => void,
  actions: { key: string, title: string, disabled: boolean, onClick: () => void }[],
  impactedSubFieldNames?: string[],
  canChangeStatus: boolean,
  readOnly: boolean,
}

const WorkflowFieldColorRenderer: FunctionComponent<WorkflowFieldColorRendererProps> = ({
  selectedOption,
  computeOptions,
  onSelect,
  actions,
  impactedSubFieldNames,
  canChangeStatus,
  readOnly,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <span className={classes.container}>
      <ColorWithDropdown
        dropdownSizes={{
          minWidth: '20rem',
          maxWidth: '40rem',
        }}
        showDropdown={showDropdown}
        openDropdown={() => {
          setShowDropdown(true);
        }}
        closeDropdown={() => {
          setShowDropdown(false);
        }}
        color={selectedOption?.squareColor ?? theme.color.border.default}
        tooltip={selectedOption === undefined ? i18n`Workflow not yet initialized` : formatOrUndef(selectedOption.label)}
        renderDropdownTitle={() => (
          <UsageContextProvider usageVariant={UsageVariant.inForm}>
            <SearchAndSelect
              placeholder={i18n`Select instance`}
              selectedOption={selectedOption ? { id: selectedOption.id, label: selectedOption.label } : undefined}
              computeOptions={() => computeOptions().filter(({ id }) => id !== selectedOption?.id)}
              onSelect={(option) => {
                onSelect(option);
                setShowDropdown(false);
              }}
              readOnly={readOnly || !canChangeStatus}
            />
          </UsageContextProvider>
        )}
        renderDropdownContent={() => [
          {
            key: 'actions',
            content: actions.length === 0 ? null : (
              <span className={classes.buttonsContainer}>
                {actions.map((action) => actionToButton(joinObjects(
                  action,
                  {
                    disabled: action.disabled || readOnly,
                    onClick: () => {
                      action.onClick();
                      setShowDropdown(false);
                    },
                  }
                )))}
              </span>
            ),
          },
          ...(impactedSubFieldNames && impactedSubFieldNames.length > 0 ? [
            {
              key: 'fields',
              withTopSeparator: true,
              content: (
                <span className={classes.fieldsContainer}>
                  <Icon name={IconName.info} colorVariant={IconColorVariant.info} tooltip={impactedSubFieldNames.join('\n')} />
                  <Typo>
                    {i18n.jsx`${(
                      <Tooltip key="subFields" title={impactedSubFieldNames.join('\n')}>
                        <span className={classes.fieldColor}>{i18n`${impactedSubFieldNames.length} fields`}</span>
                      </Tooltip>
                    )} in this workflow`}
                  </Typo>
                </span>
              ),
            },
          ] : []),
        ]}
        readOnly={readOnly}
        variant={ColorWithDropdownVariant.rightDot}
      />
    </span>
  );
};

export default WorkflowFieldColorRenderer;
