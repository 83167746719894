import { equals } from 'ramda';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { FilterStep, MultipleParameterDefinition, PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { isDimensionStep, isFieldStep, isFilterStep, isGlobalDimensionStep, isMappingStep, isRelationalType, PathStepType } from 'yooi-modules/modules/conceptModule';
import { FieldDimensionTypes, FieldDimensionTypes_Role_ConceptDefinition, FieldDimensionTypes_Role_FieldDimension } from 'yooi-modules/modules/conceptModule/ids';
import { isMultipleMappingStep } from 'yooi-modules/modules/conceptModule/utils/path/pathUtils';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { Comparator } from 'yooi-utils';
import { compareNumber, comparing, extractAndCompareValue, filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import { EditableCloseReasons } from '../../../components/molecules/EditableWithDropdown';
import SearchAndSelectMultiple from '../../../components/molecules/SearchAndSelectMultiple';
import type { FrontObjectStore } from '../../../store/useStore';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { formatOrUndef } from '../../../utils/stringUtils';
import useDerivedState from '../../../utils/useDerivedState';
import type { PathInputStep } from '../fields/_global/pathUtils';
import {
  getAllInstancesChipOption,
  getNthStep,
  GLOBAL_DIMENSION_CHIP_OPTION,
  inputPathToStorePath,
  pathStepImplementationToPathInputStep,
  storePathToInputPath,
} from '../fields/_global/pathUtils';
import type { Chip } from '../fieldUtils';
import { getFieldChip } from '../fieldUtils';
import { countValidFilters } from '../filter/filterUtils';
import type { Option } from '../modelTypeUtils';
import { defaultOptionComparator, getConceptDefinitionNameOrEntity, getOption, getUnknownChip } from '../modelTypeUtils';
import type { PathConfigurationHandler, PathStepOption } from '../pathConfigurationHandler';
import { createPathConfigurationHandler } from '../pathConfigurationHandler';

const pathStepInputOptionToChip = (
  store: FrontObjectStore,
  basePath: PathInputStep[],
  { step, replacePrevious, isParameter }: PathStepOption<PathInputStep>,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[]
): PathStepInputOptionChip => {
  const fullPath = [...basePath.flatMap(({ steps }) => steps), ...step.steps];

  const wrapChip = (chip: Chip) => joinObjects(chip, step.displayOverrides, { step, replacePrevious, isParameter, index: 0 });

  const lastStep = fullPath.at(-1);
  const previousStep = fullPath.at(-2);

  if (isFieldStep(lastStep)) {
    const maybeDimensionStep = fullPath.at(-3);
    let conceptDefinitionId;
    if (maybeDimensionStep?.type === PathStepType.dimension) {
      conceptDefinitionId = store.getObjectOrNull(maybeDimensionStep.conceptDefinitionId)?.id;
    } else if (lastStep.mapping !== undefined) {
      conceptDefinitionId = Object.keys(lastStep.mapping)
        .flatMap((dimensionId) => (store.withAssociation(FieldDimensionTypes).withRole(FieldDimensionTypes_Role_FieldDimension, dimensionId).list()))
        .at(0)
        ?.role(FieldDimensionTypes_Role_ConceptDefinition);
    }

    let fieldId;
    if (lastStep.embeddingFieldId) {
      fieldId = lastStep.embeddingFieldId;
    } else if (lastStep.workflowSubfieldId) {
      fieldId = lastStep.workflowSubfieldId;
    } else {
      fieldId = lastStep.fieldId;
    }

    if (conceptDefinitionId !== undefined && store.getObjectOrNull(fieldId) !== null) {
      return wrapChip(getFieldChip(store, conceptDefinitionId, fieldId));
    } else {
      return wrapChip(getOption(store, fieldId, parameterDefinitions));
    }
  } else if (isDimensionStep(lastStep)) {
    return wrapChip(getOption(store, lastStep.conceptDefinitionId, parameterDefinitions));
  } else if (isMappingStep(lastStep)) {
    const { icon, ...option } = getOption(store, lastStep.mapping.id, parameterDefinitions);
    return wrapChip(option);
  } else if (isMultipleMappingStep(lastStep)) {
    const { icon, ...option } = getOption(store, lastStep.id, parameterDefinitions);
    return wrapChip(option);
  } else if (isFilterStep(lastStep) && isDimensionStep(previousStep)) {
    return wrapChip(getAllInstancesChipOption(store, previousStep.conceptDefinitionId));
  } else if (isGlobalDimensionStep(lastStep) && lastStep.conceptDefinitionIds.length > 0) {
    return wrapChip(getOption(store, lastStep.conceptDefinitionIds[lastStep.conceptDefinitionIds.length - 1], parameterDefinitions));
  } else if (isGlobalDimensionStep(lastStep)) {
    return wrapChip(GLOBAL_DIMENSION_CHIP_OPTION);
  } else {
    return wrapChip(getUnknownChip('unknown'));
  }
};

interface PathStepInputOptionChip extends PathStepOption<PathInputStep>, Option {
  index: number,
}

const getStepWeight = ({ step, isParameter }: { step: PathInputStep, isParameter?: boolean }): number => {
  const { steps } = step;
  const lastStep = steps[steps.length - 1];
  if (isFieldStep(lastStep)) {
    return 1 + step.steps.length * 5;
  } else if (isDimensionStep(lastStep)) {
    return 2 + step.steps.length * 5;
  } else if (isMappingStep(lastStep)) {
    return 3 + (isParameter ? 0.5 : 0) + (step.steps.length + (step.steps.length > 1 ? 1 : 0)) * 5;
  } else if (isMultipleMappingStep(lastStep)) {
    return 4 + step.steps.length * 5;
  } else if (isFilterStep(lastStep)) {
    return 5 + step.steps.length * 5;
  } else {
    return 6 + step.steps.length * 5;
  }
};

const compareStep: Comparator<{ step: PathInputStep, isParameter?: boolean }> = comparing(extractAndCompareValue(getStepWeight, compareNumber), true);

const pathStepOptionComparator = comparing<PathStepInputOptionChip>(extractAndCompareValue(({ step, isParameter }) => ({ step, isParameter }), compareStep))
  .thenComparing(defaultOptionComparator);

// map path step errors to an input path
// the store path with errors should be the same as the complete input pats
const mapErrorsToInputPath = (
  inputPath: PathInputStep[],
  storePathWithErrors: { step: PathStep, errors: string[] }[]
): PathInputStep[] => {
  let inputPathIndex = 0;
  return inputPath.map((pathInputStep): PathInputStep => {
    const pathInputStepErrors: string[] = [];
    pathInputStep.steps.forEach(() => {
      pathInputStepErrors.push(...storePathWithErrors[inputPathIndex].errors);
      inputPathIndex += 1;
    });
    return joinObjects(pathInputStep, {
      displayOverrides: joinObjects(pathInputStep.displayOverrides, { errors: pathInputStepErrors }),
    });
  });
};

interface PathStepsInputProps {
  initialPath: PathStep[],
  onSubmit?: (newPath: PathStep[]) => void,
  showEndOnly?: boolean,
  readOnly?: boolean,
  placeholder?: string,
  focusOnMount?: boolean,
  parameterDefinitions?: (SingleParameterDefinition | MultipleParameterDefinition)[],
  valuePathHandler?: PathConfigurationHandler,
  getStepChipOption?: (
    step: PathInputStep,
    index: number,
    self: PathInputStep[],
    fallbackStepChipOption: (pathStep: PathInputStep, index: number, self: PathInputStep[]) => PathStepInputOptionChip | undefined,
  ) => PathStepInputOptionChip | undefined,
  suggestedBasePaths?: { label: string, path: PathStep[] }[],
  rootPath?: { label: string, path: PathStep[] },
  withSearchInId?: boolean,
  warning?: string,
}

const PathStepsInput: FunctionComponent<PathStepsInputProps> = ({
  initialPath,
  onSubmit,
  readOnly,
  showEndOnly,
  placeholder,
  focusOnMount,
  parameterDefinitions = [],
  valuePathHandler,
  getStepChipOption,
  suggestedBasePaths,
  rootPath,
  withSearchInId = false,
  warning,
}) => {
  const store = useStore();

  const [isEditing, setIsEditing] = useState(false);
  // path that will be displayed on the screen. Different from the one that will be stored, some steps can be hidden on the screen but have to be presents in the store.
  // We manage internally the inputPath when in edition mode
  const [inputPath, setInputPath, resetInputPath] = useDerivedState(
    () => storePathToInputPath(store, initialPath, rootPath !== undefined ? [rootPath] : suggestedBasePaths),
    isEditing ? [] : [initialPath, rootPath, suggestedBasePaths]
  );

  const { computeOptions: configurationHandlerComputeOptions, getErrors, getPathWithErrors } = valuePathHandler ?? createPathConfigurationHandler(store, parameterDefinitions);

  const getDefaultStepChipOption = (pathStep: PathInputStep, index: number, self: PathInputStep[]): PathStepInputOptionChip | undefined => {
    const n1Step = getNthStep(self, index, 1);
    const displayedStepIndex = pathStep.displayOverrides?.displayedPathStepIndex ?? pathStep.steps.length - 1;
    const displayedStep = pathStep.steps[displayedStepIndex];
    if (isFilterStep(displayedStep)) {
      if (n1Step && isDimensionStep(n1Step)) {
        const filterCount = countValidFilters(store, displayedStep.filters);
        const allInstancesChipOption = getAllInstancesChipOption(store, n1Step.conceptDefinitionId);
        return joinObjects(allInstancesChipOption, { step: pathStep, label: i18n`All Instances (${filterCount} filters)`, id: `${allInstancesChipOption.id}_${index}`, index });
      } else {
        return joinObjects(getUnknownChip(`unknown_${index}`), { step: pathStep, index });
      }
    } else if (isDimensionStep(displayedStep)) {
      const option = getOption(store, displayedStep.conceptDefinitionId, parameterDefinitions);
      return joinObjects(
        option,
        {
          id: `${option.id}_${index}`,
          step: pathStepImplementationToPathInputStep({ type: displayedStep.type, conceptDefinitionId: displayedStep.conceptDefinitionId }),
          index,
        }
      );
    } else if (isFieldStep(displayedStep)) {
      const fullPath = [...self.slice(0, index).flatMap(({ steps }) => steps), ...pathStep.steps.slice(0, displayedStepIndex + 1)];
      const maybeDimensionStep = fullPath.at(-3);

      let conceptDefinitionId;
      if (maybeDimensionStep?.type === PathStepType.dimension) {
        conceptDefinitionId = store.getObjectOrNull(maybeDimensionStep.conceptDefinitionId)?.id;
      } else if (displayedStep.mapping !== undefined) {
        conceptDefinitionId = Object.keys(displayedStep.mapping)
          .flatMap((dimensionId) => (store.withAssociation(FieldDimensionTypes).withRole(FieldDimensionTypes_Role_FieldDimension, dimensionId).list()))
          .at(0)
          ?.role(FieldDimensionTypes_Role_ConceptDefinition);
      }

      let option;
      if (conceptDefinitionId !== undefined && store.getObjectOrNull(displayedStep.fieldId) !== null) {
        option = getFieldChip(store, conceptDefinitionId, displayedStep.fieldId);
      } else {
        option = getOption(store, displayedStep.fieldId, parameterDefinitions);
      }

      const labelSuffixes: string[] = [];
      const tooltipSuffixes: string[] = [];

      if (displayedStep.embeddingFieldId) {
        const embeddingOption = getOption(store, displayedStep.embeddingFieldId, parameterDefinitions);
        labelSuffixes.push(embeddingOption.label);
        tooltipSuffixes.push(embeddingOption.tooltip ?? embeddingOption.label);
      }

      if (displayedStep.workflowSubfieldId) {
        const workflowFieldOption = getOption(store, displayedStep.workflowSubfieldId, parameterDefinitions);
        labelSuffixes.push(workflowFieldOption.label);
        tooltipSuffixes.push(workflowFieldOption.tooltip ?? workflowFieldOption.label);
      }

      if (isGlobalDimensionStep(n1Step)) {
        const mappings = displayedStep.mapping ? Object.values(displayedStep.mapping).filter(filterNullOrUndefined).length : 0;
        labelSuffixes.push(i18n`${mappings} mappings`);
        tooltipSuffixes.push(i18n`${mappings} mappings`);
      }

      return joinObjects(
        option,
        {
          label: `${option.label}${labelSuffixes.length > 0 ? ` (${labelSuffixes.join(', ')})` : ''}`,
          tooltip: `${option.tooltip}${tooltipSuffixes.length > 0 ? ` (${tooltipSuffixes.join(', ')})` : ''}`,
          id: `${option.id}_${index}`,
          step: pathStepImplementationToPathInputStep({ type: displayedStep.type, fieldId: displayedStep.fieldId, embeddingFieldId: displayedStep.embeddingFieldId }),
          index,
        }
      );
    } else if (isMappingStep(displayedStep)) {
      const { icon, ...option } = getOption(store, displayedStep.mapping.id, parameterDefinitions);
      return joinObjects(
        option,
        {
          id: `${option.id}_${index}`,
          step: pathStepImplementationToPathInputStep({ type: displayedStep.type, mapping: { id: displayedStep.mapping.id, type: displayedStep.mapping.type } }),
          index,
        }
      );
    } else if (isMultipleMappingStep(displayedStep)) {
      const { icon, label, ...option } = getOption(store, displayedStep.id, parameterDefinitions);
      const filterCount = countValidFilters(store, displayedStep.filters);
      return joinObjects(
        option,
        {
          label: `${label} (${i18n`${filterCount} filters`})`,
          id: `${option.id}_${index}`,
          step: pathStepImplementationToPathInputStep({ type: displayedStep.type, id: displayedStep.id }),
          index,
        }
      );
    } else if (isGlobalDimensionStep(displayedStep)) {
      return {
        step: pathStep,
        label: `${GLOBAL_DIMENSION_CHIP_OPTION.label}${displayedStep.conceptDefinitionIds.length > 0 ? `: ${displayedStep.conceptDefinitionIds
          .map((id) => {
            if (store.getObjectOrNull(id)) {
              return getConceptDefinitionNameOrEntity(store, id);
            } else {
              return i18n`Unknown`;
            }
          }).join(' x ')}` : ''}`,
        id: `${GLOBAL_DIMENSION_CHIP_OPTION.id}_${index}`,
        index,
      };
    } else {
      return undefined;
    }
  };

  const pathInputSteps = mapErrorsToInputPath(inputPath, getPathWithErrors(inputPathToStorePath(inputPath)));
  let selectedSteps: PathStepInputOptionChip[] = pathInputSteps
    .map((pathStep, index, self): PathStepInputOptionChip | undefined => {
      const stepOption: PathStepInputOptionChip | undefined = getStepChipOption
        ? getStepChipOption?.(pathStep, index, self, getDefaultStepChipOption)
        : getDefaultStepChipOption(pathStep, index, self);

      if (stepOption === undefined) {
        return undefined;
      } else if (pathStep.displayOverrides?.errors?.length) {
        const tooltipLines: string[] = [];
        if (stepOption?.tooltip) {
          tooltipLines.push(stepOption.tooltip);
        }
        pathStep.displayOverrides.errors?.forEach((error) => {
          if (!tooltipLines.includes(error)) {
            tooltipLines.push(error);
          }
        });

        return joinObjects(
          stepOption,
          {
            icon: { name: IconName.dangerous, colorVariant: IconColorVariant.error },
            tooltip: tooltipLines.join('\n'),
            label: formatOrUndef(pathStep.displayOverrides.label ?? stepOption?.label),
          }
        );
      } else {
        return joinObjects(
          stepOption,
          { label: formatOrUndef(pathStep.displayOverrides?.label ?? stepOption?.label) }
        );
      }
    }).filter(filterNullOrUndefined);

  if (showEndOnly) {
    selectedSteps = selectedSteps.reduce<PathStepInputOptionChip[]>((acc, pathStepOption, index) => {
      const { steps } = pathStepOption.step;
      const lastStep = steps[steps.length - 1];
      if (isFieldStep(lastStep) && acc.length > 0 && index !== selectedSteps.length - 1) {
        return [{ id: 'shortened', label: '...', step: { steps: acc.flatMap((elem) => elem.step.steps) }, index: 0 }, pathStepOption];
      } else {
        return [...acc, pathStepOption];
      }
    }, []);
  }

  const computeOptions = (): PathStepOption<PathInputStep>[] => {
    const lastStep = inputPath[inputPath.length - 1]?.steps[inputPath[inputPath.length - 1].steps.length - 1] as PathStep | undefined;
    let isLastStepRelationalField = false;
    if (lastStep && isFieldStep(lastStep)) {
      const fieldInstance = store.getObjectOrNull(lastStep.fieldId);
      isLastStepRelationalField = fieldInstance ? isRelationalType(fieldInstance[Instance_Of] as string) : false;
    }
    const stepOptions = inputPath.length === 0 && rootPath !== undefined
      ? []
      : configurationHandlerComputeOptions(inputPathToStorePath(inputPath)).map((option) => (joinObjects(option, { step: pathStepImplementationToPathInputStep(option.step) })));

    if (inputPath.length === 0 && (suggestedBasePaths !== undefined || rootPath !== undefined)) {
      const basePaths = rootPath !== undefined ? [rootPath] : suggestedBasePaths ?? [];
      return [
        ...basePaths.map((base) => ({ step: { steps: base.path, displayOverrides: { label: base.label } }, isParameter: true })),
        ...stepOptions,
        ...(
          basePaths.length === 1
            ? configurationHandlerComputeOptions(basePaths[0].path).map((option) => (joinObjects(option, { step: { steps: [...basePaths[0].path, option.step] } })))
            : []
        ),
      ];
    } else if (isLastStepRelationalField && stepOptions.length === 1) {
      const storePath: PathStep[] = [...inputPathToStorePath(inputPath), stepOptions[0].step.steps[0], { type: PathStepType.filter }];
      const shortcutOptions: PathStepOption<PathInputStep>[] = configurationHandlerComputeOptions(storePath)
        .map((option): PathStepOption<PathInputStep> => (
          joinObjects(option, { step: { steps: [stepOptions[0].step.steps[0], { type: PathStepType.filter } satisfies FilterStep, option.step] } })
        ));
      return [...shortcutOptions, ...stepOptions];
    } else {
      return stepOptions;
    }
  };

  return (
    <SearchAndSelectMultiple
      warning={warning}
      error={getErrors(inputPathToStorePath(inputPath))?.join('\n')}
      focusOnMount={focusOnMount}
      computeOptions={() => computeOptions()
        .map((pathStep) => pathStepInputOptionToChip(store, pathInputSteps, pathStep, parameterDefinitions))
        .sort(pathStepOptionComparator)}
      selectedSteps={selectedSteps}
      shouldDisplayChevron={(previousStep, nextStep) => (
        previousStep.id === 'shortened' || !(
          isDimensionStep(previousStep.step.steps.at(-1))
          && (isMappingStep(nextStep.step.steps.at(0)) || isMultipleMappingStep(nextStep.step.steps.at(0)) || isFilterStep(nextStep.step.steps.at(0)))
        )
      )}
      onSelect={({ step, replacePrevious }) => {
        if (replacePrevious) {
          setInputPath((oldInputPath) => [...oldInputPath.slice(0, -1), ...oldInputPath.slice(-1)
            .map((inputPathStep) => (joinObjects(inputPathStep, { steps: [...inputPathStep.steps.slice(0, -1), ...step.steps] })))]);
        } else {
          setInputPath((oldInputPath) => [...oldInputPath, step]);
        }
      }}
      onDelete={({ index }) => {
        if (!isEditing) {
          resetInputPath();
          onSubmit?.(inputPathToStorePath(inputPath.splice(0, index)));
        } else {
          setInputPath((oldInputPath) => oldInputPath.splice(0, index));
        }
      }}
      readOnly={readOnly}
      forceSingleLine
      placeholder={placeholder}
      onEditionStart={() => setIsEditing(true)}
      onEditionStop={(reason) => {
        setIsEditing(false);
        if (reason === EditableCloseReasons.onEscapeKeyDown) {
          resetInputPath();
          onSubmit?.(initialPath);
        } else {
          const inputPathToSubmit = inputPathToStorePath(inputPath);
          resetInputPath();
          // onsubmit is called only if path has changed
          if (!equals(inputPathToSubmit, initialPath)) {
            onSubmit?.(inputPathToSubmit);
          }
        }
      }}
      searchOptions={withSearchInId ? { searchKeys: ['id', 'label'], extractValue: (option, searchKey) => option[searchKey as 'id' | 'label'] } : undefined}
    />
  );
};

export default PathStepsInput;
