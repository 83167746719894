import type { FunctionComponent } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { StructuralBarChartViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { joinObjects } from 'yooi-utils';
import StructuralBarChart from '../../../../components/charts/BarChart/StructuralBarChart';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import base from '../../../../theme/base';
import { buildPadding, Spacing, spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import { remToPx, SCROLLBAR_WIDTH_IN_REM } from '../../../../utils/sizeUtils';
import useDeepMemo from '../../../../utils/useDeepMemo';
import type { WidgetDisplay } from '../../fields/_global/widgetUtils';
import { getWidgetHeight } from '../../fields/_global/widgetUtils';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { useViewFilters } from '../../filter/useViewFilters';
import BlockViewError from '../common/BlockViewError';
import ViewsTooltip from '../common/ViewsTooltip';
import { getViewDefinitionHandler } from '../viewDsl';
import { isResolutionError } from '../viewResolutionUtils';
import type { StructuralBarChartViewDefinitionHandler } from './structuralBarChartViewHandler';
import type { BarValue } from './structuralBarChartViewResolution';

const useStyles = makeStyles((theme) => ({
  container: joinObjects(
    {
      marginBottom: spacingRem.blockSpacing,
      boxShadow: base.shadowElevation.low,
      borderRadius: base.borderRadius.medium,
      background: theme.color.background.neutral.default,
      borderLeftWidth: '0.1rem',
      borderLeftStyle: 'solid',
      borderLeftColor: theme.color.transparent,
    },
    buildPadding({ y: Spacing.xs, right: Spacing.xs, left: Spacing.splus })
  ),
  preventChartFlick: {
    position: 'absolute',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
}), 'structuralBarChartViewBlock');

interface StructuralBarChartViewBlockProps {
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinition: StructuralBarChartViewStoredDefinition,
  parametersMapping: ParametersMapping,
  widgetDisplay: WidgetDisplay,
}

const renderStructuralTooltip = (
  datum: BarValue,
  totalByX: number | undefined
) => (
  <ViewsTooltip
    fieldId={datum.serie.fieldId}
    seriesLabel={datum.serie.label}
    dimensionsMapping={datum.dimensionsMapping ?? {}}
    value={datum.value}
    totalValue={totalByX}
  />
);

const StructuralBarChartViewBlock: FunctionComponent<StructuralBarChartViewBlockProps> = ({
  viewDimensions,
  viewFilters,
  viewDefinition,
  parametersMapping,
  widgetDisplay,
}) => {
  const classes = useStyles();

  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();

  const filterConfiguration = useViewFilters(viewFilters, viewDimensions);

  const viewHandler = getViewDefinitionHandler(viewDefinition) as StructuralBarChartViewDefinitionHandler;
  const structuralChartResolution = useDeepMemo(
    () => viewHandler.resolveView(store, {
      viewDimensions,
      parametersMapping,
      userId: loggedUserId,
      filterConfiguration,
      aclHandler,
    }),
    [store.getSerial(), viewDimensions, viewDefinition, parametersMapping, filterConfiguration]
  );

  if (isResolutionError(structuralChartResolution)) {
    return <BlockViewError error={structuralChartResolution.error} />;
  } else {
    const height = getWidgetHeight(widgetDisplay);
    return (
      <div className={classes.container}>
        <AutoSizer disableHeight>
          {({ width = 0 }) => (
            <div style={{ height }}>
              <div className={classes.preventChartFlick} style={{ height, width }}>
                <StructuralBarChart
                  minValue={structuralChartResolution.minValue}
                  maxValue={structuralChartResolution.maxValue}
                  steps={structuralChartResolution.steps}
                  data={structuralChartResolution.data}
                  labels={structuralChartResolution.labels}
                  height={height - remToPx(SCROLLBAR_WIDTH_IN_REM)}
                  width={width - remToPx(SCROLLBAR_WIDTH_IN_REM)}
                  renderTooltip={renderStructuralTooltip}
                  groupBy={Boolean(viewDefinition.xAxis)}
                  yDomain={structuralChartResolution.yDomain}
                />
              </div>
            </div>
          )}
        </AutoSizer>
      </div>
    );
  }
};

export default StructuralBarChartViewBlock;
