import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { FieldDefinitionStoreObject, FieldDimensionStoreObject, FieldDimensionTypesStoreObject, FieldStoreObject, ParsedFormula } from 'yooi-modules/modules/conceptModule';
import {
  Field_FieldDimensions,
  Field_Formula,
  Field_ParsedFormula,
  FieldDefinition,
  FieldDimension_Label,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
} from 'yooi-modules/modules/conceptModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { FormulaType, WithNonUndefinedKeys } from 'yooi-utils';
import { compareNumber, compareProperty, compareString, comparing, errorToObject, filterNullOrUndefined, pushUndefinedToEnd } from 'yooi-utils';
import Icon, { IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import Tooltip from '../../../components/atoms/Tooltip';
import Typo from '../../../components/atoms/Typo';
import TextInputString from '../../../components/inputs/TextInputString';
import CompositeField, { CompositeFieldVariants } from '../../../components/molecules/CompositeField';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SearchAndSelectMultiple from '../../../components/molecules/SearchAndSelectMultiple';
import { TableSortDirection } from '../../../components/molecules/Table';
import TableInnerCellContainer, { TableInnerCellContainerVariants } from '../../../components/molecules/TableInnerCellContainer';
import TagContainer from '../../../components/molecules/TagContainer';
import Toggle from '../../../components/molecules/Toggle';
import ToggleButton from '../../../components/molecules/ToggleButton';
import BlockContent from '../../../components/templates/BlockContent';
import DataTable from '../../../components/templates/DataTable';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import base from '../../../theme/base';
import { FontVariant } from '../../../theme/fontDefinition';
import { buildPadding, Spacing, spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { safeSessionStorageValue } from '../../../utils/sessionStorageUtils';
import useNavigation from '../../../utils/useNavigation';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import useTheme from '../../../utils/useTheme';
import { getFieldDefinitionHandler } from '../../_global/fields/FieldLibrary';
import { getFieldChip } from '../../_global/fieldUtils';
import SearchTextButton from '../../_global/filter/SearchTextButton';
import { useFilterStorage } from '../../_global/filter/useFilterSessionStorage';
import { searchFilterFunction } from '../../_global/listFilterFunctions';
import { getConceptDefinitionNameOrEntity } from '../../_global/modelTypeUtils';
import useFilterAndSort from '../../_global/useFilterAndSort';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: spacingRem.s,
    paddingBottom: spacingRem.xs,
  },
  filtersContainer: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: spacingRem.s,
    justifyContent: 'flex-start',
    marginBottom: spacingRem.xs,
  },
  toggleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingRem.s,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: spacingRem.s,
    flexGrow: 1,
    alignItems: 'center',
  },
}, 'explorerHomeComputingsTab');

const resultOptions: { [key in 'success' | 'warning' | 'error']: { id: key, label: string, icon: IconName, color: string } } = {
  error: { id: 'error', label: i18n`Error`, icon: IconName.dangerous, color: base.color.gray['300'] },
  warning: { id: 'warning', label: i18n`Warning`, icon: IconName.warning, color: base.color.gray['300'] },
  success: { id: 'success', label: i18n`Success`, icon: IconName.check_circle, color: base.color.gray['300'] },
};

interface Configuration {
  nameSearch?: string,
  statusFilters?: ('success' | 'warning' | 'error')[],
  typeFilters?: string[],
}

type ParsingResult = { type: 'error', message: string } | { type: 'warning', returnType: FormulaType, code: string } | { type: 'success', returnType: FormulaType, code: string };

const errorToMessage = (error: unknown): string => {
  const errorObject = errorToObject(error);
  return [
    `${errorObject.name}: ${errorObject.message}`,
    JSON.stringify(errorObject.data, undefined, 2),
    JSON.stringify(errorObject.stacktrace, undefined, 2),
    JSON.stringify(errorObject.cause, undefined, 2),
  ].join('\n');
};

const ExplorerHomeComputingsTab: FunctionComponent = () => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const navigation = useNavigation();

  const rawList = store.getObject(FieldDefinition)
    .navigateBack<FieldDefinitionStoreObject>(Class_Instances)
    .flatMap((fieldDefinition) => (
      fieldDefinition
        .navigateBack<FieldStoreObject>(Class_Instances)
        .filter((field): field is WithNonUndefinedKeys<FieldStoreObject, typeof Field_Formula> => field[Field_Formula] !== undefined)
        .map((field) => {
          let parsingResult: ParsingResult;

          try {
            const parsedFormula = field[Field_ParsedFormula] as ParsedFormula | undefined;
            if (parsedFormula !== undefined) {
              parsingResult = {
                type: parsedFormula.rootNode.type.name === 'any' ? 'warning' : 'success',
                returnType: parsedFormula.rootNode.type,
                code: parsedFormula.rootNode.transpilation.code,
              };
            } else {
              parsingResult = { type: 'error', message: 'Missing parsed formula' };
            }
          } catch (e) {
            parsingResult = { type: 'error', message: errorToMessage(e) };
          }

          const dimensions = field.navigateBack<FieldDimensionStoreObject>(Field_FieldDimensions)
            .map((dimension) => {
              const typeIds = store.withAssociation(FieldDimensionTypes)
                .withRole(FieldDimensionTypes_Role_FieldDimension, dimension.id)
                .withExternalRole(FieldDimensionTypes_Role_ConceptDefinition)
                .list<FieldDimensionTypesStoreObject>()
                .map((fieldDimensionTypes) => fieldDimensionTypes.role(FieldDimensionTypes_Role_ConceptDefinition));

              let label: string;
              let tooltip: string;
              if (dimension[FieldDimension_Label] !== undefined && dimension[FieldDimension_Label] !== '') {
                label = dimension[FieldDimension_Label];
                tooltip = `${dimension[FieldDimension_Label]} => ${typeIds.map((typeId) => getConceptDefinitionNameOrEntity(store, typeId)).join(', ')}`;
              } else if (typeIds.length === 1) {
                label = getConceptDefinitionNameOrEntity(store, typeIds[0]);
                tooltip = label;
              } else {
                label = i18n`Dimension`;
                tooltip = typeIds.map((typeId) => getConceptDefinitionNameOrEntity(store, typeId)).join(', ');
              }

              return { id: dimension.id, label, tooltip, typeIds };
            })
            .sort(compareProperty('label', compareString));

          if (dimensions.length === 0) {
            return undefined;
          } else {
            const fieldChip = getFieldChip(store, dimensions[0].typeIds[0], field.id);

            return {
              key: field.id,
              fieldTypeId: field[Instance_Of],
              name: fieldChip.label,
              fieldChip,
              formula: field[Field_Formula].formula,
              dimensions,
              parsingResult,
            };
          }
        })
        .filter(filterNullOrUndefined)
    ));

  const filterKey = 'explorerComputings';
  const [showFilters, setShowFilters] = useState(true);
  const [statusFilters = [], setStatusFilters] = useFilterStorage<'statusFilters', Configuration>(filterKey, 'statusFilters');

  const existingFieldDefinitionIds = Array.from(new Set(rawList.map(({ fieldTypeId }) => fieldTypeId)));
  const [typeFilters = [], setTypeFilters] = useFilterStorage<'typeFilters', Configuration>(filterKey, 'typeFilters');
  const actualTypeFilters = typeFilters.filter((id) => existingFieldDefinitionIds.includes(id));

  let filterCount = 0;
  filterCount += (statusFilters.length > 0 ? 1 : 0);
  filterCount += (actualTypeFilters.length > 0 ? 1 : 0);

  const searchText = safeSessionStorageValue<Configuration | undefined>(filterKey)?.nameSearch;
  const filterFunction = searchFilterFunction<typeof rawList[0]>(store, searchText, ['name', 'formula']);

  const { generatePageList, doSort, sortCriteria } = useFilterAndSort(
    filterKey,
    rawList,
    (item) => (
      (item.key === searchText || (filterFunction?.(item) ?? true))
      && (statusFilters.length === 0 || statusFilters.includes(item.parsingResult.type))
      && (actualTypeFilters.length === 0 || actualTypeFilters.includes(item.fieldTypeId))
    ),
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case 'field':
            return {
              extractValue: ({ fieldChip }) => fieldChip.label,
              comparator: comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString, direction === TableSortDirection.desc),
            };
          case 'dimensions':
            return {
              extractValue: ({ dimensions }) => dimensions.map(({ label }) => label).join('|'),
              comparator: comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString, direction === TableSortDirection.desc),
            };
          case 'returnType':
            return {
              extractValue: ({ parsingResult }) => (parsingResult.type === 'error' ? parsingResult.message : parsingResult.returnType.name),
              comparator: comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString, direction === TableSortDirection.desc),
            };
          case 'formula':
            return {
              extractValue: ({ formula }) => formula,
              comparator: comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString, direction === TableSortDirection.desc),
            };
          case 'status':
            return {
              extractValue: ({ parsingResult }) => {
                switch (parsingResult.type) {
                  case 'error':
                    return 0;
                  case 'warning':
                    return 1;
                  case 'success':
                    return 2;
                }
              },
              comparator: comparing<number | undefined>(pushUndefinedToEnd).thenComparing(compareNumber, direction === TableSortDirection.desc),
            };
          default:
            return undefined;
        }
      },
      initial: undefined,
    }
  );

  const { list, pagination } = generatePageList(25);

  return (
    <VerticalBlock compact>
      <BlockContent padded>
        <div className={classes.headerContainer}>
          <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
            <SearchTextButton element={filterKey} placeholder={i18n`Search`} />
            <ToggleButton
              active={showFilters}
              tooltip={i18n`Filters`}
              onClick={() => setShowFilters((current) => !current)}
              icon={IconName.filter_alt}
              count={filterCount > 0 ? filterCount : undefined}
            />
          </SizeContextProvider>
        </div>
      </BlockContent>
      {showFilters ? (
        <BlockContent padded>
          <div className={classes.filtersContainer}>
            <SizeContextProvider sizeVariant={SizeVariant.small}>
              <CompositeField
                dropdownMaxWidth="50rem"
                variant={CompositeFieldVariants.button}
                isSelected={statusFilters.length > 0}
                headerLinesRenderers={[
                  {
                    id: 'title',
                    render: (inDropdown) => {
                      const label = statusFilters.length > 0 ? i18n`Status (${statusFilters.length})` : i18n`Status`;
                      return (
                        <div className={classes.titleContainer}>
                          <Tooltip title={label}>
                            <Typo maxLine={1}>{label}</Typo>
                          </Tooltip>
                          {statusFilters.length > 0 && inDropdown ? (
                            <SizeContextProvider sizeVariant={SizeVariant.small}>
                              <IconOnlyButton
                                tooltip={i18n`Clear filters`}
                                variant={IconOnlyButtonVariants.secondary}
                                onClick={() => setStatusFilters([])}
                                iconName={IconName.delete}
                              />
                            </SizeContextProvider>
                          ) : null}
                        </div>
                      );
                    },
                  },
                ]}
                getDropdownSectionDefinitions={() => [{
                  id: 'instances',
                  lines: [{
                    id: 'values',
                    render: (
                      <div className={classes.toggleContainer}>
                        <TagContainer>
                          {
                            Object.values(resultOptions)
                              .map(({ id, label, icon }) => (
                                <Toggle
                                  key={id}
                                  text={label}
                                  icon={icon}
                                  onClick={() => {
                                    setStatusFilters((current = []) => (
                                      current.includes(id) ? current.filter((i) => i !== id) : [...current, id]
                                    ));
                                  }}
                                  isActive={statusFilters.includes(id)}
                                />
                              ))
                          }
                        </TagContainer>
                      </div>
                    ),
                  }],
                }]}
                onOpenDropdown={() => {}}
                onCloseDropdown={() => {}}
                flexGrow={0}
              />
              <CompositeField
                dropdownMaxWidth="50rem"
                variant={CompositeFieldVariants.button}
                isSelected={actualTypeFilters.length > 0}
                headerLinesRenderers={[
                  {
                    id: 'title',
                    render: (inDropdown) => {
                      const label = actualTypeFilters.length > 0 ? i18n`Type (${actualTypeFilters.length})` : i18n`Type`;
                      return (
                        <div className={classes.titleContainer}>
                          <Tooltip title={label}>
                            <Typo maxLine={1}>{label}</Typo>
                          </Tooltip>
                          {actualTypeFilters.length > 0 && inDropdown && (
                            <SizeContextProvider sizeVariant={SizeVariant.small}>
                              <IconOnlyButton
                                tooltip={i18n`Clear filters`}
                                variant={IconOnlyButtonVariants.secondary}
                                onClick={() => setTypeFilters([])}
                                iconName={IconName.delete}
                              />
                            </SizeContextProvider>
                          )}
                        </div>
                      );
                    },
                  },
                ]}
                getDropdownSectionDefinitions={() => [{
                  id: 'instances',
                  lines: [{
                    id: 'values',
                    render: (
                      <div className={classes.toggleContainer}>
                        <TagContainer>
                          {
                            existingFieldDefinitionIds
                              .map((fieldDefinitionId) => {
                                const fieldDefinitionHandler = getFieldDefinitionHandler(store, fieldDefinitionId);
                                return { fieldDefinitionId, label: fieldDefinitionHandler.getTypeLabel(), icon: fieldDefinitionHandler.typeIcon };
                              })
                              .sort(compareProperty('label', compareString))
                              .map(({ fieldDefinitionId, label, icon }) => (
                                <Toggle
                                  key={fieldDefinitionId}
                                  text={label}
                                  icon={icon}
                                  onClick={() => {
                                    setTypeFilters((current = []) => (
                                      current.includes(fieldDefinitionId) ? current.filter((id) => id !== fieldDefinitionId) : [...current, fieldDefinitionId]
                                    ));
                                  }}
                                  isActive={actualTypeFilters.includes(fieldDefinitionId)}
                                />
                              ))
                          }
                        </TagContainer>
                      </div>
                    ),
                  }],
                }]}
                onOpenDropdown={() => {}}
                onCloseDropdown={() => {}}
              />
            </SizeContextProvider>
          </div>
        </BlockContent>
      ) : null}
      <DataTable
        list={list}
        columnsDefinition={[
          {
            propertyId: 'dimensions',
            name: i18n`Dimensions`,
            width: 15,
            sortable: true,
            cellRender: ({ dimensions }) => (<SearchAndSelectMultiple selectedOptions={dimensions} readOnly />),
          },
          {
            propertyId: 'field',
            name: i18n`Field`,
            width: 20,
            sortable: true,
            cellRender: ({ key, dimensions }) => (
              <SearchAndSelect
                selectedOption={getFieldChip(store, dimensions[0].typeIds[0], key)}
                readOnly
              />
            ),
          },
          {
            propertyId: 'formula',
            name: i18n`Formula`,
            sortable: true,
            cellRender: ({ formula }) => (
              <TextInputString
                value={formula}
                textVariant={FontVariant.code}
                dropdownMaxLine={20}
                readOnly
              />
            ),
          },
          {
            propertyId: 'status',
            name: i18n`Status`,
            sortable: true,
            width: '4rem',
            cellRender: ({ parsingResult }) => {
              let icon: IconName;
              let color: string;
              switch (parsingResult.type) {
                case 'error':
                  icon = IconName.dangerous;
                  color = theme.color.text.danger;
                  break;
                case 'warning':
                  icon = IconName.warning;
                  color = theme.color.text.warning;
                  break;
                case 'success':
                  icon = IconName.check_circle;
                  color = theme.color.text.success;
                  break;
              }
              return (
                <TableInnerCellContainer variant={TableInnerCellContainerVariants.centeredFlex} padding={buildPadding({ x: Spacing.s })}>
                  <Icon
                    name={icon}
                    color={color}
                    tooltip={parsingResult.type === 'error' ? parsingResult.message : undefined}
                  />
                </TableInnerCellContainer>
              );
            },
          },
          {
            propertyId: 'returnType',
            name: i18n`Return type`,
            sortable: true,
            cellRender: ({ parsingResult }) => (
              <TextInputString
                value={parsingResult.type === 'error' ? parsingResult.message : parsingResult.returnType.name}
                textVariant={FontVariant.code}
                color={parsingResult.type === 'success' ? undefined : theme.color.text.secondary}
                dropdownMaxLine={20}
                readOnly
              />
            ),
          },
          {
            propertyId: 'code',
            name: i18n`Code`,
            width: 10,
            cellRender: ({ parsingResult }) => (
              <TextInputString
                value={parsingResult.type === 'error' ? undefined : parsingResult.code}
                textVariant={FontVariant.code}
                readOnly
              />
            ),
          },
        ]}
        pagination={pagination}
        sortCriteria={sortCriteria}
        doSort={doSort}
        linesActions={({ key, fieldChip: { getNavigationPayload } }) => [
          {
            key: 'openInExplorer',
            icon: IconName.output,
            name: i18n`Open in explorer`,
            onClick: () => navigation.push(key, { pathname: `/settings/explorer/instance/${key}` }),
          },
          {
            key: 'openInApp',
            icon: IconName.output,
            name: i18n`Open in application`,
            onClick: () => {
              if (getNavigationPayload) {
                const navigationPayload = getNavigationPayload(navigation);
                navigation.push(key, { pathname: typeof navigationPayload.to === 'string' ? navigationPayload.to : navigationPayload.to.pathname });
              }
            },
          },
        ]}
      />
    </VerticalBlock>
  );
};
export default ExplorerHomeComputingsTab;
