import type { FunctionComponent } from 'react';
import type { WorkflowStoreObject } from 'yooi-modules/modules/conceptModule';
import { Workflow, Workflow_Name } from 'yooi-modules/modules/conceptModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { IconName } from '../../../components/atoms/Icon';
import IconOnlyButton from '../../../components/atoms/IconOnlyButton';
import { TableSortDirection } from '../../../components/molecules/Table';
import BaseLayout from '../../../components/templates/BaseLayout';
import BlockContent from '../../../components/templates/BlockContent';
import DataTable from '../../../components/templates/DataTable';
import Header from '../../../components/templates/Header';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { safeSessionStorageValue } from '../../../utils/sessionStorageUtils';
import useNavigation from '../../../utils/useNavigation';
import useNewLineFocus from '../../../utils/useNewLineFocus';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import SearchTextButton from '../../_global/filter/SearchTextButton';
import type { FilterConfiguration } from '../../_global/filter/useFilterSessionStorage';
import HeaderStatic from '../../_global/HeaderStatic';
import StoreTextInputField from '../../_global/input/StoreTextInputField';
import { searchFilterFunction } from '../../_global/listFilterFunctions';
import type { NavigationFilter } from '../../_global/navigationUtils';
import { getNavigationPayload } from '../../_global/navigationUtils';
import { share } from '../../_global/shareUtils';
import TopBar from '../../_global/topBar/TopBar';
import useFilterAndSort, { buildStringColumnComparatorHandler } from '../../_global/useFilterAndSort';
import { duplicateWorkflow } from './workflowUtils';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: spacingRem.s,
    paddingBottom: spacingRem.xs,
  },
}, 'workflowListPage');

const WorkflowListPage: FunctionComponent = () => {
  const classes = useStyles();

  const store = useStore();

  const navigation = useNavigation<NavigationFilter>();

  const [newLineFocus, setNewLineFocus] = useNewLineFocus();
  const { generatePageList, doSort, sortCriteria, forceShowId, forceFollowingIds } = useFilterAndSort(
    Workflow,
    store.getObject(Workflow).navigateBack<WorkflowStoreObject>(Class_Instances),
    searchFilterFunction(store, safeSessionStorageValue<FilterConfiguration | undefined>(Workflow)?.nameSearch, [Workflow_Name]),
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case Workflow_Name:
            return buildStringColumnComparatorHandler(key, direction);
          default:
            return undefined;
        }
      },
      initial: { key: Workflow_Name, direction: TableSortDirection.asc },
    }
  );
  const { list, pagination } = generatePageList(25);

  const createWorkflow = () => {
    const workflowId = store.createObject({ [Instance_Of]: Workflow });
    forceShowId(workflowId);
    setNewLineFocus(workflowId);
  };

  return (
    <BaseLayout
      topBar={(<TopBar />)}
      header={(
        <Header
          firstLine={(
            <HeaderStatic
              text={i18n`Workflows`}
              newItem={(
                <IconOnlyButton
                  iconName={IconName.add}
                  tooltip={i18n`Create`}
                  onClick={createWorkflow}
                />
              )}
            />
          )}
        />
      )}
      content={(
        <VerticalBlock compact>
          <BlockContent padded>
            <div className={classes.headerContainer}>
              <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
                <SearchTextButton element={Workflow} placeholder={i18n`Search`} />
              </SizeContextProvider>
            </div>
          </BlockContent>
          <DataTable
            getNavigationPayload={({ id }) => getNavigationPayload(navigation, id, `/settings/workflow/${id}`)}
            doSort={doSort}
            sortCriteria={sortCriteria}
            columnsDefinition={[
              {
                propertyId: Workflow_Name,
                name: i18n`Name`,
                sortable: true,
                focusable: true,
                cellRender: ({ id, [Workflow_Name]: name }, focusOnMount) => (
                  <StoreTextInputField
                    initialValue={name}
                    focusOnMount={focusOnMount}
                    onSubmit={(newName) => {
                      store.updateObject(id, { [Workflow_Name]: newName });
                    }}
                  />
                ),
                openButton: () => true,
              },
            ]}
            linesActions={({ id }) => [
              {
                key: 'share',
                icon: IconName.link,
                name: i18n`Copy link`,
                onClick: () => share(store, getNavigationPayload(navigation, id, `/settings/workflow/${id}`)),
              },
              {
                key: 'duplicate',
                name: i18n`Duplicate`,
                icon: IconName.content_copy_outline,
                onClick: () => {
                  const duplicateWorkflowId = duplicateWorkflow(store, id);
                  forceFollowingIds(id, duplicateWorkflowId);
                  setNewLineFocus(duplicateWorkflowId);
                },
              },
              {
                key: 'delete',
                name: i18n`Delete`,
                icon: IconName.delete,
                onClick: () => {
                  store.deleteObject(id);
                },
                danger: true,
              },
            ]}
            list={list}
            pagination={pagination}
            newItemIcon={IconName.add}
            newItemTitle={i18n`Create`}
            onNewItem={createWorkflow}
            newLineFocus={newLineFocus.current}
          />
        </VerticalBlock>
      )}
    />
  );
};

export default WorkflowListPage;
