import type { FunctionComponent } from 'react';
import { DndProvider } from 'react-dnd';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { SwimlaneViewStoredDefinition, ViewDimension, WidgetStoreObject } from 'yooi-modules/modules/dashboardModule';
import { Widget_DisplayMode } from 'yooi-modules/modules/dashboardModule/ids';
import ScrollableWidgetContainer from '../../../../components/templates/ScrollableWidgetContainer';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { Spacing, spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import { remToPx, SCROLLBAR_WIDTH_IN_REM } from '../../../../utils/sizeUtils';
import useDeepMemo from '../../../../utils/useDeepMemo';
import { SessionStorageKeys } from '../../../../utils/useSessionStorage';
import { dndManager } from '../../fields/_global/dndUtils';
import WidgetInvalidConfiguration from '../../fields/_global/WidgetInvalidConfiguration';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { useViewFilters } from '../../filter/useViewFilters';
import { getViewDefinitionHandler } from '../viewDsl';
import { isResolutionError } from '../viewResolutionUtils';
import ViewConceptSwimlane from './internal/ViewConceptSwimlane';
import type { SwimlaneViewDefinitionHandler } from './swimlaneViewDefinitionHandler';

const useStyles = makeStyles({
  swimlaneContainer: {
    display: 'grid',
    gridAutoRows: 'min-content',
    rowGap: spacingRem.blockSpacing,
    gridTemplateColumns: `${spacingRem.blockLeftColumnSpacing} 1fr ${spacingRem.blockRightColumnSpacing}`,
  },
}, 'swimlaneViewWidget');

interface SwimlaneViewWidgetProps {
  widgetId: string,
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinition: SwimlaneViewStoredDefinition,
  parametersMapping: ParametersMapping,
  width: number,
  height: number,
  readOnly: boolean | undefined,
}

const SwimlaneViewWidget: FunctionComponent<SwimlaneViewWidgetProps> = ({
  widgetId,
  viewDimensions,
  viewFilters,
  viewDefinition,
  parametersMapping,
  width,
  height,
  readOnly,
}) => {
  const classes = useStyles();

  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();

  const viewHandler = getViewDefinitionHandler(viewDefinition) as SwimlaneViewDefinitionHandler;
  const viewResolvedDefinition = viewHandler.getDefinition(store, viewDimensions);
  const filterConfiguration = useViewFilters(viewFilters, viewDimensions);
  const viewResolution = useDeepMemo(
    () => viewHandler.resolveView(store, { viewDimensions, aclHandler, parametersMapping, userId: loggedUserId, filterConfiguration, readOnly }),
    [store.getSerial(), viewDimensions, viewDefinition, parametersMapping, filterConfiguration]
  );

  const isBorderless = store.getObject<WidgetStoreObject>(widgetId)[Widget_DisplayMode]?.type === 'borderless';

  const { filterKey } = viewFilters;
  const configurationKey = `${SessionStorageKeys.swimlaneConfig}_${viewDefinition.id}`;
  if (isResolutionError(viewResolution)) {
    return <WidgetInvalidConfiguration width={width} height={height} reason={viewResolution.error} />;
  } else {
    return (
      <ScrollableWidgetContainer asContent width={width} height={height}>
        <div className={classes.swimlaneContainer}>
          <DndProvider manager={dndManager}>
            <ViewConceptSwimlane
              viewFilters={viewFilters}
              viewDefinition={viewResolvedDefinition}
              viewResolution={viewResolution}
              viewDimensions={viewDimensions}
              parametersMapping={parametersMapping}
              readOnly={readOnly || viewDefinition.readOnly}
              configurationKey={configurationKey}
              filterKey={filterKey}
              width={width - remToPx(SCROLLBAR_WIDTH_IN_REM) - (isBorderless ? 0 : (2 * remToPx(spacingRem[Spacing.splus]) /* widget padding */))}
              height={height - remToPx(SCROLLBAR_WIDTH_IN_REM)}
            />
          </DndProvider>
        </div>
      </ScrollableWidgetContainer>
    );
  }
};

export default SwimlaneViewWidget;
