import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useRef } from 'react';
import { useDrop } from 'react-dnd';
import makeStyles from '../../../../../utils/makeStyles';
import { remToPx } from '../../../../../utils/sizeUtils';
import SwimlaneConstants from './SwimlaneConstants';

interface DroppableZoneProps {
  labelWidthPx: number,
  svgHeightPx: number,
  type: string,
  onDrop: (id: string) => void,
  transform: string,
  attachedConceptId?: string,
  border?: boolean,
  canDrop: (id: string) => boolean,
}

const useStyles = makeStyles((theme) => ({
  dropContainer: {
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.transparent,
  },
  dropContainerOver: {
    borderBottomColor: theme.color.border.info,
  },
  dropLine: {
    position: 'relative',
    height: '0.1rem',
    backgroundColor: theme.color.transparent,
  },
  dropLineOver: {
    backgroundColor: theme.color.border.info,
  },
}), 'droppableZone');

const DroppableZone: FunctionComponent<DroppableZoneProps> = ({ labelWidthPx, svgHeightPx, type, onDrop, transform, attachedConceptId, border, canDrop }) => {
  const classes = useStyles();

  const hoveredConceptId = useRef({});
  const [{ isOver, canDropItem }, dropRef] = useDrop(() => ({
    accept: type,
    drop: (item: { conceptId: string }) => {
      const { conceptId } = item;
      if (onDrop) {
        onDrop(conceptId);
      }
    },
    hover: (item: { conceptId: string }) => {
      const { conceptId } = item;
      hoveredConceptId.current = conceptId;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDropItem: monitor.canDrop(),
    }),
    canDrop: (item) => {
      const { conceptId } = item;
      return canDrop(conceptId);
    },
  }), [onDrop, canDrop]);

  const isOverActive = isOver && (attachedConceptId && attachedConceptId !== hoveredConceptId.current) && canDropItem;

  return (
    <foreignObject
      transform={transform}
      width={labelWidthPx}
      height={svgHeightPx}
    >
      <div
        ref={dropRef}
        className={classnames({
          [classes.dropContainer]: true,
          [classes.dropContainerOver]: border && isOverActive,
        })}
        style={{ width: labelWidthPx, height: svgHeightPx }}
      >
        {!border && (
          <div
            className={classnames({
              [classes.dropLine]: true,
              [classes.dropLineOver]: isOverActive,
            })}
            style={{ width: labelWidthPx, top: svgHeightPx - remToPx(SwimlaneConstants.conceptGapRem / 2) }}
          />
        )}
      </div>
    </foreignObject>
  );
};

export default DroppableZone;
