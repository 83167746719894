import type { FocusEvent, MutableRefObject } from 'react';
import { useCallback, useMemo, useRef } from 'react';

interface SelectionRange<T extends HTMLElement> {
  captureAncestorRef: MutableRefObject<T | null>,
  captureSelectionRange: () => void,
  restoreSelectionRange: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
}

const useSelectionRange = <T extends HTMLElement>(): SelectionRange<T> => {
  const captureAncestorRef = useRef<T>(null);
  const selectionOffsetRef = useRef<{ offsetStart: number, offsetEnd: number }>();

  const captureSelectionRange = useCallback(() => {
    const selection = window.getSelection();
    if (!selection || !captureAncestorRef.current) {
      return;
    }
    if (!(selection.rangeCount > 0)) {
      return;
    }
    const selectionRange = selection.getRangeAt(0);

    let offsetStart = -1;
    let offsetEnd = -1;
    let count = 0;

    const analyzeContainer = (c: Node) => {
      if (c === selectionRange.startContainer) {
        offsetStart = count + selectionRange.startOffset;
      }
      if (c === selectionRange.endContainer) {
        offsetEnd = count + selectionRange.endOffset;
      }
      const children = c.childNodes;
      for (let i = 0; i < children.length; i += 1) {
        const child = children.item(i);
        analyzeContainer(child);
        if (child.nodeName === '#text' && child.textContent !== null) {
          count += child.textContent.length;
        }
      }
    };

    analyzeContainer(captureAncestorRef.current);

    if (offsetStart !== -1 && offsetEnd !== -1) {
      selectionOffsetRef.current = { offsetStart, offsetEnd };
    } else {
      selectionOffsetRef.current = undefined;
    }
  }, []);

  const restoreSelectionRange = useCallback((event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (selectionOffsetRef.current) {
      const { offsetStart, offsetEnd } = selectionOffsetRef.current;
      event.target.setSelectionRange(offsetStart, offsetEnd);
      selectionOffsetRef.current = undefined;
    } else {
      // in keyboard mode, let restore the focus at the end of the input
      event.target.setSelectionRange(event.target.value.length, event.target.value.length);
    }
  }, []);

  return useMemo(() => ({ captureAncestorRef, captureSelectionRange, restoreSelectionRange }), [captureAncestorRef, captureSelectionRange, restoreSelectionRange]);
};

export default useSelectionRange;
