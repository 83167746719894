import { equals } from 'ramda';
import { v4 as uuid } from 'uuid';
import type { AccessControlListResult, ObjectStoreReadOnly } from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import { isArrayOfType, isObjectOfType } from 'yooi-utils';
import { asImport, CommonAsType } from '../common/fields/commonPropertyType';
import type { BusinessRuleRegistration } from '../common/types/TypeModuleDslType';
import type { PathStep } from '../conceptModule';
import { adminOnlyAcl, InstanceReferenceType, PathStepType } from '../conceptModule';
import { User } from '../conceptModule/ids';
import { Instance_Of, ModelType } from '../typeModule/ids';
import {
  AutomationAction,
  AutomationAction_Rule,
  AutomationActionDefinition,
  AutomationActionDefinition_Name,
  AutomationActionEmail_HasCustomPermissions,
  AutomationActionEmail_Permissions,
  AutomationActionEmail_Recipients,
  AutomationActionGenerateData_Operations,
  AutomationRule,
  AutomationRule_Name,
  AutomationRule_UserId,
} from './ids';
import type { AutomationActionStoreObject, AutomationRuleStoreObject } from './modelTypes';
import { registerModel } from './module';
import type { AutomationEmailActionRecipient } from './moduleType';
import { InstanceOperationPropertyMap } from './moduleType';

const { type } = registerModel;

const aclContextChangeOnPersonalRules: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  const ruleId = (properties?.[AutomationAction_Rule] ?? getObjectOrNull(id)?.[AutomationAction_Rule]) as string | undefined;
  const rule = ruleId ? getObjectOrNull(ruleId) : null;
  if (!rule) {
    return undefined;
  }
  // Creation ? check AclContext matches personal rule
  const currentObject = getObjectOrNull(id);
  const recipients = properties?.[AutomationActionEmail_Recipients] as AutomationEmailActionRecipient[] | undefined;
  if (!currentObject) {
    const personalRuleUserId = rule[AutomationRule_UserId] as string | undefined;
    if (
      personalRuleUserId
      && (
        !Array.isArray(recipients)
        || recipients.length !== 1
        || recipients[0].type !== 'path'
        || !equals<PathStep[]>(
          recipients[0].value,
          [{ type: PathStepType.dimension, conceptDefinitionId: User }, { type: PathStepType.mapping, mapping: { id: personalRuleUserId, type: InstanceReferenceType.instance } }]
        )
      )
    ) {
      return { rule: 'automationAction.aclContext.aclContextInvalid.rejected', status: ValidationStatus.REJECTED };
    }
  }
  // Update ? prevent update
  if (currentObject && rule.navigateOrNull(AutomationRule_UserId)) {
    if (recipients !== undefined) {
      return { rule: 'automationAction.aclContext.cannotUpdateOnPersonalRule.recipients.rejected', status: ValidationStatus.REJECTED };
    }
    if (properties?.[AutomationActionEmail_HasCustomPermissions] !== undefined) {
      return { rule: 'automationAction.aclContext.cannotUpdateOnPersonalRule.hasCustomPermissions.rejected', status: ValidationStatus.REJECTED };
    }
    if (properties?.[AutomationActionEmail_Permissions] !== undefined) {
      return { rule: 'automationAction.aclContext.cannotUpdateOnPersonalRule.permissions.rejected', status: ValidationStatus.REJECTED };
    }
  }

  return undefined;
};

const disableAutomationActionGenerateDataOnPersonalRule: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  const ruleId = (properties?.[AutomationAction_Rule] ?? getObjectOrNull(id)?.[AutomationAction_Rule]) as string | undefined;
  const rule = ruleId ? getObjectOrNull(ruleId) : null;
  if (!rule) {
    return undefined;
  }

  // Creation ? check rule is not a personal one
  const personalRuleUserId = rule[AutomationRule_UserId];
  if (personalRuleUserId) {
    return { rule: 'automationAction.generateData.disabledOnPersonalRule.rejected', status: ValidationStatus.REJECTED };
  }

  return undefined;
};

const automationActionGenerateDataInit: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  const action = getObjectOrNull(id);
  if (action || !properties) {
    return undefined;
  }
  if (properties[AutomationActionGenerateData_Operations] !== undefined) {
    return undefined;
  }
  return {
    rule: 'automation.actionGenerateData.init',
    status: ValidationStatus.ACCEPTED,
    generateSystemEvent: ({ updateObject }) => {
      updateObject(id, {
        [AutomationActionGenerateData_Operations]: [{
          id: uuid(),
          type: 'update',
          path: [],
          fieldOperations: [{ id: uuid() }],
        }],
      });
    },
  };
};

const automationActionValidity: BusinessRuleRegistration = () => (_, { properties }) => {
  if (!properties) {
    return undefined;
  }
  const newOperations = properties[AutomationActionGenerateData_Operations];
  if (!newOperations) {
    return undefined;
  }
  if (!isArrayOfType(isObjectOfType(InstanceOperationPropertyMap))(newOperations)) {
    return { rule: 'automationAction.generateData.invalidOperationType', status: ValidationStatus.REJECTED };
  } else if (new Set(newOperations.map(({ id: operationId }) => operationId)).size !== newOperations.length) {
    return { rule: 'automationAction.generateData.invalidOperationType.duplicatedOperationsIds', status: ValidationStatus.REJECTED };
  } else if (newOperations.some((operation) => operation.fieldOperations
    && new Set(operation.fieldOperations.map(({ id: operationId }) => operationId)).size !== operation.fieldOperations.length)) {
    return { rule: 'automationAction.generateData.invalidOperationType.duplicatedFieldOperationsIds', status: ValidationStatus.REJECTED };
  } else {
    return undefined;
  }
};

type({
  label: 'AutomationActionDefinition',
  extends: ModelType,
  objectDebugLabel: ({ getObjectOrNull }) => (objectId) => getObjectOrNull(objectId)?.[AutomationActionDefinition_Name] as string,
})
  .property({ label: 'AutomationActionDefinition_Name', as: CommonAsType.string });

type({
  label: 'AutomationAction',
  accessControlList: {
    READ: (store) => ({ userId }, objectId) => {
      const actionRuleId = store.getObjectOrNull<AutomationActionStoreObject>(objectId[0])?.[AutomationAction_Rule];
      if (actionRuleId) {
        return { rule: 'automationAction.read.delegate', status: ValidationStatus.DELEGATED, targetAction: 'READ', targetId: [actionRuleId] };
      } else {
        return adminOnlyAcl(store, userId, 'automationAction', 'READ');
      }
    },
    WRITE: (store) => ({ userId }, objectId, { newProperties }) => {
      if (newProperties?.[Instance_Of]) { // creation
        const actionRuleId = newProperties[AutomationAction_Rule] as string | undefined;
        if (actionRuleId) {
          return { rule: 'automationAction.read.delegate', status: ValidationStatus.DELEGATED, targetAction: 'READ', targetId: [actionRuleId] };
        } else {
          return adminOnlyAcl(store, userId, 'automationAction', 'WRITE');
        }
      } else {
        return { rule: 'automationAction.write.delegate', status: ValidationStatus.DELEGATED, targetAction: 'READ', targetId: objectId };
      }
    },
    DELETE: () => (_, objectId) => ({ rule: 'automationAction.delete.delegate', status: ValidationStatus.DELEGATED, targetAction: 'READ', targetId: objectId }),
  },
})
  .relation({ label: 'AutomationAction_Rule', targetTypeId: AutomationRule, reverseLabel: 'AutomationRule_Actions' })
  .property({ label: 'AutomationAction_Query', as: asImport('AutomationActionQueryRecord', 'modules/automationModule/moduleType') });

const hasAutomationRuleCapability = (store: ObjectStoreReadOnly, personalRuleUserId: string | undefined, userId: string | undefined, action: string): AccessControlListResult => {
  if (personalRuleUserId && personalRuleUserId === userId) {
    return { rule: `automationRule.${action}.allow`, status: ValidationStatus.ACCEPTED };
  } else if (personalRuleUserId && personalRuleUserId !== userId) {
    return { rule: `automationRule.${action}.personalRuleNoAccess`, status: ValidationStatus.REJECTED };
  } else {
    return adminOnlyAcl(store, userId, 'automationRule', action);
  }
};

type({
  label: 'AutomationRule',
  objectDebugLabel: ({ getObjectOrNull }) => (objectId) => getObjectOrNull(objectId)?.[AutomationRule_Name] as string,
  accessControlList: {
    READ: (store) => ({ userId }, objectId) => {
      const personalRuleUserId = store.getObjectOrNull<AutomationRuleStoreObject>(objectId[0])?.[AutomationRule_UserId];
      return hasAutomationRuleCapability(store, personalRuleUserId, userId, 'READ');
    },
    WRITE: (store) => ({ userId }, objectId, { newProperties }) => {
      if (newProperties?.[Instance_Of]) { // creation
        const personalRuleUserId = newProperties[AutomationRule_UserId] as string | undefined;
        return hasAutomationRuleCapability(store, personalRuleUserId, userId, 'WRITE');
      } else {
        const personalRuleUserId = store.getObjectOrNull(objectId)?.[AutomationRule_UserId] as string | undefined;
        return hasAutomationRuleCapability(store, personalRuleUserId, userId, 'WRITE');
      }
    },
    DELETE: (store) => ({ userId }, objectId) => {
      const personalRuleUserId = store.getObjectOrNull(objectId)?.[AutomationRule_UserId] as string | undefined;
      return hasAutomationRuleCapability(store, personalRuleUserId, userId, 'DELETE');
    },
  },
})
  .property({ label: 'AutomationRule_Name', as: CommonAsType.string })
  .property({ label: 'AutomationRule_IsEnabled', as: CommonAsType.boolean })
  .property({ label: 'AutomationRule_Description', as: CommonAsType.string })
  .property({ label: 'AutomationRule_Trigger', as: asImport('AutomationRuleTrigger', 'modules/automationModule/triggers/triggerHandler') })
  .property({ label: 'AutomationRule_Parameters', as: asImport('AutomationRuleParameters', 'modules/automationModule/moduleType') })
  .relation({ label: 'AutomationRule_UserId', targetTypeId: User, reverseLabel: 'User_AutomationRules' });

type({
  label: 'AutomationActionEmail',
  instanceOf: AutomationActionDefinition,
  extends: AutomationAction,
  extraProperties: {
    [AutomationActionDefinition_Name]: 'Email',
  },
  businessRules: [aclContextChangeOnPersonalRules],
})
  .property({ label: 'AutomationActionEmail_Subject', as: CommonAsType.string })
  .property({ label: 'AutomationActionEmail_Body', as: CommonAsType.string })
  .property({ label: 'AutomationActionEmail_Recipients', as: asImport('AutomationEmailActionRecipient', 'modules/automationModule/moduleType', true) })
  .property({ label: 'AutomationActionEmail_HasCustomPermissions', as: CommonAsType.boolean })
  .property({ label: 'AutomationActionEmail_Permissions', as: asImport('PathStep', 'modules/conceptModule', true) });

type({
  label: 'AutomationActionGenerateData',
  instanceOf: AutomationActionDefinition,
  extends: AutomationAction,
  extraProperties: {
    [AutomationActionDefinition_Name]: 'Generate data',
  },
  businessRules: [disableAutomationActionGenerateDataOnPersonalRule, automationActionGenerateDataInit, automationActionValidity],
})
  .property({ label: 'AutomationActionGenerateData_Operations', as: asImport('InstanceOperation', 'modules/automationModule/moduleType', true) });
