import type { FunctionComponent } from 'react';
import { getConceptUrl, getFieldDimensionOfModelType, getKinshipFieldId, getParentConceptInstance } from 'yooi-modules/modules/conceptModule';
import { Concept_Name, ConceptDefinition_Icon, Resource_Concepts } from 'yooi-modules/modules/conceptModule/ids';
import {
  DataAsset,
  DataAsset_Type,
  DataAssetTypeResourceTypes,
  DataAssetTypeResourceTypes_Role_DataAssetType,
  DataAssetTypeResourceTypes_Role_ResourceType,
} from 'yooi-modules/modules/dataAssetModule/ids';
import { Resource, Resource_Image, Resource_Type, Resource_URL, ResourceType_Name } from 'yooi-modules/modules/resourceModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import BaseLayout from '../../../components/templates/BaseLayout';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import Header from '../../../components/templates/Header';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useAcl from '../../../store/useAcl';
import useActivity from '../../../store/useActivity';
import useStore from '../../../store/useStore';
import useUpdateActivity from '../../../store/useUpdateActivity';
import i18n from '../../../utils/i18n';
import { notifyError } from '../../../utils/notify';
import useNavigation from '../../../utils/useNavigation';
import { getFieldHandler } from '../../_global/fields/FieldLibrary';
import HeaderFromStore, { HeaderFromStoreVariant } from '../../_global/HeaderFromStore';
import { getLastUpdateBy } from '../../_global/historyUtils';
import StoreURLInput from '../../_global/input/StoreURLInput';
import { defaultOptionComparator, getChipOptions, getConceptDefinitionNameOrEntity, getSearchChipOptions } from '../../_global/modelTypeUtils';
import ActivityBar from '../../_global/multiplayer/ActivityBar';
import type { NavigationFilter } from '../../_global/navigationUtils';
import { getUrlMetadata, updateResourceURL } from '../../_global/resourceUtils';
import CollaborationButton from '../../_global/rightPanel/collaboration/CollaborationButton';
import CollaborationRightPanel from '../../_global/rightPanel/collaboration/CollaborationRightPanel';
import { getIntentions } from '../../_global/rightPanel/collaboration/utils/collaborationUtils';
import TopBar from '../../_global/topBar/TopBar';

interface ResourceDetailPageProps {
  resourceId: string,
}

const ResourceDetailPage: FunctionComponent<ResourceDetailPageProps> = ({ resourceId }) => {
  const store = useStore();
  const { canWriteObject } = useAcl();

  const activity = useActivity();
  const updateActivity = useUpdateActivity();

  const navigation = useNavigation<NavigationFilter>();

  const resource = store.getObject(resourceId);
  const kinshipFieldId = getKinshipFieldId(resource);
  const dataAsset = getParentConceptInstance(resource);
  const resourceMetadata = resource[Resource_URL] ? getUrlMetadata(resource[Resource_URL] as string) : undefined;
  const readOnly = !canWriteObject(resourceId);

  const imageFieldHandler = getFieldHandler(store, Resource_Image);
  const imageFieldDimensionId = getFieldDimensionOfModelType(store, Resource_Image, Resource);
  const conceptsFieldHandler = getFieldHandler(store, Resource_Concepts);
  const conceptsFieldDimensionId = getFieldDimensionOfModelType(store, Resource_Concepts, Resource);

  const resourceType = resource.navigateOrNull(Resource_Type);

  return (
    <BaseLayout
      topBar={(<TopBar actions={(<ActivityBar objectId={resourceId} />)} />)}
      header={(
        <Header
          firstLine={(
            <HeaderFromStore
              instanceId={resourceId}
              propertyId={Concept_Name}
              placeholder={i18n`Click to edit name`}
              restingTooltip={() => getLastUpdateBy(store, resourceId, Concept_Name, undefined)}
              actions={getIntentions(store, Resource).length > 0 ? [(<CollaborationButton key="collabHeaderButton" />)] : undefined}
              isRich
              variant={HeaderFromStoreVariant.compact}
            />
          )}
          withBottomBorder
        />
      )}
      content={(
        <VerticalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle title={i18n`URL`} iconName={IconName.image} />
            <BlockContent>
              <StoreURLInput
                initialValue={resource[Resource_URL] as string | undefined}
                onSubmit={(url) => {
                  const error = updateResourceURL(store, resourceId, url);
                  if (error) {
                    notifyError(error);
                  }
                }}
                onEditionStart={() => updateActivity.onEnterEdition(resource.id, Resource_URL)}
                onEditionStop={() => updateActivity.onExitEdition(resource.id, Resource_URL)}
                readOnly={readOnly}
                key={Resource_URL}
                href={resourceMetadata?.generateUrl?.()}
                type={resourceMetadata?.type}
                isEditing={activity.listEditor(resource.id, Resource_URL).length > 0}
              />
            </BlockContent>
          </HorizontalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle title={i18n`Type`} iconName={IconName.dataset} />
            <BlockContent>
              <SearchAndSelect
                clearable
                placeholder={i18n`Add type`}
                computeOptions={() => (dataAsset ? store.withAssociation(DataAssetTypeResourceTypes)
                  .withRole(DataAssetTypeResourceTypes_Role_DataAssetType, dataAsset[DataAsset_Type] as string)
                  .list()
                  .map((assoc) => assoc.navigateRole(DataAssetTypeResourceTypes_Role_ResourceType))
                  .filter((type, index, arr) => arr.indexOf(type) === index) : [])
                  .map(({ id }) => getChipOptions(store, id))
                  .filter(filterNullOrUndefined)
                  .sort(defaultOptionComparator)}
                selectedOption={resourceType ? getChipOptions(store, resourceType.id) : undefined}
                searchOptions={{
                  searchKeys: [ResourceType_Name],
                  extractValue: ({ object }, searchKey) => {
                    const value = object[searchKey];
                    return typeof value === 'string' ? value : undefined;
                  },
                }}
                onSelect={(option) => store.updateObject(resource.id, { [Resource_Type]: option?.id ?? null })}
                onEditionStart={() => updateActivity.onEnterEdition(resource.id, Resource_Type)}
                onEditionStop={() => updateActivity.onExitEdition(resource.id, Resource_Type)}
                isEditing={activity.listEditor(resource.id, Resource_Type).length > 0}
                readOnly={readOnly}
              />
            </BlockContent>
          </HorizontalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle title={i18n`Image`} iconName={IconName.image} />
            <BlockContent>
              {imageFieldDimensionId && imageFieldHandler?.renderField?.({ dimensionsMapping: { [imageFieldDimensionId]: resourceId }, readOnly })}
            </BlockContent>
          </HorizontalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle title={i18n`Used in References`} iconName={IconName.description} />
            <BlockContent>
              {conceptsFieldDimensionId && conceptsFieldHandler?.renderField?.({ dimensionsMapping: { [conceptsFieldDimensionId]: resourceId }, readOnly })}
            </BlockContent>
          </HorizontalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle title={getConceptDefinitionNameOrEntity(store, DataAsset)} iconName={store.getObject(DataAsset)[ConceptDefinition_Icon] as IconName} />
            <BlockContent>
              <SearchAndSelect
                computeOptions={() => store.getObject(DataAsset)
                  .navigateBack(Class_Instances)
                  .map((d) => getChipOptions(store, d.id))
                  .filter(filterNullOrUndefined)
                  .sort(defaultOptionComparator)}
                selectedOption={dataAsset ? getChipOptions(store, dataAsset.id) : undefined}
                searchOptions={getSearchChipOptions(store, DataAsset)}
                onSelect={(result) => {
                  if (result) {
                    store.updateObject(resource.id, { [kinshipFieldId]: result.id });
                    navigation.replace(resourceId, { pathname: getConceptUrl(store, resourceId) });
                  }
                }}
                onEditionStart={() => updateActivity.onEnterEdition(resource.id, kinshipFieldId)}
                onEditionStop={() => updateActivity.onExitEdition(resource.id, kinshipFieldId)}
                withMultiplayerOutline
                readOnly={readOnly}
              />
            </BlockContent>
          </HorizontalBlock>
        </VerticalBlock>
      )}
      rightPanel={(<CollaborationRightPanel />)}
    />
  );
};

export default ResourceDetailPage;
