import type { FunctionComponent } from 'react';
import type { ConceptDefinitionLibraryTableStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import { LibraryDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import {
  ConceptDefinitionLibraryTable,
  ConceptDefinitionLibraryTable_Rank,
  ConceptDefinitionLibraryTable_Role_ConceptDefinition,
  ConceptDefinitionLibraryTable_Role_Field,
  ConceptDefinitionLibraryTable_Width,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import { getFieldDimensionOfModelType } from 'yooi-modules/modules/conceptModule';
import { ConceptDefinition_LibraryItemPerPage, ConceptDefinition_TableGroupBy, StakeholdersField } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { StoreObject } from 'yooi-store';
import { compareRank, extractAndCompareValue, filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { IconName } from '../../components/atoms/Icon';
import Typo from '../../components/atoms/Typo';
import SearchAndSelect from '../../components/molecules/SearchAndSelect';
import type { TableSortDirection } from '../../components/molecules/Table';
import BlockContent from '../../components/templates/BlockContent';
import type { ColumnDefinition, ItemEntry } from '../../components/templates/DataTable';
import type { Pagination } from '../../components/templates/PageSelector';
import VerticalBlock from '../../components/templates/VerticalBlock';
import useAcl from '../../store/useAcl';
import type { FrontObjectStore } from '../../store/useStore';
import useStore from '../../store/useStore';
import { spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { useFocusNewLineNotify } from '../../utils/useNewLineFocus';
import { SessionStorageKeys, useSessionStorageState } from '../../utils/useSessionStorage';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../utils/useUsageContext';
import ConceptListExportButton from '../_global/ConceptListExportButton';
import { DisplayedLine } from '../_global/ConceptViewTopBar';
import ConceptTable from '../_global/fields/_global/ConceptTable';
import { getFieldHandler } from '../_global/fields/FieldLibrary';
import { ConceptDefinitionFavoriteFiltersBar } from '../_global/filter/FavoriteFiltersBar';
import type { FilterConfiguration } from '../_global/filter/useFilterSessionStorage';
import { getConceptFilters } from '../_global/listFilterFunctions';
import { getChipOptions, getTableGroupByField, listGroupByFieldOptions } from '../_global/modelTypeUtils';
import type { TableConfiguration } from '../_global/sessionStorageTypes';
import type { UseFilterAndSortReturnType } from '../_global/useFilterAndSort';
import FirstClassConceptCardList from './FirstClassConceptCardList';

const useStyles = makeStyles({
  configContainer: {
    marginBottom: spacingRem.xs,
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.s,
    justifyContent: 'flex-end',
  },
  filtersContainer: {
    marginBottom: spacingRem.xs,
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.s,
  },
}, 'firstClassConceptList');

interface FirstClassConceptListProps {
  conceptDefinitionId: string,
  generateList: UseFilterAndSortReturnType<StoreObject>['generateList'],
  generatePageList: UseFilterAndSortReturnType<StoreObject>['generatePageList'],
  generateGroupedList: UseFilterAndSortReturnType<StoreObject>['generateGroupedList'],
  generateGroupedPageList: UseFilterAndSortReturnType<StoreObject>['generateGroupedPageList'],
  doSort: (propertyId: string) => void,
  sortCriteria?: { key: string, direction: TableSortDirection },
  forceFollowingIds: (fromId: string, toId: string) => void,
  forceShowId: (forceId: string) => void,
  filterId: string,
  libraryDisplayOption: LibraryDisplayOptions,
  displayedLine?: DisplayedLine,
}

const getConceptListFields = (store: FrontObjectStore, conceptDefinitionId: string): string[] => store.withAssociation(ConceptDefinitionLibraryTable)
  .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
  .list<ConceptDefinitionLibraryTableStoreObject>()
  .sort(extractAndCompareValue(({ object }) => object[ConceptDefinitionLibraryTable_Rank], compareRank))
  .map((conceptDefinitionLibraryTable) => conceptDefinitionLibraryTable.role(ConceptDefinitionLibraryTable_Role_Field))
  .filter((fieldId) => getFieldDimensionOfModelType(store, fieldId, conceptDefinitionId) && store.getObjectOrNull(fieldId) !== null
    && getFieldHandler(store, fieldId)?.getColumnDefinition);

const FirstClassConceptList: FunctionComponent<FirstClassConceptListProps> = ({
  conceptDefinitionId,
  generateList,
  generatePageList,
  generateGroupedList,
  generateGroupedPageList,
  doSort,
  sortCriteria,
  forceFollowingIds,
  forceShowId,
  filterId,
  libraryDisplayOption,
  displayedLine,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const { canCreateObject } = useAcl();

  const focusNewLineNotify = useFocusNewLineNotify();
  const showOptions = displayedLine === DisplayedLine.options;
  const showFilters = displayedLine === DisplayedLine.filters;

  const [filtersConfiguration] = useSessionStorageState<FilterConfiguration | undefined>(filterId, undefined);

  const conceptListFields = getConceptListFields(store, conceptDefinitionId);

  const columnsDefinition = conceptListFields.map((fieldId): ColumnDefinition<StoreObject> | undefined => {
    const dimensionId = getFieldDimensionOfModelType(store, fieldId, conceptDefinitionId);
    const fieldHandler = getFieldHandler(store, fieldId);
    const field = store.getObject(fieldId);
    if (fieldHandler?.getColumnDefinition && dimensionId) {
      const width: number | string | undefined = store.withAssociation(ConceptDefinitionLibraryTable)
        .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
        .withRole(ConceptDefinitionLibraryTable_Role_Field, fieldId)
        .getObject<ConceptDefinitionLibraryTableStoreObject>()[ConceptDefinitionLibraryTable_Width];
      return joinObjects(
        fieldHandler.getColumnDefinition(),
        {
          width: width === undefined ? fieldHandler?.estimatedColumnWidth?.(conceptDefinitionId) : width,
          cellRender: (instance: StoreObject, focusOnMount: boolean) => (
            fieldHandler.renderField?.({
              dimensionsMapping: isInstanceOf(field, StakeholdersField) ? { n1InstanceId: instance.id } : { [dimensionId]: instance.id },
              readOnly: false,
              focusOnMount,
            }) ?? null
          ),
        }
      );
    } else {
      return undefined;
    }
  })
    .filter(filterNullOrUndefined);

  const [tableConfig, , updateTableConfig] = useSessionStorageState<TableConfiguration | undefined>(`${SessionStorageKeys.tableConfig}_${conceptDefinitionId}`, undefined);

  const tableGroupByField = getTableGroupByField(store, conceptDefinitionId, tableConfig);

  const onCreateFirstClassConceptClick: (title?: string, values?: Record<string, unknown>) => string = () => {
    const objectId = store.createObject({ [Instance_Of]: conceptDefinitionId });
    focusNewLineNotify(objectId);
    return objectId;
  };

  let libraryComponent = null;
  if (libraryDisplayOption === LibraryDisplayOptions.table) {
    let list;
    let pagination;
    const itemPerPage = store.getObject(conceptDefinitionId)[ConceptDefinition_LibraryItemPerPage] as number | undefined;
    if (itemPerPage) {
      const pageList = generateGroupedPageList(itemPerPage);
      list = pageList.list;
      pagination = pageList.pagination;
    } else {
      list = generateGroupedList().list;
    }

    libraryComponent = (
      <ConceptTable
        conceptDefinitionId={conceptDefinitionId}
        columnsDefinition={columnsDefinition}
        list={list}
        doSort={doSort}
        newItemTitle={i18n`Create`}
        newItemIcon={IconName.add}
        onNewItem={canCreateObject(conceptDefinitionId) ? onCreateFirstClassConceptClick : undefined}
        sortCriteria={sortCriteria}
        forceFollowingIds={forceFollowingIds}
        forceShowId={forceShowId}
        pagination={pagination}
        navigationFilters={{ globalFilters: getConceptFilters(store, conceptDefinitionId, filtersConfiguration), globalParametersMapping: {} }}
      />
    );
  } else if (libraryDisplayOption === LibraryDisplayOptions.cardList) {
    let data: { list: ItemEntry<StoreObject>[], pagination?: Pagination | undefined };
    const itemPerPage = store.getObject(conceptDefinitionId)[ConceptDefinition_LibraryItemPerPage] as number | undefined;
    if (itemPerPage) {
      data = generatePageList(itemPerPage);
    } else {
      data = generateList();
    }
    libraryComponent = (
      <BlockContent padded>
        <FirstClassConceptCardList
          conceptDefinitionId={conceptDefinitionId}
          list={data.list.map(({ item }) => item)}
          forceFollowingIds={forceFollowingIds}
          onNewItem={canCreateObject(conceptDefinitionId) ? onCreateFirstClassConceptClick : undefined}
          pagination={data.pagination}
        />
      </BlockContent>
    );
  }

  return (
    <VerticalBlock asBlockContent compact>
      {showOptions && (
        <BlockContent padded>
          <UsageContextProvider usageVariant={UsageVariant.inForm}>
            <div className={classes.configContainer}>
              {libraryDisplayOption === LibraryDisplayOptions.table && (
                <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.inline}>
                  <Typo maxLine={1} color={theme.color.text.secondary}>{i18n`Group by`}</Typo>
                  <SearchAndSelect
                    clearable
                    computeOptions={() => listGroupByFieldOptions(store, conceptDefinitionId, true)}
                    selectedOption={tableGroupByField ? getChipOptions(store, tableGroupByField.id) : undefined}
                    onSelect={(value) => {
                      updateTableConfig({ [ConceptDefinition_TableGroupBy]: value?.id ?? null });
                    }}
                  />
                </SizeContextProvider>
              )}
              <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
                <ConceptListExportButton generateList={() => generateList().list.map(({ item }) => item)} typeId={conceptDefinitionId} displayedFieldsIds={conceptListFields} />
              </SizeContextProvider>
            </div>
          </UsageContextProvider>
        </BlockContent>
      )}
      {showFilters && (
        <BlockContent padded>
          <div className={classes.filtersContainer}>
            <ConceptDefinitionFavoriteFiltersBar filterKey={filterId} conceptDefinitionId={conceptDefinitionId} />
          </div>
        </BlockContent>
      )}
      {libraryComponent}
    </VerticalBlock>
  );
};

export default FirstClassConceptList;
