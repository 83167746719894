import type { FunctionComponent } from 'react';
import type { IconName } from '../../../../components/atoms/Icon';
import Icon from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import useTheme from '../../../../utils/useTheme';

interface ConceptTooltipTextProps {
  text: string,
  iconName?: IconName,
}

const ConceptTooltipText: FunctionComponent<ConceptTooltipTextProps> = ({ text, iconName }) => {
  const theme = useTheme();

  return (
    <Tooltip title={text}>
      <SpacingLine>
        {iconName && (<Icon name={iconName} />)}
        <Typo noWrap color={theme.color.text.disabled}>{text}</Typo>
      </SpacingLine>
    </Tooltip>
  );
};

export default ConceptTooltipText;
