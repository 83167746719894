import {
  ConceptCollaborationFieldDimension,
  ConceptCreatedAtDimension,
  ConceptFunctionalIdDimension,
  ConceptNameDimension,
  ConceptStakeholdersGroupsDimension,
  ConceptStakeholdersRolesDimension,
  ConceptStakeholdersUsersDimension,
  ConceptUpdatedAtDimension,
  Field_ApiAlias,
  Field_IsCore,
  Field_Title,
  FieldDimension,
  FieldDimension_Field,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  IconField,
  KinshipRelationDimension,
} from '../../conceptModule/ids';
import { Dashboard, Dashboard_Icon, DashboardIconDimension } from '../ids';
import { registerModel } from '../module';

const { associate, instance } = registerModel;

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Dashboard).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptNameDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Dashboard).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCreatedAtDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Dashboard).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptUpdatedAtDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Dashboard).withRole(FieldDimensionTypes_Role_FieldDimension, KinshipRelationDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Dashboard).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptFunctionalIdDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Dashboard).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptStakeholdersRolesDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Dashboard).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptStakeholdersUsersDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Dashboard).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptStakeholdersGroupsDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Dashboard).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCollaborationFieldDimension),
});

instance({ typeId: IconField, label: 'Dashboard_Icon', extraProperties: { [Field_Title]: 'Icon', [Field_ApiAlias]: 'icon', [Field_IsCore]: true } });
instance({
  typeId: FieldDimension,
  label: 'DashboardIconDimension',
  extraProperties: {
    [FieldDimension_Field]: Dashboard_Icon,
  },
});
associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Dashboard).withRole(FieldDimensionTypes_Role_FieldDimension, DashboardIconDimension),
});
