import type { RawObjectStore } from 'yooi-store';
import { compareEventId, doYield, newError } from 'yooi-utils';
import { createBroadcastChannel } from '../utils/broadcastChannelUtils';
import type { BrowserNetworkManager, BrowserNetworkProtocol } from '../utils/browserNetworkManager';
import buildInfo from '../utils/buildInfo';
import { reportClientMetric } from '../utils/clientReporterUtils';
import { acquireLockImmediately } from '../utils/webLocksUtils';
import type { Snapshot, SnapshotOperation } from './concurrencyHandler';
import type { OnConnectionProgress } from './connectionProgress';
import { ConnectionPhase } from './connectionProgress';

interface BrowserSnapshotProtocol extends BrowserNetworkProtocol {
  discover: {
    request: undefined,
    response: {
      userId: string,
      eventId: string,
    },
  },
  download: {
    request: {
      userId: string,
      minimalEventId: string,
    },
    response: {
      status: 'success',
      snapshot: Snapshot,
    } | {
      status: 'failure',
      reasons: string[],
    },
  },
}

export interface DownloadBrowserSnapshot {
  (
    userId: string,
    minimalEventId: string,
    onConnectionProgress: OnConnectionProgress
  ): Promise<{
    snapshot: Snapshot,
    reasons: undefined,
  } | {
    snapshot: undefined,
    reasons: string[],
  }>,
}

interface BrowserSnapshotNetwork {
  downloadBrowserSnapshot: DownloadBrowserSnapshot,
  close: () => void,
  registerBrowserSnapshotDownloadedBroadcastHandler: () => {
    close: () => void,
    getSnapshot: () => Snapshot | undefined,
  },
  broadcastBrowserDownloadedSnapshot: (snapshot: Snapshot) => Promise<{ duration: number } | undefined>,
}

interface RegisterBrowserSnapshotNetworkProps {
  getSnapshotDiscoveryInfo: () => {
    userId: string,
    eventId: string,
  } | undefined,
  getSnapshotDownloadInfo: () => {
    userId: string,
    eventId: string,
    checksum: string,
    rollbackOperations: SnapshotOperation[],
  } | undefined,
}

// leverage Web lock to list the presence of others clients. The advantage of Web locks is that they are release when the tab is closed or crash. We don't use it as a lock here
const getClientLockName = (clientId: string, networkId: string) => `yooi:${buildInfo.sourceHash}:${networkId}:snapshot:client:${clientId}`;
const parseClientLockName = (lockName: string) => {
  const match = /^yooi:([!A-Za-z0-9]+):([-A-Za-z0-9]+):snapshot:client:([-A-Za-z0-9]+)$/.exec(lockName);
  if (match) {
    const [, sourceHash, networkId, clientId] = match;
    return { clientId, networkId, sourceHash };
  } else {
    return undefined;
  }
};

export const createBrowserSnapshotNetwork = (browserNetworkManager: BrowserNetworkManager, networkId: string, objectStore: RawObjectStore, clientId: string) => ({
  getSnapshotDiscoveryInfo,
  getSnapshotDownloadInfo,
}: RegisterBrowserSnapshotNetworkProps): BrowserSnapshotNetwork => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { close, client } = browserNetworkManager.registerNetwork<BrowserSnapshotProtocol>(networkId, {
    discover: {
      handleRequest: () => getSnapshotDiscoveryInfo(),
    },
    download: {
      reportMetric: (data) => reportClientMetric(clientId, 'browserDownload', data),
      handleRequest: (request) => {
        const snapshotDownloadInfo = getSnapshotDownloadInfo();
        const userMatch = request.userId === snapshotDownloadInfo?.userId;
        const eventIdFulfilled = compareEventId(snapshotDownloadInfo?.eventId ?? '0-0', request.minimalEventId) >= 0;
        if (snapshotDownloadInfo !== undefined && userMatch && eventIdFulfilled) {
          return {
            status: 'success',
            snapshot: {
              eventId: snapshotDownloadInfo.eventId,
              checksum: snapshotDownloadInfo.checksum,
              rollbackOperations: snapshotDownloadInfo.rollbackOperations,
              operations: objectStore.listObjects().map((obj) => [obj.id, obj.asRawObject()]),
            },
          } satisfies BrowserSnapshotProtocol['download']['response'];
        } else {
          return {
            status: 'failure',
            reasons: Array.prototype.concat(
              snapshotDownloadInfo !== undefined ? [] : ['dirtyStore'],
              userMatch ? [] : ['userMismatch'],
              eventIdFulfilled ? [] : ['eventIdNotFullFilled']
            ),
          } satisfies BrowserSnapshotProtocol['download']['response'];
        }
      },
    },
  });

  const getOtherClientsCount = async () => (await navigator.locks.query()).held?.filter(({ name }) => {
    const lock = parseClientLockName(name ?? '');
    return lock && lock.sourceHash === buildInfo.sourceHash && lock.networkId === networkId && lock.clientId !== clientId;
  }).length ?? 0;

  const downloadBrowserSnapshot: DownloadBrowserSnapshot = async (userId, minimalEventId, onConnectionProgress) => {
    // check if there is some existing client, in order to avoid to send a discovery request and wait for a result that won"t happen
    const otherClientsCount = await getOtherClientsCount();
    if (otherClientsCount > 0) {
      const discoverResult = await client.discover(undefined, { timeout: 1000, maxResponseCount: otherClientsCount }, (response) => (
        response.userId === userId && compareEventId(response.eventId, minimalEventId) >= 0
      ));

      if (discoverResult) {
        onConnectionProgress(ConnectionPhase.DownloadFromBrowser);
        const downloadResult = await client.download(
          { userId, minimalEventId },
          { timeout: 10000 },
          discoverResult.responderNodeId
        );
        if (downloadResult) {
          const downloadResponse = downloadResult.response;
          if (downloadResponse.status === 'success') {
            return {
              snapshot: downloadResponse.snapshot,
            };
          } else {
            reportError(newError('Download snapshot from browser error', { downloadResponse }));
            return {
              reasons: ['downloadRejected', ...downloadResponse.reasons],
            };
          }
        } else {
          reportError(newError('Download snapshot from browser timed-out'));
          return {
            reasons: ['downloadTimeout'],
          };
        }
      } else {
        return {
          reasons: ['discoverTimeout'],
        };
      }
    } else {
      return {
        reasons: ['noOtherClients'],
      };
    }
  };

  const broadcastBrowserDownloadedSnapshot = async (snapshot: Snapshot) => {
    if (await getOtherClientsCount() > 0) {
      const timeStart = performance.now();
      const downloadedSnapshotChannel = createBroadcastChannel<{ snapshot: Snapshot }>(new BroadcastChannel(`snapshotDownloaded-${networkId}`));
      try {
        downloadedSnapshotChannel.postMessage({ snapshot });
        // ensure to release the event loop so that the postMessage is processed
        await doYield(true);
      } finally {
        downloadedSnapshotChannel.close();
      }
      return { duration: Math.round(performance.now() - timeStart) };
    } else {
      return undefined;
    }
  };

  const registerBrowserSnapshotDownloadedBroadcastHandler = () => {
    let result: Snapshot | undefined;
    const downloadedSnapshotChannel = createBroadcastChannel<{ snapshot: Snapshot }>(new BroadcastChannel(`snapshotDownloaded-${networkId}`));
    downloadedSnapshotChannel.registerMessageHandler(({ snapshot }) => {
      if (!result) {
        result = snapshot;
      }
    });
    return {
      close: () => downloadedSnapshotChannel.close(),
      getSnapshot: () => result,
    };
  };

  // acquire a unique lock to signal to other tabs that a client is active.
  // It's not used for lock usage, but it's a simple way to have this information with the advantage that the browser is handling it even in case of tab crash
  // cannot do async here, the promise will be awaited when the lock need to be released
  const clientLockPromise = acquireLockImmediately(getClientLockName(clientId, networkId));

  return {
    downloadBrowserSnapshot,
    close: () => {
      // cannot do async here but await is not strictly necessary here
      (async () => (await clientLockPromise)?.unlock())();
      close();
    },
    broadcastBrowserDownloadedSnapshot,
    registerBrowserSnapshotDownloadedBroadcastHandler,
  };
};
