import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { usePreview } from 'react-dnd-preview';
import { isFilterStep, PathStepType } from 'yooi-modules/modules/conceptModule';
import { joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName, IconSizeVariant } from '../../../../components/atoms/Icon';
import Typo from '../../../../components/atoms/Typo';
import Chip from '../../../../components/molecules/Chip';
import type { FrontObjectStore } from '../../../../store/useStore';
import useStore from '../../../../store/useStore';
import base from '../../../../theme/base';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import useTheme from '../../../../utils/useTheme';
import { GLOBAL_DIMENSION_CHIP_OPTION } from '../../fields/_global/pathUtils';
import type { Option } from '../../modelTypeUtils';
import { getChipOptions } from '../../modelTypeUtils';
import type { FrontFilterCondition, FrontFilters } from '../filterUtils';
import { isFrontFilterNode } from '../filterUtils';

const useStyles = makeStyles((theme) => ({
  dragItemPreview: {
    display: 'grid',
    gridTemplateColumns: '10rem 50rem 2rem',
    columnGap: spacingRem.s,
    borderRadius: base.borderRadius.medium,
    borderStyle: 'solid',
    borderColor: theme.color.border.default,
    borderWidth: '0.2rem',
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    paddingBottom: spacingRem.xs,
    paddingTop: spacingRem.xs,
    opacity: 0.7,
    backgroundColor: theme.color.background.neutral.default,
    alignItems: 'center',
  },
  dragItemPreviewHiddenChips: {
    gridTemplateColumns: '10rem 44rem 6rem 2rem',
  },
  dragItemPreviewNode: {
    borderStyle: 'dashed',
  },
  chipsContainer: {
    display: 'flex',
    columnGap: spacingRem.s,
  },
  chipContainer: {
    maxWidth: '11rem',
  },
}), 'dragItemPreview');

interface ChipOptionPreview extends Option {
  chipBackgroundColor?: string,
}

const getChipFilterConditionPreview = (store: FrontObjectStore, filters: FrontFilterCondition): ChipOptionPreview | undefined => {
  const { leftValue } = filters;
  if (!leftValue || leftValue.length === 0) {
    return { id: 'noCondition', label: 'No Condition' };
  }
  const lastStep = leftValue[leftValue.length - 1];
  const chipStep = lastStep && isFilterStep(lastStep) ? leftValue[leftValue.length - 2] : lastStep;
  if (!chipStep) {
    return undefined;
  }
  let selectedFieldId;
  switch (chipStep.type) {
    case PathStepType.field:
      selectedFieldId = chipStep.fieldId;
      break;
    case PathStepType.mapping:
      selectedFieldId = chipStep.mapping.id;
      break;
    case PathStepType.dimension:
      selectedFieldId = chipStep.conceptDefinitionId;
      break;
    case PathStepType.global:
      return GLOBAL_DIMENSION_CHIP_OPTION;
    default:
      return undefined;
  }
  return getChipOptions(store, selectedFieldId);
};

const getChipPreviewOptions = (store: FrontObjectStore, filters: FrontFilters): (ChipOptionPreview | undefined)[] => {
  let options: (ChipOptionPreview | undefined)[] = [];
  if (isFrontFilterNode(filters)) {
    filters.children?.forEach((child) => {
      if (isFrontFilterNode(child)) {
        options = options.concat(getChipPreviewOptions(store, child));
      } else {
        options.push(getChipFilterConditionPreview(store, child));
      }
    });
  } else {
    options.push(getChipFilterConditionPreview(store, filters));
  }
  return options;
};

const DragItemPreview: FunctionComponent = () => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const preview = usePreview();
  if (!preview.display) {
    return null;
  }
  const { item, style } = preview;
  const updatedStyle = joinObjects(style, { left: -630, top: -20 });
  const options = getChipPreviewOptions(store, item as FrontFilters);
  const displayedOptions = options.slice(0, 4);
  const hasHiddenChips = options.length > displayedOptions.length;
  return (
    <div
      style={updatedStyle}
      className={classnames({
        [classes.dragItemPreview]: true,
        [classes.dragItemPreviewHiddenChips]: hasHiddenChips,
        [classes.dragItemPreviewNode]: options.length > 1,
      })}
    >
      <Typo color={theme.color.text.secondary}>
        {i18n`${options.length} condition(s):`}
      </Typo>
      <div className={classes.chipsContainer}>
        {displayedOptions.map((option, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${option?.id}-${index}`} className={classes.chipContainer}>
            <Chip
              text={option?.label}
              tooltip={option?.tooltip}
              icon={option?.icon}
              squareColor={option?.squareColor}
              color={option?.color}
            />
          </div>
        ))}
      </div>
      {hasHiddenChips && (
        <Typo color={theme.color.text.secondary}>
          {`+ ${options.length - displayedOptions.length}`}
        </Typo>
      )}
      <Icon name={IconName.drag_indicator} colorVariant={IconColorVariant.secondary} size={IconSizeVariant.l} />
    </div>
  );
};

export default DragItemPreview;
