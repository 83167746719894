import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import { newError, textType } from 'yooi-utils';
import { asLocal } from '../../common/fields/commonPropertyType';
import type { GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import { preventComputedFieldUpdate, validateFieldIdAsProperty } from '../common/commonFieldUtils';
import { ImageField as ImageFieldId } from '../ids';
import { registerField } from '../module';
import type { DimensionsMapping, ResolutionStack } from '../utils';
import { isValueResolutionOfType, ParsedDimensionType, parseDimensionMapping, resolveFieldValue } from '../utils';
import type { ImageField } from './types';

export interface ImageValue {
  revision: string,
  position: { top: number, left: number, scale: number } | undefined,
}

export enum AspectRatioEnum {
  ThinBanner = 'ThinBanner',
  Banner = 'Banner',
  Wide = 'Wide',
}

export interface CustomAspectRatio {
  x: number,
  y: number,
}

export type AspectRatio = AspectRatioEnum | CustomAspectRatio;

const isImageValue = (value: unknown): value is ImageValue => {
  if (typeof value === 'object') {
    if (typeof (value as Record<'revision', unknown>).revision !== 'string') {
      return false;
    } else if ((value as Record<'position', unknown>).position === undefined) {
      return true;
    } else if (typeof (value as Record<'position', unknown>).position === 'object') {
      const { position } = value as Record<'position', Record<'top' | 'left' | 'scale', unknown>>;
      if (typeof position.top === 'number' && typeof position.left === 'number' && typeof position.scale === 'number') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const getStoreValue = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping): ImageValue | undefined => {
  const parsedDimension = parseDimensionMapping(dimensionsMapping);
  if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
    const instance = objectStore.getObject(parsedDimension.objectId);
    const revision = instance[fieldId] as string | undefined;
    const position = instance[`${fieldId}_position`] as ImageValue['position'];
    return revision ? { revision, position } : undefined;
  } else {
    return undefined;
  }
};

const getValueResolution = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack) => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
  if (isValueResolutionOfType(valueResolution, (value): value is ImageValue | undefined => value === undefined || isImageValue(value))) {
    return valueResolution;
  } else {
    return {
      value: undefined,
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['string', 'undefined'], typeof valueResolution.value),
      getDisplayValue: () => undefined,
      isTimeseries: false,
    };
  }
};

type ImageFieldHandler = GetDslFieldHandler<
  ImageField,
  ImageValue | undefined,
  undefined,
  ImageValue | undefined,
  ImageValue | undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined
>;

export const imageFieldHandler: ImageFieldHandler = registerField({
  model: {
    label: 'ImageField',
    title: 'Image',
    withApiAlias: true,
    properties: [
      { label: 'AspectRatio', as: asLocal('AspectRatio') },
    ],
    asPropertyBusinessRules: [
      validateFieldIdAsProperty('imageField'),
      preventComputedFieldUpdate('imageField'),
    ],
    extraModel: ({ registerCustomBusinessRules }) => {
      registerCustomBusinessRules((_, { onObject, onProperty }) => {
        onObject(ImageFieldId).register(([propertyId]) => {
          const positionProperty = `${propertyId}_position`;
          onProperty(propertyId).validate((__, { id, properties }) => {
            if (properties && properties[propertyId] === null) {
              return {
                rule: 'field.image.position.clear',
                status: ValidationStatus.ACCEPTED,
                generateSystemEvent: ({ updateObject }) => {
                  updateObject(id, { [positionProperty]: null });
                },
              };
            } else {
              return undefined;
            }
          });
          onProperty(positionProperty).validate(() => ({ rule: 'field.image.position.accept', status: ValidationStatus.ACCEPTED }));
        });
      });
    },
  },
  handler: (objectStore, fieldId) => ({
    describe: () => ({ hasData: true, returnType: textType, timeseriesMode: 'none' }),
    restApi: {
      returnTypeSchema: {},
      formatValue: () => undefined,
    },
    getStoreValue: (dimensionsMapping) => getStoreValue(objectStore, fieldId, dimensionsMapping),
    getValueWithoutFormula: (dimensionsMapping) => getStoreValue(objectStore, fieldId, dimensionsMapping),
    getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
    resolvePathStepConfiguration: () => ({
      hasData: true,
      timeseriesMode: 'none',
      getValueResolutionType: () => textType,
      resolveValue: (dimensionsMappings, _, resolutionStack) => {
        const { error, value } = getValueResolution(objectStore, fieldId, dimensionsMappings, resolutionStack);
        if (error) {
          throw error;
        } else {
          return value;
        }
      },
    }),
    updateValue: () => {
      throw newError('updateValue not supported');
    },
    isEmpty: (dimensionsMapping) => !getValueResolution(objectStore, fieldId, dimensionsMapping).value,
    getExportColumnHeaders: (configuration, fieldLabel) => ({
      columnsNumber: 1,
      getHeaders: () => [{ format: 'string', value: fieldLabel }],
      getColumnConfiguration: () => configuration,
    }),
    getExportValue: (dimensionsMapping, _, { hostname }) => {
      const { value } = getValueResolution(objectStore, fieldId, dimensionsMapping);
      const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
      return { format: 'string', value: value && parsedDimensionMapping.type === ParsedDimensionType.MonoDimensional ? `https://${hostname}/store/attachments/${parsedDimensionMapping.objectId}/${fieldId}/${value.revision}` : undefined };
    },
    isSaneValue: () => ({ isValid: true }),
    filterConditions: undefined,
  }),
});
