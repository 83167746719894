import { equals } from 'ramda';
import type { FunctionComponent } from 'react';
import { Suspense, useCallback, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { doYield, newError } from 'yooi-utils';
import Loading from '../components/molecules/Loading';
import AbortTaskError from './AbortTaskError';
import AlreadyCachedError from './AlreadyCachedError';
import useForceUpdate from './useForceUpdate';

interface ComputeFunction<Parameters extends unknown[], ReturnType> {
  (yieldProcessor: () => Promise<void>, ...parameters: Parameters): Promise<ReturnType>,
}

interface RegisteredComputeFunction<Parameters extends unknown[], ReturnType> {
  id: string,
  hasParameterChanged: (previousParameters: Parameters, newParameters: Parameters) => boolean,
  (yieldProcessor: () => Promise<void>, ...parameters: Parameters): Promise<ReturnType>,
}

const NoPreviousValue = Symbol('NoPreviousValue');

interface Task<Parameters extends unknown[], ReturnType> {
  computeFunction: ComputeFunction<Parameters, ReturnType>,
  parameters: Parameters,
  dependencies: unknown[],
  stage: (
    | { status: 'scheduled', previousValue: ReturnType | typeof NoPreviousValue }
    | { status: 'pending', promise: Promise<void>, previousValue: ReturnType | typeof NoPreviousValue }
    | { status: 'completed', result: ReturnType }
    | { status: 'failed', error: Error }
    | { status: 'aborted' }),
}

interface ContextRef {
  unmounted: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tasks: Map<string, Task<any, any>>,
}

export interface ExecuteAsyncTask {
  <Parameters extends unknown[], ReturnType>(
    compute: (RegisteredComputeFunction<Parameters, ReturnType>),
    parameters: Parameters,
    dependencies?: unknown[]
  ): { status: 'loading' | 'loaded', value: ReturnType },
}

export const registerComputeFunction = <Parameters extends unknown[], ReturnType>(
  compute: ComputeFunction<Parameters, ReturnType>,
  hasParameterChanged?: (previousParameters: Parameters, newParameters: Parameters) => boolean
): (RegisteredComputeFunction<Parameters, ReturnType>) => {
  const func = (yieldProcessor: () => Promise<void>, ...parameters: Parameters): Promise<ReturnType> => (
    compute(yieldProcessor, ...parameters)
  );
  func.id = uuid();
  func.hasParameterChanged = hasParameterChanged !== undefined
    ? hasParameterChanged
    : (previousParameters: Parameters, newParameters: Parameters): boolean => (
      newParameters.some((p, index) => (!equals(p, previousParameters[index])))
    );

  return func;
};

const withAsyncTask = <Props extends object & { executeAsyncTask?: never } = object>(
  Component: FunctionComponent<Omit<Props, 'executeAsyncTask'> & { executeAsyncTask: ExecuteAsyncTask }>,
  LoaderComponent?: FunctionComponent
): FunctionComponent<Props> => {
  const FallbackComponent = () => (LoaderComponent ? <LoaderComponent /> : <Loading />);

  return ({ executeAsyncTask: _, ...props }) => {
    const forceUpdate = useForceUpdate();

    const contextRef = useRef<ContextRef>({ unmounted: false, tasks: new Map() });

    useEffect(() => {
      // Copying the ref is required as the ref could be changed because of partial renderer
      const ref = contextRef;
      // Resting to false is required because of the strict mode that could trigger the destroy callback during the first render
      ref.current.unmounted = false;
      return () => {
        ref.current.unmounted = true;
      };
    }, []);

    const executeAsyncTask = useCallback<ExecuteAsyncTask>(<Parameters extends unknown[], ReturnType>(
      computeFunction: RegisteredComputeFunction<Parameters, ReturnType>,
      parameters: Parameters,
      dependencies: unknown[] = []
    ): { status: 'loading' | 'loaded', value: ReturnType } => {
      const existingTask: Task<Parameters, ReturnType> | undefined = contextRef.current.tasks.get(computeFunction.id);
      if (existingTask === undefined) {
        contextRef.current.tasks.set(computeFunction.id, { computeFunction, parameters, dependencies, stage: { status: 'scheduled', previousValue: NoPreviousValue } });
      } else if (computeFunction.hasParameterChanged(existingTask.parameters, parameters) || !equals(dependencies, existingTask.dependencies)) {
        let previousValue: ReturnType | typeof NoPreviousValue = NoPreviousValue;
        if (existingTask.stage.status === 'pending') {
          previousValue = existingTask.stage.previousValue;
        } else if (existingTask.stage.status === 'completed') {
          previousValue = existingTask.stage.result;
        }

        // Parameter or dependencies changed, renew task reference
        if (existingTask.stage.status === 'scheduled' || existingTask.stage.status === 'pending') {
          existingTask.stage = { status: 'aborted' };
        }

        contextRef.current.tasks.set(computeFunction.id, { computeFunction, parameters, dependencies, stage: { status: 'scheduled', previousValue } });
      }

      // We copy the ref to make sure the compute task is properly linked
      const processingTask = contextRef.current.tasks.get(computeFunction.id);
      if (processingTask === undefined) {
        throw newError('Invalid task state');
      }

      if (processingTask.stage.status === 'scheduled') {
        const yieldProcessor = async () => {
          await doYield();
          if (contextRef.current.unmounted || processingTask.stage.status === 'aborted') {
            throw new AbortTaskError();
          }
        };

        const startTask = async () => {
          try {
            await yieldProcessor();
            const result = await computeFunction(yieldProcessor, ...parameters);
            await yieldProcessor();

            if (processingTask.stage.status === 'aborted') {
              // Ignore
            } else if (processingTask.stage.status !== 'pending') {
              throw new AlreadyCachedError();
            } else {
              const shouldRerender = processingTask.stage.previousValue !== NoPreviousValue;
              processingTask.stage = { status: 'completed', result };
              if (shouldRerender) {
                forceUpdate();
              }
            }
          } catch (error) {
            if (error instanceof AbortTaskError) {
              // Ignore
            } else if (processingTask.stage.status !== 'pending') {
              throw new AlreadyCachedError();
            } else {
              const shouldRerender = processingTask.stage.previousValue !== NoPreviousValue;
              processingTask.stage = { status: 'failed', error: error as Error };
              if (shouldRerender) {
                forceUpdate();
              }
            }
          }
        };

        processingTask.stage = { status: 'pending', promise: startTask(), previousValue: processingTask.stage.previousValue };
      }

      switch (processingTask.stage.status) {
        case 'pending': {
          if (processingTask.stage.previousValue === NoPreviousValue) {
            // eslint-disable-next-line @typescript-eslint/only-throw-error
            throw processingTask.stage.promise;
          } else {
            return { status: 'loading', value: processingTask.stage.previousValue };
          }
        }
        case 'completed': {
          return { status: 'loaded', value: processingTask.stage.result };
        }
        case 'failed': {
          throw processingTask.stage.error;
        }
        case 'aborted': {
          throw new AbortTaskError();
        }
      }
    }, [forceUpdate]);

    return (
      <Suspense fallback={<FallbackComponent />}>
        <Component {...props} executeAsyncTask={executeAsyncTask} />
      </Suspense>
    );
  };
};

export default withAsyncTask;
