import type { FunctionComponent, MutableRefObject } from 'react';
import type { FieldBlockDisplayStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import {
  BlockType_Column,
  BlockType_Section,
  BlockType_Tab,
  FieldBlockDisplay,
  FieldBlockDisplay_Block,
  FieldBlockDisplay_DocumentationOverride,
  FieldBlockDisplay_EditDisplayCondition,
  FieldBlockDisplay_FieldDisplayConfiguration,
  FieldBlockDisplay_FieldPath,
  FieldBlockDisplay_Rank,
  FieldBlockDisplay_TitleOverride,
  FieldBlockDisplay_ViewDisplayCondition,
  FieldBlockDisplay_WorkflowField,
  FieldBlockDisplay_WorkflowFieldDisplayType,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { Filters, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import {
  BLOCK_PARAMETER_CURRENT,
  FILTER_PARAMETER_CURRENT,
  getPathLastFieldInformation,
  InstanceReferenceType,
  isFieldStep,
  isFilterStep,
  isGlobalDimensionStep,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import type { StoreObject } from 'yooi-store';
import { ranker } from 'yooi-utils';
import { IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import OverflowMenu from '../../../../../components/molecules/OverflowMenu';
import TableCell, { TableCellVAlign } from '../../../../../components/molecules/TableCell';
import TableLine from '../../../../../components/molecules/TableLine';
import useStore from '../../../../../store/useStore';
import i18n from '../../../../../utils/i18n';
import { formatOrUndef } from '../../../../../utils/stringUtils';
import { useHighlightNotify } from '../../../../../utils/useHighlight';
import type { NewLineFocusRefContent } from '../../../../../utils/useNewLineFocus';
import useScrollOnMountRef from '../../../../../utils/useScrollOnMountRef';
import { SessionStorageKeys, useSessionStorageState } from '../../../../../utils/useSessionStorage';
import { getEditorLinePathHandler } from '../../../../_global/conceptUtils';
import type { FilterDefinition } from '../../../../_global/filter/filterComposite/FilterComposite';
import FilterComposite from '../../../../_global/filter/filterComposite/FilterComposite';
import StoreTextInputField from '../../../../_global/input/StoreTextInputField';
import { getConceptDefinitionNameOrEntity } from '../../../../_global/modelTypeUtils';
import PathMappingAndFiltersInput from '../../../../_global/path/PathMappingAndFiltersInput';
import PathStepsInput from '../../../../_global/path/PathStepsInput';
import type { DisplayItem, LayoutCollapseConfiguration } from '../../../_global/displayItemsLibrary/definitions/displayItemDefinitionUtils';
import { getDisplayItemDefinitionHandler } from '../../../_global/displayItemsLibrary/displayItemLibrary';
import DisplayOptionsFieldCell from './DisplayOptionsFieldCell';
import { expandBlock } from './layoutUtils';

interface LayoutDisplayTableFieldBlockDisplayLineProps {
  conceptDefinitionId: string,
  fieldBlockDisplayId: string,
  index: number,
  parameterDefinitions: SingleParameterDefinition[],
  newlyCreatedId: MutableRefObject<NewLineFocusRefContent>,
  setNewlyCreatedId: (id: string) => void,
  workflowFieldId: string | undefined,
  workflowConceptIds: string[],
  flattenedTree: DisplayItem[],
  sortedBlockFields: (blockId: string) => StoreObject[],
  addNewFieldToBlock: (blockId: string, rank?: string) => string,
}

const LayoutDisplayTableFieldBlockDisplayLine: FunctionComponent<LayoutDisplayTableFieldBlockDisplayLineProps> = ({
  conceptDefinitionId,
  fieldBlockDisplayId,
  index,
  parameterDefinitions,
  newlyCreatedId,
  setNewlyCreatedId,
  workflowConceptIds,
  workflowFieldId,
  flattenedTree,
  sortedBlockFields,
  addNewFieldToBlock,
}) => {
  const store = useStore();

  const highlight = useHighlightNotify();
  const [layoutCollapseConfiguration, setLayoutCollapseConfiguration] = useSessionStorageState<LayoutCollapseConfiguration | undefined>(`${SessionStorageKeys.layoutCollapseConfig}_${conceptDefinitionId}`, undefined);
  const scrollOnMountRef = useScrollOnMountRef(true);

  const fieldBlockDisplay = store.getObject<FieldBlockDisplayStoreObject>(fieldBlockDisplayId);
  const blockId = fieldBlockDisplay[FieldBlockDisplay_Block];
  if (!blockId) {
    return null;
  }

  const valuePathHandler = getEditorLinePathHandler(store, parameterDefinitions);
  const moveFieldBelow = (belowBlockId: string, belowFieldBlockDisplayId?: string) => {
    const decoratedFieldsInBlock = ranker.decorateList(
      sortedBlockFields(belowBlockId),
      (field) => field[FieldBlockDisplay_Rank] as string
    );
    let rank;
    if (!belowFieldBlockDisplayId) {
      rank = decoratedFieldsInBlock.insertBeforeFirstItemRank();
    } else {
      rank = decoratedFieldsInBlock.find((f) => f.item.id === belowFieldBlockDisplayId)?.insertAfterRank() ?? decoratedFieldsInBlock.insertAfterLastItemRank();
    }
    store.updateObject(fieldBlockDisplayId, {
      [FieldBlockDisplay_Block]: belowBlockId,
      [FieldBlockDisplay_Rank]: rank,
    });
  };

  const viewBlockFieldFilters = fieldBlockDisplay[FieldBlockDisplay_ViewDisplayCondition];
  const editableBlockFieldFilters = fieldBlockDisplay[FieldBlockDisplay_EditDisplayCondition];
  const blockFieldFiltersDefinition: FilterDefinition = {
    updateFilters: (filters: Filters[]) => {
      store.updateObject(fieldBlockDisplay.id, {
        [FieldBlockDisplay_ViewDisplayCondition]: filters[0],
      });
      store.updateObject(fieldBlockDisplay.id, {
        [FieldBlockDisplay_EditDisplayCondition]: filters[1],
      });
    },
    definition: [
      {
        filter: viewBlockFieldFilters,
        title: i18n`Display`,
        subtitle: i18n`Users can see this field...`,
      },
      {
        filter: editableBlockFieldFilters,
        title: i18n`Edit`,
        subtitle: i18n`Users can edit this field...`,
      },
    ],
  };

  const fieldPath = fieldBlockDisplay[FieldBlockDisplay_FieldPath] ?? [];
  const fieldTitleLocalOverride = fieldBlockDisplay[FieldBlockDisplay_TitleOverride];
  const pathFieldInfo = getPathLastFieldInformation(fieldPath);
  const shouldDisplayMapping = fieldPath.length > 1 && isGlobalDimensionStep(fieldPath[0]) && isFieldStep(fieldPath[1]);
  const shouldDisplayFilters = fieldPath.some((pathStep) => isFilterStep(pathStep));
  return (
    <TableLine noTabIndex>
      <TableCell colSpan={shouldDisplayMapping || shouldDisplayFilters ? 1 : 2}>
        <div ref={newlyCreatedId.current.id === fieldBlockDisplayId ? scrollOnMountRef : undefined}>
          <PathStepsInput
            focusOnMount={newlyCreatedId.current.id === fieldBlockDisplayId}
            initialPath={fieldPath}
            valuePathHandler={valuePathHandler}
            onSubmit={(path) => {
              const newPathFieldInfo = getPathLastFieldInformation(path);
              if (pathFieldInfo?.fieldId === newPathFieldInfo?.fieldId) {
                store.updateObject(
                  fieldBlockDisplayId,
                  { [FieldBlockDisplay_FieldPath]: path }
                );
              } else {
                store.updateObject(
                  fieldBlockDisplayId,
                  {
                    [FieldBlockDisplay_FieldPath]: path,
                    [FieldBlockDisplay_FieldDisplayConfiguration]: null,
                    [FieldBlockDisplay_TitleOverride]: null,
                    [FieldBlockDisplay_DocumentationOverride]: null,
                    [FieldBlockDisplay_WorkflowField]: null,
                    [FieldBlockDisplay_WorkflowFieldDisplayType]: null,
                  }
                );
              }
            }}
            suggestedBasePaths={[
              {
                label: `${parameterDefinitions[0].label} (${formatOrUndef(getConceptDefinitionNameOrEntity(store, conceptDefinitionId))})`,
                path: [
                  { type: PathStepType.dimension, conceptDefinitionId },
                  { type: PathStepType.mapping, mapping: { id: BLOCK_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
                ],
              },
            ]}
            parameterDefinitions={parameterDefinitions}

          />
        </div>
      </TableCell>
      {shouldDisplayMapping || shouldDisplayFilters ? (
        <TableCell>
          <PathMappingAndFiltersInput
            path={fieldPath}
            parameterDefinitions={parameterDefinitions}
            onChange={(newPath) => store.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldPath]: newPath })}
          />
        </TableCell>
      ) : null}
      <TableCell valign={TableCellVAlign.top}>
        <StoreTextInputField
          initialValue={fieldTitleLocalOverride}
          onSubmit={(value?: string | null) => store.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_TitleOverride]: value })}
          maxLine={1}
        />
      </TableCell>
      <TableCell>
        <DisplayOptionsFieldCell
          fieldBlockDisplayId={fieldBlockDisplayId}
          workflowFieldId={workflowFieldId}
          workflowConceptIds={workflowConceptIds}
          parameterDefinitions={[
            ...parameterDefinitions,
          ]}
          onOpen={() => highlight([conceptDefinitionId, FieldBlockDisplay, fieldBlockDisplayId])}
          onClose={() => highlight(undefined)}
        />
      </TableCell>
      <TableCell>
        <FilterComposite
          suggestedPaths={[{
            label: i18n`Displayed Instance (${formatOrUndef(getConceptDefinitionNameOrEntity(store, conceptDefinitionId))})`,
            path: [
              { type: PathStepType.dimension, conceptDefinitionId },
              { type: PathStepType.mapping, mapping: { id: BLOCK_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
            ],
          }]}
          parameterDefinitions={[
            { id: FILTER_PARAMETER_CURRENT, label: i18n`Current`, typeId: pathFieldInfo?.conceptDefinitionId ?? conceptDefinitionId, type: 'parameter' },
            ...parameterDefinitions,
          ]}
          filtersDefinition={blockFieldFiltersDefinition}
          onOpen={() => highlight([conceptDefinitionId, FieldBlockDisplay, fieldBlockDisplayId])}
          onClose={() => highlight(undefined)}
        />
      </TableCell>
      <TableCell action>
        <IconOnlyButton
          onClick={() => {
            const previousField = flattenedTree[index - 2];
            moveFieldBelow(previousField.blockId, previousField.fieldBlockDisplayId);
            expandBlock(store, layoutCollapseConfiguration, setLayoutCollapseConfiguration, previousField.blockId);
            newlyCreatedId.current.reset();
          }}
          disabled={!(index > 1)}
          iconName={IconName.expand_less}
          tooltip={i18n`Move Up`}
          variant={IconOnlyButtonVariants.tertiary}
        />
      </TableCell>
      <TableCell action>
        <IconOnlyButton
          onClick={() => {
            const fieldBelow = flattenedTree[index + 1];
            moveFieldBelow(fieldBelow.blockId, fieldBelow.fieldBlockDisplayId);
            expandBlock(store, layoutCollapseConfiguration, setLayoutCollapseConfiguration, fieldBelow.blockId);
            newlyCreatedId.current.reset();
          }}
          disabled={!(index < flattenedTree.length - 1)}
          iconName={IconName.expand_more}
          tooltip={i18n`Move Down`}
          variant={IconOnlyButtonVariants.tertiary}
        />
      </TableCell>
      <TableCell action>
        <OverflowMenu
          onOpen={() => highlight([conceptDefinitionId, FieldBlockDisplay, fieldBlockDisplayId])}
          onClose={() => highlight(undefined)}
          menuItems={[
            {
              key: 'add_tab_above',
              name: i18n`Add tab above`,
              icon: IconName.description,
              onClick: () => setNewlyCreatedId(getDisplayItemDefinitionHandler(store, BlockType_Tab, conceptDefinitionId, flattenedTree)
                .createAbove(blockId, fieldBlockDisplayId)),
            },
            {
              key: 'add_tab_below',
              name: i18n`Add tab below`,
              icon: IconName.description,
              onClick: () => setNewlyCreatedId(getDisplayItemDefinitionHandler(store, BlockType_Tab, conceptDefinitionId, flattenedTree)
                .createBelow(blockId, fieldBlockDisplayId)),
            },
            {
              key: 'add_section_above',
              name: i18n`Add section above`,
              icon: IconName.subject,
              onClick: () => setNewlyCreatedId(getDisplayItemDefinitionHandler(store, BlockType_Section, conceptDefinitionId, flattenedTree)
                .createAbove(blockId, fieldBlockDisplayId)),
            },
            {
              key: 'add_section_below',
              name: i18n`Add section below`,
              icon: IconName.subject,
              onClick: () => setNewlyCreatedId(getDisplayItemDefinitionHandler(store, BlockType_Section, conceptDefinitionId, flattenedTree)
                .createBelow(blockId, fieldBlockDisplayId)),
            },
            {
              key: 'add_column_above',
              name: i18n`Add column above`,
              icon: IconName.subject,
              onClick: () => setNewlyCreatedId(getDisplayItemDefinitionHandler(store, BlockType_Column, conceptDefinitionId, flattenedTree)
                .createAbove(blockId, fieldBlockDisplayId)),
            },
            {
              key: 'add_column_below',
              name: i18n`Add column below`,
              icon: IconName.subject,
              onClick: () => setNewlyCreatedId(getDisplayItemDefinitionHandler(store, BlockType_Column, conceptDefinitionId, flattenedTree)
                .createBelow(blockId, fieldBlockDisplayId)),
            },
            {
              key: 'add_field_above',
              name: i18n`Add field above`,
              icon: IconName.tune,
              onClick: () => {
                const rank = ranker.decorateList(sortedBlockFields(blockId), (display) => display[FieldBlockDisplay_Rank] as string)
                  .find(({ item }) => item.id === fieldBlockDisplayId)
                  ?.insertBeforeRank();
                addNewFieldToBlock(blockId, rank);
              },
            },
            {
              key: 'add_field_below',
              name: i18n`Add field below`,
              icon: IconName.tune,
              onClick: () => {
                const rank = ranker.decorateList(sortedBlockFields(blockId), (display) => display[FieldBlockDisplay_Rank] as string)
                  .find(({ item }) => item.id === fieldBlockDisplayId)
                  ?.insertAfterRank();
                addNewFieldToBlock(blockId, rank);
              },
            },
            {
              key: 'unlink_field',
              name: i18n`Unlink field`,
              icon: IconName.delete,
              danger: true,
              onClick: () => store.deleteObject(fieldBlockDisplayId),
            },
          ]}
        />
      </TableCell>
    </TableLine>
  );
};

export default LayoutDisplayTableFieldBlockDisplayLine;
