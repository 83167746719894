import type { ReactElement } from 'react';
import type { ParametersMapping, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import type { ViewDimension, ViewStoredDefinition, ViewType } from 'yooi-modules/modules/dashboardModule';
import { newError } from 'yooi-utils';
import type { IconName } from '../../../components/atoms/Icon';
import type { ACLHandler } from '../../../store/useAcl';
import type { FrontObjectStore } from '../../../store/useStore';
import type { DisplayedLine } from '../ConceptViewTopBar';
import type { WidgetDisplay } from '../fields/_global/widgetUtils';
import type { UpdateViewDefinition } from '../fields/viewsField/ViewsFieldDefinitionOptions';
import type { FilterConfiguration, ViewFilters } from '../filter/useFilterSessionStorage';
import type { ViewResolution, ViewResolutionError } from './viewResolutionUtils';
import type { ViewResolvedDefinition } from './viewResolvedDefinition';

interface ViewHandler<
  StoredDefinition extends ViewStoredDefinition = ViewStoredDefinition,
  ResolvedDefinition extends ViewResolvedDefinition = ViewResolvedDefinition,
  ResolvedView extends ViewResolution = ViewResolution
> {
  type: ResolvedDefinition['type'],
  icon: IconName,
  getLabel: () => string,
  optionType: 'line' | 'popover' | undefined,
  getInitialStoredDefinition: (id: string) => StoredDefinition,
  resolveDefinition: (store: FrontObjectStore, viewDefinition: StoredDefinition, viewDimensions: ViewDimension[]) => ResolvedDefinition,
  getDefinitionErrors?: (
    store: FrontObjectStore,
    viewDefinition: ResolvedDefinition,
    viewDimensions: ViewDimension[],
    parameterDefinitions: SingleParameterDefinition[],
  ) => undefined | string[],
  renderDefinitionOptions: (
    store: FrontObjectStore,
    viewDefinition: ResolvedDefinition,
    props: {
      widgetName: string | undefined,
      viewDimensions: ViewDimension[],
      updateViewDefinition: UpdateViewDefinition<StoredDefinition>,
      readOnly: boolean,
      parameterDefinitions: SingleParameterDefinition[],
      isWidget: boolean,
    }
  ) => (ReactElement | null),
  withFilters: boolean,
  withExport: boolean,
  resolveView: (
    store: FrontObjectStore,
    viewDefinition: ResolvedDefinition,
    props: {
      viewDimensions: ViewDimension[],
      aclHandler: ACLHandler,
      parametersMapping: ParametersMapping,
      filterConfiguration?: FilterConfiguration,
      userId: string,
      readOnly?: boolean,
      addedTimePoints?: number[],
    }) => ResolvedView | ViewResolutionError,
  renderBlock: (
    store: FrontObjectStore,
    viewDefinition: StoredDefinition,
    props: {
      viewDimensions: ViewDimension[],
      viewFilters: ViewFilters,
      layoutParametersMapping: ParametersMapping,
      widgetDisplay: WidgetDisplay,
      readOnly: boolean,
      includeLoader: boolean,
    }
  ) => (ReactElement | null),
  renderWidget: (
    store: FrontObjectStore,
    viewDefinition: StoredDefinition,
    props: {
      widgetId: string,
      viewDimensions: ViewDimension[],
      viewFilters: ViewFilters,
      parameterDefinitions: SingleParameterDefinition[],
      parametersMapping: ParametersMapping,
      width: number,
      height: number,
      readOnly?: boolean,
      includeLoader: boolean,
    }
  ) => (ReactElement | null),
  hasOptions?: (
    store: FrontObjectStore,
    viewDefinition: ResolvedDefinition,
    props: {
      viewDimensions: ViewDimension[],
      parameterDefinitions: SingleParameterDefinition[],
      viewFilters: ViewFilters,
    }
  ) => boolean,
  renderOptions?: (
    store: FrontObjectStore,
    viewDefinition: StoredDefinition,
    props: {
      widgetId?: string,
      viewDimensions: ViewDimension[],
      parameterDefinitions: SingleParameterDefinition[],
      viewFilters: ViewFilters,
      parametersMapping: ParametersMapping,
      readOnly?: boolean,
      includeLoader: boolean,
    }
  ) => ReactElement | null,
  renderExport?: (
    store: FrontObjectStore,
    viewDefinition: ResolvedDefinition,
    props: {
      widgetId?: string,
      viewDimensions: ViewDimension[],
      filterConfiguration?: FilterConfiguration,
      parametersMapping: ParametersMapping,
      parameterDefinitions: SingleParameterDefinition[],
      getViewResolution: () => ResolvedView | ViewResolutionError,
    }
  ) => (ReactElement | null),
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const viewHandlerMap: Map<ViewType, ViewHandler<any, any, any>> = new Map();

const getViewHandler: <ResolvedView extends ViewResolution> (viewType: ViewType) => ViewHandler<ViewStoredDefinition, ViewResolvedDefinition, ResolvedView> = (viewType) => {
  const registration = viewHandlerMap.get(viewType);
  if (!registration) {
    throw newError('No view handler registered for this view', { viewType });
  }
  return registration;
};

type FilterType<T extends unknown[], FilteredType> = T extends []
  ? [] : T extends [infer H, ...infer R] ? H extends FilteredType ? FilterType<R, FilteredType> : [H, ...FilterType<R, FilteredType>] : T;
type OmitArgumentOfType<F, Type> = F extends (...args: infer P) => infer R ? (...args: FilterType<P, Type>) => R : never;

interface ViewTypeHandler {
  type: ViewHandler['type'],
  icon: ViewHandler['icon'],
  label: ReturnType<ViewHandler['getLabel']>,
  optionType: ViewHandler['optionType'],
  withFilters: ViewHandler['withFilters'],
  withExport: ViewHandler['withExport'],
  getStoredDefinition: ViewHandler['getInitialStoredDefinition'],
  getDefinitionErrors: ViewHandler['getDefinitionErrors'],
  getResolvedDefinition: (store: FrontObjectStore, id: string, viewDimensions: ViewDimension[]) => ViewResolvedDefinition,
  renderDefinitionOptions: (
    store: FrontObjectStore,
    id: string,
    props: {
      widgetName: string | undefined,
      viewDimensions: ViewDimension[],
      updateViewDefinition: UpdateViewDefinition<ViewStoredDefinition>,
      readOnly: boolean,
      parameterDefinitions: SingleParameterDefinition[],
      isWidget: boolean,
    }
  ) => (ReactElement | null),
  renderBlock: (
    store: FrontObjectStore,
    id: string,
    props: {
      viewDimensions: ViewDimension[],
      viewFilters: ViewFilters,
      layoutParametersMapping: ParametersMapping,
      filtersConfiguration: FilterConfiguration | undefined,
      widgetDisplay: WidgetDisplay,
      readOnly: boolean,
      includeLoader: boolean,
    }
  ) => (ReactElement | null),
  renderWidget: (
    store: FrontObjectStore,
    id: string,
    props: {
      widgetId: string,
      viewDimensions: ViewDimension[],
      viewFilters: ViewFilters,
      parametersMapping: ParametersMapping,
      parameterDefinitions: SingleParameterDefinition[],
      onDisplayedLineChange: (line: DisplayedLine | undefined) => void,
      width: number,
      height: number,
      includeLoader: boolean,
    }
  ) => (ReactElement | null),
}

export const getViewTypeHandler = (type: ViewType): ViewTypeHandler => {
  const viewHandler = getViewHandler(type);

  const resolveDefinition: ViewTypeHandler['getResolvedDefinition'] = (store, id, viewDimensions) => (
    viewHandler.resolveDefinition(store, viewHandler.getInitialStoredDefinition(id), viewDimensions)
  );

  return {
    type: viewHandler.type,
    icon: viewHandler.icon,
    label: viewHandler.getLabel(),
    optionType: viewHandler.optionType,
    withFilters: viewHandler.withFilters,
    withExport: viewHandler.withExport,
    getStoredDefinition: viewHandler.getInitialStoredDefinition,
    getResolvedDefinition: resolveDefinition,
    getDefinitionErrors: viewHandler.getDefinitionErrors,
    renderDefinitionOptions: (store, id, props) => {
      const definition = resolveDefinition(store, id, props.viewDimensions);
      return viewHandler.renderDefinitionOptions(store, definition, props);
    },
    renderBlock: (store, id, props) => viewHandler.renderBlock(store, viewHandler.getInitialStoredDefinition(id), props),
    renderWidget: (store, id, props) => viewHandler.renderWidget(store, viewHandler.getInitialStoredDefinition(id), props),
  };
};

export interface ViewDefinitionHandler<
  StoredDefinition extends ViewStoredDefinition = ViewStoredDefinition,
  ResolvedDefinition extends ViewResolvedDefinition = ViewResolvedDefinition,
  ResolvedView extends ViewResolution = ViewResolution
> {
  type: ViewHandler<StoredDefinition, ResolvedDefinition, ResolvedView>['type'],
  icon: ViewHandler<StoredDefinition, ResolvedDefinition, ResolvedView>['icon'],
  label: ReturnType<ViewHandler<StoredDefinition, ResolvedDefinition, ResolvedView>['getLabel']>,
  withFilters: ViewHandler<StoredDefinition, ResolvedDefinition, ResolvedView>['withFilters'],
  withExport: ViewHandler<StoredDefinition, ResolvedDefinition, ResolvedView>['withExport'],
  optionType: ViewHandler<StoredDefinition, ResolvedDefinition, ResolvedView>['optionType'],
  getStoredDefinition: () => StoredDefinition,
  getDefinitionErrors: (store: FrontObjectStore, viewDimensions: ViewDimension[], parameterDefinitions: SingleParameterDefinition[]) => undefined | string[],
  getDefinition: OmitArgumentOfType<ViewHandler<StoredDefinition, ResolvedDefinition, ResolvedView>['resolveDefinition'], ViewStoredDefinition>,
  renderDefinitionOptions: OmitArgumentOfType<ViewHandler<StoredDefinition, ResolvedDefinition, ResolvedView>['renderDefinitionOptions'], ViewResolvedDefinition>,
  renderBlock: OmitArgumentOfType<ViewHandler<StoredDefinition, ResolvedDefinition, ResolvedView>['renderBlock'], ViewStoredDefinition>,
  renderWidget: OmitArgumentOfType<ViewHandler<StoredDefinition, ResolvedDefinition, ResolvedView>['renderWidget'], ViewStoredDefinition>,
  hasOptions: OmitArgumentOfType<ViewHandler<StoredDefinition, ResolvedDefinition, ResolvedView>['hasOptions'], ViewResolvedDefinition>,
  renderOptions: OmitArgumentOfType<ViewHandler<StoredDefinition, ResolvedDefinition, ResolvedView>['renderOptions'], ViewStoredDefinition>,
  renderExport: OmitArgumentOfType<ViewHandler<StoredDefinition, ResolvedDefinition, ResolvedView>['renderExport'], ViewResolvedDefinition>,
  resolveView: OmitArgumentOfType<ViewHandler<StoredDefinition, ResolvedDefinition, ResolvedView>['resolveView'], ViewResolvedDefinition>,
}

export const getViewDefinitionHandler: (viewDefinition: ViewStoredDefinition) => ViewDefinitionHandler = (viewDefinition) => {
  const viewHandler = getViewHandler(viewDefinition.type);
  const viewTypeHandler = getViewTypeHandler(viewDefinition.type);
  const resolveDefinition: ViewDefinitionHandler['getDefinition'] = (store, viewDimensions) => viewHandler.resolveDefinition(store, viewDefinition, viewDimensions);
  return {
    type: viewTypeHandler.type,
    icon: viewTypeHandler.icon,
    optionType: viewTypeHandler.optionType,
    withFilters: viewTypeHandler.withFilters,
    withExport: viewTypeHandler.withExport,
    label: viewDefinition.label !== undefined && viewDefinition.label !== '' ? viewDefinition.label : viewTypeHandler.label,
    getStoredDefinition: () => viewDefinition,
    getDefinitionErrors: (store, viewDimensions, parameters) => (
      viewTypeHandler.getDefinitionErrors?.(store, resolveDefinition(store, viewDimensions), viewDimensions, parameters)
    ),
    getDefinition: resolveDefinition,
    renderDefinitionOptions: (store, props) => {
      const definition = resolveDefinition(store, props.viewDimensions);
      return viewHandler.renderDefinitionOptions(store, definition, props);
    },
    renderBlock: (store, props) => viewHandler.renderBlock(store, viewDefinition, props),
    renderWidget: (store, props) => viewHandler.renderWidget(store, viewDefinition, props),
    renderExport: (store, props) => {
      const definition = resolveDefinition(store, props.viewDimensions);
      return viewHandler.renderExport?.(store, definition, props) ?? null;
    },
    hasOptions: (store, props) => {
      const definition = resolveDefinition(store, props.viewDimensions);
      return viewHandler.hasOptions?.(store, definition, props) ?? false;
    },
    renderOptions: (store, props) => viewHandler.renderOptions?.(store, viewDefinition, props) ?? null,
    resolveView: (store, props) => {
      const definition = resolveDefinition(store, props.viewDimensions);
      return viewHandler.resolveView(
        store,
        definition,
        props
      );
    },
  };
};

export const registerView = <StoredDefinition extends ViewStoredDefinition, ResolvedDefinition extends ViewResolvedDefinition, ResolvedView extends ViewResolution>(
  viewHandlerDefinition: ViewHandler<StoredDefinition, ResolvedDefinition, ResolvedView>
): ViewHandler<StoredDefinition, ResolvedDefinition, ResolvedView> => {
  if (viewHandlerMap.has(viewHandlerDefinition.type)) {
    throw newError('A view handler definition is already registered for this view', { viewHandlerDefinitionId: viewHandlerDefinition.type });
  }
  viewHandlerMap.set(viewHandlerDefinition.type, viewHandlerDefinition);
  return viewHandlerDefinition;
};
