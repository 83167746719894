import type { ObjectStoreWithTimeseries, TimeseriesValue } from 'yooi-store';
import { newError } from 'yooi-utils';
import type { GetDslFieldHandler } from '../../../common/fields/FieldModuleDslType';
import { isInstanceOf } from '../../../typeModule';
import { Field as FieldId, TimeseriesNumberField as TimeseriesNumberFieldId, TimeseriesTextField as TimeseriesTextFieldId } from '../../ids';
import type { TimeseriesNumberField, TimeseriesTextField } from '../types';
import type { TimeseriesExportConfiguration } from './timeseriesFieldsExportUtils';
import { timeseriesNumberFieldHandler } from './timeseriesNumberField';
import { timeseriesTextFieldHandler } from './timeseriesTextField';

export const getTimeseriesFieldUtilsHandler = (
  objectStore: ObjectStoreWithTimeseries,
  fieldId: string
): ReturnType<typeof timeseriesNumberFieldHandler> | ReturnType<typeof timeseriesTextFieldHandler> => {
  const field = objectStore.getObjectOrNull(fieldId);
  if (!field) {
    throw newError('getTimeseriesFieldUtilsHandler: Field does not exist', { fieldId });
  } else if (!isInstanceOf(field, FieldId)) {
    throw newError('Called getTimeseriesFieldUtilsHandler with an id that is not a field', { fieldId });
  } else if (isInstanceOf(field, TimeseriesNumberFieldId)) {
    return timeseriesNumberFieldHandler(objectStore, fieldId);
  } else if (isInstanceOf(field, TimeseriesTextFieldId)) {
    return timeseriesTextFieldHandler(objectStore, fieldId);
  } else {
    throw newError('Called getTimeseriesFieldUtilsHandler with an id that is not a timeseries field', { fieldId });
  }
};

export type TimeseriesField = TimeseriesNumberField | TimeseriesTextField;

export type TimeseriesFieldHandler<Field extends TimeseriesField, ValueType> = GetDslFieldHandler<
  Field,
  TimeseriesFieldValue<ValueType>,
  TimeseriesUpdate<ValueType>,
  TimeseriesFieldValue<ValueType>,
  TimeseriesFieldValue<ValueType>,
  TimeseriesFieldValue<ValueType>,
  undefined,
  undefined,
  undefined,
  TimeseriesExportConfiguration
>;

export interface TimeseriesMoveUpdate {
  type: 'move',
  fromTime: number,
  toTime: number,
}

export interface TimeseriesValueUpdate<T> {
  type: 'value',
  time: number,
  value: T | null,
}

export interface TimeseriesTruncateUpdate {
  type: 'truncate',
  from: number,
  to: number,
}

export interface TimeseriesDeleteUpdate {
  type: 'delete',
  time: number,
}

export type TimeseriesUpdate<T> = TimeseriesValueUpdate<T> | TimeseriesTruncateUpdate | TimeseriesDeleteUpdate | TimeseriesMoveUpdate;

export type TimeseriesFieldValue<T> = TimeseriesValue<T | null | undefined>[] | undefined;
