import { useFormulaV2 } from 'yooi-modules';
import type { Formula, PathStep, SingleParameterDefinition, ValueFormulaInput } from 'yooi-modules/modules/conceptModule';
import { buildInputSet, InstanceReferenceType, parseFormula, PathStepType } from 'yooi-modules/modules/conceptModule';
import { Field_Formula } from 'yooi-modules/modules/conceptModule/ids';
import type { ObjectStoreWithTimeseries } from 'yooi-store';
import type { FormulaType } from 'yooi-utils';
import { compareNumber, filterNullOrUndefined, joinObjects, newError } from 'yooi-utils';
import Button, { ButtonVariant } from '../../components/atoms/Button';
import { IconName } from '../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../components/atoms/IconOnlyButton';
import Link from '../../components/molecules/Link';
import SpacingLine from '../../components/molecules/SpacingLine';
import DataTable from '../../components/templates/DataTable';
import i18n from '../../utils/i18n';
import { formatOrUndef } from '../../utils/stringUtils';
import type { FieldEditionSection, FieldEditionSectionGroup } from './fields/FieldEditionOptionType';
import { EditionOptionTypes } from './fields/FieldEditionOptionType';
import StoreTextInputField from './input/StoreTextInputField';
import { getConceptDefinitionNameOrEntity } from './modelTypeUtils';
import PathMappingAndFiltersInput from './path/PathMappingAndFiltersInput';
import PathStepsInput from './path/PathStepsInput';
import { createPathConfigurationHandler } from './pathConfigurationHandler';

const getNextIndex = (indexes: number[], currentIndex: number | undefined): number => {
  const sortedIndexes = indexes.sort(compareNumber);
  if (sortedIndexes.length === 0 || (currentIndex === undefined && sortedIndexes[0] > 1)) {
    return 1;
  }
  for (let i = 0; i < sortedIndexes.length - 1; i += 1) {
    const nextIndex = sortedIndexes[i] + 1;
    if ((currentIndex === undefined || currentIndex < nextIndex) && nextIndex !== sortedIndexes[i + 1]) {
      return nextIndex;
    }
  }
  if (currentIndex === undefined) {
    return sortedIndexes[sortedIndexes.length - 1] + 1;
  } else {
    return Math.max(currentIndex, sortedIndexes[sortedIndexes.length - 1]) + 1;
  }
};

export const sanitizeFormulaInputName = (name: string, otherNames: string[]): string => {
  const sanitizedName = name === '' ? 'Input1' : name;
  if (!otherNames.map((n) => n.toLowerCase()).includes(sanitizedName.toLowerCase())) {
    // We don't have any conflict, we can accept this name
    return sanitizedName;
  } else {
    // We have a conflict, try to find the next available index
    const splitNameRegExp = /^(.*?)([0-9]*)$/;
    const currentMatch = splitNameRegExp.exec(sanitizedName);
    if (!currentMatch) {
      throw newError('Unable to match name', { name, sanitizedName });
    }

    const prefix = currentMatch[1];
    const index = currentMatch[2] === '' ? undefined : Number.parseInt(currentMatch[2], 10);

    const indexes = otherNames
      .map((otherName) => splitNameRegExp.exec(otherName))
      .filter(filterNullOrUndefined)
      .filter((otherMatch) => otherMatch[1].toLowerCase() === prefix.toLowerCase() && otherMatch[2] !== '')
      .map((otherMatch) => Number.parseInt(otherMatch[2], 10));

    return `${prefix}${getNextIndex(indexes, index)}`;
  }
};

interface FormulaEditionOptionsProps {
  store: ObjectStoreWithTimeseries,
  parameterDefinitions: SingleParameterDefinition[],
  value: Formula | undefined,
  onChange: (newValue: Formula | null) => void,
  hasValue: () => boolean,
  clearValue: () => void,
  returnType: FormulaType,
  extraAcceptedTypes?: FormulaType[],
  forbidRemoveFormula?: boolean,
}

export const getFormulaFieldEditionSection = ({
  store,
  parameterDefinitions,
  value,
  onChange,
  hasValue,
  clearValue,
  returnType,
  extraAcceptedTypes,
  forbidRemoveFormula,
}: FormulaEditionOptionsProps): FieldEditionSection | FieldEditionSectionGroup => {
  if (value === undefined) {
    return {
      key: 'formula',
      type: 'section',
      options: [
        {
          key: Field_Formula,
          hasValue: () => value !== undefined,
          clearValue: () => onChange(null),
          type: EditionOptionTypes.custom,
          isVertical: true,
          padded: true,
          props: {
            render: () => (
              <SpacingLine>
                <Button
                  iconName={IconName.fx}
                  title={i18n`Use formula`}
                  onClick={() => onChange({ formula: '', inputs: [] })}
                />
              </SpacingLine>
            ),
          },
        },
      ],
    };
  } else {
    const onChangeValueInputPath = (index: number, newPath: PathStep[]) => {
      onChange({
        formula: value.formula,
        inputs: value.inputs
          .map((entry, i) => (index === i ? joinObjects(entry, { input: joinObjects(entry.input, { path: newPath }) }) : entry)),
      });
    };

    let error: string | undefined;
    // Look like a hook but isn't
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (useFormulaV2()) {
      try {
        const parameters: { id: string, typeIds: string[] }[] = parameterDefinitions.map((parameter) => ({ id: parameter.id, typeIds: [parameter.typeId] }));
        const { inputSet } = buildInputSet(store, parameters, value.inputs, false);
        const rootNode = parseFormula(value.formula, inputSet);
        if (
          !returnType.isAssignableFrom(rootNode.type)
          && (extraAcceptedTypes === undefined || !extraAcceptedTypes.some((type) => type.isAssignableFrom(rootNode.type)))
        ) {
          error = i18n`Return type doesn't match the expected return type.`;
        }
      } catch (_) {
        error = i18n`Something went wrong while parsing formula.`;
      }
    }

    return {
      key: 'formula',
      type: 'group',
      title: i18n`Formula`,
      rightActions: forbidRemoveFormula ? undefined : (
        <SpacingLine>
          <IconOnlyButton
            iconName={IconName.delete}
            tooltip={i18n`Delete`}
            onClick={() => onChange(null)}
            variant={IconOnlyButtonVariants.danger}
          />
        </SpacingLine>
      ),
      sections: [
        {
          key: `${Field_Formula}_execute`,
          title: i18n`Execute`,
          rightActions: (
            <Link
              key="functions"
              title={i18n`Functions library`}
              to="/settings/formula-documentation"
              iconName={IconName.output}
              openInNewTab
            />
          ),
          options: [
            {
              key: `${Field_Formula}_formula`,
              type: EditionOptionTypes.text,
              title: i18n`Formula`,
              hasValue,
              clearValue,
              props: {
                placeholder: i18n`Add formula`,
                value: value.formula,
                maxLine: 15,
                onChange: (eventValue) => {
                  onChange({ formula: eventValue ?? '', inputs: value.inputs });
                },
                error,
              },
            },
            {
              key: `${Field_Formula}_inputs`,
              title: i18n`Inputs`,
              hasValue: () => false,
              clearValue: () => {},
              type: EditionOptionTypes.custom,
              isVertical: true,
              padded: true,
              props: {
                render: () => {
                  const valueInputPathHandler = createPathConfigurationHandler(store, parameterDefinitions);
                  return (
                    <DataTable
                      list={
                        value.inputs
                          .map((entry, index) => (joinObjects(entry, { index })))
                          .filter((entry): entry is { index: number, name: string, input: ValueFormulaInput } => entry.input.type === 'value')
                          .map((entry) => ({ key: entry.name, type: 'item', item: entry, color: undefined }))
                      }
                      columnsDefinition={[
                        {
                          propertyId: 'name',
                          name: i18n`Name`,
                          width: 15,
                          cellRender: ({ name, index }) => (
                            <StoreTextInputField
                              initialValue={name}
                              onSubmit={(newName) => {
                                const newInputs = [...value.inputs];
                                newInputs[index] = joinObjects(
                                  value.inputs[index],
                                  { name: sanitizeFormulaInputName(newName ?? '', value.inputs.filter((_, i) => index !== i).map((entry) => entry.name)) }
                                );

                                onChange({ formula: value.formula, inputs: newInputs });
                              }}
                              acceptChars={/^[A-Za-z0-9_]+$/}
                              acceptCharsErrorMessage={i18n`Formula input alias should only contain letters, numbers or '_'`}
                              warning={
                                ![...(value.formula ?? '').matchAll(/([A-Za-z0-9_]+)/g)].some(([, group]) => group.toLocaleLowerCase() === name.toLocaleLowerCase())
                                  ? i18n`Input is not used in formula` : undefined
                              }
                            />
                          ),
                        },
                        {
                          propertyId: 'path',
                          name: i18n`Path`,
                          width: 70,
                          cellRender: (formulaInput) => (
                            <PathStepsInput
                              initialPath={formulaInput.input.path}
                              onSubmit={(newPath) => onChangeValueInputPath(formulaInput.index, newPath)}
                              parameterDefinitions={parameterDefinitions}
                              valuePathHandler={valueInputPathHandler}
                              suggestedBasePaths={
                                // if there is only 1 parameter, default path is "concept/instance"
                                parameterDefinitions.length === 1
                                  ? [
                                    {
                                      label: i18n`Current (${formatOrUndef(getConceptDefinitionNameOrEntity(store, parameterDefinitions[0].typeId))})`,
                                      path: [
                                        { type: PathStepType.dimension, conceptDefinitionId: parameterDefinitions[0].typeId },
                                        { type: PathStepType.mapping, mapping: { id: parameterDefinitions[0].id, type: InstanceReferenceType.parameter } },
                                      ],
                                    },
                                  ]
                                  // if there no dimension or more than one, there is no default path
                                  : undefined
                              }
                            />
                          ),
                        },
                        {
                          propertyId: 'filters',
                          name: i18n`Filters`,
                          width: 15,
                          cellRender: (formulaInput) => (
                            <PathMappingAndFiltersInput
                              path={formulaInput.input.path}
                              parameterDefinitions={parameterDefinitions}
                              onChange={(newPath) => onChangeValueInputPath(formulaInput.index, newPath)}
                            />
                          ),
                        },
                      ]}
                      linesActions={({ index }) => [
                        {
                          key: 'duplicate',
                          name: i18n`Duplicate`,
                          icon: IconName.content_copy_outline,
                          onClick: () => {
                            const newInputs = [...value.inputs];
                            newInputs.splice(
                              index + 1,
                              0,
                              joinObjects(
                                value.inputs[index],
                                {
                                  name: sanitizeFormulaInputName(value.inputs[index].name, value.inputs.map((entry) => entry.name)),
                                }
                              )
                            );
                            onChange({ formula: value.formula, inputs: newInputs });
                          },
                        },
                        {
                          key: 'delete',
                          name: i18n`Delete`,
                          icon: IconName.delete,
                          onClick: () => {
                            onChange({ formula: value.formula, inputs: value.inputs.filter((_, i) => i !== index) });
                          },
                          danger: true,
                        },
                      ]}
                      newItemIcon={IconName.add}
                      onNewItem={() => {
                        onChange({
                          formula: value.formula,
                          inputs: [...value.inputs, { name: sanitizeFormulaInputName('Input1', value.inputs.map(({ name }) => name)), input: { type: 'value', path: [] } }],
                        });
                      }}
                      newItemTitle={i18n`Add`}
                      newItemButtonVariant={ButtonVariant.secondary}
                      fullWidth
                    />
                  );
                },
              },
            },
          ],
        },
      ],
    };
  }
};
