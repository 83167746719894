// eslint-disable-next-line max-classes-per-file
import { StructuredError } from '../../errorUtils';
import type { FormulaType } from './formula';

export class TranspilerEngineNotAvailableError extends StructuredError {
  override readonly name = 'TranspilerEngineNotAvailableError';

  constructor() {
    super('The transpiler engine is not available', { data: {} });
  }
}

export class FormulaParsingError extends StructuredError {
  override readonly name = 'FormulaParsingError';

  constructor(cause: unknown, formula: string) {
    super('The formula cannot be parsed', { data: { formula }, cause });
  }
}

export class InputNotFoundError extends StructuredError {
  override readonly name = 'InputNotFoundError';

  constructor(inputName: string) {
    super('The input does not exist', { data: { inputName } });
  }
}

export class FunctionNotFoundError extends StructuredError {
  override readonly name = 'FunctionNotFoundError';

  constructor(functionName: string) {
    super('The function does not exist', { data: { functionName } });
  }
}

export class FunctionResolutionError extends StructuredError {
  override readonly name = 'FunctionResolutionError';

  constructor(functionName: string, argTypes: FormulaType[], errors: Error[]) {
    super('The function cannot be resolved', { data: { functionName, argTypes, errors } });
  }
}

export class FormulaResolutionError extends StructuredError {
  override readonly name = 'FormulaResolutionError';

  constructor(cause: unknown) {
    super('The formula resolution failed', { data: {}, cause });
  }
}

export class OperatorInvalidArgumentTypeError extends StructuredError {
  override readonly name = 'OperatorInvalidArgumentTypeError';

  constructor(operator: string, expectedType: FormulaType | FormulaType[], argType: FormulaType, invalidOperand?: string) {
    super('The operator operand type is invalid', { data: { operator, expectedType, argType, invalidOperand } });
  }
}
