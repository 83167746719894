import type { FunctionComponent, ReactElement } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { newError } from 'yooi-utils';

interface Context {
  loggedUserId: string,
}

const AuthContext = createContext<Context | undefined>(undefined);

interface AuthContextProviderProps {
  loggedUserId: string,
  children: ReactElement | null,
}

export const AuthContextProvider: FunctionComponent<AuthContextProviderProps> = ({ loggedUserId, children }) => {
  const authContext = useMemo<Context>(() => ({ loggedUserId }), [loggedUserId]);

  return (
    <AuthContext.Provider value={authContext}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = (): Context => {
  const authContext = useContext(AuthContext);
  if (authContext === undefined) {
    throw newError('authContext is missing in context');
  } else {
    return authContext;
  }
};

export default useAuth;
