import type { FunctionComponent, ReactNode } from 'react';

interface TableHeaderLineProps {
  height?: string,
  children?: ReactNode,
}

const TableHeaderLine: FunctionComponent<TableHeaderLineProps> = ({ height, children }) => (
  <tr
    style={{
      height: height ?? '3.8rem',
    }}
  >
    {children}
  </tr>
);

export default TableHeaderLine;
