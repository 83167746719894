import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import { compareNumber, compareString, createAutoProvisioningMap, extractAndCompareValue, filterNullOrUndefined } from 'yooi-utils';
import { getPointCoordinatesFromAnchorPosition } from './geometry/rectHelper';
import type Vector2 from './geometry/Vector2';
import SvgArrow from './SvgArrow';
import SvgArrowLabel from './SvgArrowLabel';
import SvgClickableArrow from './SvgClickableArrow';
import type { AnchorPositionType, ValidLineStyles, ValidSourceToTargetType } from './types';
import { ArrowSense } from './types';
import { HoverArrowContextProvider } from './useOnHoverArrowRef';

interface SvgArrowsProps {
  refs: Record<string, HTMLElement>,
  parentCoordinates: Vector2,
  sourceToTargetsMap: Record<string, ValidSourceToTargetType[]>,
}

const computeArrowDirectionVector = (anchorOrientation: AnchorPositionType): { arrowX: number, arrowY: number } => {
  switch (anchorOrientation) {
    case 'left':
      return { arrowX: -1, arrowY: 0 };
    case 'right':
      return { arrowX: 1, arrowY: 0 };
    case 'top':
      return { arrowX: 0, arrowY: -1 };
    case 'bottom':
      return { arrowX: 0, arrowY: 1 };
  }
};

const computeArrowPointAccordingToArrowHead = (
  xArrowHeadPoint: number,
  yArrowHeadPoint: number,
  arrowLength: number,
  strokeWidth: number,
  anchorOrientation: AnchorPositionType,
  lineStyle?: ValidLineStyles,
  xArrowStart?: number,
  yArrowStart?: number
): { xPoint: number, yPoint: number } => {
  let { arrowX, arrowY } = computeArrowDirectionVector(anchorOrientation);
  if (lineStyle === 'straight' && xArrowStart !== undefined && yArrowStart !== undefined) {
    const angle = Math.atan2(yArrowStart - yArrowHeadPoint, xArrowStart - xArrowHeadPoint);
    arrowX = Math.cos(angle);
    arrowY = Math.sin(angle);
  }
  const xPoint = xArrowHeadPoint + (arrowX * arrowLength * strokeWidth) / 2;
  const yPoint = yArrowHeadPoint + (arrowY * arrowLength * strokeWidth) / 2;
  return { xPoint, yPoint };
};

const SvgArrows: FunctionComponent<SvgArrowsProps> = ({ parentCoordinates, refs, sourceToTargetsMap }) => {
  const sourceToTargetsAutoProvisioningMap = createAutoProvisioningMap<string, ValidSourceToTargetType>();

  Object.values(sourceToTargetsMap).flat().forEach((sourceToTarget) => {
    const key = [sourceToTarget.source.id, sourceToTarget.target.id].sort(compareString).join('_');
    const previous = sourceToTargetsAutoProvisioningMap.getOrCreate(key, () => sourceToTarget);
    if (previous.lineType.layer > sourceToTarget.lineType.layer) {
      sourceToTargetsAutoProvisioningMap.set(key, sourceToTarget);
    }
  });

  const sourceToTargets = [...sourceToTargetsAutoProvisioningMap.values()];

  const resolvedSourceToTargets = sourceToTargets.map(({ label, lineType, source, target }) => {
    const startingAnchorOrientation = lineType.sense === ArrowSense.targetToSource ? source.anchor : target.anchor;
    const startingPoint = lineType.sense === ArrowSense.targetToSource
      ? getPointCoordinatesFromAnchorPosition(source.anchor, source.id, parentCoordinates, refs)
      : getPointCoordinatesFromAnchorPosition(target.anchor, target.id, parentCoordinates, refs);

    const endingAnchorOrientation = lineType.sense === ArrowSense.targetToSource ? target.anchor : source.anchor;
    const endingPoint = lineType.sense === ArrowSense.targetToSource
      ? getPointCoordinatesFromAnchorPosition(target.anchor, target.id, parentCoordinates, refs)
      : getPointCoordinatesFromAnchorPosition(source.anchor, source.id, parentCoordinates, refs);

    if (!startingPoint || !endingPoint) {
      return null;
    }
    // Starting point with arrow
    const { xPoint: xStart, yPoint: yStart } = computeArrowPointAccordingToArrowHead(
      startingPoint.x,
      startingPoint.y,
      lineType.marker.arrowLength * 2,
      lineType.strokeWidth,
      startingAnchorOrientation,
      lineType.lineStyle,
      endingPoint.x,
      endingPoint.y
    );
    // Ending point with arrow
    const { xPoint: xEnd, yPoint: yEnd } = computeArrowPointAccordingToArrowHead(
      endingPoint.x,
      endingPoint.y,
      0,
      lineType.strokeWidth,
      endingAnchorOrientation,
      lineType.lineStyle,
      startingPoint.x,
      startingPoint.y
    );
    return { key: `${source.id}:${target.id}`, label, lineType, xStart, xEnd, yStart, yEnd, startingAnchorOrientation };
  }).filter(filterNullOrUndefined);

  const arrows = useMemo(
    () => resolvedSourceToTargets.sort(extractAndCompareValue(({ lineType: { layer } }) => layer, compareNumber)),
    [resolvedSourceToTargets]
  );

  const hasSelectedArrows = useMemo(
    () => resolvedSourceToTargets.some(({ lineType: { showArrowLabel } }) => showArrowLabel),
    [resolvedSourceToTargets]
  );

  return (
    <HoverArrowContextProvider>
      <>
        {arrows.map(({ key, xStart, xEnd, yStart, yEnd, lineType, startingAnchorOrientation }) => (
          <SvgArrow key={key} xStart={xStart} xEnd={xEnd} yStart={yStart} yEnd={yEnd} lineType={lineType} markerOrientation={startingAnchorOrientation} />
        ))}
        {arrows.map(({ key, xStart, xEnd, yStart, yEnd, lineType, startingAnchorOrientation }) => (
          <SvgClickableArrow
            key={key}
            xStart={xStart}
            xEnd={xEnd}
            yStart={yStart}
            yEnd={yEnd}
            lineType={lineType}
            markerOrientation={startingAnchorOrientation}
            arrowKey={key}
            hasSelectedArrows={hasSelectedArrows}
          />
        ))}
        {resolvedSourceToTargets
          .map(({ key, label, xStart, xEnd, yStart, yEnd, lineType: { showArrowLabel } }) => (
            <SvgArrowLabel key={key} xStart={xStart} xEnd={xEnd} yStart={yStart} yEnd={yEnd} arrowLabel={label} withArrowLabel={showArrowLabel} arrowKey={key} />
          ))}
      </>
    </HoverArrowContextProvider>
  );
};

export default SvgArrows;
