import { Field_Documentation, Field_Title } from 'yooi-modules/modules/conceptModule/ids';
import { subscriptionsFieldHandler } from 'yooi-modules/modules/dashboardModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { IconName } from '../../../../components/atoms/Icon';
import i18n from '../../../../utils/i18n';
import { registerFieldDefinition } from '../FieldLibrary';
import type { GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import SubscriptionsWidget from './SubscriptionsWidget';

type SubscriptionsFieldDefinition = GetFieldDefinitionHandler<typeof subscriptionsFieldHandler, Record<string, never>>;

export const subscriptionsFieldDefinition: SubscriptionsFieldDefinition = registerFieldDefinition(subscriptionsFieldHandler, {
  configuration: {
    typeIcon: IconName.notifications,
    getTypeLabel: () => i18n`Subscriptions`,
    asWidget: true,
    getEditionOptions: () => () => [],
    ofField: (objectStore, fieldId) => ({
      getInitialState: () => ({}),
      submitFieldUpdate: () => {
        objectStore.updateObject(fieldId, {});
      },
      duplicateFieldDefinition: () => {
        const fieldInstance = objectStore.getObject(fieldId);
        return objectStore.createObject({
          [Instance_Of]: fieldInstance[Instance_Of],
          [Field_Title]: `${fieldInstance[Field_Title]} (copy)`,
          [Field_Documentation]: fieldInstance[Field_Documentation],
        });
      },
    }),
  },
  renderWidget: () => () => (<SubscriptionsWidget />),
});
