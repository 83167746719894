import type { FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { getPathReturnedConceptDefinitionId } from 'yooi-modules/modules/conceptModule';
import type { GraphChartFieldConfiguration } from 'yooi-modules/modules/dashboardModule';
import type { Block, Column } from 'yooi-modules/modules/dashboardModule/fields/graphChartField';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import BlockContent from '../../../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../../../components/templates/VerticalBlock';
import useStore from '../../../../../store/useStore';
import i18n from '../../../../../utils/i18n';
import useDeepMemo from '../../../../../utils/useDeepMemo';
import type { OptionRecord } from '../../../modelTypeUtils';
import GraphChartBlockCard, { getBlockLabel } from './GraphChartBlockCard';
import GraphChartColumnsConfiguration from './GraphChartColumnsConfiguration';

interface FlattenedBlock {
  columnId: string,
  block: Block,
  index: number,
}

interface GraphPathEditorRendererProps {
  value?: GraphChartFieldConfiguration,
  onChange: (value: GraphChartFieldConfiguration | null) => void,
  parameterDefinitions: SingleParameterDefinition[],
}

const getBlock = (columns: Column[], blockId: string | undefined): { columnIndex: number, block: Block } | undefined => {
  if (blockId) {
    for (let i = 0; i < columns.length; i += 1) {
      const column = columns[i];
      const block = column.blocks.find(({ id }) => id === blockId);
      if (block) {
        return { block, columnIndex: i };
      }
    }
  }
  return undefined;
};

const GraphPathEditorRenderer: FunctionComponent<GraphPathEditorRendererProps> = ({ value, onChange, parameterDefinitions }) => {
  const store = useStore();

  const newInstanceOperationIdRef = useRef<string | undefined>();

  const columns = value?.columns ?? [];
  const [selectedBlockId, setSelectedBlockId] = useState<string | undefined>(columns.at(0)?.blocks.at(0)?.id);

  const { selectedBlock, selectedColumnIndex, inheritedParameterDefinitions } = useDeepMemo(() => {
    const getBlockResult = getBlock(columns, selectedBlockId);
    if (getBlockResult) {
      const { block, columnIndex } = getBlockResult;
      if (columnIndex > 0) {
        const previousColumn = columns[columnIndex - 1];
        return {
          selectedBlock: block,
          selectedColumnIndex: columnIndex,
          inheritedParameterDefinitions: previousColumn.blocks.map(({ id: blockId, path, label }, blockIndex): SingleParameterDefinition | undefined => {
            const conceptDefinitionId = getPathReturnedConceptDefinitionId(store, path ?? []);
            if (conceptDefinitionId) {
              return { type: 'parameter', typeId: conceptDefinitionId, label: getBlockLabel(store, blockIndex, path, label), id: blockId };
            } else {
              return undefined;
            }
          }).filter(filterNullOrUndefined),
        };
      } else {
        return { selectedBlock: block, selectedColumnIndex: columnIndex, inheritedParameterDefinitions: undefined };
      }
    }
    return { selectedBlock: undefined, selectedColumnIndex: undefined, inheritedParameterDefinitions: undefined };
  }, [selectedBlockId, columns, store.getSerial()]);

  const onUpdateColumns = (newColumns: Column[]) => {
    onChange(joinObjects(
      value ?? {},
      {
        columns: newColumns
          .filter((column) => column.blocks.length)
          .map((column) => (joinObjects(
            column,
            { blocks: column.blocks.map((block, index) => (joinObjects(block, { index }))) }
          ))),
      }
    ));
  };

  const columnsToFlattenedBlocks = (columnsToFlatten: Column[]): FlattenedBlock[] => columnsToFlatten
    .flatMap(({ id, blocks }, index) => blocks
      .map((block) => ({ index, columnId: id, block })));

  const flattenedBlocksToColumns = (flattenedBlocks: FlattenedBlock[]): Column[] => flattenedBlocks.reduce<Column[]>((acc, { columnId, index, block }) => {
    acc[index] = { id: columnId, blocks: [...acc[index]?.blocks ?? [], block] } satisfies Column;
    return acc;
  }, []);

  const onMove = (blockId: string, direction: 'left' | 'right') => {
    let flattenedBlocks = columnsToFlattenedBlocks(value?.columns ?? []);

    const fromFlattenedBlock = flattenedBlocks.find(({ block: { id } }) => blockId === id);
    if (!fromFlattenedBlock) {
      return;
    }

    const from = flattenedBlocks.findIndex(({ block: { id } }) => blockId === id);
    const to = direction === 'right' ? from + 1 : from - 1;

    if (to === -1 || to >= flattenedBlocks.length) {
      flattenedBlocks = flattenedBlocks.map((column) => {
        if (direction === 'right' && column.block.id === blockId) {
          return joinObjects(column, { index: column.index + 1, columnId: uuid() });
        } else if (direction === 'left' && column.block.id === blockId) {
          return joinObjects(column, { columnId: uuid() });
        } else if (direction === 'left') {
          return joinObjects(column, { index: column.index + 1 });
        } else {
          return column;
        }
      });
    } else {
      const toFlattenedBlock = flattenedBlocks[to];
      if (toFlattenedBlock.columnId === fromFlattenedBlock.columnId) {
        flattenedBlocks.splice(from, 0, flattenedBlocks.splice(to, 1)[0]);
      } else {
        flattenedBlocks = flattenedBlocks.map((flattenedBlock) => {
          if (flattenedBlock.block.id === blockId && direction === 'right') {
            return joinObjects(flattenedBlock, { index: toFlattenedBlock.index, columnId: toFlattenedBlock.columnId });
          } else if (flattenedBlock.block.id === blockId && direction === 'left') {
            return joinObjects(flattenedBlock, { index: toFlattenedBlock.index, columnId: toFlattenedBlock.columnId });
          } else {
            return flattenedBlock;
          }
        });
      }
    }
    onUpdateColumns(flattenedBlocksToColumns(flattenedBlocks));
  };

  const align = value?.graphStyle ?? 'center';
  const alignOptions: OptionRecord<'top' | 'center'> = {
    top: { id: 'top', label: i18n`Top` },
    center: { id: 'center', label: i18n`Center` },
  };

  return (
    <>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Align`} />
        <BlockContent>
          <SearchAndSelect
            computeOptions={() => Object.values(alignOptions)}
            selectedOption={alignOptions[align]}
            onSelect={(option) => {
              if (option) {
                onChange(joinObjects(value, { graphStyle: option.id }));
              }
            }}
          />
        </BlockContent>
      </HorizontalBlock>
      <VerticalBlock asBlockContent>
        <BlockContent hideOverflowX>
          <GraphChartColumnsConfiguration
            config={value}
            graphStyle={align}
            selectedBlockId={selectedBlockId}
            onBlockSelected={(newBlockId) => setSelectedBlockId((oldBlockId) => {
              if (oldBlockId !== newBlockId) {
                return newBlockId;
              } else {
                return undefined;
              }
            })}
            onAddBlock={(columnId) => {
              const blockId = uuid();
              newInstanceOperationIdRef.current = blockId;
              if (!columnId) {
                onUpdateColumns([
                  ...columns,
                  { id: uuid(), label: undefined, blocks: [{ id: blockId, index: 0 }] },
                ]);
              } else {
                onUpdateColumns(columns.map((column) => {
                  if (column.id === columnId) {
                    return joinObjects(column, { blocks: [...column.blocks, { id: blockId, index: column.blocks.length }] });
                  } else {
                    return column;
                  }
                }));
              }
              setSelectedBlockId(blockId);
            }}
            onDeleteBlock={(columnId: string, blockId: string) => {
              let previousBlockId;
              let previousOrNextBlockId;
              let shouldGetNextBlockId = false;
              for (let i = 0; i < columns.length && !previousOrNextBlockId; i += 1) {
                const column = columns[i];
                for (let j = 0; j < column.blocks.length && !previousOrNextBlockId; j += 1) {
                  const block = column.blocks[j];
                  if (shouldGetNextBlockId) {
                    previousOrNextBlockId = block.id;
                  }
                  if (block.id === blockId) {
                    if (previousBlockId) {
                      previousOrNextBlockId = previousBlockId;
                    } else {
                      shouldGetNextBlockId = true;
                    }
                  }
                  previousBlockId = block.id;
                }
              }
              onUpdateColumns(columns.map((column) => {
                if (column.id === columnId) {
                  return joinObjects(
                    column,
                    {
                      blocks: column.blocks
                        .filter(({ id }) => id !== blockId)
                        .map((block, blockIndex) => (joinObjects(block, { index: blockIndex }))),
                    }
                  );
                } else {
                  return column;
                }
              }));
              setSelectedBlockId(previousOrNextBlockId);
            }}
            onDuplicateBlock={(columnId: string, blockId: string) => {
              const newUUID = uuid();
              onUpdateColumns(columns.map((column) => {
                if (column.id === columnId) {
                  const index = column.blocks.findIndex(({ id }) => id === blockId);
                  if (index !== -1) {
                    return joinObjects(
                      column,
                      {
                        blocks: column.blocks
                          .flatMap((block) => {
                            if (block.id === blockId) {
                              return [block, joinObjects(block, { id: newUUID })];
                            } else {
                              return [block];
                            }
                          }).map((block, blockIndex) => (joinObjects(block, { index: blockIndex }))),
                      }
                    );
                  } else {
                    return column;
                  }
                } else {
                  return column;
                }
              }));
              setSelectedBlockId(newUUID);
            }}
            onMoveBlockLeft={(blockId) => onMove(blockId, 'left')}
            onMoveBlockRight={(blockId) => onMove(blockId, 'right')}
          />
        </BlockContent>
      </VerticalBlock>
      {selectedBlock && selectedColumnIndex !== undefined && (
        <VerticalBlock asBlockContent>
          <BlockTitle
            title={i18n`Configuration`}
            variant={BlockTitleVariant.inline}
          />
          <BlockContent padded>
            <GraphChartBlockCard
              block={selectedBlock}
              columnIndex={selectedColumnIndex}
              onChange={(newBlock) => {
                if (value) {
                  onChange(joinObjects(
                    value,
                    {
                      columns: (value.columns ?? []).map((column) => (joinObjects(
                        column,
                        {
                          blocks: column.blocks.map((block) => {
                            if (block.id === newBlock.id) {
                              return newBlock;
                            } else {
                              return block;
                            }
                          }),
                        }
                      ))),
                    }
                  ));
                }
              }}
              parameterDefinitions={parameterDefinitions}
              inheritedParameterDefinitions={inheritedParameterDefinitions}
              itemPerPage={columns[selectedColumnIndex]?.itemPerPage}
              onChangePagination={(newItemPerPage) => {
                if (value) {
                  onChange(joinObjects(
                    value,
                    {
                      columns: (value.columns ?? []).map((column) => {
                        if (column.id === columns[selectedColumnIndex]?.id) {
                          const newColumn = { ...column };
                          delete newColumn.itemPerPage;
                          return joinObjects(newColumn, { itemPerPage: newItemPerPage });
                        } else {
                          return column;
                        }
                      }),
                    }
                  ));
                }
              }}
            />
          </BlockContent>
        </VerticalBlock>
      )}
    </>
  );
};

export default GraphPathEditorRenderer;
