// eslint-disable-next-line max-classes-per-file
import { StructuredError } from '../../errorUtils';
import type { FormulaType } from '../engine/formula';

export class FunctionMissingArgumentError extends StructuredError {
  override readonly name = 'FunctionMissingArgumentError';

  constructor(functionName: string, missingArgumentIndex: number) {
    super('The function is missing some arguments', { data: { functionName, missingArgumentIndex } });
  }
}

export class FunctionUnexpectedArgumentError extends StructuredError {
  override readonly name = 'FunctionUnexpectedArgumentError';

  constructor(functionName: string, unexpectedArgumentIndex: number) {
    super('The function has an unexpected argument', { data: { functionName, unexpectedArgumentIndex } });
  }
}

export class FunctionInvalidArgumentTypeError extends StructuredError {
  override readonly name = 'FunctionInvalidArgumentTypeError';

  constructor(functionName: string, expectedType: FormulaType | FormulaType[], argType: FormulaType, invalidArgumentIndex: number) {
    super('The function argument type is invalid', { data: { functionName, expectedType, argType, invalidArgumentIndex } });
  }
}
