import type { FunctionComponent } from 'react';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Typo from '../../../../components/atoms/Typo';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import BlockContent from '../../../../components/templates/BlockContent';
import i18n from '../../../../utils/i18n';
import useTheme from '../../../../utils/useTheme';

interface BlockViewErrorProps {
  error: string,
}

const BlockViewError: FunctionComponent<BlockViewErrorProps> = ({ error }) => {
  const theme = useTheme();

  return (
    <BlockContent padded>
      <SpacingLine>
        <Icon name={IconName.dangerous} colorVariant={IconColorVariant.error} tooltip={error} />
        <Typo color={theme.color.text.secondary} maxLine={1}>{i18n`Invalid configuration`}</Typo>
      </SpacingLine>
    </BlockContent>
  );
};

export default BlockViewError;
