import type { CardColorMode, PathStep } from 'yooi-modules/modules/conceptModule';
import type { CardsViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import { ViewType } from 'yooi-modules/modules/dashboardModule';
import { joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import i18n from '../../../../utils/i18n';
import type { CommonViewResolvedDefinition } from '../common/commonViewResolvedDefinition';
import type { ViewDefinitionHandler } from '../viewDsl';
import { registerView } from '../viewDsl';
import CardsViewBlock from './CardsViewBlock';
import CardsViewDefinitionOptions from './CardsViewDefinitionOptions';
import type { CardsViewResolution } from './cardsViewResolution';
import { resolveCardsView } from './cardsViewResolution';
import CardsViewWidget from './CardsViewWidget';

export interface CardsViewResolvedDefinition extends CommonViewResolvedDefinition {
  type: ViewType.Cards,
  color: { path: PathStep[], as: CardColorMode } | undefined,
  icon: { path: PathStep[] } | undefined,
  boolean: { path: PathStep[] } | undefined,
  header: { id: string, path: PathStep[], displayOptions: Record<string, unknown> }[],
  body: { id: string, label: string | undefined, path: PathStep[], displayOptions: Record<string, unknown> }[],
  layout: { columns: number | 'auto', lines: number | 'auto' },
  sort: { path: PathStep[], direction: 'asc' | 'desc' } | undefined,
}

export type CardsViewDefinitionHandler = ViewDefinitionHandler<CardsViewStoredDefinition, CardsViewResolvedDefinition, CardsViewResolution>;

export const cardsViewHandler = registerView<CardsViewStoredDefinition, CardsViewResolvedDefinition, CardsViewResolution>({
  type: ViewType.Cards,
  icon: IconName.ballot,
  getLabel: () => i18n`Cards gallery`,
  optionType: undefined,
  withFilters: true,
  withExport: false,
  resolveView: (store, viewDefinition, { aclHandler, viewDimensions, parametersMapping, filterConfiguration, readOnly }) => resolveCardsView(
    store,
    aclHandler,
    viewDimensions,
    viewDefinition,
    parametersMapping,
    filterConfiguration,
    readOnly
  ),
  renderBlock: (_, viewDefinition, { viewDimensions, viewFilters, layoutParametersMapping, readOnly }) => (
    <CardsViewBlock
      viewDefinition={viewDefinition}
      viewDimensions={viewDimensions}
      viewFilters={viewFilters}
      parametersMapping={layoutParametersMapping}
      readOnly={readOnly}
    />
  ),
  renderWidget: (_, viewDefinition, { widgetId, viewDimensions, viewFilters, parametersMapping, width, height, readOnly }) => (
    <CardsViewWidget
      widgetId={widgetId}
      viewDimensions={viewDimensions}
      viewFilters={viewFilters}
      viewDefinition={viewDefinition}
      parametersMapping={parametersMapping}
      width={width}
      height={height}
      readOnly={readOnly}
    />
  ),
  renderDefinitionOptions: (_, viewDefinition, { viewDimensions, updateViewDefinition, readOnly, parameterDefinitions }) => (
    <CardsViewDefinitionOptions
      viewDimensions={viewDimensions}
      viewDefinition={viewDefinition}
      updateViewDefinition={updateViewDefinition}
      readOnly={readOnly}
      parameterDefinitions={parameterDefinitions}
    />
  ),
  getDefinitionErrors: () => undefined,
  resolveDefinition: (_, viewDefinition) => ({
    id: viewDefinition.id,
    type: viewDefinition.type,
    label: viewDefinition.label !== undefined && viewDefinition.label !== '' ? viewDefinition.label : i18n`Cards gallery`,
    readOnly: viewDefinition.readOnly ?? false,
    color: viewDefinition.color !== undefined && viewDefinition.color.path.length > 0 ? viewDefinition.color : undefined,
    icon: viewDefinition.icon !== undefined && viewDefinition.icon.path.length > 0 ? viewDefinition.icon : undefined,
    boolean: viewDefinition.boolean !== undefined && viewDefinition.boolean.path.length > 0 ? viewDefinition.boolean : undefined,
    header: viewDefinition.header?.map(({ displayOptions, ...props }) => (joinObjects(props, { displayOptions: displayOptions ?? {} }))) ?? [],
    body: viewDefinition.body?.map(({ displayOptions, ...props }) => (joinObjects(props, { displayOptions: displayOptions ?? {} }))) ?? [],
    layout: { columns: viewDefinition.layout?.columns ?? 'auto', lines: viewDefinition.layout?.lines ?? 'auto' },
    sort: viewDefinition.sort === undefined || viewDefinition.sort.path.length === 0 ? undefined : viewDefinition.sort,
  } satisfies CardsViewResolvedDefinition),
  getInitialStoredDefinition: (id) => ({ id, type: ViewType.Cards }),
});
