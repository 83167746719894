import { forwardRef } from 'react';
import type { ConceptStoreObject, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { NavigationPayload } from '../../../../../utils/useNavigation';
import type { NavigationFilter } from '../../../navigationUtils';
import type { CardBooleanField, CardIconField, ColorColorField, ColorInstance } from '../../cards/cardsViewResolution';
import type { InstanceCardBody, InstanceCardHeader } from '../../cards/cardsViewUtils';
import InstanceCard from '../../cards/InstanceCard';
import InstanceChip from './InstanceChip';

interface SwimlaneV2ConceptChipProps {
  instance: ConceptStoreObject,
  width: number,
  header: InstanceCardHeader,
  body: InstanceCardBody,
  color: ColorInstance | ColorColorField | undefined,
  icon: CardIconField | undefined,
  boolean: CardBooleanField | undefined,
  parametersMapping: ParametersMapping,
  inEdition?: boolean,
  onEditionStart?: () => void,
  onEditionEnd?: () => void,
  navigationFilters?: NavigationFilter,
  getNavigationPayload?: () => (NavigationPayload | undefined),
  showTooltip: boolean,
}

const ViewConceptSwimlaneChip = forwardRef<HTMLDivElement, SwimlaneV2ConceptChipProps>(({
  instance,
  width,
  header,
  body,
  color,
  icon,
  boolean,
  parametersMapping,
  inEdition,
  onEditionStart,
  onEditionEnd,
  navigationFilters,
  getNavigationPayload,
  showTooltip,
}, ref) => (
  <InstanceChip
    ref={ref}
    instanceId={instance.id}
    width={width}
    inEdition={inEdition}
    onEditionStart={onEditionStart}
    onEditionEnd={onEditionEnd}
    navigationFilters={navigationFilters}
    renderTooltip={showTooltip && (header.length > 0 || body.length > 0)
      ? (editMode) => (
        <InstanceCard
          header={header}
          body={body}
          color={color}
          icon={icon}
          boolean={boolean}
          readOnly={!editMode}
          parametersMapping={parametersMapping}
          withEditionMode
          editMode={editMode}
          getNavigationPayload={getNavigationPayload}
        />
      ) : undefined}
  />
));

export default ViewConceptSwimlaneChip;
