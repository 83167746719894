import classnames from 'classnames';
import type { ScaleContinuousNumeric, ScaleTime } from 'd3-scale';
import type { FunctionComponent } from 'react';
import base from '../../../theme/base';
import { spacingRem } from '../../../theme/spacingDefinition';
import makeStyles from '../../../utils/makeStyles';
import { remToPx } from '../../../utils/sizeUtils';
import Typo, { TypoVariant } from '../../atoms/Typo';
import Tick, { TickDirection as AxisBaseDirection, TickVariant as AxisBaseVariant } from './Tick';

export { TickDirection as AxisDirection } from './Tick';
export { TickVariant as AxisVariant } from './Tick';

const axeWidthRem = '0.1rem';
export const axisNameContainerHeightRem = 2.6;

const useStyles = makeStyles((theme) => ({
  tickLine: {
    strokeWidth: axeWidthRem,
    transition: 'opacity 200ms ease-in',
  },
  tickLine_grey: {
    stroke: theme.color.border.default,
  },
  tickLine_black: {
    stroke: theme.color.text.primary,
  },
  axisNameContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: `${axisNameContainerHeightRem}rem`,
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
  },
  axisName: {
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    paddingBottom: spacingRem.xs,
    paddingTop: spacingRem.xs,
    background: theme.color.background.neutral.subtle,
    height: `${axisNameContainerHeightRem}rem`,
    pointerEvents: 'all',
    width: 'fit-content',
  },
  axisNameTopRadius: {
    borderTopLeftRadius: base.borderRadius.medium,
    borderTopRightRadius: base.borderRadius.medium,
  },
  axisNameBottomRadius: {
    borderBottomLeftRadius: base.borderRadius.medium,
    borderBottomRightRadius: base.borderRadius.medium,
  },
  container: {
    shapeRendering: 'crispEdges',
  },
  foreignObject: {
    pointerEvents: 'none',
  },
}), 'axis');

const getArrowCoordinates = (direction: AxisBaseDirection, axisLineCoordinates: AxisCoordinates, isReversed: boolean) => {
  const basePoint = {
    x: axisLineCoordinates.x2,
    y: axisLineCoordinates.y2,
  };
  const factor = (isReversed) ? -1 : 1;
  if (direction === AxisBaseDirection.left || direction === AxisBaseDirection.right) {
    return [basePoint, {
      x: basePoint.x + 5,
      y: basePoint.y - factor * 5,
    }, {
      x: basePoint.x - 5,
      y: basePoint.y - factor * 5,
    }];
  } else {
    return [basePoint, {
      x: basePoint.x - factor * 5,
      y: basePoint.y + 5,
    }, {
      x: basePoint.x - factor * 5,
      y: basePoint.y - 5,
    }];
  }
};

const getNameContainerPosition = (axisLineCoordinates: AxisCoordinates, direction: AxisBaseDirection, height: number) => {
  const axesWidth = remToPx(axeWidthRem);

  switch (direction) {
    case AxisBaseDirection.left:
      return {
        x: axisLineCoordinates.x1 + height + axesWidth,
        y: 0,
      };
    case AxisBaseDirection.bottom:
      return {
        x: 0,
        y: axisLineCoordinates.y1 - height - axesWidth,
      };
    case AxisBaseDirection.right:
      return {
        x: axisLineCoordinates.x1 - axesWidth,
        y: 0,
      };
    case AxisBaseDirection.top:
      return {
        x: 0,
        y: axisLineCoordinates.y1 + axesWidth,
      };
  }
};

interface AxisCoordinates {
  x1: number,
  y1: number,
  x2: number,
  y2: number,
}

const getAxisCoordinates = (
  direction: AxisBaseDirection,
  scale: ScaleContinuousNumeric<number, number> | ScaleTime<number, number>,
  initialCoordinate: number,
  axisEdgeMargin: number
): AxisCoordinates => {
  // x1 x2  correspond to the lower value side of the line
  const firstIsLowerDomain = scale.domain()[0] <= scale.domain()[1] ? [scale.range()[0], scale.range()[1]] : [scale.range()[1], scale.range()[0]];
  if (direction === AxisBaseDirection.left || direction === AxisBaseDirection.right) {
    return {
      x1: direction === AxisBaseDirection.left ? initialCoordinate : 0,
      y1: firstIsLowerDomain[0] + axisEdgeMargin,
      x2: direction === AxisBaseDirection.left ? initialCoordinate : 0,
      y2: firstIsLowerDomain[1] - axisEdgeMargin,
    };
  } else {
    return {
      x1: firstIsLowerDomain[0] - axisEdgeMargin,
      y1: direction === AxisBaseDirection.bottom ? initialCoordinate : 0,
      x2: firstIsLowerDomain[1] + axisEdgeMargin,
      y2: direction === AxisBaseDirection.bottom ? initialCoordinate : 0,
    };
  }
};

const isScaleReversed = (scale: ScaleContinuousNumeric<number, number> | ScaleTime<number, number>): boolean => {
  const [rawDomainAt0, rawDomainAt1] = scale.domain();
  const domainAt0 = typeof rawDomainAt0 === 'number' ? rawDomainAt0 : rawDomainAt0.getTime();
  const domainAt1 = typeof rawDomainAt1 === 'number' ? rawDomainAt1 : rawDomainAt1.getTime();

  const [rangeAt0, rangeAt1] = scale.range();

  return (domainAt1 - domainAt0) * (rangeAt1 - rangeAt0) <= 0;
};

export interface AxisBaseTick {
  id: string,
  label: string,
  position: number,
  size?: number,
  margin?: number,
  ellipsed?: boolean,
}

interface AxisProps {
  scale: ScaleContinuousNumeric<number, number> | ScaleTime<number, number>,
  initialCoordinate?: number,
  direction: AxisBaseDirection,
  axisName?: string,
  ticks: AxisBaseTick[],
  variant: AxisBaseVariant,
  showArrow?: boolean,
  axisEdgeMargin?: number,
}

const Axis: FunctionComponent<AxisProps> = ({
  scale,
  initialCoordinate = 0,
  direction = AxisBaseDirection.left,
  axisName,
  ticks,
  variant = AxisBaseVariant.black,
  showArrow = false,
  axisEdgeMargin = 0,
}) => {
  const classes = useStyles();

  const isReversed = isScaleReversed(scale);
  const axisLineCoordinates = getAxisCoordinates(direction, scale, initialCoordinate, axisEdgeMargin);
  const arrowCoordinates = getArrowCoordinates(direction, axisLineCoordinates, isReversed);
  const key = `${direction}x${initialCoordinate}x${axisLineCoordinates.y2 - axisLineCoordinates.y1}x${axisLineCoordinates.x1 - axisLineCoordinates.x2}x${ticks.length}`;

  const renderName = () => {
    if (axisName) {
      const nameContainerHeight = remToPx(axisNameContainerHeightRem);

      const nameCoordinates = getNameContainerPosition(axisLineCoordinates, direction, nameContainerHeight);
      const nameRotation = direction === AxisBaseDirection.left || direction === AxisBaseDirection.right ? 'rotate(90)' : 'rotate(0)';

      const width = direction === AxisBaseDirection.left || direction === AxisBaseDirection.right
        ? Math.abs(axisLineCoordinates.y1 - axisLineCoordinates.y2)
        : Math.abs(axisLineCoordinates.x1 - axisLineCoordinates.x2);

      return (
        <foreignObject
          width={width}
          height={nameContainerHeight}
          className={classes.foreignObject}
          transform={`translate(${nameCoordinates.x} ${nameCoordinates.y}) ${nameRotation}`}
        >
          <span className={classes.axisNameContainer}>
            <span
              className={classnames({
                [classes.axisName]: true,
                [classes.axisNameTopRadius]: direction === AxisBaseDirection.bottom || direction === AxisBaseDirection.left,
                [classes.axisNameBottomRadius]: direction === AxisBaseDirection.top || direction === AxisBaseDirection.right,
              })}
            >
              <Typo maxLine={1} variant={TypoVariant.small}>{axisName}</Typo>
            </span>
          </span>
        </foreignObject>
      );
    } else {
      return null;
    }
  };

  return (
    <g key={key} className={classes.container}>
      <line
        className={classnames(classes.tickLine, classes[`tickLine_${variant}`])}
        x1={axisLineCoordinates.x1}
        x2={axisLineCoordinates.x2}
        y1={axisLineCoordinates.y1}
        y2={axisLineCoordinates.y2}
      />
      {
        showArrow
          ? (
            <>
              <line
                className={classnames(classes.tickLine, classes[`tickLine_${variant}`])}
                x1={arrowCoordinates[0].x}
                x2={arrowCoordinates[1].x}
                y1={arrowCoordinates[0].y}
                y2={arrowCoordinates[1].y}
              />
              <line
                className={classnames(classes.tickLine, classes[`tickLine_${variant}`])}
                x1={arrowCoordinates[0].x}
                x2={arrowCoordinates[2].x}
                y1={arrowCoordinates[0].y}
                y2={arrowCoordinates[2].y}
              />
            </>
          )
          : null
      }
      {ticks.map((tick) => (
        <Tick
          key={tick.id}
          id={tick.id}
          label={tick.label}
          position={tick.position}
          initialCoordinate={initialCoordinate}
          direction={direction}
          size={tick.size}
          variant={variant}
          margin={tick.margin}
          ellipsed={tick.ellipsed}
        />
      ))}
      {renderName()}
    </g>
  );
};

export default Axis;
