import { PlatformCapabilityAdmin, PlatformCapabilityInviteUser, PlatformCapabilityUpdateEmail } from 'yooi-modules/modules/conceptModule/ids';
import i18n from '../../utils/i18n';

export const platformCapabilities: { [key in string]: { id: key, name: string, description: string } } = {
  [PlatformCapabilityAdmin]: {
    id: PlatformCapabilityAdmin,
    name: i18n`Admin access`,
    description: i18n`Grants the ability to administrate the platform & to invite users.`,
  },
  [PlatformCapabilityInviteUser]: {
    id: PlatformCapabilityInviteUser,
    name: i18n`Invite users`,
    description: i18n`Allows to give access to the platform to new users.`,
  },
  [PlatformCapabilityUpdateEmail]: {
    id: PlatformCapabilityUpdateEmail,
    name: i18n`Edit users email`,
    description: i18n`Allows to update users email address.`,
  },
};
