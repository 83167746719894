import classnames from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';
import { isFiniteNumber, joinObjects } from 'yooi-utils';
import base from '../../../theme/base';
import { getMostReadableColorFromBackgroundColor } from '../../../theme/colorUtils';
import { getSpacing, Spacing, spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { remToPx } from '../../../utils/sizeUtils';
import { formatOrUndef } from '../../../utils/stringUtils';
import useTheme from '../../../utils/useTheme';
import type { IconColorVariant } from '../../atoms/Icon';
import Icon, { IconName } from '../../atoms/Icon';
import Tooltip from '../../atoms/Tooltip';
import Typo, { TypoVariant } from '../../atoms/Typo';
import { TableSortDirection } from '../../molecules/Table';
import TableHeader from '../../molecules/TableHeader';
import TableHeaderCell, { HeaderVariant } from '../../molecules/TableHeaderCell';
import TableHeaderLine from '../../molecules/TableHeaderLine';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerContainerCentered: {
    justifyContent: 'center',
  },
  headerNameContainer: {
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    columnGap: spacingRem.xs,
    marginLeft: spacingRem.s,
    marginRight: spacingRem.s,
  },
  groupContainer: {
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    columnGap: spacingRem.xs,
    flexGrow: 1,
    justifyContent: 'center',
    borderRadius: base.borderRadius.medium,
  },
  groupContainerPadding: {
    padding: spacingRem.xs,
  },
  groupHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    userSelect: 'none',
    marginLeft: spacingRem.s,
    marginRight: spacingRem.s,
  },
}, 'dataTableHeaderRenderer');

export enum GroupVariant {
  'group' = 'group',
  'header' = 'header',
}

export interface ColumnDefinition {
  key?: string,
  propertyId: string,
  name?: string,
  tooltip?: string,
  hideHeader?: boolean,
  headerRender?: (sort: 'asc' | 'desc' | undefined) => ReactElement | null,
  width?: number | string,
  sortable?: boolean,
  noSeparator?: boolean,
  action?: boolean,
  icon?: { iconName: IconName, text?: string, color?: IconColorVariant },
  groups?: { id: string, label: string, tooltip: string, color?: string, render?: () => ReactElement, variant: GroupVariant }[],
}

interface Group {
  id: string,
  label: string | undefined,
  tooltip: string | undefined,
  color: string | undefined,
  nbEl: number,
  width: number,
  hidden?: boolean,
  render?: () => ReactElement,
  variant: GroupVariant,
}

interface DataTableHeaderRendererProps {
  containerWidth: number,
  columnsDefinition: ColumnDefinition[],
  doSort: ((propertyId: string) => void) | undefined,
  sortCriteria: { key: string, direction: TableSortDirection } | undefined,
  getCellBorderBottomColor?: (columnId: string) => string | undefined,
  separateHeaderFromBody?: boolean,
  withoutHeaderLine?: boolean,
  centerHeader?: boolean,
  minColumnWidthRem?: number | undefined,
}

const DataTableHeaderRenderer: FunctionComponent<DataTableHeaderRendererProps> = ({
  containerWidth,
  columnsDefinition,
  doSort,
  sortCriteria,
  getCellBorderBottomColor,
  separateHeaderFromBody = false,
  withoutHeaderLine = false,
  centerHeader = false,
  minColumnWidthRem,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const borderWidthInPx = remToPx(0.1);
  const widthWithoutBorders = containerWidth - (borderWidthInPx * (columnsDefinition.length + 1)); /* table border size */

  const sizeDefinition = columnsDefinition
    .reduce(({ width, columnFixedPercents, autoSizeColumn }, column) => {
      if (column.action) {
        return {
          width: Math.max(width - remToPx(4.2), 0),
          columnFixedPercents,
          autoSizeColumn,
        };
      } else if (typeof column.width === 'number') {
        return {
          width,
          autoSizeColumn,
          columnFixedPercents: columnFixedPercents + Math.max(column.width, 0),
        };
      }

      const remValue = `${column.width}`.match(/^(\d+(?:\.\d+)?)rem$/);
      const pxValue = `${column.width}`.match(/^(\d+(?:\.\d+)?)px$/);
      if (remValue) {
        return {
          width: Math.max(width - remToPx(Number(remValue[1])), 0),
          columnFixedPercents,
          autoSizeColumn,
        };
      } else if (pxValue) {
        return {
          width: Math.max(width - Number(pxValue[1]), 0),
          columnFixedPercents,
          autoSizeColumn,
        };
      } else {
        return {
          width,
          autoSizeColumn: autoSizeColumn + 1,
          columnFixedPercents,
        };
      }
    }, { width: widthWithoutBorders, columnFixedPercents: 0, autoSizeColumn: 0 });

  const fixedPercentsWidth = sizeDefinition.width * (sizeDefinition.columnFixedPercents / 100);
  const autoSizeWidth = Math.max(sizeDefinition.autoSizeColumn > 0 ? (sizeDefinition.width - fixedPercentsWidth) / sizeDefinition.autoSizeColumn : 0, remToPx(20));

  const minColumnWidthPx = minColumnWidthRem === undefined ? 0 : remToPx(minColumnWidthRem);
  const columnDefinitionWithWidth = columnsDefinition.map((c, _, self) => {
    const columnWidth = c.width;
    const remValue = `${columnWidth}`.match(/^(\d+(?:\.\d+)?)rem$/);
    const pxValue = `${columnWidth}`.match(/^(\d+(?:\.\d+)?)px$/);
    let width;
    if (c.action) {
      if (self.length === 1) {
        width = widthWithoutBorders;
      } else {
        width = remToPx(4.2);
      }
    } else if (remValue) {
      width = remToPx(Number(remValue[1]));
    } else if (pxValue) {
      width = Number(pxValue[1]);
    } else if (typeof columnWidth === 'number') {
      width = sizeDefinition.width * Math.max(columnWidth, 0) * 0.01;
    } else {
      width = autoSizeWidth;
    }

    width += borderWidthInPx;

    return (joinObjects(c, { width: Math.max(minColumnWidthPx, width) }));
  });

  const groupNumber = columnDefinitionWithWidth.reduce((acc, { groups }) => Math.max(acc, groups?.length ?? 0), 0);
  const groups: Group[][] = [];
  for (let i = 0; i < groupNumber; i += 1) {
    groups.push(columnDefinitionWithWidth.reduce((acc, column, index) => {
      const lastGroup = acc.at(acc.length - 1);
      const columnGroup = column.groups?.at(i);
      if (lastGroup && lastGroup.id === columnGroup?.id) {
        lastGroup.nbEl += 1;
        lastGroup.width += column.width;
        return acc;
      } else {
        return [
          ...acc,
          {
            id: columnGroup?.id ?? index.toString(),
            label: columnGroup?.label,
            tooltip: columnGroup?.tooltip,
            nbEl: 1,
            color: columnGroup?.color,
            width: column.width,
            hidden: column.groups === undefined,
            render: columnGroup?.render,
            variant: columnGroup?.variant ?? GroupVariant.group,
          }];
      }
    }, [] as Group[]));
  }

  return (
    <>
      <colgroup>
        {columnDefinitionWithWidth.map(({ key, propertyId, width }) => (
          <col key={`col_${key ?? propertyId}`} width={width} />
        ))}
      </colgroup>
      <TableHeader>
        {groups.length > 0 && groups.map((group, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableHeaderLine key={`group_${index}`}>
            {group.map((elementGroup, elementIndex) => {
              const groupLabel = formatOrUndef(elementGroup.label);
              const groupTooltip = formatOrUndef(elementGroup.tooltip);
              const groupColor = elementGroup.color ?? theme.color.background.primary.default;
              const key = `group_${elementIndex}_${elementGroup.id}`;
              const width = !isFiniteNumber(elementGroup.width) ? elementGroup.width : `${elementGroup.width}px`;

              if (elementGroup.hidden) {
                return (<TableHeaderCell key={key} noSeparator colSpan={elementGroup.nbEl} width={width} />);
              }

              if (elementGroup.variant === GroupVariant.header) {
                return (
                  <TableHeaderCell
                    key={key}
                    width={width}
                    tooltip={groupLabel}
                    variant={HeaderVariant.dark}
                    colSpan={elementGroup.nbEl}
                  >
                    <div className={classes.groupHeaderContainer}>
                      <Tooltip title={groupTooltip}>
                        <Typo variant={TypoVariant.small} maxLine={1}>
                          {groupLabel}
                        </Typo>
                      </Tooltip>
                    </div>
                  </TableHeaderCell>
                );
              }

              return (
                <TableHeaderCell
                  key={key}
                  colSpan={elementGroup.nbEl}
                  width={width}
                  noSeparator
                  fullHeight
                  verticalAlign="top"
                >
                  <div
                    className={classnames({
                      [classes.groupContainer]: true,
                      [classes.groupContainerPadding]: elementGroup.render === undefined,
                    })}
                    style={{ backgroundColor: groupColor }}
                  >
                    {elementGroup.render ? elementGroup.render() : (
                      <Typo maxLine={1} color={getMostReadableColorFromBackgroundColor(groupColor)}>{groupLabel}</Typo>
                    )}
                  </div>
                </TableHeaderCell>
              );
            })}
          </TableHeaderLine>
        ))}
        {(!withoutHeaderLine) && (
          <TableHeaderLine>
            {columnDefinitionWithWidth
              .map((column) => {
                const isColumnSorted = !!column.sortable && !!sortCriteria && sortCriteria.key === column.propertyId;
                const isAscending = isColumnSorted && sortCriteria.direction === TableSortDirection.asc;

                let sort: 'asc' | 'desc' | undefined;
                if (isColumnSorted) {
                  sort = isAscending ? 'asc' : 'desc';
                }
                const width = !isFiniteNumber(column.width) ? column.width : `${column.width}px`;

                if (column.hideHeader) {
                  return (<TableHeaderCell key={column.key ?? column.propertyId} noSeparator />);
                }

                return (
                  <TableHeaderCell
                    key={column.key ?? column.propertyId}
                    width={width}
                    noSeparator={column.noSeparator}
                    onClick={doSort && column.sortable ? () => doSort(column.propertyId) : undefined}
                    tooltip={column.tooltip}
                    action={column.action}
                    fullHeight={Boolean(column.headerRender)}
                    borderBottomColor={getCellBorderBottomColor?.(column.propertyId)}
                  >
                    {column.headerRender ? column.headerRender(sort) : (
                      <div className={classnames({ [classes.headerContainer]: true, [classes.headerContainerCentered]: centerHeader })}>
                        {column.name !== undefined ? (
                          <div className={classes.headerNameContainer}>
                            <Tooltip title={column.name}>
                              <Typo variant={TypoVariant.small} maxLine={1}>
                                {column.name}
                              </Typo>
                            </Tooltip>
                            {column.icon ? (<Icon name={column.icon.iconName} colorVariant={column.icon.color} tooltip={column.icon.text} />) : null}
                            {isColumnSorted ? (
                              <Icon
                                name={isAscending ? IconName.arrow_upward : IconName.arrow_downward}
                                tooltip={isAscending ? i18n`Ascending sort` : i18n`Descending sort`}
                              />
                            ) : null}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </TableHeaderCell>
                );
              })}
          </TableHeaderLine>
        )}
        {separateHeaderFromBody && (
          <TableHeaderLine height={getSpacing(Spacing.s)} />
        )}
      </TableHeader>
    </>
  );
};

export default DataTableHeaderRenderer;
