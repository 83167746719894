import { joinObjects } from 'yooi-utils';
import type { Activity } from './networkEventClient';

export interface ActivityStore {
  updateActivity: (id: string, properties: Activity & { userId: string }) => void,
  clearStore: () => void,
  listViewer: (objectId: string) => { sessionId: string, userId: string }[],
  listEditor: (objectId: string, fieldId: string) => { sessionId: string, userId: string }[],
  rebuildCache: () => void,
}

type ActivityObject = Activity & { userId: string, viewedObjectId?: string };

const createActivityStore = ({ clientId, findViewedObjectId }: { clientId: string, findViewedObjectId: (objectId: string) => string }): ActivityStore => {
  // key is sessionId
  let activityObjects: Record<string, ActivityObject> = {};
  // first key is viewedObjectId, second key is sessionId
  let activityWatch: Record<string, Record<string, ActivityObject>> = {};
  // first key is objectId|fieldId, second key is sessionId
  let activityEdit: Record<string, Record<string, ActivityObject>> = {};

  const updateActivity: ActivityStore['updateActivity'] = (id, properties) => {
    const previousActivity = activityObjects[id];
    if (previousActivity && previousActivity.objectId) {
      if (previousActivity.viewedObjectId) {
        const activityWatchObject = activityWatch[previousActivity.viewedObjectId];
        if (activityWatchObject) {
          delete activityWatchObject[id];
        }
      }
      if (previousActivity.fieldId) {
        const activityEditObject = activityEdit[`${previousActivity.objectId}|${previousActivity.fieldId}`];
        if (activityEditObject) {
          delete activityEditObject[id];
        }
      }
    }

    let activityProps: ActivityObject = properties;
    if (properties && properties.objectId) {
      const viewedObjectId = findViewedObjectId(properties.objectId);
      activityProps = joinObjects(properties, { viewedObjectId });
      const activityWatchObject = activityWatch[viewedObjectId] ?? {};

      if (!activityWatch[viewedObjectId]) {
        activityWatch[viewedObjectId] = activityWatchObject;
      }

      activityWatchObject[id] = activityProps;
      if (properties.fieldId) {
        const key = `${properties.objectId}|${properties.fieldId}`;
        const activityEditObject = activityEdit[key] ?? {};

        if (!activityEdit[key]) {
          activityEdit[key] = activityEditObject;
        }

        activityEditObject[id] = activityProps;
      }
    }

    activityObjects[id] = activityProps;
  };

  const clearStore: ActivityStore['clearStore'] = () => {
    activityObjects = {};
    activityWatch = {};
    activityEdit = {};
  };

  const rebuildCache: ActivityStore['rebuildCache'] = () => {
    const initialActivityObject = activityObjects;
    clearStore();
    Object.entries(initialActivityObject).forEach(([id, properties]) => updateActivity(id, properties));
  };

  const listViewer: ActivityStore['listViewer'] = (objectId) => {
    const viewedObjectId = findViewedObjectId(objectId);

    if (!activityWatch[viewedObjectId]) {
      return [];
    }
    return Object.entries(activityWatch[viewedObjectId]).map(([sessionId, properties]) => ({ sessionId, userId: properties.userId }));
  };
  const listEditor: ActivityStore['listEditor'] = (objectId, fieldId) => {
    if (!activityEdit[`${objectId}|${fieldId}`]) {
      return [];
    }
    return Object.entries(activityEdit[`${objectId}|${fieldId}`])
      .filter(([sessionId]) => sessionId !== clientId)
      .map(([sessionId, properties]) => ({ sessionId, userId: properties.userId }));
  };

  return { updateActivity, clearStore, listViewer, listEditor, rebuildCache };
};

export default createActivityStore;
