import type { FunctionComponent, ReactNode } from 'react';
import type { CustomElement } from 'yooi-utils';
import { RichTextElementTypes } from 'yooi-utils';
import { buildMargins, Spacing } from '../../../theme/spacingDefinition';
import makeStyles from '../../../utils/makeStyles';
import type { TypoVariant } from '../../atoms/Typo';
import Typo from '../../atoms/Typo';
import InlineLink from '../InlineLink';

const useStyles = makeStyles({
  listElement: buildMargins({ y: Spacing.xxs, x: Spacing.none }),
  link: {
    pointerEvents: 'all',
  },
}, 'richTextElement');

interface RichTextElementProps {
  children: ReactNode,
  element: CustomElement,
  attributes: Record<string, unknown>,
  typoVariant: TypoVariant | undefined,
}

const RichTextElement: FunctionComponent<RichTextElementProps> = ({ children, element, attributes, typoVariant }) => {
  const classes = useStyles();

  switch (element.type) {
    case RichTextElementTypes['block-quote']:
      return (<blockquote {...attributes}>{children}</blockquote>);
    case RichTextElementTypes['bulleted-list']:
      return (<ul {...attributes} className={classes.listElement}>{children}</ul>);
    case RichTextElementTypes['list-item']:
      return (<li {...attributes}>{children}</li>);
    case RichTextElementTypes['numbered-list']:
      return (<ol {...attributes}>{children}</ol>);
    case RichTextElementTypes.link:
      return (
        <span
          {...attributes}
          className={classes.link}
        >
          <InlineLink isUserControlled to={element.url ?? ''}>{children}</InlineLink>
        </span>
      );
    default: {
      const { ref, ...otherAttributes } = attributes;
      return (<Typo variant={typoVariant} {...otherAttributes}>{children}</Typo>);
    }
  }
};

export default RichTextElement;
