import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import type { CollaborationStoreObject, IntentionStoreObject } from 'yooi-modules/modules/collaborationModule';
import {
  Collaboration_Intention,
  Collaboration_Status,
  CollaborationMessage_CreatedAt,
  CollaborationMessage_CreatedBy,
  CollaborationMessage_Text,
  Intention_Name,
  Intention_Workflow,
} from 'yooi-modules/modules/collaborationModule/ids';
import { isCollaborationClosed, isStatusBelongingToWorkflow } from 'yooi-modules/modules/conceptModule';
import { Concept, Concept_Name, ConceptDefinition } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { RichText } from 'yooi-utils';
import { dateFormats, formatDisplayDate, richTextToText } from 'yooi-utils';
import Avatar, { AvatarSizes, AvatarVariant } from '../../../../../components/atoms/Avatar';
import Icon, { IconColorVariant, IconName, IconSizeVariant } from '../../../../../components/atoms/Icon';
import Typo, { TypoAlign } from '../../../../../components/atoms/Typo';
import Chip from '../../../../../components/molecules/Chip';
import OverflowMenu from '../../../../../components/molecules/OverflowMenu';
import useAuth from '../../../../../store/useAuth';
import useStore from '../../../../../store/useStore';
import base from '../../../../../theme/base';
import { FontVariant } from '../../../../../theme/fontDefinition';
import { spacingRem } from '../../../../../theme/spacingDefinition';
import { formatENDisplayDate } from '../../../../../utils/dateUtilsFront';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import { formatOrUndef } from '../../../../../utils/stringUtils';
import { useCollaborationPanelUpdater } from '../../../../../utils/useCollaborationContext';
import useDeleteModal from '../../../../../utils/useDeleteModal';
import { useHighlightNotify } from '../../../../../utils/useHighlight';
import { SizeContextProvider, SizeVariant } from '../../../../../utils/useSizeContext';
import useTheme from '../../../../../utils/useTheme';
import useTooltipRef from '../../../../../utils/useTooltipRef';
import { getChipOptions } from '../../../modelTypeUtils';
import usePanelObserver from '../usePanelObserver';
import { getCollaborationMessages, getCollaborationsUnreadMessageCount, getIntentions, getMostRelevantContextForCollaboration } from '../utils/collaborationUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacingRem.m,
    rowGap: spacingRem.xs,
    borderRadius: base.borderRadius.medium,
    backgroundColor: theme.color.background.neutral.default,
    cursor: 'pointer',
    boxShadow: base.shadowElevation.low,
    '&:hover, &:focus': {
      boxShadow: base.shadowElevation.medium,
    },
  },
  topLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '3.2rem',
    columnGap: spacingRem.s,
  },
  authorLine: {
    display: 'grid',
    gridTemplateColumns: 'auto auto minmax(5rem, 1fr)',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
  lineGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
  lineCounterGroup: {
    columnGap: spacingRem.m,
  },
  contentLine: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: spacingRem.s,
    paddingTop: spacingRem.s,
  },
  counter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: spacingRem.xs,
  },
  fade: {
    opacity: 0.75,
  },
  emptyContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.m,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.s,
    justifyContent: 'center',
    alignItems: 'center',
  },
}), 'collaborationCard');

interface CollaborationCardProps {
  collaborationId: string,
}

const CollaborationCard: FunctionComponent<CollaborationCardProps> = ({ collaborationId }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const { loggedUserId } = useAuth();

  const panel = useCollaborationPanelUpdater();
  const highlight = useHighlightNotify();
  const { fullContext, context, pageContext } = usePanelObserver();

  const [doDelete, deleteModal] = useDeleteModal({
    doDelete: () => {
      panel.openList(context, true);
      store.deleteObject(collaborationId);
    },
    shouldConfirm: () => true,
    getModalProps: () => ({
      title: i18n`Are you sure that you want to delete this collaboration?`,
      content: (
        <>
          <Typo>{i18n`Deleting this item is irreversible and will permanently remove it from the platform.`}</Typo>
          <Typo>{i18n`If you are not sure, you can close this collaboration instead.`}</Typo>
        </>
      ),
    }),
  });
  const firstClassConceptId = fullContext?.find((id) => id.length === 1 && isInstanceOf(store.getObject(id[0]), Concept))?.[0];
  let conceptDefinitionId: string | undefined;

  if (firstClassConceptId) {
    const concept = store.getObjectOrNull(firstClassConceptId);
    conceptDefinitionId = concept?.[Instance_Of] as string | undefined;
  } else {
    conceptDefinitionId = fullContext?.find((id) => id.length === 1 && isInstanceOf(store.getObject(id[0]), ConceptDefinition))?.[0];
  }
  const intentions = getIntentions(store, conceptDefinitionId);

  const collaboration = store.getObject<CollaborationStoreObject>(collaborationId);
  const collaborationClosed = isCollaborationClosed(store, collaboration);

  const messages = getCollaborationMessages(store, collaborationId, true);
  const contributorIds = messages
    .reduce(
      (acc, { [CollaborationMessage_CreatedBy]: authorId }) => (authorId !== undefined ? acc.add(authorId) : acc),
      new Set<string>()
    );
  const message = messages[0];
  const authorName = richTextToText(message.navigate(CollaborationMessage_CreatedBy)?.[Concept_Name] as RichText | undefined);
  const date = new Date(message[CollaborationMessage_CreatedAt] ?? 0);
  const formattedFullDate = formatENDisplayDate(date);
  const formattedAbbreviatedDate = formatDisplayDate(date, dateFormats.localDateWithAbbreviatedMonth);
  const intention = collaboration.navigateOrNull<IntentionStoreObject>(Collaboration_Intention);
  const isWorkflowDefined = !!intention?.[Intention_Workflow] && !!store.getObjectOrNull(intention[Intention_Workflow]);
  const statusBelongsToWorkflow = isStatusBelongingToWorkflow(store, intention?.[Intention_Workflow], collaboration?.[Collaboration_Status]);
  const statusChip = collaboration[Collaboration_Status] !== undefined ? getChipOptions(store, collaboration[Collaboration_Status]) : undefined;
  const unreadMessageCount = getCollaborationsUnreadMessageCount(store, [collaboration], loggedUserId);

  let messageCountTooltip: string;
  if (unreadMessageCount > 0) {
    messageCountTooltip = unreadMessageCount === 1 ? i18n`1 unread message` : i18n`${unreadMessageCount} unread messages`;
  } else {
    messageCountTooltip = messages.length === 1 ? i18n`1 message` : i18n`${messages.length} messages`;
  }
  const messageCountTooltipRef = useTooltipRef(messageCountTooltip);

  const activeUserTooltip = contributorIds.size === 1 ? i18n`1 active user` : i18n`${messages.length} active users`;
  const activeUserTooltipRef = useTooltipRef(activeUserTooltip);

  const authorTooltipRef = useTooltipRef(authorName);
  const dateTooltipRef = useTooltipRef(formattedFullDate);

  const highlightContext = getMostRelevantContextForCollaboration(store, collaborationId, context, pageContext);
  if (intentions.length === 0) {
    return (
      <div className={classes.emptyContainer}>
        <Icon name={IconName.forum} size={IconSizeVariant.xxl} color={theme.color.text.disabled} />
        <div className={classes.emptyMessageContainer}>
          <Typo color={theme.color.text.disabled} align={TypoAlign.center}>{i18n`Collaboration is no longer accessible.`}</Typo>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div
          className={classes.container}
          tabIndex={0}
          onClick={() => {
            highlight(highlightContext, false, false);
            panel.openDetail(highlightContext, collaborationId);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && !event.shiftKey) {
              highlight(highlightContext, false, false);
              panel.openDetail(highlightContext, collaborationId);
            }
          }}
          role="button"
        >
          <div className={classnames({ [classes.topLine]: true, [classes.fade]: collaborationClosed })}>
            <div className={classes.authorLine}>
              <Avatar name={authorName?.charAt(0).toUpperCase() || '...'} tooltip={authorName} variant={AvatarVariant.messageUser} size={AvatarSizes.small} />
              <Typo ref={authorTooltipRef} maxLine={1}>{authorName}</Typo>
              <Typo ref={dateTooltipRef} maxLine={1} color={theme.color.text.secondary} variant={FontVariant.small}>{formattedAbbreviatedDate}</Typo>
            </div>
            <OverflowMenu menuItems={[{ key: 'delete', name: i18n`Delete`, icon: IconName.delete, onClick: doDelete, danger: true }]} />
          </div>
          <div className={classnames({ [classes.contentLine]: true, [classes.fade]: collaborationClosed })}>
            <Typo maxLine={5}>{message[CollaborationMessage_Text]}</Typo>
          </div>
          <div className={classnames({ [classes.bottomLine]: true, [classes.fade]: collaborationClosed })}>
            <div className={classes.lineGroup}>
              <Typo maxLine={1} color={theme.color.text.secondary} variant={FontVariant.small}>
                {intention ? formatOrUndef(intention[Intention_Name]) : i18n`Deleted intention`}
              </Typo>
              {
                isWorkflowDefined && statusChip !== undefined
                  ? (
                    <SizeContextProvider sizeVariant={SizeVariant.small}>
                      <Chip
                        text={statusChip.label}
                        tooltip={statusBelongsToWorkflow ? statusChip.tooltip : i18n`Selected instance does not belong to the workflow`}
                        icon={statusBelongsToWorkflow ? statusChip.icon : { name: IconName.dangerous, colorVariant: IconColorVariant.error }}
                        squareColor={statusChip.squareColor}
                        color={statusChip.color}
                        borderStyle={statusChip.borderStyle}
                      />
                    </SizeContextProvider>
                  )
                  : null
              }
            </div>
            <div className={classnames(classes.lineGroup, classes.lineCounterGroup)}>
              <span ref={messageCountTooltipRef} className={classes.counter}>
                <Icon
                  name={unreadMessageCount > 0 ? IconName.mark_chat_unread : IconName.chat_bubble_outline}
                  color={unreadMessageCount > 0 ? theme.color.text.info : theme.color.icon.secondary}
                />
                <Typo variant={FontVariant.small}>{messages.length}</Typo>
              </span>
              <span ref={activeUserTooltipRef} className={classes.counter}>
                <Icon name={IconName.record_voice_over} color={theme.color.icon.secondary} />
                <Typo variant={FontVariant.small}>{contributorIds.size}</Typo>
              </span>
            </div>
          </div>
        </div>
        {deleteModal}
      </>
    );
  }
};

export default CollaborationCard;
