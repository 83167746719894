import type { DimensionsMapping, FieldStoreObject, PathStep } from 'yooi-modules/modules/conceptModule';
import {
  associationFieldHandler,
  createValuePathResolver,
  dimensionsMappingToParametersMapping,
  getFieldDimensionOfModelType,
  getInstanceLabel,
  getInstanceLabelOrUndefined,
  getPathLastFieldInformation,
  isMultipleRelationalType,
  isMultiValueResolution,
  isSingleFieldResolution,
  isSingleValueResolution,
  kinshipRelationFieldHandler,
  relationSingleFieldHandler,
  workflowFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import { AssociationField, Field_Formula, KinshipRelationField, RelationSingleField, WorkflowField } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import { compareString, comparing, createAutoProvisioningMap, filterNullOrUndefined, pushUndefinedToEnd } from 'yooi-utils';
import type { FrontObjectStore } from '../../store/useStore';
import { resolveConceptChipIcon, resolveConceptColorValue } from './conceptDisplayUtils';

export const buildAssociationGroupByKey = (field: FieldStoreObject, instances: StoreObject[]): string => {
  if (field[Field_Formula] === undefined) {
    return instances.map(({ id }) => id).sort(compareString).join('|');
  } else {
    const typeMap = createAutoProvisioningMap<string, string[]>();
    const typeOrderIds: string[] = [];
    for (let i = 0; i < instances.length; i += 1) {
      const instance = instances[i];
      if (!typeMap.has(instance[Instance_Of] as string)) {
        // New type, track the order
        typeOrderIds.push(instance[Instance_Of] as string);
      }

      typeMap.getOrCreate(instance[Instance_Of] as string, () => [])
        .push(instance.id);
    }
    return typeOrderIds
      .flatMap((typeId) => typeMap.getOrCreate(typeId, () => []).sort(compareString))
      .join('|');
  }
};

export const buildAssociationGroupByLabel = (store: ObjectStoreWithTimeseries, key: string): string => {
  const typeMap = createAutoProvisioningMap<string, string[]>();
  const typeOrderIds: string[] = [];
  const instances = key.split('|').filter((part) => part !== '').map((id) => store.getObjectOrNull(id)).filter(filterNullOrUndefined);
  for (let i = 0; i < instances.length; i += 1) {
    const instance = instances[i];
    if (!typeMap.has(instance[Instance_Of] as string)) {
      // New type, track the order
      typeOrderIds.push(instance[Instance_Of] as string);
    }

    typeMap.getOrCreate(instance[Instance_Of] as string, () => [])
      .push(getInstanceLabelOrUndefined(store, instance));
  }
  return typeOrderIds
    .flatMap((typeId) => typeMap.getOrCreate(typeId, () => []).sort(comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString)))
    .join(', ');
};

export const buildAssociationGroupByColor = (store: ObjectStoreWithTimeseries, key: string): string | undefined => (
  key === '' || key.includes('|') ? undefined : (resolveConceptColorValue(store, key) ?? resolveConceptChipIcon(store, key)?.color)
);

export const getFieldGroupByHandler = (store: ObjectStoreWithTimeseries, fieldId: string): {
  key: string,
  getGroupKey: (item: StoreObject) => (string | undefined),
  getGroupLabel: (key: string) => string,
  getGroupColor: (key: string) => (string | undefined),
} | undefined => {
  const field = store.getObjectOrNull<FieldStoreObject>(fieldId);
  if (field === null) {
    return undefined;
  }

  if (isInstanceOf(field, WorkflowField)) {
    const { getValueResolution } = workflowFieldHandler(store, fieldId);
    return {
      key: fieldId,
      getGroupKey: (instance) => {
        const dimension = typeof instance[Instance_Of] === 'string' ? getFieldDimensionOfModelType(store, fieldId, instance[Instance_Of]) : undefined;
        return dimension === undefined ? undefined : getValueResolution({ [dimension]: instance.id }).value.value?.id;
      },
      getGroupLabel: (key) => getInstanceLabelOrUndefined(store, store.getObject(key)),
      getGroupColor: (key) => (resolveConceptColorValue(store, key) ?? resolveConceptChipIcon(store, key)?.color),
    };
  } else if (isInstanceOf(field, RelationSingleField)) {
    const { getValueResolution } = relationSingleFieldHandler(store, fieldId);
    return {
      key: fieldId,
      getGroupKey: (instance) => {
        const dimension = typeof instance[Instance_Of] === 'string' ? getFieldDimensionOfModelType(store, fieldId, instance[Instance_Of]) : undefined;
        return dimension === undefined ? undefined : getValueResolution({ [dimension]: instance.id }).value?.id;
      },
      getGroupLabel: (key) => getInstanceLabelOrUndefined(store, store.getObject(key)),
      getGroupColor: (key) => (resolveConceptColorValue(store, key) ?? resolveConceptChipIcon(store, key)?.color),
    };
  } else if (isInstanceOf(field, KinshipRelationField)) {
    const { getValueResolution } = kinshipRelationFieldHandler(store, fieldId);
    return {
      key: fieldId,
      getGroupKey: (instance) => {
        const dimension = typeof instance[Instance_Of] === 'string' ? getFieldDimensionOfModelType(store, fieldId, instance[Instance_Of]) : undefined;
        return dimension === undefined ? undefined : getValueResolution({ [dimension]: instance.id }).value?.id;
      },
      getGroupLabel: (key) => getInstanceLabelOrUndefined(store, store.getObject(key)),
      getGroupColor: (key) => (resolveConceptColorValue(store, key) ?? resolveConceptChipIcon(store, key)?.color),
    };
  } else if (isInstanceOf(field, AssociationField)) {
    const { getValueResolution } = associationFieldHandler(store, fieldId);
    return {
      key: fieldId,
      getGroupKey: (instance) => {
        const dimension = typeof instance[Instance_Of] === 'string' ? getFieldDimensionOfModelType(store, fieldId, instance[Instance_Of]) : undefined;
        if (dimension === undefined) {
          return undefined;
        } else {
          const instances = getValueResolution({ [dimension]: instance.id }).value;
          return instances !== undefined ? buildAssociationGroupByKey(field, instances) : undefined;
        }
      },
      getGroupLabel: (key) => buildAssociationGroupByLabel(store, key),
      getGroupColor: (key) => buildAssociationGroupByColor(store, key),
    };
  } else {
    return undefined;
  }
};

export interface GroupByFieldMetadata {
  key: string,
  fieldId: string,
  getLabel: () => string | undefined,
  getDimensionsMapping: (line: { dimensionsMapping: DimensionsMapping }) => DimensionsMapping,
  getGroupKey: (line: { dimensionsMapping: DimensionsMapping }) => (string | undefined),
  getGroupLabel: (key: string) => (string | undefined),
  getGroupColor: (key: string) => (string | undefined),
}

export const resolveGroupByFieldPath = (store: FrontObjectStore, path: PathStep[]): GroupByFieldMetadata | undefined => {
  const fieldId = getPathLastFieldInformation(path)?.fieldId;
  if (fieldId !== undefined) {
    const field = store.getObjectOrNull<FieldStoreObject>(fieldId);
    const isMulti = field !== null ? isMultipleRelationalType(field[Instance_Of]) : false;
    return ({
      key: fieldId,
      fieldId,
      getLabel: () => (field !== null ? getInstanceLabel(store, field) : undefined),
      getDimensionsMapping: (line) => {
        const pathResolution = createValuePathResolver(store, dimensionsMappingToParametersMapping(line.dimensionsMapping))
          .resolvePathField(path ?? []);
        if (isSingleFieldResolution(pathResolution) && pathResolution.dimensionsMapping) {
          return pathResolution.dimensionsMapping;
        }
        return {};
      },
      getGroupKey: (line) => {
        if (field === null) {
          return undefined;
        }
        const pathResolver = createValuePathResolver(store, dimensionsMappingToParametersMapping(line.dimensionsMapping));
        if (isMulti) {
          const pathResolution = pathResolver.resolvePathValue<StoreObject>(path ?? []);
          if (isMultiValueResolution(pathResolution)) {
            return buildAssociationGroupByKey(field, pathResolution.values);
          } else {
            return undefined;
          }
        } else {
          const pathResolution = pathResolver.resolvePathValue<StoreObject | undefined>(path ?? []);
          if (isSingleValueResolution(pathResolution)) {
            return pathResolution.value?.id;
          } else {
            return undefined;
          }
        }
      },
      getGroupLabel: (key) => {
        if (isMulti) {
          return buildAssociationGroupByLabel(store, key);
        } else {
          return getInstanceLabelOrUndefined(store, store.getObject(key));
        }
      },
      getGroupColor: (key) => {
        if (isMulti) {
          return buildAssociationGroupByColor(store, key);
        } else {
          return resolveConceptColorValue(store, key) ?? resolveConceptChipIcon(store, key)?.color;
        }
      },
    });
  }
  return undefined;
};
