import type { DateFieldStoreValue, DayNumbers, MonthNumbers, WeekdayNumbers } from 'yooi-utils';
import { getDayInMonth, getNumberOfDaysInMonth, offsetFromUTC, periodicities } from 'yooi-utils';
import type { AutomationRuleTrigger, AutomationRuleTriggerHandler, TriggerHandler } from './triggerHandler';
import { TriggerType } from './utils';

export interface CronRule {
  start: number,
  end: number | undefined,
  rule: string,
  extraRule?: ((fireDate: Date) => boolean),
}

export enum RepeatType {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year',
}

export interface DayRepeat {
  type: RepeatType.day,
  times: number,
}

export interface WeekRepeat {
  type: RepeatType.week,
  times: number,
  weekDays: WeekdayNumbers[],
}

interface DayRule {
  day: DayNumbers,
}

export const isDayRule = (rule: DayRule | WeekDayRule): rule is DayRule => (rule as DayRule).day !== undefined;

interface WeekDayRule {
  week: 1 | 2 | 3 | 4 | 5,
  weekDay: WeekdayNumbers,
}

export const isWeekDayRule = (rule: DayRule | WeekDayRule): rule is WeekDayRule => (rule as WeekDayRule).weekDay !== undefined && (rule as WeekDayRule).week !== undefined;

export interface MonthRepeat {
  type: RepeatType.month,
  times: number,
  dayRule: DayRule,
  weekDayRule: WeekDayRule,
  selectedRule: 'dayRule' | 'weekDayRule',
}

export interface YearRepeat {
  type: RepeatType.year,
  times: 1,
  month: MonthNumbers,
  dayRule: DayRule,
  weekDayRule: WeekDayRule,
  selectedRule: 'dayRule' | 'weekDayRule',
}

export interface ScheduledTrigger<T extends DayRepeat | WeekRepeat | MonthRepeat | YearRepeat = DayRepeat | WeekRepeat | MonthRepeat | YearRepeat> extends AutomationRuleTrigger {
  type: TriggerType.scheduled,
  repeatRule: T,
  at: {
    hour: number,
    tz: string,
  },
  fromDate: DateFieldStoreValue | undefined,
  toDate?: DateFieldStoreValue | undefined,
}

export const isScheduledTrigger = (trigger: AutomationRuleTrigger | undefined): trigger is ScheduledTrigger => trigger?.type === TriggerType.scheduled;

export const extractCronRule = (
  repeatRule: DayRepeat | WeekRepeat | MonthRepeat | YearRepeat,
  from: DateFieldStoreValue | undefined,
  to: DateFieldStoreValue | undefined,
  hour: number,
  tz: string,
  logTrace?: (message: string, data?: Record<string, unknown>) => void
): CronRule | undefined => {
  if (!from) {
    logTrace?.('Cannot offset start date from trigger', { from });
    return undefined;
  }
  const { period: fromPeriod, date: fromDate } = from;
  // Get utc start of period (period are stored as an utc timestamp)
  const fromDateStartPeriod = periodicities[fromPeriod].getStartOfPeriod(new Date(fromDate), 'utc');
  // Offset the start date in the trigger timezone. Eg: we want rule on 05/01 at 1am-08 to start on 04/30
  const start = offsetFromUTC(fromDateStartPeriod, tz);
  // Then every comparison are done on the trigger timezone
  if (!start) {
    logTrace?.('Cannot offset start date from trigger', { from, fromDateStartPeriod });
    return undefined;
  } else {
    let end: number | undefined;
    if (to) {
      const { period: toPeriod, date: toDate } = to;
      if (toPeriod && toDate) {
        // Get utc end of period (period are stored as an utc timestamp)
        const toDateEndPeriod = periodicities[toPeriod].getEndOfPeriod(new Date(toDate), 'utc');
        // Offset the end date in the trigger timezone. Eg: we want rule to 05/31 at 6pm+08 to end on 06/01
        end = offsetFromUTC(toDateEndPeriod, tz);
      }
    }
    const { times } = repeatRule;
    if (repeatRule && repeatRule?.type === RepeatType.day) {
      return {
        start,
        end,
        rule: `0 0 ${hour} * * *`,
        extraRule: (fireDate) => Math.floor(periodicities.day.getDiffOfDatesOfPeriod(fireDate, new Date(start), tz)) % times === 0,
      };
    } else if (repeatRule?.type === RepeatType.week) {
      if (repeatRule.weekDays.length !== 0) {
        return {
          start,
          end,
          rule: `0 0 ${hour} * * ${repeatRule.weekDays.join(',')}`,
          extraRule: (fireDate) => Math.floor(periodicities.week.getDiffOfDatesOfPeriod(fireDate, new Date(start), tz)) % times === 0,
        };
      }
    } else if (repeatRule?.type === RepeatType.month) {
      const selectedRuleId = repeatRule.selectedRule;
      const selectedRule = selectedRuleId ? repeatRule[selectedRuleId] : undefined;
      if (selectedRule && isWeekDayRule(selectedRule)) {
        return {
          start,
          end,
          rule: `0 0 ${hour} * * ${selectedRule.weekDay}`,
          extraRule: (fireDate) => {
            const repeatPeriodicityCondition = (Math.floor(periodicities.month.getDiffOfDatesOfPeriod(fireDate, new Date(start), tz)) % times) === 0;

            let dayIterationCondition: boolean;
            if (selectedRule.week === 5) {
              const numberOfDaysInMonth = getNumberOfDaysInMonth(fireDate, tz);
              dayIterationCondition = numberOfDaysInMonth !== undefined && (numberOfDaysInMonth - getDayInMonth(fireDate, tz)) < 7;
            } else {
              dayIterationCondition = Math.ceil(getDayInMonth(fireDate, tz) / 7) === selectedRule.week;
            }

            return repeatPeriodicityCondition && dayIterationCondition;
          },
        };
      } else if (selectedRule && isDayRule(selectedRule)) {
        return {
          start,
          end,
          rule: `0 0 ${hour} ${selectedRule.day} * *`,
          extraRule: (fireDate) => Math.floor(periodicities.month.getDiffOfDatesOfPeriod(fireDate, new Date(start), tz)) % times === 0,
        };
      }
    } else if (repeatRule?.type === RepeatType.year) {
      const selectedRuleId = repeatRule.selectedRule;
      const selectedRule = selectedRuleId ? repeatRule[selectedRuleId] : undefined;
      if (selectedRule && isWeekDayRule(selectedRule)) {
        return {
          start,
          end,
          rule: `0 0 ${hour} * ${repeatRule.month} ${selectedRule.weekDay}`,
          extraRule: (fireDate) => {
            if (Math.floor(periodicities.year.getDiffOfDatesOfPeriod(fireDate, new Date(start), tz)) % times !== 0) {
              return false;
            }
            if (selectedRule.week === 5) {
              const numberOfDaysInMonth = getNumberOfDaysInMonth(fireDate, tz);
              return numberOfDaysInMonth !== undefined && (numberOfDaysInMonth - getDayInMonth(fireDate, tz)) < 7;
            } else {
              return Math.ceil(getDayInMonth(fireDate, tz) / 7) === selectedRule.week;
            }
          },
        };
      } else if (selectedRule && isDayRule(selectedRule)) {
        return {
          start,
          end,
          rule: `0 0 ${hour} ${selectedRule.day} ${repeatRule.month} *`,
          extraRule: (fireDate) => Math.floor(periodicities.year.getDiffOfDatesOfPeriod(fireDate, new Date(start), tz)) % times === 0,
        };
      }
    }
  }
  return undefined;
};

export interface SchedulerTriggerHandler extends AutomationRuleTriggerHandler {}

export const createSchedulerTriggerHandler = (): TriggerHandler<SchedulerTriggerHandler> => ({ parameterDefinitions: {}, mailActionQuery: {} });
