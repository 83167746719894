import type { FunctionComponent, ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import { remToPx } from '../../utils/sizeUtils';
import useFocusOnMount from '../../utils/useFocusOnMount';
import Overlay from './Overlay';

interface TooltipPopoverProps {
  currentAnchor: HTMLElement,
  onClose?: () => void,
  onEscape?: () => void,
  children?: ReactNode,
  focusOnMount?: boolean,
  delay?: number,
}

const TooltipPopover: FunctionComponent<TooltipPopoverProps> = ({ currentAnchor, onClose, onEscape, children, focusOnMount = false, delay }) => {
  const ref = useRef(null);
  useFocusOnMount(ref, Boolean(focusOnMount));
  const [display, setDisplay] = useState<boolean>(delay === undefined);

  useEffect(() => {
    const timeout = setTimeout(() => setDisplay(true), delay);
    return () => clearTimeout(timeout);
  }, [delay]);
  return display ? (
    <Overlay
      target={currentAnchor}
      placement="bottom-start"
      flip
      offset={[0, remToPx(0.4)]}
      onBackdropClick={(e) => {
        // in case the backdrop click occurs on the currentAnchor don't close the TooltipPopover
        if (currentAnchor.contains(e.target as Node | null)) {
          return;
        }
        onClose?.();
      }}
      onEscapeKeyDown={onEscape}
    >
      <div
        ref={ref}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={focusOnMount ? 0 : undefined}
      >
        {children}
      </div>
    </Overlay>
  ) : null;
};

export default TooltipPopover;
