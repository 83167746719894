import type { FunctionComponent } from 'react';
import { DndProvider } from 'react-dnd';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { MatrixViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import ScrollableWidgetContainer, {
  SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_HEIGHT_PADDING,
  SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_WIDTH_PADDING,
} from '../../../../components/templates/ScrollableWidgetContainer';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import { remToPx } from '../../../../utils/sizeUtils';
import useDeepMemo from '../../../../utils/useDeepMemo';
import { dndManager } from '../../fields/_global/dndUtils';
import WidgetInvalidConfiguration from '../../fields/_global/WidgetInvalidConfiguration';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { useViewFilters } from '../../filter/useViewFilters';
import { getViewNavigationFilters } from '../common/viewUtils';
import { getViewDefinitionHandler } from '../viewDsl';
import { isResolutionError } from '../viewResolutionUtils';
import ConceptMatrixView from './ConceptMatrixView';
import type { MatrixViewDefinitionHandler } from './matrixViewDefinitionHandler';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.s,
  },
}, 'matrixViewWidget');

interface MatrixViewWidgetProps {
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinition: MatrixViewStoredDefinition,
  parametersMapping: ParametersMapping,
  width: number,
  height: number,
  readOnly: boolean | undefined,
}

const MatrixViewWidget: FunctionComponent<MatrixViewWidgetProps> = ({
  viewDimensions,
  viewFilters,
  viewDefinition,
  parametersMapping,
  width,
  height,
  readOnly,
}) => {
  const classes = useStyles();

  const store = useStore();
  const { loggedUserId } = useAuth();
  const aclHandler = useAcl();

  const filterConfiguration = useViewFilters(viewFilters, viewDimensions);

  const viewHandler = getViewDefinitionHandler(viewDefinition) as MatrixViewDefinitionHandler;
  const resolvedViewDefinition = viewHandler.getDefinition(store, viewDimensions);
  const viewResolution = useDeepMemo(
    () => viewHandler.resolveView(store, { viewDimensions, parametersMapping, aclHandler, userId: loggedUserId, filterConfiguration }),
    [store.getSerial(), viewDefinition, viewDimensions, parametersMapping, filterConfiguration]
  );

  if (isResolutionError(viewResolution)) {
    return <WidgetInvalidConfiguration width={width} height={height} reason={viewResolution.error} />;
  } else {
    return (
      <ScrollableWidgetContainer asContent width={width} height={height}>
        <span className={classes.container}>
          <DndProvider manager={dndManager}>
            <ConceptMatrixView
              filterKey={viewFilters.filterKey}
              parametersMapping={parametersMapping}
              viewDefinition={resolvedViewDefinition}
              viewResolution={viewResolution}
              readOnly={viewDefinition.readOnly || readOnly}
              navigationFilters={getViewNavigationFilters(store, viewDimensions, filterConfiguration, parametersMapping)}
              containerWidthPx={width - remToPx(SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_WIDTH_PADDING)}
              containerHeightPx={height - remToPx(SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_HEIGHT_PADDING)}
            />
          </DndProvider>
        </span>
      </ScrollableWidgetContainer>
    );
  }
};

export default MatrixViewWidget;
