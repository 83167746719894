import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { joinObjects } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../../../components/atoms/Button';
import Icon, { IconName, IconSizeVariant } from '../../../../../components/atoms/Icon';
import Typo, { TypoAlign } from '../../../../../components/atoms/Typo';
import base, { Opacity } from '../../../../../theme/base';
import { hexColorWithAlpha } from '../../../../../theme/colorUtils';
import { buildMargins, buildPadding, getSpacing, Spacing } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeSelectorsClasses from '../../../../../utils/makeSelectorsClasses';
import makeStyles from '../../../../../utils/makeStyles';
import useDerivedState from '../../../../../utils/useDerivedState';
import useTheme from '../../../../../utils/useTheme';
import type { DraggedItem } from './types';

const selectorsClasses = makeSelectorsClasses('visibilityHandler');

const useStyles = makeStyles((theme) => ({
  itemContainer: joinObjects(
    buildPadding({ bottom: Spacing.s }),
    {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderWidth: '0.2rem',
      borderStyle: 'solid',
      borderColor: theme.color.transparent,
      borderRadius: base.borderRadius.medium,
      '&:hover, &:focus, &:focus-within': {
        [`& .${selectorsClasses.visibilityHandler}`]: {
          visibility: 'visible',
        },
      },
    }
  ),
  emptyValueZone: {
    borderColor: theme.color.border.default,
    borderStyle: 'dashed',
  },
  buttonContainer: joinObjects(
    buildPadding({ top: Spacing.s, left: Spacing.s }),
    { visibility: 'hidden' }
  ),
  dropImage: joinObjects(
    {
      outline: `0.2rem dashed ${theme.color.border.primary}`,
      borderRadius: base.borderRadius.medium,
    },
    buildMargins({ x: Spacing.s, top: Spacing.s })
  ),
  cannotDropOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  cannotDropContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0.4rem',
    padding: getSpacing(Spacing.s),
    margin: getSpacing(Spacing.s),
    backgroundColor: hexColorWithAlpha(theme.color.background.neutral.default, base.opacity[Opacity.eightyFive]),
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    gap: getSpacing(Spacing.s),
  },
  smallCannotDropContainer: {
    flexDirection: 'row',
  },
}), 'viewConceptSwimlaneColumn');

interface ViewConceptSwimlaneColumnProps {
  onDrop: (instanceId: string) => void,
  canDrop: (instanceId: string) => { result: true } | { result: false, message: string },
  type: string,
  backgroundColor: string | undefined,
  emptyValueZone: boolean,
  children: ReactNode,
  readOnly?: boolean,
  onCreate?: () => void,
}

const ViewConceptSwimlaneColumn: FunctionComponent<ViewConceptSwimlaneColumnProps> = ({
  onDrop,
  canDrop,
  type,
  backgroundColor,
  emptyValueZone,
  children,
  readOnly = false,
  onCreate,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const ref = useRef<HTMLDivElement>(null);
  const height = ref.current?.clientHeight ?? 0;

  const [
    { isOver, cantDropMessage, draggedElementHeight },
    drop,
  ] = useDrop<DraggedItem | null, unknown, { isOver: boolean, cantDropMessage: string | undefined, draggedElementHeight: number | undefined }>(
    () => ({
      accept: type,
      canDrop: (item) => item?.instanceId !== undefined && canDrop(item.instanceId).result,
      drop: (item, monitor) => {
        if (item?.instanceId !== undefined && !monitor.didDrop()) {
          onDrop(item.instanceId);
        }
      },
      collect: (monitor) => {
        const item = monitor.getItem();
        const canItemBeDropped = item?.instanceId !== undefined ? canDrop(item.instanceId) : { result: false, message: undefined };
        return {
          isOver: canItemBeDropped.result && monitor.isOver({ shallow: true }),
          cantDropMessage: !canItemBeDropped.result && monitor.isOver({ shallow: false }) ? canItemBeDropped.message : undefined,
          draggedElementHeight: item?.height,
        };
      },
    }),
    [onDrop, canDrop]
  );
  const [isOverState] = useDerivedState(() => isOver, [isOver]);

  return (
    <div
      ref={composeReactRefs<HTMLDivElement>(drop, ref)}
      className={classnames({
        [classes.itemContainer]: true,
        [classes.emptyValueZone]: emptyValueZone,
      })}
      style={{ backgroundColor }}
    >
      <div>
        {children}
        {isOverState && (<div className={classes.dropImage} style={{ height: draggedElementHeight }} />)}
      </div>
      {onCreate && !readOnly && (
        <div className={classnames(classes.buttonContainer, selectorsClasses.visibilityHandler)}>
          <Button
            title={i18n`Create`}
            iconName={IconName.add}
            variant={ButtonVariant.tertiary}
            onClick={onCreate}
          />
        </div>
      )}
      {cantDropMessage && (
        <div className={classes.cannotDropOverlay}>
          {height >= 108 ? (
            <div
              className={classes.cannotDropContainer}
            >
              <Icon color={theme.color.text.secondary} name={IconName.block} size={IconSizeVariant.xxl} />
              <Typo color={theme.color.text.secondary} align={TypoAlign.center}>{cantDropMessage}</Typo>
            </div>
          ) : (
            <div className={classnames(classes.cannotDropContainer, classes.smallCannotDropContainer)}>
              <Icon color={theme.color.text.secondary} name={IconName.block} />
              <Typo maxLine={1} noWrap color={theme.color.text.secondary} align={TypoAlign.center}>{cantDropMessage}</Typo>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ViewConceptSwimlaneColumn;
