import type OriginSources from './OriginSources';
import type { TimeseriesRangeEntry } from './utils/TimeseriesType';

export interface TimeseriesDiffOperation {
  updated: Record<number, unknown>,
  deleted: number[],
}

export interface TimeseriesOperation {
  truncate?: { from?: number, to?: number } | boolean,
  values?: Record<number, unknown>,
}

export interface IncomingEventOperation<Id extends string | string[] = string[]> {
  id: Id,
  properties?: Record<string, unknown> | null,
  timeseries?: Record<string, TimeseriesOperation>,
}

export interface Operation<Id extends string | string[] = string[]> extends IncomingEventOperation<Id> {
  systemEvent?: boolean,
}

export enum PropertyTimeseriesResponseStatuses {
  error = 'error',
  accepted = 'accepted',
}

export interface PropertyTimeseriesResponseFailure {
  status: PropertyTimeseriesResponseStatuses.error,
}

export interface PropertyTimeseriesResponseSuccess {
  status: PropertyTimeseriesResponseStatuses.accepted,
  propertyId: string,
  timeseries: TimeseriesRangeEntry[],
}

export interface SendOutgoingEventResponse {
  status: string,
  eventId: string,
}

export interface EventOrigin {
  clientId?: string,
  userId?: string,
  impersonatorEmail?: string,
  ip?: string[],
  depth?: number,
  automationRuleId?: string,
  source?: OriginSources,
}

export interface BaseEvent {
  eventId: string,
  version: number,
}

export interface IncomingEvent extends BaseEvent {
  origin: EventOrigin,
  steps: { name: string, sourceHash: string, time: number }[],
  event: IncomingEventOperation[],
}

export enum ProcessedEventStatus {
  validated = 'validated',
  rejected = 'rejected',
  // Error status seems to be no longer used but if we want to drop it, we'll need to migrate existing events
  error = 'error',
}

export interface MinifiedOperation<Id extends string | string[] = string[]> {
  id: Id,
  properties?: Record<string, unknown> | null,
  timeseries?: Record<string, TimeseriesDiffOperation>,
}

export interface MinifiedEvent extends BaseEvent {
  event: MinifiedOperation[],
  origin: EventOrigin,
}

export interface ProcessedEvent extends BaseEvent {
  event: Operation[],
  origin: EventOrigin,
  status: ProcessedEventStatus,
  metadata: Record<string, unknown>,
}

export interface AuditEvent extends BaseEvent {
  status: ProcessedEventStatus,
  steps: { name: string, sourceHash: string, time: number }[],
  audit: {
    id?: string[],
    acl?: {
      objects: {
        [objectId: string]: { rule: string, status: string },
      },
      validated: boolean,
    },
    br?: {
      objects: { [objectId: string]: { ACCEPTED?: { rule: string }[], REJECTED?: { rule: string, errorMessage?: string, errorStack?: string[] }[], validated: boolean } },
      properties: { [propertyId: string]: { ACCEPTED?: { rule: string }[], REJECTED?: { rule: string, errorMessage?: string, errorStack?: string[] }[], validated: boolean } },
    },
    gc?: {
      rules: { [rule: string]: { objectIds: (string | string[])[] } },
    },
    metadataGeneratorsKeys?: string[],
    error?: unknown,
  }[],
}
