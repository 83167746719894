import type { Globals, Property } from 'csstype';

const expose = 'Expose-Bold';
const inter = 'Inter';

export const textLineHeight = {
  main: '2.2rem',
  small: '1.6rem',
};

export enum FontVariant {
  display = 'display',
  blockPrimaryTitle = 'blockPrimaryTitle',
  blockSecondaryTitle = 'blockSecondaryTitle',
  blockInlineTitle = 'blockInlineTitle',
  blockTertiaryTitle = 'blockTertiaryTitle',
  tabTitle = 'tabTitle',
  body = 'body',
  small = 'small',
  smallBold = 'smallBold',
  buttonMain = 'buttonMain',
  buttonSmall = 'buttonSmall',
  code = 'code',
}

export interface Font {
  fontFamily: Property.FontFamily,
  fontSize: Property.FontSize,
  fontWeight: Globals | 'bold' | 'normal' | 'bolder' | 'lighter' | number,
  fontStyle: Property.FontStyle,
  lineHeight: Property.LineHeight,
  letterSpacing: Property.LetterSpacing,
}

const fontDefinition: Record<FontVariant, Font> = {
  display: {
    fontFamily: expose,
    fontSize: '3.2rem',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: '4.2rem',
    letterSpacing: '0.05rem',
  },
  blockPrimaryTitle: {
    fontFamily: expose,
    fontSize: '1.8rem',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: '2.4rem',
    letterSpacing: '0.05rem',
  },
  blockSecondaryTitle: {
    fontFamily: inter,
    fontSize: '1.4rem',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: textLineHeight.main,
    letterSpacing: 'normal',
  },
  blockInlineTitle: {
    fontFamily: inter,
    fontSize: '1.3rem',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: textLineHeight.main,
    letterSpacing: 'normal',
  },
  blockTertiaryTitle: {
    fontFamily: inter,
    fontSize: '1.3rem',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: textLineHeight.main,
    letterSpacing: 'normal',
  },
  tabTitle: {
    fontFamily: inter,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1.4rem',
    lineHeight: textLineHeight.main,
    letterSpacing: 'normal',
  },
  body: {
    fontFamily: inter,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1.3rem',
    lineHeight: textLineHeight.main,
    letterSpacing: 'normal',
  },
  small: {
    fontFamily: inter,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1.1rem',
    lineHeight: textLineHeight.small,
    letterSpacing: 'normal',
  },
  smallBold: {
    fontFamily: inter,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1.1rem',
    lineHeight: textLineHeight.small,
    letterSpacing: 'normal',
  },
  buttonMain: {
    fontFamily: inter,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1.3rem',
    lineHeight: textLineHeight.small,
    letterSpacing: 'normal',
  },
  buttonSmall: {
    fontFamily: inter,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1.1rem',
    lineHeight: textLineHeight.small,
    letterSpacing: 'normal',
  },
  code: {
    fontFamily: 'Anonymous Pro',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1.3rem',
    lineHeight: textLineHeight.small,
    letterSpacing: 'normal',
  },
};

export default fontDefinition;
