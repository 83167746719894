import { newError } from 'yooi-utils';
import type { GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import { registerField } from '../module';
import type { SubscriptionsField } from './types';

type SubscriptionsFieldHandler = GetDslFieldHandler<SubscriptionsField, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined>;
export const subscriptionsFieldHandler: SubscriptionsFieldHandler = registerField({
  model: { label: 'SubscriptionsField', title: 'Subscriptions' },
  handler: () => ({
    describe: () => ({ hasData: false }),
    restApi: {
      returnTypeSchema: {},
      formatValue: () => undefined,
    },
    getStoreValue: () => undefined,
    getValueWithoutFormula: () => undefined,
    getValueResolution: () => ({ value: undefined, getDisplayValue: () => undefined, isComputed: false, isTimeseries: false }),
    updateValue: () => {
      throw newError('updateValue not supported');
    },
    resolvePathStepConfiguration: () => ({ hasData: false }),
    isEmpty: () => false,
    isSaneValue: () => ({ isValid: true }),
  }),
});
