import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { Property } from 'csstype';
import type { FunctionComponent, KeyboardEventHandler, ReactElement, ReactNode, Ref } from 'react';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { Overlay as ReactOverlay } from 'react-overlays';
import type { Modifier } from 'react-overlays/usePopper';
import useResizeObserver from 'use-resize-observer';
import base from '../../theme/base';
import { spacingRem } from '../../theme/spacingDefinition';
import makeSelectorsClasses from '../../utils/makeSelectorsClasses';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import useBackdropClick from '../../utils/useBackdropClick';
import useFocusOnMount from '../../utils/useFocusOnMount';
import useHideOnDisappearRef from '../../utils/useHideOnDisappearRef';
import useOverlayContainerRef from '../../utils/useOverlayContainerRef';
import { useTooltip } from '../../utils/useTooltip';
import useUsageContext, { UsageVariant } from '../../utils/useUsageContext';
import { computeStylesNoGpu, overlayMeasurements } from './internal/overlayUtils';

export enum FloatingEditableCloseReasons {
  onEscapeKeyDown = 'onEscapeKeyDown',
  onBackdropClick = 'onBackdropClick',
  onTabKeyDown = 'onTabKeyDown',
  onEnterKeyDown = 'onEnterKeyDown',
}

const selectorsClasses = makeSelectorsClasses('focusedVisible', 'focusedFlex');

const shadowSize = '1.8rem';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    [`&:hover .${selectorsClasses.focusedVisible}, &:focus-within .${selectorsClasses.focusedVisible}`]: {
      visibility: 'visible',
    },
    [`&:hover .${selectorsClasses.focusedFlex}, &:focus-within .${selectorsClasses.focusedFlex}`]: {
      display: 'flex',
    },
  },
  containerBackground: {
    backgroundColor: theme.color.background.neutral.default,
  },
  containerInvisible: {
    visibility: 'hidden',
  },
  containerBorderInvisible: {
    borderColor: theme.color.transparent,
  },
  containerInCell: {
    backgroundColor: theme.color.transparent,
    width: '100%',
    height: '100%',
  },
  containerBorderEditing: {
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.dark,
    borderRadius: base.borderRadius.medium,
  },
  containerBorderSelected: {
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.dark,
    borderRadius: base.borderRadius.medium,
    boxShadow: 'none',
  },
  containerBorderMain: {
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
    borderRadius: base.borderRadius.medium,
    '&:hover': {
      borderColor: theme.color.border.hover,
    },
    '&:focus-within': {
      borderColor: theme.color.border.dark,
    },
  },
  containerBorderMainReadOnly: {
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
    borderRadius: base.borderRadius.medium,
  },
  containerBorderForm: {
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.default,
    borderRadius: base.borderRadius.medium,
    '&:hover': {
      borderColor: theme.color.border.hover,
    },
    '&:focus-within': {
      borderColor: theme.color.border.dark,
    },
  },
  containerFormReadOnly: {
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.default,
    borderRadius: base.borderRadius.medium,
  },
  // since hover & focus border color are different this permit to minimize the border color flickering when opening the dropdown
  containerBorderForcedFocus: {
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.dark,
    borderRadius: base.borderRadius.medium,
  },
  containerBorderForcedFocusReadOnly: {
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
    borderRadius: base.borderRadius.medium,
  },
  containerBorderless: {
    borderWidth: 0,
    borderRadius: 0,
  },
  containerOutline: {
    outlineWidth: '0.1rem',
    outlineStyle: 'solid',
    outlineColor: theme.color.transparent,
    '&:hover': {
      outlineColor: theme.color.border.hover,
    },
    '&:focus-within': {
      outlineColor: theme.color.border.hover,
    },
  },
  containerOutlineMultiplayer: {
    outlineWidth: '0.1rem',
    outlineStyle: 'solid',
    outlineColor: theme.color.border.primary,
    '&:hover': {
      outlineColor: theme.color.border.primary,
    },
    '&:focus-within': {
      outlineColor: theme.color.border.primary,
    },
  },
  overlayContainer: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: 'none',
    rowGap: spacingRem.s,
    [`& .${selectorsClasses.focusedVisible}`]: {
      visibility: 'visible',
    },
    [`& .${selectorsClasses.focusedFlex}`]: {
      display: 'flex',
    },
    paddingLeft: shadowSize,
    paddingRight: shadowSize,
    paddingBottom: shadowSize,
  },
  overlayValueContainer: {
    position: 'relative',
    width: 'fit-content',
    pointerEvents: 'all',
    backgroundColor: theme.color.background.neutral.default,
    overflow: 'hidden',
  },
  overlayValueContainerBorder: {
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.dark,
    borderRadius: base.borderRadius.medium,
    boxShadow: base.shadowElevation.low,
  },
  overlayValueContainerBorderReadOnly: {
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
    borderRadius: base.borderRadius.medium,
  },
  overlayValueContainerFormReadOnly: {
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.default,
    borderRadius: base.borderRadius.medium,
  },
  overlayValueContainerOutline: {
    // We add a margin top to make sure the outline is visible (linked to the -0.1rem offset of the overlay)
    marginTop: '0.1rem',
    outlineWidth: '0.1rem',
    outlineStyle: 'solid',
    outlineColor: theme.color.border.dark,
  },
  overlayValueContainerOutlineReadOnly: {
    outlineWidth: '0.1rem',
    outlineStyle: 'solid',
    outlineColor: theme.color.border.default,
  },
  overlayValueContainerBorderless: {
    borderWidth: 0,
    borderRadius: 0,
  },
  valueContainer: {
    display: 'flex',
    flexShrink: 0,
    overflow: 'hidden',
  },
  valueContainerInCell: {
    width: '100%',
    minHeight: '3.8rem',
  },
  overlayDropdownContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
    backgroundColor: theme.color.background.neutral.default,
    pointerEvents: 'all',
    borderRadius: base.borderRadius.medium,
    boxShadow: base.shadowElevation.medium,
  },
  containerBorderDashed: {
    borderStyle: 'dashed',
  },
  noPointerEvents: {
    pointerEvents: 'none',
  },
  reactOverlayContainer: {
    display: 'flex',
    zIndex: 1,
  },
}), 'floatingEditableWithDropdown');

const overlayPreventOverflow: Modifier<string, Record<string, number>> = {
  name: 'preventOverflow',
  enabled: true,
  phase: 'main',
  fn: ({ state, options }) => {
    if (!state.modifiersData.popperOffsets) {
      return;
    }

    const { padding = 4 } = options;

    const { width: popperWidth } = state.rects.popper;
    const { clientWidth: scrollWidth } = state.scrollParents.popper[0] as Element;

    const { x: popperX } = state.modifiersData.popperOffsets ?? { x: 0 };

    // Content larger than the scroll container
    if (popperWidth >= scrollWidth) {
      Object.assign(state.modifiersData.popperOffsets, { x: 0 });
      return;
    }

    // If overlay is outside the scroll container
    if (popperX + popperWidth > scrollWidth - padding) {
      // Try to align the content with the right of the scroll container
      const newXScrollContainerRightAlign = scrollWidth - popperWidth - padding - remToPx(shadowSize);
      if (newXScrollContainerRightAlign >= padding && newXScrollContainerRightAlign <= popperX) {
        Object.assign(state.modifiersData.popperOffsets, { x: newXScrollContainerRightAlign });
        return;
      }
    }

    Object.assign(state.modifiersData.popperOffsets, { x: state.modifiersData.popperOffsets.x - remToPx(shadowSize) });
  },
};

const popperConfig = { modifiers: [computeStylesNoGpu, overlayMeasurements, overlayPreventOverflow] };

interface FloatingEditableWithDropdownProps {
  showDropdown: boolean,
  openDropdown: () => void,
  closeDropdown: (reason: FloatingEditableCloseReasons) => void,
  renderValue: (isFloating: boolean) => (ReactNode | null),
  renderDropdown: () => (ReactNode | null),
  valueSizes?: {
    minWidth?: Property.MinWidth,
    width?: Property.Width,
    maxWidth?: Property.MaxWidth,
    minHeight?: Property.MinHeight,
    height?: Property.Height,
    maxHeight?: Property.MaxHeight,
  },
  overlayValueSizes?: {
    minWidth?: Property.MinWidth,
    width?: Property.Width,
    maxWidth?: Property.MaxWidth,
    minHeight?: Property.MinHeight,
    height?: Property.Height,
    maxHeight?: Property.MaxHeight,
  },
  overlayDropdownSizes?: {
    minWidth?: Property.MinWidth,
    width?: Property.Width,
    maxWidth?: Property.MaxWidth,
    minHeight?: Property.MinHeight,
    height?: Property.Height,
    maxHeight?: Property.MaxHeight,
  },
  isEditing?: boolean,
  isSelected?: boolean,
  readOnly?: boolean,
  closeOnTabKeyDown?: boolean,
  validateOnEnterKeyDown?: boolean,
  onDropdownKeyDown?: KeyboardEventHandler<HTMLElement>,
  withMultiplayerOutline?: boolean,
  restingTooltip?: string | (() => Promise<string>),
  borderless?: boolean,
  withDashedBorder?: boolean,
  withoutOverlayValueMinWidth?: boolean,
  autoFocusDropdown?: boolean,
}

const FloatingEditableWithDropdown: FunctionComponent<FloatingEditableWithDropdownProps> = ({
  showDropdown,
  openDropdown,
  closeDropdown,
  renderValue,
  renderDropdown,
  valueSizes,
  overlayValueSizes,
  overlayDropdownSizes,
  isEditing = false,
  isSelected = false,
  readOnly = false,
  closeOnTabKeyDown = false,
  validateOnEnterKeyDown = false,
  onDropdownKeyDown,
  withMultiplayerOutline = false,
  restingTooltip,
  borderless = false,
  withDashedBorder = false,
  withoutOverlayValueMinWidth = false,
  autoFocusDropdown = false,
}) => {
  const classes = useStyles();

  const usageVariant = useUsageContext();

  const editableContainerRef = useRef<HTMLDivElement | null>(null);
  const { hideRef, monitorRef } = useHideOnDisappearRef<HTMLDivElement>(showDropdown);

  const overlayContainerRef = useOverlayContainerRef();
  const popperUpdateRef = useRef<() => void>();
  const backdropClickRef = useRef<HTMLDivElement>(null);
  const overlayValueContainerRef = useRef<HTMLDivElement | null>(null);

  const { displayTooltip, hideTooltip } = useTooltip();

  const { ref: resizeRef } = useResizeObserver({ onResize: () => popperUpdateRef.current?.() });

  // permit to track the target move (eg. for multiplayer: table row deletion, matrix point move, ...)
  // it may not work if the overlay rendering is not dependent of the target rendering (in current state of the app it doesn't seem to be possible)
  const targetPosition = useRef<{ x?: number, y?: number }>({});
  useLayoutEffect(() => {
    if (editableContainerRef.current) {
      const { x, y } = editableContainerRef.current.getBoundingClientRect();
      const { x: previousX, y: previousY } = targetPosition.current;
      if (previousX && previousY && (previousX !== x || previousY !== y)) {
        popperUpdateRef.current?.();
      }
      targetPosition.current = { x, y };
    }
  });

  // ensure that focus is set back when dropdown is closed
  const latestShowDropdownRef = useRef(showDropdown);
  useEffect(() => {
    if (!showDropdown && latestShowDropdownRef.current) {
      editableContainerRef.current?.focus({ preventScroll: true });
    }
    latestShowDropdownRef.current = showDropdown;
  }, [showDropdown]);

  useBackdropClick(backdropClickRef, () => {
    latestShowDropdownRef.current = false; // prevent the editable to steal the focus which have been captured by the backdrop click
    closeDropdown(FloatingEditableCloseReasons.onBackdropClick);
  }, true, showDropdown);

  const isFormVariant = usageVariant === UsageVariant.inForm || withDashedBorder;
  const isMainVariant = usageVariant === UsageVariant.inline || usageVariant === UsageVariant.inCard;
  const isTableVariant = usageVariant === UsageVariant.inTable;
  const isBorderVariant = isFormVariant || isMainVariant;

  const dropdownContainerRef = useRef<HTMLDivElement | null>(null);
  useFocusOnMount(dropdownContainerRef, autoFocusDropdown && showDropdown);

  const doRenderDropdown = () => {
    const dropdown = renderDropdown();
    if (dropdown === null) {
      return null;
    } else {
      return (
        <span
          ref={dropdownContainerRef}
          style={overlayDropdownSizes}
          tabIndex={autoFocusDropdown ? 0 : undefined}
          className={classes.overlayDropdownContainer}
          role="button"
          onKeyDown={onDropdownKeyDown}
        >
          {dropdown}
        </span>
      );
    }
  };

  const renderAndLayoutValue = (isFloating: boolean, ref?: Ref<HTMLSpanElement>) => (
    <span ref={ref} className={classnames({ [classes.valueContainer]: true, [classes.valueContainerInCell]: isTableVariant })}>
      {renderValue(isFloating)}
    </span>
  );

  // in order to avoid resize when dropdown is open, we keep the 'editable' rendering in this ref, until the dropdown is closed
  const renderValueAtDropdownRef = useRef<ReactElement>();
  const renderValueAtDropdownHTMLElementRef = useRef<HTMLSpanElement>(null);

  const renderEditableValue = () => {
    if (showDropdown) {
      if (renderValueAtDropdownRef.current) {
        return renderValueAtDropdownRef.current;
      } else {
        const rendered = renderAndLayoutValue(false, renderValueAtDropdownHTMLElementRef);
        renderValueAtDropdownRef.current = rendered;
        return rendered;
      }
    } else {
      renderValueAtDropdownRef.current = undefined;
      return renderAndLayoutValue(false, renderValueAtDropdownHTMLElementRef);
    }
  };

  return (
    <div
      ref={composeReactRefs<HTMLDivElement>(monitorRef, editableContainerRef)}
      style={valueSizes}
      className={classnames({
        [classes.container]: true,
        [classes.containerBackground]: showDropdown,
        [classes.containerInvisible]: showDropdown,
        [classes.containerBorderInvisible]: showDropdown && isBorderVariant,
        [classes.containerInCell]: isTableVariant,
        [classes.containerBorderEditing]: isEditing,
        [classes.containerBorderSelected]: isSelected,
        [classes.containerBorderForm]: isFormVariant && !readOnly && !(showDropdown || isEditing || isSelected),
        [classes.containerFormReadOnly]: isFormVariant && readOnly && !(showDropdown || isEditing),
        [classes.containerBorderMain]: isMainVariant && !readOnly && !(showDropdown || isEditing),
        [classes.containerBorderDashed]: withDashedBorder,
        [classes.containerBorderMainReadOnly]: isMainVariant && readOnly && !(showDropdown || isEditing),
        [classes.containerBorderForcedFocus]: isBorderVariant && !readOnly && showDropdown,
        [classes.containerBorderForcedFocusReadOnly]: isBorderVariant && readOnly && showDropdown,
        [classes.containerOutline]: isTableVariant,
        [classes.containerOutlineMultiplayer]: isTableVariant && withMultiplayerOutline,
        [classes.containerBorderless]: borderless,
      })}
      tabIndex={0} // element is focusable in order to be edited
      onKeyDown={(event) => {
        if (showDropdown && closeOnTabKeyDown && event.key === 'Tab' && !readOnly) {
          closeDropdown(FloatingEditableCloseReasons.onTabKeyDown);
        } else if (event.key === 'Enter') {
          if (!showDropdown && !readOnly) {
            event.preventDefault(); // prevent unexpected line break in textarea in dropdown container
            event.stopPropagation(); // prevent queryTable to (probably queryTable to should not handle Enter but provide a focus (tabindex) on the Open button)
            openDropdown();
          }
        }
      }}
      onClick={() => {
        if (!showDropdown) {
          openDropdown();
        }
      }}
      role="button"
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      onMouseOver={restingTooltip ? () => {
        if (editableContainerRef.current && !showDropdown) {
          displayTooltip({
            element: editableContainerRef.current,
            text: restingTooltip,
          });
        }
      } : undefined}
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      onMouseOut={hideTooltip}
    >
      {renderEditableValue()}
      {showDropdown ? (
        <ReactOverlay
          show
          popperConfig={popperConfig}
          target={editableContainerRef}
          container={overlayContainerRef}
          placement="bottom-start"
          offset={[
            undefined,
            // We add an offset to make sure the outline is visible (linked to the 0.1rem margin of the class overlayValueContainerOutline)
            isTableVariant ? -remToPx(0.1) : undefined,
          ]}
        >
          {({ state, update, props: { style: { display, zIndex, ...style }, ref, ...props } }) => {
            popperUpdateRef.current = update;
            const left = state === undefined
              ? undefined
              : Math.max(
                0,
                state.rects.reference.x
                + state.rects.reference.width
                - (renderValueAtDropdownHTMLElementRef.current?.clientWidth ?? 0)
                - (state.modifiersData.popperOffsets?.x ?? 0)
                - remToPx(shadowSize)
                - (isBorderVariant ? remToPx(0.1 * 2 /* border size */) : 0)
              );

            return (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                {...props}
                className={classnames(classes.reactOverlayContainer, classes.noPointerEvents)}
                ref={composeReactRefs<HTMLDivElement>(ref, resizeRef, backdropClickRef)}
                style={style}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') {
                    closeDropdown(FloatingEditableCloseReasons.onEscapeKeyDown);
                    e.stopPropagation(); // close only one Overlay at a time
                  } else if (validateOnEnterKeyDown && e.key === 'Enter' && !e.shiftKey) {
                    closeDropdown(FloatingEditableCloseReasons.onEnterKeyDown);
                    e.stopPropagation();
                  }
                }}
              >
                <span ref={hideRef} className={classes.overlayContainer}>
                  <span
                    ref={overlayValueContainerRef}
                    className={classnames({
                      [classes.overlayValueContainer]: true,
                      [classes.overlayValueContainerBorder]: isBorderVariant && !readOnly,
                      [classes.overlayValueContainerBorderReadOnly]: isBorderVariant && readOnly,
                      [classes.containerBorderDashed]: withDashedBorder,
                      [classes.overlayValueContainerFormReadOnly]: isFormVariant && readOnly,
                      [classes.overlayValueContainerOutline]: isTableVariant && !readOnly,
                      [classes.overlayValueContainerOutlineReadOnly]: isTableVariant && readOnly,
                      [classes.overlayValueContainerBorderless]: borderless,
                    })}
                    style={{
                      left,
                      minWidth: withoutOverlayValueMinWidth && !isTableVariant ? overlayValueSizes?.minWidth : state?.rects.reference.width,
                      width: overlayValueSizes?.width,
                      maxWidth: overlayValueSizes?.maxWidth,
                      minHeight: overlayValueSizes?.minHeight,
                      height: overlayValueSizes?.height,
                      maxHeight: overlayValueSizes?.maxHeight,
                    }}
                  >
                    {renderAndLayoutValue(true)}
                  </span>
                  {doRenderDropdown()}
                </span>
              </div>
            );
          }}
        </ReactOverlay>
      ) : null}
    </div>
  );
};

export default FloatingEditableWithDropdown;
