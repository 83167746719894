import classnames from 'classnames';
import { isHotkey } from 'is-hotkey';
import { equals } from 'ramda';
import type { ForwardedRef, FunctionComponent, MouseEventHandler, RefObject } from 'react';
import { useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import type { Range } from 'slate';
import { createEditor, Editor, Node as SlateNode, Transforms } from 'slate';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';
import type { RenderElementProps } from 'slate-react/dist/components/editable';
import type { Selection } from 'slate/dist/interfaces/editor';
import type { RichText } from 'yooi-utils';
import { joinObjects, RichTextMarks, richTextToText } from 'yooi-utils';
import fontDefinition from '../../theme/fontDefinition';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import { getTextMeasurerForVariant } from '../../utils/textUtils';
import useSizeContext from '../../utils/useSizeContext';
import type { TypoVariant } from '../atoms/Typo';
import { sizeVariantToTypoVariant } from '../atoms/Typo';
import RichTextElement from './internal/RichTextElement';
import RichTextLeaf from './internal/RichTextLeaf';
import RichTextToolbar, { toggleMark } from './internal/RichTextToolbar';
import richTextWithLinks from './internal/RichTextWithLinks';
import richTextWithMarkdownShortcuts from './internal/RichTextWithMarkdownShortcuts';

const useStyles = makeStyles((theme) => (joinObjects(
  {
    editor: {
      overflowX: 'hidden',
      flexGrow: 1,
    },
    maxLine: {
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      wordBreak: 'break-all',
    },
    alignRight: {
      textAlign: 'right',
    },
    alignCenter: {
      textAlign: 'center',
    },
  },
  theme.font
)), 'richTextEditor');

const isValidSelection = (editor: Editor, range: Range) => {
  const slateStartPoint = range.anchor;
  const slateEndPoint = range.focus;
  let validStart = false;
  let validEnd = false;
  if (slateStartPoint && slateEndPoint && SlateNode.has(editor, slateEndPoint.path) && SlateNode.has(editor, slateStartPoint.path)) {
    try {
      const selectedElementStart = SlateNode.leaf(editor, slateStartPoint.path); // throws error is node is not a leaf
      validStart = selectedElementStart && selectedElementStart.text.length >= slateStartPoint.offset;
    } catch {
      validStart = false;
    }
    try {
      const selectedElementEnd = SlateNode.leaf(editor, slateEndPoint.path); // throws error is node is not a leaf
      validEnd = selectedElementEnd && selectedElementEnd.text.length >= slateEndPoint.offset;
    } catch {
      validEnd = false;
    }
  }
  return {
    start: validStart,
    end: validEnd,
  };
};

export enum RichTextAlign {
  left = 'left',
  right = 'right',
  center = 'center',
}

const HOTKEYS: { [key: string]: string } = {
  'mod+b': RichTextMarks.bold,
  'mod+i': RichTextMarks.italic,
  'mod+u': RichTextMarks.underline,
  'mod+M': RichTextMarks.code,
};

export interface RichTextEditorRef {
  resetSelection: () => void,
  getSelection: () => Selection,
}

interface RichTextEditorProps {
  value: RichText,
  placeholder?: string,
  onChange?: (value: RichText | undefined) => void,
  onDoubleClick?: MouseEventHandler<HTMLDivElement>,
  readOnly?: boolean,
  spellCheck?: boolean,
  maxRows?: number,
  align?: RichTextAlign,
  containerRef?: RefObject<HTMLElement | undefined>,
  initialSelection?: Selection,
  preventRichFeatures?: boolean,
  color?: string,
  richTextEditorRef?: ForwardedRef<RichTextEditorRef>,
  focusOnMount?: boolean,
  variant?: TypoVariant,
}

const RichTextEditor: FunctionComponent<RichTextEditorProps> = ({
  readOnly,
  onChange = () => {},
  value,
  onDoubleClick = () => {},
  placeholder,
  maxRows,
  align,
  spellCheck = false,
  containerRef,
  color,
  preventRichFeatures = false,
  richTextEditorRef,
  initialSelection,
  focusOnMount,
  variant,
}) => {
  const classes = useStyles();
  const toolbarRef = useRef<HTMLDivElement>(null);
  const initialSelectionRef = useRef(initialSelection);

  const renderElement = useCallback(({ children, ...props }: RenderElementProps) => (<RichTextElement typoVariant={variant} {...props}>{children}</RichTextElement>), [variant]);
  const { sizeVariant } = useSizeContext();
  const computedTextVariant = sizeVariantToTypoVariant[sizeVariant];
  const { lineHeight } = fontDefinition[computedTextVariant];
  const lineHeightInPx = remToPx(lineHeight);
  const maxHeight = maxRows && lineHeightInPx ? lineHeightInPx * maxRows : null;
  const isEmptyValue = !value || richTextToText(value) === '';
  const measureText = getTextMeasurerForVariant(computedTextVariant);

  const editor = useMemo(() => {
    if (preventRichFeatures) {
      return richTextWithLinks(withReact(createEditor()));
    } else {
      return richTextWithMarkdownShortcuts(richTextWithLinks(withReact(createEditor())));
    }
  }, [preventRichFeatures]);

  if (!equals(value, editor.children)) {
    editor.children = value; // Needed to handle value update see https://github.com/ianstormtaylor/slate/issues/4612
    if (editor.selection) {
      const { start: isValidStart, end: isValidEnd } = isValidSelection(editor, editor.selection);
      if (isValidStart && !isValidEnd) {
        Transforms.select(editor, {
          anchor: editor.selection.anchor,
          focus: editor.selection.anchor,
        });
      } else if (!isValidStart) {
        Transforms.select(editor, {
          anchor: Editor.end(editor, []),
          focus: Editor.end(editor, []),
        });
      }
    }
  }

  useImperativeHandle(richTextEditorRef, () => ({
    resetSelection: () => Transforms.deselect(editor),
    getSelection: () => editor.selection,
  }));

  useEffect(() => {
    if (focusOnMount) {
      setImmediate(() => { // Avoid uncontrolled scroll when used in Editable With Dropdown
        ReactEditor.focus(editor);
        if (initialSelectionRef.current) {
          Transforms.select(editor, initialSelectionRef.current);
        } else {
          Transforms.select(editor, {
            anchor: Editor.end(editor, []),
            focus: Editor.end(editor, []),
          });
        }
      });
    }
  }, [editor, focusOnMount]);

  return (
    <div
      className={classnames({
        [classes.maxLine]: readOnly && maxRows,
        [classes.editor]: true,
        [classes[computedTextVariant]]: true,
        [classes.alignRight]: align === RichTextAlign.right,
        [classes.alignCenter]: align === RichTextAlign.center,
      })}
      tabIndex={-1}
      style={joinObjects(
        { color, maxHeight: maxHeight ?? undefined },
        (readOnly ? { WebkitLineClamp: maxRows } : { overflowY: 'auto' } as const),
        (readOnly ? { pointerEvents: 'none' } as const : { pointerEvents: 'all' } as const), // Fixes a bug in tables : switching from an input to another fails to focus.
        (isEmptyValue ? { minWidth: Math.max(measureText(placeholder), remToPx(0.1)) } : {})
      )}
    >
      <Slate
        editor={editor}
        initialValue={value}
        onChange={(myValue) => {
          onChange(myValue as RichText);
        }}
      >
        <div>
          {!readOnly && !preventRichFeatures ? (<RichTextToolbar ref={toolbarRef} containerRef={containerRef} />) : null}
        </div>
        <Editable
          tabIndex={-1}
          onDoubleClick={onDoubleClick}
          readOnly={readOnly}
          spellCheck={spellCheck}
          placeholder={isEmptyValue ? placeholder ?? '' : undefined}
          renderPlaceholder={({ attributes, children }) => {
            const { style, ...otherAttributes } = attributes;
            return (
              <span
                // Override editable styles
                style={joinObjects(style, { opacity: 1, color })}
                {...otherAttributes}
              >
                {children}
              </span>
            );
          }}
          renderElement={renderElement}
          renderLeaf={({ children, ...props }) => (<RichTextLeaf {...props}>{children}</RichTextLeaf>)}
          onKeyDown={(event) => {
            Object.keys(HOTKEYS).forEach((hotkey) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore -> library types mismatch
              if (isHotkey(hotkey, event) && !preventRichFeatures) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            });
          }}
        />
      </Slate>
    </div>
  );
};

export default RichTextEditor;
