import type { FunctionComponent } from 'react';
import i18n from '../../utils/i18n';
import useTheme from '../../utils/useTheme';
import Button, { ButtonVariant } from '../atoms/Button';
import { IconName } from '../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../atoms/IconOnlyButton';
import Tooltip from '../atoms/Tooltip';
import Typo, { TypoVariant } from '../atoms/Typo';
import SpacingLine from '../molecules/SpacingLine';

export interface Pagination {
  currentPage: number,
  totalPagesNumber: number,
  totalItems: number,
  itemsRange: {
    low: number,
    high: number,
  },
  onNext: () => void,
  onPrevious: () => void,
  onPage: (pageNumber: number) => void,
}

interface PageSelectorProps {
  pagination?: Pagination,
  pageRangeDisplayed?: number,
}

const PageSelector: FunctionComponent<PageSelectorProps> = ({ pagination, pageRangeDisplayed = 3 }) => {
  const theme = useTheme();

  if (pagination && (pagination.totalPagesNumber > 1 || pagination.currentPage !== 0)) {
    const selected = pagination.currentPage;
    const pageCount = pagination.totalPagesNumber;
    const marginPagesDisplayed = 1;

    const items = [];
    if (pageCount <= pageRangeDisplayed) {
      for (let index = 0; index < pageCount; index += 1) {
        items.push({
          type: 'page',
          index,
        });
      }
    } else {
      let leftSide = pageRangeDisplayed / 2;
      let rightSide = pageRangeDisplayed - leftSide;
      if (selected > pageCount - pageRangeDisplayed / 2) {
        rightSide = pageCount - selected;
        leftSide = pageRangeDisplayed - rightSide;
      } else if (selected < pageRangeDisplayed / 2) {
        leftSide = selected;
        rightSide = pageRangeDisplayed - leftSide;
      }
      for (let index = 0; index < pageCount; index += 1) {
        const page = index + 1;
        if (page <= marginPagesDisplayed) {
          items.push({
            type: 'page',
            index,
          });
        } else if (page > pageCount - marginPagesDisplayed) {
          items.push({
            type: 'page',
            index,
          });
        } else {
          const adjustedRightSide = selected === 0 && pageRangeDisplayed > 1 ? rightSide - 1 : rightSide;
          if ((selected === 0 && index <= pageRangeDisplayed) || (index >= selected - leftSide && index <= selected + adjustedRightSide)) {
            items.push({
              type: 'page',
              index,
            });
          } else if (items.length > 0 && (pageRangeDisplayed > 0 || marginPagesDisplayed > 0)) {
            if (items[items.length - 1].type !== 'break') {
              items.push({ type: 'break', index });
            } else if (items[items.length - 1].type === 'break' && index < selected) {
              items[items.length - 1] = { type: 'break', index };
            }
          }
        }
      }
    }

    const textHelper = i18n`${pagination.itemsRange.low}-${pagination.itemsRange.high} out of ${pagination.totalItems} results`;

    return (
      <SpacingLine>
        <Tooltip title={textHelper}>
          <Typo variant={TypoVariant.body} color={theme.color.text.secondary} maxLine={1}>{textHelper}</Typo>
        </Tooltip>
        <IconOnlyButton
          tooltip={i18n`Previous page`}
          iconName={IconName.keyboard_arrow_left}
          onClick={pagination.onPrevious}
          variant={IconOnlyButtonVariants.tertiary}
          disabled={pagination.currentPage <= 0}
        />
        {
          items.map((item) => {
            if (item.type === 'page') {
              return (
                <Button
                  key={item.index}
                  title={(item.index + 1).toString()}
                  onClick={() => pagination.onPage(item.index)}
                  variant={item.index === selected ? ButtonVariant.secondary : ButtonVariant.tertiary}
                />
              );
            } else {
              return (
                <Button
                  key={item.index}
                  title="..."
                  onClick={() => pagination.onPage(item.index)}
                  variant={ButtonVariant.tertiary}
                />
              );
            }
          })
        }
        <IconOnlyButton
          tooltip={i18n`Next page`}
          iconName={IconName.keyboard_arrow_right}
          onClick={pagination.onNext}
          variant={IconOnlyButtonVariants.tertiary}
          disabled={pagination.currentPage >= pagination.totalPagesNumber - 1}
        />
      </SpacingLine>
    );
  } else {
    return null;
  }
};

export default PageSelector;
