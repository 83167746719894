export enum CoverPositionX {
  Left = 'Left',
  Center = 'Center',
  Right = 'Right',
}

export enum CoverPositionY {
  Top = 'Top',
  Center = 'Center',
  Bottom = 'Bottom',
}

export enum CoverHeight {
  Small = 'Small',
  Medium = 'Medium',
  High = 'High',
}

export enum CoverMode {
  Fill = 'Fill',
  Fit = 'Fit',
  Tile = 'Tile',
}
