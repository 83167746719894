import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { Property } from 'csstype';
import type { FunctionComponent, KeyboardEventHandler, MouseEventHandler, ReactElement } from 'react';
import { Fragment, useEffect, useRef } from 'react';
import type { Offset } from 'react-overlays/usePopper';
import base from '../../theme/base';
import { darken } from '../../theme/colorUtils';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import useFocusOnMount from '../../utils/useFocusOnMount';
import useHideOnDisappearRef from '../../utils/useHideOnDisappearRef';
import { buildInputSizeVariantMinusBorderClasses } from '../../utils/useSizeContext';
import useUsageContext, { UsageVariant } from '../../utils/useUsageContext';
import Tooltip from '../atoms/Tooltip';
import type { OverlayPlacement } from './Overlay';
import Overlay from './Overlay';

enum EditableCloseReasons {
  onEscapeKeyDown = 'onEscapeKeyDown',
  onBackdropClick = 'onBackdropClick',
  onTabKeyDown = 'onTabKeyDown',
}

const useStyles = makeStyles((theme) => ({
    dropdownContainer: {
      position: 'relative',
      height: '100%',
      background: theme.color.background.neutral.default,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      rowGap: spacingRem.s,
      paddingBottom: spacingRem.s,
      paddingTop: spacingRem.s,
    },
    dropdownContainerBorder: {
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: theme.color.border.default,
      borderRadius: base.borderRadius.medium,
    },
    dropdownContainerBorderReadOnly: {
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: theme.color.transparent,
      borderRadius: base.borderRadius.medium,
    },
    dropdownContainerOutline: {
      outlineWidth: '0.1rem',
      outlineStyle: 'solid',
      outlineColor: theme.color.border.default,
    },
    dropdownContainerOutlineReadOnly: {
      outlineWidth: '0.1rem',
      outlineStyle: 'solid',
      outlineColor: theme.color.border.default,
    },
    dropdownContainerShadow: {
      boxShadow: base.shadowElevation.medium,
    },
    ...buildInputSizeVariantMinusBorderClasses('valueContainer', ['minHeight']),

    color: {
      display: 'flex',
    },
    colorDot: {
      borderRadius: base.borderRadius.medium,
      width: '1.2rem',
      height: '1.2rem',
      flexShrink: 0,
      flexGrow: 0,
    },
    colorBar: {
      borderTopLeftRadius: base.borderRadius.medium,
      borderTopRightRadius: base.borderRadius.medium,
      height: spacingRem.s,
      flexShrink: 0,
      flexGrow: 0,
    },
    colorCursor: {
      cursor: 'pointer',
    },
    containerDot: {
      // Align the input height with a standard input size 3.2rem
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    titleContainer: {
      display: 'grid',
      columnGap: spacingRem.s,
      paddingLeft: spacingRem.s,
      paddingRight: spacingRem.s,
    },
    contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: spacingRem.s,
      paddingRight: spacingRem.s,
    },
    contentContainerBorderTop: {
      borderTopWidth: '0.1rem',
      borderTopStyle: 'solid',
      borderTopColor: theme.color.border.default,
    },
    overlayContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    overlayContainerLeft: {
      alignItems: 'flex-start',
    },
    overlayContainerMiddle: {
      alignItems: 'center',
    },
    overlayContainerRight: {
      alignItems: 'flex-end',
    },
    arrow: {
      display: 'block',
      width: 0,
      height: 0,
      borderLeftWidth: '0.8rem',
      borderLeftStyle: 'solid',
      borderLeftColor: theme.color.transparent,
      borderRightWidth: '0.8rem',
      borderRightStyle: 'solid',
      borderRightColor: theme.color.transparent,
      borderBottomWidth: '0.8rem',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.color.border.default,
      marginLeft: spacingRem.xs,
      marginRight: spacingRem.xs,
    },
  }
), 'colorWithDropdown');

export enum ColorWithDropdownVariant {
  bar = 'bar',
  leftDot = 'leftDot',
  rightDot = 'rightDot',
}

interface ColorWithDropdownProps {
  color: string,
  tooltip?: string,
  dropdownSizes?: {
    sameWidth?: boolean,
    minWidth?: Property.MinWidth,
    width?: Property.Width,
    maxWidth?: Property.MaxWidth,
    minHeight?: Property.MinHeight,
    height?: Property.Height,
    maxHeight?: Property.MaxHeight,
  },
  readOnly?: boolean,
  showDropdown: boolean,
  openDropdown: () => void,
  closeDropdown: (reason: EditableCloseReasons) => void,
  onDropdownKeyDown?: KeyboardEventHandler<HTMLElement>,
  onDropdownMouseMove?: MouseEventHandler<HTMLElement>,
  renderDropdownTitle?: () => (ReactElement | null),
  renderDropdownContent: () => { key: string, content: ReactElement | null, withTopSeparator?: boolean }[],
  variant?: ColorWithDropdownVariant,
}

const ColorWithDropdown: FunctionComponent<ColorWithDropdownProps> = ({
  color,
  tooltip,
  dropdownSizes = {},
  readOnly = false,
  showDropdown,
  openDropdown,
  closeDropdown,
  onDropdownKeyDown,
  onDropdownMouseMove,
  renderDropdownTitle,
  renderDropdownContent,
  variant = ColorWithDropdownVariant.leftDot,
}) => {
  const classes = useStyles();
  const usageVariant = useUsageContext();

  const editableContainerRef = useRef<HTMLDivElement>(null);
  const dropdownContainerRef = useRef<HTMLDivElement>(null);
  useFocusOnMount(dropdownContainerRef, showDropdown);
  const { hideRef, monitorRef } = useHideOnDisappearRef<HTMLDivElement>(showDropdown);

  // ensure that focus is set back when dropdown is closed
  const latestShowDropdown = useRef(showDropdown);
  useEffect(() => {
    if (!showDropdown && latestShowDropdown.current) {
      editableContainerRef.current?.focus({ preventScroll: true });
    }
    latestShowDropdown.current = showDropdown;
  }, [showDropdown]);

  const isFormVariant = usageVariant === UsageVariant.inForm;
  const isMainVariant = usageVariant === UsageVariant.inline || usageVariant === UsageVariant.inCard;
  const isTableVariant = usageVariant === UsageVariant.inTable;
  const isBorderVariant = isFormVariant || isMainVariant;
  const isOutlineVariant = isTableVariant;
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (showDropdown && event.key === 'Tab' && !readOnly) {
      closeDropdown(EditableCloseReasons.onTabKeyDown);
    } else if (event.key === 'Enter') {
      if (!showDropdown && !readOnly) {
        event.preventDefault(); // prevent unexpected line break in textarea in dropdown container
        event.stopPropagation(); // prevent queryTable to (probably queryTable to should not handle Enter but provide a focus (tabindex) on the Open button)
        openDropdown();
      }
    }
  };

  const computeDropdownSizes = () => {
    if (dropdownSizes.sameWidth) {
      return { minWidth: dropdownSizes.minWidth };
    } else {
      return dropdownSizes;
    }
  };

  const hoverFocusColor = darken(color, 10);

  const colorComponent = (
    <span
      ref={composeReactRefs<HTMLSpanElement>(editableContainerRef, monitorRef)}
      onKeyDown={handleKeyDown}
      onClick={() => {
        if (!readOnly && !showDropdown) {
          openDropdown();
        }
      }}
      onMouseOver={() => {
        if (!readOnly && editableContainerRef.current) {
          editableContainerRef.current.style.backgroundColor = hoverFocusColor;
        }
      }}
      onMouseOut={() => {
        if (editableContainerRef.current) {
          editableContainerRef.current.style.backgroundColor = color;
        }
      }}
      onFocus={() => {
        if (!readOnly && editableContainerRef.current) {
          editableContainerRef.current.style.backgroundColor = hoverFocusColor;
        }
      }}
      onBlur={() => {
        if (editableContainerRef.current) {
          editableContainerRef.current.style.backgroundColor = color;
        }
      }}
      tabIndex={0} // element is focusable in order to be edited
      role="button"
      aria-label={tooltip}
      className={classnames({
        [classes.color]: true,
        [classes.colorDot]: variant !== ColorWithDropdownVariant.bar,
        [classes.colorBar]: variant === ColorWithDropdownVariant.bar,
        [classes.colorCursor]: !readOnly,
      })}
      style={{ backgroundColor: color }}
    />
  );

  const computeOffset = (): Offset | undefined => {
    switch (variant) {
      case ColorWithDropdownVariant.bar:
        return undefined;
      case ColorWithDropdownVariant.leftDot:
        return [-remToPx(1.2 /* dot width */ / 2), remToPx(0.1)];
      case ColorWithDropdownVariant.rightDot:
        return [remToPx(1.2 /* dot width */ / 2), remToPx(0.1)];
    }
  };

  const computePlacement = (): OverlayPlacement => {
    switch (variant) {
      case ColorWithDropdownVariant.bar:
        return 'bottom';
      case ColorWithDropdownVariant.leftDot:
        return 'bottom-start';
      case ColorWithDropdownVariant.rightDot:
        return 'bottom-end';
    }
  };

  return (
    <span className={classnames({ [classes.containerDot]: variant !== ColorWithDropdownVariant.bar })}>
      {tooltip !== undefined ? (<Tooltip title={tooltip} asFlexbox>{colorComponent}</Tooltip>) : colorComponent}
      {showDropdown ? (
        <Overlay
          placement={computePlacement()}
          offset={computeOffset()}
          target={editableContainerRef}
          sameWidth={dropdownSizes.sameWidth}
          onBackdropClick={() => {
            latestShowDropdown.current = false; // prevent the editable to steal the focus which have been captured by the backdrop click
            closeDropdown(EditableCloseReasons.onBackdropClick);
          }}
          onEscapeKeyDown={() => {
            closeDropdown(EditableCloseReasons.onEscapeKeyDown);
          }}
        >
          <div
            ref={hideRef}
            className={classnames({
              [classes.overlayContainer]: true,
              [classes.overlayContainerLeft]: variant === ColorWithDropdownVariant.leftDot,
              [classes.overlayContainerMiddle]: variant === ColorWithDropdownVariant.bar,
              [classes.overlayContainerRight]: variant === ColorWithDropdownVariant.rightDot,
            })}
          >
            <span className={classes.arrow} />
            <div
              ref={dropdownContainerRef}
              style={computeDropdownSizes()}
              className={classnames({
                [classes.dropdownContainer]: true,
                [classes.dropdownContainerBorder]: isBorderVariant && !readOnly,
                [classes.dropdownContainerBorderReadOnly]: isBorderVariant && readOnly,
                [classes.dropdownContainerOutline]: isOutlineVariant && !readOnly,
                [classes.dropdownContainerOutlineReadOnly]: isOutlineVariant && readOnly,
                [classes.dropdownContainerShadow]: variant === ColorWithDropdownVariant.bar,
              })}
              tabIndex={0}
              onKeyDown={onDropdownKeyDown}
              onMouseMove={onDropdownMouseMove}
              role="button"
            >
              {renderDropdownTitle ? (
                <span className={classes.titleContainer}>
                  {renderDropdownTitle()}
                </span>
              ) : null}
              {
                renderDropdownContent()
                  .filter(({ content }) => content !== null)
                  .map(({ key, content, withTopSeparator }) => (
                    <Fragment key={key}>
                      {withTopSeparator ? (<span className={classes.contentContainerBorderTop} />) : null}
                      <span className={classes.contentContainer}>
                        {content}
                      </span>
                    </Fragment>
                  ))
              }
            </div>
          </div>
        </Overlay>
      ) : null}
    </span>
  );
};

export default ColorWithDropdown;
