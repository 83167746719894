import { Navigate } from 'react-router-dom';
import StoreObjectNotFoundErrorBoundary from '../store/StoreObjectNotFoundErrorBoundary';
import i18n from '../utils/i18n';
import type { Routes } from '../utils/routerTypes';
import SettingsRouter from './admin/SettingsRouter';
import ConceptRouter from './concept/ConceptRouter';
import DashboardWidgetPage from './concept/dashboard/DashboardWidgetPage';
import OrganizationEntityList from './concept/OrganizationEntityList';

const RootRouter: Routes = {
  '/organization': () => (
    <OrganizationEntityList />
  ),
  '/concept': ConceptRouter,
  '/widget/:widgetId': ({ widgetId }) => (
    <StoreObjectNotFoundErrorBoundary objectName={i18n`Widget`}>
      <DashboardWidgetPage widgetId={widgetId} />
    </StoreObjectNotFoundErrorBoundary>
  ),
  '/settings': SettingsRouter,
  '/docs/api/types': () => {
    window.location.reload();
    return null;
  },
  '/docs/api': () => {
    window.location.reload();
    return null;
  },
  '/login': '/',

  // Backward compatibility
  '/user-notifications': '/settings/notification',
  '/user-notifications/:ruleId': ({ ruleId }) => (<Navigate to={`/settings/notification/${ruleId}`} replace />),
  '/user-notifications/rule/:ruleId': ({ ruleId }) => (<Navigate to={`/settings/notification/${ruleId}`} replace />),
};

export default RootRouter;
