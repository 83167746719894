import type { FunctionComponent } from 'react';
import { newError, PeriodicityType } from 'yooi-utils';
import type { DatePickerStrategy } from './datePickerUtils';
import DayPicker from './DayPicker';
import MonthPicker from './MonthPicker';
import QuarterPicker from './QuarterPicker';
import WeekPicker from './WeekPicker';
import YearPicker from './YearPicker';

interface DatePickerProps {
  value: Date | undefined,
  onSubmit: (date: Date | undefined) => void,
  strategy?: DatePickerStrategy,
  periodicity: PeriodicityType,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
}

const DatePicker: FunctionComponent<DatePickerProps> = ({ value, onSubmit, strategy, periodicity, onEditionStart, onEditionStop }) => {
  switch (periodicity) {
    case PeriodicityType.day:
      return <DayPicker onSubmit={onSubmit} value={value} onEditionStop={onEditionStop} onEditionStart={onEditionStart} strategy={strategy} />;
    case PeriodicityType.week:
      return <WeekPicker onSubmit={onSubmit} value={value} onEditionStop={onEditionStop} onEditionStart={onEditionStart} strategy={strategy} />;
    case PeriodicityType.month:
      return <MonthPicker onSubmit={onSubmit} value={value} onEditionStop={onEditionStop} onEditionStart={onEditionStart} strategy={strategy} />;
    case PeriodicityType.quarter:
      return <QuarterPicker onSubmit={onSubmit} value={value} onEditionStop={onEditionStop} onEditionStart={onEditionStart} strategy={strategy} />;
    case PeriodicityType.year:
      return <YearPicker onSubmit={onSubmit} value={value} onEditionStop={onEditionStop} onEditionStart={onEditionStart} strategy={strategy} />;
    default:
      throw newError('no periodicity');
  }
};

export default DatePicker;
