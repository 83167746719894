import type { FunctionComponent } from 'react';
import { v4 as uuid } from 'uuid';
import type { NumberColorStepsValue, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { getPathReturnedConceptDefinitionId, NumberColorStepValueType } from 'yooi-modules/modules/conceptModule';
import type { DimensionDisplayOption, StructuralBarChartViewStoredDefinition, ViewDimension, ViewSeries } from 'yooi-modules/modules/dashboardModule';
import { DimensionDisplayAxis, SERIE_GROUP_BY_OPTION, ViewType } from 'yooi-modules/modules/dashboardModule';
import { Direction, joinObjects, moveElementInArray } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../../components/atoms/Button';
import { IconName } from '../../../../components/atoms/Icon';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import BlockContent from '../../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../../components/templates/BlockTitle';
import DataTable from '../../../../components/templates/DataTable';
import VerticalBlock from '../../../../components/templates/VerticalBlock';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import NumberColorStepInput from '../../fields/_global/NumberColorStepInput';
import type { LineEditionOption } from '../../fields/FieldEditionOptionType';
import { EditionOptionTypes } from '../../fields/FieldEditionOptionType';
import type { UpdateViewDefinition } from '../../fields/viewsField/ViewsFieldDefinitionOptions';
import type { Option } from '../../modelTypeUtils';
import { defaultOptionComparator } from '../../modelTypeUtils';
import { getDimensionDisplayOptionsColumns, getViewDefinitionDimensionsDisplayOptions } from '../common/dimensions/viewWithDimensionDisplayOptionsFeatureUtils';
import DimensionExportConfiguration from '../common/series/DimensionExportConfiguration';
import ViewDefinitionSeriesOptions from '../common/series/ViewDefinitionSeriesOptions';
import { addSeries, deleteSeries, getSeriesError, getSeriesMinMax, getViewDimensionsAsParameterDefinitions, updateSeries } from '../common/series/viewWithSeriesFeatureUtils';
import ViewOptionBlock from '../common/ViewOptionBlock';
import { getExportEditionOptions } from '../common/viewUtils';
import { getDimensionLabel } from '../data/dataResolution';
import type { StructuralBarChartViewResolvedDefinition } from './structuralBarChartViewHandler';

const useStyles = makeStyles({
  rangeContainer: {
    display: 'grid',
    rowGap: spacingRem.s,
  },
}, 'structuralBarChartViewDefinitionOptions');

interface StructuralBarChartViewDefinitionOptionsProps {
  widgetName: string | undefined,
  viewDimensions: ViewDimension[],
  viewDefinition: StructuralBarChartViewResolvedDefinition,
  updateViewDefinition: UpdateViewDefinition<StructuralBarChartViewStoredDefinition>,
  readOnly: boolean,
  parameterDefinitions: SingleParameterDefinition[],
}

const StructuralBarChartViewDefinitionOptions: FunctionComponent<StructuralBarChartViewDefinitionOptionsProps> = ({
  widgetName,
  viewDimensions,
  viewDefinition,
  updateViewDefinition,
  readOnly,
  parameterDefinitions,
}) => {
  const classes = useStyles();

  const store = useStore();

  const editionOptions: LineEditionOption[] = [];
  const viewParameterDefinitions: SingleParameterDefinition[] = [...parameterDefinitions, ...getViewDimensionsAsParameterDefinitions(store, viewDimensions)];
  const getGroupByOptions = (config: StructuralBarChartViewResolvedDefinition, viewsData: ViewDimension[] | undefined) => {
    const xAxisDimensions = viewsData?.filter((viewDimension) => config.getDimensionDisplay(viewDimension).axis === DimensionDisplayAxis.x)
      .map(({ id, label, path }, index) => ({ id, label: getDimensionLabel(store, label, index, path) })) ?? [];
    const xAxisSerie = config?.seriesAxis === DimensionDisplayAxis.x && config?.series?.length > 0 ? [{ id: SERIE_GROUP_BY_OPTION, label: 'Series' }] : [];
    return [...xAxisSerie, ...xAxisDimensions].sort(defaultOptionComparator);
  };

  const getSelectedGroupBy = (config: StructuralBarChartViewResolvedDefinition, viewsData: ViewDimension[] | undefined): Option | undefined => {
    const groupById = config.xAxis;
    return getGroupByOptions(config, viewsData).find((option) => option.id === groupById);
  };
  const getNewGroupByValue = (config: StructuralBarChartViewResolvedDefinition, viewsData: ViewDimension[] | undefined): string | undefined => {
    const groupByOption = getSelectedGroupBy(config, viewsData);
    if (groupByOption) {
      return groupByOption.id;
    } else {
      const options = getGroupByOptions(config, viewsData);
      return options.length === 1 ? options[0].id : undefined;
    }
  };

  editionOptions.push(
    {
      key: 'seriesAxis',
      title: i18n`Series axis`,
      type: EditionOptionTypes.select,
      props: {
        readOnly,
        selectedOption: { id: viewDefinition.seriesAxis, label: (viewDefinition.seriesAxis === DimensionDisplayAxis.x ? i18n`In line` : i18n`Stacked`) },
        computeOptions: () => Object.values(DimensionDisplayAxis).map((ax) => ({ id: ax, label: (ax === DimensionDisplayAxis.x ? i18n`In line` : i18n`Stacked`) })),
        onChange: (axis) => {
          if (typeof axis?.id === 'string') {
            updateViewDefinition((oldViewDefinition) => (joinObjects(
              oldViewDefinition,
              {
                seriesAxis: axis.id as DimensionDisplayAxis,
                xAxis: getNewGroupByValue(joinObjects(
                  viewDefinition,
                  { seriesAxis: axis.id as DimensionDisplayAxis }
                ), viewDimensions),
              }
            )));
          }
        },
      },
    }
  );

  editionOptions.push({
    key: 'minValue',
    title: i18n`Min value`,
    type: EditionOptionTypes.custom,
    props: {
      render: () => {
        const { series } = viewDefinition;
        const seriesMinMax = getSeriesMinMax(store, series);
        const generateTooltipLine = (seriesMinMaxValues: { min?: number, max?: number, label?: string }[], isMin: boolean | undefined) => seriesMinMaxValues.map(({
          label,
          min,
          max,
        }) => `- ${label} : ${isMin ? min : max}`).join('\n');
        const isMin = true;
        const infoTooltip = seriesMinMax.length > 0 ? i18n`To help you set a ${isMin ? 'minimum' : 'maximum'} value consistent with all the different series, here are their respective ${isMin ? 'minimum' : 'maximum'} :\n${generateTooltipLine(seriesMinMax, isMin)}` : undefined;

        return (
          <NumberColorStepInput
            value={
              viewDefinition.minValue
              ?? { type: NumberColorStepValueType.value, value: undefined, color: undefined }
            }
            parameterDefinitions={viewParameterDefinitions}
            onChange={(newValue) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { minValue: newValue })))}
            readOnly={readOnly}
            info={infoTooltip}
          />
        );
      },
    },
  });
  editionOptions.push({
    key: 'rangeValues',
    title: i18n`Ranges`,
    type: EditionOptionTypes.custom,
    props: {
      render: () => {
        const steps = viewDefinition.rangeValues
          ?? [{ id: uuid(), type: NumberColorStepValueType.value, value: undefined, color: undefined }];
        return (
          <div className={classes.rangeContainer}>
            {steps.map((step) => (
              <NumberColorStepInput<NumberColorStepsValue>
                key={step.id}
                value={step}
                parameterDefinitions={viewParameterDefinitions}
                onChange={(newValue) => updateViewDefinition(
                  (oldViewDefinition) => (joinObjects(oldViewDefinition, { rangeValues: steps.map((s) => (s.id === step.id ? newValue : s)) }))
                )}
                onDelete={() => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { rangeValues: steps.filter((s) => s.id !== step.id) })))}
                readOnly={readOnly}
              />
            ))}
            {!readOnly && (
              <SpacingLine>
                <Button
                  title={i18n`Add range`}
                  iconName={IconName.add}
                  onClick={() => updateViewDefinition(
                    (oldViewDefinition) => joinObjects(
                      oldViewDefinition,
                      { rangeValues: [...steps, { id: uuid(), type: NumberColorStepValueType.value, color: undefined, value: undefined }] }
                    )
                  )}
                  variant={ButtonVariant.secondary}
                />
              </SpacingLine>
            )}
          </div>
        );
      },
    },
  });
  editionOptions.push({
    key: 'maxValue',
    title: i18n`Max value`,
    type: EditionOptionTypes.custom,
    props: {
      render: () => {
        const { series } = viewDefinition;
        const seriesMinMax = getSeriesMinMax(store, series);
        const generateTooltipLine = (seriesMinMaxValues: { min?: number, max?: number, label?: string }[], isMin: boolean | undefined) => seriesMinMaxValues.map(({
          label,
          min,
          max,
        }) => `- ${label} : ${isMin ? min : max}`).join('\n');
        const isMin = false;
        const infoTooltip = seriesMinMax.length > 0 ? i18n`To help you set a ${isMin ? 'minimum' : 'maximum'} value consistent with all the different series, here are their respective ${isMin ? 'minimum' : 'maximum'} :\n${generateTooltipLine(seriesMinMax, isMin)}` : undefined;
        return (
          <NumberColorStepInput
            value={
              viewDefinition.maxValue
              ?? { type: NumberColorStepValueType.value, value: undefined, color: undefined }
            }
            parameterDefinitions={viewParameterDefinitions}
            onChange={(maxValue) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { maxValue })))}
            readOnly={readOnly}
            info={infoTooltip}
          />
        );
      },
    },
  });

  editionOptions.push({
    key: 'series',
    title: i18n`Series`,
    type: EditionOptionTypes.custom,
    isVertical: true,
    padded: true,
    error: getSeriesError(store, viewDefinition, viewParameterDefinitions, viewDefinition.type),
    props: {
      render: () => (
        <ViewDefinitionSeriesOptions
          viewType={ViewType.StructuralBarChart}
          series={viewDefinition.series ?? []}
          dimensions={viewDimensions}
          onCreateSeries={() => updateViewDefinition((oldViewDefinition) => addSeries(oldViewDefinition))}
          onDeleteSeries={(seriesId) => updateViewDefinition((oldViewDefinition) => deleteSeries(oldViewDefinition, seriesId))}
          onUpdateSeries={(seriesId, properties) => updateViewDefinition((oldViewDefinition) => updateSeries(oldViewDefinition, seriesId, properties))}
          onMoveUpSeries={(index) => updateViewDefinition(
            (oldViewDefinition) => joinObjects(oldViewDefinition, { series: moveElementInArray<ViewSeries>(Direction.up, index, viewDefinition.series ?? []) })
          )}
          onMoveDownSeries={(index) => updateViewDefinition(
            (oldViewDefinition) => joinObjects(oldViewDefinition, { series: moveElementInArray<ViewSeries>(Direction.down, index, viewDefinition.series ?? []) })
          )}
          readOnly={readOnly}
          parameterDefinitions={viewParameterDefinitions}
          withExportOptions={viewDefinition.export}
        />
      ),
    },
  });

  getExportEditionOptions(widgetName, updateViewDefinition, viewDefinition, readOnly).forEach((exportEditionOption) => editionOptions.push(exportEditionOption));

  editionOptions.push(
    {
      key: 'xAxisGroupBy',
      title: i18n`x Axis group by`,
      type: EditionOptionTypes.select,
      props: {
        clearable: true,
        placeholder: 'group by',
        computeOptions: () => getGroupByOptions(viewDefinition, viewDimensions),
        selectedOption: getSelectedGroupBy(viewDefinition, viewDimensions),
        onChange: (groupByOption) => updateViewDefinition(
          (oldViewDefinition) => joinObjects(oldViewDefinition, { xAxis: typeof groupByOption?.id === 'string' ? groupByOption.id : undefined })
        ),
      },
    }
  );

  const dimensionDisplayOptionsColumns = getDimensionDisplayOptionsColumns(store, viewDimensions, updateViewDefinition, true);
  if (viewDefinition.export) {
    dimensionDisplayOptionsColumns.push({
      propertyId: 'exportConfiguration',
      name: i18n`Export configuration`,
      cellRender: (displayOption: DimensionDisplayOption, _: boolean, index: number) => {
        const conceptDefinitionId = getPathReturnedConceptDefinitionId(store, viewDimensions[index].path);
        return conceptDefinitionId ? (
          <DimensionExportConfiguration
            configuration={displayOption.exportConfiguration}
            conceptDefinitionId={conceptDefinitionId}
            onChange={(newConfiguration) => updateViewDefinition((oldViewDefinition) => {
              const dimensionsDisplay = [...getViewDefinitionDimensionsDisplayOptions(oldViewDefinition, viewDimensions)];
              dimensionsDisplay[index].exportConfiguration = newConfiguration;
              return joinObjects(
                oldViewDefinition,
                { dimensionsDisplay }
              );
            })}
          />
        ) : null;
      },
    });
  }
  const dimensionDisplay = (
    <VerticalBlock asBlockContent>
      <BlockTitle
        title={i18n`Display options`}
        variant={BlockTitleVariant.inline}
      />
      <BlockContent padded>
        <DataTable<DimensionDisplayOption>
          columnsDefinition={dimensionDisplayOptionsColumns}
          list={viewDefinition.getDimensionsDisplay(viewDimensions).map((item) => ({ key: item.id, type: 'item', item, color: undefined }))}
          fullWidth
        />
      </BlockContent>
    </VerticalBlock>
  );

  return (
    <>
      {dimensionDisplay}
      {editionOptions.map((option) => (<ViewOptionBlock key={option.key} option={option} />))}
    </>
  );
};

export default StructuralBarChartViewDefinitionOptions;
