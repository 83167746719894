import classnames from 'classnames';
import type { ComponentProps, FunctionComponent, MouseEventHandler, ReactElement, ReactNode } from 'react';
import { Fragment, useRef, useState } from 'react';
import { ColorService } from 'react-color-palette';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { CardColorMode } from 'yooi-modules/modules/conceptModule';
import { filterNullOrUndefined } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import ColorPicker, { ColorPickerVariant } from '../../../../components/inputs/ColorPicker';
import Card from '../../../../components/molecules/Card';
import type { MenuItem } from '../../../../components/molecules/Menu';
import useStore from '../../../../store/useStore';
import base from '../../../../theme/base';
import { getSpacing, Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeSelectorsClasses from '../../../../utils/makeSelectorsClasses';
import makeStyles from '../../../../utils/makeStyles';
import useBackdropClick from '../../../../utils/useBackdropClick';
import useDerivedState from '../../../../utils/useDerivedState';
import type { NavigationPayload } from '../../../../utils/useNavigation';
import { SizeContextProvider, SizeVariant } from '../../../../utils/useSizeContext';
import useTheme from '../../../../utils/useTheme';
import { resolveInstanceColor } from '../../conceptDisplayUtils';
import { booleanFieldDefinition } from '../../fields/booleanField/booleanFieldDefinition';
import { colorFieldDefinition } from '../../fields/colorField/colorFieldDefinition';
import { iconFieldDefinition } from '../../fields/iconField/iconFieldDefinition';
import type { CardsViewResolution } from './cardsViewResolution';
import type { InstanceCardBody, InstanceCardHeader } from './cardsViewUtils';

const contentRowGap = getSpacing(Spacing.xs);
const defaultLineHeight = '3.2rem';
const contentVerticalPadding = getSpacing(Spacing.s);
const fullVerticalPadding = getSpacing(Spacing.xs);

const selectorsClasses = makeSelectorsClasses('visibilityHandler');

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '4rem',
    '&:hover, &:focus, &:focus-within': {
      [`& .${selectorsClasses.visibilityHandler}`]: {
        visibility: 'visible',
      },
    },
  },
  cardContainerWithColorBar: {
    minHeight: '4.8rem',
  },
  headerCardLine: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: getSpacing(Spacing.s),
    paddingRight: getSpacing(Spacing.s),
  },
  cardLine: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: getSpacing(Spacing.s),
  },
  fullWidthContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  fullWidthContainerPaddingTop: {
    paddingTop: fullVerticalPadding,
  },
  fullWidthContainerPaddingBottom: {
    paddingBottom: fullVerticalPadding,
  },
  initialCardLine: {
    display: 'grid',
    alignItems: 'center',
    paddingRight: getSpacing(Spacing.s),
    columnGap: getSpacing(Spacing.s),
  },
  initialCardLineColor: {
    gridTemplateColumns: 'auto 1fr',
    paddingLeft: getSpacing(Spacing.m),
  },
  initialCardLineField: {
    gridTemplateColumns: 'auto 1fr',
    paddingLeft: getSpacing(Spacing.splus),
  },
  initialCardLineFieldColor: {
    gridTemplateColumns: 'auto auto 1fr',
    paddingLeft: getSpacing(Spacing.splus),
  },
  initialCardLineIconColorBoolean: {
    gridTemplateColumns: 'auto auto auto 1fr',
    paddingLeft: getSpacing(Spacing.splus),
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: contentRowGap,
  },
  clickCardHeader: {
    cursor: 'pointer',
  },
  nonClickableZone: {
    cursor: 'auto',
    display: 'contents',
  },
  divider: {
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
  },
  cardHeaderPaddingTop: {
    paddingTop: contentVerticalPadding,
  },
  cardHeaderPaddingBottom: {
    paddingBottom: contentVerticalPadding,
  },
  cardBody: {
    display: 'grid',
    gridTemplateColumns: '1.6rem minmax(0, 1fr)', // Having minmax(0, 1fr) prevent blowout of the content
    alignItems: 'start',
    columnGap: getSpacing(Spacing.s),
    rowGap: contentRowGap,
    paddingTop: contentVerticalPadding,
    paddingBottom: contentVerticalPadding,
    paddingLeft: getSpacing(Spacing.m),
    paddingRight: getSpacing(Spacing.m),
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: defaultLineHeight,
  },
  bodyLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: defaultLineHeight,
    paddingLeft: getSpacing(Spacing.s),
  },
  bodyContent: {
    display: 'flex',
    flexDirection: 'column',
  },
}), 'instanceCard');

interface InstanceCardProps {
  header: InstanceCardHeader,
  body: InstanceCardBody,
  color: CardsViewResolution['color'],
  icon: CardsViewResolution['icon'],
  boolean: CardsViewResolution['boolean'],
  borderColor?: string,
  getNavigationPayload?: () => (NavigationPayload | undefined),
  actions?: ComponentProps<typeof Card>['actions'],
  menuActions?: MenuItem[],
  readOnly: boolean,
  parametersMapping: ParametersMapping,
  collapsable?: boolean,
  initialIsCollapsed?: boolean,
  onCollapse?: () => void,
  onOpen?: () => void,
  onBodyClick?: () => void,
  noShadow?: boolean,
  withEditionMode?: boolean,
  editMode?: boolean,
  onEditionStart?: () => void,
  onEditionEnd?: () => void,
  inEdition?: boolean,
}

const InstanceCard: FunctionComponent<InstanceCardProps> = ({
  header,
  body,
  getNavigationPayload,
  actions,
  menuActions,
  readOnly,
  icon,
  boolean,
  color,
  borderColor,
  parametersMapping,
  collapsable,
  initialIsCollapsed,
  onCollapse,
  onOpen,
  onBodyClick,
  noShadow = false,
  withEditionMode = false,
  editMode,
  onEditionStart,
  onEditionEnd,
  inEdition: inEditionProp,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const isCollapsable = collapsable && header.length > 0 && body.length > 0;

  const [isCollapsed, setIsCollapsed] = useState(initialIsCollapsed && isCollapsable);
  const [inEdition, setInEdition] = useDerivedState(() => (inEditionProp === undefined ? false : inEditionProp), [inEditionProp]);

  const isEditing = editMode === undefined ? inEdition : editMode;

  const ref = useRef<HTMLDivElement>(null);
  useBackdropClick(ref, () => {
    setInEdition(false);
    onEditionEnd?.();
  }, false);

  const store = useStore();

  const firstNonFullWidthHeaderIndex = header.findIndex(({ fullWidth }) => !fullWidth);
  const includeHeaderPaddingTop = !header.at(0)?.fullWidth || (header.length === 0 && color?.as === CardColorMode.Dot);
  const includeHeaderPaddingBottom = !header.at(-1)?.fullWidth || (header.length === 0 && color?.as === CardColorMode.Dot);

  let colorComponent: ReactElement | null = null;

  if (color?.type === 'field') {
    const fieldDefinition = colorFieldDefinition(store).getHandler(color.fieldId);

    const { resolve } = color;
    const fieldDisplayOptions = { variant: color.as === CardColorMode.Bar ? ColorPickerVariant.bar : ColorPickerVariant.dot };

    const resolvedEntry = resolve(parametersMapping);
    colorComponent = fieldDefinition.renderField?.({
      dimensionsMapping: resolvedEntry ?? {},
      readOnly,
      fieldDisplayOptions,
    }) ?? null;
  } else if (color?.type === 'instance') {
    const { resolve } = color;
    const variant = color.as === CardColorMode.Bar ? ColorPickerVariant.bar : ColorPickerVariant.dot;

    const resolveId = resolve(parametersMapping);
    const resolvedColor = resolveId === undefined ? undefined : resolveInstanceColor(store, resolveId);
    colorComponent = (
      <ColorPicker
        value={resolvedColor ? ColorService.convert('hex', resolvedColor) : undefined}
        defaultValue={ColorService.convert('hex', base.color.gray['300'])}
        variant={variant}
        onChange={() => {}}
        readOnly
      />
    );
  }

  let iconComponent: ReactElement | null = null;
  if (icon !== undefined) {
    const fieldDefinition = iconFieldDefinition(store).getHandler(icon.fieldId);

    const resolvedEntry = icon.resolve(parametersMapping);
    iconComponent = fieldDefinition.renderField?.({ dimensionsMapping: resolvedEntry ?? {}, readOnly }) ?? null;
  }

  let booleanComponent: ReactElement | null = null;
  if (boolean !== undefined) {
    const fieldDefinition = booleanFieldDefinition(store).getHandler(boolean.fieldId);

    const resolvedEntry = boolean.resolve(parametersMapping);
    booleanComponent = fieldDefinition.renderField?.({ dimensionsMapping: resolvedEntry ?? {}, readOnly }) ?? null;
  }

  const onClickHandlers = onBodyClick || withEditionMode ? ({
    'aria-hidden': true,
    role: 'button',
    onClick: ((event) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!event.captureNonClick) {
        onBodyClick?.();
      }
      if (withEditionMode) {
        setInEdition(true);
        onEditionStart?.();
      }
    }) satisfies MouseEventHandler<HTMLDivElement>,
  }) : {};

  const nonClickableZone = (node: ReactNode) => (
    <span
      aria-hidden
      role="button"
      className={classes.nonClickableZone}
      onClick={(e) => {
        Object.assign(e, { captureNonClick: true });
      }}
    >
      {node}
    </span>
  );

  let prefixCount = 0;
  if (color?.as === CardColorMode.Dot) {
    prefixCount += 1;
  }
  if (iconComponent !== null) {
    prefixCount += 1;
  }
  if (booleanComponent !== null) {
    prefixCount += 1;
  }

  const headerContent = (
    <div
      className={classnames({
        [classes.cardHeader]: true,
        [classes.cardHeaderPaddingTop]: includeHeaderPaddingTop,
        [classes.cardHeaderPaddingBottom]: includeHeaderPaddingBottom,
        [classes.divider]: !isCollapsed && body.length > 0,
      })}
    >
      {
        header
          .map(({ key, fullWidth, render }, index) => {
            if (fullWidth) {
              return (
                <div
                  key={key}
                  className={classnames({
                    [classes.fullWidthContainer]: true,
                    [classes.fullWidthContainerPaddingTop]: index !== 0,
                    [classes.fullWidthContainerPaddingBottom]: index !== (header.length - 1),
                  })}
                >
                  {nonClickableZone(render(parametersMapping))}
                </div>
              );
            } else if ((color?.as === CardColorMode.Dot || iconComponent !== null || booleanComponent !== null) && index === firstNonFullWidthHeaderIndex) {
              return (
                <div
                  key={key}
                  className={classnames({
                    [classes.initialCardLine]: true,
                    [classes.initialCardLineColor]: prefixCount === 1 && color?.as === CardColorMode.Dot,
                    [classes.initialCardLineField]: prefixCount === 1 && (iconComponent !== null || booleanComponent !== null),
                    [classes.initialCardLineFieldColor]: prefixCount === 2,
                    [classes.initialCardLineIconColorBoolean]: prefixCount === 3,
                  })}
                >
                  <SizeContextProvider sizeVariant={SizeVariant.small}>
                    {nonClickableZone(iconComponent)}
                  </SizeContextProvider>
                  <SizeContextProvider sizeVariant={SizeVariant.small}>
                    {nonClickableZone(booleanComponent)}
                  </SizeContextProvider>
                  {color?.as === CardColorMode.Dot ? nonClickableZone(colorComponent) : null}
                  {nonClickableZone(render(parametersMapping))}
                </div>
              );
            } else {
              return (
                <div key={key} className={classes.headerCardLine}>
                  {nonClickableZone(render(parametersMapping))}
                </div>
              );
            }
          })
      }
      {
        header.every(({ fullWidth }) => fullWidth) && prefixCount > 0 ? (
          <div
            className={classnames({
              [classes.initialCardLine]: true,
              [classes.initialCardLineColor]: prefixCount === 1 && color?.as === CardColorMode.Dot,
              [classes.initialCardLineField]: prefixCount === 1 && (iconComponent !== null || booleanComponent !== null),
              [classes.initialCardLineFieldColor]: prefixCount === 2,
              [classes.initialCardLineIconColorBoolean]: prefixCount === 3,
            })}
          >
            <SizeContextProvider sizeVariant={SizeVariant.small}>
              {nonClickableZone(iconComponent)}
            </SizeContextProvider>
            <SizeContextProvider sizeVariant={SizeVariant.small}>
              {nonClickableZone(booleanComponent)}
            </SizeContextProvider>
            {color?.as === CardColorMode.Dot ? nonClickableZone(colorComponent) : null}
          </div>
        ) : null
      }
    </div>
  );

  let headerNode: ReactNode | null = null;
  if (header.length > 0 || color?.as === CardColorMode.Dot || iconComponent !== null || booleanComponent !== null) {
    headerNode = headerContent;
  }

  return (
    <Card
      ref={ref}
      buttonExtraOffsetTop={
        [
          (header.length > 0 || body.length > 0) ? Spacing.xs : undefined,
          (color?.as === CardColorMode.Dot && header.length === 0 && body.length === 0) ? Spacing.xs : undefined,
          (color?.as === CardColorMode.Bar) ? Spacing.s : undefined,
        ].filter(filterNullOrUndefined)
      }
      getNavigationPayload={getNavigationPayload}
      actions={[
        ...actions ?? [],
        ...isCollapsable ? [{
          type: 'button',
          key: 'collapse',
          iconName: isCollapsed ? IconName.keyboard_arrow_left : IconName.expand_more,
          onClick: () => {
            if (isCollapsed) {
              onOpen?.();
            } else {
              onCollapse?.();
            }
            setIsCollapsed((previous) => !previous);
          },
          tooltip: isCollapsed ? i18n`Expand` : i18n`Collapse`,
        }] satisfies ComponentProps<typeof Card>['actions'] : [],
      ]}
      menuActions={menuActions}
      noShadow={noShadow}
      borderColor={isEditing ? theme.color.border.primary : borderColor}
    >
      <div
        className={classnames({
          [classes.cardContainer]: true,
          [classes.cardContainerWithColorBar]: color?.as === CardColorMode.Bar,
          [classes.clickCardHeader]: !!onBodyClick,
        })}
        {...onClickHandlers}
      >
        {color?.as === CardColorMode.Bar ? nonClickableZone(colorComponent) : null}
        {headerNode}
        {
          body.length > 0 && !isCollapsed ? (
            <div className={classes.cardBody}>
              {body.map(({ key, label, icon: lineIcon, render }) => (
                <Fragment key={key}>
                  <div className={classes.iconContainer}><Icon name={lineIcon} colorVariant={IconColorVariant.secondary} /></div>
                  <div className={classes.bodyContent}>
                    <span className={classes.bodyLabel}>
                      <Tooltip title={label}>
                        <Typo maxLine={1} color={theme.color.text.secondary}>{label}</Typo>
                      </Tooltip>
                    </span>
                    <div className={classes.cardLine}>
                      {nonClickableZone(render(parametersMapping))}
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>
          ) : null
        }
      </div>
    </Card>
  );
};

export default InstanceCard;
