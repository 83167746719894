import classnames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import { useRef } from 'react';
import { getSpacing, getSpacingAsNumber, Spacing, spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import { SCROLLBAR_WIDTH_IN_REM } from '../../utils/sizeUtils';
import { useWidgetContext } from '../atoms/WidgetContextProvider';

// those constants are linked to container / containerAsContent classes
export const SCROLLABLE_WIDGET_CONTAINER_FULL_HEIGHT_PADDING = getSpacingAsNumber(Spacing.splus) + getSpacingAsNumber(Spacing.xs) + SCROLLBAR_WIDTH_IN_REM;
export const SCROLLABLE_WIDGET_CONTAINER_FULL_WIDTH_PADDING = getSpacingAsNumber(Spacing.splus) + getSpacingAsNumber(Spacing.xs) + SCROLLBAR_WIDTH_IN_REM;
export const SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_HEIGHT_PADDING = SCROLLBAR_WIDTH_IN_REM;
export const SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_WIDTH_PADDING = getSpacingAsNumber(Spacing.splus) + getSpacingAsNumber(Spacing.xs) + SCROLLBAR_WIDTH_IN_REM;

const useStyles = makeStyles({
  overlayContainer: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  padEnd: {
    paddingBottom: '2rem',
  },
  rowGap: {
    rowGap: spacingRem.s,
  },
  scrollWrapper: {
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
  scrollWrapperPadded: {
    overflow: 'scroll',
  },
  base: {
    overflow: 'hidden',
  },
  container: {
    paddingTop: spacingRem.splus,
    paddingBottom: getSpacing(Spacing.splus, -SCROLLBAR_WIDTH_IN_REM),
  },
  // class linked to SCROLLABLE_WIDGET_CONTAINER_FULL_HEIGHT & SCROLLABLE_WIDGET_CONTAINER_FULL_WIDTH
  containerPadded: {
    paddingLeft: spacingRem.splus,
    paddingRight: getSpacing(Spacing.splus, -SCROLLBAR_WIDTH_IN_REM),
  },
  // class linked to SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_HEIGHT & SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_WIDTH
  containerAsContentPadded: {
    paddingLeft: spacingRem.splus,
    paddingRight: getSpacing(Spacing.splus, -SCROLLBAR_WIDTH_IN_REM),
  },
}, 'scrollableWidgetContainer');

interface ScrollableWidgetContainerProps {
  // used for scroll purpose when opening a editableWithDropdown at the end of the table
  padEnd?: boolean,
  withRowGap?: boolean,
  height: number,
  width: number,
  asContent?: boolean,
  children: ReactNode,
}

const ScrollableWidgetContainer: FunctionComponent<ScrollableWidgetContainerProps> = ({
  padEnd = false,
  withRowGap = false,
  children,
  height,
  width,
  asContent = false,
}) => {
  const classes = useStyles();

  const { padded } = useWidgetContext();

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classnames({
        [classes.base]: true,
        [classes.container]: !asContent && padded,
        [classes.containerPadded]: !asContent && padded,
        [classes.containerAsContentPadded]: asContent && padded,
      })}
      style={{ height, width }}
    >
      <div ref={ref} className={classnames({ [classes.scrollWrapper]: true, [classes.scrollWrapperPadded]: padded })}>
        <div
          className={classnames({
            [classes.overlayContainer]: true,
            [classes.padEnd]: padEnd,
            [classes.rowGap]: withRowGap,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ScrollableWidgetContainer;
