import { newFunctionLibrary } from '../engine/functionLibraryBuilder';
import { dateTimeFunctions } from './dateTimeFunctions';
import { informationFunctions } from './informationFunctions';
import { logicalFunctions } from './logicalFunctions';
import { mathFunctions } from './mathFunctions';
import { statisticalFunctions } from './statisticalFunctions';
import { textFunctions } from './textFunctions';

export const allFunctions = newFunctionLibrary()
  .addLibrary(dateTimeFunctions)
  .addLibrary(informationFunctions)
  .addLibrary(logicalFunctions)
  .addLibrary(mathFunctions)
  .addLibrary(statisticalFunctions)
  .addLibrary(textFunctions)
  .build();
