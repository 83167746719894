import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { GraphChartFieldStoreObject } from 'yooi-modules/modules/dashboardModule';
import { GraphChartField_Configuration } from 'yooi-modules/modules/dashboardModule/ids';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import useWidgetSizeContext from '../../../../utils/useWidgetSizeContext';
import GraphChart from './GraphChart';

interface GraphChartWidgetProps {
  fieldId: string,
  parametersMapping: ParametersMapping,
}

const useStyles = makeStyles({
  chartContainer: {
    padding: spacingRem.m,
    overflow: 'scroll',
  },
  centered: {
    display: 'flex',
  },
}, 'graphChartWidget');

const GraphChartWidget: FunctionComponent<GraphChartWidgetProps> = ({ fieldId, parametersMapping }) => {
  const classes = useStyles();
  const store = useStore();
  const field = store.getObject<GraphChartFieldStoreObject>(fieldId);

  const { width, height } = useWidgetSizeContext();

  return (
    <div className={classnames({ [classes.chartContainer]: true, [classes.centered]: field[GraphChartField_Configuration]?.graphStyle !== 'top' })} style={{ width, height }}>
      {field[GraphChartField_Configuration] ? (
        <GraphChart config={field[GraphChartField_Configuration]} parametersMapping={parametersMapping} />
      ) : null}
    </div>
  );
};

export default GraphChartWidget;
