import type { ComponentProps, FunctionComponent } from 'react';
import ImagePicker from '../../../components/inputs/ImagePicker';
import StoreInput from './StoreInput';

interface StoreImagePickerProps extends Omit<ComponentProps<typeof ImagePicker>, 'value' | 'onChange' | 'onSubmit' | 'onCancel'> {
  initialValue: ComponentProps<typeof ImagePicker>['value'],
  onSubmit: (content: Exclude<ComponentProps<typeof ImagePicker>['value'], undefined> | null) => void,
}

const StoreImagePicker: FunctionComponent<StoreImagePickerProps> = ({ initialValue, onSubmit, ...imageInputProps }) => (
  <StoreInput initialValue={initialValue} onSubmit={onSubmit}>
    {(props) => (
      <ImagePicker
        {...imageInputProps}
        {...props}
      />
    )}
  </StoreInput>
);

export default StoreImagePicker;
