import { equals } from 'ramda';
import { useEffect, useRef } from 'react';

const useDeepCompareEffect: typeof useEffect = (callback, dependencies) => {
  const oldDependenciesRef = useRef(dependencies);
  const signalRef = useRef<number>(0);

  if (!equals(dependencies, oldDependenciesRef.current)) {
    oldDependenciesRef.current = dependencies;
    signalRef.current += 1;
  }

  // it should not be needed to put the function of the useEffect as a dependency of the useEffect itself
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, [signalRef.current]);
};

export default useDeepCompareEffect;
