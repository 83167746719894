import { v4 as uuid } from 'uuid';
import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { dimensionsMappingToParametersMapping } from 'yooi-modules/modules/conceptModule';
import { Field_Documentation, Field_IsDocumentationInline, Field_Title } from 'yooi-modules/modules/conceptModule/ids';
import type { GraphChartFieldConfiguration, GraphChartFieldRaw, GraphChartFieldStoreObject } from 'yooi-modules/modules/dashboardModule';
import { graphChartFieldHandler } from 'yooi-modules/modules/dashboardModule';
import { GraphChartField, GraphChartField_Configuration } from 'yooi-modules/modules/dashboardModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreReadOnly } from 'yooi-store';
import { joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import VerticalBlock from '../../../../components/templates/VerticalBlock';
import i18n from '../../../../utils/i18n';
import { getLoggedUserParameterDefinition } from '../../filter/filterUtils';
import { getDocumentationFieldEditionSection } from '../_global/editionHandlerUtils';
import type { WidgetDisplay } from '../_global/widgetUtils';
import { defaultWidgetDisplay, getWidgetDisplayTypeLabel, getWidgetEditionOptionSection } from '../_global/widgetUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  duplicateFieldDimensionWithNewField,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
} from '../fieldDimensionUtils';
import type { FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode } from '../FieldLibraryTypes';
import GraphPathEditorRenderer from './configuration/GraphPathEditorRenderer';
import GraphChartBlockField from './GraphChartBlockField';
import GraphChartWidget from './GraphChartWidget';

interface GraphChartFieldBlockDisplayOptions extends FieldBlockDisplayOptions {
  widgetDisplay?: WidgetDisplay,
}

const getDisplayOptions = (objectStore: ObjectStoreReadOnly, fieldDisplayBlockId: string): GraphChartFieldBlockDisplayOptions => {
  const fieldBlockDisplay = objectStore.getObjectOrNull(fieldDisplayBlockId);
  if (fieldBlockDisplay?.[FieldBlockDisplay_FieldDisplayConfiguration]) {
    return fieldBlockDisplay[FieldBlockDisplay_FieldDisplayConfiguration] as GraphChartFieldBlockDisplayOptions;
  } else {
    return { widgetDisplay: defaultWidgetDisplay };
  }
};

interface GraphFieldConfigurationState {
  fieldId: string,
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
  [GraphChartField_Configuration]: GraphChartFieldConfiguration | null | undefined,
}

type GraphChartFieldDefinition = GetFieldDefinitionHandler<typeof graphChartFieldHandler, GraphFieldConfigurationState, never, GraphChartFieldBlockDisplayOptions>;

export const graphChartFieldDefinition: GraphChartFieldDefinition = registerFieldDefinition(graphChartFieldHandler, {
  configuration: {
    typeIcon: IconName.schema,
    getTypeLabel: () => i18n`Graph chart`,
    asWidget: true,
    getEditionOptions: () => ({ mode, editionHandler, dashboardParameterDefinitions }) => {
      if (mode !== FieldEditionOptionMode.Field && mode !== FieldEditionOptionMode.FieldDeveloperMode && mode !== FieldEditionOptionMode.Widget) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      const fieldEditionDimensions: FieldEditionDimensions = editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {};
      const parameterDefinitions = [
        ...dashboardParameterDefinitions ?? [],
        ...Object.entries(fieldEditionDimensions).map(([id, { typeId }]): SingleParameterDefinition => ({ id, typeId, type: 'dimension', label: 'dimension' })),
        getLoggedUserParameterDefinition(),
      ];

      if (mode === FieldEditionOptionMode.Field || mode === FieldEditionOptionMode.FieldDeveloperMode) {
        sections.push(getDocumentationFieldEditionSection(editionHandler));
      }

      sections.push({
        key: 'display',
        type: 'section',
        title: i18n`Display`,
        options: [
          {
            key: `${GraphChartField_Configuration}-PathsAndFilters`,
            type: EditionOptionTypes.custom,
            hasValue: () => editionHandler.getValue(GraphChartField_Configuration) !== undefined,
            clearValue: () => editionHandler.updateValues({ [GraphChartField_Configuration]: null }),
            isVertical: true,
            skipBlockContent: true,
            props: {
              render: () => (
                <VerticalBlock asBlockContent>
                  <GraphPathEditorRenderer
                    key={editionHandler.getValue('fieldId')}
                    onChange={(value) => {
                      editionHandler.updateValues({
                        [GraphChartField_Configuration]: value,
                      });
                    }}
                    value={(editionHandler.getValue(GraphChartField_Configuration))}
                    parameterDefinitions={parameterDefinitions
                      .filter((parameterDefinition): parameterDefinition is SingleParameterDefinition => Boolean(parameterDefinition.typeId))}
                  />
                </VerticalBlock>
              ),
            },
          },
        ],
      });

      return sections;
    },
    isCreationEnabled: () => () => true,
    onWidgetCreate: (objectStore) => () => objectStore.createObject<GraphChartFieldRaw>({
      [Instance_Of]: GraphChartField,
      [GraphChartField_Configuration]: {
        columns: [{ id: uuid(), blocks: [{ id: uuid(), index: 0 }] }],
      },
    }),
    onCreate: (objectStore) => (editionHandler) => {
      const fieldId = objectStore.createObject<GraphChartFieldRaw>({
        [Instance_Of]: GraphChartField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [GraphChartField_Configuration]: editionHandler.getValue(GraphChartField_Configuration) ?? {
          columns: [{ id: uuid(), blocks: [{ id: uuid(), index: 0 }] }],
        },
      });
      linkFieldToFieldDimensions(objectStore, fieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});
      return fieldId;
    },
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<GraphChartFieldStoreObject>(fieldId);
        return {
          fieldId,
          [Field_Title]: field[Field_Title],
          [Field_Documentation]: field[Field_Documentation],
          [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
          [GraphChartField_Configuration]: field[GraphChartField_Configuration],
          [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
        };
      },
      submitFieldUpdate: (stateToSubmit) => {
        objectStore.updateObject<GraphChartFieldRaw>(fieldId, {
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [GraphChartField_Configuration]: stateToSubmit[GraphChartField_Configuration],
        });
      },
      duplicateFieldDefinition: ({ parameterMap }) => {
        const fieldInstance = objectStore.getObject(fieldId);
        const fieldDimensionMapping = generateDuplicatedFieldDimensionId(objectStore, fieldId);
        let newConfiguration = fieldInstance[GraphChartField_Configuration];
        if (newConfiguration) {
          let updatedConfiguration = JSON.stringify(newConfiguration);
          if (parameterMap) {
            Object.entries(parameterMap).forEach(([pid, npid]) => {
              updatedConfiguration = updatedConfiguration.replaceAll(pid, npid);
            });
          }
          Object.entries(fieldDimensionMapping).forEach(([pid, npid]) => {
            updatedConfiguration = updatedConfiguration.replaceAll(pid, npid);
          });
          newConfiguration = JSON.parse(updatedConfiguration);
        }
        const newFieldId = objectStore.createObject({
          [Instance_Of]: fieldInstance[Instance_Of],
          [Field_Title]: `${fieldInstance[Field_Title]} (copy)`,
          [Field_Documentation]: fieldInstance[Field_Documentation],
          [Field_IsDocumentationInline]: fieldInstance[Field_IsDocumentationInline],
          [GraphChartField_Configuration]: newConfiguration,
        });
        duplicateFieldDimensionWithNewField(objectStore, newFieldId, fieldDimensionMapping);
        return newFieldId;
      },
    }),
  },
  renderBlockField: (_, fieldId) => (dimensionsMapping, displayOptions, blockFieldProps, layoutParametersMapping) => (
    <GraphChartBlockField
      fieldId={fieldId}
      blockFieldProps={blockFieldProps}
      parametersMapping={joinObjects(dimensionsMappingToParametersMapping(dimensionsMapping), layoutParametersMapping)}
      widgetDisplay={displayOptions.widgetDisplay ?? defaultWidgetDisplay}
    />
  ),
  renderWidget: (_, fieldId) => ({ parametersMapping }) => (
    <GraphChartWidget
      fieldId={fieldId}
      parametersMapping={parametersMapping}
    />
  ),
  blockDisplayOptionsHandler: (objectStore) => (fieldDisplayBlockId) => ({
    getDisplayOptions: () => getDisplayOptions(objectStore, fieldDisplayBlockId),
    renderSummary: (state) => [getWidgetDisplayTypeLabel((state.widgetDisplay ?? defaultWidgetDisplay).type)],
    getBlockEditionOptionSections: (state, setState) => [
      getWidgetEditionOptionSection(
        state.widgetDisplay ?? defaultWidgetDisplay,
        (newWidgetDisplay) => setState(joinObjects(state, { widgetDisplay: newWidgetDisplay }))
      ),
    ],
    onSubmit: (state) => {
      objectStore.updateObject(fieldDisplayBlockId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
});
