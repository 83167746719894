import composeReactRefs from '@seznam/compose-react-refs';
import type { FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import type { ViewDimension, ViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import { ViewType } from 'yooi-modules/modules/dashboardModule';
import { compareProperty, compareString, Direction, joinObjects, moveElementInArray, removeElementInArray } from 'yooi-utils';
import { ButtonVariant } from '../../../../components/atoms/Button';
import Icon, { IconColorVariant, IconName, IconSizeVariant } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import Chip from '../../../../components/molecules/Chip';
import type { MenuItem } from '../../../../components/molecules/Menu';
import Menu from '../../../../components/molecules/Menu';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import DataTable from '../../../../components/templates/DataTable';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import useInViewCallback from '../../../../utils/useInViewCallback';
import useNavigation from '../../../../utils/useNavigation';
import StoreTextInputField from '../../input/StoreTextInputField';
import type { NavigationFilter } from '../../navigationUtils';
import { getViewTypeHandler } from '../../views/viewDsl';
import type { ViewResolvedDefinition } from '../../views/viewResolvedDefinition';

interface ViewsGroupDisplayTableProps {
  viewDefinitions: ViewResolvedDefinition[],
  viewDimensions: ViewDimension[],
  parameterDefinitions: SingleParameterDefinition[],
  updateViewDefinitions: (updateFunction: (oldViewDefinition: ViewStoredDefinition[]) => ViewStoredDefinition[]) => void,
  getNavigationData: () => { viewUrl: string, navigationKey: string } | undefined,
  onOpen?: () => void,
  readOnly?: boolean,
}

const useStyles = makeStyles({
  chipContainer: {
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}, 'viewsGroupDisplayTable');

const ViewsGroupDisplayTable: FunctionComponent<ViewsGroupDisplayTableProps> = ({
  viewDefinitions,
  viewDimensions,
  parameterDefinitions,
  updateViewDefinitions,
  getNavigationData,
  onOpen,
  readOnly,
}) => {
  const classes = useStyles();

  const store = useStore();

  const navigation = useNavigation<NavigationFilter>();
  const location = useLocation();

  const [showMenu, setShowMenu] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const observeRef = useInViewCallback((inView) => {
    if (!inView) {
      setShowMenu(false);
    }
  }, showMenu);

  const viewTypes = (Object.values(ViewType))
    .map((viewType): MenuItem => {
      const viewTypeHandler = getViewTypeHandler(viewType);
      return {
        key: `${viewType}_create`,
        name: viewTypeHandler.label,
        icon: viewTypeHandler.icon,
        iconSize: IconSizeVariant.l,
        onClick: () => {
          updateViewDefinitions((oldViewDefinitions) => [
            ...oldViewDefinitions,
            viewTypeHandler.getStoredDefinition(uuid()),
          ]);
        },
      };
    })
    .sort(compareProperty('name', compareString));

  const navigationData = getNavigationData?.();

  return (
    <>
      <DataTable<ViewResolvedDefinition>
        columnsDefinition={[
          {
            name: i18n`Label`,
            propertyId: 'Label',
            cellRender: ({ label }, _, index) => (
              <StoreTextInputField
                initialValue={label}
                onSubmit={(value) => updateViewDefinitions((oldViewDefinitions) => oldViewDefinitions.map((view, i) => {
                  if (index === i) {
                    const newView = { ...view };
                    newView.label = value ?? undefined;
                    return newView;
                  } else {
                    return view;
                  }
                }))}
                readOnly={readOnly}
              />
            ),
            openButton: () => true,
          },
          {
            name: i18n`Type`,
            propertyId: 'Type',
            cellRender: (viewDefinition) => {
              const viewTypeHandler = getViewTypeHandler(viewDefinition.type);
              const errors = viewTypeHandler.getDefinitionErrors?.(store, viewDefinition, viewDimensions, parameterDefinitions);
              return (
                <div className={classes.chipContainer}>
                  <Chip text={viewTypeHandler.label} icon={viewTypeHandler.icon} />
                  {errors !== undefined && (
                    <Icon name={IconName.dangerous} colorVariant={IconColorVariant.error} tooltip={errors.join(', ')} />
                  )}
                </div>
              );
            },
          },
          {
            propertyId: 'MoveUp',
            action: true,
            cellRender: (_, __, index) => (
              <SpacingLine>
                <IconOnlyButton
                  disabled={readOnly || index === 0}
                  onClick={() => updateViewDefinitions((oldViewDefinitions) => moveElementInArray(Direction.up, index, oldViewDefinitions))}
                  iconName={IconName.expand_less}
                  tooltip={i18n`Move Up`}
                  variant={IconOnlyButtonVariants.tertiary}
                />
              </SpacingLine>
            ),
          }, {
            propertyId: 'MoveDown',
            action: true,
            cellRender: (_, __, index) => (
              <SpacingLine>
                <IconOnlyButton
                  disabled={readOnly || index === viewDefinitions.length - 1}
                  onClick={() => updateViewDefinitions((oldViewDefinitions) => moveElementInArray(Direction.down, index, oldViewDefinitions))}
                  iconName={IconName.expand_more}
                  tooltip={i18n`Move Down`}
                  variant={IconOnlyButtonVariants.tertiary}
                />
              </SpacingLine>
            ),
          },
          {
            propertyId: 'Delete',
            action: true,
            cellRender: (_, __, index) => (
              <SpacingLine>
                <IconOnlyButton
                  disabled={readOnly}
                  onClick={() => updateViewDefinitions((oldViewDefinitions) => removeElementInArray(oldViewDefinitions, index))}
                  iconName={IconName.delete}
                  tooltip={i18n`Delete`}
                  variant={IconOnlyButtonVariants.danger}
                />
              </SpacingLine>
            ),
          },
        ]}
        list={viewDefinitions.map((item) => ({ key: item.id, type: 'item', item, color: undefined }))}
        newItemButtonVariant={ButtonVariant.tertiary}
        fullWidth
        newItemRef={composeReactRefs<HTMLButtonElement>(buttonRef, observeRef)}
        onNewItem={() => setShowMenu(true)}
        newItemIcon={IconName.add}
        newItemTitle={i18n`Create`}
        getNavigationPayload={navigationData ? (item) => navigation.createNavigationPayload(
          navigationData.navigationKey,
          joinObjects(location, { pathname: navigationData.viewUrl, hash: item.id })
        ) : undefined}
        onNavigate={onOpen ? () => onOpen() : undefined}
      />
      {showMenu ? (
        <Menu
          items={viewTypes}
          anchorRef={buttonRef}
          onClose={() => setShowMenu(false)}
        />
      ) : null}
    </>
  );
};

export default ViewsGroupDisplayTable;
