import type { FunctionComponent } from 'react';
import type { StringValue } from './objectRenderType';
import TagRenderer from './TagRenderer';

interface StringRendererProps {
  string: StringValue,
}

const StringRenderer: FunctionComponent<StringRendererProps> = ({ string: { value, hint, href, variant, maxLine } }) => (
  <TagRenderer value={value} hint={hint} href={href} variant={variant} type={hint ? undefined : 'string'} maxLine={maxLine} />
);

export default StringRenderer;
