import type { DimensionsMapping, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import {
  associationFieldHandler,
  createValuePathResolver,
  dimensionsMappingToParametersMapping,
  getFieldDimensionOfModelType,
  getInstanceLabelOrUndefined,
  getPathLastFieldInformation,
  getPathReturnedConceptDefinitionId,
  getRelevantFields,
  InstanceReferenceType,
  isMappingStep,
  isMultipleRelationalType,
  isSingleFieldResolution,
  isSingleValueResolution,
  kinshipRelationFieldHandler,
  PathStepType,
  relationSingleFieldHandler,
  workflowFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import { AssociationField, Concept, Concept_Name, KinshipRelationField, RelationSingleField, WorkflowField } from 'yooi-modules/modules/conceptModule/ids';
import type { ViewDimension, ViewSeries } from 'yooi-modules/modules/dashboardModule';
import { DimensionDisplayAxis, ViewType } from 'yooi-modules/modules/dashboardModule';
import { UnsetDashboardParameterOption } from 'yooi-modules/modules/dashboardModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { StoreObject } from 'yooi-store';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import type { ACLHandler } from '../../../../store/useAcl';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { formatOrUndef } from '../../../../utils/stringUtils';
import { resolveConceptChipIcon, resolveConceptColorValue } from '../../conceptDisplayUtils';
import { formatErrorForUser } from '../../errorUtils';
import { getFieldHandler } from '../../fields/FieldLibrary';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { buildAssociationGroupByColor, buildAssociationGroupByKey, buildAssociationGroupByLabel, resolveGroupByFieldPath } from '../../groupByUtils';
import { getViewFilterFunction } from '../../listFilterFunctions';
import { getDimensionCompositionFromSeries, getSeriesLabel } from '../common/series/viewWithSeriesFeatureUtils';
import type { CreateAndLinkOptions } from '../common/viewUtils';
import { buildFilterFunctionFromDimensions, getCreateAndLinkOptions, removeFiltersFromDimensions } from '../common/viewUtils';
import { computeDimension, DataResolutionError, getDimensionCompositions, getDimensionLabel } from '../data/dataResolution';
import type { ViewResolutionError } from '../viewResolutionUtils';
import type { TableViewResolvedDefinition } from './tableViewHandler';

enum TableSortDirection {
  asc = 'asc',
  desc = 'desc',
}

export interface TableGroupByField {
  id: string,
  fieldId: string,
  label: string,
  getDimensionsMapping: (line: TableViewResolutionLine) => DimensionsMapping,
  getGroupKey: (line: TableViewResolutionLine) => (string | undefined),
  getGroupLabel: (key: string) => (string | undefined),
  getGroupColor: (key: string) => (string | undefined),
}

enum LineResolutionType {
  field = 'field',
  instance = 'instance',
}

interface InstanceLineResolution {
  type: LineResolutionType.instance,
  instanceId: string | undefined,
}

export const isInstanceLineResolution = (resolution: FieldLineResolution | InstanceLineResolution | undefined): resolution is InstanceLineResolution => (
  resolution?.type === LineResolutionType.instance
);

interface FieldLineResolution {
  type: LineResolutionType.field,
  fieldId: string,
  dimensionsMapping: DimensionsMapping | undefined,
}

export const isFieldLineResolution = (resolution: FieldLineResolution | InstanceLineResolution | undefined): resolution is FieldLineResolution => (
  resolution?.type === LineResolutionType.field
);

export interface TableColumn {
  key: string,
  label: string,
  width: { type: 'percent' | 'rem', value: number } | undefined,
  isInstance: boolean,
  viewDimension?: string,
  conceptDefinitionId: string | undefined,
  instances: (string | undefined)[],
  seriesId: string | undefined,
  fieldId: string | undefined,
  isNavigable: boolean,
  focusable: boolean,
  getLineResolution: (line: TableViewResolutionLine) => FieldLineResolution | InstanceLineResolution | undefined,
}

export interface TableViewResolutionLine {
  id: string,
  key: string,
  dimensionsMapping: DimensionsMapping,
  seriesId?: string,
}

export interface TableViewResolution extends CreateAndLinkOptions {
  type: ViewType.Table,
  lines: TableViewResolutionLine[],
  columns: TableColumn[],
  groupByFields: TableGroupByField[],
  isMultiDim: boolean,
  defaultSort: { key: string, direction: TableSortDirection } | undefined,
  filterFunction: ((parametersMapping: ParametersMapping) => boolean),
  openButtonColumn: boolean,
}

const getLabel = (objectStore: FrontObjectStore, series: ViewSeries[], id: string | undefined, dimensions: ViewDimension[], parametersIds: string[]): string => {
  const seriesIndex = series.findIndex(({ id: seriesId }) => seriesId === id);
  if (id === undefined) {
    return i18n`All`;
  } else if (seriesIndex > -1) {
    return getSeriesLabel(objectStore, series[seriesIndex].label, seriesIndex, series[seriesIndex].path, dimensions, parametersIds);
  } else if (id === UnsetDashboardParameterOption) {
    return i18n`Not set`;
  } else if (isInstanceOf(objectStore.getObject(id), Concept)) {
    return getInstanceLabelOrUndefined(objectStore, objectStore.getObject(id));
  } else {
    return formatOrUndef(undefined);
  }
};

export const getTableViewGroupByFields = (store: FrontObjectStore, viewDefinition: TableViewResolvedDefinition, viewDimensions: ViewDimension[]): TableGroupByField[] => {
  const groupByFields: TableGroupByField[] = [];
  for (let i = 0; i < (viewDefinition.groupBy?.fields?.length ?? 0); i += 1) {
    const groupByField = viewDefinition.groupBy?.fields?.[i];
    if (groupByField) {
      const resolvedGroupByFieldPath = resolveGroupByFieldPath(store, groupByField?.path ?? []);
      if (resolvedGroupByFieldPath) {
        groupByFields.push({
          id: groupByField.id,
          fieldId: resolvedGroupByFieldPath.fieldId,
          label: groupByField.label ?? formatOrUndef(resolvedGroupByFieldPath.getLabel()),
          getDimensionsMapping: resolvedGroupByFieldPath.getDimensionsMapping,
          getGroupKey: resolvedGroupByFieldPath.getGroupKey,
          getGroupLabel: resolvedGroupByFieldPath.getGroupLabel,
          getGroupColor: resolvedGroupByFieldPath.getGroupColor,
        });
      }
    }
  }
  for (let i = 0; i < (viewDefinition.groupBy?.dimensionIds?.length ?? 0); i += 1) {
    const dimensionId = viewDefinition.groupBy?.dimensionIds?.[i] as string;
    const dimension = viewDimensions.find(({ id }) => dimensionId === id);
    const dimensionDisplay = dimension ? viewDefinition.getDimensionDisplay(dimension) : undefined;
    const conceptDefinition = getPathReturnedConceptDefinitionId(store, dimension?.path ?? []);
    if (dimension && dimensionDisplay && dimensionDisplay.axis !== DimensionDisplayAxis.y && conceptDefinition !== undefined) {
      const relevantFields = getRelevantFields(store, conceptDefinition, [RelationSingleField, WorkflowField, KinshipRelationField, AssociationField]);
      groupByFields.push(
        ...relevantFields
          .map((field): TableGroupByField => {
            const isMulti = isMultipleRelationalType(field[Instance_Of]);
            return {
              id: `${dimension.id}|${field.id}`,
              label: getInstanceLabelOrUndefined(store, field),
              fieldId: field.id,
              getDimensionsMapping: (line: TableViewResolutionLine) => {
                const fieldDimension = getFieldDimensionOfModelType(store, field.id, conceptDefinition);
                return fieldDimension ? { [fieldDimension]: line.dimensionsMapping[dimension.id] } : {};
              },
              getGroupKey: (line) => {
                const fieldDimension = getFieldDimensionOfModelType(store, field.id, conceptDefinition);
                const fieldDimensionMapping = fieldDimension ? {
                  [fieldDimension]: line.dimensionsMapping[dimension.id],
                } : {};
                if (field[Instance_Of] === RelationSingleField) {
                  return relationSingleFieldHandler(store, field.id).getValueResolution(fieldDimensionMapping).value?.id;
                } else if (field[Instance_Of] === WorkflowField) {
                  return workflowFieldHandler(store, field.id).getValueResolution(fieldDimensionMapping).value.value?.id;
                } else if (field[Instance_Of] === KinshipRelationField) {
                  return kinshipRelationFieldHandler(store, field.id).getValueResolution(fieldDimensionMapping).value?.id;
                } else if (field[Instance_Of] === AssociationField) {
                  const values = associationFieldHandler(store, field.id).getValueResolution(fieldDimensionMapping).value;
                  return buildAssociationGroupByKey(field, values);
                }
                return undefined;
              },
              getGroupLabel: (key) => {
                if (isMulti) {
                  return buildAssociationGroupByLabel(store, key);
                } else {
                  return getInstanceLabelOrUndefined(store, store.getObject(key));
                }
              },
              getGroupColor: (key) => {
                if (isMulti) {
                  return buildAssociationGroupByColor(store, key);
                } else {
                  return resolveConceptColorValue(store, key) ?? resolveConceptChipIcon(store, key)?.color;
                }
              },
            };
          })
      );
    }
  }
  return groupByFields;
};

const getLineOrColumnLabel = (
  objectStore: FrontObjectStore,
  dimensionsMapping: DimensionsMapping,
  series: ViewSeries[],
  dimensions: ViewDimension[],
  parametersMapping: ParametersMapping,
  withSeries: boolean
) => (
  Object.entries(dimensionsMapping)
    .filter(([key]) => (withSeries ? true : key !== 'serieId'))
    .map(([_, id]) => getLabel(objectStore, series, id, dimensions, Object.keys(parametersMapping)))
    .join(' x '));

export const resolveTableView = (
  store: FrontObjectStore,
  aclHandler: ACLHandler,
  viewDimensions: ViewDimension[],
  viewDefinition: TableViewResolvedDefinition,
  parametersMapping: ParametersMapping,
  filterConfiguration: FilterConfiguration | undefined,
  readOnly: boolean | undefined
): TableViewResolution | ViewResolutionError => {
  const { series: inputSeries, seriesAxis } = viewDefinition;
  const maxCompositionValue = 50;

  const isInstanceSeries = (series: ViewSeries) => series.path.length === 2 && series.path[1].type === PathStepType.mapping;

  const series = inputSeries.filter((s) => {
    const pathFieldId = getPathLastFieldInformation(s.path)?.fieldId;
    return !!pathFieldId || isInstanceSeries(s);
  });

  const isMultiDim = viewDimensions.length > 1;

  if (series.length === 0) {
    return { type: 'error', error: i18n`Missing series` };
  }

  // X are lines
  // Y are columns

  const dimensionsDisplays = viewDefinition.getDimensionsDisplay(viewDimensions);
  const linesDimensionIds = dimensionsDisplays.filter(({ axis }) => axis === DimensionDisplayAxis.x).map(({ id }) => id);
  const columnsDimensionIds = dimensionsDisplays.filter(({ axis }) => axis === DimensionDisplayAxis.y).map(({ id }) => id);

  const linesFilterFunction = buildFilterFunctionFromDimensions(store, viewDimensions.filter(({ id }) => linesDimensionIds.includes(id)), parametersMapping);
  const columnsFilterFunction = buildFilterFunctionFromDimensions(store, viewDimensions.filter(({ id }) => columnsDimensionIds.includes(id)), parametersMapping);

  const parsedViewDimensions = removeFiltersFromDimensions(viewDimensions);

  const filterFunction = getViewFilterFunction(
    store,
    viewDimensions.map((dim, index) => {
      const conceptDefinitionId = getPathReturnedConceptDefinitionId(store, dim.path);
      return conceptDefinitionId ? {
        id: dim.id,
        typeId: conceptDefinitionId,
        label: getDimensionLabel(store, dim.label, index, dim.path),
      } : undefined;
    }).filter(filterNullOrUndefined),
    filterConfiguration,
    parametersMapping
  );

  const { mapReduceHandler: computeDimensionHandler } = computeDimension(store, parametersMapping, parsedViewDimensions);
  const isSeriesColumn = seriesAxis !== DimensionDisplayAxis.x;

  const dimensionCompositions = getDimensionCompositions(computeDimensionHandler, parsedViewDimensions, dimensionsDisplays, undefined, undefined);
  if (dimensionCompositions instanceof DataResolutionError) {
    return { type: 'error', error: formatErrorForUser(store, dimensionCompositions) };
  }
  let { xDimensionCompositions, yDimensionCompositions } = dimensionCompositions;

  const resolutionFilterFunction = (mapping: ParametersMapping) => {
    const columnMappings = yDimensionCompositions.length > 0 ? yDimensionCompositions : [{}];
    // Lookup for at least on cell that match filters
    return columnMappings.some((yComposition) => {
      const filterParameters = joinObjects(mapping, dimensionsMappingToParametersMapping(yComposition));
      if (filterFunction !== undefined) {
        return linesFilterFunction(filterParameters) && filterFunction(filterParameters);
      } else {
        return linesFilterFunction(filterParameters);
      }
    });
  };

  yDimensionCompositions = yDimensionCompositions
    .filter((yComposition) => (
      (xDimensionCompositions.length > 0 ? xDimensionCompositions : [{}])
        .some((xComposition) => {
          const filterParameters = dimensionsMappingToParametersMapping(joinObjects(yComposition, xComposition));

          if (filterFunction !== undefined) {
            return columnsFilterFunction(filterParameters) && filterFunction(filterParameters);
          } else {
            return columnsFilterFunction(filterParameters);
          }
        })
    ));

  if (yDimensionCompositions.length > maxCompositionValue) {
    return { type: 'error', error: i18n`Too many y dimensions. Please filter the column dimensions` };
  }

  if (isSeriesColumn) {
    yDimensionCompositions = getDimensionCompositionFromSeries(yDimensionCompositions, series);
  } else if (!isSeriesColumn) {
    xDimensionCompositions = getDimensionCompositionFromSeries(xDimensionCompositions, series);
  }
  const lines: TableViewResolutionLine[] = [];
  for (let i = 0; i < xDimensionCompositions.length; i += 1) {
    const xDimensionMapping = xDimensionCompositions[i];
    const xParameters = Object.fromEntries(Object.entries(xDimensionMapping).filter(([dimensionId]) => dimensionId !== 'serieId'));
    lines.push({
      id: Object.values(xDimensionMapping).join('_'),
      key: Object.values(xDimensionMapping).join('_'),
      dimensionsMapping: xParameters,
      seriesId: xDimensionMapping.serieId,
    });
  }

  const linkOptions = viewDefinition.readOnly || readOnly || viewDimensions.length !== 1
    ? {}
    : getCreateAndLinkOptions(
      store,
      aclHandler,
      parametersMapping,
      parsedViewDimensions,
      lines.map(({ id }) => id),
      (id) => linesFilterFunction({ [viewDimensions[0].id]: { type: 'single', id } })
    );

  const columns: TableColumn[] = [];
  const oneSeriesForColumn = !yDimensionCompositions.some((dimension) => yDimensionCompositions[0].serieId !== dimension.serieId);
  const xDimensions = dimensionsDisplays.filter(({ axis }) => axis === DimensionDisplayAxis.x);

  xDimensions
    .filter(({ withLegend }) => withLegend)
    .forEach(({ id }) => {
      const viewDimensionIndex = viewDimensions.findIndex((vd) => vd.id === id);
      if (viewDimensionIndex !== -1) {
        const viewDimension = viewDimensions[viewDimensionIndex];
        const returnedConceptDefinitionId = getPathReturnedConceptDefinitionId(store, viewDimension.path);
        if (returnedConceptDefinitionId !== undefined) {
          columns.push({
            key: id,
            focusable: false,
            label: getDimensionLabel(store, viewDimension.label, viewDimensionIndex, viewDimension.path),
            width: undefined,
            fieldId: undefined,
            seriesId: undefined,
            conceptDefinitionId: undefined,
            isNavigable: false,
            isInstance: true,
            viewDimension: viewDimension.id,
            instances: [],
            getLineResolution: (line) => {
              const pathResolution = createValuePathResolver(store, joinObjects(parametersMapping, dimensionsMappingToParametersMapping(line.dimensionsMapping)))
                .resolvePathValue([
                  { type: PathStepType.dimension, conceptDefinitionId: returnedConceptDefinitionId },
                  { type: PathStepType.mapping, mapping: { type: InstanceReferenceType.parameter, id } },
                ]);
              if (pathResolution && isSingleValueResolution(pathResolution)) {
                return { type: LineResolutionType.instance, instanceId: (pathResolution.value as StoreObject | undefined)?.id };
              }
              return undefined;
            },
          });
        }
      }
    });

  for (let i = 0; i < yDimensionCompositions.length; i += 1) {
    const yDimensionMapping = yDimensionCompositions[i];
    const relatedSeries = series.find(({ id }) => id === yDimensionMapping.serieId);
    const pathFieldInfo = getPathLastFieldInformation(relatedSeries?.path ?? []);

    const isInstance = Boolean(relatedSeries && isInstanceSeries(relatedSeries));
    columns.push({
      key: Object.values(yDimensionMapping).join('_'),
      focusable: (
        (!!pathFieldInfo && Boolean(getFieldHandler(store, pathFieldInfo.fieldId)?.getColumnDefinition?.().focusable))
        || (isInstance && linkOptions.linkOptions !== undefined)
      ),
      label: getLineOrColumnLabel(
        store,
        yDimensionMapping,
        series,
        parsedViewDimensions,
        joinObjects(parametersMapping, dimensionsMappingToParametersMapping(yDimensionMapping)),
        !oneSeriesForColumn || Object.values(yDimensionMapping).length === 1
      ),
      width: relatedSeries?.displayOptions?.width,
      fieldId: pathFieldInfo?.fieldId,
      seriesId: relatedSeries?.id,
      conceptDefinitionId: pathFieldInfo?.conceptDefinitionId,
      isNavigable: xDimensions.length === 1
        && pathFieldInfo?.fieldId === Concept_Name
        && relatedSeries?.path.length === 3
        && isMappingStep(relatedSeries.path[1])
        && relatedSeries.path[1].mapping.type === 'parameter'
        && relatedSeries.path[1].mapping.id === xDimensions[0].id,
      isInstance,
      instances: Object.entries(yDimensionMapping).reduce((acc, [key, value]) => {
        if (key !== 'serieId') {
          return [...acc, value];
        }
        return acc;
      }, [] as (string | undefined)[]),
      getLineResolution: (line) => {
        const lineSeries = series.find(({ id }) => id === yDimensionMapping.serieId || id === line.seriesId);
        if (!lineSeries) {
          return undefined;
        }
        if (isInstanceSeries(lineSeries)) {
          const pathResolution = createValuePathResolver(
            store,
            joinObjects(parametersMapping, dimensionsMappingToParametersMapping(line.dimensionsMapping), dimensionsMappingToParametersMapping(yDimensionMapping))
          ).resolvePathValue(lineSeries.path);
          if (pathResolution && isSingleValueResolution(pathResolution)) {
            return { type: LineResolutionType.instance, instanceId: (pathResolution.value as StoreObject | undefined)?.id };
          }
          return undefined;
        }
        const fieldResolution = createValuePathResolver(
          store,
          joinObjects(parametersMapping, dimensionsMappingToParametersMapping(line.dimensionsMapping), dimensionsMappingToParametersMapping(yDimensionMapping))
        ).resolvePathField(lineSeries.path);
        if (isSingleFieldResolution(fieldResolution)) {
          return { type: LineResolutionType.field, fieldId: fieldResolution.fieldId, dimensionsMapping: fieldResolution.dimensionsMapping };
        }
        return undefined;
      },
    });
  }

  return joinObjects(
    {
      type: ViewType.Table,
      lines,
      columns,
      groupByFields: getTableViewGroupByFields(store, viewDefinition, parsedViewDimensions),
      isMultiDim,
      defaultSort: viewDefinition.defaultSort,
      openButtonColumn: viewDefinition.openButtonColumn,
    } as const,
    linkOptions,
    {
      filterFunction: resolutionFilterFunction,
    }
  );
};
