import { SessionStorageKeys, useSessionStorageState } from '../../utils/useSessionStorage';

export enum DashboardLayoutVariants {
  edit = 'edit',
  view = 'view',
}

const DEFAULT_DASHBOARD_LAYOUT_MODE = DashboardLayoutVariants.view;

export const useDashboardLayoutMode = (): DashboardLayoutVariants => {
  const [mode] = useSessionStorageState(SessionStorageKeys.dashboardLayoutMode, DEFAULT_DASHBOARD_LAYOUT_MODE);
  return mode;
};

export const useToggleDashboardLayoutMode = (): (() => void) => {
  const [mode, setMode] = useSessionStorageState(SessionStorageKeys.dashboardLayoutMode, DEFAULT_DASHBOARD_LAYOUT_MODE);
  return () => {
    switch (mode) {
      case DashboardLayoutVariants.edit:
        setMode(DashboardLayoutVariants.view);
        break;
      default:
        setMode(DashboardLayoutVariants.edit);
        break;
    }
  };
};
