import { datadogRum } from '@datadog/browser-rum';
import type { FunctionComponent, ReactElement } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
// eslint-disable-next-line yooi/no-restricted-dependency
import { initModules } from 'yooi-modules';
// eslint-disable-next-line yooi/no-restricted-dependency
import { getFirstClassInstance } from 'yooi-modules/modules/conceptModule';
// eslint-disable-next-line yooi/no-restricted-dependency
import { Concept, Concept_Name, User_Email } from 'yooi-modules/modules/conceptModule/ids';
// eslint-disable-next-line yooi/no-restricted-dependency
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import type { ObjectStore } from 'yooi-store';
import type { RichText } from 'yooi-utils';
import { doYield, forgetAsyncPromise, newError, richTextToText } from 'yooi-utils';
import { IconName } from '../components/atoms/Icon';
import Loading from '../components/molecules/Loading';
import { createBroadcastChannel } from '../utils/broadcastChannelUtils';
import buildInfo from '../utils/buildInfo';
import { reportClientError, reportClientMetric } from '../utils/clientReporterUtils';
import i18n from '../utils/i18n';
import { clearNotification, notifyError } from '../utils/notify';
import { hasFeature } from '../utils/options';
import useBrowserNetworkManager from '../utils/useBrowserNetworkManager';
import type { OnConnectionProgress } from './connectionProgress';
import { ConnectionPhase, renderConnectionProgress } from './connectionProgress';
import ConnectionProgressBar from './ConnectionProgressBar';
import createNetworkStoreContext from './networkStoreContext';
import { AuthContextProvider } from './useAuth';
import type { StoreContext } from './useStoreContext';
import { StoreContextProvider } from './useStoreContext';

declare global {
  interface Window {
    notifyStoreUpdate?: () => void,
  }
}

const HEARTBEAT_DELAY_THRESHOLD_MS = 30_000;
const HEARTBEAT_CHECK_INTERVAL_MS = 15_000;

enum InitializationStates {
  loading = 'loading',
  loadingRequested = 'loadingRequested',
  loaded = 'loaded',
  unauthenticated = 'unauthenticated',
}

interface NetworkStoreContextProviderProps {
  renderLoaded: () => ReactElement | null,
  renderUnauthenticated: (forceReconnection: () => void) => void,
  bypassReadAcl?: boolean,
}

const logBroadcastChannel = createBroadcastChannel(new BroadcastChannel(`log_activity-${buildInfo.sourceHash}`));

const NetworkStoreContextProvider: FunctionComponent<NetworkStoreContextProviderProps> = ({
  renderLoaded,
  renderUnauthenticated,
  bypassReadAcl = false,
}) => {
  const [initializationState, setInitializationState] = useState(InitializationStates.loading);
  const [userId, setUserId] = useState<string>();
  const lostConnectivityToastId = useRef<string>();
  const lastHeartbeatDate = useRef(Date.now());
  const [connectionId, setConnectionId] = useState(uuid());
  const browserNetworkManager = useBrowserNetworkManager();
  const [connectionProgress, setConnectionProgress] = useState<Parameters<typeof renderConnectionProgress>[0]>();

  const forceReconnection = useCallback((state: InitializationStates.loadingRequested | InitializationStates.loading) => {
    setConnectionId(uuid());
    setInitializationState(state);
    setUserId(undefined);
  }, []);

  useEffect(() => {
    if (!bypassReadAcl) {
      logBroadcastChannel.registerMessageHandler(() => {
        if (initializationState === InitializationStates.unauthenticated) {
          forceReconnection(InitializationStates.loadingRequested);
        }
      });
      return () => {
        logBroadcastChannel.unregisterMessageHandler();
      };
    } else {
      return () => {};
    }
  }, [bypassReadAcl, forceReconnection, initializationState, userId]);

  useEffect(() => {
    if (!bypassReadAcl && initializationState === InitializationStates.loading) {
      logBroadcastChannel.postMessage();
    }
  }, [bypassReadAcl, initializationState]);

  const [storeCtx, setStoreCtx] = useState<StoreContext>();

  useEffect(() => {
    const { modules } = initModules({
      logError: (message, data) => {
        reportClientError(newError('Error when initializing modules by the client', { logMessage: message, data }));
      },
    });

    const activityStoreFindViewedObjectId = (store: ObjectStore, objectId: string): string => {
      const viewedObject = store.getObjectOrNull(objectId);
      if (viewedObject && isInstanceOf(viewedObject, Concept)) {
        return getFirstClassInstance(viewedObject).id;
      } else {
        return objectId;
      }
    };

    const onConnectionProgress: OnConnectionProgress = (connectionPhase, percentage) => {
      setConnectionProgress({ connectionPhase, percentage });
    };

    const store = createNetworkStoreContext({
      browserNetworkManager,
      modules,
      activityStoreFindViewedObjectId,
      bypassReadAcl,
      // As onConnect is called outside the React lifecycle, update is done synchronously.
      // setImmediate is used to delay this synchronous action once the initialization is completed.
      onConnect: (uid) => setImmediate(forgetAsyncPromise(async () => {
        onConnectionProgress(ConnectionPhase.StartApp);
        await doYield(true);

        if (hasFeature('monitoring', 'rum')) {
          const userData = store.data.getObjectOrNull(uid);
          datadogRum.setUser({
            id: uid,
            email: userData?.[User_Email] as string | undefined,
            name: richTextToText(userData?.[Concept_Name] as RichText | undefined),
          });
        }
        setUserId(uid);
        setInitializationState(InitializationStates.loaded);
      })),
      onUnauthorized: () => {
        if (hasFeature('monitoring', 'rum')) {
          datadogRum.clearUser();
        }
        setInitializationState(InitializationStates.unauthenticated);
      },
      onHeartbeat: () => {
        const heartbeatDate = Date.now();
        if (lostConnectivityToastId.current) {
          clearNotification(lostConnectivityToastId.current);
          lostConnectivityToastId.current = undefined;
          reportClientMetric(store.clientId, 'offline', { duration: heartbeatDate - lastHeartbeatDate.current });
        }
        lastHeartbeatDate.current = heartbeatDate;
      },
      reportMetric: (key, data) => {
        reportClientMetric(store.clientId, key, data);
      },
      onConnectionProgress,
    });
    const cnx = store.connect();

    const heartbeatCheckInterval = setInterval(() => {
      if (lastHeartbeatDate.current <= Date.now() - HEARTBEAT_DELAY_THRESHOLD_MS && !lostConnectivityToastId.current) {
        notifyError(i18n`Connection issue. Displayed data may be inaccurate.`, {
          persist: true,
          closeable: false,
          actions: [{ key: 'refresh', icon: IconName.sync, tooltip: i18n`Refresh`, onClick: () => window.location.reload() }],
          onToast: (toastId) => {
            lostConnectivityToastId.current = toastId;
          },
        });
      }
    }, HEARTBEAT_CHECK_INTERVAL_MS);

    window.notifyStoreUpdate = () => store.data.globalObservers.notify();

    setStoreCtx(store);

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (store.hasUnsavedChanges()) {
        event.preventDefault();
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      store.close();
      setStoreCtx(undefined);
      delete window.notifyStoreUpdate;
      clearInterval(heartbeatCheckInterval);
      cnx.disconnect();
    };
  }, [browserNetworkManager, bypassReadAcl, connectionId]);

  useEffect(() => {
    if (initializationState === InitializationStates.loading && window.screen) {
      reportClientMetric(connectionId, 'initializedClient', {
        screen: { height: window.screen.height, width: window.screen.width },
        available: { height: window.screen.availHeight, width: window.screen.availWidth },
        initialViewport: { height: window.innerHeight, width: window.innerWidth },
        initialDevicePixelRatio: window.devicePixelRatio,
        protocol: (performance.getEntriesByType('resource').at(0) as PerformanceResourceTiming | undefined)?.nextHopProtocol,
      });
    }
  }, [initializationState, connectionId]);

  return (
    <StoreContextProvider storeContext={storeCtx}>
      <>
        {(initializationState === InitializationStates.loading || initializationState === InitializationStates.loadingRequested) && (
          <Loading
            withLogo
            renderProgress={() => {
              const { progressText, progressPercentage } = renderConnectionProgress(connectionProgress);
              return <ConnectionProgressBar label={progressText} percentage={progressPercentage} />;
            }}
          />
        )}
        {initializationState === InitializationStates.unauthenticated ? renderUnauthenticated(() => forceReconnection(InitializationStates.loading)) : null}
        {initializationState === InitializationStates.loaded && userId ? (
          <AuthContextProvider loggedUserId={userId}>
            {renderLoaded()}
          </AuthContextProvider>
        ) : null}
      </>
    </StoreContextProvider>
  );
};

export default NetworkStoreContextProvider;
