import type { FunctionComponent } from 'react';
import Icon, { IconName, IconSizeVariant } from '../../../../components/atoms/Icon';
import Typo, { TypoAlign, TypoVariant } from '../../../../components/atoms/Typo';
import base from '../../../../theme/base';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import useTheme from '../../../../utils/useTheme';

const useStyles = makeStyles((theme) => ({
  emptyBlockContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: spacingRem.s,
    height: '16rem',
    borderRadius: base.borderRadius.medium,
    borderStyle: 'dashed',
    borderWidth: '0.1rem',
    borderColor: theme.color.border.default,
    paddingLeft: spacingRem.m,
    paddingRight: spacingRem.m,
  },
}), 'graphChartInvalidBlock');

interface GraphChartInvalidBlockProps {
  blockLabel: string,
}

const GraphChartInvalidBlock: FunctionComponent<GraphChartInvalidBlockProps> = ({ blockLabel }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.emptyBlockContainer}>
      <Icon name={IconName.dangerous} size={IconSizeVariant.xxl} color={theme.color.text.disabled} />
      <Typo variant={TypoVariant.blockInlineTitle} color={theme.color.text.disabled} align={TypoAlign.center}>{i18n`Invalid ${blockLabel} configuration`}</Typo>
    </div>
  );
};

export default GraphChartInvalidBlock;
