import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import { BlockFieldLayoutOption } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { DateFieldRaw, DateFieldStoreObject, FilterValueRaw, Formula, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { dateFieldHandler, FilterValueType, ParsedDimensionType, parseDimensionMapping } from 'yooi-modules/modules/conceptModule';
import {
  DateField,
  DateField_DefaultPeriod,
  DateField_Rank,
  Field_ApiAlias,
  Field_Documentation,
  Field_Formula,
  Field_IntegrationOnly,
  Field_IsCore,
  Field_IsDocumentationInline,
  Field_Title,
} from 'yooi-modules/modules/conceptModule/ids';
import { dateType } from 'yooi-modules/modules/conceptModule/utils/formula/dateFunctions';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { DateFieldStoreValue, DateRange as DateRangeStoreValue } from 'yooi-utils';
import { compareDate, comparing, computeEffectiveRangeForPeriodAndDates, joinObjects, numberType, PeriodicityType } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import type { DateInputValue } from '../../../../components/inputs/datePickers/DateInput';
import DateInput from '../../../../components/inputs/datePickers/DateInput';
import DateRange from '../../../../components/inputs/datePickers/DateRange';
import SimpleInput from '../../../../components/inputs/strategy/SimpleInput';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import { TableSortDirection } from '../../../../components/molecules/Table';
import { Spacing } from '../../../../theme/spacingDefinition';
import { getPeriodicityOption, getPeriodicityOptions } from '../../../../utils/dateUtilsFront';
import i18n from '../../../../utils/i18n';
import { getFormulaFieldEditionSection } from '../../formulaRendererUtils';
import InCompositeInput from '../../input/InCompositeInput';
import { createPathConfigurationHandler } from '../../pathConfigurationHandler';
import { getFieldTypeValidator } from '../../pathConfigurationHandlerUtils';
import { getBlockFieldLayoutOption, getDefaultDisplayOptions, getLayoutDisplayOption } from '../_global/blockFieldUtils';
import { getApiAliasInitialState, getDimensionsEditionOption, getDocumentationFieldEditionSection, getIntegrationFieldEditionSection } from '../_global/editionHandlerUtils';
import { getGenericOperationMetadata } from '../_global/updateOperationHandlerUtils';
import UpdateOperationInput from '../_global/UpdateOperationInput';
import UpdateOperationSelector from '../_global/UpdateOperationSelector';
import { duplicateFormula } from '../duplicationUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  createAndLinkFieldToConceptDefinitions,
  duplicateFieldDimensionWithNewField,
  FIELD_DIMENSIONS_READONLY,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
  submitDimensionUpdate,
} from '../fieldDimensionUtils';
import type { FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, FieldComparatorHandler, GetFieldDefinitionHandler, RenderFilter } from '../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldIntegrationOnlyDisabled } from '../FieldLibraryTypes';
import DateFieldRenderer from './DateFieldRenderer';

interface DateFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [FIELD_DIMENSIONS_READONLY]: boolean | undefined,
  [Field_Title]: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
  [DateField_DefaultPeriod]: PeriodicityType | null | undefined,
  [Field_Formula]: Formula | null | undefined,
  [Field_IntegrationOnly]: boolean | null | undefined,
  [FieldIntegrationOnlyDisabled]: boolean | undefined,
}

type DateFieldDefinition = GetFieldDefinitionHandler<typeof dateFieldHandler, DateFieldConfigurationState, never, FieldBlockDisplayOptions>;

export const dateFieldDefinition: DateFieldDefinition = registerFieldDefinition(dateFieldHandler, {
  configuration: {
    typeIcon: IconName.schedule_outline,
    getTypeLabel: () => i18n`Date`,
    asWidget: false,
    getEditionOptions: (store) => ({ mode, editionHandler, readOnly }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      const fieldEditionDimensions: FieldEditionDimensions = editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {};
      const parameterDefinitions: SingleParameterDefinition[] = Object.entries(fieldEditionDimensions)
        .map(([id, { typeId }]) => ({ id, typeId, label: i18n`Dimension`, type: 'dimension' }));

      sections.push({
        key: 'data',
        type: 'section',
        title: i18n`Data`,
        options: [
          getDimensionsEditionOption(editionHandler, Boolean(editionHandler.getValue(Field_Formula)), readOnly || Boolean(editionHandler.getValue(FIELD_DIMENSIONS_READONLY))),
          {
            key: DateField_DefaultPeriod,
            title: i18n`Default granularity`,
            hasValue: () => editionHandler.getValue(DateField_DefaultPeriod) !== undefined,
            clearValue: () => editionHandler.updateValues({ [DateField_DefaultPeriod]: null }),
            type: EditionOptionTypes.select,
            props: {
              selectedOption: getPeriodicityOption(editionHandler.getValueOrDefault(DateField_DefaultPeriod) ?? PeriodicityType.day),
              computeOptions: () => getPeriodicityOptions(),
              onChange: (value) => editionHandler.updateValues({ [DateField_DefaultPeriod]: (value?.id as PeriodicityType | undefined) ?? null }),
            },
          },
        ],
      });

      sections.push(getDocumentationFieldEditionSection(editionHandler));
      sections.push(getIntegrationFieldEditionSection(store, editionHandler, mode));

      sections.push(
        getFormulaFieldEditionSection({
          store,
          parameterDefinitions,
          value: editionHandler.getValueOrDefault(Field_Formula),
          onChange: (value: Formula | null) => editionHandler.updateValues({ [Field_Formula]: value }),
          hasValue: () => editionHandler.getValue(Field_Formula) !== undefined,
          clearValue: () => editionHandler.updateValues({ [Field_Formula]: null }),
          returnType: dateType,
          extraAcceptedTypes: [numberType],
        })
      );

      return sections;
    },
    isCreationEnabled: () => () => true,
    onCreate: (objectStore) => (editionHandler) => {
      const dateFieldRank = objectStore.createObject({ [Instance_Of]: DateField_Rank });
      const fieldId = objectStore.createObject<DateFieldRaw>({
        [Instance_Of]: DateField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [Field_ApiAlias]: editionHandler.getValue(Field_ApiAlias),
        [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
        [Field_Formula]: editionHandler.getValue(Field_Formula),
        [DateField_DefaultPeriod]: editionHandler.getValue(DateField_DefaultPeriod),
        [DateField_Rank]: dateFieldRank,
      });
      linkFieldToFieldDimensions(objectStore, fieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});
      return fieldId;
    },
    inlineCreate: (objectStore) => (conceptDefinitionId, extraFieldOptions) => ({
      type: 'inline',
      onCreate: (title) => {
        const dateFieldRank = objectStore.createObject({ [Instance_Of]: DateField_Rank });
        const newFieldId = objectStore.createObject<DateFieldRaw>(joinObjects(
          extraFieldOptions,
          { [Instance_Of]: DateField, [Field_Title]: title, [DateField_Rank]: dateFieldRank }
        ));
        createAndLinkFieldToConceptDefinitions(objectStore, newFieldId, [conceptDefinitionId]);
        return newFieldId;
      },
    }),
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<DateFieldStoreObject>(fieldId);
        return joinObjects(
          getApiAliasInitialState(objectStore, fieldId),
          {
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            [Field_IntegrationOnly]: field[Field_IntegrationOnly],
            [Field_Formula]: field[Field_Formula],
            [DateField_DefaultPeriod]: field[DateField_DefaultPeriod],
            [FieldIntegrationOnlyDisabled]: field[Field_IsCore],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
            [FIELD_DIMENSIONS_READONLY]: field[Field_IsCore],
          }
        );
      },
      submitFieldUpdate: (stateToSubmit, conceptDefinitionId) => {
        objectStore.updateObject<DateFieldRaw>(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: stateToSubmit[Field_IntegrationOnly],
          [Field_Formula]: stateToSubmit[Field_Formula],
          [DateField_DefaultPeriod]: stateToSubmit[DateField_DefaultPeriod],
        });
        submitDimensionUpdate(objectStore, fieldId, conceptDefinitionId, stateToSubmit[FIELD_EDITION_DIMENSIONS] ?? {});
      },
      duplicateFieldDefinition: () => {
        const field = objectStore.getObject<DateFieldStoreObject>(fieldId);
        const dateFieldRank = objectStore.createObject({ [Instance_Of]: DateField_Rank });
        const fieldDimensionMapping = generateDuplicatedFieldDimensionId(objectStore, fieldId);
        const newFieldId = objectStore.createObject<DateFieldRaw>({
          [Instance_Of]: field[Instance_Of],
          [Field_Title]: `${field[Field_Title]} (copy)`,
          [Field_Documentation]: field[Field_Documentation],
          [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: field[Field_IntegrationOnly],
          [Field_Formula]: duplicateFormula(field[Field_Formula], fieldDimensionMapping),
          [DateField_DefaultPeriod]: field[DateField_DefaultPeriod],
          [DateField_Rank]: dateFieldRank,
        });
        duplicateFieldDimensionWithNewField(objectStore, newFieldId, fieldDimensionMapping);
        return newFieldId;
      },
    }),
  },
  renderField: (_, fieldId) => ({ dimensionsMapping, readOnly, onSubmit, value, focusOnMount }) => (
    <DateFieldRenderer
      fieldId={fieldId}
      dimensionsMapping={dimensionsMapping}
      readOnly={readOnly}
      focusOnMount={focusOnMount ?? false}
      value={onSubmit ? value : undefined}
      onSubmit={onSubmit}
    />
  ),
  input: (_, __, handler) => ({
    render: ({ value, onSubmit, readOnly, onEditionStart, onEditionStop, isEditing, focusOnMount }) => (
      <SimpleInput<DateInputValue>
        initialValue={value}
        onSubmit={(newValue) => onSubmit(newValue?.date ? { period: newValue.period, date: newValue.date } : undefined)}
      >
        {(props) => (
          <DateInput
            value={props.value}
            onChange={(newValue) => props.onChange(newValue)}
            onSubmit={(newValue) => props.onSubmit(newValue)}
            onCancel={props.onCancel}
            readOnly={readOnly}
            placeholder={i18n`Add date`}
            onEditionStart={onEditionStart}
            onEditionStop={onEditionStop}
            isEditing={isEditing}
            defaultPeriodicity={handler.resolveConfiguration().defaultPeriod}
            focusOnMount={focusOnMount}
          />
        )}
      </SimpleInput>
    ),
    getInitialState: () => undefined,
    persistStateOnConcept: (dimension, state) => handler.updateValue(dimension, state ?? null),
  }),
  getUpdateOperationInput: (store, fieldId, { updateOperationHandlers: { INITIALIZE, REPLACE, CLEAR } }) => (operation) => {
    const { title, getIcon } = getGenericOperationMetadata(store, operation, fieldId);
    return {
      title,
      getIcon,
      render: ({ onSubmit, parameterDefinitions }) => {
        const operationSelectorLine = (
          <UpdateOperationSelector<typeof dateFieldHandler>
            selectedOperationAction={operation?.action}
            operationOptions={[
              { id: 'INITIALIZE', label: i18n`Initialize`, onSelect: () => onSubmit({ action: 'INITIALIZE', payload: INITIALIZE.sanitizeOperation(operation) }) },
              { id: 'REPLACE', label: i18n`Replace`, onSelect: () => onSubmit({ action: 'REPLACE', payload: REPLACE.sanitizeOperation(operation) }) },
              { id: 'CLEAR', label: i18n`Clear`, onSelect: () => onSubmit({ action: 'CLEAR', payload: CLEAR.sanitizeOperation(operation) }) },
            ]}
          />
        );

        let operationInputsLines = null;
        const { input } = dateFieldDefinition(store).getHandler(fieldId);
        if (input && operation && operation.action !== 'CLEAR' && operation.payload) {
          operationInputsLines = (
            <UpdateOperationInput<typeof dateFieldHandler>
              valueInput={input}
              initialValue={operation.payload}
              onTypeChange={(newType) => {
                onSubmit({ action: operation.action, payload: newType === 'value' ? { type: 'value', value: undefined } : { type: 'path', path: [] } });
              }}
              onValueSubmit={(newValue) => onSubmit({ action: operation.action, payload: newValue })}
              parameterDefinitions={parameterDefinitions}
              valuePathHandler={createPathConfigurationHandler(store, parameterDefinitions, [getFieldTypeValidator(store, [DateField], i18n`Input should end with a date.`)])}
            />
          );
        }

        return (
          <>
            {operationSelectorLine}
            {operationInputsLines}
          </>
        );
      },
    };
  },
  renderExportConfiguration: () => () => (
    <SpacedContainer margin={{ x: Spacing.splus }}>
      <Tooltip title={i18n`Date`}>
        <Typo maxLine={1}>{i18n`Date`}</Typo>
      </Tooltip>
    </SpacedContainer>
  ),
  getActivityProperties: (_, fieldId) => () => [fieldId],
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({
    propertyId: fieldId,
    focusable: true,
    sortable: true,
  }),
  estimatedColumnWidth: () => () => '17rem',
  getComparatorHandler: (_, __, { resolveConfigurationWithOverride, getValueResolution }) => (direction) => ({
    comparator: comparing(compareDate, direction === TableSortDirection.desc),
    extractValue: (dimensionsMapping) => {
      const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
      if (parsedDimensionMapping.type !== ParsedDimensionType.MonoDimensional) {
        return undefined;
      }

      const { defaultPeriod } = resolveConfigurationWithOverride(dimensionsMapping);
      const { value } = getValueResolution(dimensionsMapping);

      if (typeof value === 'number') {
        return computeEffectiveRangeForPeriodAndDates(defaultPeriod, value, undefined).from;
      } else if (value) {
        return computeEffectiveRangeForPeriodAndDates(value.period ?? defaultPeriod, value.date, undefined).from;
      } else {
        return undefined;
      }
    },
  } satisfies FieldComparatorHandler<Date | undefined>),
  filterConditions: () => {
    const renderDateRangeFilter: RenderFilter<FilterValueRaw<DateRangeStoreValue | undefined>> = (value, setValue, _, readOnly) => (
      <InCompositeInput
        initialValue={value.raw}
        setInputValue={(v) => setValue({ type: FilterValueType.raw, raw: v ?? {} })}
      >
        {(props) => (
          <DateRange
            readOnly={readOnly}
            placeholder={i18n`Add dates`}
            withStartConstraint={false}
            {...props}
          />
        )}
      </InCompositeInput>
    );
    const renderDateInputFilter: RenderFilter<FilterValueRaw<DateFieldStoreValue | undefined>> = (value, setValue, _, readOnly) => (
      <InCompositeInput<DateInputValue>
        initialValue={value.raw}
        setInputValue={(v) => setValue({ type: FilterValueType.raw, raw: v?.date ? { period: v.period, date: v.date } : undefined })}
      >
        {(props) => (
          <DateInput
            readOnly={readOnly}
            placeholder={i18n`Add date`}
            {...props}
          />
        )}
      </InCompositeInput>
    );

    return {
      EQUALS: {
        name: i18n`Is`,
        renderFilter: renderDateInputFilter,
      },
      NOT_EQUALS: {
        name: i18n`Is not`,
        renderFilter: renderDateInputFilter,
      },
      INTERSECT: {
        name: i18n`Is within`,
        renderFilter: renderDateRangeFilter,
      },
      IS_EMPTY: {
        name: i18n`Is empty`,
      },
      IS_NOT_EMPTY: {
        name: i18n`Is not empty`,
      },
    };
  },
  blockDisplayOptionsHandler: (objectStore) => (fieldBlockDisplayId) => ({
    getDisplayOptions: () => getDefaultDisplayOptions(objectStore, fieldBlockDisplayId),
    renderSummary: ({ layoutDisplayType }) => ([getBlockFieldLayoutOption()[layoutDisplayType ?? BlockFieldLayoutOption.auto].label]),
    getBlockEditionOptionSections: (state, setState) => [getLayoutDisplayOption(state, setState)],
    onSubmit: (state) => {
      objectStore.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
});
