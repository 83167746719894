import type { FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import { areColumnHeadersInError } from 'yooi-modules/modules/common/fields/FieldModuleDsl';
import type { ConceptStoreObject, DimensionsMapping, MultipleParameterDefinition, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import {
  createValuePathResolver,
  dimensionsMappingToParametersMapping,
  FILTER_PARAMETER_CURRENT,
  getFieldUtilsHandler,
  getInstanceLabel,
  getPathLastFieldInformation,
  InstanceReferenceType,
  isRelationFieldPathConfigurationValid,
  isSingleValueResolution,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import { Concept_Name } from 'yooi-modules/modules/conceptModule/ids';
import type { DimensionExportConfiguration, ViewDimension, WidgetStoreObject } from 'yooi-modules/modules/dashboardModule';
import { DimensionDisplayAxis } from 'yooi-modules/modules/dashboardModule';
import { Widget_Title } from 'yooi-modules/modules/dashboardModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { TimeRange } from 'yooi-store';
import { createAutoProvisioningMap, dateFormats, formatDisplayDate, isRichText, joinObjects, richTextToText, sleep } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import { Icon } from '../../../../components/atoms/icons';
import Typo from '../../../../components/atoms/Typo';
import ConfirmationModal, { ConfirmationModalVariant } from '../../../../components/molecules/ConfirmationModal';
import InlineLoading from '../../../../components/molecules/InlineLoading';
import type { FrontObjectStore } from '../../../../store/useStore';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { clearNotification, notifyInfo } from '../../../../utils/notify';
import { getHostname } from '../../../../utils/options';
import { formatOrUndef } from '../../../../utils/stringUtils';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../../utils/useSizeContext';
import useTheme from '../../../../utils/useTheme';
import withAsyncTask, { registerComputeFunction } from '../../../../utils/withAsyncTask';
import { countValidFilters } from '../../filter/filterUtils';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import type { ExcelValueType } from '../../useExport';
import useExport from '../../useExport';
import { getSeriesLabel } from '../common/series/viewWithSeriesFeatureUtils';
import type { ViewResolutionError } from '../viewResolutionUtils';
import { isResolutionError } from '../viewResolutionUtils';
import type { TableViewResolvedDefinition } from './tableViewHandler';
import type { TableColumn, TableViewResolution, TableViewResolutionLine } from './tableViewResolution';
import { isFieldLineResolution, isInstanceLineResolution } from './tableViewResolution';

interface TableViewExportButtonProps {
  widgetId: string | undefined,
  viewDefinition: TableViewResolvedDefinition,
  viewDimensions: ViewDimension[],
  getViewResolution: () => TableViewResolution | ViewResolutionError,
  parameterDefinitions: SingleParameterDefinition[],
  filterConfiguration: FilterConfiguration | undefined,
}

interface PendingTimeseries {
  objectId: string | string[],
  propertyId: string,
}

interface Header {
  key: 'header1' | 'header2',
  type: 'header',
}

type Column = (TableColumn & { type: 'column', headers?: ExcelValueType[], exportConfiguration?: object }) | Header;
type Line = (TableViewResolutionLine & { type: 'line', headers?: ExcelValueType[], exportConfiguration?: object }) | Header;

const isColumnHeader = (column: Column): column is Header => column.type === 'header';
const isLineHeader = (line: Line): line is Header => line.type === 'header';

const asyncComputeLineAndColumn = registerComputeFunction(
  async (
    yieldProcessor: () => Promise<void>,
    store: FrontObjectStore,
    viewDefinition: TableViewResolvedDefinition,
    viewDimensions: ViewDimension[],
    parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
    getViewResolution: () => TableViewResolution | ViewResolutionError
  ): Promise<{
    headerRowCount: number,
    headerColumnCount: number,
    lines: Line[],
    columns: Column[],
    seriesInErrors: string[],
  } | undefined> => {
    const viewResolution = getViewResolution();
    if (isResolutionError(viewResolution)) {
      return undefined;
    }
    let dimensionMapping: DimensionsMapping[] | undefined;

    let newPendingTimeseries: PendingTimeseries[] = [];
    const pendingTimeseries: PendingTimeseries[] = [];

    // Used to check that all timeseries are loaded
    const watchingFrontObjectStore = joinObjects(
      store,
      {
        getTimeseries: <Value, Id extends (string | string[])>(
          objectId: Id,
          propertyId: string,
          timeRange: TimeRange | undefined
        ) => {
          const values = store.getTimeseries<Value, Id>(objectId, propertyId, timeRange);
          if (!values) {
            newPendingTimeseries.push({ objectId, propertyId });
          }
          return values;
        },
      }
    );

    const getDimensionsMappings = (): DimensionsMapping[] => {
      if (dimensionMapping === undefined) {
        dimensionMapping = [];
        for (let i = 0; i < viewResolution.lines.length; i += 1) {
          const line = viewResolution.lines[i];
          for (let j = 0; j < viewResolution.columns.length; j += 1) {
            const column = viewResolution.columns[j];
            const lineResolution = column.getLineResolution(line);
            if (lineResolution && !isInstanceLineResolution(lineResolution) && lineResolution.dimensionsMapping) {
              dimensionMapping.push(lineResolution.dimensionsMapping);
            }
          }
        }
      }
      return dimensionMapping;
    };

    const lineCache = createAutoProvisioningMap<string, Line[]>();
    const columnCache = createAutoProvisioningMap<string, Column[]>();

    const getLineAndColumn = async (): Promise<{
      headerRowCount: number,
      headerColumnCount: number,
      lines: Line[],
      columns: Column[],
      seriesInErrors: string[],
      timeseriesToWait: { field: string, dimensionsMapping: DimensionsMapping[], dateRange: { from: number, to: number } }[],
    }> => {
      const lines: Line[] = [];
      const columns: Column[] = [];
      const elementInErrors: string[] = [];
      const timeseriesToWait: { field: string, dimensionsMapping: DimensionsMapping[], dateRange: { from: number, to: number } }[] = [];

      const isMultidim = viewResolution.isMultiDim;
      const withRowHeader = viewDefinition.seriesAxis === DimensionDisplayAxis.y;
      let headerRowCount = 0;
      let headerColumnCount = 0;

      if (withRowHeader) {
        headerRowCount = 2;
        lines.push({ key: 'header1', type: 'header' }, { key: 'header2', type: 'header' });
      } else {
        headerColumnCount = 2;
        columns.push({ key: 'header1', type: 'header' }, { key: 'header2', type: 'header' });
        // Always display column header
        headerRowCount = 1;
        lines.push({ key: 'header1', type: 'header' });
      }
      for (let i = 0; i < viewResolution.lines.length; i += 1) {
        const line = viewResolution.lines[i];
        if (viewResolution.filterFunction(dimensionsMappingToParametersMapping(line.dimensionsMapping))) {
          if (line.seriesId) {
            const seriesIndex = viewDefinition.series.findIndex(({ id }) => id === line.seriesId);
            const series = viewDefinition.series[seriesIndex];
            if (lineCache.has(line.key)) {
              const cachedLines = lineCache.get(line.key) ?? [];
              for (let k = 0; k < cachedLines.length; k += 1) {
                lines.push(cachedLines[k]);
              }
            } else {
              const seriesLabel = getSeriesLabel(watchingFrontObjectStore, series.label, seriesIndex, series.path, viewDimensions, []);
              const { fieldId } = getPathLastFieldInformation(series.path) ?? {};
              // If field related, use columnHeaders
              if (fieldId) {
                const columnHeaders = getFieldUtilsHandler(watchingFrontObjectStore, fieldId)
                  .getExportColumnHeaders?.(series.exportOptions ?? undefined, seriesLabel, parameterDefinitions, getDimensionsMappings);
                if (columnHeaders) {
                  if (areColumnHeadersInError(columnHeaders)) {
                    elementInErrors.push(seriesLabel);
                  } else {
                    const { columnsNumber, getColumnConfiguration, getHeaders } = columnHeaders;
                    const currentLines = [];
                    for (let j = 0; j < (columnsNumber ?? 0); j += 1) {
                      const currentLine = joinObjects(line, {
                        type: 'line' as const,
                        key: `${line.key}_${i}`,
                        exportConfiguration: getColumnConfiguration?.(j),
                        headers: getHeaders?.(j),
                      });
                      lines.push(currentLine);
                      currentLines.push(currentLine);
                    }
                    if (newPendingTimeseries.length > 0) {
                      pendingTimeseries.push(...newPendingTimeseries);
                      newPendingTimeseries = [];
                    } else {
                      lineCache.set(line.key, currentLines);
                    }
                  }
                }
              } else {
                // No field, just display column Header
                lines.push(joinObjects(line, { type: 'line' as const, headers: [{ format: 'string' as const, value: seriesLabel }] }));
              }
            }
          } else {
            // No field, just display column Header
            lines.push(joinObjects(line, { type: 'line' as const }));
          }
        }
        await yieldProcessor();
      }
      for (let i = 0; i < viewResolution.columns.length; i += 1) {
        const column = viewResolution.columns[i];
        if (column.seriesId) {
          if (columnCache.has(column.key)) {
            const currentColumns = columnCache.get(column.key) ?? [];
            for (let k = 0; k < currentColumns.length; k += 1) {
              columns.push(currentColumns[k]);
            }
            // If field related, use columnHeaders
          } else if (column.fieldId) {
            if (watchingFrontObjectStore.getObjectOrNull(column.fieldId)) {
              const seriesIndex = viewDefinition.series.findIndex(({ id }) => id === column.seriesId);
              const series = viewDefinition.series[seriesIndex];
              const seriesLabel = getSeriesLabel(watchingFrontObjectStore, series.label, seriesIndex, series.path, viewDimensions, []);
              const instancesLabels = column.instances.map((instanceId) => {
                const instance = instanceId ? watchingFrontObjectStore.getObjectOrNull(instanceId) : undefined;
                return formatOrUndef(instance ? getInstanceLabel(watchingFrontObjectStore, instance) : undefined);
              });
              const columnLabel = isMultidim ? [...instancesLabels, seriesLabel].join(' x ') : seriesLabel;
              const columnHeaders = getFieldUtilsHandler(watchingFrontObjectStore, column.fieldId)
                .getExportColumnHeaders?.(
                  series.exportOptions ?? undefined,
                  columnLabel,
                  parameterDefinitions,
                  getDimensionsMappings
                );
              if (columnHeaders) {
                if (areColumnHeadersInError(columnHeaders)) {
                  elementInErrors.push(seriesLabel);
                } else {
                  const { columnsNumber, getColumnConfiguration, getHeaders } = columnHeaders;
                  const newColumns: Column[] = [];
                  for (let j = 0; j < (columnsNumber ?? 0); j += 1) {
                    const currentColumns = joinObjects(column, {
                      type: 'column' as const,
                      key: `${column.key}_${j}`,
                      exportConfiguration: getColumnConfiguration?.(j),
                      headers: getHeaders?.(j),
                    });
                    columns.push(currentColumns);
                    newColumns.push(currentColumns);
                  }
                  if (newPendingTimeseries.length > 0) {
                    pendingTimeseries.push(...newPendingTimeseries);
                    newPendingTimeseries = [];
                  } else {
                    columnCache.set(column.key, newColumns);
                  }
                }
              }
            }
            // This is a dimension series
          } else if (column.isInstance) {
            const series = viewDefinition.series.find(({ id }) => id === column.seriesId);
            if (series) {
              const configuration = series.exportOptions as DimensionExportConfiguration | undefined;
              if (!configuration || configuration.type !== 'path' || isRelationFieldPathConfigurationValid(watchingFrontObjectStore, configuration, parameterDefinitions)) {
                columns.push(joinObjects(column, {
                  type: 'column' as const,
                  exportConfiguration: series.exportOptions ?? undefined,
                  headers: [{ format: 'string' as const, value: column.label }],
                }));
              } else {
                elementInErrors.push(column.label);
              }
            }
          }
          // This is a dimension
        } else if (column.viewDimension && column.isInstance) {
          const dimension = viewDimensions.find(({ id }) => id === column.viewDimension);
          const dimensionDisplay = dimension ? viewDefinition.getDimensionDisplay(dimension) : undefined;
          if (dimensionDisplay) {
            const configuration = dimensionDisplay.exportConfiguration;
            if (configuration?.type !== 'path' || isRelationFieldPathConfigurationValid(watchingFrontObjectStore, configuration, parameterDefinitions)) {
              columns.push(joinObjects(column, {
                type: 'column' as const,
                exportConfiguration: dimensionDisplay.exportConfiguration ?? undefined,
                headers: [{ format: 'string' as const, value: column.label }],
              }));
            } else {
              elementInErrors.push(column.label);
            }
          }
        } else {
          columns.push(joinObjects(column, {
            type: 'column' as const,
            headers: [{ format: 'string' as const, value: column.label }],
          }));
        }
        await yieldProcessor();
      }
      return { lines, columns, seriesInErrors: elementInErrors, timeseriesToWait, headerColumnCount, headerRowCount };
    };

    const result = await getLineAndColumn();
    let allTimeseriesAreResolved = pendingTimeseries.every(({ objectId, propertyId }) => !!store.getTimeseries(objectId, propertyId));
    if (allTimeseriesAreResolved) {
      return result;
    } else {
      while (!allTimeseriesAreResolved) {
        await sleep(1000);
        allTimeseriesAreResolved = pendingTimeseries.every(({ objectId, propertyId }) => !!store.getTimeseries(objectId, propertyId));
      }
      return getLineAndColumn();
    }
  }
);

const TableViewExportButton: FunctionComponent<TableViewExportButtonProps> = withAsyncTask(({
  executeAsyncTask,
  widgetId,
  viewDefinition,
  viewDimensions,
  getViewResolution,
  parameterDefinitions,
  filterConfiguration,
}) => {
  const { exportToExcel } = useExport<Column, Line>(executeAsyncTask);
  const store = useStore();
  const theme = useTheme();
  const widget = widgetId ? store.getObjectOrNull<WidgetStoreObject>(widgetId) : undefined;
  const widgetName = widget ? widget[Widget_Title] : undefined;
  const toastIdRef = useRef<string | undefined>(undefined);

  const withFilters = filterConfiguration !== undefined && ((filterConfiguration.filters && Object.values(filterConfiguration.filters)
    .some((filter) => countValidFilters(store, filter) > 0))
    || filterConfiguration.nameSearch !== undefined);

  const [pendingExport, setPendingExport] = useState<{
    headerRowCount: number,
    headerColumnCount: number,
    lines: Line[],
    columns: Column[],
    seriesInErrors: string[],
  } | 'pending' | undefined>(undefined);

  let fileName = i18n`export`;
  if (viewDefinition.exportTitle) {
    fileName = viewDefinition.exportTitle;
  } else if (widgetName) {
    fileName = `${widgetName}-${fileName}`;
  }

  const exportView = (lines: Line[], columns: Column[], headerRowCount = 0, headerColumnCount = 0) => {
    exportToExcel({
      toastId: toastIdRef.current,
      fileName: `${formatDisplayDate(new Date(), dateFormats.isoDateFormat)},${fileName}.xlsx`,
      headerRowCount,
      headerColumnCount,
      lines,
      columns,
      cellResolver: (objectStore, line, column) => {
        if (isLineHeader(line)) {
          if (isColumnHeader(column)) {
            return undefined;
          } else if (line.key === 'header1') {
            return column.headers?.at(0);
          } else if (line.key === 'header2') {
            return column.headers?.at(1);
          }
          return undefined;
        } else if (isColumnHeader(column)) {
          if (column.key === 'header1') {
            return line.headers?.at(0);
          } else if (column.key === 'header2') {
            return line.headers?.at(1);
          }
          return undefined;
        } else {
          const lineResolution = column.getLineResolution(line as TableViewResolutionLine);
          if (isFieldLineResolution(lineResolution)) {
            const configuration = column.exportConfiguration ?? line.exportConfiguration;
            const fieldUtilsHandler = getFieldUtilsHandler(objectStore, lineResolution.fieldId);
            if (!fieldUtilsHandler.getExportValue) {
              return undefined;
            }
            return fieldUtilsHandler.getExportValue(lineResolution.dimensionsMapping ?? {}, configuration, { hostname: getHostname() });
          } else if (isInstanceLineResolution(lineResolution)) {
            const configuration = (column.exportConfiguration ?? line.exportConfiguration) as DimensionExportConfiguration | undefined;
            if (configuration && configuration.type === 'uuid') {
              return { format: 'string', value: lineResolution.instanceId };
            } else {
              const instance = lineResolution.instanceId ? store.getObjectOrNull<ConceptStoreObject>(lineResolution.instanceId) : undefined;
              if (!instance) {
                return { format: 'string', value: undefined };
              }
              const path = configuration?.path ?? [{
                type: PathStepType.dimension,
                conceptDefinitionId: instance[Instance_Of],
              },
              { type: PathStepType.mapping, mapping: { type: InstanceReferenceType.parameter, id: FILTER_PARAMETER_CURRENT } },
              { type: PathStepType.field, fieldId: Concept_Name },
              ];
              const pathResolution = createValuePathResolver(objectStore, { [FILTER_PARAMETER_CURRENT]: { type: 'single', id: lineResolution.instanceId } }).resolvePathValue(path);
              if (isSingleValueResolution(pathResolution)) {
                return { format: 'string', value: isRichText(pathResolution.value) ? richTextToText(pathResolution.value) : pathResolution.value as string };
              } else {
                return { format: 'string', value: undefined };
              }
            }
          }
          return undefined;
        }
      },
    });
  };

  if (pendingExport === 'pending') {
    const { status, value } = executeAsyncTask(asyncComputeLineAndColumn, [store, viewDefinition, viewDimensions, parameterDefinitions, getViewResolution]);
    if (status === 'loaded' && value) {
      if (value.seriesInErrors.length > 0 || withFilters) {
        setPendingExport(value);
      } else {
        exportView(value.lines, value.columns, value.headerRowCount, value.headerColumnCount);
        setPendingExport(undefined);
      }
    }
  }

  return (
    <>
      <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
        <IconOnlyButton
          tooltip={i18n`Export`}
          iconName={IconName.file_save_outline}
          variant={IconOnlyButtonVariants.secondary}
          onClick={() => {
            notifyInfo(i18n`Preparing file`, {
              persist: true,
              icon: { icon: Icon.file_download },
              onToast: (newToastId) => {
                toastIdRef.current = newToastId;
              },
            });
            setPendingExport('pending');
          }}
        />
      </SizeContextProvider>
      {pendingExport && pendingExport !== 'pending' && (
        <ConfirmationModal
          variant={ConfirmationModalVariant.confirm}
          title={i18n`Warning`}
          titleIcon={{ name: Icon.warning, color: theme.color.background.warning.default }}
          open
          onConfirm={() => {
            if (pendingExport && typeof pendingExport !== 'string') {
              exportView(pendingExport.lines, pendingExport.columns, pendingExport.headerRowCount, pendingExport.headerColumnCount);
              setPendingExport(undefined);
            }
          }}
          confirmLabel={i18n`Continue`}
          cancelLabel={i18n`Cancel`}
          onCancel={() => {
            setPendingExport(undefined);
            const toastId = toastIdRef.current;
            if (toastId) {
              clearNotification(toastId);
            }
          }}
          render={() => <Typo>{i18n`${pendingExport && pendingExport.seriesInErrors.length > 0 ? i18n`${pendingExport.seriesInErrors.length} series will not be exported (${pendingExport.seriesInErrors.join(', ')}) because the export configuration is corrupted. If you wish to export them, you must ask an administrator.` : ''}${pendingExport && pendingExport.seriesInErrors.length > 0 && withFilters ? '\n' : ''}${withFilters ? i18n`Filters configured on this view will be taken into account when exporting data. Are you sure you want to continue?` : ''}`}</Typo>}
        />
      )}
    </>
  );
}, InlineLoading);

export default TableViewExportButton;
