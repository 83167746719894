import type { FunctionComponent } from 'react';
import RightPanel, { RightPanelVariant } from '../../../../components/molecules/RightPanel';
import { PanelState } from '../../../../utils/useCollaborationContext';
import CollaborationCreatePanel from './create/CollaborationCreatePanel';
import CollaborationDetailPanel from './detail/CollaborationDetailPanel';
import CollaborationListPanel from './list/CollaborationListPanel';
import usePanelObserver from './usePanelObserver';

interface CollaborationPanelProps {
  setPhaseState: (phaseState: string) => void,
  phaseState: string,
}

const CollaborationPanel: FunctionComponent<CollaborationPanelProps> = ({ setPhaseState, phaseState }) => {
  const { fullContext, state, isOpen } = usePanelObserver();

  if (fullContext === undefined) {
    return null;
  } else {
    return (
      <RightPanel variant={RightPanelVariant.collaboration} isOpen={isOpen}>
        {state === PanelState.creation ? (<CollaborationCreatePanel />) : null}
        {state === PanelState.list && isOpen ? (<CollaborationListPanel setPhaseState={setPhaseState} phaseState={phaseState} />) : null}
        {state === PanelState.detail ? (<CollaborationDetailPanel />) : null}
      </RightPanel>
    );
  }
};

export default CollaborationPanel;
