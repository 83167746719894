import type { FunctionComponent, ComponentProps, ReactElement } from 'react';
import { useState } from 'react';
import type { IntentionStoreObject } from 'yooi-modules/modules/collaborationModule';
import {
  ConceptIntentionGroups,
  ConceptIntentionGroups_Role_Concept,
  ConceptIntentionGroups_Role_Group,
  ConceptIntentionGroups_Role_Intention, ConceptIntentionRoles, ConceptIntentionRoles_Role_Concept, ConceptIntentionRoles_Role_Intention, ConceptIntentionRoles_Role_Role,
  ConceptIntentionTransitionGroups,
  ConceptIntentionTransitionGroups_Role_Concept,
  ConceptIntentionTransitionGroups_Role_Group,
  ConceptIntentionTransitionGroups_Role_Intention,
  ConceptIntentionTransitionGroups_Role_Transition,
  ConceptIntentionTransitionRoles,
  ConceptIntentionTransitionRoles_Role_Concept,
  ConceptIntentionTransitionRoles_Role_Intention, ConceptIntentionTransitionRoles_Role_Role, ConceptIntentionTransitionRoles_Role_Transition,
  ConceptIntentionUsers,
  ConceptIntentionUsers_Role_Concept,
  ConceptIntentionUsers_Role_Intention,
  ConceptIntentionUsers_Role_User,
  Intention,
  Intention_Description,
  Intention_Name,
  Intention_Workflow,
} from 'yooi-modules/modules/collaborationModule/ids';
import type { WorkflowTransitionStoreObject } from 'yooi-modules/modules/conceptModule';
import { getRawInstanceLabel, getInstanceLabelOrUndefined } from 'yooi-modules/modules/conceptModule';
import {
  ConceptRole_ConceptDefinition,
  Group,
  User, Workflow_TargetedConceptDefinition, Workflow_Transitions, WorkflowEntry, WorkflowEntry_Rank, WorkflowEntry_Role_Concept, WorkflowEntry_Role_Workflow,
  WorkflowTransition_From,
  WorkflowTransition_Name,
  WorkflowTransition_To,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareRank, compareString, comparing, filterNullOrUndefined, joinObjects } from 'yooi-utils';
import type { StoreObject } from 'yooi-store';
import { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import Tooltip from '../../../../../components/atoms/Tooltip';
import Typo from '../../../../../components/atoms/Typo';
import type { CompositeDropdownSection } from '../../../../../components/molecules/CompositeField';
import CompositeField from '../../../../../components/molecules/CompositeField';
import MasterDetailList from '../../../../../components/molecules/MasterDetailList';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import SearchAndSelectMultiple from '../../../../../components/molecules/SearchAndSelectMultiple';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../../../../components/molecules/Table';
import BaseLayout from '../../../../../components/templates/BaseLayout';
import BlockContent from '../../../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../../../components/templates/BlockTitle';
import DataTable from '../../../../../components/templates/DataTable';
import Header from '../../../../../components/templates/Header';
import HorizontalBlock from '../../../../../components/templates/HorizontalBlock';
import LeftPanel from '../../../../../components/templates/LeftPanel';
import VerticalBlock from '../../../../../components/templates/VerticalBlock';
import useAcl from '../../../../../store/useAcl';
import useStore from '../../../../../store/useStore';
import i18n from '../../../../../utils/i18n';
import { safeSessionStorageValue } from '../../../../../utils/sessionStorageUtils';
import { formatOrUndef } from '../../../../../utils/stringUtils';
import type { UseNavigation } from '../../../../../utils/useNavigation';
import { NavigationElementContainer } from '../../../../../utils/useNavigation';
import { resolveConceptChipIcon, resolveConceptColorValue } from '../../../../_global/conceptDisplayUtils';
import { getInlineCreationBuilder } from '../../../../_global/conceptUtils';
import type { FilterConfiguration } from '../../../../_global/filter/useFilterSessionStorage';
import HeaderFromStore from '../../../../_global/HeaderFromStore';
import StoreTextInputField from '../../../../_global/input/StoreTextInputField';
import { searchFilterFunction } from '../../../../_global/listFilterFunctions';
import { defaultOptionComparator, getChipOptions, getModelTypeInstances, getSearchChipOptions, getUnknownChip } from '../../../../_global/modelTypeUtils';
import { NavigationTitlePathElements } from '../../../../_global/navigationUtils';
import { getIntentionGroups, getIntentionUsers, isIntentionAvailableOnConcept } from '../../../../_global/rightPanel/collaboration/utils/collaborationUtils';
import TopBar from '../../../../_global/topBar/TopBar';
import type { ComparatorHandler } from '../../../../_global/useFilterAndSort';
import useFilterAndSort from '../../../../_global/useFilterAndSort';

interface IntentionDetailPageProps {
  conceptDefinitionId: string,
  intentionId: string,
}

const IntentionDetailPage: FunctionComponent<IntentionDetailPageProps> = ({ conceptDefinitionId, intentionId }) => {
  const store = useStore();
  const intention = store.getObject<IntentionStoreObject>(intentionId);
  const filterId = `${conceptDefinitionId}_intention`;
  const { canCreateObject } = useAcl();
  const [search, setSearch] = useState<string | undefined>(undefined);
  const filters = safeSessionStorageValue<FilterConfiguration | undefined>(filterId);
  const intentionFilterFunction = searchFilterFunction<Record<string, unknown>>(store, [filters?.nameSearch, search], [Intention_Name]);
  const { generateList } = useFilterAndSort(
    filterId,
    store.getObject(Intention)
      .navigateBack<IntentionStoreObject>(Instance_Of)
      .filter((i) => isIntentionAvailableOnConcept(store, i.id, conceptDefinitionId))
      .map((i) => (
        {
          key: i.id,
          name: i[Intention_Name],
          description: i[Intention_Description],
          url: `/settings/organization/${conceptDefinitionId}/intention/${i.id}`,
        }
      )),
    intentionFilterFunction ? (item) => intentionFilterFunction(item) : undefined,
    {
      getComparatorHandler: (_, direction) => ({
        comparator: comparing(compareString, direction === TableSortDirection.desc),
        extractValue: (item) => item.name,
      }),
      initial: { key: 'name', direction: TableSortDirection.asc },
    },
    undefined,
    [search]
  );

  const intentionGroups = getIntentionGroups(store, intentionId);
  const intentionUsers = getIntentionUsers(store, intentionId);
  const intentionChipOption = intention ? getChipOptions(store, intentionId) : undefined;
  const workflowId = intention[Intention_Workflow];
  const workflow = workflowId ? store.getObjectOrNull(workflowId) : null;
  const workflowTargetedConceptDefinition = workflow?.navigateOrNull(Workflow_TargetedConceptDefinition) ?? undefined;

  const renderTransitionStatusCell = (propertyId: string, transition: StoreObject): ReactElement | null => {
    let selectedOption: ComponentProps<typeof SearchAndSelect>['selectedOption'] | undefined;
    let error: string | undefined;
    if (transition[propertyId]) {
      const instance = transition.navigateOrNull(propertyId);
      if (instance) {
        selectedOption = getChipOptions(store, instance.id);
        if (!workflowTargetedConceptDefinition || !isInstanceOf(instance, workflowTargetedConceptDefinition.id)) {
          error = i18n`Selected step is not of the expected type`;
        } else if (
          !store.withAssociation(WorkflowEntry)
            .withRole(WorkflowEntry_Role_Workflow, workflowId!)
            .withRole(WorkflowEntry_Role_Concept, instance.id)
            .getObjectOrNull()
        ) {
          error = i18n`Step is not in the workflow`;
        }
      } else {
        selectedOption = getUnknownChip(transition[propertyId] as string);
        error = i18n`Selected step no longer exists`;
      }
    }

    return (
      <SearchAndSelect
        selectedOption={selectedOption}
        readOnly
        statusIcon={error ? { icon: IconName.dangerous, color: IconColorVariant.error, message: error } : undefined}
      />
    );
  };

  const { generateGroupedList: generateTransitionsGroupedList, sortCriteria: sortTransitionsCriteria, doSort: doTransitionsSort } = useFilterAndSort(
    `${workflowId}_${Workflow_Transitions}`,
    workflow!.navigateBack<WorkflowTransitionStoreObject>(Workflow_Transitions),
    undefined,
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case WorkflowTransition_From:
            return {
              comparator: comparing(compareRank, direction === TableSortDirection.desc),
              extractValue: (transition) => {
                const from = transition.navigateOrNull(WorkflowTransition_From);
                if (from) {
                  return store.withAssociation(WorkflowEntry)
                    .withRole(WorkflowEntry_Role_Workflow, workflowId!)
                    .withRole(WorkflowEntry_Role_Concept, from.id)
                    .getObjectOrNull()
                    ?.[WorkflowEntry_Rank] as string | undefined;
                } else {
                  return undefined;
                }
              },
            } satisfies ComparatorHandler<WorkflowTransitionStoreObject, string | undefined>;
          default:
            return undefined;
        }
      },
      initial: { key: WorkflowTransition_From, direction: TableSortDirection.asc },
    },
    {
      key: WorkflowTransition_From,
      getGroupKey: (item) => item.navigateOrNull(WorkflowTransition_From)?.id,
      getGroupLabel: (key) => getInstanceLabelOrUndefined(store, store.getObject(key)),
      getGroupColor: (key) => (resolveConceptColorValue(store, key) ?? resolveConceptChipIcon(store, key)?.color),
    }
  );

  return (
    <NavigationElementContainer
      element={{
        key: intentionId,
        name: formatOrUndef(intention[Intention_Name]),
        element: NavigationTitlePathElements.collaboration,
        path: `/settings/organization/${conceptDefinitionId}/intention/${intentionId}`,
      }}
    >
      <BaseLayout
        topBar={(<TopBar />)}
        leftPanel={(
          <LeftPanel>
            <MasterDetailList
              list={
                generateList().list
                  .map(({ item, ...entry }) => (
                    joinObjects(
                      entry,
                      {
                        render: () => (
                          <Tooltip title={formatOrUndef(item.name)}>
                            <Typo maxLine={1}>{formatOrUndef(item.name)}</Typo>
                          </Tooltip>
                        ),
                        to: () => ({ pathname: item.url }),
                      }
                    )
                  ))
              }
              search={{ value: search, setValue: setSearch }}
            />
          </LeftPanel>
        )}
        header={(
          <Header
            firstLine={(
              <HeaderFromStore
                instanceId={intentionId}
                propertyId={Intention_Name}
                placeholder={i18n`Click to edit name`}
                readOnly
              />
            )}
            withBottomBorder
          />
        )}
        content={(
          <VerticalBlock>
            <VerticalBlock asBlockContent withSeparation>
              <HorizontalBlock asBlockContent>
                <BlockTitle title={i18n`Intention platform configuration`} />
                <BlockContent>
                  <SearchAndSelect
                    readOnly
                    selectedOption={intentionChipOption
                      ? joinObjects(
                        intentionChipOption,
                        {
                          getNavigationPayload: (navigation: UseNavigation) => navigation.createNavigationPayload(
                            intentionId,
                            { pathname: `/settings/collaboration/${intentionId}` }
                          ),
                        }
                      )
                      : getUnknownChip(intentionId)}
                  />
                </BlockContent>
              </HorizontalBlock>
            </VerticalBlock>
            <VerticalBlock asBlockContent>
              <BlockTitle title={i18n`Assignation`} subtitle={i18n`These users, groups and roles will be automatically added to the subscribers of the collaboration.`} />
              <HorizontalBlock asBlockContent>
                <BlockTitle
                  title={i18n`User(s)`}
                />
                <BlockContent>
                  <SearchAndSelectMultiple
                    placeholder={i18n`Add user`}
                    selectedOptions={[
                      ...intentionUsers
                        .map((entry) => getChipOptions(store, entry)).filter(filterNullOrUndefined)
                        .map((entry) => (joinObjects(entry, {
                          noDelete: true, tooltip: i18n`Assigned via intention configuration.`,
                        }))),
                      ...store.withAssociation(ConceptIntentionUsers)
                        .withRole(ConceptIntentionUsers_Role_Concept, conceptDefinitionId)
                        .withRole(ConceptIntentionUsers_Role_Intention, intentionId).list().map((assoc) => assoc.role(ConceptIntentionUsers_Role_User))
                        .filter((user) => !intentionUsers.includes(user))
                        .map((entry) => getChipOptions(store, entry)).filter(filterNullOrUndefined),
                    ].sort(defaultOptionComparator)}
                    computeOptions={() => getModelTypeInstances(store, User)
                      .map(({ id }) => id)
                      .map((id) => getChipOptions(store, id))
                      .filter(filterNullOrUndefined).sort(defaultOptionComparator)}
                    searchOptions={getSearchChipOptions(store, User)}
                    onSelect={({ id: idSelected }) => store.withAssociation(ConceptIntentionUsers)
                      .withRole(ConceptIntentionUsers_Role_Intention, intentionId)
                      .withRole(ConceptIntentionUsers_Role_User, idSelected)
                      .withRole(ConceptIntentionUsers_Role_Concept, conceptDefinitionId)
                      .updateObject({})}
                    onDelete={({ id: idSelected }) => store.withAssociation(ConceptIntentionUsers)
                      .withRole(ConceptIntentionUsers_Role_Intention, intentionId)
                      .withRole(ConceptIntentionUsers_Role_User, idSelected)
                      .withRole(ConceptIntentionUsers_Role_Concept, conceptDefinitionId)
                      .deleteObject()}
                    getInlineCreation={
                      canCreateObject(User)
                        ? getInlineCreationBuilder(
                          store,
                          User,
                          (id) => store.withAssociation(ConceptIntentionUsers)
                            .withRole(ConceptIntentionUsers_Role_Intention, intentionId)
                            .withRole(ConceptIntentionUsers_Role_User, id)
                            .withRole(ConceptIntentionUsers_Role_Concept, conceptDefinitionId)
                            .updateObject({})
                        )
                        : undefined
                    }
                  />
                </BlockContent>
              </HorizontalBlock>
              <HorizontalBlock asBlockContent>
                <BlockTitle
                  title={i18n`Group(s)`}
                />
                <BlockContent>
                  <SearchAndSelectMultiple
                    placeholder={i18n`Add group`}
                    selectedOptions={[
                      ...intentionGroups.map((entry) => getChipOptions(store, entry)).filter(filterNullOrUndefined)
                        .map((entry) => (joinObjects(entry, {
                          noDelete: true, tooltip: i18n`Assigned via intention configuration.`,
                        }))),
                      ...store.withAssociation(ConceptIntentionGroups)
                        .withRole(ConceptIntentionGroups_Role_Concept, conceptDefinitionId)
                        .withRole(ConceptIntentionGroups_Role_Intention, intentionId)
                        .list()
                        .map((assoc) => assoc.role(ConceptIntentionGroups_Role_Group))
                        .filter((group) => !intentionGroups.includes(group))
                        .map((entry) => getChipOptions(store, entry)).filter(filterNullOrUndefined),
                    ].sort(defaultOptionComparator)}
                    computeOptions={() => getModelTypeInstances(store, Group)
                      .map(({ id }) => id)
                      .map((id) => getChipOptions(store, id))
                      .filter(filterNullOrUndefined)
                      .sort(defaultOptionComparator)}
                    searchOptions={getSearchChipOptions(store, Group)}
                    onSelect={({ id: idSelected }) => store.withAssociation(ConceptIntentionGroups)
                      .withRole(ConceptIntentionGroups_Role_Intention, intentionId)
                      .withRole(ConceptIntentionGroups_Role_Group, idSelected)
                      .withRole(ConceptIntentionGroups_Role_Concept, conceptDefinitionId)
                      .updateObject({})}
                    onDelete={({ id: idSelected }) => store.withAssociation(ConceptIntentionGroups)
                      .withRole(ConceptIntentionGroups_Role_Intention, intentionId)
                      .withRole(ConceptIntentionGroups_Role_Group, idSelected)
                      .withRole(ConceptIntentionGroups_Role_Concept, conceptDefinitionId)
                      .deleteObject()}
                    getInlineCreation={
                      canCreateObject(Group)
                        ? getInlineCreationBuilder(
                          store,
                          Group,
                          (id) => store.withAssociation(ConceptIntentionGroups)
                            .withRole(ConceptIntentionGroups_Role_Intention, intentionId)
                            .withRole(ConceptIntentionGroups_Role_Concept, conceptDefinitionId)
                            .withRole(ConceptIntentionGroups_Role_Group, id)
                            .updateObject({})
                        )
                        : undefined
                    }
                  />
                </BlockContent>
              </HorizontalBlock>
              <HorizontalBlock asBlockContent>
                <BlockTitle
                  title={i18n`Role(s)`}
                />
                <BlockContent>
                  <SearchAndSelectMultiple
                    placeholder={i18n`Add role`}
                    selectedOptions={store.withAssociation(ConceptIntentionRoles)
                      .withRole(ConceptIntentionRoles_Role_Concept, conceptDefinitionId)
                      .withRole(ConceptIntentionRoles_Role_Intention, intentionId)
                      .list()
                      .map((assoc) => assoc.role(ConceptIntentionRoles_Role_Role))
                      .map((entry) => getChipOptions(store, entry)).filter(filterNullOrUndefined)
                      .sort(defaultOptionComparator)}
                    computeOptions={() => store.getObject(conceptDefinitionId)
                      .navigateBack(ConceptRole_ConceptDefinition)
                      .map((role) => getChipOptions(store, role.id))
                      .filter(filterNullOrUndefined)
                      .sort(defaultOptionComparator)}
                    onSelect={({ id: idSelected }) => store.withAssociation(ConceptIntentionRoles)
                      .withRole(ConceptIntentionRoles_Role_Intention, intentionId)
                      .withRole(ConceptIntentionRoles_Role_Role, idSelected)
                      .withRole(ConceptIntentionRoles_Role_Concept, conceptDefinitionId)
                      .updateObject({})}
                    onDelete={({ id: idSelected }) => store.withAssociation(ConceptIntentionRoles)
                      .withRole(ConceptIntentionRoles_Role_Intention, intentionId)
                      .withRole(ConceptIntentionRoles_Role_Role, idSelected)
                      .withRole(ConceptIntentionRoles_Role_Concept, conceptDefinitionId)
                      .deleteObject()}
                  />
                </BlockContent>
              </HorizontalBlock>
            </VerticalBlock>
            {workflow && (
              <VerticalBlock asBlockContent withSeparation>
                <BlockTitle
                  title={i18n`Transitions users rights`}
                  variant={BlockTitleVariant.secondary}
                />
                <DataTable
                  list={generateTransitionsGroupedList().list}
                  sortCriteria={sortTransitionsCriteria}
                  doSort={doTransitionsSort}
                  columnsDefinition={[
                    {
                      name: i18n`From`,
                      propertyId: WorkflowTransition_From,
                      width: 20,
                      sortable: true,
                      cellRender: (transition) => renderTransitionStatusCell(WorkflowTransition_From, transition),
                    },
                    {
                      name: i18n`To`,
                      propertyId: WorkflowTransition_To,
                      width: 20,
                      cellRender: (transition) => renderTransitionStatusCell(WorkflowTransition_To, transition),
                    },
                    {
                      name: i18n`Label`,
                      propertyId: WorkflowTransition_Name,
                      width: 20,
                      cellRender: (transition) => (
                        <StoreTextInputField
                          initialValue={transition[WorkflowTransition_Name] as string}
                          readOnly
                        />
                      ),
                    },
                    {
                      name: i18n`Rights`,
                      propertyId: 'WorkflowTransitionRights',
                      width: 40,
                      cellRender: (transition) => (
                        <CompositeField
                          headerLinesRenderers={[
                            {
                              id: 'main',
                              render: () => {
                                const groups = store.withAssociation(ConceptIntentionTransitionGroups)
                                  .withRole(ConceptIntentionTransitionGroups_Role_Intention, intentionId)
                                  .withRole(ConceptIntentionTransitionGroups_Role_Transition, transition.id)
                                  .withRole(ConceptIntentionTransitionGroups_Role_Concept, conceptDefinitionId)
                                  .list()
                                  .map((assoc) => assoc.role(ConceptIntentionTransitionGroups_Role_Group))
                                  .filter(filterNullOrUndefined)
                                  .map((entry) => getRawInstanceLabel(store, store.getObject(entry)));
                                const roles = store.withAssociation(ConceptIntentionTransitionRoles)
                                  .withRole(ConceptIntentionTransitionRoles_Role_Intention, intentionId)
                                  .withRole(ConceptIntentionTransitionRoles_Role_Transition, transition.id)
                                  .withRole(ConceptIntentionTransitionRoles_Role_Concept, conceptDefinitionId)
                                  .list()
                                  .map((assoc) => assoc.role(ConceptIntentionTransitionRoles_Role_Role))
                                  .filter(filterNullOrUndefined)
                                  .map((entry) => getRawInstanceLabel(store, store.getObject(entry)));
                                const config = [...groups, ...roles].join(', ');
                                return (
                                  <SpacingLine>
                                    <Typo maxLine={1}>{(groups.length > 0 || roles.length > 0) ? config : i18n`No configuration` }</Typo>
                                  </SpacingLine>
                                );
                              },
                            },
                          ]}
                          getDropdownSectionDefinitions={() => {
                            const sections: CompositeDropdownSection[] = [];

                            const groupSection: CompositeDropdownSection = {
                              id: ConceptIntentionTransitionGroups,
                              title: i18n`Group`,
                              useGridForTitle: true,
                              action: (
                                <SearchAndSelectMultiple
                                  placeholder={i18n`Add group`}
                                  selectedOptions={store.withAssociation(ConceptIntentionTransitionGroups)
                                    .withRole(ConceptIntentionTransitionGroups_Role_Intention, intentionId)
                                    .withRole(ConceptIntentionTransitionGroups_Role_Transition, transition.id)
                                    .withRole(ConceptIntentionTransitionGroups_Role_Concept, conceptDefinitionId)
                                    .list()
                                    .map((assoc) => assoc.role(ConceptIntentionTransitionGroups_Role_Group))
                                    .map((entry) => getChipOptions(store, entry)).filter(filterNullOrUndefined)}
                                  computeOptions={() => getModelTypeInstances(store, Group)
                                    .map(({ id }) => id)
                                    .map((id) => getChipOptions(store, id))
                                    .filter(filterNullOrUndefined)
                                    .sort(defaultOptionComparator)}
                                  searchOptions={getSearchChipOptions(store, Group)}
                                  onSelect={({ id: idSelected }) => {
                                    store.withAssociation(ConceptIntentionTransitionGroups)
                                      .withRole(ConceptIntentionTransitionGroups_Role_Intention, intentionId)
                                      .withRole(ConceptIntentionTransitionGroups_Role_Transition, transition.id)
                                      .withRole(ConceptIntentionTransitionGroups_Role_Concept, conceptDefinitionId)
                                      .withRole(ConceptIntentionTransitionGroups_Role_Group, idSelected)
                                      .updateObject({});
                                  }}
                                  onDelete={({ id: idSelected }) => store.withAssociation(ConceptIntentionTransitionGroups)
                                    .withRole(ConceptIntentionTransitionGroups_Role_Intention, intentionId)
                                    .withRole(ConceptIntentionTransitionGroups_Role_Transition, transition.id)
                                    .withRole(ConceptIntentionTransitionGroups_Role_Concept, conceptDefinitionId)
                                    .withRole(ConceptIntentionTransitionGroups_Role_Group, idSelected)
                                    .deleteObject()}
                                  getInlineCreation={
                                          canCreateObject(Group)
                                            ? getInlineCreationBuilder(
                                              store,
                                              Group,
                                              (idGroup) => store.withAssociation(ConceptIntentionTransitionGroups)
                                                .withRole(ConceptIntentionTransitionGroups_Role_Intention, intentionId)
                                                .withRole(ConceptIntentionTransitionGroups_Role_Transition, transition.id)
                                                .withRole(ConceptIntentionTransitionGroups_Role_Concept, conceptDefinitionId)
                                                .withRole(ConceptIntentionTransitionGroups_Role_Group, idGroup)
                                                .updateObject({})
                                            )
                                            : undefined
                                        }
                                />
                              ),
                              lines: [],
                            };

                            sections.push(groupSection);

                            const roleSection: CompositeDropdownSection = {
                              id: ConceptIntentionTransitionRoles,
                              title: i18n`Role`,
                              useGridForTitle: true,
                              action: (
                                <SearchAndSelectMultiple
                                  placeholder={i18n`Add role`}
                                  onSelect={({ id: idSelected }) => store.withAssociation(ConceptIntentionTransitionRoles)
                                    .withRole(ConceptIntentionTransitionRoles_Role_Intention, intentionId)
                                    .withRole(ConceptIntentionTransitionRoles_Role_Transition, transition.id)
                                    .withRole(ConceptIntentionTransitionRoles_Role_Concept, conceptDefinitionId)
                                    .withRole(ConceptIntentionTransitionRoles_Role_Role, idSelected)
                                    .updateObject({})}
                                  onDelete={({ id: idSelected }) => store.withAssociation(ConceptIntentionTransitionRoles)
                                    .withRole(ConceptIntentionTransitionRoles_Role_Intention, intentionId)
                                    .withRole(ConceptIntentionTransitionRoles_Role_Transition, transition.id)
                                    .withRole(ConceptIntentionTransitionRoles_Role_Concept, conceptDefinitionId)
                                    .withRole(ConceptIntentionTransitionRoles_Role_Role, idSelected)
                                    .deleteObject()}
                                  computeOptions={() => store.getObject(conceptDefinitionId)
                                    .navigateBack(ConceptRole_ConceptDefinition)
                                    .map((role) => getChipOptions(store, role.id))
                                    .filter(filterNullOrUndefined)
                                    .sort(defaultOptionComparator)}
                                  selectedOptions={store.withAssociation(ConceptIntentionTransitionRoles)
                                    .withRole(ConceptIntentionTransitionRoles_Role_Intention, intentionId)
                                    .withRole(ConceptIntentionTransitionRoles_Role_Transition, transition.id)
                                    .withRole(ConceptIntentionTransitionRoles_Role_Concept, conceptDefinitionId)
                                    .list()
                                    .map((assoc) => assoc.role(ConceptIntentionTransitionRoles_Role_Role))
                                    .map((entry) => getChipOptions(store, entry)).filter(filterNullOrUndefined)}
                                />
                              ),
                              lines: [],
                            };
                            sections.push(roleSection);

                            return sections;
                          }}
                        />
                      ),
                    },
                  ]}
                />
              </VerticalBlock>
            )}
          </VerticalBlock>
        )}
      />
    </NavigationElementContainer>
  );
};

export default IntentionDetailPage;
