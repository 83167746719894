import type { FunctionComponent } from 'react';
import { joinObjects } from 'yooi-utils';
import Typo from '../../../../components/atoms/Typo';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import { buildPadding, Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';

interface WidgetNoDataProps {
  width: number,
  height: number,
  reason?: string,
}

const useStyles = makeStyles({
  info: joinObjects(
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buildPadding({ x: Spacing.xs })
  ),
}, 'widgetNoData');

const WidgetNoData: FunctionComponent<WidgetNoDataProps> = ({ width, height, reason = i18n`No data available` }) => {
  const classes = useStyles();

  return (
    <div style={{ width, height }} className={classes.info}>
      <SpacingLine>
        <Typo maxLine={1}>{reason}</Typo>
      </SpacingLine>
    </div>
  );
};

export default WidgetNoData;
