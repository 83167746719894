import { equals } from 'ramda';
import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { OriginSources, ValidationStatus } from 'yooi-store';
import { arrayOf, compareRank, newError, ranker, textType } from 'yooi-utils';
import { checkRankConflicts, checkUpdateOrigin, updatedAtBusinessRule } from '../../common/businessRules';
import { CommonAsType } from '../../common/fields/commonPropertyType';
import type { GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import type { BusinessRuleRegistration } from '../../common/types/TypeModuleDslType';
import { PropertyMandatoryType } from '../../common/types/TypeModuleDslType';
import {
  AnyElement,
  Attachment,
  Attachment_Rank,
  Attachment_Revision,
  Attachment_Role_FieldDimensions, Attachment_Role_FileName,
  Attachment_UploadedAt,
  Attachment_UploadedBy,
  Attachment_VarRoles_Dimensions,
  Concept,
  User,
} from '../ids';
import type { AttachmentStoreObject } from '../model';
import { registerField } from '../module';
import type { DimensionsMapping, ResolutionStack } from '../utils';
import { buildDimensionalId, isValueResolutionOfType, resolveFieldValue } from '../utils';
import type { AttachmentField } from './types';

type AttachmentValue = string[];

const isAttachmentValue = (value: unknown): value is AttachmentValue => Array.isArray(value) && value.every((v) => typeof v === 'string');

const getStoreValue = (objectStore: ObjectStoreWithTimeseries, dimensionsMapping: DimensionsMapping): AttachmentValue | undefined => {
  const dimensions = buildDimensionalId(dimensionsMapping);
  return objectStore.withAssociation(Attachment)
    .withRole(Attachment_Role_FieldDimensions, dimensions[0])
    .withExternalRole(Attachment_Role_FileName)
    .withVarRoles(Attachment_VarRoles_Dimensions, dimensions.slice(1))
    .list<AttachmentStoreObject>()
    .map((association) => association.object[Attachment_Revision]);
};

const getValueResolution = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack) => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
  if (isValueResolutionOfType(valueResolution, (value): value is AttachmentValue | undefined => value === undefined || isAttachmentValue(value))) {
    return valueResolution;
  } else {
    return {
      value: undefined,
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['string', 'undefined'], typeof valueResolution.value),
      getDisplayValue: () => undefined,
      isTimeseries: false,
    };
  }
};

const updatedByBusinessRule = (updatedByPropertyId: string, ruleLabel: string): BusinessRuleRegistration => (objectStore) => (origin, { id, properties }) => {
  if (origin.source === OriginSources.CLIENT) {
    const object = objectStore.getObjectOrNull(id);
    if (!object || (
      properties
      && Object.entries(properties).some(([propertyId, newPropertyValue]) => !equals(object[propertyId] ?? null, newPropertyValue))
    )) {
      return {
        rule: `${ruleLabel}.updatedBy.generateCreateBy`,
        status: ValidationStatus.ACCEPTED,
        generateSystemEvent: ({ updateObject }) => updateObject(id, { [updatedByPropertyId]: origin.userId }),
      };
    }
  }
  return undefined;
};

type AttachmentFieldHandler = GetDslFieldHandler<
  AttachmentField,
  AttachmentValue | undefined,
  undefined,
  AttachmentValue | undefined,
  AttachmentValue | undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined
>;

export const attachmentFieldHandler: AttachmentFieldHandler = registerField({
  model: {
    label: 'AttachmentField',
    title: 'Attachment',
    withApiAlias: true,
    extraModel: ({ association }) => {
      association({
        label: 'Attachment',
        roles: [
          { label: 'FieldDimensions', targetTypeId: AnyElement },
          { label: 'FileName', targetTypeId: AnyElement },
        ],
        varRoles: { label: 'Dimensions', targetTypeId: Concept },
        accessControlList: {
          READ: () => (origin, objectId, _, readAcl) => {
            if (objectId.slice(Attachment_VarRoles_Dimensions + 1).some((dimensionId) => !readAcl('READ', dimensionId, origin))) {
              return { status: ValidationStatus.REJECTED, rule: 'Attachment.read.cannotReadRoles' };
            } else {
              return { status: ValidationStatus.ACCEPTED, rule: 'Attachment.read.canReadRoles' };
            }
          },
          WRITE: () => (origin, objectId, _, readAcl) => {
            if (objectId.slice(Attachment_VarRoles_Dimensions + 1).some((dimensionId) => !readAcl('READ', dimensionId, origin))) {
              return { status: ValidationStatus.REJECTED, rule: 'Attachment.write.cannotReadRoles' };
            } else if (objectId.slice(Attachment_VarRoles_Dimensions + 1).some((dimensionId) => !readAcl('WRITE', dimensionId, origin))) {
              return { status: ValidationStatus.REJECTED, rule: 'Attachment.write.cannotWriteRoles' };
            }
            return { status: ValidationStatus.ACCEPTED, rule: 'Attachment.write.canWriteRoles' };
          },
          DELETE: () => (_, objectId) => ({ rule: 'association.delete.delegate', status: ValidationStatus.DELEGATED, targetId: objectId, targetAction: 'WRITE' }),
        },
        businessRules: [
          updatedAtBusinessRule(Attachment_UploadedAt, 'Attachment_UploadedAt'),
          updatedByBusinessRule(Attachment_UploadedBy, 'Attachment_UploadedBy'),
        ],
      })
        .property({
          label: 'Attachment_Revision',
          as: CommonAsType.string,
          mandatory: { type: PropertyMandatoryType.mandatory },
        })
        .property({
          label: 'Attachment_UploadedAt',
          as: CommonAsType.number,
          businessRules: [
            checkUpdateOrigin(OriginSources.SYSTEM, 'Attachment_UploadedAt'),
          ],
        })
        .property({
          label: 'Attachment_Rank',
          as: CommonAsType.string,
          mandatory: {
            type: PropertyMandatoryType.mandatoryWithDefaultValue,
            getDefaultValue: (store, id) => {
              const maxRank = store.withAssociation(Attachment)
                .withRole(Attachment_Role_FieldDimensions, id[Attachment_Role_FieldDimensions + 1])
                .listObjects<AttachmentStoreObject>()
                .reduce<string | undefined>(
                  (accumulator, { [Attachment_Rank]: rank }) => {
                    if (accumulator === undefined) {
                      return rank;
                    } else if (rank === undefined) {
                      return accumulator;
                    } else if (compareRank(rank, accumulator) > 0) {
                      return rank;
                    } else {
                      return accumulator;
                    }
                  },
                  undefined
                );
              return ranker.createNextRankGenerator(maxRank)();
            },
          },
          businessRules: [
            checkRankConflicts(
              (store, id) => store.withAssociation(Attachment)
                .withRole(Attachment_Role_FieldDimensions, id[Attachment_Role_FieldDimensions + 1])
                .listObjects(),
              Attachment_Rank
            ),
          ],
        })
        .relation({
          label: 'Attachment_UploadedBy',
          targetTypeId: User,
          reverseLabel: 'UploadedAttachments',
          businessRules: [
            checkUpdateOrigin(OriginSources.SYSTEM, 'Attachment_UploadedBy'),
          ],
        });
    },
  },
  handler: (objectStore, fieldId) => ({
    describe: () => ({ hasData: true, returnType: arrayOf(textType), timeseriesMode: 'none' }),
    restApi: {
      returnTypeSchema: {},
      formatValue: () => undefined,
    },
    getStoreValue: (dimensionsMapping) => getStoreValue(objectStore, dimensionsMapping),
    getValueWithoutFormula: (dimensionsMapping) => getStoreValue(objectStore, dimensionsMapping),
    getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
    updateValue: () => {
      throw newError('updateValue not supported');
    },
    resolvePathStepConfiguration: () => ({
      hasData: true,
      timeseriesMode: 'none',
      getValueResolutionType: () => arrayOf(textType),
      resolveValue: (dimensionsMapping, _, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack).value,
    }),
    isEmpty: (dimensionsMapping) => !getValueResolution(objectStore, fieldId, dimensionsMapping).value,
    isSaneValue: () => ({ isValid: true }),
    filterConditions: undefined,
  }),
});
