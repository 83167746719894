import type { FunctionComponent } from 'react';
import { addTimeToDate, dateFormats, DurationType, formatDisplayDate, isFiniteNumber, periodicities, PeriodicityType } from 'yooi-utils';
import InCompositeInput from '../../../app/_global/input/InCompositeInput';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useDerivedState from '../../../utils/useDerivedState';
import useSizeContext from '../../../utils/useSizeContext';
import SearchAndSelect from '../../molecules/SearchAndSelect';
import NumberPicker from '../NumberPicker';
import { DatePickerStrategy } from './datePickerUtils';

const useStyles = makeStyles({
  inputSpacings: {
    display: 'grid',
    gridAutoFlow: 'column',
    columnGap: spacingRem.xs,
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
  },
}, 'quarterPicker');

interface QuarterPickerProps {
  value: Date | undefined,
  onSubmit: (date: Date | undefined) => void,
  strategy?: DatePickerStrategy,
  periodicity?: PeriodicityType,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
}

const QuarterPicker: FunctionComponent<QuarterPickerProps> = ({ value, onSubmit, strategy = DatePickerStrategy.startOf, onEditionStart, onEditionStop }) => {
  const classes = useStyles();
  const { sizeVariant } = useSizeContext();

  const valueQuarter = value ? Math.floor(value.getMonth() / 3) + 1 : undefined;
  const valueYear = (value ?? new Date()).getFullYear();

  const [quarter, setQuarter, resetQuarter] = useDerivedState(() => (valueQuarter), [valueQuarter]);
  const [year, setYear, resetYear] = useDerivedState<number | undefined>(() => (valueYear), [valueYear]);

  const submitInvalidDate = () => {
    onSubmit(undefined);
  };

  const submitDate = ({ q, y }: { q: number | undefined, y: number | undefined }) => {
    if (q != null && y != null) {
      resetQuarter();
      resetYear();
      const submittedDate = new Date(y, (q - 1) * 3);
      // new Date(year, month) make year < 100 becoming 19XX need to set it after
      if (y < 100) {
        submittedDate.setFullYear(y);
      }
      if (strategy === DatePickerStrategy.endOf) {
        onSubmit(periodicities.quarter.getEndOfPeriod(submittedDate));
      } else {
        onSubmit(periodicities.quarter.getStartOfPeriod(submittedDate));
      }
    }
  };

  const computeSelectSize = () => {
    if (sizeVariant === 'main') {
      return { width: '21.5rem' };
    } else {
      return { width: '16.5rem' };
    }
  };
  const options = Array
    .from({ length: 4 }, (_, i) => i)
    .map((id) => {
      const d = periodicities[PeriodicityType.month].getStartOfPeriod(addTimeToDate(new Date(valueYear ?? new Date().getFullYear(), 0), id, DurationType.quarters));
      return ({ id, label: `${formatDisplayDate(d, dateFormats.quarter)} (${formatDisplayDate(d, dateFormats.month)})` });
    });

  return (
    <div
      className={classes.inputSpacings}
    >
      <div style={computeSelectSize()}>
        <SearchAndSelect
          placeholder={i18n`Quarter`}
          clearable
          onSelect={(v) => {
            const quarterToSubmit = v ? v.id + 1 : undefined;
            if (quarterToSubmit == null) {
              setQuarter(undefined);
              submitInvalidDate();
            } else {
              setQuarter(quarterToSubmit);
              submitDate({ q: quarterToSubmit, y: year });
            }
          }}
          computeOptions={() => options}
          selectedOption={quarter != null ? options[quarter - 1] : undefined}
          onEditionStart={onEditionStart}
          onEditionStop={onEditionStop}
        />
      </div>
      <InCompositeInput
        initialValue={year}
        setInputValue={(yearToSubmit) => {
          if (yearToSubmit == null || yearToSubmit > 3000 || yearToSubmit < 1) {
            setYear(undefined);
            submitInvalidDate();
          } else {
            setYear(yearToSubmit);
            submitDate({ q: quarter, y: yearToSubmit });
          }
        }}
      >
        {({ value: innerValue, onChange, ...props }) => (
          <NumberPicker
            min={1}
            max={3000}
            placeholder={i18n`yyyy`}
            withFormatting={false}
            onEditionStart={onEditionStart}
            onEditionStop={onEditionStop}
            value={innerValue}
            onChange={(v) => {
              if (isFiniteNumber(v) || v === undefined) {
                onChange(typeof v === 'string' ? parseInt(v, 10) : v);
              }
            }}
            {...props}
          />
        )}
      </InCompositeInput>
    </div>
  );
};

export default QuarterPicker;
