import type { FunctionComponent, ReactElement } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { ProgressFieldData } from '../../../../../components/charts/TimelineEntry';
import TimelineEntry from '../../../../../components/charts/TimelineEntry';
import useActivity from '../../../../../store/useActivity';
import useUpdateActivity from '../../../../../store/useUpdateActivity';
import { Spacing } from '../../../../../theme/spacingDefinition';
import makeStyles from '../../../../../utils/makeStyles';
import { remToPx } from '../../../../../utils/sizeUtils';
import SwimlaneConstants from './SwimlaneConstants';

const conceptHeightMarginRem = 0.2;
export const dragType = { concept: 'CONCEPT' };

const useStyles = makeStyles({
  container: {
    position: 'fixed',
  },
}, 'draggableItem');

interface DraggableItemProps {
  multiplayerIndicatorPropertyIds: string[],
  activityFieldId?: string,
  columnWidthPx: number,
  conceptWidthPx: number,
  progress: ProgressFieldData | undefined,
  index: number,
  columnIndex: number,
  concept: { id: string, label: { id?: string, name: string | undefined } },
  renderTooltip: (conceptId: string, editMode: boolean, currentAnchor: HTMLElement, handleClose: () => void, isChild?: boolean) => (ReactElement | null),
  colorationValue?: string,
  canDragItem?: boolean,
  type: string,
  groupHeight?: number,
  onDoubleClick: (id: string) => void,
}

const DraggableItem: FunctionComponent<DraggableItemProps> = ({
  multiplayerIndicatorPropertyIds,
  columnWidthPx,
  conceptWidthPx,
  progress,
  index,
  columnIndex,
  concept,
  renderTooltip,
  colorationValue,
  activityFieldId,
  canDragItem = false,
  type,
  groupHeight,
  onDoubleClick,
}) => {
  const classes = useStyles();

  const activity = useActivity();
  const updateActivity = useUpdateActivity();

  const [{ isDragging, isDraggingId }, dragRef] = useDrag(() => ({
    type,
    item: () => {
      if (activityFieldId) {
        updateActivity.onEnterEdition(concept.id, activityFieldId);
      }
      return { id: concept.id, conceptId: concept.id };
    },
    end: () => {
      if (activityFieldId) {
        updateActivity.onExitEdition(concept.id, activityFieldId);
      }
    },
    canDrag: canDragItem,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      isDraggingId: monitor.getItem()?.id,
    }),
  }));
  const [, drop] = useDrop(
    () => ({
      accept: type,
    })
  );

  const notDragged = isDraggingId !== undefined && isDraggingId !== concept.id;

  return (
    <foreignObject
      transform={`translate(${columnIndex * columnWidthPx},${(groupHeight ?? 0) + (index * remToPx(SwimlaneConstants.conceptGapRem + SwimlaneConstants.conceptHeightRem))})`}
      width={conceptWidthPx}
      height={remToPx(SwimlaneConstants.conceptHeightRem) + (remToPx(conceptHeightMarginRem) * 2)}
      style={{ pointerEvents: notDragged ? 'none' : 'all' }}
    >
      <div ref={(node) => (dragRef(drop(node)))} style={{ opacity: isDragging ? 0 : 1 }} className={classes.container}>
        <TimelineEntry
          id={concept.id}
          widthInPx={conceptWidthPx}
          renderTooltip={!isDragging ? renderTooltip : undefined}
          margin={{ x: Spacing.none, y: Spacing.xxs }}
          label={concept.label}
          rectColor={colorationValue}
          isEditedByOtherUser={multiplayerIndicatorPropertyIds.some((propertyId) => activity.listEditor(concept.id, propertyId).length > 0)}
          isDraggable={canDragItem}
          progress={progress}
          onDoubleClick={onDoubleClick}
        />
      </div>
    </foreignObject>
  );
};

export default DraggableItem;
