import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { getAllFieldDimensionsIds, getConceptDefinitionValidFields, isRelationalType } from 'yooi-modules/modules/conceptModule';
import { Field_ApiAlias, Field_Formula, Field_IsCore, Field_Title } from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import Tooltip from '../../../components/atoms/Tooltip';
import Typo from '../../../components/atoms/Typo';
import MasterDetailList from '../../../components/molecules/MasterDetailList';
import OverflowMenu from '../../../components/molecules/OverflowMenu';
import SpacingLine from '../../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../../components/molecules/Table';
import BaseLayout from '../../../components/templates/BaseLayout';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import Header from '../../../components/templates/Header';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import LeftPanel from '../../../components/templates/LeftPanel';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { safeSessionStorageValue } from '../../../utils/sessionStorageUtils';
import { formatOrUndef } from '../../../utils/stringUtils';
import useDeleteModal from '../../../utils/useDeleteModal';
import useNavigation, { NavigationElementContainer } from '../../../utils/useNavigation';
import { SessionStorageKeys, useSessionStorageState } from '../../../utils/useSessionStorage';
import { getFieldConfigurationHandler } from '../../_global/fields/FieldLibrary';
import { FieldEditionOptionMode } from '../../_global/fields/FieldLibraryTypes';
import { getFieldLabel, isFilteredField } from '../../_global/fieldUtils';
import { useFilterStorage } from '../../_global/filter/useFilterSessionStorage';
import HeaderFromStore, { HeaderFromStoreVariant } from '../../_global/HeaderFromStore';
import { searchFilterFunction } from '../../_global/listFilterFunctions';
import type { NavigationFilter } from '../../_global/navigationUtils';
import { NavigationTitlePathElements } from '../../_global/navigationUtils';
import TopBar from '../../_global/topBar/TopBar';
import useFilterAndSort, { buildStringColumnComparatorHandler } from '../../_global/useFilterAndSort';
import FieldOptionBlocks from './detail/__global/FieldOptionBlocks';

const useStyles = makeStyles({
  masterDetailItemContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.s,
  },
}, 'fieldEditorPage');

interface FieldEditorPageProps {
  conceptDefinitionId: string,
  fieldId: string,
}

interface FieldFilterConfiguration {
  nameSearch?: string,
  typeFilters?: string[],
  showMultidimensional?: boolean,
  showComputed?: boolean,
}

interface ListItem extends Record<string, unknown> {
  key: string,
  field: FieldStoreObject,
  title: string | undefined,
  type: string | undefined,
}

const FieldEditorPage: FunctionComponent<FieldEditorPageProps> = ({ conceptDefinitionId, fieldId }) => {
  const classes = useStyles();

  const store = useStore();

  const navigation = useNavigation<NavigationFilter>();

  const field = store.getObject<FieldStoreObject>(fieldId);

  const [doDelete, deleteModal] = useDeleteModal({
    doDelete: () => {
      store.deleteObject(fieldId);
      navigation.push(fieldId, { pathname: `/settings/organization/${conceptDefinitionId}`, hash: 'fields' });
    },
    shouldConfirm: () => true,
    getModalProps: () => ({
      title: i18n`Are you sure that you want to delete this field?`,
      content: (<Typo>{i18n`It will also remove all associated elements.`}</Typo>),
    }),
  });

  const getFieldName = (id: string): string => formatOrUndef(getFieldConfigurationHandler(store, id)?.getTypeLabel());

  const fieldConfigurationHandler = getFieldConfigurationHandler(store, fieldId);

  const filterId = `${conceptDefinitionId}_library`;
  const [typeFilters = []] = useFilterStorage<'typeFilters', FieldFilterConfiguration>(filterId, 'typeFilters');
  const existingFieldDefinitionIds = Array.from(new Set(getConceptDefinitionValidFields(store, conceptDefinitionId).map(({ [Instance_Of]: instanceOf }) => instanceOf)));
  const actualTypeFilters = typeFilters.filter((id) => existingFieldDefinitionIds.includes(id));

  const [showMultidimensional = false] = useFilterStorage<'showMultidimensional', FieldFilterConfiguration>(filterId, 'showMultidimensional');
  const [showComputed = false] = useFilterStorage<'showComputed', FieldFilterConfiguration>(filterId, 'showComputed');
  const [showDeveloperMode] = useSessionStorageState(SessionStorageKeys.developerMode, false);

  const [search, setSearch] = useState<string | undefined>(undefined);
  const filterFunction = searchFilterFunction<ListItem>(store, [safeSessionStorageValue<FieldFilterConfiguration | undefined>(filterId)?.nameSearch, search], ['title', 'type', 'apiAlias']);
  const { generateList } = useFilterAndSort(
    filterId,
    getConceptDefinitionValidFields(store, conceptDefinitionId)
      .map((f) => ({
        key: f.id,
        field: f,
        title: getFieldLabel(store, f),
        type: formatOrUndef(getFieldConfigurationHandler(store, f.id)?.getTypeLabel()),
        apiAlias: f[Field_ApiAlias],
      })),
    (item) => (
      (filterFunction?.(item) ?? true)
      && (actualTypeFilters.length === 0 || actualTypeFilters.includes(item.field[Instance_Of]))
      && (!showMultidimensional || getAllFieldDimensionsIds(store, item.field.id).length > 1)
      && (!showComputed || !!item.field[Field_Formula])
    ),
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case 'title':
          case 'type':
          case 'apiAlias':
            return buildStringColumnComparatorHandler(key, direction);
          default:
            return undefined;
        }
      },
      initial: { key: 'title', direction: TableSortDirection.asc },
    },
    undefined,
    [search]
  );
  const icon = getFieldConfigurationHandler(store, fieldId)?.getIcon();
  return (
    <NavigationElementContainer
      element={{
        key: fieldId,
        name: formatOrUndef(field[Field_Title]),
        element: NavigationTitlePathElements.organizationInstance,
        icon: typeof icon === 'object' ? icon.name : icon,
        path: `/settings/organization/${conceptDefinitionId}/field/${fieldId}`,
      }}
    >
      <BaseLayout
        topBar={(<TopBar />)}
        leftPanel={(
          <LeftPanel>
            <MasterDetailList
              list={
                generateList().list
                  .map(({ item, ...entry }) => (
                    joinObjects(
                      entry,
                      {
                        render: () => {
                          const currentField = item.field;
                          const fieldDefinitionHandler = getFieldConfigurationHandler(store, currentField.id);
                          const fieldIcon = fieldDefinitionHandler.getIcon();
                          return (
                            <div className={classes.masterDetailItemContainer}>
                              <Icon name={typeof fieldIcon === 'object' ? fieldIcon.name : fieldIcon ?? IconName.dangerous} tooltip={getFieldName(currentField.id)} />
                              {
                                getAllFieldDimensionsIds(store, currentField.id).length > 1
                                  ? (<Icon name={IconName.pivot_table_chart} tooltip={i18n`Multidimensional`} colorVariant={IconColorVariant.info} />) : null
                              }
                              {isFilteredField(store, currentField.id) ? (
                                <Icon name={IconName.filter_list} tooltip={i18n`Display filters applied on field.`} colorVariant={IconColorVariant.info} />) : null}
                              {currentField[Field_Formula] ? (<Icon name={IconName.fx} tooltip={i18n`Computed`} colorVariant={IconColorVariant.info} />) : null}
                              <Tooltip title={item.title}>
                                <Typo maxLine={1}>{item.title}</Typo>
                              </Tooltip>
                            </div>
                          );
                        },
                        to: () => ({ pathname: `/settings/organization/${conceptDefinitionId}/field/${item.field.id}` }),
                      }
                    )
                  ))
              }
              search={{ value: search, setValue: setSearch }}
            />
          </LeftPanel>
        )}
        header={(
          <Header
            firstLine={(
              <HeaderFromStore
                instanceId={fieldId}
                propertyId={Field_Title}
                placeholder={isRelationalType(field[Instance_Of]) ? getFieldLabel(store, field) : i18n`Click to edit name`}
                variant={HeaderFromStoreVariant.compact}
                actions={[
                  (
                    <OverflowMenu
                      key="overflowMenu"
                      menuItems={[
                        {
                          key: 'duplicate',
                          name: i18n`Duplicate`,
                          icon: IconName.content_copy_outline,
                          hidden: !!field[Field_IsCore] || fieldConfigurationHandler.duplicateFieldDefinition === undefined,
                          onClick: () => {
                            const newId = fieldConfigurationHandler.duplicateFieldDefinition?.({ parameterMap: {} });
                            navigation.push(newId ?? '', { pathname: `/settings/organization/${conceptDefinitionId}/field/${newId}` });
                          },
                        },
                        {
                          key: 'delete',
                          name: i18n`Delete`,
                          icon: IconName.delete,
                          hidden: !!field[Field_IsCore],
                          onClick: doDelete,
                          danger: true,
                        },
                      ]}
                    />
                  ),
                ]}
              />
            )}
          />
        )}
        content={(
          <>
            <FieldOptionBlocks
              conceptDefinitionId={conceptDefinitionId}
              fieldId={fieldId}
              mode={showDeveloperMode ? FieldEditionOptionMode.FieldDeveloperMode : FieldEditionOptionMode.Field}
              headBlocks={(
                <VerticalBlock asBlockContent withSeparation>
                  <HorizontalBlock asBlockContent>
                    <BlockTitle title={i18n`Type`} />
                    <BlockContent padded>
                      <SpacingLine>
                        <Typo>{getFieldName(fieldId)}</Typo>
                      </SpacingLine>
                    </BlockContent>
                  </HorizontalBlock>
                </VerticalBlock>
              )}
            />
            {deleteModal}
          </>
        )}
      />
    </NavigationElementContainer>
  );
};

export default FieldEditorPage;
