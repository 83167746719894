import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useRef } from 'react';
import type { GraphChartFieldConfiguration } from 'yooi-modules/modules/dashboardModule';
import Icon, { IconName } from '../../../../../components/atoms/Icon';
import Typo from '../../../../../components/atoms/Typo';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import base from '../../../../../theme/base';
import { spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import { OverlayContextProvider } from '../../../../../utils/useOverlayContainerRef';
import useTheme from '../../../../../utils/useTheme';
import ArcherContainer from '../archer/ArcherContainer';
import GraphChartColumnConfiguration from './GraphChartColumnConfiguration';

const COLUMN_IN_REM = 27;
const GAP_IN_REM = 2;

const useStyles = makeStyles((theme) => ({
  paddedContainer: {
    display: 'grid',
    padding: spacingRem.xs,
    borderColor: theme.color.transparent,
    borderStyle: 'solid',
    borderWidth: '0.2rem',
  },
  lastColumnContainer: {
    height: '9.6rem',
  },
  scrollContainer: {
    overflowX: 'scroll',
    padding: spacingRem.xs,
  },
  container: {
    display: 'grid',
    gap: `${GAP_IN_REM}rem`,
    gridAutoFlow: 'column',
    gridAutoColumns: `${COLUMN_IN_REM}rem`,
  },
  addCard: {
    cursor: 'pointer',
    display: 'flex',
    height: '4.2rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: base.borderRadius.medium,
    borderStyle: 'dashed',
    borderWidth: '0.1rem',
    backgroundColor: theme.color.transparent,
    borderColor: theme.color.border.primarylight,
    '&:hover': {
      backgroundColor: theme.color.background.primarylight.default,
    },
    '&:focus-visible': {
      backgroundColor: theme.color.background.primarylight.default,
    },
    '&:active': {
      backgroundColor: theme.color.background.primarylight.hover,
    },
  },
}), 'graphChartColumnsConfiguration');

interface GraphChartColumnsConfigurationProps {
  config?: GraphChartFieldConfiguration,
  graphStyle: 'center' | 'top',
  selectedBlockId: string | undefined,
  onBlockSelected: (id: string) => void,
  onAddBlock: (columnId?: string) => void,
  onDeleteBlock: (columnId: string, blockId: string) => void,
  onDuplicateBlock: (columnId: string, blockId: string) => void,
  onMoveBlockLeft: (blockId: string) => void,
  onMoveBlockRight: (blockId: string) => void,
}

const GraphChartColumnsConfiguration: FunctionComponent<GraphChartColumnsConfigurationProps> = ({
  config,
  graphStyle,
  selectedBlockId,
  onBlockSelected,
  onAddBlock,
  onDeleteBlock,
  onDuplicateBlock,
  onMoveBlockLeft,
  onMoveBlockRight,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const overlayContainerRef = useRef<HTMLDivElement>(null);

  const columns = config?.columns ?? [];

  const addBlockButton = (onClick: () => void) => (
    <button
      type="button"
      onKeyDown={(e) => {
        if (e.key !== 'Escape') {
          e.stopPropagation();
        }
      }}
      onKeyUp={(e) => e.stopPropagation()}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      className={classes.addCard}
      title={i18n`Add`}
    >
      <SpacingLine>
        <Icon color={theme.color.text.brand} name={IconName.add} />
        <Typo color={theme.color.text.brand}>{i18n`Add`}</Typo>
      </SpacingLine>
    </button>
  );

  const totalWidthInRem = ((COLUMN_IN_REM + GAP_IN_REM) * columns.length);

  return (
    <div className={classes.scrollContainer}>
      <ArcherContainer style={{ width: `${totalWidthInRem}rem` }}>
        <div
          ref={overlayContainerRef}
          className={classes.container}
          style={graphStyle === 'top' ? undefined : { alignItems: 'center' }}
        >
          <OverlayContextProvider containerRef={overlayContainerRef}>
            {columns.map((column, index, self) => (
              <GraphChartColumnConfiguration
                key={column.id}
                column={column}
                previousColumn={self[index - 1]}
                selectedBlockId={selectedBlockId}
                onBlockSelected={onBlockSelected}
                onAddBlock={onAddBlock}
                onDeleteBlock={onDeleteBlock}
                onDuplicateBlock={onDuplicateBlock}
                onMoveBlockLeft={onMoveBlockLeft}
                onMoveBlockRight={onMoveBlockRight}
              />
            ))}
            <div className={classnames(classes.paddedContainer, classes.lastColumnContainer)}>
              {addBlockButton(() => onAddBlock())}
            </div>
          </OverlayContextProvider>
        </div>
      </ArcherContainer>
    </div>
  );
};

export default GraphChartColumnsConfiguration;
