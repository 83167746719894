import type { FunctionComponent, ReactElement } from 'react';
import { createContext, useContext, useState } from 'react';
import { useDragLayer } from 'react-dnd';
import declareBus from '../../../../utils/declareBus';
import useDeepMemo from '../../../../utils/useDeepMemo';
import type { DropPlacement } from '../filterUtils';
import DragItemPreview from './DragItemPreview';

interface DragAndDropManagerProps {
  onDrop: (droppedFilterId: string, targetFilterId: string, placement: DropPlacement) => void,
  canDrop: (droppedFilterId: string, targetFilterId: string) => boolean,
  children: ReactElement,
}

type HoverState = { draggingItemId: string, itemId: string, dropPlacement: DropPlacement } | undefined;

type DragAndDropContextType = { draggingItemId: string, hoverState: HoverState } | undefined;

interface NotifyOnHoverEvent {
  itemId: string,
  dropPlacement: DropPlacement,
}

interface NotifyOnDragEndEvent {
  itemId: string,
}

const DragAndDropContext = createContext<DragAndDropContextType>(undefined);

export const useDragAndDropContext = (): DragAndDropContextType => useContext(DragAndDropContext);

export const useNotifyOnHoverBus = declareBus<NotifyOnHoverEvent>('notifyOnHover').useBus;
export const useNotifyOnDragEndBus = declareBus<NotifyOnDragEndEvent>('notifyOnDragEnd').useBus;

const DragAndDropManager: FunctionComponent<DragAndDropManagerProps> = ({ onDrop, canDrop, children }) => {
  const { draggingItemId } = useDragLayer((monitor) => ({
    draggingItemId: monitor.getItem()?.id,
  }));

  const [hoverState, setHoverState] = useState<HoverState>(undefined);

  useNotifyOnHoverBus(({ itemId, dropPlacement }) => {
    setHoverState({ draggingItemId, itemId, dropPlacement });
  });

  useNotifyOnDragEndBus(({ itemId }) => {
    if (hoverState && canDrop(itemId, hoverState.itemId)) {
      onDrop(itemId, hoverState.itemId, hoverState.dropPlacement);
    }
    setHoverState(undefined);
  });

  const context = useDeepMemo<DragAndDropContextType>(() => {
    if (draggingItemId) {
      return { draggingItemId, hoverState };
    }
    return undefined;
  }, [draggingItemId, hoverState]);

  return (
    <DragAndDropContext.Provider value={context}>
      {children}
      <DragItemPreview />
    </DragAndDropContext.Provider>
  );
};

export default DragAndDropManager;
