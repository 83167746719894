import { createFieldDsl } from '../common/fields/FieldModuleDsl';
import { createModule } from '../common/types/TypeModuleDsl';
import * as DataAssetModuleIds from './ids';
import { DataAssetModuleId } from './ids';
import migrations from './migrations';

const moduleDsl = createModule({ label: 'DataAsset' }, DataAssetModuleIds);
export const { registerField } = createFieldDsl(moduleDsl);

const { initTypedModule, ...registerModelDsl } = moduleDsl;
export const registerModel = registerModelDsl;

export const initModule = initTypedModule(() => ({
  id: DataAssetModuleId,
  migrations,
}));
