import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import type { SingleParameterDefinition, PathStep, MultipleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { getInstanceLabelOrUndefined } from 'yooi-modules/modules/conceptModule';
import { ConceptDefinition_Roles, Group, User } from 'yooi-modules/modules/conceptModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import Typo from '../../../../components/atoms/Typo';
import CompositeField, { CompositeFieldCloseReasons, CompositeFieldVariants, DropdownSectionTitleVariants } from '../../../../components/molecules/CompositeField';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SearchAndSelectMultiple from '../../../../components/molecules/SearchAndSelectMultiple';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import useDerivedState from '../../../../utils/useDerivedState';
import useTheme from '../../../../utils/useTheme';
import { chipDefaultBackgroundColor } from '../../conceptDisplayUtils';
import type { Option } from '../../modelTypeUtils';
import { getChipOptions } from '../../modelTypeUtils';
import PathStepsInput from '../../path/PathStepsInput';
import { createPathConfigurationHandler } from '../../pathConfigurationHandler';
import { getConceptTypeValidator } from '../../pathConfigurationHandlerUtils';

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: spacingRem.s,
  },
  textContainer: {
    display: 'flex',
    flexGrow: 1,
    gap: spacingRem.xs,
    alignItems: 'center',
  },
  valueContainer: {
    display: 'flex',
    gap: spacingRem.s,
    flexWrap: 'wrap',
  },
}, 'stakeholdersFieldInputRenderer');

const userOption: Option = { id: 'user', label: i18n`User(s)` };
const groupOption: Option = { id: 'group', label: i18n`Group(s)` };
const allRoleOption: Option = { label: i18n`All roles`, id: 'all', color: chipDefaultBackgroundColor };

type Value = { type: 'value', roleId?: string, valueType: 'user' | 'group', objectIds: string[] } | {
  type: 'path',
  roleId?: string,
  valueType: 'user' | 'group',
  path: PathStep[],
};

const isEmptyValue = (value: Value) => {
  if (value.type === 'path') {
    return value.path.length === 0;
  } else {
    return value.objectIds.length === 0;
  }
};

interface StakeholdersFieldInputRendererProps {
  initialValue: Value[],
  onSubmit: (newValue: Value[]) => void,
  readOnly?: boolean,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  conceptDefinitionId: string,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
  getPlaceholder: (isUser: boolean) => string,
  isDeletion?: boolean,
}

const StakeholdersFieldInputRenderer: FunctionComponent<StakeholdersFieldInputRendererProps> = ({
  initialValue,
  onSubmit,
  readOnly = false,
  onEditionStart,
  onEditionStop,
  conceptDefinitionId,
  parameterDefinitions,
  getPlaceholder,
  isDeletion = false,
}) => {
  const store = useStore();
  const theme = useTheme();
  const classes = useStyles();

  const userPathConfigurationHandler = createPathConfigurationHandler(store, parameterDefinitions, [getConceptTypeValidator(store, true, User)]);
  const groupPathConfigurationHandler = createPathConfigurationHandler(store, parameterDefinitions, [getConceptTypeValidator(store, true, Group)]);

  const [openOnMountKey, setOpenOnMountKey] = useState<string | undefined>(undefined);
  const [values, setValues] = useDerivedState<(Value & { key: string })[]>(() => (
    initialValue.map((v) => (v.type === 'value' ? (joinObjects(v, { key: uuid() })) : joinObjects(v, { key: uuid() })))
  ), [initialValue]);
  const getValueLabel = (v: Value): string => {
    if (v.type === 'value') {
      const instances = v.objectIds.map((id) => store.getObjectOrNull(id)).filter(filterNullOrUndefined);
      if (instances.length > 3) {
        return i18n`${getInstanceLabelOrUndefined(store, instances[0])} + ${instances.length - 1} more`;
      } else {
        return instances.map((instance) => getInstanceLabelOrUndefined(store, instance)).join(', ');
      }
    } else {
      return v.valueType === 'user' ? userOption.label : groupOption.label;
    }
  };

  return (
    <div className={classes.valueContainer}>
      {values.map((value) => {
        const { roleId, type, valueType, key } = value;
        let roleOption: Option | undefined;
        let roleLabel: string | undefined;
        if (roleId === 'all') {
          roleOption = allRoleOption;
          roleLabel = i18n`all roles`;
        } else if (roleId) {
          const role = roleId ? store.getObjectOrNull(roleId) : undefined;
          roleLabel = role ? getInstanceLabelOrUndefined(store, role) : roleLabel;
          roleOption = getChipOptions(store, roleId);
        }
        return (
          <CompositeField
            readOnly={readOnly}
            key={key}
            flexGrow={0}
            headerLinesRenderers={[{
              id: 'title',
              render: (inDropdown) => (
                <div className={classes.titleContainer}>
                  <div className={classes.textContainer}>
                    {roleId && !isEmptyValue(value) ? (
                      <>
                        <Typo maxLine={1} noWrap fullWidth>
                          {getValueLabel(value)}
                        </Typo>
                        <Typo maxLine={1} noWrap fullWidth color={theme.color.text.secondary}>
                          {!isDeletion ? i18n`as` : i18n`from`}
                        </Typo>
                        <Typo maxLine={1} noWrap fullWidth>
                          {roleLabel}
                        </Typo>
                      </>
                    ) : (
                      <Typo maxLine={1} noWrap fullWidth color={theme.color.text.secondary}>
                        {getPlaceholder(valueType === 'user')}
                      </Typo>
                    )}
                  </div>
                  {inDropdown && (
                    <IconOnlyButton
                      tooltip={i18n`Delete`}
                      iconName={IconName.delete}
                      onClick={() => {
                        onSubmit(values.filter((v) => v.key !== key));
                      }}
                      variant={IconOnlyButtonVariants.secondary}
                    />
                  )}
                </div>
              ),
            }]}
            getDropdownSectionDefinitions={
              () => {
                const valueOption: Option = { label: i18n`Value(s)`, id: 'value' };
                const pathOption: Option = { label: i18n`Path`, id: 'path' };
                return [{
                  id: 'section',
                  lines: [
                    {
                      id: 'values',
                      title: (
                        <SearchAndSelect
                          selectedOption={value.type === 'value' ? valueOption : pathOption}
                          computeOptions={() => [valueOption, pathOption]}
                          onSelect={(option) => {
                            setValues((current) => current.map((v) => {
                              if (v.key === key) {
                                if (option?.id === 'value') {
                                  return joinObjects(v, { type: 'value' as const, objectIds: [] });
                                } else if (option?.id === 'path') {
                                  return joinObjects(v, { type: 'path' as const, path: [] });
                                }
                              }
                              return v;
                            }));
                          }}
                        />
                      ),
                      render: type === 'value' ? (
                        <SearchAndSelectMultiple
                          forceSingleLine
                          selectedOptions={value.objectIds.map((id) => getChipOptions(store, id)).filter(filterNullOrUndefined)}
                          computeOptions={() => (
                            store.getObject(valueType === 'user' ? User : Group)
                              .navigateBack(Class_Instances)
                              .map((instance) => getChipOptions(store, instance.id))
                              .filter(filterNullOrUndefined)
                          )}
                          onDelete={(option) => {
                            setValues((current) => current.map((v) => {
                              if (v.key === key && v.type === 'value') {
                                return joinObjects(v, { objectIds: v.objectIds.filter((id) => id !== option.id) });
                              } else {
                                return v;
                              }
                            }));
                          }}
                          onSelect={(option) => {
                            setValues((current) => current.map((v) => {
                              if (v.key === key && v.type === 'value') {
                                return joinObjects(v, { objectIds: [...v.objectIds, option.id] });
                              } else {
                                return v;
                              }
                            }));
                          }}
                          readOnly={readOnly}
                        />
                      ) : (
                        <PathStepsInput
                          initialPath={value.path}
                          onSubmit={(newPath) => {
                            setValues((current) => current.map((v) => (v.key === key && v.type === 'path' ? joinObjects(v, { path: newPath }) : v)));
                          }}
                          parameterDefinitions={parameterDefinitions}
                          valuePathHandler={valueType === 'user' ? userPathConfigurationHandler : groupPathConfigurationHandler}
                          readOnly={readOnly}
                        />
                      ),
                    }, {
                      id: 'role',
                      titlePadded: true,
                      titleVariant: DropdownSectionTitleVariants.secondary,
                      title: i18n`As`,
                      render: (
                        <SearchAndSelect
                          selectedOption={roleOption}
                          computeOptions={() => {
                            const roleOptions = store.getObjectOrNull(conceptDefinitionId)
                              ?.navigateBack(ConceptDefinition_Roles)
                              .map((conceptRole) => getChipOptions(store, conceptRole.id))
                              .filter(filterNullOrUndefined) ?? [];
                            return !isDeletion ? roleOptions : [allRoleOption, ...roleOptions];
                          }}
                          onSelect={(option) => {
                            if (option) {
                              setValues((current) => current.map((v) => {
                                if (v.key === key) {
                                  return v.type === 'value' ? joinObjects(v, { roleId: option.id }) : joinObjects(v, { roleId: option.id });
                                } else {
                                  return v;
                                }
                              }));
                            }
                          }}
                          readOnly={readOnly}
                        />
                      ),
                    }],
                }];
              }
            }
            onOpenDropdown={
              () => {
                onEditionStart?.();
                if (key === openOnMountKey) {
                  setOpenOnMountKey(undefined);
                }
              }
            }
            onCloseDropdown={
              (reason) => {
                onEditionStop?.();
                if (reason !== CompositeFieldCloseReasons.cancel) {
                  onSubmit(values);
                }
              }
            }
            openOnMount={openOnMountKey === key}
            variant={CompositeFieldVariants.button}
          />
        );
      })}
    </div>
  );
};

export default StakeholdersFieldInputRenderer;
