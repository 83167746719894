import type { FunctionComponent } from 'react';
import type { FieldDefinitionStoreObject, FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { getConceptDefinitionValidFields, isConceptDefinitionField } from 'yooi-modules/modules/conceptModule';
import { Field_IntegrationOnly, FieldDefinition_Title } from 'yooi-modules/modules/conceptModule/ids';
import { Template_Integrations } from 'yooi-modules/modules/integrationModule/ids';
import type { TemplateConceptDefinitionStoreObject, TemplateFieldStoreObject } from 'yooi-modules/modules/templateModule';
import { TemplateFieldMode } from 'yooi-modules/modules/templateModule';
import {
  Field_MappedTemplateFields,
  TemplateConceptDefinition_MappedConceptDefinition,
  TemplateConceptDefinition_Template,
  TemplateConceptDefinition_TemplateFields,
  TemplateField_MappedField,
  TemplateField_Mode,
  TemplateField_Name,
  TemplateField_TemplateConceptDefinition,
  TemplateField_Type,
} from 'yooi-modules/modules/templateModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareString, comparing } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import TextInputString from '../../../components/inputs/TextInputString';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import { TableSortDirection } from '../../../components/molecules/Table';
import BlockTitle from '../../../components/templates/BlockTitle';
import DataTable from '../../../components/templates/DataTable';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { formatOrUndef } from '../../../utils/stringUtils';
import { getFieldDefinitionHandler } from '../../_global/fields/FieldLibrary';
import { getFieldChip, getFieldLabel } from '../../_global/fieldUtils';
import { defaultOptionComparator } from '../../_global/modelTypeUtils';
import type { ComparatorHandler } from '../../_global/useFilterAndSort';
import useFilterAndSort, { buildStringColumnComparatorHandler } from '../../_global/useFilterAndSort';

interface IntegrationTemplateFieldMappingBlockProps {
  templateConceptDefinitionId: string,
}

const IntegrationTemplateFieldMappingBlock: FunctionComponent<IntegrationTemplateFieldMappingBlockProps> = ({ templateConceptDefinitionId }) => {
  const store = useStore();

  const templateConceptDefinition = store.getObject<TemplateConceptDefinitionStoreObject>(templateConceptDefinitionId);

  const { generateList, sortCriteria, doSort } = useFilterAndSort(
    `${templateConceptDefinitionId}_${TemplateConceptDefinition_TemplateFields}`,
    templateConceptDefinition.navigateBack<TemplateFieldStoreObject>(TemplateConceptDefinition_TemplateFields),
    undefined,
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case TemplateField_Name:
            return buildStringColumnComparatorHandler(key, direction);
          case TemplateField_Type:
            return {
              comparator: comparing(compareString, direction === TableSortDirection.desc),
              extractValue: (templateField) => templateField.navigate<FieldDefinitionStoreObject>(TemplateField_Type)[FieldDefinition_Title],
            } satisfies ComparatorHandler<TemplateFieldStoreObject, string | undefined>;
          case TemplateField_MappedField:
            return {
              comparator: comparing(compareString, direction === TableSortDirection.desc),
              extractValue: (templateField) => {
                const mappedFieldId = templateField[TemplateField_MappedField];
                const mappedField = mappedFieldId ? store.getObjectOrNull<FieldStoreObject>(mappedFieldId) : undefined;
                return mappedField ? getFieldLabel(store, mappedField) : formatOrUndef(undefined);
              },
            };
          default:
            return undefined;
        }
      },
      initial: { key: TemplateField_Name, direction: TableSortDirection.asc },
    }
  );

  return (
    <VerticalBlock asBlockContent>
      <BlockTitle title={i18n`Field mapping`} />
      <DataTable
        columnsDefinition={[
          {
            propertyId: TemplateField_Name,
            name: i18n`Name`,
            width: 15,
            sortable: true,
            cellRender: ({ [TemplateField_Name]: name }) => (<TextInputString value={formatOrUndef(name)} readOnly />),
          },
          {
            propertyId: TemplateField_Type,
            name: i18n`Type`,
            width: 15,
            sortable: true,
            cellRender: (templateField) => (
              <TextInputString value={formatOrUndef(templateField.navigate<FieldDefinitionStoreObject>(TemplateField_Type)[FieldDefinition_Title])} readOnly />),
          },
          {
            propertyId: TemplateField_MappedField,
            name: i18n`Mapping`,
            width: 70,
            sortable: true,
            cellRender: (templateField) => {
              const field = templateField.navigateOrNull<FieldStoreObject>(TemplateField_MappedField);
              const fieldId = field?.id;
              const conceptDefinitionId = templateConceptDefinition[TemplateConceptDefinition_MappedConceptDefinition] as string;

              let error;
              if (field) {
                const belongToSelectConceptDefinition = isConceptDefinitionField(store, field.id, conceptDefinitionId);
                if (!belongToSelectConceptDefinition) {
                  error = i18n`Field does not belong to the selected concept.`;
                } else if (templateField[TemplateField_Mode] === TemplateFieldMode.WRITE && !field[Field_IntegrationOnly]) {
                  error = i18n`Field should be marked as integration only.`;
                } else if (templateField[TemplateField_Mode] !== TemplateFieldMode.READ
                  && field.navigateBack(Field_MappedTemplateFields)
                    .filter((tf) => (tf.navigateOrNull(TemplateField_TemplateConceptDefinition)
                      ?.navigateOrNull(TemplateConceptDefinition_Template)
                      ?.navigateBack(Template_Integrations).length ?? 0) > 0)
                    .filter((tf) => tf[TemplateField_Mode] !== TemplateFieldMode.READ)
                    .filter((tf) => tf.navigateOrNull(TemplateField_TemplateConceptDefinition)
                      ?.navigateOrNull(TemplateConceptDefinition_Template)
                      ?.navigateBack(Template_Integrations)?.length)
                    .length > 1
                ) {
                  error = i18n`Multiple integration write to the selected field. Conflicts might happen.`;
                }
              }

              const inlineCreate = getFieldDefinitionHandler(store, templateField[TemplateField_Type])?.inlineCreate;
              return (
                <SearchAndSelect
                  computeOptions={() => getConceptDefinitionValidFields(store, conceptDefinitionId)
                    .filter((conceptDefinitionField) => conceptDefinitionField[Instance_Of] === templateField[TemplateField_Type])
                    .map(({ id }) => getFieldChip(store, conceptDefinitionId, id))
                    .sort(defaultOptionComparator)}
                  selectedOption={fieldId && store.getObjectOrNull(fieldId) !== null ? getFieldChip(store, conceptDefinitionId, fieldId) : undefined}
                  onSelect={(newSelection) => {
                    store.updateObject(templateField.id, { [TemplateField_MappedField]: newSelection?.id ?? null });
                  }}
                  statusIcon={error ? { icon: IconName.dangerous, color: IconColorVariant.error, message: error } : undefined}
                  getInlineCreation={
                    inlineCreate
                      ? () => {
                        const inlineCreateOption = inlineCreate(
                          templateConceptDefinition[TemplateConceptDefinition_MappedConceptDefinition] as string,
                          { [Field_IntegrationOnly]: templateField[TemplateField_Mode] === TemplateFieldMode.WRITE }
                        );

                        if (inlineCreateOption.type === 'inline') {
                          return {
                            type: 'inline',
                            onCreate: (title) => {
                              const newFieldId = inlineCreateOption.onCreate(title);
                              store.updateObject(templateField.id, { [TemplateField_MappedField]: newFieldId });
                              return newFieldId;
                            },
                          };
                        } else {
                          return {
                            type: 'transactional',
                            getInitialState: inlineCreateOption.getInitialState,
                            getChipLabel: inlineCreateOption.getChipLabel,
                            creationOptions: inlineCreateOption.creationOptions,
                            onCreate: (creationOptionState) => {
                              const newFieldId = inlineCreateOption.onCreate(creationOptionState);
                              store.updateObject(templateField.id, { [TemplateField_MappedField]: newFieldId });
                              return newFieldId;
                            },
                          };
                        }
                      }
                      : undefined
                  }
                  clearable
                />
              );
            },
          },
        ]}
        list={generateList().list}
        sortCriteria={sortCriteria}
        doSort={doSort}
      />
    </VerticalBlock>
  );
};

export default IntegrationTemplateFieldMappingBlock;
