import type { FieldBlockDisplayOptions, FieldBlockDisplayStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import type {
  AssociationFilterStoreObject,
  ConceptDefinitionStoreObject,
  ConditionFilterStoreObject,
  Filters,
  MultipleRelationFieldExportConfiguration,
  RelationMultipleFieldRaw,
  RelationMultipleFieldStoreObject,
  RelationMultipleUpdate,
  RelationSingleFieldRaw,
  RelationSingleFieldStoreObject,
} from 'yooi-modules/modules/conceptModule';
import { getFieldUtilsHandler, getInstanceLabelOrUndefined, ParsedDimensionType, parseDimensionMapping, relationMultipleFieldHandler } from 'yooi-modules/modules/conceptModule';
import {
  Concept,
  ConceptDefinition,
  ConceptDefinition_ChipBackgroundColor,
  ConceptDefinition_Icon,
  ConceptDefinition_RestrictedAccess,
  Field_ApiAlias,
  Field_Documentation,
  Field_IntegrationOnly,
  Field_IsCore,
  Field_IsDocumentationInline,
  Field_Title,
  FieldBlockDisplay_ViewFilters,
  RelationMultipleField,
  RelationMultipleField_Field_TargetFilter,
  RelationMultipleField_ReverseField,
  RelationMultipleField_TargetType,
  RelationSingleField,
  RelationSingleField_TargetType,
} from 'yooi-modules/modules/conceptModule/ids';
import type { ViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import { Dashboard } from 'yooi-modules/modules/dashboardModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStore } from 'yooi-store';
import { compareArray, compareString, comparing, filterNullOrUndefined, joinObjects, pushUndefinedToEnd } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import SimpleInput from '../../../../components/inputs/strategy/SimpleInput';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../../../components/molecules/Table';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { createConceptDefinition } from '../../conceptDefinitionUtils';
import { computeBorderColor, conceptDefinitionChipBackgroundColor } from '../../conceptDisplayUtils';
import { defaultOptionComparator, getChipOptions, getObjectName } from '../../modelTypeUtils';
import { createPathConfigurationHandler } from '../../pathConfigurationHandler';
import { getConceptTypeValidator } from '../../pathConfigurationHandlerUtils';
import { getApiAliasInitialState, getDocumentationFieldEditionSection, getIntegrationFieldEditionSection } from '../_global/editionHandlerUtils';
import FilterField from '../_global/FilterField';
import MultipleRelationExportConfiguration from '../_global/MultipleRelationExportConfiguration';
import { getGenericOperationMetadata } from '../_global/updateOperationHandlerUtils';
import UpdateOperationInput from '../_global/UpdateOperationInput';
import UpdateOperationSelector from '../_global/UpdateOperationSelector';
import { getViewsBlockDisplayOptionsHandler } from '../_global/viewsBlockFieldUtils';
import { defaultWidgetDisplay } from '../_global/widgetUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  createAndLinkFieldToConceptDefinitions,
  duplicateFieldDimensionWithNewField,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
} from '../fieldDimensionUtils';
import type { FieldEditionOption, FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldIntegrationOnlyDisabled } from '../FieldLibraryTypes';
import { ViewLoadingStateContextProvider } from '../viewsField/useViewLoadingState';
import ViewsGroupBlock from '../viewsField/ViewsGroupBlock';
import RelationMultipleFieldInputRenderer from './RelationMultipleFieldInputRenderer';
import RelationMultipleFieldRendererSwitch from './RelationMultipleFieldRendererSwitch';

const buildOnLink = (objectStore: ObjectStore, fieldId: string) => (linkedInstanceId: string, conceptInstanceId: string) => {
  const field = objectStore.getObject(fieldId);
  const reverseField = field.navigate(RelationMultipleField_ReverseField);
  objectStore.updateObject(linkedInstanceId, {
    [reverseField.id]: conceptInstanceId,
  });
  return linkedInstanceId;
};

const buildOnUnlink = (objectStore: ObjectStore, fieldId: string) => (linkedInstanceId: string) => {
  const field = objectStore.getObject(fieldId);
  const reverseField = field.navigate(RelationMultipleField_ReverseField);
  objectStore.updateObject(linkedInstanceId, {
    [reverseField.id]: null,
  });
};

const getWithOptions = (store: FrontObjectStore, edition = false) => store.getObject(ConceptDefinition)
  .navigateBack(Class_Instances)
  .filter((concept) => edition || (!concept[ConceptDefinition_RestrictedAccess] && concept.id !== Dashboard))
  .map((conceptDefinition) => getChipOptions(store, conceptDefinition.id))
  .filter(filterNullOrUndefined)
  .sort(defaultOptionComparator);

const computeSubTitle = (fromTypeName: string, toTypeName: string) => (
  i18n`${fromTypeName} targets multiple ${toTypeName} / ${toTypeName} targets a single ${fromTypeName}`
);

interface RelationMultipleFieldBlockDisplayOptions extends FieldBlockDisplayOptions {
  viewDefinitions?: ViewStoredDefinition[],
  withFilters?: boolean,
}

interface RelationMultipleFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
  reverseName: string | null | undefined,
  [RelationMultipleField_TargetType]: string | null | undefined,
  [Field_IntegrationOnly]: boolean | null | undefined,
  [FieldIntegrationOnlyDisabled]: boolean | undefined,
  isReverseFieldCore: boolean | null | undefined,
  [RelationMultipleField_Field_TargetFilter]: Filters | null | undefined,
}

type RelationMultipleFieldDefinition = GetFieldDefinitionHandler<
  typeof relationMultipleFieldHandler,
  RelationMultipleFieldConfigurationState,
  never,
  RelationMultipleFieldBlockDisplayOptions,
  MultipleRelationFieldExportConfiguration
>;

export const relationMultipleFieldDefinition: RelationMultipleFieldDefinition = registerFieldDefinition(relationMultipleFieldHandler, {
  configuration: {
    typeIcon: IconName.spoke,
    getTypeLabel: () => i18n`Association one to many (1-n)`,
    asWidget: false,
    getEditionOptions: (store) => ({ mode, editionHandler, isEdition, modelTypeId }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      const targetTypeId = editionHandler.getValueOrDefault(RelationMultipleField_TargetType);
      const fieldTitle = editionHandler.getValue(Field_Title);
      const dataOptions: FieldEditionOption[] = [
        {
          key: RelationMultipleField_TargetType,
          title: i18n`With`,
          hasValue: () => editionHandler.getValue(RelationMultipleField_TargetType) !== undefined
            && editionHandler.getValue(RelationMultipleField_Field_TargetFilter) !== undefined,
          clearValue: () => editionHandler.updateValues({ [RelationMultipleField_TargetType]: null, [RelationMultipleField_Field_TargetFilter]: null }),
          type: EditionOptionTypes.custom,
          props: {
            render: () => (
              <SpacingLine>
                <SearchAndSelect
                  selectedOption={typeof targetTypeId === 'string' ? getChipOptions(store, targetTypeId) : undefined}
                  computeOptions={() => getWithOptions(store, isEdition)}
                  placeholder={i18n`Add concept`}
                  readOnly={isEdition}
                  onSelect={(value) => editionHandler.updateValues({ [RelationMultipleField_TargetType]: (value?.id) ?? null })}
                  statusIcon={
                    typeof targetTypeId !== 'string' || !getChipOptions(store, targetTypeId)
                      ? {
                        icon: IconName.dangerous,
                        color: IconColorVariant.error,
                        message: i18n`Required`,
                      }
                      : undefined
                  }
                />
                <FilterField
                  targetTypeId={typeof targetTypeId === 'string' ? targetTypeId : undefined}
                  modelTypeId={modelTypeId}
                  selectFieldFilters={editionHandler.getValue(RelationMultipleField_Field_TargetFilter)}
                  updateFilters={(filters: Filters[]) => {
                    editionHandler.updateValues({ [RelationMultipleField_Field_TargetFilter]: filters[0] });
                  }}
                />
              </SpacingLine>
            ),
          },
        },
        {
          key: 'reverseName',
          title: i18n`Reverse name`,
          hasValue: () => editionHandler.getValue('reverseName') !== undefined,
          clearValue: () => editionHandler.updateValues({ reverseName: null }),
          type: EditionOptionTypes.text,
          props: {
            placeholder: fieldTitle ? i18n`Reverse of "${fieldTitle}"` : i18n`Add reverse name`,
            readOnly: editionHandler.getValue('isReverseFieldCore'),
            value: editionHandler.getValueOrDefault('reverseName'),
            onChange: (value) => editionHandler.updateValues({ reverseName: value }),
          },
        },
      ];

      sections.push({
        key: 'data',
        type: 'section',
        title: i18n`Data`,
        options: dataOptions,
      });

      sections.push(getDocumentationFieldEditionSection(editionHandler));
      sections.push(getIntegrationFieldEditionSection(store, editionHandler, mode));

      return sections;
    },
    getFieldTitle: (store) => (optionHandler) => {
      const targetTypeId = optionHandler.getValue(RelationMultipleField_TargetType);
      return targetTypeId ? i18n`Association one to many with "${getObjectName(store, store.getObject(targetTypeId))}" (1-n)` : i18n`Association one to many (1-n)`;
    },
    getFieldSubTitle: (store) => (optionHandler, modelTypeId) => {
      const targetTypeId = optionHandler.getValue(RelationMultipleField_TargetType) as string;
      const targetTypeName = targetTypeId ? getObjectName(store, store.getObject(targetTypeId)) : undefined;
      const fromTypeName = getObjectName(store, store.getObject(modelTypeId));
      if (fromTypeName && targetTypeName) {
        return computeSubTitle(fromTypeName, targetTypeName);
      }
      return undefined;
    },
    getFieldIcon: (store) => (editionHandler) => {
      const toTypeId = editionHandler.getValue(RelationMultipleField_TargetType) as string;
      return toTypeId && toTypeId !== Concept ? store.getObjectOrNull<ConceptDefinitionStoreObject>(toTypeId)?.[ConceptDefinition_Icon] as IconName | undefined : undefined;
    },
    isCreationEnabled: () => () => true,
    canCreate: (_) => (editionHandler) => Boolean(editionHandler.getValue(RelationMultipleField_TargetType)),
    onCreate: (store) => (editionHandler) => {
      const modelTypeId = Object.values(editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {})[0].typeId;
      const fieldId = store.createObject<RelationSingleFieldRaw>({
        [Instance_Of]: RelationSingleField,
        [Field_Title]: editionHandler.getValue('reverseName'),
        [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
        [RelationSingleField_TargetType]: modelTypeId,
      });
      const reverseFieldId = store.createObject<RelationMultipleFieldRaw>({
        [Instance_Of]: RelationMultipleField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [Field_ApiAlias]: editionHandler.getValue(Field_ApiAlias),
        [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
        [RelationMultipleField_TargetType]: editionHandler.getValue(RelationMultipleField_TargetType) as string,
        [RelationMultipleField_ReverseField]: fieldId,
      });
      linkFieldToFieldDimensions(store, reverseFieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});
      createAndLinkFieldToConceptDefinitions(store, fieldId, [editionHandler.getValue(RelationMultipleField_TargetType)].filter(filterNullOrUndefined));
      return fieldId;
    },
    inlineCreate: (store) => (conceptDefinitionId, extraFieldOptions) => ({
      type: 'transactional',
      getChipLabel: (state) => state[Field_Title] as string | undefined,
      getInitialState: (search) => ({ [Field_Title]: search }),
      creationOptions: [
        {
          key: RelationMultipleField_TargetType,
          title: i18n`With`,
          isValueValid: (value) => typeof value === 'string',
          render: (value, setValue) => (
            <SearchAndSelect
              computeOptions={() => getWithOptions(store, true)}
              selectedOption={typeof value === 'string' ? getChipOptions(store, value) : undefined}
              onSelect={(option) => setValue(option?.id ?? undefined)}
              getInlineCreation={() => ({
                type: 'inline',
                onCreate: (name) => {
                  const newConceptDefinitionId = createConceptDefinition(store, name);
                  setValue(newConceptDefinitionId);
                  return newConceptDefinitionId;
                },
              })}
            />
          ),
        },
      ],
      onCreate: (state) => {
        const newFieldId = store.createObject<RelationSingleFieldRaw>(joinObjects(
          extraFieldOptions,
          {
            [Instance_Of]: RelationSingleField,
            [RelationSingleField_TargetType]: conceptDefinitionId,
          }
        ));
        const reverseFieldId = store.createObject<RelationMultipleFieldRaw>(joinObjects(
          extraFieldOptions,
          {
            [Instance_Of]: RelationMultipleField,
            [Field_Title]: state[Field_Title] as string | undefined,
            [RelationMultipleField_TargetType]: state[RelationMultipleField_TargetType] as string,
            [RelationMultipleField_ReverseField]: newFieldId,
          }
        ));
        createAndLinkFieldToConceptDefinitions(store, reverseFieldId, [conceptDefinitionId]);
        createAndLinkFieldToConceptDefinitions(store, newFieldId, [state[RelationMultipleField_TargetType] as string]);
        return newFieldId;
      },
    }),
    ofField: (store, fieldId, handler) => ({
      getTypeLabel: () => {
        const { targetTypeId } = handler.resolveConfiguration();
        return i18n`Association one to many with "${getObjectName(store, store.getObject(targetTypeId))}" (1-n)`;
      },
      getIcon: () => {
        const field = store.getObject(fieldId);
        const targetType = field.navigate(RelationMultipleField_TargetType);
        return {
          name: targetType[ConceptDefinition_Icon] as IconName,
          borderColor: computeBorderColor(targetType[ConceptDefinition_ChipBackgroundColor] as string | undefined ?? conceptDefinitionChipBackgroundColor),
          color: conceptDefinitionChipBackgroundColor,
        };
      },
      getInitialState: (conceptDefinitionId) => {
        const field = store.getObject<RelationMultipleFieldStoreObject>(fieldId);
        const reverseField = field.navigate<RelationSingleFieldStoreObject>(RelationMultipleField_ReverseField);
        return joinObjects(
          getApiAliasInitialState(store, fieldId),
          {
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            reverseName: reverseField[Field_Title],
            [RelationMultipleField_TargetType]: field[RelationMultipleField_TargetType],
            [Field_IntegrationOnly]: field[Field_IntegrationOnly],
            [FieldIntegrationOnlyDisabled]: field[Field_IsCore],
            isReverseFieldCore: reverseField[Field_IsCore],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(store, fieldId, conceptDefinitionId),
            [RelationMultipleField_Field_TargetFilter]: field[RelationMultipleField_Field_TargetFilter],
          }
        );
      },
      submitFieldUpdate: (stateToSubmit) => {
        store.updateObject<RelationMultipleFieldRaw>(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: stateToSubmit[Field_IntegrationOnly],
          [RelationMultipleField_Field_TargetFilter]: stateToSubmit[RelationMultipleField_Field_TargetFilter],
        });
        store.updateObject<RelationSingleFieldRaw>(
          store.getObject(fieldId).navigate(RelationMultipleField_ReverseField).id,
          { [Field_Title]: stateToSubmit.reverseName }
        );
      },
      duplicateFieldDefinition: () => {
        const relationMultipleField = store.getObject(fieldId);
        const relationSingleField = relationMultipleField.navigate(RelationMultipleField_ReverseField);

        const newRelationSingleFieldDimensionMapping = generateDuplicatedFieldDimensionId(store, relationSingleField.id);
        const newRelationSingleFieldId = store.createObject({
          [Instance_Of]: RelationSingleField,
          [Field_Title]: `${relationSingleField[Field_Title]} (copy)`,
          [Field_Documentation]: relationSingleField[Field_Documentation],
          [Field_IsDocumentationInline]: relationSingleField[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: relationSingleField[Field_IntegrationOnly],
          [RelationSingleField_TargetType]: relationSingleField[RelationSingleField_TargetType],
        });

        const newRelationMultipleFieldDimensionMapping = generateDuplicatedFieldDimensionId(store, relationMultipleField.id);
        const newRelationMultipleFieldId = store.createObject({
          [Instance_Of]: RelationMultipleField,
          [Field_Title]: `${relationMultipleField[Field_Title]} (copy)`,
          [Field_Documentation]: relationMultipleField[Field_Documentation],
          [Field_IsDocumentationInline]: relationMultipleField[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: relationMultipleField[Field_IntegrationOnly],
          [RelationMultipleField_TargetType]: relationMultipleField[RelationMultipleField_TargetType],
          [RelationMultipleField_ReverseField]: newRelationSingleFieldId,
          [RelationMultipleField_Field_TargetFilter]: relationMultipleField[RelationMultipleField_Field_TargetFilter],
        });

        duplicateFieldDimensionWithNewField(store, newRelationSingleFieldId, newRelationSingleFieldDimensionMapping);
        duplicateFieldDimensionWithNewField(store, newRelationMultipleFieldId, newRelationMultipleFieldDimensionMapping);

        return newRelationMultipleFieldId;
      },
    }),
  },
  renderField: (store, fieldId) => ({ dimensionsMapping, readOnly, focusOnMount }) => (
    <RelationMultipleFieldRendererSwitch
      fieldId={fieldId}
      dimensionsMapping={dimensionsMapping}
      readOnly={readOnly}
      focusOnMount={focusOnMount ?? false}
      onLink={buildOnLink(store, fieldId)}
      onUnlink={buildOnUnlink(store, fieldId)}
    />
  ),
  renderBlockField: (store, fieldId) => (dimensionsMapping, displayOptions, blockFieldProps, layoutParametersMapping, viewDimensions, fieldBlockDisplayId) => {
    const parsedDimension = parseDimensionMapping(dimensionsMapping);
    if (parsedDimension.type !== ParsedDimensionType.MonoDimensional) {
      return null;
    }

    const targetTypeId = getFieldUtilsHandler(store, fieldId).getTargetType?.()?.id;
    if (!targetTypeId) {
      return null; // Invalid configuration, abort
    }

    const { viewDefinitions, withFilters } = displayOptions;
    return (
      <ViewLoadingStateContextProvider>
        <ViewsGroupBlock
          fieldId={fieldId}
          viewDimensions={viewDimensions}
          viewFilters={{
            filterKey: fieldBlockDisplayId,
            hasFilters: withFilters,
            getViewFilters: () => store.getObject<FieldBlockDisplayStoreObject>(fieldBlockDisplayId)
              .navigateBack<AssociationFilterStoreObject | ConditionFilterStoreObject>(FieldBlockDisplay_ViewFilters),
          }}
          viewDefinitions={viewDefinitions ?? []}
          layoutParametersMapping={layoutParametersMapping}
          blockFieldProps={blockFieldProps}
          widgetDisplay={defaultWidgetDisplay}
        />
      </ViewLoadingStateContextProvider>
    );
  },
  input: (store, _, handler) => ({
    render: ({ value, onSubmit, focusOnMount, readOnly, onEditionStart, onEditionStop, isEditing, aclHandler: { canCreateObject } }) => {
      const targetType = handler.getTargetType?.();
      if (!targetType) {
        return null;
      }
      const applyUpdateOnState = (state: string[], update: RelationMultipleUpdate) => {
        switch (update.action) {
          case 'add':
            return Array.from(new Set([...state, ...update.objectIds]));
          case 'set':
            return update.objectIds;
          case 'remove':
            return state.filter((id) => !update.objectIds.includes(id));
        }
      };
      return (
        <SimpleInput initialValue={value} onSubmit={onSubmit}>
          {(props) => (
            <RelationMultipleFieldInputRenderer
              value={props.value}
              onChange={(newValue) => props.onChange(applyUpdateOnState(props.value, newValue))}
              onSubmit={(newValue) => props.onSubmit(applyUpdateOnState(props.value, newValue))}
              onCancel={props.onCancel}
              focusOnMount={focusOnMount}
              readOnly={readOnly}
              onEditionStart={onEditionStart}
              onEditionStop={onEditionStop}
              isEditing={isEditing}
              canCreateObject={canCreateObject}
              targetType={targetType}
            />
          )}
        </SimpleInput>
      );
    },
    getInitialState: () => [],
    persistStateOnConcept: (dimension, value) => handler.updateValue(dimension, { action: 'set', objectIds: value.filter((id) => store.getObjectOrNull(id)) }),
  }),
  getUpdateOperationInput: (store, fieldId, { getTargetType, updateOperationHandlers: { INITIALIZE, REPLACE, ADD, REMOVE, CLEAR } }) => (operation) => {
    const { title, getIcon } = getGenericOperationMetadata(store, operation, fieldId);
    return {
      title,
      getIcon,
      render: ({ onSubmit, parameterDefinitions }) => {
        const operationSelectorLine = (
          <UpdateOperationSelector<typeof relationMultipleFieldHandler>
            selectedOperationAction={operation?.action}
            operationOptions={[
              { id: 'INITIALIZE', label: i18n`Initialize`, onSelect: () => onSubmit({ action: 'INITIALIZE', payload: INITIALIZE.sanitizeOperation(operation) }) },
              { id: 'REPLACE', label: i18n`Replace`, onSelect: () => onSubmit({ action: 'REPLACE', payload: REPLACE.sanitizeOperation(operation) }) },
              { id: 'ADD', label: i18n`Add`, onSelect: () => onSubmit({ action: 'ADD', payload: ADD.sanitizeOperation(operation) }) },
              { id: 'REMOVE', label: i18n`Remove`, onSelect: () => onSubmit({ action: 'REMOVE', payload: REMOVE.sanitizeOperation(operation) }) },
              { id: 'CLEAR', label: i18n`Clear`, onSelect: () => onSubmit({ action: 'CLEAR', payload: CLEAR.sanitizeOperation(operation) }) },
            ]}
          />
        );

        let operationInputsLines = null;
        const { input } = relationMultipleFieldDefinition(store).getHandler(fieldId);
        if (input && operation && operation.action !== 'CLEAR' && operation.payload) {
          operationInputsLines = (
            <UpdateOperationInput<typeof relationMultipleFieldHandler>
              valueInput={input}
              initialValue={operation.payload}
              onTypeChange={(newType) => {
                onSubmit({ action: operation.action, payload: newType === 'value' ? { type: 'value', value: [] } : { type: 'path', path: [] } });
              }}
              onValueSubmit={(newValue) => onSubmit({ action: operation.action, payload: newValue })}
              parameterDefinitions={parameterDefinitions}
              valuePathHandler={createPathConfigurationHandler(
                store,
                parameterDefinitions,
                [getConceptTypeValidator(store, true, getTargetType?.()?.id)]
              )}
            />
          );
        }

        return (
          <>
            {operationSelectorLine}
            {operationInputsLines}
          </>
        );
      },
    };
  },
  renderExportConfiguration: () => ({ configuration, onChange, conceptDefinitionId }) => (
    <MultipleRelationExportConfiguration
      configuration={configuration}
      conceptDefinitionId={conceptDefinitionId}
      onChange={onChange}
    />
  ),
  getActivityProperties: (_, fieldId) => () => [fieldId],
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({
    key: fieldId,
    propertyId: fieldId,
    sortable: true,
    focusable: true,
  }),
  getComparatorHandler: (store, _, { getValueResolution }) => (direction) => ({
    comparator: comparing(compareArray, direction === TableSortDirection.desc),
    extractValue: (dimensionsMapping) => {
      const labels = getValueResolution(dimensionsMapping).value
        .map((instance) => getInstanceLabelOrUndefined(store, instance))
        .sort(comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString));
      // We return undefined when the size is 0 to benefit from the pushUndefinedToEnd for the array comparator
      return labels.length === 0 ? undefined : labels;
    },
  }),
  blockDisplayOptionsHandler: getViewsBlockDisplayOptionsHandler,
});
