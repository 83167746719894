import type { FunctionComponent, ReactElement } from 'react';
import { useEffect, useState } from 'react';
import type { CollaborationRaw, CollaborationStoreObject, IntentionStoreObject } from 'yooi-modules/modules/collaborationModule';
import { getCollaborationContextsObjectIds, isValidCollaborationPath } from 'yooi-modules/modules/collaborationModule';
import {
  Collaboration_Intention,
  Collaboration_Status,
  CollaborationMessage as CollaborationMessageTypeId,
  CollaborationMessage_Collaboration,
  CollaborationMessage_CreatedAt,
  CollaborationMessage_CreatedBy,
  CollaborationMessage_Text,
  ConceptIntentionTransitionGroups,
  ConceptIntentionTransitionGroups_Role_Concept,
  ConceptIntentionTransitionGroups_Role_Group,
  ConceptIntentionTransitionGroups_Role_Intention,
  ConceptIntentionTransitionGroups_Role_Transition,
  ConceptIntentionTransitionRoles,
  ConceptIntentionTransitionRoles_Role_Concept,
  ConceptIntentionTransitionRoles_Role_Intention,
  ConceptIntentionTransitionRoles_Role_Role,
  ConceptIntentionTransitionRoles_Role_Transition,
  Intention_Description,
  Intention_Name,
  Intention_Workflow,
} from 'yooi-modules/modules/collaborationModule/ids';
import type { WorkflowStoreObject, WorkflowTransitionStoreObject } from 'yooi-modules/modules/conceptModule';
import { associationFieldHandler, isCollaborationClosed, isStatusBelongingToWorkflow } from 'yooi-modules/modules/conceptModule';
import {
  Concept,
  Concept_Name,
  ConceptDefinition,
  ConceptRoleUserAssignation,
  ConceptRoleUserAssignation_Role_Concept,
  ConceptRoleUserAssignation_Role_ConceptRole,
  ConceptRoleUserAssignation_Role_User,
  User_Groups,
  UserGroupMembershipDimension,
  Workflow_Transitions,
  WorkflowTransition_From,
  WorkflowTransition_Name,
  WorkflowTransition_To,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { RichText } from 'yooi-utils';
import { filterNullOrUndefined, richTextToText } from 'yooi-utils';
import Avatar, { AvatarSizes, AvatarVariant } from '../../../../../components/atoms/Avatar';
import Icon, { IconColorVariant, IconName, IconSizeVariant } from '../../../../../components/atoms/Icon';
import Typo, { TypoAlign, TypoVariant } from '../../../../../components/atoms/Typo';
import Banner, { BannerVariant } from '../../../../../components/molecules/Banner';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import Tabs from '../../../../../components/molecules/Tabs';
import useAcl from '../../../../../store/useAcl';
import useAuth from '../../../../../store/useAuth';
import useStore from '../../../../../store/useStore';
import { buildPadding, Spacing, spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import { notifySuccess } from '../../../../../utils/notify';
import { formatOrUndef } from '../../../../../utils/stringUtils';
import { useCollaborationPanelUpdater } from '../../../../../utils/useCollaborationContext';
import useDeleteModal from '../../../../../utils/useDeleteModal';
import { useHighlightNotify } from '../../../../../utils/useHighlight';
import { SizeVariant } from '../../../../../utils/useSizeContext';
import useTheme from '../../../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../../../utils/useUsageContext';
import { getChipOptions } from '../../../modelTypeUtils';
import CollaborationBaseLayout from '../_global/CollaborationBaseLayout';
import CollaborationInput, { CollaborationInputVariant } from '../_global/CollaborationInput';
import usePanelObserver from '../usePanelObserver';
import { buildCollaborationLink, getCollaborationMessages, getCollaborationStatusIds, getIntentions, getLastRead, updateLastRead } from '../utils/collaborationUtils';
import CollaborationDetailPanelInfoTab from './CollaborationDetailPanelInfoTab';
import CollaborationDetailPanelSubscribersTab from './CollaborationDetailPanelSubscribersTab';
import CollaborationDetailPanelThreadTab from './CollaborationDetailPanelThreadTab';

const useStyles = makeStyles({
  line: {
    display: 'flex',
    flexDirection: 'column',
  },
  replyContainer: {
    display: 'grid',
    columnGap: spacingRem.s,
    gridTemplateColumns: 'auto 1fr',
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '3.2rem',
    alignItems: 'center',
  },
  emptyContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.m,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.s,
    justifyContent: 'center',
    alignItems: 'center',
  },
}, 'collaborationDetailPanel');

interface Tab {
  key: string,
  icon: IconName,
  iconColor?: IconColorVariant,
  name?: string,
  iconTooltip?: string,
  render: () => ReactElement,
  footer?: () => ReactElement,
  bodyBackgroundColor?: string,
}

const CollaborationDetailPanel: FunctionComponent = () => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const { loggedUserId } = useAuth();
  const { canOverrideCollaborationWorkflow } = useAcl();

  const highlight = useHighlightNotify();
  const { isOpen, collaborationId, pageContext, context } = usePanelObserver();
  const panel = useCollaborationPanelUpdater();
  const isLibraryPageContext = pageContext && pageContext.length === 1;
  let backContext: string[] | undefined;
  if (pageContext) {
    backContext = !isLibraryPageContext ? pageContext[pageContext.length - 1] : context;
  }
  let intentions: IntentionStoreObject[] = [];
  let firstClassConceptId: string | undefined;
  let conceptDefinitionId: string | undefined;
  if (collaborationId) {
    const collaborationContextsObjectIds = getCollaborationContextsObjectIds(store, collaborationId);
    firstClassConceptId = collaborationContextsObjectIds?.find((objectId) => isInstanceOf(store.getObject(objectId), Concept));
    if (firstClassConceptId) {
      const concept = store.getObjectOrNull(firstClassConceptId);
      conceptDefinitionId = concept?.[Instance_Of] as string | undefined;
    } else {
      conceptDefinitionId = collaborationContextsObjectIds?.find((id) => id.length === 1 && isInstanceOf(store.getObject(id[0]), ConceptDefinition))?.[0];
    }
    intentions = getIntentions(store, conceptDefinitionId);
  }
  const [doDelete, deleteModal] = useDeleteModal({
    doDelete: () => {
      if (collaborationId !== undefined) {
        panel.openList(backContext, true);
        store.deleteObject(collaborationId);
      }
    },
    shouldConfirm: () => true,
    getModalProps: () => ({
      title: i18n`Are you sure that you want to delete this collaboration?`,
      content: (
        <>
          <Typo>{i18n`Deleting this item is irreversible and will permanently remove it from the platform.`}</Typo>
          <Typo>{i18n`If you are not sure, you can close this collaboration instead.`}</Typo>
        </>
      ),
    }),
  });

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [lastRead, setLastRead] = useState(collaborationId ? getLastRead(store, collaborationId, loggedUserId) : 0);

  // update the last read value in the store on creation and deletion of the component
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (collaborationId && store.getObjectOrNull(collaborationId)) {
      const lastUpdate = updateLastRead(store, collaborationId, loggedUserId);
      timeoutId = setTimeout(() => setLastRead(lastUpdate), 5_000);
    }
    return () => {
      clearTimeout(timeoutId);
      if (collaborationId && store.getObjectOrNull(collaborationId)) {
        updateLastRead(store, collaborationId, loggedUserId);
      }
    };
  }, [collaborationId, loggedUserId, store]);

  if (!collaborationId || !isOpen) {
    return null;
  }
  if (intentions.length === 0) {
    return (
      <CollaborationBaseLayout
        title={i18n`Collaborations`}
        contentBackgroundColor={theme.color.background.neutral.subtle}
        contentCompact
        content={(
          <div className={classes.emptyContainer}>
            <Icon name={IconName.forum} size={IconSizeVariant.xxxl} color={theme.color.text.disabled} />
            <div className={classes.emptyMessageContainer}>
              <Typo color={theme.color.text.disabled} align={TypoAlign.center}>{i18n`Collaboration is no longer accessible.`}</Typo>
            </div>
          </div>
        )}
      />
    );
  } else {
    const collaboration = store.getObjectOrNull<CollaborationStoreObject>(collaborationId);
    if (!collaboration) {
      return (
        <CollaborationBaseLayout
          title={i18n`Collaboration`}
          mainBackgroundColor={theme.color.background.neutral.subtle}
          content={(
            <div className={classes.line}>
              <Banner variant={BannerVariant.danger} title={i18n`Collaboration has been deleted.`} />
            </div>
          )}
        />
      );
    }

    const collaborationMessages = getCollaborationMessages(store, collaborationId, true);
    const hasUnread = collaborationMessages.some(
      ({ [CollaborationMessage_CreatedAt]: createdAt, [CollaborationMessage_CreatedBy]: createdBy }) => createdBy !== loggedUserId && (createdAt ?? 0) > lastRead
    );

    const showUrl = isValidCollaborationPath(store, collaborationId);

    const isTransitionAvailableForUser = (conceptId: string | undefined, transitionId: string, intentionId: string): boolean => {
      let transitionAvailableForUser = true;
      if (conceptDefinitionId) {
        const groups = store.withAssociation(ConceptIntentionTransitionGroups)
          .withRole(ConceptIntentionTransitionGroups_Role_Intention, intentionId)
          .withRole(ConceptIntentionTransitionGroups_Role_Transition, transitionId)
          .withRole(ConceptIntentionTransitionGroups_Role_Concept, conceptDefinitionId)
          .list()
          .map((assoc) => assoc.role(ConceptIntentionTransitionGroups_Role_Group));

        const roles = store.withAssociation(ConceptIntentionTransitionRoles)
          .withRole(ConceptIntentionTransitionRoles_Role_Intention, intentionId)
          .withRole(ConceptIntentionTransitionRoles_Role_Transition, transitionId)
          .withRole(ConceptIntentionTransitionRoles_Role_Concept, conceptDefinitionId)
          .list()
          .map((assoc) => assoc.role(ConceptIntentionTransitionRoles_Role_Role));

        if (roles.length > 0 || groups.length > 0) {
          const userGroupIds = associationFieldHandler(store, User_Groups)
            .getValueWithoutFormula({ [UserGroupMembershipDimension]: loggedUserId })
            .map((g) => g.id);
          transitionAvailableForUser = groups.some((g) => userGroupIds.includes(g))
            || (!!conceptId && roles.some((role) => (
              store.withAssociation(ConceptRoleUserAssignation)
                .withRole(ConceptRoleUserAssignation_Role_Concept, conceptId)
                .withRole(ConceptRoleUserAssignation_Role_User, loggedUserId)
                .withRole(ConceptRoleUserAssignation_Role_ConceptRole, role)
                .getObjectOrNull() !== null
            )));
        } else {
          transitionAvailableForUser = true;
        }
      }
      return transitionAvailableForUser;
    };

    const tabs: Tab[] = [
      {
        key: 'messages',
        icon: hasUnread ? IconName.mark_chat_unread : IconName.chat_bubble,
        iconColor: hasUnread ? IconColorVariant.info : IconColorVariant.primary,
        iconTooltip: i18n`Messages`,
        name: `${collaborationMessages.length}`,
        render: () => (
          <CollaborationDetailPanelThreadTab collaborationId={collaborationId} lastRead={lastRead} />
        ),
        footer: () => {
          const currentUserName = richTextToText(store.getObject(loggedUserId)[Concept_Name] as RichText | undefined);

          const intention = collaboration[Collaboration_Intention] ? store.getObjectOrNull<IntentionStoreObject>(collaboration[Collaboration_Intention]) : undefined;
          const collaborationClosed = isCollaborationClosed(store, collaboration);

          const transitions: WorkflowTransitionStoreObject[] = intention && intention[Intention_Workflow]
          && isStatusBelongingToWorkflow(store, intention[Intention_Workflow], collaboration[Collaboration_Status])
            ? (store.getObjectOrNull(intention[Intention_Workflow])
              ?.navigateBack<WorkflowTransitionStoreObject>(Workflow_Transitions)
              .filter((transition) => (transition[WorkflowTransition_From] === collaboration[Collaboration_Status]
                && isTransitionAvailableForUser(firstClassConceptId, transition.id, intention.id))) ?? [])
            : [];

          return (
            <>
              {collaborationClosed ? (<Banner variant={BannerVariant.info} title={i18n`Collaboration is closed.`} />) : null}
              <div className={classes.replyContainer}>
                <div className={classes.avatarContainer}>
                  <Avatar name={currentUserName?.charAt(0).toUpperCase() || '...'} tooltip={currentUserName} variant={AvatarVariant.currentUser} size={AvatarSizes.small} />
                </div>
                <CollaborationInput
                  variant={collaborationClosed ? CollaborationInputVariant.ReplyClosed : CollaborationInputVariant.Reply}
                  onSend={(message) => {
                    const newLastRead = updateLastRead(store, collaborationId, loggedUserId);
                    store.createObject({
                      [Instance_Of]: CollaborationMessageTypeId,
                      [CollaborationMessage_Collaboration]: collaborationId,
                      [CollaborationMessage_Text]: message,
                    });
                    setLastRead(newLastRead);
                  }}
                  transitions={transitions.map((transition) => ({
                    key: transition.key,
                    name: transition[WorkflowTransition_Name] ?? '',
                    onClick: () => {
                      if (transition[WorkflowTransition_To]) {
                        store.updateObject<CollaborationRaw>(collaboration.id, { [Collaboration_Status]: transition[WorkflowTransition_To] });
                      }
                    },
                    tooltip: transition[WorkflowTransition_Name] ?? undefined,
                  }))}
                />
              </div>
            </>
          );
        },
        bodyBackgroundColor: theme.color.background.neutral.subtle,
      },
      {
        key: 'recipients',
        icon: IconName.group,
        iconTooltip: i18n`Subscribers`,
        render: () => (<CollaborationDetailPanelSubscribersTab collaborationId={collaborationId} />),
      },
      {
        key: 'info',
        icon: IconName.info,
        iconTooltip: i18n`About`,
        render: () => (<CollaborationDetailPanelInfoTab collaborationId={collaborationId} />),
      },
    ];

    const intention = collaboration.navigateOrNull<IntentionStoreObject>(Collaboration_Intention);
    const intentionWorkflow = intention?.[Intention_Workflow] ? store.getObjectOrNull<WorkflowStoreObject>(intention[Intention_Workflow]) : null;
    const isWorkflowDefined = !!intentionWorkflow;
    const statusBelongsToWorkflow = isStatusBelongingToWorkflow(store, intention?.[Intention_Workflow], collaboration?.[Collaboration_Status]);

    return (
      <>
        <CollaborationBaseLayout
          title={intention ? formatOrUndef(intention[Intention_Name]) : i18n`Deleted intention`}
          titleButton={{
            icon: IconName.keyboard_arrow_left,
            tooltip: i18n`All collaborations`,
            onClick: () => {
              if (!isLibraryPageContext) {
                highlight(undefined);
              }
              panel.openList(backContext);
            },
          }}
          titleTooltip={intention && Boolean(intention[Intention_Description]) ? intention[Intention_Description] : undefined}
          tabsLine={(
            <Tabs
              tabs={tabs}
              selectedTabIndex={selectedTabIndex}
              sizeVariant={SizeVariant.small}
              typoVariant={TypoVariant.blockInlineTitle}
              onSelectedIndexChanged={setSelectedTabIndex}
              tabStyle={{ ...buildPadding({ x: Spacing.xs }) }}
              padded
            />
          )}
          headerExtraLine={(
            <>
              <Typo color={theme.color.text.secondary}>{i18n`Status`}</Typo>
              <UsageContextProvider usageVariant={UsageVariant.inForm}>
                <SearchAndSelect
                  key={Collaboration_Status}
                  onSelect={(status) => {
                    if (status) {
                      store.updateObject<CollaborationRaw>(collaboration.id, { [Collaboration_Status]: status.id });
                    }
                  }}
                  computeOptions={() => {
                    if (collaboration && collaboration[Collaboration_Intention]) {
                      return getCollaborationStatusIds(store, collaboration[Collaboration_Intention])
                        .map((id) => getChipOptions(store, id))
                        .filter(filterNullOrUndefined);
                    } else {
                      return [];
                    }
                  }}
                  selectedOption={(isWorkflowDefined && collaboration[Collaboration_Status] !== undefined)
                    ? getChipOptions(store, collaboration[Collaboration_Status]) : undefined}
                  statusIcon={isWorkflowDefined && !statusBelongsToWorkflow
                    ? {
                      icon: IconName.dangerous,
                      color: IconColorVariant.error,
                      message: i18n`Selected instance does not belong to the workflow`,
                    } : undefined}
                  readOnly={!isWorkflowDefined || (
                    !!firstClassConceptId && intentionWorkflow.navigateBack(Workflow_Transitions).length > 0 && !canOverrideCollaborationWorkflow(firstClassConceptId)
                  )}
                  placeholder={i18n`Select element`}
                />
              </UsageContextProvider>
            </>
          )}
          overflowActions={[
            ...(showUrl ? [{
              key: 'copy',
              name: i18n`Copy link`,
              icon: IconName.output,
              onClick: () => {
                navigator.clipboard.writeText(buildCollaborationLink(store, collaborationId, window));
                notifySuccess(i18n`Copied to clipboard`);
              },
            }] : []),
            {
              key: 'delete',
              name: i18n`Delete`,
              icon: IconName.delete,
              onClick: doDelete,
              danger: true,
            },
          ]}
          content={tabs[selectedTabIndex].render()}
          footer={tabs[selectedTabIndex].footer?.()}
          mainBackgroundColor={tabs[selectedTabIndex]?.bodyBackgroundColor}
        />
        {deleteModal}
      </>
    );
  }
};

export default CollaborationDetailPanel;
