import type { PathStep } from '../../../../conceptModule';

export const SERIE_GROUP_BY_OPTION = 'SERIE_GROUP_BY_OPTION';

export enum InterpolationType {
  linear = 'linear',
  natural = 'natural',
  step = 'step',
  stepAfter = 'stepAfter',
  stepBefore = 'stepBefore',
}

export interface EditableViewSeriesProperties {
  label?: string,
  path: PathStep[],
  displayOptions?: DisplayOptions,
  exportOptions?: object | null,
}

export interface ViewSeries extends EditableViewSeriesProperties {
  id: string,
}

export interface SeriesFeatureDefinition {
  series?: ViewSeries[],
}

export interface DisplayOptions {
  color?: string,
  colorPath?: PathStep[],
  withArea?: boolean,
  width?: { type: 'percent' | 'rem', value: number } | undefined,
  interpolation?: InterpolationType,
  withLegend?: boolean,
}
