import { equals } from 'ramda';
import type { ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import { isStoreObject, ValidationStatus } from 'yooi-store';
import { filterNullOrUndefined, isString, joinObjects, newError } from 'yooi-utils';
import type { AsPropertyBusinessRuleRegistration, GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import { isInstanceOf } from '../../typeModule';
import { Instance_Of } from '../../typeModule/ids';
import { formatOrUndef, validateFieldIdAsProperty } from '../common/commonFieldUtils';
import { Concept, Concept_Name, EmbeddingField, EmbeddingField_FromType, EmbeddingField_ToType, KinshipRelation } from '../ids';
import type { ConceptStoreObject } from '../model';
import { registerField } from '../module';
import type { Filters, PathStep } from '../moduleType';
import { PathStepType } from '../moduleType';
import type { ConceptReference, DimensionsMapping, MultipleParameterValue, ParametersMapping, ResolutionStack, SingleParameterValue } from '../utils';
import {
  conceptRefApiSchema,
  createValuePathResolver,
  FILTER_PARAMETER_CURRENT,
  getFilterFunction,
  getInstanceLabel,
  getSingleRelationFieldExportColumnHeaders,
  getValueProxy,
  InstanceReferenceType,
  isSingleValueResolution,
  isValueResolutionOfType,
  ParsedDimensionType,
  parseDimensionMapping,
  resolveFieldValue,
  segregateParametersMapping,
  toConceptReference,
} from '../utils';
import { conceptType } from '../utils/formula/modelFunctions';
import type { SingleRelationFieldExportConfiguration } from '../utils/relationFieldUtils';
import { embeddingFieldHandler } from './embeddingField';
import type { KinshipRelationField } from './types';

const checkKinshipRelationFieldUpdate: AsPropertyBusinessRuleRegistration = ({ getObjectOrNull }, fieldId) => (_, { id, properties }) => {
  if (!properties) { // Deleting properties / Audit event
    return undefined;
  }
  const object = getObjectOrNull(id);
  const oldKinshipRelationFieldId = object?.[fieldId] as string | undefined;
  const newKinshipRelationFieldId = properties[fieldId] ?? undefined;
  if (oldKinshipRelationFieldId === newKinshipRelationFieldId) { // no updates
    return undefined;
  }

  if (newKinshipRelationFieldId !== undefined && (!isString(newKinshipRelationFieldId) || !getObjectOrNull(newKinshipRelationFieldId))) {
    return { rule: 'field.kinshipRelationField.invalidValue', status: ValidationStatus.REJECTED };
  }

  if (object && oldKinshipRelationFieldId && object[oldKinshipRelationFieldId] && properties[oldKinshipRelationFieldId] !== null) { // check cleanup after kinship relation update
    return { rule: 'field.kinshipRelationField.hasParentChange.missingCleanup', status: ValidationStatus.REJECTED };
  }

  if (newKinshipRelationFieldId && (!object || newKinshipRelationFieldId !== object[fieldId])) {
    const newEmbeddedByInstanceId = properties[newKinshipRelationFieldId];
    if (!newEmbeddedByInstanceId || !isString(newEmbeddedByInstanceId) || !getObjectOrNull(newEmbeddedByInstanceId)) {
      return { rule: 'field.kinshipRelationField.hasParentChange.missingNewParent', status: ValidationStatus.REJECTED };
    }
  }

  return undefined;
};

const getValueResolution = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack) => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
  if (isValueResolutionOfType(valueResolution, (value): value is StoreObject | undefined => value === undefined || isStoreObject(value))) {
    return valueResolution;
  } else if (valueResolution.value === null) {
    return { value: undefined, isComputed: valueResolution.isComputed, error: valueResolution.error, getDisplayValue: () => undefined, isTimeseries: valueResolution.isTimeseries };
  } else {
    return {
      value: undefined,
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['StoreObject', 'null', 'undefined'], typeof valueResolution.value),
      getDisplayValue: () => undefined,
      isTimeseries: valueResolution.isTimeseries,
    };
  }
};

export interface KinshipRelationFieldStepConfiguration {
  conceptDefinitionId?: string,
  fieldId?: string,
  filters?: Filters,
}

type KinshipFieldHandler = GetDslFieldHandler<
  KinshipRelationField,
  string | undefined,
  undefined,
  StoreObject | undefined,
  StoreObject | undefined,
  ConceptReference | undefined,
  undefined,
  undefined,
  KinshipRelationFieldStepConfiguration,
  SingleRelationFieldExportConfiguration
>;

export const kinshipRelationFieldHandler: KinshipFieldHandler = registerField({
  model: {
    label: 'KinshipRelationField',
    title: 'Embedded by',
    withApiAlias: true,
    asPropertyBusinessRules: [
      checkKinshipRelationFieldUpdate,
      ({ getObjectOrNull }, propertyId) => (_, { properties }) => {
        if (!properties || properties[propertyId] === null) {
          return undefined;
        }
        if (isInstanceOf(getObjectOrNull(properties[propertyId] as string), EmbeddingField)) {
          return { rule: 'KinshipRelationField.target.accepted', status: ValidationStatus.ACCEPTED };
        } else {
          return { rule: 'KinshipRelationField.target.rejected', status: ValidationStatus.REJECTED };
        }
      },
      ({ getObjectOrNull }, propertyId) => (_, { id, properties }) => {
        if (!properties || properties[propertyId] === null) {
          return undefined;
        }
        const targetId = properties[properties[propertyId] as string] ?? getObjectOrNull(id)?.[properties[propertyId] as string];
        if (!targetId) {
          return { rule: 'KinshipRelationField.target.missing', status: ValidationStatus.REJECTED };
        }
        return undefined;
      },
      ({ getObjectOrNull }, propertyId) => (_, { id, properties }) => {
        if (!properties || properties[propertyId] === null) {
          return undefined;
        }
        if (id.length === 1) {
          const object = getObjectOrNull(id);
          if (propertyId === object?.[KinshipRelation] || propertyId === properties[KinshipRelation]) {
            return { rule: 'field.kinshipRelationField.isKinshipRelationProperty', status: ValidationStatus.ACCEPTED };
          }
        }
        return undefined;
      },
      validateFieldIdAsProperty('kinshipRelationField'),
    ],
  },
  handler: (objectStore, fieldId) => {
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => {
      const parent = getValueResolution(objectStore, fieldId, dimensionsMapping).value;
      if (parent) {
        return formatOrUndef(getInstanceLabel(objectStore, parent));
      } else {
        return undefined;
      }
    };
    return {
      describe: () => ({ hasData: true, returnType: conceptType(Concept), timeseriesMode: 'none' }),
      restApi: {
        returnTypeSchema: conceptRefApiSchema,
        formatValue: (value, canRead) => (value && canRead([value.id]) ? toConceptReference(value) : undefined),
      },
      getStoreValue: (dimensionsMapping) => {
        const parsedDimensions = parseDimensionMapping(dimensionsMapping);
        if (parsedDimensions.type === ParsedDimensionType.MonoDimensional) {
          const concept = objectStore.getObject(parsedDimensions.objectId);
          if (concept[KinshipRelation] as string) {
            return concept[concept[KinshipRelation] as string] as string | undefined;
          } else {
            return undefined;
          }
        } else {
          return undefined;
        }
      },
      getValueWithoutFormula: (dimensionsMapping) => {
        const parsedDimensions = parseDimensionMapping(dimensionsMapping);
        if (parsedDimensions.type === ParsedDimensionType.MonoDimensional) {
          const concept = objectStore.getObject(parsedDimensions.objectId);
          if (concept[concept[KinshipRelation] as string]) {
            return concept.navigateOrNull(concept[KinshipRelation] as string) ?? undefined;
          } else {
            return undefined;
          }
        } else {
          return undefined;
        }
      },
      getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
      resolvePathStepConfiguration: (config) => {
        const resolveValue = (
          dimensionsMapping: DimensionsMapping,
          parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
          resolutionStack: ResolutionStack
        ) => {
          const { error, value } = getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack);
          if (error) {
            throw error;
          } else if (value) {
            const { filters, conceptDefinitionId } = config;
            if (conceptDefinitionId && !isInstanceOf(value, conceptDefinitionId)) {
              return undefined;
            }
            if (filters) {
              const { singleParametersMapping } = segregateParametersMapping(parametersMapping);
              const filterFunction = getFilterFunction(objectStore, filters);
              if (filterFunction) {
                return filterFunction(joinObjects(
                  singleParametersMapping,
                  { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: value.id } }
                )) ? value : undefined;
              }
            }
            return value;
          }
          return undefined;
        };

        return ({
          hasData: true,
          timeseriesMode: 'none',
          getValueResolutionType: () => {
            if (config.conceptDefinitionId) {
              return conceptType(config.conceptDefinitionId);
            } else if (config.fieldId) {
              return conceptType(embeddingFieldHandler(objectStore, config.fieldId).getTargetType?.()?.id ?? Concept);
            } else {
              return conceptType(Concept);
            }
          },
          resolveValue,
          resolveDimension: (dimensionsMappings, parametersMapping, resolutionStack) => (
            { type: 'single' as const, instance: resolveValue(dimensionsMappings, parametersMapping, resolutionStack) as ConceptStoreObject | undefined }
          ),
        });
      },
      updateValue: () => {
        throw newError('updateValue not supported');
      },
      isEmpty: (dimensionsMapping) => !getValueResolution(objectStore, fieldId, dimensionsMapping).value,
      isSaneValue: () => ({ isValid: true }),
      getValueAsText,
      getExportColumnHeaders: getSingleRelationFieldExportColumnHeaders(objectStore),
      getExportValue: (dimensionsMapping, configuration) => {
        const { value } = getValueResolution(objectStore, fieldId, dimensionsMapping);
        if (configuration?.type === 'uuid') {
          return { format: 'string', value: value?.id };
        } else {
          if (!value) {
            return { format: 'string', value: undefined };
          }
          const defaultPath: PathStep[] = [
            { type: PathStepType.dimension, conceptDefinitionId: value[Instance_Of] as string },
            { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
            { type: PathStepType.field, fieldId: Concept_Name },
          ];
          const pathResolution = createValuePathResolver(objectStore, { [FILTER_PARAMETER_CURRENT]: { type: 'single', id: value?.id } })
            .resolvePathValue(configuration?.path ?? defaultPath);
          if (isSingleValueResolution(pathResolution)) {
            return { format: 'string', value: pathResolution.value as string };
          }
          return { format: 'string', value: undefined };
        }
      },
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else if (typeof prop === 'string') {
            const targetingInstance = getValueResolution(objectStore, fieldId, dimensionsMapping);
            if (targetingInstance && !targetingInstance.error && targetingInstance.value) {
              return getValueProxy(objectStore, targetingInstance.value.id, prop);
            } else {
              return undefined;
            }
          } else {
            return undefined;
          }
        },
      }),
      getTargetType: () => objectStore.getObject(Concept),
      getTargetTypes: (fromTypeId) => objectStore.getObject(fromTypeId)
        .navigateBack(EmbeddingField_ToType)
        .map((field) => field.navigateOrNull(EmbeddingField_FromType))
        .filter(filterNullOrUndefined)
        .filter(({ id }, index, self) => (self.findIndex(({ id: innerId }) => innerId === id) === index)) ?? [objectStore.getObject(Concept)],
      filterConditions: undefined,
    };
  },
  historyEventProducer: ({ getObjectOrNull }, fieldId) => ({
    value: {
      collectImpactedInstances: ({ id, properties }) => {
        if (id.length === 1 && properties) {
          const oldKinshipRelationFieldId = getObjectOrNull(id)?.navigateOrNull(fieldId)?.id;
          const oldParentId = oldKinshipRelationFieldId ? getObjectOrNull(id)?.[oldKinshipRelationFieldId] as string | undefined : undefined;
          const kinshipRelationFieldUpdate = properties?.[fieldId] as string | undefined | null;
          let newKinshipRelationFieldId: string | undefined;
          if (kinshipRelationFieldUpdate === null) {
            newKinshipRelationFieldId = undefined;
          } else if (kinshipRelationFieldUpdate === undefined) {
            newKinshipRelationFieldId = oldKinshipRelationFieldId;
          } else {
            newKinshipRelationFieldId = kinshipRelationFieldUpdate;
          }
          let newParentId: string | undefined;
          if (newKinshipRelationFieldId) {
            if (properties[newKinshipRelationFieldId] === undefined) {
              newParentId = getObjectOrNull(id)?.[newKinshipRelationFieldId] as string | undefined;
            } else if (properties[newKinshipRelationFieldId] !== null) {
              newParentId = properties[newKinshipRelationFieldId] as string;
            }
          }
          if (newParentId !== oldParentId) {
            return [id];
          }
        }
        return [];
      },
      getValue: (id) => {
        const kinshipRelationFieldId = getObjectOrNull(id)?.navigateOrNull(fieldId)?.id;
        const value = kinshipRelationFieldId ? getObjectOrNull(id)?.[kinshipRelationFieldId] as string | undefined : undefined;
        return { value, version: 1 };
      },
      areValuesEquals: (value1, value2) => equals(value1?.value, value2?.value),
    },
  }),
});
