import type { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { compareNumber, comparing, joinObjects } from 'yooi-utils';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import LeftPanelLoader from '../../../../components/molecules/LeftPanelLoader';
import MasterDetailList from '../../../../components/molecules/MasterDetailList';
import { TableSortDirection } from '../../../../components/molecules/Table';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { remToPx } from '../../../../utils/sizeUtils';
import { SizeContextProvider, SizeVariant } from '../../../../utils/useSizeContext';
import useTheme from '../../../../utils/useTheme';
import withAsyncTask from '../../../../utils/withAsyncTask';
import { FilterParams, useFilterStorage } from '../../../_global/filter/useFilterSessionStorage';
import useFilterAndSort from '../../../_global/useFilterAndSort';
import { EXPLORER_INSTANCE_FILTER_ID, getExplorerListComputeFunction } from '../_global/explorerUtils';
import { useExplorerHint } from '../_global/GetHintContextProvider';

const ExplorerMasterDetail: FunctionComponent = withAsyncTask(({ executeAsyncTask }) => {
  const theme = useTheme();

  const store = useStore();
  const getHint = useExplorerHint();

  const location = useLocation();

  const [search, setSearch] = useFilterStorage(EXPLORER_INSTANCE_FILTER_ID, FilterParams.nameSearch);

  const { status, value } = executeAsyncTask(getExplorerListComputeFunction, [store, getHint, search], [store.getSerial()]);

  const { generateList } = useFilterAndSort(
    'explorerHomeInstance',
    value.list,
    ({ score }) => (score.global > 0),
    {
      getComparatorHandler: (_, direction) => ({
        extractValue: ({ score: { global } }) => global,
        comparator: comparing(compareNumber, direction === TableSortDirection.desc),
      }),
      initial: { key: 'score', direction: TableSortDirection.desc },
    },
    undefined,
    [value.search],
    executeAsyncTask
  );

  return (
    <MasterDetailList
      list={
        generateList().list
          .map(({ item, ...entry }) => (joinObjects(
            entry,
            {
              to: () => ({ pathname: `/settings/explorer/instance/${item.key}`, hash: location.hash }),
              render: () => (
                <Tooltip title={`${item.key} (${item.hint})`}>
                  <Typo maxLine={1}>{item.hint ?? item.key}</Typo>
                  <SizeContextProvider sizeVariant={SizeVariant.small}>
                    <Typo color={theme.color.text.secondary} maxLine={1}>{item.typeHint ?? item.type ?? i18n`N/A`}</Typo>
                  </SizeContextProvider>
                </Tooltip>
              ),
              customHeight: remToPx(5.6),
            }
          )))
      }
      search={{ value: search, setValue: setSearch }}
      loading={status === 'loading'}
    />
  );
}, LeftPanelLoader);

export default ExplorerMasterDetail;
