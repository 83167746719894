import type { FunctionComponent } from 'react';
import type { GroupStoreObject } from 'yooi-modules/modules/conceptModule';
import { getConceptUrl } from 'yooi-modules/modules/conceptModule';
import { Concept_Name, ConceptNameDimension, Group, Group_Users, GroupMembershipDimension, StakeholdersField } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { StoreObject } from 'yooi-store';
import { joinObjects, newError } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import Banner, { BannerVariant } from '../../../components/molecules/Banner';
import { TableSortDirection } from '../../../components/molecules/Table';
import BlockContent from '../../../components/templates/BlockContent';
import DataTable from '../../../components/templates/DataTable';
import type { ColumnDefinition } from '../../../components/templates/internal/DataTableRenderer';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useAcl from '../../../store/useAcl';
import useAuth from '../../../store/useAuth';
import useStore from '../../../store/useStore';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useNavigation from '../../../utils/useNavigation';
import useNewLineFocus from '../../../utils/useNewLineFocus';
import { useSessionStorageState } from '../../../utils/useSessionStorage';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import { getFieldHandler } from '../../_global/fields/FieldLibrary';
import { getFieldColumnComparator } from '../../_global/fieldUtils';
import SearchTextButton from '../../_global/filter/SearchTextButton';
import type { FilterConfiguration } from '../../_global/filter/useFilterSessionStorage';
import { getConceptFilterFunction } from '../../_global/listFilterFunctions';
import type { NavigationFilter } from '../../_global/navigationUtils';
import { getNavigationPayload } from '../../_global/navigationUtils';
import { share } from '../../_global/shareUtils';
import useConceptDeleteModal from '../../_global/useConceptDeleteModal';
import useFilterAndSort from '../../_global/useFilterAndSort';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: spacingRem.s,
    paddingBottom: spacingRem.xs,
  },
}, 'groupsTab');

const GroupsTab: FunctionComponent = () => {
  const classes = useStyles();

  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();

  const navigation = useNavigation<NavigationFilter>();

  const [doDelete, deleteModal] = useConceptDeleteModal(false);

  const [filtersConfiguration] = useSessionStorageState<FilterConfiguration | undefined>('authorization-groups', {});
  const [newLineFocus, setNewLineFocus] = useNewLineFocus();
  const { generatePageList, doSort, sortCriteria, forceShowId } = useFilterAndSort(
    'authorization-groups',
    store.getObject(Group).navigateBack<GroupStoreObject>(Class_Instances),
    getConceptFilterFunction(store, Group, filtersConfiguration, loggedUserId),
    {
      getComparatorHandler: getFieldColumnComparator(store),
      initial: { key: Concept_Name, direction: TableSortDirection.asc },
    }
  );
  const { list, pagination } = generatePageList(25);

  const buildFieldColumnDefinition = (dimensionId: string, fieldId: string, width?: string | number): ColumnDefinition<GroupStoreObject> => {
    const fieldHandler = getFieldHandler(store, fieldId);
    const field = store.getObject(fieldId);
    if (fieldHandler?.getColumnDefinition !== undefined) {
      return joinObjects(
        fieldHandler.getColumnDefinition(),
        {
          width: width ?? fieldHandler.estimatedColumnWidth?.(Group),
          cellRender: (instance: StoreObject, focusOnMount: boolean) => (
            fieldHandler.renderField?.({
              dimensionsMapping: isInstanceOf(field, StakeholdersField) ? { n1InstanceId: instance.id } : { [dimensionId]: instance.id },
              readOnly: false,
              focusOnMount,
            }) ?? null
          ),
        }
      );
    } else {
      throw newError('Invalid field id', { fieldId });
    }
  };

  const columnsDefinition: ColumnDefinition<GroupStoreObject>[] = [
    buildFieldColumnDefinition(ConceptNameDimension, Concept_Name, 25),
    buildFieldColumnDefinition(GroupMembershipDimension, Group_Users),
  ];

  return (
    <VerticalBlock>
      <BlockContent padded>
        <Banner
          variant={BannerVariant.info}
          title={i18n`Group is a concept like any other on your platform, which you can customize (fields, layout, workflows...) via its configuration page.`}
          actions={[{
            key: 'open',
            icon: IconName.output,
            title: i18n`Open configuration`,
            onClick: () => {
              navigation.push(Group, { pathname: `/settings/organization/${Group}`, hash: '#instance' });
            },
          }]}
          fullWidth
        />
      </BlockContent>
      <VerticalBlock asBlockContent compact>
        <BlockContent padded>
          <div className={classes.headerContainer}>
            <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
              <SearchTextButton element="authorization-groups" placeholder={i18n`Search`} />
            </SizeContextProvider>
          </div>
        </BlockContent>
        <DataTable
          getNavigationPayload={(instance) => getNavigationPayload(navigation, instance.id, getConceptUrl(store, instance.id))}
          doSort={doSort}
          sortCriteria={sortCriteria}
          columnsDefinition={columnsDefinition}
          linesActions={({ id }) => [
            {
              key: 'open',
              icon: IconName.output,
              name: i18n`Open`,
              onClick: () => navigation.push(id, { pathname: getConceptUrl(store, id) }),
            },
            {
              key: 'share',
              icon: IconName.link,
              name: i18n`Copy link`,
              onClick: () => share(store, getNavigationPayload(navigation, id, `/settings/workflow/${id}`)),
            },
            {
              key: 'delete',
              icon: IconName.delete,
              name: i18n`Delete`,
              hidden: !aclHandler.canDeleteObject(id),
              onClick: () => doDelete(id),
              danger: true,
            },
          ]}
          list={list}
          pagination={pagination}
          newItemIcon={IconName.add}
          newItemTitle={i18n`Create`}
          onNewItem={() => {
            const groupId = store.createObject({ [Instance_Of]: Group });
            forceShowId(groupId);
            setNewLineFocus(groupId);
          }}
          newLineFocus={newLineFocus.current}
        />
        {deleteModal}
      </VerticalBlock>
    </VerticalBlock>
  );
};

export default GroupsTab;
