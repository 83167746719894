import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import type { FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { getFieldDimensionOfModelType, numberFieldHandler, timelineFieldHandler } from 'yooi-modules/modules/conceptModule';
import { DateField, Field_Formula, TimelineField } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { DateFieldStoreValue, DateRange as DateRangeStoreValue, StoredDateObject } from 'yooi-utils';
import { DateStorageTypeKeys, joinObjects, PeriodicityType } from 'yooi-utils';
import Tooltip from '../../../../../components/atoms/Tooltip';
import Typo from '../../../../../components/atoms/Typo';
import SpacedContainer from '../../../../../components/molecules/SpacedContainer';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import TooltipPopover from '../../../../../components/molecules/TooltipPopover';
import useStore from '../../../../../store/useStore';
import { Spacing } from '../../../../../theme/spacingDefinition';
import makeStyles from '../../../../../utils/makeStyles';
import useDerivedState from '../../../../../utils/useDerivedState';
import { SessionStorageKeys, useSessionStorageState } from '../../../../../utils/useSessionStorage';
import useSizeContext, { SizeVariant } from '../../../../../utils/useSizeContext';
import useTheme from '../../../../../utils/useTheme';
import { getFieldLabel } from '../../../fieldUtils';
import { getColorField, getTimelineField, getTimelineGroupByField, getTimelineProgressField } from '../../../modelTypeUtils';
import type { NavigationFilter } from '../../../navigationUtils';
import type { TimelineConfiguration } from '../../../sessionStorageTypes';
import type { TimelineViewResolvedDefinition } from '../../../views/timeline/timelineViewHandler';
import { getFieldHandler } from '../../FieldLibrary';
import ConceptTooltip from '../ConceptTooltip';
import { getDateFieldValue } from './conceptTimelineUtils';

const useStyles = makeStyles({
  firstColumnContainer: {
    width: '10rem',
  },
  container: {
    width: '41.2rem',
  },
  containerSmall: {
    width: '38.2rem',
  },
  inlineContainer: {
    minWidth: '15rem',
  },
}, 'conceptTimelineTooltip');

export interface TimelineTooltipUpdate {
  coloration?: string | null,
  progress?: number | null,
  groupBy?: string | null,
  period?: string,
  from?: StoredDateObject,
  to?: StoredDateObject,
  date?: number,
}

interface ConceptTimelineTooltipProps {
  conceptId: string,
  buttonView: boolean,
  editMode: boolean,
  currentAnchor: HTMLElement,
  handleClose: () => void,
  handleSubmit: (idParam: string, data: TimelineTooltipUpdate) => void,
  filterId: string,
  view?: TimelineViewResolvedDefinition,
  readOnly?: boolean,
  navigationFilters?: NavigationFilter,
}

const ConceptTimelineTooltip: FunctionComponent<ConceptTimelineTooltipProps> = ({
  conceptId,
  buttonView,
  editMode,
  currentAnchor,
  handleClose,
  handleSubmit,
  filterId,
  view,
  readOnly = false,
  navigationFilters,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const concept = store.getObject(conceptId);

  const [timelineConfig] = useSessionStorageState<TimelineConfiguration | undefined>(`${SessionStorageKeys.timelineConfig}_${filterId}`, undefined);

  const conceptDefinitionId = concept[Instance_Of] as string;
  const isInView = Boolean(view);
  const colorFieldId = getColorField(store, conceptDefinitionId, timelineConfig, view?.defaultTimelineColorFieldId, isInView)?.id;
  const progressFieldId = getTimelineProgressField(store, conceptDefinitionId, timelineConfig, view?.defaultTimelineProgressFieldId, isInView)?.id;
  const groupByField = getTimelineGroupByField(store, conceptDefinitionId, timelineConfig, view?.defaultTimelineGroupByFieldId, isInView);
  const timelineField = getTimelineField(store, conceptDefinitionId, timelineConfig, view?.defaultTimelineFieldId, isInView);

  const colorField = colorFieldId ? store.getObjectOrNull<FieldStoreObject>(colorFieldId) : undefined;
  const colorFieldHandler = colorFieldId ? getFieldHandler(store, colorFieldId) : undefined;
  const colorFieldDimensionId = colorFieldId && colorFieldHandler ? getFieldDimensionOfModelType(store, colorFieldId, conceptDefinitionId) : undefined;
  const [coloration, setColoration, resetColoration] = useDerivedState<string | undefined | null>(
    () => (colorFieldId ? concept[colorFieldId] as string : undefined),
    [colorFieldId ? concept[colorFieldId] : undefined]
  );

  const progressField = progressFieldId ? store.getObjectOrNull<FieldStoreObject>(progressFieldId) : undefined;
  const progressFieldHandler = progressFieldId ? getFieldHandler(store, progressFieldId) : undefined;
  const progressFieldDimensionId = progressFieldId && progressFieldHandler ? getFieldDimensionOfModelType(store, progressFieldId, conceptDefinitionId) : undefined;

  let isProgressComputed = false;
  if (progressFieldId && progressFieldDimensionId) {
    isProgressComputed = numberFieldHandler(store, progressFieldId).getValueResolution({ [progressFieldDimensionId]: concept.id })?.isComputed;
  }
  const [progress, setProgress, resetProgress] = useDerivedState<number | undefined | null>(() => {
    if (progressFieldId && progressFieldDimensionId) {
      const { value } = numberFieldHandler(store, progressFieldId).getValueResolution({ [progressFieldDimensionId]: concept.id });
      return typeof value === 'number' ? value : undefined;
    }
    return undefined;
  }, [progressFieldId ? concept[progressFieldId] : undefined]);

  const groupByFieldHandler = groupByField?.id ? getFieldHandler(store, groupByField.id) : undefined;
  const groupByFieldDimensionId = groupByField && groupByFieldHandler ? getFieldDimensionOfModelType(store, groupByField.id, conceptDefinitionId) : undefined;
  const [groupBy, setGroupBy, resetGroupBy] = useDerivedState<string | undefined | null>(
    () => (groupByField ? concept[groupByField?.id] as string | undefined : undefined),
    [groupByField ? concept[groupByField?.id] : undefined]
  );

  const fieldHandler = timelineField?.id ? getFieldHandler(store, timelineField.id) : undefined;
  const fieldDimensionId = timelineField && fieldHandler ? getFieldDimensionOfModelType(store, timelineField.id, conceptDefinitionId) : undefined;

  let initialPopUpDate: DateRangeStoreValue | DateFieldStoreValue | null | undefined;
  if (fieldDimensionId && timelineField && isInstanceOf(timelineField, TimelineField)) {
    const handler = timelineFieldHandler(store, timelineField.id);
    const configuration = handler.resolveConfiguration();
    const configuredValue = handler.getStoreValue({ [fieldDimensionId]: concept.id });
    initialPopUpDate = {
      period: configuredValue?.period ?? configuration.defaultPeriod ?? PeriodicityType.day,
      from: configuredValue?.from ?? { type: configuration.startConstraint ? DateStorageTypeKeys.constraint : DateStorageTypeKeys.date },
      to: configuredValue?.to,
    };
  } else if (fieldDimensionId && timelineField && isInstanceOf(timelineField, DateField)) {
    initialPopUpDate = getDateFieldValue(store, conceptId, timelineField.id);
  }

  const [popupDate, setPopupDate, resetPopupDate] = useDerivedState(() => initialPopUpDate, [initialPopUpDate]);

  const { sizeVariant } = useSizeContext();
  const handleCloseAndSubmit = () => {
    const popupDateToSend = popupDate;
    const groupByToSend = groupBy;
    const progressToSend = progress;
    const colorationToSend = coloration;
    resetPopupDate();
    resetGroupBy();
    resetProgress();
    resetColoration();
    handleClose();
    handleSubmit(conceptId, joinObjects(
      popupDateToSend,
      colorFieldId && colorationToSend !== concept[colorFieldId] ? { coloration: colorationToSend } : {},
      progressToSend !== (progressFieldId && progressFieldDimensionId ? numberFieldHandler(store, progressFieldId)
        .getValueResolution({ [progressFieldDimensionId]: concept.id }).value : undefined)
      && !isProgressComputed ? { progress: progressToSend } : {},
      groupByField && groupByToSend !== concept[groupByField?.id] ? { groupBy: groupByToSend } : {}
    ));
  };

  return (
    <TooltipPopover
      focusOnMount={editMode}
      currentAnchor={currentAnchor}
      onClose={editMode ? () => {
        handleCloseAndSubmit();
      } : () => handleClose()}
      onEscape={editMode ? () => {
        handleClose();
      } : undefined}
    >
      <div
        className={classnames({
          [classes.container]: true,
          [classes.containerSmall]: sizeVariant === SizeVariant.small,
        })}
      >
        <ConceptTooltip
          conceptId={concept.id}
          buttonView={buttonView}
          editMode={editMode}
          navigationFilters={navigationFilters}
        >
          {colorFieldId && colorField && colorFieldHandler && colorFieldDimensionId ? (
            <SpacedContainer margin={{ bottom: Spacing.s }}>
              <SpacingLine>
                <Tooltip title={getFieldLabel(store, colorField)}>
                  <div className={classes.firstColumnContainer}>
                    <Typo noWrap color={theme.color.text.secondary}>{getFieldLabel(store, colorField)}</Typo>
                  </div>
                </Tooltip>
                <div className={classes.inlineContainer}>
                  {colorFieldHandler.renderField?.({
                    dimensionsMapping: { [colorFieldDimensionId]: concept.id },
                    value: coloration,
                    onSubmit: (value) => setColoration(value as string | undefined | null),
                    readOnly,
                  })}
                </div>
              </SpacingLine>
            </SpacedContainer>
          ) : null}
          {progressFieldId && progressField && progressFieldHandler && progressFieldDimensionId ? (
            <SpacedContainer margin={{ bottom: Spacing.s }}>
              <SpacingLine>
                <Tooltip title={getFieldLabel(store, progressField)}>
                  <div className={classes.firstColumnContainer}>
                    <Typo noWrap color={theme.color.text.secondary}>{getFieldLabel(store, progressField)}</Typo>
                  </div>
                </Tooltip>
                <div className={classes.inlineContainer}>
                  {progressFieldHandler.renderField?.({
                    dimensionsMapping: { [progressFieldDimensionId]: concept.id },
                    value: progress ?? undefined,
                    onSubmit: (v) => setProgress(v as number | null),
                    readOnly,
                  })}
                </div>
              </SpacingLine>
            </SpacedContainer>
          ) : null}
          {groupByField && groupByFieldHandler && groupByFieldDimensionId && (groupByField?.id !== colorFieldId) ? (
            <SpacedContainer margin={{ bottom: Spacing.s }}>
              <SpacingLine>
                <Tooltip title={getFieldLabel(store, groupByField)}>
                  <div className={classes.firstColumnContainer}>
                    <Typo noWrap color={theme.color.text.secondary}>{getFieldLabel(store, groupByField)}</Typo>
                  </div>
                </Tooltip>
                <div className={classes.inlineContainer}>
                  {groupByFieldHandler.renderField?.({
                    dimensionsMapping: { [groupByFieldDimensionId]: concept.id },
                    value: groupBy,
                    onSubmit: (value) => setGroupBy(value as string | undefined | null),
                    readOnly,
                  })}
                </div>
              </SpacingLine>
            </SpacedContainer>
          ) : null}
          {timelineField && fieldHandler && fieldDimensionId && isInstanceOf(timelineField, DateField) ? (
            <SpacedContainer margin={{ bottom: Spacing.s }}>
              <SpacingLine>
                <Tooltip title={getFieldLabel(store, timelineField)}>
                  <div className={classes.firstColumnContainer}>
                    <Typo noWrap color={theme.color.text.secondary}>{getFieldLabel(store, timelineField)}</Typo>
                  </div>
                </Tooltip>
                <div className={classes.inlineContainer}>
                  {fieldHandler.renderField?.({
                    dimensionsMapping: { [fieldDimensionId]: concept.id },
                    value: popupDate,
                    onSubmit: (value) => setPopupDate(value as DateFieldStoreValue | undefined),
                    readOnly: readOnly || Boolean(timelineField[Field_Formula]),
                  })}
                </div>
              </SpacingLine>
            </SpacedContainer>
          ) : null}
          {timelineField && fieldHandler && fieldDimensionId && isInstanceOf(timelineField, TimelineField) ? (
            <SpacedContainer margin={{ bottom: Spacing.s }}>
              <SpacingLine>
                <Tooltip title={getFieldLabel(store, timelineField)}>
                  <div className={classes.firstColumnContainer}>
                    <Typo noWrap color={theme.color.text.secondary}>{getFieldLabel(store, timelineField)}</Typo>
                  </div>
                </Tooltip>
                <div className={classes.inlineContainer}>
                  {fieldHandler.renderField?.({
                    dimensionsMapping: { [fieldDimensionId]: concept.id },
                    value: popupDate,
                    onSubmit: (value) => setPopupDate(value as undefined | DateRangeStoreValue),
                    readOnly,
                  })}
                </div>
              </SpacingLine>
            </SpacedContainer>
          ) : null}
        </ConceptTooltip>
      </div>
    </TooltipPopover>
  );
};

export default ConceptTimelineTooltip;
