import type { FormulaFunction, Parameter } from 'fast-formula-parser';
import FormulaParser from 'fast-formula-parser';
import { v4 as uuid } from 'uuid';
import { compareString } from '../comparators';
import { newError } from '../errorUtils';
import { createFunctionLibrary } from './formulaEngineFunctionLibrary';

const { Address, FormulaHelpers } = FormulaParser;
export const { FormulaError } = FormulaParser;

type FormulaEngineParameter<T> = Parameter<T>;
export type FormulaEngineFunction<Params extends FormulaEngineParameter<unknown>[] = FormulaEngineParameter<unknown>[]> = FormulaFunction<Params>;
export const FormulaEngineHelpers = FormulaHelpers;

interface FormulaEngine {
  executeFormula: (formula: string, inputs: Record<string, { isMatrix?: boolean, value?: unknown }>) => unknown,
  getDefinedFunctions: () => string[],
  getSupportedFunctions: () => string[],
}

export const createFormulaEngine = (extraFunctions?: Record<string, FormulaFunction>): FormulaEngine => {
  const notSupported = () => undefined;
  const createFunctions = () => {
    const formulaParser = new FormulaParser();

    const functions = createFunctionLibrary(formulaParser.functions, extraFunctions);
    formulaParser.supportedFunctions().forEach((name) => {
      if (!functions[name]) {
        functions[name] = notSupported;
      }
    });

    return functions;
  };

  const functions = createFunctions();

  const getDefinedFunctions: FormulaEngine['getDefinedFunctions'] = () => (
    Object.entries(functions)
      .filter(([, fun]) => fun && fun !== notSupported)
      .map(([name]) => name)
      .sort(compareString)
  );
  const getSupportedFunctions: FormulaEngine['getSupportedFunctions'] = () => new FormulaParser({ functions }).supportedFunctions();

  const formulaParserPool: FormulaParser[] = [];

  const executeFormula: FormulaEngine['executeFormula'] = (formula, inputs) => {
    const formulaParser = formulaParserPool.splice(0, 1)[0] ?? new FormulaParser({ functions });
    try {
      if (!formula) {
        throw newError('Formula is missing');
      }

      const getInput = (name: string): { isMatrix?: boolean, value?: unknown } => {
        const input = inputs[name.toLowerCase()];
        if (input) {
          return input;
        } else {
          throw newError('Input is not defined', { name });
        }
      };

      const currentSheet = uuid();
      const cellValues: Record<string, { value: unknown }> = {
        null: { value: null },
        true: { value: true },
        false: { value: false },
      };
      const rangeValues: Record<string, { rangeTo: { row: number, col: number }, value: unknown }> = {};

      formulaParser.onVariable = (name) => {
        const nameLowerCase = name.toLowerCase();
        if (nameLowerCase === 'null' || nameLowerCase === 'true' || nameLowerCase === 'false') {
          return { sheet: nameLowerCase, col: 1, row: 1 };
        }
        const { isMatrix, value } = getInput(name);
        const sheet = uuid(); // protect usage of variable mapped cell
        if (isMatrix) {
          const len = (value as unknown[]).length;
          const to = { row: Math.max(1, len), col: 1 };
          rangeValues[sheet] = { rangeTo: to, value };
          return { sheet, from: { row: 1, col: 1 }, to };
        } else {
          cellValues[sheet] = { value };
          return { sheet, row: 1, col: 1 };
        }
      };

      formulaParser.onCell = ({ sheet, row, col }) => {
        if (row === 1 && col === 1 && cellValues[sheet]) {
          return cellValues[sheet].value;
        } else if (sheet === currentSheet) {
          // short variable name are recognized as reference by parser. In that case cell reference are converted to variable name
          // do only that for reference on the current sheet, since reference on other sheet cannot correspond to a variable name
          const varName = `${Address.columnNumberToName(col)}${row ?? ''}`;
          return getInput(varName).value;
        } else {
          throw newError('Unknown cell', { sheet, row, col });
        }
      };

      formulaParser.onRange = ({ sheet, from, to }) => {
        if (from.row === 1 && from.col === 1 && rangeValues[sheet]) {
          const { rangeTo, value } = rangeValues[sheet];
          if (rangeTo.row === to.row && rangeTo.col === to.col) {
            return value;
          }
        }
        throw newError('Unknown range', { sheet, from, to });
      };

      const result = formulaParser.parse(formula, { row: 1, col: 1, sheet: currentSheet }, true);
      if (result === FormulaError.NULL || result === null) {
        // Result is FormulaError.NULL or null, this is not an issue, we just didn't resolve anything, return undefined
        return undefined;
      } else if (result instanceof Error) {
        // Result is any FormulaError, throw the error
        throw result;
      } else {
        // Otherwise, just returns the resolved value
        return result;
      }
    } finally {
      formulaParserPool.push(formulaParser);
    }
  };

  return {
    getDefinedFunctions,
    getSupportedFunctions,
    executeFormula,
  };
};
