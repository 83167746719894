import type { MatrixViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import { ViewType } from 'yooi-modules/modules/dashboardModule';
import { IconName } from '../../../../components/atoms/Icon';
import base from '../../../../theme/base';
import i18n from '../../../../utils/i18n';
import type { CommonViewResolvedDefinition } from '../common/commonViewResolvedDefinition';
import { getViewConceptConceptError } from '../common/viewUtils';
import type { ViewDefinitionHandler } from '../viewDsl';
import { registerView } from '../viewDsl';
import MatrixViewBlock from './MatrixViewBlock';
import MatrixViewDefinitionOptions from './MatrixViewDefinitionOptions';
import MatrixViewOptions from './MatrixViewOptions';
import type { MatrixViewResolution } from './matrixViewResolution';
import { resolveMatrixView } from './matrixViewResolution';
import MatrixViewWidget from './MatrixViewWidget';

export interface MatrixViewResolvedDefinition extends CommonViewResolvedDefinition {
  type: ViewType.Matrix,
  yAxisFieldId: string | undefined,
  xAxisFieldId: string | undefined,
  dependenciesFieldId: string | undefined,
  labelFieldId: string | undefined,
  colorFieldId: string | undefined,
  topLeftQuadrant: { color: string, name: string | undefined },
  topRightQuadrant: { color: string, name: string | undefined },
  bottomLeftQuadrant: { color: string, name: string | undefined },
  bottomRightQuadrant: { color: string, name: string | undefined },
  showBacklog: boolean,
}

export type MatrixViewDefinitionHandler = ViewDefinitionHandler<MatrixViewStoredDefinition, MatrixViewResolvedDefinition, MatrixViewResolution>;

export const matrixViewHandler = registerView<MatrixViewStoredDefinition, MatrixViewResolvedDefinition, MatrixViewResolution>({
  type: ViewType.Matrix,
  icon: IconName.border_outer,
  getLabel: () => i18n`Matrix`,
  optionType: 'line',
  withFilters: true,
  withExport: false,
  resolveView: (store, viewDefinition, { viewDimensions, parametersMapping, filterConfiguration }) => (
    resolveMatrixView(store, viewDefinition, viewDimensions, parametersMapping, filterConfiguration)
  ),
  renderBlock: (_, viewDefinition, { viewDimensions, viewFilters, layoutParametersMapping, readOnly }) => (
    <MatrixViewBlock
      viewDimensions={viewDimensions}
      viewFilters={viewFilters}
      viewDefinition={viewDefinition}
      parametersMapping={layoutParametersMapping}
      readOnly={readOnly}
    />
  ),
  renderWidget: (_, viewDefinition, { viewDimensions, viewFilters, parametersMapping, width, height, readOnly }) => (
    <MatrixViewWidget
      viewDimensions={viewDimensions}
      viewFilters={viewFilters}
      viewDefinition={viewDefinition}
      parametersMapping={parametersMapping}
      width={width}
      height={height}
      readOnly={readOnly}
    />
  ),
  hasOptions: () => true,
  renderOptions: (_, viewDefinition, { viewDimensions, viewFilters, widgetId }) => (
    <MatrixViewOptions
      filterKey={viewFilters.filterKey}
      viewDefinition={viewDefinition}
      viewDimensions={viewDimensions}
      widgetId={widgetId}
    />
  ),
  renderDefinitionOptions: (_, viewDefinition, { viewDimensions, updateViewDefinition, readOnly, parameterDefinitions }) => (
    <MatrixViewDefinitionOptions
      viewDimensions={viewDimensions}
      viewDefinition={viewDefinition}
      updateViewDefinition={updateViewDefinition}
      readOnly={readOnly}
      parameterDefinitions={parameterDefinitions}
    />
  ),
  getDefinitionErrors: (store, viewResolution, viewDimensions, parameters) => {
    const viewConceptConceptError = getViewConceptConceptError(store, viewDimensions, parameters, true);
    if (viewConceptConceptError) {
      return [viewConceptConceptError];
    }
    const errors: string[] = [];
    if (viewResolution.yAxisFieldId === undefined) {
      errors.push(i18n`Y-axis is not defined.`);
    } else if (store.getObjectOrNull(viewResolution.yAxisFieldId) === null) {
      errors.push(i18n`Y-axis is invalid.`);
    }
    if (viewResolution.xAxisFieldId === undefined) {
      errors.push(i18n`X-axis is not defined.`);
    } else if (store.getObjectOrNull(viewResolution.xAxisFieldId) === null) {
      errors.push(i18n`X-axis is invalid.`);
    }

    if (errors.length > 0) {
      return errors;
    } else {
      return undefined;
    }
  },
  resolveDefinition: (_, viewDefinition) => ({
    id: viewDefinition.id,
    type: viewDefinition.type,
    label: viewDefinition.label !== undefined && viewDefinition.label !== '' ? viewDefinition.label : i18n`Matrix`,
    readOnly: viewDefinition.readOnly ?? false,
    yAxisFieldId: viewDefinition.yAxisFieldId,
    xAxisFieldId: viewDefinition.xAxisFieldId,
    dependenciesFieldId: viewDefinition.dependenciesFieldId,
    labelFieldId: viewDefinition.labelFieldId,
    colorFieldId: viewDefinition.colorFieldId,
    topLeftQuadrant: { color: viewDefinition.topLeftQuadrant?.color ?? base.color.peach['100'], name: viewDefinition.topLeftQuadrant?.name },
    topRightQuadrant: { color: viewDefinition.topRightQuadrant?.color ?? base.color.teagreen['100'], name: viewDefinition.topRightQuadrant?.name },
    bottomLeftQuadrant: { color: viewDefinition.bottomLeftQuadrant?.color ?? base.color.purple['100'], name: viewDefinition.bottomLeftQuadrant?.name },
    bottomRightQuadrant: { color: viewDefinition.bottomRightQuadrant?.color ?? base.color.cyan['100'], name: viewDefinition.bottomRightQuadrant?.name },
    showBacklog: viewDefinition.showBacklog ?? false,
  } satisfies MatrixViewResolvedDefinition),
  getInitialStoredDefinition: (id) => ({ id, type: ViewType.Matrix }),
});
