import type { XYCoord } from 'dnd-core';
import type { FunctionComponent, ReactElement, RefObject } from 'react';
import { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { isFilterNode } from 'yooi-modules/modules/conceptModule';
import { remToPx } from '../../../../utils/sizeUtils';
import type { FrontFilters } from '../filterUtils';
import { DropPlacement } from '../filterUtils';
import { useDragAndDropContext, useNotifyOnHoverBus } from './DragAndDropManager';
import DragItemPlaceholder from './DragItemPlaceholder';

interface DropItemProps {
  filters: FrontFilters,
  children: (props: { isInside: boolean, dropRef: RefObject<HTMLDivElement> }) => ReactElement,
}

const DropItem: FunctionComponent<DropItemProps> = ({ filters, children }) => {
  const context = useDragAndDropContext();
  const notifyOnHover = useNotifyOnHoverBus();

  const dropRef = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop(() => ({
    accept: ['Node'],
    hover(item: { id: string }, monitor) {
      if (!dropRef.current || item.id === filters.id || !monitor.isOver({ shallow: true })) {
        return;
      }
      const hoverBoundingRect = dropRef.current?.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset() as XYCoord;
      if (isFilterNode(filters)) {
        const isEmpty = !filters.children || filters.children.length === 0 || (filters.children.length === 1 && filters.children[0].id === item.id);
        const avgPaddingTopAndBottom = remToPx(0.6);
        if (clientOffset.y - hoverBoundingRect.top < avgPaddingTopAndBottom) {
          notifyOnHover({ itemId: filters.id, dropPlacement: DropPlacement.before });
        } else if (hoverBoundingRect.bottom - clientOffset.y < avgPaddingTopAndBottom) {
          notifyOnHover({ itemId: filters.id, dropPlacement: DropPlacement.after });
        } else if (isEmpty) {
          notifyOnHover({ itemId: filters.id, dropPlacement: DropPlacement.inside });
        }
      } else {
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        if (hoverClientY <= hoverMiddleY) {
          notifyOnHover({ itemId: filters.id, dropPlacement: DropPlacement.before });
        } else {
          notifyOnHover({ itemId: filters.id, dropPlacement: DropPlacement.after });
        }
      }
    },
  }), [notifyOnHover, filters]);
  drop(dropRef);

  return (
    <>
      {context?.hoverState?.itemId === filters.id && context?.hoverState?.dropPlacement === DropPlacement.before && (
        <DragItemPlaceholder />
      )}
      {children({ isInside: context?.hoverState?.itemId === filters.id && context?.hoverState?.dropPlacement === DropPlacement.inside, dropRef })}
      {context?.hoverState?.itemId === filters.id && context?.hoverState?.dropPlacement === DropPlacement.after && (
        <DragItemPlaceholder />
      )}
    </>
  );
};

export default DropItem;
