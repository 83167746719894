import classnames from 'classnames';
import type { ChangeEvent, KeyboardEventHandler, ReactElement } from 'react';
import { Fragment, useMemo, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { compareString, comparing, extractAndCompareValue, filterNullOrUndefined, joinObjects, pushUndefinedToEnd } from 'yooi-utils';
import { Spacing, spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { sanitizeSearchValue } from '../../utils/searchUtils';
import { remToPx } from '../../utils/sizeUtils';
import useDerivedState from '../../utils/useDerivedState';
import useFocusOnMount from '../../utils/useFocusOnMount';
import type { NavigationPayload, UseNavigation } from '../../utils/useNavigation';
import useNavigation from '../../utils/useNavigation';
import useSizeContext, { buildInputSizeVariantClasses, getComponentSizeInRem, HierarchyVariant, SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import useUsageContext, { UsageContextProvider, UsageVariant } from '../../utils/useUsageContext';
import Button from '../atoms/Button';
import Icon, { IconColorVariant, IconName } from '../atoms/Icon';
import Typo, { sizeVariantToTypoVariant } from '../atoms/Typo';
import Chip from './Chip';
import type { EditableCloseReasons } from './EditableWithDropdown';
import EditableWithDropdown from './EditableWithDropdown';
import type { InlineCreationInline, InlineCreationTransactional } from './inlineCreationTypes';
import type { ListItem, ListNavigationHandler } from './ItemList';
import ItemList from './ItemList';
import ItemListNavigableChipItem from './ItemListNavigableChipItem';
import SpacedContainer from './SpacedContainer';
import SpacingLine from './SpacingLine';

const useStyles = makeStyles((theme) => ({
  editorContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginLeft: spacingRem.s,
    marginRight: spacingRem.xs,
  },
  // as cell width can be restricted by column max-width, we need to ensure that
  // container width is equal to his parent width minus his container margin in order to cut chip correctly
  editorContainerInCell: {
    marginBottom: spacingRem.xs,
    marginTop: spacingRem.xs,
    width: `calc(100% - 2 * ${spacingRem.s})`,
  },
  valueContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  valueSingleLine: {
    overflow: 'hidden',
  },
  valueMultiLine: {
    flexWrap: 'wrap',
  },
  optionList: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
    maxHeight: '20rem',
  },
  option: {
    display: 'flex',
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    cursor: 'pointer',
    '&:focus': {
      background: theme.color.background.primarylight.default,
    },
    alignItems: 'center',
  },
  ...buildInputSizeVariantClasses('optionSize', ['minHeight', 'height']),
  textarea: {
    border: 'none',
    backgroundColor: theme.color.transparent,
    resize: 'none',
    padding: '0',
    flexGrow: 1,
    margin: '0.3rem 0',
    color: theme.color.text.primary,
  },
  errorContainer: {
    marginLeft: spacingRem.xs,
    marginRight: spacingRem.xs,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorContainerInDropdown: {
    minHeight: '3rem',
  },
  chipContainer: {
    display: 'inline-flex',
    overflow: 'hidden',
  },
  chipContainerPadded: {
    paddingTop: spacingRem.xxs,
    paddingBottom: spacingRem.xxs,
  },
  chipContainerMain: {
    columnGap: spacingRem.s,
    rowGap: spacingRem.xs,
  },
  chipContainerSmall: {
    columnGap: spacingRem.xs,
    rowGap: spacingRem.xs,
  },
  chipContainerNoShirk: {
    '& > *': {
      flexShrink: 0,
    },
  },
  greyBar: {
    flexGrow: 1,
    borderBottomWidth: '0.2rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
  },
  transactionalFieldContainer: {
    display: 'grid',
    gridTemplateColumns: '8rem minmax(auto, 25.2rem)',
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    paddingBottom: spacingRem.xs,
    paddingTop: spacingRem.xs,
    alignItems: 'center',
  },
  transactionButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    paddingBottom: spacingRem.xs,
    paddingTop: spacingRem.xs,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}), 'searchAndSelectMultiple');

interface Option {
  id: string,
  label: string,
  tooltip?: string,
  icon?: IconName | { name: IconName, colorVariant: IconColorVariant } | { name: IconName, color: string },
  squareColor?: string,
  noDelete?: boolean,
  color?: string,
  borderColor?: string,
  borderStyle?: 'solid' | 'dashed',
  getNavigationPayload?: (navigation: UseNavigation) => NavigationPayload,
  startIcons?: { key: string, icon: IconName, colorVariant?: IconColorVariant, color?: string, tooltip?: string }[],
  endIcons?: { key: string, icon: IconName, colorVariant?: IconColorVariant, color?: string, tooltip?: string }[],
}

interface OptionsHandler<T extends Option = Option> {
  filterOptions: (search: string) => T[],
  perfectMatch: (search: string) => boolean,
}

export interface SearchAndSelectMultipleProps<T extends Option = Option> {
  selectedOptions?: T[],
  selectedSteps?: T[],
  shouldDisplayChevron?: (previousStep: T, nextStep: T, steps: T[]) => boolean,
  computeOptions?: (selectedSteps: T[] | undefined) => T[],
  readOnly?: boolean,
  onSelect?: (option: T, selectedSteps?: T[]) => void,
  onDelete?: (option: T) => void,
  getInlineCreation?: () => (InlineCreationInline | InlineCreationTransactional),
  searchOptions?: { searchKeys: string[], extractValue: (option: T, searchKey: string) => (string | undefined) },
  placeholder?: string,
  warning?: string,
  error?: string,
  forceSingleLine?: boolean,
  focusOnMount?: boolean,
  isEditing?: boolean,
  onEditionStart?: () => void,
  onEditionStop?: (reason: EditableCloseReasons) => void,
  restingTooltip?: string | (() => Promise<string>),
}

const SearchAndSelectMultiple = <T extends Option = Option>({
  selectedOptions = [],
  selectedSteps,
  shouldDisplayChevron,
  computeOptions,
  readOnly = false,
  onSelect,
  onDelete,
  getInlineCreation,
  searchOptions,
  placeholder,
  warning,
  error,
  forceSingleLine = false,
  focusOnMount = false,
  isEditing = false,
  onEditionStart,
  onEditionStop,
  restingTooltip,
}: SearchAndSelectMultipleProps<T>): ReactElement | null => {
  const theme = useTheme();
  const classes = useStyles();
  const navigation = useNavigation();

  const { sizeVariant } = useSizeContext();
  const typoVariant = sizeVariantToTypoVariant[sizeVariant];

  const usageVariant = useUsageContext();
  const isMainVariant = usageVariant === UsageVariant.inline;
  const isTableVariant = usageVariant === UsageVariant.inTable;
  const isInFormVariant = usageVariant === UsageVariant.inForm;

  const [showDropdown, setShowDropdown] = useState(focusOnMount);
  const [searchInput, setSearchInput] = useDerivedState(() => '', [showDropdown]);

  const inlineCreationRef = useRef<InlineCreationInline | InlineCreationTransactional | undefined>(undefined);
  if (inlineCreationRef.current === undefined && getInlineCreation && searchInput !== '') {
    inlineCreationRef.current = getInlineCreation();
  } else if (inlineCreationRef.current && !showDropdown) {
    inlineCreationRef.current = undefined;
  }
  const inlineCreation = inlineCreationRef.current;
  const [inlineCreationValues, setInlineCreationValues] = useDerivedState<Record<string, unknown> | undefined>(() => undefined, [showDropdown]);

  const textAreaFocusRef = useRef<HTMLTextAreaElement>(null);
  useFocusOnMount(textAreaFocusRef, showDropdown);
  const lazyLoadNavigationRef = useRef<ListNavigationHandler>(null);

  const [hoverItem, setHoverItem] = useDerivedState<{ index: number, source?: string }>(() => ({ index: -1 }), [showDropdown, searchInput]);

  const { filterOptions, perfectMatch } = useMemo<OptionsHandler<T>>(() => {
    // only calculate in when dropdown is shown, else large tables will have a big performance penalty
    if (showDropdown && computeOptions && !readOnly) {
      const options = computeOptions(selectedSteps);
      let lazyLoadedSearchableOptions: { option: T, searchValues: string[] }[] | undefined;
      const getSearchableOptions = () => {
        // only generate the searchableOptions list if someone is actually searching for something
        if (!lazyLoadedSearchableOptions) {
          if (searchOptions) {
            lazyLoadedSearchableOptions = options.map((option) => ({
              option,
              searchValues: searchOptions.searchKeys.map((key) => searchOptions.extractValue(option, key)).filter(filterNullOrUndefined).map(sanitizeSearchValue),
            }));
          } else {
            lazyLoadedSearchableOptions = options.map((option) => ({ option, searchValues: [sanitizeSearchValue(option.label)] }));
          }
        }
        return lazyLoadedSearchableOptions;
      };

      return {
        filterOptions: (search) => {
          if (search) {
            const sanitizedSearch = sanitizeSearchValue(search);
            return getSearchableOptions().filter(({ searchValues }) => searchValues.some((value) => value.includes(sanitizedSearch))).map(({ option }) => option);
          } else {
            return options;
          }
        },
        perfectMatch: (search) => {
          if (search) {
            const sanitizedSearch = sanitizeSearchValue(search);
            return getSearchableOptions().some(({ searchValues }) => searchValues.some((value) => value === sanitizedSearch));
          } else {
            return false;
          }
        },
      };
    } else {
      return { filterOptions: () => [], perfectMatch: () => false };
    }
  }, [showDropdown, computeOptions, readOnly, selectedSteps, searchOptions]);

  const filteredOptions = selectedSteps
    ? filterOptions(searchInput)
    : filterOptions(searchInput).filter((option) => !selectedOptions.some(({ id }) => id === option.id));

  const openDropdown = () => {
    setShowDropdown(true);
    onEditionStart?.();
  };

  const closeDropdown = (reason: EditableCloseReasons) => {
    setShowDropdown(false);
    onEditionStop?.(reason);
  };

  const handleSelect = (option: T) => {
    setSearchInput('');
    textAreaFocusRef.current?.focus();
    setInlineCreationValues(undefined);
    // if the last element is selected, then update the hover item on the next last element (ie the element before the selected one)
    if (hoverItem.index >= filteredOptions.length - 1) {
      setHoverItem({ index: filteredOptions.length - 2 });
    }
    onSelect?.(option, selectedSteps);
  };

  const handleDropdownKeyDown: KeyboardEventHandler<HTMLElement> = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // To prevent a line-break
      event.stopPropagation(); // prevent queryTable to (probably queryTable to should not handle Enter but provide a focus (tabindex) on the Open button)
      lazyLoadNavigationRef.current?.onEnter();
    } else if (event.key === 'ArrowUp') {
      lazyLoadNavigationRef.current?.onUp();
    } else if (event.key === 'ArrowDown') {
      lazyLoadNavigationRef.current?.onDown();
    }
  };

  const renderOption = (option: T, context: 'value' | 'option', inDropdown = false) => {
    const actions = [];
    if (option.getNavigationPayload) {
      const navigationPayload = option.getNavigationPayload(navigation);
      if (context === 'value') {
        actions.push({
          key: 'open',
          icon: IconName.output,
          tooltip: i18n`Open`,
          action: { to: navigationPayload.to, state: navigationPayload.state, openInNewTab: false },
          showOnHover: true,
        });
      }
    }
    if (inDropdown && context === 'value' && !readOnly && !option.noDelete && onDelete) {
      actions.push({
        key: 'remove',
        icon: IconName.close,
        tooltip: i18n`Remove`,
        action: () => {
          onDelete?.(option);
          textAreaFocusRef.current?.focus();
        },
      });
    }
    return (
      <Chip
        text={option.label}
        tooltip={option.tooltip}
        squareColor={option.squareColor}
        icon={option.icon}
        color={option.color}
        borderColor={option.borderColor}
        borderStyle={option.borderStyle}
        startIcons={option.startIcons}
        endIcons={option.endIcons}
        actions={actions}
      />
    );
  };

  const isMultiLineVariant = !forceSingleLine && (isInFormVariant || isMainVariant);

  const renderSelected = (inDropdown: boolean) => {
    if ((selectedSteps && selectedSteps.length > 0) || selectedOptions.length > 0) {
      const selection = selectedSteps ?? selectedOptions
        .sort(comparing(extractAndCompareValue((option: T) => option.label, pushUndefinedToEnd))
          .thenComparing(extractAndCompareValue((option: T) => option.label, compareString)));

      return selection.filter((option) => !!option)
        .map((option, index) => (
          <Fragment key={option.id}>
            {renderOption(option, 'value', inDropdown)}
            {(selectedSteps && index !== selection.length - 1 && (shouldDisplayChevron === undefined || shouldDisplayChevron(option, selection[index + 1], selection))) ? (
              <span className={classes.iconContainer}><Icon name={IconName.keyboard_arrow_right} /></span>
            ) : null}
          </Fragment>
        ));
    } else if (placeholder && !inDropdown && !readOnly) {
      return (
        <Typo
          maxLine={1}
          color={isInFormVariant ? theme.color.text.disabled : theme.color.text.disabled}
        >
          {placeholder}
        </Typo>
      );
    } else {
      return undefined;
    }
  };

  const renderValue = (inDropdown: boolean) => {
    const content = renderSelected(inDropdown);
    return (
      <div
        className={classnames({
          [classes.editorContainer]: true,
          [classes.editorContainerInCell]: isTableVariant,
        })}
      >
        <div className={classes.valueContainer}>
          <div
            className={classnames({
              [classes.chipContainer]: true,
              [classes.chipContainerMain]: sizeVariant === SizeVariant.main,
              [classes.chipContainerSmall]: sizeVariant === SizeVariant.small,
              [classes.chipContainerNoShirk]: !(isMultiLineVariant || inDropdown),
              [classes.valueMultiLine]: isMultiLineVariant || inDropdown,
              [classes.valueSingleLine]: !isMultiLineVariant && !inDropdown,
              [classes.chipContainerPadded]: content !== undefined,
            })}
          >
            {content}
          </div>
          {
            error === undefined && warning === undefined
              ? undefined
              : (
                <div
                  className={classnames({
                    [classes.errorContainer]: true,
                    [classes.errorContainerInDropdown]: inDropdown,
                  })}
                >
                  {
                    error !== undefined
                      ? (<Icon name={IconName.dangerous} tooltip={error} colorVariant={IconColorVariant.error} />)
                      : (<Icon name={IconName.warning} tooltip={warning} colorVariant={IconColorVariant.warning} />)
                  }
                </div>
              )
          }
        </div>
        {(inDropdown && !readOnly && (filteredOptions.length > 0 || searchInput || getInlineCreation)) && (
          // /!\ Warning : textAreaFocusRef is destroyed and recreated on every render, so we use focusOnMount
          <TextareaAutosize
            ref={textAreaFocusRef}
            value={searchInput}
            className={classes.textarea}
            style={{
              ...theme.font[typoVariant],
            }}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
              lazyLoadNavigationRef.current?.resetNavigation();
              setSearchInput(event.target.value);
              setInlineCreationValues(undefined);
            }}
            rows={1}
          />
        )}
      </div>
    );
  };

  const renderDropdown = () => {
    if (inlineCreation?.type === 'transactional' && !!inlineCreationValues) {
      return (
        <div className={classes.optionList}>
          <div
            className={classnames({
              [classes.option]: true,
              [classes[`optionSize_${sizeVariant}`]]: Boolean(sizeVariant),
            })}
            aria-hidden="true"
            onClick={() => setInlineCreationValues(undefined)}
          >
            <Typo>{i18n`Create`}</Typo>
            <SpacedContainer margin={{ left: Spacing.s }}>
              <SpacingLine>
                <Chip text={inlineCreation.getChipLabel(inlineCreationValues) ?? searchInput} />
              </SpacingLine>
            </SpacedContainer>
          </div>
          <UsageContextProvider usageVariant={UsageVariant.inForm}>
            <SizeContextProvider sizeVariant={SizeVariant.main}>
              {inlineCreation.creationOptions.map(({ key, title, render }) => (
                <div
                  key={key}
                  className={classes.transactionalFieldContainer}
                  aria-hidden="true"
                >
                  <Typo>{title}</Typo>
                  {render(
                    inlineCreationValues[key],
                    (value) => setInlineCreationValues((current) => (joinObjects(current, { [key]: value })))
                  )}
                </div>
              ))}
            </SizeContextProvider>
          </UsageContextProvider>
          <div className={classes.transactionButtonContainer}>
            <Button
              title={i18n`Create`}
              onClick={() => {
                inlineCreation.onCreate(inlineCreationValues);
                textAreaFocusRef.current?.focus();
                setShowDropdown(false);
              }}
              disabled={inlineCreation.creationOptions.some(({ key, isValueValid }) => !isValueValid(inlineCreationValues[key]))}
            />
          </div>
        </div>
      );
    } else {
      const optionHeightInRem = getComponentSizeInRem(sizeVariant, HierarchyVariant.content);
      const optionHeightInPx = remToPx(optionHeightInRem);
      const separatorHeightInPx = remToPx(0.2);

      const listItems: ListItem[] = filteredOptions.map((item) => ({
        onSelect: () => handleSelect(item),
        withKeyNavigation: true,
        getSize: () => optionHeightInPx,
        renderRow: (style, onNode, onHover, initialIsActive) => (
          <ItemListNavigableChipItem
            key={item.id}
            ref={onNode}
            style={style}
            onSelect={() => handleSelect(item)}
            onHover={onHover}
            renderContent={() => renderOption(item, 'option')}
            isNavigable
            initialIsActive={initialIsActive}
          />
        ),
        key: item.id,
      }));

      if (inlineCreation && searchInput && !perfectMatch(searchInput)) {
        if (listItems.length > 0) {
          listItems.push({
            key: 'createSeparator',
            getSize: () => separatorHeightInPx,
            renderRow: (style) => (<div key="createSeparator" style={style} className={classes.greyBar} />),
          });
        }

        const onSelectCreation = () => {
          if (inlineCreation.type === 'inline') {
            inlineCreation.onCreate(searchInput);
            setSearchInput('');
            textAreaFocusRef.current?.focus();
          } else {
            setInlineCreationValues(inlineCreation.getInitialState(searchInput));
          }
        };

        listItems.push({
          key: 'createItem',
          onSelect: onSelectCreation,
          withKeyNavigation: true,
          getSize: () => optionHeightInPx,
          renderRow: (style, onNode, onHover, initialIsActive) => (
            <ItemListNavigableChipItem
              key="createItem"
              ref={onNode}
              style={style}
              onSelect={onSelectCreation}
              onHover={onHover}
              renderContent={() => (
                <>
                  <Typo>{i18n`Create`}</Typo>
                  <SpacedContainer margin={{ left: Spacing.s }}>
                    <SpacingLine>
                      <Chip text={searchInput} />
                    </SpacingLine>
                  </SpacedContainer>
                </>
              )}
              isNavigable
              initialIsActive={initialIsActive}
            />
          ),
        });
      }

      if (listItems.length > 0) {
        return (
          <ItemList
            estimatedItemHeight={optionHeightInRem}
            navigationRef={lazyLoadNavigationRef}
            items={listItems}
            lazyMinWidth={isMultiLineVariant ? undefined : 35}
          />
        );
      } else {
        return null;
      }
    }
  };

  let editableMinWith;
  if (sizeVariant === SizeVariant.main && !isTableVariant) {
    editableMinWith = '15rem';
  }
  if (showDropdown && inlineCreation?.type === 'transactional') {
    editableMinWith = '30rem';
  }

  return (
    <EditableWithDropdown
      variant={usageVariant}
      editableSizes={{
        minWidth: editableMinWith,
        flexGrow: 1,
      }}
      dropdownSizes={{
        sameWidth: isMultiLineVariant,
        maxWidth: '64rem',
      }}
      readOnly={readOnly}
      showDropdown={showDropdown}
      openDropdown={openDropdown}
      closeDropdown={closeDropdown}
      closeOnTabKeyDown
      onDropdownKeyDown={handleDropdownKeyDown}
      renderValue={renderValue}
      renderDropdown={readOnly ? undefined : renderDropdown}
      dropdownFixedWidth
      restingTooltip={restingTooltip}
      withMultiplayerOutline={isEditing && isTableVariant}
    />
  );
};

export default SearchAndSelectMultiple;
