import type { ConceptStoreObject } from 'yooi-modules/modules/conceptModule';
import { canCopyConcept, getConceptUrl, isEmbeddedAsIntegrationOnly } from 'yooi-modules/modules/conceptModule';
import { DataAsset } from 'yooi-modules/modules/dataAssetModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { IconName } from '../../../components/atoms/Icon';
import type { MenuItem } from '../../../components/molecules/Menu';
import type { ACLHandler } from '../../../store/useAcl';
import type { FrontObjectStore } from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import type { UseNavigation } from '../../../utils/useNavigation';
import { duplicateConcept } from '../../_global/conceptUtils';

export const getConceptHeaderMenuItems = (
  navigation: UseNavigation,
  store: FrontObjectStore,
  aclHandler: ACLHandler,
  isAdmin: boolean,
  doDelete: () => void,
  conceptId: string
): MenuItem[] => {
  const concept = store.getObject(conceptId);
  const menuItems: MenuItem[] = [];
  if (!isEmbeddedAsIntegrationOnly(concept)) {
    menuItems.push({
      key: 'duplicate',
      name: i18n`Duplicate`,
      icon: IconName.content_copy_outline,
      hidden: !canCopyConcept(store, aclHandler, concept.id),
      onClick: () => duplicateConcept(store, aclHandler, conceptId, (id: string) => navigation.push(id, {
        pathname: getConceptUrl(store, id) ?? undefined,
        navigationFilters: navigation.navigationFilters,
      })),
    });
    menuItems.push({
      key: 'duplicate_with_child',
      name: i18n`Duplicate with its child elements`,
      icon: IconName.content_copy_outline,
      hidden: concept[Instance_Of] === DataAsset || !canCopyConcept(store, aclHandler, concept.id),
      onClick: () => duplicateConcept(store, aclHandler, conceptId, (id: string) => navigation.push(id, {
        pathname: getConceptUrl(store, id) ?? undefined,
        navigationFilters: navigation.navigationFilters,
      }), true),
    });
  }
  menuItems.push({
    key: 'configure',
    name: i18n`Access configuration`,
    icon: IconName.tune,
    hidden: !isAdmin,
    onClick: ({ isCtrlKeyPressed, isMiddleClick }) => {
      const definitionId = store.getObject<ConceptStoreObject>(conceptId)[Instance_Of];
      const pathname = `/settings/organization/${definitionId}`;
      if (isCtrlKeyPressed || isMiddleClick) {
        window.open(pathname, '_blank', 'noopener');
      } else {
        navigation.push(definitionId, { pathname });
      }
    },
  });
  if (!isEmbeddedAsIntegrationOnly(concept)) {
    menuItems.push({
      key: 'delete',
      name: i18n`Delete`,
      icon: IconName.delete,
      hidden: !aclHandler.canDeleteObject(concept.id),
      onClick: doDelete,
      danger: true,
    });
  }
  return menuItems;
};
