import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import { joinObjects } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../../components/atoms/Button';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Typo from '../../../../components/atoms/Typo';
import Chip from '../../../../components/molecules/Chip';
import EditableWithDropdown, { editableSelectorsClasses } from '../../../../components/molecules/EditableWithDropdown';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeSelectorsClasses from '../../../../utils/makeSelectorsClasses';
import makeStyles from '../../../../utils/makeStyles';
import { remToPx } from '../../../../utils/sizeUtils';
import useDerivedState from '../../../../utils/useDerivedState';
import useNavigation from '../../../../utils/useNavigation';
import useTheme from '../../../../utils/useTheme';
import useTooltipRef from '../../../../utils/useTooltipRef';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';
import type { ChipOption } from '../../modelTypeUtilsType';

const selectorsClasses = makeSelectorsClasses('warnVisible');

const useStyles = makeStyles((theme) => ({
  valueContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: spacingRem.s,
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
  dropdownContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '17.5rem',
    overflowY: 'scroll',
    scrollBehavior: 'smooth',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: spacingRem.s,
    paddingBottom: spacingRem.s,
  },
  containerOverride: {
    display: 'flex',
    flexDirection: 'column',
    borderTopWidth: '0.1rem',
    borderTopStyle: 'solid',
    borderTopColor: theme.color.border.default,
  },
  chevronDownIcon: {
    display: 'flex',
  },
  chevronDownIconHidden: {
    visibility: 'hidden',
  },
  chevronDownIconNone: {
    display: 'none',
  },
  lineItem: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    columnGap: spacingRem.s,
    rowGap: spacingRem.s,
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    alignItems: 'center',
    minHeight: '3.2rem',
    flexShrink: 0,
  },
  lineItemOverride: {
    marginTop: spacingRem.s,
    marginBottom: spacingRem.s,
    [`&:hover .${selectorsClasses.warnVisible}`]: {
      visibility: 'visible',
    },
  },
  lineItemOption: {
    cursor: 'pointer',
    '&:hover, &:focus-visible': {
      backgroundColor: theme.color.background.primarylight.default,
    },
  },
  iconContainer: {
    display: 'flex',
  },
  iconOnHover: {
    visibility: 'hidden',
  },
}), 'workflowInput');

interface WorkflowInputProps {
  statusOption: ChipOption | undefined,
  getTransitions: () => { key: string, title: string, disabled: boolean, onClick: () => void }[],
  getOverrideStatus?: { getOptions: () => ChipOption[], onSelect: (option: ChipOption) => void },
  statusIcon?: { message: string, icon: IconName, color: IconColorVariant },
  placeholder?: string,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  isEditing?: boolean,
  withMultiplayerOutline?: boolean,
  restingTooltip?: string | (() => Promise<string>),
  editOnMount?: boolean,
  readOnly: boolean,
}

const WorkflowInput: FunctionComponent<WorkflowInputProps> = ({
  statusOption,
  getTransitions,
  getOverrideStatus,
  statusIcon,
  placeholder,
  onEditionStart,
  onEditionStop,
  isEditing = false,
  withMultiplayerOutline = false,
  restingTooltip,
  editOnMount = false,
  readOnly,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const navigation = useNavigation();

  const usageVariant = useUsageContext();

  const [showDropdown, setShowDropdown] = useState(editOnMount);
  const [showOverride, setShowOverride] = useDerivedState(() => false, [showDropdown]);

  const dropdownContainerRef = useRef<HTMLDivElement | null>(null);
  const buttonContainerRef = useRef<HTMLDivElement | null>(null);

  const noTransitionTooltipRef = useTooltipRef(getOverrideStatus === undefined ? i18n`You cannot edit this workflow as no transition is configured.` : undefined);

  useLayoutEffect(() => {
    if (showOverride && dropdownContainerRef.current && buttonContainerRef.current) {
      dropdownContainerRef.current.scrollTop = buttonContainerRef.current.clientHeight + remToPx(0.1)/* separating border */;
    }
  }, [showOverride]);

  return (
    <EditableWithDropdown
      showDropdown={showDropdown}
      openDropdown={() => {
        setShowDropdown(true);
        if (!readOnly && onEditionStart !== undefined) {
          onEditionStart();
        }
      }}
      closeDropdown={() => {
        setShowDropdown(false);
        if (!readOnly && onEditionStop !== undefined) {
          onEditionStop();
        }
      }}
      renderValue={() => (
        <div className={classes.valueContainer}>
          {statusOption === undefined
            ? (<Typo maxLine={1} color={theme.color.text.disabled}>{placeholder}</Typo>)
            : (
              <Chip
                text={statusOption.label}
                tooltip={statusOption.tooltip}
                icon={statusOption.icon}
                squareColor={statusOption.squareColor}
                color={statusOption.color}
                borderStyle={statusOption.borderStyle}
                startIcons={statusOption.startIcons}
                endIcons={statusOption.endIcons}
                actions={
                  statusOption.getNavigationPayload
                    ? [{
                      key: 'open',
                      icon: IconName.output,
                      tooltip: i18n`Open`,
                      action: joinObjects(statusOption.getNavigationPayload(navigation), { openInNewTab: false }),
                      showOnHover: true,
                    }]
                    : undefined
                }
              />
            )}
          <span className={classes.actionContainer}>
            {statusIcon === undefined ? null : (<Icon name={statusIcon.icon} tooltip={statusIcon.message} colorVariant={statusIcon.color} />)}
            {
              readOnly
                ? null
                : (
                  <span
                    className={classnames({
                      [classes.chevronDownIcon]: true,
                      [classes.chevronDownIconHidden]: !(usageVariant === UsageVariant.inTable) && (!(usageVariant === UsageVariant.inForm) || readOnly),
                      [editableSelectorsClasses.focusedVisible]: !(usageVariant === UsageVariant.inTable) && !readOnly,
                      [classes.chevronDownIconNone]: usageVariant === UsageVariant.inTable,
                      [editableSelectorsClasses.focusedFlex]: usageVariant === UsageVariant.inTable && !readOnly,
                    })}
                  >
                    <Icon
                      colorVariant={usageVariant === UsageVariant.inForm ? IconColorVariant.disabled : IconColorVariant.alternative}
                      name={IconName.expand_more}
                    />
                  </span>
                )
            }
          </span>
        </div>
      )}
      renderDropdown={readOnly ? undefined : () => {
        const transitions = getTransitions();

        return (
          <div ref={dropdownContainerRef} className={classes.dropdownContainer}>
            <div ref={buttonContainerRef} className={classes.container}>
              {transitions.length === 0
                ? (
                  <span ref={noTransitionTooltipRef} className={classes.lineItem}>
                    <Icon name={IconName.move_down} color={theme.color.text.disabled} />
                    <Typo maxLine={1} color={theme.color.text.disabled}>{i18n`No transition available.`}</Typo>
                  </span>
                )
                : transitions.map(({ key, title, disabled, onClick }) => (
                  <span key={key} className={classes.lineItem}>
                    <Button
                      title={title}
                      disabled={disabled}
                      onClick={() => {
                        setShowDropdown(false);
                        onClick();
                      }}
                      maxLine={1}
                    />
                  </span>
                ))}
            </div>
            {
              getOverrideStatus === undefined
                ? null
                : (
                  <div className={classes.containerOverride}>
                    <span className={classnames(classes.lineItem, classes.lineItemOverride)}>
                      <Button
                        title={i18n`Override workflow`}
                        iconName={showOverride ? IconName.keyboard_arrow_down : IconName.keyboard_arrow_right}
                        onClick={() => setShowOverride((current) => !current)}
                        variant={ButtonVariant.secondary}
                        maxLine={1}
                      />
                      <span className={classnames({ [classes.iconContainer]: true, [classes.iconOnHover]: !showOverride, [selectorsClasses.warnVisible]: true })}>
                        <Icon
                          name={IconName.warning}
                          colorVariant={IconColorVariant.warning}
                          tooltip={i18n`You can still override the workflow if necessary but be aware it’s not standard usage.\nUsers are usually expected to follow the configured workflow.`}
                        />
                      </span>
                    </span>
                    {
                      showOverride
                        ? getOverrideStatus.getOptions().map((option) => (
                          <span
                            key={option.id}
                            className={classnames(classes.lineItem, classes.lineItemOption)}
                            tabIndex={0}
                            onClick={() => {
                              setShowDropdown(false);
                              getOverrideStatus.onSelect(option);
                            }}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                setShowDropdown(false);
                                getOverrideStatus.onSelect(option);
                              }
                            }}
                            aria-label={option.label}
                            role="button"
                          >
                            <Chip
                              text={option.label}
                              tooltip={option.tooltip}
                              icon={option.icon}
                              squareColor={option.squareColor}
                              color={option.color}
                              borderStyle={option.borderStyle}
                              startIcons={option.startIcons}
                              endIcons={option.endIcons}
                            />
                          </span>
                        ))
                        : null
                    }
                  </div>
                )
            }
          </div>
        );
      }}
      editableSizes={{
        width: usageVariant === UsageVariant.inTable || usageVariant === UsageVariant.inForm ? undefined : 'fit-content',
      }}
      dropdownSizes={{
        maxWidth: usageVariant === UsageVariant.inTable ? undefined : '64rem',
      }}
      readOnly={readOnly}
      isEditing={usageVariant === UsageVariant.inCard && isEditing}
      withMultiplayerOutline={withMultiplayerOutline}
      restingTooltip={restingTooltip}
    />
  );
};

export default WorkflowInput;
