import type { FunctionComponent } from 'react';
import type { BooleanValue } from './objectRenderType';
import TagRenderer from './TagRenderer';

interface BooleanRendererProps {
  boolean: BooleanValue,
}

const BooleanRenderer: FunctionComponent<BooleanRendererProps> = ({ boolean: { value } }) => (
  <TagRenderer value={value} type="boolean" />
);

export default BooleanRenderer;
