import type { FunctionComponent, ReactElement } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { TemporalBarChartViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import TemporalBarChart from '../../../../components/charts/BarChart/TemporalBarChart';
import type { ChartTooltipData } from '../../../../components/charts/ChartTypes';
import ScrollableWidgetContainer, {
  SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_HEIGHT_PADDING,
  SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_WIDTH_PADDING,
} from '../../../../components/templates/ScrollableWidgetContainer';
import WidgetLoadingPlaceholder from '../../../../components/templates/WidgetLoadingPlaceholder';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { remToPx } from '../../../../utils/sizeUtils';
import useDeepMemo from '../../../../utils/useDeepMemo';
import WidgetInvalidConfiguration from '../../fields/_global/WidgetInvalidConfiguration';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { useViewFilters } from '../../filter/useViewFilters';
import ViewsTooltip from '../common/ViewsTooltip';
import { getViewDefinitionHandler } from '../viewDsl';
import { isResolutionError } from '../viewResolutionUtils';
import type { TemporalBarChartViewDefinitionHandler } from './temporalBarChartViewHandler';
import type { TemporalBarChartResolutionLoaded, TemporalBarChartSeriesRowValue } from './temporalBarChartViewResolution';
import { isTemporalBarChartLoaded } from './temporalBarChartViewResolution';

interface TemporalBarChartViewWidgetProps {
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinition: TemporalBarChartViewStoredDefinition,
  parametersMapping: ParametersMapping,
  width: number,
  height: number,
}

const renderTemporalChartTooltip = (
  resolution: TemporalBarChartResolutionLoaded
) => ({ seriesFieldId, seriesLabel, dimensionsMapping, datum, totalValue }: ChartTooltipData<TemporalBarChartSeriesRowValue>): ReactElement => (
  <ViewsTooltip
    fieldId={seriesFieldId}
    seriesLabel={seriesLabel}
    dimensionsMapping={dimensionsMapping}
    value={datum.value}
    time={datum.time}
    periodicity={resolution.periodicity}
    totalValue={totalValue}
  />
);

const TemporalBarChartViewWidget: FunctionComponent<TemporalBarChartViewWidgetProps> = ({
  viewDimensions,
  viewFilters,
  viewDefinition,
  parametersMapping,
  width,
  height,
}) => {
  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();
  const filterConfiguration = useViewFilters(viewFilters, viewDimensions);

  const viewHandler = getViewDefinitionHandler(viewDefinition) as TemporalBarChartViewDefinitionHandler;
  const temporalBarChartResolution = useDeepMemo(
    () => viewHandler.resolveView(store, { viewDimensions, parametersMapping, userId: loggedUserId, filterConfiguration, aclHandler }),
    [store.getSerial(), viewDimensions, viewDefinition, parametersMapping, filterConfiguration]
  );

  if (isResolutionError(temporalBarChartResolution)) {
    return <WidgetInvalidConfiguration width={width} height={height} reason={temporalBarChartResolution.error} />;
  } else if (isTemporalBarChartLoaded(temporalBarChartResolution)) {
    const chartHeight = height - remToPx(SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_HEIGHT_PADDING);
    return (
      <ScrollableWidgetContainer asContent width={width} height={height}>
        <TemporalBarChart
          minValue={temporalBarChartResolution.minValue}
          maxValue={temporalBarChartResolution.maxValue}
          steps={temporalBarChartResolution.steps}
          xDomain={temporalBarChartResolution.domain.xDomain}
          yDomain={temporalBarChartResolution.domain.yDomain}
          series={temporalBarChartResolution.series}
          labels={temporalBarChartResolution.labels}
          height={chartHeight}
          width={width - remToPx(SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_WIDTH_PADDING)}
          periodicity={temporalBarChartResolution.periodicity}
          renderTooltip={renderTemporalChartTooltip(temporalBarChartResolution)}
          seriesStacked={temporalBarChartResolution.seriesStacked}
        />
      </ScrollableWidgetContainer>
    );
  } else {
    return <WidgetLoadingPlaceholder />;
  }
};

export default TemporalBarChartViewWidget;
