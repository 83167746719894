import type { FunctionComponent } from 'react';
import type { PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { CardColorMode } from 'yooi-modules/modules/conceptModule';
import { BooleanField, IconField } from 'yooi-modules/modules/conceptModule/ids';
import BlockTitle, { BlockTitleVariant } from '../../../../components/templates/BlockTitle';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { getColorPathPickerValidator, getFieldPathPickerValidator } from '../../conceptDisplayUtils';
import { EditionOptionTypes } from '../../fields/FieldEditionOptionType';
import type { OptionRecord } from '../../modelTypeUtils';
import { defaultOptionComparator } from '../../modelTypeUtils';
import { createPathConfigurationHandler } from '../../pathConfigurationHandler';
import { getFieldTypeValidator } from '../../pathConfigurationHandlerUtils';
import CardsBodyFieldsDataTable from '../cards/CardsBodyFieldsDataTable';
import CardsHeaderFieldsDataTable from '../cards/CardsHeaderFieldsDataTable';
import ViewOptionBlock from './ViewOptionBlock';

const cardColorModeOptions: OptionRecord<CardColorMode> = {
  [CardColorMode.Bar]: { id: CardColorMode.Bar, label: i18n`As bar` },
  [CardColorMode.Dot]: { id: CardColorMode.Dot, label: i18n`As dot` },
};

interface CardConfigurationOptionProps {
  value: {
    color: { path: PathStep[], as: CardColorMode } | undefined,
    icon: { path: PathStep[] } | undefined,
    boolean: { path: PathStep[] } | undefined,
    header: { id: string, path: PathStep[], displayOptions: Record<string, unknown> }[] | undefined,
    body: { id: string, label: string | undefined, path: PathStep[], displayOptions: Record<string, unknown> }[] | undefined,
  },
  onColorUpdate: (newColor: { path: PathStep[], as: CardColorMode }) => void,
  onIconUpdate: (newIcon: { path: PathStep[] }) => void,
  onBooleanUpdate: (newBoolean: { path: PathStep[] }) => void,
  onHeaderUpdate: (newHeader: { id: string, path: PathStep[], displayOptions?: Record<string, unknown> }[]) => void,
  onBodyUpdate: (newBody: { id: string, label: string | undefined, path: PathStep[], displayOptions?: Record<string, unknown> }[]) => void,
  getBodySeriesLabel: (label: string | undefined, index: number, path: PathStep[]) => string,
  parameterDefinitions: SingleParameterDefinition[],
  readOnly?: boolean,
  suggestedBasePaths: { label: string, path: PathStep[] }[],
  sectionTitle?: string | undefined,
  sectionSubtitle?: string | undefined,
  bodySectionTitle?: string | undefined,
  bodySectionSubtitle?: string | undefined,
  headerSectionTitle?: string | undefined,
  headerSectionSubtitle?: string | undefined,
}

const CardConfigurationOption: FunctionComponent<CardConfigurationOptionProps> = ({
  value,
  onColorUpdate,
  onIconUpdate,
  onBooleanUpdate,
  onHeaderUpdate,
  onBodyUpdate,
  getBodySeriesLabel,
  suggestedBasePaths,
  parameterDefinitions,
  readOnly = false,
  sectionTitle,
  sectionSubtitle,
  bodySectionTitle,
  bodySectionSubtitle,
  headerSectionTitle,
  headerSectionSubtitle,
}) => {
  const store = useStore();

  return (
    <>
      {sectionTitle && (
        <BlockTitle
          title={sectionTitle}
          subtitle={sectionSubtitle}
          variant={BlockTitleVariant.tertiary}
        />
      )}
      <ViewOptionBlock
        option={{
          key: 'color',
          title: i18n`Color`,
          type: EditionOptionTypes.path,
          props: {
            placeholder: i18n`Select a path to a color field`,
            initialPath: value.color?.path ?? [],
            onSubmit: (newPath) => {
              onColorUpdate({ path: newPath, as: value.color?.as ?? CardColorMode.Bar });
            },
            valuePathHandler: createPathConfigurationHandler(store, parameterDefinitions, [getColorPathPickerValidator(store)]),
            parameterDefinitions,
            suggestedBasePaths,
            readOnly,
          },
        }}
      />
      {(value.color?.path ?? []).length > 0 && (
        <ViewOptionBlock
          option={{
            key: 'colorMode',
            title: i18n`Display color as`,
            type: EditionOptionTypes.select,
            props: {
              selectedOption: cardColorModeOptions[value.color?.as ?? CardColorMode.Bar],
              computeOptions: () => Object.values(cardColorModeOptions).sort(defaultOptionComparator),
              onChange: (option) => {
                if (option) {
                  onColorUpdate({ path: value.color?.path ?? [], as: option.id as CardColorMode });
                }
              },
            },
          }}
        />
      )}
      <ViewOptionBlock
        option={{
          key: 'icon',
          title: i18n`Icon`,
          type: EditionOptionTypes.path,
          props: {
            placeholder: i18n`Select a path to an icon field`,
            initialPath: value.icon?.path ?? [],
            onSubmit: (newPath) => {
              onIconUpdate({ path: newPath });
            },
            valuePathHandler: createPathConfigurationHandler(store, parameterDefinitions, [getFieldPathPickerValidator(store, IconField)]),
            parameterDefinitions,
            suggestedBasePaths,
            readOnly,
          },
        }}
      />
      <ViewOptionBlock
        option={{
          key: 'boolean',
          title: i18n`Boolean`,
          type: EditionOptionTypes.path,
          props: {
            placeholder: i18n`Select a path to a boolean field`,
            initialPath: value.boolean?.path ?? [],
            onSubmit: (newPath) => {
              onBooleanUpdate({ path: newPath });
            },
            valuePathHandler: createPathConfigurationHandler(
              store,
              parameterDefinitions,
              [
                getFieldTypeValidator(store, [BooleanField], i18n`Input should end with a boolean field.`),
              ]
            ),
            parameterDefinitions,
            suggestedBasePaths,
            readOnly,
          },
        }}
      />
      {headerSectionTitle && (
        <BlockTitle
          title={headerSectionTitle}
          subtitle={headerSectionSubtitle}
          variant={BlockTitleVariant.tertiary}
        />
      )}
      <ViewOptionBlock
        option={{
          key: 'header',
          title: !headerSectionTitle ? i18n`Card header fields` : undefined,
          isVertical: true,
          padded: true,
          error: undefined,
          type: EditionOptionTypes.custom,
          props: {
            render: () => (
              <CardsHeaderFieldsDataTable
                header={value.header ?? []}
                readOnly={readOnly}
                onChange={(func) => onHeaderUpdate(func(value.header ?? []))}
                suggestedBasePaths={suggestedBasePaths}
                parameterDefinitions={parameterDefinitions}
              />
            ),
          },
        }}
      />
      {bodySectionTitle && (
        <BlockTitle
          title={bodySectionTitle}
          subtitle={bodySectionSubtitle}
          variant={BlockTitleVariant.tertiary}
        />
      )}
      <ViewOptionBlock
        option={{
          key: 'body',
          title: !bodySectionTitle ? i18n`Card body fields` : undefined,
          isVertical: true,
          padded: true,
          error: undefined,
          type: EditionOptionTypes.custom,
          props: {
            render: () => (
              <CardsBodyFieldsDataTable
                body={value.body ?? []}
                readOnly={readOnly}
                onChange={(func) => onBodyUpdate(func(value.body ?? []))}
                suggestedBasePaths={suggestedBasePaths}
                parameterDefinitions={parameterDefinitions}
                getSeriesLabel={getBodySeriesLabel}
              />
            ),
          },
        }}
      />
    </>
  );
};

export default CardConfigurationOption;
