import { v4 as uuid } from 'uuid';
import type { ObjectStoreReadOnly, ObjectStoreWithAssociation, ObjectStoreWithAssociationReadOnly, RawObjectStore, StoreObject } from './ObjectStoreType';
import createObjectRepository from './repositories/ObjectRepository';
import type { ObjectRepositoryWithAssociation } from './repositories/ObjectRepositoryType';

const createObjectStore = (storeToClone?: ObjectStoreReadOnly, storeCloneObjectFilter?: (obj: StoreObject<string | string[]>) => boolean): RawObjectStore => {
  const {
    getObject,
    getObjectOrNull,
    createObject,
    updateObject,
    deleteObject,
    withAssociation,
    objectEntries,
    forEachObject,
    objectsIterator,
    listObjects,
    size,
    flush,
    registerPropertyFunction,
    invalidatePropertyCache,
    unregisterPropertyFunction,
    unregisterAllPropertyFunctions,
    hasPropertyFunction,
  } = createObjectRepository(storeToClone, storeCloneObjectFilter);

  return {
    getObject,
    getObjectOrNull,
    objectEntries,
    listObjects,
    size,
    forEachObject,
    objectsIterator,
    createObject,
    updateObject,
    deleteObject,
    withAssociation,
    registerPropertyFunction,
    invalidatePropertyCache,
    unregisterPropertyFunction,
    unregisterAllPropertyFunctions,
    hasPropertyFunction,
    interceptUpdate: (intercept) => {
      const doUpdateObject = intercept(updateObject);
      const handleInterceptedWithAssociation = (association: ObjectRepositoryWithAssociation): ObjectStoreWithAssociation => ({
        withRole: (roleId, objectId) => handleInterceptedWithAssociation(association.withRole(roleId, objectId)),
        withVarRoles: (roleId, objectId) => handleInterceptedWithAssociation(association.withVarRoles(roleId, objectId)),
        withExternalRole: (roleId) => handleInterceptedWithAssociation(association.withExternalRole(roleId)),
        list: association.list,
        get: association.get,
        getOrNull: association.getOrNull,
        getId: association.getId,
        listObjects: association.listObjects,
        getObject: association.getObject,
        getObjectOrNull: association.getObjectOrNull,
        updateObject: (properties) => doUpdateObject(association.getId(), properties),
        deleteObject: () => doUpdateObject(association.getId(), null),
      });

      return {
        getObject,
        getObjectOrNull,
        objectEntries,
        listObjects,
        forEachObject,
        objectsIterator,
        size,
        registerPropertyFunction,
        invalidatePropertyCache,
        unregisterPropertyFunction,
        unregisterAllPropertyFunctions,
        hasPropertyFunction,
        createObject: <Properties = Record<string, unknown>>(properties: Properties) => {
          const id = uuid();
          doUpdateObject<Properties>(id, properties);
          return id;
        },
        updateObject: doUpdateObject,
        deleteObject: (id) => doUpdateObject(id, null),
        withAssociation: (id) => handleInterceptedWithAssociation(withAssociation(id)),
      };
    },
    preventUpdate: () => {
      const handleReadOnlyWithAssociation = (association: ObjectRepositoryWithAssociation): ObjectStoreWithAssociationReadOnly => ({
        withRole: (roleId, objectId) => handleReadOnlyWithAssociation(association.withRole(roleId, objectId)),
        withVarRoles: (roleId, objectId) => handleReadOnlyWithAssociation(association.withVarRoles(roleId, objectId)),
        withExternalRole: (roleId) => handleReadOnlyWithAssociation(association.withExternalRole(roleId)),
        list: association.list,
        get: association.get,
        getOrNull: association.getOrNull,
        getId: association.getId,
        listObjects: association.listObjects,
        getObject: association.getObject,
        getObjectOrNull: association.getObjectOrNull,
      });

      return {
        getObject,
        getObjectOrNull,
        objectEntries,
        listObjects,
        forEachObject,
        objectsIterator,
        size,
        registerPropertyFunction,
        invalidatePropertyCache,
        unregisterPropertyFunction,
        unregisterAllPropertyFunctions,
        hasPropertyFunction,
        withAssociation: (id) => handleReadOnlyWithAssociation(withAssociation(id)),
      };
    },
    flush,
  };
};

export default createObjectStore;
