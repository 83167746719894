import type { ConceptStoreObject, DimensionsMapping, ParametersMapping, PathStep } from 'yooi-modules/modules/conceptModule';
import {
  createValuePathResolver,
  dimensionsMappingToParametersMapping,
  getPathReturnedConceptDefinitionId,
  isMultiDimensionResolution,
  isSingleDimensionResolution,
} from 'yooi-modules/modules/conceptModule';
import { Concept, ConceptDefinition_Name } from 'yooi-modules/modules/conceptModule/ids';
import type { DashboardParameterOptionStoreObject, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { DimensionDisplayAxis } from 'yooi-modules/modules/dashboardModule';
import { UnsetDashboardParameterOption } from 'yooi-modules/modules/dashboardModule/ids';
import type { StoreObject } from 'yooi-store';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { getViewFilterFunction } from '../../listFilterFunctions';
import { getDefaultLabel } from '../common/viewUtils';

export const getTypeLabel = (store: FrontObjectStore, label: string | undefined, dimensionIndex: number, typeId: string): string => {
  if (!label && typeId) {
    if (typeId === Concept) {
      return i18n`Concept`;
    }
    // getPathReturnedConceptDefinitionId doesn't validate the path, conceptDefinitionId might no longer exists
    const conceptDefinition = store.getObjectOrNull(typeId);
    if (conceptDefinition) {
      return conceptDefinition[ConceptDefinition_Name] as string | undefined ?? 'Undefined';
    }
  }
  return getDefaultLabel(label, dimensionIndex, 'Dimension');
};

export const getDimensionLabel = (store: FrontObjectStore, label: string | undefined, dimensionIndex: number, path: PathStep[]): string => {
  const conceptDefinitionId = getPathReturnedConceptDefinitionId(store, path);
  if (!label && conceptDefinitionId) {
    if (conceptDefinitionId === Concept) {
      return i18n`Concept`;
    }
    // getPathReturnedConceptDefinitionId doesn't validate the path, conceptDefinitionId might no longer exists
    const conceptDefinition = store.getObjectOrNull(conceptDefinitionId);
    if (conceptDefinition) {
      return conceptDefinition[ConceptDefinition_Name] as string | undefined ?? 'Undefined';
    }
  }
  return getDefaultLabel(label, dimensionIndex, 'Dimension');
};

export class DataResolutionError extends Error {
  override readonly name = 'DataResolutionError';

  readonly dimensionLabel: string;

  constructor(dimensionLabel: string, cause?: Error) {
    super('Invalid data resolution');
    this.dimensionLabel = dimensionLabel;
    this.cause = cause;
  }
}

interface ComputeDimensionResult {
  mapHandler: (dimension: ViewDimension, index: number) => {
    dimId: string,
    instances: (ConceptStoreObject | DashboardParameterOptionStoreObject | undefined)[],
  } | DataResolutionError,
  mapReduceHandler: (dimensions: ViewDimension[]) => DimensionsMapping[] | DataResolutionError,
}

export const computeDimension = (
  objectStore: FrontObjectStore,
  parametersMapping: ParametersMapping,
  viewDimensions?: ViewDimension[],
  filtersConfiguration?: FilterConfiguration
): ComputeDimensionResult => {
  const conceptDefinitionId = viewDimensions && viewDimensions.length === 1 ? getPathReturnedConceptDefinitionId(objectStore, viewDimensions[0].path) : undefined;
  const filterFunction = conceptDefinitionId ? getViewFilterFunction(
    objectStore,
    (viewDimensions ?? []).map((dimension, index) => {
      const dimConceptDefinitionId = getPathReturnedConceptDefinitionId(objectStore, dimension.path);
      return dimConceptDefinitionId ? {
        id: dimension.id,
        label: getDimensionLabel(objectStore, dimension.label, index, dimension.path),
        typeId: dimConceptDefinitionId,
      } : undefined;
    }).filter(filterNullOrUndefined),
    filtersConfiguration,
    parametersMapping
  ) : undefined;
  const mapHandler: ComputeDimensionResult['mapHandler'] = (dimension, index) => {
    const { id, path, display, label } = dimension;
    const dimensionPathResolver = createValuePathResolver(objectStore, parametersMapping);
    const pathResolution = dimensionPathResolver.resolvePathDimension(path);
    if (!pathResolution) {
      return new DataResolutionError(getDimensionLabel(objectStore, label, index, path), new Error(i18n`End of path is unreachable`));
    } else if (pathResolution instanceof Error) {
      return new DataResolutionError(getDimensionLabel(objectStore, label, index, path), pathResolution);
    } else if (isMultiDimensionResolution(pathResolution)) {
      const filteredInstances = filterFunction ? pathResolution.instances
        .map((filterInstance) => (filterFunction(dimensionsMappingToParametersMapping({ [dimension.id]: filterInstance.id })) ? filterInstance : undefined))
        .filter(filterNullOrUndefined) : pathResolution.instances;
      return {
        dimId: id,
        instances: [
          ...(display.withNotSet ? [objectStore.getObject<DashboardParameterOptionStoreObject>(UnsetDashboardParameterOption)] : []),
          ...(display.withAll ? [undefined] : []),
          ...filteredInstances,
        ],
      };
    } else if (isSingleDimensionResolution(pathResolution)) {
      let filteredInstance = pathResolution.instance;
      if (filterFunction && pathResolution.instance) {
        filteredInstance = filterFunction(dimensionsMappingToParametersMapping({ [dimension.id]: pathResolution.instance.id })) ? pathResolution.instance : undefined;
      }
      return {
        dimId: id,
        instances: [
          ...(display.withNotSet ? [objectStore.getObject<DashboardParameterOptionStoreObject>(UnsetDashboardParameterOption)] : []),
          ...(display.withAll ? [undefined] : []),
          filteredInstance,
        ],
      };
    } else {
      return new DataResolutionError(getDimensionLabel(objectStore, label, index, path), new Error(i18n`Cannot resolve global`));
    }
  };
  return {
    mapHandler,
    mapReduceHandler: (dimensions: ViewDimension[]) => {
      const resolvedDimensions: { dimId: string, instances: (StoreObject | undefined)[] }[] = [];
      let error: DataResolutionError | undefined;
      dimensions.some((dim, index) => {
        const resolution = mapHandler(dim, index);
        if (resolution instanceof Error) {
          error = resolution;
          return true;
        } else {
          resolvedDimensions.push(resolution);
          return false;
        }
      });
      if (error) {
        return error;
      }
      return resolvedDimensions.reduce<DimensionsMapping[]>(
        (acc, dimList) => (
          acc.length === 0
            ? dimList.instances.map((instance) => ({ [dimList.dimId]: instance?.id }))
            : acc.flatMap((composition) => {
              if (dimList.instances.length > 0) {
                return dimList.instances
                  .map((instance) => (joinObjects(composition, { [dimList.dimId]: instance?.id })));
              } else {
                return [composition];
              }
            })),
        []
      );
    },
  };
};

export const getDimensionCompositions = (
  computeDimensionHandler: ComputeDimensionResult['mapReduceHandler'],
  dimensions: ViewDimension[],
  dimensionsDisplay: { id: string, axis: DimensionDisplayAxis }[],
  filterX?: (value: ViewDimension) => boolean,
  filterY?: (value: ViewDimension) => boolean
): { xDimensionCompositions: DimensionsMapping[], yDimensionCompositions: DimensionsMapping[] } | DataResolutionError => {
  const xDimensions = dimensions.filter(({ id }) => dimensionsDisplay.find((dim) => dim.id === id)?.axis === DimensionDisplayAxis.x);
  const yDimensions = dimensions.filter(({ id }) => dimensionsDisplay.find((dim) => dim.id === id)?.axis === DimensionDisplayAxis.y);

  const xDimensionCompositions = computeDimensionHandler(filterX ? xDimensions.filter(filterX) : xDimensions);
  if (xDimensionCompositions instanceof Error) {
    return xDimensionCompositions;
  }
  const yDimensionCompositions = computeDimensionHandler(filterY ? yDimensions.filter(filterY) : yDimensions);
  if (yDimensionCompositions instanceof Error) {
    return yDimensionCompositions;
  }

  return { xDimensionCompositions, yDimensionCompositions };
};
