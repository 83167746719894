import type { FunctionComponent } from 'react';
import type { FieldDimensionStoreObject, FieldDimensionTypesStoreObject, FieldStep, SingleParameterDefinition, PathStep } from 'yooi-modules/modules/conceptModule';
import { getAllFieldDimensionsIds, InstanceReferenceType, isFieldStep, PathStepType } from 'yooi-modules/modules/conceptModule';
import {
  Concept,
  FieldDimension_IsMandatory,
  FieldDimension_Label,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
} from 'yooi-modules/modules/conceptModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import Tooltip from '../../../components/atoms/Tooltip';
import Typo from '../../../components/atoms/Typo';
import CompositeField from '../../../components/molecules/CompositeField';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import DataTable from '../../../components/templates/DataTable';
import useStore from '../../../store/useStore';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import { defaultOptionComparator, getOption } from '../modelTypeUtils';
import PathStepsInput from './PathStepsInput';

const useStyles = makeStyles({
  titleContainer: {
    flexGrow: 1,
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center',
    columnGap: spacingRem.s,
    marginBottom: spacingRem.xs,
    marginTop: spacingRem.xs,
  },
}, 'mappingComposite');

interface MappingCompositeProps {
  path: PathStep[],
  parameterDefinitions: SingleParameterDefinition[],
  onChange: ((newPath: PathStep[]) => void) | undefined,
  readOnly?: boolean,
  withSearchInId?: boolean,
}

const MappingComposite: FunctionComponent<MappingCompositeProps> = ({ path, parameterDefinitions, onChange, readOnly = false, withSearchInId = false }) => {
  const classes = useStyles();

  const store = useStore();

  const fieldStep = path[1] as FieldStep;
  const { fieldId } = fieldStep;
  const dimensionIds = getAllFieldDimensionsIds(store, fieldId);
  const mapping = Object.fromEntries(dimensionIds.map((dimensionId) => [dimensionId, fieldStep.mapping?.[dimensionId]]));

  return (
    <CompositeField
      readOnly={readOnly}
      width="50rem"
      minWidth="3rem"
      headerLinesRenderers={[
        {
          id: 'title',
          render: () => (
            <span className={classes.titleContainer}>
              <Icon colorVariant={IconColorVariant.alternative} name={IconName.tune} />
              <Tooltip title={i18n`${Object.values(mapping).filter(Boolean).length} dimensions mapped`}>
                <Typo maxLine={1}>{i18n`${Object.values(mapping).filter(Boolean).length} dimensions mapped`}</Typo>
              </Tooltip>
              {dimensionIds.some((dimensionId) => store.getObject<FieldDimensionStoreObject>(dimensionId)[FieldDimension_IsMandatory] && !mapping[dimensionId]) ? (
                <Icon name={IconName.dangerous} colorVariant={IconColorVariant.error} tooltip={i18n`Mandatory dimensions are not mapped.`} />
              ) : null}
            </span>
          ),
        },
      ]}
      getDropdownSectionDefinitions={() => ([
        {
          id: 'mappingTable',
          lines: [{
            id: 'mappingLine',
            render: (
              <SizeContextProvider sizeVariant={SizeVariant.main}>
                <DataTable
                  list={dimensionIds.map((dimensionId) => ({ key: dimensionId, type: 'item', item: dimensionId, color: undefined }))}
                  columnsDefinition={[
                    {
                      propertyId: 'dimension',
                      name: i18n`Dimensions to map`,
                      width: 60,
                      cellRender: (id) => {
                        const fieldDimension = store.getObject<FieldDimensionStoreObject>(id);

                        const conceptDefinitionIds = store.withAssociation(FieldDimensionTypes)
                          .withRole(FieldDimensionTypes_Role_FieldDimension, id)
                          .list()
                          .map((fieldDimensionTypes) => fieldDimensionTypes.role(FieldDimensionTypes_Role_ConceptDefinition));
                        const typeId = conceptDefinitionIds.length === 1 ? conceptDefinitionIds[0] : Concept;

                        return (
                          <PathStepsInput
                            initialPath={[
                              { type: PathStepType.dimension, conceptDefinitionId: typeId },
                              { type: PathStepType.mapping, mapping: { type: InstanceReferenceType.parameter, id } },
                            ]}
                            parameterDefinitions={[{ type: 'dimension', id, typeId, label: fieldDimension[FieldDimension_Label] ?? i18n`Dimension` }]}
                            readOnly
                          />
                        );
                      },
                    },
                    {
                      propertyId: 'mapping',
                      name: i18n`Mapping`,
                      width: 40,
                      cellRender: (id) => (
                        <SearchAndSelect
                          selectedOption={mapping[id]?.id ? getOption(store, mapping[id]?.id, parameterDefinitions) : undefined}
                          onSelect={(option) => {
                            onChange?.(path.map((step, lineIndex) => {
                              if (lineIndex === 1 && isFieldStep(step)) {
                                return joinObjects(
                                  step,
                                  {
                                    mapping: joinObjects(
                                      mapping,
                                      {
                                        [id]: option?.id ? {
                                          id: option.id,
                                          type: parameterDefinitions
                                            .map((param) => param.id).includes(option.id) ? InstanceReferenceType.parameter : InstanceReferenceType.instance,
                                        } : undefined,
                                      }
                                    ),
                                  }
                                );
                              } else {
                                return step;
                              }
                            }));
                          }}
                          computeOptions={() => {
                            const conceptDefinitions = store.withAssociation(FieldDimensionTypes)
                              .withRole(FieldDimensionTypes_Role_FieldDimension, id)
                              .list<FieldDimensionTypesStoreObject>()
                              .map((assoc) => assoc.navigateRole(FieldDimensionTypes_Role_ConceptDefinition));
                            return [
                              ...parameterDefinitions
                                .filter(({ typeId }) => conceptDefinitions.some((conceptDefinition) => conceptDefinition.id === typeId))
                                .map((element) => getOption(store, element.id, parameterDefinitions))
                                .filter(filterNullOrUndefined)
                                .sort(defaultOptionComparator),
                              ...conceptDefinitions
                                .flatMap((conceptDefinition) => conceptDefinition.navigateBack(Class_Instances))
                                .map((element) => getOption(store, element.id, parameterDefinitions))
                                .filter(filterNullOrUndefined)
                                .sort(defaultOptionComparator),
                            ];
                          }}
                          searchOptions={withSearchInId ? { searchKeys: ['id', 'label'], extractValue: (option, searchKey) => option[searchKey as 'id' | 'label'] } : undefined}
                          statusIcon={
                            !mapping[id]?.id && store.getObject<FieldDimensionStoreObject>(id)[FieldDimension_IsMandatory]
                              ? { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Mapping this dimension is mandatory.` }
                              : undefined
                          }
                          readOnly={readOnly}
                          clearable
                        />
                      ),
                    },
                  ]}
                  fullWidth
                />
              </SizeContextProvider>
            ),
          }],
        },
      ])}
    />
  );
};

export default MappingComposite;
