import type { CollaborationEventTriggerHandler, CollaborationMessage } from 'yooi-modules/modules/automationModule';
import { User } from 'yooi-modules/modules/conceptModule/ids';
import { IconName } from '../../../../components/atoms/Icon';
import i18n from '../../../../utils/i18n';
import type { ActionQueryNodes, TriggerFrontHandler } from './triggerFrontHandler';

const messageNode: ActionQueryNodes<CollaborationMessage> = {
  author: { label: 'author', helperText: i18n`Collaboration message author.`, icon: IconName.person, children: { type: 'concept', typeId: User, isMultiple: false } },
  text: { label: 'text', helperText: i18n`Collaboration message content.`, icon: IconName.comment, children: undefined },
  uri: { label: 'uri', helperText: i18n`Uri to retrieve the collaboration message. Does not contains the hostname.`, icon: IconName.link, children: undefined },
};

export const createCollaborationEventTriggerFrontHandler: () => TriggerFrontHandler<CollaborationEventTriggerHandler> = () => ({
  mailQueryNodes: {
    collaboration_message: {
      label: 'collaboration_message',
      helperText: i18n`Collaboration message that triggered the automation`,
      icon: IconName.comment,
      children: {
        type: 'custom',
        nodes: messageNode,
      },
    },
    collaboration: {
      label: 'collaboration',
      helperText: i18n`Collaboration that triggered the automation`,
      icon: IconName.comment,
      children: {
        type: 'custom',
        nodes: {
          creator: { label: 'creator', helperText: i18n`Collaboration creator.`, icon: IconName.person, children: { type: 'concept', typeId: User, isMultiple: false } },
          intention: { label: 'intention', helperText: i18n`Collaboration intention.`, icon: IconName.comment, children: undefined },
          instance_label: { label: 'instance_label', helperText: i18n`Label of the most relevant collaboration context.`, icon: IconName.spoke, children: undefined },
          messages: {
            label: 'messages',
            helperText: i18n`All collaboration messages ordered by creation date.`,
            icon: IconName.comment,
            children: {
              type: 'custom',
              nodes: messageNode,
            },
          },
          recipients: { label: 'recipients', helperText: i18n`Collaboration recipients.`, icon: IconName.person, children: { type: 'concept', typeId: User, isMultiple: true } },
          status: { label: 'status', helperText: i18n`Collaboration status.`, icon: IconName.spoke, children: undefined },
          uri: { label: 'uri', helperText: i18n`Uri to retrieve the collaboration. Does not contains the hostname.`, icon: IconName.link, children: undefined },
        },
      },
    },
  },
});
