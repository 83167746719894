import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { ChangeEvent, ClipboardEventHandler, FocusEventHandler, FormEvent, FunctionComponent, KeyboardEvent, KeyboardEventHandler, MouseEvent, ReactElement } from 'react';
import { useCallback, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { joinObjects } from 'yooi-utils';
import base from '../../theme/base';
import { spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { notifyError } from '../../utils/notify';
import useBackdropClick from '../../utils/useBackdropClick';
import useDerivedState from '../../utils/useDerivedState';
import useFocusOnMount from '../../utils/useFocusOnMount';
import useSelectionRange from '../../utils/useSelectionRange';
import useSizeContext, { buildInputSizeVariantMinusBorderClasses, SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import useUsageContext, { UsageVariant } from '../../utils/useUsageContext';
import Icon, { IconColorVariant, IconName } from '../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../atoms/IconOnlyButton';
import Typo, { sizeVariantToTypoVariant, TypoAlign, typoMaxWidth, TypoVariant } from '../atoms/Typo';
import EditableWithDropdown, { EditableCloseReasons } from '../molecules/EditableWithDropdown';
import TextWithLinks from '../molecules/TextWithLinks';
import TextInputAction from './TextInputAction';

export enum TextInputStringFieldAlign {
  left = 'left',
  right = 'right',
  center = 'center',
}

const alignMap: Record<TextInputStringFieldAlign, TypoAlign> = {
  [TextInputStringFieldAlign.left]: TypoAlign.left,
  [TextInputStringFieldAlign.center]: TypoAlign.center,
  [TextInputStringFieldAlign.right]: TypoAlign.right,
};

const useStyles = makeStyles((theme) => (joinObjects(
  {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      borderRadius: base.borderRadius.medium,
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      outline: 'none',
      boxShadow: 'none',
      resize: 'none',
      borderRadius: base.borderRadius.medium,
      verticalAlign: 'top',
      '& > textarea': {
        margin: spacingRem.none,
      },
    },
    withHoverAndFocusBorder: {
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: theme.color.transparent,
      '&:hover': {
        borderColor: theme.color.border.hover,
      },
      '&:focus-within': {
        borderColor: theme.color.border.dark,
      },
    },
    [UsageVariant.inline]: {
      '& ::placeholder': {
        color: theme.color.text.disabled,
      },
    },
    [UsageVariant.inTable]: {
      border: '0',
      backgroundColor: theme.color.transparent,
      '& ::placeholder': {
        color: theme.color.text.disabled,
      },
    },
    [UsageVariant.inCard]: {
      border: '0',
      '& ::placeholder': {
        color: theme.color.text.disabled,
      },
    },
    [UsageVariant.inForm]: {
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: theme.color.transparent,
      '& ::placeholder': {
        color: theme.color.text.disabled,
      },
      '&:hover': {
        borderColor: theme.color.border.hover,
      },
      '&:focus-within': {
        borderColor: theme.color.border.dark,
        backgroundColor: theme.color.background.neutral.default,
      },
    },
    secondaryPlaceholder: {
      '& ::placeholder': {
        color: theme.color.text.disabled,
      },
    },
    disabled: {
      borderColor: theme.color.border.default,
      color: theme.color.text.disabled,
      pointerEvents: 'none',
    },
    withOutline: {
      borderColor: theme.color.border.default,
    },
    error: {
      borderColor: theme.color.border.danger,
      '&:active, &:focus, &:focus-within': {
        borderColor: theme.color.border.danger,
      },
      '&:hover': {
        borderColor: theme.color.border.danger,
      },
    },
    errorSpacer: {
      minWidth: '0.4rem',
    },
    textarea: {
      verticalAlign: 'top',
      border: 'none',
      backgroundColor: theme.color.transparent,
      resize: 'none',
      outline: 'none',
      padding: '0',
      flexGrow: 1,
      width: '100%',
      color: theme.color.text.primary,
      '&:disabled': {
        color: theme.color.text.disabled,
      },
    },
    alignRight: {
      textAlign: 'right',
    },
    alignCenter: {
      textAlign: 'center',
    },
    actionIcon: {
      display: 'flex',
      position: 'absolute',
      transform: 'translateX(-4.2rem)',
      top: '0rem',
    },
    textInputActionContainer: {
      position: 'relative',
    },
    textContainer: {
      flexGrow: 1,
      overflow: 'hidden',
    },
  },
  theme.font,
  buildInputSizeVariantMinusBorderClasses('container', ['minHeight'])
)), 'textInputString');

interface TextInputStringProps {
  /**
   * Html name of the input. This is needed when you are building a form
   */
  name?: string,
  value?: string,
  placeholder?: string,
  onSubmit?: (value: string | undefined) => void,
  onCancel?: () => void,
  onChange?: (value: string, event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLTextAreaElement>) => void,
  onClear?: (event: MouseEvent<HTMLButtonElement>) => void,
  action?: { icon: IconName, tooltip: string, onClick: (event: MouseEvent<HTMLButtonElement>) => void },
  disabled?: boolean,
  info?: string,
  warning?: string,
  error?: string,
  align?: TextInputStringFieldAlign,
  acceptChars?: RegExp,
  acceptCharsErrorMessage?: string,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  maxLine?: number,
  minLine?: number,
  dropdownMaxLine?: number,
  focusOnMount?: boolean,
  readOnly?: boolean,
  color?: string,
  fullWidth?: boolean,
  isEditing?: boolean,
  withDropdown?: boolean,
  textVariant?: TypoVariant,
  inputValidation?: (value: string) => string | undefined,
  textInputAction?: { getRender: () => ReactElement, iconName: IconName, tooltip: string },
}

const TextInputString: FunctionComponent<TextInputStringProps> = ({
  value,
  onSubmit,
  onChange,
  onCancel,
  onClear,
  action,
  readOnly = false,
  onEditionStart,
  onEditionStop,
  name,
  placeholder,
  disabled = false,
  info,
  warning,
  error,
  align,
  acceptChars,
  acceptCharsErrorMessage,
  maxLine: maxLineProp,
  minLine,
  dropdownMaxLine: dropdownMaxLineProp,
  focusOnMount = false,
  color,
  fullWidth: fullWidthProp,
  isEditing = false,
  withDropdown: withDropdownProp,
  textVariant,
  inputValidation,
  textInputAction,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const { captureAncestorRef, captureSelectionRange, restoreSelectionRange } = useSelectionRange<HTMLDivElement>();
  const [showActionBox, setShowActionBox] = useState(false);

  const { sizeVariant } = useSizeContext();
  const computedTextVariant = textVariant ?? sizeVariantToTypoVariant[sizeVariant];

  const usageVariant = useUsageContext();
  const isCardVariant = usageVariant === UsageVariant.inCard;
  const isTableVariant = usageVariant === UsageVariant.inTable;
  const isInFormVariant = usageVariant === UsageVariant.inForm;
  const containerRef = useRef<HTMLDivElement>(null);
  const openedContainerRef = useRef<HTMLDivElement>(null);

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const textareaFocusValue = useRef<string>();
  const [focus, setFocus] = useState(focusOnMount);

  useFocusOnMount(textAreaRef, focus);

  const withDropdown = withDropdownProp === undefined ? isTableVariant || isCardVariant : withDropdownProp;
  const fullWidth = fullWidthProp === undefined ? isTableVariant : fullWidthProp;
  const maxLine = maxLineProp ?? (isTableVariant || isCardVariant ? 1 : undefined);
  const dropdownMaxLine = dropdownMaxLineProp ?? (isTableVariant || isCardVariant ? 5 : undefined);

  const [computedError, setComputedError, resetComputedError] = useDerivedState(() => error, [error]);

  const onContainerKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    // Only care about key down directly on the current div
    if (event.target === event.currentTarget) {
      if (event.key === 'Enter') {
        event.preventDefault();
        setFocus(true);
      } else if (event.key === 'Tab') {
        setFocus(false);
        setShowActionBox(false);
      }
    }
  }, []);

  const onTextareaFocus: FocusEventHandler<HTMLTextAreaElement> = (event) => {
    if (!readOnly) {
      onEditionStart?.();
    }
    restoreSelectionRange(event);
    textareaFocusValue.current = event.target.value;
  };

  const onChangeValidated = useCallback((commit: boolean) => {
    setFocus(false);
    setShowActionBox(false);
    if (!readOnly && textAreaRef.current !== null) {
      if (commit) {
        const newValue = textAreaRef.current?.value;
        if (textareaFocusValue.current !== newValue && onSubmit) {
          resetComputedError();
          onSubmit(newValue);
        }
      } else {
        onCancel?.();
      }
      onEditionStop?.();
    }
  }, [onCancel, onEditionStop, onSubmit, readOnly, resetComputedError]);

  const handleEditionEnd = useCallback(() => {
    if (textAreaRef.current) {
      setFocus(false);
      setShowActionBox(false);
      if (!readOnly) {
        onEditionStop?.();
      }
    }
  }, [onEditionStop, readOnly]);

  const onTextareaBlur: FocusEventHandler<HTMLTextAreaElement> = useCallback(() => {
    if (textAreaRef.current) {
      const newValue = textAreaRef.current.value;
      if (textareaFocusValue.current !== newValue && onSubmit && !readOnly) {
        resetComputedError();
        onSubmit(newValue);
      }
    }
  }, [onSubmit, readOnly, resetComputedError]);

  useBackdropClick(openedContainerRef, handleEditionEnd);

  const onTextareaKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      // trigger onTextareaBlur so no need to do onSummit
      containerRef.current?.focus();
    } else if (event.key === 'Escape') {
      if (textAreaRef.current) {
        textAreaRef.current.value = textareaFocusValue.current || '';
      }
      if (!withDropdown) {
        // Avoid the to close the overlay containing the field
        // If hasDropdown, the EditableWithDropdown will stop the propagation as we want to close it
        event.stopPropagation();
      }
      if (onCancel) {
        onCancel();
      }
      containerRef.current?.focus();
    }
  }, [onCancel, withDropdown]);

  const onClearClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    onClear?.(event);
    containerRef.current?.focus();
  }, [onClear]);

  const outline = isInFormVariant && !readOnly;
  const onKeyPress: KeyboardEventHandler<HTMLTextAreaElement> | undefined = acceptChars ? (e) => {
    if (!acceptChars?.test(e.key)) {
      if (acceptCharsErrorMessage) {
        notifyError(acceptCharsErrorMessage);
      }
      e.preventDefault();
    }
  } : undefined;

  const onPaste: ClipboardEventHandler<HTMLTextAreaElement> | undefined = acceptChars ? (e) => {
    if (!e.clipboardData.getData('text').match(acceptChars)) {
      let errorMessage = i18n`Copied value does not match expected pattern`;
      if (acceptCharsErrorMessage) {
        errorMessage = `${errorMessage} (${acceptCharsErrorMessage})`;
      }
      notifyError(errorMessage);
      e.preventDefault();
    }
  } : undefined;

  let padding = `0.3rem ${spacingRem.s}`;
  if (computedTextVariant === TypoVariant.small) {
    padding = `0.3rem ${spacingRem.s}`;
    if ((onClear && value) || action) {
      padding = `0.1rem ${spacingRem.s}`;
    }
  } else if ((onClear && value) || action) {
    padding = `0.3rem ${spacingRem.s}`;
  } else if (isTableVariant) {
    padding = `0.8rem ${spacingRem.s}`;
  } else if (withDropdown) {
    padding = `0.4rem ${spacingRem.s}`;
  }
  const paddingTop = padding.split(' ')[0];

  const render = (inDropdown: boolean) => (
    <div
      className={classnames({
        [classes.container]: true,
        [classes.withOutline]: Boolean(outline),
        [classes[usageVariant]]: !(isInFormVariant && withDropdown),
        [classes.withHoverAndFocusBorder]: !withDropdown && !readOnly,
        [classes.textInputActionContainer]: textInputAction,
      })}
    >
      <div
        ref={composeReactRefs(containerRef, inDropdown ? openedContainerRef : undefined)}
        tabIndex={withDropdown ? -1 : 0}
        className={classnames({
          [classes.inputContainer]: true,
          [classes[`container_${sizeVariant}`]]: true,
          [classes.error]: computedError,
          [classes.disabled]: disabled,
          [classes.secondaryPlaceholder]: outline,
        })}
        style={{ maxWidth: !fullWidth ? typoMaxWidth[computedTextVariant] : undefined, padding }}
        onKeyDown={withDropdown ? undefined : onContainerKeyDown}
        onClick={() => {
          captureSelectionRange();
          setFocus(true);
        }}
        role="textbox"
      >
        {inDropdown ? (
          <TextareaAutosize
            ref={textAreaRef}
            tabIndex={-1}
            name={name}
            value={value ?? ''}
            placeholder={!readOnly ? placeholder : undefined}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
              if (inputValidation) {
                const validationError = inputValidation(event.target.value);
                setComputedError(validationError);
              }
              if (onChange) {
                onChange(event.target.value, event);
              }
            }}
            onKeyDown={withDropdown ? undefined : onTextareaKeyDown}
            onFocus={onTextareaFocus}
            onBlur={onTextareaBlur}
            onKeyPress={onKeyPress}
            onPaste={onPaste}
            className={classnames({
              [classes.textarea]: true,
              [classes[computedTextVariant]]: true,
              [classes.alignRight]: align === TextInputStringFieldAlign.right,
              [classes.alignCenter]: align === TextInputStringFieldAlign.center,
            })}
            maxRows={dropdownMaxLine ?? maxLine}
            minRows={minLine}
            style={{
              // Force override of TextareaAutosize styles
              // eslint-disable-next-line yooi/check-constant-styles
              width: '1px', // To avoid pushing the button
              // Force override of TextareaAutosize styles
              // eslint-disable-next-line yooi/check-constant-styles
              overflowX: 'hidden', // Fix a firefox bug where it provision space for scrollbar for a second when used in overlay
            }}
            disabled={disabled}
            readOnly={readOnly}
          />
        ) : (
          <div
            ref={captureAncestorRef}
            className={classnames({
              [classes.textContainer]: true,
              [classes.alignRight]: align === TextInputStringFieldAlign.right,
              [classes.alignCenter]: align === TextInputStringFieldAlign.center,
            })}
          >
            <Typo
              variant={computedTextVariant}
              color={value ? color : theme.color.text.disabled}
              maxLine={maxLine}
              minLine={minLine}
              fullWidth={fullWidth}
              align={align ? alignMap[align] : undefined}
            >
              {value ? (
                <TextWithLinks
                  text={value.endsWith('\n') ? `${value}\n` : value} // back to line to prevent Component tag to ignore last line break
                />
              ) : (!readOnly && placeholder)}
            </Typo>
          </div>
        )}
        {(onClear && value) && (
          <IconOnlyButton
            sizeVariant={SizeVariant.small}
            variant={IconOnlyButtonVariants.tertiary}
            iconName={IconName.close}
            tooltip={i18n`Clear`}
            onClick={onClearClick}
          />
        )}
        {!onClear && action ? (
          <IconOnlyButton
            sizeVariant={SizeVariant.small}
            variant={IconOnlyButtonVariants.tertiary}
            iconName={action.icon}
            tooltip={action.tooltip}
            onClick={action.onClick}
          />
        ) : null}
        {inDropdown && info && (
          <>
            <div className={classes.errorSpacer} />
            <Icon
              name={IconName.info}
              tooltip={info}
              colorVariant={IconColorVariant.info}
            />
          </>
        )}
        {
          textInputAction && inDropdown && (
            <div className={classes.actionIcon} style={{ paddingTop }}>
              <TextInputAction
                icon={textInputAction.iconName}
                tooltip={textInputAction.tooltip}
                showDropdown={showActionBox}
                openDropdown={() => setShowActionBox(true)}
                closeDropdown={() => setShowActionBox(false)}
                renderContent={textInputAction.getRender}
              />
            </div>
          )
        }
        {computedError ? (
          <>
            <div className={classes.errorSpacer} />
            <Icon
              name={IconName.dangerous}
              tooltip={computedError}
              colorVariant={IconColorVariant.error}
            />
          </>
        ) : null}
        {(warning && !computedError) ? (
          <>
            <div className={classes.errorSpacer} />
            <Icon
              name={IconName.warning}
              tooltip={warning}
              colorVariant={IconColorVariant.warning}
            />
          </>
        ) : null}
      </div>
    </div>
  );

  return withDropdown ? (
    <EditableWithDropdown
      readOnly={readOnly}
      renderValue={render}
      showDropdown={focus}
      openDropdown={() => setFocus(true)}
      closeDropdown={(reason) => onChangeValidated(reason !== EditableCloseReasons.onEscapeKeyDown)}
      closeOnTabKeyDown
      validateOnEnterKeyDown
      variant={usageVariant}
      editableSizes={{
        maxWidth: !fullWidth ? typoMaxWidth[computedTextVariant] : undefined,
        flexGrow: 1,
      }}
      dropdownSizes={{ sameWidth: true }}
      withMultiplayerOutline={usageVariant === UsageVariant.inTable && isEditing}
      isEditing={usageVariant === UsageVariant.inCard && isEditing}
    />
  ) : render(focus);
};

export default TextInputString;
