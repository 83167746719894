import type { FunctionComponent } from 'react';
import type { IntentionStoreObject } from 'yooi-modules/modules/collaborationModule';
import {
  ConceptIntentionGroups,
  ConceptIntentionGroups_Role_Concept,
  ConceptIntentionGroups_Role_Group,
  ConceptIntentionGroups_Role_Intention, ConceptIntentionRoles, ConceptIntentionRoles_Role_Concept, ConceptIntentionRoles_Role_Intention, ConceptIntentionRoles_Role_Role,
  ConceptIntentionUsers,
  ConceptIntentionUsers_Role_Concept,
  ConceptIntentionUsers_Role_Intention,
  ConceptIntentionUsers_Role_User,
  Intention,
  Intention_Name,
  Intention_Rank,
} from 'yooi-modules/modules/collaborationModule/ids';
import { ConceptRole_ConceptDefinition, Group, User } from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import SearchAndSelectMultiple from '../../../../../components/molecules/SearchAndSelectMultiple';
import { TableSortDirection } from '../../../../../components/molecules/Table';
import BlockContent from '../../../../../components/templates/BlockContent';
import DataTable from '../../../../../components/templates/DataTable';
import VerticalBlock from '../../../../../components/templates/VerticalBlock';
import useAcl from '../../../../../store/useAcl';
import useStore from '../../../../../store/useStore';
import { spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import { safeSessionStorageValue } from '../../../../../utils/sessionStorageUtils';
import useNavigation from '../../../../../utils/useNavigation';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../../../utils/useSizeContext';
import { getInlineCreationBuilder } from '../../../../_global/conceptUtils';
import SearchTextButton from '../../../../_global/filter/SearchTextButton';
import StoreTextInputField from '../../../../_global/input/StoreTextInputField';
import { searchFilterFunction } from '../../../../_global/listFilterFunctions';
import { defaultOptionComparator, getChipOptions, getModelTypeInstances, getSearchChipOptions } from '../../../../_global/modelTypeUtils';
import type { NavigationFilter } from '../../../../_global/navigationUtils';
import { getNavigationPayload } from '../../../../_global/navigationUtils';
import { getIntentionGroups, getIntentionUsers, isIntentionAvailableOnConcept } from '../../../../_global/rightPanel/collaboration/utils/collaborationUtils';
import useFilterAndSort, { buildStringColumnComparatorHandler } from '../../../../_global/useFilterAndSort';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: spacingRem.s,
    paddingBottom: spacingRem.xs,
  },
}, 'intentionTab');

interface IntentionFilterConfiguration {
  nameSearch?: string,
}

interface IntentionTabProps {
  conceptDefinitionId: string,
}

const IntentionTab: FunctionComponent<IntentionTabProps> = ({ conceptDefinitionId }) => {
  const classes = useStyles();
  const store = useStore();
  const { canCreateObject } = useAcl();
  const navigation = useNavigation<NavigationFilter>();
  const filterId = Intention;
  const intentions = store.getObject(Intention).navigateBack<IntentionStoreObject>(Instance_Of)
    .filter((intention) => isIntentionAvailableOnConcept(store, intention.id, conceptDefinitionId))
    .map((i) => ({
      key: i.id,
      id: i.id,
      name: i[Intention_Name],
      compareRank: i[Intention_Rank],
    }));
  const filterFunction = searchFilterFunction<typeof intentions[0]>(store, safeSessionStorageValue<IntentionFilterConfiguration | undefined>(filterId)?.nameSearch, ['name']);
  const { generateList, doSort, sortCriteria } = useFilterAndSort(
    filterId,
    intentions,
    (item) => (filterFunction?.(item) ?? true),
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case 'name':
            return buildStringColumnComparatorHandler(key, direction);
          default:
            return undefined;
        }
      },
      initial: { key: 'name', direction: TableSortDirection.asc },
    },
    undefined,
    [intentions]
  );

  return (
    <VerticalBlock compact>
      <BlockContent padded>
        <div className={classes.headerContainer}>
          <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
            <SearchTextButton element={filterId} placeholder={i18n`Search`} />
          </SizeContextProvider>
        </div>
      </BlockContent>
      <BlockContent fullWidth>
        <DataTable
          list={generateList().list}
          getNavigationPayload={({ id }) => getNavigationPayload(navigation, id, `/settings/organization/${conceptDefinitionId}/intention/${id}`)}
          columnsDefinition={[
            {
              key: 'name',
              propertyId: 'name',
              name: i18n`Name`,
              sortable: true,
              focusable: true,
              openButton: () => true,
              cellRender: ({ id, name }, focusOnMount) => (
                <StoreTextInputField
                  initialValue={name as string ?? undefined}
                  focusOnMount={focusOnMount}
                  readOnly
                  onSubmit={(newName) => {
                    store.updateObject(id, { [Intention_Name]: newName });
                  }}
                />
              ),
            },
            {
              key: 'users',
              propertyId: 'users',
              name: i18n`User(s)`,
              cellRender: ({ id }) => {
                const intentionUsers = getIntentionUsers(store, id);
                return (
                  <SearchAndSelectMultiple
                    selectedOptions={[
                      ...intentionUsers
                        .map((entry) => getChipOptions(store, entry)).filter(filterNullOrUndefined)
                        .map((entry) => (joinObjects(entry, {
                          noDelete: true, tooltip: i18n`Assigned via intention configuration.`,
                        }))),
                      ...store.withAssociation(ConceptIntentionUsers)
                        .withRole(ConceptIntentionUsers_Role_Concept, conceptDefinitionId)
                        .withRole(ConceptIntentionUsers_Role_Intention, id).list()
                        .map((assoc) => assoc.role(ConceptIntentionUsers_Role_User))
                        .filter((u) => !intentionUsers.includes(u))
                        .map((entry) => getChipOptions(store, entry)).filter(filterNullOrUndefined),
                    ].sort(defaultOptionComparator)}
                    computeOptions={() => (getModelTypeInstances(store, User)
                      .map(({ id: userId }) => userId)
                      .map((userId) => getChipOptions(store, userId))
                      .filter(filterNullOrUndefined)
                      .sort(defaultOptionComparator))}
                    searchOptions={getSearchChipOptions(store, User)}
                    onSelect={({ id: idSelected }) => store.withAssociation(ConceptIntentionUsers)
                      .withRole(ConceptIntentionUsers_Role_Intention, id)
                      .withRole(ConceptIntentionUsers_Role_User, idSelected)
                      .withRole(ConceptIntentionUsers_Role_Concept, conceptDefinitionId)
                      .updateObject({})}
                    onDelete={({ id: idSelected }) => store.withAssociation(ConceptIntentionUsers)
                      .withRole(ConceptIntentionUsers_Role_Intention, id)
                      .withRole(ConceptIntentionUsers_Role_User, idSelected)
                      .withRole(ConceptIntentionUsers_Role_Concept, conceptDefinitionId)
                      .deleteObject()}
                    getInlineCreation={
                      canCreateObject(User)
                        ? getInlineCreationBuilder(
                          store,
                          User,
                          (instanceId) => store.withAssociation(ConceptIntentionUsers)
                            .withRole(ConceptIntentionUsers_Role_Intention, id)
                            .withRole(ConceptIntentionUsers_Role_User, instanceId)
                            .withRole(ConceptIntentionUsers_Role_Concept, conceptDefinitionId)
                            .updateObject({})
                        )
                        : undefined
                    }
                  />
                );
              },
            },
            {
              key: 'groups',
              propertyId: 'groups',
              name: i18n`Group(s)`,
              cellRender: ({ id }) => {
                const intentionGroups = getIntentionGroups(store, id);
                return (
                  <SearchAndSelectMultiple
                    selectedOptions={[
                      ...intentionGroups
                        .map((entry) => getChipOptions(store, entry)).filter(filterNullOrUndefined)
                        .map((entry) => (joinObjects(entry, { noDelete: true, tooltip: i18n`Assigned via intention configuration.` }))),
                      ...store.withAssociation(ConceptIntentionGroups)
                        .withRole(ConceptIntentionGroups_Role_Concept, conceptDefinitionId)
                        .withRole(ConceptIntentionGroups_Role_Intention, id).list().map((assoc) => assoc.role(ConceptIntentionGroups_Role_Group))
                        .filter((group) => !intentionGroups.includes(group))
                        .map((entry) => getChipOptions(store, entry)).filter(filterNullOrUndefined),
                    ].sort(defaultOptionComparator)}
                    computeOptions={() => getModelTypeInstances(store, Group)
                      .map(({ id: groupId }) => groupId)
                      .map((groupId) => getChipOptions(store, groupId))
                      .filter(filterNullOrUndefined)
                      .sort(defaultOptionComparator)}
                    searchOptions={getSearchChipOptions(store, Group)}
                    onSelect={({ id: idSelected }) => store.withAssociation(ConceptIntentionGroups)
                      .withRole(ConceptIntentionGroups_Role_Intention, id)
                      .withRole(ConceptIntentionGroups_Role_Group, idSelected)
                      .withRole(ConceptIntentionGroups_Role_Concept, conceptDefinitionId)
                      .updateObject({})}
                    onDelete={({ id: idSelected }) => store.withAssociation(ConceptIntentionGroups)
                      .withRole(ConceptIntentionGroups_Role_Intention, id)
                      .withRole(ConceptIntentionGroups_Role_Group, idSelected)
                      .withRole(ConceptIntentionGroups_Role_Concept, conceptDefinitionId)
                      .deleteObject()}
                    getInlineCreation={
                      canCreateObject(Group)
                        ? getInlineCreationBuilder(
                          store,
                          Group,
                          (instanceId) => store.withAssociation(ConceptIntentionGroups)
                            .withRole(ConceptIntentionGroups_Role_Intention, id)
                            .withRole(ConceptIntentionGroups_Role_Group, instanceId)
                            .withRole(ConceptIntentionUsers_Role_Concept, conceptDefinitionId)
                            .updateObject({})
                        )
                        : undefined
                    }
                  />
                );
              },
            },
            {
              key: 'roles',
              propertyId: 'roles',
              name: i18n`Role(s)`,
              cellRender: ({ id }) => (
                <SearchAndSelectMultiple
                  selectedOptions={store.withAssociation(ConceptIntentionRoles)
                    .withRole(ConceptIntentionRoles_Role_Concept, conceptDefinitionId)
                    .withRole(ConceptIntentionRoles_Role_Intention, id)
                    .list()
                    .map((assoc) => assoc.role(ConceptIntentionRoles_Role_Role))
                    .map((entry) => getChipOptions(store, entry)).filter(filterNullOrUndefined)
                    .sort(defaultOptionComparator)}
                  computeOptions={() => store.getObject(conceptDefinitionId)
                    .navigateBack(ConceptRole_ConceptDefinition)
                    .map((role) => getChipOptions(store, role.id))
                    .filter(filterNullOrUndefined)
                    .sort(defaultOptionComparator)}
                  onSelect={({ id: idSelected }) => store.withAssociation(ConceptIntentionRoles)
                    .withRole(ConceptIntentionRoles_Role_Intention, id)
                    .withRole(ConceptIntentionRoles_Role_Role, idSelected)
                    .withRole(ConceptIntentionRoles_Role_Concept, conceptDefinitionId)
                    .updateObject({})}
                  onDelete={({ id: idSelected }) => store.withAssociation(ConceptIntentionRoles)
                    .withRole(ConceptIntentionRoles_Role_Intention, id)
                    .withRole(ConceptIntentionRoles_Role_Role, idSelected)
                    .withRole(ConceptIntentionRoles_Role_Concept, conceptDefinitionId)
                    .deleteObject()}
                />
              ),
            },
          ]}
          doSort={doSort}
          sortCriteria={sortCriteria}
        />
      </BlockContent>
    </VerticalBlock>
  );
};

export default IntentionTab;
