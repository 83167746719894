import type { ComponentProps, FunctionComponent, ReactElement } from 'react';
import type { WorkflowTransitionStoreObject } from 'yooi-modules/modules/conceptModule';
import { getInstanceLabelOrUndefined } from 'yooi-modules/modules/conceptModule';
import {
  Workflow_TargetedConceptDefinition,
  Workflow_Transitions,
  WorkflowEntry,
  WorkflowEntry_Rank,
  WorkflowEntry_Role_Concept,
  WorkflowEntry_Role_Workflow,
  WorkflowTransition,
  WorkflowTransition_From,
  WorkflowTransition_Name,
  WorkflowTransition_Owner,
  WorkflowTransition_To,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { StoreObject } from 'yooi-store';
import { compareProperty, compareRank, comparing, filterNullOrUndefined } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../components/atoms/Icon';
import Typo from '../../components/atoms/Typo';
import SearchAndSelect from '../../components/molecules/SearchAndSelect';
import SpacingLine from '../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../components/molecules/Table';
import BlockTitle from '../../components/templates/BlockTitle';
import type { BlockTitleVariant } from '../../components/templates/BlockTitle';
import DataTable from '../../components/templates/DataTable';
import VerticalBlock from '../../components/templates/VerticalBlock';
import useStore from '../../store/useStore';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import useTheme from '../../utils/useTheme';
import { resolveConceptChipIcon, resolveConceptColorValue } from './conceptDisplayUtils';
import StoreTextInputField from './input/StoreTextInputField';
import { getChipOptions, getUnknownChip } from './modelTypeUtils';
import type { ComparatorHandler } from './useFilterAndSort';
import useFilterAndSort from './useFilterAndSort';

const useStyles = makeStyles({
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}, 'workflowDetailPageTransitionBlock');

interface WorkflowDetailPageTransitionBlockProps {
  workflowId: string,
  displayInfo?: boolean,
  titleVariant?: BlockTitleVariant,
}

const WorkflowDetailPageTransitionBlock: FunctionComponent<WorkflowDetailPageTransitionBlockProps> = ({ workflowId, displayInfo = true, titleVariant }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const workflow = store.getObject(workflowId);
  const workflowTargetedConceptDefinition = workflow.navigateOrNull(Workflow_TargetedConceptDefinition) ?? undefined;

  const { generateGroupedList, sortCriteria, doSort } = useFilterAndSort(
    `${workflowId}_${Workflow_Transitions}`,
    workflow.navigateBack<WorkflowTransitionStoreObject>(Workflow_Transitions),
    undefined,
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case WorkflowTransition_From:
            return {
              comparator: comparing(compareRank, direction === TableSortDirection.desc),
              extractValue: (transition) => {
                const from = transition.navigateOrNull(WorkflowTransition_From);
                if (from) {
                  return store.withAssociation(WorkflowEntry)
                    .withRole(WorkflowEntry_Role_Workflow, workflowId)
                    .withRole(WorkflowEntry_Role_Concept, from.id)
                    .getObjectOrNull()
                    ?.[WorkflowEntry_Rank] as string | undefined;
                } else {
                  return undefined;
                }
              },
            } satisfies ComparatorHandler<WorkflowTransitionStoreObject, string | undefined>;
          default:
            return undefined;
        }
      },
      initial: { key: WorkflowTransition_From, direction: TableSortDirection.asc },
    },
    {
      key: WorkflowTransition_From,
      getGroupKey: (item) => item.navigateOrNull(WorkflowTransition_From)?.id,
      getGroupLabel: (key) => getInstanceLabelOrUndefined(store, store.getObject(key)),
      getGroupColor: (key) => (resolveConceptColorValue(store, key) ?? resolveConceptChipIcon(store, key)?.color),
    }
  );

  const renderCellInstance = (propertyId: string, transition: StoreObject, allowEmptyStep: boolean): ReactElement | null => {
    let selectedOption: ComponentProps<typeof SearchAndSelect>['selectedOption'] | undefined;
    let error: string | undefined;
    if (transition[propertyId]) {
      const instance = transition.navigateOrNull(propertyId);
      if (instance) {
        selectedOption = getChipOptions(store, instance.id);
        if (!workflowTargetedConceptDefinition || !isInstanceOf(instance, workflowTargetedConceptDefinition.id)) {
          error = i18n`Selected step is not of the expected type`;
        } else if (
          !store.withAssociation(WorkflowEntry)
            .withRole(WorkflowEntry_Role_Workflow, workflowId)
            .withRole(WorkflowEntry_Role_Concept, instance.id)
            .getObjectOrNull()
        ) {
          error = i18n`Step is not in the workflow`;
        }
      } else {
        selectedOption = getUnknownChip(transition[propertyId] as string);
        error = i18n`Selected step no longer exists`;
      }
    } else if (!allowEmptyStep) {
      error = i18n`A step is required`;
    }

    return (
      <SearchAndSelect
        selectedOption={selectedOption}
        computeOptions={() => store.withAssociation(WorkflowEntry)
          .withRole(WorkflowEntry_Role_Workflow, workflowId)
          .list()
          .sort(compareProperty('object', compareProperty(WorkflowEntry_Rank, compareRank)))
          .map((workflowEntry) => getChipOptions(store, workflowEntry.role(WorkflowEntry_Role_Concept)))
          .filter(filterNullOrUndefined)}
        onSelect={(option) => {
          if (option) {
            store.updateObject(transition.id, { [propertyId]: option.id });
          }
        }}
        statusIcon={error ? { icon: IconName.dangerous, color: IconColorVariant.error, message: error } : undefined}
      />
    );
  };

  return (
    <VerticalBlock asBlockContent>
      <BlockTitle
        title={i18n`Transitions`}
        variant={titleVariant}
        subtitle={displayInfo ? (
          <span className={classes.subtitleContainer}>
            <SpacingLine><Typo color={theme.color.text.secondary}>{i18n`You can add transitions to constrain how and when the status can change.`}</Typo></SpacingLine>
            <SpacingLine><Typo color={theme.color.text.secondary}>{i18n`When transitions are set, you can control how the workflow field is displayed in “Content & Layout” to display buttons for each available transition. Users won’t be able to change the status without following a transition. Connectors and integrations will be unaffected.`}</Typo></SpacingLine>
          </span>
        ) : null}
      />
      <DataTable
        list={generateGroupedList().list}
        sortCriteria={sortCriteria}
        doSort={doSort}
        columnsDefinition={[
          {
            name: i18n`From`,
            propertyId: WorkflowTransition_From,
            width: 33,
            sortable: true,
            cellRender: (transition) => renderCellInstance(WorkflowTransition_From, transition, true),
          },
          {
            name: i18n`To`,
            propertyId: WorkflowTransition_To,
            width: 33,
            cellRender: (transition) => renderCellInstance(WorkflowTransition_To, transition, false),
          },
          {
            name: i18n`Label`,
            propertyId: WorkflowTransition_Name,
            width: 33,
            cellRender: (transition) => (
              <StoreTextInputField
                initialValue={transition[WorkflowTransition_Name] as string}
                onSubmit={(newName) => store.updateObject(transition.id, {
                  [WorkflowTransition_Name]: newName,
                })}
              />
            ),
          },
        ]}
        linesActions={(line) => [
          {
            key: 'remove',
            name: i18n`Remove`,
            icon: IconName.delete,
            danger: true,
            onClick: () => {
              store.deleteObject(line.id);
            },
          },
        ]}
        newItemIcon={IconName.add}
        newItemTitle={i18n`Create`}
        onNewItem={() => {
          store.createObject({ [Instance_Of]: WorkflowTransition, [WorkflowTransition_Owner]: workflowId });
        }}
      />
    </VerticalBlock>
  );
};

export default WorkflowDetailPageTransitionBlock;
