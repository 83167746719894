import type { FunctionComponent } from 'react';
import { useCallback, useLayoutEffect, useRef } from 'react';
import { CollaborationMessage_CreatedAt } from 'yooi-modules/modules/collaborationModule/ids';
import type { NotificationStoreObject } from 'yooi-modules/modules/notificationModule';
import { Notification, Notification_Role_Message, Notification_Role_User, Notification_Seen } from 'yooi-modules/modules/notificationModule/ids';
import useAuth from '../../../../../store/useAuth';
import useStore from '../../../../../store/useStore';
import useCollaborationContentRef from '../_global/useCollaborationContentRef';
import usePanelObserver from '../usePanelObserver';
import { getCollaborationMessages } from '../utils/collaborationUtils';
import CollaborationMessage from './CollaborationMessage';

interface CollaborationDetailPanelThreadTabProps {
  collaborationId: string,
  lastRead: number,
}

const CollaborationDetailPanelThreadTab: FunctionComponent<CollaborationDetailPanelThreadTabProps> = ({ collaborationId, lastRead }) => {
  const store = useStore();
  const { loggedUserId } = useAuth();

  const { messageId } = usePanelObserver();

  const contentRef = useCollaborationContentRef();
  const lastReadNodeRef = useRef<HTMLDivElement>(null);
  const refs = useRef<Record<string, HTMLDivElement>>({});

  const shouldAutoScrollToLastReadOrMessageId = useRef(true);
  const shouldAutoScrollToBottom = useRef(true);
  const isUserScroll = useRef(false);

  // Listen scroll
  const notifyUpdate = useCallback((node: HTMLElement) => {
    if (isUserScroll.current) {
      shouldAutoScrollToLastReadOrMessageId.current = false;
      shouldAutoScrollToBottom.current = node.scrollHeight - node.scrollTop === node.clientHeight;
    }
    isUserScroll.current = true;
  }, []);

  useLayoutEffect(() => {
    const node = contentRef.current;
    if (node !== null) {
      const eventListener = (e: Event) => notifyUpdate(e.target as HTMLElement);
      node.addEventListener('scroll', eventListener);
      return () => {
        node.removeEventListener('scroll', eventListener);
      };
    } else {
      return () => {};
    }
  }, [contentRef, notifyUpdate]);

  // use only in the callback
  const applyScroll = useCallback(() => {
    if (shouldAutoScrollToLastReadOrMessageId.current && messageId && refs.current && refs.current[messageId]) {
      isUserScroll.current = false;
      shouldAutoScrollToBottom.current = false;
      refs.current[messageId].scrollIntoView();
    } else if (shouldAutoScrollToLastReadOrMessageId.current && lastReadNodeRef.current) {
      isUserScroll.current = false;
      shouldAutoScrollToBottom.current = false;
      lastReadNodeRef.current.scrollIntoView({ block: 'start' });
      if (contentRef.current) {
        contentRef.current.scrollTop -= 10;
      }
    } else if (shouldAutoScrollToBottom.current && contentRef.current) {
      isUserScroll.current = false;
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [contentRef, messageId]);
  useLayoutEffect(() => applyScroll());

  const collaborationMessages = getCollaborationMessages(store, collaborationId, true);

  collaborationMessages.forEach((message) => {
    const notifications = store.withAssociation(Notification)
      .withRole(Notification_Role_Message, message.id)
      .withRole(Notification_Role_User, loggedUserId).list<NotificationStoreObject>().filter((notif) => notif.object[Notification_Seen] === false);
    if (notifications) {
      notifications.forEach((notif) => store.updateObject(notif.object.id, { [Notification_Seen]: true }));
    }
  });

  return (
    <>
      {
        collaborationMessages.map((message) => (
          <CollaborationMessage
            key={message.id}
            messageId={message.id}
            unread={(message[CollaborationMessage_CreatedAt] ?? 0) > lastRead}
          />
        ))
      }
    </>
  );
};

export default CollaborationDetailPanelThreadTab;
