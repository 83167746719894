import { equals } from 'ramda';
import { useRef } from 'react';

const useDeepMemo = <T>(initialValueFunction: () => T, deps: unknown[]): T => {
  const valueRef = useRef<T>();
  const depsRef = useRef<unknown[]>();

  if (!equals(depsRef.current, deps)) {
    valueRef.current = initialValueFunction();
    depsRef.current = deps;
  }

  return valueRef.current as T;
};

export default useDeepMemo;
