import type { FunctionComponent } from 'react';
import { EmbeddingField_FromType, KinshipRelation } from 'yooi-modules/modules/conceptModule/ids';
import { Resource } from 'yooi-modules/modules/resourceModule/ids';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import useStore from '../../../../store/useStore';
import useUpdateActivity from '../../../../store/useUpdateActivity';
import i18n from '../../../../utils/i18n';
import { getLastUpdateBy } from '../../historyUtils';
import { getChipOptions, getModelTypeOptions, getSearchChipOptions } from '../../modelTypeUtils';

interface KinshipRendererProps {
  fieldId: string,
  conceptId: string,
  readOnly: boolean,
  withMultiplayerOutline?: boolean,
  focusOnMount?: boolean,
}

const KinshipRenderer: FunctionComponent<KinshipRendererProps> = ({ fieldId, conceptId, readOnly, withMultiplayerOutline = false, focusOnMount = false }) => {
  const store = useStore();
  const updateActivity = useUpdateActivity();

  const field = store.getObject(fieldId);
  const concept = store.getObject(conceptId);

  const targetType = field.navigate(EmbeddingField_FromType);
  const elementId = concept[fieldId];

  return (
    <SearchAndSelect
      clearable={targetType.id !== Resource}
      placeholder={i18n`Select element`}
      searchOptions={getSearchChipOptions(store, targetType.id)}
      computeOptions={() => getModelTypeOptions(store, targetType.id)}
      selectedOption={typeof elementId === 'string' ? getChipOptions(store, elementId) : undefined}
      onSelect={(result) => {
        store.updateObject(conceptId, { [fieldId]: result?.id ?? null });
      }}
      readOnly={readOnly}
      onEditionStart={() => updateActivity.onEnterEdition(conceptId, fieldId)}
      onEditionStop={() => updateActivity.onExitEdition(conceptId, fieldId)}
      withMultiplayerOutline={withMultiplayerOutline}
      editOnMount={focusOnMount}
      restingTooltip={() => getLastUpdateBy(store, conceptId, KinshipRelation, undefined)}
    />
  );
};

export default KinshipRenderer;
