import type { ObjectStore } from 'yooi-store';
import { compareString, filterNullOrUndefined } from 'yooi-utils';
import { DashboardParameter } from '../../dashboardModule/ids';
import { isInstanceOf } from '../../typeModule';
import { FieldDimension } from '../ids';

export type DimensionsMapping = Record<string, string | undefined>;

export interface SingleParameterValue {
  type: 'single',
  id: string | undefined,
}

export interface MultipleParameterValue {
  type: 'multiple',
  ids: string[],
}

export type ParametersMapping<T = SingleParameterValue> = Record<string, T>;

export const segregateParametersMapping = (parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>): {
  singleParametersMapping: ParametersMapping,
  multipleParametersMapping: ParametersMapping<MultipleParameterValue>,
} => {
  const singleParametersMapping: ParametersMapping = {};
  const multipleParametersMapping: ParametersMapping<MultipleParameterValue> = {};
  Object.entries(parametersMapping).forEach(([key, value]) => {
    if (value.type === 'single') {
      singleParametersMapping[key] = value;
    } else {
      multipleParametersMapping[key] = value;
    }
  });
  return { singleParametersMapping, multipleParametersMapping };
};

export const dimensionsMappingToParametersMapping = (dimensionsMapping: DimensionsMapping): ParametersMapping => Object.fromEntries(
  Object.entries(dimensionsMapping)
    .map(([key, id]) => {
      if (id) {
        return [key, { type: 'single', id }];
      } else {
        return undefined;
      }
    }).filter(filterNullOrUndefined)
);

export interface ParameterDefinition {
  id: string,
  typeId: string,
  label: string,
}

export interface SingleParameterDefinition extends ParameterDefinition {
  type: 'dimension' | 'parameter',
}

export interface MultipleParameterDefinition extends ParameterDefinition {
  type: 'parameterList',
}

export const isSingleParameterDefinition = (
  parameterDefinition: SingleParameterDefinition | MultipleParameterDefinition
): parameterDefinition is SingleParameterDefinition => parameterDefinition.type !== 'parameterList';

export const isParameter = (store: ObjectStore, objectId: string, parametersMapping: ParametersMapping): boolean => {
  const instance = store.getObjectOrNull(objectId);
  return Object.keys(parametersMapping).includes(objectId) || isInstanceOf(instance, DashboardParameter) || isInstanceOf(instance, FieldDimension);
};

export enum ParsedDimensionType {
  MonoDimensional = 'MonoDimensional',
  MultiDimensional = 'MultiDimensional',
}

type ParsedDimensionMapping = {
  type: ParsedDimensionType.MonoDimensional,
  objectId: string,
} | {
  type: ParsedDimensionType.MultiDimensional,
  dimensionsMapping: DimensionsMapping,
};

export const parseDimensionMapping = (dimensionsMapping: DimensionsMapping): ParsedDimensionMapping => {
  const dimensionValues = Object.values(dimensionsMapping).filter(filterNullOrUndefined);
  const monoDimValue = dimensionValues.length === 1 && dimensionValues[0];
  if (monoDimValue) {
    return { type: ParsedDimensionType.MonoDimensional, objectId: monoDimValue };
  } else {
    return { type: ParsedDimensionType.MultiDimensional, dimensionsMapping };
  }
};

const GLOBAL_DIMENSION_ID = 'global';

export const dimensionsMappingFromDimensionalIds = (id: string[]): DimensionsMapping => {
  const dimensionsMapping: DimensionsMapping = {};
  if (id[0] === GLOBAL_DIMENSION_ID) {
    return dimensionsMapping;
  }
  const dimensionsIds = id[0].split(':');
  dimensionsIds.forEach((dimensionId, index) => {
    dimensionsMapping[dimensionId] = id[index + 1];
  });
  return dimensionsMapping;
};

export const buildDimensionalId = (dimensionsMapping: DimensionsMapping): string[] => {
  const res = [];
  const dimensionIds = Object.keys(dimensionsMapping).filter((dimensionId) => dimensionsMapping[dimensionId]).sort(compareString);
  if (dimensionIds.length) {
    res.push(dimensionIds.join(':'));
  } else {
    // global use case is only working with formula, to support absorbed ones, we need to deal with an instance and add appropriate BR & ACls
    res.push(GLOBAL_DIMENSION_ID);
  }
  dimensionIds.forEach((dimensionId) => {
    res.push(dimensionsMapping[dimensionId]);
  });
  return res;
};

export const canWrite = (dimensionsMapping: DimensionsMapping, canWriteObject: (id: string | string[]) => boolean): boolean => {
  const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
  if (parsedDimensionMapping.type === ParsedDimensionType.MonoDimensional) {
    return canWriteObject(parsedDimensionMapping.objectId);
  } else {
    return Object.values(parsedDimensionMapping.dimensionsMapping).filter(filterNullOrUndefined).some((objectId) => canWriteObject(objectId));
  }
};
