import type { FunctionComponent } from 'react';
import type { ConceptDefinitionStoreObject } from 'yooi-modules/modules/conceptModule';
import {
  ConceptCapability,
  ConceptDefinition,
  ConceptDefinition_HasStakeholders,
  ConceptDefinition_Name,
  ConceptDefinition_RestrictedAccess,
  ConceptIntegrationCapability,
  ConceptIntegrationCapability_Role_ConceptCapability,
  ConceptIntegrationCapability_Role_Definition,
  ConceptIntegrationCapability_Role_Integration,
} from 'yooi-modules/modules/conceptModule/ids';
import { Template_Integrations } from 'yooi-modules/modules/integrationModule/ids';
import { ConceptDefinition_MappedTemplateConceptDefinitions, TemplateConceptDefinition_Template } from 'yooi-modules/modules/templateModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import { compareProperty, compareString, filterNullOrUndefined } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import Chip from '../../../components/molecules/Chip';
import SearchAndSelectMultiple from '../../../components/molecules/SearchAndSelectMultiple';
import { TableSortDirection } from '../../../components/molecules/Table';
import TableInnerCellContainer, { TableInnerCellContainerVariants } from '../../../components/molecules/TableInnerCellContainer';
import DataTable from '../../../components/templates/DataTable';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import { buildPadding, Spacing } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import { defaultOptionComparator, getChipOptions } from '../../_global/modelTypeUtils';
import useFilterAndSort, { buildStringColumnComparatorHandler } from '../../_global/useFilterAndSort';

interface IntegrationDetailPermissionTabProps {
  integrationId: string,
}

const IntegrationDetailPermissionTab: FunctionComponent<IntegrationDetailPermissionTabProps> = ({ integrationId }) => {
  const store = useStore();

  const isMappedConcept = (conceptDefinitionId: string) => store.getObject(conceptDefinitionId)
    .navigateBack(ConceptDefinition_MappedTemplateConceptDefinitions)
    .some((tcd) => tcd.navigateOrNull(TemplateConceptDefinition_Template)?.navigateBack(Template_Integrations).some(({ id }) => id === integrationId) ?? false);

  const { generateList, doSort, sortCriteria } = useFilterAndSort(
    'integration_permission',
    store.getObject(ConceptDefinition)
      .navigateBack<ConceptDefinitionStoreObject>(Class_Instances)
      .filter((cd) => !cd[ConceptDefinition_RestrictedAccess] && cd[ConceptDefinition_HasStakeholders]),
    undefined,
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case ConceptDefinition_Name:
            return buildStringColumnComparatorHandler(key, direction);
          default:
            return undefined;
        }
      },
      initial: { key: ConceptDefinition_Name, direction: TableSortDirection.asc },
    },
    undefined,
    [integrationId]
  );

  return (
    <VerticalBlock>
      <DataTable
        doSort={doSort}
        sortCriteria={sortCriteria}
        columnsDefinition={[
          {
            propertyId: ConceptDefinition_Name,
            name: i18n`Concept`,
            sortable: true,
            width: 20,
            cellRender: ({ id }) => {
              const option = getChipOptions(store, id);
              return (
                <TableInnerCellContainer variant={TableInnerCellContainerVariants.centeredFlex} padding={buildPadding({ x: Spacing.s })}>
                  <Chip
                    text={option?.label}
                    tooltip={option?.tooltip}
                    squareColor={option?.squareColor}
                    color={option?.color}
                    icon={option?.icon}
                    borderStyle={option?.borderStyle}
                    actions={[{
                      key: 'open',
                      icon: IconName.output,
                      tooltip: i18n`Open`,
                      action: { to: `/settings/organization/${id}`, openInNewTab: false },
                      showOnHover: true,
                    }]}
                  />
                </TableInnerCellContainer>
              );
            },
          },
          {
            propertyId: 'capabilities',
            name: i18n`Capabilities`,
            width: 80,
            cellRender: ({ id }) => {
              const assignedCapabilitiesIds = store.withAssociation(ConceptIntegrationCapability)
                .withRole(ConceptIntegrationCapability_Role_Integration, integrationId)
                .withRole(ConceptIntegrationCapability_Role_Definition, id)
                .list()
                .map((cic) => cic.role(ConceptIntegrationCapability_Role_ConceptCapability));

              const selectedOptions = assignedCapabilitiesIds
                .map((capabilityId) => getChipOptions(store, capabilityId))
                .filter(filterNullOrUndefined)
                .sort(compareProperty('label', compareString));

              return (
                <SearchAndSelectMultiple
                  selectedOptions={selectedOptions}
                  computeOptions={
                    () => store.getObject(ConceptCapability)
                      .navigateBack(Class_Instances)
                      .map(({ id: capabilityId }) => getChipOptions(store, capabilityId))
                      .filter(filterNullOrUndefined)
                      .sort(defaultOptionComparator)
                  }
                  onSelect={(option) => {
                    if (option) {
                      store.withAssociation(ConceptIntegrationCapability)
                        .withRole(ConceptIntegrationCapability_Role_Integration, integrationId)
                        .withRole(ConceptIntegrationCapability_Role_Definition, id)
                        .withRole(ConceptIntegrationCapability_Role_ConceptCapability, option.id)
                        .updateObject({});
                    }
                  }}
                  onDelete={(option) => {
                    store.withAssociation(ConceptIntegrationCapability)
                      .withRole(ConceptIntegrationCapability_Role_Integration, integrationId)
                      .withRole(ConceptIntegrationCapability_Role_Definition, id)
                      .withRole(ConceptIntegrationCapability_Role_ConceptCapability, option.id)
                      .deleteObject();
                  }}
                  error={selectedOptions.length === 0 && isMappedConcept(id) ? i18n`This concept is mapped but missing permissions` : undefined}
                />
              );
            },
          },
        ]}
        list={generateList().list}
      />
    </VerticalBlock>
  );
};

export default IntegrationDetailPermissionTab;
