import { newError } from 'yooi-utils';
import type { DimensionsMapping } from './parametersUtils';

export interface ResolutionStack {
  ensureNonRecursiveResolution: <T = unknown>(dimensionsMapping: DimensionsMapping, fieldId: string, resolution: () => T) => T,
  fork: () => ResolutionStack,
}

export const createResolutionStack: () => ResolutionStack = () => {
  const doCreateResolutionStack = (stack: Set<string>): ResolutionStack => ({
    ensureNonRecursiveResolution: <T>(dimensionsMapping: DimensionsMapping, fieldId: string, resolution: () => T): T => {
      const key = `${Object.entries(dimensionsMapping).flatMap(([dim, value]) => `${dim}_${value}`).join('|')}_${fieldId}`;
      if (stack.has(key)) {
        throw newError('Infinite recursion');
      }
      stack.add(key);

      try {
        return resolution();
      } finally {
        stack.delete(key);
      }
    },
    fork: () => doCreateResolutionStack(new Set<string>(stack)),
  });

  return doCreateResolutionStack(new Set<string>());
};
