import miscAnalyzer from './miscAnalyzer';
import s3UrlAnalyzer from './s3UrlAnalyzer';
import sharepointUrlAnalyzer from './sharepointUrlAnalyzer';
import universalUrlAnalyzer from './universalUrlAnalyzer';

export default [
  s3UrlAnalyzer,
  sharepointUrlAnalyzer,
  universalUrlAnalyzer,
  miscAnalyzer,
];
