import type { FunctionComponent } from 'react';
import { Fragment, useState } from 'react';
import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { getPathReturnedConceptDefinitionId, isMultiplePath } from 'yooi-modules/modules/conceptModule';
import type { DimensionDisplayOption, TimeseriesTableViewStoredDefinition, ViewDimension, ViewSeries } from 'yooi-modules/modules/dashboardModule';
import { DimensionDisplayAxis, ViewType } from 'yooi-modules/modules/dashboardModule';
import { Direction, joinObjects, moveElementInArray, PeriodicityType } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../../components/atoms/Button';
import { IconName } from '../../../../components/atoms/Icon';
import Typo from '../../../../components/atoms/Typo';
import { DateRangeOptions } from '../../../../components/inputs/datePickers/DateRangePredefined';
import Card from '../../../../components/molecules/Card';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import useStore from '../../../../store/useStore';
import base from '../../../../theme/base';
import { FontVariant } from '../../../../theme/fontDefinition';
import { buildMargins, getSpacing, Spacing, spacingRem } from '../../../../theme/spacingDefinition';
import { getPeriodicityOptions } from '../../../../utils/dateUtilsFront';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import useTheme from '../../../../utils/useTheme';
import type { LineEditionOption } from '../../fields/FieldEditionOptionType';
import { EditionOptionTypes } from '../../fields/FieldEditionOptionType';
import type { UpdateViewDefinition } from '../../fields/viewsField/ViewsFieldDefinitionOptions';
import type { Option } from '../../modelTypeUtils';
import { getParameterOption } from '../../modelTypeUtils';
import ViewDefinitionSeriesOptions from '../common/series/ViewDefinitionSeriesOptions';
import { addSeries, deleteSeries, getSeriesError, getViewDimensionsAsParameterDefinitions, updateSeries } from '../common/series/viewWithSeriesFeatureUtils';
import { getTemporalError } from '../common/temporal/viewWithTemporalFeatureUtils';
import ViewOptionBlock from '../common/ViewOptionBlock';
import { getDimensionLabel } from '../data/dataResolution';
import { getTimeseriesTableViewDefinitionDimensionsDisplayOptions } from './timeseriesTableUtils';
import type { TimeseriesTableViewResolvedDefinition } from './timeseriesTableViewHandler';

interface TimeseriesTableViewDefinitionOptionsProps {
  viewDimensions: ViewDimension[],
  viewDefinition: TimeseriesTableViewResolvedDefinition,
  updateViewDefinition: UpdateViewDefinition<TimeseriesTableViewStoredDefinition>,
  readOnly: boolean,
  parameterDefinitions: SingleParameterDefinition[],
  isWidget: boolean,
}

interface ItemPerPageOption extends Option {
  value: number | undefined,
}

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    ...buildMargins({ y: Spacing.s }),
  },
  advancedDisplayContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto auto 1fr',
    columnGap: getSpacing(Spacing.s),
    rowGap: getSpacing(Spacing.s),
    alignItems: 'center',
    paddingLeft: spacingRem.m,
    paddingRight: spacingRem.m,
    paddingBottom: spacingRem.s,
    paddingTop: spacingRem.s,
    borderRadius: base.borderRadius.medium,
  },
  separator: {
    flexGrow: 1,
    gridColumnStart: 1,
    gridColumnEnd: 7,
    borderBottom: `0.1rem solid ${theme.color.border.default}`,
    marginBottom: spacingRem.text,
    marginTop: spacingRem.text,
  },
  advancedDisplayTitleContainer: {
    gridColumnStart: 1,
    gridColumnEnd: 7,
    paddingLeft: getSpacing(Spacing.s),
  },
  readOnlyDisplayTextContainer: {
    paddingLeft: getSpacing(Spacing.s),
  },
  fourColumnsDiv: {
    gridColumn: 'span 4',
  },
}), 'timeseriesTableViewDefinitionOptions');

export const getProjectionErrorForAxis = (
  viewDefinition: TimeseriesTableViewResolvedDefinition,
  viewDimensions: ViewDimension[],
  axis: DimensionDisplayAxis
): string | undefined => {
  if (viewDefinition.timeAxis.axis !== axis
    && (viewDefinition.seriesAxis.axis !== axis || viewDefinition.seriesAxis.display !== 'show')
    && !viewDefinition.getDimensionsDisplay(viewDimensions).filter((dimensionDisplay) => dimensionDisplay.axis === axis)
      .some((dimensionDisplay) => dimensionDisplay.display === 'show')) {
    return i18n`You need at least a projection in ${axis === DimensionDisplayAxis.x ? i18n`column` : i18n`line`}`;
  } else {
    return undefined;
  }
};
const TimeseriesTableViewDefinitionOptions: FunctionComponent<TimeseriesTableViewDefinitionOptionsProps> = ({
  viewDimensions,
  viewDefinition,
  updateViewDefinition,
  readOnly,
  parameterDefinitions,
  isWidget,
}) => {
  const store = useStore();
  const classes = useStyles();
  const theme = useTheme();
  const viewParameterDefinitions: SingleParameterDefinition[] = [...parameterDefinitions, ...getViewDimensionsAsParameterDefinitions(store, viewDimensions)];
  const editionOptions: LineEditionOption[] = [];

  const displayAsRowOptions = [
    { id: 'time', label: i18n`Dates` },
    { id: 'timeseries', label: i18n`Timeseries` },
    ...viewDimensions.map(({ id, label, path }, index) => ({
      id,
      label: getDimensionLabel(store, label, index, path),
    })),
  ];

  const isTimeAsRow = viewDefinition.timeAxis.axis === DimensionDisplayAxis.y;
  const isSeriesAsRow = viewDefinition.seriesAxis.axis === DimensionDisplayAxis.y;
  const isSeriesDisplayed = viewDefinition.seriesAxis.display === 'show' || viewDefinition.series.length > 1;
  const isDisplayedAndProjectedAsRow = (dimension: ViewDimension): { asRow: boolean, displayed: boolean } => {
    const display = viewDefinition.getDimensionDisplay(dimension);
    return {
      asRow: display.axis === DimensionDisplayAxis.y,
      displayed: display.display === 'show' || isMultiplePath(store, dimension.path),
    };
  };

  let displayAsLineValue: Option | undefined;
  let displayAsColumnLabels: string[] | undefined;
  if (isTimeAsRow && !isSeriesAsRow && (
    (viewDimensions.length > 0 && !isSeriesDisplayed && viewDimensions.every((dim) => {
      const { asRow, displayed } = isDisplayedAndProjectedAsRow(dim);
      return !asRow && displayed;
    }))
    || (
      viewDimensions.length === 0 && isSeriesDisplayed
    )
  )) {
    displayAsLineValue = displayAsRowOptions.find(({ id }) => id === 'time');
    displayAsColumnLabels = [i18n`Timeseries`, ...viewDimensions.map((dim, index) => getDimensionLabel(store, dim.label, index, dim.path))];
  } else if (isSeriesAsRow && isSeriesDisplayed && !isTimeAsRow && viewDimensions.every((dim) => {
    const { asRow, displayed } = isDisplayedAndProjectedAsRow(dim);
    return !asRow && ((isMultiplePath(store, dim.path) && displayed) || !displayed);
  })) {
    displayAsLineValue = displayAsRowOptions.find(({ id }) => id === 'timeseries');
    displayAsColumnLabels = [i18n`Dates`, ...viewDimensions.map((dim, index) => getDimensionLabel(store, dim.label, index, dim.path))];
  } else if (!isTimeAsRow && !isSeriesAsRow && ((viewDefinition.series.length > 1 && isSeriesDisplayed) || (viewDefinition.series.length < 2 && !isSeriesDisplayed))) {
    viewDimensions.forEach((dim) => {
      const { asRow, displayed } = isDisplayedAndProjectedAsRow(dim);
      if (asRow && displayed && viewDimensions.every((currentDimension) => {
        const { asRow: dimAsRow, displayed: dimDisplayed } = isDisplayedAndProjectedAsRow(currentDimension);
        return currentDimension.id === dim.id || (!dimAsRow && (!dimDisplayed || isMultiplePath(store, currentDimension.path)));
      })) {
        displayAsLineValue = displayAsRowOptions.find(({ id }) => id === dim.id);
        displayAsColumnLabels = [
          i18n`Dates`,
          i18n`Timeseries`,
          ...viewDimensions
            .filter((dimension) => dimension.id !== dim.id)
            .map((dimension, index) => getDimensionLabel(store, dimension.label, index, dimension.path)),
        ];
      }
    });
  }

  const [openAdvanceOptions, setOpenAdvanceOptions] = useState<boolean>(displayAsLineValue === undefined);

  editionOptions.push(
    {
      key: 'timeRange',
      title: i18n`Time range`,
      type: EditionOptionTypes.dateRange,
      error: getTemporalError(viewDefinition),
      props: {
        readOnly,
        value: viewDefinition.dateRange,
        placeholder: i18n`Add time range`,
        predefinedRangeOption: DateRangeOptions.lastAndCurrent,
        onChange: (dateRange) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { dateRange: dateRange ?? undefined }))),
      },
    }
  );

  const periodicityOptions = getPeriodicityOptions();
  const periodicity = viewDefinition.granularity.periodicity ?? PeriodicityType.year;
  editionOptions.push(
    {
      key: 'granularity',
      title: i18n`Granularity`,
      type: EditionOptionTypes.select,
      props: {
        readOnly,
        computeOptions: () => periodicityOptions,
        selectedOption: periodicityOptions.find(({ id }) => id === periodicity),
        onChange: (option) => {
          if (option) {
            updateViewDefinition((oldViewDefinition) => (joinObjects(
              oldViewDefinition,
              {
                granularity: {
                  periodicity: option.id as PeriodicityType,
                  strict: oldViewDefinition.granularity?.strict ?? false,
                  displayEmpty: oldViewDefinition.granularity?.displayEmpty ?? false,
                },
              }
            )));
          }
        },
      },
    }
  );

  editionOptions.push({
    key: 'series',
    title: i18n`Timeseries`,
    type: EditionOptionTypes.custom,
    isVertical: true,
    padded: true,
    error: getSeriesError(store, viewDefinition, viewParameterDefinitions, viewDefinition.type),
    props: {
      render: () => (
        <ViewDefinitionSeriesOptions
          viewType={ViewType.TimeseriesTable}
          series={viewDefinition.series ?? []}
          dimensions={viewDimensions}
          onCreateSeries={() => updateViewDefinition((oldViewDefinition) => addSeries(oldViewDefinition))}
          onDeleteSeries={(seriesId) => updateViewDefinition((oldViewDefinition) => deleteSeries(oldViewDefinition, seriesId))}
          onUpdateSeries={(seriesId, properties) => updateViewDefinition((oldViewDefinition) => updateSeries(oldViewDefinition, seriesId, properties))}
          onMoveUpSeries={(index) => updateViewDefinition(
            (oldViewDefinition) => (joinObjects(oldViewDefinition, { series: moveElementInArray<ViewSeries>(Direction.up, index, viewDefinition.series ?? []) }))
          )}
          onMoveDownSeries={(index) => updateViewDefinition(
            (oldViewDefinition) => (joinObjects(oldViewDefinition, { series: moveElementInArray<ViewSeries>(Direction.down, index, viewDefinition.series ?? []) }))
          )}
          readOnly={readOnly}
          parameterDefinitions={viewParameterDefinitions}
        />
      ),
    },
  });

  const noItemPerPageOption: ItemPerPageOption = {
    id: 'All',
    label: i18n`All`,
    value: undefined,
  };
  let selectedItemPerPage = noItemPerPageOption;
  const itemPerPage = viewDefinition.numberOfItems;
  if (itemPerPage) {
    selectedItemPerPage = {
      id: (itemPerPage).toString(),
      label: (itemPerPage).toString(),
      value: itemPerPage,
    };
  }

  const getChipByLabel = (id: string, label: string): Option => ({
    id,
    label,
    color: theme.color.background.neutral.muted,
    borderColor: theme.color.border.default,
    borderStyle: 'solid',
  });

  editionOptions.push({
    key: 'showAdvanceSettings',
    type: EditionOptionTypes.custom,
    title: i18n`In rows`,
    error: getProjectionErrorForAxis(viewDefinition, viewDimensions, DimensionDisplayAxis.x) ?? getProjectionErrorForAxis(viewDefinition, viewDimensions, DimensionDisplayAxis.y),
    info: displayAsColumnLabels ? i18n`${displayAsColumnLabels.slice(0, -1)
      .join(', ')} and ${displayAsColumnLabels.at(-1) as string} will be displayed in columns by default. Use advanced settings to customize the display.`
      : i18n`An advanced configuration is configured, selecting a row will erase it.`,
    props: {
      render: () => (
        <SpacingLine>
          <SearchAndSelect
            readOnly={readOnly}
            placeholder={i18n`Select a row type`}
            selectedOption={displayAsLineValue}
            computeOptions={() => displayAsRowOptions}
            onSelect={(option) => {
              if (option) {
                if (option.id === 'time') {
                  updateViewDefinition((oldViewDefinition) => (joinObjects(
                    oldViewDefinition,
                    {
                      timeAxis: { axis: DimensionDisplayAxis.y },
                      seriesAxis: { axis: DimensionDisplayAxis.x, display: viewDimensions.length === 0 ? 'show' as const : undefined },
                      dimensionsDisplay: getTimeseriesTableViewDefinitionDimensionsDisplayOptions(store, oldViewDefinition, viewDimensions)
                        .map((display) => (joinObjects(display, {
                          axis: DimensionDisplayAxis.x,
                          display: 'show' as const,
                        }))),
                    }
                  )));
                } else if (option.id === 'timeseries') {
                  updateViewDefinition((oldViewDefinition) => (joinObjects(
                    oldViewDefinition,
                    {
                      timeAxis: { axis: DimensionDisplayAxis.x },
                      seriesAxis: { axis: DimensionDisplayAxis.y, display: 'show' as const },
                      dimensionsDisplay: getTimeseriesTableViewDefinitionDimensionsDisplayOptions(store, oldViewDefinition, viewDimensions)
                        .map((display) => (joinObjects(display, {
                          axis: DimensionDisplayAxis.x,
                          display: undefined,
                        }))),
                    }
                  )));
                } else {
                  updateViewDefinition((oldViewDefinition) => (joinObjects(
                    oldViewDefinition,
                    {
                      timeAxis: { axis: DimensionDisplayAxis.x },
                      seriesAxis: { axis: DimensionDisplayAxis.x },
                      dimensionsDisplay: getTimeseriesTableViewDefinitionDimensionsDisplayOptions(store, oldViewDefinition, viewDimensions)
                        .map((display) => (joinObjects(
                          display,
                          {
                            axis: display.id === option.id ? DimensionDisplayAxis.y : DimensionDisplayAxis.x,
                            display: display.id === option.id ? 'show' as const : undefined,
                          }
                        ))),
                    }
                  )));
                }
              }
            }}
          />
          <Button
            title={i18n`Advanced settings`}
            iconName={openAdvanceOptions ? IconName.visibility : IconName.visibility_off}
            onClick={() => setOpenAdvanceOptions((current) => !current)}
            variant={ButtonVariant.secondary}
          />
        </SpacingLine>
      ),
    },
  });

  if (openAdvanceOptions) {
    const timeAxisOptions = [{ id: DimensionDisplayAxis.y, label: i18n`Rows` }, { id: DimensionDisplayAxis.x, label: i18n`Columns` }];
    const displayAsOptions = [{ id: 'labels', label: i18n`Labels` }, { id: 'chips', label: i18n`Chips` }];
    const showOptions: { id: 'show' | 'hide', label: string }[] = [{ id: 'show', label: i18n`Display` }, { id: 'hide', label: i18n`Hide` }];
    const getDisplayAsOption = (dimensionDisplay: DimensionDisplayOption) => {
      if (dimensionDisplay.axis !== DimensionDisplayAxis.y || dimensionDisplay.withLegend === false) {
        return displayAsOptions[0];
      } else {
        return displayAsOptions[1];
      }
    };

    editionOptions.push(
      {
        key: 'advanceSettings',
        type: EditionOptionTypes.custom,
        isVertical: true,
        padded: true,
        error: 'error',
        props: {
          render: () => (
            <div className={classes.cardContainer}>
              <Card noHoverShadow>
                <div className={classes.advancedDisplayContainer}>
                  <div className={classes.readOnlyDisplayTextContainer}>
                    <Typo color={theme.color.text.secondary}>{i18n`Display`}</Typo>
                  </div>
                  <SearchAndSelect readOnly selectedOption={getChipByLabel('dateChip', i18n`Dates`)} />
                  <Typo color={theme.color.text.secondary}>{i18n`in`}</Typo>
                  <SearchAndSelect
                    readOnly={readOnly}
                    selectedOption={timeAxisOptions.find(({ id }) => id === viewDefinition.timeAxis.axis) ?? timeAxisOptions[0]}
                    computeOptions={() => timeAxisOptions}
                    onSelect={(option) => {
                      if (option) {
                        updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { timeAxis: { axis: option.id } })));
                      }
                    }}
                  />
                  <div />
                  <div />
                  {readOnly || viewDefinition.series.length > 1 ? (
                    <div className={classes.readOnlyDisplayTextContainer}>
                      <Typo color={theme.color.text.secondary}>{viewDefinition.series.length > 1 || viewDefinition.seriesAxis.display === 'show' ? i18n`Display` : i18n`Hide`}</Typo>
                    </div>
                  ) : (
                    <SearchAndSelect
                      readOnly={readOnly || viewDefinition.series.length > 1}
                      selectedOption={showOptions.find(({ id }) => {
                        if (viewDefinition.series.length > 1) {
                          return id === 'show';
                        } else {
                          return id === viewDefinition.seriesAxis.display;
                        }
                      })}
                      computeOptions={() => showOptions}
                      onSelect={(option) => {
                        if (option) {
                          updateViewDefinition((oldViewDefinition) => (
                            joinObjects(oldViewDefinition, { seriesAxis: { display: option.id, axis: oldViewDefinition.seriesAxis?.axis ?? DimensionDisplayAxis.x } })));
                        }
                      }}
                    />
                  )}
                  <SearchAndSelect readOnly selectedOption={getChipByLabel('timeseriesChip', i18n`Timeseries`)} />
                  <Typo color={theme.color.text.secondary}>{i18n`in`}</Typo>
                  <SearchAndSelect
                    readOnly={readOnly}
                    selectedOption={timeAxisOptions.find(({ id }) => id === viewDefinition.seriesAxis.axis) ?? timeAxisOptions[0]}
                    computeOptions={() => timeAxisOptions}
                    onSelect={(option) => {
                      if (option) {
                        updateViewDefinition((oldViewDefinition) => (
                          joinObjects(oldViewDefinition, { seriesAxis: { axis: option.id, display: oldViewDefinition.seriesAxis?.display } })));
                      }
                    }}
                  />
                  <div />
                  <div />
                  {viewDimensions.length > 0 && (
                    <>
                      <div className={classes.separator} />
                      <div className={classes.advancedDisplayTitleContainer}>
                        <Typo variant={FontVariant.body}>{i18n`Dimensions`}</Typo>
                      </div>
                      {(viewDimensions.map((viewDimension, index) => {
                        const { id: dimId, label, path } = viewDimension;
                        const dimensionDisplay = viewDefinition.getDimensionDisplay(viewDimension);
                        const conceptDefinitionId = getPathReturnedConceptDefinitionId(store, path);
                        const dimOption = conceptDefinitionId ? getParameterOption(
                          store,
                          {
                            id: dimId,
                            typeId: conceptDefinitionId,
                            label: getDimensionLabel(store, label, index, path),
                            type: 'dimension',
                          }
                        ) : getChipByLabel(dimId, getDimensionLabel(store, label, index, path));
                        const isDimDisplayed = isMultiplePath(store, viewDimension.path) || dimensionDisplay.display === 'show';
                        return (
                          <Fragment key={`${dimId}_configuration`}>
                            {readOnly || isMultiplePath(store, viewDimension.path) ? (
                              <div className={classes.readOnlyDisplayTextContainer}>
                                <Typo color={theme.color.text.secondary}>{isDimDisplayed ? i18n`Display` : i18n`Hide`}</Typo>
                              </div>
                            ) : (
                              <SearchAndSelect
                                selectedOption={showOptions.find(({ id }) => id === dimensionDisplay.display) ?? showOptions[0]}
                                computeOptions={() => showOptions}
                                onSelect={(option) => {
                                  if (option) {
                                    updateViewDefinition((oldViewDefinition) => {
                                      const dimensionsDisplay = [...getTimeseriesTableViewDefinitionDimensionsDisplayOptions(store, oldViewDefinition, viewDimensions)];
                                      dimensionsDisplay[index].display = option.id;
                                      return joinObjects(oldViewDefinition, { dimensionsDisplay });
                                    });
                                  }
                                }}
                              />
                            )}
                            <SearchAndSelect readOnly selectedOption={dimOption} />
                            {isDimDisplayed ? (
                              <>
                                <Typo color={theme.color.text.secondary}>{i18n`in`}</Typo>
                                <SearchAndSelect
                                  readOnly={readOnly}
                                  selectedOption={timeAxisOptions.find(({ id }) => id === dimensionDisplay.axis) ?? timeAxisOptions[0]}
                                  computeOptions={() => timeAxisOptions}
                                  onSelect={(option) => {
                                    if (option) {
                                      updateViewDefinition((oldViewDefinition) => {
                                        const dimensionsDisplay = [...getTimeseriesTableViewDefinitionDimensionsDisplayOptions(store, oldViewDefinition, viewDimensions)];
                                        dimensionsDisplay[index].axis = option.id;
                                        return joinObjects(oldViewDefinition, { dimensionsDisplay });
                                      });
                                    }
                                  }}
                                />
                                <Typo color={theme.color.text.secondary}>{i18n`as`}</Typo>
                                <SearchAndSelect
                                  selectedOption={getDisplayAsOption(dimensionDisplay)}
                                  computeOptions={() => displayAsOptions}
                                  onSelect={(option) => {
                                    if (option) {
                                      updateViewDefinition((oldViewDefinition) => {
                                        const dimensionsDisplay = [...getTimeseriesTableViewDefinitionDimensionsDisplayOptions(store, oldViewDefinition, viewDimensions)];
                                        dimensionsDisplay[index].withLegend = option.id === 'chips';
                                        return joinObjects(oldViewDefinition, { dimensionsDisplay });
                                      });
                                    }
                                  }}
                                  readOnly={readOnly || dimensionDisplay.axis !== DimensionDisplayAxis.y}
                                />
                              </>
                            ) : (
                              <div className={classes.fourColumnsDiv} />
                            )}
                          </Fragment>
                        );
                      }))}
                    </>
                  )}
                </div>
              </Card>
            </div>
          ),
        },
      }
    );
  }

  if (!isWidget) {
    editionOptions.push(
      {
        key: 'tableNumberOfItems',
        title: i18n`Items per page`,
        info: i18n`Select the number of items shown in each page of the library table.`,
        type: EditionOptionTypes.select,
        props: {
          readOnly,
          selectedOption: selectedItemPerPage,
          computeOptions: () => [noItemPerPageOption, ...[10, 20, 30, 50, 100].map((i) => ({
            id: (i).toString(),
            label: (i).toString(),
            value: i,
          }))],
          onChange: (selectedItem) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { numberOfItems: (selectedItem as ItemPerPageOption)?.value }))),
        },
      }
    );
  }

  editionOptions.push(
    {
      key: 'showEmptyValues',
      title: i18n`Show dates without value`,
      type: EditionOptionTypes.checkbox,
      info: i18n`Checking this will force display of date within time range even if they have no data.`,
      padded: true,
      props: {
        disabled: readOnly,
        checked: viewDefinition.granularity.displayEmpty,
        onChange: (showDate) => {
          updateViewDefinition((oldViewDefinition) => (joinObjects(
            oldViewDefinition,
            { granularity: { periodicity: viewDefinition.granularity.periodicity, displayEmpty: showDate, strict: oldViewDefinition.granularity?.strict ?? false } }
          )));
        },
      },
    }
  );

  editionOptions.push(
    {
      key: 'strictGranularity',
      title: i18n`Show dates not aligned with granularity`,
      type: EditionOptionTypes.checkbox,
      info: i18n`Checking this will display dates that are not aligned with the given granularity, without this dates with different granularity cannot be added.`,
      padded: true,
      props: {
        disabled: readOnly,
        checked: !viewDefinition.granularity.strict,
        onChange: (showDates) => {
          updateViewDefinition((oldViewDefinition) => (joinObjects(
            oldViewDefinition,
            { granularity: { periodicity: viewDefinition.granularity.periodicity, displayEmpty: oldViewDefinition.granularity?.displayEmpty ?? false, strict: !showDates } }
          )));
        },
      },
    }
  );

  return (
    <>
      {editionOptions.map((option) => (<ViewOptionBlock key={option.key} option={option} />))}
    </>
  );
};

export default TimeseriesTableViewDefinitionOptions;
