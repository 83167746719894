import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { SearchFieldDisplay, SearchFieldDisplay_Role_ConceptDefinition, SearchFieldDisplay_Role_Field } from 'yooi-modules/modules/conceptLayoutModule/ids';
import { getConceptDefinitionValidFields, isConceptDefinitionField } from 'yooi-modules/modules/conceptModule';
import { ExternalKeyField, Field_Title, IdField, TextField } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { filterNullOrUndefined } from 'yooi-utils';
import { ButtonVariant } from '../../../../../components/atoms/Button';
import { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import TableCell from '../../../../../components/molecules/TableCell';
import TableLine from '../../../../../components/molecules/TableLine';
import DataTable from '../../../../../components/templates/DataTable';
import useStore from '../../../../../store/useStore';
import i18n from '../../../../../utils/i18n';
import { getFieldChip } from '../../../../_global/fieldUtils';
import { defaultOptionComparator, getChipOptions } from '../../../../_global/modelTypeUtils';

interface SearchConfigurationEditorProps {
  conceptDefinitionId: string,
}

const SearchConfigurationEditor: FunctionComponent<SearchConfigurationEditorProps> = ({ conceptDefinitionId }) => {
  const store = useStore();

  const [showLine, setShowLine] = useState(false);

  const fields = store.withAssociation(SearchFieldDisplay)
    .withRole(SearchFieldDisplay_Role_ConceptDefinition, conceptDefinitionId)
    .list()
    .map((assoc) => assoc.navigateRole(SearchFieldDisplay_Role_Field));

  const selectableFields = () => getConceptDefinitionValidFields(store, conceptDefinitionId)
    .filter((field) => !fields.some(({ id }) => id === field.id))
    .filter((field) => isInstanceOf(field, TextField) || isInstanceOf(field, ExternalKeyField) || isInstanceOf(field, IdField))
    .map((field) => getChipOptions(store, field.id))
    .filter(filterNullOrUndefined)
    .sort(defaultOptionComparator);

  return (
    <DataTable
      list={fields.map((item) => ({ key: item.id, type: 'item', item, color: undefined }))}
      columnsDefinition={[
        {
          name: i18n`Name`,
          width: 100,
          propertyId: Field_Title,
          cellRender: ({ id }) => (
            <SearchAndSelect
              readOnly
              selectedOption={getFieldChip(store, conceptDefinitionId, id)}
              statusIcon={
                isConceptDefinitionField(store, id, conceptDefinitionId)
                  ? undefined : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
              }
            />
          ),
        },
      ]}
      linesActions={(line) => [
        {
          key: 'unlink',
          name: i18n`Unlink field`,
          icon: IconName.delete,
          danger: true,
          onClick: () => {
            store.withAssociation(SearchFieldDisplay)
              .withRole(SearchFieldDisplay_Role_Field, line.id)
              .withRole(SearchFieldDisplay_Role_ConceptDefinition, conceptDefinitionId)
              .deleteObject();
          },
        },
      ]}
      inlineCreation={{
        render: showLine ? (
          <TableLine>
            <TableCell>
              <SearchAndSelect
                computeOptions={selectableFields}
                editOnMount
                onSelect={(value) => {
                  store.withAssociation(SearchFieldDisplay)
                    .withRole(SearchFieldDisplay_Role_Field, value?.id as string)
                    .withRole(SearchFieldDisplay_Role_ConceptDefinition, conceptDefinitionId)
                    .updateObject({});
                  setShowLine(false);
                }}
                onEscape={() => setShowLine(false)}
              />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
          </TableLine>
        ) : null,
      }}
      onNewItem={() => setShowLine(true)}
      newItemIcon={IconName.add}
      newItemTitle={i18n`Add`}
      newItemButtonVariant={ButtonVariant.tertiary}
      handleClickAway={() => setShowLine(false)}
    />
  );
};

export default SearchConfigurationEditor;
