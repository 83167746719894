import { useFormulaV2 } from 'yooi-modules';
import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import { OverridableDisplayType } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import type {
  FieldLocalOverrideRaw,
  FieldLocalOverrideStoreObject,
  FieldStoreObject,
  Formula,
  NumberColorStepsValue,
  NumberColorStepValue,
  PathStep,
  SingleParameterDefinition,
  TimeseriesFieldValue,
  TimeseriesNumberFieldRaw,
  TimeseriesNumberFieldStoreObject,
  ValueFormulaInput,
} from 'yooi-modules/modules/conceptModule';
import {
  buildInputSet,
  duplicatePath,
  InstanceReferenceType,
  isFieldStep,
  isRelationalType,
  NumberColorStepValueType,
  parseFormula,
  PathStepType,
  timeseriesNumberFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import type { TimeseriesExportConfiguration } from 'yooi-modules/modules/conceptModule/fields';
import {
  Field_ApiAlias,
  Field_Documentation,
  Field_Formula,
  Field_IntegrationOnly,
  Field_IsCore,
  Field_IsDocumentationInline,
  Field_Title,
  FieldLocalOverride,
  FieldLocalOverride_Role_Concept,
  FieldLocalOverride_Role_Field,
  FieldLocalOverride_TimeseriesNumberFieldCumulative,
  FieldLocalOverride_TimeseriesNumberFieldDecimals,
  FieldLocalOverride_TimeseriesNumberFieldDefaultPeriod,
  FieldLocalOverride_TimeseriesNumberFieldInvalidColor,
  FieldLocalOverride_TimeseriesNumberFieldMaxValue,
  FieldLocalOverride_TimeseriesNumberFieldMinValue,
  FieldLocalOverride_TimeseriesNumberFieldRangeValue,
  FieldLocalOverride_TimeseriesNumberFieldUnit,
  NumberField,
  TimeseriesNumberField,
  TimeseriesNumberField_Cumulative,
  TimeseriesNumberField_Decimals,
  TimeseriesNumberField_DefaultPeriod,
  TimeseriesNumberField_InvalidColor,
  TimeseriesNumberField_MaxValue,
  TimeseriesNumberField_MinValue,
  TimeseriesNumberField_RangeValue,
  TimeseriesNumberField_Unit,
} from 'yooi-modules/modules/conceptModule/ids';
import type { TimeseriesFormulaInput } from 'yooi-modules/modules/conceptModule/model/field';
import type { PlatformConfigurationStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import { CurrentPlatformConfiguration, PlatformConfiguration_ColorPalette } from 'yooi-modules/modules/platformConfigurationModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreReadOnly } from 'yooi-store';
import { compareNumber, comparing, filterNullOrUndefined, isFiniteNumber, joinObjects, numberType, PeriodicityType } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../../../components/atoms/Button';
import { IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import NumberPicker from '../../../../../components/inputs/NumberPicker';
import SimpleInput from '../../../../../components/inputs/strategy/SimpleInput';
import TextInputString from '../../../../../components/inputs/TextInputString';
import Link from '../../../../../components/molecules/Link';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../../../../components/molecules/Table';
import type { ItemEntry } from '../../../../../components/templates/DataTable';
import DataTable from '../../../../../components/templates/DataTable';
import { getPeriodicityOption, getPeriodicityOptions } from '../../../../../utils/dateUtilsFront';
import i18n from '../../../../../utils/i18n';
import { formatOrUndef } from '../../../../../utils/stringUtils';
import { TickResolutionStatus } from '../../../fieldUtils';
import { sanitizeFormulaInputName } from '../../../formulaRendererUtils';
import StoreTextInputField from '../../../input/StoreTextInputField';
import { getConceptDefinitionNameOrEntity } from '../../../modelTypeUtils';
import PathMappingAndFiltersInput from '../../../path/PathMappingAndFiltersInput';
import PathStepsInput from '../../../path/PathStepsInput';
import { createPathConfigurationHandler, StepValidationState } from '../../../pathConfigurationHandler';
import BlockField from '../../_global/BlockField';
import { getApiAliasInitialState, getDimensionsEditionOption, getDocumentationFieldEditionSection, getIntegrationFieldEditionSection } from '../../_global/editionHandlerUtils';
import NumberColorStepInput from '../../_global/NumberColorStepInput';
import NumberColorStepsInput from '../../_global/NumberColorStepsInput';
import { getOptionalOverridableDisplayTypeOption, getOverridableDisplayTypeLabel, getOverridableDisplayTypeOptions } from '../../_global/overridableFieldUtils';
import { duplicateFormula } from '../../duplicationUtils';
import type { FieldEditionDimensions } from '../../fieldDimensionUtils';
import {
  createAndLinkFieldToConceptDefinitions,
  duplicateFieldDimensionWithNewField,
  FIELD_DIMENSIONS_READONLY,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
  submitDimensionUpdate,
} from '../../fieldDimensionUtils';
import type { FieldEditionOption, FieldEditionSection, FieldEditionSectionGroup } from '../../FieldEditionOptionType';
import { EditionOptionTypes } from '../../FieldEditionOptionType';
import { registerFieldDefinition } from '../../FieldLibrary';
import type { FieldComparatorHandler, GetFieldDefinitionHandler } from '../../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldIntegrationOnlyDisabled } from '../../FieldLibraryTypes';
import TimeseriesExportConfigurationInput from '../TimeseriesExportConfigurationInput';
import TimeseriesTable from '../TimeseriesTable';
import TimeseriesNumberFieldNumberPicker from './TimeseriesNumberFieldNumberPicker';
import { getTimeseriesMaxMinValues, getTimeseriesRangeValues } from './timeseriesNumberFieldUtils';

interface TimeseriesNumberFieldBlockDisplayOptions extends FieldBlockDisplayOptions {
  displayType?: OverridableDisplayType,
}

const defaultDisplayOption: TimeseriesNumberFieldBlockDisplayOptions = { displayType: OverridableDisplayType.Value };

const getDisplayOptions = (objectStore: ObjectStoreReadOnly, fieldBlockDisplayId: string): TimeseriesNumberFieldBlockDisplayOptions => {
  const fieldBlockDisplay = objectStore.getObject(fieldBlockDisplayId);
  if (fieldBlockDisplay[FieldBlockDisplay_FieldDisplayConfiguration]) {
    return fieldBlockDisplay[FieldBlockDisplay_FieldDisplayConfiguration] as TimeseriesNumberFieldBlockDisplayOptions;
  } else {
    return defaultDisplayOption;
  }
};

interface TimeseriesNumberFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [FIELD_DIMENSIONS_READONLY]: boolean | undefined,
  [Field_Title]?: string | null | undefined,
  [Field_ApiAlias]?: string | null | undefined,
  [Field_Documentation]?: string | null | undefined,
  [Field_IsDocumentationInline]?: boolean | null | undefined,
  [Field_IntegrationOnly]?: boolean | null | undefined,
  [FieldIntegrationOnlyDisabled]?: boolean | undefined,
  [Field_Formula]?: Formula | null | undefined,
  [TimeseriesNumberField_Decimals]: number | null | undefined,
  [TimeseriesNumberField_DefaultPeriod]: PeriodicityType | null | undefined,
  [TimeseriesNumberField_Cumulative]: boolean | null | undefined,
  [TimeseriesNumberField_InvalidColor]: string | null | undefined,
  [TimeseriesNumberField_MinValue]: NumberColorStepValue | null | undefined,
  [TimeseriesNumberField_MaxValue]: NumberColorStepValue | null | undefined,
  [TimeseriesNumberField_RangeValue]: NumberColorStepsValue[] | null | undefined,
  [TimeseriesNumberField_Unit]: string | null | undefined,
}

type TimeseriesNumberFieldDefinition = GetFieldDefinitionHandler<
  typeof timeseriesNumberFieldHandler,
  TimeseriesNumberFieldConfigurationState,
  never,
  TimeseriesNumberFieldBlockDisplayOptions,
  TimeseriesExportConfiguration
>;

export const timeseriesNumberFieldDefinition: TimeseriesNumberFieldDefinition = registerFieldDefinition(timeseriesNumberFieldHandler, {
  configuration: {
    typeIcon: IconName.avg_pace,
    getTypeLabel: () => i18n`Numbers Timeseries`,
    asWidget: false,
    getEditionOptions: (store) => ({ mode, editionHandler, readOnly }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode, FieldEditionOptionMode.Override].includes(mode)) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      const fieldEditionDimensions: FieldEditionDimensions = editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {};
      const parameterDefinitions: SingleParameterDefinition[] = Object.entries(fieldEditionDimensions)
        .map(([id, { typeId }]) => ({ id, typeId, label: i18n`Dimension`, type: 'dimension' }));

      const dataOptions: FieldEditionOption[] = [];

      if ([FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        dataOptions.push(
          getDimensionsEditionOption(editionHandler, Boolean(editionHandler.getValue(Field_Formula)), readOnly || Boolean(editionHandler.getValue(FIELD_DIMENSIONS_READONLY)))
        );
      }

      dataOptions.push({
        key: TimeseriesNumberField_DefaultPeriod,
        title: i18n`Default periodicity`,
        hasValue: () => editionHandler.getValue(TimeseriesNumberField_DefaultPeriod) !== undefined,
        clearValue: () => editionHandler.updateValues({ [TimeseriesNumberField_DefaultPeriod]: null }),
        type: EditionOptionTypes.select,
        props: {
          readOnly,
          selectedOption: getPeriodicityOption(editionHandler.getValueOrDefault(TimeseriesNumberField_DefaultPeriod) ?? PeriodicityType.day),
          computeOptions: () => getPeriodicityOptions(),
          onChange: (value) => editionHandler.updateValues({ [TimeseriesNumberField_DefaultPeriod]: (value?.id as PeriodicityType | undefined) ?? null }),
        },
      });

      sections.push({
        key: 'data',
        type: 'section',
        title: i18n`Data`,
        options: dataOptions,
      });

      if ([FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        sections.push(getDocumentationFieldEditionSection(editionHandler));
        sections.push(getIntegrationFieldEditionSection(store, editionHandler, mode));
      }

      sections.push({
        key: 'display',
        type: 'section',
        title: i18n`Display`,
        options: [
          {
            key: TimeseriesNumberField_InvalidColor,
            title: i18n`Invalid color`,
            info: i18n`Before min value and after max value`,
            hasValue: () => editionHandler.getValue(TimeseriesNumberField_InvalidColor) !== undefined,
            clearValue: () => editionHandler.updateValues({ [TimeseriesNumberField_InvalidColor]: null }),
            type: EditionOptionTypes.color,
            props: {
              readOnly,
              value: editionHandler.getValueOrDefault(TimeseriesNumberField_InvalidColor),
              onChange: (value) => editionHandler.updateValues({ [TimeseriesNumberField_InvalidColor]: value }),
              colorPalette: store.getObject<PlatformConfigurationStoreObject>(CurrentPlatformConfiguration)[PlatformConfiguration_ColorPalette],
            },
          },
          {
            key: TimeseriesNumberField_MinValue,
            title: i18n`Min value`,
            hasValue: () => editionHandler.getValue(TimeseriesNumberField_MinValue) !== undefined,
            clearValue: () => editionHandler.updateValues({ [TimeseriesNumberField_MinValue]: null }),
            type: EditionOptionTypes.custom,
            props: {
              render: () => (
                <NumberColorStepInput
                  value={
                    editionHandler.getValueOrDefault(TimeseriesNumberField_MinValue) as NumberColorStepValue | undefined
                    ?? { type: NumberColorStepValueType.value, value: undefined, color: undefined }
                  }
                  parameterDefinitions={parameterDefinitions}
                  onChange={(newValue) => editionHandler.updateValues({ [TimeseriesNumberField_MinValue]: newValue })}
                  readOnly={readOnly}
                />
              ),
            },
          },
          {
            key: TimeseriesNumberField_RangeValue,
            title: i18n`Ranges`,
            hasValue: () => editionHandler.getValue(TimeseriesNumberField_RangeValue) !== undefined,
            clearValue: () => editionHandler.updateValues({ [TimeseriesNumberField_RangeValue]: null }),
            type: EditionOptionTypes.custom,
            props: {
              render: () => (
                <NumberColorStepsInput
                  steps={editionHandler.getValueOrDefault(TimeseriesNumberField_RangeValue) ?? []}
                  onChange={(newSteps) => {
                    editionHandler.updateValues({ [TimeseriesNumberField_RangeValue]: newSteps });
                  }}
                  parameterDefinitions={parameterDefinitions}
                  readOnly={readOnly}
                />
              ),
            },
          },
          {
            key: TimeseriesNumberField_MaxValue,
            title: i18n`Max value`,
            hasValue: () => editionHandler.getValue(TimeseriesNumberField_MaxValue) !== undefined,
            clearValue: () => editionHandler.updateValues({ [TimeseriesNumberField_MaxValue]: null }),
            type: EditionOptionTypes.custom,
            props: {
              render: () => (
                <NumberColorStepInput
                  value={
                    editionHandler.getValueOrDefault(TimeseriesNumberField_MaxValue) as NumberColorStepValue | undefined
                    ?? { type: NumberColorStepValueType.value, value: undefined, color: undefined }
                  }
                  parameterDefinitions={parameterDefinitions}
                  onChange={(newValue) => editionHandler.updateValues({ [TimeseriesNumberField_MaxValue]: newValue })}
                  readOnly={readOnly}
                />
              ),
            },
          },
          {
            key: TimeseriesNumberField_Unit,
            title: i18n`Unit`,
            hasValue: () => editionHandler.getValue(TimeseriesNumberField_Unit) !== undefined,
            clearValue: () => editionHandler.updateValues({ [TimeseriesNumberField_Unit]: null }),
            type: EditionOptionTypes.text,
            props: {
              readOnly,
              placeholder: i18n`Add unit`,
              value: editionHandler.getValueOrDefault(TimeseriesNumberField_Unit),
              onChange: (newValue) => editionHandler.updateValues({ [TimeseriesNumberField_Unit]: newValue }),
            },
          },
          {
            key: TimeseriesNumberField_Decimals,
            title: i18n`Decimals`,
            hasValue: () => editionHandler.getValue(TimeseriesNumberField_Decimals) !== undefined,
            clearValue: () => editionHandler.updateValues({ [TimeseriesNumberField_Decimals]: null }),
            type: EditionOptionTypes.number,
            props: {
              readOnly,
              placeholder: i18n`Add decimal`,
              value: editionHandler.getValueOrDefault(TimeseriesNumberField_Decimals),
              onChange: (value) => editionHandler.updateValues({ [TimeseriesNumberField_Decimals]: value }),
            },
          },
        ],
      });

      if ([FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        const formulaSections: FieldEditionSectionGroup['sections'] = [];

        const formula = editionHandler.getValue(Field_Formula);
        if (formula) {
          const onChangeValueInputPath = (index: number, newPath: PathStep[]) => {
            editionHandler.updateValues({
              [Field_Formula]: {
                formula: formula.formula,
                inputs: formula.inputs
                  .map((entry, i) => (i === index ? joinObjects(entry, { input: joinObjects(entry.input, { path: newPath }) }) : entry)),
              },
            });
          };

          formulaSections.push({
            key: `${Field_Formula}_forEach`,
            title: i18n`For each`,
            info: i18n`The formula will be executed for each timestamp extracted from inputs below.\nEach input will be available as timeseries (using the input name) and as value at the execution instant (using the input name suffixed by _i).`,
            options: [
              {
                key: `${Field_Formula}_forEach`,
                hasValue: () => editionHandler.getValue(Field_Formula) !== undefined,
                clearValue: () => editionHandler.updateValues({ [Field_Formula]: null }),
                isVertical: true,
                padded: true,
                type: EditionOptionTypes.custom,
                props: {
                  render: () => {
                    const valueInputPathHandler = createPathConfigurationHandler(
                      store,
                      parameterDefinitions,
                      [
                        ({ pathStep }) => {
                          if (isFieldStep(pathStep)) {
                            const field = store.getObjectOrNull<FieldStoreObject>(pathStep.fieldId);
                            const fieldDefinitionId = field?.[Instance_Of];
                            if (!fieldDefinitionId) {
                              return [{ state: StepValidationState.invalid, reasonMessage: i18n`Input end with an unknown field.` }];
                            } else if (fieldDefinitionId === TimeseriesNumberField || (NumberField === fieldDefinitionId && field[Field_Formula] === undefined)) {
                              return [{ state: StepValidationState.valid }];
                            } else if (isRelationalType(fieldDefinitionId)) {
                              return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`Input end with an unauthorized field.` }];
                            } else {
                              return [{ state: StepValidationState.invalid, reasonMessage: i18n`Input end with an unauthorized field.` }];
                            }
                          } else {
                            return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`Input end with an unauthorized element.` }];
                          }
                        },
                      ]
                    );

                    return (
                      <DataTable
                        list={
                          formula.inputs
                            .map((entry, index) => (joinObjects(entry, { index })))
                            .filter((entry): entry is { index: number, name: string, input: TimeseriesFormulaInput } => entry.input.type === 'timeseries')
                            .map((entry) => ({ key: entry.name, type: 'item', item: entry, color: undefined }))
                        }
                        columnsDefinition={[
                          {
                            propertyId: 'name',
                            name: i18n`Name`,
                            width: 15,
                            cellRender: ({ name, index }) => (
                              <StoreTextInputField
                                initialValue={name}
                                onSubmit={(newName) => {
                                  const newInputs = [...formula.inputs];
                                  newInputs[index] = joinObjects(
                                    formula.inputs[index],
                                    {
                                      name: sanitizeFormulaInputName(
                                        newName ?? '',
                                        [
                                          'ExecutionInstant_i',
                                          ...formula.inputs
                                            .filter((_, i) => index !== i)
                                            .flatMap((entry) => (entry.input.type === 'timeseries' ? [entry.name, `${entry.name}_i`] : [entry.name])),
                                        ]
                                      ),
                                    }
                                  );

                                  editionHandler.updateValues({ [Field_Formula]: { formula: formula.formula, inputs: newInputs } });
                                }}
                              />
                            ),
                          },
                          {
                            propertyId: 'path',
                            name: i18n`Path`,
                            width: 70,
                            cellRender: ({ index, input }) => (
                              <PathStepsInput
                                initialPath={input.path}
                                onSubmit={(newPath) => onChangeValueInputPath(index, newPath)}
                                parameterDefinitions={parameterDefinitions}
                                valuePathHandler={valueInputPathHandler}
                                suggestedBasePaths={
                                  // if there is only 1 parameter, default path is "concept/instance"
                                  parameterDefinitions.length === 1
                                    ? [
                                      {
                                        label: i18n`Current (${formatOrUndef(getConceptDefinitionNameOrEntity(store, parameterDefinitions[0].typeId))})`,
                                        path: [
                                          { type: PathStepType.dimension, conceptDefinitionId: parameterDefinitions[0].typeId },
                                          { type: PathStepType.mapping, mapping: { id: parameterDefinitions[0].id, type: InstanceReferenceType.parameter } },
                                        ],
                                      },
                                    ]
                                    // if there no dimension or more than one, there is no default path
                                    : undefined
                                }
                              />
                            ),
                          },
                          {
                            propertyId: 'filters',
                            name: i18n`Filters`,
                            width: 15,
                            cellRender: ({ index, input }) => (
                              <PathMappingAndFiltersInput
                                path={input.path}
                                parameterDefinitions={parameterDefinitions}
                                onChange={(newPath) => onChangeValueInputPath(index, newPath)}
                              />
                            ),
                          },
                        ]}
                        linesActions={({ index }) => [
                          {
                            key: 'duplicate',
                            name: i18n`Duplicate`,
                            icon: IconName.content_copy_outline,
                            onClick: () => {
                              const newInputs = [...formula.inputs];
                              newInputs.splice(
                                index + 1,
                                0,
                                joinObjects(
                                  formula.inputs[index],
                                  {
                                    name: sanitizeFormulaInputName(
                                      formula.inputs[index].name,
                                      [
                                        'ExecutionInstant_i',
                                        ...formula.inputs.flatMap((entry) => (entry.input.type === 'timeseries' ? [entry.name, `${entry.name}_i`] : [entry.name])),
                                      ]
                                    ),
                                  }
                                )
                              );
                              editionHandler.updateValues({ [Field_Formula]: { formula: formula.formula, inputs: newInputs } });
                            },
                          },
                          {
                            key: 'delete',
                            name: i18n`Delete`,
                            icon: IconName.delete,
                            onClick: () => {
                              editionHandler.updateValues({
                                [Field_Formula]: {
                                  formula: formula.formula,
                                  inputs: formula.inputs.filter((_, i) => i !== index),
                                },
                              });
                            },
                            danger: true,
                          },
                        ]}
                        newItemIcon={IconName.add}
                        onNewItem={() => {
                          editionHandler.updateValues({
                            [Field_Formula]: {
                              formula: formula.formula,
                              inputs: [
                                ...formula.inputs,
                                {
                                  name: sanitizeFormulaInputName(
                                    'Input1',
                                    ['ExecutionInstant_i', ...formula.inputs.flatMap((entry) => (entry.input.type === 'timeseries' ? [entry.name, `${entry.name}_i`] : [entry.name])),
                                    ]
                                  ),
                                  input: { type: 'timeseries', path: [] },
                                },
                              ],
                            },
                          });
                        }}
                        newItemTitle={i18n`Add`}
                        newItemButtonVariant={ButtonVariant.secondary}
                        fullWidth
                      />
                    );
                  },
                },
              },
            ],
          });

          let error: string | undefined;
          if (useFormulaV2()) {
            try {
              const parameters: { id: string, typeIds: string[] }[] = parameterDefinitions.map((parameter) => ({ id: parameter.id, typeIds: [parameter.typeId] }));
              const { inputSet } = buildInputSet(store, parameters, formula.inputs, true);
              const rootNode = parseFormula(formula.formula, inputSet);
              if (!numberType.isAssignableFrom(rootNode.type)) {
                error = i18n`Return type doesn't match the expected return type.`;
              }
            } catch (_) {
              error = i18n`Something went wrong while parsing formula.`;
            }
          }

          formulaSections.push({
            key: `${Field_Formula}_execute`,
            title: i18n`Execute`,
            rightActions: (
              <Link
                key="functions"
                title={i18n`Functions library`}
                to="/settings/formula-documentation"
                iconName={IconName.output}
                openInNewTab
              />
            ),
            options: [
              {
                key: `${Field_Formula}_formula`,
                type: EditionOptionTypes.text,
                title: i18n`Formula`,
                hasValue: () => editionHandler.getValue(Field_Formula) !== undefined,
                clearValue: () => editionHandler.updateValues({ [Field_Formula]: null }),
                props: {
                  placeholder: i18n`Add formula`,
                  value: formula.formula,
                  maxLine: 15,
                  onChange: (eventValue) => editionHandler.updateValues({ [Field_Formula]: { formula: eventValue ?? '', inputs: formula.inputs } }),
                  error,
                },
              },
              {
                key: TimeseriesNumberField_Cumulative,
                title: i18n`Cumulative`,
                info: i18n`Cumulate each temporal input value from the "For each" loop with all its previous values, with the cumulative result stored in the input suffixed in _i`,
                hasValue: () => editionHandler.getValue(TimeseriesNumberField_Cumulative) !== undefined,
                clearValue: () => editionHandler.updateValues({ [TimeseriesNumberField_Cumulative]: null }),
                type: EditionOptionTypes.checkbox,
                padded: true,
                props: {
                  checked: editionHandler.getValueOrDefault(TimeseriesNumberField_Cumulative) ?? false,
                  onChange: (checked: boolean) => editionHandler.updateValues({ [TimeseriesNumberField_Cumulative]: checked }),
                },
              },
              {
                key: `${Field_Formula}_inputs`,
                title: i18n`Inputs`,
                hasValue: () => false,
                clearValue: () => {},
                type: EditionOptionTypes.custom,
                isVertical: true,
                padded: true,
                props: {
                  render: () => {
                    const valueInputPathHandler = createPathConfigurationHandler(store, parameterDefinitions);
                    return (
                      <DataTable<{ index: number, name: string, input: ValueFormulaInput | TimeseriesFormulaInput | { type: 'engine', description: string } }>
                        list={[
                          ...formula.inputs
                            .map((entry, index) => (joinObjects(entry, { index })))
                            .filter((entry): entry is { index: number, name: string, input: TimeseriesFormulaInput } => entry.input.type === 'timeseries')
                            .map((entry): ItemEntry<{ index: number, name: string, input: TimeseriesFormulaInput }> => (
                              { key: entry.name, type: 'item', item: entry, color: undefined }
                            )),
                          {
                            key: 'ExecutionInstant',
                            type: 'item',
                            item: { index: -1, name: 'ExecutionInstant_i', input: { type: 'engine', description: i18n`Timestamp of the formula execution` } },
                            color: undefined,
                          },
                          ...formula.inputs
                            .map((entry, index) => (joinObjects(entry, { index })))
                            .filter((entry): entry is { index: number, name: string, input: ValueFormulaInput } => entry.input.type === 'value')
                            .map((entry): ItemEntry<{ index: number, name: string, input: ValueFormulaInput }> => (
                              { key: entry.name, type: 'item', item: entry, color: undefined }
                            )),
                        ]}
                        columnsDefinition={[
                          {
                            propertyId: 'name',
                            name: i18n`Name`,
                            width: 15,
                            cellRender: ({ name, index, input }) => {
                              const inputName = input.type === 'timeseries' ? `${name}_i` : name;
                              return (
                                <StoreTextInputField
                                  initialValue={inputName}
                                  onSubmit={(newName) => {
                                    if (input.type === 'value') {
                                      const newInputs = [...formula.inputs];
                                      newInputs[index] = joinObjects(
                                        formula.inputs[index],
                                        {
                                          name: sanitizeFormulaInputName(
                                            newName ?? '',
                                            [
                                              'ExecutionInstant_i',
                                              ...formula.inputs
                                                .filter((_, i) => index !== i)
                                                .flatMap((entry) => (entry.input.type === 'timeseries' ? [entry.name, `${entry.name}_i`] : [entry.name])),
                                            ]
                                          ),
                                        }
                                      );
                                      editionHandler.updateValues({ [Field_Formula]: { formula: formula.formula, inputs: newInputs } });
                                    }
                                  }}
                                  acceptChars={/^[A-Za-z0-9_]+$/}
                                  acceptCharsErrorMessage={i18n`Formula input alias should only contain letters, numbers or '_'`}
                                  warning={
                                    input.type === 'value' && ![...(formula.formula ?? '').matchAll(/([A-Za-z0-9_]+)/g)].some(([, group]) => group.toLocaleLowerCase() === inputName.toLocaleLowerCase())
                                      ? i18n`Input is not used in formula` : undefined
                                  }
                                  readOnly={input.type !== 'value'}
                                  disabled={input.type !== 'value'}
                                />
                              );
                            },
                          },
                          {
                            propertyId: 'path',
                            name: i18n`Path`,
                            width: 70,
                            cellRender: ({ index, name, input }) => {
                              if (input.type === 'engine') {
                                return (<TextInputString value={input.description} disabled readOnly />);
                              } else if (input.type === 'timeseries') {
                                return (
                                  <TextInputString
                                    value={
                                      editionHandler.getValue(TimeseriesNumberField_Cumulative)
                                        ? i18n`Sum of all values of ${name} before (and including) ExecutionInstant_i`
                                        : i18n`Value of ${name} at ExecutionInstant_i`
                                    }
                                    disabled
                                    readOnly
                                  />
                                );
                              } else {
                                return (
                                  <PathStepsInput
                                    initialPath={input.path}
                                    onSubmit={(newPath) => onChangeValueInputPath(index, newPath)}
                                    parameterDefinitions={parameterDefinitions}
                                    valuePathHandler={valueInputPathHandler}
                                    suggestedBasePaths={
                                      // if there is only 1 parameter, default path is "concept/instance"
                                      parameterDefinitions.length === 1
                                        ? [
                                          {
                                            label: i18n`Current (${formatOrUndef(getConceptDefinitionNameOrEntity(store, parameterDefinitions[0].typeId))})`,
                                            path: [
                                              { type: PathStepType.dimension, conceptDefinitionId: parameterDefinitions[0].typeId },
                                              { type: PathStepType.mapping, mapping: { id: parameterDefinitions[0].id, type: InstanceReferenceType.parameter } },
                                            ],
                                          },
                                        ]
                                        // if there no dimension or more than one, there is no default path
                                        : undefined
                                    }
                                    readOnly={input.type !== 'value'}
                                  />
                                );
                              }
                            },
                          },
                          {
                            propertyId: 'filters',
                            name: i18n`Filters`,
                            width: 15,
                            cellRender: ({ input, index }) => {
                              if (input.type === 'value') {
                                return (
                                  <PathMappingAndFiltersInput
                                    path={input.path}
                                    parameterDefinitions={parameterDefinitions}
                                    onChange={(newPath) => onChangeValueInputPath(index, newPath)}
                                  />
                                );
                              } else {
                                return null;
                              }
                            },
                          },
                        ]}
                        linesActions={({ input, index }) => {
                          if (input.type === 'value') {
                            return [
                              {
                                key: 'duplicate',
                                name: i18n`Duplicate`,
                                icon: IconName.content_copy_outline,
                                onClick: () => {
                                  const newInputs = [...formula.inputs];
                                  newInputs.splice(
                                    index + 1,
                                    0,
                                    joinObjects(
                                      formula.inputs[index],
                                      {
                                        name: sanitizeFormulaInputName(
                                          formula.inputs[index].name,
                                          [
                                            'ExecutionInstant_i',
                                            ...formula.inputs.flatMap((entry) => (entry.input.type === 'timeseries' ? [entry.name, `${entry.name}_i`] : [entry.name])),
                                          ]
                                        ),
                                      }
                                    )
                                  );
                                  editionHandler.updateValues({ [Field_Formula]: { formula: formula.formula, inputs: newInputs } });
                                },
                              },
                              {
                                key: 'delete',
                                name: i18n`Delete`,
                                icon: IconName.delete,
                                onClick: () => {
                                  editionHandler.updateValues({ [Field_Formula]: { formula: formula.formula, inputs: formula.inputs.filter((_, i) => i !== index) } });
                                },
                                danger: true,
                              },
                            ];
                          } else {
                            return [];
                          }
                        }}
                        newItemIcon={IconName.add}
                        onNewItem={() => {
                          editionHandler.updateValues({
                            [Field_Formula]: {
                              formula: formula.formula,
                              inputs: [
                                ...formula.inputs,
                                {
                                  name: sanitizeFormulaInputName('Input1', [
                                    'ExecutionInstant_i',
                                    ...formula.inputs
                                      .flatMap((entry) => (entry.input.type === 'timeseries' ? [entry.name, `${entry.name}_i`] : [entry.name])),
                                  ]),
                                  input: { type: 'value', path: [] },
                                }],
                            },
                          });
                        }}
                        newItemTitle={i18n`Add`}
                        newItemButtonVariant={ButtonVariant.secondary}
                        fullWidth
                      />
                    );
                  },
                },
              },
            ],
          });

          sections.push({
            key: 'formula',
            type: 'group',
            title: i18n`Formula`,
            rightActions: (
              <SpacingLine>
                <IconOnlyButton
                  iconName={IconName.delete}
                  tooltip={i18n`Delete`}
                  onClick={() => editionHandler.updateValues({ [Field_Formula]: null })}
                  variant={IconOnlyButtonVariants.danger}
                />
              </SpacingLine>
            ),
            sections: formulaSections,
          });
        } else {
          sections.push({
            key: 'formula',
            type: 'section',
            options: [
              {
                key: Field_Formula,
                hasValue: () => editionHandler.getValue(Field_Formula) !== undefined,
                clearValue: () => editionHandler.updateValues({ [Field_Formula]: null }),
                type: EditionOptionTypes.custom,
                isVertical: true,
                padded: true,
                props: {
                  render: () => (
                    <SpacingLine>
                      <Button
                        iconName={IconName.fx}
                        title={i18n`Use formula`}
                        onClick={() => editionHandler.updateValues({ [Field_Formula]: { formula: '', inputs: [] } })}
                      />
                    </SpacingLine>
                  ),
                },
              },
            ],
          });
        }
      }

      return sections;
    },
    isCreationEnabled: () => () => true,
    onCreate: (objectStore) => (editionHandler) => {
      const fieldId = objectStore.createObject<TimeseriesNumberFieldRaw>({
        [Instance_Of]: TimeseriesNumberField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [Field_ApiAlias]: editionHandler.getValue(Field_ApiAlias),
        [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
        [Field_Formula]: editionHandler.getValue(Field_Formula),
        [TimeseriesNumberField_Decimals]: editionHandler.getValue(TimeseriesNumberField_Decimals),
        [TimeseriesNumberField_DefaultPeriod]: editionHandler.getValue(TimeseriesNumberField_DefaultPeriod),
        [TimeseriesNumberField_Cumulative]: editionHandler.getValue(TimeseriesNumberField_Cumulative),
        [TimeseriesNumberField_InvalidColor]: editionHandler.getValue(TimeseriesNumberField_InvalidColor),
        [TimeseriesNumberField_MinValue]: editionHandler.getValue(TimeseriesNumberField_MinValue),
        [TimeseriesNumberField_MaxValue]: editionHandler.getValue(TimeseriesNumberField_MaxValue),
        [TimeseriesNumberField_RangeValue]: editionHandler.getValue(TimeseriesNumberField_RangeValue),
        [TimeseriesNumberField_Unit]: editionHandler.getValue(TimeseriesNumberField_Unit),
      });
      linkFieldToFieldDimensions(objectStore, fieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});
      return fieldId;
    },
    inlineCreate: (objectStore) => (conceptDefinitionId, extraFieldOptions) => ({
      type: 'inline',
      onCreate: (title) => {
        const newFieldId = objectStore.createObject<TimeseriesNumberFieldRaw>(joinObjects(
          extraFieldOptions,
          {
            [Instance_Of]: TimeseriesNumberField,
            [Field_Title]: title,
          }
        ));
        createAndLinkFieldToConceptDefinitions(objectStore, newFieldId, [conceptDefinitionId]);
        return newFieldId;
      },
    }),
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<TimeseriesNumberFieldStoreObject>(fieldId);
        return joinObjects(
          getApiAliasInitialState(objectStore, fieldId),
          {
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            [Field_IntegrationOnly]: field[Field_IntegrationOnly],
            [FieldIntegrationOnlyDisabled]: field[Field_IsCore],
            [Field_Formula]: field[Field_Formula],
            [TimeseriesNumberField_Decimals]: field[TimeseriesNumberField_Decimals],
            [TimeseriesNumberField_DefaultPeriod]: field[TimeseriesNumberField_DefaultPeriod],
            [TimeseriesNumberField_Cumulative]: field[TimeseriesNumberField_Cumulative],
            [TimeseriesNumberField_InvalidColor]: field[TimeseriesNumberField_InvalidColor],
            [TimeseriesNumberField_MinValue]: field[TimeseriesNumberField_MinValue],
            [TimeseriesNumberField_MaxValue]: field[TimeseriesNumberField_MaxValue],
            [TimeseriesNumberField_RangeValue]: field[TimeseriesNumberField_RangeValue],
            [TimeseriesNumberField_Unit]: field[TimeseriesNumberField_Unit],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
            [FIELD_DIMENSIONS_READONLY]: field[Field_IsCore],
          }
        );
      },
      getInitialOverrideState: (objectId) => {
        const localOverride = objectStore.withAssociation(FieldLocalOverride)
          .withRole(FieldLocalOverride_Role_Field, fieldId)
          .withRole(FieldLocalOverride_Role_Concept, objectId)
          .getObjectOrNull<FieldLocalOverrideStoreObject>();
        const field = objectStore.getObject<FieldStoreObject>(fieldId);
        const conceptDefinitionId = objectStore.getObject(objectId)[Instance_Of] as string;
        return {
          [TimeseriesNumberField_Decimals]: localOverride?.[FieldLocalOverride_TimeseriesNumberFieldDecimals],
          [TimeseriesNumberField_DefaultPeriod]: localOverride?.[FieldLocalOverride_TimeseriesNumberFieldDefaultPeriod],
          [TimeseriesNumberField_Cumulative]: localOverride?.[FieldLocalOverride_TimeseriesNumberFieldCumulative],
          [TimeseriesNumberField_InvalidColor]: localOverride?.[FieldLocalOverride_TimeseriesNumberFieldInvalidColor],
          [TimeseriesNumberField_MinValue]: localOverride?.[FieldLocalOverride_TimeseriesNumberFieldMinValue],
          [TimeseriesNumberField_MaxValue]: localOverride?.[FieldLocalOverride_TimeseriesNumberFieldMaxValue],
          [TimeseriesNumberField_RangeValue]: localOverride?.[FieldLocalOverride_TimeseriesNumberFieldRangeValue],
          [TimeseriesNumberField_Unit]: localOverride?.[FieldLocalOverride_TimeseriesNumberFieldUnit],
          [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
          [FIELD_DIMENSIONS_READONLY]: field[Field_IsCore],
        };
      },
      submitFieldOverrideUpdate: (objectId, stateToSubmit) => {
        const update = {
          [FieldLocalOverride_TimeseriesNumberFieldDecimals]: stateToSubmit[TimeseriesNumberField_Decimals],
          [FieldLocalOverride_TimeseriesNumberFieldDefaultPeriod]: stateToSubmit[TimeseriesNumberField_DefaultPeriod],
          [FieldLocalOverride_TimeseriesNumberFieldCumulative]: stateToSubmit[TimeseriesNumberField_Cumulative],
          [FieldLocalOverride_TimeseriesNumberFieldInvalidColor]: stateToSubmit[TimeseriesNumberField_InvalidColor],
          [FieldLocalOverride_TimeseriesNumberFieldMinValue]: stateToSubmit[TimeseriesNumberField_MinValue],
          [FieldLocalOverride_TimeseriesNumberFieldMaxValue]: stateToSubmit[TimeseriesNumberField_MaxValue],
          [FieldLocalOverride_TimeseriesNumberFieldRangeValue]: stateToSubmit[TimeseriesNumberField_RangeValue],
          [FieldLocalOverride_TimeseriesNumberFieldUnit]: stateToSubmit[TimeseriesNumberField_Unit],
        };

        objectStore.withAssociation(FieldLocalOverride)
          .withRole(FieldLocalOverride_Role_Field, fieldId)
          .withRole(FieldLocalOverride_Role_Concept, objectId)
          .updateObject<FieldLocalOverrideRaw>(update);
      },
      submitFieldUpdate: (stateToSubmit, conceptDefinitionId) => {
        objectStore.updateObject<TimeseriesNumberFieldRaw>(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: stateToSubmit[Field_IntegrationOnly],
          [Field_Formula]: stateToSubmit[Field_Formula],
          [TimeseriesNumberField_Decimals]: stateToSubmit[TimeseriesNumberField_Decimals],
          [TimeseriesNumberField_DefaultPeriod]: stateToSubmit[TimeseriesNumberField_DefaultPeriod],
          [TimeseriesNumberField_Cumulative]: stateToSubmit[TimeseriesNumberField_Cumulative],
          [TimeseriesNumberField_InvalidColor]: stateToSubmit[TimeseriesNumberField_InvalidColor],
          [TimeseriesNumberField_MinValue]: stateToSubmit[TimeseriesNumberField_MinValue],
          [TimeseriesNumberField_MaxValue]: stateToSubmit[TimeseriesNumberField_MaxValue],
          [TimeseriesNumberField_RangeValue]: stateToSubmit[TimeseriesNumberField_RangeValue],
          [TimeseriesNumberField_Unit]: stateToSubmit[TimeseriesNumberField_Unit],
        });
        submitDimensionUpdate(objectStore, fieldId, conceptDefinitionId, stateToSubmit[FIELD_EDITION_DIMENSIONS] ?? {});
      },
      duplicateFieldDefinition: () => {
        const field = objectStore.getObject<TimeseriesNumberFieldStoreObject>(fieldId);
        const fieldDimensionMapping = generateDuplicatedFieldDimensionId(objectStore, fieldId);

        const duplicateStep = <Step extends NumberColorStepValue = NumberColorStepValue>(step: Step | undefined): Step | undefined => {
          if (!step) {
            return undefined;
          } else if (step.type === NumberColorStepValueType.field) {
            const newStep = { ...step };
            newStep.value = duplicatePath(step.value, fieldDimensionMapping);
            return newStep;
          } else {
            return step;
          }
        };

        const newFieldId = objectStore.createObject<TimeseriesNumberFieldRaw>({
          [Instance_Of]: field[Instance_Of],
          [Field_Title]: `${field[Field_Title]} (copy)`,
          [Field_Documentation]: field[Field_Documentation],
          [Field_IntegrationOnly]: field[Field_IntegrationOnly],
          [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
          [Field_Formula]: duplicateFormula(field[Field_Formula], fieldDimensionMapping),
          [TimeseriesNumberField_Decimals]: field[TimeseriesNumberField_Decimals],
          [TimeseriesNumberField_DefaultPeriod]: field[TimeseriesNumberField_DefaultPeriod],
          [TimeseriesNumberField_Cumulative]: field[TimeseriesNumberField_Cumulative],
          [TimeseriesNumberField_InvalidColor]: field[TimeseriesNumberField_InvalidColor],
          [TimeseriesNumberField_MinValue]: duplicateStep(field[TimeseriesNumberField_MinValue]),
          [TimeseriesNumberField_RangeValue]: field[TimeseriesNumberField_RangeValue]?.map((range) => duplicateStep(range)).filter(filterNullOrUndefined),
          [TimeseriesNumberField_MaxValue]: duplicateStep(field[TimeseriesNumberField_MaxValue]),
          [TimeseriesNumberField_Unit]: field[TimeseriesNumberField_Unit],
        });
        duplicateFieldDimensionWithNewField(objectStore, newFieldId, fieldDimensionMapping);
        return newFieldId;
      },
    }),
  },
  getActivityProperties: (_, fieldId) => () => [fieldId],
  renderBlockField: (_, fieldId) => (dimensionsMapping, __, { readOnly, ...otherBlockFieldProps }) => (
    <BlockField
      fieldId={fieldId}
      {...otherBlockFieldProps}
      isVertical
    >
      <TimeseriesTable
        dimensionsMapping={dimensionsMapping}
        fieldId={fieldId}
        readOnly={readOnly ?? false}
      />
    </BlockField>
  ),
  renderField: (objectStore, fieldId) => ({ readOnly, time, dimensionsMapping, onSubmit, value }) => {
    if (!time) {
      return null;
    }

    const values = value ?? timeseriesNumberFieldHandler(objectStore, fieldId).getValueResolution(dimensionsMapping, undefined, { from: time, to: time }, true).value;
    return (
      <TimeseriesNumberFieldNumberPicker
        onSubmit={onSubmit}
        fieldId={fieldId}
        dimensionsMapping={dimensionsMapping}
        time={time}
        value={values?.find(({ time: t }) => t === time)?.value ?? undefined}
        readOnly={readOnly}
      />
    );
  },
  renderExportConfiguration: () => ({ configuration, onChange }) => (<TimeseriesExportConfigurationInput configuration={configuration} onChange={onChange} />),
  blockDisplayOptionsHandler: (objectStore) => (fieldDisplayBlockId) => ({
    getDisplayOptions: () => getDisplayOptions(objectStore, fieldDisplayBlockId),
    renderSummary: (state) => [getOverridableDisplayTypeLabel(state.displayType)],
    getBlockEditionOptionSections: (state, setState) => [
      {
        key: 'displayType',
        title: i18n`Display type`,
        action: {
          type: EditionOptionTypes.select,
          props: {
            computeOptions: () => getOverridableDisplayTypeOptions(),
            selectedOption: getOptionalOverridableDisplayTypeOption(state.displayType),
            onChange: (selectedOption) => {
              if (selectedOption) {
                setState(joinObjects(state, { displayType: selectedOption.id as OverridableDisplayType }));
              }
            },
          },
        },
        options: [],
      },
    ],
    onSubmit: (state) => {
      objectStore.updateObject(fieldDisplayBlockId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
  getComparatorHandler: (_, __, { getValueResolution }) => (direction) => ({
    comparator: comparing(compareNumber, direction === TableSortDirection.desc),
    extractValue: (dimensionsMapping, time) => {
      if (!time || !isFiniteNumber(time)) {
        return undefined;
      }
      const { value } = getValueResolution(dimensionsMapping, undefined, { from: time, to: time }, true);
      return value?.find(({ time: t }) => time === t)?.value ?? undefined;
    },
  }) satisfies FieldComparatorHandler<number | undefined>,
  input: (store, fieldId, handler) => ({
    render: ({ value, onSubmit, focusOnMount, readOnly, onEditionStart, onEditionStop, isEditing }) => {
      const timeseriesNumberField = handler.resolveConfiguration();
      const minMaxValues = getTimeseriesMaxMinValues(store, fieldId, {}, {});
      const ticks = getTimeseriesRangeValues(store, fieldId, {}, {});
      const field = store.getObject(fieldId);
      const isComputed = Boolean(field[Field_Formula]);
      const extractedTime = value && value[0].time;
      const extractedValue = value && value[0].value;

      return (
        <SimpleInput<TimeseriesFieldValue<number>>
          initialValue={value}
          onSubmit={onSubmit}
        >
          {(props) => (
            <NumberPicker
              value={extractedValue ?? undefined}
              onSubmit={(newValue) => (extractedTime && isFiniteNumber(newValue) ? onSubmit([{ time: extractedTime, value: newValue }]) : undefined)}
              onChange={(newValue) => {
                if (extractedTime) {
                  if (newValue !== undefined && newValue !== null && isFiniteNumber(newValue)) {
                    onSubmit([{ time: extractedTime, value: newValue as number }]);
                  } else {
                    onSubmit([{ time: extractedTime, value: undefined }]);
                  }
                }
              }}
              onCancel={props.onCancel}
              onEditionStart={onEditionStart}
              onEditionStop={onEditionStop}
              isEditing={isEditing}
              focusOnMount={focusOnMount}
              min={minMaxValues.min?.status === TickResolutionStatus.Resolved ? minMaxValues.min : undefined}
              ticks={ticks}
              max={minMaxValues.max?.status === TickResolutionStatus.Resolved ? minMaxValues.max : undefined}
              invalidColor={timeseriesNumberField.invalidColor}
              decimals={timeseriesNumberField.decimals}
              unit={timeseriesNumberField.unit}
              readOnly={isComputed || readOnly}
              withProgress
              placeholder={i18n`Add number`}
            />
          )}
        </SimpleInput>
      );
    },
    getInitialState: () => undefined,
    persistStateOnConcept: (dimension, value) => (value ? handler.updateValue(dimension, { type: 'value', time: value[0].time, value: value[0].value ?? null }) : undefined),
  }),
});
