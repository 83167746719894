import type { RefCallback, RefObject } from 'react';
import { useMemo, useRef } from 'react';
import useInViewCallback from './useInViewCallback';
import useOverlayContainerRef from './useOverlayContainerRef';

const isScrollable = (element: HTMLElement) => {
  const hasScrollableContent = element.scrollHeight > element.clientHeight;

  const overflowYStyle = window.getComputedStyle(element).overflowY;
  const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;

  return hasScrollableContent && !isOverflowHidden;
};

const getScrollableParent = (element: HTMLElement): HTMLElement => {
  if (!element || element === document.body) {
    return document.body;
  } else if (isScrollable(element)) {
    return element;
  } else if (element.parentNode === null || element.parentNode.nodeType !== Node.ELEMENT_NODE) {
    return document.body;
  } else {
    return getScrollableParent(element.parentNode as HTMLElement);
  }
};

const useHideOnDisappearRef = <HideElement extends HTMLElement>(
  monitor: boolean,
  checkScrollContainer = true
): { monitorRef: RefCallback<HTMLElement>, hideRef: RefObject<HideElement> } => {
  const hideRef = useRef<HideElement | null>(null);
  const overlayContainerRef = useOverlayContainerRef();

  const lastActiveElementRef = useRef<HTMLElement | null>(null);
  if (!monitor) {
    lastActiveElementRef.current = null;
  }

  const monitorRef = useInViewCallback((inView, node) => {
    if (hideRef.current !== null) {
      if (inView) {
        hideRef.current.style.display = '';
        if (lastActiveElementRef.current !== null) {
          lastActiveElementRef.current.focus({ preventScroll: true });
        }
      } else if (
        overlayContainerRef.current
        && hideRef.current.clientHeight < overlayContainerRef.current.clientHeight // Only hide when the input fit in the visible part of the overlay container
        && (
          !checkScrollContainer || overlayContainerRef.current !== getScrollableParent(node)
        ) // Only hide if monitored element is injected in another scrolled container (an intermediate scroll container)
      ) {
        hideRef.current.style.display = 'none';
        if (document.activeElement instanceof HTMLElement) {
          lastActiveElementRef.current = document.activeElement;
        }
      }
    }
  }, monitor);

  return useMemo(() => ({ hideRef, monitorRef }), [monitorRef]);
};

export default useHideOnDisappearRef;
