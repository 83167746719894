import { createResolvablePromise } from 'yooi-utils';

export const acquireLockImmediately = async (name: string, mode: LockMode = 'exclusive'): Promise<({ unlock: () => void }) | undefined> => {
  const lockReleaseResolvablePromise = createResolvablePromise();
  const hasAcquiredLockPromise = createResolvablePromise();
  navigator.locks.request(
    name,
    { mode, ifAvailable: true },
    async (lock) => {
      const lockAcquired = Boolean(lock);
      hasAcquiredLockPromise.resolve(lockAcquired);
      if (lockAcquired) {
        await lockReleaseResolvablePromise.promise;
      }
    }
  );
  if (await hasAcquiredLockPromise.promise) {
    return {
      unlock: () => lockReleaseResolvablePromise.resolve(undefined),
    };
  } else {
    return undefined;
  }
};
