import {
  ConceptCollaborationFieldDimension,
  ConceptCreatedAtDimension,
  ConceptFunctionalIdDimension,
  ConceptNameDimension,
  ConceptResourceDimension,
  ConceptStakeholdersGroupsDimension,
  ConceptStakeholdersRolesDimension,
  ConceptStakeholdersUsersDimension,
  ConceptUpdatedAtDimension,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  KinshipRelationDimension,
} from '../../conceptModule/ids';
import { Reference } from '../ids';
import { registerModel } from '../module';

const { associate } = registerModel;

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Reference).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptNameDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Reference).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCreatedAtDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Reference).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptUpdatedAtDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Reference).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptResourceDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Reference).withRole(FieldDimensionTypes_Role_FieldDimension, KinshipRelationDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Reference).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptFunctionalIdDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Reference).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptStakeholdersRolesDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Reference).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptStakeholdersUsersDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Reference).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptStakeholdersGroupsDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Reference).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCollaborationFieldDimension),
});
