import type { FunctionComponent } from 'react';
import { ClipLoader } from 'react-spinners';
import { remToPx } from '../../utils/sizeUtils';

export enum SpinnerVariant {
  small = 'small',
  big = 'big',
}

interface SpinnerProps {
  size: SpinnerVariant,
  color: string,
}

const Spinner: FunctionComponent<SpinnerProps> = ({ size, color }) => (
  <ClipLoader size={size === SpinnerVariant.small ? remToPx(1.6) : remToPx(3.5)} color={color} />
);

export default Spinner;
