import classnames from 'classnames';
import { equals } from 'ramda';
import type { CSSProperties, ReactNode } from 'react';
import { forwardRef, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { isNumber, joinObjects } from 'yooi-utils';
import base from '../../theme/base';
import makeSelectorsClasses from '../../utils/makeSelectorsClasses';
import makeStyles from '../../utils/makeStyles';
import useForceUpdate from '../../utils/useForceUpdate';
import { WidgetSizeContextProvider } from '../../utils/useWidgetSizeContext';

export const widgetContainerSelectorsClasses = makeSelectorsClasses('hoveredVisible');

const useStyles = makeStyles((theme) => ({
  base: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '&:hover, &:focus, &:focus-within': {
      [`& .${widgetContainerSelectorsClasses.hoveredVisible}`]: {
        visibility: 'visible',
      },
    },
  },
  shadow: {
    boxShadow: base.shadowElevation.low,
  },
  edition: {
    outlineWidth: '0.2rem',
    outlineStyle: 'dashed',
    outlineColor: theme.color.border.default,
  },
  editionHover: {
    '&:hover, &:focus, &:focus-within': {
      outlineColor: theme.color.border.primary,
    },
  },
  editionActive: {
    outlineColor: theme.color.border.primary,
    [`& .${widgetContainerSelectorsClasses.hoveredVisible}`]: {
      visibility: 'visible',
    },
  },
}), 'widgetContainer');

interface DashboardWidgetContainerProps {
  widgetId: string,
  style?: CSSProperties,
  className?: string,
  borderless: boolean,
  backgroundColor?: string,
  children: ReactNode,
  editionMode: boolean,
  resizingKey?: string | undefined,
}

// we use ...other to transit props from react grid layout (like event handler & className)
// forwardRef is required to be directly injected as children of ReactGridLayout
const WidgetContainer = forwardRef<HTMLDivElement, DashboardWidgetContainerProps>(({
  widgetId,
  children,
  style,
  className,
  borderless,
  backgroundColor,
  editionMode,
  resizingKey,
  ...other
}, ref) => {
  const classes = useStyles();

  const forceUpdate = useForceUpdate();

  const sizeRef = useRef<{ width: number, height: number } | undefined>(undefined);
  const debounceSizeUpdate = useDebouncedCallback((size) => {
    if (!equals(sizeRef.current, size)) {
      sizeRef.current = size;
      forceUpdate();
    }
  }, 25);

  if (typeof style?.width === 'string' && style.width.endsWith('px') && typeof style?.height === 'string' && style.height.endsWith('px')) {
    const width = Number.parseFloat(style.width.slice(0, -2));
    const height = Number.parseFloat(style.height.slice(0, -2));
    if (isNumber(width) && isNumber(height)) {
      const newSize = { width, height };
      if (sizeRef.current === undefined) {
        sizeRef.current = newSize;
      } else {
        debounceSizeUpdate(newSize);
      }
    }
  } else {
    sizeRef.current = undefined;
  }

  return (
    <div
      {...other}
      className={classnames({
        // Specific case as we forward styles from react-grid-layout
        // eslint-disable-next-line yooi/check-classname-attribute
        [className ?? '']: true,
        [classes.base]: true,
        [classes.shadow]: !borderless,
        [classes.edition]: editionMode,
        [classes.editionHover]: editionMode && resizingKey === undefined,
        [classes.editionActive]: editionMode && resizingKey === widgetId,
      })}
      ref={ref}
      // Needed to override react-grid-layout-style
      style={joinObjects(style, { backgroundColor, display: 'flex', flexDirection: 'column' } as const)}
    >
      {
        sizeRef.current !== undefined
          ? (
            <WidgetSizeContextProvider height={sizeRef.current.height} width={sizeRef.current.width}>
              {children}
            </WidgetSizeContextProvider>
          )
          : null
      }
    </div>
  );
});

export default WidgetContainer;
