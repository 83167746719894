import type { AutomationActionEmailStoreObject, AutomationEmailActionRecipient, AutomationRuleStoreObject } from 'yooi-modules/modules/automationModule';
import {
  InstanceEventTriggerWhen,
  isCollaborationEventTrigger,
  isEmailRecipient,
  isFieldUpdateEventTrigger,
  isInstanceEventTrigger,
  isPathRecipient,
  isScheduledTrigger,
  isWorkflowTransitionEventTrigger,
  RepeatType,
  TriggerType,
} from 'yooi-modules/modules/automationModule';
import {
  AutomationAction_Rule,
  AutomationActionEmail,
  AutomationActionEmail_Body,
  AutomationActionEmail_Recipients,
  AutomationActionEmail_Subject,
  AutomationActionGenerateData,
  AutomationRule_Actions,
  AutomationRule_Name,
  AutomationRule_Trigger,
} from 'yooi-modules/modules/automationModule/ids';
import type { FieldStoreObject, MultipleParameterDefinition, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { getPathReturnedConceptDefinitionId, isFieldStep, isRelationalType } from 'yooi-modules/modules/conceptModule';
import { User } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStore, ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import type { DayNumbers } from 'yooi-utils';
import { compareNumber, filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import type { FrontObjectStore } from '../../../store/useStore';
import base from '../../../theme/base';
import i18n from '../../../utils/i18n';
import { getFieldLabel } from '../../_global/fieldUtils';
import { getConceptDefinitionNameOrEntity } from '../../_global/modelTypeUtils';
import type { PathConfigurationHandler } from '../../_global/pathConfigurationHandler';
import { createPathConfigurationHandler, StepValidationState } from '../../_global/pathConfigurationHandler';
import { hourToOption } from './RepeatRuleTimesInput';

export const shouldDisplayVariableAsPlaceholder = (id: string, placeholder: string, defaultVariableName: string, isInherited: boolean = false): boolean => (
  !isInherited && (id.startsWith(defaultVariableName.toLowerCase()) || id === placeholder)
);

export const areRecipientsFulfilled = (recipients: AutomationEmailActionRecipient[] | undefined): boolean => (
  recipients
    ? recipients
      .some((recipient) => (
        isEmailRecipient(recipient) && recipient.value) || (isPathRecipient(recipient) && recipient.value && recipient.value.length > 0
      ))
    : false
);

const isActionFulfilled = (action: StoreObject<string>): boolean => {
  if (isInstanceOf<AutomationActionEmailStoreObject>(action, AutomationActionEmail)) {
    return Boolean(areRecipientsFulfilled(action[AutomationActionEmail_Recipients])
      && action[AutomationActionEmail_Subject]?.length
      && action[AutomationActionEmail_Body]?.length);
  } else {
    return true;
  }
};

export const isRuleFulfilled = (rule: AutomationRuleStoreObject): boolean => {
  const trigger = rule[AutomationRule_Trigger];
  const actions = rule.navigateBack(AutomationRule_Actions);
  const action = actions.at(0);
  if (trigger) {
    if (isInstanceEventTrigger(trigger) && trigger.on && trigger.when && action) {
      if (isFieldUpdateEventTrigger(trigger)) {
        return !!(trigger.updates && trigger.updates.length > 0 && isActionFulfilled(action));
      } else if (isWorkflowTransitionEventTrigger(trigger)) {
        return !!(trigger.workflow && isActionFulfilled(action));
      } else {
        return isActionFulfilled(action);
      }
    } else if (isScheduledTrigger(trigger) && action) {
      return trigger.fromDate ? isActionFulfilled(action) : false;
    } else if (isCollaborationEventTrigger(trigger) && action) {
      return isActionFulfilled(action);
    }
  }
  return false;
};

export const duplicateRule = (store: ObjectStore, id: string, onDuplicate?: (id: string) => void): string => {
  const objectToCopy = store.getObject(id).asRawObject();
  const newRuleId = store.createObject(joinObjects(
    objectToCopy,
    { [AutomationRule_Name]: `${objectToCopy[AutomationRule_Name]} (copy)` }
  ));
  const ruleActions = store.getObject(id).navigateBack(AutomationRule_Actions);
  ruleActions.forEach((action) => {
    store.createObject(joinObjects(action.asRawObject(), { [AutomationAction_Rule]: newRuleId }));
  });
  if (onDuplicate) {
    onDuplicate(newRuleId);
  }
  return newRuleId;
};

export const createAutomationPathConfigurationHandler = (
  store: ObjectStoreWithTimeseries,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
  canBeMultiple: boolean = false
): PathConfigurationHandler => (
  createPathConfigurationHandler(
    store,
    parameterDefinitions,
    [
      ({ pathStep: lastStep, path, isNPath }) => {
        const result = [];
        let fieldTypeErrorMessage;
        if (canBeMultiple) {
          fieldTypeErrorMessage = i18n`Input should end with users or a single user.`;
        } else {
          fieldTypeErrorMessage = i18n`Input should end with a single user.`;
        }
        if (getPathReturnedConceptDefinitionId(store, path) === User) {
          if (canBeMultiple) {
            result.push({ state: StepValidationState.valid });
          } else if (canBeMultiple === isNPath) {
            result.push({ state: StepValidationState.valid });
          } else {
            result.push({ state: StepValidationState.partiallyValid, reasonMessage: fieldTypeErrorMessage });
          }
        } else if (isFieldStep(lastStep)) {
          const fieldDefinitionId = store.getObjectOrNull(lastStep.fieldId)?.[Instance_Of] as string;
          const isRelationField = isRelationalType(fieldDefinitionId);
          if (isRelationField) {
            result.push({ state: StepValidationState.partiallyValid, reasonMessage: fieldTypeErrorMessage });
          } else {
            result.push({ state: StepValidationState.invalid, reasonMessage: fieldTypeErrorMessage });
          }
        } else {
          result.push({ state: StepValidationState.partiallyValid, reasonMessage: fieldTypeErrorMessage });
        }
        return result;
      },
    ]
  )
);

export const getWeekOccurrenceLabel = (key: 1 | 2 | 3 | 4 | 5): string => {
  switch (key) {
    case 1:
      return i18n`First`;
    case 2:
      return i18n`Second`;
    case 3:
      return i18n`Third`;
    case 4:
      return i18n`Fourth`;
    case 5:
      return i18n`Last`;
  }
};

export const getWeekDayLabel = (key: 1 | 2 | 3 | 4 | 5 | 6 | 7): string => {
  switch (key) {
    case 1:
      return i18n`Monday`;
    case 2:
      return i18n`Tuesday`;
    case 3:
      return i18n`Wednesday`;
    case 4:
      return i18n`Thursday`;
    case 5:
      return i18n`Friday`;
    case 6:
      return i18n`Saturday`;
    case 7:
      return i18n`Sunday`;
  }
};

export const getMonthDayLabel = (key: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12): string => {
  switch (key) {
    case 1:
      return i18n`January`;
    case 2:
      return i18n`February`;
    case 3:
      return i18n`March`;
    case 4:
      return i18n`April`;
    case 5:
      return i18n`May`;
    case 6:
      return i18n`June`;
    case 7:
      return i18n`July`;
    case 8:
      return i18n`August`;
    case 9:
      return i18n`September`;
    case 10:
      return i18n`October`;
    case 11:
      return i18n`November`;
    case 12:
      return i18n`December`;
  }
};

export const getDaysOfMonth = (key: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12): DayNumbers[] => {
  switch (key) {
    case 1:
      return Array.from({ length: 31 }).map((_, i) => i + 1) as DayNumbers[];
    case 2:
      return Array.from({ length: 29 }).map((_, i) => i + 1) as DayNumbers[];
    case 3:
      return Array.from({ length: 31 }).map((_, i) => i + 1) as DayNumbers[];
    case 4:
      return Array.from({ length: 30 }).map((_, i) => i + 1) as DayNumbers[];
    case 5:
      return Array.from({ length: 31 }).map((_, i) => i + 1) as DayNumbers[];
    case 6:
      return Array.from({ length: 30 }).map((_, i) => i + 1) as DayNumbers[];
    case 7:
      return Array.from({ length: 31 }).map((_, i) => i + 1) as DayNumbers[];
    case 8:
      return Array.from({ length: 31 }).map((_, i) => i + 1) as DayNumbers[];
    case 9:
      return Array.from({ length: 30 }).map((_, i) => i + 1) as DayNumbers[];
    case 10:
      return Array.from({ length: 31 }).map((_, i) => i + 1) as DayNumbers[];
    case 11:
      return Array.from({ length: 30 }).map((_, i) => i + 1) as DayNumbers[];
    case 12:
      return Array.from({ length: 31 }).map((_, i) => i + 1) as DayNumbers[];
  }
};

export const getRuleTriggerDescription = (store: FrontObjectStore, id: string): string => {
  const rule = store.getObject<AutomationRuleStoreObject>(id);
  const trigger = rule[AutomationRule_Trigger];
  if (isInstanceEventTrigger(trigger) && trigger.on && store.getObjectOrNull(trigger.on)) {
    const conceptName = getConceptDefinitionNameOrEntity(store, trigger.on);
    if (trigger.when === InstanceEventTriggerWhen.create) {
      return i18n`When a ${conceptName} is created`;
    } else if (trigger.when === InstanceEventTriggerWhen.delete) {
      return i18n`When a ${conceptName} is deleted`;
    } else if (trigger.when === InstanceEventTriggerWhen.anyUpdate) {
      return i18n`When any ${conceptName} field is updated`;
    } else if (isFieldUpdateEventTrigger(trigger)) {
      const fields = (trigger.updates ?? [])
        .map((fieldId) => store.getObjectOrNull<FieldStoreObject>(fieldId))
        .filter(filterNullOrUndefined);
      if (fields.length === 1) {
        return i18n`When ${conceptName} > ${getFieldLabel(store, fields[0])} is updated`;
      } else if (fields.length > 1) {
        return i18n`When ${conceptName} > ${fields.map((field) => getFieldLabel(store, field)).join(', ')} are updated`;
      }
      return '-';
    } else if (isWorkflowTransitionEventTrigger(trigger)) {
      return i18n`When a workflow transition is executed`;
    }
  } else if (isScheduledTrigger(trigger)) {
    const { at: { hour, tz } } = trigger;
    if (trigger.repeatRule.type === RepeatType.day) {
      const { times } = trigger.repeatRule;
      return i18n`Every${times === 1 ? '' : ` ${times}`} day(s) at ${hourToOption(hour).label} (${tz})`;
    } else if (trigger.repeatRule.type === RepeatType.week) {
      const { weekDays, times } = trigger.repeatRule;
      if (weekDays.length > 0) {
        return i18n`Every${times === 1 ? '' : ` ${times}`} week on ${weekDays.sort(compareNumber).map((wD) => getWeekDayLabel(wD))
          .join(', ')} at ${hourToOption(hour).label} (${tz})`;
      } else {
        return i18n`Never`;
      }
    } else if (trigger.repeatRule.type === RepeatType.month) {
      if (trigger.repeatRule.selectedRule === 'dayRule') {
        const { dayRule: { day }, times } = trigger.repeatRule;
        return i18n`Every${times === 1 ? '' : ` ${times}`} month on day ${day} at ${hourToOption(hour).label} (${tz})`;
      } else {
        const { weekDayRule: { week, weekDay }, times } = trigger.repeatRule;
        return i18n`Every${times === 1 ? '' : ` ${times}`} month on ${getWeekOccurrenceLabel(week)} ${getWeekDayLabel(weekDay)} at ${hourToOption(hour).label} (${tz})`;
      }
    } else if (trigger.repeatRule.type === RepeatType.year) {
      if (trigger.repeatRule.selectedRule === 'dayRule') {
        const { dayRule: { day }, month } = trigger.repeatRule;
        return i18n`Every ${getMonthDayLabel(month)} ${day} at ${hourToOption(hour).label} (${tz})`;
      } else {
        const { weekDayRule: { week, weekDay }, month } = trigger.repeatRule;
        return i18n`Every ${getWeekOccurrenceLabel(week)} ${getWeekDayLabel(weekDay)} of ${getMonthDayLabel(month)} at ${hourToOption(hour).label} (${tz})`;
      }
    }
  } else if (isCollaborationEventTrigger(trigger)) {
    return i18n`When a collaboration message is posted`;
  }
  return '-';
};

export const triggerTypesOptions: { [key in TriggerType]: { id: key, label: string, icon: IconName, color: string } } = {
  [TriggerType.event]: { id: TriggerType.event, label: i18n`Listen to event(s)`, icon: IconName.hearing, color: base.color.gray['300'] },
  [TriggerType.collaboration]: { id: TriggerType.collaboration, label: i18n`Listen to collaboration(s)`, icon: IconName.hearing, color: base.color.gray['300'] },
  [TriggerType.scheduled]: { id: TriggerType.scheduled, label: i18n`Run at a scheduled time`, icon: IconName.schedule, color: base.color.gray['300'] },
};

export const actionTypesOptions: { [key in string]: { id: key, label: string, icon: IconName, color: string } } = {
  [AutomationActionEmail]: { id: AutomationActionEmail, label: i18n`Send an email`, icon: IconName.mail, color: base.color.gray['300'] },
  [AutomationActionGenerateData]: { id: AutomationActionGenerateData, label: i18n`Generate data`, icon: IconName.edit, color: base.color.gray['300'] },
};
