type HasGenericKey<T> = string extends keyof T ? true : false;

type OptionalPropertyNames<T> = { [K in keyof T]-?: (object extends { [P in K]: T[K] } ? K : never) }[keyof T];

type SpreadProperties<L, R, K extends keyof L & keyof R> = { [P in K]: L[P] | Exclude<R[P], undefined> };

type Id<T> = T extends infer U ? { [K in keyof U]: U[K] } : never;

type SpreadTwo<L, R> = HasGenericKey<L> extends false
  ? HasGenericKey<R> extends false
    ? Id<
      & Pick<L, Exclude<keyof L, keyof R>>
      & Pick<R, Exclude<keyof R, OptionalPropertyNames<R>>>
      & Pick<R, Exclude<OptionalPropertyNames<R>, keyof L>>
      & SpreadProperties<L, R, OptionalPropertyNames<R> & keyof L>
    >
    : Record<keyof L | keyof R, L[keyof L] | R[keyof R]>
  : Record<keyof L | keyof R, L[keyof L] | R[keyof R]>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Spread<A extends readonly [...any]> = A extends [infer L, ...infer R] ? SpreadTwo<L, Spread<R>> : unknown;

export const joinObjects = <A extends (object | undefined | null)[]>(...a: [...A]): Spread<A> => (Object.assign({}, ...a) as Spread<A>);
