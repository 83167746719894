import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';
import { useEffect, useRef } from 'react';
import base from '../../theme/base';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import useFocusOnMount from '../../utils/useFocusOnMount';
import useInViewCallback from '../../utils/useInViewCallback';
import useUsageContext, { UsageVariant } from '../../utils/useUsageContext';
import type { IconName } from '../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../atoms/IconOnlyButton';
import Tooltip from '../atoms/Tooltip';
import Overlay from '../molecules/Overlay';

const useStyles = makeStyles((theme) => ({
  dropdownContainer: {
    position: 'relative',
    height: '100%',
    background: theme.color.background.neutral.default,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.s,
    boxShadow: base.shadowElevation.medium,
    paddingBottom: spacingRem.s,
    paddingTop: spacingRem.s,
  },
  dropdownContainerBorder: {
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.default,
    borderRadius: base.borderRadius.medium,
  },
  dropdownContainerOutline: {
    outlineWidth: '0.1rem',
    outlineStyle: 'solid',
    outlineColor: theme.color.border.default,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
  },
  overlayContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  arrow: {
    display: 'block',
    width: 0,
    height: 0,
    borderLeftWidth: '0.8rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
    borderRightWidth: '0.8rem',
    borderRightStyle: 'solid',
    borderRightColor: theme.color.transparent,
    borderBottomWidth: '0.8rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
    marginLeft: '0.5rem', // Matching icon only button width
  },
}), 'textInputAction');

interface TextInputActionProps {
  icon: IconName,
  tooltip: string,
  showDropdown: boolean,
  openDropdown: () => void,
  closeDropdown: () => void,
  renderContent: () => ReactElement | null,
}

const TextInputAction: FunctionComponent<TextInputActionProps> = ({
  icon,
  tooltip,
  showDropdown,
  openDropdown,
  closeDropdown,
  renderContent,
}) => {
  const classes = useStyles();
  const usageVariant = useUsageContext();

  const editableContainerRef = useRef<HTMLDivElement>(null);
  const dropdownContainerRef = useRef<HTMLDivElement>(null);
  useFocusOnMount(dropdownContainerRef, showDropdown);

  const observeRef = useInViewCallback((inView) => {
    if (!inView) {
      closeDropdown();
    }
  }, showDropdown);

  // ensure that focus is set back when dropdown is closed
  const latestShowDropdown = useRef(showDropdown);
  useEffect(() => {
    if (!showDropdown && latestShowDropdown.current) {
      editableContainerRef.current?.focus({ preventScroll: true });
    }
    latestShowDropdown.current = showDropdown;
  }, [showDropdown]);

  const isFormVariant = usageVariant === UsageVariant.inForm;
  const isMainVariant = usageVariant === UsageVariant.inline || usageVariant === UsageVariant.inCard;
  const isTableVariant = usageVariant === UsageVariant.inTable;
  const isBorderVariant = isFormVariant || isMainVariant;
  const isOutlineVariant = isTableVariant;

  const iconOnlyButton = (
    <div ref={composeReactRefs<HTMLDivElement>(editableContainerRef, observeRef)}>
      <IconOnlyButton
        onClick={() => {
          if (!showDropdown) {
            openDropdown();
          } else {
            closeDropdown();
          }
        }}
        iconName={icon}
        variant={IconOnlyButtonVariants.tertiary}
        tooltip={tooltip}
      />
    </div>
  );

  return (
    <>
      {tooltip !== undefined ? (<Tooltip title={tooltip} asFlexbox>{iconOnlyButton}</Tooltip>) : iconOnlyButton}
      {showDropdown ? (
        <Overlay
          placement="bottom-start"
          offset={[remToPx(0), remToPx(0.1)]}
          target={editableContainerRef}
          onBackdropClick={closeDropdown}
          onEscapeKeyDown={closeDropdown}
        >
          <div className={classes.overlayContainer}>
            <span className={classes.arrow} />
            <div
              ref={dropdownContainerRef}
              className={classnames({
                [classes.dropdownContainer]: true,
                [classes.dropdownContainerBorder]: isBorderVariant,
                [classes.dropdownContainerOutline]: isOutlineVariant,
              })}
              tabIndex={0}
              role="button"
            >
              <span className={classes.contentContainer}>
                {renderContent()}
              </span>
            </div>
          </div>
        </Overlay>
      ) : null}
    </>
  );
};

export default TextInputAction;
