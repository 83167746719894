import type { ReactElement, RefObject } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { IconName } from '../../components/atoms/Icon';
import ToggleButton from '../../components/molecules/ToggleButton';
import useStore from '../../store/useStore';
import { spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import useForceUpdate from '../../utils/useForceUpdate';
import { countValidFilters } from './filter/filterUtils';
import SearchTextButton from './filter/SearchTextButton';
import { FilterParams, useFilterStorage, useOnFilterChanged } from './filter/useFilterSessionStorage';

const useStyles = makeStyles({
  headerButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: spacingRem.s,
  },
}, 'conceptViewTopBar');

export enum DisplayedLine {
  'filters' = 'filters',
  'options' = 'options',
}

interface ConceptViewTopBarProps {
  filterKey: string,
  onFiltersClick: () => void,
  onOptionsClick: () => void,
  activeFilters?: boolean,
  activeOptions?: boolean,
  hideOptions?: boolean,
  hideFilters?: boolean,
  hideSearch?: boolean,
  renderExportButton?: () => ReactElement | null,
}

const ConceptViewTopBar = forwardRef<{ options: RefObject<HTMLDivElement>, filters: RefObject<HTMLDivElement> }, ConceptViewTopBarProps>(({
  filterKey,
  onFiltersClick,
  onOptionsClick,
  activeFilters,
  activeOptions,
  hideOptions = false,
  hideFilters = false,
  hideSearch = false,
  renderExportButton,
}, ref) => {
  const classes = useStyles();

  const store = useStore();
  const forceUpdate = useForceUpdate();
  useOnFilterChanged(filterKey, forceUpdate);

  const optionsButtonRef = useRef<HTMLDivElement>(null);
  const filtersButtonRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    options: optionsButtonRef,
    filters: filtersButtonRef,
  }));

  const [filterSessionStorageState = {}] = useFilterStorage(filterKey, FilterParams.filters);
  const filtersCount = Object.values(filterSessionStorageState).reduce((acc, filters) => acc + countValidFilters(store, filters), 0);

  return (
    <div className={classes.headerButtonContainer}>
      {!hideSearch && (
        <SearchTextButton element={filterKey} placeholder={i18n`Search`} />
      )}
      {!hideFilters && (
        <ToggleButton
          ref={filtersButtonRef}
          active={activeFilters}
          tooltip={i18n`Filters`}
          onClick={() => onFiltersClick()}
          icon={IconName.filter_alt}
          count={filtersCount > 0 ? filtersCount : undefined}
        />
      )}
      {renderExportButton ? renderExportButton() : null}
      {!hideOptions && (
        <ToggleButton
          ref={optionsButtonRef}
          active={activeOptions}
          tooltip={i18n`Configuration`}
          onClick={() => onOptionsClick()}
          icon={IconName.tune}
        />
      )}
    </div>
  );
});

export default ConceptViewTopBar;
