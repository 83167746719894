import type {
  AccessControlListLibrary,
  BusinessRulesLibrary,
  EventValidatorResult,
  GarbageCollectorRulesLibrary,
  OperationValidationTransaction,
  PendingOperation,
  RawObjectStore,
} from 'yooi-store';
import { createEventValidator, ExecutionContextClients, OriginSources, ProcessedEventStatus } from 'yooi-store';

export enum BusinessRulesStatus {
  validated = ProcessedEventStatus.validated,
  rejected = ProcessedEventStatus.rejected,
}

export interface BusinessRulesHandler {
  validateAndApplyLocalEvent: (event: PendingOperation[]) => {
    status: BusinessRulesStatus,
    rollbackEvent?: EventValidatorResult['rollbackEvent'],
    audit: EventValidatorResult['audit'],
  },
  createOperationValidationTransaction: () => OperationValidationTransaction,
  applyRemoteEvent: (id: string | string[], properties: Record<string, unknown> | null) => void,
  initBusinessRulesHandler: (userId: string) => void,
  initGarbageCollectorHandler: () => void,
}

const createBusinessRulesHandler = (
  objectStore: RawObjectStore,
  accessControlListLibrary: AccessControlListLibrary,
  businessRulesLibrary: BusinessRulesLibrary,
  garbageCollectorRulesLibrary: GarbageCollectorRulesLibrary
): BusinessRulesHandler => {
  let userId: string;
  const { validateEvent, createOperationValidationTransaction } = createEventValidator(objectStore, businessRulesLibrary, garbageCollectorRulesLibrary, accessControlListLibrary);

  return {
    validateAndApplyLocalEvent: (event) => {
      const { status, audit, rollbackEvent } = validateEvent(event, { source: OriginSources.CLIENT, userId }, { client: ExecutionContextClients.FRONT, date: new Date() });
      if (status === ProcessedEventStatus.validated) {
        return { status: BusinessRulesStatus.validated, audit, rollbackEvent };
      } else {
        return { status: BusinessRulesStatus.rejected, audit };
      }
    },
    createOperationValidationTransaction: () => (
      createOperationValidationTransaction({ source: OriginSources.CLIENT, userId }, { client: ExecutionContextClients.FRONT, date: new Date() })
    ),
    applyRemoteEvent: (id, properties) => {
      businessRulesLibrary.onObjectUpdate(id, properties);
      objectStore.updateObject(id, properties);
    },
    initBusinessRulesHandler: (uid) => {
      businessRulesLibrary.init();
      userId = uid;
    },
    initGarbageCollectorHandler: () => {
      garbageCollectorRulesLibrary.init();
    },
  };
};

export default createBusinessRulesHandler;
