import type { FunctionComponent } from 'react';
import { getParentConceptInstance, isConceptValid } from 'yooi-modules/modules/conceptModule';
import { Concept_Name, Concept_Resource } from 'yooi-modules/modules/conceptModule/ids';
import { getResourcesToDataAssetsEmbeddingFieldInstanceId } from 'yooi-modules/modules/resourceModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreReadOnly, StoreObject } from 'yooi-store';
import type { RichText } from 'yooi-utils';
import { compareString, comparing, extractAndCompareValue, richTextToText } from 'yooi-utils';
import Card from '../../../../components/molecules/Card';
import BlockContent from '../../../../components/templates/BlockContent';
import CardList from '../../../../components/templates/CardList';
import VerticalBlock from '../../../../components/templates/VerticalBlock';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';
import { buildInstancesTree } from '../../conceptUtils';
import { getConceptDefinitionNameOrEntity } from '../../modelTypeUtils';
import DetailHierarchyTreeComposite from './DetailHierarchyTreeComposite';
import ListHierarchyTreeComposite from './ListHierarchyTreeComposite';

const getInstanceTree = (store: ObjectStoreReadOnly, dataAssetId: string) => {
  const resourcesToDataAssetsEmbeddingFieldInstanceId = getResourcesToDataAssetsEmbeddingFieldInstanceId(store);
  const nodes = store.getObject(dataAssetId)
    .navigateBack(resourcesToDataAssetsEmbeddingFieldInstanceId).flatMap((resourceInstance) => resourceInstance.navigateBack(Concept_Resource))
    .filter((reference) => isConceptValid(store, reference.id));
  return buildInstancesTree(
    nodes,
    (node: StoreObject) => getParentConceptInstance(node) ?? undefined,
    comparing(extractAndCompareValue((nodeId: string) => getConceptDefinitionNameOrEntity(store, store.getObject(nodeId)[Instance_Of] as string), compareString))
      .thenComparing(extractAndCompareValue(
        (nodeId) => (store.getObject(nodeId)[Concept_Name] ? richTextToText(store.getObject(nodeId)[Concept_Name] as RichText) : undefined),
        compareString
      ))
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    marginLeft: spacingRem.s,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
  },
}), 'dataAssetHierarchicalUsageField');

interface DataAssetHierarchicalUsageFieldProps {
  dataAssetId: string,
  focusOnMount?: boolean,
}

const DataAssetHierarchicalUsageField: FunctionComponent<DataAssetHierarchicalUsageFieldProps> = ({ dataAssetId, focusOnMount = false }) => {
  const store = useStore();
  const classes = useStyles();
  const instanceTree = getInstanceTree(store, dataAssetId);
  const usageVariant = useUsageContext();
  if (usageVariant === UsageVariant.inTable) {
    return <ListHierarchyTreeComposite nodes={instanceTree} focusOnMount={focusOnMount} />;
  } else if (usageVariant === UsageVariant.inCard) {
    return (
      <div className={classes.container}>
        <CardList
          list={instanceTree}
          renderCard={(node) => (
            <Card>
              <DetailHierarchyTreeComposite node={node} focusOnMount={focusOnMount} />
            </Card>
          )}
        />
      </div>
    );
  } else {
    return (
      <VerticalBlock>
        <BlockContent padded>
          <CardList
            list={instanceTree}
            renderCard={(node) => (
              <Card>
                <DetailHierarchyTreeComposite node={node} focusOnMount={focusOnMount} />
              </Card>
            )}
          />
        </BlockContent>
      </VerticalBlock>
    );
  }
};

export default DataAssetHierarchicalUsageField;
