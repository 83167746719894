import type { StrictUnion } from 'yooi-utils';
import { withRetry, fromError, joinObjects, sleep } from 'yooi-utils';

const withTimeoutSignal = async <T>(runnable: (timeoutSignal: AbortSignal) => Promise<T>, timeout: number): Promise<T> => {
  const timeoutAbortController = new AbortController();
  const start = Date.now();
  const timeoutId = setTimeout(() => timeoutAbortController.abort(`Aborted on timeout ${timeout}, start: ${start}, end: ${Date.now()}`), timeout);
  try {
    return await runnable(timeoutAbortController.signal);
  } finally {
    clearTimeout(timeoutId);
  }
};

interface DoFetchIterationMetrics {
  iteration: number,
  iterationStart: number,
  iterationEnd: number,
  error: unknown,
  response?: { headers: Headers, ok: boolean, status: number, statusText: string, type: ResponseType, url: string, redirected: boolean, body: boolean, bodyUsed: boolean },
}

const getResponseData = ({ headers, ok, status, statusText, type, url: responseUrl, redirected, body, bodyUsed }: Response) => (
  { headers, ok, status, statusText, type, url: responseUrl, redirected, body: !!body, bodyUsed }
);

export type DoFetchInit = StrictUnion<
  RequestInit | (Omit<RequestInit, 'headers' | 'body'> & { json: object | undefined })
> & { maxIterations?: number, retryTimeout?: number, timeout?: number };

export const doFetch = async (url: string, init?: DoFetchInit): Promise<{
  response: Response,
  iterationMetrics: DoFetchIterationMetrics[],
}> => {
  const timeout = init?.timeout ?? 10_000;
  const maxIterations = init?.maxIterations ?? 3;
  const retryTimeout = init?.retryTimeout ?? 3_000;
  const statusCodeToRetry = [502, 503, 504];

  const iterationMetrics: DoFetchIterationMetrics[] = [];
  const fetchFunction = async (): Promise<Response> => withTimeoutSignal(async (timeoutSignal) => {
    const abortController = new AbortController();
    const onExternalSignalAbort = () => abortController.abort(init?.signal?.reason);
    const onTimeoutSignalAbort = () => abortController.abort(timeoutSignal.reason);
    try {
      init?.signal?.addEventListener('abort', onExternalSignalAbort);
      timeoutSignal.addEventListener('abort', onTimeoutSignalAbort);
      if (init?.json !== undefined) {
        return (await fetch(url, joinObjects(init, { headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(init.json), signal: abortController.signal })));
      } else {
        return (await fetch(url, joinObjects(init, { signal: abortController.signal })));
      }
    } finally {
      init?.signal?.removeEventListener('abort', onExternalSignalAbort);
      timeoutSignal.removeEventListener('abort', onTimeoutSignalAbort);
    }
  }, timeout);

  try {
    const response = await withRetry(
      fetchFunction,
      async ({ result, error, iteration, iterationStart, iterationEnd }) => {
        iterationMetrics.push({ response: result ? getResponseData(result) : undefined, error, iteration, iterationStart, iterationEnd });
        if (iteration >= maxIterations) {
          return false;
        } else if ((result && statusCodeToRetry.includes(result.status)) || error) {
          await sleep(retryTimeout);
          return true;
        } else {
          return false;
        }
      }
    );
    return { response, iterationMetrics };
  } catch (error) {
    throw fromError(error, 'Fetch error', { url, method: init?.method, iterationMetrics });
  }
};

export interface FetchJSONResult {
  status: number,
  response: unknown,
}

export const fetchJSON = async <T extends FetchJSONResult = FetchJSONResult>(url: string, init?: DoFetchInit): Promise<T & { iterationMetrics: DoFetchIterationMetrics[] }> => {
  try {
    const { response, iterationMetrics } = await doFetch(url, init);

    try {
      const responseText = await response.text();
      try {
        return { status: response.status, response: JSON.parse(responseText), iterationMetrics } as T & { iterationMetrics: DoFetchIterationMetrics[] };
      } catch (e) {
        throw fromError(e, 'Error while parsing JSON response', { code: response.status, headers: response.headers, responseText });
      }
    } catch (e) {
      throw fromError(e, 'Error when reading response body', { code: response.status, headers: response.headers });
    }
  } catch (e) {
    throw fromError(e, 'Fetch JSON error', { url, method: init?.method });
  }
};
