import { createModule } from '../common/types/TypeModuleDsl';
import * as PlatformModuleIds from './ids';
import { PlatformModuleId } from './ids';
import migrations from './migrations';

const { initTypedModule, ...registerModelDsl } = createModule({ label: 'Platform' }, PlatformModuleIds);
export const registerModel = registerModelDsl;

export const initModule = initTypedModule(() => ({
  id: PlatformModuleId,
  migrations,
}));
