import type { CSSProperties, FunctionComponent, ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { joinObjects } from 'yooi-utils';
import makeStyles from '../../../../../utils/makeStyles';
import useTheme from '../../../../../utils/useTheme';
import { ArcherContainerContextProvider } from './ArcherContainerContext';
import { getPointFromElement } from './geometry/rectHelper';
import SvgArrows from './SvgArrows';
import type { SourceToTargetType, ValidSourceToTargetType } from './types';
import { ArrowSense } from './types';

interface ArcherContainerProps {
  /** A string representing an array of sizes */
  style?: CSSProperties,
  /** Set this to true if you want angles instead of curves */
  children?: ReactNode,
}

const useStyles = makeStyles({
  svgContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    pointerEvents: 'none',
  },
  container: {
    position: 'relative',
  },
}, 'archerContainer');

const ArcherContainer: FunctionComponent<ArcherContainerProps> = ({ children, style }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [refs, setRefs] = useState<Record<string, HTMLElement>>({});
  const [sourceToTargetsMap, setSourceToTargetsMap] = useState<Record<string, ValidSourceToTargetType[]>>({});

  const parent = useRef<HTMLDivElement>(null);

  const registerTransitions = useCallback(
    (elementId: string, newSourceToTargets: SourceToTargetType[]): void => {
      setSourceToTargetsMap((previousValue) => (joinObjects(
        previousValue,
        {
          [elementId]: newSourceToTargets.map((sourceToTarget): ValidSourceToTargetType => ({
          source: sourceToTarget.source,
          target: sourceToTarget.target,
          order: sourceToTarget.order,
          label: sourceToTarget.label,
          lineType: {
            sense: sourceToTarget.lineType.sense ?? ArrowSense.targetToSource,
            strokeColor: sourceToTarget.lineType.strokeColor ?? theme.color.border.default,
            strokeWidth: sourceToTarget.lineType.strokeWidth ?? 2,
            lineStyle: sourceToTarget.lineType.lineStyle || 'curve',
            marker: sourceToTarget.lineType.marker,
            layer: sourceToTarget.lineType.layer ?? 0,
            arrowClickHandler: sourceToTarget.lineType.arrowClickHandler,
            showArrowLabel: Boolean(sourceToTarget.lineType.withArrowLabel),
          },
        })),
        }
      )));
    },
    [theme]
  );

  const unregisterTransitions = useCallback((elementId: string): void => {
    setSourceToTargetsMap((previousValue) => {
      const sourceToTargetsMapCopy = { ...previousValue };
      delete sourceToTargetsMapCopy[elementId];
      return sourceToTargetsMapCopy;
    });
  }, []);

  const registerChild = useCallback((id: string, ref: HTMLElement): void => {
    setRefs((currentRefs) => {
      if (currentRefs[id] === ref) {
        return currentRefs;
      }
      return joinObjects(currentRefs, { [id]: ref });
    });
  }, []);

  const unregisterChild = useCallback((id: string): void => {
    setRefs((currentRefs) => {
      const newRefs = { ...currentRefs };
      delete newRefs[id];
      return newRefs;
    });
  }, []);

  const contextValue = useMemo(
    () => ({ registerTransitions, unregisterTransitions, registerChild, unregisterChild }),
    [registerTransitions, unregisterTransitions, registerChild, unregisterChild]
  );

  const [, redrawArrows] = useState(true);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      redrawArrows((old) => !old);
    });
    Object.values(refs).map((ref) => resizeObserver.observe(ref));
    return () => {
      Object.values(refs).map((ref) => resizeObserver.unobserve(ref));
    };
  }, [refs]);

  const parentCoordinates = getPointFromElement(parent.current);

  return (
    <ArcherContainerContextProvider value={contextValue}>
      <div style={style} className={classes.container}>
        <svg className={classes.svgContainer}>
          {parentCoordinates ? (
            <SvgArrows
              parentCoordinates={parentCoordinates}
              refs={refs}
              sourceToTargetsMap={sourceToTargetsMap}
            />
          ) : null}
        </svg>
        <div ref={parent}>
          {children}
        </div>
      </div>
    </ArcherContainerContextProvider>
  );
};

export default ArcherContainer;
