import type { FunctionComponent, ReactElement } from 'react';
import type { SingleParameterDefinition, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { GaugeViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import type { ChartTooltipData } from '../../../../components/charts/ChartTypes';
import GaugeChart from '../../../../components/charts/GaugeChart/GaugeChart';
import ScrollableWidgetContainer, {
  SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_HEIGHT_PADDING,
  SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_WIDTH_PADDING,
} from '../../../../components/templates/ScrollableWidgetContainer';
import WidgetLoadingPlaceholder from '../../../../components/templates/WidgetLoadingPlaceholder';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { remToPx } from '../../../../utils/sizeUtils';
import useDeepMemo from '../../../../utils/useDeepMemo';
import WidgetInvalidConfiguration from '../../fields/_global/WidgetInvalidConfiguration';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { useViewFilters } from '../../filter/useViewFilters';
import ViewsTooltip from '../common/ViewsTooltip';
import { getViewDefinitionHandler } from '../viewDsl';
import type { GaugeViewDefinitionHandler } from './gaugeViewDefinitionHandler';
import type { GaugeChartSeriesWithIdResolved } from './gaugeViewResolution';

interface GaugeViewWidgetProps {
  widgetId: string,
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinition: GaugeViewStoredDefinition,
  parametersMapping: ParametersMapping,
  parameterDefinitions: SingleParameterDefinition[],
  width: number,
  height: number,
}

const renderGaugeTooltip = ({ seriesFieldId, seriesLabel, dimensionsMapping, datum, totalValue }: ChartTooltipData<{ value: number }>): ReactElement => (
  <ViewsTooltip
    fieldId={seriesFieldId}
    seriesLabel={seriesLabel}
    dimensionsMapping={dimensionsMapping}
    value={datum.value}
    totalValue={totalValue}
  />
);

const GaugeViewWidget: FunctionComponent<GaugeViewWidgetProps> = ({
  viewDimensions,
  viewFilters,
  viewDefinition,
  parametersMapping,
  width,
  height,
}) => {
  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();

  const filterConfiguration = useViewFilters(viewFilters, viewDimensions);

  let content: ReactElement;
  const viewHandler = getViewDefinitionHandler(viewDefinition) as GaugeViewDefinitionHandler;

  const gaugeChartResolution = useDeepMemo(
    () => viewHandler.resolveView(store, { viewDimensions, parametersMapping, userId: loggedUserId, filterConfiguration, aclHandler }),
    [store.getSerial(), viewDimensions, viewDefinition, parametersMapping, filterConfiguration]
  );

  if (gaugeChartResolution === undefined) {
    content = (<WidgetLoadingPlaceholder />);
  } else if (gaugeChartResolution.error !== undefined) {
    content = (<WidgetInvalidConfiguration width={width} height={height} reason={gaugeChartResolution.error} />);
  } else {
    const chartHeight = Math.max(0, height - remToPx(SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_HEIGHT_PADDING));
    content = (
      <ScrollableWidgetContainer asContent width={width} height={height}>
        <GaugeChart<GaugeChartSeriesWithIdResolved>
          minValue={gaugeChartResolution.minValue}
          maxValue={gaugeChartResolution.maxValue}
          steps={gaugeChartResolution.steps}
          labels={gaugeChartResolution.labels}
          series={gaugeChartResolution.series ?? []}
          height={chartHeight}
          width={Math.max(0, width - remToPx(SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_WIDTH_PADDING))}
          renderTooltip={renderGaugeTooltip}
        />
      </ScrollableWidgetContainer>
    );
  }
  return content;
};

export default GaugeViewWidget;
