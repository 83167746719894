import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { parseFormula } from 'yooi-modules/modules/conceptModule';
import { anyType, errorToObject, numberType, textType } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import Typo, { TypoVariant } from '../../../components/atoms/Typo';
import SimpleInput from '../../../components/inputs/strategy/SimpleInput';
import TextInputString from '../../../components/inputs/TextInputString';
import SpacingLine from '../../../components/molecules/SpacingLine';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import DataTable from '../../../components/templates/DataTable';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import i18n from '../../../utils/i18n';
import StoreTextInputField from '../../_global/input/StoreTextInputField';

const FormulaPlaygroundBlock: FunctionComponent = () => {
  const [playgroundFormula, setPlaygroundFormula] = useState<string | undefined>('MAX(5,9,3,2)');
  const [inputs, setInputs] = useState<{ key: string, value: string }[]>([]);

  let playgroundResult: string | undefined;
  let playgroundError: string | undefined;
  try {
    const preparedFormula = parseFormula(playgroundFormula ?? '', {
      getInput: (name) => {
        const caseInsensitiveName = name.toLowerCase();
        const input = inputs.find((lookup) => lookup.key.toLowerCase() === caseInsensitiveName);
        if (input === undefined) {
          return undefined;
        } else {
          const value = input.value === undefined ? undefined : (JSON.parse(input.value) ?? undefined);
          let type = anyType;
          if (typeof value === 'number') {
            type = numberType;
          } else if (typeof value === 'string') {
            type = textType;
          }

          return { kind: 'constant', type, name, value };
        }
      },
    });

    const executeFormula = preparedFormula.createCompute();
    const value = executeFormula(() => undefined);
    if (typeof value === 'number') {
      playgroundResult = `${+value.toPrecision(15)}`;
    } else {
      playgroundResult = `${value}`;
    }
  } catch (e) {
    if (e instanceof Error) {
      playgroundError = JSON.stringify(errorToObject(e), undefined, 2);
    } else {
      playgroundError = JSON.stringify(e);
    }
  }

  return (
    <VerticalBlock asBlockContent withSeparation>
      <BlockTitle title={i18n`Playground`} />
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Formula`} />
        <BlockContent>
          <SimpleInput
            initialValue={playgroundFormula}
            onSubmit={setPlaygroundFormula}
          >
            {(props) => (<TextInputString {...props} />)}
          </SimpleInput>
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Inputs`} />
        <DataTable
          columnsDefinition={[
            {
              propertyId: 'name',
              name: i18n`Name`,
              width: '15rem',
              cellRender: ({ key }, _, index) => (
                <StoreTextInputField
                  initialValue={key}
                  onSubmit={(newName) => setInputs((current) => {
                    const cloned = [...current];
                    cloned[index] = { key: newName ?? '', value: current[index].value };
                    return cloned;
                  })}
                />
              ),
            }, {
              propertyId: 'value',
              name: i18n`Value`,
              width: '45rem',
              cellRender: ({ value }, _, index) => (
                <StoreTextInputField
                  initialValue={value}
                  onSubmit={(newValue) => setInputs((current) => {
                    const cloned = [...current];
                    cloned[index] = { key: current[index].key, value: newValue ?? '' };
                    return cloned;
                  })}
                />
              ),
            },
          ]}
          list={inputs.map((item) => ({ key: item.key, type: 'item', item, color: undefined }))}
          newItemIcon={IconName.add}
          onNewItem={() => setInputs((current) => [...current, { key: '', value: '' }])}
          newItemTitle={i18n`Add`}
        />
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Result`} />
        <BlockContent>
          {
            playgroundError !== undefined
              ? (<SpacingLine><Typo variant={TypoVariant.code}>{playgroundError.trim()}</Typo></SpacingLine>)
              : (<TextInputString value={playgroundResult} readOnly />)
          }
        </BlockContent>
      </HorizontalBlock>
    </VerticalBlock>
  );
};

export default FormulaPlaygroundBlock;
