import type { FunctionComponent } from 'react';
import { Fragment, useState } from 'react';
import { encodeExternalKey } from 'yooi-modules/modules/conceptModule';
import {
  ConceptExternalKeyMapping,
  ConceptExternalKeyMapping_ObjectId,
  ConceptExternalKeyMapping_Role_ExternalKey,
  ConceptExternalKeyMapping_Role_ExternalKeyPropertyId,
  ExternalKeyField_RegexValidation,
  User,
  User_Email,
  User_IsEnabled,
  User_Type,
} from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareNumber, comparing, extractAndCompareValue } from 'yooi-utils';
import Button from '../../../components/atoms/Button';
import Checkbox from '../../../components/atoms/Checkbox';
import Banner, { BannerVariant } from '../../../components/molecules/Banner';
import ScreenModal from '../../../components/molecules/ScreenModal';
import SpacingLine from '../../../components/molecules/SpacingLine';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';

const parseRawEmails = (rawEmails: string): string[] => Array.from(new Set(rawEmails.split('\n').map((string) => string.trim()).filter((string) => string)));

const useStyles = makeStyles({
  textarea: {
    resize: 'none',
  },
}, 'provisionSSOUsersModal');

interface ProvisionSSOUsersModalProps {
  onClose: () => void,
}

const ProvisionSSOUsersModal: FunctionComponent<ProvisionSSOUsersModalProps> = ({ onClose }) => {
  const classes = useStyles();

  const store = useStore();

  const [submitting, setSubmitting] = useState(false);
  const [emails, setEmails] = useState('');
  const [disabled, setDisabled] = useState(false);

  const emailField = store.getObject(User_Email);

  const emailStatuses = parseRawEmails(emails)
    .map((email) => {
      const status: { email: string, warnings: string[], errors: string[] } = { email, warnings: [], errors: [] };

      const isValidFormat = RegExp(emailField[ExternalKeyField_RegexValidation] as string).test(email);
      if (isValidFormat) {
        const mappedUser = store.withAssociation(ConceptExternalKeyMapping)
          .withRole(ConceptExternalKeyMapping_Role_ExternalKeyPropertyId, User_Email)
          .withRole(ConceptExternalKeyMapping_Role_ExternalKey, encodeExternalKey(email))
          .getObjectOrNull()
          ?.navigateOrNull(ConceptExternalKeyMapping_ObjectId);

        if (mappedUser) {
          if (mappedUser[User_Type] !== 'sso') {
            status.warnings.push(i18n`User account type will be converted to SSO.`);
          }
          if (disabled && mappedUser[User_IsEnabled]) {
            status.warnings.push(i18n`User account will be disabled.`);
          } else if (!disabled && !mappedUser[User_IsEnabled]) {
            status.warnings.push(i18n`User account will be enabled.`);
          }
        }
      } else {
        status.errors.push(i18n`Email format doesn't match validation pattern.`);
      }

      return status;
    })
    .filter(({ warnings, errors }) => warnings.length > 0 || errors.length > 0);

  const submit = () => {
    setSubmitting(true);

    try {
      const parsedEmails = parseRawEmails(emails);
      if (parsedEmails.length > 0) {
        parsedEmails.forEach((email) => {
          const mappedUser = store.withAssociation(ConceptExternalKeyMapping)
            .withRole(ConceptExternalKeyMapping_Role_ExternalKeyPropertyId, User_Email)
            .withRole(ConceptExternalKeyMapping_Role_ExternalKey, encodeExternalKey(email))
            .getObjectOrNull()
            ?.navigateOrNull(ConceptExternalKeyMapping_ObjectId);

          if (mappedUser) {
            store.updateObject(mappedUser.id, {
              [User_Type]: 'sso',
              [User_IsEnabled]: !disabled,
            });
          } else {
            store.createObject({
              [Instance_Of]: User,
              [User_Email]: email,
              [User_Type]: 'sso',
              [User_IsEnabled]: !disabled,
            });
          }
        });
        onClose();
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ScreenModal
      title={i18n`Provision SSO users`}
      open
      hide={onClose}
      render={() => (
        <VerticalBlock>
          <VerticalBlock asBlockContent>
            <BlockTitle title={i18n`List of emails to provision`} variant={BlockTitleVariant.inline} />
            <BlockContent padded>
              <textarea
                className={classes.textarea}
                cols={80}
                rows={15}
                value={emails}
                placeholder={i18n`Enter one email per line`}
                onChange={(event) => setEmails(event.target.value)}
              />
            </BlockContent>
          </VerticalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle title={i18n`Disable users`} />
            <BlockContent>
              <SpacingLine>
                <Checkbox checked={disabled} onChange={setDisabled} />
              </SpacingLine>
            </BlockContent>
          </HorizontalBlock>
          {
            emailStatuses.length === 0 ? null : (
              <SizeContextProvider sizeVariant={SizeVariant.small}>
                <VerticalBlock asBlockContent compact>
                  {
                    emailStatuses
                      .sort(comparing(extractAndCompareValue(({ errors }) => errors.length, compareNumber), true))
                      .map(({ email, errors, warnings }) => (
                        <Fragment key={email}>
                          {errors.map((error) => (
                            <BlockContent key={error} padded>
                              <Banner variant={BannerVariant.danger} title={`${email}: ${error}`} />
                            </BlockContent>
                          ))}
                          {warnings.map((warning) => (
                            <BlockContent key={warning} padded>
                              <Banner key={warning} variant={BannerVariant.warning} title={`${email}: ${warning}`} />
                            </BlockContent>
                          ))}
                        </Fragment>
                      ))
                  }
                </VerticalBlock>
              </SizeContextProvider>
            )
          }
          <VerticalBlock asBlockContent>
            <BlockContent padded>
              <Button
                title={i18n`Save`}
                disabled={submitting || emailStatuses.some(({ errors }) => errors.length > 0)}
                onClick={submit}
              />
            </BlockContent>
          </VerticalBlock>
        </VerticalBlock>
      )}
    />
  );
};

export default ProvisionSSOUsersModal;
