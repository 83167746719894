import type { FunctionComponent } from 'react';
import { v4 as uuid } from 'uuid';
import { usePathV2 } from 'yooi-modules';
import type {
  MultipleParameterDefinition,
  MultipleParameterValue,
  ParametersMapping,
  PathStep,
  SingleParameterDefinition,
  SingleParameterValue,
} from 'yooi-modules/modules/conceptModule';
import {
  compilePath1,
  compilePath2,
  createValuePathResolver1,
  createValuePathResolver2,
  isMultiValueResolution,
  isSingleValueResolution,
} from 'yooi-modules/modules/conceptModule';
import { getPathReturnedType as getPathReturnedType1 } from 'yooi-modules/modules/conceptModule/utils/path/pathResolver1';
import { getPathReturnedType as getPathReturnedType2 } from 'yooi-modules/modules/conceptModule/utils/path/pathResolver2';
// eslint-disable-next-line yooi/no-restricted-dependency
import { isStoreObject } from 'yooi-store';
import { errorToObject, joinObjects } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../components/atoms/Button';
import Icon, { IconName } from '../../../components/atoms/Icon';
import Typo from '../../../components/atoms/Typo';
import SpacingLine from '../../../components/molecules/SpacingLine';
import ToggleButton, { ElementPosition } from '../../../components/molecules/ToggleButton';
import BlockContent from '../../../components/templates/BlockContent';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import { FontVariant } from '../../../theme/fontDefinition';
import i18n from '../../../utils/i18n';
import { formatOrUndef } from '../../../utils/stringUtils';
import { useSessionStorageState } from '../../../utils/useSessionStorage';
import useTheme from '../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../utils/useUsageContext';
import type { MultipleParameterInterface, SingleParameterInterface } from '../../_global/parameters/ParameterLine';
import ParameterLine from '../../_global/parameters/ParameterLine';
import type { ParameterMultipleData } from '../../_global/parameters/ParameterMultiple';
import type { ParameterSingleData } from '../../_global/parameters/ParameterSingle';
import PathInput from '../../_global/path/PathInput';
import { generateHintedValue } from './_global/explorerUtils';
import { useExplorerHint } from './_global/GetHintContextProvider';
import ValueRenderer from './_global/ValueRenderer';

interface ExplorerHomePathsTabConfig {
  parameterDefinitions: (ParameterSingleData | ParameterMultipleData)[],
  path: PathStep[],
  isTimeseries: boolean,
  pathVersion: 'v1' | 'v2' | undefined,
}

const ExplorerHomePathsTab: FunctionComponent = () => {
  const theme = useTheme();

  const store = useStore();
  const explorerHint = useExplorerHint();

  const [config, setConfig] = useSessionStorageState<ExplorerHomePathsTabConfig>('explorerPath', {
    parameterDefinitions: [],
    path: [],
    isTimeseries: false,
    pathVersion: undefined,
  });

  const parameterDefinitions = config.parameterDefinitions.flatMap(({ id, typeId, type, label }): (SingleParameterDefinition | MultipleParameterDefinition)[] => {
    if (typeId) {
      return [{ id, typeId, type, label: formatOrUndef(label) }];
    }
    return [];
  });
  const parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue> = {};
  config.parameterDefinitions.forEach((parameterDefinition) => {
    if (parameterDefinition.typeId && parameterDefinition.type === 'parameter') {
      parametersMapping[parameterDefinition.id] = { type: 'single' as const, id: parameterDefinition.selectedInstanceId };
    } else if (parameterDefinition.typeId && parameterDefinition.type === 'parameterList') {
      parametersMapping[parameterDefinition.id] = { type: 'multiple' as const, ids: parameterDefinition.selectedInstanceIds ?? [] };
    }
  });

  const isDefaultV2 = usePathV2();
  const defaultPathVersion = isDefaultV2 ? 'v2' : 'v1';
  const pathVersion = config.pathVersion ?? defaultPathVersion;

  let resolvedValue;
  let pathReturnedType;
  let compiledPath;
  switch (pathVersion) {
    case 'v1': {
      const { resolvePathValue } = createValuePathResolver1(store, parametersMapping);
      resolvedValue = resolvePathValue(config.path, undefined, undefined, config.isTimeseries);
      pathReturnedType = getPathReturnedType1(
        store,
        parameterDefinitions.map(({ id, typeId }) => ({ id, typeIds: [typeId] })),
        config.path,
        config.isTimeseries ? 'timeseries' : 'value'
      );
      compiledPath = compilePath1(store, config.path);
      break;
    }
    case 'v2': {
      const { resolvePathValue, resolvePathTimeseries } = createValuePathResolver2(store, parametersMapping);
      resolvedValue = config.isTimeseries ? resolvePathTimeseries(config.path, undefined) : resolvePathValue(config.path);
      pathReturnedType = getPathReturnedType2(store, config.path, config.isTimeseries ? 'timeseries' : 'value');
      compiledPath = compilePath2(store, config.path);
      break;
    }
  }

  let displayValue: unknown;
  if (isSingleValueResolution(resolvedValue)) {
    displayValue = {
      pathVersion,
      type: resolvedValue.type,
      path: config.path,
      compiledPath,
      isNumber: resolvedValue.isArrhythmicTimeseries,
      isTimeseries: resolvedValue.isTimeseries,
      value: isStoreObject(resolvedValue.value)
        ? joinObjects({ key: resolvedValue.value.key, id: resolvedValue.value.id }, resolvedValue.value.asRawObject())
        : resolvedValue.value,
    };
  } else if (isMultiValueResolution(resolvedValue)) {
    displayValue = {
      pathVersion,
      type: resolvedValue.type,
      path: config.path,
      compiledPath,
      isNumber: resolvedValue.isArrhythmicTimeseries,
      isTimeseries: resolvedValue.isTimeseries,
      values: resolvedValue.values.every((obj) => isStoreObject(obj))
        ? (resolvedValue.values).map((obj) => (joinObjects({ key: obj.key, id: obj.id }, obj.asRawObject())))
        : resolvedValue.values,
    };
  } else {
    displayValue = errorToObject(resolvedValue);
  }

  return (
    <VerticalBlock>
      <BlockContent padded>
        <SpacingLine>
          <ToggleButton
            name={pathVersion}
            icon={pathVersion === 'v2' ? IconName.toggle_on : IconName.toggle_off}
            onClick={() => setConfig(joinObjects(config, { pathVersion: pathVersion === 'v1' ? 'v2' as const : 'v1' as const }))}
            active={pathVersion === 'v2'}
            type={ElementPosition.alone}
          />
          <ToggleButton
            name={config.isTimeseries ? i18n`As timeseries` : i18n`As value`}
            icon={config.isTimeseries ? IconName.toggle_on : IconName.toggle_off}
            onClick={() => setConfig(joinObjects(config, { isTimeseries: !config.isTimeseries }))}
            active={config.isTimeseries}
            type={ElementPosition.alone}
          />
          <Button
            iconName={IconName.add}
            variant={ButtonVariant.secondary}
            title={i18n`Single parameter`}
            onClick={() => {
              const newConfig = { ...config };
              newConfig.parameterDefinitions.push({ id: uuid(), type: 'parameter' });
              setConfig(newConfig);
            }}
          />
          <Button
            iconName={IconName.add}
            variant={ButtonVariant.secondary}
            title={i18n`Multiple parameter`}
            onClick={() => {
              const newConfig = { ...config };
              newConfig.parameterDefinitions.push({ id: uuid(), type: 'parameterList' });
              setConfig(newConfig);
            }}
          />
        </SpacingLine>
      </BlockContent>
      {
        config.parameterDefinitions.length > 0
          ? (
            <BlockContent padded>
              <SpacingLine>
                <ParameterLine
                  parameterInterfaces={
                    config.parameterDefinitions.map((parameterDefinition): SingleParameterInterface | MultipleParameterInterface => {
                      if (parameterDefinition?.type === 'parameterList') {
                        return {
                          type: 'parameterList',
                          parameterData: {
                            id: parameterDefinition.id,
                            selectedInstanceIds: parameterDefinition.selectedInstanceIds,
                            type: parameterDefinition.type,
                            typeId: parameterDefinition.typeId,
                            label: parameterDefinition.label,
                            unsetLabel: parameterDefinition.unsetLabel,
                            filters: parameterDefinition.filters,
                            defaultInstanceIds: parameterDefinition.defaultInstanceIds,
                            enableNotSetOption: parameterDefinition.enableNotSetOption,
                          },
                          handleSubmit: (data) => {
                            const newConfig = { ...config };
                            newConfig.parameterDefinitions = newConfig.parameterDefinitions.map((p) => {
                              if (p.id === parameterDefinition.id) {
                                return joinObjects(p, data);
                              } else {
                                return p;
                              }
                            });
                            setConfig(newConfig);
                          },
                          onDelete: () => {
                            const newConfig = { ...config };
                            newConfig.parameterDefinitions = newConfig.parameterDefinitions.filter(({ id }) => id !== parameterDefinition.id);
                            setConfig(newConfig);
                          },
                          readOnly: false,
                          focusOnMount: false,
                          parameterDefinitions,
                          parametersMapping,
                        };
                      } else {
                        return {
                          type: 'parameter',
                          parameterData: {
                            id: parameterDefinition.id,
                            selectedInstanceId: parameterDefinition.selectedInstanceId,
                            type: parameterDefinition.type,
                            typeId: parameterDefinition.typeId,
                            label: parameterDefinition.label,
                            unsetLabel: parameterDefinition.unsetLabel,
                            filters: parameterDefinition.filters,
                            defaultInstanceId: parameterDefinition.defaultInstanceId,
                            enableNotSetOption: parameterDefinition.enableNotSetOption,
                          },
                          handleSubmit: (data) => {
                            const newConfig = { ...config };
                            newConfig.parameterDefinitions = newConfig.parameterDefinitions.map((p) => {
                              if (p.id === parameterDefinition.id) {
                                return joinObjects(p, data);
                              } else {
                                return p;
                              }
                            });
                            setConfig(newConfig);
                          },
                          onDelete: () => {
                            const newConfig = { ...config };
                            newConfig.parameterDefinitions = newConfig.parameterDefinitions.filter(({ id }) => id !== parameterDefinition.id);
                            setConfig(newConfig);
                          },
                          readOnly: false,
                          focusOnMount: false,
                          parameterDefinitions,
                          parametersMapping,
                        };
                      }
                    })
                  }
                />
              </SpacingLine>
            </BlockContent>
          )
          : null
      }
      <BlockContent padded>
        <UsageContextProvider usageVariant={UsageVariant.inForm}>
          <PathInput
            path={config.path}
            onChange={(newPath) => {
              const newConfig = { ...config };
              newConfig.path = newPath;
              setConfig(newConfig);
            }}
            parameterDefinitions={parameterDefinitions}
            placeholder={i18n`Select path`}
            withSearchInId
          />
        </UsageContextProvider>
      </BlockContent>
      <BlockContent padded>
        <SpacingLine>
          <Typo>{i18n`Returned type:`}</Typo>
          {
            pathReturnedType.type === 'resolvable'
              ? (
                <>
                  <Icon name={IconName.check_circle} color={theme.color.text.success} tooltip={i18n`Resolvable`} />
                  <Typo variant={FontVariant.code}>{pathReturnedType.returnType.name}</Typo>
                </>
              )
              : (
                <Icon name={IconName.dangerous} color={theme.color.text.danger} tooltip={i18n`Unresolvable`} />
              )
          }
        </SpacingLine>
      </BlockContent>
      <BlockContent padded>
        <ValueRenderer
          // Force recreation of object as we navigate to make sure we clear component states
          key={JSON.stringify(config.path)}
          value={generateHintedValue(store, explorerHint, displayValue, true)}
        />
      </BlockContent>
    </VerticalBlock>
  );
};

export default ExplorerHomePathsTab;
