import type { ObjectStoreReadOnly, ObjectStoreWithTimeseries, TimeRange } from 'yooi-store';
import type { FormulaType } from 'yooi-utils';
import { usePathV2 } from '../../../../src/pathFeatureFlag';
import { Concept_GetFilterFunction } from '../../ids';
import type { FilterCondition, Filters, FilterValue, PathStep } from '../../moduleType';
import type { BackendFilterCondition, BackendFilterConditions } from '../filters/filters';
import type { MultipleParameterValue, ParametersMapping, SingleParameterValue } from '../parametersUtils';
import type { ResolutionStack } from '../resolutionStackUtils';
import type {
  FilterConditionExecutionContext,
  FilterFunction,
  MultiDimensionResolution,
  MultiFieldResolution,
  MultiValueResolution,
  SingleDimensionResolution,
  SingleFieldResolution,
  SingleValueResolution,
  StepResolutionError,
} from './common';
import type { GlobalDimensionResolution } from './pathResolver1';
import {
  compilePath as compilePath1,
  createValuePathResolver as createValuePathResolver1,
  getFilterConditionContext as getFilterConditionContext1,
  getFilterConditionsFromFilterPath as getFilterConditionsFromFilterPath1,
  getFilterFunctionHandler as getFilterFunctionHandler1,
  getFilterState as getFilterState1,
  getPathReturnedType as getPathReturnedType1,
  isMultiplePath as isMultiplePath1,
  isPathTargetingConcept as isPathTargetingConcept1,
} from './pathResolver1';
import type { CompiledPath } from './pathResolver2';
import {
  compilePath as compilePath2,
  createValuePathResolver as createValuePathResolver2,
  getFilterConditionContext as getFilterConditionContext2,
  getFilterConditionsFromFilterPath as getFilterConditionsFromFilterPath2,
  getFilterFunctionHandler as getFilterFunctionHandler2,
  getFilterState as getFilterState2,
  getPathReturnedType as getPathReturnedType2,
  isMultiplePath as isMultiplePath2,
  isPathTargetingConcept as isPathTargetingConcept2,
} from './pathResolver2';

export const isMultiplePath = (store: ObjectStoreWithTimeseries, path: PathStep[]): boolean => {
  if (usePathV2()) {
    return isMultiplePath2(store, path);
  } else {
    return isMultiplePath1(store, path);
  }
};

export const isPathTargetingConcept = (store: ObjectStoreReadOnly, path: PathStep[]): boolean => {
  if (usePathV2()) {
    return isPathTargetingConcept1(store, path);
  } else {
    return isPathTargetingConcept2(store, path);
  }
};

export const compilePath = (store: ObjectStoreWithTimeseries, path: PathStep[]): CompiledPath | PathStep[] | undefined => {
  if (usePathV2()) {
    return compilePath2(store, path);
  } else {
    return compilePath1(store, path);
  }
};

export const getPathReturnedType = (
  store: ObjectStoreWithTimeseries,
  parameters: { id: string, typeIds: string[] }[],
  path: PathStep[],
  mode: 'value' | 'timeseries' | 'timeseriesIterator'
): { type: 'resolvable', returnType: FormulaType, timeseriesMode: 'none' | 'implicit' | 'explicit' } | { type: 'unresolvable', reason: string, data: Record<string, unknown> } => {
  if (usePathV2()) {
    return getPathReturnedType2(store, path, mode);
  } else {
    return getPathReturnedType1(store, parameters, path, mode);
  }
};

export const getFilterConditionsFromFilterPath = (store: ObjectStoreWithTimeseries, filterPath: PathStep[]): BackendFilterConditions | undefined => {
  if (usePathV2()) {
    try {
      return getFilterConditionsFromFilterPath2(store, compilePath2(store, filterPath));
    } catch {
      return undefined;
    }
  } else {
    return getFilterConditionsFromFilterPath1(store, filterPath);
  }
};

export const getFilterState = (store: ObjectStoreWithTimeseries, filter: FilterCondition): undefined | BackendFilterCondition<FilterValue<unknown>, unknown, unknown> => {
  if (usePathV2()) {
    return getFilterState2(store, filter);
  } else {
    return getFilterState1(store, filter);
  }
};

export const getFilterConditionContext = (store: ObjectStoreWithTimeseries, filter: FilterCondition): FilterConditionExecutionContext | undefined => {
  if (usePathV2()) {
    return getFilterConditionContext2(store, filter);
  } else {
    return getFilterConditionContext1(store, filter);
  }
};

export const getFilterFunctionHandler = (store: ObjectStoreWithTimeseries, filters: Filters): FilterFunction => {
  if (usePathV2()) {
    return getFilterFunctionHandler2(store, filters);
  } else {
    return getFilterFunctionHandler1(store, filters);
  }
};

export const getFilterFunction = (store: ObjectStoreWithTimeseries, filters: Filters | undefined): FilterFunction => {
  if (!filters) {
    return undefined;
  } else if (store.hasPropertyFunction(Concept_GetFilterFunction)) {
    return store.getObject(JSON.stringify(filters), true)[Concept_GetFilterFunction] as FilterFunction;
  } else {
    return getFilterFunctionHandler(store, filters);
  }
};

export interface ValuePathResolver {
  resolvePathValue: <T = unknown>(
    valuePath: PathStep[], resolutionStack?: ResolutionStack
  ) => SingleValueResolution<T> | MultiValueResolution<T> | StepResolutionError | Error | undefined,
  resolvePathTimeseries: <T = unknown>(
    valuePath: PathStep[], dateRange: TimeRange | undefined, resolutionStack?: ResolutionStack
  ) => SingleValueResolution<T> | MultiValueResolution<T> | StepResolutionError | Error | undefined,
  resolvePathField: (valuePath: PathStep[]) => SingleFieldResolution | MultiFieldResolution | StepResolutionError | Error | undefined,
  resolvePathDimension: (valuePath: PathStep[]) => SingleDimensionResolution | MultiDimensionResolution | GlobalDimensionResolution | StepResolutionError | Error | undefined,
}

export const createValuePathResolver = (
  store: ObjectStoreWithTimeseries,
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>
): ValuePathResolver => {
  if (usePathV2()) {
    return createValuePathResolver2(store, parametersMapping);
  } else {
    const { resolvePathValue, resolvePathField, resolvePathDimension } = createValuePathResolver1(store, parametersMapping);
    return {
      resolvePathValue,
      resolvePathTimeseries: (valuePath, dateRange, resolutionStack) => resolvePathValue(valuePath, resolutionStack, dateRange, true),
      resolvePathField,
      resolvePathDimension,
    };
  }
};
