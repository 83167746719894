import { equals } from 'ramda';
import { v4 as uuid } from 'uuid';
import type { ObjectStoreReadOnly, ObjectStoreWithTimeseries } from 'yooi-store';
import { isStoreObject, OriginSources, ValidationStatus } from 'yooi-store';
import type { FormulaType, RichText } from 'yooi-utils';
import { anyType, compareProperty, compareRank, filterNullOrUndefined, joinObjects, newError, richTextToText } from 'yooi-utils';
import { asImport, CommonAsType } from '../../../common/fields/commonPropertyType';
import type { AsPropertyBusinessRuleRegistration, GetDslFieldHandler, UpdateOperationHandlers } from '../../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../../common/typeErrorUtils';
import { Integration } from '../../../integrationModule/ids';
import { isInstanceOf } from '../../../typeModule';
import { Instance_Of } from '../../../typeModule/ids';
import { preventComputedFieldUpdate, validateFieldIdAsProperty, validateIntegrationOnlyPropertyUpdate } from '../../common/commonFieldUtils';
import {
  Concept,
  Concept_Name,
  ConceptDefinition,
  ConceptInstanceWorkflowFieldField,
  ConceptInstanceWorkflowFieldField_Role_ConceptInstance,
  ConceptInstanceWorkflowFieldField_Role_Field,
  ConceptInstanceWorkflowFieldField_Role_WorkflowField,
  ConceptInstanceWorkflowFieldField_Value,
  Field,
  Field_ApiAlias,
  Field_IntegrationOnly,
  FieldDimension_Field,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  Workflow,
  Workflow_Name,
  Workflow_TargetedConceptDefinition,
  WorkflowEntry,
  WorkflowEntry_Rank,
  WorkflowEntry_Role_Concept,
  WorkflowEntry_Role_Workflow,
  WorkflowField as WorkflowFieldId,
  WorkflowField_TargetedConceptDefinition,
  WorkflowField_Workflow,
  WorkflowFieldFields,
  WorkflowFieldFields_Role_Field,
  WorkflowFieldFields_Role_WorkflowField,
  WorkflowFieldFieldsTransitionExecution,
  WorkflowFieldFieldsTransitionExecution_Role_Concept,
  WorkflowFieldFieldsTransitionExecution_Role_Field,
  WorkflowFieldFieldsTransitionExecution_Role_WorkflowField,
  WorkflowFieldTransitionExecution,
  WorkflowFieldTransitionExecution_Role_Concept,
  WorkflowFieldTransitionExecution_Role_WorkflowField,
  WorkflowTransition,
  WorkflowTransition_From,
  WorkflowTransition_Name,
  WorkflowTransition_Owner,
  WorkflowTransition_To,
} from '../../ids';
import type {
  ConceptStoreObject,
  FieldDimensionStoreObject,
  FieldDimensionTypesStoreObject,
  FieldStoreObject,
  WorkflowFieldStoreObject,
  WorkflowStoreObject,
  WorkflowTransitionStoreObject,
} from '../../model';
import { registerField } from '../../module';
import type { Filters, PathStep } from '../../moduleType';
import { PathStepType } from '../../moduleType';
import type { ConceptReference, DimensionsMapping, MultipleParameterValue, ParametersMapping, ResolutionStack, SingleParameterValue } from '../../utils';
import {
  adminOnlyAcl,
  conceptRefApiSchema,
  createValuePathResolver,
  FILTER_PARAMETER_CURRENT,
  FILTER_PARAMETER_LOGGED_USER,
  getFieldDimensionOfModelType,
  getFilterFunction,
  getInstanceLabel,
  InstanceReferenceType,
  isConceptValid,
  isSingleValueResolution,
  isValueResolutionOfType,
  ParsedDimensionType,
  parseDimensionMapping,
  resolveFieldValue,
  segregateParametersMapping,
  toConceptReference,
} from '../../utils';
import { conceptType } from '../../utils/formula/modelFunctions';
import type { SingleRelationFieldExportConfiguration } from '../../utils/relationFieldUtils';
import type { WorkflowField } from '../types';
import { WORKFLOW_FIELD_PARAMETER_SUB_FIELD_DIMENSION } from './constants';
import { isEntryValidForInstance, isWorkflowFieldStorageValueValid } from './utils';

interface WorkflowFieldValue {
  value: ConceptStoreObject | undefined,
  fieldValues: Record<string, ConceptStoreObject | undefined>,
}

interface WorkflowFieldRestValue {
  value: ConceptReference | undefined,
  fieldValues: Record<string, ConceptReference | undefined>,
}

const getValueWithoutFormula = (objectStore: ObjectStoreReadOnly, fieldId: string, dimensionsMapping: DimensionsMapping) => {
  const { [WORKFLOW_FIELD_PARAMETER_SUB_FIELD_DIMENSION]: subFieldId, ...dimensions } = dimensionsMapping;

  const parsedDimension = parseDimensionMapping(dimensions);
  if (parsedDimension.type !== ParsedDimensionType.MonoDimensional) {
    return { value: undefined, fieldValues: {} };
  }
  const fieldResults = objectStore.withAssociation(ConceptInstanceWorkflowFieldField)
    .withRole(ConceptInstanceWorkflowFieldField_Role_WorkflowField, fieldId)
    .withRole(ConceptInstanceWorkflowFieldField_Role_ConceptInstance, parsedDimension.objectId)
    .list();

  return {
    value: subFieldId
      ? (
        fieldResults.find((assoc) => assoc.role(ConceptInstanceWorkflowFieldField_Role_Field) === subFieldId)?.object
          .navigateOrNull<ConceptStoreObject>(ConceptInstanceWorkflowFieldField_Value)
        ?? undefined
      )
      : objectStore.getObject(parsedDimension.objectId).navigateOrNull<ConceptStoreObject>(fieldId) ?? undefined,
    fieldValues: Object.fromEntries(
      fieldResults
        .map((assoc) => [
          assoc.role(ConceptInstanceWorkflowFieldField_Role_Field),
          assoc.object.navigateOrNull<ConceptStoreObject>(ConceptInstanceWorkflowFieldField_Value) ?? undefined])
    ),
  };
};

const getValueResolution = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack) => {
  const field = objectStore.getObject(fieldId);
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
  if (isValueResolutionOfType(valueResolution, (value): value is WorkflowFieldValue => {
    if (typeof value === 'object' && value) {
      const keys = Object.keys(value);
      if (keys.length === 2 && keys.includes('value') && keys.includes('fieldValues')) {
        return ((value as { value: unknown }).value === undefined || isStoreObject((value as { value: unknown }).value))
          && typeof ((value as { fieldValues: unknown }).fieldValues) === 'object'
          && Object.values((value as { fieldValues: Record<string, unknown> }).fieldValues).every((fieldValue) => fieldValue === undefined || isStoreObject(fieldValue));
      }
    }
    return false;
  })) {
    if (!valueResolution.value.value || valueResolution.error) {
      return valueResolution;
    }

    const workflow = field.navigateOrNull(WorkflowField_Workflow);
    if (
      !workflow
      || !objectStore.withAssociation(WorkflowEntry)
        .withRole(WorkflowEntry_Role_Workflow, workflow.id)
        .withRole(WorkflowEntry_Role_Concept, valueResolution.value.value.id)
        .getObjectOrNull()
    ) {
      return joinObjects(valueResolution, { error: newError('Selected instance does not belong to the workflow') });
    } else {
      return valueResolution;
    }
  } else {
    return {
      value: { value: undefined, fieldValues: {} },
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['WorkflowFieldValue'], typeof valueResolution.value),
      getDisplayValue: () => ({ value: undefined, fieldValues: {} }),
      isTimeseries: valueResolution.isTimeseries,
    };
  }
};

const checkWorkflowValueIsARelatedWorkflowEntry: AsPropertyBusinessRuleRegistration = (store, workflowFieldId) => (_, { id: instanceId, properties }) => {
  if (instanceId.length > 1 || !properties) {
    return undefined;
  }

  const newValue = properties[workflowFieldId];
  if (!isWorkflowFieldStorageValueValid(store, workflowFieldId, newValue)) {
    return { rule: 'field.workflowField.invalidValue', status: ValidationStatus.REJECTED };
  }
  return { rule: 'field.workflowField.valueIsARelatedWorkflowEntry', status: ValidationStatus.ACCEPTED };
};

const updateFieldValueWhenGlobalValueIsUpdated: AsPropertyBusinessRuleRegistration = (store, workflowFieldId) => (_, { id: instanceId, properties }) => {
  if (instanceId.length > 1 || !properties || properties[workflowFieldId] == null) {
    return undefined;
  }

  const newValue = properties[workflowFieldId];
  const fieldIds = store.withAssociation(WorkflowFieldFields)
    .withRole(WorkflowFieldFields_Role_WorkflowField, workflowFieldId)
    .list()
    .map((assoc) => assoc.role(WorkflowFieldFields_Role_Field));

  return {
    rule: 'field.workflowField.updateRelatedFieldValue',
    status: ValidationStatus.ACCEPTED,
    generateSystemEvent: (systemEventStore) => {
      fieldIds.forEach((fieldId) => {
        systemEventStore.withAssociation(ConceptInstanceWorkflowFieldField)
          .withRole(ConceptInstanceWorkflowFieldField_Role_ConceptInstance, instanceId[0])
          .withRole(ConceptInstanceWorkflowFieldField_Role_WorkflowField, workflowFieldId)
          .withRole(ConceptInstanceWorkflowFieldField_Role_Field, fieldId)
          .updateObject({ [ConceptInstanceWorkflowFieldField_Value]: newValue });
      });
    },
  };
};

const workflowType: FormulaType = {
  name: 'workflow',
  equals: (type) => type === workflowType,
  isAssignableFrom: (type) => type === workflowType || type === anyType,
};

interface WorkflowSetUpdate {
  action: 'set',
  objectId: string,
}

interface WorkflowRunTransitionUpdate {
  action: 'runTransition',
  transitionId: string,
}

type WorkflowUpdate = WorkflowSetUpdate | WorkflowRunTransitionUpdate;

interface WorkflowFieldUpdateConfiguration {
  REPLACE: { type: 'value', value: string | undefined } | { type: 'path', path: PathStep[] },
  RUN_TRANSITION: { type: 'value', value: string | undefined },
}

export interface WorkflowFieldStepConfiguration {
  workflowSubfieldId?: string,
  filters?: Filters,
}

type WorkflowFieldHandler = GetDslFieldHandler<
  WorkflowField,
  { value: string | undefined, fieldValues: Record<string, string | undefined> },
  WorkflowUpdate,
  WorkflowFieldValue,
  WorkflowFieldValue,
  WorkflowFieldRestValue,
  undefined,
  WorkflowFieldUpdateConfiguration,
  WorkflowFieldStepConfiguration,
  SingleRelationFieldExportConfiguration
>;
export const workflowFieldHandler: WorkflowFieldHandler = registerField({
  model: {
    label: 'WorkflowField',
    title: 'Workflow',
    withApiAlias: true,
    extraModel: ({ type, association, registerCustomBusinessRules }) => {
      type({
        label: 'Workflow',
        accessControlList: {
          READ: () => () => ({ rule: 'workflow.read.allow', status: ValidationStatus.ACCEPTED }),
          WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'workflow', 'WRITE'),
          DELETE: () => (_, objectId) => ({ rule: 'workflow.delete.delegate', status: ValidationStatus.DELEGATED, targetId: objectId, targetAction: 'WRITE' }),
        },
        objectDebugLabel: ({ getObjectOrNull }) => (objectId) => getObjectOrNull(objectId)?.[Workflow_Name] as string | undefined,
      })
        .property({ label: 'Workflow_Name', as: CommonAsType.string })
        .property({ label: 'Workflow_Description', as: CommonAsType.string })
        .relation({
          label: 'Workflow_TargetedConceptDefinition',
          targetTypeId: ConceptDefinition,
          reverseLabel: 'ConceptDefinition_AsWorkflowTarget',
          businessRules: [
            (objectStore) => (_, { id, properties }) => {
              if (properties === undefined || properties === null || properties[Workflow_TargetedConceptDefinition] === undefined) {
                return undefined;
              } else if (properties[Workflow_TargetedConceptDefinition] === null) {
                return {
                  rule: 'workflow.preventTargetClear',
                  status: ValidationStatus.REJECTED,
                };
              } else {
                const workflow = objectStore.getObjectOrNull(id);
                if (workflow === null || workflow[Workflow_TargetedConceptDefinition] === undefined) {
                  return undefined;
                } else if (workflow[Workflow_TargetedConceptDefinition] !== properties[Workflow_TargetedConceptDefinition]) {
                  return {
                    rule: 'workflow.preventTargetUpdate',
                    status: ValidationStatus.REJECTED,
                  };
                } else {
                  return undefined;
                }
              }
            },
          ],
        });

      association({
        label: 'WorkflowEntry',
        accessControlList: {
          READ: () => () => ({ rule: 'workflowEntry.read.allow', status: ValidationStatus.ACCEPTED }),
          WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'WorkflowEntry', 'WRITE'),
          DELETE: () => (_, objectId) => ({ rule: 'workflowEntry.delete.delegate', status: ValidationStatus.DELEGATED, targetId: objectId, targetAction: 'WRITE' }),
        },
        roles: [{ label: 'Workflow', targetTypeId: Workflow }, { label: 'Concept', targetTypeId: Concept }],
      })
        .property({ label: 'WorkflowEntry_Rank', as: CommonAsType.string });

      type({
        label: 'WorkflowTransition',
        accessControlList: {
          READ: () => () => ({ rule: 'workflow.read.allow', status: ValidationStatus.ACCEPTED }),
          WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'WorkflowTransition', 'WRITE'),
          DELETE: () => (_, objectId) => ({ rule: 'workflowTransition.delete.delegate', status: ValidationStatus.DELEGATED, targetId: objectId, targetAction: 'WRITE' }),
        },
        dynamicBusinessRules: [
          ({ onProperty }, { getObject }) => ([workflowTransitionId]) => {
            onProperty(workflowTransitionId).validate((_, { id, properties }) => {
              if (properties === undefined) {
                return undefined;
              }
              if ((id.length !== 3 || id[0] !== WorkflowFieldTransitionExecution) && (id.length !== 4 || id[0] !== WorkflowFieldFieldsTransitionExecution)) {
                return { rule: 'workflowTransition.asProperty.deny', status: ValidationStatus.REJECTED };
              } else if (id[0] === WorkflowFieldTransitionExecution) {
                const workflowFieldId = id[WorkflowFieldTransitionExecution_Role_WorkflowField + 1];
                const conceptId = id[WorkflowFieldTransitionExecution_Role_Concept + 1];

                const workflowField = getObject(workflowFieldId);
                const workflow = workflowField.navigateOrNull(WorkflowField_Workflow);
                if (!workflow) {
                  return { rule: 'workflowTransition.workflow.missing', status: ValidationStatus.REJECTED };
                }

                const workflowTargetedConceptDefinition = workflow.navigateOrNull(Workflow_TargetedConceptDefinition);
                if (!workflowTargetedConceptDefinition) {
                  return { rule: 'workflowTransition.workflow.target.missing', status: ValidationStatus.REJECTED };
                }

                const workflowTransition = getObject(workflowTransitionId);
                if (workflowTransition[WorkflowTransition_Owner] !== workflow.id) {
                  return { rule: 'workflowTransition.workflow.mismatch', status: ValidationStatus.REJECTED };
                }

                const to = workflowTransition.navigateOrNull(WorkflowTransition_To);
                if (!to) {
                  return { rule: 'workflowTransition.to.missing', status: ValidationStatus.REJECTED };
                } else if (!isInstanceOf(to, workflowTargetedConceptDefinition.id)) {
                  return { rule: 'workflowTransition.to.invalid', status: ValidationStatus.REJECTED };
                }

                return {
                  rule: 'workflowTransition.asProperty.allow',
                  status: ValidationStatus.ACCEPTED,
                  generateSystemEvent: ({ updateObject }) => {
                    updateObject(conceptId, { [workflowFieldId]: to.id });
                  },
                };
              } else {
                const workflowFieldId = id[WorkflowFieldFieldsTransitionExecution_Role_WorkflowField + 1];
                const conceptId = id[WorkflowFieldFieldsTransitionExecution_Role_Concept + 1];
                const fieldId = id[WorkflowFieldFieldsTransitionExecution_Role_Field + 1];

                const workflowField = getObject(workflowFieldId);
                const workflow = workflowField.navigateOrNull(WorkflowField_Workflow);
                if (!workflow) {
                  return { rule: 'workflowTransition.workflow.missing', status: ValidationStatus.REJECTED };
                }

                const workflowTargetedConceptDefinition = workflow.navigateOrNull(Workflow_TargetedConceptDefinition);
                if (!workflowTargetedConceptDefinition) {
                  return { rule: 'workflowTransition.workflow.target.missing', status: ValidationStatus.REJECTED };
                }

                const workflowTransition = getObject(workflowTransitionId);
                if (workflowTransition[WorkflowTransition_Owner] !== workflow.id) {
                  return { rule: 'workflowTransition.workflow.mismatch', status: ValidationStatus.REJECTED };
                }

                const to = workflowTransition.navigateOrNull(WorkflowTransition_To);
                if (!to) {
                  return { rule: 'workflowTransition.to.missing', status: ValidationStatus.REJECTED };
                } else if (!isInstanceOf(to, workflowTargetedConceptDefinition.id)) {
                  return { rule: 'workflowTransition.to.invalid', status: ValidationStatus.REJECTED };
                }

                return {
                  rule: 'workflowTransition.asProperty.allow',
                  status: ValidationStatus.ACCEPTED,
                  generateSystemEvent: ({ withAssociation }) => {
                    withAssociation(ConceptInstanceWorkflowFieldField)
                      .withRole(ConceptInstanceWorkflowFieldField_Role_WorkflowField, workflowFieldId)
                      .withRole(ConceptInstanceWorkflowFieldField_Role_Field, fieldId)
                      .withRole(ConceptInstanceWorkflowFieldField_Role_ConceptInstance, conceptId)
                      .updateObject({ [ConceptInstanceWorkflowFieldField_Value]: to.id });
                  },
                };
              }
            });
          },
        ],
        objectDebugLabel: ({ getObjectOrNull }) => (objectId) => {
          const transition = getObjectOrNull<WorkflowTransitionStoreObject>(typeof objectId === 'string' ? objectId : objectId[0]);
          if (transition === null) {
            return undefined;
          }
          const workflowName = transition.navigateOrNull<WorkflowStoreObject>(WorkflowTransition_Owner)?.[Workflow_Name];
          const fromName = richTextToText(transition.navigateOrNull(WorkflowTransition_From)?.[Concept_Name] as RichText | undefined);
          const toName = richTextToText(transition.navigateOrNull(WorkflowTransition_To)?.[Concept_Name] as RichText | undefined);
          return `${workflowName}: ${transition[WorkflowTransition_Name]} - from '${fromName}' to '${toName}'`;
        },
      })
        .property({ label: 'WorkflowTransition_Name', as: CommonAsType.string })
        .relation({ label: 'WorkflowTransition_Owner', targetTypeId: Workflow, reverseLabel: 'Workflow_Transitions' })
        .relation({ label: 'WorkflowTransition_From', targetTypeId: Concept, reverseLabel: 'Concept_AsWorkflowFrom' })
        .relation({ label: 'WorkflowTransition_To', targetTypeId: Concept, reverseLabel: 'Concept_AsWorkflowTo' });

      association({
        label: 'WorkflowFieldTransition',
        roles: [{ label: 'WorkflowField', targetTypeId: WorkflowFieldId }, { label: 'Transition', targetTypeId: WorkflowTransition }],
        accessControlList: {
          READ: () => () => ({ rule: 'WorkflowFieldTransition.read.allow', status: ValidationStatus.ACCEPTED }),
          WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'WorkflowFieldTransition', 'WRITE'),
          DELETE: () => (_, objectId) => ({ rule: 'WorkflowFieldTransition.delete.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: objectId }),
        },
      })
        .property({ label: 'WorkflowFieldTransition_Filters', as: CommonAsType.Filters })
        .property({ label: 'WorkflowFieldTransition_Rights', as: asImport('TransitionRights', 'modules/conceptModule/fields/workflowField') });

      association({
        label: 'WorkflowFieldEntry',
        roles: [{ label: 'WorkflowField', targetTypeId: WorkflowFieldId }, { label: 'Entry', targetTypeId: Concept }],
        accessControlList: {
          READ: () => () => ({ rule: 'WorkflowFieldEntry.read.allow', status: ValidationStatus.ACCEPTED }),
          WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'WorkflowFieldEntry', 'WRITE'),
          DELETE: () => (_, objectId) => ({ rule: 'WorkflowFieldEntry.delete.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: objectId }),
        },
      })
        .property({ label: 'WorkflowFieldEntry_Filters', as: CommonAsType.Filters });

      association({
        label: 'WorkflowFieldTransitionExecution',
        roles: [{ label: 'WorkflowField', targetTypeId: WorkflowFieldId }, { label: 'Concept', targetTypeId: Concept }],
        accessControlList: {
          READ: () => (_, objectId) => ({
            rule: 'workflowFieldTransitionExecution.read.allow',
            status: ValidationStatus.DELEGATED,
            targetId: [objectId[WorkflowFieldTransitionExecution_Role_Concept + 1]],
            targetAction: 'READ',
          }),
          WRITE: () => (_, objectId) => ({
            rule: 'workflowFieldTransitionExecution.write.allow',
            status: ValidationStatus.DELEGATED,
            targetId: [objectId[WorkflowFieldTransitionExecution_Role_Concept + 1]],
            targetAction: 'WRITE',
          }),
          DELETE: () => (_, objectId) => ({
            rule: 'workflowFieldTransitionExecution.delete.allow',
            status: ValidationStatus.DELEGATED,
            targetId: [objectId[WorkflowFieldTransitionExecution_Role_Concept + 1]],
            targetAction: 'DELETE',
          }),
        },
        businessRules: [
          ({ getObject, getObjectOrNull }) => (origin, { id, properties }) => {
            if (origin.source && [OriginSources.SYSTEM, OriginSources.MIGRATION].includes(origin.source)) {
              return undefined;
            }

            // Deleting instance, ignore
            if (properties === null) {
              return undefined;
            }

            const field = getObjectOrNull(id[1]);
            if (field?.[Field_IntegrationOnly] === true) {
              if (!origin.userId || !isInstanceOf(getObject(origin.userId), Integration)) {
                return {
                  rule: 'field.workflow.integrationOnly.rejectUpdate',
                  status: ValidationStatus.REJECTED,
                };
              }
            }
            return undefined;
          },
        ],
      });

      registerCustomBusinessRules(({ getObjectOrNull, withAssociation }, { onObject }) => {
        // Apply workflow default value on creation
        onObject(Concept).validate(({ userId }, { id, properties }) => {
          if (!properties || getObjectOrNull(id)) {
            return undefined;
          }
          return {
            rule: 'workflowField.concept.initializeValues',
            status: ValidationStatus.ACCEPTED,
            generateSystemEvent: (updatedStore) => {
              const workflowFieldDefaults = withAssociation(FieldDimensionTypes)
                .withRole(FieldDimensionTypes_Role_ConceptDefinition, properties[Instance_Of] as string)
                .list<FieldDimensionTypesStoreObject>()
                .map((fieldDimensionTypes) => fieldDimensionTypes.navigateRole<FieldDimensionStoreObject>(FieldDimensionTypes_Role_FieldDimension))
                .map((fieldDimension) => fieldDimension.navigateOrNull<FieldStoreObject>(FieldDimension_Field))
                .filter(filterNullOrUndefined)
                .filter((field): field is WorkflowFieldStoreObject => isInstanceOf(field, WorkflowFieldId))
                .filter((workflowField) => workflowField.navigateOrNull(WorkflowField_Workflow))
                .map((workflowField) => [
                  workflowField.id,
                  withAssociation(WorkflowEntry)
                    .withRole(WorkflowEntry_Role_Workflow, workflowField[WorkflowField_Workflow] as string)
                    .list()
                    .sort(compareProperty('object', compareProperty(WorkflowEntry_Rank, compareRank)))
                    .filter((assoc) => isEntryValidForInstance(
                      updatedStore as unknown as ObjectStoreWithTimeseries,
                      workflowField.id,
                      assoc.role(WorkflowEntry_Role_Concept),
                      {
                        [FILTER_PARAMETER_CURRENT]: { type: 'single', id: id[0] },
                        [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: userId },
                      }
                    ))[0]
                    ?.navigateRole(WorkflowEntry_Role_Concept).id,
                ])
                .filter(([key]) => properties[key] === undefined)
                .filter(([, entry]) => entry !== undefined);

              if (workflowFieldDefaults.length > 0) {
                updatedStore.updateObject(id, Object.fromEntries(workflowFieldDefaults));
              }
            },
          };
        });
      });

      association({
        label: 'WorkflowFieldFields',
        accessControlList: {
          READ: () => () => ({ rule: 'WorkflowFieldFields.read.allow', status: ValidationStatus.ACCEPTED }),
          WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'WorkflowFieldFields', 'WRITE'),
          DELETE: () => (_, objectId) => ({ rule: 'WorkflowFieldFields.delete.delegate', status: ValidationStatus.DELEGATED, targetId: objectId, targetAction: 'WRITE' }),
        },
        roles: [{ label: 'WorkflowField', targetTypeId: WorkflowFieldId }, { label: 'Field', targetTypeId: Field }],
      });

      association({
        label: 'ConceptInstanceWorkflowFieldField',
        accessControlList: {
          READ: () => (_, objectId) => ({
            rule: 'ConceptInstanceWorkflowFieldField.read.delegate',
            status: ValidationStatus.DELEGATED,
            targetId: [objectId[ConceptInstanceWorkflowFieldField_Role_ConceptInstance + 1]],
            targetAction: 'READ',
          }),
          WRITE: () => (_, objectId) => ({
            rule: 'ConceptInstanceWorkflowFieldField.write.delegate',
            status: ValidationStatus.DELEGATED,
            targetId: [objectId[ConceptInstanceWorkflowFieldField_Role_ConceptInstance + 1]],
            targetAction: 'WRITE',
          }),
          DELETE: () => (_, objectId) => ({
            rule: 'ConceptInstanceWorkflowFieldField.delete.delegate',
            status: ValidationStatus.DELEGATED,
            targetId: objectId,
            targetAction: 'WRITE',
          }),
        },
        roles: [{ label: 'WorkflowField', targetTypeId: WorkflowFieldId }, { label: 'Field', targetTypeId: Field }, { label: 'ConceptInstance', targetTypeId: Concept }],
        businessRules: [
          ({ getObject, getObjectOrNull }) => (origin, { id, properties }) => {
            if (origin.source && [OriginSources.SYSTEM, OriginSources.MIGRATION].includes(origin.source)) {
              return undefined;
            }

            // Deleting instance, ignore
            if (properties === null) {
              return undefined;
            }

            const field = getObjectOrNull(id[1]);
            if (field?.[Field_IntegrationOnly] === true) {
              if (!origin.userId || !isInstanceOf(getObject(origin.userId), Integration)) {
                return {
                  rule: 'field.workflow.integrationOnly.rejectUpdate',
                  status: ValidationStatus.REJECTED,
                };
              }
            }
            return undefined;
          },
        ],
      })
        .property({ label: 'ConceptInstanceWorkflowFieldField_Value', as: CommonAsType.string });

      association({
        label: 'WorkflowFieldFieldsTransitionExecution',
        roles: [{ label: 'WorkflowField', targetTypeId: WorkflowFieldId }, { label: 'Concept', targetTypeId: Concept }, { label: 'Field', targetTypeId: Field }],
        accessControlList: {
          READ: () => (_, objectId) => ({
            rule: 'workflowFieldTransitionExecution.read.allow',
            status: ValidationStatus.DELEGATED,
            targetId: [objectId[WorkflowFieldTransitionExecution_Role_Concept + 1]],
            targetAction: 'READ',
          }),
          WRITE: () => (_, objectId) => ({
            rule: 'workflowFieldTransitionExecution.write.allow',
            status: ValidationStatus.DELEGATED,
            targetId: [objectId[WorkflowFieldTransitionExecution_Role_Concept + 1]],
            targetAction: 'WRITE',
          }),
          DELETE: () => (_, objectId) => ({
            rule: 'workflowFieldTransitionExecution.delete.allow',
            status: ValidationStatus.DELEGATED,
            targetId: [objectId[WorkflowFieldTransitionExecution_Role_Concept + 1]],
            targetAction: 'DELETE',
          }),
        },
        businessRules: [
          ({ getObject, getObjectOrNull }) => (origin, { id, properties }) => {
            if (origin.source && [OriginSources.SYSTEM, OriginSources.MIGRATION].includes(origin.source)) {
              return undefined;
            }

            // Deleting instance, ignore
            if (properties === null) {
              return undefined;
            }

            const field = getObjectOrNull(id[1]);
            if (field?.[Field_IntegrationOnly] === true) {
              if (!origin.userId || !isInstanceOf(getObject(origin.userId), Integration)) {
                return {
                  rule: 'field.workflow.integrationOnly.rejectUpdate',
                  status: ValidationStatus.REJECTED,
                };
              }
            }
            return undefined;
          },
        ],
      });
    },
    relations: [
      {
        label: 'Workflow',
        targetTypeId: Workflow,
        reverseLabel: 'Workflow_UsedByWorkflowField',
        businessRules: [
          (objectStore) => (_, { id, properties }) => {
            if (properties === undefined || properties === null || properties[WorkflowField_Workflow] === undefined) {
              return undefined;
            } else if (properties[WorkflowField_Workflow] === null) {
              return {
                rule: 'workflowField.preventWorkflowClear',
                status: ValidationStatus.REJECTED,
              };
            } else if (typeof properties[WorkflowField_Workflow] !== 'string') {
              return {
                rule: 'workflowField.preventWorkflowInvalidType',
                status: ValidationStatus.REJECTED,
              };
            } else {
              const workflowField = objectStore.getObjectOrNull(id);
              if (workflowField === null || workflowField[WorkflowField_Workflow] === undefined) {
                return undefined;
              } else {
                const currentWorkflow = workflowField.navigateOrNull(WorkflowField_Workflow);
                if (currentWorkflow === null) {
                  return {
                    rule: 'workflowField.invalidCurrentWorkflow',
                    status: ValidationStatus.REJECTED,
                  };
                }
                const targetWorkflow = objectStore.getObjectOrNull(properties[WorkflowField_Workflow]);
                if (targetWorkflow === null) {
                  return {
                    rule: 'workflowField.invalidTargetWorkflow',
                    status: ValidationStatus.REJECTED,
                  };
                }

                if (currentWorkflow[Workflow_TargetedConceptDefinition] !== targetWorkflow[Workflow_TargetedConceptDefinition]) {
                  return {
                    rule: 'workflowField.preventWorkflowTargetUpdate',
                    status: ValidationStatus.REJECTED,
                  };
                } else {
                  return undefined;
                }
              }
            }
          },
        ],
      },
      {
        label: 'TargetedConceptDefinition',
        targetTypeId: ConceptDefinition,
        reverseLabel: 'ConceptDefinition_UsedAsWorkflowFieldTarget',
      },
    ],
    asPropertyBusinessRules: [
      checkWorkflowValueIsARelatedWorkflowEntry,
      updateFieldValueWhenGlobalValueIsUpdated,
      validateFieldIdAsProperty('workflowField'),
      preventComputedFieldUpdate('workflowField'),
      validateIntegrationOnlyPropertyUpdate('workflowField'),
    ],
    businessRules: [
      (objectStore) => (_, { id, properties }) => {
        if (properties === undefined || properties === null) {
          return undefined;
        } else {
          const storeWorkflowField = objectStore.getObjectOrNull(id);
          const targetedWorkflowId = properties[WorkflowField_Workflow] as string | undefined ?? storeWorkflowField?.[WorkflowField_Workflow] as string | undefined;
          if (targetedWorkflowId === undefined) {
            // Unable to define a targeted workflow, skip
            return undefined;
          }

          const fieldTargetedConceptDefinitionId = properties[WorkflowField_TargetedConceptDefinition] as string | undefined
            ?? storeWorkflowField?.[WorkflowField_TargetedConceptDefinition] as string | undefined;

          const workflow = objectStore.getObjectOrNull(targetedWorkflowId);
          if (workflow === null) {
            if (fieldTargetedConceptDefinitionId === null) {
              return {
                status: ValidationStatus.REJECTED,
                rule: 'workflowField.preventTargetConceptDefinitionReset',
              };
            } else {
              return undefined;
            }
          }

          const workflowTargetedConceptDefinitionId = workflow[Workflow_TargetedConceptDefinition] as string | undefined;

          if (fieldTargetedConceptDefinitionId === undefined) {
            if (properties[WorkflowField_Workflow] !== undefined && workflowTargetedConceptDefinitionId !== undefined) {
              return {
                status: ValidationStatus.ACCEPTED,
                rule: 'workflowField.autoFillTargetConceptDefinition',
                generateSystemEvent: ({ updateObject }) => {
                  updateObject(id, { [WorkflowField_TargetedConceptDefinition]: workflowTargetedConceptDefinitionId });
                },
              };
            } else {
              return undefined;
            }
          } else if (fieldTargetedConceptDefinitionId !== workflowTargetedConceptDefinitionId) {
            return {
              status: ValidationStatus.REJECTED,
              rule: 'workflowField.preventTargetConceptDefinition',
            };
          } else {
            return {
              status: ValidationStatus.ACCEPTED,
              rule: 'workflowField.validTargetConceptDefinition',
            };
          }
        }
      },
    ],
  },
  handler: (objectStore, fieldId) => {
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => {
      const { value } = getValueResolution(objectStore, fieldId, dimensionsMapping).value;
      if (value) {
        return getInstanceLabel(objectStore, value);
      } else {
        return undefined;
      }
    };
    const extractOperationValueObjectId = (
      parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
      operationValue: { type: 'value', value: string | undefined } | { type: 'path', path: PathStep[] }
    ): string | undefined => {
      if (operationValue.type === 'path') {
        const { path } = operationValue;
        const resolution = createValuePathResolver(objectStore, parametersMapping).resolvePathValue(path);
        if (isSingleValueResolution(resolution) && isStoreObject(resolution.value) && isConceptValid(objectStore, resolution.value.id)) {
          return resolution.value.id;
        } else {
          return undefined;
        }
      } else {
        return operationValue.value;
      }
    };

    return {
      describe: () => ({ hasData: true, returnType: workflowType, timeseriesMode: 'none' }),
      restApi: {
        returnTypeSchema: { type: 'object', properties: { value: conceptRefApiSchema } },
        formatValue: ({ value, fieldValues }) => ({
          value: value ? toConceptReference(value) : undefined,
          fieldValues: Object.fromEntries(Object.entries(fieldValues)
            .filter(([workflowFieldId]) => objectStore.getObjectOrNull(workflowFieldId)?.[Field_ApiAlias])
            .map(([workflowFieldId, workflowFieldIdValue]) => [
              objectStore.getObject(workflowFieldId)[Field_ApiAlias] as string,
              workflowFieldIdValue ? toConceptReference(workflowFieldIdValue) : undefined,
            ])),
        }),
      },
      getStoreValue: (dimensionsMapping) => {
        const { [WORKFLOW_FIELD_PARAMETER_SUB_FIELD_DIMENSION]: subFieldId, ...dimensions } = dimensionsMapping;

        const parsedDimension = parseDimensionMapping(dimensions);
        if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
          const fieldResults = objectStore.withAssociation(ConceptInstanceWorkflowFieldField)
            .withRole(ConceptInstanceWorkflowFieldField_Role_WorkflowField, fieldId)
            .withRole(ConceptInstanceWorkflowFieldField_Role_ConceptInstance, parsedDimension.objectId)
            .list();

          return {
            value: subFieldId
              ? (
                fieldResults.find((assoc) => assoc.role(ConceptInstanceWorkflowFieldField_Role_Field) === subFieldId)
                  ?.object[ConceptInstanceWorkflowFieldField_Value] as string | undefined
              )
              : objectStore.getObject(parsedDimension.objectId)[fieldId] as string | undefined,
            fieldValues: Object.fromEntries(
              fieldResults
                .map((assoc) => [
                  assoc.role(ConceptInstanceWorkflowFieldField_Role_Field),
                  assoc.object[ConceptInstanceWorkflowFieldField_Value] as string | undefined,
                ])
            ),
          };
        } else {
          return { value: undefined, fieldValues: {} };
        }
      },
      getValueWithoutFormula: (dimensionsMapping) => getValueWithoutFormula(objectStore, fieldId, dimensionsMapping),
      getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
      resolvePathStepConfiguration: (configuration) => {
        const conceptDefinitionId = objectStore.getObject(fieldId).navigateOrNull(WorkflowField_Workflow)?.navigateOrNull(Workflow_TargetedConceptDefinition)?.id ?? null;
        if (!conceptDefinitionId) {
          throw newError('Invalid field configuration, workflow not configured, missing return type.');
        }
        const resolveValue = (
          dimensionsMapping: DimensionsMapping,
          parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
          resolutionStack: ResolutionStack
        ) => {
          const { error, value } = getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack);
          let result: ConceptStoreObject | undefined;
          if (error) {
            throw error;
          } else if (configuration.workflowSubfieldId) {
            result = (value as WorkflowFieldValue).fieldValues[configuration.workflowSubfieldId];
          } else {
            result = value.value;
          }
          if (configuration.filters && result !== undefined) {
            const filterFunction = getFilterFunction(objectStore, configuration.filters);
            if (filterFunction) {
              return filterFunction(joinObjects(
                segregateParametersMapping(parametersMapping).singleParametersMapping,
                { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: result.id } }
              )) ? result : undefined;
            }
          }
          return result;
        };
        return ({
          hasData: true,
          timeseriesMode: 'none',
          getValueResolutionType: () => conceptType(conceptDefinitionId),
          getFieldResolutionDimensions: (dimensionsMapping) => joinObjects(
            dimensionsMapping,
            configuration.workflowSubfieldId ? { [WORKFLOW_FIELD_PARAMETER_SUB_FIELD_DIMENSION]: configuration.workflowSubfieldId } : undefined
          ),
          resolveDimension: (dimensionsMappings, parametersMapping, resolutionStack) => (
            { type: 'single' as const, instance: resolveValue(dimensionsMappings, parametersMapping, resolutionStack) }
          ),
          resolveValue,
        });
      },
      updateValue: (dimensionsMapping, update) => {
        const parsedDimension = parseDimensionMapping(dimensionsMapping);
        if (parsedDimension.type !== ParsedDimensionType.MonoDimensional) {
          throw newError('RelationSingle field only support mono-dimensional absorbed values');
        }

        if (update.action === 'set' && update.objectId) {
          objectStore.updateObject(parsedDimension.objectId, { [fieldId]: update.objectId });
        } else if (update.action === 'runTransition' && update.transitionId) {
          objectStore.withAssociation(WorkflowFieldTransitionExecution)
            .withRole(WorkflowFieldTransitionExecution_Role_WorkflowField, fieldId)
            .withRole(WorkflowFieldTransitionExecution_Role_Concept, parsedDimension.objectId)
            .updateObject({ [update.transitionId]: uuid() });
        }
      },
      isEmpty: (dimensionsMapping) => !getValueResolution(objectStore, fieldId, dimensionsMapping).value,
      isSaneValue: (objectId) => {
        const dimensionId = getFieldDimensionOfModelType(objectStore, fieldId, objectStore.getObject(objectId)[Instance_Of] as string);
        if (dimensionId) {
          const { error } = getValueResolution(objectStore, fieldId, { [dimensionId]: objectId });
          return { isValid: !error, error };
        } else {
          return ({ isValid: false });
        }
      },
      getValueAsText,
      getExportColumnHeaders: (configuration, fieldLabel) => ({
        columnsNumber: 1,
        getHeaders: () => [{ format: 'string', value: fieldLabel }],
        getColumnConfiguration: () => configuration,
      }),
      getExportValue: (dimensionsMapping, configuration) => {
        const { value } = getValueResolution(objectStore, fieldId, dimensionsMapping);
        if (!value.value) {
          return { format: 'string', value: undefined };
        }
        if (!configuration || configuration.type === 'path') {
          const defaultPath: PathStep[] = [
            { type: PathStepType.dimension, conceptDefinitionId: value.value[Instance_Of] },
            { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
            { type: PathStepType.field, fieldId: Concept_Name },
          ];
          const pathResolution = createValuePathResolver(objectStore, { [FILTER_PARAMETER_CURRENT]: { type: 'single', id: value.value?.id } })
            .resolvePathValue(configuration?.path ?? defaultPath);
          if (isSingleValueResolution(pathResolution)) {
            return { format: 'string', value: pathResolution.value as string };
          }
          return { format: 'string', value: undefined };
        } else {
          return { format: 'string', value: value.value?.id };
        }
      },
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else {
            return undefined;
          }
        },
      }),
      getTargetType: () => objectStore.getObject(fieldId).navigateOrNull(WorkflowField_Workflow)?.navigateOrNull(Workflow_TargetedConceptDefinition) ?? null,
      filterConditions: undefined,
      updateOperationHandlers: {
        REPLACE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            const objectId = extractOperationValueObjectId(parametersMapping, value);
            // a field workflow has always a value
            if (objectId !== undefined) {
              workflowFieldHandler(objectStore, fieldId).updateValue(dimensionsMapping, { action: 'set', objectId });
            }
          },
          sanitizeOperation: () => ({ type: 'value', value: undefined }),
        },
        RUN_TRANSITION: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            const transitionId = extractOperationValueObjectId(parametersMapping, value);
            if (transitionId !== undefined) {
              workflowFieldHandler(objectStore, fieldId).updateValue(dimensionsMapping, { action: 'runTransition', transitionId });
            }
          },
          sanitizeOperation: () => ({ type: 'value', value: undefined }),
        },
      } satisfies UpdateOperationHandlers<WorkflowFieldUpdateConfiguration>,
    };
  },
  historyEventProducer: (objectStore, fieldId) => ({
    value: {
      collectImpactedInstances: ({ id, properties }) => {
        const instance = objectStore.getObjectOrNull(id[0]);
        const dimension = instance ? getFieldDimensionOfModelType(objectStore, fieldId, instance[Instance_Of] as string) : undefined;
        if (
          properties?.[fieldId] !== undefined // A value is in the update for the current field
          || (
            dimension !== undefined && properties === null && id[0] && objectStore.getObjectOrNull(id[0])
            && getValueWithoutFormula(objectStore, fieldId, { [dimension]: id[0] })?.value !== undefined
          ) // Object is deleted and store had a value for the field
        ) {
          return [id];
        } else {
          return [];
        }
      },
      getValue: (id) => {
        const instance = objectStore.getObjectOrNull(id[0]);
        const dimension = instance ? getFieldDimensionOfModelType(objectStore, fieldId, instance[Instance_Of] as string) : undefined;
        return { value: dimension && instance ? getValueWithoutFormula(objectStore, fieldId, { [dimension]: instance.id }).value?.id ?? null : null, version: 1 };
      },
      areValuesEquals: (value1, value2) => equals(value1?.value, value2?.value),
    },
  }),
});
