import type { FrontObjectStore } from '../../../../../store/useStore';
import type { LayoutCollapseConfiguration } from '../../../_global/displayItemsLibrary/definitions/displayItemDefinitionUtils';
import { getParentsBlocksIds } from '../../../_global/displayItemsLibrary/definitions/displayItemDefinitionUtils';

export const expandBlock = (
  store: FrontObjectStore,
  layoutCollapseConfiguration: LayoutCollapseConfiguration | undefined,
  setLayoutCollapseConfiguration: (newLayoutCollapseConfiguration: LayoutCollapseConfiguration | undefined) => void,
  blockId: string
): void => {
  const parentIds: string[] = [...getParentsBlocksIds(store, blockId), blockId];
  if (layoutCollapseConfiguration) {
    const newLayoutCollapseConfiguration = layoutCollapseConfiguration;
    parentIds.forEach((parentId) => {
      if (newLayoutCollapseConfiguration[parentId]) {
        delete newLayoutCollapseConfiguration[parentId];
      }
    });
    setLayoutCollapseConfiguration(newLayoutCollapseConfiguration);
  }
};
