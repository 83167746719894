import type { RichText } from 'yooi-utils';
import { richTextToText } from 'yooi-utils';
import { createFieldDsl } from '../common/fields/FieldModuleDsl';
import { createModule } from '../common/types/TypeModuleDsl';
import { Concept_Name, Field } from '../conceptModule/ids';
import { isInstanceOf } from '../typeModule';
import * as DashboardModuleIds from './ids';
import {
  Dashboard,
  Dashboard_Parameters,
  Dashboard_Widgets,
  DashboardModuleId,
  DashboardParameter,
  DashboardParameter_Dashboard,
  Field_Widget,
  Widget,
  Widget_Dashboard,
  Widget_Title,
} from './ids';
import migrations from './migrations';

const moduleDsl = createModule({ label: 'Dashboard' }, DashboardModuleIds);
export const { registerField } = createFieldDsl(moduleDsl);

const { initTypedModule, ...registerModelDsl } = moduleDsl;
export const registerModel = registerModelDsl;

export const initModule = initTypedModule(() => ({
  id: DashboardModuleId,
  migrations,
  registerGarbageCollectorRules: (objectStore, { register }) => {
    register({
      ruleName: 'invalidWidget',
      collect: ({ id, properties }) => {
        if (properties === null && typeof id === 'string') {
          const deletedObject = objectStore.getObjectOrNull(id);
          if (deletedObject) {
            if (isInstanceOf(deletedObject, Dashboard)) {
              return deletedObject.navigateBack(Dashboard_Widgets).map((widget) => widget.id);
            }
          }
        }
        return [];
      },
      shouldDelete: (id) => {
        if (typeof id !== 'string') {
          return false;
        }
        const object = objectStore.getObject(id);
        return isInstanceOf(object, Widget) && !object.navigateOrNull(Widget_Dashboard);
      },
    });
    register({
      ruleName: 'invalidDashboardParameter',
      collect: ({ id, properties }) => {
        if (properties === null && typeof id === 'string') {
          const deletedObject = objectStore.getObjectOrNull(id);
          if (deletedObject) {
            if (isInstanceOf(deletedObject, Dashboard)) {
              return deletedObject.navigateBack(Dashboard_Parameters).map((parameter) => parameter.id);
            }
          }
        }
        return [];
      },
      shouldDelete: (id) => {
        if (typeof id !== 'string') {
          return false;
        }
        const object = objectStore.getObject(id);
        return isInstanceOf(object, DashboardParameter) && !object.navigateOrNull(DashboardParameter_Dashboard);
      },
    });
  },
  registerObjectDebugLabel: (objectStore, { onObject }) => {
    onObject(Field).register((objectId) => {
      const field = objectStore.getObject(objectId);
      const widget = field.navigateBack(Field_Widget)[0];
      if (widget) {
        return `Widget field of '${richTextToText(widget.navigateOrNull(Widget_Dashboard)?.[Concept_Name] as RichText | undefined)}' -> '${widget[Widget_Title]}'`;
      } else {
        return undefined;
      }
    });
  },
}));
