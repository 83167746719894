import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { FieldStoreObject, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import {
  associationFieldHandler,
  displayInstanceFieldAsText,
  getConceptUrl,
  idFieldHandler,
  isConceptValid,
  isEmbeddedAsIntegrationOnly,
  numberFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import { Concept_FunctionalId, Concept_Name, ConceptDefinition_Color, ConceptFunctionalIdDimension } from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { WithNonUndefinedKeys } from 'yooi-utils';
import Quadrant from '../../../../components/charts/Quadrant';
import useAcl from '../../../../store/useAcl';
import useActivity from '../../../../store/useActivity';
import useStore from '../../../../store/useStore';
import useUpdateActivity from '../../../../store/useUpdateActivity';
import i18n from '../../../../utils/i18n';
import { remToPx } from '../../../../utils/sizeUtils';
import useNavigation from '../../../../utils/useNavigation';
import { useSessionStorageState } from '../../../../utils/useSessionStorage';
import { getConceptModelDisplayField, resolveColor } from '../../conceptDisplayUtils';
import ConceptBacklog from '../../fields/_global/backlog/ConceptBacklog';
import ConceptMatrixTooltip from '../../fields/_global/matrix/ConceptMatrixTooltip';
import { getFieldColumnComparator, getFieldLabel, getInstanceMaxMinValues, TickResolutionStatus } from '../../fieldUtils';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { getConceptFilters } from '../../listFilterFunctions';
import type { NavigationFilter } from '../../navigationUtils';
import useFilterAndSort from '../../useFilterAndSort';
import type { MatrixViewResolvedDefinition } from './matrixViewDefinitionHandler';
import type { MatrixViewConfiguration, MatrixViewResolution } from './matrixViewResolution';

interface MatrixEntry {
  id: string,
  instanceOf: string,
  label: { id?: string, name: string | undefined },
  absValue: number | undefined,
  ordValue: number | undefined,
  color: string | undefined,
  colorRawValue: string | undefined,
}

interface ConceptMatrixViewProps {
  filterKey: string,
  readOnly?: boolean,
  parametersMapping: ParametersMapping,
  viewDefinition: MatrixViewResolvedDefinition,
  viewResolution: MatrixViewResolution,
  navigationFilters: NavigationFilter,
  containerWidthPx: number,
  containerHeightPx: number,
}

const ConceptMatrixView: FunctionComponent<ConceptMatrixViewProps> = ({
  filterKey,
  readOnly = false,
  parametersMapping,
  viewDefinition,
  viewResolution,
  navigationFilters,
  containerWidthPx,
  containerHeightPx,
}) => {
  const store = useStore();
  const { canWriteObject } = useAcl();

  const activity = useActivity();
  const updateActivity = useUpdateActivity();

  const navigation = useNavigation<NavigationFilter>();

  const { generateList } = useFilterAndSort(
    filterKey,
    viewResolution.instances,
    viewResolution.filterFunction,
    { getComparatorHandler: getFieldColumnComparator(store), initial: viewResolution.defaultSort },
    undefined,
    [parametersMapping]
  );

  const [filtersConfiguration] = useSessionStorageState<FilterConfiguration | undefined>(filterKey, undefined);

  const [matrixConfig] = useSessionStorageState<MatrixViewConfiguration>(`MatrixView:${filterKey}`, {});

  const { xAxis, yAxis, dependencies } = viewResolution;

  const yAxisFieldHandler = numberFieldHandler(store, yAxis.fieldId);
  const yAxisField = yAxisFieldHandler.resolveConfiguration();
  const yAxisIsFieldComputed = !!yAxisField.formula;
  const yAxisName = getFieldLabel(store, store.getObject<FieldStoreObject>(yAxis.fieldId));

  const xAxisFieldHandler = numberFieldHandler(store, xAxis.fieldId);
  const xAxisField = xAxisFieldHandler.resolveConfiguration();
  const xAxisIsFieldComputed = !!xAxisField.formula;
  const xAxisName = getFieldLabel(store, store.getObject<FieldStoreObject>(xAxis.fieldId));

  const colorFieldId = matrixConfig.colorFieldId === null
    ? undefined
    : matrixConfig.colorFieldId ?? viewDefinition.colorFieldId ?? getConceptModelDisplayField(store, viewResolution.conceptDefinitionId, ConceptDefinition_Color)?.id;

  const showDependencies = dependencies !== undefined && (matrixConfig.showDependencies ?? true);
  const dependencyFieldHandler = dependencies ? associationFieldHandler(store, dependencies.fieldId) : undefined;

  const [isDraggingElement, setIsDraggingElement] = useState(false);

  const matrixList = generateList()
    .list.map(({ item }) => item)
    .filter(({ id }) => isConceptValid(store, id))
    .map((concept): MatrixEntry => {
      const yValueResolution = yAxisFieldHandler.getValueResolution({ [yAxis.dimensionId]: concept.id });
      const xValueResolution = xAxisFieldHandler.getValueResolution({ [xAxis.dimensionId]: concept.id });

      const name = viewDefinition.labelFieldId ? displayInstanceFieldAsText(store, concept.id, viewDefinition.labelFieldId) : undefined;
      const id = viewDefinition.labelFieldId !== Concept_FunctionalId
        ? idFieldHandler(store, Concept_FunctionalId).getValueAsText?.({ [ConceptFunctionalIdDimension]: concept.id })
        : undefined;

      return {
        id: concept.id,
        instanceOf: concept[Instance_Of],
        label: { id, name },
        absValue: typeof xValueResolution.value === 'number' ? xValueResolution.value : undefined,
        ordValue: typeof yValueResolution.value === 'number' ? yValueResolution.value : undefined,
        color: colorFieldId ? resolveColor(store, concept[Instance_Of], concept.id, colorFieldId) : undefined,
        colorRawValue: colorFieldId ? concept[colorFieldId] as string | undefined : undefined,
      };
    });

  const { min: yFieldMin, max: yFieldMax } = getInstanceMaxMinValues(store, yAxis.fieldId, undefined, {});
  const { min: xFieldMin, max: xFieldMax } = getInstanceMaxMinValues(store, xAxis.fieldId, undefined, {});

  const { yMin, yMax, xMin, xMax } = matrixList.reduce(
    (acc, { id, absValue, ordValue }) => {
      const { min: yInstanceMin, max: yInstanceMax } = getInstanceMaxMinValues(store, yAxis.fieldId, id, {});
      const { min: xInstanceMin, max: xInstanceMax } = getInstanceMaxMinValues(store, xAxis.fieldId, id, {});

      return {
        yMin: Math.min(acc.yMin, (yInstanceMin?.status === TickResolutionStatus.Resolved ? yInstanceMin.value : undefined) ?? ordValue ?? 0),
        yMax: Math.max(acc.yMax, (yInstanceMax?.status === TickResolutionStatus.Resolved ? yInstanceMax.value : undefined) ?? ordValue ?? 0),
        xMin: Math.min(acc.xMin, (xInstanceMin?.status === TickResolutionStatus.Resolved ? xInstanceMin.value : undefined) ?? absValue ?? 0),
        xMax: Math.max(acc.xMax, (xInstanceMax?.status === TickResolutionStatus.Resolved ? xInstanceMax.value : undefined) ?? absValue ?? 0),
      };
    },
    {
      yMin: (yFieldMin?.status === TickResolutionStatus.Resolved ? yFieldMin.value : undefined) ?? Number.MAX_SAFE_INTEGER,
      yMax: (yFieldMax?.status === TickResolutionStatus.Resolved ? yFieldMax.value : undefined) ?? Number.MIN_SAFE_INTEGER,
      xMin: (xFieldMin?.status === TickResolutionStatus.Resolved ? xFieldMin.value : undefined) ?? Number.MAX_SAFE_INTEGER,
      xMax: (xFieldMax?.status === TickResolutionStatus.Resolved ? xFieldMax.value : undefined) ?? Number.MIN_SAFE_INTEGER,
    }
  );

  const isInMatrix = (entry: MatrixEntry): entry is WithNonUndefinedKeys<MatrixEntry, 'absValue' | 'ordValue'> => {
    const { absValue, ordValue } = entry;
    return absValue !== undefined && ordValue !== undefined && ordValue >= yMin && ordValue <= yMax && absValue >= xMin && absValue <= xMax;
  };

  const { quadrantList, backlogList } = matrixList
    .reduce<{ quadrantList: WithNonUndefinedKeys<MatrixEntry, 'absValue' | 'ordValue'>[], backlogList: MatrixEntry[] }>(
      (acc, entry) => {
        if (isInMatrix(entry)) {
          acc.quadrantList.push(entry);
        } else {
          acc.backlogList.push(entry);
        }
        return acc;
      },
      { quadrantList: [], backlogList: [] }
    );

  const handleDragStart = (data: { key: string }) => {
    updateActivity.onEnterEdition(data.key, yAxis.fieldId);
    updateActivity.onEnterEdition(data.key, xAxis.fieldId);
    // on Chrome changing the dom in the dragStart event trigger a dragEnd event the setTimeout 0 is a simple hack to prevent this
    setTimeout(() => setIsDraggingElement(true), 0);
  };

  const handleDragEnd = (data: { key: string }) => {
    updateActivity.onExitEdition(data.key, yAxis.fieldId);
    updateActivity.onExitEdition(data.key, xAxis.fieldId);
    setIsDraggingElement(false);
  };

  const handleDrop = (droppedId: string, position?: { x: number, y: number }) => {
    const dropped = matrixList.find(({ id }) => droppedId === id);
    setIsDraggingElement(false);

    if (!dropped) {
      return;
    }

    if (!xAxisIsFieldComputed) {
      xAxisFieldHandler.updateValue({ [xAxis.dimensionId]: droppedId }, position?.x ?? null);
    }
    if (!yAxisIsFieldComputed) {
      yAxisFieldHandler.updateValue({ [yAxis.dimensionId]: droppedId }, position?.y ?? null);
    }
  };

  const renderTooltip = (conceptId: string, editMode: boolean, currentAnchor: EventTarget, handleClose: () => void) => {
    const entry = matrixList.find(({ id }) => conceptId === id);
    if (entry !== undefined) {
      return (
        <ConceptMatrixTooltip
          concept={entry}
          matrixOrdFieldId={yAxis.fieldId}
          matrixAbsFieldId={xAxis.fieldId}
          colorFieldId={colorFieldId}
          editMode={editMode && canWriteObject(conceptId) && !readOnly && !isEmbeddedAsIntegrationOnly(store.getObject(conceptId))}
          buttonView={editMode}
          currentAnchor={currentAnchor as HTMLElement}
          handleClose={handleClose}
          handleSubmit={(id, { color, ordValue, absValue }) => {
            if (!xAxisIsFieldComputed) {
              xAxisFieldHandler.updateValue({ [xAxis.dimensionId]: id }, absValue);
            }
            if (!yAxisIsFieldComputed) {
              yAxisFieldHandler.updateValue({ [yAxis.dimensionId]: id }, ordValue);
            }

            if (colorFieldId) {
              const oldValues = store.getObject(id);
              if (oldValues[colorFieldId] !== color) {
                store.updateObject(id, { [colorFieldId]: color });
              }
            }
          }}
          readOnly={readOnly}
          navigationFilters={navigationFilters}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Quadrant
        yName={yAxisField?.unit ? `${yAxisName} ${yAxisField.unit}` : yAxisName}
        yMin={Math.min(yMin, yMax)}
        yMax={Math.max(yMin, yMax)}
        yCanDrag={!yAxisIsFieldComputed}
        xName={xAxisField?.unit ? `${xAxisName} ${xAxisField.unit}` : xAxisName}
        xMin={Math.min(xMin, xMax)}
        xMax={Math.max(xMin, xMax)}
        xCanDrag={!xAxisIsFieldComputed}
        topLeft={viewDefinition.topLeftQuadrant}
        topRight={viewDefinition.topRightQuadrant}
        bottomLeft={viewDefinition.bottomLeftQuadrant}
        bottomRight={viewDefinition.bottomRightQuadrant}
        data={
          quadrantList
            .map(({ id, label, absValue, ordValue, color }) => ({
              key: id,
              label,
              color,
              x: absValue,
              y: ordValue,
              draggable: canWriteObject(id) && !readOnly && !isEmbeddedAsIntegrationOnly(store.getObject(id)),
              clickable: true,
              dependingOn: dependencyFieldHandler && showDependencies
                ? dependencyFieldHandler.getValueResolution({ [dependencies.dimensionId]: id }).value.map((concept) => concept.id)
                : [],
            }))
        }
        heightPx={viewDefinition.showBacklog ? remToPx(65) : containerHeightPx}
        widthPx={containerWidthPx}
        onElementDrag={handleDragStart}
        onElementDragEnd={handleDragEnd}
        onElementDrop={handleDrop}
        isDragging={isDraggingElement}
        renderTooltip={renderTooltip}
        showDependencies={showDependencies}
        isEntryEditedByOtherUser={(id) => [yAxis.fieldId, xAxis.fieldId].some((fieldId) => activity.listEditor(id, fieldId).length > 0)}
        onDoubleClick={(conceptId) => {
          navigation.push(conceptId, {
            pathname: getConceptUrl(store, conceptId),
            navigationFilters: {
              globalFilters: getConceptFilters(store, viewResolution.conceptDefinitionId, filtersConfiguration),
              globalParametersMapping: {},
            },
          });
        }}
      />
      {
        viewDefinition.showBacklog
          ? (
            <ConceptBacklog
              title={i18n`Unassessed`}
              list={backlogList}
              onElementDrag={handleDragStart ? ({ id }) => handleDragStart({ key: id }) : undefined}
              onElementDragEnd={handleDragEnd ? ({ id }) => handleDragEnd({ key: id }) : undefined}
              onElementDrop={handleDrop}
              dragLabelFieldId={viewDefinition.labelFieldId}
              renderTooltip={renderTooltip}
              multiplayerPropertyIds={[Concept_Name, yAxis.fieldId, xAxis.fieldId]}
              readOnly={readOnly}
              iconText={i18n`You can drag and drop instances directly to the matrix`}
              fullWidth
            />
          )
          : null
      }
    </>
  );
};

export default ConceptMatrixView;
