import type { FunctionComponent } from 'react';
import { DndProvider } from 'react-dnd';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { TimelineViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import useDeepMemo from '../../../../utils/useDeepMemo';
import { dndManager } from '../../fields/_global/dndUtils';
import ConceptTimeline from '../../fields/_global/timeline/ConceptTimeline';
import { getFieldColumnComparator } from '../../fieldUtils';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { useViewFilters } from '../../filter/useViewFilters';
import useFilterAndSort from '../../useFilterAndSort';
import BlockViewError from '../common/BlockViewError';
import { getViewNavigationFilters } from '../common/viewUtils';
import { getViewDefinitionHandler } from '../viewDsl';
import { isResolutionError } from '../viewResolutionUtils';
import type { TimelineViewDefinitionHandler } from './timelineViewHandler';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: spacingRem.blockSpacing,
    paddingBottom: spacingRem.xs,
    paddingLeft: spacingRem.s,
    borderRadius: '0.4rem',
    background: theme.color.background.neutral.default,
  },
}), 'timelineViewBlock');

interface TimelineViewBlockProps {
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinition: TimelineViewStoredDefinition,
  parametersMapping: ParametersMapping,
  readOnly: boolean | undefined,
}

const TimelineViewBlock: FunctionComponent<TimelineViewBlockProps> = ({
  viewDimensions,
  viewFilters,
  viewDefinition,
  parametersMapping,
  readOnly,
}) => {
  const classes = useStyles();

  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();

  const filterConfiguration = useViewFilters(viewFilters, viewDimensions);
  const viewHandler = getViewDefinitionHandler(viewDefinition) as TimelineViewDefinitionHandler;
  const resoledViewDefinition = viewHandler.getDefinition(store, viewDimensions);
  const resolution = useDeepMemo(
    () => viewHandler.resolveView(store, { viewDimensions, parametersMapping, userId: loggedUserId, aclHandler, filterConfiguration }),
    [store.getSerial(), viewDimensions, parametersMapping, filterConfiguration]
  );
  const { filterKey } = viewFilters;

  const { generateList } = useFilterAndSort(
    filterKey,
    isResolutionError(resolution) ? [] : resolution.instances,
    isResolutionError(resolution) ? undefined : resolution.filterFunction,
    { getComparatorHandler: getFieldColumnComparator(store), initial: isResolutionError(resolution) ? undefined : resolution.defaultSort },
    undefined
  );
  if (isResolutionError(resolution)) {
    return <BlockViewError error={resolution.error} />;
  } else {
    return (
      <div className={classes.container}>
        <DndProvider manager={dndManager}>
          <ConceptTimeline
            filterKey={filterKey}
            generateList={generateList}
            conceptDefinitionId={resolution.conceptDefinitionId}
            view={resoledViewDefinition}
            readOnly={viewDefinition.readOnly || readOnly}
            navigationFilters={getViewNavigationFilters(store, viewDimensions, filterConfiguration, parametersMapping)}
          />
        </DndProvider>
      </div>
    );
  }
};

export default TimelineViewBlock;
