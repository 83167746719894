import type { ConceptDefinitionLibraryTableStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import {
  ConceptDefinitionLibraryTable,
  ConceptDefinitionLibraryTable_Rank,
  ConceptDefinitionLibraryTable_Role_ConceptDefinition,
  ConceptDefinitionLibraryTable_Role_Field,
  ConceptDefinitionLibraryTable_Width,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { ConceptDefinitionStoreObject, PathStep } from 'yooi-modules/modules/conceptModule';
import { getFieldDimensionOfModelType, InstanceReferenceType, PathStepType } from 'yooi-modules/modules/conceptModule';
import { Concept_FunctionalId, Concept_Name, ConceptDefinition_LibraryItemPerPage, ConceptDefinition_TableGroupBy } from 'yooi-modules/modules/conceptModule/ids';
import type { TableViewStoredDefinition, ViewSeries } from 'yooi-modules/modules/dashboardModule';
import { DimensionDisplayAxis, ViewType } from 'yooi-modules/modules/dashboardModule';
import { compareRank, extractAndCompareValue } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import { TableSortDirection } from '../../../../components/molecules/Table';
import i18n from '../../../../utils/i18n';
import type { CommonViewResolvedDefinition } from '../common/commonViewResolvedDefinition';
import type { DefaultConfig, DefaultConfigError, DefaultConfigurationFeatureResolvedDefinition } from '../common/defaultConfiguration/defaultConfigurationFeatureDefinition';
import { isDefaultConfigError } from '../common/defaultConfiguration/defaultConfigurationFeatureDefinition';
import { getDefaultConfig } from '../common/defaultConfiguration/viewWithDefaultConfigurationUtils';
import type { DimensionsDisplayOptionsFeatureResolvedDefinition } from '../common/dimensions/dimensionDisplayOptionsFeatureDefinition';
import {
  getViewDefinitionDefaultDimensionDisplayOption,
  getViewDefinitionDefaultDimensionsDisplayOptions,
  getViewDefinitionDimensionDisplayOption,
  getViewDefinitionDimensionsDisplayOptions,
} from '../common/dimensions/viewWithDimensionDisplayOptionsFeatureUtils';
import type { ExportViewResolvedDefinition } from '../common/exportViewResolvedDefinition';
import type { SeriesFeatureResolvedDefinition } from '../common/series/seriesFeatureDefinition';
import { getSeriesError, getViewDefinitionSeries, getViewDimensionsAsParameterDefinitions } from '../common/series/viewWithSeriesFeatureUtils';
import type { StackedSeriesFeatureResolvedDefinition } from '../common/stackedSeries/stackedSeriesFeatureDefinition';
import type { ViewDefinitionHandler } from '../viewDsl';
import { registerView } from '../viewDsl';
import TableViewBlock from './TableViewBlock';
import TableViewDefinitionOptions from './TableViewDefinitionOptions';
import TableViewExportButton from './TableViewExportButton';
import TableViewOptions from './TableViewOptions';
import type { TableViewResolution } from './tableViewResolution';
import { resolveTableView } from './tableViewResolution';
import { viewsFieldTableHasOption } from './tableViewUtils';
import TableViewWidget from './TableViewWidget';

export interface TableViewResolvedDefinition extends CommonViewResolvedDefinition,
  DimensionsDisplayOptionsFeatureResolvedDefinition,
  SeriesFeatureResolvedDefinition,
  DefaultConfigurationFeatureResolvedDefinition,
  StackedSeriesFeatureResolvedDefinition,
  ExportViewResolvedDefinition {
  type: ViewType.Table,
  defaultConfigOptions: DefaultConfig | DefaultConfigError,
  defaultGroupBy?: string,
  groupBy?: { dimensionIds?: string[], fields?: { id: string, label?: string, path: PathStep[] }[] },
  defaultSort?: { key: string, direction: TableSortDirection },
  numberOfItems?: number,
  openButtonColumn: boolean,
}

export type TableViewDefinitionHandler = ViewDefinitionHandler<TableViewStoredDefinition, TableViewResolvedDefinition, TableViewResolution>;

export const tableViewHandler = registerView<TableViewStoredDefinition, TableViewResolvedDefinition, TableViewResolution>({
  type: ViewType.Table,
  getLabel: () => i18n`Table`,
  icon: IconName.table_chart,
  optionType: 'line',
  withFilters: true,
  withExport: true,
  resolveView: (store, viewDefinition, { aclHandler, viewDimensions, parametersMapping, filterConfiguration, readOnly }) => (
    resolveTableView(store, aclHandler, viewDimensions, viewDefinition, parametersMapping, filterConfiguration, readOnly)
  ),
  renderBlock: (_, viewDefinition, { viewDimensions, viewFilters, layoutParametersMapping, readOnly }) => (
    <TableViewBlock
      viewDimensions={viewDimensions}
      viewFilters={viewFilters}
      viewDefinition={viewDefinition}
      parametersMapping={layoutParametersMapping}
      readOnly={readOnly}
    />
  ),
  renderWidget: (_, viewDefinition, { viewDimensions, viewFilters, parametersMapping, width, height, readOnly }) => (
    <TableViewWidget
      viewDimensions={viewDimensions}
      viewFilters={viewFilters}
      viewDefinition={viewDefinition}
      parametersMapping={parametersMapping}
      groupByDefault={{ groupById: viewDefinition.defaultGroupBy }}
      width={width}
      height={height}
      readOnly={readOnly}
    />
  ),
  hasOptions: (store, viewDefinition, { viewDimensions }) => viewsFieldTableHasOption(store, viewDimensions, viewDefinition),
  renderOptions: (_, viewDefinition, { widgetId, viewDimensions, viewFilters, includeLoader }) => (
    <TableViewOptions
      widgetId={widgetId}
      viewDimensions={viewDimensions}
      viewFilters={viewFilters}
      viewDefinition={viewDefinition}
      includeLoader={includeLoader}
    />
  ),
  renderExport: (_, viewDefinition, { widgetId, filterConfiguration, getViewResolution, viewDimensions, parameterDefinitions }) => (
    <TableViewExportButton
      widgetId={widgetId}
      viewDefinition={viewDefinition}
      viewDimensions={viewDimensions}
      filterConfiguration={filterConfiguration}
      parameterDefinitions={parameterDefinitions}
      getViewResolution={getViewResolution}
    />
  ),
  renderDefinitionOptions: (_, viewDefinition, { widgetName, viewDimensions, updateViewDefinition, isWidget, readOnly, parameterDefinitions }) => (
    <TableViewDefinitionOptions
      widgetName={widgetName}
      viewDimensions={viewDimensions}
      viewDefinition={viewDefinition}
      updateViewDefinition={updateViewDefinition}
      readOnly={readOnly}
      parameterDefinitions={parameterDefinitions}
      isWidget={isWidget}
    />
  ),
  getDefinitionErrors: (store, viewDefinition, viewDimensions, parameters) => {
    const error = getSeriesError(store, viewDefinition, [...parameters, ...getViewDimensionsAsParameterDefinitions(store, viewDimensions)], viewDefinition.type);
    return error !== undefined ? [error] : undefined;
  },
  resolveDefinition: (store, viewDefinition, viewDimensions) => {
    const label = viewDefinition.label !== undefined && viewDefinition.label !== '' ? viewDefinition.label : i18n`Table`;
    const defaultConfigOptions = getDefaultConfig(store, viewDefinition, viewDimensions);
    if (isDefaultConfigError(defaultConfigOptions) || !defaultConfigOptions.checked) {
      return {
        id: viewDefinition.id,
        type: viewDefinition.type,
        defaultConfigOptions,
        label,
        readOnly: viewDefinition.readOnly ?? false,
        getDimensionsDisplay: (displayedViewDimensions) => getViewDefinitionDimensionsDisplayOptions(viewDefinition, displayedViewDimensions),
        getDimensionDisplay: (displayedViewDimension) => getViewDefinitionDimensionDisplayOption(viewDefinition, displayedViewDimension),
        defaultGroupBy: viewDefinition.defaultGroupBy,
        groupBy: viewDefinition.groupBy,
        defaultSort: viewDefinition.defaultSort,
        numberOfItems: viewDefinition.numberOfItems,
        seriesAxis: viewDefinition.seriesAxis ?? DimensionDisplayAxis.y,
        series: getViewDefinitionSeries(viewDefinition),
        openButtonColumn: viewDimensions.length === 1 ? viewDefinition.openButtonColumn ?? false : false,
        export: Boolean(viewDefinition.export),
        exportTitle: viewDefinition.exportTitle,
      };
    } else {
      const { conceptDefinitionId, viewDimensionId } = defaultConfigOptions;
      const displayedFields = store.withAssociation(ConceptDefinitionLibraryTable)
        .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
        .list<ConceptDefinitionLibraryTableStoreObject>()
        .sort(extractAndCompareValue(({ object }) => object[ConceptDefinitionLibraryTable_Rank], compareRank))
        .map((conceptDefinitionLibraryTable) => {
          const columnFieldId = conceptDefinitionLibraryTable.role(ConceptDefinitionLibraryTable_Role_Field);
          return ({ fieldId: columnFieldId, dimensionId: getFieldDimensionOfModelType(store, columnFieldId, conceptDefinitionId) });
        })
        .filter((entry): entry is { fieldId: string, dimensionId: string } => Boolean(entry.dimensionId) && store.getObjectOrNull(entry.fieldId) !== null);
      const series: ViewSeries[] = displayedFields.map(({ fieldId }) => {
        const percentWidth = store.withAssociation(ConceptDefinitionLibraryTable)
          .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
          .withRole(ConceptDefinitionLibraryTable_Role_Field, fieldId)
          .getObject<ConceptDefinitionLibraryTableStoreObject>()[ConceptDefinitionLibraryTable_Width];
        return ({
          id: fieldId,
          path: [
            { type: PathStepType.dimension, conceptDefinitionId },
            { type: PathStepType.mapping, mapping: { id: viewDimensionId, type: InstanceReferenceType.parameter } },
            { type: PathStepType.field, fieldId },
          ],
          displayOptions: {
            width: percentWidth === undefined ? undefined : { type: 'percent', value: percentWidth },
          },
        });
      });

      const conceptDefinition = store.getObject<ConceptDefinitionStoreObject>(conceptDefinitionId);
      const hasFunctionalId = Boolean(
        store.withAssociation(ConceptDefinitionLibraryTable)
          .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
          .withRole(ConceptDefinitionLibraryTable_Role_Field, Concept_FunctionalId)
          .getObjectOrNull<ConceptDefinitionLibraryTableStoreObject>()
      );

      return {
        id: viewDefinition.id,
        type: viewDefinition.type,
        defaultConfigOptions,
        label,
        readOnly: viewDefinition.readOnly ?? false,
        getDimensionsDisplay: (displayedViewDimensions) => getViewDefinitionDefaultDimensionsDisplayOptions(displayedViewDimensions),
        getDimensionDisplay: (displayedViewDimension) => getViewDefinitionDefaultDimensionDisplayOption(displayedViewDimension),
        defaultSort: hasFunctionalId ? { key: Concept_FunctionalId, direction: TableSortDirection.desc } : { key: Concept_Name, direction: TableSortDirection.asc },
        defaultGroupBy: `${viewDimensionId}|${conceptDefinition[ConceptDefinition_TableGroupBy]}`,
        groupBy: { dimensionIds: viewDimensions.map(({ id }) => id) },
        numberOfItems: conceptDefinition[ConceptDefinition_LibraryItemPerPage],
        seriesAxis: DimensionDisplayAxis.y,
        series,
        openButtonColumn: viewDimensions.length === 1 ? viewDefinition.openButtonColumn ?? false : false,
        export: Boolean(viewDefinition.export),
        exportTitle: viewDefinition.exportTitle,
      };
    }
  },
  getInitialStoredDefinition: (id) => ({ type: ViewType.Table, id, numberOfItems: 20, export: false, exportTitle: undefined }),
});
