// Register setImmediate as function
import 'setimmediate';
import type { EventValidatorResult, OperationValidationTransaction, TimeseriesOperation, TimeseriesUpdate } from 'yooi-store';
import { ProcessedEventStatus } from 'yooi-store';
import type { ConcurrencyHandler } from './concurrencyHandler';

interface EventPublisher {
  publishOperation: (
    id: string | string[], properties: Record<string, unknown> | null | undefined, timeseries: Record<string, TimeseriesOperation> | undefined
  ) => (EventValidatorResult & { rollbackTimeseriesEvent: TimeseriesUpdate[] }),
}

interface PublisherOperationValidationTransaction {
  validateOperation: OperationValidationTransaction['validateOperation'],
  completeTransaction: () => (EventValidatorResult & { rollbackTimeseriesEvent: TimeseriesUpdate[] }),
}

interface CreateEventPublisher {
  (options: {
    handleOutgoingEvent: ConcurrencyHandler['handleOutgoingEvent'],
    createOperationValidationTransaction: () => PublisherOperationValidationTransaction,
  }): EventPublisher,
}

const createEventPublisher: CreateEventPublisher = ({ handleOutgoingEvent, createOperationValidationTransaction }) => {
  let operationValidationTransaction: PublisherOperationValidationTransaction | undefined;

  return {
    publishOperation: (id, properties, timeseries) => {
      if (!operationValidationTransaction) {
        operationValidationTransaction = createOperationValidationTransaction();
        // use setImmediate to queue a send action to be the next element to be executed by the event loop
        setImmediate(() => {
          if (!operationValidationTransaction) {
            // events publishing was aborted
            return;
          }

          const { event, rollbackEvent, rollbackTimeseriesEvent } = operationValidationTransaction.completeTransaction();

          operationValidationTransaction = undefined;

          // the UI must not be blocked by the request processing (optimistic UI) so don't await the handleOutgoingEvent call
          handleOutgoingEvent(event.filter(({ systemEvent }) => !systemEvent), rollbackEvent, rollbackTimeseriesEvent);
        });
      }
      if (operationValidationTransaction.validateOperation(id, properties, timeseries)) {
        return {
          status: ProcessedEventStatus.validated,
          event: [{ id, properties, timeseries }],
          audit: [],
          rollbackEvent: [],
          rollbackTimeseriesEvent: [],
          metadata: {},
        };
      } else {
        const result = operationValidationTransaction.completeTransaction();
        operationValidationTransaction = undefined;
        return result;
      }
    },
  };
};

export default createEventPublisher;
