import type { FunctionComponent } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { TableViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import useDeepMemo from '../../../../utils/useDeepMemo';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { useViewFilters } from '../../filter/useViewFilters';
import BlockViewError from '../common/BlockViewError';
import { getViewDefinitionHandler } from '../viewDsl';
import { isResolutionError } from '../viewResolutionUtils';
import TableView from './TableView';
import type { TableViewDefinitionHandler } from './tableViewHandler';

const useStyles = makeStyles((theme) => ({
  paddedContainer: {
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
    paddingLeft: spacingRem.s,
  },
}), 'tableViewBlock');

interface TableViewBlockProps {
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinition: TableViewStoredDefinition,
  parametersMapping: ParametersMapping,
  readOnly: boolean | undefined,
}

const TableViewBlock: FunctionComponent<TableViewBlockProps> = ({
  viewDimensions,
  viewFilters,
  viewDefinition,
  parametersMapping,
  readOnly,
}) => {
  const classes = useStyles();

  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();

  const { filterKey } = viewFilters;
  const filterConfiguration = useViewFilters(viewFilters, viewDimensions);

  const viewHandler = getViewDefinitionHandler(viewDefinition) as TableViewDefinitionHandler;
  const resolvedViewDefinition = viewHandler.getDefinition(store, viewDimensions);
  const tableViewResolution = useDeepMemo(
    () => viewHandler.resolveView(store, {
      aclHandler,
      viewDimensions,
      parametersMapping,
      userId: loggedUserId,
      filterConfiguration,
      readOnly,
    }),
    [store.getSerial(), viewDimensions, viewDefinition, parametersMapping, filterConfiguration]
  );

  if (isResolutionError(tableViewResolution)) {
    return <BlockViewError error={tableViewResolution.error} />;
  } else {
    return (
      <div className={classes.paddedContainer}>
        <TableView
          filterKey={filterKey}
          viewResolution={tableViewResolution}
          itemPerPage={resolvedViewDefinition.numberOfItems}
          viewDimensions={viewDimensions}
          groupByDefault={{ groupById: resolvedViewDefinition.defaultGroupBy }}
          parametersMapping={parametersMapping}
          dimensionsDisplay={resolvedViewDefinition.getDimensionsDisplay(viewDimensions)}
          readOnly={resolvedViewDefinition.readOnly || readOnly}
        />
      </div>
    );
  }
};

export default TableViewBlock;
