import type {
  WorkflowEntryRaw,
  WorkflowEntryStoreObject,
  WorkflowRaw,
  WorkflowStoreObject,
  WorkflowTransitionRaw,
  WorkflowTransitionStoreObject,
} from 'yooi-modules/modules/conceptModule';
import {
  Workflow,
  Workflow_Description,
  Workflow_Name,
  Workflow_TargetedConceptDefinition,
  Workflow_Transitions,
  WorkflowEntry,
  WorkflowEntry_Rank,
  WorkflowEntry_Role_Concept,
  WorkflowEntry_Role_Workflow,
  WorkflowTransition,
  WorkflowTransition_From,
  WorkflowTransition_Name,
  WorkflowTransition_Owner,
  WorkflowTransition_To,
} from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStore } from 'yooi-store';
import { formatOrUndef } from '../../../utils/stringUtils';

export const duplicateWorkflow = (store: ObjectStore, workflowToDuplicateId: string): string => {
  const workflowToDuplicate = store.getObject<WorkflowStoreObject>(workflowToDuplicateId);

  const duplicatedWorkflowId = store.createObject<WorkflowRaw>({
    [Instance_Of]: Workflow,
    [Workflow_Name]: `${formatOrUndef(workflowToDuplicate[Workflow_Name])} (copy)`,
    [Workflow_Description]: workflowToDuplicate[Workflow_Description],
    [Workflow_TargetedConceptDefinition]: workflowToDuplicate[Workflow_TargetedConceptDefinition],
  });

  store.withAssociation(WorkflowEntry)
    .withRole(WorkflowEntry_Role_Workflow, workflowToDuplicateId)
    .list<WorkflowEntryStoreObject>()
    .forEach((entry) => {
      store.withAssociation(WorkflowEntry)
        .withRole(WorkflowEntry_Role_Workflow, duplicatedWorkflowId)
        .withRole(WorkflowEntry_Role_Concept, entry.role(WorkflowEntry_Role_Concept))
        .updateObject<WorkflowEntryRaw>({ [WorkflowEntry_Rank]: entry.object[WorkflowEntry_Rank] });
    });

  workflowToDuplicate.navigateBack<WorkflowTransitionStoreObject>(Workflow_Transitions)
    .forEach((transitionToDuplicate) => {
      store.createObject<WorkflowTransitionRaw>({
        [Instance_Of]: WorkflowTransition,
        [WorkflowTransition_Name]: transitionToDuplicate[WorkflowTransition_Name],
        [WorkflowTransition_Owner]: duplicatedWorkflowId,
        [WorkflowTransition_From]: transitionToDuplicate[WorkflowTransition_From],
        [WorkflowTransition_To]: transitionToDuplicate[WorkflowTransition_To],
      });
    });

  return duplicatedWorkflowId;
};
