import type { Property } from 'csstype';
import type { FunctionComponent } from 'react';
import makeStyles from '../../utils/makeStyles';
import useDerivedState from '../../utils/useDerivedState';
import { bondedValue, computeMaxSize } from './imageUtils';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  window: {
    position: 'relative',
    overflow: 'hidden',
  },
  image: {
    position: 'absolute',
  },
}, 'image');

interface ImageProps {
  aspectRatio: { x: number, y: number },
  imageUrl: string,
  position: { top: number, left: number, scale: number } | undefined,
  maxHeightRem?: number,
  cursor?: Property.Cursor,
  onClick?: () => void,
}

const Image: FunctionComponent<ImageProps> = ({ aspectRatio, imageUrl, position, maxHeightRem, cursor, onClick }) => {
  const classes = useStyles();

  const [imageBounds, setImageBounds] = useDerivedState<Record<'naturalWidth' | 'naturalHeight' | 'width' | 'height', number> | undefined>(() => undefined, [imageUrl]);
  let imagePosition: Record<'top' | 'left' | 'scale', number> | undefined;
  if (imageBounds) {
    const minWidthScale = aspectRatio.x / imageBounds.naturalWidth;
    const minHeightScale = aspectRatio.y / imageBounds.naturalHeight;
    const fitScale = minWidthScale > minHeightScale ? minWidthScale : minHeightScale;
    const minScale = Math.min(minHeightScale, minWidthScale);

    const scale = bondedValue(position?.scale ?? fitScale, minScale, Number.MAX_SAFE_INTEGER);
    const { minLeft, minTop, maxLeft, maxTop } = computeMaxSize(imageBounds, aspectRatio, scale);
    imagePosition = {
      left: bondedValue(position?.left ?? (minLeft + ((maxLeft - minLeft) / 2)), minLeft, maxLeft),
      top: bondedValue(position?.top ?? (minTop + ((maxTop - minTop) / 2)), minTop, maxTop),
      scale,
    };
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={classes.container}
      style={{ maxWidth: maxHeightRem ? `${(maxHeightRem * aspectRatio.x) / aspectRatio.y}rem` : undefined, cursor }}
      onClick={onClick}
    >
      <div
        className={classes.window}
        style={{ paddingBottom: `${((aspectRatio.y / aspectRatio.x) * 100).toFixed(1)}%` }}
      >
        <img
          src={imageUrl}
          className={classes.image}
          style={{
            visibility: imagePosition === undefined || imageBounds === undefined ? 'hidden' : undefined,
            width: imagePosition !== undefined && imageBounds !== undefined ? `${(imageBounds.width / aspectRatio.x) * imagePosition.scale * 100}%` : undefined,
            height: imagePosition !== undefined && imageBounds !== undefined ? `${(imageBounds.height / aspectRatio.y) * imagePosition.scale * 100}%` : undefined,
            top: imagePosition !== undefined && imageBounds !== undefined ? `${(imagePosition.top) * 100}%` : undefined,
            left: imagePosition !== undefined && imageBounds !== undefined ? `${(imagePosition.left) * 100}%` : undefined,
          }}
          onLoad={(event) => {
            const { naturalWidth, naturalHeight, width, height } = event.currentTarget;
            setImageBounds({ naturalWidth, naturalHeight, width, height });
          }}
          draggable={false}
          alt=""
        />

      </div>
    </div>
  );
};

export default Image;
