import type { FunctionComponent } from 'react';
import BaseLayout from '../../../components/templates/BaseLayout';
import Header from '../../../components/templates/Header';
import i18n from '../../../utils/i18n';
import HeaderStatic from '../../_global/HeaderStatic';
import TopBar from '../../_global/topBar/TopBar';
import PersonalNotificationRuleList from './PersonalNotificationRuleList';

const UserNotificationSettings: FunctionComponent = () => (
  <BaseLayout
    topBar={(<TopBar />)}
    header={(<Header firstLine={(<HeaderStatic text={i18n`Notification`} />)} />)}
    content={(<PersonalNotificationRuleList />)}
  />
);

export default UserNotificationSettings;
