import type { ReactElement, ReactNode } from 'react';
import { Children, cloneElement, useCallback, useContext, useLayoutEffect, useRef } from 'react';
import { joinObjects, newError } from 'yooi-utils';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import type { ArcherContainerContextType } from './ArcherContainerContext';
import { ArcherContainerContext } from './ArcherContainerContext';
import type { RelationType } from './types';

interface ArcherElementProps {
  /** The id that will identify the Archer Element. */
  ids: string[],
  relations?: Array<RelationType>,
  children: ReactElement,
}

const assertContextExists = (context: ArcherContainerContextType | null): context is ArcherContainerContextType => {
  if (!context) {
    throw newError('Could not find ArcherContainerContext in <ArcherElement>. Please wrap the component in a <ArcherContainer>.');
  }
  return true;
};

const ArcherElement = ({ ids, relations = [], children }: ArcherElementProps): ReactNode => {
  const context = useContext(ArcherContainerContext);
  const ref = useRef<HTMLElement>();

  const registerTransitions = useCallback(
    (newRelations: Array<RelationType>) => {
      ids.forEach((id) => {
        if (assertContextExists(context)) {
          context.registerTransitions(id, newRelations.map(
            ({ targetId, sourceAnchor, targetAnchor, label, lineType, order = 0 }: RelationType) => ({
              source: { id, anchor: sourceAnchor },
              target: { id: targetId, anchor: targetAnchor },
              label,
              lineType,
              order,
            })
          ));
        }
      });
    },
    [context, ids]
  );

  const unregisterTransitions = useCallback(() => {
    ids.forEach((id) => {
      if (assertContextExists(context)) {
        context.unregisterTransitions(id);
      }
    });
  }, [context, ids]);

  const registerChild = useCallback(
    (newRef: HTMLElement | undefined) => {
      if (!newRef) {
        return;
      }
      ids.forEach((id) => {
        if (assertContextExists(context)) {
          context.registerChild(id, newRef);
        }
      });
    },
    [ids, context]
  );

  const unregisterChild = useCallback(() => {
    ids.forEach((id) => {
      if (assertContextExists(context)) {
        context.unregisterChild(id);
      }
    });
  }, [context, ids]);

  useLayoutEffect(() => {
    registerChild(ref.current);

    return () => unregisterChild();
  }, [registerChild, unregisterChild]);

  useDeepCompareEffect(() => {
    registerTransitions(relations);

    return () => unregisterTransitions();
  }, [registerTransitions, relations, unregisterTransitions]);

  // Check that we only have one child to ArcherElement
  Children.only(children);
  // Now, we'll render this child by getting its ref. The ref will be used to compute the element's position.
  // I'm pretty sure there's a cleaner way to get the ref of the child... feel free to suggest it!
  const child = children;
  return cloneElement(child, joinObjects(child.props, { ref }));
};

export default ArcherElement;
