import type { ObjectStoreWithTimeseries } from '../../StoreType';
import type { InitializedModule } from '../platformModule';
import type {
  OnObjectRegistrationHandler,
  PropertyFunctionRegistration,
  PropertyFunctionsWithTimeseriesRegistration,
  RegisterPropertyFunctionsWithTimeseries,
} from '../PlatformModulesTypes';

export interface PropertyFunctionWithTimeseriesLibrary {
  init: () => void,
  onTimeseriesStoreUpdate: () => void,
  onObjectRepositoryUpdate: (id: string | string[], properties: Record<string, unknown> | null) => void,
}

export const createPropertyFunctionWithTimeseriesLibrary = (modules: InitializedModule[], objectStore: ObjectStoreWithTimeseries): PropertyFunctionWithTimeseriesLibrary => {
  let propertyFunctionWithTimeseriesModuleExtensions: PropertyFunctionRegistration[] = [];

  let propertyFunctionsWithTimeseriesRegistrations: PropertyFunctionsWithTimeseriesRegistration = {};

  const onObjectWithTimeseries: OnObjectRegistrationHandler = (key) => ({
    register: (handler) => {
      propertyFunctionsWithTimeseriesRegistrations[key] = [...(propertyFunctionsWithTimeseriesRegistrations[key] ?? []), handler];
    },
  });

  return {
    init: () => {
      propertyFunctionsWithTimeseriesRegistrations = {};

      const { registerPropertyFunction, unregisterPropertyFunction } = objectStore;

      propertyFunctionWithTimeseriesModuleExtensions = modules
        .map((module) => module.registerPropertyFunctionsWithTimeseries)
        .filter((register): register is RegisterPropertyFunctionsWithTimeseries => Boolean(register))
        .map((register) => (
          register(
            objectStore,
            { onObject: onObjectWithTimeseries, registerPropertyFunction, unregisterPropertyFunction }
          )))
        .filter((value): value is PropertyFunctionRegistration => value !== null && value !== undefined);

      objectStore.forEachObject((object) => {
        const properties = object.asRawObject();
        propertyFunctionWithTimeseriesModuleExtensions.forEach((handler) => {
          handler(object.id, properties, propertyFunctionsWithTimeseriesRegistrations);
        });
      });
    },
    onTimeseriesStoreUpdate: () => {
      objectStore.invalidatePropertyCache();
    },
    onObjectRepositoryUpdate: (id, properties) => {
      objectStore.invalidatePropertyCache();
      propertyFunctionWithTimeseriesModuleExtensions.forEach((handler) => {
        handler(id, properties, propertyFunctionsWithTimeseriesRegistrations);
      });
    },
  };
};
