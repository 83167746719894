import type { FunctionComponent, ReactElement } from 'react';
import type { FieldDimensionStoreObject, FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { Field_FieldDimensions, FieldDimensionTypes, FieldDimensionTypes_Role_FieldDimension } from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { newError } from 'yooi-utils';
import Banner, { BannerVariant } from '../../../../../components/molecules/Banner';
import BlockContent from '../../../../../components/templates/BlockContent';
import VerticalBlock from '../../../../../components/templates/VerticalBlock';
import useStore from '../../../../../store/useStore';
import i18n from '../../../../../utils/i18n';
import { getFieldDefinitionHandler, getFieldHandler } from '../../../../_global/fields/FieldLibrary';
import type { FieldEditionOptionMode } from '../../../../_global/fields/FieldLibraryTypes';
import { FieldEditionVariant } from '../../../../_global/fields/FieldLibraryTypes';
import useStoreFieldEditionHandler from '../../../../_global/fields/useStoreFieldEditionHandler';
import InputOptionBlocks from '../../../../_global/InputOptionBlocks';

interface FieldOptionBlocksProps {
  conceptDefinitionId: string,
  fieldId: string,
  mode: FieldEditionOptionMode,
  headBlocks?: ReactElement | null,
}

const FieldOptionBlocks: FunctionComponent<FieldOptionBlocksProps> = ({ conceptDefinitionId, fieldId, mode, headBlocks }) => {
  const store = useStore();

  const field = store.getObject<FieldStoreObject>(fieldId);

  const fieldHandler = getFieldHandler(store, fieldId);
  if (!fieldHandler) {
    throw newError('Unsupported field type');
  }

  const editionHandler = useStoreFieldEditionHandler(fieldId, conceptDefinitionId);
  const sections = getFieldDefinitionHandler(store, field[Instance_Of])
    .getEditionOptions({
      mode,
      modelTypeId: conceptDefinitionId,
      isEdition: true,
      editionHandler,
      readOnly: false,
      variant: FieldEditionVariant.page,
    });

  return (
    <VerticalBlock key={fieldId}>
      {
        field.navigateBack<FieldDimensionStoreObject>(Field_FieldDimensions)
          .some(({ id }) => store.withAssociation(FieldDimensionTypes).withRole(FieldDimensionTypes_Role_FieldDimension, id).list().length > 1)
          ? (
            <BlockContent padded>
              <Banner variant={BannerVariant.warning} title={i18n`This field is shared between concepts, modifying it will modify all of them.`} />
            </BlockContent>
          ) : null
      }
      {headBlocks ?? null}
      <InputOptionBlocks sections={sections} />
    </VerticalBlock>
  );
};

export default FieldOptionBlocks;
