import type { FunctionComponent, ReactElement, RefCallback } from 'react';
import { createContext, useCallback, useMemo, useRef } from 'react';
import declareBus from '../../../../../utils/declareBus';

interface Context {
  hoveredArrowKey: string | undefined,
}

const HoveredArrowContext = createContext<Context | undefined>(undefined);

interface HoverArrowContextProviderProps {
  children: ReactElement | null,
}

export const HoverArrowContextProvider: FunctionComponent<HoverArrowContextProviderProps> = ({ children }) => {
  const hoverContext = useMemo<Context>(() => ({ hoveredArrowKey: undefined }), []);

  return (
    <HoveredArrowContext.Provider value={hoverContext}>
      {children}
    </HoveredArrowContext.Provider>
  );
};

const useHoveredArrowBus = declareBus<{ hoveredArrowKey?: string, unHoveredArrowKey?: string, reset?: boolean }>('hoveredArrow').useBus;

type TooltipListener = (hoveredArrowKey?: string, unHoveredArrowKey?: string, reset?: boolean) => void;

interface HoveredArrowManager {
  hoverArrow: (hoveredArrowKey: string) => void,
  unHoverArrow: (unHoveredArrowKey: string) => void,
  reset: () => void,
}

export const useHoveredArrow = (listener?: TooltipListener): HoveredArrowManager => {
  const sendEvent = useHoveredArrowBus(
    listener ? (event) => listener(event.hoveredArrowKey, event.unHoveredArrowKey) : undefined,
    undefined,
    undefined
  );

  return useMemo(() => ({
    hoverArrow: (hoveredArrowKey) => sendEvent({ hoveredArrowKey }),
    unHoverArrow: (unHoveredArrowKey) => sendEvent({ unHoveredArrowKey }),
    reset: () => sendEvent({ reset: true }),
  }), [sendEvent]);
};

const useOnHoverArrowRef = <Element extends SVGGElement = SVGGElement>(arrowKey: string, enabled: boolean): RefCallback<Element | null> => {
  const currentNodeRef = useRef<Element | null>(null);
  const hoveredArrow = useHoveredArrow();

  const onMouseOver = useCallback((event: MouseEvent) => {
    if (currentNodeRef.current !== null && arrowKey !== undefined && enabled) {
      hoveredArrow.hoverArrow(arrowKey);
      event.stopPropagation();
    }
  }, [arrowKey, enabled, hoveredArrow]);

  const onMouseOut = useCallback(() => {
    hoveredArrow.unHoverArrow(arrowKey);
  }, [arrowKey, hoveredArrow]);

  return useCallback((node) => {
    if (currentNodeRef.current) {
      currentNodeRef.current.removeEventListener('mouseover', onMouseOver);
      currentNodeRef.current.removeEventListener('mouseout', onMouseOut);
    }
    currentNodeRef.current = node;

    if (node) {
      node.addEventListener('mouseover', onMouseOver);
      node.addEventListener('mouseout', onMouseOut);
    }
  }, [onMouseOut, onMouseOver]);
};

export default useOnHoverArrowRef;
