import type { FunctionComponent } from 'react';
import type { IntegrationRaw, IntegrationStoreObject } from 'yooi-modules/modules/integrationModule';
import { Integration_Configuration, Integration_ConfigurationError, Integration_Description } from 'yooi-modules/modules/integrationModule/ids';
import { PeriodicityType } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import Typo from '../../../components/atoms/Typo';
import DateInput from '../../../components/inputs/datePickers/DateInput';
import Banner, { BannerVariant } from '../../../components/molecules/Banner';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import HorizontalBlock, { HorizontalBlockVariant } from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import useTheme from '../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../utils/useUsageContext';
import StoreTextInputField from '../../_global/input/StoreTextInputField';

interface IntegrationDetailConfigurationTabProps {
  integrationId: string,
  tokenData: { expirationDate: number | undefined } | undefined,
}

const IntegrationDetailConfigurationTab: FunctionComponent<IntegrationDetailConfigurationTabProps> = ({ integrationId, tokenData }) => {
  const theme = useTheme();

  const store = useStore();

  const integration = store.getObject<IntegrationStoreObject>(integrationId);

  return (
    <VerticalBlock>
      <VerticalBlock asBlockContent withSeparation>
        <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compact}>
          <BlockTitle title={i18n`Description`} iconName={IconName.subject} />
          <BlockContent>
            <StoreTextInputField
              initialValue={integration[Integration_Description]}
              onSubmit={(description) => store.updateObject<IntegrationRaw>(integrationId, { [Integration_Description]: description })}
              placeholder={i18n`Add description`}
            />
          </BlockContent>
        </HorizontalBlock>
        {tokenData && (
          <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compact}>
            <BlockTitle title={i18n`Expiration date`} iconName={IconName.schedule} />
            <BlockContent>
              {tokenData.expirationDate !== undefined ? (
                <DateInput
                  value={{ period: PeriodicityType.day, date: tokenData.expirationDate }}
                  onChange={() => {}}
                  onSubmit={() => {}}
                  onCancel={() => {}}
                  readOnly
                  error={(tokenData.expirationDate < Date.now()) ? i18n`The current token is expired` : undefined}
                />
              ) : (
                <Banner variant={BannerVariant.warning} title={i18n`The current token will never expire`} />
              )}

            </BlockContent>
          </HorizontalBlock>
        )}
      </VerticalBlock>
      <VerticalBlock asBlockContent>
        <BlockTitle title={i18n`Configuration`} />
        <BlockContent padded>
          <UsageContextProvider usageVariant={UsageVariant.inForm}>
            <StoreTextInputField
              minLine={4}
              fullWidth
              placeholder={i18n`Write JSON configuration...`}
              initialValue={integration[Integration_Configuration]}
              onSubmit={(newConfiguration) => {
                store.updateObject<IntegrationRaw>(integrationId, { [Integration_Configuration]: newConfiguration });
              }}
            />
          </UsageContextProvider>
        </BlockContent>
        {integration[Integration_ConfigurationError] ? (
          <BlockContent>
            <Typo color={theme.color.text.danger}>{integration[Integration_ConfigurationError]}</Typo>
          </BlockContent>
        ) : null}
      </VerticalBlock>
    </VerticalBlock>
  );
};

export default IntegrationDetailConfigurationTab;
