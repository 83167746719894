import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import { BlockFieldLayoutOption } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { KinshipRelationFieldRaw, KinshipRelationFieldStoreObject, SingleRelationFieldExportConfiguration } from 'yooi-modules/modules/conceptModule';
import { getEmbeddedByTypes, getInstanceLabel, kinshipRelationFieldHandler, ParsedDimensionType, parseDimensionMapping } from 'yooi-modules/modules/conceptModule';
import type { Field_Title } from 'yooi-modules/modules/conceptModule/ids';
import { EmbeddingField_ToType, Field_ApiAlias, Field_Documentation, Field_IsDocumentationInline } from 'yooi-modules/modules/conceptModule/ids';
import { compareString, comparing, joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import { TableSortDirection } from '../../../../components/molecules/Table';
import i18n from '../../../../utils/i18n';
import { getConceptDefinitionNameOrEntity } from '../../modelTypeUtils';
import { getBlockFieldLayoutOption, getDefaultDisplayOptions, getLayoutDisplayOption } from '../_global/blockFieldUtils';
import { getApiAliasEditionOption, getApiAliasInitialState, getDocumentationFieldEditionSection } from '../_global/editionHandlerUtils';
import SingleRelationExportConfiguration from '../_global/SingleRelationExportConfiguration';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import { FIELD_EDITION_DIMENSIONS, getFieldDimensionsEditionHandlerValue } from '../fieldDimensionUtils';
import type { FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, FieldComparatorHandler, GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode } from '../FieldLibraryTypes';
import KinshipRenderer from './KinshipRenderer';

interface KinshipRelationFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
}

type KinshipRelationFieldDefinition = GetFieldDefinitionHandler<
  typeof kinshipRelationFieldHandler,
  KinshipRelationFieldConfigurationState,
  never,
  FieldBlockDisplayOptions,
  SingleRelationFieldExportConfiguration
>;

export const kinshipRelationFieldDefinition: KinshipRelationFieldDefinition = registerFieldDefinition(kinshipRelationFieldHandler, {
  configuration: {
    typeIcon: IconName.spoke,
    getTypeLabel: () => i18n`Embedded by`,
    asWidget: false,
    getFieldTitle: (objectStore) => (_, modelTypeId) => {
      const embeddedByTypes = getEmbeddedByTypes(objectStore, modelTypeId)
        .map((typeId) => getConceptDefinitionNameOrEntity(objectStore, typeId));
      return embeddedByTypes.length > 0 ? i18n`Embedded by (${embeddedByTypes.join(', ')})` : i18n`Embedded by (none)`;
    },
    getEditionOptions: (objectStore) => ({ mode, editionHandler }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      sections.push(getDocumentationFieldEditionSection(editionHandler));

      if (mode === FieldEditionOptionMode.FieldDeveloperMode) {
        sections.push({
          key: 'integration',
          type: 'section',
          title: i18n`Integration`,
          options: [getApiAliasEditionOption(objectStore, editionHandler)],
        });
      }

      return sections;
    },
    getTargetFields: (objectStore) => (fromType: string) => objectStore
      .getObject(fromType)
      .navigateBack(EmbeddingField_ToType) ?? [],
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<KinshipRelationFieldStoreObject>(fieldId);
        return joinObjects(
          getApiAliasInitialState(objectStore, fieldId),
          {
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
          }
        );
      },
      submitFieldUpdate: (stateToSubmit) => {
        objectStore.updateObject<KinshipRelationFieldRaw>(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
        });
      },
    }),
  },
  renderField: (store, fieldId) => ({ dimensionsMapping, focusOnMount }) => {
    const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
    if (parsedDimensionMapping.type !== ParsedDimensionType.MonoDimensional) {
      return null;
    }

    const concept = store.getObject(parsedDimensionMapping.objectId);
    if (concept[fieldId] && concept[concept[fieldId] as string]) {
      return (
        <KinshipRenderer
          fieldId={concept[fieldId] as string}
          conceptId={parsedDimensionMapping.objectId}
          focusOnMount={focusOnMount}
          readOnly
        />
      );
    } else {
      return null;
    }
  },
  renderExportConfiguration: () => ({ configuration, conceptDefinitionId, onChange }) => (
    <SingleRelationExportConfiguration configuration={configuration} conceptDefinitionId={conceptDefinitionId} onChange={onChange} />
  ),
  getActivityProperties: (_, fieldId) => () => [fieldId],
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({
    propertyId: fieldId,
    sortable: true,
    focusable: true,
  }),
  getComparatorHandler: (store, _, { getValueResolution }) => (direction) => ({
    comparator: comparing(compareString, direction === TableSortDirection.desc),
    extractValue: (dimensionsMapping) => {
      const { value } = getValueResolution(dimensionsMapping);
      return value ? getInstanceLabel(store, value) : undefined;
    },
  } satisfies FieldComparatorHandler<string | undefined>),
  blockDisplayOptionsHandler: (objectStore) => (fieldBlockDisplayId) => ({
    getDisplayOptions: () => getDefaultDisplayOptions(objectStore, fieldBlockDisplayId),
    renderSummary: ({ layoutDisplayType }) => ([getBlockFieldLayoutOption()[layoutDisplayType ?? BlockFieldLayoutOption.auto].label]),
    getBlockEditionOptionSections: (state, setState) => [getLayoutDisplayOption(state, setState)],
    onSubmit: (state) => {
      objectStore.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
});
