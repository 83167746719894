import { v4 as uuid } from 'uuid';
import type {
  InitialStatePropertyValidationHandler,
  ModuleRegistration,
  ObjectStoreReadOnly,
  ObjectStoreWithTimeseries,
  RegisterAccessControlList,
  RegisterBusinessRules,
  RegisterPropertyFunctions,
  RegisterPropertyFunctionsWithTimeseries,
} from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import { joinObjects, newError } from 'yooi-utils';
import { createDebugInitializer } from '../../debugModule';
import {
  Association_RoleTypes,
  Association_VarRolesType,
  Class_Extend,
  Class_IsExternal,
  Class_IsInstanceOf,
  Instance_Of,
  ModelAssociation,
  ModelProperty,
  ModelType,
  Property_OfClass,
  Property_TargetClass,
  TypeModuleId,
} from '../../typeModule/ids';
import { CommonAsType } from '../fields/commonPropertyType';
import type { PropertyAs } from '../fields/FieldModuleDslType';
import type {
  AccessControlListRegistrations,
  AssociationDsl,
  AssociationRole,
  BusinessRuleRegistration,
  DynamicBusinessRuleRegistration,
  MandatoryProperty,
  ModuleDsl,
  ObjectDebugLabelRegistration,
  PropertyGetDefaultValueFunction,
  TypeDsl,
} from './TypeModuleDslType';
import { PropertyMandatoryType } from './TypeModuleDslType';

export const dslConfig = {
  autoGenerateId: false,
};

export enum ConstantType {
  type = 'type',
  association = 'association',
  property = 'property',
  propertyFunction = 'propertyFunction',
  relation = 'relation',
  instance = 'instance',
}

interface ConstantRegistration {
  type: ConstantType,
  id: string,
  label: string,
}

interface TypeConstantRegistration extends ConstantRegistration {
  type: ConstantType.type,
}

interface AssociationConstantRegistration extends ConstantRegistration {
  type: ConstantType.association,
  roles: AssociationRole[],
  varRoles?: AssociationRole,
}

interface PropertyConstantRegistration extends ConstantRegistration {
  type: ConstantType.property,
  typeId: string,
}

interface PropertyFunctionConstantRegistration extends ConstantRegistration {
  type: ConstantType.propertyFunction,
  typeId?: string,
}

interface RelationConstantRegistration extends ConstantRegistration {
  type: ConstantType.relation,
  typeId: string,
  reverseLabel: string,
  targetTypeId: string,
}

interface InstanceConstantRegistration extends ConstantRegistration {
  type: ConstantType.instance,
}

type Constant =
  TypeConstantRegistration
  | AssociationConstantRegistration
  | PropertyConstantRegistration
  | PropertyFunctionConstantRegistration
  | RelationConstantRegistration
  | InstanceConstantRegistration;

interface PropertyRegistration {
  label: string,
  as: PropertyAs,
  mandatory?: MandatoryProperty,
}

interface RelationRegistration {
  label: string,
  mandatory?: MandatoryProperty,
}

export interface TypeModuleRegistration {
  id: string,
  label: string,
  constants: Constant[],
  types: Record<string, { label: string, extendsId: string | undefined, properties: PropertyRegistration[], relations: RelationRegistration[] }>,
  associations: Record<string, { label: string, properties: PropertyRegistration[], relations: RelationRegistration[] }>,
  extensions: Record<string, { properties: PropertyRegistration[], relations: RelationRegistration[] }>,
}

const typeModuleConstants: TypeModuleRegistration['constants'] = [
  { type: ConstantType.type, id: ModelType, label: 'ModelType' },
  { type: ConstantType.type, id: ModelProperty, label: 'ModelProperty' },
  { type: ConstantType.type, id: ModelAssociation, label: 'ModelAssociation' },
  { type: ConstantType.property, id: Association_RoleTypes, label: 'Association_RoleTypes', typeId: ModelAssociation },
  { type: ConstantType.property, id: Association_VarRolesType, label: 'Association_VarRolesType', typeId: ModelAssociation },
  { type: ConstantType.property, id: Class_IsExternal, label: 'Class_IsExternal', typeId: ModelType },
  { type: ConstantType.relation, id: Instance_Of, label: 'Instance_Of', typeId: ModelType, targetTypeId: ModelType, reverseLabel: 'Class_Instances' },
  { type: ConstantType.relation, id: Class_Extend, label: 'Class_Extend', typeId: ModelType, targetTypeId: ModelType, reverseLabel: 'Class_Extensions' },
  { type: ConstantType.relation, id: Property_OfClass, label: 'Property_OfClass', typeId: ModelProperty, targetTypeId: ModelType, reverseLabel: 'Class_Properties' },
  {
    type: ConstantType.relation,
    id: Property_TargetClass,
    label: 'Property_TargetClass',
    typeId: ModelProperty,
    targetTypeId: ModelType,
    reverseLabel: 'Class_TargetedByProperties',
  },
  { type: ConstantType.propertyFunction, id: Class_IsInstanceOf, label: 'Class_IsInstanceOf', typeId: ModelType },
];

// Register DSL ids in DebugInitializer
const typeModuleDebug = createDebugInitializer('TypeModule', TypeModuleId);
typeModuleConstants.forEach(({ id, label }) => typeModuleDebug.id(label, undefined, id));

export const modules: TypeModuleRegistration[] = [
  {
    id: TypeModuleId,
    label: 'Type',
    constants: typeModuleConstants,
    types: {
      [ModelType]: {
        extendsId: undefined,
        label: 'ModelType',
        properties: [
          { label: 'Class_IsExternal', as: CommonAsType.boolean },
        ],
        relations: [
          { label: 'Class_Extend' },
        ],
      },
      [ModelProperty]: {
        extendsId: undefined,
        label: 'ModelProperty',
        properties: [],
        relations: [{ label: 'Property_OfClass' }],
      },
      [ModelAssociation]: {
        extendsId: undefined,
        label: 'ModelAssociation',
        properties: [
          { label: 'Association_RoleTypes', as: CommonAsType.string, mandatory: { type: PropertyMandatoryType.mandatory } },
          { label: 'Association_VarRolesType', as: CommonAsType.string },
        ],
        relations: [],
      },
    },
    associations: {},
    extensions: {},
  },
];

const mandatoryPropertyBusinessRule = (propertyId: string): BusinessRuleRegistration => ({ getObjectOrNull }) => (_, { id, properties }) => {
  if (properties === undefined || properties === null) {
    return undefined;
  }
  const object = getObjectOrNull(id);
  if (object && properties[propertyId] === null) {
    // Object is existing, prevent clear
    return { rule: `property.${propertyId}.mandatory.missing`, status: ValidationStatus.REJECTED, propertyIds: [propertyId] };
  } else if (!object && (properties[propertyId] === null || properties[propertyId] === undefined)) {
    // Creating the object, make sure the property exists
    return { rule: `property.${propertyId}.mandatory.missing`, status: ValidationStatus.REJECTED, propertyIds: [propertyId] };
  } else {
    return undefined;
  }
};

const mandatoryWithDefaultValuePropertyBusinessRule = (
  propertyId: string,
  getDefaultValue: PropertyGetDefaultValueFunction
): BusinessRuleRegistration => ({ getObjectOrNull }) => (_, { id, properties }) => {
  if (properties === null || !properties) {
    return undefined;
  }
  if (properties[propertyId] === null) {
    // Object is existing, prevent clear
    return { rule: `property.${propertyId}.mandatoryWithDefaultValue.cannotBeDeleted`, status: ValidationStatus.REJECTED, propertyIds: [propertyId] };
  }
  if (properties[propertyId] !== undefined) {
    return undefined;
  }
  const object = getObjectOrNull(id);
  if (!object) {
    // Creating the object, initialize the property
    return {
      rule: `property.${propertyId}.mandatory.initializing`,
      status: ValidationStatus.ACCEPTED,
      generateSystemEvent: (objectStore) => {
        objectStore.updateObject(id, { [propertyId]: getDefaultValue(objectStore, id) });
      },
      propertyIds: [propertyId],
    };
  } else {
    return undefined;
  }
};

export const createModule = ({ label: moduleLabel }: { label: string }, ids: Record<string, unknown>): ModuleDsl => {
  const existingIds: Record<string, string> = {};
  Object.entries(ids).forEach(([label, id]) => {
    if (typeof id === 'string') {
      existingIds[label] = id;
    }
  });

  const getId = (label: string) => {
    if (existingIds[label]) {
      return existingIds[label];
    } else if (dslConfig.autoGenerateId) {
      return uuid();
    } else {
      throw newError('Missing id for label', { label });
    }
  };

  const { id: generateId, moduleId } = createDebugInitializer(moduleLabel, getId(`${moduleLabel}ModuleId`));

  const typeBusinessRules: Record<string, BusinessRuleRegistration[]> = {};
  const typeDynamicBusinessRules: Record<string, DynamicBusinessRuleRegistration[]> = {};
  const propertiesBusinessRules: Record<string, BusinessRuleRegistration[]> = {};
  const registerCustomBusinessRules: RegisterBusinessRules[] = [];
  const registerCustomPropertyFunctions: RegisterPropertyFunctions[] = [];
  const registerCustomPropertyFunctionsWithTimeseries: RegisterPropertyFunctionsWithTimeseries[] = [];

  const typeObjectDebugLabel: Record<string, ObjectDebugLabelRegistration> = {};

  const typeAccessControlList: Record<string, AccessControlListRegistrations> = {};
  const registerCustomAccessControlList: RegisterAccessControlList[] = [];

  const propertiesInitialStateValidationHandler: Record<string, InitialStatePropertyValidationHandler> = {};

  const propertiesFunctionsInitialStateHandler: Record<string, (objectStore: ObjectStoreReadOnly) => (id: string[]) => unknown> = {};
  const propertiesFunctionsWithTimeseriesInitialStateHandler: Record<string, (objectStore: ObjectStoreWithTimeseries) => (id: string[]) => unknown> = {};

  const initializationState: { id: string | string[], properties: Record<string, unknown> }[] = [];

  const module: TypeModuleRegistration = {
    id: moduleId,
    label: moduleLabel,
    constants: [],
    types: {},
    associations: {},
    extensions: {},
  };
  modules.push(module);

  const property: ModuleDsl['property'] = ({
    typeId,
    label,
    as,
    extraProperties = {},
    businessRules = [],
    initialStateValidationHandler,
    mandatory,
  }) => {
    if (!typeId) {
      throw newError('Property is missing mandatory typeId');
    }
    if (!label) {
      throw newError('Property is missing mandatory label');
    }

    const propertyId = generateId(label, undefined, getId(label));
    module.constants.push({ type: ConstantType.property, id: propertyId, label, typeId });

    if (businessRules.length > 0) {
      propertiesBusinessRules[propertyId] = businessRules;
    }
    if (mandatory?.type === PropertyMandatoryType.mandatory) {
      if (typeBusinessRules[typeId]) {
        typeBusinessRules[typeId].push(mandatoryPropertyBusinessRule(propertyId));
      } else {
        typeBusinessRules[typeId] = [mandatoryPropertyBusinessRule(propertyId)];
      }
    } else if (mandatory?.type === PropertyMandatoryType.mandatoryWithDefaultValue) {
      if (typeBusinessRules[typeId]) {
        typeBusinessRules[typeId].push(mandatoryWithDefaultValuePropertyBusinessRule(propertyId, mandatory.getDefaultValue));
      } else {
        typeBusinessRules[typeId] = [mandatoryWithDefaultValuePropertyBusinessRule(propertyId, mandatory.getDefaultValue)];
      }
    }
    if (initialStateValidationHandler) {
      propertiesInitialStateValidationHandler[propertyId] = initialStateValidationHandler;
    }

    initializationState.push({
      id: propertyId,
      properties: joinObjects(
        {
          [Instance_Of]: ModelProperty,
          [Property_OfClass]: typeId,
        },
        extraProperties
      ),
    });

    if (module.types[typeId]) {
      module.types[typeId].properties.push({
        label,
        as,
        mandatory,
      });
    } else if (module.associations[typeId]) {
      module.associations[typeId].properties.push({
        label,
        as,
        mandatory,
      });
    } else if (module.extensions[typeId]) {
      module.extensions[typeId].properties.push({
        label,
        as,
        mandatory,
      });
    } else {
      module.extensions[typeId] = {
        properties: [{
          label,
          as,
          mandatory,
        }],
        relations: [],
      };
    }
  };
  const propertyFunction: ModuleDsl['propertyFunction'] = ({ typeId, label, computeFunction }) => {
    if (!label) {
      throw newError('Property Function is missing mandatory label');
    }
    if (!computeFunction) {
      throw newError('Property Function is missing mandatory compute function');
    }

    const propertyFunctionId = generateId(label, undefined, getId(label));
    module.constants.push({ type: ConstantType.propertyFunction, id: propertyFunctionId, label, typeId });

    propertiesFunctionsInitialStateHandler[propertyFunctionId] = computeFunction;
  };

  const propertyFunctionWithTimeseries: ModuleDsl['propertyFunctionWithTimeseries'] = ({ typeId, label, computeFunction }) => {
    if (!label) {
      throw newError('Property Function is missing mandatory label');
    }
    if (!computeFunction) {
      throw newError('Property Function is missing mandatory compute function');
    }

    const propertyFunctionId = generateId(label, undefined, getId(label));
    module.constants.push({ type: ConstantType.propertyFunction, id: propertyFunctionId, label, typeId });

    propertiesFunctionsWithTimeseriesInitialStateHandler[propertyFunctionId] = computeFunction;
  };
  const relation: ModuleDsl['relation'] = ({
    typeId,
    label,
    targetTypeId,
    reverseLabel,
    extraProperties = {},
    businessRules = [],
    initialStateValidationHandler,
    mandatory,
  }) => {
    if (!typeId) {
      throw newError('Relation is missing mandatory typeId');
    }
    if (!label) {
      throw newError('Relation is missing mandatory label');
    }
    if (!targetTypeId) {
      throw newError('Relation is missing mandatory targetTypeId');
    }
    if (!reverseLabel) {
      throw newError('Relation is missing mandatory reverseLabel');
    }

    const relationId = generateId(label, undefined, getId(label));
    module.constants.push({ type: ConstantType.relation, id: relationId, label, reverseLabel, typeId, targetTypeId });

    if (businessRules.length > 0) {
      propertiesBusinessRules[relationId] = businessRules;
    }
    if (mandatory?.type === PropertyMandatoryType.mandatory) {
      if (typeBusinessRules[typeId]) {
        typeBusinessRules[typeId].push(mandatoryPropertyBusinessRule(relationId));
      } else {
        typeBusinessRules[typeId] = [mandatoryPropertyBusinessRule(relationId)];
      }
    } else if (mandatory?.type === PropertyMandatoryType.mandatoryWithDefaultValue) {
      if (typeBusinessRules[typeId]) {
        typeBusinessRules[typeId].push(mandatoryWithDefaultValuePropertyBusinessRule(relationId, mandatory.getDefaultValue));
      } else {
        typeBusinessRules[typeId] = [mandatoryWithDefaultValuePropertyBusinessRule(relationId, mandatory.getDefaultValue)];
      }
    }
    if (initialStateValidationHandler) {
      propertiesInitialStateValidationHandler[relationId] = initialStateValidationHandler;
    }

    initializationState.push({
      id: relationId,
      properties: joinObjects(
        {
          [Instance_Of]: ModelProperty,
          [Property_OfClass]: typeId,
          [Property_TargetClass]: targetTypeId,
        },
        extraProperties
      ),
    });

    if (module.types[typeId]) {
      module.types[typeId].relations.push({
        label,
        mandatory,
      });
    } else if (module.associations[typeId]) {
      module.associations[typeId].relations.push({
        label,
        mandatory,
      });
    } else if (module.extensions[typeId]) {
      module.extensions[typeId].relations.push({
        label,
        mandatory,
      });
    } else {
      module.extensions[typeId] = {
        properties: [],
        relations: [{
          label,
          mandatory,
        }],
      };
    }
  };
  const instance: ModuleDsl['instance'] = ({ typeId, label, extraProperties = {} }) => {
    if (!typeId) {
      throw newError('Instance is missing mandatory typeId');
    }
    if (!label) {
      throw newError('Instance is missing mandatory label');
    }

    const instanceId = generateId(label, undefined, getId(label));
    module.constants.push({ type: ConstantType.instance, id: instanceId, label });

    initializationState.push({
      id: instanceId,
      properties: joinObjects(
        { [Instance_Of]: typeId },
        extraProperties
      ),
    });
  };

  const typeResult = (typeId: string): TypeDsl => {
    const result: TypeDsl = {
      property: ({ label, as, extraProperties = {}, businessRules = [], initialStateValidationHandler, mandatory }) => {
        property({
          typeId,
          label,
          as,
          extraProperties,
          businessRules,
          initialStateValidationHandler,
          mandatory,
        });
        return result;
      },
      propertyFunction: ({ label, computeFunction }) => {
        propertyFunction({ typeId, label, computeFunction });
        return result;
      },
      propertyFunctionWithTimeseries: ({ label, computeFunction }) => {
        propertyFunctionWithTimeseries({ typeId, label, computeFunction });
        return result;
      },
      relation: ({
        label,
        targetTypeId,
        reverseLabel,
        extraProperties = {},
        businessRules = [],
        initialStateValidationHandler, mandatory,
      }) => {
        relation({
          typeId,
          label,
          targetTypeId,
          reverseLabel,
          extraProperties,
          businessRules,
          initialStateValidationHandler,
          mandatory,
        });
        return result;
      },
      instance: ({ label, extraProperties = {} }) => {
        instance({ typeId, label, extraProperties });
        return result;
      },
    };

    return result;
  };

  const associate: ModuleDsl['associate'] = ({ typeId, roleBuilder, extraProperties = {} }) => {
    if (!typeId) {
      throw newError('Associate is missing mandatory typeId');
    }
    if (!roleBuilder) {
      throw newError('Associate is missing mandatory roleBuilder');
    }

    const id = [typeId];
    const withRole = (roleId: number, objectId: string) => {
      id[roleId + 1] = objectId;
      return { withRole };
    };
    roleBuilder(withRole);

    if (id.some((i) => !i)) {
      throw newError('Associate is missing some roles');
    }

    initializationState.push({ id, properties: extraProperties });
  };

  const associationResult = (typeId: string): AssociationDsl => {
    const result: AssociationDsl = {
      property: ({ label, as, extraProperties = {}, businessRules = [], initialStateValidationHandler, mandatory }) => {
        property({
          typeId,
          label,
          as,
          extraProperties,
          businessRules,
          initialStateValidationHandler,
          mandatory,
        });
        return result;
      },
      relation: ({
        label,
        targetTypeId,
        reverseLabel,
        extraProperties = {},
        businessRules = [],
        initialStateValidationHandler,
        mandatory,
      }) => {
        relation({
          typeId,
          label,
          targetTypeId,
          reverseLabel,
          extraProperties,
          businessRules,
          initialStateValidationHandler,
          mandatory,
        });
        return result;
      },
      associate: ({ roleBuilder, extraProperties = {} }) => {
        associate({ typeId, roleBuilder, extraProperties });
        return result;
      },
    };

    return result;
  };

  return {
    id: moduleId,
    label: moduleLabel,
    getId,
    type: ({
      label,
      instanceOf = ModelType,
      extends: typeExtends,
      isExternal,
      extraProperties = {},
      businessRules = [],
      dynamicBusinessRules = [],
      objectDebugLabel,
      accessControlList,
    }) => {
      if (!label) {
        throw newError('Type is missing mandatory label');
      }

      const typeId = generateId(label, undefined, getId(label));
      module.types[typeId] = ({ extendsId: typeExtends, label, properties: [], relations: [] });
      module.constants.push({ type: ConstantType.type, label, id: typeId });

      initializationState.push({
        id: typeId,
        properties: joinObjects(
          {
            [Instance_Of]: instanceOf,
            [Class_Extend]: typeExtends,
            [Class_IsExternal]: isExternal,
          },
          extraProperties
        ),
      });

      if (businessRules.length > 0) {
        typeBusinessRules[typeId] = businessRules;
      }
      if (dynamicBusinessRules.length > 0) {
        typeDynamicBusinessRules[typeId] = dynamicBusinessRules;
      }
      if (objectDebugLabel) {
        typeObjectDebugLabel[typeId] = objectDebugLabel;
      }

      if (accessControlList) {
        typeAccessControlList[typeId] = accessControlList;
      }

      return typeResult(typeId);
    },
    property,
    propertyFunction,
    propertyFunctionWithTimeseries,
    relation,
    association: ({ label, roles, varRoles, extraProperties = {}, businessRules = [], objectDebugLabel, accessControlList }) => {
      if (!label) {
        throw newError('Association is missing mandatory label');
      }
      if (!roles || roles.length === 0) {
        throw newError('Association is missing mandatory roles');
      }
      if (roles.some((role) => !role.label || !role.targetTypeId)) {
        throw newError('Association has some misconfigured roles');
      }
      if (varRoles && (!varRoles.label || !varRoles.targetTypeId)) {
        throw newError('Association varRole is misconfigured');
      }

      const associationId = generateId(label, undefined, getId(label));
      module.associations[associationId] = ({ label, properties: [], relations: [] });
      module.constants.push({ type: ConstantType.association, id: associationId, label, roles, varRoles });

      if (businessRules.length > 0) {
        typeBusinessRules[associationId] = businessRules;
      }
      if (objectDebugLabel) {
        typeObjectDebugLabel[associationId] = objectDebugLabel;
      }

      if (accessControlList) {
        typeAccessControlList[associationId] = accessControlList;
      }

      initializationState.push({
        id: associationId,
        properties: joinObjects(
          {
            [Instance_Of]: ModelAssociation,
            [Association_RoleTypes]: roles.map(({ targetTypeId }) => targetTypeId).join('|'),
            [Association_VarRolesType]: varRoles?.targetTypeId,
          },
          extraProperties
        ),
      });
      return associationResult(associationId);
    },
    instance,
    associate,
    registerCustomBusinessRules: (register) => {
      registerCustomBusinessRules.push(register);
    },
    registerCustomAccessControlList: (register) => {
      registerCustomAccessControlList.push(register);
    },
    registerCustomPropertyFunction: (register) => {
      registerCustomPropertyFunctions.push(register);
    },
    registerCustomPropertyFunctionWithTimeseries: (register) => {
      registerCustomPropertyFunctionsWithTimeseries.push(register);
    },
    initTypedModule: (initModuleToWrap) => () => {
      const doRegisterBusinessRules: ModuleRegistration['registerBusinessRules'] = (objectStoreReadOnly, registrationHandler) => {
        const { onObject, onProperty } = registrationHandler;

        Object.entries(typeBusinessRules).forEach(([typeId, handlers]) => {
          handlers.forEach((handler) => onObject(typeId).validate(handler(objectStoreReadOnly)));
        });
        Object.entries(typeDynamicBusinessRules).forEach(([typeId, handlers]) => {
          handlers.forEach((handler) => onObject(typeId).register(handler(registrationHandler, objectStoreReadOnly)));
        });
        Object.entries(propertiesBusinessRules).forEach(([propertyId, handlers]) => {
          handlers.forEach((handler) => onProperty(propertyId).validate(handler(objectStoreReadOnly)));
        });

        registerCustomBusinessRules.forEach((register) => register(objectStoreReadOnly, registrationHandler));
      };

      const doRegisterAccessControlList: ModuleRegistration['registerAccessControlList'] = (objectStoreReadOnly, registrationHandler, isFeatureEnabled) => {
        const { onObject } = registrationHandler;

        Object.entries(typeAccessControlList).forEach(([typeId, handlerByAction]) => {
          Object.entries(handlerByAction).forEach(([action, handler]) => {
            onObject(typeId).allow(action, handler(objectStoreReadOnly, isFeatureEnabled));
          });
        });

        registerCustomAccessControlList.forEach((register) => register(objectStoreReadOnly, registrationHandler, isFeatureEnabled));
      };

      const doRegisterObjectDebugLabel: ModuleRegistration['registerObjectDebugLabel'] = (objectStoreReadOnly, { onObject }) => {
        Object.entries(typeObjectDebugLabel).forEach(([typeId, handler]) => {
          onObject(typeId).register(handler(objectStoreReadOnly));
        });
      };

      const doRegisterPropertyFunctions: ModuleRegistration['registerPropertyFunctions'] = (objectStore, registrationHandler) => {
        Object.entries(propertiesFunctionsInitialStateHandler).forEach(([propertyId, computeFunction]) => {
          registrationHandler.registerPropertyFunction(propertyId, computeFunction(objectStore));
        });

        registerCustomPropertyFunctions.forEach((register) => register(objectStore, registrationHandler));
      };

      const doRegisterPropertyFunctionsWithTimeseries: ModuleRegistration['registerPropertyFunctionsWithTimeseries'] = (objectStore, registrationHandler) => {
        Object.entries(propertiesFunctionsWithTimeseriesInitialStateHandler).forEach(([propertyId, computeFunction]) => {
          registrationHandler.registerPropertyFunction(propertyId, computeFunction(objectStore));
        });

        registerCustomPropertyFunctionsWithTimeseries.forEach((register) => register(objectStore, registrationHandler));
      };

      const doRegisterValidationHandlers: Exclude<ModuleRegistration['initializationState'], undefined>['registerValidationHandlers'] = ({ onProperty }) => {
        Object.entries(propertiesInitialStateValidationHandler).forEach(([propertyId, handler]) => {
          onProperty(propertyId).validate(handler);
        });
      };

      if (initModuleToWrap) {
        const moduleToWrap = initModuleToWrap();
        if (moduleToWrap.id !== moduleId) {
          throw newError('moduleToWrap and current module ids don\'t match', { moduleToWrapId: moduleToWrap.id, moduleId });
        }
        return {
          id: moduleId,
          registerBusinessRules: (objectStoreReadOnly, registerBusinessRules) => {
            moduleToWrap.registerBusinessRules?.(objectStoreReadOnly, registerBusinessRules);
            doRegisterBusinessRules(objectStoreReadOnly, registerBusinessRules);
          },
          registerGarbageCollectorRules: (objectStoreReadOnly, registerGarbageCollectorRules) => {
            moduleToWrap.registerGarbageCollectorRules?.(objectStoreReadOnly, registerGarbageCollectorRules);
          },
          registerAccessControlList: (objectStoreReadOnly, registerAccessControlList, isFeatureEnabled) => {
            doRegisterAccessControlList(objectStoreReadOnly, registerAccessControlList, isFeatureEnabled);
            return moduleToWrap.registerAccessControlList?.(objectStoreReadOnly, registerAccessControlList, isFeatureEnabled);
          },
          registerObjectDebugLabel: (objectStoreReadOnly, registerObjectDebugLabel) => {
            moduleToWrap.registerObjectDebugLabel?.(objectStoreReadOnly, registerObjectDebugLabel);
            doRegisterObjectDebugLabel?.(objectStoreReadOnly, registerObjectDebugLabel);
          },
          registerPropertyFunctions: (cacheObjectStore, registerPropertyFunctionRules) => {
            moduleToWrap?.registerPropertyFunctions?.(cacheObjectStore, registerPropertyFunctionRules);
            doRegisterPropertyFunctions?.(cacheObjectStore, registerPropertyFunctionRules);
          },
          registerPropertyFunctionsWithTimeseries: (cacheObjectStore, registerPropertyFunctionRules) => {
            moduleToWrap?.registerPropertyFunctionsWithTimeseries?.(cacheObjectStore, registerPropertyFunctionRules);
            doRegisterPropertyFunctionsWithTimeseries?.(cacheObjectStore, registerPropertyFunctionRules);
          },
          registerMetadataGenerators: moduleToWrap.registerMetadataGenerators,
          initializationState: {
            getState: () => [...(moduleToWrap.initializationState?.getState?.() ?? []), ...initializationState],
            registerValidationHandlers: (registrationHandler) => {
              moduleToWrap.initializationState?.registerValidationHandlers?.(registrationHandler);
              doRegisterValidationHandlers(registrationHandler);
            },
          },
          migrations: moduleToWrap.migrations,
          migrationHelpers: moduleToWrap.migrationHelpers,
        };
      } else {
        return {
          id: moduleId,
          registerBusinessRules: doRegisterBusinessRules,
          registerAccessControlList: doRegisterAccessControlList,
          registerObjectDebugLabel: doRegisterObjectDebugLabel,
          registerPropertyFunctions: doRegisterPropertyFunctions,
          registerPropertyFunctionsWithTimeseries: doRegisterPropertyFunctionsWithTimeseries,
          initializationState: {
            getState: () => initializationState,
            registerValidationHandlers: doRegisterValidationHandlers,
          },
        };
      }
    },
  };
};
