import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { Property } from 'csstype';
import type { JssStyle } from 'jss';
import type { FunctionComponent, KeyboardEventHandler, ReactElement, ReactNode } from 'react';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import type { Offset } from 'react-overlays/usePopper';
import { joinObjects } from 'yooi-utils';
import base from '../../theme/base';
import makeSelectorsClasses from '../../utils/makeSelectorsClasses';
import makeStyles from '../../utils/makeStyles';
import useDerivedState from '../../utils/useDerivedState';
import useFocusOnMount from '../../utils/useFocusOnMount';
import useHideOnDisappearRef from '../../utils/useHideOnDisappearRef';
import useSizeContext, { buildInputSizeVariantMinusBorderClasses } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import { useTooltip } from '../../utils/useTooltip';
import useUsageContext, { UsageVariant } from '../../utils/useUsageContext';
import Overlay from './Overlay';

export enum EditableCloseReasons {
  onEscapeKeyDown = 'onEscapeKeyDown',
  onBackdropClick = 'onBackdropClick',
  onTabKeyDown = 'onTabKeyDown',
  onEnterKeyDown = 'onEnterKeyDown',
}

export enum EditableButtonVariant {
  tertiary = 'tertiary',
  free = 'free',
}

export const editableSelectorsClasses = makeSelectorsClasses('focusedVisible', 'focusedFlex');

const useStyles = makeStyles((theme) => {
  const buildBorderStyle = ({ color, boxShadow, hoverColor, focusColor }: Record<string, Property.Color>): JssStyle => {
    const style: Record<string, unknown> = {
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: color,
      borderRadius: base.borderRadius.medium,
      boxShadow,
    };
    if (hoverColor) {
      style['&:hover'] = {
        borderColor: hoverColor,
      };
    }
    if (focusColor) {
      style['&:focus-within'] = {
        borderColor: focusColor,
      };
    }
    return style;
  };

  const buildOutlineStyle = ({ color, boxShadow, hoverColor, focusColor }: Record<string, Property.Color>): JssStyle => {
    const style: Record<string, unknown> = {
      outlineWidth: '0.1rem',
      outlineStyle: 'solid',
      outlineColor: color,
      boxShadow,
    };
    if (hoverColor) {
      style['&:hover'] = {
        outlineColor: hoverColor,
      };
    }
    if (focusColor) {
      style['&:focus-within'] = {
        outlineColor: focusColor,
      };
    }
    return style;
  };

  return joinObjects(
    buildInputSizeVariantMinusBorderClasses('valueContainer', ['minHeight']),
    {
      container: {
        overflow: 'hidden',
        [`&:hover .${editableSelectorsClasses.focusedVisible}, &:focus-within .${editableSelectorsClasses.focusedVisible}`]: {
          visibility: 'visible',
        },
        [`&:hover .${editableSelectorsClasses.focusedFlex}, &:focus-within .${editableSelectorsClasses.focusedFlex}`]: {
          display: 'flex',
        },
      },
      containerBackground: {
        background: theme.color.background.neutral.default,
      },
      containerHidden: {
        visibility: 'hidden',
      },
      containerInCell: {
        background: theme.color.transparent,
        width: '100%',
        height: '100%',
      },
      containerBorderEditing: buildBorderStyle({
        color: theme.color.border.dark,
      }),
      containerBorderSelected: buildBorderStyle({
        boxShadow: 'none',
        color: theme.color.border.dark,
      }),
      containerBorderForm: buildBorderStyle({
        color: theme.color.border.default,
        hoverColor: theme.color.border.hover,
        focusColor: theme.color.border.dark,
      }),
      containerBorderTertiary: buildBorderStyle({
        color: theme.color.transparent,
        hoverColor: theme.color.background.primarylight.default,
        focusColor: theme.color.background.primarylight.hover,
      }),
      containerBorderFree: buildBorderStyle({
        color: theme.color.transparent,
        hoverColor: theme.color.transparent,
        focusColor: theme.color.transparent,
      }),
      containerFormReadOnly: buildBorderStyle({ color: theme.color.border.default }),
      containerBorderMain: buildBorderStyle({
        color: theme.color.transparent,
        hoverColor: theme.color.border.hover,
        focusColor: theme.color.border.dark,
      }),
      containerBorderMainReadOnly: buildBorderStyle({ color: theme.color.transparent }),
      // since hover & focus border color are different this permit to minimize the border color flickering when opening the dropdown
      containerBorderForcedFocus: buildBorderStyle({ color: theme.color.border.dark }),
      containerBorderForcedFocusReadOnly: buildBorderStyle({ color: theme.color.transparent }),
      containerBorderless: {
        borderWidth: 0,
        borderRadius: 0,
      },
      containerOutlineEditing: buildOutlineStyle({ color: theme.color.border.dark }),
      containerOutline: buildOutlineStyle({
        color: theme.color.transparent,
        hoverColor: theme.color.border.hover,
        focusColor: theme.color.border.hover,
      }),
      containerOutlineMultiplayer: buildOutlineStyle({
        color: theme.color.border.primary,
        hoverColor: theme.color.border.primary,
        focusColor: theme.color.border.primary,
      }),
      dropdownContainer: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        [`& .${editableSelectorsClasses.focusedVisible}`]: {
          visibility: 'visible',
        },
        [`& .${editableSelectorsClasses.focusedFlex}`]: {
          display: 'flex',
        },
      },
      dropdownContainerBackground: {
        background: theme.color.background.neutral.default,
      },
      dropdownContainerBorder: buildBorderStyle({ color: theme.color.border.dark, boxShadow: base.shadowElevation.low }),
      dropdownContainerBorderReadOnly: buildBorderStyle({ color: theme.color.transparent }),
      dropdownContainerFormReadOnly: buildBorderStyle({ color: theme.color.border.default }),
      dropdownContainerOutline: buildOutlineStyle({ color: theme.color.border.dark }),
      dropdownContainerOutlineReadOnly: buildOutlineStyle({ color: theme.color.border.default }),
      dropdownContainerBorderless: {
        borderWidth: 0,
        borderRadius: 0,
      },
      valueContainer: {
        display: 'flex',
        flexShrink: 0,
        alignItems: 'center',
      },
      valueContainerInCell: {
        minHeight: '3.8rem',
      },
      valueContainerDropdownSeparator: {
        height: '0.1rem',
        backgroundColor: theme.color.border.default,
      },
    } as const
  );
}, 'editableWithDropdown');

interface EditableWithDropdownProps {
  variant?: UsageVariant,
  buttonVariant?: EditableButtonVariant,
  editableSizes?: {
    minWidth?: Property.MinWidth,
    width?: Property.Width,
    maxWidth?: Property.MaxWidth,
    minHeight?: Property.MinHeight,
    height?: Property.Height,
    maxHeight?: Property.MaxHeight,
    flexGrow?: Property.FlexGrow,
  },
  dropdownSizes?: {
    sameWidth?: boolean,
    minWidth?: Property.MinWidth,
    width?: Property.Width,
    maxWidth?: Property.MaxWidth,
    minHeight?: Property.MinHeight,
    height?: Property.Height,
    maxHeight?: Property.MaxHeight,
  },
  isEditing?: boolean,
  isSelected?: boolean,
  readOnly?: boolean,
  showDropdown: boolean,
  openDropdown: () => void,
  closeDropdown: (reason: EditableCloseReasons) => void,
  closeOnTabKeyDown?: boolean,
  validateOnEnterKeyDown?: boolean,
  autoFocus?: boolean,
  onDropdownKeyDown?: KeyboardEventHandler<HTMLElement>,
  renderValue: (inDropdown: boolean) => ReactNode | null,
  renderDropdown?: () => ReactNode | null,
  dropdownFixedWidth?: boolean,
  withMultiplayerOutline?: boolean,
  restingTooltip?: string | (() => Promise<string>),
  borderless?: boolean,
  offset?: Offset,
}

const EditableWithDropdown: FunctionComponent<EditableWithDropdownProps> = ({
  variant,
  buttonVariant,
  editableSizes = {},
  dropdownSizes = {},
  isEditing = false,
  isSelected = false,
  readOnly = false,
  showDropdown,
  openDropdown,
  closeDropdown,
  closeOnTabKeyDown = false,
  validateOnEnterKeyDown = false,
  autoFocus = false,
  onDropdownKeyDown,
  renderValue,
  renderDropdown,
  dropdownFixedWidth = false,
  withMultiplayerOutline = false,
  restingTooltip,
  borderless = false,
  offset,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const { sizeVariant } = useSizeContext();
  const contextUsageVariant = useUsageContext();
  const usageVariant = variant ?? contextUsageVariant;
  const [fixedDropdownContainerWidth, setFixedDropdownContainerWidth] = useState<number>();
  const [dropdownContainerVisible, setDropdownContainerVisible] = useDerivedState(() => false, [showDropdown]);

  const editableContainerRef = useRef<HTMLDivElement>(null);
  const dropdownContainerRef = useRef<HTMLDivElement>(null);
  useFocusOnMount(dropdownContainerRef, autoFocus && showDropdown);
  const { hideRef, monitorRef } = useHideOnDisappearRef<HTMLDivElement>(showDropdown);

  const { displayTooltip, hideTooltip } = useTooltip();

  const onOverlayVisible = useCallback(() => {
    setDropdownContainerVisible(true);
  }, [setDropdownContainerVisible]);

  // in order to avoid resize when dropdown is open, we keep the 'editable' rendering in this ref, until the dropdown is closed
  const renderValueAtDropdown = useRef<{ theme: string, rendered: ReactElement }>();

  // this permit to avoid the dropdown containers width to change during the dropdown is active
  useLayoutEffect(() => {
    if (showDropdown && dropdownFixedWidth) {
      setFixedDropdownContainerWidth((current) => current ?? Math.max(
        editableContainerRef.current?.getBoundingClientRect().width ?? 0,
        dropdownContainerRef.current?.getBoundingClientRect().width ?? 0
      ));
    } else {
      setFixedDropdownContainerWidth(undefined);
    }
  }, [showDropdown, dropdownFixedWidth]);

  // ensure that focus is set back when dropdown is closed
  const latestShowDropdown = useRef(showDropdown);
  useEffect(() => {
    if (!showDropdown && latestShowDropdown.current) {
      editableContainerRef.current?.focus({ preventScroll: true });
    }
    latestShowDropdown.current = showDropdown;
  }, [showDropdown]);

  const isFormVariant = usageVariant === UsageVariant.inForm;
  const isMainVariant = usageVariant === UsageVariant.inline || usageVariant === UsageVariant.inCard;
  const isTableVariant = usageVariant === UsageVariant.inTable;
  const isBorderVariant = isFormVariant || isMainVariant;
  const isOutlineVariant = isTableVariant;
  const isTertiaryVariant = buttonVariant === EditableButtonVariant.tertiary;
  const isFreeVariant = buttonVariant === EditableButtonVariant.free;
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (showDropdown && closeOnTabKeyDown && event.key === 'Tab' && !readOnly) {
      closeDropdown(EditableCloseReasons.onTabKeyDown);
    } else if (event.key === 'Enter') {
      if (!showDropdown && !readOnly) {
        event.preventDefault(); // prevent unexpected line break in textarea in dropdown container
        event.stopPropagation(); // prevent queryTable to (probably queryTable to should not handle Enter but provide a focus (tabindex) on the Open button)
        openDropdown();
      }
    }
  };

  const renderAndLayoutValue = (inDropdown: boolean) => (
    <div
      className={classnames({
        [classes.valueContainer]: true,
        [classes[`valueContainer_${sizeVariant}`]]: !isTableVariant,
        [classes.valueContainerInCell]: isTableVariant,
      })}
    >
      {renderValue(inDropdown)}
    </div>
  );

  const computeDropdownSizes = () => {
    if (dropdownSizes.sameWidth) {
      return { minWidth: dropdownSizes.minWidth };
    } else if (showDropdown && fixedDropdownContainerWidth != null) {
      return joinObjects(dropdownSizes, { width: fixedDropdownContainerWidth, maxWidth: undefined, minWidth: undefined });
    } else {
      return dropdownSizes;
    }
  };

  const doRenderDropdown = () => {
    if (renderDropdown) {
      const dropdown = renderDropdown();
      if (dropdown === null) {
        return null;
      } else {
        return (
          <>
            <div className={classes.valueContainerDropdownSeparator} />
            {dropdown}
          </>
        );
      }
    } else {
      return null;
    }
  };

  const renderEditableValue = () => {
    if (showDropdown) {
      // Because styles are destroyed in the element is re-rendered using a different theme
      // we also need to keep track of the theme to regenerate the cached value
      // in to have a valid 'hidden' element
      if (renderValueAtDropdown.current && renderValueAtDropdown.current.theme === theme.name) {
        return renderValueAtDropdown.current.rendered;
      }
      const rendered = renderAndLayoutValue(false);
      renderValueAtDropdown.current = { theme: theme.name, rendered };
      return rendered;
    } else {
      renderValueAtDropdown.current = undefined;
      return renderAndLayoutValue(false);
    }
  };

  return (
    <div
      ref={composeReactRefs<HTMLDivElement>(monitorRef, editableContainerRef)}
      style={editableSizes}
      className={classnames({
        [classes.container]: true,
        [classes.containerBackground]: showDropdown,
        [classes.containerHidden]: dropdownContainerVisible,
        [classes.containerInCell]: isTableVariant,
        [classes.containerBorderEditing]: isEditing,
        [classes.containerBorderSelected]: isSelected,
        [classes.containerBorderForm]: isFormVariant && !readOnly && !(showDropdown || isEditing || isSelected),
        [classes.containerBorderTertiary]: isTertiaryVariant && !readOnly && !(showDropdown || isEditing || isSelected),
        [classes.containerBorderFree]: isFreeVariant && !readOnly && !(showDropdown || isEditing || isSelected),
        [classes.containerFormReadOnly]: isFormVariant && readOnly && !(showDropdown || isEditing),
        [classes.containerBorderMain]: isMainVariant && !readOnly && !(showDropdown || isEditing),
        [classes.containerBorderMainReadOnly]: isMainVariant && readOnly && !(showDropdown || isEditing),
        [classes.containerBorderForcedFocus]: isBorderVariant && !readOnly && showDropdown,
        [classes.containerBorderForcedFocusReadOnly]: isBorderVariant && readOnly && showDropdown,
        [classes.containerOutline]: isOutlineVariant,
        [classes.containerOutlineMultiplayer]: isOutlineVariant && withMultiplayerOutline,
        [classes.containerBorderless]: borderless,
      })}
      tabIndex={0} // element is focusable in order to be edited
      onKeyDown={handleKeyDown}
      onClick={() => {
        if (!showDropdown) {
          openDropdown();
        }
      }}
      role="button"
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      onMouseOver={restingTooltip ? () => {
        if (editableContainerRef.current && !showDropdown) {
          displayTooltip({
            element: editableContainerRef.current,
            text: restingTooltip,
          });
        }
      } : undefined}
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      onMouseOut={hideTooltip}
    >
      {renderEditableValue()}
      {showDropdown ? (
        <Overlay
          target={editableContainerRef}
          sameWidth={dropdownSizes.sameWidth}
          onBackdropClick={() => {
            latestShowDropdown.current = false; // prevent the editable to steal the focus which have been captured by the backdrop click
            closeDropdown(EditableCloseReasons.onBackdropClick);
          }}
          onEscapeKeyDown={() => {
            closeDropdown(EditableCloseReasons.onEscapeKeyDown);
          }}
          onEnterKeyDown={validateOnEnterKeyDown ? () => {
            closeDropdown(EditableCloseReasons.onEnterKeyDown);
          } : undefined}
          onOverlayVisible={onOverlayVisible}
          // When using outline, we need to include a bottom margin to make sure the bottom is visible when in scroll container
          containerMarginBottom={isOutlineVariant ? '0.1rem' : undefined}
          offset={offset}
        >
          <div
            ref={composeReactRefs<HTMLDivElement>(dropdownContainerRef, hideRef)}
            tabIndex={autoFocus ? 0 : undefined}
            style={computeDropdownSizes()}
            className={classnames({
              [classes.dropdownContainer]: true,
              [classes.dropdownContainerBackground]: true,
              [classes.dropdownContainerBorder]: isBorderVariant && !readOnly,
              [classes.dropdownContainerBorderReadOnly]: isBorderVariant && readOnly,
              [classes.dropdownContainerFormReadOnly]: isFormVariant && readOnly,
              [classes.dropdownContainerOutline]: isOutlineVariant && !readOnly,
              [classes.dropdownContainerOutlineReadOnly]: isOutlineVariant && readOnly,
              [classes.dropdownContainerBorderless]: borderless,
            })}
            onKeyDown={onDropdownKeyDown}
            role="button"
          >
            {renderAndLayoutValue(true)}
            {doRenderDropdown()}
          </div>
        </Overlay>
      ) : null}
    </div>
  );
};

export default EditableWithDropdown;
