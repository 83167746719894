import classnames from 'classnames';
import type { Styles } from 'jss';
import type { FunctionComponent, ReactNode } from 'react';
import { useRef } from 'react';
import { joinObjects } from 'yooi-utils';
import type { Theme } from '../../theme/themeUtils';
import makeStyles from '../../utils/makeStyles';
import { OverlayContextProvider } from '../../utils/useOverlayContainerRef';

export enum RightPanelVariant {
  collaboration = 'collaboration',
  advice = 'advice',
}

const variantStyles = (theme: Theme): Styles<RightPanelVariant> => ({
  [RightPanelVariant.collaboration]: {
    borderLeftColor: theme.color.border.default,
    background: '#FFF',
    boxShadow: '-0.4rem 0 0 0.1rem rgba(0, 0, 0, 0.02)',
  },
  [RightPanelVariant.advice]: {
    borderLeftColor: theme.color.border.default,
  },
});

const useStyles = makeStyles((theme) => (joinObjects(
  {
    container: {
      overflowX: 'hidden',
      flexDirection: 'column',
      borderLeftWidth: '0.1rem',
      borderLeftStyle: 'solid',
      minWidth: '35rem',
      maxWidth: '35rem',
    },
    containerDisplay: {
      display: 'flex',
    },
    containerHidden: {
      display: 'none',
    },
  },
  variantStyles(theme)
)), 'rightPanel');

interface RightPanelProps {
  variant: RightPanelVariant,
  isOpen: boolean,
  children: ReactNode,
}

const RightPanel: FunctionComponent<RightPanelProps> = ({ variant, isOpen, children }) => {
  const classes = useStyles();

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={containerRef}
      className={classnames({
        [classes.container]: true,
        [classes[variant]]: true,
        [classes.containerDisplay]: isOpen,
        [classes.containerHidden]: !isOpen,
      })}
    >
      <OverlayContextProvider containerRef={containerRef}>
        {children}
      </OverlayContextProvider>
    </div>
  );
};

export default RightPanel;
