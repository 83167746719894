import { equals } from 'ramda';
import type { FunctionComponent } from 'react';
import { fieldDocumentationDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import { Block_Documentation, Block_IsDocumentationInline, Block_Type, Block_WorkflowField, BlockType_Name, BlockType_Section } from 'yooi-modules/modules/conceptLayoutModule/ids';
import { getConceptDefinitionValidFields, getFieldDimensionOfModelType } from 'yooi-modules/modules/conceptModule';
import { Field_Title, WorkflowField } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { compareProperty, compareString, comparing, filterNullOrUndefined, joinObjects, pushUndefinedToEnd } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import Typo from '../../../../../components/atoms/Typo';
import type { CompositeDropdownSection } from '../../../../../components/molecules/CompositeField';
import CompositeField, { CompositeFieldCloseReasons } from '../../../../../components/molecules/CompositeField';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import useStore from '../../../../../store/useStore';
import i18n from '../../../../../utils/i18n';
import { formatOrUndef } from '../../../../../utils/stringUtils';
import useDeepMemo from '../../../../../utils/useDeepMemo';
import useDerivedState from '../../../../../utils/useDerivedState';
import StoreTextInputField from '../../../../_global/input/StoreTextInputField';
import { getChipOptions, getUnknownChip } from '../../../../_global/modelTypeUtils';

interface DisplayOptionsLayoutCellProps {
  conceptDefinitionId: string,
  blockId: string,
  onOpen?: () => void,
  onClose?: (reason: CompositeFieldCloseReasons) => void,
}

const DisplayOptionsLayoutCell: FunctionComponent<DisplayOptionsLayoutCellProps> = ({ conceptDefinitionId, blockId, onOpen, onClose }) => {
  const store = useStore();

  const block = store.getObject(blockId);

  const initialState: {
    [Block_Documentation]: string | undefined,
    [Block_IsDocumentationInline]: boolean | undefined,
    [Block_WorkflowField]: string | undefined,
  } = {
    [Block_Documentation]: block[Block_Documentation] as string | undefined,
    [Block_IsDocumentationInline]: block[Block_IsDocumentationInline] as boolean | undefined,
    [Block_WorkflowField]: block[Block_WorkflowField] as string | undefined,

  };

  const memoizedInitialState = useDeepMemo(() => initialState, [initialState]);
  const [state, setState, resetState] = useDerivedState(() => memoizedInitialState, [memoizedInitialState]);

  const headerLinesRenderers = [
    {
      id: 'title',
      render: () => (
        <SpacingLine>
          <Typo maxLine={1}>{formatOrUndef(block.navigate(Block_Type)[BlockType_Name] as string)}</Typo>
        </SpacingLine>
      ),
    },
  ];

  if (block[Block_Type] !== BlockType_Section) {
    return (
      <CompositeField
        headerLinesRenderers={headerLinesRenderers}
        readOnly
      />
    );
  }

  return (
    <CompositeField
      width="64rem"
      getDropdownSectionDefinitions={() => {
        const sections: CompositeDropdownSection[] = [];

        const workflowFields = getConceptDefinitionValidFields(store, conceptDefinitionId)
          .filter((field) => isInstanceOf(field, WorkflowField))
          .map((workflowField) => ({
            id: workflowField.id,
            title: workflowField[Field_Title],
          }));

        if (workflowFields.length > 0 || state[Block_WorkflowField]) {
          let selectedOption;
          let error: string | undefined;
          if (state[Block_WorkflowField]) {
            const workflowField = store.getObjectOrNull(state[Block_WorkflowField]);
            if (workflowField) {
              selectedOption = getChipOptions(store, state[Block_WorkflowField]);
              if (!getFieldDimensionOfModelType(store, state[Block_WorkflowField], conceptDefinitionId)) {
                error = i18n`Selected workflow is not an instance of the expected concept`;
              }
            } else {
              selectedOption = getUnknownChip(state[Block_WorkflowField]);
            }
          }

          const workflowSection: CompositeDropdownSection = {
            id: Block_WorkflowField,
            title: i18n`Workflow`,
            useGridForTitle: true,
            action: (
              <SearchAndSelect
                clearable
                computeOptions={() => (
                  workflowFields
                    .sort(compareProperty('title', comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString)))
                    .map((field) => getChipOptions(store, field.id))
                    .filter(filterNullOrUndefined)
                )}
                selectedOption={selectedOption}
                statusIcon={error ? { icon: IconName.dangerous, color: IconColorVariant.error, message: error } : undefined}
                onSelect={(option) => setState((current) => (joinObjects(current, { [Block_WorkflowField]: option?.id })))}
              />
            ),
            lines: [],
          };
          sections.push(workflowSection);
        }

        return [
          ...sections,
          {
            id: Block_Documentation,
            title: i18n`Documentation`,
            lines: [
              {
                id: 'value',
                title: i18n`Content`,
                render: (
                  <StoreTextInputField
                    initialValue={state[Block_Documentation]}
                    onSubmit={(text) => setState((current) => (joinObjects(current, { [Block_Documentation]: text ?? undefined })))}
                  />
                ),
              },
              {
                id: 'showOn',
                title: i18n`Show on`,
                info: i18n`This documentation will be displayed either on a line above the field or on hover of the field`,
                render: (
                  <SearchAndSelect
                    computeOptions={() => fieldDocumentationDisplayOptions}
                    selectedOption={fieldDocumentationDisplayOptions.find(({ id }) => id === (state[Block_IsDocumentationInline] ?? false))}
                    onSelect={(option) => {
                      if (option) {
                        setState((current) => (joinObjects(current, { [Block_IsDocumentationInline]: option?.id ?? false })));
                      }
                    }}
                  />
                ),
              },
            ],
          },
        ];
      }}
      headerLinesRenderers={headerLinesRenderers}
      onOpenDropdown={onOpen}
      onCloseDropdown={(reason) => {
        if (reason === CompositeFieldCloseReasons.validate && !equals(memoizedInitialState, state)) {
          if (block[Block_WorkflowField] !== state[Block_WorkflowField]) {
            store.updateObject(block.id, { [Block_WorkflowField]: state[Block_WorkflowField] ?? null });
          }

          if (state[Block_Documentation]) {
            if (block[Block_Documentation] !== state[Block_Documentation]) {
              store.updateObject(block.id, { [Block_Documentation]: state[Block_Documentation] });
            }
            if (block[Block_IsDocumentationInline] !== state[Block_IsDocumentationInline]) {
              store.updateObject(block.id, { [Block_IsDocumentationInline]: state[Block_IsDocumentationInline] });
            }
          } else if (block[Block_Documentation] || block[Block_IsDocumentationInline]) {
            store.updateObject(block.id, { [Block_Documentation]: null, [Block_IsDocumentationInline]: null });
          }
        } else {
          resetState();
        }

        onClose?.(reason);
      }}
    />
  );
};

export default DisplayOptionsLayoutCell;
