import type { FunctionComponent } from 'react';
import tinycolor from 'tinycolor2';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useDerivedState from '../../../utils/useDerivedState';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import useTheme from '../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../utils/useUsageContext';
import { IconName } from '../../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../atoms/IconOnlyButton';
import Typo from '../../atoms/Typo';
import TextInputString from '../TextInputString';
import ColorSquare from './ColorSquare';

const numberOfColorsInLine = 7;

const useStyles = makeStyles((theme) => ({
  dropdownColorsLineContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
    columnGap: spacingRem.splus,
    minHeight: '2.6rem',
    alignItems: 'center',
    paddingLeft: spacingRem.splus,
    paddingRight: spacingRem.splus,
  },
  dropdownColorSpacer: {
    display: 'flex',
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.border.default,
    height: '2rem',
  },
  dropdownColorPaletteContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 2rem)',
    columnGap: spacingRem.s,
    alignItems: 'center',
  },
  dropdownColorHexContainer: {
    display: 'grid',
    gridTemplateColumns: '2rem auto 1fr',
    columnGap: spacingRem.s,
    alignItems: 'center',
  },
}), 'iconColorLine');

interface IconColorLineProps {
  colorPalette: { value: string, label?: string }[],
  value: string,
  onChange: (newColor: string) => void,
}

const IconColorLine: FunctionComponent<IconColorLineProps> = ({ colorPalette, value, onChange }) => {
  const theme = useTheme();
  const classes = useStyles();

  const [tmpHexColor, setTmpHexColor] = useDerivedState(() => value, [value]);

  const isColorFromPalette = colorPalette.findIndex((pc) => pc.value === tmpHexColor) !== -1;
  const [colorMode, setColorMode] = useDerivedState<'picker' | 'hex'>(() => (isColorFromPalette ? 'picker' : 'hex'), []);

  const colorPages: { value: string, label?: string }[][] = [];
  for (let i = 0; i < colorPalette.length;) {
    const start = i;
    if (colorPages.length === 0) {
      // First page
      if (colorPalette.length <= numberOfColorsInLine) {
        i = colorPalette.length;
      } else {
        i += numberOfColorsInLine - 1;
      }
    } else if ((i + numberOfColorsInLine - 1) > colorPalette.length - 1) {
      // Last page
      i = colorPalette.length;
    } else {
      // Middle page
      i += numberOfColorsInLine - 2;
    }
    colorPages.push(colorPalette.slice(start, i));
  }

  const valueColorPage = colorPages.findIndex((page) => page.findIndex((palette) => palette.value === value) !== -1);
  const [colorPage, setColorPage] = useDerivedState(() => (valueColorPage !== -1 ? valueColorPage : 0), [value, valueColorPage]);
  const currentColorPage = Math.min(colorPage, colorPages.length - 1);

  return (
    <SizeContextProvider sizeVariant={SizeVariant.small}>
      <span className={classes.dropdownColorsLineContainer}>
        {
          colorMode === 'hex'
            ? (
              <span className={classes.dropdownColorHexContainer}>
                <ColorSquare color={value} tooltip={value} />
                <Typo color={theme.color.text.secondary}>{i18n`HEX`}</Typo>
                <UsageContextProvider usageVariant={UsageVariant.inline}>
                  <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.inline}>
                    <TextInputString
                      value={tmpHexColor}
                      placeholder={i18n`HEX`}
                      onChange={(rawNewColor) => {
                        if (rawNewColor === null || rawNewColor === '') {
                          setTmpHexColor('#');
                        } else if (/^#[0-9A-F]{0,8}$/i.test(rawNewColor)) {
                          const newColor = rawNewColor.toUpperCase();
                          setTmpHexColor(newColor);
                          if (tinycolor(rawNewColor).isValid()) {
                            onChange(newColor);
                          }
                        }
                      }}
                    />
                  </SizeContextProvider>
                </UsageContextProvider>
              </span>
            )
            : (
              <span className={classes.dropdownColorPaletteContainer}>
                {
                  currentColorPage > 0
                    ? (
                      <IconOnlyButton
                        tooltip={i18n`Previous`}
                        iconName={IconName.keyboard_arrow_left}
                        onClick={() => setColorPage(currentColorPage - 1)}
                        variant={IconOnlyButtonVariants.tertiary}
                      />
                    )
                    : null
                }
                {
                  colorPages[currentColorPage]
                    .map(({ value: paletteColor, label }) => (
                      <ColorSquare
                        key={`${paletteColor}_${label}`}
                        color={paletteColor}
                        tooltip={label}
                        onClick={() => onChange(paletteColor)}
                        isSelected={value === paletteColor}
                      />
                    ))
                }
                {
                  colorPages.length - 1 > currentColorPage
                    ? (
                      <IconOnlyButton
                        tooltip={i18n`Next`}
                        iconName={IconName.keyboard_arrow_right}
                        onClick={() => setColorPage(currentColorPage + 1)}
                        variant={IconOnlyButtonVariants.tertiary}
                      />
                    )
                    : null
                }
              </span>
            )
        }
        <span className={classes.dropdownColorSpacer} />
        <IconOnlyButton
          tooltip={colorMode === 'hex' ? i18n`Palette` : i18n`HEX`}
          iconName={colorMode === 'hex' ? IconName.palette : IconName.tag}
          onClick={() => setColorMode((current) => (current === 'hex' ? 'picker' : 'hex'))}
          variant={IconOnlyButtonVariants.secondary}
        />
      </span>
    </SizeContextProvider>
  );
};

export default IconColorLine;
