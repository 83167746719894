import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { dimensionsMappingToParametersMapping, getPathReturnedConceptDefinitionId } from 'yooi-modules/modules/conceptModule';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { ViewType } from 'yooi-modules/modules/dashboardModule';
import type { StoreObject } from 'yooi-store';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import type { FrontObjectStore } from '../../../../store/useStore';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { getViewFilterFunction } from '../../listFilterFunctions';
import type { ConceptViewResolution } from '../common/viewWithConceptResolutionUtils';
import { resolveConcept } from '../common/viewWithConceptResolutionUtils';
import { getDimensionLabel } from '../data/dataResolution';
import type { ViewResolutionError } from '../viewResolutionUtils';
import { isResolutionError } from '../viewResolutionUtils';

export interface TimelineViewResolution extends ConceptViewResolution {
  type: ViewType.Timeline,
  filterFunction: (item: StoreObject) => boolean,
}

export const resolveTimelineView = (
  store: FrontObjectStore,
  dimensions: ViewDimension[],
  parametersMapping: ParametersMapping,
  filterConfiguration: FilterConfiguration | undefined
): TimelineViewResolution | ViewResolutionError => {
  const resolvedConcept = resolveConcept(store, dimensions, parametersMapping);
  if (isResolutionError(resolvedConcept)) {
    return resolvedConcept;
  } else {
    const filterFunction = getViewFilterFunction(
      store,
      dimensions.map((dimension, index) => {
        const dimConceptDefinitionId = getPathReturnedConceptDefinitionId(store, dimension.path);
        return dimConceptDefinitionId ? {
          id: dimension.id,
          label: getDimensionLabel(store, dimension.label, index, dimension.path),
          typeId: dimConceptDefinitionId,
        } : undefined;
      }).filter(filterNullOrUndefined),
      filterConfiguration,
      parametersMapping
    );
    return joinObjects(
      {
        type: ViewType.Timeline as const,
        filterFunction: (item: StoreObject) => (!filterFunction || filterFunction(dimensionsMappingToParametersMapping({ [dimensions[0].id]: item.id }))),
      },
      resolvedConcept
    );
  }
};
