import classnames from 'classnames';
import type { DragEvent, DragEventHandler, FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import makeStyles from '../../../utils/makeStyles';
import { remToPx } from '../../../utils/sizeUtils';

const timelineParentBottomMargin = 0.6;

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  lineTopContainer: {
    flexGrow: 2,
  },
  lineTop: {
    borderTopWidth: '0.2rem',
    borderTopStyle: 'solid',
    borderTopColor: theme.color.border.info,
  },
  lineBottomContainer: {
    flexGrow: 2,
  },
  lineBottom: {
    borderBottomWidth: '0.2rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.info,
  },
  lineMiddleTopContainer: {
    flexGrow: 1,
  },
  lineMiddleTop: {
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.info,
  },
  lineMiddleBottomContainer: {
    flexGrow: 1,
  },
  lineMiddleBottom: {
    borderTopWidth: '0.1rem',
    borderTopStyle: 'solid',
    borderTopColor: theme.color.border.info,
  },
}), 'timelineDropZone');

interface TimelineDropZoneProps {
  timelineRowsAndRowNumbers: string[],
  height: number,
  width: number,
  onElementDrop?: (droppedConceptId: string, drop: { timelineRow: string, position: string }) => void,
  conflictingLines?: string[],
  yPositionFromRow: (row: string) => number,
}

const TimelineDropZone: FunctionComponent<TimelineDropZoneProps> = ({ timelineRowsAndRowNumbers, height, width, onElementDrop, conflictingLines = [], yPositionFromRow }) => {
  const classes = useStyles();

  const [currentPosition, setCurrentPosition] = useState<{ timelineRow?: string | number, position?: string }>({});
  // dragLeave event of parent elements fire when dragging over a child element so we need a counter to only proc
  const dragEventCounter = useRef(0);

  const handleDragOver: DragEventHandler = (event) => {
    // By default, data/elements cannot be dropped in other elements. To allow a drop, we must prevent the default handling of the element.
    event.preventDefault();
  };
  const handleDragEnter = (timelineRow: string, position: string) => {
    setCurrentPosition({ timelineRow, position });
    dragEventCounter.current += 1;
  };
  const handleDrop = (event: DragEvent<HTMLElement>, timelineRow: string, position: string) => {
    dragEventCounter.current = 0;
    setCurrentPosition({});
    onElementDrop?.(event.dataTransfer.getData('id'), { timelineRow, position });
  };

  const handleDragLeave = () => {
    dragEventCounter.current -= 1;
    // if the counter is at 0 that mean that we're leaving the parent element.
    if (dragEventCounter.current === 0) {
      setCurrentPosition({});
    }
  };

  return (
    <g onDragLeave={handleDragLeave}>
      {timelineRowsAndRowNumbers.map((timelineRow) => (
        <foreignObject
          key={timelineRow}
          transform={`translate(0 ${yPositionFromRow(timelineRow) - (remToPx(timelineParentBottomMargin) / 2)})`}
          width={width}
          height={height}
        >
          <div className={classes.container}>
            <div
              onDragEnter={() => handleDragEnter(timelineRow, 'top')}
              onDragOver={(event) => handleDragOver(event)}
              onDrop={(event) => handleDrop(event, timelineRow, 'top')}
              className={classnames({
                [classes.lineTopContainer]: true,
                [classes.lineTop]: currentPosition.timelineRow === timelineRow && currentPosition.position === 'top',
              })}
            />
            {!conflictingLines.includes(timelineRow) && (
              <>
                <div
                  onDragEnter={() => handleDragEnter(timelineRow, 'middle')}
                  onDragOver={(event) => handleDragOver(event)}
                  onDrop={(event) => handleDrop(event, timelineRow, 'middle')}
                  className={classnames({
                    [classes.lineMiddleTopContainer]: true,
                    [classes.lineMiddleTop]: currentPosition.timelineRow === timelineRow && currentPosition.position === 'middle',
                  })}
                />
                <div
                  onDragEnter={() => handleDragEnter(timelineRow, 'middle')}
                  onDragOver={(event) => handleDragOver(event)}
                  onDrop={(event) => handleDrop(event, timelineRow, 'middle')}
                  className={classnames({
                    [classes.lineMiddleBottomContainer]: true,
                    [classes.lineMiddleBottom]: currentPosition.timelineRow === timelineRow && currentPosition.position === 'middle',
                  })}
                />
              </>
            )}
            <div
              onDragEnter={() => handleDragEnter(timelineRow, 'bottom')}
              onDragOver={(event) => handleDragOver(event)}
              onDrop={(event) => handleDrop(event, timelineRow, 'bottom')}
              className={classnames({
                [classes.lineBottomContainer]: true,
                [classes.lineBottom]: currentPosition.timelineRow === timelineRow && currentPosition.position === 'bottom',
              })}
            />
          </div>
        </foreignObject>
      ))}
    </g>
  );
};

export default TimelineDropZone;
