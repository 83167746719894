import { Block_ConceptDefinition, Block_Type } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { StoreObject } from 'yooi-store';
import { newError } from 'yooi-utils';
import type { FrontObjectStore } from '../../../../store/useStore';
import type { DisplayItem } from './definitions/displayItemDefinitionUtils';

interface DisplayItemHandler {
  canMoveUp: () => boolean,
  canMoveDown: () => boolean,
  moveUp: () => string | void,
  moveDown: () => string | void,
  deleteBlockAndContent: () => void,
}

interface DisplayItemDefinitionHandler {
  createAbove: (fromBlockId: string, fromFieldId?: string) => string,
  createBelow: (topBlockId?: string, topFieldId?: string) => string,
}

export interface DisplayItemLibrary {
  id: string,
  itemHandler: (store: FrontObjectStore, blockId: string, conceptDefinition: StoreObject, flattenedTree: DisplayItem[]) => DisplayItemHandler,
  itemDefinitionHandler: (store: FrontObjectStore, conceptDefinition: StoreObject, flattenedTree: DisplayItem[]) => DisplayItemDefinitionHandler,
}

const displayItemMap: Record<string, DisplayItemLibrary> = {};

export const registerDisplayItem = (definition: DisplayItemLibrary): void => {
  displayItemMap[definition.id] = definition;
};

export const getDisplayItemDefinitionHandler = (
  store: FrontObjectStore,
  blockTypeId: string,
  conceptDefinitionId: string,
  flattenedTree: DisplayItem[]
): DisplayItemDefinitionHandler => {
  const displayItemDefinitionHandler = displayItemMap[blockTypeId]?.itemDefinitionHandler(store, store.getObject(conceptDefinitionId), flattenedTree);
  if (!displayItemDefinitionHandler) {
    throw newError('Missing display item handler for type', { blockTypeId });
  }

  return {
    createAbove: displayItemDefinitionHandler.createAbove,
    createBelow: displayItemDefinitionHandler.createBelow,
  };
};

export const getDisplayItemHandler = (store: FrontObjectStore, blockId: string, flattenedTree: DisplayItem[]): DisplayItemHandler => {
  const block = store.getObject(blockId);
  const conceptDefinition = block.navigate(Block_ConceptDefinition);
  const blockTypeId = block[Block_Type] as string;
  const displayItemHandler = displayItemMap[blockTypeId]?.itemHandler(store, blockId, conceptDefinition, flattenedTree);
  if (!displayItemHandler) {
    throw newError('Missing display item handler for type', { blockTypeId });
  }

  return {
    canMoveUp: displayItemHandler.canMoveUp,
    canMoveDown: displayItemHandler.canMoveDown,
    moveUp: displayItemHandler.moveUp,
    moveDown: displayItemHandler.moveDown,
    deleteBlockAndContent: displayItemHandler.deleteBlockAndContent,
  };
};
