import type { FunctionComponent } from 'react';
import type { ConceptDefinitionLibraryTableStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import {
  ConceptDefinitionLibraryTable,
  ConceptDefinitionLibraryTable_HideOnCard,
  ConceptDefinitionLibraryTable_Rank,
  ConceptDefinitionLibraryTable_Role_ConceptDefinition,
  ConceptDefinitionLibraryTable_Role_Field,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { ConceptDefinitionStoreObject } from 'yooi-modules/modules/conceptModule';
import { canCopyConcept, getConceptUrl, isEmbeddedAsIntegrationOnly } from 'yooi-modules/modules/conceptModule';
import { Concept_Name, ConceptDefinition_CardColorField, ConceptDefinition_CardImageField, ConceptDefinition_CardTitleField } from 'yooi-modules/modules/conceptModule/ids';
import { DataAsset } from 'yooi-modules/modules/dataAssetModule/ids';
import type { StoreObject } from 'yooi-store';
import { compareRank, extractAndCompareValue } from 'yooi-utils';
import { ButtonVariant } from '../../components/atoms/Button';
import { IconName } from '../../components/atoms/Icon';
import CardList from '../../components/templates/CardList';
import type { Pagination } from '../../components/templates/PageSelector';
import useAcl from '../../store/useAcl';
import useAuth from '../../store/useAuth';
import useStore from '../../store/useStore';
import i18n from '../../utils/i18n';
import useNavigation from '../../utils/useNavigation';
import useNewLineFocus from '../../utils/useNewLineFocus';
import { duplicateConcept } from '../_global/conceptUtils';
import { getConceptFieldForCard } from '../_global/fields/_global/cardUtils';
import ConceptCard from '../_global/fields/ConceptCard';
import { getFieldHandler } from '../_global/fields/FieldLibrary';
import type { NavigationFilter } from '../_global/navigationUtils';
import { getNavigationPayload } from '../_global/navigationUtils';
import { share } from '../_global/shareUtils';
import useConceptDeleteModal from '../_global/useConceptDeleteModal';
import useAdminMenuItems from '../_global/userAdmin/useAdminMenuItems';

interface FirstClassConceptCardListProps {
  conceptDefinitionId: string,
  list: StoreObject[],
  forceFollowingIds: (id: string, nextId: string) => void,
  onNewItem?: (title?: string, values?: Record<string, unknown>) => string,
  readOnly?: boolean,
  pagination?: Pagination,
}

const FirstClassConceptCardList: FunctionComponent<FirstClassConceptCardListProps> = ({
  conceptDefinitionId,
  list,
  forceFollowingIds,
  onNewItem,
  readOnly = false,
  pagination,
}) => {
  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();

  const navigation = useNavigation<NavigationFilter>();

  const [newLineFocus] = useNewLineFocus();

  const conceptDefinition = store.getObject<ConceptDefinitionStoreObject>(conceptDefinitionId);

  const [doDelete, popupComponent] = useConceptDeleteModal(false);

  const { passwordModal, buildAdminMenuItems } = useAdminMenuItems(store, loggedUserId);

  const linesActions = (concept: StoreObject) => {
    if (readOnly) {
      return [];
    }
    const adminMenuItems = buildAdminMenuItems(concept.id);
    return [
      {
        key: 'share',
        icon: IconName.link,
        name: i18n`Copy link`,
        onClick: () => share(store, getNavigationPayload(navigation, concept.id, getConceptUrl(store, concept.id))),
      },
      {
        key: 'duplicate',
        icon: IconName.content_copy_outline,
        name: i18n`Duplicate`,
        hidden: !canCopyConcept(store, aclHandler, concept.id),
        onClick: () => duplicateConcept(store, aclHandler, concept.id, (id) => forceFollowingIds(concept.id, id)),
      },
      {
        key: 'duplicate_with_child',
        icon: IconName.content_copy_outline,
        name: i18n`Duplicate with its child elements`,
        hidden: conceptDefinitionId === DataAsset || !canCopyConcept(store, aclHandler, concept.id),
        onClick: () => duplicateConcept(store, aclHandler, concept.id, (id) => forceFollowingIds(concept.id, id), true),
      },
      {
        key: 'delete',
        icon: IconName.delete,
        name: i18n`Delete`,
        hidden: isEmbeddedAsIntegrationOnly(store.getObject(concept.id)) || !aclHandler.canDeleteObject(concept.id),
        onClick: () => doDelete(concept.id),
        danger: true,
      },
      ...adminMenuItems,
    ];
  };

  const linesDefinition = store.withAssociation(ConceptDefinitionLibraryTable)
    .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
    .list<ConceptDefinitionLibraryTableStoreObject>()
    .filter((conceptDefinitionLibraryTable) => !conceptDefinitionLibraryTable.object[ConceptDefinitionLibraryTable_HideOnCard])
    .sort(extractAndCompareValue((conceptDefinitionLibraryTable) => conceptDefinitionLibraryTable.object[ConceptDefinitionLibraryTable_Rank], compareRank))
    .map((conceptDefinitionLibraryTable) => conceptDefinitionLibraryTable.role(ConceptDefinitionLibraryTable_Role_Field))
    .filter((fieldId) => getFieldHandler(store, fieldId)?.renderField)
    .map((fieldId) => getConceptFieldForCard(store, fieldId, readOnly, fieldId === Concept_Name));

  return (
    <>
      <CardList<StoreObject>
        list={list}
        pagination={pagination}
        renderCard={(instance, focusOnMount) => (
          <ConceptCard
            getNavigationPayload={() => getNavigationPayload(navigation, instance.id, getConceptUrl(store, instance.id))}
            actions={linesActions(instance)}
            focusOnMount={focusOnMount}
            colorFieldId={conceptDefinition.navigateOrNull(ConceptDefinition_CardColorField)?.id}
            titleFieldId={conceptDefinition.navigateOrNull(ConceptDefinition_CardTitleField)?.id}
            imageFieldId={conceptDefinition.navigateOrNull(ConceptDefinition_CardImageField)?.id}
            linesDefinition={linesDefinition}
            conceptId={instance.id}
            readOnly={readOnly || !aclHandler.canWriteObject(instance.id)}
          />
        )}
        inlineCreation={onNewItem ? {
          type: 'creation',
          button: {
            title: i18n`Create`,
            icon: IconName.add,
            variant: ButtonVariant.tertiary,
            onClick: onNewItem,
          },
          lineFocus: newLineFocus.current,
        } : undefined}
      />
      {popupComponent}
      {passwordModal}
    </>
  );
};

export default FirstClassConceptCardList;
