import type { FunctionComponent } from 'react';
import { useState } from 'react';
import i18n from '../../utils/i18n';
import { IconName } from '../atoms/Icon';
import RawInput, { RawInputType } from './RawInput';

interface PasswordInputProps {
  name: string,
  value?: string,
  onSubmit?: (value: string | undefined) => void,
  onCancel?: (value: string | undefined) => void,
  onChange?: (value: string) => void,
  onEnterKeyPressed?: () => void,
  error?: string,
  disabled?: boolean,
  fullWidth?: boolean,
  focusOnMount?: boolean,
}

const PasswordInput: FunctionComponent<PasswordInputProps> = ({
  name,
  value,
  onSubmit,
  onChange,
  onCancel,
  onEnterKeyPressed,
  error,
  disabled = false,
  fullWidth = false,
  focusOnMount = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <RawInput
      name={name}
      type={showPassword ? RawInputType.text : RawInputType.password}
      value={value}
      onSubmit={onSubmit}
      onChange={onChange}
      onCancel={onCancel}
      onEnterKeyPressed={onEnterKeyPressed}
      error={error}
      disabled={disabled}
      fullWidth={fullWidth}
      focusOnMount={focusOnMount}
      action={{
        icon: showPassword ? IconName.visibility : IconName.visibility_off,
        tooltip: showPassword ? i18n`Hide password` : i18n`Show password`,
        onClick: () => setShowPassword((current) => !current),
      }}
    />
  );
};

export default PasswordInput;
