import type { FunctionComponent, MutableRefObject } from 'react';
import type { BlockStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import {
  Block_FieldDisplays,
  Block_Parent,
  Block_Rank,
  Block_Type,
  BlockType_Column,
  BlockType_Section,
  BlockType_Tab,
  ConceptDefinition_Blocks,
  FieldBlockDisplay,
  FieldBlockDisplay_Block,
  FieldBlockDisplay_FieldPath,
  FieldBlockDisplay_Rank,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import {
  ConceptDefinition_MainWorkflowField,
  WorkflowEntry,
  WorkflowEntry_Rank,
  WorkflowEntry_Role_Concept,
  WorkflowEntry_Role_Workflow,
  WorkflowField,
  WorkflowField_Workflow,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { StoreObject } from 'yooi-store';
import { compareProperty, compareRank, extractAndCompareValue, joinObjects, ranker } from 'yooi-utils';
import Button from '../../../../../components/atoms/Button';
import { IconName } from '../../../../../components/atoms/Icon';
import Tooltip from '../../../../../components/atoms/Tooltip';
import Typo, { TypoVariant } from '../../../../../components/atoms/Typo';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import Table from '../../../../../components/molecules/Table';
import TableBody from '../../../../../components/molecules/TableBody';
import TableHeader from '../../../../../components/molecules/TableHeader';
import TableHeaderCell from '../../../../../components/molecules/TableHeaderCell';
import TableHeaderLine from '../../../../../components/molecules/TableHeaderLine';
import BlockContent from '../../../../../components/templates/BlockContent';
import useStore from '../../../../../store/useStore';
import { spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import type { NewLineFocusRefContent } from '../../../../../utils/useNewLineFocus';
import { SessionStorageKeys, useSessionStorageState } from '../../../../../utils/useSessionStorage';
import { getBlockParameterDefinitionCurrent } from '../../../../_global/conceptFilterIdUtils';
import { getLoggedUserParameterDefinition } from '../../../../_global/filter/filterUtils';
import type { LayoutCollapseConfiguration } from '../../../_global/displayItemsLibrary/definitions/displayItemDefinitionUtils';
import { DisplayItemTypes, getFlattenedTree, getParentsBlocksIds } from '../../../_global/displayItemsLibrary/definitions/displayItemDefinitionUtils';
import { getDisplayItemDefinitionHandler } from '../../../_global/displayItemsLibrary/displayItemLibrary';
import LayoutDisplayTableBlockLine from './LayoutDisplayTableBlockLine';
import LayoutDisplayTableFieldBlockDisplayLine from './LayoutDisplayTableFieldBlockDisplayLine';
import { expandBlock } from './layoutUtils';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerNameContainer: {
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    marginLeft: spacingRem.s,
    marginRight: spacingRem.s,
  },
}, 'layoutDisplayEditorBlock');

interface LayoutDisplayEditorBlockProps {
  conceptDefinitionId: string,
  newlyCreatedId: MutableRefObject<NewLineFocusRefContent>,
  setNewlyCreatedId: (id: string) => void,
}

const LayoutDisplayEditorBlock: FunctionComponent<LayoutDisplayEditorBlockProps> = ({ conceptDefinitionId, newlyCreatedId, setNewlyCreatedId }) => {
  const classes = useStyles();

  const store = useStore();
  const [layoutCollapseConfiguration, setLayoutCollapseConfiguration] = useSessionStorageState<LayoutCollapseConfiguration | undefined>(`${SessionStorageKeys.layoutCollapseConfig}_${conceptDefinitionId}`, undefined);

  const conceptDefinition = store.getObject(conceptDefinitionId);
  const workflowFieldId = conceptDefinition[ConceptDefinition_MainWorkflowField]
  && store.getObjectOrNull(conceptDefinition[ConceptDefinition_MainWorkflowField] as string) !== null
    ? conceptDefinition[ConceptDefinition_MainWorkflowField] as string
    : undefined;

  const workflowConceptIds: string[] = [];
  if (workflowFieldId) {
    const workflowField = store.getObject(workflowFieldId);
    if (isInstanceOf(workflowField, WorkflowField)) {
      const workflowId = workflowField.navigateOrNull(WorkflowField_Workflow)?.id;
      if (workflowId) {
        workflowConceptIds.push(
          ...store.withAssociation(WorkflowEntry)
            .withRole(WorkflowEntry_Role_Workflow, workflowId)
            .list()
            .sort(compareProperty('object', compareProperty(WorkflowEntry_Rank, compareRank)))
            .map((entry) => entry.role(WorkflowEntry_Role_Concept))
        );
      }
    }
  }

  const flattenedTree = getFlattenedTree(store, conceptDefinition);

  const sortedBlockFields = (blockId: string) => store.getObject(blockId)
    .navigateBack(Block_FieldDisplays)
    .sort(extractAndCompareValue((f) => f[FieldBlockDisplay_Rank] as string, compareRank));

  const addNewFieldToBlock = (blockId: string, rank?: string) => {
    const blockFieldsInsertRanks = ranker.decorateList(
      sortedBlockFields(blockId),
      (blockField) => blockField[FieldBlockDisplay_Rank] as string
    );
    const fieldDisplayId = store.createObject({
      [Instance_Of]: FieldBlockDisplay,
      [FieldBlockDisplay_Block]: blockId,
      [FieldBlockDisplay_FieldPath]: [],
      [FieldBlockDisplay_Rank]: rank ?? blockFieldsInsertRanks.insertBeforeFirstItemRank(),
    });
    setNewlyCreatedId(fieldDisplayId);
    return fieldDisplayId;
  };

  const getOldestParentBlockId = (blockId: string): string => {
    const block = store.getObject<BlockStoreObject>(blockId);
    const parentId = block[Block_Parent];
    return parentId ? getOldestParentBlockId(parentId) : blockId;
  };

  const computeChildBlockList = (block?: StoreObject) => {
    let startingPoint;
    if (block) {
      startingPoint = block.navigateBack(Block_Parent);
    } else {
      startingPoint = conceptDefinition.navigateBack(ConceptDefinition_Blocks).filter((b) => b[Block_Type] === BlockType_Tab);
    }
    const list = startingPoint
      .sort((a, b) => compareRank(a[Block_Rank] as string, b[Block_Rank] as string))
      .map((childBlock) => (joinObjects(
        childBlock,
        {
          fields: ranker.decorateList(
            sortedBlockFields(childBlock.id),
            (field) => field[FieldBlockDisplay_Rank] as string
          ),
        }
      )));
    return ranker.decorateList(list, (b) => b[Block_Rank] as string);
  };

  const parameterDefinitions: SingleParameterDefinition[] = [getBlockParameterDefinitionCurrent(conceptDefinitionId), getLoggedUserParameterDefinition()];

  return (
    <>
      <BlockContent padded>
        <Table>
          <colgroup>
            <col width="65%" />
            <col width="10%" />
            <col width="10%" />
            <col width="15%" />
          </colgroup>
          <TableHeader>
            <TableHeaderLine>
              <TableHeaderCell width="55%" colSpan={2}>
                <div className={classes.headerContainer}>
                  <div className={classes.headerNameContainer}>
                    <Tooltip title={i18n`Name`}>
                      <Typo variant={TypoVariant.small} maxLine={1}>{i18n`Name`}</Typo>
                    </Tooltip>
                  </div>
                </div>
              </TableHeaderCell>
              <TableHeaderCell width="20%">
                <div className={classes.headerContainer}>
                  <div className={classes.headerNameContainer}>
                    <Tooltip title={i18n`Local title`}>
                      <Typo variant={TypoVariant.small} maxLine={1}>{i18n`Local title`}</Typo>
                    </Tooltip>
                  </div>
                </div>
              </TableHeaderCell>
              <TableHeaderCell width="10%">
                <div className={classes.headerContainer}>
                  <div className={classes.headerNameContainer}>
                    <Tooltip title={i18n`Display options`}>
                      <Typo variant={TypoVariant.small} maxLine={1}>{i18n`Display options`}</Typo>
                    </Tooltip>
                  </div>
                </div>
              </TableHeaderCell>
              <TableHeaderCell width="15%">
                <div className={classes.headerContainer}>
                  <div className={classes.headerNameContainer}>
                    <Tooltip title={i18n`Display & Edit conditions`}>
                      <Typo variant={TypoVariant.small} maxLine={1}>{i18n`Display & Edit conditions`}</Typo>
                    </Tooltip>
                  </div>
                </div>
              </TableHeaderCell>
              <TableHeaderCell action />
              <TableHeaderCell action />
              <TableHeaderCell action />
            </TableHeaderLine>
          </TableHeader>
          <TableBody>
            {flattenedTree.map((entry, index) => {
              const isBlockCollapsed = layoutCollapseConfiguration ? layoutCollapseConfiguration[entry.blockId] : false;
              const oldestParentId = getOldestParentBlockId(entry.blockId);
              const parentIds = getParentsBlocksIds(store, entry.blockId);
              const isAnyParentCollapsed = parentIds.some((parentId) => (layoutCollapseConfiguration ? layoutCollapseConfiguration[parentId] : false));
              if (entry.type === DisplayItemTypes.block && (oldestParentId === entry.blockId || !isAnyParentCollapsed)) {
                return (
                  <LayoutDisplayTableBlockLine
                    key={entry.blockId}
                    conceptDefinitionId={conceptDefinitionId}
                    blockId={entry.blockId}
                    index={index}
                    parameterDefinitions={parameterDefinitions}
                    newlyCreatedId={newlyCreatedId}
                    setNewlyCreatedId={setNewlyCreatedId}
                    flattenedTree={flattenedTree}
                    sortedBlockFields={sortedBlockFields}
                    addNewFieldToBlock={addNewFieldToBlock}
                    computeChildBlockList={computeChildBlockList}
                  />
                );
              } else if (entry.type === DisplayItemTypes.field && !isAnyParentCollapsed && !isBlockCollapsed) {
                return (
                  <LayoutDisplayTableFieldBlockDisplayLine
                    key={entry.fieldBlockDisplayId}
                    conceptDefinitionId={conceptDefinitionId}
                    fieldBlockDisplayId={entry.fieldBlockDisplayId}
                    index={index}
                    parameterDefinitions={parameterDefinitions}
                    newlyCreatedId={newlyCreatedId}
                    setNewlyCreatedId={setNewlyCreatedId}
                    workflowFieldId={workflowFieldId}
                    workflowConceptIds={workflowConceptIds}
                    flattenedTree={flattenedTree}
                    sortedBlockFields={sortedBlockFields}
                    addNewFieldToBlock={addNewFieldToBlock}
                  />
                );
              } else {
                return null;
              }
            })}
          </TableBody>
        </Table>
      </BlockContent>
      <BlockContent padded>
        <SpacingLine>
          <div>
            <Button
              title={i18n`Add tab`}
              iconName={IconName.add}
              onClick={() => {
                if (flattenedTree.length > 0) {
                  const { blockId, fieldBlockDisplayId } = flattenedTree[flattenedTree.length - 1];
                  setNewlyCreatedId(getDisplayItemDefinitionHandler(store, BlockType_Tab, conceptDefinitionId, flattenedTree).createBelow(blockId, fieldBlockDisplayId));
                } else {
                  setNewlyCreatedId(getDisplayItemDefinitionHandler(store, BlockType_Tab, conceptDefinitionId, flattenedTree).createBelow());
                }
              }}
            />
          </div>
          {conceptDefinition.navigateBack(ConceptDefinition_Blocks).filter((b) => b[Block_Type] === BlockType_Tab).length > 0 && (
            <>
              <div>
                <Button
                  title={i18n`Add section`}
                  iconName={IconName.add}
                  onClick={() => {
                    const { blockId, fieldBlockDisplayId } = flattenedTree[flattenedTree.length - 1];
                    setNewlyCreatedId(getDisplayItemDefinitionHandler(store, BlockType_Section, conceptDefinitionId, flattenedTree).createBelow(blockId, fieldBlockDisplayId));
                    expandBlock(store, layoutCollapseConfiguration, setLayoutCollapseConfiguration, blockId);
                  }}
                />
              </div>
              <div>
                <Button
                  title={i18n`Add column`}
                  iconName={IconName.add}
                  onClick={() => {
                    const { blockId, fieldBlockDisplayId } = flattenedTree[flattenedTree.length - 1];
                    setNewlyCreatedId(getDisplayItemDefinitionHandler(store, BlockType_Column, conceptDefinitionId, flattenedTree).createBelow(blockId, fieldBlockDisplayId));
                    expandBlock(store, layoutCollapseConfiguration, setLayoutCollapseConfiguration, blockId);
                  }}
                />
              </div>
              <div>
                <Button
                  title={i18n`Add field`}
                  iconName={IconName.add}
                  onClick={() => {
                    const { blockId } = flattenedTree[flattenedTree.length - 1];
                    addNewFieldToBlock(blockId, ranker.decorateList(
                      sortedBlockFields(blockId),
                      (display) => display[FieldBlockDisplay_Rank] as string
                    ).insertAfterLastItemRank());
                    expandBlock(store, layoutCollapseConfiguration, setLayoutCollapseConfiguration, blockId);
                  }}
                />
              </div>
            </>
          )}
        </SpacingLine>
      </BlockContent>
    </>
  );
};

export default LayoutDisplayEditorBlock;
