import type { FunctionComponent } from 'react';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import Typo from '../../../../components/atoms/Typo';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { SessionStorageKeys, useSessionStorageState } from '../../../../utils/useSessionStorage';
import { SizeContextProvider, SizeVariant } from '../../../../utils/useSizeContext';
import useTheme from '../../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../../utils/useUsageContext';
import { getChipOptions } from '../../modelTypeUtils';
import type { QueryTableConfiguration } from '../../sessionStorageTypes';
import type { TableViewResolvedDefinition } from './tableViewHandler';
import type { TableGroupByField } from './tableViewResolution';
import { getTableViewGroupByFields } from './tableViewResolution';

const useStyles = makeStyles({
  configurationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}, 'tableViewActions');

interface TableViewActionsProps {
  filterKey: string,
  viewDefinition: TableViewResolvedDefinition,
  viewDimensions: ViewDimension[],
  groupByDefault: { groupById: string | undefined },
}

const TableViewActions: FunctionComponent<TableViewActionsProps> = ({ filterKey, viewDefinition, viewDimensions, groupByDefault }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const [tableConfig, , setTableConfig] = useSessionStorageState<QueryTableConfiguration | undefined>(`${SessionStorageKeys.tableConfig}_${filterKey}`, groupByDefault);

  const groupByFields = getTableViewGroupByFields(store, viewDefinition, viewDimensions);
  const groupByField = groupByFields.find(({ id }) => id === tableConfig?.groupById);
  const getGroupByFieldOption = (tableGroupByField: TableGroupByField | undefined) => {
    const fieldOption = tableGroupByField?.fieldId ? getChipOptions(store, tableGroupByField.fieldId) : undefined;
    return tableGroupByField && fieldOption ? joinObjects(fieldOption, { key: tableGroupByField.id, id: tableGroupByField.id, label: tableGroupByField.label }) : undefined;
  };

  if (groupByFields.length > 0) {
    return (
      <UsageContextProvider usageVariant={UsageVariant.inForm}>
        <SizeContextProvider sizeVariant={SizeVariant.small}>
          <div className={classes.configurationContainer}>
            <SpacingLine>
              <Typo maxLine={1} color={theme.color.text.secondary}>{i18n`Group by`}</Typo>
              <SearchAndSelect
                clearable
                computeOptions={() => groupByFields
                  .map((groupByOption) => getGroupByFieldOption(groupByOption))
                  .filter(filterNullOrUndefined)}
                selectedOption={getGroupByFieldOption(groupByField)}
                onSelect={(option) => {
                  setTableConfig({ groupById: option?.id ?? null });
                }}
              />
            </SpacingLine>
          </div>
        </SizeContextProvider>
      </UsageContextProvider>
    );
  } else {
    return null;
  }
};

export default TableViewActions;
