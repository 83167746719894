import type { RefCallback } from 'react';
import { useCallback, useRef } from 'react';

const useEscapeListenerRef = <Element extends HTMLElement>(callback: () => void): RefCallback<Element> => {
  const nodeRef = useRef<Element | null>(null);
  const eventListenerRef = useRef<(event: KeyboardEvent) => void>();
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  return useCallback((node) => {
    if (nodeRef.current && eventListenerRef.current) {
      nodeRef.current.removeEventListener('keydown', eventListenerRef.current);
    }

    if (node) {
      eventListenerRef.current = (event) => {
        if (event.key === 'Escape') {
          callbackRef.current();
        }
      };
      node.addEventListener('keydown', eventListenerRef.current);
    }

    nodeRef.current = node;
  }, []);
};

export default useEscapeListenerRef;
