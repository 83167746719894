import type { ComponentProps, FunctionComponent } from 'react';
import URLInput from '../../../components/inputs/URLInput';
import StoreInput from './StoreInput';

interface StoreURLInputProps extends Omit<ComponentProps<typeof URLInput>, 'value' | 'onChange' | 'onSubmit' | 'onCancel'> {
  initialValue: string | undefined,
  onSubmit: (value: string | null) => void,
}

const StoreURLInput: FunctionComponent<StoreURLInputProps> = ({ initialValue, onSubmit, ...urlInputProps }) => (
  <StoreInput initialValue={initialValue} onSubmit={onSubmit}>
    {(formInputProps) => (
      <URLInput
        {...urlInputProps}
        {...formInputProps}
      />
    )}
  </StoreInput>
);

export default StoreURLInput;
