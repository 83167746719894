import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import Button from '../../../components/atoms/Button';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useNavigation from '../../../utils/useNavigation';
import { UsageContextProvider, UsageVariant } from '../../../utils/useUsageContext';
import FormTextInputField from '../../_global/input/FormTextInputField';
import type { NavigationFilter } from '../../_global/navigationUtils';

const EventIdRegex = /^[0-9]+-[0-9]+$/;

const useStyles = makeStyles({
  searchEventContainer: {
    display: 'grid',
    columnGap: spacingRem.s,
    alignItems: 'center',
  },
  singleEventContainer: {
    gridTemplateColumns: '20rem auto 1fr',
  },
  rangeEventContainer: {
    gridTemplateColumns: '20rem 20rem auto 1fr',
  },
}, 'explorerHomeEventsTab');

const ExplorerHomeEventsTab: FunctionComponent = () => {
  const classes = useStyles();

  const navigation = useNavigation<NavigationFilter>();

  const [singleEventId, setSingleEventId] = useState('');
  const [fromEventId, setFromEventId] = useState('');
  const [toEventId, setToEventId] = useState('');

  return (
    <VerticalBlock>
      <VerticalBlock asBlockContent withSeparation>
        <BlockTitle title={i18n`Search single event`} />
        <BlockContent padded>
          <div className={classnames(classes.searchEventContainer, classes.singleEventContainer)}>
            <UsageContextProvider usageVariant={UsageVariant.inForm}>
              <FormTextInputField
                initialValue={singleEventId}
                onChangeDebounced={(eventId) => setSingleEventId(eventId ?? '')}
                placeholder={i18n`Search event id`}
              />
            </UsageContextProvider>
            <Button
              title={i18n`Search event`}
              disabled={!EventIdRegex.test(singleEventId)}
              onClick={() => {
                navigation.push(`explorer:${singleEventId}`, { pathname: `/settings/explorer/event/${singleEventId}` });
              }}
            />
          </div>
        </BlockContent>
      </VerticalBlock>
      <VerticalBlock asBlockContent>
        <BlockTitle title={i18n`Search event range`} />
        <BlockContent padded>
          <div className={classnames(classes.searchEventContainer, classes.rangeEventContainer)}>
            <UsageContextProvider usageVariant={UsageVariant.inForm}>
              <FormTextInputField
                initialValue={fromEventId}
                onChangeDebounced={(eventId) => setFromEventId(eventId ?? '')}
                placeholder={i18n`From event`}
              />
              <FormTextInputField
                initialValue={toEventId}
                onChangeDebounced={(eventId) => setToEventId(eventId ?? '')}
                placeholder={i18n`To event`}
              />
            </UsageContextProvider>
            <Button
              title={i18n`Search event`}
              disabled={!EventIdRegex.test(fromEventId) || !EventIdRegex.test(toEventId)}
              onClick={() => {
                navigation.push(`explorer:${fromEventId}:${toEventId}`, { pathname: `/settings/explorer/event/${fromEventId}/${toEventId}` });
              }}
            />
          </div>
        </BlockContent>
      </VerticalBlock>
    </VerticalBlock>
  );
};

export default ExplorerHomeEventsTab;
