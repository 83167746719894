import composeReactRefs from '@seznam/compose-react-refs';
import type { FunctionComponent, ReactElement, Ref } from 'react';
import { useDrag } from 'react-dnd';
import { getConceptUrl } from 'yooi-modules/modules/conceptModule';
import type { ProgressFieldData } from '../../../../../components/charts/TimelineEntry';
import TimelineEntry from '../../../../../components/charts/TimelineEntry';
import useAcl from '../../../../../store/useAcl';
import useActivity from '../../../../../store/useActivity';
import useStore from '../../../../../store/useStore';
import { Spacing } from '../../../../../theme/spacingDefinition';
import { remToPx } from '../../../../../utils/sizeUtils';
import useNavigation from '../../../../../utils/useNavigation';
import type { NavigationFilter } from '../../../navigationUtils';

const timelineEntryWidthInRem = 28;

interface DraggableConceptItemProps {
  instance: { id: string, label: { id?: string, name: string | undefined }, rightText?: string, progress?: ProgressFieldData | undefined },
  renderTooltip: (conceptId: string, editMode: boolean, currentAnchor: HTMLElement, handleClose: () => void, isChild?: boolean) => ReactElement | null,
  readOnly?: boolean,
  multiplayerPropertyIds: string[],
  conceptRefFunc: Ref<HTMLDivElement>,
  rectColor: string | undefined | null,
  isDraggable: boolean,
  dragKey: string,
  onElementDrag?: ({ id }: { id: string }) => void,
  onElementDragEnd?: ({ id }: { id: string }) => void,
}

const DraggableConceptItem: FunctionComponent<DraggableConceptItemProps> = ({
  instance,
  renderTooltip,
  readOnly = false,
  multiplayerPropertyIds,
  conceptRefFunc,
  rectColor,
  isDraggable = false,
  dragKey,
  onElementDrag,
  onElementDragEnd,
}) => {
  const store = useStore();
  const { canWriteObject } = useAcl();

  const activity = useActivity();

  const navigation = useNavigation<NavigationFilter>();

  const { id, rightText, label } = instance;

  const navigateToConcept = (conceptId: string) => {
    const conceptUrl = getConceptUrl(store, conceptId);
    navigation.push(conceptId, { pathname: conceptUrl });
  };

  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: dragKey,
    item: () => ({ id, conceptId: id }),
    canDrag: isDraggable && !readOnly,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      isDraggingId: monitor.getItem()?.id,
    }),
  }), [isDraggable]);
  const composedRef = composeReactRefs<HTMLDivElement>(conceptRefFunc, dragRef);

  return (
    <div ref={composedRef}>
      <TimelineEntry
        id={id}
        onElementDrag={isDraggable && canWriteObject(id) && !readOnly ? onElementDrag : undefined}
        onElementDragEnd={isDraggable ? onElementDragEnd : undefined}
        renderTooltip={!isDragging ? renderTooltip : undefined}
        rectColor={rectColor}
        rightText={rightText}
        margin={{ right: Spacing.xl, y: Spacing.xs }}
        label={label}
        widthInPx={remToPx(timelineEntryWidthInRem)}
        isEditedByOtherUser={multiplayerPropertyIds && multiplayerPropertyIds.some((propertyId) => activity.listEditor(id, propertyId).length > 0)}
        progress={instance.progress}
        isDraggable={isDraggable && !readOnly}
        onDoubleClick={navigateToConcept}
      />
    </div>
  );
};

export default DraggableConceptItem;
