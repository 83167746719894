import { createModule } from '../common/types/TypeModuleDsl';
import * as AutomationModuleIds from './ids';
import { AutomationModuleId } from './ids';
import migrations from './migrations';

const moduleDsl = createModule({ label: 'Automation' }, AutomationModuleIds);

const { initTypedModule, ...registerModelDsl } = moduleDsl;
export const registerModel = registerModelDsl;

export const initModule = initTypedModule(() => ({
  id: AutomationModuleId,
  migrations,
}));
