import type { FunctionComponent, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { hasPlatformCapability } from 'yooi-modules/modules/conceptModule';
import { PlatformCapabilityAdmin } from 'yooi-modules/modules/conceptModule/ids';
import ErrorPage from '../../components/templates/ErrorPage';
import useAuth from '../../store/useAuth';
import useStore from '../../store/useStore';
import i18n from '../../utils/i18n';

interface AdminPermissionPortalProps {
  children: ReactElement | null,
}

const AdminPermissionPortal: FunctionComponent<AdminPermissionPortalProps> = ({ children }) => {
  const store = useStore();
  const { loggedUserId } = useAuth();

  const navigate = useNavigate();

  if (hasPlatformCapability(store, loggedUserId, PlatformCapabilityAdmin)) {
    return children;
  } else {
    return (
      <ErrorPage
        title={i18n`Access denied`}
        messages={[i18n`You don't have permission to access this page.`, i18n`Please contact an administrator for more information.`]}
        action={{ title: i18n`Go back`, onClick: () => navigate(-1) }}
      />
    );
  }
};

export default AdminPermissionPortal;
