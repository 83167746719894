import type { FunctionComponent, ReactNode } from 'react';
import type { To } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { joinObjects } from 'yooi-utils';
import makeStyles from '../../utils/makeStyles';
import StopClickPropagation from '../atoms/StopClickPropagation';

const useStyles = makeStyles((theme) => ({
  base: {
    color: theme.color.link.default,
    outline: 0,
    textDecoration: 'none',

    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      textDecoration: 'underline',
    },
    '&:disabled': {
      color: theme.color.link.disabled,
    },
    '&:pressed': {
      color: theme.color.link.pressed,
      textDecoration: 'underline',
    },
  },
  noStyle: {
    display: 'contents',
    textDecoration: 'none',
    color: 'inherit',
    '&:link': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '&:visited': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '&:hover': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '&:active': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '&:focus': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
}), 'inlineLink');

interface InlineLinkProps {
  noStyle?: boolean,
  to: To,
  openInNewTab?: boolean,
  replace?: boolean,
  children: ReactNode,
  state?: Record<string, unknown>,
  onClick?: () => void,
  isUserControlled?: boolean,
  onMouseOver?: () => void,
  onMouseOut?: () => void,
  onFocus?: () => void,
  onBlur?: () => void,
}

/**
 * The link is available is different variants to address different use cases:
 * <ul>
 *   <li>Navigation inside the application using relative or absolute path (without http(s))</li>
 *   <li>Navigation to an external website (using http(s))</li>
 * </ul>
 */
const InlineLink: FunctionComponent<InlineLinkProps> = ({
  noStyle = false,
  to,
  openInNewTab = false,
  replace = false,
  children,
  state,
  onClick,
  isUserControlled = false,
  onMouseOver,
  onMouseOut,
  onFocus,
  onBlur,
}) => {
  const classes = useStyles();

  const externalLink = typeof to === 'string' && (to.startsWith('http://') || to.startsWith('https://'));

  return (
    <StopClickPropagation>
      {externalLink ? (
        <a
          className={noStyle ? classes.noStyle : classes.base}
          href={to}
          rel="noopener noreferrer"
          target="_blank"
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          {children}
        </a>
      ) : (
        <RouterLink
          className={noStyle ? classes.noStyle : classes.base}
          to={to}
          replace={replace}
          rel={openInNewTab ? 'noopener noreferrer' : undefined}
          target={openInNewTab ? '_blank' : undefined}
          state={joinObjects(state, { isUserControlled })}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          {children}
        </RouterLink>
      )}
    </StopClickPropagation>
  );
};

export default InlineLink;
