import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import type { YearRepeat } from 'yooi-modules/modules/automationModule';
import type { DayNumbers, MonthNumbers } from 'yooi-utils';
import { joinObjects } from 'yooi-utils';
import Typo from '../../../components/atoms/Typo';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../components/molecules/SpacingLine';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import type { Option } from '../../_global/modelTypeUtils';
import { getDaysOfMonth, getMonthDayLabel } from './automationUtils';
import { getWeekOptions, isWeekOption } from './MonthRepeatRuleInput';
import { getWeekDayOptions } from './WeekRepeatRuleInput';

const useStyles = makeStyles({
  dayInputSpacings: {
    display: 'grid',
    gridAutoFlow: 'column',
    columnGap: spacingRem.m,
    gridTemplateColumns: '12rem 12rem 6rem',
    alignItems: 'center',
  },
  weekDayInputSpacings: {
    display: 'grid',
    gridAutoFlow: 'column',
    columnGap: spacingRem.m,
    gridTemplateColumns: '12rem 12rem 2rem 12rem',
    alignItems: 'center',
  },
}, 'yearRepeatRuleInput');

interface YearRepeatRuleInputProps {
  repeatRule: YearRepeat,
  onChange: (repeatRule: YearRepeat) => void,
}

interface MonthOption extends Option {
  value: MonthNumbers,
  textColor?: string,
}

const getMonthOptions = (): MonthOption[] => [
  { id: '1', value: 1, label: getMonthDayLabel(1) },
  { id: '2', value: 2, label: getMonthDayLabel(2) },
  { id: '3', value: 3, label: getMonthDayLabel(3) },
  { id: '4', value: 4, label: getMonthDayLabel(4) },
  { id: '5', value: 5, label: getMonthDayLabel(5) },
  { id: '6', value: 6, label: getMonthDayLabel(6) },
  { id: '7', value: 7, label: getMonthDayLabel(7) },
  { id: '8', value: 8, label: getMonthDayLabel(8) },
  { id: '9', value: 9, label: getMonthDayLabel(9) },
  { id: '10', value: 10, label: getMonthDayLabel(10) },
  { id: '11', value: 11, label: getMonthDayLabel(11) },
  { id: '12', value: 12, label: getMonthDayLabel(12) },
];

const YearRepeatRuleInput: FunctionComponent<YearRepeatRuleInputProps> = ({ repeatRule, onChange }) => {
  const classes = useStyles();

  const specificDayOption = { id: '0', label: i18n`Specific day` };

  return (
    <SpacingLine>
      <div
        className={classnames({
          [classes.weekDayInputSpacings]: Boolean(repeatRule.selectedRule === 'weekDayRule'),
          [classes.dayInputSpacings]: Boolean(repeatRule.selectedRule === 'dayRule'),
        })}
      >
        <SearchAndSelect
          computeOptions={() => [specificDayOption, ...getWeekOptions()]}
          placeholder={i18n`Select a frequency`}
          selectedOption={repeatRule.selectedRule === 'dayRule' ? specificDayOption : getWeekOptions().find(({ value }) => value === repeatRule.weekDayRule.week)}
          onSelect={(option) => {
            if (option && isWeekOption(option)) {
              onChange(joinObjects(repeatRule, {
                selectedRule: 'weekDayRule' as const,
                weekDayRule: joinObjects(repeatRule.weekDayRule, { week: option.value }),
              }));
            } else {
              onChange(joinObjects(repeatRule, { selectedRule: 'dayRule' as const }));
            }
          }}
        />
        {Boolean(repeatRule.selectedRule === 'dayRule') && (
          <>
            <SearchAndSelect
              computeOptions={getMonthOptions}
              placeholder={i18n`Select a frequency`}
              selectedOption={getMonthOptions().find(({ value }) => value === repeatRule.month)}
              onSelect={(option) => {
                if (option) {
                  const currentDay = repeatRule.dayRule.day;
                  const daysOfMonth = getDaysOfMonth(option.value);
                  if (daysOfMonth.includes(currentDay)) {
                    onChange(joinObjects(repeatRule, { month: option.value }));
                  } else {
                    onChange(joinObjects(repeatRule, { month: option.value, dayRule: joinObjects(repeatRule.dayRule, { day: daysOfMonth[daysOfMonth.length - 1] }) }));
                  }
                }
              }}
            />
            <SearchAndSelect
              computeOptions={() => getDaysOfMonth(repeatRule.month).map((d) => ({ label: d.toString(), id: d.toString() }))}
              selectedOption={{ label: repeatRule.dayRule.day.toString(), id: repeatRule.dayRule.day.toString() }}
              onSelect={(option) => {
                if (option) {
                  onChange(joinObjects(repeatRule, { dayRule: joinObjects(repeatRule.dayRule, { day: Number(option.id) as DayNumbers }) }));
                }
              }}
              minWidth={6}
            />
          </>
        )}
        {Boolean(repeatRule.selectedRule === 'weekDayRule') && (
          <>
            <SearchAndSelect
              computeOptions={getWeekDayOptions}
              placeholder={i18n`Select a frequency`}
              selectedOption={getWeekDayOptions().find(({ value }) => value === repeatRule.weekDayRule.weekDay)}
              onSelect={(option) => {
                if (option) {
                  onChange(joinObjects(repeatRule, { weekDayRule: joinObjects(repeatRule.weekDayRule, { weekDay: option.value }) }));
                }
              }}
            />
            <Typo>{i18n`of`}</Typo>
            <SearchAndSelect
              computeOptions={getMonthOptions}
              placeholder={i18n`Select a frequency`}
              selectedOption={getMonthOptions().find(({ value }) => value === repeatRule.month)}
              onSelect={(option) => {
                if (option) {
                  onChange(joinObjects(repeatRule, { month: option.value }));
                }
              }}
            />
          </>
        )}
      </div>
    </SpacingLine>
  );
};

export default YearRepeatRuleInput;
