import type { FunctionComponent } from 'react';
import { v4 as uuid } from 'uuid';
import type { CardFieldButton } from 'yooi-modules/modules/conceptLayoutModule';
import { CardFieldActionTypes } from 'yooi-modules/modules/dashboardModule';
import { joinObjects } from 'yooi-utils';
import { ButtonVariant } from '../../../../components/atoms/Button';
import { IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import DataTable from '../../../../components/templates/DataTable';
import i18n from '../../../../utils/i18n';
import useNewLineFocus from '../../../../utils/useNewLineFocus';
import StoreTextInputField from '../../input/StoreTextInputField';
import type { OptionRecord } from '../../modelTypeUtils';
import CardFieldActionComposite from './CardFieldActionComposite';

interface CardFieldButtonInputProps {
  initialValue: CardFieldButton[] | undefined,
  onUpdate: (value: CardFieldButton[]) => void,
}

const CardFieldButtonInput: FunctionComponent<CardFieldButtonInputProps> = ({ initialValue = [], onUpdate }) => {
  const [newLineFocus, setNewLineFocus] = useNewLineFocus();

  const variantOptions: OptionRecord<Exclude<CardFieldButton['variant'], undefined>> = {
    primary: { id: 'primary', label: i18n`Primary` },
    secondary: { id: 'secondary', label: i18n`Secondary` },
    tertiary: { id: 'tertiary', label: i18n`Tertiary` },
  };

  return (
    <DataTable
      columnsDefinition={[
        {
          propertyId: 'label',
          name: i18n`Label`,
          width: 25,
          focusable: true,
          cellRender: ({ id, label }, focusOnMount) => (
            <StoreTextInputField
              initialValue={label}
              onSubmit={(value) => {
                onUpdate(initialValue.map((config) => (config.id === id ? joinObjects(config, { label: value ?? '' }) : config)));
              }}
              focusOnMount={focusOnMount}
            />
          ),
        },
        {
          propertyId: 'variant',
          name: i18n`Variant`,
          width: 14,
          cellRender: ({ id, variant }) => (
            <SearchAndSelect
              selectedOption={variantOptions[variant ?? 'primary']}
              computeOptions={() => Object.values(variantOptions)}
              onSelect={(option) => {
                onUpdate(initialValue.map((config) => (config.id === id ? joinObjects(config, { variant: option?.id }) : config)));
              }}
            />
          ),
        },
        {
          propertyId: 'action',
          name: i18n`Action`,
          width: 61,
          cellRender: (buttonConfig) => (
            <CardFieldActionComposite
              value={buttonConfig}
              onSubmit={(newConfig) => onUpdate(initialValue.map((config) => (config.id === newConfig.id ? newConfig : config)))}
            />
          ),
        },
        {
          propertyId: 'moveUp',
          action: true,
          cellRender: (buttonConfig, _, index) => (
            <IconOnlyButton
              onClick={() => {
                const previousCell = initialValue[index - 1];
                const updatedTable = [...initialValue];
                updatedTable[index - 1] = buttonConfig;
                updatedTable[index] = previousCell;
                onUpdate(updatedTable);
              }}
              disabled={index === 0}
              iconName={IconName.expand_less}
              tooltip={i18n`Move Up`}
              variant={IconOnlyButtonVariants.tertiary}
            />
          ),
        },
        {
          propertyId: 'moveDown',
          action: true,
          cellRender: (buttonConfig, _, index) => (
            <IconOnlyButton
              onClick={() => {
                const nextCell = initialValue[index + 1];
                const updatedTable = [...initialValue];
                updatedTable[index + 1] = buttonConfig;
                updatedTable[index] = nextCell;
                onUpdate(updatedTable);
              }}
              disabled={index === initialValue.length - 1}
              iconName={IconName.expand_more}
              tooltip={i18n`Move Down`}
              variant={IconOnlyButtonVariants.tertiary}
            />
          ),
        },
      ]}
      list={initialValue.map((item) => ({ key: item.id, type: 'item', item, color: undefined }))}
      linesActions={(buttonConfig) => [
        {
          key: 'delete',
          name: i18n`Remove`,
          icon: IconName.delete,
          danger: true,
          onClick: () => {
            onUpdate(initialValue.filter((button) => button.id !== buttonConfig.id));
          },
        },
      ]}
      newItemIcon={IconName.add}
      newItemTitle={i18n`Add`}
      newItemButtonVariant={ButtonVariant.tertiary}
      newLineFocus={newLineFocus?.current}
      onNewItem={() => {
        const buttonTable = initialValue;
        const buttonId = uuid();
        buttonTable.push({ id: buttonId, action: CardFieldActionTypes.OpenUrl });
        onUpdate(buttonTable);
        setNewLineFocus(buttonId);
      }}
      fullWidth
    />
  );
};

export default CardFieldButtonInput;
