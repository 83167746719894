import type { FunctionComponent } from 'react';
import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { isFieldStep, isGlobalDimensionStep } from 'yooi-modules/modules/conceptModule';
import { AssociationField, EmbeddingField, KinshipRelationField, RelationMultipleField, RelationSingleField, ReverseWorkflowField } from 'yooi-modules/modules/conceptModule/ids';
import type { ViewDimension, ViewDimensionProperties } from 'yooi-modules/modules/dashboardModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { ButtonVariant } from '../../../../components/atoms/Button';
import Checkbox from '../../../../components/atoms/Checkbox';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import CompositeField from '../../../../components/molecules/CompositeField';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import DataTable from '../../../../components/templates/DataTable';
import useStore from '../../../../store/useStore';
import { Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import StoreTextInputField from '../../input/StoreTextInputField';
import PathMappingAndFiltersInput from '../../path/PathMappingAndFiltersInput';
import PathStepsInput from '../../path/PathStepsInput';
import { createPathConfigurationHandler, StepValidationState } from '../../pathConfigurationHandler';
import { getDimensionLabel } from '../../views/data/dataResolution';

const viewEnabledFieldTypeIds = [
  AssociationField,
  EmbeddingField,
  KinshipRelationField,
  RelationMultipleField,
  RelationSingleField,
  ReverseWorkflowField,
];

interface ViewsFieldDimensionsOptionsProps {
  dimensions: ViewDimension[],
  onUpdateDimension: (dimensionId: string, properties: Partial<ViewDimensionProperties>) => void,
  onCreateDimension: () => void,
  onDeleteDimension: (dimensionId: string) => void,
  onMoveUpDimension: (index: number) => void,
  onMoveDownDimension: (index: number) => void,
  readOnly: boolean,
  parameterDefinitions: SingleParameterDefinition[],
}

const useStyles = makeStyles({
  buttonOptions: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
  },
}, 'viewsFieldDimensionsOptions');

const ViewsFieldDimensionsOptions: FunctionComponent<ViewsFieldDimensionsOptionsProps> = ({
  dimensions,
  onCreateDimension,
  onUpdateDimension,
  onDeleteDimension,
  onMoveUpDimension,
  onMoveDownDimension,
  readOnly,
  parameterDefinitions,
}) => {
  const classes = useStyles();

  const store = useStore();

  const pathConfigurationHandler = createPathConfigurationHandler(
    store,
    parameterDefinitions,
    [
      ({ pathStep: lastStep, path: validatorPath }) => {
        const result = [];
        const instanceErrorMessage = i18n`Input should end with one or more instances.`;
        if (validatorPath.some((step) => isGlobalDimensionStep(step))) {
          result.push({ state: StepValidationState.invalid });
        } else if (isFieldStep(lastStep)) {
          const fieldDefinitionId = store.getObjectOrNull(lastStep.fieldId)?.[Instance_Of] as string;
          const isRelationField = viewEnabledFieldTypeIds.includes(fieldDefinitionId);
          if (isRelationField) {
            result.push({ state: StepValidationState.valid });
          } else {
            result.push({ state: StepValidationState.invalid, reasonMessage: instanceErrorMessage });
          }
        } else {
          result.push({ state: StepValidationState.valid });
        }
        return result;
      },
    ]
  );
  return (
    <DataTable<ViewDimension>
      columnsDefinition={[
        {
          name: i18n`Label`,
          propertyId: 'Label',
          width: 20,
          cellRender: ({ id, label, path }, _, index) => (
            <StoreTextInputField
              initialValue={getDimensionLabel(store, label, index, path)}
              onSubmit={(value) => {
                onUpdateDimension(id, { label: value ?? undefined });
              }}
              readOnly={readOnly}
            />
          ),
        },
        {
          name: i18n`Path`,
          propertyId: 'Path',
          width: 40,
          cellRender: ({ id, path }) => (
            <PathStepsInput
              initialPath={path}
              onSubmit={(newPath) => {
                onUpdateDimension(id, { path: newPath });
              }}
              readOnly={readOnly}
              valuePathHandler={pathConfigurationHandler}
              parameterDefinitions={parameterDefinitions}
            />
          ),
        },
        {
          propertyId: 'filters',
          name: i18n`Filters`,
          width: 20,
          cellRender: ({ id, path }) => (
            <PathMappingAndFiltersInput
              path={path}
              onChange={(newPath) => {
                onUpdateDimension(id, { path: newPath });
              }}
              readOnly={readOnly}
              valuePathHandler={pathConfigurationHandler}
              parameterDefinitions={parameterDefinitions}
            />
          ),
        },
        {
          name: i18n`Display options`,
          propertyId: 'display',
          width: 20,
          cellRender: ({ id, display }) => (
            <CompositeField
              readOnly={readOnly}
              width="40rem"
              headerLinesRenderers={[
                {
                  id: 'title',
                  render: () => {
                    const displayOptionLabels = [
                      display?.withNotSet ? i18n`With not set` : undefined,
                      display?.withAll ? i18n`With all` : undefined,
                    ].filter(filterNullOrUndefined);

                    const dimensionDisplayOptionsTitle = displayOptionLabels.length === 0 ? i18n`No options set` : displayOptionLabels.join(', ');
                    return (
                      <SpacedContainer margin={{ y: Spacing.xs }}>
                        <div className={classes.buttonOptions}>
                          <SpacedContainer margin={{ right: Spacing.s }}>
                            <div className={classes.iconContainer}>
                              <Icon colorVariant={IconColorVariant.alternative} name={IconName.tune} />
                            </div>
                          </SpacedContainer>
                          <Tooltip title={dimensionDisplayOptionsTitle}>
                            <Typo maxLine={1}>{dimensionDisplayOptionsTitle}</Typo>
                          </Tooltip>
                        </div>
                      </SpacedContainer>
                    );
                  },
                },
              ]}
              getDropdownSectionDefinitions={() => ([
                {
                  id: 'displayOptions',
                  lines: [
                    {
                      id: 'withNotSet',
                      isVertical: false,
                      title: i18n`With not set`,
                      render: (
                        <SpacingLine>
                          <Checkbox
                            checked={Boolean(display.withNotSet)}
                            onChange={(value) => {
                              onUpdateDimension(id, { display: joinObjects(display, { withNotSet: value }) });
                            }}
                            disabled={readOnly}
                          />
                        </SpacingLine>
                      ),
                    },
                    {
                      id: 'withAll',
                      isVertical: false,
                      title: i18n`With all`,
                      render: (
                        <SpacingLine>
                          <Checkbox
                            checked={Boolean(display.withAll)}
                            onChange={(value) => {
                              onUpdateDimension(id, { display: joinObjects(display, { withAll: value }) });
                            }}
                            disabled={readOnly}
                          />
                        </SpacingLine>
                      ),
                    },
                  ],
                },
              ])}
            />
          ),
        }, {
          propertyId: 'MoveUp',
          action: true,
          cellRender: (_, __, index) => (
            <SpacingLine>
              <IconOnlyButton
                disabled={readOnly || index === 0}
                onClick={() => onMoveUpDimension(index)}
                iconName={IconName.expand_less}
                tooltip={i18n`Move Up`}
                variant={IconOnlyButtonVariants.tertiary}
              />
            </SpacingLine>
          ),
        }, {
          propertyId: 'MoveDown',
          action: true,
          cellRender: (_, __, index) => (
            <SpacingLine>
              <IconOnlyButton
                disabled={readOnly || index === dimensions.length - 1}
                onClick={() => onMoveDownDimension(index)}
                iconName={IconName.expand_more}
                tooltip={i18n`Move Down`}
                variant={IconOnlyButtonVariants.tertiary}
              />
            </SpacingLine>
          ),
        },
        {
          propertyId: 'Delete',
          action: true,
          cellRender: ({ id }) => (
            <SpacingLine>
              <IconOnlyButton
                disabled={readOnly}
                onClick={() => onDeleteDimension(id)}
                iconName={IconName.delete}
                tooltip={i18n`Delete`}
                variant={IconOnlyButtonVariants.danger}
              />
            </SpacingLine>
          ),
        },
      ]}
      list={dimensions.map((item) => ({ key: item.id, type: 'item', item, color: undefined }))}
      newItemIcon={IconName.add}
      newItemTitle={i18n`Add`}
      newItemButtonVariant={ButtonVariant.tertiary}
      onNewItem={readOnly ? undefined : () => onCreateDimension()}
    />
  );
};

export default ViewsFieldDimensionsOptions;
