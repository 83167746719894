import type { YieldMethod } from 'yooi-utils';
import { sleep } from 'yooi-utils';

export const createRenderYieldStrategy = (maxDurationBeforeRendering = 14): YieldMethod => {
  let hasYield: (() => Promise<boolean>) | undefined;

  const monitorRendering = () => {
    const yieldTimeout = Date.now() + maxDurationBeforeRendering;
    const yieldPromise = new Promise<void>((resolve) => {
      window.requestAnimationFrame(() => {
        hasYield = undefined;
        resolve();
      });
    });

    hasYield = async () => {
      if (Date.now() > yieldTimeout) {
        await yieldPromise;
        return true;
      } else {
        return false;
      }
    };
  };

  return async (force) => {
    if (force) {
      await sleep(0);
      return true;
    } else {
      let result = false;
      while (await hasYield?.()) {
        result = true;
      }

      if (!hasYield) {
        monitorRendering();
      }
      return result;
    }
  };
};
