import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import ReactGridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { useDebounce } from 'use-debounce';
import useResizeObserver from 'use-resize-observer';
import { compareNumber, compareProperty, comparing } from 'yooi-utils';
import base from '../../theme/base';
import makeSelectorsClasses from '../../utils/makeSelectorsClasses';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import { DashboardSizeContextProvider } from '../../utils/useDashboardSizeContext';
import WidgetContainer from '../atoms/WidgetContainer';
import WidgetContextProvider from '../atoms/WidgetContextProvider';
import WidgetResizeHandle from '../atoms/WidgetResizeHandle';

const selectorsClasses = makeSelectorsClasses('dragHandler');

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerDisableTransition: {
    '& .react-grid-item': {
      transition: 'none',
    },
  },
  grid: {
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
    '& .react-grid-item.react-grid-placeholder': {
      backgroundColor: theme.color.background.primarylight.default,
      opacity: 1,
      borderRadius: base.borderRadius.medium,
      zIndex: 0,
    },
    '& .react-grid-item:not(.react-grid-placeholder)': {
      overflow: 'hidden',
      borderRadius: base.borderRadius.medium,
      backgroundColor: theme.color.background.neutral.default,
    },
  },
}), 'dashboardGrid');

interface DashboardGridProps {
  layout: {
    id: string,
    x: number,
    y: number,
    w: number,
    h: number,
    padded: boolean,
    borderless: boolean,
    backgroundColor: string | undefined,
    renderWidget: (dragHandlerClassName: string) => ReactElement | null,
  }[],
  onLayoutChanged?: (newLayout: { id: string, x: number, y: number, w: number, h: number }[]) => void,
  isResizable?: boolean,
  isDraggable?: boolean,
  editionMode?: boolean,
}

const DashboardGrid: FunctionComponent<DashboardGridProps> = ({ layout, onLayoutChanged, isResizable = false, isDraggable = false, editionMode = false }) => {
  const classes = useStyles();

  const containerRef = useRef<HTMLDivElement>(null);
  const disableTransitionRef = useRef(true);

  const [width, setWidth] = useDebounce<number | undefined>(undefined, 25);
  const [resizingKey, setResizingKey] = useState<string | undefined>(undefined);

  useLayoutEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.clientWidth);
      if (disableTransitionRef.current) {
        // Remove class that prevent transition after 250ms
        const timeout = setTimeout(() => {
          if (containerRef.current && disableTransitionRef.current) {
            containerRef.current.className = containerRef.current.className.split(' ').filter((c) => c !== classes.containerDisableTransition).join(' ');
          }
        }, 250);
        return () => clearTimeout(timeout);
      }
    }
    return () => {};
  }, [classes, setWidth]);

  const { ref: resizeRef } = useResizeObserver({
    onResize: (size) => {
      if (size.width) {
        setWidth(size.width);
      }
    },
  });

  return (
    <div
      ref={composeReactRefs<HTMLDivElement>(containerRef, resizeRef)}
      className={classnames({ [classes.container]: true, [classes.containerDisableTransition]: disableTransitionRef.current })}
    >
      {
        width === undefined
          ? null
          : (
            <DashboardSizeContextProvider width={width}>
              <ReactGridLayout
                width={width}
                margin={[remToPx(0.8), remToPx(0.8)]}
                className={classes.grid}
                layout={layout.map(({ id, x, y, w, h }) => ({ i: id, x, y, w, h, minW: 2, minH: 3 }))}
                cols={24}
                rowHeight={remToPx(0.8)}
                onLayoutChange={onLayoutChanged ? (newLayout) => onLayoutChanged(newLayout.map(({ i, x, y, h, w }) => ({ id: i, x, y, h, w }))) : undefined}
                useCSSTransforms
                draggableHandle={`.${selectorsClasses.dragHandler}`}
                isResizable={isResizable}
                isDraggable={isDraggable}
                onResizeStart={(_, __, newItem) => {
                  setResizingKey(newItem.i);
                }}
                onResizeStop={() => {
                  setResizingKey(undefined);
                }}
                resizeHandle={editionMode ? <WidgetResizeHandle /> : undefined}
              >
                {
                  layout
                    .sort(comparing<typeof layout[0]>(compareProperty('y', compareNumber)).thenComparing(compareProperty('x', compareNumber)))
                    .map(({ id, padded, borderless, backgroundColor, renderWidget }) => (
                      <WidgetContainer key={id} widgetId={id} borderless={borderless} backgroundColor={backgroundColor} editionMode={editionMode} resizingKey={resizingKey}>
                        <WidgetContextProvider padded={padded} backgroundColor={backgroundColor}>
                          {renderWidget(selectorsClasses.dragHandler)}
                        </WidgetContextProvider>
                      </WidgetContainer>
                    ))
                }
              </ReactGridLayout>
            </DashboardSizeContextProvider>
          )
      }
    </div>
  );
};

export default DashboardGrid;
