import type { FunctionComponent } from 'react';
import { useRef } from 'react';
import { isFiniteNumber } from 'yooi-utils';
import NumberPicker from '../../../../../components/inputs/NumberPicker';
import Chooser from '../../../../../components/molecules/Chooser';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import i18n from '../../../../../utils/i18n';
import useForceUpdate from '../../../../../utils/useForceUpdate';

interface WidthPickerProps {
  value: { type: 'percent' | 'rem', value: number } | undefined,
  onChange: (newWidth: { type: 'percent' | 'rem', value: number } | undefined) => void,
}

const WidthPicker: FunctionComponent<WidthPickerProps> = ({ value, onChange }) => {
  const forceUpdate = useForceUpdate();

  const selectedTypeRef = useRef<'percent' | 'rem'>(value?.type ?? 'percent');
  if (value !== undefined && value.type !== selectedTypeRef.current) {
    selectedTypeRef.current = value.type;
  }

  return (
    <SpacingLine>
      <NumberPicker
        placeholder={i18n`Size`}
        value={value?.value}
        onChange={(width) => {
          if (isFiniteNumber(width)) {
            onChange({ type: selectedTypeRef.current, value: Number(width) });
          } else {
            onChange(undefined);
          }
        }}
        decimals={0}
        min={0}
      />
      <Chooser
        actions={[{ key: 'percent', name: '%' }, { key: 'rem', name: 'rem' }]}
        selectedIndexes={[selectedTypeRef.current === 'rem' ? 1 : 0]}
        onClick={(selectedIndex) => {
          selectedTypeRef.current = selectedIndex === 0 ? 'percent' : 'rem';
          forceUpdate();
          if (value !== undefined) {
            onChange({ type: selectedTypeRef.current, value: value.value });
          }
        }}
      />
    </SpacingLine>
  );
};

export default WidthPicker;
