import type { FunctionComponent, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { joinObjects } from 'yooi-utils';
import BaseLayout from '../../../components/templates/BaseLayout';
import Header from '../../../components/templates/Header';
import HeaderTabs from '../../../components/templates/HeaderTabs';
import i18n from '../../../utils/i18n';
import useNavigation, { NavigationElementContainer } from '../../../utils/useNavigation';
import HeaderStatic from '../../_global/HeaderStatic';
import type { NavigationFilter } from '../../_global/navigationUtils';
import TopBar from '../../_global/topBar/TopBar';
import GeneralTab from './GeneralTab';
import HomepageTab from './HomepageTab';
import LeftBarTab from './LeftBarTab';
import ThemeTab from './ThemeTab';

const CustomizationPage: FunctionComponent = () => {
  const navigation = useNavigation<NavigationFilter>();
  const location = useLocation();

  // Keep in component for i18n
  const tabs: { key: string, hash: string, name: string, render: () => ReactElement | null }[] = [
    {
      key: 'general',
      hash: '#general',
      name: i18n`General`,
      render: () => (<GeneralTab />),
    },
    {
      key: 'theme',
      hash: '#theme',
      name: i18n`Theme`,
      render: () => (<ThemeTab />),
    },
    {
      key: 'homepage',
      hash: '#homepage',
      name: i18n`Homepage`,
      render: () => (<HomepageTab />),
    },
    {
      key: 'leftBar',
      hash: '#leftBar',
      name: i18n`Left bar`,
      render: () => (<LeftBarTab />),
    },
  ];

  const tabIndex = tabs.findIndex((tab) => tab.hash === location.hash);
  const selectedTabIndex = tabIndex >= 0 ? tabIndex : 0;

  return (
    <NavigationElementContainer element={{ key: 'customization' }}>
      <BaseLayout
        topBar={(<TopBar />)}
        header={(
          <Header
            firstLine={(<HeaderStatic text={i18n`Customization`} />)}
            tabsLine={(
              <HeaderTabs
                selectedTabIndex={selectedTabIndex}
                tabs={tabs}
                onSelectedIndexChanged={(index) => {
                  navigation.replace('customization', joinObjects(location, { hash: tabs[index].hash }));
                }}
              />
            )}
          />
        )}
        content={tabs[selectedTabIndex].render()}
      />
    </NavigationElementContainer>
  );
};

export default CustomizationPage;
