import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useMemo, useState } from 'react';
import type { CollaborationStoreObject } from 'yooi-modules/modules/collaborationModule';
import {
  buildCollaborationLocation,
  getCollaborationContextsObjectIds,
  getCollaborationsForGroup,
  getCollaborationsForRole,
  getCollaborationsForUserId,
} from 'yooi-modules/modules/collaborationModule';
import {
  Collaboration_CreatedAt,
  Collaboration_Intention,
  CollaborationMessage_CreatedBy,
  CollaborationMessage_Text,
  CollaborationUsers,
  CollaborationUsers_Role_Collaboration,
  CollaborationUsers_Role_User,
} from 'yooi-modules/modules/collaborationModule/ids';
import { getGroupsForUser, getInstanceLabel, getInstanceLabelOrUndefined, getRolesForUser, isCollaborationClosed } from 'yooi-modules/modules/conceptModule';
import {
  Concept,
  ConceptDefinition,
  ConceptRoleUserAssignation,
  ConceptRoleUserAssignation_Role_Concept,
  ConceptRoleUserAssignation_Role_ConceptRole,
  ConceptRoleUserAssignation_Role_User,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareString, comparing, extractAndCompareValue, filterNullOrUndefined, joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../components/atoms/IconOnlyButton';
import Tooltip from '../../components/atoms/Tooltip';
import Typo from '../../components/atoms/Typo';
import TextInputString from '../../components/inputs/TextInputString';
import Chooser from '../../components/molecules/Chooser';
import CompositeField, { CompositeFieldVariants } from '../../components/molecules/CompositeField';
import SearchAndSelect from '../../components/molecules/SearchAndSelect';
import { TableSortDirection } from '../../components/molecules/Table';
import TableInnerCellContainer, { TableInnerCellContainerVariants } from '../../components/molecules/TableInnerCellContainer';
import TagContainer from '../../components/molecules/TagContainer';
import Toggle from '../../components/molecules/Toggle';
import ToggleButton, { ElementPosition } from '../../components/molecules/ToggleButton';
import BlockContent from '../../components/templates/BlockContent';
import DataTable from '../../components/templates/DataTable';
import useAuth from '../../store/useAuth';
import useStore from '../../store/useStore';
import base from '../../theme/base';
import { buildMargins, buildPadding, Spacing, spacingRem } from '../../theme/spacingDefinition';
import { formatENDisplayDate } from '../../utils/dateUtilsFront';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { safeSessionStorageValue } from '../../utils/sessionStorageUtils';
import { formatOrUndef } from '../../utils/stringUtils';
import useDerivedState from '../../utils/useDerivedState';
import useNavigation from '../../utils/useNavigation';
import { SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import SearchTextButton from './filter/SearchTextButton';
import SearchTextInput from './filter/SearchTextInput';
import { FilterElement, FilterParams, useFilterStorage } from './filter/useFilterSessionStorage';
import { searchFilterFunction } from './listFilterFunctions';
import { defaultOptionComparator, getChipOptions, getUnknownChip } from './modelTypeUtils';
import type { NavigationFilter } from './navigationUtils';
import { getCurrentNavigationId } from './navigationUtils';
import {
  getCollaborationMessages,
  getCollaborationsUnreadMessageCount,
  getIntentions,
  getLastUpdatedDateForCollaboration,
  getSubscriptionInstanceForCollaboration,
} from './rightPanel/collaboration/utils/collaborationUtils';
import useFilterAndSort, { buildNumberColumnComparatorHandler, buildStringColumnComparatorHandler } from './useFilterAndSort';

const useStyles = makeStyles({
  configContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: spacingRem.s,
  },
  configContainerInWidget: buildMargins({ bottom: Spacing.s }),
  titleContainer: buildPadding({ x: Spacing.s }),
  filtersTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: spacingRem.s,
    flexGrow: 1,
    alignItems: 'center',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.s,
    paddingTop: spacingRem.xs,
  },
  filtersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: spacingRem.s,
    justifyContent: 'flex-start',
    marginBottom: spacingRem.s,
  },
  toggleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingRem.s,
  },
  filtersButtonContainer: {
    display: 'flex',
    gap: spacingRem.xs,
  },
  unreadButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.xs,
    paddingRight: spacingRem.s,
  },
  opacity: {
    opacity: base.opacity.fifty,
  },
}, 'subscriptionTable');

interface SubscriptionTableProps {
  numberOfItemsPerPage?: number,
  usedInBlock?: boolean,
}

interface SubscriptionFilterConfiguration {
  nameSearch?: string,
  authorFilters?: string[],
  intentionFilters?: string[],
  instanceFilters?: string[],
  unreadFilters?: boolean,
}

enum SubscriptionType {
  user = 'user',
  groupOrRole = 'groupOrRole',
  userAndGroupOrRole = 'userAndGroupOrRole',
  unsubscribed = 'unsubscribed',
}

const SubscriptionTable: FunctionComponent<SubscriptionTableProps> = ({ numberOfItemsPerPage = 20, usedInBlock = false }) => {
  const classes = useStyles();

  const store = useStore();
  const { loggedUserId } = useAuth();
  const theme = useTheme();

  const navigation = useNavigation<NavigationFilter>();

  const chooserActions = [
    {
      key: 'all',
      name: i18n`All`,
    },
    {
      key: 'opened',
      name: i18n`Opened`,
    },
    {
      key: 'closed',
      name: i18n`Closed`,
    },
  ];
  const [collaborationsStatusState, setCollaborationsStatusState] = useDerivedState(() => ('all'), []);

  const filterId = FilterElement.subscriptionFilters;
  const instanceFilterId = FilterElement.subscriptionsInstancesFilters;
  const authorFilterId = FilterElement.subscriptionsAuthorFilters;
  const intentionFilterId = FilterElement.subscriptionsIntentionFilters;
  const [showFilters, setShowFilters] = useState(false);
  const [instancesSearch] = useFilterStorage(instanceFilterId, FilterParams.nameSearch);
  const [showInstancesSearchBar, setShowInstancesSearchBar] = useState(instancesSearch !== undefined);
  const [authorsSearch] = useFilterStorage(authorFilterId, FilterParams.nameSearch);
  const [showAuthorSearchBar, setShowAuthorSearchBar] = useState(authorsSearch !== undefined);
  const [intentionSearch] = useFilterStorage(intentionFilterId, FilterParams.nameSearch);
  const [showIntentionSearchBar, setShowIntentionSearchBar] = useState(intentionSearch !== undefined);
  const [showUnreadMessagesOnly = false, setShowUnreadMessagesOnly] = (
    useFilterStorage<'unreadFilters', SubscriptionFilterConfiguration>(filterId, 'unreadFilters')
  );
  const [authorFilters = [], setAuthorFilters] = useFilterStorage<'authorFilters', SubscriptionFilterConfiguration>(filterId, 'authorFilters');
  const [intentionFilters = [], setIntentionFilters] = useFilterStorage<'intentionFilters', SubscriptionFilterConfiguration>(filterId, 'intentionFilters');
  const [instanceFilters = [], setInstanceFilters] = useFilterStorage<'instanceFilters', SubscriptionFilterConfiguration>(filterId, 'instanceFilters');

  const [unsubscribedCollaborations, setUnsubscribedCollaborations] = useState<{
    collaboration: CollaborationStoreObject,
    subscriptionType: SubscriptionType,
    collaborationStatus: string,
  }[]>([]);
  const unsubscriptions: { collaboration: CollaborationStoreObject, subscriptionType: SubscriptionType, collaborationStatus: string }[] = useMemo(
    () => (unsubscribedCollaborations),
    [unsubscribedCollaborations]
  );

  let filterCount = 0;
  if (showUnreadMessagesOnly) {
    filterCount += 1;
  }
  filterCount += (authorFilters.length > 0 ? 1 : 0);
  filterCount += (intentionFilters.length > 0 ? 1 : 0);
  filterCount += (instanceFilters.length > 0 ? 1 : 0);

  const allCollaborations: { collaboration: CollaborationStoreObject, subscriptionType: SubscriptionType, collaborationStatus: string }[] = [];

  const loggedUserCollaborations = getCollaborationsForUserId(store, loggedUserId)
    .filter((collaboration) => {
      if (collaborationsStatusState === 'opened') {
        return !isCollaborationClosed(store, collaboration);
      } else if (collaborationsStatusState === 'closed') {
        return isCollaborationClosed(store, collaboration);
      } else {
        return collaboration;
      }
    })
    .map((collaboration) => ({ collaboration, subscriptionType: SubscriptionType.user, collaborationStatus: isCollaborationClosed(store, collaboration) ? 'closed' : 'open' }));

  const groupsCollaborations = getGroupsForUser(store, loggedUserId)
    .flatMap((group) => getCollaborationsForGroup(store, group.id)
      .filter((collaboration) => {
        if (collaborationsStatusState === 'opened') {
          return !isCollaborationClosed(store, collaboration);
        } else if (collaborationsStatusState === 'closed') {
          return isCollaborationClosed(store, collaboration);
        } else {
          return collaboration;
        }
      })
      .map((collaboration) => ({
        collaboration,
        subscriptionType: SubscriptionType.groupOrRole,
        collaborationStatus: isCollaborationClosed(store, collaboration) ? 'closed' : 'open',
      })));

  const rolesCollaborations = getRolesForUser(store, loggedUserId)
    .flatMap(({ conceptId, role }) => (conceptId && role ? getCollaborationsForRole(store, role)
      .filter((collaboration) => {
        const collaborationContextsObjectIds = getCollaborationContextsObjectIds(store, collaboration.id);
        const firstClassConceptId = collaborationContextsObjectIds?.find((objectId) => isInstanceOf(store.getObject(objectId), Concept));
        return firstClassConceptId ? store.withAssociation(ConceptRoleUserAssignation)
          .withRole(ConceptRoleUserAssignation_Role_Concept, firstClassConceptId)
          .withRole(ConceptRoleUserAssignation_Role_User, loggedUserId)
          .withRole(ConceptRoleUserAssignation_Role_ConceptRole, role)
          .getObjectOrNull() !== null : false;
      })
      .filter((collaboration) => {
        if (collaborationsStatusState === 'opened') {
          return !isCollaborationClosed(store, collaboration);
        } else if (collaborationsStatusState === 'closed') {
          return isCollaborationClosed(store, collaboration);
        } else {
          return collaboration;
        }
      })
      .map((collaboration) => ({
        collaboration,
        subscriptionType: SubscriptionType.groupOrRole,
        collaborationStatus: isCollaborationClosed(store, collaboration) ? 'closed' : 'open',
      })) : []));

  loggedUserCollaborations.forEach((userCollab) => {
    if (groupsCollaborations.map((groupCollab) => groupCollab.collaboration.id).includes(userCollab.collaboration.id)
      || rolesCollaborations.map((roleCollab) => roleCollab.collaboration.id).includes(userCollab.collaboration.id)) {
      allCollaborations.push(joinObjects(userCollab, { subscriptionType: SubscriptionType.userAndGroupOrRole }));
    } else {
      allCollaborations.push({ ...userCollab });
    }
  });
  groupsCollaborations.forEach((groupCollab) => {
    if (!allCollaborations.map((allCollab) => allCollab.collaboration.id).includes(groupCollab.collaboration.id)) {
      allCollaborations.push({ ...groupCollab });
    }
  });

  rolesCollaborations.forEach((roleCollab) => {
    if (!allCollaborations.map((allCollab) => allCollab.collaboration.id).includes(roleCollab.collaboration.id)) {
      allCollaborations.push({ ...roleCollab });
    }
  });

  allCollaborations.push(...unsubscriptions.filter((unsubscription) => {
    if (collaborationsStatusState === 'opened') {
      return !isCollaborationClosed(store, unsubscription.collaboration);
    } else if (collaborationsStatusState === 'closed') {
      return isCollaborationClosed(store, unsubscription.collaboration);
    } else {
      return unsubscription;
    }
  }));

  const subscriptions = allCollaborations.map(({ collaboration, subscriptionType, collaborationStatus }) => {
    const collaborationMessages = getCollaborationMessages(store, collaboration.id, true);
    const messagesCount = collaborationMessages.length;
    const firstMessage = collaborationMessages[0];
    const author = firstMessage[CollaborationMessage_CreatedBy];
    const fadedLine = (collaborationsStatusState === 'all' || collaborationsStatusState === 'closed') && collaborationStatus === 'closed';
    const onInstance = getSubscriptionInstanceForCollaboration(store, collaboration.id);
    const hasUnreadMessages = getCollaborationsUnreadMessageCount(store, [collaboration], loggedUserId) > 0;

    return ({
      key: collaboration.key,
      storeId: collaboration.id,
      title: firstMessage[CollaborationMessage_Text],
      author,
      onInstance,
      intention: collaboration?.[Collaboration_Intention],
      createdAt: collaboration?.[Collaboration_CreatedAt],
      updatedAt: collaboration ? getLastUpdatedDateForCollaboration(store, collaboration) : undefined,
      collaboration,
      message: firstMessage,
      subscriptionType,
      collaborationStatus,
      fadedLine,
      messagesCount,
      hasUnreadMessages,
    });
  }).sort(extractAndCompareValue(((subscription) => subscription.title), compareString))
    .filter((collab) => {
      let conceptDefinitionId: string | undefined;
      if (collab.onInstance) {
        const concept = store.getObjectOrNull(collab.onInstance);
        conceptDefinitionId = concept?.[Instance_Of] as string | undefined;
      } else {
        const collaborationContextsObjectIds = getCollaborationContextsObjectIds(store, collab.collaboration.id);
        conceptDefinitionId = collaborationContextsObjectIds?.find((id) => id.length === 1 && isInstanceOf(store.getObject(id[0]), ConceptDefinition))?.[0];
      }
      return conceptDefinitionId && getIntentions(store, conceptDefinitionId).length > 0;
    });

  let uniqueAuthors = [...new Set(subscriptions.map((subscription) => (subscription.author ? store.getObjectOrNull(subscription.author) : undefined)))]
    .filter(filterNullOrUndefined);
  let uniqueIntentions = [...new Set(subscriptions.map((subscription) => (subscription.intention ? store.getObjectOrNull(subscription.intention) : undefined)))]
    .filter(filterNullOrUndefined);
  let uniqueInstances = [...new Set(subscriptions.map((subscription) => (subscription.onInstance ? store.getObjectOrNull(subscription.onInstance) : undefined)))]
    .filter(filterNullOrUndefined);

  const authorFilterFunction = searchFilterFunction<Record<string, unknown>>(store, authorsSearch, ['conceptLabel']);
  const intentionFilterFunction = searchFilterFunction<Record<string, unknown>>(store, intentionSearch, ['conceptLabel']);
  const instancesFilterFunction = searchFilterFunction<Record<string, unknown>>(store, instancesSearch, ['conceptLabel']);

  if (authorFilterFunction) {
    uniqueAuthors = uniqueAuthors.filter((author) => authorFilterFunction(joinObjects(author, { conceptLabel: getInstanceLabelOrUndefined(store, author) })));
  }
  if (intentionFilterFunction) {
    uniqueIntentions = uniqueIntentions.filter((intention) => intentionFilterFunction(joinObjects(intention, { conceptLabel: getInstanceLabelOrUndefined(store, intention) })));
  }
  if (instancesFilterFunction) {
    uniqueInstances = uniqueInstances.filter((instance) => instancesFilterFunction(joinObjects(instance, { conceptLabel: getInstanceLabelOrUndefined(store, instance) })));
  }

  const uniqueAuthorsChipOptions = uniqueAuthors
    .map((author) => getChipOptions(store, author.id))
    .filter(filterNullOrUndefined).sort(defaultOptionComparator);
  const uniqueIntentionsChipOptions = uniqueIntentions
    .map((intention) => getChipOptions(store, intention.id))
    .filter(filterNullOrUndefined).sort(defaultOptionComparator);
  const uniqueInstancesChipOptions = uniqueInstances
    .map((instance) => getChipOptions(store, instance.id))
    .filter(filterNullOrUndefined).sort(defaultOptionComparator);

  const filterFunction = searchFilterFunction<typeof subscriptions[0]>(store, safeSessionStorageValue<SubscriptionFilterConfiguration | undefined>(filterId)?.nameSearch, ['title']);

  const { generatePageList, doSort, sortCriteria } = useFilterAndSort(
    filterId,
    subscriptions,
    (item) => (
      (filterFunction?.(item) ?? true)
      && (!showUnreadMessagesOnly || (item.hasUnreadMessages))
      && (authorFilters.length === 0 || (item.author !== undefined && authorFilters.includes(item.author)))
      && (instanceFilters.length === 0 || (item.onInstance !== undefined && instanceFilters.includes(item.onInstance)))
      && (intentionFilters.length === 0 || (item.intention !== undefined && intentionFilters.includes(item.intention)))
    ),
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case 'createdAt':
          case 'updatedAt':
            return buildNumberColumnComparatorHandler(key, direction);
          case 'title':
            return buildStringColumnComparatorHandler(key, direction);
          case 'author':
          case 'onInstance':
          case 'intention':
            return {
              comparator: comparing(compareString, direction === TableSortDirection.desc),
              extractValue: (item) => {
                const instanceId = item[key];
                const instance = instanceId ? store.getObjectOrNull(instanceId) : undefined;
                return instance ? getInstanceLabel(store, instance) : undefined;
              },
            };
          default:
            return undefined;
        }
      },
      initial: { key: 'updatedAt', direction: TableSortDirection.desc },
    },
    undefined,
    [store.getSerial(), collaborationsStatusState]
  );

  const header = (
    <div className={classnames({ [classes.configContainer]: true, [classes.configContainerInWidget]: !usedInBlock })}>
      <div className={classes.searchContainer}>
        <SearchTextButton element={filterId} placeholder={i18n`Search`} />
        <ToggleButton
          active={showFilters}
          tooltip={i18n`Filters`}
          onClick={() => setShowFilters((current) => !current)}
          icon={IconName.filter_alt}
          count={filterCount > 0 ? filterCount : undefined}
        />
        <Chooser
          actions={chooserActions}
          onClick={(index) => {
            setCollaborationsStatusState(chooserActions[index].key);
          }}
          selectedIndexes={[chooserActions.findIndex((action) => action.key === collaborationsStatusState)]}
        />
      </div>
    </div>
  );

  const filters = showFilters ? (
    <div className={classes.filtersContainer}>
      <ToggleButton
        name={i18n`Unread messages`}
        icon={IconName.mark_chat_unread}
        onClick={() => setShowUnreadMessagesOnly((current) => !current)}
        active={showUnreadMessagesOnly}
      />
      <SizeContextProvider sizeVariant={SizeVariant.small}>
        <CompositeField
          dropdownMaxWidth="50rem"
          variant={CompositeFieldVariants.button}
          isSelected={authorFilters.length > 0}
          headerLinesRenderers={[
            {
              id: 'title',
              render: (inDropdown) => {
                const label = authorFilters.length > 0 ? i18n`Author (${authorFilters.length})` : i18n`Author`;
                return (
                  <div className={classes.filtersTitleContainer}>
                    <Tooltip title={label}>
                      <Typo maxLine={1}>{label}</Typo>
                    </Tooltip>
                    {inDropdown ? (
                      <SizeContextProvider sizeVariant={SizeVariant.small}>
                        <div className={classes.filtersButtonContainer}>
                          <IconOnlyButton
                            tooltip={showAuthorSearchBar ? i18n`Hide search bar` : i18n`Show search bar`}
                            variant={IconOnlyButtonVariants.secondary}
                            onClick={() => {
                              setShowAuthorSearchBar((current) => !current);
                            }}
                            iconName={IconName.search}
                            disabled={authorsSearch !== undefined}
                          />
                          {authorFilters.length > 0 && (
                            <IconOnlyButton
                              tooltip={i18n`Clear filters`}
                              variant={IconOnlyButtonVariants.secondary}
                              onClick={() => setAuthorFilters([])}
                              iconName={IconName.delete}
                            />
                          )}
                        </div>
                      </SizeContextProvider>
                    ) : null}
                  </div>
                );
              },
            },
          ]}
          getDropdownSectionDefinitions={() => [{
            id: 'instances',
            lines: [{
              id: 'values',
              render: (
                <div className={classes.toggleContainer}>
                  {(showAuthorSearchBar || authorsSearch !== undefined) && (
                    <SearchTextInput placeholder={i18n`Search`} element={authorFilterId} focusOnMount fullWidth />)}
                  <TagContainer>
                    {
                      Object.values(uniqueAuthorsChipOptions).map(({ id, label, icon }) => (
                        <Toggle
                          key={id}
                          text={label}
                          icon={icon as IconName}
                          onClick={() => {
                            setAuthorFilters((current = []) => (
                              current.includes(id) ? current.filter((i) => i !== id) : [...current, id]
                            ));
                          }}
                          isActive={authorFilters.includes(id)}
                        />
                      ))
                    }
                  </TagContainer>
                </div>
              ),
            }],
          }]}
          flexGrow={0}
        />
        <CompositeField
          dropdownMaxWidth="50rem"
          variant={CompositeFieldVariants.button}
          isSelected={intentionFilters.length > 0}
          headerLinesRenderers={[
            {
              id: 'title',
              render: (inDropdown) => {
                const label = intentionFilters.length > 0 ? i18n`Intention (${intentionFilters.length})` : i18n`Intention`;
                return (
                  <div className={classes.filtersTitleContainer}>
                    <Tooltip title={label}>
                      <Typo maxLine={1}>{label}</Typo>
                    </Tooltip>
                    {inDropdown ? (
                      <SizeContextProvider sizeVariant={SizeVariant.small}>
                        <div className={classes.filtersButtonContainer}>
                          <IconOnlyButton
                            tooltip={showIntentionSearchBar ? i18n`Hide search bar` : i18n`Show search bar`}
                            variant={IconOnlyButtonVariants.secondary}
                            onClick={() => {
                              setShowIntentionSearchBar((current) => !current);
                            }}
                            iconName={IconName.search}
                            disabled={intentionSearch !== undefined}
                          />
                          {intentionFilters.length > 0 && (
                            <IconOnlyButton
                              tooltip={i18n`Clear filters`}
                              variant={IconOnlyButtonVariants.secondary}
                              onClick={() => setIntentionFilters([])}
                              iconName={IconName.delete}
                            />
                          )}
                        </div>
                      </SizeContextProvider>
                    ) : null}
                  </div>
                );
              },
            },
          ]}
          getDropdownSectionDefinitions={() => [{
            id: 'instances',
            lines: [{
              id: 'values',
              render: (
                <div className={classes.toggleContainer}>
                  {(showIntentionSearchBar || intentionSearch !== undefined) && (
                    <SearchTextInput placeholder={i18n`Search`} element={intentionFilterId} focusOnMount fullWidth />)}
                  <TagContainer>
                    {
                      Object.values(uniqueIntentionsChipOptions).map(({ id, label, icon }) => (
                        <Toggle
                          key={id}
                          text={label}
                          icon={icon as IconName}
                          onClick={() => {
                            setIntentionFilters((current = []) => (
                              current.includes(id) ? current.filter((i) => i !== id) : [...current, id]
                            ));
                          }}
                          isActive={intentionFilters.includes(id)}
                        />
                      ))
                    }
                  </TagContainer>
                </div>
              ),
            }],
          }]}
          flexGrow={0}
        />
        <CompositeField
          dropdownMaxWidth="50rem"
          variant={CompositeFieldVariants.button}
          isSelected={instanceFilters.length > 0}
          headerLinesRenderers={[
            {
              id: 'title',
              render: (inDropdown) => {
                const label = instanceFilters.length > 0 ? i18n`Instance (${instanceFilters.length})` : i18n`Instance`;
                return (
                  <div className={classes.filtersTitleContainer}>
                    <Tooltip title={label}>
                      <Typo maxLine={1}>{label}</Typo>
                    </Tooltip>
                    {inDropdown ? (
                      <SizeContextProvider sizeVariant={SizeVariant.small}>
                        <div className={classes.filtersButtonContainer}>
                          <IconOnlyButton
                            tooltip={showInstancesSearchBar ? i18n`Hide search bar` : i18n`Show search bar`}
                            variant={IconOnlyButtonVariants.secondary}
                            onClick={() => {
                              setShowInstancesSearchBar((current) => !current);
                            }}
                            iconName={IconName.search}
                            disabled={instancesSearch !== undefined}
                          />
                          {instanceFilters.length > 0 && (
                            <IconOnlyButton
                              tooltip={i18n`Clear filters`}
                              variant={IconOnlyButtonVariants.secondary}
                              onClick={() => setInstanceFilters([])}
                              iconName={IconName.delete}
                            />
                          )}
                        </div>
                      </SizeContextProvider>
                    ) : null}
                  </div>
                );
              },
            },
          ]}
          getDropdownSectionDefinitions={() => [{
            id: 'instances',
            lines: [{
              id: 'values',
              render: (
                <div className={classes.toggleContainer}>
                  {(showInstancesSearchBar || instancesSearch !== undefined) && (
                    <SearchTextInput placeholder={i18n`Search`} element={instanceFilterId} focusOnMount fullWidth />)}
                  <TagContainer>
                    {
                      Object.values(uniqueInstancesChipOptions).map(({ id, label, icon }) => (
                        <Toggle
                          key={id}
                          text={label}
                          icon={icon as IconName}
                          onClick={() => {
                            setInstanceFilters((current = []) => (
                              current.includes(id) ? current.filter((i) => i !== id) : [...current, id]
                            ));
                          }}
                          isActive={instanceFilters.includes(id)}
                        />
                      ))
                    }
                  </TagContainer>
                </div>
              ),
            }],
          }]}
          flexGrow={0}
        />
      </SizeContextProvider>
    </div>
  ) : null;

  const { list, pagination } = generatePageList(numberOfItemsPerPage);

  const table = (
    <DataTable
      doSort={doSort}
      sortCriteria={sortCriteria}
      list={list}
      pagination={pagination}
      fullWidth={!usedInBlock}
      columnsDefinition={[
        {
          propertyId: 'read',
          name: '',
          sortable: false,
          width: 5,
          cellRender: ({ messagesCount, hasUnreadMessages, fadedLine }) => (
            <TableInnerCellContainer variant={TableInnerCellContainerVariants.centeredFlex} padding={buildPadding({ x: Spacing.s })}>
              <Tooltip title={hasUnreadMessages ? i18n`Unread message(s)` : i18n`No unread messages`}>
                <div className={classnames({ [classes.unreadButtonContainer]: true, [classes.opacity]: fadedLine })}>
                  <Icon
                    name={hasUnreadMessages ? IconName.mark_chat_unread : IconName.chat_bubble}
                    colorVariant={hasUnreadMessages ? IconColorVariant.info : IconColorVariant.secondary}
                  />
                  <Typo>{messagesCount < 100 ? messagesCount.toString() : '99+'}</Typo>
                </div>
              </Tooltip>
            </TableInnerCellContainer>
          ),
        },
        {
          propertyId: 'title',
          name: i18n`First message`,
          sortable: true,
          width: 14,
          openButton: ({ collaboration }) => Boolean(collaboration),
          cellRender: ({ title, fadedLine }) => (
            <div className={classnames({ [classes.titleContainer]: true, [classes.opacity]: fadedLine })}>
              <Typo maxLine={1}>{formatOrUndef(title)}</Typo>
            </div>
          ),
        },
        {
          propertyId: 'author',
          name: i18n`Author`,
          sortable: true,
          width: 14,
          cellRender: ({ author, fadedLine }) => (
            <div className={classnames({ [classes.opacity]: fadedLine })}>
              <SearchAndSelect selectedOption={author ? getChipOptions(store, author) : undefined} readOnly />
            </div>
          ),
        },
        {
          propertyId: 'onInstance',
          name: i18n`On instance`,
          sortable: true,
          width: 14,
          cellRender: ({ key, onInstance, fadedLine }) => (
            <div className={classnames({ [classes.opacity]: fadedLine })}>
              <SearchAndSelect selectedOption={onInstance ? getChipOptions(store, onInstance) : getUnknownChip(key)} readOnly />
            </div>
          ),
        },
        {
          propertyId: 'intention',
          name: i18n`Intention`,
          sortable: true,
          width: 13,
          cellRender: ({ intention, fadedLine }) => (
            <div className={classnames({ [classes.opacity]: fadedLine })}>
              <SearchAndSelect selectedOption={intention ? getChipOptions(store, intention) : undefined} readOnly />
            </div>
          ),
        },
        {
          propertyId: 'createdAt',
          name: i18n`Creation date`,
          sortable: true,
          width: 14,
          cellRender: ({ createdAt, fadedLine }) => (
            createdAt ? (
              <div className={classnames({ [classes.opacity]: fadedLine })}>
                <TextInputString value={formatENDisplayDate(new Date(createdAt))} color={theme.color.text.secondary} readOnly />
              </div>
            ) : null
          ),
        },
        {
          propertyId: 'updatedAt',
          name: i18n`Last update`,
          sortable: true,
          width: 14,
          cellRender: ({ updatedAt, fadedLine }) => (
            updatedAt ? (
              <div className={classnames({ [classes.opacity]: fadedLine })}>
                <TextInputString value={formatENDisplayDate(new Date(updatedAt))} color={theme.color.text.secondary} readOnly />
              </div>
            ) : null
          ),
        },
        {
          propertyId: 'subscribe',
          name: '',
          sortable: false,
          width: 12,
          cellRender: ({ collaboration, subscriptionType, collaborationStatus }) => {
            const isSubscribed = [SubscriptionType.user, SubscriptionType.userAndGroupOrRole, SubscriptionType.groupOrRole].includes(subscriptionType);
            const isDisabled = [SubscriptionType.userAndGroupOrRole, SubscriptionType.groupOrRole].includes(subscriptionType);
            return (
              <TableInnerCellContainer variant={TableInnerCellContainerVariants.centeredFlex} padding={buildPadding({ x: Spacing.s })}>
                <Tooltip title={subscriptionType !== SubscriptionType.user ? i18n`Unsubscribe is not possible when you are in a group or role of subscribers` : i18n`Unsubscribe`}>
                  <ToggleButton
                    name={isSubscribed ? i18n`Unsubscribe` : i18n`Subscribe`}
                    disable={isDisabled}
                    icon={isSubscribed ? IconName.notifications_off : IconName.notifications}
                    type={ElementPosition.alone}
                    onClick={() => {
                      if (isSubscribed) {
                        setUnsubscribedCollaborations([...unsubscribedCollaborations, { collaboration, subscriptionType: SubscriptionType.unsubscribed, collaborationStatus }]);
                        store.withAssociation(CollaborationUsers)
                          .withRole(CollaborationUsers_Role_Collaboration, collaboration.id)
                          .withRole(CollaborationUsers_Role_User, loggedUserId)
                          .deleteObject();
                      } else {
                        setUnsubscribedCollaborations([...unsubscribedCollaborations.filter((subscription) => subscription.collaboration.id !== collaboration.id)]);
                        store.withAssociation(CollaborationUsers)
                          .withRole(CollaborationUsers_Role_Collaboration, collaboration.id)
                          .withRole(CollaborationUsers_Role_User, loggedUserId)
                          .updateObject({});
                      }
                    }}
                  />
                </Tooltip>
              </TableInnerCellContainer>
            );
          },
        },
      ]}
      getNavigationPayload={({ collaboration, message }) => {
        const collaborationHistoryLocation = buildCollaborationLocation(store, collaboration.id, message?.id);
        const conceptId = getCurrentNavigationId(store, collaborationHistoryLocation.pathname ?? '', loggedUserId);
        if (conceptId) {
          return navigation.createNavigationPayload(conceptId, collaborationHistoryLocation);
        }
        return undefined;
      }}
    />
  );

  if (usedInBlock) {
    return (
      <>
        <BlockContent padded>
          {header}
          {filters}
        </BlockContent>
        <BlockContent fullWidth hideOverflowX>
          {table}
        </BlockContent>
      </>
    );
  } else {
    return (
      <>
        {header}
        {filters}
        {table}
      </>
    );
  }
};

export default SubscriptionTable;
