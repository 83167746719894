import type { FunctionComponent } from 'react';
import { Fragment } from 'react';
import type { FieldStoreObject, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import type { DashboardParameterStoreObject, WidgetStoreObject } from 'yooi-modules/modules/dashboardModule';
import { Dashboard, Dashboard_Parameters, DashboardParameter_Label, DashboardParameter_Type, Widget_Dashboard, Widget_Field } from 'yooi-modules/modules/dashboardModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import BlockTitle, { BlockTitleVariant } from '../../../components/templates/BlockTitle';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useAcl from '../../../store/useAcl';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { getFieldDefinitionHandler } from '../../_global/fields/FieldLibrary';
import { FieldEditionOptionMode, FieldEditionVariant } from '../../_global/fields/FieldLibraryTypes';
import useStoreFieldEditionHandler from '../../_global/fields/useStoreFieldEditionHandler';
import ViewOptionBlock from '../../_global/views/common/ViewOptionBlock';

interface WidgetFieldOptionsProps {
  widgetId: string,
}

const WidgetFieldOptions: FunctionComponent<WidgetFieldOptionsProps> = ({ widgetId }) => {
  const store = useStore();
  const { canWriteObject } = useAcl();
  const readOnly = !canWriteObject(widgetId);
  const widget = store.getObject<WidgetStoreObject>(widgetId);
  const dashboard = widget.navigate(Widget_Dashboard);
  const fieldId = widget[Widget_Field] as string;

  const dashboardParameterDefinitions = dashboard.navigateBack<DashboardParameterStoreObject>(Dashboard_Parameters);
  const editionHandler = useStoreFieldEditionHandler(fieldId, Dashboard);

  const sections = getFieldDefinitionHandler(store, store.getObject<FieldStoreObject>(fieldId)[Instance_Of])
    .getEditionOptions({
      mode: FieldEditionOptionMode.Widget,
      editionHandler,
      dashboardParameterDefinitions: (
        dashboardParameterDefinitions
          .filter((parameterDefinition) => Boolean(parameterDefinition[DashboardParameter_Type]))
          .map((parameterDefinition): SingleParameterDefinition => ({
            id: parameterDefinition.id,
            typeId: parameterDefinition[DashboardParameter_Type] as string,
            label: parameterDefinition[DashboardParameter_Label] ?? i18n`Parameter`,
            type: 'parameter',
          }))),
      readOnly,
      modelTypeId: Dashboard,
      variant: FieldEditionVariant.page,
    });

  return (
    <>
      {sections.map((section) => (
        <VerticalBlock key={section.key} asBlockContent withSeparation>
          {section.title ? (<BlockTitle title={section.title} />) : null}
          {section.type === 'section' ? section.options.map((option) => (<ViewOptionBlock key={option.key} option={option} />)) : null}
          {
            section.type === 'group'
              ? section.sections.map((subSection) => (
                <Fragment key={subSection.key}>
                  {
                    subSection.title
                      ? (<BlockTitle variant={BlockTitleVariant.secondary} title={subSection.title} rightActions={subSection.rightActions} infoTooltip={subSection.info} />)
                      : null
                  }
                  {subSection.options.map((option) => (<ViewOptionBlock key={option.key} option={option} />))}
                </Fragment>
              ))
              : null
          }
        </VerticalBlock>
      ))}
    </>
  );
};

export default WidgetFieldOptions;
