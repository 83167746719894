import type { FunctionComponent } from 'react';
import { compareString, comparing } from 'yooi-utils';
import { TableSortDirection } from '../../../../components/molecules/Table';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { formatOrUndef } from '../../../../utils/stringUtils';
import type { Chip } from '../../../_global/fieldUtils';
import type { ComparatorHandler } from '../../../_global/useFilterAndSort';
import { getInstanceHint } from '../_global/explorerUtils';
import { useExplorerHint } from '../_global/GetHintContextProvider';
import { ValueType } from '../_global/objectRenderType';
import ValueRenderer from '../_global/ValueRenderer';
import InstanceTable from './InstanceTable';

const useStyles = makeStyles({
  cell: {
    display: 'flex',
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
  },
}, 'instanceMentionsTab');

interface InstanceMentionsTabProps {
  instanceId: string,
}

const InstanceMentionsTab: FunctionComponent<InstanceMentionsTabProps> = ({ instanceId }) => {
  const classes = useStyles();

  const store = useStore();
  const getHint = useExplorerHint();

  const references = store.listObjects()
    .flatMap((o) => (
      Object.entries(o.asRawObject())
        .filter(([, value]) => value !== instanceId && JSON.stringify(value).toLowerCase().includes(instanceId.toLowerCase()))
        .map(([propertyId]) => propertyId)
        .map((mentionId) => ({
          key: `${o.key}_${mentionId}`,
          id: o.key,
          object: o,
          hint: getInstanceHint(store, getHint, o.id),
          propertyId: mentionId,
          mentionHint: getInstanceHint(store, getHint, mentionId),
        }))
    ));

  const computeOption = (groupById: string): Chip => ({ id: groupById, label: groupById });

  return (
    <InstanceTable
      filterId={`instanceMentionsTab_${instanceId}`}
      list={references}
      sort={{
        getComparatorHandler: (key, direction) => {
          switch (key) {
            case 'id':
              return {
                comparator: comparing(compareString, direction === TableSortDirection.desc),
                extractValue: (item) => item.hint ?? item.id,
              } satisfies ComparatorHandler<typeof references[0], string | undefined>;
            case 'propertyId':
              return {
                comparator: comparing(compareString, direction === TableSortDirection.desc),
                extractValue: (item) => item.mentionHint ?? item.propertyId,
              } satisfies ComparatorHandler<typeof references[0], string | undefined>;
            default:
              return undefined;
          }
        },
        initial: { key: 'id', direction: TableSortDirection.asc },
      }}
      groupBy={{
        computeSelectedOption: (groupById) => (groupById ? computeOption(groupById) : undefined),
        computeOptions: () => ['propertyId', 'id'].map(computeOption),
        defaultGroupById: 'propertyId',
        getGroupLabel: (key) => formatOrUndef(key ? (getHint(key) ?? key) : undefined),
      }}
      searchStrings={(item) => [item.id, item.hint, item.mentionHint, item.propertyId]}
      columnsDefinition={[
        {
          propertyId: 'id',
          name: i18n`Id`,
          sortable: true,
          width: 60,
          cellRender: ({ id, hint }) => (
            <div className={classes.cell}>
              <ValueRenderer key={id} value={{ type: ValueType.string, value: id, hint, href: `/settings/explorer/instance/${id}`, maxLine: 1 }} />
            </div>
          ),
        },
        {
          propertyId: 'propertyId',
          name: i18n`Property`,
          sortable: true,
          width: 40,
          cellRender: ({ propertyId, mentionHint: propertyHint }) => (
            <div className={classes.cell}>
              <ValueRenderer
                key={propertyId}
                value={{ type: ValueType.string, value: propertyId, hint: propertyHint, href: `/settings/explorer/instance/${propertyId}`, maxLine: 1 }}
              />
            </div>
          ),
        },
      ]}
    />
  );
};

export default InstanceMentionsTab;
