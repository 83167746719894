import type { FunctionComponent, ReactElement } from 'react';
import { createContext, useContext, useMemo } from 'react';

interface Context {
  padded: boolean,
  backgroundColor: string | undefined,
}

const WidgetContext = createContext<Context>({ padded: true, backgroundColor: undefined });

interface WidgetContextProviderProps {
  padded: boolean,
  backgroundColor: string | undefined,
  children: ReactElement | null,
}

const WidgetContextProvider: FunctionComponent<WidgetContextProviderProps> = ({ padded, backgroundColor, children }) => {
  const context = useMemo(() => ({ padded, backgroundColor }), [padded, backgroundColor]);

  return (
    <WidgetContext.Provider value={context}>
      {children}
    </WidgetContext.Provider>
  );
};

export const useWidgetContext = (): Context => useContext(WidgetContext);

export default WidgetContextProvider;
