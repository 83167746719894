import { v4 as uuid } from 'uuid';
import type { ExtractOptionalPropsKeys, ExtractRequiredPropsKeys, PartialNullable } from 'yooi-utils';
import { InfiniteComputingRecursionError, newError, StoreObjectNotFoundError } from 'yooi-utils';
import type { ObjectRepository, ObjectRepositoryWithAssociation, PropertyFunction, RepositoryAssociation, RepositoryObject } from './ObjectRepositoryType';

const KEY_ID_SEP = '|';

const validateStoreId = (storeId: string | string[]) => {
  if (!storeId) {
    throw newError('Id must be defined', { storeId });
  } else if ((typeof storeId !== 'string' && !Array.isArray(storeId)) || (Array.isArray(storeId) && storeId.some((id) => typeof id !== 'string'))) {
    throw newError('Invalid Id, it must be a string or string[]', { id: storeId });
  }
};

interface AssociationRoleIndex {
  getKeys: (criteria: string[]) => Set<string>,
  indexKey: (id: string[], key: string) => void,
  unindexKey: (id: string[], key: string) => void,
}

interface AssociationIndex {
  forEachCriteriaIndex: (callback: (associationRoleIndex: AssociationRoleIndex) => void) => void,
  getCriteriaIndex: (idCriteria: string[]) => AssociationRoleIndex,
}

interface InternalRepositoryObject<Id extends (string | string[]) = string> {
  id: Id,
  key: string,
  navigateBack: <Properties = { readonly [Key in string]: unknown }>(propertyId: string) => (Properties & RepositoryObject<string | string[]>)[],
  navigate: <Properties = { readonly [Key in string]: unknown }>(propertyId: string) => (Properties & RepositoryObject),
  navigateOrNull: <Properties = { readonly [Key in string]: unknown }>(propertyId: string) => ((Properties & RepositoryObject) | null),
  asRawObject: () => { readonly [Key in string]: unknown },

  [propertyId: string]: unknown,
}

const createObjectRepository = (
  storeToClone?: Pick<ObjectRepository, 'forEachObject'>,
  storeCloneObjectFilter: (obj: RepositoryObject<string | string[]>) => boolean = () => true
): ObjectRepository => {
  const propertyFunctions: Map<string, PropertyFunction> = new Map();
  const propertyFunctionValues = new Map<string, unknown>();
  const objects = new Map<string, InternalRepositoryObject<string | string[]>>(); // key -> {props}  props values cannot be null or undefined
  const propertyIndexes = new Map<string, Map<unknown, Set<string>>>(); // propertyId -> value -> Set(keys)
  // associationId -> associationIndex {criteriaIndex, getCriteriaIndex(criteria)} -> associationCriteriaIndex -> { getKeys, indexKey, unindexKey }
  const associationIndexes = new Map<string, AssociationIndex>();

  const matchCriteria = (idCriteria: string[], idToCheck: string[]) => idCriteria.every((criteria, i) => criteria === null || criteria === undefined || criteria === idToCheck[i]);

  const listObjects = (idCriteria?: string[]): RepositoryObject<string | string[]>[] => (
    idCriteria
      ? [...objects.values()].filter((o) => Array.isArray(o.id) && matchCriteria(idCriteria, o.id))
      : [...objects.values()]
  );

  const computePropertyIndex = (propertyId: string): Map<unknown, Set<string>> => {
    const index = new Map<unknown, Set<string>>();
    objects.forEach(({ [propertyId]: propertyVal }, key) => {
      if (propertyVal !== undefined) {
        const indexKeys = index.get(propertyVal);
        if (indexKeys) {
          indexKeys.add(key);
        } else {
          index.set(propertyVal, new Set([key]));
        }
      }
    });
    return index;
  };

  const getOrCreatePropertyIndex = (propertyId: string): Map<unknown, Set<string>> => {
    const index = propertyIndexes.get(propertyId);
    if (index) {
      return index;
    } else {
      const newIndex = computePropertyIndex(propertyId);
      propertyIndexes.set(propertyId, newIndex);
      return newIndex;
    }
  };

  const reindexObjectProperty = (key: string, propertyId: string, previousValue: unknown, newValue: unknown) => {
    const index = propertyIndexes.get(propertyId);
    if (index) {
      if (previousValue !== undefined) {
        index.get(previousValue)?.delete(key);
      }
      if (newValue !== null) {
        const valueKeys = index.get(newValue);
        if (valueKeys) {
          valueKeys.add(key);
        } else {
          index.set(newValue, new Set([key]));
        }
      }
    }
  };

  const createAssociationIndex: (associationId: string) => AssociationIndex = (associationId) => {
    // roleIdA|roleIdB|... -> associationCriteriaIndex -> { getKeys, indexKey, unindexKey }
    const associationCriteriaIndexes = new Map<string, AssociationRoleIndex>();

    const createAssociationCriteriaIndex: (index: number[]) => AssociationRoleIndex = (indexes) => {
      const maxRoleIndex = Math.max(...indexes);
      const idToIndexKey = (id: string[]): string => indexes.map((i) => id[i]).join('|');

      // roleA|roleB|... -> Set(key)
      const index = new Map<string, Set<string>>();
      const associationRoleIdsIndex: AssociationRoleIndex = {
        getKeys: (criteria) => index.get(idToIndexKey(criteria)) ?? new Set(),
        indexKey: (id, key) => {
          if (maxRoleIndex < id.length) {
            const indexValueId = idToIndexKey(id);
            let valueIndex = index.get(indexValueId);
            if (!valueIndex) {
              valueIndex = new Set();
              index.set(indexValueId, valueIndex);
            }
            valueIndex.add(key);
          }
        },
        unindexKey: (id, key) => {
          if (maxRoleIndex < id.length) {
            const indexValueId = idToIndexKey(id);
            index.get(indexValueId)?.delete(key);
          }
        },
      };

      listObjects([associationId])
        .forEach(({ key, id }) => associationRoleIdsIndex.indexKey(id as string[], key));

      return associationRoleIdsIndex;
    };

    const associationIndex: AssociationIndex = {
      forEachCriteriaIndex: (callback) => associationCriteriaIndexes.forEach(callback),
      getCriteriaIndex: (idCriteria) => {
        const indexes = idCriteria.map((value, index) => (value ? index : undefined)).filter(Boolean) as number[];
        const roleIdsKey = indexes.join('|');
        let associationCriteriaIndex = associationCriteriaIndexes.get(roleIdsKey);
        if (!associationCriteriaIndex) {
          associationCriteriaIndex = createAssociationCriteriaIndex(indexes);
          associationCriteriaIndexes.set(roleIdsKey, associationCriteriaIndex);
        }
        return associationCriteriaIndex;
      },
    };
    associationIndexes.set(associationId, associationIndex);
    return associationIndex;
  };

  const getAssociationCriteriaIndexedKeys = (idCriteria: string[]) => {
    const associationId = idCriteria[0];
    const associationIndex = associationIndexes.get(associationId) ?? createAssociationIndex(associationId);
    return associationIndex.getCriteriaIndex(idCriteria).getKeys(idCriteria);
  };

  const reindexAssociationObject = (key: string, id: string | string[], previousValue: unknown, newValue: unknown) => {
    if (!Array.isArray(id) || id.length === 1) {
      return;
    }

    const associationId = id[0];
    const associationIndex = associationIndexes.get(associationId);
    if (associationIndex) {
      if (newValue && !previousValue) { // New assoc
        associationIndex.forEachCriteriaIndex((criteriaIndex) => criteriaIndex.indexKey(id, key));
      } else if (previousValue && !newValue) { // Deleted assoc
        associationIndex.forEachCriteriaIndex((criteriaIndex) => criteriaIndex.unindexKey(id, key));
      }
    }
  };

  const idToKey = (id: string | string[]): string => {
    if (typeof id === 'string' && id.length > 0) {
      return id;
    } else if (Array.isArray(id) && id.length > 0) {
      return id.join(KEY_ID_SEP);
    }
    throw newError('Invalid id', { id });
  };

  const normalizeId = (id: string | string[]): string | string[] => {
    if (typeof id === 'string') {
      return id;
    } else if (id.length === 1) {
      return id[0];
    } else {
      return [...id]; // TODO should be an immutable copy
    }
  };

  const getObjectOrNull = <Properties = Record<string, unknown>, Id extends (string | string[]) = string>(id: Id): ((Properties & RepositoryObject<Id>) | null) => {
    validateStoreId(id);
    return objects.get(idToKey(id)) as (Properties & RepositoryObject<Id>) ?? null;
  };

  const getObject = <Properties = Record<string, unknown>, Id extends (string | string[]) = string>(id: Id, isVirtual = false): (Properties & RepositoryObject<Id>) => {
    const object = getObjectOrNull(id);
    if (!object) {
      if (isVirtual) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return createObject(id) as (Properties & RepositoryObject<Id>);
      } else {
        throw new StoreObjectNotFoundError(id);
      }
    } else {
      return object as (Properties & RepositoryObject<Id>);
    }
  };

  const objectEntries = (object: RepositoryObject<string | string[]>) => (
    Object.entries(object)
      .filter(([propertyId, propValue]) => (typeof propValue !== 'function' && propertyId !== 'id' && propertyId !== 'key'))
  );

  const invalidatePropertyCache = (): void => {
    propertyFunctionValues.clear();
  };

  const computingStack = new Set<string>();
  const safeCompute = (key: string, fn: () => unknown) => {
    if (computingStack.has(key)) {
      throw new InfiniteComputingRecursionError(key);
    }
    computingStack.add(key);
    try {
      return fn();
    } finally {
      computingStack.delete(key);
    }
  };

  const proxyProps = ['id', 'key', 'navigateBack', 'navigate', 'navigateOrNull', 'asRawObject'];
  const proxyHandler = <Id extends (string | string[]) = string>(id: Id, key: string): ProxyHandler<RepositoryObject<string | string[]>> => ({
    ownKeys: (object: RepositoryObject<string | string[]>) => [...Object.keys(object), ...proxyProps],
    get: (object: RepositoryObject<Id>, propertyId: string) => {
      const propValue = object[propertyId];
      if (propValue !== undefined) {
        return propValue;
      } else if (propertyId === 'id') {
        return id;
      } else if (propertyId === 'key') {
        return key;
      } else if (propertyId === 'navigateBack') {
        return <Properties = Record<string, unknown>>(targetPropertyId: string) => {
          const index = getOrCreatePropertyIndex(targetPropertyId);
          const childrenKeys = index.get(key);
          if (childrenKeys) {
            return [...childrenKeys].map((childKey) => objects.get(childKey) as (Properties & RepositoryObject<string | string[]>));
          } else {
            return [];
          }
        };
      } else if (propertyId === 'navigate') {
        return (targetPropertyId: string) => {
          const parentId = object[targetPropertyId]; // it won't permit to navigate to computed property
          if (parentId === undefined) {
            throw new StoreObjectNotFoundError(id);
          } else {
            return getObject(parentId as string);
          }
        };
      } else if (propertyId === 'navigateOrNull') {
        return <Properties = Record<string, unknown>>(targetPropertyId: string) => {
          const parentId = object[targetPropertyId]; // it won't permit to navigate to computed property
          if (parentId === undefined) {
            return null;
          } else {
            return getObjectOrNull(parentId as string) as (Properties & RepositoryObject);
          }
        };
      } else if (propertyId === 'asRawObject') {
        return () => object;
      }

      const propertyFunction = propertyFunctions.get(propertyId);
      if (propertyFunction !== undefined) {
        const cacheKey = `${key}_${propertyId}`;
        if (propertyFunctionValues.has(cacheKey)) {
          return propertyFunctionValues.get(cacheKey);
        } else {
          const arrayId: string[] = Array.isArray(id) ? id : [id];
          const value = safeCompute(cacheKey, () => propertyFunction(arrayId));
          if (value === null) {
            throw newError('Invalid computing result, cannot be null');
          }
          propertyFunctionValues.set(cacheKey, value);
          return value;
        }
      } else {
        return undefined;
      }
    },
    // set: (object: RepositoryObject<Id>, propertyId: string, newValue: unknown) => {
    //   // We are dealing with a proxy, this is expected
    //   // eslint-disable-next-line no-param-reassign
    //   object[propertyId] = newValue;
    //   return true;
    // },
    // getOwnPropertyDescriptor: () => ({ enumerable: true, configurable: true }),
  });

  const createObject = <Id extends (string | string[]) = string>(id: Id, properties?: { [propertyId: string]: unknown }) => (
    new Proxy<RepositoryObject<Id>>((properties ?? {}) as RepositoryObject<Id>, proxyHandler(normalizeId(id), idToKey(id)))
  );

  const createNewObject = <Id extends (string | string[]) = string>(id: Id, key: string, properties?: { [propertyId: string]: unknown }): InternalRepositoryObject<Id> => {
    const object: RepositoryObject<Id> = createObject(id, properties);
    objects.set(key, object);
    return object;
  };

  const registerPropertyFunction = (propertyId: string, func: PropertyFunction): void => {
    if (!propertyFunctions.has(propertyId)) {
      propertyFunctions.set(propertyId, func);
    } else {
      throw newError('Property is already registered', { propertyId });
    }
  };

  const unregisterPropertyFunction = (propertyId: string): void => {
    if (propertyFunctions.has(propertyId)) {
      propertyFunctions.delete(propertyId);
    }
  };

  const unregisterAllPropertyFunctions = (): void => {
    invalidatePropertyCache();
    propertyFunctions.clear();
  };

  const hasPropertyFunction = (propertyId: string): boolean => propertyFunctions.has(propertyId);

  // Almost a duplicate from RawObjectStoreRepository. If updated, check for the other one.
  const updateObject: ObjectRepository['updateObject'] = <Properties = Record<string, unknown>>(
    id: string | string[],
    properties: (Partial<Pick<Properties, ExtractRequiredPropsKeys<Properties>>> & PartialNullable<Pick<Properties, ExtractOptionalPropsKeys<Properties>>>) | null
  ) => {
    validateStoreId(id);
    const key = idToKey(id);
    const currentObject = objects.get(key);
    const rollbackProperties: Record<string, unknown> | null = currentObject ? {} : null;

    reindexAssociationObject(key, id, currentObject, properties);

    if (properties) {
      // create/update object
      const object = currentObject || createNewObject(id, key);
      Object.entries(properties).forEach(([propertyId, propValue]) => {
        if (propValue !== undefined) {
          const previousValue = object[propertyId];
          if (!((propValue === null && previousValue === undefined) || Object.is(previousValue, propValue))) {
            if (propValue === null) {
              delete object[propertyId];
            } else {
              object[propertyId] = propValue;
            }
            reindexObjectProperty(key, propertyId, previousValue, propValue);
            if (rollbackProperties) {
              rollbackProperties[propertyId] = previousValue === undefined ? null : previousValue;
            }
          }
        }
      });
    } else if (currentObject) {
      // delete object
      objectEntries(currentObject).forEach(([propertyId, propValue]) => {
        reindexObjectProperty(key, propertyId, propValue, null);
        rollbackProperties![propertyId] = propValue;
      });
      objects.delete(key);
    }
    // else delete non existing object -> rollbackProperties = null, so rollback would try to delete it again which should be safe

    invalidatePropertyCache();

    return rollbackProperties as (Partial<Pick<Properties, ExtractRequiredPropsKeys<Properties>>> & PartialNullable<Pick<Properties, ExtractOptionalPropsKeys<Properties>>>) | null;
  };

  const flush = (newObjects?: Iterable<[string | string[], { [propertyId: string]: unknown }]>) => {
    objects.clear();
    propertyIndexes.clear();
    associationIndexes.clear();
    propertyFunctions.clear();
    propertyFunctionValues.clear();
    if (newObjects) {
      for (const [id, properties] of newObjects) {
        createNewObject(id, idToKey(id), properties);
      }
    }
  };

  const wrapAssociation = <Props>(object: (Props & RepositoryObject<string[]>)): RepositoryAssociation<Props> => ({
    object,
    role: (roleId: number) => object.id[roleId + 1],
    navigateRole: <Properties = Record<string, unknown>>(roleId: number) => getObject<Properties>(object.id[roleId + 1]),
    navigateRoleOrNull: <Properties = Record<string, unknown>>(roleId: number) => getObjectOrNull<Properties>(object.id[roleId + 1]),
    varRole: (varRoleId: number, index: number) => object.id[varRoleId + index + 1],
    varRoles: (varRoleId: number) => object.id.slice(varRoleId + 1),
    navigateVarRole: (varRoleId: number, index: number) => getObject(object.id[varRoleId + index + 1]),
    navigateVarRoleOrNull: (varRoleId: number, index: number) => (object.id[varRoleId + index + 1] ? getObjectOrNull(object.id[varRoleId + index + 1]) : null),
  });

  const handleAssociationCriteria = (idCriteria: string[], uncheckedRoles: boolean[]): ObjectRepositoryWithAssociation => {
    const ensureUniqueCriteria = () => {
      if (!idCriteria.every((i) => i)) {
        throw newError('(get|update|delete)Object() requires to have every association role ids defined', { idCriteria });
      }
    };

    const listAssociationObjects = <Properties>() => {
      const indexedKeys = getAssociationCriteriaIndexedKeys(idCriteria);
      return [...indexedKeys]
        .map((key) => objects.get(key) as (Properties & RepositoryObject<string[]>))
        .filter((object) => object.id.every((id, index) => index === 0 || uncheckedRoles[index] || getObjectOrNull(id)));
    };

    return {
      withRole: (roleId, objectId) => {
        if (!objectId) {
          return handleAssociationCriteria(idCriteria, uncheckedRoles);
        }
        // We need this check as we still use this API with plain js
        // noinspection SuspiciousTypeOfGuard
        if (typeof objectId !== 'string') {
          throw newError('objectId must be a string', { objectId });
        }
        const newIdCriteria = [...idCriteria];
        newIdCriteria[roleId + 1] = objectId;
        const newUncheckedRoles = [...uncheckedRoles];
        newUncheckedRoles[roleId + 1] = true;
        return handleAssociationCriteria(newIdCriteria, newUncheckedRoles);
      },
      withVarRoles: (roleId, objectIds) => {
        // We need this check as we still use this API with plain js
        // noinspection SuspiciousTypeOfGuard
        if (!Array.isArray(objectIds) || objectIds.some((id) => typeof id !== 'string')) {
          throw newError('objectIds must be a string[]', { objectIds });
        }
        const newIdCriteria = [...idCriteria];
        const newUncheckedRoles = [...uncheckedRoles];
        objectIds.forEach((objectId, index) => {
          newIdCriteria[roleId + 1 + index] = objectId;
          newUncheckedRoles[roleId + 1 + index] = true;
        });
        return handleAssociationCriteria(newIdCriteria, newUncheckedRoles);
      },
      withExternalRole: (roleId) => {
        const newUncheckedRoles = [...uncheckedRoles];
        newUncheckedRoles[roleId + 1] = true;
        return handleAssociationCriteria(idCriteria, newUncheckedRoles);
      },
      list: <Properties>() => listAssociationObjects<Properties>().map((o) => wrapAssociation(o)),
      get: <Properties>() => {
        ensureUniqueCriteria();
        return wrapAssociation(getObject<Properties, string[]>(idCriteria));
      },
      getOrNull: <Properties>() => {
        ensureUniqueCriteria();
        const o = getObjectOrNull<Properties, string[]>(idCriteria);
        return o ? wrapAssociation(o) : null;
      },
      getId: () => {
        ensureUniqueCriteria();
        return idCriteria;
      },
      listObjects: listAssociationObjects,
      getObject: () => {
        ensureUniqueCriteria();
        return getObject(idCriteria);
      },
      getObjectOrNull: () => {
        ensureUniqueCriteria();
        return getObjectOrNull(idCriteria);
      },
      updateObject: (properties) => {
        ensureUniqueCriteria();
        return updateObject(idCriteria, properties);
      },
      deleteObject: () => {
        ensureUniqueCriteria();
        return updateObject(idCriteria, null);
      },
    };
  };

  if (storeToClone) {
    storeToClone.forEachObject((object) => {
      if (storeCloneObjectFilter(object)) {
        createNewObject(object.id, idToKey(object.id), object.asRawObject());
      }
    });
  }

  return {
    registerPropertyFunction,
    unregisterPropertyFunction,
    unregisterAllPropertyFunctions,
    invalidatePropertyCache,
    hasPropertyFunction,
    getObject,
    getObjectOrNull,
    objectEntries,
    listObjects,
    objectsIterator: () => objects.values(),
    size: () => objects.size,
    forEachObject: (callback) => objects.forEach((object) => callback(object)),
    createObject: <Properties = Record<string, unknown>>(properties: Properties) => {
      const id = uuid();
      updateObject<Properties>(id, properties);
      return id;
    },
    updateObject,
    deleteObject: (id) => updateObject(id, null),
    withAssociation: (associationId) => handleAssociationCriteria([associationId], []),
    flush,
  };
};

export default createObjectRepository;
