import { useContext, useEffect } from 'react';
import { ConceptDefinition } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { joinObjects, newError } from 'yooi-utils';
import useStore from '../../../../store/useStore';
import { useChipBlockContext } from '../../../../utils/useChipBlockContext';
import type { PanelContext } from '../../../../utils/useCollaborationContext';
import { CollaborationContext, PanelState } from '../../../../utils/useCollaborationContext';
import useForceUpdate from '../../../../utils/useForceUpdate';
import { computeFullContext } from '../../collaboration/usePageCollaborationContext';
import { getMostRelevantContextForCollaboration } from './utils/collaborationUtils';

const usePanelObserver = (): PanelContext & { pageContext: string[][] | undefined, fullContext: string[][] | undefined } => {
  const forceUpdate = useForceUpdate();
  const collaborationContext = useContext(CollaborationContext);
  const globalChipContext = useChipBlockContext();
  const store = useStore();

  if (collaborationContext === undefined) {
    throw newError('CollaborationContext has not been initialized, add a CollaborationPanelContextProvider in the React parent component hierarchy');
  }

  useEffect(() => {
    collaborationContext.panelObserver.register(forceUpdate);
    return () => collaborationContext.panelObserver.unregister(forceUpdate);
  }, [forceUpdate, collaborationContext.panelObserver]);

  const { context: panelContextContext, scrollToChip } = collaborationContext.panelContext;
  let mostRelevantPanelContext = panelContextContext;
  let mostRelevantPageContext: string[][] | undefined;
  if (!collaborationContext.pageContext) {
    mostRelevantPageContext = globalChipContext.context ? [...globalChipContext.parentContext, [globalChipContext.context]] : undefined;
  } else {
    mostRelevantPageContext = collaborationContext.pageContext;
  }

  const contextObject = panelContextContext && panelContextContext.length === 1 ? store.getObjectOrNull(panelContextContext) : undefined;
  if (isInstanceOf(contextObject, ConceptDefinition) || contextObject === null) {
    collaborationContext.updatePanelContext({
      state: PanelState.list,
      context: undefined,
      isOpen: false,
    });
  }

  if (collaborationContext.pageContext && collaborationContext.panelContext.urlContext) {
    const { collaborationId, messageId } = collaborationContext.panelContext.urlContext;
    mostRelevantPanelContext = getMostRelevantContextForCollaboration(store, collaborationId, collaborationContext.panelContext.context, collaborationContext.pageContext);
    collaborationContext.updatePanelContext({
      state: PanelState.detail,
      context: mostRelevantPanelContext,
      isOpen: true,
      collaborationId,
      messageId,
      scrollToChip: true,
    });
  }

  return joinObjects(
    (collaborationContext.panelContext),
    {
      context: mostRelevantPanelContext,
      scrollToChip,
      fullContext: computeFullContext(store, mostRelevantPageContext, mostRelevantPanelContext),
      pageContext: mostRelevantPageContext,
    }
  );
};

export default usePanelObserver;
