import type { StoreObject } from '../ObjectStoreType';

export const isStoreObject = (value: unknown): value is StoreObject => (
  typeof value === 'object'
  && value !== null
  && (value as Record<string, unknown>).key !== undefined
  && (value as Record<string, unknown>).id !== undefined
  && (value as Record<string, unknown>).navigate !== undefined
  && (value as Record<string, unknown>).navigateBack !== undefined
  && (value as Record<string, unknown>).navigateOrNull !== undefined
  && (value as Record<string, unknown>).asRawObject !== undefined
);
