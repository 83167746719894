import { StructuredError } from '../errorUtils';
import type { EngineContext, FormulaType } from './engine/formula';

export interface ArrayFormulaType<T extends FormulaType = FormulaType> extends FormulaType {
  readonly array: {
    elementType: T,
    depth: number,
  },
}

export const isArrayFormulaType = (formulaType: FormulaType): formulaType is ArrayFormulaType => (
  Object.prototype.hasOwnProperty.call(formulaType, 'array')
);

type ExtractElementType<T extends FormulaType> = T extends ArrayFormulaType<infer FT> ? FT : T;

export const isArrayFormulaTypeOfType = <T extends FormulaType>(formulaType: FormulaType, type: T): formulaType is ArrayFormulaType<T> => (
  isArrayFormulaType(formulaType) && type.isAssignableFrom(formulaType.array.elementType)
);

export const anyType: FormulaType = {
  name: 'any',
  equals: (type) => type === anyType,
  isAssignableFrom: () => true,
};

export const booleanType: FormulaType = {
  name: 'boolean',
  equals: (type) => type === booleanType,
  isAssignableFrom: (type) => type === booleanType || type === anyType,
};

export const numberType: FormulaType = {
  name: 'number',
  equals: (type) => type === numberType,
  isAssignableFrom: (type) => type === numberType || type === anyType,
};

const NaSIdentifier = 'NaS';
export const NaS = (): symbol => Symbol(NaSIdentifier);
export const isNaS = (value: unknown): boolean => (typeof value === 'symbol' && value.description === NaSIdentifier);

export const textType: FormulaType = {
  name: 'text',
  equals: (type) => type === textType,
  isAssignableFrom: (type) => type === textType || type === anyType,
};

export const arrayOf = <T extends FormulaType>(elementType: T): ArrayFormulaType<ExtractElementType<T>> => {
  const array = {
    elementType: (isArrayFormulaType(elementType) ? elementType.array.elementType : elementType) as ExtractElementType<T>,
    depth: 1 + (isArrayFormulaType(elementType) ? elementType.array.depth : 0),
  };
  const equals = (type: FormulaType) => Boolean(isArrayFormulaType(type) && type.array.elementType.equals(array.elementType) && type.array.depth === array.depth);
  return {
    name: `array<${elementType.name}>`,
    array,
    equals,
    isAssignableFrom: (type) => {
      if (equals(type)) {
        return true;
      } else if (type === anyType) {
        return true;
      } else if (isArrayFormulaType(type)) {
        if (type.array.elementType === anyType) {
          return array.depth >= type.array.depth;
        } else {
          return array.elementType.isAssignableFrom(type.array.elementType) && array.depth === type.array.depth;
        }
      } else {
        return false;
      }
    },
  };
};

export class ArrayInvalidElementTypeError extends StructuredError {
  override readonly name = 'ArrayInvalidElementTypeError';

  constructor(expectedType: FormulaType, elementType: FormulaType, elementIndex: number) {
    super('The array element type is invalid', { data: { expectedType, elementType, elementIndex } });
  }
}

export const getMostCommonType = (types: FormulaType[]): { mostCommonType: FormulaType, errorIndex: number | undefined } => {
  // find the most discriminating type (eg the one with the max number of dimensions)
  const mostCommonType = types.reduce((previousType, type) => {
    if (previousType.equals(type)) {
      return type;
    } else if (previousType === anyType) {
      return type;
    } else if (type === anyType) {
      return previousType;
    } else if (isArrayFormulaType(previousType) && previousType.array.elementType === anyType && type.isAssignableFrom(previousType)) {
      return type;
    } else if (isArrayFormulaType(type) && type.array.elementType === anyType && previousType.isAssignableFrom(type)) {
      return previousType;
    } else if (previousType.isAssignableFrom(type)) {
      return previousType;
    } else if (type.isAssignableFrom(previousType)) {
      return type;
    } else {
      return previousType;
    }
  }, anyType);

  const errorIndex = types.findIndex((type) => !mostCommonType.isAssignableFrom(type));
  return {
    mostCommonType,
    errorIndex: errorIndex !== -1 ? errorIndex : undefined,
  };
};

export const arrayTypeFrom = (elementsTypes: FormulaType[]): FormulaType | Error => {
  const { mostCommonType, errorIndex } = getMostCommonType(elementsTypes);
  if (errorIndex !== undefined) {
    return new ArrayInvalidElementTypeError(mostCommonType, elementsTypes[errorIndex], errorIndex);
  } else {
    return arrayOf(mostCommonType);
  }
};

export const engineTypeSystem: EngineContext['typeSystem'] = {
  booleanType,
  numberType,
  textType,
  arrayTypeFrom,
};
