import type { ReactElement } from 'react';
import { useState } from 'react';
import ConfirmationModal, { ConfirmationModalVariant } from '../components/molecules/ConfirmationModal';
import { notifySuccess } from './notify';

interface UseDeleteModalProps<Context> {
  doDelete: (context: Context, alternateConfirm: boolean) => (string | void),
  doCancel?: () => void,
  shouldConfirm: (context: Context) => boolean,
  getModalProps: (context: Context) => {
    title: string,
    confirmLabel?: string,
    alternateConfirmLabel?: string,
    content: ReactElement | null,
  },
}

const useDeleteModal = <Context = undefined>({
  doDelete: doDeleteProp,
  doCancel,
  shouldConfirm,
  getModalProps,
}: UseDeleteModalProps<Context>): [Context extends undefined ? () => void : (context: Context) => void, ReactElement | null] => {
  const [modalContext, setModalContext] = useState<{ display: false } | { display: true, context: Context }>({ display: false });

  const doDelete = (context: Context, alternateConfirm: boolean) => {
    setModalContext({ display: false });
    const toastMessage = doDeleteProp(context, alternateConfirm);
    if (toastMessage) {
      notifySuccess(toastMessage);
    }
  };

  let modalComponent: ReactElement | null = null;
  if (modalContext.display) {
    const { title, confirmLabel, alternateConfirmLabel, content } = getModalProps(modalContext.context);
    modalComponent = (
      <ConfirmationModal
        open
        variant={ConfirmationModalVariant.delete}
        title={title}
        onConfirm={() => doDelete(modalContext.context, false)}
        confirmLabel={confirmLabel}
        onAlternateConfirm={() => doDelete(modalContext.context, true)}
        alternateConfirmLabel={alternateConfirmLabel}
        onCancel={() => {
          doCancel?.();
          setModalContext({ display: false });
        }}
        render={() => content}
      />
    );
  }

  return [
    ((context) => {
      if (shouldConfirm(context)) {
        setModalContext({ display: true, context });
      } else {
        doDelete(context, false);
      }
    }) as Context extends undefined ? () => void : (context: Context) => void,
    modalComponent,
  ];
};

export default useDeleteModal;
