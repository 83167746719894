import type { RefObject } from 'react';
import { forwardRef, useState } from 'react';
import type { Offset } from 'react-overlays/usePopper';
import { CollaborationMessage_Collaboration } from 'yooi-modules/modules/collaborationModule/ids';
import { getUnreadNotificationsForUser } from 'yooi-modules/modules/notificationModule';
import {
  Notification,
  Notification_CreatedAt,
  Notification_Role_Message,
  Notification_Role_User,
  Notification_Seen,
  Notification_Title,
} from 'yooi-modules/modules/notificationModule/ids';
import type { StoreObject } from 'yooi-store';
import { compareNumber, compareProperty, filterNullOrUndefined } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../components/atoms/Button';
import Icon, { IconName, IconSizeVariant } from '../../../components/atoms/Icon';
import { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import Typo, { TypoAlign } from '../../../components/atoms/Typo';
import OverflowMenu from '../../../components/molecules/OverflowMenu';
import type { OverlayPlacement } from '../../../components/molecules/Overlay';
import Overlay from '../../../components/molecules/Overlay';
import ToggleButton, { ElementPosition } from '../../../components/molecules/ToggleButton';
import useAuth from '../../../store/useAuth';
import useStore from '../../../store/useStore';
import base from '../../../theme/base';
import { FontVariant } from '../../../theme/fontDefinition';
import { getSpacingAsNumber, Spacing, spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { remToPx } from '../../../utils/sizeUtils';
import useDeleteModal from '../../../utils/useDeleteModal';
import useTheme from '../../../utils/useTheme';
import { collaborationAvailableFilter } from '../rightPanel/collaboration/utils/collaborationUtils';
import NotificationCard from './NotificationCard';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    overflowX: 'hidden',
    flexDirection: 'column',
    borderRadius: base.borderRadius.large,
    minWidth: '35rem',
    maxWidth: '35rem',
    minHeight: '64vh',
    maxHeight: '64vh',
    backgroundColor: theme.color.background.neutral.default,
    boxShadow: base.shadowElevation.medium,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.s,
    zIndex: 1,
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
    paddingTop: spacingRem.m,
    paddingBottom: spacingRem.splus,
  },
  headerLine: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: spacingRem.s,
    height: '3.2rem',
    paddingLeft: spacingRem.l,
    paddingRight: spacingRem.l,
  },
  headerTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
  headerActionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
  emptyContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.m,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.s,
    justifyContent: 'center',
    alignItems: 'center',
  },
  notificationsContainer: {
    display: 'flex',
    overflow: 'hidden',
    flexGrow: '1',
    flexDirection: 'column',
    overflowY: 'scroll',
  },
  loadMoreContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.s,
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacingRem.m,
  },
}), 'notificationsPopover');

interface NotificationsPopoverProps {
  anchorRef: RefObject<HTMLElement>,
  onBackdropClick?: (event: MouseEvent, preventDefault?: boolean) => void,
  onClose: () => void,
  placement?: OverlayPlacement,
  offset?: Offset,
}

interface NotificationElement {
  key: string,
  storeId: string[],
  title: string | undefined,
  collaboration: StoreObject | undefined,
  message: StoreObject | undefined,
  createdAt: number,
  seen: boolean,
}

const NotificationsPopover = forwardRef<HTMLDivElement, NotificationsPopoverProps>(({ anchorRef, onBackdropClick, onClose, placement, offset }, ref) => {
  const classes = useStyles();
  const theme = useTheme();
  const store = useStore();
  const { loggedUserId } = useAuth();
  const [showUnreadOnly, setShowUnreadOnly] = useState(false);
  const [nbOfNotifications, setNbOfNotifications] = useState(10);
  const [deleteModalShown, setDeleteModalShown] = useState(false);
  const [doDelete, deleteModal] = useDeleteModal({
    doDelete: () => {
      store
        .withAssociation(Notification)
        .withExternalRole(Notification_Role_Message)
        .withRole(Notification_Role_User, loggedUserId)
        .list().forEach((notificationAssociation) => store.deleteObject(notificationAssociation.object.id));
      setDeleteModalShown(false);
    },
    doCancel: () => {
      setDeleteModalShown(false);
    },
    shouldConfirm: () => true,
    getModalProps: () => ({
      title: i18n`Are you sure that you want to delete all notifications?`,
      content: (
        <Typo>{i18n`You will still have access to your collaborations, but existing notifications will be permanently deleted.`}</Typo>
      ),
    }),
  });

  const unreadNotifications = getUnreadNotificationsForUser(store, loggedUserId)
    .filter((notificationAssociation) => collaborationAvailableFilter(store, notificationAssociation));
  const updateReadState = (notificationStoreId: string[], state: boolean) => store.updateObject(notificationStoreId, { [Notification_Seen]: state });
  const markAllAsRead = () => unreadNotifications.forEach((notificationAssociation) => updateReadState(notificationAssociation.object.id, true));

  const notifications: NotificationElement[] = store
    .withAssociation(Notification)
    .withExternalRole(Notification_Role_Message)
    .withRole(Notification_Role_User, loggedUserId)
    .list()
    .filter((notificationAssociation) => collaborationAvailableFilter(store, notificationAssociation))
    .filter((notificationAssociation) => {
      if (showUnreadOnly) {
        return !notificationAssociation.object[Notification_Seen] && notificationAssociation.navigateRoleOrNull(Notification_Role_Message)
          ?.navigateOrNull(CollaborationMessage_Collaboration);
      } else {
        return notificationAssociation;
      }
    })
    .map((notification) => {
      const message = notification.navigateRoleOrNull(Notification_Role_Message) ?? undefined;
      return message ? ({
        key: notification.object.key,
        storeId: notification.object.id,
        title: notification.object[Notification_Title] as string | undefined,
        collaboration: message.navigateOrNull(CollaborationMessage_Collaboration) ?? undefined,
        message,
        createdAt: notification.object[Notification_CreatedAt] as number,
        seen: notification.object[Notification_Seen] as boolean | undefined ?? false,
      }) : null;
    })
    .filter(filterNullOrUndefined)
    .sort(compareProperty('createdAt', compareNumber)).reverse();

  const displayedNotifications = notifications.slice(0, nbOfNotifications);
  const loadMoreNumber = notifications.length - displayedNotifications.length > 10 ? 10 : notifications.length - displayedNotifications.length;

  return (
    <>
      <Overlay
        target={anchorRef}
        sameWidth={false}
        isBoundary={false}
        onBackdropClick={(event) => {
          if (deleteModalShown) {
            event.preventDefault();
          } else {
            return onBackdropClick === undefined ? onClose : onBackdropClick(event, deleteModalShown);
          }
        }}
        onEscapeKeyDown={onClose}
        placement={placement ?? 'bottom-start'}
        // if direction is vertical, we add an horizontal offset -9 to align icons
        offset={offset ?? [-9, remToPx(getSpacingAsNumber(Spacing.xs))]}
        // Include a bottom margin to make sure the bottom shadow is visible when in scroll container
        containerMarginBottom="0.4rem"
      >
        <div ref={ref} className={classes.container}>
          <div className={classes.headerContainer}>
            <div className={classes.headerLine}>
              <div className={classes.headerTitleContainer}>
                <Typo maxLine={1} variant={FontVariant.blockPrimaryTitle}>
                  {i18n`Notifications`}
                </Typo>
              </div>
              <div className={classes.headerActionContainer}>
                <OverflowMenu
                  key="readActionsOverflowMenu"
                  buttonVariant={IconOnlyButtonVariants.secondary}
                  iconName={IconName.more_horiz}
                  placement="bottom-end"
                  offset={[0, remToPx(getSpacingAsNumber(Spacing.xs))]}
                  menuItems={[
                    {
                      key: 'delete',
                      name: i18n`Delete all`,
                      icon: IconName.delete,
                      onClick: () => {
                        setDeleteModalShown(true);
                        doDelete();
                      },
                      danger: true,
                    },
                  ]}
                />
              </div>
            </div>
            <div className={classes.headerLine}>
              <ToggleButton
                key="unreadOnly"
                name={i18n`Unread only (${unreadNotifications.length})`}
                icon={showUnreadOnly ? IconName.toggle_on : IconName.toggle_off}
                onClick={() => setShowUnreadOnly(!showUnreadOnly)}
                active={showUnreadOnly}
                type={ElementPosition.alone}
              />
              <Button
                variant={ButtonVariant.tertiary}
                iconName={IconName.check_circle}
                title={i18n`Mark all as read`}
                onClick={markAllAsRead}
                disabled={unreadNotifications.length === 0}
              />
            </div>
          </div>
          <div className={classes.notificationsContainer}>
            {displayedNotifications.length > 0 ? displayedNotifications.map((notification) => (<NotificationCard notificationId={notification.storeId} key={notification.key} />))
              : (
                <div className={classes.emptyContainer}>
                  <Icon name={IconName.inbox} size={IconSizeVariant.xxxl} color={theme.color.text.secondary} />
                  <div className={classes.emptyMessageContainer}>
                    <Typo color={theme.color.text.secondary} variant={FontVariant.blockSecondaryTitle}>{i18n`You're all caught up`}</Typo>
                    <Typo
                      color={theme.color.text.secondary}
                      align={TypoAlign.center}
                    >
                      {i18n`No unread notifications here.\n Use the toggle to switch between\n read and unread ones.`}
                    </Typo>
                  </div>
                </div>
              )}
            {loadMoreNumber > 0 ? (
              <div className={classes.loadMoreContainer}>
                <Button
                  variant={ButtonVariant.tertiary}
                  title={i18n`Load ${loadMoreNumber} more`}
                  onClick={() => setNbOfNotifications(nbOfNotifications + 10)}
                />
              </div>
            ) : null}
          </div>
        </div>
      </Overlay>
      {deleteModal}
    </>
  );
});

export default NotificationsPopover;
