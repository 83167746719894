import { equals } from 'ramda';
import { newError } from 'yooi-utils';
import { asImport } from '../../../common/fields/commonPropertyType';
import type { GetDslFieldHandler } from '../../../common/fields/FieldModuleDslType';
import { registerField } from '../../module';
import type { IFrameField } from '../types';

type IFrameFieldHandler = GetDslFieldHandler<IFrameField, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined>;
export const iFrameFieldHandler: IFrameFieldHandler = registerField({
  model: {
    label: 'IFrameField',
    title: 'IFrame',
    properties: [
      { label: 'Url', as: asImport('IFrameConfiguration', 'modules/conceptLayoutModule/fields/iFrameField') },
    ],
  },
  handler: () => ({
    describe: () => ({ hasData: false }),
    restApi: {
      returnTypeSchema: {},
      formatValue: () => undefined,
    },
    getStoreValue: () => undefined,
    getValueWithoutFormula: () => undefined,
    getValueResolution: () => ({ value: undefined, getDisplayValue: () => undefined, isComputed: false, isTimeseries: false }),
    updateValue: () => {
      throw newError('updateValue not supported');
    },
    resolvePathStepConfiguration: () => ({ hasData: false }),
    isEmpty: () => false,
    isSaneValue: () => ({ isValid: true }),
  }),
  historyEventProducer: ({ getObjectOrNull }, fieldId) => ({
    value: {
      collectImpactedInstances: ({ id, properties }) => {
        if (
          properties?.[fieldId] !== undefined // A value is in the update for the current field
          || (properties === null && getObjectOrNull(id)?.[fieldId] !== undefined) // Object is deleted and store had a value for the field
        ) {
          return [id];
        } else {
          return [];
        }
      },
      getValue: (id) => ({ value: (getObjectOrNull(id)?.[fieldId] as number | undefined) ?? null, version: 1 }),
      areValuesEquals: (value1, value2) => equals(value1?.value, value2?.value),
    },
  }),
});
