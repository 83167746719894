import { compareNumber, DATE_MAX_TIMESTAMP, DATE_MIN_TIMESTAMP, extractAndCompareValue } from 'yooi-utils';
import type { TimeRange } from '../utils/TimeseriesType';
import { mergeTimeValidities } from '../utils/timeseriesUtils';
import type { TimeseriesItem, TimeseriesRepository, TimeseriesUpdate, TimeseriesValue } from './TimeseriesRepositoryType';

const STORAGE_ID_SEPARATOR = '_';

const getTimeRangesIntersections = (timeRanges: TimeRange[], timeRangeToIntersect: TimeRange): TimeRange[] => {
  const min = timeRangeToIntersect.from;
  const max = timeRangeToIntersect.to;
  let intersections: TimeRange[] = [];

  timeRanges.forEach((timeRange) => {
    let { from, to } = timeRange;
    if (timeRange.from >= max || timeRange.to <= min) {
      return;
    }
    if (timeRange.from < min) {
      from = min;
    }
    if (timeRange.to > max) {
      to = max;
    }
    intersections = mergeTimeValidities(intersections, { from, to });
  });

  return intersections;
};

const getTimeRangesFirstIntersection = (timeRanges: TimeRange[], timeRangeToIntersect: TimeRange): { intersectedTimeRange: TimeRange, intersection: TimeRange } | undefined => {
  const min = timeRangeToIntersect.from;
  const max = timeRangeToIntersect.to;
  const firstIntersection = timeRanges.find((timeRange) => !(timeRange.from >= max || timeRange.to <= min));
  if (!firstIntersection) {
    return undefined;
  }
  let { from, to } = firstIntersection;
  if (firstIntersection.from < min) {
    from = min;
  }
  if (firstIntersection.to > max) {
    to = max;
  }

  return { intersectedTimeRange: firstIntersection, intersection: { from, to } };
};

const unionOpposite = (timeRanges: TimeRange[]): TimeRange[] => {
  const result: TimeRange[] = [];

  if (!timeRanges.length) {
    return [{ from: DATE_MIN_TIMESTAMP, to: DATE_MAX_TIMESTAMP }];
  }

  const sortedTimeRanges = timeRanges.sort(extractAndCompareValue(({ from }) => from, compareNumber));
  sortedTimeRanges.forEach(({ from, to }, index, self) => {
    if (index === 0 && from !== DATE_MIN_TIMESTAMP) {
      if (DATE_MIN_TIMESTAMP !== from) {
        result.push({ from: DATE_MIN_TIMESTAMP, to: from });
      }
    }
    if (index < self.length - 1) {
      if (to !== self[index + 1].from) {
        result.push({ from: to, to: self[index + 1].from });
      }
    }
    if (index === self.length - 1 && to !== DATE_MAX_TIMESTAMP) {
      if (to !== DATE_MAX_TIMESTAMP) {
        result.push({ from: to, to: DATE_MAX_TIMESTAMP });
      }
    }
  });

  return result;
};

const getTimeRangesDiff = (timeRanges: TimeRange[], timeRangeToDiff: TimeRange): TimeRange[] => {
  const { from: fromDiff, to: toDiff } = timeRangeToDiff;
  return timeRanges.flatMap(({ from, to }) => {
    if (fromDiff <= from && toDiff >= to) {
      return [];
    } else if (toDiff < from || fromDiff > to) {
      return [{ from, to }];
    } else if (fromDiff <= from && toDiff < to) {
      return [{ from: toDiff, to }];
    } else if (fromDiff > from && toDiff < to) {
      return [{ from, to: fromDiff }, { from: toDiff, to }];
    } else {
      return [{ from, to: fromDiff }];
    }
  });
};

const getStorageId = (id: string | string[], propertyId: string): string => {
  let joinedIds: string | undefined;
  if (typeof id === 'string' && id.length > 0) {
    joinedIds = id;
  } else if (Array.isArray(id) && id.length > 0) {
    joinedIds = id.join('|');
  }
  return `${joinedIds}${STORAGE_ID_SEPARATOR}${propertyId}`;
};

const getIdsFromStorageId = (storageId: string): { id: string[], propertyId: string } => {
  const [joinedIds, propertyId] = storageId.split(STORAGE_ID_SEPARATOR);
  return { id: joinedIds.split('|'), propertyId };
};

interface TimeseriesRepositoryRaw extends TimeseriesRepository {
  onUpdate: (onUpdate?: () => void) => TimeseriesRepository,
}

const createTimeseriesRepository = (): TimeseriesRepositoryRaw => {
  let timeseriesRepository: Record<string, TimeseriesItem> = {};

  const getItem = (id: string | string[], propertyId: string): TimeseriesItem | undefined => {
    const storeId = getStorageId(id, propertyId);
    return timeseriesRepository[storeId];
  };

  const updateItem = (id: string | string[], propertyId: string, timeRangesValidity: TimeRange[], values: TimeseriesValue[]) => {
    const storeId = getStorageId(id, propertyId);
    timeseriesRepository[storeId] = {
      timeRangesValidity,
      values,
    };
  };

  const applyOperationOnStore = (
    id: string | string[],
    propertyId: string,
    timeRange: TimeRange,
    values: {
      time: number,
      value: unknown,
    }[] | undefined
  ): TimeseriesUpdate[] => {
    const currentTimeRangesValidity = getItem(id, propertyId)?.timeRangesValidity || [];
    const currentValues = getItem(id, propertyId)?.values || [];

    const intersections = getTimeRangesIntersections(currentTimeRangesValidity, timeRange);

    const newValues: Record<string, unknown> = {};
    let newTimeRangesValidity: TimeRange[];
    let rollbacks: TimeseriesUpdate[];
    if (values === undefined) {
      const diff = getTimeRangesDiff(currentTimeRangesValidity, timeRange);
      // commit => time range validity = time range validity - time range
      // rollback intersections with their values
      rollbacks = intersections.map((intersection): TimeseriesUpdate => ({ id, propertyId, timeRange: intersection, values: [] }));
      currentValues.forEach((oldValue) => {
        const { time, value } = oldValue;
        const rollback = rollbacks.find(({ timeRange: intersectionTimeRange }) => time >= intersectionTimeRange.from && time < intersectionTimeRange.to);
        if (rollback) {
          rollback.values = [...rollback?.values || [], oldValue];
        } else {
          newValues[time] = value;
        }
      });
      newTimeRangesValidity = diff;
    } else {
      const union = mergeTimeValidities(currentTimeRangesValidity, timeRange);
      const differences = getTimeRangesIntersections(unionOpposite(currentTimeRangesValidity), timeRange);
      // commit => time range validity = union
      //           values
      // rollback intersections with their values
      // rollback differences with undefined
      const intersectionRollbacks: TimeseriesUpdate[] = intersections.map((intersection): TimeseriesUpdate => ({ id, propertyId, timeRange: intersection, values: [] }));
      const differenceRollbacks: TimeseriesUpdate[] = differences.map((difference): TimeseriesUpdate => ({ id, propertyId, timeRange: difference, values: undefined }));
      currentValues.forEach((oldValue) => {
        const { time, value } = oldValue;
        const intersectionRollback = intersectionRollbacks.find(({ timeRange: intersectionTimeRange }) => time >= intersectionTimeRange.from && time < intersectionTimeRange.to);
        if (intersectionRollback) {
          intersectionRollback.values = [...intersectionRollback?.values || [], oldValue];
        } else {
          newValues[time] = value;
        }
      });
      rollbacks = [...intersectionRollbacks, ...differenceRollbacks];
      newTimeRangesValidity = union;
    }

    values?.filter(({ time }) => time >= timeRange.from && time < timeRange.to)
      .forEach(({ time, value }) => {
        newValues[time] = value;
      });

    updateItem(id, propertyId, newTimeRangesValidity, Object.entries(newValues).map(([time, value]): TimeseriesValue => ({ time: Number(time), value })));

    return rollbacks;
  };

  const getTimeseriesItem = (objectId: string | string[], propertyId: string): TimeseriesItem => {
    const timeseriesItem = getItem(objectId, propertyId);
    if (!timeseriesItem) {
      return { timeRangesValidity: [], values: [] };
    }
    return { timeRangesValidity: [...timeseriesItem.timeRangesValidity], values: [...timeseriesItem.values] };
  };

  const getNextTime = (time: number) => {
    if (time === Number.MAX_VALUE) {
      return DATE_MAX_TIMESTAMP;
    } else {
      return time + 1;
    }
  };

  const getTimeseries = <Value = unknown, Id extends (string | string[]) = string>(
    objectId: Id,
    propertyId: string,
    timeRange?: { from: number, to: number },
    withNextValue = false
  ) => {
    const timeseriesItem = getItem(objectId, propertyId);
    const timeRangeInner = timeRange ?? { from: DATE_MIN_TIMESTAMP, to: DATE_MAX_TIMESTAMP };
    if (!timeseriesItem) {
      return undefined;
    }
    const firstIntersection = getTimeRangesFirstIntersection(timeseriesItem.timeRangesValidity, timeRangeInner);
    if (firstIntersection && firstIntersection.intersection.from === timeRangeInner.from && firstIntersection.intersection.to === timeRangeInner.to) {
      const { intersection } = firstIntersection;
      let previousValue: TimeseriesValue | undefined;
      let nextValue: TimeseriesValue | undefined;
      let firstIntersectionValueTime: number | undefined;
      let lastIntersectionValueTime: number | undefined;
      const values: TimeseriesValue[] = [];
      getItem(objectId, propertyId)?.values.forEach(({ time, value }) => {
        if (time >= intersection.from && time < intersection.to) {
          if (firstIntersectionValueTime !== undefined) {
            firstIntersectionValueTime = Math.min(firstIntersectionValueTime, time);
          } else {
            firstIntersectionValueTime = time;
          }
          if (lastIntersectionValueTime !== undefined) {
            lastIntersectionValueTime = Math.max(lastIntersectionValueTime, getNextTime(time));
          } else {
            lastIntersectionValueTime = getNextTime(time);
          }
          values.push({ time, value });
        } else if (time < intersection.from) {
          if (previousValue) {
            if (previousValue.time < time) {
              previousValue = { time, value };
            }
          } else {
            previousValue = { time, value };
          }
        } else if (time >= intersection.to) {
          if (nextValue) {
            if (nextValue.time > time) {
              nextValue = { time, value };
            }
          } else {
            nextValue = { time, value };
          }
        }
      });
      let from: number | undefined;
      const previousValuesToAdd: TimeseriesValue[] = [];
      if (firstIntersectionValueTime === timeRangeInner.from) {
        from = timeRangeInner.from;
      } else if (previousValue) {
        from = previousValue.time;
        previousValuesToAdd.push(previousValue);
      } else if (firstIntersection.intersectedTimeRange.from === DATE_MIN_TIMESTAMP) {
        from = DATE_MIN_TIMESTAMP;
      }
      let to: number | undefined;
      const nextValuesToAdd: TimeseriesValue[] = [];
      if (!withNextValue || lastIntersectionValueTime === timeRangeInner.to) {
        to = timeRangeInner.to;
      } else if (nextValue) {
        to = getNextTime(nextValue.time);
        nextValuesToAdd.push(nextValue);
      } else if (firstIntersection.intersectedTimeRange.to === DATE_MAX_TIMESTAMP) {
        to = DATE_MAX_TIMESTAMP;
      }
      if (from && to) {
        return { timeRange: { from, to }, values: [...previousValuesToAdd, ...values, ...nextValuesToAdd] } as { timeRange: TimeRange, values: TimeseriesValue<Value>[] };
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  const updateTimeseries = (
    timeseriesUpdates: TimeseriesUpdate[]
  ): TimeseriesUpdate[] => {
    const rollbackOperations: TimeseriesUpdate[] = [];

    timeseriesUpdates.forEach(({ id, propertyId, timeRange, values }) => {
      rollbackOperations.push(...applyOperationOnStore(id, propertyId, timeRange, values));
    });

    return rollbackOperations;
  };

  const insertInFetchedTimeseries = (
    timeseriesUpdates: TimeseriesUpdate[]
  ): TimeseriesUpdate[] => {
    const rollbackOperations: TimeseriesUpdate[] = [];

    timeseriesUpdates.forEach(({ id, propertyId, timeRange, values }) => {
      const currentTimeRangesValidity = getItem(id, propertyId)?.timeRangesValidity || [];
      const intersections = getTimeRangesIntersections(currentTimeRangesValidity, timeRange);
      intersections.forEach((intersection) => {
        rollbackOperations.push(...applyOperationOnStore(id, propertyId, intersection, values));
      });
    });

    return rollbackOperations;
  };

  const reset = () => {
    timeseriesRepository = {};
  };

  const getLoadedTimeseries = () => Object.entries(timeseriesRepository)
    .map(([storageId, { timeRangesValidity, values }]) => ({ ...getIdsFromStorageId(storageId), timeRangesValidity, values }));

  return {
    getLoadedTimeseries,
    getTimeseriesItem,
    reset,
    insertInFetchedTimeseries,
    getTimeseries,
    updateTimeseries,
    onUpdate: (onUpdate) => ({
      getLoadedTimeseries,
      getTimeseriesItem,
      reset: () => {
        onUpdate?.();
        return reset();
      },
      insertInFetchedTimeseries: (timeseriesUpdates: TimeseriesUpdate[]): TimeseriesUpdate[] => {
        onUpdate?.();
        return insertInFetchedTimeseries(timeseriesUpdates);
      },
      getTimeseries,
      updateTimeseries: (timeseriesUpdates: TimeseriesUpdate[]): TimeseriesUpdate[] => {
        onUpdate?.();
        return updateTimeseries(timeseriesUpdates);
      },
    }),
  };
};

export default createTimeseriesRepository;

export const testables = {
  getTimeRangesIntersections,
  getTimeRangesFirstIntersection,
  unionOpposite,
  getTimeRangesDiff,
};
