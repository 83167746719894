import type { ObjectStore } from 'yooi-store';
import type { DimensionStep, FieldStep, FilterStep, GlobalDimensionStep, MappingStep, MultipleMappingStep, PathStep } from '../../moduleType';
import { PathStepType } from '../../moduleType';
import { InstanceReferenceType } from '../filters/filters';
import type { MultipleParameterDefinition, SingleParameterDefinition } from '../parametersUtils';
import type { LastFieldInfo } from './path';

export const isDimensionStep = (step: PathStep | undefined): step is DimensionStep => step?.type === PathStepType.dimension;
export const isGlobalDimensionStep = (step: PathStep | undefined): step is GlobalDimensionStep => step?.type === PathStepType.global;
export const isFilterStep = (step: PathStep | undefined): step is FilterStep => step?.type === PathStepType.filter;
export const isMultipleMappingStep = (step: PathStep | undefined): step is MultipleMappingStep => step?.type === PathStepType.multipleMapping;
export const isMappingStep = (step: PathStep | undefined): step is MappingStep => step?.type === PathStepType.mapping;
export const isFieldStep = (step: PathStep | undefined): step is FieldStep => step?.type === PathStepType.field;

export const isPathValid = (store: ObjectStore, path: PathStep[], parametersDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[]): boolean => {
  for (let i = 0; i < path.length; i += 1) {
    const nStep = path[i];
    if (isMappingStep(nStep)) {
      if (nStep.mapping.type === InstanceReferenceType.instance && !store.getObjectOrNull(nStep.mapping.id)) {
        return false;
      } else if (nStep.mapping.type === InstanceReferenceType.parameter && !parametersDefinitions.some(({ id }) => id === nStep.mapping.id)) {
        return false;
      }
    } else if (isFieldStep(nStep)) {
      if (!store.getObjectOrNull(nStep.fieldId)) {
        return false;
      }
    }
  }
  return true;
};

export const getPathLastFieldInformation = (path: PathStep[]): LastFieldInfo | undefined => {
  let conceptDefinitionId: string | undefined;
  let lastFieldInfo: LastFieldInfo | undefined;
  for (let i = 0; i < path.length; i += 1) {
    const nStep = path[i];
    if (isGlobalDimensionStep(nStep)) {
      // nothing is returned
    } else if (isDimensionStep(nStep)) {
      // a list of concept of dimension step conceptDefinition is returned
      conceptDefinitionId = nStep.conceptDefinitionId;
    } else if (isMappingStep(nStep)) {
      // only multiplicity change
    } else if (isMultipleMappingStep(nStep)) {
      // only multiplicity change
    } else if (isFieldStep(nStep)) {
      lastFieldInfo = {
        conceptDefinitionId,
        fieldId: nStep.fieldId,
        pathBeforeField: path.slice(0, i),
        pathToField: path.slice(0, i + 1),
        pathAfterField: path.slice(i + 1),
      };
    } else if (isFilterStep(nStep)) {
      // only multiplicity change
    }
  }
  return lastFieldInfo;
};
