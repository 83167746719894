import type { FunctionComponent } from 'react';
import type { WeekRepeat } from 'yooi-modules/modules/automationModule';
import type { WeekdayNumbers } from 'yooi-utils';
import { joinObjects } from 'yooi-utils';
import SpacedContainer from '../../../components/molecules/SpacedContainer';
import SpacingLine from '../../../components/molecules/SpacingLine';
import TagContainer from '../../../components/molecules/TagContainer';
import ToggleButton from '../../../components/molecules/ToggleButton';
import { Spacing } from '../../../theme/spacingDefinition';
import type { Option } from '../../_global/modelTypeUtils';
import { getWeekDayLabel } from './automationUtils';

interface WeekRepeatRuleInputProps {
  repeatRule: WeekRepeat,
  onChange: (repeatRule: WeekRepeat) => void,
}

interface WeekDayOption extends Option {
  value: WeekdayNumbers,
  textColor?: string,
}

export const getWeekDayOptions = (): WeekDayOption[] => [
  { id: '1', value: 1, label: getWeekDayLabel(1) },
  { id: '2', value: 2, label: getWeekDayLabel(2) },
  { id: '3', value: 3, label: getWeekDayLabel(3) },
  { id: '4', value: 4, label: getWeekDayLabel(4) },
  { id: '5', value: 5, label: getWeekDayLabel(5) },
  { id: '6', value: 6, label: getWeekDayLabel(6) },
  { id: '7', value: 7, label: getWeekDayLabel(7) },
];

const WeekRepeatRuleInput: FunctionComponent<WeekRepeatRuleInputProps> = ({ repeatRule, onChange }) => (
  <SpacedContainer margin={{ left: Spacing.s }}>
    <SpacingLine>
      <TagContainer>
        {
          getWeekDayOptions()
            .map(({ id, value, label }) => (
              <ToggleButton
                key={id}
                name={label}
                active={!!repeatRule.weekDays.find((weekDayNumber) => weekDayNumber === value)}
                onClick={() => {
                  const newDays = new Set(repeatRule.weekDays);
                  if (newDays.has(value)) {
                    newDays.delete(value);
                  } else {
                    newDays.add(value);
                  }
                  onChange(joinObjects(repeatRule, { weekDays: Array.from(newDays) }));
                }}
              />
            ))
        }
      </TagContainer>
    </SpacingLine>
  </SpacedContainer>
);

export default WeekRepeatRuleInput;
