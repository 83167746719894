import classnames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import type { SpacingDefinitionProp } from '../../theme/spacingDefinition';
import { buildMargins } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
  },
}, 'spacedContainer');

interface SpacedContainerProps {
  margin?: SpacingDefinitionProp,
  flex?: boolean,
  children: ReactNode,
}

const SpacedContainer: FunctionComponent<SpacedContainerProps> = ({ margin, flex = false, children }) => {
  const classes = useStyles();

  return (
    <div
      style={buildMargins(margin)}
      className={classnames({
        [classes.container]: flex,
      })}
    >
      {children}
    </div>
  );
};

export default SpacedContainer;
