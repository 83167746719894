import type { ObjectStore } from '../../ObjectStoreType';
import type { InitializedModule } from '../platformModule';
import type { OnObjectRegistrationHandler, PropertyFunctionRegistration, PropertyFunctionsRegistration, RegisterPropertyFunctions } from '../PlatformModulesTypes';

export interface PropertyFunctionLibrary {
  init: () => void,
  onObjectRepositoryUpdate: (id: string | string[], properties: Record<string, unknown> | null) => void,
}

export const createPropertyFunctionLibrary = (modules: InitializedModule[], objectStore: ObjectStore): PropertyFunctionLibrary => {
  let propertyFunctionModuleExtensions: PropertyFunctionRegistration[] = [];

  let propertyFunctionsRegistrations: PropertyFunctionsRegistration = {};

  const onObject: OnObjectRegistrationHandler = (key) => ({
    register: (handler) => {
      propertyFunctionsRegistrations[key] = [...(propertyFunctionsRegistrations[key] ?? []), handler];
    },
  });

  return {
    init: () => {
      propertyFunctionsRegistrations = {};

      const { registerPropertyFunction, unregisterPropertyFunction } = objectStore;

      propertyFunctionModuleExtensions = modules
        .map((module) => module.registerPropertyFunctions)
        .filter((register): register is RegisterPropertyFunctions => Boolean(register))
        .map((register) => register(objectStore, { onObject, registerPropertyFunction, unregisterPropertyFunction }))
        .filter((value): value is PropertyFunctionRegistration => value !== null && value !== undefined);

      objectStore.forEachObject((object) => {
        const properties = object.asRawObject();
        propertyFunctionModuleExtensions.forEach((handler) => {
          handler(object.id, properties, propertyFunctionsRegistrations);
        });
      });
    },
    onObjectRepositoryUpdate: (id, properties) => {
      objectStore.invalidatePropertyCache();
      propertyFunctionModuleExtensions.forEach((handler) => {
        handler(id, properties, propertyFunctionsRegistrations);
      });
    },
  };
};
