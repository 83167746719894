import type { Location } from 'react-router-dom';
import { validate as validateUuid } from 'uuid';
import type { AutomationRuleStoreObject } from 'yooi-modules/modules/automationModule';
import { AutomationRule, AutomationRule_UserId } from 'yooi-modules/modules/automationModule/ids';
import { Intention, Intention_Name } from 'yooi-modules/modules/collaborationModule/ids';
import type { ConceptDefinitionStoreObject, ConceptStoreObject, FieldStoreObject, Filters, GlobalFilter, PathStep, WorkflowStoreObject } from 'yooi-modules/modules/conceptModule';
import {
  FILTER_PARAMETER_LOGGED_USER,
  getConceptDefinitionUrl,
  getConceptUrl,
  getFieldDimensionOfModelType,
  getInstanceLabelOrUndefined,
  getParentConceptInstance,
  iconFieldHandler,
  InstanceReferenceType,
  isSingleRelationalType,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import { Concept, ConceptDefinition, ConceptDefinition_Icon, ConceptDefinition_MainIconField, Field, FieldDefinition, Workflow } from 'yooi-modules/modules/conceptModule/ids';
import type { DashboardParameterStoreObject, DashboardStoreObject, WidgetStoreObject } from 'yooi-modules/modules/dashboardModule';
import { Dashboard, DashboardParameter, DashboardParameter_Dashboard, Widget, Widget_Dashboard } from 'yooi-modules/modules/dashboardModule/ids';
import type { IntegrationStoreObject } from 'yooi-modules/modules/integrationModule';
import { Integration } from 'yooi-modules/modules/integrationModule/ids';
import type { LeftBarItemStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import { LeftBarItem_Path } from 'yooi-modules/modules/platformConfigurationModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreReadOnly, StoreObject } from 'yooi-store';
import type { WithOptionalField } from 'yooi-utils';
import { joinObjects } from 'yooi-utils';
import type { IconName } from '../../components/atoms/Icon';
import type { NavigationElement, NavigationElementsOrSingleKey } from '../../components/molecules/NavigationTitle';
import { NavigationTitlePathElements as ImportedNavigationTitlePathElements } from '../../components/molecules/NavigationTitle';
import type { FrontObjectStore } from '../../store/useStore';
import i18n from '../../utils/i18n';
import type { NavigationLocation, NavigationPayload, UseNavigation } from '../../utils/useNavigation';
import { getHomepageConcept } from '../utils/homepageUtils';
import { getFieldConfigurationHandler } from './fields/FieldLibrary';
import { getFieldLabel } from './fieldUtils';
import { getLoggedUserParameterDefinition } from './filter/filterUtils';
import { getConceptDefinitionNameOrEntity } from './modelTypeUtils';
import type { PathStepValidator } from './pathConfigurationHandler';
import { createPathConfigurationHandler, StepValidationState } from './pathConfigurationHandler';

export { ImportedNavigationTitlePathElements as NavigationTitlePathElements };

export const leftBarItemValidator: (store: ObjectStoreReadOnly) => PathStepValidator = (store) => ({ pathStep, isNPath }) => {
  switch (pathStep.type) {
    case PathStepType.global:
      return [{ state: StepValidationState.invalid, reasonMessage: i18n`Global dimension step not supported.` }];
    case PathStepType.dimension: {
      return [{ state: StepValidationState.valid }];
    }
    case PathStepType.filter:
      if (isNPath) {
        return [{ state: StepValidationState.invalid, reasonMessage: i18n`Filters are not supported.` }];
      } else {
        return [{ state: StepValidationState.valid }];
      }
    case PathStepType.mapping: {
      if (
        (pathStep.mapping.type === InstanceReferenceType.parameter && pathStep.mapping.id !== FILTER_PARAMETER_LOGGED_USER)
        || (pathStep.mapping.type === InstanceReferenceType.instance && store.getObjectOrNull(pathStep.mapping.id) === null)
      ) {
        return [{ state: StepValidationState.invalid, reasonMessage: i18n`Second step must be an existing instance ot parameter.` }];
      } else {
        return [{ state: StepValidationState.valid }];
      }
    }
    case PathStepType.multipleMapping: {
      if (pathStep.id !== FILTER_PARAMETER_LOGGED_USER) {
        return [{ state: StepValidationState.invalid, reasonMessage: i18n`Second step must be an existing instance ot parameter.` }];
      } else {
        return [{ state: StepValidationState.valid }];
      }
    }
    case PathStepType.field: {
      const field = store.getObjectOrNull<FieldStoreObject>(pathStep.fieldId);
      if (field === null) {
        return [{ state: StepValidationState.invalid, reasonMessage: i18n`Field does not exist anymore.` }];
      } else if (!isSingleRelationalType(field[Instance_Of])) {
        return [{ state: StepValidationState.invalid, reasonMessage: i18n`Field must returns an instance.` }];
      } else {
        return [{ state: StepValidationState.valid }];
      }
    }
  }
};

export const isValidLeftBarItem = (store: FrontObjectStore, leftBarItem: LeftBarItemStoreObject): boolean => {
  const path = leftBarItem[LeftBarItem_Path];
  if (path === undefined || path.length === 0) {
    return false;
  }

  const parameterDefinitions = [getLoggedUserParameterDefinition()];
  const errors = createPathConfigurationHandler(store, parameterDefinitions, [leftBarItemValidator(store)]).getErrors(path);
  return errors === undefined || errors.length === 0;
};

export const getCurrentConceptDefinition = (store: FrontObjectStore, location: Location): StoreObject | undefined => {
  const conceptDefinitionIdMatch = location.pathname.match(/^(\/concept\/)([^/]+)/);
  const conceptDefinitionId = conceptDefinitionIdMatch && conceptDefinitionIdMatch.length > 2 ? conceptDefinitionIdMatch[2] : undefined;
  if (conceptDefinitionId) {
    const conceptDefinition = store.getObjectOrNull(conceptDefinitionId);
    if (conceptDefinition && isInstanceOf<ConceptDefinitionStoreObject>(conceptDefinition, ConceptDefinition)) {
      return conceptDefinition;
    }
  }
  return undefined;
};

export const getCurrentConceptInstance = (store: FrontObjectStore, location: Location): StoreObject | undefined => {
  const conceptIdMatch = location.pathname.match(/^(\/concept\/)([^/]+)\/([^/]+)/);
  const conceptId = conceptIdMatch && conceptIdMatch.length > 3 ? conceptIdMatch[3] : undefined;
  if (conceptId) {
    const concept = store.getObjectOrNull(conceptId);
    if (concept && isInstanceOf<ConceptStoreObject>(concept, Concept)) {
      return concept;
    }
  }
  return undefined;
};

export const getCurrentNavigationId = (store: FrontObjectStore, path: string, loggedUserId: string): string | undefined => {
  const lastPathMatch = path.match(/.*\/[^/]+\/(([\w-]|(?:%7C))*)/);
  if (lastPathMatch) {
    return decodeURI(lastPathMatch[1]);
  } else {
    return getHomepageConcept(store, loggedUserId)?.id ?? 'organization';
  }
};

const isNavigationElement = (element: NavigationElement | { key: string }): element is NavigationElement => Boolean((element as NavigationElement)?.element);

export const getNavigationElement = (store: FrontObjectStore, element: { key: string, path?: string }): WithOptionalField<NavigationElement, 'path'> => {
  switch (element.key) {
    case 'notification_settings':
      return { key: 'notification_settings', name: i18n`Notification`, element: ImportedNavigationTitlePathElements.notification_settings, path: '/settings/notification' };
    case 'automation':
      return { key: 'automation', name: i18n`Automation`, element: ImportedNavigationTitlePathElements.automation, path: '/settings/automation' };
    case 'settings_admin':
      return { key: 'settings_admin', name: i18n`Settings`, element: ImportedNavigationTitlePathElements.configuration, path: '/settings' };
    case 'organization_settings':
      return {
        key: 'organization_settings',
        name: i18n`Information model configuration`,
        element: ImportedNavigationTitlePathElements.organization,
        path: '/settings/organization',
      };
    case 'organization':
      return { key: 'organization', name: i18n`Information model`, element: ImportedNavigationTitlePathElements.organization, path: '/organization' };
    case 'integration':
      return { key: 'integration', name: i18n`Integration`, element: ImportedNavigationTitlePathElements.integration, path: '/settings/integration' };
    case 'workflow':
      return { key: 'workflow', name: i18n`Workflow`, element: ImportedNavigationTitlePathElements.workflow, path: '/settings/workflow' };
    case 'explorer':
      return { key: 'explorer', name: i18n`Explorer`, element: ImportedNavigationTitlePathElements.explorer, path: '/settings/explorer' };
    case 'events':
      return { key: 'events', name: i18n`Events`, element: ImportedNavigationTitlePathElements.explorer, path: '/settings/explorer', hash: '#events' };
    case 'about':
      return { key: 'about', name: i18n`About`, element: ImportedNavigationTitlePathElements.configuration, path: '/settings/about' };
    case 'collaboration':
      return { key: 'collaboration', name: i18n`Collaboration configuration`, element: ImportedNavigationTitlePathElements.collaboration, path: '/settings/collaboration' };
    case 'formula':
      return { key: 'formula', name: i18n`Formula documentation`, element: ImportedNavigationTitlePathElements.formulaDocumentation, path: '/settings/formula-documentation' };
    case 'customization':
      return { key: 'customization', name: i18n`Customization`, element: ImportedNavigationTitlePathElements.platformConfiguration, path: '/settings/customization' };
    case 'security':
      return { key: 'security', name: i18n`Security`, element: ImportedNavigationTitlePathElements.security, path: '/settings/security' };
    case 'authorization':
      return { key: 'authorization', name: i18n`Authorization`, element: ImportedNavigationTitlePathElements.authorization, path: '/settings/authorization' };
    case 'user':
      return { key: 'user', name: i18n`Users, groups & platform capabilities`, element: ImportedNavigationTitlePathElements.group, path: '/settings/user' };
    default: {
      let instance = store.getObjectOrNull(element.key);
      if (instance === null) {
        if (isNavigationElement(element)) {
          return element;
        } else {
          return {
            key: element.key,
            name: element.key,
            path: element.path,
            element: ImportedNavigationTitlePathElements.organizationInstance,
          };
        }
      } else if (isInstanceOf<ConceptDefinitionStoreObject>(instance, ConceptDefinition)) {
        return {
          key: instance.key,
          name: getConceptDefinitionNameOrEntity(store, instance.key),
          element: ImportedNavigationTitlePathElements.organizationInstance,
          icon: instance[ConceptDefinition_Icon] as IconName | undefined,
          path: element.path ?? getConceptDefinitionUrl(instance.key),
        };
      } else if (isInstanceOf<AutomationRuleStoreObject>(instance, AutomationRule)) {
        return {
          key: instance.key,
          name: getInstanceLabelOrUndefined(store, instance),
          element: instance[AutomationRule_UserId] !== undefined ? ImportedNavigationTitlePathElements.notification_settings : ImportedNavigationTitlePathElements.automation,
          path: element.path ?? getConceptUrl(store, instance.key),
        };
      } else if (isInstanceOf<IntegrationStoreObject>(instance, Integration)) {
        return {
          key: instance.key,
          name: getInstanceLabelOrUndefined(store, instance),
          element: ImportedNavigationTitlePathElements.integration,
          path: element.path ?? getConceptUrl(store, instance.key),
        };
      } else if (isInstanceOf<WorkflowStoreObject>(instance, Workflow)) {
        return {
          key: instance.key,
          name: getInstanceLabelOrUndefined(store, instance),
          element: ImportedNavigationTitlePathElements.workflow,
          path: element.path ?? getConceptUrl(store, instance.key),
        };
      } else if (isInstanceOf<FieldStoreObject>(instance, Field)) {
        const icon = getFieldConfigurationHandler(store, instance.id)?.getIcon();

        return {
          key: instance.key,
          name: getFieldLabel(store, instance),
          element: ImportedNavigationTitlePathElements.organizationInstance,
          path: element.path ?? getConceptUrl(store, instance.key),
          icon: typeof icon === 'object' ? icon.name : icon,
        };
      } else if (isInstanceOf(instance, Intention)) {
        return {
          key: instance.key,
          name: instance[Intention_Name] as string,
          element: ImportedNavigationTitlePathElements.collaboration,
          path: element.path ?? getConceptUrl(store, instance.key),
        };
      }

      if (isInstanceOf<DashboardParameterStoreObject>(instance, DashboardParameter)) {
        instance = instance.navigate(DashboardParameter_Dashboard);
      }

      let icon: IconName | undefined;
      const conceptDefinition = instance.navigateOrNull<ConceptDefinitionStoreObject>(Instance_Of);
      if (conceptDefinition !== null) {
        icon = conceptDefinition[ConceptDefinition_Icon] as IconName | undefined;
        const mainIconField = conceptDefinition.navigateOrNull(ConceptDefinition_MainIconField);
        if (mainIconField !== null) {
          const dimensionId = getFieldDimensionOfModelType(store, mainIconField.id, conceptDefinition.id);
          if (dimensionId !== undefined) {
            const instanceIcon = iconFieldHandler(store, mainIconField.id).getValueResolution({ [dimensionId]: instance.id }).value?.icon as IconName | undefined;
            if (instanceIcon !== undefined) {
              icon = instanceIcon;
            }
          }
        }
      }

      return {
        key: instance.key,
        name: getInstanceLabelOrUndefined(store, instance),
        element: ImportedNavigationTitlePathElements.organizationInstance,
        icon,
        path: element.path ?? getConceptUrl(store, instance.key),
      };
    }
  }
};

const addNavigationElement = (
  store: FrontObjectStore,
  navigationElements: NavigationElementsOrSingleKey,
  el: { key: string } | NavigationElement
): NavigationElementsOrSingleKey => {
  if (!el.key) {
    return navigationElements;
  }
  const instance = store.getObjectOrNull(el.key);

  let elements = [...navigationElements];
  // Coming back to a known instance
  const currentElementIndex = elements.findIndex(({ key }) => key === el.key);
  if (currentElementIndex > -1) {
    elements.splice(currentElementIndex, 99, joinObjects(elements[currentElementIndex], el));
    return elements;
  }

  if (instance) {
    const parentInstance = getParentConceptInstance(instance);
    // Replacing same concept instance and if both Field but not if both are conceptDef
    const existingSameConcept = elements.findIndex(({ key }) => (
      instance[Instance_Of] && store.getObjectOrNull(key)?.[Instance_Of] === instance[Instance_Of] && instance[Instance_Of] !== ConceptDefinition
    ) || (
      instance.navigateOrNull(Instance_Of)
      && store.getObjectOrNull(key)?.navigateOrNull(Instance_Of)?.[Instance_Of] === instance.navigate(Instance_Of)[Instance_Of]
      && instance.navigate(Instance_Of)[Instance_Of] === FieldDefinition
    ));

    if (existingSameConcept > -1 && !isInstanceOf<DashboardStoreObject>(instance, Dashboard)) {
      elements.splice(existingSameConcept, 99, el);
      if (parentInstance && elements.length > existingSameConcept && elements[existingSameConcept - 1]?.key !== parentInstance.id) {
        elements.splice(elements.length - 1, 0, getNavigationElement(store, { key: parentInstance.id }));
      }
      return elements;
    }

    // Checking parent for embedding instances
    if (!isInstanceOf<ConceptDefinitionStoreObject>(instance, ConceptDefinition) && parentInstance && elements[elements.length - 1]?.key !== parentInstance.id) {
      elements.push(getNavigationElement(store, { key: parentInstance.id }));
    }

    if (isInstanceOf<WidgetStoreObject>(instance, Widget) && !parentInstance && existingSameConcept === -1) {
      if (elements.length === 0) {
        const dashboard = instance.navigateOrNull<DashboardStoreObject>(Widget_Dashboard);
        if (dashboard !== null) {
          elements.push(getNavigationElement(store, { key: dashboard.id }));
        }
      }
      const newEl = joinObjects(el, { path: `/widget/${el.key}` });
      elements.push(newEl);
      return elements;
    }
  } else {
    // Handling a forced element, get out of the drill down
    elements = elements.filter(({ key }) => !store.getObjectOrNull(key));
    // We are not pushing a hardcoded element like organization but referring to an ID that no longer exists : Don't push it.
    if (validateUuid(el.key)) {
      return elements;
    }
  }

  // Adding element
  elements.push(el);
  return elements;
};

export const getNavigationElements = (objectStore: FrontObjectStore, location: NavigationLocation): NavigationElementsOrSingleKey => {
  const navState = location.state?.navigationState ?? [];
  let navElements: NavigationElementsOrSingleKey = [];
  navState.filter((el) => !!el.key).forEach((el) => {
    navElements = addNavigationElement(objectStore, navElements, joinObjects(el, { key: Array.isArray(el.key) ? el.key[0].toLowerCase() : el.key.toLowerCase() }));
  });
  return navElements;
};

export const getNavigationPayload = (
  navigation: UseNavigation,
  id: string,
  url: string,
  navigationFilters?: NavigationFilter | undefined
): NavigationPayload => navigation.createNavigationPayload(id, { pathname: url, navigationFilters });

export type NavigationFilter = {
  navigationFilters?: Filters,
  navigationPath?: PathStep[],
  filterDimensionId?: string,
} & GlobalFilter;
