import type { ReactNode } from 'react';
import type { SingleParameterDefinition, PathStep, MultipleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { WorkflowEntry, WorkflowEntry_Rank, WorkflowEntry_Role_Concept, WorkflowEntry_Role_Workflow } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { compareProperty, compareRank, filterNullOrUndefined } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Banner, { BannerVariant } from '../../../../components/molecules/Banner';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import BlockContent from '../../../../components/templates/BlockContent';
import BlockTitle from '../../../../components/templates/BlockTitle';
import HorizontalBlock, { HorizontalBlockVariant } from '../../../../components/templates/HorizontalBlock';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import StoreInput from '../../input/StoreInput';
import type { OptionRecord } from '../../modelTypeUtils';
import { getChipOptions } from '../../modelTypeUtils';
import PathInput from '../../path/PathInput';
import type { PathConfigurationHandler } from '../../pathConfigurationHandler';

const useStyles = makeStyles((theme) => ({
  operationContainer: {
    marginLeft: spacingRem.xl,
    display: 'flex',
    flexFlow: 'row wrap',
    borderLeftWidth: '0.2rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
    alignItems: 'center',
  },
  bannerContainer: {
    marginLeft: spacingRem.s,
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
  },
}), 'workflowFieldUpdateOperationInput');

interface WorkflowFieldUpdateOperationInputProps {
  workflowId: string,
  targetTypeId: string,
  initialValue: { type: 'value', value: string | undefined } | { type: 'path', path: PathStep[] },
  onTypeChange: (newType: 'value' | 'path') => void,
  onValueSubmit: (newValue: { type: 'value', value: string | undefined } | { type: 'path', path: PathStep[] }) => void,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
  valuePathHandler?: PathConfigurationHandler,
}

const WorkflowFieldUpdateOperationInput = ({
  workflowId,
  targetTypeId,
  initialValue,
  onTypeChange,
  onValueSubmit,
  parameterDefinitions,
  valuePathHandler,
}: WorkflowFieldUpdateOperationInputProps): ReactNode => {
  const classes = useStyles();

  const store = useStore();

  const options: OptionRecord<'value' | 'path'> = {
    value: { id: 'value', label: i18n`With a value` },
    path: { id: 'path', label: i18n`With a path` },
  };

  const workflowConceptChips = store.withAssociation(WorkflowEntry)
    .withRole(WorkflowEntry_Role_Workflow, workflowId)
    .list()
    .filter((workflowEntry) => isInstanceOf(workflowEntry.navigateRole(WorkflowEntry_Role_Concept), targetTypeId))
    .sort(compareProperty('object', compareProperty(WorkflowEntry_Rank, compareRank)))
    .map((workflowEntry) => getChipOptions(store, workflowEntry.role(WorkflowEntry_Role_Concept)))
    .filter(filterNullOrUndefined);
  const workflowConceptChipsIds = new Set(workflowConceptChips.map(({ id }) => id));

  return (
    <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
      <BlockTitle
        unPadded
        title={(
          <SearchAndSelect
            selectedOption={options[initialValue.type ?? 'value']}
            computeOptions={() => Object.values(options)}
            onSelect={(value) => onTypeChange(value?.id ?? 'value')}
            hideCaret
          />
        )}
      />
      <BlockContent fullWidth>
        <div className={classes.operationContainer}>
          {initialValue.type !== 'path' && (
            <>
              <StoreInput
                initialValue={initialValue.value}
                apply={(__, update) => update ?? undefined}
                onSubmit={(value) => onValueSubmit({ type: 'value', value: value ?? undefined })}
              >
                {({ value: formValue, onSubmit: formOnSubmit }) => (
                  <SearchAndSelect
                    computeOptions={() => workflowConceptChips}
                    selectedOption={formValue ? getChipOptions(store, formValue) : undefined}
                    onSelect={(option) => formOnSubmit(option?.id ?? undefined)}
                    placeholder={i18n`Select element`}
                    // Selected instance does not belong to the workflow
                    statusIcon={formValue && !workflowConceptChipsIds.has(formValue) ? {
                      icon: IconName.dangerous,
                      color: IconColorVariant.error,
                      message: i18n`Selected instance does not belong to the workflow`,
                    } : undefined}
                  />
                )}
              </StoreInput>
              <div className={classes.bannerContainer}>
                <Banner variant={BannerVariant.warning} title={i18n`This value will be applied regardless any existing transition conditions.`} fullWidth />
              </div>
            </>
          )}
          {initialValue.type === 'path' && (
            <>
              <PathInput
                path={initialValue.path ?? []}
                onChange={(path) => onValueSubmit({ type: 'path', path })}
                parameterDefinitions={parameterDefinitions}
                valuePathHandler={valuePathHandler}
                placeholder={i18n`Add path`}
              />
              <div className={classes.bannerContainer}>
                <Banner
                  variant={BannerVariant.warning}
                  title={i18n`This value will be applied regardless any existing transition conditions.\nPath should target a valid workflow value otherwise the action will be rejected.`}
                  fullWidth
                />
              </div>
            </>
          )}

        </div>
      </BlockContent>
    </HorizontalBlock>
  );
};

export default WorkflowFieldUpdateOperationInput;
