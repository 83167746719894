import classnames from 'classnames';
import type { CSSProperties, FunctionComponent } from 'react';
import { useState } from 'react';
import { joinObjects } from 'yooi-utils';
import base from '../../theme/base';
import { darken, getMostReadableColorFromBackgroundColor, isLight, lighten } from '../../theme/colorUtils';
import { spacingRem } from '../../theme/spacingDefinition';
import type { Theme } from '../../theme/themeUtils';
import makeStyles from '../../utils/makeStyles';
import { formatOrUndef } from '../../utils/stringUtils';
import useSizeContext, { buildComponentSizeVariantClasses, SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import useTooltipRef from '../../utils/useTooltipRef';
import type { IconName } from '../atoms/Icon';
import Icon, { IconColorVariant } from '../atoms/Icon';
import Typo, { TypoVariant } from '../atoms/Typo';
import ColorChip from './ColorChip';

const useStyles = makeStyles((theme) => (joinObjects(
  {
    root: {
      backgroundColor: theme.color.background.neutral.subtle,
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: theme.color.border.default,
      '&:hover': {
        backgroundColor: theme.color.background.neutral.muted,
        borderColor: theme.color.border.default,
      },
      '&:focus': {
        backgroundColor: theme.color.background.neutral.muted,
        borderColor: theme.color.border.default,
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      outline: 0,
      cursor: 'pointer',
      userSelect: 'none',
      borderRadius: base.borderRadius.medium,
      paddingLeft: spacingRem.s,
      paddingRight: spacingRem.s,
      columnGap: spacingRem.s,
    },
  },
  buildComponentSizeVariantClasses('toggle', ['height']),
  {
    active: {
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: theme.color.border.primary,
      '&:hover': {
        borderColor: theme.color.border.primary,
      },
      '&:focus': {
        borderColor: theme.color.border.primary,
      },
    },
    lastTag: {
      marginRight: spacingRem.none,
    },
  }
)), 'toggle');

const getStyleFromBackgroundColor = (theme: Theme, chipBackgroundColor: string, isHovered: boolean, isActive: boolean): CSSProperties => {
  let backgroundColor = chipBackgroundColor;
  const color = getMostReadableColorFromBackgroundColor(chipBackgroundColor);
  if (isHovered) {
    if (isLight(chipBackgroundColor)) {
      backgroundColor = darken(chipBackgroundColor, 5);
    } else {
      backgroundColor = lighten(chipBackgroundColor, 5);
    }
  }
  return {
    backgroundColor,
    borderColor: isActive ? theme.color.border.primary : backgroundColor,
    color,
  };
};

interface ToggleProps {
  text: string,
  color?: string,
  isActive?: boolean,
  onClick: () => void,
  icon?: IconName,
  iconColor?: IconColorVariant,
  backgroundColor?: string,
}

const Toggle: FunctionComponent<ToggleProps> = ({ text, color, isActive = false, onClick, icon, iconColor, backgroundColor }) => {
  const theme = useTheme();
  const classes = useStyles();

  const { sizeVariant: contextSizeVariant, hierarchyVariant } = useSizeContext();
  const sizeContextComputed = contextSizeVariant ?? SizeVariant.main;
  const [isHovered, setIsHovered] = useState(false);

  const tooltipRef = useTooltipRef(formatOrUndef(text), true);

  return (
    <div
      ref={tooltipRef}
      style={backgroundColor ? getStyleFromBackgroundColor(theme, backgroundColor, isHovered, isActive) : undefined}
      className={classnames({
        [classes.root]: true,
        [classes.active]: isActive,
        [classes[`toggle_${sizeContextComputed}_${hierarchyVariant}`]]: true,
      })}
      onClick={(event) => {
        onClick();
        event.stopPropagation();
        event.preventDefault();
      }}
      onMouseOver={() => setIsHovered(true)}
      onFocus={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      onBlur={() => setIsHovered(false)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onClick();
        }
      }}
      tabIndex={0}
      role="link"
    >
      {icon ? (<Icon name={icon} colorVariant={iconColor ?? IconColorVariant.alternative} />) : null}
      <ColorChip color={color} />
      <Typo
        maxLine={1}
        variant={sizeContextComputed === SizeVariant.small ? TypoVariant.buttonSmall : TypoVariant.buttonMain}
      >
        {formatOrUndef(text)}
      </Typo>
    </div>
  );
};

export default Toggle;
