import type { FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { canWrite, dimensionsMappingToParametersMapping, getTimeseriesFieldUtilsHandler } from 'yooi-modules/modules/conceptModule';
import { DimensionDisplayAxis } from 'yooi-modules/modules/dashboardModule';
import { joinObjects } from 'yooi-utils';
import { ButtonVariant } from '../../../../components/atoms/Button';
import { IconName } from '../../../../components/atoms/Icon';
import Typo from '../../../../components/atoms/Typo';
import type { DateInputValue } from '../../../../components/inputs/datePickers/DateInput';
import DateInput from '../../../../components/inputs/datePickers/DateInput';
import SimpleInput from '../../../../components/inputs/strategy/SimpleInput';
import TextInputString from '../../../../components/inputs/TextInputString';
import ConfirmationModal, { ConfirmationModalVariant } from '../../../../components/molecules/ConfirmationModal';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import TableCell from '../../../../components/molecules/TableCell';
import TableLine from '../../../../components/molecules/TableLine';
import type { ItemEntry } from '../../../../components/templates/DataTable';
import DataTable from '../../../../components/templates/DataTable';
import { GroupVariant } from '../../../../components/templates/internal/DataTableHeaderRenderer';
import type { Pagination } from '../../../../components/templates/PageSelector';
import useAcl from '../../../../store/useAcl';
import useStore from '../../../../store/useStore';
import { buildMargins, Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { remToPx } from '../../../../utils/sizeUtils';
import { formatOrUndef } from '../../../../utils/stringUtils';
import useDerivedState from '../../../../utils/useDerivedState';
import useNewLineFocus, { useFocusNewLineNotify } from '../../../../utils/useNewLineFocus';
import { SizeContextProvider, SizeVariant } from '../../../../utils/useSizeContext';
import useTheme from '../../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../../utils/useUsageContext';
import { getFieldHandler } from '../../fields/FieldLibrary';
import { getChipOptions } from '../../modelTypeUtils';
import useFilterAndSort from '../../useFilterAndSort';
import type { TimeseriesTableViewResolvedDefinition } from './timeseriesTableViewHandler';
import type { TimeseriesTableLine, TimeseriesTableViewResolution } from './timeseriesTableViewResolution';
import { isColumnTimeseriesTableFieldColumn } from './timeseriesTableViewResolution';

const useStyles = makeStyles({
  newLineHeaderContainer: {
    ...buildMargins({ x: Spacing.xs, y: Spacing.xxs }),
  },
}, 'timeseriesTableView');

interface TimeseriesTableViewProps {
  viewDefinition: TimeseriesTableViewResolvedDefinition,
  viewResolution: TimeseriesTableViewResolution,
  parametersMapping: ParametersMapping,
  filterKey: string,
  height?: number,
  addTimePoint: (time: number) => void,
  removeTimePoint: (time: number) => void,
  removeAllTimePoints: () => void,
  readOnly?: boolean,
}

const TimeseriesTableView: FunctionComponent<TimeseriesTableViewProps> = ({
  viewDefinition,
  viewResolution,
  parametersMapping: viewDimensionMapping,
  filterKey,
  height,
  addTimePoint,
  removeTimePoint,
  removeAllTimePoints,
  readOnly = false,
}) => {
  const store = useStore();
  const theme = useTheme();
  const classes = useStyles();
  const { canWriteObject } = useAcl();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [updatedTime, setUpdatedTime] = useDerivedState<number[]>(() => [], [filterKey, viewDimensionMapping]);
  const [inlineCreation, setInlineCreation] = useDerivedState<boolean>(() => false, [filterKey, viewDimensionMapping]);

  const [newTimeFocus, setNewTimeFocus] = useNewLineFocus();
  const focusNewTimeNotify = useFocusNewLineNotify();

  const [deleteModalCallback, setDeleteModalCallback] = useState<() => void | undefined>();

  let itemPerPage: number | undefined;
  const withHeaderMargin = !viewResolution.withoutHeaderLine;
  if (viewDefinition.numberOfItems !== undefined) {
    itemPerPage = viewDefinition.numberOfItems;
  } else if (height) {
    const LINE_HEIGHT = 3.8/* line height */ + 0.1/* border */;
    const SCROLL_HEIGHT = 0.8;
    const HEADER_MARGIN = withHeaderMargin ? 0.8 : 0;
    const PAGINATION_HEIGHT = 3.2/* line height */ + 0.4/* row gap */;
    const HEADER_LINE_NUMBER = (viewResolution.columns.find(isColumnTimeseriesTableFieldColumn)?.groups?.length ?? 0) + (viewResolution.withoutHeaderLine ? 0 : 1);
    itemPerPage = Math.floor((height - remToPx((HEADER_LINE_NUMBER * LINE_HEIGHT) + PAGINATION_HEIGHT + 2 * SCROLL_HEIGHT + HEADER_MARGIN)) / remToPx(LINE_HEIGHT));
    if (itemPerPage < 2) {
      itemPerPage = 2;
    }
  }

  const { generatePageList, forceShowId, generateList } = useFilterAndSort(
    filterKey,
    viewResolution.lines,
    (line) => viewResolution.filterFunction(dimensionsMappingToParametersMapping(line.lineDimensionMapping)),
    undefined,
    undefined,
    [filterKey, viewDimensionMapping, viewResolution]
  );

  let list: ItemEntry<TimeseriesTableLine>[];
  let pagination: Pagination | undefined;
  if (itemPerPage) {
    const pageList = generatePageList(itemPerPage);
    list = pageList.list;
    pagination = joinObjects(
      pageList.pagination,
      {
        onNext: () => {
          pageList.pagination.onNext();
          removeAllTimePoints();
        },
        onPrevious: () => {
          pageList.pagination.onPrevious();
          removeAllTimePoints();
        },
        onPage: ((...args) => {
          pageList.pagination.onPage(...args);
          removeAllTimePoints();
        }) satisfies Pagination['onPage'],
      } as const
    );
  } else {
    list = generateList().list;
  }

  return (
    <>
      <DataTable
        loading={viewResolution.loading}
        list={list}
        columnsDefinition={
          [
            ...viewResolution.columns.map((column) => {
              if (column.type === 'time') {
                return ({
                  key: column.key,
                  name: column.label,
                  propertyId: column.key,
                  hideHeader: true,
                  cellRender: (line: TimeseriesTableLine) => (
                    <DateInput
                      value={{ period: viewDefinition.granularity.periodicity, date: column.getTime(line) }}
                      onCancel={() => {}}
                      onChange={() => {}}
                      onSubmit={() => {}}
                      readOnly
                    />
                  ),
                });
              } else if (column.type === 'series') {
                return ({
                  key: column.key,
                  name: column.label,
                  propertyId: column.key,
                  hideHeader: true,
                  cellRender: (line: TimeseriesTableLine) => (
                    <TextInputString
                      value={column.getSeriesLabel(line)}
                      readOnly
                    />
                  ),
                });
              } else if (column.type === 'instance') {
                return ({
                  key: column.key,
                  name: column.label,
                  propertyId: column.key,
                  hideHeader: true,
                  cellRender: (line: TimeseriesTableLine) => {
                    if (!column.asChip) {
                      const label = column.getInstanceLabel(line);
                      return (
                        <TextInputString
                          value={label}
                          readOnly
                        />
                      );
                    } else {
                      const instanceId = column.getInstanceId(line);
                      return (
                        <SearchAndSelect
                          selectedOption={instanceId ? getChipOptions(store, instanceId) : undefined}
                          readOnly
                        />
                      );
                    }
                  },
                });
              } else {
                const { key, label, width, groups, getDimensionsMapping, fieldId: columnFieldId, time: columnTime } = column;
                return ({
                  key,
                  name: label,
                  propertyId: key,
                  width,
                  groups: groups?.map((group) => ({
                    id: group.id,
                    label: group.label,
                    tooltip: group.tooltip,
                    variant: group.isTime ? GroupVariant.group : GroupVariant.header,
                  })),
                  cellRender: (line: TimeseriesTableLine) => {
                    const dimensionsMapping = getDimensionsMapping(line);
                    const fieldId = line.fieldId ?? columnFieldId;
                    if (!fieldId || store.getObjectOrNull(fieldId) === null || !dimensionsMapping) {
                      return null;
                    }
                    const handler = getFieldHandler(store, fieldId);
                    const { updateValue } = getTimeseriesFieldUtilsHandler(store, fieldId);
                    const time = (line.time ?? columnTime);
                    return handler?.renderField?.({
                      dimensionsMapping,
                      readOnly,
                      time,
                      onSubmit: (value) => {
                        updateValue(dimensionsMapping, value);
                        if (time) {
                          setUpdatedTime((current) => (current.includes(time) ? current : [...current, time]));
                        }
                      },
                    }) ?? null;
                  },
                });
              }
            }),
            ...inlineCreation && viewDefinition.timeAxis.axis === DimensionDisplayAxis.x ? viewResolution.getNewTimeInfo(undefined).map(({ id, label, groups }) => ({
              key: id,
              name: label,
              propertyId: id,
              scrollOnMount: true,
              groups: groups?.map(({ id: groupId, label: groupLabel, isTime }) => ({
                id: groupId,
                label: formatOrUndef(groupLabel),
                tooltip: formatOrUndef(groupLabel),
                variant: isTime ? GroupVariant.group : GroupVariant.header,
                render: isTime ? () => (
                  <UsageContextProvider usageVariant={UsageVariant.inline}>
                    <SizeContextProvider sizeVariant={SizeVariant.small}>
                      <div className={classes.newLineHeaderContainer}>
                        <SimpleInput
                          initialValue={undefined as DateInputValue}
                          onSubmit={(newDate) => {
                            if (newDate?.date) {
                              addTimePoint(newDate.date);
                              setInlineCreation(false);
                            }
                          }}
                        >
                          {({ ...props }) => (inlineCreation ? (
                            <DateInput
                              {...props}
                              onCancel={() => {
                                props.onCancel();
                                setInlineCreation(false);
                              }}
                              placeholder={i18n`Select a date`}
                              defaultPeriodicity={viewDefinition.granularity.periodicity}
                              fixedPeriodicity={viewDefinition.granularity.strict}
                              withoutClear
                              focusOnMount
                              offset={[0, 32]}
                            />
                          ) : null)}
                        </SimpleInput>
                      </div>
                    </SizeContextProvider>
                  </UsageContextProvider>
                ) : undefined,
              })),
              cellRender: () => null,
            })) : [],
          ]
        }
        linesActions={(line) => [{
          key: 'delete',
          name: i18n`Delete value(s)`,
          danger: true,
          hidden: readOnly
            || line.time === undefined
            || !viewResolution.timePointsWithValue.has(line.time)
            || viewResolution.isComputedOrIntegrationOnly
            || viewResolution.columns.filter(isColumnTimeseriesTableFieldColumn).every(({ getDimensionsMapping }) => !canWrite(getDimensionsMapping(line), canWriteObject)),
          icon: IconName.delete,
          onClick: () => {
            setDeleteModalCallback(() => () => {
              if (line.time) {
                removeTimePoint(line.time);
                viewResolution.columns.filter(isColumnTimeseriesTableFieldColumn)
                  .forEach(({ getDimensionsMapping, isComputedOrIntegrationOnly: columnIsComputedOrIntegrationOnly, fieldId: columnFieldId }) => {
                    const fieldId = line.fieldId ?? columnFieldId;
                    const isComputed = line.isComputedOrIntegrationOnly || columnIsComputedOrIntegrationOnly;
                    const dimensionsMapping = getDimensionsMapping(line);
                    if (fieldId && line.time && !isComputed && dimensionsMapping && canWrite(dimensionsMapping, canWriteObject)) {
                      const { updateValue } = getTimeseriesFieldUtilsHandler(store, fieldId);
                      updateValue(dimensionsMapping, { type: 'delete', time: line.time });
                    }
                  });
              }
            });
          },
        }]}
        withoutHeaderLine={viewResolution.withoutHeaderLine}
        centerHeader
        separateHeaderFromBody={withHeaderMargin}
        pagination={pagination}
        newLineFocus={viewDefinition.timeAxis.axis === DimensionDisplayAxis.y ? newTimeFocus.current : undefined}
        newColumnFocus={viewDefinition.timeAxis.axis === DimensionDisplayAxis.x ? newTimeFocus.current : undefined}
        newItemIcon={IconName.add}
        newItemButtonVariant={ButtonVariant.tertiary}
        newItemTitle={i18n`Add`}
        onNewItem={!readOnly && !viewResolution.isComputedOrIntegrationOnly ? () => {
          setInlineCreation(true);
          if (viewDefinition.timeAxis.axis === DimensionDisplayAxis.x) {
            const columnKey = viewResolution.getNewTimeInfo(undefined).at(-1)?.id;
            if (columnKey) {
              setNewTimeFocus(columnKey);
              focusNewTimeNotify(columnKey);
            }
          }
        } : undefined}
        newItemDisabled={inlineCreation}
        newItemRef={buttonRef}
        inlineCreation={inlineCreation && viewDefinition.timeAxis.axis === DimensionDisplayAxis.y ? {
          highlight: (propertyId) => (propertyId !== 'actions' ? {
            color: theme.color.text.warning,
            icon: IconName.warning,
            message: i18n`You need to select a date before adding values`,
          } : undefined),
          render: (
            <TableLine>
              {viewResolution.columns.map(({ type, key }, index, self) => {
                const isLastColumn = index === self.length - 1;
                const isFirstColumn = index === 0;
                return (
                  <TableCell
                    key={key}
                    borderColor={{
                      bottom: theme.color.text.warning,
                      top: theme.color.text.warning,
                      right: isLastColumn ? theme.color.text.warning : undefined,
                      left: isFirstColumn ? theme.color.text.warning : undefined,
                    }}
                    message={isLastColumn ? {
                      color: theme.color.text.warning,
                      icon: IconName.warning,
                      message: i18n`You need to select a date before adding values`,
                    } : undefined}
                  >
                    {type === 'time' && (
                      <SimpleInput
                        initialValue={undefined as DateInputValue}
                        onSubmit={(newTime) => {
                          if (newTime?.date) {
                            viewResolution.getNewTimeInfo(newTime.date).forEach(({ id: lineId }, newTimeIndex, newTimes) => {
                              if (newTimeIndex === newTimes.length - 1) {
                                setNewTimeFocus(lineId);
                                focusNewTimeNotify(lineId);
                              }
                              forceShowId(lineId);
                            });
                            addTimePoint(newTime.date);
                            setUpdatedTime((current) => current.filter((t) => t !== newTime.date));
                          }
                          setInlineCreation(false);
                        }}
                      >
                        {(props) => (
                          <DateInput
                            {...props}
                            onCancel={() => {
                              props.onCancel();
                              setInlineCreation(false);
                            }}
                            defaultPeriodicity={viewDefinition.granularity.periodicity}
                            fixedPeriodicity
                            withoutClear
                            focusOnMount
                          />
                        )}
                      </SimpleInput>
                    )}
                  </TableCell>
                );
              })}
              <TableCell />
            </TableLine>
          ),
        } : undefined}
        fullWidth
        getHighlight={(line, propertyId) => {
          const column = viewResolution.columns.find((c) => c.key === propertyId);
          if (column?.type === 'field') {
            const time = line.time ?? column.time;
            if (propertyId !== 'actions' && propertyId !== 'time' && propertyId !== 'instance') {
              if (!time) {
                return {
                  color: theme.color.text.warning,
                  icon: IconName.warning,
                  message: i18n`You need to select a date before adding values`,
                };
              } else if (viewResolution.addedTimePoints.includes(time)) {
                if (!viewResolution.timePointsWithValue.has(time)) {
                  return {
                    color: theme.color.text.warning,
                    icon: IconName.warning,
                    message: i18n`Add at least one valid value to save this entry`,
                  };
                } else if (viewResolution.requestedTemporalRange.from > time || viewResolution.requestedTemporalRange.to < time) {
                  return {
                    color: theme.color.text.info,
                    icon: IconName.info,
                    message: i18n`This entry date is outside the time range defined for this table and will be hidden on refresh`,
                  };
                } else if (!updatedTime.includes(time)) {
                  return {
                    color: theme.color.text.info,
                    icon: IconName.info,
                    message: i18n`This entry already has value(s)`,
                  };
                }
              }
            }
          }
          return undefined;
        }}
      />
      {deleteModalCallback !== undefined ? (
        <ConfirmationModal
          variant={ConfirmationModalVariant.delete}
          open
          onConfirm={() => {
            deleteModalCallback();
            setDeleteModalCallback(undefined);
          }}
          title={i18n`Are you sure you want to delete those values?`}
          confirmLabel={i18n`Delete`}
          render={() => (<Typo>{i18n`Confirming this action will delete the value for the given time for each displayed timeseries that are not computed.`}</Typo>)}
          onCancel={() => setDeleteModalCallback(undefined)}
        />
      ) : null}
    </>
  );
};

export default TimeseriesTableView;
