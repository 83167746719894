import type { ComponentProps, FunctionComponent } from 'react';
import TextInputString from '../../../components/inputs/TextInputString';
import StoreInput from './StoreInput';

interface StoreTextInputFieldProps extends Omit<ComponentProps<typeof TextInputString>, 'value' | 'onChange' | 'onSubmit' | 'onCancel'> {
  initialValue: string | undefined,
  onSubmit?: (value: string | null) => void,
}

const StoreTextInputField: FunctionComponent<StoreTextInputFieldProps> = ({ initialValue, onSubmit, ...textInputStringProps }) => (
  <StoreInput initialValue={initialValue} onSubmit={onSubmit}>
    {(formInputProps) => (
      <TextInputString
        {...textInputStringProps}
        {...formInputProps}
      />
    )}
  </StoreInput>
);

export default StoreTextInputField;
