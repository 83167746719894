import { newError } from '../../errorUtils';
import { newFunctionLibrary } from '../engine/functionLibraryBuilder';
import { booleanType, numberType } from '../typeSystem';
import { checkArgumentCount, checkArgumentType, checkFlattenType, checks, flattenForEach } from './utils';

export const mathFunctions = newFunctionLibrary()
  .addFunction(
    'ABS',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          transpile: ([n]) => `Math.abs(${n})`,
          jsFunction: (n: number) => Math.abs(n),
        }),
    })
  )
  .addFunction(
    'CEILING',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (n: number, significance: number | undefined) => {
            // eslint-disable-next-line yooi/number-is-nan-call
            if (significance === undefined || Number.isNaN(significance)) {
              return Number.NaN;
            } else if (significance === 0) {
              return 0;
            } else if ((n / significance) % 1 === 0) {
              return n;
            }

            const absSignificance = Math.abs(significance);
            const times = Math.floor(Math.abs(n) / absSignificance);
            if (n < 0) {
              // round down, away from zero
              const roundDown = significance < 0;
              return roundDown ? -absSignificance * (times + 1) : -absSignificance * (times);
            } else {
              return (times + 1) * absSignificance;
            }
          },
        }),
    })
  )
  .addFunction(
    'EXP',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          transpile: ([n]) => `Math.exp(${n})`,
          jsFunction: (n: number) => Math.exp(n),
        }),
    })
  )
  .addFunction(
    'FLOOR',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (n: number, significance: number | undefined) => {
            // eslint-disable-next-line yooi/number-is-nan-call
            if (significance === undefined || Number.isNaN(significance)) {
              return Number.NaN;
            } else if (significance === 0) {
              return 0;
            } else if ((n / significance) % 1 === 0) {
              return n;
            }

            const absSignificance = Math.abs(significance);
            const times = Math.floor(Math.abs(n) / absSignificance);
            if (n < 0) {
              // round down, away from zero
              const roundDown = significance < 0;
              return roundDown ? -absSignificance * times : -absSignificance * (times + 1);
            } else {
              // toward zero
              return times * absSignificance;
            }
          },
        }),
    })
  )
  .addFunction(
    'IFNAN',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          jsFunctionArgsMode: 'lazy',
          jsFunction: (n: () => number, elseN: () => number) => {
            const nValue = n();
            // eslint-disable-next-line yooi/number-is-nan-call
            return Number.isNaN(nValue) ? elseN() : nValue;
          },
        }),
    })
  )
  .addFunction(
    'INT',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          transpile: ([n]) => `Math.floor(${n})`,
          jsFunction: (n: number) => Math.floor(n),
        }),
    })
  )
  .addFunction(
    'ISNAN',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: booleanType,
          transpile: ([n]) => `Number.isNaN(${n})`,
          // eslint-disable-next-line yooi/number-is-nan-call
          jsFunction: (n: number) => Number.isNaN(n),
        }),
    })
  )
  .addFunction(
    'LN',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (n: number) => {
            if (n <= 0) {
              throw newError('LN only support positive values');
            } else {
              return Math.log(n);
            }
          },
        }),
    })
  )
  .addFunction(
    'LOG',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 2),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (n: number, base: number | undefined = 10) => {
            if (n <= 0) {
              throw newError('LOG only support positive values');
            } else if (base <= 0) {
              throw newError('LOG only support positive bases');
            } else {
              return Math.log(n) / Math.log(base);
            }
          },
        }),
    })
  )
  .addFunction(
    'LOG10',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (n: number) => {
            if (n <= 0) {
              throw newError('LOG10 only support positive values');
            } else {
              return Math.log10(n);
            }
          },
        }),
    })
  )
  .addFunction(
    'MOD',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (n: number, divisor: number) => (n - (divisor * Math.floor(n / divisor))),
        }),
    })
  )
  .addFunction(
    'MROUND',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (n: number | undefined, multiple: number | undefined) => {
            // eslint-disable-next-line yooi/number-is-nan-call
            if (n === undefined || multiple === undefined || Number.isNaN(n) || Number.isNaN(multiple)) {
              return Number.NaN;
            } else if (multiple === 0) {
              return 0;
            } else if ((n > 0 && multiple < 0) || (n < 0 && multiple > 0)) {
              throw newError('MROUND number and multiple should have the same sign');
            } else if ((n / multiple) % 1 === 0) {
              return n;
            }
            return Math.round(n / multiple) * multiple;
          },
        }),
    })
  )
  .addFunction(
    'POWER',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          transpile: ([n, power]) => `(${n})**(${power})`,
          jsFunction: (n: number, power: number) => (n ** power),
        }),
    })
  )
  .addFunction(
    'PRODUCT',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1),
          () => checkFlattenType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (...args: unknown[]) => {
            let total: number = 1;
            flattenForEach(args, (num: number | undefined) => {
              if (num !== undefined) {
                total *= num;
              }
            });
            return total;
          },
        }),
    })
  )
  .addFunction(
    'QUOTIENT',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          transpile: ([numerator, denominator]) => `Math.trunc((${numerator})/(${denominator}))`,
          jsFunction: (numerator: number, denominator: number) => Math.trunc(numerator / denominator),
        }),
    })
  )
  .addFunction(
    'ROUND',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 2),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (n: number, decimals = 0) => {
            const sign = n >= 0 ? 1 : -1;
            if (decimals > 0) {
              const multiplier = 10 ** Math.abs(decimals);
              return sign * (Math.round(Math.abs(n) * multiplier) / multiplier);
            } else if (decimals === 0) {
              return sign * Math.round(Math.abs(n));
            } else {
              const multiplier = 10 ** Math.abs(decimals);
              return sign * (Math.round(Math.abs(n) / multiplier) * multiplier);
            }
          },
        }),
    })
  )
  .addFunction(
    ['ROUNDDOWN', 'TRUNC'],
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, name === 'TRUNC' ? 1 : 2, 2),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (n: number, decimals = 0) => {
            const sign = n >= 0 ? 1 : -1;
            if (decimals > 0) {
              const multiplier = 10 ** Math.abs(decimals);
              const offset = (1 / multiplier) * 0.5;
              return sign * (Math.round((Math.abs(n) - offset) * multiplier) / multiplier);
            } else if (decimals === 0) {
              const offset = 0.5;
              return sign * Math.round(Math.abs(n) - offset);
            } else {
              const multiplier = 10 ** Math.abs(decimals);
              const offset = multiplier * 0.5;
              return sign * (Math.round((Math.abs(n) - offset) / multiplier) * multiplier);
            }
          },
        }),
    })
  )
  .addFunction(
    'ROUNDUP',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (n: number, decimals = 0) => {
            const sign = n >= 0 ? 1 : -1;
            if (decimals > 0) {
              const multiplier = 10 ** Math.abs(decimals);
              const offset = (1 / multiplier) * 0.5;
              return sign * (Math.round((Math.abs(n) + offset) * multiplier) / multiplier);
            } else if (decimals === 0) {
              const offset = 0.5;
              return sign * Math.round(Math.abs(n) + offset);
            } else {
              const multiplier = 10 ** Math.abs(decimals);
              const offset = multiplier * 0.5;
              return sign * (Math.round((Math.abs(n) + offset) / multiplier) * multiplier);
            }
          },
        }),
    })
  )
  .addFunction(
    'SQRT',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (n: number) => {
            if (n < 0) {
              throw newError('SQRT only accept positive values');
            } else {
              return Math.sqrt(n);
            }
          },
        }),
    })
  )
  .addFunction(
    'SUM',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1),
          () => checkFlattenType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (...args: unknown[]) => {
            let sum: number = 0;
            flattenForEach(args, (e: number | undefined) => {
              if (e !== undefined) {
                sum += e;
              }
            });
            return sum;
          },
        }),
    })
  )
  .addFunction(
    'ZN',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          transpile: ([n]) => `(${n}) ?? 0`,
          jsFunction: (n: number | undefined) => (n ?? 0),
        }),
    })
  )
  .build();
