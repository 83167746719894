// This file is automatically updated by the generate-constants script. Do not update it manually.

export const CollaborationModuleId = '74db5f98-8197-56d2-a721-75995b5881ae';
export const Collaboration = '5cba1451-d00f-5453-9414-3a0beecf9d31';
export const Collaboration_Contexts = '398f7b44-d1f5-4040-b7c2-9ab8ff9a7c5b';
export const Collaboration_CreatedAt = '83a6ca6a-62ce-5c08-a923-1d98d541d934';
export const Collaboration_Hash = 'f2b08df6-b9d5-548c-aaa4-9cf0b39b463a';
export const Collaboration_Intention = '076a6f85-d227-5c91-9733-1e41b00391ee';
export const Collaboration_Path = '83902400-6e13-510c-86bb-c48e4825468b';
export const Collaboration_Status = 'a6fea511-24f0-596b-9cf3-2634f43c6cde';
export const Collaboration_UpdatedAt = '9b227f7b-754f-5165-9fd9-69db07eba450';
export const CollaborationGroups = '7b7c07a4-676a-52d3-abb2-d77516d47d08';
export const CollaborationGroups_Role_Collaboration = 1;
export const CollaborationGroups_Role_Group = 0;
export const CollaborationMessage = '1e36acf6-dce4-5fcf-a8b0-5d9c4f6aa82e';
export const CollaborationMessage_Collaboration = '927b03fb-6d56-5492-9be0-0c2004cb3d18';
export const CollaborationMessage_CreatedAt = '3277797f-d6cc-5fb3-b0a3-50977d5954b0';
export const CollaborationMessage_CreatedBy = 'cdef2a64-bad6-595f-978b-0952967b67b0';
export const CollaborationMessage_Text = 'ab989069-ae40-56bb-bc22-0321cc13498d';
export const CollaborationMessage_UpdatedAt = '55ecc61a-125c-5efd-84e7-ec79133970a3';
export const CollaborationOnMultipleContext = '9dc6e3c8-f2c7-580c-819a-d5de48b79874';
export const CollaborationOnMultipleContext_Rank = '83ab621c-b1db-5d7e-a908-099ef16446aa';
export const CollaborationOnMultipleContext_Role_Collaboration = 0;
export const CollaborationOnMultipleContext_VarRoles_Contexts = 1;
export const CollaborationRoles = '6ff2cb41-192f-496a-85c4-6bad264b5ce8';
export const CollaborationRoles_Role_Collaboration = 1;
export const CollaborationRoles_Role_Role = 0;
export const CollaborationUserInfo = '4d825207-bcbc-50f1-91d6-99a8a8302c0a';
export const CollaborationUserInfo_LastRead = '16b7f93e-8d8a-5455-bae3-ee56a358ad2d';
export const CollaborationUserInfo_Role_Collaboration = 1;
export const CollaborationUserInfo_Role_User = 0;
export const CollaborationUsers = '58308f37-92c0-5195-86d5-5e1738beaa8f';
export const CollaborationUsers_Role_Collaboration = 1;
export const CollaborationUsers_Role_User = 0;
export const ConceptIntentionGroups = '0c6fd01a-b711-4c41-b0d0-4abdf3f98d05';
export const ConceptIntentionGroups_Role_Concept = 2;
export const ConceptIntentionGroups_Role_Group = 1;
export const ConceptIntentionGroups_Role_Intention = 0;
export const ConceptIntentionRoles = '93cebab1-b707-4cbc-895b-95d1e9a6c263';
export const ConceptIntentionRoles_Role_Concept = 2;
export const ConceptIntentionRoles_Role_Intention = 0;
export const ConceptIntentionRoles_Role_Role = 1;
export const ConceptIntentionTransitionGroups = '26bcbf0f-2a7c-4e37-879f-7ce1860a1ee4';
export const ConceptIntentionTransitionGroups_Role_Concept = 1;
export const ConceptIntentionTransitionGroups_Role_Group = 3;
export const ConceptIntentionTransitionGroups_Role_Intention = 0;
export const ConceptIntentionTransitionGroups_Role_Transition = 2;
export const ConceptIntentionTransitionRoles = 'e95602ff-7fe0-492c-b7ae-444c9cb6f7cd';
export const ConceptIntentionTransitionRoles_Role_Concept = 1;
export const ConceptIntentionTransitionRoles_Role_Intention = 0;
export const ConceptIntentionTransitionRoles_Role_Role = 3;
export const ConceptIntentionTransitionRoles_Role_Transition = 2;
export const ConceptIntentionUsers = '94aa81f7-4e36-4fb3-9f2b-81ad0e1ee6a8';
export const ConceptIntentionUsers_Role_Concept = 2;
export const ConceptIntentionUsers_Role_Intention = 0;
export const ConceptIntentionUsers_Role_User = 1;
export const Intention = '9aae4363-fa75-56a4-977d-f85b3effe8f4';
export const Intention_Available = '8009baba-9c16-4add-b346-5bc810c5e054';
export const Intention_Description = '88436f59-f8db-4a9c-aedc-86de6b3ee607';
export const Intention_Name = '413fced0-34c4-5078-8cdb-75f3f1a84230';
export const Intention_Rank = '0b58a2c8-1d5f-50e6-8ae1-140776ce4bf7';
export const Intention_Workflow = '4dd48a3f-3eed-4b3d-90d4-3f72105fb277';
export const IntentionConcepts = 'e4606e07-5e11-4058-9da1-d757f492426a';
export const IntentionConcepts_Role_ConceptDefinition = 1;
export const IntentionConcepts_Role_Intention = 0;
export const IntentionGroups = '418e7cb1-fdfb-4a55-b100-8b9cb34572fa';
export const IntentionGroups_Role_Group = 1;
export const IntentionGroups_Role_Intention = 0;
export const IntentionStatusEntries = '90bf2c59-826c-4258-a985-0976e82df068';
export const IntentionStatusEntries_Role_Entry = 1;
export const IntentionStatusEntries_Role_Intention = 0;
export const IntentionStatusIsClosedStatus = 'b4248140-fa29-4b64-940c-9b66585f838c';
export const IntentionUsers = 'daa9c830-645a-4b30-8f0c-4253f1c41666';
export const IntentionUsers_Role_Intention = 0;
export const IntentionUsers_Role_User = 1;
export const Collaboration_Messages = CollaborationMessage_Collaboration;
export const Intention_Collaborations = Collaboration_Intention;
export const User_CollaborationMessages = CollaborationMessage_CreatedBy;
export const Workflow_UseAsWorkflowInIntention = Intention_Workflow;
