import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { WorkflowStoreObject } from 'yooi-modules/modules/conceptModule';
import {
  Concept_Name,
  Workflow_TargetedConceptDefinition,
  WorkflowEntry,
  WorkflowEntry_Rank,
  WorkflowEntry_Role_Concept,
  WorkflowEntry_Role_Workflow,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareProperty, compareRank, ranker, textToRichText } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import Typo from '../../../components/atoms/Typo';
import InlineLink from '../../../components/molecules/InlineLink';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import TableCell, { TableCellAlign } from '../../../components/molecules/TableCell';
import TableLine from '../../../components/molecules/TableLine';
import BlockTitle from '../../../components/templates/BlockTitle';
import DataTable from '../../../components/templates/DataTable';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useAcl from '../../../store/useAcl';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import useTheme from '../../../utils/useTheme';
import { getChipOptions, getConceptDefinitionNameOrEntity, getModelTypeOptions, getUnknownChip } from '../../_global/modelTypeUtils';

interface WorkflowDetailPageInstanceListBlockProps {
  workflowId: string,
}

const WorkflowDetailPageInstanceListBlock: FunctionComponent<WorkflowDetailPageInstanceListBlockProps> = ({ workflowId }) => {
  const theme = useTheme();

  const store = useStore();
  const { canCreateObject } = useAcl();

  const [showInlineCreation, setShowInlineCreation] = useState(false);

  const workflow = store.getObject<WorkflowStoreObject>(workflowId);
  const workflowTargetedConceptDefinition = workflow.navigateOrNull(Workflow_TargetedConceptDefinition) ?? undefined;
  const conceptDefinitionName = workflowTargetedConceptDefinition ? getConceptDefinitionNameOrEntity(store, workflowTargetedConceptDefinition.id) : i18n`Undefined`;
  const workflowEntries = store.withAssociation(WorkflowEntry)
    .withRole(WorkflowEntry_Role_Workflow, workflowId)
    .withExternalRole(WorkflowEntry_Role_Concept)
    .list();

  const computeInstanceOptions = () => {
    const associatedConceptIds = new Set<string>(workflowEntries.map((workflowEntry) => workflowEntry.role(WorkflowEntry_Role_Concept)));
    if (workflow[Workflow_TargetedConceptDefinition] && workflow.navigateOrNull(Workflow_TargetedConceptDefinition)) {
      return getModelTypeOptions(store, workflow[Workflow_TargetedConceptDefinition])
        .filter(({ id }) => !associatedConceptIds.has(id));
    } else {
      return [];
    }
  };

  const workflowInstances = ranker.decorateList(
    workflowEntries
      .map((workflowEntry) => ({
        key: workflowEntry.object.id.join('|'),
        id: workflowEntry.object.id,
        conceptId: workflowEntry.role(WorkflowEntry_Role_Concept),
        rank: workflowEntry.object[WorkflowEntry_Rank] as string,
      }))
      .sort(compareProperty('rank', compareRank)),
    ({ rank }) => rank
  );

  return (
    <VerticalBlock withSeparation asBlockContent>
      <BlockTitle
        title={i18n`Steps`}
        subtitle={(
          <Typo color={theme.color.text.secondary}>
            {i18n.jsx`You can add steps from ${(
              <InlineLink
                key={Workflow_TargetedConceptDefinition}
                to={{ pathname: `/settings/organization/${workflow[Workflow_TargetedConceptDefinition]}`, hash: '#instance' }}
              >
                {conceptDefinitionName}
              </InlineLink>
            )} and rank them here.`}
          </Typo>
        )}
      />
      <DataTable
        list={workflowInstances
          .map(({ item: { key, id: associationId, conceptId }, moveUpRank, moveDownRank, i: index }) => ({
            key,
            type: 'item',
            item: { index, associationId, conceptId, moveUpRank, moveDownRank },
            color: undefined,
          }))}
        columnsDefinition={[
          {
            name: i18n`Name`,
            propertyId: Workflow_TargetedConceptDefinition,
            cellRender: ({ associationId, conceptId }) => (
              <SearchAndSelect
                selectedOption={getChipOptions(store, conceptId) ?? getUnknownChip(conceptId)}
                computeOptions={computeInstanceOptions}
                onSelect={(option) => {
                  if (option) {
                    const currentObject = store.getObject(associationId).asRawObject();
                    store.deleteObject(associationId);
                    store.withAssociation(WorkflowEntry)
                      .withRole(WorkflowEntry_Role_Workflow, workflowId)
                      .withRole(WorkflowEntry_Role_Concept, option.id)
                      .updateObject(currentObject);
                  }
                }}
                getInlineCreation={workflowTargetedConceptDefinition ? () => ({
                  type: 'inline',
                  onCreate: (title) => {
                    const newId = store.createObject({
                      [Instance_Of]: workflowTargetedConceptDefinition.id,
                      [Concept_Name]: textToRichText(title),
                    });

                    const currentObject = store.getObject(associationId).asRawObject();
                    store.deleteObject(associationId);
                    store.withAssociation(WorkflowEntry)
                      .withRole(WorkflowEntry_Role_Workflow, workflowId)
                      .withRole(WorkflowEntry_Role_Concept, newId)
                      .updateObject(currentObject);

                    return newId;
                  },
                }) : undefined}
                placeholder={i18n`Select step`}
                statusIcon={
                  !workflowTargetedConceptDefinition || !isInstanceOf(store.getObjectOrNull(conceptId), workflowTargetedConceptDefinition.id)
                    ? { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Selected step is not of the expected type` } : undefined
                }
              />
            ),
          },
          {
            propertyId: 'move-up',
            action: true,
            align: TableCellAlign.center,
            cellRender: ({ index, associationId, moveUpRank }) => (
              <IconOnlyButton
                onClick={() => {
                  store.updateObject(associationId, { [WorkflowEntry_Rank]: moveUpRank() });
                }}
                disabled={index === 0}
                iconName={IconName.expand_less}
                tooltip={i18n`Move Up`}
                variant={IconOnlyButtonVariants.tertiary}
              />
            ),
          },
          {
            propertyId: 'move-down',
            action: true,
            align: TableCellAlign.center,
            cellRender: ({ index, associationId, moveDownRank }) => (
              <IconOnlyButton
                onClick={() => {
                  store.updateObject(associationId, { [WorkflowEntry_Rank]: moveDownRank() });
                }}
                disabled={index === workflowInstances.length - 1}
                iconName={IconName.expand_more}
                tooltip={i18n`Move Down`}
                variant={IconOnlyButtonVariants.tertiary}
              />
            ),
          },
        ]}
        linesActions={({ associationId }) => [
          {
            key: 'unlink',
            name: i18n`Unlink step`,
            icon: IconName.delete,
            danger: true,
            onClick: () => {
              store.deleteObject(associationId);
            },
          },
        ]}
        newItemIcon={IconName.add}
        newItemTitle={i18n`Add`}
        onNewItem={showInlineCreation ? undefined : () => setShowInlineCreation(true)}
        handleClickAway={(isEscape) => {
          if (isEscape) {
            setShowInlineCreation(false);
          }
        }}
        inlineCreation={{
          render: showInlineCreation ? (
            <TableLine>
              <TableCell>
                <SearchAndSelect
                  computeOptions={computeInstanceOptions}
                  onSelect={(value) => {
                    if (value) {
                      const association = store.withAssociation(WorkflowEntry)
                        .withRole(WorkflowEntry_Role_Workflow, workflowId)
                        .withRole(WorkflowEntry_Role_Concept, value.id);
                      if (!association.getObjectOrNull()) {
                        association.updateObject({ [WorkflowEntry_Rank]: workflowInstances.insertAfterLastItemRank() });
                      }
                      setShowInlineCreation(false);
                    }
                  }}
                  getInlineCreation={workflowTargetedConceptDefinition && canCreateObject(workflowTargetedConceptDefinition.id) ? () => ({
                    type: 'inline',
                    onCreate: (title) => {
                      const newId = store.createObject({
                        [Instance_Of]: workflowTargetedConceptDefinition.id,
                        [Concept_Name]: textToRichText(title),
                      });

                      store.withAssociation(WorkflowEntry)
                        .withRole(WorkflowEntry_Role_Workflow, workflowId)
                        .withRole(WorkflowEntry_Role_Concept, newId)
                        .updateObject({ [WorkflowEntry_Rank]: workflowInstances.insertAfterLastItemRank() });
                      setShowInlineCreation(false);

                      return newId;
                    },
                  }) : undefined}
                  editOnMount
                />
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
            </TableLine>
          ) : null,
        }}
      />
    </VerticalBlock>
  );
};

export default WorkflowDetailPageInstanceListBlock;
