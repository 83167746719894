import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import type {
  AutomationActionEmailStoreObject,
  AutomationEmailActionRecipient,
  AutomationRuleStoreObject,
} from 'yooi-modules/modules/automationModule';
import {
  escapeQueryLabel,
  getForEachOutputParameterDefinitions,
  isEmailRecipient,
  isPathRecipient,
} from 'yooi-modules/modules/automationModule';
import {
  AutomationAction_Query,
  AutomationAction_Rule,
  AutomationActionEmail,
  AutomationActionEmail_Body,
  AutomationActionEmail_HasCustomPermissions,
  AutomationActionEmail_Permissions,
  AutomationActionEmail_Recipients,
  AutomationActionEmail_Subject,
  AutomationRule_Actions,
  AutomationRule_Trigger,
  AutomationRule_UserId,
} from 'yooi-modules/modules/automationModule/ids';
import type { PathStep } from 'yooi-modules/modules/conceptModule';
import { InstanceReferenceType, isMultiplePath, PathStepType } from 'yooi-modules/modules/conceptModule';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { checkIsEmailValid, compareProperty, compareString, joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import Typo from '../../../components/atoms/Typo';
import Banner, { BannerVariant } from '../../../components/molecules/Banner';
import Chooser from '../../../components/molecules/Chooser';
import OverflowMenu from '../../../components/molecules/OverflowMenu';
import SpacingLine from '../../../components/molecules/SpacingLine';
import type { TreeNodeEntry } from '../../../components/molecules/Tree';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import CardBlock from '../../../components/templates/CardBlock';
import HorizontalBlock, { HorizontalBlockVariant } from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import { buildPadding, Spacing, spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { remToPx } from '../../../utils/sizeUtils';
import useDerivedState from '../../../utils/useDerivedState';
import useTheme from '../../../utils/useTheme';
import StoreTextInputField from '../../_global/input/StoreTextInputField';
import PathInput from '../../_global/path/PathInput';
import PathStepsInput from '../../_global/path/PathStepsInput';
import { createPathConfigurationHandler } from '../../_global/pathConfigurationHandler';
import AutomationCardTitle from './AutomationCardTitle';
import { areRecipientsFulfilled, createAutomationPathConfigurationHandler } from './automationUtils';
import MailAutomationQuery from './MailAutomationQuery';
import { getAutomationNodes, getPathStepAsTreeNode } from './templateAutomationTreeUtils';
import TemplateTree from './TemplateTree';
import type { ActionQueryNode, ConceptChildren, CustomNodesChildren } from './triggers/triggerFrontHandler';
import { getTriggerFrontHandler } from './triggers/triggerFrontHandler';

interface MailActionProps {
  ruleId: string,
}

const useStyles = makeStyles({
  cardContainer: {
    padding: spacingRem.m,
  },
  permissions: {
    ...buildPadding({ y: Spacing.s }),
  },
  alignContent: {
    paddingLeft: spacingRem.s,
  },
  recipientLine: {
    display: 'grid',
    gridTemplateColumns: '1fr 2rem',
    columnGap: spacingRem.s,
    alignItems: 'center',
  },
  recipientLinePadding: {
    paddingBottom: spacingRem.s,
  },
  bold: {
    fontWeight: 'bold',
    whiteSpace: 'pre-wrap',
  },
}, 'mailAction');

export interface MailPathActionQuery {
  type: 'path',
  path: PathStep[],
  readOnly: boolean,
}

export type MailConstantActionQuery = ActionQueryNode & { type: 'constant', readOnly: boolean };

export type MailActionQuery = Record<string, MailPathActionQuery | MailConstantActionQuery>;

const MailAction: FunctionComponent<MailActionProps> = ({ ruleId }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const automationRule = store.getObject<AutomationRuleStoreObject>(ruleId);
  const action = automationRule.navigateBack<AutomationActionEmailStoreObject>(AutomationRule_Actions)[0];
  const rule = action?.navigate<AutomationRuleStoreObject>(AutomationAction_Rule);
  const hasCustomPermissions = action?.[AutomationActionEmail_HasCustomPermissions] ?? false;
  const permissionAsRecipientAction = { key: 'permissions_as_recipient', name: i18n`Recipient's` };
  const customPermissionsAction = { key: 'custom_permissions', name: i18n`Custom` };
  const isPersonalRule = Boolean(automationRule.navigateOrNull(AutomationRule_UserId));

  const [showVariables, setShowVariables] = useDerivedState(() => true, [ruleId]);
  const [permissionState, setPermissionState] = useDerivedState(() => (hasCustomPermissions ? customPermissionsAction.key : permissionAsRecipientAction.key), [action]);
  const [hoveredLine, setHoveredLine] = useState<{ id: string | undefined, isHovered: boolean }>({ id: undefined, isHovered: false });

  if (!isInstanceOf(action, AutomationActionEmail) || !rule) {
    return null;
  }

  const parameterDefinitions = getForEachOutputParameterDefinitions(store, ruleId);
  const valuePathHandlerCanBeMultipleUsers = createAutomationPathConfigurationHandler(store, parameterDefinitions, true);
  const valuePathHandlerSingleUser = createAutomationPathConfigurationHandler(store, parameterDefinitions);

  const actionQuery: MailActionQuery = {};

  parameterDefinitions
    .filter((parameterDefinition) => Boolean(parameterDefinition.label))
    .sort(compareProperty('label', compareString))
    .forEach((parameterDefinition) => {
      actionQuery[escapeQueryLabel(parameterDefinition.label)] = {
        type: 'path',
        path: [
          { type: PathStepType.dimension, conceptDefinitionId: parameterDefinition.typeId },
          parameterDefinition.type !== 'parameterList'
            ? { type: PathStepType.mapping, mapping: { id: parameterDefinition.id, type: InstanceReferenceType.parameter } }
            : { type: PathStepType.multipleMapping, id: parameterDefinition.id },
        ],
        readOnly: true,
      };
    });

  Object.entries(action[AutomationAction_Query] ?? {}).forEach(([key, path]) => {
    actionQuery[key] = { type: 'path', path, readOnly: false };
  });

  const trigger = rule[AutomationRule_Trigger];
  if (trigger && isInstanceOf<AutomationActionEmailStoreObject>(action, AutomationActionEmail)) {
    Object.entries(getTriggerFrontHandler(trigger).mailQueryNodes ?? {}).forEach(([key, { label, helperText, children, icon }]) => {
      actionQuery[key] = { type: 'constant', label, helperText, children, icon, readOnly: true };
    });
  }

  const buildGetChildrenNode = (children: (CustomNodesChildren | ConceptChildren)): (() => TreeNodeEntry[]) | undefined => {
    if (children.type === 'concept') {
      return getAutomationNodes(store, parameterDefinitions, children.typeId, children.isMultiple, 1);
    } else {
      return () => Object.values(children.nodes).map(({ label, helperText, icon, children: grandChildren }): TreeNodeEntry => ({
        label,
        getKey: () => label,
        getPath: (parentPath) => `${parentPath}.${label}`,
        matchFunction: (searchInput) => label.includes(searchInput),
        generateGetChipOption: () => () => ({ key: label, label, tooltip: helperText, icon }),
        generateOnChipClick: (copyValue, parentPath) => () => {
          copyValue(`{{ ${parentPath}.${label} }}`, i18n`Path copied to clipboard`);
          return `{{ ${parentPath}.${label} }}`;
        },
        returnTypeId: undefined,
        getChildrenNode: grandChildren ? buildGetChildrenNode(grandChildren) : undefined,
      }));
    }
  };

  const getTreeData = (): TreeNodeEntry[] => {
    const tree: TreeNodeEntry[] = [];
    Object.entries(actionQuery).forEach(([label, query], index) => {
      if (query.type === 'path') {
        const { path } = query;
        if (path.length > 0) {
          const treeNode = getPathStepAsTreeNode(store, parameterDefinitions, label, path, `${label}_${index}`);
          if (treeNode) {
            tree.push(treeNode);
          }
        }
      } else {
        const { children, helperText, icon } = query;
        tree.push({
          label,
          getKey: () => label,
          matchFunction: (searchInput) => searchInput.includes(label),
          generateGetChipOption: () => () => ({ key: label, label, tooltip: helperText, borderStyle: 'dashed', icon }),
          generateOnChipClick: (copyValue) => () => {
            copyValue(`{{ ${label} }}`, i18n`Path copied to clipboard`);
            return `{{ ${label} }}`;
          },
          returnTypeId: undefined,
          getPath: () => label,
          getChildrenNode: children ? buildGetChildrenNode(children) : undefined,
        });
      }
    });
    return tree;
  };

  const getRecipientLineContent = (recipients: AutomationEmailActionRecipient[], recipient: AutomationEmailActionRecipient, recipientIndex: number) => {
    const newRecipients = [...recipients];
    if (isEmailRecipient(recipient)) {
      return (
        <StoreTextInputField
          initialValue={recipient.value}
          onSubmit={(val) => {
            if (recipientIndex !== -1 && isEmailRecipient(recipients[recipientIndex])) {
              newRecipients[recipientIndex] = joinObjects(recipient, { value: val ?? undefined });
              store.updateObject(action.id, { [AutomationActionEmail_Recipients]: newRecipients });
            }
          }}
          inputValidation={(value) => (!value || checkIsEmailValid(value) ? undefined : i18n`Email is invalid`)}
          error={recipient.value && !checkIsEmailValid(recipient.value) ? i18n`Email is invalid` : undefined}
          placeholder={i18n`Add an email`}
          readOnly={isPersonalRule}
        />
      );
    } else if (isPathRecipient(recipient)) {
      return (
        <PathInput
          path={recipient.value}
          parameterDefinitions={parameterDefinitions}
          placeholder={i18n`Add a path to user(s)`}
          onChange={(newPath) => {
            let actionUpdate: Record<string, unknown> = {};
            if (recipientIndex !== -1 && isPathRecipient(recipients[recipientIndex])) {
              newRecipients[recipientIndex] = joinObjects(recipient, { value: newPath });
              actionUpdate = joinObjects(actionUpdate, { [AutomationActionEmail_Recipients]: newRecipients });
            }
            if (!hasCustomPermissions && isMultiplePath(store, newPath)) {
              actionUpdate = joinObjects(actionUpdate, { [AutomationActionEmail_HasCustomPermissions]: true });
              setPermissionState(customPermissionsAction.key);
            }
            store.updateObject(action.id, actionUpdate);
          }}
          valuePathHandler={valuePathHandlerCanBeMultipleUsers}
          readOnly={isPersonalRule}
        />
      );
    } else {
      return (<Typo>{i18n`Unresolved recipient`}</Typo>);
    }
  };

  const getAddRecipientButton = (recipients: AutomationEmailActionRecipient[], title?: string) => (isPersonalRule ? undefined
    : (
      <OverflowMenu
        title={title}
        iconName={IconName.add}
        tooltip={i18n`Add a recipient`}
        placement="bottom-start"
        offset={[remToPx(0), remToPx(spacingRem.xs)]}
        menuItems={[
          {
            key: 'addRecipientPath',
            name: i18n`Use a path`,
            onClick: () => {
              const newRecipients = [...recipients, { id: uuid(), type: 'path', value: [] }];
              let actionUpdate: Record<string, unknown> = { [AutomationActionEmail_Recipients]: newRecipients };
              if (!hasCustomPermissions && newRecipients.length > 1) {
                actionUpdate = joinObjects(actionUpdate, { [AutomationActionEmail_HasCustomPermissions]: true });
                setPermissionState(customPermissionsAction.key);
              }
              store.updateObject(action.id, actionUpdate);
            },
          },
          {
            key: 'addRecipientString',
            name: i18n`Use a value`,
            onClick: () => {
              const newRecipients = [...recipients, { id: uuid(), type: 'email' }];
              let actionUpdate: Record<string, unknown> = { [AutomationActionEmail_Recipients]: newRecipients };
              if (!hasCustomPermissions) {
                actionUpdate = joinObjects(actionUpdate, { [AutomationActionEmail_HasCustomPermissions]: true });
                setPermissionState(customPermissionsAction.key);
              }
              store.updateObject(action.id, actionUpdate);
            },
          },
        ]}
      />
    ));

  const textInputAction = { getRender: () => (<TemplateTree getTreeData={getTreeData} />), iconName: IconName.data_object, tooltip: i18n`Insert variables or tags` };
  const permissions = action[AutomationActionEmail_Permissions];
  const pathConfigurationHandler = createPathConfigurationHandler(store, parameterDefinitions);
  const arePermissionsSet = Boolean(permissions && pathConfigurationHandler.getPathSummarize(permissions));
  const permissionLabel = (permissions && pathConfigurationHandler.getPathSummarize(permissions)) ?? '';
  const displayPermissionsWarning = !(permissions && valuePathHandlerSingleUser.getErrors(permissions))
    && (JSON.stringify(action[AutomationActionEmail_Recipients]) !== JSON.stringify(permissions));
  const recipients = action[AutomationActionEmail_Recipients] && action[AutomationActionEmail_Recipients]?.length > 0
    ? action[AutomationActionEmail_Recipients]
    : [];
  const sameAsRecipientDisabled = (recipients.length === 1 && isEmailRecipient(recipients[0])) || (recipients.length === 1 && isPathRecipient(recipients[0])
    && recipients[0].value && isMultiplePath(store, recipients[0].value)) || recipients.length > 1;

  const permissionsChooserActions = [
    joinObjects(
      permissionAsRecipientAction,
      {
        disable: sameAsRecipientDisabled,
        tooltip: sameAsRecipientDisabled ? i18n`List-based recipients do not support individual recipient permissions` : i18n`The email will be sent using the recipient's permissions`,
      }
    ),
    joinObjects(
      customPermissionsAction,
      { tooltip: i18n`Define a custom permissions you want to use to send this email` }
    )];

  return (
    <>
      <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compact}>
        <BlockTitle
          title={i18n`To`}
          mandatoryIcon={{
            tooltip: areRecipientsFulfilled(action[AutomationActionEmail_Recipients]) ? undefined : i18n`Required field`,
            color: areRecipientsFulfilled(action[AutomationActionEmail_Recipients]) ? IconColorVariant.secondary : undefined,
          }}
          actions={recipients?.length > 0 && getAddRecipientButton(recipients)}
        />
        {recipients && recipients.length > 0 ? recipients.map((recipient, index) => {
          const newRecipients = [...recipients];
          const recipientIndex = recipients.findIndex((rec) => rec.id === recipient.id);
          return (
            <BlockContent
              key={recipient.id}
              action={index === 0 && (recipients.length > 1 || (isPathRecipient(recipient) && isMultiplePath(store, recipient.value))) ? (
                <SpacingLine>
                  <Icon
                    name={IconName.info}
                    colorVariant={IconColorVariant.info}
                    tooltip={i18n`A single email will be sent to all recipients. If you want to send several distinct emails, please use a parameter in the "for each" section above.`}
                  />
                </SpacingLine>
              ) : undefined}
            >
              <div
                className={classnames({
                  [classes.recipientLine]: true,
                  [classes.recipientLinePadding]: recipients.length !== index + 1,
                })}
                onMouseEnter={() => setHoveredLine({ id: recipient.id, isHovered: true })}
                onMouseLeave={() => setHoveredLine({ id: recipient.id, isHovered: false })}
              >
                {getRecipientLineContent(recipients, recipient, recipientIndex)}
                {recipient.id === hoveredLine.id && hoveredLine.isHovered && !isPersonalRule && (
                  <IconOnlyButton
                    iconName={IconName.delete}
                    tooltip={i18n`Delete`}
                    variant={IconOnlyButtonVariants.danger}
                    onClick={() => {
                      newRecipients.splice(recipientIndex, 1);
                      store.updateObject(action.id, { [AutomationActionEmail_Recipients]: newRecipients });
                    }}
                  />
                )}
              </div>
            </BlockContent>
          );
        }) : (
          <BlockContent
            key="addRecipient"
            padded
          >
            <SpacingLine>
              {getAddRecipientButton(recipients, i18n`Recipient`)}
            </SpacingLine>
          </BlockContent>
        )}
      </HorizontalBlock>
      <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compact}>
        <BlockTitle
          title={i18n`Permissions`}
        />
        <BlockContent
          action={(
            <SpacingLine>
              <Icon
                name={IconName.info}
                colorVariant={IconColorVariant.info}
                tooltip={i18n`Permissions affects the trigger, the "Only if" filter and the data contained on the email sent. For the email to be sent, the user set in permissions should be able to see the events that triggered the automation and all the variables contained in the email. The "Only if" filter is also evaluated based on the permissions set.`}
              />
            </SpacingLine>
          )}
        >
          <div className={classes.alignContent}>
            <SpacingLine>
              <Chooser
                actions={permissionsChooserActions}
                readOnly={isPersonalRule}
                onClick={(index) => {
                  setPermissionState(permissionsChooserActions[index].key);
                  store.updateObject(action.id, { [AutomationActionEmail_HasCustomPermissions]: permissionsChooserActions[index].key === customPermissionsAction.key });
                }}
                selectedIndexes={[permissionsChooserActions.findIndex((permissionAction) => permissionAction.key === permissionState)]}
              />
            </SpacingLine>
          </div>
        </BlockContent>
        {permissionState === customPermissionsAction.key
          && (
            <BlockContent>
              <div className={classes.permissions}>
                <PathStepsInput
                  initialPath={action[AutomationActionEmail_Permissions] ?? []}
                  parameterDefinitions={parameterDefinitions}
                  placeholder={i18n`No permission restriction`}
                  onSubmit={(newPath) => store.updateObject(action.id, { [AutomationActionEmail_Permissions]: newPath })}
                  valuePathHandler={valuePathHandlerSingleUser}
                />
              </div>
              {displayPermissionsWarning
                && (
                  <div className={classes.alignContent}>
                    <Banner
                      key="permissionBanner"
                      variant={BannerVariant.warning}
                      title={(
                        <Typo>
                          {
                            i18n.jsx`Current settings may lead to ${(
                              <span key="boldPermissionBanner1" className={classes.bold}>{i18n`data leaks`}</span>
                            )}.\nAll recipients will receive the same email ${
                              !arePermissionsSet
                                ? (
                                  <span key="boldPermissionBanner2" className={classes.bold}>{i18n`without any permission restriction`}</span>
                                )
                                : i18n`based on ${permissionLabel} permissions`
                            }.`
                          }
                        </Typo>
                      )}
                    />
                  </div>
                )}
            </BlockContent>
          )}
      </HorizontalBlock>
      <BlockContent padded>
        <CardBlock
          titleContent={(
            <AutomationCardTitle
              label={i18n`Send an email`}
              icon={IconName.mail}
              iconColor={theme.color.background.info.default}
              backgroundColor={theme.color.background.info.light}
            />
          )}
          content={(
            <div className={classes.cardContainer}>
              <VerticalBlock>
                <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compactInCard}>
                  <BlockTitle
                    title={i18n`Variables`}
                    buttonIcon={{
                      tooltip: showVariables ? i18n`Hide` : i18n`Show`,
                      iconName: showVariables ? IconName.expand_more : IconName.keyboard_arrow_right,
                      onClick: () => setShowVariables((old) => !old),
                      alwaysVisible: true,
                    }}
                  />
                </HorizontalBlock>
                {showVariables && (
                  <BlockContent fullWidth padded>
                    <MailAutomationQuery
                      query={actionQuery}
                      parameterDefinitions={getForEachOutputParameterDefinitions(store, ruleId)}
                      onChange={(newQuery) => (
                        store.updateObject(action.id, { [AutomationAction_Query]: newQuery })
                      )}
                    />
                  </BlockContent>
                )}
                <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compactInCard}>
                  <BlockTitle
                    title={i18n`Subject`}
                    mandatoryIcon={{
                      tooltip: !action[AutomationActionEmail_Subject] ? i18n`Required field` : undefined,
                      color: action[AutomationActionEmail_Subject] ? IconColorVariant.secondary : undefined,
                    }}
                  />
                  <BlockContent fullWidth padded>
                    <StoreTextInputField
                      initialValue={action[AutomationActionEmail_Subject]}
                      onSubmit={(val) => store.updateObject(action.id, { [AutomationActionEmail_Subject]: val })}
                      placeholder={i18n`Add a Subject`}
                      textInputAction={textInputAction}
                    />
                  </BlockContent>
                </HorizontalBlock>
                <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compactInCard}>
                  <BlockTitle
                    title={i18n`Body`}
                    mandatoryIcon={{
                      tooltip: !action[AutomationActionEmail_Body] ? i18n`Required field` : undefined,
                      color: action[AutomationActionEmail_Body] ? IconColorVariant.secondary : undefined,
                    }}
                  />
                  <BlockContent fullWidth padded>
                    <StoreTextInputField
                      initialValue={action[AutomationActionEmail_Body]}
                      onSubmit={(val) => store.updateObject(action.id, { [AutomationActionEmail_Body]: val })}
                      placeholder={i18n`Add a Body`}
                      textInputAction={textInputAction}
                    />
                  </BlockContent>
                </HorizontalBlock>
              </VerticalBlock>
            </div>
          )}
        />
      </BlockContent>
    </>
  );
};
export default MailAction;
