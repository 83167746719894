import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import { OverridableDisplayType } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import type {
  FieldLocalOverrideRaw,
  FieldLocalOverrideStoreObject,
  FieldStoreObject,
  Formula,
  TimeseriesFieldValue,
  TimeseriesTextFieldRaw,
  TimeseriesTextFieldStoreObject,
} from 'yooi-modules/modules/conceptModule';
import { timeseriesTextFieldHandler } from 'yooi-modules/modules/conceptModule';
import type { TimeseriesExportConfiguration } from 'yooi-modules/modules/conceptModule/fields';
import {
  Field_ApiAlias,
  Field_Documentation,
  Field_Formula,
  Field_IntegrationOnly,
  Field_IsCore,
  Field_IsDocumentationInline,
  Field_Title,
  FieldLocalOverride,
  FieldLocalOverride_Role_Concept,
  FieldLocalOverride_Role_Field,
  FieldLocalOverride_TimeseriesTextFieldDefaultPeriod,
  TimeseriesTextField,
  TimeseriesTextField_DefaultPeriod,
} from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreReadOnly } from 'yooi-store';
import { compareString, comparing, isFiniteNumber, joinObjects, PeriodicityType } from 'yooi-utils';
import { IconName } from '../../../../../components/atoms/Icon';
import SimpleInput from '../../../../../components/inputs/strategy/SimpleInput';
import TextInputString from '../../../../../components/inputs/TextInputString';
import { TableSortDirection } from '../../../../../components/molecules/Table';
import { getPeriodicityOption, getPeriodicityOptions } from '../../../../../utils/dateUtilsFront';
import i18n from '../../../../../utils/i18n';
import BlockField from '../../_global/BlockField';
import { getApiAliasInitialState, getDimensionsEditionOption, getDocumentationFieldEditionSection, getIntegrationFieldEditionSection } from '../../_global/editionHandlerUtils';
import { getOptionalOverridableDisplayTypeOption, getOverridableDisplayTypeLabel, getOverridableDisplayTypeOptions } from '../../_global/overridableFieldUtils';
import { duplicateFormula } from '../../duplicationUtils';
import type { FieldEditionDimensions } from '../../fieldDimensionUtils';
import {
  createAndLinkFieldToConceptDefinitions,
  duplicateFieldDimensionWithNewField,
  FIELD_DIMENSIONS_READONLY,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
  submitDimensionUpdate,
} from '../../fieldDimensionUtils';
import type { FieldEditionOption, FieldEditionSection, FieldEditionSectionGroup } from '../../FieldEditionOptionType';
import { EditionOptionTypes } from '../../FieldEditionOptionType';
import { registerFieldDefinition } from '../../FieldLibrary';
import type { FieldComparatorHandler, GetFieldDefinitionHandler } from '../../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldIntegrationOnlyDisabled } from '../../FieldLibraryTypes';
import TimeseriesExportConfigurationInput from '../TimeseriesExportConfigurationInput';
import TimeseriesTable from '../TimeseriesTable';
import TimeseriesTextFieldTextInput from './TimeseriesTextFieldTextInput';

interface TimeseriesTextFieldBlockDisplayOptions extends FieldBlockDisplayOptions {
  displayType?: OverridableDisplayType,
}

const defaultDisplayOption: TimeseriesTextFieldBlockDisplayOptions = { displayType: OverridableDisplayType.Value };

const getDisplayOptions = (objectStore: ObjectStoreReadOnly, fieldBlockDisplayId: string): TimeseriesTextFieldBlockDisplayOptions => {
  const fieldBlockDisplay = objectStore.getObject(fieldBlockDisplayId);
  if (fieldBlockDisplay[FieldBlockDisplay_FieldDisplayConfiguration]) {
    return fieldBlockDisplay[FieldBlockDisplay_FieldDisplayConfiguration] as TimeseriesTextFieldBlockDisplayOptions;
  } else {
    return defaultDisplayOption;
  }
};

interface TimeseriesTextFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [FIELD_DIMENSIONS_READONLY]: boolean | undefined,
  [Field_Title]?: string | null | undefined,
  [Field_ApiAlias]?: string | null | undefined,
  [Field_Documentation]?: string | null | undefined,
  [Field_IsDocumentationInline]?: boolean | null | undefined,
  [Field_IntegrationOnly]?: boolean | null | undefined,
  [FieldIntegrationOnlyDisabled]?: boolean | undefined,
  [Field_Formula]?: Formula | null | undefined,
  [TimeseriesTextField_DefaultPeriod]: PeriodicityType | null | undefined,
}

type TimeseriesTextFieldDefinition = GetFieldDefinitionHandler<
  typeof timeseriesTextFieldHandler,
  TimeseriesTextFieldConfigurationState,
  never,
  TimeseriesTextFieldBlockDisplayOptions,
  TimeseriesExportConfiguration
>;

export const timeseriesTextFieldDefinition: TimeseriesTextFieldDefinition = registerFieldDefinition(timeseriesTextFieldHandler, {
  configuration: {
    typeIcon: IconName.avg_pace,
    getTypeLabel: () => i18n`Texts Timeseries`,
    asWidget: false,
    getEditionOptions: (store) => ({ mode, editionHandler, readOnly }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode, FieldEditionOptionMode.Override].includes(mode)) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      const dataOptions: FieldEditionOption[] = [];

      if ([FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        dataOptions.push(
          getDimensionsEditionOption(editionHandler, Boolean(editionHandler.getValue(Field_Formula)), readOnly || Boolean(editionHandler.getValue(FIELD_DIMENSIONS_READONLY)))
        );
      }

      dataOptions.push({
        key: TimeseriesTextField_DefaultPeriod,
        title: i18n`Default periodicity`,
        hasValue: () => editionHandler.getValue(TimeseriesTextField_DefaultPeriod) !== undefined,
        clearValue: () => editionHandler.updateValues({ [TimeseriesTextField_DefaultPeriod]: null }),
        type: EditionOptionTypes.select,
        props: {
          readOnly,
          selectedOption: getPeriodicityOption(editionHandler.getValueOrDefault(TimeseriesTextField_DefaultPeriod) ?? PeriodicityType.day),
          computeOptions: () => getPeriodicityOptions(),
          onChange: (value) => editionHandler.updateValues({ [TimeseriesTextField_DefaultPeriod]: (value?.id as PeriodicityType | undefined) ?? null }),
        },
      });

      sections.push({
        key: 'data',
        type: 'section',
        title: i18n`Data`,
        options: dataOptions,
      });

      if ([FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        sections.push(getDocumentationFieldEditionSection(editionHandler));
        sections.push(getIntegrationFieldEditionSection(store, editionHandler, mode));
      }

      return sections;
    },
    isCreationEnabled: () => () => true,
    onCreate: (objectStore) => (editionHandler) => {
      const fieldId = objectStore.createObject<TimeseriesTextFieldRaw>({
        [Instance_Of]: TimeseriesTextField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [Field_ApiAlias]: editionHandler.getValue(Field_ApiAlias),
        [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
        [Field_Formula]: editionHandler.getValue(Field_Formula),
        [TimeseriesTextField_DefaultPeriod]: editionHandler.getValue(TimeseriesTextField_DefaultPeriod),
      });
      linkFieldToFieldDimensions(objectStore, fieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});
      return fieldId;
    },
    inlineCreate: (objectStore) => (conceptDefinitionId, extraFieldOptions) => ({
      type: 'inline',
      onCreate: (title) => {
        const newFieldId = objectStore.createObject<TimeseriesTextFieldRaw>(joinObjects(
          extraFieldOptions,
          {
            [Instance_Of]: TimeseriesTextField,
            [Field_Title]: title,
          }
        ));
        createAndLinkFieldToConceptDefinitions(objectStore, newFieldId, [conceptDefinitionId]);
        return newFieldId;
      },
    }),
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<TimeseriesTextFieldStoreObject>(fieldId);
        return joinObjects(
          getApiAliasInitialState(objectStore, fieldId),
          {
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            [Field_IntegrationOnly]: field[Field_IntegrationOnly],
            [FieldIntegrationOnlyDisabled]: field[Field_IsCore],
            [Field_Formula]: field[Field_Formula],
            [TimeseriesTextField_DefaultPeriod]: field[TimeseriesTextField_DefaultPeriod],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
            [FIELD_DIMENSIONS_READONLY]: field[Field_IsCore],
          }
        );
      },
      getInitialOverrideState: (objectId) => {
        const localOverride = objectStore.withAssociation(FieldLocalOverride)
          .withRole(FieldLocalOverride_Role_Field, fieldId)
          .withRole(FieldLocalOverride_Role_Concept, objectId)
          .getObjectOrNull<FieldLocalOverrideStoreObject>();
        const field = objectStore.getObject<FieldStoreObject>(fieldId);
        const conceptDefinitionId = objectStore.getObject(objectId)[Instance_Of] as string;
        return {
          [TimeseriesTextField_DefaultPeriod]: localOverride?.[FieldLocalOverride_TimeseriesTextFieldDefaultPeriod],
          [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
          [FIELD_DIMENSIONS_READONLY]: field[Field_IsCore],
        };
      },
      submitFieldOverrideUpdate: (objectId, stateToSubmit) => {
        const update = {
          [FieldLocalOverride_TimeseriesTextFieldDefaultPeriod]: stateToSubmit[TimeseriesTextField_DefaultPeriod],
        };

        objectStore.withAssociation(FieldLocalOverride)
          .withRole(FieldLocalOverride_Role_Field, fieldId)
          .withRole(FieldLocalOverride_Role_Concept, objectId)
          .updateObject<FieldLocalOverrideRaw>(update);
      },
      submitFieldUpdate: (stateToSubmit, conceptDefinitionId) => {
        objectStore.updateObject<TimeseriesTextFieldRaw>(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: stateToSubmit[Field_IntegrationOnly],
          [Field_Formula]: stateToSubmit[Field_Formula],
          [TimeseriesTextField_DefaultPeriod]: stateToSubmit[TimeseriesTextField_DefaultPeriod],
        });
        submitDimensionUpdate(objectStore, fieldId, conceptDefinitionId, stateToSubmit[FIELD_EDITION_DIMENSIONS] ?? {});
      },
      duplicateFieldDefinition: () => {
        const field = objectStore.getObject<TimeseriesTextFieldStoreObject>(fieldId);
        const fieldDimensionMapping = generateDuplicatedFieldDimensionId(objectStore, fieldId);

        const newFieldId = objectStore.createObject<TimeseriesTextFieldRaw>({
          [Instance_Of]: field[Instance_Of],
          [Field_Title]: `${field[Field_Title]} (copy)`,
          [Field_Documentation]: field[Field_Documentation],
          [Field_IntegrationOnly]: field[Field_IntegrationOnly],
          [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
          [Field_Formula]: duplicateFormula(field[Field_Formula], fieldDimensionMapping),
          [TimeseriesTextField_DefaultPeriod]: field[TimeseriesTextField_DefaultPeriod],
        });
        duplicateFieldDimensionWithNewField(objectStore, newFieldId, fieldDimensionMapping);
        return newFieldId;
      },
    }),
  },
  getActivityProperties: (_, fieldId) => () => [fieldId],
  renderBlockField: (_, fieldId) => (dimensionsMapping, __, { readOnly, ...otherBlockFieldProps }) => (
    <BlockField
      fieldId={fieldId}
      {...otherBlockFieldProps}
      isVertical
    >
      <TimeseriesTable
        dimensionsMapping={dimensionsMapping}
        fieldId={fieldId}
        readOnly={readOnly ?? false}
      />
    </BlockField>
  ),
  renderField: (objectStore, fieldId) => ({ readOnly, time, dimensionsMapping, onSubmit, value }) => {
    if (!time) {
      return null;
    }

    const values = value ?? timeseriesTextFieldHandler(objectStore, fieldId).getValueResolution(dimensionsMapping, undefined, { from: time, to: time }, true).value;

    return (
      <TimeseriesTextFieldTextInput
        onSubmit={onSubmit}
        fieldId={fieldId}
        dimensionsMapping={dimensionsMapping}
        time={time}
        value={values?.find(({ time: t }) => t === time)?.value ?? undefined}
        readOnly={readOnly}
      />
    );
  },
  renderExportConfiguration: () => ({ configuration, onChange }) => (<TimeseriesExportConfigurationInput configuration={configuration} onChange={onChange} />),
  getComparatorHandler: (_, __, { getValueResolution }) => (direction) => ({
    comparator: comparing(compareString, direction === TableSortDirection.desc),
    extractValue: (dimensionsMapping, time) => {
      if (!time || !isFiniteNumber(time)) {
        return undefined;
      }
      const { value } = getValueResolution(dimensionsMapping, undefined, { from: time, to: time }, true);
      return value?.find(({ time: t }) => time === t)?.value ?? undefined;
    },
  }) satisfies FieldComparatorHandler<string | undefined>,
  input: (store, fieldId, handler) => ({
    render: ({ value, onSubmit, focusOnMount, readOnly, onEditionStart, onEditionStop, isEditing }) => {
      const field = store.getObject(fieldId);
      const isComputed = Boolean(field[Field_Formula]);
      const extractedTime = value && value[0].time;

      return (
        <SimpleInput<TimeseriesFieldValue<string>>
          initialValue={value}
          onSubmit={onSubmit}
        >
          {(props) => (
            <TextInputString
              value={props?.value?.[0].value || undefined}
              onSubmit={(newValue) => (extractedTime ? onSubmit([{ time: extractedTime, value: newValue }]) : undefined)}
              onChange={(newValue) => (extractedTime ? props.onChange([{ time: extractedTime, value: newValue }]) : undefined)}
              onCancel={props.onCancel}
              onEditionStart={onEditionStart}
              onEditionStop={onEditionStop}
              isEditing={isEditing}
              focusOnMount={focusOnMount}
              readOnly={isComputed || readOnly}
              dropdownMaxLine={1}
              placeholder={i18n`Add text`}
            />
          )}
        </SimpleInput>
      );
    },
    getInitialState: () => undefined,
    persistStateOnConcept: (dimension, value) => (value ? handler.updateValue(dimension, { type: 'value', time: value[0].time, value: value[0].value ?? null }) : undefined),
  }),
  blockDisplayOptionsHandler: (objectStore) => (fieldDisplayBlockId) => ({
    getDisplayOptions: () => getDisplayOptions(objectStore, fieldDisplayBlockId),
    renderSummary: (state) => [getOverridableDisplayTypeLabel(state.displayType)],
    getBlockEditionOptionSections: (state, setState) => [
      {
        key: 'displayType',
        title: i18n`Display type`,
        action: {
          type: EditionOptionTypes.select,
          props: {
            computeOptions: () => getOverridableDisplayTypeOptions(),
            selectedOption: getOptionalOverridableDisplayTypeOption(state.displayType),
            onChange: (selectedOption) => {
              if (selectedOption) {
                setState(joinObjects(state, { displayType: selectedOption.id as OverridableDisplayType }));
              }
            },
          },
        },
        options: [],
      },
    ],
    onSubmit: (state) => {
      objectStore.updateObject(fieldDisplayBlockId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
});
