import type { FunctionComponent, ReactNode } from 'react';
import { Modal } from 'react-overlays';
import base, { Opacity } from '../../theme/base';
import { colorWithAlpha } from '../../theme/colorUtils';
import { spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { IconName } from '../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../atoms/IconOnlyButton';
import Typo, { TypoVariant } from '../atoms/Typo';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 999, // aka between backdrop and tooltip layers
    width: '96rem',
    height: '55rem',
    overflow: 'hidden',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: base.borderRadius.medium,
    backgroundColor: theme.color.background.neutral.default,
  },
  title: {
    paddingLeft: spacingRem.m,
    flexGrow: 1,
  },
  headerBar: {
    paddingLeft: spacingRem.m,
    paddingRight: spacingRem.m,
    paddingBottom: spacingRem.l,
    paddingTop: spacingRem.l,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'sticky',
    top: '0rem',
    backgroundColor: theme.color.background.neutral.default,
    alignItems: 'center',
  },
  scrollContainer: {
    flexGrow: 1,
    overflowY: 'scroll',
    paddingRight: spacingRem.m,
  },
  imageModal: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 999, // aka between backdrop and tooltip layers
    width: 'auto',
    height: 'auto',
    overflow: 'hidden',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: base.borderRadius.medium,
    boxShadow: base.shadowElevation.high,
    backgroundColor: theme.color.background.neutral.default,
  },
  imageContainer: {
    padding: spacingRem.modalSpacing,
    maxHeight: '85vh',
  },
  backdrop: {
    position: 'fixed',
    zIndex: 998, // aka over content below modal and tooltip layers
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: colorWithAlpha(theme.color.background.neutral.dark, Opacity.fifty),
  },
}), 'screenModal');

interface ScreenModalProps {
  title?: string,
  open: boolean,
  hide: () => void,
  onBackdropClick?: () => void,
  render: () => ReactNode,
  isImageModal?: boolean,
}

const ScreenModal: FunctionComponent<ScreenModalProps> = ({ open, hide, onBackdropClick, title, render, isImageModal }) => {
  const classes = useStyles();
  return (
    <Modal
      show={open}
      className={isImageModal ? classes.imageModal : classes.modal}
      renderBackdrop={(props) => (<div className={classes.backdrop} {...props} />)}
      onEscapeKeyDown={() => hide()}
      onBackdropClick={onBackdropClick}
    >
      <>
        <div className={classes.headerBar}>
          {title ? (
            <div className={classes.title}>
              <Typo variant={TypoVariant.blockPrimaryTitle}>{title}</Typo>
            </div>
          ) : (<span />)}
          <IconOnlyButton
            iconName={IconName.close}
            tooltip={i18n`Close`}
            variant={IconOnlyButtonVariants.tertiary}
            onClick={hide}
          />
        </div>
        <div className={isImageModal ? classes.imageContainer : classes.scrollContainer}>
          {render()}
        </div>
      </>
    </Modal>
  );
};

export default ScreenModal;
