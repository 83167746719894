import type { FunctionComponent, ReactElement } from 'react';
import { createContext, useContext } from 'react';
import type { AccessControlListLibrary, ObjectDebugLabelLibrary, ObjectStore, TimeseriesStore } from 'yooi-store';
import { newError } from 'yooi-utils';
import type { GlobalObserver } from '../utils/globalObserver';
import type { ActivityStore } from './activityStore';
import type { AttachmentStore } from './attachmentStore';
import type { Activity } from './networkEventClient';

export interface StoreContext {
  clientId: string,
  connect: () => {
    disconnect: () => void,
  },
  data: Omit<ObjectStore, 'flush'> & {
    globalObservers: GlobalObserver<never>,
    accessControlListLibrary: AccessControlListLibrary,
    objectDebugLabelLibrary: ObjectDebugLabelLibrary,
    getConnectionDate: () => (number | undefined),
  },
  attachment: AttachmentStore,
  activity: {
    listViewer: ActivityStore['listViewer'],
    listEditor: ActivityStore['listEditor'],
    updateActivity: (activity: Activity) => void,
    globalObservers: GlobalObserver<never>,
    connectionObservers: GlobalObserver<never>,
    onView: (objectId: string | null) => void,
    onEnterEdition: (objectId: string, fieldId: string) => void,
    onExitEdition: (objectId: string, fieldId: string) => void,
  },
  timeseries: TimeseriesStore,
  close: () => void,
  hasUnsavedChanges: () => boolean,
}

const Context = createContext<StoreContext | undefined>(undefined);

interface StoreContextProviderProps {
  storeContext: StoreContext | undefined,
  children: ReactElement,
}

export const StoreContextProvider: FunctionComponent<StoreContextProviderProps> = ({ storeContext, children }) => (
  <Context.Provider value={storeContext}>
    {children}
  </Context.Provider>
);

const useStoreContext = (): StoreContext => {
  const storeContext = useContext(Context);
  if (storeContext === undefined) {
    throw newError('StoreContext has not been initialized, add a StoreContextProvider in the React parent component hierarchy');
  }

  return storeContext;
};

export default useStoreContext;
