import type { FunctionComponent } from 'react';
import { escapeQueryLabel } from 'yooi-modules/modules/automationModule';
import type { MultipleParameterDefinition, PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { compareBoolean, extractAndCompareValue, isFiniteNumber, joinObjects } from 'yooi-utils';
import { ButtonVariant } from '../../../components/atoms/Button';
import { IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import SpacingLine from '../../../components/molecules/SpacingLine';
import DataTable from '../../../components/templates/DataTable';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import StoreTextInputField from '../../_global/input/StoreTextInputField';
import PathMappingAndFiltersInput from '../../_global/path/PathMappingAndFiltersInput';
import PathStepsInput from '../../_global/path/PathStepsInput';
import { createPathConfigurationHandler } from '../../_global/pathConfigurationHandler';
import { shouldDisplayVariableAsPlaceholder } from './automationUtils';
import type { MailActionQuery, MailConstantActionQuery, MailPathActionQuery } from './MailAction';

interface MailAutomationQueryProps {
  query: MailActionQuery,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
  onChange: (value: Record<string, PathStep[]>) => void,
}

interface TriggerParameter {
  id: string,
  actionQuery: MailPathActionQuery | MailConstantActionQuery,
}

const defaultVariableName = i18n`Variable`;

const computeName = (name: string, inputsObject: MailActionQuery): string => {
  const computedName = escapeQueryLabel(name === '' ? defaultVariableName : name);
  if (inputsObject[computedName]) {
    const split = name.split(/(\d+)/);
    if (split.length > 2 && split[split.length - 1] === '' && isFiniteNumber(split[split.length - 2])) {
      return escapeQueryLabel(computeName(`${split.slice(0, split.length - 2).join('')}${parseInt(split[split.length - 2], 10) + 1}`, inputsObject));
    } else {
      return escapeQueryLabel(computeName(`${computedName}1`, inputsObject));
    }
  } else {
    return computedName;
  }
};

const getQueryInputLabel = (
  store: ObjectStoreWithTimeseries,
  path: PathStep[],
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
  inputsObject: MailActionQuery
): string => {
  const pathConfigurationHandler = createPathConfigurationHandler(store, parameterDefinitions ?? []);
  const label = pathConfigurationHandler.getPathSummarize(path) ?? '';
  return computeName(label !== '' ? label : defaultVariableName, inputsObject);
};

const MailAutomationQuery: FunctionComponent<MailAutomationQueryProps> = ({ query, parameterDefinitions, onChange }) => {
  const store = useStore();

  const submitChange = (fullQuery: MailActionQuery) => onChange(
    Object.fromEntries(Object.entries(fullQuery)
      .flatMap(([key, actionQuery]) => (actionQuery.type === 'path' && !actionQuery.readOnly ? [[key, actionQuery.path]] : [])))
  );

  return (
    <DataTable<TriggerParameter>
      fullWidth
      columnsDefinition={[
        {
          name: i18n`Name`,
          propertyId: 'id',
          width: 20,
          cellRender: ({ id, actionQuery }) => {
            if (actionQuery.type === 'path') {
              const { readOnly, path } = actionQuery;
              const placeholder = getQueryInputLabel(store, path, parameterDefinitions, Object.fromEntries(Object.entries(query).filter(([key]) => key !== id)));
              const displayAsPlaceholder = shouldDisplayVariableAsPlaceholder(id, placeholder, defaultVariableName, readOnly);
              return (
                <StoreTextInputField
                  initialValue={displayAsPlaceholder ? undefined : id}
                  placeholder={displayAsPlaceholder ? id : undefined}
                  onSubmit={(newId) => {
                    const newIdWithDefault = computeName(newId ?? '', Object.fromEntries(Object.entries(query).filter(([key]) => key !== id)));
                    const newQuery = Object.fromEntries(Object.entries(query)
                      .map(([oldId, oldActionQuery]) => [oldId === id ? newIdWithDefault : oldId, oldActionQuery]));
                    submitChange(newQuery);
                  }}
                  readOnly={readOnly}
                />
              );
            } else {
              return (
                <StoreTextInputField initialValue={id} readOnly />
              );
            }
          },
        },
        {
          name: i18n`Path`,
          propertyId: 'Path',
          width: 60,
          cellRender: ({ id, actionQuery }) => {
            if (actionQuery.type === 'path') {
              const { readOnly, path } = actionQuery;
              return (
                <PathStepsInput
                  initialPath={path}
                  onSubmit={(newPath) => {
                    const placeholder = getQueryInputLabel(store, path, parameterDefinitions, Object.fromEntries(Object.entries(query).filter(([key]) => key !== id)));
                    const newId = getQueryInputLabel(store, newPath, parameterDefinitions, Object.fromEntries(Object.entries(query).filter(([key]) => key !== id)));
                    const displayAsPlaceholder = shouldDisplayVariableAsPlaceholder(id, placeholder, defaultVariableName, readOnly);
                    const newQuery = Object.fromEntries(Object.entries(query)
                      .map(([oldId, oldActionQuery]) => [oldId === id && displayAsPlaceholder ? newId : oldId, oldId === id
                        ? { type: 'path' as const, path: newPath, readOnly: oldActionQuery.readOnly }
                        : oldActionQuery]));
                    submitChange(newQuery);
                  }}
                  readOnly={readOnly}
                  parameterDefinitions={parameterDefinitions}
                />
              );
            } else {
              return (
                <StoreTextInputField initialValue={undefined} placeholder={actionQuery.helperText} disabled />
              );
            }
          },
        },
        {
          propertyId: 'filters',
          name: i18n`Filters`,
          width: 20,
          cellRender: ({ id, actionQuery }) => {
            if (actionQuery.type === 'path') {
              const { readOnly, path } = actionQuery;
              return (
                <PathMappingAndFiltersInput
                  path={path}
                  onChange={!readOnly ? (newPath) => {
                    submitChange(joinObjects(query, { [id]: { type: 'path' as const, path: newPath, readOnly: false } }));
                  } : undefined}
                  readOnly={readOnly}
                  parameterDefinitions={parameterDefinitions}
                />
              );
            } else {
              return null;
            }
          },
        },
        {
          propertyId: 'Delete',
          action: true,
          cellRender: ({ id, actionQuery }) => (
            <SpacingLine>
              <IconOnlyButton
                disabled={actionQuery.type === 'constant' || actionQuery.readOnly}
                onClick={() => {
                  const newQuery = { ...query };
                  delete newQuery[id];
                  submitChange(newQuery);
                }}
                iconName={IconName.delete}
                tooltip={i18n`Delete`}
                variant={IconOnlyButtonVariants.danger}
              />
            </SpacingLine>
          ),
        },
      ]}
      list={Object.entries(query)
        .sort(extractAndCompareValue(([_, actionQuery]) => actionQuery.readOnly, compareBoolean))
        .map(([key, actionQuery]) => ({ key, type: 'item', item: { id: key, actionQuery }, color: undefined }))}
      newItemIcon={IconName.add}
      newItemTitle={i18n`Add`}
      newItemButtonVariant={ButtonVariant.tertiary}
      onNewItem={() => submitChange(joinObjects(query, { [computeName('', query)]: { type: 'path' as const, path: [], readOnly: false } }))}
    />
  );
};

export default MailAutomationQuery;
