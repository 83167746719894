import classnames from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';
import { useCallback } from 'react';
import { isSafari } from 'react-device-detect';
import type {
  DateFieldStoreObject,
  ParametersMapping,
  RelationSingleFieldStoreObject,
  TimelineFieldStoreObject,
  WorkflowFieldStoreObject,
} from 'yooi-modules/modules/conceptModule';
import {
  dateFieldHandler,
  getConceptChipFields,
  getConceptUrl,
  getFieldDimensionOfModelType,
  getFields,
  getFieldUtilsHandler,
  getInstanceLabel,
  getInstanceLabelOrUndefined,
  getKinshipFieldId,
  getParentConceptInstance,
  GROUP_BY_PARAMETER,
  idFieldHandler,
  isConceptValid,
  isEmbeddedAsIntegrationOnly,
  relationSingleFieldHandler,
  timelineFieldHandler,
  workflowFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import {
  Association,
  Association_Role_Definition,
  Association_Role_Role1TypeInstance,
  Association_Role_Role2TypeInstance,
  AssociationField,
  AssociationField_Definition,
  Concept,
  Concept_FunctionalId,
  Concept_Name,
  ConceptDefinition_Color,
  ConceptDefinition_Timeline,
  ConceptDefinition_TimelineDependency,
  ConceptDefinition_TimelineGroupBy,
  ConceptDefinition_TimelineProgress,
  ConceptFunctionalIdDimension,
  DateField,
  DateField_Rank,
  EmbeddingField,
  RelationMultipleField,
  RelationSingleField,
  TimelineField,
  TimelineField_Period,
  TimelineField_Rank,
  WorkflowField,
} from 'yooi-modules/modules/conceptModule/ids';
import { doExtends, isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { StoreObject } from 'yooi-store';
// eslint-disable-next-line yooi/no-restricted-dependency
import { isStoreObject } from 'yooi-store';
import type { StoredDateObject } from 'yooi-utils';
import {
  addTimeToDate,
  compareRank,
  compareString,
  comparing,
  computeEffectiveRangeForPeriodAndDates,
  DateStorageTypeKeys,
  DurationType,
  extractAndCompareValue,
  filterNullOrUndefined,
  formatForStorage,
  isEqualDateRangeValue,
  joinObjects,
  periodicities,
  PeriodicityType,
  pushUndefinedToEnd,
  ranker,
  subtractTimeFromDate,
} from 'yooi-utils';
import type { TimelineLine, TimelineLineEntry, TimelinePeriod } from '../../../../../components/charts/Timeline';
import Timeline from '../../../../../components/charts/Timeline';
import type { ProgressFieldData } from '../../../../../components/charts/TimelineEntry';
import { TableSortDirection } from '../../../../../components/molecules/Table';
import BlockContent from '../../../../../components/templates/BlockContent';
import VerticalBlock from '../../../../../components/templates/VerticalBlock';
import useAcl from '../../../../../store/useAcl';
import useActivity from '../../../../../store/useActivity';
import type { FrontObjectStore } from '../../../../../store/useStore';
import useStore from '../../../../../store/useStore';
import useUpdateActivity from '../../../../../store/useUpdateActivity';
import { spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import useNavigation from '../../../../../utils/useNavigation';
import { SessionStorageKeys, useMultipleSessionStorageState, useSessionStorageState } from '../../../../../utils/useSessionStorage';
import useTheme from '../../../../../utils/useTheme';
import { getConceptInstanceDisplayField, getConceptProgressValue, resolveConceptColorField, resolveConceptColorValue } from '../../../conceptDisplayUtils';
import type { FilterConfiguration } from '../../../filter/useFilterSessionStorage';
import { getNavigationFilter } from '../../../FrontFilterRenderers';
import { getFieldGroupByHandler } from '../../../groupByUtils';
import { getConceptFilters } from '../../../listFilterFunctions';
import { getColorField, getTimelineDependencyField, getTimelineField, getTimelineGroupByField, getTimelineProgressField } from '../../../modelTypeUtils';
import ActivityIndicator from '../../../multiplayer/ActivityIndicator';
import type { NavigationFilter } from '../../../navigationUtils';
import type { TimelineConfiguration } from '../../../sessionStorageTypes';
import type { UseFilterAndSortReturnType } from '../../../useFilterAndSort';
import type { TimelineViewResolvedDefinition } from '../../../views/timeline/timelineViewHandler';
import { getFieldHandler } from '../../FieldLibrary';
import ConceptBacklog from '../backlog/ConceptBacklog';
import type { TimelineTooltipUpdate } from './ConceptTimelineTooltip';
import ConceptTimelineTooltip from './ConceptTimelineTooltip';
import { getDateFieldValue } from './conceptTimelineUtils';

// This function generates the appropriate range to display on timeline for specific dates from filtering
const periodFromDates = (from: Date | undefined, to: Date | undefined, period: PeriodicityType | undefined): TimelinePeriod => {
  let periodPeriodicity: PeriodicityType = PeriodicityType.month;
  if (period) {
    periodPeriodicity = Object.values(PeriodicityType).includes(period) ? period : PeriodicityType.month;
  }
  let periodBegin = periodicities[periodPeriodicity].getStartOfPeriod(subtractTimeFromDate(new Date(), periodPeriodicity !== PeriodicityType.year ? 3 : 6, DurationType.months));
  let periodEnd = periodicities[periodPeriodicity].getEndOfPeriod(addTimeToDate(new Date(), periodPeriodicity !== PeriodicityType.year ? 3 : 6, DurationType.months));
  if ((from && from.getTime() < 0) || (to && to.getTime() < 0)) {
    return { period: periodPeriodicity, from: new Date(periodBegin.valueOf()), to: new Date(periodEnd.valueOf()) };
  }
  if (from !== undefined && to !== undefined) {
    if (period === undefined) {
      const diffInMonth = periodicities[PeriodicityType.month].getDiffOfDatesOfPeriod(to, from);
      if (diffInMonth > 24) {
        periodPeriodicity = PeriodicityType.year;
      } else if (diffInMonth > 12) {
        periodPeriodicity = PeriodicityType.quarter;
      } else if (diffInMonth > 2) {
        periodPeriodicity = PeriodicityType.month;
      } else {
        periodPeriodicity = PeriodicityType.week;
      }
    }
    periodBegin = periodicities[periodPeriodicity].getStartOfPeriod(from);
    periodEnd = periodicities[periodPeriodicity].getEndOfPeriod(subtractTimeFromDate(to, 1, DurationType.milliseconds));
  } else if (from !== undefined) {
    periodBegin = periodicities[periodPeriodicity].getStartOfPeriod(from);
    periodEnd = periodicities[periodPeriodicity].getEndOfPeriod(
      addTimeToDate(
        subtractTimeFromDate(from, 1, DurationType.milliseconds),
        periodPeriodicity !== PeriodicityType.year ? 6 : 12,
        DurationType.months
      )
    );
  } else if (to !== undefined) {
    periodEnd = periodicities[periodPeriodicity].getEndOfPeriod(to);
    periodBegin = periodicities[periodPeriodicity].getStartOfPeriod(
      subtractTimeFromDate(
        subtractTimeFromDate(to, 1, DurationType.milliseconds),
        periodPeriodicity !== PeriodicityType.year ? 6 : 12,
        DurationType.months
      )
    );
  }
  return { period: periodPeriodicity, from: periodBegin, to: periodEnd };
};

const useStyles = makeStyles({
  timelineContainer: {
    // Relative is for positioning the tooltip, and the flex is to handle the margin-collapsing issue
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  marginTimelineContainer: {
    marginRight: spacingRem.blockRightColumnSpacing,
    marginLeft: spacingRem.pageSpacing,
  },
}, 'conceptTimeline');

const listRelatedFieldWithTargetConceptDefinitions = (store: FrontObjectStore, conceptDefinitionId: string): { fieldId: string, targetConceptDefinitionId: string }[] => (
  getFields(store, conceptDefinitionId, [EmbeddingField, RelationSingleField, AssociationField, RelationMultipleField])
    .map((field) => ({ targetConceptDefinitionId: getFieldUtilsHandler(store, field.id)?.getTargetType?.()?.id, fieldId: field.id }))
    .filter((result): result is { fieldId: string, targetConceptDefinitionId: string } => Boolean(result.targetConceptDefinitionId))
);

const getRelatedInstancesForConcept = (
  store: FrontObjectStore,
  conceptDefinitionId: string,
  conceptId: string,
  targetConceptDefinitionId: string
): StoreObject[] => {
  const fields = listRelatedFieldWithTargetConceptDefinitions(store, conceptDefinitionId)
    .filter(({ targetConceptDefinitionId: id }) => targetConceptDefinitionId === id)
    .map(({ fieldId }) => store.getObject(fieldId));
  const instances: StoreObject[] = [];
  const instance = store.getObjectOrNull(conceptId);
  fields.forEach((field) => {
    const dimension = instance ? getFieldDimensionOfModelType(store, field.id, instance[Instance_Of] as string) : undefined;
    const value = getFieldUtilsHandler(store, field.id).getValueWithoutFormula(dimension ? { [dimension]: conceptId } : {});
    if (Array.isArray(value)) {
      instances.push(...value);
    } else if (isStoreObject(value)) {
      instances.push(value);
    }
  });

  return instances;
};

interface TimelineEntry {
  id: string,
  label: { id?: string, name: string | undefined },
  color: string | undefined,
  rightText: string | undefined,
  progress: ProgressFieldData | undefined,
}

interface ConceptTimelineProps {
  filterKey: string,
  generateList: UseFilterAndSortReturnType<StoreObject>['generateList'],
  conceptDefinitionId: string,
  readOnly?: boolean,
  view?: TimelineViewResolvedDefinition,
  navigationFilters?: NavigationFilter,
}

const ConceptTimeline: FunctionComponent<ConceptTimelineProps> = ({
  filterKey,
  generateList,
  conceptDefinitionId,
  readOnly = false,
  view,
  navigationFilters,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const { canWriteObject } = useAcl();
  const activity = useActivity();

  const navigation = useNavigation<NavigationFilter>();
  const isInView = Boolean(view);

  const filterTimelineConcepts = (conceptId: string, timelineField: StoreObject | null | undefined, timelineConfig?: TimelineConfiguration) => {
    if (!timelineConfig?.showItself) {
      return true;
    }
    const instance = store.getObjectOrNull(conceptId);
    const dimension = instance && timelineField ? getFieldDimensionOfModelType(store, timelineField.id, instance[Instance_Of] as string) : undefined;
    if (timelineField && isInstanceOf<TimelineFieldStoreObject>(timelineField, TimelineField)) {
      const { value } = timelineFieldHandler(store, timelineField.id).getValueResolution(dimension ? { [dimension]: conceptId } : {});
      const { from: startDate, to: endDate, error } = computeEffectiveRangeForPeriodAndDates(value?.period, value?.from, value?.to);
      return !error && (startDate || endDate);
    } else if (timelineField && isInstanceOf<DateFieldStoreObject>(timelineField, DateField)) {
      const { value } = dateFieldHandler(store, timelineField.id).getValueResolution(dimension ? { [dimension]: conceptId } : {});
      let effectiveRange;
      if (typeof value === 'number') {
        effectiveRange = computeEffectiveRangeForPeriodAndDates(undefined, value);
      } else {
        effectiveRange = computeEffectiveRangeForPeriodAndDates(value?.period, value?.date);
      }
      return !effectiveRange.error && (effectiveRange.from || effectiveRange.to);
    }
    return false;
  };

  const updateActivity = useUpdateActivity();

  const initialTimelineConfig: TimelineConfiguration = {
    [ConceptDefinition_Color]: undefined,
    [ConceptDefinition_Timeline]: undefined,
    [ConceptDefinition_TimelineProgress]: undefined,
    [ConceptDefinition_TimelineGroupBy]: undefined,
    [ConceptDefinition_TimelineDependency]: undefined,
    children: [],
    dependencies: false,
    showItself: true,
    timelineRange: {
      from: {
        type: DateStorageTypeKeys.date,
        value: formatForStorage(periodicities[PeriodicityType.month].getPreviousDateInAmountOfPeriod(new Date(), 6)) ?? undefined,
      },
      to: {
        type: DateStorageTypeKeys.date,
        value: formatForStorage(addTimeToDate(periodicities[PeriodicityType.month].getNextDateInAmountOfPeriod(new Date(), 6), 1, DurationType.milliseconds)) ?? undefined,
      },
      period: PeriodicityType.month,
    },
  };

  const [filtersConfiguration] = useSessionStorageState<FilterConfiguration | undefined>(filterKey, undefined);

  const [timelineConfig] = useSessionStorageState<TimelineConfiguration>(
    `${SessionStorageKeys.timelineConfig}_${filterKey}`,
    initialTimelineConfig,
    true,
    (config) => !config.children
  );

  const [childTimelineConfig] = useMultipleSessionStorageState<TimelineConfiguration>(
    timelineConfig.children?.map((child: string) => `${SessionStorageKeys.timelineConfig}_${child}`),
    initialTimelineConfig,
    true,
    (config) => !config.children
  );

  const getRightText = (currentConceptDefinitionId: string, conceptId: string, relatedTimelineConfig: TimelineConfiguration): string => {
    const colorField = resolveConceptColorField(store, conceptId, relatedTimelineConfig, view?.defaultTimelineColorFieldId, isInView);
    if (colorField) {
      const dimensionId = getFieldDimensionOfModelType(store, colorField.id, currentConceptDefinitionId);
      if (dimensionId) {
        if (isInstanceOf<RelationSingleFieldStoreObject>(colorField, RelationSingleField)) {
          const targetedConcept = relationSingleFieldHandler(store, colorField.id).getValueResolution({ [dimensionId]: conceptId }).value;
          if (targetedConcept) {
            return getInstanceLabel(store, targetedConcept) ?? '';
          }
        } else if (isInstanceOf<WorkflowFieldStoreObject>(colorField, WorkflowField)) {
          const targetedConcept = workflowFieldHandler(store, colorField.id).getValueResolution({ [dimensionId]: conceptId }).value.value;
          if (targetedConcept) {
            return getInstanceLabel(store, targetedConcept) ?? '';
          }
        }
      }
    }
    return '';
  };

  const getCurrentConfigForInstanceId = useCallback((modelId: string) => {
    if (modelId === conceptDefinitionId) {
      return timelineConfig;
    }
    const json = childTimelineConfig.find(({ key }) => key === `${SessionStorageKeys.timelineConfig}_${modelId}`)?.value;
    return json ? JSON.parse(json) : undefined;
  }, [childTimelineConfig, conceptDefinitionId, timelineConfig]);

  const conceptsFieldOptions = joinObjects(
    {
      [conceptDefinitionId]: {
        colorField: getColorField(store, conceptDefinitionId, timelineConfig, view?.defaultTimelineColorFieldId, isInView),
        timelineField: getTimelineField(store, conceptDefinitionId, timelineConfig, view?.defaultTimelineFieldId, isInView),
        timelineProgressField: getTimelineProgressField(store, conceptDefinitionId, timelineConfig, view?.defaultTimelineProgressFieldId, isInView),
        timelineGroupByField: getTimelineGroupByField(store, conceptDefinitionId, timelineConfig, view?.defaultTimelineGroupByFieldId, isInView),
        timelineDependencyField: getTimelineDependencyField(store, conceptDefinitionId, timelineConfig, view?.defaultTimelineDependencyFieldId, isInView),
      },
    },
    Object.fromEntries(
      timelineConfig.children?.map((child) => {
        const json = childTimelineConfig.find(({ key }) => key === `${SessionStorageKeys.timelineConfig}_${child}`)?.value;
        const childConfig = json ? JSON.parse(json) : undefined;
        return [child, {
          colorField: getColorField(store, child, childConfig, view?.defaultTimelineColorFieldId, isInView),
          timelineField: getTimelineField(store, child, childConfig, view?.defaultTimelineFieldId, isInView),
          timelineProgressField: getTimelineProgressField(store, child, childConfig, view?.defaultTimelineProgressFieldId, isInView),
          timelineGroupByField: getTimelineGroupByField(store, child, childConfig, view?.defaultTimelineGroupByFieldId, isInView),
          timelineDependencyField: getTimelineDependencyField(store, child, childConfig, view?.defaultTimelineDependencyFieldId, isInView),
        }];
      }) ?? []
    )
  );

  const { timelineRange } = timelineConfig;
  const effectiveTimelineRange = computeEffectiveRangeForPeriodAndDates(timelineRange.period, timelineRange.from, timelineRange.to);
  const period = periodFromDates(effectiveTimelineRange.from, effectiveTimelineRange.to, timelineRange.period);

  const timelineFieldRank: string | undefined = conceptsFieldOptions[conceptDefinitionId]?.timelineField?.[TimelineField_Rank] as string | undefined
    ?? conceptsFieldOptions[conceptDefinitionId]?.timelineField?.[DateField_Rank] as string | undefined;

  let partialRanker = ranker.createNextRankGenerator();
  const timeLineRowsInsertRanks = ranker.decorateList(
    store.getObject(conceptDefinitionId)
      .navigateBack(Class_Instances)
      .filter(({ id }) => isConceptValid(store, id))
      .filter((a) => !!a)
      .filter((value, index, self) => !timelineFieldRank || self.findIndex((item) => value[timelineFieldRank] === item[timelineFieldRank]) === index)
      .sort((a, b) => {
        if (!timelineFieldRank) {
          return 0;
        }
        if (!a[timelineFieldRank]) {
          return 1;
        } else if (!b[timelineFieldRank]) {
          return -1;
        }
        return compareRank(a[timelineFieldRank] as string, b[timelineFieldRank] as string);
      }),
    (identity) => {
      if (timelineFieldRank && identity[timelineFieldRank]) {
        partialRanker = ranker.createNextRankGenerator(identity[timelineFieldRank] as string);
        return identity[timelineFieldRank] as string;
      } else {
        return partialRanker();
      }
    }
  );

  const getSelectedTimelineField = (instanceId: string): StoreObject | null | undefined => {
    const modelId = store.getObject(instanceId)[Instance_Of] as string;
    const currentConfig = getCurrentConfigForInstanceId(modelId);
    const defaultTimelineField = conceptsFieldOptions[modelId].timelineField;
    return getConceptInstanceDisplayField(store, instanceId, ConceptDefinition_Timeline, view?.defaultTimelineFieldId, isInView, currentConfig) ?? defaultTimelineField;
  };

  const { list } = generateList();

  const backlogConcepts: TimelineEntry[] = list
    .map(({ item: { id } }) => id)
    .filter((id) => isConceptValid(store, id))
    .filter((id) => !filterTimelineConcepts(id, conceptsFieldOptions[conceptDefinitionId].timelineField, timelineConfig))
    .map((conceptId) => {
      const concept = store.getObject(conceptId);
      const name = getInstanceLabelOrUndefined(store, concept, false);
      const conceptChipField = getConceptChipFields(store, concept);
      let id: string | undefined;
      if (conceptChipField.some((field) => field.id === Concept_FunctionalId)) {
        id = idFieldHandler(store, Concept_FunctionalId).getValueAsText?.({ [ConceptFunctionalIdDimension]: conceptId });
      }

      return {
        id: conceptId,
        label: { id, name },
        progress: getConceptProgressValue(store, conceptId, ConceptDefinition_TimelineProgress, timelineConfig, view?.defaultTimelineProgressFieldId, isInView),
        color: resolveConceptColorValue(store, conceptId, timelineConfig, view?.defaultTimelineColorFieldId, isInView),
        rightText: getRightText(concept[Instance_Of] as string, conceptId, timelineConfig),
      };
    });

  const handleConceptDrop = (droppedConceptId: string, { timelineRow, position }: { timelineRow: string, position: string }): void => {
    const timelineField = getSelectedTimelineField(droppedConceptId);
    if (timelineField) {
      const rankId = timelineField[TimelineField_Rank] as string | undefined ?? timelineField[DateField_Rank] as string | undefined;
      if (rankId) {
        const timelineRowInsertRanks = timeLineRowsInsertRanks.find(({ itemRank }) => itemRank === timelineRow);
        if (timelineRowInsertRanks) {
          if (position === 'top') {
            store.updateObject(droppedConceptId, { [rankId]: timelineRowInsertRanks.insertBeforeRank() });
          } else if (position === 'bottom') {
            store.updateObject(droppedConceptId, { [rankId]: timelineRowInsertRanks.insertAfterRank() });
          } else if (position === 'middle') {
            store.updateObject(droppedConceptId, { [rankId]: timelineRow });
          }
        }
      }
    }
  };

  let comparator = (a: TimelineLine, b: TimelineLine) => compareRank(a.row, b.row);
  const { timelineGroupByField } = conceptsFieldOptions[conceptDefinitionId];
  if (timelineGroupByField && !doExtends(timelineGroupByField, Concept)) {
    const fieldHandler = getFieldHandler(store, timelineGroupByField.id);
    const dimension = getFieldDimensionOfModelType(store, timelineGroupByField.id, conceptDefinitionId);
    if (fieldHandler && dimension) {
      const handler = getFieldGroupByHandler(store, timelineGroupByField.id);
      const extractFieldValue = (line: TimelineLine) => handler?.getGroupKey(store.getObject(line.id));
      const undefinedGroupComparator = extractAndCompareValue(extractFieldValue, pushUndefinedToEnd);
      const defineGroupComparatorHandler = extractAndCompareValue(extractFieldValue, fieldHandler.getComparatorHandler?.(TableSortDirection.asc)?.comparator ?? compareString);
      comparator = comparing(undefinedGroupComparator).thenComparing(defineGroupComparatorHandler).thenComparing(comparator);
    }
  }
  const groupByParametersMapping: ParametersMapping | undefined = timelineGroupByField ? {
    [GROUP_BY_PARAMETER]: {
      type: 'single' as const,
      id: timelineGroupByField.id,
    },
  } : undefined;
  const updatedNavigationFilters: NavigationFilter | undefined = navigationFilters
    ? joinObjects(navigationFilters, { globalParametersMapping: groupByParametersMapping })
    : {
      globalFilters: getConceptFilters(store, conceptDefinitionId, filtersConfiguration),
      globalParametersMapping: groupByParametersMapping,
    };
  const navigateToConcept = (id: string, isEmbedded = false): void => {
    const conceptUrl = getConceptUrl(store, id);
    if (isEmbedded) {
      const instance = store.getObject(id);
      navigation.push(
        id,
        {
          pathname: conceptUrl,
          navigationFilters: getNavigationFilter(
            store,
            getKinshipFieldId(instance),
            instance[Instance_Of] as string,
            getParentConceptInstance(instance)?.id,
            undefined,
            groupByParametersMapping
          ),
        }
      );
    } else {
      navigation.push(id, {
        pathname: conceptUrl,
        navigationFilters: updatedNavigationFilters,
      });
    }
  };

  const renderTooltip = (conceptId: string, editMode: boolean, currentAnchor: HTMLElement, handleClose: () => void, isChild?: boolean): ReactElement | null => {
    const currentConfig = getCurrentConfigForInstanceId(store.getObject(conceptId)[Instance_Of] as string);
    const progressField = getConceptInstanceDisplayField(store, conceptId, ConceptDefinition_TimelineProgress, view?.defaultTimelineProgressFieldId, isInView, currentConfig);
    const colorationField = getConceptInstanceDisplayField(store, conceptId, ConceptDefinition_Color, view?.defaultTimelineColorFieldId, isInView, currentConfig);
    const groupByField = getConceptInstanceDisplayField(store, conceptId, ConceptDefinition_TimelineGroupBy, view?.defaultTimelineGroupByFieldId, isInView, currentConfig);
    const timelineField = getSelectedTimelineField(conceptId);

    const handleSubmit = (idParam: string, data: TimelineTooltipUpdate) => {
      const { coloration, progress, from, to, period: p, groupBy, date } = data;
      const currentTimelineRow = timelineField?.[TimelineField_Rank] ? store.getObject(idParam)[timelineField[TimelineField_Rank] as string] : undefined;

      interface DateData {
        period?: string,
        from?: StoredDateObject,
        to?: StoredDateObject,
        date?: number,
      }

      let dateData: DateData | undefined;
      if (timelineField && isInstanceOf<DateFieldStoreObject>(timelineField, DateField)) {
        dateData = getDateFieldValue(store, idParam, timelineField.id);
      } else if (timelineField && isInstanceOf<TimelineFieldStoreObject>(timelineField, TimelineField)) {
        dateData = timelineField[TimelineField_Period] ? store.getObject(idParam)?.[timelineField[TimelineField_Period]] as DateData | undefined : undefined;
      }

      const currentData: TimelineTooltipUpdate = joinObjects(
        {
          coloration: colorationField?.id ? store.getObject(idParam)?.[colorationField.id] as string | undefined : undefined,
          progress: progressField?.id ? store.getObject(idParam)?.[progressField.id] as number | undefined : undefined,
          groupBy: groupByField?.id ? store.getObject(idParam)?.[groupByField.id] as string | undefined : undefined,
        },
        dateData
      );
      const isEqualValue = (fromValue: TimelineTooltipUpdate, toValue: TimelineTooltipUpdate) => {
        const getDateAreEquals = () => (timelineField && isInstanceOf<TimelineFieldStoreObject>(timelineField, TimelineField)
          ? isEqualDateRangeValue(fromValue, toValue)
          : (fromValue?.date === toValue?.date && fromValue?.period === toValue?.period));
        return (
          (toValue?.coloration === undefined || fromValue?.coloration === toValue?.coloration)
          && (toValue?.progress === undefined || fromValue?.progress === toValue?.progress)
          && (toValue?.groupBy === undefined || fromValue?.groupBy === toValue?.groupBy)
          && getDateAreEquals()
        );
      };
      if (!isEqualValue(currentData, data)) {
        let newDateData: Record<string, unknown> = {};
        if (timelineField && isInstanceOf<DateFieldStoreObject>(timelineField, DateField)) {
          if (!p || !date) {
            newDateData = { [timelineField.id]: null };
          } else {
            newDateData = { [timelineField.id]: { period: p, date } };
          }
        } else if (timelineField && isInstanceOf<TimelineFieldStoreObject>(timelineField, TimelineField)) {
          if (timelineField[TimelineField_Rank]) {
            newDateData[timelineField[TimelineField_Rank]] = currentTimelineRow ?? timeLineRowsInsertRanks.insertAfterLastItemRank();
          }
          if (timelineField[TimelineField_Period]) {
            newDateData[timelineField[TimelineField_Period]] = { from, to, period: p };
          }
        }
        store.updateObject(idParam, joinObjects(
          colorationField ? { [colorationField.id]: coloration } : {},
          progressField ? { [progressField.id]: progress } : {},
          groupByField && (groupByField.id !== colorationField?.id) ? { [groupByField.id]: groupBy } : {},
          newDateData
        ));
      }
    };

    return (
      <ConceptTimelineTooltip
        conceptId={conceptId}
        editMode={editMode && canWriteObject(conceptId) && !readOnly && !isEmbeddedAsIntegrationOnly(store.getObject(conceptId))}
        buttonView={editMode}
        currentAnchor={currentAnchor}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        filterId={!isChild ? filterKey : store.getObject(conceptId)[Instance_Of] as string}
        view={view}
        readOnly={readOnly}
        navigationFilters={updatedNavigationFilters}
      />
    );
  };

  const toTimelineLineEntry = (concept: StoreObject): TimelineLineEntry | undefined => {
    const timelineField = getSelectedTimelineField(concept.id);
    let row: string | undefined;
    if (timelineField?.[TimelineField_Rank] ?? timelineField?.[DateField_Rank]) {
      row = concept[timelineField?.[TimelineField_Rank] as string | undefined ?? timelineField?.[DateField_Rank] as string] as string | undefined;
    }
    if (!row) {
      row = timeLineRowsInsertRanks.find(({ item: { id } }) => id === concept.id)?.itemRank;
    }
    if (!row) {
      return undefined;
    }

    const currentConfig = getCurrentConfigForInstanceId(store.getObject(concept.id)[Instance_Of] as string);

    let currentPeriod = PeriodicityType.day;
    let startDate;
    let endDate;
    let explicitEnd;
    let shouldBeDisplayed = false;

    if (timelineField && isInstanceOf<TimelineFieldStoreObject>(timelineField, TimelineField)) {
      const dimension = getFieldDimensionOfModelType(store, timelineField.id, concept[Instance_Of] as string);
      const { value: timelineValue } = timelineFieldHandler(store, timelineField.id).getValueResolution(dimension ? { [dimension]: concept.id } : {});
      const { from, to } = computeEffectiveRangeForPeriodAndDates(timelineValue?.period, timelineValue?.from, timelineValue?.to);
      currentPeriod = timelineValue?.period ?? PeriodicityType.day;
      startDate = from;
      endDate = to;
      explicitEnd = timelineValue?.to;
      shouldBeDisplayed = timelineValue?.from?.value !== undefined || timelineValue?.to?.value !== undefined;
    } else if (timelineField && isInstanceOf<DateFieldStoreObject>(timelineField, DateField)) {
      const date = getDateFieldValue(store, concept.id, timelineField.id);
      if (!date) {
        return undefined;
      }
      const { from, to } = computeEffectiveRangeForPeriodAndDates(date.period, date.date);
      currentPeriod = date.period;
      startDate = from;
      endDate = to;
      shouldBeDisplayed = Boolean(date.date);
    }

    // should not happen as entries are filtered before calling this
    if (startDate === undefined && endDate === undefined) {
      return undefined;
    }

    const dependencyFieldId = conceptsFieldOptions[conceptDefinitionId].timelineDependencyField?.[AssociationField_Definition] as string | undefined;

    const name = getInstanceLabelOrUndefined(store, concept, false);
    let id: string | undefined;
    const conceptChipField = getConceptChipFields(store, concept);
    if (conceptChipField.some((field) => field.id === Concept_FunctionalId)) {
      id = idFieldHandler(store, Concept_FunctionalId).getValueAsText?.({ [ConceptFunctionalIdDimension]: concept.id });
    }

    return {
      id: concept.id,
      label: { id, name },
      progress: getConceptProgressValue(store, concept.id, ConceptDefinition_TimelineProgress, currentConfig, view?.defaultTimelineProgressFieldId, isInView),
      startDate: startDate ? periodicities[currentPeriod].getStartOfPeriod(startDate) : undefined,
      endDate: explicitEnd && endDate ? periodicities[currentPeriod].getEndOfPeriod(endDate) : periodicities[currentPeriod].getStartOfPeriod(startDate as Date),
      color: resolveConceptColorValue(store, concept.id, currentConfig, view?.defaultTimelineColorFieldId, isInView),
      rightText: getRightText(concept[Instance_Of] as string, concept.id, currentConfig),
      dependingOn: dependencyFieldId ? store.withAssociation(Association)
        .withRole(Association_Role_Definition, dependencyFieldId)
        .withRole(Association_Role_Role2TypeInstance, concept.id).list()
        .map((association) => association.role(Association_Role_Role1TypeInstance)) : undefined,
      shouldBeDisplayed,
      draggable: canWriteObject(concept.id) && !readOnly && !isEmbeddedAsIntegrationOnly(store.getObject(concept.id))
        && !conceptsFieldOptions[conceptDefinitionId].timelineGroupByField?.id,
      row,
    };
  };

  const computeChildren = (value: StoreObject) => {
    if (timelineConfig.children.length > 0 && value[Instance_Of] === conceptDefinitionId) {
      const childrenIds = new Set<string>();
      timelineConfig.children
        .forEach((childConceptDefinitionId) => {
          getRelatedInstancesForConcept(store, conceptDefinitionId, value.id, childConceptDefinitionId)
            .filter(({ id }) => isConceptValid(store, id))
            .filter(({ id }) => filterTimelineConcepts(id, conceptsFieldOptions[childConceptDefinitionId].timelineField))
            .forEach(({ id }) => childrenIds.add(id));
        });

      return Array.from(childrenIds)
        .map((childId) => toTimelineLineEntry(store.getObject(childId)))
        .filter(filterNullOrUndefined)
        .filter((timelineEntry) => timelineEntry.shouldBeDisplayed)
        .filter((timelineEntry) => period.from.getTime() <= timelineEntry.endDate.getTime())
        .filter((timelineEntry) => period.to.getTime() >= (timelineEntry.startDate?.getTime() ?? Number.MIN_SAFE_INTEGER));
    } else {
      return [];
    }
  };

  const toTimelineLine = (concept: StoreObject): TimelineLine | undefined => {
    const lineEntry = toTimelineLineEntry(concept);
    if (!lineEntry) {
      return undefined;
    }

    const groupProps: { groupByFieldValue?: string, groupByFieldValueName?: string, groupByFieldValueColor?: string | undefined } = {};
    if (doExtends(timelineGroupByField, Concept)) {
      groupProps.groupByFieldValue = concept.id;
      groupProps.groupByFieldValueName = getInstanceLabelOrUndefined(store, concept);
      groupProps.groupByFieldValueColor = lineEntry.color ?? undefined;
    } else if (timelineGroupByField) {
      const handler = getFieldGroupByHandler(store, timelineGroupByField.id);
      if (handler) {
        groupProps.groupByFieldValue = handler.getGroupKey(concept);
        groupProps.groupByFieldValueName = groupProps.groupByFieldValue !== undefined ? handler.getGroupLabel(groupProps.groupByFieldValue) : i18n`Undefined`;
        groupProps.groupByFieldValueColor = groupProps.groupByFieldValue !== undefined ? handler.getGroupColor(groupProps.groupByFieldValue) : theme.color.border.hover;
      }
    }

    return joinObjects(
      lineEntry,
      {
        children: computeChildren(concept),
      },
      groupProps
    );
  };

  const timelineEntries = list
    .filter(({ item: { id } }) => filterTimelineConcepts(id, conceptsFieldOptions[conceptDefinitionId].timelineField, timelineConfig))
    .map(({ item: { id } }) => toTimelineLine(store.getObject(id)))
    .filter(filterNullOrUndefined)
    .filter((timelineEntry) => !timelineConfig.showItself || period.from.getTime() <= timelineEntry.endDate?.getTime())
    .filter((timelineEntry) => !timelineConfig.showItself || period.to.getTime() >= (timelineEntry.startDate?.getTime() ?? Number.MIN_SAFE_INTEGER))
    .sort(comparator);

  const timelineEntriesWithoutDuplicate = [...new Set(timelineEntries.map((entry) => entry.groupByFieldValue))];
  const timelineEntriesByGroupByRank = timelineEntriesWithoutDuplicate.map((groupValue) => timelineEntries.filter((entry) => entry.groupByFieldValue === groupValue));

  const nextRank = ranker.createNextRankGenerator();
  const initialAcc: Record<string, TimelineLine[]> = {};
  const timelineEntriesOnlyWithRank = timelineEntriesByGroupByRank.map((entriesGroup) => entriesGroup.filter((entry) => Boolean(entry.row))
    .map((entry) => ({ ...entry }))
    .reduce((accumulator, timelineEntry) => (joinObjects(
      accumulator,
      {
        [timelineEntry.row]: [...(accumulator[timelineEntry.row] ?? []), timelineEntry],
      }
    )), initialAcc));
  let timelineLines: Record<string, TimelineLine[]> = {};
  timelineEntriesOnlyWithRank.forEach((group) => {
    Object.entries(group).forEach(([, value]) => {
      const next = conceptsFieldOptions[conceptDefinitionId].timelineGroupByField?.id ? nextRank() : value[0].row;
      timelineLines = joinObjects(timelineLines, {
        [next]: value.map((val) => (joinObjects(val, {
          row: next,
        }))),
      });
    });
  });

  const multiplayerIndicatorPropertyIds: string[] = [
    Concept_Name,
    ...Object.values(conceptsFieldOptions).map((options) => options.timelineField?.[TimelineField_Rank] as string | undefined),
    ...Object.values(conceptsFieldOptions).map((options) => options.timelineField?.id),
    ...Object.values(conceptsFieldOptions).map((options) => options.colorField?.id),
    ...Object.values(conceptsFieldOptions).map((options) => options.timelineProgressField?.id),
  ].filter(filterNullOrUndefined);

  return (
    <>
      <VerticalBlock asBlockContent compact>
        <BlockContent fullWidth>
          <div
            className={
              classnames({
                [classes.timelineContainer]: true,
                [classes.marginTimelineContainer]: !isInView,
              })
            }
          >
            <Timeline
              lines={timelineLines}
              period={period}
              onElementDrop={isSafari ? undefined : handleConceptDrop}
              showSelf={timelineConfig?.showItself}
              showDependencies={Boolean(conceptsFieldOptions[conceptDefinitionId].timelineDependencyField?.id)}
              isEntryEditedByOtherUser={(id) => multiplayerIndicatorPropertyIds.some((propertyId) => activity.listEditor(id, propertyId).length > 0)}
              activityComponent={({ ids }) => <ActivityIndicator instanceIds={ids} propertyIds={multiplayerIndicatorPropertyIds} />}
              renderTooltip={renderTooltip}
              onDoubleClick={navigateToConcept}
              onEntryDrag={isSafari || conceptsFieldOptions[conceptDefinitionId].timelineGroupByField?.id ? undefined : (id) => {
                const timelineField = getSelectedTimelineField(id);
                if (timelineField?.[TimelineField_Rank]) {
                  updateActivity.onEnterEdition(id, timelineField[TimelineField_Rank] as string);
                }
              }}
              onEntryDragEnd={isSafari || conceptsFieldOptions[conceptDefinitionId].timelineGroupByField?.id ? undefined : (id) => {
                const timelineField = getSelectedTimelineField(id);
                if (timelineField?.[TimelineField_Rank]) {
                  updateActivity.onExitEdition(id, timelineField[TimelineField_Rank] as string);
                }
              }}
            />
          </div>
        </BlockContent>
      </VerticalBlock>
      {
        backlogConcepts.length > 0 && (
          <ConceptBacklog
            title={i18n`Unplanned`}
            list={backlogConcepts}
            renderTooltip={renderTooltip}
            multiplayerPropertyIds={multiplayerIndicatorPropertyIds}
            readOnly={readOnly}
            fullWidth={isInView}
          />
        )
      }
    </>
  );
};

export default ConceptTimeline;
