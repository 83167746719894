import type { FunctionComponent } from 'react';
import { BeatLoader } from 'react-spinners';
import { remToPx } from '../../utils/sizeUtils';
import useSizeContext, { SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';

const InlineLoading: FunctionComponent = () => {
  const theme = useTheme();

  const { sizeVariant } = useSizeContext();

  const sizeInPx = sizeVariant === SizeVariant.small ? remToPx(0.8) : remToPx(1.3);

  return (<BeatLoader cssOverride={{ display: 'inline-flex' }} size={sizeInPx} margin={remToPx(0.3)} color={theme.color.background.primarylight.default} />);
};

export default InlineLoading;
