import type { FunctionComponent } from 'react';
import type { FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { joinObjects } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../components/atoms/Button';
import { IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import Typo from '../../../components/atoms/Typo';
import type { CompositeLine } from '../../../components/molecules/CompositeField';
import CompositeField, { CompositeFieldCloseReasons, DropdownSectionTitleVariants } from '../../../components/molecules/CompositeField';
import SpacingLine from '../../../components/molecules/SpacingLine';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import { getFieldEditionOptionRender } from '../../_global/fields/fieldEditionOptionsUtils';
import type { FieldEditionOption } from '../../_global/fields/FieldEditionOptionType';
import { getFieldConfigurationHandler } from '../../_global/fields/FieldLibrary';
import { FieldEditionOptionMode, FieldEditionVariant } from '../../_global/fields/FieldLibraryTypes';
import { getFieldLabel } from '../../_global/fieldUtils';
import useStoreFieldOverrideEditionHandler from './useStoreFieldOverrideEditionHandler';

interface FieldConfigurationOverrideProps {
  conceptId: string,
  fieldId: string,
  readOnly: boolean,
}

const useStyles = makeStyles({
  inputSpacingContainer: {
    width: '30rem',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  rightActionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}, 'fieldConfigurationOverride');

const FieldConfigurationOverride: FunctionComponent<FieldConfigurationOverrideProps> = ({ conceptId, fieldId, readOnly }) => {
  const classes = useStyles();
  const store = useStore();

  const concept = store.getObject(conceptId);
  const conceptDefinitionId = concept[Instance_Of] as string;
  const overrideEditionHandler = useStoreFieldOverrideEditionHandler(conceptId, fieldId, conceptDefinitionId);

  const fieldConfigurationHandler = getFieldConfigurationHandler(store, fieldId);
  const field = store.getObject<FieldStoreObject>(fieldId);

  const sections = fieldConfigurationHandler.getEditionOptions({
    mode: FieldEditionOptionMode.Override,
    modelTypeId: conceptDefinitionId,
    isEdition: true,
    editionHandler: overrideEditionHandler,
    readOnly,
    variant: FieldEditionVariant.composite,
  });

  const optionsToLines = (options: FieldEditionOption[]) => (
    options.map((option) => ({
      id: option.key,
      title: option.title,
      titleAction: !readOnly && option.hasValue() ? (
        <SizeContextProvider sizeVariant={SizeVariant.small}>
          <IconOnlyButton
            variant={IconOnlyButtonVariants.tertiary}
            iconName={IconName.sync}
            tooltip={i18n`Reset to default`}
            onClick={() => option.clearValue()}
          />
        </SizeContextProvider>
      ) : undefined,
      isVertical: option.isVertical,
      info: option.info,
      render: (() => {
        if (option.title && !option.isVertical && !option.fullWidth) {
          return (
            <div className={classes.inputSpacingContainer}>
              {getFieldEditionOptionRender(option)}
            </div>
          );
        } else {
          return getFieldEditionOptionRender(option);
        }
      })(),
    }))
  );

  return (
    <CompositeField
      getDropdownSectionDefinitions={() => [
        ...sections.map((section) => (joinObjects(
          section,
          {
            id: section.key,
            rightAction: section.type === 'group' ? section.rightActions : undefined,
            lines: (
              section.type === 'section'
                ? optionsToLines(section.options)
                : section.sections.flatMap((subSection): CompositeLine[] => {
                  const lines = optionsToLines(subSection.options);
                  if (subSection.title) {
                    return [
                      {
                        id: `${subSection.key}_title`,
                        title: subSection.title,
                        titleVariant: DropdownSectionTitleVariants.secondary,
                        info: subSection.info,
                        render: (<span className={classes.rightActionContainer}>{subSection.rightActions}</span>),
                      },
                      ...lines,
                    ];
                  } else {
                    return lines;
                  }
                })
            ),
          }
        ))),
      ]}
      dropdownMinWidth="60rem"
      maxBodyHeight="45rem"
      onCloseDropdown={(reason) => {
        if (overrideEditionHandler.hasModification()) {
          const newValues = overrideEditionHandler.reset();
          if (reason === CompositeFieldCloseReasons.validate) {
            fieldConfigurationHandler.submitFieldOverrideUpdate?.(conceptId, newValues);
          }
        }
      }}
      headerLinesRenderers={[
        {
          id: 'title',
          render: (inDropdown) => (
            <div className={classes.title}>
              <SpacingLine>
                <Typo maxLine={1}>{i18n`${getFieldLabel(store, field)} configuration`}</Typo>
              </SpacingLine>
              {
                !readOnly
                && inDropdown
                && sections
                  .flatMap((section) => (section.type === 'section' ? section.options : section.sections.flatMap(({ options }) => options)))
                  .some(({ hasValue }) => hasValue())
                && (
                  <SpacingLine>
                    <SizeContextProvider sizeVariant={SizeVariant.small}>
                      <Button
                        variant={ButtonVariant.tertiary}
                        title={i18n`Reset to default`}
                        onClick={() => (
                          sections
                            .flatMap((section) => (section.type === 'section' ? section.options : section.sections.flatMap(({ options }) => options)))
                            .forEach(({ clearValue }) => clearValue())
                        )}
                      />
                    </SizeContextProvider>
                  </SpacingLine>
                )
              }
            </div>
          ),
        },
      ]}
    />
  );
};

export default FieldConfigurationOverride;
