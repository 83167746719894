import type { FunctionComponent } from 'react';
import base from '../../theme/base';
import makeStyles from '../../utils/makeStyles';

const useStyles = makeStyles({
  colorSpan: {
    width: '1.2rem',
    minWidth: '1.2rem',
    maxWidth: '1.2rem',
    height: '1.2rem',
    minHeight: '1.2rem',
    maxHeight: '1.2rem',
    borderRadius: base.borderRadius.medium,
  },
}, 'colorChip');

interface ColorChipProps {
  color: string | undefined,
}

const ColorChip: FunctionComponent<ColorChipProps> = ({ color }) => {
  const classes = useStyles();

  if (color) {
    return (<span className={classes.colorSpan} style={{ background: color }} />);
  } else {
    return null;
  }
};

export default ColorChip;
