import type { OpenAPIV3 } from 'openapi-types';
import { equals } from 'ramda';
import type { ObjectStoreWithAssociation, ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import { isStoreObject } from 'yooi-store';
import { arrayOf, createAutoProvisioningMap, filterNullOrUndefined, isRichText, joinObjects, newError, richTextToText } from 'yooi-utils';
import { getCollaborationAllUserRecipientsIds, getCollaborationGroupRecipientsIds, getCollaborationRoleRecipientsIds } from '../../collaborationModule';
import { CommonAsType } from '../../common/fields/commonPropertyType';
import type { GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import { isInstanceOf } from '../../typeModule';
import { Class_Instances, Instance_Of } from '../../typeModule/ids';
import { formatOrUndef } from '../common/commonFieldUtils';
import {
  Concept_Name,
  Concept_StakeholdersGroups,
  Concept_StakeholdersRoles,
  Concept_StakeholdersUsers,
  ConceptDefinition_Roles,
  ConceptRole,
  ConceptRole_ConceptDefinition,
  ConceptRole_ForCollaboration,
  ConceptRoleGroupAssignation,
  ConceptRoleGroupAssignation_Role_Concept,
  ConceptRoleGroupAssignation_Role_ConceptRole,
  ConceptRoleGroupAssignation_Role_Group,
  ConceptRoleUserAssignation,
  ConceptRoleUserAssignation_Role_Concept,
  ConceptRoleUserAssignation_Role_ConceptRole,
  ConceptRoleUserAssignation_Role_User,
  ConceptStakeholdersGroupsDimension,
  ConceptStakeholdersUsersDimension,
  Group,
  Group_Users,
  GroupMembershipDimension,
  StakeholdersField_TargetType,
  User,
} from '../ids';
import type { ConceptStoreObject } from '../model';
import { registerField } from '../module';
import type { Filters, PathStep } from '../moduleType';
import { PathStepType } from '../moduleType';
import type { ConceptReference, DimensionsMapping, InstanceReferenceValue, MultipleParameterValue, ParametersMapping, ResolutionStack, SingleParameterValue } from '../utils';
import {
  conceptRefApiSchema,
  createValuePathResolver,
  FILTER_PARAMETER_CURRENT,
  getConceptInstanceProxy,
  getFieldDimensionOfModelType,
  getFieldUtilsHandler,
  getFilterFunction,
  getInstanceLabel,
  getMultipleRelationFieldExportColumnHeaders,
  getOpenConceptCollaborationIds,
  handleProxyArrayProps,
  InstanceReferenceType,
  isMultiValueResolution,
  isSingleValueResolution,
  isValidValuePathResolution,
  isValueResolutionOfType,
  ParsedDimensionType,
  parseDimensionMapping,
  resolveFieldValue,
  segregateParametersMapping,
  toConceptReference,
} from '../utils';
import { conceptType } from '../utils/formula/modelFunctions';
import type { MultipleRelationFieldExportConfiguration } from '../utils/relationFieldUtils';
import type { StakeholdersField } from './types';

export interface StakeholdersFieldValueResolution extends StoreObject {
  [User]?: string[],
  [Group]?: string[],
  [ConceptRole_ForCollaboration]?: string[],
}

interface StakeholdersGetFieldValueProps {
  roleId: string | undefined,
  conceptInstanceId: string | undefined,
}

const getUsers = (objectStore: ObjectStoreWithTimeseries, conceptInstanceId: string, roleIds: string[], resolutionStack: ResolutionStack): ConceptStoreObject[] => {
  const conceptInstance = objectStore.getObject(conceptInstanceId);
  const allConceptRoles = objectStore.getObject(conceptInstance[Instance_Of] as string).navigateBack(ConceptDefinition_Roles);
  const hasRoleForCollaborator = allConceptRoles.some((r) => r[ConceptRole_ForCollaboration]);
  const hasSpecificRoleForCollaborator = (roleId: string) => allConceptRoles.some((r) => r.id === roleId && r[ConceptRole_ForCollaboration]);
  const userIdsSet = new Set<string>();

  if (roleIds.length === 0) {
    // Directly assigned users
    objectStore.withAssociation(ConceptRoleUserAssignation)
      .withRole(ConceptRoleUserAssignation_Role_Concept, conceptInstanceId)
      .list()
      .forEach((assoc) => {
        userIdsSet.add(assoc.role(ConceptRoleUserAssignation_Role_User));
      });
    // Group assigned users
    objectStore.withAssociation(ConceptRoleGroupAssignation)
      .withRole(ConceptRoleGroupAssignation_Role_Concept, conceptInstanceId)
      .list()
      .forEach((assoc) => {
        (getFieldUtilsHandler(objectStore, Group_Users)
          .getValueWithoutFormula({ [GroupMembershipDimension]: assoc.role(ConceptRoleGroupAssignation_Role_Group) }, resolutionStack) as StoreObject[])
          .forEach(({ id }) => userIdsSet.add(id));
      });
    // Collaboration users
    if (hasRoleForCollaborator) {
      getOpenConceptCollaborationIds(objectStore, conceptInstanceId)
        .flatMap((collaborationId) => getCollaborationAllUserRecipientsIds(objectStore, collaborationId))
        .forEach((userId) => userIdsSet.add(userId));
    }
  } else {
    roleIds.forEach((roleId) => {
      // Directly assigned users
      objectStore.withAssociation(ConceptRoleUserAssignation)
        .withRole(ConceptRoleUserAssignation_Role_Concept, conceptInstanceId)
        .withRole(ConceptRoleUserAssignation_Role_ConceptRole, roleId)
        .list()
        .forEach((assoc) => {
          userIdsSet.add(assoc.role(ConceptRoleUserAssignation_Role_User));
        });
      // Group assigned users
      objectStore.withAssociation(ConceptRoleGroupAssignation)
        .withRole(ConceptRoleGroupAssignation_Role_Concept, conceptInstanceId)
        .withRole(ConceptRoleGroupAssignation_Role_ConceptRole, roleId)
        .list()
        .forEach((assoc) => {
          (getFieldUtilsHandler(objectStore, Group_Users)
            .getValueWithoutFormula({ [GroupMembershipDimension]: assoc.role(ConceptRoleGroupAssignation_Role_Group) }, resolutionStack) as StoreObject[])
            .forEach(({ id }) => userIdsSet.add(id));
        });
    });
    // Collaboration users
    if (roleIds.some((roleId) => hasSpecificRoleForCollaborator(roleId))) {
      getOpenConceptCollaborationIds(objectStore, conceptInstanceId)
        .flatMap((collaborationId) => getCollaborationAllUserRecipientsIds(objectStore, collaborationId))
        .forEach((userId) => userIdsSet.add(userId));
    }
  }
  return [...userIdsSet].map((id) => objectStore.getObjectOrNull<ConceptStoreObject>(id)).filter(filterNullOrUndefined);
};

const getGroups = (objectStore: ObjectStoreWithTimeseries, conceptInstanceId: string, roleIds: string[]): ConceptStoreObject[] => {
  const conceptInstance = objectStore.getObject(conceptInstanceId);
  const allConceptRoles = objectStore.getObject(conceptInstance[Instance_Of] as string).navigateBack(ConceptDefinition_Roles);
  const hasRoleForCollaborator = allConceptRoles.some((r) => r[ConceptRole_ForCollaboration]);
  const hasSpecificRoleForCollaborator = (roleId: string) => allConceptRoles.some((r) => r.id === roleId && r[ConceptRole_ForCollaboration]);
  const groupIdsSet = new Set<string>();

  if (roleIds.length === 0) {
    // Directly assigned groups
    objectStore.withAssociation(ConceptRoleGroupAssignation)
      .withRole(ConceptRoleGroupAssignation_Role_Concept, conceptInstanceId)
      .list()
      .forEach((assoc) => groupIdsSet.add(assoc.role(ConceptRoleGroupAssignation_Role_Group)));
    // Collaboration users
    if (hasRoleForCollaborator) {
      getOpenConceptCollaborationIds(objectStore, conceptInstanceId)
        .flatMap((collaborationId) => getCollaborationGroupRecipientsIds(objectStore, collaborationId))
        .forEach((groupId) => groupIdsSet.add(groupId));
    }
  } else {
    roleIds.forEach((roleId) => {
      // Directly assigned groups
      objectStore.withAssociation(ConceptRoleGroupAssignation)
        .withRole(ConceptRoleGroupAssignation_Role_Concept, conceptInstanceId)
        .withRole(ConceptRoleGroupAssignation_Role_ConceptRole, roleId)
        .list()
        .forEach((assoc) => groupIdsSet.add(assoc.role(ConceptRoleGroupAssignation_Role_Group)));
    });
    // Collaboration users
    if (roleIds.some((roleId) => hasSpecificRoleForCollaborator(roleId))) {
      getOpenConceptCollaborationIds(objectStore, conceptInstanceId)
        .flatMap((collaborationId) => getCollaborationGroupRecipientsIds(objectStore, collaborationId))
        .forEach((groupId) => groupIdsSet.add(groupId));
    }
  }
  return [...groupIdsSet].map((id) => objectStore.getObjectOrNull<ConceptStoreObject>(id)).filter(filterNullOrUndefined);
};

const getRoles = (objectStore: ObjectStoreWithTimeseries, conceptInstanceId: string): ConceptStoreObject[] => {
  const conceptInstance = objectStore.getObject(conceptInstanceId);
  return objectStore.getObject(conceptInstance[Instance_Of] as string).navigateBack<ConceptStoreObject>(ConceptDefinition_Roles);
};

const getFieldValue = (objectStore: ObjectStoreWithTimeseries, fieldId: string, { roleId, conceptInstanceId }: StakeholdersGetFieldValueProps) => {
  const field = objectStore.getObject(fieldId);
  const conceptInstance = conceptInstanceId ? objectStore.getObject(conceptInstanceId) : undefined;
  const role = roleId ? objectStore.getObject(roleId) : undefined;

  const roleConceptDefinition = role ? role[ConceptRole_ConceptDefinition] : undefined;
  const allConceptRoles = conceptInstance && conceptInstance[Instance_Of] ? objectStore.getObject(conceptInstance[Instance_Of] as string)
    .navigateBack(ConceptDefinition_Roles) : undefined;

  const roleMapping = createAutoProvisioningMap<string, { [User]: Set<string>, [Group]: Set<string>, [ConceptRole_ForCollaboration]: Set<string> }>();
  let freeDimension: number | undefined;
  let uniqueDimension: number | undefined;
  if (conceptInstance === undefined) {
    uniqueDimension = 1; // ConceptRoleUserAssignation_Role_ConceptRole or ConceptRoleGroupAssignation_Role_ConceptRole
    freeDimension = 0; // ConceptRoleUserAssignation_Role_Concept or ConceptRoleGroupAssignation_Role_Concept
  } else if (role === undefined) {
    uniqueDimension = 0;
    freeDimension = 1;
  } else {
    uniqueDimension = undefined;
    freeDimension = undefined;
  }

  const filterAssoc = (assoc: ObjectStoreWithAssociation) => {
    if (uniqueDimension !== undefined) {
      return assoc.withRole(uniqueDimension, roleId ?? conceptInstanceId as string);
    } else {
      return assoc.withRole(ConceptRoleUserAssignation_Role_Concept, conceptInstanceId as string)
        .withRole(ConceptRoleUserAssignation_Role_ConceptRole, roleId as string);
    }
  };

  const getOrCreateRoleMapping = (id: string) => roleMapping.getOrCreate(id, () => ({
    [User]: new Set<string>(),
    [Group]: new Set<string>(),
    [ConceptRole_ForCollaboration]: new Set<string>(),
  }));

  if (field[StakeholdersField_TargetType] === User) {
    // Directly assigned users
    filterAssoc(objectStore.withAssociation(ConceptRoleUserAssignation))
      .list()
      .forEach((assoc) => {
        const instanceId = freeDimension !== undefined ? assoc.role(freeDimension) : conceptInstanceId;
        if (instanceId) {
          getOrCreateRoleMapping(assoc.role(ConceptRoleUserAssignation_Role_User))[User].add(instanceId);
        }
      });

    // Group assigned users
    filterAssoc(objectStore.withAssociation(ConceptRoleGroupAssignation))
      .list()
      .forEach((assoc) => {
        (getFieldUtilsHandler(objectStore, Group_Users)
          .getValueWithoutFormula({ [GroupMembershipDimension]: assoc.role(ConceptRoleGroupAssignation_Role_Group) }) as StoreObject[])
          .map((user) => user.id)
          .forEach((userId) => {
            const instanceId = freeDimension !== undefined ? assoc.role(freeDimension) : conceptInstanceId;
            if (instanceId) {
              getOrCreateRoleMapping(userId)[Group].add(instanceId);
            }
          });
      });

    // Collaboration users
    if (uniqueDimension !== undefined && role && role[ConceptRole_ForCollaboration]) {
      objectStore.getObject(roleConceptDefinition as string)
        .navigateBack(Class_Instances)
        .forEach(({ id: instanceId }) => {
          getOpenConceptCollaborationIds(objectStore, instanceId)
            .flatMap((collaborationId) => getCollaborationAllUserRecipientsIds(objectStore, collaborationId))
            .forEach((userId) => getOrCreateRoleMapping(userId)[ConceptRole_ForCollaboration].add(instanceId));
        });
    } else if (uniqueDimension !== undefined && conceptInstanceId) {
      getOpenConceptCollaborationIds(objectStore, conceptInstanceId)
        .flatMap((collaborationId) => getCollaborationAllUserRecipientsIds(objectStore, collaborationId))
        .forEach((userId) => {
          allConceptRoles?.forEach((r) => {
            if (r[ConceptRole_ForCollaboration]) {
              getOrCreateRoleMapping(userId)[ConceptRole_ForCollaboration].add(r.id);
            }
          });
        });
    } else if (uniqueDimension === undefined && conceptInstanceId && role && role[ConceptRole_ForCollaboration]) {
      getOpenConceptCollaborationIds(objectStore, conceptInstanceId)
        .flatMap((collaborationId) => getCollaborationAllUserRecipientsIds(objectStore, collaborationId))
        .forEach((userId) => getOrCreateRoleMapping(userId)[ConceptRole_ForCollaboration].add(conceptInstanceId));
    }
  } else if (field[StakeholdersField_TargetType] === Group) {
    filterAssoc(objectStore.withAssociation(ConceptRoleGroupAssignation))
      .list()
      .forEach((assoc) => {
        const instanceId = freeDimension !== undefined ? assoc.role(freeDimension) : conceptInstanceId;
        if (instanceId) {
          const groupId = assoc.role(ConceptRoleGroupAssignation_Role_Group);
          getOrCreateRoleMapping(groupId)[Group].add(instanceId);
        }
      });

    // Collaboration groups
    if (uniqueDimension !== undefined && role && role[ConceptRole_ForCollaboration]) {
      objectStore.getObject(roleConceptDefinition as string)
        .navigateBack(Class_Instances)
        .forEach(({ id: instanceId }) => {
          getOpenConceptCollaborationIds(objectStore, instanceId)
            .flatMap((collaborationId) => getCollaborationGroupRecipientsIds(objectStore, collaborationId))
            .forEach((groupId) => {
              getOrCreateRoleMapping(groupId)[ConceptRole_ForCollaboration].add(instanceId);
            });
        });
    } else if (uniqueDimension !== undefined && conceptInstanceId) {
      getOpenConceptCollaborationIds(objectStore, conceptInstanceId)
        .flatMap((collaborationId) => getCollaborationGroupRecipientsIds(objectStore, collaborationId))
        .forEach((groupId) => {
          allConceptRoles?.forEach((r) => {
            if (r[ConceptRole_ForCollaboration]) {
              getOrCreateRoleMapping(groupId)[ConceptRole_ForCollaboration].add(r.id);
            }
          });
        });
    } else if (uniqueDimension === undefined && conceptInstanceId && role && role[ConceptRole_ForCollaboration]) {
      getOpenConceptCollaborationIds(objectStore, conceptInstanceId)
        .flatMap((collaborationId) => getCollaborationGroupRecipientsIds(objectStore, collaborationId))
        .forEach((groupId) => getOrCreateRoleMapping(groupId)[ConceptRole_ForCollaboration].add(conceptInstanceId));
    }
  } else if (conceptInstanceId && field[StakeholdersField_TargetType] === ConceptRole) {
    allConceptRoles?.forEach((r) => getOrCreateRoleMapping(r.id));

    objectStore.withAssociation(ConceptRoleUserAssignation)
      .withRole(ConceptRoleUserAssignation_Role_Concept, conceptInstanceId)
      .list()
      .forEach((assoc) => getOrCreateRoleMapping(assoc.role(ConceptRoleUserAssignation_Role_ConceptRole))[User].add(assoc.role(ConceptRoleUserAssignation_Role_User)));

    objectStore.withAssociation(ConceptRoleGroupAssignation)
      .withRole(ConceptRoleGroupAssignation_Role_Concept, conceptInstanceId)
      .list()
      .forEach((assoc) => getOrCreateRoleMapping(assoc.role(ConceptRoleGroupAssignation_Role_ConceptRole))[Group].add(assoc.role(ConceptRoleGroupAssignation_Role_Group)));

    // User assigned by collaboration
    const users = getOpenConceptCollaborationIds(objectStore, conceptInstanceId)
      .flatMap((collaborationId) => getCollaborationAllUserRecipientsIds(objectStore, collaborationId));
    // Group assigned by collaboration
    const groups = getOpenConceptCollaborationIds(objectStore, conceptInstanceId)
      .flatMap((collaborationId) => getCollaborationGroupRecipientsIds(objectStore, collaborationId));
    // roles assigned by collaboration
    const roles = getOpenConceptCollaborationIds(objectStore, conceptInstanceId)
      .flatMap((collaborationId) => getCollaborationRoleRecipientsIds(objectStore, collaborationId));

    allConceptRoles?.forEach((r) => {
      if (r[ConceptRole_ForCollaboration]) {
        const currentRoleMapping = getOrCreateRoleMapping(r.id)[ConceptRole_ForCollaboration];
        users.forEach((userId) => currentRoleMapping.add(userId));
        groups.forEach((groupId) => currentRoleMapping.add(groupId));
        roles.forEach((rId) => currentRoleMapping.add(rId));
      }
    });
  }

  const result: Value = [];
  roleMapping
    .forEach((value, id) => {
      if (objectStore.getObjectOrNull(id) !== null) {
        result.push(new Proxy(objectStore.getObject(id), {
          get(target, prop) {
            switch (prop) {
              case User:
              case Group:
              case ConceptRole_ForCollaboration:
                return Array.from(value[prop]);
              default:
                // Typescript is not able to see that we wont change the type and that it is safe
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return target[prop];
            }
          },
        }));
      }
    });
  return result;
};

const getValueWithoutFormula = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping) => {
  const parsedDimension = parseDimensionMapping(dimensionsMapping);
  if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
    if (isInstanceOf(objectStore.getObject(parsedDimension.objectId), ConceptRole)) {
      return getFieldValue(objectStore, fieldId, { roleId: parsedDimension.objectId, conceptInstanceId: undefined });
    } else {
      return getFieldValue(objectStore, fieldId, { conceptInstanceId: parsedDimension.objectId, roleId: undefined });
    }
  } else {
    return getFieldValue(objectStore, fieldId, {
      roleId: dimensionsMapping.n1InstanceId,
      conceptInstanceId: dimensionsMapping.n2InstanceId,
    });
  }
};

const isStakeholdersFieldValueResolution = (value: unknown): value is StakeholdersFieldValueResolution => {
  if (!isStoreObject(value)) {
    return false;
  } else if (value[User] !== undefined && (!Array.isArray(value[User]) || !(value[User] as unknown[]).every((id) => typeof id === 'string'))) {
    return false;
  } else if (value[Group] !== undefined && (!Array.isArray(value[Group]) || !(value[Group] as unknown[]).every((id) => typeof id === 'string'))) {
    return false;
  } else if (value[ConceptRole_ForCollaboration] !== undefined && (!Array.isArray(value[ConceptRole_ForCollaboration]) || !(value[ConceptRole_ForCollaboration] as unknown[]).every((id) => typeof id === 'string'))) {
    return false;
  } else {
    return true;
  }
};

type Value = StakeholdersFieldValueResolution[];
type StakeholdersFieldAPIFormat = (
  { role: ConceptReference, users: ConceptReference[], groups: ConceptReference[] }
  | { user: ConceptReference, roles: ConceptReference[] }
  | { group: ConceptReference, roles: ConceptReference[] }
  );

type ActionPayload = ({
  roleId?: string,
  type: 'value',
  valueType: 'user' | 'group',
  objectIds: string[],
} | {
  roleId?: string,
  type: 'path',
  valueType: 'user' | 'group',
  path: PathStep[],
})[];

type ClearPayload = undefined | { roleId?: string, from: 'user' | 'group' | 'all' | undefined };

interface StakeholdersUpdateOperationTypes {
  INITIALIZE: ActionPayload,
  ADD: ActionPayload,
  REMOVE: ActionPayload,
  CLEAR: ClearPayload,
}

interface StakeholderAddUpdate {
  action: 'add',
  roleId: string,
  userIds?: string[],
  groupIds?: string[],
}

interface StakeholderRemoveUpdate {
  action: 'remove',
  roleId: string,
  userIds?: string[],
  groupIds?: string[],
}

type StakeholdersUpdate = StakeholderAddUpdate | StakeholderRemoveUpdate;

interface UserFieldStepConfiguration {
  type: 'user',
  anyRole: InstanceReferenceValue[],
  filters?: Filters,
}

interface GroupFieldStepConfiguration {
  type: 'group',
  anyRole: InstanceReferenceValue[],
  filters?: Filters,
}

interface UsersRoleFieldStepConfiguration {
  type: 'usersRole',
  allUsers: InstanceReferenceValue[],
  filters?: Filters,
}

interface GroupsRoleFieldStepConfiguration {
  type: 'groupsRole',
  allGroups: InstanceReferenceValue[],
  filters?: Filters,
}

export type StakeholdersPathStepConfiguration =
  | UserFieldStepConfiguration
  | GroupFieldStepConfiguration
  | GroupsRoleFieldStepConfiguration
  | UsersRoleFieldStepConfiguration;

type StakeholderFieldHandler = GetDslFieldHandler<
  StakeholdersField,
  Value,
  StakeholdersUpdate,
  Value,
  Value,
  StakeholdersFieldAPIFormat[] | undefined,
  undefined,
  StakeholdersUpdateOperationTypes,
  StakeholdersPathStepConfiguration,
  MultipleRelationFieldExportConfiguration
>;
export const stakeholdersFieldHandler: StakeholderFieldHandler = registerField({
  model: {
    label: 'StakeholdersField',
    title: 'Stakeholders',
    withApiAlias: true,
    properties: [
      { label: 'TargetType', as: CommonAsType.string },
    ],
    asPropertyBusinessRules: [],
  },
  handler: (objectStore, fieldId) => {
    const getValueResolution = (dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack) => {
      const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
      if (isValueResolutionOfType(
        valueResolution,
        (value): value is StakeholdersFieldValueResolution[] => Array.isArray(value) && value.every(isStakeholdersFieldValueResolution)
      )) {
        return valueResolution;
      } else {
        return {
          value: [],
          isComputed: valueResolution.isComputed,
          error: valueResolution.error ?? new ResolutionTypeError(['StakeholdersFieldValueResolution[]'], typeof valueResolution.value),
          getDisplayValue: () => [],
          isTimeseries: valueResolution.isTimeseries,
        };
      }
    };

    const getValueAsText = (dimensionsMapping: DimensionsMapping) => (getValueResolution(dimensionsMapping).value ?? [])
      .map((e) => formatOrUndef(getInstanceLabel(objectStore, e))).join(', ');

    const getTargetType = () => objectStore.getObject(fieldId).navigate(StakeholdersField_TargetType);

    return {
      describe: () => ({ hasData: true, returnType: arrayOf(conceptType(getTargetType().id)), timeseriesMode: 'none' }),
      restApi: {
        returnTypeSchema: {
          type: 'array',
          items: {
            oneOf: [{
              type: 'object',
              properties: {
                role: conceptRefApiSchema,
                users: { type: 'array', items: conceptRefApiSchema },
                groups: { type: 'array', items: conceptRefApiSchema },
              },
            }, {
              type: 'object',
              properties: {
                user: conceptRefApiSchema,
                roles: { type: 'array', items: conceptRefApiSchema },
              },
            }, {
              type: 'object',
              properties: {
                group: conceptRefApiSchema,
                roles: { type: 'array', items: conceptRefApiSchema },
              },
            }] as OpenAPIV3.SchemaObject[],
          },
        },
        formatValue: (value) => {
          if (!value) {
            return undefined;
          }
          const result: unknown[] = [];
          value.forEach((v) => {
            const instance = objectStore.getObject(v.id);
            switch (instance[Instance_Of]) {
              case User:
                result.push(toConceptReference(v)
                  ? {
                    user: toConceptReference(v),
                    roles: [
                      ...v[User]?.map((instanceId) => toConceptReference(objectStore.getObject(instanceId))).filter(filterNullOrUndefined) ?? [],
                      ...v[Group]?.map((instanceId) => toConceptReference(objectStore.getObject(instanceId))).filter(filterNullOrUndefined) ?? [],
                      ...v[ConceptRole_ForCollaboration]?.map((instanceId) => toConceptReference(objectStore.getObject(instanceId))).filter(filterNullOrUndefined) ?? [],
                    ],
                  } : undefined);
                break;
              case Group:
                result.push(toConceptReference(v)
                  ? {
                    group: toConceptReference(v),
                    roles: [
                      ...v[User]?.map((instanceId) => toConceptReference(objectStore.getObject(instanceId))).filter(filterNullOrUndefined) ?? [],
                      ...v[Group]?.map((instanceId) => toConceptReference(objectStore.getObject(instanceId))).filter(filterNullOrUndefined) ?? [],
                      ...v[ConceptRole_ForCollaboration]?.map((instanceId) => toConceptReference(objectStore.getObject(instanceId))).filter(filterNullOrUndefined) ?? [],
                    ],
                  } : undefined);
                break;
              default:
                result.push(toConceptReference(v)
                  ? {
                    role: toConceptReference(v),
                    users: [
                      ...v[User]?.map((instanceId) => toConceptReference(objectStore.getObject(instanceId))).filter(filterNullOrUndefined) ?? [],
                      ...v[ConceptRole_ForCollaboration]
                        ?.filter((id) => isInstanceOf(objectStore.getObject(id), User))
                        .map((instanceId) => toConceptReference(objectStore.getObject(instanceId)))
                        .filter(filterNullOrUndefined) ?? [],
                    ],
                    groups: [
                      ...v[Group]?.map((instanceId) => toConceptReference(objectStore.getObject(instanceId))).filter(filterNullOrUndefined) ?? [],
                      ...v[ConceptRole_ForCollaboration]
                        ?.filter((id) => isInstanceOf(objectStore.getObject(id), Group))
                        .map((instanceId) => toConceptReference(objectStore.getObject(instanceId)))
                        .filter(filterNullOrUndefined) ?? [],
                    ],
                  } : undefined);
                break;
            }
          });
          return result.filter(filterNullOrUndefined) as StakeholdersFieldAPIFormat[];
        },
      },
      getValueResolution,
      getStoreValue: (dimensionsMapping) => getValueWithoutFormula(objectStore, fieldId, dimensionsMapping),
      getValueWithoutFormula: (dimensionsMapping) => getValueWithoutFormula(objectStore, fieldId, dimensionsMapping),
      resolvePathStepConfiguration: (configuration) => {
        const getMappingValue = (parametersMapping: ParametersMapping, value: InstanceReferenceValue | undefined): string | undefined => {
          if (value && value.type === InstanceReferenceType.parameter) {
            return parametersMapping[value.id]?.id;
          } else if (value) {
            return value.id;
          }
          return undefined;
        };
        const field = objectStore.getObject(fieldId);
        switch (field[StakeholdersField_TargetType]) {
          case User: {
            if (configuration.type !== 'user') {
              throw newError('Invalid stakeholder field configuration', { fieldId, configuration });
            }
            const resolveValue = (
              dimensionsMapping: DimensionsMapping,
              parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
              resolutionStack: ResolutionStack
            ) => {
              const { singleParametersMapping } = segregateParametersMapping(parametersMapping);
              const parsedDimension = parseDimensionMapping(dimensionsMapping);
              if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
                const users = getUsers(
                  objectStore,
                  parsedDimension.objectId,
                  configuration.anyRole.map((mapping) => getMappingValue(singleParametersMapping, mapping)).filter(filterNullOrUndefined),
                  resolutionStack
                );
                if (configuration.filters) {
                  const filterFunction = getFilterFunction(objectStore, configuration.filters);
                  if (filterFunction) {
                    return users.filter((user) => filterFunction(joinObjects(
                      segregateParametersMapping(parametersMapping).singleParametersMapping,
                      { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: user.id } }
                    )));
                  }
                }
                return users;
              } else {
                throw newError('Invalid stakeholder field dimensions', { fieldId, configuration, dimensionsMapping });
              }
            };
            return {
              hasData: true,
              timeseriesMode: 'none',
              getValueResolutionType: () => arrayOf(conceptType(User)),
              getFieldResolutionDimensions: (dimensionsMappings, parametersMapping) => {
                if (configuration.anyRole.length === 0) {
                  return dimensionsMappings;
                } else if (configuration.anyRole.length === 1) {
                  const { singleParametersMapping } = segregateParametersMapping(parametersMapping);
                  return {
                    n1InstanceId: getMappingValue(singleParametersMapping, configuration.anyRole[0]),
                    n2InstanceId: dimensionsMappings[ConceptStakeholdersUsersDimension],
                  };
                } else {
                  throw newError('Not supported');
                }
              },
              resolveDimension: (dimensionsMappings, parametersMapping, resolutionStack) => (
                { type: 'multiple', instances: resolveValue(dimensionsMappings, parametersMapping, resolutionStack) }
              ),
              resolveValue,
            };
          }
          case ConceptRole: {
            if (configuration.type !== 'usersRole' && configuration.type !== 'groupsRole') {
              throw newError('Invalid stakeholder field configuration, type is not compatible', { fieldId, configuration });
            }
            const resolveValue = (dimensionsMapping: DimensionsMapping, parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>) => {
              const parsedDimension = parseDimensionMapping(dimensionsMapping);
              if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
                const roles = getRoles(objectStore, parsedDimension.objectId);
                if (configuration.filters) {
                  const filterFunction = getFilterFunction(objectStore, configuration.filters);
                  if (filterFunction) {
                    return roles.filter((role) => filterFunction(joinObjects(
                      segregateParametersMapping(parametersMapping).singleParametersMapping,
                      { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: role.id } }
                    )));
                  }
                }
                return roles;
              } else {
                throw newError('Invalid stakeholder field dimensions', { fieldId, configuration, dimensionsMapping });
              }
            };
            return {
              hasData: true,
              timeseriesMode: 'none',
              getValueResolutionType: () => arrayOf(conceptType(ConceptRole)),
              resolveDimension: (dimensionsMappings, parametersMapping) => (
                { type: 'multiple', instances: resolveValue(dimensionsMappings, parametersMapping) }
              ),
              resolveValue,
            };
          }
          case Group: {
            if (configuration.type !== 'group') {
              throw newError('Invalid stakeholder field configuration', { fieldId, configuration });
            }
            const resolveValue = (dimensionsMapping: DimensionsMapping, parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>) => {
              const { singleParametersMapping } = segregateParametersMapping(parametersMapping);
              const parsedDimension = parseDimensionMapping(dimensionsMapping);
              if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
                const groups = getGroups(
                  objectStore,
                  parsedDimension.objectId,
                  configuration.anyRole.map((mapping) => getMappingValue(singleParametersMapping, mapping)).filter(filterNullOrUndefined)
                );
                if (configuration.filters) {
                  const filterFunction = getFilterFunction(objectStore, configuration.filters);
                  if (filterFunction) {
                    return groups.filter((group) => filterFunction(joinObjects(
                      segregateParametersMapping(parametersMapping).singleParametersMapping,
                      { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: group.id } }
                    )));
                  }
                }
                return groups;
              } else {
                throw newError('Invalid stakeholder field dimensions', { fieldId, configuration, dimensionsMapping });
              }
            };
            return {
              hasData: true,
              timeseriesMode: 'none',
              getValueResolutionType: () => arrayOf(conceptType(Group)),
              getFieldResolutionDimensions: (dimensionsMappings, parametersMapping) => {
                if (configuration.anyRole.length === 0) {
                  return dimensionsMappings;
                } else if (configuration.anyRole.length === 1) {
                  const { singleParametersMapping } = segregateParametersMapping(parametersMapping);
                  return {
                    n1InstanceId: getMappingValue(singleParametersMapping, configuration.anyRole[0]),
                    n2InstanceId: dimensionsMappings[ConceptStakeholdersGroupsDimension],
                  };
                } else {
                  throw newError('Not supported');
                }
              },
              resolveDimension: (dimensionsMappings, parametersMapping) => (
                { type: 'multiple', instances: resolveValue(dimensionsMappings, parametersMapping) }
              ),
              resolveValue,
            };
          }
          default:
            throw newError('Invalid stakeholder field configuration', { fieldId, configuration });
        }
      },
      updateValue: (dimensionsMapping, update) => {
        const parsedDimension = parseDimensionMapping(dimensionsMapping);
        const conceptId = parsedDimension.type === ParsedDimensionType.MonoDimensional ? parsedDimension.objectId : parsedDimension.dimensionsMapping.n1InstanceId;
        if (conceptId) {
          switch (update.action) {
            case 'add': {
              update.userIds?.forEach((instanceId) => {
                objectStore.withAssociation(ConceptRoleUserAssignation)
                  .withRole(ConceptRoleUserAssignation_Role_Concept, conceptId)
                  .withRole(ConceptRoleUserAssignation_Role_ConceptRole, update.roleId)
                  .withRole(ConceptRoleUserAssignation_Role_User, instanceId)
                  .updateObject({});
              });
              update.groupIds?.forEach((instanceId) => {
                objectStore.withAssociation(ConceptRoleGroupAssignation)
                  .withRole(ConceptRoleGroupAssignation_Role_Concept, conceptId)
                  .withRole(ConceptRoleGroupAssignation_Role_ConceptRole, update.roleId)
                  .withRole(ConceptRoleGroupAssignation_Role_Group, instanceId)
                  .updateObject({});
              });
              return;
            }
            case 'remove': {
              update.userIds?.forEach((instanceId) => {
                objectStore.withAssociation(ConceptRoleUserAssignation)
                  .withRole(ConceptRoleUserAssignation_Role_Concept, conceptId)
                  .withRole(ConceptRoleUserAssignation_Role_ConceptRole, update.roleId)
                  .withRole(ConceptRoleUserAssignation_Role_User, instanceId)
                  .deleteObject();
              });
              update.groupIds?.forEach((instanceId) => {
                objectStore.withAssociation(ConceptRoleGroupAssignation)
                  .withRole(ConceptRoleGroupAssignation_Role_Concept, conceptId)
                  .withRole(ConceptRoleGroupAssignation_Role_ConceptRole, update.roleId)
                  .withRole(ConceptRoleGroupAssignation_Role_Group, instanceId)
                  .deleteObject();
              });
              return;
            }
            default:
              throw newError('update type not supported', { update });
          }
        }
      },
      updateOperationHandlers: {
        INITIALIZE: {
          applyOperation: (dimensionsMapping, parametersMapping, values) => {
            const fieldHandler = stakeholdersFieldHandler(objectStore, fieldId);
            const parsedDimension = parseDimensionMapping(dimensionsMapping);
            if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
              values.forEach((value) => {
                const isUser = value.valueType === 'user';
                if (value.roleId !== undefined) {
                  const shouldInitialize = objectStore
                    .withAssociation(isUser ? ConceptRoleUserAssignation : ConceptRoleGroupAssignation)
                    .withRole(isUser ? ConceptRoleUserAssignation_Role_Concept : ConceptRoleGroupAssignation_Role_Concept, parsedDimension.objectId)
                    .withRole(isUser ? ConceptRoleUserAssignation_Role_ConceptRole : ConceptRoleGroupAssignation_Role_ConceptRole, value.roleId)
                    .listObjects().length === 0;

                  if (shouldInitialize) {
                    if (value.type === 'path') {
                      const resolution = createValuePathResolver(objectStore, parametersMapping).resolvePathValue(value.path);
                      if (isValidValuePathResolution(resolution) && isSingleValueResolution(resolution) && isStoreObject(resolution.value)) {
                        fieldHandler.updateValue(dimensionsMapping, {
                          action: 'add',
                          roleId: value.roleId,
                          [isUser ? 'userIds' : 'groupIds']: [resolution.value.id],
                        });
                      } else if (isValidValuePathResolution(resolution) && isMultiValueResolution(resolution)) {
                        fieldHandler.updateValue(dimensionsMapping, {
                          action: 'add',
                          roleId: value.roleId,
                          [isUser ? 'userIds' : 'groupIds']: resolution.values.filter(isStoreObject).map((instance) => instance.id),
                        });
                      }
                    } else {
                      fieldHandler.updateValue(dimensionsMapping, {
                        action: 'add',
                        roleId: value.roleId,
                        [isUser ? 'userIds' : 'groupIds']: value.objectIds,
                      });
                    }
                  }
                }
              });
            } else {
              throw newError('multidim update not supported', { dimensionsMapping, parametersMapping, values });
            }
          },
          sanitizeOperation: (oldOperation) => (oldOperation && Array.isArray(oldOperation.payload) ? oldOperation.payload : []),
        },
        ADD: {
          applyOperation: (dimensionsMapping, parametersMapping, values) => {
            const fieldHandler = stakeholdersFieldHandler(objectStore, fieldId);
            const parsedDimension = parseDimensionMapping(dimensionsMapping);
            if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
              values.forEach((value) => {
                if (value.roleId !== undefined) {
                  if (value.type === 'path') {
                    const resolution = createValuePathResolver(objectStore, parametersMapping).resolvePathValue(value.path);
                    if (isValidValuePathResolution(resolution) && isSingleValueResolution(resolution) && isStoreObject(resolution.value)) {
                      fieldHandler.updateValue(dimensionsMapping, {
                        action: 'add',
                        roleId: value.roleId,
                        [value.valueType === 'user' ? 'userIds' : 'groupIds']: [resolution.value.id],
                      });
                    } else if (isValidValuePathResolution(resolution) && isMultiValueResolution(resolution)) {
                      fieldHandler.updateValue(dimensionsMapping, {
                        action: 'add',
                        roleId: value.roleId,
                        [value.valueType === 'user' ? 'userIds' : 'groupIds']: resolution.values.filter(isStoreObject).map((instance) => instance.id),
                      });
                    }
                  } else {
                    fieldHandler.updateValue(dimensionsMapping, {
                      action: 'add',
                      roleId: value.roleId,
                      [value.valueType === 'user' ? 'userIds' : 'groupIds']: value.objectIds,
                    });
                  }
                }
              });
            } else {
              throw newError('multidim update not supported', { dimensionsMapping, parametersMapping, values });
            }
          },
          sanitizeOperation: (oldOperation) => (oldOperation && Array.isArray(oldOperation.payload) ? oldOperation.payload : []),
        },
        REMOVE: {
          applyOperation: (dimensionsMapping, parametersMapping, values) => {
            const fieldHandler = stakeholdersFieldHandler(objectStore, fieldId);
            const parsedDimension = parseDimensionMapping(dimensionsMapping);
            if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
              values.forEach((value) => {
                if (value.roleId !== undefined) {
                  if (value.type === 'path') {
                    const resolution = createValuePathResolver(objectStore, parametersMapping).resolvePathValue(value.path);
                    if (isValidValuePathResolution(resolution) && isSingleValueResolution(resolution) && isStoreObject(resolution.value)) {
                      fieldHandler.updateValue(dimensionsMapping, {
                        action: 'remove',
                        roleId: value.roleId,
                        [value.valueType === 'user' ? 'userIds' : 'groupIds']: [resolution.value.id],
                      });
                    } else if (isValidValuePathResolution(resolution) && isMultiValueResolution(resolution)) {
                      fieldHandler.updateValue(dimensionsMapping, {
                        action: 'remove',
                        roleId: value.roleId,
                        [value.valueType === 'user' ? 'userIds' : 'groupIds']: resolution.values.filter(isStoreObject).map((instance) => instance.id),
                      });
                    }
                  } else if (value.roleId === 'all') {
                    const roles = objectStore.getObjectOrNull(parsedDimension.objectId)?.navigateOrNull(Instance_Of)?.navigateBack(ConceptDefinition_Roles) ?? [];
                    roles.forEach(({ id: roleId }) => {
                      fieldHandler.updateValue(dimensionsMapping, {
                        action: 'remove',
                        roleId,
                        [value.valueType === 'user' ? 'userIds' : 'groupIds']: value.objectIds,
                      });
                    });
                  } else {
                    fieldHandler.updateValue(dimensionsMapping, {
                      action: 'remove',
                      roleId: value.roleId,
                      [value.valueType === 'user' ? 'userIds' : 'groupIds']: value.objectIds,
                    });
                  }
                }
              });
            } else {
              throw newError('multidim update not supported', { dimensionsMapping, parametersMapping, values });
            }
          },
          sanitizeOperation: (oldOperation) => (oldOperation && Array.isArray(oldOperation.payload) ? oldOperation.payload : []),
        },
        CLEAR: {
          applyOperation: (dimensionsMapping, _, value) => {
            const fieldHandler = stakeholdersFieldHandler(objectStore, fieldId);
            const targetType = fieldHandler.getTargetType?.()?.id;
            const oldValues = fieldHandler.getStoreValue(dimensionsMapping);
            if (targetType === User || targetType === Group) {
              const parsedDimension = parseDimensionMapping(dimensionsMapping);
              if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
                const conceptRoles = objectStore.getObjectOrNull(parsedDimension.objectId)?.navigateOrNull(Instance_Of)?.navigateBack(ConceptDefinition_Roles) ?? [];
                conceptRoles.forEach((role) => {
                  fieldHandler.updateValue(dimensionsMapping, {
                    action: 'remove',
                    roleId: role.id,
                    userIds: targetType !== Group ? oldValues.map(({ id }) => id) : undefined,
                    groupIds: targetType !== User ? oldValues.map(({ id }) => id) : undefined,
                  });
                });
              } else {
                throw newError('multidim update not supported', { fieldId, dimensionsMapping, payload: value });
              }
            } else if (targetType === ConceptRole && value) {
              const group = oldValues.find(({ id }) => id === value.roleId);
              if (group) {
                fieldHandler.updateValue(dimensionsMapping, {
                  action: 'remove',
                  roleId: group.id,
                  userIds: value.from !== 'group' ? group[User] : undefined,
                  groupIds: value.from !== 'user' ? group[Group] : undefined,
                });
              }
            }
          },
          sanitizeOperation: (oldOperation) => (oldOperation && !Array.isArray(oldOperation.payload) ? oldOperation.payload : undefined),
        },
      },
      getValueAsText,
      getExportColumnHeaders: getMultipleRelationFieldExportColumnHeaders(objectStore),
      getExportValue: (dimensionsMapping, configurationProp) => {
        const configuration: MultipleRelationFieldExportConfiguration = configurationProp ?? { type: 'path', separator: ', ' };
        const values = getValueResolution(dimensionsMapping);
        return {
          format: 'string',
          value: values.value.map((instance) => {
            if (configuration.type === 'path') {
              const defaultPath: PathStep[] = [
                { type: PathStepType.dimension, conceptDefinitionId: instance[Instance_Of] as string },
                { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
                { type: PathStepType.field, fieldId: Concept_Name },
              ];
              const pathResolution = createValuePathResolver(objectStore, { [FILTER_PARAMETER_CURRENT]: { type: 'single', id: instance.id } }).resolvePathValue(configuration.path ?? defaultPath);
              if (isSingleValueResolution(pathResolution)) {
                return isRichText(pathResolution.value) ? richTextToText(pathResolution.value) : pathResolution.value as string;
              } else {
                return undefined;
              }
            } else {
              return instance.id;
            }
          }).filter(filterNullOrUndefined)
            .join(configuration.separator),
        };
      },
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else {
            const valueResolution = getValueResolution(dimensionsMapping);
            const value = valueResolution && !valueResolution.error ? valueResolution.value : [];
            return handleProxyArrayProps(prop, value, (v) => getConceptInstanceProxy(objectStore, v.id));
          }
        },
      }),
      isEmpty: (dimensionsMapping) => {
        if (dimensionsMapping[ConceptStakeholdersUsersDimension]) {
          return objectStore.withAssociation(ConceptRoleUserAssignation)
            .withRole(ConceptRoleUserAssignation_Role_Concept, dimensionsMapping[ConceptStakeholdersUsersDimension])
            .list()
            .length === 0;
        } else if (dimensionsMapping[ConceptStakeholdersGroupsDimension]) {
          return objectStore.withAssociation(ConceptRoleGroupAssignation)
            .withRole(ConceptRoleGroupAssignation_Role_Concept, dimensionsMapping[ConceptStakeholdersGroupsDimension])
            .list()
            .length === 0;
        } else {
          return true;
        }
      },
      isSaneValue: () => ({ isValid: true }),
      getTargetType,
      filterConditions: undefined,
    };
  },
  historyEventProducer: (objectStore, fieldId) => ({
    value: {
      collectImpactedInstances: ({ id, properties }) => {
        if (id.length > 1 && id[0] === ConceptRoleUserAssignation) {
          if (
            properties === undefined
            || objectStore.getObjectOrNull(id[ConceptRoleUserAssignation_Role_User + 1]) === null
            || objectStore.getObjectOrNull(id[ConceptRoleUserAssignation_Role_Concept + 1]) === null
            || objectStore.getObjectOrNull(id[ConceptRoleUserAssignation_Role_ConceptRole + 1]) === null
          ) {
            return [];
          } else if (fieldId === Concept_StakeholdersUsers) {
            return [[id[ConceptRoleUserAssignation_Role_Concept + 1]], [id[ConceptRoleUserAssignation_Role_ConceptRole + 1]]];
          } else if (fieldId === Concept_StakeholdersRoles) {
            return [[id[ConceptRoleUserAssignation_Role_Concept + 1]]];
          }
        }
        if (id.length > 1 && id[0] === ConceptRoleGroupAssignation) {
          if (
            properties === undefined
            || objectStore.getObjectOrNull(id[ConceptRoleGroupAssignation_Role_Group + 1]) === null
            || objectStore.getObjectOrNull(id[ConceptRoleGroupAssignation_Role_Concept + 1]) === null
            || objectStore.getObjectOrNull(id[ConceptRoleGroupAssignation_Role_ConceptRole + 1]) === null
          ) {
            return [];
          } else if (fieldId === Concept_StakeholdersGroups) {
            return [[id[ConceptRoleGroupAssignation_Role_Concept + 1]], [id[ConceptRoleGroupAssignation_Role_ConceptRole + 1]]];
          } else if (fieldId === Concept_StakeholdersRoles) {
            return [[id[ConceptRoleGroupAssignation_Role_Concept + 1]]];
          }
        }
        return [];
      },
      getValue: (id) => {
        const concept = objectStore.getObjectOrNull(id);
        if (concept) {
          const dimensionId = getFieldDimensionOfModelType(objectStore, fieldId, concept[Instance_Of] as string);
          if (dimensionId) {
            return ({
              value: getValueWithoutFormula(objectStore as ObjectStoreWithTimeseries, fieldId, { [dimensionId]: id[0] }).map(({ id: objectId }) => objectId),
              version: 1,
            });
          } else {
            return { value: null, version: 1 };
          }
        } else {
          return { value: null, version: 1 };
        }
      },
      areValuesEquals: (value1, value2) => equals(value1?.value, value2?.value),
    },
  }),
});
