import { useContext, useEffect, useMemo, useRef } from 'react';
import { UNSAFE_DataRouterContext } from 'react-router-dom';
import { newError } from 'yooi-utils';

export interface NavigationHistoryEntry {
  action: string,
  date: number,
  url: string,
  isUserControlled?: boolean,
}

const maxHistorySize = 5;

const useNavigationHistory = (): {
  onNavigation: (listener: () => void) => () => void,
  getNavigationHistory: () => NavigationHistoryEntry[],
} => {
  const tracking = useRef<NavigationHistoryEntry[]>([]);
  const { router } = useContext(UNSAFE_DataRouterContext) ?? {};

  if (!router) {
    throw newError('Not in router');
  }

  if (tracking.current.length === 0) {
    tracking.current.splice(0, 0, {
      action: 'external',
      date: Date.now(),
      url: router.createHref(router.state.location),
    });
  }

  useEffect(() => router.subscribe(({ historyAction, location }) => {
    tracking.current.splice(0, 0, {
      action: historyAction,
      date: Date.now(),
      url: router.createHref(location),
      isUserControlled: location.state?.isUserControlled,
    });

    if (tracking.current.length > maxHistorySize) {
      tracking.current.splice(maxHistorySize);
    }
  }), [router]);

  return useMemo(() => ({
    onNavigation: (listener) => router.subscribe(listener),
    getNavigationHistory: () => tracking.current.slice(0),
  }), [router]);
};

export default useNavigationHistory;
