import { dateFieldHandler, getFieldDimensionOfModelType } from 'yooi-modules/modules/conceptModule';
import { DateField_DefaultPeriod } from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreWithTimeseries } from 'yooi-store';
import type { DateFieldStoreValue } from 'yooi-utils';
import { PeriodicityType } from 'yooi-utils';

export const getDateFieldValue = (store: ObjectStoreWithTimeseries, conceptId: string, dateFieldId: string): DateFieldStoreValue | undefined => {
  const conceptInstance = store.getObjectOrNull(conceptId);
  const dimension = conceptInstance ? getFieldDimensionOfModelType(store, dateFieldId, conceptInstance[Instance_Of] as string) : undefined;
  const { value: dateValue } = dateFieldHandler(store, dateFieldId).getValueResolution(dimension ? { [dimension]: conceptId } : {});
  if (typeof dateValue === 'number') {
    const period = (store.getObject(conceptId)[dateFieldId] as DateFieldStoreValue | undefined)?.period
      ?? store.getObject(dateFieldId)[DateField_DefaultPeriod] as PeriodicityType | undefined
      ?? PeriodicityType.day;
    return { period, date: dateValue };
  } else {
    return dateValue;
  }
};
