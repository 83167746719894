import type { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../../components/templates/Header';
import HeaderTabs from '../../../components/templates/HeaderTabs';
import i18n from '../../../utils/i18n';
import useNavigation from '../../../utils/useNavigation';
import HeaderStatic from '../../_global/HeaderStatic';
import type { NavigationFilter } from '../../_global/navigationUtils';
import ExplorerMainContainer from './_global/ExplorerMainContainer';
import ExplorerHomeComputingsTab from './ExplorerHomeComputingsTab';
import ExplorerHomeEventsTab from './ExplorerHomeEventsTab';
import ExplorerHomeInstanceTab from './ExplorerHomeInstanceTab';
import ExplorerHomePathsTab from './ExplorerHomePathsTab';
import ExplorerHomeStatisticsTab from './ExplorerHomeStatisticsTab';

const ExplorerHomePage: FunctionComponent = () => {
  const location = useLocation();
  const navigation = useNavigation<NavigationFilter>();

  const tabs = [
    {
      key: 'instances',
      name: i18n`Instances`,
      hash: '#instances',
      content: () => (<ExplorerHomeInstanceTab />),
    },
    {
      key: 'events',
      name: i18n`Events`,
      hash: '#events',
      content: () => (<ExplorerHomeEventsTab />),
    },
    {
      key: 'statistics',
      name: i18n`Statistics`,
      hash: '#statistics',
      content: () => (<ExplorerHomeStatisticsTab />),
    },
    {
      key: 'paths',
      name: i18n`Paths`,
      hash: '#paths',
      content: () => (<ExplorerHomePathsTab />),
    },
    {
      key: 'computings',
      name: i18n`Computings`,
      hash: '#computings',
      content: () => (<ExplorerHomeComputingsTab />),
    },
  ];

  const definitionIndex = tabs.findIndex(({ hash }) => hash === location.hash);
  const tabIndex = definitionIndex === -1 ? 0 : definitionIndex;

  return (
    <ExplorerMainContainer
      header={(
        <Header
          firstLine={(<HeaderStatic text={i18n`Explorer`} />)}
          tabsLine={(
            <HeaderTabs
              tabs={tabs}
              selectedTabIndex={tabIndex}
              onSelectedIndexChanged={(value, { isCtrlKeyPressed, isMiddleClick }) => {
                if (isCtrlKeyPressed || isMiddleClick) {
                  window.open(`${location.pathname}${tabs[value].hash}`, '_blank', 'noopener');
                } else {
                  navigation.replace('explorer', { hash: tabs[value].hash });
                }
              }}
            />
          )}
        />
      )}
      content={tabs[tabIndex].content()}
    />
  );
};

export default ExplorerHomePage;
