import { StructuredError } from 'yooi-utils';

class AlreadyCachedError extends StructuredError {
  override readonly name = 'AlreadyCachedError';

  constructor() {
    super('Task result was already cached, something is wrong!', { data: {} });
  }
}

export default AlreadyCachedError;
