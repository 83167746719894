import { equals } from 'ramda';
import { v4 as uuid } from 'uuid';
import { getParentConceptInstance, isConceptValid } from 'yooi-modules/modules/conceptModule';
import { Concept_Name, KinshipRelation } from 'yooi-modules/modules/conceptModule/ids';
import { DataAsset, DataAsset_Type } from 'yooi-modules/modules/dataAssetModule/ids';
import { getResourcesToDataAssetsEmbeddingFieldInstanceId } from 'yooi-modules/modules/resourceModule';
import { Resource, Resource_Image, Resource_Type, Resource_URL } from 'yooi-modules/modules/resourceModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStore, ObjectStoreReadOnly, ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import type { RichText } from 'yooi-utils';
import { joinObjects, newError, textToRichText } from 'yooi-utils';
import type { FrontObjectStore } from '../../store/useStore';
import urlAnalyzers from './urlAnalyzers';

export const getUrlMetadata = (url: string): { type: string, assetKey: Record<string, unknown>, generateUrl?: () => string | undefined } => {
  for (let i = 0; i < urlAnalyzers.length; i += 1) {
    try {
      const match = urlAnalyzers[i].match(url);
      if (match) {
        return match;
      }
    } catch {
      // do nothing
    }
  }
  throw newError('No analyzer match the url', { url });
};

const getResourceFromUrl = (store: ObjectStoreReadOnly, url: string): StoreObject | undefined => store.getObject(Resource)
  .navigateBack(Class_Instances)
  .filter(({ id: conceptId }) => isConceptValid(store, conceptId))
  .find((resource) => (resource[Resource_URL] as string | undefined)?.toLowerCase() === url.toLowerCase());

export const updateResourceURL = (store: ObjectStoreWithTimeseries, resourceInstanceId: string, url: string | null): string | undefined => {
  if (url === null) {
    store.updateObject(resourceInstanceId, {
      [Resource_URL]: null,
    });
    return undefined;
  }
  const existingResource = getResourceFromUrl(store, url);
  if (existingResource) {
    return `A resource with the url ${url} already exists for an instance of this concept`;
  }
  store.updateObject(resourceInstanceId, {
    [Resource_URL]: url,
  });
  return undefined;
};

export const getOrCreateResource = (store: ObjectStoreReadOnly, url: string): { id: string, [key: string]: unknown } => {
  const existingResource = getResourceFromUrl(store, url);
  if (existingResource) {
    return existingResource;
  } else {
    const { assetKey, type } = getUrlMetadata(url);
    const matchingExternalAssetResource = store.getObject(Resource)
      .navigateBack(Class_Instances)
      .filter(({ id: conceptId }) => isConceptValid(store, conceptId))
      .find((resource) => {
        if (!resource[Resource_URL]) {
          return false;
        }
        const { assetKey: compareAssetKey, type: compareType } = getUrlMetadata(resource[Resource_URL] as string);
        return compareType === type && equals(assetKey, compareAssetKey);
      });
    let dataAssetId;
    if (matchingExternalAssetResource) {
      dataAssetId = getParentConceptInstance(matchingExternalAssetResource)?.id;
    }
    const resourcesToDataAssetsEmbeddingFieldInstanceId = getResourcesToDataAssetsEmbeddingFieldInstanceId(store);
    return {
      id: uuid(),
      [Resource_URL]: url,
      [KinshipRelation]: resourcesToDataAssetsEmbeddingFieldInstanceId,
      [resourcesToDataAssetsEmbeddingFieldInstanceId]: dataAssetId,
    };
  }
};

const createNewAssetAndLinkResource = (
  store: FrontObjectStore,
  reference: StoreObject,
  fieldId: string,
  { resourceInstance, dataAsset, image }: {
    resourceInstance: { [Resource_Image]: string | null | undefined, [Resource_URL]: string | null | undefined, [Resource_Type]: string | null | undefined },
    dataAsset: { id: string, [Concept_Name]: RichText | undefined, [DataAsset_Type]: string | null | undefined },
    image: { id?: string, url?: string } | { data: unknown, objectId: string, propertyId: string, contentType: string } | undefined,
  }
): void => {
  const dataAssetId = store.createObject({
    [Instance_Of]: DataAsset,
    [Concept_Name]: textToRichText(resourceInstance[Resource_URL] as string),
    [DataAsset_Type]: dataAsset[DataAsset_Type],
  });
  const resourcesToDataAssetsEmbeddingFieldInstanceId = getResourcesToDataAssetsEmbeddingFieldInstanceId(store);
  const resourceId = store.createObject({
    [Instance_Of]: Resource,
    [KinshipRelation]: resourcesToDataAssetsEmbeddingFieldInstanceId,
    [resourcesToDataAssetsEmbeddingFieldInstanceId]: dataAssetId,
    [Resource_URL]: resourceInstance[Resource_URL],
    [Resource_Type]: resourceInstance[Resource_Type],
    [Resource_Image]: resourceInstance[Resource_Image],
  });
  store.updateObject(reference.id, { [fieldId]: resourceId });
  if ((image as { data: unknown })?.data) {
    store.uploadAttachment(
      joinObjects((image as { data: unknown, propertyId: string, contentType: string }), { objectId: resourceId }),
      (revisionId) => {
        store.updateObject(resourceId, {
          [Resource_Image]: revisionId,
        });
      }
    );
  }
};

const createOrUpdateDataAssetFromValues = (
  store: ObjectStore,
  { dataAsset }: { dataAsset: { id: string, [Concept_Name]: RichText | undefined, [DataAsset_Type]: string | null | undefined } }
): void => {
  const storeDataAssetInstance = store.getObjectOrNull(dataAsset.id);
  if (storeDataAssetInstance && storeDataAssetInstance[DataAsset_Type] !== dataAsset[DataAsset_Type]) {
    const newType = dataAsset[DataAsset_Type] ?? null;
    if (storeDataAssetInstance[DataAsset_Type] !== newType) {
      store.updateObject(dataAsset.id, { [DataAsset_Type]: dataAsset[DataAsset_Type] ?? null });
    }
  } else if (!storeDataAssetInstance) {
    store.updateObject(dataAsset.id, {
      [Instance_Of]: DataAsset,
      [Concept_Name]: dataAsset[Concept_Name],
      [DataAsset_Type]: dataAsset[DataAsset_Type],
    });
  }
};

const createOrUpdateResourceFromValues = (
  store: ObjectStore,
  { resourceInstance, dataAsset }: {
    resourceInstance: { id: string, [Resource_Image]: string | null | undefined, [Resource_URL]: string | null | undefined, [Resource_Type]: string | null | undefined },
    dataAsset: { id: string, [Concept_Name]: RichText | undefined, [DataAsset_Type]: string | null | undefined },
  }
) => {
  const storeResourceInstance = store.getObjectOrNull(resourceInstance.id);
  const resourcesToDataAssetsEmbeddingFieldInstanceId = getResourcesToDataAssetsEmbeddingFieldInstanceId(store);
  // If we have updated values from existing resource
  if (storeResourceInstance
    && ((resourceInstance[Resource_Type] !== undefined && storeResourceInstance[Resource_Type] !== resourceInstance[Resource_Type])
      || (resourceInstance[Resource_Image] !== undefined && storeResourceInstance[Resource_Image] !== resourceInstance[Resource_Image])
      || (dataAsset.id !== undefined && dataAsset.id !== storeResourceInstance[resourcesToDataAssetsEmbeddingFieldInstanceId])
    )) {
    store.updateObject(resourceInstance.id, {
      [Resource_Type]: resourceInstance[Resource_Type] ?? null,
      [KinshipRelation]: resourcesToDataAssetsEmbeddingFieldInstanceId,
      [resourcesToDataAssetsEmbeddingFieldInstanceId]: dataAsset.id,
      [Resource_Image]: resourceInstance[Resource_Image],
    });
  } else if (!storeResourceInstance) {
    store.updateObject(resourceInstance.id, {
      [Instance_Of]: Resource,
      [KinshipRelation]: resourcesToDataAssetsEmbeddingFieldInstanceId,
      [resourcesToDataAssetsEmbeddingFieldInstanceId]: dataAsset.id,
      [Resource_URL]: resourceInstance[Resource_URL],
      [Resource_Type]: resourceInstance[Resource_Type],
      [Resource_Image]: resourceInstance[Resource_Image],
    });
  }
};

export const updateStoreForTransactionalResourceAndDataAsset = (
  store: FrontObjectStore,
  reference: StoreObject,
  fieldId: string,
  { resourceInstance, dataAsset, image }: {
    resourceInstance: { id: string, [Resource_Image]: string | null | undefined, [Resource_URL]: string | null | undefined, [Resource_Type]: string | null | undefined },
    dataAsset: { id: string, [Concept_Name]: RichText | undefined, [DataAsset_Type]: string | null | undefined },
    image: { id?: string, url?: string } | { data: unknown, objectId: string, propertyId: string, contentType: string } | undefined,
  }
): void => {
  // Removing resource from reference
  if (!resourceInstance || !resourceInstance[Resource_URL]) {
    store.updateObject(reference.id, { [fieldId]: null });
  } else if (!dataAsset?.id) {
    createNewAssetAndLinkResource(store, reference, fieldId, { resourceInstance, dataAsset, image });
  } else {
    createOrUpdateDataAssetFromValues(store, { dataAsset });
    createOrUpdateResourceFromValues(store, { resourceInstance, dataAsset });
    // If resources linked from reference has changed
    if (reference[fieldId] !== resourceInstance.id) {
      store.updateObject(reference.id, { [fieldId]: resourceInstance.id });
    }
    if ((image as { data: unknown })?.data) {
      store.uploadAttachment(
        image as { data: unknown, objectId: string, propertyId: string, contentType: string },
        (revisionId) => {
          store.updateObject(resourceInstance.id, {
            [Resource_Image]: revisionId,
          });
        }
      );
    }
  }
};
