import type { MutableRefObject } from 'react';
import { useCallback, useRef } from 'react';
import { spacingRem } from '../theme/spacingDefinition';
import declareBus from './declareBus';
import useDeepMemo from './useDeepMemo';

export const scrollMarginTop = spacingRem.l;

const useScrollToAnchorBus = declareBus<string>('scrollToAnchor').useBus;

const knownAnchors = new Set<string>();

export const useAnchorScroll = (): { knownAnchors: Set<string>, scrollTo: (anchor: string | undefined) => boolean } => {
  const scrollTo = useScrollToAnchorBus();

  return {
    knownAnchors,
    scrollTo: useCallback((anchor) => {
      if (anchor && knownAnchors.has(anchor.toLowerCase())) {
        scrollTo(anchor);
        return true;
      } else {
        return false;
      }
    }, [scrollTo]),
  };
};

export const useScrollableAnchorsRef = <E extends HTMLElement = HTMLElement>(
  anchors: string[],
  options?: boolean | ScrollIntoViewOptions
): MutableRefObject<E | null> => {
  const ref = useRef<E>(null);

  const callback = useDeepMemo(() => {
    if (anchors) {
      return (targetAnchor: string | undefined) => {
        if (anchors.findIndex((anchor) => anchor.toLowerCase() === targetAnchor?.toLowerCase()) > -1 && ref.current) {
          ref.current.scrollIntoView(options);
        }
      };
    } else {
      return undefined;
    }
  }, [anchors, options]);

  const onSubscribe = useDeepMemo(() => {
    if (anchors) {
      return () => {
        anchors.forEach((anchor) => {
          knownAnchors.add(anchor.toLowerCase());
        });
      };
    } else {
      return undefined;
    }
  }, [anchors]);

  const onUnsubscribe = useDeepMemo(() => {
    if (anchors) {
      return () => {
        anchors.forEach((anchor) => {
          knownAnchors.delete(anchor.toLowerCase());
        });
      };
    } else {
      return undefined;
    }
  }, [anchors]);

  useScrollToAnchorBus(callback, onSubscribe, onUnsubscribe);

  return ref;
};
