import type { FunctionComponent } from 'react';
import base from '../../theme/base';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import useTheme from '../../utils/useTheme';
import Button, { ButtonVariant } from '../atoms/Button';
import Typo, { TypoVariant } from '../atoms/Typo';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  message: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    maxWidth: '50rem',
    backgroundColor: theme.color.background.neutral.default,
    paddingTop: '6rem',
    paddingLeft: '6rem',
    paddingRight: '6rem',
    paddingBottom: '3rem',
    borderRadius: base.borderRadius.medium,
    boxShadow: base.shadowElevation.high,
  },
  titleContainer: {
    margin: spacingRem.text,
  },
  buttonContainer: {
    marginTop: spacingRem.s,
  },
}), 'errorPage');

interface ErrorPageProps {
  title: string,
  messages: string[],
  action?: { title: string, onClick: () => void },
}

const ErrorPage: FunctionComponent<ErrorPageProps> = ({ title, messages, action }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.message}>
        <div className={classes.titleContainer}>
          <Typo variant={TypoVariant.blockPrimaryTitle} color={theme.color.text.danger}>
            {title}
          </Typo>
        </div>
        {messages.map((message) => (
          <Typo key={message} color={theme.color.text.primary}>
            {message}
          </Typo>
        ))}
        {action && (
          <div className={classes.buttonContainer}>
            <Button
              title={action.title}
              variant={ButtonVariant.secondary}
              onClick={action.onClick}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorPage;
