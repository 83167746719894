import { isFirefox } from 'react-device-detect';
import { newError } from 'yooi-utils';

const getFontSize = () => parseFloat(getComputedStyle(document.documentElement).fontSize);

let fontSize = getFontSize();
window.addEventListener('load', () => {
  fontSize = getFontSize();
});

export const remToPx = (remSize: number | string): number => {
  if (typeof remSize === 'number') {
    return remSize * fontSize;
  } else if (remSize.endsWith('rem') && remSize.length > 3) {
    return Number.parseFloat(remSize.slice(0, remSize.length - 3)) * fontSize;
  } else {
    throw newError('Invalid size provided', { remSize });
  }
};

export const SCROLLBAR_WIDTH_IN_REM = isFirefox ? 0 : 0.8;
