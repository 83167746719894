import { Editor, Element, Element as SlateElement, Point, Range, Transforms } from 'slate';
import { RichTextElementTypes } from 'yooi-utils';

const SHORTCUTS: Record<string, RichTextElementTypes> = {
  '*': RichTextElementTypes['list-item'],
  '-': RichTextElementTypes['list-item'],
  '+': RichTextElementTypes['list-item'],
  '>': RichTextElementTypes['block-quote'],
};

const richTextWithMarkdownShortcuts = (editor: Editor): Editor => {
  const { deleteBackward, insertText } = editor;
  const mutableEditor = editor;
  mutableEditor.insertText = (text) => {
    const { selection } = editor;

    if (text === ' ' && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection;
      const block = Editor.above(editor, {
        match: (n) => Element.isElement(n) && Editor.isBlock(editor, n),
      });
      const path = block ? block[1] : [];
      const start = Editor.start(editor, path);
      const range = { anchor, focus: start };
      const beforeText = Editor.string(editor, range);
      const type = SHORTCUTS[beforeText];

      if (type) {
        Transforms.select(editor, range);
        Transforms.delete(editor);
        const newProperties = {
          type,
        };
        Transforms.setNodes(editor, newProperties, {
          match: (n) => Element.isElement(n) && Editor.isBlock(editor, n),
        });

        if (type === RichTextElementTypes['list-item']) {
          const list = {
            type: RichTextElementTypes['bulleted-list'],
            children: [],
          };
          Transforms.wrapNodes(editor, list, {
            match: (n) => !Editor.isEditor(n)
              && SlateElement.isElement(n)
              && n.type === RichTextElementTypes['list-item'],
          });
        }
        return;
      }
    }
    insertText(text);
  };
  mutableEditor.deleteBackward = (...args) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: (n) => Element.isElement(n) && Editor.isBlock(editor, n),
      });
      if (match) {
        const [block, path] = match;
        const start = Editor.start(editor, path);

        if (
          !Editor.isEditor(block)
          && SlateElement.isElement(block)
          && block.type !== RichTextElementTypes.paragraph
          && Point.equals(selection.anchor, start)
        ) {
          const newProperties = {
            type: RichTextElementTypes.paragraph,
          };
          Transforms.setNodes(editor, newProperties);

          if (block.type === RichTextElementTypes['list-item']) {
            Transforms.unwrapNodes(editor, {
              match: (n) => !Editor.isEditor(n)
                && SlateElement.isElement(n)
                && n.type === RichTextElementTypes.paragraph,
              split: true,
            });
          }
          return;
        }
      }
      deleteBackward(...args);
    }
  };
  return mutableEditor;
};

export default richTextWithMarkdownShortcuts;
