import type { FunctionComponent } from 'react';
import { Integration_Stakeholders } from 'yooi-modules/modules/integrationModule/ids';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { UsageContextProvider, UsageVariant } from '../../../utils/useUsageContext';
import StoreTextInputField from '../../_global/input/StoreTextInputField';

interface IntegrationDetailStakeholdersTabProps {
  integrationId: string,
}

const IntegrationDetailStakeholdersTab: FunctionComponent<IntegrationDetailStakeholdersTabProps> = ({ integrationId }) => {
  const store = useStore();

  const integration = store.getObject(integrationId);

  const placeholder = `[
    {
      "concept": "UUID",
      "roles": {
        "YOOI_ROLE_ID": "JIRA_FIELD_ID"
      }
    }
  ]`;

  return (
    <VerticalBlock>
      <BlockTitle title={i18n`Stakeholders`} subtitle={i18n`You can map YOOI concept roles to your fields here`} />
      <BlockContent padded>
        <UsageContextProvider usageVariant={UsageVariant.inForm}>
          <StoreTextInputField
            fullWidth
            placeholder={placeholder}
            initialValue={integration[Integration_Stakeholders] as string}
            onSubmit={(newConfiguration) => {
              store.updateObject(integrationId, { [Integration_Stakeholders]: newConfiguration });
            }}
          />
        </UsageContextProvider>
      </BlockContent>
    </VerticalBlock>
  );
};

export default IntegrationDetailStakeholdersTab;
