import { useCallback, useLayoutEffect } from 'react';

const subscribers: Record<string, ((event: unknown) => void)[]> = {};

const subscribe = <Event>(topic: string, callback: ((event: Event) => void) | undefined, onSubscribe: (() => void) | undefined, onUnsubscribe: (() => void) | undefined) => {
  if (!callback) {
    return undefined;
  }

  if (subscribers[topic]) {
    subscribers[topic].push(callback as (event: unknown) => void);
  } else {
    subscribers[topic] = [callback as (event: unknown) => void];
  }

  if (onSubscribe) {
    onSubscribe();
  }

  return () => {
    subscribers[topic] = subscribers[topic].filter((subscriberCallback) => !(subscriberCallback === callback));
    if (onUnsubscribe) {
      onUnsubscribe();
    }
  };
};

const dispatch = <Event>(topic: string, event: Event): void => {
  (subscribers[topic] ?? []).forEach((callback) => callback(event));
};

interface Bus<Event> {
  useBus: (callback?: (event: Event) => void, onSubscribe?: () => void, onUnsubscribe?: () => void) => ((event: Event) => void),
  dispatch: (event: Event) => void,
}

const declareBus = <Event = never>(topic: string): Bus<Event> => ({
  useBus: (callback, onSubscribe, onUnsubscribe) => {
    useLayoutEffect(() => subscribe<Event>(topic, callback, onSubscribe, onUnsubscribe), [callback, onSubscribe, onUnsubscribe]);

    return useCallback((event) => dispatch<Event>(topic, event), []);
  },
  dispatch: (event) => dispatch<Event>(topic, event),
});

export default declareBus;
