import type { FunctionComponent } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { TimeseriesTableViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import ScrollableWidgetContainer, { SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_HEIGHT_PADDING } from '../../../../components/templates/ScrollableWidgetContainer';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { remToPx } from '../../../../utils/sizeUtils';
import useDeepMemo from '../../../../utils/useDeepMemo';
import useDerivedState from '../../../../utils/useDerivedState';
import useForceUpdate from '../../../../utils/useForceUpdate';
import { useSessionStorageState } from '../../../../utils/useSessionStorage';
import WidgetInvalidConfiguration from '../../fields/_global/WidgetInvalidConfiguration';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { useOnFilterChanged } from '../../filter/useFilterSessionStorage';
import { getViewDefinitionHandler } from '../viewDsl';
import { isResolutionError } from '../viewResolutionUtils';
import TimeseriesTableView from './TimeseriesTableView';
import type { TimeseriesTableViewDefinitionHandler } from './timeseriesTableViewHandler';

interface TimeseriesTableViewWidgetProps {
  widgetId: string,
  viewDimensions: ViewDimension[],
  viewDefinition: TimeseriesTableViewStoredDefinition,
  parametersMapping: ParametersMapping,
  width: number,
  height: number,
  filterKey: string,
  readOnly?: boolean,
}

const TimeseriesTableViewWidget: FunctionComponent<TimeseriesTableViewWidgetProps> = ({
  widgetId,
  viewDimensions,
  viewDefinition,
  parametersMapping,
  width,
  height,
  filterKey,
  readOnly,
}) => {
  const store = useStore();
  const { loggedUserId } = useAuth();
  const aclHandler = useAcl();
  const forceUpdate = useForceUpdate();

  const [addedTimePoints, setAddedTimePoints] = useDerivedState<number[]>(() => [], [widgetId, viewDimensions, viewDefinition, parametersMapping]);
  const [filterConfiguration] = useSessionStorageState<FilterConfiguration | undefined>(filterKey, undefined);
  useOnFilterChanged(filterKey, forceUpdate);

  const viewHandler = getViewDefinitionHandler(viewDefinition) as TimeseriesTableViewDefinitionHandler;
  const resolvedViewDefinition = viewHandler.getDefinition(store, viewDimensions);
  const timeseriesTableResolution = useDeepMemo(
    () => viewHandler.resolveView(store, {
      viewDimensions,
      parametersMapping,
      userId: loggedUserId,
      addedTimePoints,
      aclHandler,
      filterConfiguration,
    }),
    [store.getSerial(), viewDimensions, viewDefinition, parametersMapping, loggedUserId, addedTimePoints, filterConfiguration]
  );

  if (isResolutionError(timeseriesTableResolution)) {
    return <WidgetInvalidConfiguration width={width} height={height} reason={timeseriesTableResolution.error} />;
  } else {
    const chartHeight = height - remToPx(SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_HEIGHT_PADDING);
    return (
      <ScrollableWidgetContainer asContent width={width} height={height}>
        <TimeseriesTableView
          viewDefinition={resolvedViewDefinition}
          filterKey={viewDefinition.id}
          parametersMapping={parametersMapping}
          readOnly={readOnly}
          height={chartHeight}
          viewResolution={timeseriesTableResolution}
          addTimePoint={(time) => setAddedTimePoints((current) => (current.includes(time) ? current : [...current, time]))}
          removeTimePoint={(time) => setAddedTimePoints((current) => current.filter((t) => t !== time))}
          removeAllTimePoints={() => setAddedTimePoints([])}
        />
      </ScrollableWidgetContainer>
    );
  }
};

export default TimeseriesTableViewWidget;
