import classnames from 'classnames';
import { equals } from 'ramda';
import type { ComponentProps, FunctionComponent } from 'react';
import type { FilterCondition, FilterValue, SingleParameterDefinition, PathStep } from 'yooi-modules/modules/conceptModule';
import {
  getConceptDefinitionValidFields,
  getFilterConditionsFromFilterPath,
  isDimensionStep,
  isFieldStep,
  isFilterStep,
  isGlobalDimensionStep,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import { Field } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { compareString, extractAndCompareValue, filterNullOrUndefined, joinObjects } from 'yooi-utils';
import Typo from '../../../components/atoms/Typo';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../components/molecules/SpacingLine';
import useStore from '../../../store/useStore';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useTheme from '../../../utils/useTheme';
import { getAllInstancesChipOption, getNthStep } from '../fields/_global/pathUtils';
import { getFieldHandler } from '../fields/FieldLibrary';
import type { SetValue } from '../fields/FieldLibraryTypes';
import { getFilterValuePathHandler, getRenderersFromFilterPath } from '../FrontFilterRenderers';
import { defaultOptionComparator, getChipOptions, getParameterOption } from '../modelTypeUtils';
import MappingComposite from '../path/MappingComposite';
import PathStepsInput from '../path/PathStepsInput';
import { createPathConfigurationHandler } from '../pathConfigurationHandler';

const useStyles = makeStyles({
  multilineContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.s,
  },
  firstLineContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 11rem',
    columnGap: spacingRem.xs,
  },
  pathAndDimensionContainer: {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    columnGap: spacingRem.xs,
  },
  conditionGrid: {
    display: 'grid',
    gap: spacingRem.s,
  },
  simpleConditionGrid: {
    gridTemplateColumns: '16rem 13rem 23.8rem',
  },
  simpleConditionEmptyGrid: {
    gridTemplateColumns: '16rem 13rem',
  },
  simpleConditionNoFilterGrid: {
    gridTemplateColumns: '16rem 23rem',
  },
  complexConditionGrid: {
    gridTemplateColumns: '29.8rem 13rem 39rem',
  },
  complexConditionEmptyGrid: {
    gridTemplateColumns: '29.8rem 13rem',
  },
  complexConditionNoFilterGrid: {
    gridTemplateColumns: '29.8rem 23rem',
  },
}, 'blockFilterInput');

type FilterInput = Omit<FilterCondition, 'id'>;

interface BlockFilterInputProps {
  filter: FilterInput,
  readOnly?: boolean,
  requiredConceptDefinitionId?: string,
  parameterDefinitions?: SingleParameterDefinition[],
  onChange: (filter: FilterInput) => void,
  multilines?: boolean,
  suggestedPaths?: { label: string, path: PathStep[] }[],
  rootPath?: { label: string, path: PathStep[] },
  isSimple?: boolean,
}

const DEFAULT_PATH_OPTION_ID = 'defaultPath';

const BlockFilterInput: FunctionComponent<BlockFilterInputProps> = ({
  requiredConceptDefinitionId,
  filter,
  onChange,
  readOnly = false,
  parameterDefinitions = [],
  multilines = false,
  suggestedPaths,
  rootPath,
  isSimple = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const updateFilterSelection = (newProps: Partial<FilterInput>) => {
    onChange(joinObjects(filter, newProps));
  };

  const getStepChipOption: ComponentProps<typeof PathStepsInput>['getStepChipOption'] = (pathStep, index, self, fallbackStepChipOption) => {
    const n1Step = getNthStep(self, index, 1);
    const overrideIndex = pathStep.displayOverrides?.displayedPathStepIndex ?? pathStep.steps.length - 1;
    const displayedStep = pathStep.steps[overrideIndex];
    if (isFilterStep(displayedStep)) {
      if (n1Step && isDimensionStep(n1Step)) {
        const allInstancesChipOption = getAllInstancesChipOption(store, n1Step.conceptDefinitionId);
        return joinObjects(allInstancesChipOption, { step: pathStep, id: `${allInstancesChipOption.id}_${index}`, index });
      }
    }
    return fallbackStepChipOption?.(pathStep, index, self);
  };

  const { operator, leftValue = [], rightValue } = filter;
  const lastStep = leftValue[leftValue.length - 1];
  const selectedFieldId = isFieldStep(lastStep) ? lastStep.fieldId : undefined;

  const basePath = rootPath ?? (suggestedPaths?.length === 1 ? suggestedPaths[0] : undefined);
  const filterConditionsRenderers = getRenderersFromFilterPath(store, leftValue, parameterDefinitions);
  const filterConditions = getFilterConditionsFromFilterPath(store, leftValue);
  const valuePathHandler = requiredConceptDefinitionId
    ? getFilterValuePathHandler(store, [requiredConceptDefinitionId], parameterDefinitions)
    : createPathConfigurationHandler(store, parameterDefinitions);

  let conditionInput;
  let valueInput;
  const canDisplayCondition = (!basePath && leftValue.length === 0) || (filterConditionsRenderers && filterConditions && !valuePathHandler.getErrors(leftValue));
  if (canDisplayCondition) {
    const filterConditionRenderer = operator ? filterConditionsRenderers?.[operator] : undefined;
    const filterCondition = operator ? filterConditions?.[operator] : undefined;
    conditionInput = (
      <SearchAndSelect
        placeholder={i18n`Operator`}
        selectedOption={filterConditionRenderer ? { id: operator as string, label: filterConditionRenderer.name } : undefined}
        computeOptions={() => (filterConditionsRenderers ? Object.entries(filterConditionsRenderers)
          .map(([key, { name }]) => ({ id: key, label: name }))
          .sort(defaultOptionComparator) : [])}
        onSelect={(option) => updateFilterSelection({ leftValue, operator: option?.id, rightValue: undefined })}
        readOnly={readOnly || (!basePath && leftValue.length === 0)}
        clearable
      />
    );

    const updateValue: SetValue<FilterValue<unknown>> = (newValue) => {
      if (typeof newValue === 'function') {
        updateFilterSelection({
          rightValue: newValue(filterCondition?.sanitizeValue?.(rightValue)),
        });
      } else {
        updateFilterSelection({ rightValue: newValue as FilterValue<unknown> });
      }
    };
    valueInput = filterConditionRenderer?.renderFilter?.(
      filterCondition?.sanitizeValue?.(rightValue),
      updateValue,
      parameterDefinitions,
      readOnly,
      leftValue,
      isSimple,
      i18n`Value`
    ) ?? valueInput;
  } else {
    conditionInput = (
      <SpacingLine>
        <Typo maxLine={1} color={theme.color.text.secondary}>{i18n`No filter available on this type of field`}</Typo>
      </SpacingLine>
    );
  }

  let pathStepsInput;
  if (isSimple) {
    const firstStep = leftValue.at(0) ?? basePath?.path?.[0];
    const conceptDefinitionId = isDimensionStep(firstStep) ? firstStep.conceptDefinitionId : undefined;
    const defaultPathOption = basePath && conceptDefinitionId ? getParameterOption(
      store,
      {
        id: DEFAULT_PATH_OPTION_ID,
        label: basePath.label,
        typeId: conceptDefinitionId,
        type: 'parameter',
      }
    ) : undefined;

    let selectedOption;
    if (selectedFieldId) {
      selectedOption = getChipOptions(store, selectedFieldId);
    } else if (equals(leftValue, basePath?.path)) {
      selectedOption = defaultPathOption;
    }
    pathStepsInput = (
      <SearchAndSelect
        placeholder={i18n`Field`}
        selectedOption={selectedOption}
        computeOptions={() => [
          defaultPathOption,
          ...(conceptDefinitionId
            ? getConceptDefinitionValidFields(store, conceptDefinitionId)
              .map(({ id: fieldId }) => getChipOptions(store, fieldId))
              .filter(filterNullOrUndefined)
              .sort(defaultOptionComparator)
            : []),
        ].filter(filterNullOrUndefined)}
        onSelect={(option) => {
          if (option?.id === DEFAULT_PATH_OPTION_ID) {
            updateFilterSelection(option ? { leftValue: basePath?.path, rightValue: undefined } : {});
          } else {
            updateFilterSelection(option ? { leftValue: [leftValue[0], leftValue[1], { type: PathStepType.field, fieldId: option.id }], rightValue: undefined } : {});
          }
        }}
        readOnly={readOnly}
      />
    );
  } else {
    pathStepsInput = (
      <PathStepsInput
        placeholder={i18n`Field`}
        onSubmit={(newPath) => {
          let newCondition;
          if (lastStep && isFieldStep(lastStep)) {
            const { fieldId } = lastStep;
            if (fieldId) {
              const fieldHandler = isInstanceOf(store.getObjectOrNull(fieldId), Field) && getFieldHandler(store, fieldId);
              if (fieldHandler) {
                const { filterConditions: newFilterConditions } = fieldHandler;
                if (newFilterConditions) {
                  const firstConditionEntry = Object.entries(newFilterConditions).sort(extractAndCompareValue(([, { name }]) => name, compareString))[0];
                  newCondition = firstConditionEntry?.[0];
                }
              }
            }
          }
          updateFilterSelection({ leftValue: newPath, operator: newCondition, rightValue: null });
        }}
        initialPath={leftValue}
        valuePathHandler={valuePathHandler}
        parameterDefinitions={parameterDefinitions}
        getStepChipOption={getStepChipOption}
        suggestedBasePaths={suggestedPaths}
        rootPath={rootPath}
        readOnly={readOnly}
      />
    );
  }

  const pathInput = leftValue.length > 1 && isGlobalDimensionStep(leftValue[0]) && isFieldStep(leftValue[1])
    ? (
      <span className={classes.pathAndDimensionContainer}>
        {pathStepsInput}
        <MappingComposite
          path={leftValue}
          parameterDefinitions={parameterDefinitions}
          onChange={(newPath) => updateFilterSelection({ leftValue: newPath })}
          readOnly={readOnly}
        />
      </span>
    )
    : pathStepsInput;

  if (multilines) {
    return (
      <div className={classes.multilineContainer}>
        <div className={classes.firstLineContainer}>
          {pathInput}
          {conditionInput}
        </div>
        {valueInput}
      </div>
    );
  } else {
    return (
      <div
        className={classnames({
          [classes.conditionGrid]: true,
          [classes.simpleConditionGrid]: isSimple && valueInput,
          [classes.simpleConditionEmptyGrid]: isSimple && !valueInput,
          [classes.simpleConditionNoFilterGrid]: isSimple && !canDisplayCondition,
          [classes.complexConditionGrid]: !isSimple && valueInput,
          [classes.complexConditionEmptyGrid]: !isSimple && !valueInput,
          [classes.complexConditionNoFilterGrid]: !isSimple && !canDisplayCondition,
        })}
      >
        {pathInput}
        {conditionInput}
        {valueInput}
      </div>
    );
  }
};

export default BlockFilterInput;
