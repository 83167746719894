import type { ReactElement } from 'react';
import type { ExtractDslHandlerTypes } from 'yooi-modules/modules/common/fields/FieldModuleDslType';
import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import { BlockFieldLayoutOption } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type {
  ConceptDefinitionStoreObject,
  MultipleRelationFieldExportConfiguration,
  StakeholdersFieldRaw,
  StakeholdersFieldStoreObject,
} from 'yooi-modules/modules/conceptModule';
import { ParsedDimensionType, parseDimensionMapping, stakeholdersFieldHandler } from 'yooi-modules/modules/conceptModule';
import type { Field_Title } from 'yooi-modules/modules/conceptModule/ids';
import {
  ConceptDefinition,
  ConceptDefinition_ChipBackgroundColor,
  ConceptDefinition_Icon,
  ConceptDefinition_Roles,
  ConceptRole,
  Field_ApiAlias,
  Field_Documentation,
  Field_IsDocumentationInline,
  Group,
  User,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../../components/atoms/Button';
import { IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import OverflowMenu from '../../../../components/molecules/OverflowMenu';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import BlockContent from '../../../../components/templates/BlockContent';
import BlockTitle from '../../../../components/templates/BlockTitle';
import HorizontalBlock, { HorizontalBlockVariant } from '../../../../components/templates/HorizontalBlock';
import i18n from '../../../../utils/i18n';
import { formatOrUndef } from '../../../../utils/stringUtils';
import { computeBorderColor, conceptDefinitionChipBackgroundColor } from '../../conceptDisplayUtils';
import { getChipOptions, getObjectName } from '../../modelTypeUtils';
import { extractLastFieldFilters } from '../../views/common/viewUtils';
import { getBlockFieldLayoutOption, getDefaultDisplayOptions, getLayoutDisplayOption } from '../_global/blockFieldUtils';
import { getApiAliasEditionOption, getApiAliasInitialState, getDocumentationFieldEditionSection } from '../_global/editionHandlerUtils';
import MultipleRelationExportConfiguration from '../_global/MultipleRelationExportConfiguration';
import { getGenericOperationMetadata } from '../_global/updateOperationHandlerUtils';
import UpdateOperationSelector from '../_global/UpdateOperationSelector';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import { FIELD_EDITION_DIMENSIONS, getFieldDimensionsEditionHandlerValue } from '../fieldDimensionUtils';
import type { FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode } from '../FieldLibraryTypes';
import StakeholdersBlockFieldRenderer from './StakeholdersBlockFieldRenderer';
import StakeholdersFieldInputRenderer from './StakeholdersFieldInputRenderer';
import StakeholdersFieldRenderer from './StakeholdersFieldRenderer';

interface OperationOption {
  id: ExtractDslHandlerTypes<typeof stakeholdersFieldHandler>['UpdateOperationsActionKeys'] & string,
  label: string,
  actionLabel: string,
  onSelect: () => void,
}

interface StakeholdersFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
}

type StakeholdersFieldDefinition = GetFieldDefinitionHandler<
  typeof stakeholdersFieldHandler,
  StakeholdersFieldConfigurationState,
  never, FieldBlockDisplayOptions,
  MultipleRelationFieldExportConfiguration
>;

export const stakeholdersFieldDefinition: StakeholdersFieldDefinition = registerFieldDefinition(stakeholdersFieldHandler, {
  configuration: {
    typeIcon: IconName.person,
    getTypeLabel: () => i18n`Stakeholders`,
    asWidget: false,
    getEditionOptions: (objectStore) => ({ mode, editionHandler }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      sections.push(getDocumentationFieldEditionSection(editionHandler));

      if (mode === FieldEditionOptionMode.FieldDeveloperMode) {
        sections.push({
          key: 'integration',
          type: 'section',
          title: i18n`Integration`,
          options: [getApiAliasEditionOption(objectStore, editionHandler)],
        });
      }

      return sections;
    },
    ofField: (objectStore, fieldId, handler) => ({
      getTypeLabel: () => {
        const targetType = handler.getTargetType?.();
        if (targetType) {
          return i18n`Stakeholders: ${getObjectName(objectStore, objectStore.getObject(targetType.id))}`;
        } else {
          return i18n`Stakeholders`;
        }
      },
      getIcon: () => {
        const targetType = handler.getTargetType?.();
        if (targetType && isInstanceOf<ConceptDefinitionStoreObject>(targetType, ConceptDefinition)) {
          return {
            name: targetType[ConceptDefinition_Icon] as IconName,
            borderColor: computeBorderColor(targetType[ConceptDefinition_ChipBackgroundColor] ?? conceptDefinitionChipBackgroundColor),
            color: conceptDefinitionChipBackgroundColor,
          };
        } else {
          return undefined;
        }
      },
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<StakeholdersFieldStoreObject>(fieldId);
        return joinObjects(
          getApiAliasInitialState(objectStore, fieldId),
          {
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
          }
        );
      },
      submitFieldUpdate: (stateToSubmit) => {
        objectStore.updateObject<StakeholdersFieldRaw>(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
        });
      },
    }),
  },
  renderExportConfiguration: () => ({ configuration, onChange, conceptDefinitionId }) => (
    <MultipleRelationExportConfiguration configuration={configuration} conceptDefinitionId={conceptDefinitionId} onChange={onChange} />
  ),
  getActivityProperties: () => undefined,
  renderField: (_, fieldId) => ({ dimensionsMapping, path, parametersMapping, readOnly }) => {
    const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
    const filter = extractLastFieldFilters(path);

    if (parsedDimensionMapping.type === ParsedDimensionType.MonoDimensional) {
      return (
        <StakeholdersFieldRenderer
          fieldId={fieldId}
          valueFilters={filter}
          parametersMapping={parametersMapping}
          dimensions={{ n1InstanceId: parsedDimensionMapping.objectId }}
          readOnly={readOnly}
        />
      );
    } else if (Object.keys(dimensionsMapping).includes('n1InstanceId') && Object.keys(dimensionsMapping).includes('n2InstanceId')) {
      return (
        <StakeholdersFieldRenderer
          fieldId={fieldId}
          valueFilters={filter}
          parametersMapping={parametersMapping}
          dimensions={{ n1InstanceId: dimensionsMapping.n1InstanceId, n2InstanceId: dimensionsMapping.n2InstanceId } as { n1InstanceId: string, n2InstanceId: string }}
          readOnly={readOnly}
        />
      );
    } else {
      return null;
    }
  },
  renderBlockField: (_, fieldId) => (dimensionsMapping, __, blockFieldProps) => {
    const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
    if (parsedDimensionMapping.type !== ParsedDimensionType.MonoDimensional) {
      // More than one dimension, rely on the render field
      return null;
    } else if (Object.keys(dimensionsMapping).includes('n2InstanceId')) {
      // n2InstanceId is defined, rely on the render field (we only want a search and select
      return null;
    } else {
      return (
        <StakeholdersBlockFieldRenderer
          fieldId={fieldId}
          dimensions={{ n1InstanceId: parsedDimensionMapping.objectId }}
          blockFieldProps={blockFieldProps}
        />
      );
    }
  },
  getUpdateOperationInput: (store, fieldId, { updateOperationHandlers: { INITIALIZE, ADD, REMOVE, CLEAR }, getTargetType }) => (operation) => {
    const { title, getIcon } = getGenericOperationMetadata(store, operation, fieldId);
    return {
      title,
      getIcon,
      render: ({ onSubmit, parameterDefinitions, conceptDefinitionId }) => {
        const targetType = getTargetType?.();
        let warningMessage;
        if (operation?.action === 'CLEAR' && targetType?.id === User) {
          warningMessage = i18n`Be careful while using this operation as all Users will be removed from their given role(s).`;
        } else if (operation?.action === 'CLEAR' && targetType?.id === Group) {
          warningMessage = i18n`Be careful while using this operation as all Groups will be removed from their given role(s).`;
        }
        let info: string | undefined;
        if (operation?.action === 'INITIALIZE') {
          if (targetType?.id === ConceptRole) {
            info = i18n`User(s) will be added if the given role has no Users yet.\nGroup(s) will be added if the given role has no Groups yet.`;
          } else if (targetType?.id === User) {
            info = i18n`User(s) will be added if the given role has no Users yet.`;
          } else if (targetType?.id === Group) {
            info = i18n`Group(s) will be added if the given role has no Groups yet.`;
          }
        }

        const operationOptions: OperationOption[] = [
          {
            id: 'INITIALIZE',
            label: targetType?.id === ConceptRole ? i18n`Initialize` : i18n`Initialize roles`,
            actionLabel: i18n`Initialize`,
            onSelect: () => onSubmit({ action: 'INITIALIZE', payload: INITIALIZE.sanitizeOperation(operation) }),
          },
          {
            id: 'ADD',
            label: i18n`Add`,
            actionLabel: i18n`Add`,
            onSelect: () => onSubmit({ action: 'ADD', payload: ADD.sanitizeOperation(operation) }),
          },
          {
            id: 'REMOVE',
            label: i18n`Remove`,
            actionLabel: i18n`Remove`,
            onSelect: () => onSubmit({ action: 'REMOVE', payload: REMOVE.sanitizeOperation(operation) }),
          },
          {
            id: 'CLEAR',
            label: i18n`Clear`,
            actionLabel: i18n`Clear`,
            onSelect: () => onSubmit({ action: 'CLEAR', payload: CLEAR.sanitizeOperation(operation) }),
          },
        ];
        const operationSelectorLine = (
          <UpdateOperationSelector<typeof stakeholdersFieldHandler>
            selectedOperationAction={operation?.action}
            operationOptions={operationOptions}
            info={info}
            warningMessage={warningMessage}
          />
        );
        let operationInputsLines: ReactElement[] = [];
        if (operation && conceptDefinitionId && operation.action !== 'CLEAR') {
          const operationLineTitle = operation.action === 'INITIALIZE' ? i18n`With` : i18n`Who`;
          let addButtonComponent: ReactElement | null = null;
          if (targetType?.id === ConceptRole) {
            addButtonComponent = (
              <OverflowMenu
                iconName={IconName.add}
                title={operation.payload?.length ? undefined : i18n`Select`}
                tooltip={i18n`Select User(s) or Group(s) to give them a role`}
                menuItems={[
                  {
                    key: 'user',
                    icon: IconName.person,
                    name: i18n`User(s)`,
                    onClick: () => {
                      onSubmit({
                        action: operation.action,
                        payload: [...operation?.payload ?? [], { type: 'value', valueType: 'user', objectIds: [] }],
                      });
                    },
                  },
                  {
                    key: 'group',
                    icon: IconName.group,
                    name: i18n`Group(s)`,
                    onClick: () => {
                      onSubmit({
                        action: operation.action,
                        payload: [...operation?.payload ?? [], { type: 'value', valueType: 'group', objectIds: [] }],
                      });
                    },
                  },
                ]}
              />
            );
          } else if (operation.payload?.length) {
            addButtonComponent = (
              <IconOnlyButton
                variant={IconOnlyButtonVariants.secondary}
                iconName={IconName.add}
                tooltip={i18n`Select ${targetType?.id === Group ? i18n`Group(s)` : i18n`User(s)`} to give them a role`}
                onClick={
                  () => {
                    onSubmit({
                      action: operation.action,
                      payload: [...operation?.payload ?? [], { type: 'value', valueType: targetType?.id === User ? 'user' : 'group', objectIds: [] }],
                    });
                  }
                }
              />
            );
          } else {
            addButtonComponent = (
              <Button
                variant={ButtonVariant.secondary}
                iconName={IconName.add}
                title={i18n`Select`}
                tooltip={i18n`Select ${targetType?.id === Group ? i18n`Group(s)` : i18n`User(s)`} to give them a role`}
                onClick={
                  () => {
                    onSubmit({
                      action: operation.action,
                      payload: [...operation?.payload ?? [], { type: 'value', valueType: targetType?.id === User ? 'user' : 'group', objectIds: [] }],
                    });
                  }
                }
              />
            );
          }
          operationInputsLines = [(
            <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent key="operationLine">
              <BlockTitle
                unPadded
                title={operationLineTitle}
                actions={operation.payload?.length ? addButtonComponent : undefined}
              />
              <BlockContent padded fullWidth>
                {operation.payload?.length ? (
                  <StakeholdersFieldInputRenderer
                    initialValue={operation.payload}
                    onSubmit={(payload) => {
                      onSubmit({
                        action: operation.action,
                        payload,
                      });
                    }}
                    conceptDefinitionId={conceptDefinitionId}
                    parameterDefinitions={parameterDefinitions}
                    getPlaceholder={(isUser) => i18n`Select ${isUser ? i18n`Users` : i18n`Groups`} to ${formatOrUndef(operationOptions.find(({ id }) => id === operation?.action)?.actionLabel)}`}
                    isDeletion={operation.action === 'REMOVE'}
                  />
                ) : addButtonComponent}
              </BlockContent>
            </HorizontalBlock>
          )];
        } else if (targetType?.id === ConceptRole && conceptDefinitionId && operation?.action === 'CLEAR') {
          const fromOptions: { label: string, id: 'group' | 'user' | 'all' }[] = [
            { label: i18n`All`, id: 'all' },
            { label: i18n`Users`, id: 'user' },
            { label: i18n`Groups`, id: 'group' },
          ];
          operationInputsLines = [
            (
              <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent key="RoleBlock">
                <BlockTitle title={i18n`Role`} unPadded />
                <BlockContent>
                  <SearchAndSelect
                    selectedOption={operation.payload?.roleId ? getChipOptions(store, operation.payload.roleId) : undefined}
                    computeOptions={() => (
                      store
                        .getObjectOrNull(conceptDefinitionId)
                        ?.navigateBack(ConceptDefinition_Roles)
                        .map((role) => getChipOptions(store, role.id))
                        .filter(filterNullOrUndefined)
                      ?? []
                    )}
                    onSelect={(option) => {
                      onSubmit({ action: 'CLEAR', payload: { from: operation.payload?.from, roleId: option?.id ?? undefined } });
                    }}
                    placeholder={i18n`Add role`}
                  />
                </BlockContent>
              </HorizontalBlock>
            ),
            (
              <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent key="FromBlock">
                <BlockTitle title={i18n`From`} unPadded />
                <BlockContent>
                  <SearchAndSelect
                    selectedOption={fromOptions.find(({ id }) => id === operation.payload?.from) ?? fromOptions[0]}
                    computeOptions={() => fromOptions}
                    onSelect={(option) => {
                      onSubmit({ action: 'CLEAR', payload: { from: option?.id, roleId: operation.payload?.roleId } });
                    }}
                  />
                </BlockContent>
              </HorizontalBlock>
            ),
          ];
        }
        return (
          <>
            {operationSelectorLine}
            {operationInputsLines.map((line) => line)}
          </>
        );
      },
    };
  },
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({
    propertyId: fieldId,
    scrollOnMount: true,
    focusable: true,
  }),
  blockDisplayOptionsHandler: (objectStore) => (fieldBlockDisplayId) => ({
    getDisplayOptions: () => getDefaultDisplayOptions(objectStore, fieldBlockDisplayId),
    renderSummary: ({ layoutDisplayType }) => ([getBlockFieldLayoutOption()[layoutDisplayType ?? BlockFieldLayoutOption.auto].label]),
    getBlockEditionOptionSections: (state, setState) => [getLayoutDisplayOption(state, setState)],
    onSubmit: (state) => {
      objectStore.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
});
