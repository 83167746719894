type IterationMetric<T> = {
  iteration: number,
  iterationStart: number,
  iterationEnd: number,
} & ({ result: T, error?: undefined } | { error: unknown, result?: undefined });

export const withRetry = async <T>(runnable: () => Promise<T>, shouldRetry: (params: IterationMetric<T>) => Promise<boolean>): Promise<T> => {
  let iteration = 0;
  while (true) {
    iteration += 1;
    const iterationStart = Date.now();
    try {
      const result = await runnable();
      if (!await shouldRetry({ result, iteration, iterationStart, iterationEnd: Date.now() })) {
        return result;
      }
    } catch (error) {
      if (!await shouldRetry({ error, iteration, iterationStart, iterationEnd: Date.now() })) {
        throw error;
      }
    }
  }
};
