import { equals } from 'ramda';
import type { ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import { isStoreObject, OriginSources, ValidationStatus } from 'yooi-store';
import { arrayOf, filterNullOrUndefined, isRichText, isString, joinObjects, newError, richTextToText } from 'yooi-utils';
import { CommonAsType } from '../../common/fields/commonPropertyType';
import type { GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import { PropertyMandatoryType } from '../../common/types/TypeModuleDslType';
import { DataAsset } from '../../dataAssetModule/ids';
import { Resource } from '../../resourceModule/ids';
import { isInstanceOf } from '../../typeModule';
import { Instance_Of } from '../../typeModule/ids';
import { formatOrUndef } from '../common/commonFieldUtils';
import { Concept, Concept_Name, ConceptRole, EmbeddingField_ToType, Field_Formula, Group, KinshipRelation, User } from '../ids';
import type { ConceptStoreObject } from '../model';
import { registerField } from '../module';
import type { Filters, PathStep } from '../moduleType';
import { PathStepType } from '../moduleType';
import type { ConceptReference, DimensionsMapping, MultipleParameterValue, ParametersMapping, ResolutionStack, SingleParameterValue, ValueResolution } from '../utils';
import {
  conceptRefApiSchema,
  createValuePathResolver,
  FILTER_PARAMETER_CURRENT,
  getConceptInstanceProxy,
  getFilterFunction,
  getInstanceLabel,
  getMultipleRelationFieldExportColumnHeaders,
  handleProxyArrayProps,
  InstanceReferenceType,
  isSingleValueResolution,
  isValueResolutionOfType,
  ParsedDimensionType,
  parseDimensionMapping,
  resolveFieldValue,
  segregateParametersMapping,
  toConceptReference,
} from '../utils';
import { conceptType } from '../utils/formula/modelFunctions';
import type { MultipleRelationFieldExportConfiguration } from '../utils/relationFieldUtils';
import type { EmbeddingField } from './types';

const getStoreValue = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping): StoreObject[] => {
  const parsedDimensions = parseDimensionMapping(dimensionsMapping);
  if (parsedDimensions.type === ParsedDimensionType.MonoDimensional) {
    return objectStore.getObject(parsedDimensions.objectId).navigateBack(fieldId);
  } else {
    return [];
  }
};

const getValueResolution = (
  objectStore: ObjectStoreWithTimeseries,
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
  resolutionStack?: ResolutionStack
): ValueResolution<StoreObject[]> => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
  if (isValueResolutionOfType(valueResolution, (value): value is StoreObject[] => (Array.isArray(value) && value.every(isStoreObject)))) {
    return valueResolution;
  } else {
    return {
      value: [],
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['StoreObject[]'], typeof valueResolution.value),
      getDisplayValue: () => [],
      isTimeseries: false,
    };
  }
};

export interface EmbeddingFieldStepConfiguration {
  filters?: Filters,
}

type EmbeddingFieldHandler = GetDslFieldHandler<
  EmbeddingField,
  StoreObject[],
  undefined,
  StoreObject[],
  StoreObject[],
  ConceptReference[],
  undefined,
  undefined,
  EmbeddingFieldStepConfiguration,
  MultipleRelationFieldExportConfiguration
>;
export const embeddingFieldHandler: EmbeddingFieldHandler = registerField({
  model: {
    label: 'EmbeddingField',
    title: 'Embedding',
    withApiAlias: true,
    properties: [
      { label: 'Label', as: CommonAsType.string },
      { label: 'FromType', as: CommonAsType.string, mandatory: { type: PropertyMandatoryType.mandatory } },
      {
        label: 'ToType',
        as: CommonAsType.string,
        mandatory: { type: PropertyMandatoryType.mandatory },
        businessRules: [
          () => ({ source }, { properties }) => {
            if (source !== OriginSources.SYSTEM && [DataAsset, Resource, User, ConceptRole, Group].includes(properties?.[EmbeddingField_ToType] as string)) {
              return {
                rule: 'embeddingField.toType.excludedType',
                status: ValidationStatus.REJECTED,
              };
            } else {
              return undefined;
            }
          },
        ],
      },
    ],
    asPropertyBusinessRules: [
      ({ getObjectOrNull }, propertyId) => (_, { id, properties }) => {
        if (!properties) { // Deleting properties / Audit event
          return undefined;
        }
        const object = getObjectOrNull(id);
        const oldEmbeddingBy = object?.[propertyId];
        const newEmbeddingBy = properties[propertyId] ?? undefined;
        if (oldEmbeddingBy === newEmbeddingBy) { // no updates
          return undefined;
        }

        if (newEmbeddingBy !== undefined && (!isString(newEmbeddingBy) || !getObjectOrNull(newEmbeddingBy))) {
          return { rule: 'field.parent.invalidValue', status: ValidationStatus.REJECTED };
        }

        const kinshipRelationId = properties[KinshipRelation] === null ? undefined : (properties[KinshipRelation] ?? object?.[KinshipRelation]);
        if (object && !newEmbeddingBy && kinshipRelationId === propertyId) {
          // Removing embedding by should come with a kinship relation update or cleanup -> no update or update is still this embedding field
          return { rule: 'EmbeddingField.parentDeletion.missingKinshipRelationCleanup', status: ValidationStatus.REJECTED };
        }

        if (newEmbeddingBy && !kinshipRelationId) { // Adding embedding by should come with corresponding kinship relation
          return { rule: 'EmbeddingField.parentUpdate.missingKinshipRelation', status: ValidationStatus.REJECTED };
        } else if (newEmbeddingBy && kinshipRelationId !== propertyId) { // Adding embedding by should come with corresponding kinship relation
          return { rule: 'EmbeddingField.parentUpdate.wrongKinshipRelation', status: ValidationStatus.REJECTED };
        } else if (!newEmbeddingBy && kinshipRelationId === propertyId) { // removing embedding by should come with corresponding kinship relation cleanup
          return { rule: 'EmbeddingField.parentUpdate.wrongKinshipRelation', status: ValidationStatus.REJECTED };
        }
        return undefined;
      },
      ({ getObjectOrNull }, propertyId) => (_, { properties }) => {
        if (!properties || properties[propertyId] === null) {
          return undefined;
        }
        if (isInstanceOf(getObjectOrNull(properties[propertyId] as string), Concept)) {
          return { rule: 'EmbeddingField.target.accepted', status: ValidationStatus.ACCEPTED };
        } else {
          return { rule: 'EmbeddingField.target.rejected', status: ValidationStatus.REJECTED };
        }
      },
      ({ getObject, getObjectOrNull }, fieldId) => (_, { id, properties }) => {
        if (!properties || properties[fieldId] === null) {
          return undefined;
        }

        const dimensionTypeId = getObject(fieldId)[EmbeddingField_ToType];

        if (id.length === 1) {
          const object = getObjectOrNull(id);
          if ((object?.[Instance_Of] ?? properties[Instance_Of] as string) === dimensionTypeId) {
            return { rule: 'field.embeddingField.isAssociatedToConcept', status: ValidationStatus.ACCEPTED };
          }
        }
        return { rule: 'field.embeddingField.isNotAssociatedToConcept', status: ValidationStatus.REJECTED };
      },
    ],
  },
  handler: (objectStore, fieldId) => {
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => (
      getValueResolution(objectStore, fieldId, dimensionsMapping, undefined).value.map((object) => formatOrUndef(getInstanceLabel(objectStore, object))).join(', ')
    );
    const getTargetType = () => objectStore.getObject(fieldId).navigate(EmbeddingField_ToType);

    return {
      describe: () => ({ hasData: true, returnType: arrayOf(conceptType(getTargetType().id)), timeseriesMode: 'none' }),
      resolvePathStepConfiguration: (config) => {
        const resolveValue = (
          dimensionsMapping: DimensionsMapping,
          parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
          resolutionStack: ResolutionStack
        ) => {
          const { value, error } = getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack);
          if (error) {
            throw error;
          } else {
            const { filters } = config;
            if (filters) {
              const { singleParametersMapping } = segregateParametersMapping(parametersMapping);
              const filterFunction = getFilterFunction(objectStore, filters);
              if (filterFunction) {
                return value.filter((v) => filterFunction(joinObjects(singleParametersMapping, { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: v.id } })));
              }
            }
            return value;
          }
        };
        return ({
          hasData: true,
          timeseriesMode: 'none',
          getValueResolutionType: () => arrayOf(conceptType(getTargetType().id)),
          resolveValue,
          resolveDimension: (dimensionsMapping, parametersMapping, resolutionStack) => (
            { type: 'multiple', instances: resolveValue(dimensionsMapping, parametersMapping, resolutionStack) as ConceptStoreObject[] }
          ),
        });
      },
      restApi: {
        returnTypeSchema: { type: 'array', items: conceptRefApiSchema },
        formatValue: (value, canRead) => {
          if (objectStore.getObject(fieldId)[Field_Formula]) {
            return [];
          } else {
            return (value as StoreObject[])
              .filter((concept) => canRead([concept.id]))
              .map(toConceptReference)
              .filter(filterNullOrUndefined);
          }
        },
      },
      getStoreValue: (dimensionsMapping) => getStoreValue(objectStore, fieldId, dimensionsMapping),
      getValueWithoutFormula: (dimensionsMapping) => getStoreValue(objectStore, fieldId, dimensionsMapping),
      getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
      updateValue: () => {
        throw newError('updateValue not supported');
      },
      isEmpty: (dimensionsMapping) => getValueResolution(objectStore, fieldId, dimensionsMapping, undefined).value.length === 0,
      isSaneValue: () => ({ isValid: true }),
      getValueAsText,
      getExportColumnHeaders: getMultipleRelationFieldExportColumnHeaders(objectStore),
      getExportValue: (dimensionsMapping, configurationProp) => {
        const configuration: MultipleRelationFieldExportConfiguration = configurationProp ?? { type: 'path', separator: ', ' };
        const values = getValueResolution(objectStore, fieldId, dimensionsMapping);
        return {
          format: 'string',
          value: values.value.map((instance) => {
            if (configuration.type === 'path') {
              const defaultPath: PathStep[] = [
                { type: PathStepType.dimension, conceptDefinitionId: instance[Instance_Of] as string },
                { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
                { type: PathStepType.field, fieldId: Concept_Name },
              ];
              const pathResolution = createValuePathResolver(objectStore, { [FILTER_PARAMETER_CURRENT]: { type: 'single', id: instance.id } })
                .resolvePathValue(configuration.path ?? defaultPath);
              if (isSingleValueResolution(pathResolution)) {
                return isRichText(pathResolution.value) ? richTextToText(pathResolution.value) : pathResolution.value as string;
              } else {
                return undefined;
              }
            } else {
              return instance.id;
            }
          }).filter(filterNullOrUndefined)
            .join(configuration.separator),
        };
      },
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else {
            const valueResolution = getValueResolution(objectStore, fieldId, dimensionsMapping);
            let value: StoreObject[] = [];
            if (valueResolution && !valueResolution.error && isValueResolutionOfType(valueResolution, (v): v is StoreObject[] => (Array.isArray(v) && v.every(isStoreObject)))) {
              value = valueResolution.value;
            }
            return handleProxyArrayProps(prop, value, (v) => getConceptInstanceProxy(objectStore, v.id));
          }
        },
      }),
      getTargetType,
      filterConditions: undefined,
    };
  },
  historyEventProducer: ({ getObjectOrNull }, fieldId) => ({
    value: {
      collectImpactedInstances: ({ id, properties }) => {
        if (id.length === 1) {
          const impactedInstances = [];
          if (properties?.[fieldId] !== undefined || properties === null) {
            if (properties?.[fieldId] !== null && typeof properties?.[fieldId] === 'string') {
              impactedInstances.push([properties[fieldId]]);
            }
            const previousValue = getObjectOrNull(id);
            if (previousValue && previousValue[fieldId] && typeof previousValue[fieldId] === 'string') {
              impactedInstances.push([previousValue[fieldId]]);
            }
          }
          return impactedInstances;
        }
        return [];
      },
      getValue: (id) => ({
        value: getObjectOrNull(id)?.navigateBack(fieldId).map(({ id: targetId }) => targetId) ?? [],
        version: 1,
      }),
      areValuesEquals: (value1, value2) => equals(value1?.value, value2?.value),
    },
  }),
});
