import type { FunctionComponent } from 'react';
import type { DimensionsMapping } from 'yooi-modules/modules/conceptModule';
import { associationFieldHandler } from 'yooi-modules/modules/conceptModule';
import { filterNullOrUndefined } from 'yooi-utils';
import SearchAndSelectMultiple from '../../../../components/molecules/SearchAndSelectMultiple';
import useStore from '../../../../store/useStore';
import { formatFieldResolutionErrorForUser } from '../../errorUtils';
import { defaultOptionComparator, getChipOptions } from '../../modelTypeUtils';

interface MultidimensionalAssociationFieldRendererProps {
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
}

const MultidimensionalAssociationFieldRenderer: FunctionComponent<MultidimensionalAssociationFieldRendererProps> = ({ fieldId, dimensionsMapping }) => {
  const store = useStore();

  const fieldHandler = associationFieldHandler(store, fieldId);
  const valueResolution = fieldHandler.getValueResolution(dimensionsMapping);

  return (
    <SearchAndSelectMultiple
      selectedOptions={
        valueResolution.value
          .map(({ id }) => getChipOptions(store, id))
          .filter(filterNullOrUndefined)
          .sort(defaultOptionComparator)
      }
      error={valueResolution.error ? formatFieldResolutionErrorForUser(store, valueResolution.error, fieldId) : undefined}
      readOnly
    />
  );
};

export default MultidimensionalAssociationFieldRenderer;
