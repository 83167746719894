import type { FunctionComponent } from 'react';
import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import type { ChipViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { joinObjects } from 'yooi-utils';
import BlockContent from '../../../../components/templates/BlockContent';
import BlockTitle from '../../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../../components/templates/HorizontalBlock';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { getFieldEditionOptionRender } from '../../fields/fieldEditionOptionsUtils';
import type { FieldEditionOption } from '../../fields/FieldEditionOptionType';
import { EditionOptionTypes } from '../../fields/FieldEditionOptionType';
import type { UpdateViewDefinition } from '../../fields/viewsField/ViewsFieldDefinitionOptions';
import { getLineErrorContent } from '../common/viewUtils';
import type { ChipViewResolvedDefinition } from './chipViewDefinitionHandler';
import { getChipViewError } from './chipViewUtils';

interface ChipViewDefinitionOptionsProps {
  viewDimensions: ViewDimension[],
  viewDefinition: ChipViewResolvedDefinition,
  updateViewDefinition: UpdateViewDefinition<ChipViewStoredDefinition>,
  readOnly: boolean,
  parameterDefinitions: SingleParameterDefinition[],
}

const ChipViewDefinitionOptions: FunctionComponent<ChipViewDefinitionOptionsProps> = ({
  viewDimensions,
  viewDefinition,
  updateViewDefinition,
  readOnly,
  parameterDefinitions,
}) => {
  const store = useStore();

  const getEditionOptions = (): FieldEditionOption[] => {
    const editionOptions: FieldEditionOption[] = [];
    editionOptions.push(
      {
        key: 'readOnly',
        title: i18n`Read only`,
        padded: true,
        hasValue: () => Boolean(viewDefinition),
        clearValue: () => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { readOnly: false }))),
        type: EditionOptionTypes.checkbox,
        props: {
          checked: viewDefinition.readOnly,
          disabled: readOnly,
          onChange: (value) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { readOnly: value }))),
        },
      }
    );
    return editionOptions;
  };

  const error = getChipViewError(store, viewDimensions, parameterDefinitions);
  if (error !== undefined) {
    return (<BlockContent padded>{getLineErrorContent(error)}</BlockContent>);
  } else {
    const editionOptions = getEditionOptions();
    return (
      <>
        {editionOptions.map(
          (option) => (
            <HorizontalBlock key={option.key} asBlockContent>
              {option.title ? (
                <BlockTitle title={option.title} infoTooltip={option.info} errorTooltip={option.error} />
              ) : <div />}
              <BlockContent padded={option.padded}>
                {getFieldEditionOptionRender(option) ?? null}
              </BlockContent>
            </HorizontalBlock>
          )
        )}
      </>
    );
  }
};

export default ChipViewDefinitionOptions;
