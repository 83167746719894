import type { ReactNode } from 'react';

export type ValidLineStyles = 'angle' | 'straight' | 'curve';

export type AnchorPositionType = 'left' | 'right' | 'top' | 'bottom';

export interface RelationType {
  targetId: string,
  targetAnchor: AnchorPositionType,
  sourceAnchor: AnchorPositionType,
  order?: number,
  label?: ReactNode | null | undefined,
  lineType: LineType,
}

interface EntityRelationType {
  id: string,
  anchor: AnchorPositionType,
}

export interface SourceToTargetType {
  source: EntityRelationType,
  target: EntityRelationType,
  order: number,
  label?: ReactNode | null | undefined,
  lineType: LineType,
}

export interface ValidSourceToTargetType {
  source: EntityRelationType,
  target: EntityRelationType,
  order: number,
  label: ReactNode | null | undefined,
  lineType: ValidLineType,
}

interface ArrowShapeType {
  arrowLength: number,
  arrowThickness: number,
}

export enum ArrowSense {
  sourceToTarget = 'sourceToTarget',
  targetToSource = 'targetToSource',
}

interface LineType {
  strokeColor: string,
  strokeWidth?: number,
  sense?: ArrowSense,
  marker: ArrowShapeType,
  lineStyle?: ValidLineStyles,
  layer?: number,
  withArrowLabel?: boolean,
  arrowClickHandler?: {
    clicked: boolean,
    onArrowClick: () => void,
    clickableStrokeWidth: number,
    hoveredStrokeWidth: number,
  } | undefined,
}

export interface ValidLineType {
  strokeColor: string,
  strokeWidth: number,
  sense: ArrowSense,
  marker: ArrowShapeType,
  lineStyle: ValidLineStyles,
  layer: number,
  showArrowLabel: boolean,
  arrowClickHandler: {
    clicked: boolean,
    onArrowClick: () => void,
    clickableStrokeWidth: number,
    hoveredStrokeWidth: number,
  } | undefined,
}
