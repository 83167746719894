import type { FunctionComponent } from 'react';
import { useState } from 'react';
import Chooser from '../../../../components/molecules/Chooser';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';

const useStyles = makeStyles(
  {
    baseContainer: {
      paddingTop: spacingRem.xxs,
    },
    inFormContainer: {
      paddingTop: spacingRem.xs,
    },
  },
  'booleanChooser'
);

interface BooleanChooserProps {
  value: boolean,
  onSubmit: (newValue: boolean) => void,
  readOnly?: boolean,
}

const BooleanChooser: FunctionComponent<BooleanChooserProps> = ({ value, onSubmit, readOnly }) => {
  const [actionState, setActionState] = useState(value ? 'true' : 'false');
  const booleanChooserActions = [{ key: 'true', name: i18n`True`, value: true }, { key: 'false', name: i18n`False`, value: false }];

  const usage = useUsageContext();
  const classes = useStyles();

  return (
    <div className={usage === UsageVariant.inForm ? classes.inFormContainer : classes.baseContainer}>
      <Chooser
        actions={booleanChooserActions}
        onClick={(index) => {
          setActionState(booleanChooserActions[index].key);
          onSubmit(booleanChooserActions[index].value);
        }}
        noBoxShadow
        readOnly={readOnly}
        selectedIndexes={[booleanChooserActions.findIndex((booleanAction) => booleanAction.key === actionState)]}
      />
    </div>
  );
};

export default BooleanChooser;
