import type { FunctionComponent } from 'react';
import type { DimensionsMapping } from 'yooi-modules/modules/conceptModule';
import { canWrite, dateFieldHandler, ParsedDimensionType, parseDimensionMapping } from 'yooi-modules/modules/conceptModule';
import type { DateFieldStoreValue } from 'yooi-utils';
import { PeriodicityType } from 'yooi-utils';
import type { DateInputValue } from '../../../../components/inputs/datePickers/DateInput';
import DateInput from '../../../../components/inputs/datePickers/DateInput';
import useAcl from '../../../../store/useAcl';
import useActivity from '../../../../store/useActivity';
import useStore from '../../../../store/useStore';
import useUpdateActivity from '../../../../store/useUpdateActivity';
import i18n from '../../../../utils/i18n';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';
import { formatFieldResolutionErrorForUser } from '../../errorUtils';
import { getLastUpdateBy } from '../../historyUtils';
import InCompositeInput from '../../input/InCompositeInput';
import StoreDateInput from '../../input/StoreDateInput';

interface DateFieldRendererProps {
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
  readOnly: boolean,
  focusOnMount: boolean,
  value?: DateFieldStoreValue | undefined,
  onSubmit?: (value: DateFieldStoreValue | null) => void,
}

const DateFieldRenderer: FunctionComponent<DateFieldRendererProps> = ({ fieldId, dimensionsMapping, readOnly, focusOnMount, value, onSubmit }) => {
  const store = useStore();
  const { canWriteObject } = useAcl();

  const usageVariant = useUsageContext();

  const activity = useActivity();
  const updateActivity = useUpdateActivity();

  const fieldHandler = dateFieldHandler(store, fieldId);
  const configuration = fieldHandler.resolveConfiguration();

  const isReadOnly = readOnly || configuration.integrationOnly || Boolean(configuration.formula) || !canWrite(dimensionsMapping, canWriteObject);

  const parsedDimensions = parseDimensionMapping(dimensionsMapping);
  let onEditionStart: (() => void) | undefined;
  let onEditionStop: (() => void) | undefined;
  let isEditing = false;
  let restingTooltip: (() => Promise<string>) | undefined;
  if (parsedDimensions.type === ParsedDimensionType.MonoDimensional) {
    const { objectId } = parsedDimensions;
    isEditing = activity.listEditor(objectId, fieldId).length > 0;
    onEditionStart = () => updateActivity.onEnterEdition(objectId, fieldId);
    onEditionStop = () => updateActivity.onExitEdition(objectId, fieldId);
    restingTooltip = configuration.formula === undefined ? () => getLastUpdateBy(store, objectId, fieldId, undefined) : undefined;
  }

  if (onSubmit) {
    return (
      <InCompositeInput<DateInputValue>
        initialValue={value}
        setInputValue={(v) => onSubmit(v?.date ? { date: v.date, period: v.period } : null)}
      >
        {(props) => (
          <DateInput
            readOnly={isReadOnly}
            placeholder={i18n`Add date`}
            onEditionStart={onEditionStart}
            onEditionStop={onEditionStop}
            isEditing={isEditing}
            defaultPeriodicity={configuration.defaultPeriod}
            {...props}
          />
        )}
      </InCompositeInput>
    );
  } else {
    const valueResolution = fieldHandler.getValueResolution(dimensionsMapping);
    const error = valueResolution.error ? formatFieldResolutionErrorForUser(store, valueResolution.error, fieldId) : undefined;

    return (
      <StoreDateInput
        initialValue={
          typeof valueResolution.value === 'number'
            ? { period: configuration.defaultPeriod ?? PeriodicityType.day, date: valueResolution.value }
            : valueResolution.value
        }
        onSubmit={(newValue) => {
          if (!valueResolution.isComputed) {
            fieldHandler.updateValue(dimensionsMapping, newValue);
          }
        }}
        placeholder={i18n`Add date`}
        readOnly={isReadOnly}
        error={error}
        onEditionStart={onEditionStart}
        onEditionStop={onEditionStop}
        isEditing={isEditing}
        withMultiplayerOutline={usageVariant === UsageVariant.inTable && isEditing}
        defaultPeriodicity={configuration.defaultPeriod}
        focusOnMount={focusOnMount}
        restingTooltip={restingTooltip}
      />
    );
  }
};

export default DateFieldRenderer;
