import type { FunctionComponent, ReactElement } from 'react';
import { v4 as uuid } from 'uuid';
import { WorkflowRelationDisplayType } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import type { Filters, ParametersMapping, WorkflowTransitionStoreObject } from 'yooi-modules/modules/conceptModule';
import { FILTER_PARAMETER_CURRENT, FILTER_PARAMETER_LOGGED_USER, getFieldDimensionOfModelType, getFilterFunction } from 'yooi-modules/modules/conceptModule';
import { isEntryValidForInstance, isTransitionValidForUser, workflowFieldHandler } from 'yooi-modules/modules/conceptModule/fields/workflowField';
import {
  ConceptInstanceWorkflowFieldField,
  ConceptInstanceWorkflowFieldField_Role_ConceptInstance,
  ConceptInstanceWorkflowFieldField_Role_Field,
  ConceptInstanceWorkflowFieldField_Role_WorkflowField,
  ConceptInstanceWorkflowFieldField_Value,
  Field_Title,
  Workflow_TargetedConceptDefinition,
  Workflow_Transitions,
  WorkflowEntry,
  WorkflowEntry_Rank,
  WorkflowEntry_Role_Concept,
  WorkflowEntry_Role_Workflow,
  WorkflowFieldFields,
  WorkflowFieldFields_Role_Field,
  WorkflowFieldFields_Role_WorkflowField,
  WorkflowFieldFieldsTransitionExecution,
  WorkflowFieldFieldsTransitionExecution_Role_Concept,
  WorkflowFieldFieldsTransitionExecution_Role_Field,
  WorkflowFieldFieldsTransitionExecution_Role_WorkflowField,
  WorkflowFieldTransition,
  WorkflowFieldTransition_Filters,
  WorkflowFieldTransition_Role_Transition,
  WorkflowFieldTransition_Role_WorkflowField,
  WorkflowFieldTransitionExecution,
  WorkflowFieldTransitionExecution_Role_Concept,
  WorkflowFieldTransitionExecution_Role_WorkflowField,
  WorkflowTransition_From,
  WorkflowTransition_Name,
  WorkflowTransition_To,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareProperty, compareRank, filterNullOrUndefined } from 'yooi-utils';
import Button from '../../../../components/atoms/Button';
import { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import useAcl from '../../../../store/useAcl';
import useActivity from '../../../../store/useActivity';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import useUpdateActivity from '../../../../store/useUpdateActivity';
import i18n from '../../../../utils/i18n';
import { formatOrUndef } from '../../../../utils/stringUtils';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';
import { formatFieldResolutionErrorForUser } from '../../errorUtils';
import { getLastUpdateBy } from '../../historyUtils';
import { getChipOptions } from '../../modelTypeUtils';
import WorkflowFieldColorRenderer from './WorkflowFieldColorRenderer';
import WorkflowInput from './WorkflowInput';

interface WorkflowFieldRendererProps {
  fieldId: string,
  subFieldId?: string,
  conceptId: string,
  displayType: WorkflowRelationDisplayType,
  readOnly: boolean,
  focusOnMount?: boolean,
}

const actionToButton = ({ key, title, disabled, onClick }: { key: string, title: string, disabled: boolean, onClick: () => void }): ReactElement => (
  <Button
    key={key}
    maxLine={1}
    title={title}
    disabled={disabled}
    onClick={onClick}
  />
);

const WorkflowFieldRenderer: FunctionComponent<WorkflowFieldRendererProps> = ({ fieldId, subFieldId, conceptId, displayType, readOnly, focusOnMount = false }) => {
  const store = useStore();
  const { loggedUserId } = useAuth();
  const { canOverrideWorkflow, canWriteObject } = useAcl();

  const { onEnterEdition, onExitEdition } = useUpdateActivity();
  const activity = useActivity();
  const isEditing = activity.listEditor(conceptId, fieldId).length > 0;

  const usageVariant = useUsageContext();

  const concept = store.getObject(conceptId);

  const dimensionId = getFieldDimensionOfModelType(store, fieldId, concept[Instance_Of] as string);
  if (!dimensionId) {
    return null;
  }

  const fieldHandler = workflowFieldHandler(store, fieldId);
  const field = fieldHandler.resolveConfigurationWithOverride({ [dimensionId]: conceptId });
  const workflow = field.workflowId ? store.getObjectOrNull(field.workflowId) : undefined;
  const workflowTargetedConceptDefinition = workflow ? workflow.navigateOrNull(Workflow_TargetedConceptDefinition) : undefined;
  const transitions = workflow?.navigateBack<WorkflowTransitionStoreObject>(Workflow_Transitions) ?? [];
  const isReadOnly = readOnly || !canWriteObject(conceptId) || (field.integrationOnly ?? false);

  const { value: { value: workflowValue, fieldValues }, error } = fieldHandler.getValueResolution({ [dimensionId]: conceptId });
  const value = subFieldId ? fieldValues[subFieldId] : workflowValue;
  let displayError;
  const parametersMapping: ParametersMapping = {
    [FILTER_PARAMETER_CURRENT]: { type: 'single', id: conceptId },
    [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: loggedUserId },
  };
  if (error) {
    displayError = formatFieldResolutionErrorForUser(store, error, fieldId);
  } else if (!isEntryValidForInstance(store, fieldId, value?.id, parametersMapping)) {
    displayError = i18n`Display condition(s) are not fulfilled for current value`;
  }

  const canChangeStatus = !!workflow && !!workflowTargetedConceptDefinition && (value === undefined || transitions.length === 0 || canOverrideWorkflow(conceptId));

  const selectedOption = value ? getChipOptions(store, value.id) : undefined;

  const computeOptions = () => {
    if (workflow && workflowTargetedConceptDefinition) {
      return store.withAssociation(WorkflowEntry)
        .withRole(WorkflowEntry_Role_Workflow, workflow.id)
        .list()
        .filter((workflowEntry) => isInstanceOf(workflowEntry.navigateRole(WorkflowEntry_Role_Concept), workflowTargetedConceptDefinition.id))
        .filter((workFlowEntry) => isEntryValidForInstance(store, fieldId, workFlowEntry.role(WorkflowEntry_Role_Concept), parametersMapping))
        .sort(compareProperty('object', compareProperty(WorkflowEntry_Rank, compareRank)))
        .map((workflowEntry) => getChipOptions(store, workflowEntry.role(WorkflowEntry_Role_Concept)))
        .filter(filterNullOrUndefined);
    } else {
      return [];
    }
  };

  const onSelect = (option: { id: string } | null): void => {
    if (option) {
      if (!subFieldId) {
        store.updateObject(conceptId, { [fieldId]: option.id });
      } else {
        store.withAssociation(ConceptInstanceWorkflowFieldField)
          .withRole(ConceptInstanceWorkflowFieldField_Role_Field, subFieldId)
          .withRole(ConceptInstanceWorkflowFieldField_Role_WorkflowField, fieldId)
          .withRole(ConceptInstanceWorkflowFieldField_Role_ConceptInstance, conceptId)
          .updateObject({ [ConceptInstanceWorkflowFieldField_Value]: option.id });
      }
    }
  };

  const actions: { key: string, title: string, disabled: boolean, onClick: () => void }[] = [];
  if (workflow && workflowTargetedConceptDefinition) {
    transitions
      .filter((transition) => transition[WorkflowTransition_From] === value?.id)
      .filter((transition) => transition.navigateOrNull(WorkflowTransition_To))
      .filter((transition) => isInstanceOf(transition.navigate(WorkflowTransition_To), workflowTargetedConceptDefinition.id))
      .filter((transition) => isEntryValidForInstance(store, fieldId, transition[WorkflowTransition_To] as string, parametersMapping))
      .filter((transition) => isTransitionValidForUser(store, fieldId, transition.id, loggedUserId, conceptId))
      .forEach((transition) => {
        const transitionConfiguration = store.withAssociation(WorkflowFieldTransition)
          .withRole(WorkflowFieldTransition_Role_WorkflowField, fieldId)
          .withRole(WorkflowFieldTransition_Role_Transition, transition.id)
          .getObjectOrNull();
        const transitionFilter = transitionConfiguration?.[WorkflowFieldTransition_Filters] as Filters | undefined;
        const disabled = isReadOnly
          || getFilterFunction(store, transitionFilter)?.(parametersMapping) === false;
        actions.push({
          key: transition.id,
          title: formatOrUndef(transition[WorkflowTransition_Name]),
          disabled,
          onClick: () => {
            if (subFieldId) {
              store.withAssociation(WorkflowFieldFieldsTransitionExecution)
                .withRole(WorkflowFieldFieldsTransitionExecution_Role_WorkflowField, fieldId)
                .withRole(WorkflowFieldFieldsTransitionExecution_Role_Concept, conceptId)
                .withRole(WorkflowFieldFieldsTransitionExecution_Role_Field, subFieldId)
                .updateObject({ [transition.id]: uuid() });
            } else {
              store.withAssociation(WorkflowFieldTransitionExecution)
                .withRole(WorkflowFieldTransitionExecution_Role_WorkflowField, fieldId)
                .withRole(WorkflowFieldTransitionExecution_Role_Concept, conceptId)
                .updateObject({ [transition.id]: uuid() });
            }
          },
        });
      });
  }

  let input: ReactElement;
  if (transitions.length > 0 && usageVariant !== UsageVariant.inline) {
    input = (
      <WorkflowInput
        placeholder={usageVariant === UsageVariant.inTable ? undefined : i18n`Run transition`}
        statusIcon={displayError ? { icon: IconName.dangerous, color: IconColorVariant.error, message: displayError } : undefined}
        statusOption={selectedOption}
        getTransitions={() => actions}
        getOverrideStatus={canChangeStatus ? { getOptions: computeOptions, onSelect } : undefined}
        isEditing={isEditing}
        withMultiplayerOutline={isEditing}
        onEditionStart={() => onEnterEdition(conceptId, fieldId)}
        onEditionStop={() => onExitEdition(conceptId, fieldId)}
        restingTooltip={() => getLastUpdateBy(store, conceptId, fieldId, undefined)}
        editOnMount={focusOnMount}
        readOnly={isReadOnly}
      />
    );
  } else {
    input = (
      <SearchAndSelect
        placeholder={i18n`Select instance`}
        selectedOption={selectedOption}
        computeOptions={computeOptions}
        onSelect={onSelect}
        statusIcon={displayError ? { icon: IconName.dangerous, color: IconColorVariant.error, message: displayError } : undefined}
        readOnly={isReadOnly || !canChangeStatus}
        onEditionStart={() => onEnterEdition(conceptId, fieldId)}
        onEditionStop={() => onExitEdition(conceptId, fieldId)}
        editOnMount={focusOnMount}
        isEditing={isEditing}
        withMultiplayerOutline={isEditing}
        restingTooltip={() => getLastUpdateBy(store, conceptId, fieldId, undefined)}
      />
    );
  }

  if (usageVariant === UsageVariant.inTable) {
    return input;
  } else if (displayType === WorkflowRelationDisplayType.Value) {
    return input;
  } else {
    const impactedSubFieldNames: string[] | undefined = subFieldId ? undefined : store.withAssociation(WorkflowFieldFields)
      .withRole(WorkflowFieldFields_Role_WorkflowField, fieldId)
      .list()
      .map((workflowFieldFields) => workflowFieldFields.navigateRoleOrNull(WorkflowFieldFields_Role_Field))
      .filter(filterNullOrUndefined)
      .map((subField) => formatOrUndef(subField[Field_Title] as string | undefined));

    const wrapTooltip = (button: ReactElement) => {
      if (impactedSubFieldNames && impactedSubFieldNames.length > 0) {
        return (
          <Tooltip key={button.key} title={impactedSubFieldNames.join('\n')}>
            {button}
          </Tooltip>
        );
      } else {
        return button;
      }
    };

    if (displayType === WorkflowRelationDisplayType.Button) {
      return (
        <SpacingLine>
          {actions.map(actionToButton).map(wrapTooltip)}
        </SpacingLine>
      );
    } else if (displayType === WorkflowRelationDisplayType.ButtonAndValue) {
      return (
        <SpacingLine>
          {actions.map(actionToButton).map(wrapTooltip)}
          {input}
        </SpacingLine>
      );
    } else if (displayType === WorkflowRelationDisplayType.Color) {
      return (
        <WorkflowFieldColorRenderer
          selectedOption={selectedOption}
          computeOptions={computeOptions}
          onSelect={onSelect}
          actions={actions}
          impactedSubFieldNames={impactedSubFieldNames}
          canChangeStatus={canChangeStatus}
          readOnly={isReadOnly}
        />
      );
    } else {
      return (
        <SpacingLine>
          {input}
          {actions.map(actionToButton).map(wrapTooltip)}
        </SpacingLine>
      );
    }
  }
};

export default WorkflowFieldRenderer;
