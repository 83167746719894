import type { Migration, StoreObject } from 'yooi-store';
import { joinObjects } from 'yooi-utils';
import { MigrationDropUselessFieldLocalOverride, MigrationFieldFilter, MigrationRemWidthInViews, MigrationUpdateMatrixView } from '../../src/Migrations';
import type { InstanceReferenceType } from '../conceptModule';
import { PathStepType } from '../conceptModule';
import {
  AssociationField,
  AssociationField_Definition, AssociationField_Field_TargetFilter,
  AssociationField_SourceRole,
  AssociationFieldDefinition_Role1Type,
  AssociationFieldDefinition_Role2Type,
  Concept,
  ConceptDefinition_Color,
  ConceptDefinition_MatrixAbs,
  ConceptDefinition_MatrixDependency,
  ConceptDefinition_MatrixLabel,
  ConceptDefinition_MatrixOrd,
  EmbeddingField,
  EmbeddingField_FromType,
  EmbeddingField_ToType,
  Field_Formula,
  KinshipRelationField,
  RelationMultipleField, RelationMultipleField_Field_TargetFilter,
  RelationMultipleField_TargetType,
  RelationSingleField, RelationSingleField_Field_TargetFilter,
  RelationSingleField_TargetType,
  StakeholdersField,
  StakeholdersField_TargetType,
  Workflow_TargetedConceptDefinition,
  WorkflowField,
  WorkflowField_Workflow,
} from '../conceptModule/ids';
import { ViewType } from '../dashboardModule';
import type { TypeHelperRevision1 } from '../typeModule';
import { Class_Instances, Instance_Of, TypeModuleId } from '../typeModule/ids';
import { FieldBlockDisplay, FieldBlockDisplay_FieldDisplayConfiguration, FieldBlockDisplay_FieldPath } from './ids';

const migrations: Record<number, Migration> = {
  [MigrationUpdateMatrixView]: {
    handler: ({ getObjectOrNull, getObject, updateObject }) => {
      interface GlobalDimensionStep {
        type: PathStepType.global,
      }

      interface DimensionStep {
        type: PathStepType.dimension,
        conceptDefinitionId: string,
      }

      interface FilterStep {
        type: PathStepType.filter,
      }

      interface MappingStep {
        type: PathStepType.mapping,
        mapping: { id: string, type: InstanceReferenceType },
      }

      interface FieldStep {
        type: PathStepType.field,
        fieldId: string,
        embeddingFieldId?: string,
      }

      type PathStep = GlobalDimensionStep | DimensionStep | FilterStep | MappingStep | FieldStep;

      interface OldMatrixViewStoredDefinition {
        type: ViewType.Matrix,
        id: string,
        label?: string,
        readOnly?: boolean,
        withCustomConfig?: boolean,
        defaultMatrixOrdId?: string,
        defaultMatrixAbsId?: string,
        defaultMatrixDependencyId?: string,
        defaultMatrixLabelId?: string,
        defaultMatrixColorId?: string,
      }

      interface NewMatrixViewStoredDefinition {
        type: ViewType.Matrix,
        id: string,
        label?: string,
        readOnly?: boolean,
        yAxisFieldId?: string,
        xAxisFieldId?: string,
        dependenciesFieldId?: string,
        labelFieldId?: string,
        colorFieldId?: string,
        topLeftQuadrant?: { color: string | undefined, name: string | undefined },
        topRightQuadrant?: { color: string | undefined, name: string | undefined },
        bottomLeftQuadrant?: { color: string | undefined, name: string | undefined },
        bottomRightQuadrant?: { color: string | undefined, name: string | undefined },
        showBacklog?: boolean,
      }

      interface FieldDisplayConfiguration {
        viewDefinitions: ({ type: Exclude<ViewType, ViewType.Matrix> } | OldMatrixViewStoredDefinition)[],
        [key: string]: unknown,
      }

      const getPathReturnedConceptDefinitionId = (path: PathStep[]): string | undefined => {
        let returnTypeId: string | undefined;
        for (let i = 0; i < path.length; i += 1) {
          const nStep = path[i];
          if (nStep?.type === PathStepType.global) {
            // nothing is returned
          } else if (nStep?.type === PathStepType.dimension) {
            // a list of concept of dimension step conceptDefinition is returned
            returnTypeId = nStep.conceptDefinitionId;
          } else if (nStep?.type === PathStepType.mapping) {
            // only multiplicity change
          } else if (nStep?.type === PathStepType.field) {
            if (getObjectOrNull(nStep.fieldId) === null) {
              return undefined;
            }
            const stepField = getObject(nStep.fieldId);
            const previousStep = path[i - 1];
            if (previousStep && previousStep?.type === PathStepType.global) {
              // field
              returnTypeId = undefined;
            } else if (stepField[Instance_Of] as string === KinshipRelationField) {
              if (nStep.embeddingFieldId) {
                const embeddingField = getObjectOrNull(nStep.embeddingFieldId);
                if (embeddingField && embeddingField[EmbeddingField_FromType]) {
                  returnTypeId = embeddingField[EmbeddingField_FromType] as string;
                } else {
                  returnTypeId = undefined;
                }
              } else {
                // multiple returned types not supported
                returnTypeId = undefined;
              }
            } else if (stepField[Instance_Of] === KinshipRelationField) {
              returnTypeId = Concept;
            } else if (stepField[Instance_Of] === RelationSingleField) {
              returnTypeId = getObjectOrNull(stepField.id)?.navigateOrNull(RelationSingleField_TargetType)?.id;
            } else if (stepField[Instance_Of] === WorkflowField) {
              returnTypeId = getObjectOrNull(stepField.id)?.navigateOrNull(WorkflowField_Workflow)?.navigateOrNull(Workflow_TargetedConceptDefinition)?.id;
            } else if (stepField[Instance_Of] === AssociationField) {
              const field = getObjectOrNull(stepField.id);
              if (field === null) {
                returnTypeId = undefined;
              } else if (field[Field_Formula]) {
                returnTypeId = Concept;
              } else {
                const sourceRole = field[AssociationField_SourceRole];
                const targetRoleField = sourceRole === 1 ? AssociationFieldDefinition_Role2Type : AssociationFieldDefinition_Role1Type;
                const fieldDefinition = field.navigateOrNull(AssociationField_Definition);
                returnTypeId = fieldDefinition?.navigateOrNull(targetRoleField)?.id;
              }
            } else if (stepField[Instance_Of] === RelationMultipleField) {
              returnTypeId = getObjectOrNull(stepField.id)?.navigateOrNull(RelationMultipleField_TargetType)?.id;
            } else if (stepField[Instance_Of] === EmbeddingField) {
              returnTypeId = getObjectOrNull(stepField.id)?.navigateOrNull(EmbeddingField_ToType)?.id;
            } else if (stepField[Instance_Of] === StakeholdersField) {
              returnTypeId = getObjectOrNull(stepField.id)?.navigateOrNull(StakeholdersField_TargetType)?.id;
            } else {
              returnTypeId = undefined;
            }
          } else if (nStep?.type === PathStepType.filter) {
            // only multiplicity change
          }
        }
        return returnTypeId;
      };

      const updateViews = (views: FieldDisplayConfiguration['viewDefinitions'], returnedConceptDefinition: StoreObject | undefined) => (
        views.map((oldView) => {
          if (oldView.type === ViewType.Matrix) {
            if (returnedConceptDefinition === undefined) {
              return {
                type: ViewType.Matrix,
                id: oldView.id,
                label: oldView.label,
                readOnly: oldView.readOnly,
              } satisfies NewMatrixViewStoredDefinition;
            } else if (oldView.withCustomConfig) {
              return {
                type: ViewType.Matrix,
                id: oldView.id,
                label: oldView.label,
                readOnly: oldView.readOnly,
                yAxisFieldId: oldView.defaultMatrixOrdId,
                xAxisFieldId: oldView.defaultMatrixAbsId,
                dependenciesFieldId: oldView.defaultMatrixDependencyId,
                labelFieldId: oldView.defaultMatrixLabelId,
                colorFieldId: oldView.defaultMatrixColorId,
                showBacklog: true,
              } satisfies NewMatrixViewStoredDefinition;
            } else {
              return {
                type: ViewType.Matrix,
                id: oldView.id,
                label: oldView.label,
                readOnly: oldView.readOnly,
                yAxisFieldId: typeof returnedConceptDefinition[ConceptDefinition_MatrixOrd] === 'string' ? returnedConceptDefinition[ConceptDefinition_MatrixOrd] : undefined,
                xAxisFieldId: typeof returnedConceptDefinition[ConceptDefinition_MatrixAbs] === 'string' ? returnedConceptDefinition[ConceptDefinition_MatrixAbs] : undefined,
                dependenciesFieldId: typeof returnedConceptDefinition[ConceptDefinition_MatrixDependency] === 'string' ? returnedConceptDefinition[ConceptDefinition_MatrixDependency] : undefined,
                labelFieldId: typeof returnedConceptDefinition[ConceptDefinition_MatrixLabel] === 'string' ? returnedConceptDefinition[ConceptDefinition_MatrixLabel] : undefined,
                colorFieldId: typeof returnedConceptDefinition[ConceptDefinition_Color] === 'string' ? returnedConceptDefinition[ConceptDefinition_Color] : undefined,
                showBacklog: true,
              } satisfies NewMatrixViewStoredDefinition;
            }
          } else {
            return oldView;
          }
        })
      );

      getObject(FieldBlockDisplay)
        .navigateBack(Class_Instances)
        .filter(({ [FieldBlockDisplay_FieldDisplayConfiguration]: configuration }) => (
          (configuration as FieldDisplayConfiguration | undefined)?.viewDefinitions?.some(({ type }) => type === ViewType.Matrix)
          ?? false
        ))
        .forEach((blockDisplay) => {
          const configuration = blockDisplay[FieldBlockDisplay_FieldDisplayConfiguration] as FieldDisplayConfiguration;
          const path = blockDisplay[FieldBlockDisplay_FieldPath] as PathStep[] | undefined;

          const returnedConceptDefinitionId = path !== undefined ? getPathReturnedConceptDefinitionId(path) : undefined;
          const returnedConceptDefinition = returnedConceptDefinitionId !== undefined ? getObjectOrNull(returnedConceptDefinitionId) ?? undefined : undefined;

          const newConfiguration = joinObjects(
            configuration,
            { viewDefinitions: updateViews(configuration.viewDefinitions, returnedConceptDefinition) }
          );

          updateObject(blockDisplay.id, { [FieldBlockDisplay_FieldDisplayConfiguration]: newConfiguration });
        });
    },
  },
  [MigrationDropUselessFieldLocalOverride]: {
    handler: ({ deleteObject }) => {
      const FieldLocalOverride_IFrameFieldUrl = '652f52cf-9ba3-437f-a368-14ddb4d223ba';
      const FieldLocalOverride_TextConstantFieldText = '031fe2fa-fab6-4468-8136-60cca6c04b02';

      deleteObject(FieldLocalOverride_IFrameFieldUrl);
      deleteObject(FieldLocalOverride_TextConstantFieldText);
    },
  },
  [MigrationRemWidthInViews]: {
    handler: ({ getObject, updateObject }) => {
      interface OldFieldDisplayConfiguration {
        viewDefinitions: {
          type: string,
          series?: { displayOptions?: { width?: number | undefined, [key: string]: unknown }, [key: string]: unknown }[],
          [key: string]: unknown,
        }[],
        [key: string]: unknown,
      }

      interface NewFieldDisplayConfiguration {
        viewDefinitions: {
          type: string,
          series?: { displayOptions?: { width?: { type: 'percent' | 'rem', value: number } | undefined, [key: string]: unknown }, [key: string]: unknown }[],
          [key: string]: unknown,
        }[],
        [key: string]: unknown,
      }

      const migrateViews = (oldView: OldFieldDisplayConfiguration) => (
        joinObjects(
          oldView,
          {
            viewDefinitions: oldView.viewDefinitions.map((view) => (
              joinObjects(
                view,
                {
                  series: view?.series?.map((s) => (
                    joinObjects(
                      s,
                      {
                        displayOptions: s.displayOptions === undefined
                          ? undefined
                          : joinObjects(s.displayOptions, { width: s.displayOptions.width === undefined ? undefined : { type: 'percent', value: s.displayOptions.width } }),
                      }
                    )
                  )),
                }
              )
            )),
          }
        )
      ) as NewFieldDisplayConfiguration;

      getObject(FieldBlockDisplay)
        .navigateBack(Class_Instances)
        .filter((field) => (
          (field[FieldBlockDisplay_FieldDisplayConfiguration] as OldFieldDisplayConfiguration | undefined)?.viewDefinitions?.some(({ series }) => series?.some(({ displayOptions }) => typeof displayOptions?.width === 'number'))
        ))
        .forEach((field) => {
          updateObject(field.id, {
            [FieldBlockDisplay_FieldDisplayConfiguration]: migrateViews(field[FieldBlockDisplay_FieldDisplayConfiguration] as OldFieldDisplayConfiguration),
          });
        });
    },
  },
  [MigrationFieldFilter]: {
    dependencies: [TypeModuleId],
    handler: (_, helpers) => {
      const typeHelper = helpers.typeHelper as TypeHelperRevision1;
      typeHelper.property(RelationSingleField, RelationSingleField_Field_TargetFilter);
      typeHelper.property(RelationMultipleField, RelationMultipleField_Field_TargetFilter);
      typeHelper.property(AssociationField, AssociationField_Field_TargetFilter);
    },
  },
};

export default migrations;
