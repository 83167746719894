import type { Property } from 'csstype';
import type { DeepPartial } from 'yooi-utils';
import { joinObjects, newError } from 'yooi-utils';
import type { Font, FontVariant } from './fontDefinition';
import type light from './light';

interface RawColor {
  [key: string]: RawColor | string,
}

export interface RawTheme {
  name: string,
  font: Record<FontVariant, Font>,
  color: RawColor,
}

type MapValueToPropertyColor<T> = {
  [P in keyof T]: T[P] extends string ? Property.Color : T[P] extends object ? MapValueToPropertyColor<T[P]> : T[P];
};

export interface Theme {
  name: string,
  font: Record<FontVariant, Font>,
  color: MapValueToPropertyColor<typeof light['color']>,
}

const deepMerge = <T extends RawColor>(left: T, right: DeepPartial<T>): T => (
  Object.fromEntries(
    Object.entries(left)
      .map(([key, leftValue]) => {
        const rightValue = right[key];
        if (rightValue === undefined) {
          return [key, leftValue];
        } else if (typeof leftValue === 'string' && typeof rightValue === 'string') {
          return [key, rightValue];
        } else if (typeof leftValue === 'object' && typeof rightValue === 'object') {
          return [key, deepMerge(leftValue, rightValue)];
        } else {
          throw newError('Invalid value type');
        }
      })
  ) as T
);

export const extendsTheme = ({ name, color, ...props }: Theme, override: { name: string, color: DeepPartial<Theme['color']> }): Theme => (joinObjects(
  {
    name: override.name,
    color: deepMerge(color, override.color),
  },
  props
));

export const testables = {
  deepMerge,
};
