import type { FunctionComponent } from 'react';
import type { PlatformConfigurationStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import {
  CurrentPlatformConfiguration,
  PlatformConfiguration_AutoLogoutTime,
  PlatformConfiguration_IntegrationDefaultExpiration,
} from 'yooi-modules/modules/platformConfigurationModule/ids';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import StoreNumberPickerInput from '../../_global/input/StoreNumberPickerInput';

const GeneralTab: FunctionComponent = () => {
  const store = useStore();

  const platformConfiguration = store.getObject<PlatformConfigurationStoreObject>(CurrentPlatformConfiguration);

  return (
    <VerticalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle
          title={i18n`Auto logout inactive users`}
          infoTooltip={i18n`After n minutes of inactivity, user is automatically logged out.`}
        />
        <BlockContent>
          <StoreNumberPickerInput
            initialValue={platformConfiguration[PlatformConfiguration_AutoLogoutTime]}
            onSubmit={(newTime) => {
              const autoLogoutTime = typeof newTime === 'string' ? Number.parseInt(newTime, 10) : newTime;
              if (autoLogoutTime !== null && Number.isSafeInteger(autoLogoutTime) && autoLogoutTime > 0) {
                store.updateObject(CurrentPlatformConfiguration, { [PlatformConfiguration_AutoLogoutTime]: autoLogoutTime });
              } else {
                store.updateObject(CurrentPlatformConfiguration, { [PlatformConfiguration_AutoLogoutTime]: null });
              }
            }}
            placeholder="-"
            min={1}
            unit={i18n`min`}
          />
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle
          title={i18n`Integration default expiration`}
          infoTooltip={i18n`By default integration token will expire after n days.`}
        />
        <BlockContent>
          <StoreNumberPickerInput
            initialValue={platformConfiguration[PlatformConfiguration_IntegrationDefaultExpiration]}
            onSubmit={(newTime) => {
              const autoLogoutTime = typeof newTime === 'string' ? Number.parseInt(newTime, 10) : newTime;
              if (autoLogoutTime !== null && Number.isSafeInteger(autoLogoutTime) && autoLogoutTime > 0) {
                store.updateObject(CurrentPlatformConfiguration, { [PlatformConfiguration_IntegrationDefaultExpiration]: autoLogoutTime });
              }
            }}
            min={1}
            unit={i18n`day(s)`}
          />
        </BlockContent>
      </HorizontalBlock>
    </VerticalBlock>
  );
};

export default GeneralTab;
