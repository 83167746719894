// This file is automatically updated by the generate-constants script. Do not update it manually.

export const TypeModuleId = 'd5de3e09-7f07-55df-be86-ba8e7ea00ca5';
export const Association_RoleTypes = 'd3a9f2c9-8779-547b-bb6e-63d17fa68b3d';
export const Association_VarRolesType = '4accd491-6be2-546d-a380-39b124df0f1a';
export const Class_Extend = '189aa1f3-d34a-552d-953f-89bd906fa43f';
export const Class_IsExternal = '1ada7e2a-905a-5782-b143-ddfbd313a041';
export const Class_IsInstanceOf = 'da72219b-2361-436e-8836-8b1a67744996';
export const Instance_Of = 'ee3b77dc-4172-5aae-add8-627a43b06783';
export const ModelAssociation = '8e9d80de-01bb-5094-8f3b-c8a4cf98ba40';
export const ModelProperty = '1a045b71-4fca-51b5-b9f9-9817a1808277';
export const ModelType = 'c4fe94d5-e0b4-5575-b667-7a0fbfdca273';
export const Property_OfClass = '48bf4089-912a-52b9-a246-91851c00d009';
export const Property_TargetClass = '5c34187d-c4c9-500b-8063-da3c5110bc9d';
export const Class_Extensions = Class_Extend;
export const Class_Instances = Instance_Of;
export const Class_Properties = Property_OfClass;
export const Class_TargetedByProperties = Property_TargetClass;
