import type { FunctionComponent } from 'react';
import type { ConceptDefinitionStoreObject } from 'yooi-modules/modules/conceptModule';
import { getConceptDefinitionValidFields, isConceptValid } from 'yooi-modules/modules/conceptModule';
import {
  ConceptDefinition,
  ConceptDefinition_Description,
  ConceptDefinition_IsCore,
  ConceptDefinition_Name,
  ConceptDefinition_RestrictedAccess,
} from 'yooi-modules/modules/conceptModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import { compareString, comparing } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import Typo from '../../../components/atoms/Typo';
import NumberPicker from '../../../components/inputs/NumberPicker';
import Link from '../../../components/molecules/Link';
import { TableSortDirection } from '../../../components/molecules/Table';
import BaseLayout from '../../../components/templates/BaseLayout';
import BlockContent from '../../../components/templates/BlockContent';
import DataTable from '../../../components/templates/DataTable';
import Header from '../../../components/templates/Header';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useActivity from '../../../store/useActivity';
import useStore from '../../../store/useStore';
import useUpdateActivity from '../../../store/useUpdateActivity';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { safeSessionStorageValue } from '../../../utils/sessionStorageUtils';
import useDeleteModal from '../../../utils/useDeleteModal';
import useNavigation from '../../../utils/useNavigation';
import useNewLineFocus, { useFocusNewLineNotify } from '../../../utils/useNewLineFocus';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import { createConceptDefinition } from '../../_global/conceptDefinitionUtils';
import SearchTextButton from '../../_global/filter/SearchTextButton';
import type { FilterConfiguration } from '../../_global/filter/useFilterSessionStorage';
import HeaderStatic from '../../_global/HeaderStatic';
import StoreTextInputField from '../../_global/input/StoreTextInputField';
import { searchFilterFunction } from '../../_global/listFilterFunctions';
import type { NavigationFilter } from '../../_global/navigationUtils';
import { getNavigationPayload } from '../../_global/navigationUtils';
import { share } from '../../_global/shareUtils';
import TopBar from '../../_global/topBar/TopBar';
import useFilterAndSort, { buildNumberColumnComparatorHandler } from '../../_global/useFilterAndSort';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: spacingRem.s,
    paddingBottom: spacingRem.xs,
  },
}, 'organizationSettingsPage');

const OrganizationSettingsPage: FunctionComponent = () => {
  const classes = useStyles();

  const store = useStore();
  const activity = useActivity();
  const updateActivity = useUpdateActivity();

  const [newLineFocus, setNewLineFocus] = useNewLineFocus();
  const focusNewLineNotify = useFocusNewLineNotify();

  const navigation = useNavigation<NavigationFilter>();

  const filterId = `admin_${ConceptDefinition}`;
  const filters = safeSessionStorageValue<FilterConfiguration | undefined>(filterId);
  const conceptDefinitionFilterFunction = searchFilterFunction(store, filters?.nameSearch, [ConceptDefinition_Name]);
  const {
    generatePageList,
    sortCriteria,
    doSort,
    forceShowId,
  } = useFilterAndSort(
    filterId,
    store.getObject(ConceptDefinition)
      .navigateBack<ConceptDefinitionStoreObject>(Class_Instances)
      .filter((conceptDefinition) => !conceptDefinition[ConceptDefinition_RestrictedAccess])
      .map((conceptDefinition) => ({
        key: conceptDefinition.id,
        conceptDefinition,
        instancesCount: conceptDefinition.navigateBack(Class_Instances).filter(({ id }) => isConceptValid(store, id)).length,
        fieldsCount: getConceptDefinitionValidFields(store, conceptDefinition.id).length,
      })),
    conceptDefinitionFilterFunction ? (item) => conceptDefinitionFilterFunction(item.conceptDefinition) : undefined,
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case 'fieldsCount':
          case 'instancesCount':
            return buildNumberColumnComparatorHandler(key, direction);
          case ConceptDefinition_Name:
          case ConceptDefinition_Description:
            return { comparator: comparing(compareString, direction === TableSortDirection.desc), extractValue: (item) => item.conceptDefinition[key] };
          default:
            return undefined;
        }
      },
      initial: { key: ConceptDefinition_Name, direction: TableSortDirection.asc },
    }
  );
  const { list, pagination } = generatePageList(25);

  const [doDelete, deleteModal] = useDeleteModal<string>({
    doDelete: (conceptDefinitionId) => {
      store.deleteObject(conceptDefinitionId);
    },
    shouldConfirm: (conceptDefinitionId) => (
      store.getObject<ConceptDefinitionStoreObject>(conceptDefinitionId)
        .navigateBack(Class_Instances)
        .filter(({ id: conceptId }) => isConceptValid(store, conceptId))
        .map(({ id: conceptId }) => conceptId)
        .length > 0),
    getModalProps: () => ({
      title: i18n`Are you sure that you want to delete this concept?`,
      content: (<Typo>{i18n`It will also remove all associated elements.`}</Typo>),
    }),
  });

  return (
    <BaseLayout
      topBar={(<TopBar />)}
      header={(
        <Header
          firstLine={(<HeaderStatic text={i18n`Information model configuration`} actions={[<Link key="list" title={i18n`Access information model`} to="/organization" />]} />)}
        />
      )}
      content={(
        <>
          <VerticalBlock compact>
            <BlockContent padded>
              <div className={classes.headerContainer}>
                <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
                  <SearchTextButton element={filterId} placeholder={i18n`Search`} />
                </SizeContextProvider>
              </div>
            </BlockContent>
            <DataTable
              list={list}
              pagination={pagination}
              sortCriteria={sortCriteria}
              doSort={doSort}
              getNavigationPayload={({ conceptDefinition: { id } }) => getNavigationPayload(navigation, `${id}`, `/settings/organization/${id}`)}
              onNewItem={() => {
                const newId = createConceptDefinition(store, undefined);
                setNewLineFocus(newId);
                forceShowId(newId);
                focusNewLineNotify(newId);
              }}
              newItemIcon={IconName.add}
              newItemTitle={i18n`Create`}
              newLineFocus={newLineFocus?.current}
              columnsDefinition={[
                {
                  propertyId: ConceptDefinition_Name,
                  name: i18n`Name`,
                  width: 40,
                  sortable: true,
                  focusable: true,
                  openButton: () => true,
                  cellRender: ({ conceptDefinition: { id, [ConceptDefinition_Name]: name } }, focus) => (
                    <StoreTextInputField
                      initialValue={name}
                      onSubmit={(title) => store.updateObject(id, { [ConceptDefinition_Name]: title })}
                      focusOnMount={focus}
                      onEditionStart={() => updateActivity.onEnterEdition(id, ConceptDefinition_Name)}
                      onEditionStop={() => updateActivity.onExitEdition(id, ConceptDefinition_Name)}
                      isEditing={activity.listEditor(id, ConceptDefinition_Name).length > 0}
                    />
                  ),
                },
                {
                  propertyId: ConceptDefinition_Description,
                  name: i18n`Description`,
                  width: 50,
                  sortable: true,
                  cellRender: ({ conceptDefinition: { id, [ConceptDefinition_Description]: description } }) => (
                    <StoreTextInputField
                      initialValue={description}
                      onSubmit={(value) => store.updateObject(id, { [ConceptDefinition_Description]: value })}
                      onEditionStart={() => updateActivity.onEnterEdition(id, ConceptDefinition_Description)}
                      onEditionStop={() => updateActivity.onExitEdition(id, ConceptDefinition_Description)}
                      isEditing={activity.listEditor(id, ConceptDefinition_Description).length > 0}
                    />
                  ),
                },
                {
                  propertyId: 'instancesCount',
                  name: i18n`# values`,
                  width: 5,
                  sortable: true,
                  cellRender: ({ instancesCount }) => (<NumberPicker value={instancesCount} onChange={() => {}} readOnly />),
                },
                {
                  propertyId: 'fieldsCount',
                  name: i18n`# Fields`,
                  width: 5,
                  sortable: true,
                  cellRender: ({ fieldsCount }) => (<NumberPicker value={fieldsCount} onChange={() => {}} readOnly />),
                },
              ]}
              linesActions={({ conceptDefinition }) => [
                {
                  key: 'share',
                  icon: IconName.link,
                  name: i18n`Copy link`,
                  onClick: () => share(store, getNavigationPayload(navigation, `${conceptDefinition.id}`, `/settings/organization/${conceptDefinition.id}`)),
                },
                {
                  key: 'delete',
                  name: i18n`Delete`,
                  hidden: Boolean(conceptDefinition[ConceptDefinition_IsCore]),
                  icon: IconName.delete,
                  onClick: () => doDelete(conceptDefinition.id),
                  danger: true,
                },
              ]}
            />
          </VerticalBlock>
          {deleteModal}
        </>
      )}
    />
  );
};

export default OrganizationSettingsPage;
