import { newFunctionLibrary } from '../engine/functionLibraryBuilder';
import { numberType } from '../typeSystem';
import { checkArgumentCount } from './utils';

export const dateTimeFunctions = newFunctionLibrary()
  .addFunction(
    'NOW',
    (name) => ({
      isDeterminist: false,
      resolve: (argTypes) => (
        checkArgumentCount(name, argTypes, 0, 0) ?? {
          type: numberType,
          transpile: () => 'Date.now()',
          jsFunction: Date.now,
        }),
    })
  )
  .build();
