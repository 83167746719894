import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import makeSelectorsClasses from '../../../../../utils/makeSelectorsClasses';
import makeStyles from '../../../../../utils/makeStyles';
import type { AnchorPositionType, ValidLineType } from './types';
import useOnHoverArrowRef, { useHoveredArrow } from './useOnHoverArrowRef';

const selectorsClasses = makeSelectorsClasses('onHoverHandler');

const computeStartingAnchorPosition = (xStart: number, yStart: number, xEnd: number): { xAnchor1: number, yAnchor1: number } => ({
  xAnchor1: xStart + (xEnd - xStart) / 2, yAnchor1: yStart,
});

const computeEndingAnchorPosition = (xStart: number, xEnd: number, yEnd: number): { xAnchor2: number, yAnchor2: number } => ({
  xAnchor2: xEnd - (xEnd - xStart) / 2, yAnchor2: yEnd,
});

const computePathString = ({ xStart, yStart, xAnchor1, yAnchor1, xAnchor2, yAnchor2, xEnd, yEnd, lineStyle }: {
  xStart: number, yStart: number,
  xAnchor1: number, yAnchor1: number, xAnchor2: number, yAnchor2: number,
  xEnd: number, yEnd: number,
  lineStyle: string,
}): string => {
  let linePath = `M${xStart},${yStart} `;
  if (['curve', 'angle'].includes(lineStyle)) {
    linePath += `${
      lineStyle === 'curve' ? 'C' : ''
    }${xAnchor1},${yAnchor1} ${xAnchor2},${yAnchor2} `;
  }
  linePath += `${xEnd},${yEnd}`;
  return linePath;
};

const useStyles = makeStyles({
  clickableArrow: {
    opacity: 0,
    pointerEvents: 'all',
  },
  drawnLine: {
    pointerEvents: 'none',
    fill: 'none',
  },
  hideDrawLine: {
    opacity: 0,
  },
  hoverAndClickableLine: {
    opacity: 0,
    fill: 'none',
    pointerEvents: 'visibleStroke',
  },
  clickablePath: {
    pointerEvents: 'visibleStroke',
  },
  hoverHandler: {
    cursor: 'pointer',
    [`&:hover .${selectorsClasses.onHoverHandler}`]: {
      opacity: 1,
    },
  },
}, 'svgClickableArrow');

interface SvgClickableArrowProps {
  xStart: number,
  xEnd: number,
  yStart: number,
  yEnd: number,
  lineType: ValidLineType,
  markerOrientation: AnchorPositionType,
  arrowKey: string,
  hasSelectedArrows: boolean,
}

const SvgClickableArrow: FunctionComponent<SvgClickableArrowProps> = ({ xStart, xEnd, yStart, yEnd, lineType, markerOrientation, arrowKey, hasSelectedArrows }) => {
  const classes = useStyles();

  // Starting position
  const { xAnchor1, yAnchor1 } = computeStartingAnchorPosition(xStart, yStart, xEnd);
  // Ending position
  const { xAnchor2, yAnchor2 } = computeEndingAnchorPosition(xStart, xEnd, yEnd);

  const pathString = computePathString({ xStart, yStart, xAnchor1, yAnchor1, xAnchor2, yAnchor2, xEnd, yEnd, lineStyle: lineType.lineStyle });
  const { arrowClickHandler, strokeColor, strokeWidth, marker: { arrowThickness, arrowLength } } = lineType;

  const hoveredArrow = useHoveredArrow();
  const tooltipRef = useOnHoverArrowRef(arrowKey, !hasSelectedArrows);

  return (
    <g ref={tooltipRef} className={classnames({ [classes.hoverHandler]: !hasSelectedArrows })}>
      <path
        className={classnames({
          [classes.hideDrawLine]: !lineType.showArrowLabel,
          [classes.drawnLine]: true,
          [selectorsClasses.onHoverHandler]: true,
        })}
        d={pathString}
        style={{ stroke: strokeColor, strokeWidth: arrowClickHandler?.hoveredStrokeWidth ?? strokeWidth }}
      />
      <path
        className={classnames({
          [classes.hoverAndClickableLine]: true,
          [classes.clickablePath]: !hasSelectedArrows,
        })}
        d={pathString}
        style={{ strokeWidth: arrowClickHandler?.clickableStrokeWidth ?? strokeWidth }}
        onClick={() => {
          if (!hasSelectedArrows && arrowClickHandler) {
            arrowClickHandler.onArrowClick();
          }
          hoveredArrow.reset();
        }}
      />
      <path
        className={classnames({ [selectorsClasses.onHoverHandler]: !hasSelectedArrows, [classes.clickableArrow]: !hasSelectedArrows })}
        d={`M0,0 L0,${arrowThickness * 2} L${arrowLength},${arrowThickness} z`}
        transform={`translate(${xStart},${yStart - (arrowThickness)}) 
           ${markerOrientation === 'right' ? `rotate(180,0,${arrowThickness})` : ''}`}
        fill={strokeColor}
        onClick={() => {
          if (!hasSelectedArrows && arrowClickHandler) {
            arrowClickHandler.onArrowClick();
          }
          hoveredArrow.reset();
        }}
      />
    </g>
  );
};

export default SvgClickableArrow;
