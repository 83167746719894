import type { FunctionComponent } from 'react';
import type { ConceptDefinitionStoreObject } from 'yooi-modules/modules/conceptModule';
import { isConceptDefinitionField } from 'yooi-modules/modules/conceptModule';
import {
  ConceptDefinition_LibraryShowSwimlane,
  ConceptDefinition_SwimlaneColumnBy,
  ConceptDefinition_SwimlaneGroupBy,
  ConceptDefinition_SwimlaneProgress,
} from 'yooi-modules/modules/conceptModule/ids';
import Checkbox from '../../../../../components/atoms/Checkbox';
import { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import BlockContent from '../../../../../components/templates/BlockContent';
import BlockTitle from '../../../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../../../components/templates/HorizontalBlock';
import useStore from '../../../../../store/useStore';
import i18n from '../../../../../utils/i18n';
import { getFieldChip } from '../../../../_global/fieldUtils';
import { listColumnByFieldOptions, listGroupByFieldOptions, listProgressFieldOptions } from '../../../../_global/modelTypeUtils';

interface SwimlaneEditorProps {
  conceptDefinitionId: string,
}

const SwimlaneEditor: FunctionComponent<SwimlaneEditorProps> = ({ conceptDefinitionId }) => {
  const store = useStore();

  const conceptDefinition = store.getObject<ConceptDefinitionStoreObject>(conceptDefinitionId);

  const defaultSwimlaneGroupByFieldId = conceptDefinition.navigateOrNull(ConceptDefinition_SwimlaneGroupBy)?.id;
  const defaultSwimlaneProgressFieldId = conceptDefinition.navigateOrNull(ConceptDefinition_SwimlaneProgress)?.id;
  const defaultSwimlaneColumnByFieldId = conceptDefinition.navigateOrNull(ConceptDefinition_SwimlaneColumnBy)?.id;

  return (
    <>
      <HorizontalBlock asBlockContent>
        <BlockTitle
          title={i18n`Show swimlane`}
          infoTooltip={i18n`Show / hide library swimlane tab.`}
        />
        <BlockContent padded>
          <SpacingLine>
            <Checkbox
              checked={Boolean(conceptDefinition[ConceptDefinition_LibraryShowSwimlane])}
              onChange={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_LibraryShowSwimlane]: value })}
            />
          </SpacingLine>
        </BlockContent>
      </HorizontalBlock>
      {Boolean(conceptDefinition[ConceptDefinition_LibraryShowSwimlane]) && (
        <>
          <HorizontalBlock asBlockContent>
            <BlockTitle
              title={i18n`Default field for progress`}
              infoTooltip={i18n`Numeric field used to display the progress of each instance on the swimlane, by default`}
            />
            <BlockContent>
              <SearchAndSelect
                clearable
                placeholder={i18n`Select field`}
                selectedOption={
                  defaultSwimlaneProgressFieldId && store.getObjectOrNull(defaultSwimlaneProgressFieldId) !== null
                    ? getFieldChip(store, conceptDefinitionId, defaultSwimlaneProgressFieldId) : undefined
                }
                computeOptions={() => listProgressFieldOptions(store, conceptDefinitionId)}
                onSelect={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_SwimlaneProgress]: value?.id ?? null })}
                statusIcon={
                  !defaultSwimlaneProgressFieldId || isConceptDefinitionField(store, defaultSwimlaneProgressFieldId, conceptDefinitionId)
                    ? undefined : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
                }
              />
            </BlockContent>
          </HorizontalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle
              title={i18n`Default field for column by`}
              infoTooltip={i18n`Numeric field used to display the column of each instance on the swimlane, by default`}
            />
            <BlockContent>
              <SearchAndSelect
                clearable
                placeholder={i18n`Select field`}
                selectedOption={
                  defaultSwimlaneColumnByFieldId && store.getObjectOrNull(defaultSwimlaneColumnByFieldId) !== null
                    ? getFieldChip(store, conceptDefinitionId, defaultSwimlaneColumnByFieldId) : undefined
                }
                computeOptions={() => listColumnByFieldOptions(store, conceptDefinitionId)}
                onSelect={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_SwimlaneColumnBy]: value?.id ?? null })}
                statusIcon={
                  !defaultSwimlaneColumnByFieldId || isConceptDefinitionField(store, defaultSwimlaneColumnByFieldId, conceptDefinitionId)
                    ? undefined : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
                }
              />
            </BlockContent>
          </HorizontalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle
              title={i18n`Default field for group by`}
              infoTooltip={i18n`Relation used to group the instances on the swimlane, by default`}
            />
            <BlockContent>
              <SearchAndSelect
                clearable
                placeholder={i18n`Select field`}
                selectedOption={
                  defaultSwimlaneGroupByFieldId && store.getObjectOrNull(defaultSwimlaneGroupByFieldId) !== null
                    ? getFieldChip(store, conceptDefinitionId, defaultSwimlaneGroupByFieldId) : undefined
                }
                computeOptions={() => listGroupByFieldOptions(store, conceptDefinitionId, true)}
                onSelect={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_SwimlaneGroupBy]: value?.id ?? null })}
                statusIcon={
                  !defaultSwimlaneGroupByFieldId || isConceptDefinitionField(store, defaultSwimlaneGroupByFieldId, conceptDefinitionId)
                    ? undefined : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
                }
              />
            </BlockContent>
          </HorizontalBlock>
        </>
      )}
    </>
  );
};

export default SwimlaneEditor;
