import type { FunctionComponent } from 'react';
import { joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import { buildPadding, Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';

interface WidgetInvalidConfigurationProps {
  width: number,
  height: number,
  reason?: string,
}

const useStyles = makeStyles({
  error: joinObjects(
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buildPadding({ x: Spacing.xs })
  ),
}, 'widgetInvalidConfiguration');

const WidgetInvalidConfiguration: FunctionComponent<WidgetInvalidConfigurationProps> = ({ width, height, reason = i18n`Invalid configuration` }) => {
  const classes = useStyles();

  return (
    <div style={{ width, height }} className={classes.error}>
      <Tooltip title={reason}>
        <SpacingLine>
          <Icon name={IconName.dangerous} colorVariant={IconColorVariant.error} tooltip={reason} />
          <Typo maxLine={1}>{reason}</Typo>
        </SpacingLine>
      </Tooltip>
    </div>
  );
};

export default WidgetInvalidConfiguration;
