import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { getNumberOfDaysInMonth, isFiniteNumber, periodicities } from 'yooi-utils';
import InCompositeInput from '../../../app/_global/input/InCompositeInput';
import { spacingRem } from '../../../theme/spacingDefinition';
import { getMonthsOptions } from '../../../utils/dateUtilsFront';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useDerivedState from '../../../utils/useDerivedState';
import useSizeContext, { SizeVariant } from '../../../utils/useSizeContext';
import SearchAndSelect from '../../molecules/SearchAndSelect';
import NumberPicker from '../NumberPicker';
import { DatePickerStrategy } from './datePickerUtils';

const useStyles = makeStyles({
  inputSpacings: {
    display: 'grid',
    gridAutoFlow: 'column',
    columnGap: spacingRem.xs,
    alignItems: 'center',
  },
  inputMainTemplate: {
    gridTemplateColumns: 'auto 16.4rem 1fr',
  },
  inputDefaultTemplate: {
    gridTemplateColumns: 'auto 11.7rem 1fr',
  },
}, 'dayPicker');

interface DayPickerProps {
  value: Date | undefined,
  onSubmit: (date: Date | undefined) => void,
  strategy?: DatePickerStrategy,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
}

const DayPicker: FunctionComponent<DayPickerProps> = ({ value, onSubmit, strategy = DatePickerStrategy.startOf, onEditionStart, onEditionStop }) => {
  const classes = useStyles();
  const { sizeVariant } = useSizeContext();

  const valueDay = value?.getDate();
  const valueMonth = value?.getMonth();
  const valueYear = (value ?? new Date()).getFullYear();

  const [day, setDay, resetDay] = useDerivedState(() => (valueDay), [valueDay]);
  const [month, setMonth, resetMonth] = useDerivedState(() => (valueMonth), [valueMonth]);
  const [year, setYear, resetYear] = useDerivedState<number | undefined>(() => (valueYear), [valueYear]);

  const submitInvalidDate = () => {
    onSubmit(undefined);
  };

  const submitDate = ({ d, m, y }: { d: number | undefined, m: number | undefined, y: number | undefined }) => {
    if (d != null && m != null && y != null) {
      resetDay();
      resetMonth();
      resetYear();
      const submittedDate = new Date(y, m, d);
      // new Date(year, month) make year < 100 becoming 19XX need to set it after
      if (y < 100) {
        submittedDate.setFullYear(y);
      }
      if (strategy === DatePickerStrategy.endOf) {
        onSubmit(periodicities.day.getEndOfPeriod(submittedDate));
      } else {
        onSubmit(periodicities.day.getStartOfPeriod(submittedDate));
      }
    }
  };

  const numberOfDaysInMonth = value ? getNumberOfDaysInMonth(value) : undefined;
  const monthsOptions = getMonthsOptions();

  return (
    <div
      className={classnames({
        [classes.inputSpacings]: true,
        [classes.inputMainTemplate]: sizeVariant === SizeVariant.main,
        [classes.inputDefaultTemplate]: sizeVariant !== SizeVariant.main,
      })}
    >
      <InCompositeInput
        initialValue={day}
        setInputValue={(d) => {
          if (d == null) {
            setDay(undefined);
            submitInvalidDate();
          } else {
            setDay(d);
            submitDate({ d, m: month, y: year });
          }
        }}
      >
        {({ value: innerValue, onChange, ...props }) => (
          <NumberPicker
            min={1}
            max={numberOfDaysInMonth}
            placeholder={i18n`dd`}
            withFormatting={false}
            onEditionStart={onEditionStart}
            onEditionStop={onEditionStop}
            value={innerValue}
            onChange={(v) => {
              if (isFiniteNumber(v) || v === undefined) {
                onChange(typeof v === 'string' ? parseInt(v, 10) : v);
              }
            }}
            {...props}
          />
        )}
      </InCompositeInput>
      <SearchAndSelect
        placeholder={i18n`Month`}
        clearable
        onSelect={(v) => {
          const monthToSubmit = v?.id;
          if (monthToSubmit == null || monthToSubmit >= 12 || monthToSubmit < 0) {
            setMonth(undefined);
            submitInvalidDate();
          } else {
            setMonth(monthToSubmit);
            submitDate({ d: day, m: monthToSubmit, y: year });
          }
        }}
        computeOptions={() => monthsOptions}
        selectedOption={month != null ? monthsOptions[month] : undefined}
        onEditionStart={onEditionStart}
        onEditionStop={onEditionStop}
      />
      <InCompositeInput
        initialValue={year}
        setInputValue={(yearToSubmit) => {
          if (yearToSubmit == null || yearToSubmit > 3000 || yearToSubmit < 1) {
            setYear(undefined);
            submitInvalidDate();
          } else {
            setYear(yearToSubmit);
            submitDate({ d: day, m: month, y: yearToSubmit });
          }
        }}
      >
        {({ value: innerValue, onChange, ...props }) => (
          <NumberPicker
            min={1}
            max={3000}
            placeholder={i18n`yyyy`}
            withFormatting={false}
            onEditionStart={onEditionStart}
            onEditionStop={onEditionStop}
            value={innerValue}
            onChange={(v) => {
              if (isFiniteNumber(v) || v === undefined) {
                onChange(typeof v === 'string' ? parseInt(v, 10) : v);
              }
            }}
            {...props}
          />
        )}
      </InCompositeInput>
    </div>
  );
};

export default DayPicker;
