import type { ReactElement } from 'react';
import { useMemo } from 'react';
import type { NavigationPayload } from '../../../utils/useNavigation';
import type { TableSortDirection } from '../../molecules/Table';
import Table from '../../molecules/Table';
import type { CellHighlight } from '../../molecules/TableCell';
import type { ColumnDefinition as BodyColumnDefinition, Data, GroupEntry, ItemEntry } from './DataTableBodyRenderer';
import DataTableBodyRenderer from './DataTableBodyRenderer';
import type { ColumnDefinition as HeaderColumnDefinition } from './DataTableHeaderRenderer';
import DataTableHeaderRenderer from './DataTableHeaderRenderer';

export type { ItemEntry, GroupEntry } from './DataTableBodyRenderer';
export type ColumnDefinition<Item extends Data> = BodyColumnDefinition<Item> & HeaderColumnDefinition;

interface DataTableRendererProps<Item extends Data> {
  containerWidth: number, // Show a table placeholder on undefined
  list: (ItemEntry<Item> | GroupEntry)[],
  columnsDefinition: ColumnDefinition<Item>[],
  newLineFocus?: { id?: string, reset: () => void },
  newColumnFocus?: { id?: string, reset: () => void },
  doSort: ((propertyId: string) => void) | undefined,
  sortCriteria: { key: string, direction: TableSortDirection } | undefined,
  multiplayerRenderer: ((item: Item, columnIds: string[]) => ReactElement) | undefined,
  inlineCreation: { render: ReactElement | null, highlight?: (propertyId: string) => CellHighlight | undefined } | undefined,
  lineContext: ((item: Item) => string[]) | undefined,
  loading: boolean | undefined,
  getNavigationPayload?: (item: Item) => NavigationPayload | undefined,
  onNavigate?: (item: Item) => void,
  getHighlight?: (item: Item, propertyId: string) => CellHighlight | undefined,
  separateHeaderFromBody?: boolean,
  withoutHeaderLine?: boolean,
  centerHeader?: boolean,
  minColumnWidthRem?: number | undefined,
}

const DataTableRenderer = <Item extends Data = Data>({
  containerWidth,
  list,
  columnsDefinition,
  newLineFocus,
  newColumnFocus,
  doSort,
  sortCriteria,
  getNavigationPayload,
  onNavigate,
  multiplayerRenderer,
  inlineCreation,
  lineContext,
  loading = false,
  getHighlight,
  separateHeaderFromBody,
  withoutHeaderLine,
  centerHeader,
  minColumnWidthRem,
}: DataTableRendererProps<Item>): ReactElement | null => {
  const firstLine = list.at(0);
  const firstLineItem = firstLine?.type === 'item' ? firstLine.item : undefined;

  const header = useMemo(() => (
    <DataTableHeaderRenderer
      containerWidth={containerWidth}
      columnsDefinition={columnsDefinition}
      doSort={doSort}
      sortCriteria={sortCriteria}
      getCellBorderBottomColor={!separateHeaderFromBody && firstLineItem ? (columnKey) => getHighlight?.(firstLineItem, columnKey)?.color : undefined}
      separateHeaderFromBody={separateHeaderFromBody}
      withoutHeaderLine={withoutHeaderLine}
      centerHeader={centerHeader}
      minColumnWidthRem={minColumnWidthRem}
    />
  ), [containerWidth, columnsDefinition, doSort, sortCriteria, firstLineItem, separateHeaderFromBody, withoutHeaderLine, getHighlight, centerHeader, minColumnWidthRem]);

  const body = useMemo(() => (
    <DataTableBodyRenderer
      list={list}
      columnsDefinition={columnsDefinition}
      newLineFocus={newLineFocus}
      newColumnFocus={newColumnFocus}
      getNavigationPayload={getNavigationPayload}
      onNavigate={onNavigate}
      multiplayerRenderer={multiplayerRenderer}
      inlineCreation={inlineCreation}
      lineContext={lineContext}
      loading={loading}
      getHighlight={getHighlight}
    />
  ), [columnsDefinition, inlineCreation, lineContext, list, loading, multiplayerRenderer, newLineFocus, newColumnFocus, getNavigationPayload, onNavigate, getHighlight]);

  return (
    <Table maxWidth={containerWidth}>
      {header}
      {body}
    </Table>
  );
};

export default DataTableRenderer;
