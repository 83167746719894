import type { FunctionComponent, ReactElement } from 'react';
import type { RichText } from 'yooi-utils';
import { richTextToText } from 'yooi-utils';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import Typo, { TypoVariant } from '../atoms/Typo';
import RichTextInput from '../inputs/RichTextInput';
import SimpleInput from '../inputs/strategy/SimpleInput';
import TextInputString from '../inputs/TextInputString';
import SpacingLine from '../molecules/SpacingLine';
import HeaderActions from './HeaderActions';
import HeaderLine from './HeaderLine';

export enum HeaderTitleLineVariant {
  title = 'title',
  compact = 'compact',
  sticky = 'sticky',
}

const useStyles = makeStyles((theme) => ({
  typoContainer: {
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
    marginLeft: spacingRem.s,
    marginRight: spacingRem.s,
    marginBottom: spacingRem.text,
    marginTop: spacingRem.text,
  },
}), 'headerTitleLine');

interface HeaderTitleLineProps {
  value: string | RichText | undefined,
  onEditValue?: (newValue: string | RichText | null) => void,
  placeholder: string,
  restingTooltip?: string | (() => Promise<string>),
  newItem?: ReactElement | null,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  readOnly?: boolean,
  isRich?: boolean,
  preventRichFeatures?: boolean,
  variant?: HeaderTitleLineVariant,
  left?: ReactElement | null,
  actions?: (ReactElement | null)[],
}

const HeaderTitleLine: FunctionComponent<HeaderTitleLineProps> = ({
  value,
  onEditValue,
  placeholder,
  restingTooltip,
  left,
  newItem,
  onEditionStart,
  onEditionStop,
  readOnly = false,
  isRich = false,
  preventRichFeatures = false,
  variant = HeaderTitleLineVariant.title,
  actions,
}) => {
  const classes = useStyles();

  let component: ReactElement;
  if (onEditValue === undefined) {
    component = (
      <div className={classes.typoContainer}>
        <Typo maxLine={1} variant={TypoVariant.display}>{(isRich ? richTextToText(value as RichText) : value as string | undefined)}</Typo>
      </div>
    );
  } else if (isRich) {
    component = (
      <SimpleInput<RichText | undefined>
        initialValue={value as RichText | undefined}
        onSubmit={(v) => onEditValue(v ?? null)}
      >
        {(props) => (
          <RichTextInput
            readOnly={readOnly}
            placeholder={placeholder}
            onEditionStart={onEditionStart}
            onEditionStop={onEditionStop}
            maxLine={1}
            dropdownMaxLine={5}
            preventRichFeatures={preventRichFeatures}
            dropdownSizes={{ sameWidth: false }}
            restingTooltip={restingTooltip}
            {...props}
          />
        )}
      </SimpleInput>
    );
  } else {
    component = (
      <SimpleInput<string | undefined>
        initialValue={value as string | undefined}
        onSubmit={(v) => onEditValue(v ?? null)}
      >
        {(props) => (
          <TextInputString
            readOnly={readOnly}
            placeholder={placeholder}
            onEditionStart={onEditionStart}
            onEditionStop={onEditionStop}
            maxLine={1}
            dropdownMaxLine={5}
            withDropdown
            {...props}
          />
        )}
      </SimpleInput>
    );
  }

  return (
    <HeaderLine
      left={variant !== HeaderTitleLineVariant.sticky ? left : undefined}
      actions={<HeaderActions>{actions}</HeaderActions>}
    >
      <SizeContextProvider sizeVariant={SizeVariant.title} hierarchyVariant={HierarchyVariant.content}>
        {!readOnly && newItem ? (
          <SpacingLine>
            {component}
            {newItem}
          </SpacingLine>
        ) : component}
      </SizeContextProvider>
    </HeaderLine>
  );
};

export default HeaderTitleLine;
