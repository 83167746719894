import type { Property } from 'csstype';
import type { JssStyle } from 'jss';
import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { joinObjects, newError } from 'yooi-utils';
import base from '../theme/base';

export enum SizeVariant {
  title = 'title',
  subtitle = 'subtitle',
  tabs = 'tabs',
  main = 'main',
  small = 'small',
}

export enum HierarchyVariant {
  inline = 'inline',
  content = 'content',
}

const inputSizeVariants: Record<SizeVariant, number> = {
  [SizeVariant.title]: 5.2,
  [SizeVariant.subtitle]: 3.2,
  [SizeVariant.tabs]: 3.2,
  [SizeVariant.main]: 3.2,
  [SizeVariant.small]: 2.6,
};

export const getInputSize = (sizeVariant: SizeVariant): string => `${inputSizeVariants[sizeVariant]}rem`;

const sizeValue = {
  small: 2,
  medium: 2.6,
  large: 3.2,
};

const componentSizeVariants: Record<SizeVariant, Record<HierarchyVariant, number>> = {
  [SizeVariant.title]: {
    [HierarchyVariant.inline]: sizeValue.medium,
    [HierarchyVariant.content]: sizeValue.large,
  },
  [SizeVariant.subtitle]: {
    [HierarchyVariant.inline]: sizeValue.medium,
    [HierarchyVariant.content]: sizeValue.large,
  },
  [SizeVariant.tabs]: {
    [HierarchyVariant.inline]: sizeValue.small,
    [HierarchyVariant.content]: sizeValue.medium,
  },
  [SizeVariant.main]: {
    [HierarchyVariant.inline]: sizeValue.medium,
    [HierarchyVariant.content]: sizeValue.large,
  },
  [SizeVariant.small]: {
    [HierarchyVariant.inline]: sizeValue.small,
    [HierarchyVariant.content]: sizeValue.medium,
  },
};

const getComponentSize = (sizeVariant: SizeVariant, hierarchyVariant: HierarchyVariant): string => `${componentSizeVariants[sizeVariant][hierarchyVariant]}rem`;
export const getComponentSizeInRem = (sizeVariant: SizeVariant, hierarchyVariant: HierarchyVariant): number => componentSizeVariants[sizeVariant][hierarchyVariant];
export const getIconSize = (sizeVariant: SizeVariant, hierarchyVariant: HierarchyVariant): Property.FontSize => {
  const componentSize = componentSizeVariants[sizeVariant][hierarchyVariant];
  if (componentSize === sizeValue.small) {
    return base.icon.size.small;
  } else if (componentSize === sizeValue.medium) {
    return base.icon.size.main;
  } else if (componentSize === sizeValue.large) {
    return base.icon.size.large;
  } else {
    throw newError('Unsupported size value');
  }
};
const buildInputPropertyStyleInRem = (property: string, sizeVariant: SizeVariant) => ({ [property]: getInputSize(sizeVariant) });
export const buildInputSizeVariantClasses = <Prefix extends string>(prefix: Prefix, properties: string[]): Record<`${Prefix}_${SizeVariant}`, JssStyle> => {
  let classesObject = {};
  Object.values(SizeVariant).forEach((sizeVariant) => {
    let styles = {};
    properties.forEach((property) => {
      styles = joinObjects(styles, buildInputPropertyStyleInRem(property, sizeVariant));
    });
    const key = `${prefix}_${sizeVariant}`;
    classesObject = joinObjects(classesObject, { [key]: styles });
  });
  return classesObject as Record<`${Prefix}_${SizeVariant}`, JssStyle>;
};

const buildInputPropertyStyleMinusBorderInRem = (property: string, sizeVariant: SizeVariant) => ({ [property]: `${inputSizeVariants[sizeVariant] - 0.2}rem` });
export const buildInputSizeVariantMinusBorderClasses = <Prefix extends string>(prefix: Prefix, properties: string[]): Record<`${Prefix}_${SizeVariant}`, JssStyle> => {
  let classesObject = {};
  Object.values(SizeVariant).forEach((sizeVariant) => {
    let styles = {};
    properties.forEach((property) => {
      styles = joinObjects(styles, buildInputPropertyStyleMinusBorderInRem(property, sizeVariant));
    });
    const key = `${prefix}_${sizeVariant}`;
    classesObject = joinObjects(classesObject, { [key]: styles });
  });
  return classesObject as Record<`${Prefix}_${SizeVariant}`, JssStyle>;
};

export const buildComponentPropertyStyleInRem = (
  property: string,
  sizeVariant: SizeVariant,
  hierarchyVariant: HierarchyVariant
): Record<string, string> => ({ [property]: getComponentSize(sizeVariant, hierarchyVariant) });

export const buildComponentSizeVariantClasses = <Prefix extends string>(prefix: Prefix, properties: string[]): Record<`${Prefix}_${SizeVariant}_${HierarchyVariant}`, JssStyle> => {
  let classesObject = {};
  Object.values(SizeVariant).forEach((sizeVariant) => {
    Object.values(HierarchyVariant).forEach((hierarchyVariant) => {
      let styles = {};
      properties.forEach((property) => {
        styles = joinObjects(styles, buildComponentPropertyStyleInRem(property, sizeVariant, hierarchyVariant));
      });
      const key = `${prefix}_${sizeVariant}_${hierarchyVariant}`;
      classesObject = joinObjects(classesObject, { [key]: styles });
    });
  });

  return classesObject as Record<`${Prefix}_${SizeVariant}_${HierarchyVariant}`, JssStyle>;
};

const SizeContext = createContext<{ sizeVariant: SizeVariant, hierarchyVariant: HierarchyVariant }>({ sizeVariant: SizeVariant.main, hierarchyVariant: HierarchyVariant.inline });

interface SizeContextProviderProps {
  sizeVariant: SizeVariant,
  hierarchyVariant?: HierarchyVariant,
  children: ReactNode,
}

export const SizeContextProvider: FunctionComponent<SizeContextProviderProps> = ({ sizeVariant, hierarchyVariant = HierarchyVariant.inline, children }) => {
  const value = useMemo(() => ({ sizeVariant, hierarchyVariant }), [sizeVariant, hierarchyVariant]);
  return (
    <SizeContext.Provider value={value}>
      {children}
    </SizeContext.Provider>
  );
};

const useSizeContext = (): { sizeVariant: SizeVariant, hierarchyVariant: HierarchyVariant } => useContext(SizeContext);

export default useSizeContext;
