import type { FunctionComponent, ReactElement, RefCallback } from 'react';
import { useState } from 'react';
import type { FlyoutProps } from 'victory';
import makeStyles from '../../../utils/makeStyles';
import { remToPx } from '../../../utils/sizeUtils';

const useStyles = makeStyles({
  tooltipObject: {
    overflow: 'visible',
  },
  container: {
    pointerEvents: 'none',
  },
}, 'chartTooltipForVictory');

interface ChartTooltipForVictoryProps {
  x: FlyoutProps['x'],
  y: FlyoutProps['y'],
  center?: FlyoutProps['center'],
  chartWidth: number,
  chartHeight: number,
  renderTooltip: () => ReactElement | null,
}

const ChartTooltipForVictory: FunctionComponent<ChartTooltipForVictoryProps> = ({ x, y, center, chartWidth, chartHeight, renderTooltip }) => {
  const classes = useStyles();
  const [dimensions, setDimension] = useState<DOMRect>();
  const initTooltipDimensions: RefCallback<HTMLDivElement> = (e) => {
    if (e && !dimensions) {
      setDimension(e.getBoundingClientRect());
    }
  };

  const tooltipWidthInRem = 32;
  const tooltipWidthInPx = chartWidth < remToPx(tooltipWidthInRem) ? chartWidth : remToPx(tooltipWidthInRem);
  const tooltipHeightInPx = dimensions?.height ?? 1;
  let tooltipPositionX = center?.x ? center.x - (tooltipWidthInPx / 2) : x ?? 0;
  if ((tooltipPositionX + tooltipWidthInPx) > chartWidth) {
    tooltipPositionX -= tooltipWidthInPx;
  }
  if (tooltipPositionX < 0) {
    tooltipPositionX = 0;
  }
  if ((tooltipPositionX + tooltipWidthInPx) > chartWidth) {
    tooltipPositionX = chartWidth - tooltipWidthInPx - 10;
  }
  let tooltipPositionY = y ?? 0;
  if (tooltipPositionY < 0) {
    tooltipPositionY = 0;
  }
  const tooltipBottomMargin = 10;
  if ((tooltipPositionY + tooltipHeightInPx + tooltipBottomMargin) > chartHeight) {
    tooltipPositionY = chartHeight - tooltipHeightInPx - tooltipBottomMargin;
  }

  return (
    // Avoid tooltip flickering when using standalone: false option
    <g className={classes.container}>
      <foreignObject className={classes.tooltipObject} x={tooltipPositionX} y={tooltipPositionY} width={`${tooltipWidthInPx}px`} height={0.1}>
        <div ref={initTooltipDimensions}>
          {renderTooltip()}
        </div>
      </foreignObject>
    </g>
  );
};

export default ChartTooltipForVictory;
