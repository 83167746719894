import type { FunctionComponent } from 'react';
import type { ConceptDefinitionStoreObject } from 'yooi-modules/modules/conceptModule';
import { getConceptDefinitionUrl, hasPlatformCapability, isConceptValid } from 'yooi-modules/modules/conceptModule';
import { ConceptDefinition, ConceptDefinition_Name, ConceptDefinition_RestrictedAccess, PlatformCapabilityAdmin } from 'yooi-modules/modules/conceptModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import { compareString, comparing } from 'yooi-utils';
import NumberPicker from '../../components/inputs/NumberPicker';
import TextInputString from '../../components/inputs/TextInputString';
import Link from '../../components/molecules/Link';
import { TableSortDirection } from '../../components/molecules/Table';
import BaseLayout from '../../components/templates/BaseLayout';
import BlockContent from '../../components/templates/BlockContent';
import DataTable from '../../components/templates/DataTable';
import Header from '../../components/templates/Header';
import VerticalBlock from '../../components/templates/VerticalBlock';
import useActivity from '../../store/useActivity';
import useAuth from '../../store/useAuth';
import useStore from '../../store/useStore';
import { spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { safeSessionStorageValue } from '../../utils/sessionStorageUtils';
import useNavigation, { NavigationElementContainer } from '../../utils/useNavigation';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import SearchTextButton from '../_global/filter/SearchTextButton';
import type { FilterConfiguration } from '../_global/filter/useFilterSessionStorage';
import HeaderStatic from '../_global/HeaderStatic';
import { searchFilterFunction } from '../_global/listFilterFunctions';
import ActivityIndicator from '../_global/multiplayer/ActivityIndicator';
import type { NavigationFilter } from '../_global/navigationUtils';
import { getNavigationPayload } from '../_global/navigationUtils';
import TopBar from '../_global/topBar/TopBar';
import type { ComparatorHandler } from '../_global/useFilterAndSort';
import useFilterAndSort, { buildNumberColumnComparatorHandler } from '../_global/useFilterAndSort';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: spacingRem.s,
    paddingBottom: spacingRem.xs,
  },
}, 'organizationEntityList');

const OrganizationEntityList: FunctionComponent = () => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const { loggedUserId } = useAuth();

  const activity = useActivity();

  const navigation = useNavigation<NavigationFilter>();

  const filters = safeSessionStorageValue<FilterConfiguration | undefined>(ConceptDefinition);
  const filterFunction = searchFilterFunction(store, filters?.nameSearch, [ConceptDefinition_Name]);

  const isAdmin = hasPlatformCapability(store, loggedUserId, PlatformCapabilityAdmin);

  const { generateList, sortCriteria, doSort } = useFilterAndSort(
    ConceptDefinition,
    store.getObject(ConceptDefinition)
      .navigateBack<ConceptDefinitionStoreObject>(Class_Instances)
      .filter(({ [ConceptDefinition_RestrictedAccess]: restrictedAccess }) => !restrictedAccess)
      .map((conceptDefinition) => ({
        key: conceptDefinition.id,
        conceptDefinition,
        instancesCount: conceptDefinition.navigateBack(Class_Instances).filter(({ id: conceptId }) => isConceptValid(store, conceptId)).length,
      })),
    filterFunction ? (item) => filterFunction(item.conceptDefinition) : undefined,
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case 'instancesCount':
            return buildNumberColumnComparatorHandler(key, direction);
          case ConceptDefinition_Name:
            return {
              comparator: comparing(compareString, direction === TableSortDirection.desc),
              extractValue: ({ conceptDefinition }) => conceptDefinition[key],
            } satisfies ComparatorHandler<{ key: string, conceptDefinition: ConceptDefinitionStoreObject, instancesCount: number }, string | undefined>;
          default:
            return undefined;
        }
      },
      initial: { key: ConceptDefinition_Name, direction: TableSortDirection.asc },
    }
  );

  return (
    <NavigationElementContainer element={{ key: 'organization' }}>
      <BaseLayout
        topBar={(<TopBar />)}
        header={(
          <Header
            firstLine={(
              <HeaderStatic
                text={i18n`Information model`}
                actions={isAdmin ? [(<Link key="configuration" title={i18n`Access information model configuration`} to="/settings/organization" />)] : undefined}
              />
            )}
          />
        )}
        content={(
          <VerticalBlock compact>
            <BlockContent padded>
              <div className={classes.headerContainer}>
                <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
                  <SearchTextButton element={ConceptDefinition} placeholder={i18n`Search`} />
                </SizeContextProvider>
              </div>
            </BlockContent>
            <DataTable
              list={generateList().list}
              doSort={doSort}
              sortCriteria={sortCriteria}
              getNavigationPayload={({ conceptDefinition: { id } }) => getNavigationPayload(navigation, id, getConceptDefinitionUrl(id))}
              lineContext={({ conceptDefinition: { id } }) => [id]}
              columnsDefinition={[
                {
                  propertyId: ConceptDefinition_Name,
                  name: i18n`Name`,
                  width: 90,
                  sortable: true,
                  openButton: () => true,
                  cellRender: ({ conceptDefinition: { id, [ConceptDefinition_Name]: name } }) => (
                    <TextInputString
                      value={name}
                      color={name ? undefined : theme.color.text.secondary}
                      isEditing={activity.listEditor(id, ConceptDefinition_Name).length > 0}
                      readOnly
                    />
                  ),
                },
                {
                  propertyId: 'instancesCount',
                  name: i18n`# values`,
                  width: 10,
                  sortable: true,
                  cellRender: ({ instancesCount }) => (<NumberPicker value={instancesCount} onChange={() => {}} readOnly />),
                },
              ]}
              multiplayerRenderer={({ conceptDefinition: { id } }, columnKeys) => <ActivityIndicator instanceIds={id} propertyIds={columnKeys} />}
            />
          </VerticalBlock>
        )}
      />
    </NavigationElementContainer>
  );
};

export default OrganizationEntityList;
