import type { StoreObject } from 'yooi-store';
import type { ArrayFormulaType } from 'yooi-utils';
import { FunctionInvalidArgumentTypeError, isArrayFormulaType } from 'yooi-utils';
import type { FormulaType } from 'yooi-utils/src/formula2/engine/formula';
import { newFunctionLibrary } from 'yooi-utils/src/formula2/engine/functionLibraryBuilder';
import { checkArgumentCount, checkArgumentType, checkFlattenType, checks, flattenForEach } from 'yooi-utils/src/formula2/functionLibrary/utils';
import { anyType, arrayOf, booleanType } from 'yooi-utils/src/formula2/typeSystem';
import { Concept } from '../../ids';

export const isConceptType = (formulaType: FormulaType): formulaType is ConceptType => Object.prototype.hasOwnProperty.call(formulaType, 'conceptDefinitionId');

export interface ConceptType extends FormulaType {
  readonly conceptDefinitionId: string,
}

export const conceptType = (conceptDefinitionId: string): ConceptType => ({
  name: `concept<${conceptDefinitionId}>`,
  conceptDefinitionId,
  equals: (type) => (isConceptType(type) && type.conceptDefinitionId === conceptDefinitionId),
  isAssignableFrom: (type) => (isConceptType(type) ? conceptDefinitionId === Concept || conceptDefinitionId === type.conceptDefinitionId : type === anyType),
});

const extractConceptType = (argTypes: (ConceptType | ArrayFormulaType<ConceptType>)[]): ConceptType => {
  const firstConceptType = isArrayFormulaType(argTypes[0]) ? argTypes[0].array.elementType : argTypes[0];
  if (firstConceptType.equals(conceptType(Concept))) {
    return conceptType(Concept);
  }

  for (let i = 1; i < argTypes.length; i += 1) {
    const argType = argTypes[i];

    if (!firstConceptType.isAssignableFrom(isArrayFormulaType(argType) ? argType.array.elementType : argType)) {
      return conceptType(Concept);
    }
  }

  return firstConceptType;
};

const getMostCommonConceptType = (
  argTypes: FormulaType[]
): { mostCommonType: ConceptType | ArrayFormulaType<ConceptType>, errorIndex: undefined } | { mostCommonType: undefined, errorIndex: number } => {
  if (argTypes.length === 0) {
    return { mostCommonType: undefined, errorIndex: 0 };
  }
  const firstNonConceptIndex = argTypes.findIndex((argType) => (
    isArrayFormulaType(argType) ? !conceptType(Concept).isAssignableFrom(argType.array.elementType) : !conceptType(Concept).isAssignableFrom(argType)
  ));
  if (firstNonConceptIndex !== -1) {
    return { mostCommonType: undefined, errorIndex: firstNonConceptIndex };
  }

  const firstArgType = argTypes[0];
  const firstNonMatchingIndex = argTypes.findIndex(
    (argType) => (isArrayFormulaType(firstArgType) ? !isArrayFormulaType(argType) || firstArgType.array.depth !== argType.array.depth : isArrayFormulaType(argType))
  );
  if (firstNonMatchingIndex !== -1) {
    return { mostCommonType: undefined, errorIndex: firstNonMatchingIndex };
  }

  if (isArrayFormulaType(firstArgType)) {
    // Then, they are all arrays

    if (firstArgType.array.elementType.equals(conceptType(Concept))) {
      return { mostCommonType: firstArgType as ArrayFormulaType<ConceptType>, errorIndex: undefined };
    }

    for (let i = 1; i < argTypes.length; i += 1) {
      const argType = argTypes[i];

      if (!firstArgType.isAssignableFrom(argType)) {
        let returnType: ConceptType | ArrayFormulaType<ConceptType> = conceptType(Concept);
        for (let j = 0; j < firstArgType.array.depth; j += 1) {
          returnType = arrayOf(returnType);
        }
        return { mostCommonType: returnType, errorIndex: undefined };
      }
    }

    return { mostCommonType: firstArgType as ArrayFormulaType<ConceptType>, errorIndex: undefined };
  } else {
    if (firstArgType.equals(conceptType(Concept))) {
      return { mostCommonType: conceptType(Concept), errorIndex: undefined };
    }

    for (let i = 1; i < argTypes.length; i += 1) {
      const argType = argTypes[i];

      if (!firstArgType.isAssignableFrom(argType)) {
        return { mostCommonType: conceptType(Concept), errorIndex: undefined };
      }
    }

    return { mostCommonType: firstArgType as ConceptType, errorIndex: undefined };
  }
};

export const modelFunctions = newFunctionLibrary()
  .addFunction(
    'GETOBJECTS',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1),
          () => checkFlattenType(name, conceptType(Concept), argTypes)
        ) ?? {
          type: arrayOf(extractConceptType(argTypes as (ConceptType | ArrayFormulaType<ConceptType>)[])),
          jsFunction: (...args: unknown[]) => {
            const result: StoreObject[] = [];
            const ids = new Set<string>();
            flattenForEach(args, (obj: StoreObject | undefined) => {
              if (obj && !ids.has(obj.id)) {
                ids.add(obj.id);
                result.push(obj);
              }
            });
            return result;
          },
        }
      ),
    })
  )
  .addFunction(
    '=',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, conceptType(Concept), argTypes, 0),
          () => checkArgumentType(name, conceptType(Concept), argTypes, 1),
          () => (
            !argTypes[0].isAssignableFrom(argTypes[1]) && !argTypes[1].isAssignableFrom(argTypes[0])
              ? new FunctionInvalidArgumentTypeError(name, argTypes[0], argTypes[1], 1)
              : undefined
          )
        ) ?? {
          type: booleanType,
          transpile: ([left, right]) => `((${left})?.id)===((${right})?.id)`,
          jsFunction: (left: StoreObject | undefined, right: StoreObject | undefined) => (left?.id === right?.id),
        }),
    })
  )
  .addFunction(
    '<>',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, conceptType(Concept), argTypes, 0),
          () => checkArgumentType(name, conceptType(Concept), argTypes, 1),
          () => (
            !argTypes[0].isAssignableFrom(argTypes[1]) && !argTypes[1].isAssignableFrom(argTypes[0])
              ? new FunctionInvalidArgumentTypeError(name, argTypes[0], argTypes[1], 1)
              : undefined
          )
        ) ?? {
          type: booleanType,
          transpile: ([left, right]) => `((${left})?.id)!==((${right})?.id)`,
          jsFunction: (left: StoreObject | undefined, right: StoreObject | undefined) => (left?.id !== right?.id),
        }),
    })
  )
  .addFunction(
    'IF',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => {
        const checkError = checks(
          () => checkArgumentCount(name, argTypes, 3, 3),
          () => checkArgumentType(name, booleanType, argTypes, 0),
          () => checkArgumentType(name, [conceptType(Concept), arrayOf(conceptType(Concept))], argTypes, 1),
          () => checkArgumentType(name, [conceptType(Concept), arrayOf(conceptType(Concept))], argTypes, 2)
        );

        if (checkError) {
          return checkError;
        }

        const { mostCommonType, errorIndex } = getMostCommonConceptType(argTypes.slice(1));
        if (errorIndex !== undefined) {
          return new FunctionInvalidArgumentTypeError(name, conceptType(Concept), argTypes[errorIndex + 1], errorIndex);
        }

        return [
          { type: argTypes[0] },
          { type: mostCommonType },
          { type: mostCommonType },
        ];
      },
    })
  )
  .addFunction(
    'IFS',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => {
        const argsCountError = checkArgumentCount(name, argTypes, 2);
        if (argsCountError !== undefined) {
          return argsCountError;
        }

        let j = 0;
        while ((j + 1) < argTypes.length) {
          const booleanError = checkArgumentType(name, booleanType, argTypes, j);
          if (booleanError !== undefined) {
            return booleanError;
          }
          const conceptError = checkArgumentType(name, [conceptType(Concept), arrayOf(conceptType(Concept))], argTypes, j + 1);
          if (conceptError !== undefined) {
            return conceptError;
          }

          j += 2;
        }

        const hasDefaultLastValue = argTypes.length % 2 === 1;
        if (hasDefaultLastValue) {
          const conceptError = checkArgumentType(name, [conceptType(Concept), arrayOf(conceptType(Concept))], argTypes, argTypes.length - 1);
          if (conceptError !== undefined) {
            return conceptError;
          }
        }

        const conceptArgs = argTypes.filter((_, i) => ((i % 2 === 1) || (hasDefaultLastValue && i === argTypes.length - 1))) as ConceptType[];
        const { mostCommonType, errorIndex } = getMostCommonConceptType(conceptArgs);
        if (errorIndex !== undefined) {
          return new FunctionInvalidArgumentTypeError(name, conceptType(Concept), argTypes[2 * errorIndex + 1], 2 * errorIndex + 1);
        }

        return argTypes.map((argType, index) => (index % 2 === 0 ? { type: argType } : { type: mostCommonType }));
      },
    })
  )
  .build();

export const testables = {
  getMostCommonConceptType,
};
