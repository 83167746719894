export interface LayoutOption {
  id: BlockFieldLayoutOption,
  label: string,
}

export enum BlockFieldLayoutOption {
  auto = 'auto',
  column = 'column',
  row = 'row',
}

export interface FieldBlockDisplayOptions {
  layoutDisplayType?: BlockFieldLayoutOption,
  [key: string]: unknown,
}

export enum OverridableDisplayType {
  Value = 'Value',
  Configuration = 'Configuration',
}

export enum WorkflowRelationDisplayType {
  Value = 'Value',
  ValueAndButton = 'ValueAndButton',
  ButtonAndValue = 'ButtonAndValue',
  Button = 'Button',
  Color = 'Color',
}

export interface DocumentationOverride {
  value: string,
  isInline: boolean,
}
