import classnames from 'classnames';
import type { ReactNode } from 'react';
import { forwardRef } from 'react';
import { joinObjects } from 'yooi-utils';
import fontDefinition, { FontVariant as TypoVariant } from '../../theme/fontDefinition';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import { getTextMeasurerForVariant } from '../../utils/textUtils';
import useSizeContext, { SizeVariant } from '../../utils/useSizeContext';

export { FontVariant as TypoVariant } from '../../theme/fontDefinition';

export enum TypoAlign {
  left = 'left',
  right = 'right',
  center = 'center',
}

const useStyles = makeStyles((theme) => (joinObjects(
  {
    base: {
      whiteSpace: 'pre-wrap',
      overflowWrap: 'break-word',
    },
    alignLeft: {
      textAlign: 'left',
    },
    alignCenter: {
      textAlign: 'center',
    },
    alignRight: {
      textAlign: 'right',
    },
    noWrap: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    maxLine: {
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      wordBreak: 'break-all',
    },
    multiline: {
      wordWrap: 'break-word',
    },
  },
  theme.font
)), 'typo');

export const typoMaxWidth = {
  [TypoVariant.display]: undefined,
  [TypoVariant.blockPrimaryTitle]: '76rem',
  [TypoVariant.blockSecondaryTitle]: '67rem',
  [TypoVariant.blockTertiaryTitle]: '67rem',
  [TypoVariant.blockInlineTitle]: undefined,
  [TypoVariant.tabTitle]: '40rem',
  [TypoVariant.body]: undefined,
  [TypoVariant.small]: '55rem',
  [TypoVariant.smallBold]: '55rem',
  [TypoVariant.buttonMain]: '64rem',
  [TypoVariant.buttonSmall]: '64rem',
  [TypoVariant.code]: undefined,
} satisfies Record<TypoVariant, string | undefined>;

export const sizeVariantToTypoVariant: Record<string, TypoVariant> = {
  [SizeVariant.title]: TypoVariant.display,
  [SizeVariant.subtitle]: TypoVariant.blockPrimaryTitle,
  [SizeVariant.tabs]: TypoVariant.tabTitle,
  [SizeVariant.main]: TypoVariant.body,
  [SizeVariant.small]: TypoVariant.small,
};

interface TypoProps {
  children?: ReactNode,
  noWrap?: boolean,
  variant?: TypoVariant,
  color?: string,
  minLine?: number,
  maxLine?: number,
  multiline?: boolean,
  fullWidth?: boolean,
  align?: TypoAlign,
}

const Typo = forwardRef<HTMLDivElement, TypoProps>(({ children, noWrap, variant, color, minLine, maxLine, multiline = false, fullWidth = false, align = TypoAlign.left }, ref) => {
  const classes = useStyles();

  const { sizeVariant } = useSizeContext();
  const variantComputed: TypoVariant = variant ?? sizeVariantToTypoVariant[sizeVariant] ?? TypoVariant.body;

  let maxWidth: string | number | undefined;
  if (!fullWidth) {
    // When we have a simple children and ask to have a single line, we try to compute a mawWidth that will avoid a big white space if there is longer line after the first one
    if (maxLine === 1 && typeof children === 'string' && children.indexOf('\n') !== -1) {
      const firstLineWidthPx = getTextMeasurerForVariant(variantComputed)(`${children.substring(0, children.indexOf('\n'))} ...`);
      const variantMaxWidth = typoMaxWidth[variantComputed];
      if (variantMaxWidth) {
        const variantMaxWidthPx = remToPx(variantMaxWidth);
        maxWidth = firstLineWidthPx < variantMaxWidthPx ? firstLineWidthPx : variantMaxWidth;
      } else {
        maxWidth = firstLineWidthPx;
      }
    } else {
      maxWidth = typoMaxWidth[variantComputed];
    }
  }

  const className = classnames({
    [classes.base]: true,
    [classes[variantComputed]]: true,
    [classes.alignLeft]: align === TypoAlign.left,
    [classes.alignCenter]: align === TypoAlign.center,
    [classes.alignRight]: align === TypoAlign.right,
    [classes.noWrap]: noWrap,
    [classes.maxLine]: maxLine,
    [classes.multiline]: multiline,
  });
  const style = { minHeight: remToPx(fontDefinition[variantComputed].lineHeight) * (minLine ?? 1), maxWidth, color, WebkitLineClamp: maxLine };

  // Yes, we are not supposed to use variables as classes but we have a specific case here as we only want to change the type of tag
  /* eslint-disable yooi/check-classname-attribute */
  switch (variantComputed) {
    case TypoVariant.display:
    case TypoVariant.blockPrimaryTitle:
      return (<h1 ref={ref} className={className} style={style}>{children}</h1>);
    case TypoVariant.blockSecondaryTitle:
    case TypoVariant.blockTertiaryTitle:
    case TypoVariant.blockInlineTitle:
      return (<h2 ref={ref} className={className} style={style}>{children}</h2>);
    case TypoVariant.tabTitle:
      return (<h3 ref={ref} className={className} style={style}>{children}</h3>);
    case TypoVariant.body:
      return (<p ref={ref} className={className} style={style}>{children}</p>);
    case TypoVariant.small:
    case TypoVariant.smallBold:
    case TypoVariant.buttonMain:
    case TypoVariant.buttonSmall:
      return (<div ref={ref} className={className} style={style}>{children}</div>);
    case TypoVariant.code:
      return (<span ref={ref} className={className} style={style}>{children}</span>);
  }
  /* eslint-enable yooi/check-classname-attribute */
});

export default Typo;
