import type { EventOrigin, Operation, SendOutgoingEventResponse } from 'yooi-store';
import { fromError, joinObjects, newError, sleep } from 'yooi-utils';
import buildInfo from '../utils/buildInfo';
import { reportClientMetric, reportClientTrace } from '../utils/clientReporterUtils';
import { doFetch, fetchJSON } from '../utils/fetchUtils';
import type { Snapshot } from './concurrencyHandler';
import type { OnConnectionProgress } from './connectionProgress';
import { ConnectionPhase } from './connectionProgress';
import type { CustomEventSource, NetworkClientResponse } from './networkClientUtils';
import { readSnapshot } from './snapshotDeserializer';

const EVENT_SOURCE_MAX_RETRY_TIME = 2_000;

export interface DownloadNetworkSnapshot {
  (minimalEventId: string, abortSignal: AbortSignal, onConnectionProgress: OnConnectionProgress): Promise<({
    snapshot: Snapshot,
    status: 'success',
  } | {
    snapshot?: undefined,
    status: 'failure' | 'unauthorized' | 'interrupted',
  }) & {
    stats: Record<string, unknown>,
  }>,
}

export interface Activity {
  objectId: string | null,
  fieldId: string | null,
}

export interface SendOutgoingEvent {
  (event: { event?: Operation[], activity?: Activity }): Promise<NetworkClientResponse<SendOutgoingEventResponse, SendOutgoingEventResponse>>,
}

export interface SendActivity {
  (activity: Activity): Promise<NetworkClientResponse<SendOutgoingEventResponse, SendOutgoingEventResponse>>,
}

export interface RemoteEvent {
  eventId: string,
  event: Operation[],
  checksum: string,
  status: string,
  feedback: boolean,
  permissionUpdated: boolean,
  origin: EventOrigin,
}

type CreateServerEventConnector = (params: {
  onIncomingEvent: (event: RemoteEvent) => void,
  onHeartbeat: () => void,
  onActivity: (activity: Operation[]) => void,
  onConnected: (options: { lastBroadcastedMessageId: string, userId: string, buildSourceHash: string }) => void,
}) => () => { disconnect: () => void };

interface CreateNetworkEventClient {
  (props: {
    clientId: string,
    bypassReadAcl: boolean,
    onUnauthorized: () => void,
  }): {
    downloadSnapshot: DownloadNetworkSnapshot,
    sendOutgoingEvent: SendOutgoingEvent,
    createServerEventConnector: CreateServerEventConnector,
  },
}

const createNetworkEventClient: CreateNetworkEventClient = ({ clientId, bypassReadAcl, onUnauthorized }) => {
  const downloadSnapshot: DownloadNetworkSnapshot = async (minimalEventId, abortSignal, onConnectionProgress) => {
    const timeStart = performance.now();
    try {
      const { response: { url, type, status, statusText, redirected, headers, body, text }, iterationMetrics } = await doFetch('/store/snapshot', {
        signal: abortSignal,
        method: 'POST',
        json: { clientId, minimalEventId, bypassReadAcl: bypassReadAcl ? true : undefined },
      });
      const timeFetchCompleted = performance.now();
      const headersList: string[] = []; // don't use a map since there can be the same header key multiple times
      headers.forEach((value, key) => headersList.push(`${key}: ${value}`));
      reportClientMetric(clientId, 'snapshotQueryResponse', { url, type, status, statusText, redirected, headersList, duration: timeFetchCompleted - timeStart, iterationMetrics });

      if (status === 200 && body) {
        const reader = body.getReader();
        const { snapshot, stats: readStats } = await readSnapshot(
          () => reader.read(),
          () => !abortSignal.aborted,
          (progress) => onConnectionProgress(ConnectionPhase.DownloadFromNetwork, progress)
        );
        const timeCompleted = performance.now();
        const stats = joinObjects(
          {
            duration: Math.ceil(timeCompleted - timeStart),
            fetchDuration: Math.ceil(timeFetchCompleted - timeStart),
            streamDuration: Math.ceil(timeCompleted - timeFetchCompleted),
          },
          readStats
        );

        return snapshot ? { snapshot, status: 'success', stats } : { status: 'interrupted', stats };
      } else if (status === 401) {
        return {
          status: 'unauthorized',
          stats: {
            fetchDuration: Math.ceil(timeFetchCompleted - timeStart),
          },
        };
      } else {
        let bodyText;
        try {
          bodyText = await text();
        } catch {
          bodyText = '--- error when requesting text() ---';
        }

        return {
          status: 'failure',
          stats: {
            fetchDuration: Math.ceil(timeFetchCompleted - timeStart),
            fetchStatusCode: status,
            fetchStatusText: statusText,
            body: bodyText,
          },
        };
      }
    } catch (e) {
      const timeError = performance.now();
      if (e instanceof DOMException && e.name === 'AbortError') {
        return {
          status: 'interrupted',
          stats: {
            duration: Math.ceil(timeError - timeStart),
          },
        };
      } else {
        reportClientTrace(e);
        return {
          status: 'failure',
          stats: {
            duration: Math.ceil(timeError - timeStart),
            errorMessage: e?.toString(),
          },
        };
      }
    }
  };

  interface CustomEventSourceEventMapForEventStream extends EventSourceEventMap {
    connected: MessageEvent,
    heartbeat: MessageEvent,
    activity: MessageEvent,
  }

  const sendOutgoingEvent: SendOutgoingEvent = async ({ event, activity }) => {
    const sendOutgoingEventStart = performance.now();
    try {
      const { status, response, iterationMetrics } = await fetchJSON<
        { status: 202, response: { status: 'accepted', eventId: string } } | { status: 400 | 401 | 403 | 500, response: { status: string } }
      >(
        '/store/sync/publish',
        { method: 'POST', json: { clientId, event, activity, sourceHash: buildInfo.sourceHash, time: Date.now() } }
      );
      if (status === 202) {
        return { httpStatus: status, answer: response };
      } else if (status === 401) {
        onUnauthorized();
        return {
          httpStatus: status,
          error: newError('Failed to send outgoing event, unauthorized', {
            clientId,
            sourceHash: buildInfo.sourceHash,
            response,
            iterationMetrics,
            sendOutgoingEventStart,
            sendOutgoingEventEnd: performance.now(),
          }),
        };
      } else {
        return {
          httpStatus: status,
          error: newError('Failed to send outgoing event', {
            clientId,
            sourceHash: buildInfo.sourceHash,
            response,
            iterationMetrics,
            sendOutgoingEventStart,
            sendOutgoingEventEnd: performance.now(),
          }),
        };
      }
    } catch (e) {
      return {
        error: fromError(e, 'Failed to send outgoing event', {
          clientId,
          sourceHash: buildInfo.sourceHash,
          sendOutgoingEventStart,
          sendOutgoingEventEnd: performance.now(),
        }),
      };
    }
  };

  const createServerEventConnector: CreateServerEventConnector = ({ onIncomingEvent, onHeartbeat, onActivity, onConnected }) => () => {
    let eventSource: CustomEventSource<CustomEventSourceEventMapForEventStream>;
    let connected = false;

    const connect = () => {
      if (connected) {
        reportError(newError('Trying to reconnect a server event connector'));
      }
      connected = true;
      eventSource = new EventSource(`/store/sync/events?clientId=${clientId}${bypassReadAcl ? `&bypassReadAcl=${bypassReadAcl}` : ''}`);

      // eventListener listen to a surcharged version of Event, with data.
      eventSource.addEventListener('message', ({ data }) => onIncomingEvent(JSON.parse(data)));
      eventSource.addEventListener('connected', ({ data }) => onConnected(JSON.parse(data)));
      eventSource.addEventListener('heartbeat', () => onHeartbeat());
      eventSource.addEventListener('activity', ({ data }) => onActivity(JSON.parse(data)));

      eventSource.onerror = async () => {
        eventSource.close();

        if (connected) {
          const retryConnect = async () => {
            // Wait between 1 and 3s to retry
            await sleep(Math.round(1_000 + EVENT_SOURCE_MAX_RETRY_TIME * Math.random()));
            if (connected) {
              connected = false;
              connect();
            }
          };

          // EventSource doesn't provide any information about the error
          // Call the store to check the error is authentication related
          try {
            const { status } = await fetchJSON('/store/sync');
            if (status === 401) {
              onUnauthorized();
            } else {
              await retryConnect();
            }
          } catch (e) {
            // Fetch failed, probably a network error
            reportClientTrace(fromError(e, 'Error while calling store sync'));
            await retryConnect();
          }
        }
      };
    };

    connect();

    return {
      disconnect: () => {
        connected = false;
        eventSource.close();
      },
    };
  };

  return { downloadSnapshot, sendOutgoingEvent, createServerEventConnector };
};

export default createNetworkEventClient;
