import classnames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import { useMemo } from 'react';
import { filterNullOrUndefined } from 'yooi-utils';
import { getSpacing, getSpacingAsNumber, Spacing, spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import useBlockContext, { BlockContextProvider } from '../../utils/useBlockContext';
import { useChipBlockContext } from '../../utils/useChipBlockContext';
import useHighlight from '../../utils/useHighlight';

const useStyles = makeStyles((theme) => ({
  block: {
    display: 'grid',
    minHeight: '3.2rem',
    gridAutoRows: 'minmax(min-content, max-content)',
    gridTemplateColumns: `${spacingRem.blockLeftColumnSpacing} 1fr ${spacingRem.blockRightColumnSpacing}`,
  },
  standard: {
    rowGap: spacingRem.blockSpacing,
  },
  compact: {
    rowGap: spacingRem.xs,
  },
  large: {
    rowGap: spacingRem.xxl,
  },
  verticalFullWidth: {
    gridColumnStart: 1,
    gridColumnEnd: 'last',
  },
  horizontalFullWidth: {
    gridColumnStart: 3,
    gridColumnEnd: 'last',
  },
  separation: {
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
    marginTop: getSpacing(Spacing.l, -getSpacingAsNumber(Spacing.blockSpacing)),
    marginBottom: getSpacing(Spacing.l, -getSpacingAsNumber(Spacing.blockSpacing)),
    marginLeft: getSpacing(Spacing.s, 0.1 /* border */),
    gridColumnStart: 2,
  },
}), 'verticalBlock');

interface VerticalBlockProps {
  children: ReactNode,
  withHighlightBlock?: string,
  compact?: boolean,
  large?: boolean,
  withSeparation?: boolean,
  asBlockContent?: boolean,
}

const VerticalBlock: FunctionComponent<VerticalBlockProps> = ({ children, withHighlightBlock, compact = false, large = false, withSeparation = false, asBlockContent = false }) => {
  const classes = useStyles();

  const { isVertical, level } = useBlockContext();
  const chipContext = useChipBlockContext();
  const currentContext = useMemo(() => [chipContext.context, withHighlightBlock].filter(filterNullOrUndefined), [chipContext.context, withHighlightBlock]);
  const highlightRef = useHighlight<HTMLDivElement>(currentContext, withHighlightBlock ? undefined : {});

  const value = useMemo(() => ({ level: level + 1, isVertical: true }), [level]);

  return (
    <BlockContextProvider context={value}>
      <div
        ref={highlightRef}
        className={classnames({
          [classes.block]: true,
          [classes.standard]: !compact && !large,
          [classes.compact]: compact,
          [classes.large]: large,
          [classes.horizontalFullWidth]: asBlockContent && !isVertical,
          [classes.verticalFullWidth]: asBlockContent && isVertical,
        })}
      >
        {children}
        {withSeparation ? (<div className={classes.separation} />) : null}
      </div>
    </BlockContextProvider>

  );
};

export default VerticalBlock;
