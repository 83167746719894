import type { FunctionComponent } from 'react';
import { useMemo, useRef, useState } from 'react';
import { forgetAsyncPromise, joinObjects } from 'yooi-utils';
import { FontVariant } from '../../theme/fontDefinition';
import { buildMargins, Spacing, spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { notifyError } from '../../utils/notify';
import useTheme from '../../utils/useTheme';
import Button, { ButtonVariant } from '../atoms/Button';
import { IconName } from '../atoms/Icon';
import Typo from '../atoms/Typo';
import EditableWithDropdown, { EditableCloseReasons } from '../molecules/EditableWithDropdown';
import SpacingLine from '../molecules/SpacingLine';
import UploadFileButton from './UploadFileButton';

interface ValueUrl {
  type: 'url',
  url: string,
  size: { width: number, height: number },
}

interface ValueBuffer {
  type: 'buffer',
  data: ArrayBuffer,
  contentType: string,
  size: { width: number, height: number },
}

const getImageSize = (value: Omit<ValueBuffer, 'size'>) => new Promise<{ height: number, width: number }>(
  (resolve, reject) => {
    const image = new Image();
    const blob = new Blob([value.data], { type: value.contentType });
    image.onload = () => resolve({ height: image.height, width: image.width });
    image.onerror = reject;
    image.src = URL.createObjectURL(blob);
  }
);

const useStyles = makeStyles({
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: spacingRem.s,
    padding: spacingRem.s,
  },
  placeholderContainer: buildMargins({ x: Spacing.s, y: Spacing.xs }),
}, 'rawImagePicker');

type Value = ValueUrl | ValueBuffer;

interface RawImagePickerProps {
  value: Value | undefined,
  onChange: (content: Value | undefined) => void,
  onSubmit: (content: Value | undefined) => void,
  onCancel: () => void,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  isEditing?: boolean,
  readOnly?: boolean,
  maxSize?: { width: number, height: number },
  exactSize?: { width: number, height: number },
  alt: string,
}

const RawImagePicker: FunctionComponent<RawImagePickerProps> = ({
  value,
  onSubmit,
  onChange,
  onCancel,
  onEditionStart,
  onEditionStop,
  isEditing = false,
  readOnly = false,
  maxSize,
  exactSize,
  alt,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const [showDropdown, setShowDropdown] = useState(false);

  const isDraggingRef = useRef(false);

  const imageUrl = useMemo(() => {
    if (value?.type === 'url') {
      return value.url;
    } else if (value?.type === 'buffer') {
      const blob = new Blob([value.data], { type: value.contentType });
      return URL.createObjectURL(blob);
    } else {
      return undefined;
    }
  }, [value]);

  return (
    <EditableWithDropdown
      showDropdown={showDropdown}
      openDropdown={() => {
        isDraggingRef.current = false;
        setShowDropdown(true);
        if (!readOnly) {
          onEditionStart?.();
        }
      }}
      editableSizes={{
        width: value !== undefined ? `calc(${value.size.width}px + 0.2rem)` : undefined,
        height: value !== undefined ? `calc(${value.size.height}px + 0.2rem)` : undefined,
        minWidth: '2.6rem',
        minHeight: '3.2rem',
        maxWidth: value !== undefined && (maxSize !== undefined || exactSize !== undefined) ? `calc(${maxSize?.width ?? exactSize?.width}px + 0.2rem)` : undefined,
        maxHeight: value !== undefined && (maxSize !== undefined || exactSize !== undefined) ? `calc(${maxSize?.height ?? exactSize?.height}px + 0.2rem)` : undefined,
      }}
      closeDropdown={(reason) => {
        if (!isDraggingRef.current) {
          setShowDropdown(false);
          if (!readOnly) {
            onEditionStop?.();
            if (reason === EditableCloseReasons.onEscapeKeyDown) {
              onCancel();
            } else {
              onSubmit(value);
            }
          }
        }
      }}
      renderValue={() => {
        if (!imageUrl || !value) {
          if (!readOnly) {
            return (
              <div className={classes.placeholderContainer}>
                <Typo maxLine={1} color={theme.color.text.disabled}>
                  {i18n`Add image`}
                </Typo>
              </div>
            );
          } else {
            return null;
          }
        } else {
          return (
            <img
              src={imageUrl}
              alt={alt}
              width={value.size.width}
              height={value.size.height}
            />
          );
        }
      }}
      renderDropdown={readOnly ? undefined : () => (
        <span className={classes.line}>
          <SpacingLine>
            <UploadFileButton
              value={value}
              onChange={forgetAsyncPromise(async (newValue) => {
                try {
                  const size = await getImageSize(newValue);
                  if (exactSize !== undefined && (size.width !== exactSize.width || size.height !== exactSize.height)) {
                    notifyError(i18n`Uploaded image size (${size.width}x${size.height}px) does not match the expected size (${exactSize.width}x${exactSize.height}px)`);
                  } else if (maxSize !== undefined && (size.width > maxSize.width || size.height > maxSize.height)) {
                    notifyError(i18n`Uploaded image size (${size.width}x${size.height}px) exceed the maximum allowed size (${maxSize.width}x${maxSize.height}px)`);
                  } else {
                    onChange(joinObjects(newValue, { size }));
                  }
                } catch (_) {
                  notifyError(i18n`Unable to load image.`);
                }
              })}
              typeRestriction={{ accept: 'image/*', isAccepted: (type) => type.startsWith('image/'), errorMessage: i18n`Invalid file format, only images are accepted` }}
            />
            {value !== undefined ? (
              <Typo variant={FontVariant.small} color={theme.color.text.secondary}>{i18n`The maximum size per file is 5 MB.`}</Typo>
            ) : null}
            {exactSize !== undefined ? (
              <Typo variant={FontVariant.small} color={theme.color.text.secondary}>{i18n`The image should be ${exactSize.width}x${exactSize.height}px.`}</Typo>
            ) : null}
            {exactSize === undefined && maxSize !== undefined ? (
              <Typo variant={FontVariant.small} color={theme.color.text.secondary}>{i18n`The maximum resolution is ${maxSize.width}x${maxSize.height}px.`}</Typo>
            ) : null}
          </SpacingLine>
          <SpacingLine>
            {value !== undefined ? (
              <Button
                onClick={() => onChange(undefined)}
                variant={ButtonVariant.danger}
                title={i18n`Delete`}
                iconName={IconName.delete}
              />
            ) : null}
          </SpacingLine>
        </span>
      )}
      isEditing={isEditing}
      readOnly={readOnly && !value}
      autoFocus
    />
  );
};

export default RawImagePicker;
