import { difference } from 'ramda';
import type { ReactElement } from 'react';
import type {
  FieldStoreObject,
  Filters,
  NumberColorStepValue,
  NumberColorStepValueResolved,
  ParametersMapping,
  PathStep,
  SingleParameterDefinition,
} from 'yooi-modules/modules/conceptModule';
import {
  associationFieldHandler,
  canCreateAssociation,
  createValuePathResolver,
  dimensionsMappingToParametersMapping,
  FILTER_PARAMETER_CURRENT,
  getColorByValue,
  getFieldDimensionOfModelType,
  getFieldUtilsHandler,
  getFilterFunctionHandler,
  getPathLastFieldInformation,
  getPathReturnedConceptDefinitionId,
  isDimensionLibraryPath,
  isDimensionStep,
  isFieldStep,
  isFilterStep,
  isGlobalDimensionResolution,
  isGlobalDimensionStep,
  isMappingStep,
  isMultiplePath,
  isSingleDimensionResolution,
  isSingleFieldResolution,
  isSingleValueResolution,
  NumberColorStepValueType,
  numberFieldHandler,
  ParsedDimensionType,
  parseDimensionMapping,
  relationMultipleFieldHandler,
  relationSingleFieldHandler,
  timeseriesNumberFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import {
  AssociationField,
  Concept,
  EmbeddingField,
  Field_IntegrationOnly,
  KinshipRelation,
  NumberField,
  RelationMultipleField,
  RelationSingleField,
  TimeseriesNumberField,
} from 'yooi-modules/modules/conceptModule/ids';
import type { ViewDimension, ViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import type { ExportViewDefinition } from 'yooi-modules/modules/dashboardModule/views/common/exportViewDefinition';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { dateFormats, filterNullOrUndefined, formatDisplayDate, isFiniteNumber, joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Typo from '../../../../components/atoms/Typo';
import Chooser from '../../../../components/molecules/Chooser';
import type { InlineCreationInline, InlineCreationTransactional } from '../../../../components/molecules/inlineCreationTypes';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import BlockContent from '../../../../components/templates/BlockContent';
import type { ACLHandler } from '../../../../store/useAcl';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { getInlineCreationBuilder } from '../../conceptUtils';
import type { LineEditionOption } from '../../fields/FieldEditionOptionType';
import { EditionOptionTypes } from '../../fields/FieldEditionOptionType';
import { getTimeseriesMaxMinValues } from '../../fields/timeseries/timeseriesNumberField/timeseriesNumberFieldUtils';
import type { UpdateViewDefinition } from '../../fields/viewsField/ViewsFieldDefinitionOptions';
import type { TickResolved } from '../../fieldUtils';
import { getInstanceMaxMinValues, resolveTickValue, TickResolutionStatus } from '../../fieldUtils';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { getConceptFilters } from '../../listFilterFunctions';
import { getModelTypeInstances } from '../../modelTypeUtils';
import type { NavigationFilter } from '../../navigationUtils';
import type { PathConfigurationHandler, PathStepValidator } from '../../pathConfigurationHandler';
import { createPathConfigurationHandler, StepValidationState } from '../../pathConfigurationHandler';
import type { ViewResolvedDefinition } from '../viewResolvedDefinition';
import type { ExportViewResolvedDefinition } from './exportViewResolvedDefinition';
import ViewOptionBlock from './ViewOptionBlock';

export const getLineErrorContent = (error: string, tooltip?: string): ReactElement => (
  <SpacingLine>
    <Icon name={IconName.dangerous} colorVariant={IconColorVariant.error} tooltip={tooltip} />
    <Typo maxLine={1}>{error}</Typo>
  </SpacingLine>
);

export const getDefaultLabel = (label: string | undefined, index: number, defaultLabel: string): string => (!label ? `${defaultLabel} #${index + 1}` : label);

export const getColorForValue = (objectStore: FrontObjectStore, fieldId: string, value: unknown, conceptId?: string): string | undefined => {
  const conceptDefinitionId = conceptId ? objectStore.getObject(conceptId)[Instance_Of] as string : undefined;
  const dimensionId = conceptDefinitionId ? getFieldDimensionOfModelType(objectStore, fieldId, conceptDefinitionId) : undefined;
  const dimensionsMapping = dimensionId ? { [dimensionId]: conceptId } : {};

  if (isInstanceOf(objectStore.getObjectOrNull(fieldId), NumberField)) {
    const fieldHandler = numberFieldHandler(objectStore, fieldId);
    const configuration = fieldHandler.resolveConfigurationWithOverride(dimensionsMapping);
    const { invalidColor } = configuration;
    const { min, max } = getInstanceMaxMinValues(objectStore, fieldId, conceptId, {});
    let color: string | undefined;
    if (isFiniteNumber(value)) {
      const numberValue = Number(value);
      if (min?.status === TickResolutionStatus.Resolved && numberValue < min.value) {
        color = invalidColor;
      } else if (max?.status === TickResolutionStatus.Resolved && numberValue > max.value) {
        color = invalidColor;
      } else {
        color = getColorByValue(
          numberValue,
          min?.status === TickResolutionStatus.Resolved ? min : undefined,
          configuration.rangeValue
            ?.map((tick) => resolveTickValue(objectStore, dimensionsMappingToParametersMapping(dimensionsMapping), tick))
            .filter(filterNullOrUndefined)
            .filter((resolution): resolution is TickResolved => resolution.status === TickResolutionStatus.Resolved),
          max?.status === TickResolutionStatus.Resolved ? max : undefined
        );
      }
    }
    return color;
  } else if (isInstanceOf(objectStore.getObjectOrNull(fieldId), TimeseriesNumberField)) {
    const fieldHandler = timeseriesNumberFieldHandler(objectStore, fieldId);
    const configuration = fieldHandler.resolveConfigurationWithOverride(dimensionsMapping);

    const { min, max } = getTimeseriesMaxMinValues(objectStore, fieldId, dimensionsMapping, {});
    let color: string | undefined;
    if (isFiniteNumber(value)) {
      const numberValue = Number(value);
      if (min?.status === TickResolutionStatus.Resolved && numberValue < min.value) {
        color = configuration.invalidColor;
      } else if (max?.status === TickResolutionStatus.Resolved && numberValue > max.value) {
        color = configuration.invalidColor;
      } else {
        color = getColorByValue(
          numberValue,
          min?.status === TickResolutionStatus.Resolved ? min : undefined,
          configuration.rangeValue
            ?.map((tick) => resolveTickValue(objectStore, dimensionsMappingToParametersMapping(dimensionsMapping), tick))
            .filter(filterNullOrUndefined)
            .filter((resolution): resolution is TickResolved => resolution.status === TickResolutionStatus.Resolved),
          max?.status === TickResolutionStatus.Resolved ? max : undefined
        );
      }
    }
    return color;
  } else {
    return undefined;
  }
};

export const getConceptPathConfigurationHandler = (
  store: ObjectStoreWithTimeseries,
  parameterDefinitions: SingleParameterDefinition[],
  lastStepMustBeNPath = true,
  lastStepMustNotBeNPath = false
): PathConfigurationHandler => {
  const conceptPathValidator: PathStepValidator[] = [
    ({ pathStep, isLastStep, isNPath, path }) => {
      // Swimlane must be on unique combination of instances (no n-n path)
      if (isLastStep) {
        // last step must be multiple
        if (lastStepMustBeNPath && !isNPath) {
          return [{ state: StepValidationState.invalid, reasonMessage: i18n`Input end with an unauthorized field.` }];
        } else if (lastStepMustNotBeNPath && isNPath) {
          return [{ state: StepValidationState.invalid, reasonMessage: i18n`Input end with an unauthorized field.` }];
        } else {
          return [{ state: StepValidationState.valid }];
        }
      } else if (isDimensionStep(pathStep) || isMappingStep(pathStep) || isFilterStep(pathStep)) {
        return [{ state: StepValidationState.valid }];
      } else if (isGlobalDimensionStep(pathStep)) {
        return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`Missing field.` }];
      } else if (isFieldStep(pathStep)) {
        const field = store.getObjectOrNull(pathStep.fieldId);
        if (!field) {
          return [{ state: StepValidationState.invalid, reasonMessage: i18n`Field does not exist anymore.` }];
        } else if (isMultiplePath(store, path.slice(0, -1))) {
          return [{ state: StepValidationState.invalid, reasonMessage: i18n`Cannot select fields on multiple dimension paths.` }];
        } else {
          return [{ state: StepValidationState.valid }];
        }
      } else {
        return [{ state: StepValidationState.invalid, reasonMessage: i18n`Invalid path.` }];
      }
    },
  ];
  return createPathConfigurationHandler(
    store,
    parameterDefinitions,
    conceptPathValidator
  );
};
export const getViewConceptConceptDefinitionId = (
  store: FrontObjectStore,
  viewDimensions: ViewDimension[],
  parameterDefinitions: SingleParameterDefinition[],
  lastStepMustBeNPath: boolean
): { error: string } | { error?: undefined, conceptDefinitionId: string } => {
  if (viewDimensions.length !== 1) {
    return { error: viewDimensions.length === 0 ? i18n`Invalid data: Missing dimension` : i18n`Invalid data: Only one dimension is authorized for this type of view` };
  } else {
    const dimensionPath = viewDimensions[0].path;
    const valuePathHandler = getConceptPathConfigurationHandler(store, parameterDefinitions, lastStepMustBeNPath);
    const error = valuePathHandler.getErrors(dimensionPath);
    if (error) {
      return { error: error.join('\n') };
    } else {
      const conceptDefinitionId = valuePathHandler.getReturnedConceptDefinitionId(dimensionPath);
      if (!conceptDefinitionId) {
        return { error: i18n`Invalid data: Missing returned concept` };
      } else {
        return { conceptDefinitionId };
      }
    }
  }
};

export const getViewConceptConceptError = (
  store: FrontObjectStore,
  viewDimensions: ViewDimension[],
  parameterDefinitions: SingleParameterDefinition[],
  lastStepMustBeNPath: boolean
): string | undefined => (
  getViewConceptConceptDefinitionId(store, viewDimensions, parameterDefinitions, lastStepMustBeNPath).error
);

export const getViewConceptPathOptions = (
  store: FrontObjectStore,
  parameterDefinitions: SingleParameterDefinition[],
  viewDimensions: ViewDimension[],
  getEditionOptions: (conceptDefinitionId: string) => LineEditionOption[],
  lastStepMustBeNPath: boolean
): ReactElement => {
  const result = getViewConceptConceptDefinitionId(store, viewDimensions, parameterDefinitions, lastStepMustBeNPath);
  if (result.error !== undefined) {
    return (<BlockContent padded>{getLineErrorContent(result.error)}</BlockContent>);
  } else {
    const editionOptions = getEditionOptions(result.conceptDefinitionId);
    return (
      <>
        {editionOptions.map((option) => (<ViewOptionBlock key={option.key} option={option} />))}
      </>
    );
  }
};

const getColorByValueOrField = (store: FrontObjectStore, valueField: NumberColorStepValue | undefined, parametersMapping: ParametersMapping): NumberColorStepValueResolved => {
  switch (valueField?.type) {
    case NumberColorStepValueType.value:
      return { ...valueField };
    case NumberColorStepValueType.field:
      if (Array.isArray(valueField?.value)) {
        const pathResolution = createValuePathResolver(store, parametersMapping).resolvePathValue(valueField?.value);
        if (isSingleValueResolution(pathResolution)) {
          return joinObjects(valueField, { value: pathResolution.value as number });
        }
      }
      break;
    default:
      return { value: undefined, color: undefined };
  }
  return { value: undefined, color: undefined };
};

export const resolveChartColors = (
  store: FrontObjectStore,
  minValue: NumberColorStepValue | undefined,
  maxValue: NumberColorStepValue | undefined,
  rangeValues: NumberColorStepValue[] | undefined,
  parametersMapping: ParametersMapping
): {
  min: NumberColorStepValueResolved,
  max: NumberColorStepValueResolved,
  steps: NumberColorStepValueResolved[],
} => ({
  min: getColorByValueOrField(store, minValue, parametersMapping),
  max: getColorByValueOrField(store, maxValue, parametersMapping),
  steps: rangeValues?.map((step) => getColorByValueOrField(store, step, parametersMapping)) ?? [],
});

export interface CreateAndLinkOptions {
  originConceptId?: string,
  targetConceptDefinitionId?: string,
  linkOptions?: {
    targetConceptDefinitionId: string,
    onLink: (id: string) => void,
    onUnlink: (id: string) => void,
    computeLinkableInstanceIds: () => string[],
  },
  createOptions?: {
    targetConceptDefinitionId: string,
    onCreate: () => string,
  },
  inlineCreationOptions?: {
    targetConceptDefinitionId: string,
    buildInlineCreation: () => (InlineCreationInline | InlineCreationTransactional),
  },
}

export enum CreateAndLinkOptionsVariants {
  standard = 'standard',
  inlineCreationOnly = 'inlineCreationOnly',
}

export const getCreateAndLinkOptions = (
  store: FrontObjectStore,
  aclHandler: ACLHandler,
  parametersMapping: ParametersMapping,
  viewDimensions: ViewDimension[],
  linkedInstanceIds: string[],
  linkElementFilterFunction: (id: string) => boolean,
  variant: CreateAndLinkOptionsVariants = CreateAndLinkOptionsVariants.standard
): CreateAndLinkOptions => {
  let originConceptId: CreateAndLinkOptions['originConceptId'];
  let inlineCreationOptions: CreateAndLinkOptions['inlineCreationOptions'];
  let createOptions: CreateAndLinkOptions['createOptions'];
  let linkOptions: CreateAndLinkOptions['linkOptions'];
  let targetConceptDefinitionId: CreateAndLinkOptions['targetConceptDefinitionId'];
  if (viewDimensions.length === 1 && viewDimensions[0].path) {
    const dimensionPath = viewDimensions[0].path;
    if (isDimensionLibraryPath(dimensionPath)) {
      if (aclHandler.canCreateObject(dimensionPath[0].conceptDefinitionId)) {
        targetConceptDefinitionId = dimensionPath[0].conceptDefinitionId;
        if (variant === CreateAndLinkOptionsVariants.standard) {
          createOptions = {
            targetConceptDefinitionId,
            onCreate: () => store.createObject({ [Instance_Of]: dimensionPath[0].conceptDefinitionId }),
          };
        } else {
          inlineCreationOptions = {
            targetConceptDefinitionId,
            buildInlineCreation: getInlineCreationBuilder(store, dimensionPath[0].conceptDefinitionId),
          };
        }
      }
    } else {
      const pathLastFieldInformation = getPathLastFieldInformation(dimensionPath);
      if (pathLastFieldInformation) {
        const resolution = createValuePathResolver(store, parametersMapping).resolvePathField(pathLastFieldInformation.pathToField);
        if (isSingleFieldResolution(resolution)) {
          const resolutionMapping = resolution.dimensionsMapping;
          if (resolutionMapping && Object.entries(resolutionMapping).length === 1) {
            const parsedDimension = parseDimensionMapping(resolutionMapping);
            if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
              originConceptId = parsedDimension.objectId;
              const fieldUtilsHandler = getFieldUtilsHandler(store, resolution.fieldId);
              const { fieldDefinitionId } = fieldUtilsHandler.resolveConfiguration();
              targetConceptDefinitionId = fieldUtilsHandler.getTargetType?.()?.id;
              const field = store.getObjectOrNull<FieldStoreObject>(resolution.fieldId);
              if (targetConceptDefinitionId && field) {
                if (field[Field_IntegrationOnly] || targetConceptDefinitionId === Concept || !aclHandler.canWriteObject(parsedDimension.objectId)) {
                  return { originConceptId };
                }
                const computeOptions = (): string[] => {
                  if (pathLastFieldInformation.pathAfterField.length) {
                    const dimensionResolution = createValuePathResolver(store, parametersMapping).resolvePathDimension(pathLastFieldInformation.pathAfterField);
                    if (!dimensionResolution || dimensionResolution instanceof Error || isGlobalDimensionResolution(dimensionResolution)) {
                      return [];
                    } else if (isSingleDimensionResolution(dimensionResolution)) {
                      return [dimensionResolution.instance?.id]
                        .filter(filterNullOrUndefined)
                        .filter(linkElementFilterFunction);
                    } else {
                      return dimensionResolution.instances.map(({ id }) => id)
                        .filter(linkElementFilterFunction);
                    }
                  } else if (targetConceptDefinitionId) {
                    return getModelTypeInstances(store, targetConceptDefinitionId).map(({ id }) => id);
                  } else {
                    return [];
                  }
                };
                const isCreationEnabled = aclHandler.canCreateObject(targetConceptDefinitionId);
                const isAssociationEnabled = canCreateAssociation(targetConceptDefinitionId);
                if (fieldDefinitionId === AssociationField) {
                  const fieldHandler = associationFieldHandler(store, resolution.fieldId);
                  if (isAssociationEnabled) {
                    const onLink = (id: string) => fieldHandler.updateValue(resolutionMapping, { action: 'add', objectIds: [id] });
                    linkOptions = {
                      targetConceptDefinitionId,
                      onLink,
                      onUnlink: (id) => fieldHandler.updateValue(resolutionMapping, { action: 'remove', objectIds: [id] }),
                      computeLinkableInstanceIds: () => difference(computeOptions(), linkedInstanceIds),
                    };
                    if (isCreationEnabled) {
                      inlineCreationOptions = {
                        targetConceptDefinitionId,
                        buildInlineCreation: getInlineCreationBuilder(store, targetConceptDefinitionId, onLink),
                      };
                    }
                  }
                } else if (fieldDefinitionId === EmbeddingField) {
                  if (isCreationEnabled) {
                    if (variant === CreateAndLinkOptionsVariants.standard) {
                      createOptions = {
                        targetConceptDefinitionId,
                        onCreate: () => store.createObject({
                          [Instance_Of]: targetConceptDefinitionId,
                          [KinshipRelation]: resolution.fieldId,
                          [resolution.fieldId]: parsedDimension.objectId,
                        }),
                      };
                    } else {
                      inlineCreationOptions = {
                        targetConceptDefinitionId,
                        buildInlineCreation: getInlineCreationBuilder(store, targetConceptDefinitionId, undefined, undefined, {
                          [KinshipRelation]: resolution.fieldId,
                          [resolution.fieldId]: parsedDimension.objectId,
                        }),
                      };
                    }
                  }
                } else if (fieldDefinitionId === RelationMultipleField) {
                  const fieldHandler = relationMultipleFieldHandler(store, resolution.fieldId);
                  if (isAssociationEnabled) {
                    const onLink = (id: string) => fieldHandler.updateValue(resolutionMapping, { action: 'add', objectIds: [id] });
                    linkOptions = {
                      targetConceptDefinitionId,
                      onLink,
                      onUnlink: (id) => fieldHandler.updateValue(resolutionMapping, { action: 'remove', objectIds: [id] }),
                      computeLinkableInstanceIds: () => difference(computeOptions(), linkedInstanceIds),
                    };
                    if (isCreationEnabled) {
                      inlineCreationOptions = {
                        targetConceptDefinitionId,
                        buildInlineCreation: getInlineCreationBuilder(store, targetConceptDefinitionId, onLink),
                      };
                    }
                  }
                } else if (fieldDefinitionId === RelationSingleField) {
                  const fieldHandler = relationSingleFieldHandler(store, resolution.fieldId);
                  if (isAssociationEnabled) {
                    const onLink = (id: string) => fieldHandler.updateValue(resolutionMapping, id);
                    linkOptions = {
                      targetConceptDefinitionId,
                      onLink,
                      onUnlink: () => fieldHandler.updateValue(resolutionMapping, null),
                      computeLinkableInstanceIds: () => difference(computeOptions(), linkedInstanceIds),
                    };
                    if (isCreationEnabled && isAssociationEnabled) {
                      inlineCreationOptions = {
                        targetConceptDefinitionId,
                        buildInlineCreation: getInlineCreationBuilder(store, targetConceptDefinitionId, onLink),
                      };
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  return { inlineCreationOptions, createOptions, linkOptions, originConceptId, targetConceptDefinitionId };
};

export const canDuplicateInstance = (store: FrontObjectStore, dimension: ViewDimension | undefined): boolean => {
  if (!dimension) {
    return false;
  }
  const lastFieldInfo = getPathLastFieldInformation(dimension.path);
  const fieldId = lastFieldInfo?.fieldId;
  if (fieldId) {
    const field = store.getObjectOrNull(fieldId);
    return !isInstanceOf(field, RelationSingleField);
  }
  return true;
};

export const extractLastFieldFilters = (path: PathStep[] | undefined): Filters | undefined => {
  const lastStep = path?.slice(-1)[0];
  if (lastStep && isFilterStep(lastStep) && lastStep.filters) {
    return lastStep.filters;
  } else {
    return undefined;
  }
};

export const removeFiltersFromDimensions = (viewDimensions: ViewDimension[]): ViewDimension[] => (
  viewDimensions.map((viewDimension) => {
    const filters = extractLastFieldFilters(viewDimension.path);
    return filters ? joinObjects(viewDimension, { path: viewDimension.path.slice(0, -1) }) : viewDimension;
  })
);

export const buildFilterFunctionFromDimensions = (
  store: FrontObjectStore,
  viewDimensions: ViewDimension[],
  parametersMapping: ParametersMapping
): (parametersMapping: ParametersMapping) => boolean => (
  (execParametersMapping) => (
    viewDimensions.every((viewDimension) => {
      const filters = extractLastFieldFilters(viewDimension.path);
      if (!filters) {
        return true;
      }
      const filterFunctionHandler = getFilterFunctionHandler(store, filters);
      if (!filterFunctionHandler) {
        return true;
      }
      return filterFunctionHandler(
        joinObjects(
          parametersMapping,
          execParametersMapping,
          { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: execParametersMapping[viewDimension.id]?.id } }
        )
      );
    })
  )
);

export const getViewTypeSummary = (viewsDefinitions: ViewResolvedDefinition[]): string => {
  let viewsDisplay = viewsDefinitions.map((v) => v.label).join(', ');
  if (viewsDisplay) {
    viewsDisplay = `${viewsDisplay}`;
  }
  if (viewsDisplay.length === 0) {
    viewsDisplay = i18n`No view has been configured`;
  }
  return viewsDisplay;
};

export const getViewNavigationFilters = (
  store: FrontObjectStore,
  viewDimensions: ViewDimension[],
  filtersConfiguration: FilterConfiguration | undefined,
  parametersMapping: ParametersMapping
): NavigationFilter => {
  if (viewDimensions.length !== 1) {
    return {};
  }
  const dimensionPath = viewDimensions[0].path;
  const targetConceptDefinition = getPathReturnedConceptDefinitionId(store, dimensionPath);
  return {
    globalFilters: targetConceptDefinition ? getConceptFilters(store, targetConceptDefinition, filtersConfiguration) : undefined,
    globalParametersMapping: parametersMapping,
    navigationPath: dimensionPath,
    filterDimensionId: viewDimensions.length === 1 ? viewDimensions[0].id : undefined,
  };
};

export const getExportEditionOptions = <T extends ViewStoredDefinition & ExportViewDefinition, U extends ExportViewResolvedDefinition>(
  widgetName: string | undefined,
  updateViewDefinition: UpdateViewDefinition<T>,
  viewDefinition: U,
  readOnly: boolean
): LineEditionOption[] => {
  const editionOptions: LineEditionOption[] = [];
  editionOptions.push({
    key: 'ExcelExport',
    title: i18n`Excel export`,
    type: EditionOptionTypes.custom,
    props: {
      render: () => (
        <Chooser
          actions={[{ key: 'disabled', name: i18n`Disabled` }, { key: 'enabled', name: i18n`Enabled` }]}
          onClick={(index) => updateViewDefinition((oldViewDefinition) => {
            const newViewDefinition = { ...oldViewDefinition };
            newViewDefinition.export = index === 1;
            return newViewDefinition;
          })}
          selectedIndexes={viewDefinition.export ? [1] : [0]}
          readOnly={readOnly}
          noBoxShadow
        />
      ),
    },
  });

  let fileName = i18n`export`;
  if (viewDefinition.exportTitle) {
    fileName = viewDefinition.exportTitle;
  } else if (widgetName) {
    fileName = `${widgetName}-${fileName}`;
  }

  if (viewDefinition.export) {
    editionOptions.push({
      key: 'ExcelTitle',
      title: i18n`Export file name`,
      type: EditionOptionTypes.text,
      info: i18n`The file name will be '${formatDisplayDate(new Date(), dateFormats.isoDateFormat)},${fileName}.xlsx'`,
      props: {
        value: viewDefinition.exportTitle,
        placeholder: `${formatDisplayDate(new Date(), dateFormats.isoDateFormat)},${fileName}.xlsx`,
        onChange: (fileTitle) => updateViewDefinition((oldViewDefinition) => {
          const newViewDefinition = { ...oldViewDefinition };
          if (typeof fileTitle === 'string') {
            newViewDefinition.exportTitle = fileTitle ?? undefined;
          }
          return newViewDefinition;
        }),
        readOnly,
      },
    });
  }
  return editionOptions;
};

export const isExportViewDefinition = (viewDefinition: unknown): viewDefinition is ExportViewDefinition => (viewDefinition as ExportViewDefinition).export !== undefined;
