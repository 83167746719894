import { createModule } from '../common/types/TypeModuleDsl';
import * as ReferenceModuleIds from './ids';
import { ReferenceModuleId } from './ids';
import migrations from './migrations';

const { initTypedModule, ...registerModelDsl } = createModule({ label: 'Reference' }, ReferenceModuleIds);
export const registerModel = registerModelDsl;

export const initModule = initTypedModule(() => ({
  id: ReferenceModuleId,
  migrations,
}));
