import { v4 as uuid } from 'uuid';
import { createResolvablePromise, newError, sleep } from 'yooi-utils';
import buildInfo from './buildInfo';

interface NetworkFunctionProtocol<Req = unknown, Res = unknown> {
  request: Req,
  response: Res,
}

export interface BrowserNetworkProtocol {
  [functionId: string]: NetworkFunctionProtocol,
}

type ResponseAcceptor<Res> = (response: Res, responderNodeId: string) => boolean;

interface NetworkFunctionHandler<F extends NetworkFunctionProtocol> {
  handleRequest: (request: F['request']) => Promise<F['response'] | undefined> | F['response'] | undefined,
  reportMetric?: (data: { [key: string]: unknown }) => void,
}

interface ResponseMessage<Res> {
  // requestId is inferred by including it in the channel name
  responderNodeId: string,
  response: Res,
}

interface NetworkFunctionClient<F extends NetworkFunctionProtocol> {
  (
    request: F['request'],
    wait: { timeout: number, maxResponseCount?: number },
    targetNodeIdOrResponseAcceptor: string | ResponseAcceptor<F['response']>
  ): Promise<ResponseMessage<F['response']> | undefined>,
}

type BrowserNetworkClient<P extends BrowserNetworkProtocol> = {
  [functionId in keyof P]: NetworkFunctionClient<P[functionId]>;
};

type BrowserNetworkHandler<P extends BrowserNetworkProtocol> = {
  [functionId in keyof P]: NetworkFunctionHandler<P[functionId]>;
};

interface BrowserNetworkRegistration<P extends BrowserNetworkProtocol> {
  client: BrowserNetworkClient<P>,
  close: () => void,
}

export interface BrowserNetworkManager {
  registerNetwork: <P extends BrowserNetworkProtocol>(networkId: string, handler: BrowserNetworkHandler<P>) => BrowserNetworkRegistration<P>,
}

const createBrowserNetworkManager = (): BrowserNetworkManager => {
  interface RequestMessage<Req> {
    requestId: string,
    requesterNodeId: string,
    request: Req,
  }

  const currentNodeId = uuid();

  interface RegisteredNetwork {
    close: () => void,
  }

  const registeredNetworks = new Map<string, RegisteredNetwork>();

  const registerNetwork = <P extends BrowserNetworkProtocol>(networkId: string, networkHandler: BrowserNetworkHandler<P>) => {
    if (registeredNetworks.has(networkId)) {
      throw newError('Network is already registered', { networkId });
    }

    const createChannel = (functionId: string, role: 'broadcast' | `unicast/${string}` | `response/${string}`) => new BroadcastChannel(
      `yooi:${buildInfo.sourceHash}:${networkId}:${functionId}:${role}`
    );
    const createBroadcastChannel = (functionId: string) => createChannel(functionId, 'broadcast');
    const createUnicastChannel = (functionId: string, nodeId: string) => createChannel(functionId, `unicast/${nodeId}`);
    const createResponseChannel = (functionId: string, requestId: string) => createChannel(functionId, `response/${requestId}`);

    const createServerFunctionHandler = <F extends NetworkFunctionProtocol>(functionId: string, { handleRequest, reportMetric }: NetworkFunctionHandler<F>) => {
      const messageEventHandler = async ({ data: { requestId, requesterNodeId, request } }: MessageEvent<RequestMessage<unknown>>) => {
        if (requesterNodeId === currentNodeId) {
          // ensure own request are ignored
          return;
        }

        const responseStart = performance.now();
        const response = await handleRequest(request);
        const responseEnd = performance.now();
        if (response) {
          const responseChannel = createResponseChannel(functionId, requestId);
          try {
            responseChannel.postMessage({
              responderNodeId: currentNodeId,
              response,
            } satisfies ResponseMessage<unknown>);
            const postEnd = performance.now();
            reportMetric?.({
              networkId,
              functionId,
              duration: Math.ceil(postEnd - responseStart),
              handlerDuration: Math.ceil(responseEnd - responseStart),
              postDuration: Math.ceil(postEnd - responseEnd),
            });
          } finally {
            responseChannel.close();
          }
        }
      };

      const broadcastChannel = createBroadcastChannel(functionId);
      broadcastChannel.onmessage = messageEventHandler;
      const unicastChannel = createUnicastChannel(functionId, currentNodeId);
      unicastChannel.onmessage = messageEventHandler;

      return {
        broadcastChannel, // this is used by the client to avoid create a second channel to send the request and so avoid to filter the request in the server
        closeChannels: () => {
          broadcastChannel.close();
          unicastChannel.close();
        },
      };
    };

    const serverHandlerRegistrations = Object.fromEntries(
      Object.entries(networkHandler).map(([functionId, functionHandler]) => [functionId, createServerFunctionHandler(functionId, functionHandler)])
    );

    let isOpen = true;

    const createFunctionClient = <F extends NetworkFunctionProtocol>(functionId: string): NetworkFunctionClient<F> => async (
      request,
      { timeout, maxResponseCount },
      targetNodeIdOrResponseAcceptor
    ): Promise<ResponseMessage<F['response']> | undefined> => {
      if (!isOpen) {
        throw newError('BrowserNetworkManager is closed');
      }

      const isBroadcastRequest = typeof targetNodeIdOrResponseAcceptor !== 'string';
      const targetNodeId = isBroadcastRequest ? undefined : targetNodeIdOrResponseAcceptor;
      if (targetNodeId === currentNodeId) {
        throw newError('Request cannot target same node', { currentNodeId });
      }

      const responseAcceptor = isBroadcastRequest ? targetNodeIdOrResponseAcceptor : () => true;

      const requestId = uuid();
      const resultResolvablePromise = createResolvablePromise<{
        responderNodeId: string,
        response: F['response'],
      } | undefined>();

      // try to get the corresponding server broadcast channel if already registered, so that using it will avoid to get this request
      const registrationScopeRequestChannel = targetNodeId ? undefined : serverHandlerRegistrations[functionId]?.broadcastChannel;
      const requestChannel = registrationScopeRequestChannel ?? (targetNodeId ? createUnicastChannel(functionId, targetNodeId) : createBroadcastChannel(functionId));
      const responseChannel = createResponseChannel(functionId, requestId);
      try {
        let responseCount = 0;
        responseChannel.onmessage = ({ data: { responderNodeId, response } }: MessageEvent<ResponseMessage<ResponseType>>) => {
          if (!resultResolvablePromise.isSettled()) {
            responseCount += 1;
            if (responseAcceptor(response, responderNodeId)) {
              resultResolvablePromise.resolve({ responderNodeId, response });
            } else if (responseCount === maxResponseCount) {
              resultResolvablePromise.resolve(undefined);
            }
          }
        };

        requestChannel.postMessage({
          requestId,
          requesterNodeId: currentNodeId,
          request,
        } satisfies RequestMessage<typeof request>);

        // if timeout win the race, the result is undefined
        return await Promise.race([resultResolvablePromise.promise, sleep(timeout) as Promise<undefined>]);
      } finally {
        if (requestChannel !== registrationScopeRequestChannel) {
          requestChannel.close();
        }
        responseChannel.close();
      }
    };

    // this dirty cast is necessary to workaround typescript limitations
    const client = Object.fromEntries(Object.keys(networkHandler).map((functionId) => [functionId, createFunctionClient(functionId)])) as unknown as BrowserNetworkClient<P>;

    const network = {
      client,
      close: () => {
        isOpen = false;
        Object.values(serverHandlerRegistrations).forEach(({ closeChannels }) => closeChannels());
        registeredNetworks.delete(networkId);
      },
    };
    registeredNetworks.set(networkId, network);
    return network;
  };

  return {
    registerNetwork,
  };
};

export default createBrowserNetworkManager;
