import type { PathStep } from 'yooi-modules/modules/conceptModule';
import { PathStepType } from 'yooi-modules/modules/conceptModule';
import type { Block } from 'yooi-modules/modules/dashboardModule';
import type { NonUndefined } from 'yooi-utils';
import { getMappingIdsUsedInPath } from '../_global/pathUtils';

export enum HighlightedType {
  item = 'item',
  group = 'group',
  arrow = 'arrow',
  inPath = 'inPath',
}

interface HighlightItem {
  type: HighlightedType.item | HighlightedType.group,
  key: string,
}

export type Highlight =
  HighlightItem
  | { type: HighlightedType.inPath, key: string }
  | { type: HighlightedType.arrow, source: HighlightItem, target: HighlightItem };

export const getBlockIdsUsedInPath = (path: PathStep[], blockIds: Set<string>): string[] => getMappingIdsUsedInPath(path).filter((id) => blockIds.has(id));

export const getDefaultArrowColor = (conceptDefinitionId: string | undefined): NonUndefined<Block['arrowColor']> => (
  { type: 'path', path: conceptDefinitionId ? [{ type: PathStepType.dimension, conceptDefinitionId }] : [] }
);

export const getArrowColor = (arrowColor: Block['arrowColor'] | undefined, conceptDefinitionId: string | undefined): NonUndefined<Block['arrowColor']> => {
  if (!arrowColor && conceptDefinitionId) {
    return getDefaultArrowColor(conceptDefinitionId);
  } else if (!arrowColor) {
    return { type: 'value', value: undefined };
  } else {
    return arrowColor;
  }
};
