import type { FunctionComponent, ReactElement } from 'react';
import { useCallback, useState } from 'react';
import type { DateRange as DateRangeType } from 'yooi-utils';
import { computeEffectiveValueFrom, DateStorageTypeKeys, periodicities, PeriodicityType } from 'yooi-utils';
import { Spacing, spacingRem } from '../../../theme/spacingDefinition';
import { formatDate, formatDateRange } from '../../../utils/dateUtilsFront';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useSizeContext, { SizeVariant } from '../../../utils/useSizeContext';
import useTheme from '../../../utils/useTheme';
import useUsageContext, { UsageVariant } from '../../../utils/useUsageContext';
import Icon, { IconColorVariant, IconName } from '../../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../atoms/IconOnlyButton';
import Typo from '../../atoms/Typo';
import Chooser from '../../molecules/Chooser';
import EditableWithDropdown, { EditableCloseReasons } from '../../molecules/EditableWithDropdown';
import SpacedContainer from '../../molecules/SpacedContainer';
import DateRangeCustom from './DateRangeCustom';
import type { DateRangeOptions } from './DateRangePredefined';
import DateRangePredefined from './DateRangePredefined';

const useStyles = makeStyles({
  valueContainer: {
    marginLeft: spacingRem.s,
    marginRight: spacingRem.s,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: spacingRem.s,
    alignItems: 'center',
    flexGrow: 1,
  },
}, 'dateRange');

interface DateRangeProps {
  value: DateRangeType | undefined,
  onChange: (value: DateRangeType | undefined) => void,
  onSubmit: (value: DateRangeType | undefined) => void,
  onCancel: (value: DateRangeType | undefined) => void,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  placeholder?: string,
  withMultiplayerOutline?: boolean,
  withPredefinedRanges?: boolean,
  predefinedRangeOption?: DateRangeOptions,
  withLastAndNext?: boolean,
  readOnly?: boolean,
  withStartConstraint?: boolean,
  startConstraintChip?: ReactElement,
  startConstraintValue?: number,
  allowEmptyConstraint?: boolean,
  defaultPeriodicity?: PeriodicityType,
  focusOnMount?: boolean,
  restingTooltip?: string | (() => Promise<string>),
}

const DateRange: FunctionComponent<DateRangeProps> = ({
  value,
  onChange,
  onSubmit,
  onCancel,
  onEditionStart,
  onEditionStop,
  placeholder,
  withMultiplayerOutline = false,
  withPredefinedRanges,
  predefinedRangeOption,
  withLastAndNext,
  readOnly = false,
  withStartConstraint = true,
  startConstraintChip,
  startConstraintValue,
  allowEmptyConstraint = false,
  defaultPeriodicity = PeriodicityType.day,
  focusOnMount = false,
  restingTooltip,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const { sizeVariant } = useSizeContext();

  const contextUsageVariant = useUsageContext();
  const usageVariant = contextUsageVariant || UsageVariant.inForm;
  const isTableVariant = usageVariant === UsageVariant.inTable;
  const [showDropdown, setShowDropdown] = useState(focusOnMount);

  const valueFrom = value?.from;
  const valueTo = value?.to;

  const valuePeriod = value?.period ?? defaultPeriodicity;

  const defaultFromType = startConstraintChip ? DateStorageTypeKeys.constraint : DateStorageTypeKeys.date;
  const defaultToType = DateStorageTypeKeys.date;

  const openDropdown = useCallback(() => {
    if (!readOnly) {
      if (valuePeriod === PeriodicityType.year) {
        let fromValue;
        let toValue;
        if (valueFrom?.value === undefined && (valueFrom?.type ?? defaultFromType) === DateStorageTypeKeys.date) {
          fromValue = periodicities[PeriodicityType.year].getStartOfPeriod?.(new Date()).getTime();
        }
        if (valueTo?.value === undefined && (valueTo?.type ?? defaultToType) === DateStorageTypeKeys.date) {
          toValue = periodicities[PeriodicityType.year].getEndOfPeriod?.(new Date()).getTime();
        }
        if (fromValue || toValue) {
          onChange({
            period: PeriodicityType.year,
            from: { value: fromValue ?? valueFrom?.value, type: valueFrom?.type ?? defaultFromType },
            to: { value: toValue ?? valueTo?.value, type: valueTo?.type ?? defaultFromType },
          });
        }
      }
      onEditionStart?.();
    }
    setShowDropdown(true);
  }, [defaultFromType, defaultToType, onChange, onEditionStart, readOnly, valueFrom?.type, valueFrom?.value, valueTo?.type, valueTo?.value, valuePeriod]);

  const closeDropdown = (reason: EditableCloseReasons) => {
    if (!readOnly) {
      onEditionStop?.();
    }
    setShowDropdown(false);
    if (!readOnly) {
      if (reason !== EditableCloseReasons.onEscapeKeyDown) {
        onSubmit(value);
      } else {
        onCancel(value);
      }
    }
  };

  const viewTabs = [
    {
      key: 'predefined',
      name: i18n`Predefined range`,
      component: (
        <DateRangePredefined onSubmit={onChange} value={value} withLastAndNext={withLastAndNext} dateRangeOption={predefinedRangeOption} />
      ),
    },
    {
      key: 'custom',
      name: i18n`Custom range`,
      component: (
        <DateRangeCustom
          onSubmit={onChange}
          value={value}
          withStartConstraint={!withPredefinedRanges}
          withLastAndNext={withLastAndNext}
          defaultFromType={defaultFromType}
          defaultToType={defaultToType}
          allowEmptyConstraint={allowEmptyConstraint}
        />
      ),
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleViewChange = (index: number) => {
    setCurrentIndex(index);
  };
  const { type: fromType } = valueFrom ?? {};
  const effectiveValueFrom = computeEffectiveValueFrom(value, startConstraintValue, valuePeriod);
  const { text, fromIcon, fromTooltipText, toIcon, toTooltipText, error } = formatDateRange(value, startConstraintValue, defaultPeriodicity, allowEmptyConstraint);

  const { dateFormatted: startConstraintFormatted } = formatDate(
    startConstraintValue,
    valuePeriod
  );

  let startConstraintError = '';
  // Only display constraint error if start date is in "Use constraint" mode
  if (effectiveValueFrom?.value && startConstraintValue && effectiveValueFrom?.value < startConstraintValue && fromType === DateStorageTypeKeys.constraint) {
    startConstraintError = i18n`Your start date is before your start constraint: ${startConstraintFormatted ?? ''}`;
  } else if (!startConstraintValue && fromType === DateStorageTypeKeys.constraint && !allowEmptyConstraint) {
    startConstraintError = i18n`Your start constraint has no value`;
  }

  const renderSelected = (restingMode: boolean): ReactElement | null => {
    if (!restingMode || text) {
      return (
        <div className={classes.valueContainer}>
          <div className={classes.dateContainer}>
            {fromIcon ? (<Icon name={fromIcon} tooltip={fromTooltipText} />) : null}
            <Typo maxLine={1}>{text}</Typo>
            {toIcon ? (<Icon name={toIcon} tooltip={toTooltipText} />) : null}
          </div>
          {(error || startConstraintError) ? (<Icon name={IconName.dangerous} tooltip={error ?? startConstraintError} colorVariant={IconColorVariant.error} />) : null}
          {!restingMode && (valueFrom?.value != null || valueTo?.value != null) ? (
            <IconOnlyButton
              iconName={IconName.delete}
              variant={IconOnlyButtonVariants.tertiary}
              tooltip={i18n`Clear`}
              onClick={() => onChange({})}
            />
          ) : null}
        </div>
      );
    } else if (placeholder && !readOnly && !isTableVariant) {
      return (
        <div className={classes.valueContainer}>
          <Typo
            maxLine={1}
            color={theme.color.text.disabled}
          >
            {placeholder}
          </Typo>
        </div>
      );
    } else {
      return null;
    }
  };

  if (withPredefinedRanges) {
    const dropdownEditorSize = sizeVariant === SizeVariant.main ? '41.4rem' : '41.2rem';
    return (
      <EditableWithDropdown
        variant={usageVariant}
        dropdownSizes={{
          width: dropdownEditorSize,
        }}
        showDropdown={showDropdown}
        openDropdown={openDropdown}
        readOnly={readOnly}
        closeDropdown={closeDropdown}
        renderValue={(inDropdown) => renderSelected(!inDropdown)}
        renderDropdown={readOnly ? undefined : () => (
          <SpacedContainer margin={{ y: Spacing.s, x: Spacing.s }}>
            <SpacedContainer margin={{ bottom: Spacing.s }}>
              <Chooser actions={viewTabs} onClick={handleViewChange} selectedIndexes={[currentIndex]} />
            </SpacedContainer>
            {viewTabs[currentIndex].component}
          </SpacedContainer>
        )}
        dropdownFixedWidth
        restingTooltip={restingTooltip}
      />
    );
  } else {
    const dropdownEditorSize = isTableVariant || usageVariant ? undefined : '41.4rem'; // 0.2rem due to outline vs border usage
    const editableMinWidth = '15rem';
    return (
      <EditableWithDropdown
        variant={usageVariant}
        editableSizes={{
          minWidth: editableMinWidth,
          width: isTableVariant || usageVariant ? undefined : 'fit-content',
          maxWidth: dropdownEditorSize,
        }}
        dropdownSizes={{
          width: readOnly ? undefined : dropdownEditorSize,
        }}
        showDropdown={showDropdown}
        openDropdown={openDropdown}
        closeDropdown={closeDropdown}
        autoFocus
        readOnly={readOnly}
        renderValue={(inDropdown) => renderSelected(!inDropdown || readOnly)}
        renderDropdown={readOnly ? undefined : () => (
          <SpacedContainer margin={{ y: Spacing.s, x: Spacing.s }}>
            <DateRangeCustom
              onSubmit={onChange}
              startConstraintChip={startConstraintChip}
              startConstraintValue={startConstraintValue}
              value={value} // We don't want to update value on change
              withLastAndNext={withLastAndNext}
              defaultPeriodicity={defaultPeriodicity}
              defaultFromType={defaultFromType}
              defaultToType={defaultFromType}
              withStartConstraint={withStartConstraint}
              allowEmptyConstraint={allowEmptyConstraint}
            />
          </SpacedContainer>
        )}
        dropdownFixedWidth
        withMultiplayerOutline={withMultiplayerOutline}
        restingTooltip={restingTooltip}
      />
    );
  }
};

export default DateRange;
