import type { FieldBlockDisplayStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import { BlockFieldLayoutOption } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration, FieldBlockDisplay_FieldPath } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { FieldBlockDisplayOptions, LayoutOption } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import type { ObjectStoreReadOnly } from 'yooi-store';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import type { OptionRecord } from '../../modelTypeUtils';
import { defaultOptionComparator } from '../../modelTypeUtils';
import type { EditionOptionSection } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';

export enum BlockFieldDisplayStatus {
  visible = 'visible',
  hidden = 'hidden',
  locked = 'locked',
}

export const getBlockFieldLayoutOption = (): OptionRecord<BlockFieldLayoutOption> => ({
  [BlockFieldLayoutOption.auto]: { id: BlockFieldLayoutOption.auto, label: i18n`Auto` },
  [BlockFieldLayoutOption.column]: { id: BlockFieldLayoutOption.column, label: i18n`Single column` },
  [BlockFieldLayoutOption.row]: { id: BlockFieldLayoutOption.row, label: i18n`Single row` },
});

export const getFiledBlockDisplay = (store: ObjectStoreReadOnly, fieldBlockDisplayId: string): BlockFieldLayoutOption => {
  const fieldBlockDisplay = store.getObject<FieldBlockDisplayStoreObject>(fieldBlockDisplayId);
  const overrideValue = fieldBlockDisplay?.[FieldBlockDisplay_FieldDisplayConfiguration]?.layoutDisplayType;
  if (!overrideValue) {
    return BlockFieldLayoutOption.auto;
  }
  return overrideValue;
};

export const getDefaultDisplayOptions = (objectStore: ObjectStoreReadOnly, fieldBlockDisplayId: string): FieldBlockDisplayOptions => {
  const fieldBlockDisplay = objectStore.getObjectOrNull<FieldBlockDisplayStoreObject>(fieldBlockDisplayId);
  if (fieldBlockDisplay?.[FieldBlockDisplay_FieldDisplayConfiguration]) {
    return fieldBlockDisplay[FieldBlockDisplay_FieldDisplayConfiguration];
  } else {
    return { layoutDisplayType: BlockFieldLayoutOption.auto };
  }
};

export const getLayoutDisplayOption = <T extends FieldBlockDisplayOptions>(state: T, setState: (state: T) => void): EditionOptionSection => {
  const options = getBlockFieldLayoutOption();

  return {
    key: 'layoutDisplayType',
    title: i18n`Layout type`,
    action: {
      type: EditionOptionTypes.select,
      props: {
        computeOptions: () => Object.values(options).sort(defaultOptionComparator),
        selectedOption: options[state.layoutDisplayType ?? BlockFieldLayoutOption.auto],
        onChange: (selectedOption) => {
          if (selectedOption) {
            const newState = { ...state };
            newState.layoutDisplayType = selectedOption?.id === BlockFieldLayoutOption.auto ? undefined : ((selectedOption as LayoutOption)?.id ?? undefined);
            setState(newState);
          }
        },
      },
    },
    options: [],
  };
};

export const getFieldBlockDisplayDimensions = (store: FrontObjectStore, fieldBlockDisplayId: string): ViewDimension[] => {
  const fieldBlockDisplay = store.getObject<FieldBlockDisplayStoreObject>(fieldBlockDisplayId);
  return [{
    id: `${fieldBlockDisplay.id}_Dimension`,
    display: {},
    path: fieldBlockDisplay[FieldBlockDisplay_FieldPath] ?? [],
  }];
};
