import { isMultiplePath } from 'yooi-modules/modules/conceptModule';
import type { DimensionDisplayOption, TimeseriesTableViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { DimensionDisplayAxis } from 'yooi-modules/modules/dashboardModule';
import { joinObjects } from 'yooi-utils';
import type { FrontObjectStore } from '../../../../store/useStore';
import { getViewDefinitionDimensionsDisplayOptions } from '../common/dimensions/viewWithDimensionDisplayOptionsFeatureUtils';

export interface TimeseriesTableDimensionDisplayOption extends DimensionDisplayOption {
  display: 'show' | 'hide',
}

export const getTimeseriesTableViewDefinitionDimensionsDisplayOptions = (
  store: FrontObjectStore,
  definition: TimeseriesTableViewStoredDefinition,
  viewDimensions: ViewDimension[]
): TimeseriesTableDimensionDisplayOption[] => {
  const displayOptions = getViewDefinitionDimensionsDisplayOptions(
    definition,
    viewDimensions,
    definition.timeAxis?.axis === DimensionDisplayAxis.x ? DimensionDisplayAxis.y : DimensionDisplayAxis.x
  );
  return displayOptions.map((displayOption, index) => {
    const isDimensionMultiple = isMultiplePath(store, viewDimensions[index].path);
    const dimensionDisplay = (definition.dimensionsDisplay ?? []).find(({ id }) => displayOption.id === id);
    return joinObjects(displayOption, { display: isDimensionMultiple ? 'show' : dimensionDisplay?.display ?? 'hide' });
  });
};
