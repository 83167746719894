import type { MutableRefObject } from 'react';
import { useRef } from 'react';
import declareBus from './declareBus';

const useFocusNewLineBus = declareBus<string>('focusNewLine').useBus;

const useFocusNewLine = (callback: (objectId: string) => void) => {
  useFocusNewLineBus((objectId) => {
    callback(objectId);
  });
};

export const useFocusNewLineNotify: () => (objectId: string) => void = () => {
  const dispatchUpdated = useFocusNewLineBus();
  return (objectId) => dispatchUpdated(objectId);
};

export interface NewLineFocusRefContent {
  id: string | undefined,
  reset: () => void,
}

const useNewLineFocus = (): [MutableRefObject<NewLineFocusRefContent>, (id: string) => void] => {
  const newlyCreated = useRef<NewLineFocusRefContent>({
    id: undefined,
    reset: () => {
      newlyCreated.current.id = undefined;
    },
  });
  useFocusNewLine((id) => {
    newlyCreated.current.id = id;
  });
  return [newlyCreated, (id) => {
    newlyCreated.current.id = id;
  }];
};

export default useNewLineFocus;
