import StoreObjectNotFoundErrorBoundary from '../../store/StoreObjectNotFoundErrorBoundary';
import i18n from '../../utils/i18n';
import type { Switch } from '../../utils/routerTypes';
import { ChipBlockContextProvider } from '../../utils/useChipBlockContext';
import ConceptLibrary from './ConceptLibrary';
import ConceptPage from './ConceptPage';

const ConceptRouter: Switch = {
  switch: {
    '/:conceptDefinitionId': {
      switch: {
        '/': ({ conceptDefinitionId }) => (
          <ChipBlockContextProvider context={conceptDefinitionId}>
            <StoreObjectNotFoundErrorBoundary objectName={i18n`Concept`}>
              <ConceptLibrary conceptDefinitionId={conceptDefinitionId} />
            </StoreObjectNotFoundErrorBoundary>
          </ChipBlockContextProvider>
        ),
        '/:conceptId': ({ conceptDefinitionId, conceptId }) => (
          <ChipBlockContextProvider context={conceptDefinitionId}>
            <ChipBlockContextProvider context={conceptId}>
              <ConceptPage conceptDefinitionId={conceptDefinitionId} conceptId={conceptId} />
            </ChipBlockContextProvider>
          </ChipBlockContextProvider>
        ),
      },
    },
  },
};

export default ConceptRouter;
