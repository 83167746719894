import tinyColor from 'tinycolor2';
import { newError } from 'yooi-utils';
import type { Opacity } from './base';
import base from './base';

const getOpacityNumber = (opacity: Opacity): number => {
  const opacityNumber = base.opacity[opacity];
  if (opacityNumber === undefined) {
    throw newError('Unsupported opacity');
  }
  return opacityNumber;
};

export const colorWithAlpha = (color: string, opacity: Opacity): string => tinyColor(color).setAlpha(getOpacityNumber(opacity)).toHex8String();

export const generateColorFromOpacity = (colorHex: string, backgroundColor: string | undefined, opacity: Opacity): string => {
  if (backgroundColor === undefined) {
    return colorHex;
  } else {
    return tinyColor.mix(backgroundColor, colorHex, getOpacityNumber(opacity) * 100).toHexString();
  }
};

export const hexColorWithAlpha = <Color extends string | undefined = string | undefined>(colorHex: Color, opacity: number): Color => {
  if (!colorHex) {
    return undefined as Color;
  } else if (!opacity) {
    return colorHex;
  }
  // 100% opacity is equal FF in hex or 255 in decimal
  const ratioDecimalToHexOpacity = Math.round(255 * opacity);
  const hexOpacity = Number(ratioDecimalToHexOpacity).toString(16).padStart(2, '0');
  return colorHex + hexOpacity as Color;
};

export const isLight = (color: string): boolean => tinyColor(color).getLuminance() > 0.65;

export const getMostReadableColorFromBackgroundColor = (backgroundColorHex: string): string => (
  isLight(backgroundColorHex) ? base.color.gray['900'] : base.color.gray['100']
);

export const lighten = (color: string, amount: number): string => tinyColor(color).lighten(amount).toHexString();

export const darken = (color: string, amount: number): string => tinyColor(color).darken(amount).toHexString();
