import type { ConceptDefinitionLibraryTableStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import {
  ConceptDefinitionLibraryTable,
  ConceptDefinitionLibraryTable_Role_ConceptDefinition,
  ConceptDefinitionLibraryTable_Role_Field,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { ConceptStoreObject, SingleParameterDefinition, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { createValuePathResolver, isMultiDimensionResolution, isSingleDimensionResolution } from 'yooi-modules/modules/conceptModule';
import { Concept_FunctionalId, Concept_Name } from 'yooi-modules/modules/conceptModule/ids';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { TableSortDirection } from '../../../../components/molecules/Table';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { formatErrorForUser } from '../../errorUtils';
import { DataResolutionError, getDimensionLabel } from '../data/dataResolution';
import type { ViewResolutionError } from '../viewResolutionUtils';
import { getConceptPathConfigurationHandler } from './viewUtils';

export interface ConceptViewResolution {
  conceptDefinitionId: string,
  instances: ConceptStoreObject[],
  defaultSort: { key: string, direction: TableSortDirection },
}

export const resolveConcept = (store: FrontObjectStore, dimensions: ViewDimension[], parametersMapping: ParametersMapping): ConceptViewResolution | ViewResolutionError => {
  if (dimensions.length === 1) {
    const dimensionPath = dimensions[0]?.path;
    const conceptPathParameterDefinitions: SingleParameterDefinition[] = [];
    Object.entries(parametersMapping).forEach(([key, { id }]) => {
      if (id) {
        const object = store.getObjectOrNull(id);
        if (object) {
          conceptPathParameterDefinitions.push({ id: key, typeId: object[Instance_Of] as string, label: i18n`Dimension`, type: 'dimension' });
        }
      }
    });
    const valuePathHandler = getConceptPathConfigurationHandler(store, conceptPathParameterDefinitions);
    const conceptDefinitionId = valuePathHandler.getReturnedConceptDefinitionId(dimensionPath);
    if (conceptDefinitionId) {
      const instances: ConceptStoreObject[] = [];
      const dimensionPathResolver = createValuePathResolver(store, parametersMapping);
      const pathResolution = dimensionPathResolver.resolvePathDimension(dimensionPath);
      if (!pathResolution) {
        return {
          type: 'error',
          error: formatErrorForUser(store, new DataResolutionError(getDimensionLabel(store, dimensions[0].label, 0, dimensionPath), new Error(i18n`Path do not return anything`))),
        };
      } else if (pathResolution instanceof Error) {
        return {
          type: 'error',
          error: formatErrorForUser(store, new DataResolutionError(getDimensionLabel(store, dimensions[0].label, 0, dimensionPath), pathResolution)),
        };
      } else if (isSingleDimensionResolution(pathResolution)) {
        if (pathResolution.instance) {
          instances.push(pathResolution.instance);
        }
      } else if (isMultiDimensionResolution(pathResolution)) {
        instances.push(...pathResolution.instances);
      }
      const hasFunctionalId = Boolean(
        store.withAssociation(ConceptDefinitionLibraryTable)
          .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
          .withRole(ConceptDefinitionLibraryTable_Role_Field, Concept_FunctionalId)
          .getObjectOrNull<ConceptDefinitionLibraryTableStoreObject>()
      );
      return {
        conceptDefinitionId,
        instances,
        defaultSort: hasFunctionalId ? { key: Concept_FunctionalId, direction: TableSortDirection.desc } : { key: Concept_Name, direction: TableSortDirection.asc },
      };
    } else {
      return { type: 'error', error: i18n`Invalid data configuration` };
    }
  } else {
    return { type: 'error', error: i18n`Invalid configuration. This view is not compatible with multiple dimension.` };
  }
};
