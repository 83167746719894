import type { FunctionComponent } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { DashboardStoreObject, WidgetStoreObject } from 'yooi-modules/modules/dashboardModule';
import { Dashboard_Widgets, Widget_DisplayMode, Widget_Height, Widget_Width, Widget_X, Widget_Y } from 'yooi-modules/modules/dashboardModule/ids';
import DashboardGrid from '../../../components/molecules/DashboardGrid';
import BlockContent from '../../../components/templates/BlockContent';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import DashboardWidget from './DashboardWidget';

interface DashboardLayoutTabProps {
  dashboardId: string,
  parametersMapping: ParametersMapping,
  readOnly: boolean,
  isViewMode: boolean,
}

const DashboardLayoutTab: FunctionComponent<DashboardLayoutTabProps> = ({ dashboardId, parametersMapping, readOnly, isViewMode }) => {
  const store = useStore();
  const dashboard = store.getObject<DashboardStoreObject>(dashboardId);

  return (
    <VerticalBlock>
      <BlockContent compactAction>
        <DashboardGrid
          layout={dashboard
            .navigateBack<WidgetStoreObject>(Dashboard_Widgets)
            .reverse() // Reverse is a hacky way to always have the last created element first
            .map((widget) => ({
              id: widget.id,
              x: widget[Widget_X] ?? 0,
              y: widget[Widget_Y] ?? 0,
              w: widget[Widget_Width] ?? 3,
              h: widget[Widget_Height] ?? 3,
              borderless: widget[Widget_DisplayMode]?.type === 'borderless' || widget[Widget_DisplayMode]?.type === 'color',
              padded: widget[Widget_DisplayMode]?.type !== 'borderless',
              backgroundColor: widget[Widget_DisplayMode]?.type === 'color' ? widget[Widget_DisplayMode].color : undefined,
              renderWidget: (dragHandlerClassName) => (
                <DashboardWidget
                  widgetId={widget.id}
                  onDelete={() => store.deleteObject(widget.id)}
                  dragHandlerClassName={dragHandlerClassName}
                  readOnly={readOnly}
                  parametersMapping={parametersMapping}
                />
              ),
            }))}
          onLayoutChanged={isViewMode ? undefined : (newLayout) => {
            newLayout.forEach(({ id, x, y, h, w }) => {
              const currentValue = store.getObjectOrNull(id);
              if (
                !currentValue
                || currentValue[Widget_Width] !== w
                || currentValue[Widget_Height] !== h
                || currentValue[Widget_X] !== x
                || currentValue[Widget_Y] !== y
              ) {
                store.updateObject(id, {
                  [Widget_Width]: w,
                  [Widget_Height]: h,
                  [Widget_X]: x,
                  [Widget_Y]: y,
                });
              }
            });
          }}
          isResizable={!isViewMode}
          isDraggable={!isViewMode}
          editionMode={!isViewMode}
        />
      </BlockContent>
    </VerticalBlock>
  );
};

export default DashboardLayoutTab;
