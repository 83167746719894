import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { FunctionComponent, ReactElement, RefCallback } from 'react';
import { useCallback, useRef } from 'react';
import { spacingRem } from '../../theme/spacingDefinition';
import makeSelectorsClasses from '../../utils/makeSelectorsClasses';
import makeStyles from '../../utils/makeStyles';
import { SCROLLBAR_WIDTH_IN_REM } from '../../utils/sizeUtils';
import useNavigation from '../../utils/useNavigation';
import { OverlayContextProvider } from '../../utils/useOverlayContainerRef';

export const baseLayoutSelectorsClasses = makeSelectorsClasses('onScrollShadow', 'onScrollBorder');

const useStyles = makeStyles((theme) => ({
  base: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
  },
  horizontalContainer: {
    flexDirection: 'row',
  },
  verticalContainer: {
    flexDirection: 'column',
  },
  main: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
  },
  scrollContainer: {
    overflowY: 'scroll',
    overflowX: 'auto',
    flexGrow: 1,
    minWidth: '40rem',
    paddingLeft: spacingRem.splus,
    marginRight: `${1 - (SCROLLBAR_WIDTH_IN_REM / 2)}rem`,
    paddingTop: spacingRem.l,
    paddingBottom: '28rem',
    paddingRight: `${1.4 - (SCROLLBAR_WIDTH_IN_REM / 2)}rem`,
    position: 'relative',
  },
  showShadow: {
    [`& .${baseLayoutSelectorsClasses.onScrollShadow}`]: {
      boxShadow: 'rgba(0, 0, 0, 0.02) -0.4rem 2rem 0 -1.6rem',
    },
    [`& .${baseLayoutSelectorsClasses.onScrollBorder}`]: {
      borderBottomColor: theme.color.border.default,
    },
  },
}), 'baseLayout');

interface BaseLayoutProps {
  topBar: ReactElement | null,
  header: ReactElement | null,
  content: ReactElement | null,
  leftPanel?: ReactElement | null,
  rightPanel?: ReactElement | null,
}

const BaseLayout: FunctionComponent<BaseLayoutProps> = ({ topBar, header, content, leftPanel, rightPanel }) => {
  const classes = useStyles();

  const baseRef = useRef<HTMLDivElement | null>(null);
  const mainRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { scrollableRef } = useNavigation();

  const lastScrollNodeRef = useRef<HTMLDivElement | null>(null);
  const onScroll = useCallback(() => {
    if (mainRef.current !== null && lastScrollNodeRef.current !== null) {
      if (lastScrollNodeRef.current.scrollTop === 0) {
        if (mainRef.current.className.includes(classes.showShadow)) {
          mainRef.current.className = mainRef.current.className.split(' ').filter((name) => name !== classes.showShadow).join(' ');
        }
      } else if (!mainRef.current.className.includes(classes.showShadow)) {
        mainRef.current.className += ` ${classes.showShadow}`;
      }
    }
  }, [classes.showShadow]);
  const onScrollDiv = useCallback<RefCallback<HTMLDivElement>>((node) => {
    if (lastScrollNodeRef.current !== null) {
      lastScrollNodeRef.current.removeEventListener('scroll', onScroll);
    }
    lastScrollNodeRef.current = node;
    if (node !== null) {
      node.addEventListener('scroll', onScroll);
    }
  }, [onScroll]);

  return (
    <div className={classnames(classes.base, classes.horizontalContainer)}>
      <div ref={baseRef} className={classnames(classes.base, classes.verticalContainer)}>
        <OverlayContextProvider containerRef={baseRef}>
          {topBar}
          <div className={classnames(classes.base, classes.horizontalContainer)}>
            {leftPanel ?? null}
            <main
              ref={mainRef}
              className={classnames({
                [classes.main]: true,
                [classes.showShadow]: contentRef.current !== null && contentRef.current.scrollTop > 0,
              })}
            >
              <OverlayContextProvider containerRef={mainRef}>
                {header}
                <div
                  ref={composeReactRefs<HTMLDivElement>(contentRef, scrollableRef, onScrollDiv)}
                  className={classes.scrollContainer}
                >
                  <OverlayContextProvider containerRef={contentRef}>
                    {content}
                  </OverlayContextProvider>
                </div>
              </OverlayContextProvider>
            </main>
            {rightPanel ?? null}
          </div>
        </OverlayContextProvider>
      </div>
    </div>
  );
};

export default BaseLayout;
