import type { FunctionComponent } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { RadarChartFieldConfiguration } from 'yooi-modules/modules/dashboardModule';
import { RadarChartField_Configuration } from 'yooi-modules/modules/dashboardModule/ids';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Typo from '../../../../components/atoms/Typo';
import RadarChart from '../../../../components/charts/RadarChart/RadarChart';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import BlockContent from '../../../../components/templates/BlockContent';
import useStore from '../../../../store/useStore';
import base from '../../../../theme/base';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { remToPx } from '../../../../utils/sizeUtils';
import BlockField from '../_global/BlockField';
import { filterPath } from '../_global/widgetSeriesUtils';
import type { BlockFieldProps } from '../FieldLibraryTypes';
import { ChartLabelPathAcceptedFields, computeRadarChartFieldSeries } from './radarChartFieldUtils';
import RadarChartTooltip from './RadarChartTooltip';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginBottom: spacingRem.blockSpacing,
    padding: spacingRem.xs,
    boxShadow: base.shadowElevation.low,
    borderRadius: base.borderRadius.medium,
    background: theme.color.background.neutral.default,
  },
  chartContainer: {
    width: '100%',
  },
  preventChartFlick: {
    position: 'absolute',
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
}), 'radarChartBlockField');

interface RadarChartBlockFieldProps {
  fieldId: string,
  chartHeight: number,
  parametersMapping: ParametersMapping,
  blockFieldProps: BlockFieldProps,
}

const RadarChartBlockField: FunctionComponent<RadarChartBlockFieldProps> = ({ fieldId, chartHeight, parametersMapping, blockFieldProps }) => {
  const store = useStore();
  const classes = useStyles();

  const field = store.getObject(fieldId);
  const configuration = field[RadarChartField_Configuration] as RadarChartFieldConfiguration | undefined;

  const { path: labelFilteredPath } = filterPath(store, configuration?.labelPath, ChartLabelPathAcceptedFields);

  const series = computeRadarChartFieldSeries(store, fieldId, parametersMapping);

  if (!series) {
    return (
      <BlockField
        fieldId={fieldId}
        {...blockFieldProps}
      >
        <BlockContent fullWidth>
          <SpacingLine>
            <Icon name={IconName.dangerous} colorVariant={IconColorVariant.error} />
            <Typo maxLine={1}>{i18n`Invalid configuration`}</Typo>
          </SpacingLine>
        </BlockContent>
      </BlockField>
    );
  }

  return (
    <BlockField
      fieldId={fieldId}
      {...blockFieldProps}
      isVertical
    >
      <BlockContent fullWidth>
        <div className={classes.container}>
          <AutoSizer disableHeight>
            {({ width = 0 }) => {
              const height = chartHeight ? remToPx(chartHeight) : 0;
              return (
                <div className={classes.chartContainer} style={{ height }}>
                  <div className={classes.preventChartFlick} style={{ height, width }}>
                    <RadarChart
                      height={height}
                      width={width}
                      instanceData={series.instanceData}
                      fieldData={series.fieldData}
                      withLegend={Boolean(labelFilteredPath.length)}
                      renderTooltip={(instanceId, seriesIds, color) => <RadarChartTooltip instanceId={instanceId} fieldId={field.id} seriesIds={seriesIds} color={color} />}
                    />
                  </div>
                </div>
              );
            }}
          </AutoSizer>
        </div>
      </BlockContent>
    </BlockField>
  );
};

export default RadarChartBlockField;
