import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import { BlockFieldLayoutOption } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { IconFieldRaw, IconFieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { iconFieldHandler } from 'yooi-modules/modules/conceptModule';
import {
  Field_ApiAlias,
  Field_Documentation,
  Field_IntegrationOnly,
  Field_IsCore,
  Field_IsDocumentationInline,
  Field_Title,
  IconField,
} from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareString, comparing, joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import ColoredIconPicker from '../../../../components/inputs/ColoredIconPicker';
import { getDefaultStandardColors } from '../../../../components/inputs/ColorPicker';
import SimpleInput from '../../../../components/inputs/strategy/SimpleInput';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import { TableSortDirection } from '../../../../components/molecules/Table';
import { Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import { getColorPalette } from '../../../utils/standardColorsUtils';
import { createPathConfigurationHandler } from '../../pathConfigurationHandler';
import { getFieldTypeValidator } from '../../pathConfigurationHandlerUtils';
import { getBlockFieldLayoutOption, getDefaultDisplayOptions, getLayoutDisplayOption } from '../_global/blockFieldUtils';
import { getApiAliasInitialState, getDimensionsEditionOption, getDocumentationFieldEditionSection, getIntegrationFieldEditionSection } from '../_global/editionHandlerUtils';
import { getGenericOperationMetadata } from '../_global/updateOperationHandlerUtils';
import UpdateOperationInput from '../_global/UpdateOperationInput';
import UpdateOperationSelector from '../_global/UpdateOperationSelector';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  createAndLinkFieldToConceptDefinitions,
  duplicateFieldDimensionWithNewField,
  FIELD_DIMENSIONS_READONLY,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
  submitDimensionUpdate,
} from '../fieldDimensionUtils';
import type { FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, FieldComparatorHandler, GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldIntegrationOnlyDisabled } from '../FieldLibraryTypes';
import IconFieldRenderer from './IconFieldRenderer';

interface IconFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [FIELD_DIMENSIONS_READONLY]: boolean | undefined,
  [Field_Title]: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
  [Field_IntegrationOnly]: boolean | null | undefined,
  [FieldIntegrationOnlyDisabled]: boolean | undefined,
}

interface IconDisplayOptions {
}

type IconFieldDefinition = GetFieldDefinitionHandler<typeof iconFieldHandler, IconFieldConfigurationState, IconDisplayOptions, FieldBlockDisplayOptions>;

export const iconFieldDefinition: IconFieldDefinition = registerFieldDefinition(iconFieldHandler, {
  configuration: {
    typeIcon: IconName.token,
    getTypeLabel: () => i18n`Icon`,
    asWidget: false,
    getEditionOptions: (objectStore) => ({ mode, editionHandler, readOnly }) => {
      if (mode !== FieldEditionOptionMode.Field && mode !== FieldEditionOptionMode.FieldDeveloperMode) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      sections.push({
        key: 'data',
        type: 'section',
        title: i18n`Data`,
        options: [
          getDimensionsEditionOption(editionHandler, false, readOnly || Boolean(editionHandler.getValue(FIELD_DIMENSIONS_READONLY))),
        ],
      });

      sections.push(getDocumentationFieldEditionSection(editionHandler));
      sections.push(getIntegrationFieldEditionSection(objectStore, editionHandler, mode));

      return sections;
    },
    isCreationEnabled: () => () => true,
    onCreate: (objectStore) => (editionHandler) => {
      const fieldId = objectStore.createObject<IconFieldRaw>({
        [Instance_Of]: IconField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [Field_ApiAlias]: editionHandler.getValue(Field_ApiAlias),
        [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
      });
      linkFieldToFieldDimensions(objectStore, fieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});
      return fieldId;
    },
    inlineCreate: (objectStore) => (conceptDefinitionId, extraFieldOptions) => ({
      type: 'inline',
      onCreate: (title) => {
        const newFieldId = objectStore.createObject<IconFieldRaw>(joinObjects(
          extraFieldOptions,
          { [Instance_Of]: IconField, [Field_Title]: title }
        ));
        createAndLinkFieldToConceptDefinitions(objectStore, newFieldId, [conceptDefinitionId]);
        return newFieldId;
      },
    }),
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<IconFieldStoreObject>(fieldId);
        return joinObjects(
          getApiAliasInitialState(objectStore, fieldId),
          {
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            [Field_IntegrationOnly]: field[Field_IntegrationOnly],
            [FieldIntegrationOnlyDisabled]: field[Field_IsCore],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
            [FIELD_DIMENSIONS_READONLY]: field[Field_IsCore],
          }
        );
      },
      submitFieldUpdate: (stateToSubmit, conceptDefinitionId) => {
        objectStore.updateObject<IconFieldStoreObject>(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: stateToSubmit[Field_IntegrationOnly],
        });
        submitDimensionUpdate(objectStore, fieldId, conceptDefinitionId, stateToSubmit[FIELD_EDITION_DIMENSIONS] ?? {});
      },
      duplicateFieldDefinition: () => {
        const field = objectStore.getObject<IconFieldStoreObject>(fieldId);
        const fieldDimensionMapping = generateDuplicatedFieldDimensionId(objectStore, fieldId);
        const newFieldId = objectStore.createObject<IconFieldRaw>({
          [Instance_Of]: field[Instance_Of],
          [Field_Title]: `${field[Field_Title]} (copy)`,
          [Field_Documentation]: field[Field_Documentation],
          [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: field[Field_IntegrationOnly],
        });
        duplicateFieldDimensionWithNewField(objectStore, newFieldId, fieldDimensionMapping);
        return newFieldId;
      },
    }),
  },
  renderField: (_, fieldId) => ({ dimensionsMapping, readOnly, focusOnMount }) => (
    <IconFieldRenderer
      fieldId={fieldId}
      dimensionsMapping={dimensionsMapping}
      readOnly={readOnly}
      focusOnMount={focusOnMount ?? false}
    />
  ),
  input: (objectStore, __, handler) => ({
    render: ({ value, onSubmit, readOnly, onEditionStart, onEditionStop, isEditing, focusOnMount }) => (
      <SimpleInput initialValue={value as { icon: IconName, color: string } | undefined} onSubmit={onSubmit}>
        {(props) => (
          <ColoredIconPicker
            value={props.value}
            onChange={props.onChange}
            onSubmit={props.onSubmit}
            onCancel={props.onCancel}
            onEditionStart={onEditionStart}
            onEditionStop={onEditionStop}
            readOnly={readOnly}
            withMultiplayerOutline={isEditing}
            focusOnMount={focusOnMount}
            colorPalette={getColorPalette(objectStore) ?? getDefaultStandardColors()}
            placeholder={i18n`Add icon`}
            clearMode={{ type: 'clear' }}
          />
        )}
      </SimpleInput>
    ),
    getInitialState: () => undefined,
    persistStateOnConcept: (dimension, state) => handler.updateValue(dimension, state ?? null),
  }),
  getUpdateOperationInput: (store, fieldId, { updateOperationHandlers: { INITIALIZE, REPLACE, CLEAR } }) => (operation) => {
    const { title, getIcon } = getGenericOperationMetadata(store, operation, fieldId);

    return {
      title,
      getIcon,
      render: ({ onSubmit, parameterDefinitions }) => {
        const { input } = iconFieldDefinition(store).getHandler(fieldId);

        return (
          <>
            <UpdateOperationSelector<typeof iconFieldHandler>
              selectedOperationAction={operation?.action}
              operationOptions={[
                { id: 'INITIALIZE', label: i18n`Initialize`, onSelect: () => onSubmit({ action: 'INITIALIZE', payload: INITIALIZE.sanitizeOperation(operation) }) },
                { id: 'REPLACE', label: i18n`Replace`, onSelect: () => onSubmit({ action: 'REPLACE', payload: REPLACE.sanitizeOperation(operation) }) },
                { id: 'CLEAR', label: i18n`Clear`, onSelect: () => onSubmit({ action: 'CLEAR', payload: CLEAR.sanitizeOperation(operation) }) },
              ]}
            />
            {
              input && operation && operation.action !== 'CLEAR' && operation.payload
                ? (
                  <UpdateOperationInput<typeof iconFieldHandler>
                    valueInput={input}
                    initialValue={operation.payload}
                    onTypeChange={(newType) => {
                      onSubmit({ action: operation.action, payload: newType === 'value' ? { type: 'value', value: undefined } : { type: 'path', path: [] } });
                    }}
                    onValueSubmit={(newValue) => onSubmit({ action: operation.action, payload: newValue })}
                    parameterDefinitions={parameterDefinitions}
                    valuePathHandler={createPathConfigurationHandler(store, parameterDefinitions, [getFieldTypeValidator(store, [IconField], i18n`Input should end with an icon.`)])}
                  />
                )
                : null
            }
          </>
        );
      },
    };
  },
  renderExportConfiguration: () => () => (
    <SpacedContainer margin={{ x: Spacing.splus }}>
      <Tooltip title={i18n`Text - Icon ID + HEX color`}>
        <Typo maxLine={1}>{i18n`Text - Icon ID + HEX color`}</Typo>
      </Tooltip>
    </SpacedContainer>
  ),
  getActivityProperties: (_, fieldId) => () => [fieldId],
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({ propertyId: fieldId, sortable: true, focusable: true }),
  estimatedColumnWidth: () => () => '3.8rem',
  getComparatorHandler: (_, __, { getValueResolution }) => (direction) => ({
    comparator: comparing(compareString, direction === TableSortDirection.desc),
    extractValue: (dimensionsMapping) => getValueResolution(dimensionsMapping).value?.icon,
  }) satisfies FieldComparatorHandler<string | undefined>,
  blockDisplayOptionsHandler: (objectStore) => (fieldBlockDisplayId) => ({
    getDisplayOptions: () => getDefaultDisplayOptions(objectStore, fieldBlockDisplayId),
    renderSummary: ({ layoutDisplayType }) => ([getBlockFieldLayoutOption()[layoutDisplayType ?? BlockFieldLayoutOption.auto].label]),
    getBlockEditionOptionSections: (state, setState) => [getLayoutDisplayOption(state, setState)],
    onSubmit: (state) => {
      objectStore.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
});
