import { FieldBlockDisplay_FieldDisplayConfiguration, TextConstantField } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import { BlockFieldLayoutOption } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import type { FilterValueRaw, Formula, SingleParameterDefinition, TextFieldRaw, TextFieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { FilterValueType, textFieldHandler, updateTextWithLinks } from 'yooi-modules/modules/conceptModule';
import {
  Concept_Name,
  Field_ApiAlias,
  Field_Documentation,
  Field_Formula,
  Field_IntegrationOnly,
  Field_IsCore,
  Field_IsDocumentationInline,
  Field_Title,
  TextField,
} from 'yooi-modules/modules/conceptModule/ids';
import { richTextType } from 'yooi-modules/modules/conceptModule/utils/formula/richTextFunctions';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { RichText } from 'yooi-utils';
import { compareString, comparing, joinObjects, textType } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import RichTextInput from '../../../../components/inputs/RichTextInput';
import SimpleInput from '../../../../components/inputs/strategy/SimpleInput';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import { TableSortDirection } from '../../../../components/molecules/Table';
import { FontVariant } from '../../../../theme/fontDefinition';
import { Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import { getFormulaFieldEditionSection } from '../../formulaRendererUtils';
import StoreTextInputField from '../../input/StoreTextInputField';
import type { OptionRecord } from '../../modelTypeUtils';
import { defaultOptionComparator } from '../../modelTypeUtils';
import { createPathConfigurationHandler } from '../../pathConfigurationHandler';
import { getFieldTypeValidator } from '../../pathConfigurationHandlerUtils';
import { getBlockFieldLayoutOption, getDefaultDisplayOptions, getLayoutDisplayOption } from '../_global/blockFieldUtils';
import { getApiAliasInitialState, getDimensionsEditionOption, getDocumentationFieldEditionSection, getIntegrationFieldEditionSection } from '../_global/editionHandlerUtils';
import { getGenericOperationMetadata } from '../_global/updateOperationHandlerUtils';
import UpdateOperationInput from '../_global/UpdateOperationInput';
import UpdateOperationSelector from '../_global/UpdateOperationSelector';
import { duplicateFormula } from '../duplicationUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  createAndLinkFieldToConceptDefinitions,
  duplicateFieldDimensionWithNewField,
  FIELD_DIMENSIONS_READONLY,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
  submitDimensionUpdate,
} from '../fieldDimensionUtils';
import type { FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, FieldComparatorHandler, GetFieldDefinitionHandler, RenderFilter } from '../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldIntegrationOnlyDisabled } from '../FieldLibraryTypes';
import TextFieldRenderer from './TextFieldRenderer';

interface TextFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
  [Field_IntegrationOnly]: boolean | null | undefined,
  [FieldIntegrationOnlyDisabled]: boolean | undefined,
  [Field_Formula]?: Formula | null | undefined,
  [FIELD_DIMENSIONS_READONLY]: boolean | undefined,
}

interface TextFieldBlockDisplayOptions {
  displayType?: 'title' | undefined,
}

type TextFieldDefinition = GetFieldDefinitionHandler<typeof textFieldHandler, TextFieldConfigurationState, TextFieldBlockDisplayOptions, FieldBlockDisplayOptions>;

export const textFieldDefinition: TextFieldDefinition = registerFieldDefinition(textFieldHandler, {
  configuration: {
    typeIcon: IconName.subject,
    getTypeLabel: () => i18n`Text`,
    asWidget: false,
    getEditionOptions: (store) => ({ mode, editionHandler, readOnly }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      sections.push({
        key: 'data',
        type: 'section',
        title: i18n`Data`,
        options: [
          getDimensionsEditionOption(editionHandler, Boolean(editionHandler.getValue(Field_Formula)), readOnly || Boolean(editionHandler.getValue(FIELD_DIMENSIONS_READONLY))),
        ],
      });

      sections.push(getDocumentationFieldEditionSection(editionHandler));
      sections.push(getIntegrationFieldEditionSection(store, editionHandler, mode));

      const fieldEditionDimensions: FieldEditionDimensions = editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {};
      const parameterDefinitions: SingleParameterDefinition[] = Object.entries(fieldEditionDimensions)
        .map(([id, { typeId }]) => ({ id, typeId, label: i18n`Dimension`, type: 'dimension' }));

      sections.push(getFormulaFieldEditionSection({
        store,
        parameterDefinitions,
        value: editionHandler.getValueOrDefault(Field_Formula),
        onChange: (value) => editionHandler.updateValues({ [Field_Formula]: value }),
        hasValue: () => editionHandler.getValue(Field_Formula) !== undefined,
        clearValue: () => editionHandler.updateValues({ [Field_Formula]: null }),
        returnType: richTextType,
        extraAcceptedTypes: [textType],
      }));

      return sections;
    },
    isCreationEnabled: () => () => true,
    onCreate: (objectStore) => (editionHandler) => {
      const fieldId = objectStore.createObject<TextFieldRaw>({
        [Instance_Of]: TextField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [Field_ApiAlias]: editionHandler.getValue(Field_ApiAlias),
        [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
        [Field_Formula]: editionHandler.getValue(Field_Formula),
      });
      linkFieldToFieldDimensions(objectStore, fieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});
      return fieldId;
    },
    inlineCreate: (objectStore) => (conceptDefinitionId, extraFieldOptions) => ({
      type: 'inline',
      onCreate: (title) => {
        const newFieldId = objectStore.createObject<TextFieldRaw>(joinObjects(
          extraFieldOptions,
          {
            [Instance_Of]: TextField,
            [Field_Title]: title,
          }
        ));
        createAndLinkFieldToConceptDefinitions(objectStore, newFieldId, [conceptDefinitionId]);
        return newFieldId;
      },
    }),
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<TextFieldStoreObject>(fieldId);
        return joinObjects(
          getApiAliasInitialState(objectStore, fieldId),
          {
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            [Field_IntegrationOnly]: field[Field_IntegrationOnly],
            [FieldIntegrationOnlyDisabled]: field[Field_IsCore],
            [Field_Formula]: field[Field_Formula],
            [FIELD_DIMENSIONS_READONLY]: field[Field_IsCore],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
          }
        );
      },
      submitFieldUpdate: (stateToSubmit, conceptDefinitionId) => {
        objectStore.updateObject<TextFieldRaw>(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: stateToSubmit[Field_IntegrationOnly],
          [Field_Formula]: stateToSubmit[Field_Formula],
        });
        submitDimensionUpdate(objectStore, fieldId, conceptDefinitionId, stateToSubmit[FIELD_EDITION_DIMENSIONS] ?? {});
      },
      duplicateFieldDefinition: () => {
        const field = objectStore.getObject<TextFieldStoreObject>(fieldId);
        const fieldDimensionMapping = generateDuplicatedFieldDimensionId(objectStore, fieldId);
        const newFieldId = objectStore.createObject<TextFieldRaw>({
          [Instance_Of]: field[Instance_Of],
          [Field_Title]: `${field[Field_Title]} (copy)`,
          [Field_Documentation]: field[Field_Documentation],
          [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: field[Field_IntegrationOnly],
          [Field_Formula]: duplicateFormula(field[Field_Formula], fieldDimensionMapping),
        });
        duplicateFieldDimensionWithNewField(objectStore, newFieldId, fieldDimensionMapping);
        return newFieldId;
      },
    }),
  },
  renderField: (_, fieldId) => ({ dimensionsMapping, readOnly, focusOnMount, fieldDisplayOptions }) => (
    <TextFieldRenderer
      fieldId={fieldId}
      dimensionsMapping={dimensionsMapping}
      readOnly={readOnly}
      focusOnMount={focusOnMount ?? false}
      variant={fieldDisplayOptions?.displayType === 'title' ? FontVariant.tabTitle : undefined}
    />
  ),
  input: (_, __, handler) => ({
    render: ({ value, onSubmit, focusOnMount, readOnly, onEditionStart, onEditionStop, isEditing, fieldDisplayOptions }) => (
      <SimpleInput initialValue={value} onSubmit={onSubmit}>
        {(props) => (
          <RichTextInput
            value={props.value}
            onChange={(newValue) => props.onChange(newValue)}
            onSubmit={(newValue) => props.onSubmit(newValue)}
            onCancel={props.onCancel}
            readOnly={readOnly}
            onEditionStart={onEditionStart}
            onEditionStop={onEditionStop}
            placeholder={i18n`Add text`}
            focusOnMount={focusOnMount}
            isEditing={isEditing}
            variant={fieldDisplayOptions?.displayType === 'title' ? FontVariant.tabTitle : undefined}
          />
        )}
      </SimpleInput>
    ),
    getInitialState: () => undefined,
    persistStateOnConcept: (dimension, value) => handler.updateValue(dimension, value ?? null),
  }),
  getUpdateOperationInput: (store, fieldId, { updateOperationHandlers: { INITIALIZE, REPLACE, CLEAR } }) => (operation) => {
    const { title, getIcon } = getGenericOperationMetadata(store, operation, fieldId);
    return {
      title,
      getIcon,
      render: ({ onSubmit, parameterDefinitions }) => {
        const operationSelectorLine = (
          <UpdateOperationSelector<typeof textFieldHandler>
            selectedOperationAction={operation?.action}
            operationOptions={[
              { id: 'INITIALIZE', label: i18n`Initialize`, onSelect: () => onSubmit({ action: 'INITIALIZE', payload: INITIALIZE.sanitizeOperation(operation) }) },
              { id: 'REPLACE', label: i18n`Replace`, onSelect: () => onSubmit({ action: 'REPLACE', payload: REPLACE.sanitizeOperation(operation) }) },
              { id: 'CLEAR', label: i18n`Clear`, onSelect: () => onSubmit({ action: 'CLEAR', payload: CLEAR.sanitizeOperation(operation) }) },
            ]}
          />
        );

        let operationInputsLines = null;
        const { input } = textFieldDefinition(store).getHandler(fieldId);
        if (input && operation && operation.action !== 'CLEAR' && operation.payload) {
          operationInputsLines = (
            <UpdateOperationInput<typeof textFieldHandler>
              valueInput={input}
              initialValue={operation.payload}
              onTypeChange={(newType) => {
                onSubmit({ action: operation.action, payload: newType === 'value' ? { type: 'value', value: undefined } : { type: 'path', path: [] } });
              }}
              onValueSubmit={(newValue) => {
                if (newValue.type === 'path') {
                  onSubmit({ action: operation.action, payload: newValue });
                } else {
                  onSubmit({
                    action: operation.action,
                    payload: { type: 'value', value: newValue.value != null ? updateTextWithLinks(newValue.value) : undefined },
                  });
                }
              }}
              parameterDefinitions={parameterDefinitions}
              valuePathHandler={createPathConfigurationHandler(store, parameterDefinitions, [getFieldTypeValidator(store, [TextField, TextConstantField], i18n`Input should end with a text.`)])}
            />
          );
        }

        return (
          <>
            {operationSelectorLine}
            {operationInputsLines}
          </>
        );
      },
    };
  },
  renderSuggestedField: () => (value, setValue, focusOnMount) => (
    <RichTextInput
      value={value as RichText | undefined}
      onChange={(v) => setValue(v ?? undefined)}
      placeholder={i18n`Add text`}
      focusOnMount={focusOnMount}
    />
  ),
  getActivityProperties: (_, fieldId) => () => [fieldId],
  renderExportConfiguration: () => () => (
    <SpacedContainer margin={{ x: Spacing.splus }}>
      <Tooltip title={i18n`Text`}>
        <Typo maxLine={1}>{i18n`Text`}</Typo>
      </Tooltip>
    </SpacedContainer>
  ),
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({
    propertyId: fieldId,
    sortable: true,
    scrollOnMount: fieldId === Concept_Name ? true : undefined,
    openButton: fieldId === Concept_Name ? () => true : undefined,
    focusable: true,
  }),
  getComparatorHandler: (_, __, { getValueResolution }) => (direction) => ({
    comparator: comparing(compareString, direction === TableSortDirection.desc),
    extractValue: (dimensionsMapping) => {
      const { value } = getValueResolution(dimensionsMapping);
      return value?.toString() ?? '';
    },
  } satisfies FieldComparatorHandler<string | undefined>),
  filterConditions: () => {
    const renderTextFilter: RenderFilter<FilterValueRaw<string>> = (value, setValue, _, readOnly) => (
      <StoreTextInputField
        initialValue={value.raw}
        onSubmit={(v) => setValue({ type: FilterValueType.raw, raw: v ?? '' })}
        readOnly={readOnly}
        maxLine={1}
        withDropdown
        dropdownMaxLine={5}
        fullWidth
        placeholder={i18n`Value`}
      />
    );

    return {
      CONTAINS: {
        name: i18n`Contains`,
        renderFilter: renderTextFilter,
      },
      DOES_NOT_CONTAIN: {
        name: i18n`Does not contain`,
        renderFilter: renderTextFilter,
      },
      EQUALS: {
        name: i18n`Is`,
        renderFilter: renderTextFilter,
      },
      NOT_EQUALS: {
        name: i18n`Is not`,
        renderFilter: renderTextFilter,
      },
      STARTS_WITH: {
        name: i18n`Starts with`,
        renderFilter: renderTextFilter,
      },
      ENDS_WITH: {
        name: i18n`Ends with`,
        renderFilter: renderTextFilter,
      },
      IS_EMPTY: {
        name: i18n`Is empty`,
      },
      IS_NOT_EMPTY: {
        name: i18n`Is not empty`,
      },
    };
  },
  blockDisplayOptionsHandler: (objectStore) => (fieldBlockDisplayId) => ({
    getDisplayOptions: () => getDefaultDisplayOptions(objectStore, fieldBlockDisplayId),
    renderSummary: ({ layoutDisplayType }) => ([getBlockFieldLayoutOption()[layoutDisplayType ?? BlockFieldLayoutOption.auto].label]),
    getBlockEditionOptionSections: (state, setState) => [getLayoutDisplayOption(state, setState)],
    onSubmit: (state) => {
      objectStore.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
  fieldDisplayOptionsHandler: () => () => ({
    renderSummary: ({ displayType }) => (displayType === 'title' ? [i18n`As title`] : [i18n`As content`]),
    getEditionOptionSections: (state, setState) => {
      const options: OptionRecord<'title' | 'content'> = {
        title: { id: 'title', label: i18n`Title` },
        content: { id: 'content', label: i18n`Content` },
      };

      return [
        {
          key: 'displayType',
          title: i18n`Display as`,
          action: {
            type: EditionOptionTypes.select,
            props: {
              computeOptions: () => Object.values(options).sort(defaultOptionComparator),
              selectedOption: options[state.displayType ?? 'content'],
              onChange: (selectedOption) => {
                setState(joinObjects(state, {
                  displayType: selectedOption?.id === 'title' ? 'title' as const : undefined,
                }));
              },
            },
          },
          options: [],
        },
      ];
    },
    getErrors: () => [],
  }),
});
