import type { DateRange, StoredDateObject } from 'yooi-utils';
import { DateStorageTypeKeys, isDateValid, isFiniteNumber, PeriodicityType } from 'yooi-utils';

const isStoreDateObject = (value: unknown): value is StoredDateObject => (
  typeof value === 'object'
  && value !== null
  && Object.keys(value).every((key) => ['type', 'value'].includes(key))
  && (
    (value as { type: unknown }).type === undefined
    || (Object.values(DateStorageTypeKeys) as unknown[]).includes((value as { type: unknown }).type)
  )
  && (
    (value as { value: unknown }).value === undefined
    || typeof (value as { value: unknown }).value === 'number'
  )
);

const validateDateFormat = (date: StoredDateObject): boolean => {
  switch (date.type) {
    case DateStorageTypeKeys.duration:
    case DateStorageTypeKeys.last:
    case DateStorageTypeKeys.next:
    case DateStorageTypeKeys.constraint:
      return date.value == null || isFiniteNumber(date.value);
    default:
      if (date.type && !Object.values(DateStorageTypeKeys).includes(date.type)) {
        return false;
      }
      if (!date.value) {
        return true;
      }
      return isDateValid(new Date(date.value));
  }
};

export const validateTimelineFieldValue = (value: unknown): value is DateRange => (
  value === undefined
    || (
      value !== null
      && typeof value === 'object'
      && Object.keys(value).every((key) => ['from', 'to', 'period'].includes(key))
      && (
        (value as { period: unknown }).period === undefined
        || (Object.values(PeriodicityType) as unknown[]).includes((value as { period: unknown }).period)
      )
      && (
        (value as { from: unknown }).from === undefined
        || (isStoreDateObject((value as { from: unknown }).from) && validateDateFormat((value as { from: StoredDateObject }).from))
      )
      && (
        (value as { to: unknown }).to === undefined
        || (isStoreDateObject((value as { to: unknown }).to) && validateDateFormat((value as { to: StoredDateObject }).to))
      )
    )
);
