import { newFunctionLibrary } from '../engine/functionLibraryBuilder';
import { booleanType, getMostCommonType } from '../typeSystem';
import { FunctionInvalidArgumentTypeError } from './errors';
import { checkArgumentCount, checkArgumentType, checks } from './utils';

export const logicalFunctions = newFunctionLibrary()
  .addFunction(
    'FALSE',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checkArgumentCount(name, argTypes, 0, 0) ?? {
          type: booleanType,
          transpile: () => 'false',
          jsFunction: () => false,
        }),
    })
  )
  .addFunction(
    'TRUE',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checkArgumentCount(name, argTypes, 0, 0) ?? {
          type: booleanType,
          transpile: () => 'true',
          jsFunction: () => true,
        }),
    })
  )
  .addFunction(
    'NOT',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, booleanType, argTypes, 0)
        ) ?? {
          type: booleanType,
          transpile: ([arg]) => `!(${arg})`,
          jsFunction: (b: boolean | undefined) => !b,
        }
      ),
    })
  )
  .addFunction(
    'AND',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1),
          () => checkArgumentType(name, booleanType, argTypes)
        ) ?? {
          type: booleanType,
          transpile: (args) => args.map((arg) => `((${arg})??true)`).join('&&'),
          jsFunction: (...args: (boolean | undefined)[]) => args.every((b) => b ?? true),
        }
      ),
    })
  )
  .addFunction(
    'OR',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1),
          () => checkArgumentType(name, booleanType, argTypes)
        ) ?? {
          type: booleanType,
          transpile: (args) => args.map((arg) => `((${arg})??false)`).join('||'),
          jsFunction: (...args: (boolean | undefined)[]) => args.some((b) => b ?? false),
        }
      ),
    })
  )
  .addFunction(
    'XOR',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1),
          () => checkArgumentType(name, booleanType, argTypes)
        ) ?? {
          type: booleanType,
          transpile: (args) => args.map((arg) => `((${arg}??false))`).join('!=='),
          jsFunction: (...args: (boolean | undefined)[]) => args.reduce((a, b) => a !== (b ?? false), false),
        }
      ),
    })
  )
  .addFunction(
    'IF',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => {
        const checkError = checks(
          () => checkArgumentCount(name, argTypes, 2, 3),
          () => checkArgumentType(name, booleanType, argTypes, 0)
        );

        if (checkError) {
          return checkError;
        }

        const { mostCommonType, errorIndex } = getMostCommonType(argTypes.slice(1));
        if (errorIndex !== undefined) {
          return new FunctionInvalidArgumentTypeError(name, mostCommonType, argTypes[errorIndex + 1], errorIndex + 1);
        }

        return {
          type: mostCommonType,
          transpile: ([condition, ifValue, elseValue]) => `(${condition})?(${ifValue}):${elseValue ? `(${elseValue})` : 'undefined'}`,
          jsFunctionArgsMode: 'lazy',
          jsFunction: (condition: () => boolean | undefined, ifValue: () => unknown, elseValue: () => unknown) => (condition() ? ifValue() : elseValue?.()),
        };
      },
    })
  )
  .addFunction(
    'IFS',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => {
        const argsCountError = checkArgumentCount(name, argTypes, 2);
        if (argsCountError !== undefined) {
          return argsCountError;
        }

        let j = 0;
        while ((j + 1) < argTypes.length) {
          const error = checkArgumentType(name, booleanType, argTypes, j);
          if (error !== undefined) {
            return error;
          }
          j += 2;
        }

        const hasFallbackAsLastArg = argTypes.length % 2 === 1;

        const returnArgTypes = argTypes.filter((_, i) => ((i % 2 === 1) || (hasFallbackAsLastArg && i === argTypes.length - 1)));
        const { mostCommonType, errorIndex } = getMostCommonType(returnArgTypes);
        if (errorIndex !== undefined) {
          const badArgIndex = hasFallbackAsLastArg && errorIndex === returnArgTypes.length - 1 ? argTypes.length - 1 : 2 * errorIndex + 1;
          return new FunctionInvalidArgumentTypeError(name, mostCommonType, argTypes[badArgIndex], badArgIndex);
        }

        return {
          type: mostCommonType,
          transpile: (args) => [...args, ...((args.length % 2) === 0 ? ['undefined'] : [])]
            .map((arg, i) => [arg, args[i + 1]])
            .filter((_, i) => i % 2 === 0)
            .map(([arg1, arg2]) => (arg2 !== undefined ? `(${arg1})?(${arg2})` : `(${arg1})`))
            .join(':'),
          jsFunctionArgsMode: 'lazy',
          jsFunction: (...args: (() => unknown)[]) => {
            let i = 0;
            while ((i + 1) < args.length) {
              if (args[i]()) {
                return args[i + 1]();
              }
              i += 2;
            }
            if (i < args.length) {
              return args[i]();
            } else {
              return undefined;
            }
          },
        };
      },
    })
  )
  .addFunction(
    'COALESCE',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => {
        const argsCountError = checkArgumentCount(name, argTypes, 1);
        if (argsCountError !== undefined) {
          return argsCountError;
        }

        const { mostCommonType, errorIndex } = getMostCommonType(argTypes);
        if (errorIndex !== undefined) {
          return new FunctionInvalidArgumentTypeError(name, mostCommonType, argTypes[errorIndex], errorIndex);
        }

        return {
          type: mostCommonType,
          transpile: (args) => args.map((arg) => `(${arg})`).join('??'),
          jsFunctionArgsMode: 'lazy',
          jsFunction: (...args: (() => unknown)[]) => {
            for (let i = 0; i < args.length; i += 1) {
              const argValue = args[i]();
              if (argValue !== undefined) {
                return argValue;
              }
            }
            return undefined;
          },
        };
      },
    })
  )
  .build();
