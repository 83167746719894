import { createModule } from '../common/types/TypeModuleDsl';
import * as TemplateModuleIds from './ids';
import { TemplateModuleId } from './ids';
import migrations from './migrations';

const { initTypedModule, ...registerModelDsl } = createModule({ label: 'Template' }, TemplateModuleIds);
export const registerModel = registerModelDsl;

export enum TemplateFieldMode {
  READ = 'READ',
  WRITE = 'WRITE',
  SYNC = 'SYNC',
}

export const initModule = initTypedModule(() => ({
  id: TemplateModuleId,
  migrations,
}));
