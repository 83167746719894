import type { ObjectStoreReadOnly, StoreObject } from 'yooi-store';
import { OriginSources, ValidationStatus } from 'yooi-store';
import type { AsPropertyBusinessRuleRegistration } from '../../common/fields/FieldModuleDslType';
import { Integration } from '../../integrationModule/ids';
import { isInstanceOf } from '../../typeModule';
import { Instance_Of } from '../../typeModule/ids';
import type { AssociationFieldStepConfiguration } from '../fields/associationField';
import type { EmbeddingFieldStepConfiguration } from '../fields/embeddingField';
import type { KinshipRelationFieldStepConfiguration } from '../fields/kinshipRelationField';
import type { RelationMultipleFieldStepConfiguration } from '../fields/relationMultipleField';
import type { RelationSingleFieldStepConfiguration } from '../fields/relationSingleField/relationSingleField';
import type { StakeholdersPathStepConfiguration } from '../fields/stakeholdersField';
import type { WorkflowFieldStepConfiguration } from '../fields/workflowField/workflowField';
import {
  Concept,
  Field_FieldDimensions,
  Field_Formula,
  Field_IntegrationOnly,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
} from '../ids';
import type { FieldDimensionTypesStoreObject } from '../model';

export type FieldStepConfiguration =
  RelationSingleFieldStepConfiguration
  | RelationMultipleFieldStepConfiguration
  | WorkflowFieldStepConfiguration
  | EmbeddingFieldStepConfiguration
  | KinshipRelationFieldStepConfiguration
  | StakeholdersPathStepConfiguration
  | AssociationFieldStepConfiguration;

export const formatOrUndef = (text: string | undefined): string => text || 'Undefined';

export const isSaneAssociationValue = (
  objectStore: ObjectStoreReadOnly,
  conceptInstanceId: string | undefined,
  targetTypeId: string | undefined,
  values: StoreObject[] | undefined
): boolean => {
  if (!targetTypeId) {
    return false;
  }
  const conceptInstance = conceptInstanceId && objectStore.getObjectOrNull(conceptInstanceId);
  if (!conceptInstance) {
    return false;
  }
  const isCompatibleWithTargetType = (instance: StoreObject) => targetTypeId === Concept || isInstanceOf(instance, targetTypeId);
  return values?.every((instance) => isCompatibleWithTargetType(instance)) ?? true;
};

export const validateIntegrationOnlyPropertyUpdate = (rulePrefix: string): AsPropertyBusinessRuleRegistration => ({ getObject }, fieldId) => (origin, { properties }) => {
  if (origin.source && [OriginSources.SYSTEM, OriginSources.MIGRATION].includes(origin.source)) {
    return undefined;
  }

  // Deleting instance, ignore
  if (properties === null) {
    return undefined;
  }

  const field = getObject(fieldId);
  if (field[Field_IntegrationOnly]) {
    if (!origin.userId || !isInstanceOf(getObject(origin.userId), Integration)) {
      return {
        rule: `field.${rulePrefix}.integrationOnly.rejectUpdate`,
        status: ValidationStatus.REJECTED,
      };
    }
  }
  return undefined;
};

export const sanitizeFilterValue = (value: string | undefined): string => (
  // normalize split letter with accent in two different character (é = e + ') then every accent is removed to compare without accent
  value ? value.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : ''
);

export const validateFieldIdAsProperty = (
  fieldLabel: string
): AsPropertyBusinessRuleRegistration => ({ getObject, getObjectOrNull, withAssociation }, fieldId) => (_, { id, properties, timeseries }) => {
  if ((!properties || properties[fieldId] === null) && (!timeseries || timeseries[fieldId] === null)) {
    return undefined;
  }

  const dimensions = getObject(fieldId).navigateBack(Field_FieldDimensions);

  if (id.length === 1) {
    const object = getObjectOrNull(id);
    const objectTypeId = (object?.[Instance_Of] ?? properties?.[Instance_Of]) as string;
    if (!objectTypeId) {
      return { rule: `field.${fieldLabel}.isNotAssociatedToConcept.missingObjectTypeId`, status: ValidationStatus.REJECTED };
    }
    for (let i = 0; i < dimensions.length; i += 1) {
      const dimension = dimensions[i];
      if (withAssociation(FieldDimensionTypes)
        .withRole(FieldDimensionTypes_Role_FieldDimension, dimension.id)
        .withRole(FieldDimensionTypes_Role_ConceptDefinition, objectTypeId)
        .getOrNull<FieldDimensionTypesStoreObject>()) {
        return { rule: `field.${fieldLabel}.isAssociatedToConcept`, status: ValidationStatus.ACCEPTED };
      }
    }
    return { rule: `field.${fieldLabel}.isNotAssociatedToConcept`, status: ValidationStatus.REJECTED };
  } else if (id.length > 1 && id[0].includes(':')) {
    const dimensionIds = dimensions.map((dimension) => dimension.id);
    if (id[0].split(':').every((i) => dimensionIds.includes(i))) {
      return { rule: `field.${fieldLabel}.isAssociatedToAllConcepts`, status: ValidationStatus.ACCEPTED };
    } else {
      return { rule: `field.${fieldLabel}.isNotAssociatedToAllConcepts`, status: ValidationStatus.REJECTED };
    }
  }
  return undefined;
};

export const preventComputedFieldUpdate = (
  fieldLabel: string
): AsPropertyBusinessRuleRegistration => ({ getObject }, fieldId) => (_, { properties }) => {
  // ignore on instance deletion
  if (properties === null) {
    return undefined;
  }

  const field = getObject(fieldId);
  if (!field) {
    return undefined;
  }

  if (field[Field_Formula] !== undefined) {
    return { rule: `field.${fieldLabel}.preventUpdateComputedField`, status: ValidationStatus.REJECTED };
  }

  return undefined;
};
