import type { AccessControlListResult, ObjectStoreReadOnly } from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import { filterNullOrUndefined } from 'yooi-utils';
import {
  Association,
  Association_Role_Definition,
  Association_Role_Role1TypeInstance,
  Association_Role_Role2TypeInstance,
  ConceptRoleUserAssignation,
  ConceptRoleUserAssignation_Role_Concept,
  ConceptRoleUserAssignation_Role_ConceptRole,
  ConceptRoleUserAssignation_Role_User,
  GroupMembershipAssociationDefinition,
  PlatformCapabilityAdmin,
  PlatformGroupCapability,
  PlatformGroupCapability_Role_Group,
  PlatformGroupCapability_Role_PlatformCapability,
} from '../ids';
import type { GroupStoreObject } from '../model';

export const getGroupsForUser = (store: ObjectStoreReadOnly, userId: string): GroupStoreObject[] => store.withAssociation(Association)
  .withExternalRole(Association_Role_Role1TypeInstance)
  .withRole(Association_Role_Definition, GroupMembershipAssociationDefinition)
  .withRole(Association_Role_Role2TypeInstance, userId)
  .list()
  .map((groupUserAsso) => groupUserAsso.navigateRoleOrNull<GroupStoreObject>(Association_Role_Role1TypeInstance))
  .filter(filterNullOrUndefined);

export const getRolesForUser = (store: ObjectStoreReadOnly, userId: string): { conceptId: string | undefined, role: string | undefined }[] => (
  store.withAssociation(ConceptRoleUserAssignation)
    .withRole(ConceptRoleUserAssignation_Role_User, userId)
    .list()
    .map((conceptRoleUserAssignation) => ({
      conceptId: conceptRoleUserAssignation.navigateRoleOrNull<GroupStoreObject>(ConceptRoleUserAssignation_Role_Concept)?.id,
      role: conceptRoleUserAssignation.navigateRoleOrNull<GroupStoreObject>(ConceptRoleUserAssignation_Role_ConceptRole)?.id,
    }))
    .filter(filterNullOrUndefined)
);

export const isAdminGroup = (store: ObjectStoreReadOnly, groupId: string): boolean => (
  Boolean(
    store.withAssociation(PlatformGroupCapability)
      .withRole(PlatformGroupCapability_Role_Group, groupId)
      .withRole(PlatformGroupCapability_Role_PlatformCapability, PlatformCapabilityAdmin)
      .getOrNull()
  )
);

export const hasPlatformCapability = ({ withAssociation }: ObjectStoreReadOnly, userId: string, capabilityId: string): boolean => (
  withAssociation(Association)
    .withRole(Association_Role_Definition, GroupMembershipAssociationDefinition)
    .withRole(Association_Role_Role2TypeInstance, userId)
    .list()
    .some((association) => (
      withAssociation(PlatformGroupCapability)
        .withRole(PlatformGroupCapability_Role_Group, association.role(Association_Role_Role1TypeInstance))
        .withRole(PlatformGroupCapability_Role_PlatformCapability, capabilityId)
        .getOrNull()
    ))
);

export const hasOnePlatformCapabilitiesOf = (store: ObjectStoreReadOnly, userId: string, capabilityIds: string[]): boolean => (
  capabilityIds.some((capabilityId) => hasPlatformCapability(store, userId, capabilityId))
);

export const adminOnlyAcl = (objectStore: ObjectStoreReadOnly, userId: string | undefined, typeLabel: string, action: string): AccessControlListResult => {
  if (userId && hasPlatformCapability(objectStore, userId, PlatformCapabilityAdmin)) {
    return { rule: `${typeLabel}.${action.toLowerCase()}.adminOnly`, status: ValidationStatus.ACCEPTED };
  } else {
    return { rule: `${typeLabel}.${action.toLowerCase()}.adminOnly`, status: ValidationStatus.REJECTED };
  }
};
