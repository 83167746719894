import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import type { CollaborationFieldRaw, CollaborationFieldStoreObject, FilterValueRaw } from 'yooi-modules/modules/conceptModule';
import { buildDimensionalId, collaborationFieldHandler, FilterValueType, ParsedDimensionType, parseDimensionMapping } from 'yooi-modules/modules/conceptModule';
import { ConceptCollaborationFieldDimension, Field_Title } from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareNumber, comparing, isFiniteNumber } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../../../components/molecules/Table';
import { Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import StoreNumberPickerInput from '../../input/StoreNumberPickerInput';
import CollaborationButton from '../../rightPanel/collaboration/CollaborationButton';
import { getIntentions } from '../../rightPanel/collaboration/utils/collaborationUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import { FIELD_EDITION_DIMENSIONS, getFieldDimensionsEditionHandlerValue } from '../fieldDimensionUtils';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, FieldComparatorHandler, GetFieldDefinitionHandler, RenderFilter } from '../FieldLibraryTypes';
import { FieldEditionOptionMode } from '../FieldLibraryTypes';

interface CollaborationFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
}

type CollaborationFieldDefinition = GetFieldDefinitionHandler<typeof collaborationFieldHandler, CollaborationFieldConfigurationState, never, FieldBlockDisplayOptions>;

export const collaborationFieldDefinition: CollaborationFieldDefinition = registerFieldDefinition(collaborationFieldHandler, {
  configuration: {
    typeIcon: IconName.chat_bubble,
    getTypeLabel: () => i18n`Collaboration`,
    asWidget: false,
    getEditionOptions: () => ({ mode, editionHandler }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        return [];
      }

      return [
        {
          key: 'display',
          type: 'section',
          title: i18n`Display`,
          options: [
            {
              key: Field_Title,
              title: i18n`Field title`,
              hasValue: () => editionHandler.getValue(Field_Title) !== undefined,
              clearValue: () => editionHandler.updateValues({ [Field_Title]: null }),
              type: EditionOptionTypes.text,
              props: {
                placeholder: i18n`Add text`,
                value: editionHandler.getValueOrDefault(Field_Title),
                maxLine: 5,
                onChange: (value) => editionHandler.updateValues({ [Field_Title]: value }),
              },
            },
          ],
        },
      ];
    },
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<CollaborationFieldStoreObject>(fieldId);
        return {
          [Field_Title]: field[Field_Title],
          [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
        };
      },
      submitFieldUpdate: (stateToSubmit) => {
        objectStore.updateObject<CollaborationFieldRaw>(fieldId, {
          [Field_Title]: stateToSubmit[Field_Title],
        });
      },
    }),
  },
  renderField: (store, fieldId) => ({ dimensionsMapping }) => {
    const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
    let hasIntentions = false;
    if (parsedDimensionMapping.type === ParsedDimensionType.MonoDimensional) {
      hasIntentions = getIntentions(store, store.getObject(parsedDimensionMapping.objectId)[Instance_Of] as string | undefined).length > 0;
    } else {
      hasIntentions = buildDimensionalId(parsedDimensionMapping.dimensionsMapping)
        .some((id) => getIntentions(store, store.getObject(id)[Instance_Of] as string | undefined).length > 0);
    }
    return (
      <SpacingLine>
        {hasIntentions && (<CollaborationButton instanceId={dimensionsMapping[ConceptCollaborationFieldDimension]} fieldId={fieldId} forceDisplay />)}
      </SpacingLine>
    );
  },
  getComparatorHandler: (_, __, { getValueResolution }) => (direction) => ({
    comparator: comparing(compareNumber, direction === TableSortDirection.desc),
    extractValue: (dimensionsMapping) => {
      const { value } = getValueResolution(dimensionsMapping);
      return typeof value === 'number' ? value : undefined;
    },
  }) satisfies FieldComparatorHandler<number | undefined>,
  renderExportConfiguration: () => () => (
    <SpacedContainer margin={{ x: Spacing.splus }}>
      <Tooltip title={i18n`Number - Collaboration count`}>
        <Typo maxLine={1}>{i18n`Number - Collaboration count`}</Typo>
      </Tooltip>
    </SpacedContainer>
  ),
  getActivityProperties: (_, fieldId) => () => [fieldId],
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({
    propertyId: fieldId,
    sortable: true,
  }),
  estimatedColumnWidth: () => () => '9rem',
  filterConditions: () => {
    const renderNumberFilter: RenderFilter<FilterValueRaw<number | undefined>> = (value, setValue, _, readOnly) => (
      <StoreNumberPickerInput
        placeholder={i18n`Value`}
        initialValue={value.raw}
        onSubmit={(newValue) => {
          if (newValue !== null && !isFiniteNumber(newValue)) {
            return;
          }
          setValue({ type: FilterValueType.raw, raw: typeof newValue === 'string' ? Number(newValue) : (newValue ?? undefined) });
        }}
        readOnly={readOnly}
      />
    );
    return {
      EQUALS: {
        name: i18n`=`,
        renderFilter: renderNumberFilter,
      },
      NOT_EQUALS: {
        name: i18n`≠`,
        renderFilter: renderNumberFilter,
      },
      GREATER: {
        name: i18n`>`,
        renderFilter: renderNumberFilter,
      },
      GREATER_OR_EQUALS: {
        name: i18n`≥`,
        renderFilter: renderNumberFilter,
      },
      LOWER: {
        name: i18n`<`,
        renderFilter: renderNumberFilter,
      },
      LOWER_OR_EQUALS: {
        name: i18n`≤`,
        renderFilter: renderNumberFilter,
      },
    };
  },
});
