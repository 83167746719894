type Observer<T> = (data?: T) => void;

export interface GlobalObserver<T> {
  register: (observer: Observer<T>) => () => void,
  notify: (data?: T) => void,
  serial: () => number,
}

const createGlobalObserver = <T>(): GlobalObserver<T> => {
  const observers = new Set<Observer<T>>();
  let serial = 0;

  return {
    register: (observer) => {
      observers.add(observer);
      return () => observers.delete(observer);
    },
    notify: (data?: T) => {
      serial += 1;
      observers.forEach((o) => o(data));
    },
    serial: () => serial,
  };
};

export default createGlobalObserver;
