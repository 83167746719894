import { createModule } from '../common/types/TypeModuleDsl';
import * as PlatformConfigurationModuleIds from './ids';
import { PlatformConfigurationModuleId } from './ids';
import migrations from './migrations';

const { initTypedModule, ...registerModelDsl } = createModule({ label: 'PlatformConfiguration' }, PlatformConfigurationModuleIds);
export const registerModel = registerModelDsl;

export const initModule = initTypedModule(() => ({
  id: PlatformConfigurationModuleId,
  migrations,
}));
