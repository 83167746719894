import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { DimensionsMapping, ParametersMapping, PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import {
  createValuePathResolver,
  dimensionsMappingToParametersMapping,
  FILTER_PARAMETER_CURRENT,
  getFieldUtilsHandler,
  getInstanceLabel,
  getPathLastFieldInformation,
  InstanceReferenceType,
  isDimensionLibraryPath,
  isRelationFieldPathConfigurationValid,
  isSingleFieldResolution,
  isSingleValueResolution,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import type { FieldStoreObject } from 'yooi-modules/modules/conceptModule/model/types';
import type { DimensionDisplayOption, DimensionExportConfiguration, ViewDimension, WidgetStoreObject } from 'yooi-modules/modules/dashboardModule';
import { Widget_Title } from 'yooi-modules/modules/dashboardModule/ids';
import type { StoreObject } from 'yooi-store';
import { dateFormats, formatDisplayDate, isRichText, joinObjects, richTextToText } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import { Icon } from '../../../../components/atoms/icons';
import Typo from '../../../../components/atoms/Typo';
import ConfirmationModal, { ConfirmationModalVariant } from '../../../../components/molecules/ConfirmationModal';
import InlineLoading from '../../../../components/molecules/InlineLoading';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { formatOrUndef } from '../../../../utils/stringUtils';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../../utils/useSizeContext';
import useTheme from '../../../../utils/useTheme';
import withAsyncTask from '../../../../utils/withAsyncTask';
import { getFieldLabel } from '../../fieldUtils';
import { countValidFilters } from '../../filter/filterUtils';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import useExport from '../../useExport';
import { getDimensionLabel } from '../data/dataResolution';
import type { ViewResolutionError } from '../viewResolutionUtils';
import { isResolutionError } from '../viewResolutionUtils';
import type { StructuralBarChartViewResolvedDefinition } from './structuralBarChartViewHandler';
import type { StructuralBarChartViewResolution } from './structuralBarChartViewResolution';

interface StructuralBarChartViewExportButtonProps {
  widgetId: string | undefined,
  getViewResolution: () => StructuralBarChartViewResolution | ViewResolutionError,
  parametersMapping: ParametersMapping,
  viewDefinition: StructuralBarChartViewResolvedDefinition,
  viewDimensions: ViewDimension[],
  filterConfiguration: FilterConfiguration | undefined,
  parameterDefinitions: SingleParameterDefinition[],
}

interface Header {
  type: 'header',
  key: string,
}

interface DimensionColumn {
  type: 'dimension',
  key: string,
  dimensionId: string,
  label: string | undefined,
  path: PathStep[],
  exportConfiguration: DimensionExportConfiguration | undefined,
}

interface FieldColumn {
  type: 'field',
  key: string,
  conceptDefinitionId: string | undefined,
  fieldId: string,
  label: string | undefined,
  path: PathStep[],
}

type BarChartExportColumn = DimensionColumn | FieldColumn;

type Line = { type: 'line', key: string, dimensionsMapping: DimensionsMapping } | Header;

const StructuralBarChartViewExportButton: FunctionComponent<StructuralBarChartViewExportButtonProps> = withAsyncTask(({
  executeAsyncTask,
  widgetId,
  getViewResolution,
  parametersMapping,
  viewDefinition,
  viewDimensions,
  filterConfiguration,
  parameterDefinitions,
}) => {
  const { exportToExcel } = useExport<BarChartExportColumn, Line>(executeAsyncTask);
  const store = useStore();
  const theme = useTheme();

  const withFilters = filterConfiguration !== undefined && ((filterConfiguration.filters && Object.values(filterConfiguration.filters)
    .some((filter) => countValidFilters(store, filter) > 0))
    || filterConfiguration.nameSearch !== undefined);

  const widget = widgetId ? store.getObjectOrNull<WidgetStoreObject>(widgetId) : undefined;
  const widgetName = widget ? widget[Widget_Title] : undefined;

  const [pendingExport, setPendingExport] = useState<{
    lines: Line[],
    columns: BarChartExportColumn[],
    fileName: string,
    dimensionsInError: string[],
  } | undefined>(undefined);

  const exportView = (lines: Line[], columns: BarChartExportColumn[], fileName: string) => {
    exportToExcel({
      fileName: `${formatDisplayDate(new Date(), dateFormats.isoDateFormat)},${fileName}.xlsx`,
      lines,
      columns,
      headerRowCount: 1,
      cellResolver: (objectStore, line, column) => {
        if (line.type === 'header') {
          return { format: 'string', value: column.label };
        }
        const valuePathResolver = createValuePathResolver(objectStore, joinObjects(parametersMapping, dimensionsMappingToParametersMapping(line.dimensionsMapping)));
        if (column.type === 'dimension') {
          const { exportConfiguration } = column;
          const defaultPathResolution = valuePathResolver.resolvePathValue<StoreObject>([
            { type: PathStepType.dimension, conceptDefinitionId: column.dimensionId },
            { type: PathStepType.mapping, mapping: { type: InstanceReferenceType.parameter, id: column.key } },
          ]);
          if (exportConfiguration) {
            if (exportConfiguration.type === 'path' && exportConfiguration.path) {
              const exportPathResolution = createValuePathResolver(objectStore, { [FILTER_PARAMETER_CURRENT]: { type: 'single', id: line.key } })
                .resolvePathValue(exportConfiguration.path);
              if (exportPathResolution && isSingleValueResolution(exportPathResolution) && exportPathResolution.value) {
                return { format: 'string', value: isRichText(exportPathResolution.value) ? richTextToText(exportPathResolution.value) : exportPathResolution.value as string };
              } else {
                return { format: 'string', value: undefined };
              }
            } else if (defaultPathResolution && isSingleValueResolution(defaultPathResolution) && defaultPathResolution.value) {
              if (exportConfiguration.type === 'uuid') {
                return { format: 'string', value: defaultPathResolution.value.id };
              }
            }
          }
          if (defaultPathResolution && isSingleValueResolution(defaultPathResolution) && defaultPathResolution.value) {
            return { format: 'string', value: formatOrUndef(getInstanceLabel(objectStore, defaultPathResolution.value)) };
          }
        }
        if (column.type === 'field') {
          const pathResolution = valuePathResolver.resolvePathField(column.path);
          if (isSingleFieldResolution(pathResolution) && pathResolution.dimensionsMapping) {
            const fieldUtilsHandler = getFieldUtilsHandler(objectStore, column.fieldId);
            if (!fieldUtilsHandler.getExportValue) {
              return undefined;
            }
            return fieldUtilsHandler.getExportValue(pathResolution.dimensionsMapping, undefined, {});
          }
        }
        return undefined;
      },
    });
  };

  const prepareExportView = () => {
    const viewResolution = getViewResolution();
    if (isResolutionError(viewResolution)) {
      return;
    }
    const { data } = viewResolution;
    const lines: Line[] = [{ type: 'header', key: 'header' }];
    const columns: BarChartExportColumn[] = [];
    const dimensionsInError: string[] = [];

    viewDimensions.forEach((viewDimension, index) => {
      const dimensionDisplay: DimensionDisplayOption | undefined = viewDefinition.getDimensionDisplay(viewDimension);
      let dimensionColumn = {
        type: 'dimension',
        key: viewDimension.id,
      };
      const { exportConfiguration } = dimensionDisplay;
      if (exportConfiguration && exportConfiguration.type === 'path' && !isRelationFieldPathConfigurationValid(store, exportConfiguration, parameterDefinitions)) {
        dimensionsInError.push(viewDimension.label ?? getDimensionLabel(store, viewDimension.label, index, viewDimension.path));
      } else {
        if (dimensionDisplay && exportConfiguration) {
          dimensionColumn = joinObjects(dimensionColumn, { exportConfiguration });
        }
        if (isDimensionLibraryPath(viewDimension.path)) {
          dimensionColumn = joinObjects(dimensionColumn, {
            label: viewDimension.label ?? getDimensionLabel(store, viewDimension.label, index, viewDimension.path),
            path: viewDimension.path,
            dimensionId: viewDimension.path[0].conceptDefinitionId,
          });
        }
        columns.push(dimensionColumn as BarChartExportColumn);
      }
    });

    if (viewDefinition.series) {
      viewDefinition.series.forEach((serie) => {
        const lastFieldInfo = getPathLastFieldInformation(serie.path);
        let { label } = serie;
        if (lastFieldInfo) {
          if (!label) {
            const fieldObject = lastFieldInfo?.fieldId && store.getObjectOrNull<FieldStoreObject>(lastFieldInfo.fieldId);
            label = fieldObject ? getFieldLabel(store, fieldObject) : undefined;
          }
          const fieldColumn: BarChartExportColumn = {
            type: 'field',
            key: serie.id,
            conceptDefinitionId: lastFieldInfo.conceptDefinitionId,
            fieldId: lastFieldInfo.fieldId,
            label,
            path: serie.path,
          };
          columns.push(fieldColumn);
        }
      });
    }

    const flatMapData = data.flat(2);
    const dimensionMappingKeys: string[] = [];
    flatMapData.forEach((dataElement) => {
      if (dataElement.dimensionsMapping) {
        const dimensionMappingKey = Object.values(dataElement.dimensionsMapping).join('|');
        if (!dimensionMappingKeys.includes(dimensionMappingKey)) {
          dimensionMappingKeys.push(dimensionMappingKey);
          const line: Line = { type: 'line', key: dimensionMappingKey, dimensionsMapping: dataElement.dimensionsMapping };
          lines.push(line);
        }
      }
    });
    let fileName = i18n`export`;
    if (viewDefinition.exportTitle) {
      fileName = viewDefinition.exportTitle;
    } else if (widgetName) {
      fileName = `${widgetName}-${fileName}`;
    }
    if (dimensionsInError.length === 0 && !withFilters) {
      exportView(lines, columns, fileName);
    } else {
      setPendingExport({ lines, columns, fileName, dimensionsInError });
    }
  };

  return (
    <>
      <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
        <IconOnlyButton
          iconName={IconName.file_save_outline}
          tooltip={i18n`Export`}
          variant={IconOnlyButtonVariants.secondary}
          onClick={() => {
            prepareExportView();
          }}
        />
      </SizeContextProvider>
      {pendingExport && (
        <ConfirmationModal
          variant={ConfirmationModalVariant.confirm}
          title={i18n`Warning`}
          titleIcon={{ name: Icon.warning, color: theme.color.background.warning.default }}
          open
          onConfirm={() => {
            if (pendingExport) {
              exportView(pendingExport.lines, pendingExport.columns, pendingExport.fileName);
              setPendingExport(undefined);
            }
          }}
          confirmLabel={i18n`Continue`}
          cancelLabel={i18n`Cancel`}
          onCancel={() => setPendingExport(undefined)}
          render={() => <Typo>{i18n`${pendingExport && pendingExport.dimensionsInError.length > 0 ? i18n`${pendingExport.dimensionsInError.length} dimensions will not be exported (${pendingExport.dimensionsInError.join(', ')}) because the export configuration is corrupted. If you wish to export them, you must ask an administrator.` : ''}${pendingExport && pendingExport.dimensionsInError.length > 0 && withFilters ? '\n' : ''}${withFilters ? i18n`Filters configured on this view will be taken into account when exporting data. Are you sure you want to continue?` : ''}`}</Typo>}
        />
      )}
    </>
  );
}, InlineLoading);

export default StructuralBarChartViewExportButton;
