import { useSyncExternalStore } from 'react';
import type { ObjectDebugLabelLibrary } from 'yooi-store';
import useStoreContext from './useStoreContext';

const useObjectDebugLabelLibrary = (): ObjectDebugLabelLibrary => {
  const { data: { globalObservers, objectDebugLabelLibrary } } = useStoreContext();

  useSyncExternalStore(
    globalObservers.register,
    globalObservers.serial
  );

  return objectDebugLabelLibrary;
};

export default useObjectDebugLabelLibrary;
