import { useContext, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Concept } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import type { ObjectStore } from 'yooi-store';
import { joinObjects, newError } from 'yooi-utils';
import useStore from '../../../store/useStore';
import { useChipBlockContext } from '../../../utils/useChipBlockContext';
import type { PanelContext } from '../../../utils/useCollaborationContext';
import { CollaborationContext, DEFAULT_PANEL_CONTEXT, PanelState, storeContextInSessionStorage } from '../../../utils/useCollaborationContext';
import { useHighlightNotify } from '../../../utils/useHighlight';
import { getConceptInstanceFullContext } from '../conceptUtils';
import { getMostRelevantContextForCollaboration } from '../rightPanel/collaboration/utils/collaborationUtils';

export const computeFullContext = (store: ObjectStore, pageContext: string[][] | undefined, context: string[] | undefined): string[][] | undefined => {
  if (!pageContext) {
    return undefined;
  }
  const fullContext = context ? [...pageContext, context] : pageContext;
  // If an element from the full context is a concept (from the most relevant context to less) then rewrite the context before to match the correct conceptInstance context
  // that is not related to the pageContext
  const reversedIndex = fullContext.slice().reverse().findIndex((ctx) => isInstanceOf(store.getObjectOrNull(ctx[0]), Concept));
  if (reversedIndex >= 0) {
    const conceptInstanceIndex = fullContext.length - 1 - reversedIndex;
    if (fullContext[conceptInstanceIndex].length === 1) {
      return [...getConceptInstanceFullContext(store, fullContext[conceptInstanceIndex][0]), ...fullContext.slice(conceptInstanceIndex + 1)];
    } else {
      return [...getConceptInstanceFullContext(store, fullContext[conceptInstanceIndex][0]), ...fullContext.slice(conceptInstanceIndex)];
    }
  } else {
    return fullContext;
  }
};

const computePanelStateAfterPageContextUpdate = (
  store: ObjectStore,
  pageContext: string[][] | undefined,
  panelContext: PanelContext,
  globalChipContext: string[] | undefined
): PanelContext => {
  if (panelContext.state === PanelState.detail && panelContext.collaborationId && panelContext.context && pageContext) {
    const mostRelevantContext = getMostRelevantContextForCollaboration(store, panelContext.collaborationId, panelContext.context, pageContext);
    return joinObjects(
      panelContext,
      { context: mostRelevantContext }
    );
  }

  return {
    state: PanelState.list,
    context: globalChipContext,
    isOpen: panelContext.isOpen,
  };
};

export const usePageCollaborationContext = (): void => {
  // Be careful, context couldn't be spread as the Context works as a reference, some of its child object gets replaced (like pageContext or panelContext)
  const context = useContext(CollaborationContext);
  if (context === undefined) {
    throw newError('CollaborationContext has not been initialized, add a CollaborationPanelContextProvider in the React parent component hierarchy');
  }

  const globalChipContext = useChipBlockContext();
  const store = useStore();

  const location = useLocation();
  const navigate = useNavigate();
  const highlight = useHighlightNotify();
  // We push navigate & location to a state because they are not stable over time and we don't want to always re-run the layout effect with each re-render
  const historyRef = useRef({ navigate, location });
  historyRef.current = { navigate, location };
  const { search } = location;

  useEffect(() => {
    if (globalChipContext.context) {
      let urlContext;
      const searchParams = new URLSearchParams(search);
      if (searchParams.has('collaboration')) {
        urlContext = {
          collaborationId: searchParams.get('collaboration') as string,
          messageId: searchParams.get('message') ?? undefined,
        };
        searchParams.delete('collaboration');
        searchParams.delete('message');
        historyRef.current.navigate({ hash: historyRef.current.location.hash, search: searchParams.toString() }, { replace: true });
      }

      context.pageContext = [...globalChipContext.parentContext, [globalChipContext.context]];
      context.updatePanelContext(joinObjects(
        computePanelStateAfterPageContextUpdate(
          store,
          context.pageContext,
          context.panelContext,
          globalChipContext.context ? [globalChipContext.context] : undefined
        ),
        {
          urlContext: (urlContext ?? context.panelContext.urlContext),
        }
      ));

      highlight(context.panelContext.context);
    } else {
      context.panelContext = DEFAULT_PANEL_CONTEXT;
      storeContextInSessionStorage(context.panelContext, context.pageContext);
    }

    context.panelObserver.notify();

    if (globalChipContext.context) {
      return () => {
        context.pageContext = undefined;
        context.panelObserver.notify();
      };
    }
    return undefined;
  }, [globalChipContext.context, globalChipContext.parentContext, context, search, store, highlight]);
};

export const testables = {
  computePanelStateAfterPageContextUpdate,
};
