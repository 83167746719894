import { StructuredError } from 'yooi-utils';

class AbortTaskError extends StructuredError {
  override readonly name = 'AbortTaskError';

  constructor() {
    super('Abort task', { data: {} });
  }
}

export default AbortTaskError;
