import type { FunctionComponent } from 'react';
import { Fragment } from 'react';
import BlockContent from '../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../components/templates/BlockTitle';
import HorizontalBlock from '../../components/templates/HorizontalBlock';
import VerticalBlock from '../../components/templates/VerticalBlock';
import { getFieldEditionOptionRender } from './fields/fieldEditionOptionsUtils';
import type { FieldEditionOption, FieldEditionSection, FieldEditionSectionGroup } from './fields/FieldEditionOptionType';
import { EditionOptionTypes } from './fields/FieldEditionOptionType';

const renderOptions = (options: FieldEditionOption[]) => options
  .map((option) => {
    const Block = option.isVertical ? VerticalBlock : HorizontalBlock;
    return (
      <Block key={option.key} asBlockContent>
        {
          option.title
            ? (
              <BlockTitle
                title={option.title}
                variant={option.type === EditionOptionTypes.custom ? BlockTitleVariant.inline : undefined}
                infoTooltip={option.info}
              />
            )
            : null
        }
        {
          option.skipBlockContent
            ? getFieldEditionOptionRender(option)
            : (
              <BlockContent padded={option.padded}>
                {getFieldEditionOptionRender(option)}
              </BlockContent>
            )
        }
      </Block>
    );
  });

interface InputOptionBlocksProps {
  sections: (FieldEditionSection | FieldEditionSectionGroup)[],
}

const InputOptionBlocks: FunctionComponent<InputOptionBlocksProps> = ({ sections }) => (
  <>
    {sections.map((section, index) => (
      <VerticalBlock key={section.key} asBlockContent withSeparation={index < (sections.length - 1)}>
        {section.title ? (<BlockTitle title={section.title} rightActions={section.type === 'group' ? section.rightActions : undefined} />) : null}
        {section.type === 'section' ? renderOptions(section.options) : null}
        {
          section.type === 'group'
            ? section.sections.map((subSection) => (
              <Fragment key={subSection.key}>
                {
                  subSection.title
                    ? (<BlockTitle variant={BlockTitleVariant.secondary} title={subSection.title} rightActions={subSection.rightActions} infoTooltip={subSection.info} />)
                    : null
                }
                {renderOptions(subSection.options)}
              </Fragment>
            ))
            : null
        }
      </VerticalBlock>
    ))}
  </>
);

export default InputOptionBlocks;
