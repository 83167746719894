import type { EventOrigin, ObjectStoreWithTimeseries } from 'yooi-store';
import { newError } from 'yooi-utils';
import type { Filters, MultipleParameterValue, ParametersMapping, SingleParameterValue } from '../../conceptModule';
import { createCollaborationEventTriggerHandler, isCollaborationEventTrigger } from './collaborationEventTrigger';
import { createInstanceEventTriggerHandler, isInstanceEventTrigger } from './instanceEventTrigger';
import { createSchedulerTriggerHandler, isScheduledTrigger } from './schedulerTrigger';
import type { TriggerType } from './utils';

export interface SingleActionParameterDefinition {
  type: 'parameter',
}

export interface MultipleActionParameterDefinition {
  type: 'parameterList',
}

export interface ObjectActionQuery {
  type: 'object',
  object: MailActionQueryRecord,
}

export interface StoreObjectActionQuery {
  type: 'storeObject',
}

export type ActionQuery = SimpleActionQuery | ObjectActionQuery | StoreObjectActionQuery;

export type MailActionQueryRecord = Record<string, ActionQuery>;

export interface AutomationRuleTriggerHandler {
  parameterDefinitions: Record<string, SingleActionParameterDefinition | MultipleActionParameterDefinition>,
  mailActionQuery: MailActionQueryRecord,
}

export interface SimpleActionQuery {
  type: 'simple',
}

export interface ConstantActionQuery<T extends ActionQuery = ActionQuery> {
  type: 'constant',
  getValue: (props: {
    store: ObjectStoreWithTimeseries,
    eventOrigin: EventOrigin | undefined,
    parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
  }) => T extends SimpleActionQuery
    ? string | undefined
    : T extends ObjectActionQuery
      ? { [K in keyof T['object']]: unknown } | undefined
      : object,
}

export interface TriggerHandler<TH extends AutomationRuleTriggerHandler> {
  parameterDefinitions: {
    [K in keyof TH['parameterDefinitions']]: { id: K, type: 'parameter' | 'parameterList', typeId: string, label: string } | undefined
  },
  mailActionQuery: { [K in keyof TH['mailActionQuery']]: ConstantActionQuery<TH['mailActionQuery'][K]> },
}

export interface AutomationRuleTrigger {
  type: TriggerType,
  filters?: Filters,
  label?: string,
}

export const getTriggerHandler = (trigger: AutomationRuleTrigger): TriggerHandler<AutomationRuleTriggerHandler> => {
  if (isInstanceEventTrigger(trigger)) {
    return createInstanceEventTriggerHandler(trigger);
  } else if (isCollaborationEventTrigger(trigger)) {
    return createCollaborationEventTriggerHandler();
  } else if (isScheduledTrigger(trigger)) {
    return createSchedulerTriggerHandler();
  } else {
    throw newError('Unknown trigger type');
  }
};
