import { getColorByValue, getFieldDimensionOfModelType, numberFieldHandler } from 'yooi-modules/modules/conceptModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined } from 'yooi-utils';
import type { FrontObjectStore } from '../../../../store/useStore';
import { hexColorWithAlpha } from '../../../../theme/colorUtils';
import type { TickResolved } from '../../fieldUtils';
import { resolveTickValue, TickResolutionStatus } from '../../fieldUtils';

export const generateColorByPercentage = (
  value: number | undefined,
  min: { value: number, color: string | undefined } | undefined,
  ticks: { value: number, color: string | undefined }[] | undefined,
  max: { value: number, color: string | undefined } | undefined
): (isText?: boolean) => string | undefined => {
  const color = getColorByValue(value, min, ticks, max);
  return (isText = false) => (isText || !color ? color : hexColorWithAlpha(color, 0.1));
};

export const getNumberRangeValues = (store: FrontObjectStore, fieldId: string, conceptId: string | undefined): { value: number, color: string | undefined }[] => {
  const conceptDefinitionId = conceptId ? store.getObject(conceptId)[Instance_Of] as string : undefined;
  const dimensionId = conceptDefinitionId ? getFieldDimensionOfModelType(store, fieldId, conceptDefinitionId) : undefined;

  const fieldHandler = numberFieldHandler(store, fieldId);
  const numberField = dimensionId && conceptId ? fieldHandler.resolveConfigurationWithOverride({ [dimensionId]: conceptId }) : fieldHandler.resolveConfiguration();

  return (numberField.rangeValue ?? [])
    .map((tick) => resolveTickValue(store, dimensionId ? { [dimensionId]: { type: 'single', id: conceptId } } : {}, tick))
    .filter(filterNullOrUndefined)
    .filter((resolution): resolution is TickResolved => resolution.status === TickResolutionStatus.Resolved);
};
