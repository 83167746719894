import classnames from 'classnames';
import sizeof from 'object-sizeof';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { AutoProvisioningMap } from 'yooi-utils';
import { compareNumber, compareProperty, comparing, createAutoProvisioningMap, joinObjects } from 'yooi-utils';
import Typo, { TypoVariant } from '../../../components/atoms/Typo';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../components/molecules/SpacingLine';
import Spinner, { SpinnerVariant } from '../../../components/molecules/Spinner';
import Table from '../../../components/molecules/Table';
import TableBody from '../../../components/molecules/TableBody';
import TableCell, { TableCellAlign } from '../../../components/molecules/TableCell';
import TableLine from '../../../components/molecules/TableLine';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import type { FrontObjectStore } from '../../../store/useStore';
import useStore from '../../../store/useStore';
import { Opacity } from '../../../theme/base';
import { generateColorFromOpacity } from '../../../theme/colorUtils';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useTheme from '../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../utils/useUsageContext';
import withAsyncTask, { registerComputeFunction } from '../../../utils/withAsyncTask';
import { defaultOptionComparator } from '../../_global/modelTypeUtils';
import { useExplorerHint } from './_global/GetHintContextProvider';
import { ValueType } from './_global/objectRenderType';
import ValueRenderer from './_global/ValueRenderer';

const units = ['B', 'kB', 'MB', 'GB', 'TB'];
const humanReadableSize = (size: number): string => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / (1024 ** i)).toFixed(2)} ${units[i]}`;
};

const getStatisticsComputeFunction = registerComputeFunction(
  async (yieldProcessor: () => Promise<void>, store: FrontObjectStore, objectTopBy: 'count' | 'totalSize', associationTopBy: 'count' | 'totalSize') => {
    const objects = store.listObjects();

    await yieldProcessor();

    let globalCount = 0;
    let globalSize = 0;
    let globalRawSize = 0;

    let objectsCount = 0;
    let objectsSize = 0;
    let objectsRawSize = 0;
    const objectTypeMap: AutoProvisioningMap<string /* typeId */, { typeId: string, count: number, totalSize: number, totalRawSize: number }> = createAutoProvisioningMap();

    let associationsCount = 0;
    let associationsSize = 0;
    let associationsRawSize = 0;
    const associationTypeMap: AutoProvisioningMap<string /* typeId */, { typeId: string, count: number, totalSize: number, totalRawSize: number }> = createAutoProvisioningMap();

    for (let i = 0; i < objects.length; i += 1) {
      const object = objects[i];

      const objectSize = sizeof(
        joinObjects(
          object.asRawObject(),
          { id: object.id, key: object.key, navigateBack: object.navigateBack, navigate: object.navigate, navigateOrNull: object.navigateOrNull, asRawObject: object.asRawObject }
        )
      );
      const objectRawSize = sizeof(joinObjects(object.asRawObject(), { id: object.id }));

      globalCount += 1;
      globalSize += objectSize;
      globalRawSize += objectRawSize;
      if (typeof object.id === 'string') {
        objectsCount += 1;
        objectsSize += objectSize;
        objectsRawSize += objectRawSize;

        const typeId = `${object[Instance_Of]}`;
        const typeStatistics = objectTypeMap.getOrCreate(typeId, (key) => ({ typeId: key, count: 0, totalSize: 0, totalRawSize: 0 }));
        typeStatistics.count += 1;
        typeStatistics.totalSize += objectSize;
        typeStatistics.totalRawSize += objectRawSize;
      } else {
        associationsCount += 1;
        associationsSize += objectSize;
        associationsRawSize += objectRawSize;

        const typeId = object.id[0];
        const typeStatistics = associationTypeMap.getOrCreate(typeId, (key) => ({ typeId: key, count: 0, totalSize: 0, totalRawSize: 0 }));
        typeStatistics.count += 1;
        typeStatistics.totalSize += objectSize;
        typeStatistics.totalRawSize += objectRawSize;
      }

      await yieldProcessor();
    }

    const objectsTop10 = Array.from(objectTypeMap.values()).sort(comparing(compareProperty(objectTopBy, compareNumber), true));
    await yieldProcessor();

    const associationsTop10 = Array.from(associationTypeMap.values()).sort(comparing(compareProperty(associationTopBy, compareNumber), true)).slice(0, 10);
    await yieldProcessor();

    return {
      objects: {
        count: objectsCount,
        size: objectsSize,
        rawSize: objectsRawSize,
        top10: objectsTop10.slice(0, 10),
      },
      associations: {
        count: associationsCount,
        size: associationsSize,
        rawSize: associationsRawSize,
        top10: associationsTop10,
      },
      global: {
        count: globalCount,
        size: globalSize,
        rawSize: globalRawSize,
      },
    };
  }
);

const useStyles = makeStyles({
  cell: {
    display: 'flex',
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
  },
  verticalCell: {
    flexDirection: 'column',
  },
  endCell: {
    justifyContent: 'end',
    alignItems: 'end',
  },
}, 'explorerHomeStatisticsTab');

const ExplorerHomeStatisticsTab: FunctionComponent = withAsyncTask(({ executeAsyncTask }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const getHint = useExplorerHint();

  const [objectTopBy, setObjectTopBy] = useState<'count' | 'totalSize'>('count');
  const [associationTopBy, setAssociationTopBy] = useState<'count' | 'totalSize'>('count');

  const { status, value: statistics } = executeAsyncTask(getStatisticsComputeFunction, [store, objectTopBy, associationTopBy], [store.getSerial()]);

  const topByOptions = [
    { id: 'count', label: i18n`number of objects` } as const,
    { id: 'totalSize', label: i18n`total size` } as const,
  ].sort(defaultOptionComparator);

  const renderLine = ({ typeId, count, totalSize, totalRawSize }: { typeId: string, count: number, totalSize: number, totalRawSize: number }) => (
    <TableLine key={typeId}>
      <TableCell width="35rem">
        <div className={classes.cell}>
          <ValueRenderer value={{ type: ValueType.string, value: typeId, hint: getHint(typeId), href: `/settings/explorer/instance/${typeId}`, maxLine: 1 }} />
        </div>
      </TableCell>
      <TableCell align={TableCellAlign.right} width="10rem">
        <div className={classnames(classes.cell, classes.endCell)}>
          <Typo>{count}</Typo>
        </div>
      </TableCell>
      <TableCell align={TableCellAlign.right} width="10rem">
        <div className={classnames(classes.cell, classes.verticalCell, classes.endCell)}>
          <Typo>{humanReadableSize(totalSize)}</Typo>
          <Typo variant={TypoVariant.small} color={theme.color.text.disabled}>{humanReadableSize(totalRawSize)}</Typo>
        </div>
      </TableCell>
    </TableLine>
  );

  return (
    <VerticalBlock>
      <VerticalBlock asBlockContent withSeparation>
        <BlockTitle
          title={i18n`Usage`}
          actions={
            status === 'loading'
              ? (
                <SpacingLine>
                  <Spinner size={SpinnerVariant.small} color={generateColorFromOpacity(theme.color.text.brand, theme.color.background.neutral.default, Opacity.fifty)} />
                </SpacingLine>
              )
              : undefined
          }
          actionsFullHeight
        />
        <BlockContent padded>
          <span>
            <Table>
              <TableBody>
                <TableLine>
                  <TableCell width="20rem">
                    <div className={classes.cell}>
                      <Typo variant={TypoVariant.tabTitle}>{i18n`Number of objects`}</Typo>
                    </div>
                  </TableCell>
                  <TableCell width="10rem">
                    <div className={classnames(classes.cell, classes.endCell)}>
                      <Typo>{statistics.objects.count}</Typo>
                    </div>
                  </TableCell>
                </TableLine>
                <TableLine>
                  <TableCell>
                    <div className={classes.cell}>
                      <Typo variant={TypoVariant.tabTitle}>{i18n`Number of associations`}</Typo>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={classnames(classes.cell, classes.endCell)}>
                      <Typo>{statistics.associations.count}</Typo>
                    </div>
                  </TableCell>
                </TableLine>
                <TableLine>
                  <TableCell>
                    <div className={classes.cell}>
                      <Typo variant={TypoVariant.tabTitle}>{i18n`Total number of objects`}</Typo>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={classnames(classes.cell, classes.endCell)}>
                      <Typo>{statistics.global.count}</Typo>
                    </div>
                  </TableCell>
                </TableLine>
                <TableLine>
                  <TableCell>
                    <div className={classnames(classes.cell, classes.verticalCell)}>
                      <Typo variant={TypoVariant.tabTitle}>{i18n`Objects size`}</Typo>
                      <Typo variant={TypoVariant.small} color={theme.color.text.disabled}>{i18n`Raw size`}</Typo>
                    </div>
                  </TableCell>
                  <TableCell align={TableCellAlign.right}>
                    <div className={classnames(classes.cell, classes.verticalCell, classes.endCell)}>
                      <Typo>{humanReadableSize(statistics.objects.size)}</Typo>
                      <Typo variant={TypoVariant.small} color={theme.color.text.disabled}>{humanReadableSize(statistics.objects.rawSize)}</Typo>
                    </div>
                  </TableCell>
                </TableLine>
                <TableLine>
                  <TableCell>
                    <div className={classnames(classes.cell, classes.verticalCell)}>
                      <Typo variant={TypoVariant.tabTitle}>{i18n`Total associations size`}</Typo>
                      <Typo variant={TypoVariant.small} color={theme.color.text.disabled}>{i18n`Raw size`}</Typo>
                    </div>
                  </TableCell>
                  <TableCell align={TableCellAlign.right}>
                    <div className={classnames(classes.cell, classes.verticalCell, classes.endCell)}>
                      <Typo>{humanReadableSize(statistics.associations.size)}</Typo>
                      <Typo variant={TypoVariant.small} color={theme.color.text.disabled}>{humanReadableSize(statistics.associations.rawSize)}</Typo>
                    </div>
                  </TableCell>
                </TableLine>
                <TableLine>
                  <TableCell>
                    <div className={classnames(classes.cell, classes.verticalCell)}>
                      <Typo variant={TypoVariant.tabTitle}>{i18n`Total store size`}</Typo>
                      <Typo variant={TypoVariant.small} color={theme.color.text.disabled}>{i18n`Raw size`}</Typo>
                    </div>
                  </TableCell>
                  <TableCell align={TableCellAlign.right}>
                    <div className={classnames(classes.cell, classes.verticalCell, classes.endCell)}>
                      <Typo>{humanReadableSize(statistics.global.size)}</Typo>
                      <Typo variant={TypoVariant.small} color={theme.color.text.disabled}>{humanReadableSize(statistics.global.rawSize)}</Typo>
                    </div>
                  </TableCell>
                </TableLine>
              </TableBody>
            </Table>
          </span>
        </BlockContent>
      </VerticalBlock>
      <VerticalBlock asBlockContent withSeparation>
        <BlockTitle
          title={i18n`Top 10 instances types by`}
          actions={(
            <SpacingLine>
              <UsageContextProvider usageVariant={UsageVariant.inForm}>
                <SearchAndSelect
                  computeOptions={() => topByOptions}
                  selectedOption={topByOptions.find(({ id }) => id === objectTopBy)}
                  onSelect={(option) => {
                    if (option) {
                      setObjectTopBy(option.id);
                    }
                  }}
                />
              </UsageContextProvider>
              {
                status === 'loading'
                  ? (<Spinner size={SpinnerVariant.small} color={generateColorFromOpacity(theme.color.text.brand, theme.color.background.neutral.default, Opacity.fifty)} />)
                  : undefined
              }
            </SpacingLine>
          )}
          actionsFullHeight
        />
        <BlockContent padded>
          <span>
            <Table>
              <TableBody>
                {statistics.objects.top10.map(renderLine)}
              </TableBody>
            </Table>
          </span>
        </BlockContent>
      </VerticalBlock>
      <VerticalBlock asBlockContent>
        <BlockTitle
          title={i18n`Top 10 associations types by`}
          actions={(
            <SpacingLine>
              <UsageContextProvider usageVariant={UsageVariant.inForm}>
                <SearchAndSelect
                  computeOptions={() => topByOptions}
                  selectedOption={topByOptions.find(({ id }) => id === associationTopBy)}
                  onSelect={(option) => {
                    if (option) {
                      setAssociationTopBy(option.id);
                    }
                  }}
                />
              </UsageContextProvider>
              {
                status === 'loading'
                  ? (<Spinner size={SpinnerVariant.small} color={generateColorFromOpacity(theme.color.text.brand, theme.color.background.neutral.default, Opacity.fifty)} />)
                  : undefined
              }
            </SpacingLine>
          )}
          actionsFullHeight
        />
        <BlockContent padded>
          <span>
            <Table>
              <TableBody>
                {statistics.associations.top10.map(renderLine)}
              </TableBody>
            </Table>
          </span>
        </BlockContent>
      </VerticalBlock>
    </VerticalBlock>
  );
});

export default ExplorerHomeStatisticsTab;
