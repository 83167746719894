import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { IntentionStoreObject } from 'yooi-modules/modules/collaborationModule';
import {
  Intention,
  Intention_Available,
  Intention_Description,
  Intention_Name,
  Intention_Workflow,
  IntentionConcepts,
  IntentionConcepts_Role_ConceptDefinition,
  IntentionConcepts_Role_Intention,
  IntentionGroups,
  IntentionGroups_Role_Group,
  IntentionGroups_Role_Intention,
  IntentionStatusEntries,
  IntentionStatusEntries_Role_Entry,
  IntentionStatusEntries_Role_Intention,
  IntentionStatusIsClosedStatus,
  IntentionUsers,
  IntentionUsers_Role_Intention,
  IntentionUsers_Role_User,
} from 'yooi-modules/modules/collaborationModule/ids';
import type { ConceptDefinitionStoreObject, FieldDimensionStoreObject, WorkflowFieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { getFirstIntentionStatus } from 'yooi-modules/modules/conceptModule';
import {
  Concept_Name,
  ConceptDefinition,
  ConceptDefinition_Name,
  FieldDimension_Field,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  Group,
  User,
  Workflow,
  Workflow_Name,
  Workflow_TargetedConceptDefinition,
  WorkflowEntry,
  WorkflowEntry_Rank,
  WorkflowEntry_Role_Concept,
  WorkflowEntry_Role_Workflow,
  WorkflowField,
  WorkflowField_Workflow,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareProperty, compareRank, compareString, comparing, filterNullOrUndefined, joinObjects, pushUndefinedToEnd, ranker, textToRichText } from 'yooi-utils';
import Checkbox from '../../../components/atoms/Checkbox';
import Icon, { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import Tooltip from '../../../components/atoms/Tooltip';
import Typo from '../../../components/atoms/Typo';
import Banner, { BannerVariant } from '../../../components/molecules/Banner';
import Chooser from '../../../components/molecules/Chooser';
import MasterDetailList from '../../../components/molecules/MasterDetailList';
import { NavigationTitlePathElements } from '../../../components/molecules/NavigationTitle';
import OverflowMenu from '../../../components/molecules/OverflowMenu';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SearchAndSelectMultiple from '../../../components/molecules/SearchAndSelectMultiple';
import SpacingLine from '../../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../../components/molecules/Table';
import TableCell, { TableCellAlign } from '../../../components/molecules/TableCell';
import TableLine from '../../../components/molecules/TableLine';
import BaseLayout from '../../../components/templates/BaseLayout';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../components/templates/BlockTitle';
import DataTable from '../../../components/templates/DataTable';
import Header from '../../../components/templates/Header';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import LeftPanel from '../../../components/templates/LeftPanel';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useAcl from '../../../store/useAcl';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { safeSessionStorageValue } from '../../../utils/sessionStorageUtils';
import { formatOrUndef } from '../../../utils/stringUtils';
import useDeleteModal from '../../../utils/useDeleteModal';
import useNavigation, { NavigationElementContainer } from '../../../utils/useNavigation';
import { createConceptDefinition } from '../../_global/conceptDefinitionUtils';
import { getInlineCreationBuilder } from '../../_global/conceptUtils';
import type { FilterConfiguration } from '../../_global/filter/useFilterSessionStorage';
import HeaderFromStore from '../../_global/HeaderFromStore';
import StoreTextInputField from '../../_global/input/StoreTextInputField';
import { searchFilterFunction } from '../../_global/listFilterFunctions';
import {
  defaultOptionComparator,
  getChipOptions,
  getChipOptionWithUnknown,
  getModelTypeInstances,
  getModelTypeOptions,
  getSearchChipOptions,
  getUnknownChip,
} from '../../_global/modelTypeUtils';
import type { NavigationFilter } from '../../_global/navigationUtils';
import { duplicateIntention, getIntentionGroups, getIntentionUsers } from '../../_global/rightPanel/collaboration/utils/collaborationUtils';
import TopBar from '../../_global/topBar/TopBar';
import useFilterAndSort from '../../_global/useFilterAndSort';
import WorkflowDetailPageTransitionBlock from '../../_global/WorkflowDetailPageTransitionBlock';

interface IntentionDetailProps {
  intentionId: string,
  navigationElement: string,
}

const IntentionDetail: FunctionComponent<IntentionDetailProps> = ({ intentionId, navigationElement }) => {
  const store = useStore();
  const { canCreateObject } = useAcl();
  const navigation = useNavigation<NavigationFilter>();
  const intention = store.getObject<IntentionStoreObject>(intentionId);

  const filterId = Intention;
  const [search, setSearch] = useState<string | undefined>(undefined);
  const filters = safeSessionStorageValue<FilterConfiguration | undefined>(filterId);
  const intentionFilterFunction = searchFilterFunction<Record<string, unknown>>(store, [filters?.nameSearch, search], [Intention_Name]);
  const [showInlineCreation, setShowInlineCreation] = useState(false);
  const { generateList } = useFilterAndSort(
    filterId,
    store.getObject(Intention)
      .navigateBack<IntentionStoreObject>(Class_Instances)
      .map((i) => ({
        key: i.id,
        name: i[Intention_Name],
        description: i[Intention_Description],
        url: `/settings/collaboration/${i.id}`,
      })),
    intentionFilterFunction ? (item) => intentionFilterFunction(item) : undefined,
    {
      getComparatorHandler: (_, direction) => ({
        comparator: comparing(compareString, direction === TableSortDirection.desc),
        extractValue: (item) => item.name,
      }),
      initial: { key: 'name', direction: TableSortDirection.asc },
    },
    undefined,
    [search]
  );

  const [doDelete, deleteModal] = useDeleteModal({
    doDelete: () => {
      store.deleteObject(intentionId);
      navigation.push(navigationElement, { pathname: '/settings/collaboration' });
    },
    shouldConfirm: () => true,
    getModalProps: () => ({
      title: i18n`Are you sure that you want to delete this intention?`,
      content: (
        <Typo>{i18n`All collaborations with this intention will be automatically closed, but will remain accessible via the panel.`}</Typo>
      ),
    }),
  });
  const workflowId = intention[Intention_Workflow];
  const workflow = workflowId ? store.getObjectOrNull(workflowId) : null;
  const workflowTargetedConceptDefinition = workflow ? workflow.navigateOrNull(Workflow_TargetedConceptDefinition) ?? undefined : undefined;
  const workflowEntries = workflowId ? store.withAssociation(WorkflowEntry)
    .withRole(WorkflowEntry_Role_Workflow, workflowId)
    .withExternalRole(WorkflowEntry_Role_Concept)
    .list() : [];

  let conceptDefinitions: string[] = [];
  if (workflow) {
    const workflowFields = workflow.navigateBack(WorkflowField_Workflow)
      .filter((field): field is WorkflowFieldStoreObject => isInstanceOf(field, WorkflowField)).map((o) => o.id);
    conceptDefinitions = store.withAssociation(FieldDimensionTypes)
      .list()
      .filter((el) => workflowFields.includes(el.navigateRole<FieldDimensionStoreObject>(FieldDimensionTypes_Role_FieldDimension)[FieldDimension_Field]))
      .map((o) => o.navigateRole<FieldDimensionStoreObject>(FieldDimensionTypes_Role_ConceptDefinition)[ConceptDefinition_Name] as string);
  }
  const computeWorkflowInstanceOptions = () => {
    const associatedConceptIds = new Set<string>(workflowEntries.map((workflowEntry) => workflowEntry.role(WorkflowEntry_Role_Concept)));
    if (workflow && workflow[Workflow_TargetedConceptDefinition] && workflow.navigateOrNull(Workflow_TargetedConceptDefinition)) {
      return getModelTypeOptions(store, workflow[Workflow_TargetedConceptDefinition] as string)
        .filter(({ id }) => !associatedConceptIds.has(id));
    } else {
      return [];
    }
  };

  const intentionDescription = intention ? intention[Intention_Description] as string : undefined;
  const workflowInstances = ranker.decorateList(
    workflowEntries
      .map((workflowEntry) => ({
        key: workflowEntry.object.id.join('|'),
        id: workflowEntry.object.id,
        statusId: workflowEntry.role(WorkflowEntry_Role_Concept),
        rank: workflowEntry.object[WorkflowEntry_Rank] as string,
      }))
      .sort(compareProperty('rank', compareRank)),
    ({ rank }) => rank
  );

  const availableOptions = [
    {
      key: 'all',
      name: i18n`All concepts`,
    },
    {
      key: 'all_except',
      name: i18n`All concepts except`,
    },
    {
      key: 'only',
      name: i18n`Only`,
    },
  ];

  return (
    <NavigationElementContainer
      element={{
        key: intentionId,
        name: formatOrUndef(intention[Intention_Name]),
        element: NavigationTitlePathElements.collaboration,
        path: `/settings/collaboration/${intentionId}`,
      }}
    >
      <BaseLayout
        topBar={(<TopBar />)}
        leftPanel={(
          <LeftPanel>
            <MasterDetailList
              list={
                generateList().list
                  .map(({ item, ...entry }) => (
                    joinObjects(
                      entry,
                      {
                        render: () => (
                          <Tooltip title={formatOrUndef(item.name)}>
                            <Typo maxLine={1}>{formatOrUndef(item.name)}</Typo>
                          </Tooltip>
                        ),
                        to: () => ({ pathname: item.url }),
                      }
                    )
                  ))
              }
              search={{ value: search, setValue: setSearch }}
            />
          </LeftPanel>
        )}
        header={(
          <Header
            firstLine={(
              <HeaderFromStore
                instanceId={intentionId}
                propertyId={Intention_Name}
                placeholder={i18n`Click to edit name`}
                actions={[
                  (
                    <OverflowMenu
                      key="overflowMenu"
                      buttonVariant={IconOnlyButtonVariants.tertiary}
                      menuItems={[
                        {
                          key: 'duplicate',
                          name: i18n`Duplicate`,
                          icon: IconName.content_copy_outline,
                          onClick: () => duplicateIntention(store, intentionId),
                        },
                        {
                          key: 'delete',
                          name: i18n`Delete`,
                          icon: IconName.delete,
                          onClick: () => doDelete(),
                          danger: true,
                        },
                      ]}
                    />
                  ),
                ]}
              />
            )}
            withBottomBorder
          />
        )}
        content={(
          <>
            <VerticalBlock>
              <VerticalBlock asBlockContent withSeparation>
                <HorizontalBlock asBlockContent>
                  <BlockTitle title={i18n`Description`} />
                  <BlockContent
                    action={(
                      <SpacingLine>
                        <Icon
                          name={IconName.info}
                          colorVariant={IconColorVariant.info}
                          tooltip={i18n`When selecting an intention for a collaboration, users will see the description as a tooltip.`}
                        />
                      </SpacingLine>
                    )}
                  >
                    <StoreTextInputField
                      initialValue={intentionDescription}
                      onSubmit={(description) => store.updateObject(intention.id, { [Intention_Description]: description })}
                      placeholder={i18n`Add description`}
                    />
                  </BlockContent>
                </HorizontalBlock>
                <HorizontalBlock asBlockContent>
                  <BlockTitle title={i18n`Availability`} />
                  <BlockContent>
                    <Chooser
                      actions={availableOptions}
                      onClick={(index) => {
                        store.updateObject(intentionId, { [Intention_Available]: availableOptions[index].key });
                      }}
                      selectedIndexes={[availableOptions.findIndex(({ key }) => key === intention[Intention_Available])]}
                      noBoxShadow
                    />
                  </BlockContent>
                </HorizontalBlock>
                {intention[Intention_Available] && intention[Intention_Available] !== 'all' && (
                  <HorizontalBlock asBlockContent>
                    <BlockTitle title={i18n`Concept(s)`} />
                    <BlockContent>
                      <SearchAndSelectMultiple
                        placeholder={i18n`Add concept`}
                        selectedOptions={
                          store.withAssociation(IntentionConcepts)
                            .withRole(IntentionConcepts_Role_Intention, intentionId)
                            .list()
                            .map((assoc) => assoc.role(IntentionConcepts_Role_ConceptDefinition))
                            .map((entry) => getChipOptions(store, entry))
                            .filter(filterNullOrUndefined)
                        }
                        onDelete={(entry) => store.withAssociation(IntentionConcepts)
                          .withRole(IntentionConcepts_Role_Intention, intentionId)
                          .withRole(IntentionConcepts_Role_ConceptDefinition, entry.id)
                          .deleteObject()}
                        onSelect={(entry) => store.withAssociation(IntentionConcepts)
                          .withRole(IntentionConcepts_Role_Intention, intentionId)
                          .withRole(IntentionConcepts_Role_ConceptDefinition, entry.id)
                          .updateObject({})}
                        computeOptions={() => store.getObject(ConceptDefinition)
                          .navigateBack<ConceptDefinitionStoreObject>(Class_Instances)
                          .sort(compareProperty(ConceptDefinition_Name, comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString)))
                          .map(({ id: instanceId }) => getChipOptions(store, instanceId))
                          .filter(filterNullOrUndefined)}
                      />
                    </BlockContent>
                  </HorizontalBlock>
                )}
              </VerticalBlock>
              <VerticalBlock asBlockContent>
                <BlockTitle title={i18n`Workflow`} />
                <HorizontalBlock asBlockContent>
                  <BlockTitle
                    title={i18n`Use platform workflow`}
                  />
                  <BlockContent>
                    <SearchAndSelect
                      placeholder={i18n`Choose a workflow`}
                      clearable
                      selectedOption={workflowId ? getChipOptionWithUnknown(store, workflowId) : undefined}
                      onSelect={(w) => {
                        store.updateObject(intention.id, { [Intention_Workflow]: w?.id ?? null });
                        store.withAssociation(IntentionStatusEntries)
                          .withRole(IntentionStatusEntries_Role_Intention, intentionId).list().forEach((assoc) => store.deleteObject(assoc.object.id));
                      }}
                      computeOptions={() => store.getObject(Workflow)
                        .navigateBack(Class_Instances)
                        .map((w) => getChipOptions(store, w.id))
                        .filter(filterNullOrUndefined)}
                    />
                  </BlockContent>
                </HorizontalBlock>
                {conceptDefinitions.length > 0
                  && (
                    <HorizontalBlock asBlockContent>
                      <BlockContent padded>
                        <Banner
                          variant={BannerVariant.info}
                          title={i18n`This workflow is used by several concepts: ${conceptDefinitions.join(', ')}. Updating its statuses or transitions will affect all those concepts.`}
                          fullWidth
                        />
                      </BlockContent>
                    </HorizontalBlock>
                  )}
                {!workflow && (
                  <HorizontalBlock asBlockContent>
                    <BlockContent>
                      <Banner
                        variant={BannerVariant.info}
                        title={i18n`Don't have a workflow? Click here to create a new workflow.`}
                        actions={[{
                          key: 'createWorkflow',
                          icon: IconName.add,
                          title: i18n`Create a workflow`,
                          tooltip: !intention[Intention_Name] ? i18n`Intention name must be filled to create a new workflow.` : undefined,
                          disabled: !intention[Intention_Name],
                          onClick: () => {
                            const newWorkflow = store.createObject({ [Instance_Of]: Workflow, [Workflow_Name]: `${intention[Intention_Name]} Workflow` });
                            const newConceptDefinitionId = createConceptDefinition(store, `${intention[Intention_Name]} Status`);
                            store.updateObject(newWorkflow, { [Workflow_TargetedConceptDefinition]: newConceptDefinitionId });
                            store.withAssociation(IntentionStatusEntries)
                              .withRole(IntentionStatusEntries_Role_Intention, intentionId).list().forEach((assoc) => store.deleteObject(assoc.object.id));
                            store.updateObject(intention.id, { [Intention_Workflow]: newWorkflow ?? null });
                          },
                        }]}
                      />
                    </BlockContent>
                  </HorizontalBlock>
                )}
              </VerticalBlock>
              {workflow && (
                <VerticalBlock asBlockContent>
                  <BlockTitle title={i18n`Status`} variant={BlockTitleVariant.secondary} />
                  <DataTable
                    list={workflowInstances
                      .map(({ item: { key, id: workflowAssociationId, statusId }, moveUpRank, moveDownRank, i: index }) => {
                        const intentionStatusAssoc = store.withAssociation(IntentionStatusEntries)
                          .withRole(IntentionStatusEntries_Role_Intention, intentionId)
                          .withRole(IntentionStatusEntries_Role_Entry, statusId).getObjectOrNull();
                        return ({
                          key,
                          type: 'item',
                          item: {
                            index,
                            workflowAssociationId,
                            statusId,
                            moveUpRank,
                            moveDownRank,
                            intentionAssociationId: intentionStatusAssoc ? intentionStatusAssoc.id : undefined,
                            isIntentionClosedStatus: intentionStatusAssoc ? intentionStatusAssoc[IntentionStatusIsClosedStatus] : false,
                          },
                          color: undefined,
                        });
                      })}
                    columnsDefinition={[
                      {
                        name: i18n`Name`,
                        width: 90,
                        propertyId: Workflow_TargetedConceptDefinition,
                        cellRender: ({ workflowAssociationId, statusId, intentionAssociationId }) => (
                          <SearchAndSelect
                            selectedOption={getChipOptions(store, statusId) ?? getUnknownChip(statusId)}
                            computeOptions={computeWorkflowInstanceOptions}
                            onSelect={(option) => {
                              if (option) {
                                const currentObject = store.getObject(workflowAssociationId).asRawObject();
                                store.deleteObject(workflowAssociationId);
                                store.withAssociation(WorkflowEntry)
                                  .withRole(WorkflowEntry_Role_Workflow, workflowId as string)
                                  .withRole(WorkflowEntry_Role_Concept, option.id)
                                  .updateObject(currentObject);
                                if (intentionAssociationId) {
                                  store.deleteObject(intentionAssociationId);
                                  store.withAssociation(IntentionStatusEntries)
                                    .withRole(IntentionStatusEntries_Role_Intention, intentionId)
                                    .withRole(IntentionStatusEntries_Role_Entry, option.id)
                                    .updateObject({ [IntentionStatusIsClosedStatus]: false });
                                }
                              }
                            }}
                            getInlineCreation={workflowTargetedConceptDefinition ? () => ({
                              type: 'inline',
                              onCreate: (title) => {
                                const newId = store.createObject({
                                  [Instance_Of]: workflowTargetedConceptDefinition.id,
                                  [Concept_Name]: textToRichText(title),
                                });

                                const currentObject = store.getObject(workflowAssociationId).asRawObject();
                                store.deleteObject(workflowAssociationId);
                                store.withAssociation(WorkflowEntry)
                                  .withRole(WorkflowEntry_Role_Workflow, workflowId as string)
                                  .withRole(WorkflowEntry_Role_Concept, newId)
                                  .updateObject(currentObject);

                                if (intentionAssociationId) {
                                  store.deleteObject(intentionAssociationId);
                                  store.withAssociation(IntentionStatusEntries)
                                    .withRole(IntentionStatusEntries_Role_Intention, intentionId)
                                    .withRole(IntentionStatusEntries_Role_Entry, newId)
                                    .updateObject({ [IntentionStatusIsClosedStatus]: false });
                                }

                                return newId;
                              },
                            }) : undefined}
                            placeholder={i18n`Select step`}
                            statusIcon={
                              !workflowTargetedConceptDefinition || !isInstanceOf(store.getObjectOrNull(statusId), workflowTargetedConceptDefinition.id)
                                ? { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Selected step is not of the expected type.` } : undefined
                            }
                          />
                        ),
                      },
                      {
                        name: 'Is closure status',
                        propertyId: 'isIntentionClosedStatus',
                        width: 10,
                        align: TableCellAlign.center,
                        cellRender: ({ index, intentionAssociationId, isIntentionClosedStatus, statusId }) => {
                          let tooltip;
                          if (!store.getObjectOrNull(statusId)) {
                            tooltip = i18n`Unknown status cannot be defined as closure status.`;
                          } else if (getFirstIntentionStatus(store, intentionId) === statusId) {
                            tooltip = i18n`First status cannot be defined as closure status as it will be the status when a new collaboration is created.`;
                          }
                          return (
                            tooltip
                              ? (
                                <Tooltip title={tooltip}>
                                  <Checkbox
                                    checked={false}
                                    disabled
                                  />
                                </Tooltip>
                              )
                              : (
                                <Checkbox
                                  checked={index !== 0 && isIntentionClosedStatus as boolean}
                                  disabled={index === 0}
                                  onChange={(value) => {
                                    if (intentionAssociationId) {
                                      store.updateObject(intentionAssociationId, { [IntentionStatusIsClosedStatus]: value ?? false });
                                    } else {
                                      store.withAssociation(IntentionStatusEntries)
                                        .withRole(IntentionStatusEntries_Role_Intention, intentionId)
                                        .withRole(IntentionStatusEntries_Role_Entry, statusId)
                                        .updateObject({ [IntentionStatusIsClosedStatus]: value ?? false });
                                    }
                                  }}
                                />
                              )
                          );
                        },
                      },
                      {
                        propertyId: 'move-up',
                        action: true,
                        align: TableCellAlign.center,
                        cellRender: ({ index, workflowAssociationId, intentionAssociationId, statusId, moveUpRank }) => (
                          <IconOnlyButton
                            onClick={() => {
                              if (index === 1) {
                                if (intentionAssociationId) {
                                  store.updateObject(intentionAssociationId, { [IntentionStatusIsClosedStatus]: false });
                                } else {
                                  store.withAssociation(IntentionStatusEntries)
                                    .withRole(IntentionStatusEntries_Role_Intention, intentionId)
                                    .withRole(IntentionStatusEntries_Role_Entry, statusId)
                                    .updateObject({ [IntentionStatusIsClosedStatus]: false });
                                }
                              }
                              store.updateObject(workflowAssociationId, { [WorkflowEntry_Rank]: moveUpRank() });
                            }}
                            disabled={index === 0}
                            iconName={IconName.expand_less}
                            tooltip={i18n`Move Up`}
                            variant={IconOnlyButtonVariants.tertiary}
                          />
                        ),
                      },
                      {
                        propertyId: 'move-down',
                        action: true,
                        align: TableCellAlign.center,
                        cellRender: ({ index, workflowAssociationId, moveDownRank }) => (
                          <IconOnlyButton
                            onClick={() => {
                              store.updateObject(workflowAssociationId, { [WorkflowEntry_Rank]: moveDownRank() });
                            }}
                            disabled={index === workflowInstances.length - 1}
                            iconName={IconName.expand_more}
                            tooltip={i18n`Move Down`}
                            variant={IconOnlyButtonVariants.tertiary}
                          />
                        ),
                      },
                    ]}
                    linesActions={({ workflowAssociationId, intentionAssociationId }) => [
                      {
                        key: 'unlink',
                        name: i18n`Unlink step`,
                        icon: IconName.delete,
                        onClick: () => {
                          store.deleteObject(workflowAssociationId);
                          if (intentionAssociationId) {
                            store.deleteObject(intentionAssociationId);
                          }
                        },
                      },
                    ]}
                    newItemIcon={IconName.add}
                    newItemTitle={i18n`Add`}
                    onNewItem={showInlineCreation ? undefined : () => setShowInlineCreation(true)}
                    handleClickAway={(isEscape) => {
                      if (isEscape) {
                        setShowInlineCreation(false);
                      }
                    }}
                    inlineCreation={{
                      render: showInlineCreation ? (
                        <TableLine>
                          <TableCell>
                            <SearchAndSelect
                              computeOptions={computeWorkflowInstanceOptions}
                              onSelect={(value) => {
                                if (value) {
                                  const association = store.withAssociation(WorkflowEntry)
                                    .withRole(WorkflowEntry_Role_Workflow, workflowId as string)
                                    .withRole(WorkflowEntry_Role_Concept, value.id);
                                  if (!association.getObjectOrNull()) {
                                    association.updateObject({ [WorkflowEntry_Rank]: workflowInstances.insertAfterLastItemRank() });
                                  }
                                  store.withAssociation(IntentionStatusEntries)
                                    .withRole(IntentionStatusEntries_Role_Intention, intentionId)
                                    .withRole(IntentionStatusEntries_Role_Entry, value.id)
                                    .updateObject({ [IntentionStatusIsClosedStatus]: false });
                                  setShowInlineCreation(false);
                                }
                              }}
                              getInlineCreation={workflowTargetedConceptDefinition && canCreateObject(workflowTargetedConceptDefinition.id) ? () => ({
                                type: 'inline',
                                onCreate: (title) => {
                                  const newId = store.createObject({
                                    [Instance_Of]: workflowTargetedConceptDefinition.id,
                                    [Concept_Name]: textToRichText(title),
                                  });
                                  store.withAssociation(WorkflowEntry)
                                    .withRole(WorkflowEntry_Role_Workflow, workflowId as string)
                                    .withRole(WorkflowEntry_Role_Concept, newId)
                                    .updateObject({ [WorkflowEntry_Rank]: workflowInstances.insertAfterLastItemRank() });
                                  store.withAssociation(IntentionStatusEntries)
                                    .withRole(IntentionStatusEntries_Role_Intention, intentionId)
                                    .withRole(IntentionStatusEntries_Role_Entry, newId)
                                    .updateObject({ [IntentionStatusIsClosedStatus]: false });

                                  setShowInlineCreation(false);
                                  return newId;
                                },
                              }) : undefined}
                              editOnMount
                            />
                          </TableCell>
                          <TableCell />
                          <TableCell />
                          <TableCell />
                          <TableCell />
                        </TableLine>
                      ) : null,
                    }}
                  />
                </VerticalBlock>
              )}
              {workflow && (
                <VerticalBlock asBlockContent withSeparation>
                  <WorkflowDetailPageTransitionBlock workflowId={workflowId as string} displayInfo={false} titleVariant={BlockTitleVariant.secondary} />
                </VerticalBlock>
              )}
              <VerticalBlock asBlockContent>
                <BlockTitle title={i18n`Assignation`} subtitle={i18n`These users and groups will be automatically added to the subscribers of the collaboration.`} />
                <HorizontalBlock asBlockContent>
                  <BlockTitle
                    title={i18n`User(s)`}
                  />
                  <BlockContent>
                    <SearchAndSelectMultiple
                      placeholder={i18n`Add user`}
                      selectedOptions={
                        getIntentionUsers(store, intentionId)
                          .map((entry) => getChipOptions(store, entry)).filter(filterNullOrUndefined)
                          .sort(defaultOptionComparator)
                      }
                      computeOptions={() => {
                        const users = getModelTypeInstances(store, User).map(({ id }) => id);
                        return users.map((id) => getChipOptions(store, id)).filter(filterNullOrUndefined).sort(defaultOptionComparator);
                      }}
                      searchOptions={getSearchChipOptions(store, User)}
                      onSelect={({ id }) => store.withAssociation(IntentionUsers)
                        .withRole(IntentionUsers_Role_Intention, intentionId)
                        .withRole(IntentionUsers_Role_User, id)
                        .updateObject({})}
                      onDelete={({ id }) => store.withAssociation(IntentionUsers)
                        .withRole(IntentionUsers_Role_Intention, intentionId)
                        .withRole(IntentionUsers_Role_User, id).deleteObject()}
                      getInlineCreation={
                        canCreateObject(User)
                          ? getInlineCreationBuilder(
                            store,
                            User,
                            (id) => store.withAssociation(IntentionUsers)
                              .withRole(IntentionUsers_Role_Intention, intentionId)
                              .withRole(IntentionUsers_Role_User, id)
                              .updateObject({})
                          )
                          : undefined
                      }
                    />
                  </BlockContent>
                </HorizontalBlock>
                <HorizontalBlock asBlockContent>
                  <BlockTitle
                    title={i18n`Group(s)`}
                  />
                  <BlockContent>
                    <SearchAndSelectMultiple
                      placeholder={i18n`Add group`}
                      selectedOptions={
                        getIntentionGroups(store, intentionId)
                          .map((entry) => getChipOptions(store, entry)).filter(filterNullOrUndefined)
                          .sort(defaultOptionComparator)
                      }
                      computeOptions={() => {
                        const groups = getModelTypeInstances(store, Group).map(({ id }) => id);
                        return groups.map((id) => getChipOptions(store, id)).filter(filterNullOrUndefined).sort(defaultOptionComparator);
                      }}
                      searchOptions={getSearchChipOptions(store, Group)}
                      onSelect={({ id }) => store.withAssociation(IntentionGroups)
                        .withRole(IntentionGroups_Role_Intention, intentionId)
                        .withRole(IntentionGroups_Role_Group, id)
                        .updateObject({})}
                      onDelete={({ id }) => store.withAssociation(IntentionGroups)
                        .withRole(IntentionGroups_Role_Intention, intentionId)
                        .withRole(IntentionGroups_Role_Group, id).deleteObject()}
                      getInlineCreation={
                        canCreateObject(Group)
                          ? getInlineCreationBuilder(
                            store,
                            Group,
                            (id) => store.withAssociation(IntentionGroups)
                              .withRole(IntentionGroups_Role_Intention, intentionId)
                              .withRole(IntentionGroups_Role_Group, id)
                              .updateObject({})
                          )
                          : undefined
                      }
                    />
                  </BlockContent>
                </HorizontalBlock>
              </VerticalBlock>
            </VerticalBlock>
            {deleteModal}
          </>
        )}
      />
    </NavigationElementContainer>
  );
};

export default IntentionDetail;
