import type { FunctionComponent } from 'react';
import ScrollableWidgetContainer from '../../../../components/templates/ScrollableWidgetContainer';
import { remToPx } from '../../../../utils/sizeUtils';
import useWidgetSizeContext from '../../../../utils/useWidgetSizeContext';
import SubscriptionTable from '../../SubscriptionTable';

const SubscriptionsWidget: FunctionComponent = () => {
  const { width, height } = useWidgetSizeContext();

  const WIDGET_PADDING = 1.2 + 0.4;
  const LINE_HEIGHT = 3.9;
  const HEADER_HEIGHT = 7.4;
  const SCROLL_HEIGHT = 0.8;
  const PAGINATION_HEIGHT = 5.4;
  let numberOfItemsPerPage = Math.floor((height - remToPx(WIDGET_PADDING + HEADER_HEIGHT + PAGINATION_HEIGHT + SCROLL_HEIGHT)) / remToPx(LINE_HEIGHT));
  if (numberOfItemsPerPage < 1) {
    numberOfItemsPerPage = 1;
  }

  return (
    <ScrollableWidgetContainer width={width} height={height}>
      <SubscriptionTable numberOfItemsPerPage={numberOfItemsPerPage} />
    </ScrollableWidgetContainer>
  );
};

export default SubscriptionsWidget;
