import type { FunctionComponent } from 'react';
import type { StakeholdersFieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { isEmbeddedConceptInstance } from 'yooi-modules/modules/conceptModule';
import { ConceptRole, StakeholdersField_TargetType } from 'yooi-modules/modules/conceptModule/ids';
import { joinObjects } from 'yooi-utils';
import BlockContent from '../../../../components/templates/BlockContent';
import useAcl from '../../../../store/useAcl';
import useStore from '../../../../store/useStore';
import BlockField from '../_global/BlockField';
import type { BlockFieldProps } from '../FieldLibraryTypes';
import ConceptListDisplay from './ConceptListDisplay';
import EmbeddedStakeholdersRenderer from './EmbeddedStakeholdersRenderer';
import StakeholdersFieldUserGroupBlockRenderer from './StakeholdersFieldUserGroupBlockRenderer';

interface StakeholdersBlockFieldRendererProps {
  fieldId: string,
  dimensions: { n1InstanceId: string },
  blockFieldProps: BlockFieldProps,
}

const StakeholdersBlockFieldRenderer: FunctionComponent<StakeholdersBlockFieldRendererProps> = ({ fieldId, dimensions, blockFieldProps }) => {
  const store = useStore();
  const { canAssignStakeholders } = useAcl();

  const canAssign = canAssignStakeholders(dimensions.n1InstanceId);
  const targetTypeId = store.getObject<StakeholdersFieldStoreObject>(fieldId)[StakeholdersField_TargetType];

  const conceptInstance = store.getObjectOrNull(dimensions.n1InstanceId);
  const isEmbeddedConcept = conceptInstance !== null && isEmbeddedConceptInstance(conceptInstance);

  if (targetTypeId && isEmbeddedConcept) {
    return (
      <BlockField
        fieldId={fieldId}
        isVertical
        {...blockFieldProps}
      >
        <BlockContent padded>
          <EmbeddedStakeholdersRenderer instanceId={conceptInstance.id} targetType={targetTypeId} />
        </BlockContent>
      </BlockField>
    );
  } else if (targetTypeId === ConceptRole) {
    return (
      <ConceptListDisplay
        fieldId={fieldId}
        conceptId={dimensions.n1InstanceId}
        blockFieldProps={joinObjects(blockFieldProps, { readOnly: !canAssign })}
        lineContext={(instance) => [dimensions.n1InstanceId, fieldId, instance.id]}
      />
    );
  } else {
    return (
      <BlockField
        fieldId={fieldId}
        isVertical
        {...blockFieldProps}
      >
        <StakeholdersFieldUserGroupBlockRenderer
          fieldId={fieldId}
          dimensions={dimensions}
          readOnly={!canAssign}
        />
      </BlockField>
    );
  }
};

export default StakeholdersBlockFieldRenderer;
