import type { FunctionComponent } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { User_IsEnabled } from 'yooi-modules/modules/conceptModule/ids';
import TooltipRender from '../components/atoms/TooltipRender';
import Toaster from '../components/templates/Toaster';
import NetworkStoreContextProvider from '../store/NetworkStoreContextProvider';
import useAuth from '../store/useAuth';
import useStore, { StoreProvider } from '../store/useStore';
import { doFetch } from '../utils/fetchUtils';
import { CollaborationPanelContextProvider } from '../utils/useCollaborationContext';
import { IFrameContextProvider } from './_global/fields/iFrameField/useIFrameContext';
import LeftBar from './_global/LeftBar';
import AppRouter from './AppRouter';
import AutoLogout from './AutoLogout';
import ErrorBoundary from './error/ErrorBoundary';
import Login from './login/Login';
import RootRouter from './RootRouter';

const RouterComponent: FunctionComponent = () => {
  const store = useStore();
  const { loggedUserId } = useAuth();
  const location = useLocation();

  if (!store.getObjectOrNull(loggedUserId)?.[User_IsEnabled]) {
    // If user is disabled, force the reload of the page to redirect to login
    window.location.reload();
  }

  useEffect(() => {
    // sends user navigation logs to report lambda function
    doFetch('/platform/report', {
      method: 'POST',
      json: { type: 'navigation', path: `${location.pathname}${location.hash ? `#${location.hash}` : ''}` },
    });
  }, [location]);

  return (
    <AppRouter routes={RootRouter} />
  );
};

const Root: FunctionComponent = () => (
  <NetworkStoreContextProvider
    renderUnauthenticated={(onLoginSucceeded) => (<Login onLoginSucceeded={onLoginSucceeded} />)}
    renderLoaded={() => (
      <StoreProvider>
        <IFrameContextProvider>
          <Toaster />
          <LeftBar />
          <ErrorBoundary>
            <CollaborationPanelContextProvider>
              <TooltipRender />
              <AutoLogout />
              <RouterComponent />
            </CollaborationPanelContextProvider>
          </ErrorBoundary>
        </IFrameContextProvider>
      </StoreProvider>
    )}
  />
);

export default Root;
