import type { ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import { isStoreObject, ValidationStatus } from 'yooi-store';
import { isRichText, joinObjects, newError, richTextToText } from 'yooi-utils';
import { CommonAsType } from '../../../common/fields/commonPropertyType';
import type { AsPropertyBusinessRuleRegistration, GetDslFieldHandler, UpdateOperationHandlers } from '../../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../../common/typeErrorUtils';
import type { BusinessRuleRegistration } from '../../../common/types/TypeModuleDslType';
import { PropertyMandatoryType } from '../../../common/types/TypeModuleDslType';
import { Instance_Of, ModelType } from '../../../typeModule/ids';
import { formatOrUndef, preventComputedFieldUpdate, validateFieldIdAsProperty, validateIntegrationOnlyPropertyUpdate } from '../../common/commonFieldUtils';
import { Concept_Name, ConceptDefinition_RestrictedAccess, Field_IntegrationOnly, RelationMultipleField_ReverseField, RelationSingleField_TargetType } from '../../ids';
import type { ConceptStoreObject } from '../../model';
import { registerField } from '../../module';
import type { Filters, PathStep } from '../../moduleType';
import { PathStepType } from '../../moduleType';
import type { ConceptReference, DimensionsMapping, MultipleParameterValue, ParametersMapping, ResolutionStack, SingleParameterValue } from '../../utils';
import {
  conceptRefApiSchema,
  createValuePathResolver,
  FILTER_PARAMETER_CURRENT,
  getFilterFunction,
  getInstanceLabel,
  getSingleRelationFieldExportColumnHeaders,
  getValueProxy,
  InstanceReferenceType,
  isConceptValid,
  isSingleValueResolution,
  isValueResolutionOfType,
  ParsedDimensionType,
  parseDimensionMapping,
  resolveFieldValue,
  segregateParametersMapping,
  toConceptReference,
} from '../../utils';
import { conceptType } from '../../utils/formula/modelFunctions';
import type { SingleRelationFieldExportConfiguration } from '../../utils/relationFieldUtils';
import type { RelationSingleField } from '../types';
import { isRelationFieldStorageValueValid } from './utils';

const cannotCreateRelationSingleOnRestrictedConcept: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  if (properties && !getObjectOrNull(id[0])) {
    const sourceConcept = getObjectOrNull(properties[RelationSingleField_TargetType] as string);
    if (sourceConcept?.[ConceptDefinition_RestrictedAccess]) {
      return { rule: 'field.relationSingleField.cannotCreateRelationSingleOnRestrictedConcept', status: ValidationStatus.REJECTED };
    }
  }
  return undefined;
};

const syncIntegrationOnly: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  if (!properties) {
    // deletion don't care, reverse will be deleted
    return undefined;
  }
  const relationSingle = getObjectOrNull(id[0]);
  if (!relationSingle) {
    // creation, nothing to do, need to wait for the creation of the association 1-n field
    return undefined;
  } else if (properties[Field_IntegrationOnly] !== undefined) {
    // update integration only
    const relationMultipleField = relationSingle.navigateBack(RelationMultipleField_ReverseField)[0];
    if (!relationMultipleField) {
      // should never happen, no reverse field
      return undefined;
    }
    if (Boolean(relationMultipleField[Field_IntegrationOnly]) !== Boolean(properties[Field_IntegrationOnly])) {
      // Only update if necessary, or we will have an infinite event loop (we use boolean because integration only can be undefined / null / false)
      return {
        rule: 'relationSingleField.syncIntegrationOnly',
        status: ValidationStatus.ACCEPTED,
        generateSystemEvent: ({ updateObject }) => {
          updateObject(relationMultipleField.id, {
            [Field_IntegrationOnly]: Boolean(properties[Field_IntegrationOnly]),
          });
        },
      };
    }
  }
  return undefined;
};

const checkRelationSingleField: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  if (!properties) {
    return undefined;
  }
  const object = getObjectOrNull(id)?.asRawObject() ?? {};
  const allProperties = joinObjects(object, properties);
  const targetType = allProperties[RelationSingleField_TargetType];
  if (!targetType) {
    return { rule: 'field.relationSingleField.hasTargetType', status: ValidationStatus.REJECTED };
  }
  return { rule: 'field.relationSingleField.checkRelationSingleField', status: ValidationStatus.ACCEPTED };
};

const checkValueIsTheIdOfAValidConcept: AsPropertyBusinessRuleRegistration = (store, propertyId) => (_, { properties }) => {
  if (!properties || properties[propertyId] == null) {
    return undefined;
  }
  if (!isRelationFieldStorageValueValid(store, propertyId, properties[propertyId])) {
    return { rule: 'field.relationSingleField.invalidValue', status: ValidationStatus.REJECTED };
  }
  return { rule: 'field.relationSingleField.conceptIsValid', status: ValidationStatus.ACCEPTED };
};

const getValueResolution = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack) => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
  if (isValueResolutionOfType(valueResolution, (value): value is StoreObject | undefined => value === undefined || isStoreObject(value))) {
    return valueResolution;
  } else {
    return {
      value: undefined,
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['StoreObject', 'undefined'], typeof valueResolution.value),
      getDisplayValue: () => undefined,
      isTimeseries: valueResolution.isTimeseries,
    };
  }
};

interface RelationSingleUpdateOperationTypes {
  INITIALIZE: { type: 'value', value: string | undefined } | { type: 'path', path: PathStep[] },
  REPLACE: { type: 'value', value: string | undefined } | { type: 'path', path: PathStep[] },
  CLEAR: undefined,
}

export interface RelationSingleFieldStepConfiguration {
  filters?: Filters,
}

type RelationSingleFieldHandler = GetDslFieldHandler<
  RelationSingleField,
  string | undefined,
  string | null,
  StoreObject | undefined,
  StoreObject | undefined,
  ConceptReference | undefined,
  undefined,
  RelationSingleUpdateOperationTypes,
  RelationSingleFieldStepConfiguration,
  SingleRelationFieldExportConfiguration
>;
export const relationSingleFieldHandler: RelationSingleFieldHandler = registerField({
  model: {
    label: 'RelationSingleField',
    title: 'Association many to one (n-1)',
    withApiAlias: true,
    properties: [{ label: 'Field_TargetFilter', as: CommonAsType.Filters }],
    relations: [{ label: 'TargetType', targetTypeId: ModelType, reverseLabel: 'ModelType_TargetedByRelationSingleFields', mandatory: { type: PropertyMandatoryType.mandatory } }],
    businessRules: [
      checkRelationSingleField,
      cannotCreateRelationSingleOnRestrictedConcept,
      syncIntegrationOnly,
    ],
    asPropertyBusinessRules: [
      checkValueIsTheIdOfAValidConcept,
      validateIntegrationOnlyPropertyUpdate('relationSingleField'),
      validateFieldIdAsProperty('relationSingleField'),
      preventComputedFieldUpdate('relationSingleField'),
    ],
  },
  handler: (objectStore, fieldId) => {
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => {
      const targetInstance = getValueResolution(objectStore, fieldId, dimensionsMapping).value;
      if (targetInstance) {
        return formatOrUndef(getInstanceLabel(objectStore, targetInstance));
      } else {
        return undefined;
      }
    };

    const extractOperationValueObjectId = (
      parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
      operationValue: { type: 'value', value: string | undefined } | { type: 'path', path: PathStep[] }
    ): string | undefined => {
      if (operationValue.type === 'path') {
        const { path } = operationValue;
        const resolution = createValuePathResolver(objectStore, parametersMapping).resolvePathValue(path);
        if (isSingleValueResolution(resolution) && isStoreObject(resolution.value) && isConceptValid(objectStore, resolution.value.id)) {
          return resolution.value.id;
        } else {
          return undefined;
        }
      } else {
        return operationValue.value;
      }
    };

    const getTargetType = () => objectStore.getObject(fieldId).navigate(RelationSingleField_TargetType);

    return {
      describe: () => ({ hasData: true, returnType: conceptType(getTargetType().id), timeseriesMode: 'none' }),
      restApi: {
        returnTypeSchema: conceptRefApiSchema,
        formatValue: (value, canRead) => (value && canRead([value.id]) ? toConceptReference(value) : undefined),
      },
      getStoreValue: (dimensionsMapping) => {
        const parsedDimensions = parseDimensionMapping(dimensionsMapping);
        if (parsedDimensions.type === ParsedDimensionType.MonoDimensional) {
          return objectStore.getObject(parsedDimensions.objectId)[fieldId] as string | undefined;
        } else {
          return undefined;
        }
      },
      getValueWithoutFormula: (dimensionsMapping) => {
        const parsedDimensions = parseDimensionMapping(dimensionsMapping);
        if (parsedDimensions.type === ParsedDimensionType.MonoDimensional) {
          const target = objectStore.getObject(parsedDimensions.objectId).navigateOrNull(fieldId);
          if (target && isConceptValid(objectStore, target.id)) {
            return target;
          } else {
            return undefined;
          }
        } else {
          return undefined;
        }
      },
      getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
      updateValue: (dimensionsMapping, update) => {
        const parsedDimension = parseDimensionMapping(dimensionsMapping);
        if (parsedDimension.type !== ParsedDimensionType.MonoDimensional) {
          throw newError('RelationSingle field only support mono-dimensional absorbed values');
        }

        objectStore.updateObject(parsedDimension.objectId, { [fieldId]: update });
      },
      isEmpty: (dimensionsMapping) => !getValueResolution(objectStore, fieldId, dimensionsMapping).value,
      isSaneValue: () => ({ isValid: true }),
      getValueAsText,
      getExportColumnHeaders: getSingleRelationFieldExportColumnHeaders(objectStore),
      getExportValue: (dimensionsMapping, configuration) => {
        const { value } = getValueResolution(objectStore, fieldId, dimensionsMapping);
        if (!value) {
          return { format: 'string', value: undefined };
        }
        if (configuration?.type === 'uuid') {
          return { format: 'string', value: value?.id };
        } else {
          const defaultPath: PathStep[] = [
            { type: PathStepType.dimension, conceptDefinitionId: value[Instance_Of] as string },
            { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
            { type: PathStepType.field, fieldId: Concept_Name },
          ];
          const pathResolution = createValuePathResolver(objectStore, { [FILTER_PARAMETER_CURRENT]: { type: 'single', id: value?.id } })
            .resolvePathValue(configuration?.path ?? defaultPath);
          if (isSingleValueResolution(pathResolution)) {
            return { format: 'string', value: isRichText(pathResolution.value) ? richTextToText(pathResolution.value) : pathResolution.value as string };
          }
          return { format: 'string', value: undefined };
        }
      },
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else if (typeof prop === 'string') {
            const targetingInstance = getValueResolution(objectStore, fieldId, dimensionsMapping);
            if (targetingInstance && !targetingInstance.error && targetingInstance.value) {
              return getValueProxy(objectStore, targetingInstance.value.id, prop);
            } else {
              return undefined;
            }
          } else {
            return undefined;
          }
        },
      }),
      getTargetType,
      resolvePathStepConfiguration: (config) => {
        const resolveValue = (
          dimensionsMapping: DimensionsMapping,
          parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
          resolutionStack: ResolutionStack
        ) => {
          const { error, value } = getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack);
          if (error) {
            throw error;
          } else {
            const { filters } = config;
            if (filters && value !== undefined) {
              const { singleParametersMapping } = segregateParametersMapping(parametersMapping);
              const filterFunction = getFilterFunction(objectStore, filters);
              if (filterFunction) {
                return filterFunction(joinObjects(
                  singleParametersMapping,
                  { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: value.id } }
                )) ? value as ConceptStoreObject : undefined;
              }
            }
            return value;
          }
        };

        return ({
          hasData: true,
          timeseriesMode: 'none',
          getValueResolutionType: () => conceptType(getTargetType().id),
          resolveDimension: (dimensionsMapping, parametersMapping, resolutionStack) => {
            const value = resolveValue(dimensionsMapping, parametersMapping, resolutionStack);
            return { type: 'single', instance: value as ConceptStoreObject };
          },
          resolveValue,
        });
      },
      filterConditions: undefined,
      updateOperationHandlers: {
        INITIALIZE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            const fieldHandler = relationSingleFieldHandler(objectStore, fieldId);
            if (fieldHandler.getStoreValue(dimensionsMapping) !== undefined) {
              return;
            }
            const objectId = extractOperationValueObjectId(parametersMapping, value);
            if (objectId) {
              fieldHandler.updateValue(dimensionsMapping, objectId);
            }
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: undefined },
        },
        REPLACE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            const objectId = extractOperationValueObjectId(parametersMapping, value);
            relationSingleFieldHandler(objectStore, fieldId).updateValue(dimensionsMapping, objectId ?? null);
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: undefined },
        },
        CLEAR: {
          applyOperation: (dimensionsMapping) => {
            relationSingleFieldHandler(objectStore, fieldId).updateValue(dimensionsMapping, null);
          },
          sanitizeOperation: () => undefined,
        },
      } satisfies UpdateOperationHandlers<RelationSingleUpdateOperationTypes>,
    };
  },
  historyEventProducer: ({ getObjectOrNull }, fieldId) => ({
    value: {
      collectImpactedInstances: ({ id, properties }) => {
        if (
          properties?.[fieldId] !== undefined // A value is in the update for the current field
          || (properties === null && getObjectOrNull(id)?.[fieldId] !== undefined) // Object is deleted and store had a value for the field
        ) {
          return [id];
        } else {
          return [];
        }
      },
      getValue: (id) => {
        const object = getObjectOrNull(id);
        return ({ value: typeof getObjectOrNull(id)?.[fieldId] === 'string' ? object?.navigateOrNull(fieldId)?.id ?? null : null, version: 1 });
      },
      areValuesEquals: (value1, value2) => value1?.value === value2?.value,
    },
  }),
});
