import { ValidationStatus } from 'yooi-store';
import { checkObjectRankConflicts } from '../../common/businessRules';
import { asImport, CommonAsType } from '../../common/fields/commonPropertyType';
import { adminOnlyAcl } from '../../conceptModule';
import { Concept, ConceptDefinition_Name, WorkflowField } from '../../conceptModule/ids';
import { Block, Block_ConceptDefinition, Block_Name, FieldBlockDisplay, FieldBlockDisplay_Block, FieldBlockDisplay_Rank } from '../ids';
import { registerModel } from '../module';

const { association, type } = registerModel;

type({
  label: 'FieldBlockDisplay',
  accessControlList: {
    READ: () => () => ({ rule: 'fieldBlockDisplay.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'fieldBlockDisplay', 'WRITE'),
    DELETE: () => (_, objectId) => ({ rule: 'fieldBlockDisplay.delete.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: objectId }),
  },
  businessRules: [
    checkObjectRankConflicts(FieldBlockDisplay_Block, FieldBlockDisplay_Rank),
  ],
  objectDebugLabel: ({ getObject }) => (objectId) => {
    const block = getObject(objectId).navigateOrNull(FieldBlockDisplay_Block);
    const conceptDefinition = block?.navigateOrNull(Block_ConceptDefinition);
    return `FieldBlockDisplay of '${conceptDefinition?.[ConceptDefinition_Name]}' -> '${block?.[Block_Name]}'`;
  },
})
  .relation({ label: 'FieldBlockDisplay_Block', targetTypeId: Block, reverseLabel: 'Block_FieldDisplays' })
  .property({ label: 'FieldBlockDisplay_FieldPath', as: asImport('PathStep', 'modules/conceptModule', true) })
  .property({ label: 'FieldBlockDisplay_Rank', as: CommonAsType.string })
  .property({ label: 'FieldBlockDisplay_VisibleFromStatus', as: CommonAsType.string })
  .property({ label: 'FieldBlockDisplay_VisibleToStatus', as: CommonAsType.string })
  .property({ label: 'FieldBlockDisplay_ViewDisplayCondition', as: CommonAsType.Filters })
  .property({ label: 'FieldBlockDisplay_EditDisplayCondition', as: CommonAsType.Filters })
  .property({ label: 'FieldBlockDisplay_FieldDisplayConfiguration', as: asImport('FieldBlockDisplayOptions', 'modules/conceptLayoutModule/moduleType') })
  .property({ label: 'FieldBlockDisplay_TitleOverride', as: CommonAsType.string })
  .property({ label: 'FieldBlockDisplay_DocumentationOverride', as: asImport('DocumentationOverride', 'modules/conceptLayoutModule/moduleType') })
  .property({ label: 'FieldBlockDisplay_WorkflowFieldDisplayType', as: asImport('WorkflowRelationDisplayType', 'modules/conceptLayoutModule/moduleType') })
  .relation({ label: 'FieldBlockDisplay_WorkflowField', targetTypeId: WorkflowField, reverseLabel: 'WorkflowField_DisplayAsBlock' });

association({
  label: 'FieldBlockConceptInstanceDisplay',
  roles: [{ label: 'FieldBlockDisplay', targetTypeId: FieldBlockDisplay }, { label: 'Concept', targetTypeId: Concept }],
  accessControlList: {
    READ: () => () => ({ rule: 'fieldBlockConceptInstanceDisplay.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: () => () => ({ rule: 'fieldBlockConceptInstanceDisplay.write.allow', status: ValidationStatus.ACCEPTED }),
    DELETE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'fieldBlockConceptInstanceDisplay', 'DELETE'),
  },
})
  .property({ label: 'FieldBlockConceptInstanceDisplay_DisplayOverride', as: CommonAsType.boolean });
