import * as bcrypt from 'bcrypt-ts';

const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
export const isValidPassword = (password: string): boolean => passwordRegExp.test(password);

const passwordSaltLength = 11; // 2^11 = 2048 iterations

export const verifyPassword = (password: string, passwordHash: string): Promise<boolean> => bcrypt.compare(password, passwordHash);

export const hashPassword = (password: string): Promise<string> => bcrypt.hash(password, passwordSaltLength);

export const shouldUpgradePasswordHash = (passwordHash: string): boolean => bcrypt.getRounds(passwordHash) < passwordSaltLength;
