import type { Formula } from './model';
import type { InstanceReferenceValue } from './utils';

export interface Field {
  readonly id: string,
  readonly fieldDefinitionId: string,
  readonly title: string | undefined,
  readonly documentation: string | undefined,
  readonly isTitleHidden: boolean | undefined,
  readonly isDocumentationInline: boolean | undefined,
  readonly apiAlias: string | undefined,
  readonly formula: Formula | undefined,
  readonly integrationOnly: boolean | undefined,
  readonly isCore: boolean | undefined,
}

export enum PathStepType {
  global = 'global',
  dimension = 'dimension',
  filter = 'filter',
  mapping = 'mapping',
  multipleMapping = 'multipleMapping',
  field = 'field',
}

export interface GlobalDimensionStep {
  type: PathStepType.global,
  conceptDefinitionIds: string[],
}

export interface DimensionStep {
  type: PathStepType.dimension,
  conceptDefinitionId: string,
}

export enum FilterConditionOperators {
  AND = 'AND',
  OR = 'OR',
}

export enum FilterValueType {
  path = 'path',
  raw = 'raw',
}

export interface FilterValuePath {
  type: FilterValueType.path,
  path: PathStep[],
}

export interface FilterValueRaw<T> {
  type: FilterValueType.raw,
  raw: T,
}

export type FilterValue<T> = FilterValueRaw<T> | FilterValuePath;

export type Filters = FilterNode | FilterCondition;

export interface FilterCondition {
  leftValue?: PathStep[],
  operator?: string,
  rightValue?: FilterValue<unknown> | null,
}

export interface FilterNode {
  condition: FilterConditionOperators,
  children?: Filters[],
}

export interface FilterStep {
  type: PathStepType.filter,
  filters?: Filters,
}

export interface MultipleMappingStep {
  type: PathStepType.multipleMapping,
  id: string,
  filters?: Filters,
}

export interface MappingStep {
  type: PathStepType.mapping,
  mapping: InstanceReferenceValue,
}

export interface FieldStep {
  type: PathStepType.field,
  fieldId: string,
  embeddingFieldId?: string,
  workflowSubfieldId?: string,
  mapping?: Record<string, InstanceReferenceValue | undefined>,
}

export type PathStep = GlobalDimensionStep | DimensionStep | FilterStep | MappingStep | MultipleMappingStep | FieldStep;

export interface Path {
  steps: PathStep[],
}

export enum NumberColorStepValueType {
  value = 'value',
  field = 'field',
}

interface ColorStepNumberValue {
  type: NumberColorStepValueType.value,
  color: string | undefined,
  value: number | undefined,
}

interface ColorStepFieldValue {
  type: NumberColorStepValueType.field,
  color: string | undefined,
  value: PathStep[] | undefined,
}

export type NumberColorStepValue = ColorStepNumberValue | ColorStepFieldValue;

export interface NumberColorStepValueResolved {
  color: string | undefined,
  value: number | undefined,
}

export type NumberColorStepsValue = { id: string } & NumberColorStepValue;

export interface BooleanFieldValue {
  icon: string | undefined,
  color: string | undefined,
  tooltip: string | undefined,
}

export interface Width {
  type: 'percent' | 'rem',
  value: number,
}
