import type { FieldBlockDisplayOptions, FieldBlockDisplayStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import type {
  AssociationFilterStoreObject,
  ConceptDefinitionStoreObject,
  ConditionFilterStoreObject,
  EmbeddingFieldRaw,
  EmbeddingFieldStoreObject,
  Formula,
  MultipleRelationFieldExportConfiguration,
} from 'yooi-modules/modules/conceptModule';
import { embeddingFieldHandler, InvalidFieldError, ParsedDimensionType, parseDimensionMapping } from 'yooi-modules/modules/conceptModule';
import {
  Concept,
  ConceptDefinition,
  ConceptDefinition_ChipBackgroundColor,
  ConceptDefinition_Icon,
  ConceptRole,
  EmbeddingField,
  EmbeddingField_FromType,
  EmbeddingField_ToType,
  Field_ApiAlias,
  Field_Documentation,
  Field_Formula,
  Field_IntegrationOnly,
  Field_IsCore,
  Field_IsDocumentationInline,
  Field_Title,
  FieldBlockDisplay_ViewFilters,
  Group,
  KinshipRelation,
  User,
} from 'yooi-modules/modules/conceptModule/ids';
import type { ViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import { DataAsset } from 'yooi-modules/modules/dataAssetModule/ids';
import { Resource } from 'yooi-modules/modules/resourceModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareNumber, comparing, filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import { TableSortDirection } from '../../../../components/molecules/Table';
import i18n from '../../../../utils/i18n';
import { computeBorderColor, conceptDefinitionChipBackgroundColor } from '../../conceptDisplayUtils';
import { getInlineCreationBuilder } from '../../conceptUtils';
import { formatErrorForUser } from '../../errorUtils';
import { defaultOptionComparator, getChipOptions, getConceptDefinitionNameOrEntity } from '../../modelTypeUtils';
import ConceptChipList from '../_global/ConceptChipList';
import { getApiAliasInitialState, getDocumentationFieldEditionSection, getIntegrationFieldEditionSection } from '../_global/editionHandlerUtils';
import MultipleRelationExportConfiguration from '../_global/MultipleRelationExportConfiguration';
import { getViewsBlockDisplayOptionsHandler } from '../_global/viewsBlockFieldUtils';
import { defaultWidgetDisplay } from '../_global/widgetUtils';
import { duplicateFormula } from '../duplicationUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  duplicateFieldDimensionWithNewField,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
} from '../fieldDimensionUtils';
import type { FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, FieldComparatorHandler, GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldIntegrationOnlyDisabled } from '../FieldLibraryTypes';
import { ViewLoadingStateContextProvider } from '../viewsField/useViewLoadingState';
import ViewsGroupBlock from '../viewsField/ViewsGroupBlock';

interface EmbeddingFieldBlockDisplayOptions extends FieldBlockDisplayOptions {
  viewDefinitions?: ViewStoredDefinition[],
  withFilters?: boolean,
}

interface EmbeddingFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
  [EmbeddingField_ToType]: string | null | undefined,
  [Field_Formula]: Formula | null | undefined,
  [Field_IntegrationOnly]: boolean | null | undefined,
  [FieldIntegrationOnlyDisabled]: boolean | undefined,
}

type EmbeddingFieldDefinition = GetFieldDefinitionHandler<
  typeof embeddingFieldHandler,
  EmbeddingFieldConfigurationState,
  never,
  EmbeddingFieldBlockDisplayOptions,
  MultipleRelationFieldExportConfiguration>;

export const embeddingFieldDefinition: EmbeddingFieldDefinition = registerFieldDefinition(embeddingFieldHandler, {
  configuration: {
    typeIcon: IconName.spoke,
    getTypeLabel: () => i18n`Embedding`,
    asWidget: false,
    getEditionOptions: (store) => ({ mode, editionHandler, isEdition }) => {
      if (mode !== FieldEditionOptionMode.Field && mode !== FieldEditionOptionMode.FieldDeveloperMode) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      const toTypeId = editionHandler.getValue(EmbeddingField_ToType);

      sections.push({
        key: 'data',
        type: 'section',
        title: i18n`Data`,
        options: [
          {
            key: EmbeddingField_ToType,
            title: i18n`Embedded type`,
            hasValue: () => toTypeId !== undefined,
            clearValue: () => editionHandler.updateValues({ [EmbeddingField_ToType]: null }),
            type: EditionOptionTypes.select,
            props: {
              required: true,
              readOnly: isEdition,
              computeOptions: () => store.getObject(ConceptDefinition)
                .navigateBack(Class_Instances)
                .filter(({ id }) => ![DataAsset, Resource, User, ConceptRole, Group].includes(id))
                .map((conceptDefinition) => getChipOptions(store, conceptDefinition.id))
                .filter(filterNullOrUndefined)
                .sort(defaultOptionComparator),
              selectedOption: typeof toTypeId === 'string' ? getChipOptions(store, toTypeId) : undefined,
              onChange: (value) => editionHandler.updateValues({ [EmbeddingField_ToType]: (value?.id as string | undefined) ?? null }),
            },
          },
        ],
      });

      sections.push(getDocumentationFieldEditionSection(editionHandler));
      sections.push(getIntegrationFieldEditionSection(store, editionHandler, mode));

      return sections;
    },
    getFieldTitle: (store) => (editionHandler) => {
      const fromTypeId = Object.values(editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {}).at(0)?.typeId;
      const toTypeId = editionHandler.getValue(EmbeddingField_ToType);
      return fromTypeId !== undefined && toTypeId !== undefined
        ? i18n`Embedding "${getConceptDefinitionNameOrEntity(store, toTypeId)}" into "${getConceptDefinitionNameOrEntity(store, fromTypeId)}"`
        : i18n`Embedding`;
    },
    getFieldIcon: (store) => (editionHandler) => {
      const toTypeId = editionHandler.getValue(EmbeddingField_ToType);
      return toTypeId && toTypeId !== Concept ? store.getObjectOrNull<ConceptDefinitionStoreObject>(toTypeId)?.[ConceptDefinition_Icon] as IconName | undefined : undefined;
    },
    isCreationEnabled: () => () => true,
    onCreate: (store) => (editionHandler) => {
      const modelTypeId = Object.values(editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {})[0].typeId;
      const fieldId = store.createObject<EmbeddingFieldRaw>({
        [Instance_Of]: EmbeddingField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [Field_ApiAlias]: editionHandler.getValue(Field_ApiAlias),
        [Field_Formula]: editionHandler.getValue(Field_Formula),
        [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
        [EmbeddingField_ToType]: editionHandler.getValue(EmbeddingField_ToType) as string,
        [EmbeddingField_FromType]: modelTypeId,
      });
      linkFieldToFieldDimensions(store, fieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});
      return fieldId;
    },
    ofField: (store, fieldId, handler) => ({
      getTypeLabel: () => {
        const { toType: toTypeId, fromType: fromTypeId } = handler.resolveConfiguration();
        return i18n`Embedding "${getConceptDefinitionNameOrEntity(store, toTypeId)}" into "${getConceptDefinitionNameOrEntity(store, fromTypeId)}"`;
      },
      getIcon: () => {
        const targetType = store.getObject(fieldId).navigate(EmbeddingField_ToType);
        return {
          name: targetType[ConceptDefinition_Icon] as IconName,
          borderColor: computeBorderColor(targetType[ConceptDefinition_ChipBackgroundColor] as string | undefined ?? conceptDefinitionChipBackgroundColor),
          color: conceptDefinitionChipBackgroundColor,
        };
      },
      getInitialState: (conceptDefinitionId) => {
        const field = store.getObject<EmbeddingFieldStoreObject>(fieldId);
        return joinObjects(
          getApiAliasInitialState(store, fieldId),
          {
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            [Field_Formula]: field[Field_Formula],
            [Field_IntegrationOnly]: field[Field_IntegrationOnly],
            [FieldIntegrationOnlyDisabled]: field[Field_IsCore],
            [EmbeddingField_ToType]: field[EmbeddingField_ToType],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(store, fieldId, conceptDefinitionId),
          }
        );
      },
      submitFieldUpdate: (stateToSubmit) => {
        store.updateObject<EmbeddingFieldRaw>(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [Field_Formula]: stateToSubmit[Field_Formula],
          [Field_IntegrationOnly]: stateToSubmit[Field_IntegrationOnly],
        });
      },
      duplicateFieldDefinition: () => {
        const field = store.getObject<EmbeddingFieldStoreObject>(fieldId);
        const fieldDimensionMapping = generateDuplicatedFieldDimensionId(store, fieldId);
        const newFieldId = store.createObject<EmbeddingFieldRaw>({
          [Instance_Of]: field[Instance_Of],
          [Field_Title]: `${field[Field_Title]} (copy)`,
          [Field_Documentation]: field[Field_Documentation],
          [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: field[Field_IntegrationOnly],
          [EmbeddingField_ToType]: field[EmbeddingField_ToType],
          [EmbeddingField_FromType]: field[EmbeddingField_FromType],
          [Field_Formula]: duplicateFormula(field[Field_Formula], fieldDimensionMapping),
        });
        duplicateFieldDimensionWithNewField(store, newFieldId, fieldDimensionMapping);
        return newFieldId;
      },
    }),
  },
  renderField: (store, fieldId) => ({ dimensionsMapping, readOnly }) => {
    const parsedDimension = parseDimensionMapping(dimensionsMapping);
    if (parsedDimension.type !== ParsedDimensionType.MonoDimensional) {
      return null;
    }

    const { getValueWithoutFormula, isSaneValue, getTargetType } = embeddingFieldHandler(store, fieldId);
    const targetTypeId = getTargetType?.()?.id;
    if (!targetTypeId) {
      return null; // Invalid configuration, abort
    }

    const sanityError = isSaneValue(parsedDimension.objectId).error;

    return (
      <ConceptChipList
        conceptId={parsedDimension.objectId}
        fieldId={fieldId}
        value={getValueWithoutFormula(dimensionsMapping)}
        error={sanityError ? formatErrorForUser(store, new InvalidFieldError(fieldId, sanityError)) : undefined}
        readOnly={readOnly}
        getInlineCreation={getInlineCreationBuilder(
          store,
          targetTypeId,
          () => {},
          undefined,
          { [KinshipRelation]: fieldId, [fieldId]: parsedDimension.objectId }
        )}
        onUnlink={(id) => store.deleteObject(id)}
      />
    );
  },
  renderBlockField: (store, fieldId) => (dimensionsMapping, displayOptions, blockFieldProps, layoutParametersMapping, viewDimensions, fieldBlockDisplayId) => {
    const parsedDimension = parseDimensionMapping(dimensionsMapping);
    if (parsedDimension.type !== ParsedDimensionType.MonoDimensional) {
      return null;
    }

    const targetTypeId = embeddingFieldHandler(store, fieldId).getTargetType?.()?.id;
    if (!targetTypeId) {
      return null; // Invalid configuration, abort
    }

    const { viewDefinitions, withFilters } = displayOptions;
    return (
      <ViewLoadingStateContextProvider>
        <ViewsGroupBlock
          fieldId={fieldId}
          viewDimensions={viewDimensions}
          viewFilters={{
            filterKey: fieldBlockDisplayId,
            hasFilters: withFilters,
            getViewFilters: () => store.getObject<FieldBlockDisplayStoreObject>(fieldBlockDisplayId)
              .navigateBack<AssociationFilterStoreObject | ConditionFilterStoreObject>(FieldBlockDisplay_ViewFilters),
          }}
          viewDefinitions={viewDefinitions ?? []}
          layoutParametersMapping={layoutParametersMapping}
          blockFieldProps={blockFieldProps}
          widgetDisplay={defaultWidgetDisplay}
        />
      </ViewLoadingStateContextProvider>
    );
  },
  renderExportConfiguration: () => ({ configuration, onChange, conceptDefinitionId }) => (
    <MultipleRelationExportConfiguration configuration={configuration} conceptDefinitionId={conceptDefinitionId} onChange={onChange} />
  ),
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({
    propertyId: fieldId,
    sortable: true,
  }),
  getComparatorHandler: (store, fieldId) => (direction) => ({
    comparator: comparing(compareNumber, direction === TableSortDirection.desc),
    extractValue: (dimensionsMapping) => {
      const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
      return parsedDimensionMapping.type === ParsedDimensionType.MonoDimensional ? store.getObject(parsedDimensionMapping.objectId).navigateBack(fieldId).length : undefined;
    },
  } satisfies FieldComparatorHandler<number | undefined>),
  blockDisplayOptionsHandler: getViewsBlockDisplayOptionsHandler,
});
