import { getPathReturnedConceptDefinitionId } from 'yooi-modules/modules/conceptModule';
import {
  ConceptDefinition_Color,
  ConceptDefinition_Timeline,
  ConceptDefinition_TimelineDependency,
  ConceptDefinition_TimelineGroupBy,
  ConceptDefinition_TimelineProgress,
} from 'yooi-modules/modules/conceptModule/ids';
import type { TimelineViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import { ViewType } from 'yooi-modules/modules/dashboardModule';
import { joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import i18n from '../../../../utils/i18n';
import { getConceptModelDisplayField } from '../../conceptDisplayUtils';
import ConceptTimelineOptions from '../../fields/_global/timeline/ConceptTimelineOptions';
import type { CommonViewResolvedDefinition } from '../common/commonViewResolvedDefinition';
import type { DefaultConfigurationFeatureResolvedDefinition } from '../common/defaultConfiguration/defaultConfigurationFeatureDefinition';
import { isDefaultConfigError } from '../common/defaultConfiguration/defaultConfigurationFeatureDefinition';
import { getDefaultConfig } from '../common/defaultConfiguration/viewWithDefaultConfigurationUtils';
import { getViewConceptConceptError } from '../common/viewUtils';
import type { ViewDefinitionHandler } from '../viewDsl';
import { registerView } from '../viewDsl';
import TimelineViewBlock from './TimelineViewBlock';
import TimelineViewDefinitionOptions from './TimelineViewDefinitionOptions';
import type { TimelineViewResolution } from './timelineViewResolution';
import { resolveTimelineView } from './timelineViewResolution';
import TimelineViewWidget from './TimelineViewWidget';

export interface TimelineViewResolvedDefinition extends CommonViewResolvedDefinition, DefaultConfigurationFeatureResolvedDefinition {
  type: ViewType.Timeline,
  defaultTimelineFieldId?: string,
  defaultTimelineProgressFieldId?: string,
  defaultTimelineDependencyFieldId?: string,
  // ⚠️ This is not always a field, could be a concept definition
  defaultTimelineGroupByFieldId?: string,
  defaultTimelineColorFieldId?: string,
}

export type TimelineViewDefinitionHandler = ViewDefinitionHandler<TimelineViewStoredDefinition, TimelineViewResolvedDefinition, TimelineViewResolution>;

export const timelineViewHandler = registerView<TimelineViewStoredDefinition, TimelineViewResolvedDefinition, TimelineViewResolution>({
  type: ViewType.Timeline,
  getLabel: () => i18n`Timeline`,
  icon: IconName.view_timeline,
  optionType: 'line',
  withFilters: true,
  withExport: false,
  resolveView: (store, _, { viewDimensions, parametersMapping, filterConfiguration }) => (
    resolveTimelineView(store, viewDimensions, parametersMapping, filterConfiguration)
  ),
  renderBlock: (_, viewDefinition, { viewDimensions, viewFilters, layoutParametersMapping, readOnly }) => (
    <TimelineViewBlock
      viewDimensions={viewDimensions}
      viewFilters={viewFilters}
      viewDefinition={viewDefinition}
      parametersMapping={layoutParametersMapping}
      readOnly={readOnly}
    />
  ),
  renderWidget: (_, viewDefinition, { viewDimensions, viewFilters, parametersMapping, width, height, readOnly }) => (
    <TimelineViewWidget
      viewDimensions={viewDimensions}
      viewFilters={viewFilters}
      viewDefinition={viewDefinition}
      parametersMapping={parametersMapping}
      width={width}
      height={height}
      readOnly={readOnly}
    />
  ),
  hasOptions: () => true,
  renderOptions: (store, viewDefinition, { widgetId, viewFilters, viewDimensions }) => {
    const conceptDefinitionId = getPathReturnedConceptDefinitionId(store, viewDimensions.at(0)?.path ?? []);
    return (
      <ConceptTimelineOptions
        widgetId={widgetId}
        conceptDefinitionId={conceptDefinitionId}
        viewDefinition={viewDefinition}
        viewDimensions={viewDimensions}
        filterKey={viewFilters.filterKey}
      />
    );
  },
  renderDefinitionOptions: (_, viewDefinition, { viewDimensions, updateViewDefinition, readOnly, parameterDefinitions }) => (
    <TimelineViewDefinitionOptions
      viewDimensions={viewDimensions}
      viewDefinition={viewDefinition}
      updateViewDefinition={updateViewDefinition}
      readOnly={readOnly}
      parameterDefinitions={parameterDefinitions}
    />
  ),
  getDefinitionErrors: (store, _, viewDimensions, parameters) => {
    const error = getViewConceptConceptError(store, viewDimensions, parameters, true);
    if (error) {
      return [error];
    }
    return undefined;
  },
  resolveDefinition: (store, viewDefinition, viewDimensions) => {
    const defaultConfigOptions = getDefaultConfig(store, viewDefinition, viewDimensions);

    const timelineViewResolvedDefinition: TimelineViewResolvedDefinition = {
      id: viewDefinition.id,
      type: viewDefinition.type,
      label: viewDefinition.label !== undefined && viewDefinition.label !== '' ? viewDefinition.label : i18n`Timeline`,
      defaultConfigOptions,
      readOnly: viewDefinition.readOnly ?? false,
      defaultTimelineFieldId: viewDefinition.defaultTimelineFieldId,
      defaultTimelineProgressFieldId: viewDefinition.defaultTimelineProgressFieldId,
      defaultTimelineDependencyFieldId: viewDefinition.defaultTimelineDependencyFieldId,
      defaultTimelineGroupByFieldId: viewDefinition.defaultTimelineGroupByFieldId,
      defaultTimelineColorFieldId: viewDefinition.defaultTimelineColorFieldId,
    };

    if (isDefaultConfigError(defaultConfigOptions) || !defaultConfigOptions.checked) {
      return timelineViewResolvedDefinition;
    } else {
      const { conceptDefinitionId } = defaultConfigOptions;
      return joinObjects(
        timelineViewResolvedDefinition,
        {
          defaultTimelineFieldId: getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_Timeline)?.id,
          defaultTimelineProgressFieldId: (getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_TimelineProgress)?.id),
          defaultTimelineDependencyFieldId: (getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_TimelineDependency)?.id),
          defaultTimelineGroupByFieldId: (getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_TimelineGroupBy)?.id),
          defaultTimelineColorFieldId: (getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_Color)?.id),
        }
      );
    }
  },
  getInitialStoredDefinition: (id) => ({ id, type: ViewType.Timeline }),
});
