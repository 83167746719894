import classnames from 'classnames';
import type { ComponentProps, FunctionComponent } from 'react';
import { Fragment, useState } from 'react';
import { ConceptDefinition, ConceptDefinition_RestrictedAccess } from 'yooi-modules/modules/conceptModule/ids';
import { Dashboard } from 'yooi-modules/modules/dashboardModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../components/atoms/Button';
import { IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import Typo from '../../../components/atoms/Typo';
import Banner, { BannerVariant } from '../../../components/molecules/Banner';
import Chip from '../../../components/molecules/Chip';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SearchAndSelectMultiple from '../../../components/molecules/SearchAndSelectMultiple';
import SpacingLine from '../../../components/molecules/SpacingLine';
import ToggleButton, { ElementPosition } from '../../../components/molecules/ToggleButton';
import useStore from '../../../store/useStore';
import { getSpacing, Spacing, spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useNavigation from '../../../utils/useNavigation';
import useUsageContext, { UsageVariant } from '../../../utils/useUsageContext';
import FormTextInputField from '../input/FormTextInputField';
import type { Option } from '../modelTypeUtils';
import { defaultOptionComparator, getChipOptions, getUnknownChip } from '../modelTypeUtils';

const useStyles = makeStyles({
  singleContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    columnGap: spacingRem.s,
    alignItems: 'start',
  },
  advancedModeGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    rowGap: spacingRem.s,
    columnGap: spacingRem.s,
    gridAutoRows: '3.2rem',
  },
  leftContainer: {
    display: 'inline-grid',
    gridTemplateColumns: 'auto auto minmax(10rem, 1fr)',
    columnGap: spacingRem.s,
    alignItems: 'center',
  },
  leftContainerPadded: {
    paddingLeft: getSpacing(Spacing.s, 0.1),
  },
  bannerContainer: {
    paddingLeft: getSpacing(Spacing.s, 0.1),
    paddingTop: getSpacing(Spacing.s),
    display: 'grid',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: spacingRem.s,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  actionContainerPadded: {
    paddingLeft: getSpacing(Spacing.s, 0.1),
  },
}, 'fieldDimensionsSearchAndSelect');

interface FieldDimension {
  id: string,
  typeId: string,
  readOnly: boolean,
  label: string | undefined,
  mandatory: boolean,
}

interface FieldDimensionsSearchAndSelectProps {
  dimensions: FieldDimension[],
  isComputed: boolean,
  onAdd: (typeId: string) => void,
  onChange: (id: string, label: string | undefined, mandatory: boolean) => void,
  onDelete: (id: string) => void,
  readOnly?: boolean,
}

const FieldDimensionsSearchAndSelect: FunctionComponent<FieldDimensionsSearchAndSelectProps> = ({ dimensions, isComputed, onAdd, onChange, onDelete, readOnly = false }) => {
  const classes = useStyles();

  const store = useStore();
  const navigation = useNavigation();

  const usageVariant = useUsageContext();

  const [showAdvancedMode, setShowAdvancedMode] = useState(
    dimensions.some(({ typeId }, index) => dimensions.findIndex(({ typeId: tId }) => typeId === tId) !== index)
    || dimensions.some(({ label, mandatory }) => (mandatory || label !== undefined))
  );
  const [showInlineCreation, setShowInlineCreation] = useState(false);

  if (showAdvancedMode) {
    return (
      <>
        <span className={classes.advancedModeGrid}>
          {
            dimensions.map(({ id, typeId, label, mandatory, readOnly: dimensionReadOnly }) => {
              const chip = getChipOptions(store, typeId) ?? getUnknownChip(typeId);
              const actions: ComponentProps<typeof Chip>['actions'] = [];

              if (chip.getNavigationPayload) {
                const navigationPayload = chip.getNavigationPayload(navigation);
                actions.push({
                  key: 'open',
                  icon: IconName.output,
                  tooltip: i18n`Open`,
                  action: { to: navigationPayload.to, state: navigationPayload.state, openInNewTab: false },
                  showOnHover: true,
                });
              }

              return (
                <Fragment key={id}>
                  <span className={classnames({ [classes.leftContainer]: true, [classes.leftContainerPadded]: usageVariant !== UsageVariant.inForm })}>
                    <Chip
                      text={chip.label}
                      tooltip={chip.tooltip}
                      squareColor={chip.squareColor}
                      icon={chip.icon}
                      color={chip.color}
                      borderStyle={chip.borderStyle}
                      actions={actions}
                    />
                    <Typo>{i18n`as`}</Typo>
                    <FormTextInputField
                      initialValue={label}
                      onSubmit={(newValue) => onChange(id, newValue ?? undefined, mandatory)}
                      placeholder={i18n`Dimension`}
                      maxLine={1}
                    />
                  </span>
                  <span className={classes.rightContainer}>
                    {isComputed || dimensions.length > 1 ? (
                      <ToggleButton
                        name={i18n`Mandatory`}
                        icon={mandatory ? IconName.toggle_on : IconName.toggle_off}
                        onClick={() => onChange(id, label, !mandatory)}
                        active={mandatory}
                        type={ElementPosition.alone}
                        disable={readOnly}
                      />
                    ) : null}
                    <IconOnlyButton
                      tooltip={i18n`Delete`}
                      iconName={IconName.delete}
                      onClick={() => onDelete(id)}
                      variant={IconOnlyButtonVariants.danger}
                      disabled={readOnly || dimensionReadOnly}
                    />
                  </span>
                </Fragment>
              );
            })
          }
          <span
            className={classnames({
              [classes.actionContainer]: true,
              [classes.actionContainerPadded]: usageVariant !== UsageVariant.inForm && !showInlineCreation,
            })}
          >
            {showInlineCreation ? (
              <SearchAndSelect
                selectedOption={undefined}
                computeOptions={() => (
                  store.getObject(ConceptDefinition)
                    .navigateBack(Class_Instances)
                    .filter((conceptDefinition) => (!conceptDefinition[ConceptDefinition_RestrictedAccess] && conceptDefinition.id !== Dashboard))
                    .map((conceptDefinition) => getChipOptions(store, conceptDefinition.id))
                    .filter(filterNullOrUndefined)
                    .sort(defaultOptionComparator)
                )}
                onSelect={(option) => {
                  if (option) {
                    onAdd(option.id);
                  }
                  setShowInlineCreation(false);
                }}
                onEscape={() => {
                  setShowInlineCreation(false);
                }}
                onClickAway={() => {
                  setShowInlineCreation(false);
                }}
                editOnMount
                readOnly={readOnly}
              />
            ) : (
              <Button
                title={i18n`Add dimension`}
                iconName={IconName.add}
                onClick={() => setShowInlineCreation(true)}
                variant={ButtonVariant.secondary}
                disabled={readOnly}
              />
            )}
          </span>
        </span>
        {!isComputed && dimensions.length > 1 && dimensions.every(({ mandatory }) => !mandatory) ? (
          <span className={classes.bannerContainer}>
            <Banner variant={BannerVariant.info} title={i18n`To use this field, at least one dimension must be mapped.`} />
          </span>
        ) : null}
      </>
    );
  } else {
    return (
      <span className={classes.singleContainer}>
        <SearchAndSelectMultiple<Option & { noDelete?: boolean }>
          readOnly={readOnly}
          selectedOptions={dimensions.map(({ id, typeId, mandatory, readOnly: readOnlyDimension }) => (joinObjects(
            (getChipOptions(store, typeId) ?? getUnknownChip(id)),
            {
              id,
              noDelete: readOnly || mandatory || readOnlyDimension,
            }
          )))}
          computeOptions={() => (
            store.getObject(ConceptDefinition)
              .navigateBack(Class_Instances)
              .filter((conceptDefinition) => !dimensions.some(({ typeId }) => typeId === conceptDefinition.id))
              .filter((conceptDefinition) => (!conceptDefinition[ConceptDefinition_RestrictedAccess] && conceptDefinition.id !== Dashboard))
              .map((conceptDefinition) => getChipOptions(store, conceptDefinition.id))
              .filter(filterNullOrUndefined)
              .sort(defaultOptionComparator)
          )}
          onSelect={(option) => {
            if (option) {
              onAdd(option.id);
            }
          }}
          onDelete={(option) => {
            onDelete(option.id);
          }}
        />
        <SpacingLine>
          <IconOnlyButton
            iconName={IconName.tune}
            tooltip={i18n`Advanced settings`}
            variant={IconOnlyButtonVariants.secondary}
            onClick={() => {
              setShowAdvancedMode(true);
            }}
          />
        </SpacingLine>
      </span>
    );
  }
};

export default FieldDimensionsSearchAndSelect;
