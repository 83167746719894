import type { FieldBlockDisplayStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import { BlockFieldLayoutOption, WorkflowRelationDisplayType } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import type {
  ConceptDefinitionStoreObject,
  Filters,
  RelationMultipleFieldRaw,
  RelationMultipleFieldStoreObject,
  RelationSingleFieldRaw,
  RelationSingleFieldStoreObject,
  SingleRelationFieldExportConfiguration,
} from 'yooi-modules/modules/conceptModule';
import { getFieldUtilsHandler, getInstanceLabel, relationSingleFieldHandler } from 'yooi-modules/modules/conceptModule';
import {
  Concept,
  ConceptDefinition,
  ConceptDefinition_ChipBackgroundColor,
  ConceptDefinition_Icon,
  ConceptDefinition_RestrictedAccess,
  Field_ApiAlias,
  Field_Documentation,
  Field_IntegrationOnly,
  Field_IsCore,
  Field_IsDocumentationInline,
  Field_Title,
  RelationMultipleField,
  RelationMultipleField_ReverseField,
  RelationMultipleField_TargetType,
  RelationSingleField,
  RelationSingleField_Field_TargetFilter,
  RelationSingleField_ReverseFields,
  RelationSingleField_TargetType,
} from 'yooi-modules/modules/conceptModule/ids';
import { Dashboard } from 'yooi-modules/modules/dashboardModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareString, comparing, filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import SimpleInput from '../../../../components/inputs/strategy/SimpleInput';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../../../components/molecules/Table';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { createConceptDefinition } from '../../conceptDefinitionUtils';
import { computeBorderColor, conceptDefinitionChipBackgroundColor } from '../../conceptDisplayUtils';
import { getInlineCreationBuilder } from '../../conceptUtils';
import { defaultOptionComparator, getChipOptions, getModelTypeInstances, getObjectName, getSearchChipOptions } from '../../modelTypeUtils';
import { createPathConfigurationHandler } from '../../pathConfigurationHandler';
import { getConceptTypeValidator } from '../../pathConfigurationHandlerUtils';
import { extractLastFieldFilters } from '../../views/common/viewUtils';
import { getBlockFieldLayoutOption, getLayoutDisplayOption } from '../_global/blockFieldUtils';
import { getApiAliasInitialState, getDocumentationFieldEditionSection, getIntegrationFieldEditionSection } from '../_global/editionHandlerUtils';
import FilterField from '../_global/FilterField';
import SingleRelationExportConfiguration from '../_global/SingleRelationExportConfiguration';
import { getGenericOperationMetadata } from '../_global/updateOperationHandlerUtils';
import UpdateOperationInput from '../_global/UpdateOperationInput';
import UpdateOperationSelector from '../_global/UpdateOperationSelector';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  createAndLinkFieldToConceptDefinitions,
  duplicateFieldDimensionWithNewField,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
} from '../fieldDimensionUtils';
import type { FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, FieldComparatorHandler, GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldIntegrationOnlyDisabled } from '../FieldLibraryTypes';
import RelationSingleRendererSwitch from './RelationSingleRendererSwitch';

const getWithOptions = (store: FrontObjectStore, edition = false) => store.getObject(ConceptDefinition)
  .navigateBack(Class_Instances)
  .filter((concept) => edition || (!concept[ConceptDefinition_RestrictedAccess] && concept.id !== Dashboard))
  .map((conceptDefinition) => conceptDefinition.id)
  .map((instanceId) => getChipOptions(store, instanceId))
  .filter(filterNullOrUndefined)
  .sort(defaultOptionComparator);

const computeSubTitle = (fromTypeName: string, toTypeName: string) => (
  i18n`${fromTypeName} targets a single ${toTypeName} / ${toTypeName} targets multiple ${fromTypeName}`
);

interface RelationSingleConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
  reverseName: string | null | undefined,
  [RelationSingleField_TargetType]: string | null | undefined,
  [Field_IntegrationOnly]: boolean | null | undefined,
  [FieldIntegrationOnlyDisabled]: boolean | undefined,
  isReverseFieldCore: boolean | null | undefined,
  [RelationSingleField_Field_TargetFilter]: Filters | null | undefined,
}

type RelationSingleFieldDefinition = GetFieldDefinitionHandler<
  typeof relationSingleFieldHandler,
  RelationSingleConfigurationState,
  never, FieldBlockDisplayOptions,
  SingleRelationFieldExportConfiguration
>;

export const relationSingleFieldDefinition: RelationSingleFieldDefinition = registerFieldDefinition(relationSingleFieldHandler, {
  configuration: {
    typeIcon: IconName.spoke,
    getTypeLabel: () => i18n`Association many to one (n-1)`,
    asWidget: false,
    getEditionOptions: (store) => ({ mode, editionHandler, isEdition, modelTypeId }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      const targetTypeId = editionHandler.getValueOrDefault(RelationSingleField_TargetType);
      const fieldTitle = editionHandler.getValue(Field_Title);

      sections.push({
        key: 'data',
        type: 'section',
        title: i18n`Data`,
        options: [
          {
            key: RelationSingleField_TargetType,
            title: i18n`With`,
            hasValue: () => editionHandler.getValue(RelationSingleField_TargetType) !== undefined
              && editionHandler.getValue(RelationSingleField_Field_TargetFilter) !== undefined,
            clearValue: () => editionHandler.updateValues({ [RelationSingleField_TargetType]: null, [RelationSingleField_Field_TargetFilter]: null }),
            type: EditionOptionTypes.custom,
            props: {
              render: () => (
                <SpacingLine>
                  <SearchAndSelect
                    selectedOption={typeof targetTypeId === 'string' ? getChipOptions(store, targetTypeId) : undefined}
                    computeOptions={() => getWithOptions(store, isEdition)}
                    placeholder={i18n`Add concept`}
                    readOnly={isEdition}
                    onSelect={(value) => editionHandler.updateValues({ [RelationSingleField_TargetType]: value?.id ?? null })}
                    statusIcon={
                      typeof targetTypeId !== 'string' || !getChipOptions(store, targetTypeId)
                        ? {
                          icon: IconName.dangerous,
                          color: IconColorVariant.error,
                          message: i18n`Required`,
                        }
                        : undefined
                    }
                  />
                  <FilterField
                    targetTypeId={typeof targetTypeId === 'string' ? targetTypeId : undefined}
                    modelTypeId={modelTypeId}
                    selectFieldFilters={editionHandler.getValue(RelationSingleField_Field_TargetFilter)}
                    updateFilters={(filters: Filters[]) => {
                      editionHandler.updateValues({ [RelationSingleField_Field_TargetFilter]: filters[0] });
                    }}
                  />
                </SpacingLine>
              ),
            },
          },
          {
            key: 'reverseName',
            title: i18n`Reverse name`,
            hasValue: () => editionHandler.getValue('reverseName') !== undefined,
            clearValue: () => editionHandler.updateValues({ reverseName: null }),
            type: EditionOptionTypes.text,
            props: {
              placeholder: fieldTitle ? i18n`Reverse of "${fieldTitle}"` : i18n`Add reverse name`,
              readOnly: editionHandler.getValue('isReverseFieldCore'),
              value: editionHandler.getValueOrDefault('reverseName'),
              onChange: (value) => editionHandler.updateValues({ reverseName: value }),
            },
          },
        ],
      });

      sections.push(getDocumentationFieldEditionSection(editionHandler));
      sections.push(getIntegrationFieldEditionSection(store, editionHandler, mode));

      return sections;
    },
    getFieldTitle: (objectStore) => (optionHandler) => {
      const targetTypeId = optionHandler.getValue(RelationSingleField_TargetType);
      return targetTypeId ? i18n`Association many to one with "${getObjectName(objectStore, objectStore.getObject(targetTypeId))}" (n-1)` : i18n`Association many to one (n-1)`;
    },
    getFieldSubTitle: (objectStore) => (optionHandler, modelTypeId) => {
      const targetTypeId = optionHandler.getValue(RelationSingleField_TargetType) as string;
      const targetTypeName = targetTypeId ? getObjectName(objectStore, objectStore.getObject(targetTypeId)) : undefined;
      const fromTypeName = getObjectName(objectStore, objectStore.getObject(modelTypeId));
      if (fromTypeName && targetTypeName) {
        return computeSubTitle(fromTypeName, targetTypeName);
      }
      return undefined;
    },
    getFieldIcon: (objectStore) => (editionHandler) => {
      const toTypeId = editionHandler.getValue(RelationSingleField_TargetType) as string;
      return toTypeId && toTypeId !== Concept ? objectStore.getObjectOrNull<ConceptDefinitionStoreObject>(toTypeId)?.[ConceptDefinition_Icon] as IconName | undefined : undefined;
    },
    isCreationEnabled: () => () => true,
    canCreate: (_) => (editionHandler) => Boolean(editionHandler.getValue(RelationSingleField_TargetType)),
    onCreate: (objectStore) => (editionHandler) => {
      const modelTypeId = Object.values(editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {})[0].typeId;
      const fieldId = objectStore.createObject({
        [Instance_Of]: RelationSingleField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [Field_ApiAlias]: editionHandler.getValue(Field_ApiAlias),
        [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
        [RelationSingleField_TargetType]: editionHandler.getValue(RelationSingleField_TargetType),
      });
      const reverseFieldId = objectStore.createObject({
        [Instance_Of]: RelationMultipleField,
        [Field_Title]: editionHandler.getValue('reverseName'),
        [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
        [RelationMultipleField_TargetType]: modelTypeId,
        [RelationMultipleField_ReverseField]: fieldId,
      });
      createAndLinkFieldToConceptDefinitions(objectStore, reverseFieldId, [editionHandler.getValue(RelationSingleField_TargetType)].filter(filterNullOrUndefined));
      linkFieldToFieldDimensions(objectStore, fieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});
      return fieldId;
    },
    inlineCreate: (objectStore) => (conceptDefinitionId, extraFieldOptions) => ({
      type: 'transactional',
      getChipLabel: (state) => state[Field_Title] as string | undefined,
      getInitialState: (search) => ({ [Field_Title]: search }),
      creationOptions: [
        {
          key: RelationSingleField_TargetType,
          title: i18n`With`,
          isValueValid: (value) => typeof value === 'string',
          render: (value, setValue) => (
            <SearchAndSelect
              computeOptions={() => getWithOptions(objectStore, true)}
              selectedOption={typeof value === 'string' ? getChipOptions(objectStore, value) : undefined}
              onSelect={(option) => setValue(option?.id ?? undefined)}
              getInlineCreation={() => ({
                type: 'inline',
                onCreate: (name) => {
                  const newConceptDefinitionId = createConceptDefinition(objectStore, name);
                  setValue(newConceptDefinitionId);
                  return newConceptDefinitionId;
                },
              })}
            />
          ),
        },
      ],
      onCreate: (state) => {
        const newFieldId = objectStore.createObject<RelationSingleFieldRaw>(joinObjects(
          extraFieldOptions,
          {
            [Instance_Of]: RelationSingleField,
            [Field_Title]: state[Field_Title] as string | undefined,
            [RelationSingleField_TargetType]: state[RelationSingleField_TargetType] as string,
          }
        ));
        const reverseFieldId = objectStore.createObject<RelationMultipleFieldRaw>(joinObjects(
          extraFieldOptions,
          {
            [Instance_Of]: RelationMultipleField,
            [RelationMultipleField_TargetType]: conceptDefinitionId,
            [RelationMultipleField_ReverseField]: newFieldId,
          }
        ));
        createAndLinkFieldToConceptDefinitions(objectStore, reverseFieldId, [state[RelationSingleField_TargetType] as string]);
        createAndLinkFieldToConceptDefinitions(objectStore, newFieldId, [conceptDefinitionId]);
        return newFieldId;
      },

    }),
    ofField: (objectStore, fieldId, handler) => ({
      getTypeLabel: () => {
        const { targetTypeId } = handler.resolveConfiguration();
        return i18n`Association many to one with "${getObjectName(objectStore, objectStore.getObject(targetTypeId))}" (n-1)`;
      },
      getIcon: () => {
        const targetType = handler.getTargetType?.();
        if (targetType && isInstanceOf<ConceptDefinitionStoreObject>(targetType, ConceptDefinition)) {
          return {
            name: targetType[ConceptDefinition_Icon] as IconName,
            borderColor: computeBorderColor(targetType[ConceptDefinition_ChipBackgroundColor] ?? conceptDefinitionChipBackgroundColor),
            color: conceptDefinitionChipBackgroundColor,
          };
        } else {
          return undefined;
        }
      },
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<RelationSingleFieldStoreObject>(fieldId);
        const reverseField = field.navigateBack<RelationMultipleFieldStoreObject>(RelationSingleField_ReverseFields)[0];
        return joinObjects(
          getApiAliasInitialState(objectStore, fieldId),
          {
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            reverseName: reverseField[Field_Title],
            [RelationSingleField_TargetType]: field[RelationSingleField_TargetType],
            [Field_IntegrationOnly]: field[Field_IntegrationOnly],
            [FieldIntegrationOnlyDisabled]: field[Field_IsCore],
            isReverseFieldCore: reverseField[Field_IsCore],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
            [RelationSingleField_Field_TargetFilter]: field[RelationSingleField_Field_TargetFilter],
          }
        );
      },
      submitFieldUpdate: (stateToSubmit) => {
        objectStore.updateObject(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: stateToSubmit[Field_IntegrationOnly],
          [RelationSingleField_Field_TargetFilter]: stateToSubmit[RelationSingleField_Field_TargetFilter],
        });
        objectStore.updateObject(objectStore.getObject(fieldId).navigateBack(RelationMultipleField_ReverseField)[0].id, { [Field_Title]: stateToSubmit.reverseName });
      },
      duplicateFieldDefinition: () => {
        const field = objectStore.getObject(fieldId);
        const reverseFieldInstance = field.navigateBack(RelationMultipleField_ReverseField)[0];
        const fieldDimensionMapping = generateDuplicatedFieldDimensionId(objectStore, fieldId);
        const newFieldId = objectStore.createObject({
          [Instance_Of]: RelationSingleField,
          [Field_Title]: `${field[Field_Title]} (copy)`,
          [Field_Documentation]: field[Field_Documentation],
          [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: field[Field_IntegrationOnly],
          [RelationSingleField_TargetType]: field[RelationSingleField_TargetType],
          [RelationSingleField_Field_TargetFilter]: field[RelationSingleField_Field_TargetFilter],
        });
        const reverseFieldDimensionMapping = generateDuplicatedFieldDimensionId(objectStore, reverseFieldInstance.id);
        const newReverseFieldId = objectStore.createObject({
          [Instance_Of]: RelationMultipleField,
          [Field_Title]: `${reverseFieldInstance[Field_Title]} (copy)`,
          [Field_Documentation]: reverseFieldInstance[Field_Documentation],
          [Field_IsDocumentationInline]: reverseFieldInstance[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: reverseFieldInstance[Field_IntegrationOnly],
          [RelationMultipleField_TargetType]: reverseFieldInstance[RelationMultipleField_TargetType],
          [RelationMultipleField_ReverseField]: newFieldId,
        });
        duplicateFieldDimensionWithNewField(objectStore, newFieldId, fieldDimensionMapping);
        duplicateFieldDimensionWithNewField(objectStore, newReverseFieldId, reverseFieldDimensionMapping);
        return newFieldId;
      },
    }),
  },
  renderField: (_, fieldId) => ({ dimensionsMapping, parametersMapping, value, onSubmit, readOnly, focusOnMount, path }) => (
    <RelationSingleRendererSwitch
      fieldId={fieldId}
      dimensionsMapping={dimensionsMapping}
      value={value}
      onSubmit={onSubmit}
      readOnly={readOnly}
      focusOnMount={focusOnMount}
      filters={extractLastFieldFilters(path)}
      parametersMapping={parametersMapping}
    />
  ),
  input: (store, _, handler) => ({
    render: ({
      value,
      onSubmit,
      focusOnMount,
      readOnly,
      onEditionStart,
      onEditionStop,
      aclHandler: { canCreateObject },
    }) => {
      const targetType = handler.getTargetType?.();
      if (!targetType) {
        return null;
      }
      const canCreate = canCreateObject(targetType.id);

      return (
        <SimpleInput initialValue={value} onSubmit={onSubmit}>
          {(props) => (
            <SearchAndSelect
              computeOptions={() => (
                getModelTypeInstances(store, targetType.id)
                  .map(({ id }) => getChipOptions(store, id))
                  .filter(filterNullOrUndefined)
                  .sort(defaultOptionComparator)
              )}
              selectedOption={props.value ? getChipOptions(store, props.value) : undefined}
              onSelect={(option) => props.onSubmit(option?.id ?? undefined)}
              onEditionStart={onEditionStart}
              onEditionStop={onEditionStop}
              searchOptions={getSearchChipOptions(store, targetType.id)}
              placeholder={i18n`Select element`}
              readOnly={readOnly}
              editOnMount={focusOnMount}
              getInlineCreation={canCreate ? getInlineCreationBuilder(
                store,
                targetType.id,
                (newInstanceId) => props.onSubmit(newInstanceId)
              ) : undefined}
            />
          )}
        </SimpleInput>
      );
    },
    getInitialState: () => undefined,
    persistStateOnConcept: (dimension, value) => {
      handler.updateValue(dimension, value ? store.getObjectOrNull(value)?.id ?? null : null);
    },
  }),
  getUpdateOperationInput: (store, fieldId, { getTargetType, updateOperationHandlers: { INITIALIZE, REPLACE, CLEAR } }) => (operation) => {
    const { title, getIcon } = getGenericOperationMetadata(store, operation, fieldId);
    return {
      title,
      getIcon,
      render: ({ onSubmit, parameterDefinitions }) => {
        const operationSelectorLine = (
          <UpdateOperationSelector<typeof relationSingleFieldHandler>
            selectedOperationAction={operation?.action}
            operationOptions={[
              { id: 'INITIALIZE', label: i18n`Initialize`, onSelect: () => onSubmit({ action: 'INITIALIZE', payload: INITIALIZE.sanitizeOperation(operation) }) },
              { id: 'REPLACE', label: i18n`Replace`, onSelect: () => onSubmit({ action: 'REPLACE', payload: REPLACE.sanitizeOperation(operation) }) },
              { id: 'CLEAR', label: i18n`Clear`, onSelect: () => onSubmit({ action: 'CLEAR', payload: CLEAR.sanitizeOperation(operation) }) },
            ]}
          />
        );

        let operationInputsLines = null;
        const { input } = relationSingleFieldDefinition(store).getHandler(fieldId);
        if (input && operation && operation.action !== 'CLEAR' && operation.payload) {
          operationInputsLines = (
            <UpdateOperationInput<typeof relationSingleFieldHandler>
              valueInput={input}
              initialValue={operation.payload}
              onTypeChange={(newType) => {
                onSubmit({ action: operation.action, payload: newType === 'value' ? { type: 'value', value: undefined } : { type: 'path', path: [] } });
              }}
              onValueSubmit={(newValue) => onSubmit({ action: operation.action, payload: newValue })}
              parameterDefinitions={parameterDefinitions}
              valuePathHandler={createPathConfigurationHandler(
                store,
                parameterDefinitions,
                [getConceptTypeValidator(store, false, getTargetType?.()?.id)]
              )}
            />
          );
        }

        return (
          <>
            {operationSelectorLine}
            {operationInputsLines}
          </>
        );
      },
    };
  },
  renderExportConfiguration: () => ({ configuration, conceptDefinitionId, onChange }) => (
    <SingleRelationExportConfiguration
      conceptDefinitionId={conceptDefinitionId}
      configuration={configuration}
      onChange={onChange}
    />
  ),
  getActivityProperties: (_, fieldId) => () => [fieldId],
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({
    key: fieldId,
    propertyId: fieldId,
    sortable: true,
    focusable: true,
  }),
  getComparatorHandler: (store, _, { getValueResolution }) => (direction) => ({
    comparator: comparing(compareString, direction === TableSortDirection.desc),
    extractValue: (dimensionsMapping) => {
      const { value } = getValueResolution(dimensionsMapping);
      return value ? getInstanceLabel(store, value) : undefined;
    },
  } satisfies FieldComparatorHandler<string | undefined>),
  blockDisplayOptionsHandler: (objectStore, fieldId) => (fieldBlockDisplayId) => ({
    getDisplayOptions: () => (joinObjects(
      {
        displayType: WorkflowRelationDisplayType.Value,
        layoutDisplayType: BlockFieldLayoutOption.auto,
      },
      objectStore.getObjectOrNull<FieldBlockDisplayStoreObject>(fieldBlockDisplayId)?.[FieldBlockDisplay_FieldDisplayConfiguration] ?? {}
    )),
    renderSummary: (state) => {
      const targetTypeId = getFieldUtilsHandler(objectStore, fieldId).getTargetType?.()?.id;
      if (!targetTypeId) {
        return []; // Invalid configuration, abort
      }

      return [getBlockFieldLayoutOption()[state.layoutDisplayType ?? BlockFieldLayoutOption.auto].label];
    },
    getBlockEditionOptionSections: (state, setState) => {
      const targetTypeId = getFieldUtilsHandler(objectStore, fieldId).getTargetType?.()?.id;
      return targetTypeId ? [getLayoutDisplayOption(state, setState)] : [];
    },
    onSubmit: (state) => {
      objectStore.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
});
