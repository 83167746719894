import type { FieldBlockDisplayOptions, FieldBlockDisplayStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import type {
  AssociationFieldDefinitionRaw,
  AssociationFieldDefinitionStoreObject,
  AssociationFieldRaw,
  AssociationFieldStoreObject,
  AssociationFilterStoreObject,
  AssociationUpdate,
  ConceptDefinitionStoreObject,
  ConditionFilterStoreObject,
  DimensionsMapping,
  Filters,
  Formula,
  MultipleRelationFieldExportConfiguration,
  SingleParameterDefinition,
} from 'yooi-modules/modules/conceptModule';
import {
  associationFieldHandler,
  canCreateAssociation,
  getFieldUtilsHandler,
  getInstanceLabelOrUndefined,
  getReverseAssociationField,
  InvalidFieldError,
  ParsedDimensionType,
  parseDimensionMapping,
} from 'yooi-modules/modules/conceptModule';
import {
  Association,
  Association_Role_Definition,
  AssociationField,
  AssociationField_Definition,
  AssociationField_Field_TargetFilter,
  AssociationField_SourceRole,
  AssociationFieldDefinition,
  AssociationFieldDefinition_Role1Type,
  AssociationFieldDefinition_Role2Type,
  Concept,
  ConceptDefinition,
  ConceptDefinition_ChipBackgroundColor,
  ConceptDefinition_Icon,
  ConceptDefinition_RestrictedAccess,
  Field_ApiAlias,
  Field_Documentation,
  Field_Formula,
  Field_IntegrationOnly,
  Field_IsCore,
  Field_IsDocumentationInline,
  Field_Title,
  FieldBlockDisplay_ViewFilters,
} from 'yooi-modules/modules/conceptModule/ids';
import { conceptType } from 'yooi-modules/modules/conceptModule/utils/formula/modelFunctions';
import type { ViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import { Dashboard } from 'yooi-modules/modules/dashboardModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStore } from 'yooi-store';
import { arrayOf, compareArray, compareString, comparing, createAutoProvisioningMap, filterNullOrUndefined, joinObjects, pushUndefinedToEnd } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import SimpleInput from '../../../../components/inputs/strategy/SimpleInput';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../../../components/molecules/Table';
import type { FrontObjectStore } from '../../../../store/useStore';
import base from '../../../../theme/base';
import i18n from '../../../../utils/i18n';
import { createConceptDefinition } from '../../conceptDefinitionUtils';
import { computeBorderColor, conceptDefinitionChipBackgroundColor } from '../../conceptDisplayUtils';
import { getInlineCreationBuilder } from '../../conceptUtils';
import { formatErrorForUser, formatFieldResolutionErrorForUser } from '../../errorUtils';
import { getFormulaFieldEditionSection } from '../../formulaRendererUtils';
import { defaultOptionComparator, getChipOptions, getModelTypeIcon, getObjectName } from '../../modelTypeUtils';
import { createPathConfigurationHandler } from '../../pathConfigurationHandler';
import { getConceptTypeValidator } from '../../pathConfigurationHandlerUtils';
import ConceptChipList from '../_global/ConceptChipList';
import { getApiAliasInitialState, getDimensionsEditionOption, getDocumentationFieldEditionSection, getIntegrationFieldEditionSection } from '../_global/editionHandlerUtils';
import FilterField from '../_global/FilterField';
import MultipleRelationExportConfiguration from '../_global/MultipleRelationExportConfiguration';
import { getGenericOperationMetadata } from '../_global/updateOperationHandlerUtils';
import UpdateOperationInput from '../_global/UpdateOperationInput';
import UpdateOperationSelector from '../_global/UpdateOperationSelector';
import { getViewsBlockDisplayOptionsHandler } from '../_global/viewsBlockFieldUtils';
import { defaultWidgetDisplay } from '../_global/widgetUtils';
import { duplicateFormula } from '../duplicationUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  createAndLinkFieldToConceptDefinitions,
  duplicateFieldDimensionWithNewField,
  FIELD_DIMENSIONS_READONLY,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
  submitDimensionUpdate,
} from '../fieldDimensionUtils';
import type { FieldEditionOption, FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, FieldComparatorHandler, GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldIntegrationOnlyDisabled } from '../FieldLibraryTypes';
import { ViewLoadingStateContextProvider } from '../viewsField/useViewLoadingState';
import ViewsGroupBlock from '../viewsField/ViewsGroupBlock';
import AssociationFieldInputRenderer from './AssociationFieldInputRenderer';
import MultidimensionalAssociationFieldRenderer from './MultidimensionalAssociationFieldRenderer';

const buildOnLink = (objectStore: ObjectStore, fieldId: string) => (selectedId: string, conceptInstanceId: string) => {
  const field = objectStore.getObject(fieldId);
  const sourceRole = field[AssociationField_SourceRole] as number;
  objectStore.withAssociation(Association)
    .withRole(Association_Role_Definition, field[AssociationField_Definition] as string)
    .withRole(sourceRole, conceptInstanceId)
    .withRole(sourceRole === 1 ? 2 : 1, selectedId)
    .updateObject({});
  return selectedId;
};

const buildOnUnlink = (objectStore: ObjectStore, fieldId: string) => (id: string, conceptInstanceId: string) => {
  const field = objectStore.getObject(fieldId);
  const sourceRole = field[AssociationField_SourceRole] as number;
  objectStore.withAssociation(Association)
    .withRole(Association_Role_Definition, field[AssociationField_Definition] as string)
    .withRole(sourceRole, conceptInstanceId)
    .withRole(sourceRole === 1 ? 2 : 1, id)
    .deleteObject();
};

const getWithOptions = (store: FrontObjectStore, edition = false) => [
  ...store.getObject(ConceptDefinition)
    .navigateBack(Class_Instances)
    .filter((concept) => !concept[ConceptDefinition_RestrictedAccess] && concept.id !== Dashboard)
    .map((conceptDefinition) => conceptDefinition.id),
  ...edition ? [Concept] : [],
]
  .map((instanceId) => getChipOptions(store, instanceId))
  .filter(filterNullOrUndefined)
  .sort(defaultOptionComparator);

const computeSubTitle = (fromTypeName: string, toTypeName: string) => (
  `${fromTypeName} targets multiple ${toTypeName} / ${toTypeName} targets multiple ${fromTypeName}`
);

interface AssociationFieldBlockDisplayOptions extends FieldBlockDisplayOptions {
  viewDefinitions?: ViewStoredDefinition[],
  withFilters?: boolean,
}

interface AssociationFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [FIELD_DIMENSIONS_READONLY]: boolean | undefined,
  [Field_Title]: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
  reverseName: string | null | undefined,
  [Field_IntegrationOnly]: boolean | null | undefined,
  [FieldIntegrationOnlyDisabled]: boolean | undefined,
  isReverseFieldCore: boolean | null | undefined,
  [AssociationFieldDefinition_Role2Type]: string | null | undefined,
  [Field_Formula]: Formula | null | undefined,
  [AssociationField_Field_TargetFilter]: Filters | null | undefined,
}

type AssociationFieldDefinition = GetFieldDefinitionHandler<
  typeof associationFieldHandler,
  AssociationFieldConfigurationState,
  never,
  AssociationFieldBlockDisplayOptions,
  MultipleRelationFieldExportConfiguration
>;

export const associationFieldDefinition: AssociationFieldDefinition = registerFieldDefinition(associationFieldHandler, {
  configuration: {
    typeIcon: IconName.spoke,
    getTypeLabel: () => i18n`Association many to many (n-n)`,
    asWidget: false,
    getEditionOptions: (store) => ({ mode, editionHandler, isEdition, readOnly, modelTypeId }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      const fieldEditionDimensions: FieldEditionDimensions = editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {};
      const parameterDefinitions: SingleParameterDefinition[] = Object.entries(fieldEditionDimensions)
        .map(([id, { typeId }]) => ({ id, typeId, label: i18n`Dimension`, type: 'dimension' }));
      const role2TypeId = editionHandler.getValueOrDefault(AssociationFieldDefinition_Role2Type);
      const dataOptions: FieldEditionOption[] = [];

      if (!editionHandler.getValue(Field_Formula)) {
        dataOptions.push(
          {
            key: AssociationFieldDefinition_Role2Type,
            title: i18n`With`,
            hasValue: () => editionHandler.getValue(AssociationFieldDefinition_Role2Type) !== undefined
              && editionHandler.getValue(AssociationField_Field_TargetFilter) !== undefined,
            clearValue: () => editionHandler.updateValues({ [AssociationFieldDefinition_Role2Type]: null, [AssociationField_Field_TargetFilter]: null }),
            type: EditionOptionTypes.custom,
            props: {
              render: () => (
                <SpacingLine>
                  <SearchAndSelect
                    selectedOption={typeof role2TypeId === 'string' ? getChipOptions(store, role2TypeId) : undefined}
                    computeOptions={() => getWithOptions(store, isEdition)}
                    placeholder={i18n`Add concept`}
                    readOnly={isEdition}
                    onSelect={(value) => editionHandler.updateValues({ [AssociationFieldDefinition_Role2Type]: value?.id ?? null })}
                    statusIcon={
                      typeof role2TypeId !== 'string' || !getChipOptions(store, role2TypeId)
                        ? {
                          icon: IconName.dangerous,
                          color: IconColorVariant.error,
                          message: i18n`Required`,
                        }
                        : undefined
                    }
                  />
                  <FilterField
                    targetTypeId={typeof role2TypeId === 'string' ? role2TypeId : undefined}
                    modelTypeId={modelTypeId}
                    selectFieldFilters={editionHandler.getValue(AssociationField_Field_TargetFilter)}
                    updateFilters={(filters: Filters[]) => {
                      editionHandler.updateValues({ [AssociationField_Field_TargetFilter]: filters[0] });
                    }}
                  />
                </SpacingLine>
              ),
            },
          }
        );
        const fieldTitle = editionHandler.getValue(Field_Title);
        dataOptions.push(
          {
            key: 'reverseName',
            title: i18n`Reverse name`,
            hasValue: () => editionHandler.getValue('reverseName') !== undefined,
            clearValue: () => editionHandler.updateValues({ reverseName: null }),
            type: EditionOptionTypes.text,
            props: {
              placeholder: fieldTitle ? i18n`Reverse of "${fieldTitle}"` : i18n`Add reverse name`,
              readOnly: editionHandler.getValue('isReverseFieldCore'),
              value: editionHandler.getValueOrDefault('reverseName'),
              onChange: (value) => editionHandler.updateValues({ reverseName: value }),
            },
          }
        );
      } else {
        dataOptions.push(getDimensionsEditionOption(
          editionHandler,
          Boolean(editionHandler.getValue(Field_Formula)),
          readOnly || Boolean(editionHandler.getValue(FIELD_DIMENSIONS_READONLY))
        ));
      }

      sections.push({
        key: 'data',
        type: 'section',
        title: i18n`Data`,
        options: dataOptions,
      });

      sections.push(getDocumentationFieldEditionSection(editionHandler));
      sections.push(getIntegrationFieldEditionSection(store, editionHandler, mode));

      if (editionHandler.getValue(AssociationFieldDefinition_Role2Type)
        && (editionHandler.getValue(AssociationFieldDefinition_Role2Type) !== Concept)
        && !editionHandler.getValue(Field_Formula)) {
        // We don't want to be able to add formula to existing non computed formula
      } else {
        sections.push(getFormulaFieldEditionSection({
          store,
          parameterDefinitions,
          value: editionHandler.getValueOrDefault(Field_Formula),
          onChange: (value) => editionHandler.updateValues({ [Field_Formula]: value }),
          hasValue: () => editionHandler.getValue(Field_Formula) !== undefined,
          clearValue: () => editionHandler.updateValues({ [Field_Formula]: null }),
          returnType: arrayOf(conceptType(Concept)),
          forbidRemoveFormula: true,
        }));
      }
      return sections;
    },
    getFieldTitle: (store) => (editionHandler) => {
      const toTypeId = editionHandler.getValue(AssociationFieldDefinition_Role2Type);
      return toTypeId ? i18n`Association many to many with "${getObjectName(store, store.getObject(toTypeId))}" (n-n)` : i18n`Association many to many (n-n)`;
    },
    getFieldSubTitle: (store) => (editionHandler, modelTypeId) => {
      const toTypeId = editionHandler.getValue(AssociationFieldDefinition_Role2Type);
      const toTypeName = toTypeId ? getObjectName(store, store.getObject(toTypeId)) : undefined;
      const fromTypeName = getObjectName(store, store.getObject(modelTypeId));
      if (fromTypeName && toTypeName) {
        return computeSubTitle(fromTypeName, toTypeName);
      }
      return undefined;
    },
    getFieldIcon: (store) => (editionHandler) => {
      const toTypeId = editionHandler.getValue(AssociationFieldDefinition_Role2Type);
      return toTypeId && toTypeId !== Concept ? store.getObjectOrNull<ConceptDefinitionStoreObject>(toTypeId)?.[ConceptDefinition_Icon] as IconName | undefined : undefined;
    },
    isCreationEnabled: () => () => true,
    canCreate: (_) => (editionHandler) => {
      const hasFormula = Boolean(editionHandler.getValue(Field_Formula));
      const hasTargetType = Boolean(editionHandler.getValue(AssociationFieldDefinition_Role2Type));
      return hasFormula || hasTargetType;
    },
    onCreate: (store) => (editionHandler) => {
      const hasFormula = Boolean(editionHandler.getValue(Field_Formula));
      const fieldEditionDimensions = editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {};
      const dimensions = Object.values(fieldEditionDimensions);

      const role1TypeId = hasFormula ? Concept : dimensions[0].typeId;
      const role2TypeId = hasFormula ? Concept : editionHandler.getValue(AssociationFieldDefinition_Role2Type) as string;

      const fieldDefinitionId = store.createObject<AssociationFieldDefinitionRaw>({
        [Instance_Of]: AssociationFieldDefinition,
        [AssociationFieldDefinition_Role1Type]: role1TypeId,
        [AssociationFieldDefinition_Role2Type]: role2TypeId,
      });

      const fieldId = store.createObject<AssociationFieldRaw>({
        [Instance_Of]: AssociationField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [Field_ApiAlias]: editionHandler.getValue(Field_ApiAlias),
        [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
        [Field_Formula]: editionHandler.getValue(Field_Formula),
        [AssociationField_Definition]: fieldDefinitionId,
        [AssociationField_SourceRole]: 1,
      });
      linkFieldToFieldDimensions(store, fieldId, fieldEditionDimensions);

      if (!hasFormula) {
        const reverseFieldId = store.createObject<AssociationFieldRaw>({
          [Instance_Of]: AssociationField,
          [Field_Title]: editionHandler.getValue('reverseName'),
          [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
          [AssociationField_Definition]: fieldDefinitionId,
          [AssociationField_SourceRole]: 2,
        });
        createAndLinkFieldToConceptDefinitions(store, reverseFieldId, [role2TypeId]);
      }

      return fieldId;
    },
    inlineCreate: (store) => (conceptDefinitionId, extraFieldOptions) => ({
      type: 'transactional',
      getChipLabel: (state) => state[Field_Title] as string | undefined,
      getInitialState: (search) => ({ [Field_Title]: search }),
      creationOptions: [
        {
          key: AssociationFieldDefinition_Role2Type,
          title: i18n`With`,
          isValueValid: (value) => typeof value === 'string',
          render: (value, setValue) => (
            <SearchAndSelect
              computeOptions={() => getWithOptions(store, true)}
              selectedOption={typeof value === 'string' ? getChipOptions(store, value) : undefined}
              onSelect={(option) => setValue(option?.id ?? undefined)}
              getInlineCreation={() => ({
                type: 'inline',
                onCreate: (name) => {
                  const newConceptDefinitionId = createConceptDefinition(store, name);
                  setValue(newConceptDefinitionId);
                  return newConceptDefinitionId;
                },
              })}
            />
          ),
        },
      ],
      onCreate: (state) => {
        const fieldDefinitionId = store.createObject<AssociationFieldDefinitionRaw>({
          [Instance_Of]: AssociationFieldDefinition,
          [AssociationFieldDefinition_Role1Type]: conceptDefinitionId,
          [AssociationFieldDefinition_Role2Type]: state[AssociationFieldDefinition_Role2Type] as string,
        });

        const newFieldId = store.createObject<AssociationFieldRaw>(joinObjects(
          extraFieldOptions,
          {
            [Instance_Of]: AssociationField,
            [Field_Title]: state[Field_Title] as string | undefined,
            [AssociationField_Definition]: fieldDefinitionId,
            [AssociationField_SourceRole]: 1,
          }
        ));
        createAndLinkFieldToConceptDefinitions(store, newFieldId, [conceptDefinitionId]);

        const reverseFieldId = store.createObject<AssociationFieldRaw>(joinObjects(
          extraFieldOptions,
          {
            [Instance_Of]: AssociationField,
            [AssociationField_Definition]: fieldDefinitionId,
            [AssociationField_SourceRole]: 2,
          }
        ));
        createAndLinkFieldToConceptDefinitions(store, reverseFieldId, [state[AssociationFieldDefinition_Role2Type] as string]);

        return newFieldId;
      },

    }),
    ofField: (store, fieldId, handler) => ({
      getTypeLabel: () => {
        const { definitionId, sourceRole } = handler.resolveConfiguration();
        const toTypeName = getObjectName(
          store,
          store.getObject(definitionId).navigate(sourceRole === 1 ? AssociationFieldDefinition_Role2Type : AssociationFieldDefinition_Role1Type)
        );
        return i18n`Association many to many with "${toTypeName}" (n-n)`;
      },
      getIcon: () => {
        const targetType = handler.getTargetType?.();
        if (targetType && isInstanceOf<ConceptDefinitionStoreObject>(targetType, ConceptDefinition)) {
          return {
            name: targetType[ConceptDefinition_Icon] as IconName,
            borderColor: computeBorderColor(targetType[ConceptDefinition_ChipBackgroundColor] ?? conceptDefinitionChipBackgroundColor),
            color: conceptDefinitionChipBackgroundColor,
          };
        } else if (targetType?.id === Concept) {
          return {
            name: IconName.spoke,
            color: base.color.gray['200'],
          };
        } else if (targetType) {
          return getModelTypeIcon(store, targetType.id);
        } else {
          return undefined;
        }
      },
      getInitialState: (conceptDefinitionId) => {
        const field = store.getObject<AssociationFieldStoreObject>(fieldId);
        return joinObjects(
          getApiAliasInitialState(store, fieldId),
          {
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            reverseName: getReverseAssociationField(field)?.[Field_Title],
            [AssociationFieldDefinition_Role2Type]: getFieldUtilsHandler(store, fieldId)?.getTargetType?.()?.id,
            [Field_IntegrationOnly]: field[Field_IntegrationOnly],
            [Field_Formula]: field[Field_Formula],
            [FieldIntegrationOnlyDisabled]: field[Field_IsCore],
            [FIELD_DIMENSIONS_READONLY]: field[Field_IsCore],
            isReverseFieldCore: getReverseAssociationField(field)?.[Field_IsCore],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(store, fieldId, conceptDefinitionId),
            [AssociationField_Field_TargetFilter]: field[AssociationField_Field_TargetFilter],
          }
        );
      },
      submitFieldUpdate: (stateToSubmit, conceptDefinitionId) => {
        store.updateObject<AssociationFieldRaw>(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: stateToSubmit[Field_IntegrationOnly],
          [Field_Formula]: stateToSubmit[Field_Formula],
          [AssociationField_Field_TargetFilter]: stateToSubmit[AssociationField_Field_TargetFilter],
        });

        const reverseFieldId = getReverseAssociationField(store.getObject(fieldId))?.id;
        if (reverseFieldId) {
          store.updateObject<AssociationFieldRaw>(reverseFieldId, {
            [Field_Title]: stateToSubmit.reverseName,
          });
        }

        submitDimensionUpdate(store, fieldId, conceptDefinitionId, stateToSubmit[FIELD_EDITION_DIMENSIONS] ?? {});
      },
      duplicateFieldDefinition: () => {
        const field = store.getObject<AssociationFieldStoreObject>(fieldId);
        const associationFieldDefinitionStoreObject = field.navigate<AssociationFieldDefinitionStoreObject>(AssociationField_Definition);
        const [associationField1, associationField2] = associationFieldDefinitionStoreObject.navigateBack<AssociationFieldStoreObject>(AssociationField_Definition);

        const newAssociationFiledDefinitionId = store.createObject<AssociationFieldDefinitionRaw>({
          [Instance_Of]: AssociationFieldDefinition,
          [AssociationFieldDefinition_Role1Type]: associationFieldDefinitionStoreObject[AssociationFieldDefinition_Role1Type],
          [AssociationFieldDefinition_Role2Type]: associationFieldDefinitionStoreObject[AssociationFieldDefinition_Role2Type],
        });
        const withFormula = Boolean(associationField1[Field_Formula]);
        const fieldDimensionMapping = generateDuplicatedFieldDimensionId(store, associationField1.id);
        const newAssociationField1Id = store.createObject<AssociationFieldRaw>({
          [Instance_Of]: AssociationField,
          [Field_Title]: `${associationField1[Field_Title]} (copy)`,
          [Field_Documentation]: associationField1[Field_Documentation],
          [Field_IsDocumentationInline]: associationField1[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: associationField1[Field_IntegrationOnly],
          [Field_Formula]: duplicateFormula(associationField1[Field_Formula], fieldDimensionMapping),
          [AssociationField_Definition]: newAssociationFiledDefinitionId,
          [AssociationField_SourceRole]: associationField1[AssociationField_SourceRole],
          [AssociationField_Field_TargetFilter]: associationField1[AssociationField_Field_TargetFilter],
        });
        duplicateFieldDimensionWithNewField(store, newAssociationField1Id, fieldDimensionMapping);
        if (!withFormula) {
          const fieldDimensionMapping2 = generateDuplicatedFieldDimensionId(store, associationField2.id);
          const newAssociationField2Id = store.createObject<AssociationFieldRaw>({
            [Instance_Of]: AssociationField,
            [Field_Title]: `${associationField2[Field_Title]} (copy)`,
            [Field_Documentation]: associationField2[Field_Documentation],
            [Field_IsDocumentationInline]: associationField2[Field_IsDocumentationInline],
            [Field_IntegrationOnly]: associationField2[Field_IntegrationOnly],
            [AssociationField_Definition]: newAssociationFiledDefinitionId,
            [AssociationField_SourceRole]: associationField2[AssociationField_SourceRole],
          });
          duplicateFieldDimensionWithNewField(store, newAssociationField2Id, fieldDimensionMapping2);
          return field[AssociationField_SourceRole] === 1 ? newAssociationField1Id : newAssociationField2Id;
        } else {
          return newAssociationField1Id;
        }
      },
    }),
  },
  renderField: (store, fieldId) => ({ dimensionsMapping, readOnly, focusOnMount }) => {
    const parsedDimension = parseDimensionMapping(dimensionsMapping);
    if (parsedDimension.type !== ParsedDimensionType.MonoDimensional) {
      return (
        <MultidimensionalAssociationFieldRenderer
          fieldId={fieldId}
          dimensionsMapping={dimensionsMapping}
        />
      );
    }

    const { isSaneValue, getValueResolution, getTargetType } = associationFieldHandler(store, fieldId);
    const targetTypeId = getTargetType?.()?.id;
    if (!targetTypeId) {
      return null;
    }

    const valueResolution = getValueResolution(dimensionsMapping);

    const onLink = buildOnLink(store, fieldId);

    let formattedError;
    if (valueResolution.error) {
      formattedError = formatFieldResolutionErrorForUser(store, valueResolution.error, fieldId);
    } else {
      const sanityError = isSaneValue(parsedDimension.objectId).error;
      if (sanityError) {
        formattedError = formatErrorForUser(store, new InvalidFieldError(fieldId, sanityError));
      }
    }

    return (
      <ConceptChipList
        conceptId={parsedDimension.objectId}
        fieldId={fieldId}
        value={valueResolution.value}
        error={formattedError}
        readOnly={readOnly || valueResolution.isComputed}
        getInlineCreation={
          canCreateAssociation(targetTypeId)
            ? getInlineCreationBuilder(store, targetTypeId, (newInstanceId) => onLink(newInstanceId, parsedDimension.objectId))
            : undefined
        }
        onLink={onLink}
        onUnlink={buildOnUnlink(store, fieldId)}
        focusOnMount={focusOnMount}
      />
    );
  },
  input: (store, _, handler) => ({
    render: ({ value, onSubmit, focusOnMount, readOnly, onEditionStart, onEditionStop, isEditing, aclHandler: { canCreateObject } }) => {
      const targetType = handler.getTargetType?.();
      if (!targetType) {
        return null;
      }
      const applyUpdateOnState = (state: string[], update: AssociationUpdate): string[] => {
        switch (update.action) {
          case 'add':
            return Array.from(new Set([...state, ...update.objectIds]));
          case 'set':
            return update.objectIds;
          case 'remove':
            return state.filter((id) => !update.objectIds.includes(id));
        }
      };
      return (
        <SimpleInput<string[]>
          initialValue={value}
          onSubmit={onSubmit}
        >
          {(props) => (
            <AssociationFieldInputRenderer
              value={props.value}
              onChange={(update) => props.onChange(applyUpdateOnState(props.value, update))}
              onSubmit={(update) => props.onSubmit(applyUpdateOnState(props.value, update))}
              onCancel={props.onCancel}
              focusOnMount={focusOnMount}
              readOnly={readOnly}
              onEditionStart={onEditionStart}
              onEditionStop={onEditionStop}
              isEditing={isEditing}
              canCreateObject={canCreateObject}
              targetType={targetType}
            />
          )}
        </SimpleInput>
      );
    },
    getInitialState: () => [],
    persistStateOnConcept: (dimension, value) => handler.updateValue(dimension, { action: 'set', objectIds: value.filter((id) => store.getObjectOrNull(id)) }),
  }),
  getUpdateOperationInput: (store, fieldId, { getTargetType, updateOperationHandlers: { INITIALIZE, REPLACE, ADD, REMOVE, CLEAR } }) => (operation) => {
    const { title, getIcon } = getGenericOperationMetadata(store, operation, fieldId);
    return {
      title,
      getIcon,
      render: ({ onSubmit, parameterDefinitions }) => {
        const operationSelectorLine = (
          <UpdateOperationSelector<typeof associationFieldHandler>
            selectedOperationAction={operation?.action}
            operationOptions={[
              { id: 'INITIALIZE', label: i18n`Initialize`, onSelect: () => onSubmit({ action: 'INITIALIZE', payload: INITIALIZE.sanitizeOperation(operation) }) },
              { id: 'REPLACE', label: i18n`Replace`, onSelect: () => onSubmit({ action: 'REPLACE', payload: REPLACE.sanitizeOperation(operation) }) },
              { id: 'ADD', label: i18n`Add`, onSelect: () => onSubmit({ action: 'ADD', payload: ADD.sanitizeOperation(operation) }) },
              { id: 'REMOVE', label: i18n`Remove`, onSelect: () => onSubmit({ action: 'REMOVE', payload: REMOVE.sanitizeOperation(operation) }) },
              { id: 'CLEAR', label: i18n`Clear`, onSelect: () => onSubmit({ action: 'CLEAR', payload: CLEAR.sanitizeOperation(operation) }) },
            ]}
          />
        );

        let operationInputsLines = null;
        const { input } = associationFieldDefinition(store).getHandler(fieldId);
        if (input && operation && operation.action !== 'CLEAR' && operation.payload) {
          operationInputsLines = (
            <UpdateOperationInput<typeof associationFieldHandler>
              valueInput={input}
              initialValue={operation.payload}
              onTypeChange={(newType) => {
                onSubmit({ action: operation.action, payload: newType === 'value' ? { type: 'value', value: [] } : { type: 'path', path: [] } });
              }}
              onValueSubmit={(newValue) => onSubmit({ action: operation.action, payload: newValue })}
              parameterDefinitions={parameterDefinitions}
              valuePathHandler={createPathConfigurationHandler(
                store,
                parameterDefinitions,
                [getConceptTypeValidator(store, true, getTargetType?.()?.id)]
              )}
            />
          );
        }

        return (
          <>
            {operationSelectorLine}
            {operationInputsLines}
          </>
        );
      },
    };
  },
  renderBlockField: (store, fieldId) => (_, displayOptions, blockFieldProps, layoutParametersMapping, viewDimensions, fieldBlockDisplayId) => {
    const { viewDefinitions, withFilters } = displayOptions;
    return (
      <ViewLoadingStateContextProvider>
        <ViewsGroupBlock
          fieldId={fieldId}
          viewDimensions={viewDimensions}
          viewFilters={{
            filterKey: fieldBlockDisplayId,
            hasFilters: withFilters,
            getViewFilters: () => store.getObject<FieldBlockDisplayStoreObject>(fieldBlockDisplayId)
              .navigateBack<AssociationFilterStoreObject | ConditionFilterStoreObject>(FieldBlockDisplay_ViewFilters),
          }}
          viewDefinitions={viewDefinitions ?? []}
          layoutParametersMapping={layoutParametersMapping}
          blockFieldProps={blockFieldProps}
          widgetDisplay={defaultWidgetDisplay}
        />
      </ViewLoadingStateContextProvider>
    );
  },
  renderExportConfiguration: () => ({ configuration, onChange, conceptDefinitionId }) => (
    <MultipleRelationExportConfiguration
      configuration={configuration}
      conceptDefinitionId={conceptDefinitionId}
      onChange={onChange}
    />
  ),
  getActivityProperties: () => () => [],
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({
    key: fieldId,
    propertyId: fieldId,
    sortable: true,
    focusable: true,
  }),
  getComparatorHandler: (store, _, { resolveConfiguration, getValueResolution }) => {
    const { formula } = resolveConfiguration();
    let valueExtractor: (dimensionsMapping: DimensionsMapping) => string[];
    if (formula === undefined) {
      valueExtractor = (dimensionsMapping) => (
        getValueResolution(dimensionsMapping).value
          .map((instance) => getInstanceLabelOrUndefined(store, instance))
          .sort(comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString))
      );
    } else {
      // For formula, we maintain the original order of types to give an impression of control on group merging
      valueExtractor = (dimensionsMapping) => {
        const typeMap = createAutoProvisioningMap<string, string[]>();
        const typeOrderIds: string[] = [];
        const instances = getValueResolution(dimensionsMapping).value;
        for (let i = 0; i < instances.length; i += 1) {
          const instance = instances[i];
          if (!typeMap.has(instance[Instance_Of] as string)) {
            // New type, track the order
            typeOrderIds.push(instance[Instance_Of] as string);
          }

          typeMap.getOrCreate(instance[Instance_Of] as string, () => [])
            .push(getInstanceLabelOrUndefined(store, instance));
        }
        return typeOrderIds
          .flatMap((typeId) => typeMap.getOrCreate(typeId, () => []).sort(comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString)));
      };
    }

    return (direction) => ({
      comparator: comparing(compareArray, direction === TableSortDirection.desc),
      extractValue: (dimensionsMapping) => {
        const labels = valueExtractor(dimensionsMapping);
        // We return undefined when the size is 0 to benefit from the pushUndefinedToEnd for the array comparator
        return labels.length === 0 ? undefined : labels;
      },
    } satisfies FieldComparatorHandler<string[] | undefined>);
  },
  blockDisplayOptionsHandler: getViewsBlockDisplayOptionsHandler,
});
