import type { ExtractDslHandlerTypes, GenericGetDslFieldHandler } from 'yooi-modules/modules/common/fields/FieldModuleDslType';
import type { FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { IconName } from '../../../../components/atoms/Icon';
import type { FrontObjectStore } from '../../../../store/useStore';
import type { Theme } from '../../../../theme/themeUtils';
import i18n from '../../../../utils/i18n';
import { getFieldLabel } from '../../fieldUtils';

export const getGenericOperationMetadata: <DslFieldHandler extends GenericGetDslFieldHandler>(
  store: FrontObjectStore,
  operation: ExtractDslHandlerTypes<DslFieldHandler>['UpdateOperations'] | undefined,
  fieldId: string,
) => {
  title: string | undefined,
  getIcon: (theme: Theme) => {
    name: IconName,
    color: string,
    backgroundColor: string,
  } | undefined,
} = (store, operation, fieldId) => {
  const field = store.getObject<FieldStoreObject>(fieldId);
  const fieldLabel = getFieldLabel(store, field);

  switch (operation?.action) {
    case 'CLEAR': {
      return {
        title: i18n`Clear "${fieldLabel}"`,
        getIcon: (theme: Theme) => ({
          name: IconName.delete,
          color: theme.color.text.primary,
          backgroundColor: theme.color.background.neutral.subtle,
        }),
      };
    }
    case 'INITIALIZE': {
      return {
        title: i18n`Initialize "${fieldLabel}"`,
        getIcon: (theme: Theme) => ({
          name: IconName.start,
          color: theme.color.background.info.default,
          backgroundColor: theme.color.background.info.light,
        }),
      };
    }
    case 'REPLACE': {
      return {
        title: i18n`Replace "${fieldLabel}"`,
        getIcon: (theme: Theme) => ({
          name: IconName.repeat,
          color: theme.color.text.warning,
          backgroundColor: theme.color.background.warning.light,
        }),
      };
    }
    case 'INCREMENT': {
      return {
        title: i18n`Increment "${fieldLabel}"`,
        getIcon: (theme: Theme) => ({
          name: IconName.exposure_plus_1,
          color: theme.color.text.brand,
          backgroundColor: theme.color.background.primarylight.default,
        }),
      };
    }
    case 'RUN_TRANSITION': {
      return {
        title: i18n`Run transition on "${fieldLabel}"`,
        getIcon: (theme: Theme) => ({
          name: IconName.play_circle,
          color: theme.color.text.brand,
          backgroundColor: theme.color.background.primarylight.default,
        }),
      };
    }
    case 'ADD': {
      return {
        title: i18n`Add in "${fieldLabel}"`,
        getIcon: (theme: Theme) => ({
          name: IconName.add_circle,
          color: theme.color.text.brand,
          backgroundColor: theme.color.background.primarylight.default,
        }),
      };
    }
    case 'REMOVE': {
      return {
        title: i18n`Remove in "${fieldLabel}"`,
        getIcon: (theme: Theme) => ({
          name: IconName.remove,
          color: theme.color.text.danger,
          backgroundColor: theme.color.background.danger.light,
        }),
      };
    }
    default: {
      return {
        title: undefined,
        getIcon: () => undefined,
      };
    }
  }
};
