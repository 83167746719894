import type { PathStep } from '../../../../conceptModule';

interface UuidDimensionExportConfiguration {
  type: 'uuid',
}

interface PathDimensionExportConfiguration {
  type: 'path',
  path?: PathStep[],
}

export type DimensionExportConfiguration = UuidDimensionExportConfiguration | PathDimensionExportConfiguration;

export enum DimensionDisplayAxis {
  x = 'x',
  y = 'y',
}

export interface DimensionDisplayOption {
  id: string,
  axis: DimensionDisplayAxis,
  withLegend?: boolean,
  exportConfiguration?: DimensionExportConfiguration,
}

export interface DimensionsDisplayOptionsFeatureDefinition<T extends DimensionDisplayOption = DimensionDisplayOption> {
  dimensionsDisplay?: T[],
}
