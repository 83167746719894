import type { FunctionComponent, ReactNode } from 'react';
import { useState } from 'react';
import type {
  AutomationActionDefinitionStoreObject,
  AutomationActionStoreObject,
  AutomationRuleParameters,
  AutomationRuleStoreObject,
  ScheduledTrigger,
} from 'yooi-modules/modules/automationModule';
import { getForEachOutputParameterDefinitions, getTriggerOutputParameterDefinitions, RepeatType, TriggerType } from 'yooi-modules/modules/automationModule';
import {
  AutomationAction_Rule,
  AutomationActionDefinition,
  AutomationActionEmail,
  AutomationActionGenerateData,
  AutomationRule,
  AutomationRule_Actions,
  AutomationRule_Description,
  AutomationRule_IsEnabled,
  AutomationRule_Name,
  AutomationRule_Parameters,
  AutomationRule_Trigger,
  AutomationRule_UserId,
} from 'yooi-modules/modules/automationModule/ids';
import type { Filters, PathStep } from 'yooi-modules/modules/conceptModule';
import { isSingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { formatForStorage, getLocalZoneName, joinObjects, periodicities, PeriodicityType } from 'yooi-utils';
import Icon, { IconColorVariant, IconName, IconSizeVariant } from '../../../components/atoms/Icon';
import { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import Tooltip from '../../../components/atoms/Tooltip';
import Typo from '../../../components/atoms/Typo';
import Banner, { BannerVariant } from '../../../components/molecules/Banner';
import ConfirmationModal, { ConfirmationModalVariant } from '../../../components/molecules/ConfirmationModal';
import MasterDetailList from '../../../components/molecules/MasterDetailList';
import { NavigationTitlePathElements } from '../../../components/molecules/NavigationTitle';
import OverflowMenu from '../../../components/molecules/OverflowMenu';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../../components/molecules/Table';
import ToggleButton, { ElementPosition } from '../../../components/molecules/ToggleButton';
import BaseLayout from '../../../components/templates/BaseLayout';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../components/templates/BlockTitle';
import Header from '../../../components/templates/Header';
import HorizontalBlock, { HorizontalBlockVariant } from '../../../components/templates/HorizontalBlock';
import LeftPanel from '../../../components/templates/LeftPanel';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useAuth from '../../../store/useAuth';
import useStore from '../../../store/useStore';
import fontDefinition from '../../../theme/fontDefinition';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { safeSessionStorageValue } from '../../../utils/sessionStorageUtils';
import { formatOrUndef } from '../../../utils/stringUtils';
import useDerivedState from '../../../utils/useDerivedState';
import useNavigation, { NavigationElementContainer } from '../../../utils/useNavigation';
import useTheme from '../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../utils/useUsageContext';
import FilterComposite from '../../_global/filter/filterComposite/FilterComposite';
import { useFilterStorage } from '../../_global/filter/useFilterSessionStorage';
import HeaderFromStore, { HeaderFromStoreVariant } from '../../_global/HeaderFromStore';
import StoreTextInputField from '../../_global/input/StoreTextInputField';
import { searchFilterFunction } from '../../_global/listFilterFunctions';
import type { Option } from '../../_global/modelTypeUtils';
import { getAutomationRuleUrl, getUnknownChip } from '../../_global/modelTypeUtils';
import type { NavigationFilter } from '../../_global/navigationUtils';
import { getNavigationElement } from '../../_global/navigationUtils';
import TopBar from '../../_global/topBar/TopBar';
import useFilterAndSort, { buildBooleanColumnComparatorHandler, buildStringColumnComparatorHandler } from '../../_global/useFilterAndSort';
import AutomationParametersList from './AutomationParametersList';
import AutomationRuleCollaborationEventTriggerOptions from './AutomationRuleCollaborationEventTriggerOptions';
import AutomationRuleInstanceEventTriggerOptions from './AutomationRuleInstanceEventTriggerOptions';
import type { AutomationFilterConfiguration } from './AutomationRuleList';
import AutomationRuleScheduledTriggerOptions from './AutomationRuleScheduledTriggerOptions';
import { actionTypesOptions, duplicateRule, isRuleFulfilled, triggerTypesOptions } from './automationUtils';
import GenerateDataAction from './GenerateDataAction';
import MailAction from './MailAction';
import TemplateHelperBlock from './TemplateHelperBlock';

const useStyles = makeStyles({
  requiredFieldsTypo: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacingRem.l,
  },
  iconContainer: {
    display: 'flex',
    height: fontDefinition.blockPrimaryTitle.lineHeight,
  },
  bold: {
    fontWeight: 'bold',
    whiteSpace: 'pre-wrap',
  },
}, 'automationRuleDetail');

interface ConfirmationModalState {
  title: string,
  confirmLabel?: string,
  render?: () => ReactNode,
  variant?: ConfirmationModalVariant,
  callback: () => void,
}

interface AutomationRuleDetailProps {
  ruleId: string,
  navigationElement: string,
}

const AutomationRuleDetail: FunctionComponent<AutomationRuleDetailProps> = ({ ruleId, navigationElement }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const { loggedUserId } = useAuth();

  const navigation = useNavigation<NavigationFilter>();

  const rule = store.getObject<AutomationRuleStoreObject>(ruleId);

  const [search, setSearch] = useState<string | undefined>(undefined);

  const filterId = AutomationRule;
  const [triggerFilters = []] = useFilterStorage<'triggerFilters', AutomationFilterConfiguration>(filterId, 'triggerFilters');
  const [actionFilters = []] = useFilterStorage<'actionFilters', AutomationFilterConfiguration>(filterId, 'actionFilters');
  const [activationFilters = []] = useFilterStorage<'activationFilters', AutomationFilterConfiguration>(filterId, 'activationFilters');
  const filterFunction = searchFilterFunction<{
    name: string | undefined,
  }>(store, [safeSessionStorageValue<AutomationFilterConfiguration | undefined>(filterId)?.nameSearch, search], ['name']);

  const { generateList } = useFilterAndSort(
    AutomationRule,
    store.getObject(AutomationRule)
      .navigateBack<AutomationRuleStoreObject>(Class_Instances)
      .filter((r) => {
        if (navigationElement === 'notification_settings') {
          return r[AutomationRule_UserId] === loggedUserId;
        } else {
          return r[AutomationRule_UserId] === undefined;
        }
      })
      .map((r) => ({
        key: r.id,
        name: r[AutomationRule_Name],
        description: r[AutomationRule_Description],
        enabled: Boolean(r[AutomationRule_IsEnabled]),
        url: getAutomationRuleUrl(r.id, !!r[AutomationRule_UserId]),
        triggerType: r[AutomationRule_Trigger]?.type,
        actionType: r.navigateBack<AutomationActionStoreObject>(AutomationRule_Actions).at(0)?.[Instance_Of],
      })),
    (item) => (
      (filterFunction?.(item) ?? true)
      && (triggerFilters.length === 0 || (item.triggerType !== undefined && triggerFilters.includes(item.triggerType)))
      && (actionFilters.length === 0 || (item.actionType !== undefined && actionFilters.includes(item.actionType)))
      && (activationFilters.length === 0 || activationFilters.includes(item.enabled))
    ),
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case 'name':
          case 'description':
          case 'url':
            return buildStringColumnComparatorHandler(key, direction);
          case 'enabled':
            return buildBooleanColumnComparatorHandler(key, direction);
          default:
            return undefined;
        }
      },
      initial: { key: 'name', direction: TableSortDirection.asc },
    },
    undefined,
    [search]
  );

  // trigger
  const trigger = rule[AutomationRule_Trigger];

  // action
  const actions = rule.navigateBack(AutomationRule_Actions);
  const action = actions.at(0); // Only support one action for now

  const actionDefinitions: Option[] = store.getObject(AutomationActionDefinition)
    .navigateBack<AutomationActionDefinitionStoreObject>(Class_Instances)
    .map(({ id }) => actionTypesOptions[id] ?? getUnknownChip(id));

  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModalState | undefined>();

  const doDelete = (id: string) => {
    setConfirmationModal({
      title: i18n`Are you sure you want to delete this automation ?`,
      confirmLabel: i18n`Delete`,
      variant: ConfirmationModalVariant.delete,
      callback: () => {
        store.deleteObject(id);
        navigation.push(navigationElement, { pathname: getNavigationElement(store, { key: navigationElement }).path });
      },
    });
  };

  const doDuplicateRule = (id: string) => {
    duplicateRule(store, id, (newId) => navigation.push(navigationElement, { pathname: getAutomationRuleUrl(newId, !!rule[AutomationRule_UserId]) }));
  };

  const isEnabled = rule[AutomationRule_IsEnabled] ?? false;

  const automationRuleParametersList: AutomationRuleParameters = Object.fromEntries(Object.entries(rule[AutomationRule_Parameters] as Record<string, PathStep[]> | undefined ?? {})
    .map(([key, path]) => [key, { path }]));

  const isForEachFilled = Boolean(Object.entries(automationRuleParametersList).length);
  const [expandBlock, setExpandBlock] = useDerivedState(() => isForEachFilled, [ruleId]);
  const isForEachGreyed = !expandBlock && !isForEachFilled;

  return (
    <NavigationElementContainer
      element={{
        key: ruleId,
        name: formatOrUndef(rule[AutomationRule_Name]),
        element: NavigationTitlePathElements.automation,
        path: getAutomationRuleUrl(ruleId, !!rule[AutomationRule_UserId]),
      }}
    >
      <BaseLayout
        topBar={(<TopBar />)}
        leftPanel={(
          <LeftPanel>
            <MasterDetailList
              list={
                generateList().list
                  .map(({ item, ...entry }) => (
                    joinObjects(
                      entry,
                      {
                        render: () => (
                          <Tooltip title={formatOrUndef(item.name)}>
                            <Typo maxLine={1}>{formatOrUndef(item.name)}</Typo>
                          </Tooltip>
                        ),
                        to: () => ({ pathname: item.url }),
                      }
                    )
                  ))
              }
              search={{ value: search, setValue: setSearch }}
            />
          </LeftPanel>
        )}
        header={(
          <Header
            firstLine={(
              <HeaderFromStore
                instanceId={ruleId}
                propertyId={AutomationRule_Name}
                placeholder={i18n`Click to edit name`}
                variant={HeaderFromStoreVariant.compact}
                actions={[
                  (isEnabled && !isRuleFulfilled(rule)
                    ? (<Banner key="ruleBanner" variant={BannerVariant.danger} title={i18n`Required field(s) * missing for this automation to run successfully`} />)
                    : (
                      <div key="requiredFieldsTypo" className={classes.requiredFieldsTypo}>
                        <div className={classes.iconContainer}>
                          <Icon name={IconName.emergency} size={IconSizeVariant.xs} colorVariant={IconColorVariant.secondary} />
                        </div>
                        <Typo color={theme.color.text.secondary} fullWidth>{i18n` Indicates required fields`}</Typo>
                      </div>
                    )),
                  (
                    <ToggleButton
                      key="active"
                      name={isEnabled ? i18n`Enabled` : i18n`Disabled`}
                      icon={isEnabled ? IconName.toggle_on : IconName.toggle_off}
                      onClick={() => {
                        if (isEnabled) {
                          setConfirmationModal({
                            title: i18n`Are you sure you want to disable this automation ?`,
                            confirmLabel: i18n`Disable`,
                            variant: ConfirmationModalVariant.delete,
                            callback: () => store.updateObject(rule.id, { [AutomationRule_IsEnabled]: false }),
                          });
                        } else {
                          setConfirmationModal({
                            title: i18n`Are you sure you want to enable this automation ?`,
                            confirmLabel: i18n`Enable`,
                            callback: () => store.updateObject(rule.id, { [AutomationRule_IsEnabled]: true }),
                          });
                        }
                      }}
                      active={isEnabled}
                      type={ElementPosition.alone}
                    />
                  ),
                  (
                    <OverflowMenu
                      key="overflowMenu"
                      buttonVariant={IconOnlyButtonVariants.tertiary}
                      menuItems={[
                        {
                          key: 'duplicate',
                          name: i18n`Duplicate`,
                          icon: IconName.content_copy_outline,
                          onClick: () => doDuplicateRule(ruleId),
                        },
                        {
                          key: 'delete',
                          name: i18n`Delete`,
                          icon: IconName.delete,
                          onClick: () => doDelete(ruleId),
                          danger: true,
                        },
                      ]}
                    />
                  ),
                ]}
              />
            )}
            withBottomBorder
          />
        )}
        content={(
          <VerticalBlock>
            <VerticalBlock asBlockContent withSeparation>
              <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compact}>
                <BlockTitle title={i18n`Description`} iconName={IconName.subject} />
                <BlockContent>
                  <StoreTextInputField
                    initialValue={rule[AutomationRule_Description]}
                    onSubmit={(description) => store.updateObject(rule.id, { [AutomationRule_Description]: description })}
                    placeholder={i18n`Add description`}
                  />
                </BlockContent>
              </HorizontalBlock>
            </VerticalBlock>
            <VerticalBlock asBlockContent withSeparation>
              <BlockTitle title={i18n`Trigger`} iconName={IconName.offline_bolt} variant={BlockTitleVariant.secondary} />
              <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compact}>
                <BlockTitle
                  title={i18n`Type`}
                  mandatoryIcon={{
                    tooltip: trigger?.type ? undefined : i18n`Required field`,
                    color: trigger?.type ? IconColorVariant.secondary : undefined,
                  }}
                />
                <BlockContent>
                  <SearchAndSelect
                    computeOptions={() => Object.values(triggerTypesOptions)}
                    placeholder={i18n`Select type`}
                    selectedOption={trigger?.type !== undefined ? triggerTypesOptions[trigger.type] : undefined}
                    onSelect={(option) => {
                      if (option && option.id !== trigger?.type) {
                        if (option.id === TriggerType.scheduled) {
                          const date = formatForStorage(periodicities.day.getStartOfPeriod(new Date()), true);
                          const defaultDailyTrigger: ScheduledTrigger = {
                            type: option.id,
                            fromDate: date ? { period: PeriodicityType.day, date } : undefined,
                            repeatRule: { type: RepeatType.day, times: 1 },
                            at: { hour: 12, tz: getLocalZoneName() },
                          };
                          store.updateObject<AutomationRuleStoreObject>(ruleId, {
                            [AutomationRule_Trigger]: joinObjects(
                              trigger ?? {},
                              defaultDailyTrigger
                            ),
                          });
                        } else {
                          store.updateObject<AutomationRuleStoreObject>(ruleId, {
                            [AutomationRule_Trigger]: joinObjects(
                              trigger ?? {},
                              { type: option.id }
                            ),
                          });
                        }
                      }
                    }}
                  />
                </BlockContent>
              </HorizontalBlock>
              <AutomationRuleInstanceEventTriggerOptions ruleId={ruleId} />
              <AutomationRuleCollaborationEventTriggerOptions ruleId={ruleId} />
              <AutomationRuleScheduledTriggerOptions ruleId={ruleId} />
            </VerticalBlock>
            <VerticalBlock asBlockContent withSeparation>
              <BlockTitle
                isGreyed={isForEachGreyed}
                buttonIcon={!isForEachFilled ? {
                  tooltip: !expandBlock ? i18n`Show` : i18n`Hide`,
                  iconName: !expandBlock ? IconName.keyboard_arrow_right : IconName.expand_more,
                  onClick: () => setExpandBlock((show) => !show),
                } : undefined}
                iconName={expandBlock ? IconName.repeat : undefined}
                title={i18n`For each`}
                variant={BlockTitleVariant.secondary}
              />
              {expandBlock && (
                <>
                  <BlockTitle
                    subtitle={i18n.jsx`Action will ${(<span key="forEachBold" className={classes.bold}>{i18n` repeat for each `}</span>)} item in the variable list below.`}
                  />
                  <BlockContent padded>
                    <AutomationParametersList
                      parametersList={automationRuleParametersList}
                      parameterDefinitions={getTriggerOutputParameterDefinitions(store, ruleId)}
                      onChange={(newParams) => {
                        store.updateObject<AutomationRuleStoreObject>(ruleId, { [AutomationRule_Parameters]: newParams });
                      }}
                    />
                  </BlockContent>
                </>
              )}
            </VerticalBlock>
            <VerticalBlock asBlockContent withSeparation>
              <BlockTitle title={i18n`Action`} iconName={IconName.play_circle} variant={BlockTitleVariant.secondary} />
              <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compact}>
                <BlockTitle title={i18n`Only if`} />
                <BlockContent padded>
                  <SpacingLine>
                    <UsageContextProvider usageVariant={UsageVariant.inline}>
                      <FilterComposite
                        getCustomCompositeLabel={(count) => {
                          if (count === 0) {
                            return i18n`No conditions`;
                          } else if (count === 1) {
                            return i18n`1 condition is met`;
                          } else {
                            return i18n`${count} conditions are met`;
                          }
                        }}
                        filtersDefinition={{
                          updateFilters: (filters: Filters[]) => {
                            store.updateObject<AutomationRuleStoreObject>(ruleId, {
                              [AutomationRule_Trigger]: joinObjects(
                                trigger ?? {},
                                { filters: filters[0] }
                              ),
                            });
                          },
                          definition: [
                            {
                              filter: trigger?.filters,
                              subtitle: i18n`This action will be executed only...`,
                            },
                          ],
                        }}
                        parameterDefinitions={getForEachOutputParameterDefinitions(store, ruleId).filter(isSingleParameterDefinition)}
                      />
                    </UsageContextProvider>
                  </SpacingLine>
                </BlockContent>
              </HorizontalBlock>
              <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compact}>
                <BlockTitle
                  title={i18n`Then...`}
                  mandatoryIcon={{
                    tooltip: action ? undefined : i18n`Required field`,
                    color: action ? IconColorVariant.secondary : undefined,
                  }}
                />
                <VerticalBlock asBlockContent>
                  <BlockContent
                    action={
                      action?.[Instance_Of] === AutomationActionGenerateData ? (
                        <SpacingLine>
                          <Icon
                            name={IconName.info}
                            colorVariant={IconColorVariant.info}
                            tooltip={i18n`The automation being asynchronous, it may take a few minutes to see its results.\nAdditionally, you can chain automations, allowing one data-generating rule to trigger another, limited to 5 iterations.`}
                          />
                        </SpacingLine>
                      ) : undefined
                    }
                  >
                    <SpacingLine>
                      <SearchAndSelect
                        readOnly={Boolean(rule[AutomationRule_UserId])}
                        computeOptions={() => actionDefinitions}
                        placeholder={i18n`Select type`}
                        selectedOption={action?.[Instance_Of] ? actionDefinitions.find(({ id }) => id === action?.[Instance_Of])
                          ?? getUnknownChip(action?.[Instance_Of] as string) : undefined}
                        onSelect={(option) => {
                          if (option) {
                            const updateAction = () => {
                              store.getObject(ruleId).navigateBack(AutomationRule_Actions).forEach(({ id }) => store.deleteObject(id));
                              store.createObject({
                                [Instance_Of]: option.id,
                                [AutomationAction_Rule]: ruleId,
                              });
                            };
                            if (!action) {
                              updateAction();
                            } else {
                              setConfirmationModal({
                                title: i18n`Are you sure you want to update action type?`,
                                confirmLabel: i18n`Update`,
                                render: () => (<Typo>{i18n`All your action configuration will be lost`}</Typo>),
                                callback: updateAction,
                              });
                            }
                          }
                        }}
                      />
                    </SpacingLine>
                  </BlockContent>
                </VerticalBlock>
              </HorizontalBlock>
              <MailAction ruleId={ruleId} />
              <GenerateDataAction ruleId={ruleId} />
            </VerticalBlock>
            {action?.[Instance_Of] === AutomationActionEmail && (
              <TemplateHelperBlock />
            )}
            {confirmationModal !== undefined ? (
              <ConfirmationModal
                variant={confirmationModal.variant}
                title={confirmationModal?.title}
                open
                onConfirm={() => {
                  confirmationModal.callback();
                  setConfirmationModal(undefined);
                }}
                confirmLabel={confirmationModal.confirmLabel}
                onCancel={() => setConfirmationModal(undefined)}
                render={() => confirmationModal.render?.() ?? null}
              />
            ) : null}
          </VerticalBlock>
        )}
      />
    </NavigationElementContainer>
  );
};

export default AutomationRuleDetail;
