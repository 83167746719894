import { useState } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { FILTER_PARAMETER_LOGGED_USER } from 'yooi-modules/modules/conceptModule';
import type { DashboardParameterStoreObject } from 'yooi-modules/modules/dashboardModule';
import { Dashboard_Parameters, DashboardParameter_DefaultValue } from 'yooi-modules/modules/dashboardModule/ids';
import { joinObjects } from 'yooi-utils';
import useStore from '../../../store/useStore';
import { safeSessionStorageValue, sessionStoragePrefix } from '../../../utils/sessionStorageUtils';

type ParameterState = Record<string, { type: 'single', id: string | null }>;

const useDashboardParameterState = (dashboardId: string): [ParametersMapping, (parameterId: string, instanceId: string | null) => void] => {
  const store = useStore();

  const dashboard = store.getObject(dashboardId);

  const sessionStorageKey = `${dashboardId}_parameters`;
  const [selectedParameters, setSelectedParameters] = useState<ParameterState>(safeSessionStorageValue<ParameterState>(sessionStorageKey) ?? {});
  const parameterDefinitions = dashboard
    .navigateBack<DashboardParameterStoreObject>(Dashboard_Parameters)
    .reduce((accumulator, parameter) => {
      let actualValueId;
      const selectedValueId = selectedParameters[parameter.id]?.id;
      if (selectedValueId !== undefined) {
        actualValueId = selectedValueId && store.getObjectOrNull(selectedValueId) ? selectedValueId : undefined;
      } else if (parameter.navigateOrNull(DashboardParameter_DefaultValue)) {
        actualValueId = parameter[DashboardParameter_DefaultValue] as string;
      } else if (parameter[DashboardParameter_DefaultValue] === FILTER_PARAMETER_LOGGED_USER) {
        actualValueId = FILTER_PARAMETER_LOGGED_USER;
      }
      accumulator[parameter.id] = { type: 'single', id: actualValueId ?? undefined };
      return accumulator;
    }, {} as ParametersMapping);

  return [
    parameterDefinitions,
    (parameterId, instanceId) => {
      setSelectedParameters((current) => {
        const updatedValue = joinObjects(current, { [parameterId]: { type: 'single' as const, id: instanceId } });
        sessionStorage.setItem(`${sessionStoragePrefix}${sessionStorageKey}`, JSON.stringify(updatedValue));
        return updatedValue;
      });
    },
  ];
};

export default useDashboardParameterState;
