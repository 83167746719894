import { getCollaborationUserRecipientsIds } from 'yooi-modules/modules/collaborationModule';
import type { FieldSuggestedDisplayStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import {
  FieldBlockConceptInstanceDisplay,
  FieldBlockConceptInstanceDisplay_DisplayOverride,
  FieldBlockConceptInstanceDisplay_Role_Concept,
  FieldBlockConceptInstanceDisplay_Role_FieldBlockDisplay,
  FieldSuggestedDisplay,
  FieldSuggestedDisplay_Rank,
  FieldSuggestedDisplay_Role_ConceptDefinition,
  FieldSuggestedDisplay_Role_Field,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import type {
  AssociationFieldDefinitionStoreObject,
  AssociationFieldStoreObject,
  AttachmentStoreObject,
  ConceptStoreObject,
  DateFieldStoreObject,
  ExternalKeyFieldStoreObject,
  FieldStoreObject,
  NumberFieldStoreObject,
  SingleParameterDefinition,
  TimelineFieldStoreObject,
} from 'yooi-modules/modules/conceptModule';
import {
  buildDimensionalId,
  compilePath1,
  createValuePathResolver,
  FILTER_PARAMETER_LOGGED_USER,
  getConceptDefinitionValidFields,
  getEndOfPathFieldStep,
  getFieldDimensionOfModelType,
  getFieldUtilsHandler,
  getOpenConceptCollaborationIds,
  getViewerGroupsSet,
  isEmbeddedConceptInstance,
  isMultiplePath,
  isRelationFieldStorageValueValid,
  isSingleValueResolution,
  isWorkflowFieldStorageValueValid,
} from 'yooi-modules/modules/conceptModule';
import { appendTextAtTheEnd } from 'yooi-modules/modules/conceptModule/fields/textField';
import {
  Association,
  Association_Role_Definition,
  Association_Role_Role1TypeInstance,
  Association_Role_Role2TypeInstance,
  AssociationField_Definition,
  AssociationFieldDefinition_Role1Type,
  AssociationFieldDefinition_Role2Type,
  Attachment,
  Attachment_Revision,
  Attachment_Role_FieldDimensions,
  Attachment_Role_FileName,
  Attachment_VarRoles_Dimensions,
  AttachmentField,
  Concept,
  Concept_CreatedAt,
  Concept_Name,
  Concept_UpdatedAt,
  ConceptChipDisplay,
  ConceptChipDisplay_Rank,
  ConceptChipDisplay_Role_ConceptDefinition,
  ConceptChipDisplay_Role_Field,
  ConceptRoleUserAssignation,
  ConceptRoleUserAssignation_Role_Concept,
  ConceptRoleUserAssignation_Role_User,
  DateField,
  DateField_Rank,
  EmbeddingField,
  EmbeddingField_ToType,
  ExternalKeyField,
  Field_Formula,
  Field_IntegrationOnly,
  Group_Users,
  GroupMembershipDimension,
  ImageField,
  KinshipRelation,
  NumberField,
  RelationSingleField,
  TimelineField,
  TimelineField_Period,
  TimelineField_Rank,
  WorkflowField,
} from 'yooi-modules/modules/conceptModule/ids';
import {
  Dashboard_Parameters,
  Dashboard_Widgets,
  DashboardParameter_Dashboard,
  DashboardParameter_DefaultValue,
  DashboardParameter_Filters,
  DashboardParameter_Type,
} from 'yooi-modules/modules/dashboardModule/ids';
import type { LeftBarItemStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import { LeftBarItem, LeftBarItem_Path } from 'yooi-modules/modules/platformConfigurationModule/ids';
import { Resource } from 'yooi-modules/modules/resourceModule/ids';
import { collectTypes, isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances, Class_IsExternal, Class_Properties, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreReadOnly, ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
// eslint-disable-next-line yooi/no-restricted-dependency
import { isStoreObject } from 'yooi-store';
import type { DecoratedList, RichText } from 'yooi-utils';
import { compareProperty, compareRank, filterNullOrUndefined, joinObjects, newError, ranker, richTextToText, textToRichText } from 'yooi-utils';
import type { CreationOption, InlineCreationInline, InlineCreationTransactional } from '../../components/molecules/inlineCreationTypes';
import type { ACLHandler } from '../../store/useAcl';
import type { FrontObjectStore } from '../../store/useStore';
import i18n from '../../utils/i18n';
import { duplicateWidget } from './dashboardUtils';
import { getFieldHandler } from './fields/FieldLibrary';
import { getFieldLabel } from './fieldUtils';
import type { PathConfigurationHandler } from './pathConfigurationHandler';
import { createPathConfigurationHandler, StepValidationState } from './pathConfigurationHandler';

export interface InstanceTreeNode {
  key: string,
  parentKey: string | null,
  children: InstanceTreeNode[],
}

export const getEditorLinePathHandler = (store: ObjectStoreWithTimeseries, parameterDefinitions: SingleParameterDefinition[]): PathConfigurationHandler => (
  createPathConfigurationHandler(
    store,
    parameterDefinitions,
    [
      ({ path: currentPath }) => {
        const compiledPath = compilePath1(store, currentPath);
        const { index } = getEndOfPathFieldStep(compiledPath);
        const truncatedPath = index ? [...compiledPath].slice(0, index) : [...compiledPath];
        if (isMultiplePath(store, truncatedPath)) {
          return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`Cannot select fields on multiple dimension paths.` }];
        }

        if (!index) {
          return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`Path should end with field.` }];
        }

        return [{ state: StepValidationState.valid }];
      },
    ]
  )
);

const buildNodesMap = <T extends { key: string } = { key: string }>(nodes: T[], parentExtractor: (node: T) => T | undefined): Record<string, InstanceTreeNode> => {
  let nodesMap: Record<string, InstanceTreeNode> = {};
  nodes.forEach((node) => {
    if (node.key) {
      const parent = parentExtractor(node);
      nodesMap[node.key] = {
        key: node.key,
        parentKey: parent?.key ?? null,
        children: [],
      };
      if (parent && !nodesMap[parent.key]) {
        nodesMap = joinObjects(nodesMap, buildNodesMap([parent], parentExtractor));
      }
    }
  });
  return nodesMap;
};

export const buildInstancesTree = <T extends { key: string }>(
  nodes: T[],
  parentExtractor: (node: T) => T | undefined,
  nodeComparator?: (a: string, b: string) => number
): InstanceTreeNode[] => {
  const nodesTree = Object.fromEntries(
    Object.entries(buildNodesMap(nodes, parentExtractor))
      .sort(([nodeAId], [nodeBId]) => (nodeComparator?.(nodeAId, nodeBId) ?? 0))
  );
  Object.values(nodesTree).forEach((v) => {
    if (v.parentKey !== null) {
      nodesTree[v.parentKey].children = [...(nodesTree[v.parentKey].children || []), v];
    }
  });
  return Object.values(nodesTree).filter((node) => !node.parentKey);
};

const createConceptAndChildren = (
  store: FrontObjectStore,
  conceptInstanceId: string,
  withEmbedded: boolean,
  newIdsMap: Map<string, string>,
  newParentId?: string
) => {
  if (!newIdsMap.get(conceptInstanceId)) {
    const concept = store.getObjectOrNull(conceptInstanceId);
    if (!concept || !isInstanceOf<ConceptStoreObject>(concept, Concept)) {
      throw newError('Duplicate concept called with a non-concept instance');
    }
    let children: StoreObject[] | undefined;
    if (withEmbedded) {
      children = getConceptDefinitionValidFields(store, concept[Instance_Of])
        .filter((fieldInstance) => fieldInstance[Instance_Of] === EmbeddingField && fieldInstance[EmbeddingField_ToType] !== Resource);
    }
    const allowedProperties = [
      ...(isEmbeddedConceptInstance(concept) ? [concept[KinshipRelation]] : []),
      ...getConceptDefinitionValidFields(store, concept[Instance_Of])
        .flatMap((field) => {
          if (field[Field_IntegrationOnly]) {
            return [];
          } else if (isInstanceOf<TimelineFieldStoreObject>(field, TimelineField)) {
            return [
              field[TimelineField_Rank],
              field[TimelineField_Period],
            ];
          } else if (isInstanceOf<DateFieldStoreObject>(field, DateField)) {
            return [
              field.id,
              field[DateField_Rank],
            ];
          } else if (isInstanceOf<NumberFieldStoreObject>(field, NumberField) && field[Field_Formula]) {
            return [];
          } else if (isInstanceOf<ExternalKeyFieldStoreObject>(field, ExternalKeyField)) {
            return [];
          } else {
            return [field.id];
          }
        }),
      ...collectTypes(store, concept[Instance_Of])
        .flatMap((typeId) => store.getObject(typeId).navigateBack(Class_Properties).map((instance) => instance.id)),
      Instance_Of,
    ];

    const objectToCopy = Object.fromEntries(
      store.objectEntries(store.getObject(conceptInstanceId))
        .filter(([id, value]) => allowedProperties.includes(id)
          // filtering invalid values
          && (!isInstanceOf(store.getObjectOrNull(id), RelationSingleField) || isRelationFieldStorageValueValid(store, id, value))
          && (!isInstanceOf(store.getObjectOrNull(id), WorkflowField) || isWorkflowFieldStorageValueValid(store, id, value)))
    );

    if (newParentId && isEmbeddedConceptInstance(concept)) {
      objectToCopy[concept[KinshipRelation] as string] = newParentId;
    } else if (allowedProperties.includes(Concept_Name)) {
      objectToCopy[Concept_Name] = appendTextAtTheEnd(store.getObject(conceptInstanceId)[Concept_Name] as RichText | undefined, ' (copy)');
    }

    const newId = store.createObject(joinObjects(
      objectToCopy,
      {
        [Concept_CreatedAt]: undefined,
        [Concept_UpdatedAt]: undefined,
      }
    ));
    newIdsMap.set(conceptInstanceId, newId);
    if (children) {
      children.forEach((fieldInstance) => {
        concept.navigateBack(fieldInstance.id)
          .forEach((embeddedConcept) => {
            createConceptAndChildren(store, embeddedConcept.id, withEmbedded, newIdsMap, newId);
          });
      });
    }
  }
};

const isAssociationValuesDuplicable = (store: ObjectStoreReadOnly, associationFieldDefinitionId: string): boolean => {
  const associationDefinition = store.getObject<AssociationFieldDefinitionStoreObject>(associationFieldDefinitionId);
  const role1Type = associationDefinition.navigateOrNull(AssociationFieldDefinition_Role1Type);
  if (role1Type === null) {
    return false;
  }
  const role2Type = associationDefinition.navigateOrNull(AssociationFieldDefinition_Role2Type);
  if (role2Type === null) {
    return false;
  }

  return associationDefinition
    .navigateBack<AssociationFieldStoreObject>(AssociationField_Definition)
    .some((associationField) => !!associationField[Field_IntegrationOnly]);
};

export const duplicateConcept = (
  store: FrontObjectStore,
  aclHandlers: ACLHandler,
  conceptInstanceId: string,
  onDuplicate?: (id: string) => void,
  withEmbedded = false
): void => {
  const newIdsMap = new Map<string, string>();
  createConceptAndChildren(store, conceptInstanceId, withEmbedded, newIdsMap);
  newIdsMap.forEach((newId, oldId) => {
    const concept = store.getObjectOrNull(oldId);
    if (!concept || !isInstanceOf<ConceptStoreObject>(concept, Concept)) {
      throw newError('Duplicate concept called with a non-concept instance');
    }
    getConceptDefinitionValidFields(store, concept[Instance_Of])
      .forEach((field) => {
        if (isInstanceOf(field, ImageField) && concept[field.id]) {
          store.cloneAttachment(
            {
              objectId: newId,
              propertyId: field.id,
              originObjectId: concept.id,
              originPropertyId: field.id,
              originRevisionId: concept[field.id] as string,
            },
            (revisionId) => {
              store.updateObject(newId, {
                [field.id]: revisionId,
              });
            }
          );
          store.updateObject(newId, { [`${field.id}_position`]: concept[`${field.id}_position`] });
        } else if (isInstanceOf(field, AttachmentField)) {
          const currentDimensionMapping = getFieldDimensionOfModelType(store, field.id, concept[Instance_Of]);
          if (!currentDimensionMapping) {
            throw newError('Cannot duplicate concept because of invalid attachment field');
          }
          const newDimensionMapping = { [currentDimensionMapping]: newId };
          const newDimensionalId = buildDimensionalId(newDimensionMapping);
          const oldDimensionMapping = { [currentDimensionMapping]: concept.id };
          const oldDimensionalId = buildDimensionalId(oldDimensionMapping);
          store.withAssociation(Attachment)
            .withRole(Attachment_Role_FieldDimensions, oldDimensionalId[0])
            .withVarRoles(Attachment_VarRoles_Dimensions, oldDimensionalId.slice(1))
            .withExternalRole(Attachment_Role_FileName)
            .list<AttachmentStoreObject>()
            .forEach(({ role, object }) => {
              store.cloneAttachment(
                {
                  objectId: newDimensionalId.join('|'),
                  propertyId: field.id,
                  originObjectId: oldDimensionalId.join('|'),
                  originPropertyId: field.id,
                  originRevisionId: object[Attachment_Revision],
                },
                (revisionId) => {
                  store.withAssociation(Attachment)
                    .withRole(Attachment_Role_FileName, role(Attachment_Role_FileName))
                    .withRole(Attachment_Role_FieldDimensions, newDimensionalId[0])
                    .withVarRoles(Attachment_VarRoles_Dimensions, newDimensionalId.slice(1))
                    .updateObject<AttachmentStoreObject>({
                      [Attachment_Revision]: revisionId,
                    });
                }
              );
            });
        }
      });

    store.withAssociation(Association)
      .withRole(Association_Role_Role1TypeInstance, oldId)
      .withExternalRole(Association_Role_Role2TypeInstance)
      .list()
      .filter((association) => isAssociationValuesDuplicable(store, association.role(Association_Role_Definition)))
      .forEach((association) => {
        const role2Instance = association.navigateRoleOrNull(Association_Role_Role2TypeInstance);
        if (
          // user can create an association only on objects he can read
          (role2Instance && aclHandlers.canReadObject(role2Instance.id))
          || association.navigateRole(Association_Role_Definition).navigate(AssociationFieldDefinition_Role2Type)[Class_IsExternal]
        ) {
          store.withAssociation(Association)
            .withRole(Association_Role_Definition, association.role(Association_Role_Definition))
            .withRole(Association_Role_Role1TypeInstance, newId)
            .withRole(Association_Role_Role2TypeInstance, newIdsMap.get(association.role(Association_Role_Role2TypeInstance))
              ?? association.role(Association_Role_Role2TypeInstance))
            .updateObject({});
        }
      });

    store.withAssociation(Association)
      .withExternalRole(Association_Role_Role1TypeInstance)
      .withRole(Association_Role_Role2TypeInstance, oldId)
      .list()
      .filter((association) => isAssociationValuesDuplicable(store, association.role(Association_Role_Definition)))
      .forEach((association) => {
        // user can create an association only on objects he can read
        const role1Instance = association.navigateRoleOrNull(Association_Role_Role1TypeInstance);
        if (
          (role1Instance && aclHandlers.canReadObject(role1Instance.id))
          || association.navigateRole(Association_Role_Definition).navigate(AssociationFieldDefinition_Role1Type)[Class_IsExternal]
        ) {
          const role1InstanceId = newIdsMap.get(association.role(Association_Role_Role1TypeInstance)) ?? association.role(Association_Role_Role1TypeInstance);
          store.withAssociation(Association)
            .withRole(Association_Role_Definition, association.role(Association_Role_Definition))
            .withRole(Association_Role_Role1TypeInstance, role1InstanceId)
            .withRole(Association_Role_Role2TypeInstance, newId)
            .updateObject({});
        }
      });

    store.withAssociation(FieldBlockConceptInstanceDisplay)
      .withRole(FieldBlockConceptInstanceDisplay_Role_Concept, oldId)
      .list()
      .forEach((association) => (
        store.withAssociation(FieldBlockConceptInstanceDisplay)
          .withRole(FieldBlockConceptInstanceDisplay_Role_Concept, newId)
          .withRole(FieldBlockConceptInstanceDisplay_Role_FieldBlockDisplay, association.role(FieldBlockConceptInstanceDisplay_Role_FieldBlockDisplay))
          .updateObject({ [FieldBlockConceptInstanceDisplay_DisplayOverride]: association.object[FieldBlockConceptInstanceDisplay_DisplayOverride] })
      ));

    const parameterMap: Record<string, string> = {};
    const sanitizeRelation = (parameter: StoreObject, id: string) => (parameter[id] && parameter.navigateOrNull(id) ? parameter[id] : null);
    concept
      .navigateBack(Dashboard_Parameters)
      .forEach((parameter) => {
        parameterMap[parameter.id] = store.createObject(joinObjects(
          parameter.asRawObject(),
          {
            [DashboardParameter_Dashboard]: newId,
            [DashboardParameter_Type]: sanitizeRelation(parameter, DashboardParameter_Type),
            [DashboardParameter_DefaultValue]: sanitizeRelation(parameter, DashboardParameter_DefaultValue),
          }
        ));
      });

    Object.entries(parameterMap)
      .forEach(([parameterId, newParameterId]) => {
        const filters = store.getObject(parameterId)[DashboardParameter_Filters];
        if (filters) {
          let newFilterValue = JSON.stringify(filters);
          Object.entries(parameterMap).forEach(([pid, npid]) => {
            newFilterValue = newFilterValue.replaceAll(pid, npid);
          });
          store.updateObject(newParameterId, {
            [DashboardParameter_Filters]: JSON.parse(newFilterValue),
          });
        }
      });

    concept.navigateBack(Dashboard_Widgets)
      .forEach((widget) => {
        duplicateWidget(store, widget.id, newId, parameterMap);
      });
  });
  const newId = newIdsMap.get(conceptInstanceId);
  if (onDuplicate && newId) {
    onDuplicate(newId);
  }
};

const addParents = (concept: StoreObject, array: StoreObject[]): void => {
  const parent = concept.navigateOrNull(concept[KinshipRelation] as string);
  if (parent) {
    array.push(parent);
    addParents(parent, array);
  }
};

const getConceptInstancesTree = (store: ObjectStoreReadOnly, conceptInstanceId: string): StoreObject[] => {
  const concept = store.getObject(conceptInstanceId);
  const concepts = [concept];
  addParents(concept, concepts);
  concepts.reverse();
  return concepts;
};

export const getConceptInstanceFullContext = (store: ObjectStoreReadOnly, conceptInstanceId: string): string[][] => (
  getConceptInstancesTree(store, conceptInstanceId)
    .flatMap((instance) => [[instance[Instance_Of] as string], [instance.id]])
);

export const isConceptPromotedAsLeftBarIcon = (store: FrontObjectStore, conceptId: string): boolean => {
  const pathResolver = createValuePathResolver(store, { [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: store.getLoggedUserId() } });
  return (
    store.getObject(LeftBarItem)
      .navigateBack<LeftBarItemStoreObject>(Class_Instances)
      .some((leftBarItem) => {
        if (leftBarItem[LeftBarItem_Path] === undefined) {
          return false;
        } else {
          const valueResolution = pathResolver.resolvePathValue(leftBarItem[LeftBarItem_Path]);
          return (
            isSingleValueResolution(valueResolution)
            && isStoreObject(valueResolution.value)
            && valueResolution.value.id === conceptId
          );
        }
      })
  );
};

export const getConceptDefinitionChipFields = (store: FrontObjectStore, conceptDefinition: StoreObject): DecoratedList<{ id: string, rank: string }> => (
  ranker.decorateList(
    conceptDefinition ? store.withAssociation(ConceptChipDisplay)
      .withRole(ConceptChipDisplay_Role_ConceptDefinition, conceptDefinition.id)
      .list()
      .map((assoc) => ({ id: assoc.role(ConceptChipDisplay_Role_Field), rank: assoc.object[ConceptChipDisplay_Rank] as string }))
      .sort(compareProperty('rank', compareRank)) : [],
    (field) => field.rank
  )
);

export const getViewerUsersSet = (store: ObjectStoreWithTimeseries, conceptId: string): Set<string> => {
  const openCollaborationIds = getOpenConceptCollaborationIds(store, conceptId);
  return new Set([
    ...openCollaborationIds
      .flatMap((collaborationId) => getCollaborationUserRecipientsIds(store, collaborationId)),
    ...store.withAssociation(ConceptRoleUserAssignation)
      .withRole(ConceptRoleUserAssignation_Role_Concept, conceptId)
      .list()
      .map((assignation) => assignation.role(ConceptRoleUserAssignation_Role_User)),
    ...[...getViewerGroupsSet(store, conceptId)]
      .flatMap((groupId) => {
        const fieldUtils = getFieldUtilsHandler(store, Group_Users);
        const users = fieldUtils.getValueWithoutFormula({ [GroupMembershipDimension]: groupId }) as StoreObject[];
        return users.map((user) => user.id);
      }),
  ]);
};

export const getInlineCreationBuilder = (
  store: FrontObjectStore,
  conceptDefinitionId: string,
  onNewInstance: (instanceId: string) => void = () => {},
  initialStateValues: Record<string, unknown> = {},
  creationValue: Record<string, unknown> = {}
): () => (InlineCreationInline | InlineCreationTransactional) => {
  const suggestedFields = store.withAssociation(FieldSuggestedDisplay)
    .withRole(FieldSuggestedDisplay_Role_ConceptDefinition, conceptDefinitionId)
    .list<FieldSuggestedDisplayStoreObject>()
    .map((assoc) => ({ id: assoc.role(FieldSuggestedDisplay_Role_Field), rank: assoc.object[FieldSuggestedDisplay_Rank] }));

  if (suggestedFields.length > 0) {
    return (
      () => ({
        type: 'transactional',
        getChipLabel: (creationOptionState) => richTextToText(creationOptionState[Concept_Name] as RichText | undefined),
        creationOptions: suggestedFields
          .sort(compareProperty('rank', compareRank))
          .map(({ id }, index): CreationOption | undefined => {
            const suggestedFieldHandler = getFieldHandler(store, id);
            const suggestedField = store.getObjectOrNull<FieldStoreObject>(id);
            if (suggestedFieldHandler && suggestedField) {
              return {
                key: id,
                title: getFieldLabel(store, suggestedField),
                isValueValid: (fieldValue) => suggestedFieldHandler.valueValidation?.(fieldValue).isValid ?? true,
                render: (fieldValue, setValue) => suggestedFieldHandler.renderSuggestedField?.(fieldValue, setValue, index === 0) ?? null,
              };
            } else {
              return undefined;
            }
          })
          .filter(filterNullOrUndefined),
        getInitialState: (search) => (joinObjects(
          initialStateValues,
          { [Concept_Name]: textToRichText(search) }
        )),
        onCreate: (creationOptionState) => {
          const newObjectValue = joinObjects(
            creationOptionState,
            creationValue,
            { [Instance_Of]: conceptDefinitionId }
          );
          const newInstanceId = store.createObject(newObjectValue);
          onNewInstance(newInstanceId);
          return newInstanceId;
        },
      })
    );
  } else {
    return (
      () => ({
        type: 'inline',
        onCreate: (title) => {
          const newObjectValue = joinObjects(
            {
              [Instance_Of]: conceptDefinitionId,
              [Concept_Name]: textToRichText(title),
            },
            creationValue
          );
          const newInstanceId = store.createObject(newObjectValue);
          onNewInstance(newInstanceId);
          return newInstanceId;
        },
      })
    );
  }
};
