import type { FunctionComponent, ReactNode } from 'react';
import makeSelectorsClasses from '../../utils/makeSelectorsClasses';
import makeStyles from '../../utils/makeStyles';
import { UsageContextProvider, UsageVariant } from '../../utils/useUsageContext';

export const tableSelectorsClasses = makeSelectorsClasses('navigationButton');

export enum TableSortDirection {
  asc = 'asc',
  desc = 'desc',
}

const useStyles = makeStyles((theme) => ({
  base: {
    borderCollapse: 'collapse',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
    tableLayout: 'fixed',
    width: 'fit-content',
  },
}), 'table');

interface TableProps {
  children: ReactNode,
  width?: number | string,
  maxWidth?: number,
}

const Table: FunctionComponent<TableProps> = ({ children, width, maxWidth }) => {
  const classes = useStyles();

  return (
    <UsageContextProvider usageVariant={UsageVariant.inTable}>
      <table className={classes.base} style={{ width, maxWidth }}>
        {children}
      </table>
    </UsageContextProvider>
  );
};

export default Table;
