// Keep ./initializeI18n import first to ensure proper i18n initialization
import './initializeI18n';

import { datadogRum } from '@datadog/browser-rum';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { registerYieldStrategy } from 'yooi-utils';
import App from './app/App';
import './app/initFieldLibrary';
import './app/initViewLibrary';
import './index.css';
import fontDefinition from './theme/fontDefinition';
import buildInfo from './utils/buildInfo';
import './utils/devTools';
import { attachStyles } from './utils/makeStyles';
import { getHostname, hasFeature } from './utils/options';
import { createRenderYieldStrategy } from './utils/renderYieldStrategy';

registerYieldStrategy(createRenderYieldStrategy());

if (hasFeature('monitoring', 'rum')) {
  const version = buildInfo.sourceHash;

  datadogRum.init({
    applicationId: 'f6d2b718-b826-4b12-a276-dc8e8cb912a5',
    clientToken: 'pubd44a7eb10708ad2bd59d593cecd6378e',
    site: 'datadoghq.eu',

    service: 'yooi',
    env: getHostname(),
    version: version?.startsWith('dirty!') ? undefined : version.substring(0, 8),

    useSecureSessionCookie: true,

    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    traceSampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

// Force font pre-loading
Object.values(fontDefinition).forEach(({ fontStyle, fontWeight, fontSize, fontFamily }) => {
  document.fonts.load(`${fontStyle} ${fontWeight} ${fontSize} ${fontFamily}`);
});

attachStyles();

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(<StrictMode><App /></StrictMode>);
}
