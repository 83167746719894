import type {
  ActionQuery,
  AutomationRuleTrigger,
  AutomationRuleTriggerHandler,
  MailActionQueryRecord,
  ObjectActionQuery,
  StoreObjectActionQuery,
} from 'yooi-modules/modules/automationModule';
import { isCollaborationEventTrigger, isInstanceEventTrigger, isScheduledTrigger } from 'yooi-modules/modules/automationModule';
import { newError } from 'yooi-utils';
import type { IconName } from '../../../../components/atoms/Icon';
import { createCollaborationEventTriggerFrontHandler } from './collaborationEventTriggerFrontHandler';
import { createInstanceEventTriggerFrontHandler } from './instanceEventTriggerFrontHandler';
import { createSchedulerTriggerFrontHandler } from './schedulerTriggerFrontHandler';

export interface ActionQueryNode<L extends string | number | symbol = string, A extends ActionQuery = ActionQuery> {
  label: L,
  helperText: string,
  icon: IconName,
  children: A extends ObjectActionQuery
    ? CustomNodesChildren<A['object']>
    : A extends StoreObjectActionQuery
      ? ConceptChildren
      : undefined,
}

export interface ConceptChildren {
  type: 'concept',
  typeId: string,
  isMultiple: boolean,
}

export type ActionQueryNodes<T extends MailActionQueryRecord> = { [K in keyof T]: ActionQueryNode<K, T[K]> };

export interface CustomNodesChildren<T extends Record<string, ActionQuery> = Record<string, ActionQuery>> {
  type: 'custom',
  nodes: ActionQueryNodes<T>,
}

export interface TriggerFrontHandler<TH extends AutomationRuleTriggerHandler> {
  mailQueryNodes: ActionQueryNodes<TH['mailActionQuery']>,
}

export const getTriggerFrontHandler = (trigger: AutomationRuleTrigger): TriggerFrontHandler<AutomationRuleTriggerHandler> => {
  if (isInstanceEventTrigger(trigger)) {
    return createInstanceEventTriggerFrontHandler();
  } else if (isCollaborationEventTrigger(trigger)) {
    return createCollaborationEventTriggerFrontHandler();
  } else if (isScheduledTrigger(trigger)) {
    return createSchedulerTriggerFrontHandler();
  } else {
    throw newError('Unknown trigger type');
  }
};
