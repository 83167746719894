import i18n from '../utils/i18n';

export enum ConnectionPhase {
  Initializing,
  WaitingForSnapshot,
  DownloadFromNetwork,
  DownloadFromNetworkInOtherTab,
  DownloadFromBrowser,
  ApplySnapshot,
  StartApp,
}

export type OnConnectionProgress = (connectionPhase: ConnectionPhase, percentage?: number) => void;

const applyPercentageRatio = (startPercentage: number, endPercentage: number, phasePercentage?: number) => (
  startPercentage + (phasePercentage !== undefined ? phasePercentage * (endPercentage - startPercentage) : 0)
);

export const renderConnectionProgress = (state: { connectionPhase: ConnectionPhase, percentage?: number } | undefined): { progressText: string, progressPercentage: number } => {
  const connectionPhase = state?.connectionPhase ?? ConnectionPhase.Initializing;
  const percentage = state?.percentage;

  switch (connectionPhase) {
    case ConnectionPhase.Initializing:
      return {
        progressText: i18n`Connecting to server`,
        progressPercentage: applyPercentageRatio(0, 0.01, percentage),
      };
    case ConnectionPhase.WaitingForSnapshot:
      return {
        progressText: i18n`Requesting data`,
        progressPercentage: applyPercentageRatio(0.01, 0.05, percentage),
      };
    case ConnectionPhase.DownloadFromNetwork:
      return {
        progressText: i18n`Downloading data`,
        progressPercentage: applyPercentageRatio(0.05, 0.9, percentage),
      };
    case ConnectionPhase.DownloadFromNetworkInOtherTab:
      return {
        progressText: i18n`Downloading data in another tab`,
        progressPercentage: applyPercentageRatio(0.05, 0.85, percentage),
      };
    case ConnectionPhase.DownloadFromBrowser:
      return {
        progressText: i18n`Copying data from another tab`,
        progressPercentage: applyPercentageRatio(0.85, 0.9, percentage),
      };
    case ConnectionPhase.ApplySnapshot:
      return {
        progressText: i18n`Processing data`,
        progressPercentage: applyPercentageRatio(0.9, 0.99, percentage),
      };
    case ConnectionPhase.StartApp:
      return {
        progressText: i18n`Starting application`,
        progressPercentage: applyPercentageRatio(0.99, 1, percentage),
      };
  }
};
