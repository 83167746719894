import { compareNumber, extractAndCompareValue } from 'yooi-utils';
import type { TimeRange } from './TimeseriesType';

const mergeCrossTimeRanges = (timeRange1: TimeRange, timeRange2: TimeRange): TimeRange => {
  let { from, to } = timeRange1;
  if (from >= timeRange2.from) {
    from = timeRange2.from;
  }
  if (to <= timeRange2.to) {
    to = timeRange2.to;
  }
  return { from, to };
};

const isCross = (timeRange1: TimeRange, timeRange2: TimeRange): boolean => (timeRange1.from >= timeRange2.from && timeRange1.from <= timeRange2.to)
  || (timeRange2.from >= timeRange1.from && timeRange2.from <= timeRange1.to);

export const mergeTimeValidities = (uniqueTimeRangesValidity: TimeRange[], timeRangeValidityToInsert: TimeRange): TimeRange[] => {
  const workingTimeRangesValidity = [...uniqueTimeRangesValidity];
  const index = workingTimeRangesValidity.findIndex((timeRangeValidity) => isCross(timeRangeValidity, timeRangeValidityToInsert));
  if (index === -1) {
    return [...workingTimeRangesValidity, timeRangeValidityToInsert].sort(extractAndCompareValue((tr) => tr.from, compareNumber));
  }
  const [timeRangeToMerge] = workingTimeRangesValidity.splice(index, 1);
  const newTimeRangeToInsert = mergeCrossTimeRanges(timeRangeToMerge, timeRangeValidityToInsert);
  return mergeTimeValidities(workingTimeRangesValidity, newTimeRangeToInsert);
};
