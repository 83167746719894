import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useRef } from 'react';
import { getPathReturnedConceptDefinitionId } from 'yooi-modules/modules/conceptModule';
import type { Block } from 'yooi-modules/modules/dashboardModule/fields/graphChartField';
import { IconName } from '../../../../../components/atoms/Icon';
import Tooltip from '../../../../../components/atoms/Tooltip';
import Typo, { TypoVariant } from '../../../../../components/atoms/Typo';
import WidgetActionButton from '../../../../../components/atoms/WidgetActionButton';
import Chip from '../../../../../components/molecules/Chip';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import useStore from '../../../../../store/useStore';
import base from '../../../../../theme/base';
import { buildPadding, getSpacing, Spacing, spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeSelectorsClasses from '../../../../../utils/makeSelectorsClasses';
import makeStyles from '../../../../../utils/makeStyles';
import useTheme from '../../../../../utils/useTheme';
import { getChipOptionWithUnknown } from '../../../modelTypeUtils';
import ArcherElement from '../archer/ArcherElement';
import { getBlockArrowLabel, getBlockLabel, getBlockMultiplicity } from './GraphChartBlockCard';

const selectorsClasses = makeSelectorsClasses('hoveredVisible');

const useStyles = makeStyles((theme) => ({
  paddedContainer: {
    ...buildPadding({ x: Spacing.xxs }),
  },
  numberBubble: {
    width: '2.4rem',
    height: '2rem',
    borderRadius: base.borderRadius.circle,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.color.text.primary,
    backgroundColor: theme.color.background.neutral.subtle,
    cursor: 'default',
  },
  selectionContainer: {
    position: 'relative',
    display: 'grid',
    alignItems: 'center',
    borderWidth: '0.2rem',
    borderStyle: 'dashed',
    borderColor: theme.color.transparent,
    borderRadius: base.borderRadius.medium,
    '&:active, &:hover': {
      borderColor: theme.color.border.primarylight,
    },
    '&:hover, &:focus, &:focus-within': {
      [`& .${selectorsClasses.hoveredVisible}`]: {
        visibility: 'visible',
      },
    },
    padding: spacingRem.xs,
  },
  selectedSelectionContainer: {
    position: 'relative',
    borderWidth: '0.2rem',
    borderStyle: 'dashed',
    borderColor: theme.color.border.primary,
    '&:active, &:hover': {
      borderColor: theme.color.border.primary,
    },
    '&:hover, &:focus, &:focus-within': {
      [`& .${selectorsClasses.hoveredVisible}`]: {
        visibility: 'visible',
      },
    },
  },
  blockContainer: {
    gap: getSpacing(Spacing.s),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '4.2rem',
    borderWidth: 0,
    borderStyle: 'none',
    borderRadius: base.borderRadius.medium,
    boxShadow: base.shadowElevation.low,
    '&:hover, &:focus': {
      boxShadow: base.shadowElevation.medium,
    },
    paddingLeft: spacingRem.m,
    paddingRight: spacingRem.m,
    cursor: 'pointer',
    backgroundColor: theme.color.background.neutral.default,
  },
  toolbarContainer: {
    position: 'absolute',
    visibility: 'hidden',
    top: spacingRem.s,
    right: spacingRem.s,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: base.borderRadius.medium,
    overflow: 'hidden',
  },
}), 'graphChartBlockChip');

interface GraphChartBlockChipProps {
  block: Block,
  dependingBlockIds: string[],
  isSelected: boolean,
  onSelect: () => void,
  onDelete: () => void,
  onDuplicate: () => void,
  onMoveLeft: () => void,
  onMoveRight: () => void,
}

const GraphChartBlockChip: FunctionComponent<GraphChartBlockChipProps> = ({
  block,
  dependingBlockIds,
  isSelected,
  onSelect,
  onDelete,
  onDuplicate,
  onMoveLeft,
  onMoveRight,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const containerRef = useRef<HTMLDivElement>(null);

  const label = getBlockLabel(store, block.index, block.path, block.label);
  const conceptDefinitionId = getPathReturnedConceptDefinitionId(store, block.path ?? []);

  const chipOptions = conceptDefinitionId ? getChipOptionWithUnknown(store, conceptDefinitionId) : undefined;

  const arrowLabel = getBlockArrowLabel(store, block.path, block.arrowLabel?.type === 'value' ? block.arrowLabel.value : undefined);
  const multiplicity = getBlockMultiplicity(store, block.path);

  return (
    <div ref={containerRef}>
      <ArcherElement
        key={block.id}
        ids={[block.id]}
        relations={dependingBlockIds.map((targetId) => ({
          targetId,
          targetAnchor: 'right',
          sourceAnchor: 'left',
          lineType: {
            strokeColor: theme.color.text.brand,
            marker: { arrowLength: 4, arrowThickness: 8 },
            lineStyle: 'angle',
          },
        }))}
      >
        <div className={classes.paddedContainer}>
          <div
            className={classnames({
              [classes.selectionContainer]: true,
              [classes.selectedSelectionContainer]: isSelected,
            })}
          >
            <div className={classnames(selectorsClasses.hoveredVisible, classes.toolbarContainer)}>
              <WidgetActionButton
                tooltip={i18n`Move left`}
                icon={IconName.keyboard_arrow_left}
                action={{ type: 'click', onClick: onMoveLeft }}
              />
              <WidgetActionButton
                tooltip={i18n`Move right`}
                icon={IconName.keyboard_arrow_right}
                action={{ type: 'click', onClick: onMoveRight }}
              />
              <WidgetActionButton
                tooltip={i18n`Copy`}
                icon={IconName.file_copy}
                action={{ type: 'click', onClick: onDuplicate }}
              />
              <WidgetActionButton
                tooltip={i18n`Delete`}
                icon={IconName.delete}
                action={{ type: 'click', onClick: onDelete }}
              />
            </div>
            <button
              type="button"
              className={classes.blockContainer}
              onClick={() => onSelect()}
            >
              <SpacingLine>
                {arrowLabel && (
                  <Tooltip title={label}>
                    <Typo variant={TypoVariant.small} color={theme.color.text.secondary} maxLine={1}>{label}</Typo>
                  </Tooltip>
                )}
                {multiplicity && (
                  <div className={classes.numberBubble}>
                    <Typo variant={TypoVariant.small}>{multiplicity}</Typo>
                  </div>
                )}
              </SpacingLine>
              <Chip
                text={label}
                tooltip={label}
                squareColor={chipOptions?.squareColor}
                color={chipOptions?.color}
                borderStyle={chipOptions?.borderStyle}
                icon={chipOptions?.icon}
              />
            </button>
          </div>
        </div>
      </ArcherElement>
    </div>
  );
};

export default GraphChartBlockChip;
