import classnames from 'classnames';
import { forwardRef, Fragment } from 'react';
import type { ConnectDragSource } from 'react-dnd';
import { v4 as uuid } from 'uuid';
import type { SingleParameterDefinition, PathStep } from 'yooi-modules/modules/conceptModule';
import { FilterConditionOperators, isFilterNode } from 'yooi-modules/modules/conceptModule';
import { joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName, IconSizeVariant } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import Typo from '../../../../components/atoms/Typo';
import OverflowMenu from '../../../../components/molecules/OverflowMenu';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import base from '../../../../theme/base';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import type { FrontFilterNode, FrontFilters } from '../filterUtils';
import { addNewFilterNodeToFilters, addNewFilterToFilters, duplicateFilter, getFilterNodeTitle } from '../filterUtils';
import DragItem from './DragItem';
import DragItemPlaceholder from './DragItemPlaceholder';
import DropItem from './DropItem';
import FilterCondition from './FilterCondition';
import FilterOperator from './FilterOperator';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
  },
  nestedContainer: {
    gridTemplateColumns: '6.5rem 1fr',
    gap: spacingRem.s,
  },
  nodeFirstLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
  nodeContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingRem.s,
    borderRadius: base.borderRadius.medium,
  },
  nestedNodeContainer: {
    borderRadius: base.borderRadius.medium,
    borderStyle: 'dashed',
    borderColor: theme.color.border.default,
    borderWidth: '0.2rem',
    padding: spacingRem.s,
  },
  subtitle: {
    color: theme.color.text.primary,
    paddingLeft: spacingRem.s,
  },
  dragCursor: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
}), 'filterNode');

interface FilterNodeProps {
  filters: FrontFilterNode,
  parentNode?: FrontFilterNode,
  level: number,
  readOnly: boolean,
  filterParameterDefinitions: SingleParameterDefinition[],
  onNodeConditionChange?: (condition: FilterConditionOperators) => void,
  requiredConceptDefinitionId?: string,
  defaultFilterValue: FrontFilters,
  suggestedPaths?: { label: string, path: PathStep[] }[],
  rootPath?: { label: string, path: PathStep[] },
  onChange: (filterNode: FrontFilters) => void,
  onDuplicate: (filterNode: FrontFilters) => void,
  onDelete: () => void,
  drawInsidePlaceholder?: boolean,
  connectDragSource?: ConnectDragSource,
}

const FilterNode = forwardRef<HTMLDivElement, FilterNodeProps>(({
  filters,
  parentNode,
  level,
  readOnly,
  filterParameterDefinitions,
  onNodeConditionChange,
  requiredConceptDefinitionId,
  defaultFilterValue,
  suggestedPaths,
  rootPath,
  onChange,
  onDelete,
  onDuplicate,
  drawInsidePlaceholder = false,
  connectDragSource,
}, ref) => {
  const classes = useStyles();

  const selectedCondition = parentNode?.condition ?? FilterConditionOperators.OR;
  const index = parentNode?.children?.findIndex(({ id }) => id === filters.id) ?? 0;

  return (
    <div
      ref={ref}
      className={classnames({
        [classes.container]: true,
        [classes.nestedContainer]: level !== 0,
      })}
    >
      {
        level > 0
          ? (
            <FilterOperator index={index} selectedCondition={selectedCondition} readOnly={readOnly} onNodeConditionChange={onNodeConditionChange} isNodeOperator />
          )
          : null
      }
      <div
        className={classnames({
          [classes.nodeContainer]: true,
          [classes.nestedNodeContainer]: level !== 0,
        })}
      >
        {
          parentNode
            ? (
              <div className={classes.nodeFirstLine}>
                <div className={classes.subtitle}>
                  <SpacingLine>
                    <Typo maxLine={1}>{getFilterNodeTitle(filters.condition)}</Typo>
                  </SpacingLine>
                </div>
                {
                  readOnly
                    ? null
                    : (
                      <div className={classes.nodeFirstLine}>
                        <IconOnlyButton
                          iconName={IconName.add}
                          variant={IconOnlyButtonVariants.tertiary}
                          tooltip={i18n`Add condition`}
                          onClick={() => onChange(addNewFilterToFilters(filters, defaultFilterValue))}
                        />
                        <OverflowMenu
                          buttonVariant={IconOnlyButtonVariants.tertiary}
                          menuItems={[
                            {
                              key: 'add simple',
                              name: i18n`Add condition`,
                              icon: IconName.add,
                              onClick: () => onChange(addNewFilterToFilters(filters, defaultFilterValue)),
                            },
                            {
                              key: 'add advanced',
                              name: i18n`Add advanced condition`,
                              icon: IconName.add,
                              onClick: () => onChange(addNewFilterToFilters(filters, {
                                id: uuid(),
                                isSimpleCondition: false,
                              })),
                            },
                            {
                              key: 'add group',
                              name: i18n`Add condition group`,
                              icon: IconName.library_add,
                              onClick: () => onChange(addNewFilterNodeToFilters(filters, defaultFilterValue)),
                            },
                            {
                              key: 'duplicate group',
                              name: i18n`Duplicate`,
                              icon: IconName.content_copy_outline,
                              onClick: () => onDuplicate(filters),
                            },
                            {
                              key: 'delete',
                              name: i18n`Delete`,
                              icon: IconName.delete,
                              danger: true,
                              onClick: onDelete,
                            },
                          ]}
                        />
                        <div ref={connectDragSource} className={classes.dragCursor}>
                          <Icon name={IconName.drag_indicator} colorVariant={IconColorVariant.secondary} size={IconSizeVariant.l} />
                        </div>
                      </div>
                    )
                }
              </div>
            )
            : null
        }
        {drawInsidePlaceholder ? (<DragItemPlaceholder />) : null}
        {filters.children?.map((child, childIndex) => (
          <Fragment key={child.id}>
            {isFilterNode(child) && (
              <DropItem filters={child}>
                {({ isInside, dropRef }) => (
                  <DragItem item={child}>
                    {({ connectDragSourceRef }) => (
                      <FilterNode
                        connectDragSource={connectDragSourceRef}
                        ref={dropRef}
                        filters={child}
                        level={level + 1}
                        filterParameterDefinitions={filterParameterDefinitions}
                        defaultFilterValue={defaultFilterValue}
                        onChange={(newFilter) => onChange(joinObjects(
                          filters,
                          { children: filters.children?.map((c) => (c.id === newFilter.id ? newFilter : c)) }
                        ))}
                        onDuplicate={() => onChange(duplicateFilter(child, filters))}
                        onDelete={() => onChange(joinObjects(
                          filters,
                          { children: filters.children?.flatMap((c, i) => (childIndex === i ? [] : [c])) }
                        ))}
                        onNodeConditionChange={(condition) => onChange(joinObjects(filters, { condition }))}
                        suggestedPaths={suggestedPaths}
                        rootPath={rootPath}
                        requiredConceptDefinitionId={requiredConceptDefinitionId}
                        parentNode={filters}
                        readOnly={readOnly}
                        drawInsidePlaceholder={isInside}
                      />
                    )}
                  </DragItem>
                )}
              </DropItem>
            )}
            {
              isFilterNode(child)
                ? null
                : (
                  <DropItem filters={child}>
                    {({ dropRef }) => (
                      <DragItem item={child}>
                        {({ connectDragSourceRef }) => (
                          <FilterCondition
                            ref={dropRef}
                            connectDragSource={connectDragSourceRef}
                            filters={child}
                            filterParameterDefinitions={filterParameterDefinitions}
                            onChange={(newFilter) => onChange(joinObjects(
                              filters,
                              { children: filters.children?.map((c) => (c.id === newFilter.id ? newFilter : c)) }
                            ))}
                            onDuplicate={() => onChange(duplicateFilter(child, filters))}
                            onDelete={() => onChange(joinObjects(
                              filters,
                              { children: filters.children?.flatMap((c, i) => (childIndex === i ? [] : [c])) }
                            ))}
                            onNodeConditionChange={(condition) => onChange(joinObjects(filters, { condition }))}
                            suggestedPaths={suggestedPaths}
                            rootPath={rootPath}
                            requiredConceptDefinitionId={requiredConceptDefinitionId}
                            parentNode={filters}
                            readOnly={readOnly}
                          />
                        )}
                      </DragItem>
                    )}
                  </DropItem>
                )
            }
          </Fragment>
        ))}
      </div>
    </div>
  );
});

export default FilterNode;
