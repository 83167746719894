import type {
  AccessControlListHandler,
  BusinessRuleHandler,
  BusinessRulesLibraryRegistrationHandler,
  DynamicBusinessRuleHandler,
  InitialStatePropertyValidationHandler,
  InitModuleFunction,
  ModuleRegistration,
  ObjectDebugLabelHandler,
  ObjectStoreReadOnly,
  ObjectStoreWithTimeseries,
  RegisterAccessControlList,
  RegisterBusinessRules,
  RegisterPropertyFunctions,
  RegisterPropertyFunctionsWithTimeseries,
} from 'yooi-store';
import type { PropertyAs } from '../fields/FieldModuleDslType';

export interface ObjectDebugLabelRegistration {
  (objectStore: ObjectStoreReadOnly): ObjectDebugLabelHandler,
}

export interface BusinessRuleRegistration {
  (objectStore: ObjectStoreReadOnly): BusinessRuleHandler,
}

export interface DynamicBusinessRuleRegistration {
  (registrationHandler: BusinessRulesLibraryRegistrationHandler, objectStore: ObjectStoreReadOnly): DynamicBusinessRuleHandler,
}

interface AccessControlListRegistration {
  (objectStore: ObjectStoreReadOnly, isFeatureEnabled: (feature: string, name: string) => boolean): AccessControlListHandler,
}

export interface AccessControlListRegistrations {
  [permission: string]: AccessControlListRegistration,
}

export enum PropertyMandatoryType {
  mandatory = 'mandatory',
  mandatoryWithDefaultValue = 'mandatoryWithDefaultValue',
  optional = 'optional',
}

export type PropertyGetDefaultValueFunction<T = NonNullable<unknown>> = (objectStoreAfterEvent: ObjectStoreReadOnly, id: string[]) => T;

interface MandatoryTypeMandatoryProperty {
  type: PropertyMandatoryType.mandatory,
}

interface DefaultValueTypeMandatoryProperty {
  type: PropertyMandatoryType.mandatoryWithDefaultValue,
  getDefaultValue: PropertyGetDefaultValueFunction,
}

export type MandatoryProperty = MandatoryTypeMandatoryProperty | DefaultValueTypeMandatoryProperty;

interface TypePropertyParameters {
  mandatory?: MandatoryProperty | undefined,
  label: string,
  as: PropertyAs,
  extraProperties?: Record<string, unknown>,
  businessRules?: BusinessRuleRegistration[],
  dynamicBusinessRules?: DynamicBusinessRuleRegistration[],
  initialStateValidationHandler?: InitialStatePropertyValidationHandler,
}

type PropertyFunctionParameters = TypePropertyParameters & {
  typeId: string,
};

interface TypePropertyFunctionWithTimeseriesParameters {
  label: string,
  computeFunction: (objectStore: ObjectStoreWithTimeseries) => (id: string[]) => unknown,
}

interface TypePropertyFunctionParameters {
  label: string,
  computeFunction: (objectStore: ObjectStoreReadOnly) => (id: string[]) => unknown,
}

interface PropertyFunctionFunctionWithTimeseriesParameters extends TypePropertyFunctionWithTimeseriesParameters {
  typeId?: string,
}

interface PropertyFunctionFunctionParameters extends TypePropertyFunctionParameters {
  typeId?: string,
}

interface TypeRelationParameters {
  label: string,
  targetTypeId: string,
  reverseLabel: string,
  mandatory?: MandatoryProperty,
  extraProperties?: Record<string, unknown>,
  businessRules?: BusinessRuleRegistration[],
  initialStateValidationHandler?: InitialStatePropertyValidationHandler,
}

type RelationFunctionParameters = TypeRelationParameters & {
  typeId: string,
};

interface TypeInstanceParameters {
  label: string,
  extraProperties?: Record<string, unknown>,
}

interface InstanceFunctionParameters extends TypeInstanceParameters {
  typeId: string,
}

export interface TypeDsl {
  property: (parameters: TypePropertyParameters) => TypeDsl,
  propertyFunction: (parameters: TypePropertyFunctionParameters) => TypeDsl,
  propertyFunctionWithTimeseries: (parameters: TypePropertyFunctionWithTimeseriesParameters) => TypeDsl,
  relation: (parameters: TypeRelationParameters) => TypeDsl,
  instance: (parameters: TypeInstanceParameters) => TypeDsl,
}

interface RoleBuilder {
  withRole: (roleId: number, objectId: string) => RoleBuilder,
}

interface AssociationAssociateParameters {
  roleBuilder: (withRole: (roleId: number, objectId: string) => RoleBuilder) => RoleBuilder | undefined,
  extraProperties?: Record<string, unknown>,
}

interface AssociateParameters extends AssociationAssociateParameters {
  typeId: string,
}

export interface AssociationDsl {
  property: (parameters: TypePropertyParameters) => AssociationDsl,
  relation: (parameters: TypeRelationParameters) => AssociationDsl,
  associate: (parameters: AssociationAssociateParameters) => AssociationDsl,
}

export interface AssociationRole {
  label: string,
  targetTypeId: string,
}

export interface ModuleDsl {
  id: string,
  label: string,
  getId: (label: string) => string,
  type: (parameters: {
    label: string,
    instanceOf?: string,
    extends?: string,
    isExternal?: boolean,
    extraProperties?: Record<string, unknown>,
    businessRules?: BusinessRuleRegistration[],
    dynamicBusinessRules?: DynamicBusinessRuleRegistration[],
    objectDebugLabel?: ObjectDebugLabelRegistration,
    accessControlList?: AccessControlListRegistrations,
  }) => TypeDsl,
  association: (parameters: {
    label: string,
    roles: AssociationRole[],
    varRoles?: AssociationRole,
    extraProperties?: Record<string, unknown>,
    businessRules?: BusinessRuleRegistration[],
    objectDebugLabel?: ObjectDebugLabelRegistration,
    accessControlList?: { [action: string]: AccessControlListRegistration },
  }) => AssociationDsl,
  property: (parameters: PropertyFunctionParameters) => void,
  propertyFunction: (parameters: PropertyFunctionFunctionParameters) => void,
  propertyFunctionWithTimeseries: (parameters: PropertyFunctionFunctionWithTimeseriesParameters) => void,
  relation: (parameters: RelationFunctionParameters) => void,
  instance: (parameters: InstanceFunctionParameters) => void,
  associate: (parameters: AssociateParameters) => void,
  registerCustomBusinessRules: (register: RegisterBusinessRules) => void,
  registerCustomAccessControlList: (register: RegisterAccessControlList) => void,
  registerCustomPropertyFunction: (register: RegisterPropertyFunctions) => void,
  registerCustomPropertyFunctionWithTimeseries: (register: RegisterPropertyFunctionsWithTimeseries) => void,
  initTypedModule: (initModuleToWrap?: InitModuleFunction) => () => ModuleRegistration,
}
