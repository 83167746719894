import type { OpenAPIV3 } from 'openapi-types';
import type {
  BusinessRuleHandler,
  InitialStatePropertyValidationHandler,
  MinifiedOperation,
  ObjectStoreReadOnly,
  ObjectStoreWithTimeseries,
  Operation,
  StoreObject,
  TimeRange,
  TimeseriesValue,
} from 'yooi-store';
import type { ExtractPropsValueTypes, FormulaType, PeriodicityType } from 'yooi-utils';
import type {
  ConceptStoreObject,
  DimensionsMapping,
  Field,
  FieldFilterConditions,
  MultipleParameterDefinition,
  MultipleParameterValue,
  ParametersMapping,
  ResolutionStack,
  SingleParameterDefinition,
  SingleParameterValue,
  ValueResolution,
} from '../../conceptModule';
import type { TimeseriesFormulaType } from '../../conceptModule/utils/formula/timeseriesFunctions';
import type { BusinessRuleRegistration, MandatoryProperty, ModuleDsl } from '../types/TypeModuleDslType';

export enum PropertyTypeMode {
  Basic = 'Basic',
  Local = 'Local',
  Import = 'Import',
}

export type PropertyType =
  | { mode: PropertyTypeMode.Basic, name: string }
  | { mode: PropertyTypeMode.Local, name: string }
  | { mode: PropertyTypeMode.Import, name: string, path: string, isAbsolutePath: boolean };

export interface PropertyAs {
  type: PropertyType,
  isArray?: boolean,
}

export interface AsPropertyBusinessRuleRegistration {
  (objectStore: ObjectStoreReadOnly, propertyId: string): BusinessRuleHandler,
}

export interface DslConfigurationHandler<Configuration extends Field> {
  withApiAlias: boolean,
  resolveConfiguration: () => Configuration,
  resolveConfigurationWithOverride: (dimensionsMapping: DimensionsMapping) => Configuration,
}

type ExtractUpdateOperations<UpdateOperationTypesDefinition> = ExtractPropsValueTypes<{
  [UpdateOperationActionKey in keyof UpdateOperationTypesDefinition]: {
    action: UpdateOperationActionKey, payload?: UpdateOperationTypesDefinition[UpdateOperationActionKey],
  }
}>;

export type UpdateOperationHandlers<UpdateOperationTypesDefinition> = UpdateOperationTypesDefinition extends object ? {
  [UpdateOperationActionKey in keyof UpdateOperationTypesDefinition]: {
    applyOperation: (
      dimensionsMapping: DimensionsMapping,
      parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
      value: UpdateOperationTypesDefinition[UpdateOperationActionKey]
    ) => void,
    sanitizeOperation: (oldOperation?: ExtractUpdateOperations<UpdateOperationTypesDefinition>) => UpdateOperationTypesDefinition[UpdateOperationActionKey],
  }
} : undefined;

type ExtractUpdateOperationsActionKeys<UpdateOperationTypesDefinition> = UpdateOperationTypesDefinition extends object ? keyof UpdateOperationTypesDefinition : undefined;

type Describe =
  | { hasData: false }
  | { hasData: true, returnType: FormulaType, extraAcceptedTypes?: FormulaType[], timeseriesMode: 'none' }
  | { hasData: true, returnType: FormulaType, extraAcceptedTypes?: FormulaType[], timeseriesMode: 'implicit' | 'explicit' };

type PathStepConfigurationValueResolver = (
  dimensionsMapping: DimensionsMapping,
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
  resolutionStack: ResolutionStack,
) => unknown;

type PathStepConfigurationDimensionResolver = (
  dimensionsMapping: DimensionsMapping,
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
  resolutionStack: ResolutionStack,
) => { type: 'single', instance: ConceptStoreObject | undefined } | { type: 'multiple', instances: ConceptStoreObject[] };

type PathStepConfigurationFieldDimensionsResolver = (
  dimensionsMapping: DimensionsMapping,
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
) => DimensionsMapping;

type PathStepConfigurationTimeseriesResolver = (
  dimensionsMapping: DimensionsMapping,
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
  resolutionStack: ResolutionStack,
  dateRange: TimeRange | undefined,
) => TimeseriesValue[] | undefined;

export type ExcelValueType = { format: 'string', value: string | undefined }
| { format: 'number', value: number | undefined, decimal?: number | undefined, unit?: string | undefined }
| { format: 'boolean', value: boolean }
| { format: 'date', value: number | undefined, period: PeriodicityType | undefined };

export type DslFieldHandler<
  StoreValue,
  UpdateValue,
  WithoutFormulaValue,
  ResolvedValue,
  RestValue,
  FilterConditions extends FieldFilterConditions<ResolvedValue> | undefined,
  UpdateOperationsTypes,
  PathStepConfiguration,
  ExportConfiguration
> = {
  describe: () => Describe,
  restApi: {
    returnTypeSchema: OpenAPIV3.ReferenceObject | OpenAPIV3.SchemaObject,
    formatValue: (value: ResolvedValue, canRead: (id: string[]) => boolean) => RestValue,
  },
  getStoreValue: (dimensionsMapping: DimensionsMapping) => StoreValue,
  getValueResolution: (dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack, timeRange?: TimeRange, isTimeseries?: boolean) => ValueResolution<ResolvedValue>,
  getValueWithoutFormula: (dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack, TimeRange?: TimeRange) => WithoutFormulaValue,
  getTimeseriesValueWithoutFormula?: (id: string, timeRange?: TimeRange) => TimeseriesValue<WithoutFormulaValue>[],
  updateValue: (dimensionsMapping: DimensionsMapping, value: UpdateValue) => void,
  isEmpty: (dimensionsMapping: DimensionsMapping) => boolean,
  isSaneValue: (objectId: string) => { isValid: boolean, error?: Error },
  getValueAsText?: (dimensionsMapping: DimensionsMapping) => string | undefined,
  getExportColumnHeaders?: (
    configuration: ExportConfiguration | undefined,
    fieldLabel: string,
    parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
    getDimensionMappings: () => DimensionsMapping[],
  ) => {
    getHeaders: (columnIndex: number) => ExcelValueType[],
    columnsNumber: number,
    getColumnConfiguration: (columnIndex: number) => ExportConfiguration | undefined,
  } | { error: string },
  getExportValue?: (dimensionsMapping: DimensionsMapping, configuration: ExportConfiguration | undefined, options: { hostname?: string }) => ExcelValueType,
  getValueProxy?: (dimensionsMapping: DimensionsMapping) => object,
  filterConditions: FilterConditions,
  getTargetType?: () => StoreObject | null,
  getTargetTypes?: (fromTypeId: string) => StoreObject[],
  resolvePathStepConfiguration: (configuration: PathStepConfiguration) => {
    hasData: true,
    timeseriesMode: 'none',
    getValueResolutionType: () => FormulaType,
    resolveValue: PathStepConfigurationValueResolver,
    resolveDimension?: PathStepConfigurationDimensionResolver,
    getFieldResolutionDimensions?: PathStepConfigurationFieldDimensionsResolver,
  } | {
    hasData: true,
    timeseriesMode: 'implicit',
    getValueResolutionType: () => FormulaType,
    resolveValue: PathStepConfigurationValueResolver,
    getTimeseriesResolutionType: () => TimeseriesFormulaType,
    resolveDimension?: PathStepConfigurationDimensionResolver,
    resolveTimeseries: PathStepConfigurationTimeseriesResolver,
    getFieldResolutionDimensions?: PathStepConfigurationFieldDimensionsResolver,
  } | {
    hasData: true,
    timeseriesMode: 'explicit',
    getTimeseriesResolutionType: () => TimeseriesFormulaType,
    resolveTimeseries: PathStepConfigurationTimeseriesResolver,
    getFieldResolutionDimensions?: PathStepConfigurationFieldDimensionsResolver,
  } | {
    hasData: false,
  },
} & (UpdateOperationsTypes extends object ? {
  updateOperationHandlers: UpdateOperationHandlers<UpdateOperationsTypes>,
} : { updateOperationHandlers?: never });

export interface GetFieldHandler<
  Configuration extends Field,
  StoreValue,
  UpdateValue,
  WithoutFormulaValue,
  ResolvedValue,
  RestValue,
  FilterConditions extends FieldFilterConditions<ResolvedValue> | undefined,
  UpdateOperationsTypes,
  PathStepConfiguration,
  ExportConfiguration
> {
  (
    objectStore: ObjectStoreWithTimeseries,
    fieldId: string
  ): DslConfigurationHandler<Configuration> & DslFieldHandler<
    StoreValue, UpdateValue, WithoutFormulaValue, ResolvedValue, RestValue, FilterConditions, UpdateOperationsTypes, PathStepConfiguration, ExportConfiguration
  >,
}

export interface GetDslFieldHandler<
  Configuration extends Field,
  StoreValue,
  UpdateValue,
  WithoutFormulaValue,
  ResolvedValue,
  RestValue,
  FilterConditions extends FieldFilterConditions<ResolvedValue> | undefined,
  UpdateOperationsTypes,
  PathStepConfiguration,
  ExportConfiguration
> {
  fieldDefinitionId: string,
  (
    objectStore: ObjectStoreWithTimeseries,
    fieldId: string
  ): DslConfigurationHandler<Configuration> & DslFieldHandler<
    StoreValue, UpdateValue, WithoutFormulaValue, ResolvedValue, RestValue, FilterConditions, UpdateOperationsTypes, PathStepConfiguration, ExportConfiguration
  >,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenericGetDslFieldHandler = GetDslFieldHandler<Field, any, any, any, any, any, any, any, any, any>;

export type ExtractDslHandlerTypes<T> = T extends GetDslFieldHandler<
    infer Configuration,
    infer StoreValue,
    infer UpdateValue,
    infer WithoutFormulaValue,
    infer ResolvedValue,
    infer RestValue,
    infer FilterConditions,
    infer UpdateOperationsTypes,
    infer PathStepConfiguration,
    infer ExportConfiguration
  >
  ? {
    Configuration: Configuration,
    StoreValue: StoreValue,
    UpdateValue: UpdateValue,
    WithoutFormulaValue: WithoutFormulaValue,
    ResolvedValue: ResolvedValue,
    RestValue: RestValue,
    FilterConditions: FilterConditions,
    UpdateOperationsActionKeys: ExtractUpdateOperationsActionKeys<UpdateOperationsTypes>,
    UpdateOperations: ExtractUpdateOperations<UpdateOperationsTypes>,
    PathStepConfiguration: PathStepConfiguration,
    ExportConfiguration: ExportConfiguration,
  } : never;

export interface DslRegistrationFieldHandler<
  Configuration extends Field,
  StoreValue,
  UpdateValue,
  WithoutFormulaValue,
  ResolvedValue,
  RestValue,
  FilterConditions extends FieldFilterConditions<ResolvedValue> | undefined,
  UpdateOperationsTypes,
  PathStepConfiguration,
  ExportConfiguration
> {
  (
    objectStore: ObjectStoreWithTimeseries,
    fieldId: string,
    configurationHandler: DslConfigurationHandler<Configuration>
  ): FilterConditions extends FieldFilterConditions<ResolvedValue>
    ? DslFieldHandler<StoreValue, UpdateValue, WithoutFormulaValue, ResolvedValue, RestValue, FilterConditions, UpdateOperationsTypes, PathStepConfiguration, ExportConfiguration>
    : Omit<DslFieldHandler<StoreValue, UpdateValue, WithoutFormulaValue, ResolvedValue, RestValue, undefined, UpdateOperationsTypes, PathStepConfiguration, ExportConfiguration>, 'filterConditions'>,
}

export type ExtractRegistrationHandlerTypes<T> = T extends DslRegistrationFieldHandler<
    infer Configuration,
    infer StoreValue,
    infer UpdateValue,
    infer WithoutFormulaValue,
    infer ResolvedValue,
    infer RestValue,
    infer FilterConditions,
    infer UpdateOperationsTypes,
    infer PathStepConfiguration,
    infer ExportConfiguration
  >
  ? {
    Configuration: Configuration,
    StoreValue: StoreValue,
    UpdateValue: UpdateValue,
    WithoutFormulaValue: WithoutFormulaValue,
    ResolvedValue: ResolvedValue,
    RestValue: RestValue,
    FilterConditions: FilterConditions,
    UpdateOperations: ExtractUpdateOperations<UpdateOperationsTypes>,
    PathStepConfiguration: PathStepConfiguration,
    ExportConfiguration: ExportConfiguration,
  } : never;

export interface ValueHistoryEventProducer {
  collectImpactedInstances: (operation: MinifiedOperation) => (string[])[],
  getValue: (id: string[]) => { value: unknown, version: number },
  areValuesEquals: (value1: { value: unknown, version: number } | undefined, value2: { value: unknown, version: number } | undefined) => boolean,
}

export interface TimeseriesHistoryEventProducer {
  isImpacted: (operation: Operation) => boolean,
  formatValue: (value: unknown | null) => { value: unknown | null, version: number },
  areValuesEquals: (value1: { value: unknown, version: number } | undefined, value2: { value: unknown, version: number } | undefined) => boolean,
}

export interface CreateHistoryEventProducer {
  (objectStore: ObjectStoreReadOnly, fieldId: string): { value: ValueHistoryEventProducer } | { timeseries: TimeseriesHistoryEventProducer },
}

export interface FieldModuleDslRegistration<
  Configuration extends Field,
  StoreValue,
  UpdateValue,
  WithoutFormulaValue,
  ResolvedValue,
  RestValue,
  FilterConditions extends FieldFilterConditions<ResolvedValue> | undefined,
  UpdateOperationsTypes,
  PathStepConfiguration,
  ExportConfiguration
> {
  model: {
    label: string,
    title: string,
    extraModel?: (moduleDsl: ModuleDsl) => void,
    businessRules?: BusinessRuleRegistration[],
    asPropertyBusinessRules?: AsPropertyBusinessRuleRegistration[],
    withApiAlias?: boolean,
    properties?: {
      label: string,
      as: PropertyAs,
      mandatory?: MandatoryProperty,
      supportLocalOverride?: boolean,
      businessRules?: BusinessRuleRegistration[],
      initialStateValidationHandler?: InitialStatePropertyValidationHandler,
    }[],
    relations?: {
      label: string,
      mandatory?: MandatoryProperty,
      supportLocalOverride?: boolean,
      targetTypeId: string,
      reverseLabel: string,
      businessRules?: BusinessRuleRegistration[],
    }[],
  },
  handler: DslRegistrationFieldHandler<
    Configuration,
    StoreValue,
    UpdateValue,
    WithoutFormulaValue,
    ResolvedValue,
    RestValue,
    FilterConditions,
    UpdateOperationsTypes,
    PathStepConfiguration,
    ExportConfiguration
  >,
  historyEventProducer?: CreateHistoryEventProducer,
}

export interface FieldModuleDsl {
  registerField: <
    Configuration extends Field,
    StoreValue,
    UpdateValue,
    WithoutFormulaValue,
    ResolvedValue,
    RestValue,
    FilterConditions extends FieldFilterConditions<ResolvedValue> | undefined,
    UpdateOperationsTypes extends object | undefined,
    PathStepConfiguration extends object | undefined,
    ExportConfiguration
  >(
    registration: FieldModuleDslRegistration<
      Configuration, StoreValue, UpdateValue, WithoutFormulaValue, ResolvedValue, RestValue, FilterConditions, UpdateOperationsTypes, PathStepConfiguration, ExportConfiguration
    >
  ) => GetDslFieldHandler<
    Configuration,
    StoreValue,
    UpdateValue,
    WithoutFormulaValue,
    ResolvedValue,
    RestValue,
    FilterConditions,
    UpdateOperationsTypes,
    PathStepConfiguration,
    ExportConfiguration
  >,
}
