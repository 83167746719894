import type { FunctionComponent } from 'react';
import { Field_Formula } from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import useStore from '../../store/useStore';
import { FIELD_EDITION_DIMENSIONS } from './fields/fieldDimensionUtils';
import { getFieldConfigurationHandler, getFieldDefinitionHandler } from './fields/FieldLibrary';
import { FieldEditionOptionMode, FieldEditionVariant } from './fields/FieldLibraryTypes';
import useEditionHandler from './fields/useEditionHandler';
import FieldTypeOptions from './FieldTypeOptions';
import { isFilteredField } from './fieldUtils';

interface FieldTypeEditionCompositeProps {
  fieldId: string,
  conceptDefinitionId: string,
  isDeveloperMode: boolean,
}

const FieldTypeEditionComposite: FunctionComponent<FieldTypeEditionCompositeProps> = ({ fieldId, conceptDefinitionId, isDeveloperMode }) => {
  const store = useStore();

  const fieldConfigurationHandler = getFieldConfigurationHandler(store, fieldId);
  const editionHandler = useEditionHandler(fieldConfigurationHandler.getInitialState(conceptDefinitionId));

  const field = store.getObject(fieldId);
  const fieldTypeId = field[Instance_Of] as string;

  const { getEditionOptions, getFieldTitle, getFieldSubTitle, getFieldIcon, ofField } = getFieldDefinitionHandler(store, fieldTypeId);
  const sections = getEditionOptions({
    mode: isDeveloperMode ? FieldEditionOptionMode.FieldDeveloperMode : FieldEditionOptionMode.Field,
    modelTypeId: conceptDefinitionId,
    isEdition: true,
    editionHandler,
    readOnly: false,
    variant: FieldEditionVariant.composite,
  });

  const title = getFieldTitle(editionHandler, conceptDefinitionId);
  const subTitle = getFieldSubTitle?.(editionHandler, conceptDefinitionId);
  const icon = getFieldIcon?.(editionHandler) ?? ofField(fieldId).getIcon();

  return (
    <FieldTypeOptions
      title={title}
      subTitle={subTitle}
      icon={typeof icon === 'object' ? icon.name : icon}
      sections={sections}
      onCancel={() => {
        editionHandler.reset();
      }}
      onSubmit={() => {
        if (editionHandler.hasModification()) {
          const valueToSubmit = editionHandler.reset();
          fieldConfigurationHandler.submitFieldUpdate(valueToSubmit, conceptDefinitionId);
        } else {
          editionHandler.reset();
        }
      }}
      isMultidimensional={Object.entries(editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {}).length > 1}
      isFiltered={isFilteredField(store, fieldId)}
      isComputing={editionHandler.getValue(Field_Formula) !== undefined}
    />
  );
};

export default FieldTypeEditionComposite;
