import type { FunctionComponent, ReactElement } from 'react';
import { Fragment } from 'react';
import type { Datum } from 'victory';
import { VictoryArea, VictoryChart, VictoryContainer, VictoryGroup, VictoryPolarAxis, VictoryScatter, VictoryTooltip } from 'victory';
import { joinObjects } from 'yooi-utils';
import { getFontStyle } from '../../../utils/fontUtils';
import { approximateNumber } from '../../../utils/numberUtils';
import { remToPx } from '../../../utils/sizeUtils';
import useTheme from '../../../utils/useTheme';
import ChartLegend, { CHART_LEGEND_LINE_HEIGHT } from '../ChartLegend';
import ChartTooltipForVictory from '../internal/ChartTooltipForVictory';
import FlyoutWrapper from '../internal/FlyoutWrapper';
import NoLabel from '../NoLabel';

const AXIS_ARRAY = [0.25, 0.5, 0.75, 1];

export interface RadarChartData {
  fieldData: { key: string, label: string, yMax: number, yMin: number }[],
  instanceData: Record<string, { color?: string, label: string, data: { x: string, y: number | null, value?: number }[] }>,
}

interface RadarChartProps {
  fieldData: RadarChartData['fieldData'],
  instanceData: RadarChartData['instanceData'],
  height: number,
  width: number,
  withLegend: boolean,
  renderTooltip: (instanceId: string, fieldIds: string[], color: string) => ReactElement,
}

const RadarChart: FunctionComponent<RadarChartProps> = ({ fieldData, instanceData, height, width, withLegend, renderTooltip }) => {
  const theme = useTheme();

  const actualWidth = Math.max(0, width);
  const actualHeight = withLegend ? height - remToPx(CHART_LEGEND_LINE_HEIGHT) : height;

  return (
    <>
      <VictoryChart
        padding={70}
        polar
        height={actualHeight}
        width={actualWidth}
        domain={{ y: [0, 1] }}
        containerComponent={(
          <VictoryContainer
            preserveAspectRatio="xMinYMin meet"
            responsive={false}
          />
        )}
      >
        <VictoryPolarAxis
          dependentAxis
          tickFormat={() => ''}
          tickValues={AXIS_ARRAY}
          style={{
            axis: { stroke: 'none' },
            grid: { stroke: theme.color.border.default, strokeWidth: 1, shapeRendering: 'geometricPrecision', strokeDasharray: '10, 5' },
          }}
          standalone={false}
        />
        <VictoryPolarAxis
          labelPlacement="parallel"
          tickFormat={() => ''}
          style={{
            axis: { stroke: 'none' },
            grid: { stroke: theme.color.border.default, shapeRendering: 'crispedges' },
            tickLabels: joinObjects(
              getFontStyle(theme.font.small),
              { fill: theme.color.text.secondary }
            ),
          }}
        />
        {
          fieldData.map(({ key, label, yMax, yMin }, i) => (
            <VictoryPolarAxis
              key={key}
              dependentAxis
              style={{
                axisLabel: joinObjects(
                  { padding: 30, fill: theme.color.text.secondary },
                  getFontStyle(theme.font.small)
                ),
                axis: { stroke: 'none' },
                grid: { stroke: 'none' },
                tickLabels: joinObjects(
                  getFontStyle(theme.font.small),
                  { fill: theme.color.text.secondary }
                ),
              }}
              labelPlacement="perpendicular"
              axisAngle={(360 / fieldData.length) * i}
              label={label}
              tickFormat={(t: number) => {
                const quot = (yMax - yMin) !== 0 ? (yMax - yMin) : 1;
                let result;
                if (yMin < 0 && yMax < 0) {
                  result = (1 - t) * yMin;
                } else if (yMin < 0) {
                  result = ((1 - t) * yMin) + (t * yMax);
                } else {
                  result = yMin + (t * quot);
                }
                return approximateNumber(result);
              }}
              tickValues={AXIS_ARRAY}
            />
          ))
        }
        <VictoryGroup
          style={{ data: { fillOpacity: 0.2, strokeWidth: 2 } }}
        >
          {Object.entries(instanceData).map(([id, { color, data }]) => (
            <VictoryArea
              key={id}
              data={data}
              style={{
                data: {
                  fill: theme.color.transparent,
                  stroke: color || theme.color.border.primary,
                  strokeWidth: 3,
                },
              }}
            />
          ))}
          {Object.entries(instanceData).map(([id, { color, data }]) => (
            <VictoryScatter
              key={id}
              data={data}
              labels={({ datum }: { datum: Datum }) => datum.value}
              size={4}
              style={{
                data: {
                  fill: color || theme.color.border.primary,
                  fillOpacity: 1,
                },
              }}
              labelComponent={(
                <VictoryTooltip
                  orientation="right"
                  angle={360}
                  pointerLength={0}
                  constrainToVisibleArea
                  // We explicitly wants to avoid the label component
                  labelComponent={<NoLabel />}
                  flyoutComponent={(
                    <FlyoutWrapper>
                      {({ x, y, datum }) => (
                        <ChartTooltipForVictory
                          chartWidth={width}
                          chartHeight={height}
                          renderTooltip={() => {
                            const { x: seriesId, y: fieldValue } = datum as { x: string, y: number };
                            return (
                              <>
                                {
                                  Object.entries(instanceData)
                                    .filter(([, { data: d }]) => d.some((v) => v.x === seriesId && v.y === fieldValue))
                                    .map(([conceptId, { color: conceptColor }]) => {
                                      let seriesIds = [seriesId];
                                      if (fieldValue === 0) { // handle axis cross in 0
                                        seriesIds = instanceData[conceptId].data.filter((d) => d.y === fieldValue).map((d) => d.x);
                                      }
                                      return (
                                        <Fragment key={conceptId}>{renderTooltip(conceptId, seriesIds, conceptColor ?? theme.color.border.primary)}</Fragment>
                                      );
                                    })
                                }
                              </>
                            );
                          }}
                          x={x}
                          y={y}
                        />
                      )}
                    </FlyoutWrapper>
                  )}
                />
              )}
            />
          ))}
        </VictoryGroup>
      </VictoryChart>
      {withLegend && (
        <ChartLegend
          labels={Object.entries(instanceData)
            .map(([, { color, label }]) => ({ name: label, symbol: { fill: color ?? theme.color.border.primary }, displayStroke: !color }))}
        />
      )}
    </>
  );
};

export default RadarChart;
