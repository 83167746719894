import type { RefCallback } from 'react';
import { useCallback, useRef } from 'react';
import { remToPx } from './sizeUtils';

const useScrollOnMountRef = (scrollOnMount: boolean): RefCallback<HTMLElement | null> => {
  const scrollOnMountRef = useRef(scrollOnMount);

  return useCallback((node: HTMLElement | null) => {
    if (node && scrollOnMountRef.current) {
      const nodeBoundingRect = node.getBoundingClientRect();
      // Nav bar + sticky tab size to remove from viewport
      const stickyTabMargin = remToPx(9);
      const containerBoundingRect = node.offsetParent?.getBoundingClientRect() ?? {
        left: 0,
        right: 0,
        bottom: window.innerHeight || document.documentElement.clientHeight,
        top: stickyTabMargin,
      };

      const isInViewPort = (
        nodeBoundingRect.top >= stickyTabMargin
        && nodeBoundingRect.top >= containerBoundingRect.top
        && nodeBoundingRect.left >= containerBoundingRect.left
        && nodeBoundingRect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && nodeBoundingRect.bottom <= containerBoundingRect.bottom
        && containerBoundingRect.left <= containerBoundingRect.right
        && containerBoundingRect.right <= containerBoundingRect.left
        && nodeBoundingRect.right - containerBoundingRect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );

      if (!isInViewPort) {
        node.scrollIntoView({ block: 'center', inline: 'nearest' });
      }

      scrollOnMountRef.current = false;
    }
  }, []);
};

export default useScrollOnMountRef;
