import classnames from 'classnames';
import type { Property } from 'csstype';
import type { ComponentProps, CSSProperties, FunctionComponent, ReactElement } from 'react';
import { Fragment, useCallback } from 'react';
import base from '../../theme/base';
import { buildMargins, buildPadding, Spacing, spacingRem } from '../../theme/spacingDefinition';
import type { Theme } from '../../theme/themeUtils';
import makeStyles from '../../utils/makeStyles';
import useDerivedState from '../../utils/useDerivedState';
import useSizeContext, { buildInputSizeVariantClasses, buildInputSizeVariantMinusBorderClasses, SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import useUsageContext, { UsageContextProvider, UsageVariant } from '../../utils/useUsageContext';
import Icon, { IconColorVariant, IconName } from '../atoms/Icon';
import type IconOnlyButton from '../atoms/IconOnlyButton';
import Tooltip from '../atoms/Tooltip';
import Typo, { TypoVariant } from '../atoms/Typo';
import EditableWithDropdown, { EditableButtonVariant, EditableCloseReasons } from './EditableWithDropdown';
import InlineLoading from './InlineLoading';
import SpacingLine from './SpacingLine';

export enum DropdownSectionTitleVariants {
  main = 'main',
  secondary = 'secondary',
  inlineSecondary = 'inlineSecondary',
  inline = 'inline',
}

export enum CompositeFieldVariants {
  button = 'button',
  iconOnlyButton = 'iconOnlyButton',
}

export enum CompositeFieldCloseReasons {
  cancel = 'cancel',
  validate = 'validate',
}

const dropdownSectionTitleVariantsToTypoStyle = (theme: Theme): Record<DropdownSectionTitleVariants, { variant?: TypoVariant, color?: string }> => ({
  [DropdownSectionTitleVariants.main]: {
    variant: TypoVariant.blockTertiaryTitle,
  },
  [DropdownSectionTitleVariants.secondary]: {
    variant: TypoVariant.blockInlineTitle,
    color: theme.color.text.secondary,
  },
  [DropdownSectionTitleVariants.inlineSecondary]: {
    color: theme.color.text.primary,
  },
  [DropdownSectionTitleVariants.inline]: {},
});

const useStyles = makeStyles((theme) => ({
  rendererContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  rendererContainerMain: {
    borderRadius: base.borderRadius.medium,
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  renderContainerButton: {
    cursor: 'pointer',
    margin: spacingRem.none,
    backgroundColor: theme.color.background.neutral.subtle,
  },
  renderContainerTertiaryButton: {
    cursor: 'pointer',
    margin: spacingRem.none,
    backgroundColor: theme.color.transparent,
    '&:hover': {
      backgroundColor: theme.color.background.primarylight.default,
    },
    '&:focus': {
      backgroundColor: theme.color.background.primarylight.hover,
    },
    '&:active': {
      backgroundColor: theme.color.background.primarylight.hover,
    },
  },
  renderContainerButtonEditing: {
    cursor: 'auto',
  },
  rendererContainerBackground: {
    backgroundColor: theme.color.background.neutral.default,
  },
  rendererContainerInCell: {
    margin: '0.3rem 0.4rem',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  ...buildInputSizeVariantClasses('labelSize', ['height']),
  spacingContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
  },
  spacingContainerForIconOnlyButton: {
    paddingLeft: spacingRem.xs,
    paddingRight: spacingRem.xs,
  },
  image: {
    flexGrow: 1,
    background: 'no-repeat center center',
    backgroundSize: 'cover',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  loader: {
    flexGrow: 1,
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    borderRadius: base.borderRadius.medium,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  headerContainerWithBackground: {
    background: theme.color.background.neutral.subtle,
  },
  headerContainerWithBackgroundInCell: {
    minHeight: '3.2rem',
  },
  headerContainerWithImage: {
    borderRadius: `${base.borderRadius.medium} ${base.borderRadius.medium} 0 0`,
  },
  bodyContainer: {
    overflowY: 'scroll',
  },
  gridContainer: {
    alignItems: 'flex-start',
    display: 'grid',
    columnGap: spacingRem.s,
    rowGap: spacingRem.s,
    overflowX: 'auto',
    padding: spacingRem.s,
  },
  gridContainerFixed: {
    gridTemplateColumns: 'minmax(auto, 1fr) 3fr',
  },
  gridContainerWithCompactLabel: {
    gridTemplateColumns: 'minmax(10rem, auto) 1fr',
  },
  mergedCell: {
    gridColumn: '1 / 3',
  },
  padded: {
    marginLeft: spacingRem.s,
  },
  sectionWithTopSeparator: {
    borderTopWidth: '0.1rem',
    borderTopStyle: 'solid',
    borderTopColor: theme.color.border.default,
  },
  placeholderContainer: buildMargins({ x: Spacing.s }),
  cardPlaceholderContainer: buildPadding({ top: Spacing.xs }),
  ...buildInputSizeVariantMinusBorderClasses('button', ['height']),
  imagePaddingDropdown: {
    paddingBottom: '25%',
  },
  imagePaddingTable: {
    paddingBottom: '3.2rem', /* table line height - vertical padding */
  },
  imagePaddingDefault: {
    paddingBottom: '25%',
  },
  imageRadiusAsField: {
    borderRadius: 0,
  },
  sectionTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}), 'compositeField');

export interface CompositeLine {
  id: string,
  title?: string | ReactElement,
  titleAction?: ReactElement | null,
  titleVariant?: DropdownSectionTitleVariants,
  titlePadded?: boolean,
  info?: string,
  isVertical?: boolean,
  render: ReactElement | null,
}

export interface CompositeDropdownSection {
  id: string,
  title?: string,
  info?: string,
  subtitle?: string,
  titleVariant?: DropdownSectionTitleVariants,
  action?: ReactElement | null,
  rightAction?: ReactElement | null,
  useGridForTitle?: boolean,
  lines: CompositeLine[],
}

interface CompositeHeaderLine {
  id: string,
  customContainerStyle?: CSSProperties,
  render: (inDropdown: boolean) => ReactElement | null,
}

interface UrlImage {
  url?: string,
  loading?: boolean,
}

interface ContentImage {
  objectId: string,
  propertyId: string,
  data: Uint8Array,
  contentType: string,
}

const isUrlImage = (imageType: UrlImage | ContentImage): imageType is UrlImage => (imageType as UrlImage)?.url !== undefined || (imageType as UrlImage)?.loading !== undefined;

interface CompositeFieldProps {
  placeholder?: string,
  headerHeight?: Property.Height,
  maxBodyHeight?: Property.MaxHeight,
  headerLinesRenderers?: CompositeHeaderLine[],
  headerImage?: UrlImage | ContentImage,
  withImagePlaceholder?: boolean,
  getDropdownSectionDefinitions?: () => CompositeDropdownSection[],
  isEditing?: boolean,
  isSelected?: boolean,
  onOpenDropdown?: () => void,
  onCloseDropdown?: (reason: CompositeFieldCloseReasons) => void,
  openOnMount?: boolean,
  flexGrow?: Property.FlexGrow,
  width?: Property.Width,
  minWidth?: Property.MinWidth,
  dropdownMinWidth?: Property.MinWidth,
  dropdownMaxWidth?: Property.Width,
  variant?: CompositeFieldVariants,
  buttonVariant?: EditableButtonVariant,
  readOnly?: boolean,
  doNotExpand?: boolean,
  showDropdown?: boolean,
  withCompactLabel?: boolean,
  getIconOnlyButton?: (onClick: ComponentProps<typeof IconOnlyButton>['onClick']) => ReturnType<typeof IconOnlyButton>,
}

const CompositeField: FunctionComponent<CompositeFieldProps> = ({
  placeholder,
  headerHeight,
  maxBodyHeight,
  headerLinesRenderers = [],
  headerImage,
  withImagePlaceholder = false,
  getDropdownSectionDefinitions,
  isEditing = false,
  isSelected = false,
  onOpenDropdown,
  onCloseDropdown,
  openOnMount = false,
  flexGrow = 1,
  width,
  minWidth,
  dropdownMinWidth,
  dropdownMaxWidth,
  variant,
  buttonVariant,
  readOnly = false,
  doNotExpand = false,
  showDropdown: showDropdownProp,
  withCompactLabel = false,
  getIconOnlyButton,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const [showDropdown, setShowDropdown] = useDerivedState(() => (showDropdownProp !== undefined ? showDropdownProp : openOnMount), [showDropdownProp]);

  const openDropdown = useCallback(() => {
    if (!doNotExpand) {
      setShowDropdown(true);
      onOpenDropdown?.();
    }
  }, [doNotExpand, onOpenDropdown, setShowDropdown]);

  const closeDropdown = useCallback((reason: CompositeFieldCloseReasons) => {
    setShowDropdown(false);
    onCloseDropdown?.(reason);
  }, [onCloseDropdown, setShowDropdown]);

  const { sizeVariant: contextSizeVariant } = useSizeContext();
  const sizeContextComputed = contextSizeVariant ?? SizeVariant.main;
  const contextUsageVariant = useUsageContext();
  const usageVariant = variant || contextUsageVariant;

  const isButtonVariant = usageVariant === CompositeFieldVariants.button || usageVariant === CompositeFieldVariants.iconOnlyButton;
  const isTertiaryButtonVariant = isButtonVariant && buttonVariant === EditableButtonVariant.tertiary;
  const isTableVariant = usageVariant === UsageVariant.inTable;
  const isCardVariant = usageVariant === UsageVariant.inCard;
  const isFormVariant = usageVariant === UsageVariant.inForm;
  const isMainVariant = usageVariant === UsageVariant.inline;

  let newImageUrl: string | undefined;
  if (headerImage && !isUrlImage(headerImage)) {
    const blob = new Blob([headerImage.data]);
    newImageUrl = URL.createObjectURL(blob);
  }

  const renderValue = (inDropdown: boolean) => {
    let showImage = false;
    const headerImageValue = (headerImage && isUrlImage(headerImage)) ? headerImage.url : newImageUrl;
    const hasHeaderImage = Boolean(headerImageValue);
    if (isMainVariant) {
      showImage = hasHeaderImage || withImagePlaceholder;
    } else if (isCardVariant) {
      showImage = hasHeaderImage || ((headerImage || withImagePlaceholder) && !inDropdown);
    } else if (isTableVariant) {
      showImage = hasHeaderImage || (withImagePlaceholder && inDropdown);
    }

    if (!inDropdown && getIconOnlyButton) {
      return getIconOnlyButton(openDropdown);
    } else {
      return (
        <div
          className={classnames({
            [classes.rendererContainer]: true,
            [classes.renderContainerButton]: isButtonVariant && (!isTertiaryButtonVariant || showDropdown),
            [classes.renderContainerTertiaryButton]: isButtonVariant && isTertiaryButtonVariant && !showDropdown,
            [classes.renderContainerButtonEditing]: isButtonVariant && showDropdown,
            [classes.rendererContainerMain]: !isButtonVariant,
            [classes.rendererContainerInCell]: isTableVariant,
            [classes.rendererContainerBackground]: !isButtonVariant && headerLinesRenderers.length > 0,
            [classes[`button_${sizeContextComputed}`]]: isButtonVariant,
            [classes.image]: showImage,
            [classes.imagePaddingDropdown]: showImage && inDropdown,
            [classes.imagePaddingTable]: showImage && !inDropdown && isTableVariant,
            [classes.imagePaddingDefault]: showImage && !inDropdown && !isTableVariant,
            [classes.imageRadiusAsField]: showImage && inDropdown && !isTableVariant,
          })}
          style={showImage ? { backgroundImage: `url("${headerImageValue}")` } : undefined}
        >
          <div
            className={classnames({
              [classes.headerContainer]: true,
              [classes.headerContainerWithBackground]: !isButtonVariant && headerLinesRenderers.length > 0,
              [classes.headerContainerWithBackgroundInCell]: !isButtonVariant && headerLinesRenderers.length > 0 && isTableVariant,
              [classes.headerContainerWithImage]: headerImageValue || (headerImage && isCardVariant && !inDropdown),
            })}
            style={{
              height: (isCardVariant && !inDropdown) ? headerHeight : undefined,
              minHeight: (isCardVariant && inDropdown) ? headerHeight : undefined,
            }}
          >
            {headerLinesRenderers.length === 0 && !headerImageValue && !isTableVariant && (
              <div className={classnames({ [classes.placeholderContainer]: true, [classes.cardPlaceholderContainer]: isCardVariant && Boolean(headerHeight) })}>
                <Typo
                  maxLine={1}
                  color={isFormVariant ? theme.color.text.disabled : theme.color.text.disabled}
                >
                  {placeholder}
                </Typo>
              </div>
            )}
            {headerLinesRenderers.filter((_, index) => !isTableVariant || inDropdown || index === 0).map((line) => (
              <div
                key={line.id}
                className={classnames({
                  [classes.spacingContainer]: true,
                  [classes.spacingContainerForIconOnlyButton]: usageVariant === CompositeFieldVariants.iconOnlyButton,
                })}
                style={line.customContainerStyle}
              >
                {line.render(inDropdown)}
              </div>
            ))}
          </div>
          {showImage && headerImage && isUrlImage(headerImage) && headerImage.loading && (
            <div className={classes.loader}>
              <InlineLoading />
            </div>
          )}
        </div>
      );
    }
  };

  let renderDropdown: (() => ReactElement | null) | undefined;

  if (getDropdownSectionDefinitions) {
    renderDropdown = () => {
      const dropdownSectionDefinitions = getDropdownSectionDefinitions();
      if (dropdownSectionDefinitions.length > 0) {
        return (
          <UsageContextProvider usageVariant={UsageVariant.inForm}>
            <div
              className={classes.bodyContainer}
              style={{
                maxHeight: maxBodyHeight ?? undefined,
              }}
            >
              {dropdownSectionDefinitions.map((section, index) => {
                const renderTitle = () => {
                  if (section.title) {
                    const title = (
                      <Typo {...dropdownSectionTitleVariantsToTypoStyle(theme)[section.titleVariant ?? DropdownSectionTitleVariants.main]}>
                        {section.title}
                      </Typo>
                    );

                    if (section.useGridForTitle) {
                      return (
                        <>
                          <SpacingLine>
                            {title}
                            {section.info && <Icon tooltip={section.info} colorVariant={IconColorVariant.info} name={IconName.info} />}
                          </SpacingLine>
                          {section.subtitle && (
                            <SpacingLine>
                              <Typo {...dropdownSectionTitleVariantsToTypoStyle(theme)[section.titleVariant ?? DropdownSectionTitleVariants.secondary]}>
                                {section.subtitle}
                              </Typo>
                            </SpacingLine>
                          )}
                          {section.action}
                        </>
                      );
                    } else {
                      return (
                        <>
                          <div className={classnames(classes.mergedCell, classes.sectionTitleContainer)}>
                            <SpacingLine>
                              {title}
                              {section.action}
                            </SpacingLine>
                            {section.rightAction}
                          </div>
                          {section.subtitle && (
                            <div className={classes.mergedCell}>
                              <SpacingLine>
                                <Typo color={theme.color.text.secondary} {...dropdownSectionTitleVariantsToTypoStyle(theme)[DropdownSectionTitleVariants.secondary]}>
                                  {section.subtitle}
                                </Typo>
                              </SpacingLine>
                            </div>
                          )}
                        </>
                      );
                    }
                  } else {
                    return null;
                  }
                };

                // Only having merged cell produce a weird behavior (ending up resizing after render)
                const requiresColumns = section.useGridForTitle || section.lines.some(({ title, isVertical }) => (title && !isVertical));

                return (
                  <div
                    key={section.id}
                    className={classnames({
                      [classes.gridContainer]: true,
                      [classes.gridContainerFixed]: requiresColumns && !withCompactLabel,
                      [classes.gridContainerWithCompactLabel]: requiresColumns && withCompactLabel,
                      [classes.sectionWithTopSeparator]: index > 0,
                    })}
                  >
                    {renderTitle()}
                    {section.lines.map((line) => {
                      if (line.title) {
                        return (
                          <Fragment key={line.id}>
                            <div
                              className={classnames({
                                [classes.label]: true,
                                [classes[`labelSize_${sizeContextComputed}`]]: Boolean(sizeContextComputed),
                                [classes.mergedCell]: line.isVertical,
                                [classes.padded]: line.titlePadded,
                              })}
                            >
                              <SpacingLine>
                                {typeof line.title === 'string' ? (
                                  <Tooltip title={line.title}>
                                    <Typo maxLine={1} {...dropdownSectionTitleVariantsToTypoStyle(theme)[line.titleVariant ?? DropdownSectionTitleVariants.inlineSecondary]}>
                                      {line.title}
                                    </Typo>
                                  </Tooltip>
                                ) : line.title}
                                {line.info && (
                                  <Icon tooltip={line.info} colorVariant={IconColorVariant.info} name={IconName.info} />
                                )}
                                {line.titleAction}
                              </SpacingLine>
                            </div>
                            {line.isVertical && requiresColumns ? (<div className={classes.mergedCell}>{line.render}</div>) : null}
                            {!line.isVertical || (line.isVertical && !requiresColumns) ? line.render : null}
                          </Fragment>
                        );
                      } else if (requiresColumns) {
                        return (
                          <Fragment key={line.id}>
                            <div className={classes.mergedCell}>
                              {line.render}
                            </div>
                          </Fragment>
                        );
                      } else {
                        return (
                          <Fragment key={line.id}>
                            {line.render}
                          </Fragment>
                        );
                      }
                    })}
                  </div>
                );
              })}
            </div>
            <div
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex={0}
              onFocus={() => {
                closeDropdown(CompositeFieldCloseReasons.validate);
              }}
            />
          </UsageContextProvider>
        );
      } else {
        return null;
      }
    };
  }

  return (
    <EditableWithDropdown
      variant={isButtonVariant ? UsageVariant.inForm : usageVariant}
      buttonVariant={buttonVariant}
      isSelected={isSelected}
      isEditing={isEditing}
      showDropdown={showDropdown}
      openDropdown={openDropdown}
      readOnly={readOnly}
      closeDropdown={(closeReason) => {
        if (closeReason === EditableCloseReasons.onEscapeKeyDown) {
          closeDropdown(CompositeFieldCloseReasons.cancel);
        } else if (closeReason === EditableCloseReasons.onBackdropClick) {
          closeDropdown(CompositeFieldCloseReasons.validate);
        }
      }}
      autoFocus
      renderValue={renderValue}
      renderDropdown={renderDropdown}
      editableSizes={{
        flexGrow: !width ? flexGrow : undefined,
        minWidth,
      }}
      dropdownSizes={{
        sameWidth: !width ? isMainVariant : undefined,
        width: width ?? (isMainVariant ? '64rem' : undefined),
        minWidth: dropdownMinWidth,
        maxWidth: dropdownMaxWidth,
      }}
    />
  );
};

export default CompositeField;
