import type { FunctionComponent } from 'react';
import BlockTitle from '../../../../../components/templates/BlockTitle';
import VerticalBlock from '../../../../../components/templates/VerticalBlock';
import i18n from '../../../../../utils/i18n';
import RelevantDimensionsEditor from './RelevantDimensionsEditor';
import SearchConfigurationEditor from './SearchConfigurationEditor';

interface BehaviorTabProps {
  conceptDefinitionId: string,
}

const BehaviorTab: FunctionComponent<BehaviorTabProps> = ({ conceptDefinitionId }) => (
  <VerticalBlock>
    <VerticalBlock asBlockContent withSeparation>
      <BlockTitle
        title={i18n`Favorite fields`}
        subtitle={i18n`The selected fields will be used for visualisation options such as 'Group by' or 'Color by'.`}
        anchor="#favoritefields"
      />
      <RelevantDimensionsEditor conceptDefinitionId={conceptDefinitionId} />
    </VerticalBlock>
    <VerticalBlock asBlockContent>
      <BlockTitle
        title={i18n`Search configuration`}
        subtitle={i18n`The selected fields will be used by the text search for instances list. An empty list means all text field are used.`}
        anchor="#searchconfiguration"
      />
      <SearchConfigurationEditor conceptDefinitionId={conceptDefinitionId} />
    </VerticalBlock>
  </VerticalBlock>
);

export default BehaviorTab;
