import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { getSpacing, Spacing } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import type { TabsProps as HeaderTabsProps } from '../molecules/Tabs';
import Tabs from '../molecules/Tabs';

const useStyles = makeStyles((theme) => ({
  left: {
    gridColumnStart: 1,
  },
  content: {
    display: 'grid',
    gridTemplateColumns: `${getSpacing(Spacing.s /* title left margin */, 0.1 /* title border width */)} 1fr`,
    gridColumnStart: 2,
  },
  right: {
    gridColumnStart: 3,
  },
  line: {
    alignSelf: 'end',
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
  },
}), 'headerTabs');

const HeaderTabs: FunctionComponent<HeaderTabsProps> = ({ tabs, selectedTabIndex, onSelectedIndexChanged }) => {
  const classes = useStyles();

  return (
    <>
      <span className={classnames(classes.line, classes.left)} />
      <div className={classes.content}>
        <span className={classes.line} />
        <Tabs
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          onSelectedIndexChanged={onSelectedIndexChanged}
        />
      </div>
      <span className={classnames(classes.line, classes.right)} />
    </>
  );
};

export default HeaderTabs;
