import type { ComponentProps, FunctionComponent, ReactElement, ReactNode } from 'react';
import { useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import type { AttachmentStoreObject, DimensionsMapping } from 'yooi-modules/modules/conceptModule';
import { buildDimensionalId, getInstanceLabel } from 'yooi-modules/modules/conceptModule';
import {
  Attachment,
  Attachment_Revision,
  Attachment_Role_FieldDimensions,
  Attachment_Role_FileName,
  Attachment_UploadedAt,
  Attachment_UploadedBy,
  Attachment_VarRoles_Dimensions,
} from 'yooi-modules/modules/conceptModule/ids';
import type { AutoProvisioningMap } from 'yooi-utils';
import { compareString, comparing, createAutoProvisioningMap, sleep } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../../components/atoms/Button';
import { IconName } from '../../../../components/atoms/Icon';
import Typo from '../../../../components/atoms/Typo';
import UploadFileForm from '../../../../components/inputs/UploadFileForm';
import ConfirmationModal, { ConfirmationModalVariant } from '../../../../components/molecules/ConfirmationModal';
import { TableSortDirection } from '../../../../components/molecules/Table';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { clearNotification, notifyError, notifyInfo } from '../../../../utils/notify';
import useDeleteModal from '../../../../utils/useDeleteModal';
import useFilterAndSort, { buildNumberColumnComparatorHandler, buildStringColumnComparatorHandler } from '../../useFilterAndSort';
import AttachmentFieldCardsRenderer from './AttachmentFieldCardsRenderer';
import AttachmentFieldCellRenderer from './AttachmentFieldCellRenderer';
import AttachmentFieldTableRenderer from './AttachmentFieldTableRenderer';
import { getAttachmentList } from './attachmentUtils';

type ConfirmationModalContext = Parameters<ComponentProps<typeof UploadFileForm>['onChange']>[0];

export interface AttachmentEntry {
  key: string,
  name: string,
  uploadedOn: number | undefined,
  uploadedBy: string | undefined,
  revision: string,
  onDownload: () => void,
  onDelete: () => void,
  buildLink: () => ReactNode,
}

interface AttachmentFieldRendererProps {
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
  readOnly: boolean,
  variant: 'table' | 'cards' | 'cell',
}

const AttachmentFieldRenderer: FunctionComponent<AttachmentFieldRendererProps> = ({ fieldId, dimensionsMapping, readOnly, variant }) => {
  const store = useStore();

  const inputRef = useRef<HTMLInputElement>(null);

  const dimensionalId = buildDimensionalId(dimensionsMapping);
  const filterId = dimensionalId.join('|');

  const [doDelete, deleteModal] = useDeleteModal<{ name: string }>({
    doDelete: ({ name }) => {
      store.withAssociation(Attachment)
        .withRole(Attachment_Role_FileName, name)
        .withRole(Attachment_Role_FieldDimensions, dimensionalId[0])
        .withVarRoles(Attachment_VarRoles_Dimensions, dimensionalId.slice(1))
        .deleteObject();
    },
    shouldConfirm: () => true,
    getModalProps: () => ({
      title: i18n`Are you sure that you want to delete this file?`,
      content: (<Typo>{i18n`This action cannot be reverted.`}</Typo>),
    }),
  });

  const requests: AutoProvisioningMap<string, { toastId?: string, status: 'start' | 'pending' | 'canceled' }> = createAutoProvisioningMap();

  const onDownload = (revision: string, name: string, requestId: string = uuid()) => {
    const request = requests.getOrCreate(requestId, () => ({ status: 'start' }));
    if (request.status === 'canceled') {
      return;
    } else if (request.status === 'start') {
      notifyInfo(i18n`"${name}" checked for malware...`, {
        icon: { icon: IconName.info },
        persist: true,
        closeable: false,
        onToast: (id) => {
          request.toastId = id;
        },
        actions: [{
          key: 'cancel',
          icon: IconName.close,
          onClick: () => {
            if (request.toastId) {
              clearNotification(request.toastId);
            }
            request.status = 'canceled';
          },
          tooltip: i18n`Cancel`,
        }],
      });
      request.status = 'pending';
    }
    store.isSafeAttachment(
      { objectId: dimensionalId.join('|'), propertyId: fieldId, revisionId: revision },
      async (isSafe) => {
        if (request.status !== 'canceled') {
          if (isSafe === 'pending') {
            await sleep(1000);
            onDownload(revision, name, requestId);
          } else if (isSafe === 'safe') {
            if (request.toastId) {
              clearNotification(request.toastId);
            }
            // anchor link
            const element = document.createElement('a');
            element.href = store.getAttachmentUrl(dimensionalId.join('|'), fieldId)(revision, false);
            element.download = name;
            // simulate link click
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            document.body.removeChild(element);
          } else if (isSafe === 'unsafe') {
            if (request.toastId) {
              clearNotification(request.toastId);
            }
            notifyError(i18n`Unsafe document, please contact support...`);
          }
        }
      },
      () => {}
    );
  };

  const attachments: AttachmentEntry[] = getAttachmentList(store, dimensionsMapping).map(({ role, object }) => {
    const revision = object[Attachment_Revision];
    const name = role(Attachment_Role_FileName);
    return ({
      key: object.key,
      name,
      revision,
      uploadedOn: object[Attachment_UploadedAt],
      uploadedBy: object[Attachment_UploadedBy],
      onDownload: () => onDownload(revision, name),
      onDelete: () => doDelete({ name }),
      buildLink: () => (<Button title={name} tooltip={name} maxLine={1} variant={ButtonVariant.link} onClick={() => onDownload(revision, name)} />),
    });
  });

  const [confirmUploadModalContext, setConfirmationUploadModalContext] = useState<ConfirmationModalContext | undefined>(undefined);

  const doUploadAttachment = ({ contentType, data, name }: ConfirmationModalContext) => {
    setConfirmationUploadModalContext(undefined);
    const objectId = dimensionalId.join('|');
    let tooQuick = false;
    let toastId: string;
    notifyInfo(i18n`uploading "${name}"...`, {
      onToast: (id) => {
        toastId = id;
        if (tooQuick) {
          clearNotification(toastId);
        }
      },
      persist: true,
      closeable: false,
      icon: { icon: IconName.info },
    });
    store.uploadAttachment(
      { objectId, propertyId: fieldId, contentType, data, name },
      (revisionId) => {
        store.withAssociation(Attachment)
          .withRole(Attachment_Role_FileName, name)
          .withRole(Attachment_Role_FieldDimensions, dimensionalId[0])
          .withVarRoles(Attachment_VarRoles_Dimensions, dimensionalId.slice(1))
          .updateObject<AttachmentStoreObject>({
            [Attachment_Revision]: revisionId,
          });
        clearNotification(toastId);
        tooQuick = true;
      },
      () => {
        clearNotification(toastId);
        tooQuick = true;
      }
    );
  };

  let modalComponent: ReactElement | null = null;
  if (confirmUploadModalContext !== undefined) {
    modalComponent = (
      <ConfirmationModal
        open
        variant={ConfirmationModalVariant.confirm}
        title={i18n`'${confirmUploadModalContext.name}' already exists. Do you want to replace it?`}
        alternateConfirmLabel={i18n`A file with the same name already exists. Replacing it will overwrite its current contents.`}
        onConfirm={() => doUploadAttachment(confirmUploadModalContext)}
        confirmLabel={i18n`Replace`}
        cancelLabel={i18n`Cancel`}
        onCancel={() => {
          setConfirmationUploadModalContext(undefined);
        }}
        render={() => null}
      />
    );
  }

  const { generateList, generatePageList, doSort, sortCriteria } = useFilterAndSort(
    filterId,
    attachments,
    undefined,
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case 'name':
            return buildStringColumnComparatorHandler(key, direction);
          case 'uploadedOn':
            return buildNumberColumnComparatorHandler(key, direction);
          case 'uploadedBy':
            return {
              comparator: comparing(compareString, direction === TableSortDirection.desc),
              extractValue: (item) => {
                const instanceId = item[key];
                const instance = instanceId ? store.getObjectOrNull(instanceId) : undefined;
                return instance ? getInstanceLabel(store, instance) : undefined;
              },
            };
          default:
            return undefined;
        }
      },
      initial: { key: 'name', direction: TableSortDirection.asc },
    }
  );

  let content: ReactNode;
  switch (variant) {
    case 'table': {
      const { list, pagination } = generatePageList(10);
      content = (
        <AttachmentFieldTableRenderer
          attachments={list}
          pagination={pagination}
          onCreate={() => inputRef.current?.click()}
          readOnly={readOnly}
          doSort={doSort}
          sortCriteria={sortCriteria}
        />
      );
      break;
    }
    case 'cards':
      content = <AttachmentFieldCardsRenderer attachments={generateList().list} onCreate={() => inputRef.current?.click()} readOnly={readOnly} />;
      break;
    case 'cell':
      content = <AttachmentFieldCellRenderer attachments={generateList().list} onCreate={() => inputRef.current?.click()} readOnly={readOnly} />;
      break;
  }

  return (
    <>
      {content}
      {modalComponent}
      {deleteModal}
      {!readOnly && (
        <UploadFileForm
          value={undefined}
          onChange={(context) => {
            if (store.withAssociation(Attachment)
              .withRole(Attachment_Role_FileName, context.name)
              .withRole(Attachment_Role_FieldDimensions, dimensionalId[0])
              .withVarRoles(Attachment_VarRoles_Dimensions, dimensionalId.slice(1))
              .getObjectOrNull()) {
              setConfirmationUploadModalContext(context);
            } else {
              doUploadAttachment(context);
            }
          }}
          inputRef={inputRef}
        />
      )}
    </>
  );
};

export default AttachmentFieldRenderer;
