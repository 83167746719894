import type { FunctionComponent } from 'react';
import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { Field } from 'yooi-modules/modules/conceptModule/ids';
import type { TimelineViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { joinObjects } from 'yooi-utils';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import type { LineEditionOption } from '../../fields/FieldEditionOptionType';
import { EditionOptionTypes } from '../../fields/FieldEditionOptionType';
import type { UpdateViewDefinition } from '../../fields/viewsField/ViewsFieldDefinitionOptions';
import {
  getChipOptions, getFieldChipOrUnknown,
  listColorFieldOptions,
  listDependenciesFieldOptions,
  listGroupByFieldOptions,
  listProgressFieldOptions,
  listTimelineFieldOptions,
} from '../../modelTypeUtils';
import { isDefaultConfigError } from '../common/defaultConfiguration/defaultConfigurationFeatureDefinition';
import { getDefaultConfigEditionOption } from '../common/defaultConfiguration/viewWithDefaultConfigurationUtils';
import { getViewConceptPathOptions } from '../common/viewUtils';
import type { TimelineViewResolvedDefinition } from './timelineViewHandler';

interface TimelineViewDefinitionOptionsProps {
  viewDimensions: ViewDimension[],
  viewDefinition: TimelineViewResolvedDefinition,
  updateViewDefinition: UpdateViewDefinition<TimelineViewStoredDefinition>,
  readOnly: boolean,
  parameterDefinitions: SingleParameterDefinition[],
}

const TimelineViewDefinitionOptions: FunctionComponent<TimelineViewDefinitionOptionsProps> = ({
  viewDimensions,
  viewDefinition,
  updateViewDefinition,
  readOnly,
  parameterDefinitions,
}) => {
  const store = useStore();

  const getEditionOptions = (conceptDefinitionId: string): LineEditionOption[] => {
    const editionOptions: LineEditionOption[] = [];
    editionOptions.push(getDefaultConfigEditionOption(viewDefinition, readOnly, updateViewDefinition));
    editionOptions.push(
      {
        key: 'readOnly',
        title: i18n`Read only`,
        padded: true,
        type: EditionOptionTypes.checkbox,
        props: {
          checked: viewDefinition.readOnly ?? false,
          disabled: readOnly,
          onChange: (value) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { readOnly: value }))),
        },
      }
    );
    const { defaultConfigOptions } = viewDefinition;
    const isCustomView = isDefaultConfigError(defaultConfigOptions) || !defaultConfigOptions.checked;
    if (isCustomView) {
      editionOptions.push(
        {
          key: 'timelineField',
          title: i18n`Default field for timeline`,
          type: EditionOptionTypes.select,
          props: {
            clearable: true,
            placeholder: i18n`Select field`,
            selectedOption:
              viewDefinition.defaultTimelineFieldId ? getFieldChipOrUnknown(store, conceptDefinitionId, viewDefinition.defaultTimelineFieldId) : undefined,
            computeOptions: () => listTimelineFieldOptions(store, conceptDefinitionId),
            readOnly,
            onChange: (value) => {
              updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { defaultTimelineFieldId: value?.id as string | undefined })));
            },
          },
        }
      );
      editionOptions.push(
        {
          key: 'progressField',
          title: i18n`Default field for progress`,
          type: EditionOptionTypes.select,
          props: {
            clearable: true,
            placeholder: i18n`Select field`,
            selectedOption:
              viewDefinition.defaultTimelineProgressFieldId ? getFieldChipOrUnknown(store, conceptDefinitionId, viewDefinition.defaultTimelineProgressFieldId) : undefined,
            computeOptions: () => listProgressFieldOptions(store, conceptDefinitionId),
            readOnly,
            onChange: (value) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { defaultTimelineProgressFieldId: value?.id as string | undefined }))),
          },
        }
      );
      editionOptions.push(
        {
          key: 'dependencies',
          title: i18n`Default field for dependency`,
          type: EditionOptionTypes.select,
          props: {
            clearable: true,
            placeholder: i18n`Select field`,
            selectedOption:
              viewDefinition.defaultTimelineDependencyFieldId ? getFieldChipOrUnknown(store, conceptDefinitionId, viewDefinition.defaultTimelineDependencyFieldId) : undefined,
            computeOptions: () => listDependenciesFieldOptions(store, conceptDefinitionId),
            readOnly,
            onChange: (value) => updateViewDefinition(
              (oldViewDefinition) => joinObjects(oldViewDefinition, { defaultTimelineDependencyFieldId: value?.id as string | undefined })
            ),
          },
        }
      );

      let selectedOption;
      if (viewDefinition.defaultTimelineGroupByFieldId !== undefined) {
        const option = store.getObjectOrNull(viewDefinition.defaultTimelineGroupByFieldId);
        if (isInstanceOf(option, Field)) {
          selectedOption = getFieldChipOrUnknown(store, conceptDefinitionId, viewDefinition.defaultTimelineGroupByFieldId);
        } else if (option !== null) {
          selectedOption = getChipOptions(store, viewDefinition.defaultTimelineGroupByFieldId);
        }
      }

      editionOptions.push(
        {
          key: 'groupByField',
          title: i18n`Default field for group by`,
          type: EditionOptionTypes.select,
          props: {
            clearable: true,
            placeholder: i18n`Select field`,
            selectedOption,
            computeOptions: () => listGroupByFieldOptions(store, conceptDefinitionId),
            readOnly,
            onChange: (value) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { defaultTimelineGroupByFieldId: value?.id as string | undefined }))),
          },
        }
      );
      editionOptions.push(
        {
          key: 'colorByField',
          title: i18n`Default field for color by`,
          type: EditionOptionTypes.select,
          props: {
            clearable: true,
            placeholder: i18n`Select field`,
            selectedOption:
              viewDefinition.defaultTimelineColorFieldId ? getFieldChipOrUnknown(store, conceptDefinitionId, viewDefinition.defaultTimelineColorFieldId) : undefined,
            computeOptions: () => listColorFieldOptions(store, conceptDefinitionId),
            readOnly,
            onChange: (value) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { defaultTimelineColorFieldId: value?.id as string | undefined }))),
          },
        }
      );
    }
    return editionOptions;
  };
  return getViewConceptPathOptions(store, parameterDefinitions, viewDimensions, getEditionOptions, true);
};

export default TimelineViewDefinitionOptions;
