import { DateTime } from 'luxon';
import type { DateRange, StoredDateObject } from './dateUtils';
import { DateStorageTypeKeys, periodicities, PeriodicityType } from './dateUtils';

const parseStoredDate = (date: string): Date => new Date(Number.parseInt(date, 10));

export const computeEffectiveValueFrom = (
  value: DateRange | undefined,
  constraintValue: number | undefined,
  period: PeriodicityType
): StoredDateObject | undefined => {
  const { type: fromType, value: fromValue } = value?.from ?? {};
  let effectiveValueFrom: StoredDateObject | undefined = value?.from ? { type: fromType, value: fromValue } : undefined;

  if (fromType === DateStorageTypeKeys.constraint) {
    if (constraintValue && effectiveValueFrom) {
      if (constraintValue === periodicities[period].getStartOfPeriod(new Date(constraintValue)).valueOf()) { // Constraint is a valid start of period
        effectiveValueFrom.value = periodicities[period].getPreviousDateInAmountOfPeriod(new Date(constraintValue), -1 * (fromValue ?? 0)).getTime();
      } else {
        effectiveValueFrom.value = periodicities[period].getPreviousDateInAmountOfPeriod(new Date(constraintValue), -1 * ((fromValue ?? 0) + 1)).getTime();
      }
    } else {
      effectiveValueFrom = undefined;
    }
  }
  return effectiveValueFrom;
};

export const isEqualDateRangeValue = (
  fromValue: { period?: string, from?: StoredDateObject, to?: StoredDateObject },
  toValue: { period?: string, from?: StoredDateObject, to?: StoredDateObject }
): boolean => (
  fromValue?.from === toValue?.from
  && fromValue?.to === toValue?.to
  && fromValue?.period === toValue?.period
);

const MAX_GAP_IN_DATES = 8640000000000000;
const MAX_DATE = DateTime.fromMillis(MAX_GAP_IN_DATES);
const MIN_DATE = DateTime.fromMillis(-MAX_GAP_IN_DATES);

interface EffectiveRangeType {
  from?: Date,
  to?: Date,
  error?: string,
}

// This function return the included effective period from dates
// Ex: If in backend dateFrom: 01-09-2020, dateTo: 01-10-2020, period: month it returns from 01-09-2020, to: 30-09-2020
export const computeEffectiveRangeForPeriodAndDates = (
  period: string | undefined,
  dateFrom: StoredDateObject | number | undefined,
  dateTo?: StoredDateObject | number | undefined
): EffectiveRangeType => {
  let dateFromObject: StoredDateObject = { type: undefined, value: undefined };
  if (dateFrom) {
    const { type } = dateFrom as StoredDateObject;
    if (type) {
      dateFromObject = dateFrom as StoredDateObject;
    } else {
      dateFromObject = { type: DateStorageTypeKeys.date, value: dateFrom as number };
    }
  }
  const { type: dateFromType, value: dateFromValue } = dateFromObject;

  let dateToObject: StoredDateObject = { type: undefined, value: undefined };
  if (dateTo) {
    const { type } = dateTo as StoredDateObject;
    if (type) {
      dateToObject = dateTo as StoredDateObject;
    } else {
      dateToObject = { type: DateStorageTypeKeys.date, value: dateTo as number };
    }
  }
  const { type: dateToType, value: dateToValue } = dateToObject;

  const duration = dateToType === DateStorageTypeKeys.duration ? dateToValue : undefined;
  // Handle old storage behavior with ??
  let from: DateTime | undefined;
  if (dateFromType && [DateStorageTypeKeys.last, DateStorageTypeKeys.next].includes(dateFromType)) {
    const multiplier = dateFromType === DateStorageTypeKeys.last ? 1 : -1;
    const date = dateFromValue != null ? periodicities[period as PeriodicityType | undefined ?? PeriodicityType.day]
      .getPreviousDateInAmountOfPeriod(new Date(), multiplier * dateFromValue) : undefined;
    from = date ? DateTime.fromJSDate(date) : undefined;
  } else {
    from = (dateFromValue) ? DateTime.fromJSDate(parseStoredDate((dateFromValue ?? dateFrom).toString())) : undefined;
  }

  let to: DateTime | undefined;
  if (dateToType && [DateStorageTypeKeys.last, DateStorageTypeKeys.next].includes(dateToType)) {
    const multiplier = dateToType === DateStorageTypeKeys.last ? -1 : 1;
    const date = dateToValue !== undefined ? periodicities[period as PeriodicityType | undefined ?? PeriodicityType.day]
      .getNextDateInAmountOfPeriod(new Date(), multiplier * dateToValue) : undefined;
    to = date ? DateTime.fromJSDate(date) : undefined;
  } else {
    to = (dateToValue) && !duration ? DateTime.fromJSDate(parseStoredDate((dateToValue ?? dateTo).toString())) : undefined;
  }

  if (from && to && duration == null) {
    let error;
    if (to <= from) {
      error = 'Period start should not be after period end.';
    }
    const includedDateTo = to.minus({ millisecond: 1 });
    return {
      from: from ? from.toJSDate() : undefined,
      to: includedDateTo ? includedDateTo.toJSDate() : undefined,
      error,
    };
  } else if (from) {
    if (duration != null) {
      if (duration === 0) {
        return {
          from: from.toJSDate(),
          to: from.toJSDate(),
          error: 'Duration cannot be 0',
        };
      }
      let error;
      const computedToDate = periodicities[period as PeriodicityType | undefined ?? PeriodicityType.day]
        .getNextDateInAmountOfPeriod(from.toJSDate(), duration > 0 ? duration - 1 : duration);
      let computedTo = computedToDate ? DateTime.fromJSDate(computedToDate) : undefined;
      // Error in parsing, Date is too far
      if (computedTo?.isValid === false) {
        error = 'You have exited boundaries';
        if (duration > 0) {
          computedTo = MAX_DATE;
        } else {
          computedTo = MIN_DATE;
        }
      }
      if (computedTo && computedTo <= from) {
        error = 'Period start should not be after period end.';
      }
      return {
        from: from.toJSDate(),
        to: computedTo?.toJSDate(),
        error,
      };
    } else {
      return {
        from: from.toJSDate(),
        to: from.toJSDate(),
      };
    }
  } else if (to) {
    return {
      from: to.minus({ millisecond: 1 }).toJSDate(),
      to: to.minus({ millisecond: 1 }).toJSDate(),
    };
  } else {
    return {};
  }
};

export const isEffectiveDateIncluded = (
  filterPeriodicity: string | undefined,
  filterStart: StoredDateObject | number | undefined,
  filterEnd: StoredDateObject | number | undefined,
  elementStart: Date | undefined,
  elementEnd: Date | undefined,
  elementError: string | undefined
): boolean => {
  const {
    from: effectiveFilterStart,
    to: effectiveFilterEnd, error: filterError,
  } = computeEffectiveRangeForPeriodAndDates(filterPeriodicity ?? PeriodicityType.day, filterStart, filterEnd);
  if (elementError) {
    return false;
  } else if (filterError) {
    return true;
  }
  const isOverlappingFirstCondition = !filterStart || ((elementEnd && effectiveFilterStart) && elementEnd >= effectiveFilterStart);
  const isOverlappingSecondCondition = !filterEnd || ((elementStart && effectiveFilterEnd) && elementStart <= effectiveFilterEnd);
  return Boolean(isOverlappingFirstCondition && isOverlappingSecondCondition);
};
