import type { ConceptStoreObject, DimensionsMapping, FieldStoreObject, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import {
  createValuePathResolver,
  dimensionsMappingToParametersMapping,
  getInstanceLabel,
  getPathLastFieldInformation,
  getPathReturnedConceptDefinitionId,
  getTimeseriesFieldUtilsHandler,
  isSingleFieldResolution,
} from 'yooi-modules/modules/conceptModule';
import {
  Field_Formula,
  Field_IntegrationOnly,
  TimeseriesNumberField as TimeseriesNumberFieldId,
  TimeseriesTextField as TimeseriesTextFieldId,
} from 'yooi-modules/modules/conceptModule/ids';
import type { ViewDimension, ViewSeries } from 'yooi-modules/modules/dashboardModule';
import { DimensionDisplayAxis, ViewType } from 'yooi-modules/modules/dashboardModule';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareNumber, filterNullOrUndefined, getFormattedTextDateByPeriod, joinObjects, periodicities } from 'yooi-utils';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { formatOrUndef } from '../../../../utils/stringUtils';
import { formatErrorForUser } from '../../errorUtils';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { getViewFilterFunction } from '../../listFilterFunctions';
import { getConceptDefinitionNameOrEntity } from '../../modelTypeUtils';
import { getDimensionCompositionFromSeries, getSeriesLabel } from '../common/series/viewWithSeriesFeatureUtils';
import { getTemporalRange } from '../common/temporal/viewWithTemporalFeatureUtils';
import { computeDimension, DataResolutionError, getDimensionCompositions, getDimensionLabel } from '../data/dataResolution';
import type { ViewResolutionError } from '../viewResolutionUtils';
import { getProjectionErrorForAxis } from './TimeseriesTableViewDefinitionOptions';
import type { TimeseriesTableViewResolvedDefinition } from './timeseriesTableViewHandler';

export interface TimeseriesTableLine {
  id: string,
  key: string,
  time?: number,
  fieldId?: string,
  isComputedOrIntegrationOnly?: boolean,
  lineDimensionMapping: DimensionsMapping,
}

interface TimeseriesTableInstanceColumn {
  type: 'instance',
  id: string,
  key: string,
  label: string,
  asChip: boolean,
  getInstanceLabel: (line: TimeseriesTableLine) => string,
  getInstanceId: (line: TimeseriesTableLine) => string | undefined,
}

interface TimeseriesTableSeriesColumn {
  type: 'series',
  id: string,
  key: string,
  label: string,
  getSeriesLabel: (line: TimeseriesTableLine) => string,
}

interface TimeseriesTableTimeColumn {
  type: 'time',
  id: string,
  key: string,
  label: string,
  getTime: (line: TimeseriesTableLine) => number,
}

interface TimeseriesTableFieldColumn {
  type: 'field',
  id: string,
  key: string,
  label: string,
  fieldId?: string,
  isComputedOrIntegrationOnly?: boolean,
  time?: number,
  width?: number | string,
  groups?: { id: string, label: string, tooltip: string, isTime: boolean }[],
  getDimensionsMapping: (line: TimeseriesTableLine) => DimensionsMapping,
}

type TimeseriesTableColumn = TimeseriesTableInstanceColumn | TimeseriesTableSeriesColumn | TimeseriesTableTimeColumn | TimeseriesTableFieldColumn;

export interface TimeseriesTableViewResolution {
  type: ViewType.TimeseriesTable,
  loading: boolean,
  isComputedOrIntegrationOnly: boolean,
  requestedTemporalRange: { from: number, to: number },
  filterFunction: (parameterMapping: ParametersMapping) => boolean,
  lines: TimeseriesTableLine[],
  columns: TimeseriesTableColumn[],
  addedTimePoints: number[],
  timePointsWithValue: Set<number>,
  withoutHeaderLine: boolean,
  getNewTimeInfo: (time?: number) => { id: string, label?: string, groups?: { id: string, label?: string, isTime: boolean }[] }[],
}

export const isColumnTimeseriesTableFieldColumn = (column: TimeseriesTableColumn): column is TimeseriesTableFieldColumn => column.type === 'field';

const computeId = (dimensionsMapping: DimensionsMapping, time?: number) => {
  const mappingId = Object.values(dimensionsMapping).join('_');
  if (time !== undefined) {
    return mappingId.length > 0 ? `${mappingId}_${time}` : time.toString();
  }
  return mappingId;
};

const getColumnLabel = (
  store: FrontObjectStore,
  columnDimensionsMapping: DimensionsMapping,
  viewDefinition: TimeseriesTableViewResolvedDefinition,
  viewDimensions: ViewDimension[],
  series: ViewSeries[],
  dimensionsMapping: ParametersMapping
): string => Object.entries(columnDimensionsMapping).map(([prop, value]) => {
  const dimensionDisplay = viewDefinition.getDimensionsDisplay(viewDimensions);
  if (viewDimensions.length !== 1 || series.length > 1 || (
    viewDefinition.timeAxis.axis === DimensionDisplayAxis.y
    && dimensionDisplay.every((display) => display.axis === DimensionDisplayAxis.y))
  ) {
    if (prop === 'serieId') {
      const seriesIndex = series.findIndex((s) => s.id === value);
      if (seriesIndex >= 0) {
        return getSeriesLabel(store, series[seriesIndex].label, seriesIndex, series[seriesIndex].path, viewDimensions, []);
      }
    }
  } else if (value) {
    const instanceId = dimensionsMapping[value]?.id ?? value;
    const instance = instanceId ? store.getObjectOrNull(instanceId) : undefined;
    return formatOrUndef(instance ? getInstanceLabel(store, instance) : undefined);
  }
  return undefined;
}).filter(filterNullOrUndefined).join(' x ');

const validateTimePoint = (
  time: number,
  granularity: TimeseriesTableViewResolvedDefinition['granularity']
): boolean => !granularity.strict || periodicities[granularity.periodicity].getStartOfPeriod(new Date(time))
  .getTime() === time;

export const resolveTimeseriesTableView = (
  store: FrontObjectStore,
  viewDimensions: ViewDimension[],
  viewDefinition: TimeseriesTableViewResolvedDefinition,
  parametersMapping: ParametersMapping,
  filterConfiguration: FilterConfiguration | undefined,
  addedTimePoint: number[]
): TimeseriesTableViewResolution | ViewResolutionError => {
  const { series: inputSeries } = viewDefinition;

  const series = inputSeries.filter((s) => {
    const pathFieldId = getPathLastFieldInformation(s.path)?.fieldId;
    return !!pathFieldId;
  });

  if (series.length === 0) {
    return { type: 'error', error: i18n`Missing series` };
  }

  const requestedTemporalRange = getTemporalRange(viewDefinition);

  if (requestedTemporalRange === undefined) {
    return { type: 'error', error: i18n`Missing date range` };
  }

  const xProjectionError = getProjectionErrorForAxis(viewDefinition, viewDimensions, DimensionDisplayAxis.x);
  if (xProjectionError) {
    return { type: 'error', error: xProjectionError };
  }
  const yProjectionError = getProjectionErrorForAxis(viewDefinition, viewDimensions, DimensionDisplayAxis.y);
  if (yProjectionError) {
    return { type: 'error', error: yProjectionError };
  }

  const temporalRange = addedTimePoint.length > 0
    ? {
      from: addedTimePoint[0] < requestedTemporalRange.from ? addedTimePoint[0] : requestedTemporalRange.from,
      to: addedTimePoint[addedTimePoint.length - 1] > requestedTemporalRange.to ? addedTimePoint[addedTimePoint.length - 1] : requestedTemporalRange.to,
    } : requestedTemporalRange;

  const timePointsWithValue = new Set<number>();

  const { mapReduceHandler: computeDimensionHandler } = computeDimension(store, parametersMapping, viewDimensions);

  const dimensionCompositions = getDimensionCompositions(computeDimensionHandler, viewDimensions, viewDefinition.getDimensionsDisplay(viewDimensions));
  if (dimensionCompositions instanceof DataResolutionError) {
    return { type: 'error', error: formatErrorForUser(store, dimensionCompositions) };
  }
  let { xDimensionCompositions, yDimensionCompositions } = dimensionCompositions;

  const viewFilterFunction = getViewFilterFunction(
    store,
    viewDimensions.map((dim, index) => {
      const conceptDefinitionId = getPathReturnedConceptDefinitionId(store, dim.path);
      return conceptDefinitionId ? {
        id: dim.id,
        typeId: conceptDefinitionId,
        label: getDimensionLabel(store, dim.label, index, dim.path),
      } : undefined;
    }).filter(filterNullOrUndefined),
    filterConfiguration,
    parametersMapping
  );
  if (viewFilterFunction) {
    xDimensionCompositions = xDimensionCompositions
      .filter((xComposition) => (yDimensionCompositions.length > 0 ? yDimensionCompositions : [{}])
        .some((yComposition) => viewFilterFunction(dimensionsMappingToParametersMapping(joinObjects(xComposition, yComposition)))));
  }

  if (viewDefinition.seriesAxis.axis === DimensionDisplayAxis.x) {
    xDimensionCompositions = getDimensionCompositionFromSeries(xDimensionCompositions, series);
  } else {
    yDimensionCompositions = getDimensionCompositionFromSeries(yDimensionCompositions, series);
  }

  if (![
    viewDefinition.timeAxis.axis,
    viewDefinition.seriesAxis.axis,
    ...viewDefinition.getDimensionsDisplay(viewDimensions).map(({ axis }) => axis),
  ].includes(DimensionDisplayAxis.x)) {
    return { type: 'error', error: i18n`You need to have at least one projection on columns` };
  }

  if (![
    viewDefinition.timeAxis.axis,
    viewDefinition.seriesAxis.axis,
    ...viewDefinition.getDimensionsDisplay(viewDimensions).map(({ axis }) => axis),
  ].includes(DimensionDisplayAxis.y)) {
    return { type: 'error', error: i18n`You need to have at least one projection on rows` };
  }

  const computeInstanceIds = (lineOrColumnDimensionMapping: DimensionsMapping, axis: DimensionDisplayAxis): (string | undefined)[] => viewDimensions
    .flatMap((dim) => {
      const dimensionDisplay = viewDefinition.getDimensionDisplay(dim);
      if (dimensionDisplay.axis === axis && dimensionDisplay.display === 'show') {
        const value = lineOrColumnDimensionMapping[dim.id];
        const mappedValue = value && parametersMapping[value]?.id;
        if (!(dim.id in lineOrColumnDimensionMapping)) {
          return [];
        } else if (value === undefined) {
          return [undefined];
        } else if (mappedValue !== undefined) {
          return [mappedValue];
        } else {
          return [value];
        }
      }
      return [];
    });

  const getInstanceGroup = (instanceId: string | undefined): { id: string, label: string, tooltip: string, isTime: boolean } => {
    const instance = instanceId ? store.getObjectOrNull<ConceptStoreObject>(instanceId) : undefined;
    const label = formatOrUndef(instance ? getInstanceLabel(store, instance) : undefined);
    const typeLabel = instance ? getConceptDefinitionNameOrEntity(store, instance[Instance_Of]) : undefined;
    return { id: instanceId ?? 'undefined', label, tooltip: `${label} (${formatOrUndef(typeLabel)})`, isTime: false };
  };
  const isLastInstanceDisplayedAsGroup = viewDefinition.seriesAxis.axis === DimensionDisplayAxis.x && (viewDefinition.seriesAxis.display === 'show' || viewDefinition.series.length > 1);
  const computeColumn = (xDimensionMapping: DimensionsMapping, time?: number): TimeseriesTableFieldColumn => {
    const columnId = computeId(xDimensionMapping, time);
    const columnSeriesIndex = series.findIndex(({ id }) => id === xDimensionMapping.serieId);
    const columnSeries = columnSeriesIndex >= 0 ? series.at(columnSeriesIndex) : undefined;
    const { fieldId = undefined } = columnSeries ? getPathLastFieldInformation(columnSeries.path) ?? {} : {};
    let groups: TimeseriesTableFieldColumn['groups'] | undefined;
    if (time !== undefined) {
      const label = getFormattedTextDateByPeriod(new Date(time), viewDefinition.granularity.periodicity);
      groups = [{ id: time.toString(), label, tooltip: label, isTime: true }];
    }
    const instanceIds = computeInstanceIds(xDimensionMapping, DimensionDisplayAxis.x);
    for (let i = 0; instanceIds !== undefined && i < instanceIds.length - (isLastInstanceDisplayedAsGroup ? 0 : 1); i += 1) {
      const instanceId = instanceIds?.[i];
      const instanceGroup = getInstanceGroup(instanceId);
      if (groups) {
        groups.push(instanceGroup);
      } else {
        groups = [instanceGroup];
      }
    }
    let label: string | undefined;
    if (isLastInstanceDisplayedAsGroup) {
      if (columnSeries) {
        label = getSeriesLabel(store, columnSeries.label, columnSeriesIndex, columnSeries.path, viewDimensions, []);
      }
    } else if (instanceIds) {
      const lastInstanceId = instanceIds.at(-1);
      const lastInstance = lastInstanceId !== undefined ? store.getObjectOrNull(lastInstanceId) : undefined;
      if (lastInstance) {
        label = getInstanceLabel(store, lastInstance);
      }
    }

    let width: string | number | undefined;
    if (columnSeries?.displayOptions?.width !== undefined && columnSeries.displayOptions.width.type === 'percent') {
      width = columnSeries.displayOptions.width.value;
    } else if (columnSeries?.displayOptions?.width !== undefined && columnSeries.displayOptions.width.type === 'rem') {
      width = `${columnSeries.displayOptions.width.value}rem`;
    }

    const field = fieldId ? store.getObjectOrNull<FieldStoreObject>(fieldId) : null;

    return {
      type: 'field',
      id: columnId,
      key: columnId,
      time,
      groups,
      fieldId,
      width,
      isComputedOrIntegrationOnly: Boolean(field?.[Field_Formula]) || Boolean(field?.[Field_IntegrationOnly]),
      label: formatOrUndef(label),
      getDimensionsMapping: (line: TimeseriesTableLine): DimensionsMapping => {
        const relatedSeries = columnSeries || series.find(({ id }) => id === line.lineDimensionMapping.serieId);
        if (!relatedSeries) {
          return {};
        }
        const relatedParametersMapping = Object.fromEntries(Object.entries(
          joinObjects(dimensionsMappingToParametersMapping(line.lineDimensionMapping), dimensionsMappingToParametersMapping(xDimensionMapping), parametersMapping)
        ).filter(([k]) => k !== 'serieId'));
        const fieldResolution = relatedSeries ? createValuePathResolver(store, relatedParametersMapping).resolvePathField(relatedSeries.path) : undefined;
        if (isSingleFieldResolution(fieldResolution)) {
          return fieldResolution.dimensionsMapping ?? {};
        }
        return {};
      },
    };
  };

  const computeLine = (yDimensionMapping: DimensionsMapping, time?: number): TimeseriesTableLine => {
    const lineId = computeId(yDimensionMapping, time);
    const lineSeries = series.find(({ id }) => id === yDimensionMapping.serieId);
    const { fieldId = undefined } = lineSeries ? getPathLastFieldInformation(lineSeries.path) ?? {} : {};
    const field = fieldId ? store.getObjectOrNull<FieldStoreObject>(fieldId) : null;
    return {
      id: lineId,
      key: lineId,
      time,
      fieldId,
      isComputedOrIntegrationOnly: Boolean(field?.[Field_Formula]) || Boolean(field?.[Field_IntegrationOnly]),
      lineDimensionMapping: yDimensionMapping,
    };
  };

  const timePoints: number[] = [];
  let loading = false;
  let isComputedOrIntegrationOnly = true;

  if (viewDefinition.granularity.displayEmpty) {
    let currentDate = periodicities[viewDefinition.granularity.periodicity].getStartOfPeriod(new Date(requestedTemporalRange.from));
    while (currentDate.getTime() <= requestedTemporalRange.to) {
      if (currentDate.getTime() >= requestedTemporalRange.from) {
        timePoints.push(currentDate.getTime());
      }
      // Using getPreviousDateInAmountOfPeriod as getNextDateInAmountOfPeriod return the end of the period
      currentDate = periodicities[viewDefinition.granularity.periodicity].getPreviousDateInAmountOfPeriod(currentDate, -1);
    }
  }

  let i = 0;
  do {
    const yDimensionMapping = yDimensionCompositions.at(i) ?? {};
    const yRelatedSeries = series.find(({ id }) => id === yDimensionMapping.serieId);
    let j = 0;
    do {
      const xDimensionMapping = xDimensionCompositions.at(j) ?? {};
      const xRelatedSeries = series.find(({ id }) => id === xDimensionMapping.serieId);
      const cellParametersMapping = Object.fromEntries(Object.entries(
        joinObjects(dimensionsMappingToParametersMapping(yDimensionMapping), dimensionsMappingToParametersMapping(xDimensionMapping), parametersMapping)
      ).filter(([k]) => k !== 'serieId'));
      const relatedSeries = yRelatedSeries ?? xRelatedSeries;
      const pathFieldResolution = relatedSeries ? createValuePathResolver(store, cellParametersMapping).resolvePathField(relatedSeries.path) : undefined;
      if (isSingleFieldResolution(pathFieldResolution)) {
        const field = store.getObjectOrNull<FieldStoreObject>(pathFieldResolution.fieldId);
        isComputedOrIntegrationOnly = isComputedOrIntegrationOnly && field !== null && (Boolean(field[Field_Formula]) || Boolean(field[Field_IntegrationOnly]));
        if (isInstanceOf(field, TimeseriesNumberFieldId) || (isInstanceOf(field, TimeseriesTextFieldId))) {
          const { value, error } = getTimeseriesFieldUtilsHandler(store, pathFieldResolution.fieldId)
            .getValueResolution(pathFieldResolution.dimensionsMapping ?? {}, undefined, temporalRange);
          if (value !== undefined) {
            for (let k = 0; k < value.length; k += 1) {
              const { time, value: timeValue } = value[k];
              if (timeValue !== undefined && timeValue !== null) {
                if (!timePointsWithValue.has(time)) {
                  timePointsWithValue.add(time);
                }

                if (validateTimePoint(time, viewDefinition.granularity) && !timePoints.includes(time) && requestedTemporalRange.from <= time && requestedTemporalRange.to >= time) {
                  timePoints.push(time);
                }
              }
            }
          } else if (!error) {
            loading = true;
          }
        } else {
          return { type: 'error', error: i18n`Invalid series` };
        }
      }
      j += 1;
    } while (j < xDimensionCompositions.length);
    i += 1;
  } while (i < yDimensionCompositions.length);

  timePoints.sort(compareNumber);

  const columns: TimeseriesTableColumn[] = [];
  const lines: TimeseriesTableLine[] = [];
  const lineDimension = viewDefinition.getDimensionsDisplay(viewDimensions).filter(({ axis }) => axis === DimensionDisplayAxis.y);

  if (viewDefinition.timeAxis.axis === DimensionDisplayAxis.y) {
    columns.push({
      type: 'time',
      id: 'time',
      key: 'time',
      label: i18n`Time`,
      getTime: (line) => line.time as number,
    });
  }

  for (i = 0; i < lineDimension.length; i += 1) {
    const dimensionDisplay = lineDimension[i];
    const dimensionIndex = viewDimensions.findIndex(({ id }) => id === dimensionDisplay.id);
    const dimension = viewDimensions[dimensionIndex];
    const getInstanceId = (line: TimeseriesTableLine) => {
      const value = line.lineDimensionMapping[dimension.id];
      if (value !== undefined && parametersMapping[value] !== undefined) {
        return parametersMapping[value]?.id;
      }
      return value;
    };
    if (dimension && dimensionDisplay.display === 'show') {
      columns.push({
        type: 'instance',
        id: dimension.id,
        key: dimension.id,
        label: getDimensionLabel(store, dimension.label, dimensionIndex, dimension.path),
        asChip: dimensionDisplay.withLegend === undefined || dimensionDisplay.withLegend,
        getInstanceLabel: (line) => {
          const instanceId = getInstanceId(line);
          const instance = instanceId ? store.getObjectOrNull(instanceId) : undefined;
          return formatOrUndef(instance ? getInstanceLabel(store, instance) : undefined);
        },
        getInstanceId,
      });
    }
  }

  if (
    viewDefinition.seriesAxis.axis === DimensionDisplayAxis.y && viewDefinition.seriesAxis.display === 'show') {
    columns.push({
      type: 'series',
      id: 'series',
      key: 'series',
      label: i18n`Series`,
      getSeriesLabel: (line) => {
        const seriesIndex = series.findIndex((s) => s.id === line.lineDimensionMapping.serieId);
        const lineSeries = series[seriesIndex];
        return getSeriesLabel(store, lineSeries.label, seriesIndex, lineSeries.path, viewDimensions, []);
      },
    });
  }

  for (i = 0; i < timePoints.length + addedTimePoint.length; i += 1) {
    const time = timePoints[i] ?? addedTimePoint[i - timePoints.length];
    if (i >= timePoints.length || !addedTimePoint.includes(time)) {
      let j = 0;
      if (viewDefinition.timeAxis.axis === DimensionDisplayAxis.x) {
        do {
          columns.push(computeColumn(xDimensionCompositions.at(j) ?? {}, time));
          j += 1;
        } while (j < xDimensionCompositions.length);
      } else {
        do {
          lines.push(computeLine(yDimensionCompositions.at(j) ?? {}, time));
          j += 1;
        } while (j < yDimensionCompositions.length);
      }
    }
  }

  if (viewDefinition.timeAxis.axis === DimensionDisplayAxis.x) {
    for (let j = 0; j < yDimensionCompositions.length; j += 1) {
      lines.push(computeLine(yDimensionCompositions[j]));
    }
  } else {
    for (let j = 0; j < xDimensionCompositions.length; j += 1) {
      columns.push(computeColumn(xDimensionCompositions[j]));
    }
  }

  return {
    type: ViewType.TimeseriesTable,
    loading,
    columns,
    lines,
    addedTimePoints: addedTimePoint,
    timePointsWithValue,
    requestedTemporalRange,
    filterFunction: (yMapping) => !viewFilterFunction
      || (xDimensionCompositions.length > 0 ? xDimensionCompositions : [{}])
        .some((xComposition) => viewFilterFunction(joinObjects(dimensionsMappingToParametersMapping(xComposition), yMapping))),
    withoutHeaderLine: (viewDefinition.seriesAxis.axis === DimensionDisplayAxis.y || viewDefinition.seriesAxis.display === 'hide')
      && (viewDefinition.getDimensionsDisplay(viewDimensions).every(({ axis, display }) => axis === DimensionDisplayAxis.y || display === 'hide')),
    isComputedOrIntegrationOnly,
    getNewTimeInfo: (time: number | undefined): { id: string, label?: string, groups?: { id: string, label?: string, isTime: boolean }[] }[] => {
      if (viewDefinition.timeAxis.axis === DimensionDisplayAxis.y) {
        return yDimensionCompositions.length > 0 ? yDimensionCompositions.map((mapping) => ({
          id: computeId(mapping, time),
        })) : [{ id: time?.toString() ?? 'newLine' }];
      } else {
        return xDimensionCompositions.length > 0 ? xDimensionCompositions
          .map((mapping) => ({
            id: computeId(mapping, time),
            label: getColumnLabel(store, mapping, viewDefinition, viewDimensions, series, parametersMapping),
            groups: [
              { id: 'time', label: undefined, isTime: true },
              ...computeInstanceIds(mapping, DimensionDisplayAxis.x)
                .filter((_, index, self) => isLastInstanceDisplayedAsGroup || index !== self.length - 1)
                .map((instanceId) => getInstanceGroup(instanceId))],
          })) : [{ id: time?.toString() ?? 'newLine', groups: [{ id: 'time', label: undefined, isTime: true }], label: formatOrUndef(undefined) }];
      }
    },
  };
};
