import type { CoverHeight } from 'yooi-utils';
import { newError } from 'yooi-utils';
import { asImport, asLocal, CommonAsType } from '../../common/fields/commonPropertyType';
import type { GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import { registerField } from '../module';
import type { CardField } from './types';

export enum CardFieldActionTypes {
  CreateInstance = 'CreateInstance',
  OpenUrl = 'OpenUrl',
}

interface Button<Action> {
  id: string,
  label?: string,
  variant?: 'primary' | 'secondary' | 'tertiary',
  action: Action,
}

interface CreateInstanceButton extends Button<CardFieldActionTypes.CreateInstance> {
  action: CardFieldActionTypes.CreateInstance,
  data?: string,
  defaultValues?: Record<string, unknown>,
}

interface OpenUrlButton extends Button<CardFieldActionTypes.OpenUrl> {
  action: CardFieldActionTypes.OpenUrl,
  data?: string,
}

export type CardFieldButton = Button<undefined> | CreateInstanceButton | OpenUrlButton;

export type ImageHeight = CoverHeight | number;
type CardFieldHandler = GetDslFieldHandler<CardField, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined>;
export const cardFieldHandler: CardFieldHandler = registerField({
  model: {
    label: 'CardField',
    title: 'Card',
    properties: [
      { label: 'Image', as: CommonAsType.string },
      { label: 'ImageMode', as: asImport('CoverMode', 'yooi-utils', false, true) },
      { label: 'ImagePositionX', as: asImport('CoverPositionX', 'yooi-utils', false, true) },
      { label: 'ImagePositionY', as: asImport('CoverPositionY', 'yooi-utils', false, true) },
      { label: 'ImageHeight', as: asLocal('ImageHeight') },
      { label: 'Subtitle', as: CommonAsType.string },
      { label: 'Text', as: CommonAsType.string },
      { label: 'Button', as: asLocal('CardFieldButton', true) },
      { label: 'ButtonPositionX', as: asImport('CoverPositionX', 'yooi-utils', false, true) },
      { label: 'ButtonPositionY', as: asImport('CoverPositionY', 'yooi-utils', false, true) },
    ],
  },
  handler: () => ({
    describe: () => ({ hasData: false }),
    restApi: {
      returnTypeSchema: {},
      formatValue: () => undefined,
    },
    getStoreValue: () => undefined,
    getValueWithoutFormula: () => undefined,
    getValueResolution: () => ({ value: undefined, getDisplayValue: () => undefined, isComputed: false, isTimeseries: false }),
    updateValue: () => {
      throw newError('updateValue not supported');
    },
    resolvePathStepConfiguration: () => ({ hasData: false }),
    isEmpty: () => false,
    isSaneValue: () => ({ isValid: true }),
  }),
});
