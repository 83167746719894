import { equals } from 'ramda';
import type {
  ConceptDefinitionStoreObject,
  EmbeddingFieldStoreObject,
  FieldStep,
  FieldStoreObject,
  FilterCondition,
  Filters,
  FilterStep,
  PathStep,
} from 'yooi-modules/modules/conceptModule';
import { isDimensionStep, isFieldStep, isFilterNode, isFilterStep, isFilterValuePath, isFilterValueRaw, isMappingStep } from 'yooi-modules/modules/conceptModule';
import { ConceptDefinition_ChipBackgroundColor } from 'yooi-modules/modules/conceptModule/ids';
import type { ObjectStoreReadOnly } from 'yooi-store';
import { filterNullOrUndefined } from 'yooi-utils';
import type { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { formatOrUndef } from '../../../../utils/stringUtils';
import { getFieldLabel } from '../../fieldUtils';
import { countValidFilters } from '../../filter/filterUtils';
import type { Option } from '../../modelTypeUtils';

export const getAllInstancesChipOption = (store: ObjectStoreReadOnly, conceptDefinitionId: string): Option => ({
  id: 'allInstances',
  label: i18n`All Instances`,
  color: store.getObjectOrNull<ConceptDefinitionStoreObject>(conceptDefinitionId)?.[ConceptDefinition_ChipBackgroundColor],
  borderStyle: 'dashed',
});

export const GLOBAL_DIMENSION_CHIP_OPTION: Option = { id: 'globalDimension', label: 'Global' };

export interface StepOption {
  id: string,
  icon?: IconName | { name: IconName, colorVariant: IconColorVariant } | { name: IconName, color: string },
  label: string,
  tooltip?: string,
  color?: string,
  squareColor?: string,
  to?: string,
  index?: number,
  noDelete?: boolean,
}

export interface PathInputStep {
  displayOverrides?: {
    displayedPathStepIndex?: number,
    label?: string,
    labelSuffix?: string,
    errors?: string[],
  },
  // previous (hidden) and current step (displayed)
  steps: PathStep[],
}

export const pathStepImplementationToPathInputStep = (step: PathStep): PathInputStep => ({ steps: [step] });

const getAllInstanceMergedStepOverrideLabel = (store: FrontObjectStore, filterStep: FilterStep, fieldStep: FieldStep): string | undefined => {
  const filterCount = countValidFilters(store, filterStep.filters);
  const field = store.getObjectOrNull<FieldStoreObject>(fieldStep.fieldId);
  const labelSuffixes: string[] = [];

  if (fieldStep.embeddingFieldId !== undefined) {
    const embeddingField = store.getObjectOrNull<EmbeddingFieldStoreObject>(fieldStep.embeddingFieldId);
    if (embeddingField !== null) {
      labelSuffixes.push(formatOrUndef(getFieldLabel(store, embeddingField)));
    }
  }

  if (fieldStep.workflowSubfieldId !== undefined) {
    const subField = store.getObjectOrNull<FieldStoreObject>(fieldStep.workflowSubfieldId);
    if (subField !== null) {
      labelSuffixes.push(formatOrUndef(getFieldLabel(store, subField)));
    }
  }

  if (filterCount > 0) {
    labelSuffixes.push(i18n`${filterCount} filters`);
  }

  return `${formatOrUndef(field ? getFieldLabel(store, field) : undefined)}${labelSuffixes.length > 0 ? ` (${labelSuffixes.join(', ')})` : ''}`;
};

export const storePathToInputPath = (store: FrontObjectStore, storePath: PathStep[], suggestedBasePaths: { label: string, path: PathStep[] }[] | undefined): PathInputStep[] => {
  const inputStepsPath: PathInputStep[] = [];
  let afterDefaultPathMergedIndex = -1;
  // if path starts with hiddenDefaultPath, this default path must be hidden
  if (suggestedBasePaths !== undefined && suggestedBasePaths.length > 0) {
    const basePath = suggestedBasePaths.find(({ path }) => equals(path, storePath.slice(0, path.length)));
    if (basePath !== undefined) {
      if (storePath.length === basePath.path.length || suggestedBasePaths.length > 1) {
        inputStepsPath.push({ steps: basePath.path, displayOverrides: { label: basePath.label } });
        afterDefaultPathMergedIndex = basePath.path.length;
      } else {
        inputStepsPath.push({ steps: storePath.slice(0, basePath.path.length + 1) });
        afterDefaultPathMergedIndex = basePath.path.length + 1;
      }
    }
  }
  // next steps are displayed completely (displayStep = allSteps)

  let insertIndex = 0;
  storePath.forEach((pathStep, stepIndex, array) => {
    const n1Step = array[stepIndex + 1];
    const n2Step = array[stepIndex + 2];
    if (stepIndex >= insertIndex && pathStep && n1Step && n2Step && isFieldStep(pathStep) && isDimensionStep(n1Step) && isFilterStep(n2Step)) {
      if (afterDefaultPathMergedIndex > 0 && stepIndex + 1 === afterDefaultPathMergedIndex) {
        inputStepsPath[0].steps.push(n1Step, n2Step);
        inputStepsPath[0].displayOverrides = {
          label: getAllInstanceMergedStepOverrideLabel(store, n2Step, pathStep),
          displayedPathStepIndex: stepIndex,
        };
      } else {
        inputStepsPath.push({
          steps: [pathStep, n1Step, n2Step],
          displayOverrides: { label: getAllInstanceMergedStepOverrideLabel(store, n2Step, pathStep), displayedPathStepIndex: 0 },
        });
      }
      insertIndex = stepIndex + 3;
    } else if (stepIndex >= insertIndex && stepIndex >= afterDefaultPathMergedIndex) {
      inputStepsPath.push(pathStepImplementationToPathInputStep(pathStep));
    }
  });
  return inputStepsPath;
};

export const inputPathToStorePath = (inputPath: PathInputStep[]): PathStep[] => {
  const storePath: PathStep[] = [];
  inputPath.forEach((pathInputStep) => {
    storePath.push(...pathInputStep.steps);
  });
  return storePath;
};

export const getNthStep = (pathInputSteps: PathInputStep[], index: number, nth: number): PathStep | undefined => {
  if (index >= pathInputSteps.length) {
    return undefined;
  }
  const stepPath = pathInputSteps.slice(0, index + 1).flatMap((inputStep) => inputStep.steps);
  return stepPath.length > nth ? stepPath[stepPath.length - nth - 1] : undefined;
};

export const getMappingIdsUsedInPath = (path: PathStep[]): string[] => {
  const extractMappingIdsUsedInFilterCondition = (filter: FilterCondition) => {
    const { leftValue, rightValue } = filter;
    if (leftValue) {
      const mappingIds = new Set(getMappingIdsUsedInPath(leftValue));
      if (rightValue && isFilterValuePath(rightValue)) {
        const { path: valuePath } = rightValue;
        getMappingIdsUsedInPath(valuePath).forEach((id) => mappingIds.add(id));
      } else if (rightValue && isFilterValueRaw(rightValue)) {
        if (Array.isArray(rightValue.raw)) {
          rightValue.raw.forEach((value) => mappingIds.add(value));
        } else if (typeof rightValue.raw === 'string') {
          mappingIds.add(rightValue.raw);
        }
      }
      return [...mappingIds];
    }
    return [];
  };

  const extractMappingIdsUsedInFilter = (filters: Filters): string[] => {
    if (isFilterNode(filters)) {
      return filters.children ? [...new Set(filters.children.flatMap((childrenFilters) => extractMappingIdsUsedInFilter(childrenFilters)))] : [];
    } else {
      return extractMappingIdsUsedInFilterCondition(filters);
    }
  };

  const idsUsedInPath = new Set<string>();
  path?.forEach((pathStep) => {
    if (isFieldStep(pathStep) && pathStep.mapping) {
      Object.values(pathStep.mapping)
        .filter(filterNullOrUndefined)
        .forEach((instanceReferenceValue) => idsUsedInPath.add(instanceReferenceValue.id));
    } else if (isMappingStep(pathStep)) {
      idsUsedInPath.add(pathStep.mapping.id);
    } else if (isFilterStep(pathStep) && pathStep.filters) {
      extractMappingIdsUsedInFilter(pathStep.filters).forEach((id) => idsUsedInPath.add(id));
    }
  });

  return [...idsUsedInPath];
};
