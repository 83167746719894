import { ValidationStatus } from 'yooi-store';
import { asLocal, CommonAsType } from '../../common/fields/commonPropertyType';
import { Concept, ConceptDefinition, ConceptDefinition_FirstClassOnly, ConceptDefinition_IsCore, ConceptDefinition_MainIconField, Field } from '../../conceptModule/ids';
import { Dashboard, Dashboard_Icon, DashboardParameter_Dashboard, DashboardParameter_Label, Widget_Dashboard, Widget_Title } from '../ids';
import { registerModel } from '../module';
import type { DashboardParameterStoreObject, WidgetStoreObject } from './types';

const { type } = registerModel;

export type WidgetDisplayMode = { type: 'borderless' | 'card' } | { type: 'color', color: string };
export type WidgetTitleMode = 'auto' | 'always' | 'hide';

type({
  label: 'Dashboard',
  extends: Concept,
  instanceOf: ConceptDefinition,
  extraProperties: {
    [ConceptDefinition_IsCore]: true,
    [ConceptDefinition_FirstClassOnly]: true,
    [ConceptDefinition_MainIconField]: Dashboard_Icon,
  },
});

type({
  label: 'Widget',
  accessControlList: {
    READ: ({ getObjectOrNull }) => (_, objectId) => {
      const dashboardId = getObjectOrNull<WidgetStoreObject>(objectId[0])?.[Widget_Dashboard];
      if (dashboardId) {
        return { rule: 'widget.read.delegated', status: ValidationStatus.DELEGATED, targetId: [dashboardId], targetAction: 'READ' };
      } else {
        return undefined;
      }
    },
    WRITE: ({ getObjectOrNull }) => (_, objectId, { newProperties }) => {
      const dashboardId = getObjectOrNull<WidgetStoreObject>(objectId[0])?.[Widget_Dashboard] || newProperties?.[Widget_Dashboard] as string;
      if (dashboardId) {
        return { rule: 'widget.write.delegated', status: ValidationStatus.DELEGATED, targetId: [dashboardId], targetAction: 'WRITE' };
      } else {
        return undefined;
      }
    },
    DELETE: ({ getObjectOrNull }) => (_, objectId) => {
      const dashboardId = getObjectOrNull<WidgetStoreObject>(objectId[0])?.[Widget_Dashboard];
      if (dashboardId) {
        return {
          rule: 'widget.delete.delegated',
          status: ValidationStatus.DELEGATED,
          targetId: [dashboardId],
          targetAction: 'WRITE',
        };
      } else {
        return undefined;
      }
    },
  },
  objectDebugLabel: (objectStore) => (objectId) => objectStore.getObject(objectId)[Widget_Title] as string,
})
  .property({ label: 'Widget_Title', as: CommonAsType.string })
  .property({ label: 'Widget_TitleMode', as: asLocal('WidgetTitleMode') })
  .property({ label: 'Widget_HeaderBackgroundColor', as: CommonAsType.string })
  .property({ label: 'Widget_DisplayMode', as: asLocal('WidgetDisplayMode') })
  .relation({ label: 'Widget_Dashboard', targetTypeId: Dashboard, reverseLabel: 'Dashboard_Widgets' })
  .relation({ label: 'Widget_Field', targetTypeId: Field, reverseLabel: 'Field_Widget' })
  .property({ label: 'Widget_Width', as: CommonAsType.number })
  .property({ label: 'Widget_Height', as: CommonAsType.number })
  .property({ label: 'Widget_X', as: CommonAsType.number })
  .property({ label: 'Widget_Y', as: CommonAsType.number });

type({
  label: 'DashboardParameter',
  objectDebugLabel: ({ getObject }) => (objectId) => getObject(objectId)[DashboardParameter_Label] as string,
  accessControlList: {
    READ: ({ getObjectOrNull }) => (_, objectId) => {
      const dashboardId = getObjectOrNull<DashboardParameterStoreObject>(objectId[0])?.[DashboardParameter_Dashboard];
      if (dashboardId) {
        return {
          rule: 'dashboardParameter.read.delegated',
          status: ValidationStatus.DELEGATED,
          targetId: [dashboardId],
          targetAction: 'READ',
        };
      } else {
        return undefined;
      }
    },
    WRITE: ({ getObjectOrNull }) => (_, objectId, { newProperties }) => {
      const dashboardId = getObjectOrNull<DashboardParameterStoreObject>(objectId[0])?.[DashboardParameter_Dashboard] || newProperties?.[DashboardParameter_Dashboard] as string;
      if (dashboardId) {
        return {
          rule: 'dashboardParameter.write.delegated',
          status: ValidationStatus.DELEGATED,
          targetId: [dashboardId],
          targetAction: 'WRITE',
        };
      } else {
        return undefined;
      }
    },
    DELETE: ({ getObjectOrNull }) => (_, objectId) => {
      const dashboardId = getObjectOrNull<DashboardParameterStoreObject>(objectId[0])?.[DashboardParameter_Dashboard];
      if (dashboardId) {
        return {
          rule: 'dashboardParameter.delete.delegated',
          status: ValidationStatus.DELEGATED,
          targetId: [dashboardId],
          targetAction: 'WRITE',
        };
      } else {
        return undefined;
      }
    },
  },
})
  .property({ label: 'DashboardParameter_Label', as: CommonAsType.string })
  .relation({ label: 'DashboardParameter_Dashboard', targetTypeId: Dashboard, reverseLabel: 'Dashboard_Parameters' })
  .relation({ label: 'DashboardParameter_Type', targetTypeId: ConceptDefinition, reverseLabel: 'ConceptDefinition_DashboardParameterTargets' })
  .property({ label: 'DashboardParameter_DefaultValue', as: CommonAsType.string })
  .property({ label: 'DashboardParameter_Filters', as: CommonAsType.Filters })
  .property({ label: 'DashboardParameter_Rank', as: CommonAsType.string })
  .property({ label: 'DashboardParameter_UnsetLabel', as: CommonAsType.string })
  .property({ label: 'DashboardParameter_EnableNotSetOption', as: CommonAsType.boolean });

type({
  label: 'DashboardParameterOption',
  accessControlList: {
    READ: () => () => ({ rule: 'DashboardParameterOption.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: () => () => ({ rule: 'DashboardParameterOption.write.deny', status: ValidationStatus.REJECTED }),
    DELETE: () => () => ({ rule: 'DashboardParameterOption.delete.deny', status: ValidationStatus.REJECTED }),
  },
})
  .instance({ label: 'UnsetDashboardParameterOption' });
