import type { PathStep } from 'yooi-modules/modules/conceptModule';
import { Field_Documentation, Field_Title } from 'yooi-modules/modules/conceptModule/ids';
import type { MirrorFieldRaw, MirrorFieldStoreObject } from 'yooi-modules/modules/dashboardModule';
import { mirrorFieldHandler } from 'yooi-modules/modules/dashboardModule';
import { MirrorField, MirrorField_Path, MirrorField_TargetedFieldDefinition } from 'yooi-modules/modules/dashboardModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { IconName } from '../../../../components/atoms/Icon';
import i18n from '../../../../utils/i18n';
import { getLoggedUserParameterDefinition } from '../../filter/filterUtils';
import PathInput from '../../path/PathInput';
import { createPathConfigurationHandler } from '../../pathConfigurationHandler';
import { getFieldTypeValidator } from '../../pathConfigurationHandlerUtils';
import { duplicatePathSteps } from '../duplicationUtils';
import { duplicateFieldDimensionWithNewField, generateDuplicatedFieldDimensionId } from '../fieldDimensionUtils';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode } from '../FieldLibraryTypes';
import MirrorWidgetField from './MirrorWidgetField';

interface MirrorFieldConfigurationState {
  [MirrorField_Path]: PathStep[] | null | undefined,
  [MirrorField_TargetedFieldDefinition]: string | null | undefined,
}

type MirrorFieldDefinition = GetFieldDefinitionHandler<typeof mirrorFieldHandler, MirrorFieldConfigurationState>;

export const mirrorFieldDefinition: MirrorFieldDefinition = registerFieldDefinition(mirrorFieldHandler, {
  configuration: {
    typeIcon: IconName.tune,
    getTypeLabel: () => i18n`Mirror`,
    asWidget: false,
    getEditionOptions: (store) => ({ mode, editionHandler, dashboardParameterDefinitions, readOnly }) => {
      if (mode !== FieldEditionOptionMode.Widget) {
        return [];
      }

      const mirrorTypeId = editionHandler.getValueOrDefault(MirrorField_TargetedFieldDefinition) as string;
      const parameterDefinitions = [...dashboardParameterDefinitions ?? [], getLoggedUserParameterDefinition()];
      return [
        {
          key: 'display',
          type: 'section',
          title: i18n`Display`,
          options: [
            {
              key: MirrorField_Path,
              title: i18n`Field`,
              type: EditionOptionTypes.custom,
              hasValue: () => !!editionHandler.getValue(MirrorField_Path),
              clearValue: () => editionHandler.updateValues({ [MirrorField_Path]: [] }),
              props: {
                render: () => {
                  const fieldPath = editionHandler.getValue(MirrorField_Path) ?? [];
                  const valuePathHandler = createPathConfigurationHandler(
                    store,
                    parameterDefinitions,
                    [getFieldTypeValidator(store, [mirrorTypeId], i18n`Input should end with a number.`)]
                  );
                  return (
                    <PathInput
                      parameterDefinitions={parameterDefinitions}
                      onChange={(newPath) => {
                        editionHandler.updateValues({ [MirrorField_Path]: newPath });
                      }}
                      path={fieldPath}
                      valuePathHandler={valuePathHandler}
                      readOnly={readOnly}
                      placeholder={i18n`Select field`}
                    />
                  );
                },
              },
            },
          ],
        },
      ];
    },
    ofField: (objectStore, fieldId) => ({
      getInitialState: () => {
        const field = objectStore.getObject<MirrorFieldStoreObject>(fieldId);
        return {
          [MirrorField_Path]: field[MirrorField_Path],
          [MirrorField_TargetedFieldDefinition]: field[MirrorField_TargetedFieldDefinition],
        };
      },
      submitFieldUpdate: (stateToSubmit) => {
        objectStore.updateObject<MirrorFieldRaw>(fieldId, {
          [MirrorField_Path]: stateToSubmit[MirrorField_Path],
          [MirrorField_TargetedFieldDefinition]: stateToSubmit[MirrorField_TargetedFieldDefinition],
        });
      },
      duplicateFieldDefinition: ({ parameterMap }) => {
        const mirrorField = mirrorFieldHandler(objectStore, fieldId).resolveConfiguration();
        const newFieldId = objectStore.createObject({
          [Instance_Of]: MirrorField,
          [Field_Title]: `${mirrorField.title} (copy)`,
          [Field_Documentation]: mirrorField.documentation,
          [MirrorField_Path]: parameterMap && mirrorField.path ? duplicatePathSteps(mirrorField.path, parameterMap) : mirrorField.path,
          [MirrorField_TargetedFieldDefinition]: mirrorField.targetedFieldDefinitionId,
        });
        duplicateFieldDimensionWithNewField(objectStore, newFieldId, generateDuplicatedFieldDimensionId(objectStore, fieldId));
        return newFieldId;
      },
    }),
  },
  renderWidget: (_, fieldId) => ({ parametersMapping, readOnly }) => <MirrorWidgetField fieldId={fieldId} parametersMapping={parametersMapping} readOnly={Boolean(readOnly)} />,
  getActivityProperties: (_, fieldId) => () => [fieldId],
});
