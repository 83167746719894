import type { FunctionComponent } from 'react';
import Typo from '../components/atoms/Typo';
import ProgressBar from '../components/molecules/ProgressBar';
import { FontVariant } from '../theme/fontDefinition';
import { getSpacing, Spacing } from '../theme/spacingDefinition';
import makeStyles from '../utils/makeStyles';
import { remToPx } from '../utils/sizeUtils';
import useTheme from '../utils/useTheme';

const useStyles = makeStyles({
  progressBarContainer: {
    marginTop: getSpacing(Spacing.xxl),
    marginBottom: getSpacing(Spacing.m),
  },
}, 'connectionProgressBar');

interface ConnectionProgressBarProps {
  label: string,
  percentage: number,
}

const ConnectionProgressBar: FunctionComponent<ConnectionProgressBarProps> = ({ label, percentage }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <>
      <div className={classes.progressBarContainer}>
        <ProgressBar width={remToPx(17)} percentage={percentage} />
      </div>
      <Typo variant={FontVariant.small} color={theme.color.text.secondary}>{label}</Typo>
    </>
  );
};

export default ConnectionProgressBar;
