import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { Instance_Of } from '../../../typeModule/ids';
import { EmbeddingField_FromType, KinshipRelationField } from '../../ids';
import type { DimensionStep, FilterStep, PathStep } from '../../moduleType';
import { PathStepType } from '../../moduleType';
import { getFieldUtilsHandler } from '../fieldUtilsHandler';
import { isRelationalType } from '../relationFieldUtils';
import { isDimensionStep, isFieldStep, isFilterStep, isGlobalDimensionStep, isMappingStep } from './pathUtils';

export const isDimensionLibraryPath = (path: PathStep[]): path is [DimensionStep] | [DimensionStep, FilterStep] => (
  path.length === 1 && isDimensionStep(path[0])) || (path.length === 2 && isDimensionStep(path[0]) && isFilterStep(path[1])
);

export interface LastFieldInfo {
  fieldId: string,
  conceptDefinitionId: string | undefined,
  pathBeforeField: PathStep[],
  pathToField: PathStep[],
  pathAfterField: PathStep[],
}

export const getPathReturnedConceptDefinitionId = (store: ObjectStoreWithTimeseries, path: PathStep[]): string | undefined => {
  let returnTypeId: string | undefined;
  for (let i = 0; i < path.length; i += 1) {
    const nStep = path[i];
    if (isGlobalDimensionStep(nStep)) {
      // nothing is returned
    } else if (isDimensionStep(nStep)) {
      if (store.getObjectOrNull(nStep.conceptDefinitionId) === null) {
        return undefined;
      }
      // a list of concept of dimension step conceptDefinition is returned
      returnTypeId = nStep.conceptDefinitionId;
    } else if (isMappingStep(nStep)) {
      // only multiplicity change
    } else if (isFieldStep(nStep)) {
      if (store.getObjectOrNull(nStep.fieldId) === null) {
        return undefined;
      }
      const stepField = store.getObject(nStep.fieldId);
      const previousStep = path[i - 1];
      if (previousStep && isGlobalDimensionStep(previousStep)) {
        // field
        returnTypeId = undefined;
      } else if (stepField[Instance_Of] as string === KinshipRelationField) {
        if (nStep.embeddingFieldId) {
          const embeddingField = store.getObjectOrNull(nStep.embeddingFieldId);
          if (embeddingField && embeddingField[EmbeddingField_FromType]) {
            returnTypeId = embeddingField[EmbeddingField_FromType] as string;
          } else {
            returnTypeId = undefined;
          }
        } else {
          // multiple returned types not supported
          returnTypeId = undefined;
        }
      } else if (isRelationalType(stepField[Instance_Of] as string)) {
        returnTypeId = getFieldUtilsHandler(store, stepField.id)?.getTargetType?.()?.id;
      } else {
        returnTypeId = undefined;
      }
    } else if (isFilterStep(nStep)) {
      // only multiplicity change
    }
  }
  return returnTypeId;
};

export const duplicatePath = (oldFieldPath: PathStep[] | undefined, dimensionsMapping: Record<string, string>): PathStep[] | undefined => {
  if (oldFieldPath) {
    return oldFieldPath.map((step) => (isMappingStep(step) ? {
      type: PathStepType.mapping,
      mapping: { id: dimensionsMapping[step.mapping.id] ?? step.mapping.id, type: step.mapping.type },
    } : step));
  } else {
    return undefined;
  }
};
