import type { FunctionComponent } from 'react';
// Yes, we have a cycle, but this is expected
// eslint-disable-next-line import/no-cycle
import ArrayRenderer from './ArrayRenderer';
import BooleanRenderer from './BooleanRenderer';
import DateRenderer from './DateRenderer';
import EventIdRenderer from './EventIdRenderer';
import NullRenderer from './NullRenderer';
import NumberRenderer from './NumberRenderer';
// Yes, we have a cycle, but this is expected
// eslint-disable-next-line import/no-cycle
import ObjectRenderer from './ObjectRenderer';
import type { Values } from './objectRenderType';
import { ValueType } from './objectRenderType';
import StringRenderer from './StringRenderer';

interface ValueRendererProps {
  value: Values,
}

const ValueRenderer: FunctionComponent<ValueRendererProps> = ({ value }) => {
  switch (value.type) {
    case ValueType.object:
      return (<ObjectRenderer object={value} />);
    case ValueType.number:
      return (<NumberRenderer number={value} />);
    case ValueType.eventId:
      return (<EventIdRenderer eventId={value} />);
    case ValueType.string:
      return (<StringRenderer string={value} />);
    case ValueType.array:
      return (<ArrayRenderer array={value} />);
    case ValueType.boolean:
      return (<BooleanRenderer boolean={value} />);
    case ValueType.date:
      return (<DateRenderer date={value} />);
    case ValueType.null:
      return (<NullRenderer />);
  }
};

export default ValueRenderer;
