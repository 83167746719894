// a > b => positive
// a === b => 0
// a < b => negative
export interface Comparator<T> {
  (a: T, b: T): number,
}

export interface ComparatorChain<T> extends Comparator<T> {
  thenComparing: (comparator: Comparator<T>, reverse?: boolean) => ComparatorChain<T>,
}

export const buildComparatorChain = <T>(comparators: Comparator<T>[]): ComparatorChain<T> => {
  const comparatorFunction = (a: T, b: T): number => {
    for (let i = 0; i < comparators.length; i += 1) {
      const result = comparators[i](a, b);
      if (result !== 0) {
        return result;
      }
    }
    return 0;
  };
  comparatorFunction.thenComparing = (comparator: Comparator<T>, reverse = false) => {
    const newComparator: Comparator<T> = reverse ? (a, b) => -comparator(a, b) : comparator;
    return buildComparatorChain([...comparators, newComparator]);
  };
  return comparatorFunction as unknown as ComparatorChain<T>;
};

export const comparing = <T>(comparator: Comparator<T>, reverse = false): ComparatorChain<T> => {
  const newComparator: Comparator<T> = reverse ? (a, b) => -comparator(a, b) : comparator;
  return buildComparatorChain([newComparator]);
};

export const compareNumber: Comparator<number | undefined> = (a, b) => (a ?? 0) - (b ?? 0);

export const compareBoolean: Comparator<boolean | null | undefined> = (a, b) => {
  if (b && !a) {
    return 1; // b should be before a
  } else if (a && !b) {
    return -1; // a should be before b
  } else {
    return 0;
  }
};

export const compareString: Comparator<string | undefined> = (a, b) => (a ?? '').localeCompare(b ?? '', 'en');

export const compareProperty = <T, U = T[keyof T]>(key: keyof T, comparator: Comparator<U>): Comparator<T> => (a, b) => (
  comparator(a?.[key] as unknown as U, b?.[key] as unknown as U)
);

export const extractAndCompareValue = <O, T>(getValue: (object: O) => T, comparator: Comparator<T>): Comparator<O> => (a, b) => comparator(getValue(a), getValue(b));

export const compareArray: Comparator<string[] | undefined> = (a, b) => {
  if ((a?.length ?? 0) === 0 && (b?.length ?? 0) === 0) {
    return 0;
  } else if (!a || a.length === 0) {
    return 1; // b should be before a
  } else if (!b || b.length === 0) {
    return -1; // a should be before b
  } else {
    const numberOfIteration = Math.min(a.length, b.length);
    for (let i = 0; i < numberOfIteration; i += 1) {
      const compareResult = extractAndCompareValue<string[], string>((array) => array[i], compareString)(a, b);
      if (compareResult !== 0) {
        return compareResult;
      }
    }
    if (a.length > b.length) {
      return -1; // a should be before b
    } else if (a.length < b.length) {
      return 1;
    } else {
      return 0;
    }
  }
};

// push every empty string / null / undefined to end
export const pushUndefinedToEnd = <T>(a: T | null | undefined, b: T | null | undefined): number => {
  const aIsUndefined = a === undefined || a === null || (typeof a === 'string' && a === '');
  const bIsUndefined = b === undefined || b === null || (typeof b === 'string' && b === '');
  if (aIsUndefined && bIsUndefined) {
    return 0;
  } else if (aIsUndefined) {
    return 1; // b should be before a
  } else if (bIsUndefined) {
    return -1; // a should be before b
  } else {
    return 0; // There are not in the list, don't change the order
  }
};

export const compareDate: Comparator<Date | undefined> = extractAndCompareValue((v) => v?.getTime?.(), compareNumber);

export const withObjectWithIdsAtEnd = (order: string[]): Comparator<{ id: string }> => ({ id: aId }, { id: bId }) => {
  const aPosition = order.indexOf(aId);
  const bPosition = order.indexOf(bId);
  if (aPosition !== -1 && bPosition !== -1) {
    return aPosition - bPosition;
  } else if (aPosition !== -1) {
    return 1; // b should be before a
  } else if (bPosition !== -1) {
    return -1; // a should be before b
  } else {
    return 0; // There are not in the list, don't change the order
  }
};

export const compareEventId: Comparator<string> = (a, b) => {
  const [timestampA, sequenceA] = a.split('-');
  const [timestampB, sequenceB] = b.split('-');
  return (Number.parseInt(timestampA, 10) - Number.parseInt(timestampB, 10)) || (Number.parseInt(sequenceA, 10) - Number.parseInt(sequenceB, 10));
};
