import type { FunctionComponent } from 'react';
import {
  DataAsset,
  DataAsset_Type,
  DataAssetType,
  DataAssetType_DefaultResourceType,
  DataAssetType_Description,
  DataAssetType_IsDefaultType,
  DataAssetType_Name,
  DataAssetTypeResourceTypes,
  DataAssetTypeResourceTypes_Role_DataAssetType,
  DataAssetTypeResourceTypes_Role_ResourceType,
} from 'yooi-modules/modules/dataAssetModule/ids';
import { ResourceType, ResourceType_Description, ResourceType_Name } from 'yooi-modules/modules/resourceModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined } from 'yooi-utils';
import { IconName } from '../../../../../components/atoms/Icon';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import SearchAndSelectMultiple from '../../../../../components/molecules/SearchAndSelectMultiple';
import BlockContent from '../../../../../components/templates/BlockContent';
import BlockTitle from '../../../../../components/templates/BlockTitle';
import DataTable from '../../../../../components/templates/DataTable';
import HorizontalBlock from '../../../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../../../components/templates/VerticalBlock';
import useStore from '../../../../../store/useStore';
import i18n from '../../../../../utils/i18n';
import StoreTextInputField from '../../../../_global/input/StoreTextInputField';
import { defaultOptionComparator, getChipOptions } from '../../../../_global/modelTypeUtils';

const DataAssetTypesTab: FunctionComponent = () => {
  const store = useStore();

  const dataAssetTypes = store.getObject(DataAssetType)
    .navigateBack(Class_Instances);

  const resourceTypes = store.getObject(ResourceType)
    .navigateBack(Class_Instances);

  const dataAssetTypeResourceTypes = (dataAssetId: string) => store.withAssociation(DataAssetTypeResourceTypes)
    .withRole(DataAssetTypeResourceTypes_Role_DataAssetType, dataAssetId)
    .list()
    .map((assoc) => getChipOptions(store, assoc.role(DataAssetTypeResourceTypes_Role_ResourceType)))
    .filter(filterNullOrUndefined)
    .sort(defaultOptionComparator);

  const onUnlinkResourceType = (dataAssetId: string, resourceTypeId: string) => {
    if (store.getObject(dataAssetId)[DataAssetType_DefaultResourceType] === resourceTypeId) {
      store.updateObject(dataAssetId, { [DataAssetType_DefaultResourceType]: null });
    }
    store.withAssociation(DataAssetTypeResourceTypes)
      .withRole(DataAssetTypeResourceTypes_Role_DataAssetType, dataAssetId)
      .withRole(DataAssetTypeResourceTypes_Role_ResourceType, resourceTypeId)
      .deleteObject();
  };

  const doDeleteResourceType = (resourceTypeId: string) => {
    store.withAssociation(DataAssetTypeResourceTypes)
      .withRole(DataAssetTypeResourceTypes_Role_ResourceType, resourceTypeId)
      .withExternalRole(DataAssetTypeResourceTypes_Role_DataAssetType)
      .list()
      .forEach(({ object: { id } }) => store.deleteObject(id));
    store.getObject(DataAssetType)
      .navigateBack(Class_Instances)
      .filter((dataAssetType) => dataAssetType[DataAssetType_DefaultResourceType] === resourceTypeId)
      .map((dataAssetType) => store.updateObject(dataAssetType.id, { [DataAssetType_DefaultResourceType]: null }));
    store.deleteObject(resourceTypeId);
  };

  const defaultType = store.getObject(DataAssetType)
    .navigateBack(Class_Instances)
    .find((dataAssetType) => dataAssetType[DataAssetType_IsDefaultType]);

  const doDeleteDataAssetType = (dataAssetTypeId: string) => {
    store.withAssociation(DataAssetTypeResourceTypes)
      .withRole(DataAssetTypeResourceTypes_Role_DataAssetType, dataAssetTypeId)
      .list()
      .forEach(({ object: { id } }) => store.deleteObject(id));
    store.getObject(DataAsset)
      .navigateBack(Class_Instances)
      .filter((dataAsset) => dataAsset[DataAsset_Type] === dataAssetTypeId)
      .forEach((dataAsset) => store.updateObject(dataAsset.id, { [DataAsset_Type]: null }));
    store.deleteObject(dataAssetTypeId);
  };

  return (
    <VerticalBlock>
      <VerticalBlock asBlockContent withSeparation>
        <BlockTitle title={i18n`Data asset types`} />
        <HorizontalBlock asBlockContent>
          <BlockTitle title={i18n`Default data asset type`} />
          <BlockContent>
            <SearchAndSelect
              clearable
              computeOptions={() => (
                dataAssetTypes
                  .map(({ id }) => getChipOptions(store, id))
                  .filter(filterNullOrUndefined)
                  .sort(defaultOptionComparator)
              )}
              selectedOption={defaultType ? getChipOptions(store, defaultType.id) : undefined}
              searchOptions={{
                searchKeys: [DataAssetType_Name],
                extractValue: ({ object }, searchKey) => {
                  const value = object[searchKey];
                  return typeof value === 'string' ? value : undefined;
                },
              }}
              onSelect={(dataAssetType) => {
                if (dataAssetType) {
                  store.updateObject(dataAssetType.id, { [DataAssetType_IsDefaultType]: true });
                } else if (defaultType) {
                  store.updateObject(defaultType.id, { [DataAssetType_IsDefaultType]: null });
                }
              }}
              placeholder={i18n`Select data asset type`}
            />
          </BlockContent>
        </HorizontalBlock>
        <DataTable
          list={dataAssetTypes.map((item) => ({ key: item.id, type: 'item', item, color: undefined }))}
          newItemIcon={IconName.dataset}
          newItemTitle={i18n`Create`}
          onNewItem={() => store.createObject({ [Instance_Of]: DataAssetType })}
          columnsDefinition={[
            {
              propertyId: DataAssetType_Name,
              name: i18n`Name`,
              width: 20,
              cellRender: ({ id, [DataAssetType_Name]: name }, focusOnMount) => (
                <StoreTextInputField
                  initialValue={name as string | undefined}
                  onSubmit={(value) => store.updateObject(id, { [DataAssetType_Name]: value })}
                  focusOnMount={focusOnMount}
                />
              ),
            },
            {
              propertyId: DataAssetType_Description,
              name: i18n`Description`,
              width: 20,
              cellRender: ({ id, [DataAssetType_Description]: description }) => (
                <StoreTextInputField
                  initialValue={description as string | undefined}
                  onSubmit={(value) => store.updateObject(id, { [DataAssetType_Description]: value })}
                />
              ),
            },
            {
              propertyId: DataAssetTypeResourceTypes,
              name: i18n`Resource types`,
              width: 40,
              cellRender: ({ id, [DataAssetType_DefaultResourceType]: defaultResourceTypeId }) => (
                <SearchAndSelectMultiple
                  computeOptions={() => resourceTypes
                    .map(({ id: instanceId }) => getChipOptions(store, instanceId))
                    .filter(filterNullOrUndefined)
                    .sort(defaultOptionComparator)}
                  selectedOptions={dataAssetTypeResourceTypes(id)}
                  onDelete={(resourceType) => onUnlinkResourceType(id, resourceType.id)}
                  getInlineCreation={() => ({
                    type: 'inline',
                    onCreate: (resourceTypeName) => {
                      const resourceTypeId = store.createObject({
                        [Instance_Of]: ResourceType,
                        [ResourceType_Name]: resourceTypeName,
                      });
                      store.withAssociation(DataAssetTypeResourceTypes)
                        .withRole(DataAssetTypeResourceTypes_Role_DataAssetType, id)
                        .withRole(DataAssetTypeResourceTypes_Role_ResourceType, resourceTypeId)
                        .updateObject({});
                      if (!defaultResourceTypeId) {
                        store.updateObject(id, { [DataAssetType_DefaultResourceType]: resourceTypeId });
                      }
                      return resourceTypeId;
                    },
                  })}
                  onSelect={(resourceType) => store.withAssociation(DataAssetTypeResourceTypes)
                    .withRole(DataAssetTypeResourceTypes_Role_DataAssetType, id)
                    .withRole(DataAssetTypeResourceTypes_Role_ResourceType, resourceType.id)
                    .updateObject({})}
                />
              ),
            },
            {
              propertyId: DataAssetType_DefaultResourceType,
              name: i18n`Default resource type`,
              cellRender: ({ id, [DataAssetType_DefaultResourceType]: defaultResourceTypeId }) => (
                <SearchAndSelect
                  clearable
                  computeOptions={() => dataAssetTypeResourceTypes(id)}
                  selectedOption={typeof defaultResourceTypeId === 'string' ? getChipOptions(store, defaultResourceTypeId) : undefined}
                  onSelect={(resourceType) => store.updateObject(id, { [DataAssetType_DefaultResourceType]: resourceType?.id ?? null })}
                />
              ),
            },
          ]}
          linesActions={(line) => [
            {
              key: 'delete',
              name: i18n`Delete`,
              icon: IconName.delete,
              onClick: () => doDeleteDataAssetType(line.id),
              danger: true,
            },
          ]}
        />
      </VerticalBlock>
      <BlockTitle title={i18n`Resources types`} />
      <DataTable
        list={resourceTypes.map((item) => ({ key: item.id, type: 'item', item, color: undefined }))}
        newItemIcon={IconName.description}
        newItemTitle={i18n`Create`}
        onNewItem={() => store.createObject({ [Instance_Of]: ResourceType })}
        columnsDefinition={[
          {
            propertyId: ResourceType_Name,
            name: i18n`Name`,
            width: 30,
            cellRender: ({ id, [ResourceType_Name]: name }, focusOnMount) => (
              <StoreTextInputField
                initialValue={name as string | undefined}
                onSubmit={(value) => store.updateObject(id, { [ResourceType_Name]: value })}
                focusOnMount={focusOnMount}
              />
            ),
          },
          {
            propertyId: ResourceType_Description,
            name: i18n`Description`,
            cellRender: ({ id, [ResourceType_Description]: description }) => (
              <StoreTextInputField
                initialValue={description as string | undefined}
                onSubmit={(value) => store.updateObject(id, { [ResourceType_Description]: value })}
              />
            ),
          },
        ]}
        linesActions={(line) => [
          {
            key: 'delete',
            name: i18n`Delete`,
            icon: IconName.delete,
            onClick: () => doDeleteResourceType(line.id),
            danger: true,
          },
        ]}
      />
    </VerticalBlock>
  );
};

export default DataAssetTypesTab;
