import classnames from 'classnames';
import type { ComponentProps, FunctionComponent } from 'react';
import { useState } from 'react';
import { computeLightShade } from '../../app/utils/standardColorsUtils';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import useDerivedState from '../../utils/useDerivedState';
import useSizeContext, { SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import useUsageContext, { UsageVariant } from '../../utils/useUsageContext';
import Icon, { IconName, IconSizeVariant } from '../atoms/Icon';
import Typo from '../atoms/Typo';
import FloatingEditableWithDropdown, { FloatingEditableCloseReasons } from '../molecules/FloatingEditableWithDropdown';
import IconColorLine from './internal/IconColorLine';
import IconPickerOptions from './internal/IconPickerOptions';

interface PaletteColor {
  value: string,
  label?: string,
}

const useStyles = makeStyles((theme) => ({
  valueContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  valueContainerInTable: {
    flexGrow: 1,
  },
  valueContainerDefault: {
    minWidth: '3.2rem',
    minHeight: '3rem',
  },
  valueContainerSmall: {
    minWidth: '2.2rem',
    minHeight: '2.2rem',
  },
  valueContainerDefaultPadded: {
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
  },
  valueContainerBig: {
    minWidth: '5rem',
    minHeight: '5rem',
  },
  dropdownContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.splus,
    paddingTop: spacingRem.splus,
    paddingBottom: spacingRem.splus,
  },
  dropdownSpacer: {
    display: 'flex',
    borderTopWidth: '0.1rem',
    borderTopStyle: 'solid',
    borderTopColor: theme.color.border.default,
  },
}), 'coloredIconPicker');

interface ColoredIconPickerProps {
  value: { icon: IconName, color: string } | undefined,
  onChange: (newValue: { icon: IconName, color: string } | undefined) => void,
  onSubmit?: (newValue: { icon: IconName, color: string } | undefined) => void,
  onCancel?: () => void,
  colorPalette: PaletteColor[],
  readOnly?: boolean,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  withMultiplayerOutline?: boolean,
  focusOnMount?: boolean,
  clearMode?: { type: 'clear' } | { type: 'reset', value: IconName, color?: string, tooltip?: string },
  restingTooltip?: string | (() => Promise<string>),
  placeholder?: string,
}

const ColoredIconPicker: FunctionComponent<ColoredIconPickerProps> = ({
  value,
  onChange,
  onSubmit,
  onCancel,
  colorPalette,
  readOnly = false,
  onEditionStart,
  onEditionStop,
  withMultiplayerOutline = false,
  focusOnMount = false,
  clearMode,
  restingTooltip,
  placeholder,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const usageVariant = useUsageContext();
  const { sizeVariant } = useSizeContext();

  const [showDropdown, setShowDropdown] = useState(focusOnMount);

  const [appliedColor, setAppliedColor, clearAppliedColor] = useDerivedState(() => value?.color ?? theme.color.text.brand, [value?.color, showDropdown]);

  const openDropdown = () => {
    setShowDropdown(true);
    onEditionStart?.();
  };
  const closeDropdown: ComponentProps<typeof FloatingEditableWithDropdown>['closeDropdown'] = (reason) => {
    setShowDropdown(false);
    onEditionStop?.();
    if (reason === FloatingEditableCloseReasons.onBackdropClick) {
      onSubmit?.(value);
    } else {
      onCancel?.();
    }
  };

  return (
    <FloatingEditableWithDropdown
      showDropdown={showDropdown}
      openDropdown={openDropdown}
      closeDropdown={closeDropdown}
      renderValue={() => {
        let iconSize: IconSizeVariant;
        if (sizeVariant === SizeVariant.title) {
          iconSize = IconSizeVariant.xl;
        } else if (sizeVariant === SizeVariant.small) {
          iconSize = IconSizeVariant.m;
        } else {
          iconSize = IconSizeVariant.l;
        }
        return (
          <span
            className={classnames({
              [classes.valueContainer]: true,
              [classes.valueContainerInTable]: usageVariant === UsageVariant.inTable,
              [classes.valueContainerDefault]: sizeVariant !== SizeVariant.title && sizeVariant !== SizeVariant.small,
              [classes.valueContainerDefaultPadded]: sizeVariant !== SizeVariant.title && sizeVariant !== SizeVariant.small && value === undefined,
              [classes.valueContainerBig]: sizeVariant === SizeVariant.title,
              [classes.valueContainerSmall]: sizeVariant === SizeVariant.small,
            })}
            style={value !== undefined ? { backgroundColor: computeLightShade(appliedColor) } : undefined}
          >
            {
              value === undefined && (sizeVariant === SizeVariant.title || sizeVariant === SizeVariant.small)
                ? (
                  <Icon
                    name={IconName.category}
                    size={iconSize}
                    color={theme.color.background.neutral.subtle}
                    tooltip={placeholder}
                  />
                )
                : null
            }
            {
              value === undefined && sizeVariant !== SizeVariant.title && sizeVariant !== SizeVariant.small && usageVariant !== UsageVariant.inTable
                ? (<Typo maxLine={1} color={theme.color.text.disabled}>{placeholder}</Typo>)
                : null
            }
            {value !== undefined ? (<Icon name={value.icon} color={value.color} size={iconSize} />) : null}
          </span>
        );
      }}
      renderDropdown={() => (
        readOnly
          ? null
          : (
            <span className={classes.dropdownContainer}>
              <IconColorLine
                colorPalette={colorPalette}
                value={appliedColor}
                onChange={(newColor) => {
                  if (value === undefined) {
                    setAppliedColor(newColor);
                  } else {
                    onChange({ icon: value.icon, color: newColor });
                  }
                }}
              />
              <span className={classes.dropdownSpacer} />
              <IconPickerOptions
                value={value?.icon}
                color={appliedColor}
                onChange={(newValue) => {
                  if (newValue?.newIcon === undefined) {
                    if (value === undefined) {
                      clearAppliedColor();
                    } else {
                      onChange(undefined);
                    }
                  } else {
                    onChange({ icon: newValue.newIcon, color: newValue.newColor || appliedColor });
                  }
                }}
                clearMode={clearMode}
              />
            </span>
          )
      )}
      valueSizes={usageVariant !== UsageVariant.inTable ? { width: 'fit-content' } : undefined}
      readOnly={readOnly}
      withMultiplayerOutline={withMultiplayerOutline}
      withDashedBorder={(sizeVariant === SizeVariant.title || sizeVariant === SizeVariant.small) && value === undefined}
      restingTooltip={restingTooltip}
      withoutOverlayValueMinWidth
      autoFocusDropdown
    />
  );
};

export default ColoredIconPicker;
