import type { FunctionComponent } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { TimeseriesTableViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import useDeepMemo from '../../../../utils/useDeepMemo';
import useDerivedState from '../../../../utils/useDerivedState';
import useForceUpdate from '../../../../utils/useForceUpdate';
import { useSessionStorageState } from '../../../../utils/useSessionStorage';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { useOnFilterChanged } from '../../filter/useFilterSessionStorage';
import BlockViewError from '../common/BlockViewError';
import { getViewDefinitionHandler } from '../viewDsl';
import { isResolutionError } from '../viewResolutionUtils';
import TimeseriesTableView from './TimeseriesTableView';
import type { TimeseriesTableViewDefinitionHandler } from './timeseriesTableViewHandler';

interface TimeseriesTableViewBlockProps {
  viewDimensions: ViewDimension[],
  viewDefinition: TimeseriesTableViewStoredDefinition,
  parametersMapping: ParametersMapping,
  filterKey: string,
  readOnly?: boolean,
}

const useStyles = makeStyles((theme) => ({
  paddedContainer: {
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
    paddingLeft: spacingRem.s,
  },
}), 'timeseriesTableViewBlock');

const TimeseriesTableViewBlock: FunctionComponent<TimeseriesTableViewBlockProps> = ({
  viewDimensions,
  viewDefinition,
  parametersMapping,
  filterKey,
  readOnly,
}) => {
  const store = useStore();
  const classes = useStyles();
  const { loggedUserId } = useAuth();
  const aclHandler = useAcl();
  const forceUpdate = useForceUpdate();
  const [addedTimePoints, setAddedTimePoints] = useDerivedState<number[]>(() => [], [viewDimensions, viewDefinition, parametersMapping]);
  const [filterConfiguration] = useSessionStorageState<FilterConfiguration | undefined>(filterKey, undefined);
  useOnFilterChanged(filterKey, forceUpdate);

  const viewHandler = getViewDefinitionHandler(viewDefinition) as TimeseriesTableViewDefinitionHandler;
  const resolvedViewDefinition = viewHandler.getDefinition(store, viewDimensions);
  const timeseriesTableResolution = useDeepMemo(
    () => viewHandler.resolveView(store, {
      viewDimensions,
      parametersMapping,
      userId: loggedUserId,
      addedTimePoints,
      aclHandler,
      filterConfiguration,
    }),
    [store.getSerial(), viewDimensions, viewDefinition, parametersMapping, loggedUserId, addedTimePoints, filterConfiguration]
  );

  if (isResolutionError(timeseriesTableResolution)) {
    return <BlockViewError error={timeseriesTableResolution.error} />;
  } else {
    return (
      <div className={classes.paddedContainer}>
        <TimeseriesTableView
          parametersMapping={parametersMapping}
          viewDefinition={resolvedViewDefinition}
          filterKey={viewDefinition.id}
          readOnly={readOnly}
          viewResolution={timeseriesTableResolution}
          addTimePoint={(time) => setAddedTimePoints((current) => (current.includes(time) ? current : [...current, time]))}
          removeTimePoint={(time) => setAddedTimePoints((current) => current.filter((t) => t !== time))}
          removeAllTimePoints={() => setAddedTimePoints([])}
        />
      </div>
    );
  }
};

export default TimeseriesTableViewBlock;
