import type { FunctionComponent } from 'react';
import { AutomationRule, AutomationRule_UserId } from 'yooi-modules/modules/automationModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import BaseLayout from '../../../components/templates/BaseLayout';
import Header from '../../../components/templates/Header';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import HeaderStatic from '../../_global/HeaderStatic';
import TopBar from '../../_global/topBar/TopBar';
import AutomationRuleList from './AutomationRuleList';

const AdminAutomationRuleList: FunctionComponent = () => {
  const store = useStore();

  return (
    <BaseLayout
      topBar={(<TopBar />)}
      header={(<Header firstLine={(<HeaderStatic text={i18n`Automation`} />)} />)}
      content={(
        <AutomationRuleList
          ruleIds={
            store.getObject(AutomationRule)
              .navigateBack(Class_Instances)
              .filter((rule) => rule[AutomationRule_UserId] === undefined)
              .map(({ id }) => id)
          }
          onCreate={() => store.createObject({ [Instance_Of]: AutomationRule })}
          includeActionFilters
        />
      )}
    />
  );
};

export default AdminAutomationRuleList;
