import type { FunctionComponent } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { FILTER_PARAMETER_LOGGED_USER } from 'yooi-modules/modules/conceptModule';
import { joinObjects } from 'yooi-utils';
import useAuth from '../../../store/useAuth';
import useStore from '../../../store/useStore';
import useDeepMemo from '../../../utils/useDeepMemo';
import { getFieldHandler } from '../../_global/fields/FieldLibrary';

interface FieldWidgetProps {
  fieldId: string,
  parametersMapping: ParametersMapping,
  isPreview?: boolean,
  readOnly?: boolean,
}

const FieldWidget: FunctionComponent<FieldWidgetProps> = ({ fieldId, parametersMapping, isPreview = false, readOnly = false }) => {
  const store = useStore();
  const { loggedUserId } = useAuth();

  const updatedParameters = Object.entries(joinObjects(parametersMapping, { [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: loggedUserId } as const }))
    .reduce<ParametersMapping>((acc, [key, { type, id }]) => {
      // Yes, it look weird but we are testing value here, not key ;)
      if (id === FILTER_PARAMETER_LOGGED_USER) {
        acc[key] = { type: 'single', id: loggedUserId };
      } else {
        acc[key] = { type, id };
      }
      return acc;
    }, {});

  return useDeepMemo(
    () => getFieldHandler(store, fieldId)?.renderWidget?.({ parametersMapping: updatedParameters, isPreview, readOnly }),
    [fieldId, updatedParameters, isPreview, store.getSerial()]
  );
};

export default FieldWidget;
