import { errorToObject, joinObjects } from 'yooi-utils';
import buildInfo from './buildInfo';
import { fetchJSON } from './fetchUtils';
import i18n from './i18n';
import { notifyError } from './notify';

const reportToBackend = (path: string, data: { [key: string]: unknown }) => {
  const fireAndForget = async () => {
    try {
      const { status } = await fetchJSON(path, {
        method: 'POST',
        json: joinObjects(data, { sourceHash: buildInfo.sourceHash, reportTime: Date.now() }),
      });
      if (status === 200) {
        return;
      }
    } catch (_) {
      // ignored
    }
    notifyError(i18n`Error reporting failed`);
  };

  if ((navigator?.userAgent ?? '').includes('StatusCake')) {
    return;
  }
  fireAndForget();
};

const reportError = (level: 'trace' | 'error', error: unknown) => {
  reportToBackend(`/platform/report/${level}`, joinObjects(
    errorToObject(error),
    { path: window.location?.href }
  ));
};

export const reportClientTrace = (error: unknown): void => reportError('trace', error);

export const reportClientError = (error: unknown): void => reportError('error', error);

export const reportClientMetric = (clientId: string, key: string, data: { [key: string]: unknown }): void => {
  reportToBackend('/platform/report/metric', { clientId, key, data });
};
