import {
  Block_ConceptDefinition,
  Block_Name,
  Block_Parent,
  Block_Rank,
  Block_Type,
  BlockType_Section,
  BlockType_Tab,
  ConceptDefinition_Blocks,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { StoreObject } from 'yooi-store';
import { compareProperty, compareRank } from 'yooi-utils';
import { createHash } from '../../utils/historyUtils';
import { formatOrUndef } from '../../utils/stringUtils';

const computeHash = (block: StoreObject | undefined, filterFunction: (block: StoreObject) => boolean, initialSections: string[], prefix?: string): string => {
  if (!block) {
    return '';
  }
  const usedHash: Record<string, number> = {};
  const computeUniqueHash = (hash: string) => {
    const sanitizedHash = hash.toLowerCase();
    const lastHashCount = usedHash[sanitizedHash];
    usedHash[sanitizedHash] = (lastHashCount ?? 0) + 1;
    if (lastHashCount) {
      if (prefix) {
        return createHash(prefix, sanitizedHash, lastHashCount);
      } else {
        return createHash(sanitizedHash, undefined, lastHashCount);
      }
    } else if (prefix) {
      return createHash(prefix, sanitizedHash);
    } else {
      return createHash(sanitizedHash);
    }
  };

  initialSections.forEach(computeUniqueHash);

  if (block[Block_Type] === BlockType_Tab) {
    return block
      .navigate(Block_ConceptDefinition)
      .navigateBack(ConceptDefinition_Blocks)
      .sort(compareProperty(Block_Rank, compareRank))
      .filter(filterFunction)
      .map((b) => ({ id: b.id, hash: computeUniqueHash(formatOrUndef(b[Block_Name] as string | undefined)) }))
      .find(({ id }) => id === block.id)?.hash ?? '';
  } else {
    return block
      .navigate(Block_Parent)
      .navigateBack(Block_Parent)
      .sort(compareProperty(Block_Rank, compareRank))
      .filter(filterFunction)
      .map((b) => ({ id: b.id, hash: computeUniqueHash(formatOrUndef(b[Block_Name] as string | undefined)) }))
      .find(({ id }) => id === block.id)?.hash ?? '';
  }
};

export const computeTabHash = (block: StoreObject | undefined, initialSections: string[] = []): string => (
  computeHash(block, (b) => b[Block_Type] === BlockType_Tab, initialSections)
);

export const computeSectionHash = (block: StoreObject | undefined, prefix?: string, initialSections: string[] = []): string => (
  computeHash(block, (b) => b[Block_Type] === BlockType_Section, initialSections, prefix)
);
