import type { FunctionComponent } from 'react';
import type { DimensionsMapping } from 'yooi-modules/modules/conceptModule';
import {
  canWrite,
  hasPlatformCapability,
  isEmbeddedAsIntegrationOnly,
  ParsedDimensionType,
  parseDimensionMapping,
  textFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import { Concept_Name, PlatformCapabilityAdmin, User } from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { RichText } from 'yooi-utils';
import { richTextToText } from 'yooi-utils';
import useAcl from '../../../../store/useAcl';
import useActivity from '../../../../store/useActivity';
import useStore from '../../../../store/useStore';
import useUpdateActivity from '../../../../store/useUpdateActivity';
import type { FontVariant } from '../../../../theme/fontDefinition';
import i18n from '../../../../utils/i18n';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';
import { formatFieldResolutionErrorForUser } from '../../errorUtils';
import { getLastUpdateBy } from '../../historyUtils';
import StoreRichTextInputField from '../../input/StoreRichTextInputField';

interface TextFieldRendererProps {
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
  readOnly: boolean,
  focusOnMount: boolean,
  variant?: FontVariant,
}

const TextFieldRenderer: FunctionComponent<TextFieldRendererProps> = ({ fieldId, dimensionsMapping, readOnly, focusOnMount, variant }) => {
  const store = useStore();
  const { canWriteObject } = useAcl();

  const activity = useActivity();
  const updateActivity = useUpdateActivity();

  const usageVariant = useUsageContext();

  const parsedDimension = parseDimensionMapping(dimensionsMapping);

  const fieldHandler = textFieldHandler(store, fieldId);
  const configuration = fieldHandler.resolveConfiguration();

  const valueResolution = fieldHandler.getValueResolution(dimensionsMapping);
  const value = valueResolution.getDisplayValue()?.valueOf();

  const isReadOnly = readOnly
    || configuration.integrationOnly
    || configuration.formula !== undefined
    || !canWrite(dimensionsMapping, canWriteObject)
    || (parsedDimension.type === ParsedDimensionType.MonoDimensional && isEmbeddedAsIntegrationOnly(store.getObject(parsedDimension.objectId)))
    || (
      // We can remove this once we have ACL for properties
      parsedDimension.type === ParsedDimensionType.MonoDimensional
      && fieldId === Concept_Name
      && store.getObject(parsedDimension.objectId)[Instance_Of] === User
      && !hasPlatformCapability(store, store.getLoggedUserId(), PlatformCapabilityAdmin)
    );

  const onSubmit = (newValue: RichText | null) => {
    const skipUpdate = !value && (!newValue || richTextToText(newValue) === '');
    if (!skipUpdate) {
      fieldHandler.updateValue(dimensionsMapping, newValue);
    }
  };

  return (
    <StoreRichTextInputField
      initialValue={value}
      onSubmit={onSubmit}
      onEditionStart={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => updateActivity.onEnterEdition(parsedDimension.objectId, fieldId) : undefined}
      onEditionStop={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => updateActivity.onExitEdition(parsedDimension.objectId, fieldId) : undefined}
      isEditing={parsedDimension.type === ParsedDimensionType.MonoDimensional ? activity.listEditor(parsedDimension.objectId, fieldId).length > 0 : undefined}
      readOnly={isReadOnly}
      placeholder={usageVariant === UsageVariant.inTable ? undefined : i18n`Add text`}
      focusOnMount={focusOnMount}
      error={valueResolution.error ? formatFieldResolutionErrorForUser(store, valueResolution.error, fieldId) : undefined}
      restingTooltip={
        configuration.formula === undefined && parsedDimension.type === ParsedDimensionType.MonoDimensional
          ? () => getLastUpdateBy(store, parsedDimension.objectId, fieldId, undefined) : undefined
      }
      variant={variant}
    />
  );
};

export default TextFieldRenderer;
