import type { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import type { AutomationActionEmailStoreObject, AutomationActionGenerateDataStoreObject, AutomationRuleStoreObject } from 'yooi-modules/modules/automationModule';
import { AutomationAction, AutomationAction_Rule, AutomationActionGenerateData, AutomationRule } from 'yooi-modules/modules/automationModule/ids';
import type { BlockStoreObject, FieldBlockDisplayStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import { Block_ConceptDefinition, FieldBlockDisplay, FieldBlockDisplay_Block } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { ConceptDefinitionStoreObject, ConceptStoreObject, FieldStoreObject, WorkflowStoreObject } from 'yooi-modules/modules/conceptModule';
import { getAllFieldDimensionsLinkedConceptDefinitionIds } from 'yooi-modules/modules/conceptModule';
import { Concept, ConceptDefinition, Field, Workflow } from 'yooi-modules/modules/conceptModule/ids';
import type { IntegrationStoreObject } from 'yooi-modules/modules/integrationModule';
import { Integration } from 'yooi-modules/modules/integrationModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo, { TypoVariant } from '../../../../components/atoms/Typo';
import Link from '../../../../components/molecules/Link';
import { NavigationTitlePathElements } from '../../../../components/molecules/NavigationTitle';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import Header from '../../../../components/templates/Header';
import HeaderLine from '../../../../components/templates/HeaderLine';
import HeaderTabs from '../../../../components/templates/HeaderTabs';
import LeftPanel from '../../../../components/templates/LeftPanel';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import useNavigation, { NavigationElementContainer } from '../../../../utils/useNavigation';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../../utils/useSizeContext';
import type { NavigationFilter } from '../../../_global/navigationUtils';
import ExplorerMainContainer from '../_global/ExplorerMainContainer';
import { keyToId } from '../_global/explorerUtils';
import { useExplorerHint } from '../_global/GetHintContextProvider';
import ExplorerMasterDetail from './ExplorerMasterDetail';
import InstanceAsPropertyTab from './InstanceAsPropertyTab';
import InstanceAssociationsTab from './InstanceAssociationsTab';
import InstanceDetailTab from './InstanceDetailTab';
import InstanceEventsTab from './InstanceEventsTab';
import InstanceMentionsTab from './InstanceMentionsTab';
import InstanceTargetedByTab from './InstanceTargetedByTab';

const useStyles = makeStyles((theme) => ({
  titleTypoContainer: {
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
    marginLeft: spacingRem.s,
    marginRight: spacingRem.s,
  },
}), 'instanceDetailPage');

interface InstanceDetailPageProps {
  instanceKey: string,
}

const InstanceDetailPage: FunctionComponent<InstanceDetailPageProps> = ({ instanceKey }) => {
  const classes = useStyles();

  const store = useStore();

  const location = useLocation();
  const navigation = useNavigation<NavigationFilter>();

  const getHint = useExplorerHint();

  const decodedKey = decodeURIComponent(instanceKey);
  const instanceId = keyToId(decodedKey);

  const tabs = [
    {
      key: 'instance',
      name: i18n`Instance`,
      hash: '#instance',
      content: () => (<InstanceDetailTab instanceId={instanceId} />),
    },
  ];

  if (instanceId.length === 1) {
    tabs.push({
      key: 'associations',
      name: i18n`Associations`,
      hash: '#associations',
      content: () => (<InstanceAssociationsTab instanceId={instanceId[0]} />),
    });
    tabs.push({
      key: 'targetedBy',
      name: i18n`Targeted by`,
      hash: '#targetedBy',
      content: () => (<InstanceTargetedByTab instanceId={instanceId[0]} />),
    });
    tabs.push({
      key: 'mentions',
      name: i18n`Mentions`,
      hash: '#mentions',
      content: () => (<InstanceMentionsTab instanceId={instanceId[0]} />),
    });
    tabs.push({
      key: 'asProperty',
      name: i18n`Used as property`,
      hash: '#asProperty',
      content: () => (<InstanceAsPropertyTab instanceId={instanceId[0]} />),
    });
  }

  tabs.push({
    key: 'events',
    name: i18n`Events`,
    hash: '#events',
    content: () => (<InstanceEventsTab instanceId={instanceId} />),
  });

  const definitionIndex = tabs.findIndex(({ hash }) => hash === location.hash);
  const tabIndex = definitionIndex === -1 ? 0 : definitionIndex;

  const name = getHint(decodedKey) ?? decodedKey;

  let openInAppTo: string | undefined;
  if (instanceId.length === 1) {
    const instance = store.getObjectOrNull(instanceId[0]);
    if (instance) {
      if (isInstanceOf<ConceptStoreObject>(instance, Concept)) {
        openInAppTo = `/concept/${instance[Instance_Of]}/${instance.id}`;
      } else if (isInstanceOf<ConceptDefinitionStoreObject>(instance, ConceptDefinition)) {
        openInAppTo = `/settings/organization/${instance.id}`;
      } else if (isInstanceOf<FieldStoreObject>(instance, Field)) {
        const conceptDefinitionIds = getAllFieldDimensionsLinkedConceptDefinitionIds(store, instance.id);
        if (conceptDefinitionIds.length > 0) {
          openInAppTo = `/settings/organization/${conceptDefinitionIds[0]}/field/${instance.id}`;
        }
      } else if (isInstanceOf<AutomationRuleStoreObject>(instance, AutomationRule)) {
        openInAppTo = `/settings/automation/${instance.id}`;
      } else if (isInstanceOf<IntegrationStoreObject>(instance, Integration)) {
        openInAppTo = `/settings/integration/${instance.id}`;
      } else if (isInstanceOf<WorkflowStoreObject>(instance, Workflow)) {
        openInAppTo = `/settings/workflow/${instance.id}`;
      } else if (
        isInstanceOf<AutomationActionEmailStoreObject>(instance, AutomationAction)
        || isInstanceOf<AutomationActionGenerateDataStoreObject>(instance, AutomationActionGenerateData)
      ) {
        const ruleId = instance.navigateOrNull<AutomationRuleStoreObject>(AutomationAction_Rule)?.id;
        openInAppTo = ruleId ? `/settings/automation/${ruleId}` : '/settings/automation';
      } else if (isInstanceOf<FieldBlockDisplayStoreObject>(instance, FieldBlockDisplay)) {
        const conceptDefinitionId = instance.navigateOrNull<BlockStoreObject>(FieldBlockDisplay_Block)?.navigateOrNull(Block_ConceptDefinition)?.id;
        openInAppTo = conceptDefinitionId ? `/settings/organization/${conceptDefinitionId}` : undefined;
      }
    }
  }

  return (
    <NavigationElementContainer
      element={{
        key: `explorer:${decodedKey}`,
        name,
        element: NavigationTitlePathElements.explorerInstance,
        path: `/settings/explorer/instance/${decodedKey}`,
      }}
    >
      <ExplorerMainContainer
        leftPanel={(
          <LeftPanel>
            <ExplorerMasterDetail />
          </LeftPanel>
        )}
        header={(
          <Header
            firstLine={(
              <HeaderLine actions={openInAppTo ? (<Link title={i18n`Open in application`} to={openInAppTo} />) : undefined}>
                <div className={classes.titleTypoContainer}>
                  <SizeContextProvider sizeVariant={SizeVariant.title} hierarchyVariant={HierarchyVariant.content}>
                    <SpacingLine>
                      <Tooltip title={name ?? decodedKey}>
                        <Typo maxLine={1} variant={TypoVariant.display}>{name ?? decodedKey}</Typo>
                      </Tooltip>
                      {
                        store.getObjectOrNull(instanceId)
                          ? null
                          : (<Icon name={IconName.dangerous} colorVariant={IconColorVariant.error} tooltip={i18n`Deleted instance`} />)
                      }
                    </SpacingLine>
                  </SizeContextProvider>
                </div>
              </HeaderLine>
            )}
            tabsLine={(
              <HeaderTabs
                tabs={tabs}
                selectedTabIndex={tabIndex}
                onSelectedIndexChanged={(value, { isCtrlKeyPressed, isMiddleClick }) => {
                  if (isCtrlKeyPressed || isMiddleClick) {
                    window.open(`${location.pathname}${tabs[value].hash}`, '_blank', 'noopener');
                  } else {
                    navigation.replace(`explorer:${decodedKey}`, { hash: tabs[value].hash });
                  }
                }}
              />
            )}
          />
        )}
        content={tabs[tabIndex].content()}
      />
    </NavigationElementContainer>
  );
};

export default InstanceDetailPage;
