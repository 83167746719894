import type { FunctionComponent } from 'react';
import { useState } from 'react';
import Icon, { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import Typo, { TypoVariant } from '../../../components/atoms/Typo';
import Chip from '../../../components/molecules/Chip';
import InlineLink from '../../../components/molecules/InlineLink';
import SpacedContainer from '../../../components/molecules/SpacedContainer';
import Tabs from '../../../components/molecules/Tabs';
import type { TreeNodeEntry } from '../../../components/molecules/Tree';
import Tree from '../../../components/molecules/Tree';
import { buildPadding, Spacing, spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { notifySuccess } from '../../../utils/notify';
import { SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import useTheme from '../../../utils/useTheme';

const useStyles = makeStyles({
  tabContainer: {
    width: '30rem',
    minHeight: '32rem',
    paddingBottom: spacingRem.splus,
    paddingTop: spacingRem.m,
  },
  functionContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  nodeElement: {
    marginRight: spacingRem.s,
    cursor: 'pointer',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: spacingRem.s,
  },
}, 'templateTree');

interface TemplateFunction {
  key: string,
  label: string,
  value: string,
}

const copyFunction = (value: string) => {
  navigator.clipboard.writeText(value);
  notifySuccess(i18n`Function copied to clipboard`);
};

const templateFunctions: TemplateFunction[] = [
  {
    key: 'if_function',
    label: 'if',
    value: '{% if variable %}\n  It is true\n{% endif %}',
  },
  {
    key: 'ifelse_function',
    label: 'if else',
    value: '{% if variable %}\n  It is true\n{% else %}\n  It is false\n{% endif %}',
  },
  {
    key: 'for_function',
    label: 'for',
    value: '{% for item in items %}\n  <li>{{ item.title }}</li>\n{% else %}\n  <li>This would display if the \'item\' collection were empty</li>\n{% endfor %}',
  },
];

interface TemplateTreeProps {
  getTreeData: () => TreeNodeEntry[],
}

const TemplateTree: FunctionComponent<TemplateTreeProps> = ({ getTreeData }) => {
  const theme = useTheme();
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(0);
  const tabs = [
    {
      key: 'variables',
      name: i18n`Variables`,
      content: () => (
        <div className={classes.tabContainer}>
          <Tree data={getTreeData()} search treeInfo={i18n`Only fields with a valid API alias are available below. Click on any of them to copy its path.`} />
        </div>
      ),
    },
    {
      key: 'tags',
      name: i18n`Tags`,
      content: () => (
        <div className={classes.tabContainer}>
          <div className={classes.functionContainer}>
            {templateFunctions.map((templateFunction) => (
              <div
                key={templateFunction.key}
                className={classes.nodeElement}
                tabIndex={0}
                onClick={() => {
                  copyFunction(templateFunction.value);
                }}
                onKeyDown={() => {}}
                role="button"
                aria-label={i18n`Copy tag`}
              >
                <Chip
                  key={`${templateFunction.key}_chip`}
                  tooltip={i18n`Copy tag`}
                  text={templateFunction.label}
                />
              </div>
            ))}
          </div>
          <div className={classes.infoContainer}>
            <Icon
              colorVariant={IconColorVariant.secondary}
              name={IconName.info}
            />
            <SpacedContainer margin={{ x: Spacing.s, top: Spacing.s }}>
              <Typo color={theme.color.text.disabled}>
                {i18n`Please use `}
                <InlineLink to="https://mozilla.github.io/nunjucks/templating.html#tags">{i18n`Nunjucks documentation`}</InlineLink>
                {i18n` to know more about available tags and how to use them.`}
              </Typo>
            </SpacedContainer>
          </div>
        </div>
      ),
    },
  ];
  return (
    <SizeContextProvider sizeVariant={SizeVariant.small}>
      <Tabs
        tabs={tabs}
        selectedTabIndex={tabIndex}
        sizeVariant={SizeVariant.small}
        typoVariant={TypoVariant.blockInlineTitle}
        onSelectedIndexChanged={(newIndex: number) => {
          if (newIndex !== undefined) {
            setTabIndex(newIndex);
          }
        }}
        tabStyle={{ ...buildPadding({ x: Spacing.xs }) }}
      />
      {tabs[tabIndex].content()}
    </SizeContextProvider>
  );
};

export default TemplateTree;
