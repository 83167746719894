import { Concept, ConceptDefinition, ConceptDefinition_IsCore } from '../../conceptModule/ids';
import { registerModel } from '../module';

const { type } = registerModel;

type({
  label: 'Reference',
  extends: Concept,
  instanceOf: ConceptDefinition,
  extraProperties: {
    [ConceptDefinition_IsCore]: true,
  },
});
