import type { FunctionComponent } from 'react';
import type { ConceptDefinitionStoreObject } from 'yooi-modules/modules/conceptModule';
import { isConceptDefinitionField } from 'yooi-modules/modules/conceptModule';
import {
  ConceptDefinition_LibraryShowTimeline,
  ConceptDefinition_Timeline,
  ConceptDefinition_TimelineDependency,
  ConceptDefinition_TimelineGroupBy,
  ConceptDefinition_TimelineProgress,
} from 'yooi-modules/modules/conceptModule/ids';
import Checkbox from '../../../../../components/atoms/Checkbox';
import { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import BlockContent from '../../../../../components/templates/BlockContent';
import BlockTitle from '../../../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../../../components/templates/HorizontalBlock';
import useStore from '../../../../../store/useStore';
import i18n from '../../../../../utils/i18n';
import { getFieldChip } from '../../../../_global/fieldUtils';
import { listDependenciesFieldOptions, listGroupByFieldOptions, listProgressFieldOptions, listTimelineFieldOptions } from '../../../../_global/modelTypeUtils';

interface TimelineEditorProps {
  conceptDefinitionId: string,
}

const TimelineEditor: FunctionComponent<TimelineEditorProps> = ({ conceptDefinitionId }) => {
  const store = useStore();

  const conceptDefinition = store.getObject<ConceptDefinitionStoreObject>(conceptDefinitionId);

  const defaultTimelineFieldId = conceptDefinition.navigateOrNull(ConceptDefinition_Timeline)?.id;
  const defaultTimelineProgressFieldId = conceptDefinition.navigateOrNull(ConceptDefinition_TimelineProgress)?.id;
  const defaultTimelineDependencyFieldId = conceptDefinition.navigateOrNull(ConceptDefinition_TimelineDependency)?.id;
  const defaultTimelineGroupByFieldId = conceptDefinition.navigateOrNull(ConceptDefinition_TimelineGroupBy)?.id;

  return (
    <>
      <HorizontalBlock asBlockContent>
        <BlockTitle
          title={i18n`Show timeline`}
          infoTooltip={i18n`Show / hide library timeline tab`}
        />
        <BlockContent padded>
          <SpacingLine>
            <Checkbox
              checked={Boolean(conceptDefinition[ConceptDefinition_LibraryShowTimeline])}
              onChange={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_LibraryShowTimeline]: value })}
            />
          </SpacingLine>
        </BlockContent>
      </HorizontalBlock>
      {Boolean(conceptDefinition[ConceptDefinition_LibraryShowTimeline]) && (
        <>
          <HorizontalBlock asBlockContent>
            <BlockTitle
              title={i18n`Default field for timeline`}
              infoTooltip={i18n`Date range field used to display each instance on the timeline, by default`}
            />
            <BlockContent>
              <SearchAndSelect
                clearable
                placeholder={i18n`Select field`}
                selectedOption={
                  defaultTimelineFieldId && store.getObjectOrNull(defaultTimelineFieldId) !== null
                    ? getFieldChip(store, conceptDefinitionId, defaultTimelineFieldId) : undefined
                }
                computeOptions={() => listTimelineFieldOptions(store, conceptDefinitionId)}
                onSelect={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_Timeline]: value?.id ?? null })}
                statusIcon={
                  !defaultTimelineFieldId || isConceptDefinitionField(store, defaultTimelineFieldId, conceptDefinitionId)
                    ? undefined : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
                }
              />
            </BlockContent>
          </HorizontalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle
              title={i18n`Default field for progress`}
              infoTooltip={i18n`Numeric field used to display the progress of each instance on the timeline, by default`}
            />
            <BlockContent>
              <SearchAndSelect
                clearable
                placeholder={i18n`Select field`}
                selectedOption={
                  defaultTimelineProgressFieldId && store.getObjectOrNull(defaultTimelineProgressFieldId) !== null
                    ? getFieldChip(store, conceptDefinitionId, defaultTimelineProgressFieldId) : undefined
                }
                computeOptions={() => listProgressFieldOptions(store, conceptDefinitionId)}
                onSelect={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_TimelineProgress]: value?.id ?? null })}
                statusIcon={
                  !defaultTimelineProgressFieldId || isConceptDefinitionField(store, defaultTimelineProgressFieldId, conceptDefinitionId)
                    ? undefined : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
                }
              />
            </BlockContent>
          </HorizontalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle
              title={i18n`Default field for dependency`}
              infoTooltip={i18n`Relation used to display the dependencies of each instance on the timeline, by default`}
            />
            <BlockContent>
              <SearchAndSelect
                clearable
                placeholder={i18n`Select field`}
                selectedOption={
                  defaultTimelineDependencyFieldId && store.getObjectOrNull(defaultTimelineDependencyFieldId) !== null
                    ? getFieldChip(store, conceptDefinitionId, defaultTimelineDependencyFieldId) : undefined
                }
                computeOptions={() => listDependenciesFieldOptions(store, conceptDefinitionId)}
                onSelect={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_TimelineDependency]: value?.id ?? null })}
                statusIcon={
                  !defaultTimelineDependencyFieldId || isConceptDefinitionField(store, defaultTimelineDependencyFieldId, conceptDefinitionId)
                    ? undefined : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
                }
              />
            </BlockContent>
          </HorizontalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle
              title={i18n`Default field for group by`}
              infoTooltip={i18n`Relation used to group the instances on the timeline, by default`}
            />
            <BlockContent>
              <SearchAndSelect
                clearable
                placeholder={i18n`Select field`}
                selectedOption={
                  defaultTimelineGroupByFieldId && store.getObjectOrNull(defaultTimelineGroupByFieldId) !== null
                    ? getFieldChip(store, conceptDefinitionId, defaultTimelineGroupByFieldId) : undefined
                }
                computeOptions={() => listGroupByFieldOptions(store, conceptDefinitionId, true)}
                onSelect={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_TimelineGroupBy]: value?.id ?? null })}
                statusIcon={
                  !defaultTimelineGroupByFieldId || isConceptDefinitionField(store, defaultTimelineGroupByFieldId, conceptDefinitionId)
                    ? undefined : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
                }
              />
            </BlockContent>
          </HorizontalBlock>
        </>
      )}
    </>
  );
};

export default TimelineEditor;
