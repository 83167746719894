import type { KinshipRelationFieldStoreObject, SingleParameterDefinition, RelationSingleFieldStoreObject, FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { getConceptDefinitionValidFields, getFieldUtilsHandler } from 'yooi-modules/modules/conceptModule';
import {
  ColorField,
  EmbeddingField,
  EmbeddingField_FromType,
  Field_Title,
  FieldDefinition_Title,
  KinshipRelationField,
  RelationSingleField,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import type { StoreObject } from 'yooi-store';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import type { FrontObjectStore } from '../../../../store/useStore';
import { getFieldLabel } from '../../fieldUtils';
import type { Option } from '../../modelTypeUtils';
import { defaultOptionComparator, getChipOptions, getParameterOption } from '../../modelTypeUtils';
import type { ChipOption } from '../../modelTypeUtilsType';
import { getFieldConfigurationHandler } from '../FieldLibrary';

export const computePathOptions = (
  store: FrontObjectStore,
  conceptDefinitionId: string,
  path: string [],
  fieldTypeIds: string [],
  filterFunction: ((v: StoreObject) => boolean) = () => true
): ChipOption[] => {
  let type;
  const lastPathElementId = path?.[path.length - 1];
  if (!lastPathElementId) {
    type = store.getObject(conceptDefinitionId);
  } else if (isInstanceOf(store.getObject(lastPathElementId), RelationSingleField)) {
    type = getFieldUtilsHandler(store, lastPathElementId)?.getTargetType?.();
    if (!type) {
      return [];
    }
  } else if (isInstanceOf(store.getObject(lastPathElementId), KinshipRelationField)) {
    const types = getFieldUtilsHandler(store, lastPathElementId)?.getTargetTypes?.(path.length > 1 ? path?.[path.length - 2] : conceptDefinitionId);
    if (types && types.length > 0) {
      const fields = getFieldConfigurationHandler(store, lastPathElementId).getTargetFields?.(path.length > 1 ? path?.[path.length - 2] : conceptDefinitionId) ?? [];
      return fields.map((field) => {
        const option = typeof field[EmbeddingField_FromType] === 'string' ? getChipOptions(store, field[EmbeddingField_FromType]) : undefined;
        if (option) {
          return joinObjects(option, { label: `${option.label} (${field[Field_Title]})`, id: field.id });
        } else {
          return undefined;
        }
      })
        .filter(filterNullOrUndefined)
        .sort(defaultOptionComparator);
    } else {
      return [];
    }
  } else if (isInstanceOf(store.getObject(lastPathElementId), EmbeddingField)) {
    type = store.getObject(lastPathElementId).navigate(EmbeddingField_FromType);
  } else {
    return [];
  }
  return getConceptDefinitionValidFields(store, type.id)
    .filter((instance) => filterFunction(instance))
    .filter((instance) => fieldTypeIds.some((typeId) => isInstanceOf(instance, typeId))
      || isInstanceOf(instance, RelationSingleField) || isInstanceOf(instance, KinshipRelationField))
    .map(({ id: instanceId }) => getChipOptions(store, instanceId))
    .filter(filterNullOrUndefined)
    .sort(defaultOptionComparator);
};

export const filterPath = (
  store: FrontObjectStore,
  path: string[] | undefined,
  fieldTypeIds: string[]
): { error?: string, path: string[] } => {
  const error = `Last element should be one of ${fieldTypeIds.map((id) => store.getObject(id)[FieldDefinition_Title]).join(', ')} field`;
  if (!path || path.length === 0) {
    return { error, path: [] };
  }
  const filteredPath = [];
  for (let i = 0; i < path.length; i += 1) {
    const pathElement = path[i];
    const pathInstance = store.getObjectOrNull(pathElement);
    if (!pathInstance) {
      return { error, path: filteredPath };
    } else if (isInstanceOf(pathInstance, RelationSingleField) || isInstanceOf(pathInstance, KinshipRelationField)) {
      filteredPath.push(pathElement);
    } else if (isInstanceOf(pathInstance, EmbeddingField)) {
      filteredPath.push(pathElement);
    } else {
      const expectedTypeId = fieldTypeIds.filter((fieldTypeId) => isInstanceOf(pathInstance, fieldTypeId))[0];
      if (expectedTypeId) {
        filteredPath.push(pathElement);
        return { path: filteredPath };
      }
      return { error, path: filteredPath };
    }
  }
  return { error, path: filteredPath };
};

export const getLastConcept = (store: FrontObjectStore, instance: StoreObject, path: string[]): StoreObject | null => {
  let lastConcept: StoreObject | null = instance;
  for (let i = 0; i < path.length; i += 1) {
    const elem = store.getObjectOrNull(path[i]);
    if (elem) {
      if (isInstanceOf<RelationSingleFieldStoreObject>(elem, RelationSingleField)) {
        if (lastConcept) {
          lastConcept = lastConcept.navigateOrNull(elem.id);
        } else {
          lastConcept = null;
        }
      } else if (isInstanceOf<KinshipRelationFieldStoreObject>(elem, KinshipRelationField)) {
        if (lastConcept) {
          lastConcept = lastConcept.navigateOrNull(lastConcept[elem.id] as string);
        } else {
          lastConcept = null;
        }
      }
    }
  }
  return lastConcept;
};

export const getSeriesLabel = (store: FrontObjectStore, label: string | undefined, valuePath: string[], seriesIndex: number): string => {
  const defaultLabel = `Series ${seriesIndex + 1}`;
  const lastElementId = valuePath.at(-1);
  const lastElement = lastElementId ? store.getObjectOrNull<FieldStoreObject>(lastElementId) : undefined;
  if (!label && lastElement) {
    return getFieldLabel(store, lastElement);
  }
  return !label ? defaultLabel : label;
};

export const getFilteredPathOptions = ({
  store,
  instanceId,
  parameterDefinitions,
}: { store: FrontObjectStore, instanceId: string, parameterDefinitions: SingleParameterDefinition[], modelTypeId: string }): Option | undefined => {
  if (store.getObjectOrNull(instanceId)) {
    if (isInstanceOf(store.getObject(instanceId), EmbeddingField)) {
      const field = store.getObject(instanceId);
      const option = typeof field[EmbeddingField_FromType] === 'string' ? getChipOptions(store, field[EmbeddingField_FromType]) : undefined;
      if (option) {
        return joinObjects(option, { label: `${option?.label} (${field[Field_Title]})`, id: field.id });
      } else {
        return undefined;
      }
    } else {
      return getChipOptions(store, instanceId);
    }
  } else {
    const parameter = parameterDefinitions.find(({ id }) => id === instanceId);
    if (parameter) {
      return getParameterOption(store, parameter);
    }
  }
  return undefined;
};

export const ChartColorPathAcceptedFields = [ColorField];
