import { User } from '../../conceptModule/ids';
import type { AutomationRuleTrigger, AutomationRuleTriggerHandler, TriggerHandler } from './triggerHandler';
import { formatEventOrigin, TriggerType } from './utils';

export enum InstanceEventTriggerWhen {
  delete = 'delete',
  create = 'create',
  anyUpdate = 'anyUpdate',
  updatedField = 'updatedField',
  workflowTransition = 'workflowTransition',
}

export interface InstanceEventTrigger extends AutomationRuleTrigger {
  type: TriggerType.event,
  on: string,
  when: InstanceEventTriggerWhen,
}

export interface FieldUpdateInstanceEventTrigger extends InstanceEventTrigger {
  when: InstanceEventTriggerWhen.updatedField,
  updates?: string[],
}

export enum WorkflowListeningType {
  main = 'main',
  fields = 'fields',
}

export interface WorkflowTransitionInstanceEventTrigger extends InstanceEventTrigger {
  when: InstanceEventTriggerWhen.workflowTransition,
  workflow?: string,
  listeningType?: WorkflowListeningType[],
  fields?: string[],
  transitions?: string[],
}

export const INSTANCE_EVENT_TRIGGER_TRIGGERING_INSTANCE_PARAMETER = 'TRIGGERING_INSTANCE_PARAMETER';
export const INSTANCE_EVENT_TRIGGER_TRIGGERING_USER_PARAMETER = 'TRIGGERING_USER_PARAMETER';

export const isInstanceEventTrigger = (trigger: AutomationRuleTrigger | undefined): trigger is InstanceEventTrigger => trigger?.type === TriggerType.event;

export const isFieldUpdateEventTrigger = (trigger: AutomationRuleTrigger | undefined): trigger is FieldUpdateInstanceEventTrigger => isInstanceEventTrigger(trigger)
  && Boolean(trigger.on)
  && trigger.when === InstanceEventTriggerWhen.updatedField;

export const isWorkflowTransitionEventTrigger = (trigger: AutomationRuleTrigger | undefined): trigger is WorkflowTransitionInstanceEventTrigger => isInstanceEventTrigger(trigger)
  && Boolean(trigger.on)
  && trigger.when === InstanceEventTriggerWhen.workflowTransition;

export interface InstanceEventTriggerHandler extends AutomationRuleTriggerHandler {
  parameterDefinitions: {
    [INSTANCE_EVENT_TRIGGER_TRIGGERING_USER_PARAMETER]: { type: 'parameter' },
    [INSTANCE_EVENT_TRIGGER_TRIGGERING_INSTANCE_PARAMETER]: { type: 'parameter' },
  },
  mailActionQuery: {
    triggering_origin: { type: 'simple' },
  },
}

export const createInstanceEventTriggerHandler = (trigger: InstanceEventTrigger): TriggerHandler<InstanceEventTriggerHandler> => ({
  parameterDefinitions: {
    [INSTANCE_EVENT_TRIGGER_TRIGGERING_USER_PARAMETER]: { id: INSTANCE_EVENT_TRIGGER_TRIGGERING_USER_PARAMETER, label: 'Triggering user', typeId: User, type: 'parameter' },
    [INSTANCE_EVENT_TRIGGER_TRIGGERING_INSTANCE_PARAMETER]: trigger.when === InstanceEventTriggerWhen.create
    || isFieldUpdateEventTrigger(trigger)
    || trigger.when === InstanceEventTriggerWhen.anyUpdate
    || isWorkflowTransitionEventTrigger(trigger)
      ? { id: INSTANCE_EVENT_TRIGGER_TRIGGERING_INSTANCE_PARAMETER, typeId: trigger.on, label: 'Triggering instance', type: 'parameter' }
      : undefined,
  },
  mailActionQuery: {
    triggering_origin: {
      type: 'constant',
      getValue: ({ store, eventOrigin }) => (eventOrigin ? formatEventOrigin(store, eventOrigin) : undefined),
    },
  },
});
