import type { FunctionComponent } from 'react';
import { useState } from 'react';
import Button, { ButtonVariant } from '../../../components/atoms/Button';
import { IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import Tooltip from '../../../components/atoms/Tooltip';
import Typo, { TypoVariant } from '../../../components/atoms/Typo';
import InlineLoading from '../../../components/molecules/InlineLoading';
import SpacingLine from '../../../components/molecules/SpacingLine';
import TagContainer from '../../../components/molecules/TagContainer';
import Toggle from '../../../components/molecules/Toggle';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import useTheme from '../../../utils/useTheme';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.s,
  },
}, 'toggleBlock');

export interface ToggleEntry {
  id: string,
  name: string,
  color: string | undefined,
  backgroundColor: string | undefined,
}

interface ToggleBlockProps {
  title: string,
  toggles: ToggleEntry[],
  activeIds: string[],
  onToggle: (id: string) => void,
  onClear: () => void,
  tooltip: string,
  loading?: boolean,
}

const renderToggles = (toggles: ToggleEntry[], activeIds: string[], onToggle: (id: string) => void) => (
  <TagContainer>
    {
      toggles
        .map(({ id, name, color, backgroundColor }) => (
          <Toggle
            key={id}
            text={name}
            isActive={activeIds.includes(id)}
            color={color}
            backgroundColor={backgroundColor}
            onClick={() => onToggle(id)}
          />
        ))
    }
  </TagContainer>
);

const ToggleBlock: FunctionComponent<ToggleBlockProps> = ({ title, toggles, activeIds, onToggle, onClear, tooltip, loading = false }) => {
  const theme = useTheme();
  const classes = useStyles();

  const [showAll, setShowAll] = useState(false);

  let buttonTitle = i18n`Hide`;
  if (!showAll) {
    const extraActiveToggles = toggles.slice(10).filter(({ id }) => activeIds.includes(id));
    if (extraActiveToggles.length > 0) {
      buttonTitle = i18n`Show all (${extraActiveToggles.length} other selected)`;
    } else {
      buttonTitle = i18n`Show all`;
    }
  }

  return (
    <SizeContextProvider sizeVariant={SizeVariant.small}>
      <div className={classes.container}>
        <SpacingLine>
          <Tooltip title={tooltip}>
            <Typo color={theme.color.text.secondary} maxLine={1} variant={TypoVariant.small}>{title}</Typo>
          </Tooltip>
          {activeIds.length > 0 && (
            <IconOnlyButton tooltip={i18n`Clear filters`} iconName={IconName.delete} onClick={onClear} variant={IconOnlyButtonVariants.secondary} />
          )}
          {loading && (<InlineLoading />)}
        </SpacingLine>
        {renderToggles(toggles.slice(0, toggles.length > 15 ? 10 : 15), activeIds, onToggle)}
        {toggles.length > 15 ? (
          <span>
            <Button
              iconName={showAll ? IconName.expand_more : IconName.keyboard_arrow_right}
              title={buttonTitle}
              variant={ButtonVariant.tertiary}
              onClick={() => {
                setShowAll((current) => !current);
              }}
            />
          </span>
        ) : null}
        {showAll && toggles.length > 15 ? renderToggles(toggles.slice(10), activeIds, onToggle) : null}
      </div>
    </SizeContextProvider>
  );
};

export default ToggleBlock;
