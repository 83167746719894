import type { FunctionComponent } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { RadarChartFieldConfiguration } from 'yooi-modules/modules/dashboardModule';
import { RadarChartField_Configuration } from 'yooi-modules/modules/dashboardModule/ids';
import RadarChart from '../../../../components/charts/RadarChart/RadarChart';
import ScrollableWidgetContainer, {
  SCROLLABLE_WIDGET_CONTAINER_FULL_HEIGHT_PADDING,
  SCROLLABLE_WIDGET_CONTAINER_FULL_WIDTH_PADDING,
} from '../../../../components/templates/ScrollableWidgetContainer';
import useStore from '../../../../store/useStore';
import { remToPx } from '../../../../utils/sizeUtils';
import useWidgetSizeContext from '../../../../utils/useWidgetSizeContext';
import WidgetInvalidConfiguration from '../_global/WidgetInvalidConfiguration';
import { filterPath } from '../_global/widgetSeriesUtils';
import { ChartLabelPathAcceptedFields, computeRadarChartFieldSeries } from './radarChartFieldUtils';
import RadarChartTooltip from './RadarChartTooltip';

interface RadarChartWidgetProps {
  fieldId: string,
  parametersMapping: ParametersMapping,
}

const RadarChartWidget: FunctionComponent<RadarChartWidgetProps> = ({ fieldId, parametersMapping }) => {
  const store = useStore();

  const { width, height } = useWidgetSizeContext();

  const field = store.getObject(fieldId);
  const configuration = field[RadarChartField_Configuration] as RadarChartFieldConfiguration | undefined;

  const {
    path: labelFilteredPath,
  } = filterPath(store, configuration?.labelPath, ChartLabelPathAcceptedFields);

  const series = computeRadarChartFieldSeries(store, fieldId, parametersMapping);

  if (!series) {
    return <WidgetInvalidConfiguration width={width} height={height} />;
  }

  return (
    <ScrollableWidgetContainer width={width} height={height}>
      <RadarChart
        height={height - remToPx(SCROLLABLE_WIDGET_CONTAINER_FULL_HEIGHT_PADDING)}
        width={width - remToPx(SCROLLABLE_WIDGET_CONTAINER_FULL_WIDTH_PADDING)}
        instanceData={series.instanceData}
        fieldData={series.fieldData}
        withLegend={Boolean(labelFilteredPath.length)}
        renderTooltip={(instanceId, seriesIds, color) => <RadarChartTooltip fieldId={fieldId} instanceId={instanceId} seriesIds={seriesIds} color={color} />}
      />
    </ScrollableWidgetContainer>
  );
};

export default RadarChartWidget;
