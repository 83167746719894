import type { TimeRange, TimeseriesValue } from 'yooi-store';
import type { DateRange } from 'yooi-utils';
import { compareNumber, computeEffectiveRangeForPeriodAndDates, getFormattedTextDateByPeriod, joinObjects, periodicities, PeriodicityType } from 'yooi-utils';
import type { ExcelValueType } from '../../../common/fields/FieldModuleDslType';
import type { DimensionsMapping, MultipleParameterDefinition, SingleParameterDefinition } from '../../utils';

export interface TimeseriesExportConfiguration {
  granularity?: PeriodicityType,
  dateRange?: DateRange,
  withEmptyValue?: boolean,
  hideNonAlignValue?: boolean,
  time?: number,
}

export const getTimeseriesExportColumnsHeaders = <T extends TimeseriesValue>(
  getValueResolution: (dimensionMapping: DimensionsMapping, timeRange: TimeRange) => T[] | undefined) => (
  configuration: TimeseriesExportConfiguration | undefined,
  fieldLabel: string,
  _: (SingleParameterDefinition | MultipleParameterDefinition)[],
  getDimensionMappings: () => DimensionsMapping[]
): {
  columnsNumber: number,
  getHeaders: (columnIndex: number) => ExcelValueType[],
  getColumnConfiguration: (columnIndex: number) => TimeseriesExportConfiguration | undefined,
} | { error: string } => {
    const granularity = configuration?.granularity ?? PeriodicityType.day;
    const { from, to } = configuration?.dateRange?.from && configuration?.dateRange?.to
      ? computeEffectiveRangeForPeriodAndDates(configuration?.dateRange.period, configuration?.dateRange.from, configuration?.dateRange.to)
      : { from: undefined, to: undefined };
    if (!from || !to) {
      return {
        error: 'invalid time range',
      };
    }

    const dimensionMappings = getDimensionMappings();
    const times = new Set<number>();
    for (let i = 0; i < dimensionMappings.length; i += 1) {
      const dimensionMapping = dimensionMappings[i];
      const value = getValueResolution(dimensionMapping, { from: from.getTime(), to: to.getTime() });
      if (value !== undefined) {
        value.forEach(({ time }) => {
          if (!times.has(time) && time >= from.getTime() && time <= to.getTime()
          && (!configuration?.hideNonAlignValue || periodicities[granularity].getStartOfPeriod(new Date(time))
            .getTime() === time)) {
            times.add(time);
          }
        });
      }
    }
    if (configuration?.withEmptyValue) {
      let currentDate = periodicities[granularity].getStartOfPeriod(from);
      while (currentDate.getTime() <= to.getTime()) {
        if (currentDate.getTime() >= from.getTime() && !times.has(currentDate.getTime())) {
          times.add(currentDate.getTime());
        }
        // Using getPreviousDateInAmountOfPeriod as getNextDateInAmountOfPeriod return the end of the period
        currentDate = periodicities[granularity].getPreviousDateInAmountOfPeriod(currentDate, -1);
      }
    }

    const sortedTimes = Array.from(times).sort(compareNumber);

    return {
      columnsNumber: sortedTimes.length,
      getHeaders: (columnIndex: number) => [{
        format: 'string' as const,
        value: `${fieldLabel} - ${getFormattedTextDateByPeriod(new Date(sortedTimes[columnIndex]), granularity)}`,
      }, { format: 'date' as const, value: sortedTimes[columnIndex], period: granularity }],
      getColumnConfiguration: (columnIndex: number) => joinObjects(configuration, { time: sortedTimes[columnIndex] }),
    };
  };
