import { ticks } from 'd3-array';
import type { ScaleContinuousNumeric } from 'd3-scale';
import type { FunctionComponent } from 'react';
import type { AxisBaseTick, AxisDirection as NumberAxisDirection } from './Axis';
import Axis, { AxisVariant as NumberAxisVariant } from './Axis';

export { AxisDirection as NumberAxisDirection } from './Axis';
export { AxisVariant as NumberAxisVariant } from './Axis';

const generateTicks = (scale: ScaleContinuousNumeric<number, number>, ellipsed: boolean): AxisBaseTick[] => {
  const [startValue, endValue] = scale.domain();
  const axisTicks: AxisBaseTick[] = [];
  ticks(startValue, endValue, 10).forEach((value, index) => {
    axisTicks.push({
      id: `${index}`,
      label: Intl.NumberFormat('en', { notation: 'compact' }).format(value),
      position: scale(value),
      ellipsed,
    });
  });
  return axisTicks;
};

interface NumberAxisProps {
  scale: ScaleContinuousNumeric<number, number>,
  initialCoordinate?: number,
  direction: NumberAxisDirection,
  axisName: string | undefined,
  variant?: NumberAxisVariant,
  showArrow?: boolean,
  axisEdgeMargin?: number,
  ellipseLabel?: boolean,
}

const NumberAxis: FunctionComponent<NumberAxisProps> = ({
  scale,
  initialCoordinate,
  direction,
  axisName,
  variant = NumberAxisVariant.black,
  showArrow,
  axisEdgeMargin,
  ellipseLabel = false,
}) => (
  <Axis
    scale={scale}
    initialCoordinate={initialCoordinate}
    axisEdgeMargin={axisEdgeMargin}
    axisName={axisName}
    direction={direction}
    ticks={generateTicks(scale, ellipseLabel)}
    variant={variant}
    showArrow={showArrow}
  />
);

export default NumberAxis;
