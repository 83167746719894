import { fromByteArray, toByteArray } from 'base64-js';

export const base64 = (() => {
  if (typeof Buffer === 'function') {
    return {
      decode: (str: string) => Buffer.from(str, 'base64').toString(),
      encode: (str: string) => Buffer.from(str).toString('base64'),
    };
  } else if (typeof atob === 'function' && typeof btoa === 'function') {
    return {
      decode: (str: string) => new TextDecoder().decode(toByteArray(str)),
      encode: (str: string) => fromByteArray(new TextEncoder().encode(str)),
    };
  } else {
    throw new Error('Failed to find native base64 encoder');
  }
})();
