import type { FunctionComponent } from 'react';
import { getFieldDimensionOfModelType, hasPlatformCapability } from 'yooi-modules/modules/conceptModule';
import {
  Field_Title,
  PlatformCapabilityAdmin,
  User,
  User_Email,
  User_Groups,
  User_IsEnabled,
  User_Type,
  UserGroupMembershipDimension,
} from 'yooi-modules/modules/conceptModule/ids';
import { IconName } from '../../../components/atoms/Icon';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useAuth from '../../../store/useAuth';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { getFieldHandler } from '../../_global/fields/FieldLibrary';

interface AdminUserDetailProps {
  userId: string,
}

const AdminUserDetail: FunctionComponent<AdminUserDetailProps> = ({ userId }) => {
  const store = useStore();
  const { loggedUserId } = useAuth();
  const isAdmin = hasPlatformCapability(store, loggedUserId, PlatformCapabilityAdmin);

  const accountTypeOptions = [{ id: 'sso', label: i18n`SSO` }, { id: 'internal', label: i18n`Internal` }];
  const accountStatusOptions = [{ id: true, label: i18n`Enabled` }, { id: false, label: i18n`Disabled` }];

  const dimensionId = getFieldDimensionOfModelType(store, User_Email, User);
  const user = store.getObject(userId);
  const groupFieldHandler = getFieldHandler(store, User_Groups);

  return (
    <VerticalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Email`} iconName={IconName.alternate_email} />
        <BlockContent>
          {dimensionId && getFieldHandler(store, User_Email)?.renderField?.({ dimensionsMapping: { [dimensionId]: userId }, readOnly: false })}
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={store.getObject(User_Groups)[Field_Title] as string} iconName={IconName.group} />
        <BlockContent>
          {groupFieldHandler?.renderField?.({
            readOnly: !isAdmin,
            dimensionsMapping: { [UserGroupMembershipDimension]: userId },
          })}
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Account status`} iconName={IconName.subject} />
        <BlockContent>
          <SearchAndSelect
            computeOptions={() => accountStatusOptions}
            selectedOption={accountStatusOptions.find(({ id }) => id === (user[User_IsEnabled] ?? false))}
            onSelect={(option) => {
              if (option) {
                store.updateObject(userId, { [User_IsEnabled]: option.id });
              }
            }}
            readOnly={!isAdmin}
          />
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Type`} iconName={IconName.subject} />
        <BlockContent>
          <SearchAndSelect
            selectedOption={accountTypeOptions.find(({ id }) => id === (user[User_Type] ?? 'internal'))}
            readOnly
          />
        </BlockContent>
      </HorizontalBlock>
    </VerticalBlock>
  );
};

export default AdminUserDetail;
