import { newError } from '../../errorUtils';
import { newFunctionLibrary } from '../engine/functionLibraryBuilder';
import { booleanType, getMostCommonType, numberType, textType } from '../typeSystem';
import { FunctionInvalidArgumentTypeError } from './errors';
import { checkArgumentCount, checkArgumentType, checks } from './utils';

export const informationFunctions = newFunctionLibrary()
  .addFunction(
    'ISBLANK',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, [textType, numberType, booleanType], argTypes, 0)
        ) ?? {
          type: booleanType,
          transpile: ([arg]) => `((${arg})??'') === ''`,
          jsFunction: (val: unknown) => (val === undefined || val === ''),
        }),
    })
  )
  .addFunction(
    'ISNUMBER',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checkArgumentCount(name, argTypes, 1, 1) ?? {
          type: booleanType,
          transpile: ([arg]) => `typeof (${arg}) === 'number'`,
          jsFunction: (val: unknown) => (typeof val === 'number'),
        }),
    })
  )
  .addFunction(
    'SWITCH',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => {
        const argsCountError = checkArgumentCount(name, argTypes, 3);
        if (argsCountError !== undefined) {
          return argsCountError;
        }

        const switchCommonType = getMostCommonType(argTypes.filter((_, i) => (i === 0 || (i % 2 === 1 && (i + 1 < argTypes.length)))));
        if (switchCommonType.errorIndex !== undefined) {
          const errorIndex = 2 * switchCommonType.errorIndex - 1; // Will never be 0 as it is the reference
          return new FunctionInvalidArgumentTypeError(name, switchCommonType.mostCommonType, argTypes[errorIndex], errorIndex);
        }

        const returnCommonType = getMostCommonType(argTypes.filter((_, i) => (i !== 0 && ((i % 2 === 0) || (i % 2 === 1 && i + 1 === argTypes.length)))));
        if (returnCommonType.errorIndex !== undefined) {
          const errorIndex = 2 * returnCommonType.errorIndex - 1; // Will never be 0 as it is the reference
          return new FunctionInvalidArgumentTypeError(name, returnCommonType.mostCommonType, argTypes[errorIndex], errorIndex);
        }

        return {
          type: returnCommonType.mostCommonType,
          jsFunctionArgsMode: 'lazy',
          jsFunction: (switchValueFunction: () => unknown, ...args: (() => unknown)[]) => {
            const switchValue = switchValueFunction();
            const caseCount = Math.floor(args.length / 2);
            for (let i = 0; i < caseCount; i += 1) {
              if (switchValue === args[2 * i]()) {
                return args[(2 * i) + 1]();
              }
            }

            if (args.length % 2 === 1) {
              return args.at(-1)?.();
            } else {
              throw newError('SWITCH has no eligible case');
            }
          },
        };
      },
    })
  )
  .build();
