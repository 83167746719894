import { OriginSources, ValidationStatus } from 'yooi-store';
import { CommonAsType } from '../common/fields/commonPropertyType';
import { adminOnlyAcl } from '../conceptModule';
import { Template } from '../templateModule/ids';
import { Integration_Name } from './ids';
import type { IntegrationStoreObject } from './modelTypes';
import { registerModel } from './module';

const { type } = registerModel;

type({
  label: 'Integration',
  accessControlList: {
    READ: (store) => ({ userId, source }, objectId) => {
      switch (source) {
        case OriginSources.API: {
          if (userId === objectId[0]) {
            return { rule: 'integration.read.allowed', status: ValidationStatus.ACCEPTED };
          } else {
            return { rule: 'integration.read.denied', status: ValidationStatus.REJECTED };
          }
        }
        default:
          return adminOnlyAcl(store, userId, 'integration', 'READ');
      }
    },
    WRITE: (store) => ({ userId, source }, objectId) => {
      switch (source) {
        case OriginSources.API: {
          if (userId === objectId[0]) {
            return { rule: 'integration.write.allowed', status: ValidationStatus.ACCEPTED };
          } else {
            return { rule: 'integration.write.denied', status: ValidationStatus.REJECTED };
          }
        }
        default:
          return adminOnlyAcl(store, userId, 'integration', 'WRITE');
      }
    },
    DELETE: (store) => ({ userId, source }, objectId) => {
      switch (source) {
        case OriginSources.API: {
          if (userId === objectId[0]) {
            return { rule: 'integration.delete.allowed', status: ValidationStatus.ACCEPTED };
          } else {
            return { rule: 'integration.delete.denied', status: ValidationStatus.REJECTED };
          }
        }
        default:
          return adminOnlyAcl(store, userId, 'integration', 'DELETE');
      }
    },
  },
  objectDebugLabel: ({ getObject }) => (objectId) => getObject<IntegrationStoreObject>(objectId as string)[Integration_Name],
})
  .property({ label: 'Integration_Name', as: CommonAsType.string })
  .property({ label: 'Integration_Description', as: CommonAsType.string })
  .property({ label: 'Integration_Configuration', as: CommonAsType.string })
  .property({ label: 'Integration_Stakeholders', as: CommonAsType.string })
  .property({ label: 'Integration_ConfigurationError', as: CommonAsType.string })
  .relation({ label: 'Integration_Template', reverseLabel: 'Template_Integrations', targetTypeId: Template });
