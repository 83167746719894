import { ValidationStatus } from 'yooi-store';
import { newError } from 'yooi-utils';
import { asLocal } from '../../../common/fields/commonPropertyType';
import type { GetDslFieldHandler } from '../../../common/fields/FieldModuleDslType';
import type { BusinessRuleRegistration } from '../../../common/types/TypeModuleDslType';
import { ViewsField_Definition } from '../../ids';
import { registerField } from '../../module';
import type { ViewDimension, ViewStoredDefinition } from '../../views';
import { isValidView } from '../../views';
import { isValidDimension } from '../../views/data/dataDefinition';
import type { ViewsField } from '../types';

export interface ViewsFieldDefinition {
  data: ViewDimension[],
  views: ViewStoredDefinition[],
  hasFilters?: boolean,
}

const isValidViewsFieldDefinition = (definition: unknown): definition is ViewsFieldDefinition => {
  if (!definition || typeof definition !== 'object') {
    return false;
  }
  const { data, views } = definition as ViewsFieldDefinition;
  if (!Array.isArray(data) || data.some((dimension) => !isValidDimension(dimension))) {
    return false;
  }
  return Array.isArray(views) && views.every((view) => isValidView(view));
};

const checkValidViewsFieldDefinition: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  // Deletion
  if (properties === null) {
    return undefined;
  }
  // Creation without definition
  if (getObjectOrNull(id) === null && !properties?.[ViewsField_Definition]) {
    return {
      rule: 'viewsField.configuration.inValidConfigurations',
      status: ValidationStatus.REJECTED,
    };
  }
  // Update configuration
  if (properties && properties[ViewsField_Definition] && !isValidViewsFieldDefinition(properties[ViewsField_Definition])) {
    return {
      rule: 'viewsField.configuration.inValidConfigurations',
      status: ValidationStatus.REJECTED,
    };
  } else {
    return undefined;
  }
};

type ViewsFieldHandler = GetDslFieldHandler<ViewsField, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined>;
export const viewsFieldHandler: ViewsFieldHandler = registerField({
  model: {
    label: 'ViewsField',
    title: 'Views',
    properties: [
      { label: 'Definition', as: asLocal('ViewsFieldDefinition') },
    ],
    businessRules: [checkValidViewsFieldDefinition],
  },
  handler: () => ({
    describe: () => ({ hasData: false }),
    restApi: {
      returnTypeSchema: {},
      formatValue: () => undefined,
    },
    getStoreValue: () => undefined,
    getValueWithoutFormula: () => undefined,
    getValueResolution: () => ({ value: undefined, getDisplayValue: () => undefined, isComputed: false, isTimeseries: false }),
    updateValue: () => {
      throw newError('updateValue not supported');
    },
    resolvePathStepConfiguration: () => ({ hasData: false }),
    isEmpty: () => true,
    isSaneValue: () => ({ isValid: true }),
  }),
});
