import classnames from 'classnames';
import type { JssStyle } from 'jss';
import type { FunctionComponent, SyntheticEvent } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { isFiniteNumber, joinObjects } from 'yooi-utils';
import { generateColorByPercentage } from '../../app/_global/fields/numberField/numberFieldUtils';
import base from '../../theme/base';
import { hexColorWithAlpha } from '../../theme/colorUtils';
import type { FontVariant } from '../../theme/fontDefinition';
import { getSpacingAsNumber, Spacing, spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import { getTextMeasurerForVariant } from '../../utils/textUtils';
import useFocusOnMount from '../../utils/useFocusOnMount';
import useSelectionRange from '../../utils/useSelectionRange';
import useSizeContext, { SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import useTooltipRef from '../../utils/useTooltipRef';
import useUsageContext, { UsageVariant } from '../../utils/useUsageContext';
import Icon, { IconColorVariant, IconName } from '../atoms/Icon';
import Typo, { sizeVariantToTypoVariant } from '../atoms/Typo';
import EditableWithDropdown, { EditableCloseReasons } from '../molecules/EditableWithDropdown';

const useStyles = makeStyles((theme) => (joinObjects(
  {
    container: {
      borderRadius: base.borderRadius.medium,
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'space-between',
      paddingRight: spacingRem.s,
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    containerInTable: {
      justifyContent: 'flex-end',
    },
    input: {
      border: 0,
      padding: 0,
      color: theme.color.text.primary,
      '&::placeholder': {
        color: theme.color.text.disabled,
      },
    },
    inputReadOnly: {
      textAlign: 'right',
    },
    inputInForm: {
      '&::placeholder': {
        color: theme.color.text.disabled,
      },
    },
  },
  (Object.fromEntries(Object.entries(theme.font).map(([name, properties]) => [`inputText_${name}`, properties])) as Record<`inputText_${FontVariant}`, JssStyle>),
  {
    readContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    numberContainer: {
      marginLeft: spacingRem.s,
      paddingRight: spacingRem.xxs,
      width: '100%',
      borderRadius: base.borderRadius.medium,
    },
    numberContainerInTable: {
      textAlign: 'right',
      paddingLeft: spacingRem.splus,
    },
    fullWidth: {
      width: '100%',
    },
  }
)), 'numberPicker');

interface NumberPickerProps {
  value: string | number | undefined,
  onChange: (value: string | number | undefined) => void,
  onSubmit?: (value: number | undefined) => void,
  onCancel?: () => void,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  placeholder?: string,
  unit?: string,
  min?: number | { value: number, color: string | undefined },
  ticks?: { value: number, color: string | undefined }[],
  max?: number | { value: number, color: string | undefined },
  invalidColor?: string,
  decimals?: number,
  step?: number,
  withProgress?: boolean,
  readOnly?: boolean,
  inputError?: string,
  withDecimals?: boolean,
  withFormatting?: boolean,
  isEditing?: boolean,
  focusOnMount?: boolean,
  restingTooltip?: string | (() => Promise<string>),
}

const NumberPicker: FunctionComponent<NumberPickerProps> = ({
  value,
  onChange,
  onSubmit,
  onCancel,
  onEditionStart,
  onEditionStop,
  placeholder,
  unit,
  min: minProp,
  ticks,
  max: maxProp,
  invalidColor,
  decimals,
  step = 1,
  withProgress = false,
  readOnly = false,
  inputError,
  withDecimals = true,
  withFormatting = true,
  isEditing = false,
  focusOnMount = false,
  restingTooltip,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const { captureAncestorRef, captureSelectionRange, restoreSelectionRange } = useSelectionRange<HTMLDivElement>();

  const min: { value: number, color: string | undefined } | undefined = typeof minProp === 'number' ? { value: minProp, color: undefined } : minProp;
  const max: { value: number, color: string | undefined } | undefined = typeof maxProp === 'number' ? { value: maxProp, color: undefined } : maxProp;

  const minMaxTooltipRef = useTooltipRef(!readOnly && min && max ? `${min.value} - ${max.value}` : undefined);

  const submitChanges = (commit: boolean) => {
    if (!readOnly) {
      onEditionStop?.();
    }
    if (onSubmit && commit && !readOnly) {
      if (!isFiniteNumber(value)) {
        onSubmit(undefined);
      } else {
        onSubmit(Number(value));
      }
    } else {
      onCancel?.();
    }
  };

  const handleViewButtonClickSubmit = useDebouncedCallback(() => submitChanges(true), 500);

  const [editMode, setEditMode] = useState(focusOnMount);
  const inputRef = useRef<HTMLInputElement>(null);
  const positionRef = useRef<{ start: number, end: number } | undefined>();

  useFocusOnMount(inputRef, editMode);

  const containerRef = useRef<HTMLDivElement>(null);

  const { sizeVariant } = useSizeContext();
  const typoVariant = sizeVariantToTypoVariant[sizeVariant];
  const measureText = getTextMeasurerForVariant(typoVariant);

  const spacing = getSpacingAsNumber(Spacing.splus);

  const usageVariant = useUsageContext();
  const isCardVariant = usageVariant === UsageVariant.inCard;
  const isFormVariant = usageVariant === UsageVariant.inForm;
  const isTableVariant = usageVariant === UsageVariant.inTable;

  const onChangeValidated = (commit: boolean) => {
    setEditMode(false);
    submitChanges(commit);
  };

  const addSuffix = (v: string | number | undefined, suffix: string | undefined): string => {
    if (v || v === 0) {
      return `${v}${suffix ? ` ${suffix}` : ''}`;
    } else {
      return `${suffix ? ` ${suffix}` : ''}`;
    }
  };

  const removeSuffix = (v: string, suffix: string | undefined): string => {
    if (suffix?.length) {
      return v?.slice(0, -(suffix.length + 1));
    } else {
      return v;
    }
  };

  useLayoutEffect(() => {
    if (positionRef.current && inputRef.current) {
      inputRef.current.setSelectionRange(positionRef.current.start, positionRef.current.end);
      positionRef.current = undefined;
    }
  }, [value]);

  const handleArrow = (increment: number) => (event: SyntheticEvent, inEditMode: boolean) => {
    event.preventDefault();
    // Stop propagation when in view mode to not pass the input in edit mode
    if (!inEditMode) {
      event.stopPropagation();
    }
    let newValue;
    if (!isFiniteNumber(value)) {
      newValue = (min && min.value > 0) ? min.value : 0;
    } else {
      const tempValue = Number(value) + increment;
      if (isFiniteNumber(tempValue)) {
        newValue = tempValue;
      }
    }

    if (!inEditMode) {
      if (!readOnly) {
        onEditionStart?.();
      }
      onChange(newValue);
      handleViewButtonClickSubmit();
    }
    positionRef.current = { start: newValue?.toString().length ?? 0, end: newValue?.toString().length ?? 0 };
    onChange(newValue);
  };

  const handleArrowUp = handleArrow(step);
  const handleArrowDown = handleArrow(-step);

  const generateInputColor = () => {
    let inputBackground;
    let textColor;
    if (withProgress) {
      if (isFiniteNumber(value)) {
        const numberValue = Number(value);
        if (min && numberValue < min.value) {
          inputBackground = `linear-gradient(90deg, ${invalidColor ? hexColorWithAlpha(invalidColor, 0.1) : theme.color.text.white} 0.8rem, ${invalidColor ? hexColorWithAlpha(invalidColor, 0.1) : theme.color.text.white} 100%)`;
          textColor = !textColor ? invalidColor : undefined;
        } else if (max && numberValue > max.value) {
          inputBackground = `linear-gradient(90deg, ${invalidColor ? hexColorWithAlpha(invalidColor, 0.1) : theme.color.border.default} 0.8rem, ${invalidColor ? hexColorWithAlpha(invalidColor, 0.1) : theme.color.border.default} 100%)`;
          textColor = !textColor ? invalidColor : undefined;
        } else {
          const position = (min && max) ? Math.round(((numberValue - min.value) / (max.value - min.value)) * 100) : 0;
          const gradientPadding = (100 - position) / 100;
          if (invalidColor || min?.color || max?.color || ticks?.some(({ color }) => Boolean(color))) {
            const generateColor = generateColorByPercentage(numberValue, min, ticks, max);
            const backgroundColor = generateColor() ?? theme.color.border.default;
            inputBackground = `linear-gradient(90deg, ${backgroundColor} 0.8rem, ${backgroundColor} calc(${position}% + ${gradientPadding}rem ), ${theme.color.background.neutral.subtle} calc(${position}% + ${gradientPadding}rem)`;
            textColor = !textColor ? generateColor(true) : undefined;
          } else {
            inputBackground = `linear-gradient(90deg, ${theme.color.border.default} 0.8rem, ${theme.color.border.default} calc(${position}% + ${gradientPadding}rem ), ${theme.color.background.neutral.subtle} calc(${position}% + ${gradientPadding}rem)`;
          }
        }
      }
    }
    if (min === unit || max === undefined) {
      inputBackground = undefined;
    }
    if (!(value || value === 0)) {
      textColor = isFormVariant ? theme.color.text.disabled : theme.color.text.disabled;
    }
    return { textColor, inputBackground };
  };

  const renderNumberContainer = (inEditMode: boolean) => {
    const { textColor, inputBackground } = generateInputColor();
    const displayedPlaceholder = !isTableVariant && !readOnly ? placeholder : undefined;

    const placeholderLength = measureText(displayedPlaceholder) + remToPx(spacing /* Size of the left and right padding */);
    let displayText;
    let error = inputError;
    let formattedValue = '';

    let fractionDigits = 0;
    if (withDecimals && Number.isSafeInteger(decimals) && decimals && decimals >= 0) {
      fractionDigits = decimals;
    }
    if (value !== null && isFiniteNumber(value)) {
      if (withFormatting) {
        formattedValue = value.toLocaleString(i18n.locale, { maximumFractionDigits: fractionDigits, minimumFractionDigits: fractionDigits });
      } else {
        formattedValue = value.toString(10);
      }
    }

    if (value || value === 0) {
      displayText = addSuffix(formattedValue, unit);
      if (!isFiniteNumber(value)) {
        error = i18n`Value is not a valid number`;
      } else if (min && Number(value) < min.value) {
        error = i18n`Value is lower than the minimal allowed value (${min.value})`;
      } else if (max && Number(value) > max.value) {
        error = i18n`Value is greater than the maximum allowed value (${max.value})`;
      }
    } else {
      displayText = displayedPlaceholder;
    }

    const currentValueWidth = Math.max(measureText(displayText), measureText('00')) + remToPx(1.2 /* Size of the left and right padding */) + (error ? remToPx(1.2) : 0);

    // Magic value from the DS
    const minWidth = withProgress ? remToPx(12) : 0;
    let numberContainerWidth: number | string = Math.max(placeholderLength, currentValueWidth, minWidth);
    if (withProgress && sizeVariant === SizeVariant.small) {
      numberContainerWidth = remToPx(unit ? 5.9 : 7);
    } else if (isTableVariant && !inEditMode) {
      numberContainerWidth = Math.max(placeholderLength, currentValueWidth);
    }

    if (inEditMode) {
      let inputValue = addSuffix(value, unit);
      if (readOnly) {
        inputValue = formattedValue !== '' ? addSuffix(formattedValue, unit) : formattedValue;
      }
      return (
        <input
          ref={inputRef}
          readOnly={readOnly}
          className={classnames({
            [classes.input]: true,
            [classes[`inputText_${typoVariant}`]]: true,
            [classes.inputInForm]: usageVariant === UsageVariant.inForm,
            [classes.numberContainer]: true,
            [classes.numberContainerInTable]: usageVariant === UsageVariant.inTable,
            [classes.inputReadOnly]: isTableVariant && readOnly,
          })}
          style={{
            minWidth: numberContainerWidth,
            backgroundImage: inputBackground,
            color: textColor,
          }}
          type="text"
          spellCheck={false}
          value={inputValue}
          onSelect={(e) => {
            if (e.target instanceof HTMLInputElement) {
              let { selectionStart, selectionEnd } = e.target;
              const valueWithoutSuffix = removeSuffix(e.target.value, unit);
              if (selectionStart && selectionStart > valueWithoutSuffix.length) {
                selectionStart = valueWithoutSuffix.length;
                selectionEnd = valueWithoutSuffix.length;
                inputRef.current?.setSelectionRange(selectionStart, selectionEnd);
              } else if (selectionEnd && selectionEnd > valueWithoutSuffix.length) {
                selectionEnd = valueWithoutSuffix.length;
                inputRef.current?.setSelectionRange(selectionStart, selectionEnd);
              }
            }
          }}
          placeholder={displayedPlaceholder}
          onFocus={restoreSelectionRange}
          onChange={(event) => {
            const newValue = removeSuffix(event.target.value, unit);
            if (/^-?\d*(?:[.,](\d*))?$/.test(newValue)) {
              onChange(newValue.replace(',', '.') || undefined);
            }
          }}
          onKeyDown={(event) => {
            const { key } = event;
            if (key === 'Enter') {
              onChangeValidated(true);
            } else if (key === 'ArrowUp') {
              handleArrowUp(event, inEditMode);
            } else if (key === 'ArrowDown') {
              handleArrowDown(event, inEditMode);
            }
          }}
        />
      );
    }

    return (
      <div
        ref={captureAncestorRef}
        className={classnames({
          [classes.readContainer]: true,
          [classes.numberContainer]: true,
          [classes.numberContainerInTable]: usageVariant === UsageVariant.inTable,
        })}
        style={{
          minWidth: isTableVariant ? undefined : numberContainerWidth,
          backgroundImage: inputBackground,
          flexDirection: isTableVariant ? 'row-reverse' : 'row',
        }}
      >
        <Typo maxLine={1} color={textColor} ref={minMaxTooltipRef}>
          {formattedValue !== '' ? addSuffix(formattedValue, unit) : displayedPlaceholder}
        </Typo>
        {error ? (<span className={classes.iconContainer}><Icon name={IconName.dangerous} tooltip={error} colorVariant={IconColorVariant.error} /></span>) : null}
      </div>
    );
  };

  const renderValue = (inEditMode: boolean) => (
    <div
      className={classnames({
        [classes.container]: true,
        [classes.containerInTable]: usageVariant === UsageVariant.inTable,
        [classes.fullWidth]: true,
      })}
      aria-hidden="true"
      ref={containerRef}
    >
      {renderNumberContainer(inEditMode)}
    </div>
  );

  return (
    <EditableWithDropdown
      variant={usageVariant}
      showDropdown={editMode}
      openDropdown={() => {
        captureSelectionRange();
        setEditMode(true);
        if (!readOnly) {
          onEditionStart?.();
        }
      }}
      closeDropdown={(reason) => onChangeValidated(reason !== EditableCloseReasons.onEscapeKeyDown)}
      renderValue={renderValue}
      readOnly={readOnly}
      closeOnTabKeyDown
      editableSizes={{ flexGrow: 0 }}
      withMultiplayerOutline={isEditing && isTableVariant}
      isEditing={isCardVariant && isEditing}
      dropdownSizes={{ sameWidth: true }}
      restingTooltip={restingTooltip}
    />
  );
};

export default NumberPicker;
