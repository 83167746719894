import type { CardsViewStoredDefinition } from './cards';
import type { ChipViewStoredDefinition } from './chip';
import type { GaugeViewStoredDefinition } from './gauge';
import type { LineChartViewStoredDefinition } from './lineChart';
import type { MatrixViewStoredDefinition } from './matrix';
import type { StructuralBarChartViewStoredDefinition } from './structuralBarChart';
import type { SwimlaneViewStoredDefinition } from './swimlane';
import type { TableViewStoredDefinition } from './table';
import type { TemporalBarChartViewStoredDefinition } from './temporalBarChart';
import type { TimelineViewStoredDefinition } from './timeline';
import type { TimeseriesTableViewStoredDefinition } from './timeseriesTable';

export type ViewStoredDefinition =
  | TableViewStoredDefinition
  | LineChartViewStoredDefinition
  | StructuralBarChartViewStoredDefinition
  | TemporalBarChartViewStoredDefinition
  | GaugeViewStoredDefinition
  | SwimlaneViewStoredDefinition
  | MatrixViewStoredDefinition
  | TimelineViewStoredDefinition
  | CardsViewStoredDefinition
  | ChipViewStoredDefinition
  | TimeseriesTableViewStoredDefinition;

export enum ViewType {
  Table = 'Table',
  LineChart = 'LineChart',
  StructuralBarChart = 'StructuralBarChart',
  TemporalBarChart = 'TemporalBarChart',
  Gauge = 'Gauge',
  Swimlane = 'Swimlane',
  Matrix = 'Matrix',
  Timeline = 'Timeline',
  Cards = 'Cards',
  Chip = 'Chip',
  TimeseriesTable = 'TimeseriesTable',
}

export const isTableViewDefinition = (definition: ViewStoredDefinition | undefined): definition is TableViewStoredDefinition => definition?.type === ViewType.Table;
export const isLineChartViewDefinition = (definition: ViewStoredDefinition | undefined): definition is LineChartViewStoredDefinition => definition?.type === ViewType.LineChart;
export const isStructuralBarChartViewDefinition = (definition: ViewStoredDefinition | undefined): definition is StructuralBarChartViewStoredDefinition => (
  definition?.type === ViewType.StructuralBarChart
);
export const isTemporalBarChartViewDefinition = (definition: ViewStoredDefinition | undefined): definition is TemporalBarChartViewStoredDefinition => (
  definition?.type === ViewType.TemporalBarChart
);
export const isGaugeViewDefinition = (definition: ViewStoredDefinition | undefined): definition is GaugeViewStoredDefinition => definition?.type === ViewType.Gauge;
export const isSwimlaneViewDefinition = (definition: ViewStoredDefinition | undefined): definition is SwimlaneViewStoredDefinition => definition?.type === ViewType.Swimlane;
export const isMatrixViewDefinition = (definition: ViewStoredDefinition | undefined): definition is MatrixViewStoredDefinition => definition?.type === ViewType.Matrix;
export const isTimelineViewDefinition = (definition: ViewStoredDefinition | undefined): definition is TimelineViewStoredDefinition => definition?.type === ViewType.Timeline;
export const isCardsViewDefinition = (definition: ViewStoredDefinition | undefined): definition is CardsViewStoredDefinition => definition?.type === ViewType.Cards;
export const isChipViewDefinition = (definition: ViewStoredDefinition | undefined): definition is ChipViewStoredDefinition => definition?.type === ViewType.Chip;

export const isValidView = (maybeInvalidViewDefinition: unknown): maybeInvalidViewDefinition is ViewStoredDefinition => {
  if (!maybeInvalidViewDefinition || typeof maybeInvalidViewDefinition !== 'object') {
    return false;
  }
  const viewDefinition: ViewStoredDefinition = maybeInvalidViewDefinition as ViewStoredDefinition;
  const { type } = viewDefinition;

  return typeof type === 'string' && Object.values(ViewType).includes(type);
};
