import type { FunctionComponent } from 'react';
import type { DimensionsMapping } from 'yooi-modules/modules/conceptModule';
import {
  canCreateAssociation,
  InvalidFieldError,
  isEmbeddedAsIntegrationOnly,
  ParsedDimensionType,
  parseDimensionMapping,
  relationMultipleFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import { RelationMultipleField_TargetType } from 'yooi-modules/modules/conceptModule/ids';
import { Resource } from 'yooi-modules/modules/resourceModule/ids';
import useStore from '../../../../store/useStore';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';
import { getInlineCreationBuilder } from '../../conceptUtils';
import { formatErrorForUser } from '../../errorUtils';
import ConceptChipList from '../_global/ConceptChipList';
import ConceptResourceField from '../_global/ConceptResourceField';

interface RelationMultipleFieldRendererSwitchProps {
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
  readOnly: boolean,
  focusOnMount: boolean,
  onLink: (selectedId: string, conceptId: string) => void,
  onUnlink: (selectedId: string, conceptId: string) => void,
}

const RelationMultipleFieldRendererSwitch: FunctionComponent<RelationMultipleFieldRendererSwitchProps> = ({
  fieldId,
  dimensionsMapping,
  readOnly,
  focusOnMount,
  onLink,
  onUnlink,
}) => {
  const store = useStore();

  const usageVariant = useUsageContext();

  const parsedDimension = parseDimensionMapping(dimensionsMapping);
  if (parsedDimension.type !== ParsedDimensionType.MonoDimensional) {
    return null;
  }

  if (usageVariant !== UsageVariant.inTable && store.getObject(fieldId)[RelationMultipleField_TargetType] === Resource) {
    return (<ConceptResourceField conceptId={parsedDimension.objectId} resourceFieldId={fieldId} readOnly={readOnly} />);
  } else {
    const { getValueWithoutFormula, isSaneValue, getTargetType } = relationMultipleFieldHandler(store, fieldId);
    const targetTypeId = getTargetType?.()?.id;
    if (targetTypeId === undefined) {
      return null;
    }

    const resolutionError = isSaneValue(parsedDimension.objectId).error;
    return (
      <ConceptChipList
        conceptId={parsedDimension.objectId}
        fieldId={fieldId}
        value={getValueWithoutFormula(dimensionsMapping)}
        error={resolutionError ? formatErrorForUser(store, new InvalidFieldError(fieldId, resolutionError)) : undefined}
        readOnly={readOnly || isEmbeddedAsIntegrationOnly(store.getObject(parsedDimension.objectId))}
        getInlineCreation={
          canCreateAssociation(targetTypeId)
            ? getInlineCreationBuilder(store, targetTypeId, (newInstanceId) => onLink(newInstanceId, parsedDimension.objectId))
            : undefined
        }
        onLink={onLink}
        onUnlink={onUnlink}
        focusOnMount={focusOnMount}
      />
    );
  }
};

export default RelationMultipleFieldRendererSwitch;
