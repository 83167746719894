import type { OpenAPIV3 } from 'openapi-types';
import type { StoreObject } from 'yooi-store';
import { Class_Instances } from '../../typeModule/ids';
import { ConceptDefinition_ApiAlias } from '../ids';

export interface ConceptReference {
  id: string,
  href: string,
}

export const toConceptReference = (concept: StoreObject): ConceptReference | undefined => {
  const conceptDefinition = concept.navigate(Class_Instances);
  const conceptDefinitionApiAlias = conceptDefinition?.[ConceptDefinition_ApiAlias];
  if (conceptDefinitionApiAlias && conceptDefinitionApiAlias !== '') {
    return {
      id: concept.id,
      href: `/api/store/type/${conceptDefinitionApiAlias}/id/${concept.id}`,
    };
  } else {
    return undefined;
  }
};
export const conceptRefApiSchema: OpenAPIV3.SchemaObject = {
  type: 'object',
  properties: {
    id: { type: 'string' },
    href: { type: 'string' },
  },
};
