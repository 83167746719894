import classnames from 'classnames';
import type { FunctionComponent, ReactElement, RefObject } from 'react';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useResizeObserver from 'use-resize-observer';
import { BlockFieldLayoutOption } from 'yooi-modules/modules/conceptLayoutModule';
import type { ParametersMapping, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import type { ViewDimension, ViewStoredDefinition, WidgetStoreObject } from 'yooi-modules/modules/dashboardModule';
import { ViewType } from 'yooi-modules/modules/dashboardModule';
import { Widget_DisplayMode, Widget_Title, Widget_TitleMode } from 'yooi-modules/modules/dashboardModule/ids';
import { joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Typo from '../../../../components/atoms/Typo';
import { useWidgetContext } from '../../../../components/atoms/WidgetContextProvider';
import Chooser from '../../../../components/molecules/Chooser';
import Overlay from '../../../../components/molecules/Overlay';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import Spinner, { SpinnerVariant } from '../../../../components/molecules/Spinner';
import BlockContent from '../../../../components/templates/BlockContent';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { Opacity } from '../../../../theme/base';
import { generateColorFromOpacity, getMostReadableColorFromBackgroundColor } from '../../../../theme/colorUtils';
import { buildPadding, getSpacing, getSpacingAsNumber, Spacing, spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { remToPx } from '../../../../utils/sizeUtils';
import useDerivedState from '../../../../utils/useDerivedState';
import { useSessionStorageState } from '../../../../utils/useSessionStorage';
import useTheme from '../../../../utils/useTheme';
import ConceptViewTopBar from '../../ConceptViewTopBar';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { useViewFilters } from '../../filter/useViewFilters';
import { isExportViewDefinition } from '../../views/common/viewUtils';
import { getViewDefinitionHandler } from '../../views/viewDsl';
import BlockField from '../_global/BlockField';
import WidgetInvalidConfiguration from '../_global/WidgetInvalidConfiguration';
import type { WidgetDisplay } from '../_global/widgetUtils';
import type { BlockFieldProps } from '../FieldLibraryTypes';
import useViewLoadingState from './useViewLoadingState';
import ViewsWidgetFavoriteFilterBar from './ViewsWidgetFavoriteFilterBar';

const containerPaddingTop = Spacing.splus;
const containerPaddingBottom = Spacing.xs;
const headerLinePaddingBottom = Spacing.s;

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: getSpacing(containerPaddingBottom),
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerPaddedTop: {
    paddingTop: getSpacing(containerPaddingTop),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    columnGap: spacingRem.s,
  },
  headerBottomPadding: {
    paddingBottom: getSpacing(headerLinePaddingBottom),
  },
  headerPadded: {
    ...buildPadding({ x: Spacing.splus }),
  },
  widgetContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
}, 'viewsGroup');

interface ViewsGroupWidgetProps {
  fieldId?: undefined,
  widgetId: string,
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinitions: ViewStoredDefinition[],
  parametersMapping: ParametersMapping,
  parameterDefinitions: SingleParameterDefinition[],
  isPreview?: boolean,
  readOnly?: boolean,
  widgetDisplay?: undefined,
  width: number,
  height: number,
  blockFieldProps?: undefined,
}

interface ViewsGroupBlockProps {
  fieldId: string,
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinitions: ViewStoredDefinition[],
  parametersMapping: ParametersMapping,
  widgetDisplay: WidgetDisplay,
  blockFieldProps: BlockFieldProps,
  readOnly?: boolean,
  parameterDefinitions?: undefined,
  isPreview?: undefined,
  widgetId?: undefined,
  width?: undefined,
  height?: undefined,
}

type ViewsGroupProps = ViewsGroupWidgetProps | ViewsGroupBlockProps;

const ViewsGroup: FunctionComponent<ViewsGroupProps> = ({
  fieldId,
  widgetId,
  viewDimensions,
  viewFilters,
  viewDefinitions,
  parametersMapping,
  parameterDefinitions = [],
  widgetDisplay,
  isPreview = false,
  readOnly = false,
  width,
  height,
  blockFieldProps,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();

  const location = useLocation();

  const viewLoadingState = useViewLoadingState();
  const { padded, backgroundColor } = useWidgetContext();

  const optionOrFilterLineHeight = 2.6;
  const [filterLineHeightPx, setFilterLineHeightPx] = useState<number>(remToPx(optionOrFilterLineHeight));
  const { ref: filterLineRef } = useResizeObserver({ onResize: (size) => setFilterLineHeightPx(size.height ?? remToPx(optionOrFilterLineHeight)) });

  const inView = widgetId !== undefined && width !== undefined && height !== undefined;
  const inBlock = fieldId !== undefined && blockFieldProps !== undefined;

  const widget = inView ? store.getObject<WidgetStoreObject>(widgetId) : undefined;
  const isBorderless = widget?.[Widget_DisplayMode]?.type === 'borderless';

  const [activeViewDefinitionId, setActiveViewDefinitionId] = useSessionStorageState<string | null>(inView ? `${widgetId}-state-widget` : `${viewDimensions.map(({ id }) => id)
    .join('-')}`, null);
  const [activePreviewViewDefinitionId, setActivePreviewViewDefinitionId] = useDerivedState(() => (
    isPreview ? location.hash.slice(1, location.hash.length) : activeViewDefinitionId
  ), [location.hash, activeViewDefinitionId]);
  const setActiveViewId = (id: string) => {
    if (isPreview) {
      setActivePreviewViewDefinitionId(id);
    } else {
      setActiveViewDefinitionId(id);
    }
  };
  const filterConfiguration = useViewFilters(viewFilters, viewDimensions);
  const buttonRef = useRef<{ options: RefObject<HTMLDivElement>, filters: RefObject<HTMLDivElement> }>(null);

  let activeViewDefinitionIndex = viewDefinitions.findIndex(({ id }) => activePreviewViewDefinitionId === id);
  if (activeViewDefinitionIndex === -1) {
    activeViewDefinitionIndex = 0;
  }
  const withChooser = viewDefinitions.length > 1;
  const activeViewDefinition = viewDefinitions.at(activeViewDefinitionIndex);

  const { filterKey, hasFilters, getViewFilters } = viewFilters;

  let forceShowOptions = !hasFilters && viewDefinitions.length === 1;
  const [showLine, setShowLine] = useState<{ filters: boolean, options: boolean, popover: boolean }>({
    filters: !!hasFilters && getViewFilters().length > 0,
    options: false,
    popover: false,
  });

  let getContent: (width?: number, height?: number) => (ReactElement | null);
  let withOptions = false;
  let withFilters = false;
  let withExport = false;
  let injectHeaderLine = false;
  let optionAsPopover = false;
  let renderExport: (() => ReactElement | null) | undefined;

  if (activeViewDefinition) {
    const viewHandler = getViewDefinitionHandler(activeViewDefinition);
    optionAsPopover = viewHandler.optionType === 'popover';
    withOptions = viewHandler.optionType !== undefined && viewHandler.hasOptions(store, { viewDimensions, parameterDefinitions, viewFilters });
    withFilters = viewHandler.withFilters;
    withExport = viewHandler.withExport;
    if (isExportViewDefinition(activeViewDefinition)) {
      forceShowOptions = forceShowOptions && !activeViewDefinition.export;
      renderExport = viewHandler.withExport && activeViewDefinition.export ? () => viewHandler.renderExport(store, {
        widgetId,
        viewDimensions,
        filterConfiguration,
        parametersMapping,
        parameterDefinitions,
        getViewResolution: () => viewHandler.resolveView(store, {
          aclHandler,
          viewDimensions,
          parametersMapping,
          userId: loggedUserId,
          filterConfiguration,
          readOnly,
        }),
      }) : undefined;
    }

    forceShowOptions = forceShowOptions && !optionAsPopover;
    injectHeaderLine = !forceShowOptions && (withChooser || withOptions || withFilters || withExport);
    const displayOption = forceShowOptions || (optionAsPopover ? showLine.popover : showLine.options);

    getContent = (w = 0, h = 0) => (
      <div className={classes.contentContainer}>
        {withOptions && displayOption && buttonRef.current && optionAsPopover && (
          <Overlay
            target={buttonRef.current.options}
            onBackdropClick={(event) => {
              event.stopPropagation();
              setShowLine((current) => joinObjects(current, { popover: false }));
            }}
            isBoundary={false}
            offset={[0, (buttonRef.current.options.current?.clientHeight ?? 0) + remToPx(getSpacingAsNumber(Spacing.s))]}
          >
            {viewHandler.renderOptions(store, {
              widgetId,
              viewDimensions,
              viewFilters,
              parametersMapping,
              parameterDefinitions,
              readOnly: isPreview || readOnly,
              includeLoader: !injectHeaderLine,
            })}
          </Overlay>
        )}
        {withOptions && displayOption && viewHandler.optionType === 'line' && viewHandler.renderOptions(store, {
          widgetId,
          viewDimensions,
          viewFilters,
          parametersMapping,
          parameterDefinitions,
          readOnly: isPreview || readOnly,
          includeLoader: !injectHeaderLine,
        })}
        {withFilters && showLine.filters && (
          <ViewsWidgetFavoriteFilterBar
            ref={filterLineRef}
            viewFilters={viewFilters}
            viewDimensions={viewDimensions}
            isBorderless={isBorderless}
            inBlock={inBlock}
            parameterDefinitions={parameterDefinitions}
            parametersMapping={parametersMapping}
          />
        )}
        {inView && viewHandler.renderWidget(
          store,
          {
            widgetId,
            viewDimensions,
            viewFilters,
            parametersMapping,
            parameterDefinitions,
            width: w,
            height: (
              (withFilters && showLine.filters) || (withOptions && displayOption && viewHandler.optionType === 'line')
                ? h - (withFilters && showLine.filters ? filterLineHeightPx : remToPx(optionOrFilterLineHeight)) - remToPx(getSpacingAsNumber(headerLinePaddingBottom))
                : h
            ),
            readOnly: isPreview,
            includeLoader: !injectHeaderLine,
          }
        )}
        {inBlock && viewHandler.renderBlock(
          store,
          {
            viewDimensions,
            viewFilters,
            layoutParametersMapping: parametersMapping,
            widgetDisplay,
            readOnly,
            includeLoader: !injectHeaderLine,
          }
        )}
      </div>
    );
  } else {
    getContent = (w = 0, h = 0) => (inView ? (
      <WidgetInvalidConfiguration width={w} height={h} reason={i18n`Invalid configuration`} />
    ) : (
      <BlockContent padded>
        <SpacingLine>
          <Icon name={IconName.dangerous} colorVariant={IconColorVariant.error} tooltip={i18n`Missing views`} />
          <Typo color={theme.color.text.secondary} maxLine={1}>{i18n`Invalid configuration`}</Typo>
        </SpacingLine>
      </BlockContent>
    ));
  }

  const headerLineHeight = remToPx(2.6 /* chooser height */) + remToPx(getSpacing(headerLinePaddingBottom));

  const mostReadableColor = getMostReadableColorFromBackgroundColor(backgroundColor ?? theme.color.background.neutral.default);

  const renderTopBar = () => (
    <div className={classes.header} style={{ width }}>
      {
        viewLoadingState === 'loading'
          ? (
            <Spinner
              size={SpinnerVariant.small}
              color={generateColorFromOpacity(mostReadableColor, theme.color.background.neutral.default, Opacity.fifty)}
            />
          )
          : null
      }
      {(withOptions || withFilters || withExport) ? (
        <ConceptViewTopBar
          ref={buttonRef}
          filterKey={filterKey}
          activeFilters={showLine.filters}
          activeOptions={showLine.options}
          onFiltersClick={() => {
            setShowLine((current) => {
              if (optionAsPopover) {
                return joinObjects(current, { filters: !current.filters });
              } else {
                return { options: !current.filters ? false : current.options, filters: !current.filters, popover: false };
              }
            });
          }}
          onOptionsClick={() => {
            setShowLine((current) => {
              if (optionAsPopover) {
                return joinObjects(current, { popover: !current.popover });
              } else {
                return { filters: !current.options ? false : current.filters, options: !current.options, popover: false };
              }
            });
          }}
          hideFilters={!hasFilters || !withFilters || (viewFilters.getViewFilters().length === 0 && viewDimensions.length > 1)}
          hideOptions={forceShowOptions || !withOptions}
          hideSearch={!hasFilters || !withFilters || viewDimensions.length !== 1}
          renderExportButton={renderExport}
        />
      ) : null}
      {withChooser ? (
        <Chooser
          actions={viewDefinitions.map((v) => ({ key: v.id, name: getViewDefinitionHandler(v).label }))}
          onClick={(index) => {
            const selectedView = viewDefinitions[index];
            if (getViewDefinitionHandler(selectedView).optionType !== 'popover' && showLine.filters && showLine.options) {
              setShowLine((current) => joinObjects(current, { options: false }));
            }
            setActiveViewId(selectedView.id);
          }}
          selectedIndexes={[activeViewDefinitionIndex]}
          readOnly={isPreview}
        />
      ) : null}
    </div>
  );

  if (inView) {
    const displayTitle = widget && widget[Widget_TitleMode] !== 'hide' && (widget[Widget_TitleMode] === 'always' || (widget[Widget_Title] !== undefined && widget[Widget_Title] !== ''));
    const isPaddedTop = !isBorderless || displayTitle;
    return (
      <div className={classnames({ [classes.container]: true, [classes.containerPaddedTop]: isPaddedTop })}>
        {
          injectHeaderLine
            ? (
              <div className={classnames({ [classes.header]: true, [classes.headerBottomPadding]: true, [classes.headerPadded]: padded && injectHeaderLine })} style={{ width }}>
                {renderTopBar()}
              </div>
            )
            : null
        }
        <div className={classes.widgetContainer}>
          {getContent(width, height - (injectHeaderLine ? headerLineHeight : 0))}
        </div>
      </div>
    );
  } else if (inBlock) {
    return (
      <BlockField
        fieldId={fieldId}
        {...blockFieldProps}
        inColumn={blockFieldProps.layoutDisplayMode === BlockFieldLayoutOption.column
          || (blockFieldProps.layoutDisplayMode === BlockFieldLayoutOption.auto && !(viewDefinitions.length === 1 && viewDefinitions[0].type === ViewType.Chip))}
        rightActions={injectHeaderLine ? (<SpacingLine>{renderTopBar()}</SpacingLine>) : null}
      >
        <BlockContent>
          {getContent()}
        </BlockContent>
      </BlockField>
    );
  } else {
    return null;
  }
};

export default ViewsGroup;
