import type { ScaleTime } from 'd3-scale';
import type { FunctionComponent } from 'react';
import { addTimeToDate, DurationType, getFormattedTextDateByPeriod, periodicities, PeriodicityType } from 'yooi-utils';
import type { AxisBaseTick, AxisDirection as TimeAxisDirection } from './Axis';
import Axis, { AxisVariant as TimeAxisVariant } from './Axis';

export { AxisDirection as TimeAxisDirection } from './Axis';
export { AxisVariant as TimeAxisVariant } from './Axis';

const periodicityAxisTicks: Record<PeriodicityType, [number, DurationType][]> = {
  [PeriodicityType.day]: [
    [2, DurationType.days],
    [7, DurationType.days],
    [14, DurationType.days],
    [21, DurationType.days],
  ],
  [PeriodicityType.week]: [
    [1, DurationType.weeks],
    [3, DurationType.weeks],
    [5, DurationType.weeks],
    [10, DurationType.weeks],
    [15, DurationType.weeks],
    [20, DurationType.weeks],
  ],
  [PeriodicityType.month]: [
    [1, DurationType.months],
    [2, DurationType.months],
    [3, DurationType.months],
    [4, DurationType.months],
  ],
  [PeriodicityType.quarter]: [
    [1, DurationType.quarters],
    [2, DurationType.quarters],
    [3, DurationType.quarters],
  ],
  [PeriodicityType.year]: [
    [1, DurationType.years],
  ],
};

const periodicityHigherElement: Record<PeriodicityType, PeriodicityType> = {
  [PeriodicityType.year]: PeriodicityType.year,
  [PeriodicityType.quarter]: PeriodicityType.year,
  [PeriodicityType.month]: PeriodicityType.year,
  [PeriodicityType.week]: PeriodicityType.week,
  [PeriodicityType.day]: PeriodicityType.day,
};

const resolveAxisTicks = (periodicity: PeriodicityType, timeScale: ScaleTime<number, number>, startTime: Date) => {
  const tickMinSpace = 120;
  const axisTickOptions = periodicityAxisTicks[periodicity];
  if (axisTickOptions.length !== 1) {
    for (let i = 0; i < axisTickOptions.length; i += 1) {
      const axisOption = axisTickOptions[i];
      const spaceForTick = timeScale(addTimeToDate(startTime, axisOption[0], axisOption[1])) - timeScale(startTime);
      if ((spaceForTick > tickMinSpace) || (i === axisTickOptions.length - 1)) {
        return axisOption;
      }
    }
  }
  return axisTickOptions[0];
};

const generateTicks = (
  startTime: Date,
  endTime: Date,
  timeScale: ScaleTime<number, number>,
  periodicity: PeriodicityType,
  size: number | undefined,
  margin: number,
  hideStartTimeTick: boolean
): AxisBaseTick[] => {
  const axisOption = resolveAxisTicks(periodicity, timeScale, startTime);
  const startTick = periodicities[periodicityHigherElement[periodicity]].getStartOfPeriod(startTime);
  const ticks = [];
  let date = startTick;
  while (date.getTime() < endTime.getTime()) {
    if ((!hideStartTimeTick && date >= startTime && date < endTime) || (hideStartTimeTick && date > startTime && date < endTime)) {
      ticks.push(
        {
          id: date.toISOString(),
          label: getFormattedTextDateByPeriod(date, periodicity),
          position: timeScale(date),
          size,
          margin,
        }
      );
    }
    date = addTimeToDate(date, axisOption[0], axisOption[1]);
  }
  return ticks;
};

interface TimeAxisProps {
  timeScale: ScaleTime<number, number>,
  initialCoordinate?: number,
  direction: TimeAxisDirection,
  axisName?: string,
  periodicity: PeriodicityType,
  tickSize?: number,
  showArrow?: boolean,
  margin?: number,
  variant?: TimeAxisVariant,
  hideStartTimeTick?: boolean,
}

const TimeAxis: FunctionComponent<TimeAxisProps> = ({
  timeScale,
  initialCoordinate,
  direction,
  axisName,
  periodicity,
  tickSize,
  showArrow = false,
  margin = 0,
  variant = TimeAxisVariant.black,
  hideStartTimeTick = false,
}) => (
  <Axis
    scale={timeScale}
    initialCoordinate={initialCoordinate}
    axisName={axisName}
    direction={direction}
    ticks={generateTicks(timeScale.domain()[0], timeScale.domain()[1], timeScale, periodicity, tickSize, margin, hideStartTimeTick)}
    showArrow={showArrow}
    variant={variant}
  />
);

export default TimeAxis;
