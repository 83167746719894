import type { FunctionComponent, ReactElement } from 'react';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import type { BlockStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import {
  Block_EditDisplayCondition,
  Block_Name,
  Block_Rank,
  Block_Type,
  Block_ViewDisplayCondition,
  BlockParent_Block,
  BlockType_Column,
  BlockType_Tab,
  ConceptDefinition_Blocks,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { ConceptStoreObject, FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import {
  FILTER_PARAMETER_CURRENT,
  FILTER_PARAMETER_LOGGED_USER,
  getFilterFunction,
  hasPlatformCapability,
  isConceptValid,
  isEmbeddedConceptInstance,
} from 'yooi-modules/modules/conceptModule';
import {
  ConceptDefinition_ShowEcosystemTab,
  ConceptDefinition_ShowMasterDetail,
  ConceptRole,
  Field_IntegrationOnly,
  Group,
  KinshipRelation,
  PlatformCapabilityAdmin,
  User,
} from 'yooi-modules/modules/conceptModule/ids';
import { compareProperty, compareRank, extractAndCompareValue, joinObjects } from 'yooi-utils';
import type { Tab } from '../../components/molecules/Tabs';
import BaseLayout from '../../components/templates/BaseLayout';
import LeftPanel from '../../components/templates/LeftPanel';
import VerticalBlock from '../../components/templates/VerticalBlock';
import useAcl from '../../store/useAcl';
import useAuth from '../../store/useAuth';
import useStore from '../../store/useStore';
import i18n from '../../utils/i18n';
import { ObjectNotFoundError } from '../../utils/ObjectNotFoundError';
import { formatOrUndef } from '../../utils/stringUtils';
import useNavigation from '../../utils/useNavigation';
import { buildConceptInstanceFilterId } from '../_global/conceptFilterIdUtils';
import { isFilterReadOnly } from '../_global/filter/filterUtils';
import ActivityBar from '../_global/multiplayer/ActivityBar';
import type { NavigationFilter } from '../_global/navigationUtils';
import CollaborationRightPanel from '../_global/rightPanel/collaboration/CollaborationRightPanel';
import TopBar from '../_global/topBar/TopBar';
import type { ColumnBlockType } from './ConceptBlock';
import ConceptHeader from './ConceptHeader';
import ConceptMasterDetail from './ConceptMasterDetail';
import type { SectionBlockType } from './ConceptSection';
import ConceptSection from './ConceptSection';
import { getBlockFieldDisplays, precomputeTab } from './conceptUtils';
import Ecosystem from './ecosystem/Ecosystem';
import AdminRoleDetail from './roleAdmin/AdminRoleDetail';
import AdminGroupDetail from './userAdmin/AdminGroupDetail';
import AdminUserDetail from './userAdmin/AdminUserDetail';

interface ConceptDetailPageProps {
  conceptDefinitionId: string,
  conceptId: string,
}

interface ConceptTab extends Tab {
  hash: string,
  render: () => ReactElement,
}

const ConceptDetailPage: FunctionComponent<ConceptDetailPageProps> = ({ conceptDefinitionId, conceptId }) => {
  const store = useStore();
  const { loggedUserId } = useAuth();

  const location = useLocation();
  const navigation = useNavigation<NavigationFilter>();

  const concept = store.getObject<ConceptStoreObject>(conceptId);

  const currentHash = location.hash.split('_')[0];

  if (!isConceptValid(store, conceptId)) {
    throw new ObjectNotFoundError('Instance is not valid', i18n`Concept`, conceptId);
  }

  const { canWriteObject } = useAcl();
  const readOnly: boolean = !canWriteObject(conceptId) || (
    isEmbeddedConceptInstance(concept) && Boolean(concept.navigate<FieldStoreObject>(KinshipRelation)[Field_IntegrationOnly])
  );

  const tabDisplayState = useRef<{ instanceId?: string, knownTabs: string[], overflowTabs: string[], persistentOverflowTabs: string[] }>({
    instanceId: undefined,
    knownTabs: [],
    overflowTabs: [],
    persistentOverflowTabs: [],
  });
  if (tabDisplayState.current.instanceId !== conceptId) {
    tabDisplayState.current = { instanceId: conceptId, knownTabs: [], overflowTabs: [], persistentOverflowTabs: [] };
  }

  const getColumns = (block: BlockStoreObject, isParentReadOnly: boolean): ColumnBlockType[] => block
    .navigateBack<BlockStoreObject>(BlockParent_Block)
    .filter((child) => child[Block_Type] === BlockType_Column)
    .filter((child) => getFilterFunction(store, child[Block_ViewDisplayCondition])?.({
      [FILTER_PARAMETER_CURRENT]: { type: 'single', id: concept.id },
      [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: loggedUserId },
    }) ?? true)
    .map((object) => ({
      object,
      fields: getBlockFieldDisplays(store, concept, object, loggedUserId).filter(({ isFiltered }) => !isFiltered),
      readOnly: isParentReadOnly || (isFilterReadOnly(getFilterFunction(store, object[Block_EditDisplayCondition])?.({
        [FILTER_PARAMETER_CURRENT]: { type: 'single', id: concept.id },
        [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: loggedUserId },
      })) ?? false),
    }))
    .filter(({ fields }) => fields.length > 0)
    .sort(extractAndCompareValue(({ object }) => object[Block_Rank] ?? '', compareRank));

  const getSectionBlockType = (block: BlockStoreObject, parentSection?: SectionBlockType): SectionBlockType => {
    const filterFunction = getFilterFunction(store, block[Block_EditDisplayCondition]);
    const isSectionReadOnly = readOnly || parentSection?.readOnly || Boolean(filterFunction && !filterFunction({
      [FILTER_PARAMETER_CURRENT]: { type: 'single', id: concept.id },
      [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: loggedUserId },
    }));
    return {
      object: block,
      readOnly: isSectionReadOnly,
      fields: getBlockFieldDisplays(store, concept, block, loggedUserId).filter(({ isFiltered }) => !isFiltered),
      columns: getColumns(block, isSectionReadOnly),
    };
  };

  const conceptTabs = store.getObject(conceptDefinitionId)
    .navigateBack<BlockStoreObject>(ConceptDefinition_Blocks)
    .filter((block) => block[Block_Type] === BlockType_Tab)
    .sort(compareProperty(Block_Rank, compareRank))
    .flatMap((tab) => {
      const { isGreyed, isVisible, sections, hash } = precomputeTab(store, concept, tab, loggedUserId);
      if (!isVisible) {
        return [];
      }

      if (tabDisplayState.current.knownTabs.indexOf(tab.id) === -1) {
        if (isGreyed) {
          tabDisplayState.current.overflowTabs.push(tab.id);
        }
        tabDisplayState.current.knownTabs.push(tab.id);
      }

      return [{
        key: tab.id,
        name: formatOrUndef(tab[Block_Name]),
        hash,
        menuItems: sections
          .map(({ id, name, hash: sectionHash }) => ({
            key: id,
            name,
            selected: location.hash === sectionHash,
            onClick: () => {
              navigation.replace(conceptId, joinObjects(location, { hash: sectionHash }));
            },
          })),
        isGreyed,
        inOverflow: hash !== currentHash && tabDisplayState.current.overflowTabs.indexOf(tab.id) !== -1,
        render: () => {
          const tabSection = getSectionBlockType(tab);
          return (
            <VerticalBlock key={tab.id}>
              <ConceptSection conceptId={conceptId} section={tabSection} readOnly={tabSection.readOnly} withSeparation={sections.length > 0} />
              {sections.map(({ id: sectionId }, index) => {
                const section = getSectionBlockType(store.getObject(sectionId), tabSection);
                return (
                  <ConceptSection
                    key={section.object.id}
                    readOnly={section.readOnly}
                    conceptId={conceptId}
                    section={section}
                    withSeparation={index < (sections.length - 1)}
                  />
                );
              })}
            </VerticalBlock>
          );
        },
      }];
    });

  const conceptTabMap = Object.fromEntries(conceptTabs.map((conceptTab) => [conceptTab.key, conceptTab]));

  const modelTabs: ConceptTab[] = conceptTabs
    .filter(({ key }) => !tabDisplayState.current.overflowTabs.includes(key) && !tabDisplayState.current.persistentOverflowTabs.includes(key));
  const persistentOverflowTabs: ConceptTab[] = tabDisplayState.current.persistentOverflowTabs.map((key) => conceptTabMap[key]).filter(Boolean);
  const overflowTabs: ConceptTab[] = tabDisplayState.current.overflowTabs.map((key) => conceptTabMap[key]).filter(Boolean);

  const overflowTabSelectedIndex = overflowTabs.findIndex((tab) => encodeURI(tab.hash) === currentHash || currentHash === tab.hash);
  if (overflowTabSelectedIndex !== -1) {
    // Move it to first position
    const selectedOverflowTab = overflowTabs[overflowTabSelectedIndex];
    overflowTabs.splice(overflowTabSelectedIndex, 1);
    overflowTabs.splice(0, 0, selectedOverflowTab);

    // Mark it at persistent for the next pass if no longer greyed
    const stateOverflowTabIndex = tabDisplayState.current.overflowTabs.indexOf(selectedOverflowTab.key);
    if (!selectedOverflowTab.isGreyed && stateOverflowTabIndex !== -1) {
      tabDisplayState.current.overflowTabs.splice(stateOverflowTabIndex, 1);
      tabDisplayState.current.persistentOverflowTabs.push(selectedOverflowTab.key);
    }
  }

  const hardcodedTabs: ConceptTab[] = [];

  const conceptDefinition = store.getObject(conceptDefinitionId);
  if (conceptDefinition[ConceptDefinition_ShowEcosystemTab]) {
    hardcodedTabs.push({
      key: 'ecosystem',
      name: i18n`Ecosystem`,
      hash: '#ecosystem',
      render: () => (<Ecosystem conceptId={conceptId} readOnly={readOnly} />),
    });
  }

  const isAdmin = hasPlatformCapability(store, loggedUserId, PlatformCapabilityAdmin);

  if (conceptDefinition.id === User && (isAdmin || loggedUserId === conceptId)) {
    hardcodedTabs.push({
      key: 'administration',
      name: i18n`Administration`,
      hash: '#administration',
      render: () => (<AdminUserDetail userId={conceptId} />),
    });
  }

  if (conceptDefinition.id === Group && isAdmin) {
    hardcodedTabs.push({
      key: 'administration',
      name: i18n`Administration`,
      hash: '#administration',
      render: () => (<AdminGroupDetail groupId={conceptId} />),
    });
  }

  if (conceptDefinition.id === ConceptRole && isAdmin) {
    hardcodedTabs.push({
      key: 'administration',
      name: i18n`Administration`,
      hash: '#administration',
      render: () => (<AdminRoleDetail roleId={conceptId} />),
    });
  }

  const tabs: ConceptTab[] = [...modelTabs, ...hardcodedTabs, ...persistentOverflowTabs, ...overflowTabs];
  const hashIndex = tabs.findIndex((tab) => encodeURI(tab.hash) === currentHash || currentHash === tab.hash);
  const selectedTabIndex = hashIndex !== -1 ? hashIndex : 0;

  return (
    <BaseLayout
      topBar={(<TopBar actions={(<ActivityBar objectId={conceptId} />)} />)}
      leftPanel={
        conceptDefinition[ConceptDefinition_ShowMasterDetail]
          ? (<LeftPanel><ConceptMasterDetail conceptId={conceptId} filterId={buildConceptInstanceFilterId(concept)} /></LeftPanel>)
          : undefined
      }
      header={(<ConceptHeader conceptId={conceptId} tabs={tabs} selectedTabIndex={selectedTabIndex} />)}
      content={tabs.length > 0 ? tabs[selectedTabIndex].render() : null}
      rightPanel={(<CollaborationRightPanel />)}
    />
  );
};

export default ConceptDetailPage;
