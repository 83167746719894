import type { Migration } from 'yooi-store';
import { MigrationIntroduceIntegrationDefaultExpiration, MigrationLeftBarFilters, MigrationMultiHomepage, MigrationPlatformCollaborationMessage } from '../../src/Migrations';
import { InstanceReferenceType, PathStepType } from '../conceptModule';
import { Concept, ConceptDefinition } from '../conceptModule/ids';
import type { TypeHelperRevision1 } from '../typeModule';
import { isInstanceOf } from '../typeModule';
import { Class_Instances, Instance_Of, TypeModuleId } from '../typeModule/ids';
import {
  CurrentPlatformConfiguration,
  Homepage,
  Homepage_DisplayConditions,
  Homepage_Path,
  Homepage_Rank,
  LeftBarItem,
  LeftBarItem_DisplayConditions,
  LeftBarItem_Path,
  PlatformConfiguration,
  PlatformConfiguration_CollaborationMessage,
  PlatformConfiguration_IntegrationDefaultExpiration,
} from './ids';

const migrations: Record<number, Migration> = {
  [MigrationIntroduceIntegrationDefaultExpiration]: {
    dependencies: [TypeModuleId],
    handler: ({ updateObject }, helpers) => {
      const typeHelper = helpers.typeHelper as TypeHelperRevision1;

      typeHelper.property(PlatformConfiguration, PlatformConfiguration_IntegrationDefaultExpiration);

      updateObject(CurrentPlatformConfiguration, { [PlatformConfiguration_IntegrationDefaultExpiration]: 30 });
    },
  },
  [MigrationMultiHomepage]: {
    dependencies: [TypeModuleId],
    handler: ({ getObject, getObjectOrNull, createObject, updateObject, deleteObject }, helpers) => {
      const typeHelper = helpers.typeHelper as TypeHelperRevision1;

      const PlatformConfiguration_Homepage = '3829d553-c4c7-40e9-a18f-e7a5ca84f6dd';

      typeHelper.type(Homepage);
      typeHelper.property(Homepage, Homepage_Path);
      typeHelper.property(Homepage, Homepage_DisplayConditions);
      typeHelper.property(Homepage, Homepage_Rank);

      type Bookmark = { type: 'concept', path: string[] } | { type: 'custom', id: string };
      const previousHomepage = getObject(CurrentPlatformConfiguration)[PlatformConfiguration_Homepage] as Bookmark | undefined;
      if (previousHomepage !== undefined && previousHomepage.type === 'concept' && previousHomepage.path.length === 2) {
        const concept = getObjectOrNull(previousHomepage.path[1]);
        if (concept !== null) {
          createObject({
            [Instance_Of]: Homepage,
            [Homepage_Path]: [{ type: 'dimension', conceptDefinitionId: concept[Instance_Of] }, { type: 'mapping', mapping: { mapping: 'instance', id: concept.id } }],
            [Homepage_Rank]: '       !',
          });
        }
      }

      updateObject(CurrentPlatformConfiguration, { [PlatformConfiguration_Homepage]: null });

      deleteObject(PlatformConfiguration_Homepage);
    },
  },
  [MigrationPlatformCollaborationMessage]: {
    dependencies: [TypeModuleId],
    handler: (_, helpers) => {
      const typeHelper = helpers.typeHelper as TypeHelperRevision1;
      typeHelper.property(PlatformConfiguration, PlatformConfiguration_CollaborationMessage);
    },
  },
  [MigrationLeftBarFilters]: {
    dependencies: [TypeModuleId],
    handler: ({ getObject, getObjectOrNull, updateObject, deleteObject }, helpers) => {
      const LeftBarItem_Bookmark = '814f0a42-9f0e-4eee-a570-a4e09295fab8';

      enum BookmarkType {
        concept = 'concept',
        custom = 'custom',
      }

      interface ConceptBookmark {
        type: BookmarkType.concept,
        path: string[],
      }

      interface CustomBookmark {
        type: BookmarkType.custom,
        id: string,
      }

      type Bookmark = ConceptBookmark | CustomBookmark;

      const typeHelper = helpers.typeHelper as TypeHelperRevision1;
      typeHelper.property(LeftBarItem, LeftBarItem_Path);
      typeHelper.property(LeftBarItem, LeftBarItem_DisplayConditions);

      getObject(LeftBarItem)
        .navigateBack(Class_Instances)
        .forEach((leftBarItem) => {
          if ((leftBarItem[LeftBarItem_Bookmark] as Bookmark)?.type === BookmarkType.concept) {
            const newPath = [];
            const { path } = (leftBarItem[LeftBarItem_Bookmark] as ConceptBookmark);
            if (path.length > 0) {
              if (isInstanceOf(getObjectOrNull(path[0]), ConceptDefinition)) {
                newPath.push({ type: PathStepType.dimension, conceptDefinitionId: path[0] });

                if (path.length === 2 && isInstanceOf(getObjectOrNull(path[1]), Concept)) {
                  newPath.push({ type: PathStepType.mapping, mapping: { type: InstanceReferenceType.instance, id: path[1] } });
                }
              }
            }

            updateObject(leftBarItem.id, { [LeftBarItem_Path]: newPath });
          }

          updateObject(leftBarItem.id, { [LeftBarItem_Bookmark]: null });
        });

      deleteObject(LeftBarItem_Bookmark);
    },
  },
};

export default migrations;
