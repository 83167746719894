import type { FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import type { ConceptDefinitionStoreObject, IconFieldStoreObject, PathStep } from 'yooi-modules/modules/conceptModule';
import {
  createValuePathResolver,
  displayInstanceFieldAsText,
  FILTER_PARAMETER_LOGGED_USER,
  getConceptDefinitionUrl,
  getConceptUrl,
  getFieldDimensionOfModelType,
  getFilterFunction,
  getFirstClassInstance,
  iconFieldHandler,
  isConceptValid,
  isDimensionStep,
  isSingleValueResolution,
} from 'yooi-modules/modules/conceptModule';
import { Concept_Name, ConceptDefinition_Icon, ConceptDefinition_MainIconField } from 'yooi-modules/modules/conceptModule/ids';
import type { LeftBarItemStoreObject, PlatformConfigurationStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import {
  CurrentPlatformConfiguration,
  LeftBarItem,
  LeftBarItem_DisplayConditions,
  LeftBarItem_Path,
  LeftBarItem_Rank,
  PlatformConfiguration_LeftBarImage,
} from 'yooi-modules/modules/platformConfigurationModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
// eslint-disable-next-line yooi/no-restricted-dependency
import { isStoreObject } from 'yooi-store';
import { compareProperty, compareRank } from 'yooi-utils';
import Avatar, { AvatarSizes, AvatarVariant } from '../../components/atoms/Avatar';
import { IconName } from '../../components/atoms/Icon';
import SidebarLink, { SidebarLinkVariants } from '../../components/atoms/SidebarLink';
import Menu from '../../components/molecules/Menu';
import useAuth from '../../store/useAuth';
import useStore from '../../store/useStore';
import { buildMargins, Spacing } from '../../theme/spacingDefinition';
import { doFetch } from '../../utils/fetchUtils';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import { formatOrUndef } from '../../utils/stringUtils';
import { OverlayContextProvider } from '../../utils/useOverlayContainerRef';
import { getHomepageConcept } from '../utils/homepageUtils';
import { getUserName } from '../utils/userUtils';
import { isConceptPromotedAsLeftBarIcon } from './conceptUtils';
import { getConceptDefinitionNameOrEntity } from './modelTypeUtils';
import { getCurrentConceptDefinition, getCurrentConceptInstance, isValidLeftBarItem } from './navigationUtils';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '5.6rem',
    backgroundColor: theme.color.background.neutral.subtle,
    paddingTop: '0.2rem',
    paddingBottom: '2rem',
    zIndex: 3,
  },
  spacer: {
    height: '4rem',
    minHeight: '4rem',
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    overflowY: 'scroll',
    // Hide the scrollbar as we still wants the container to be vertically scrollable
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: 0,
    },
  },
  staticContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatarContainer: buildMargins({ top: Spacing.text }),
}), 'leftBar');

const LeftBar: FunctionComponent = () => {
  const classes = useStyles();

  const store = useStore();
  const { loggedUserId } = useAuth();

  const navRef = useRef<HTMLElement>(null);

  const [showMenu, setShowMenu] = useState(false);
  const avatarRef = useRef<HTMLDivElement>(null);

  const platformConfiguration = store.getObject<PlatformConfigurationStoreObject>(CurrentPlatformConfiguration);
  const homepageConcept = getHomepageConcept(store, loggedUserId);

  const attachmentUrl = store.getAttachmentUrl(CurrentPlatformConfiguration, PlatformConfiguration_LeftBarImage);

  const pathResolver = createValuePathResolver(store, { [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: store.getLoggedUserId() } });

  return (
    <nav ref={navRef} className={classes.rootContainer}>
      <OverlayContextProvider containerRef={navRef}>
        <div className={classes.staticContainer}>
          <SidebarLink
            id={homepageConcept?.id ?? 'organization'}
            iconName={platformConfiguration[PlatformConfiguration_LeftBarImage] === undefined ? IconName.yooi_logo : undefined}
            image={
              platformConfiguration[PlatformConfiguration_LeftBarImage] !== undefined
                ? {
                  url: attachmentUrl(platformConfiguration[PlatformConfiguration_LeftBarImage].revisionId, true),
                  alt: i18n`Application logo`,
                  size: {
                    width: platformConfiguration[PlatformConfiguration_LeftBarImage].width,
                    height: platformConfiguration[PlatformConfiguration_LeftBarImage].height,
                  },
                }
                : undefined
            }
            name={i18n`Home`}
            to="/"
            customMatch={(_, location) => location.pathname === '/'}
            variant={SidebarLinkVariants.logo}
          />
        </div>
        <div className={classes.spacer} />
        <div className={classes.linksContainer}>
          {
            store.getObject(LeftBarItem)
              .navigateBack<LeftBarItemStoreObject>(Class_Instances)
              .filter((leftBarItem) => isValidLeftBarItem(store, leftBarItem))
              .sort(compareProperty(LeftBarItem_Rank, compareRank))
              .filter((leftBarItem) => {
                const filterFunction = getFilterFunction(store, leftBarItem[LeftBarItem_DisplayConditions]);
                return filterFunction === undefined || filterFunction({ [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: store.getLoggedUserId() } });
              })
              .map((leftBarItem) => {
                const path = leftBarItem[LeftBarItem_Path];

                if (path?.length === 1 && isDimensionStep(path[0])) {
                  const conceptDefinition = store.getObject(path[0].conceptDefinitionId);
                  return (
                    <SidebarLink
                      key={leftBarItem.id}
                      id={conceptDefinition.id}
                      iconName={conceptDefinition[ConceptDefinition_Icon] as IconName}
                      name={getConceptDefinitionNameOrEntity(store, conceptDefinition.id)}
                      to={getConceptDefinitionUrl(conceptDefinition.id)}
                      customMatch={(_, location) => {
                        const currentConcept = getCurrentConceptInstance(store, location);
                        if (currentConcept && isConceptValid(store, currentConcept.id)) {
                          const firstClassConcept = getFirstClassInstance(currentConcept);
                          return !isConceptPromotedAsLeftBarIcon(store, firstClassConcept.id)
                            && firstClassConcept[Instance_Of] === conceptDefinition.id;
                        }
                        return getCurrentConceptDefinition(store, location)?.id === conceptDefinition.id;
                      }}
                    />
                  );
                } else {
                  const valueResolution = pathResolver.resolvePathValue(leftBarItem[LeftBarItem_Path] as PathStep[]);
                  if (isSingleValueResolution(valueResolution) && isStoreObject(valueResolution.value)) {
                    const concept = valueResolution.value;
                    const conceptDefinition = concept.navigate<ConceptDefinitionStoreObject>(Class_Instances);
                    const mainIconField = conceptDefinition.navigateOrNull<IconFieldStoreObject>(ConceptDefinition_MainIconField);
                    let iconName: IconName | undefined;
                    if (mainIconField !== null) {
                      const mainIconDimensionId = getFieldDimensionOfModelType(store, mainIconField.id, conceptDefinition.id);
                      if (mainIconDimensionId !== undefined) {
                        iconName = iconFieldHandler(store, mainIconField.id).getValueResolution({ [mainIconDimensionId]: concept.id }).value?.icon as IconName | undefined;
                      }
                    }

                    return (
                      <SidebarLink
                        key={leftBarItem.id}
                        id={concept.id}
                        name={formatOrUndef(displayInstanceFieldAsText(store, concept.id, Concept_Name))}
                        iconName={iconName}
                        to={getConceptUrl(store, concept.id)}
                        customMatch={(_, location) => {
                          const currentConceptInstance = getCurrentConceptInstance(store, location);
                          if (currentConceptInstance && isConceptValid(store, currentConceptInstance.id)) {
                            return currentConceptInstance && getFirstClassInstance(currentConceptInstance).id === concept.id;
                          }
                          return false;
                        }}
                      />
                    );
                  } else {
                    return null;
                  }
                }
              })
          }
        </div>
        <div className={classes.spacer} />
        <div className={classes.staticContainer}>
          <SidebarLink
            id="settings_admin"
            iconName={IconName.settings}
            name={i18n`Settings`}
            to="/settings"
          />
          <div className={classes.avatarContainer}>
            <Avatar
              ref={avatarRef}
              name={getUserName(store.getObject(loggedUserId)).charAt(0).toUpperCase()}
              variant={AvatarVariant.leftBar}
              onClick={(event) => {
                event.preventDefault();
                setShowMenu(true);
              }}
              size={AvatarSizes.small}
            />
          </div>
          {showMenu ? (
            <Menu
              items={[
                {
                  key: 'logout',
                  name: i18n`Logout`,
                  onClick: () => {
                    setShowMenu(false);
                    doFetch('/logout');
                  },
                },
              ]}
              anchorRef={avatarRef}
              onClose={() => {
                setShowMenu(false);
              }}
              placement="right"
              offset={avatarRef.current ? [0, -avatarRef.current.clientWidth - remToPx(1)] : undefined}
            />
          ) : null}
        </div>
      </OverlayContextProvider>
    </nav>
  );
};

export default LeftBar;
