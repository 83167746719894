import type { AccessControlListResult, ObjectStoreReadOnly, StoreObject } from 'yooi-store';
import { OriginSources, ValidationStatus } from 'yooi-store';
import { compareRank, ranker, toSnakeCase } from 'yooi-utils';
import {
  CollaborationGroups,
  CollaborationGroups_Role_Group,
  CollaborationOnMultipleContext,
  CollaborationOnMultipleContext_Role_Collaboration,
  CollaborationOnMultipleContext_VarRoles_Contexts,
  CollaborationUsers,
  CollaborationUsers_Role_Collaboration,
  CollaborationUsers_Role_User,
} from '../../collaborationModule/ids';
import { buildValidateApiLabelBusinessRule, checkObjectRankConflicts } from '../../common/businessRules';
import { asImport, asLocal, CommonAsType } from '../../common/fields/commonPropertyType';
import type { BusinessRuleRegistration } from '../../common/types/TypeModuleDslType';
import { PropertyMandatoryType } from '../../common/types/TypeModuleDslType';
import { isInstanceOf } from '../../typeModule';
import { Class_Instances, Instance_Of, ModelType } from '../../typeModule/ids';
import {
  Association,
  Association_Role_Definition,
  Association_Role_Role1TypeInstance,
  Association_Role_Role2TypeInstance,
  Concept_SwimlaneRank,
  ConceptCapabilityAssignCollaborator,
  ConceptCapabilityAssignUser,
  ConceptCapabilityCreate,
  ConceptCapabilityDelete,
  ConceptCapabilityEdit,
  ConceptCapabilityOverrideCollaborationWorkflow,
  ConceptCapabilityOverrideWorkflow,
  ConceptCapabilityRead,
  ConceptDefinition,
  ConceptDefinition_ApiAlias,
  ConceptDefinition_ConceptCapability_User_HasCapability,
  ConceptDefinition_HasStakeholders,
  ConceptDefinition_Icon,
  ConceptDefinition_IsCore,
  ConceptDefinition_LibraryShowTable,
  ConceptDefinition_Name,
  ConceptDefinition_Roles,
  ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition,
  ConceptDefinitionInstanceAdministrationColumn_Rank,
  ConceptIntegrationCapability,
  ConceptIntegrationCapability_Role_ConceptCapability,
  ConceptIntegrationCapability_Role_Definition,
  ConceptIntegrationCapability_Role_Integration,
  ConceptRole_AssignedByDefault,
  ConceptRoleGroupAssignation,
  ConceptRoleGroupAssignation_Role_Concept,
  ConceptRoleGroupAssignation_Role_Group,
  ConceptRoleUserAssignation,
  ConceptRoleUserAssignation_Role_Concept,
  ConceptRoleUserAssignation_Role_ConceptRole,
  ConceptRoleUserAssignation_Role_User,
  Field,
  Group,
  GroupMembershipAssociationDefinition,
  IconField,
  PlatformCapabilityAdmin,
  PlatformCapabilityInviteUser,
  User,
  User_GroupIds,
} from '../ids';
import { hasConceptCapability, registerModel } from '../module';
import type { FilterFunction } from '../utils';
import {
  adminOnlyAcl,
  computeDenormalizedPermission,
  getConceptDefinitionChipFieldsInternal,
  getFilterConditionContext,
  getFilterFunctionHandler,
  getParentConceptInstance,
  hasOnePlatformCapabilitiesOf,
  isConceptValid,
  isConceptValidPrivate,
  isEmbeddedConceptInstance,
} from '../utils';
import type { ConceptDefinitionStoreObject, ConceptRoleStoreObject, ConceptStoreObject } from './types';

const { type } = registerModel;

const applyDefaultRolesOnConcept: BusinessRuleRegistration = ({ getObject, getObjectOrNull }) => ({ userId, source }, { id, properties }) => {
  // Timeseries update or deletion ? Don't care
  if (!properties) {
    return undefined;
  }

  // No userId in origin ? Skip update
  if (!userId) {
    return undefined;
  }

  // Coming from integration ? Skip update
  if (source === OriginSources.API) {
    return undefined;
  }

  let conceptDefinitionId = properties[Instance_Of] as string;

  // Existing object ? Don't care
  const currentObject = getObjectOrNull(id[0]);
  if (currentObject) {
    // If we are promoting a embedded as first class
    if (isEmbeddedConceptInstance(currentObject)) {
      conceptDefinitionId = currentObject[Instance_Of] as string;
    } else {
      return undefined;
    }
  }

  const defaultRoles = getObject<ConceptDefinitionStoreObject>(conceptDefinitionId)
    .navigateBack<ConceptRoleStoreObject>(ConceptDefinition_Roles)
    .filter((role) => role[ConceptRole_AssignedByDefault]);

  if (defaultRoles.length > 0) {
    return {
      rule: 'concept.role.assignedByDefault',
      status: ValidationStatus.ACCEPTED,
      generateSystemEvent: ({ withAssociation }) => {
        if (!isEmbeddedConceptInstance(getObject(id[0]))) {
          defaultRoles.forEach((role) => withAssociation(ConceptRoleUserAssignation)
            .withRole(ConceptRoleUserAssignation_Role_User, userId)
            .withRole(ConceptRoleUserAssignation_Role_Concept, id[0])
            .withRole(ConceptRoleUserAssignation_Role_ConceptRole, role.id)
            .updateObject({}));
        }
      },
    };
  }
  return undefined;
};

const hasConceptDefinitionCapability = (
  objectStore: ObjectStoreReadOnly,
  userId: string,
  objectId: string,
  newProperties: Record<string, unknown> | null | undefined,
  capability: string
): AccessControlListResult => {
  if (capability === ConceptCapabilityCreate) {
    let permission;
    if (newProperties?.[Instance_Of] === ConceptDefinition) {
      return { rule: 'conceptDefinition.creation', status: ValidationStatus.ACCEPTED };
    } else if (newProperties?.[Instance_Of]) {
      permission = computeDenormalizedPermission(objectStore, userId, objectId, newProperties[Instance_Of] as string);
    } else {
      permission = computeDenormalizedPermission(objectStore, userId, objectId, objectId);
    }
    if (permission && permission[capability]) {
      return { rule: `conceptDefinition.${objectId}.canCreate`, status: ValidationStatus.ACCEPTED };
    }
  }
  return { rule: `conceptDefinition.${capability}.forbidden`, status: ValidationStatus.REJECTED };
};

const applyDefaultConceptConfiguration: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  // Deletion, update ? Don't care
  if (!properties || getObjectOrNull(id) !== null) {
    return undefined;
  }

  return {
    rule: 'concept.applyDefaultConceptConfiguration',
    status: ValidationStatus.ACCEPTED,
    generateSystemEvent: (objectStore) => {
      const { updateObject, getObject } = objectStore;
      const conceptDefinition = getObject(id);
      updateObject(id, {
        [ConceptDefinition_Icon]: conceptDefinition[ConceptDefinition_Icon] === undefined ? 'category' : undefined,
        [ConceptDefinition_HasStakeholders]: conceptDefinition[ConceptDefinition_HasStakeholders] === undefined ? true : undefined,
        [ConceptDefinition_LibraryShowTable]: conceptDefinition[ConceptDefinition_LibraryShowTable] === undefined ? true : undefined,
      });
    },
  };
};

const cannotDeleteCoreConcept: BusinessRuleRegistration = ({ getObject }) => (origin, { id, properties }) => {
  // Deletion Only
  if (properties === null && origin.source === OriginSources.CLIENT) {
    const conceptDefinition = getObject(id);
    if (conceptDefinition[ConceptDefinition_IsCore]) {
      return {
        rule: 'concept.cannotDeleteCoreConcept',
        status: ValidationStatus.REJECTED,
      };
    }
  }
  return undefined;
};

const autoGenerateApiAlias: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  if (!properties) {
    // Deleting the concept definition ? don't care
    return undefined;
  } else if (!properties[ConceptDefinition_Name]) {
    // Clearing the concept definition name ? don't care
    return undefined;
  } else if (properties[ConceptDefinition_ApiAlias] !== undefined) {
    // Producing an update on the ApiAlias ? don't care
    return undefined;
  } else if (getObjectOrNull(id)?.[ConceptDefinition_ApiAlias]) {
    // ApiAlias already has a value ? don't care
    return undefined;
  }

  const apiAlias = toSnakeCase(properties[ConceptDefinition_Name] as string);
  if (apiAlias === '') {
    // Produced apiAlias is empty ? don't care
    return undefined;
  }
  return {
    rule: 'conceptDefinition.apiAlias.autogenerate',
    status: ValidationStatus.ACCEPTED,
    generateSystemEvent: ({ updateObject }) => {
      updateObject(id, { [ConceptDefinition_ApiAlias]: apiAlias });
    },
  };
};

export enum CardColorMode {
  Dot = 'Dot',
  Bar = 'Bar',
}

type({
  label: 'ConceptDefinition',
  extends: ModelType,
  objectDebugLabel: ({ getObjectOrNull }) => (objectId) => getObjectOrNull(objectId)?.[ConceptDefinition_Name] as string,
  accessControlList: {
    CREATE: (store) => ({ userId }, objectId, { newProperties }) => {
      if (objectId[0] === User && userId && hasOnePlatformCapabilitiesOf(store, userId, [PlatformCapabilityAdmin, PlatformCapabilityInviteUser])) {
        // User has enough privileges to invite/create users.
        return { rule: 'conceptDefinition.user.create.platformCapabilityAllow', status: ValidationStatus.ACCEPTED };
      } else {
        return userId ? hasConceptDefinitionCapability(store, userId, objectId[0], newProperties, ConceptCapabilityCreate) : undefined;
      }
    },
  },
  businessRules: [
    applyDefaultConceptConfiguration,
    cannotDeleteCoreConcept,
  ],
})
  .property({
    label: 'ConceptDefinition_Name',
    as: CommonAsType.string,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
    businessRules: [autoGenerateApiAlias],
  })
  .property({
    label: 'ConceptDefinition_Description',
    as: CommonAsType.string,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'ConceptDefinition_Icon',
    as: CommonAsType.string, // In reality IconName
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'ConceptDefinition_Prefix',
    as: CommonAsType.string,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({ label: 'ConceptDefinition_IsCore', as: CommonAsType.boolean })
  .property({ label: 'ConceptDefinition_FirstClassOnly', as: CommonAsType.boolean })
  .property({ label: 'ConceptDefinition_RestrictedAccess', as: CommonAsType.boolean })
  .property({
    label: 'ConceptDefinition_HasStakeholders',
    as: CommonAsType.boolean,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'ConceptDefinition_ShowEcosystemTab',
    as: CommonAsType.boolean,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'ConceptDefinition_ChipBackgroundColor',
    as: CommonAsType.string,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'ConceptDefinition_ChipDisplayIcon', // boolean
    as: CommonAsType.boolean,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_Color',
    targetTypeId: Field,
    reverseLabel: 'Field_DefaultColorField',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_ChipIconField',
    targetTypeId: Field,
    reverseLabel: 'Field_AsChipIcon',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_Timeline',
    targetTypeId: Field,
    reverseLabel: 'Field_DefaultTimelineField',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_TimelineDependency',
    targetTypeId: Field,
    reverseLabel: 'Field_DefaultTimelineDependencyField',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_TimelineProgress',
    targetTypeId: Field,
    reverseLabel: 'Field_DefaultTimelineProgressField',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_MatrixOrd',
    targetTypeId: Field,
    reverseLabel: 'Field_DefaultMatrixOrdField',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_MatrixAbs',
    targetTypeId: Field,
    reverseLabel: 'Field_DefaultMatrixAbsField',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_MatrixLabel',
    targetTypeId: Field,
    reverseLabel: 'Field_DefaultMatrixLabelField',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_MatrixDependency',
    targetTypeId: Field,
    reverseLabel: 'Field_DefaultMatrixDependencyField',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_SwimlaneProgress',
    targetTypeId: Field,
    reverseLabel: 'Field_DefaultSwimlaneProgressField',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_SwimlaneColumnBy',
    targetTypeId: Field,
    reverseLabel: 'Field_DefaultSwimlaneColumnByField',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_SwimlaneGroupBy',
    targetTypeId: Field,
    reverseLabel: 'Field_DefaultSwimlaneGroupByField',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_SanityScore',
    targetTypeId: Field,
    reverseLabel: 'Field_DefaultSanityScoreByField',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_TableGroupBy',
    targetTypeId: Field,
    reverseLabel: 'Field_DefaultTableGroupByField',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_TimelineGroupBy',
    targetTypeId: Field,
    reverseLabel: 'Field_DefaultTimelineGroupByField',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'ConceptDefinition_LibraryDisplayOption',
    as: asImport('LibraryDisplayOptions', 'modules/conceptLayoutModule'),
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'ConceptDefinition_LibraryItemPerPage',
    as: CommonAsType.number,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'ConceptDefinition_LibraryShowMatrix',
    as: CommonAsType.boolean,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'ConceptDefinition_LibraryShowSwimlane',
    as: CommonAsType.boolean,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'ConceptDefinition_LibraryShowTimeline',
    as: CommonAsType.boolean,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'ConceptDefinition_LibraryShowTable',
    as: CommonAsType.boolean,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'ConceptDefinition_ApiAlias',
    as: CommonAsType.string,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
    businessRules: [buildValidateApiLabelBusinessRule(ConceptDefinition_ApiAlias, 'conceptDefinition.apiAlias')],
  })
  .relation({
    label: 'ConceptDefinition_MainWorkflowField',
    targetTypeId: Field,
    reverseLabel: 'Field_AsMainWorkflow',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'ConceptDefinition_MainWorkflowOnSecondLine',
    as: CommonAsType.boolean,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_MainIconField',
    targetTypeId: IconField,
    reverseLabel: 'Field_AsMainIcon',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'ConceptDefinition_MainBooleanField',
    as: asImport('PathStep', 'modules/conceptModule', true),
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_CardTitleField',
    targetTypeId: Field,
    reverseLabel: 'Field_AsCardTitle',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_CardColorField',
    targetTypeId: Field,
    reverseLabel: 'Field_AsCardColor',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'ConceptDefinition_CardColorMode',
    as: asLocal('CardColorMode'),
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({
    label: 'ConceptDefinition_CardImageField',
    targetTypeId: Field,
    reverseLabel: 'Field_AsCardImage',
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .propertyFunction({ label: 'ConceptDefinition_ChipFields', computeFunction: (store) => (id) => getConceptDefinitionChipFieldsInternal(store, id[0]) })
  .property({
    label: 'ConceptDefinition_ShowMasterDetail',
    as: CommonAsType.boolean,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  });

type({
  label: 'ConceptDefinitionInstanceAdministrationColumn',
  accessControlList: {
    READ: () => () => ({ rule: 'conceptDefinitionInstanceAdministrationColumn.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'conceptDefinitionInstanceAdministrationColumn', 'WRITE'),
    DELETE: () => (_, objectId) => ({
      rule: 'conceptDefinitionInstanceAdministrationColumn.delete.delegate',
      status: ValidationStatus.DELEGATED,
      targetAction: 'WRITE',
      targetId: objectId,
    }),
  },
})
  .relation({
    label: 'ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition',
    reverseLabel: 'ConceptDefinition_AdministrationColumns',
    targetTypeId: ConceptDefinition,
    mandatory: { type: PropertyMandatoryType.mandatory },
  })
  .relation({
    label: 'ConceptDefinitionInstanceAdministrationColumn_Field',
    reverseLabel: 'Field_AsConceptDefinitionInstanceAdministrationColumn',
    targetTypeId: Field,
  })
  .property({
    label: 'ConceptDefinitionInstanceAdministrationColumn_Width',
    as: asImport('Width', 'modules/conceptModule/moduleType'),
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'ConceptDefinitionInstanceAdministrationColumn_Rank',
    as: CommonAsType.string,
    mandatory: { type: PropertyMandatoryType.mandatory },
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
    businessRules: [checkObjectRankConflicts(ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition, ConceptDefinitionInstanceAdministrationColumn_Rank)],
  });

type({
  label: 'Concept',
  accessControlList: {
    CREATE: (store) => (origin, objectId, { newProperties }, readAcl) => (
      hasConceptCapability(store, origin, objectId[0], newProperties, ConceptCapabilityCreate, 'WRITE', readAcl)
    ),
    READ: (store) => ({ source, userId }, objectId) => {
      if (!userId || !isConceptValid(store, objectId[0])) {
        return undefined;
      }

      const concept = store.getObject<ConceptStoreObject>(objectId[0]);
      const userOrIntegration = store.getObjectOrNull(userId);
      if (!userOrIntegration) {
        return undefined;
      }

      if (isInstanceOf(userOrIntegration, User) && isInstanceOf(concept, Group)) {
        if (
          store.withAssociation(Association)
            .withRole(Association_Role_Definition, GroupMembershipAssociationDefinition)
            .withRole(Association_Role_Role1TypeInstance, concept.id)
            .withRole(Association_Role_Role2TypeInstance, userOrIntegration.id)
            .getObjectOrNull() !== null
        ) {
          return { rule: 'group.read.belongToGroup', status: ValidationStatus.ACCEPTED };
        }
      }

      if (isEmbeddedConceptInstance(concept)) {
        const parent = getParentConceptInstance(concept);
        if (parent) {
          return { rule: 'concept.read.parent.delegate', status: ValidationStatus.DELEGATED, targetAction: 'READ', targetId: [parent.id] };
        } else {
          // This branch should never be reached as an embedded without his parent is an invalid concept
          return { rule: 'concept.read.parent.missing', status: ValidationStatus.REJECTED };
        }
      } else if (!concept.navigate(Instance_Of)[ConceptDefinition_HasStakeholders]) {
        return { rule: 'concept.read.openBar', status: ValidationStatus.ACCEPTED };
      } else if (source === OriginSources.API) {
        const isIntegrationAllowed = store.withAssociation(ConceptIntegrationCapability)
          .withRole(ConceptIntegrationCapability_Role_Definition, concept[Instance_Of])
          .withRole(ConceptIntegrationCapability_Role_ConceptCapability, ConceptCapabilityRead)
          .withRole(ConceptIntegrationCapability_Role_Integration, userId)
          .getObjectOrNull() !== null;
        return { rule: 'concept.read.integration', status: isIntegrationAllowed ? ValidationStatus.ACCEPTED : ValidationStatus.REJECTED };
      } else {
        if (store.getObject([concept[Instance_Of], ConceptCapabilityRead, userId], true)[ConceptDefinition_ConceptCapability_User_HasCapability]) {
          return { rule: 'concept.read.global', status: ValidationStatus.ACCEPTED };
        }

        const userDirectAssignations = store.withAssociation(ConceptRoleUserAssignation)
          .withRole(ConceptRoleUserAssignation_Role_Concept, objectId[0])
          .withRole(ConceptRoleUserAssignation_Role_User, userId)
          .list();
        if (userDirectAssignations.length > 0) {
          return { rule: 'concept.read.stakeholder.user', status: ValidationStatus.ACCEPTED };
        }

        const userGroupIds = userOrIntegration[User_GroupIds] as string[];
        if (userGroupIds.some((groupId) => (
          store.withAssociation(ConceptRoleGroupAssignation)
            .withRole(ConceptRoleGroupAssignation_Role_Concept, objectId[0])
            .withRole(ConceptRoleGroupAssignation_Role_Group, groupId)
            .list()
            .length > 0
        ))) {
          return { rule: 'concept.read.stakeholder.user', status: ValidationStatus.ACCEPTED };
        }

        if (
          store.withAssociation(CollaborationOnMultipleContext)
            .withRole(CollaborationOnMultipleContext_VarRoles_Contexts, objectId[0])
            .list()
            .some((context) => {
              const collaborationId = context.role(CollaborationOnMultipleContext_Role_Collaboration);

              if (store.withAssociation(CollaborationUsers)
                .withRole(CollaborationUsers_Role_User, userId)
                .withRole(CollaborationUsers_Role_Collaboration, collaborationId)
                .getOrNull()) {
                return true;
              }

              return userGroupIds.some((groupId) => store.withAssociation(CollaborationGroups)
                .withRole(CollaborationGroups_Role_Group, groupId)
                .withRole(CollaborationUsers_Role_Collaboration, collaborationId)
                .getOrNull());
            })
        ) {
          return { rule: 'concept.read.collaborator.user', status: ValidationStatus.ACCEPTED };
        }
      }

      return undefined;
    },
    WRITE: (store) => (origin, objectId, { newProperties }, readAcl) => {
      if (store.getObjectOrNull(objectId)?.[Instance_Of] === User) {
        // User ACL. Allow a user to edit his data without edition rights.
        return undefined;
      } else {
        return hasConceptCapability(store, origin, objectId[0], newProperties, ConceptCapabilityEdit, 'WRITE', readAcl);
      }
    },
    DELETE: (store) => (origin, objectId, { newProperties }, readAcl) => (
      hasConceptCapability(store, origin, objectId[0], newProperties, ConceptCapabilityDelete, 'WRITE', readAcl)
    ),
    ASSIGN_STAKEHOLDERS: (store) => (origin, objectId, { newProperties }, readAcl) => (
      hasConceptCapability(store, origin, objectId[0], newProperties, ConceptCapabilityAssignUser, 'WRITE', readAcl)
    ),
    ASSIGN_COLLABORATOR: (store) => (origin, objectId, { newProperties }, readAcl) => (
      hasConceptCapability(store, origin, objectId[0], newProperties, ConceptCapabilityAssignCollaborator, 'WRITE', readAcl)
    ),
    OVERRIDE_WORKFLOW: (store) => (origin, objectId, { newProperties }, readAcl) => (
      hasConceptCapability(store, origin, objectId[0], newProperties, ConceptCapabilityOverrideWorkflow, 'OVERRIDE_WORKFLOW', readAcl)
    ),
    OVERRIDE_COLLABORATION_WORKFLOW: (store) => (origin, objectId, { newProperties }, readAcl) => (
      hasConceptCapability(store, origin, objectId[0], newProperties, ConceptCapabilityOverrideCollaborationWorkflow, 'OVERRIDE_COLLABORATION_WORKFLOW', readAcl)
    ),
  },
  businessRules: [applyDefaultRolesOnConcept],
})
  .property({
    label: 'Concept_SwimlaneRank',
    as: CommonAsType.string,
    mandatory: {
      type: PropertyMandatoryType.mandatoryWithDefaultValue,
      getDefaultValue: ({ getObject }, id) => {
        const maxRank = getObject(id)
          .navigate(Instance_Of)
          .navigateBack<StoreObject & { [Concept_SwimlaneRank]: string | undefined }>(Class_Instances)
          .filter((instance) => instance.id !== id[0])
          .reduce<string | undefined>(
            (accumulator, { [Concept_SwimlaneRank]: rank }) => {
              if (accumulator === undefined) {
                return rank;
              } else if (rank === undefined) {
                return accumulator;
              } else if (compareRank(rank, accumulator) > 0) {
                return rank;
              } else {
                return accumulator;
              }
            },
            undefined
          );
        return ranker.createNextRankGenerator(maxRank)();
      },
    },
    businessRules: [checkObjectRankConflicts(Instance_Of, Concept_SwimlaneRank)],
  })
  .propertyFunction({ label: 'Concept_IsValid', computeFunction: (store) => (id) => isConceptValidPrivate(store, id[0]) })
  .propertyFunctionWithTimeseries({
    label: 'Concept_GetFilterConditionContext',
    computeFunction: (store) => ([filterConditionKey]) => getFilterConditionContext(store, JSON.parse(filterConditionKey)),
  })
  .propertyFunctionWithTimeseries({
    label: 'Concept_GetFilterFunction',
    computeFunction: (store) => ([filtersKey]): FilterFunction => getFilterFunctionHandler(store, JSON.parse(filtersKey)),
  });

export const testables = {
  applyDefaultRolesOnConcept,
};
