import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { Fragment, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import type { FieldOperation } from 'yooi-modules/modules/automationModule';
import type { MultipleParameterDefinition, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { getAllFieldDimensionsLinkedConceptDefinitionIds, getConceptDefinitionValidFields } from 'yooi-modules/modules/conceptModule';
import { Field_Formula, Field_IntegrationOnly } from 'yooi-modules/modules/conceptModule/ids';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import Typo from '../../../components/atoms/Typo';
import OverflowMenu from '../../../components/molecules/OverflowMenu';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../components/molecules/SpacingLine';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import CardBlock from '../../../components/templates/CardBlock';
import HorizontalBlock, { HorizontalBlockVariant } from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import base from '../../../theme/base';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { SizeVariant } from '../../../utils/useSizeContext';
import useTheme from '../../../utils/useTheme';
import { getFieldHandler } from '../../_global/fields/FieldLibrary';
import { getFieldChip } from '../../_global/fieldUtils';
import { defaultOptionComparator, getChipOptions, getChipOptionWithUnknown } from '../../_global/modelTypeUtils';
import AutomationCardTitle from './AutomationCardTitle';

const useStyles = makeStyles((theme) => ({
  line: {
    display: 'grid',
    gridTemplateColumns: '2.8rem 1fr',
  },
  indicator: {
    display: 'flex',
    flexDirection: 'column',
  },
  positionContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '2rem',
  },
  numberBubble: {
    width: '2rem',
    height: '2rem',
    borderRadius: base.borderRadius.circle,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '0.2rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.hover,
    cursor: 'default',
  },
  firstVerticalLineContainer: {
    display: 'flex',
    height: '2.3rem',
    paddingTop: '1rem',
  },
  firstVerticalDivider: {
    width: '0.2rem',
    backgroundColor: theme.color.border.hover,
    marginLeft: '0.9rem',
    borderTopLeftRadius: '9rem',
    borderTopRightRadius: '9rem',
  },

  verticalLineContainer: {
    height: '2.3rem',
    display: 'flex',
  },
  verticalDivider: {
    width: '0.2rem',
    backgroundColor: theme.color.border.hover,
    marginLeft: '0.9rem',
  },

  lastVerticalLineContainer: {
    display: 'flex',
    flex: 1,
    minHeight: '1rem',
  },
  lastVerticalDivider: {
    width: '0.2rem',
    backgroundColor: theme.color.border.hover,
    marginLeft: '0.9rem',
    borderBottomLeftRadius: '9rem',
    borderBottomRightRadius: '9rem',
  },
  dashed: {
    background: `repeating-linear-gradient(to bottom, ${theme.color.border.hover}, ${theme.color.border.hover} 0.5rem, ${theme.color.transparent} 0.5rem, ${theme.color.transparent} 1rem)`,
    marginBottom: '1rem',
  },
  divider: {
    borderTop: 'solid 0.2rem',
    borderTopColor: theme.color.border.hover,
    flex: 1,
  },

  cardContainer: {
    padding: spacingRem.m,
  },
  addCard: {
    gridColumnStart: 2,
    cursor: 'pointer',
    display: 'flex',
    height: '6.4rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: base.borderRadius.medium,
    borderStyle: 'dashed',
    borderWidth: '0.1rem',
    backgroundColor: theme.color.transparent,
    borderColor: theme.color.border.primarylight,
    '&:hover': {
      backgroundColor: theme.color.background.primarylight.default,
    },
    '&:focus-visible': {
      backgroundColor: theme.color.background.primarylight.default,
    },
    '&:active': {
      backgroundColor: theme.color.background.primarylight.hover,
    },
  },
  lineGap: {
    gridColumnStart: 2,
    height: spacingRem.m,
  },
  operationLineGap: {
    display: 'flex',
    gridColumnStart: 1,
    gridColumnEnd: 3,
    height: spacingRem.m,
  },
}), 'fieldOperationsList');

interface FieldOperationsListProps {
  conceptDefinitionId: string | undefined,
  fieldOperations: FieldOperation[],
  onChange: (newFieldOperations: FieldOperation[]) => void,
  newLineFocus?: {
    id: string,
    reset: () => void,
  },
  collapseHelper: {
    isCollapsed: (id: string) => boolean,
    onCollapse: (id: string) => void,
    onExpand: (id: string) => void,
  },
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
}

const FieldOperationsList: FunctionComponent<FieldOperationsListProps> = ({
  conceptDefinitionId, fieldOperations, onChange, newLineFocus, collapseHelper, parameterDefinitions,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const localNewLineFocusRef = useRef<string | undefined>();
  const lineFocusId = newLineFocus?.id ?? localNewLineFocusRef.current;
  localNewLineFocusRef.current = undefined;
  newLineFocus?.reset();

  const fields = conceptDefinitionId ? getConceptDefinitionValidFields(store, conceptDefinitionId)
    .filter(({ id: fieldId }) => getFieldHandler(store, fieldId)?.getUpdateOperationInput)
    .filter((field) => !field[Field_IntegrationOnly] && !field[Field_Formula]) : [];

  const updateFieldOperation = (id: string, newOperation: Omit<FieldOperation, 'id'>) => onChange(
    fieldOperations
      .map((oldOperation) => (oldOperation.id !== id ? oldOperation : joinObjects({ id }, newOperation)))
  );

  const addFieldOperation = () => {
    const id = uuid();
    onChange([...fieldOperations ?? [], { id }]);
    return id;
  };

  const deleteFieldOperation = (id: string) => onChange(
    fieldOperations
      .filter((oldOperation) => oldOperation.id !== id)
  );

  const duplicateFieldOperation = (id: string) => onChange(
    fieldOperations
      .flatMap((operation) => {
        if (operation.id === id) {
          return [operation, joinObjects(operation, { id: uuid() })];
        } else {
          return [operation];
        }
      })
  );

  return (
    <div className={classes.line}>
      {fieldOperations?.map(({ id: fieldOperationId, fieldId, label, operation }, index, self) => {
        let updateOperationInput;
        let icon;
        let selectedFieldOption;
        let fieldError;
        if (fieldId) {
          selectedFieldOption = getChipOptionWithUnknown(store, fieldId);
          if (store.getObjectOrNull(fieldId) !== null) {
            updateOperationInput = getFieldHandler(store, fieldId)?.getUpdateOperationInput?.(operation);
            icon = updateOperationInput?.getIcon(theme);
            fieldError = Boolean(fields.findIndex(({ id: availableFieldId }) => availableFieldId === fieldId) === -1);
            const fieldConceptDefinitionId = !fieldError ? conceptDefinitionId : getAllFieldDimensionsLinkedConceptDefinitionIds(store, fieldId).at(0);
            if (fieldConceptDefinitionId) {
              selectedFieldOption = getFieldChip(store, fieldConceptDefinitionId, fieldId);
            }
          }
        }
        return (
          <Fragment key={fieldOperationId}>
            <div className={classes.indicator}>
              {index === 0 && (
                <div className={classes.firstVerticalLineContainer}>
                  <span className={classes.firstVerticalDivider} />
                </div>
              )}
              {index > 0 && (
                <div className={classes.verticalLineContainer}>
                  <span className={classes.verticalDivider} />
                </div>
              )}
              <div className={classes.positionContainer}>
                <div className={classes.numberBubble}>
                  <Typo color={theme.color.text.secondary}>{index + 1}</Typo>
                </div>
                <div className={classes.divider} />
              </div>
              <div className={classes.lastVerticalLineContainer}>
                <span
                  className={classnames({
                    [classes.lastVerticalDivider]: true,
                    [classes.dashed]: (index + 1) === self.length,
                  })}
                />
              </div>
            </div>
            <CardBlock
              collapseHelper={{
                isCollapsed: collapseHelper.isCollapsed(fieldOperationId),
                onExpand: () => collapseHelper.onExpand(fieldOperationId),
                onCollapse: () => collapseHelper.onCollapse(fieldOperationId),
              }}
              titleContent={(
                <AutomationCardTitle
                  placeholder={i18n`Operation title`}
                  onLabelUpdate={(newLabel) => updateFieldOperation(fieldOperationId, { operation, fieldId, label: newLabel ?? undefined })}
                  label={label || updateOperationInput?.title}
                  icon={icon?.name ?? IconName.add}
                  iconColor={icon?.color ?? theme.color.text.success}
                  backgroundColor={icon?.backgroundColor ?? theme.color.background.primarylight.default}
                />
              )}
              content={(
                <div className={classes.cardContainer}>
                  <VerticalBlock>
                    <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                      <BlockTitle title={i18n`Field`} />
                      <BlockContent>
                        <SearchAndSelect
                          placeholder={i18n`Select a field`}
                          selectedOption={selectedFieldOption}
                          computeOptions={() => fields
                            .map(({ id: optionId }) => getChipOptions(store, optionId))
                            .filter(filterNullOrUndefined)
                            .sort(defaultOptionComparator)}
                          onSelect={(option) => {
                            updateFieldOperation(fieldOperationId, { label, fieldId: option?.id });
                          }}
                          statusIcon={fieldError ? {
                            icon: IconName.warning,
                            color: IconColorVariant.error,
                            message: i18n`This field is not compatible with current configuration`,
                          } : undefined}
                          scrollOnMount={fieldOperationId === lineFocusId}
                          clearable
                        />
                      </BlockContent>
                    </HorizontalBlock>
                    {updateOperationInput?.render({
                      conceptDefinitionId,
                      onChange: () => {},
                      onSubmit: (newOperation) => updateFieldOperation(fieldOperationId, { fieldId, label, operation: newOperation }),
                      onCancel: () => {},
                      readOnly: false,
                      parameterDefinitions,
                    })}
                  </VerticalBlock>
                </div>
              )}
              actions={(
                <SpacingLine>
                  <IconOnlyButton
                    tooltip={i18n`Move Up`}
                    sizeVariant={SizeVariant.small}
                    variant={IconOnlyButtonVariants.secondary}
                    iconName={IconName.expand_less}
                    disabled={index === 0}
                    onClick={() => {
                      const newOperations = [...fieldOperations ?? []];
                      newOperations.splice(index - 1, 0, newOperations.splice(index, 1)[0]);
                      onChange(newOperations);
                    }}
                  />
                  <IconOnlyButton
                    tooltip={i18n`Move Down`}
                    sizeVariant={SizeVariant.small}
                    variant={IconOnlyButtonVariants.secondary}
                    iconName={IconName.expand_more}
                    disabled={index + 1 === self.length}
                    onClick={() => {
                      const newOperations = [...fieldOperations ?? []];
                      newOperations.splice(index + 1, 0, newOperations.splice(index, 1)[0]);
                      onChange(newOperations);
                    }}
                  />
                  <OverflowMenu
                    menuItems={[
                      {
                        key: 'duplicate',
                        icon: IconName.content_copy_outline,
                        name: i18n`Duplicate`,
                        onClick: () => duplicateFieldOperation(fieldOperationId),
                      },
                      {
                        key: 'delete',
                        name: i18n`Delete`,
                        icon: IconName.delete,
                        onClick: () => deleteFieldOperation(fieldOperationId),
                        danger: true,
                      },
                    ]}
                    sizeVariant={SizeVariant.small}
                  />
                </SpacingLine>
              )}
            />
            {(index + 1) < self.length && (
              <div className={classes.operationLineGap}>
                <span className={classes.verticalDivider} />
              </div>
            )}
          </Fragment>
        );
      })}
      <div className={classes.lineGap} />
      <button
        type="button"
        onKeyDown={(e) => {
          if (e.key !== 'Escape') {
            e.stopPropagation();
          }
        }}
        onKeyUp={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.stopPropagation();
          localNewLineFocusRef.current = addFieldOperation();
        }}
        className={classes.addCard}
        title={i18n`Add an operation`}
      >
        <SpacingLine>
          <Icon color={theme.color.text.brand} name={IconName.add} />
          <Typo color={theme.color.text.brand}>{i18n`Add an operation`}</Typo>
        </SpacingLine>
      </button>
    </div>
  );
};

export default FieldOperationsList;
