import type { FunctionComponent, ReactElement } from 'react';
import { useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { API_LABEL_ACCEPTED_CHARS_REGEX } from 'yooi-modules';
import { hasApiAlias } from 'yooi-modules/modules/common/fields/FieldModuleDsl';
import { getConceptDefinitionValidFields } from 'yooi-modules/modules/conceptModule';
import { Field_ApiAlias, Field_Formula, Field_Title, FieldDefinition, FieldDimension_Field } from 'yooi-modules/modules/conceptModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined, joinObjects, toSnakeCase } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import IconOnlyButton from '../../../components/atoms/IconOnlyButton';
import TableCell, { TableCellAlign } from '../../../components/molecules/TableCell';
import TableLine from '../../../components/molecules/TableLine';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { formatOrUndef } from '../../../utils/stringUtils';
import type { FieldEditionDimensions } from '../../_global/fields/fieldDimensionUtils';
import { FIELD_EDITION_DIMENSIONS } from '../../_global/fields/fieldDimensionUtils';
import type { FieldEditionOption } from '../../_global/fields/FieldEditionOptionType';
import { EditionOptionTypes } from '../../_global/fields/FieldEditionOptionType';
import { getFieldDefinitionHandler } from '../../_global/fields/FieldLibrary';
import { FieldEditionOptionMode, FieldEditionVariant } from '../../_global/fields/FieldLibraryTypes';
import useEditionHandler from '../../_global/fields/useEditionHandler';
import FieldTypeOptions from '../../_global/FieldTypeOptions';
import StoreTextInputField from '../../_global/input/StoreTextInputField';
import { defaultOptionComparator, getChipOptions } from '../../_global/modelTypeUtils';

interface NewFieldLineProps {
  modelTypeId: string,
  onCreate: (fieldId: string) => void,
  showApiAliases: boolean,
}

const NewFieldLine: FunctionComponent<NewFieldLineProps> = ({ modelTypeId, onCreate, showApiAliases }) => {
  const store = useStore();

  const mandatoryDimensionId = useRef(uuid());

  const fieldDefinitionsOptions = store.getObject(FieldDefinition)
    .navigateBack(Class_Instances)
    .filter((fieldDefinition) => getFieldDefinitionHandler(store, fieldDefinition.id)?.isCreationEnabled?.({ modelTypeId }))
    .map((fieldDefinition) => getChipOptions(store, fieldDefinition.id))
    .filter(filterNullOrUndefined)
    .sort(defaultOptionComparator);

  const editionHandler = useEditionHandler<{
    [Instance_Of]: string | null | undefined,
    [Field_Title]: string | null | undefined,
    [Field_ApiAlias]: string | null | undefined,
    [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions,
  } & Record<string, unknown>>({
    [Instance_Of]: fieldDefinitionsOptions[0]?.id,
    [Field_Title]: undefined,
    [Field_ApiAlias]: undefined,
    [FIELD_EDITION_DIMENSIONS]: { [mandatoryDimensionId.current]: { typeId: modelTypeId, label: undefined, mandatory: false, readOnly: true } },
  });

  const instanceOf = editionHandler.getValue(Instance_Of);
  const fieldDefinitionHandler = instanceOf ? getFieldDefinitionHandler(store, instanceOf) : undefined;

  const sections = fieldDefinitionHandler ? fieldDefinitionHandler
    .getEditionOptions({
      mode: FieldEditionOptionMode.Field,
      editionHandler,
      modelTypeId,
      readOnly: false,
      variant: FieldEditionVariant.composite,
    }) : [];

  const title = formatOrUndef(fieldDefinitionHandler?.getFieldTitle(editionHandler, modelTypeId));
  const subTitle = fieldDefinitionHandler?.getFieldSubTitle?.(editionHandler, modelTypeId);

  const onValidate = () => {
    const doOnCreate = fieldDefinitionHandler?.onCreate;
    if (doOnCreate) {
      const fieldId = doOnCreate(editionHandler);
      editionHandler.reset();
      onCreate(fieldId);
    }
  };
  const isActive = () => (
    (!fieldDefinitionHandler?.canCreate || fieldDefinitionHandler.canCreate(editionHandler))
    && !sections
      .flatMap((section) => (section.type === 'section' ? section.options : section.sections.flatMap(({ options }) => options)))
      .some((option) => option.type === EditionOptionTypes.select && option.props.required && !option.props.selectedOption)
  );

  const instanceOfOrDefault = editionHandler.getValueOrDefault(Instance_Of);

  let apiAliasColumn: ReactElement | null = null;

  if (showApiAliases) {
    const suggestedApiAlias = toSnakeCase(editionHandler.getValue(Field_Title) ?? '');

    const fieldEditionDimensions = editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {};
    const existingFieldId: string | undefined = Object.keys(fieldEditionDimensions)
      .reduce((fieldId, dimensionId) => {
        if (fieldId) {
          // Already got the field, no need to keep looking
          return fieldId;
        }

        return store.getObjectOrNull(dimensionId)?.navigateOrNull(FieldDimension_Field)?.id;
      }, undefined as string | undefined);

    const existingApiAliases = new Set<string>(
      Object.values(fieldEditionDimensions)
        .flatMap(({ typeId }) => getConceptDefinitionValidFields(store, typeId))
        .filter(({ id }) => id !== existingFieldId)
        .map(({ [Field_ApiAlias]: apiAlias }) => apiAlias)
        .filter(filterNullOrUndefined)
    );

    const apiAlias = editionHandler.getValue(Field_ApiAlias) ?? '';
    const isReadOnly = !instanceOf || (!hasApiAlias(instanceOf) && !editionHandler.getValue(Field_ApiAlias));

    apiAliasColumn = (
      <TableCell>
        <StoreTextInputField
          initialValue={apiAlias}
          onSubmit={(newApiAlias) => {
            editionHandler.updateValues({ [Field_ApiAlias]: newApiAlias });
          }}
          acceptChars={API_LABEL_ACCEPTED_CHARS_REGEX}
          acceptCharsErrorMessage={i18n`API alias should only contain letters, numbers, '-' or '_'`}
          action={
            !isReadOnly && suggestedApiAlias !== apiAlias
              ? {
                icon: IconName.sync,
                tooltip: i18n`Reset to default`,
                onClick: () => {
                  editionHandler.updateValues({ [Field_ApiAlias]: suggestedApiAlias });
                },
              }
              : undefined
          }
          error={
            apiAlias && existingApiAliases.has(apiAlias) ? i18n`Another field already use this API alias. Fields won't be available in the API until the conflict is resolved.` : undefined
          }
          readOnly={isReadOnly}
        />
      </TableCell>
    );
  }

  const removeOptionPlaceholder = (options: FieldEditionOption[]) => options.map((option) => {
    switch (option.type) {
      case EditionOptionTypes.custom:
        return option;
      default:
        return joinObjects(option, { props: option.props ? joinObjects(option.props, { placeholder: undefined }) : undefined }) as FieldEditionOption;
    }
  });

  return (
    <TableLine>
      <TableCell>
        <StoreTextInputField
          initialValue={editionHandler.getValue(Field_Title)}
          onSubmit={(name) => editionHandler.updateValues({ [Field_Title]: name })}
          focusOnMount
        />
      </TableCell>
      <TableCell>
        <FieldTypeOptions
          title={title}
          subTitle={subTitle}
          icon={fieldDefinitionHandler?.getFieldIcon?.(editionHandler) ?? fieldDefinitionHandler?.typeIcon}
          sections={[
            {
              key: 'type',
              type: 'section',
              options: [
                {
                  key: Instance_Of,
                  title: i18n`Type`,
                  type: EditionOptionTypes.select,
                  hasValue: () => instanceOf !== undefined,
                  clearValue: () => editionHandler.updateValues({ [Instance_Of]: null }),
                  props: {
                    computeOptions: () => fieldDefinitionsOptions,
                    selectedOption: typeof instanceOfOrDefault === 'string' ? getChipOptions(store, instanceOfOrDefault) : undefined,
                    onChange: (value) => editionHandler.updateValues({ [Instance_Of]: (value?.id as string | undefined) ?? null }),
                  },
                },
              ],
            },
            ...sections.map((section) => {
              if (section.type === 'section') {
                return joinObjects(
                  section,
                  { options: removeOptionPlaceholder(section.options) }
                );
              } else {
                return joinObjects(
                  section,
                  {
                    sections: section.sections.map((subSection) => (joinObjects(
                      subSection,
                      { options: removeOptionPlaceholder(subSection.options) }
                    ))),
                  }
                );
              }
            }),
          ]}
          isMultidimensional={Object.entries(editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {}).length > 1}
          isComputing={editionHandler.getValue(Field_Formula) !== undefined}
        />
      </TableCell>
      {apiAliasColumn}
      <TableCell align={TableCellAlign.center} action>
        <IconOnlyButton
          tooltip={i18n`Create`}
          onClick={onValidate}
          iconName={IconName.check}
          disabled={!isActive()}
        />
      </TableCell>
    </TableLine>
  );
};

export default NewFieldLine;
