import classnames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import type { AutomationRuleStoreObject, InstanceEventTrigger } from 'yooi-modules/modules/automationModule';
import {
  InstanceEventTriggerWhen,
  isFieldUpdateEventTrigger,
  isInstanceEventTrigger,
  isWorkflowTransitionEventTrigger,
  WorkflowListeningType,
} from 'yooi-modules/modules/automationModule';
import { AutomationRule_Trigger } from 'yooi-modules/modules/automationModule/ids';
import { getOptionalHistoryProducer } from 'yooi-modules/modules/common/fields/FieldModuleDsl';
import type {
  ConceptDefinitionStoreObject,
  FieldStoreObject,
  WorkflowFieldStoreObject,
  WorkflowStoreObject,
  WorkflowTransitionStoreObject,
} from 'yooi-modules/modules/conceptModule';
import { getConceptDefinitionValidFields } from 'yooi-modules/modules/conceptModule';
import { ConceptDefinition, ConceptDefinition_Name, Field_Formula, Workflow_Transitions, WorkflowField, WorkflowField_Workflow } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import { compareProperty, compareString, comparing, filterNullOrUndefined, joinObjects, pushUndefinedToEnd } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import Tooltip from '../../../components/atoms/Tooltip';
import Typo from '../../../components/atoms/Typo';
import Banner, { BannerVariant } from '../../../components/molecules/Banner';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SearchAndSelectMultiple from '../../../components/molecules/SearchAndSelectMultiple';
import SpacingLine from '../../../components/molecules/SpacingLine';
import ToggleButton from '../../../components/molecules/ToggleButton';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../components/templates/BlockTitle';
import CardBlock from '../../../components/templates/CardBlock';
import HorizontalBlock, { HorizontalBlockVariant } from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { formatOrUndef } from '../../../utils/stringUtils';
import useTheme from '../../../utils/useTheme';
import type { Chip } from '../../_global/fieldUtils';
import { getFieldChip, getFieldLabel } from '../../_global/fieldUtils';
import type { Option } from '../../_global/modelTypeUtils';
import { getChipOptions, getConceptDefinitionNameOrEntity, getUnknownChip } from '../../_global/modelTypeUtils';
import { listFieldIdsOfWorkflowField } from '../../_global/workflow/workflowUtils';
import AutomationCardTitle from './AutomationCardTitle';

interface AutomationRuleInstanceEventTriggerOptionsProps {
  ruleId: string,
}

const useStyles = makeStyles({
  cardContainer: {
    padding: spacingRem.m,
  },
  paddedLeft: {
    paddingLeft: spacingRem.s,
  },
  toggles: {
    display: 'flex',
    gap: spacingRem.s,
    marginTop: spacingRem.xs,
    paddingLeft: spacingRem.s,
  },
  customOpacity: {
    opacity: '0.5',
  },
  banner: {
    padding: spacingRem.s,
  },
}, 'automationRuleInstanceEventTriggerOptions');

const AutomationRuleInstanceEventTriggerOptions: FunctionComponent<AutomationRuleInstanceEventTriggerOptionsProps> = ({ ruleId }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  let whenOptions: Option[] = [];
  const automationRule = store.getObject<AutomationRuleStoreObject>(ruleId);
  const eventTrigger = automationRule[AutomationRule_Trigger];

  if (!eventTrigger || !isInstanceEventTrigger(eventTrigger)) {
    return null;
  }
  if (eventTrigger.on && store.getObjectOrNull(eventTrigger.on)) {
    const conceptName = getConceptDefinitionNameOrEntity(store, eventTrigger.on);
    whenOptions = [
      { id: InstanceEventTriggerWhen.create, label: i18n`A ${conceptName} is created` },
      { id: InstanceEventTriggerWhen.delete, label: i18n`A ${conceptName} is deleted` },
      { id: InstanceEventTriggerWhen.anyUpdate, label: i18n`Any field is updated` },
      { id: InstanceEventTriggerWhen.updatedField, label: i18n`A specific field is updated` },
      { id: InstanceEventTriggerWhen.workflowTransition, label: i18n`A workflow transition is executed` },
    ];
  }

  const getTrackedConceptFields = (): FieldStoreObject[] => (isInstanceEventTrigger(eventTrigger) && eventTrigger.on ? getConceptDefinitionValidFields(store, eventTrigger.on)
    .filter((field) => field[Field_Formula] === undefined)
    .filter((field) => !!getOptionalHistoryProducer(store, field.id)) : []);

  const getTrackedConceptFieldsChips = (): Chip[] => getTrackedConceptFields().map(({ id }) => getFieldChip(store, eventTrigger.on, id));

  const getTrackedConceptWorkflowFieldsChips = (): Chip[] => getTrackedConceptFields()
    .filter((field): field is WorkflowFieldStoreObject => isInstanceOf(field, WorkflowField))
    .map(({ id }) => getFieldChip(store, eventTrigger.on, id));

  const getWorkflowFieldFieldsChips = (fieldId: string): Chip[] => listFieldIdsOfWorkflowField(store, fieldId)
    .map((workflowFieldFieldId) => getFieldChip(store, eventTrigger.on, workflowFieldFieldId));

  const getTransitionsFromWorkflowField = (fieldId: string): Chip[] => {
    const field = store.getObjectOrNull(fieldId);
    const workflowFieldWorkflow = field?.navigateOrNull<WorkflowStoreObject>(WorkflowField_Workflow);
    return workflowFieldWorkflow ? workflowFieldWorkflow
      .navigateBack<WorkflowTransitionStoreObject>(Workflow_Transitions)
      .map((transition) => getChipOptions(store, transition.id))
      .filter(filterNullOrUndefined) : [];
  };

  const getTitleContent = (trigger: InstanceEventTrigger): ReactNode => {
    const onLabelUpdate = (newValue: string | null) => {
      if (newValue !== null) {
        store.updateObject<AutomationRuleStoreObject>(ruleId, {
          [AutomationRule_Trigger]: joinObjects(eventTrigger, { label: newValue }),
        });
      } else {
        const newScheduledTrigger = { ...eventTrigger };
        delete newScheduledTrigger.label;
        store.updateObject<AutomationRuleStoreObject>(ruleId, { [AutomationRule_Trigger]: newScheduledTrigger });
      }
    };
    const storedLabel = eventTrigger.label;
    if (trigger.when) {
      if (trigger.when === InstanceEventTriggerWhen.workflowTransition) {
        return (
          <AutomationCardTitle
            label={storedLabel || i18n`A workflow transition is executed`}
            onLabelUpdate={onLabelUpdate}
            icon={IconName.route}
            iconColor={theme.color.background.info.default}
            backgroundColor={theme.color.background.info.light}
          />
        );
      } else if (trigger.when === InstanceEventTriggerWhen.anyUpdate) {
        return (
          <AutomationCardTitle
            label={storedLabel || i18n`Any "${getConceptDefinitionNameOrEntity(store, trigger.on)}" is updated`}
            onLabelUpdate={onLabelUpdate}
            icon={IconName.edit}
            iconColor={theme.color.background.warning.default}
            backgroundColor={theme.color.background.warning.light}
          />
        );
      } else if (isFieldUpdateEventTrigger(trigger)) {
        const fieldsIds = trigger.updates ?? [];
        let label: string | undefined;
        if (fieldsIds.length > 0) {
          label = i18n`${fieldsIds.map((fieldId) => {
            const field = store.getObjectOrNull<FieldStoreObject>(fieldId);
            const fieldLabel = field ? getFieldLabel(store, field) : formatOrUndef(undefined);
            return `"${fieldLabel}"`;
          }).join(i18n` or `)} is updated`;
        }
        return (
          <AutomationCardTitle
            label={storedLabel || label}
            placeholder={i18n`Trigger title`}
            onLabelUpdate={onLabelUpdate}
            icon={IconName.edit}
            iconColor={theme.color.background.warning.default}
            backgroundColor={theme.color.background.warning.light}
          />
        );
      } else if (trigger.when === InstanceEventTriggerWhen.delete) {
        return (
          <AutomationCardTitle
            label={storedLabel || i18n`Any "${getConceptDefinitionNameOrEntity(store, trigger.on)}" is deleted`}
            onLabelUpdate={onLabelUpdate}
            icon={IconName.delete}
          />
        );
      } else {
        return (
          <AutomationCardTitle
            label={storedLabel || i18n`Any "${getConceptDefinitionNameOrEntity(store, trigger.on)}" is created`}
            onLabelUpdate={onLabelUpdate}
            icon={IconName.add}
            iconColor={theme.color.text.success}
            backgroundColor={theme.color.background.primarylight.default}
          />
        );
      }
    } else {
      return (
        <AutomationCardTitle
          label={storedLabel}
          placeholder={i18n`Trigger title`}
          onLabelUpdate={onLabelUpdate}
          icon={IconName.add}
        />
      );
    }
  };

  const getSelectedWorkflowOption = (): Option | undefined => {
    if (isWorkflowTransitionEventTrigger(eventTrigger) && eventTrigger.workflow) {
      const workflow = store.getObjectOrNull(eventTrigger.workflow);
      return workflow ? getFieldChip(store, eventTrigger.on, eventTrigger.workflow) : getUnknownChip(eventTrigger.workflow);
    }
    return undefined;
  };

  return (
    <>
      <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compact}>
        <BlockTitle
          title={i18n`On concept`}
          mandatoryIcon={{
            tooltip: eventTrigger.on ? undefined : i18n`Required field`,
            color: eventTrigger.on ? IconColorVariant.secondary : undefined,
          }}
        />
        <BlockContent>
          <SearchAndSelect
            computeOptions={() => store.getObject(ConceptDefinition)
              .navigateBack<ConceptDefinitionStoreObject>(Class_Instances)
              .sort(compareProperty(ConceptDefinition_Name, comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString)))
              .map(({ id: instanceId }) => getChipOptions(store, instanceId))
              .filter(filterNullOrUndefined)}
            placeholder={i18n`Select a concept`}
            selectedOption={eventTrigger.on ? getChipOptions(store, eventTrigger.on) ?? getUnknownChip(eventTrigger.on) : undefined}
            onSelect={(option) => {
              if (option) {
                store.updateObject<AutomationRuleStoreObject>(ruleId, {
                  [AutomationRule_Trigger]: joinObjects(
                    eventTrigger,
                    {
                      on: option.id,
                      updates: [],
                      workflow: null,
                      listeningType: null,
                      fields: null,
                      transitions: null,
                    }
                  ),
                });
              }
            }}
          />
        </BlockContent>
      </HorizontalBlock>
      {eventTrigger.on ? (
        <VerticalBlock asBlockContent>
          <BlockTitle title={i18n`When...`} variant={BlockTitleVariant.inline} />
          <BlockContent padded>
            <CardBlock
              titleContent={getTitleContent(eventTrigger)}
              content={(
                <div className={classes.cardContainer}>
                  <VerticalBlock>
                    <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn}>
                      <BlockTitle
                        title={i18n`Event type`}
                        mandatoryIcon={{
                          tooltip: eventTrigger.when ? undefined : i18n`Required field`,
                          color: eventTrigger.when ? IconColorVariant.secondary : undefined,
                        }}
                      />
                      <BlockContent>
                        <SearchAndSelect
                          computeOptions={() => whenOptions}
                          placeholder={i18n`Select event type`}
                          selectedOption={eventTrigger.when ? whenOptions.find(({ id }) => id === eventTrigger.when) ?? getUnknownChip(eventTrigger.when) : undefined}
                          onSelect={(option) => {
                            if (option) {
                              const eventTriggerToCopy = { ...eventTrigger };
                              store.updateObject<AutomationRuleStoreObject>(ruleId, {
                                [AutomationRule_Trigger]: joinObjects(
                                  eventTriggerToCopy,
                                  { when: option.id },
                                  (option.id !== InstanceEventTriggerWhen.updatedField ? { updates: null } : {}),
                                  (option.id !== InstanceEventTriggerWhen.workflowTransition ? {
                                    workflow: null,
                                    listeningType: null,
                                    fields: null,
                                    transitions: null,
                                  } : {})
                                ),
                              });
                            }
                          }}
                          minWidth={32}
                        />
                      </BlockContent>
                    </HorizontalBlock>
                    {isFieldUpdateEventTrigger(eventTrigger) && (
                      <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn}>
                        <BlockTitle
                          title={i18n`Watch field(s)`}
                          mandatoryIcon={{
                            tooltip: eventTrigger.updates?.length ? undefined : i18n`Required field`,
                            color: eventTrigger.updates?.length ? IconColorVariant.secondary : undefined,
                          }}
                        />
                        <BlockContent
                          action={(
                            <SpacingLine>
                              <Icon
                                name={IconName.info}
                                colorVariant={IconColorVariant.info}
                                tooltip={i18n`The trigger will be activated when one of the selected field is updated. If all fields are being watched, any field created in the future will also be included.`}
                              />
                            </SpacingLine>
                          )}
                        >
                          <SearchAndSelectMultiple
                            computeOptions={getTrackedConceptFieldsChips}
                            placeholder={i18n`Select fields`}
                            selectedOptions={(eventTrigger?.updates ?? [])
                              .map((selectedField) => {
                                if (store.getObjectOrNull(selectedField) !== null) {
                                  return getFieldChip(store, eventTrigger.on, selectedField);
                                } else {
                                  return getUnknownChip(selectedField);
                                }
                              })}
                            onSelect={(option) => {
                              if (option) {
                                store.updateObject<AutomationRuleStoreObject>(ruleId, {
                                  [AutomationRule_Trigger]: joinObjects(
                                    eventTrigger,
                                    { updates: eventTrigger.updates ? [...eventTrigger.updates, option.id] : [option.id] }
                                  ),
                                });
                              }
                            }}
                            onDelete={(option) => {
                              store.updateObject<AutomationRuleStoreObject>(ruleId, {
                                [AutomationRule_Trigger]: joinObjects(
                                  eventTrigger,
                                  { updates: eventTrigger.updates?.filter((id) => id !== option.id) }
                                ),
                              });
                            }}
                          />
                        </BlockContent>
                      </HorizontalBlock>
                    )}
                    {isWorkflowTransitionEventTrigger(eventTrigger) && (
                      <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn}>
                        <BlockTitle
                          title={i18n`Workflow`}
                          mandatoryIcon={{
                            tooltip: eventTrigger.workflow ? undefined : i18n`Required field`,
                            color: eventTrigger.workflow ? IconColorVariant.secondary : undefined,
                          }}
                        />
                        <BlockContent
                          action={(
                            <SpacingLine>
                              <Icon
                                name={IconName.info}
                                colorVariant={IconColorVariant.info}
                                tooltip={i18n`The trigger will be fired when transitions are executed on the selected workflow.`}
                              />
                            </SpacingLine>
                          )}
                        >
                          {getTrackedConceptWorkflowFieldsChips().length > 0 ? (
                            <SearchAndSelect
                              computeOptions={() => getTrackedConceptWorkflowFieldsChips()}
                              placeholder={i18n`Select workflow`}
                              selectedOption={getSelectedWorkflowOption()}
                              onSelect={(option) => {
                                if (option) {
                                  store.updateObject<AutomationRuleStoreObject>(ruleId, {
                                    [AutomationRule_Trigger]: joinObjects(
                                      eventTrigger,
                                      { workflow: option.id },
                                      (option.id !== eventTrigger.workflow) ? {
                                        listeningType: getWorkflowFieldFieldsChips(option.id).length > 0
                                          ? [WorkflowListeningType.main, WorkflowListeningType.fields]
                                          : [WorkflowListeningType.main],
                                        fields: null,
                                        transitions: null,
                                      } : {}
                                    ),
                                  });
                                }
                              }}
                              minWidth={32}
                            />
                          ) : (
                            <div className={classes.paddedLeft}>
                              <SpacingLine>
                                <Typo maxLine={1} color={theme.color.text.secondary}>
                                  {i18n`No workflow available for this concept`}
                                </Typo>
                              </SpacingLine>
                            </div>
                          )}
                        </BlockContent>
                      </HorizontalBlock>
                    )}
                    {isWorkflowTransitionEventTrigger(eventTrigger) && eventTrigger?.workflow && (
                      <>
                        <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn}>
                          <BlockTitle
                            title={i18n`Listen to`}
                          />
                          <BlockContent>
                            <div className={classes.toggles}>
                              <Tooltip title={i18n`Listen to main value`} asFlexbox>
                                <ToggleButton
                                  key="main"
                                  name={i18n`Main value`}
                                  icon={eventTrigger.listeningType?.includes(WorkflowListeningType.main) ? IconName.toggle_on : IconName.toggle_off}
                                  onClick={() => {
                                    let updatedListeningType: WorkflowListeningType[] = [];
                                    if (isWorkflowTransitionEventTrigger(eventTrigger) && eventTrigger.listeningType) {
                                      if (eventTrigger.listeningType.includes(WorkflowListeningType.main)) {
                                        updatedListeningType = eventTrigger.listeningType.filter((listeningType) => listeningType !== WorkflowListeningType.main);
                                      } else {
                                        updatedListeningType = [...eventTrigger.listeningType, WorkflowListeningType.main];
                                      }
                                    }
                                    store.updateObject<AutomationRuleStoreObject>(ruleId, {
                                      [AutomationRule_Trigger]: joinObjects(
                                        eventTrigger,
                                        { listeningType: updatedListeningType }
                                      ),
                                    });
                                  }}
                                  active={eventTrigger.listeningType?.includes(WorkflowListeningType.main)}
                                />
                              </Tooltip>
                              <div
                                className={classnames({
                                  [classes.customOpacity]: getWorkflowFieldFieldsChips(eventTrigger.workflow).length === 0,
                                })}
                              >
                                <Tooltip
                                  title={getWorkflowFieldFieldsChips(eventTrigger.workflow).length === 0 ? i18n`This workflow is not applied on any fields` : i18n`Listen to field(s) value`}
                                  asFlexbox
                                >
                                  <ToggleButton
                                    key="fields"
                                    name={i18n`Field(s) value`}
                                    icon={eventTrigger.listeningType?.includes(WorkflowListeningType.fields)
                                    && getWorkflowFieldFieldsChips(eventTrigger.workflow).length > 0 ? IconName.toggle_on : IconName.toggle_off}
                                    onClick={() => {
                                      let updatedListeningType: WorkflowListeningType[] = [];
                                      let updatedFields = eventTrigger.fields;
                                      if (isWorkflowTransitionEventTrigger(eventTrigger) && eventTrigger.listeningType) {
                                        if (eventTrigger.listeningType.includes(WorkflowListeningType.fields)) {
                                          updatedListeningType = eventTrigger.listeningType.filter((listeningType) => listeningType !== WorkflowListeningType.fields);
                                          updatedFields = [];
                                        } else {
                                          updatedListeningType = [...eventTrigger.listeningType, WorkflowListeningType.fields];
                                        }
                                      }
                                      store.updateObject<AutomationRuleStoreObject>(ruleId, {
                                        [AutomationRule_Trigger]: joinObjects(
                                          eventTrigger,
                                          {
                                            listeningType: updatedListeningType,
                                            fields: updatedFields,
                                          }
                                        ),
                                      });
                                    }}
                                    active={getWorkflowFieldFieldsChips(eventTrigger.workflow).length > 0 && eventTrigger.listeningType?.includes(WorkflowListeningType.fields)}
                                    disable={getWorkflowFieldFieldsChips(eventTrigger.workflow).length === 0}
                                  />
                                </Tooltip>
                              </div>
                            </div>
                            {(!eventTrigger.listeningType || eventTrigger.listeningType.length === 0
                                || (eventTrigger.listeningType.includes(WorkflowListeningType.fields) && getWorkflowFieldFieldsChips(eventTrigger.workflow).length === 0))
                              && (
                                <div className={classes.banner}>
                                  <Banner variant={BannerVariant.warning} title={i18n`You're not listening to any values with current configuration`} />
                                </div>
                              )}
                          </BlockContent>
                        </HorizontalBlock>
                        {((eventTrigger.listeningType?.includes(WorkflowListeningType.fields) && getWorkflowFieldFieldsChips(eventTrigger.workflow).length > 0)
                          || (eventTrigger.fields && eventTrigger.fields.length > 0)) && (
                          <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn}>
                            <BlockTitle
                              title={i18n`Field(s)`}
                            />
                            <BlockContent
                              action={(
                                <SpacingLine>
                                  <Icon
                                    name={IconName.info}
                                    colorVariant={IconColorVariant.info}
                                    tooltip={i18n`Leave this empty to listen to all fields.`}
                                  />
                                </SpacingLine>
                              )}
                            >
                              <SearchAndSelectMultiple
                                computeOptions={() => (eventTrigger.workflow ? getWorkflowFieldFieldsChips(eventTrigger.workflow) : [])}
                                placeholder={i18n`All fields`}
                                selectedOptions={(eventTrigger?.fields ?? [])
                                  .map((selectedField) => {
                                    if (eventTrigger.workflow && getWorkflowFieldFieldsChips(eventTrigger.workflow)
                                      .some((chip) => chip.id === selectedField) && store.getObjectOrNull(selectedField) !== null) {
                                      return getFieldChip(store, eventTrigger.on, selectedField);
                                    } else {
                                      return getUnknownChip(selectedField);
                                    }
                                  })}
                                onSelect={(option) => {
                                  if (option) {
                                    store.updateObject<AutomationRuleStoreObject>(ruleId, {
                                      [AutomationRule_Trigger]: joinObjects(
                                        eventTrigger,
                                        { fields: eventTrigger.fields ? [...eventTrigger.fields, option.id] : [option.id] }
                                      ),
                                    });
                                  }
                                }}
                                onDelete={(option) => {
                                  store.updateObject<AutomationRuleStoreObject>(ruleId, {
                                    [AutomationRule_Trigger]: joinObjects(
                                      eventTrigger,
                                      { fields: eventTrigger.fields?.filter((id) => id !== option.id) }
                                    ),
                                  });
                                }}
                              />
                            </BlockContent>
                          </HorizontalBlock>
                        )}
                        <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn}>
                          <BlockTitle
                            title={i18n`Transition(s)`}
                          />
                          <BlockContent
                            action={(
                              <SpacingLine>
                                <Icon
                                  name={IconName.info}
                                  colorVariant={IconColorVariant.info}
                                  tooltip={i18n`Leave this empty to listen to all transitions.`}
                                />
                              </SpacingLine>
                            )}
                          >
                            {eventTrigger.workflow && getTransitionsFromWorkflowField(eventTrigger.workflow).length > 0 ? (
                              <SearchAndSelectMultiple
                                computeOptions={() => (eventTrigger.workflow ? getTransitionsFromWorkflowField(eventTrigger.workflow) : [])}
                                placeholder={i18n`All transitions`}
                                selectedOptions={(eventTrigger?.transitions ?? [])
                                  .map((selectedTransitionId) => {
                                    if (store.getObjectOrNull(selectedTransitionId) !== null) {
                                      return getChipOptions(store, selectedTransitionId);
                                    } else {
                                      return getUnknownChip(selectedTransitionId);
                                    }
                                  })
                                  .filter(filterNullOrUndefined)}
                                onSelect={(option) => {
                                  if (option) {
                                    store.updateObject<AutomationRuleStoreObject>(ruleId, {
                                      [AutomationRule_Trigger]: joinObjects(
                                        eventTrigger,
                                        { transitions: eventTrigger.transitions ? [...eventTrigger.transitions, option.id] : [option.id] }
                                      ),
                                    });
                                  }
                                }}
                                onDelete={(option) => {
                                  store.updateObject<AutomationRuleStoreObject>(ruleId, {
                                    [AutomationRule_Trigger]: joinObjects(
                                      eventTrigger,
                                      { transitions: eventTrigger.transitions?.filter((id) => id !== option.id) }
                                    ),
                                  });
                                }}
                              />
                            ) : (
                              <div className={classes.paddedLeft}>
                                <SpacingLine>
                                  <Typo maxLine={1} color={theme.color.text.secondary}>
                                    {i18n`No transition available for this workflow`}
                                  </Typo>
                                </SpacingLine>
                              </div>
                            )}
                          </BlockContent>
                        </HorizontalBlock>
                      </>
                    )}
                  </VerticalBlock>
                </div>
              )}
            />
          </BlockContent>
        </VerticalBlock>
      ) : null}
    </>
  );
};

export default AutomationRuleInstanceEventTriggerOptions;
