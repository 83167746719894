import type { FunctionComponent } from 'react';
import type { AssociationUpdate } from 'yooi-modules/modules/conceptModule';
import type { StoreObject } from 'yooi-store';
import { filterNullOrUndefined } from 'yooi-utils';
import { EditableCloseReasons } from '../../../../components/molecules/EditableWithDropdown';
import SearchAndSelectMultiple from '../../../../components/molecules/SearchAndSelectMultiple';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';
import { getInlineCreationBuilder } from '../../conceptUtils';
import { defaultOptionComparator, getChipOptions, getChipOptionWithUnknown, getModelTypeInstances, getSearchChipOptions } from '../../modelTypeUtils';

interface AssociationFieldInputRendererProps {
  value: string[],
  onChange: (newValue: AssociationUpdate) => void,
  onSubmit: (newValue: AssociationUpdate) => void,
  onCancel: () => void,
  readOnly: boolean,
  focusOnMount?: boolean,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  isEditing?: boolean,
  canCreateObject: (id: string | string[]) => boolean,
  targetType: StoreObject<string>,
}

const AssociationFieldInputRenderer: FunctionComponent<AssociationFieldInputRendererProps> = ({
  value,
  onChange,
  onSubmit,
  onCancel,
  focusOnMount,
  readOnly,
  onEditionStart,
  onEditionStop,
  isEditing,
  canCreateObject,
  targetType,
}) => {
  const store = useStore();
  const usageVariant = useUsageContext();

  const canCreate = canCreateObject(targetType.id);

  return (
    <SearchAndSelectMultiple
      computeOptions={() => (
        getModelTypeInstances(store, targetType.id)
          .map(({ id }) => getChipOptions(store, id))
          .filter(filterNullOrUndefined)
          .sort(defaultOptionComparator)
      )}
      selectedOptions={value.map((id) => getChipOptionWithUnknown(store, id)).sort(defaultOptionComparator)}
      onSelect={(option) => onChange({ action: 'add', objectIds: [option.id] })}
      onDelete={(option) => onChange({ action: 'remove', objectIds: [option.id] })}
      onEditionStart={onEditionStart}
      onEditionStop={(reason) => {
        if (reason === EditableCloseReasons.onEscapeKeyDown) {
          onCancel();
        } else {
          onSubmit({ action: 'set', objectIds: value });
        }
        onEditionStop?.();
      }}
      searchOptions={getSearchChipOptions(store, targetType.id)}
      placeholder={usageVariant === UsageVariant.inTable ? undefined : i18n`Add element`}
      readOnly={readOnly}
      focusOnMount={focusOnMount}
      isEditing={isEditing}
      getInlineCreation={canCreate ? getInlineCreationBuilder(
        store,
        targetType.id,
        (newInstanceId) => onChange({ action: 'add', objectIds: [newInstanceId] })
      ) : undefined}
    />
  );
};

export default AssociationFieldInputRenderer;
