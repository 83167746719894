import type { FunctionComponent } from 'react';
import { IFrameField, TextConstantField } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { InstanceReferenceType, isFieldStep, isFilterStep, isMappingStep, isMultiplePath, PathStepType } from 'yooi-modules/modules/conceptModule';
import type { CardsViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { GraphChartField, RadarChartField, ViewsField } from 'yooi-modules/modules/dashboardModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { joinObjects } from 'yooi-utils';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import type { LineEditionOption } from '../../fields/FieldEditionOptionType';
import { EditionOptionTypes } from '../../fields/FieldEditionOptionType';
import type { UpdateViewDefinition } from '../../fields/viewsField/ViewsFieldDefinitionOptions';
import type { OptionRecord } from '../../modelTypeUtils';
import { createPathConfigurationHandler, StepValidationState } from '../../pathConfigurationHandler';
import CardConfigurationOption from '../common/CardConfigurationOption';
import { getSeriesLabel, getViewDimensionsAsParameterDefinitions } from '../common/series/viewWithSeriesFeatureUtils';
import ViewOptionBlock from '../common/ViewOptionBlock';
import type { CardsViewResolvedDefinition } from './cardsViewDefinitionHandler';

const sortOptions: OptionRecord<'asc' | 'desc'> = {
  asc: { id: 'asc', label: i18n`Ascending sort` },
  desc: { id: 'desc', label: i18n`Descending sort` },
};

interface CardsViewDefinitionOptionsProps {
  viewDimensions: ViewDimension[],
  viewDefinition: CardsViewResolvedDefinition,
  updateViewDefinition: UpdateViewDefinition<CardsViewStoredDefinition>,
  readOnly: boolean,
  parameterDefinitions: SingleParameterDefinition[],
}

const CardsViewDefinitionOptions: FunctionComponent<CardsViewDefinitionOptionsProps> = ({
  viewDimensions,
  viewDefinition,
  updateViewDefinition,
  readOnly,
  parameterDefinitions,
}) => {
  const store = useStore();

  const seriesParameterDefinitions: SingleParameterDefinition[] = [...parameterDefinitions, ...getViewDimensionsAsParameterDefinitions(store, viewDimensions)];

  const suggestedBasePaths: { label: string, path: PathStep[] }[] = getViewDimensionsAsParameterDefinitions(store, viewDimensions)
    .map(({ id: parameterId, typeId, label }) => ({
      label,
      path: [
        { type: PathStepType.dimension, conceptDefinitionId: typeId },
        { type: PathStepType.mapping, mapping: { id: parameterId, type: InstanceReferenceType.parameter } },
      ],
    }));

  const contentPathHandler = createPathConfigurationHandler(
    store,
    seriesParameterDefinitions,
    [
      ({ pathStep, path: vPath, isNPath }) => {
        if (isFieldStep(pathStep)) {
          const fieldDefinitionId = store.getObjectOrNull(pathStep.fieldId)?.[Instance_Of] as string | undefined;
          if (!fieldDefinitionId) {
            return [{ state: StepValidationState.invalid, reasonMessage: i18n`Input end with an unknown field.` }];
          } else if (![GraphChartField, IFrameField, RadarChartField, TextConstantField, ViewsField].includes(fieldDefinitionId)) {
            if (isMultiplePath(store, vPath.slice(0, vPath.length - 1))) {
              return [{
                state: StepValidationState.invalid,
                reasonMessage: i18n`Input should be unique, use a mapping in your path.`,
              }];
            } else {
              return [{ state: StepValidationState.valid }];
            }
          } else {
            return [{ state: StepValidationState.invalid, reasonMessage: i18n`Input end with an unauthorized field.` }];
          }
        } else if (isMappingStep(pathStep) && vPath.length === 2) {
          return [{ state: StepValidationState.valid }];
        } else if (isFilterStep(pathStep)) {
          if (isNPath) {
            return [{ state: StepValidationState.invalid, reasonMessage: i18n`Input should be unique, use a mapping in your path.` }];
          } else {
            return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`Input should end with a field.` }];
          }
        } else {
          return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`Input end with an unauthorized element.` }];
        }
      },
    ]
  );

  const editionOptions: LineEditionOption[] = [];
  editionOptions.push({
    key: 'readOnly',
    title: i18n`Read only`,
    padded: true,
    type: EditionOptionTypes.checkbox,
    props: {
      checked: viewDefinition.readOnly,
      disabled: readOnly,
      onChange: (value) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { readOnly: value }))),
    },
  });
  editionOptions.push({
    key: 'layout.lines',
    title: i18n`Number of cards lines`,
    type: EditionOptionTypes.number,
    props: {
      value: viewDefinition.layout.lines === 'auto' ? undefined : viewDefinition.layout.lines,
      onChange: (newValue) => {
        if (typeof newValue !== 'number' || (Number.isSafeInteger(newValue) && newValue > 0)) {
          updateViewDefinition((oldViewDefinition) => joinObjects(oldViewDefinition, { layout: joinObjects(oldViewDefinition.layout, { lines: newValue ?? 'auto' as const }) }));
        }
      },
      min: 1,
      readOnly,
      placeholder: i18n`Auto`,
    },
  });
  editionOptions.push({
    key: 'layout.columns',
    title: i18n`Number of cards columns`,
    type: EditionOptionTypes.number,
    props: {
      value: viewDefinition.layout.columns === 'auto' ? undefined : viewDefinition.layout.columns,
      onChange: (newValue) => {
        if (typeof newValue !== 'number' || (Number.isSafeInteger(newValue) && newValue > 0)) {
          updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { layout: joinObjects(oldViewDefinition.layout, { columns: newValue ?? 'auto' as const }) })));
        }
      },
      min: 1,
      readOnly,
      placeholder: i18n`Auto`,
    },
  });
  editionOptions.push({
    key: 'sort',
    title: i18n`Sort cards by`,
    type: EditionOptionTypes.path,
    props: {
      initialPath: viewDefinition.sort?.path ?? [],
      onSubmit: (newPath) => {
        updateViewDefinition((oldViewDefinition) => (joinObjects(
          oldViewDefinition,
          {
            sort: oldViewDefinition.sort === undefined ? { path: newPath, direction: 'asc' as const } : { path: newPath, direction: oldViewDefinition.sort.direction },
          }
        )));
      },
      placeholder: i18n`Select a path to a sorting field`,
      parameterDefinitions: seriesParameterDefinitions,
      valuePathHandler: contentPathHandler,
      suggestedBasePaths,
      readOnly,
    },
  });
  if (viewDefinition.sort !== undefined) {
    editionOptions.push({
      key: 'sortMode',
      title: i18n`Sort mode`,
      type: EditionOptionTypes.select,
      props: {
        selectedOption: sortOptions[viewDefinition.sort.direction],
        computeOptions: () => Object.values(sortOptions),
        onChange: (option) => {
          if (option && viewDefinition.sort?.direction !== option.id) {
            updateViewDefinition((oldViewDefinition) => (joinObjects(
              oldViewDefinition,
              {
                sort: oldViewDefinition.sort === undefined ? undefined : { path: oldViewDefinition.sort.path, direction: option.id as 'asc' | 'desc' },
              }
            )));
          }
        },
      },
    });
  }

  return (
    <>
      {editionOptions.map((option) => (<ViewOptionBlock key={option.key} option={option} />))}
      <CardConfigurationOption
        value={{ color: viewDefinition.color, icon: viewDefinition.icon, boolean: viewDefinition.boolean, body: viewDefinition.body, header: viewDefinition.header }}
        onIconUpdate={(newIcon) => {
          updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { icon: newIcon })));
        }}
        onBooleanUpdate={(newBoolean) => {
          updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { boolean: newBoolean })));
        }}
        onColorUpdate={(newColor) => {
          updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { color: newColor })));
        }}
        onHeaderUpdate={(newHeader) => {
          updateViewDefinition((oldViewDefinition) => (joinObjects(
            oldViewDefinition,
            {
              header: newHeader,
            }
          )));
        }}
        onBodyUpdate={(newBody) => {
          updateViewDefinition((oldViewDefinition) => (joinObjects(
            oldViewDefinition,
            {
              body: newBody,
            }
          )));
        }}
        getBodySeriesLabel={(label, index, path) => getSeriesLabel(store, label, index, path, viewDimensions, parameterDefinitions.map((parameter) => parameter.id))}
        suggestedBasePaths={suggestedBasePaths}
        parameterDefinitions={seriesParameterDefinitions}
        readOnly={readOnly}
      />
    </>
  );
};

export default CardsViewDefinitionOptions;
