import classnames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import { useRef } from 'react';
import { Modal } from 'react-overlays';
import base, { Opacity } from '../../theme/base';
import { colorWithAlpha } from '../../theme/colorUtils';
import { buildPadding, Spacing, spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { OverlayContextProvider } from '../../utils/useOverlayContainerRef';
import Button, { ButtonVariant } from '../atoms/Button';
import type { IconName } from '../atoms/Icon';
import Icon, { IconSizeVariant } from '../atoms/Icon';
import Typo, { TypoVariant } from '../atoms/Typo';
import SpacingLine from './SpacingLine';

export enum ConfirmationModalVariant {
  confirm = 'confirm',
  delete = 'delete',
}

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 999, // aka between backdrop and tooltip layers
    width: '100%',
    maxWidth: '60rem',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: base.borderRadius.medium,
    backgroundColor: theme.color.background.neutral.default,
    boxShadow: base.shadowElevation.high,
  },
  backdrop: {
    position: 'fixed',
    zIndex: 998, // aka over content below modal and tooltip layers
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: colorWithAlpha(theme.color.background.neutral.dark, Opacity.fifty),
  },
  title: {
    ...buildPadding({ x: Spacing.xxl, bottom: Spacing.m, top: Spacing.xxl }),
  },
  iconTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.s,
  },
  content: {
    ...buildPadding({ x: Spacing.xxl, y: Spacing.s }),
  },
  actions: {
    paddingRight: spacingRem.xxl,
    paddingBottom: spacingRem.xxl,
    paddingTop: spacingRem.s,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}), 'confirmationModal');

interface ConfirmationModalProps {
  variant?: ConfirmationModalVariant,
  title: string,
  titleIcon?: {
    name: IconName,
    color?: string,
  },
  open: boolean,
  confirmLabel?: string,
  confirmButtonVariant?: ButtonVariant,
  onConfirm: () => void,
  alternateConfirmLabel?: string,
  onAlternateConfirm?: () => void,
  cancelLabel?: string,
  cancelButtonVariant?: ButtonVariant,
  onCancel: () => void,
  render: () => ReactNode,
  isConfirmDisable?: boolean,
  isCancelDisable?: boolean,
}

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = ({
  variant = ConfirmationModalVariant.confirm,
  title,
  titleIcon,
  open,
  confirmLabel,
  confirmButtonVariant,
  onConfirm,
  alternateConfirmLabel,
  onAlternateConfirm,
  cancelLabel,
  cancelButtonVariant,
  onCancel,
  render,
  isConfirmDisable = false,
  isCancelDisable = false,
}) => {
  const classes = useStyles();

  const ref = useRef(null);
  const computedConfirmLabel = confirmLabel ?? (variant === ConfirmationModalVariant.delete ? i18n`Delete` : i18n`Ok`);
  const computedConfirmButtonVariant = confirmButtonVariant ?? (variant === ConfirmationModalVariant.delete ? ButtonVariant.danger : ButtonVariant.primary);

  return (
    <Modal
      show={open}
      className={classes.modal}
      renderBackdrop={(props) => (<div className={classes.backdrop} {...props} />)}
      onEscapeKeyDown={() => onCancel()}
    >
      <div ref={ref}>
        <OverlayContextProvider containerRef={ref}>
          <div className={classnames({ [classes.title]: true, [classes.iconTitle]: titleIcon })}>
            {titleIcon ? <Icon name={titleIcon.name} color={titleIcon.color} size={IconSizeVariant.l} /> : undefined}
            <Typo variant={TypoVariant.blockPrimaryTitle}>{title}</Typo>
          </div>
          <div className={classes.content}>
            {render()}
          </div>
          <div className={classes.actions}>
            <SpacingLine>
              <Button
                title={cancelLabel ?? i18n`Cancel`}
                variant={cancelButtonVariant ?? ButtonVariant.secondary}
                onClick={() => onCancel()}
                disabled={isCancelDisable}
              />
              {alternateConfirmLabel && onAlternateConfirm && (
                <Button
                  title={alternateConfirmLabel}
                  variant={ButtonVariant.secondary}
                  onClick={() => onAlternateConfirm()}
                />
              )}
              <Button
                title={computedConfirmLabel}
                variant={computedConfirmButtonVariant}
                onClick={() => onConfirm()}
                disabled={isConfirmDisable}
              />
            </SpacingLine>
          </div>
        </OverlayContextProvider>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
