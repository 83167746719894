import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useContext } from 'react';

export enum UsageVariant {
  inline = 'inline',
  inTable = 'inTable',
  inForm = 'inForm',
  inCard = 'inCard',
}

const UsageContext = createContext(UsageVariant.inline);

interface UsageContextProviderProps {
  usageVariant: UsageVariant,
  children: ReactNode,
}

export const UsageContextProvider: FunctionComponent<UsageContextProviderProps> = ({ usageVariant = UsageVariant.inline, children }) => (
  <UsageContext.Provider value={usageVariant}>
    {children}
  </UsageContext.Provider>
);

const useUsageContext = (): UsageVariant => useContext(UsageContext);

export default useUsageContext;
