import classnames from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';

const useStyles = makeStyles((theme) => ({
  left: {
    display: 'flex',
    gridColumnStart: 1,
  },
  content: {
    display: 'grid',
    gridTemplateColumns: 'minMax(20%, 1fr) auto',
    gridColumnStart: 2,
    justifyContent: 'start',
    alignItems: 'center',
    columnGap: spacingRem.l,
  },
  contentPadded: {
    paddingLeft: spacingRem.s,
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
  },
  right: {
    display: 'flex',
    gridColumnStart: 3,
  },
}), 'headerLine');

interface HeaderLineProps {
  left?: ReactElement | null,
  children: ReactElement | null,
  actions?: ReactElement | null,
  right?: ReactElement | null,
  padded?: boolean,
}

const HeaderLine: FunctionComponent<HeaderLineProps> = ({ left, children, actions, right, padded = false }) => {
  const classes = useStyles();

  return (
    <>
      {left !== null && left !== undefined ? (<span className={classes.left}>{left}</span>) : null}
      <span className={classnames({ [classes.content]: true, [classes.contentPadded]: padded })}>
        {children}
        {actions}
      </span>
      {right !== null && right !== undefined ? (<span className={classes.right}>{right}</span>) : null}
    </>
  );
};

export default HeaderLine;
