import type { FunctionComponent } from 'react';
import type { HorizontalSize } from 'react-virtualized-auto-sizer';
import AutoSizer from 'react-virtualized-auto-sizer';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import BlockContent from '../../../../components/templates/BlockContent';
import base from '../../../../theme/base';
import { spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import BlockField from '../_global/BlockField';
import type { WidgetDisplay } from '../_global/widgetUtils';
import { getWidgetHeight } from '../_global/widgetUtils';
import type { BlockFieldProps } from '../FieldLibraryTypes';
import IFrameFieldRenderer from './IFrameFieldRenderer';

const useStyles = makeStyles({
  iFrameContainer: {
    width: '100%',
  },
  container: {
    width: '100%',
    marginBottom: spacingRem.blockSpacing,
    padding: spacingRem.xs,
    boxShadow: base.shadowElevation.low,
    borderRadius: base.borderRadius.medium,
  },
  preventIFrameFlick: {
    position: 'absolute',
  },
}, 'iFrameBlockField');

interface IFrameBlockFieldProps {
  parametersMapping: ParametersMapping,
  fieldId: string,
  widgetDisplay: WidgetDisplay,
  blockFieldProps: BlockFieldProps,
}

const IFrameBlockField: FunctionComponent<IFrameBlockFieldProps> = ({ parametersMapping, fieldId, blockFieldProps, widgetDisplay }) => {
  const classes = useStyles();

  return (
    <BlockField
      fieldId={fieldId}
      {...blockFieldProps}
      isVertical
    >
      <BlockContent padded>
        <div className={classes.container}>
          <AutoSizer disableHeight>
            {({ width }: HorizontalSize) => {
              const height = getWidgetHeight(widgetDisplay);
              return (
                <div className={classes.iFrameContainer} style={{ height }}>
                  <div className={classes.preventIFrameFlick} style={{ height, width }}>
                    <IFrameFieldRenderer
                      height={height}
                      width={width}
                      fieldId={fieldId}
                      parametersMapping={parametersMapping}
                    />
                  </div>
                </div>

              );
            }}
          </AutoSizer>
        </div>
      </BlockContent>
    </BlockField>
  );
};

export default IFrameBlockField;
