import type { ConceptDefinitionStoreObject } from 'yooi-modules/modules/conceptModule';
import { FILTER_PARAMETER_LOGGED_USER } from 'yooi-modules/modules/conceptModule';
import { ConceptDefinition_ChipBackgroundColor, User } from 'yooi-modules/modules/conceptModule/ids';
import {
  UnsetDashboardParameterOption,
  Widget,
  Widget_Dashboard,
  Widget_DisplayMode,
  Widget_Field,
  Widget_HeaderBackgroundColor,
  Widget_Height,
  Widget_Title,
  Widget_Width,
  Widget_X,
  Widget_Y,
} from 'yooi-modules/modules/dashboardModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreReadOnly } from 'yooi-store';
import type { FrontObjectStore } from '../../store/useStore';
import i18n from '../../utils/i18n';
import { getFieldConfigurationHandler } from './fields/FieldLibrary';
import type { Option } from './modelTypeUtils';

export const getWidgetUrl = (widgetId: string): string => `/widget/${widgetId}`;

export const duplicateWidget = (
  store: FrontObjectStore,
  widgetId: string,
  newConceptInstanceId ?: string,
  parameterMap ?: Record<string, string>
): string => {
  const initialWidget = store.getObject(widgetId);

  return store.createObject({
    [Instance_Of]: Widget,
    [Widget_Title]: newConceptInstanceId ? initialWidget[Widget_Title] : `${initialWidget[Widget_Title]} (copy)`,
    [Widget_Field]: (
      initialWidget.navigateOrNull(Widget_Field)
        ? getFieldConfigurationHandler(store, initialWidget[Widget_Field] as string).duplicateFieldDefinition?.({ parameterMap })
        : undefined
    ),
    [Widget_Dashboard]: newConceptInstanceId ?? initialWidget[Widget_Dashboard],
    [Widget_HeaderBackgroundColor]: initialWidget[Widget_HeaderBackgroundColor],
    [Widget_DisplayMode]: initialWidget[Widget_DisplayMode],
    [Widget_X]: initialWidget[Widget_X],
    [Widget_Y]: initialWidget[Widget_Y],
    [Widget_Height]: initialWidget[Widget_Height],
    [Widget_Width]: initialWidget[Widget_Width],
  });
};

export const buildUnsetOption = (label?: string): { id: string, label: string, tooltip: string } => ({
  id: UnsetDashboardParameterOption,
  label: (label && label !== '') ? label : i18n`Not set`,
  tooltip: (label && label !== '') ? label : i18n`Not set`,
});

export const getLoggedUserOption = (store: ObjectStoreReadOnly): Option => ({
  id: FILTER_PARAMETER_LOGGED_USER,
  label: i18n`Logged-in User`,
  color: store.getObject<ConceptDefinitionStoreObject>(User)[ConceptDefinition_ChipBackgroundColor],
  borderStyle: 'dashed',
  tooltip: i18n`Logged-in User`,
});
