import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createCollaboration } from 'yooi-modules/modules/collaborationModule';
import {
  ConceptIntentionGroups,
  ConceptIntentionGroups_Role_Concept,
  ConceptIntentionGroups_Role_Group,
  ConceptIntentionGroups_Role_Intention,
  ConceptIntentionRoles,
  ConceptIntentionRoles_Role_Concept,
  ConceptIntentionRoles_Role_Intention,
  ConceptIntentionRoles_Role_Role,
  ConceptIntentionUsers,
  ConceptIntentionUsers_Role_Concept,
  ConceptIntentionUsers_Role_Intention,
  ConceptIntentionUsers_Role_User,
  Intention_Description,
  Intention_Name,
} from 'yooi-modules/modules/collaborationModule/ids';
import { getViewerGroupsSet } from 'yooi-modules/modules/conceptModule';
import { Concept, ConceptDefinition, ConceptRole_ConceptDefinition, Group, User } from 'yooi-modules/modules/conceptModule/ids';
import type { PlatformConfigurationStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import { CurrentPlatformConfiguration, PlatformConfiguration_CollaborationMessage } from 'yooi-modules/modules/platformConfigurationModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined } from 'yooi-utils';
import Button from '../../../../../components/atoms/Button';
import Icon, { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import Typo from '../../../../../components/atoms/Typo';
import Chooser from '../../../../../components/molecules/Chooser';
import SearchAndSelectMultiple from '../../../../../components/molecules/SearchAndSelectMultiple';
import useAcl from '../../../../../store/useAcl';
import useAuth from '../../../../../store/useAuth';
import useStore from '../../../../../store/useStore';
import { spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import { formatOrUndef } from '../../../../../utils/stringUtils';
import { useCollaborationPanelUpdater } from '../../../../../utils/useCollaborationContext';
import useDerivedState from '../../../../../utils/useDerivedState';
import { useHighlightNotify } from '../../../../../utils/useHighlight';
import { SizeVariant } from '../../../../../utils/useSizeContext';
import useTheme from '../../../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../../../utils/useUsageContext';
import { getInlineCreationBuilder, getViewerUsersSet } from '../../../conceptUtils';
import { defaultOptionComparator, getChipOptions, getModelTypeInstances, getSearchChipOptions } from '../../../modelTypeUtils';
import CollaborationBaseLayout from '../_global/CollaborationBaseLayout';
import CollaborationInput, { CollaborationInputVariant } from '../_global/CollaborationInput';
import usePanelObserver from '../usePanelObserver';
import { getIntentionGroups, getIntentions, getIntentionUsers } from '../utils/collaborationUtils';

const useStyles = makeStyles((theme) => ({
  line: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '3.2rem',
    gap: spacingRem.xs,
  },
  titleLine: {
    justifyContent: 'space-between',
  },
  buttonLine: {
    justifyContent: 'flex-end',
  },
  textInputLine: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    minHeight: '10rem',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: spacingRem.s,
    alignItems: 'center',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.s,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  separator: {
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
  },
}), 'collaborationCreatePanel');

const CollaborationCreatePanel: FunctionComponent = () => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const { canAssignCollaborator, canCreateObject } = useAcl();
  const { loggedUserId } = useAuth();
  const platformConfiguration = store.getObject<PlatformConfigurationStoreObject>(CurrentPlatformConfiguration);
  const disclaimerMessage = platformConfiguration[PlatformConfiguration_CollaborationMessage];

  const location = useLocation();

  const panel = useCollaborationPanelUpdater();
  const highlight = useHighlightNotify();
  const [text, setText] = useState('');
  const { fullContext, intention: panelIntention, context, pageContext } = usePanelObserver();
  const [intentionId, setIntentionId] = useDerivedState(() => panelIntention, [panelIntention]);
  const [userRecipientsIds, setUserRecipientsIds] = useState<string[]>([]);
  const [groupRecipientsIds, setGroupRecipientsIds] = useState<string[]>([]);
  const [roleRecipientsIds, setRoleRecipientsIds] = useState<string[]>([]);
  const subscribersCount = userRecipientsIds.length + groupRecipientsIds.length + roleRecipientsIds.length;

  const [isSubscribersCollapsed, setIsSubscribersCollapsed] = useState(false);
  const firstClassConceptId = fullContext?.find((id) => id.length === 1 && isInstanceOf(store.getObject(id[0]), Concept))?.[0];
  let conceptDefinitionId: string | undefined;

  if (firstClassConceptId) {
    const concept = store.getObjectOrNull(firstClassConceptId);
    conceptDefinitionId = concept?.[Instance_Of] as string | undefined;
  } else {
    conceptDefinitionId = fullContext?.find((id) => id.length === 1 && isInstanceOf(store.getObject(id[0]), ConceptDefinition))?.[0];
  }

  const intentions = getIntentions(store, conceptDefinitionId);

  const onIntentionChooserClick = (index: number) => {
    const { id } = intentions[index];
    setIntentionId(id);
    const intentionUsers = getIntentionUsers(store, id)
      .filter((user) => user !== loggedUserId);
    const conceptsIntentionUsers = conceptDefinitionId ? store.withAssociation(ConceptIntentionUsers)
      .withRole(ConceptIntentionUsers_Role_Concept, conceptDefinitionId)
      .withRole(ConceptIntentionUsers_Role_Intention, id)
      .list()
      .map((assoc) => assoc.role(ConceptIntentionUsers_Role_User))
      .filter((user) => !intentionUsers.includes(user) && user !== loggedUserId) : [];
    setUserRecipientsIds([
      loggedUserId,
      ...intentionUsers,
      ...conceptsIntentionUsers,
    ]);
    const intentionGroups = getIntentionGroups(store, id);
    setGroupRecipientsIds([
      ...intentionGroups,
      ...(conceptDefinitionId ? store.withAssociation(ConceptIntentionGroups)
        .withRole(ConceptIntentionGroups_Role_Concept, conceptDefinitionId)
        .withRole(ConceptIntentionGroups_Role_Intention, id)
        .list()
        .map((assoc) => assoc.role(ConceptIntentionGroups_Role_Group))
        .filter((group) => !intentionGroups.includes(group)) : []),
    ]);
    setRoleRecipientsIds(conceptDefinitionId
      ? store.withAssociation(ConceptIntentionRoles)
        .withRole(ConceptIntentionRoles_Role_Concept, conceptDefinitionId)
        .withRole(ConceptIntentionRoles_Role_Intention, id)
        .list()
        .map((assoc) => assoc.role(ConceptIntentionRoles_Role_Role))
      : []);
  };

  if (!intentionId && intentions.length > 0) {
    setIntentionId(intentions[0].id);
    onIntentionChooserClick(0);
  }

  const isLibraryPageContext = pageContext && pageContext.length === 1;
  let backContext: string[] | undefined;
  if (pageContext) {
    backContext = !isLibraryPageContext ? pageContext[pageContext.length - 1] : context;
  }

  return (
    <CollaborationBaseLayout
      title={i18n`New collaboration`}
      titleButton={{
        icon: IconName.keyboard_arrow_left,
        tooltip: i18n`All collaborations`,
        onClick: () => {
          if (!isLibraryPageContext) {
            highlight(undefined);
          }
          panel.openList(backContext || undefined);
        },
      }}
      content={(
        <UsageContextProvider usageVariant={UsageVariant.inForm}>
          <div className={classes.block}>
            <div className={classes.line}>
              <Typo color={theme.color.text.secondary}>{i18n`Intention`}</Typo>
            </div>
            <div className={classes.line}>
              <Chooser
                actions={
                  intentions.map(({ key, [Intention_Name]: name, [Intention_Description]: description }) => (
                    { key, name: formatOrUndef(name as string), tooltip: formatOrUndef(description || (name as string)) }
                  ))
                }
                onClick={(index) => onIntentionChooserClick(index)}
                selectedIndexes={[intentions.findIndex(({ id }) => id === intentionId)]}
                limit={2}
              />
            </div>
          </div>
          <div className={classes.block}>
            <div className={classes.line}>
              <Typo color={theme.color.text.secondary}>{i18n`Message`}</Typo>
              {disclaimerMessage && <Icon name={IconName.info} colorVariant={IconColorVariant.info} tooltip={disclaimerMessage} />}
            </div>
            <div className={classes.textInputLine}>
              <CollaborationInput
                variant={CollaborationInputVariant.New}
                onChange={(newText) => setText(newText)}
                displayDisclaimerMessage={false}
              />
            </div>
            <div className={classnames(classes.line, classes.buttonLine)}>
              <Button
                title={i18n`Create`}
                onClick={() => {
                  const collaborationId = createCollaboration({
                    store,
                    intention: { intentionId },
                    path: location.pathname,
                    hash: location.hash,
                    context: fullContext,
                    userRecipientsIds,
                    groupRecipientsIds,
                    roleRecipientsIds,
                    text,
                  });

                  panel.openDetail(context, collaborationId);
                }}
                disabled={text === ''}
              />
            </div>
          </div>
          <span className={classes.separator} />
          <div className={classnames(classes.line, classes.titleLine)}>
            <div className={classes.title}>
              <Icon name={IconName.notifications} tooltip={i18n`People that will be notified of new messages.`} />
              <Typo>{subscribersCount === 1 ? i18n`1 subscriber` : i18n`${subscribersCount} subscribers`}</Typo>
            </div>
            <IconOnlyButton
              tooltip={isSubscribersCollapsed ? i18n`Expand` : i18n`Collapse`}
              variant={IconOnlyButtonVariants.tertiary}
              iconName={isSubscribersCollapsed ? IconName.keyboard_arrow_left : IconName.expand_more}
              onClick={() => setIsSubscribersCollapsed((current) => !current)}
              sizeVariant={SizeVariant.small}
            />
          </div>
          {
            isSubscribersCollapsed
              ? null
              : (
                <div className={classes.block}>
                  <div className={classnames(classes.line, classes.titleLine)}>
                    <div className={classes.title}>
                      <Typo color={theme.color.text.secondary}>{i18n`Users`}</Typo>
                      {userRecipientsIds.length > 0 ? (<Typo color={theme.color.text.disabled}>{userRecipientsIds.length}</Typo>) : null}
                    </div>
                  </div>
                  <div className={classes.content}>
                    <SearchAndSelectMultiple
                      placeholder={i18n`Add user`}
                      selectedOptions={userRecipientsIds.map((id) => getChipOptions(store, id)).filter(filterNullOrUndefined).sort(defaultOptionComparator)}
                      computeOptions={() => {
                        const users = firstClassConceptId && !canAssignCollaborator(firstClassConceptId)
                          ? Array.from(getViewerUsersSet(store, firstClassConceptId))
                          : getModelTypeInstances(store, User).map(({ id }) => id);
                        return users.map((id) => getChipOptions(store, id)).filter(filterNullOrUndefined).sort(defaultOptionComparator);
                      }}
                      searchOptions={getSearchChipOptions(store, User)}
                      onSelect={({ id }) => setUserRecipientsIds((current) => [...current, id])}
                      onDelete={({ id }) => setUserRecipientsIds((current) => current.filter((i) => i !== id))}
                      getInlineCreation={
                        canCreateObject(User) && firstClassConceptId && canAssignCollaborator(firstClassConceptId)
                          ? getInlineCreationBuilder(
                            store,
                            User,
                            (id) => setUserRecipientsIds((current) => [...current, id])
                          )
                          : undefined
                      }
                    />
                  </div>
                  <div className={classnames(classes.line, classes.titleLine)}>
                    <div className={classes.title}>
                      <Typo color={theme.color.text.secondary}>{i18n`Groups`}</Typo>
                      {groupRecipientsIds.length > 0 ? (<Typo color={theme.color.text.disabled}>{groupRecipientsIds.length}</Typo>) : null}
                    </div>
                  </div>
                  <div className={classes.content}>
                    <SearchAndSelectMultiple
                      placeholder={i18n`Add group`}
                      selectedOptions={groupRecipientsIds.map((id) => getChipOptions(store, id)).filter(filterNullOrUndefined).sort(defaultOptionComparator)}
                      computeOptions={() => {
                        const groups = firstClassConceptId && !canAssignCollaborator(firstClassConceptId)
                          ? Array.from(getViewerGroupsSet(store, firstClassConceptId))
                          : getModelTypeInstances(store, Group).map(({ id }) => id);
                        return groups.map((id) => getChipOptions(store, id)).filter(filterNullOrUndefined).sort(defaultOptionComparator);
                      }}
                      searchOptions={getSearchChipOptions(store, Group)}
                      onSelect={({ id }) => setGroupRecipientsIds((current) => [...current, id])}
                      onDelete={({ id }) => setGroupRecipientsIds((current) => current.filter((i) => i !== id))}
                      getInlineCreation={
                        canCreateObject(Group) && firstClassConceptId && canAssignCollaborator(firstClassConceptId)
                          ? getInlineCreationBuilder(
                            store,
                            Group,
                            (id) => setGroupRecipientsIds((current) => [...current, id])
                          )
                          : undefined
                      }
                    />
                  </div>
                  <div className={classnames(classes.line, classes.titleLine)}>
                    <div className={classes.title}>
                      <Typo color={theme.color.text.secondary}>{i18n`Roles`}</Typo>
                      {roleRecipientsIds.length > 0 && (<Typo color={theme.color.text.disabled}>{roleRecipientsIds.length}</Typo>)}
                    </div>
                  </div>
                  <div className={classes.content}>
                    <SearchAndSelectMultiple
                      placeholder={i18n`Add role`}
                      selectedOptions={roleRecipientsIds.map((id) => getChipOptions(store, id)).filter(filterNullOrUndefined).sort(defaultOptionComparator)}
                      computeOptions={() => (conceptDefinitionId
                        ? store.getObject(conceptDefinitionId)
                          .navigateBack(ConceptRole_ConceptDefinition)
                          .map((role) => getChipOptions(store, role.id))
                          .filter(filterNullOrUndefined)
                          .sort(defaultOptionComparator)
                        : [])}
                      onSelect={({ id }) => setRoleRecipientsIds((current) => [...current, id])}
                      onDelete={({ id }) => setRoleRecipientsIds((current) => current.filter((i) => i !== id))}
                      readOnly={!!firstClassConceptId && !canAssignCollaborator(firstClassConceptId)}
                    />
                  </div>
                </div>
              )
          }
        </UsageContextProvider>
      )}
      contentFit
      mainBackgroundColor={theme.color.background.neutral.subtle}
      contentBackgroundColor={theme.color.background.neutral.default}
    />
  );
};

export default CollaborationCreatePanel;
