import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { BLOCK_PARAMETER_CURRENT, getKinshipFieldId, isEmbeddedConceptInstance } from 'yooi-modules/modules/conceptModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { StoreObject } from 'yooi-store';
import i18n from '../../utils/i18n';

const SEPARATOR = '_';

export const getBlockParameterDefinitionCurrent = (parameterTypeId: string): SingleParameterDefinition => ({
  id: BLOCK_PARAMETER_CURRENT,
  type: 'dimension',
  typeId: parameterTypeId,
  label: i18n`Displayed instance`,
});

export const buildEmbeddingFieldFilterId = (conceptDefinitionId: string, fieldId: string): string => [conceptDefinitionId, fieldId].join(SEPARATOR);

export const buildConceptInstanceFilterId = (concept: StoreObject): string => {
  const kinshipFieldId = getKinshipFieldId(concept);
  if (isEmbeddedConceptInstance(concept)) {
    return [concept[Instance_Of], kinshipFieldId].join(SEPARATOR);
  } else {
    return concept[Instance_Of] as string;
  }
};
