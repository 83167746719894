import { equals } from 'ramda';
import { v4 as uuid } from 'uuid';
import type { FilterCondition, FilterNode, Filters, PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import {
  FILTER_PARAMETER_LOGGED_USER,
  FilterConditionOperators,
  getFilterState,
  isFieldStep,
  isFilterNode,
  isFilterStep,
  isFilterValuePath,
  isGlobalDimensionStep,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import { User } from 'yooi-modules/modules/conceptModule/ids';
import { isMultipleMappingStep } from 'yooi-modules/modules/conceptModule/utils/path/pathUtils';
import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import type { FrontObjectStore } from '../../../store/useStore';
import i18n from '../../../utils/i18n';

export interface FrontFilterNode extends FilterNode {
  id: string,
  children?: FrontFilters[],
}

export interface FrontFilterCondition extends FilterCondition {
  id: string,
  isSimpleCondition: boolean,
}

export type FrontFilters = FrontFilterNode | FrontFilterCondition;

export const getLoggedUserParameterDefinition = (): SingleParameterDefinition => ({ id: FILTER_PARAMETER_LOGGED_USER, label: i18n`Logged-in User`, typeId: User, type: 'parameter' });

export enum DropPlacement {
  before = 'before',
  after = 'after',
  inside = 'inside',
}

const getAllFilterConditions = (filter: Filters): FilterCondition[] => {
  const filterConditions: FilterCondition[] = [];
  if (isFilterNode(filter) && filter.children) {
    filter.children.forEach((child) => filterConditions.push(...getAllFilterConditions(child)));
  } else if (!isFilterNode(filter)) {
    filterConditions.push(filter);
  }
  return filterConditions;
};

export const getFilterConditionLabel = (filterCondition: FilterConditionOperators): string => {
  switch (filterCondition) {
    case FilterConditionOperators.OR:
      return i18n`or`;
    case FilterConditionOperators.AND:
      return i18n`and`;
    default:
      return '';
  }
};

export const getFilterNodeTitle = (filterCondition: FilterConditionOperators): string => {
  switch (filterCondition) {
    case FilterConditionOperators.AND:
      return i18n`All of the following are true`;
    case FilterConditionOperators.OR:
      return i18n`Any of the following are true`;
    default:
      return '';
  }
};

export const isFrontFilterNode = (
  filters: FrontFilters
): filters is FrontFilterNode => !!(filters as Partial<FilterNode>).condition;

const isFilterInNode = (parentFilter: FrontFilters, targetFilterId: string): boolean => {
  if (isFrontFilterNode(parentFilter) && parentFilter.children) {
    const isTargetFilterInChildren = parentFilter.children.some((child) => child.id === targetFilterId);
    return isTargetFilterInChildren ? true : parentFilter.children.some((child) => isFrontFilterNode(child) && isFilterInNode(child, targetFilterId));
  }
  return false;
};

const getFilterFromId = (droppedFilterId: string, parentFilter: FrontFilters): FrontFilters | undefined => {
  if (parentFilter.id === droppedFilterId) {
    return parentFilter;
  }
  if (isFrontFilterNode(parentFilter) && parentFilter.children) {
    const droppedFilter = parentFilter.children.find((child) => child.id === droppedFilterId);
    if (droppedFilter) {
      return droppedFilter;
    } else {
      const droppedFilterNested = parentFilter.children.map((child) => (isFrontFilterNode(child) ? getFilterFromId(droppedFilterId, child) : undefined));
      return droppedFilterNested.filter(filterNullOrUndefined).at(0);
    }
  } else {
    return undefined;
  }
};

export const canDropFilter = (parentFilter: FrontFilters, targetFilterId: string, droppedFilterId: string): boolean => {
  if (droppedFilterId === targetFilterId) {
    return false;
  }

  const droppedFilter = getFilterFromId(droppedFilterId, parentFilter);
  if (!droppedFilter) {
    return false;
  }

  if (!isFrontFilterNode(droppedFilter)) {
    return true;
  }
  if (isFrontFilterNode(parentFilter) && parentFilter.children) {
    const isDroppedFilterInParent = isFilterInNode(parentFilter, droppedFilter.id);
    if (isDroppedFilterInParent) {
      return !isFilterInNode(droppedFilter, targetFilterId);
    }
    return false;
  } else {
    return false;
  }
};

export const addNewFilterToFilters = (filter: FrontFilters, newFilter: FrontFilters): FrontFilters => {
  if (isFrontFilterNode(filter)) {
    return joinObjects(
      filter,
      {
        children: [
          ...(filter.children ?? []),
          newFilter,
        ],
      }
    );
  } else {
    return {
      id: uuid(),
      condition: FilterConditionOperators.OR,
      children: [filter, newFilter],
    };
  }
};

const changeFiltersIds = (filterToDuplicate: FrontFilters): FrontFilters => {
  if (isFrontFilterNode(filterToDuplicate)) {
    return joinObjects(
      filterToDuplicate,
      {
        id: uuid(),
        children: filterToDuplicate.children?.map((child) => changeFiltersIds(child)),
      }
    );
  } else {
    return joinObjects(
      filterToDuplicate,
      { id: uuid() }
    );
  }
};

export const duplicateFilter = (filterToDuplicate: FrontFilters, parentNode?: FrontFilterNode): FrontFilters => {
  if (parentNode) {
    return joinObjects(
      parentNode,
      { children: [...parentNode.children ?? [], changeFiltersIds(filterToDuplicate)] }
    );
  }
  return {
    condition: FilterConditionOperators.OR,
    id: uuid(),
    children: [filterToDuplicate, changeFiltersIds(filterToDuplicate)],
  };
};

const removeFilterFromNode = (filterIdToRemove: string, parentFilter: FrontFilters): FrontFilters => {
  if (isFrontFilterNode(parentFilter) && parentFilter.children) {
    const filterToRemoveIndex = parentFilter.children.findIndex(({ id }) => id === filterIdToRemove);
    if (filterToRemoveIndex !== undefined && filterToRemoveIndex >= 0) {
      const newChildren = parentFilter.children;
      newChildren.splice(filterToRemoveIndex, 1);
      return joinObjects(
        parentFilter,
        { children: newChildren }
      );
    } else {
      return joinObjects(
        parentFilter,
        { children: parentFilter.children.map((child) => (isFrontFilterNode(child) ? removeFilterFromNode(filterIdToRemove, child) : child)) }
      );
    }
  } else {
    return parentFilter;
  }
};

const addFilterToNode = (filterToAdd: FrontFilters, targetFilter: FrontFilters, parentFilter: FrontFilters, placement: DropPlacement): FrontFilters => {
  if (isFrontFilterNode(parentFilter) && parentFilter.children) {
    const targetFilterIndex = parentFilter.children?.findIndex((child) => child.id === targetFilter.id);
    if (targetFilterIndex !== -1) {
      const newChildren = parentFilter.children;
      if (placement === DropPlacement.before) {
        newChildren.splice(targetFilterIndex, 0, filterToAdd);
      } else if (placement === DropPlacement.after) {
        newChildren.splice(targetFilterIndex + 1, 0, filterToAdd);
      } else if (placement === DropPlacement.inside) {
        const targetInsideFilter = parentFilter.children[targetFilterIndex];
        if (isFrontFilterNode(targetInsideFilter)) {
          newChildren.splice(targetFilterIndex, 1, joinObjects(
            targetInsideFilter,
            { children: targetInsideFilter.children ? [filterToAdd, ...targetInsideFilter.children] : [filterToAdd] }
          ));
        }
      }
      return joinObjects(parentFilter, {
        children: newChildren,
      });
    } else {
      return joinObjects(
        parentFilter,
        { children: parentFilter.children.map((child) => (addFilterToNode(filterToAdd, targetFilter, child, placement))) }
      );
    }
  }
  return parentFilter;
};

export const dropFilter = (droppedFilterId: string, targetFilterId: string, parentFilter: FrontFilters, placement: DropPlacement): FrontFilters => {
  const droppedFilter = getFilterFromId(droppedFilterId, parentFilter);
  if (!droppedFilter) {
    return parentFilter;
  }
  const targetFilter = getFilterFromId(targetFilterId, parentFilter);
  if (!targetFilter) {
    return parentFilter;
  }
  const parentFilterWithDroppedFilterRemoved = removeFilterFromNode(droppedFilterId, parentFilter);
  return addFilterToNode(droppedFilter, targetFilter, parentFilterWithDroppedFilterRemoved, placement);
};

export const isConditionSimple = (filterCondition: FilterCondition, basePath: PathStep[] | undefined): boolean => {
  const { leftValue } = filterCondition;
  if (isFilterValuePath(filterCondition.rightValue) || !basePath) {
    return false;
  }
  if (leftValue && leftValue.length > 0) {
    if (leftValue.length === basePath.length) {
      return equals(leftValue, basePath);
    } else if (leftValue.length === basePath.length + 1 && equals(leftValue.slice(0, leftValue.length - 1), basePath)) {
      return leftValue[leftValue.length - 1].type === PathStepType.field;
    } else {
      return false;
    }
  }
  return true;
};

export const addNewFilterNodeToFilters = (filter: FrontFilters, defaultFilterCondition: FrontFilters): FrontFilters => {
  if (isFilterNode(filter)) {
    return joinObjects(
      filter,
      {
        children: [
          ...(filter.children ?? []),
          { id: uuid(), condition: FilterConditionOperators.OR, children: [defaultFilterCondition] },
        ],
      }
    );
  } else {
    return {
      id: uuid(),
      condition: FilterConditionOperators.OR,
      children: [
        filter,
        {
          id: uuid(),
          condition: FilterConditionOperators.OR,
          children: [defaultFilterCondition],
        }],
    };
  }
};

export const countValidFilters = (store: FrontObjectStore, filters: Filters | undefined): number => {
  if (!filters) {
    return 0;
  }
  const filterConditions: FilterCondition[] = getAllFilterConditions(filters);
  return filterConditions ? filterConditions
    .filter((filter) => !!getFilterState(store, filter)).length : 0;
};

export const getCompleteLeftBarFilters = (filters: Filters[]): Filters => ({
  condition: FilterConditionOperators.AND,
  children: filters ?? [],
});

export const composeFilters = (filters: (Filters | undefined)[]): Filters => ({
  condition: FilterConditionOperators.AND,
  children: filters.filter(filterNullOrUndefined),
});

export const isFilterReadOnly = (filter: boolean | undefined): boolean | undefined => (filter == null ? filter : !filter);

const frontFilterNodeToFilterNode = (frontFilters: FrontFilterNode): FilterNode => {
  const filters: FilterNode & { id?: string } = frontFilters;
  delete filters.id;
  return filters;
};

const frontFilterConditionToFilterCondition = (frontFilters: FrontFilterCondition): FilterCondition => {
  const filters: FilterCondition & { id?: string, isSimpleCondition?: boolean } = frontFilters;
  delete filters.id;
  delete filters.isSimpleCondition;
  return filters;
};

export const simplifyFilter = (store: ObjectStoreWithTimeseries, filters: FrontFilters): Filters | undefined => {
  let simplifiedFilter: Filters | undefined;
  if (isFilterNode(filters)) {
    if (filters.children && filters.children.length === 1) {
      return simplifyFilter(store, filters.children[0]);
    } else {
      simplifiedFilter = filters.children && filters.children.length > 0 ? joinObjects(
        frontFilterNodeToFilterNode(filters),
        { children: filters.children.map((child) => simplifyFilter(store, child)).filter(filterNullOrUndefined) }
      ) : undefined;
      if (simplifiedFilter && simplifiedFilter.children && simplifiedFilter.children.length > 0) {
        if (simplifiedFilter.children.length === 1) {
          return simplifiedFilter.children[0];
        }
        return simplifiedFilter;
      } else {
        return undefined;
      }
    }
  } else {
    return getFilterState(store, filters) ? frontFilterConditionToFilterCondition(filters) : undefined;
  }
};

export const getFiltersPath = (path: PathStep[]): { path: PathStep[], type: 'mapping' | 'filter' }[] => path.reduce<{ path: PathStep[], type: 'mapping' | 'filter' }[]>((
  acc,
  el,
  index,
  self
) => {
  if (index === 1 && isGlobalDimensionStep(path[0]) && isFieldStep(path[1])) {
    return [...acc, { path: self.slice(0, index + 1), type: 'mapping' }];
  } else if (isFilterStep(el) || isMultipleMappingStep(el)) {
    return [...acc, { path: self.slice(0, index + 1), type: 'filter' }];
  } else {
    return acc;
  }
}, []);
