import type { FunctionComponent } from 'react';
import type { FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { FILTER_PARAMETER_LOGGED_USER, InstanceReferenceType, isSingleRelationalType, PathStepType } from 'yooi-modules/modules/conceptModule';
import { User } from 'yooi-modules/modules/conceptModule/ids';
import type { HomepageRaw, HomepageStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import { Homepage, Homepage_DisplayConditions, Homepage_Path, Homepage_Rank } from 'yooi-modules/modules/platformConfigurationModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareProperty, compareRank, ranker } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import OverflowMenu from '../../../components/molecules/OverflowMenu';
import SpacingLine from '../../../components/molecules/SpacingLine';
import BlockTitle, { BlockTitleVariant } from '../../../components/templates/BlockTitle';
import DataTable from '../../../components/templates/DataTable';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import useNewLineFocus from '../../../utils/useNewLineFocus';
import FilterComposite from '../../_global/filter/filterComposite/FilterComposite';
import { getLoggedUserParameterDefinition } from '../../_global/filter/filterUtils';
import PathStepsInput from '../../_global/path/PathStepsInput';
import { createPathConfigurationHandler, StepValidationState } from '../../_global/pathConfigurationHandler';

const HomepageTab: FunctionComponent = () => {
  const store = useStore();

  const [newLineFocus, setNewLineFocus] = useNewLineFocus();

  const list = store.getObject(Homepage)
    .navigateBack<HomepageStoreObject>(Class_Instances)
    .sort(compareProperty(Homepage_Rank, compareRank));

  const rankedList = ranker.decorateList(list, (homepage) => homepage[Homepage_Rank]);

  const parameterDefinitions = [getLoggedUserParameterDefinition()];

  return (
    <VerticalBlock>
      <BlockTitle
        title={i18n`Different home pages can be defined for different users. The home pages are ordered, and the one displayed for a given user will be the first to respect the filters defined for that home page.`}
        variant={BlockTitleVariant.inline}
      />
      <DataTable<HomepageStoreObject>
        list={list.map((item) => ({ key: item.id, type: 'item', item, color: undefined }))}
        columnsDefinition={[
          {
            propertyId: Homepage_Path,
            name: i18n`Path`,
            width: 85,
            focusable: true,
            cellRender: ({ id, [Homepage_Path]: path }, focusAndScrollOnMount) => (
              <PathStepsInput
                focusOnMount={focusAndScrollOnMount}
                initialPath={path ?? []}
                parameterDefinitions={parameterDefinitions}
                onSubmit={(newPath) => {
                  store.updateObject<HomepageRaw>(id, { [Homepage_Path]: newPath });
                }}
                valuePathHandler={
                  createPathConfigurationHandler(
                    store,
                    parameterDefinitions,
                    [
                      ({ pathStep, isNPath }) => {
                        switch (pathStep.type) {
                          case PathStepType.global:
                            return [{ state: StepValidationState.invalid, reasonMessage: i18n`Global dimension step not supported.` }];
                          case PathStepType.dimension: {
                            if (isNPath) {
                              return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`Path must not end with a dimension.` }];
                            } else {
                              return [{ state: StepValidationState.valid }];
                            }
                          }
                          case PathStepType.filter:
                            if (isNPath) {
                              return [{ state: StepValidationState.invalid, reasonMessage: i18n`Filters are not supported.` }];
                            } else {
                              return [{ state: StepValidationState.valid }];
                            }
                          case PathStepType.mapping: {
                            if (
                              (pathStep.mapping.type === InstanceReferenceType.parameter && pathStep.mapping.id !== FILTER_PARAMETER_LOGGED_USER)
                              || (pathStep.mapping.type === InstanceReferenceType.instance && store.getObjectOrNull(pathStep.mapping.id) === null)
                            ) {
                              return [{ state: StepValidationState.invalid, reasonMessage: i18n`Second step must be an existing instance ot parameter.` }];
                            } else {
                              return [{ state: StepValidationState.valid }];
                            }
                          }
                          case PathStepType.multipleMapping: {
                            if (pathStep.id !== FILTER_PARAMETER_LOGGED_USER) {
                              return [{ state: StepValidationState.invalid, reasonMessage: i18n`Second step must be an existing instance ot parameter.` }];
                            } else {
                              return [{ state: StepValidationState.valid }];
                            }
                          }
                          case PathStepType.field: {
                            const field = store.getObjectOrNull<FieldStoreObject>(pathStep.fieldId);
                            if (field === null) {
                              return [{ state: StepValidationState.invalid, reasonMessage: i18n`Field does not exist anymore.` }];
                            } else if (!isSingleRelationalType(field[Instance_Of])) {
                              return [{ state: StepValidationState.invalid, reasonMessage: i18n`Field must returns an instance.` }];
                            } else {
                              return [{ state: StepValidationState.valid }];
                            }
                          }
                        }
                      },
                    ]
                  )
                }
              />
            ),
          },
          {
            propertyId: Homepage_DisplayConditions,
            name: i18n`Display conditions`,
            width: 15,
            cellRender: ({ id, [Homepage_DisplayConditions]: filters }) => (
              <FilterComposite
                parameterDefinitions={parameterDefinitions}
                filtersDefinition={{
                  updateFilters: (newFilters) => {
                    store.updateObject<HomepageRaw>(id, { [Homepage_DisplayConditions]: newFilters.at(0) ?? null });
                  },
                  definition: [{ filter: filters, subtitle: i18n`Display when...` }],
                }}
                suggestedPaths={[{
                  label: i18n`Logged-in User`,
                  path: [
                    { type: PathStepType.dimension, conceptDefinitionId: User },
                    { type: PathStepType.mapping, mapping: { type: InstanceReferenceType.parameter, id: FILTER_PARAMETER_LOGGED_USER } },
                  ],
                }]}
              />
            ),
          },
          {
            propertyId: 'moveUp',
            action: true,
            cellRender: ({ id }, _, index) => (
              <SpacingLine>
                <IconOnlyButton
                  disabled={index === 0}
                  onClick={() => store.updateObject(id, { [Homepage_Rank]: rankedList[index].moveUpRank() })}
                  iconName={IconName.expand_less}
                  tooltip={i18n`Move Up`}
                  variant={IconOnlyButtonVariants.tertiary}
                />
              </SpacingLine>
            ),
          },
          {
            propertyId: 'moveDown',
            action: true,
            cellRender: ({ id }, _, index) => (
              <SpacingLine>
                <IconOnlyButton
                  disabled={index === (list.length - 1)}
                  onClick={() => store.updateObject(id, { [Homepage_Rank]: rankedList[index].moveDownRank() })}
                  iconName={IconName.expand_more}
                  tooltip={i18n`Move Down`}
                  variant={IconOnlyButtonVariants.tertiary}
                />
              </SpacingLine>
            ),
          },
          {
            propertyId: 'delete',
            action: true,
            cellRender: ({ id }, _, index) => (
              <SpacingLine>
                <OverflowMenu
                  menuItems={[
                    {
                      key: 'add_before',
                      name: i18n`Add above`,
                      icon: IconName.input,
                      onClick: () => {
                        const newId = store.createObject({ [Instance_Of]: Homepage, [Homepage_Rank]: rankedList[index].insertBeforeRank() });
                        setNewLineFocus(newId);
                      },
                    },
                    {
                      key: 'add_after',
                      name: i18n`Add below`,
                      icon: IconName.input,
                      onClick: () => {
                        const newId = store.createObject({ [Instance_Of]: Homepage, [Homepage_Rank]: rankedList[index].insertAfterRank() });
                        setNewLineFocus(newId);
                      },
                    },
                    {
                      key: 'delete',
                      name: i18n`Delete`,
                      icon: IconName.delete,
                      onClick: () => store.deleteObject(id),
                      danger: true,
                    },
                  ]}
                />
              </SpacingLine>
            ),
          },
        ]}
        newLineFocus={newLineFocus.current}
        newItemTitle={i18n`Add`}
        newItemIcon={IconName.add}
        onNewItem={() => {
          const newId = store.createObject({ [Instance_Of]: Homepage, [Homepage_Rank]: rankedList.insertAfterLastItemRank() });
          setNewLineFocus(newId);
        }}
      />
    </VerticalBlock>
  );
};

export default HomepageTab;
