import type { FunctionComponent } from 'react';
import { displayInstanceFieldAsText, isConceptValid } from 'yooi-modules/modules/conceptModule';
import { Concept_Name } from 'yooi-modules/modules/conceptModule/ids';
import type { TemplateConceptStoreObject } from 'yooi-modules/modules/templateModule';
import {
  TemplateConcept_Mandatory,
  TemplateConcept_MappedConcept,
  TemplateConcept_Name,
  TemplateConceptDefinition_MappedConceptDefinition,
  TemplateConceptDefinition_TemplateConcepts,
} from 'yooi-modules/modules/templateModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareString, comparing, filterNullOrUndefined, textToRichText } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import TextInputString from '../../../components/inputs/TextInputString';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import { TableSortDirection } from '../../../components/molecules/Table';
import BlockTitle from '../../../components/templates/BlockTitle';
import DataTable from '../../../components/templates/DataTable';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { formatOrUndef } from '../../../utils/stringUtils';
import { defaultOptionComparator, getChipOptions } from '../../_global/modelTypeUtils';
import type { ComparatorHandler } from '../../_global/useFilterAndSort';
import useFilterAndSort, { buildStringColumnComparatorHandler } from '../../_global/useFilterAndSort';

interface IntegrationTemplateConceptMappingBlockProps {
  templateConceptDefinitionId: string,
}

const IntegrationTemplateConceptMappingBlock: FunctionComponent<IntegrationTemplateConceptMappingBlockProps> = ({ templateConceptDefinitionId }) => {
  const store = useStore();

  const templateConceptDefinition = store.getObject(templateConceptDefinitionId);

  const { generateList, sortCriteria, doSort } = useFilterAndSort(
    `${templateConceptDefinitionId}_${TemplateConceptDefinition_TemplateConcepts}`,
    templateConceptDefinition.navigateBack<TemplateConceptStoreObject>(TemplateConceptDefinition_TemplateConcepts),
    undefined,
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case TemplateConcept_Name:
            return buildStringColumnComparatorHandler(key, direction);
          case TemplateConcept_MappedConcept:
            return {
              comparator: comparing(compareString, direction === TableSortDirection.desc),
              extractValue: (templateConcept) => (
                templateConcept[TemplateConcept_MappedConcept]
                  ? displayInstanceFieldAsText(store, templateConcept[TemplateConcept_MappedConcept], Concept_Name)
                  : undefined
              ),
            } satisfies ComparatorHandler<TemplateConceptStoreObject, string | undefined>;
          default:
            return undefined;
        }
      },
      initial: { key: TemplateConcept_Name, direction: TableSortDirection.asc },
    }
  );

  return (
    <VerticalBlock asBlockContent>
      <BlockTitle title={i18n`Instance mapping`} />
      <DataTable
        columnsDefinition={[
          {
            propertyId: TemplateConcept_Name,
            name: i18n`Name`,
            width: 20,
            sortable: true,
            cellRender: ({ [TemplateConcept_Name]: name }) => (<TextInputString value={formatOrUndef(name)} readOnly />),
          },
          {
            propertyId: TemplateConcept_MappedConcept,
            name: i18n`Mapping`,
            width: 80,
            sortable: true,
            cellRender: (templateConcept) => {
              const concept = templateConcept.navigateOrNull(TemplateConcept_MappedConcept);
              let error;
              if (!concept && templateConcept[TemplateConcept_Mandatory]) {
                error = i18n`Instance is required to be mapped`;
              } else if (concept && !isInstanceOf(concept, templateConceptDefinition[TemplateConceptDefinition_MappedConceptDefinition] as string)) {
                error = i18n`Instance is not of the mapped type`;
              }

              return (
                <SearchAndSelect
                  computeOptions={() => templateConceptDefinition
                    .navigate(TemplateConceptDefinition_MappedConceptDefinition)
                    .navigateBack(Class_Instances)
                    .filter(({ id }) => isConceptValid(store, id))
                    .map(({ id: instanceId }) => getChipOptions(store, instanceId))
                    .filter(filterNullOrUndefined)
                    .sort(defaultOptionComparator)}
                  selectedOption={concept ? getChipOptions(store, concept.id) : undefined}
                  onSelect={(newSelection) => {
                    store.updateObject(templateConcept.id, { [TemplateConcept_MappedConcept]: newSelection?.id ?? null });
                  }}
                  statusIcon={error ? { icon: IconName.dangerous, color: IconColorVariant.error, message: error } : undefined}
                  getInlineCreation={() => ({
                    type: 'inline',
                    onCreate: (title) => {
                      const conceptId = store.createObject({
                        [Instance_Of]: templateConceptDefinition[TemplateConceptDefinition_MappedConceptDefinition],
                        [Concept_Name]: textToRichText(title),
                      });
                      store.updateObject(templateConcept.id, { [TemplateConcept_MappedConcept]: conceptId });
                      return conceptId;
                    },
                  })}
                  clearable
                />
              );
            },
          },
        ]}
        list={generateList().list}
        sortCriteria={sortCriteria}
        doSort={doSort}
      />
    </VerticalBlock>
  );
};

export default IntegrationTemplateConceptMappingBlock;
