import type { FunctionComponent, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { joinObjects } from 'yooi-utils';
import BaseLayout from '../../../components/templates/BaseLayout';
import Header from '../../../components/templates/Header';
import HeaderTabs from '../../../components/templates/HeaderTabs';
import i18n from '../../../utils/i18n';
import useNavigation, { NavigationElementContainer } from '../../../utils/useNavigation';
import HeaderStatic from '../../_global/HeaderStatic';
import type { NavigationFilter } from '../../_global/navigationUtils';
import TopBar from '../../_global/topBar/TopBar';
import PlatformCapabilitiesTab from './PlatformCapabilitiesTab';
import GroupsTab from './GroupsTab';
import UsersTab from './UsersTab';

const AuthorizationPage: FunctionComponent = () => {
  const location = useLocation();
  const navigation = useNavigation<NavigationFilter>();

  const tabs: { key: string, hash: string, name: string, render: () => ReactElement | null }[] = [
    {
      key: 'users',
      name: i18n`Users`,
      hash: '#users',
      render: () => (<UsersTab />),
    },
    {
      key: 'groups',
      name: i18n`Groups`,
      hash: '#groups',
      render: () => (<GroupsTab />),
    },
    {
      key: 'platform-capabilities',
      name: i18n`Platform capabilities`,
      hash: '#platform-capabilities',
      render: () => (<PlatformCapabilitiesTab />),
    },
  ];

  const tabIndex = tabs.findIndex((tab) => tab.hash === location.hash);
  const selectedTabIndex = tabIndex >= 0 ? tabIndex : 0;

  return (
    <NavigationElementContainer element={{ key: 'authorization' }}>
      <BaseLayout
        topBar={(<TopBar />)}
        header={(
          <Header
            firstLine={(<HeaderStatic text={i18n`Authorization`} />)}
            tabsLine={(
              <HeaderTabs
                selectedTabIndex={selectedTabIndex}
                tabs={tabs}
                onSelectedIndexChanged={(index) => {
                  navigation.replace('authorization', joinObjects(location, { hash: tabs[index].hash }));
                }}
              />
            )}
          />
        )}
        content={(tabs[selectedTabIndex].render())}
      />
    </NavigationElementContainer>
  );
};

export default AuthorizationPage;
