import type { KeyboardEventHandler, ReactElement } from 'react';
import base from '../../../theme/base';
import { buildPadding, Spacing, spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { formatOrUndef } from '../../../utils/stringUtils';
import type { NavigationPayload } from '../../../utils/useNavigation';
import useNavigation from '../../../utils/useNavigation';
import useTheme from '../../../utils/useTheme';
import Typo, { TypoVariant } from '../../atoms/Typo';
import Loading from '../../molecules/Loading';
import TableBody from '../../molecules/TableBody';
import type { CellHighlight, TableCellAlign } from '../../molecules/TableCell';
import TableCell from '../../molecules/TableCell';
import TableInnerCellContainer, { TableInnerCellContainerVariants } from '../../molecules/TableInnerCellContainer';
import TableLine from '../../molecules/TableLine';

const useStyles = makeStyles({
  groupLabel: {
    height: '2.8rem',
    borderRadius: base.borderRadius.medium,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
  },
}, 'dataTableBodyRenderer');

export interface ItemEntry<Item extends Data> {
  key: string,
  type: 'item',
  item: Item,
  color: string | undefined,
}

export interface GroupEntry {
  key: string,
  type: 'group',
  label: string | undefined,
  color: string | undefined,
}

export interface Data {}

export interface ColumnDefinition<Item extends Data> {
  key?: string,
  propertyId: string,
  cellRender: (item: Item, focusAndScrollOnMount: boolean, index: number) => ReactElement | null,
  noSeparator?: boolean,
  openButton?: (item: Item) => boolean,
  action?: boolean,
  scrollOnMount?: boolean,
  background?: string,
  align?: TableCellAlign,
  focusable?: boolean,
}

interface DataTableBodyRendererProps<Item extends Data> {
  list: (ItemEntry<Item> | GroupEntry)[],
  columnsDefinition: ColumnDefinition<Item>[],
  newLineFocus?: { id?: string, reset: () => void },
  newColumnFocus?: { id?: string, reset: () => void },
  multiplayerRenderer: ((item: Item, columnIds: string[]) => ReactElement) | undefined,
  inlineCreation: { render: ReactElement | null, highlight?: (propertyId: string) => CellHighlight | undefined } | undefined,
  lineContext: ((item: Item) => string[]) | undefined,
  loading: boolean | undefined,
  getNavigationPayload?: (item: Item) => NavigationPayload | undefined,
  onNavigate?: (item: Item) => void,
  getHighlight?: (item: Item, propertyId: string) => CellHighlight | undefined,
}

const compareHighlight = (h1: CellHighlight | undefined, h2: CellHighlight | undefined): boolean => h1?.message === h2?.message && h1?.color === h2?.color;

const getCellColors = (
  cellHighlight: CellHighlight | undefined,
  previousColumnCellHighlighted: CellHighlight | undefined,
  nextColumnCellHighlighted: CellHighlight | undefined,
  previousLineCellHighlighted: CellHighlight | undefined,
  nextLineCellHighlighted: CellHighlight | undefined
): { left: string | undefined, right: string | undefined, top: string | undefined, bottom: string | undefined } => {
  if (cellHighlight) {
    return {
      left: !previousColumnCellHighlighted || !compareHighlight(previousColumnCellHighlighted, cellHighlight) ? cellHighlight.color : undefined,
      right: !nextColumnCellHighlighted || !compareHighlight(nextColumnCellHighlighted, cellHighlight) ? cellHighlight.color : undefined,
      top: !previousLineCellHighlighted || !compareHighlight(previousLineCellHighlighted, cellHighlight) ? cellHighlight.color : undefined,
      bottom: !nextLineCellHighlighted || !compareHighlight(nextLineCellHighlighted, cellHighlight) ? cellHighlight.color : undefined,
    };
  } else {
    return {
      left: previousColumnCellHighlighted ? previousColumnCellHighlighted.color : undefined,
      right: nextColumnCellHighlighted ? nextColumnCellHighlighted.color : undefined,
      top: previousLineCellHighlighted ? previousLineCellHighlighted.color : undefined,
      bottom: nextLineCellHighlighted ? nextLineCellHighlighted.color : undefined,
    };
  }
};
const DataTableBodyRenderer = <Item extends Data = Data>({
  list,
  columnsDefinition,
  newLineFocus,
  newColumnFocus,
  getNavigationPayload,
  onNavigate,
  multiplayerRenderer,
  inlineCreation,
  lineContext,
  loading = false,
  getHighlight,
}: DataTableBodyRendererProps<Item>): ReactElement | null => {
  const theme = useTheme();
  const classes = useStyles();
  const navigation = useNavigation();

  const focusedElementId = newLineFocus?.id;

  return (
    <TableBody>
      {(list.map((line, index, lineList) => {
        const highlightContext = [line.key];
        const previousLine = index > 0 ? lineList.at(index - 1) : undefined;
        const nextLine = lineList.at(index + 1);

        const onKeyDown: KeyboardEventHandler<HTMLTableRowElement> | undefined = getNavigationPayload ? (e) => {
          if (e.key === 'Enter' && e.ctrlKey && line.type === 'item') {
            const payload = getNavigationPayload(line.item);
            if (payload) {
              onNavigate?.(line.item);
              navigation.pushPayload(payload);
            }
          }
        } : undefined;
        if (line.type === 'group') {
          const colSpan = multiplayerRenderer ? columnsDefinition.length - 2 : columnsDefinition.length;
          return (
            <TableLine key={line.key}>
              {multiplayerRenderer && (
                <>
                  <TableCell noSeparator />
                  <TableCell noSeparator />
                </>
              )}
              <TableCell colSpan={colSpan} noBorder>
                <div className={classes.groupLabel} style={{ backgroundColor: line?.color }}>
                  <Typo
                    fullWidth
                    color={theme.color.text.white}
                    maxLine={1}
                    variant={TypoVariant.blockSecondaryTitle}
                  >
                    {formatOrUndef(line.label)}
                  </Typo>
                </div>
              </TableCell>
              <TableCell noSeparator />
            </TableLine>
          );
        } else {
          return (
            <TableLine
              key={`${line.key}_line`}
              context={lineContext ? lineContext(line.item) : undefined}
              highlightContext={highlightContext}
              onKeyDown={onKeyDown}
            >
              {columnsDefinition.map((column, columnIndex, columnList) => {
                const firstFocusableColumnDefinition = columnsDefinition.find((columnDefinition) => columnDefinition.focusable);
                let focusAndScrollOnMount = false;
                if (focusedElementId === line.key && firstFocusableColumnDefinition?.propertyId === column.propertyId) {
                  focusAndScrollOnMount = true;
                  newLineFocus?.reset?.();
                }
                if (newColumnFocus && newColumnFocus.id === column.key && column.scrollOnMount) {
                  focusAndScrollOnMount = true;
                  newLineFocus?.reset?.();
                }

                const cellHighlight = getHighlight?.(line.item, column.propertyId);

                const previousColumn = columnList.at(columnIndex - 1);
                const previousColumnHighlight = previousColumn && getHighlight?.(line.item, previousColumn.propertyId);

                const nextColumn = columnList.at(columnIndex + 1);
                const nextColumnHighlight = nextColumn && getHighlight?.(line.item, nextColumn.propertyId);

                const previousLineCellHighlight = previousLine?.type === 'item' ? getHighlight?.(previousLine.item, column.propertyId) : undefined;
                let nextLineCellHighlight;
                if (nextLine?.type === 'item') {
                  nextLineCellHighlight = getHighlight?.(nextLine.item, column.propertyId);
                } else if (!nextLine && inlineCreation?.highlight) {
                  nextLineCellHighlight = inlineCreation.highlight(column.propertyId);
                }

                const cellHighlightMessage = cellHighlight
                && !compareHighlight(nextColumnHighlight, cellHighlight)
                && !compareHighlight(nextLineCellHighlight, cellHighlight)
                  ? cellHighlight
                  : undefined;

                return (
                  <TableCell
                    key={column.key ?? column.propertyId}
                    action={column.action}
                    align={column.align}
                    noSeparator={column.noSeparator}
                    navigationPayload={line.key && column.openButton && column.openButton(line.item) && getNavigationPayload ? getNavigationPayload(line.item) : undefined}
                    afterOpen={() => onNavigate?.(line.item)}
                    background={column.background ?? line.color}
                    scrollOnMount={(focusAndScrollOnMount && column.scrollOnMount) || (!!focusedElementId && columnIndex === (columnsDefinition.length - 1))}
                    borderColor={getCellColors(cellHighlight, previousColumnHighlight, nextColumnHighlight, previousLineCellHighlight, nextLineCellHighlight)}
                    message={cellHighlightMessage}
                  >
                    {column.cellRender ? column.cellRender(line.item, focusAndScrollOnMount, index) : (
                      <TableInnerCellContainer variant={TableInnerCellContainerVariants.centeredFlex} padding={buildPadding({ x: Spacing.splus })}>
                        <Typo noWrap>{i18n`Content not supported yet`}</Typo>
                      </TableInnerCellContainer>
                    )}
                  </TableCell>
                );
              })}
            </TableLine>
          );
        }
      }))}
      {(!inlineCreation || (inlineCreation && !inlineCreation.render)) && list.length === 0 && !loading && (
        <TableLine>
          {multiplayerRenderer && <TableCell noSeparator />}
          {multiplayerRenderer && <TableCell noSeparator />}
          <TableCell colSpan={multiplayerRenderer ? columnsDefinition.length - 2 : columnsDefinition.length}>
            <TableInnerCellContainer variant={TableInnerCellContainerVariants.centeredFlex} padding={buildPadding({ x: Spacing.s })}>
              <Typo variant={TypoVariant.blockInlineTitle} color={theme.color.text.secondary}>{i18n`No content to display`}</Typo>
            </TableInnerCellContainer>
          </TableCell>
          {multiplayerRenderer && <TableCell noSeparator />}
        </TableLine>
      )}
      {loading && (
        <TableLine>
          {multiplayerRenderer && <TableCell noSeparator />}
          {multiplayerRenderer && <TableCell noSeparator />}
          <TableCell colSpan={columnsDefinition.length}>
            <Loading />
          </TableCell>
        </TableLine>
      )}
      {(inlineCreation) && inlineCreation.render}
    </TableBody>
  );
};

export default DataTableBodyRenderer;
