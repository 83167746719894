import type { ReactElement } from 'react';
import { useState } from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryContainer, VictoryGroup, VictoryLine, VictoryStack, VictoryTooltip } from 'victory';
import { isNumber, joinObjects } from 'yooi-utils';
import { hexColorWithAlpha } from '../../../theme/colorUtils';
import { getFontStyle } from '../../../utils/fontUtils';
import { remToPx, SCROLLBAR_WIDTH_IN_REM } from '../../../utils/sizeUtils';
import useTheme from '../../../utils/useTheme';
import ChartLegend, { CHART_LEGEND_LINE_HEIGHT } from '../ChartLegend';
import type { ColorStepsValue, Labels } from '../ChartTypes';
import { chartAxisStyle } from '../internal/chartStylesUtils';
import ChartTooltipForVictory from '../internal/ChartTooltipForVictory';
import DependentAxis from '../internal/DependentAxis';
import FlyoutWrapper from '../internal/FlyoutWrapper';
import NoLabel from '../NoLabel';
import BarChartXAxis from './BarChartXAxis';

interface StructuralBarValue {
  y: number,
  xLabel?: string,
  value?: number,
  color: string,
  serie: {
    label: string,
  },
  key: string,
}

interface BarValueProcessed extends StructuralBarValue {
  x: string,
}

interface StructuralBarChartProps<T = StructuralBarValue> {
  data: T[][][],
  labels: Labels[],
  minValue: ColorStepsValue,
  maxValue: ColorStepsValue,
  steps: ColorStepsValue[],
  width: number,
  height: number,
  renderTooltip: (datum: T, totalByX: number | undefined) => ReactElement | null,
  yDomain: [number, number],
  groupBy?: boolean,
}

const generateMinMaxLines = (
  minValue: ColorStepsValue,
  maxValue: ColorStepsValue,
  steps: ColorStepsValue[]
): { value: number, color: string | undefined }[] | [] => {
  const minMaxLines = [];

  if (isNumber(minValue.value)) {
    minMaxLines.push({ value: minValue.value, color: minValue.color });
  }
  if (steps && steps?.length > 0) {
    steps.forEach((step) => {
      if (isNumber(step.value)) {
        minMaxLines.push({ value: step.value, color: step.color });
      }
    });
  }
  if (isNumber(maxValue.value)) {
    minMaxLines.push({ value: maxValue.value, color: maxValue.color });
  }
  return minMaxLines;
};

const StructuralBarChart = <T extends StructuralBarValue = StructuralBarValue>({
  minValue,
  maxValue,
  steps,
  data,
  labels: generatedLabels,
  width,
  height,
  renderTooltip,
  groupBy = false,
  yDomain,
}: StructuralBarChartProps<T>): ReactElement | null => {
  const theme = useTheme();

  const chartLeftPadding = remToPx(5);
  const charRightPadding = remToPx(5 - SCROLLBAR_WIDTH_IN_REM);
  const actualWidth = Math.max(0, width);

  const axisXHeight = 5.5 - SCROLLBAR_WIDTH_IN_REM;
  const firstLegendLineHeightInRem = generatedLabels.length > 0 ? CHART_LEGEND_LINE_HEIGHT : 0;
  const svgHeight = height - remToPx(firstLegendLineHeightInRem);
  const xAxisY = svgHeight - remToPx(axisXHeight) + remToPx(0.1);

  const [displayKeyList, setDisplayKeyList] = useState<string[]>([]);

  const getOpacity = (key: string) => (displayKeyList.length === 0 || displayKeyList.includes(key) ? 1 : 0.2);
  const groupedData: BarValueProcessed[][][] = (data ?? [])
    .map((stack) => stack
      .map((bar) => bar
        .map((barValue, index): BarValueProcessed => joinObjects(barValue as StructuralBarValue, { x: `${index + 1}` }))));
  const xMap = new Map();
  groupedData.forEach((barStack) => barStack.forEach((bar) => bar.forEach((barValue) => {
    if (!xMap.get(barValue.x)) {
      xMap.set(barValue.x, barValue.xLabel);
    }
  })));
  const xPoints = [...xMap];
  const groupWidth = Math.max(0, Math.round((actualWidth - chartLeftPadding - charRightPadding) / xPoints.length));
  const renderBar = (
    values: StructuralBarValue[],
    getTotalByX: ((x: string) => number) | undefined,
    isLastBarSegment: (y: number, group?: number, stack?: number) => boolean
  ) => (
    <VictoryBar
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      key={({ datum }: { datum: BarValue }) => datum.key}
      labels={({ datum }) => datum.serie.label}
      cornerRadius={{
        // eslint-disable-next-line no-underscore-dangle
        top: ({ datum }) => (isLastBarSegment(datum._y, datum._group, datum._stack) ? 4 : 0),
      }}
      labelComponent={(
        <VictoryTooltip
          pointerLength={0}
          constrainToVisibleArea
          flyoutComponent={(
            <FlyoutWrapper>
              {({ x, y, datum }) => (
                <ChartTooltipForVictory
                  chartWidth={actualWidth}
                  chartHeight={height}
                  x={x}
                  y={y}
                  renderTooltip={() => renderTooltip(datum as T, getTotalByX?.((datum as BarValueProcessed).x))}
                />
              )}
            </FlyoutWrapper>
          )}
          // We explicitly wants to avoid the label component
          labelComponent={<NoLabel />}
          style={joinObjects(
            getFontStyle(theme.font.small),
            // lineHeight not supported by VictoryTooltip
            { lineHeight: undefined }
          )}
        />
      )}
      data={values}
      style={{
        data: {
          fill: ({ datum: { color } }) => color,
          opacity: ({ datum: { key } }) => getOpacity(key),
        },
      }}
    />
  );
  const getTotalByX = (barStack: BarValueProcessed[][]) => (x: string) => barStack.reduce((acc, bar) => acc + (bar.find(({ x: stackedX }) => stackedX === x)?.y ?? 0), 0);
  const getStackKey = (barStack: BarValueProcessed[][]): string => {
    const firstBar = barStack[0]?.[0];
    return firstBar ? `${firstBar.x}|${firstBar.serie.label}` : '-';
  };

  const labels = generatedLabels.map(({ label, color, key }) => ({
    name: label,
    symbol: { fill: hexColorWithAlpha(color, getOpacity(key)) },
    displayStroke: !color,
    key,
  }));

  const maxLabels = 20;
  const truncatedLabels = labels.length > maxLabels ? [
    ...labels.slice(0, maxLabels),
    {
      name: `${labels.length - maxLabels} other labels ...`,
      symbol: { fill: theme.color.text.secondary },
      displayStroke: false,
      key: 'others',
    },
  ] : labels;

  const minMaxLines = generateMinMaxLines(minValue, maxValue, steps);

  const barRoundedCorners: Record<number, number> = {};
  const isLastBarSegment = (y: number, group = 0, stack = 0) => {
    if (y > 0 && !(group in barRoundedCorners)) {
      barRoundedCorners[group] = stack;
    }
    return (group in barRoundedCorners && stack === barRoundedCorners[group]);
  };

  return (svgHeight > 0 && groupedData && groupedData.length > 0) ? (
    <>
      <VictoryChart
        // using height as key to prevent flickering when resizing (Victory renders bars AFTER svg)
        key={svgHeight}
        height={svgHeight}
        width={actualWidth}
        minDomain={{ y: yDomain?.[0] }}
        maxDomain={{ y: yDomain?.[1] }}
        domainPadding={{ x: Math.round(groupWidth / 2) }}
        padding={{ top: remToPx(2.5), bottom: remToPx(axisXHeight), left: chartLeftPadding, right: charRightPadding }}
        containerComponent={(
          <VictoryContainer
            preserveAspectRatio="xMinYMin meet"
            responsive={false}
          />
        )}
      >

        <VictoryAxis
          // prevent x axis value when there is no x value
          tickFormat={groupBy ? (x: unknown) => xMap.get(x) : () => ''}
          style={chartAxisStyle(theme)}
          tickLabelComponent={(
            <BarChartXAxis
              y={xAxisY}
              axisHeightInRem={axisXHeight}
              xAxisLabelAngle={35}
            />
          )}
        />
        <DependentAxis />

        <VictoryGroup offset={(groupWidth) / groupedData.length}>
          {groupedData.map((barStack) => (
            <VictoryStack key={getStackKey(barStack)}>
              {barStack.map((barValues) => renderBar(
                barValues,
                getTotalByX(barStack),
                isLastBarSegment
              ))}
            </VictoryStack>
          ))}
        </VictoryGroup>

        {minMaxLines.map(({ color, value: lineValue }, index) => {
          const key = index;
          return (
            <VictoryLine
              key={key}
              style={{
                data: { stroke: color },
              }}
              y={() => lineValue}
            />
          );
        })}
      </VictoryChart>
      {labels.length > 0 && (
        <ChartLegend
          labels={truncatedLabels}
          onLabelClick={(selectedKey) => {
            setDisplayKeyList((current) => {
              if (current.includes(selectedKey)) {
                return current.filter((key) => key !== selectedKey);
              } else {
                return current.length === groupedData.flatMap((barStack) => barStack.map((bar) => bar[0].key)).length - 1 ? [] : [...current, selectedKey];
              }
            });
          }}
        />
      )}
    </>
  ) : null;
};

export default StructuralBarChart;
