import { pathToRegexp } from 'path-to-regexp';
import { equals } from 'ramda';
import type { FunctionComponent } from 'react';
import { createContext, useEffect, useMemo, useRef } from 'react';
import { matchPath, Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  createValuePathResolver,
  FILTER_PARAMETER_LOGGED_USER,
  getConceptDefinitionUrl,
  getConceptUrl,
  getFilterFunction,
  isDimensionStep,
  isSingleValueResolution,
} from 'yooi-modules/modules/conceptModule';
import { NavigationContext_Context } from 'yooi-modules/modules/navigationModule/ids';
import type { LeftBarItemStoreObject, PlatformConfigurationStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import {
  CurrentPlatformConfiguration,
  LeftBarItem,
  LeftBarItem_DisplayConditions,
  LeftBarItem_Path,
  LeftBarItem_Rank,
  PlatformConfiguration_Name,
} from 'yooi-modules/modules/platformConfigurationModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
// eslint-disable-next-line yooi/no-restricted-dependency
import { isStoreObject } from 'yooi-store';
import { compareProperty, compareRank, joinObjects, newError } from 'yooi-utils';
import useAuth from '../store/useAuth';
import type { FrontObjectStore } from '../store/useStore';
import useStore from '../store/useStore';
import useUpdateActivity from '../store/useUpdateActivity';
import { HomepageNotFoundError } from '../utils/HomepageNotFoundError';
import i18n from '../utils/i18n';
import { NotFoundError } from '../utils/NotFoundError';
import { notifyError } from '../utils/notify';
import type { LayoutRenderer, LayoutSwitch, Route, RouteRenderer, Routes, Switch } from '../utils/routerTypes';
import type { NavigationLocation } from '../utils/useNavigation';
import { getCurrentNavigationId, getNavigationElement, getNavigationElements, isValidLeftBarItem } from './_global/navigationUtils';
import { getHomepageConcept } from './utils/homepageUtils';

interface RouterContext {
  params: Record<string, string | undefined>,
  lastParam: string | null,
}

const CustomRouterContext = createContext<RouterContext>({ params: {}, lastParam: null });

const isRedirectRoute = (routeContent: Route | undefined): routeContent is string => typeof routeContent === 'string';

const isComponentRoute = (routeContent: Route | undefined): routeContent is RouteRenderer => typeof routeContent === 'function';

const isFinalRoute = (routeContent: Route | undefined): routeContent is string | RouteRenderer => (
  isRedirectRoute(routeContent) || isComponentRoute(routeContent)
);

const isASwitchRoute = (routeContent: Route | undefined): routeContent is Switch => typeof routeContent === 'object' && routeContent.switch !== undefined;

const isALayoutSwitchRoute = (routeContent: Route | undefined): routeContent is LayoutSwitch => typeof routeContent === 'object' && routeContent.switch !== undefined && (routeContent as LayoutSwitch).layout !== undefined;

const recursiveFlattenAndCheck = (routeMap: Record<string, RouteRenderer>, parentPath: string, parentLayout: LayoutRenderer, routes: Routes) => {
  Object.entries(routes).forEach(([relativePath, routeContent]) => {
    if (!relativePath.startsWith('/') || relativePath.startsWith('//')) {
      throw newError('Route definition path must start with a /', { path: relativePath });
    }
    const path = relativePath === '/' ? parentPath : `${parentPath}${relativePath}`;
    if (isFinalRoute(routeContent)) {
      if (routeMap[path]) {
        throw newError('Routes are not valid, more than one route for path', { path });
      } else {
        let result: RouteRenderer = () => null;
        if (isRedirectRoute(routeContent)) {
          result = () => (
            <Navigate to={routeContent.startsWith('/') ? routeContent : `${path}/${routeContent}`} replace />
          );
        } else if (isComponentRoute(routeContent)) {
          result = (parameters) => routeContent(parameters);
        }
        // eslint-disable-next-line no-param-reassign
        routeMap[path] = (parameters) => parentLayout({ children: result(parameters), parameters });
      }
    } else if (isALayoutSwitchRoute(routeContent)) {
      const { layout } = routeContent;
      if (typeof layout !== 'function') {
        throw newError('Layout can only be a function', { path });
      }
      recursiveFlattenAndCheck(
        routeMap,
        path,
        ({ children, parameters }) => parentLayout({ children: layout({ children, parameters }), parameters }),
        routeContent.switch
      );
    } else if (isASwitchRoute(routeContent)) {
      recursiveFlattenAndCheck(
        routeMap,
        path,
        parentLayout,
        routeContent.switch
      );
    } else if (!routeContent) {
      // Ignore entry that are defined with an undefined value
    } else {
      throw newError('Route should be: A string | A function | Have a switch | undefined. Route does not respect this rule', { path });
    }
  });
};

const flattenAndCheckRoutes = (routes: Routes) => {
  const routesMap: Record<string, RouteRenderer> = {};
  recursiveFlattenAndCheck(routesMap, '', ({ children }) => children, routes);
  return routesMap;
};

const getPathFromLeftBarItem = (store: FrontObjectStore, leftBarItem: LeftBarItemStoreObject | undefined) => {
  if (leftBarItem === undefined || leftBarItem[LeftBarItem_Path] === undefined) {
    return undefined;
  } else if (leftBarItem[LeftBarItem_Path].length === 1 && isDimensionStep(leftBarItem[LeftBarItem_Path][0])) {
    const { conceptDefinitionId } = leftBarItem[LeftBarItem_Path][0];
    return store.getObjectOrNull(conceptDefinitionId) ? getConceptDefinitionUrl(conceptDefinitionId) : undefined;
  } else {
    const pathResolver = createValuePathResolver(store, { [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: store.getLoggedUserId() } });
    const valueResolution = pathResolver.resolvePathValue(leftBarItem[LeftBarItem_Path]);
    return isSingleValueResolution(valueResolution) && isStoreObject(valueResolution.value) ? getConceptUrl(store, valueResolution.value.id) : undefined;
  }
};

interface NavigationContextState {
  locationState?: NavigationLocation['state'],
  sessionStorageState?: Record<string, string>,
}

interface AppRouterProps {
  routes: Routes,
}

const AppRouter: FunctionComponent<AppRouterProps> = ({ routes }) => {
  const store = useStore();
  const { loggedUserId } = useAuth();
  const { onView } = useUpdateActivity();

  const lastMatchParams = useRef<RouterContext>({ params: {}, lastParam: null });

  const platformConfiguration = store.getObject<PlatformConfigurationStoreObject>(CurrentPlatformConfiguration);

  const location = useLocation() as NavigationLocation;
  const navigate = useNavigate();
  const [urlParams] = useSearchParams();

  if (urlParams.has('shared')) {
    const instance = store.getObjectOrNull(urlParams.get('shared') as string);
    if (!instance) {
      notifyError(i18n`Your shared link is not correct or doesn't exists anymore.`);
    } else {
      const { locationState, sessionStorageState } = instance[NavigationContext_Context] as NavigationContextState;
      if (!locationState || !sessionStorageState) {
        notifyError(i18n`Your shared link is not correct or doesn't exists anymore.`);
      } else {
        location.state = locationState;
        Object.entries(sessionStorageState).forEach(([key, value]) => sessionStorage.setItem(key, value));
      }
    }
  }
  useEffect(() => {
    if (urlParams.has('shared')) {
      const updated = new URLSearchParams(urlParams);
      updated.delete('shared');
      navigate({ hash: location.hash, search: updated.toString() }, { state: location.state, replace: true });
    }
  }, [location.hash, location.state, navigate, urlParams]);

  const computedNavigationElements = getNavigationElements(store, location);

  let objectId;
  let currentNavigationElement;
  if (computedNavigationElements.length === 0) {
    objectId = getCurrentNavigationId(store, location.pathname, loggedUserId);
    if (objectId && store.getObjectOrNull(objectId)) {
      currentNavigationElement = getNavigationElement(store, { key: objectId });
    }
  } else {
    // browser tab context
    const navigationElement = computedNavigationElements[computedNavigationElements.length - 1];
    currentNavigationElement = getNavigationElement(store, navigationElement);
  }

  const platformName = platformConfiguration[PlatformConfiguration_Name] !== undefined && platformConfiguration[PlatformConfiguration_Name] !== ''
    ? platformConfiguration[PlatformConfiguration_Name] : 'YOOI';
  document.title = currentNavigationElement ? `${platformName} | ${currentNavigationElement.name}` : platformName;

  const homepageConcept = getHomepageConcept(store, loggedUserId);
  const hasHomepageBookmark = homepageConcept !== undefined;

  const homeRoutePathAlias = getPathFromLeftBarItem(
    store,
    store.getObject(LeftBarItem)
      .navigateBack<LeftBarItemStoreObject>(Class_Instances)
      .filter((leftBarItem) => isValidLeftBarItem(store, leftBarItem))
      .filter((leftBarItem) => {
        const filterFunction = getFilterFunction(store, leftBarItem[LeftBarItem_DisplayConditions]);
        return filterFunction === undefined || filterFunction({ [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: store.getLoggedUserId() } });
      })
      .sort(compareProperty(LeftBarItem_Rank, compareRank))
      .at(0)
  );

  let pathToMatch: string = location.pathname;
  if (hasHomepageBookmark && pathToMatch === '/') {
    pathToMatch = getConceptUrl(store, homepageConcept.id) ?? pathToMatch;
  }

  const flatRoutes = useMemo(() => {
    let homeRoute;
    if (hasHomepageBookmark) {
      homeRoute = () => {
        throw new HomepageNotFoundError();
      };
    } else if (homeRoutePathAlias) {
      homeRoute = homeRoutePathAlias;
    } else {
      homeRoute = '/settings';
    }
    return flattenAndCheckRoutes(joinObjects(routes, { '/': homeRoute }));
  }, [routes, hasHomepageBookmark, homeRoutePathAlias]);

  const matchingRoutes = useMemo(() => {
    if (pathToMatch.startsWith('/admin/') || pathToMatch === '/admin') {
      // Backward compatibility after /admin renaming
      return [
        {
          path: pathToMatch,
          match: matchPath(pathToMatch, pathToMatch),
          result: () => (<Navigate to={`/settings${pathToMatch.substring(6)}`} replace />),
        },
      ];
    } else {
      return Object.entries(flatRoutes).map(([path, result]) => ({
        path,
        match: matchPath(path, pathToMatch),
        result,
      })).filter((res) => !!res.match);
    }
  }, [flatRoutes, pathToMatch]);

  if (matchingRoutes.length > 1) {
    lastMatchParams.current = { params: {}, lastParam: null };
    onView(null);
    throw newError('The current route matches more than one path', { matchedPaths: matchingRoutes.map(({ path }) => path) });
  } else if (matchingRoutes.length === 1) {
    const { path, match, result } = matchingRoutes[0];
    let matchParams: RouterContext;
    if (match) {
      // Use the same lib as react router to get the last param key
      const { keys } = pathToRegexp(path, {});
      matchParams = {
        params: match.params,
        lastParam: keys.length > 0 ? match.params[keys[keys.length - 1].name] ?? null : null,
      };
    } else {
      matchParams = { params: {}, lastParam: null };
    }

    // Do not change the object reference if the content didn't change
    if (!equals(lastMatchParams.current, matchParams)) {
      lastMatchParams.current = matchParams;
      onView(lastMatchParams.current.lastParam);
    }

    if (!(location.state?.navigationState || location.state?.navigationState?.length === 0) && !objectId) {
      return null;
    }
    return (
      <CustomRouterContext.Provider value={lastMatchParams.current}>
        {result(match?.params as Record<string, string>)}
      </CustomRouterContext.Provider>
    );
  }

  lastMatchParams.current = { params: {}, lastParam: null };
  onView(null);
  throw new NotFoundError();
};

export default AppRouter;
