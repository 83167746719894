import composeReactRefs from '@seznam/compose-react-refs';
import type { FunctionComponent, MouseEventHandler } from 'react';
import { useRef, useState } from 'react';
import type { Offset } from 'react-overlays/usePopper';
import i18n from '../../utils/i18n';
import useHideOnDisappearRef from '../../utils/useHideOnDisappearRef';
import type { SizeVariant } from '../../utils/useSizeContext';
import Button, { ButtonVariant, isButtonVariant } from '../atoms/Button';
import { IconName } from '../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants, isIconOnlyButton } from '../atoms/IconOnlyButton';
import type { MenuItem } from './Menu';
import Menu from './Menu';
import type { OverlayPlacement } from './Overlay';

interface OverflowMenuProps {
  menuItems: MenuItem[],
  onOpen?: () => void,
  onClose?: () => void,
  iconName?: IconName,
  tooltip?: string,
  disabled?: boolean,
  placement?: OverlayPlacement,
  offset?: Offset,
  sizeVariant?: SizeVariant,
  title?: string,
  buttonVariant?: ButtonVariant | IconOnlyButtonVariants,
}

const OverflowMenu: FunctionComponent<OverflowMenuProps> = ({
  menuItems,
  onOpen,
  onClose,
  iconName,
  tooltip,
  disabled = false,
  placement,
  offset,
  sizeVariant,
  title,
  buttonVariant = title ? ButtonVariant.secondary : IconOnlyButtonVariants.secondary,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { monitorRef, hideRef } = useHideOnDisappearRef<HTMLDivElement>(showMenu);

  const handleOpenMenu: MouseEventHandler<HTMLButtonElement> = () => {
    onOpen?.();
    setShowMenu(true);
  };
  if (menuItems.length === 0 && !disabled) {
    return null;
  } else {
    return (
      <>
        {title
          ? (
            <Button
              ref={composeReactRefs<HTMLButtonElement>(buttonRef, monitorRef)}
              title={title}
              tooltip={tooltip}
              iconName={iconName || IconName.more_vert}
              variant={(buttonVariant && isButtonVariant(buttonVariant)) ? buttonVariant : ButtonVariant.secondary}
              onClick={handleOpenMenu}
              disabled={disabled || menuItems.every(({ hidden }) => hidden)}
              sizeVariant={sizeVariant}
            />
          )
          : (
            <IconOnlyButton
              ref={composeReactRefs<HTMLButtonElement>(buttonRef, monitorRef)}
              iconName={iconName || IconName.more_vert}
              tooltip={tooltip || i18n`Show all actions`}
              variant={(buttonVariant && isIconOnlyButton(buttonVariant)) ? buttonVariant : IconOnlyButtonVariants.secondary}
              onClick={handleOpenMenu}
              disabled={disabled || menuItems.every(({ hidden }) => hidden)}
              sizeVariant={sizeVariant}
            />
          )}
        {showMenu ? (
          <Menu
            ref={hideRef}
            items={menuItems}
            anchorRef={buttonRef}
            onClose={() => {
              onClose?.();
              setShowMenu(false);
            }}
            onBackdropClick={(event) => {
              if (event.target instanceof Node && buttonRef.current?.contains(event.target)) {
                event.stopPropagation();
              }
              onClose?.();
              setShowMenu(false);
            }}
            placement={placement}
            offset={offset}
          />
        ) : null}
      </>
    );
  }
};

export default OverflowMenu;
