import type { OpenAPIV3 } from 'openapi-types';
import type { FunctionComponent } from 'react';
import { useCallback, useEffect } from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import Loading from '../../components/molecules/Loading';
import { useFetchJSON } from '../../utils/useFetchJSON';

const { origin } = window.location;

const DisableTryItOutOnSSE = () => ({
  statePlugins: {
    spec: {
      wrapSelectors: {
        allowTryItOutFor: () => (_: unknown, path: string, method: string) => path !== '/api/store/events/object' || method !== 'get',
      },
    },
  },
});

interface SwaggerProps {
  pathname: string,
  openApiPath: string,
}

const Swagger: FunctionComponent<SwaggerProps> = ({ pathname, openApiPath }) => {
  useEffect(() => {
    const onPopState = () => {
      if (!window.location.pathname.startsWith(pathname)) {
        window.location.reload();
      }
    };
    window.addEventListener('popstate', onPopState);

    return () => window.removeEventListener('popstate', onPopState);
  });

  const postProcess = useCallback(({ status, response }: { status: 200, response: OpenAPIV3.Document }) => {
    Object.assign(response, {
      servers: [{
        url: origin,
        description: 'YOOI API',
      }],
    });
    return { status, response };
  }, []);

  const { value } = useFetchJSON<{ status: 200, response: OpenAPIV3.Document }>(`${origin}${openApiPath}`, { postProcess });

  if (value) {
    return (<SwaggerUI spec={value.response} plugins={[DisableTryItOutOnSSE]} />);
  } else {
    return <Loading withLogo />;
  }
};

export default Swagger;
