import { equals } from 'ramda';
import type { ReactElement } from 'react';
import useDerivedState from '../../../utils/useDerivedState';

interface StoreInputChildrenProps<T, U> {
  value: T,
  onChange: (operation: U) => void,
  onSubmit: (value: U) => void,
  onCancel: () => void,
}

export interface StoreInputExposedProps<T, U> {
  initialValue: T,
  apply?: (current: T, newValueOrOperation: U) => T,
  onSubmit?: (value: Exclude<T, undefined> | null) => void,
}

interface StoreInputProps<T, U> extends StoreInputExposedProps<T, U> {
  children: (props: StoreInputChildrenProps<T, U>) => (ReactElement | null),
}

const StoreInput = <T, U = T>({ initialValue, apply = (_, v) => v as unknown as T, onSubmit, children }: StoreInputProps<T, U>): ReactElement | null => {
  const [value, setValue, resetValue] = useDerivedState<T>(() => initialValue, [initialValue]);
  const props: StoreInputChildrenProps<T, U> = {
    value,
    onChange: (newValueOrOperation) => {
      setValue((oldValue) => apply(oldValue, newValueOrOperation));
    },
    onSubmit: (newValueOrOperation) => {
      resetValue();
      const valueToSubmit = apply(value, newValueOrOperation);
      const valueChanged = !equals(initialValue, valueToSubmit);
      if (valueChanged) {
        onSubmit?.(valueToSubmit === undefined ? null : valueToSubmit as Exclude<T, undefined>);
      }
    },
    onCancel: () => {
      resetValue();
    },
  };

  return (
    children(props)
  );
};

export default StoreInput;
