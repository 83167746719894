import type { FunctionComponent } from 'react';
import type { Filters, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { FILTER_PARAMETER_CURRENT, getFilterFunction, isEmbeddedConceptInstance, stakeholdersFieldHandler } from 'yooi-modules/modules/conceptModule';
import {
  ConceptRole_ForCollaboration,
  ConceptRoleGroupAssignation,
  ConceptRoleGroupAssignation_Role_Concept,
  ConceptRoleGroupAssignation_Role_ConceptRole,
  ConceptRoleGroupAssignation_Role_Group,
  ConceptRoleUserAssignation,
  ConceptRoleUserAssignation_Role_Concept,
  ConceptRoleUserAssignation_Role_ConceptRole,
  ConceptRoleUserAssignation_Role_User,
  Group,
  User,
} from 'yooi-modules/modules/conceptModule/ids';
import { filterNullOrUndefined, joinObjects, newError } from 'yooi-utils';
import SearchAndSelectMultiple from '../../../../components/molecules/SearchAndSelectMultiple';
import useAcl from '../../../../store/useAcl';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';
import { getInlineCreationBuilder } from '../../conceptUtils';
import { getNavigationFilter } from '../../FrontFilterRenderers';
import { getChipOptions, getModelTypeOptions, getSearchChipOptions } from '../../modelTypeUtils';
import EmbeddedStakeholdersRenderer from './EmbeddedStakeholdersRenderer';

interface StakeholdersFieldRendererProps {
  dimensions: { n1InstanceId: string, n2InstanceId?: string },
  fieldId: string,
  valueFilters?: Filters,
  parametersMapping?: ParametersMapping,
  readOnly?: boolean,
}

const StakeholdersFieldRenderer: FunctionComponent<StakeholdersFieldRendererProps> = ({ dimensions, fieldId, valueFilters, parametersMapping = {}, readOnly = false }) => {
  const store = useStore();
  const usageVariant = useUsageContext();
  const { canAssignStakeholders, canCreateObject } = useAcl();

  const fieldHandler = stakeholdersFieldHandler(store, fieldId);
  const { targetType: targetTypeId } = fieldHandler.resolveConfiguration();

  if (!targetTypeId) {
    throw newError('Invalid stakeholder field', { fieldId });
  }

  const navigationFilters = getNavigationFilter(store, fieldId, targetTypeId, dimensions.n1InstanceId);

  const { value } = fieldHandler.getValueResolution(dimensions);
  const filterFunction = getFilterFunction(store, valueFilters);

  const conceptInstance = dimensions.n2InstanceId ? store.getObjectOrNull(dimensions.n2InstanceId) : store.getObjectOrNull(dimensions.n1InstanceId);
  const isEmbeddedConcept = conceptInstance !== null && isEmbeddedConceptInstance(conceptInstance);
  if (isEmbeddedConcept) {
    return (
      <EmbeddedStakeholdersRenderer instanceId={conceptInstance.id} targetType={targetTypeId} />
    );
  } else if (dimensions.n2InstanceId) {
    // In case of n2InstanceId, n1 is the role, n2 is the concept
    const conceptId = dimensions.n2InstanceId;

    const onSelect = (selectedId: string) => {
      store.withAssociation(targetTypeId === User ? ConceptRoleUserAssignation : ConceptRoleGroupAssignation)
        .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_Concept : ConceptRoleGroupAssignation_Role_Concept, conceptId)
        .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_ConceptRole : ConceptRoleGroupAssignation_Role_ConceptRole, dimensions.n1InstanceId)
        .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_User : ConceptRoleGroupAssignation_Role_Group, selectedId)
        .updateObject({});
    };

    const canAssign = canAssignStakeholders(conceptId);
    const placeholder = targetTypeId === User ? i18n`Add people` : i18n`Add group`;

    return (
      <SearchAndSelectMultiple
        selectedOptions={
          value
            .flatMap((v) => {
              const option = getChipOptions(store, v.id, navigationFilters);
              if (!option) {
                return [];
              }
              const options = [];
              if (Array.isArray(v[ConceptRole_ForCollaboration]) && v[ConceptRole_ForCollaboration].length > 0) {
                options.push(joinObjects(
                  option,
                  {
                    id: `${v.id}_${ConceptRole_ForCollaboration}`,
                    tooltip: i18n`Assigned via collaborations`,
                    noDelete: true,
                  }
                ));
              }
              if (targetTypeId === User) {
                if (Array.isArray(v[Group]) && v[Group].length > 0) {
                  options.push(joinObjects(
                    option,
                    {
                      id: `${v.id}_${Group}`,
                      tooltip: i18n`Assigned via group`,
                      noDelete: true,
                    }
                  ));
                }
                if (Array.isArray(v[User]) && v[User].length > 0) {
                  options.push(option);
                }
              } else if (Array.isArray(v[Group]) && v[Group].length > 0) {
                options.push(option);
              }

              return options;
            })
        }
        computeOptions={() => getModelTypeOptions(store, targetTypeId)
          .filter(({ id }) => !filterFunction || filterFunction(joinObjects(parametersMapping, { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id } })))}
        onSelect={(option) => onSelect(option.id)}
        onDelete={(option) => {
          if (dimensions.n2InstanceId) {
            store.withAssociation(targetTypeId === User ? ConceptRoleUserAssignation : ConceptRoleGroupAssignation)
              .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_Concept : ConceptRoleGroupAssignation_Role_Concept, dimensions.n2InstanceId)
              .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_ConceptRole : ConceptRoleGroupAssignation_Role_ConceptRole, dimensions.n1InstanceId)
              .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_User : ConceptRoleGroupAssignation_Role_Group, option.id)
              .deleteObject();
          }
        }}
        placeholder={usageVariant === UsageVariant.inTable ? undefined : placeholder}
        searchOptions={getSearchChipOptions(store, targetTypeId)}
        getInlineCreation={canAssign && canCreateObject(targetTypeId) ? getInlineCreationBuilder(store, targetTypeId, onSelect) : undefined}
        readOnly={!canAssign || readOnly}
      />
    );
  } else {
    // In case of no n2InstanceId, n1 is the concept
    return (
      <SearchAndSelectMultiple
        selectedOptions={
          value
            .map(({ id }) => getChipOptions(store, id, navigationFilters))
            .filter(filterNullOrUndefined)
        }
        readOnly
      />
    );
  }
};

export default StakeholdersFieldRenderer;
