import type { FunctionComponent, ReactElement } from 'react';
import type { DateRange as DateRangeType } from 'yooi-utils';
import { DateStorageTypeKeys, formatForStorage, isFiniteNumber, joinObjects, periodicities, PeriodicityType } from 'yooi-utils';
import InCompositeInput from '../../../app/_global/input/InCompositeInput';
import { spacingRem } from '../../../theme/spacingDefinition';
import { formatDate, formatDateRange, getDateTypeLabel, getPeriodicityOption, getPeriodicityOptions } from '../../../utils/dateUtilsFront';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useDerivedState from '../../../utils/useDerivedState';
import useTheme from '../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../utils/useUsageContext';
import Icon, { IconColorVariant, IconName } from '../../atoms/Icon';
import Typo from '../../atoms/Typo';
import Chooser from '../../molecules/Chooser';
import SearchAndSelect from '../../molecules/SearchAndSelect';
import SpacingLine from '../../molecules/SpacingLine';
import NumberPicker from '../NumberPicker';
import DatePicker from './DatePicker';
import { DatePickerStrategy } from './datePickerUtils';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: '10rem auto',
    alignItems: 'center',
    rowGap: spacingRem.s,
    columnGap: spacingRem.s,
  },
  errorIconContainer: {
    width: '5rem',
  },
  typoValue: {
    marginLeft: '0.9rem', // spacing.s + 1px border
  },
}, 'dateRangeCustom');

interface DateRangeCustomProps {
  value?: DateRangeType,
  onSubmit: (value: DateRangeType) => void,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  readOnly?: boolean,
  withErrorIndicator?: boolean,
  withStartConstraint?: boolean,
  startConstraintChip?: ReactElement,
  startConstraintValue?: number,
  withLastAndNext?: boolean,
  defaultPeriodicity?: PeriodicityType,
  defaultFromType: DateStorageTypeKeys,
  defaultToType: DateStorageTypeKeys,
  allowEmptyConstraint?: boolean,
}

const sanitizeValue = (value: string | number | undefined): number | undefined => {
  if (typeof value === 'string') {
    const parsed = Number.parseFloat(value);
    return isFiniteNumber(parsed) ? parsed : undefined;
  } else {
    return value;
  }
};

const DateRangeCustom: FunctionComponent<DateRangeCustomProps> = ({
  value,
  onSubmit,
  onEditionStart,
  onEditionStop,
  readOnly = false,
  withErrorIndicator = false,
  withStartConstraint = true,
  startConstraintChip,
  startConstraintValue,
  withLastAndNext = false,
  defaultPeriodicity = PeriodicityType.day,
  defaultFromType,
  defaultToType,
  allowEmptyConstraint = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const periodicity = value?.period ?? defaultPeriodicity;

  const { dateFormatted: startConstraintFormatted } = formatDate(startConstraintValue, periodicity);

  const { from, to, dateFromFormatted, dateToFormatted, error } = formatDateRange(value, startConstraintValue, periodicity, allowEmptyConstraint);
  let dateFromType = value?.from?.type ?? defaultFromType;
  if (dateFromType === DateStorageTypeKeys.constraint && !startConstraintValue && !allowEmptyConstraint) {
    dateFromType = DateStorageTypeKeys.date;
  }
  const dateToType = value?.to?.type ?? undefined;

  const [dateFromValue, setDateFromValue] = useDerivedState<string | number | undefined>(() => sanitizeValue(value?.from?.value), [value?.from?.value]);
  const [dateToValue, setDateToValue] = useDerivedState<string | number | undefined>(() => sanitizeValue(value?.to?.value), [value?.to?.value]);

  const doSubmitValueUpdate = (key: 'from' | 'to', type: DateStorageTypeKeys, newValue: string | number | null): void => {
    if (typeof newValue === 'number') {
      onSubmit(joinObjects(value || {}, { [key]: { type, value: newValue } }));
    } else if (typeof newValue === 'string' && isFiniteNumber(newValue)) {
      onSubmit(joinObjects(value || {}, { [key]: { type, value: Number(newValue) } }));
    } else if (newValue === null) {
      onSubmit(joinObjects(value || {}, { [key]: { type, value: undefined } }));
    }
  };

  const onDateFromValueChanged = (type: DateStorageTypeKeys, newValue: string | number | null): void => {
    setDateFromValue(newValue ?? undefined);
    doSubmitValueUpdate('from', type, newValue);
  };

  const onDateToValueChanged = (type: DateStorageTypeKeys, newValue: string | number | null): void => {
    setDateToValue(newValue ?? undefined);
    doSubmitValueUpdate('to', type, newValue);
  };

  const startDateTypes: { id: string, label: string, storageType: DateStorageTypeKeys[], disable?: boolean, tooltip?: string }[] = [
    { id: 'fixed', label: i18n`Fixed`, storageType: [DateStorageTypeKeys.date] },
    ...withLastAndNext ? [{ id: 'relative', label: i18n`Relative`, storageType: [DateStorageTypeKeys.next, DateStorageTypeKeys.last] }] : [],
  ];

  if (withStartConstraint) {
    const disable = !withStartConstraint || (!startConstraintValue && !allowEmptyConstraint);
    let tooltip: string | undefined;
    if (disable && !startConstraintChip) {
      tooltip = i18n`No start constraint are defined in field configuration`;
    } else if (disable && !startConstraintValue && !allowEmptyConstraint) {
      tooltip = i18n`Start constraint have no value`;
    }
    startDateTypes.push({
      id: 'constraint',
      label: i18n`Constrained`,
      storageType: [DateStorageTypeKeys.constraint],
      disable,
      tooltip,
    });
  }

  const endDateTypes: { id: string, label: string, storageType: DateStorageTypeKeys[], disable?: boolean, tooltip?: string }[] = [
    { id: 'fixed', label: i18n`Fixed`, storageType: [DateStorageTypeKeys.date] },
    { id: 'relative', label: i18n`Relative`, storageType: [DateStorageTypeKeys.duration, DateStorageTypeKeys.next, DateStorageTypeKeys.last] },
  ];

  const renderStartDate = () => {
    if (readOnly) {
      return (
        <div className={classes.typoValue}>
          <Typo>{dateFromFormatted}</Typo>
        </div>
      );
    } else {
      return (
        <DatePicker
          value={dateFromValue ? from : undefined}
          onSubmit={(v) => onSubmit(joinObjects(value || {}, { period: periodicity, from: { type: DateStorageTypeKeys.date, value: formatForStorage(v) ?? undefined } }))}
          strategy={DatePickerStrategy.startOf}
          onEditionStart={onEditionStart}
          onEditionStop={onEditionStop}
          periodicity={periodicity}
        />
      );
    }
  };

  const renderEndDate = () => {
    const effectiveDateToType = dateToType ?? DateStorageTypeKeys.date;
    const endDateType = endDateTypes.find(({ storageType }) => storageType.includes(effectiveDateToType));
    const relativeTypeSelection = endDateType?.id === 'relative' ? (
      <SearchAndSelect
        selectedOption={{ id: effectiveDateToType, label: getDateTypeLabel(effectiveDateToType, periodicity) }}
        computeOptions={() => [
          DateStorageTypeKeys.duration,
          ...withLastAndNext ? [DateStorageTypeKeys.next, DateStorageTypeKeys.last] : [],
        ].map((id) => ({ id, label: getDateTypeLabel(id, periodicity) }))}
        onSelect={(option) => {
          if (option) {
            onDateToValueChanged(option.id, dateToValue ?? null);
          }
        }}
      />
    ) : null;
    if (dateToType === DateStorageTypeKeys.duration) {
      if (readOnly) {
        return (
          <SpacingLine>
            {relativeTypeSelection}
            <Typo>{dateToValue}</Typo>
            <Typo>{`${periodicity}${dateToValue && Number(dateToValue) > 0 ? 's' : ''}`}</Typo>
          </SpacingLine>
        );
      } else {
        return (
          <SpacingLine>
            {relativeTypeSelection}
            <InCompositeInput
              initialValue={dateToValue}
              setInputValue={(v) => onDateToValueChanged(DateStorageTypeKeys.duration, v)}
            >
              {({ value: innerValue, onChange, ...props }) => (
                <NumberPicker
                  decimals={0}
                  value={innerValue}
                  onChange={onChange}
                  withDecimals={false}
                  {...props}
                />
              )}
            </InCompositeInput>
            <Typo>{periodicity}</Typo>
          </SpacingLine>
        );
      }
    } else if (dateToType && [DateStorageTypeKeys.last, DateStorageTypeKeys.next].includes(dateToType)) {
      if (readOnly) {
        return (
          <SpacingLine>
            {relativeTypeSelection}
            <Typo>{dateToValue}</Typo>
            <Typo>{`${periodicity}${dateToValue && Number(dateToValue) > 0 ? 's' : ''}`}</Typo>
          </SpacingLine>
        );
      } else {
        return (
          <SpacingLine>
            {relativeTypeSelection}
            <InCompositeInput
              initialValue={dateToValue}
              setInputValue={(v) => onDateToValueChanged(dateToType, v)}
            >
              {({ value: innerValue, onChange, ...props }) => (
                <NumberPicker
                  decimals={0}
                  value={innerValue}
                  onChange={onChange}
                  {...props}
                />
              )}
            </InCompositeInput>
            <Typo>{periodicity}</Typo>
          </SpacingLine>
        );
      }
    } else if (readOnly) {
      return (
        <div className={classes.typoValue}>
          <Typo>{dateToFormatted}</Typo>
        </div>
      );
    } else {
      return (
        <DatePicker
          value={dateToValue ? to : undefined}
          onSubmit={(v) => onSubmit(joinObjects(value || {}, { period: periodicity, to: { type: DateStorageTypeKeys.date, value: formatForStorage(v) ?? undefined } }))}
          strategy={DatePickerStrategy.endOf}
          onEditionStart={onEditionStart}
          onEditionStop={onEditionStop}
          periodicity={periodicity}
        />
      );
    }
  };

  return (
    <UsageContextProvider usageVariant={UsageVariant.inForm}>
      <div className={classes.container}>
        <Typo color={theme.color.text.secondary}>{i18n`Periodicity`}</Typo>
        <SpacingLine>
          <SearchAndSelect
            onEditionStart={onEditionStart}
            onEditionStop={onEditionStop}
            computeOptions={() => getPeriodicityOptions()}
            onSelect={(option) => {
              if (option?.id === PeriodicityType.year) {
                let fromValue = value?.from?.value;
                let toValue = value?.to?.value;
                if (!fromValue && (value?.from?.type ?? defaultFromType) === DateStorageTypeKeys.date) {
                  fromValue = periodicities[PeriodicityType.year].getStartOfPeriod?.(new Date()).getTime();
                }
                if (!toValue && (value?.to?.type ?? defaultToType) === DateStorageTypeKeys.date) {
                  toValue = periodicities[PeriodicityType.year].getEndOfPeriod?.(new Date()).getTime();
                }
                onSubmit({ period: PeriodicityType.year, from: { value: fromValue, type: DateStorageTypeKeys.date }, to: { value: toValue, type: DateStorageTypeKeys.date } });
              } else {
                onSubmit(joinObjects(value || {}, { period: option?.id }));
              }
            }}
            selectedOption={getPeriodicityOption(periodicity)}
            readOnly={readOnly}
          />
          {withErrorIndicator && (
            <div className={classes.errorIconContainer}>
              {error && (
                <Icon name={IconName.dangerous} tooltip={error} colorVariant={IconColorVariant.error} />
              )}
            </div>
          )}
        </SpacingLine>
        {startConstraintChip && (
          <>
            <Typo color={theme.color.text.secondary}>{i18n`Start constraint`}</Typo>
            <SpacingLine>
              {startConstraintChip}
            </SpacingLine>
          </>
        )}
        <Typo color={theme.color.text.secondary}>{i18n`Start date`}</Typo>
        {(startDateTypes.length > 1 || dateFromType !== DateStorageTypeKeys.date) && (
          <SpacingLine>
            <Chooser
              actions={startDateTypes.map(({ id, label, disable, tooltip }) => ({
                key: id,
                name: label,
                disable,
                tooltip: tooltip ?? label,
              }))}
              onClick={(index) => {
                const newType = startDateTypes.at(index);
                if (newType && !newType.storageType.includes(dateFromType) && newType.storageType.length > 0) {
                  onSubmit(joinObjects(value || {}, { from: { type: newType.storageType[0], value: undefined } }));
                }
              }}
              selectedIndexes={[
                startDateTypes
                  .findIndex(({ storageType }) => storageType.includes(
                    (dateFromType || (withStartConstraint && startConstraintChip ? DateStorageTypeKeys.constraint : DateStorageTypeKeys.date))
                  )),
              ]}
            />
            {(dateFromType === DateStorageTypeKeys.constraint || readOnly) && <Typo>{startConstraintFormatted}</Typo>}
          </SpacingLine>
        )}
        {(!dateFromType || dateFromType === DateStorageTypeKeys.date) ? (
          <>
            {startDateTypes.length > 1 && (
              <div />
            )}
            {renderStartDate()}
          </>
        ) : null}
        {(dateFromType && [DateStorageTypeKeys.last, DateStorageTypeKeys.next].includes(dateFromType)) ? (
          <>
            <div />
            <SpacingLine>
              <SearchAndSelect
                selectedOption={{ id: dateFromType, label: getDateTypeLabel(dateFromType, periodicity) }}
                computeOptions={() => [DateStorageTypeKeys.next, DateStorageTypeKeys.last].map((id) => ({ id, label: getDateTypeLabel(id, periodicity) }))}
                onSelect={(option) => {
                  if (option) {
                    onDateFromValueChanged(option.id, dateFromValue ?? null);
                  }
                }}
              />
              <InCompositeInput
                initialValue={dateFromValue}
                setInputValue={(v) => onDateFromValueChanged(dateFromType, v)}
              >
                {({ value: innerValue, onChange, ...props }) => (
                  <NumberPicker
                    decimals={0}
                    readOnly={readOnly}
                    value={innerValue}
                    onChange={onChange}
                    {...props}
                  />
                )}
              </InCompositeInput>
              <Typo>{periodicity}</Typo>
            </SpacingLine>
          </>
        ) : null}
        {(dateFromType === DateStorageTypeKeys.constraint) ? (
          <>
            <Typo color={theme.color.text.secondary}>{i18n`Start delay`}</Typo>
            <SpacingLine>
              <InCompositeInput
                initialValue={dateFromValue}
                setInputValue={(v) => onDateFromValueChanged(DateStorageTypeKeys.constraint, v)}
              >
                {({ value: innerValue, onChange, ...props }) => (
                  <NumberPicker
                    readOnly={readOnly}
                    decimals={0}
                    value={innerValue}
                    onChange={onChange}
                    {...props}
                  />
                )}
              </InCompositeInput>
              <Typo>{periodicity}</Typo>
            </SpacingLine>
          </>
        ) : null}
        <Typo color={theme.color.text.secondary}>{i18n`End`}</Typo>
        <SpacingLine>
          {endDateTypes.length > 1 && (
            <Chooser
              actions={endDateTypes.map(({ id, label }) => ({
                key: id,
                name: label,
                tooltip: label,
              }))}
              onClick={(index) => {
                const newType = endDateTypes.at(index);
                if (newType && (!dateToType || !newType.storageType.includes(dateToType)) && newType.storageType.length > 0) {
                  onSubmit(joinObjects(value || {}, { to: { type: newType.storageType[0], value: undefined } }));
                }
              }}
              selectedIndexes={[endDateTypes.findIndex(({ storageType }) => storageType.includes((dateToType || DateStorageTypeKeys.date)))]}
            />
          )}
        </SpacingLine>
        <div />
        {renderEndDate()}
      </div>
    </UsageContextProvider>
  );
};

export default DateRangeCustom;
