import type { FunctionComponent } from 'react';
import { v4 as uuid } from 'uuid';
import type { DimensionStep, FieldStep, MappingStep, SingleParameterDefinition, PathStep } from 'yooi-modules/modules/conceptModule';
import { getPathReturnedConceptDefinitionId, InstanceReferenceType, isFieldStep, isRelationalType, isSingleRelationalType, PathStepType } from 'yooi-modules/modules/conceptModule';
import { AssociationField } from 'yooi-modules/modules/conceptModule/ids';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { DimensionDisplayAxis } from 'yooi-modules/modules/dashboardModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { Direction, joinObjects, moveElementInArray } from 'yooi-utils';
import { ButtonVariant } from '../../../../components/atoms/Button';
import Checkbox from '../../../../components/atoms/Checkbox';
import { IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import type { ItemEntry } from '../../../../components/templates/DataTable';
import DataTable from '../../../../components/templates/DataTable';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { getTableGroupByLabel } from '../../fields/_global/widgetUtils';
import StoreTextInputField from '../../input/StoreTextInputField';
import { listGroupByFieldOptions } from '../../modelTypeUtils';
import PathStepsInput from '../../path/PathStepsInput';
import { createPathConfigurationHandler, StepValidationState } from '../../pathConfigurationHandler';
import { getViewDimensionsAsParameterDefinitions } from '../common/series/viewWithSeriesFeatureUtils';
import type { TableViewResolvedDefinition } from './tableViewHandler';

interface TableViewDefinitionGroupByFieldsOptionsProps {
  viewDefinition: TableViewResolvedDefinition,
  viewDimensions: ViewDimension[],
  parameterDefinitions: SingleParameterDefinition[],
  onFieldsChange: (fields: { id: string, label?: string, path: PathStep[], isDefault?: boolean }[]) => void,
  onDefaultChange: (id: string | undefined) => void,
  readOnly: boolean,
}

enum GroupByFieldType {
  favoriteField = 'favoriteField',
  custom = 'custom',
}

const TableViewDefinitionGroupByFieldsOptions: FunctionComponent<TableViewDefinitionGroupByFieldsOptionsProps> = ({
  viewDefinition,
  viewDimensions,
  parameterDefinitions,
  onFieldsChange,
  onDefaultChange,
  readOnly,
}) => {
  const store = useStore();

  const { dimensionIds = [], fields = [] } = viewDefinition.groupBy ?? {};

  const valuePathHandler = createPathConfigurationHandler(
    store,
    parameterDefinitions,
    [
      ({ pathStep: lastStep, isNPath }) => {
        const result = [];
        const fieldTypeErrorMessage = i18n`Input end with an unauthorized field.`;
        if (isFieldStep(lastStep)) {
          const fieldDefinitionId = store.getObjectOrNull(lastStep.fieldId)?.[Instance_Of] as string;
          if (isSingleRelationalType(fieldDefinitionId) || fieldDefinitionId === AssociationField) {
            result.push({ state: StepValidationState.valid });
          } else if (isRelationalType(fieldDefinitionId)) {
            result.push({ state: StepValidationState.partiallyValid, reasonMessage: fieldTypeErrorMessage });
          } else {
            result.push({ state: StepValidationState.invalid, reasonMessage: fieldTypeErrorMessage });
          }
        } else if (isNPath) {
          result.push({ state: StepValidationState.partiallyValid, reasonMessage: i18n`Input should be unique, use a mapping in your path.` });
        } else {
          result.push({ state: StepValidationState.partiallyValid, reasonMessage: fieldTypeErrorMessage });
        }
        return result;
      }]
  );

  return (
    <DataTable<{ id: string, type: string, label?: string, path: PathStep[], isDefault?: boolean }>
      newItemIcon={IconName.add}
      newItemTitle={i18n`Add`}
      newItemButtonVariant={ButtonVariant.tertiary}
      onNewItem={() => {
        onFieldsChange([...fields, { id: uuid(), path: [] }]);
      }}
      fullWidth
      list={[
        ...fields.map((field) => (
          {
            key: field.id,
            type: 'item',
            item: joinObjects(field, { type: GroupByFieldType.custom }),
            color: undefined,
          } satisfies ItemEntry<{ id: string, type: string, label?: string, path: PathStep[], isDefault?: boolean }>
        )),
        ...dimensionIds
          .filter((id) => viewDimensions.some((dimension) => dimension.id === id && viewDefinition.getDimensionDisplay(dimension).axis !== DimensionDisplayAxis.y))
          .flatMap((dimensionId) => {
            const dimension = viewDimensions.find(({ id }) => id === dimensionId);
            const conceptDefinitionId = dimension ? getPathReturnedConceptDefinitionId(store, dimension.path) : undefined;
            const dimensionFavoriteFields = conceptDefinitionId ? listGroupByFieldOptions(store, conceptDefinitionId, true) : [];
            return dimensionFavoriteFields.map(({ id: fieldId }) => ({
              key: `${dimensionId}|${fieldId}`,
              type: 'item',
              item: {
                type: GroupByFieldType.favoriteField,
                id: `${dimensionId}|${fieldId}`,
                path: [
                  { type: PathStepType.dimension, conceptDefinitionId } as DimensionStep,
                  { type: PathStepType.mapping, mapping: { id: dimensionId, type: InstanceReferenceType.parameter } } as MappingStep,
                  { type: PathStepType.field, fieldId } as FieldStep,
                ],
              },
              color: undefined,
            } satisfies ItemEntry<{ id: string, type: string, label?: string, path: PathStep[], isDefault?: boolean }>));
          }),
      ]}
      columnsDefinition={[{
        key: 'label',
        name: i18n`Label`,
        width: 20,
        propertyId: 'label',
        cellRender: ({ label, type, path }, _, index) => (
          <StoreTextInputField
            initialValue={getTableGroupByLabel(store, label, path, parameterDefinitions)}
            onSubmit={(newLabel) => {
              onFieldsChange(fields.map((field, i) => (i === index ? joinObjects(field, { label: newLabel ?? undefined }) : field)));
            }}
            readOnly={type === GroupByFieldType.favoriteField || readOnly}
          />
        ),
      }, {
        key: 'path',
        name: i18n`Path`,
        width: 70,
        propertyId: 'path',
        cellRender: ({ path, type }, _, index) => (
          <PathStepsInput
            initialPath={path}
            onSubmit={(newPath) => {
              onFieldsChange(fields.map((field, i) => (i === index ? joinObjects(field, { path: newPath }) : field)));
            }}
            valuePathHandler={valuePathHandler}
            parameterDefinitions={parameterDefinitions}
            suggestedBasePaths={
              getViewDimensionsAsParameterDefinitions(store, viewDimensions)
                .map(({ id: parameterId, typeId, label }) => ({
                  label,
                  path: [
                    { type: PathStepType.dimension, conceptDefinitionId: typeId },
                    { type: PathStepType.mapping, mapping: { id: parameterId, type: InstanceReferenceType.parameter } },
                  ],
                }))
            }
            readOnly={type === GroupByFieldType.favoriteField || readOnly}
          />
        ),
      }, {
        propertyId: 'Default',
        key: 'Default',
        width: 10,
        name: i18n`Default`,
        cellRender: ({ id }) => (
          <SpacingLine>
            <Checkbox
              checked={id === viewDefinition.defaultGroupBy}
              onChange={(isDefault) => onDefaultChange(isDefault ? id : undefined)}
              disabled={readOnly}
            />
          </SpacingLine>
        ),
      }, {
        propertyId: 'MoveUp',
        key: 'MoveUp',
        action: true,
        cellRender: ({ type }, __, index) => (
          <SpacingLine>
            <IconOnlyButton
              disabled={type === GroupByFieldType.favoriteField || readOnly || index === 0}
              onClick={() => {
                onFieldsChange(moveElementInArray(Direction.up, index, fields));
              }}
              iconName={IconName.expand_less}
              tooltip={i18n`Move Up`}
              variant={IconOnlyButtonVariants.tertiary}
            />
          </SpacingLine>
        ),
      }, {
        propertyId: 'MoveDown',
        key: 'MoveDown',
        action: true,
        cellRender: ({ type }, __, index) => (
          <SpacingLine>
            <IconOnlyButton
              disabled={type === GroupByFieldType.favoriteField || readOnly || index === fields.length - 1}
              onClick={() => {
                onFieldsChange(moveElementInArray(Direction.down, index, fields));
              }}
              iconName={IconName.expand_more}
              tooltip={i18n`Move Down`}
              variant={IconOnlyButtonVariants.tertiary}
            />
          </SpacingLine>
        ),
      }, {
        propertyId: 'Delete',
        action: true,
        cellRender: ({ type }, _, index) => (
          <SpacingLine>
            <IconOnlyButton
              disabled={type === GroupByFieldType.favoriteField || readOnly}
              onClick={() => {
                onFieldsChange(fields.filter((__, i) => i !== index));
              }}
              iconName={IconName.delete}
              tooltip={i18n`Delete`}
              variant={IconOnlyButtonVariants.danger}
            />
          </SpacingLine>
        ),
      }]}
    />
  );
};

export default TableViewDefinitionGroupByFieldsOptions;
