import { AnimatePresence, motion } from 'framer-motion';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { filterNullOrUndefined } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import Tooltip from '../../../../components/atoms/Tooltip';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import useSizeContext, { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../../utils/useSizeContext';
import { UsageContextProvider, UsageVariant } from '../../../../utils/useUsageContext';
import { getFieldLabel } from '../../fieldUtils';
import FormTextInputField from '../../input/FormTextInputField';
import { getSearchableConfigFields } from '../../modelTypeUtils';

interface GraphChartSearchTextButtonProps {
  placeholder: string,
  conceptDefinitionId: string,
  search: string | undefined,
  setSearch: (newSearch: string) => void,
  clearSearch: () => void,
  showSearch?: boolean,
}

const GraphChartSearchTextButton: FunctionComponent<GraphChartSearchTextButtonProps> = ({
  placeholder,
  conceptDefinitionId,
  search,
  setSearch,
  clearSearch,
  showSearch = true,
}) => {
  const store = useStore();

  const hasSearchValue = search !== undefined && search !== '';

  const [displayState, setDisplayState] = useState<{ show: false } | { show: true, focusOnMount: boolean }>(showSearch ? { show: true, focusOnMount: false } : { show: false });

  const { sizeVariant } = useSizeContext();

  const getSearchBarTooltip = () => {
    const searchFieldIds = conceptDefinitionId ? getSearchableConfigFields(store, conceptDefinitionId) : [];
    if (searchFieldIds.length === 0) {
      return i18n`Search in all text fields`;
    } else {
      const fields = searchFieldIds.slice(0, 3).map((id) => {
        const field = store.getObjectOrNull<FieldStoreObject>(id);
        return field ? getFieldLabel(store, field) : undefined;
      }).filter(filterNullOrUndefined).join(', ');
      if (searchFieldIds.length < 4) {
        return i18n`Search in ${fields}`;
      } else {
        return i18n`Search in ${fields} +${searchFieldIds.length - 3} more fields`;
      }
    }
  };

  return (
    <>
      <IconOnlyButton
        tooltip={displayState.show ? i18n`Close` : i18n`Search`}
        iconName={IconName.search}
        onClick={() => {
          setDisplayState((current) => (current.show ? { show: false } : { show: true, focusOnMount: true }));
        }}
        disabled={hasSearchValue}
        variant={IconOnlyButtonVariants.tertiary}
      />
      <AnimatePresence initial={false}>
        {
          (hasSearchValue || displayState.show)
            ? (
              <motion.span
                initial={{ width: 0 }}
                animate={{ width: sizeVariant === SizeVariant.title ? '24rem' : '10rem' }}
                exit={{ width: 0 }}
                transition={{ duration: 0.2 }}
              >
                <SizeContextProvider sizeVariant={sizeVariant === SizeVariant.title ? SizeVariant.main : SizeVariant.small} hierarchyVariant={HierarchyVariant.inline}>
                  <UsageContextProvider usageVariant={UsageVariant.inForm}>
                    <Tooltip title={getSearchBarTooltip()}>
                      <FormTextInputField
                        maxLine={1}
                        initialValue={search}
                        placeholder={placeholder}
                        onChangeDebounced={(value) => {
                          if (value && value.length > 0) {
                            setSearch(value);
                          } else {
                            clearSearch();
                          }
                        }}
                        onClear={clearSearch}
                        focusOnMount={displayState.show && displayState.focusOnMount}
                      />
                    </Tooltip>
                  </UsageContextProvider>
                </SizeContextProvider>
              </motion.span>
            )
            : null
        }
      </AnimatePresence>
    </>
  );
};

export default GraphChartSearchTextButton;
