import type { DimensionsMapping, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { dimensionsMappingToParametersMapping, timeseriesNumberFieldHandler } from 'yooi-modules/modules/conceptModule';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import type { FrontObjectStore } from '../../../../../store/useStore';
import type { TickResolved } from '../../../fieldUtils';
import { resolveTickValue, TickResolutionStatus } from '../../../fieldUtils';

interface Step {
  value: number,
  color: string | undefined,
}

export const getTimeseriesRangeValues = (store: FrontObjectStore, fieldId: string, dimensionsMapping: DimensionsMapping, parametersMapping: ParametersMapping): Step[] => {
  const fieldHandler = timeseriesNumberFieldHandler(store, fieldId);
  const configuration = fieldHandler.resolveConfigurationWithOverride(dimensionsMapping);

  const resolutionParameters = joinObjects(parametersMapping, dimensionsMappingToParametersMapping(dimensionsMapping));

  return (configuration.rangeValue ?? [])
    .map((tick) => resolveTickValue(store, resolutionParameters, tick))
    .filter(filterNullOrUndefined)
    .filter((resolution): resolution is TickResolved => resolution.status === TickResolutionStatus.Resolved);
};

export const getTimeseriesMaxMinValues = (store: FrontObjectStore, fieldId: string, dimensionsMapping: DimensionsMapping, parametersMapping: ParametersMapping): {
  min: { status: TickResolutionStatus.Resolved, value: number, color: string | undefined } | { status: TickResolutionStatus.Error, error: string } | undefined,
  max: { status: TickResolutionStatus.Resolved, value: number, color: string | undefined } | { status: TickResolutionStatus.Error, error: string } | undefined,
} => {
  const fieldHandler = timeseriesNumberFieldHandler(store, fieldId);
  const configuration = fieldHandler.resolveConfigurationWithOverride(dimensionsMapping);

  const resolutionParameters = joinObjects(parametersMapping, dimensionsMappingToParametersMapping(dimensionsMapping));

  return {
    min: configuration.minValue ? resolveTickValue(store, resolutionParameters, configuration.minValue) : undefined,
    max: configuration.maxValue ? resolveTickValue(store, resolutionParameters, configuration.maxValue) : undefined,
  };
};
