import type { BlockStoreObject, FieldBlockDisplayStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import {
  Block_FieldDisplays,
  Block_Name,
  Block_Rank,
  Block_Type,
  Block_ViewDisplayCondition,
  BlockParent_Block,
  BlockType_Section,
  FieldBlockConceptInstanceDisplay,
  FieldBlockConceptInstanceDisplay_DisplayOverride,
  FieldBlockConceptInstanceDisplay_Role_Concept,
  FieldBlockConceptInstanceDisplay_Role_FieldBlockDisplay,
  FieldBlockDisplay_EditDisplayCondition,
  FieldBlockDisplay_FieldDisplayConfiguration,
  FieldBlockDisplay_FieldPath,
  FieldBlockDisplay_Rank,
  FieldBlockDisplay_ViewDisplayCondition,
  FieldBlockDisplay_VisibleFromStatus,
  FieldBlockDisplay_VisibleToStatus,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import { OverridableDisplayType } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import type {
  ConceptDefinitionStoreObject,
  ConceptStoreObject,
  FieldStoreObject,
  ParametersMapping,
  PathStep,
  SingleParameterDefinition,
} from 'yooi-modules/modules/conceptModule';
import {
  BLOCK_PARAMETER_CURRENT,
  createValuePathResolver,
  FILTER_PARAMETER_CURRENT,
  FILTER_PARAMETER_LOGGED_USER,
  getFieldDimensionOfModelType,
  getFieldUtilsHandler,
  getFilterFunction,
  getPathLastFieldInformation,
  isSingleFieldResolution,
  ParsedDimensionType,
  parseDimensionMapping,
  workflowFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import {
  Concept,
  ConceptDefinition_MainWorkflowField,
  ConceptDefinition_ShowEcosystemTab,
  Field,
  Field_Formula,
  TimeseriesNumberField,
  Workflow_TargetedConceptDefinition,
  WorkflowEntry,
  WorkflowEntry_Rank,
  WorkflowEntry_Role_Concept,
  WorkflowEntry_Role_Workflow,
  WorkflowField,
  WorkflowField_Workflow,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import { compareProperty, compareRank, extractAndCompareValue, joinObjects } from 'yooi-utils';
import type { FrontObjectStore } from '../../store/useStore';
import i18n from '../../utils/i18n';
import { getBlockParameterDefinitionCurrent } from '../_global/conceptFilterIdUtils';
import { computeSectionHash, computeTabHash } from '../_global/conceptHashUtils';
import { getEditorLinePathHandler } from '../_global/conceptUtils';
import { BlockFieldDisplayStatus } from '../_global/fields/_global/blockFieldUtils';
import { getFieldDefinitionHandler, getFieldHandler } from '../_global/fields/FieldLibrary';
import { FieldEditionOptionMode, FieldEditionVariant, readOnlyEditionHandler } from '../_global/fields/FieldLibraryTypes';
import { getLoggedUserParameterDefinition, isFilterReadOnly } from '../_global/filter/filterUtils';

const isFieldDisplayRecommended = (store: ObjectStoreWithTimeseries, concept: StoreObject, visibleFromStatusId: string | undefined, visibleToStatusId: string | undefined) => {
  const conceptDefinition = concept.navigate(Instance_Of);

  const mainWorkflowField = conceptDefinition.navigateOrNull(ConceptDefinition_MainWorkflowField);
  if (
    !mainWorkflowField
    || !isInstanceOf(mainWorkflowField, WorkflowField)
    || (isInstanceOf(mainWorkflowField, WorkflowField) && !mainWorkflowField.navigateOrNull(WorkflowField_Workflow)?.navigateOrNull(Workflow_TargetedConceptDefinition))
  ) {
    return true;
  }
  const mainWorkflowFieldDimensionId = getFieldDimensionOfModelType(store, mainWorkflowField.id, conceptDefinition.id);
  if (!mainWorkflowFieldDimensionId) {
    return true;
  }

  let currentStatusRank: string | undefined;
  const statusRank = (statusId: string | undefined): string | undefined => {
    if (statusId) {
      return store.withAssociation(WorkflowEntry)
        .withRole(WorkflowEntry_Role_Workflow, mainWorkflowField[WorkflowField_Workflow] as string)
        .withRole(WorkflowEntry_Role_Concept, statusId)
        .getObjectOrNull()
        ?.[WorkflowEntry_Rank] as string | undefined;
    } else {
      return undefined;
    }
  };

  const status = workflowFieldHandler(store, mainWorkflowField.id).getValueResolution({ [mainWorkflowFieldDimensionId]: concept.id }).value.value;
  if (status) {
    currentStatusRank = statusRank(status.id);
  } else {
    currentStatusRank = store.withAssociation(WorkflowEntry)
      .withRole(WorkflowEntry_Role_Workflow, mainWorkflowField[WorkflowField_Workflow] as string)
      .list()
      .sort(compareProperty('object', compareProperty(WorkflowEntry_Rank, compareRank)))[0]
      ?.object[WorkflowEntry_Rank] as string | undefined;
  }

  // No current status ? display everything
  if (!currentStatusRank) {
    return true;
  }

  const fromStatusRank = statusRank(visibleFromStatusId);
  const toStatusRank = statusRank(visibleToStatusId);

  if (fromStatusRank && toStatusRank) {
    return compareRank(fromStatusRank, currentStatusRank) <= 0 && compareRank(currentStatusRank, toStatusRank) <= 0;
  } else if (fromStatusRank) {
    return compareRank(fromStatusRank, currentStatusRank) <= 0;
  } else if (toStatusRank) {
    return compareRank(toStatusRank, currentStatusRank) >= 0;
  } else {
    return true;
  }
};

export interface BlockFieldDisplay {
  blockFieldDisplayId: string,
  fieldPath: PathStep[],
  isDisplayRecommended: boolean,
  displayStatus: BlockFieldDisplayStatus,
  isFiltered: boolean,
  readOnly: boolean,
}

const getBlockDisplayStatus = (
  store: FrontObjectStore,
  blockFieldDisplay: FieldBlockDisplayStoreObject,
  concept: ConceptStoreObject,
  layoutPath: PathStep[],
  parametersMapping: ParametersMapping
) => {
  const fieldDisplayConfiguration = blockFieldDisplay[FieldBlockDisplay_FieldDisplayConfiguration];
  const pathResolver = createValuePathResolver(store, parametersMapping);
  const fieldValue = pathResolver.resolvePathField(layoutPath);
  const { fieldId = undefined, dimensionsMapping = {} } = isSingleFieldResolution(fieldValue) ? fieldValue : {};
  const fieldHandler = fieldId ? getFieldHandler(store, fieldId) : undefined;
  const fieldUtilsHandler = fieldId ? getFieldUtilsHandler(store, fieldId) : undefined;
  const parsedDimension = parseDimensionMapping(dimensionsMapping ?? {});
  if (
    !isInstanceOf(concept, Concept)
    || (fieldHandler && !fieldUtilsHandler?.isEmpty(dimensionsMapping))
    || (
      parsedDimension.type === ParsedDimensionType.MonoDimensional
      && parsedDimension.objectId === concept.id
      && fieldId
      && fieldDisplayConfiguration?.displayType === OverridableDisplayType.Configuration
      && isInstanceOf(store.getObjectOrNull(fieldId), Field)
      && getFieldDefinitionHandler(store, store.getObject<FieldStoreObject>(fieldId)[Instance_Of])
        .getEditionOptions({
          mode: FieldEditionOptionMode.Override,
          modelTypeId: concept[Instance_Of],
          isEdition: false,
          editionHandler: readOnlyEditionHandler(fieldHandler?.configuration?.getInitialOverrideState?.(concept.id) ?? {}),
          readOnly: true,
          variant: FieldEditionVariant.page,
        })
        .some((section) => (section.type === 'section' ? section.options.some(({ hasValue }) => hasValue()) : section.sections.some(({ options }) => options.some(({ hasValue }) => hasValue()))))
    )
  ) {
    return BlockFieldDisplayStatus.locked;
  } else {
    const displayOverride = store.withAssociation(FieldBlockConceptInstanceDisplay)
      .withRole(FieldBlockConceptInstanceDisplay_Role_FieldBlockDisplay, blockFieldDisplay.id)
      .withRole(FieldBlockConceptInstanceDisplay_Role_Concept, concept.id)
      .getObjectOrNull()
      ?.[FieldBlockConceptInstanceDisplay_DisplayOverride];

    if (typeof displayOverride === 'boolean') {
      return displayOverride ? BlockFieldDisplayStatus.visible : BlockFieldDisplayStatus.hidden;
    } else {
      const isDisplayRecommended = isFieldDisplayRecommended(
        store,
        concept,
        blockFieldDisplay[FieldBlockDisplay_VisibleFromStatus],
        blockFieldDisplay[FieldBlockDisplay_VisibleToStatus]
      );
      return isDisplayRecommended ? BlockFieldDisplayStatus.visible : BlockFieldDisplayStatus.hidden;
    }
  }
};

const isBlockFieldDisplayed = (store: FrontObjectStore, block: FieldBlockDisplayStoreObject, conceptId: string | undefined, parametersMapping: ParametersMapping) => {
  const filterFunction = getFilterFunction(store, block[FieldBlockDisplay_ViewDisplayCondition]);
  return (!filterFunction || filterFunction(joinObjects(parametersMapping, { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: conceptId } })));
};

const isBlockDisplayed = (store: FrontObjectStore, block: BlockStoreObject, conceptId: string, parametersMapping: ParametersMapping) => {
  const filterFunction = getFilterFunction(store, block[Block_ViewDisplayCondition]);
  return (!filterFunction || filterFunction(joinObjects(
    parametersMapping,
    { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: conceptId } }
  )));
};

export const getBlockFieldDisplays = (
  store: FrontObjectStore,
  concept: ConceptStoreObject,
  block: BlockStoreObject,
  userId: string
): BlockFieldDisplay[] => {
  const parameterDefinitions: SingleParameterDefinition[] = [getBlockParameterDefinitionCurrent(concept[Instance_Of]), getLoggedUserParameterDefinition()];
  const parametersMapping = { [BLOCK_PARAMETER_CURRENT]: { type: 'single' as const, id: concept.id }, [FILTER_PARAMETER_LOGGED_USER]: { type: 'single' as const, id: userId } };
  return block.navigateBack<FieldBlockDisplayStoreObject>(Block_FieldDisplays)
    .filter((blockFieldDisplay) => {
      const fieldPath = blockFieldDisplay[FieldBlockDisplay_FieldPath] ?? [];
      const pathValueHandler = getEditorLinePathHandler(store, parameterDefinitions);
      return !pathValueHandler.getErrors(fieldPath);
    })
    .sort(extractAndCompareValue((display) => display[FieldBlockDisplay_Rank] ?? '', compareRank))
    .map((blockFieldDisplay) => {
      const fieldPath = blockFieldDisplay[FieldBlockDisplay_FieldPath] ?? [];
      const fieldResolution = createValuePathResolver(store, parametersMapping).resolvePathField(fieldPath);
      let fieldPathLastInstanceId;
      if (isSingleFieldResolution(fieldResolution) && fieldResolution.dimensionsMapping) {
        const parsedDimension = parseDimensionMapping(fieldResolution.dimensionsMapping);
        if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
          fieldPathLastInstanceId = parsedDimension.objectId;
        }
      }
      return {
        blockFieldDisplayId: blockFieldDisplay.id,
        fieldPath,
        isDisplayRecommended: isFieldDisplayRecommended(
          store,
          concept,
          blockFieldDisplay[FieldBlockDisplay_VisibleFromStatus],
          blockFieldDisplay[FieldBlockDisplay_VisibleToStatus]
        ),
        displayStatus: getBlockDisplayStatus(store, blockFieldDisplay, concept, fieldPath, parametersMapping),
        isFiltered: !isBlockFieldDisplayed(store, blockFieldDisplay, fieldPathLastInstanceId, parametersMapping),
        readOnly: isFilterReadOnly(
          getFilterFunction(store, blockFieldDisplay[FieldBlockDisplay_EditDisplayCondition])?.(
            joinObjects(parametersMapping, { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: fieldPathLastInstanceId } })
          )
        ) ?? false,
      };
    });
};

interface PrecomputedBlock {
  isVisible: boolean,
  isGreyed: boolean,
  hash: string,
  sections: { id: string, name: string, hash: string }[],
}

export const precomputeTab = (store: FrontObjectStore, concept: ConceptStoreObject, tab: BlockStoreObject, userId: string): PrecomputedBlock => {
  const parametersMapping = {
    [BLOCK_PARAMETER_CURRENT]: { type: 'single' as const, id: concept.id },
    [FILTER_PARAMETER_LOGGED_USER]: { type: 'single' as const, id: userId },
  };

  if (!isBlockDisplayed(store, tab, concept.id, parametersMapping)) {
    return {
      isVisible: false,
      isGreyed: true,
      hash: '',
      sections: [],
    };
  }

  const blockIsDisplayed = new Map<string, boolean>();

  const hash = computeTabHash(tab, concept.navigate<ConceptDefinitionStoreObject>(Instance_Of)[ConceptDefinition_ShowEcosystemTab] ? ['ecosystem'] : []);

  const parameterDefinitions: SingleParameterDefinition[] = [
    { typeId: concept[Instance_Of], id: BLOCK_PARAMETER_CURRENT, label: i18n`Current`, type: 'dimension' },
    getLoggedUserParameterDefinition(),
  ];

  let isVisible = false;
  let isGreyed = true;
  const computedFields: FieldBlockDisplayStoreObject[] = [];
  const timeseriesFields: FieldBlockDisplayStoreObject[] = [];
  const precomputeFieldDisplay = (simpleComplexityOnly: boolean) => (blockFieldDisplay: FieldBlockDisplayStoreObject) => {
    const fieldPath = blockFieldDisplay[FieldBlockDisplay_FieldPath] ?? [];
    const lastFieldId = getPathLastFieldInformation(fieldPath)?.fieldId;
    const lastField = lastFieldId ? store.getObjectOrNull<FieldStoreObject>(lastFieldId) : null;
    if (simpleComplexityOnly) {
      // TimeseriesField and computed field can take time to compute, and will be computed last if necessary.
      if (lastField?.[Instance_Of] === TimeseriesNumberField) {
        timeseriesFields.push(blockFieldDisplay);
        return;
      } else if (lastField?.[Field_Formula]) {
        computedFields.push(blockFieldDisplay);
        return;
      }
    }
    if (!isVisible) {
      if (!getEditorLinePathHandler(store, parameterDefinitions).getErrors(fieldPath)) {
        const fieldResolution = createValuePathResolver(store, parametersMapping).resolvePathField(fieldPath);
        let fieldPathLastInstanceId;
        if (isSingleFieldResolution(fieldResolution) && fieldResolution.dimensionsMapping) {
          const parsedDimension = parseDimensionMapping(fieldResolution.dimensionsMapping);
          if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
            fieldPathLastInstanceId = parsedDimension.objectId;
          }
        }
        isVisible = isBlockFieldDisplayed(store, blockFieldDisplay, fieldPathLastInstanceId, parametersMapping);
      }
    }
    if (isVisible && isGreyed) {
      isGreyed = getBlockDisplayStatus(store, blockFieldDisplay, concept, fieldPath, parametersMapping) === BlockFieldDisplayStatus.hidden;
    }
  };
  const precomputeBlock = (block: BlockStoreObject) => {
    const blockFieldDisplays = block.navigateBack<FieldBlockDisplayStoreObject>(Block_FieldDisplays);

    let i = 0;
    while (i < blockFieldDisplays.length && isGreyed) {
      precomputeFieldDisplay(true)(blockFieldDisplays[i]);
      i += 1;
    }

    if (isGreyed) {
      const children = block.navigateBack<BlockStoreObject>(BlockParent_Block).filter((child) => {
        const isDisplayed = isBlockDisplayed(store, child, concept.id, parametersMapping);
        blockIsDisplayed.set(child.id, isDisplayed);
        return isDisplayed;
      });
      i = 0;
      while (i < children.length && isGreyed) {
        precomputeBlock(children[i]);
        i += 1;
      }
    }
  };

  precomputeBlock(tab);

  let i = 0;
  while (i < computedFields.length && isGreyed) {
    precomputeFieldDisplay(false)(computedFields[i]);
    i += 1;
  }
  i = 0;
  while (i < timeseriesFields.length && isGreyed) {
    precomputeFieldDisplay(false)(timeseriesFields[i]);
    i += 1;
  }

  return {
    isVisible,
    isGreyed,
    hash,
    sections: tab.navigateBack<BlockStoreObject>(BlockParent_Block)
      .filter((childBlock) => (childBlock[Block_Type] === BlockType_Section
        && (blockIsDisplayed.has(childBlock.id) ? blockIsDisplayed.get(childBlock.id) : isBlockDisplayed(store, childBlock, concept.id, parametersMapping))))
      .sort(compareProperty(Block_Rank, compareRank))
      .map((section, index) => ({
        id: section.id,
        hash: computeSectionHash(section, hash),
        name: section[Block_Name] || `Section ${index + 1}`,
      })),
  };
};
