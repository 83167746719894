import type { BlockStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import {
  Block_FieldDisplays,
  Block_Parent,
  Block_Rank,
  Block_Type,
  BlockType_Tab,
  ConceptDefinition_Blocks,
  FieldBlockDisplay_Block,
  FieldBlockDisplay_Rank,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import type { DecoratedList } from 'yooi-utils';
import { compareRank, extractAndCompareValue, ranker } from 'yooi-utils';
import type { FrontObjectStore } from '../../../../../store/useStore';

export const getSortedBlockFields = (store: ObjectStoreWithTimeseries, blockId: string): StoreObject[] => store.getObject(blockId)
  .navigateBack(Block_FieldDisplays)
  .sort(extractAndCompareValue((fieldBlockDisplay) => fieldBlockDisplay[FieldBlockDisplay_Rank] as string, compareRank));

export const computeChildBlockList = (block: StoreObject): DecoratedList<StoreObject> => {
  const list = block.navigateBack(Block_Parent)
    .sort((a, b) => compareRank(a[Block_Rank] as string, b[Block_Rank] as string));
  return ranker.decorateList(list, (b: StoreObject) => b[Block_Rank] as string);
};

export const updateFieldParentBlock = (store: ObjectStoreWithTimeseries, fieldBlockDisplayId: string, newBlockId: string): void => {
  const blockFieldsInsertRanks = ranker.decorateList(
    getSortedBlockFields(store, newBlockId),
    (fieldBlockDisplay) => fieldBlockDisplay[FieldBlockDisplay_Rank] as string
  );
  store.updateObject(fieldBlockDisplayId, {
    [FieldBlockDisplay_Block]: newBlockId,
    [FieldBlockDisplay_Rank]: blockFieldsInsertRanks.insertAfterLastItemRank(),
  });
};

export enum DisplayItemTypes {
  block = 'block',
  field = 'field',
}

export type DisplayItem = BlockDisplayItem | FieldDisplayItem;

export type LayoutCollapseConfiguration = Record<string, boolean>;

interface BlockDisplayItem {
  blockId: string,
  fieldBlockDisplayId: undefined,
  type: DisplayItemTypes.block,
}

interface FieldDisplayItem {
  blockId: string,
  fieldBlockDisplayId: string,
  type: DisplayItemTypes.field,
}

export const isFieldDisplayItem = (displayItem: DisplayItem): displayItem is FieldDisplayItem => Boolean(displayItem.type === DisplayItemTypes.field);

const getOrderedTabs = (conceptDefinition: StoreObject) => conceptDefinition.navigateBack(ConceptDefinition_Blocks).filter((b) => b[Block_Type] === BlockType_Tab)
  .sort(extractAndCompareValue((b) => b[Block_Rank] as string, compareRank));

const getOrderedBlocks = (parentBlock: StoreObject) => parentBlock.navigateBack(Block_Parent)
  .sort(extractAndCompareValue((b) => b[Block_Rank] as string, compareRank));

const getOrderedBlockFields = (store: ObjectStoreWithTimeseries, parentBlockId: string) => store.getObject(parentBlockId)
  .navigateBack(Block_FieldDisplays)
  .sort(extractAndCompareValue((fieldBlockDisplay) => fieldBlockDisplay[FieldBlockDisplay_Rank] as string, compareRank));

const getChildTree = (store: ObjectStoreWithTimeseries, parentBlock: StoreObject, modelTypeId: string): (BlockDisplayItem | FieldDisplayItem)[] => [
  ...getOrderedBlocks(parentBlock).flatMap((block): DisplayItem[] => [
    { blockId: block.id, fieldBlockDisplayId: undefined, type: DisplayItemTypes.block },
    ...getOrderedBlockFields(store, block.id)
      .map((fieldBlockDisplay): FieldDisplayItem => ({ blockId: block.id, fieldBlockDisplayId: fieldBlockDisplay.id, type: DisplayItemTypes.field })),
    ...getChildTree(store, block, modelTypeId),
  ]),
];

export const getFlattenedTree = (store: ObjectStoreWithTimeseries, conceptDefinition: StoreObject): DisplayItem[] => getOrderedTabs(conceptDefinition)
  .flatMap((tab): DisplayItem[] => [
    { blockId: tab.id, fieldBlockDisplayId: undefined, type: DisplayItemTypes.block },
    ...getOrderedBlockFields(store, tab.id)
      .map((fieldBlockDisplay): FieldDisplayItem => ({ blockId: tab.id, fieldBlockDisplayId: fieldBlockDisplay.id, type: DisplayItemTypes.field })),
    ...getChildTree(store, tab, conceptDefinition.id),
  ]);

export const getParentsBlocksIds = (store: FrontObjectStore, blockId: string): string[] => {
  const parentsBlocksIds: string[] = [];
  const block = store.getObject<BlockStoreObject>(blockId);
  let parentId = block[Block_Parent];
  if (parentId) {
    do {
      parentsBlocksIds.push(parentId);
      const parentBlock: BlockStoreObject = store.getObject<BlockStoreObject>(parentId);
      parentId = parentBlock[Block_Parent];
    } while (parentId);
  }
  return parentsBlocksIds;
};

export const getChildrenCount = (store: ObjectStoreWithTimeseries, parentBlock: StoreObject, conceptDefinition: StoreObject): number => getFlattenedTree(store, conceptDefinition)
  .filter((item) => item.blockId === parentBlock.id).length - 1 + getChildTree(store, parentBlock, conceptDefinition.id).length;
