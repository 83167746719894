import { equals } from 'ramda';
import { useCallback, useRef } from 'react';
import useForceUpdate from './useForceUpdate';

type Set<T> = (funOrValue: T | ((current: T) => T)) => void;
type Reset = () => void;

const useDerivedState = <T>(initialValueFunction: () => T, deps: unknown[]): [T, Set<T>, Reset] => {
  const valueRef = useRef<T>();
  const depsRef = useRef<unknown[]>();
  const update = useForceUpdate();

  const reset = useCallback(() => {
    valueRef.current = initialValueFunction();
    update();
  }, [initialValueFunction, update]);

  const set: Set<T> = useCallback((funOrValue) => {
    if (funOrValue instanceof Function) {
      valueRef.current = funOrValue(valueRef.current as T);
    } else {
      valueRef.current = funOrValue;
    }
    update();
  }, [update]);

  if (!equals(depsRef.current, deps)) {
    valueRef.current = initialValueFunction();
    depsRef.current = deps;
  }

  return [valueRef.current as T, set, reset];
};

export default useDerivedState;
