import { motion } from 'framer-motion';
import type { ComponentProps, FunctionComponent } from 'react';
import { useCallback, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import type { ToastEvent } from '../../utils/notify';
import { useClearToastBus, useToastBus } from '../../utils/notify';
import useForceUpdate from '../../utils/useForceUpdate';
import { IconName } from '../atoms/Icon';
import Banner, { BannerVariant } from '../molecules/Banner';

const useStyles = makeStyles({
  toaster: {
    flexDirection: 'column',
    left: '50%',
    transform: 'translateX(-50%)',
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    maxHeight: '100%',
    position: 'fixed',
    zIndex: 1400,
    height: 'auto',
    width: 'auto',
    // container itself is invisible and should not block clicks, clicks should be passed to its children
    pointerEvents: 'none',
    maxWidth: 'calc(100% - 4rem)',
  },
  toastContainer: {
    pointerEvents: 'all',
    marginBottom: spacingRem.s,
  },
}, 'toaster');

const mapToBannerVariant = (variant: 'success' | 'warning' | 'error' | 'info'): BannerVariant => {
  switch (variant) {
    case 'success':
      return BannerVariant.success;
    case 'warning':
      return BannerVariant.warning;
    case 'error':
      return BannerVariant.danger;
    case 'info':
      return BannerVariant.info;
  }
};

interface ToastState {
  id: string,
  message: string,
  variant: BannerVariant,
  actions: { key: string, icon: IconName, tooltip: string, onClick: () => void }[],
  closeable: boolean,
  timeout?: number,
  icon?: { icon?: IconName, iconColor?: string, tooltip?: string },
}

const Toaster: FunctionComponent = () => {
  const classes = useStyles();
  const forceUpdate = useForceUpdate();
  const toastsRef = useRef<ToastState[]>([]);

  const closeSnackbar = useCallback<(toastId: string) => void>((toastId) => {
    const index = toastsRef.current.findIndex(({ id }) => id === toastId);
    if (index !== -1) {
      const toast = toastsRef.current[index];
      toastsRef.current.splice(index, 1);
      clearTimeout(toast.timeout);
      forceUpdate();
    }
  }, [forceUpdate]);

  const doToast = useCallback<(event: ToastEvent) => void>(({ variant, message, options: { persist = false, closeable = true, actions = [], onToast, icon } }) => {
    let toastId: string;
    const existingToast = toastsRef.current.find(({ message: toastMessage }) => message === toastMessage);
    if (existingToast) {
      toastId = existingToast.id;
    } else {
      toastId = uuid();
      toastsRef.current.push({
        id: toastId,
        message,
        variant: mapToBannerVariant(variant),
        actions,
        closeable,
        timeout: persist ? undefined : window.setTimeout(() => closeSnackbar(toastId), 5_000),
        icon,
      });
      forceUpdate();
    }

    if (onToast) {
      onToast(toastId);
    }
  }, [closeSnackbar, forceUpdate]);

  useToastBus(doToast);
  useClearToastBus(closeSnackbar);

  if (toastsRef.current.length === 0) {
    return null;
  }

  return (
    <motion.div
      className={classes.toaster}
      initial={{ top: '1rem' }}
      animate={{ top: '1.4rem' }}
      transition={{ delay: 0, duration: 0.3 }}
    >
      {toastsRef.current.map(({ id, message, variant, closeable, actions, icon }) => {
        const bannerActions: ComponentProps<typeof Banner>['actions'] = [...actions];
        if (closeable) {
          bannerActions.push({ key: 'close', icon: IconName.close, tooltip: i18n`Close`, onClick: () => closeSnackbar(id) });
        }

        return (
          <motion.div
            key={id}
            className={classes.toastContainer}
            initial={{ y: '1rem' }}
            animate={{ y: 0 }}
            transition={{ ease: [0, 0, 0.2, 1], delay: 0, duration: 0.25 }}
          >
            <Banner variant={variant} title={message} actions={bannerActions} icon={icon} withShadow />
          </motion.div>
        );
      })}
    </motion.div>
  );
};

export default Toaster;
