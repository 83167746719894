import { Reference } from '../../referenceModule/ids';
import { Resource } from '../../resourceModule/ids';
import {
  CollaborationField,
  Concept_CollaborationField,
  Concept_CreatedAt,
  Concept_FunctionalId,
  Concept_Name,
  Concept_Resource,
  Concept_StakeholdersGroups,
  Concept_StakeholdersRoles,
  Concept_StakeholdersUsers,
  Concept_UpdatedAt,
  ConceptRole,
  Field_ApiAlias,
  Field_IsCore,
  Field_Title,
  FieldDimension,
  FieldDimension_Field,
  Group,
  IdField,
  KinshipRelation,
  KinshipRelationField,
  RelationMultipleField,
  RelationMultipleField_ReverseField,
  RelationMultipleField_TargetType,
  RelationSingleField,
  RelationSingleField_TargetType,
  StakeholdersField,
  StakeholdersField_TargetType,
  TextField,
  TimeStampField,
  User,
} from '../ids';
import { registerModel } from '../module';

const { instance } = registerModel;

instance({ typeId: KinshipRelationField, label: 'KinshipRelation', extraProperties: { [Field_Title]: 'Embedded by', [Field_ApiAlias]: 'embedded-by', [Field_IsCore]: true } });
instance({
  typeId: FieldDimension,
  label: 'KinshipRelationDimension',
  extraProperties: {
    [FieldDimension_Field]: KinshipRelation,
  },
});

instance({ typeId: TextField, label: 'Concept_Name', extraProperties: { [Field_Title]: 'Name', [Field_ApiAlias]: 'name', [Field_IsCore]: true } });
instance({
  typeId: FieldDimension,
  label: 'ConceptNameDimension',
  extraProperties: {
    [FieldDimension_Field]: Concept_Name,
  },
});
instance({ typeId: IdField, label: 'Concept_FunctionalId', extraProperties: { [Field_Title]: 'ID', [Field_ApiAlias]: 'functional-id', [Field_IsCore]: true } });
instance({
  typeId: FieldDimension,
  label: 'ConceptFunctionalIdDimension',
  extraProperties: {
    [FieldDimension_Field]: Concept_FunctionalId,
  },
});

instance({ typeId: CollaborationField, label: 'Concept_CollaborationField', extraProperties: { [Field_Title]: 'Collaboration', [Field_IsCore]: true } });
instance({
  typeId: FieldDimension,
  label: 'ConceptCollaborationFieldDimension',
  extraProperties: {
    [FieldDimension_Field]: Concept_CollaborationField,
  },
});

instance({ typeId: TimeStampField, label: 'Concept_CreatedAt', extraProperties: { [Field_Title]: 'Created at', [Field_ApiAlias]: 'created-at', [Field_IsCore]: true } });
instance({
  typeId: FieldDimension,
  label: 'ConceptCreatedAtDimension',
  extraProperties: {
    [FieldDimension_Field]: Concept_CreatedAt,
  },
});
instance({ typeId: TimeStampField, label: 'Concept_UpdatedAt', extraProperties: { [Field_Title]: 'Updated at', [Field_ApiAlias]: 'updated-at', [Field_IsCore]: true } });
instance({
  typeId: FieldDimension,
  label: 'ConceptUpdatedAtDimension',
  extraProperties: {
    [FieldDimension_Field]: Concept_UpdatedAt,
  },
});

instance({
  typeId: RelationSingleField,
  label: 'Concept_Resource',
  extraProperties: { [Field_Title]: 'Resource', [Field_ApiAlias]: 'resource', [RelationSingleField_TargetType]: Resource, [Field_IsCore]: true },
});
instance({
  typeId: FieldDimension,
  label: 'ConceptResourceDimension',
  extraProperties: {
    [FieldDimension_Field]: Concept_Resource,
  },
});
instance({
  typeId: RelationMultipleField,
  label: 'Resource_Concepts',
  extraProperties: {
    [Field_Title]: 'References',
    [Field_ApiAlias]: 'references',
    [RelationMultipleField_ReverseField]: Concept_Resource,
    [RelationMultipleField_TargetType]: Reference,
    [Field_IsCore]: true,
  },
});

instance({
  typeId: StakeholdersField,
  label: 'Concept_StakeholdersRoles',
  extraProperties: {
    [Field_Title]: 'Roles',
    [Field_ApiAlias]: 'roles',
    [Field_IsCore]: true,
    [StakeholdersField_TargetType]: ConceptRole,
  },
});
instance({
  typeId: StakeholdersField,
  label: 'Concept_StakeholdersUsers',
  extraProperties: {
    [Field_Title]: 'Users',
    [Field_ApiAlias]: 'users',
    [Field_IsCore]: true,
    [StakeholdersField_TargetType]: User,
  },
});
instance({
  typeId: StakeholdersField,
  label: 'Concept_StakeholdersGroups',
  extraProperties: {
    [Field_Title]: 'Groups',
    [Field_ApiAlias]: 'groups',
    [Field_IsCore]: true,
    [StakeholdersField_TargetType]: Group,
  },
});

instance({
  typeId: FieldDimension,
  label: 'ConceptStakeholdersRolesDimension',
  extraProperties: {
    [FieldDimension_Field]: Concept_StakeholdersRoles,
  },
});
instance({
  typeId: FieldDimension,
  label: 'ConceptStakeholdersUsersDimension',
  extraProperties: {
    [FieldDimension_Field]: Concept_StakeholdersUsers,
  },
});
instance({
  typeId: FieldDimension,
  label: 'ConceptStakeholdersGroupsDimension',
  extraProperties: {
    [FieldDimension_Field]: Concept_StakeholdersGroups,
  },
});
