import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { ConceptChipDisplayRaw, ConceptDefinitionRaw, ConceptDefinitionStoreObject } from 'yooi-modules/modules/conceptModule';
import { getConceptDefinitionValidFields, isConceptDefinitionField } from 'yooi-modules/modules/conceptModule';
import {
  ConceptChipDisplay,
  ConceptChipDisplay_Rank,
  ConceptChipDisplay_Role_ConceptDefinition,
  ConceptChipDisplay_Role_Field,
  ConceptDefinition_ChipBackgroundColor,
  ConceptDefinition_ChipDisplayIcon,
  ConceptDefinition_ChipIconField,
  ConceptDefinition_Color,
  ConceptDefinition_Icon,
  ConceptDefinition_Name,
  DateField,
  ExternalKeyField,
  Field_Title,
  IconField,
  IdField,
  NumberField,
  RelationSingleField,
  TextField,
  WorkflowField,
} from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { DecoratedList } from 'yooi-utils';
import { ButtonVariant } from '../../../../../components/atoms/Button';
import Checkbox from '../../../../../components/atoms/Checkbox';
import { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import Chip from '../../../../../components/molecules/Chip';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import TableCell, { TableCellAlign } from '../../../../../components/molecules/TableCell';
import TableLine from '../../../../../components/molecules/TableLine';
import BlockContent from '../../../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../../../components/templates/BlockTitle';
import DataTable from '../../../../../components/templates/DataTable';
import HorizontalBlock from '../../../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../../../components/templates/VerticalBlock';
import useStore from '../../../../../store/useStore';
import i18n from '../../../../../utils/i18n';
import { formatOrUndef } from '../../../../../utils/stringUtils';
import useTheme from '../../../../../utils/useTheme';
import {
  chipDefaultBackgroundColor,
  computeBorderColor,
  conceptDefinitionChipBackgroundColor,
  isFieldAvailableForColoration,
  resolveConceptDefinitionColorValue,
} from '../../../../_global/conceptDisplayUtils';
import { getConceptDefinitionChipFields } from '../../../../_global/conceptUtils';
import { getFieldChip, getFieldLabel } from '../../../../_global/fieldUtils';
import StoreColorPickerInput from '../../../../_global/input/StoreColorPickerInput';
import StoreIconPicker from '../../../../_global/input/StoreIconPicker';
import { defaultOptionComparator } from '../../../../_global/modelTypeUtils';
import { getColorPalette } from '../../../../utils/standardColorsUtils';

interface ChipTabProps {
  conceptDefinitionId: string,
}

const ChipTab: FunctionComponent<ChipTabProps> = ({ conceptDefinitionId }) => {
  const theme = useTheme();

  const store = useStore();

  const conceptDefinition = store.getObject<ConceptDefinitionStoreObject>(conceptDefinitionId);

  const [showLine, setShowLine] = useState(false);

  const moveField = (fieldId: string, rank: string) => {
    store.withAssociation(ConceptChipDisplay)
      .withRole(ConceptChipDisplay_Role_ConceptDefinition, conceptDefinitionId)
      .withRole(ConceptChipDisplay_Role_Field, fieldId)
      .updateObject<ConceptChipDisplayRaw>({ [ConceptChipDisplay_Rank]: rank });
  };

  const fields: DecoratedList<{ id: string, rank: string }> = getConceptDefinitionChipFields(store, conceptDefinition);
  const moveFieldUp = (fieldId: string) => {
    const fieldToInsertBefore = fields[fields.findIndex(({ item }) => item.id === fieldId) - 1];
    if (fieldToInsertBefore) {
      moveField(fieldId, fieldToInsertBefore.insertBeforeRank());
    }
  };
  const moveFieldDown = (fieldId: string) => {
    const fieldToInsertAfter = fields[fields.findIndex(({ item }) => item.id === fieldId) + 1];
    if (fieldToInsertAfter) {
      moveField(fieldId, fieldToInsertAfter.insertAfterRank());
    }
  };

  const getSelectableFields = () => getConceptDefinitionValidFields(store, conceptDefinitionId)
    .filter((field) => !fields.some(({ item: { id } }) => id === field.id));

  const colorAndEmbeddedColorFields = () => getSelectableFields()
    .filter(({ id }) => isFieldAvailableForColoration(store, conceptDefinitionId, id))
    .map(({ id }) => getFieldChip(store, conceptDefinitionId, id))
    .sort(defaultOptionComparator);

  const forChipFields = () => getSelectableFields()
    .filter((field) => [TextField, NumberField, IdField, ExternalKeyField, RelationSingleField, WorkflowField, DateField].includes(field[Instance_Of]))
    .map(({ id }) => getFieldChip(store, conceptDefinitionId, id))
    .sort(defaultOptionComparator);

  const fieldList = fields.map((field) => field.item);

  const selectedColorFieldId = conceptDefinition.navigateOrNull(ConceptDefinition_Color)?.id;
  const selectedIconFieldId = conceptDefinition.navigateOrNull(ConceptDefinition_ChipIconField)?.id;

  return (
    <VerticalBlock>
      <BlockTitle
        title={i18n`Chip display`}
        subtitle={i18n`This concept and its instances can be represented as a chip in various contexts. You can customize its content and color below.`}
      />
      <BlockTitle title={i18n`Concept chip`} variant={BlockTitleVariant.secondary} />
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Preview`} />
        <BlockContent padded>
          <SpacingLine>
            <Chip
              text={formatOrUndef(conceptDefinition[ConceptDefinition_Name])}
              icon={conceptDefinition[ConceptDefinition_Icon] as IconName}
              color={conceptDefinitionChipBackgroundColor}
              borderColor={computeBorderColor(conceptDefinition[ConceptDefinition_ChipBackgroundColor] ?? conceptDefinitionChipBackgroundColor)}
              borderStyle="solid"
            />
          </SpacingLine>
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Concept icon`} />
        <BlockContent>
          <StoreIconPicker
            initialValue={conceptDefinition[ConceptDefinition_Icon] as IconName}
            onSubmit={(newIcon) => store.updateObject<ConceptDefinitionRaw>(conceptDefinitionId, { [ConceptDefinition_Icon]: newIcon ?? IconName.category })}
            clearMode={{ type: 'reset', value: IconName.category }}
          />
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Background color`} />
        <BlockContent>
          <StoreColorPickerInput
            defaultValue={chipDefaultBackgroundColor}
            initialValue={conceptDefinition[ConceptDefinition_ChipBackgroundColor]}
            onSubmit={(value) => {
              store.updateObject<ConceptDefinitionRaw>(conceptDefinitionId, { [ConceptDefinition_ChipBackgroundColor]: value || chipDefaultBackgroundColor });
            }}
            colorPalette={getColorPalette(store)}
          />
        </BlockContent>
      </HorizontalBlock>

      <BlockTitle title={i18n`Instance chip`} variant={BlockTitleVariant.secondary} />
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Preview`} />
        <BlockContent padded>
          <SpacingLine>
            <Chip
              text={fields.map((field) => getFieldLabel(store, store.getObject(field.item.id))).join(' - ')}
              icon={conceptDefinition[ConceptDefinition_ChipDisplayIcon] ? conceptDefinition[ConceptDefinition_Icon] as IconName | undefined : undefined}
              color={conceptDefinition[ConceptDefinition_ChipBackgroundColor] ?? chipDefaultBackgroundColor}
              squareColor={
                selectedColorFieldId && store.getObjectOrNull(selectedColorFieldId) !== null
                  ? resolveConceptDefinitionColorValue(store, conceptDefinitionId) ?? theme.color.border.default
                  : undefined
              }
              startIcons={selectedIconFieldId !== undefined ? [{ key: 'iconField', icon: IconName.token, colorVariant: IconColorVariant.primary }] : undefined}
            />
          </SpacingLine>
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Display concept icon`} />
        <BlockContent padded>
          <SpacingLine>
            <Checkbox
              checked={conceptDefinition[ConceptDefinition_ChipDisplayIcon] ?? false}
              onChange={(value) => {
                store.updateObject<ConceptDefinitionRaw>(conceptDefinitionId, { [ConceptDefinition_ChipDisplayIcon]: value });
              }}
            />
          </SpacingLine>
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle
          title={i18n`Default field for dot color`}
          infoTooltip={i18n`Field used to color the dot in the instance chip. It allows to differentiate the chips of different instances of a same concept depending on the value of the color field. (Example: In matrix and timeline)`}
        />
        <BlockContent>
          <SearchAndSelect
            clearable
            placeholder={i18n`Select field`}
            selectedOption={
              selectedColorFieldId && store.getObjectOrNull(selectedColorFieldId) !== null
                ? getFieldChip(store, conceptDefinitionId, selectedColorFieldId)
                : undefined
            }
            computeOptions={() => colorAndEmbeddedColorFields().map(({ id }) => getFieldChip(store, conceptDefinitionId, id))}
            onSelect={(value) => store.updateObject<ConceptDefinitionRaw>(conceptDefinitionId, { [ConceptDefinition_Color]: value?.id ?? null })}
            statusIcon={
              !selectedColorFieldId || isConceptDefinitionField(store, selectedColorFieldId, conceptDefinitionId)
                ? undefined
                : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
            }
          />
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Icon field`} />
        <BlockContent>
          <SearchAndSelect
            clearable
            placeholder={i18n`Select field`}
            selectedOption={
              selectedIconFieldId && store.getObjectOrNull(selectedIconFieldId) !== null
                ? getFieldChip(store, conceptDefinitionId, selectedIconFieldId)
                : undefined
            }
            computeOptions={() => getSelectableFields()
              .filter((field) => [IconField, RelationSingleField, WorkflowField].includes(field[Instance_Of]))
              .map(({ id }) => getFieldChip(store, conceptDefinitionId, id))
              .sort(defaultOptionComparator)}
            onSelect={(value) => store.updateObject<ConceptDefinitionRaw>(conceptDefinitionId, { [ConceptDefinition_ChipIconField]: value?.id ?? null })}
            statusIcon={
              !selectedIconFieldId || isConceptDefinitionField(store, selectedIconFieldId, conceptDefinitionId)
                ? undefined
                : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
            }
          />
        </BlockContent>
      </HorizontalBlock>
      <DataTable
        columnsDefinition={[
          {
            name: i18n`Name`,
            width: 100,
            propertyId: Field_Title,
            cellRender: ({ id }) => (
              <SearchAndSelect
                readOnly
                selectedOption={getFieldChip(store, conceptDefinitionId, id)}
                statusIcon={
                  isConceptDefinitionField(store, id, conceptDefinitionId)
                    ? undefined
                    : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
                }
              />
            ),
          },
          {
            name: '',
            propertyId: 'move-up',
            action: true,
            align: TableCellAlign.center,
            cellRender: ({ id }) => (
              <IconOnlyButton
                onClick={() => moveFieldUp(id)}
                disabled={fieldList.findIndex((f) => f.id === id) === 0}
                iconName={IconName.expand_less}
                tooltip={i18n`Move Up`}
                variant={IconOnlyButtonVariants.tertiary}
              />
            ),
          },
          {
            name: '',
            propertyId: 'move-down',
            action: true,
            align: TableCellAlign.center,
            cellRender: ({ id }) => (
              <IconOnlyButton
                onClick={() => moveFieldDown(id)}
                disabled={fieldList.findIndex((f) => f.id === id) === fieldList.length - 1}
                iconName={IconName.expand_more}
                tooltip={i18n`Move Down`}
                variant={IconOnlyButtonVariants.tertiary}
              />
            ),
          },
        ]}
        linesActions={({ id }) => [
          {
            key: 'unlink',
            name: i18n`Unlink field`,
            icon: IconName.delete,
            danger: true,
            onClick: () => {
              store.withAssociation(ConceptChipDisplay)
                .withRole(ConceptChipDisplay_Role_ConceptDefinition, conceptDefinitionId)
                .withRole(ConceptChipDisplay_Role_Field, id)
                .deleteObject();
            },
          },
        ]}
        list={fieldList.map((item) => ({ key: item.id, type: 'item', item, color: undefined }))}
        inlineCreation={{
          render: showLine ? (
            <TableLine>
              <TableCell>
                <SearchAndSelect
                  computeOptions={forChipFields}
                  editOnMount
                  onSelect={(value) => {
                    if (value) {
                      store.withAssociation(ConceptChipDisplay)
                        .withRole(ConceptChipDisplay_Role_ConceptDefinition, conceptDefinitionId)
                        .withRole(ConceptChipDisplay_Role_Field, value.id)
                        .updateObject<ConceptChipDisplayRaw>({ [ConceptChipDisplay_Rank]: fields.insertAfterLastItemRank() });
                      setShowLine(false);
                    }
                  }}
                  onEscape={() => setShowLine(false)}
                />
              </TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
            </TableLine>
          ) : null,
        }}
        onNewItem={() => setShowLine(true)}
        newItemIcon={IconName.add}
        newItemTitle={i18n`Add`}
        newItemButtonVariant={ButtonVariant.tertiary}
        handleClickAway={() => setShowLine(false)}
      />
    </VerticalBlock>
  );
};

export default ChipTab;
