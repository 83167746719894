import type { FunctionComponent } from 'react';
import { v4 as uuid } from 'uuid';
import { IFrameField, TextConstantField } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { isFieldStep, isFilterStep, isGlobalDimensionStep, isMappingStep, isMultiplePath } from 'yooi-modules/modules/conceptModule';
import { GraphChartField, RadarChartField, ViewsField } from 'yooi-modules/modules/dashboardModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { Direction, joinObjects, moveElementInArray } from 'yooi-utils';
import { ButtonVariant } from '../../../../components/atoms/Button';
import { IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import DataTable from '../../../../components/templates/DataTable';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import useNewLineFocus from '../../../../utils/useNewLineFocus';
import MappingComposite from '../../path/MappingComposite';
import PathStepsInput from '../../path/PathStepsInput';
import { createPathConfigurationHandler, StepValidationState } from '../../pathConfigurationHandler';
import DisplayOptionsCell from './DisplayOptionsCell';

type CardsHeader = { id: string, path: PathStep[], displayOptions?: Record<string, unknown> }[];

interface CardsHeaderFieldsDataTableProps {
  header: CardsHeader,
  onChange: (updateFunction: ((oldHeader: CardsHeader) => CardsHeader)) => void,
  parameterDefinitions: SingleParameterDefinition[],
  suggestedBasePaths?: { label: string, path: PathStep[] }[],
  readOnly?: boolean,
}

const CardsHeaderFieldsDataTable: FunctionComponent<CardsHeaderFieldsDataTableProps> = ({
  header,
  onChange,
  parameterDefinitions,
  suggestedBasePaths,
  readOnly,
}) => {
  const store = useStore();

  const [headerNewLineFocusRef, setHeaderNewLineFocus] = useNewLineFocus();

  const contentPathHandler = createPathConfigurationHandler(
    store,
    parameterDefinitions,
    [
      ({ pathStep, path: vPath }) => {
        if (isFieldStep(pathStep)) {
          const fieldDefinitionId = store.getObjectOrNull(pathStep.fieldId)?.[Instance_Of] as string | undefined;
          if (!fieldDefinitionId) {
            return [{ state: StepValidationState.invalid, reasonMessage: i18n`Input end with an unknown field.` }];
          } else if (![GraphChartField, IFrameField, RadarChartField, TextConstantField, ViewsField].includes(fieldDefinitionId)) {
            if (isMultiplePath(store, vPath.slice(0, vPath.length - 1))) {
              return [{
                state: StepValidationState.invalid,
                reasonMessage: i18n`Input should be unique, use a mapping in your path.`,
              }];
            } else {
              return [{ state: StepValidationState.valid }];
            }
          } else {
            return [{ state: StepValidationState.invalid, reasonMessage: i18n`Input end with an unauthorized field.` }];
          }
        } else if (isMappingStep(pathStep) && vPath.length === 2) {
          return [{ state: StepValidationState.valid }];
        } else if (isFilterStep(pathStep) && isMultiplePath(store, vPath)) {
          return [{ state: StepValidationState.invalid, reasonMessage: i18n`Input should be unique, use a mapping in your path.` }];
        } else {
          return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`Input end with an unauthorized element.` }];
        }
      },
    ]
  );

  return (
    <DataTable
      list={header.map((item) => ({ key: item.id, type: 'item', item, color: undefined }))}
      columnsDefinition={[
        {
          propertyId: 'path',
          name: i18n`Field path`,
          width: 60,
          focusable: true,
          cellRender: ({ id, path }, focusOnMount) => (
            <PathStepsInput
              initialPath={path}
              onSubmit={(newPath) => {
                onChange((oldHeader) => oldHeader.map((h) => (h.id === id ? joinObjects(h, { path: newPath }) : h)));
              }}
              parameterDefinitions={parameterDefinitions}
              valuePathHandler={contentPathHandler}
              suggestedBasePaths={suggestedBasePaths}
              readOnly={readOnly}
              focusOnMount={focusOnMount}
            />
          ),
        },
        {
          propertyId: 'mappings',
          name: i18n`Mappings`,
          width: 20,
          cellRender: ({ id, path }) => (
            path.length >= 2 && isGlobalDimensionStep(path[0]) && isFieldStep(path[1])
              ? (
                <MappingComposite
                  path={path}
                  parameterDefinitions={parameterDefinitions}
                  onChange={(newPath) => {
                    onChange((oldHeader) => oldHeader.map((h) => (h.id === id ? joinObjects(h, { path: newPath }) : h)));
                  }}
                  readOnly={readOnly}
                />
              )
              : null
          ),
        },
        {
          propertyId: 'displayOptions',
          name: i18n`Field display`,
          width: 20,
          cellRender: ({ id, path, displayOptions }) => {
            const lastStep = path.at(-1);
            if (isFieldStep(lastStep)) {
              return (
                <DisplayOptionsCell
                  fieldId={lastStep.fieldId}
                  parameterDefinitions={parameterDefinitions}
                  initialState={displayOptions ?? {}}
                  onSubmit={(newDisplayOptions) => {
                    onChange((oldHeader) => oldHeader.map((h) => (h.id === id ? joinObjects(h, { displayOptions: newDisplayOptions }) : h)));
                  }}
                />
              );
            } else {
              return null;
            }
          },
        },
        {
          propertyId: 'moveUp',
          action: true,
          cellRender: (_, __, index) => (
            <IconOnlyButton
              disabled={readOnly || index === 0}
              onClick={() => {
                onChange((oldHeader) => moveElementInArray(Direction.up, index, oldHeader));
              }}
              iconName={IconName.expand_less}
              tooltip={i18n`Move Up`}
              variant={IconOnlyButtonVariants.tertiary}
            />
          ),
        },
        {
          propertyId: 'moveDown',
          action: true,
          cellRender: (_, __, index) => (
            <IconOnlyButton
              disabled={readOnly || index === header.length - 1}
              onClick={() => {
                onChange((oldHeader) => moveElementInArray(Direction.down, index, oldHeader));
              }}
              iconName={IconName.expand_more}
              tooltip={i18n`Move Down`}
              variant={IconOnlyButtonVariants.tertiary}
            />
          ),
        },
        {
          propertyId: 'delete',
          action: true,
          cellRender: ({ id }) => (
            <SpacingLine>
              <IconOnlyButton
                disabled={readOnly}
                onClick={() => onChange((oldHeader) => oldHeader.filter((h) => h.id !== id))}
                iconName={IconName.delete}
                tooltip={i18n`Delete`}
                variant={IconOnlyButtonVariants.danger}
              />
            </SpacingLine>
          ),
        },
      ]}
      newItemIcon={IconName.add}
      newItemTitle={i18n`Add`}
      newItemButtonVariant={ButtonVariant.tertiary}
      newLineFocus={headerNewLineFocusRef.current}
      onNewItem={readOnly ? undefined : () => {
        const newId = uuid();
        onChange((oldHeader) => [...oldHeader, { id: newId, path: [], displayOptions: {} }]);
        setHeaderNewLineFocus(newId);
      }}
      fullWidth
    />
  );
};

export default CardsHeaderFieldsDataTable;
