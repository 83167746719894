import type {
  AssociationFieldStoreObject,
  FieldStoreObject,
  FilterFunction,
  NumberColorStepValue,
  ParametersMapping,
  RelationMultipleFieldStoreObject,
  RelationSingleFieldStoreObject,
} from 'yooi-modules/modules/conceptModule';
import {
  createValuePathResolver,
  dimensionsMappingToParametersMapping,
  getAllFieldDimensionsIds,
  getFieldDimensionOfModelType,
  getFieldUtilsHandler,
  getFilterFunction,
  getReverseField,
  hasPlatformCapability,
  isMultiValueResolution,
  isRelationalType,
  isSingleValueResolution,
  NumberColorStepValueType,
  numberFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import {
  AssociationField,
  AssociationField_Field_TargetFilter,
  Field_Formula,
  Field_Title,
  PlatformCapabilityAdmin,
  RelationMultipleField,
  RelationMultipleField_Field_TargetFilter,
  RelationSingleField,
  RelationSingleField_Field_TargetFilter,
  WorkflowField,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import { filterNullOrUndefined, joinObjects, newError } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../components/atoms/Icon';
import type { TableSortDirection } from '../../components/molecules/Table';
import type { FrontObjectStore } from '../../store/useStore';
import base from '../../theme/base';
import i18n from '../../utils/i18n';
import { formatOrUndef } from '../../utils/stringUtils';
import type { NavigationPayload, UseNavigation } from '../../utils/useNavigation';
import { getFieldConfigurationHandler, getFieldHandler } from './fields/FieldLibrary';
import { countValidFilters } from './filter/filterUtils';
import type { ComparatorHandler } from './useFilterAndSort';

export enum TickResolutionStatus {
  Resolved = 'Resolved',
  Error = 'Error',
}

interface TickError {
  status: TickResolutionStatus.Error,
  error: string,
}

export interface TickResolved {
  status: TickResolutionStatus.Resolved,
  value: number,
  color: string | undefined,
}

export interface Chip<T extends string = string> {
  id: T,
  icon?: IconName | { name: IconName, colorVariant: IconColorVariant } | { name: IconName, color: string },
  isLabelUndefined?: boolean,
  label: string,
  tooltip?: string,
  color?: string,
  getNavigationPayload?: (navigation: UseNavigation) => NavigationPayload,
  startIcons?: { key: string, icon: IconName, colorVariant?: IconColorVariant, color?: string, tooltip?: string }[],
  endIcons?: { key: string, icon: IconName, colorVariant?: IconColorVariant, color?: string, tooltip?: string }[],
}

export const getFieldLabel = (store: ObjectStoreWithTimeseries, field: FieldStoreObject): string => {
  const fieldTitle = field[Field_Title];
  if (
    (fieldTitle === undefined || fieldTitle === '')
    && isRelationalType(field[Instance_Of])
    && field[Instance_Of] !== WorkflowField
    && (field[Instance_Of] !== AssociationField || field[Field_Formula] === undefined)
  ) {
    return i18n`Reverse of "${formatOrUndef(getReverseField(store, field.id, undefined)?.[Field_Title])}"`;
  } else {
    return formatOrUndef(fieldTitle);
  }
};

export const isFilteredField = (store: FrontObjectStore, fieldId: string): boolean => {
  const field = store.getObjectOrNull(fieldId);
  return !!field
    && ((isInstanceOf<AssociationFieldStoreObject>(field, AssociationField) && countValidFilters(store, field[AssociationField_Field_TargetFilter]) > 0)
      || (isInstanceOf<RelationSingleFieldStoreObject>(field, RelationSingleField) && countValidFilters(store, field[RelationSingleField_Field_TargetFilter]) > 0)
      || (isInstanceOf<RelationMultipleFieldStoreObject>(field, RelationMultipleField) && countValidFilters(store, field[RelationMultipleField_Field_TargetFilter]) > 0));
};

export const getFieldChip = (store: FrontObjectStore, conceptDefinitionId: string, fieldId: string): Chip => {
  const fieldHandler = getFieldConfigurationHandler(store, fieldId);
  const field = store.getObjectOrNull<FieldStoreObject>(fieldId);
  if (!fieldHandler || !field) {
    throw newError('Invalid field id', { fieldId });
  }
  const fieldUtilsHandler = getFieldUtilsHandler(store, fieldId);
  const configuration = fieldUtilsHandler.resolveConfiguration();

  const rawLabel = fieldHandler.getTitle();
  const label = getFieldLabel(store, field);

  return {
    id: fieldId,
    icon: fieldHandler.getIcon(),
    isLabelUndefined: !rawLabel,
    label,
    tooltip: `${label} (${(fieldHandler.getTypeLabel())})`,
    color: base.color.gray['300'],
    getNavigationPayload: hasPlatformCapability(store, store.getLoggedUserId(), PlatformCapabilityAdmin)
      ? () => ({ to: `/settings/organization/${conceptDefinitionId}/field/${fieldId}` }) : undefined,
    startIcons: [
      getAllFieldDimensionsIds(store, fieldId).length > 1
        ? { key: 'multidimensional', icon: IconName.pivot_table_chart, colorVariant: IconColorVariant.info, tooltip: i18n`Multidimensional` } : undefined,
      isFilteredField(store, fieldId)
        ? { key: 'isFiltered', icon: IconName.filter_list, colorVariant: IconColorVariant.info, tooltip: i18n`Display filters applied on field.` } : undefined,
      configuration.formula
        ? { key: 'computed', icon: IconName.fx, colorVariant: IconColorVariant.info, tooltip: i18n`Computed` } : undefined,
    ]
      .filter(filterNullOrUndefined)
    ,
  };
};

export const getFieldColumnComparatorKey = (store: FrontObjectStore) => (
  (columnFieldId: string, direction: TableSortDirection): ComparatorHandler<{ key: string }, unknown> | undefined => {
    const comparatorHandler = getFieldHandler(store, columnFieldId)?.getComparatorHandler?.(direction);
    if (comparatorHandler) {
      return {
        comparator: comparatorHandler.comparator,
        extractValue: ({ key }) => {
          const instance = store.getObjectOrNull(key);
          if (comparatorHandler.extractValue && instance) {
            const dimensionId = getFieldDimensionOfModelType(store, columnFieldId, instance[Instance_Of] as string);
            return comparatorHandler.extractValue?.(dimensionId ? { [dimensionId]: instance.id } : {});
          } else {
            return undefined;
          }
        },
      };
    } else {
      return undefined;
    }
  }
);

export const getFieldColumnComparator = (store: FrontObjectStore) => (
  (columnFieldId: string, direction: TableSortDirection): ComparatorHandler<StoreObject, unknown> | undefined => {
    const comparatorHandler = getFieldHandler(store, columnFieldId)?.getComparatorHandler?.(direction);
    if (comparatorHandler) {
      return {
        comparator: comparatorHandler.comparator,
        extractValue: (instance) => {
          if (comparatorHandler.extractValue) {
            const dimensionId = getFieldDimensionOfModelType(store, columnFieldId, instance[Instance_Of] as string);
            return comparatorHandler.extractValue?.(dimensionId ? { [dimensionId]: instance.id } : {});
          } else {
            return undefined;
          }
        },
      };
    } else {
      return undefined;
    }
  }
);

export const resolveTickValue = (store: FrontObjectStore, parametersMapping: ParametersMapping, tick: NumberColorStepValue): TickResolved | TickError | undefined => {
  if (tick.value === undefined) {
    return undefined;
  }

  switch (tick.type) {
    case NumberColorStepValueType.field: {
      const pathResolution = createValuePathResolver(store, parametersMapping).resolvePathValue(tick.value);
      if (isMultiValueResolution(pathResolution)) {
        return { status: TickResolutionStatus.Error, error: i18n`Multiple value not supported` };
      } else if (isSingleValueResolution(pathResolution)) {
        if (typeof pathResolution.value === 'number') {
          return { status: TickResolutionStatus.Resolved, value: pathResolution.value, color: tick.color };
        } else {
          return { status: TickResolutionStatus.Error, error: i18n`Invalid value type` };
        }
      } else {
        return {
          status: TickResolutionStatus.Error,
          error: (pathResolution instanceof Error ? pathResolution.message : JSON.stringify(pathResolution)) ?? i18n`Unable to resolve value`,
        };
      }
    }
    case NumberColorStepValueType.value: {
      return { status: TickResolutionStatus.Resolved, value: tick.value, color: tick.color };
    }
    default:
      throw newError('Unsupported tick type');
  }
};

export const getInstanceMaxMinValues = (store: FrontObjectStore, fieldId: string, conceptId: string | undefined, parametersMapping: ParametersMapping): {
  min: { status: TickResolutionStatus.Resolved, value: number, color: string | undefined } | { status: TickResolutionStatus.Error, error: string } | undefined,
  max: { status: TickResolutionStatus.Resolved, value: number, color: string | undefined } | { status: TickResolutionStatus.Error, error: string } | undefined,
} => {
  const conceptDefinitionId = conceptId ? store.getObject(conceptId)[Instance_Of] as string : undefined;
  const dimensionId = conceptDefinitionId ? getFieldDimensionOfModelType(store, fieldId, conceptDefinitionId) : undefined;
  const dimensionParameters = dimensionId ? { [dimensionId]: conceptId } : {};
  const resolutionParameters = joinObjects(parametersMapping, dimensionsMappingToParametersMapping(dimensionParameters));

  const fieldHandler = numberFieldHandler(store, fieldId);
  const numberField = conceptId && dimensionId ? fieldHandler.resolveConfigurationWithOverride({ [dimensionId]: conceptId }) : fieldHandler.resolveConfiguration();

  return {
    min: numberField.minValue ? resolveTickValue(store, resolutionParameters, numberField.minValue) : undefined,
    max: numberField.maxValue ? resolveTickValue(store, resolutionParameters, numberField.maxValue) : undefined,
  };
};

export const getFieldFilterFunction = (field: StoreObject<string>, store: FrontObjectStore): FilterFunction | undefined => {
  let fieldFilter: FilterFunction | undefined;
  switch (field[Instance_Of]) {
    case RelationSingleField:
      fieldFilter = field[RelationSingleField_Field_TargetFilter] ? getFilterFunction(store, field[RelationSingleField_Field_TargetFilter]) : undefined;
      break;
    case RelationMultipleField:
      fieldFilter = field[RelationMultipleField_Field_TargetFilter] ? getFilterFunction(store, field[RelationMultipleField_Field_TargetFilter]) : undefined;
      break;
    case AssociationField:
      fieldFilter = field[AssociationField_Field_TargetFilter] ? getFilterFunction(store, field[AssociationField_Field_TargetFilter]) : undefined;
      break;
  }
  return fieldFilter;
};
