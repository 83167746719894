import { v4 as uuid } from 'uuid';
import type { Migration, ObjectStoreReadOnly, StoreObject } from 'yooi-store';
import { joinObjects } from 'yooi-utils';
import {
  MigrationDashboardNotSetOption,
  MigrationDropUselessFieldLocalOverride,
  MigrationIconField,
  MigrationRemWidthInViews,
  MigrationSwimlaneViews,
  MigrationTimeseriesTableDimensionDisplay,
  MigrationUpdateMatrixView,
} from '../../src/Migrations';
import type { ConceptDefinitionStoreObject, FieldStoreObject, InstanceReferenceValue } from '../conceptModule';
import { CardColorMode, isMultipleRelationalType, PathStepType } from '../conceptModule';
import {
  AssociationField,
  AssociationField_Definition,
  AssociationField_SourceRole,
  AssociationFieldDefinition_Role1Type,
  AssociationFieldDefinition_Role2Type,
  Concept,
  ConceptDefinition_Color,
  ConceptDefinition_MainIconField,
  ConceptDefinition_MatrixAbs,
  ConceptDefinition_MatrixDependency,
  ConceptDefinition_MatrixLabel,
  ConceptDefinition_MatrixOrd,
  ConceptDefinition_SwimlaneColumnBy,
  ConceptDefinition_SwimlaneGroupBy,
  ConceptDefinition_SwimlaneProgress,
  EmbeddingField,
  EmbeddingField_FromType,
  EmbeddingField_ToType,
  Field_ApiAlias,
  Field_Formula,
  Field_IsCore,
  Field_Title,
  FieldDimension,
  FieldDimension_Field,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  IconField,
  KinshipRelationField,
  RelationMultipleField,
  RelationMultipleField_TargetType,
  RelationSingleField,
  RelationSingleField_TargetType,
  StakeholdersField,
  StakeholdersField_TargetType,
  Workflow_TargetedConceptDefinition,
  WorkflowField,
  WorkflowField_Workflow,
} from '../conceptModule/ids';
import type { Filters } from '../conceptModule/moduleType';
import type { InstanceReferenceType } from '../conceptModule/utils/filters/filters';
import type { TypeHelperRevision1 } from '../typeModule';
import { isInstanceOf } from '../typeModule';
import { Class_Instances, Instance_Of, TypeModuleId } from '../typeModule/ids';
import { Dashboard, Dashboard_Icon, DashboardIconDimension, DashboardParameter, DashboardParameter_EnableNotSetOption, ViewsField, ViewsField_Definition } from './ids';
import { ViewType } from './views/viewsDefinition';

const migrations: Record<number, Migration> = {
  [MigrationUpdateMatrixView]: {
    handler: ({ getObjectOrNull, getObject, updateObject }) => {
      interface GlobalDimensionStep {
        type: PathStepType.global,
      }

      interface DimensionStep {
        type: PathStepType.dimension,
        conceptDefinitionId: string,
      }

      interface FilterStep {
        type: PathStepType.filter,
      }

      interface MappingStep {
        type: PathStepType.mapping,
        mapping: { id: string, type: InstanceReferenceType },
      }

      interface FieldStep {
        type: PathStepType.field,
        fieldId: string,
        embeddingFieldId?: string,
      }

      type PathStep = GlobalDimensionStep | DimensionStep | FilterStep | MappingStep | FieldStep;

      interface OldMatrixViewStoredDefinition {
        type: ViewType.Matrix,
        id: string,
        label?: string,
        readOnly?: boolean,
        withCustomConfig?: boolean,
        defaultMatrixOrdId?: string,
        defaultMatrixAbsId?: string,
        defaultMatrixDependencyId?: string,
        defaultMatrixLabelId?: string,
        defaultMatrixColorId?: string,
      }

      interface NewMatrixViewStoredDefinition {
        type: ViewType.Matrix,
        id: string,
        label?: string,
        readOnly?: boolean,
        yAxisFieldId?: string,
        xAxisFieldId?: string,
        dependenciesFieldId?: string,
        labelFieldId?: string,
        colorFieldId?: string,
        topLeftQuadrant?: { color: string | undefined, name: string | undefined },
        topRightQuadrant?: { color: string | undefined, name: string | undefined },
        bottomLeftQuadrant?: { color: string | undefined, name: string | undefined },
        bottomRightQuadrant?: { color: string | undefined, name: string | undefined },
        showBacklog?: boolean,
      }

      interface ViewsFieldDefinition {
        data: { id: string, label?: string, display: { withNotSet?: boolean, withAll?: boolean }, path: PathStep[] }[],
        views: ({ type: Exclude<ViewType, ViewType.Matrix> } | OldMatrixViewStoredDefinition)[],
        hasFilters?: boolean,
      }

      const getPathReturnedConceptDefinitionId = (path: PathStep[]): string | undefined => {
        let returnTypeId: string | undefined;
        for (let i = 0; i < path.length; i += 1) {
          const nStep = path[i];
          if (nStep?.type === PathStepType.global) {
            // nothing is returned
          } else if (nStep?.type === PathStepType.dimension) {
            // a list of concept of dimension step conceptDefinition is returned
            returnTypeId = nStep.conceptDefinitionId;
          } else if (nStep?.type === PathStepType.mapping) {
            // only multiplicity change
          } else if (nStep?.type === PathStepType.field) {
            if (getObjectOrNull(nStep.fieldId) === null) {
              return undefined;
            }
            const stepField = getObject(nStep.fieldId);
            const previousStep = path[i - 1];
            if (previousStep && previousStep?.type === PathStepType.global) {
              // field
              returnTypeId = undefined;
            } else if (stepField[Instance_Of] as string === KinshipRelationField) {
              if (nStep.embeddingFieldId) {
                const embeddingField = getObjectOrNull(nStep.embeddingFieldId);
                if (embeddingField && embeddingField[EmbeddingField_FromType]) {
                  returnTypeId = embeddingField[EmbeddingField_FromType] as string;
                } else {
                  returnTypeId = undefined;
                }
              } else {
                // multiple returned types not supported
                returnTypeId = undefined;
              }
            } else if (stepField[Instance_Of] === KinshipRelationField) {
              returnTypeId = Concept;
            } else if (stepField[Instance_Of] === RelationSingleField) {
              returnTypeId = getObjectOrNull(stepField.id)?.navigateOrNull(RelationSingleField_TargetType)?.id;
            } else if (stepField[Instance_Of] === WorkflowField) {
              returnTypeId = getObjectOrNull(stepField.id)?.navigateOrNull(WorkflowField_Workflow)?.navigateOrNull(Workflow_TargetedConceptDefinition)?.id;
            } else if (stepField[Instance_Of] === AssociationField) {
              const field = getObjectOrNull(stepField.id);
              if (field === null) {
                returnTypeId = undefined;
              } else if (field[Field_Formula]) {
                returnTypeId = Concept;
              } else {
                const sourceRole = field[AssociationField_SourceRole];
                const targetRoleField = sourceRole === 1 ? AssociationFieldDefinition_Role2Type : AssociationFieldDefinition_Role1Type;
                const fieldDefinition = field.navigateOrNull(AssociationField_Definition);
                returnTypeId = fieldDefinition?.navigateOrNull(targetRoleField)?.id;
              }
            } else if (stepField[Instance_Of] === RelationMultipleField) {
              returnTypeId = getObjectOrNull(stepField.id)?.navigateOrNull(RelationMultipleField_TargetType)?.id;
            } else if (stepField[Instance_Of] === EmbeddingField) {
              returnTypeId = getObjectOrNull(stepField.id)?.navigateOrNull(EmbeddingField_ToType)?.id;
            } else if (stepField[Instance_Of] === StakeholdersField) {
              returnTypeId = getObjectOrNull(stepField.id)?.navigateOrNull(StakeholdersField_TargetType)?.id;
            } else {
              returnTypeId = undefined;
            }
          } else if (nStep?.type === PathStepType.filter) {
            // only multiplicity change
          }
        }
        return returnTypeId;
      };

      const updateViews = (views: ViewsFieldDefinition['views'], isMultidim: boolean, returnedConceptDefinition: StoreObject | undefined) => (
        views.map((oldView) => {
          if (oldView.type === ViewType.Matrix) {
            if (isMultidim || returnedConceptDefinition === undefined) {
              return {
                type: ViewType.Matrix,
                id: oldView.id,
                label: oldView.label,
                readOnly: oldView.readOnly,
              } satisfies NewMatrixViewStoredDefinition;
            } else if (oldView.withCustomConfig) {
              return {
                type: ViewType.Matrix,
                id: oldView.id,
                label: oldView.label,
                readOnly: oldView.readOnly,
                yAxisFieldId: oldView.defaultMatrixOrdId,
                xAxisFieldId: oldView.defaultMatrixAbsId,
                dependenciesFieldId: oldView.defaultMatrixDependencyId,
                labelFieldId: oldView.defaultMatrixLabelId,
                colorFieldId: oldView.defaultMatrixColorId,
                showBacklog: true,
              } satisfies NewMatrixViewStoredDefinition;
            } else {
              return {
                type: ViewType.Matrix,
                id: oldView.id,
                label: oldView.label,
                readOnly: oldView.readOnly,
                yAxisFieldId: typeof returnedConceptDefinition[ConceptDefinition_MatrixOrd] === 'string' ? returnedConceptDefinition[ConceptDefinition_MatrixOrd] : undefined,
                xAxisFieldId: typeof returnedConceptDefinition[ConceptDefinition_MatrixAbs] === 'string' ? returnedConceptDefinition[ConceptDefinition_MatrixAbs] : undefined,
                dependenciesFieldId: typeof returnedConceptDefinition[ConceptDefinition_MatrixDependency] === 'string' ? returnedConceptDefinition[ConceptDefinition_MatrixDependency] : undefined,
                labelFieldId: typeof returnedConceptDefinition[ConceptDefinition_MatrixLabel] === 'string' ? returnedConceptDefinition[ConceptDefinition_MatrixLabel] : undefined,
                colorFieldId: typeof returnedConceptDefinition[ConceptDefinition_Color] === 'string' ? returnedConceptDefinition[ConceptDefinition_Color] : undefined,
                showBacklog: true,
              } satisfies NewMatrixViewStoredDefinition;
            }
          } else {
            return oldView;
          }
        })
      );

      getObject(ViewsField)
        .navigateBack(Class_Instances)
        .filter(({ [ViewsField_Definition]: definition }) => (
          (definition as ViewsFieldDefinition | undefined)?.views.some(({ type }) => type === ViewType.Matrix)
          ?? false
        ))
        .forEach((viewsField) => {
          const definition = viewsField[ViewsField_Definition] as ViewsFieldDefinition;

          const dimensions = definition.data.filter(({ path }) => path.length > 0);
          const isMultidim = dimensions.length !== 1;

          const dimension = dimensions.at(0);
          const returnedConceptDefinitionId = dimension !== undefined ? getPathReturnedConceptDefinitionId(dimension.path) : undefined;
          const returnedConceptDefinition = returnedConceptDefinitionId !== undefined ? getObjectOrNull(returnedConceptDefinitionId) ?? undefined : undefined;

          const newDefinition = joinObjects(
            definition,
            { views: updateViews(definition.views, isMultidim, returnedConceptDefinition) }
          );

          updateObject(viewsField.id, { [ViewsField_Definition]: newDefinition });
        });
    },
  },
  [MigrationDropUselessFieldLocalOverride]: {
    handler: ({ deleteObject }) => {
      const FieldLocalOverride_CardFieldButton = '087168e5-6489-4b0a-a942-3b500909c60f';
      const FieldLocalOverride_CardFieldImage = 'e068f4e8-047d-4240-81e2-3ac52cec34e7';
      const FieldLocalOverride_CardFieldImageHeight = '76ae81d1-a799-47bb-bc81-8f6a0c06fa00';
      const FieldLocalOverride_CardFieldImageMode = '9594df1a-bb62-4adf-af55-e6c7343fe450';
      const FieldLocalOverride_CardFieldImagePositionX = 'a12cf675-c096-4391-a5d0-ad2d7f639ac8';
      const FieldLocalOverride_CardFieldImagePositionY = '53cdb938-08fe-4b13-91a2-3d0eaf3e9a5d';
      const FieldLocalOverride_CardFieldSubtitle = '7d5c30eb-be81-4e63-8f40-dc42ae7ce5be';
      const FieldLocalOverride_CardFieldText = '1dc56af8-a93d-4fad-be1b-41f202cce152';
      const FieldLocalOverride_GraphChartFieldConfiguration = 'e98620d4-3037-4bf9-a1b8-e315cf25b222';
      const FieldLocalOverride_MirrorFieldFieldDefinition = '847eeb55-d706-443b-9ba9-a1370edc28f4';
      const FieldLocalOverride_MirrorFieldPath = '4dad4416-85cc-47d0-a7a1-4695a7faa564';
      const FieldLocalOverride_RadarChartFieldConfiguration = '1496696c-10f5-46da-92c5-5cc9cc39df45';
      const FieldLocalOverride_RadarChartFieldModelType = 'ae01aa30-7676-49a8-b81b-bcb4b5e84a20';
      const FieldLocalOverride_ViewsFieldDefinition = '36a31b6d-7c11-4afb-888e-ae2526ae03c2';

      deleteObject(FieldLocalOverride_CardFieldButton);
      deleteObject(FieldLocalOverride_CardFieldImage);
      deleteObject(FieldLocalOverride_CardFieldImageHeight);
      deleteObject(FieldLocalOverride_CardFieldImageMode);
      deleteObject(FieldLocalOverride_CardFieldImagePositionX);
      deleteObject(FieldLocalOverride_CardFieldImagePositionY);
      deleteObject(FieldLocalOverride_CardFieldSubtitle);
      deleteObject(FieldLocalOverride_CardFieldText);
      deleteObject(FieldLocalOverride_GraphChartFieldConfiguration);
      deleteObject(FieldLocalOverride_MirrorFieldFieldDefinition);
      deleteObject(FieldLocalOverride_MirrorFieldPath);
      deleteObject(FieldLocalOverride_RadarChartFieldConfiguration);
      deleteObject(FieldLocalOverride_RadarChartFieldModelType);
      deleteObject(FieldLocalOverride_ViewsFieldDefinition);
    },
  },
  [MigrationDashboardNotSetOption]: {
    dependencies: [TypeModuleId],
    handler: ({ updateObject, getObject }, helpers) => {
      const typeHelper = helpers.typeHelper as TypeHelperRevision1;
      typeHelper.property(DashboardParameter, DashboardParameter_EnableNotSetOption);
      getObject(DashboardParameter)
        .navigateBack(Instance_Of)
        .forEach((parameter) => {
          updateObject(parameter.id, {
            [DashboardParameter_EnableNotSetOption]: true,
          });
        });
    },
  },
  [MigrationRemWidthInViews]: {
    handler: ({ getObject, updateObject }) => {
      interface OldViewsFieldDefinition {
        views: {
          type: string,
          series?: { displayOptions?: { width?: number | undefined, [key: string]: unknown }, [key: string]: unknown }[],
          [key: string]: unknown,
        }[],
        [key: string]: unknown,
      }

      interface NewViewsFieldDefinition {
        views: {
          type: string,
          series?: { displayOptions?: { width?: { type: 'percent' | 'rem', value: number } | undefined, [key: string]: unknown }, [key: string]: unknown }[],
          [key: string]: unknown,
        }[],
        [key: string]: unknown,
      }

      const migrateViews = (oldView: OldViewsFieldDefinition) => (
        joinObjects(
          oldView,
          {
            views: oldView.views.map((view) => (
              joinObjects(
                view,
                {
                  series: view?.series?.map((s) => (
                    joinObjects(
                      s,
                      {
                        displayOptions: s.displayOptions === undefined
                          ? undefined
                          : joinObjects(s.displayOptions, { width: s.displayOptions.width === undefined ? undefined : { type: 'percent', value: s.displayOptions.width } }),
                      }
                    )
                  )),
                }
              )
            )),
          }
        )
      ) as NewViewsFieldDefinition;

      getObject(ViewsField)
        .navigateBack(Class_Instances)
        .filter((field) => (field[ViewsField_Definition] as OldViewsFieldDefinition | undefined)?.views.some(({ series }) => series?.some(({ displayOptions }) => typeof displayOptions?.width === 'number')))
        .forEach((field) => {
          updateObject(field.id, { [ViewsField_Definition]: migrateViews(field[ViewsField_Definition] as OldViewsFieldDefinition) });
        });
    },
  },
  [MigrationSwimlaneViews]: {
    dependencies: [TypeModuleId],
    handler: (objectStore) => {
      interface GlobalDimensionStep {
        type: PathStepType.global,
      }

      interface DimensionStep {
        type: PathStepType.dimension,
        conceptDefinitionId: string,
      }

      interface FilterStep {
        type: PathStepType.filter,
      }

      interface MappingStep {
        type: PathStepType.mapping,
        mapping: { id: string, type: InstanceReferenceType },
      }

      interface FieldStep {
        type: PathStepType.field,
        fieldId: string,
        embeddingFieldId?: string,
      }

      type PathStep = GlobalDimensionStep | DimensionStep | FilterStep | MappingStep | FieldStep;

      const getPathReturnedConceptDefinitionId = (path: PathStep[]): string | undefined => {
        let returnTypeId: string | undefined;
        for (let i = 0; i < path.length; i += 1) {
          const nStep = path[i];
          if (nStep?.type === PathStepType.global) {
            // nothing is returned
          } else if (nStep?.type === PathStepType.dimension) {
            // a list of concept of dimension step conceptDefinition is returned
            returnTypeId = nStep.conceptDefinitionId;
          } else if (nStep?.type === PathStepType.mapping) {
            // only multiplicity change
          } else if (nStep?.type === PathStepType.field) {
            if (objectStore.getObjectOrNull(nStep.fieldId) === null) {
              return undefined;
            }
            const stepField = objectStore.getObject(nStep.fieldId);
            const previousStep = path[i - 1];
            if (previousStep && previousStep?.type === PathStepType.global) {
              // field
              returnTypeId = undefined;
            } else if (stepField[Instance_Of] as string === KinshipRelationField) {
              if (nStep.embeddingFieldId) {
                const embeddingField = objectStore.getObjectOrNull(nStep.embeddingFieldId);
                if (embeddingField && embeddingField[EmbeddingField_FromType]) {
                  returnTypeId = embeddingField[EmbeddingField_FromType] as string;
                } else {
                  returnTypeId = undefined;
                }
              } else {
                // multiple returned types not supported
                returnTypeId = undefined;
              }
            } else if (stepField[Instance_Of] === KinshipRelationField) {
              returnTypeId = Concept;
            } else if (stepField[Instance_Of] === RelationSingleField) {
              returnTypeId = objectStore.getObjectOrNull(stepField.id)?.navigateOrNull(RelationSingleField_TargetType)?.id;
            } else if (stepField[Instance_Of] === WorkflowField) {
              returnTypeId = objectStore.getObjectOrNull(stepField.id)?.navigateOrNull(WorkflowField_Workflow)?.navigateOrNull(Workflow_TargetedConceptDefinition)?.id;
            } else if (stepField[Instance_Of] === AssociationField) {
              const field = objectStore.getObjectOrNull(stepField.id);
              if (field === null) {
                returnTypeId = undefined;
              } else if (field[Field_Formula]) {
                returnTypeId = Concept;
              } else {
                const sourceRole = field[AssociationField_SourceRole];
                const targetRoleField = sourceRole === 1 ? AssociationFieldDefinition_Role2Type : AssociationFieldDefinition_Role1Type;
                const fieldDefinition = field.navigateOrNull(AssociationField_Definition);
                returnTypeId = fieldDefinition?.navigateOrNull(targetRoleField)?.id;
              }
            } else if (stepField[Instance_Of] === RelationMultipleField) {
              returnTypeId = objectStore.getObjectOrNull(stepField.id)?.navigateOrNull(RelationMultipleField_TargetType)?.id;
            } else if (stepField[Instance_Of] === EmbeddingField) {
              returnTypeId = objectStore.getObjectOrNull(stepField.id)?.navigateOrNull(EmbeddingField_ToType)?.id;
            } else if (stepField[Instance_Of] === StakeholdersField) {
              returnTypeId = objectStore.getObjectOrNull(stepField.id)?.navigateOrNull(StakeholdersField_TargetType)?.id;
            } else {
              returnTypeId = undefined;
            }
          } else if (nStep?.type === PathStepType.filter) {
            // only multiplicity change
          }
        }
        return returnTypeId;
      };

      interface ViewDefinition {
        data: { id: string, path: PathStep[] }[],
        views: {
          type: string,
          series?: { displayOptions?: { width?: number | undefined, [key: string]: unknown }, [key: string]: unknown }[],
          data?: { id: string, path: PathStep[] }[],
          [key: string]: unknown,
        }[],
      }

      interface OldSwimlaneConfiguration {
        id: string,
        type: 'Swimlane',
        withCustomConfig: boolean | undefined,
        readonly: boolean | undefined,
        defaultProgressFieldId: string | undefined,
        defaultColumnByFieldId: string | undefined,
        defaultGroupByFieldId: string | undefined,
        defaultColorFieldId: string | undefined,
      }

      interface NewSwimlaneConfiguration {
        id: string,
        type: 'Swimlane',
        columnBy: { fieldId: string | undefined, filters: Filters | undefined, withEmpty: boolean, size: { type: 'auto' | 'rem' | 'percent', value?: number } },
        groupBy: { id: string, label: string | undefined, path: PathStep[], isDefault: boolean }[],
        color: 'group' | 'column',
        dependencies: PathStep[],
        instanceDisplay: 'card' | 'chip' | undefined,
        cardColor: { path: PathStep[], as: CardColorMode } | undefined,
        cardHeader: { id: string, path: PathStep[], displayOptions?: Record<string, unknown> }[],
        cardBody: { id: string, label: string | undefined, path: PathStep[], displayOptions?: Record<string, unknown> }[],
      }

      const migrateConfiguration = (
        oldConfiguration: OldSwimlaneConfiguration,
        dimensionId: string | undefined,
        conceptDefinitionId: string | undefined
      ): NewSwimlaneConfiguration => {
        if (!conceptDefinitionId || !dimensionId) {
          return {
            id: oldConfiguration.id,
            type: 'Swimlane',
            columnBy: { fieldId: undefined, filters: undefined, withEmpty: false, size: { type: 'auto' } },
            groupBy: [],
            color: 'column',
            dependencies: [],
            instanceDisplay: undefined,
            cardColor: undefined,
            cardHeader: [],
            cardBody: [],
          };
        }
        const Concept_Name = 'bf040b6b-58be-5194-8f32-4e1c8df24b42';
        if (oldConfiguration.withCustomConfig) {
          const groupByField = oldConfiguration.defaultGroupByFieldId ? objectStore.getObjectOrNull<FieldStoreObject>(oldConfiguration.defaultGroupByFieldId) : null;
          const colorByField = oldConfiguration.defaultColorFieldId ? objectStore.getObjectOrNull<FieldStoreObject>(oldConfiguration.defaultColorFieldId) : null;
          const columnByField = oldConfiguration.defaultColumnByFieldId ? objectStore.getObjectOrNull<FieldStoreObject>(oldConfiguration.defaultColumnByFieldId) : null;
          const progressField = oldConfiguration.defaultProgressFieldId ? objectStore.getObjectOrNull<FieldStoreObject>(oldConfiguration.defaultProgressFieldId) : null;
          return {
            id: oldConfiguration.id,
            type: 'Swimlane',
            columnBy: { fieldId: oldConfiguration.defaultColumnByFieldId, filters: undefined, withEmpty: false, size: { type: 'auto' } },
            groupBy: groupByField ? [{
              id: uuid(),
              path: [
                { type: PathStepType.dimension, conceptDefinitionId },
                {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: groupByField.id,
                }] as PathStep[],
              label: undefined,
              isDefault: true,
            }] : [],
            color: 'column',
            dependencies: [],
            instanceDisplay: 'chip',
            cardColor: colorByField ? {
              path: [{
                type: PathStepType.dimension, conceptDefinitionId,
              }, {
                type: PathStepType.mapping,
                mapping: { type: 'parameter', id: dimensionId },
              }, {
                type: PathStepType.field,
                fieldId: colorByField.id,
              }] as PathStep[],
              as: CardColorMode.Dot,
            } : undefined,
            cardHeader: [{
              id: uuid(),
              path: [{
                type: PathStepType.dimension, conceptDefinitionId,
              }, {
                type: PathStepType.mapping,
                mapping: { type: 'parameter', id: dimensionId },
              }, {
                type: PathStepType.field,
                fieldId: Concept_Name,
              }] as PathStep[],
              displayOptions: {},
            }],
            cardBody: [
              ...columnByField ? [{
                id: uuid(),
                label: undefined,
                path: [{
                  type: PathStepType.dimension, conceptDefinitionId,
                }, {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: columnByField.id,
                }] as PathStep[],
                displayOptions: {},
              }] : [],
              ...groupByField ? [{
                id: uuid(),
                label: undefined,
                path: [{
                  type: PathStepType.dimension, conceptDefinitionId,
                }, {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: groupByField.id,
                }] as PathStep[],
                displayOptions: {},
              }] : [],
              ...colorByField ? [{
                id: uuid(),
                label: undefined,
                path: [{
                  type: PathStepType.dimension, conceptDefinitionId,
                }, {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: colorByField.id,
                }] as PathStep[],
                displayOptions: {},
              }] : [],
              ...progressField ? [{
                id: uuid(),
                label: undefined,
                path: [{
                  type: PathStepType.dimension, conceptDefinitionId,
                }, {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: progressField.id,
                }] as PathStep[],
                displayOptions: {},
              }] : [],
            ],
          };
        } else {
          const conceptDefinition = objectStore.getObjectOrNull<ConceptDefinitionStoreObject>(conceptDefinitionId);
          const groupByField = conceptDefinition?.[ConceptDefinition_SwimlaneGroupBy]
            ? objectStore.getObjectOrNull<FieldStoreObject>(conceptDefinition[ConceptDefinition_SwimlaneGroupBy])
            : null;
          const colorByField = conceptDefinition?.[ConceptDefinition_Color]
            ? objectStore.getObjectOrNull<FieldStoreObject>(conceptDefinition[ConceptDefinition_Color])
            : null;
          const columnByField = conceptDefinition?.[ConceptDefinition_SwimlaneColumnBy]
            ? objectStore.getObjectOrNull<FieldStoreObject>(conceptDefinition[ConceptDefinition_SwimlaneColumnBy])
            : null;
          const progressField = conceptDefinition?.[ConceptDefinition_SwimlaneProgress]
            ? objectStore.getObjectOrNull<FieldStoreObject>(conceptDefinition[ConceptDefinition_SwimlaneProgress])
            : null;
          return {
            id: oldConfiguration.id,
            type: 'Swimlane',
            columnBy: { fieldId: columnByField?.id, filters: undefined, withEmpty: false, size: { type: 'auto' } },
            groupBy: groupByField ? [{
              id: uuid(),
              path: [
                { type: PathStepType.dimension, conceptDefinitionId },
                {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: groupByField.id,
                }] as PathStep[],
              label: undefined,
              isDefault: true,
            }] : [],
            color: 'column',
            dependencies: [],
            instanceDisplay: 'chip',
            cardColor: colorByField ? {
              path: [{
                type: PathStepType.dimension, conceptDefinitionId,
              }, {
                type: PathStepType.mapping,
                mapping: { type: 'parameter', id: dimensionId },
              }, {
                type: PathStepType.field,
                fieldId: colorByField.id,
              }] as PathStep[],
              as: CardColorMode.Dot,
            } : undefined,
            cardHeader: [{
              id: uuid(),
              path: [{
                type: PathStepType.dimension, conceptDefinitionId,
              }, {
                type: PathStepType.mapping,
                mapping: { type: 'parameter', id: dimensionId },
              }, {
                type: PathStepType.field,
                fieldId: Concept_Name,
              }] as PathStep[],
              displayOptions: {},
            }],
            cardBody: [
              ...columnByField ? [{
                id: uuid(),
                label: undefined,
                path: [{
                  type: PathStepType.dimension, conceptDefinitionId,
                }, {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: columnByField.id,
                }] as PathStep[],
                displayOptions: {},
              }] : [],
              ...groupByField ? [{
                id: uuid(),
                label: undefined,
                path: [{
                  type: PathStepType.dimension, conceptDefinitionId,
                }, {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: groupByField.id,
                }] as PathStep[],
                displayOptions: {},
              }] : [],
              ...colorByField ? [{
                id: uuid(),
                label: undefined,
                path: [{
                  type: PathStepType.dimension, conceptDefinitionId,
                }, {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: colorByField.id,
                }] as PathStep[],
                displayOptions: {},
              }] : [],
              ...progressField ? [{
                id: uuid(),
                label: undefined,
                path: [{
                  type: PathStepType.dimension, conceptDefinitionId,
                }, {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: progressField.id,
                }] as PathStep[],
                displayOptions: {},
              }] : [],
            ],
          };
        }
      };

      objectStore.getObject(ViewsField)
        .navigateBack(Class_Instances)
        .filter((field): field is StoreObject & { [ViewsField_Definition]: ViewDefinition } => (
          Boolean((field[ViewsField_Definition] as ViewDefinition | undefined)?.views.some(({ type }) => type === 'Swimlane'))))
        .forEach((field) => objectStore.updateObject(field.id, {
          [ViewsField_Definition]: joinObjects(
            field[ViewsField_Definition],
            {
              views: field[ViewsField_Definition].views.map((viewConfiguration) => {
                if (viewConfiguration.type === 'Swimlane') {
                  const firstData = field[ViewsField_Definition].data?.at(0);
                  return migrateConfiguration(
                    viewConfiguration as unknown as OldSwimlaneConfiguration,
                    firstData?.id,
                    getPathReturnedConceptDefinitionId(firstData?.path ?? [])
                  );
                }
                return viewConfiguration;
              }),
            }
          ),
        }));
    },
  },
  [MigrationIconField]: {
    handler: ({ updateObject, withAssociation }) => {
      updateObject(Dashboard_Icon, { [Instance_Of]: IconField, [Field_Title]: 'Icon', [Field_ApiAlias]: 'icon', [Field_IsCore]: true });
      updateObject(DashboardIconDimension, { [Instance_Of]: FieldDimension, [FieldDimension_Field]: Dashboard_Icon });
      withAssociation(FieldDimensionTypes)
        .withRole(FieldDimensionTypes_Role_ConceptDefinition, Dashboard)
        .withRole(FieldDimensionTypes_Role_FieldDimension, DashboardIconDimension)
        .updateObject({});
      updateObject(Dashboard, { [ConceptDefinition_MainIconField]: Dashboard_Icon });
    },
  },
  [MigrationTimeseriesTableDimensionDisplay]: {
    dependencies: [TypeModuleId],
    handler: (objectStore) => {
      interface GlobalDimensionStep {
        type: PathStepType.global,
      }

      interface DimensionStep {
        type: PathStepType.dimension,
        conceptDefinitionId: string,
      }

      interface FilterStep {
        type: PathStepType.filter,
      }

      interface MappingStep {
        type: PathStepType.mapping,
        mapping: { id: string, type: InstanceReferenceType },
      }

      interface FieldStep {
        type: PathStepType.field,
        fieldId: string,
        embeddingFieldId?: string,
        workflowSubfieldId?: string,
        mapping?: Record<string, InstanceReferenceValue | undefined>,
      }

      type PathStep = GlobalDimensionStep | DimensionStep | FilterStep | MappingStep | FieldStep;

      interface ViewDefinition {
        views: {
          type: string,
          [key: string]: unknown,
        }[],
        data: { id: string, path: PathStep[] }[],
      }

      interface Configuration {
        id: string,
        type: 'TimeseriesTable',
        timeAxis: {
          axis: 'x' | 'y',
        },
        seriesAxis: {
          axis: 'x' | 'y',
          display: 'show' | 'hide' | undefined,
        },
        series: unknown[],
        dimensionsDisplay: {
          id: string,
          axis: 'x' | 'y',
          display: 'show' | 'hide' | undefined,
        }[] | undefined,
      }

      const isDimensionStep = (step: PathStep | undefined): step is DimensionStep => step?.type === PathStepType.dimension;
      const isGlobalDimensionStep = (step: PathStep | undefined): step is GlobalDimensionStep => step?.type === PathStepType.global;
      const isMappingStep = (step: PathStep | undefined): step is MappingStep => step?.type === PathStepType.mapping;
      const isFieldStep = (step: PathStep | undefined): step is FieldStep => step?.type === PathStepType.field;

      const compilePath = (store: ObjectStoreReadOnly, valuePath: PathStep[]): PathStep[] => (
        valuePath.reduce<PathStep[]>((accumulator, step) => {
          if (isFieldStep(step) && isInstanceOf(store.getObjectOrNull(step.fieldId), StakeholdersField)) {
            const prevStep = accumulator.at(-1);
            const prev2Step = accumulator.at(-2);
            const prev3Step = accumulator.at(-3);
            if (
              isMappingStep(prevStep)
              && isDimensionStep(prev2Step)
              && isFieldStep(prev3Step) && isInstanceOf(store.getObjectOrNull(prev3Step.fieldId), StakeholdersField)
            ) {
              return [
                ...accumulator.slice(0, -3),
                { type: PathStepType.field, fieldId: step.fieldId, mapping: { n1InstanceId: prevStep.mapping } } satisfies FieldStep,
              ];
            }
          }

          accumulator.push(step);
          return accumulator;
        }, [])
      );

      const isMultiplePath = (store: ObjectStoreReadOnly, path: PathStep[]): boolean => {
        const compiledPath = compilePath(store, path);
        let result = compiledPath.length > 0;
        for (let i = 0; i < compiledPath.length; i += 1) {
          const nStep = compiledPath[i];
          if (isGlobalDimensionStep(nStep)) {
            result = false;
          } else if (isMappingStep(nStep)) {
            result = false;
          } else if (isFieldStep(nStep)) {
            const stepField = store.getObjectOrNull<FieldStoreObject>(nStep.fieldId);
            if (stepField && isMultipleRelationalType(stepField[Instance_Of])) {
              result = true;
            }
          }
        }
        return result;
      };

      const migrateConfiguration = (oldConfiguration: Configuration, viewData: { id: string, path: PathStep[] }[]): Configuration => {
        const { dimensionsDisplay = [] } = oldConfiguration;
        const dataDimensionDisplay = viewData.map((data) => {
          const dimensionDisplay = dimensionsDisplay.find(({ id }) => id === data.id) ?? {
            id: data.id,
            display: isMultiplePath(objectStore, data.path ?? []) ? 'show' : 'hide',
            axis: 'x',
          };
          return joinObjects(data, dimensionDisplay);
        });
        if (oldConfiguration.seriesAxis.axis === 'y' && oldConfiguration.timeAxis.axis === 'x' && dataDimensionDisplay.every(({ axis }) => axis === 'x')) {
          return joinObjects(
            oldConfiguration,
            {
              seriesAxis: joinObjects(oldConfiguration.seriesAxis, {
                display: 'show' as const,
              }),
            }
          );
        } else if (oldConfiguration.seriesAxis.axis === 'x' && oldConfiguration.timeAxis.axis === 'y' && dataDimensionDisplay.every(({ axis }) => axis === 'x')) {
          return joinObjects(
            oldConfiguration,
            {
              seriesAxis: dataDimensionDisplay.length === 0 ? joinObjects(oldConfiguration.seriesAxis, { display: 'show' as const }) : oldConfiguration.seriesAxis,
              dimensionsDisplay: dataDimensionDisplay.map((dimensionDisplay) => (joinObjects(dimensionDisplay, {
                display: 'show' as const,
              }))),
            }
          );
        }
        for (let i = 0; i < dataDimensionDisplay.length; i += 1) {
          const dimensionDisplay = dataDimensionDisplay[i];
          if (dimensionDisplay.axis === 'y' && oldConfiguration.seriesAxis.axis === 'x' && oldConfiguration.timeAxis.axis === 'x'
            && dataDimensionDisplay.every((d, j) => d.axis === 'x' || i === j)) {
            return joinObjects(
              oldConfiguration,
              {
                seriesAxis: dataDimensionDisplay.length === 0 ? joinObjects(oldConfiguration.seriesAxis, { display: 'show' as const }) : oldConfiguration.seriesAxis,
                dimensionsDisplay: dataDimensionDisplay.map((d) => (joinObjects(d, {
                  display: 'show' as const,
                }))),
              }
            );
          }
        }
        return oldConfiguration;
      };

      objectStore.getObject(ViewsField)
        .navigateBack(Class_Instances)
        .filter((field): field is StoreObject & { [ViewsField_Definition]: ViewDefinition } => (
          Boolean((field[ViewsField_Definition] as ViewDefinition | undefined)?.views.some(({ type }) => type === 'TimeseriesTable'))))
        .forEach((field) => objectStore.updateObject(field.id, {
          [ViewsField_Definition]: joinObjects(
            field[ViewsField_Definition],
            {
              views: field[ViewsField_Definition].views.map((viewConfiguration) => {
                if (viewConfiguration.type === 'TimeseriesTable') {
                  return migrateConfiguration(
                    viewConfiguration as unknown as Configuration,
                    field[ViewsField_Definition].data
                  );
                }
                return viewConfiguration;
              }),
            }
          ),
        }));
    },
  },
};

export default migrations;
