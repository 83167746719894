export const sleep = (duration: number, signal?: AbortSignal): Promise<void> => new Promise((resolve) => {
  let timeout: ReturnType<typeof setTimeout> | undefined;
  const onAbort = () => {
    clearTimeout(timeout);
    signal?.removeEventListener('abort', onAbort);
    resolve();
  };
  signal?.addEventListener('abort', onAbort);
  timeout = setTimeout(onAbort, duration);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const forgetAsyncPromise = <TArgs extends any[]>(func: (...args: TArgs) => Promise<any>): (...params: TArgs) => void => (
  (...params) => {
    func(...params);
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setAsyncTimeout = <TArgs extends any[]>(asyncCallback: (...args: TArgs) => Promise<void>, ms?: number, ...args: TArgs): ReturnType<typeof setTimeout> => (
  setTimeout(forgetAsyncPromise(asyncCallback), ms, ...args)
);

export const createTaskExecutor = (parallelism: number) => async <T>(tasks: IterableIterator<() => Promise<T>>): Promise<Promise<T>[]> => {
  const results: Promise<T>[] = [];
  let i = 0;

  const executeTask = async () => {
    for (const task of tasks) {
      const promise = task();
      results[i] = promise;
      i += 1;
      try {
        await promise;
      } catch (_) {
        // ignored, will be caught by caller
      }
    }
  };

  await Promise.all(Array(parallelism).fill(undefined).map(executeTask));

  return results;
};

export const createAsyncMapper = (parallelism: number) => async <T, U>(array: T[], mapper: (t: T) => Promise<U>): Promise<Promise<U>[]> => {
  const executor = createTaskExecutor(parallelism);
  return executor(array.map((t) => () => mapper(t)).values());
};
