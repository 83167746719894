import type { ReactNode } from 'react';
import type { ExtractDslHandlerTypes, GenericGetDslFieldHandler } from 'yooi-modules/modules/common/fields/FieldModuleDslType';
import type { SingleParameterDefinition, PathStep, MultipleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import BlockContent from '../../../../components/templates/BlockContent';
import BlockTitle from '../../../../components/templates/BlockTitle';
import HorizontalBlock, { HorizontalBlockVariant } from '../../../../components/templates/HorizontalBlock';
import useAcl from '../../../../store/useAcl';
import i18n from '../../../../utils/i18n';
import type { OptionRecord } from '../../modelTypeUtils';
import PathInput from '../../path/PathInput';
import type { PathConfigurationHandler } from '../../pathConfigurationHandler';
import type { FieldHandler } from '../FieldLibraryTypes';

interface UpdateOperationInputProps<DslFieldHandler extends GenericGetDslFieldHandler> {
  valueInput: Required<FieldHandler<DslFieldHandler, never, never, never>>['input'],
  initialValue: { type: 'value', value: ExtractDslHandlerTypes<DslFieldHandler>['StoreValue'] } | { type: 'path', path: PathStep[] },
  onTypeChange: (newType: 'value' | 'path') => void,
  onValueSubmit: (newValue: { type: 'value', value: ExtractDslHandlerTypes<DslFieldHandler>['StoreValue'] } | { type: 'path', path: PathStep[] }) => void,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
  valuePathHandler?: PathConfigurationHandler,
}

const UpdateOperationInput = <Handler extends GenericGetDslFieldHandler = never>({
  valueInput,
  initialValue,
  onTypeChange,
  onValueSubmit,
  parameterDefinitions,
  valuePathHandler,
}: UpdateOperationInputProps<Handler>): ReactNode => {
  const aclHandler = useAcl();

  const options: OptionRecord<'value' | 'path'> = {
    value: { id: 'value', label: i18n`With a value` },
    path: { id: 'path', label: i18n`With a path` },
  };

  return (
    <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
      <BlockTitle
        unPadded
        title={(
          <SearchAndSelect
            selectedOption={options[initialValue.type ?? 'value']}
            computeOptions={() => Object.values(options)}
            onSelect={(value) => onTypeChange(value?.id ?? 'value')}
            hideCaret
          />
        )}
      />
      <BlockContent>
        {initialValue.type !== 'path' && (
          valueInput.render({
            value: initialValue.value,
            onSubmit: (value) => onValueSubmit({ type: 'value', value }),
            readOnly: false,
            aclHandler,
          })
        )}
        {initialValue.type === 'path' && (
          <PathInput
            path={initialValue.path ?? []}
            onChange={(path) => onValueSubmit({ type: 'path', path })}
            parameterDefinitions={parameterDefinitions}
            valuePathHandler={valuePathHandler}
            placeholder={i18n`Add path`}
          />
        )}
      </BlockContent>
    </HorizontalBlock>
  );
};

export default UpdateOperationInput;
