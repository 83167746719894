import { equals } from 'ramda';
import type { FunctionComponent } from 'react';
import { useCallback, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { setAsyncTimeout } from 'yooi-utils';
import base from '../../theme/base';
import { textLineHeight } from '../../theme/fontDefinition';
import { getSpacingAsNumber, Spacing, spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import { getTextMeasurerForVariant } from '../../utils/textUtils';
import useTheme from '../../utils/useTheme';
import type { TooltipConfig } from '../../utils/useTooltip';
import { useTooltip } from '../../utils/useTooltip';
import { computePosition } from './tooltipUtils';
import Typo, { typoMaxWidth, TypoVariant } from './Typo';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    paddingBottom: spacingRem.xs,
    paddingTop: spacingRem.xs,
    backgroundColor: theme.color.background.neutral.dark,
    borderRadius: base.borderRadius.medium,
    position: 'absolute',
    zIndex: 1500,
  },
}), 'tooltipRender');

const TooltipRender: FunctionComponent = () => {
  const theme = useTheme();
  const classes = useStyles();

  const tooltipRef = useRef<({ callerId: string, config: TooltipConfig }) | undefined>(undefined);
  const tooltipSeq = useRef(0);

  const [label, setLabel] = useState<string | undefined>(undefined);
  const [debouncedLabel] = useDebounce(label, 50);

  const listener = useCallback((callerId: string, tooltipConfig: TooltipConfig | undefined) => {
    const isNewCallerId = tooltipRef.current?.callerId !== callerId;
    if (tooltipConfig === undefined && isNewCallerId) {
      // Got a clear on an already replaced tooltip, skip
      return;
    } else if (!isNewCallerId && equals(tooltipConfig, tooltipRef.current?.config)) {
      return;
    }

    // increment tooltip sequence and retain the current value to be compared when coming back for async/timeout calls
    tooltipSeq.current += 1;
    const seq = tooltipSeq.current;

    if (isNewCallerId || tooltipConfig === undefined) {
      setLabel(undefined);
    }

    tooltipRef.current = tooltipConfig === undefined ? tooltipConfig : { callerId, config: tooltipConfig };

    if (tooltipConfig) {
      const { text, prefix } = tooltipConfig;
      let textWithPrefix;

      setAsyncTimeout(async () => {
        if (seq === tooltipSeq.current) {
          if (typeof text === 'string') {
            textWithPrefix = prefix ? prefix + text : text;
            setLabel(textWithPrefix);
          } else {
            setLabel((current) => (isNewCallerId || current === undefined ? i18n`Loading...` : current));
            const asyncText = await text();
            textWithPrefix = prefix ? prefix + asyncText : asyncText;
            if (seq === tooltipSeq.current) {
              setLabel(textWithPrefix);
            }
          }
        }
      }, 450);
    } else {
      setLabel(undefined);
    }
  }, []);

  useTooltip(listener);

  if (tooltipRef.current && label !== undefined && debouncedLabel !== undefined) {
    const offset = remToPx(0.5);
    const paddingX = remToPx(getSpacingAsNumber(Spacing.s));
    const paddingY = remToPx(getSpacingAsNumber(Spacing.xs));

    const textMeasurer = getTextMeasurerForVariant(TypoVariant.small);

    const textMaxWidth = remToPx(typoMaxWidth[TypoVariant.small]);
    let textWidth = 0;
    let textHeight = 0;
    const lineHeight = remToPx(textLineHeight.small);
    debouncedLabel.split('\n')
      .forEach((line) => {
        const width = textMeasurer(line);
        if (width <= textMaxWidth) {
          if (width > textWidth) {
            textWidth = width;
          }
          textHeight += lineHeight;
        } else {
          textWidth = textMaxWidth;
          textHeight += lineHeight * Math.ceil(width / textMaxWidth);
        }
      });

    const tooltipWidth = textWidth + (2 * paddingX);
    const tooltipHeight = textHeight + (2 * paddingY);

    const bounding = tooltipRef.current.config.element.getBoundingClientRect();
    const { tooltipX, tooltipY } = computePosition(
      window.innerWidth,
      window.innerHeight,
      bounding.left,
      bounding.top,
      bounding.width,
      bounding.height,
      offset,
      tooltipWidth,
      tooltipHeight
    );

    return (
      <div
        style={{ top: tooltipY, left: tooltipX }}
        className={classes.tooltip}
      >
        <Typo color={theme.color.text.white} variant={TypoVariant.small}>{debouncedLabel}</Typo>
      </div>
    );
  } else {
    return null;
  }
};

export default TooltipRender;
