export interface AutoProvisioningMap<K, V> extends Map<K, V> {
  getOrCreate: (key: K, create: (key: K) => V) => V,
  asyncGetOrCreate: (key: K, create: (key: K) => Promise<V>) => Promise<V>,
  createIfMissing: (key: K, create: (key: K) => V) => void,
}

export const createAutoProvisioningMap = <K, V>(): AutoProvisioningMap<K, V> => {
  const map = new Map<K, V>() as AutoProvisioningMap<K, V>;

  map.getOrCreate = (key, create) => {
    if (map.has(key)) {
      return map.get(key) as V;
    } else {
      const value = create(key);
      map.set(key, value);
      return value;
    }
  };

  map.asyncGetOrCreate = async (key, create) => {
    if (map.has(key)) {
      return map.get(key) as V;
    } else {
      const value = await create(key);
      map.set(key, value);
      return value;
    }
  };

  map.createIfMissing = (key, create) => {
    if (!map.has(key)) {
      map.set(key, create(key));
    }
  };

  return map;
};
