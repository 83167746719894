import type { RefCallback } from 'react';
import { useCallback, useRef } from 'react';
import { useTooltip } from './useTooltip';

const useTooltipRef = <Element extends HTMLElement | SVGSVGElement = HTMLElement | SVGSVGElement>(
  title: string | (() => Promise<string>) | undefined,
  propagateOverEvent = false
): RefCallback<Element | null> => {
  const { displayTooltip, hideTooltip } = useTooltip();

  const titleRef = useRef<typeof title>(title);
  titleRef.current = title;

  const currentNodeRef = useRef<Element | null>(null);

  const onMouseOver = useCallback((event: Event) => {
    if (currentNodeRef.current !== null && titleRef.current !== undefined) {
      displayTooltip({ element: currentNodeRef.current, text: titleRef.current });
      if (!propagateOverEvent) {
        event.stopPropagation();
      }
    }
  }, [propagateOverEvent, displayTooltip]);

  const onMouseOut = useCallback(() => hideTooltip(), [hideTooltip]);

  return useCallback((node) => {
    if (currentNodeRef.current) {
      currentNodeRef.current.removeEventListener('mouseover', onMouseOver);
      currentNodeRef.current.removeEventListener('mouseout', onMouseOut);
    }
    currentNodeRef.current = node;

    if (node) {
      node.addEventListener('mouseover', onMouseOver);
      node.addEventListener('mouseout', onMouseOut);
    }
  }, [onMouseOut, onMouseOver]);
};

export default useTooltipRef;
