import { equals } from 'ramda';
import type { FunctionComponent } from 'react';
import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Typo from '../../../../components/atoms/Typo';
import type { CompositeDropdownSection } from '../../../../components/molecules/CompositeField';
import CompositeField, { CompositeFieldCloseReasons } from '../../../../components/molecules/CompositeField';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import TableInnerCellContainer from '../../../../components/molecules/TableInnerCellContainer';
import useStore from '../../../../store/useStore';
import useDeepMemo from '../../../../utils/useDeepMemo';
import useDerivedState from '../../../../utils/useDerivedState';
import { getFieldEditionOptionRender } from '../../fields/fieldEditionOptionsUtils';
import { getFieldHandler } from '../../fields/FieldLibrary';

interface DisplayOptionsCellProps {
  fieldId: string,
  parameterDefinitions: SingleParameterDefinition[],
  initialState: Record<string, unknown>,
  onSubmit: (state: Record<string, unknown>) => void,
}

const DisplayOptionsCell: FunctionComponent<DisplayOptionsCellProps> = ({ fieldId, parameterDefinitions, initialState, onSubmit }) => {
  const store = useStore();

  const fieldHandler = getFieldHandler(store, fieldId);

  const memoizedInitialState = useDeepMemo(() => initialState, [initialState]);
  const [state, setState, resetState] = useDerivedState<Record<string, unknown>>(() => memoizedInitialState, [memoizedInitialState]);

  if (fieldHandler === undefined || fieldHandler.fieldDisplayOptionsHandler === undefined) {
    return (<TableInnerCellContainer />);
  }
  const { renderSummary, getErrors, getEditionOptionSections } = fieldHandler.fieldDisplayOptionsHandler(parameterDefinitions);

  return (
    <CompositeField
      headerLinesRenderers={[
        {
          id: 'main',
          render: () => {
            const errors = getErrors?.(state) ?? [];
            return (
              <SpacingLine>
                <Typo maxLine={1}>{renderSummary(state).join(', ')}</Typo>
                {errors.length > 0 ? (
                  <Icon name={IconName.dangerous} colorVariant={IconColorVariant.error} tooltip={errors ? errors.join(', ') : undefined} />
                ) : null}
              </SpacingLine>
            );
          },
        },
      ]}
      getDropdownSectionDefinitions={() => {
        const sections: CompositeDropdownSection[] = [];

        sections.push(
          ...getEditionOptionSections(state, setState)
            .map((section) => ({
              id: section.key,
              title: section.title,
              titleVariant: section.titleVariant,
              useGridForTitle: Boolean(section.action),
              action: section.action ? getFieldEditionOptionRender(section.action) : null,
              lines: section.options.map((option) => ({
                id: option.key,
                title: option.title,
                isVertical: option.isVertical,
                info: option.info,
                render: getFieldEditionOptionRender(option),
              })),
            }))
        );

        return sections;
      }}
      onCloseDropdown={(reason) => {
        if (reason === CompositeFieldCloseReasons.validate && !equals(memoizedInitialState, state)) {
          onSubmit(state);
          resetState();
        } else {
          resetState();
        }
      }}
    />
  );
};

export default DisplayOptionsCell;
