import type {
  BusinessRuleHandler,
  GarbageCollectorRulesLibraryRegistration,
  InitModuleFunction,
  MigrationHelper,
  ObjectBusinessRules,
  ObjectStore,
  ObjectStoreReadOnly,
  StoreObject,
} from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import type { BusinessRuleRegistration } from '../common/types/TypeModuleDslType';
import {
  Association_RoleTypes,
  Association_VarRolesType,
  Class_Extend,
  Class_Extensions,
  Class_Instances,
  Class_IsExternal,
  Class_IsInstanceOf,
  Instance_Of,
  ModelAssociation,
  ModelProperty,
  ModelType,
  Property_OfClass,
  Property_TargetClass,
  TypeModuleId,
} from './ids';
import migrations from './migrations';

export const doExtends = (object: StoreObject | null | undefined, targetClassId: string): boolean => {
  let type = object;
  while (type !== null && type !== undefined && type[Class_Extend] !== undefined) {
    if (type[Class_Extend] === targetClassId) {
      return true;
    } else {
      type = type.navigateOrNull(Class_Extend);
    }
  }
  return false;
};

const isInstanceOfPrivate = (object: StoreObject | StoreObject<string[]>, targetClassId: string): boolean => {
  if (object[Instance_Of] === undefined || object.navigateOrNull(Instance_Of) === null) {
    return false;
  } else if (object[Instance_Of] === targetClassId) {
    return true;
  } else {
    return doExtends(object.navigateOrNull(Instance_Of), targetClassId);
  }
};

interface TypedStoreObject<Id extends (string | string[]) = string> extends StoreObject<Id> {
  [Instance_Of]: string,
}

export const isInstanceOf = <O extends TypedStoreObject | TypedStoreObject<string[]>>(
  object: StoreObject | StoreObject<string[]> | null | undefined,
  targetClassId: string
): object is O => {
  if (object === null || object === undefined) {
    return false;
  } else {
    const isInstanceOfPropertyFunction = object[Class_IsInstanceOf] as ((typeId: string) => boolean) | undefined;
    if (isInstanceOfPropertyFunction === undefined) {
      return isInstanceOfPrivate(object, targetClassId);
    } else {
      return isInstanceOfPropertyFunction(targetClassId);
    }
  }
};

const checkInstanceOfImmutableConstraint: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  const newClass = properties && properties[Instance_Of];
  if (newClass) {
    const currentProperties = getObjectOrNull(id);
    const currentClass = currentProperties && currentProperties[Instance_Of];
    if (!currentProperties) {
      return { rule: 'type.instanceOf.allowOnCreation', status: ValidationStatus.ACCEPTED };
    } else if (newClass !== currentClass) {
      return { rule: 'type.instanceOf.cannotBeUpdated', status: ValidationStatus.REJECTED };
    } else {
      return { rule: 'type.instanceOf.sameAsStored', status: ValidationStatus.ACCEPTED };
    }
  }
  return undefined;
};

const checkExtendsImmutableConstraint: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  const newParentsClass = properties && properties[Class_Extend];
  if (newParentsClass) {
    const currentProperties = getObjectOrNull(id);
    const currentParentClass = currentProperties && currentProperties[Class_Extend];
    if (!currentProperties) {
      return { rule: 'type.extends.allowOnCreation', status: ValidationStatus.ACCEPTED };
    } else if (newParentsClass !== currentParentClass) {
      return { rule: 'type.extends.cannotBeUpdated', status: ValidationStatus.REJECTED };
    } else {
      return { rule: 'type.extends.sameAsStored', status: ValidationStatus.ACCEPTED };
    }
  }
  return undefined;
};

const allowPropOnAnything = (propertyName: string): BusinessRuleHandler => (_, { properties }) => {
  if (!properties) {
    return {
      rule: `${propertyName}.deletion.allow`,
      status: ValidationStatus.ACCEPTED,
    };
  }

  return {
    rule: `${propertyName}.allow`,
    status: ValidationStatus.ACCEPTED,
  };
};

const getParentId = (objectId: string, getObjectOrNull: ObjectStore['getObjectOrNull']): string | undefined => {
  const parentProperties = getObjectOrNull(objectId);
  return parentProperties ? parentProperties[Class_Extend] as string : undefined;
};

const isParentsMatch = (targetedClassId: string, objectClassId: string, getObjectOrNull: ObjectStore['getObjectOrNull']): boolean => {
  const parentClassId = getParentId(objectClassId, getObjectOrNull);
  if (parentClassId && parentClassId === targetedClassId) {
    return true;
  } else if (parentClassId && parentClassId !== targetedClassId) {
    return isParentsMatch(targetedClassId, parentClassId, getObjectOrNull);
  }
  return false;
};

const checkCyclicExtendsConstraint: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  const newParentsClass = properties ? properties[Class_Extend] as string : undefined;
  if (newParentsClass) {
    if (newParentsClass === id[0]) {
      return { rule: 'type.extends.cannotCreateCyclicDependencies', status: ValidationStatus.REJECTED };
    }
    const isParentMatch = isParentsMatch(id[0], newParentsClass, getObjectOrNull);
    if (!isParentMatch) {
      return { rule: 'type.extends.allowNonCyclic', status: ValidationStatus.ACCEPTED };
    } else {
      return { rule: 'type.extends.cannotCreateCyclicDependencies', status: ValidationStatus.REJECTED };
    }
  }
  return undefined;
};

const allowDeleteOnUndefinedProperty: BusinessRuleRegistration = ({ getObjectOrNull, objectEntries }) => (_, { id: instanceId, properties }) => {
  const instance = getObjectOrNull(instanceId);
  if (!properties && instance) {
    const validProperties = objectEntries(instance).map(([propertyId]) => propertyId).filter((propertyId) => !getObjectOrNull(propertyId));
    if (validProperties.length) {
      return {
        rule: 'type.allowDeleteOnUndefinedProperty',
        status: ValidationStatus.ACCEPTED,
        propertyIds: validProperties,
      };
    }
  }
  return undefined;
};

const checkPropertiesOfTypeConstraints: BusinessRuleRegistration = ({ getObjectOrNull, objectEntries }) => (_, { id, properties }) => {
  const ruleMap = {} as Record<string, { status: ValidationStatus, propertyIds: string[] }>;

  const addRuleToMap = (ruleName: string, propertyIds: string[], status: ValidationStatus) => {
    ruleMap[ruleName] = { propertyIds: [...(ruleMap[ruleName]?.propertyIds ?? []), ...propertyIds], status };
  };

  const instance = getObjectOrNull(id);

  let propertyEntries: [string, unknown][];
  if (properties) {
    // update of object
    propertyEntries = Object.entries(properties);
  } else if (properties === null && instance) {
    // deletion of existing object
    propertyEntries = objectEntries(instance).map(([propertyId]) => [propertyId, null]);
  } else {
    // deletion of nothing or timeseries update
    propertyEntries = [];
  }

  propertyEntries.forEach(([propertyId, propertyValue]) => {
    let objectClass;
    if (id.length > 1) {
      [objectClass] = id;
    } else {
      const currentProperties = getObjectOrNull(id);
      objectClass = (currentProperties && currentProperties[Instance_Of] as string) || properties?.[Instance_Of] as string;
    }
    const property = getObjectOrNull(propertyId);
    if (property) {
      const typeRestriction = property[Property_OfClass] as string;
      if (typeRestriction && properties) {
        if (objectClass) {
          // for o[prop] update then checks that prop[PROPERTY_TYPE] === o[TYPE]
          const parentsMatch = isParentsMatch(typeRestriction, objectClass, getObjectOrNull);
          if (typeRestriction) {
            if (objectClass !== typeRestriction && !parentsMatch) {
              const ruleName = 'type.propertyOfClass.mismatch';
              addRuleToMap(ruleName, [propertyId], ValidationStatus.REJECTED);
            } else {
              const ruleName = 'type.propertyOfClass.match';
              addRuleToMap(ruleName, [propertyId], ValidationStatus.ACCEPTED);
            }
          }
        } else {
          const ruleName = 'type.propertyOfClass.onlyOnInstances';
          addRuleToMap(ruleName, [propertyId], ValidationStatus.REJECTED);
        }
      }
      // if o[prop]=val then checks that prop[PROPERTY_TYPE] && val && val[TYPE] === prop[TARGET_TYPE]
      const acceptedTargetType = property[Property_TargetClass] as string;
      if (acceptedTargetType) {
        if (propertyValue) {
          const target = getObjectOrNull(propertyValue as string);
          if (!target) {
            const targetType = getObjectOrNull(acceptedTargetType);
            if (!targetType) {
              const ruleName = 'type.propertyTargetClass.unknown';
              addRuleToMap(ruleName, [propertyId], ValidationStatus.REJECTED);
            } else if (targetType[Class_IsExternal]) {
              const ruleName = 'type.propertyTargetClass.external';
              addRuleToMap(ruleName, [propertyId], ValidationStatus.ACCEPTED);
            } else {
              const ruleName = 'type.propertyTargetClass.missing';
              addRuleToMap(ruleName, [propertyId], ValidationStatus.REJECTED);
            }
          } else if (!target[Instance_Of]) {
            const ruleName = 'type.propertyTargetClass.notAnInstanceOf';
            addRuleToMap(ruleName, [propertyId], ValidationStatus.REJECTED);
          } else if (target[Instance_Of] !== acceptedTargetType && !isParentsMatch(acceptedTargetType, target[Instance_Of] as string, getObjectOrNull)) {
            const ruleName = 'type.propertyTargetClass.mismatch';
            addRuleToMap(ruleName, [propertyId], ValidationStatus.REJECTED);
          } else {
            const ruleName = 'type.propertyTargetClass.match';
            addRuleToMap(ruleName, [propertyId], ValidationStatus.ACCEPTED);
          }
        }
      }

      if (properties === null) {
        const ruleName = 'type.propertyOfClass.deletion.allow';
        addRuleToMap(ruleName, [propertyId], ValidationStatus.ACCEPTED);
      }
    }
  });
  return Object.entries(ruleMap).map(([ruleName, { propertyIds, status }]) => ({ rule: ruleName, status, propertyIds }));
};

const checkAssociationRolesExists: BusinessRuleRegistration = ({ getObject, getObjectOrNull }) => (_, { id, properties }) => {
  // deletion doesn't need to be checked
  if (!properties) {
    return undefined;
  }

  // objects doesn't need to be checked
  if (id.length === 1) {
    return undefined;
  }

  const association = getObjectOrNull(id[0]);

  // Not a type module powered association
  if (!association || association[Instance_Of] !== ModelAssociation) {
    return undefined;
  }

  const roleTypes = (association[Association_RoleTypes] as string).split('|');
  const varRolesType = association[Association_VarRolesType] as string;
  const targets = id.slice(1).map((roleTargetId) => getObjectOrNull(roleTargetId));
  if (targets.some((target, i) => {
    if (i >= roleTypes.length && varRolesType) {
      return !(getObject(varRolesType)[Class_IsExternal] || target);
    } else {
      return !(getObject(roleTypes[i])[Class_IsExternal] || target);
    }
  })) {
    return { rule: 'association.roleInstanceNotFound', status: ValidationStatus.REJECTED };
  } else if (targets.some((target, i) => {
    if (i >= roleTypes.length && varRolesType) {
      return !(getObject(varRolesType)[Class_IsExternal] || isInstanceOf(target, varRolesType));
    } else {
      return !(getObject(roleTypes[i])[Class_IsExternal] || isInstanceOf(target, roleTypes[i]));
    }
  })) {
    return { rule: 'association.roleInstanceBadType', status: ValidationStatus.REJECTED };
  } else {
    return { rule: 'association.roleInstanceExists', status: ValidationStatus.ACCEPTED };
  }
};

const instanceOfGarbageRuleRegistration = (objectStore: ObjectStoreReadOnly): GarbageCollectorRulesLibraryRegistration => ({
  ruleName: 'instanceOf',
  collect: ({ id, properties }) => {
    if (properties === null) {
      return objectStore.getObjectOrNull(id)?.navigateBack(Instance_Of).map((o) => o.id) ?? [];
    } else {
      return [];
    }
  },
  shouldDelete: (objectId) => {
    const object = objectStore.getObjectOrNull(objectId);
    return Boolean(object?.[Instance_Of] && !object.navigateOrNull(Instance_Of));
  },
});

const extendsGarbageRuleRegistration = (objectStore: ObjectStoreReadOnly): GarbageCollectorRulesLibraryRegistration => ({
  ruleName: 'extends',
  collect: ({ id, properties }) => {
    if (properties === null) {
      return objectStore.getObjectOrNull(id)?.navigateBack(Class_Extensions).map((o) => o.id) ?? [];
    } else {
      return [];
    }
  },
  shouldDelete: (objectId) => {
    const object = objectStore.getObjectOrNull(objectId);
    return Boolean(object?.[Class_Extend] && !object.navigateOrNull(Class_Extend));
  },
});

const checkHasType: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  // If we are an association, we don't need to validate anything
  if (Array.isArray(id) && id.length > 1) {
    return undefined;
  }

  // Ignore instances of TypeModule or PlatformModule
  if ([
    ModelType, ModelProperty, ModelAssociation,
    Association_RoleTypes, Association_VarRolesType,
    Instance_Of, Class_Extend, Class_IsExternal,
    Property_OfClass, Property_TargetClass,
  ].indexOf(id[0]) !== -1) {
    return undefined;
  }

  // If we are deleting the object, we don't need to check the type
  if (properties === null) {
    return { rule: 'type.checkHasType.deletion.allow', propertyIds: [Instance_Of], status: ValidationStatus.ACCEPTED };
  }

  if (!properties?.[Instance_Of]) {
    const object = getObjectOrNull(id);
    if (!object) {
      return { rule: 'type.checkHasType.missing', status: ValidationStatus.REJECTED };
    } else if (!object[Instance_Of]) {
      return { rule: 'type.checkHasType.missingInStore', status: ValidationStatus.REJECTED };
    }
  }
  return undefined;
};

export const collectTypes = ({ getObjectOrNull }: ObjectStoreReadOnly, typeId: string | undefined): string[] => {
  const types: string[] = [];
  let currentType: StoreObject | null = typeId ? getObjectOrNull(typeId) : null;
  while (currentType) {
    types.push(currentType.id);
    currentType = currentType.navigateOrNull(Class_Extend);
  }
  return types;
};

const collectSubTypes = (objectStore: ObjectStoreReadOnly, type: string): string[] => objectStore.getObjectOrNull(type)?.navigateBack(Class_Extensions)?.flatMap(
  ({ id }) => [id, ...collectSubTypes(objectStore, id)]
) ?? [];

export interface TypeHelperRevision1 extends MigrationHelper {
  type: (typeId: string, options?: { of?: string, extends?: string }) => void,
  property: (typeId: string, propertyId: string) => void,
  relation: (typeId: string, relationId: string, targetTypeId: string) => void,
  association: (typeId: string, roleTargets: string[], varRolesTarget?: string) => void,
}

export const initModule: InitModuleFunction = () => ({
  id: TypeModuleId,
  migrationHelpers: {
    typeHelper: (revision, { updateObject }) => {
      if (revision >= 1) {
        const typeHelper: TypeHelperRevision1 = {
          type: (typeId, { of = ModelType, extends: extendsType } = {}) => {
            updateObject(typeId, {
              [Instance_Of]: of,
              [Class_Extend]: extendsType,
            });
          },
          property: (typeId, propertyId) => {
            updateObject(propertyId, {
              [Instance_Of]: ModelProperty,
              [Property_OfClass]: typeId,
            });
          },
          relation: (typeId, relationId, targetTypeId) => {
            updateObject(relationId, {
              [Instance_Of]: ModelProperty,
              [Property_OfClass]: typeId,
              [Property_TargetClass]: targetTypeId,
            });
          },
          association: (associationId, roleTargets, varRolesTarget) => {
            updateObject(associationId, {
              [Instance_Of]: ModelAssociation,
              [Association_RoleTypes]: roleTargets.join('|'),
              [Association_VarRolesType]: varRolesTarget,
            });
          },
        };
        return typeHelper;
      } else {
        return undefined;
      }
    },
  },
  migrations,
  registerGarbageCollectorRules: (objectStore, { register }) => {
    register(instanceOfGarbageRuleRegistration(objectStore));
    register(extendsGarbageRuleRegistration(objectStore));
  },
  registerPropertyFunctions: (objectStore, { registerPropertyFunction }) => {
    registerPropertyFunction(Class_IsInstanceOf, (id) => {
      const cache = new Map<string, boolean>();
      return (typeId: string) => {
        if (cache.has(typeId)) {
          return cache.get(typeId);
        } else {
          const result = isInstanceOfPrivate(objectStore.getObject(id), typeId);
          cache.set(typeId, result);
          return result;
        }
      };
    });

    return (id, properties, typeRegistrations) => {
      let objectId: string | undefined;
      if (Array.isArray(id) && id.length === 1) {
        [objectId] = id;
      } else if (!Array.isArray(id)) {
        objectId = id;
      }
      if (objectId !== undefined) {
        const currentType = properties?.[Instance_Of] as string | undefined ?? objectStore.getObjectOrNull(id)?.[Instance_Of] as string | undefined;
        const parentTypes = collectTypes(objectStore, currentType);
        parentTypes.forEach(
          (typeId) => {
            typeRegistrations[typeId]?.forEach((handler) => {
              if (objectId !== undefined) {
                handler(objectId, properties);
              }
            });
          }
        );
      }
    };
  },
  registerPropertyFunctionsWithTimeseries: (objectStore) => (
    (id, properties, typeRegistrations) => {
      let objectId: string | undefined;
      if (Array.isArray(id) && id.length === 1) {
        [objectId] = id;
      } else if (!Array.isArray(id)) {
        objectId = id;
      }
      if (objectId !== undefined) {
        const currentType = properties?.[Instance_Of] as string | undefined ?? objectStore.getObjectOrNull(id)?.[Instance_Of] as string | undefined;
        const parentTypes = collectTypes(objectStore, currentType);
        parentTypes.forEach(
          (typeId) => {
            typeRegistrations[typeId]?.forEach((handler) => {
              if (objectId !== undefined) {
                handler(objectId, properties);
              }
            });
          }
        );
      }
    }
  ),
  registerBusinessRules: (objectStore, { onObject, onProperty }) => {
    onObject('*').validate(checkHasType(objectStore));
    onObject('*').validate(checkPropertiesOfTypeConstraints(objectStore));
    onObject('*').validate(checkAssociationRolesExists(objectStore));
    onObject('*').validate(allowDeleteOnUndefinedProperty(objectStore));
    onProperty(Instance_Of).validate(checkInstanceOfImmutableConstraint(objectStore));
    onProperty(Class_Extend).validate(checkExtendsImmutableConstraint(objectStore));
    onProperty(Class_Extend).validate(checkCyclicExtendsConstraint(objectStore));
    onProperty(Class_IsExternal).validate(allowPropOnAnything('isExternal'));
    onProperty(Property_OfClass).validate(allowPropOnAnything('propertyOfClass'));
    onProperty(Property_TargetClass).validate(allowPropOnAnything('propertyTargetClass'));
    onProperty(Association_RoleTypes).validate(allowPropOnAnything('associationRoleTypes'));

    return {
      onInit: (objectBusinessRules) => {
        const { typeRegistrations } = objectBusinessRules;
        Object.entries(typeRegistrations)
          .forEach(([typeId, registerHandlers]) => {
            [typeId, ...collectSubTypes(objectStore, typeId)]
              .forEach((currentTypeId) => {
                objectStore.getObjectOrNull(currentTypeId)
                  ?.navigateBack(Class_Instances)
                  .forEach(({ id }) => {
                    registerHandlers.forEach((register) => register([id]));
                  });
              });
          });
      },
      onUpdate: (id: string | string[], properties: Record<string, unknown> | null, objectBusinessRules: ObjectBusinessRules) => {
        const { typeRegistrations } = objectBusinessRules;
        let objectId: string | undefined;
        if (Array.isArray(id) && id.length === 1) {
          [objectId] = id;
        } else if (!Array.isArray(id)) {
          objectId = id;
        }
        if (objectId !== undefined && !objectStore.getObjectOrNull(id)) {
          const currentType = properties?.[Instance_Of] as string;
          const parentTypes = collectTypes(objectStore, currentType);
          parentTypes.forEach(
            (typeId) => {
              // If we handle a newly created object, we can register dynamic business rules
              typeRegistrations[typeId]?.forEach((register) => register(Array.isArray(id) ? id : [id]));
            }
          );
        }
      },
      getRuleLibraryKeys: (store, operation) => {
        const { id: objectId, properties: newProperties } = operation;
        const currentObject = store.getObjectOrNull(objectId);
        const objectType = Array.isArray(objectId) && objectId.length > 1 ? objectId[0] : newProperties?.[Instance_Of] as string || currentObject?.[Instance_Of] as string;
        return collectTypes(store, objectType);
      },
    };
  },
  registerAccessControlList: (_, { onObject }) => {
    const MODEL_TYPE = 'MODEL_TYPE';
    onObject(MODEL_TYPE).allow('READ', () => ({ rule: 'typeModule.read.allow', status: ValidationStatus.ACCEPTED }));
    onObject(MODEL_TYPE).allow('WRITE', () => ({ rule: 'typeModule.write.forbidden', status: ValidationStatus.REJECTED }));
    onObject(MODEL_TYPE).allow('DELETE', () => ({ rule: 'typeModule.delete.forbidden', status: ValidationStatus.REJECTED }));

    onObject(ModelType).allow('READ', () => ({ rule: 'modelType.read.allow', status: ValidationStatus.ACCEPTED }));
    onObject(ModelType).allow('DELETE', (__, objectId) => ({ rule: 'modelType.delete.delegate', status: ValidationStatus.DELEGATED, targetId: objectId, targetAction: 'WRITE' }));

    onObject(ModelProperty).allow('READ', () => ({ rule: 'modelProperty.read.allow', status: ValidationStatus.ACCEPTED }));
    onObject(ModelProperty).allow('DELETE', () => ({ rule: 'modelProperty.delete.forbidden', status: ValidationStatus.REJECTED }));

    onObject(ModelAssociation).allow('READ', () => ({ rule: 'modelAssociation.read.allow', status: ValidationStatus.ACCEPTED }));
    onObject(ModelAssociation).allow('DELETE', () => ({ rule: 'modelAssociation.delete.forbidden', status: ValidationStatus.REJECTED }));

    return {
      getACLLibraryKeys: (store, operation) => {
        const { id: objectId, properties: newProperties } = operation;
        if (objectId.length === 1 && [
          ModelType, ModelProperty, ModelAssociation,
          Association_RoleTypes, Association_VarRolesType,
          Instance_Of, Class_Extend, Class_IsExternal,
          Property_OfClass, Property_TargetClass,
        ].includes(objectId[0])) {
          return [MODEL_TYPE];
        }

        const currentObject = store.getObjectOrNull(objectId);
        const objectType = Array.isArray(objectId) && objectId.length > 1 ? objectId[0] : newProperties?.[Instance_Of] as string || currentObject?.[Instance_Of] as string;
        return collectTypes(store, objectType);
      },
    };
  },
  initializationState: {
    getState: () => [
      { id: ModelType, properties: {} },
      { id: ModelProperty, properties: {} },
      { id: ModelAssociation, properties: {} },
      { id: Association_RoleTypes, properties: {} },
      { id: Association_VarRolesType, properties: {} },
      { id: Class_IsExternal, properties: {} },
      { id: Instance_Of, properties: {} },
      { id: Class_Extend, properties: {} },
      { id: Property_OfClass, properties: {} },
      { id: Property_TargetClass, properties: {} },
    ],
  },
});

export const testables = {
  checkHasType,
  checkPropertiesOfTypeConstraints,
  checkCyclicExtendsConstraint,
  checkInstanceOfImmutableConstraint,
};
