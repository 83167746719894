import * as d3Scale from 'd3-scale';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import type { FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import {
  associationFieldHandler,
  displayInstanceFieldAsText,
  getConceptUrl,
  getFieldDimensionOfModelType,
  idFieldHandler,
  isConceptValid,
  isEmbeddedAsIntegrationOnly,
  numberFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import {
  Concept_FunctionalId,
  Concept_Name,
  ConceptDefinition_Color,
  ConceptDefinition_MatrixAbs,
  ConceptDefinition_MatrixDependency,
  ConceptDefinition_MatrixLabel,
  ConceptDefinition_MatrixOrd,
  ConceptFunctionalIdDimension,
} from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { StoreObject } from 'yooi-store';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import Typo from '../../../../../components/atoms/Typo';
import Quadrant, { horizontalAxisEstimatedHeightRem, innerQuadrantPaddingRem, yMarginBottomRem, yMarginTopRem } from '../../../../../components/charts/Quadrant';
import type { ProgressFieldData } from '../../../../../components/charts/TimelineEntry';
import CompositeField, { CompositeFieldVariants } from '../../../../../components/molecules/CompositeField';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import BlockContent from '../../../../../components/templates/BlockContent';
import VerticalBlock from '../../../../../components/templates/VerticalBlock';
import useAcl from '../../../../../store/useAcl';
import useActivity from '../../../../../store/useActivity';
import useStore from '../../../../../store/useStore';
import useUpdateActivity from '../../../../../store/useUpdateActivity';
import base from '../../../../../theme/base';
import { Spacing, spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import { remToPx } from '../../../../../utils/sizeUtils';
import useNavigation from '../../../../../utils/useNavigation';
import { SessionStorageKeys, useSessionStorageState } from '../../../../../utils/useSessionStorage';
import { SizeContextProvider, SizeVariant } from '../../../../../utils/useSizeContext';
import useTheme from '../../../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../../../utils/useUsageContext';
import { getConceptModelDisplayField, resolveConceptColorValue } from '../../../conceptDisplayUtils';
import { DisplayedLine } from '../../../ConceptViewTopBar';
import { getFieldLabel, getInstanceMaxMinValues, TickResolutionStatus } from '../../../fieldUtils';
import { ConceptDefinitionFavoriteFiltersBar } from '../../../filter/FavoriteFiltersBar';
import type { FilterConfiguration } from '../../../filter/useFilterSessionStorage';
import { getConceptFilters } from '../../../listFilterFunctions';
import {
  getChipOptions,
  getColorField,
  getMatrixAbsFieldId,
  getMatrixOrdFieldId,
  listColorFieldOptions,
  listDependenciesFieldOptions,
  listLabelByFieldOptions,
  listMatrixFieldOptions,
} from '../../../modelTypeUtils';
import ActivityIndicator from '../../../multiplayer/ActivityIndicator';
import type { NavigationFilter } from '../../../navigationUtils';
import type { MatrixConfiguration } from '../../../sessionStorageTypes';
import ConceptBacklog from '../backlog/ConceptBacklog';
import ConceptMatrixTooltip from './ConceptMatrixTooltip';

const entryTotalHeightRem = 5.1;
const useStyles = makeStyles({
  matrixContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    flexGrow: 1,
  },
  buttonOptions: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.s,
  },
  multiplayerIndicatorContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  multiplayerHeaderSpacer: {
    height: '2.8rem',
  },
  multiplayerEntryLine: {
    height: `${entryTotalHeightRem}rem`,
    display: 'flex',
    alignItems: 'center',
  },
  quadrantContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
  },
  optionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: spacingRem.s,
    gridColumnStart: 2,
  },
}, 'conceptMatrix');

const quadrantHeightInRem = 65;

interface MatrixEntry {
  id: string,
  label: { id?: string, name: string | undefined },
  rightText?: string | undefined,
  progress?: ProgressFieldData | undefined,
  instanceOf: string,
  name: string | undefined,
  absValue: number | undefined,
  ordValue: number | undefined,
  colorRawValue: string | undefined,
  color: string | undefined,
  isDraggable?: boolean,
}

interface ConceptMatrixProps {
  filterKey: string,
  generateList: () => StoreObject[],
  conceptDefinitionId: string,
  readOnly?: boolean,
  displayedLine?: DisplayedLine,
}

const ConceptMatrix: FunctionComponent<ConceptMatrixProps> = ({
  filterKey,
  generateList,
  conceptDefinitionId,
  readOnly = false,
  displayedLine,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const { canWriteObject } = useAcl();

  const activity = useActivity();
  const updateActivity = useUpdateActivity();

  const navigation = useNavigation<NavigationFilter>();

  const { ref: containerRef, width: containerWidth } = useResizeObserver();

  const showOptions = displayedLine === DisplayedLine.options;
  const showFilters = displayedLine === DisplayedLine.filters;
  const [filtersConfiguration] = useSessionStorageState<FilterConfiguration | undefined>(filterKey, undefined);

  const [matrixConfig, updateMatrixConfig] = useSessionStorageState<MatrixConfiguration | undefined>(`${SessionStorageKeys.matrixConfig}_${filterKey}`, undefined);
  const doUpdateMatrixConfig = <Key extends keyof MatrixConfiguration>(key: Key, value: MatrixConfiguration[Key]) => {
    const config = matrixConfig ? { ...matrixConfig } : {
      [ConceptDefinition_Color]: undefined,
      [ConceptDefinition_MatrixOrd]: undefined,
      [ConceptDefinition_MatrixAbs]: undefined,
      [ConceptDefinition_MatrixLabel]: undefined,
      [ConceptDefinition_MatrixDependency]: undefined,
    };
    config[key] = value;
    updateMatrixConfig(config);
  };

  const colorFieldId = getColorField(store, conceptDefinitionId, matrixConfig, undefined, false)?.id;
  const labelByFieldId = getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_MatrixLabel, matrixConfig)?.id;

  const matrixOrdFieldId = getMatrixOrdFieldId(store, conceptDefinitionId, matrixConfig);
  const matrixOrdFieldHandler = matrixOrdFieldId ? numberFieldHandler(store, matrixOrdFieldId) : undefined;
  const matrixOrdFieldConfiguration = matrixOrdFieldHandler?.resolveConfiguration();
  const matrixOrdField = matrixOrdFieldId ? store.getObjectOrNull<FieldStoreObject>(matrixOrdFieldId) : undefined;
  const isMatrixOrdFieldComputed = matrixOrdFieldConfiguration?.formula;
  const matrixOrdFieldDimensionId = matrixOrdFieldId ? getFieldDimensionOfModelType(store, matrixOrdFieldId, conceptDefinitionId) : undefined;

  const matrixAbsFieldId = getMatrixAbsFieldId(store, conceptDefinitionId, matrixConfig);
  const matrixAbsFieldHandler = matrixAbsFieldId ? numberFieldHandler(store, matrixAbsFieldId) : undefined;
  const matrixAbsFieldConfiguration = matrixAbsFieldHandler?.resolveConfiguration();
  const matrixAbsField = matrixAbsFieldId ? store.getObjectOrNull<FieldStoreObject>(matrixAbsFieldId) : undefined;
  const isMatrixAbsFieldComputed = matrixAbsFieldConfiguration?.formula;
  const matrixAbsFieldDimensionId = matrixAbsFieldId ? getFieldDimensionOfModelType(store, matrixAbsFieldId, conceptDefinitionId) : undefined;

  const matrixDependencyFieldId = getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_MatrixDependency, matrixConfig)?.id;
  const matrixDependencyFieldHandler = matrixDependencyFieldId ? associationFieldHandler(store, matrixDependencyFieldId) : undefined;
  const matrixDependencyFieldDimensionId = matrixDependencyFieldId ? getFieldDimensionOfModelType(store, matrixDependencyFieldId, conceptDefinitionId) : undefined;

  const ordTitle = matrixOrdField ? getFieldLabel(store, matrixOrdField) : undefined;
  const absTitle = matrixAbsField ? getFieldLabel(store, matrixAbsField) : undefined;

  const computeAbsOptions = () => listMatrixFieldOptions(store, conceptDefinitionId).filter((field) => field.id !== matrixOrdFieldId);
  const computeOrdOptions = () => listMatrixFieldOptions(store, conceptDefinitionId).filter((field) => field.id !== matrixAbsFieldId);

  const isMatrixConfigurationValid = Boolean(matrixOrdFieldId !== null && matrixOrdFieldId !== undefined && matrixAbsFieldId !== null && matrixAbsFieldId !== undefined);

  const [isDraggingElement, setIsDraggingElement] = useState(false);

  const matrixList: MatrixEntry[] = isMatrixConfigurationValid ? generateList()
    .filter(({ id }) => isConceptValid(store, id))
    .map((instance): MatrixEntry => {
      let ordValueResolution;
      if (matrixOrdFieldHandler && matrixOrdFieldDimensionId) {
        ordValueResolution = matrixOrdFieldHandler.getValueResolution({ [matrixOrdFieldDimensionId]: instance.id });
      }
      let absValueResolution;
      if (matrixAbsFieldHandler && matrixAbsFieldDimensionId) {
        absValueResolution = matrixAbsFieldHandler.getValueResolution({ [matrixAbsFieldDimensionId]: instance.id });
      }

      const name = labelByFieldId ? displayInstanceFieldAsText(store, instance.id, labelByFieldId) : undefined;
      const id = labelByFieldId !== Concept_FunctionalId
        ? idFieldHandler(store, Concept_FunctionalId).getValueAsText?.({ [ConceptFunctionalIdDimension]: instance.id })
        : undefined;

      return {
        id: instance.id,
        instanceOf: instance[Instance_Of] as string,
        name: instance[Concept_Name] as string | undefined,
        absValue: typeof absValueResolution?.value === 'number' ? absValueResolution.value : undefined,
        ordValue: typeof ordValueResolution?.value === 'number' ? ordValueResolution.value : undefined,
        color: resolveConceptColorValue(store, instance.id, matrixConfig, undefined, false),
        colorRawValue: colorFieldId ? instance[colorFieldId] as string | undefined : undefined,
        label: { id, name },
        progress: undefined,
      };
    }) : [];

  const { min: defaultMinOrd, max: defaultMaxOrd } = matrixOrdFieldId
    ? getInstanceMaxMinValues(store, matrixOrdFieldId, undefined, {})
    : { min: { status: TickResolutionStatus.Resolved, value: 0 }, max: { status: TickResolutionStatus.Resolved, value: 100 } };

  const { min: defaultMinAbs, max: defaultMaxAbs } = matrixAbsFieldId
    ? getInstanceMaxMinValues(store, matrixAbsFieldId, undefined, {})
    : { min: { status: TickResolutionStatus.Resolved, value: 0 }, max: { status: TickResolutionStatus.Resolved, value: 100 } };

  const { minOrd, maxOrd, minAbs, maxAbs } = matrixList.reduce((acc, { id, absValue, ordValue }) => {
    const { min: instanceMinOrd, max: instanceMaxOrd } = matrixOrdFieldId ? getInstanceMaxMinValues(store, matrixOrdFieldId, id, {}) : { min: undefined, max: undefined };
    const { min: instanceMinAbs, max: instanceMaxAbs } = matrixAbsFieldId ? getInstanceMaxMinValues(store, matrixAbsFieldId, id, {}) : { min: undefined, max: undefined };

    return {
      minOrd: Math.min(acc.minOrd, (instanceMinOrd?.status === TickResolutionStatus.Resolved ? instanceMinOrd.value : undefined) ?? ordValue ?? 0),
      maxOrd: Math.max(acc.maxOrd, (instanceMaxOrd?.status === TickResolutionStatus.Resolved ? instanceMaxOrd.value : undefined) ?? ordValue ?? 0),
      minAbs: Math.min(acc.minAbs, (instanceMinAbs?.status === TickResolutionStatus.Resolved ? instanceMinAbs.value : undefined) ?? absValue ?? 0),
      maxAbs: Math.max(acc.maxAbs, (instanceMaxAbs?.status === TickResolutionStatus.Resolved ? instanceMaxAbs.value : undefined) ?? absValue ?? 0),
    };
  }, {
    minOrd: (defaultMinOrd?.status === TickResolutionStatus.Resolved ? defaultMinOrd.value : undefined) ?? Number.MAX_SAFE_INTEGER,
    maxOrd: (defaultMaxOrd?.status === TickResolutionStatus.Resolved ? defaultMaxOrd.value : undefined) ?? Number.MIN_SAFE_INTEGER,
    minAbs: (defaultMinAbs?.status === TickResolutionStatus.Resolved ? defaultMinAbs.value : undefined) ?? Number.MAX_SAFE_INTEGER,
    maxAbs: (defaultMaxAbs?.status === TickResolutionStatus.Resolved ? defaultMaxAbs.value : undefined) ?? Number.MIN_SAFE_INTEGER,
  });

  const isInMatrix = (entry: MatrixEntry): entry is Omit<MatrixEntry, 'absValue' | 'ordValue'> & { absValue: number, ordValue: number } => {
    const { absValue, ordValue } = entry;
    return absValue !== undefined && ordValue !== undefined && ordValue >= minOrd && ordValue <= maxOrd && absValue >= minAbs && absValue <= maxAbs;
  };

  const backlogData = matrixList.filter((entry) => !isInMatrix(entry));

  const quadrantData = matrixList
    .filter(isInMatrix)
    .map(({ id, label, absValue, ordValue, color }) => ({
      key: id,
      label,
      color,
      x: absValue,
      y: ordValue,
      draggable: canWriteObject(id) && !readOnly && !isEmbeddedAsIntegrationOnly(store.getObject(id)),
      clickable: true,
      dependingOn: matrixDependencyFieldHandler && matrixDependencyFieldDimensionId
        ? matrixDependencyFieldHandler.getValueResolution({ [matrixDependencyFieldDimensionId]: id }).value.map((a) => a.id)
        : [],
    }));

  const svgHeight = remToPx(quadrantHeightInRem);
  const chartHeight = svgHeight - remToPx(yMarginTopRem) - remToPx(horizontalAxisEstimatedHeightRem) - remToPx(yMarginBottomRem);
  const axisEdgeMargin = remToPx(innerQuadrantPaddingRem);
  const scaleY = d3Scale
    .scaleLinear()
    .domain([minOrd, maxOrd])
    .nice()
    .range([chartHeight - axisEdgeMargin, axisEdgeMargin]);

  const multiplayerHeightPx = remToPx(entryTotalHeightRem);

  const topOffset = scaleY(maxOrd);

  const multiplayerData = matrixList
    .filter((instance) => !backlogData.some((i) => i.id === instance.id))
    .reduce((accumulator, instance) => {
      // Remove 1 to always push the limit value to the top above
      const yPos = Math.round(scaleY(instance?.ordValue ?? 0)) - topOffset - 1;
      const mod = yPos % multiplayerHeightPx;
      const line = Math.max(0, (yPos - mod) / multiplayerHeightPx);
      const result = [];
      for (let i = 0; i < Math.max(line + 1, accumulator.length); i += 1) {
        result[i] = i === line ? [...(accumulator[line] ?? []), instance.id] : [...(accumulator[i] ?? [])];
      }

      return result;
    }, [] as string[][]);

  const handleDrop = !isMatrixConfigurationValid ? undefined : (droppedConceptId: string, position?: { x: number, y: number }) => {
    const dropped = matrixList.find(({ id }) => droppedConceptId === id);
    setIsDraggingElement(false);

    if (!dropped) {
      return;
    }

    store.updateObject(droppedConceptId, joinObjects(
      matrixAbsFieldId && !isMatrixAbsFieldComputed ? { [matrixAbsFieldId]: position?.x ?? null } : {},
      matrixOrdFieldId && !isMatrixOrdFieldComputed ? { [matrixOrdFieldId]: position?.y ?? null } : {}
    ));
  };

  const handleDragStart = !isMatrixConfigurationValid ? undefined : (data: { key: string, x?: number, y?: number }) => {
    if (matrixOrdFieldId) {
      updateActivity.onEnterEdition(data.key, matrixOrdFieldId);
    }
    if (matrixAbsFieldId) {
      updateActivity.onEnterEdition(data.key, matrixAbsFieldId);
    }
    // on Chrome changing the dom in the dragStart event trigger a dragEnd event the setTimeout 0 is a simple hack to prevent this
    setTimeout(() => {
      setIsDraggingElement(true);
    }, 0);
  };
  const handleDragEnd = !isMatrixConfigurationValid ? undefined : (data: { key: string }) => {
    if (matrixOrdFieldId) {
      updateActivity.onExitEdition(data.key, matrixOrdFieldId);
    }
    if (matrixAbsFieldId) {
      updateActivity.onExitEdition(data.key, matrixAbsFieldId);
    }
    setIsDraggingElement(false);
  };

  const navigateToConcept = (conceptId: string) => {
    const conceptUrl = getConceptUrl(store, conceptId);
    navigation.push(conceptId, {
      pathname: conceptUrl,
      navigationFilters: {
        globalFilters: getConceptFilters(store, conceptDefinitionId, filtersConfiguration),
        globalParametersMapping: {},
      },
    });
  };

  const renderTooltip = (conceptId: string, editMode: boolean, currentAnchor: EventTarget, handleClose: () => void) => {
    const instance = matrixList.find(({ id }) => conceptId === id);
    if (!instance) {
      return null;
    }

    const handleSubmit = (idParam: string, data: { ordValue: number | null, absValue: number | null, color: string | null }) => {
      const { color, ordValue, absValue } = data;
      const oldValues = store.getObject(idParam);
      store.updateObject(idParam, joinObjects(
        colorFieldId && oldValues[colorFieldId] !== color ? { [colorFieldId]: color } : {},
        matrixOrdFieldId && !isMatrixOrdFieldComputed && oldValues[matrixOrdFieldId] !== ordValue ? { [matrixOrdFieldId]: ordValue } : {},
        matrixAbsFieldId && !isMatrixAbsFieldComputed && oldValues[matrixAbsFieldId] !== absValue ? { [matrixAbsFieldId]: absValue } : {}
      ));
    };

    return (
      <ConceptMatrixTooltip
        concept={instance}
        matrixOrdFieldId={matrixOrdFieldId}
        matrixAbsFieldId={matrixAbsFieldId}
        colorFieldId={colorFieldId}
        editMode={editMode && canWriteObject(conceptId) && !readOnly && !isEmbeddedAsIntegrationOnly(store.getObject(conceptId))}
        buttonView={editMode}
        currentAnchor={currentAnchor as HTMLElement}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        readOnly={readOnly}
      />
    );
  };

  const multiplayerIndicatorPropertyIds = [matrixOrdFieldId, matrixAbsFieldId].filter(filterNullOrUndefined);
  const options = (
    <>
      <Typo color={theme.color.text.secondary}>{i18n`Color by`}</Typo>
      <SearchAndSelect
        clearable
        computeOptions={() => listColorFieldOptions(store, conceptDefinitionId)}
        selectedOption={colorFieldId ? getChipOptions(store, colorFieldId) : undefined}
        onSelect={(value) => doUpdateMatrixConfig(ConceptDefinition_Color, value?.id ?? null)}
      />
      <CompositeField
        variant={CompositeFieldVariants.button}
        width="41.2rem"
        headerLinesRenderers={[
          {
            id: 'title',
            render: () => (
              <div className={classes.buttonOptions}>
                {isMatrixConfigurationValid
                  ? null
                  : (<Icon name={IconName.dangerous} tooltip={i18n`Matrix field missing`} colorVariant={IconColorVariant.error} />)}
                <Typo>{i18n`Options`}</Typo>
              </div>
            ),
          },
        ]}
        getDropdownSectionDefinitions={() => [
          {
            id: 'concept-matrix',
            lines: [
              {
                id: 'concept-matrix-ord-field',
                title: i18n`Matrix ord`,
                render: (
                  <SearchAndSelect
                    computeOptions={computeOrdOptions}
                    selectedOption={matrixOrdFieldId ? getChipOptions(store, matrixOrdFieldId) : undefined}
                    onSelect={(value) => doUpdateMatrixConfig(ConceptDefinition_MatrixOrd, value?.id ?? null)}
                  />
                ),
              },
              {
                id: 'concept-matrix-abs-field',
                title: i18n`Matrix abs`,
                render: (
                  <SearchAndSelect
                    computeOptions={computeAbsOptions}
                    selectedOption={matrixAbsFieldId ? getChipOptions(store, matrixAbsFieldId) : undefined}
                    onSelect={(value) => doUpdateMatrixConfig(ConceptDefinition_MatrixAbs, value?.id ?? null)}
                  />
                ),
              },
              {
                id: 'concept-matrix-label',
                title: i18n`Label`,
                render: (
                  <SearchAndSelect
                    clearable
                    computeOptions={() => listLabelByFieldOptions(store, conceptDefinitionId)}
                    selectedOption={labelByFieldId ? getChipOptions(store, labelByFieldId) : undefined}
                    onSelect={(value) => doUpdateMatrixConfig(ConceptDefinition_MatrixLabel, value?.id ?? null)}
                  />),
              },
              {
                id: 'concept-dependencies',
                title: i18n`Dependencies`,
                render: (
                  <SearchAndSelect
                    clearable
                    computeOptions={() => listDependenciesFieldOptions(store, conceptDefinitionId)}
                    selectedOption={matrixDependencyFieldId ? getChipOptions(store, matrixDependencyFieldId) : undefined}
                    onSelect={(value) => doUpdateMatrixConfig(ConceptDefinition_MatrixDependency, value?.id ?? null)}
                  />
                ),
              },
            ],
          },
        ]}
      />
    </>
  );
  // Relative is for positioning the tooltip, and the flex is to handle the margin-collapsing issue
  return (
    <>
      <VerticalBlock asBlockContent compact>
        {showOptions && (
          <div className={classes.optionsContainer}>
            <UsageContextProvider usageVariant={UsageVariant.inForm}>
              <SizeContextProvider sizeVariant={SizeVariant.small}>
                {options}
              </SizeContextProvider>
            </UsageContextProvider>
          </div>
        )}
        {showFilters && (
          <BlockContent padded>
            <ConceptDefinitionFavoriteFiltersBar filterKey={filterKey} conceptDefinitionId={conceptDefinitionId} />
          </BlockContent>
        )}
        <BlockContent
          padded
          action={(
            <div className={classes.multiplayerIndicatorContainer}>
              <div className={classes.multiplayerHeaderSpacer} />
              {multiplayerData.map((conceptIds, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className={classes.multiplayerEntryLine}>
                  <ActivityIndicator propertyIds={multiplayerIndicatorPropertyIds} instanceIds={conceptIds} padding={Spacing.none} />
                </div>
              ))}
            </div>
          )}
        >
          <div className={classes.matrixContainer}>
            <div ref={containerRef} className={classes.quadrantContainer}>
              {
                containerWidth === undefined
                  ? null
                  : (
                    <Quadrant
                      yName={matrixOrdFieldConfiguration?.unit ? `${ordTitle} ${matrixOrdFieldConfiguration.unit}` : ordTitle}
                      yMin={Math.min(minOrd, maxOrd)}
                      yMax={Math.max(minOrd, maxOrd)}
                      yCanDrag={!isMatrixOrdFieldComputed}
                      xName={matrixAbsFieldConfiguration?.unit ? `${absTitle} ${matrixAbsFieldConfiguration.unit}` : absTitle}
                      xMin={Math.min(minAbs, maxAbs)}
                      xMax={Math.max(minAbs, maxAbs)}
                      xCanDrag={!isMatrixAbsFieldComputed}
                      topLeft={{ name: undefined, color: base.color.peach['100'] }}
                      topRight={{ name: undefined, color: base.color.teagreen['100'] }}
                      bottomLeft={{ name: undefined, color: base.color.purple['100'] }}
                      bottomRight={{ name: undefined, color: base.color.cyan['100'] }}
                      data={quadrantData}
                      heightPx={remToPx(quadrantHeightInRem)}
                      widthPx={containerWidth}
                      onElementDrag={handleDragStart}
                      onElementDragEnd={handleDragEnd}
                      onElementDrop={handleDrop}
                      isDragging={isDraggingElement}
                      renderTooltip={renderTooltip}
                      showDependencies={Boolean(matrixDependencyFieldId)}
                      isEntryEditedByOtherUser={(id) => multiplayerIndicatorPropertyIds.some((fieldId) => activity.listEditor(id, fieldId).length > 0)}
                      onDoubleClick={navigateToConcept}
                    />
                  )
              }
            </div>
          </div>
        </BlockContent>
      </VerticalBlock>
      <ConceptBacklog
        title={i18n`Unassessed`}
        list={backlogData}
        onElementDrag={handleDragStart ? ({ id }) => handleDragStart({ key: id }) : undefined}
        onElementDragEnd={handleDragEnd ? ({ id }) => handleDragEnd({ key: id }) : undefined}
        onElementDrop={handleDrop}
        dragLabelFieldId={labelByFieldId}
        renderTooltip={renderTooltip}
        multiplayerPropertyIds={[Concept_Name, ...multiplayerIndicatorPropertyIds]}
        readOnly={readOnly}
        iconText={i18n`You can drag and drop instances directly to the matrix`}
      />
    </>
  );
};

export default ConceptMatrix;
