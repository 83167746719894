import type { FunctionComponent } from 'react';
import type { AutomationRuleParameters } from 'yooi-modules/modules/automationModule';
import { escapeQueryLabel } from 'yooi-modules/modules/automationModule';
import type { MultipleParameterDefinition, PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { isMultiplePath } from 'yooi-modules/modules/conceptModule';
import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { isFiniteNumber, joinObjects } from 'yooi-utils';
import { ButtonVariant } from '../../../components/atoms/Button';
import { IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import SpacingLine from '../../../components/molecules/SpacingLine';
import DataTable from '../../../components/templates/DataTable';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import StoreTextInputField from '../../_global/input/StoreTextInputField';
import PathMappingAndFiltersInput from '../../_global/path/PathMappingAndFiltersInput';
import PathStepsInput from '../../_global/path/PathStepsInput';
import { createPathConfigurationHandler } from '../../_global/pathConfigurationHandler';
import { shouldDisplayVariableAsPlaceholder } from './automationUtils';

interface AutomationParametersListProps {
  parametersList: AutomationRuleParameters,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
  onChange: (value: Record<string, PathStep[]>) => void,
}

interface AutomationRuleParameter {
  id: string,
  path: PathStep[],
}

const defaultVariableName = i18n`For each variable`;

const computeName = (name: string, inputsObject: AutomationRuleParameters): string => {
  const computedName = escapeQueryLabel(name === '' ? defaultVariableName : name);
  if (inputsObject[computedName]) {
    const split = name.split(/(\d+)/);
    if (split.length > 2 && split[split.length - 1] === '' && isFiniteNumber(split[split.length - 2])) {
      return escapeQueryLabel(computeName(`${split.slice(0, split.length - 2).join('')}${parseInt(split[split.length - 2], 10) + 1}`, inputsObject));
    } else {
      return escapeQueryLabel(computeName(`${computedName}1`, inputsObject));
    }
  } else {
    return computedName;
  }
};

const getInputLabel = (
  store: ObjectStoreWithTimeseries,
  path: PathStep[],
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
  inputsObject: AutomationRuleParameters
): string => {
  const pathConfigurationHandler = createPathConfigurationHandler(store, parameterDefinitions ?? []);
  const label = pathConfigurationHandler.getPathSummarize(path) ?? '';
  const computedName = computeName(label !== '' ? label : defaultVariableName, inputsObject);
  return `${computedName}${isMultiplePath(store, path) ? '[i]' : ''}`;
};

const AutomationParametersList: FunctionComponent<AutomationParametersListProps> = ({ parametersList, parameterDefinitions, onChange }) => {
  const store = useStore();

  const submitChange = (fullParametersList: AutomationRuleParameters) => {
    onChange(Object.fromEntries(Object.entries(fullParametersList)
      .map(([key, { path }]) => [key, path])));
  };

  return (
    <DataTable<AutomationRuleParameter>
      fullWidth
      columnsDefinition={[
        {
          name: i18n`Name`,
          propertyId: 'id',
          width: 20,
          cellRender: ({ id, path }) => {
            const placeholder = getInputLabel(store, path, parameterDefinitions, Object.fromEntries(Object.entries(parametersList).filter(([key]) => key !== id)));
            const displayAsPlaceholder = shouldDisplayVariableAsPlaceholder(id, placeholder, defaultVariableName);
            return (
              <StoreTextInputField
                initialValue={displayAsPlaceholder ? undefined : id}
                placeholder={displayAsPlaceholder ? id : undefined}
                onSubmit={(newId) => {
                  const newIdWithDefault = computeName(newId ?? '', Object.fromEntries(Object.entries(parametersList).filter(([key]) => key !== id)));
                  const newParametersList = joinObjects(parametersList, { [newIdWithDefault]: parametersList[id] });
                  if (id !== newIdWithDefault) {
                    delete newParametersList[id];
                  }
                  submitChange(newParametersList);
                }}
              />
            );
          },
        },
        {
          name: i18n`Path`,
          propertyId: 'Path',
          width: 60,
          cellRender: ({ id, path }) => (
            <PathStepsInput
              initialPath={path}
              onSubmit={(newPath) => {
                const placeholder = getInputLabel(store, path, parameterDefinitions, Object.fromEntries(Object.entries(parametersList).filter(([key]) => key !== id)));
                const newId = getInputLabel(store, newPath, parameterDefinitions, Object.fromEntries(Object.entries(parametersList).filter(([key]) => key !== id)));
                const displayAsPlaceholder = shouldDisplayVariableAsPlaceholder(id, placeholder, defaultVariableName);
                submitChange(Object.fromEntries(Object.entries(parametersList)
                  .map(([oldId, { path: oldPath }]) => [oldId === id && displayAsPlaceholder ? newId : oldId, oldId === id ? { path: newPath } : {
                    path: oldPath,
                  }])));
              }}
              parameterDefinitions={parameterDefinitions}
              placeholder={i18n`Select a path`}
            />
          ),
        },
        {
          propertyId: 'filters',
          name: i18n`Filters`,
          width: 20,
          cellRender: ({ id, path }) => (
            <PathMappingAndFiltersInput
              path={path}
              onChange={(newPath) => {
                submitChange(joinObjects(parametersList, { [id]: { path: newPath } }));
              }}
              parameterDefinitions={parameterDefinitions}
            />
          ),
        },
        {
          propertyId: 'Delete',
          action: true,
          cellRender: ({ id }) => (
            <SpacingLine>
              <IconOnlyButton
                onClick={() => {
                  const newParams = { ...parametersList };
                  delete newParams[id];
                  submitChange(newParams);
                }}
                iconName={IconName.delete}
                tooltip={i18n`Delete`}
                variant={IconOnlyButtonVariants.danger}
              />
            </SpacingLine>
          ),
        },
      ]}
      list={Object.entries(parametersList).map(([key, value]) => ({
        key,
        type: 'item',
        item: joinObjects({ id: key }, value),
        color: undefined,
      }))}
      newItemIcon={IconName.add}
      newItemTitle={i18n`Add`}
      newItemButtonVariant={ButtonVariant.tertiary}
      onNewItem={Object.keys(parametersList).length > 0 ? undefined : () => submitChange(joinObjects(parametersList, { [computeName('', parametersList)]: { path: [] } }))}
    />
  );
};

export default AutomationParametersList;
