import type {
  DimensionsMapping,
  NumberColorStepValueResolved,
  NumberFieldStoreObject,
  ParametersMapping,
  PathStep,
  TimeseriesNumberFieldStoreObject,
} from 'yooi-modules/modules/conceptModule';
import {
  colorFieldHandler,
  createValuePathResolver,
  dimensionsMappingToParametersMapping,
  getFieldUtilsHandler,
  getPathLastFieldInformation,
  isMultiFieldResolution,
  isSingleFieldResolution,
  isSingleValueResolution,
  numberFieldHandler,
  timeseriesNumberFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import { ColorField, NumberField, TimeseriesNumberField } from 'yooi-modules/modules/conceptModule/ids';
import type { DisplayOptions, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { ViewType } from 'yooi-modules/modules/dashboardModule';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { joinObjects, newError, PeriodicityType } from 'yooi-utils';
import type { Labels } from '../../../../components/charts/ChartTypes';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { formatErrorForUser } from '../../errorUtils';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { getProjectionValueLabel, getSeriesLabel } from '../common/series/viewWithSeriesFeatureUtils';
import { isSeriesStacked } from '../common/stackedSeries/viewWithStackedSeriesFeatureUtils';
import { getTemporalRange } from '../common/temporal/viewWithTemporalFeatureUtils';
import { getColorForValue, resolveChartColors } from '../common/viewUtils';
import { computeDimension, DataResolutionError, getDimensionCompositions } from '../data/dataResolution';
import type { ViewResolution, ViewResolutionError } from '../viewResolutionUtils';
import type { TemporalBarChartViewResolvedDefinition } from './temporalBarChartViewHandler';

export type TemporalBarChartViewResolution = TemporalBarChartResolutionLoaded | TemporalBarChartResolutionLoading;

interface Domain {
  xDomain: [number, number],
  yDomain: [number, number],
}

interface TemporalBarChartSeriesRowInfo {
  key: string,
}

export interface TemporalBarChartSeriesRowValue {
  x: Date,
  y: number,
  color?: string,
  time: number,
  value: number,
}

interface TemporalBarChartSeriesStackedRowInfo {
  key: string,
  dimensionsMapping: DimensionsMapping,
  colorDimensionsMapping: DimensionsMapping,
  legendLabel: string,
  color?: string,
  unit: string | undefined,
}

interface TemporalBarChartStackedSeriesRow {
  key: string,
  domain: Domain,
  info: TemporalBarChartSeriesStackedRowInfo,
  values: TemporalBarChartSeriesRowValue[],
  error?: Error,
  loading: boolean,
}

interface TemporalBarChartSeriesRow {
  key: string,
  domain: Domain,
  info: TemporalBarChartSeriesRowInfo,
  stackedRows: TemporalBarChartStackedSeriesRow[],
  hasNoData: boolean,
  error?: Error,
  loading: boolean,
}

interface TemporalBarChartSeriesInfo {
  key: string,
  fieldId: string,
  label: string,
  color?: string,
  colorPath?: PathStep[],
  periodicity: PeriodicityType,
  unit?: string,
}

interface TemporalBarChartSeries {
  key: string,
  domain: Domain,
  info: TemporalBarChartSeriesInfo,
  rows: TemporalBarChartSeriesRow[],
  hasNoData: boolean,
  error?: string,
}

export interface TemporalBarChartResolutionLoaded {
  type: ViewType.TemporalBarChart,
  error?: string,
  loading: false,
  domain: Domain,
  series: TemporalBarChartSeries[],
  periodicity: PeriodicityType | undefined,
  seriesStacked: boolean,
  minValue: NumberColorStepValueResolved,
  maxValue: NumberColorStepValueResolved,
  steps: NumberColorStepValueResolved[],
  labels: Labels[],
}

const isTemporalBarChartViewResolution = (viewResolution: ViewResolution): viewResolution is TemporalBarChartViewResolution => (
  viewResolution.type === ViewType.TemporalBarChart
);

export const isTemporalBarChartLoaded = (viewResolution: ViewResolution): viewResolution is TemporalBarChartResolutionLoaded => (
  isTemporalBarChartViewResolution(viewResolution) && !viewResolution.loading
);

interface TemporalBarChartResolutionLoading {
  type: ViewType.TemporalBarChart,
  loading: true,
}

export const resolveTemporalBarChart = (
  store: FrontObjectStore,
  viewDimensions: ViewDimension[],
  viewDefinition: TemporalBarChartViewResolvedDefinition,
  parametersMapping: ParametersMapping,
  filterConfiguration?: FilterConfiguration
): TemporalBarChartViewResolution | ViewResolutionError => {
  const { series: seriesDefinitions, minValue: graphMinValue, maxValue: graphMaxValue, rangeValues: graphRangeValues } = viewDefinition;

  if (seriesDefinitions.length === 0) {
    return { type: 'error', error: i18n`Missing series` };
  }

  const temporalRange = getTemporalRange(viewDefinition);
  if (!temporalRange) {
    return { type: 'error', error: i18n`Missing time range` };
  }
  const { from: dateFrom, to: dateTo } = temporalRange;

  const { mapReduceHandler: computeDimensionHandler } = computeDimension(store, parametersMapping, viewDimensions, filterConfiguration);

  // Managing dimension
  const dimensionCompositions = getDimensionCompositions(computeDimensionHandler, viewDimensions, viewDefinition.getDimensionsDisplay(viewDimensions));
  if (dimensionCompositions instanceof DataResolutionError) {
    return { type: 'error', error: formatErrorForUser(store, dimensionCompositions) };
  }
  const { xDimensionCompositions, yDimensionCompositions } = dimensionCompositions;

  const generateLabel = (
    labelDimensionsMapping: DimensionsMapping,
    seriesLabel: string,
    seriesId: string,
    displayOptions: DisplayOptions | undefined,
    fieldPath: PathStep[],
    withLegend?: boolean
  ) => {
    const onlyDimensionLabel = seriesDefinitions.length === 0 || seriesDefinitions.every(({ displayOptions: serieDisplayOption }) => !serieDisplayOption?.withLegend);
    const onlySeriesLabel = viewDimensions.length === 0 || viewDefinition.getDimensionsDisplay(viewDimensions).every((display) => !display?.withLegend);
    const stackedFieldValueResolver = createValuePathResolver(store, joinObjects(parametersMapping, dimensionsMappingToParametersMapping(labelDimensionsMapping)));
    const stackedFieldResolution = stackedFieldValueResolver.resolvePathField(fieldPath);
    if (!isSingleFieldResolution(stackedFieldResolution)) {
      return null;
    }

    const colorResolution = stackedFieldValueResolver.resolvePathValue<string>(displayOptions?.colorPath ?? []);
    const colorValue = colorResolution && isSingleValueResolution(colorResolution) && colorResolution.value ? colorResolution.value : displayOptions?.color ?? '';
    const fullProjectedValue = Object.entries(labelDimensionsMapping)
      .filter(([dimId]) => viewDefinition.getDimensionsDisplay(viewDimensions).find((dimensionDisplay) => dimensionDisplay.id === dimId)?.withLegend)
      .map(([__, dimValue]) => dimValue)
      .map((value) => getProjectionValueLabel(store, value))
      .join(' x ');
    const serieLegendLabel = withLegend ? seriesLabel : '';

    let value;
    if ((onlyDimensionLabel || onlySeriesLabel) && !(onlyDimensionLabel && onlySeriesLabel)) {
      value = { label: fullProjectedValue || serieLegendLabel, color: colorValue, key: [seriesId, ...Object.values(labelDimensionsMapping)].join('|') };
    } else if (!displayOptions?.withLegend) {
      value = null;
    } else {
      const legendLabel = `${fullProjectedValue} - ${serieLegendLabel}`;
      value = { label: legendLabel, color: colorValue, key: [seriesId, ...Object.values(labelDimensionsMapping)].join('|') };
    }

    return value;
  };

  // Managing series
  const generateRow = (
    rowDimensionsMapping: DimensionsMapping,
    seriesLabel: string,
    seriesId: string,
    fieldPath: PathStep[],
    colorPath?: PathStep[],
    withLegend?: boolean
  ): TemporalBarChartSeriesStackedRowInfo | { error: Error } => {
    const stackedFieldValueResolver = createValuePathResolver(store, joinObjects(parametersMapping, dimensionsMappingToParametersMapping(rowDimensionsMapping)));
    const stackedFieldResolution = stackedFieldValueResolver.resolvePathField(fieldPath);
    if (stackedFieldResolution instanceof Error) {
      return { error: stackedFieldResolution };
    } else if (!stackedFieldResolution) {
      return { error: newError(i18n`series path should target a field`) };
    } else if (isMultiFieldResolution(stackedFieldResolution)) {
      return { error: newError(i18n`series path should return a single field`) };
    }

    const colorFieldResolution = colorPath ? stackedFieldValueResolver.resolvePathField(colorPath) : undefined;
    const colorDimensionsMapping = isSingleFieldResolution(colorFieldResolution) ? colorFieldResolution.dimensionsMapping ?? {} : {};
    const fullProjectedValue = Object.entries(rowDimensionsMapping)
      .filter(([dimId]) => viewDefinition.getDimensionsDisplay(viewDimensions).find((dimensionDisplay) => dimensionDisplay.id === dimId)?.withLegend)
      .map(([__, dimValue]) => dimValue)
      .map((value) => getProjectionValueLabel(store, value))
      .join(' x ');
    const serieLegendLabel = withLegend ? seriesLabel : '';
    const legendLabel = fullProjectedValue && serieLegendLabel ? `${fullProjectedValue} - ${serieLegendLabel}` : `${fullProjectedValue || serieLegendLabel}`;

    let unit: string | undefined;
    const field = store.getObject(stackedFieldResolution.fieldId);
    if (isInstanceOf<NumberFieldStoreObject>(field, NumberField)) {
      unit = numberFieldHandler(store, stackedFieldResolution.fieldId)
        .resolveConfigurationWithOverride(stackedFieldResolution.dimensionsMapping ?? {})
        .unit;
    } else if (isInstanceOf<TimeseriesNumberFieldStoreObject>(field, TimeseriesNumberField)) {
      unit = timeseriesNumberFieldHandler(store, stackedFieldResolution.fieldId)
        .resolveConfigurationWithOverride(stackedFieldResolution.dimensionsMapping ?? {})
        .unit;
    }

    return {
      key: [seriesId, ...Object.values(rowDimensionsMapping)].join('|'),
      legendLabel,
      dimensionsMapping: isSingleFieldResolution(stackedFieldResolution) ? stackedFieldResolution.dimensionsMapping ?? {} : {},
      colorDimensionsMapping,
      unit,
    };
  };

  const series: (TemporalBarChartSeriesInfo & {
    colorField?: string,
    rowsInfo: {
      key: string,
      stackedRows: TemporalBarChartSeriesStackedRowInfo[],
    }[],
  })[] = [];
  const seriesPeriodicities: (PeriodicityType | undefined)[] = [];
  const labels: Labels[] = [];

  for (let seriesIndex = 0; seriesIndex < seriesDefinitions.length; seriesIndex += 1) {
    const { id, label, path, displayOptions } = seriesDefinitions[seriesIndex];
    const seriesLabel = getSeriesLabel(store, label, seriesIndex, path, viewDimensions, Object.keys(parametersMapping));
    const seriesField = getPathLastFieldInformation(path)?.fieldId;
    const colorField = displayOptions?.colorPath ? getPathLastFieldInformation(displayOptions.colorPath)?.fieldId : undefined;

    if (seriesField
      && store.getObjectOrNull(seriesField) !== null
      && ![NumberField, TimeseriesNumberField].every((fieldType) => !isInstanceOf(store.getObject(seriesField), fieldType))) {
      let unit: string | undefined;
      let periodicity = PeriodicityType.day;
      if (isInstanceOf(store.getObject(seriesField), NumberField)) {
        unit = numberFieldHandler(store, seriesField).resolveConfiguration().unit;
      } else if (isInstanceOf(store.getObject(seriesField), TimeseriesNumberField)) {
        unit = timeseriesNumberFieldHandler(store, seriesField).resolveConfiguration().unit;
      }

      if (isInstanceOf(store.getObject(seriesField), TimeseriesNumberField)) {
        const timeseriesNumberField = timeseriesNumberFieldHandler(store, seriesField).resolveConfiguration();
        periodicity = timeseriesNumberField.defaultPeriod ?? periodicity;
        seriesPeriodicities.push(periodicity);
      } else {
        seriesPeriodicities.push(undefined);
      }

      const rowLabels: Labels[] = (xDimensionCompositions.length > 0 ? xDimensionCompositions : [{}])
        .flatMap((xDimensionMapping) => (yDimensionCompositions.length > 0 ? yDimensionCompositions : [{}])
          .map((yDimensionMapping) => generateLabel(
            joinObjects(yDimensionMapping, xDimensionMapping),
            seriesLabel,
            id,
            displayOptions,
            path,
            Boolean(displayOptions?.withLegend)
          ))).filter((currentLabel): currentLabel is Labels => !!currentLabel);
      labels.push(...rowLabels);

      series.push({
        key: id,
        rowsInfo: (xDimensionCompositions.length > 0 ? xDimensionCompositions : [{}]).map((xDimensionMapping) => ({
          key: Object.values(xDimensionMapping).join('|'),
          stackedRows: (yDimensionCompositions.length > 0 ? yDimensionCompositions : [{}]).map((yDimensionMapping) => generateRow(
            joinObjects(yDimensionMapping, xDimensionMapping),
            seriesLabel,
            id,
            path,
            displayOptions?.colorPath
          )).filter((stackedRowInfo): stackedRowInfo is TemporalBarChartSeriesStackedRowInfo => !(stackedRowInfo as { error?: string }).error),
        })),
        label: seriesLabel,
        fieldId: seriesField,
        colorField,
        color: displayOptions?.color,
        colorPath: displayOptions?.colorPath,
        periodicity,
        unit,
      });
    }
  }
  const existingLabel = new Set();
  const deduplicateLabels = labels.filter(({ label, color }) => {
    const key = `${label}|${color}`;
    const isDuplicate = existingLabel.has(key);
    if (!isDuplicate) {
      existingLabel.add(key);
    }
    return !isDuplicate;
  });

  const xDomain: [number, number] = [dateFrom, dateTo];

  const temporalChartSeries: TemporalBarChartSeries[] = series.map((seriesInfo) => {
    const { rowsInfo, key, colorField } = seriesInfo;
    const rows: TemporalBarChartSeriesRow[] = rowsInfo.map((rowInfo) => {
      const stackedRows = rowInfo.stackedRows.map((stackedRowInfo) => {
        let values;
        let error;
        let rowColor;
        if (isInstanceOf(store.getObjectOrNull(seriesInfo.fieldId), TimeseriesNumberField)) {
          const { value, error: computingError } = timeseriesNumberFieldHandler(store, seriesInfo.fieldId)
            .getValueResolution(stackedRowInfo.dimensionsMapping, undefined, { from: dateFrom, to: dateTo });
          values = value;
          error = computingError;
          if (colorField) {
            const colorFieldInstance = store.getObjectOrNull(colorField);
            if (colorFieldInstance && isInstanceOf(colorFieldInstance, ColorField)) {
              rowColor = colorFieldHandler(store, colorField).getValueResolution(stackedRowInfo.colorDimensionsMapping).value;
            }
            if (colorFieldInstance && (isInstanceOf(colorFieldInstance, NumberField) || isInstanceOf(colorFieldInstance, TimeseriesNumberField))) {
              const colorInstanceId = Object.values(stackedRowInfo.colorDimensionsMapping).length === 1 ? Object.values(stackedRowInfo.colorDimensionsMapping)[0] : undefined;
              values = values?.map((v) => (joinObjects(v, { color: getColorForValue(store, colorField, v.value, colorInstanceId) })));
            }
          }
        } else {
          const { value, error: computingError } = getFieldUtilsHandler(store, seriesInfo.fieldId)
            .getValueResolution(stackedRowInfo.dimensionsMapping, undefined, { from: dateFrom, to: dateTo }, true);
          values = value;
          error = computingError;
          if (!Array.isArray(values)) {
            values = undefined;
          }
        }
        const inRangeValues = values?.filter(({ time }) => (time >= dateFrom && time <= dateTo)).map(({ value }) => value) ?? [];
        const minValue = inRangeValues.length ? Math.min(...inRangeValues.filter((inRangeValue) => inRangeValue !== undefined)) : 0;
        const maxValue = inRangeValues.length ? Math.max(...inRangeValues.filter((inRangeValue) => inRangeValue !== undefined)) : 0;

        const yDomain: [number, number] = [minValue > 0 ? 0 : minValue, maxValue];

        return {
          key: stackedRowInfo.key,
          info: joinObjects(stackedRowInfo, { color: rowColor }),
          loading: !error && !values,
          domain: {
            xDomain,
            yDomain,
          },
          error,
          values: values?.map(({ time, value, color }) => ({
            x: new Date(time),
            y: value,
            time,
            value,
            color,
          })) ?? [],
        };
      });

      return {
        key: rowInfo.key,
        info: rowInfo,
        loading: stackedRows.some(({ loading }) => loading),
        error: stackedRows.find(({ error }) => Boolean(error))?.error,
        domain: {
          xDomain,
          yDomain: [
            stackedRows.reduce((acc, s) => acc + s.domain.yDomain[0], 0),
            stackedRows.reduce((acc, s) => acc + s.domain.yDomain[1], 0),
          ],
        },
        stackedRows,
        hasNoData: stackedRows.every((stackedRow) => stackedRow.values.length === 0),
      };
    });

    const yDomain: [number, number] = [
      rows.length > 0 ? Math.min(...rows.map((s) => s.domain.yDomain[0])) : 0,
      rows.length > 0 ? Math.max(...rows.map((s) => s.domain.yDomain[1])) : 0,
    ];

    return {
      key,
      domain: {
        xDomain,
        yDomain,
      },
      info: seriesInfo,
      rows,
      hasNoData: rows.every((row) => row.hasNoData),
    };
  });

  if (temporalChartSeries.some(({ rows }) => rows.some(({ loading }) => loading))) {
    return { type: ViewType.TemporalBarChart, loading: true };
  }

  let yDomain: [number, number];

  const seriesStacked = isSeriesStacked(viewDefinition.seriesAxis);
  if (seriesStacked) {
    yDomain = [
      temporalChartSeries.length > 0 ? temporalChartSeries.reduce((acc, serie) => acc + serie.domain.yDomain[0], 0) : 0,
      temporalChartSeries.length > 0 ? temporalChartSeries.reduce((acc, serie) => acc + serie.domain.yDomain[1], 0) : 0,
    ];
  } else {
    yDomain = [
      temporalChartSeries.length > 0 ? Math.min(...temporalChartSeries.map((serie) => serie.domain.yDomain[0])) : 0,
      temporalChartSeries.length > 0 ? Math.max(...temporalChartSeries.map((serie) => serie.domain.yDomain[1])) : 0,
    ];
  }

  if (yDomain[0] > 0) {
    yDomain[0] = 0;
  }
  if (yDomain[0] === 0 && yDomain[1] === 0) {
    yDomain = [0, 10];
  } else if (yDomain[0] === yDomain[1]) {
    yDomain = [yDomain[0] - 1, yDomain[0] + 1];
  }

  let graphPeriodicity: PeriodicityType | undefined;
  if (seriesPeriodicities.some((periodicity) => periodicity === undefined)) {
    graphPeriodicity = undefined;
  } else if (seriesPeriodicities.some((periodicity) => periodicity === PeriodicityType.day)) {
    graphPeriodicity = PeriodicityType.day;
  } else if (seriesPeriodicities.some((periodicity) => periodicity === PeriodicityType.week)) {
    if (seriesPeriodicities.every((periodicity) => periodicity === PeriodicityType.week)) {
      graphPeriodicity = PeriodicityType.week;
    } else {
      graphPeriodicity = PeriodicityType.day;
    }
  } else if (seriesPeriodicities.some((periodicity) => periodicity === PeriodicityType.month)) {
    graphPeriodicity = PeriodicityType.month;
  } else if (seriesPeriodicities.some((periodicity) => periodicity === PeriodicityType.quarter)) {
    graphPeriodicity = PeriodicityType.quarter;
  } else {
    graphPeriodicity = PeriodicityType.year;
  }

  const { min, max, steps } = resolveChartColors(store, graphMinValue, graphMaxValue, graphRangeValues, parametersMapping);

  return {
    type: ViewType.TemporalBarChart,
    loading: false,
    domain: { xDomain, yDomain },
    series: temporalChartSeries,
    periodicity: graphPeriodicity,
    seriesStacked,
    labels: deduplicateLabels,
    minValue: min,
    maxValue: max,
    steps: steps?.filter(({ color, value: stepValue }) => color && (stepValue || stepValue === 0)),
  } satisfies TemporalBarChartResolutionLoaded;
};
