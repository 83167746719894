import type { FunctionComponent } from 'react';
import { validate } from 'uuid';
import { compareNumber, comparing } from 'yooi-utils';
import Icon, { IconName } from '../../../components/atoms/Icon';
import NumberPicker from '../../../components/inputs/NumberPicker';
import Banner, { BannerVariant } from '../../../components/molecules/Banner';
import Loading from '../../../components/molecules/Loading';
import SpacingLine from '../../../components/molecules/SpacingLine';
import Spinner, { SpinnerVariant } from '../../../components/molecules/Spinner';
import { TableSortDirection } from '../../../components/molecules/Table';
import TableInnerCellContainer, { TableInnerCellContainerVariants } from '../../../components/molecules/TableInnerCellContainer';
import BlockContent from '../../../components/templates/BlockContent';
import DataTable from '../../../components/templates/DataTable';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import { Opacity } from '../../../theme/base';
import { generateColorFromOpacity, getMostReadableColorFromBackgroundColor } from '../../../theme/colorUtils';
import { buildPadding, Spacing, spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useNavigation from '../../../utils/useNavigation';
import { SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import useTheme from '../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../utils/useUsageContext';
import withAsyncTask from '../../../utils/withAsyncTask';
import { FilterParams, useFilterStorage } from '../../_global/filter/useFilterSessionStorage';
import FormTextInputField from '../../_global/input/FormTextInputField';
import type { NavigationFilter } from '../../_global/navigationUtils';
import useFilterAndSort from '../../_global/useFilterAndSort';
import { EXPLORER_INSTANCE_FILTER_ID, getExplorerListComputeFunction, keyToId } from './_global/explorerUtils';
import { useExplorerHint } from './_global/GetHintContextProvider';
import { ValueType } from './_global/objectRenderType';
import ValueRenderer from './_global/ValueRenderer';

const useStyles = makeStyles({
  cell: {
    display: 'flex',
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
  },
  headerLine: {
    display: 'grid',
    gridTemplateColumns: '68.6rem 1.6rem 1fr auto',
    columnGap: spacingRem.m,
    alignItems: 'center',
  },
}, 'explorerHomeInstanceTab');

const ExplorerHomeInstanceTab: FunctionComponent = withAsyncTask(({ executeAsyncTask }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const getHint = useExplorerHint();

  const navigation = useNavigation<NavigationFilter>();

  const [search, setSearch, clearSearch] = useFilterStorage(EXPLORER_INSTANCE_FILTER_ID, FilterParams.nameSearch);

  const { status, value } = executeAsyncTask(getExplorerListComputeFunction, [store, getHint, search], [store.getSerial()]);

  const { generatePageList } = useFilterAndSort(
    EXPLORER_INSTANCE_FILTER_ID,
    value.list,
    ({ score }) => (score.global > 0),
    {
      getComparatorHandler: (_, direction) => ({
        extractValue: ({ score: { global } }) => global,
        comparator: comparing(compareNumber, direction === TableSortDirection.desc),
      }),
      initial: { key: 'score', direction: TableSortDirection.desc },
    },
    undefined,
    [value.search]
  );

  const { list, pagination } = generatePageList(25);

  const getMatchLevelIcon = (text: string | undefined, score: number): { tooltip: string, name: IconName, color: string } | undefined => {
    if (!search || !text) {
      return undefined;
    } else if (score === 1) {
      return { tooltip: i18n`Perfect match`, name: IconName.check_circle, color: theme.color.text.success };
    } else if (text.toLowerCase().includes(search.toLowerCase())) {
      return { tooltip: i18n`Partial match`, name: IconName.warning, color: theme.color.text.warning };
    } else {
      return { tooltip: i18n`Poor match`, name: IconName.dangerous, color: theme.color.text.danger };
    }
  };

  const getBanner = () => {
    if (value.search !== undefined && (validate(value.search) || keyToId(value.search).every((s) => validate(s)))) {
      const object = store.getObjectOrNull(keyToId(value.search));
      return (
        <Banner
          variant={object === null ? BannerVariant.danger : BannerVariant.info}
          title={
            object === null
              ? i18n`The id you searched doesn't match any known id. The instance might have been deleted.`
              : i18n`The id you searched match an existing instance.`
          }
          actions={[
            {
              key: 'open',
              icon: IconName.output,
              tooltip: object === null ? i18n`Open anyway` : i18n`Open`,
              onClick: () => {
                navigation.push(`explorer:${value.search}`, { pathname: `/settings/explorer/instance/${value.search}` });
              },
            },
          ]}
        />
      );
    } else {
      return (<span />);
    }
  };

  const mostReadableColor = getMostReadableColorFromBackgroundColor(theme.color.background.neutral.default);

  return (
    <VerticalBlock compact>
      <BlockContent padded>
        <div className={classes.headerLine}>
          <UsageContextProvider usageVariant={UsageVariant.inForm}>
            <FormTextInputField
              initialValue={search}
              onChangeDebounced={(newSearch) => setSearch(newSearch ?? '')}
              onClear={clearSearch}
              placeholder={i18n`Search instance`}
              fullWidth
            />
          </UsageContextProvider>
          {
            status === 'loading' ? (
              <Spinner
                size={SpinnerVariant.small}
                color={generateColorFromOpacity(mostReadableColor, theme.color.background.neutral.default, Opacity.fifty)}
              />
            ) : (<span />)
          }
          <span />
          <SizeContextProvider sizeVariant={SizeVariant.small}>
            {getBanner()}
          </SizeContextProvider>
        </div>
      </BlockContent>
      <DataTable
        list={list}
        pagination={pagination}
        columnsDefinition={[
          {
            propertyId: 'id',
            name: i18n`Id`,
            cellRender: ({ key, hint }) => (
              <div className={classes.cell}>
                <ValueRenderer
                  value={{
                    type: ValueType.string,
                    value: key,
                    hint,
                    href: `/settings/explorer/instance/${key}`,
                    maxLine: 1,
                  }}
                />
              </div>
            ),
          },
          {
            propertyId: 'type',
            name: i18n`Type`,
            cellRender: ({ type, typeHint }) => (
              <div className={classes.cell}>
                {
                  type !== undefined
                    ? (
                      <ValueRenderer
                        value={{
                          type: ValueType.string,
                          value: type,
                          hint: typeHint,
                          href: `/settings/explorer/instance/${type}`,
                          maxLine: 1,
                        }}
                      />
                    )
                    : null
                }
              </div>
            ),
          },
          {
            propertyId: 'score',
            name: i18n`Score`,
            width: '7.5rem',
            cellRender: (item) => {
              const formattedKeyScore = item.score.key.toLocaleString(i18n.locale, { maximumFractionDigits: 3, minimumFractionDigits: 3 });
              const formattedHintScore = item.score.hint.toLocaleString(i18n.locale, { maximumFractionDigits: 3, minimumFractionDigits: 3 });
              return (
                <NumberPicker
                  value={item.score.global}
                  onChange={() => {}}
                  decimals={3}
                  withDecimals
                  restingTooltip={i18n`Key: ${formattedKeyScore}\nHint: ${formattedHintScore}`}
                  readOnly
                />
              );
            },
          },
          {
            propertyId: 'idMatchLevel',
            name: i18n`Id Match`,
            width: '7.5rem',
            cellRender: (item) => {
              const idMatchLevelIcon = getMatchLevelIcon(item.key, item.score.key);

              return (
                <TableInnerCellContainer variant={TableInnerCellContainerVariants.centeredFlex} padding={buildPadding({ x: Spacing.s })}>
                  <SpacingLine>
                    {idMatchLevelIcon !== undefined ? (
                      <Icon tooltip={idMatchLevelIcon.tooltip} name={idMatchLevelIcon.name} color={idMatchLevelIcon.color} />
                    ) : null}
                  </SpacingLine>
                </TableInnerCellContainer>
              );
            },
          },
          {
            propertyId: 'hintMatchLevel',
            name: i18n`Hint Match`,
            width: '7.5rem',
            cellRender: (item) => {
              const hintMatchLevelIcon = getMatchLevelIcon(item.hint, item.score.hint);

              return (
                <TableInnerCellContainer variant={TableInnerCellContainerVariants.centeredFlex} padding={buildPadding({ x: Spacing.s })}>
                  <SpacingLine>
                    {hintMatchLevelIcon !== undefined ? (
                      <Icon tooltip={hintMatchLevelIcon.tooltip} name={hintMatchLevelIcon.name} color={hintMatchLevelIcon.color} />
                    ) : null}
                  </SpacingLine>
                </TableInnerCellContainer>
              );
            },
          },
        ]}
      />
    </VerticalBlock>
  );
}, () => (
  <VerticalBlock>
    <BlockContent>
      <Loading />
    </BlockContent>
  </VerticalBlock>
));

export default ExplorerHomeInstanceTab;
