import type { FunctionComponent } from 'react';
import type { DimensionsMapping, TimeseriesValueUpdate } from 'yooi-modules/modules/conceptModule';
import { canWrite, ParsedDimensionType, parseDimensionMapping, timeseriesNumberFieldHandler } from 'yooi-modules/modules/conceptModule';
import { Field_Formula, Field_IntegrationOnly } from 'yooi-modules/modules/conceptModule/ids';
import useAcl from '../../../../../store/useAcl';
import useActivity from '../../../../../store/useActivity';
import useStore from '../../../../../store/useStore';
import useUpdateActivity from '../../../../../store/useUpdateActivity';
import i18n from '../../../../../utils/i18n';
import { TickResolutionStatus } from '../../../fieldUtils';
import { getLastUpdateBy } from '../../../historyUtils';
import StoreNumberPickerInput from '../../../input/StoreNumberPickerInput';
import { getTimeseriesMaxMinValues, getTimeseriesRangeValues } from './timeseriesNumberFieldUtils';

interface TimeseriesNumberFieldNumberPickerProps {
  dimensionsMapping: DimensionsMapping,
  fieldId: string,
  readOnly: boolean,
  time: number,
  value: number | undefined,
  onSubmit?: (value: TimeseriesValueUpdate<number>) => void,
}

const TimeseriesNumberFieldNumberPicker: FunctionComponent<TimeseriesNumberFieldNumberPickerProps> = ({ dimensionsMapping, fieldId, time, value, onSubmit, readOnly = false }) => {
  const store = useStore();
  const field = store.getObject(fieldId);
  const isComputed = Boolean(field[Field_Formula]);
  const isIntegrationOnly = Boolean(field[Field_IntegrationOnly]);
  const fieldHandler = timeseriesNumberFieldHandler(store, fieldId);
  const parsedDimension = parseDimensionMapping(dimensionsMapping);
  const configuration = fieldHandler.resolveConfigurationWithOverride(dimensionsMapping);
  const { canWriteObject } = useAcl();

  const minMaxValues = getTimeseriesMaxMinValues(store, fieldId, dimensionsMapping, {});
  const ticks = getTimeseriesRangeValues(store, fieldId, dimensionsMapping, {});

  const activity = useActivity();
  const updateActivity = useUpdateActivity();

  return (
    <StoreNumberPickerInput
      initialValue={value ?? undefined}
      onSubmit={(newValue) => {
        const update: TimeseriesValueUpdate<number> = { type: 'value', time, value: typeof newValue === 'string' ? Number.parseFloat(newValue) : newValue };
        if (onSubmit) {
          onSubmit(update);
        } else {
          fieldHandler.updateValue(dimensionsMapping, update);
        }
      }}
      min={minMaxValues.min?.status === TickResolutionStatus.Resolved ? minMaxValues.min : undefined}
      ticks={ticks}
      max={minMaxValues.max?.status === TickResolutionStatus.Resolved ? minMaxValues.max : undefined}
      invalidColor={configuration.invalidColor}
      decimals={configuration.decimals}
      unit={configuration.unit}
      readOnly={isComputed || isIntegrationOnly || readOnly || !canWrite(dimensionsMapping, canWriteObject)}
      withProgress
      onEditionStart={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => updateActivity.onEnterEdition(parsedDimension.objectId, `${fieldId}_${time}`) : undefined}
      onEditionStop={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => updateActivity.onExitEdition(parsedDimension.objectId, `${fieldId}_${time}`) : undefined}
      isEditing={parsedDimension.type === ParsedDimensionType.MonoDimensional ? activity.listEditor(parsedDimension.objectId, `${fieldId}_${time}`).length > 0 : undefined}
      placeholder={i18n`Add number`}
      restingTooltip={
        configuration.formula === undefined && parsedDimension.type === ParsedDimensionType.MonoDimensional
          ? () => getLastUpdateBy(store, parsedDimension.objectId, fieldId, time) : undefined
      }
    />
  );
};

export default TimeseriesNumberFieldNumberPicker;
