import type { FunctionComponent } from 'react';
import { isFiniteNumber, periodicities } from 'yooi-utils';
import InCompositeInput from '../../../app/_global/input/InCompositeInput';
import { spacingRem } from '../../../theme/spacingDefinition';
import { getMonthsOptions } from '../../../utils/dateUtilsFront';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useDerivedState from '../../../utils/useDerivedState';
import useSizeContext from '../../../utils/useSizeContext';
import SearchAndSelect from '../../molecules/SearchAndSelect';
import NumberPicker from '../NumberPicker';
import { DatePickerStrategy } from './datePickerUtils';

const useStyles = makeStyles({
  inputSpacings: {
    display: 'grid',
    gridAutoFlow: 'column',
    columnGap: spacingRem.xs,
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
  },
}, 'monthPicker');

interface MonthPickerProps {
  value: Date | undefined,
  onSubmit: (value: Date | undefined) => void,
  strategy?: DatePickerStrategy,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
}

const MonthPicker: FunctionComponent<MonthPickerProps> = ({ value, onSubmit, strategy = DatePickerStrategy.startOf, onEditionStart, onEditionStop }) => {
  const classes = useStyles();
  const { sizeVariant } = useSizeContext();

  const valueMonth = value?.getMonth();
  const valueYear = (value ?? new Date()).getFullYear();

  const [month, setMonth, resetMonth] = useDerivedState(() => (valueMonth), [valueMonth]);
  const [year, setYear, resetYear] = useDerivedState<number | undefined>(() => (valueYear), [valueYear]);

  const submitInvalidDate = () => {
    onSubmit(undefined);
  };

  const submitDate = ({ m, y }: { m: number | undefined, y: number | undefined }) => {
    if (m != null && y != null) {
      resetMonth();
      resetYear();
      const submittedDate = new Date(y, m);
      // new Date(year, month) make year < 100 becoming 19XX need to set it after
      if (y < 100) {
        submittedDate.setFullYear(y);
      }
      if (strategy === DatePickerStrategy.endOf) {
        onSubmit(periodicities.month.getEndOfPeriod(submittedDate));
      } else {
        onSubmit(periodicities.month.getStartOfPeriod(submittedDate));
      }
    }
  };

  const computeSelectSize = () => {
    if (sizeVariant === 'main') {
      return { width: '21.5rem' };
    } else {
      return { width: '16.5rem' };
    }
  };

  const monthsOptions = getMonthsOptions();

  return (
    <div
      className={classes.inputSpacings}
    >
      <div style={computeSelectSize()}>
        <SearchAndSelect
          placeholder={i18n`Month`}
          clearable
          onSelect={(v) => {
            const monthToSubmit = v?.id;
            if (monthToSubmit == null || monthToSubmit >= 12 || monthToSubmit < 0) {
              setMonth(undefined);
              submitInvalidDate();
            } else {
              setMonth(monthToSubmit);
              submitDate({ m: monthToSubmit, y: year });
            }
          }}
          computeOptions={() => monthsOptions}
          selectedOption={month != null ? monthsOptions[month] : undefined}
          onEditionStart={onEditionStart}
          onEditionStop={onEditionStop}
        />
      </div>
      <InCompositeInput
        initialValue={year}
        setInputValue={(yearToSubmit) => {
          if (yearToSubmit == null || yearToSubmit > 3000 || yearToSubmit < 1) {
            setYear(undefined);
            submitInvalidDate();
          } else {
            setYear(yearToSubmit);
            submitDate({ m: month, y: yearToSubmit });
          }
        }}
      >
        {({ value: innerValue, onChange, ...props }) => (
          <NumberPicker
            min={1}
            max={3000}
            placeholder={i18n`yyyy`}
            withFormatting={false}
            onEditionStart={onEditionStart}
            onEditionStop={onEditionStop}
            value={innerValue}
            onChange={(v) => {
              if (isFiniteNumber(v) || v === undefined) {
                onChange(typeof v === 'string' ? parseInt(v, 10) : v);
              }
            }}
            {...props}
          />
        )}
      </InCompositeInput>
    </div>
  );
};

export default MonthPicker;
