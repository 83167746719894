import { equals } from 'ramda';
import type { Editor } from 'slate';
import { createEditor, Element as SlateElement, Node as SlateNode, Text, Transforms } from 'slate';
import type { CustomElement, CustomText, RichText } from 'yooi-utils';
import { extractLinksFromText, isCustomText, isUrl, RichTextElementTypes, richTextToText } from 'yooi-utils';

const richTextWithLinks = (editor: Editor): Editor => {
  const { isInline } = editor;
  const mutableEditor = editor;
  mutableEditor.isInline = (element) => (element.type === 'link' ? true : isInline(element));
  return mutableEditor;
};
export const updateTextWithLinks = (richText: RichText): RichText => {
  const editor = richTextWithLinks(createEditor());
  editor.children = richText;
  const getOffsets = (listOfParts: ({ text: string, isLink?: boolean })[], index: number) => {
    const offset = listOfParts.length > 0 ? listOfParts.slice(0, index).reduce((accumulator, newValue) => accumulator + newValue.text.length, 0) : 0;
    return [offset, offset + listOfParts[index].text.length];
  };
  let previousChildren: RichText | null = richText;
  let newChildren: RichText | null = null;
  while (!equals(newChildren, previousChildren)) {
    Array.from(SlateNode.elements(editor as SlateNode)).every(([element, elementPath]) => {
      if (SlateElement.isElement(element) && element.type !== 'link') {
        return Array.from(SlateNode.children(editor as SlateNode, elementPath)).every(([child, childPath]) => {
          if (Text.isText(child)) {
            const parts = extractLinksFromText(child.text);
            parts.forEach(({ isLink, text }, index) => {
              if (isLink) {
                const offsets = getOffsets(parts, index);
                Transforms.wrapNodes(editor, {
                  type: RichTextElementTypes.link,
                  url: text,
                  children: [{ text }],
                }, {
                  split: true,
                  at: {
                    anchor: {
                      path: childPath,
                      offset: offsets[0],
                    },
                    focus: {
                      path: childPath,
                      offset: offsets[1],
                    },
                  },
                });
                return false;
              }
              return true;
            });
          } else if (child.type === 'link') {
            const linkChildren = child.children[0];
            const linkText = isCustomText(linkChildren) ? linkChildren.text : '';
            const hasMoreThanOneChild = child.children.length !== 1;
            const linkTextIsUrl = linkText && isUrl(linkText);
            if (hasMoreThanOneChild || !linkTextIsUrl) {
              Transforms.unwrapNodes(editor, { at: childPath });
              return false;
            } else if (isCustomText(linkChildren) && linkTextIsUrl && linkChildren.text !== child.url) {
              Transforms.setNodes(editor, {
                type: RichTextElementTypes.link,
                url: linkText,
                children: [{ text: linkText }],
              }, { at: childPath });
              return false;
            }
          }
          return true;
        });
      }
      return true;
    });
    previousChildren = newChildren;
    newChildren = editor.children as RichText;
  }
  return editor.children as RichText;
};
const getLastTextElementInValue = (element: (CustomElement | CustomText)): CustomText => {
  if (isCustomText(element)) {
    return element;
  }
  const { children } = element;
  const lastElement = children[children.length - 1];
  return getLastTextElementInValue(lastElement);
};
export const appendTextAtTheEnd = (richText: RichText | undefined, text: string): RichText => {
  if (!richText) {
    return [{ type: RichTextElementTypes.paragraph, children: [{ text }] }];
  }
  const copyValue = JSON.parse(JSON.stringify(richText));
  const textNode = getLastTextElementInValue(copyValue[copyValue.length - 1] as CustomElement);
  textNode.text += text;
  return copyValue;
};

const wrappedRichTextSymbol = Symbol('WrappedRichText');

export interface WrappedRichText {
  type: typeof wrappedRichTextSymbol,
  toString: () => string,
  valueOf: () => RichText | undefined,
}

export const isWrappedRichText = (value: unknown): value is WrappedRichText => (
  typeof value === 'object' && value !== null && (value as { type: unknown }).type === wrappedRichTextSymbol
);

export const richTextWrapper = (richText: RichText | undefined): WrappedRichText | undefined => {
  if (richText === undefined) {
    return undefined;
  } else {
    let cachedToStringValue: string | undefined;
    return {
      type: wrappedRichTextSymbol,
      toString: () => {
        if (cachedToStringValue === undefined) {
          cachedToStringValue = richTextToText(richText) ?? '';
        }
        return cachedToStringValue;
      },
      valueOf: () => richText,
    };
  }
};
