import { ValidationStatus } from 'yooi-store';
import { CommonAsType } from '../../common/fields/commonPropertyType';
import { Integration } from '../../integrationModule/ids';
import { AnyElement, ConceptCapability, ConceptCapability_Description, ConceptCapability_Name, ConceptDefinition, ConceptRole, Group, PlatformCapability } from '../ids';
import { registerModel } from '../module';
import { adminOnlyAcl } from '../utils';

const { type, association } = registerModel;

type({
  label: 'ConceptCapability',
  accessControlList: {
    READ: () => () => ({ rule: 'conceptCapability.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'conceptCapability', 'WRITE'),
    DELETE: () => (_, objectId) => ({ rule: 'conceptCapability.delete.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: objectId }),
  },
})
  .property({ label: 'ConceptCapability_Name', as: CommonAsType.string })
  .property({ label: 'ConceptCapability_Description', as: CommonAsType.string })
  .instance({
    label: 'ConceptCapabilityCreate',
    extraProperties: {
      [ConceptCapability_Name]: 'Create',
      [ConceptCapability_Description]: 'Allows to create content',
    },
  })
  .instance({
    label: 'ConceptCapabilityRead',
    extraProperties: {
      [ConceptCapability_Name]: 'View',
      [ConceptCapability_Description]: 'Allows to view content',
    },
  })
  .instance({
    label: 'ConceptCapabilityEdit',
    extraProperties: {
      [ConceptCapability_Name]: 'Edit',
      [ConceptCapability_Description]: 'Allows to edit content',
    },
  })
  .instance({
    label: 'ConceptCapabilityDelete',
    extraProperties: {
      [ConceptCapability_Name]: 'Delete',
      [ConceptCapability_Description]: 'Allows to delete',
    },
  })
  .instance({
    label: 'ConceptCapabilityAssignUser',
    extraProperties: {
      [ConceptCapability_Name]: 'Assign Stakeholders',
      [ConceptCapability_Description]: 'Allows to manage stakeholders',
    },
  })
  .instance({
    label: 'ConceptCapabilityAssignCollaborator',
    extraProperties: {
      [ConceptCapability_Name]: 'Assign Collaborators',
      [ConceptCapability_Description]: 'Allows to assign external contributors to a collaboration. External contributors will be able to at least see the instance.',
    },
  })
  .instance({
    label: 'ConceptCapabilityOverrideWorkflow',
    extraProperties: {
      [ConceptCapability_Name]: 'Override workflow',
      [ConceptCapability_Description]: 'Allows to override workflow and update statuses manually',
    },
  })
  .instance({
    label: 'ConceptCapabilityOverrideCollaborationWorkflow',
    extraProperties: {
      [ConceptCapability_Name]: 'Override collaboration workflow',
      [ConceptCapability_Description]: 'Allows to override collaboration workflow and update statuses manually',
    },
  });

association({
  label: 'ConceptRoleCapability',
  roles: [{ label: 'ConceptRole', targetTypeId: ConceptRole }, { label: 'ConceptCapability', targetTypeId: ConceptCapability }],
  accessControlList: {
    READ: () => () => ({ rule: 'conceptRoleCapability.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'conceptRoleCapability', 'WRITE'),
    DELETE: () => (_, objectId) => ({ rule: 'conceptRoleCapability.delete.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: objectId }),
  },
});

association({
  label: 'ConceptGroupCapability',
  roles: [
    { label: 'ConceptGroup', targetTypeId: Group },
    { label: 'ConceptCapability', targetTypeId: ConceptCapability },
    { label: 'ConceptDefinition', targetTypeId: ConceptDefinition },
  ],
  accessControlList: {
    READ: () => () => ({ rule: 'conceptGroupCapability.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'conceptGroupCapability', 'WRITE'),
    DELETE: () => (_, objectId) => ({ rule: 'conceptGroupCapability.delete.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: objectId }),
  },
});

association({
  label: 'ConceptIntegrationCapability',
  roles: [
    { label: 'Integration', targetTypeId: Integration },
    { label: 'ConceptCapability', targetTypeId: ConceptCapability },
    { label: 'Definition', targetTypeId: AnyElement },
  ],
  accessControlList: {
    READ: () => () => ({ rule: 'conceptIntegrationCapability.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'conceptIntegrationCapability', 'WRITE'),
    DELETE: () => (_, objectId) => ({ rule: 'conceptIntegrationCapability.delete.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: objectId }),
  },
});

type({
  label: 'PlatformCapability',
  accessControlList: {
    READ: () => () => ({ rule: 'platformCapability.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'platformCapability', 'WRITE'),
    DELETE: () => (_, objectId) => ({ rule: 'platformCapability.delete.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: objectId }),
  },
})
  .instance({ label: 'PlatformCapabilityAdmin' })
  .instance({ label: 'PlatformCapabilityInviteUser' })
  .instance({ label: 'PlatformCapabilityUpdateEmail' });

association({
  label: 'PlatformGroupCapability',
  roles: [
    { label: 'Group', targetTypeId: Group },
    { label: 'PlatformCapability', targetTypeId: PlatformCapability },
  ],
  accessControlList: {
    READ: () => () => ({ rule: 'platformGroupCapability.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'platformGroupCapability', 'WRITE'),
    DELETE: () => (_, objectId) => ({ rule: 'platformGroupCapability.delete.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: objectId }),
  },
});
