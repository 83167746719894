import type { FunctionComponent, ReactElement } from 'react';
import { useState } from 'react';
import type { FieldStoreObject, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { getPathReturnedConceptDefinitionId } from 'yooi-modules/modules/conceptModule';
import type { SwimlaneViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { joinObjects } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../../../components/atoms/Button';
import { IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import Tooltip from '../../../../../components/atoms/Tooltip';
import Typo, { TypoVariant } from '../../../../../components/atoms/Typo';
import Chip from '../../../../../components/molecules/Chip';
import Chooser from '../../../../../components/molecules/Chooser';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import useStore from '../../../../../store/useStore';
import base from '../../../../../theme/base';
import { buildPadding, getSpacing, Spacing } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import { formatOrUndef } from '../../../../../utils/stringUtils';
import { SessionStorageKeys, useSessionStorageState } from '../../../../../utils/useSessionStorage';
import useTheme from '../../../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../../../utils/useUsageContext';
import { getFieldLabel } from '../../../fieldUtils';
import SearchTextInput from '../../../filter/SearchTextInput';
import { FilterParams, useFilterStorage } from '../../../filter/useFilterSessionStorage';
import { getChipOptions, getUnknownChip } from '../../../modelTypeUtils';
import type { SwimlaneV2Configuration } from '../../../sessionStorageTypes';
import { getViewDefinitionHandler } from '../../viewDsl';
import type { SwimlaneViewDefinitionHandler } from '../swimlaneViewDefinitionHandler';
import { getSwimlaneViewColumns, getSwimlaneViewGroupBy } from '../swimlaneViewResolution';

const useStyles = makeStyles((theme) => ({
  optionsContainer: joinObjects(
    {
      backgroundColor: theme.color.background.neutral.default,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: base.borderRadius.medium,
      boxShadow: base.shadowElevation.medium,
      width: '35rem',
      gap: getSpacing(Spacing.xs),
    },
    buildPadding({ x: Spacing.splus, top: Spacing.s, bottom: Spacing.splus })
  ),
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: getSpacing(Spacing.s),
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '3.2rem',
  },
  titleLeftContainer: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: '6em',
    gap: getSpacing(Spacing.xs),
    alignItems: 'center',
  },
  optionsValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: getSpacing(Spacing.xs),
  },
  optionLineContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
    height: '3.2rem',
    gap: getSpacing(Spacing.s),
    justifyContent: 'space-between',
  },
  mainColumnConfigContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '20rem',
    overflowY: 'auto',
    gap: getSpacing(Spacing.s),
  },
  columnConfigContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: getSpacing(Spacing.s),
  },
  columnConfigListContainer: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    gap: getSpacing(Spacing.s),
    justifyContent: 'space-between',
  },
  columnConfigTitleContainer: joinObjects(
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: getSpacing(Spacing.s),
    },
    buildPadding({ y: Spacing.text })
  ),
  groupByConfigContainer: joinObjects({
    display: 'flex', flexDirection: 'column', gap: getSpacing(Spacing.s),
  }, buildPadding({ top: Spacing.xs })),
  groupByConfigChipsContainer: joinObjects(
    {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '20rem',
      overflowY: 'auto',
      gap: getSpacing(Spacing.s),
    },
    buildPadding({ top: Spacing.xs })
  ),
  valueLine: {
    display: 'flex',
    justifyContent: 'end',
  },
}), 'viewConceptSwimlaneOptions');

interface ViewConceptSwimlaneOptionsProps {
  viewDefinition: SwimlaneViewStoredDefinition,
  viewDimensions: ViewDimension[],
  parametersMapping: ParametersMapping,
}

const ViewConceptSwimlaneOptions: FunctionComponent<ViewConceptSwimlaneOptionsProps> = ({
  viewDefinition,
  viewDimensions,
  parametersMapping,
}) => {
  const classes = useStyles();
  const store = useStore();
  const theme = useTheme();

  const configurationKey = `${SessionStorageKeys.swimlaneConfig}_${viewDefinition.id}`;

  const defaultConfiguration: SwimlaneV2Configuration = {
    hiddenColumns: [],
    groupBy: viewDefinition.groupBy.find(({ isDefault }) => isDefault)?.id,
    showDependencies: viewDefinition.dependencies.length > 0,
  };
  const [swimlaneConfig, updateSwimlaneConfig] = useSessionStorageState<SwimlaneV2Configuration>(configurationKey, defaultConfiguration);
  const doUpdateSwimlaneConfig = <Key extends keyof SwimlaneV2Configuration>(key: Key, value: SwimlaneV2Configuration[Key]) => {
    const config = { ...(swimlaneConfig ?? defaultConfiguration) };
    config[key] = value;
    updateSwimlaneConfig(config);
  };
  const groupBySearchId = `${configurationKey}_groupBySearch`;
  const [search] = useFilterStorage(groupBySearchId, FilterParams.nameSearch);

  const [optionState, setOptionState] = useState<'option' | 'groupBy' | 'columns'>('option');

  const viewHandler = getViewDefinitionHandler(viewDefinition) as SwimlaneViewDefinitionHandler;
  const resolvedViewDefinition = viewHandler.getDefinition(store, viewDimensions);
  const conceptDefinition = getPathReturnedConceptDefinitionId(store, viewDimensions.at(0)?.path ?? []);

  if (!conceptDefinition) {
    return null;
  }

  const { columns } = getSwimlaneViewColumns(store, resolvedViewDefinition, conceptDefinition);
  const groupByList = getSwimlaneViewGroupBy(store, resolvedViewDefinition, viewDimensions, parametersMapping);
  const groupBy = groupByList.find(({ id }) => id === swimlaneConfig.groupBy);

  const visibleColumns = columns.filter((instance) => !swimlaneConfig.hiddenColumns.includes(instance?.id ?? null));
  const hiddenColumns = columns.filter((instance) => swimlaneConfig.hiddenColumns.includes(instance?.id ?? null));
  const columnField = viewDefinition.columnBy.fieldId ? store.getObjectOrNull<FieldStoreObject>(viewDefinition.columnBy.fieldId) : null;
  const columnFieldLabel = formatOrUndef(columnField ? getFieldLabel(store, columnField) : undefined);

  let content: ReactElement;
  if (optionState === 'option') {
    content = (
      <div className={classes.optionsValueContainer}>
        {viewDefinition.groupBy.length > 0 && (
          <div className={classes.optionLineContainer}>
            <Typo>{i18n`Group`}</Typo>
            <div className={classes.valueLine}>
              <SpacingLine>
                {groupBy !== undefined && (
                  <Chip
                    text={groupBy.label}
                    icon={groupBy.icon}
                  />
                )}
                <IconOnlyButton variant={IconOnlyButtonVariants.tertiary} tooltip={i18n`Open`} iconName={IconName.keyboard_arrow_right} onClick={() => setOptionState('groupBy')} />
              </SpacingLine>
            </div>
          </div>
        )}
        <div className={classes.optionLineContainer}>
          <Typo>{i18n`Columns`}</Typo>
          <div className={classes.valueLine}>
            <SpacingLine>
              <Typo color={theme.color.text.secondary}>
                {i18n`${hiddenColumns.length} hidden`}
              </Typo>
              <IconOnlyButton variant={IconOnlyButtonVariants.tertiary} tooltip={i18n`Open`} iconName={IconName.keyboard_arrow_right} onClick={() => setOptionState('columns')} />
            </SpacingLine>
          </div>
        </div>
        {viewDefinition.dependencies.length > 0 && (
          <div className={classes.optionLineContainer}>
            <Typo>{i18n`Dependencies`}</Typo>
            <div className={classes.valueLine}>
              <Chooser
                actions={[{ key: 'show', name: i18n`Show`, disable: viewDefinition.dependencies.length === 0 }, { key: 'hide', name: i18n`Hide` }]}
                selectedIndexes={[swimlaneConfig.showDependencies ? 0 : 1]}
                onClick={(index) => {
                  doUpdateSwimlaneConfig('showDependencies', index === 0);
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  } else if (optionState === 'groupBy') {
    content = (
      <div className={classes.groupByConfigContainer}>
        <SearchTextInput placeholder={i18n`Search`} element={groupBySearchId} focusOnMount fullWidth />
        <div className={classes.groupByConfigChipsContainer}>
          {groupByList.filter(({ label }) => !search || label.toLowerCase().includes(search.toLowerCase())).map((group) => (
            <Chip
              key={group.id}
              text={group.label}
              icon={group.icon}
              onClick={() => doUpdateSwimlaneConfig('groupBy', group.id)}
            />
          ))}
        </div>
      </div>
    );
  } else {
    content = (
      <div className={classes.mainColumnConfigContainer}>
        {visibleColumns.length > 0 && (
          <>
            <div className={classes.columnConfigTitleContainer}>
              <Typo color={theme.color.text.secondary}>{i18n`Visible columns`}</Typo>
              <Button
                title={i18n`Hide all`}
                variant={ButtonVariant.tertiary}
                onClick={() => doUpdateSwimlaneConfig('hiddenColumns', columns.map((column) => column?.id))}
              />
            </div>
            <div className={classes.columnConfigContainer}>
              {visibleColumns.filter(() => true).map((instance) => {
                const chipOptions = instance ? getChipOptions(store, instance.id) ?? getUnknownChip(instance.id) : undefined;

                return (
                  <div
                    key={`${instance?.id}_config`}
                    className={classes.columnConfigListContainer}
                  >
                    {chipOptions ? (
                      <Chip
                        tooltip={chipOptions.tooltip}
                        color={chipOptions.color}
                        text={chipOptions.label}
                        icon={chipOptions.icon}
                        squareColor={chipOptions.squareColor}
                      />
                    ) : (
                      <Chip
                        tooltip={i18n`No ${columnFieldLabel}`}
                        text={i18n`No ${columnFieldLabel}`}
                        borderStyle="dashed"
                      />
                    )}
                    <IconOnlyButton
                      variant={IconOnlyButtonVariants.secondary}
                      tooltip={i18n`Hide field`}
                      iconName={IconName.visibility}
                      onClick={() => doUpdateSwimlaneConfig('hiddenColumns', [...swimlaneConfig.hiddenColumns, instance?.id])}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
        {hiddenColumns.length > 0 && (
          <>
            <div className={classes.columnConfigTitleContainer}>
              <Typo color={theme.color.text.secondary}>{i18n`Hidden columns`}</Typo>
              <Button
                title={i18n`Show all`}
                variant={ButtonVariant.tertiary}
                onClick={() => doUpdateSwimlaneConfig('hiddenColumns', [])}
              />
            </div>
            <div className={classes.columnConfigContainer}>
              {hiddenColumns.filter(() => true).map((instance) => {
                const chipOptions = instance ? getChipOptions(store, instance.id) ?? getUnknownChip(instance.id) : undefined;
                return (
                  <div
                    key={`${instance?.id}_config`}
                    className={classes.columnConfigListContainer}
                  >
                    {chipOptions ? (
                      <Chip
                        tooltip={chipOptions.tooltip}
                        color={chipOptions.color}
                        text={chipOptions.label}
                        icon={chipOptions.icon}
                        squareColor={chipOptions.squareColor}
                      />
                    ) : (
                      <Chip
                        tooltip={i18n`No ${columnFieldLabel}`}
                        text={i18n`No ${columnFieldLabel}`}
                        borderStyle="dashed"
                      />
                    )}
                    <IconOnlyButton
                      variant={IconOnlyButtonVariants.secondary}
                      tooltip={i18n`Show field`}
                      iconName={IconName.visibility_off}
                      onClick={() => doUpdateSwimlaneConfig(
                        'hiddenColumns',
                        swimlaneConfig.hiddenColumns.filter((id) => id !== (instance?.id ?? null))
                      )}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  }

  let title: string | undefined;
  if (optionState === 'option') {
    title = i18n`View options`;
  } else if (optionState === 'groupBy') {
    title = i18n`Group by`;
  } else if (optionState === 'columns') {
    title = i18n`Columns`;
  }

  return (
    <UsageContextProvider usageVariant={UsageVariant.inForm}>
      <div className={classes.optionsContainer}>
        <div className={classes.titleContainer}>
          <div className={classes.titleLeftContainer}>
            {optionState !== 'option' && (
              <IconOnlyButton
                tooltip={i18n`Back to option`}
                iconName={IconName.keyboard_arrow_left}
                onClick={() => setOptionState('option')}
                variant={IconOnlyButtonVariants.tertiary}
              />
            )}
            <Tooltip title={formatOrUndef(title)}>
              <Typo maxLine={1} variant={TypoVariant.blockSecondaryTitle}>{formatOrUndef(title)}</Typo>
            </Tooltip>
          </div>
          {optionState === 'groupBy' && groupBy && (
            <Chip
              key={groupBy.id}
              text={groupBy.label}
              icon={groupBy.icon}
              actions={[{
                key: 'remove',
                tooltip: i18n`Remove group by`,
                icon: IconName.close,
                action: () => doUpdateSwimlaneConfig('groupBy', null),
              }]}
            />
          )}
        </div>
        {content}
      </div>
    </UsageContextProvider>
  );
};
export default ViewConceptSwimlaneOptions;
