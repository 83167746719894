import type { FunctionComponent } from 'react';
import { getInstanceLabelOrUndefined } from 'yooi-modules/modules/conceptModule';
import Avatar, { AvatarSizes, AvatarVariant } from '../../../components/atoms/Avatar';
import useActivity from '../../../store/useActivity';
import useStore from '../../../store/useStore';
import type { SpacingDefinitionProp } from '../../../theme/spacingDefinition';
import { buildPadding, Spacing } from '../../../theme/spacingDefinition';
import makeStyles from '../../../utils/makeStyles';
import { getUserName } from '../../utils/userUtils';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
  },
}, 'activityIndicator');

interface ActivityIndicatorProps {
  instanceIds: string[] | string,
  propertyIds: string[] | string,
  padding?: SpacingDefinitionProp,
}

const ActivityIndicator: FunctionComponent<ActivityIndicatorProps> = ({ instanceIds: instanceIdsParam, propertyIds: propertyIdsParam, padding = { top: Spacing.s } }) => {
  const activity = useActivity();
  const store = useStore();
  const classes = useStyles();

  const propertyIds = Array.isArray(propertyIdsParam) ? propertyIdsParam.filter((id, index, array) => array.indexOf(id) === index) : [propertyIdsParam];
  const instanceIds = Array.isArray(instanceIdsParam) ? instanceIdsParam.filter((id, index, array) => array.indexOf(id) === index) : [instanceIdsParam];

  const editors = propertyIds.flatMap((fieldId) => instanceIds.flatMap((instanceId) => activity.listEditor(instanceId, fieldId))).map((editor) => editor.userId);

  if (editors.length === 0) {
    return null;
  }

  if (editors.length >= 1) {
    const editorId = editors[0];
    const editor = store.getObject(editorId);
    const userName = getUserName(editor);
    const formattedUsername = userName.charAt(0).toUpperCase();
    return (
      <div className={classes.container} style={buildPadding(padding)}>
        <Avatar
          name={editors.length > 1 ? `${editors.length}` : formattedUsername}
          tooltip={editors.map((id) => store.getObject(id)).map((user) => getInstanceLabelOrUndefined(store, user)).join('\n')}
          variant={AvatarVariant.user}
          size={AvatarSizes.extraSmall}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default ActivityIndicator;
