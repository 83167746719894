import type { PropertyTimeseriesResponseFailure, PropertyTimeseriesResponseSuccess, TimeRange, TimeseriesRangeEntry } from 'yooi-store';
import { PropertyTimeseriesResponseStatuses } from 'yooi-store';
import { createAutoProvisioningMap, filterNullOrUndefined, fromError, newError, sleep } from 'yooi-utils';
import buildInfo from '../utils/buildInfo';
import { reportClientTrace } from '../utils/clientReporterUtils';
import { fetchJSON } from '../utils/fetchUtils';
import type { CustomEventSource, NetworkClientError, NetworkClientResponse } from './networkClientUtils';
import { isNetworkCallSuccessful } from './networkClientUtils';

interface TimeseriesValuesRequest {
  propertyId: string,
  timeseries: {
    objectId: string | string[],
    timeRange: TimeRange,
  }[],
}

const EVENT_SOURCE_MAX_RETRY_TIME = 2000;
const REQUEST_SIZE_LIMIT = 1_000_000;

interface CreateServerTimeseriesEventConnectorProps {
  onTimeseriesUpdate: ({ eventId }: { eventId: string }) => void,
  onTimeseriesConnected: (options: { lastBroadcastedMessageId: string, userId: string }) => void,
}

interface GetTimeseriesPropertyValues {
  propertyId: string,
  timeseries: TimeseriesRangeEntry[],
}

export interface NetworkTimeseriesClient {
  createServerTimeseriesEventConnector: ({ onTimeseriesUpdate }: CreateServerTimeseriesEventConnectorProps) => () => {
    disconnect: () => void,
  },
  getTimeseriesPropertyValues: (request: TimeseriesValuesRequest) => Promise<NetworkClientResponse<GetTimeseriesPropertyValues, PropertyTimeseriesResponseFailure>>,
}

interface CustomEventSourceEventMapForTimeseriesStream extends EventSourceEventMap {
  connected: MessageEvent,
  update: MessageEvent,
}

const createNetworkTimeseriesClient = ({ clientId, onUnauthorized }: { clientId: string, onUnauthorized: () => void }): NetworkTimeseriesClient => {
  const createServerTimeseriesEventConnector = ({ onTimeseriesUpdate, onTimeseriesConnected }: CreateServerTimeseriesEventConnectorProps) => () => {
    let eventSource: CustomEventSource<CustomEventSourceEventMapForTimeseriesStream>;

    const connect = () => {
      eventSource = new EventSource(`/timeseries/sync/events?clientId=${clientId}`);
      // Data should be available on the listener
      eventSource.addEventListener('update', ({ lastEventId }) => {
        onTimeseriesUpdate({ eventId: lastEventId });
      });
      eventSource.addEventListener('connected', ({ data }) => onTimeseriesConnected(JSON.parse(data)));
      eventSource.onerror = async () => {
        eventSource.close();

        const retryConnect = async () => {
          // Wait between 1 and 3s to retry
          await sleep(Math.round(1_000 + EVENT_SOURCE_MAX_RETRY_TIME * Math.random()));
          connect();
        };

        // EventSource doesn't provide any information about the error
        // Call the timeseries feature to check if the error is authentication related
        try {
          const { status } = await fetchJSON('/timeseries/sync', { timeout: 60_000 }); // with heavy request, it might be “normal” to have a long time request
          if (status === 401) {
            onUnauthorized();
          } else {
            await retryConnect();
          }
        } catch (e) {
          // Fetch failed, probably a network error
          reportClientTrace(fromError(e, 'Error while calling timeseries sync'));
          await retryConnect();
        }
      };
    };

    connect();

    return { disconnect: () => eventSource.close() };
  };

  const processingTimeseriesRequestMap = createAutoProvisioningMap<string, Promise<NetworkClientResponse<PropertyTimeseriesResponseSuccess, PropertyTimeseriesResponseFailure>>>();

  const getRequestKeyFromData = (propertyId: string, objectId: string | string[], timeRange: TimeRange) => (
    `${propertyId}_${typeof objectId === 'string' ? objectId : objectId.join('|')}_${timeRange.from}_${timeRange.to}`
  );

  const getDataFromRequestKey = (requestKey: string): { propertyId: string, objectId: string[], timeRange: TimeRange } => {
    const [propertyId, objectId, timeRangeFrom, timeRangeTo] = requestKey.split('_');
    const objectIds = objectId.split('|');
    return {
      propertyId,
      objectId: objectIds,
      timeRange: { from: Number(timeRangeFrom), to: Number(timeRangeTo) },
    };
  };

  const getTimeseriesPropertyValues = async ({ propertyId, timeseries }: TimeseriesValuesRequest) => {
    const toCleanupOnErrorKeys = new Set<string>();
    try {
      const allRequestedTimeseriesKeys = timeseries.map(({ objectId, timeRange }) => getRequestKeyFromData(propertyId, objectId, timeRange));

      const alreadyProcessingTimeseriesRequestPromises = new Map<string, Promise<NetworkClientResponse<PropertyTimeseriesResponseSuccess, PropertyTimeseriesResponseFailure>>>();
      allRequestedTimeseriesKeys.forEach((requestKey) => {
        const processingPromise = processingTimeseriesRequestMap.get(requestKey);
        if (processingPromise) {
          alreadyProcessingTimeseriesRequestPromises.set(requestKey, processingPromise);
        }
      });

      const allTimeseriesKeysToProcess = allRequestedTimeseriesKeys
        .filter((requestKey) => !processingTimeseriesRequestMap.has(requestKey));

      const answers: TimeseriesRangeEntry[] = [];
      if (allTimeseriesKeysToProcess.length) {
        const path = `/timeseries/property/${propertyId}`;
        const textEncoder = new TextEncoder();
        const data = getDataFromRequestKey(allTimeseriesKeysToProcess[0]);
        const oneKeyRequestSize = textEncoder.encode(JSON.stringify({ objectId: data.objectId, timeRange: data.timeRange })).length;
        const batchSize = Math.floor(REQUEST_SIZE_LIMIT / oneKeyRequestSize);

        for (let i = 0; i < allTimeseriesKeysToProcess.length; i += batchSize) {
          const toProcessTimeseriesKeys = allTimeseriesKeysToProcess
            .slice(i, i + batchSize);

          const requestPromise = (async () => {
            const { status, response } = await fetchJSON<
              { status: 200, response: PropertyTimeseriesResponseSuccess } | { status: 400 | 401 | 500, response: PropertyTimeseriesResponseFailure }
            >(path, {
              method: 'POST',
              json: {
                objects: toProcessTimeseriesKeys
                  .map((requestKey) => getDataFromRequestKey(requestKey))
                  .map(({ objectId, timeRange }) => ({ objectId, timeRange })),
              },
            });
            if (status === 200) {
              return { httpStatus: status, answer: response };
            } else if (status === 401) {
              onUnauthorized();
              return { httpStatus: status, error: newError('Failed to update timeseries, unauthorized', { clientId, sourceHash: buildInfo.sourceHash, time: Date.now(), response }) };
            } else {
              return { httpStatus: status, error: newError('Failed to update timeseries', { clientId, sourceHash: buildInfo.sourceHash, time: Date.now(), response }) };
            }
          })();

          // Add promise to processing timeseries request map, maintain the toCleanupOnErrorKeys in case an error is thrown
          toProcessTimeseriesKeys.forEach((notProcessingTimeseriesKey) => {
            toCleanupOnErrorKeys.add(notProcessingTimeseriesKey);
            processingTimeseriesRequestMap.createIfMissing(notProcessingTimeseriesKey, () => requestPromise);
          });

          const answer = await requestPromise;
          // every emitted promise had been resolved, can be globally cleaned up
          toProcessTimeseriesKeys.forEach((notProcessingTimeseriesKey) => {
            processingTimeseriesRequestMap.delete(notProcessingTimeseriesKey);
            toCleanupOnErrorKeys.delete(notProcessingTimeseriesKey);
          });

          if (isNetworkCallSuccessful(answer)) {
            answers.push(...answer.answer.timeseries);
          } else {
            reportClientTrace(newError('Timeseries network call unsuccessful', { propertyId, timeseries }));
            const result: NetworkClientError<PropertyTimeseriesResponseFailure> = { answer: { status: PropertyTimeseriesResponseStatuses.error }, error: answer.error };
            return result;
          }
        }
      }

      const otherAnswers = await Promise.all(Array.from(alreadyProcessingTimeseriesRequestPromises).map(async ([requestKey, promise]) => {
        const answer = await promise;
        const { objectId } = getDataFromRequestKey(requestKey);
        if (isNetworkCallSuccessful(answer)) {
          return { success: answer.answer.timeseries.find((t) => t.objectId.toString() === objectId.toString()) };
        } else {
          return { error: answer.error };
        }
      }));

      const answerError = otherAnswers.find((answer): answer is { error: Error, httpStatus: number | undefined } => answer.error !== undefined);
      if (answerError) {
        reportClientTrace(newError('Timeseries network call unsuccessful', { propertyId, timeseries }));
        const result: NetworkClientError<PropertyTimeseriesResponseFailure> = { answer: { status: PropertyTimeseriesResponseStatuses.error }, error: answerError.error };
        return result;
      }

      return {
        answer: {
          status: PropertyTimeseriesResponseStatuses.accepted,
          propertyId,
          timeseries: [...(otherAnswers.map(({ success }) => success).filter(filterNullOrUndefined)), ...answers],
        },
      };
    } catch (err) {
      Array.from(toCleanupOnErrorKeys).forEach((key) => processingTimeseriesRequestMap.delete(key));
      reportClientTrace(fromError(err, 'Error occurred when fetching timeseries', { propertyId, timeseries }));
      return { status: PropertyTimeseriesResponseStatuses.error, error: err as Error };
    }
  };

  return { createServerTimeseriesEventConnector, getTimeseriesPropertyValues };
};

export default createNetworkTimeseriesClient;
