import { graphlib, layout } from '@dagrejs/dagre';
import type { FunctionComponent } from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import type { DimensionsMapping, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import {
  createValuePathResolver,
  dimensionsMappingToParametersMapping,
  getFieldUtilsHandler,
  getPathReturnedConceptDefinitionId,
  isMultiDimensionResolution,
  isSingleDimensionResolution,
  isSingleFieldResolution,
} from 'yooi-modules/modules/conceptModule';
import { Concept_Name, TextField } from 'yooi-modules/modules/conceptModule/ids';
import type { Column, GraphChartFieldConfiguration, GraphChartSubStepDisplay } from 'yooi-modules/modules/dashboardModule/fields/graphChartField';
import { CardDisplayMode } from 'yooi-modules/modules/dashboardModule/fields/graphChartField';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { compareNumber, createAutoProvisioningMap, extractAndCompareValue, filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { TableSortDirection } from '../../../../components/molecules/Table';
import useAuth from '../../../../store/useAuth';
import type { FrontObjectStore } from '../../../../store/useStore';
import useStore from '../../../../store/useStore';
import makeStyles from '../../../../utils/makeStyles';
import { formatOrUndef } from '../../../../utils/stringUtils';
import useBackdropClick from '../../../../utils/useBackdropClick';
import useDeepMemo from '../../../../utils/useDeepMemo';
import useDerivedState from '../../../../utils/useDerivedState';
import { resolveColorFromPath } from '../../conceptDisplayUtils';
import { getFieldColumnComparatorKey } from '../../fieldUtils';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import type { GroupByFieldMetadata } from '../../groupByUtils';
import { resolveGroupByFieldPath } from '../../groupByUtils';
import { getConceptFilterFunction } from '../../listFilterFunctions';
import type useFilterAndSort from '../../useFilterAndSort';
import { getListHandlers } from '../../useFilterAndSort';
import ArcherContainer from './archer/ArcherContainer';
import { getBlockArrowLabel, getBlockLabel } from './configuration/GraphChartBlockCard';
import GraphChartColumn, { GRAPH_CHART_COLUMN_IN_REM } from './GraphChartColumn';
import { NO_GROUP_KEY } from './GraphChartItemGroup';
import type { Highlight } from './graphChartUtils';
import { getArrowColor, getBlockIdsUsedInPath, HighlightedType } from './graphChartUtils';

type GroupCollapseMap = Map<string, 'collapsed' | 'expanded'>;

export type OnHighlight = (highlight: Highlight) => void;

export interface GroupCollapseHandler {
  onCollapse: (arrowGroupKey: string) => void,
  onExpand: (arrowGroupKey: string) => void,
  isGroupCollapsed: (blockId: string, arrowGroupKey: string) => boolean,
}

export type IsHighlighted = (key: string) => Highlight | undefined;

interface GraphChartProps {
  config: GraphChartFieldConfiguration,
  parametersMapping: ParametersMapping,
}

const GRAPH_CHART_COLUMN_GAP_IN_REM = 12;

const useStyles = makeStyles({
  centeredScroll: {
    margin: 'auto',
  },
  container: {
    display: 'grid',
    gap: `${GRAPH_CHART_COLUMN_GAP_IN_REM}rem`,
    gridAutoFlow: 'column',
    gridAutoColumns: `${GRAPH_CHART_COLUMN_IN_REM}rem`,
  },
}, 'graphChart');

export interface Group {
  key: string,
  arrowKey: string,
  resolveArrowLabel: (inheritedDimensionsMapping: DimensionsMapping) => string,
  resolveArrowColor: (inheritedDimensionsMapping: DimensionsMapping) => string | undefined,
  items: Item[],
}

export interface Item {
  key: string,
  arrowKey: string,
  resolveArrowLabel: (inheritedDimensionsMapping: DimensionsMapping) => string,
  resolveArrowColor: (inheritedDimensionsMapping: DimensionsMapping) => string | undefined,
  blockId: string,
  instanceId: string,
  dimensionsMapping: DimensionsMapping,
  parametersMapping: ParametersMapping,
  previousItems: Item[],
  nextItems: Item[],
  groupKey: string | undefined,
  group: Group | undefined,
}

interface ValidBlock {
  type: 'valid',
  blockLabel: string,
  conceptDefinitionId: string,
  blockIndex: number,
  blockId: string,
  items: Item[],
  groups: Group[],
  groupByFieldMetadata: GroupByFieldMetadata | undefined,
  display: GraphChartSubStepDisplay,
}

interface InvalidBlock {
  type: 'invalid',
  blockId: string,
  blockIndex: number,
  blockLabel: string,
}

export interface ColumnResolution {
  columnId: string,
  blocks: (ValidBlock | InvalidBlock)[],
  pagination?: {
    currentPage: number,
    totalPagesNumber: number,
    totalItems: number,
    itemsRange: {
      low: number,
      high: number,
    },
  },
}

const sortAndGroup = (
  list: Omit<Item, 'groupKey'>[],
  sort: Parameters<typeof useFilterAndSort<Omit<Item, 'groupKey'>>>[3],
  groupByKey: string | undefined,
  getGroupKey: ((item: Omit<Item, 'groupKey'>) => string | undefined) | undefined
): Item[] => {
  const { comparator, groupByExtractor, sortExtractor } = getListHandlers(sort, groupByKey, undefined);

  const mappedList = list.map((item) => ({
    item,
    key: item.key,
    groupKey: getGroupKey?.(item),
    groupValue: groupByExtractor?.(item),
    sortValue: sortExtractor?.(item),
  }));

  if (comparator !== undefined) {
    mappedList.sort(comparator);
  }

  return mappedList.map(({ item, groupKey }) => joinObjects(item, { groupKey }));
};

const resolveColumn = (
  store: FrontObjectStore,
  userId: string,
  getFiltersConfiguration: (blockId: string) => FilterConfiguration | undefined,
  column: Column,
  parametersMapping: ParametersMapping,
  previousColumnResolution: ColumnResolution | undefined,
  page: number | undefined = 0
): ColumnResolution => {
  let itemCount = column.itemPerPage;
  let unusedItemCount = column.itemPerPage !== undefined ? (column.itemPerPage * page) : undefined;
  let totalItemCount = 0;

  const resolution: Omit<ColumnResolution, 'pagination'> = { columnId: column.id, blocks: [] };

  column.blocks.forEach(({ path, label, id: blockId, groupByFieldPath, arrowLabel, arrowColor: confArrowColor, display }, index) => {
    if (path) {
      const conceptDefinitionId = getPathReturnedConceptDefinitionId(store, path);
      if (!conceptDefinitionId) {
        resolution.blocks.push({ type: 'invalid', blockId, blockIndex: index, blockLabel: getBlockLabel(store, index, path, label) });
      } else {
        const blockInstanceIdsWithParentItems = createAutoProvisioningMap<string, Item[]>();
        const ingestBlockInstanceIdsWithParentItems = (previousItems: Item[] = []) => {
          const resolutionParameters: ParametersMapping = joinObjects(
            parametersMapping,
            Object.fromEntries(previousItems.map((previousItem) => ([previousItem.blockId, { type: 'single' as const, id: previousItem.instanceId }])))
          );
          const resolvedDimension = createValuePathResolver(store, resolutionParameters).resolvePathDimension(path);
          if (!resolvedDimension || resolvedDimension instanceof Error) {
            // do nothing
          } else if (isSingleDimensionResolution(resolvedDimension) && resolvedDimension.instance) {
            const instanceId = resolvedDimension.instance.id;
            blockInstanceIdsWithParentItems.getOrCreate(instanceId, () => []).push(...previousItems);
          } else if (isMultiDimensionResolution(resolvedDimension)) {
            resolvedDimension.instances.forEach(({ id: instanceId }) => {
              blockInstanceIdsWithParentItems.getOrCreate(instanceId, () => []).push(...previousItems);
            });
          }
        };

        if (previousColumnResolution) {
          const blockIdsUsedInPath = new Set(getBlockIdsUsedInPath(path, new Set(previousColumnResolution.blocks.map(({ blockId: previousBlockId }) => previousBlockId))));
          if (blockIdsUsedInPath.size) {
            previousColumnResolution.blocks
              .filter(({ blockId: previousBlockId }) => blockIdsUsedInPath.has(previousBlockId))
              .reduce<Item[][]>((blocksItemsUsedInPath, blockUsedInPath) => {
                if (blockUsedInPath.type !== 'valid') {
                  return [];
                } else if (blocksItemsUsedInPath.length === 0) {
                  return blockUsedInPath.items.map((item) => [item]);
                } else {
                  return blocksItemsUsedInPath.flatMap((otherItems) => blockUsedInPath.items.map((item) => [...otherItems, item]));
                }
              }, [])
              .forEach(ingestBlockInstanceIdsWithParentItems);
          } else {
            ingestBlockInstanceIdsWithParentItems();
          }
        } else {
          ingestBlockInstanceIdsWithParentItems();
        }

        const filterFunction = getConceptFilterFunction(store, conceptDefinitionId, getFiltersConfiguration(blockId), userId);
        const groupByFieldMetadata = groupByFieldPath ? resolveGroupByFieldPath(store, groupByFieldPath) : undefined;
        const items = sortAndGroup(
          Array.from(blockInstanceIdsWithParentItems)
            .filter(([instanceId]) => !filterFunction || (store.getObjectOrNull(instanceId) ? filterFunction(store.getObject(instanceId)) : false))
            .map(([instanceId, previousItems]) => {
              const dimensionsMapping = { [blockId]: instanceId };
              const itemParameters = joinObjects(dimensionsMappingToParametersMapping(dimensionsMapping), parametersMapping);
              return ({
                key: instanceId,
                instanceId,
                blockId,
                arrowKey: `${blockId}_${instanceId}`,
                resolveArrowColor: (inheritedDimensionsMapping) => {
                  const arrowColor = getArrowColor(confArrowColor, conceptDefinitionId);
                  if (arrowColor?.type === 'path') {
                    return resolveColorFromPath(store, joinObjects(itemParameters, dimensionsMappingToParametersMapping(inheritedDimensionsMapping)), arrowColor.path);
                  } else {
                    return arrowColor?.value;
                  }
                },
                resolveArrowLabel: (inheritedDimensionsMapping) => {
                  let resolvedArrowLabel: string | undefined;
                  if (arrowLabel?.type === 'path') {
                    const fieldResolution = createValuePathResolver(store, joinObjects(itemParameters, dimensionsMappingToParametersMapping(inheritedDimensionsMapping)))
                      .resolvePathField(arrowLabel.path);
                    if (
                      isSingleFieldResolution(fieldResolution) && fieldResolution.dimensionsMapping
                      && store.getObjectOrNull(fieldResolution.fieldId) && isInstanceOf(store.getObject(fieldResolution.fieldId), TextField)
                    ) {
                      resolvedArrowLabel = getFieldUtilsHandler(store, fieldResolution.fieldId).getValueAsText?.(fieldResolution.dimensionsMapping);
                    }
                  } else if (arrowLabel) {
                    resolvedArrowLabel = arrowLabel.value;
                  }
                  return formatOrUndef(getBlockArrowLabel(store, path, resolvedArrowLabel));
                },
                dimensionsMapping,
                parametersMapping: itemParameters,
                previousItems,
                nextItems: [],
                group: undefined,
              });
            }),
          { getComparatorHandler: getFieldColumnComparatorKey(store), initial: { key: Concept_Name, direction: TableSortDirection.asc } },
          groupByFieldMetadata?.fieldId,
          groupByFieldMetadata?.getGroupKey
        ).filter(() => {
          if (unusedItemCount === undefined || itemCount === undefined) {
            return true;
          } else {
            totalItemCount += 1;
            if (unusedItemCount > 0) {
              unusedItemCount -= 1;
            } else if (itemCount > 0) {
              itemCount -= 1;
              return true;
            }
          }
          return false;
        });

        items.forEach((item) => item.previousItems.forEach((previousItem) => previousItem.nextItems.push(item)));

        resolution.blocks.push({
          type: 'valid',
          blockId,
          blockIndex: index,
          conceptDefinitionId,
          blockLabel: getBlockLabel(store, index, path, label),
          items,
          groups: Array.from(items.reduce((acc, item: Item) => {
            const groupItems = acc.getOrCreate(
              item.groupKey ?? NO_GROUP_KEY,
              (): Group => ({
                key: item.groupKey ?? NO_GROUP_KEY,
                arrowKey: `${blockId}_${item.groupKey ?? NO_GROUP_KEY}`,
                resolveArrowColor: (inheritedDimensionsMapping) => {
                  const arrowColor = getArrowColor(confArrowColor, conceptDefinitionId);
                  if (arrowColor?.type === 'path') {
                    return resolveColorFromPath(store, joinObjects(dimensionsMappingToParametersMapping(inheritedDimensionsMapping), parametersMapping), arrowColor.path);
                  } else {
                    return arrowColor?.value;
                  }
                },
                resolveArrowLabel: (inheritedDimensionsMapping) => {
                  let resolvedArrowLabel: string | undefined;
                  if (arrowLabel?.type === 'path') {
                    const fieldResolution = createValuePathResolver(store, joinObjects(dimensionsMappingToParametersMapping(inheritedDimensionsMapping), parametersMapping))
                      .resolvePathField(arrowLabel.path);
                    if (
                      fieldResolution
                      && isSingleFieldResolution(fieldResolution) && fieldResolution.dimensionsMapping
                      && store.getObjectOrNull(fieldResolution.fieldId) && isInstanceOf(store.getObject(fieldResolution.fieldId), TextField)
                    ) {
                      resolvedArrowLabel = getFieldUtilsHandler(store, fieldResolution.fieldId).getValueAsText?.(fieldResolution.dimensionsMapping);
                    }
                  } else if (arrowLabel) {
                    resolvedArrowLabel = arrowLabel.value;
                  }
                  return formatOrUndef(getBlockArrowLabel(store, path, resolvedArrowLabel));
                },
                items: [],
              })
            );
            // eslint-disable-next-line no-param-reassign
            item.group = groupItems;
            groupItems.items.push(item);
            return acc;
          }, createAutoProvisioningMap<string, Group>()).values()),
          groupByFieldMetadata,
          display: display || { type: 'chip' },
        });
      }
    } else {
      resolution.blocks.push({ type: 'invalid', blockId, blockIndex: index, blockLabel: getBlockLabel(store, index, path, label) });
    }
  });

  return joinObjects(
    resolution,
    {
      pagination: column.itemPerPage !== undefined ? {
        totalPagesNumber: Math.ceil(totalItemCount / column.itemPerPage),
        totalItems: totalItemCount,
        currentPage: page,
        itemsRange: {
          low: column.itemPerPage * page + 1,
          high: column.itemPerPage * (page + 1),
        },
      } : undefined,
    }
  );
};

const resolveColumns = (
  store: FrontObjectStore,
  columns: Column[],
  loggedUserId: string,
  parametersMapping: ParametersMapping,
  getFilter: (blockId: string) => FilterConfiguration | undefined,
  getPage: (columnId: string) => number | undefined,
  previousColumnResolution?: ColumnResolution
): ColumnResolution[] => {
  const column = columns.at(0);
  if (column) {
    const columnResolution = resolveColumn(store, loggedUserId, getFilter, column, parametersMapping, previousColumnResolution, getPage(column.id));
    const resolutions = resolveColumns(store, columns.slice(1), loggedUserId, parametersMapping, getFilter, getPage, columnResolution);
    return [columnResolution, ...resolutions];
  } else {
    return [];
  }
};

const getItemArrowKeys = (item: Item): Set<string> => {
  if (item.group) {
    return new Set([item.group.arrowKey, item.arrowKey]);
  } else {
    return new Set([item.arrowKey]);
  }
};

const getPreviousArrowKeys = (item: Item): string[] => (item.previousItems.flatMap((i) => [...getItemArrowKeys(i), ...getPreviousArrowKeys(i)]));
const getNextArrowKeys = (item: Item): string[] => (item.nextItems.flatMap((i) => [...getItemArrowKeys(i), ...getNextArrowKeys(i)]));

const GraphChart: FunctionComponent<GraphChartProps> = ({ config, parametersMapping }) => {
  const classes = useStyles();

  const store = useStore();
  const { loggedUserId } = useAuth();

  const [highlightedAs, setHighlighted, resetHighlighted] = useDerivedState(() => new Map<string, Highlight>(), []);
  const [paginationMap, setPaginationMap] = useState(new Map<string, number>());
  const [filterMap, setFilterMap] = useState(new Map<string, FilterConfiguration>());

  const containerRef = useRef<HTMLDivElement>(null);

  useBackdropClick(containerRef, resetHighlighted);

  const [groupCollapseMap, setGroupCollapseMap] = useState<GroupCollapseMap>(new Map());
  const defaultGroupCollapsedBlocks = useMemo(() => {
    const result = new Set<string>();
    config.columns?.forEach((column: Column) => column.blocks.forEach((block) => {
      if (block.collapsedGroups === CardDisplayMode.Closed) {
        result.add(block.id);
      }
    }));
    return result;
  }, [config.columns]);

  const columnResolutions = useDeepMemo(() => {
    const resolution = resolveColumns(
      store,
      config.columns ?? [],
      loggedUserId,
      parametersMapping,
      (blockId) => filterMap.get(blockId),
      (columnId) => paginationMap.get(columnId)
    );
    // Use dagre to optimize edge crossing
    // Optimize only if: no pagination as we don't want to reorder items on page changes
    if (resolution.every((columnResolution) => (columnResolution.pagination?.totalPagesNumber ?? 1) <= 1)) {
      const g = new graphlib.Graph();
      g.setGraph({ rankdir: 'LR' });
      g.setDefaultEdgeLabel(() => ({}));
      resolution.forEach((column) => {
        column.blocks.forEach((block) => {
          if (block.type === 'valid') {
            if (defaultGroupCollapsedBlocks.has(block.blockId)) {
              block.groups.forEach((group) => {
                g.setNode(group.arrowKey, { label: group.arrowKey, id: group.arrowKey });
                group.items.forEach((item) => {
                  item.previousItems.forEach((previousItem) => {
                    if (defaultGroupCollapsedBlocks.has(previousItem.blockId)) {
                      g.setEdge(previousItem.group?.arrowKey ?? previousItem.arrowKey, group.arrowKey);
                    } else {
                      g.setEdge(previousItem.arrowKey, group.arrowKey);
                    }
                  });
                });
              });
            } else {
              block.items.forEach((item) => {
                g.setNode(item.arrowKey, { label: item.arrowKey, id: item.instanceId });
                item.previousItems.forEach((previousItem) => {
                  if (defaultGroupCollapsedBlocks.has(previousItem.blockId)) {
                    g.setEdge(previousItem.group?.arrowKey ?? previousItem.arrowKey, item.arrowKey);
                  } else {
                    g.setEdge(previousItem.arrowKey, item.arrowKey);
                  }
                });
              });
            }
          }
        });
      });
      layout(g);
      resolution.forEach((column) => {
        column.blocks.forEach((block) => {
          if (block.type === 'valid') {
            if (defaultGroupCollapsedBlocks.has(block.blockId)) {
              block.groups.sort(extractAndCompareValue((group) => g.node(group.arrowKey).y, compareNumber));
            } else {
              block.groups.forEach(({ items }) => {
                items.sort(extractAndCompareValue((item) => g.node(item.arrowKey).y, compareNumber));
              });
            }
          }
        });
      });
    }
    return resolution;
  }, [config.columns, filterMap, loggedUserId, paginationMap, parametersMapping, store.getSerial()]);

  const groupedBlockIds = useDeepMemo(
    () => new Set(columnResolutions
      .flatMap((columnResolution) => columnResolution.blocks.map((block) => (block.type === 'valid' && block.groupByFieldMetadata ? block.blockId : undefined)))
      .filter(filterNullOrUndefined)),
    [columnResolutions]
  );
  const isGroupCollapsed = useCallback((blockId: string, arrowGroupKey: string) => (
    groupedBlockIds.has(blockId)
    && (groupCollapseMap.get(arrowGroupKey) === 'collapsed' || (defaultGroupCollapsedBlocks.has(blockId) && groupCollapseMap.get(arrowGroupKey) !== 'expanded'))
  ), [defaultGroupCollapsedBlocks, groupCollapseMap, groupedBlockIds]);

  const highlightedKeys = useMemo(() => {
    if (highlightedAs.size === 0) {
      return new Set();
    } else {
      return new Set(
        columnResolutions
          .flatMap(({ blocks }) => blocks)
          .filter((block: ValidBlock | InvalidBlock): block is ValidBlock => block.type === 'valid')
          .flatMap((block) => block.groups.flatMap((({ arrowKey: groupArrowKey, items: groupItems }) => {
            const groupHighlight = highlightedAs.get(groupArrowKey);
            if (groupHighlight?.type === HighlightedType.group) {
              return groupItems.flatMap((item) => ([...getPreviousArrowKeys(item), ...getItemArrowKeys(item), ...getNextArrowKeys(item)]));
            } else if (groupHighlight?.type === HighlightedType.arrow) {
              if (groupHighlight.source.key === groupArrowKey) {
                return groupItems
                  .filter(({ previousItems }) => previousItems.some((previousItem) => getItemArrowKeys(previousItem).has(groupHighlight.target.key)))
                  .flatMap((item) => ([...getItemArrowKeys(item), ...getNextArrowKeys(item)]));
              } else {
                return groupItems
                  .filter(({ nextItems }) => nextItems.some((nextItem) => getItemArrowKeys(nextItem).has(groupHighlight.source.key)))
                  .flatMap((item) => ([...getItemArrowKeys(item), ...getPreviousArrowKeys(item)]));
              }
            } else {
              return groupItems.flatMap((item) => {
                const highlight = highlightedAs.get(item.arrowKey);
                if (!highlight) {
                  return [];
                } else if (highlight.type === HighlightedType.arrow) {
                  if (highlight.source.key === item.arrowKey) {
                    return [...getItemArrowKeys(item), ...getNextArrowKeys(item)];
                  } else {
                    return [...getItemArrowKeys(item), ...getPreviousArrowKeys(item)];
                  }
                } else {
                  return [...getPreviousArrowKeys(item), ...getItemArrowKeys(item), ...getNextArrowKeys(item)];
                }
              });
            }
          })))
      );
    }
  }, [highlightedAs, columnResolutions]);

  const onHighlight: OnHighlight = (highlight) => setHighlighted(() => {
    if (highlight.type === HighlightedType.arrow) {
      return new Map().set(highlight.source.key, highlight).set(highlight.target.key, highlight);
    } else {
      return new Map().set(highlight.key, highlight);
    }
  });

  const resetHighlight = () => setHighlighted(new Map());

  const totalWidthInRem = ((GRAPH_CHART_COLUMN_IN_REM + GRAPH_CHART_COLUMN_GAP_IN_REM) * columnResolutions.length) - GRAPH_CHART_COLUMN_GAP_IN_REM;

  return (
    <div className={classes.centeredScroll}>
      <ArcherContainer style={{ width: `${totalWidthInRem}rem` }}>
        <div
          ref={containerRef}
          className={classes.container}
          aria-hidden="true"
          style={config.graphStyle === 'top' ? undefined : { alignItems: 'center' }}
          onClick={(e) => {
            if (e.target === containerRef.current) {
              resetHighlighted();
            }
          }}
        >
          {columnResolutions.map((columnResolution) => (
            <GraphChartColumn
              key={columnResolution.columnId}
              columnResolution={columnResolution}
              groupCollapseHandler={{
                onCollapse: (arrowGroupKey) => setGroupCollapseMap((old) => new Map(old).set(arrowGroupKey, 'collapsed')),
                onExpand: (arrowGroupKey) => setGroupCollapseMap((old) => new Map(old).set(arrowGroupKey, 'expanded')),
                isGroupCollapsed,
              }}
              onHighlight={onHighlight}
              resetHighlight={resetHighlight}
              isHighlighted={(arrowKey) => highlightedAs.get(arrowKey) ?? (highlightedKeys.has(arrowKey) ? { type: HighlightedType.inPath, key: arrowKey } : undefined)}
              hasHighlightedItem={highlightedAs.size > 0 || highlightedKeys.size > 0}
              getSearch={(blockId) => filterMap.get(blockId)?.nameSearch}
              setSearch={(blockId, nameSearch) => setFilterMap((old) => new Map(old).set(blockId, joinObjects(old.get(blockId), { nameSearch })))}
              getFilters={(blockId) => filterMap.get(blockId)?.filters}
              setFilters={(blockId, filters) => setFilterMap((old) => new Map(old).set(blockId, joinObjects(old.get(blockId), { filters })))}
              onResetFilters={(blockId) => setFilterMap((old) => new Map(old).set(blockId, joinObjects(old.get(blockId), { filters: undefined })))}
              onPage={(page) => setPaginationMap((old) => new Map(old).set(columnResolution.columnId, page))}
              onPrevious={() => setPaginationMap((old) => new Map(old).set(columnResolution.columnId, (old.get(columnResolution.columnId) ?? 1) - 1))}
              onNext={() => setPaginationMap((old) => new Map(old).set(columnResolution.columnId, (old.get(columnResolution.columnId) ?? 1) + 1))}
            />
          ))}
        </div>
      </ArcherContainer>
    </div>
  );
};

export default GraphChart;
