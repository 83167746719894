import { notifyError } from '../utils/notify';

interface AttachmentStoreReadOnly {
  getAttachmentUrl: (objectId: string, propertyId: string) => (revisionId: string, wait: boolean) => string,
}

export interface AttachmentStore extends AttachmentStoreReadOnly {
  uploadAttachment: (
    options: {
      objectId: string,
      propertyId: string,
      contentType: string,
      data: unknown,
      name?: string,
    },
    onUploaded: (revisionId: string) => void,
    onError?: (error: string) => void,
  ) => void,
  cloneAttachment: (
    options: {
      objectId: string,
      propertyId: string,
      originObjectId: string,
      originPropertyId: string,
      originRevisionId: string,
    },
    onCloned: (revisionId: string) => void,
    onError?: (error: string) => void,
  ) => void,
  isSafeAttachment: (
    options: { objectId: string, propertyId: string, revisionId: string },
    onAnswer: (isSafe: 'pending' | 'safe' | 'unsafe') => Promise<void>,
    onError?: (error: string) => void,
  ) => void,
}

const createAttachmentStore = ({ uploadAttachment, cloneAttachment, isSafeAttachment, getAttachmentUrl }: {
  uploadAttachment: (options: { objectId: string, propertyId: string, contentType: string, data: unknown }) => Promise<string>,
  cloneAttachment: (options: {
    objectId: string,
    propertyId: string,
    originObjectId: string,
    originPropertyId: string,
    originRevisionId: string,
  }) => Promise<string>,
  isSafeAttachment: (options: { objectId: string, propertyId: string, revisionId: string }) => Promise<'pending' | 'safe' | 'unsafe'>,
  getAttachmentUrl: (objectId: string, propertyId: string) => (revisionId: string, wait: boolean) => string,
}): AttachmentStore => {
  const handleAttachmentCreation = (
    createFunction: () => Promise<string>,
    storeFunction: (revisionId: string) => void,
    onErrorFunction?: (error: string) => void
  ) => {
    (async () => {
      try {
        const revisionId = await createFunction();
        storeFunction(revisionId);
      } catch (err) {
        const error = (err as Error).message.includes('StatusText:') ? (err as Error).message.substring('StatusText:'.length) : (err as Error).message;
        notifyError(error);
        onErrorFunction?.(error);
      }
    })();
  };

  return {
    uploadAttachment: ({ objectId, propertyId, contentType, data }, storeFunction, onErrorFunction) => {
      handleAttachmentCreation(
        () => uploadAttachment({ objectId, propertyId, contentType, data }),
        storeFunction,
        onErrorFunction
      );
    },
    cloneAttachment: ({ objectId, propertyId, originObjectId, originPropertyId, originRevisionId }, storeFunction, onErrorFunction) => {
      handleAttachmentCreation(
        () => cloneAttachment({ objectId, propertyId, originObjectId, originPropertyId, originRevisionId }),
        storeFunction,
        onErrorFunction
      );
    },
    isSafeAttachment: ({ objectId, propertyId, revisionId }, onAnswer, onErrorFunction) => {
      (async () => {
        try {
          const isSafe = await isSafeAttachment({ objectId, propertyId, revisionId });
          await onAnswer(isSafe);
        } catch (err) {
          const error = (err as Error).message.includes('StatusText:') ? (err as Error).message.substring('StatusText:'.length) : (err as Error).message;
          notifyError(error);
          onErrorFunction?.(error);
        }
      })();
    },
    getAttachmentUrl,
  };
};

export default createAttachmentStore;
