import classnames from 'classnames';
import type { ComponentProps, FunctionComponent } from 'react';
import { useCallback } from 'react';
import { DataAsset, DataAssetType_Name } from 'yooi-modules/modules/dataAssetModule/ids';
import { ResourceType_Name } from 'yooi-modules/modules/resourceModule/ids';
import { joinObjects } from 'yooi-utils';
import Button, { ButtonVariant } from '../../components/atoms/Button';
import Icon, { IconName } from '../../components/atoms/Icon';
import StopClickPropagation from '../../components/atoms/StopClickPropagation';
import Tooltip from '../../components/atoms/Tooltip';
import Typo, { TypoVariant } from '../../components/atoms/Typo';
import UploadFileButton from '../../components/inputs/UploadFileButton';
import CompositeField, { CompositeFieldCloseReasons } from '../../components/molecules/CompositeField';
import Link from '../../components/molecules/Link';
import SearchAndSelect from '../../components/molecules/SearchAndSelect';
import SpacingLine from '../../components/molecules/SpacingLine';
import useStore from '../../store/useStore';
import base from '../../theme/base';
import { FontVariant } from '../../theme/fontDefinition';
import { buildPadding, Spacing, spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { buildComponentPropertyStyleInRem, HierarchyVariant, SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import StoreTextInputField from './input/StoreTextInputField';
import { getChipOptions, getConceptDefinitionNameOrEntity, getSearchChipOptions } from './modelTypeUtils';
import type { ChipOption } from './modelTypeUtilsType';

const useStyles = makeStyles((theme) => ({
  buttonMain: {
    minHeight: '2.4rem',
    minWidth: '2.4rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: theme.color.transparent,
    borderBottomRightRadius: '0.4rem',
    borderTopRightRadius: '0.4rem',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
    color: theme.color.text.brand,
    '&:hover': {
      backgroundColor: theme.color.background.neutral.subtle,
      borderColor: theme.color.border.default,
    },
    '&:focus': {
      backgroundColor: theme.color.background.neutral.subtle,
      borderColor: theme.color.border.default,
    },
    '&:active': {
      backgroundColor: theme.color.background.primarylight.default,
      borderColor: theme.color.border.primarylight,
    },
  },
  buttonDelete: {
    borderBottomRightRadius: '0',
    backgroundColor: theme.color.transparent,
    borderColor: theme.color.transparent,
    color: theme.color.text.danger,

    '&:disabled': {
      Opacity: base.opacity.twenty,
    },
    '&:hover': {
      backgroundColor: theme.color.background.neutral.subtle,
      borderColor: theme.color.border.default,
    },
    '&:focus': {
      backgroundColor: theme.color.background.neutral.subtle,
      borderColor: theme.color.border.default,
    },
    '&:pressed': {
      backgroundColor: theme.color.background.danger.light,
      borderColor: theme.color.border.danger,
    },
  },
  layoutContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: spacingRem.s,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    minWidth: '2.4rem',
    minHeight: '2.4rem',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
  },
  navigationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  fakeAnchor: {
    height: '2.2rem',
    display: 'flex',
    alignItems: 'center',
    color: theme.color.text.info,
    cursor: 'pointer',
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
  urlContainer: joinObjects(
    buildComponentPropertyStyleInRem('height', SizeVariant.main, HierarchyVariant.inline),
    {
      display: 'flex',
      alignItems: 'center',
    }
  ),
  linkContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
}), 'resourceInputField');

interface ImageReference {
  data?: ArrayBuffer,
  contentType?: string,
  url?: string,
}

interface ResourceInputFieldProps {
  url?: string,
  href?: string,
  analyzer?: string,
  image?: ImageReference,
  type?: string,
  getResourceTypeOptions?: () => ChipOption[],
  selectedDataAssetOption?: ChipOption,
  dataAssetType?: string,
  getDataAssetTypeOptions?: () => ChipOption[],
  getDataAssetOptions?: () => ChipOption[],
  onDataAssetCreate: (title: string) => string,
  onDataAssetUpdate?: (value: ChipOption | null) => void,
  onDataAssetTypeUpdate?: (value: ChipOption | null) => void,
  onResourceTypeUpdate?: (value: ChipOption | null) => void,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  isEditing?: boolean,
  onImageLoad: (data: { data?: ArrayBuffer, type?: string }) => void,
  onUrlChange?: (url: string | null) => void,
  onClearAll?: () => void,
  onOverlayClose: () => void,
  onEscape: () => void,
  resourceReadOnly?: boolean,
  readOnly?: boolean,
}

const ResourceInputField: FunctionComponent<ResourceInputFieldProps> = ({
  url,
  href,
  analyzer,
  image,
  type,
  getResourceTypeOptions,
  selectedDataAssetOption,
  dataAssetType,
  getDataAssetTypeOptions,
  getDataAssetOptions,
  onDataAssetCreate,
  onDataAssetUpdate,
  onDataAssetTypeUpdate,
  onResourceTypeUpdate,
  onEditionStart,
  onEditionStop,
  isEditing = false,
  onImageLoad,
  onUrlChange,
  onClearAll,
  onOverlayClose,
  onEscape,
  resourceReadOnly = false,
  readOnly = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const onOpenDropdown = useCallback(() => {
    onEditionStart?.();
  }, [onEditionStart]);

  const onCloseDropdown = useCallback((reason: CompositeFieldCloseReasons) => {
    onEditionStop?.();
    if (CompositeFieldCloseReasons.cancel === reason) {
      onEscape();
    } else if (CompositeFieldCloseReasons.validate === reason) {
      onOverlayClose();
    }
  }, [onEditionStop, onEscape, onOverlayClose]);

  const renderButton = (forDropdown: boolean) => {
    if (forDropdown && (url || image)) {
      return (
        <Tooltip title={i18n`Clear all`}>
          <div
            className={classnames(classes.buttonMain, classes.buttonDelete)}
            tabIndex={0}
            onClick={onClearAll}
            aria-hidden="true"
            role="button"
          >
            <Icon name={IconName.delete} />
          </div>
        </Tooltip>
      );
    } else {
      return undefined;
    }
  };

  let uploadValue: ComponentProps<typeof UploadFileButton>['value'];
  if (image?.url) {
    uploadValue = { type: 'url', url: image.url };
  } else if (image?.data && image.contentType) {
    uploadValue = { type: 'buffer', data: image.data, contentType: image.contentType };
  }

  const imageLine = {
    id: 'image',
    title: i18n`Image`,
    render: (
      <div className={classes.layoutContainer}>
        <SpacingLine>
          <UploadFileButton
            value={uploadValue}
            onChange={(newValue) => onImageLoad({ data: newValue.data, type: newValue.contentType })}
            typeRestriction={{ accept: 'image/*', isAccepted: (uploadType) => uploadType.startsWith('image/'), errorMessage: i18n`Invalid file format, only images are accepted` }}
          />
          {!image?.url && !image?.data ? (<Typo variant={FontVariant.small} color={theme.color.text.secondary}>{i18n`The maximum size per file is 5 MB.`}</Typo>) : null}
        </SpacingLine>
        <SpacingLine>
          {image?.url || image?.data ? (
            <Button
              onClick={() => onImageLoad({})}
              variant={ButtonVariant.danger}
              title={i18n`Delete`}
              iconName={IconName.delete}
            />
          ) : null}
        </SpacingLine>
      </div>
    ),
  };

  return (
    <CompositeField
      isEditing={isEditing}
      placeholder={i18n`Add resource`}
      headerHeight="5.2rem"
      readOnly={readOnly}
      doNotExpand={readOnly}
      headerLinesRenderers={(url) ? [
        {
          id: 'URL',
          customContainerStyle: { ...buildPadding({ left: Spacing.s, right: Spacing.none }) },
          render: (forDropdown) => (
            <>
              <div className={classes.urlContainer}>
                <Tooltip title={url}>
                  <Typo variant={TypoVariant.code} maxLine={1}>
                    {url}
                  </Typo>
                </Tooltip>
              </div>
              {renderButton(forDropdown)}
            </>
          ),
        },
        {
          id: 'navigate',
          customContainerStyle: { ...buildPadding({ left: Spacing.s, right: Spacing.none }) },
          render: () => (
            <div className={classes.navigationContainer}>
              <SizeContextProvider sizeVariant={SizeVariant.small}>
                <Typo variant={TypoVariant.code}>{analyzer}</Typo>
                <div className={classes.linkContainer}>
                  {!href && (
                    <StopClickPropagation>
                      <div
                        tabIndex={0}
                        className={classes.fakeAnchor}
                        onKeyDown={(event) => {
                          event.stopPropagation();
                          navigator.clipboard.writeText(url);
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText(url);
                        }}
                        role="link"
                      >
                        <Typo maxLine={1}>{i18n`Copy URL`}</Typo>
                        <div className={classes.iconContainer}><Icon name={IconName.link} /></div>
                      </div>
                    </StopClickPropagation>
                  )}
                  {href && (
                    <Link
                      isUserControlled
                      title={i18n`Navigate to`}
                      to={href}
                      iconName={IconName.output}
                      maxLine={1}
                      openInNewTab
                    />
                  )}
                </div>
              </SizeContextProvider>
            </div>
          ),
        },
      ] : []}
      headerImage={image}
      withImagePlaceholder
      getDropdownSectionDefinitions={() => [
        {
          id: 'main',
          lines: [
            {
              id: 'url',
              title: i18n`URL`,
              render: (
                <StoreTextInputField
                  initialValue={url}
                  onSubmit={(newValue) => onUrlChange?.(newValue)}
                  maxLine={1}
                  focusOnMount
                />
              ),
            },
          ],
        },
        {
          id: 'DataAssetLib',
          title: getConceptDefinitionNameOrEntity(store, DataAsset),
          lines: [
            {
              id: 'information',
              render: (
                <>
                  <Typo variant={TypoVariant.small}>{i18n`You can either select a existing data asset or let us create a new one`}</Typo>
                  <Typo variant={TypoVariant.small}>{i18n`Modification of these fields will update every use of this resource in other references`}</Typo>
                </>
              ),
            },
            {
              id: 'data-asset',
              title: getConceptDefinitionNameOrEntity(store, DataAsset),
              render: (
                <SearchAndSelect
                  computeOptions={getDataAssetOptions}
                  selectedOption={selectedDataAssetOption}
                  onSelect={onDataAssetUpdate}
                  getInlineCreation={() => ({ type: 'inline', onCreate: onDataAssetCreate })}
                  readOnly={resourceReadOnly}
                  searchOptions={getSearchChipOptions(store, DataAsset)}
                  placeholder={i18n`Create`}
                />
              ),
            },
            {
              id: 'data-asset-type',
              title: i18n`Type`,
              render: (
                <SearchAndSelect
                  clearable
                  computeOptions={getDataAssetTypeOptions}
                  selectedOption={dataAssetType ? getChipOptions(store, dataAssetType) : undefined}
                  onSelect={onDataAssetTypeUpdate}
                  readOnly={resourceReadOnly}
                  searchOptions={{
                    searchKeys: [DataAssetType_Name],
                    extractValue: ({ object }, searchKey) => {
                      const value = object[searchKey];
                      return typeof value === 'string' ? value : undefined;
                    },
                  }}
                />
              ),
            },
          ],
        },
        {
          id: 'ResourceLib',
          title: i18n`Resource definition`,
          lines: [
            {
              id: 'information',
              render: (<Typo variant={TypoVariant.small}>{i18n`Modification of these fields will update every use of this resource in other references`}</Typo>),
            },
            {
              id: 'resource-type',
              title: i18n`Type`,
              render: (
                <SearchAndSelect
                  clearable
                  computeOptions={getResourceTypeOptions}
                  selectedOption={type ? getChipOptions(store, type) : undefined}
                  onSelect={onResourceTypeUpdate}
                  readOnly={resourceReadOnly}
                  searchOptions={{
                    searchKeys: [ResourceType_Name],
                    extractValue: ({ object }, searchKey) => {
                      const value = object[searchKey];
                      return typeof value === 'string' ? value : undefined;
                    },
                  }}
                />
              ),
            },
            ...resourceReadOnly ? [] : [imageLine],
          ],
        },
      ]}
      onOpenDropdown={onOpenDropdown}
      onCloseDropdown={onCloseDropdown}
    />
  );
};

export default ResourceInputField;
