import classnames from 'classnames';
import type { Property } from 'csstype';
import type { CSSProperties, FunctionComponent, ReactNode } from 'react';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import useSizeContext from '../../utils/useSizeContext';

const useStyles = makeStyles({
  title: {
    minHeight: '5.2rem',
    columnGap: spacingRem.splusplus,
  },
  subtitle: {
    minHeight: '3.4rem',
    columnGap: spacingRem.s,
  },
  tabs: {
    minHeight: '4.2rem',
    columnGap: spacingRem.s,
  },
  main: {
    minHeight: '3.2rem',
    columnGap: spacingRem.s,
  },
  small: {
    minHeight: '2.6rem',
    columnGap: spacingRem.s,
  },
  container: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    width: 'fit-content',
  },
  containerNotCentered: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    width: 'fit-content',
  },
  containerFullWidth: {
    width: 'unset',
    flexGrow: 1,
    gridTemplateColumns: 'auto 1fr',
  },
}, 'spacingLine');

interface SpacingLineProps {
  fullWidth?: boolean,
  notCentered?: boolean,
  children: ReactNode,
  customGridTemplateColumns?: Property.GridTemplateColumns,
  customColumnGap?: Property.GridColumnGap,
}

const SpacingLine: FunctionComponent<SpacingLineProps> = ({ fullWidth = false, notCentered = false, children, customGridTemplateColumns, customColumnGap }) => {
  const classes = useStyles();

  const { sizeVariant } = useSizeContext();

  const style: CSSProperties = {};
  if (customGridTemplateColumns !== undefined) {
    style.gridTemplateColumns = customGridTemplateColumns;
  }
  if (customColumnGap !== undefined) {
    style.columnGap = customColumnGap;
  }

  return (
    <div
      className={classnames({
        [classes[sizeVariant]]: true,
        [classes.container]: !notCentered,
        [classes.containerFullWidth]: fullWidth,
        [classes.containerNotCentered]: notCentered,
      })}
      style={style}
    >
      {children}
    </div>
  );
};

export default SpacingLine;
