import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { DimensionsMapping, TimeseriesFieldValue, TimeseriesUpdate } from 'yooi-modules/modules/conceptModule';
import { canWrite, getTimeseriesFieldUtilsHandler, isEmbeddedAsIntegrationOnly, ParsedDimensionType, parseDimensionMapping } from 'yooi-modules/modules/conceptModule';
import type { TimeseriesValue } from 'yooi-store';
import { DATE_MAX_TIMESTAMP, DATE_MIN_TIMESTAMP, formatForStorage, getDateFromString, isFiniteNumber, joinObjects, newError, PeriodicityType } from 'yooi-utils';
import { ButtonVariant } from '../../../../components/atoms/Button';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton from '../../../../components/atoms/IconOnlyButton';
import Typo from '../../../../components/atoms/Typo';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../../../components/molecules/Table';
import TableCell from '../../../../components/molecules/TableCell';
import TableLine from '../../../../components/molecules/TableLine';
import BlockContent from '../../../../components/templates/BlockContent';
import DataTable from '../../../../components/templates/DataTable';
import useAcl from '../../../../store/useAcl';
import useStore from '../../../../store/useStore';
import { reportClientTrace } from '../../../../utils/clientReporterUtils';
import { getClipboardDateFormat, isClipboardDateAcceptedForPeriodicity } from '../../../../utils/dateUtilsFront';
import i18n from '../../../../utils/i18n';
import { notifyWarning } from '../../../../utils/notify';
import useNewLineFocus from '../../../../utils/useNewLineFocus';
import { formatFieldResolutionErrorForUser } from '../../errorUtils';
import StoreDateInput from '../../input/StoreDateInput';
import useFilterAndSort, { buildNumberColumnComparatorHandler } from '../../useFilterAndSort';
import { getFieldHandler } from '../FieldLibrary';

const LINE_SEPARATOR_REGEX = /[\r\n]+/;
const COLUMN_SEPARATOR = '\t';

interface TimeseriesTableProps {
  dimensionsMapping: DimensionsMapping,
  fieldId: string,
  readOnly: boolean,
}

const TimeseriesTable: FunctionComponent<TimeseriesTableProps> = ({ dimensionsMapping, fieldId, readOnly = false }) => {
  const store = useStore();
  const aclHandler = useAcl();

  const parsedDimension = parseDimensionMapping(dimensionsMapping);

  const fieldHandler = getFieldHandler(store, fieldId);
  const fieldUtilsHandler = getTimeseriesFieldUtilsHandler(store, fieldId);
  const configuration = fieldUtilsHandler.resolveConfigurationWithOverride(dimensionsMapping);
  const periodicity = configuration.defaultPeriod ?? PeriodicityType.day;

  // Casting updateValue here for now as a quicker solution than refactoring a lot of types for timeseries
  const updateValue = fieldUtilsHandler.updateValue as (dimensionsMapping: DimensionsMapping, value: TimeseriesUpdate<number | string>) => void;

  const [creationTime, setCreationTime] = useState<number>();
  const [creationValue, setCreationValue] = useState<number | string | null>();
  const [showLine, setShowLine] = useState(false);
  const [newLineFocus] = useNewLineFocus();

  const valueResolution = fieldUtilsHandler.getValueResolution(dimensionsMapping);

  const { value: values, error } = valueResolution;
  const timeseries: (TimeseriesValue<number | string | null | undefined> & { key: string })[] | undefined = values
    ?.map((value) => (joinObjects(value, { key: value.time.toString() })));

  const { generateList, doSort, sortCriteria, forceFollowingIds } = useFilterAndSort(
    fieldId,
    timeseries ?? [],
    undefined,
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case 'time':
            return buildNumberColumnComparatorHandler(key, direction);
          case 'value': {
            const comparatorHandler = fieldHandler?.getComparatorHandler?.(direction);
            return comparatorHandler ? {
              comparator: comparatorHandler.comparator,
              extractValue: ({ time }) => comparatorHandler.extractValue(dimensionsMapping, time),
            } : undefined;
          }
          default:
            return undefined;
        }
      },
      initial: { key: 'time', direction: TableSortDirection.asc },
    },
    undefined,
    [Object.entries(dimensionsMapping).map(([key, value]) => `${key}_${value}`).join('|')]
  );

  if (!fieldId || !fieldHandler || !fieldHandler.renderField || !fieldHandler.input) {
    return null;
  }

  const isReadOnly = readOnly
    || configuration.integrationOnly
    || (parsedDimension.type === ParsedDimensionType.MonoDimensional && isEmbeddedAsIntegrationOnly(store.getObject(parsedDimension.objectId)))
    || configuration.formula !== undefined
    || !canWrite(dimensionsMapping, aclHandler.canWriteObject);

  const handleReset = (reason: boolean) => {
    if (reason) {
      setShowLine(false);
      setCreationTime(undefined);
      setCreationValue(undefined);
    }
  };

  const getClipboardLineValidityAndFormat = (line: string): { format: string, isValid: boolean } => {
    const parsedLine = line.split(COLUMN_SEPARATOR);
    if (parsedLine.length < 2) {
      return { isValid: false, format: '' };
    }
    const [date] = parsedLine;
    const isAcceptableDateForPeriodicity = isClipboardDateAcceptedForPeriodicity(date, periodicity);
    return isAcceptableDateForPeriodicity ? { format: getClipboardDateFormat(date) as string, isValid: true } : { isValid: false, format: '' };
  };

  const handlePaste = (clipboardData: string) => {
    const lines = clipboardData.split(LINE_SEPARATOR_REGEX);
    let encounteredInvalidValues = false;

    lines.forEach((line) => {
      if (line === '') {
        return;
      }
      const { isValid, format } = getClipboardLineValidityAndFormat(line);
      if (isValid) {
        const [date, value] = line.split(COLUMN_SEPARATOR);

        const parsedDate = getDateFromString(date, format);
        const time = formatForStorage(parsedDate);
        if (time !== undefined) {
          updateValue(dimensionsMapping, { type: 'value', time, value: isFiniteNumber(value) ? parseFloat(value) : value });
        }
      } else {
        encounteredInvalidValues = true;
      }
    });

    if (encounteredInvalidValues) {
      notifyWarning(i18n`Some values could not be applied`);
      reportClientTrace(newError('Encountered invalid clipboard data for timeseries'));
    }

    handleReset(true);
  };

  if (error) {
    const displayedError = valueResolution.error ? formatFieldResolutionErrorForUser(store, valueResolution.error, fieldId) : undefined;
    return (
      <BlockContent padded>
        <SpacingLine>
          <Icon name={IconName.dangerous} colorVariant={IconColorVariant.error} />
          <Typo maxLine={1}>{!timeseries ? i18n`Invalid configuration` : displayedError}</Typo>
        </SpacingLine>
      </BlockContent>
    );
  }

  return (
    <DataTable
      doSort={doSort}
      sortCriteria={sortCriteria}
      loading={!timeseries}
      globalActions={!isReadOnly ? [
        {
          key: 'delete',
          name: i18n`Delete all values`,
          icon: IconName.delete,
          onClick: () => {
            updateValue(dimensionsMapping, { type: 'truncate', from: DATE_MIN_TIMESTAMP, to: DATE_MAX_TIMESTAMP });
          },
        },
      ] : []}
      linesActions={(line) => (!isReadOnly ? [
        {
          key: 'delete',
          name: i18n`Delete value`,
          icon: IconName.delete,
          onClick: () => updateValue(dimensionsMapping, { type: 'delete', time: line.time }),
        },
      ] : [])}
      columnsDefinition={[
        {
          propertyId: 'time',
          name: i18n`Date`,
          sortable: true,
          width: 50,
          cellRender: ({ time, value }) => (
            <StoreDateInput
              readOnly={isReadOnly}
              defaultPeriodicity={periodicity}
              fixedPeriodicity
              initialValue={{ period: periodicity, date: time }}
              onSubmit={(newValue) => {
                if (newValue?.date) {
                  forceFollowingIds(time.toString(), newValue.date.toString());
                  updateValue(dimensionsMapping, { type: 'delete', time });
                  updateValue(dimensionsMapping, { type: 'value', time: newValue.date, value: value ?? null });
                }
              }}
            />
          ),
        },
        {
          propertyId: 'value',
          name: i18n`Value`,
          sortable: true,
          width: 50,
          cellRender: ({ time, value }) => (
            fieldHandler.renderField?.({
              dimensionsMapping,
              readOnly: isReadOnly,
              time,
              value: [{ time, value }],
            }) ?? null
          ),
        },
      ]}
      list={generateList().list}
      lineContext={parsedDimension.type === ParsedDimensionType.MonoDimensional ? ({ key }) => [parsedDimension.objectId, key] : undefined}
      newItemButtonVariant={ButtonVariant.tertiary}
      newItemTitle={i18n`Add`}
      newItemIcon={IconName.add}
      onNewItem={!isReadOnly ? () => setShowLine(true) : undefined}
      newLineFocus={newLineFocus.current}
      handleClickAway={handleReset}
      inlineCreation={{
        render: showLine ? (
          <TableLine>
            <TableCell>
              <StoreDateInput
                defaultPeriodicity={periodicity}
                initialValue={creationTime ? { period: periodicity, date: creationTime } : undefined}
                onSubmit={(newValue) => setCreationTime(newValue?.date)}
                onPaste={(e) => handlePaste(e.clipboardData.getData('text/plain'))}
                focusOnMount
                fixedPeriodicity
                error={(creationTime && timeseries?.some(({ time }) => time === creationTime)) ? i18n`An existing value is set on this date, you will be overriding it` : undefined}
              />
            </TableCell>
            <TableCell>
              {creationTime
                && (
                  fieldHandler.input.render({
                    value: [{ time: creationTime, value: creationValue }],
                    onSubmit: (newValue: TimeseriesFieldValue<number | string>) => {
                      const newCreationValue = newValue?.[0].value;
                      setCreationValue(newCreationValue);
                    },
                    readOnly: isReadOnly,
                    focusOnMount: true,
                    aclHandler,
                  })
                )}
            </TableCell>
            <TableCell action>
              <IconOnlyButton
                tooltip={i18n`Add`}
                iconName={IconName.check}
                onClick={() => {
                  if (creationTime && fieldHandler.input) {
                    fieldHandler.input.persistStateOnConcept(
                      dimensionsMapping,
                      [{ time: creationTime, value: creationValue }]
                    );
                    handleReset(true);
                  }
                }}
                disabled={!creationTime || creationValue === undefined || creationValue === null}
              />
            </TableCell>
          </TableLine>
        ) : null,
      }}
    />
  );
};

export default TimeseriesTable;
