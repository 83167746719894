import { ValidationStatus } from 'yooi-store';
import { CommonAsType } from '../../common/fields/commonPropertyType';
import type { BusinessRuleRegistration } from '../../common/types/TypeModuleDslType';
import { adminOnlyAcl } from '../../conceptModule';
import { Concept, ConceptDefinition, ConceptDefinition_FirstClassOnly, ConceptDefinition_IsCore } from '../../conceptModule/ids';
import { ResourceType } from '../../resourceModule/ids';
import { Class_Instances } from '../../typeModule/ids';
import {
  DataAsset_Type,
  DataAssetType,
  DataAssetType_DefaultResourceType,
  DataAssetType_IsDefaultType,
  DataAssetTypeResourceTypes,
  DataAssetTypeResourceTypes_Role_DataAssetType,
  DataAssetTypeResourceTypes_Role_ResourceType,
} from '../ids';
import { registerModel } from '../module';

const { type, association } = registerModel;

const applyDefaultDataAssetTypeToNewlyCreatedDataAsset: BusinessRuleRegistration = ({ getObject, getObjectOrNull }) => (_, { id, properties }) => {
  // Existing or undefined object ? Don't care
  if (!id || getObjectOrNull(id)) {
    return undefined;
  }

  const defaultType = getObject(DataAssetType)
    .navigateBack(Class_Instances)
    .find((dataAssetType) => dataAssetType[DataAssetType_IsDefaultType]);

  if (properties?.[DataAsset_Type] !== undefined || !defaultType) {
    return {
      rule: 'concept.dataAsset.types.applyDefaultDataAssetTypeToNewlyCreatedDataAsset',
      status: ValidationStatus.ACCEPTED,
    };
  } else {
    return {
      rule: 'concept.dataAsset.types.applyDefaultDataAssetTypeToNewlyCreatedDataAsset',
      status: ValidationStatus.ACCEPTED,
      generateSystemEvent: ({ updateObject }) => {
        updateObject(id, {
          [DataAsset_Type]: defaultType.id,
        });
      },
    };
  }
};

type({
  label: 'DataAsset',
  extends: Concept,
  instanceOf: ConceptDefinition,
  extraProperties: {
    [ConceptDefinition_IsCore]: true,
    [ConceptDefinition_FirstClassOnly]: true,
  },
  businessRules: [applyDefaultDataAssetTypeToNewlyCreatedDataAsset],
});

const defaultResourceTypeIsPartOfResourcesAssociation: BusinessRuleRegistration = ({ withAssociation }) => (_, { id, properties }) => {
  // Timeseries update or deletion, Don't care
  if (!properties) {
    return undefined;
  }
  const resourceTypeAssociation = withAssociation(DataAssetTypeResourceTypes)
    .withRole(DataAssetTypeResourceTypes_Role_DataAssetType, id[0])
    .withExternalRole(DataAssetTypeResourceTypes_Role_ResourceType)
    .list()
    .find((assoc) => assoc.role(DataAssetTypeResourceTypes_Role_ResourceType) === properties[DataAssetType_DefaultResourceType]);
  if (properties[DataAssetType_DefaultResourceType] && !resourceTypeAssociation) {
    return {
      rule: 'concept.dataAsset.types.defaultResourceTypeIsPartOfResourcesAssociation',
      status: ValidationStatus.REJECTED,
    };
  } else {
    return {
      rule: 'concept.dataAsset.types.defaultResourceTypeIsPartOfResourcesAssociation',
      status: ValidationStatus.ACCEPTED,
    };
  }
};

const onlyOneDataAssetTypeIsDefault: BusinessRuleRegistration = ({ getObject }) => (_, { id, properties }) => {
  if (!properties || properties[DataAssetType_IsDefaultType] === null) {
    return undefined;
  }
  const defaultType = getObject(DataAssetType)
    .navigateBack(Class_Instances)
    .find((dataAssetType) => dataAssetType[DataAssetType_IsDefaultType]);
  if (!defaultType || defaultType?.id === id[0]) {
    return {
      rule: 'concept.dataAsset.types.onlyOneTypeIsDefault',
      status: ValidationStatus.ACCEPTED,
    };
  } else {
    return {
      rule: 'concept.dataAsset.types.onlyOneTypeIsDefault',
      status: ValidationStatus.ACCEPTED,
      generateSystemEvent: ({ updateObject }) => {
        updateObject(defaultType.id, {
          [DataAssetType_IsDefaultType]: null,
        });
      },
    };
  }
};

type({
  label: 'DataAssetType',
  accessControlList: {
    READ: () => () => ({ rule: 'dataAssetType.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'dataAssetType', 'WRITE'),
    DELETE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'dataAssetType', 'DELETE'),
  },
})
  .property({ label: 'DataAssetType_Name', as: CommonAsType.string })
  .property({ label: 'DataAssetType_Description', as: CommonAsType.string })
  .property({ label: 'DataAssetType_DefaultResourceType', as: CommonAsType.string, businessRules: [defaultResourceTypeIsPartOfResourcesAssociation] })
  .property({ label: 'DataAssetType_IsDefaultType', as: CommonAsType.boolean, businessRules: [onlyOneDataAssetTypeIsDefault] });

association({
  label: 'DataAssetTypeResourceTypes',
  roles: [{ label: 'DataAssetType', targetTypeId: DataAssetType }, { label: 'ResourceType', targetTypeId: ResourceType }],
  accessControlList: {
    READ: () => () => ({ rule: 'dataAssetTypeResourceTypes.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'dataAssetTypeResourceTypes', 'WRITE'),
    DELETE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'dataAssetTypeResourceTypes', 'DELETE'),
  },
});
