import type { ComponentProps, FunctionComponent, MouseEvent } from 'react';
import TextInputString from '../../../components/inputs/TextInputString';
import FormInput from './FormInput';

interface FormTextInputFieldProps extends Omit<ComponentProps<typeof TextInputString>, 'value' | 'onChange' | 'onChangeDebounced' | 'onSubmit' | 'onCancel'> {
  initialValue?: string,
  onSubmit?: (value: string | null) => void,
  onChange?: (value: string | null) => void,
  onChangeDebounced?: (value: string | null) => void,
  onClear?: (event: MouseEvent<HTMLButtonElement>) => void,
}

const FormTextInputField: FunctionComponent<FormTextInputFieldProps> = ({ initialValue, onSubmit, onChange, onChangeDebounced, ...props }) => (
  <FormInput initialValue={initialValue} onSubmit={onSubmit} onChange={onChange} onChangeDebounced={onChangeDebounced}>
    {(formInputProps) => (
      <TextInputString
        {...props}
        {...formInputProps}
      />
    )}
  </FormInput>
);

export default FormTextInputField;
