// This file is automatically updated by the generate-constants script. Do not update it manually.

export const IntegrationModuleId = '71672187-0d21-4dc5-915f-cbb6daa82618';
export const Integration = '6de7ab19-c7d1-4e69-b1de-34e26f9aded5';
export const Integration_Configuration = '441b3840-0eb7-4415-8fb1-a2388afeb00f';
export const Integration_ConfigurationError = 'e6dfae03-d812-4b4c-bd0b-b70f7b24dbe7';
export const Integration_Description = '9b7a161b-90bb-4abc-a58d-01fbba2b77e5';
export const Integration_Name = '71b7dc49-a821-4102-979a-cdb668e996e0';
export const Integration_Stakeholders = '0e52a461-ca97-4102-a5e4-2449cde5ac7d';
export const Integration_Template = 'e449828f-f880-4410-b876-56013c0ea4ea';
export const Template_Integrations = Integration_Template;
