import { v4 as uuid } from 'uuid';
import type { ObjectStore } from 'yooi-store';
import { compareProperty, compareRank, extractAndCompareValue, joinObjects, ranker } from 'yooi-utils';
import {
  Block,
  Block_ConceptDefinition,
  Block_Name,
  Block_Parent,
  Block_Rank,
  Block_Type,
  ConceptDefinitionLibraryTable,
  ConceptDefinitionLibraryTable_Rank,
  ConceptDefinitionLibraryTable_Role_ConceptDefinition,
  ConceptDefinitionLibraryTable_Role_Field,
  FieldBlockDisplay,
  FieldBlockDisplay_Block,
  FieldBlockDisplay_FieldDisplayConfiguration,
  FieldBlockDisplay_FieldPath,
  FieldBlockDisplay_Rank,
} from '../conceptLayoutModule/ids';
import type { ConceptDefinitionLibraryTableRaw, ConceptDefinitionLibraryTableStoreObject } from '../conceptLayoutModule/model';
import { BLOCK_PARAMETER_CURRENT, InstanceReferenceType, PathStepType } from '../conceptModule';
import { AssociationField, EmbeddingField, EmbeddingField_ToType, RelationMultipleField } from '../conceptModule/ids';
import type { CardsViewStoredDefinition, ChipViewStoredDefinition, TableViewStoredDefinition } from '../dashboardModule';
import { ViewType } from '../dashboardModule';
import { Reference } from '../referenceModule/ids';
import { Resource } from '../resourceModule/ids';
import { isInstanceOf } from '../typeModule';
import { Class_Instances, Instance_Of } from '../typeModule/ids';

const injectBlockInPage = (
  { getObject, createObject }: ObjectStore,
  {
    name,
    type,
    conceptDefinitionId,
    parent,
    rank,
    fieldDefinitions,
  }: { name: string, type: string, conceptDefinitionId: string, parent?: string, rank: string, fieldDefinitions?: string[] }
): string => {
  const blockId = createObject({
    [Instance_Of]: Block,
    [Block_Name]: name,
    [Block_Rank]: rank,
    [Block_Type]: type,
    [Block_Parent]: parent,
    [Block_ConceptDefinition]: conceptDefinitionId,
  });
  const generateNextPageRank = ranker.createNextRankGenerator();
  fieldDefinitions?.forEach((fieldInstanceId) => {
    const field = getObject(fieldInstanceId);
    let fieldDisplayConfiguration;
    if ([Reference, Resource].includes(field[EmbeddingField_ToType] as string)) {
      fieldDisplayConfiguration = { viewDefinitions: [{ id: uuid(), type: ViewType.Cards } satisfies CardsViewStoredDefinition] };
    } else if (isInstanceOf(field, EmbeddingField)) {
      fieldDisplayConfiguration = { viewDefinitions: [{ id: uuid(), type: ViewType.Table } satisfies TableViewStoredDefinition] };
    } else if (isInstanceOf(field, RelationMultipleField) || isInstanceOf(field, AssociationField)) {
      fieldDisplayConfiguration = { viewDefinitions: [{ id: uuid(), type: ViewType.Chip } satisfies ChipViewStoredDefinition] };
    }
    createObject({
      [Instance_Of]: FieldBlockDisplay,
      [FieldBlockDisplay_Block]: blockId,
      [FieldBlockDisplay_FieldPath]: [
        { type: PathStepType.dimension, conceptDefinitionId },
        { type: PathStepType.mapping, mapping: { id: BLOCK_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
        { type: PathStepType.field, fieldId: fieldInstanceId }],
      [FieldBlockDisplay_Rank]: generateNextPageRank(),
      [FieldBlockDisplay_FieldDisplayConfiguration]: fieldDisplayConfiguration,
    });
  });
  return blockId;
};

export const injectPage = (
  objectStore: ObjectStore,
  conceptDefinitionId: string,
  blockDefinitions: { name: string, type: string, fieldDefinitions?: string[], sections?: { name: string, type: string, fieldDefinitions: string[] }[] }[]
): void => {
  const lastBlockRank = objectStore.getObject(Block)
    .navigateBack(Class_Instances)
    .filter((block) => block[Block_ConceptDefinition] === conceptDefinitionId)
    .sort(compareProperty(Block_Rank, compareRank))
    .reverse()[0]?.[Block_Rank] as string | undefined;
  const generateNextTabRank = ranker.createNextRankGenerator(lastBlockRank);
  blockDefinitions.forEach(({ name, type, fieldDefinitions, sections }) => {
    const blockId = injectBlockInPage(objectStore, {
      name,
      rank: generateNextTabRank(),
      conceptDefinitionId,
      type,
      fieldDefinitions,
    });
    const generateNextSectionRank = ranker.createNextRankGenerator();
    sections?.forEach((section) => {
      injectBlockInPage(objectStore, joinObjects(section, { conceptDefinitionId, rank: generateNextSectionRank(), parent: blockId }));
    });
  });
};

export const injectLibraryTableColumns = ({ withAssociation }: ObjectStore, conceptDefinitionId: string, dataGridDefinition: string[]): void => {
  const lastRank = withAssociation(ConceptDefinitionLibraryTable)
    .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
    .list<ConceptDefinitionLibraryTableStoreObject>()
    .sort(extractAndCompareValue(({ object }) => object[ConceptDefinitionLibraryTable_Rank], compareRank))
    .at(0)
    ?.object
    ?.[ConceptDefinitionLibraryTable_Rank];

  const rankGenerator = ranker.createNextRankGenerator(lastRank);
  dataGridDefinition.forEach((fieldInstanceId) => {
    withAssociation(ConceptDefinitionLibraryTable)
      .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
      .withRole(ConceptDefinitionLibraryTable_Role_Field, fieldInstanceId)
      .updateObject<ConceptDefinitionLibraryTableRaw>({
        [ConceptDefinitionLibraryTable_Rank]: rankGenerator(),
      });
  });
};
