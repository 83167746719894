import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import { isFiniteNumber, newError, textType } from 'yooi-utils';
import { CommonAsType } from '../../../common/fields/commonPropertyType';
import type { GetDslFieldHandler } from '../../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../../common/typeErrorUtils';
import type { BusinessRuleRegistration } from '../../../common/types/TypeModuleDslType';
import { isInstanceOf } from '../../../typeModule';
import { Instance_Of } from '../../../typeModule/ids';
import { preventComputedFieldUpdate, sanitizeFilterValue, validateFieldIdAsProperty } from '../../common/commonFieldUtils';
import {
  Concept,
  Concept_FunctionalId,
  ConceptDefinition,
  Field,
  FieldDimension_Field,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  IdField as IdFieldId,
  IdFieldAssociation,
  IdFieldAssociation_NextId,
  IdFieldAssociation_Role_ConceptDefinition,
  IdFieldAssociation_Role_Field,
} from '../../ids';
import type { FieldDimensionTypesStoreObject, FieldStoreObject } from '../../model';
import { registerField } from '../../module';
import type { FilterValueRaw } from '../../moduleType';
import { FilterValueType } from '../../moduleType';
import type { DimensionsMapping, FieldFilterCondition, FieldFilterConditions, ResolutionStack } from '../../utils';
import { adminOnlyAcl, isFilterValueRaw, isValueResolutionOfType, ParsedDimensionType, parseDimensionMapping, resolveFieldValue } from '../../utils';
import type { IdField } from '../types';
import { buildFunctionalIdFormatter } from './utils';

const applyFunctionalId: BusinessRuleRegistration = ({ getObjectOrNull, withAssociation }) => (_, { id, properties }) => {
  if (!properties) {
    // Deleting instance ? Abort!
    return undefined;
  }

  const concept = getObjectOrNull(id);
  const conceptDefinitionId = concept?.[Instance_Of] as string | undefined ?? properties[Instance_Of] as string | undefined;

  if (!conceptDefinitionId) {
    // No type ? Abort!
    return undefined;
  }

  const idFields = withAssociation(FieldDimensionTypes)
    .withRole(FieldDimensionTypes_Role_ConceptDefinition, conceptDefinitionId)
    .list<FieldDimensionTypesStoreObject>()
    .map((fieldDimensionType) => fieldDimensionType.navigateRole(FieldDimensionTypes_Role_FieldDimension).navigate<FieldStoreObject>(FieldDimension_Field))
    .filter((f) => isInstanceOf(f, IdFieldId));
  if (idFields.length === 0) {
    // Not associated to any id fields ? Abort!
    return undefined;
  }

  if (idFields.some(({ id: fieldId }) => !concept?.[fieldId])) {
    // Some id fields are not filled
    return {
      rule: 'fieldModule.functionalId',
      status: ValidationStatus.ACCEPTED,
      generateSystemEvent: ({ updateObject, withAssociation: updateWithAssociation }) => {
        idFields.forEach(({ id: fieldId }) => {
          if (!concept?.[fieldId]) {
            const nextId = Number.parseInt(
              updateWithAssociation(IdFieldAssociation)
                .withRole(IdFieldAssociation_Role_ConceptDefinition, conceptDefinitionId)
                .withRole(IdFieldAssociation_Role_Field, fieldId)
                .getObjectOrNull()?.[IdFieldAssociation_NextId] as string | undefined ?? '1',
              10
            );
            updateObject(id, { [Concept_FunctionalId]: nextId.toString() });
            updateWithAssociation(IdFieldAssociation)
              .withRole(IdFieldAssociation_Role_ConceptDefinition, conceptDefinitionId)
              .withRole(IdFieldAssociation_Role_Field, fieldId)
              .updateObject({ [IdFieldAssociation_NextId]: (nextId + 1).toString() });
          }
        });
      },
    };
  }
  return undefined;
};

const getValueResolution = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack) => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
  if (isValueResolutionOfType(valueResolution, (value): value is string | undefined => value === undefined || typeof value === 'string')) {
    return valueResolution;
  } else {
    return {
      value: undefined,
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['string', 'undefined'], typeof valueResolution.value),
      getDisplayValue: () => undefined,
      isTimeseries: false,
    };
  }
};

interface IdFieldFilterConditions extends FieldFilterConditions<string> {
  CONTAINS: FieldFilterCondition<string, string>,
  DOES_NOT_CONTAIN: FieldFilterCondition<string, string>,
  EQUALS: FieldFilterCondition<number | undefined, string>,
  NOT_EQUALS: FieldFilterCondition<number | undefined, string>,
  GREATER: FieldFilterCondition<number | undefined, string>,
  GREATER_OR_EQUALS: FieldFilterCondition<number | undefined, string>,
  LOWER: FieldFilterCondition<number | undefined, string>,
  LOWER_OR_EQUALS: FieldFilterCondition<number | undefined, string>,
}

const isTextFilterApplicable = (value: FilterValueRaw<string>) => isFilterValueRaw(value);
const isNumberFilterApplicable = (value: FilterValueRaw<number | undefined>) => isFilterValueRaw(value);
const sanitizeTextValue = (value: unknown) => (value ?? { type: FilterValueType.raw, raw: undefined }) as FilterValueRaw<string>;
const sanitizeNumberValue = (value: unknown) => (value ?? { type: FilterValueType.raw, raw: undefined }) as FilterValueRaw<number>;

type IdFieldHandler = GetDslFieldHandler<
  IdField,
  string | undefined,
  undefined,
  string | undefined,
  string | undefined,
  string | undefined,
  IdFieldFilterConditions,
  undefined,
  undefined,
  undefined
>;

export const idFieldHandler: IdFieldHandler = registerField({
  model: {
    label: 'IdField',
    title: 'ID',
    withApiAlias: true,
    asPropertyBusinessRules: [
      validateFieldIdAsProperty('idField'),
      preventComputedFieldUpdate('idField'),
    ],
    extraModel: ({ association, registerCustomBusinessRules }) => {
      association({
        label: 'IdFieldAssociation',
        roles: [{ label: 'Field', targetTypeId: Field }, { label: 'ConceptDefinition', targetTypeId: ConceptDefinition }],
        accessControlList: {
          READ: () => () => ({ rule: 'idFieldAssociation.read.allow', status: ValidationStatus.ACCEPTED }),
          WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'idFieldAssociation', 'WRITE'),
          DELETE: () => (_, objectId) => ({ rule: 'idFieldAssociation.delete.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: objectId }),
        },
      })
        .property({ label: 'IdFieldAssociation_NextId', as: CommonAsType.string });

      registerCustomBusinessRules((objectStore, { onObject }) => {
        onObject(Concept).validate(applyFunctionalId(objectStore));
      });
    },
  },
  handler: (objectStore, fieldId) => {
    const getValueWithoutFormula = (dimensionsMapping: DimensionsMapping) => {
      if (Object.values(dimensionsMapping).length !== 1) {
        return undefined;
      }
      const objectId = Object.values(dimensionsMapping)[0] as string;
      const instance = objectStore.getObject(objectId);
      const modelTypeId = objectStore.getObject(objectId)[Instance_Of] as string;
      if (!instance[fieldId]) {
        return undefined;
      }
      return buildFunctionalIdFormatter(objectStore, modelTypeId, fieldId)(instance[fieldId] as string);
    };

    const getStoreValue = (dimensionsMapping: DimensionsMapping): string | undefined => {
      const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
      if (parsedDimensionMapping.type === ParsedDimensionType.MonoDimensional) {
        return objectStore.getObject(parsedDimensionMapping.objectId)[fieldId] as string | undefined;
      } else {
        return undefined;
      }
    };

    return {
      describe: () => ({ hasData: true, returnType: textType, timeseriesMode: 'none' }),
      restApi: {
        returnTypeSchema: { type: 'string', nullable: true },
        formatValue: (value) => value ?? undefined,
      },
      getStoreValue,
      getValueWithoutFormula,
      getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
      updateValue: () => {
        throw newError('updateValue not supported');
      },
      isEmpty: (dimensionsMapping) => !getValueResolution(objectStore, fieldId, dimensionsMapping).value,
      isSaneValue: () => ({ isValid: true }),
      getValueAsText: getValueWithoutFormula,
      getExportColumnHeaders: (configuration, fieldLabel) => ({
        columnsNumber: 1,
        getHeaders: () => [{ format: 'string', value: fieldLabel }],
        getColumnConfiguration: () => configuration,
      }),
      getExportValue: (dimensionsMapping) => ({ format: 'string', value: getValueResolution(objectStore, fieldId, dimensionsMapping).value }),
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueWithoutFormula(dimensionsMapping) ?? '';
          } else {
            return undefined;
          }
        },
      }),
      resolvePathStepConfiguration: () => ({
        hasData: true,
        timeseriesMode: 'none',
        getValueResolutionType: () => textType,
        resolveValue: (dimensionsMapping, _, resolutionStack) => {
          const { value, error } = getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack);
          if (error) {
            throw error;
          } else {
            return value;
          }
        },
      }),
      filterConditions: {
        CONTAINS: {
          isFilterApplicable: isTextFilterApplicable,
          sanitizeValue: sanitizeTextValue,
          filterFunction: (leftValue, rightValue) => sanitizeFilterValue(leftValue).includes(sanitizeFilterValue(rightValue)),
        },
        DOES_NOT_CONTAIN: {
          isFilterApplicable: isTextFilterApplicable,
          sanitizeValue: sanitizeTextValue,
          filterFunction: (leftValue, rightValue) => !sanitizeFilterValue(leftValue).includes(sanitizeFilterValue(rightValue)),
        },
        EQUALS: {
          isFilterApplicable: isNumberFilterApplicable,
          sanitizeValue: sanitizeNumberValue,
          filterFunction: (leftValue, rightValue) => {
            if (!isFiniteNumber(rightValue)) {
              return false;
            }
            const numberString = leftValue ? leftValue.split('-').at(-1) : undefined;
            const parsedLeftValue = numberString ? parseInt(numberString, 10) : undefined;
            if (!isFiniteNumber(parsedLeftValue)) {
              return false;
            }
            return parsedLeftValue === rightValue;
          },
        },
        NOT_EQUALS: {
          isFilterApplicable: isNumberFilterApplicable,
          sanitizeValue: sanitizeNumberValue,
          filterFunction: (leftValue, rightValue) => {
            if (!isFiniteNumber(rightValue)) {
              return false;
            }
            const numberString = leftValue ? leftValue.split('-').at(-1) : undefined;
            const parsedLeftValue = numberString ? parseInt(numberString, 10) : undefined;
            if (!isFiniteNumber(parsedLeftValue)) {
              return false;
            }
            return parsedLeftValue !== rightValue;
          },
        },
        GREATER: {
          sanitizeValue: sanitizeNumberValue,
          isFilterApplicable: isNumberFilterApplicable,
          filterFunction: (leftValue, rightValue) => {
            if (!isFiniteNumber(rightValue)) {
              return false;
            }
            const numberString = leftValue ? leftValue.split('-').at(-1) : undefined;
            const parsedLeftValue = numberString ? parseInt(numberString, 10) : undefined;
            if (!isFiniteNumber(parsedLeftValue)) {
              return false;
            }
            return parsedLeftValue > Number(rightValue);
          },
        },
        GREATER_OR_EQUALS: {
          sanitizeValue: sanitizeNumberValue,
          isFilterApplicable: isNumberFilterApplicable,
          filterFunction: (leftValue, rightValue) => {
            if (!isFiniteNumber(rightValue)) {
              return false;
            }
            const numberString = leftValue ? leftValue.split('-').at(-1) : undefined;
            const parsedLeftValue = numberString ? parseInt(numberString, 10) : undefined;
            if (!isFiniteNumber(parsedLeftValue)) {
              return false;
            }
            return parsedLeftValue >= Number(rightValue);
          },
        },
        LOWER: {
          sanitizeValue: sanitizeNumberValue,
          isFilterApplicable: isNumberFilterApplicable,
          filterFunction: (leftValue, rightValue) => {
            if (!isFiniteNumber(rightValue)) {
              return false;
            }
            const numberString = leftValue ? leftValue.split('-').at(-1) : undefined;
            const parsedLeftValue = numberString ? parseInt(numberString, 10) : undefined;
            if (!isFiniteNumber(parsedLeftValue)) {
              return false;
            }
            return parsedLeftValue < Number(rightValue);
          },
        },
        LOWER_OR_EQUALS: {
          sanitizeValue: sanitizeNumberValue,
          isFilterApplicable: isNumberFilterApplicable,
          filterFunction: (leftValue, rightValue) => {
            if (!isFiniteNumber(rightValue)) {
              return false;
            }
            const numberString = leftValue ? leftValue.split('-').at(-1) : undefined;
            const parsedLeftValue = numberString ? parseInt(numberString, 10) : undefined;
            if (!isFiniteNumber(parsedLeftValue)) {
              return false;
            }
            return parsedLeftValue <= Number(rightValue);
          },
        },
      },
    };
  },
});
