import type { ConceptDefinitionStoreObject, MultipleParameterDefinition, PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { getConceptDefinitionValidFields, getFieldUtilsHandler, isMultipleRelationalType } from 'yooi-modules/modules/conceptModule';
import { ConceptDefinition_ChipBackgroundColor, Field_ApiAlias, Field_Title } from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareString, extractAndCompareValue, joinObjects } from 'yooi-utils';
import type { TreeNodeEntry } from '../../../components/molecules/Tree';
import type { FrontObjectStore } from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { sanitizeSearchValue } from '../../../utils/searchUtils';
import { formatOrUndef } from '../../../utils/stringUtils';
import { chipDefaultBackgroundColor } from '../../_global/conceptDisplayUtils';
import { getOption } from '../../_global/modelTypeUtils';
import { ReturnedTypes } from '../../_global/pathConfigurationHandler';
import { createAutomationPathConfigurationHandler } from './automationUtils';

const getAutomationChipOption = (
  store: FrontObjectStore,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
  instanceId: string,
  idSuffix: string,
  nodeName: string,
  tooltip: string
) => {
  const options = getOption(store, instanceId, parameterDefinitions);
  if (options) {
    return joinObjects(options, { id: `${options?.id}_option_${idSuffix}`, label: nodeName ?? options.label, key: `${options?.id}_option_${idSuffix}`, tooltip });
  } else {
    return undefined;
  }
};

const getPathWithPrefix = (valuePrefix: string | undefined, path: string, isParentMultiple: boolean) => {
  if (path.includes('-')) {
    const updatedPath = `["${path}"]`;
    return (valuePrefix ? `${valuePrefix}${isParentMultiple ? '[0]' : ''}${updatedPath}` : updatedPath);
  }
  return (valuePrefix ? `${valuePrefix}${isParentMultiple ? '[0]' : ''}.${path}` : path);
};

export const getAutomationNodes = (
  store: FrontObjectStore,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
  conceptDefinitionId: string,
  isParentMultiple = false,
  level = 0
): (() => TreeNodeEntry[]) | undefined => {
  const fieldsIds: string[] = getConceptDefinitionValidFields(store, conceptDefinitionId)
    .filter((field) => {
      if (!field[Field_ApiAlias]) {
        return false;
      }
      const { getValueProxy, getValueAsText } = getFieldUtilsHandler(store, field.id);
      return getValueProxy || getValueAsText;
    })
    .map((field) => field.id);
  if (fieldsIds.length > 0) {
    return () => fieldsIds.map((fieldId, index): TreeNodeEntry | null => {
      const field = store.getObjectOrNull(fieldId);
      if (field && field[Field_ApiAlias]) {
        const fieldAPIAlias = field[Field_ApiAlias] as string;
        let label = formatOrUndef(field[Field_Title] as string);
        const isMultiple = isMultipleRelationalType(field[Instance_Of] as string);
        if (isMultiple) {
          label += ' [ ]';
        }
        const returnTypeId = getFieldUtilsHandler(store, fieldId)?.getTargetType?.()?.id;
        const getPath = (parentPath?: string) => getPathWithPrefix(parentPath, fieldAPIAlias, isParentMultiple);
        return {
          label,
          getPath,
          generateGetChipOption: (parentPath) => () => getAutomationChipOption(
            store,
            parameterDefinitions,
            field.id,
            `${getPath(parentPath)}_${label}_${level}_${index}`,
            label,
            `API alias: ${fieldAPIAlias}`
          ),
          generateOnChipClick: (copyValue, parentPath) => () => {
            const path = getPath(parentPath);
            copyValue(`{{ ${path} }}`, i18n`Path copied to clipboard`);
            return `{{ ${path} }}`;
          },
          getKey: (parentPath) => `${getPath(parentPath)}_${label}_${level}_${index}`,
          matchFunction: (searchInput) => {
            if (!searchInput) {
              return true;
            } else {
              return sanitizeSearchValue(label).includes(searchInput);
            }
          },
          returnTypeId,
          getChildrenNode: returnTypeId ? getAutomationNodes(store, parameterDefinitions, returnTypeId, isMultiple, level + 1) : undefined,
        };
      } else {
        return null;
      }
    })
      .filter((n): n is TreeNodeEntry => Boolean(n))
      .sort(extractAndCompareValue((node) => node.label, compareString));
  } else {
    return undefined;
  }
};

export const getPathStepAsTreeNode = (
  store: FrontObjectStore,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
  label: string,
  path: PathStep[],
  key: string
): TreeNodeEntry => {
  const valuePathConfigurationHandler = createAutomationPathConfigurationHandler(store, parameterDefinitions);
  const returnedType = valuePathConfigurationHandler.getReturnedType(path);

  const targetConceptDefinition = returnedType.type === ReturnedTypes.concept ? store.getObjectOrNull<ConceptDefinitionStoreObject>(returnedType.conceptDefinitionId) : undefined;
  return ({
    generateGetChipOption: () => () => ({
      key,
      label,
      nodeName: label,
      color: targetConceptDefinition?.[ConceptDefinition_ChipBackgroundColor] ?? chipDefaultBackgroundColor,
      borderStyle: 'dashed',
    }),
    label,
    getPath: () => label,
    generateOnChipClick: (copyValue) => () => copyValue(`{{ ${label} }}`, i18n`Path copied to clipboard`),
    getKey: () => `${label}`,
    matchFunction: (searchInput) => {
      if (!searchInput) {
        return true;
      } else {
        return sanitizeSearchValue(label).includes(searchInput);
      }
    },
    returnTypeId: (returnedType.type === ReturnedTypes.concept) ? returnedType.conceptDefinitionId : undefined,
    getChildrenNode: (returnedType.type === ReturnedTypes.concept)
      ? getAutomationNodes(store, parameterDefinitions, returnedType.conceptDefinitionId, returnedType.isMultiple)
      : undefined,
  });
};
