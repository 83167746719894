import type { FunctionComponent } from 'react';
import type { ConceptStoreObject } from 'yooi-modules/modules/conceptModule';
import { getConceptDefinitionValidFields } from 'yooi-modules/modules/conceptModule';
import { AssociationField, RelationMultipleField, RelationSingleField, ReverseWorkflowField, WorkflowField } from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareString, extractAndCompareValue } from 'yooi-utils';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import { getFieldLabel } from '../../_global/fieldUtils';
import FieldRenderer from '../_global/FieldRenderer';

const ecosystemFieldTypeIds: readonly string[] = [
  AssociationField,
  RelationMultipleField,
  RelationSingleField,
  ReverseWorkflowField,
  WorkflowField,
];

interface EcosystemProps {
  conceptId: string,
  readOnly: boolean,
}

const Ecosystem: FunctionComponent<EcosystemProps> = ({ conceptId, readOnly }) => {
  const store = useStore();

  const concept = store.getObject<ConceptStoreObject>(conceptId);

  const fields = getConceptDefinitionValidFields(store, concept[Instance_Of])
    .filter((field) => ecosystemFieldTypeIds.includes(field[Instance_Of]))
    .sort(extractAndCompareValue((field) => getFieldLabel(store, field), compareString));

  return (
    <VerticalBlock>
      {fields.map((field) => <FieldRenderer key={field.id} fieldId={field.id} conceptId={conceptId} readOnly={readOnly} />)}
    </VerticalBlock>
  );
};

export default Ecosystem;
