import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type {
  ConceptCapabilityStoreObject,
  ConceptGroupCapabilityStoreObject,
  ConceptRoleStoreObject,
  GroupStoreObject,
  WorkflowFieldStoreObject,
  WorkflowStoreObject,
  WorkflowTransitionStoreObject,
} from 'yooi-modules/modules/conceptModule';
import { createConceptRole, getConceptDefinitionValidFields, getConceptUrl, getInstanceLabelOrUndefined } from 'yooi-modules/modules/conceptModule';
import type { TransitionRights } from 'yooi-modules/modules/conceptModule/fields/workflowField';
import {
  Concept_Name,
  ConceptCapability,
  ConceptCapability_Description,
  ConceptCapability_Name,
  ConceptCapabilityCreate,
  ConceptCapabilityEdit,
  ConceptCapabilityRead,
  ConceptDefinition_Roles,
  ConceptGroupCapability,
  ConceptGroupCapability_Role_ConceptCapability,
  ConceptGroupCapability_Role_ConceptDefinition,
  ConceptGroupCapability_Role_ConceptGroup,
  ConceptRole,
  ConceptRole_AssignedByDefault,
  ConceptRole_ConceptDefinition,
  ConceptRole_ForCollaboration,
  ConceptRoleCapability,
  ConceptRoleCapability_Role_ConceptCapability,
  ConceptRoleCapability_Role_ConceptRole,
  ConceptRoleGroupAssignation,
  ConceptRoleGroupAssignation_Role_ConceptRole,
  ConceptRoleGroupAssignation_Role_Group,
  ConceptRoleUserAssignation,
  ConceptRoleUserAssignation_Role_ConceptRole,
  Field_Title,
  Group,
  Workflow_Transitions,
  WorkflowField,
  WorkflowField_Workflow,
  WorkflowFieldTransition,
  WorkflowFieldTransition_Rights,
  WorkflowFieldTransition_Role_Transition,
  WorkflowFieldTransition_Role_WorkflowField,
  WorkflowTransition_Name,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreReadOnly } from 'yooi-store';
import type { RichText } from 'yooi-utils';
import { compareBoolean, compareProperty, compareString, comparing, filterNullOrUndefined, richTextToText } from 'yooi-utils';
import { ButtonVariant } from '../../../../../components/atoms/Button';
import Checkbox from '../../../../../components/atoms/Checkbox';
import Icon, { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton from '../../../../../components/atoms/IconOnlyButton';
import Typo from '../../../../../components/atoms/Typo';
import TextInputString from '../../../../../components/inputs/TextInputString';
import Banner, { BannerVariant } from '../../../../../components/molecules/Banner';
import Chip from '../../../../../components/molecules/Chip';
import InlineLink from '../../../../../components/molecules/InlineLink';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import SearchAndSelectMultiple from '../../../../../components/molecules/SearchAndSelectMultiple';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../../../../components/molecules/Table';
import TableCell, { TableCellAlign } from '../../../../../components/molecules/TableCell';
import TableInnerCellContainer, { TableInnerCellContainerVariants } from '../../../../../components/molecules/TableInnerCellContainer';
import TableLine from '../../../../../components/molecules/TableLine';
import BlockContent from '../../../../../components/templates/BlockContent';
import BlockTitle from '../../../../../components/templates/BlockTitle';
import DataTable from '../../../../../components/templates/DataTable';
import VerticalBlock from '../../../../../components/templates/VerticalBlock';
import useActivity from '../../../../../store/useActivity';
import useStore from '../../../../../store/useStore';
import useUpdateActivity from '../../../../../store/useUpdateActivity';
import { buildPadding, Spacing } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import useDeleteModal from '../../../../../utils/useDeleteModal';
import useNavigation from '../../../../../utils/useNavigation';
import useNewLineFocus, { useFocusNewLineNotify } from '../../../../../utils/useNewLineFocus';
import useTheme from '../../../../../utils/useTheme';
import { resolveConceptChipIcon, resolveConceptColorValue } from '../../../../_global/conceptDisplayUtils';
import { getFieldChip, getFieldColumnComparator } from '../../../../_global/fieldUtils';
import StoreRichTextInputField from '../../../../_global/input/StoreRichTextInputField';
import { defaultOptionComparator, getChipOptions, getSearchChipOptions } from '../../../../_global/modelTypeUtils';
import type { ChipOption } from '../../../../_global/modelTypeUtilsType';
import ActivityIndicator from '../../../../_global/multiplayer/ActivityIndicator';
import type { NavigationFilter } from '../../../../_global/navigationUtils';
import { getNavigationPayload } from '../../../../_global/navigationUtils';
import type { ComparatorHandler } from '../../../../_global/useFilterAndSort';
import useFilterAndSort, { buildStringColumnComparatorHandler } from '../../../../_global/useFilterAndSort';

const atLeastOneDefaultCreatorsHasEditCapability = (store: ObjectStoreReadOnly, conceptDefinitionId: string): boolean => {
  const defaultCreatorRoles = store.getObject(ConceptRole)
    .navigateBack(Class_Instances)
    .filter((role) => role[ConceptRole_AssignedByDefault]
      && role[ConceptRole_ConceptDefinition] === conceptDefinitionId);
  return defaultCreatorRoles
    .some((role) => store.withAssociation(ConceptRoleCapability)
      .withRole(ConceptRoleCapability_Role_ConceptCapability, ConceptCapabilityEdit)
      .withRole(ConceptRoleCapability_Role_ConceptRole, role.id)
      .getOrNull() != null);
};

export const getRolesProjectConfigurationStatus = (store: ObjectStoreReadOnly, conceptDefinitionId: string): { variant: BannerVariant, message: string } => {
  const roles = store.getObject(conceptDefinitionId).navigateBack(ConceptRole_ConceptDefinition);
  const nbRoles = roles.length;
  if (nbRoles === 0) {
    return { variant: BannerVariant.danger, message: i18n`You must have at least one role` };
  } else if (!roles.some((role) => role[ConceptRole_AssignedByDefault])) {
    return { variant: BannerVariant.danger, message: i18n`You must have at least one role for creator` };
  } else if (!atLeastOneDefaultCreatorsHasEditCapability(store, conceptDefinitionId)) {
    return { variant: BannerVariant.danger, message: i18n`The creator does not have edit capability` };
  } else if (nbRoles === 1) {
    return { variant: BannerVariant.warning, message: i18n`You should consider having more than one role` };
  } else if (roles.map((role) => role[Concept_Name]).some((roleName, index, roleNames) => roleNames.indexOf(roleName) !== index)) {
    return { variant: BannerVariant.warning, message: i18n`You have multiple roles with the same name` };
  } else if (!roles.some((role) => role[ConceptRole_ForCollaboration])) {
    return { variant: BannerVariant.warning, message: i18n`You should consider having a role for collaborators` };
  } else {
    const conceptCapabilitiesIds = new Set();
    store.withAssociation(ConceptRoleCapability)
      .list()
      .filter((assoc) => assoc.navigateRole(ConceptRoleCapability_Role_ConceptRole)[ConceptRole_ConceptDefinition] === conceptDefinitionId)
      .map((assoc) => assoc.role(ConceptRoleCapability_Role_ConceptCapability))
      .forEach((id) => conceptCapabilitiesIds.add(id));

    const unusedCapability = store.getObject(ConceptCapability)
      .navigateBack(Class_Instances)
      .some((capability) => ![ConceptCapabilityCreate, ConceptCapabilityRead].includes(capability.id) && Array.from(conceptCapabilitiesIds).indexOf(capability.id) === -1);
    if (unusedCapability) {
      return { variant: BannerVariant.warning, message: i18n`You have unused capabilities` };
    }
  }
  return { variant: BannerVariant.success, message: i18n`All good Folks !` };
};

export const getGroupConfigurationStatus = (store: ObjectStoreReadOnly, conceptDefinitionId: string): { variant: BannerVariant, message: string } => {
  const conceptCapabilitiesIds = new Set(
    store.withAssociation(ConceptGroupCapability)
      .withRole(ConceptGroupCapability_Role_ConceptDefinition, conceptDefinitionId)
      .list()
      .map((assoc) => assoc.role(ConceptGroupCapability_Role_ConceptCapability))
  );

  if (!conceptCapabilitiesIds.has(ConceptCapabilityCreate)) {
    return { variant: BannerVariant.danger, message: i18n`No one is able to create an instance` };
  } else {
    return { variant: BannerVariant.success, message: i18n`All good Folks !` };
  }
};

const useStyles = makeStyles({
  subtitlesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}, 'permissionTab');

interface PermissionTabProps {
  conceptDefinitionId: string,
}

const PermissionTab: FunctionComponent<PermissionTabProps> = ({ conceptDefinitionId }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const activity = useActivity();
  const updateActivity = useUpdateActivity();

  const navigation = useNavigation<NavigationFilter>();

  const [showGroupLine, setShowGroupLine] = useState(false);
  const [newGroup, setNewGroup] = useState<string>();
  const [newCapabilities, setNewCapabilities] = useState<string[]>([]);
  const [newLineFocus] = useNewLineFocus();
  const focusNewLineNotify = useFocusNewLineNotify();

  const conceptDefinition = store.getObject(conceptDefinitionId);

  const { generateList, doSort, sortCriteria, forceShowId } = useFilterAndSort(
    conceptDefinitionId,
    conceptDefinition.navigateBack<ConceptRoleStoreObject>(ConceptDefinition_Roles),
    undefined,
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case ConceptRole_AssignedByDefault:
          case ConceptRole_ForCollaboration:
            return {
              comparator: comparing(compareBoolean, direction === TableSortDirection.desc),
              extractValue: (item) => (item[key] ?? false),
            } satisfies ComparatorHandler<ConceptRoleStoreObject, boolean | undefined>;
          default:
            return getFieldColumnComparator(store)(key, direction);
        }
      },
      initial: { key: Concept_Name, direction: TableSortDirection.asc },
    }
  );

  const roleConfigurationStatus = getRolesProjectConfigurationStatus(store, conceptDefinitionId);
  const groupConfigurationStatus = getGroupConfigurationStatus(store, conceptDefinitionId);

  const [doDelete, deleteModal] = useDeleteModal<string>({
    doDelete: (id) => {
      store.deleteObject(id);
    },
    shouldConfirm: (id) => (
      store.withAssociation(ConceptRoleGroupAssignation)
        .withRole(ConceptRoleGroupAssignation_Role_ConceptRole, id)
        .withExternalRole(ConceptRoleGroupAssignation_Role_Group)
        .list()
        .length > 0
      || store.withAssociation(ConceptRoleUserAssignation)
        .withRole(ConceptRoleUserAssignation_Role_ConceptRole, id)
        .list()
        .length > 0
    ),
    getModalProps: () => ({
      title: i18n`Are you sure that you want to delete this role?`,
      content: (<Typo>{i18n`It will also remove all associated elements.`}</Typo>),
    }),
  });

  const { generateList: generateCapabilitiesSortedList, doSort: doSortCapabilitiesList, sortCriteria: capabilitiesSortCriteria } = useFilterAndSort(
    `${conceptDefinitionId}-manage-capabilities`,
    store.getObject(ConceptCapability).navigateBack<ConceptCapabilityStoreObject>(Class_Instances),
    undefined,
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case ConceptCapability_Name:
          case ConceptCapability_Description:
            return buildStringColumnComparatorHandler(key, direction);
          default:
            return undefined;
        }
      },
      initial: { key: ConceptCapability_Name, direction: TableSortDirection.asc },
    }
  );

  const { generateList: generateGroupSortedList, doSort: doSortGroupList, sortCriteria: groupSortCriteria } = useFilterAndSort(
    `${conceptDefinitionId}-manage-group`,
    store.withAssociation(ConceptGroupCapability)
      .withRole(ConceptGroupCapability_Role_ConceptDefinition, conceptDefinitionId)
      .list<ConceptGroupCapabilityStoreObject>()
      .map((assoc) => assoc.navigateRole<GroupStoreObject>(ConceptGroupCapability_Role_ConceptGroup))
      .filter(({ id }, index, arr) => arr.findIndex(({ id: groupId }) => groupId === id) === index),
    undefined,
    { getComparatorHandler: getFieldColumnComparator(store), initial: { key: Concept_Name, direction: TableSortDirection.asc } }
  );
  const { list: groupSortedList } = generateGroupSortedList();

  // Prevent to many association list during render of the table
  const capabilityRolesMap: Record<string, ChipOption[]> = {};
  const capabilityGroupsMap: Record<string, ChipOption[]> = {};
  store.withAssociation(ConceptRoleCapability)
    .list()
    .filter((assoc) => assoc.navigateRole(ConceptRoleCapability_Role_ConceptRole)[ConceptRole_ConceptDefinition] === conceptDefinitionId)
    .forEach((assoc) => {
      capabilityRolesMap[assoc.role(ConceptRoleCapability_Role_ConceptCapability)] = [
        ...capabilityRolesMap[assoc.role(ConceptRoleCapability_Role_ConceptCapability)] ?? [],
        getChipOptions(store, assoc.role(ConceptRoleCapability_Role_ConceptRole)),
      ]
        .filter(filterNullOrUndefined);
    });
  store.withAssociation(ConceptGroupCapability)
    .withRole(ConceptGroupCapability_Role_ConceptDefinition, conceptDefinitionId)
    .list()
    .forEach((assoc) => {
      capabilityGroupsMap[assoc.role(ConceptGroupCapability_Role_ConceptCapability)] = [
        ...capabilityGroupsMap[assoc.role(ConceptGroupCapability_Role_ConceptCapability)] ?? [],
        getChipOptions(store, assoc.role(ConceptGroupCapability_Role_ConceptGroup)),
      ]
        .filter(filterNullOrUndefined);
    });

  const computeConceptRolesList = () => conceptDefinition
    .navigateBack(ConceptRole_ConceptDefinition)
    .map((role) => getChipOptions(store, role.id))
    .filter(filterNullOrUndefined)
    .sort(defaultOptionComparator);

  const selectedRoles = (capabilityId: string) => capabilityRolesMap[capabilityId]
    ?.sort(compareProperty('label', compareString))
    .map((role) => getChipOptions(store, role.id))
    .filter(filterNullOrUndefined) ?? [];

  const computeConceptGroupList = () => store.getObject(Group)
    .navigateBack(Class_Instances)
    .map((group) => getChipOptions(store, group.id))
    .filter(filterNullOrUndefined)
    .sort(defaultOptionComparator);

  const selectedGroup = (capabilityId: string) => capabilityGroupsMap[capabilityId]
    ?.sort(compareProperty('label', compareString))
    .map((instance) => getChipOptions(store, instance.id))
    .filter(filterNullOrUndefined) ?? [];

  const handleReset = () => {
    setNewGroup(undefined);
    setNewCapabilities([]);
    setShowGroupLine(false);
  };

  const onCreate = () => {
    if (newGroup) {
      newCapabilities.forEach((capabilityId) => store.withAssociation(ConceptGroupCapability)
        .withRole(ConceptGroupCapability_Role_ConceptDefinition, conceptDefinitionId)
        .withRole(ConceptGroupCapability_Role_ConceptCapability, capabilityId)
        .withRole(ConceptGroupCapability_Role_ConceptGroup, newGroup)
        .updateObject({}));
    }
    handleReset();
  };

  const transitions = getConceptDefinitionValidFields(store, conceptDefinitionId)
    .filter((field): field is WorkflowFieldStoreObject => isInstanceOf(field, WorkflowField))
    .filter((workflowField) => workflowField[WorkflowField_Workflow])
    .flatMap((workflowField) => workflowField
      .navigate<WorkflowStoreObject>(WorkflowField_Workflow)
      .navigateBack<WorkflowTransitionStoreObject>(Workflow_Transitions)
      .map((transition) => {
        const associationId = store.withAssociation(WorkflowFieldTransition)
          .withRole(WorkflowFieldTransition_Role_WorkflowField, workflowField.id)
          .withRole(WorkflowFieldTransition_Role_Transition, transition.id)
          .getId();
        const { roles: transitionRoles = [], groups: transitionGroups = [] } = store
          .getObjectOrNull(associationId)?.[WorkflowFieldTransition_Rights] as TransitionRights | undefined ?? {};
        return {
          key: associationId.join('|'),
          associationId,
          transitionLabel: transition[WorkflowTransition_Name],
          workflowId: workflowField.id,
          workflowLabel: workflowField[Field_Title],
          roles: transitionRoles,
          groups: transitionGroups,
        };
      }));

  const { generateGroupedList: generateTransitionsGroupedList, doSort: doSortTransitionsList, sortCriteria: transitionsSortCriteria } = useFilterAndSort(
    `${conceptDefinitionId}-transition-capabilities`,
    transitions,
    undefined,
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case 'transitionLabel':
            return buildStringColumnComparatorHandler(key, direction);
          case 'workflowId':
            return {
              comparator: comparing(compareString, direction === TableSortDirection.desc),
              extractValue: (item) => item.workflowLabel,
            } satisfies ComparatorHandler<typeof transitions[0], string | undefined>;
          default:
            return undefined;
        }
      },
      initial: { key: 'transitionLabel', direction: TableSortDirection.asc },
    },
    {
      key: 'workflowId',
      getGroupKey: (item) => item.workflowId,
      getGroupLabel: (key) => getInstanceLabelOrUndefined(store, store.getObject(key)),
      getGroupColor: (key) => (resolveConceptColorValue(store, key) ?? resolveConceptChipIcon(store, key)?.color),
    }
  );
  const { list: transitionsGroupedList } = generateTransitionsGroupedList();

  return (
    <VerticalBlock>
      <VerticalBlock asBlockContent withSeparation>
        <BlockTitle
          title={i18n`On each instance`}
          anchor="#oneachinstance"
          subtitle={(
            <div className={classes.subtitlesContainer}>
              <SpacingLine>
                <Typo color={theme.color.text.secondary}>{i18n`You can use roles to manage user rights on each instance.`}</Typo>
              </SpacingLine>
              <SpacingLine>
                <Typo color={theme.color.text.secondary}>
                  {i18n`To do so, create roles and assign capabilities to them. You can find and use those roles in the stakeholders tab of each instance.`}
                </Typo>
              </SpacingLine>
            </div>
          )}
        />
        <BlockContent padded>
          <Banner variant={roleConfigurationStatus.variant} title={roleConfigurationStatus.message} />
        </BlockContent>
        <DataTable
          list={generateList().list}
          newItemTitle={i18n`Create`}
          newItemIcon={IconName.add}
          onNewItem={() => {
            const roleId = createConceptRole(store, conceptDefinitionId);
            forceShowId(roleId);
            focusNewLineNotify(roleId);
            return roleId;
          }}
          newItemButtonVariant={ButtonVariant.tertiary}
          sortCriteria={sortCriteria}
          doSort={doSort}
          linesActions={(role) => [{ key: 'delete', name: i18n`Delete`, icon: IconName.delete, onClick: () => doDelete(role.id), danger: true }]}
          multiplayerRenderer={(line, columnKeys) => <ActivityIndicator instanceIds={line.id} propertyIds={columnKeys} />}
          getNavigationPayload={({ id }) => getNavigationPayload(navigation, id, getConceptUrl(store, id))}
          newLineFocus={newLineFocus?.current}
          columnsDefinition={[
            {
              propertyId: Concept_Name,
              name: i18n`Name`,
              sortable: true,
              width: 30,
              openButton: () => true,
              cellRender: ({ id, [Concept_Name]: name }, focusOnMount) => (
                <StoreRichTextInputField
                  initialValue={name as RichText | undefined}
                  onSubmit={(newName) => store.updateObject(id, { [Concept_Name]: newName })}
                  focusOnMount={focusOnMount}
                  onEditionStart={() => updateActivity.onEnterEdition(id, Concept_Name)}
                  onEditionStop={() => updateActivity.onExitEdition(id, Concept_Name)}
                  isEditing={activity.listEditor(id, Concept_Name).length > 0}
                />
              ),
            },
            {
              propertyId: ConceptCapability,
              name: i18n`Capabilities`,
              width: 50,
              cellRender: (role) => (
                <SearchAndSelectMultiple
                  onSelect={({ id }) => store.withAssociation(ConceptRoleCapability)
                    .withRole(ConceptRoleCapability_Role_ConceptRole, role.id)
                    .withRole(ConceptRoleCapability_Role_ConceptCapability, id)
                    .updateObject({})}
                  onDelete={({ id }) => store.withAssociation(ConceptRoleCapability)
                    .withRole(ConceptRoleCapability_Role_ConceptRole, role.id)
                    .withRole(ConceptRoleCapability_Role_ConceptCapability, id)
                    .deleteObject()}
                  computeOptions={() => store.getObject(ConceptCapability)
                    .navigateBack(Class_Instances)
                    .filter(({ id }) => ![ConceptCapabilityCreate, ConceptCapabilityRead].includes(id))
                    .map((capability) => getChipOptions(store, capability.id))
                    .filter(filterNullOrUndefined)
                    .sort(defaultOptionComparator)}
                  selectedOptions={store.withAssociation(ConceptRoleCapability).list()
                    .filter((assoc) => assoc.role(ConceptRoleCapability_Role_ConceptRole) === role.id)
                    .map((assoc) => assoc.navigateRole(ConceptRoleCapability_Role_ConceptCapability))
                    .sort(compareProperty(ConceptCapability_Name, compareString))
                    .map((capability) => getChipOptions(store, capability.id))
                    .filter(filterNullOrUndefined)}
                  searchOptions={{
                    searchKeys: [ConceptCapability_Name],
                    extractValue: ({ object }, searchKey) => {
                      const value = object[searchKey];
                      return typeof value === 'string' ? value : undefined;
                    },
                  }}
                />
              ),
            },
            {
              propertyId: ConceptRole_AssignedByDefault,
              name: i18n`Role for creator`,
              icon: { iconName: IconName.info, text: i18n`Default role(s) assigned to the user who created the instance`, color: IconColorVariant.info },
              sortable: true,
              width: 10,
              cellRender: ({ id, [ConceptRole_AssignedByDefault]: assignedByDefault }) => (
                <Checkbox
                  checked={Boolean(assignedByDefault)}
                  onChange={(checked) => store.updateObject(id, { [ConceptRole_AssignedByDefault]: checked })}
                />
              ),
            },
            {
              propertyId: ConceptRole_ForCollaboration,
              name: i18n`Role for collaborators`,
              icon: {
                iconName: IconName.info,
                text: i18n`Default role(s) assigned to the user who are added as recipient in a collaboration on a instance`,
                color: IconColorVariant.info,
              },
              sortable: true,
              width: 10,
              cellRender: ({ id, [ConceptRole_ForCollaboration]: forCollaboration }) => (
                <Checkbox
                  checked={Boolean(forCollaboration)}
                  onChange={(checked) => store.updateObject(id, { [ConceptRole_ForCollaboration]: checked })}
                />
              ),
            },
          ]}
        />
      </VerticalBlock>
      <VerticalBlock asBlockContent withSeparation>
        <BlockTitle
          title={i18n`On all instances`}
          anchor="#onallinstances"
          subtitle={(
            <div className={classes.subtitlesContainer}>
              <SpacingLine>
                <Typo color={theme.color.text.secondary}>
                  {i18n.jsx`You can use ${(
                    <InlineLink key="groups" to={{ pathname: `/settings/organization/${Group}`, hash: '#instance' }}>{i18n`groups`}</InlineLink>
                  )} to manage user rights for all instance.`}
                </Typo>
              </SpacingLine>
              <SpacingLine>
                <Typo color={theme.color.text.secondary}>
                  {i18n`To do so, select groups defined at platform level and assign capabilities to them. They will be applied to all instances.`}
                </Typo>
              </SpacingLine>
            </div>
          )}
        />
        <BlockContent padded>
          <Banner variant={groupConfigurationStatus.variant} title={groupConfigurationStatus.message} />
        </BlockContent>
        <DataTable
          sortCriteria={groupSortCriteria}
          doSort={doSortGroupList}
          list={groupSortedList}
          multiplayerRenderer={(line, columnKeys) => <ActivityIndicator instanceIds={line.id} propertyIds={columnKeys} />}
          newItemTitle={i18n`Add`}
          newItemIcon={IconName.add}
          onNewItem={() => setShowGroupLine(true)}
          newItemButtonVariant={ButtonVariant.tertiary}
          inlineCreation={{
            render: showGroupLine ? (
              <TableLine>
                <TableCell noSeparator />
                <TableCell noSeparator />
                <TableCell>
                  <SearchAndSelect
                    computeOptions={() => store.getObject(Group)
                      .navigateBack(Class_Instances)
                      .filter((group) => !groupSortedList.some(({ item: { id } }) => id === group.id))
                      .map(({ id }) => getChipOptions(store, id))
                      .filter(filterNullOrUndefined)
                      .sort(defaultOptionComparator)}
                    onSelect={(value) => setNewGroup(value?.id)}
                    selectedOption={newGroup ? getChipOptions(store, newGroup) : undefined}
                    searchOptions={getSearchChipOptions(store, Group)}
                    editOnMount
                    onEscape={() => handleReset()}
                  />
                </TableCell>
                <TableCell>
                  <SearchAndSelectMultiple
                    computeOptions={() => store.getObject(ConceptCapability)
                      .navigateBack(Class_Instances)
                      .filter((capability) => !newCapabilities.some((id) => id === capability.id))
                      .map(({ id }) => getChipOptions(store, id))
                      .filter(filterNullOrUndefined)
                      .sort(defaultOptionComparator)}
                    onSelect={(value) => setNewCapabilities((current) => [...current, value?.id])}
                    onDelete={(value) => setNewCapabilities((current) => current.filter((capability) => capability !== value.id))}
                    selectedOptions={newCapabilities
                      .map((capabilityId) => getChipOptions(store, capabilityId))
                      .filter(filterNullOrUndefined)}
                    searchOptions={{
                      searchKeys: [ConceptCapability_Name],
                      extractValue: ({ object }, searchKey) => {
                        const value = object[searchKey];
                        return typeof value === 'string' ? value : undefined;
                      },
                    }}
                  />
                </TableCell>
                <TableCell align={TableCellAlign.center} action>
                  <IconOnlyButton
                    tooltip={i18n`Add`}
                    onClick={onCreate}
                    iconName={IconName.check}
                    disabled={!newGroup || !newCapabilities.length}
                  />
                </TableCell>
              </TableLine>
            ) : null,
          }}
          linesActions={(group) => [{
            key: 'remove',
            name: i18n`Remove`,
            icon: IconName.delete,
            danger: true,
            onClick: () => {
              store.withAssociation(ConceptGroupCapability)
                .withRole(ConceptGroupCapability_Role_ConceptGroup, group.id)
                .withRole(ConceptGroupCapability_Role_ConceptDefinition, conceptDefinitionId)
                .list()
                .forEach(({ object }) => store.deleteObject(object.id));
            },
          }]}
          columnsDefinition={[
            {
              propertyId: Concept_Name,
              name: i18n`Name`,
              sortable: true,
              width: 30,
              cellRender: ({ id, [Concept_Name]: groupName }) => {
                const option = getChipOptions(store, id);
                const actions = [];
                if (option?.getNavigationPayload) {
                  const navigationPayload = option.getNavigationPayload(navigation);
                  actions.push({
                    key: 'open',
                    icon: IconName.output,
                    tooltip: i18n`Open`,
                    action: { to: navigationPayload.to, state: navigationPayload.state, openInNewTab: false },
                    showOnHover: true,
                  });
                }

                return (
                  <TableInnerCellContainer variant={TableInnerCellContainerVariants.centeredFlex} padding={buildPadding({ x: Spacing.s })}>
                    <Chip
                      text={option?.label ?? richTextToText(groupName as RichText | undefined)}
                      tooltip={option?.tooltip}
                      squareColor={option?.squareColor}
                      icon={option?.icon ?? IconName.group}
                      color={option?.color}
                      actions={actions}
                    />
                  </TableInnerCellContainer>
                );
              },
            },
            {
              propertyId: ConceptCapability,
              name: i18n`Capabilities`,
              width: 70,
              cellRender: ({ id: groupId }) => (
                <SearchAndSelectMultiple
                  onSelect={({ id }) => store.withAssociation(ConceptGroupCapability)
                    .withRole(ConceptGroupCapability_Role_ConceptGroup, groupId)
                    .withRole(ConceptGroupCapability_Role_ConceptCapability, id)
                    .withRole(ConceptGroupCapability_Role_ConceptDefinition, conceptDefinitionId)
                    .updateObject({})}
                  onDelete={({ id }) => store.withAssociation(ConceptGroupCapability)
                    .withRole(ConceptGroupCapability_Role_ConceptGroup, groupId)
                    .withRole(ConceptGroupCapability_Role_ConceptCapability, id)
                    .withRole(ConceptGroupCapability_Role_ConceptDefinition, conceptDefinitionId)
                    .deleteObject()}
                  computeOptions={() => store.getObject(ConceptCapability)
                    .navigateBack(Class_Instances)
                    .map(({ id }) => getChipOptions(store, id))
                    .filter(filterNullOrUndefined)
                    .sort(defaultOptionComparator)}
                  selectedOptions={
                    store.withAssociation(ConceptGroupCapability)
                      .withRole(ConceptGroupCapability_Role_ConceptDefinition, conceptDefinitionId)
                      .withRole(ConceptGroupCapability_Role_ConceptGroup, groupId)
                      .list()
                      .map((assoc) => getChipOptions(store, assoc.role(ConceptGroupCapability_Role_ConceptCapability)))
                      .filter(filterNullOrUndefined)
                  }
                  searchOptions={{
                    searchKeys: [ConceptCapability_Name],
                    extractValue: ({ object }, searchKey) => {
                      const value = object[searchKey];
                      return typeof value === 'string' ? value : undefined;
                    },
                  }}
                />
              ),
            },
          ]}
        />
      </VerticalBlock>
      <VerticalBlock asBlockContent withSeparation>
        <BlockTitle
          title={i18n`Available capabilities`}
          subtitle={i18n`This is the list of capabilities you can assign to roles and groups.`}
          anchor="#availablecapabilities"
        />
        <DataTable
          sortCriteria={capabilitiesSortCriteria}
          doSort={doSortCapabilitiesList}
          list={generateCapabilitiesSortedList().list}
          multiplayerRenderer={(line, columnKeys) => <ActivityIndicator instanceIds={line.id} propertyIds={columnKeys} />}
          columnsDefinition={[
            {
              propertyId: ConceptCapability_Name,
              name: i18n`Name`,
              sortable: true,
              width: 15,
              cellRender: ({ id: capabilityId, [ConceptCapability_Name]: name }) => {
                if (capabilityId === ConceptCapabilityRead) {
                  return (
                    <TableInnerCellContainer variant={TableInnerCellContainerVariants.centeredFlex} padding={buildPadding({ x: Spacing.s })}>
                      <SpacingLine>
                        <Chip text={name} />
                        <Icon
                          name={IconName.info}
                          colorVariant={IconColorVariant.info}
                          tooltip={i18n`Users who have a role on an instance automatically have the capability to view the instance.`}
                        />
                      </SpacingLine>
                    </TableInnerCellContainer>
                  );
                } else {
                  return (
                    <TableInnerCellContainer variant={TableInnerCellContainerVariants.centeredFlex} padding={buildPadding({ x: Spacing.s })}>
                      <Chip text={name} />
                    </TableInnerCellContainer>
                  );
                }
              },
            },
            {
              propertyId: ConceptCapability_Description,
              name: i18n`Description`,
              sortable: true,
              width: 25,
              cellRender: ({ [ConceptCapability_Description]: description }) => (<TextInputString value={description} readOnly />),
            },
            {
              propertyId: ConceptRole,
              name: i18n`Roles`,
              width: 30,
              cellRender: ({ id: capabilityId }) => (
                <SearchAndSelectMultiple
                  onSelect={({ id }) => store.withAssociation(ConceptRoleCapability)
                    .withRole(ConceptRoleCapability_Role_ConceptRole, id)
                    .withRole(ConceptRoleCapability_Role_ConceptCapability, capabilityId)
                    .updateObject({})}
                  onDelete={({ id }) => store.withAssociation(ConceptRoleCapability)
                    .withRole(ConceptRoleCapability_Role_ConceptRole, id)
                    .withRole(ConceptRoleCapability_Role_ConceptCapability, capabilityId)
                    .deleteObject()}
                  computeOptions={computeConceptRolesList}
                  selectedOptions={selectedRoles(capabilityId)}
                  readOnly={[ConceptCapabilityCreate, ConceptCapabilityRead].includes(capabilityId)}
                />
              ),
            },
            {
              propertyId: Group,
              name: i18n`Groups`,
              width: 30,
              cellRender: ({ id: capabilityId }) => (
                <SearchAndSelectMultiple
                  onSelect={({ id }) => store.withAssociation(ConceptGroupCapability)
                    .withRole(ConceptGroupCapability_Role_ConceptGroup, id)
                    .withRole(ConceptGroupCapability_Role_ConceptCapability, capabilityId)
                    .withRole(ConceptGroupCapability_Role_ConceptDefinition, conceptDefinitionId)
                    .updateObject({})}
                  onDelete={({ id }) => store.withAssociation(ConceptGroupCapability)
                    .withRole(ConceptGroupCapability_Role_ConceptGroup, id)
                    .withRole(ConceptGroupCapability_Role_ConceptCapability, capabilityId)
                    .withRole(ConceptGroupCapability_Role_ConceptDefinition, conceptDefinitionId)
                    .deleteObject()}
                  computeOptions={computeConceptGroupList}
                  selectedOptions={selectedGroup(capabilityId)}
                />
              ),
            },
          ]}
        />
      </VerticalBlock>
      <VerticalBlock asBlockContent>
        <BlockTitle
          title={i18n`Available workflow capabilities`}
          subtitle={i18n`This is the list of rights associated to workflow transitions.`}
          anchor="#availableworkflowcapabilities"
        />
        <DataTable
          multiplayerRenderer={(line, columnKeys) => <ActivityIndicator instanceIds={line.key} propertyIds={columnKeys} />}
          sortCriteria={transitionsSortCriteria}
          doSort={doSortTransitionsList}
          list={transitionsGroupedList}
          columnsDefinition={[
            {
              propertyId: 'transitionLabel',
              name: i18n`Name`,
              width: 15,
              sortable: true,
              cellRender: ({ transitionLabel }) => (
                <TextInputString
                  readOnly
                  value={transitionLabel}
                />
              ),
            },
            {
              propertyId: 'workflowId',
              name: i18n`Workflow`,
              width: 15,
              sortable: true,
              cellRender: ({ workflowId }) => (
                <SearchAndSelect
                  readOnly
                  selectedOption={getFieldChip(store, conceptDefinitionId, workflowId)}
                />
              ),
            },
            {
              propertyId: `${WorkflowFieldTransition_Rights}_Role`,
              width: 35,
              name: i18n`Role`,
              cellRender: (transition) => (
                <SearchAndSelectMultiple
                  selectedOptions={transition.roles.map((roleId) => getChipOptions(store, roleId)).filter(filterNullOrUndefined)}
                  computeOptions={() => store.getObject(conceptDefinitionId)
                    .navigateBack(ConceptRole_ConceptDefinition)
                    .map((role) => getChipOptions(store, role.id))
                    .filter(filterNullOrUndefined)}
                  onSelect={(option) => store.updateObject(transition.associationId, {
                    [WorkflowFieldTransition_Rights]: { roles: [...transition.roles, option.id], groups: transition.groups },
                  })}
                  onDelete={(option) => store.updateObject(transition.associationId, {
                    [WorkflowFieldTransition_Rights]: { roles: transition.roles.filter((roleId) => roleId !== option.id), groups: transition.groups },
                  })}
                />
              ),
            },
            {
              propertyId: `${WorkflowFieldTransition_Rights}_Group`,
              width: 35,
              name: i18n`Group`,
              cellRender: (transition) => (
                <SearchAndSelectMultiple
                  selectedOptions={transition.groups.map((groupId) => getChipOptions(store, groupId)).filter(filterNullOrUndefined)}
                  computeOptions={() => store.getObject(Group)
                    .navigateBack(Class_Instances)
                    .map((group) => getChipOptions(store, group.id))
                    .filter(filterNullOrUndefined)}
                  onSelect={(option) => store.updateObject(transition.associationId, {
                    [WorkflowFieldTransition_Rights]: { roles: transition.roles, groups: [...transition.groups, option.id] },
                  })}
                  onDelete={(option) => store.updateObject(transition.associationId, {
                    [WorkflowFieldTransition_Rights]: { roles: transition.roles, groups: transition.groups.filter((groupId) => groupId !== option.id) },
                  })}
                />
              ),
            },
          ]}
        />
      </VerticalBlock>
      {deleteModal}
    </VerticalBlock>
  );
};

export default PermissionTab;
