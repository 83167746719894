import { OriginSources, ValidationStatus } from 'yooi-store';
import { buildValidateApiLabelBusinessRule } from '../../common/businessRules';
import { asLocal, CommonAsType } from '../../common/fields/commonPropertyType';
import { resolveConceptConfigurationInternal, resolveConfigurationInternal } from '../../common/fields/FieldModuleDsl';
import type { BusinessRuleRegistration } from '../../common/types/TypeModuleDslType';
import { PropertyMandatoryType } from '../../common/types/TypeModuleDslType';
import { Field_Widget } from '../../dashboardModule/ids';
import type { ModelPropertyStoreObject } from '../../typeModule';
import { doExtends, isInstanceOf } from '../../typeModule';
import { Instance_Of, ModelProperty, Property_OfClass } from '../../typeModule/ids';
import { formatOrUndef } from '../common/commonFieldUtils';
import {
  AssociationField,
  AssociationField_Definition,
  AssociationField_SourceRole,
  AssociationFieldDefinition_Role1Type,
  AssociationFieldDefinition_Role2Type,
  Concept,
  ConceptDefinition,
  ConceptDefinition_Name,
  Field,
  Field_ApiAlias,
  Field_FieldDimensions,
  Field_IsCore,
  Field_Title,
  FieldDefinition,
  FieldDimension,
  FieldDimension_Field,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  FieldLocalOverride_Role_Concept,
  FieldLocalOverrideProperty_OverrideProperty,
  RelationMultipleField,
  RelationMultipleField_ReverseField,
  RelationMultipleField_TargetType,
  RelationSingleField,
  RelationSingleField_TargetType,
} from '../ids';
import { registerModel } from '../module';
import type { PathStep } from '../moduleType';
import { adminOnlyAcl, parseFormulaPropertyFunction } from '../utils';

const { association, type, propertyFunction } = registerModel;

type({
  label: 'FieldDefinition',
  accessControlList: {
    READ: () => () => ({ rule: 'fieldDefinition.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'fieldDefinition', 'WRITE'),
    DELETE: () => (_, objectId) => ({ rule: 'fieldDefinition.delete.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: objectId }),
  },
})
  .property({ label: 'FieldDefinition_Title', as: CommonAsType.string, mandatory: { type: PropertyMandatoryType.mandatory } });

const rejectDeletionOfCoreField: BusinessRuleRegistration = ({ getObject }) => (origin, { id, properties }) => {
  if (properties) {
    return undefined;
  } else {
    const fieldInstance = getObject(id);
    if (origin.source === OriginSources.MIGRATION || origin.source === OriginSources.SYSTEM || !fieldInstance[Field_IsCore]) {
      return undefined;
    } else {
      return {
        rule: 'field.coreFieldCannotBeDeleted',
        status: ValidationStatus.REJECTED,
      };
    }
  }
};

const rejectCoreFieldPropertyUpdate: BusinessRuleRegistration = (objectStore) => (origin, { id, properties }) => {
  const isFieldCore = objectStore.getObjectOrNull(id)?.[Field_IsCore];
  if (!isFieldCore || origin.source === OriginSources.MIGRATION || origin.source === OriginSources.SYSTEM || properties?.[Field_IsCore] === true) {
    return undefined;
  } else {
    return {
      rule: 'field.coreFieldPropertyCannotBeUpdated',
      status: ValidationStatus.REJECTED,
    };
  }
};

export interface TimeseriesFormulaInput {
  type: 'timeseries',
  path: PathStep[],
}

export interface ValueFormulaInput {
  type: 'value',
  path: PathStep[],
}

export type FormulaInput = ValueFormulaInput | TimeseriesFormulaInput;

export interface Formula {
  formula: string,
  inputs: { name: string, input: FormulaInput }[],
}

type({
  label: 'Field',
  accessControlList: {
    READ: () => () => ({ rule: 'field.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }, objectId) => {
      const fieldInstance = store.getObjectOrNull(objectId);
      if (!fieldInstance) {
        return { rule: 'field.write.allow', status: ValidationStatus.ACCEPTED };
      } else if (fieldInstance.navigateBack(Field_FieldDimensions).length > 0) {
        return adminOnlyAcl(store, userId, 'field', 'WRITE');
      } else if (fieldInstance.navigateBack(Field_Widget).length > 0) {
        return { rule: 'field.write.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: [fieldInstance.navigateBack(Field_Widget)[0].id] };
      } else {
        return { rule: 'field.write.allow', status: ValidationStatus.ACCEPTED };
      }
    },
    DELETE: () => (_, objectId) => ({ rule: 'field.delete.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: objectId }),
  },
  objectDebugLabel: ({ getObjectOrNull }) => (objectId) => getObjectOrNull(objectId)?.[Field_Title] as string,
  businessRules: [rejectDeletionOfCoreField],
})
  .property({
    label: 'Field_Title',
    as: CommonAsType.string,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'Field_Documentation',
    as: CommonAsType.string,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({ label: 'Field_IsTitleHidden', as: CommonAsType.boolean })
  .property({
    label: 'Field_IsDocumentationInline',
    as: CommonAsType.boolean,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({ label: 'Field_Formula', as: asLocal('Formula') })
  .propertyFunctionWithTimeseries({ label: 'Field_ParsedFormula', computeFunction: parseFormulaPropertyFunction })
  .property({ label: 'Field_IntegrationOnly', as: CommonAsType.boolean })
  .property({
    label: 'Field_IsCore',
    as: CommonAsType.boolean,
    businessRules: [rejectCoreFieldPropertyUpdate],
  })
  .property({
    label: 'Field_ApiAlias',
    as: CommonAsType.string,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
    businessRules: [buildValidateApiLabelBusinessRule(Field_ApiAlias, 'field.apiAlias')],
  })
  .propertyFunction({
    label: 'Field_Configuration',
    computeFunction: (objectStore) => ([fieldId]) => resolveConfigurationInternal(objectStore, fieldId),
  });

propertyFunction({
  label: 'FieldXConcept_Configuration',
  computeFunction: (objectStore) => ([fieldId, conceptId]) => resolveConceptConfigurationInternal(objectStore, fieldId, conceptId),
});

type({ label: 'FieldLocalOverrideProperty', extends: ModelProperty })
  .relation({
    label: 'FieldLocalOverrideProperty_OverrideProperty',
    targetTypeId: ModelProperty,
    reverseLabel: 'ModelProperty_OverriddenBy',
    businessRules: [
      ({ getObjectOrNull }) => (_, { properties }) => {
        if (!properties) {
          return undefined;
        }
        if (properties[FieldLocalOverrideProperty_OverrideProperty] === null) {
          return {
            rule: 'FieldLocalOverrideProperty_OverrideProperty.preventClearingTarget',
            status: ValidationStatus.REJECTED,
          };
        }
        const property = getObjectOrNull<ModelPropertyStoreObject>(properties[FieldLocalOverrideProperty_OverrideProperty] as string);
        if (!property) {
          return {
            rule: 'FieldLocalOverrideProperty_OverrideProperty.targetMissing',
            status: ValidationStatus.REJECTED,
          };
        } else if (!property[Property_OfClass]) {
          return {
            rule: 'FieldLocalOverrideProperty_OverrideProperty.Property_OfClassMissing',
            status: ValidationStatus.REJECTED,
          };
        }
        if (isInstanceOf(getObjectOrNull(property[Property_OfClass]), FieldDefinition)) {
          return {
            rule: 'FieldLocalOverrideProperty_OverrideProperty.validTarget',
            status: ValidationStatus.ACCEPTED,
          };
        } else {
          return {
            rule: 'FieldLocalOverrideProperty_OverrideProperty.invalidTarget',
            status: ValidationStatus.REJECTED,
          };
        }
      },
    ],
  });

association({
  label: 'FieldLocalOverride',
  roles: [{ label: 'Concept', targetTypeId: Concept }, { label: 'Field', targetTypeId: Field }],
  accessControlList: {
    READ: () => (_, objectId) => ({
      rule: 'fieldLocalOverride.read.delegate',
      status: ValidationStatus.DELEGATED,
      targetId: [objectId[FieldLocalOverride_Role_Concept + 1]],
      targetAction: 'READ',
    }),
    WRITE: () => (_, objectId) => ({
      rule: 'fieldLocalOverride.write.delegate',
      status: ValidationStatus.DELEGATED,
      targetId: [objectId[FieldLocalOverride_Role_Concept + 1]],
      targetAction: 'WRITE',
    }),
    DELETE: () => (_, objectId) => ({
      rule: 'fieldLocalOverride.delete.delegate',
      status: ValidationStatus.DELEGATED,
      targetId: [objectId[FieldLocalOverride_Role_Concept + 1]],
      targetAction: 'WRITE',
    }),
  },
});

const cannotUpdateDimensionField: BusinessRuleRegistration = ({ getObjectOrNull, getObject }) => (_, { id, properties }) => {
  // creation, deletion or timeseries update don't care
  if (!properties || !getObjectOrNull(id)) {
    return undefined;
  }
  if (properties[FieldDimension_Field] && properties[FieldDimension_Field] !== getObject(id)[FieldDimension_Field]) {
    return {
      status: ValidationStatus.REJECTED,
      rule: 'fieldDimension.cannotUpdateFieldDimension_Field',
    };
  }
  return undefined;
};

const rejectDeletionOfCoreFieldDimension: BusinessRuleRegistration = ({ getObject }) => (origin, { id, properties }) => {
  // not a deletion don't care
  if (properties !== null) {
    return undefined;
  }
  if (origin.source === OriginSources.MIGRATION || origin.source === OriginSources.SYSTEM || origin.source === OriginSources.GARBAGE) {
    return undefined;
  }

  const dimension = getObject(id);
  const fieldInstance = dimension.navigate(FieldDimension_Field);
  if (!fieldInstance[Field_IsCore]) {
    return undefined;
  } else {
    return {
      rule: 'field.coreFieldDimensionCannotBeDeleted',
      status: ValidationStatus.REJECTED,
    };
  }
};

type({
  label: 'FieldDimension',
  accessControlList: {
    READ: () => () => ({ rule: 'fieldDimension.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'fieldDimension', 'WRITE'),
    DELETE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'fieldDimension', 'DELETE'),
  },
  objectDebugLabel: ({ getObject, withAssociation }) => (objectId) => {
    const field = getObject(objectId)?.navigateOrNull(FieldDimension_Field);
    if (field !== null) {
      const types = withAssociation(FieldDimensionTypes)
        .withRole(FieldDimensionTypes_Role_FieldDimension, typeof objectId === 'string' ? objectId : objectId[0])
        .withExternalRole(FieldDimensionTypes_Role_ConceptDefinition)
        .list()
        .map((fieldDimensionType) => {
          const conceptDefinition = fieldDimensionType.navigateRoleOrNull(FieldDimensionTypes_Role_ConceptDefinition);
          return conceptDefinition === null ? fieldDimensionType.role(FieldDimensionTypes_Role_ConceptDefinition) : `'${formatOrUndef(conceptDefinition[ConceptDefinition_Name] as string | undefined)}'`;
        });

      if (types.length === 0) {
        return `FieldDimension #NONE => '${field[Field_Title]}'`;
      } else if (types.length === 1) {
        return `FieldDimension ${types[0]} => '${field[Field_Title]}'`;
      } else if (types.length < 6) {
        return `FieldDimension [${types.join(', ')}] => '${field[Field_Title]}'`;
      } else {
        return `FieldDimension [${types.slice(0, 5).join(', ')}, ...] => '${field[Field_Title]}'`;
      }
    } else {
      return undefined;
    }
  },
  businessRules: [
    cannotUpdateDimensionField,
    rejectDeletionOfCoreFieldDimension,
  ],
})
  .property({
    label: 'FieldDimension_Label',
    as: CommonAsType.string,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'FieldDimension_IsMandatory',
    as: CommonAsType.boolean,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .relation({ label: 'FieldDimension_Field', targetTypeId: Field, reverseLabel: 'Field_FieldDimensions', mandatory: { type: PropertyMandatoryType.mandatory } });

const validateRelationAndAssociationConceptFields: BusinessRuleRegistration = ({ getObjectOrNull, getObject }) => (_, { id, properties }) => {
  // not a creation don't care
  if (getObjectOrNull(id) || !properties) {
    return undefined;
  }

  const dimension = getObject(id[FieldDimensionTypes_Role_FieldDimension + 1]);
  const fieldInstance = dimension.navigate(FieldDimension_Field);
  const concept = getObject(id[FieldDimensionTypes_Role_ConceptDefinition + 1]);
  const isRelationSingleField = fieldInstance[Instance_Of] === RelationSingleField;
  const isRelationMultipleField = fieldInstance[Instance_Of] === RelationMultipleField;
  const isAssociationField = fieldInstance[Instance_Of] === AssociationField;
  const isCompatibleType = (expectedTypeId: string) => expectedTypeId === concept.id || doExtends(concept, expectedTypeId);
  if (fieldInstance && isRelationSingleField) {
    const reverseField = fieldInstance.navigateBack(RelationMultipleField_ReverseField)[0];
    const expectedTypeId = reverseField[RelationMultipleField_TargetType] as string;
    const isCompatible = isCompatibleType(expectedTypeId);
    if (isCompatible) {
      return {
        rule: 'conceptField.relationSingleConceptFieldCompatibility',
        status: ValidationStatus.ACCEPTED,
      };
    } else {
      return {
        rule: 'conceptField.relationSingleConceptFieldCompatibility',
        status: ValidationStatus.REJECTED,
      };
    }
  } else if (fieldInstance && isRelationMultipleField) {
    const reverseField = fieldInstance.navigate(RelationMultipleField_ReverseField);
    const expectedTypeId = reverseField[RelationSingleField_TargetType] as string;
    const isCompatible = isCompatibleType(expectedTypeId);
    if (isCompatible) {
      return {
        rule: 'conceptField.relationMultipleConceptFieldCompatibility',
        status: ValidationStatus.ACCEPTED,
      };
    } else {
      return {
        rule: 'conceptField.relationMultipleConceptFieldCompatibility',
        status: ValidationStatus.REJECTED,
      };
    }
  } else if (fieldInstance && isAssociationField) {
    const sourceRole = fieldInstance[AssociationField_SourceRole];
    const sourceRoleField = sourceRole === 1 ? AssociationFieldDefinition_Role1Type : AssociationFieldDefinition_Role2Type;
    const fieldDefinition = fieldInstance.navigate(AssociationField_Definition);
    const expectedTypeId = fieldDefinition[sourceRoleField] as string;
    const isCompatible = isCompatibleType(expectedTypeId);
    if (isCompatible) {
      return {
        rule: 'conceptField.associationConceptFieldCompatibility',
        status: ValidationStatus.ACCEPTED,
      };
    } else {
      return {
        rule: 'conceptField.associationConceptFieldCompatibility',
        status: ValidationStatus.REJECTED,
      };
    }
  } else {
    return undefined;
  }
};

const rejectUnlinkOfCoreField: BusinessRuleRegistration = ({ getObject }) => (origin, { id, properties }) => {
  // not a deletion don't care
  if (properties !== null || origin.source === OriginSources.MIGRATION || origin.source === OriginSources.SYSTEM || origin.source === OriginSources.GARBAGE) {
    return undefined;
  }

  const dimension = getObject(id[FieldDimensionTypes_Role_FieldDimension + 1]);
  const fieldInstance = dimension.navigate(FieldDimension_Field);
  if (!fieldInstance[Field_IsCore]) {
    return undefined;
  } else {
    return {
      rule: 'field.coreFieldCannotBeUnlinked',
      status: ValidationStatus.REJECTED,
    };
  }
};

association({
  label: 'FieldDimensionTypes',
  roles: [{ label: 'FieldDimension', targetTypeId: FieldDimension }, { label: 'ConceptDefinition', targetTypeId: ConceptDefinition }],
  accessControlList: {
    READ: () => () => ({ rule: 'fieldDimensionTypes.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'fieldDimensionTypes', 'WRITE'),
    DELETE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'fieldDimensionTypes', 'DELETE'),
  },
  businessRules: [
    validateRelationAndAssociationConceptFields,
    rejectUnlinkOfCoreField,
  ],
});
