import type { FunctionComponent } from 'react';
import { dateFormats, formatDisplayDate } from 'yooi-utils';
import Typo from '../../../../components/atoms/Typo';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import TableInnerCellContainer, { TableInnerCellContainerVariants } from '../../../../components/molecules/TableInnerCellContainer';
import useStore from '../../../../store/useStore';
import { buildPadding, Spacing, spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: spacingRem.s,
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
    borderWidth: 0,
    borderLeftWidth: '0.1rem',
  },
}), 'timestampFieldRenderer');

interface TimestampFieldRendererProps {
  objectId: string,
  fieldId: string,
}

const TimestampFieldRenderer: FunctionComponent<TimestampFieldRendererProps> = ({ objectId, fieldId }) => {
  const classes = useStyles();
  const store = useStore();

  const usageVariant = useUsageContext();

  const object = store.getObject(objectId);

  if (usageVariant === UsageVariant.inTable) {
    return (
      <TableInnerCellContainer variant={TableInnerCellContainerVariants.centeredFlex} padding={buildPadding({ x: Spacing.s })}>
        <Typo maxLine={1}>{formatDisplayDate(new Date(object[fieldId] as number), dateFormats.dayNumberAndMonthAndYear)}</Typo>
      </TableInnerCellContainer>
    );
  } else {
    return (
      <div className={classes.container}>
        <SpacingLine>
          <Typo>{formatDisplayDate(new Date(object[fieldId] as number), dateFormats.timestamp)}</Typo>
        </SpacingLine>
      </div>
    );
  }
};

export default TimestampFieldRenderer;
