import type { FunctionComponent } from 'react';
import { v4 as uuid } from 'uuid';
import type { FieldStoreObject, WorkflowFieldStoreObject, WorkflowStoreObject } from 'yooi-modules/modules/conceptModule';
import { getConceptDefinitionValidFields } from 'yooi-modules/modules/conceptModule';
import {
  Field_Title,
  Workflow,
  Workflow_Name,
  Workflow_TargetedConceptDefinition,
  WorkflowField,
  WorkflowField_TargetedConceptDefinition,
  WorkflowField_Workflow,
  WorkflowFieldFields,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareArray, compareString, comparing, filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import Typo from '../../../../../components/atoms/Typo';
import InlineLink from '../../../../../components/molecules/InlineLink';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import SearchAndSelectMultiple from '../../../../../components/molecules/SearchAndSelectMultiple';
import { TableSortDirection } from '../../../../../components/molecules/Table';
import BlockTitle from '../../../../../components/templates/BlockTitle';
import DataTable from '../../../../../components/templates/DataTable';
import VerticalBlock from '../../../../../components/templates/VerticalBlock';
import useStore from '../../../../../store/useStore';
import { spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import { safeSessionStorageValue } from '../../../../../utils/sessionStorageUtils';
import useNavigation from '../../../../../utils/useNavigation';
import useNewLineFocus from '../../../../../utils/useNewLineFocus';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../../../utils/useSizeContext';
import useTheme from '../../../../../utils/useTheme';
import { linkFieldToFieldDimensions } from '../../../../_global/fields/fieldDimensionUtils';
import { workflowFieldDefinition } from '../../../../_global/fields/workflowField/workflowFieldDefinition';
import SearchTextButton from '../../../../_global/filter/SearchTextButton';
import type { FilterConfiguration } from '../../../../_global/filter/useFilterSessionStorage';
import StoreTextInputField from '../../../../_global/input/StoreTextInputField';
import { searchFilterFunction } from '../../../../_global/listFilterFunctions';
import { defaultOptionComparator, getChipOptions, getUnknownChip } from '../../../../_global/modelTypeUtils';
import type { NavigationFilter } from '../../../../_global/navigationUtils';
import { getNavigationPayload } from '../../../../_global/navigationUtils';
import type { ComparatorHandler } from '../../../../_global/useFilterAndSort';
import useFilterAndSort, { buildStringColumnComparatorHandler } from '../../../../_global/useFilterAndSort';
import { listFieldIdsOfWorkflowField } from '../../../../_global/workflow/workflowUtils';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: spacingRem.l,
    paddingBottom: spacingRem.xs,
  },
  headerRightContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.m,
  },
}, 'workflowTab');

interface WorkflowTabProps {
  conceptDefinitionId: string,
}

const WorkflowTab: FunctionComponent<WorkflowTabProps> = ({ conceptDefinitionId }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const navigation = useNavigation<NavigationFilter>();

  const workflowFields = getConceptDefinitionValidFields(store, conceptDefinitionId)
    .filter((field) => isInstanceOf<WorkflowFieldStoreObject>(field, WorkflowField));

  const workflowFieldDefinitionHandler = workflowFieldDefinition(store);

  const [newLineFocus, setNewLineFocus] = useNewLineFocus();

  const filterId = `${conceptDefinitionId}_${WorkflowField}`;
  const { generatePageList, doSort, sortCriteria, forceShowId } = useFilterAndSort(
    filterId,
    workflowFields,
    searchFilterFunction(store, safeSessionStorageValue<FilterConfiguration | undefined>(filterId)?.nameSearch, [Field_Title]),
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case Field_Title:
            return buildStringColumnComparatorHandler(key, direction);
          case WorkflowField_Workflow:
            return {
              comparator: comparing(compareString, direction === TableSortDirection.desc),
              extractValue: (field) => field.navigateOrNull<WorkflowStoreObject>(WorkflowField_Workflow)?.[Workflow_Name],
            } satisfies ComparatorHandler<FieldStoreObject, string | undefined>;
          case WorkflowFieldFields:
            return {
              comparator: comparing(compareArray, direction === TableSortDirection.desc),
              extractValue: (field) => listFieldIdsOfWorkflowField(store, field.id),
            } satisfies ComparatorHandler<FieldStoreObject, string[] | undefined>;
          default:
            return undefined;
        }
      },
      initial: { key: Field_Title, direction: TableSortDirection.asc },
    }
  );
  const { list, pagination } = generatePageList(25);

  return (
    <VerticalBlock compact>
      <BlockTitle title={i18n`Available workflows`} />
      <BlockTitle
        title={(
          <Typo color={theme.color.text.secondary}>
            {i18n.jsx`You can create new workflows from the ${
              <InlineLink key="workflow" to="/settings/workflow">{i18n`Workflows page`}</InlineLink>
            } and configure any of them for this concept using the table below.`}
          </Typo>
        )}
        rightActions={(
          <div className={classes.headerContainer}>
            <div className={classes.headerRightContainer}>
              <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
                <SearchTextButton element={filterId} placeholder={i18n`Search`} />
              </SizeContextProvider>
            </div>
          </div>
        )}
      />
      <DataTable
        getNavigationPayload={({ id }) => getNavigationPayload(navigation, id, `/settings/organization/${conceptDefinitionId}/workflow/${id}`)}
        doSort={doSort}
        sortCriteria={sortCriteria}
        columnsDefinition={[
          {
            propertyId: Field_Title,
            name: i18n`Name`,
            width: 40,
            sortable: true,
            focusable: true,
            cellRender: ({ id, [Field_Title]: name }, focusOnMount) => (
              <StoreTextInputField
                initialValue={name}
                focusOnMount={focusOnMount}
                onSubmit={(newName) => {
                  const ofField = workflowFieldDefinitionHandler.ofField(id);
                  ofField.submitFieldUpdate(joinObjects(ofField.getInitialState(conceptDefinitionId), { [Field_Title]: newName }), conceptDefinitionId);
                }}
              />
            ),
            openButton: () => true,
          },
          {
            propertyId: WorkflowField_Workflow,
            name: i18n`Workflow`,
            sortable: true,
            width: 40,
            cellRender: (workflowField) => (
              <SearchAndSelect
                selectedOption={
                  workflowField[WorkflowField_Workflow] !== undefined
                    ? getChipOptions(store, workflowField[WorkflowField_Workflow]) ?? getUnknownChip(workflowField[WorkflowField_Workflow])
                    : undefined
                }
                computeOptions={() => (
                  store.getObject(Workflow)
                    .navigateBack<WorkflowStoreObject>(Class_Instances)
                    .filter((workflow) => (
                      workflowField[WorkflowField_TargetedConceptDefinition] === undefined
                      || workflowField[WorkflowField_TargetedConceptDefinition] === workflow[Workflow_TargetedConceptDefinition]
                    ))
                    .map(({ id }) => getChipOptions(store, id))
                    .filter(filterNullOrUndefined)
                    .sort(defaultOptionComparator)
                )}
                onSelect={(option) => {
                  if (option) {
                    const ofField = workflowFieldDefinitionHandler.ofField(workflowField.id);
                    ofField.submitFieldUpdate(joinObjects(ofField.getInitialState(conceptDefinitionId), { [WorkflowField_Workflow]: option.id }), conceptDefinitionId);
                  }
                }}
                getInlineCreation={() => ({
                  type: 'inline',
                  onCreate: (title) => {
                    const newWorkflowId = store.createObject({ [Instance_Of]: Workflow, [Workflow_Name]: title });
                    const ofField = workflowFieldDefinitionHandler.ofField(workflowField.id);
                    ofField.submitFieldUpdate(joinObjects(ofField.getInitialState(conceptDefinitionId), { [WorkflowField_Workflow]: newWorkflowId }), conceptDefinitionId);
                    return newWorkflowId;
                  },
                })}
              />
            ),
          },
          {
            propertyId: WorkflowFieldFields,
            name: i18n`Applied on fields`,
            icon: {
              iconName: IconName.info,
              text: i18n`If you want to see a workflow on a field, don't forget to select it in its display options (Layout tab)`,
              color: IconColorVariant.info,
            },
            width: 20,
            sortable: true,
            cellRender: ({ id }) => (
              <SearchAndSelectMultiple
                selectedOptions={id
                  ? listFieldIdsOfWorkflowField(store, id)
                    .map((instanceId) => getChipOptions(store, instanceId))
                    .filter(filterNullOrUndefined)
                    .sort(defaultOptionComparator)
                  : []}
                computeOptions={() => getConceptDefinitionValidFields(store, conceptDefinitionId)
                  .map((field) => getChipOptions(store, field.id))
                  .filter(filterNullOrUndefined)}
                onSelect={(option) => {
                  if (option) {
                    const ofField = workflowFieldDefinition(store).ofField(id);
                    const initialState = ofField.getInitialState(conceptDefinitionId);
                    ofField.submitFieldUpdate(
                      joinObjects(initialState, { [WorkflowFieldFields]: [...(initialState[WorkflowFieldFields] ?? []), option.id] }),
                      conceptDefinitionId
                    );
                  }
                }}
                onDelete={(option) => {
                  const ofField = workflowFieldDefinition(store).ofField(id);
                  const initialState = ofField.getInitialState(conceptDefinitionId);
                  ofField.submitFieldUpdate(
                    joinObjects(initialState, { [WorkflowFieldFields]: [...(initialState[WorkflowFieldFields] ?? []).filter((i) => i !== option.id)] }),
                    conceptDefinitionId
                  );
                }}
              />
            ),
          },
        ]}
        linesActions={({ id }) => [
          {
            key: 'delete',
            name: i18n`Delete`,
            icon: IconName.delete,
            onClick: () => store.deleteObject(id),
            danger: true,
          },
        ]}
        list={list}
        pagination={pagination}
        newItemIcon={IconName.add}
        newItemTitle={i18n`Create`}
        onNewItem={() => {
          const fieldId = store.createObject({ [Instance_Of]: WorkflowField });
          linkFieldToFieldDimensions(store, fieldId, { [uuid()]: { typeId: conceptDefinitionId, label: undefined, mandatory: false, readOnly: true } });
          forceShowId(fieldId);
          setNewLineFocus(fieldId);
        }}
        newLineFocus={newLineFocus.current}
      />
    </VerticalBlock>
  );
};

export default WorkflowTab;
