export const bondedValue = (value: number, min: number, max: number): number => {
  if (value < min) {
    return min;
  } else if (value > max) {
    return max;
  } else {
    return value;
  }
};

export const computeMaxSize = (
  { naturalWidth, naturalHeight }: Record<'naturalWidth' | 'naturalHeight', number>,
  aspectRatio: Record<'x' | 'y', number>,
  scale: number
): Record<'minTop' | 'minLeft' | 'maxTop' | 'maxLeft', number> => ({
  minTop: Math.min(1 - ((naturalHeight * scale) / aspectRatio.y), 0),
  minLeft: Math.min(1 - ((naturalWidth * scale) / aspectRatio.x), 0),
  maxTop: Math.max((1 - (naturalHeight * scale) / aspectRatio.y), 0),
  maxLeft: Math.max((1 - (naturalWidth * scale) / aspectRatio.x), 0),
});

export const computeScaleLimits = (
  { naturalWidth, naturalHeight }: Record<'naturalWidth' | 'naturalHeight', number>,
  aspectRatio: Record<'x' | 'y', number>
): Record<'fitScale' | 'minScale', number> => {
  const minWidthScale = aspectRatio.x / naturalWidth;
  const minHeightScale = aspectRatio.y / naturalHeight;

  return {
    fitScale: minWidthScale > minHeightScale ? minWidthScale : minHeightScale,
    minScale: Math.min(minHeightScale, minWidthScale),
  };
};
