import type { FunctionComponent } from 'react';
import { useRef } from 'react';
import i18n from '../../utils/i18n';
import type { ButtonVariant } from '../atoms/Button';
import Button from '../atoms/Button';
import { IconName } from '../atoms/Icon';
import UploadFileForm from './UploadFileForm';

interface UploadFileButtonProps {
  value: { type: 'url', url: string } | { type: 'buffer', data: ArrayBuffer, contentType: string } | undefined,
  onChange: (data: { type: 'buffer', data: ArrayBuffer, contentType: string }) => void,
  buttonVariant?: ButtonVariant,
  typeRestriction: { accept: string, isAccepted: (type: string) => void, errorMessage: string },
}

const UploadFileButton: FunctionComponent<UploadFileButtonProps> = ({ value, onChange, buttonVariant, typeRestriction }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Button
        onClick={() => inputRef.current?.click()}
        title={value === undefined ? i18n`Upload a file` : i18n`Replace`}
        iconName={value === undefined ? IconName.download : IconName.sync}
        variant={buttonVariant}
      />
      <UploadFileForm value={value} onChange={onChange} typeRestriction={typeRestriction} inputRef={inputRef} />
    </>
  );
};

export default UploadFileButton;
