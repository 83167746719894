import type { FunctionComponent } from 'react';
import type {
  AssociationFilterRaw,
  AssociationFilterStoreObject,
  ConceptDefinitionStoreObject,
  ConditionFilterRaw,
  ConditionFilterStoreObject,
} from 'yooi-modules/modules/conceptModule';
import { FILTER_PARAMETER_CURRENT } from 'yooi-modules/modules/conceptModule';
import {
  AssociationFilter,
  AssociationFilter_Path,
  ConceptDefinition_Description,
  ConceptDefinition_ViewFilters,
  ConditionFilter,
  ViewFilter_ConceptDefinition,
  ViewFilter_Rank,
} from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import BlockContent from '../../../../../components/templates/BlockContent';
import BlockTitle from '../../../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../../../components/templates/VerticalBlock';
import useStore from '../../../../../store/useStore';
import i18n from '../../../../../utils/i18n';
import { getLoggedUserParameterDefinition } from '../../../../_global/filter/filterUtils';
import QuickFiltersEditor, { QuickFilterType } from '../../../../_global/filter/QuickFiltersEditor';
import StoreTextInputField from '../../../../_global/input/StoreTextInputField';
import DataGridViewEditor from './DataGridViewEditor';
import MatrixEditor from './MatrixEditor';
import SwimlaneEditor from './SwimlaneEditor';
import TimelineEditor from './TimelineEditor';

interface LibraryTabProps {
  conceptDefinitionId: string,
}

const LibraryTab: FunctionComponent<LibraryTabProps> = ({ conceptDefinitionId }) => {
  const store = useStore();

  const conceptDefinition = store.getObject<ConceptDefinitionStoreObject>(conceptDefinitionId);

  return (
    <VerticalBlock>
      <VerticalBlock asBlockContent withSeparation>
        <HorizontalBlock asBlockContent>
          <BlockTitle title={i18n`Description`} />
          <BlockContent>
            <StoreTextInputField
              initialValue={conceptDefinition[ConceptDefinition_Description]}
              onSubmit={(summary) => store.updateObject(conceptDefinition.id, { [ConceptDefinition_Description]: summary })}
              placeholder={i18n`Add text`}
            />
          </BlockContent>
        </HorizontalBlock>
      </VerticalBlock>
      <VerticalBlock asBlockContent withSeparation>
        <BlockTitle title={i18n`Predefined filters`} anchor="#predefinedfilters" />
        <QuickFiltersEditor
          onNewItem={(type, rank) => {
            if (type === QuickFilterType.association) {
              return store.createObject<AssociationFilterRaw>({
                [Instance_Of]: AssociationFilter,
                [ViewFilter_ConceptDefinition]: conceptDefinitionId,
                [ViewFilter_Rank]: rank,
                [AssociationFilter_Path]: [],
              });
            } else {
              return store.createObject<ConditionFilterRaw>({
                [Instance_Of]: ConditionFilter,
                [ViewFilter_ConceptDefinition]: conceptDefinitionId,
                [ViewFilter_Rank]: rank,
              });
            }
          }}
          getQuickFilters={() => store.getObject(conceptDefinitionId).navigateBack<AssociationFilterStoreObject | ConditionFilterStoreObject>(ConceptDefinition_ViewFilters)}
          filterParametersDefinitions={[
            getLoggedUserParameterDefinition(),
            {
              id: FILTER_PARAMETER_CURRENT,
              type: 'dimension',
              label: 'Current',
              typeId: conceptDefinitionId,
            }]}
          dimensionParameters={[{
            id: FILTER_PARAMETER_CURRENT,
            type: 'dimension',
            label: 'Current',
            typeId: conceptDefinitionId,
          }]}
        />
      </VerticalBlock>
      <VerticalBlock asBlockContent withSeparation>
        <BlockTitle
          title={i18n`Table and Card display`}
          subtitle={i18n`This concept can be displayed as card or in table. You can define its content below and manage fields ordering.`}
          anchor="#tableandcarddisplay"
        />
        <DataGridViewEditor conceptDefinitionId={conceptDefinitionId} />
      </VerticalBlock>
      <VerticalBlock asBlockContent withSeparation>
        <BlockTitle
          title={i18n`Timeline display`}
          subtitle={i18n`This concept can be displayed in a timeline with proper field. You can define the default timeline view here.`}
          anchor="#timelinedisplay"
        />
        <TimelineEditor conceptDefinitionId={conceptDefinitionId} />
      </VerticalBlock>
      <VerticalBlock asBlockContent withSeparation>
        <BlockTitle
          title={i18n`Matrix display`}
          subtitle={i18n`This concept can be displayed on a matrix with proper fields. You can define the default matrix view here.`}
          anchor="#matrixdisplay"
        />
        <MatrixEditor conceptDefinitionId={conceptDefinitionId} />
      </VerticalBlock>
      <VerticalBlock asBlockContent>
        <BlockTitle
          title={i18n`Swimlane display`}
          subtitle={i18n`This concept can be displayed in a swimlane with proper field. You can define the default swimlane view here.`}
          anchor="#swimlanedisplay"
        />
        <SwimlaneEditor conceptDefinitionId={conceptDefinitionId} />
      </VerticalBlock>
    </VerticalBlock>
  );
};

export default LibraryTab;
