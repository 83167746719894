import type { InstanceEventTriggerHandler } from 'yooi-modules/modules/automationModule';
import { IconName } from '../../../../components/atoms/Icon';
import i18n from '../../../../utils/i18n';
import type { TriggerFrontHandler } from './triggerFrontHandler';

export const createInstanceEventTriggerFrontHandler: () => TriggerFrontHandler<InstanceEventTriggerHandler> = () => ({
  mailQueryNodes: {
    triggering_origin: {
      label: 'triggering_origin',
      helperText: i18n`Text representation of the user, integration, automation, or system that has triggered the mail.`,
      icon: IconName.person,
      children: undefined,
    },
  },
});
