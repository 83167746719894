import type { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import {
  createValuePathResolver,
  FILTER_PARAMETER_CURRENT,
  FILTER_PARAMETER_LOGGED_USER,
  getConceptChipFields,
  getConceptUrl,
  getFilterFunction,
  getInstanceLabel,
  GROUP_BY_PARAMETER,
  isConceptValid,
  isMultiValueResolution,
  isSingleValueResolution,
  isValidValuePathResolution,
} from 'yooi-modules/modules/conceptModule';
import { IdField } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { StoreObject } from 'yooi-store';
// eslint-disable-next-line yooi/no-restricted-dependency
import { isStoreObject } from 'yooi-store';
import type { Comparator } from 'yooi-utils';
import { compareString, comparing, joinObjects, pushUndefinedToEnd } from 'yooi-utils';
import Tooltip from '../../components/atoms/Tooltip';
import Typo from '../../components/atoms/Typo';
import LeftPanelLoader from '../../components/molecules/LeftPanelLoader';
import MasterDetailList from '../../components/molecules/MasterDetailList';
import { TableSortDirection } from '../../components/molecules/Table';
import useAuth from '../../store/useAuth';
import useStore from '../../store/useStore';
import { formatOrUndef } from '../../utils/stringUtils';
import useDerivedState from '../../utils/useDerivedState';
import useNavigation from '../../utils/useNavigation';
import { SessionStorageKeys, useSessionStorageState } from '../../utils/useSessionStorage';
import withAsyncTask from '../../utils/withAsyncTask';
import { getFieldColumnComparator } from '../_global/fieldUtils';
import type { FilterConfiguration } from '../_global/filter/useFilterSessionStorage';
import { getFieldGroupByHandler } from '../_global/groupByUtils';
import { getConceptFilterFunction, searchFilterFunction } from '../_global/listFilterFunctions';
import { getSearchableFields, getTableGroupByField, listGroupByFieldOptions } from '../_global/modelTypeUtils';
import type { NavigationFilter } from '../_global/navigationUtils';
import type { TableConfiguration } from '../_global/sessionStorageTypes';
import type { ComparatorHandler } from '../_global/useFilterAndSort';
import useFilterAndSort from '../_global/useFilterAndSort';

interface ConceptMasterDetailProps {
  conceptId: string,
  filterId: string,
}

const ConceptMasterDetail: FunctionComponent<ConceptMasterDetailProps> = withAsyncTask(({ conceptId, filterId, executeAsyncTask }) => {
  const store = useStore();
  const location = useLocation();
  const navigation = useNavigation<NavigationFilter>();
  const { loggedUserId } = useAuth();

  const concept = store.getObject(conceptId);

  const conceptDefinition = concept.navigate(Instance_Of);
  const labelStartsWithFunctionalId = isInstanceOf(getConceptChipFields(store, concept)[0]?.field, IdField);

  const [tableConfig] = useSessionStorageState<TableConfiguration | undefined>(`${SessionStorageKeys.tableConfig}_${filterId}`, undefined);

  const { navigationFilters, navigationPath, globalFilters, filterDimensionId, globalParametersMapping = {} } = navigation.navigationFilters ?? {};

  const [filtersConfiguration] = useSessionStorageState<FilterConfiguration | undefined>(filterId, undefined);

  const filterFunctions: ((item: StoreObject) => boolean)[] = [];
  const navigationFilterFunction = getFilterFunction(store, navigationFilters);
  if (navigationFilterFunction) {
    filterFunctions.push((item) => navigationFilterFunction({
      [FILTER_PARAMETER_CURRENT]: { type: 'single', id: item.id },
      [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: loggedUserId },
    }));
  }
  const globalFilterFunction = getFilterFunction(store, globalFilters);
  if (globalFilterFunction) {
    filterFunctions
      .push((item) => globalFilterFunction(
        joinObjects(globalParametersMapping, {
          [filterDimensionId ?? FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: item.id },
          [FILTER_PARAMETER_LOGGED_USER]: { type: 'single' as const, id: loggedUserId },
        })
      ));
  } else {
    const conceptFilterFunction = getConceptFilterFunction(store, conceptDefinition.id, filtersConfiguration, loggedUserId);
    if (conceptFilterFunction) {
      filterFunctions.push(conceptFilterFunction);
    }
  }

  const [search, setSearch] = useDerivedState<string | undefined>(() => undefined, [filterId]);
  const extraSearchFilterFunction = searchFilterFunction(store, search, getSearchableFields(store, conceptDefinition.id));
  if (extraSearchFilterFunction !== undefined) {
    filterFunctions.push(extraSearchFilterFunction);
  }

  let instances = concept.navigate(Instance_Of)
    .navigateBack(Class_Instances)
    .filter(({ id }) => isConceptValid(store, id));
  if (navigationPath) {
    const resolution = createValuePathResolver(store, globalParametersMapping).resolvePathValue(navigationPath);
    if (isValidValuePathResolution(resolution)) {
      if (isSingleValueResolution(resolution) && isStoreObject(resolution.value)) {
        instances = [resolution.value];
      } else if (isMultiValueResolution(resolution) && resolution.values.every(isStoreObject)) {
        instances = resolution.values;
      }
    }
  }

  let groupByFieldId = globalParametersMapping[GROUP_BY_PARAMETER]?.id;
  const groupByOptions = listGroupByFieldOptions(store, conceptDefinition.id, true).map((chip) => chip.id);
  if (groupByFieldId === undefined || !groupByOptions.includes(groupByFieldId)) {
    groupByFieldId = getTableGroupByField(store, conceptDefinition.id, tableConfig)?.id;
  }

  const getLabelComparator = () => (
    (column: string, direction: TableSortDirection): ComparatorHandler<StoreObject, unknown> | undefined => {
      if (column === 'label') {
        return {
          comparator: comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString, direction === TableSortDirection.desc) as Comparator<unknown>,
          extractValue: (instance) => getInstanceLabel(store, instance),
        };
      } else {
        return getFieldColumnComparator(store)(column, direction);
      }
    }
  );

  const { generateGroupedList } = useFilterAndSort(
    filterId,
    instances,
    filterFunctions.length > 0 ? (item) => filterFunctions.every((filterFunction) => filterFunction(item)) : undefined,
    {
      getComparatorHandler: getLabelComparator(),
      initial: { key: 'label', direction: labelStartsWithFunctionalId ? TableSortDirection.desc : TableSortDirection.asc },
    },
    groupByFieldId ? getFieldGroupByHandler(store, groupByFieldId) : undefined,
    // Invalidate state everytime the store or the search is updated
    [store.getSerial(), search],
    executeAsyncTask
  );

  const { list, status } = generateGroupedList();
  const [debouncedStatus] = useDebounce(status, 100);

  return (
    <MasterDetailList
      list={
        list
          .map((entry) => {
            if (entry.type === 'group') {
              return entry;
            } else {
              return joinObjects(
                entry,
                {
                  item: undefined,
                  render: () => {
                    const label = getInstanceLabel(store, entry.item);
                    return (
                      <Tooltip title={formatOrUndef(label)}>
                        <Typo maxLine={1}>
                          {label}
                        </Typo>
                      </Tooltip>
                    );
                  },
                  to: () => (joinObjects(location, {
                    pathname: getConceptUrl(store, entry.item.key),
                  })),
                }
              );
            }
          })
      }
      search={{ value: search, setValue: setSearch }}
      loading={debouncedStatus === 'loading'}
    />
  );
}, LeftPanelLoader);

export default ConceptMasterDetail;
