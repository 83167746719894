import type { FunctionComponent } from 'react';
import type { ParametersMapping, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { FILTER_PARAMETER_LOGGED_USER } from 'yooi-modules/modules/conceptModule';
import type { DashboardParameterStoreObject } from 'yooi-modules/modules/dashboardModule';
import {
  Dashboard_Parameters,
  DashboardParameter_DefaultValue,
  DashboardParameter_EnableNotSetOption,
  DashboardParameter_Filters,
  DashboardParameter_Label,
  DashboardParameter_Rank,
  DashboardParameter_Type,
  DashboardParameter_UnsetLabel,
} from 'yooi-modules/modules/dashboardModule/ids';
import type { StoreObject } from 'yooi-store';
import { compareRank, extractAndCompareValue, joinObjects, ranker } from 'yooi-utils';
import useAuth from '../../../store/useAuth';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import type { SingleParameterInterface } from '../../_global/parameters/ParameterLine';
import ParameterLine from '../../_global/parameters/ParameterLine';
import { DashboardLayoutVariants, useDashboardLayoutMode } from '../../utils/useDashboardLayoutMode';

interface DashboardParameterLineProps {
  dashboardId: string,
  parametersMapping: ParametersMapping,
  selectParameter: (parameterId: string, instanceId: string | null) => void,
  shouldFocusParameterEditionOnMount: (parameterId: string) => boolean,
  readOnly?: boolean,
}

const DashboardParameterLine: FunctionComponent<DashboardParameterLineProps> = ({
  dashboardId,
  parametersMapping,
  selectParameter,
  shouldFocusParameterEditionOnMount,
  readOnly = false,
}) => {
  const store = useStore();
  const { loggedUserId } = useAuth();

  const mode = useDashboardLayoutMode();
  const isViewMode = mode === DashboardLayoutVariants.view;

  const dashboardParameters = ranker.decorateList(
    store.getObject(dashboardId)
      .navigateBack<DashboardParameterStoreObject>(Dashboard_Parameters)
      .sort(extractAndCompareValue((parameter: StoreObject) => parameter[DashboardParameter_Rank] as string, compareRank)),
    (parameter) => parameter[DashboardParameter_Rank] as string
  );

  return (
    <ParameterLine
      parameterInterfaces={
        dashboardParameters.map(({ item: dashboardParameter, moveUpRank, moveDownRank }, index, { length: numberOfParameters }): SingleParameterInterface => {
          const currentSelectedInstanceId = parametersMapping[dashboardParameter.id]?.id;
          const selectedInstance = currentSelectedInstanceId ? store.getObjectOrNull(currentSelectedInstanceId) : undefined;
          const isDefaultValueFilterParameter = dashboardParameter[DashboardParameter_DefaultValue] === FILTER_PARAMETER_LOGGED_USER;
          const isSelectedInstanceFilterParameter = currentSelectedInstanceId === FILTER_PARAMETER_LOGGED_USER;
          const parameterData = {
            id: dashboardParameter.id,
            selectedInstanceId: isSelectedInstanceFilterParameter ? currentSelectedInstanceId : selectedInstance?.id,
            type: 'parameter' as const,
            typeId: dashboardParameter.navigateOrNull(DashboardParameter_Type)?.id,
            label: dashboardParameter[DashboardParameter_Label],
            unsetLabel: dashboardParameter[DashboardParameter_UnsetLabel],
            filters: dashboardParameter[DashboardParameter_Filters],
            defaultInstanceId: isDefaultValueFilterParameter ? dashboardParameter[DashboardParameter_DefaultValue] as string
              : dashboardParameter.navigateOrNull(DashboardParameter_DefaultValue)?.id,
            enableNotSetOption: dashboardParameter[DashboardParameter_EnableNotSetOption],
          };

          return {
            type: 'parameter',
            parameterData,
            handleSubmit: (data) => {
              const { selectedInstanceId } = data;
              if (parameterData.selectedInstanceId !== selectedInstanceId) {
                selectParameter(dashboardParameter.id, selectedInstanceId ?? null);
              }
              if (!readOnly && !isViewMode) {
                const { unsetLabel, label, filters, enableNotSetOption, typeId, defaultInstanceId } = data;
                if (parameterData.typeId !== typeId) {
                  store.updateObject(dashboardParameter.id, { [DashboardParameter_Type]: typeId });
                }
                if (parameterData.label !== label) {
                  store.updateObject(dashboardParameter.id, { [DashboardParameter_Label]: label });
                }
                if (parameterData.unsetLabel !== unsetLabel) {
                  store.updateObject(dashboardParameter.id, { [DashboardParameter_UnsetLabel]: unsetLabel });
                }
                if (parameterData.enableNotSetOption !== enableNotSetOption) {
                  store.updateObject(dashboardParameter.id, { [DashboardParameter_EnableNotSetOption]: enableNotSetOption });
                }
                if (JSON.stringify(parameterData.filters) !== JSON.stringify(filters)) {
                  store.updateObject(dashboardParameter.id, {
                    [DashboardParameter_Filters]: filters,
                  });
                }
                if (parameterData.defaultInstanceId !== defaultInstanceId) {
                  store.updateObject(dashboardParameter.id, {
                    [DashboardParameter_DefaultValue]: defaultInstanceId ?? null,
                  });
                }
              }
            },
            onMoveLeft: index === 0 ? undefined : () => {
              store.updateObject(dashboardParameter.id, { [DashboardParameter_Rank]: moveUpRank() });
            },
            onMoveRight: index === numberOfParameters - 1 ? undefined : () => {
              store.updateObject(dashboardParameter.id, { [DashboardParameter_Rank]: moveDownRank() });
            },
            onDelete: () => store.deleteObject(dashboardParameter.id),
            readOnly: readOnly || isViewMode,
            focusOnMount: shouldFocusParameterEditionOnMount(dashboardParameter.id),
            parameterDefinitions: dashboardParameters
              .filter(({ item: parameterDefinition }) => Boolean(parameterDefinition.navigateOrNull(DashboardParameter_Type)?.id))
              .map(({ item: parameterDefinition }): SingleParameterDefinition => ({
                id: parameterDefinition.id,
                type: 'parameter' as const,
                typeId: parameterDefinition.navigate(DashboardParameter_Type).id,
                label: parameterDefinition[DashboardParameter_Label] ?? i18n`Parameter`,
              })),
            parametersMapping: joinObjects(parametersMapping, {
              [FILTER_PARAMETER_LOGGED_USER]: { type: 'single' as const, id: loggedUserId },
            }),
          };
        })
      }
    />
  );
};

export default DashboardParameterLine;
