import type { ObjectStore, ObjectStoreReadOnly, ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import { base64, compareNumber, compareProperty, compareRank, compareString, extractAndCompareValue, isFiniteNumber } from 'yooi-utils';
import type { AutomationRuleStoreObject } from '../../automationModule';
import { AutomationRule, AutomationRule_Name } from '../../automationModule/ids';
import type { IntentionStoreObject } from '../../collaborationModule';
import { Intention, Intention_Name } from '../../collaborationModule/ids';
import type { FieldBlockDisplayStoreObject, TextConstantFieldStoreObject } from '../../conceptLayoutModule';
import {
  FieldBlockDisplay,
  FieldBlockDisplay_FieldPath,
  FieldRelevantFieldDisplay,
  FieldRelevantFieldDisplay_Rank,
  FieldRelevantFieldDisplay_Role_ConceptDefinition,
  FieldRelevantFieldDisplay_Role_Field,
  TextConstantField,
  TextConstantField_Text,
} from '../../conceptLayoutModule/ids';
import type { WidgetStoreObject } from '../../dashboardModule';
import { Widget, Widget_Title } from '../../dashboardModule/ids';
import type { DataAssetTypeStoreObject } from '../../dataAssetModule';
import { DataAssetType, DataAssetType_Name } from '../../dataAssetModule/ids';
import type { IntegrationStoreObject } from '../../integrationModule';
import { Integration, Integration_Name } from '../../integrationModule/ids';
import type { ResourceTypeStoreObject } from '../../resourceModule';
import { ResourceType, ResourceType_Name } from '../../resourceModule/ids';
import { isInstanceOf } from '../../typeModule';
import { Instance_Of } from '../../typeModule/ids';
import { formatOrUndef } from '../common/commonFieldUtils';
import { buildFunctionalIdFormatter } from '../fields/idField/utils';
import {
  AssociationField,
  AssociationField_Definition,
  AssociationFieldDefinition_Fields,
  Concept,
  ConceptChipDisplay,
  ConceptChipDisplay_Rank,
  ConceptChipDisplay_Role_ConceptDefinition,
  ConceptChipDisplay_Role_Field,
  ConceptDefinition_ChipFields,
  EmbeddingField,
  Field,
  Field_ApiAlias,
  Field_FieldDimensions,
  Field_Title,
  FieldDimension,
  FieldDimension_Field,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  IdField,
  KinshipRelation,
  RelationMultipleField,
  RelationMultipleField_ReverseField,
  RelationSingleField,
  ReverseWorkflowField,
  ReverseWorkflowField_ReverseField,
  Workflow,
  Workflow_Name,
} from '../ids';
import type {
  AssociationFieldDefinitionStoreObject,
  AssociationFieldStoreObject,
  ConceptStoreObject,
  EmbeddingFieldStoreObject,
  FieldDimensionRaw,
  FieldDimensionStoreObject,
  FieldStoreObject,
  IdFieldStoreObject,
  RelationMultipleFieldStoreObject,
  RelationSingleFieldStoreObject,
  ReverseWorkflowFieldStoreObject,
  WorkflowFieldStoreObject,
  WorkflowStoreObject,
} from '../model';
import { getFieldUtilsHandler } from './fieldUtilsHandler';
import { getPathLastFieldInformation } from './path/pathUtils';
import { isConceptValid } from './utils';

export const decodeExternalKey = (key?: string): string => base64.decode(key ?? '');
export const encodeExternalKey = (key?: string): string => base64.encode(key ?? '');

interface ConceptChipField {
  id: string,
  field: StoreObject,
  rank: string,
}

export const getConceptDefinitionValidFields = (store: ObjectStoreReadOnly, conceptDefinitionId: string): FieldStoreObject[] => (
  Array.from(
    new Set(
      store.withAssociation(FieldDimensionTypes)
        .withRole(FieldDimensionTypes_Role_ConceptDefinition, conceptDefinitionId)
        .list()
        .map((fieldDimensionTypes) => fieldDimensionTypes.navigateRole<FieldDimensionStoreObject>(FieldDimensionTypes_Role_FieldDimension)[FieldDimension_Field])
    )
  )
    .map((fieldId) => store.getObject(fieldId))
);

export const getFieldDimensionOfModelType = (store: ObjectStoreReadOnly, fieldId: string, modelTypeId: string): string | undefined => (
  store.getObject(fieldId)
    .navigateBack<FieldDimensionStoreObject>(Field_FieldDimensions)
    .filter((fieldDimension) => (
      store.withAssociation(FieldDimensionTypes)
        .withRole(FieldDimensionTypes_Role_FieldDimension, fieldDimension.id)
        .withRole(FieldDimensionTypes_Role_ConceptDefinition, modelTypeId)
        .getObjectOrNull()
    ))
    .at(0)
    ?.id
);

export const isConceptDefinitionField = (store: ObjectStoreReadOnly, fieldId: string, conceptDefinitionId: string): boolean => (
  store.getObject(fieldId)
    .navigateBack<FieldDimensionStoreObject>(Field_FieldDimensions)
    .some((fieldDimension) => Boolean(
      store.withAssociation(FieldDimensionTypes)
        .withRole(FieldDimensionTypes_Role_FieldDimension, fieldDimension.id)
        .withRole(FieldDimensionTypes_Role_ConceptDefinition, conceptDefinitionId)
        .getObjectOrNull()
    ))
);

export const getAllFieldDimensionsIds = (store: ObjectStoreWithTimeseries, fieldId: string): string[] => (
  store.getObject(fieldId)
    .navigateBack(Field_FieldDimensions)
    .map(({ id }) => id)
);

export const getAllFieldDimensionsLinkedConceptDefinitionIds = (store: ObjectStoreReadOnly, fieldId: string): string[] => (
  Array.from(
    new Set(
      store.getObject(fieldId)
        .navigateBack<FieldDimensionStoreObject>(Field_FieldDimensions)
        .flatMap((fieldDimension) => (
          store.withAssociation(FieldDimensionTypes)
            .withRole(FieldDimensionTypes_Role_FieldDimension, fieldDimension.id)
            .list()
            .map((assoc) => assoc.role(FieldDimensionTypes_Role_ConceptDefinition))
        ))
    )
  )
);

export const getConceptDefinitionChipFieldsInternal = (store: ObjectStoreReadOnly, conceptDefinitionId: string): ConceptChipField[] => (
  store.withAssociation(ConceptChipDisplay)
    .withRole(ConceptChipDisplay_Role_ConceptDefinition, conceptDefinitionId)
    .list()
    .map((assoc) => ({
      id: assoc.role(ConceptChipDisplay_Role_Field),
      field: assoc.navigateRole(ConceptChipDisplay_Role_Field),
      rank: assoc.object[ConceptChipDisplay_Rank] as string,
    }))
    .sort(compareProperty('rank', compareRank))
);

export const getConceptDefinitionChipFields = (store: ObjectStoreWithTimeseries, conceptDefinitionId: string): ConceptChipField[] => {
  const cachedValue = store.getObjectOrNull(conceptDefinitionId)?.[ConceptDefinition_ChipFields] as ConceptChipField[] | undefined;
  if (cachedValue !== undefined) {
    return cachedValue;
  } else {
    return getConceptDefinitionChipFieldsInternal(store, conceptDefinitionId);
  }
};

export const getConceptChipFields = (store: ObjectStoreWithTimeseries, concept: StoreObject): ConceptChipField[] => {
  if (!concept || !isConceptValid(store, concept.id)) {
    return [];
  } else {
    return getConceptDefinitionChipFields(store, concept[Instance_Of] as string);
  }
};

export const displayInstanceFieldAsText = (store: ObjectStoreWithTimeseries, instanceId: string, fieldId: string): string | undefined => {
  const instance = store.getObjectOrNull(instanceId);
  const dimension = instance ? getFieldDimensionOfModelType(store, fieldId, instance[Instance_Of] as string) : undefined;
  return formatOrUndef(getFieldUtilsHandler(store, fieldId)
    ?.getValueAsText?.(dimension ? { [dimension]: instanceId } : {}));
};

export const getRawInstanceLabel = (store: ObjectStoreWithTimeseries, instance: StoreObject, withId = true): (string | undefined)[] => {
  if (isInstanceOf<ConceptStoreObject>(instance, Concept)) {
    return getConceptChipFields(store, instance)
      .flatMap(({ field }) => {
        if (isInstanceOf<TextConstantFieldStoreObject>(field, TextConstantField)) {
          return [field[TextConstantField_Text]];
        } else if (isInstanceOf<IdFieldStoreObject>(field, IdField)) {
          if (withId) {
            return [buildFunctionalIdFormatter(store, instance[Instance_Of], field.id)(instance[field.id] as string | undefined)];
          }
          return [];
        } else {
          return [displayInstanceFieldAsText(store, instance.id, field.id)];
        }
      });
  } else if (isInstanceOf<DataAssetTypeStoreObject>(instance, DataAssetType)) {
    return [instance[DataAssetType_Name]];
  } else if (isInstanceOf<ResourceTypeStoreObject>(instance, ResourceType)) {
    return [instance[ResourceType_Name]];
  } else if (isInstanceOf<FieldStoreObject>(instance, Field)) {
    return [instance[Field_Title]];
  } else if (isInstanceOf<WidgetStoreObject>(instance, Widget)) {
    return [instance[Widget_Title]];
  } else if (isInstanceOf<IntegrationStoreObject>(instance, Integration)) {
    return [instance[Integration_Name]];
  } else if (isInstanceOf<WorkflowStoreObject>(instance, Workflow)) {
    return [instance[Workflow_Name]];
  } else if (isInstanceOf<AutomationRuleStoreObject>(instance, AutomationRule)) {
    return [instance[AutomationRule_Name]];
  } else if (isInstanceOf<IntentionStoreObject>(instance, Intention)) {
    return [instance[Intention_Name]];
  } else if (isInstanceOf<FieldBlockDisplayStoreObject>(instance, FieldBlockDisplay)) {
    const lastFieldInfo = instance[FieldBlockDisplay_FieldPath] ? getPathLastFieldInformation(instance[FieldBlockDisplay_FieldPath]) : undefined;
    if (lastFieldInfo?.fieldId) {
      const field = store.getObjectOrNull(lastFieldInfo.fieldId);
      return [`${formatOrUndef(field?.[Field_Title] as string | undefined)} - Display Options`];
    } else {
      return [`${formatOrUndef(undefined)} - Display Options`];
    }
  } else {
    return [];
  }
};

export const getInstanceLabel = (store: ObjectStoreWithTimeseries, instance: StoreObject, withId = true): string | undefined => {
  const rawInstanceLabel = getRawInstanceLabel(store, instance, withId);
  return rawInstanceLabel.every((element) => element === undefined) ? undefined : rawInstanceLabel.map(formatOrUndef).join(' - ');
};

export const getInstanceLabelOrUndefined = (store: ObjectStoreWithTimeseries, instance: StoreObject, withId = true): string => (
  formatOrUndef(getInstanceLabel(store, instance, withId))
);

export const getValueProxy = (store: ObjectStoreWithTimeseries, instanceId: string, fieldIdOrFieldAPIAlias: string): object | string | undefined => {
  const instance = store.getObjectOrNull(instanceId);
  if (instance) {
    // prop is either a field id OR a field api alias
    let field = store.getObjectOrNull(fieldIdOrFieldAPIAlias);
    if (!isInstanceOf(field, Field)) {
      [field] = getConceptDefinitionValidFields(store, instance[Instance_Of] as string)
        .filter((f) => f[Field_ApiAlias] === fieldIdOrFieldAPIAlias);
    }
    if (!field) {
      if (fieldIdOrFieldAPIAlias === 'id') {
        return instanceId;
      } else {
        return undefined;
      }
    } else {
      const fieldUtil = getFieldUtilsHandler(store, field.id);
      const dimension = getFieldDimensionOfModelType(store, field.id, instance[Instance_Of] as string);
      const fieldObject = fieldUtil.getValueProxy?.(dimension ? { [dimension]: instance.id } : {});
      if (!fieldObject) {
        return undefined;
      } else {
        return fieldObject;
      }
    }
  } else {
    return undefined;
  }
};

const getConceptInstanceProxyHandler = (store: ObjectStoreWithTimeseries, instanceId: string, prop: string | symbol): unknown => {
  const instance = store.getObjectOrNull(instanceId);
  if (!instance) {
    return undefined;
  } else if (prop === 'toString' || prop === Symbol.toStringTag) {
    return () => getInstanceLabelOrUndefined(store, instance);
  } else if (!!prop && typeof prop === 'string') {
    return getValueProxy(store, instance.id, prop);
  } else {
    return undefined;
  }
};

export const getConceptInstanceProxy = (store: ObjectStoreWithTimeseries, instanceId: string): object => (
  new Proxy({}, {
    get(_, prop) {
      return getConceptInstanceProxyHandler(store, instanceId, prop);
    },
  })
);

export const getReverseAssociationField = (field: StoreObject): AssociationFieldStoreObject | undefined => (
  field.navigate<AssociationFieldDefinitionStoreObject>(AssociationField_Definition)
    .navigateBack<AssociationFieldStoreObject>(AssociationFieldDefinition_Fields)
    .find(({ id }) => id !== field.id)
);

export const getReverseField = (store: ObjectStoreReadOnly, fieldId: string, instanceId: string | undefined): FieldStoreObject | undefined => {
  const field = store.getObject(fieldId);

  if (isInstanceOf<ReverseWorkflowFieldStoreObject>(field, ReverseWorkflowField)) {
    return field.navigate<WorkflowFieldStoreObject>(ReverseWorkflowField_ReverseField);
  } else if (isInstanceOf<AssociationFieldStoreObject>(field, AssociationField)) {
    return getReverseAssociationField(field);
  } else if (isInstanceOf<RelationSingleFieldStoreObject>(field, RelationSingleField)) {
    return field.navigateBack<FieldStoreObject>(RelationMultipleField_ReverseField)[0];
  } else if (isInstanceOf<RelationMultipleFieldStoreObject>(field, RelationMultipleField)) {
    return field.navigate<FieldStoreObject>(RelationMultipleField_ReverseField);
  } else if (isInstanceOf<EmbeddingFieldStoreObject>(field, EmbeddingField)) {
    return store.getObject<FieldStoreObject>(KinshipRelation);
  } else if (field.id === KinshipRelation) {
    if (!instanceId) {
      return undefined;
    } else {
      const instance = store.getObject(instanceId);
      return store.getObject<FieldStoreObject>(instance[KinshipRelation] as string);
    }
  } else {
    return undefined;
  }
};

export const handleProxyArrayProps = <Item extends object, TransformedItem extends object>(
  prop: string | symbol,
  array: Item[],
  transform: (item: Item) => TransformedItem
): TransformedItem | number | undefined => {
  if (prop === 'length') {
    return array[prop];
  } else if (isFiniteNumber(prop)) {
    return transform(array[Number(prop)]);
  } else {
    return undefined;
  }
};

export const addField = ({ createObject, withAssociation }: ObjectStore, conceptId: string, properties: Record<string, unknown>): string => {
  const fieldId = createObject(properties);
  const dimensionId = createObject<FieldDimensionRaw>({ [Instance_Of]: FieldDimension, [FieldDimension_Field]: fieldId });

  withAssociation(FieldDimensionTypes)
    .withRole(FieldDimensionTypes_Role_FieldDimension, dimensionId)
    .withRole(FieldDimensionTypes_Role_ConceptDefinition, conceptId)
    .updateObject({});
  return fieldId;
};

export const getColorByValue = (
  value: number | undefined,
  min: { value: number, color: string | undefined } | undefined,
  ticks: { value: number, color: string | undefined }[] | undefined,
  max: { value: number, color: string | undefined } | undefined
): string | undefined => {
  if (value === undefined) {
    return undefined;
  }

  let color: string | undefined;
  if (min && value >= min.value) {
    color = min.color;
  }

  ticks
    ?.sort(compareProperty('value', compareNumber))
    .forEach((tick) => {
      if (value >= tick.value) {
        color = tick.color;
      }
    });

  if (max && value === max.value) {
    color = max.color;
  }

  return color;
};

export const getFields = (store: ObjectStoreWithTimeseries, conceptDefinitionId: string, allowFieldTypeIds: string[]): FieldStoreObject[] => (
  getConceptDefinitionValidFields(store, conceptDefinitionId)
    .filter((field) => allowFieldTypeIds.includes(field[Instance_Of]))
);
export const getRelevantFields = (store: ObjectStoreWithTimeseries, conceptDefinitionId: string, allowFieldTypeIds: string[]): FieldStoreObject[] => {
  const relevantFields = store.withAssociation(FieldRelevantFieldDisplay)
    .withRole(FieldRelevantFieldDisplay_Role_ConceptDefinition, conceptDefinitionId)
    .list()
    .sort(extractAndCompareValue(({ object }) => object[FieldRelevantFieldDisplay_Rank] as string, compareRank))
    .map((fieldRelevantFieldDisplay) => fieldRelevantFieldDisplay.navigateRole<FieldStoreObject>(FieldRelevantFieldDisplay_Role_Field))
    .filter((field) => allowFieldTypeIds.includes(field[Instance_Of]));

  if (relevantFields.length > 0) {
    return relevantFields;
  } else {
    return getFields(store, conceptDefinitionId, allowFieldTypeIds)
      .sort(compareProperty('label', compareString));
  }
};
