import type { ComponentProps, FunctionComponent } from 'react';
import type { IconName } from '../../../components/atoms/Icon';
import ColoredIconPicker from '../../../components/inputs/ColoredIconPicker';
import StoreInput from './StoreInput';

interface StoreColoredIconPickerProps extends Omit<ComponentProps<typeof ColoredIconPicker>, 'value' | 'onChange' | 'onSubmit' | 'onCancel'> {
  initialValue: { icon: IconName, color: string } | undefined,
  onSubmit: (value: { icon: IconName, color: string } | null) => void,
}

const StoreColoredIconPicker: FunctionComponent<StoreColoredIconPickerProps> = ({ initialValue, onSubmit, ...coloredIconPickerProps }) => (
  <StoreInput initialValue={initialValue} onSubmit={onSubmit}>
    {(formInputProps) => (
      <ColoredIconPicker
        {...coloredIconPickerProps}
        {...formInputProps}
      />
    )}
  </StoreInput>
);

export default StoreColoredIconPicker;
