import type { FunctionComponent, ReactElement } from 'react';
import type { ConnectDragSource } from 'react-dnd';
import { useDrag } from 'react-dnd';
import type { FrontFilters } from '../filterUtils';
import { useDragAndDropContext, useNotifyOnDragEndBus } from './DragAndDropManager';
import DragItemPlaceholder from './DragItemPlaceholder';

interface DragItemProps {
  item: FrontFilters,
  children: (props: { connectDragSourceRef: ConnectDragSource }) => ReactElement,
}

const DragItem: FunctionComponent<DragItemProps> = ({ item, children }) => {
  const context = useDragAndDropContext();
  const notifyOnDragEnd = useNotifyOnDragEndBus();

  const [, connectDragSource] = useDrag(() => ({
    type: 'Node',
    item,
    end: (draggedItem) => {
      notifyOnDragEnd({ itemId: draggedItem.id });
    },
  }), [item, notifyOnDragEnd]);

  if (context?.draggingItemId === item.id && context?.hoverState === undefined) {
    return (<DragItemPlaceholder />);
  } else if (context?.draggingItemId === item.id && context?.hoverState) {
    return null;
  } else {
    return children({ connectDragSourceRef: connectDragSource });
  }
};

export default DragItem;
