import classnames from 'classnames';
import type { JssStyle } from 'jss';
import type { FunctionComponent, MouseEventHandler, ReactNode } from 'react';
import { joinObjects } from 'yooi-utils';
import { ButtonVariant } from '../../components/atoms/Button';
import Typo, { TypoVariant } from '../../components/atoms/Typo';
import base from '../../theme/base';
import makeStyles from '../../utils/makeStyles';

const buildVariantStyles = <VariantName extends ButtonVariant>(
  { variantName, normal, hover, focus = {}, pressed = {} }: { variantName: VariantName, normal: JssStyle, hover: JssStyle, focus?: JssStyle, pressed?: JssStyle }
): Record<VariantName | `${VariantName}_hover` | `${VariantName}_focus` | `${VariantName}_pressed`, JssStyle> => {
  const styles: JssStyle = joinObjects(
    {
      '&:hover': hover,
      '&:focus-visible': focus,
      '&:active': pressed,
    },
    normal
  );

  return {
    [variantName]: styles,
    [`${variantName}_hover`]: joinObjects(styles, hover),
    [`${variantName}_focus`]: joinObjects(styles, focus),
    [`${variantName}_pressed`]: joinObjects(styles, pressed),
  } as Record<VariantName | `${VariantName}_hover` | `${VariantName}_focus` | `${VariantName}_pressed`, JssStyle>;
};

const useStyles = makeStyles((theme) => (joinObjects(
  {
    button: {
      width: '100%',
      padding: '0.9rem 1.8rem',
      borderRadius: base.borderRadius.medium,
      borderWidth: '0.1rem',
      outline: 0,
      borderStyle: 'solid',
      borderColor: theme.color.transparent,
      boxShadow: base.button.boxShadow,
      cursor: 'pointer',
      '&:disabled': {
        cursor: 'auto',
      },
    },
  },
  buildVariantStyles({
    variantName: ButtonVariant.primary,
    normal: {
      backgroundColor: theme.color.background.primary.default,
      color: theme.color.text.white,

      '&:disabled': {
        opacity: base.opacity.twenty,
      },
    },
    hover: {
      backgroundColor: theme.color.background.primary.hover,
    },
    focus: {
      backgroundColor: theme.color.background.primary.pressed,
      borderColor: theme.color.transparent,
    },
    pressed: {
      backgroundColor: theme.color.background.primary.pressed,
    },
  }),
  buildVariantStyles({
    variantName: ButtonVariant.tertiary,
    normal: {
      backgroundColor: theme.color.transparent,
      boxShadow: 'none',
      color: theme.color.text.brand,

      '&:disabled': {
        opacity: base.opacity.twenty,
      },
    },
    hover: {
      backgroundColor: theme.color.background.primarylight.default,
    },
    focus: {
      backgroundColor: theme.color.background.primarylight.default,
    },
    pressed: {
      backgroundColor: theme.color.background.primarylight.hover,
    },
  }),
  {
    buttonContainer: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
    },
  }
)), 'loginButton');

interface LoginButtonProps {
  title?: string,
  variant?: ButtonVariant.primary | ButtonVariant.tertiary,
  disabled?: boolean,
  onClick?: MouseEventHandler<HTMLButtonElement>,
  renderIcon?: () => ReactNode,
}

const LoginButton: FunctionComponent<LoginButtonProps> = ({ title, variant = ButtonVariant.primary, disabled, onClick, renderIcon }) => {
  const classes = useStyles();

  return (
    <button
      className={classnames(classes.button, classes[variant])}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      {renderIcon?.()}
      <span className={classes.buttonContainer}>
        <Typo variant={TypoVariant.buttonMain}>{title}</Typo>
      </span>
    </button>
  );
};

export default LoginButton;
