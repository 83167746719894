import { isFiniteNumber, newError } from 'yooi-utils';
import type { InitModuleFunction, ModuleRegistration } from './PlatformModulesTypes';

type LogError = (message: string, data?: Record<string, unknown>) => void;

export interface Logger {
  logError: LogError,
}

export interface InitializedModule extends ModuleRegistration {
  name: string,
}

export interface InitializedModules {
  modules: InitializedModule[],
  platformInitializationModule: InitializedModule,
}

type ModuleDeclarations = Record<string, { initModule: InitModuleFunction }>;
type DoInitModules = (modules: ModuleDeclarations, platformInitializationModuleId: string, { logError }: Logger) => InitializedModules;

export const doInitModules: DoInitModules = (modules, platformInitializationModuleId, { logError }) => {
  const modulesMissingInitModule = Object.entries(modules).filter(([, module]) => !module.initModule).map(([name]) => name);
  if (modulesMissingInitModule.length > 0) {
    logError('Some modules doesn\'t export the initModule method', { modules: modulesMissingInitModule });
    throw newError('Some modules doesn\'t export the initModule method', { modulesMissingInitModule });
  }

  const initializedModules: InitializedModule[] = Object.entries(modules).map(([name, module]) => ({ ...module.initModule(), name }));

  const modulesWithoutIds = initializedModules.filter((module) => !module.id).map((module) => module.name);
  if (modulesWithoutIds.length > 0) {
    logError('Some modules doesn\'t provide any ids', { modules: modulesWithoutIds });
    throw newError('Some modules doesn\'t provide any ids', { modulesWithoutIds });
  }

  const moduleNamePerIds = initializedModules.reduce((accumulator, module) => {
    accumulator[module.id] = [...(accumulator[module.id] ?? []), module.name];
    return accumulator;
  }, {} as Record<string, string[]>);
  if (Object.keys(moduleNamePerIds).length !== initializedModules.length) {
    const modulesWithDuplicatedIds = Object.entries(moduleNamePerIds).filter(([, names]) => names.length > 1).flatMap(([, name]) => name);
    logError('Some modules share the same id', { modules: modulesWithDuplicatedIds });
    throw newError('Some modules share the same id', { modulesWithDuplicatedIds });
  }

  const modulesWithInvalidMigrations = initializedModules
    .filter((module) => module.migrations && Object.entries(module.migrations).some(([rawId, migration]) => {
      const id = parseInt(rawId, 10);
      if (!isFiniteNumber(id)) {
        logError('Modules has an invalid migration id', { moduleId: module.id, moduleName: module.name, migrationId: rawId });
        return true;
      } else if (!migration.handler) {
        logError('Migration is missing an handler', { moduleId: module.id, moduleName: module.name, migrationId: id });
        return true;
      }
      return false;
    }));
  if (modulesWithInvalidMigrations.length > 0) {
    throw newError('Some modules have invalid migrations', { modulesWithInvalidMigrations: modulesWithInvalidMigrations.map((module) => module.name) });
  }

  const modulesWithMigrations = initializedModules.filter((module) => module.migrationHelpers);
  if (!modulesWithMigrations?.flatMap) {
    throw newError('Missing modulesWithMigrations.flatMap', {
      modulesWithMigrations: modulesWithMigrations ?? null,
      'modulesWithMigrations.flatMap': typeof modulesWithMigrations?.flatMap,
    });
  }
  const moduleHelperNames = modulesWithMigrations.flatMap((module) => (module.migrationHelpers ? Object.keys(module.migrationHelpers) : []));

  const uniqueModuleHelperNames = [...new Set(moduleHelperNames)];
  if (moduleHelperNames.length !== uniqueModuleHelperNames.length) {
    const duplicatedNames = moduleHelperNames.filter((name, id) => moduleHelperNames.indexOf(name) !== id);
    throw newError('Multiple modules declared the same helper name', { duplicatedNames });
  }

  const platformInitializationModule = initializedModules.find(({ id }) => id === platformInitializationModuleId);

  if (!platformInitializationModule) {
    throw newError('Missing platform initialization module declaration');
  }

  return {
    modules: initializedModules.filter(({ id }) => id !== platformInitializationModuleId),
    platformInitializationModule,
  };
};
