import { compareString } from '../../comparators';
import { InputNotFoundError, TranspilerEngineNotAvailableError } from './errors';
import type { ComputeFormula, Constant, EngineContext, FormulaNode, FormulaType, InputSet, Variable } from './formula';
import type { FormulaVisitorProcessor } from './formulaVisitor';

const contextVar = 'c';
const inputTableVar = 'i';
const functionTableVar = 'f';

const isTranspilerAvailable = (() => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    Function('return 0')();
    return true;
  } catch {
    return false;
  }
})();

const createComputeFromTranspilation = <C>({ code, inputTable, functionTable }: FormulaNode<C>['transpilation']): (context: C) => unknown => {
  if (inputTable.length > 0 && functionTable.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    const compute = Function(inputTableVar, functionTableVar, contextVar, `return ${code};`) as (
      inputTable: ((context: C) => unknown)[],
      functionTable: CallableFunction[],
      context: C
    ) => unknown;

    return (context: C) => compute(inputTable, functionTable, context);
  } else if (inputTable.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    const compute = Function(inputTableVar, contextVar, `return ${code};`) as (
      inputTable: ((context: C) => unknown)[],
      context: C
    ) => unknown;

    return (context: C) => compute(inputTable, context);
  } else if (functionTable.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    const compute = Function(functionTableVar, `return ${code};`) as (
      functionTable: CallableFunction[],
    ) => unknown;

    return () => compute(functionTable);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    return Function(`return ${code};`) as () => unknown;
  }
};

const handleResult = (r: unknown) => {
  if (typeof r === 'number') {
    return r + 0; // handle -0 -> 0
  } else {
    return r;
  }
};

const enrichWithCreateCompute = <C>(node: Omit<FormulaNode<C>, 'createCompute'>) => {
  const createCompute = (useTranspiler: 'auto' | 'always' | 'never' = 'auto') => {
    if (useTranspiler === 'always' && !isTranspilerAvailable) {
      throw new TranspilerEngineNotAvailableError();
    }
    const compute = (useTranspiler !== 'never' && isTranspilerAvailable) ? createComputeFromTranspilation(node.transpilation) : node.compute;
    return (context: C) => handleResult(compute(context));
  };
  return { ...node, createCompute };
};

const merge = (lists: string[][]) => [...new Set(Array.prototype.concat(...lists))].sort(compareString);

export const createResolverFactory: {
  (context: EngineContext): {
    <C>(inputSet: InputSet<C>): FormulaVisitorProcessor<FormulaNode<C> | Error>,
  },
} = ({
  functionLibrary,
  typeSystem: {
    textType,
    numberType,
    arrayTypeFrom,
  },
}) => <C>(inputSet: InputSet<C>): FormulaVisitorProcessor<FormulaNode<C> | Error> => {
  const functionTable: CallableFunction[] = [];
  const functionMapping = new Map<CallableFunction, number>();
  const createFunctionMapping = (jsFunction: CallableFunction) => {
    const index = functionMapping.size;
    functionTable.push(jsFunction);
    functionMapping.set(jsFunction, index);
    return index;
  };

  const inputTable: ((context: C) => unknown)[] = [];
  const inputMapping = new Map<string, number>();
  const createInputMapping = (name: string, resolve: (context: C) => unknown) => {
    const index = inputMapping.size;
    inputTable.push(resolve);
    inputMapping.set(name, index);
    return index;
  };

  const fromPrimitive = (type: FormulaType, value: unknown) => ({
    type,
    compute: () => value,
    transpilation: {
      code: JSON.stringify(value) ?? 'undefined',
      functionTable,
      inputTable,
    },
    referencedInputNames: [],
    referencedFunctionNames: [],
    isDeterminist: true,
    children: [],
  });

  const transpileInput = (name: string, resolve: (context: C) => unknown) => {
    const inputIndex = inputMapping.get(name) ?? createInputMapping(name, resolve);
    return `${inputTableVar}[${inputIndex}](${contextVar})`;
  };

  const transpileConstantInput = (name: string, value: unknown) => {
    switch (typeof value) {
      case 'undefined':
        return 'undefined';
      case 'string':
      case 'number':
      case 'boolean':
        return JSON.stringify(value);
      default:
        return transpileInput(name, () => value);
    }
  };

  const fromInput = (name: string, type: FormulaType, jsFunction: (context: C) => unknown, transpiled: string) => ({
    type,
    compute: jsFunction,
    transpilation: {
      code: transpiled,
      functionTable,
      inputTable,
    },
    referencedInputNames: [name],
    referencedFunctionNames: [],
    isDeterminist: true,
    children: [],
  });

  const fromConstantInput = ({ name, type, value }: Constant): FormulaNode<C> => enrichWithCreateCompute(fromInput(name, type, () => value, transpileConstantInput(name, value)));
  const fromVariableInput = ({ name, type, resolve }: Variable<C>): FormulaNode<C> => enrichWithCreateCompute(fromInput(name, type, resolve, transpileInput(name, resolve)));

  const resolveTypeFromChildren = <T>(processor: (types: FormulaType[]) => T | Error, children: (FormulaNode<C> | Error)[]) => {
    const error = children.find((child) => child instanceof Error);
    if (error) {
      return error;
    } else {
      const childrenNodes = children as FormulaNode<C>[]; // it's ensured that there is no Error in the types array
      const resolution = processor(childrenNodes.map(({ type: childrenType }) => childrenType));
      if (resolution instanceof Error) {
        return resolution;
      } else {
        return { resolution, childrenNodes };
      }
    }
  };

  return {
    visitText: (value) => enrichWithCreateCompute(fromPrimitive(textType, value)),
    visitNumber: (value) => enrichWithCreateCompute(fromPrimitive(numberType, value)),
    visitInput: (inputName) => {
      const input = inputSet.getInput(inputName);
      if (input) {
        return input.kind === 'constant' ? fromConstantInput(input) : fromVariableInput(input);
      } else {
        return new InputNotFoundError(inputName);
      }
    },
    visitExpressionGroup: (expression) => {
      if (expression instanceof Error) {
        return expression;
      } else {
        const { transpilation: { code, ...transpilation }, ...node } = expression;
        return {
          ...node,
          transpilation: {
            code: `(${code})`,
            ...transpilation,
          },
        };
      }
    },
    visitArray: (elements) => {
      const typeResolution = resolveTypeFromChildren((types) => arrayTypeFrom(types), elements);
      if (typeResolution instanceof Error) {
        return typeResolution;
      } else {
        const { resolution, childrenNodes } = typeResolution;
        return enrichWithCreateCompute({
          type: resolution,
          compute: (context: C) => childrenNodes.map(({ compute }) => compute(context)),
          transpilation: {
            code: `[${childrenNodes.map(({ transpilation: { code } }) => code).join(',')}]`,
            inputTable,
            functionTable,
          },
          referencedInputNames: merge(childrenNodes.map(({ referencedInputNames }) => referencedInputNames)),
          referencedFunctionNames: merge(childrenNodes.map(({ referencedFunctionNames }) => referencedFunctionNames)),
          isDeterminist: childrenNodes.every((arg) => arg.isDeterminist),
          children: childrenNodes,
        });
      }
    },
    visitFunctionCall: (functionName, args) => {
      const visitFunction = (localArgs: (FormulaNode<C> | Error)[]): FormulaNode<C> | Error => {
        const functionResolution = resolveTypeFromChildren(
          (types) => functionLibrary.resolveFunction(functionName, types),
          localArgs
        );

        if (functionResolution instanceof Error) {
          return functionResolution;
        } else if (Array.isArray(functionResolution.resolution)) {
          const mappedArgs: (FormulaNode<C> | Error)[] = [];
          for (let i = 0; i < localArgs.length; i += 1) {
            const arg = localArgs[i];
            if (arg instanceof Error) {
              mappedArgs[i] = arg;
            } else {
              const argumentMapper = functionResolution.resolution.at(i);
              const mappedType = argumentMapper?.type;
              const mapperJsFunction = (argumentMapper as { jsFunction: CallableFunction }).jsFunction;
              const mapperTranspile = (argumentMapper as { transpile: (argument: string) => string }).transpile;

              let compute: ComputeFormula<C>;
              let transpilationCode: string;
              if (mapperJsFunction === undefined) {
                compute = arg.compute;
                transpilationCode = arg.transpilation.code;
              } else {
                compute = (context) => mapperJsFunction(arg.compute(context));
                if (mapperTranspile === undefined) {
                  const functionIndex = functionMapping.get(mapperJsFunction) ?? createFunctionMapping(mapperJsFunction);
                  transpilationCode = `${functionTableVar}[${functionIndex}](${arg.transpilation.code})`;
                } else {
                  transpilationCode = `${mapperTranspile(arg.transpilation.code)}`;
                }
              }

              mappedArgs[i] = enrichWithCreateCompute({
                type: mappedType ?? arg.type,
                compute,
                transpilation: {
                  code: transpilationCode,
                  inputTable: arg.transpilation.inputTable,
                  functionTable: arg.transpilation.functionTable,
                },
                referencedInputNames: arg.referencedInputNames,
                referencedFunctionNames: arg.referencedFunctionNames,
                isDeterminist: arg.isDeterminist,
                children: arg.children,
              });
            }
          }

          return visitFunction(mappedArgs);
        } else {
          const { resolution: { formulaFunction: { isDeterminist }, resolution: { type, jsFunction, jsFunctionArgsMode, transpile } }, childrenNodes } = functionResolution;

          const compute = (context: C) => jsFunction(
            ...childrenNodes.map(({ compute: argCompute }) => (jsFunctionArgsMode === 'lazy' ? () => argCompute(context) : argCompute(context)))
          );

          const functionIndex = transpile ? undefined : functionMapping.get(jsFunction) ?? createFunctionMapping(jsFunction);
          const transpiled = transpile?.(childrenNodes.map(({ transpilation: { code } }) => code))
            ?? `${functionTableVar}[${functionIndex}](${
              childrenNodes
                .map(({ transpilation: { code } }) => (jsFunctionArgsMode === 'lazy' ? `() => ${code}` : code))
                .join(',')
            })`;
          return enrichWithCreateCompute({
            type,
            compute,
            transpilation: {
              code: transpiled,
              inputTable,
              functionTable,
            },
            referencedInputNames: merge(childrenNodes.map(({ referencedInputNames }) => referencedInputNames)),
            referencedFunctionNames: merge([[functionName], ...childrenNodes.map(({ referencedFunctionNames }) => referencedFunctionNames)]),
            isDeterminist: isDeterminist && childrenNodes.every((arg) => arg.isDeterminist),
            children: childrenNodes,
          });
        }
      };

      return visitFunction(args);
    },
  };
};
