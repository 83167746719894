import type { FunctionComponent, ReactElement } from 'react';
import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { WorkflowRelationDisplayType } from 'yooi-modules/modules/conceptLayoutModule';
import type { BooleanFieldStoreObject, ConceptDefinitionStoreObject, ConceptStoreObject, WorkflowFieldStoreObject } from 'yooi-modules/modules/conceptModule';
import {
  BLOCK_PARAMETER_CURRENT,
  createValuePathResolver,
  FILTER_PARAMETER_LOGGED_USER,
  getFieldDimensionOfModelType,
  getPathLastFieldInformation,
  hasPlatformCapability,
  isEmbeddedAsIntegrationOnly,
  isSingleFieldResolution,
  textFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import {
  BooleanField,
  Concept_Name,
  ConceptDefinition_MainBooleanField,
  ConceptDefinition_MainIconField,
  ConceptDefinition_MainWorkflowField,
  ConceptDefinition_MainWorkflowOnSecondLine,
  ConceptDefinition_SanityScore,
  PlatformCapabilityAdmin,
  User,
  WorkflowField,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import OverflowMenu from '../../components/molecules/OverflowMenu';
import SpacingLine from '../../components/molecules/SpacingLine';
import type { Tab } from '../../components/molecules/Tabs';
import Header from '../../components/templates/Header';
import HeaderActions from '../../components/templates/HeaderActions';
import HeaderLine from '../../components/templates/HeaderLine';
import HeaderTabs from '../../components/templates/HeaderTabs';
import useAcl from '../../store/useAcl';
import useAuth from '../../store/useAuth';
import useStore from '../../store/useStore';
import useUpdateActivity from '../../store/useUpdateActivity';
import { spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import useNavigation from '../../utils/useNavigation';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import { booleanFieldDefinition } from '../_global/fields/booleanField/booleanFieldDefinition';
import { getFieldHandler } from '../_global/fields/FieldLibrary';
import { iconFieldDefinition } from '../_global/fields/iconField/iconFieldDefinition';
import { workflowFieldDefinition } from '../_global/fields/workflowField/workflowFieldDefinition';
import { getLastUpdateBy } from '../_global/historyUtils';
import StoreRichTextInputField from '../_global/input/StoreRichTextInputField';
import ActivityIndicator from '../_global/multiplayer/ActivityIndicator';
import type { NavigationFilter } from '../_global/navigationUtils';
import CollaborationButton from '../_global/rightPanel/collaboration/CollaborationButton';
import { getIntentions } from '../_global/rightPanel/collaboration/utils/collaborationUtils';
import useConceptDeleteModal from '../_global/useConceptDeleteModal';
import useAdminMenuItems from '../_global/userAdmin/useAdminMenuItems';
import { getConceptHeaderMenuItems } from './_global/conceptHeaderUtils';

const useStyles = makeStyles({
  booleanField: {
    paddingTop: spacingRem.splus,
    paddingBottom: spacingRem.splus,
    paddingRight: spacingRem.xl,
  },
}, 'conceptHeader');

interface ConceptHeaderProps {
  conceptId: string,
  tabs: Tab[],
  selectedTabIndex: number,
}

const ConceptHeader: FunctionComponent<ConceptHeaderProps> = ({ conceptId, tabs, selectedTabIndex }) => {
  const store = useStore();
  const aclHandler = useAcl();
  const classes = useStyles();
  const { loggedUserId } = useAuth();

  const updateActivity = useUpdateActivity();

  const location = useLocation();
  const navigation = useNavigation<NavigationFilter>();

  const { passwordModal, buildAdminMenuItems } = useAdminMenuItems(store, loggedUserId);
  const [doDelete, deleteModal] = useConceptDeleteModal(true);

  const concept = store.getObject<ConceptStoreObject>(conceptId);
  const conceptDefinition = concept.navigate<ConceptDefinitionStoreObject>(Instance_Of);

  const readOnly = !aclHandler.canWriteObject(conceptId) || isEmbeddedAsIntegrationOnly(concept);

  const mainWorkflowField = conceptDefinition.navigateOrNull(ConceptDefinition_MainWorkflowField);
  const hasMainWorkflowField = isInstanceOf<WorkflowFieldStoreObject>(mainWorkflowField, WorkflowField);

  const mainBooleanPath = conceptDefinition[ConceptDefinition_MainBooleanField];
  const mainBooleanLastFieldStepId = mainBooleanPath && getPathLastFieldInformation(mainBooleanPath)?.fieldId;
  const mainBooleanField = mainBooleanLastFieldStepId ? store.getObjectOrNull(mainBooleanLastFieldStepId) ?? undefined : undefined;
  const hasMainBooleanField = isInstanceOf<BooleanFieldStoreObject>(mainBooleanField, BooleanField);
  const parametersMapping = {
    [BLOCK_PARAMETER_CURRENT]: { type: 'single' as const, id: concept.id },
    [FILTER_PARAMETER_LOGGED_USER]: { type: 'single' as const, id: loggedUserId },
  };

  const renderWorkflowField = (displayType: WorkflowRelationDisplayType): ReactElement | null => {
    if (hasMainWorkflowField) {
      const renderField = workflowFieldDefinition(store).getHandler(mainWorkflowField.id)?.renderField;
      const dimensionId = getFieldDimensionOfModelType(store, mainWorkflowField.id, conceptDefinition.id);

      if (renderField !== undefined && dimensionId !== undefined) {
        return renderField({ dimensionsMapping: { [dimensionId]: conceptId }, readOnly, fieldDisplayOptions: { displayType } });
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const renderBooleanField = (): ReactElement | null => {
    if (hasMainBooleanField) {
      const renderField = booleanFieldDefinition(store).getHandler(mainBooleanField.id)?.renderField;
      const dimensionId = getFieldDimensionOfModelType(store, mainBooleanField.id, conceptDefinition.id);
      const valuePathResolver = createValuePathResolver(store, parametersMapping);
      const resolution = mainBooleanPath && valuePathResolver.resolvePathField(mainBooleanPath);
      if (renderField !== undefined && dimensionId !== undefined && resolution !== undefined && !(resolution instanceof Error)
        && isSingleFieldResolution(resolution) && resolution.dimensionsMapping) {
        return renderField({ dimensionsMapping: resolution.dimensionsMapping, readOnly, parametersMapping });
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const sanityScoreField = conceptDefinition.navigateOrNull(ConceptDefinition_SanityScore);
  const hasSanityScoreField = sanityScoreField !== null;

  const renderSanityScoreField = (): ReactElement | null => {
    if (hasSanityScoreField) {
      const renderField = getFieldHandler(store, sanityScoreField.id)?.renderField;
      const dimensionId = getFieldDimensionOfModelType(store, sanityScoreField.id, conceptDefinition.id);

      if (renderField !== undefined && dimensionId !== undefined) {
        return renderField({ dimensionsMapping: { [dimensionId]: conceptId }, readOnly });
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const getAction = () => {
    const actions: ReactElement[] = [];

    if (hasMainBooleanField) {
      const booleanFieldElement = renderBooleanField();
      if (booleanFieldElement !== null) {
        // Fragment is needed to make sure react receive a key
        actions.push((
          <div key="boolean" className={classes.booleanField}>
            {booleanFieldElement}
          </div>
        ));
      }
    }

    if (hasMainWorkflowField && !conceptDefinition[ConceptDefinition_MainWorkflowOnSecondLine]) {
      const workflowFieldElement = renderWorkflowField(WorkflowRelationDisplayType.ButtonAndValue);
      if (workflowFieldElement !== null) {
        // Fragment is needed to make sure react receive a key
        actions.push((
          <Fragment key="workflow">
            {workflowFieldElement}
          </Fragment>
        ));
      }
    }

    if (hasSanityScoreField) {
      const sanityScoreElement = renderSanityScoreField();
      if (sanityScoreElement !== null) {
        // Fragment is needed to make sure react receive a key
        actions.push((
          <Fragment key="sanity">
            {sanityScoreElement}
          </Fragment>
        ));
      }
    }

    const isAdmin = hasPlatformCapability(store, loggedUserId, PlatformCapabilityAdmin);
    if (getIntentions(store, conceptDefinition.id).length > 0) {
      actions.push((<CollaborationButton key="collaboration" />));
    }
    actions.push(
      (
        <OverflowMenu
          key="overflowMenu"
          menuItems={[
            ...getConceptHeaderMenuItems(navigation, store, aclHandler, isAdmin, () => doDelete(conceptId), conceptId),
            ...buildAdminMenuItems(conceptId),
          ]}
        />
      )
    );

    return actions;
  };

  const nameFieldHandler = textFieldHandler(store, Concept_Name);
  const nameDimensionId = getFieldDimensionOfModelType(store, Concept_Name, conceptDefinition.id);
  // We can remove this once we have ACL for properties
  const isNameReadOnly = readOnly || (concept[Instance_Of] === User && !hasPlatformCapability(store, store.getLoggedUserId(), PlatformCapabilityAdmin));
  const nameInput = (
    <StoreRichTextInputField
      initialValue={nameDimensionId === undefined ? undefined : nameFieldHandler.getValueResolution({ [nameDimensionId]: conceptId }).value?.valueOf()}
      onSubmit={(newName) => {
        if (nameDimensionId !== undefined) {
          nameFieldHandler.updateValue({ [nameDimensionId]: conceptId }, newName);
        }
      }}
      placeholder={i18n`Click to edit name`}
      restingTooltip={() => getLastUpdateBy(store, conceptId, Concept_Name, undefined)}
      readOnly={isNameReadOnly}
      onEditionStart={() => updateActivity.onEnterEdition(conceptId, Concept_Name)}
      onEditionStop={() => updateActivity.onExitEdition(conceptId, Concept_Name)}
      maxLine={1}
      dropdownMaxLine={5}
      dropdownSizes={{ sameWidth: false }}
    />
  );

  let iconInput: ReactElement | undefined;
  const mainIconField = conceptDefinition.navigateOrNull(ConceptDefinition_MainIconField);
  if (mainIconField !== null) {
    const iconDimensionId = getFieldDimensionOfModelType(store, mainIconField.id, conceptDefinition.id);
    if (iconDimensionId !== undefined) {
      iconInput = iconFieldDefinition(store).getHandler(mainIconField.id).renderField?.({ dimensionsMapping: { [iconDimensionId]: conceptId }, readOnly }) ?? undefined;
    }
  }

  return (
    <>
      <Header
        firstLine={(
          <HeaderLine
            left={(
              <ActivityIndicator
                propertyIds={hasMainWorkflowField && !conceptDefinition[ConceptDefinition_MainWorkflowOnSecondLine] ? [Concept_Name, mainWorkflowField.id] : [Concept_Name]}
                instanceIds={conceptId}
              />
            )}
            actions={<HeaderActions>{getAction()}</HeaderActions>}
            padded={iconInput !== undefined}
          >
            <SizeContextProvider sizeVariant={SizeVariant.title} hierarchyVariant={HierarchyVariant.content}>
              {
                iconInput !== undefined
                  ? (
                    <SpacingLine fullWidth>
                      {iconInput}
                      {nameInput}
                    </SpacingLine>
                  )
                  : nameInput
              }
            </SizeContextProvider>
          </HeaderLine>
        )}
        secondLine={
          hasMainWorkflowField && (conceptDefinition[ConceptDefinition_MainWorkflowOnSecondLine] ?? false)
            ? (
              <HeaderLine
                left={(<ActivityIndicator propertyIds={[mainWorkflowField.id]} instanceIds={conceptId} padding={{}} />)}
              >
                {renderWorkflowField(WorkflowRelationDisplayType.ValueAndButton)}
              </HeaderLine>
            )
            : null
        }
        tabsLine={
          tabs.length > 1
            ? (
              <HeaderTabs
                tabs={tabs}
                selectedTabIndex={selectedTabIndex}
                onSelectedIndexChanged={(index) => {
                  navigation.replace(concept.id, { pathname: location.pathname, hash: tabs[index].hash });
                }}
              />
            )
            : null
        }
        withBottomBorder
      />
      {deleteModal}
      {passwordModal}
    </>
  );
};

export default ConceptHeader;
