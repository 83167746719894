import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import Icon, { IconName } from '../../../../components/atoms/Icon';
import Typo, { TypoVariant } from '../../../../components/atoms/Typo';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeSelectorsClasses from '../../../../utils/makeSelectorsClasses';
import makeStyles from '../../../../utils/makeStyles';
import { notifySuccess } from '../../../../utils/notify';
import useDerivedState from '../../../../utils/useDerivedState';
import useTheme from '../../../../utils/useTheme';
import { generateExportFromValues } from './explorerUtils';
import type { ObjectValue } from './objectRenderType';
import TagRenderer from './TagRenderer';
// Yes, we have a cycle, but this is expected
// eslint-disable-next-line import/no-cycle
import ValueRenderer from './ValueRenderer';

const selectorsClasses = makeSelectorsClasses('actionHovered');

const useStyles = makeStyles((theme) => ({
  groupContainer: {
    lineHeight: 'initial',
  },
  container: {
    marginLeft: spacingRem.xs,
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.border.default,
  },
  entry: {
    paddingLeft: spacingRem.l,
    paddingRight: spacingRem.s,
    paddingBottom: spacingRem.xxs,
    paddingTop: spacingRem.xxs,
    display: 'flex',
    flexDirection: 'row',
    '& .action': {
      visibility: 'hidden',
    },
    '&:hover .action': {
      visibility: 'visible',
    },
    '&:hover': {
      borderColor: theme.color.text.disabled,
    },
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
  },
  objectTagContainer: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    columnGap: spacingRem.s,
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
    alignItems: 'center',
    [`& .${selectorsClasses.actionHovered}`]: {
      visibility: 'hidden',
    },
    [`&:hover .${selectorsClasses.actionHovered}`]: {
      visibility: 'visible',
    },
  },
  objectActionContainer: {
    cursor: 'pointer',
  },
}), 'objectRenderer');

interface ObjectRendererProps {
  object: ObjectValue,
}

const ObjectRenderer: FunctionComponent<ObjectRendererProps> = ({ object }) => {
  const theme = useTheme();
  const classes = useStyles();

  const [collapsed, setCollapsed] = useDerivedState(() => object.collapseByDefault ?? false, [object.collapseByDefault]);
  const toggleCollapsed = () => setCollapsed((current) => !current);

  if (object.entries.length === 0) {
    return (
      <span className={classes.groupContainer}>
        <span className={classes.objectTagContainer}>
          <Typo variant={TypoVariant.code}>&#123;&#125;</Typo>
        </span>
      </span>
    );
  } else {
    const toggle = (
      <span className={classes.objectActionContainer} onClick={toggleCollapsed} aria-hidden="true">
        <Icon name={collapsed ? IconName.keyboard_arrow_right : IconName.expand_more} />
      </span>
    );
    const clipboard = (
      <span
        className={classnames(classes.objectActionContainer, selectorsClasses.actionHovered)}
        onClick={(event) => {
          // We stop the propagation to no trigger the toggle operation (hint <> value) of the containing div
          event.stopPropagation();

          navigator.clipboard.writeText(JSON.stringify(generateExportFromValues(object)));
          notifySuccess(i18n`Copied to clipboard`);
        }}
        aria-hidden="true"
      >
        <Icon name={IconName.description} />
      </span>
    );

    if (collapsed) {
      return (
        <span className={classes.groupContainer}>
          <span className={classes.objectTagContainer}>
            <Typo variant={TypoVariant.code}>&#123;</Typo>
            {toggle}
            {clipboard}
            {
              object.collapsedLabel
                ? (
                  <Typo variant={TypoVariant.code} color={theme.color.text.disabled}>{object.collapsedLabel}</Typo>
                )
                : null
            }
            <Typo variant={TypoVariant.code}>&#125;</Typo>
          </span>
        </span>
      );
    } else {
      return (
        <span className={classes.groupContainer}>
          <span className={classes.objectTagContainer}>
            <Typo variant={TypoVariant.code}>&#123;</Typo>
            {toggle}
            {clipboard}
          </span>
          <div className={classes.container}>
            {
              object.entries.map(({ key: { key, value: keyValue, hint, href, variant }, value }) => (
                <div key={key} className={classes.entry}>
                  <TagRenderer value={keyValue} hint={hint} href={href} variant={variant} />
                  <Typo variant={TypoVariant.code}>: </Typo>
                  <ValueRenderer value={value} />
                </div>
              ))
            }
          </div>
          <span className={classes.objectTagContainer}>
            <Typo variant={TypoVariant.code}>&#125;</Typo>
          </span>
        </span>
      );
    }
  }
};

export default ObjectRenderer;
