// This file is automatically updated by the generate-constants script. Do not update it manually.

export const AutomationModuleId = 'ab7f95e7-4d7f-4e19-b4a8-edf4234d24ba';
export const AutomationAction = 'a6fcdd18-3628-48ea-be8b-b884278f5f04';
export const AutomationAction_Query = '6ffab6da-03b7-4ca1-a227-f2899bf2585e';
export const AutomationAction_Rule = '2b1752da-08f5-4a4f-9e34-079f916bd7a9';
export const AutomationActionDefinition = 'f8b16dc1-502b-4f38-9549-f206bc4adc1a';
export const AutomationActionDefinition_Name = '1fc7ae75-77b8-48e8-882d-01b4aa0b0e84';
export const AutomationActionEmail = 'b4548ca4-8049-4fb3-81d2-486a208ec316';
export const AutomationActionEmail_Body = '7016b70f-03c8-4990-b842-98188a49a742';
export const AutomationActionEmail_HasCustomPermissions = '87d2be98-34ae-43ba-8f75-ca6a2a3ebf2e';
export const AutomationActionEmail_Permissions = '3b9f0ee4-5c48-4e36-9916-fe5856f09266';
export const AutomationActionEmail_Recipients = 'e1adce3e-080f-4fe8-b61f-85be54c656be';
export const AutomationActionEmail_Subject = '7f3250dd-c157-4f43-9b71-1da092684f5d';
export const AutomationActionGenerateData = '899c0541-f467-4e37-9567-571f0bc06c98';
export const AutomationActionGenerateData_Operations = 'b8ad05f0-74c0-4521-9ef4-79f16968bcbc';
export const AutomationRule = 'c439b6b2-6e60-43da-a521-873a2857ec29';
export const AutomationRule_Description = '499db515-b2cb-405e-b823-3f490276dbb5';
export const AutomationRule_IsEnabled = '8a754137-b274-4e20-8454-9ee48e05b40c';
export const AutomationRule_Name = 'a759200b-4b7c-4402-97ae-c84bdaeca177';
export const AutomationRule_Parameters = '7c2e2883-a106-4b5b-911f-7e3c87933dbb';
export const AutomationRule_Trigger = '3706d95a-efca-4aeb-9b18-600cda5d4b51';
export const AutomationRule_UserId = '62ed3ab8-a5c6-453a-9697-e0b132b900d9';
export const AutomationRule_Actions = AutomationAction_Rule;
export const User_AutomationRules = AutomationRule_UserId;
