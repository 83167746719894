export const toUnaccentedString = (value: string): string => (
  // normalize split letter with accent in two different character (é = e + ') then every accent is removed to compare without accent
  value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
);

export const toSnakeCase = (value: string): string => (
  (toUnaccentedString(value.toLowerCase()).match(/[a-z0-9-_]+/g) ?? [])
    .map((segment) => segment.toLowerCase())
    .join('-')
);
