import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import type { TableViewStoredDefinition, ViewDimension, WidgetStoreObject } from 'yooi-modules/modules/dashboardModule';
import { Widget_DisplayMode } from 'yooi-modules/modules/dashboardModule/ids';
import { useWidgetContext } from '../../../../components/atoms/WidgetContextProvider';
import Spinner, { SpinnerVariant } from '../../../../components/molecules/Spinner';
import useStore from '../../../../store/useStore';
import { Opacity } from '../../../../theme/base';
import { generateColorFromOpacity, getMostReadableColorFromBackgroundColor } from '../../../../theme/colorUtils';
import { spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import useTheme from '../../../../utils/useTheme';
import useViewLoadingState from '../../fields/viewsField/useViewLoadingState';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { getViewDefinitionHandler } from '../viewDsl';
import TableViewActions from './TableViewActions';
import type { TableViewDefinitionHandler } from './tableViewHandler';
import { viewsFieldTableHasOption } from './tableViewUtils';

const useStyles = makeStyles({
  baseConfigurationContainer: {
    display: 'inline-flex',
    justifyItems: 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: spacingRem.s,
  },
  borderlessConfigurationContainer: {
    paddingLeft: spacingRem.splus,
    paddingBottom: spacingRem.s,
  },
  tableConfigurationContainer: {
    paddingRight: spacingRem.splus,
    paddingBottom: spacingRem.s,
  },
}, 'tableViewOptions');

interface TableViewOptionsProps {
  widgetId?: string,
  viewDimensions: ViewDimension[],
  viewDefinition: TableViewStoredDefinition,
  viewFilters: ViewFilters,
  includeLoader: boolean,
}

const TableViewOptions: FunctionComponent<TableViewOptionsProps> = ({
  widgetId,
  viewDimensions,
  viewDefinition,
  viewFilters,
  includeLoader,
}) => {
  const store = useStore();
  const theme = useTheme();
  const classes = useStyles();
  const loadingState = useViewLoadingState();
  const { backgroundColor } = useWidgetContext();
  const viewHandler = getViewDefinitionHandler(viewDefinition) as TableViewDefinitionHandler;
  const resolvedViewDefinition = viewHandler.getDefinition(store, viewDimensions);
  const groupByDefault = { groupById: resolvedViewDefinition.defaultGroupBy };
  const isBorderless = !widgetId || store.getObject<WidgetStoreObject>(widgetId)[Widget_DisplayMode]?.type === 'borderless';
  const mostReadableColor = getMostReadableColorFromBackgroundColor(backgroundColor ?? theme.color.background.neutral.default);

  return (
    <div
      className={classnames({
        [classes.baseConfigurationContainer]: true,
        [classes.tableConfigurationContainer]: !isBorderless,
        [classes.borderlessConfigurationContainer]: isBorderless,
      })}
    >
      {
        includeLoader && loadingState === 'loading'
          ? (
            <Spinner
              size={SpinnerVariant.small}
              color={generateColorFromOpacity(mostReadableColor, theme.color.background.neutral.default, Opacity.fifty)}
            />
          )
          : null
      }
      {
        viewsFieldTableHasOption(store, viewDimensions, resolvedViewDefinition)
          ? (
            <TableViewActions filterKey={viewFilters.filterKey} viewDefinition={resolvedViewDefinition} viewDimensions={viewDimensions} groupByDefault={groupByDefault} />
          )
          : null
      }
    </div>
  );
};

export default TableViewOptions;
