import { ValidationStatus } from 'yooi-store';
import { CommonAsType } from '../../common/fields/commonPropertyType';
import type { BusinessRuleRegistration } from '../../common/types/TypeModuleDslType';
import { PropertyMandatoryType } from '../../common/types/TypeModuleDslType';
import type { FieldDimensionTypesStoreObject } from '../../conceptModule';
import { adminOnlyAcl, isConceptDefinitionField } from '../../conceptModule';
import {
  ConceptDefinition,
  ExternalKeyField,
  Field,
  Field_FieldDimensions,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  IdField,
  TextField,
} from '../../conceptModule/ids';
import { isInstanceOf } from '../../typeModule';
import {
  ConceptDefinitionLibraryTable,
  ConceptDefinitionLibraryTable_Rank,
  ConceptDefinitionLibraryTable_Role_ConceptDefinition,
  ConceptDefinitionLibraryTable_Role_Field,
  FieldRelevantFieldDisplay_Role_ConceptDefinition,
  FieldRelevantFieldDisplay_Role_Field,
  SearchFieldDisplay_Role_ConceptDefinition,
  SearchFieldDisplay_Role_Field,
} from '../ids';
import { registerModel } from '../module';
import type { ConceptDefinitionLibraryTableStoreObject } from './types';

const { association } = registerModel;

const allowDataGridField: BusinessRuleRegistration = ({ getObject, withAssociation }) => (_, { id, properties }) => {
  if (!properties) {
    return undefined;
  }

  const field = getObject(id[ConceptDefinitionLibraryTable_Role_Field + 1]);

  const dimensions = field.navigateBack(Field_FieldDimensions);

  for (let i = 0; i < dimensions.length; i += 1) {
    const exist = withAssociation(FieldDimensionTypes)
      .withRole(FieldDimensionTypes_Role_ConceptDefinition, id[ConceptDefinitionLibraryTable_Role_ConceptDefinition + 1])
      .withRole(FieldDimensionTypes_Role_FieldDimension, dimensions[i].id)
      .getOrNull<FieldDimensionTypesStoreObject>();

    if (exist) {
      return {
        rule: 'conceptDefinition.dataGrid.fieldAllowed',
        status: ValidationStatus.ACCEPTED,
      };
    }
  }
  return {
    rule: 'conceptDefinition.dataGrid.fieldNotAllowed',
    status: ValidationStatus.REJECTED,
  };
};

const preventSameRanks: BusinessRuleRegistration = ({ getObject, withAssociation }) => (_, { id, properties }) => {
  if (!properties) {
    return undefined;
  }
  const conceptDefinition = getObject(id[ConceptDefinitionLibraryTable_Role_ConceptDefinition + 1]);
  const existingRank = withAssociation(ConceptDefinitionLibraryTable)
    .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinition.id)
    .list<ConceptDefinitionLibraryTableStoreObject>()
    .some((conceptDefinitionLibraryTable) => conceptDefinitionLibraryTable.object[ConceptDefinitionLibraryTable_Rank] === properties[ConceptDefinitionLibraryTable_Rank]);

  return existingRank ? {
    rule: 'conceptDefinitionLibraryTable.rank.existingRank',
    status: ValidationStatus.REJECTED,
  } : {
    rule: 'conceptDefinitionLibraryTable.rank.allowedRank',
    status: ValidationStatus.ACCEPTED,
  };
};

association({
  label: 'ConceptDefinitionLibraryTable',
  roles: [{ label: 'ConceptDefinition', targetTypeId: ConceptDefinition }, { label: 'Field', targetTypeId: Field }],
  accessControlList: {
    READ: () => () => ({ rule: 'conceptDefinitionLibraryTable.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'conceptDefinitionLibraryTable', 'WRITE'),
    DELETE: () => (_, objectId) => ({ rule: 'conceptDefinitionLibraryTable.delete.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: objectId }),
  },
  businessRules: [allowDataGridField],
})
  .property({
    label: 'ConceptDefinitionLibraryTable_Rank',
    as: CommonAsType.string,
    mandatory: { type: PropertyMandatoryType.mandatory },
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
    businessRules: [preventSameRanks],
  })
  .property({
    label: 'ConceptDefinitionLibraryTable_Width',
    as: CommonAsType.number,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'ConceptDefinitionLibraryTable_HideOnCard',
    as: CommonAsType.boolean,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  });

association({
  label: 'FieldSuggestedDisplay',
  roles: [{ label: 'ConceptDefinition', targetTypeId: ConceptDefinition }, { label: 'Field', targetTypeId: Field }],
  accessControlList: {
    READ: () => () => ({ rule: 'FieldSuggestedDisplay.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: () => () => ({ rule: 'FieldSuggestedDisplay.write.rejected', status: ValidationStatus.REJECTED }),
    DELETE: () => () => ({ rule: 'FieldSuggestedDisplay.delete.rejected', status: ValidationStatus.REJECTED }),
  },
})
  .property({
    label: 'FieldSuggestedDisplay_Rank',
    as: CommonAsType.string,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  });

const allowGroupByField: BusinessRuleRegistration = ({ getObject, withAssociation }) => (_, { id, properties }) => {
  if (!properties) {
    return undefined;
  }

  const field = getObject(id[FieldRelevantFieldDisplay_Role_Field + 1]);

  const dimensions = field.navigateBack(Field_FieldDimensions);

  for (let i = 0; i < dimensions.length; i += 1) {
    const exist = withAssociation(FieldDimensionTypes)
      .withRole(FieldDimensionTypes_Role_ConceptDefinition, id[FieldRelevantFieldDisplay_Role_ConceptDefinition + 1])
      .withRole(FieldDimensionTypes_Role_FieldDimension, dimensions[i].id)
      .getOrNull<FieldDimensionTypesStoreObject>();

    if (exist) {
      return {
        rule: 'field.groupBy.isAllowedOnConcept',
        status: ValidationStatus.ACCEPTED,
      };
    }
  }
  return {
    rule: 'field.groupBy.isNotAllowedOnConcept',
    status: ValidationStatus.REJECTED,
  };
};

association({
  label: 'FieldRelevantFieldDisplay',
  roles: [{ label: 'ConceptDefinition', targetTypeId: ConceptDefinition }, { label: 'Field', targetTypeId: Field }],
  accessControlList: {
    READ: () => () => ({ rule: 'fieldRelevantFieldDisplay.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'fieldRelevantFieldDisplay', 'WRITE'),
    DELETE: () => (_, objectId) => ({ rule: 'fieldRelevantFieldDisplay.delete.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: objectId }),
  },
  businessRules: [allowGroupByField],
})
  .property({ label: 'FieldRelevantFieldDisplay_Rank', as: CommonAsType.string });

const checkAllowedTypeForSearchAssociation: BusinessRuleRegistration = (store) => (_, { id, properties }) => {
  if (!properties) {
    return undefined;
  }
  const fieldId = id[SearchFieldDisplay_Role_Field + 1];
  const conceptDefinitionId = id[SearchFieldDisplay_Role_ConceptDefinition + 1];
  const field = store.getObjectOrNull(fieldId);
  if (!field) {
    return {
      rule: 'searchFieldDisplay.field.undefined',
      status: ValidationStatus.REJECTED,
    };
  }
  if ((isInstanceOf(field, TextField) || isInstanceOf(field, ExternalKeyField) || isInstanceOf(field, IdField))
    && isConceptDefinitionField(store, fieldId, conceptDefinitionId)) {
    return {
      rule: 'searchFieldDisplay.field.isTextOrKeyField',
      status: ValidationStatus.ACCEPTED,
    };
  } else {
    return {
      rule: 'searchFieldDisplay.field.isNotAllowedField',
      status: ValidationStatus.REJECTED,
    };
  }
};

association({
  label: 'SearchFieldDisplay',
  roles: [{ label: 'ConceptDefinition', targetTypeId: ConceptDefinition }, { label: 'Field', targetTypeId: Field }],
  accessControlList: {
    READ: () => () => ({ rule: 'SearchFieldDisplay.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'searchFieldDisplay', 'WRITE'),
    DELETE: () => (_, objectId) => ({ rule: 'SearchFieldDisplay.delete.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: objectId }),
  },
  businessRules: [checkAllowedTypeForSearchAssociation],
});

export const testables = { allowDataGridField };
