import type { FunctionComponent } from 'react';
import type { DimensionsMapping, FieldDimensionTypesStoreObject } from 'yooi-modules/modules/conceptModule';
import { dimensionsMappingToParametersMapping, numberFieldHandler, timeseriesNumberFieldHandler } from 'yooi-modules/modules/conceptModule';
import {
  ConceptDefinition_Name,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  NumberField,
  TimeseriesNumberField,
} from 'yooi-modules/modules/conceptModule/ids';
import { UnsetDashboardParameterOption } from 'yooi-modules/modules/dashboardModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import type { PeriodicityType } from 'yooi-utils';
import { dateFormats, formatDisplayDate, periodicities } from 'yooi-utils';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import TextInputString from '../../../../components/inputs/TextInputString';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import useStore from '../../../../store/useStore';
import { Spacing, spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { formatOrUndef } from '../../../../utils/stringUtils';
import { buildUnsetOption } from '../../dashboardUtils';
import ConceptTooltipText from '../../fields/_global/ConceptTooltipText';
import { getNumberRangeValues } from '../../fields/numberField/numberFieldUtils';
import { getTimeseriesMaxMinValues, getTimeseriesRangeValues } from '../../fields/timeseries/timeseriesNumberField/timeseriesNumberFieldUtils';
import { getInstanceMaxMinValues, TickResolutionStatus } from '../../fieldUtils';
import StoreNumberPickerInput from '../../input/StoreNumberPickerInput';
import { getOption } from '../../modelTypeUtils';
import TooltipContainer from '../../TooltipContainer';

const useStyles = makeStyles((theme) => ({
  tooltipLabelContainer: {
    width: '10.5rem',
  },
  spacingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: spacingRem.s,
    marginRight: spacingRem.s,
  },
  tooltipTitleSeparator: {
    height: '0.1rem',
    backgroundColor: theme.color.border.default,
  },
}), 'viewsTooltip');

interface ViewsTooltipProps {
  fieldId: string,
  seriesLabel: string,
  dimensionsMapping: DimensionsMapping,
  time?: number,
  value: number,
  periodicity?: PeriodicityType,
  totalValue?: number,
}

const ViewsTooltip: FunctionComponent<ViewsTooltipProps> = ({
  fieldId,
  seriesLabel,
  dimensionsMapping,
  time,
  value,
  periodicity,
  totalValue,
}) => {
  const classes = useStyles();

  const store = useStore();

  const field = store.getObjectOrNull(fieldId);
  let min: { value: number, color: string | undefined } | undefined;
  let ticks: { value: number, color: string | undefined }[] | undefined;
  let max: { value: number, color: string | undefined } | undefined;
  let invalidColor: string | undefined;
  let unit: string | undefined;
  let decimals: number | undefined;

  const instanceId = Object.values(dimensionsMapping).length === 1 ? Object.values(dimensionsMapping)[0] : undefined;
  if (isInstanceOf(field, NumberField)) {
    const resolutions = getInstanceMaxMinValues(store, fieldId, instanceId, dimensionsMappingToParametersMapping(dimensionsMapping));
    const fieldHandler = numberFieldHandler(store, fieldId);
    const numberField = fieldHandler.resolveConfigurationWithOverride(dimensionsMapping);
    min = resolutions.min?.status === TickResolutionStatus.Resolved ? resolutions.min : undefined;
    ticks = getNumberRangeValues(store, fieldId, instanceId);
    max = resolutions.max?.status === TickResolutionStatus.Resolved ? resolutions.max : undefined;
    invalidColor = numberField.invalidColor;
    unit = numberField.unit;
    decimals = numberField.decimals;
  } else if (isInstanceOf(field, TimeseriesNumberField)) {
    const resolutions = getTimeseriesMaxMinValues(store, fieldId, dimensionsMapping, {});
    const fieldHandler = timeseriesNumberFieldHandler(store, fieldId);
    const configuration = fieldHandler.resolveConfigurationWithOverride(dimensionsMapping);
    min = resolutions.min?.status === TickResolutionStatus.Resolved ? resolutions.min : undefined;
    ticks = getTimeseriesRangeValues(store, fieldId, dimensionsMapping, {});
    max = resolutions.max?.status === TickResolutionStatus.Resolved ? resolutions.max : undefined;
    invalidColor = configuration.invalidColor;
    unit = configuration.unit;
    decimals = configuration.decimals;
  }
  return (
    <TooltipContainer>
      <div className={classes.spacingContainer}>
        <Tooltip title={formatOrUndef(seriesLabel)}>
          <SpacedContainer margin={{ right: Spacing.text }}>
            <SpacingLine>
              <Typo noWrap>{formatOrUndef(seriesLabel)}</Typo>
            </SpacingLine>
          </SpacedContainer>
        </Tooltip>
      </div>
      <div className={classes.tooltipTitleSeparator} />
      <SpacedContainer margin={Spacing.s}>
        {Object.entries(dimensionsMapping)
          .filter(([, dimensionValue]) => !!dimensionValue)
          .map(([dimensionId, dimensionValue]) => {
            const dimLabel = store.withAssociation(FieldDimensionTypes)
              .withRole(FieldDimensionTypes_Role_FieldDimension, dimensionId)
              .list<FieldDimensionTypesStoreObject>()
              .map((assoc) => assoc.navigateRole(FieldDimensionTypes_Role_ConceptDefinition)[ConceptDefinition_Name])
              .join('_');
            let selectedOption;
            if (dimensionValue === UnsetDashboardParameterOption) {
              selectedOption = buildUnsetOption();
            } else if (dimensionValue) {
              selectedOption = getOption(store, dimensionValue, []);
            }
            return (
              <SpacingLine key={dimensionId}>
                <div className={classes.tooltipLabelContainer}>
                  <ConceptTooltipText text={dimLabel} />
                </div>
                <div>
                  <SearchAndSelect
                    selectedOption={selectedOption}
                    readOnly
                  />
                </div>
              </SpacingLine>
            );
          })}
        <SpacingLine>
          <div className={classes.tooltipLabelContainer}>
            <ConceptTooltipText text={i18n`Value`} />
          </div>
          <div>
            <StoreNumberPickerInput
              initialValue={value}
              readOnly
              min={min}
              ticks={ticks}
              max={max}
              invalidColor={invalidColor}
              unit={unit}
              withProgress
              decimals={decimals}
            />
          </div>
        </SpacingLine>
        {time && (
          <SpacingLine>
            <div className={classes.tooltipLabelContainer}>
              <ConceptTooltipText text={i18n`Time`} />
            </div>
            <div>
              <div>
                <TextInputString
                  value={formatDisplayDate(new Date(time), periodicity ? periodicities[periodicity].getFormatString() : dateFormats.timestamp)}
                  readOnly
                />
              </div>
            </div>
          </SpacingLine>
        )}
        {(totalValue !== undefined) && (
          <SpacingLine>
            <div className={classes.tooltipLabelContainer}>
              <ConceptTooltipText text={i18n`Total`} />
            </div>
            <div>
              <StoreNumberPickerInput
                initialValue={totalValue}
                readOnly
                min={min}
                ticks={ticks}
                max={max}
                invalidColor={invalidColor}
                unit={unit}
                withProgress
                decimals={decimals}
              />
            </div>
          </SpacingLine>
        )}
      </SpacedContainer>
    </TooltipContainer>
  );
};

export default ViewsTooltip;
