import type { FunctionComponent } from 'react';
import base from '../../theme/base';
import { textLineHeight } from '../../theme/fontDefinition';
import makeStyles from '../../utils/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  barContainer: {
    background: theme.color.background.neutral.default,
    borderRadius: base.borderRadius.medium,
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  progressBar: {
    borderTopRightRadius: '0.4rem',
    borderBottomRightRadius: '0.4rem',
    background: theme.color.background.neutral.subtle,
  },
}), 'timelineProgress');

interface TimelineProgressProps {
  progress: number,
  min?: number,
  max?: number,
  widthInPx?: number,
  isChild?: boolean,
}

const TimelineProgress: FunctionComponent<TimelineProgressProps> = ({ progress, min, max, widthInPx, isChild = false }) => {
  const classes = useStyles();
  const containerHeight = isChild ? '2.6rem' : undefined;
  const barContainerHeight = isChild ? '2.2rem' : '3rem';
  const progressBarHeight = isChild ? textLineHeight.small : textLineHeight.main;

  if (min !== undefined && max !== undefined) {
    const width = `${Math.round(((progress - min) / max) * 100)}%`;
    return (
      <div className={classes.container} style={{ height: containerHeight, width: `${widthInPx}px` }}>
        <div
          className={classes.barContainer}
          style={{ height: barContainerHeight }}
        >
          <div
            className={classes.progressBar}
            style={{ width, height: progressBarHeight }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.container} style={{ height: containerHeight, width: `${widthInPx}px` }}>
        <div
          className={classes.barContainer}
          style={{ height: barContainerHeight }}
        />
      </div>
    );
  }
};

export default TimelineProgress;
