import classnames from 'classnames';
import type { CSSProperties, FunctionComponent } from 'react';
import { useState } from 'react';
import base from '../../../theme/base';
import { darken, isLight, lighten } from '../../../theme/colorUtils';
import makeStyles from '../../../utils/makeStyles';
import useSizeContext, { SizeVariant } from '../../../utils/useSizeContext';
import useTooltipRef from '../../../utils/useTooltipRef';
import Icon, { IconColorVariant, IconName } from '../../atoms/Icon';

const useStyles = makeStyles((theme) => ({
  colorContainer: {
    borderRadius: base.borderRadius.medium,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.default,
    width: '2.6rem',
    height: '2.6rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  colorSmall: {
    width: '2rem',
    height: '2rem',
  },
}), 'colorSquare');

const getStyleBackgroundColor = (color: string, isHovered = false): CSSProperties => {
  let displayColor: string;
  if (isHovered) {
    displayColor = isLight(color) ? darken(color, 10) : lighten(color, 10);
  } else {
    displayColor = color;
  }
  return {
    backgroundColor: displayColor,
    borderColor: isLight(displayColor) ? undefined : displayColor,
  };
};

interface ColorSquareProps {
  color: string,
  tooltip?: string,
  onClick?: () => void,
  isSelected?: boolean,
  clearable?: boolean,
}

const ColorSquare: FunctionComponent<ColorSquareProps> = ({ color, tooltip, onClick, isSelected = false, clearable = false }) => {
  const classes = useStyles();

  const { sizeVariant } = useSizeContext();

  const tooltipRef = useTooltipRef(tooltip, true);

  const [isHovered, setIsHovered] = useState<boolean>(false);

  return (
    <span
      ref={tooltipRef}
      className={classnames({ [classes.colorContainer]: true, [classes.colorSmall]: sizeVariant === SizeVariant.small })}
      style={getStyleBackgroundColor(color, isHovered)}
      onClick={onClick}
      onKeyDown={
        onClick === undefined
          ? undefined
          : (event) => {
            if (event.key === 'Enter') {
              onClick();
            }
          }
      }
      onMouseOver={onClick === undefined ? undefined : () => setIsHovered(true)}
      onFocus={onClick === undefined ? undefined : () => setIsHovered(true)}
      onMouseOut={onClick === undefined ? undefined : () => setIsHovered(false)}
      onBlur={onClick === undefined ? undefined : () => setIsHovered(false)}
      role="button"
      tabIndex={0}
    >
      {
        isSelected
          ? (
            <Icon
              name={isHovered && clearable ? IconName.close : IconName.check}
              colorVariant={isLight(color) ? IconColorVariant.primary : IconColorVariant.light}
            />
          )
          : null
      }
    </span>
  );
};
export default ColorSquare;
