import { ValidationStatus } from 'yooi-store';
import { textToRichText } from 'yooi-utils';
import { CommonAsType } from '../../common/fields/commonPropertyType';
import type { BusinessRuleRegistration } from '../../common/types/TypeModuleDslType';
import { adminOnlyAcl } from '../../conceptModule';
import { Concept, Concept_Name, ConceptDefinition, ConceptDefinition_IsCore, ConceptDefinition_RestrictedAccess, KinshipRelation } from '../../conceptModule/ids';
import { DataAsset_Type, DataAssetType_DefaultResourceType } from '../../dataAssetModule/ids';
import { Resource_Type, Resource_URL } from '../ids';
import { registerModel } from '../module';

const { type } = registerModel;

const checkResourceHasADataAsset: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  // only on resource update
  if (properties) {
    const resourceInstance = getObjectOrNull(id);
    if (resourceInstance && (properties[KinshipRelation] === null || properties[properties[KinshipRelation] as string] === null)) {
      return {
        rule: 'concept.resource.cannotRemoveResourceDataAssetLink',
        status: ValidationStatus.REJECTED,
      };
    } else if (!resourceInstance && (!properties[KinshipRelation] || !properties[properties[KinshipRelation] as string])) {
      return {
        rule: 'concept.resource.shouldBeLinkedToADataAsset',
        status: ValidationStatus.REJECTED,
      };
    }
  }
  return undefined;
};

const applyDefaultResourceTypeToNewlyCreatedResource: BusinessRuleRegistration = ({ getObject, getObjectOrNull }) => (_, { id, properties }) => {
  // Existing or undefined object ? Deleting object ? Don't care
  if (!id || getObjectOrNull(id) || !properties) {
    return undefined;
  }

  // Data asset has no type
  const dataAsset = properties[properties?.[KinshipRelation] as string] as string;
  if (!dataAsset || !getObjectOrNull(dataAsset)?.[DataAsset_Type]) {
    return undefined;
  }
  const defaultType = getObject(properties[properties[KinshipRelation] as string] as string).navigate(DataAsset_Type)?.[DataAssetType_DefaultResourceType];

  if (properties[Resource_Type] !== undefined || !defaultType) {
    return {
      rule: 'concept.dataAsset.types.applyDefaultResourceTypeToNewlyCreatedResource',
      status: ValidationStatus.ACCEPTED,
    };
  } else {
    return {
      rule: 'concept.dataAsset.types.applyDefaultResourceTypeToNewlyCreatedResource',
      status: ValidationStatus.ACCEPTED,
      generateSystemEvent: ({ updateObject }) => {
        updateObject(id, {
          [Resource_Type]: defaultType,
        });
      },
    };
  }
};

const applyResourceAliasFromUrl: BusinessRuleRegistration = () => (_, { id, properties }) => {
  if (!properties?.[Resource_URL]) {
    return undefined;
  } else {
    return {
      rule: 'concept.dataAsset.types.applyResourceAliasFromUrl',
      status: ValidationStatus.ACCEPTED,
      generateSystemEvent: ({ updateObject }) => {
        updateObject(id, {
          [Concept_Name]: textToRichText(properties[Resource_URL] as string),
        });
      },
    };
  }
};

type({
  label: 'Resource',
  extends: Concept,
  instanceOf: ConceptDefinition,
  extraProperties: {
    [ConceptDefinition_IsCore]: true,
    [ConceptDefinition_RestrictedAccess]: true,
  },
  businessRules: [
    checkResourceHasADataAsset,
    applyDefaultResourceTypeToNewlyCreatedResource,
    applyResourceAliasFromUrl,
  ],
});

type({
  label: 'ResourceType',
  accessControlList: {
    READ: () => () => ({ rule: 'resourceType.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'resourceType', 'WRITE'),
    DELETE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'resourceType', 'DELETE'),
  },
})
  .property({ label: 'ResourceType_Name', as: CommonAsType.string })
  .property({ label: 'ResourceType_Description', as: CommonAsType.string });

export const testables = { checkResourceHasADataAsset };
