import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { FieldStoreObject, WorkflowStoreObject } from 'yooi-modules/modules/conceptModule';
import { getConceptDefinitionValidFields } from 'yooi-modules/modules/conceptModule';
import { Field_IsCore, Field_Title, Workflow_Name, WorkflowField, WorkflowField_Workflow, WorkflowFieldFields } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { compareArray, compareString, comparing, joinObjects } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import Tooltip from '../../../components/atoms/Tooltip';
import Typo from '../../../components/atoms/Typo';
import MasterDetailList from '../../../components/molecules/MasterDetailList';
import OverflowMenu from '../../../components/molecules/OverflowMenu';
import { TableSortDirection } from '../../../components/molecules/Table';
import BaseLayout from '../../../components/templates/BaseLayout';
import Header from '../../../components/templates/Header';
import LeftPanel from '../../../components/templates/LeftPanel';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { formatOrUndef } from '../../../utils/stringUtils';
import useDeleteModal from '../../../utils/useDeleteModal';
import useNavigation, { NavigationElementContainer } from '../../../utils/useNavigation';
import { getFieldConfigurationHandler } from '../../_global/fields/FieldLibrary';
import { FieldEditionOptionMode } from '../../_global/fields/FieldLibraryTypes';
import HeaderFromStore, { HeaderFromStoreVariant } from '../../_global/HeaderFromStore';
import { searchFilterFunction } from '../../_global/listFilterFunctions';
import type { NavigationFilter } from '../../_global/navigationUtils';
import { NavigationTitlePathElements } from '../../_global/navigationUtils';
import TopBar from '../../_global/topBar/TopBar';
import type { ComparatorHandler } from '../../_global/useFilterAndSort';
import useFilterAndSort, { buildStringColumnComparatorHandler } from '../../_global/useFilterAndSort';
import { listFieldIdsOfWorkflowField } from '../../_global/workflow/workflowUtils';
import FieldOptionBlocks from './detail/__global/FieldOptionBlocks';

interface WorkflowFieldDetailPageProps {
  conceptDefinitionId: string,
  fieldId: string,
}

const WorkflowFieldDetailPage: FunctionComponent<WorkflowFieldDetailPageProps> = ({ conceptDefinitionId, fieldId }) => {
  const store = useStore();

  const navigation = useNavigation<NavigationFilter>();

  const field = store.getObject<FieldStoreObject>(fieldId);

  const [doDelete, deleteModal] = useDeleteModal({
    doDelete: () => {
      store.deleteObject(fieldId);
      navigation.push(conceptDefinitionId, { pathname: `/settings/organization/${conceptDefinitionId}`, hash: 'workflow' });
    },
    shouldConfirm: () => true,
    getModalProps: () => ({
      title: i18n`Are you sure that you want to delete this field?`,
      content: (<Typo>{i18n`It will also remove all associated elements.`}</Typo>),
    }),
  });

  const isEditable = !field[Field_IsCore];

  const fieldConfigurationHandler = getFieldConfigurationHandler(store, fieldId);

  const [search, setSearch] = useState<string | undefined>(undefined);
  const filterId = `${conceptDefinitionId}_${WorkflowField}`;
  const { generateList } = useFilterAndSort(
    filterId,
    getConceptDefinitionValidFields(store, conceptDefinitionId).filter((f) => isInstanceOf(f, WorkflowField)),
    searchFilterFunction(store, search, [Field_Title]),
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case Field_Title:
            return buildStringColumnComparatorHandler(key, direction);
          case WorkflowField_Workflow:
            return {
              comparator: comparing(compareString, direction === TableSortDirection.desc),
              extractValue: (f) => f.navigateOrNull<WorkflowStoreObject>(WorkflowField_Workflow)?.[Workflow_Name],
            } satisfies ComparatorHandler<FieldStoreObject, string | undefined>;
          case WorkflowFieldFields:
            return {
              comparator: comparing(compareArray, direction === TableSortDirection.desc),
              extractValue: (f) => listFieldIdsOfWorkflowField(store, f.id),
            } satisfies ComparatorHandler<FieldStoreObject, string[] | undefined>;
          default:
            return undefined;
        }
      },
      initial: { key: Field_Title, direction: TableSortDirection.asc },
    },
    undefined,
    [search]
  );

  return (
    <NavigationElementContainer
      element={{
        key: fieldId,
        name: formatOrUndef(field[Field_Title]),
        element: NavigationTitlePathElements.workflow,
        path: `/settings/organization/${conceptDefinitionId}/workflow/${fieldId}`,
      }}
    >
      <BaseLayout
        topBar={(<TopBar />)}
        leftPanel={(
          <LeftPanel>
            <MasterDetailList
              list={
                generateList().list
                  .map(({ item, ...entry }) => (
                    joinObjects(
                      entry,
                      {
                        render: () => (
                          <Typo maxLine={1}>
                            <Tooltip title={formatOrUndef(item[Field_Title])}>
                              <span>{formatOrUndef(item[Field_Title])}</span>
                            </Tooltip>
                          </Typo>
                        ),
                        to: () => ({ pathname: `/settings/organization/${conceptDefinitionId}/workflow/${item.id}` }),
                      }
                    )
                  ))
              }
              search={{ value: search, setValue: setSearch }}
            />
          </LeftPanel>
        )}
        header={(
          <Header
            firstLine={(
              <HeaderFromStore
                instanceId={fieldId}
                readOnly={Boolean(field[Field_IsCore])}
                propertyId={Field_Title}
                placeholder={i18n`Click to edit name`}
                variant={HeaderFromStoreVariant.compact}
                actions={[
                  (
                    <OverflowMenu
                      key="overflowMenu"
                      menuItems={[
                        {
                          key: 'open',
                          name: i18n`Open field`,
                          icon: IconName.output,
                          onClick: () => {
                            navigation.push(fieldId, { pathname: `/settings/organization/${conceptDefinitionId}/field/${fieldId}` });
                          },
                        },
                        {
                          key: 'duplicate',
                          name: i18n`Duplicate`,
                          icon: IconName.content_copy_outline,
                          hidden: !isEditable || fieldConfigurationHandler.duplicateFieldDefinition === undefined,
                          onClick: () => {
                            const newId = fieldConfigurationHandler.duplicateFieldDefinition?.({ parameterMap: {} });
                            navigation.push(newId ?? '', { pathname: `/settings/organization/${conceptDefinitionId}/workflow/${newId}` });
                          },
                        },
                        {
                          key: 'delete',
                          name: i18n`Delete`,
                          icon: IconName.delete,
                          hidden: !isEditable,
                          onClick: doDelete,
                          danger: true,
                        },
                      ]}
                    />
                  ),
                ]}
              />
            )}
          />
        )}
        content={(
          <>
            <FieldOptionBlocks conceptDefinitionId={conceptDefinitionId} fieldId={fieldId} mode={FieldEditionOptionMode.EverythingButFieldSpecific} />
            {deleteModal}
          </>
        )}
      />
    </NavigationElementContainer>
  );
};

export default WorkflowFieldDetailPage;
