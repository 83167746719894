import type { FunctionComponent, ReactElement } from 'react';
import type { FlyoutProps } from 'victory';

interface FlyoutWrapperProps extends FlyoutProps {
  children: (props: FlyoutProps) => ReactElement,
}

const FlyoutWrapper: FunctionComponent<FlyoutWrapperProps> = ({ children, ...props }) => (children(props));

export default FlyoutWrapper;
