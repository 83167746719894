import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import type { Column } from 'yooi-modules/modules/dashboardModule';
import Button, { ButtonVariant } from '../../../../../components/atoms/Button';
import { IconName } from '../../../../../components/atoms/Icon';
import { getSpacing, Spacing, spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeSelectorsClasses from '../../../../../utils/makeSelectorsClasses';
import makeStyles from '../../../../../utils/makeStyles';
import { getBlockIdsUsedInPath } from '../graphChartUtils';
import GraphChartBlockChip from './GraphChartBlockChip';

const selectorsClasses = makeSelectorsClasses('hoveredVisible');

const useStyles = makeStyles((theme) => ({
  buttonPaddedContainer: {
    visibility: 'hidden',
    display: 'flex',
    padding: spacingRem.xs,
    borderColor: theme.color.transparent,
    borderStyle: 'solid',
    borderWidth: '0.2rem',
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: getSpacing(Spacing.xs),
    '&:hover, &:focus, &:focus-within': {
      [`& .${selectorsClasses.hoveredVisible}`]: {
        visibility: 'visible',
      },
    },
  },
}), 'graphChartColumnConfiguration');

interface GraphChartColumnConfigurationProps {
  column: Column,
  previousColumn: Column,
  selectedBlockId: string | undefined,
  onBlockSelected: (id: string) => void,
  onAddBlock: (columnId?: string) => void,
  onDeleteBlock: (columnId: string, blockId: string) => void,
  onDuplicateBlock: (columnId: string, blockId: string) => void,
  onMoveBlockLeft: (blockId: string) => void,
  onMoveBlockRight: (blockId: string) => void,
}

const GraphChartColumnConfiguration: FunctionComponent<GraphChartColumnConfigurationProps> = ({
  column,
  previousColumn,
  selectedBlockId,
  onBlockSelected,
  onAddBlock,
  onDeleteBlock,
  onDuplicateBlock,
  onMoveBlockLeft,
  onMoveBlockRight,
}) => {
  const classes = useStyles();

  return (
    <div key={column.id} className={classes.columnContainer}>
      {column.blocks.map((block) => (
        <GraphChartBlockChip
          key={block.id}
          block={block}
          dependingBlockIds={block.path && previousColumn
            ? getBlockIdsUsedInPath(block.path, new Set(previousColumn.blocks.map(({ id }) => id)) ?? [])
            : []}
          isSelected={selectedBlockId === block.id}
          onSelect={() => onBlockSelected(block.id)}
          onDelete={() => onDeleteBlock(column.id, block.id)}
          onDuplicate={() => onDuplicateBlock(column.id, block.id)}
          onMoveLeft={() => onMoveBlockLeft(block.id)}
          onMoveRight={() => onMoveBlockRight(block.id)}
        />
      ))}
      <div className={classnames(classes.buttonPaddedContainer, selectorsClasses.hoveredVisible)}>
        <Button title={i18n`Add`} iconName={IconName.add} variant={ButtonVariant.tertiary} onClick={() => onAddBlock(column.id)} />
      </div>
    </div>
  );
};

export default GraphChartColumnConfiguration;
