import type { SingleParameterDefinition, PathStep } from 'yooi-modules/modules/conceptModule';
import { getInstanceLabelOrUndefined } from 'yooi-modules/modules/conceptModule';
import { isNumber, joinObjects } from 'yooi-utils';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { remToPx } from '../../../../utils/sizeUtils';
import { formatOrUndef } from '../../../../utils/stringUtils';
import StoreNumberPickerInput from '../../input/StoreNumberPickerInput';
import type { PathStepValidator } from '../../pathConfigurationHandler';
import { createPathConfigurationHandler } from '../../pathConfigurationHandler';
import type { EditionOptionSection } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';

enum WidgetDisplayType {
  Small = 'Small',
  Medium = 'Medium',
  Tall = 'Tall',
  Custom = 'Custom',
}

export type WidgetDisplay = {
  type: WidgetDisplayType.Small | WidgetDisplayType.Medium | WidgetDisplayType.Tall,
} | { type: WidgetDisplayType.Custom, height?: number };

export const defaultWidgetDisplay: WidgetDisplay = { type: WidgetDisplayType.Medium };

const defaultWidgetDisplayCustomHeightInRem = 45;

export const getWidgetHeight = (widgetDisplay: WidgetDisplay | undefined = defaultWidgetDisplay): number => {
  switch (widgetDisplay.type) {
    case WidgetDisplayType.Small:
      return remToPx(25);
    case WidgetDisplayType.Medium:
      return remToPx(35);
    case WidgetDisplayType.Tall:
      return remToPx(45);
    case WidgetDisplayType.Custom:
      return remToPx(widgetDisplay.height ?? defaultWidgetDisplayCustomHeightInRem);
  }
};

export const getWidgetDisplayTypeLabel = (widgetDisplayType: WidgetDisplayType): string => {
  switch (widgetDisplayType) {
    case WidgetDisplayType.Small:
      return i18n`Widget (Small)`;
    case WidgetDisplayType.Medium:
      return i18n`Widget (Medium)`;
    case WidgetDisplayType.Tall:
      return i18n`Widget (Tall)`;
    case WidgetDisplayType.Custom:
      return i18n`Widget (Custom)`;
  }
};

interface WidgetDisplayTypeOption {
  id: WidgetDisplayType,
  label: string,
}

const getWidgetDisplayTypeOption = (widgetDisplayType: WidgetDisplayType): WidgetDisplayTypeOption => ({
  id: widgetDisplayType,
  label: getWidgetDisplayTypeLabel(widgetDisplayType),
});

export const getTableGroupByLabel = (
  store: FrontObjectStore,
  label: string | undefined,
  path: PathStep[],
  parameterDefinitions?: SingleParameterDefinition[],
  validators?: PathStepValidator[]
): string => {
  const pathConfigurationHandler = createPathConfigurationHandler(
    store,
    parameterDefinitions ?? [],
    validators
  );
  const { fieldId } = pathConfigurationHandler.getLastFieldInformation(path) ?? {};
  const field = fieldId ? store.getObjectOrNull(fieldId) : undefined;
  if (!label && field) {
    return getInstanceLabelOrUndefined(store, field);
  }
  return formatOrUndef(label);
};

export const getWidgetEditionOptionSection = (widgetDisplay: WidgetDisplay, onChange: (newWidgetDisplay: WidgetDisplay) => void): EditionOptionSection => ({
  key: 'WidgetEditionOptionSection',
  title: i18n`Display as`,
  action: {
    type: EditionOptionTypes.custom,
    props: {
      render: () => {
        const searchAndSelect = (
          <SearchAndSelect
            selectedOption={getWidgetDisplayTypeOption(widgetDisplay.type)}
            computeOptions={(): WidgetDisplayTypeOption[] => Object.values(WidgetDisplayType).map(getWidgetDisplayTypeOption)}
            onSelect={(option) => {
              if (option) {
                onChange({ type: option.id });
              }
            }}
          />
        );
        if (widgetDisplay.type === WidgetDisplayType.Custom) {
          return (
            <SpacingLine>
              {searchAndSelect}
              <StoreNumberPickerInput
                initialValue={widgetDisplay.height ?? defaultWidgetDisplayCustomHeightInRem}
                onSubmit={(newValue) => {
                  const newValueNumber = typeof newValue === 'string' ? Number.parseFloat(newValue) : newValue;
                  if (newValueNumber !== null && isNumber(newValueNumber) && newValueNumber > 0) {
                    onChange(joinObjects(widgetDisplay, { height: newValueNumber }));
                  }
                }}
                decimals={1}
                unit="rem"
              />
            </SpacingLine>
          );
        } else {
          return (
            <SpacingLine>
              {searchAndSelect}
            </SpacingLine>
          );
        }
      },
    },
  },
  options: [],
});
