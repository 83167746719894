import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { getConceptDefinitionValidFields, getFieldUtilsHandler } from 'yooi-modules/modules/conceptModule';
import { ConceptDefinition } from 'yooi-modules/modules/conceptModule/ids';
import { CardFieldActionTypes } from 'yooi-modules/modules/dashboardModule';
import type { CardFieldButton } from 'yooi-modules/modules/dashboardModule/fields/cardField';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreReadOnly } from 'yooi-store';
import type { WithMandatoryField } from 'yooi-utils';
import { filterNullOrUndefined, isValidLink, joinObjects } from 'yooi-utils';
import { ButtonVariant } from '../../../../components/atoms/Button';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Typo, { TypoVariant } from '../../../../components/atoms/Typo';
import SimpleInput from '../../../../components/inputs/strategy/SimpleInput';
import TextInputString from '../../../../components/inputs/TextInputString';
import Chip from '../../../../components/molecules/Chip';
import type { CompositeLine } from '../../../../components/molecules/CompositeField';
import CompositeField, { CompositeFieldCloseReasons } from '../../../../components/molecules/CompositeField';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import TableCell from '../../../../components/molecules/TableCell';
import TableLine from '../../../../components/molecules/TableLine';
import DataTable from '../../../../components/templates/DataTable';
import useAcl from '../../../../store/useAcl';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { formatOrUndef } from '../../../../utils/stringUtils';
import FormInput from '../../input/FormInput';
import { defaultOptionComparator, getChipOptions, getUnknownChip } from '../../modelTypeUtils';
import { getFieldHandler } from '../FieldLibrary';

const useStyles = makeStyles({
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacingRem.xs,
    marginRight: spacingRem.xs,
  },
}, 'cardFieldActionComposite');

const getErrorMessage = (store: ObjectStoreReadOnly, value: CardFieldButton) => {
  if (value.action === CardFieldActionTypes.CreateInstance) {
    return value.data && store.getObjectOrNull(value.data) ? undefined : i18n`No concept selected`;
  } else if (value.action === CardFieldActionTypes.OpenUrl) {
    if (!value.data) {
      return i18n`Missing URL`;
    } else {
      return isValidLink(value.data) ? undefined : i18n`Invalid URL`;
    }
  } else {
    return undefined;
  }
};

interface CardFieldActionCompositeProps {
  value: CardFieldButton,
  onSubmit: (newValue: CardFieldButton) => void,
}

const CardFieldActionComposite: FunctionComponent<CardFieldActionCompositeProps> = ({ value: initialValue, onSubmit: doSubmit }) => {
  const classes = useStyles();

  const store = useStore();
  const aclHandler = useAcl();

  const [showNewDefaultValueLine, setShowNewDefaultValueLine] = useState(false);

  // Options need to be defined inside component as i18n requires to be in the React lifecycle
  const options: Record<CardFieldActionTypes, { id: CardFieldActionTypes, label: string, tooltip: string }> = {
    [CardFieldActionTypes.CreateInstance]: { id: CardFieldActionTypes.CreateInstance, label: i18n`Create instance`, tooltip: i18n`Create instance` },
    [CardFieldActionTypes.OpenUrl]: { id: CardFieldActionTypes.OpenUrl, label: i18n`Open URL`, tooltip: i18n`Open URL` },
  };

  return (
    <FormInput
      initialValue={initialValue}
      onSubmit={(newValue) => {
        if (newValue) {
          doSubmit(newValue);
        }
      }}
    >
      {({ value, onChange, onSubmit, onCancel }) => {
        if (!value) {
          // This case will never happen but FormInput API force undefined
          return null;
        }

        const errorMessage = getErrorMessage(store, value);

        return (
          <CompositeField
            onOpenDropdown={() => {}}
            onCloseDropdown={(reason) => {
              setShowNewDefaultValueLine(false);
              if (reason === CompositeFieldCloseReasons.validate) {
                onSubmit(value);
              } else {
                onCancel();
              }
            }}
            headerLinesRenderers={[
              {
                id: 'title',
                render: () => {
                  if (!value.action || !value.data) {
                    return (
                      <SpacingLine>
                        <Typo maxLine={1}>{i18n`Set the action`}</Typo>
                        <div className={classes.errorContainer}>
                          <Icon name={IconName.dangerous} tooltip={errorMessage} colorVariant={IconColorVariant.error} />
                        </div>
                      </SpacingLine>
                    );
                  }

                  switch (value.action) {
                    case CardFieldActionTypes.CreateInstance: {
                      const chipOptions = getChipOptions(store, value.data) ?? getUnknownChip(value.data);

                      return (
                        <SpacingLine>
                          <Typo>{i18n`Create instance of type:`}</Typo>
                          <Chip
                            text={formatOrUndef(chipOptions.label)}
                            tooltip={formatOrUndef(chipOptions.tooltip)}
                            squareColor={chipOptions.squareColor}
                            color={chipOptions.color}
                            icon={chipOptions.icon}
                          />
                        </SpacingLine>
                      );
                    }
                    case CardFieldActionTypes.OpenUrl: {
                      return (
                        <SpacingLine>
                          <Typo>{i18n`Open URL:`}</Typo>
                          <Typo maxLine={1} variant={TypoVariant.code}>{value.data}</Typo>
                          <div className={classes.errorContainer}>
                            {errorMessage ? (<Icon name={IconName.dangerous} tooltip={errorMessage} colorVariant={IconColorVariant.error} />) : undefined}
                          </div>
                        </SpacingLine>
                      );
                    }
                    default: {
                      return (
                        <SpacingLine>
                          <Typo maxLine={1}>{i18n`Set the action`}</Typo>
                        </SpacingLine>
                      );
                    }
                  }
                },
              },
            ]}
            getDropdownSectionDefinitions={() => {
              const lines: CompositeLine[] = [
                {
                  id: 'action',
                  title: i18n`Action`,
                  render: (
                    <SearchAndSelect<{ id: CardFieldActionTypes, label: string, tooltip: string }>
                      placeholder={i18n`Select action`}
                      computeOptions={() => Object.values(options)}
                      selectedOption={value.action ? options[value.action] : undefined}
                      onSelect={(selectedOption) => onChange({ id: value.id, label: value.label, variant: value.variant, action: selectedOption?.id })}
                      statusIcon={
                        !value.action || !Object.values(CardFieldActionTypes).includes(value.action)
                          ? { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`No action selected` } : undefined
                      }
                      clearable
                    />
                  ),
                },
              ];

              if (value.action === CardFieldActionTypes.CreateInstance) {
                lines.push({
                  id: 'conceptDefinition',
                  title: i18n`Type of instance`,
                  render: (
                    <SearchAndSelect
                      placeholder={i18n`Select type of instance to create`}
                      computeOptions={() => store.getObject(ConceptDefinition)
                        .navigateBack(Class_Instances)
                        .map(({ id }) => getChipOptions(store, id))
                        .filter(filterNullOrUndefined)
                        .sort(defaultOptionComparator)}
                      selectedOption={value.data ? getChipOptions(store, value.data) ?? getUnknownChip(value.data) : undefined}
                      onSelect={(newValue) => onChange(joinObjects(value, { data: newValue?.id, defaultValues: {} }))}
                      statusIcon={errorMessage ? { icon: IconName.dangerous, color: IconColorVariant.error, message: errorMessage } : undefined}
                      clearable
                    />
                  ),
                });

                const conceptDefinitionId = value.data;
                if (conceptDefinitionId && store.getObjectOrNull(conceptDefinitionId)) {
                  const selectedFieldIds = Object.keys(value.defaultValues ?? {});
                  const handlers = selectedFieldIds
                    .map((id) => getFieldHandler(store, id))
                    .filter(filterNullOrUndefined)
                    .filter((handler): handler is WithMandatoryField<typeof handler, 'input'> => Boolean(handler?.input))
                    .filter((handler) => getFieldUtilsHandler(store, handler.id).resolveConfiguration().formula === undefined);

                  lines.push({
                    id: 'default',
                    title: i18n`Default values`,
                    isVertical: true,
                    render: (
                      <DataTable
                        list={handlers.map((handler) => ({ key: handler.id, type: 'item', item: handler, color: undefined }))}
                        columnsDefinition={[
                          {
                            propertyId: 'field',
                            name: i18n`Field`,
                            cellRender: ({ id }) => (
                              <SearchAndSelect selectedOption={getChipOptions(store, id)} readOnly />
                            ),
                          },
                          {
                            propertyId: 'value',
                            name: i18n`Value`,
                            cellRender: ({ id, input }) => input.render({
                              value: value.defaultValues?.[id],
                              onSubmit: (newValue) => onChange(joinObjects(value, { defaultValues: joinObjects(value.defaultValues, { [id]: newValue }) })),
                              readOnly: false,
                              aclHandler,
                            }),
                          },
                        ]}
                        fullWidth
                        newItemButtonVariant={ButtonVariant.tertiary}
                        newItemTitle={i18n`Add`}
                        newItemIcon={IconName.add}
                        onNewItem={() => setShowNewDefaultValueLine(true)}
                        inlineCreation={{
                          render: showNewDefaultValueLine ? (
                            <TableLine>
                              <TableCell>
                                <SearchAndSelect
                                  computeOptions={() => (
                                    getConceptDefinitionValidFields(store, conceptDefinitionId)
                                      .filter(({ id }) => !selectedFieldIds.includes(id))
                                      .map(({ id }) => getFieldHandler(store, id))
                                      .filter(filterNullOrUndefined)
                                      .filter((handler): handler is WithMandatoryField<typeof handler, 'input'> => Boolean(handler?.input))
                                      .filter((handler) => getFieldUtilsHandler(store, handler.id).resolveConfiguration().formula === undefined)
                                      .map(({ id }) => getChipOptions(store, id))
                                      .filter(filterNullOrUndefined)
                                      .sort(defaultOptionComparator)
                                  )}
                                  onSelect={(option) => {
                                    if (option) {
                                      onChange(joinObjects(
                                        value,
                                        { defaultValues: joinObjects(value.defaultValues, { [option.id]: getFieldHandler(store, option.id)?.input?.getInitialState() }) }
                                      ));
                                    }
                                    setShowNewDefaultValueLine(false);
                                  }}
                                  onClickAway={() => {
                                    setShowNewDefaultValueLine(false);
                                  }}
                                  onEscape={() => {
                                    setShowNewDefaultValueLine(false);
                                  }}
                                  editOnMount
                                />
                              </TableCell>
                              <TableCell />
                              <TableCell />
                            </TableLine>
                          ) : null,
                        }}
                        linesActions={({ id }) => [{
                          key: 'remove',
                          name: i18n`Remove`,
                          icon: IconName.delete,
                          danger: true,
                          onClick: () => {
                            onChange(joinObjects(
                              value,
                              { defaultValues: Object.fromEntries(Object.entries(value.defaultValues ?? {}).filter(([fId]) => fId !== id)) }
                            ));
                          },
                        }]}
                      />
                    ),
                  });
                }
              } else if (value.action === CardFieldActionTypes.OpenUrl) {
                lines.push({
                  id: 'url',
                  title: i18n`URL`,
                  info: `${i18n`Enter an absolute url "https://url.com" or an url that is relative to ${window.location.host} "/concept/{conceptId}/{instanceId}"`}`,
                  render: (
                    <SimpleInput
                      initialValue={value.data}
                      onSubmit={(newValue) => onChange(joinObjects(value, { data: newValue }))}
                    >
                      {({ value: url, ...props }) => (
                        <TextInputString
                          placeholder={i18n`Add URL`}
                          {...props}
                          value={url}
                          error={getErrorMessage(store, joinObjects(value, { data: url }))}
                        />
                      )}
                    </SimpleInput>
                  ),
                });
              }

              return [{ id: 'main', lines }];
            }}
          />
        );
      }}
    </FormInput>
  );
};

export default CardFieldActionComposite;
