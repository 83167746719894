import type { FunctionComponent } from 'react';
import { v4 as uuid } from 'uuid';
import type { NumberColorStepsValue, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { NumberColorStepValueType } from 'yooi-modules/modules/conceptModule';
import Button, { ButtonVariant } from '../../../../components/atoms/Button';
import { IconName } from '../../../../components/atoms/Icon';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import { getSpacing, Spacing, spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';
import NumberColorStepInput from './NumberColorStepInput';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    rowGap: spacingRem.s,
  },
  padded: {
    display: 'flex',
    marginLeft: getSpacing(Spacing.s, 0.1 /* border */),
  },
}, 'numberColorStepsInput');

interface NumberColorStepsInputProps {
  steps: NumberColorStepsValue[],
  onChange: (steps: NumberColorStepsValue[]) => void,
  parameterDefinitions: SingleParameterDefinition[],
  readOnly: boolean,
}

const NumberColorStepsInput: FunctionComponent<NumberColorStepsInputProps> = ({ steps, onChange, parameterDefinitions, readOnly }) => {
  const classes = useStyles();

  const usageVariant = useUsageContext();

  const button = (
    <SpacingLine>
      <Button
        title={i18n`Add range`}
        iconName={IconName.add}
        onClick={() => {
          onChange([...steps, { id: uuid(), type: NumberColorStepValueType.value, color: undefined, value: undefined }]);
        }}
        variant={ButtonVariant.secondary}
      />
    </SpacingLine>
  );

  return (
    <div className={classes.container}>
      {steps.map((step) => (
        <NumberColorStepInput<NumberColorStepsValue>
          key={step.id}
          value={step}
          parameterDefinitions={parameterDefinitions}
          onChange={(newValue) => {
            onChange(steps.map((s) => (s.id === step.id ? newValue : s)));
          }}
          onDelete={() => {
            onChange(steps.filter((s) => s.id !== step.id));
          }}
          readOnly={readOnly}
        />
      ))}
      {usageVariant === UsageVariant.inForm && !readOnly ? button : null}
      {usageVariant !== UsageVariant.inForm && !readOnly ? (<div className={classes.padded}>{button}</div>) : null}
    </div>
  );
};

export default NumberColorStepsInput;
