import type { FunctionComponent, ReactElement } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { LineChartViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { joinObjects } from 'yooi-utils';
import type { ChartTooltipData } from '../../../../components/charts/ChartTypes';
import LineChart from '../../../../components/charts/LineChart/LineChart';
import Loading from '../../../../components/molecules/Loading';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import base from '../../../../theme/base';
import { buildPadding, Spacing, spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import { remToPx, SCROLLBAR_WIDTH_IN_REM } from '../../../../utils/sizeUtils';
import useDeepMemo from '../../../../utils/useDeepMemo';
import type { WidgetDisplay } from '../../fields/_global/widgetUtils';
import { getWidgetHeight } from '../../fields/_global/widgetUtils';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { useViewFilters } from '../../filter/useViewFilters';
import BlockViewError from '../common/BlockViewError';
import ViewsTooltip from '../common/ViewsTooltip';
import { getViewDefinitionHandler } from '../viewDsl';
import { isResolutionError } from '../viewResolutionUtils';
import type { LineChartViewDefinitionHandler } from './lineChartViewDefinitionHandler';
import type { LineChartResolutionLoaded, LineChartSeriesRowValue } from './lineChartViewResolution';
import { isLineChartLoaded } from './lineChartViewResolution';

const useStyles = makeStyles((theme) => ({
  container: joinObjects(
    {
      marginBottom: spacingRem.blockSpacing,
      boxShadow: base.shadowElevation.low,
      borderRadius: base.borderRadius.medium,
      background: theme.color.background.neutral.default,
      borderLeftWidth: '0.1rem',
      borderLeftStyle: 'solid',
      borderLeftColor: theme.color.transparent,
    },
    buildPadding({ y: Spacing.xs, left: Spacing.splus, right: Spacing.xs })
  ),
  preventChartFlick: {
    position: 'absolute',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
}), 'lineChartViewBlock');

interface LineChartViewBlockProps {
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinition: LineChartViewStoredDefinition,
  parametersMapping: ParametersMapping,
  widgetDisplay: WidgetDisplay,
}

const renderLineChartTooltip = (series: LineChartResolutionLoaded) => ({
  seriesFieldId,
  seriesLabel,
  dimensionsMapping,
  datum,
  totalValue,
}: ChartTooltipData<LineChartSeriesRowValue>): ReactElement => (
  <ViewsTooltip
    fieldId={seriesFieldId}
    seriesLabel={seriesLabel}
    dimensionsMapping={dimensionsMapping}
    value={datum.value}
    time={datum.time}
    periodicity={series.periodicity}
    totalValue={totalValue}
  />
);

const LineChartViewBlock: FunctionComponent<LineChartViewBlockProps> = ({
  viewDimensions,
  viewFilters,
  viewDefinition,
  parametersMapping,
  widgetDisplay,
}) => {
  const classes = useStyles();

  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();

  const height = getWidgetHeight(widgetDisplay);
  const filterConfiguration = useViewFilters(viewFilters, viewDimensions);

  const viewHandler = getViewDefinitionHandler(viewDefinition) as LineChartViewDefinitionHandler;

  const lineChartResolution = useDeepMemo(
    () => viewHandler.resolveView(store, { viewDimensions, parametersMapping, userId: loggedUserId, filterConfiguration, aclHandler }),
    [store.getSerial(), viewDimensions, viewDefinition, parametersMapping, filterConfiguration]
  );

  if (isResolutionError(lineChartResolution)) {
    return <BlockViewError error={lineChartResolution.error} />;
  } else if (isLineChartLoaded(lineChartResolution)) {
    return (
      <div className={classes.container}>
        <AutoSizer disableHeight>
          {({ width = 0 }) => (
            <div style={{ height }}>
              <div className={classes.preventChartFlick} style={{ height, width }}>
                <LineChart
                  minValue={lineChartResolution.minValue}
                  maxValue={lineChartResolution.maxValue}
                  steps={lineChartResolution.steps}
                  xDomain={lineChartResolution.domain.xDomain}
                  yDomain={lineChartResolution.domain.yDomain}
                  series={lineChartResolution.series}
                  labels={lineChartResolution.labels}
                  height={height - remToPx(SCROLLBAR_WIDTH_IN_REM) - (2 * remToPx(spacingRem.xs))}
                  width={width - remToPx(SCROLLBAR_WIDTH_IN_REM)}
                  renderTooltip={renderLineChartTooltip(lineChartResolution)}
                  seriesStacked={lineChartResolution.seriesStacked}
                />
              </div>
            </div>
          )}
        </AutoSizer>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default LineChartViewBlock;
