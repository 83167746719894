import type { FunctionComponent } from 'react';
import type { DateValue } from './objectRenderType';
import TagRenderer from './TagRenderer';

interface DateRendererProps {
  date: DateValue,
}

const DateRenderer: FunctionComponent<DateRendererProps> = ({ date: { value } }) => (
  <TagRenderer value={value} hint={new Date(value).toISOString()} type="date" />
);

export default DateRenderer;
