import type { FunctionComponent, ReactElement } from 'react';
import { parseFormula } from 'yooi-modules/modules/conceptModule';
import type { Constant } from 'yooi-utils';
import { isNaS } from 'yooi-utils';
import Tooltip from '../../../components/atoms/Tooltip';
import Typo from '../../../components/atoms/Typo';
import Table from '../../../components/molecules/Table';
import TableBody from '../../../components/molecules/TableBody';
import TableCell from '../../../components/molecules/TableCell';
import TableInnerCellContainer from '../../../components/molecules/TableInnerCellContainer';
import TableLine from '../../../components/molecules/TableLine';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../components/templates/BlockTitle';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import { FontVariant } from '../../../theme/fontDefinition';
import { buildPadding, Spacing, spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useTheme from '../../../utils/useTheme';

const stringify = (value: unknown, prettify = false): string => {
  // eslint-disable-next-line yooi/number-is-nan-call
  if (Number.isNaN(value)) {
    return 'NaN';
  } else if (isNaS(value)) {
    return 'NaS';
  } else if (value === undefined) {
    return 'NULL';
  } else if (value === true) {
    return 'TRUE';
  } else if (value === false) {
    return 'FALSE';
  } else {
    return JSON.stringify(value, undefined, prettify ? 2 : undefined);
  }
};

const useStyles = makeStyles({
  outputLine: {
    display: 'inline-flex',
    columnGap: spacingRem.xs,
    alignItems: 'baseline',
  },
}, 'formulaFunctionBlock');

interface FormulaFunctionBlockProps {
  name: string,
  anchor?: string,
  syntax: string,
  output: string | string[],
  description: string,
  example: (string | (string | Constant)[])[],
}

const FormulaFunctionBlock: FunctionComponent<FormulaFunctionBlockProps> = ({ name, anchor, syntax, output, description, example }) => {
  const theme = useTheme();
  const classes = useStyles();

  const renderExampleCell = (rawFormula: string | (string | Constant)[]) => {
    let result;
    try {
      const formula = typeof rawFormula === 'string' ? rawFormula : rawFormula.reduce<string>((acc, part) => `${acc}${typeof part === 'string' ? part : part.name}`, '');
      const constants = typeof rawFormula === 'string' ? [] : rawFormula.filter((part): part is Constant => typeof part !== 'string');

      const executeFormula = parseFormula(
        formula,
        {
          getInput: (rawInputName) => {
            const inputName = rawInputName.toLowerCase();
            return constants.find((constant) => constant.name.toLowerCase() === inputName);
          },
        }
      );
      const computeFormula = executeFormula.createCompute();
      result = stringify(computeFormula(() => {}));
    } catch (_) {
      result = 'ERROR';
    }

    return (
      <TableCell>
        <TableInnerCellContainer padding={buildPadding(Spacing.s)}>
          {
            typeof rawFormula === 'string'
              ? (<Typo variant={FontVariant.code}>{`${rawFormula} = ${result}`}</Typo>)
              : (
                <>
                  {
                    rawFormula.reduce<ReactElement | null>((acc, part) => (
                      <>
                        {acc}
                        {
                          typeof part === 'string'
                            ? (<Typo variant={FontVariant.code}>{part}</Typo>)
                            : (
                              <Tooltip title={stringify(part.value, true)}>
                                <Typo variant={FontVariant.code} color={theme.color.text.info}>{part.name}</Typo>
                              </Tooltip>
                            )
                        }
                      </>
                    ), null)

                  }
                  <Typo variant={FontVariant.code}>{` = ${result}`}</Typo>
                </>
              )
          }
        </TableInnerCellContainer>
      </TableCell>
    );
  };

  return (
    <VerticalBlock asBlockContent compact>
      <BlockTitle title={name} variant={BlockTitleVariant.secondary} anchor={anchor ?? `#${name.toLowerCase()}`} />
      <BlockContent padded>
        <Table width="100rem">
          <TableBody>
            <TableLine>
              <TableCell width="12rem">
                <TableInnerCellContainer padding={buildPadding(Spacing.s)}>
                  <Typo variant={FontVariant.tabTitle}>{i18n`Description`}</Typo>
                </TableInnerCellContainer>
              </TableCell>
              <TableCell>
                <TableInnerCellContainer padding={buildPadding(Spacing.s)}>
                  <Typo>{description}</Typo>
                </TableInnerCellContainer>
              </TableCell>
            </TableLine>
            <TableLine>
              <TableCell>
                <TableInnerCellContainer padding={buildPadding(Spacing.s)}>
                  <Typo variant={FontVariant.tabTitle}>{i18n`Syntax`}</Typo>
                </TableInnerCellContainer>
              </TableCell>
              <TableCell>
                <TableInnerCellContainer padding={buildPadding(Spacing.s)}>
                  <Typo variant={FontVariant.code}>{syntax}</Typo>
                </TableInnerCellContainer>
              </TableCell>
            </TableLine>
            <TableLine>
              <TableCell>
                <TableInnerCellContainer padding={buildPadding(Spacing.s)}>
                  <Typo variant={FontVariant.tabTitle}>{i18n`Output`}</Typo>
                </TableInnerCellContainer>
              </TableCell>
              <TableCell>
                <TableInnerCellContainer padding={buildPadding(Spacing.s)}>
                  {
                    typeof output === 'string'
                      ? (<Typo variant={FontVariant.code}>{output}</Typo>)
                      : (
                        <span className={classes.outputLine}>
                          {
                            output.reduce<ReactElement | null>((acc, type, index) => {
                              const entry = (<Typo variant={FontVariant.code}>{type}</Typo>);
                              if (acc === null) {
                                return entry;
                              } else {
                                return (
                                  <>
                                    {acc}
                                    <Typo>{index === output.length - 1 ? i18n`or` : ','}</Typo>
                                    {entry}
                                  </>
                                );
                              }
                            }, null)
                          }
                        </span>
                      )
                  }
                </TableInnerCellContainer>
              </TableCell>
            </TableLine>
            {
              example.length > 0
                ? (
                  <TableLine>
                    <TableCell rowSpan={example.length}>
                      <TableInnerCellContainer padding={buildPadding(Spacing.s)}>
                        <Typo variant={FontVariant.tabTitle}>{i18n`Example`}</Typo>
                      </TableInnerCellContainer>
                    </TableCell>
                    {renderExampleCell(example[0])}
                  </TableLine>
                )
                : undefined
            }
            {example.slice(1).map((formula) => (<TableLine key={JSON.stringify(formula)}>{renderExampleCell(formula)}</TableLine>))}
          </TableBody>
        </Table>
      </BlockContent>
    </VerticalBlock>
  );
};

export default FormulaFunctionBlock;
