// eslint-disable-next-line yooi/no-restricted-dependency
import { getPlatformAccentColor } from '../utils/options';
import createCustomTheme from './customTheme';
import light from './light';

const accentColor = getPlatformAccentColor();

const appliedTheme = accentColor === undefined ? light : createCustomTheme(accentColor);

export default appliedTheme;
