import type { FilterCondition, Filters, FilterValue, FilterValuePath, FilterValueRaw } from '../../moduleType';
import { FilterValueType } from '../../moduleType';
import type { ParametersMapping } from '../parametersUtils';

export type SanitizeFilterValue<T> = (value: T) => T;

export interface GlobalFilter {
  globalFilters?: Filters,
  globalParametersMapping?: ParametersMapping,
}

export interface FrontEndFilter extends FilterCondition {
  label?: string,
}

export interface BackendFilterCondition<S, T, U> {
  sanitizeValue: SanitizeFilterValue<S>,
  isFilterApplicable: (value: S) => boolean,
  filterFunction: (leftValue: U, rightValue: T, parametersMapping: ParametersMapping) => boolean,
}

export enum InstanceReferenceType {
  parameter = 'parameter',
  instance = 'instance',
}

export interface InstanceReferenceValue {
  id: string,
  type: InstanceReferenceType,
}

export const isFilterValuePath = (value: FilterValue<unknown> | null | undefined): value is FilterValuePath => Boolean(value) && value?.type === FilterValueType.path;
export const isFilterValueRaw = <T>(value: FilterValue<unknown> | null | undefined): value is FilterValueRaw<T> => Boolean(value) && value?.type === FilterValueType.raw;

export type FieldFilterCondition<T, U> = BackendFilterCondition<FilterValueRaw<T>, T, U | undefined>;
export type SingleInstanceFilterCondition<T> = BackendFilterCondition<FilterValue<T>, T, string | undefined>;
export type MultipleInstanceFilterCondition<T> = BackendFilterCondition<FilterValue<T>, T, string[]>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BackendFilterConditions = Record<string, BackendFilterCondition<any, any, any>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FieldFilterConditions<U> = Record<string, FieldFilterCondition<any, U>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SingleInstanceFilterConditions = Record<string, SingleInstanceFilterCondition<any>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MultipleInstanceFilterConditions = Record<string, MultipleInstanceFilterCondition<any>>;

export interface ConceptInstanceFilterConditions extends SingleInstanceFilterConditions {
  EQUALS: SingleInstanceFilterCondition<InstanceReferenceValue>,
  NOT_EQUALS: SingleInstanceFilterCondition<InstanceReferenceValue>,
  NOT_IN: SingleInstanceFilterCondition<InstanceReferenceValue[]>,
  IN: SingleInstanceFilterCondition<InstanceReferenceValue[]>,
  IS_EMPTY: SingleInstanceFilterCondition<undefined>,
  IS_NOT_EMPTY: SingleInstanceFilterCondition<undefined>,
}

export interface ConceptMultipleInstanceFilterConditions extends MultipleInstanceFilterConditions {
  CONTAINS: MultipleInstanceFilterCondition<InstanceReferenceValue[]>,
  CONTAINS_SOME: MultipleInstanceFilterCondition<InstanceReferenceValue[]>,
  DOES_NOT_CONTAIN: MultipleInstanceFilterCondition<InstanceReferenceValue[]>,
  IS_EMPTY: MultipleInstanceFilterCondition<undefined>,
  IS_NOT_EMPTY: MultipleInstanceFilterCondition<undefined>,
}
