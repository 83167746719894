import { equals } from 'ramda';
import type { FunctionComponent } from 'react';
import { useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import type { AssociationFilterStoreObject, Filters, InstanceReferenceValue, SingleParameterDefinition, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { FilterValueType, getInstanceLabelOrUndefined, InstanceReferenceType, isFilterNode, isFilterValueRaw } from 'yooi-modules/modules/conceptModule';
import { AssociationFilter_Path } from 'yooi-modules/modules/conceptModule/ids';
import useAuth from '../../../store/useAuth';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { formatOrUndef } from '../../../utils/stringUtils';
import useForceUpdate from '../../../utils/useForceUpdate';
import { getModelBackgroundColor, resolveConceptColorValue } from '../conceptDisplayUtils';
import { getQuickFilterInstances, getQuickFilterLabel, getQuickFilterOperator, getQuickFiltersTargetedConceptDefinitionId } from './quickFiltersUtils';
import type { ToggleEntry } from './ToggleBlock';
import ToggleBlock from './ToggleBlock';

interface QuickFilterBlockProps {
  filters: Record<string, Filters>,
  onChange: (fun: (current: Record<string, Filters> | undefined) => Record<string, Filters> | undefined) => void,
  quickFilterId: string,
  parameterDefinitions: SingleParameterDefinition[],
  getPrefilteredInstances?: (conceptDefinitionIdToFilter: string, quickFilter: AssociationFilterStoreObject) => string[] | undefined,
  parametersMapping?: ParametersMapping,
}

const QuickFilterBlock: FunctionComponent<QuickFilterBlockProps> = ({ filters, onChange, quickFilterId, parameterDefinitions, getPrefilteredInstances, parametersMapping }) => {
  const store = useStore();
  const quickFilter = store.getObject<AssociationFilterStoreObject>(quickFilterId);
  const { loggedUserId } = useAuth();

  const forceUpdate = useForceUpdate();

  const filterFromSessionStorage = filters[quickFilterId];
  const activeElementsFromSessionStorage = (
    filterFromSessionStorage && !isFilterNode(filterFromSessionStorage) && isFilterValueRaw<InstanceReferenceValue[]>(filterFromSessionStorage.rightValue)
      ? filterFromSessionStorage.rightValue.raw.map((v) => v.id)
      : []
  );
  const lastActiveElementsFromSessionStorageRef = useRef(activeElementsFromSessionStorage);
  const activeElementsRef = useRef(activeElementsFromSessionStorage);
  if (!equals(lastActiveElementsFromSessionStorageRef.current, activeElementsFromSessionStorage)) {
    // Session storage changed, reset states
    lastActiveElementsFromSessionStorageRef.current = activeElementsFromSessionStorage;
    activeElementsRef.current = activeElementsFromSessionStorage;
  }

  const debounceFilterStorageUpdate = useDebouncedCallback((newValue: string[]) => {
    if (newValue.length === 0) {
      onChange((current) => {
        const newFilters = { ...current };
        delete newFilters[quickFilterId];
        return newFilters;
      });
    } else {
      const operator = getQuickFilterOperator(store, quickFilter[AssociationFilter_Path]);
      if (operator) {
        onChange((current) => {
          const newState = { ...current };
          newState[quickFilterId] = {
            leftValue: quickFilter[AssociationFilter_Path],
            operator,
            rightValue: { type: FilterValueType.raw, raw: newValue.map((value) => ({ id: value, type: InstanceReferenceType.instance })) },
          };
          return newState;
        });
      }
    }
  }, 500);

  const quickFilterTargetConceptDefinitionId = getQuickFiltersTargetedConceptDefinitionId(store, quickFilter[AssociationFilter_Path], parameterDefinitions);
  const quickFilterConceptDefinition = quickFilterTargetConceptDefinitionId ? store.getObjectOrNull(quickFilterTargetConceptDefinitionId) : undefined;
  if (!quickFilterConceptDefinition) {
    return null;
  }

  const onToggle = (toggleId: string) => {
    if (activeElementsRef.current.includes(toggleId)) {
      activeElementsRef.current = activeElementsRef.current.filter((e) => e !== toggleId);
    } else {
      activeElementsRef.current = [...activeElementsRef.current, toggleId];
    }
    debounceFilterStorageUpdate(activeElementsRef.current);
    forceUpdate();
  };
  const onClear = () => {
    activeElementsRef.current = [];
    debounceFilterStorageUpdate(activeElementsRef.current);
    forceUpdate();
  };

  const instances = getQuickFilterInstances(
    store,
    quickFilter,
    parameterDefinitions,
    loggedUserId,
    getPrefilteredInstances,
    parametersMapping
  );

  // Computing name & color is very costly, to avoid heavy operations, we lazy load the data
  const toggles = instances.map((concept): ToggleEntry => new Proxy({ id: concept.id } as ToggleEntry, {
    get: (target: ToggleEntry, property: keyof ToggleEntry) => {
      if (Object.prototype.hasOwnProperty.call(target, property)) {
        return target[property];
      } else if (property === 'name') {
        // This is expected as we want to cache the value
        // eslint-disable-next-line no-param-reassign
        target.name = getInstanceLabelOrUndefined(store, concept);
        return target.name;
      } else if (property === 'color') {
        // This is expected as we want to cache the value
        // eslint-disable-next-line no-param-reassign
        target.color = resolveConceptColorValue(store, concept.id);
        return target.color;
      } else if (property === 'backgroundColor') {
        // This is expected as we want to cache the value
        // eslint-disable-next-line no-param-reassign
        target.backgroundColor = getModelBackgroundColor(concept);
        return target.backgroundColor;
      } else {
        return undefined;
      }
    },
    has: (_, property: keyof ToggleEntry) => ['id', 'name', 'color', 'backgroundColor'].includes(property),
  }));

  if (instances.length === 0) {
    return null;
  }

  const label = formatOrUndef(getQuickFilterLabel(store, quickFilterId, parameterDefinitions));

  return (
    <ToggleBlock
      title={label}
      toggles={toggles}
      activeIds={activeElementsRef.current}
      onToggle={onToggle}
      onClear={onClear}
      tooltip={i18n`Filter elements that have one of the following selected ${label}`}
      loading={!equals(lastActiveElementsFromSessionStorageRef.current, activeElementsRef.current)}
    />
  );
};

export default QuickFilterBlock;
