import {
  Block,
  Block_ConceptDefinition,
  Block_FieldDisplays,
  Block_Parent,
  Block_Rank,
  Block_Type,
  BlockType_Tab,
  ConceptDefinition_Blocks,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStore, StoreObject } from 'yooi-store';
import type { DecoratedList } from 'yooi-utils';
import { compareRank, newError, ranker } from 'yooi-utils';
import type { DisplayItemLibrary } from '../displayItemLibrary';
import { registerDisplayItem } from '../displayItemLibrary';
import { updateFieldParentBlock } from './displayItemDefinitionUtils';

const createTab = (store: ObjectStore, conceptDefinitionId: string, rank: string) => store.createObject(
  {
    [Instance_Of]: Block,
    [Block_ConceptDefinition]: conceptDefinitionId,
    [Block_Rank]: rank,
    [Block_Type]: BlockType_Tab,
  }
);

const getTabParent = (block: StoreObject | null): StoreObject | null => {
  if (!block) {
    return null;
  } else if (BlockType_Tab === block[Block_Type] as string) {
    return block;
  } else {
    return getTabParent(block.navigateOrNull(Block_Parent));
  }
};

const computeTabDecoratedList = (conceptDefinition: StoreObject): DecoratedList<StoreObject> => {
  const list = conceptDefinition.navigateBack(ConceptDefinition_Blocks).filter((b) => b[Block_Type] === BlockType_Tab)
    .sort((a, b) => compareRank(a[Block_Rank] as string, b[Block_Rank] as string));
  return ranker.decorateList(list, (b: StoreObject) => b[Block_Rank] as string);
};

const blockTabHandler: DisplayItemLibrary['itemHandler'] = (store, blockId, conceptDefinition, flattenedTree) => {
  const getFlattenedTabTree = () => flattenedTree
    .filter(({ blockId: bId, fieldBlockDisplayId }) => !fieldBlockDisplayId && (BlockType_Tab === store.getObject(bId)[Block_Type] as string));

  const removeBlockItems = (blockToRemoveId: string) => {
    store.getObject(blockToRemoveId).navigateBack(Block_FieldDisplays).forEach((fieldDisplay) => store.deleteObject(fieldDisplay.id));
    store.getObject(blockToRemoveId).navigateBack(Block_Parent).forEach((childBlock) => removeBlockItems(childBlock.id));
  };

  const deleteBlockAndContent = () => {
    removeBlockItems(blockId);
    store.deleteObject(blockId);
  };

  return {
    canMoveUp: () => getFlattenedTabTree().findIndex(({ blockId: bId }) => blockId === bId) > 0,
    canMoveDown: () => {
      const flattenedTabTree = getFlattenedTabTree();
      return flattenedTabTree.findIndex(({ blockId: bId }) => blockId === bId) < flattenedTabTree.length - 1;
    },
    moveUp: () => {
      const flattenedTabTree = getFlattenedTabTree();
      const tabIndex = flattenedTabTree.findIndex(({ blockId: bId }) => blockId === bId);
      const previousTab = flattenedTabTree[tabIndex - 1];
      store.updateObject(blockId, {
        [Block_Rank]: computeTabDecoratedList(conceptDefinition).find(({ item }) => item.id === previousTab.blockId)?.insertBeforeRank(),
      });
    },
    moveDown: () => {
      const flattenedTabTree = getFlattenedTabTree();
      const tabIndex = flattenedTabTree.findIndex(({ blockId: bId }) => blockId === bId);
      const nextTab = flattenedTabTree[tabIndex + 1];
      store.updateObject(blockId, {
        [Block_Rank]: computeTabDecoratedList(conceptDefinition).find(({ item }) => item.id === nextTab.blockId)?.insertAfterRank(),
      });
    },
    deleteBlockAndContent,
  };
};

const blockTabDefinitionHandler: DisplayItemLibrary['itemDefinitionHandler'] = (store, conceptDefinition, flattenedTree) => {
  const createBelow = (aboveBlockId?: string, aboveFieldDisplayBlockId?: string) => {
    let rank;
    let newTabId: string;
    if (!aboveBlockId) {
      rank = computeTabDecoratedList(conceptDefinition).insertBeforeFirstItemRank();
      newTabId = createTab(store, conceptDefinition.id, rank);
    } else {
      const aboveBlock = store.getObject(aboveBlockId);
      const parentTab = getTabParent(aboveBlock);
      if (!parentTab) {
        throw newError('Every item should have a tab parent or be a tab');
      }
      const aboveTabItem = computeTabDecoratedList(conceptDefinition).find(({ item: { id } }) => id === parentTab.id);
      if (!aboveTabItem) {
        throw newError('Cannot create a tab below. No tab above.');
      }
      rank = aboveTabItem.insertAfterRank();

      const itemIndex = flattenedTree.findIndex(({ blockId: bId, fieldBlockDisplayId: fBDId }) => bId === aboveBlockId && fBDId === aboveFieldDisplayBlockId);
      const afterItems = flattenedTree.filter((_, i) => i > itemIndex);

      const nextTabIndex = afterItems
        .findIndex(({ blockId: bId, fieldBlockDisplayId: fBDId }) => !fBDId && store.getObject(bId)[Block_Type] === BlockType_Tab);

      const blockIds: string[] = [];
      const itemsToReroute = afterItems
        .filter((_, i) => nextTabIndex === -1 || i < nextTabIndex)
        .filter(({ blockId: bId, fieldBlockDisplayId: fBDId }) => {
          if (fBDId) {
            return !blockIds.includes(bId);
          } else {
            blockIds.push(bId);
            return !blockIds.includes(store.getObject(bId)[Block_Parent] as string);
          }
        });
      newTabId = createTab(store, conceptDefinition.id, rank);
      const nextRankGenerator = ranker.createNextRankGenerator();
      itemsToReroute.forEach(({ blockId: bId, fieldBlockDisplayId: fBDId }) => {
        if (fBDId) {
          updateFieldParentBlock(store, fBDId, newTabId);
        } else {
          store.updateObject(bId, {
            [Block_Parent]: newTabId,
            [Block_Rank]: nextRankGenerator(),
          });
        }
      });
    }
    return newTabId;
  };

  return {
    createBelow,
    createAbove: (fromBlockId: string, fromFieldId?: string) => {
      const itemIndex = flattenedTree.findIndex(({ blockId: bId, fieldBlockDisplayId: fBDId }) => fromBlockId === bId && fromFieldId === fBDId);
      const { blockId: bId, fieldBlockDisplayId: fBDId } = flattenedTree[itemIndex - 1] ?? {};
      return createBelow(bId, fBDId);
    },
  };
};

registerDisplayItem({
  id: BlockType_Tab,
  itemHandler: blockTabHandler,
  itemDefinitionHandler: blockTabDefinitionHandler,
});
