import type { FunctionComponent } from 'react';
import { LibraryDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import type { ConceptDefinitionStoreObject } from 'yooi-modules/modules/conceptModule';
import { CardColorMode, getConceptDefinitionValidFields, getFields, isConceptDefinitionField } from 'yooi-modules/modules/conceptModule';
import {
  ConceptDefinition_CardColorField,
  ConceptDefinition_CardColorMode,
  ConceptDefinition_CardImageField,
  ConceptDefinition_CardTitleField,
  ConceptDefinition_LibraryDisplayOption,
  ConceptDefinition_LibraryItemPerPage,
  ConceptDefinition_LibraryShowTable,
  ConceptDefinition_TableGroupBy,
  ImageField,
  TextField,
} from 'yooi-modules/modules/conceptModule/ids';
import Checkbox from '../../../../../components/atoms/Checkbox';
import { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import BlockContent from '../../../../../components/templates/BlockContent';
import BlockTitle from '../../../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../../../components/templates/HorizontalBlock';
import useStore from '../../../../../store/useStore';
import i18n from '../../../../../utils/i18n';
import { isFieldAvailableForColoration } from '../../../../_global/conceptDisplayUtils';
import { getFieldChip } from '../../../../_global/fieldUtils';
import type { Option, OptionRecord } from '../../../../_global/modelTypeUtils';
import { defaultOptionComparator, listGroupByFieldOptions } from '../../../../_global/modelTypeUtils';
import DataLibraryViewEditor from './DataLibraryViewEditor';

interface DataGridViewEditorProps {
  conceptDefinitionId: string,
}

const DataGridViewEditor: FunctionComponent<DataGridViewEditorProps> = ({ conceptDefinitionId }) => {
  const store = useStore();

  const libraryDisplayOptions = {
    [LibraryDisplayOptions.table]: { id: LibraryDisplayOptions.table, tooltip: i18n`Table`, label: i18n`Table` },
    [LibraryDisplayOptions.cardList]: { id: LibraryDisplayOptions.cardList, tooltip: i18n`Card list`, label: i18n`Card list` },
  };

  const conceptDefinition = store.getObject<ConceptDefinitionStoreObject>(conceptDefinitionId);
  const defaultGroupByFieldId = conceptDefinition.navigateOrNull(ConceptDefinition_TableGroupBy)?.id;
  const titleFieldId = conceptDefinition.navigateOrNull(ConceptDefinition_CardTitleField)?.id;
  const imageFieldId = conceptDefinition.navigateOrNull(ConceptDefinition_CardImageField)?.id;
  const colorFieldId = conceptDefinition.navigateOrNull(ConceptDefinition_CardColorField)?.id;

  interface ItemPerPageOption extends Option {
    value: number | null,
  }

  const noItemPerPageOption: ItemPerPageOption = {
    id: 'All',
    label: i18n`All`,
    value: null,
  };
  let selectedItemPerPage = noItemPerPageOption;
  const itemPerPage = conceptDefinition[ConceptDefinition_LibraryItemPerPage];
  if (itemPerPage) {
    selectedItemPerPage = {
      id: (itemPerPage).toString(),
      label: (itemPerPage).toString(),
      value: itemPerPage,
    };
  }

  const cardColorModeOptions: OptionRecord<CardColorMode> = {
    [CardColorMode.Bar]: { id: CardColorMode.Bar, label: i18n`As bar` },
    [CardColorMode.Dot]: { id: CardColorMode.Dot, label: i18n`As dot` },
  };

  return (
    <>
      <HorizontalBlock asBlockContent>
        <BlockTitle
          title={i18n`Show table`}
          infoTooltip={i18n`Show / hide library table tab`}
        />
        <BlockContent padded>
          <SpacingLine>
            <Checkbox
              checked={Boolean(conceptDefinition[ConceptDefinition_LibraryShowTable])}
              onChange={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_LibraryShowTable]: value })}
            />
          </SpacingLine>
        </BlockContent>
      </HorizontalBlock>
      {Boolean(conceptDefinition[ConceptDefinition_LibraryShowTable]) && (
        <>
          <HorizontalBlock asBlockContent>
            <BlockTitle
              title={i18n`Number of items per page`}
              infoTooltip={i18n`Select the number of items shown in each page of the library table.`}
            />
            <BlockContent>
              <SpacingLine>
                <SearchAndSelect
                  selectedOption={selectedItemPerPage}
                  computeOptions={() => [
                    ...[10, 20, 30, 50, 100].map((numberOfItems) => ({ id: numberOfItems.toString(), label: numberOfItems.toString(), value: numberOfItems })),
                    noItemPerPageOption,
                  ]}
                  onSelect={(selectedItem) => {
                    if (selectedItem) {
                      store.updateObject(conceptDefinitionId, { [ConceptDefinition_LibraryItemPerPage]: selectedItem.value });
                    }
                  }}
                />
              </SpacingLine>
            </BlockContent>
          </HorizontalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle
              title={i18n`Default field for group by`}
              infoTooltip={i18n`Relation used to group each instance on the table display, by default`}
            />
            <BlockContent>
              <SearchAndSelect
                clearable
                placeholder={i18n`Select field`}
                selectedOption={
                  defaultGroupByFieldId && store.getObjectOrNull(defaultGroupByFieldId) !== null
                    ? getFieldChip(store, conceptDefinitionId, defaultGroupByFieldId) : undefined
                }
                computeOptions={() => listGroupByFieldOptions(store, conceptDefinitionId, true)}
                onSelect={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_TableGroupBy]: value?.id ?? null })}
                statusIcon={
                  !defaultGroupByFieldId || isConceptDefinitionField(store, defaultGroupByFieldId, conceptDefinitionId)
                    ? undefined : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
                }
              />
            </BlockContent>
          </HorizontalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle
              title={i18n`Display library as`}
              infoTooltip={i18n`Representation used to display the list of instances in libraries`}
            />
            <BlockContent>
              <SearchAndSelect
                placeholder={i18n`Select display option`}
                selectedOption={
                  libraryDisplayOptions[conceptDefinition[ConceptDefinition_LibraryDisplayOption] ?? LibraryDisplayOptions.table]
                }
                computeOptions={() => Object.values(libraryDisplayOptions).sort(defaultOptionComparator)}
                onSelect={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_LibraryDisplayOption]: value?.id ?? null })}
              />
            </BlockContent>
          </HorizontalBlock>
          <DataLibraryViewEditor conceptDefinitionId={conceptDefinitionId} />
          <HorizontalBlock asBlockContent>
            <BlockTitle title={i18n`Card title`} />
            <BlockContent>
              <SearchAndSelect
                clearable
                placeholder={i18n`Select field`}
                selectedOption={titleFieldId ? getFieldChip(store, conceptDefinitionId, titleFieldId) : undefined}
                computeOptions={() => getFields(store, conceptDefinitionId, [TextField])
                  .map((field) => getFieldChip(store, conceptDefinitionId, field.id))
                  .sort(defaultOptionComparator)}
                onSelect={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_CardTitleField]: value?.id ?? null })}
                statusIcon={
                  !titleFieldId || isConceptDefinitionField(store, titleFieldId, conceptDefinitionId)
                    ? undefined : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
                }
              />
            </BlockContent>
          </HorizontalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle title={i18n`Card image`} />
            <BlockContent>
              <SearchAndSelect
                clearable
                placeholder={i18n`Select field`}
                selectedOption={imageFieldId ? getFieldChip(store, conceptDefinitionId, imageFieldId) : undefined}
                computeOptions={() => getFields(store, conceptDefinitionId, [ImageField])
                  .map((field) => getFieldChip(store, conceptDefinitionId, field.id))
                  .sort(defaultOptionComparator)}
                onSelect={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_CardImageField]: value?.id ?? null })}
                statusIcon={
                  !imageFieldId || isConceptDefinitionField(store, imageFieldId, conceptDefinitionId)
                    ? undefined : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
                }
              />
            </BlockContent>
          </HorizontalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle title={i18n`Card color`} />
            <BlockContent>
              <SpacingLine>
                <SearchAndSelect
                  clearable
                  placeholder={i18n`Select field`}
                  selectedOption={
                    colorFieldId ? getFieldChip(store, conceptDefinitionId, colorFieldId) : undefined
                  }
                  computeOptions={() => getConceptDefinitionValidFields(store, conceptDefinitionId)
                    .filter(({ id }) => isFieldAvailableForColoration(store, conceptDefinitionId, id))
                    .map((field) => getFieldChip(store, conceptDefinitionId, field.id))
                    .sort(defaultOptionComparator)}
                  onSelect={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_CardColorField]: value?.id ?? null })}
                  statusIcon={
                    !colorFieldId || isConceptDefinitionField(store, colorFieldId, conceptDefinitionId)
                      ? undefined : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
                  }
                />
                <SearchAndSelect
                  selectedOption={cardColorModeOptions[conceptDefinition[ConceptDefinition_CardColorMode] ?? CardColorMode.Bar]}
                  computeOptions={() => Object.values(cardColorModeOptions).sort(defaultOptionComparator)}
                  onSelect={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_CardColorMode]: value?.id ?? null })}
                />
              </SpacingLine>
            </BlockContent>
          </HorizontalBlock>
        </>
      )}
    </>
  );
};

export default DataGridViewEditor;
