import type { FunctionComponent } from 'react';
import { useState } from 'react';
import {
  Concept_Name,
  Group,
  PlatformCapability,
  PlatformGroupCapability,
  PlatformGroupCapability_Role_Group,
  PlatformGroupCapability_Role_PlatformCapability,
} from 'yooi-modules/modules/conceptModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import IconOnlyButton from '../../../components/atoms/IconOnlyButton';
import Typo from '../../../components/atoms/Typo';
import InlineLink from '../../../components/molecules/InlineLink';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SearchAndSelectMultiple from '../../../components/molecules/SearchAndSelectMultiple';
import { TableSortDirection } from '../../../components/molecules/Table';
import TableCell, { TableCellAlign } from '../../../components/molecules/TableCell';
import TableLine from '../../../components/molecules/TableLine';
import BlockTitle from '../../../components/templates/BlockTitle';
import DataTable from '../../../components/templates/DataTable';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useTheme from '../../../utils/useTheme';
import { getFieldColumnComparator } from '../../_global/fieldUtils';
import { defaultOptionComparator, getChipOptions, getSearchChipOptions } from '../../_global/modelTypeUtils';
import type { ChipOption } from '../../_global/modelTypeUtilsType';
import ActivityIndicator from '../../_global/multiplayer/ActivityIndicator';
import useFilterAndSort from '../../_global/useFilterAndSort';

const useStyles = makeStyles({
  container: {
    display: 'grid',
  },
  firstLine: {
    display: 'flex',
    alignItems: 'center',
  },
}, 'platformCapabilitiesTab');

const PlatformCapabilitiesTab: FunctionComponent = () => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const [showGroupLine, setShowGroupLine] = useState(false);
  const [newGroup, setNewGroup] = useState<string>();
  const [newCapabilities, setNewCapabilities] = useState<string[]>([]);

  const groups = store.withAssociation(PlatformGroupCapability)
    .list()
    .map((assoc) => assoc.navigateRole(PlatformGroupCapability_Role_Group))
    .filter(({ id }, index, arr) => arr.findIndex(({ id: groupId }) => groupId === id) === index);

  const { generateList, doSort: doSortGroupList, sortCriteria: groupSortCriteria } = useFilterAndSort(
    'platform-manage-group',
    groups,
    undefined,
    { getComparatorHandler: getFieldColumnComparator(store), initial: { key: Concept_Name, direction: TableSortDirection.asc } }
  );
  const { list: groupSortedList } = generateList();

  const handleReset = () => {
    setNewGroup(undefined);
    setNewCapabilities([]);
    setShowGroupLine(false);
  };

  const onCreate = () => {
    if (newGroup) {
      newCapabilities.forEach((capabilityId) => store.withAssociation(PlatformGroupCapability)
        .withRole(PlatformGroupCapability_Role_PlatformCapability, capabilityId)
        .withRole(PlatformGroupCapability_Role_Group, newGroup)
        .updateObject({}));
    }
    handleReset();
  };

  return (
    <VerticalBlock>
      <BlockTitle
        title={i18n`Platform capabilities`}
        subtitle={(
          <div className={classes.container}>
            <div className={classes.firstLine}>
              <Typo color={theme.color.text.secondary}>
                {i18n.jsx`You can use ${(
                  <InlineLink key="groups" to={{ pathname: `/settings/organization/${Group}`, hash: '#instance' }}>{i18n`groups`}</InlineLink>
                )} to manage user rights on the platform.`}
              </Typo>
            </div>
            <Typo color={theme.color.text.secondary} fullWidth>
              {i18n`To do so, select groups defined at platform level and assign capabilities to them. They will be applied everywhere in the platform`}
            </Typo>
          </div>
        )}
      />
      <DataTable
        sortCriteria={groupSortCriteria}
        doSort={doSortGroupList}
        list={groupSortedList}
        multiplayerRenderer={(line, columnKeys) => <ActivityIndicator instanceIds={line.id} propertyIds={columnKeys} />}
        newItemTitle={i18n`Add`}
        newItemIcon={IconName.add}
        onNewItem={() => setShowGroupLine(true)}
        inlineCreation={{
          render: showGroupLine ? (
            <TableLine>
              <TableCell noSeparator />
              <TableCell noSeparator />
              <TableCell>
                <SearchAndSelect
                  computeOptions={() => store.getObject(Group)
                    .navigateBack(Class_Instances)
                    .filter((group) => !groupSortedList.some(({ item: { id } }) => id === group.id))
                    .map(({ id }) => getChipOptions(store, id))
                    .filter(filterNullOrUndefined)
                    .sort(defaultOptionComparator)}
                  onSelect={(value) => setNewGroup(value?.id)}
                  selectedOption={newGroup ? getChipOptions(store, newGroup) : undefined}
                  searchOptions={getSearchChipOptions(store, Group)}
                  editOnMount
                  onEscape={() => handleReset()}
                />
              </TableCell>
              <TableCell>
                <SearchAndSelectMultiple
                  computeOptions={() => store.getObject(PlatformCapability)
                    .navigateBack(Class_Instances)
                    .filter((capability) => !newCapabilities.some((id) => id === capability.id))
                    .map(({ id }) => getChipOptions(store, id))
                    .filter(filterNullOrUndefined)
                    .sort(defaultOptionComparator)}
                  onSelect={(value) => setNewCapabilities((current) => [...current, value?.id])}
                  onDelete={(value) => setNewCapabilities((current) => current.filter((capability) => capability !== value.id))}
                  selectedOptions={newCapabilities
                    .map((capabilityId) => getChipOptions(store, capabilityId))
                    .filter(filterNullOrUndefined)}
                  searchOptions={{
                    searchKeys: ['label'],
                    extractValue: ({ isLabelUndefined, label }) => (isLabelUndefined ? undefined : label),
                  }}
                />
              </TableCell>
              <TableCell align={TableCellAlign.center} action>
                <IconOnlyButton
                  tooltip={i18n`Add`}
                  onClick={onCreate}
                  iconName={IconName.check}
                  disabled={!newGroup || !newCapabilities.length}
                />
              </TableCell>
            </TableLine>
          ) : null,
        }}
        linesActions={(group) => [{
          key: 'remove',
          name: i18n`Remove`,
          icon: IconName.delete,
          danger: true,
          onClick: () => {
            store.withAssociation(PlatformGroupCapability)
              .withRole(PlatformGroupCapability_Role_Group, group.id)
              .list()
              .forEach(({ object }) => store.deleteObject(object.id));
          },
        }]}
        columnsDefinition={[
          {
            propertyId: Concept_Name,
            name: i18n`Name`,
            sortable: true,
            width: 30,
            cellRender: ({ id }) => (
              <SearchAndSelect
                selectedOption={getChipOptions(store, id)}
                readOnly
              />
            ),
          },
          {
            propertyId: PlatformCapability,
            name: i18n`Capabilities`,
            width: 70,
            cellRender: ({ id: groupId }) => (
              <SearchAndSelectMultiple<ChipOption>
                onSelect={({ id }) => store.withAssociation(PlatformGroupCapability)
                  .withRole(PlatformGroupCapability_Role_Group, groupId)
                  .withRole(PlatformGroupCapability_Role_PlatformCapability, id)
                  .updateObject({})}
                onDelete={({ id }) => store.withAssociation(PlatformGroupCapability)
                  .withRole(PlatformGroupCapability_Role_Group, groupId)
                  .withRole(PlatformGroupCapability_Role_PlatformCapability, id)
                  .deleteObject()}
                computeOptions={() => store.getObject(PlatformCapability)
                  .navigateBack(Class_Instances)
                  .map(({ id }) => getChipOptions(store, id))
                  .filter(filterNullOrUndefined)
                  .sort(defaultOptionComparator)}
                selectedOptions={
                  store.withAssociation(PlatformGroupCapability)
                    .withRole(PlatformGroupCapability_Role_Group, groupId)
                    .list()
                    .map((assoc) => getChipOptions(store, assoc.role(PlatformGroupCapability_Role_PlatformCapability)))
                    .filter(filterNullOrUndefined)
                }
                searchOptions={{
                  searchKeys: ['label'],
                  extractValue: ({ isLabelUndefined, label }) => (isLabelUndefined ? undefined : label),
                }}
              />
            ),
          },
        ]}
      />
    </VerticalBlock>
  );
};

export default PlatformCapabilitiesTab;
