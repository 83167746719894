import classnames from 'classnames';
import type { RefObject } from 'react';
import { forwardRef } from 'react';
import type { Offset } from 'react-overlays/usePopper';
import base from '../../theme/base';
import { getSpacingAsNumber, Spacing, spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import type { IconName, IconSizeVariant } from '../atoms/Icon';
import Icon, { IconColorVariant } from '../atoms/Icon';
import Tooltip from '../atoms/Tooltip';
import Typo from '../atoms/Typo';
import { editableSelectorsClasses } from './EditableWithDropdown';
import type { OverlayPlacement } from './Overlay';
import Overlay from './Overlay';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.xs,
    minWidth: '16rem',
    borderRadius: base.borderRadius.medium,
    backgroundColor: theme.color.background.neutral.default,
    boxShadow: base.shadowElevation.medium,
    padding: spacingRem.s,
    [`& .${editableSelectorsClasses.focusedVisible}`]: {
      visibility: 'visible',
    },
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: spacingRem.s,
    height: '3.2rem',
    cursor: 'pointer',
    overflow: 'hidden',
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    borderRadius: base.borderRadius.medium,
    '&:hover, &:focus-visible': {
      backgroundColor: theme.color.background.neutral.subtle,
    },
    '&:active': {
      backgroundColor: theme.color.background.neutral.muted,
    },
  },
  lineDanger: {
    color: theme.color.text.danger,
  },
  active: {
    backgroundColor: theme.color.background.primarylight.default,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.default,
    color: theme.color.text.brand,
    '&:hover': {
      backgroundColor: theme.color.background.primarylight.hover,
    },
    '&:active': {
      backgroundColor: theme.color.background.primarylight.pressed,
    },
  },
}), 'menu');

export interface MenuItem {
  key: string,
  name: string,
  icon?: IconName,
  iconSize?: IconSizeVariant,
  onClick: (event: { isCtrlKeyPressed: boolean, isMiddleClick: boolean }) => void,
  hidden?: boolean,
  danger?: boolean,
  tooltip?: string,
  active?: boolean,
}

interface MenuProps {
  items: MenuItem[],
  anchorRef: RefObject<HTMLElement>,
  onBackdropClick?: (event: MouseEvent) => void,
  onClose: () => void,
  placement?: OverlayPlacement,
  offset?: Offset,
}

const Menu = forwardRef<HTMLDivElement, MenuProps>(({ items, anchorRef, onBackdropClick, onClose, placement, offset }, ref) => {
  const classes = useStyles();

  return (
    <Overlay
      target={anchorRef}
      sameWidth={false}
      onBackdropClick={onBackdropClick === undefined ? onClose : onBackdropClick}
      onEscapeKeyDown={onClose}
      placement={placement ?? 'bottom-start'}
      // if direction is vertical, we add an horizontal offset -9 to align icons
      offset={offset ?? [-9, remToPx(getSpacingAsNumber(Spacing.xs))]}
      // Include a bottom margin to make sure the bottom shadow is visible when in scroll container
      containerMarginBottom="0.4rem"
    >
      <div ref={ref} className={classes.container}>
        {
          items
            .filter(({ hidden }) => !hidden)
            .map(({ key, name, onClick, danger, icon, iconSize, tooltip, active }, index) => (
              <div
                key={key}
                ref={index === 0 ? (node) => node?.focus({ preventScroll: true }) : undefined}
                tabIndex={0}
                role="button"
                className={classnames({ [classes.line]: true, [classes.lineDanger]: danger, [classes.active]: active })}
                onClick={(event) => {
                  event.stopPropagation();
                  onClick({ isCtrlKeyPressed: event.ctrlKey, isMiddleClick: false });
                  onClose();
                }}
                onMouseDown={(event) => {
                  if (event.button === 1) {
                    event.stopPropagation();
                    onClick({ isCtrlKeyPressed: event.ctrlKey, isMiddleClick: true });
                    onClose();
                  }
                }}
                onKeyDown={(event) => {
                  if (event?.key === 'Enter') {
                    event.stopPropagation();
                    onClick({ isCtrlKeyPressed: event.ctrlKey, isMiddleClick: false });
                    onClose();
                  }
                }}
              >
                {tooltip
                  ? (
                    <Tooltip title={tooltip}>
                      {icon ? (<Icon colorVariant={danger ? IconColorVariant.error : IconColorVariant.alternative} name={icon} size={iconSize} />) : null}
                      <Typo>{name}</Typo>
                    </Tooltip>
                  )
                  : (
                    <>
                      {icon ? (<Icon colorVariant={danger ? IconColorVariant.error : IconColorVariant.alternative} name={icon} size={iconSize} />) : null}
                      <Typo>{name}</Typo>
                    </>
                  )}
              </div>
            ))
        }
      </div>
    </Overlay>
  );
});

export default Menu;
