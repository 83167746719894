import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { UserStoreObject } from 'yooi-modules/modules/conceptModule';
import { getConceptUrl } from 'yooi-modules/modules/conceptModule';
import {
  Concept_Name,
  ConceptNameDimension,
  StakeholdersField,
  User,
  User_Email,
  User_Groups,
  User_IsEnabled,
  User_Type,
  UserEmailDimension,
  UserGroupMembershipDimension,
  UserIsEnabledDimension,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { StoreObject } from 'yooi-store';
import { compareString, comparing, joinObjects, newError } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../components/atoms/Button';
import { IconName } from '../../../components/atoms/Icon';
import TextInputString from '../../../components/inputs/TextInputString';
import Banner, { BannerVariant } from '../../../components/molecules/Banner';
import { TableSortDirection } from '../../../components/molecules/Table';
import BlockContent from '../../../components/templates/BlockContent';
import type { ColumnDefinition } from '../../../components/templates/DataTable';
import DataTable from '../../../components/templates/DataTable';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useAcl from '../../../store/useAcl';
import useAuth from '../../../store/useAuth';
import useStore from '../../../store/useStore';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useNavigation from '../../../utils/useNavigation';
import useNewLineFocus from '../../../utils/useNewLineFocus';
import { useSessionStorageState } from '../../../utils/useSessionStorage';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import { getFieldHandler } from '../../_global/fields/FieldLibrary';
import { getFieldColumnComparator } from '../../_global/fieldUtils';
import SearchTextButton from '../../_global/filter/SearchTextButton';
import type { FilterConfiguration } from '../../_global/filter/useFilterSessionStorage';
import { getConceptFilterFunction } from '../../_global/listFilterFunctions';
import type { NavigationFilter } from '../../_global/navigationUtils';
import { getNavigationPayload } from '../../_global/navigationUtils';
import { share } from '../../_global/shareUtils';
import useConceptDeleteModal from '../../_global/useConceptDeleteModal';
import useFilterAndSort from '../../_global/useFilterAndSort';
import useAdminMenuItems from '../../_global/userAdmin/useAdminMenuItems';
import ProvisionSSOUsersModal from './ProvisionSSOUsersModal';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: spacingRem.s,
    paddingBottom: spacingRem.xs,
  },
}, 'usersTab');

const accountTypeLabels: Record<string, string> = {
  sso: i18n`SSO`,
  internal: i18n`Internal`,
};

const UsersTab: FunctionComponent = () => {
  const classes = useStyles();

  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();

  const navigation = useNavigation<NavigationFilter>();

  const [openProvisionSSOModal, setProvisionSSOModal] = useState(false);
  const { passwordModal, buildAdminMenuItems } = useAdminMenuItems(store, loggedUserId);

  const [doDelete, deleteModal] = useConceptDeleteModal(false);

  const [filtersConfiguration] = useSessionStorageState<FilterConfiguration | undefined>('authorization-users', {});
  const [newLineFocus, setNewLineFocus] = useNewLineFocus();
  const { generatePageList, doSort, sortCriteria, forceShowId } = useFilterAndSort(
    'authorization-users',
    store.getObject(User).navigateBack<UserStoreObject>(Class_Instances),
    getConceptFilterFunction(store, User, filtersConfiguration, loggedUserId),
    {
      getComparatorHandler: (key, direction) => {
        if (key === User_Type) {
          return {
            extractValue: (user) => user[User_Type] ?? 'internal',
            comparator: comparing(compareString, direction === TableSortDirection.desc),
          };
        } else {
          return getFieldColumnComparator(store)(key, direction);
        }
      },
      initial: { key: Concept_Name, direction: TableSortDirection.asc },
    }
  );
  const { list, pagination } = generatePageList(25);

  const buildFieldColumnDefinition = (dimensionId: string, fieldId: string, width?: string | number): ColumnDefinition<UserStoreObject> => {
    const fieldHandler = getFieldHandler(store, fieldId);
    const field = store.getObject(fieldId);
    if (fieldHandler?.getColumnDefinition !== undefined) {
      return joinObjects(
        fieldHandler.getColumnDefinition(),
        {
          width: width ?? fieldHandler.estimatedColumnWidth?.(User),
          cellRender: (instance: StoreObject, focusOnMount: boolean) => (
            fieldHandler.renderField?.({
              dimensionsMapping: isInstanceOf(field, StakeholdersField) ? { n1InstanceId: instance.id } : { [dimensionId]: instance.id },
              readOnly: false,
              focusOnMount,
            }) ?? null
          ),
        }
      );
    } else {
      throw newError('Invalid field id', { fieldId });
    }
  };

  const columnsDefinition: ColumnDefinition<UserStoreObject>[] = [
    buildFieldColumnDefinition(ConceptNameDimension, Concept_Name, 25),
    buildFieldColumnDefinition(UserEmailDimension, User_Email, 25),
    buildFieldColumnDefinition(UserGroupMembershipDimension, User_Groups),
    buildFieldColumnDefinition(UserIsEnabledDimension, User_IsEnabled),
    {
      propertyId: User_Type,
      name: i18n`Type`,
      width: '7rem',
      sortable: true,
      cellRender: (user) => (
        <TextInputString value={accountTypeLabels[user[User_Type] ?? 'internal']} readOnly />
      ),
    },
  ];

  return (
    <VerticalBlock>
      <BlockContent padded>
        <Banner
          variant={BannerVariant.info}
          title={i18n`User is a concept like any other on your platform, which you can customize (fields, layout, workflows...) via its configuration page.`}
          actions={[{
            key: 'open',
            icon: IconName.output,
            title: i18n`Open configuration`,
            onClick: () => {
              navigation.push(User, { pathname: `/settings/organization/${User}`, hash: '#instance' });
            },
          }]}
          fullWidth
        />
      </BlockContent>
      <VerticalBlock asBlockContent compact>
        <BlockContent padded>
          <div className={classes.headerContainer}>
            <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
              <SearchTextButton element="authorization-users" placeholder={i18n`Search`} />
            </SizeContextProvider>
          </div>
        </BlockContent>
        <DataTable
          getNavigationPayload={(instance) => getNavigationPayload(navigation, instance.id, getConceptUrl(store, instance.id))}
          doSort={doSort}
          sortCriteria={sortCriteria}
          columnsDefinition={columnsDefinition}
          linesActions={({ id }) => [
            {
              key: 'open',
              icon: IconName.output,
              name: i18n`Open`,
              onClick: () => navigation.push(id, { pathname: getConceptUrl(store, id) }),
            },
            {
              key: 'share',
              icon: IconName.link,
              name: i18n`Copy link`,
              onClick: () => share(store, getNavigationPayload(navigation, id, `/settings/workflow/${id}`)),
            },
            ...buildAdminMenuItems(id),
            {
              key: 'delete',
              icon: IconName.delete,
              name: i18n`Delete`,
              hidden: !aclHandler.canDeleteObject(id),
              onClick: () => doDelete(id),
              danger: true,
            },
          ]}
          list={list}
          pagination={pagination}
          newItemIcon={IconName.add}
          newItemTitle={i18n`Create`}
          onNewItem={() => {
            const userId = store.createObject({ [Instance_Of]: User });
            forceShowId(userId);
            setNewLineFocus(userId);
          }}
          newLineFocus={newLineFocus.current}
          extraButton={(
            <Button
              key="provisionUser"
              variant={ButtonVariant.secondary}
              title={i18n`Provision SSO users`}
              onClick={() => setProvisionSSOModal(true)}
            />
          )}
        />
        {passwordModal}
        {deleteModal}
        {openProvisionSSOModal ? (<ProvisionSSOUsersModal onClose={() => setProvisionSSOModal(false)} />) : null}
      </VerticalBlock>
    </VerticalBlock>
  );
};

export default UsersTab;
