import { equals } from 'ramda';
import type { BusinessRuleHandler, BusinessRuleResult, BusinessRulesLibraryRegistrationHandler, ObjectStoreReadOnly, StoreObject } from 'yooi-store';
import { OriginSources, ValidationStatus } from 'yooi-store';
import type { DecoratedList } from 'yooi-utils';
import { compareRank, formatForStorage, ranker } from 'yooi-utils';
import { API_LABEL_ACCEPTED_CHARS_REGEX } from '../../src/apiAlias/apiAliasRegex';
import type { BusinessRuleRegistration } from './types/TypeModuleDslType';

export const checkUpdateOrigin = (allowedOrigin: OriginSources, ruleLabel: string) => (): BusinessRuleHandler => (origin, { properties }) => {
  if (properties) {
    if (origin.source === allowedOrigin) {
      return { rule: `${ruleLabel}.systemEvent.allowUpdate`, status: ValidationStatus.ACCEPTED };
    } else {
      return { rule: `${ruleLabel}.cannotBeUpdated`, status: ValidationStatus.REJECTED };
    }
  } else if (properties === null) {
    return { rule: `${ruleLabel}.deletion.allow`, status: ValidationStatus.ACCEPTED };
  }
  return undefined;
};

export const updatedAtBusinessRule = (
  updatedAtPropertyId: string,
  ruleLabel: string,
  creationOnly = false
): BusinessRuleRegistration => (objectStore) => (origin, { id, properties }, executionContext) => {
  const object = objectStore.getObjectOrNull(id);
  if (!object) {
    return {
      rule: `${ruleLabel}.dates.generateCreateDate`,
      status: ValidationStatus.ACCEPTED,
      generateSystemEvent: ({ updateObject }) => updateObject(id, { [updatedAtPropertyId]: formatForStorage(executionContext.date) }),
    };
  } else if (
    !creationOnly
    && origin.source !== OriginSources.SYSTEM
    && properties
    && Object.entries(properties).some(([propertyId, newPropertyValue]) => !equals(object[propertyId] ?? null, newPropertyValue))
  ) {
    return {
      rule: `${ruleLabel}.dates.generateUpdateDate`,
      status: ValidationStatus.ACCEPTED,
      generateSystemEvent: ({ updateObject }) => updateObject(id, { [updatedAtPropertyId]: formatForStorage(executionContext.date) }),
    };
  }
  return undefined;
};

export const registerCreationAndUpdateDateBusinessRules = (
  objectStore: ObjectStoreReadOnly,
  { onObject, onProperty }: BusinessRulesLibraryRegistrationHandler,
  typeId: string,
  creationDatePropertyId: string,
  updatedDatePropertyId: string
): void => {
  onProperty(creationDatePropertyId).validate(checkUpdateOrigin(OriginSources.SYSTEM, 'creationDate')());
  onObject(typeId).validate(updatedAtBusinessRule(creationDatePropertyId, 'creationDate', true)(objectStore));
  if (updatedDatePropertyId) {
    onProperty(updatedDatePropertyId).validate(checkUpdateOrigin(OriginSources.SYSTEM, 'updateDate')());
    onObject(typeId).validate(updatedAtBusinessRule(updatedDatePropertyId, 'updateDate')(objectStore));
  }
};

const handleRankConflict = (
  id: string[],
  properties: Record<string, unknown>,
  rankedItems: DecoratedList<StoreObject> | DecoratedList<StoreObject<string[]>>,
  rankPropertyId: string
): BusinessRuleResult => {
  const sameRankField = rankedItems.find(({ item }) => item[rankPropertyId] === properties[rankPropertyId]);
  if (sameRankField) {
    const rank = sameRankField.insertAfterRank();
    return {
      rule: 'rank.conflictsManaged',
      status: ValidationStatus.ACCEPTED,
      generateSystemEvent: ({ updateObject }) => {
        updateObject(id, { [rankPropertyId]: rank });
      },
    };
  } else {
    return {
      rule: 'rank.noConflict',
      status: ValidationStatus.ACCEPTED,
    };
  }
};

export const checkRankConflicts = (
  getRankedList: (store: ObjectStoreReadOnly, id: string[]) => StoreObject<string[]>[],
  rankPropertyId: string
): BusinessRuleRegistration => (store: ObjectStoreReadOnly) => (_, { id, properties }) => {
  if (!properties) {
    return undefined;
  }
  const rankedItems = ranker.decorateList(
    getRankedList(store, id)
      .filter(({ id: instanceId }) => !equals(instanceId, id))
      .sort((objectA, objectB) => compareRank(objectA[rankPropertyId] as string, objectB[rankPropertyId] as string)),
    ({ [rankPropertyId]: rank }) => rank as string
  );
  return handleRankConflict(id, properties, rankedItems, rankPropertyId);
};

export const checkObjectRankConflicts = (
  groupingPropertyId: string,
  rankPropertyId: string
): BusinessRuleRegistration => ({ getObject, getObjectOrNull }: ObjectStoreReadOnly) => (_, { id, properties }) => {
  if (!properties) {
    return undefined;
  }
  const rankedObject = getObjectOrNull(id);
  const groupingInstanceId = rankedObject?.[groupingPropertyId] as string || properties[groupingPropertyId] as string;
  const rankedItems = ranker.decorateList(
    getObject(groupingInstanceId)
      .navigateBack(groupingPropertyId)
      .filter(({ id: instanceId }) => instanceId !== id[0])
      .sort((fieldA, fieldB) => compareRank(fieldA[rankPropertyId] as string, fieldB[rankPropertyId] as string)),
    (field) => field[rankPropertyId] as string
  );
  return handleRankConflict(id, properties, rankedItems, rankPropertyId);
};

export const buildValidateApiLabelBusinessRule = (propertyId: string, rulePrefix: string): BusinessRuleRegistration => () => (_, { properties }) => {
  if (properties && properties[propertyId]) {
    const validationStatus = API_LABEL_ACCEPTED_CHARS_REGEX.test(properties[propertyId] as string) ? ValidationStatus.ACCEPTED : ValidationStatus.REJECTED;

    return {
      rule: `${rulePrefix}.${validationStatus.toLowerCase()}`,
      status: validationStatus,
    };
  } else {
    return undefined;
  }
};
