// This file is automatically updated by the generate-constants script. Do not update it manually.

export const ConceptModuleId = 'cce23d11-69d9-56bb-96aa-cf44b34fd97d';
export const AnyElement = '808ed2b1-753a-5db9-8def-fc9d58a5caeb';
export const Association = '605d3e73-85b7-5f92-a45a-490eb7920383';
export const Association_Role_Definition = 0;
export const Association_Role_Role1TypeInstance = 1;
export const Association_Role_Role2TypeInstance = 2;
export const AssociationField = '673f5436-4545-5f72-91a4-19be6b3cb305';
export const AssociationField_Definition = 'bf1a9f9e-b25e-51c1-807e-e5c2e245b76c';
export const AssociationField_Field_TargetFilter = '64afdd12-ab30-4b00-a7f1-66fb41ec9580';
export const AssociationField_SourceRole = 'bceedeb8-3fad-5f6a-bbf7-f9b328b2ab7b';
export const AssociationFieldDefinition = '92a966ef-3f4d-55f8-9a87-cc1ec16ae711';
export const AssociationFieldDefinition_Role1Type = 'afabfce7-296c-510c-82c1-e57660aecebc';
export const AssociationFieldDefinition_Role2Type = '57e870df-e1be-585c-80f9-544598d71461';
export const AssociationFilter = '0a698a7d-a42d-49fd-b8e0-209a0181ad57';
export const AssociationFilter_Filters = 'cce07f14-8e65-4deb-9549-ed82786a1b9e';
export const AssociationFilter_LibraryDefaultValueFilters = '8667721b-e8cd-481b-8bbd-e7451dc5d8f5';
export const AssociationFilter_Path = '2dcdc4c7-ad3f-4bd6-83c4-ef17b1451fc9';
export const Attachment = 'c227ad0c-c67a-4309-b439-dba5b7f55b66';
export const Attachment_Rank = 'e9f731eb-c404-47dc-bd49-98eec27a74b5';
export const Attachment_Revision = '8b4d454e-b138-4fc0-bc0e-853beb5625ff';
export const Attachment_Role_FieldDimensions = 0;
export const Attachment_Role_FileName = 1;
export const Attachment_UploadedAt = '0033acc9-dbab-4cef-82e9-e1fb9c9bd76a';
export const Attachment_UploadedBy = '6403db5a-0ddb-40f1-8919-26eb0fc58ac7';
export const Attachment_VarRoles_Dimensions = 2;
export const AttachmentField = 'a73804ed-af60-4ddf-9086-2e37e5d0592e';
export const BooleanField = 'df45530b-fdc1-487d-b6b0-b9f32b85e9a0';
export const BooleanField_FalseValue = '9a229c2e-9e04-42cb-bfa1-2ba80173feba';
export const BooleanField_TrueValue = '1075362b-15f2-4eb9-ae34-2b8b5864ca77';
export const CollaborationField = '7f47b9f1-fd1c-4969-a9a4-b1e8635fab5e';
export const ColorField = '992acc42-a6ca-54f8-abcc-81566d128d4d';
export const ColorField_DefaultColor = '757dc6e8-3335-584b-b439-c4d6a14aacf3';
export const Concept = '023e6075-d955-58f5-8901-ac1aea2d5e39';
export const Concept_CollaborationField = 'a12c7325-a775-4c86-b48d-abbdad2338ea';
export const Concept_CreatedAt = 'e8b10c4a-bae3-57cc-95c7-a22e69b5d4ae';
export const Concept_FunctionalId = 'cc55f5eb-ae08-5985-8771-d54b7ddaf7bd';
export const Concept_GetFilterConditionContext = '40e59cba-c942-43c7-8b8b-2fa2e1c540e2';
export const Concept_GetFilterFunction = '51bb89e7-7279-4d79-96ec-a57243228d5a';
export const Concept_IsValid = '75245ebc-a7a3-46e3-bc18-3a26512f2555';
export const Concept_Name = 'bf040b6b-58be-5194-8f32-4e1c8df24b42';
export const Concept_Resource = 'e4a2669f-9af8-50d8-9c99-eda4acbebfea';
export const Concept_StakeholdersGroups = '914afe1a-8513-498c-8f31-1caa412f4d8a';
export const Concept_StakeholdersRoles = '6f26eb2c-8027-4c5c-b6ca-d16e8f31a61c';
export const Concept_StakeholdersUsers = '24e7c0f1-25bb-48ec-bb0c-16f03f166f48';
export const Concept_SwimlaneRank = '30e01fe8-841d-43cb-814b-af97098ebfde';
export const Concept_UpdatedAt = '436095ac-79f2-59c9-afa3-596bb1887f61';
export const ConceptCapability = '40d0474e-cd28-5fde-b76f-d68f1b1de58e';
export const ConceptCapability_Description = '0dfa137f-2075-5d70-ab3f-7bc3294e351e';
export const ConceptCapability_Name = 'ecc005cd-8c1b-56d4-b791-490a1bb42dd7';
export const ConceptCapabilityAssignCollaborator = '08bda6d3-a12d-5cd3-b6e1-e1765fdbad55';
export const ConceptCapabilityAssignUser = '26e0ef86-cc86-50e6-9565-d53d8cca1438';
export const ConceptCapabilityCreate = 'aa4fca95-985e-48aa-bad5-cddba192fc75';
export const ConceptCapabilityDelete = 'd3717bcd-4751-5b9e-8ebf-40ce84f20ae0';
export const ConceptCapabilityEdit = '40f0bc5b-de2e-5770-9999-d5f66be3a22c';
export const ConceptCapabilityOverrideCollaborationWorkflow = '2c41ae9d-ee07-43a9-a056-7bda6c031adb';
export const ConceptCapabilityOverrideWorkflow = '4cf1be0c-7137-4bd9-9fda-c585cf7c8ee1';
export const ConceptCapabilityRead = 'cf5e9295-97ab-4778-ae6a-96d7bbc2ba93';
export const ConceptChipDisplay = 'd2e2e273-2ff2-427d-9d77-52c52de7fba7';
export const ConceptChipDisplay_Rank = 'd0444f47-5074-4496-b85f-80594591e190';
export const ConceptChipDisplay_Role_ConceptDefinition = 0;
export const ConceptChipDisplay_Role_Field = 1;
export const ConceptCollaborationFieldDimension = '225b516d-20b8-4783-ad2a-066622a5b43e';
export const ConceptCreatedAtDimension = '9089eb32-b8cc-423c-a48f-becf65b014e2';
export const ConceptDefinition = 'b4d1fa11-b826-5579-b21c-f24e57db65d0';
export const ConceptDefinition_ApiAlias = 'e7c7c7eb-7ad6-4245-8aa8-945d433f30cc';
export const ConceptDefinition_CardColorField = 'b730cd4a-5528-4625-b8a1-176d6f43531e';
export const ConceptDefinition_CardColorMode = '194702cf-4add-464a-ab15-1d509ed11cf1';
export const ConceptDefinition_CardImageField = '68c666fd-8086-4ab3-98b1-9f508fc22391';
export const ConceptDefinition_CardTitleField = '5c5e0934-aa76-45cf-9831-e44af099b6d4';
export const ConceptDefinition_ChipBackgroundColor = 'ad474572-df10-4843-a1aa-533c870a3258';
export const ConceptDefinition_ChipDisplayIcon = 'df0964ad-8703-4789-b915-0e0a5e3dcdc0';
export const ConceptDefinition_ChipFields = 'c9bf0a7a-771e-4048-9b1d-530d2632e522';
export const ConceptDefinition_ChipIconField = '99e4c4a6-4bab-41e4-8c3d-f6ac12561421';
export const ConceptDefinition_Color = '75ab9c40-8fdc-4c23-ac0a-9b186054f759';
export const ConceptDefinition_ConceptCapability_User_HasCapability = '95a27d51-589e-49b3-8215-bf06b14374cc';
export const ConceptDefinition_Description = '77649227-517e-5366-a11a-69786c48d774';
export const ConceptDefinition_FirstClassOnly = 'dc7d524d-4f75-5aa2-bbea-66670ea2cadb';
export const ConceptDefinition_HasStakeholders = '2450f582-73f6-41cb-9e04-55905b989a2d';
export const ConceptDefinition_Icon = '8f93c3c6-c30d-5620-8e3f-465f75d8516b';
export const ConceptDefinition_IsCore = 'c8379118-b4ce-5ef8-aa09-70977126e602';
export const ConceptDefinition_LibraryDisplayOption = '647b768e-890b-4dd0-a7ed-a7c2662ffb4b';
export const ConceptDefinition_LibraryItemPerPage = '24ef2a06-4f37-418a-8ba9-350107f4625f';
export const ConceptDefinition_LibraryShowMatrix = 'b5e241af-12d8-4c01-9da9-a121838521bf';
export const ConceptDefinition_LibraryShowSwimlane = '6714f8a2-189c-423a-b4bf-5dec0476757e';
export const ConceptDefinition_LibraryShowTable = '37beb027-bfa1-4101-a064-ce4aa71473db';
export const ConceptDefinition_LibraryShowTimeline = '60867033-78b0-46a5-821d-244cb2c50318';
export const ConceptDefinition_MainBooleanField = '8147174f-5d85-48f7-8120-8dcf64136f6e';
export const ConceptDefinition_MainIconField = '4adaa322-739c-4989-abec-2754e2ef08ad';
export const ConceptDefinition_MainWorkflowField = '2b24a359-4ad1-4e21-a409-87cc58c7a0be';
export const ConceptDefinition_MainWorkflowOnSecondLine = '446784ab-0924-45b1-bf93-83ec077151c4';
export const ConceptDefinition_MatrixAbs = '757f4697-735b-41e6-b045-f0c18770a9a5';
export const ConceptDefinition_MatrixDependency = 'e59ffee5-ccf4-4a17-8edb-4c0020084794';
export const ConceptDefinition_MatrixLabel = '36bdbf99-5f31-48ef-b75c-aa02a7449c79';
export const ConceptDefinition_MatrixOrd = '836e9547-c33f-46a1-8f55-2fc5cded81aa';
export const ConceptDefinition_Name = '9705b6d6-8456-5cb5-ac5f-3567c23c2472';
export const ConceptDefinition_Prefix = '200f2217-837d-40e2-86b0-3b0675d37dca';
export const ConceptDefinition_RestrictedAccess = 'a7d7a0ef-6aa4-5bbc-96c4-6b3b74598786';
export const ConceptDefinition_SanityScore = '68e50d8f-883b-4f6d-a1d2-e9e1230e597e';
export const ConceptDefinition_ShowEcosystemTab = '2504f1eb-d685-4180-9326-96d6a388c982';
export const ConceptDefinition_ShowMasterDetail = 'ac24857d-6ea1-488c-b8a4-3d6915c13c42';
export const ConceptDefinition_SwimlaneColumnBy = '15c59c2c-5282-459e-8f04-f1330d2b0655';
export const ConceptDefinition_SwimlaneGroupBy = '5d1af3fd-533a-45e4-8f64-c945b49c17a7';
export const ConceptDefinition_SwimlaneProgress = '874d38b0-3ca4-452d-96b8-9f1c57bfefb8';
export const ConceptDefinition_TableGroupBy = '89fd50fd-d70d-4df2-81b0-01bf76b1599e';
export const ConceptDefinition_Timeline = '281000ca-91a7-49c4-9a4f-89e713892c32';
export const ConceptDefinition_TimelineDependency = '8774db8b-73f5-4127-894b-290cdbcb79fd';
export const ConceptDefinition_TimelineGroupBy = 'dec43bef-de7e-4544-82ce-729b8fa49281';
export const ConceptDefinition_TimelineProgress = 'f77c353b-0a82-408e-9abd-70e65cf0a223';
export const ConceptDefinitionInstanceAdministrationColumn = '1c2c84c2-1aa4-4e50-9c2f-772360bcb988';
export const ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition = '23315bd2-4b8e-4b57-befc-27cd27ca083b';
export const ConceptDefinitionInstanceAdministrationColumn_Field = '73e9a3a7-16c0-45f5-9403-f1615e2de30e';
export const ConceptDefinitionInstanceAdministrationColumn_Rank = 'c8183e4d-7b2e-4bff-8be9-4c5f1978bc91';
export const ConceptDefinitionInstanceAdministrationColumn_Width = '6fcd07fe-b043-46ea-90c9-ccb00eecefbb';
export const ConceptExternalKeyMapping = '9840098d-7273-4791-a692-729b0e9e49ba';
export const ConceptExternalKeyMapping_ObjectId = 'cb8163ab-36f8-4f58-a85b-e95fc118ff42';
export const ConceptExternalKeyMapping_Role_ExternalKey = 1;
export const ConceptExternalKeyMapping_Role_ExternalKeyPropertyId = 0;
export const ConceptFunctionalIdDimension = 'c871fe56-fe1f-4007-aa48-2d9385170730';
export const ConceptGroupCapability = '7c4f584a-4822-46bb-88e8-1556f8fb9c01';
export const ConceptGroupCapability_Role_ConceptCapability = 1;
export const ConceptGroupCapability_Role_ConceptDefinition = 2;
export const ConceptGroupCapability_Role_ConceptGroup = 0;
export const ConceptInstanceWorkflowFieldField = 'eb76f3d1-2a06-4f43-a791-54c791c69c79';
export const ConceptInstanceWorkflowFieldField_Role_ConceptInstance = 2;
export const ConceptInstanceWorkflowFieldField_Role_Field = 1;
export const ConceptInstanceWorkflowFieldField_Role_WorkflowField = 0;
export const ConceptInstanceWorkflowFieldField_Value = 'c215e93f-4349-43ea-a7b7-70b29d833d37';
export const ConceptIntegrationCapability = 'e5b1bbb7-572e-4740-8713-c2df2c838dda';
export const ConceptIntegrationCapability_Role_ConceptCapability = 1;
export const ConceptIntegrationCapability_Role_Definition = 2;
export const ConceptIntegrationCapability_Role_Integration = 0;
export const ConceptNameDimension = 'aacb013d-09c1-4cce-b148-4a00b8bf1266';
export const ConceptResourceDimension = 'd2b3e506-10f6-4196-8f14-a064188d6d69';
export const ConceptRole = '06bfcc5f-29f3-5209-8654-bccb3fb93941';
export const ConceptRole_AssignedByDefault = 'ffcbb8d7-9794-50f1-9fb6-d4e4267a1e71';
export const ConceptRole_ConceptDefinition = '41646f39-ee06-52a9-afc0-2662d516c50e';
export const ConceptRole_ForCollaboration = '23fb6c91-e3ea-5400-b917-e6dae35e9547';
export const ConceptRoleCapability = '197b9374-3935-5af7-8984-6bba7094b5bf';
export const ConceptRoleCapability_Role_ConceptCapability = 1;
export const ConceptRoleCapability_Role_ConceptRole = 0;
export const ConceptRoleGroupAssignation = 'e2312a4c-0e03-55a2-ba2e-9dd65f05d481';
export const ConceptRoleGroupAssignation_Role_Concept = 0;
export const ConceptRoleGroupAssignation_Role_ConceptRole = 1;
export const ConceptRoleGroupAssignation_Role_Group = 2;
export const ConceptRoleUserAssignation = '01fcd590-794c-51b0-b123-0641ffae8e4c';
export const ConceptRoleUserAssignation_Role_Concept = 0;
export const ConceptRoleUserAssignation_Role_ConceptRole = 1;
export const ConceptRoleUserAssignation_Role_User = 2;
export const ConceptStakeholdersGroupsDimension = 'e3786131-99a7-4a2b-8cf8-2b588430c4bd';
export const ConceptStakeholdersRolesDimension = 'd437f8db-babe-4e17-95d8-4f40a6c19c73';
export const ConceptStakeholdersUsersDimension = '73f48a07-3872-48bb-930d-df5a973afaaa';
export const ConceptUpdatedAtDimension = '8cc2cd86-8f3b-4a47-a603-0e37bfe1577f';
export const ConditionFilter = '3525baa7-c160-49cb-83c3-b4b95742187a';
export const ConditionFilter_ActivateByDefault = '9f6389ef-0703-42b9-ab26-626c44a001b0';
export const ConditionFilter_Filters = '32c0468d-b0dd-4a6d-9381-1bc0253bfad9';
export const DateField = 'acc6fc44-56ff-4be4-8571-e315a9191a9b';
export const DateField_DefaultPeriod = '5edf6cd2-2e5d-4ad0-a6ae-b102a8eb832b';
export const DateField_Rank = '2fb19fa1-eb48-4de9-a9b2-f8df12876b19';
export const EmbeddingField = '85e3f8cb-90f8-5ab3-acc1-cbcfd2e77e19';
export const EmbeddingField_FromType = 'cf1dad8f-657b-5a27-8aa8-9f032c97663e';
export const EmbeddingField_Label = 'e14be46d-9a18-5bf8-9db5-19c9d3f82afe';
export const EmbeddingField_ToType = '1044c75e-e1a4-5a5d-ad4c-f0d3a815f20a';
export const ExternalKeyField = 'fd1f4052-2343-4413-9a04-ccc8dca5dba0';
export const ExternalKeyField_IsImmutable = 'b736a6f1-c315-43b4-b422-6e42b272e424';
export const ExternalKeyField_IsRequired = '46bd0430-5dc7-4079-aa18-042130f77c29';
export const ExternalKeyField_PreventKeyStealing = 'd60a338c-6f65-42ea-9802-6fc017575ecb';
export const ExternalKeyField_RegexValidation = '5c187076-d39e-44ec-93fe-2da703fdab7c';
export const Field = 'cfac45c9-495f-54bb-9048-0d11bb2e507d';
export const Field_ApiAlias = '8ee3a1a1-a261-446e-86a4-e0c5f1a0c792';
export const Field_Configuration = '77443458-97de-4a49-93f8-7e8371178929';
export const Field_Documentation = 'af6bd2c8-956e-4678-8617-10e68b7e3533';
export const Field_Formula = 'e28677d9-1ae7-5b45-8360-654de66547c2';
export const Field_IntegrationOnly = 'b6a9c360-c1e2-41a3-b090-f13a17bc9735';
export const Field_IsCore = '3210501e-bfdd-4f19-89ed-b4905d667cd7';
export const Field_IsDocumentationInline = '47ad618d-b7af-4783-ac21-39f356d924cc';
export const Field_IsTitleHidden = '08e08969-9ae9-4010-b5ea-8b82f5508f83';
export const Field_ParsedFormula = '6189f293-b57f-4e32-9266-79e0d7308f37';
export const Field_Title = '554e37c5-66f2-5413-a6de-1e309b7be8cb';
export const FieldDefinition = '5ae526b6-4e8c-556b-b4c6-9e33fc0084db';
export const FieldDefinition_Title = '715880eb-6bbe-5f47-a820-3b3889ea8cde';
export const FieldDimension = '4ba3d19a-194f-4651-ac4f-611b9e6a02cf';
export const FieldDimension_Field = '7ef87b35-9e1b-4df2-89b8-e12a595dd505';
export const FieldDimension_IsMandatory = 'a27de92a-2ac4-4106-95ea-6441d8bd5cc8';
export const FieldDimension_Label = '17356e73-105a-4aa4-a155-d7a519153c84';
export const FieldDimensionTypes = '06976164-0789-4a86-b74f-4eaf5ee816a8';
export const FieldDimensionTypes_Role_ConceptDefinition = 1;
export const FieldDimensionTypes_Role_FieldDimension = 0;
export const FieldLocalOverride = 'aeb20cea-f238-405d-a5b0-fb4132a3cdfe';
export const FieldLocalOverride_NumberFieldDecimals = '1d3d990f-3086-450d-898a-9e9ba952a3ac';
export const FieldLocalOverride_NumberFieldInvalidColor = '00d7682b-e9d3-4f79-a9a7-3b1459dd16af';
export const FieldLocalOverride_NumberFieldMaxValue = '82d67e4e-e711-4c0e-b8ce-b6d93a48bb37';
export const FieldLocalOverride_NumberFieldMinValue = 'e4a9d1da-ed44-4642-988d-29a72c77e3b5';
export const FieldLocalOverride_NumberFieldRangeValue = '51ae66c0-2eda-43e9-b5a7-0453c0bf1a5d';
export const FieldLocalOverride_NumberFieldUnit = '61f1fc07-2c40-439f-b2bc-993ce1ec77df';
export const FieldLocalOverride_Role_Concept = 0;
export const FieldLocalOverride_Role_Field = 1;
export const FieldLocalOverride_TimeseriesNumberFieldCumulative = '32f1c235-9b2d-40b3-9361-0bfece9b5087';
export const FieldLocalOverride_TimeseriesNumberFieldDecimals = 'c8daddc6-fae4-41f9-8aba-33a6f3c5c7a2';
export const FieldLocalOverride_TimeseriesNumberFieldDefaultPeriod = 'f0a8ac1f-b60f-40d1-86a3-3a222a1dd526';
export const FieldLocalOverride_TimeseriesNumberFieldInvalidColor = '9a44a6ee-c010-4c4a-8ead-390fac77ae8f';
export const FieldLocalOverride_TimeseriesNumberFieldMaxValue = '3eea91ef-8ba1-4267-b8c0-f1fd3f3f8fc8';
export const FieldLocalOverride_TimeseriesNumberFieldMinValue = '6537c193-6e05-4e73-8484-7ba9b4b55a77';
export const FieldLocalOverride_TimeseriesNumberFieldRangeValue = '165b0544-db7b-473d-95c6-403876cd300c';
export const FieldLocalOverride_TimeseriesNumberFieldUnit = 'd135d16c-ede4-44b7-b106-a01934085711';
export const FieldLocalOverride_TimeseriesTextFieldDefaultPeriod = '6bc61c97-b701-4816-830d-4fb3ad482a62';
export const FieldLocalOverrideProperty = 'cbb6c4a5-9f3e-4454-97c9-f5e5e6a4a0c1';
export const FieldLocalOverrideProperty_OverrideProperty = '7f40461d-a608-4b34-bbb7-7cebbb137684';
export const FieldXConcept_Configuration = 'db58cb2d-7de3-4f4c-8a61-40347bd2a7fd';
export const Group = '735982a2-3b8d-55f1-8582-9e13600b17d2';
export const Group_GrantedOnCreation = '943b708c-8a01-43ac-b34e-0142551a7bf2';
export const Group_Users = 'f3bb2837-6c8d-4d2b-84dd-25fd6e9f3e4a';
export const GroupMembershipAssociationDefinition = 'ebdd1e57-60ce-4013-b7e9-5d5f1693b778';
export const GroupMembershipDimension = 'ceaa6536-b48c-40f0-9c73-3b7fe086d218';
export const IconField = 'c1565e60-6bb8-4c01-b2ca-3f383fc6fc92';
export const IdField = '250afa0e-acd6-56f7-b33a-a0ed4ca43039';
export const IdFieldAssociation = 'c5a5cfe5-4d62-573d-a3a6-33a74708c9de';
export const IdFieldAssociation_NextId = '49e7305c-40a5-50ec-88d5-3418f91a344c';
export const IdFieldAssociation_Role_ConceptDefinition = 1;
export const IdFieldAssociation_Role_Field = 0;
export const ImageField = '1ada909c-3b3d-51f5-98db-c8cdd1e66d6e';
export const ImageField_AspectRatio = 'f1a0c564-9ba2-4299-8f31-9b85bc0df6af';
export const KinshipRelation = '4891be9b-4af6-52dd-979b-15eaaa356035';
export const KinshipRelationDimension = 'fe237dce-ea4a-4d6c-b7a7-c0867bbbf23b';
export const KinshipRelationField = '53e27b83-cc95-574a-b409-5d02fff074b3';
export const NumberField = '362b524e-b151-5202-bb73-a1b59026b0e7';
export const NumberField_Decimals = 'e438aa81-01ce-4e29-978e-b9c90892a65f';
export const NumberField_InvalidColor = '9633010b-e047-4b25-9e0a-68d5ff75c763';
export const NumberField_MaxValue = '37785684-2da8-4e93-89d7-2fa1ab72fae2';
export const NumberField_MinValue = '54fa7cb8-375a-49a1-aed0-93e4d9b56026';
export const NumberField_RangeValue = '3bb97aa5-73b6-4f75-97b2-0f8243c312d6';
export const NumberField_Unit = 'eef83584-bf97-574e-a22c-72321dc73bc0';
export const PlatformCapability = 'f51c51b3-6dfe-46db-976b-7493db89f056';
export const PlatformCapabilityAdmin = '316d43ba-55c7-451d-8693-4b0ebc7ba91e';
export const PlatformCapabilityInviteUser = 'ae126169-534b-4937-9280-8e531386162e';
export const PlatformCapabilityUpdateEmail = '8f486a23-6933-48b7-9b48-5b26501703d2';
export const PlatformGroupCapability = 'c026e8fa-e626-4b0a-8187-620bae46575a';
export const PlatformGroupCapability_Role_Group = 0;
export const PlatformGroupCapability_Role_PlatformCapability = 1;
export const RelationMultipleField = '2f3c2935-fe91-5738-9665-f552f1b71841';
export const RelationMultipleField_Field_TargetFilter = 'fd34e2eb-fd7f-4240-a5c9-5cedba551962';
export const RelationMultipleField_ReverseField = 'eb012c45-ec63-52af-8834-08bb08a4cfb8';
export const RelationMultipleField_TargetType = 'b0d73b09-488d-50f6-84f2-9066a04359fd';
export const RelationSingleField = '8e4480ec-c582-5b49-b3ba-9af38ff3c70a';
export const RelationSingleField_Field_TargetFilter = 'f1725192-6131-49f1-9789-e3da611a3ada';
export const RelationSingleField_TargetType = '827b108f-5209-5d80-b261-ee097a0e3a44';
export const Resource_Concepts = '9055bd24-9434-5253-8349-5b3a0fd63104';
export const ReverseWorkflowField = '85384f76-0051-4c63-96db-4c1f8b36ac84';
export const ReverseWorkflowField_ReverseField = 'a4c67018-d3d8-4d16-9a2b-9b47424c9153';
export const StakeholdersField = '23099dba-9c2a-40ea-ac79-ab1bc07d35b5';
export const StakeholdersField_TargetType = '95fda438-434a-469f-a608-3e402aa87c9c';
export const TextField = 'c9521922-e4e8-5e3c-873e-0678931215bb';
export const TimelineField = '39def58b-f455-57a0-ab93-7a2e9f657c34';
export const TimelineField_DefaultPeriod = '9daa1ee7-ff3d-4e65-8c21-80fcbd4c302d';
export const TimelineField_Period = 'c9255118-36f4-5c45-859e-a664d5e3df27';
export const TimelineField_Rank = '00586960-7903-50c4-be23-dc8552281295';
export const TimelineField_StartConstraint = 'deca2cc0-b5d7-41f6-b5db-6effbfbe692e';
export const TimeseriesNumberField = '3095f530-9995-4845-b4b6-d7217168681f';
export const TimeseriesNumberField_Cumulative = 'ffbddec8-604c-4d77-8efe-2bc952f7269c';
export const TimeseriesNumberField_Decimals = 'aa44e06f-6ae8-4de6-b023-74e7ebf19b0f';
export const TimeseriesNumberField_DefaultPeriod = '3a81e596-a38e-451e-9e06-602266178e54';
export const TimeseriesNumberField_InvalidColor = '08b4a4fa-6e72-44df-9220-f05e17dd930e';
export const TimeseriesNumberField_MaxValue = 'f39dfc38-e3f8-489b-82f4-79f4f36752de';
export const TimeseriesNumberField_MinValue = 'dd910eee-4442-4c23-84a1-c967dd6b0264';
export const TimeseriesNumberField_RangeValue = '3c3fd8b2-3c93-482b-a3b1-b8c5dbd891f7';
export const TimeseriesNumberField_Unit = '2c9cc192-a29f-4d59-a8ce-abb95d21230e';
export const TimeseriesTextField = '67fae5e7-991b-4d6f-9e84-c5250ba923ec';
export const TimeseriesTextField_DefaultPeriod = '588e81ce-2da4-4f35-b121-4429be7b2938';
export const TimeStampField = 'd15f3632-f12e-5c99-9c51-ba24fd88c5fb';
export const UrlField = '1d3b43a2-add2-5a77-9d79-af0b0467ca03';
export const User = 'aa3a3281-bae0-5710-a9c4-193affed9a2d';
export const User_AuthenticationId = '43e7caf4-4165-4b4c-8190-334b1e4bb768';
export const User_Email = '121e534c-191e-59dd-9259-5353b383d320';
export const User_GroupIds = '8e0bf7b7-c640-4989-901e-298a51d8847f';
export const User_Groups = 'ee62bcf7-a3e4-460d-921e-afbec5af25db';
export const User_IsEnabled = 'ef50a348-6acb-569e-8217-03df091a43ef';
export const User_Type = '71a072e6-55e6-59eb-b376-a4092b4db359';
export const UserAuthenticationIdDimension = 'a496121d-3a30-4c59-86ca-93cae986b8fd';
export const UserEmailDimension = '38aba836-5165-4d4d-8807-fc402805c893';
export const UserGroupMembershipDimension = 'ff491f39-88d5-4591-af04-4977891b84be';
export const UserIsEnabledDimension = 'd15aac10-f6e2-4dab-aa7a-0603e39cc586';
export const ViewFilter = 'a37fc00a-b98d-4c80-9fe1-413f179986eb';
export const ViewFilter_ConceptDefinition = 'db53d49e-031a-405c-a322-c8fd270531fd';
export const ViewFilter_FieldBlockDisplay = 'f56394b4-0423-4171-af4f-5ec646ed7b5f';
export const ViewFilter_Label = 'b8842cd7-df5c-47ce-a205-05a0959cb03e';
export const ViewFilter_Rank = 'f3c1bf71-a7f9-4b24-8e48-1a45d272f53a';
export const ViewFilter_ViewsField = '8f857135-cec4-43fe-851e-b67d3eae865e';
export const Workflow = '2141354e-5298-41ec-a9d2-faacb9034b8a';
export const Workflow_Description = 'c5848d0b-ef8a-4e93-bb74-2163dd52c106';
export const Workflow_Name = 'b48f39f2-8050-4484-8e2d-58831d7591b5';
export const Workflow_TargetedConceptDefinition = 'e3004b8a-1f76-43b0-b024-28590f2f54bc';
export const WorkflowEntry = '53f187f1-9f9d-43cf-a00f-beb7b7a898a2';
export const WorkflowEntry_Rank = '3dfee13c-f57d-465e-a7a5-2cce04856ccc';
export const WorkflowEntry_Role_Concept = 1;
export const WorkflowEntry_Role_Workflow = 0;
export const WorkflowField = 'a773821b-e04e-4359-b2f1-4b86e3e7681a';
export const WorkflowField_TargetedConceptDefinition = 'b57c81a6-4ffe-4415-9702-7cfd63a1d348';
export const WorkflowField_Workflow = 'e4296b3f-a7c8-4b3d-8be3-3be8be820461';
export const WorkflowFieldEntry = '0bae1dc1-cd52-4fbf-84e2-20fdee329508';
export const WorkflowFieldEntry_Filters = '3b663e5b-ee75-4158-b56e-5fc0ae0a343b';
export const WorkflowFieldEntry_Role_Entry = 1;
export const WorkflowFieldEntry_Role_WorkflowField = 0;
export const WorkflowFieldFields = 'e89adea1-a477-44fc-ab64-abea94a81fe4';
export const WorkflowFieldFields_Role_Field = 1;
export const WorkflowFieldFields_Role_WorkflowField = 0;
export const WorkflowFieldFieldsTransitionExecution = '9e00e5e0-dee4-4089-a1d5-9ab7392cbf6e';
export const WorkflowFieldFieldsTransitionExecution_Role_Concept = 1;
export const WorkflowFieldFieldsTransitionExecution_Role_Field = 2;
export const WorkflowFieldFieldsTransitionExecution_Role_WorkflowField = 0;
export const WorkflowFieldTransition = 'addb8b7f-559e-4419-965b-799040c9015f';
export const WorkflowFieldTransition_Filters = '70cc7f08-78de-4de9-b24a-5a4503b8a209';
export const WorkflowFieldTransition_Rights = 'cc66e454-0276-4c64-bc97-46ff51b39890';
export const WorkflowFieldTransition_Role_Transition = 1;
export const WorkflowFieldTransition_Role_WorkflowField = 0;
export const WorkflowFieldTransitionExecution = 'c955babb-7cf3-49ec-afda-2cb631ec6cbf';
export const WorkflowFieldTransitionExecution_Role_Concept = 1;
export const WorkflowFieldTransitionExecution_Role_WorkflowField = 0;
export const WorkflowTransition = '4b1950b8-59b7-407c-a964-f52214dc590f';
export const WorkflowTransition_From = 'ecb3ae6d-7a4e-4e54-9e94-0b0e8777650c';
export const WorkflowTransition_Name = '45f4812c-a8e3-4343-83bf-8df85e0c0772';
export const WorkflowTransition_Owner = 'e7750672-cc51-4218-981b-2b55f7486866';
export const WorkflowTransition_To = 'fc04ef1d-c0fa-48d0-8cae-78c26aa1d890';
export const AssociationFieldDefinition_Fields = AssociationField_Definition;
export const Concept_AsWorkflowFrom = WorkflowTransition_From;
export const Concept_AsWorkflowTo = WorkflowTransition_To;
export const ConceptDefinition_AdministrationColumns = ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition;
export const ConceptDefinition_AsWorkflowTarget = Workflow_TargetedConceptDefinition;
export const ConceptDefinition_Roles = ConceptRole_ConceptDefinition;
export const ConceptDefinition_UsedAsWorkflowFieldTarget = WorkflowField_TargetedConceptDefinition;
export const ConceptDefinition_ViewFilters = ViewFilter_ConceptDefinition;
export const Field_AsCardColor = ConceptDefinition_CardColorField;
export const Field_AsCardImage = ConceptDefinition_CardImageField;
export const Field_AsCardTitle = ConceptDefinition_CardTitleField;
export const Field_AsChipIcon = ConceptDefinition_ChipIconField;
export const Field_AsConceptDefinitionInstanceAdministrationColumn = ConceptDefinitionInstanceAdministrationColumn_Field;
export const Field_AsMainIcon = ConceptDefinition_MainIconField;
export const Field_AsMainWorkflow = ConceptDefinition_MainWorkflowField;
export const Field_DefaultColorField = ConceptDefinition_Color;
export const Field_DefaultMatrixAbsField = ConceptDefinition_MatrixAbs;
export const Field_DefaultMatrixDependencyField = ConceptDefinition_MatrixDependency;
export const Field_DefaultMatrixLabelField = ConceptDefinition_MatrixLabel;
export const Field_DefaultMatrixOrdField = ConceptDefinition_MatrixOrd;
export const Field_DefaultSanityScoreByField = ConceptDefinition_SanityScore;
export const Field_DefaultSwimlaneColumnByField = ConceptDefinition_SwimlaneColumnBy;
export const Field_DefaultSwimlaneGroupByField = ConceptDefinition_SwimlaneGroupBy;
export const Field_DefaultSwimlaneProgressField = ConceptDefinition_SwimlaneProgress;
export const Field_DefaultTableGroupByField = ConceptDefinition_TableGroupBy;
export const Field_DefaultTimelineDependencyField = ConceptDefinition_TimelineDependency;
export const Field_DefaultTimelineField = ConceptDefinition_Timeline;
export const Field_DefaultTimelineGroupByField = ConceptDefinition_TimelineGroupBy;
export const Field_DefaultTimelineProgressField = ConceptDefinition_TimelineProgress;
export const Field_FieldDimensions = FieldDimension_Field;
export const FieldBlockDisplay_ViewFilters = ViewFilter_FieldBlockDisplay;
export const ModelProperty_OverriddenBy = FieldLocalOverrideProperty_OverrideProperty;
export const ModelType_AssociationFieldDefinitionAsRole1 = AssociationFieldDefinition_Role1Type;
export const ModelType_AssociationFieldDefinitionAsRole2 = AssociationFieldDefinition_Role2Type;
export const ModelType_TargetedByRelationMultipleFields = RelationMultipleField_TargetType;
export const ModelType_TargetedByRelationSingleFields = RelationSingleField_TargetType;
export const RelationSingleField_ReverseFields = RelationMultipleField_ReverseField;
export const UploadedAttachments = Attachment_UploadedBy;
export const ViewsField_ViewFilters = ViewFilter_ViewsField;
export const Workflow_Transitions = WorkflowTransition_Owner;
export const Workflow_UsedByWorkflowField = WorkflowField_Workflow;
export const WorkflowField_ReverseFields = ReverseWorkflowField_ReverseField;
