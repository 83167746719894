import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { FilterConditionOperators } from 'yooi-modules/modules/conceptModule';
import Typo from '../../../../components/atoms/Typo';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { getFilterConditionLabel } from '../filterUtils';

const useStyles = makeStyles((theme) => ({
  filterOperatorTypo: {
    marginLeft: spacingRem.s,
    marginTop: spacingRem.xs,
    color: theme.color.text.primary,
  },
  filterNodeCondOperatorTypo: {
    marginTop: spacingRem.splus,
  },
}), 'filterOperator');

interface FilterOperatorProps {
  index: number,
  selectedCondition: FilterConditionOperators,
  readOnly: boolean,
  onNodeConditionChange?: (condition: FilterConditionOperators) => void,
  isNodeOperator?: boolean,
}

const FilterOperator: FunctionComponent<FilterOperatorProps> = ({
  index,
  selectedCondition,
  readOnly,
  onNodeConditionChange,
  isNodeOperator = false,
}) => {
  const classes = useStyles();
  const conditions = (Object.keys(FilterConditionOperators) as FilterConditionOperators[])
    .map((id) => ({ id, label: getFilterConditionLabel(id) }));
  if (index === 0) {
    return (
      <div
        className={classnames({
          [classes.filterOperatorTypo]: true,
          [classes.filterNodeCondOperatorTypo]: isNodeOperator,
        })}
      >
        <Typo>{i18n`When`}</Typo>
      </div>
    );
  } else if (index === 1) {
    return (
      <div>
        <SearchAndSelect
          computeOptions={() => conditions}
          selectedOption={{ id: selectedCondition, label: getFilterConditionLabel(selectedCondition) }}
          onSelect={(option) => onNodeConditionChange && option && onNodeConditionChange(option.id)}
          minWidth={6}
          readOnly={readOnly || index > 1}
        />
      </div>
    );
  } else {
    return (
      <div
        className={classnames({
          [classes.filterOperatorTypo]: true,
          [classes.filterNodeCondOperatorTypo]: isNodeOperator,
        })}
      >
        <Typo>{getFilterConditionLabel(selectedCondition)}</Typo>
      </div>
    );
  }
};

export default FilterOperator;
