import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { API_LABEL_ACCEPTED_CHARS_REGEX } from 'yooi-modules';
import type { ConceptDefinitionStoreObject, FieldDimensionStoreObject, FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { getAllFieldDimensionsIds, getConceptDefinitionValidFields, getFieldUtilsHandler } from 'yooi-modules/modules/conceptModule';
import {
  ConceptDefinition,
  ConceptDefinition_ApiAlias,
  ConceptDefinition_Name,
  Field_ApiAlias,
  Field_FieldDimensions,
  Field_Formula,
  Field_IsCore,
  Field_Title,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
} from 'yooi-modules/modules/conceptModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareProperty, compareString, filterNullOrUndefined, toSnakeCase } from 'yooi-utils';
import { IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import Tooltip from '../../../../../components/atoms/Tooltip';
import Typo from '../../../../../components/atoms/Typo';
import CompositeField, { CompositeFieldVariants } from '../../../../../components/molecules/CompositeField';
import type { MenuItem } from '../../../../../components/molecules/Menu';
import { TableSortDirection } from '../../../../../components/molecules/Table';
import TagContainer from '../../../../../components/molecules/TagContainer';
import Toggle from '../../../../../components/molecules/Toggle';
import ToggleButton, { ElementPosition } from '../../../../../components/molecules/ToggleButton';
import BlockContent from '../../../../../components/templates/BlockContent';
import BlockTitle from '../../../../../components/templates/BlockTitle';
import type { ColumnDefinition } from '../../../../../components/templates/DataTable';
import DataTable from '../../../../../components/templates/DataTable';
import HorizontalBlock from '../../../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../../../components/templates/VerticalBlock';
import useStore from '../../../../../store/useStore';
import { spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import { safeSessionStorageValue } from '../../../../../utils/sessionStorageUtils';
import { formatOrUndef } from '../../../../../utils/stringUtils';
import useDeleteModal from '../../../../../utils/useDeleteModal';
import useNavigation from '../../../../../utils/useNavigation';
import { SessionStorageKeys, useSessionStorageState } from '../../../../../utils/useSessionStorage';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../../../utils/useSizeContext';
import { getFieldConfigurationHandler, getFieldDefinitionHandler } from '../../../../_global/fields/FieldLibrary';
import FieldTypeEditionComposite from '../../../../_global/FieldTypeEditionComposite';
import { getFieldLabel } from '../../../../_global/fieldUtils';
import SearchTextButton from '../../../../_global/filter/SearchTextButton';
import { useFilterStorage } from '../../../../_global/filter/useFilterSessionStorage';
import StoreTextInputField from '../../../../_global/input/StoreTextInputField';
import { searchFilterFunction } from '../../../../_global/listFilterFunctions';
import { getObjectName } from '../../../../_global/modelTypeUtils';
import type { NavigationFilter } from '../../../../_global/navigationUtils';
import { getNavigationPayload } from '../../../../_global/navigationUtils';
import { share } from '../../../../_global/shareUtils';
import useFilterAndSort, { buildStringColumnComparatorHandler } from '../../../../_global/useFilterAndSort';
import NewFieldLine from '../../../_global/NewFieldLine';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacingRem.l,
    paddingBottom: spacingRem.xs,
  },
  headerRightContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.m,
  },
  filtersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: spacingRem.s,
    justifyContent: 'flex-start',
    marginBottom: spacingRem.xs,
  },
  toggleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingRem.s,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: spacingRem.s,
    flexGrow: 1,
    alignItems: 'center',
  },
  child: {
    flexGrow: 0,
  },
}, 'fieldsTab');

interface FieldFilterConfiguration {
  nameSearch?: string,
  typeFilters?: string[],
  showMultidimensional?: boolean,
  showComputed?: boolean,
}

interface ListItem {
  key: string,
  field: FieldStoreObject,
  title: string | undefined,
  type: string | undefined,
  targetType: string | undefined,
  targetTypes: string,
  apiAlias: string | undefined,
}

interface FieldsTabProps {
  conceptDefinitionId: string,
}

const FieldsTab: FunctionComponent<FieldsTabProps> = ({ conceptDefinitionId }) => {
  const classes = useStyles();

  const store = useStore();

  const navigation = useNavigation<NavigationFilter>();

  const conceptDefinition = store.getObject<ConceptDefinitionStoreObject>(conceptDefinitionId);
  const suggestedConceptDefinitionApiAlias = toSnakeCase(conceptDefinition[ConceptDefinition_Name] ?? '');

  const [showLine, setShowLine] = useState(false);
  const [showDeveloperMode, setShowDeveloperMode] = useSessionStorageState(SessionStorageKeys.developerMode, false);

  const [showFilters, setShowFilters] = useState(true);

  const existingFieldDefinitionIds = Array.from(new Set(getConceptDefinitionValidFields(store, conceptDefinitionId).map(({ [Instance_Of]: instanceOf }) => instanceOf)));

  const filterId = `${conceptDefinitionId}_library`;

  const [showMultidimensional = false, setShowMultidimensional] = (
    useFilterStorage<'showMultidimensional', FieldFilterConfiguration>(filterId, 'showMultidimensional')
  );
  const [showComputed = false, setShowComputed] = useFilterStorage<'showComputed', FieldFilterConfiguration>(filterId, 'showComputed');

  const [typeFilters = [], setTypeFilters] = useFilterStorage<'typeFilters', FieldFilterConfiguration>(filterId, 'typeFilters');
  const actualTypeFilters = typeFilters.filter((id) => existingFieldDefinitionIds.includes(id));

  const filterFunction = searchFilterFunction<Record<string, unknown>>(store, safeSessionStorageValue<FieldFilterConfiguration | undefined>(filterId)?.nameSearch, ['title', 'type', 'apiAlias', 'targetType', 'targetTypes']);
  const { generatePageList, doSort, sortCriteria, forceFollowingIds, forceShowId } = useFilterAndSort<ListItem>(
    filterId,
    getConceptDefinitionValidFields(store, conceptDefinitionId)
      .map((field): ListItem => {
        const targetType = getFieldUtilsHandler(store, field.id).getTargetType?.();
        const targetTypes = getFieldUtilsHandler(store, field.id).getTargetTypes?.(conceptDefinitionId) ?? [];
        return {
          key: field.id,
          field,
          title: getFieldLabel(store, field),
          type: formatOrUndef(getFieldConfigurationHandler(store, field.id)?.getTypeLabel()),
          targetType: targetType ? getObjectName(store, targetType) : undefined,
          targetTypes: targetTypes.map((type) => getObjectName(store, type)).join(', '),
          apiAlias: field[Field_ApiAlias],
        };
      }),
    (item) => (
      (filterFunction?.(item as unknown as Record<string, unknown>) ?? true)
      && (actualTypeFilters.length === 0 || actualTypeFilters.includes(item.field[Instance_Of]))
      && (!showMultidimensional || getAllFieldDimensionsIds(store, item.field.id).length > 1)
      && (!showComputed || !!item.field[Field_Formula])
    ),
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case 'title':
          case 'type':
          case 'apiAlias':
            return buildStringColumnComparatorHandler(key, direction);
          default:
            return undefined;
        }
      },
      initial: { key: 'title', direction: TableSortDirection.asc },
    }
  );
  const { list, pagination } = generatePageList(25);

  const [doDelete, deleteModal] = useDeleteModal<string>({
    doDelete: (id) => {
      store.deleteObject(id);
    },
    shouldConfirm: () => true,
    getModalProps: () => ({
      title: i18n`Are you sure that you want to delete this field?`,
      content: (<Typo>{i18n`It will also remove all associated elements.`}</Typo>),
    }),
  });

  const columnDefinitions: ColumnDefinition<ListItem>[] = [
    {
      propertyId: 'title',
      name: i18n`Name`,
      width: showDeveloperMode ? 30 : 40,
      sortable: true,
      cellRender: ({ field }) => (
        <StoreTextInputField
          initialValue={field[Field_Title]}
          placeholder={!field[Field_Title] ? getFieldLabel(store, field) : undefined}
          onSubmit={(newValue) => store.updateObject(field.id, { [Field_Title]: newValue })}
          info={field[Field_IsCore] ? i18n`Warning, modifying this field will update the field title for every concept sharing this value` : undefined}
        />
      ),
      openButton: () => true,
    },
    {
      propertyId: 'type',
      name: i18n`Type`,
      width: showDeveloperMode ? 50 : 60,
      sortable: true,
      cellRender: ({ field }) => (<FieldTypeEditionComposite fieldId={field.id} conceptDefinitionId={conceptDefinitionId} isDeveloperMode={showDeveloperMode} />),
    },
  ];

  if (showDeveloperMode) {
    columnDefinitions.push({
      propertyId: 'apiAlias',
      name: i18n`API alias`,
      width: 20,
      sortable: true,
      cellRender: ({ field }) => {
        const suggestedFieldApiAlias = toSnakeCase(field[Field_Title] ?? '');

        const existingApiAliases = new Set<string>(
          field
            .navigateBack<FieldDimensionStoreObject>(Field_FieldDimensions)
            .flatMap((fieldDimension) => (
              store.withAssociation(FieldDimensionTypes)
                .withRole(FieldDimensionTypes_Role_FieldDimension, fieldDimension.id)
                .withRole(FieldDimensionTypes_Role_ConceptDefinition, conceptDefinitionId)
                .getObjectOrNull()
                ? [conceptDefinitionId]
                : store.withAssociation(FieldDimensionTypes)
                  .withRole(FieldDimensionTypes_Role_FieldDimension, fieldDimension.id)
                  .list()
                  .map((assoc) => assoc.role(FieldDimensionTypes_Role_ConceptDefinition))
            ))
            .flatMap((typeId) => getConceptDefinitionValidFields(store, typeId))
            .filter(({ id }) => id !== field.id)
            .map(({ [Field_ApiAlias]: apiAlias }) => apiAlias)
            .filter(filterNullOrUndefined)
        );

        const isReadOnly = !getFieldUtilsHandler(store, field.id).withApiAlias;

        return (
          <StoreTextInputField
            initialValue={field[Field_ApiAlias]}
            onSubmit={(newApiAlias) => {
              store.updateObject(field.id, { [Field_ApiAlias]: newApiAlias });
            }}
            acceptChars={API_LABEL_ACCEPTED_CHARS_REGEX}
            acceptCharsErrorMessage={i18n`API alias should only contain letters, numbers, '-' or '_'`}
            action={
              !isReadOnly && suggestedFieldApiAlias !== field[Field_ApiAlias] && !existingApiAliases.has(suggestedFieldApiAlias)
                ? {
                  icon: IconName.sync,
                  tooltip: i18n`Reset to default`,
                  onClick: () => {
                    store.updateObject(field.id, { [Field_ApiAlias]: suggestedFieldApiAlias });
                  },
                }
                : undefined
            }
            error={
              field[Field_ApiAlias] && existingApiAliases.has(field[Field_ApiAlias])
                ? i18n`Another field already use this API alias. Fields won't be available in the API until the conflict is resolved.`
                : undefined
            }
            readOnly={isReadOnly}
          />
        );
      },
    });
  }

  let filterCount = 0;
  if (actualTypeFilters.length > 0) {
    filterCount += 1;
  }
  if (showMultidimensional) {
    filterCount += 1;
  }
  if (showComputed) {
    filterCount += 1;
  }

  return (
    <>
      <VerticalBlock compact>
        <BlockContent padded>
          <div className={classes.headerContainer}>
            <ToggleButton
              name={i18n`Developer mode`}
              icon={showDeveloperMode ? IconName.toggle_on : IconName.toggle_off}
              onClick={() => setShowDeveloperMode(!showDeveloperMode)}
              active={showDeveloperMode}
              type={ElementPosition.alone}
            />
            <div className={classes.headerRightContainer}>
              <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
                <SearchTextButton element={filterId} placeholder={i18n`Search`} />
                <ToggleButton
                  active={showFilters}
                  tooltip={i18n`Filters`}
                  onClick={() => setShowFilters((current) => !current)}
                  icon={IconName.filter_alt}
                  count={filterCount > 0 ? filterCount : undefined}
                />
              </SizeContextProvider>
            </div>
          </div>
        </BlockContent>
        {showFilters ? (
          <BlockContent padded>
            <div className={classes.filtersContainer}>
              <SizeContextProvider sizeVariant={SizeVariant.small}>
                <span className={classes.child}>
                  <CompositeField
                    dropdownMaxWidth="50rem"
                    variant={CompositeFieldVariants.button}
                    isSelected={actualTypeFilters.length > 0}
                    headerLinesRenderers={[
                      {
                        id: 'title',
                        render: (inDropdown) => {
                          const label = actualTypeFilters.length > 0 ? i18n`Type (${actualTypeFilters.length})` : i18n`Type`;
                          return (
                            <div className={classes.titleContainer}>
                              <Tooltip title={label}>
                                <Typo maxLine={1}>{label}</Typo>
                              </Tooltip>
                              {actualTypeFilters.length > 0 && inDropdown && (
                                <SizeContextProvider sizeVariant={SizeVariant.small}>
                                  <IconOnlyButton
                                    tooltip={i18n`Clear filters`}
                                    variant={IconOnlyButtonVariants.secondary}
                                    onClick={() => setTypeFilters([])}
                                    iconName={IconName.delete}
                                  />
                                </SizeContextProvider>
                              )}
                            </div>
                          );
                        },
                      },
                    ]}
                    getDropdownSectionDefinitions={() => [{
                      id: 'instances',
                      lines: [{
                        id: 'values',
                        render: (
                          <div className={classes.toggleContainer}>
                            <TagContainer>
                              {
                                existingFieldDefinitionIds
                                  .map((fieldDefinitionId) => {
                                    const fieldDefinitionHandler = getFieldDefinitionHandler(store, fieldDefinitionId);
                                    return { fieldDefinitionId, label: fieldDefinitionHandler.getTypeLabel(), icon: fieldDefinitionHandler.typeIcon };
                                  })
                                  .sort(compareProperty('label', compareString))
                                  .map(({ fieldDefinitionId, label, icon }) => (
                                    <Toggle
                                      key={fieldDefinitionId}
                                      text={label}
                                      icon={icon}
                                      onClick={() => {
                                        setTypeFilters((current = []) => (
                                          current.includes(fieldDefinitionId) ? current.filter((id) => id !== fieldDefinitionId) : [...current, fieldDefinitionId]
                                        ));
                                      }}
                                      isActive={actualTypeFilters.includes(fieldDefinitionId)}
                                    />
                                  ))
                              }
                            </TagContainer>
                          </div>
                        ),
                      }],
                    }]}
                    onOpenDropdown={() => {}}
                    onCloseDropdown={() => {}}
                  />
                </span>
              </SizeContextProvider>
              <ToggleButton
                name={i18n`Multidimensional`}
                icon={IconName.pivot_table_chart}
                onClick={() => setShowMultidimensional((current) => !current)}
                active={showMultidimensional}
              />
              <ToggleButton
                name={i18n`Computed`}
                icon={IconName.fx}
                onClick={() => setShowComputed((current) => !current)}
                active={showComputed}
              />
            </div>
          </BlockContent>
        ) : null}
        {showDeveloperMode ? (
          <HorizontalBlock asBlockContent>
            <BlockTitle title={i18n`Concept API alias`} />
            <BlockContent>
              <StoreTextInputField
                placeholder={i18n`Add text`}
                initialValue={conceptDefinition[ConceptDefinition_ApiAlias]}
                onSubmit={(newApiAlias) => store.updateObject(conceptDefinition.id, { [ConceptDefinition_ApiAlias]: newApiAlias })}
                acceptChars={API_LABEL_ACCEPTED_CHARS_REGEX}
                acceptCharsErrorMessage={i18n`API alias should only contain letters, numbers, '-' or '_'`}
                error={
                  conceptDefinition[ConceptDefinition_ApiAlias]
                  && conceptDefinition[ConceptDefinition_ApiAlias] !== ''
                  && store.getObject(ConceptDefinition)
                    .navigateBack(Class_Instances)
                    .filter(({ id }) => id !== conceptDefinitionId)
                    .some(({ [ConceptDefinition_ApiAlias]: apiAlias }) => apiAlias === conceptDefinition[ConceptDefinition_ApiAlias]) ? i18n`Another concept already use this alias. They won't be available in the API.` : undefined
                }
                action={
                  suggestedConceptDefinitionApiAlias !== conceptDefinition[ConceptDefinition_ApiAlias]
                    ? {
                      icon: IconName.sync,
                      tooltip: i18n`Reset to default`,
                      onClick: () => {
                        store.updateObject(conceptDefinitionId, { [ConceptDefinition_ApiAlias]: suggestedConceptDefinitionApiAlias });
                      },
                    }
                    : undefined
                }
              />
            </BlockContent>
          </HorizontalBlock>
        ) : null}
        <DataTable
          list={list}
          pagination={pagination}
          doSort={doSort}
          sortCriteria={sortCriteria}
          getNavigationPayload={({ field: { id } }) => getNavigationPayload(navigation, id, `/settings/organization/${conceptDefinitionId}/field/${id}`)}
          columnsDefinition={columnDefinitions}
          linesActions={({ field }) => {
            const { duplicateFieldDefinition } = getFieldConfigurationHandler(store, field.id);
            const actions: MenuItem[] = [
              {
                key: 'share',
                icon: IconName.link,
                name: i18n`Copy link`,
                onClick: () => share(store, getNavigationPayload(navigation, field.id, `/settings/organization/${conceptDefinitionId}/field/${field.id}`)),
              },
            ];

            if (!field[Field_IsCore]) {
              if (duplicateFieldDefinition) {
                actions.push({
                  key: 'duplicate',
                  name: i18n`Duplicate`,
                  icon: IconName.content_copy_outline,
                  onClick: () => {
                    const newId = duplicateFieldDefinition({ parameterMap: {} });
                    forceFollowingIds(field.id, newId);
                  },
                });
              }

              actions.push({
                key: 'delete',
                name: i18n`Delete`,
                icon: IconName.delete,
                onClick: () => doDelete(field.id),
                danger: true,
              });
            }

            return actions;
          }}
          newItemTitle={i18n`Create`}
          newItemIcon={IconName.add}
          onNewItem={!showLine ? () => {
            setShowLine(true);
          } : undefined}
          handleClickAway={(isEscape) => {
            if (isEscape) {
              setShowLine(false);
            }
          }}
          inlineCreation={{
            render: showLine ? (
              <NewFieldLine
                key={`newLineField${conceptDefinitionId}`}
                modelTypeId={conceptDefinitionId}
                showApiAliases={showDeveloperMode}
                onCreate={(fieldId) => {
                  setShowLine(false);
                  forceShowId(fieldId);
                }}
              />
            ) : null,
          }}
        />
      </VerticalBlock>
      {deleteModal}
    </>
  );
};

export default FieldsTab;
