import type { Property } from 'csstype';

export enum Spacing {
  none = 'none',
  xxs = 'xxs',
  xs = 'xs',
  text = 'text',
  s = 's',
  splus = 'splus',
  splusplus = 'splusplus',
  m = 'm',
  l = 'l',
  xl = 'xl',
  xxl = 'xxl',
  xxxl = 'xxxl',
  x4l = 'x4l',
  x5l = 'x5l',
  iconSize = 'iconSize',
  pageSpacing = 'pageSpacing',
  blockSpacing = 'blockSpacing',
  blockLeftColumnSpacing = 'blockLeftColumnSpacing',
  blockRightColumnSpacing = 'blockRightColumnSpacing',
  modalSpacing = 'modalSpacing',
}

const spacingsDefinition = {
  [Spacing.none]: 0,
  [Spacing.xxs]: 0.2,
  [Spacing.xs]: 0.4,
  [Spacing.text]: 0.6,
  [Spacing.s]: 0.8,
  [Spacing.splus]: 1.2,
  [Spacing.splusplus]: 1.4,
  [Spacing.m]: 1.6,
  [Spacing.l]: 2,
  [Spacing.xl]: 2.4,
  [Spacing.xxl]: 3.2,
  [Spacing.xxxl]: 4,
  [Spacing.x4l]: 5.4,
  [Spacing.x5l]: 8,
  [Spacing.iconSize]: 2.2,
  [Spacing.pageSpacing]: 3.5,
  [Spacing.blockSpacing]: 1.4,
  [Spacing.blockLeftColumnSpacing]: 2.6,
  [Spacing.blockRightColumnSpacing]: 6.2,
  [Spacing.modalSpacing]: 4.6,
} as const satisfies Record<Spacing, number>;

type SpacingsDefinition = typeof spacingsDefinition;
type SpacingRem = {
  readonly [key in keyof SpacingsDefinition]: `${SpacingsDefinition[key]}rem`
};

export const spacingRem: SpacingRem = Object.fromEntries(Object.entries(spacingsDefinition).map(([key, value]) => [key, `${value}rem`])) as SpacingRem;

export type SpacingDefinitionProp = Spacing | { left?: Spacing, right?: Spacing, top?: Spacing, bottom?: Spacing, x?: Spacing, y?: Spacing };

export const getSpacing = (spacing: Spacing, ...spacings: (Spacing | number)[]): string => (
  `${(spacingsDefinition[spacing] + spacings.reduce<number>((sum, space) => sum + (typeof space === 'number' ? space : spacingsDefinition[space]), 0)).toFixed(1)}rem`
);
export const getSpacingAsNumber = (spacing: Spacing): number => spacingsDefinition[spacing];

type Margins =
  { margin: Property.Margin }
  | { marginLeft?: Property.MarginLeft, marginRight?: Property.MarginRight, marginTop?: Property.MarginTop, marginBottom?: Property.MarginBottom }
  | Record<string, never>;

const computeSpacing = (direction: Spacing | undefined, axis: Spacing | undefined): string | undefined => {
  if (direction) {
    return getSpacing(direction);
  } else if (axis) {
    return getSpacing(axis);
  } else {
    return undefined;
  }
};

export const buildMargins = (marginDefinition: SpacingDefinitionProp | undefined): Margins => {
  if (typeof marginDefinition === 'string') {
    return { margin: getSpacing(marginDefinition) };
  } else if (typeof marginDefinition === 'object') {
    return {
      marginLeft: computeSpacing(marginDefinition.left, marginDefinition.x),
      marginRight: computeSpacing(marginDefinition.right, marginDefinition.x),
      marginTop: computeSpacing(marginDefinition.top, marginDefinition.y),
      marginBottom: computeSpacing(marginDefinition.bottom, marginDefinition.y),
    };
  } else {
    return {};
  }
};

type Paddings =
  { padding: Property.Padding }
  | { paddingLeft?: Property.PaddingLeft, paddingRight?: Property.PaddingRight, paddingTop?: Property.PaddingTop, paddingBottom?: Property.PaddingBottom }
  | Record<string, never>;

export const buildPadding = (paddingDefinition: SpacingDefinitionProp): Paddings => {
  if (typeof paddingDefinition === 'string') {
    return { padding: getSpacing(paddingDefinition) };
  } else if (typeof paddingDefinition === 'object') {
    return {
      paddingLeft: computeSpacing(paddingDefinition.left, paddingDefinition.x),
      paddingRight: computeSpacing(paddingDefinition.right, paddingDefinition.x),
      paddingTop: computeSpacing(paddingDefinition.top, paddingDefinition.y),
      paddingBottom: computeSpacing(paddingDefinition.bottom, paddingDefinition.y),
    };
  } else {
    return {};
  }
};
