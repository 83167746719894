import { isMultiplePath } from 'yooi-modules/modules/conceptModule';
import type { TimeseriesTableViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import { DimensionDisplayAxis, ViewType } from 'yooi-modules/modules/dashboardModule';
import { joinObjects, PeriodicityType } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import i18n from '../../../../utils/i18n';
import type { CommonViewResolvedDefinition } from '../common/commonViewResolvedDefinition';
import type { DimensionsDisplayOptionsFeatureResolvedDefinition } from '../common/dimensions/dimensionDisplayOptionsFeatureDefinition';
import { getViewDefinitionDimensionDisplayOption } from '../common/dimensions/viewWithDimensionDisplayOptionsFeatureUtils';
import type { SeriesFeatureResolvedDefinition } from '../common/series/seriesFeatureDefinition';
import { getSeriesError, getViewDefinitionSeries, getViewDimensionsAsParameterDefinitions } from '../common/series/viewWithSeriesFeatureUtils';
import type { TemporalFeatureResolvedDefinition } from '../common/temporal/temporalFeatureResolvedDefinition';
import type { ViewDefinitionHandler } from '../viewDsl';
import { registerView } from '../viewDsl';
import type { TimeseriesTableDimensionDisplayOption } from './timeseriesTableUtils';
import { getTimeseriesTableViewDefinitionDimensionsDisplayOptions } from './timeseriesTableUtils';
import TimeseriesTableViewBlock from './TimeseriesTableViewBlock';
import TimeseriesTableViewDefinitionOptions, { getProjectionErrorForAxis } from './TimeseriesTableViewDefinitionOptions';
import type { TimeseriesTableViewResolution } from './timeseriesTableViewResolution';
import { resolveTimeseriesTableView } from './timeseriesTableViewResolution';
import TimeseriesTableViewWidget from './TimeseriesTableViewWidget';

export interface TimeseriesTableViewResolvedDefinition extends CommonViewResolvedDefinition,
  SeriesFeatureResolvedDefinition,
  TemporalFeatureResolvedDefinition,
  DimensionsDisplayOptionsFeatureResolvedDefinition<TimeseriesTableDimensionDisplayOption> {
  type: ViewType.TimeseriesTable,

  granularity: { displayEmpty: boolean, strict: boolean, periodicity: PeriodicityType },
  timeAxis: { axis: DimensionDisplayAxis },
  seriesAxis: { axis: DimensionDisplayAxis, display: 'show' | 'hide' },
  numberOfItems?: number,
}

export type TimeseriesTableViewDefinitionHandler = ViewDefinitionHandler<TimeseriesTableViewStoredDefinition, TimeseriesTableViewResolvedDefinition, TimeseriesTableViewResolution>;
export const timeseriesTableViewHandler = registerView<TimeseriesTableViewStoredDefinition, TimeseriesTableViewResolvedDefinition, TimeseriesTableViewResolution>({
  type: ViewType.TimeseriesTable,
  getLabel: () => i18n`Timeseries table`,
  icon: IconName.table_chart,
  optionType: undefined,
  withFilters: true,
  withExport: false,
  resolveView: (store, viewDefinition, { viewDimensions, parametersMapping, addedTimePoints, filterConfiguration }) => (
    resolveTimeseriesTableView(store, viewDimensions, viewDefinition, parametersMapping, filterConfiguration, addedTimePoints ?? [])
  ),
  renderBlock: (_, viewDefinition, { layoutParametersMapping, viewDimensions, viewFilters, readOnly }) => (
    <TimeseriesTableViewBlock
      parametersMapping={layoutParametersMapping}
      viewDimensions={viewDimensions}
      viewDefinition={viewDefinition}
      filterKey={viewFilters.filterKey}
      readOnly={readOnly}
    />
  ),
  renderWidget: (_, viewDefinition, { viewDimensions, parametersMapping, readOnly, height, width, widgetId, viewFilters }) => (
    <TimeseriesTableViewWidget
      widgetId={widgetId}
      parametersMapping={parametersMapping}
      viewDimensions={viewDimensions}
      viewDefinition={viewDefinition}
      height={height}
      width={width}
      readOnly={readOnly}
      filterKey={viewFilters.filterKey}
    />
  ),
  renderDefinitionOptions: (_, viewDefinition, { viewDimensions, updateViewDefinition, readOnly, parameterDefinitions, isWidget }) => (
    <TimeseriesTableViewDefinitionOptions
      viewDimensions={viewDimensions}
      viewDefinition={viewDefinition}
      updateViewDefinition={updateViewDefinition}
      readOnly={readOnly}
      parameterDefinitions={parameterDefinitions}
      isWidget={isWidget}
    />
  ),
  getDefinitionErrors: (store, viewDefinition, viewDimensions, parameters) => {
    const errors: string[] = [];
    const seriesError = getSeriesError(store, viewDefinition, [...parameters, ...getViewDimensionsAsParameterDefinitions(store, viewDimensions)], viewDefinition.type);
    if (seriesError) {
      errors.push(seriesError);
    }
    const lineProjectionError = getProjectionErrorForAxis(viewDefinition, viewDimensions, DimensionDisplayAxis.y);
    if (lineProjectionError) {
      errors.push(lineProjectionError);
    }
    const columnProjectionError = getProjectionErrorForAxis(viewDefinition, viewDimensions, DimensionDisplayAxis.x);
    if (columnProjectionError) {
      errors.push(columnProjectionError);
    }
    return errors.length > 0 ? errors : undefined;
  },
  resolveDefinition: (store, viewDefinition) => ({
    id: viewDefinition.id,
    type: viewDefinition.type,
    label: viewDefinition.label !== undefined && viewDefinition.label !== '' ? viewDefinition.label : i18n`Timeseries table`,
    granularity: viewDefinition.granularity ?? { displayEmpty: false, strict: true, periodicity: PeriodicityType.year },
    series: getViewDefinitionSeries(viewDefinition),
    getDimensionsDisplay: (displayedViewDimensions) => getTimeseriesTableViewDefinitionDimensionsDisplayOptions(store, viewDefinition, displayedViewDimensions),
    getDimensionDisplay: (displayedViewDimension) => {
      const dimensionDisplayOption = getViewDefinitionDimensionDisplayOption(
        viewDefinition,
        displayedViewDimension,
        viewDefinition.timeAxis?.axis === DimensionDisplayAxis.x ? DimensionDisplayAxis.y : DimensionDisplayAxis.x
      );
      const dimensionDisplay = (viewDefinition.dimensionsDisplay ?? []).find(({ id }) => dimensionDisplayOption.id === id);
      const isDimensionMultiple = isMultiplePath(store, displayedViewDimension.path);
      return joinObjects(getViewDefinitionDimensionDisplayOption(
        viewDefinition,
        displayedViewDimension,
        viewDefinition.timeAxis?.axis === DimensionDisplayAxis.x ? DimensionDisplayAxis.y : DimensionDisplayAxis.x
      ), { display: isDimensionMultiple ? 'show' : dimensionDisplay?.display ?? 'hide' });
    },
    dateRange: viewDefinition.dateRange,
    readOnly: false,
    timeAxis: { axis: viewDefinition.timeAxis?.axis ?? DimensionDisplayAxis.y },
    seriesAxis: {
      axis: viewDefinition.seriesAxis?.axis ?? DimensionDisplayAxis.x,
      display: (viewDefinition.series ?? []).length > 1 ? 'show' : (viewDefinition.seriesAxis?.display ?? 'hide'),
    },
    numberOfItems: viewDefinition.numberOfItems,
  }),
  getInitialStoredDefinition: (id) => ({
    type: ViewType.TimeseriesTable,
    id,
    granularity: { displayEmpty: false, strict: true, periodicity: PeriodicityType.year },
    timeAxis: { axis: DimensionDisplayAxis.y },
    seriesAxis: { axis: DimensionDisplayAxis.x },
  }),
});
