export const sessionStoragePrefix = 'yooi::';

export const safeSessionStorageValue = <T = unknown>(key: string): T | undefined => {
  try {
    const item = sessionStorage.getItem(`${sessionStoragePrefix}${key}`);
    if (item !== null) {
      return JSON.parse(item);
    }
  } catch (_) {
    // Failed to parse the value ? then drop it
    sessionStorage.removeItem(`${sessionStoragePrefix}${key}`);
  }
  return undefined;
};
