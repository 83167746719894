export interface CustomEventSource<T extends EventSourceEventMap> extends EventSource {
  addEventListener<K extends keyof T>(
    type: K,
    listener: (this: EventSource, ev: T[K]) => void,
    options?: boolean | AddEventListenerOptions,
  ): void,
}

export type NetworkClientResponse<T, U> = NetworkClientSuccess<T> | NetworkClientError<U>;

export interface NetworkClientError<T> {
  httpStatus?: number,
  answer?: T,
  error: Error,
}

interface NetworkClientSuccess<T> {
  httpStatus?: number,
  answer: T,
}

export const isNetworkCallSuccessful = <T, U>(res: NetworkClientResponse<T, U>): res is NetworkClientSuccess<T> => (res as NetworkClientError<U>).error === undefined;
