import type { ObjectStoreReadOnly, ObjectStoreWithTimeseries } from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import { joinObjects, newError, toSnakeCase } from 'yooi-utils';
import type { Field, FieldFilterConditions, FieldLocalOverrideStoreObject, FieldStoreObject } from '../../conceptModule';
import {
  Field as FieldId,
  Field_ApiAlias,
  Field_Configuration,
  Field_Documentation,
  Field_Formula,
  Field_IntegrationOnly,
  Field_IsCore,
  Field_IsDocumentationInline,
  Field_IsTitleHidden,
  Field_Title,
  FieldDefinition,
  FieldDefinition_Title,
  FieldLocalOverride,
  FieldLocalOverride_Role_Concept,
  FieldLocalOverride_Role_Field,
  FieldLocalOverrideProperty,
  FieldLocalOverrideProperty_OverrideProperty,
  FieldXConcept_Configuration,
} from '../../conceptModule/ids';
import { ParsedDimensionType, parseDimensionMapping } from '../../conceptModule/utils/parametersUtils';
import { isInstanceOf } from '../../typeModule';
import { Instance_Of } from '../../typeModule/ids';
import type { BusinessRuleRegistration, MandatoryProperty, ModuleDsl } from '../types/TypeModuleDslType';
import { asImport, CommonAsType } from './commonPropertyType';
import type {
  CreateHistoryEventProducer,
  DslConfigurationHandler,
  DslFieldHandler,
  FieldModuleDsl,
  FieldModuleDslRegistration,
  GenericGetDslFieldHandler,
  GetDslFieldHandler,
  GetFieldHandler,
  PropertyAs,
} from './FieldModuleDslType';
import { PropertyTypeMode } from './FieldModuleDslType';

interface FieldRegistrationFieldProperty {
  id: string,
  label: string,
  as: PropertyAs,
  mandatory?: MandatoryProperty,
}

interface FieldRegistrationField {
  id: string,
  label: string,
  properties: FieldRegistrationFieldProperty[],
}

export interface FieldRegistrationModule {
  id: string,
  label: string,
  fields: FieldRegistrationField[],
}

export const fieldModules: FieldRegistrationModule[] = [];
const fieldMap: Record<string, FieldRegistrationField> = {};
const fieldHandlers: Record<string, GenericGetDslFieldHandler | undefined> = {};
const historyEventProducers: Map<string, CreateHistoryEventProducer> = new Map();
const fieldDefinitionHasApiAlias: Map<string, boolean> = new Map();

const autoGenerateApiAlias: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  if (!properties) {
    // Deleting the field ? don't care
    return undefined;
  } else if (!properties[Field_Title]) {
    // Clearing or not updating the title ? don't care
    return undefined;
  } else if (properties[Field_ApiAlias] !== undefined) {
    // Producing an update on the ApiAlias ? don't care
    return undefined;
  } else if (getObjectOrNull(id)?.[Field_ApiAlias]) {
    // ApiAlias already has a value ? don't care
    return undefined;
  }

  return {
    rule: 'field.apiAlias.autogenerate',
    status: ValidationStatus.ACCEPTED,
    generateSystemEvent: ({ updateObject }) => {
      updateObject(id, { [Field_ApiAlias]: toSnakeCase(properties[Field_Title] as string | undefined ?? '') });
    },
  };
};

export const resolveConfigurationInternal = <Configuration extends Field>(objectStore: ObjectStoreReadOnly, fieldId: string): Configuration => {
  const field = objectStore.getObject<FieldStoreObject>(fieldId);
  const result: Field & Record<string, unknown> = {
    id: fieldId,
    fieldDefinitionId: field[Instance_Of],
    title: field[Field_Title],
    documentation: field[Field_Documentation],
    isTitleHidden: field[Field_IsTitleHidden],
    isDocumentationInline: field[Field_IsDocumentationInline],
    apiAlias: field[Field_ApiAlias],
    formula: field[Field_Formula],
    integrationOnly: field[Field_IntegrationOnly],
    isCore: field[Field_IsCore],
  };

  fieldMap[field[Instance_Of]].properties.forEach(({ id, label }) => {
    result[label.charAt(0).toLowerCase() + label.slice(1)] = field[id];
  });

  return result as Configuration;
};

const resolveConfiguration = <Configuration extends Field>(objectStore: ObjectStoreReadOnly, fieldId: string): Configuration => {
  if (objectStore.hasPropertyFunction(Field_Configuration)) {
    return objectStore.getObject(fieldId)[Field_Configuration] as Configuration;
  } else {
    return resolveConfigurationInternal<Configuration>(objectStore, fieldId);
  }
};

export const resolveConceptConfigurationInternal = <Configuration extends Field>(objectStore: ObjectStoreReadOnly, fieldId: string, conceptId: string): Configuration => {
  const fieldLocalOverride = objectStore.withAssociation(FieldLocalOverride)
    .withRole(FieldLocalOverride_Role_Concept, conceptId)
    .withRole(FieldLocalOverride_Role_Field, fieldId)
    .getObjectOrNull<FieldLocalOverrideStoreObject>();

  if (fieldLocalOverride === null) {
    return resolveConfiguration<Configuration>(objectStore, fieldId);
  } else {
    const field = objectStore.getObject<FieldStoreObject>(fieldId);
    const configuration = { ...resolveConfiguration<Configuration>(objectStore, fieldId) } as Record<string, unknown>;

    Object.entries(fieldLocalOverride.asRawObject())
      .forEach(([fieldLocalOverridePropertyId, overrideValue]) => {
        const initialPropertyId = objectStore.getObjectOrNull(fieldLocalOverridePropertyId)?.navigateOrNull(FieldLocalOverrideProperty_OverrideProperty)?.id;
        if (!initialPropertyId) {
          return;
        }

        const prop = fieldMap[field[Instance_Of]].properties.find(({ id }) => id === initialPropertyId);
        if (!prop) {
          return;
        }

        configuration[prop.label.charAt(0).toLowerCase() + prop.label.slice(1)] = overrideValue;
      });

    return configuration as Configuration;
  }
};

const resolveConceptConfiguration = <Configuration extends Field>(objectStore: ObjectStoreReadOnly, fieldId: string, conceptId: string): Configuration => {
  if (objectStore.hasPropertyFunction(FieldXConcept_Configuration)) {
    return objectStore.getObject([fieldId, conceptId], true)[FieldXConcept_Configuration] as Configuration;
  } else {
    return resolveConceptConfigurationInternal(objectStore, fieldId, conceptId);
  }
};

export const areColumnHeadersInError = <ExportConfiguration extends object>(
  columnHeader: ExportConfiguration | { error: string } | undefined
): columnHeader is { error: string } => (
    (columnHeader as { error: string } | undefined)?.error !== undefined
  );

export const createFieldDsl = (moduleDsl: ModuleDsl): FieldModuleDsl => {
  const { type, property, relation, getId } = moduleDsl;

  const module: FieldRegistrationModule = { id: moduleDsl.id, label: moduleDsl.label, fields: [] };
  fieldModules.push(module);

  const moduleFolder = `${moduleDsl.label.charAt(0).toLowerCase()}${moduleDsl.label.slice(1)}Module`;

  return {
    registerField: <
      Configuration extends Field,
      StoreValue,
      UpdateValue,
      WithoutFormulaValue,
      ResolvedValue,
      RestValue,
      FilterConditions extends FieldFilterConditions<ResolvedValue> | undefined,
      UpdateOperationTypes,
      PathStepConfiguration,
      ExportConfiguration
    >(
      registration: FieldModuleDslRegistration<
        Configuration,
        StoreValue,
        UpdateValue,
        WithoutFormulaValue,
        ResolvedValue,
        RestValue,
        FilterConditions,
        UpdateOperationTypes,
        PathStepConfiguration,
        ExportConfiguration
      >
    ): GetDslFieldHandler<
      Configuration,
      StoreValue,
      UpdateValue,
      WithoutFormulaValue,
      ResolvedValue,
      RestValue,
      FilterConditions,
      UpdateOperationTypes,
      PathStepConfiguration,
      ExportConfiguration
    > => {
      const {
        label: fieldLabel,
        title,
        extraModel,
        businessRules: fieldBusinessRules,
        asPropertyBusinessRules = [],
        withApiAlias = false,
        properties = [],
        relations = [],
      } = registration.model;
      const fieldDefinitionId = getId(fieldLabel);
      fieldDefinitionHasApiAlias.set(fieldDefinitionId, withApiAlias);

      const fieldType: FieldRegistrationField = { id: fieldDefinitionId, label: fieldLabel, properties: [] };
      module.fields.push(fieldType);
      fieldMap[fieldDefinitionId] = fieldType;

      const typeDsl = type({
        label: fieldLabel,
        extends: FieldId,
        instanceOf: FieldDefinition,
        extraProperties: { [FieldDefinition_Title]: title },
        businessRules: withApiAlias ? [...fieldBusinessRules ?? [], autoGenerateApiAlias] : fieldBusinessRules,
        dynamicBusinessRules: asPropertyBusinessRules.map(
          (handler) => ({ onProperty }, objectStore) => ([propertyId]) => onProperty(propertyId).validate(handler(objectStore, propertyId))
        ),
      });
      extraModel?.(moduleDsl);

      properties.forEach(({
        label: propertyLabel,
        businessRules: propertyBusinessRules,
        initialStateValidationHandler,
        as: propertyAs,
        mandatory,
        supportLocalOverride = false,
      }) => {
        const constantLabel = `${fieldLabel}_${propertyLabel}`;
        const propertyId = getId(constantLabel);

        const typeDslAs = propertyAs.type.mode === PropertyTypeMode.Local
          ? asImport(propertyAs.type.name, `modules/${moduleFolder}/fields/${fieldLabel.charAt(0).toLowerCase()}${fieldLabel.slice(1)}`, propertyAs.isArray)
          : propertyAs;

        typeDsl.property({
          label: constantLabel,
          as: typeDslAs,
          businessRules: propertyBusinessRules,
          initialStateValidationHandler,
          mandatory,
        });

        if (supportLocalOverride) {
          property({
            typeId: FieldLocalOverride,
            label: `FieldLocalOverride_${fieldLabel}${propertyLabel}`,
            as: typeDslAs,
            extraProperties: {
              [Instance_Of]: FieldLocalOverrideProperty,
              [FieldLocalOverrideProperty_OverrideProperty]: propertyId,
            },
          });
        }

        fieldType.properties.push({
          id: propertyId,
          label: propertyLabel,
          as: propertyAs,
          mandatory,
        });
      });

      relations.forEach(({ label: relationLabel, reverseLabel, targetTypeId, mandatory, supportLocalOverride = false, businessRules: relationBusinessRules }) => {
        const constantLabel = `${fieldLabel}_${relationLabel}`;
        const propertyId = getId(constantLabel);

        typeDsl.relation({
          label: constantLabel,
          reverseLabel,
          targetTypeId,
          businessRules: relationBusinessRules,
          mandatory,
        });

        if (supportLocalOverride) {
          relation({
            typeId: FieldLocalOverride,
            label: `FieldLocalOverride_${fieldLabel}${relationLabel}`,
            reverseLabel: `${reverseLabel.split('_')[0]}_FieldLocalOverride${fieldLabel}${relationLabel}`,
            targetTypeId,
            extraProperties: {
              [Instance_Of]: FieldLocalOverrideProperty,
              [FieldLocalOverrideProperty_OverrideProperty]: propertyId,
            },
          });
        }

        fieldType.properties.push({ id: propertyId, label: `${relationLabel}Id`, as: CommonAsType.string, mandatory });
      });

      const handler = (objectStore: ObjectStoreWithTimeseries, fieldId: string) => {
        const field = objectStore.getObject(fieldId);

        if (field[Instance_Of] !== fieldDefinitionId) {
          throw newError('Field type mismatch', { fieldInstanceOf: field[Instance_Of], expectedFieldDefinitionId: fieldDefinitionId });
        }

        const dslConfigurationHandler: DslConfigurationHandler<Configuration> = {
          withApiAlias,
          resolveConfiguration: () => resolveConfiguration<Configuration>(objectStore, fieldId),
          resolveConfigurationWithOverride: (dimensionsMapping) => {
            const parsedDimension = parseDimensionMapping(dimensionsMapping);
            if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
              return resolveConceptConfiguration<Configuration>(objectStore, fieldId, parsedDimension.objectId);
            } else {
              return dslConfigurationHandler.resolveConfiguration();
            }
          },
        };

        const registrationHandler = registration.handler(objectStore, fieldId, dslConfigurationHandler);

        return joinObjects(registrationHandler, dslConfigurationHandler) as unknown as DslConfigurationHandler<Configuration> & DslFieldHandler<
          StoreValue, UpdateValue, WithoutFormulaValue, ResolvedValue, RestValue, FilterConditions, UpdateOperationTypes, PathStepConfiguration, ExportConfiguration
        >;
      };

      handler.fieldDefinitionId = fieldDefinitionId;
      fieldHandlers[fieldDefinitionId] = handler as GenericGetDslFieldHandler;

      if (registration.historyEventProducer) {
        historyEventProducers.set(fieldDefinitionId, registration.historyEventProducer);
      }

      return handler;
    },
  };
};

type GetDslFieldHandlerFunc = GetFieldHandler<Field, unknown, unknown, unknown, unknown, unknown, FieldFilterConditions<unknown> | undefined, object | undefined, object, unknown>;
export const getDslFieldHandler: GetDslFieldHandlerFunc = (objectStore, fieldId) => {
  const field = objectStore.getObject(fieldId);
  const fieldTypeId = field[Instance_Of] as string;
  const handler = fieldHandlers[fieldTypeId];
  if (!handler) {
    throw newError('No handler available for field type', { fieldTypeId });
  }
  return handler(objectStore, fieldId);
};

export const getOptionalHistoryProducer = (objectStore: ObjectStoreReadOnly, fieldId: string): ReturnType<CreateHistoryEventProducer> | undefined => {
  const field = objectStore.getObjectOrNull(fieldId);
  if (!field) {
    throw newError('getOptionalHistoryProducer: Field does not exist', { fieldId });
  } else if (!isInstanceOf(field, FieldId)) {
    throw newError('getOptionalHistoryProducer: Provided instance is not a field', { fieldId });
  }

  return historyEventProducers.get(field[Instance_Of])?.(objectStore, fieldId);
};

export const hasApiAlias = (fieldDefinitionId: string): boolean => fieldDefinitionHasApiAlias.get(fieldDefinitionId) ?? false;
