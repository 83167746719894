import type {
  AssociationFilterStoreObject,
  ConceptStoreObject,
  ConditionFilterStoreObject,
  ParametersMapping,
  PathStep,
  SingleParameterDefinition,
  ViewFilterStoreObject,
} from 'yooi-modules/modules/conceptModule';
import {
  FILTER_PARAMETER_CURRENT,
  FILTER_PARAMETER_LOGGED_USER,
  FilterValueType,
  getFilterFunction,
  getInstanceLabel,
  InstanceReferenceType,
  isFieldStep,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import {
  AssociationField,
  AssociationFilter,
  AssociationFilter_Filters,
  AssociationFilter_LibraryDefaultValueFilters,
  AssociationFilter_Path,
  ConditionFilter,
  ConditionFilter_ActivateByDefault,
  ConditionFilter_Filters,
  RelationMultipleField,
  RelationSingleField,
  StakeholdersField,
  ViewFilter_Label,
  ViewFilter_Rank,
  Workflow_TargetedConceptDefinition,
  WorkflowEntry,
  WorkflowEntry_Rank,
  WorkflowEntry_Role_Concept,
  WorkflowEntry_Role_Workflow,
  WorkflowField,
  WorkflowField_Workflow,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { compareProperty, compareRank, compareString, comparing, extractAndCompareValue, filterNullOrUndefined, joinObjects, pushUndefinedToEnd } from 'yooi-utils';
import type { FrontObjectStore } from '../../../store/useStore';
import { getConceptDefinitionNameOrEntity } from '../modelTypeUtils';
import { createPathConfigurationHandler } from '../pathConfigurationHandler';
import type { FilterConfiguration } from './useFilterSessionStorage';

export const getQuickFiltersTargetedConceptDefinitionId = (
  store: ObjectStoreWithTimeseries,
  path: PathStep[],
  parameterDefinitions: SingleParameterDefinition[]
): string | undefined => {
  if (path.length > 0) {
    const lastStep = path[path.length - 1];
    if (isFieldStep(lastStep) && isInstanceOf(store.getObjectOrNull(lastStep.fieldId), WorkflowField)) {
      return store.getObject(lastStep.fieldId).navigateOrNull(WorkflowField_Workflow)?.navigateOrNull(Workflow_TargetedConceptDefinition)?.id;
    } else {
      const pathConfigurationHandler = createPathConfigurationHandler(store, parameterDefinitions);
      return pathConfigurationHandler.getReturnedConceptDefinitionId(path);
    }
  }
  return undefined;
};

export const isAssociationFilter = (viewFilter: ViewFilterStoreObject): viewFilter is AssociationFilterStoreObject => viewFilter[Instance_Of] === AssociationFilter;
export const isConditionFilter = (viewFilter: ViewFilterStoreObject): viewFilter is ConditionFilterStoreObject => viewFilter[Instance_Of] === ConditionFilter;

export const getQuickFilterLabel = (store: ObjectStoreWithTimeseries, quickFilterId: string, parameterDefinitions: SingleParameterDefinition[]): string | undefined => {
  const quickFilter = store.getObject<ViewFilterStoreObject>(quickFilterId);
  if (quickFilter[ViewFilter_Label] && quickFilter[ViewFilter_Label].length > 0) {
    return quickFilter[ViewFilter_Label];
  } else {
    if (isAssociationFilter(quickFilter)) {
      const returnedConceptDefinitionId = getQuickFiltersTargetedConceptDefinitionId(store, quickFilter[AssociationFilter_Path], parameterDefinitions);
      if (returnedConceptDefinitionId) {
        return getConceptDefinitionNameOrEntity(store, returnedConceptDefinitionId);
      }
    }
    return undefined;
  }
};

export const getQuickFilterInstances = (
  store: FrontObjectStore,
  quickFilter: AssociationFilterStoreObject,
  parameterDefinitions: SingleParameterDefinition[],
  userId: string,
  getPrefilteredInstances?: (conceptDefinitionIdToFilter: string, quickFilter: AssociationFilterStoreObject) => string[] | undefined,
  parametersMapping?: ParametersMapping
): ConceptStoreObject[] => {
  const path = quickFilter[AssociationFilter_Path];
  const filters = quickFilter[AssociationFilter_Filters];
  const lastPathItem = path[path.length - 1];
  const filterFunction = getFilterFunction(store, filters) ?? (() => true);

  if (lastPathItem && lastPathItem.type === PathStepType.field && isInstanceOf(store.getObjectOrNull(lastPathItem.fieldId), WorkflowField)) {
    const workflowId = store.getObject(lastPathItem.fieldId).navigateOrNull(WorkflowField_Workflow)?.id;
    if (workflowId) {
      return store.withAssociation(WorkflowEntry)
        .withRole(WorkflowEntry_Role_Workflow, workflowId)
        .list()
        .sort(compareProperty('object', compareProperty(WorkflowEntry_Rank, compareRank)))
        .map((workflowEntry) => workflowEntry.navigateRole<ConceptStoreObject>(WorkflowEntry_Role_Concept))
        .filter((concept) => filterFunction(joinObjects(
          parametersMapping ?? {},
          {
            [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: concept.id },
            [FILTER_PARAMETER_LOGGED_USER]: { type: 'single' as const, id: userId },
          }
        )));
    } else {
      return [];
    }
  } else {
    const targetedConceptDefinitionId = getQuickFiltersTargetedConceptDefinitionId(store, path, parameterDefinitions);
    if (!targetedConceptDefinitionId) {
      return [];
    }
    const targetConceptDefinition = store.getObjectOrNull(targetedConceptDefinitionId);
    if (!targetConceptDefinition) {
      return [];
    }

    let instances = targetConceptDefinition
      .navigateBack<ConceptStoreObject>(Class_Instances)
      .filter((concept) => filterFunction(joinObjects(
        parametersMapping ?? {},
        {
          [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: concept.id },
          [FILTER_PARAMETER_LOGGED_USER]: { type: 'single' as const, id: userId },
        }
      )));

    if (getPrefilteredInstances && quickFilter) {
      const prefilteredInstances = new Set(getPrefilteredInstances(targetConceptDefinition.id, quickFilter));
      instances = prefilteredInstances.size > 0 ? instances.filter((instance) => prefilteredInstances.has(instance.id)) : instances;
    }

    return instances.sort(extractAndCompareValue((concept) => getInstanceLabel(store, concept), comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString)));
  }
};

const getLastFieldId = (path: PathStep[]) => {
  for (let i = path.length - 1; i >= 0; i -= 1) {
    const step = path[i];
    if (isFieldStep(step)) {
      return step.fieldId;
    }
  }
  return undefined;
};

export const getQuickFilterOperator = (store: FrontObjectStore, path: PathStep[]): string | undefined => {
  const lastFieldId = getLastFieldId(path);
  if (lastFieldId) {
    const lastField = store.getObjectOrNull(lastFieldId);
    if (isInstanceOf(lastField, RelationSingleField) || isInstanceOf(lastField, WorkflowField)) {
      return 'IN';
    } else if (isInstanceOf(lastField, RelationMultipleField) || isInstanceOf(lastField, AssociationField) || isInstanceOf(lastField, StakeholdersField)) {
      return 'CONTAINS_SOME';
    }
  } else if (path.length === 2 && path[1].type === PathStepType.mapping) {
    return 'IN';
  }
  return undefined;
};

export const getDefaultFilterConfiguration = (
  store: FrontObjectStore,
  userId: string,
  getViewFilters: () => (AssociationFilterStoreObject | ConditionFilterStoreObject)[],
  parameterDefinitions: SingleParameterDefinition[]
): FilterConfiguration | undefined => {
  const quickFilters = getViewFilters()
    .filter((quickFilter) => (isAssociationFilter(quickFilter) && quickFilter[AssociationFilter_LibraryDefaultValueFilters])
      || (isConditionFilter(quickFilter) && quickFilter[ConditionFilter_ActivateByDefault]))
    .sort(compareProperty(ViewFilter_Rank, compareRank));

  const defaultFilters = quickFilters.map((quickFilter) => {
    if (isConditionFilter(quickFilter)) {
      return [quickFilter.id, quickFilter[ConditionFilter_Filters]];
    }
    const quickFilterTargetConceptDefinitionId = getQuickFiltersTargetedConceptDefinitionId(store, quickFilter[AssociationFilter_Path], parameterDefinitions);
    if (!quickFilterTargetConceptDefinitionId) {
      return undefined;
    }
    const targetConceptDefinition = store.getObjectOrNull(quickFilterTargetConceptDefinitionId);
    if (!targetConceptDefinition) {
      return undefined;
    }
    const valueFilterFunction = getFilterFunction(store, quickFilter[AssociationFilter_LibraryDefaultValueFilters]);
    if (valueFilterFunction) {
      const operator = getQuickFilterOperator(store, quickFilter[AssociationFilter_Path]);
      if (!operator) {
        return undefined;
      }

      const instances = getQuickFilterInstances(store, quickFilter, parameterDefinitions, userId);

      const ids = instances
        .filter((instance) => valueFilterFunction({
          [FILTER_PARAMETER_CURRENT]: { type: 'single', id: instance.id },
          [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: userId },
        }))
        .map(({ id }) => id);

      if (ids.length > 0) {
        return [quickFilter.id, {
          id: quickFilter.id,
          leftValue: quickFilter[AssociationFilter_Path],
          operator,
          rightValue: {
            type: FilterValueType.raw,
            raw: ids.map((id) => ({ id, type: InstanceReferenceType.instance })),
          },
        }];
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }).filter(filterNullOrUndefined);

  return { filters: Object.fromEntries(defaultFilters) };
};
