import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import type { CollaborationMessageStoreObject, CollaborationStoreObject, IntentionStoreObject } from 'yooi-modules/modules/collaborationModule';
import { buildCollaborationLocation, isValidCollaborationPath } from 'yooi-modules/modules/collaborationModule';
import {
  Collaboration_CreatedAt,
  Collaboration_Intention,
  Collaboration_Messages,
  CollaborationMessage_CreatedAt,
  CollaborationMessage_CreatedBy,
  Intention_Workflow,
  IntentionStatusEntries,
  IntentionStatusEntries_Role_Entry,
  IntentionStatusEntries_Role_Intention,
  IntentionStatusIsClosedStatus,
} from 'yooi-modules/modules/collaborationModule/ids';
import { isStatusBelongingToWorkflow } from 'yooi-modules/modules/conceptModule';
import { compareNumber, compareProperty, filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import Typo from '../../../../../components/atoms/Typo';
import Chip from '../../../../../components/molecules/Chip';
import InlineLink from '../../../../../components/molecules/InlineLink';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import useStore from '../../../../../store/useStore';
import { spacingRem } from '../../../../../theme/spacingDefinition';
import { formatENDisplayDate } from '../../../../../utils/dateUtilsFront';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import useNavigation from '../../../../../utils/useNavigation';
import { SizeVariant } from '../../../../../utils/useSizeContext';
import useTheme from '../../../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../../../utils/useUsageContext';
import { getChipOptions, getUnknownChip } from '../../../modelTypeUtils';

const useStyles = makeStyles((theme) => ({
  line: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: spacingRem.s,
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '3.2rem',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.s,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.xs,
  },
  separator: {
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
  },
}), 'collaborationDetailPanelInfoTab');

interface CollaborationDetailPanelInfoTabProps {
  collaborationId: string,
}

const CollaborationDetailPanelInfoTab: FunctionComponent<CollaborationDetailPanelInfoTabProps> = ({ collaborationId }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const navigation = useNavigation();
  const location = useLocation();

  const collaboration = store.getObject<CollaborationStoreObject>(collaborationId);
  const collaborationCreatorId = collaboration.navigateBack<CollaborationMessageStoreObject>(Collaboration_Messages)
    .sort(compareProperty(CollaborationMessage_CreatedAt, compareNumber))
    .at(0)
    ?.[CollaborationMessage_CreatedBy];
  const creatorChip = (collaborationCreatorId === undefined ? undefined : getChipOptions(store, collaborationCreatorId)) ?? getUnknownChip(collaborationCreatorId ?? '');
  const creatorPayload = creatorChip.getNavigationPayload?.(navigation);

  const [isAboutCollapsed, setIsAboutCollapsed] = useState(false);
  const [isWorkflowCollapsed, setIsWorkflowCollapsed] = useState(false);

  const intention = collaboration[Collaboration_Intention] ? store.getObjectOrNull<IntentionStoreObject>(collaboration[Collaboration_Intention]) : null;
  const workflowId = intention ? intention[Intention_Workflow] : undefined;
  const workflowChip = (workflowId ? getChipOptions(store, workflowId) : undefined) ?? getUnknownChip(workflowId ?? 'unknown');
  const closureStatusChips = workflowId !== undefined && intention
    ? store.withAssociation(IntentionStatusEntries)
      .withRole(IntentionStatusEntries_Role_Intention, intention.id)
      .list()
      .filter((intentionClosedEntries) => (
        intentionClosedEntries.object?.[IntentionStatusIsClosedStatus]
        && isStatusBelongingToWorkflow(store, workflowId, intentionClosedEntries.role(IntentionStatusEntries_Role_Entry))
      ))
      .map((collaborationClosedEntries) => getChipOptions(store, collaborationClosedEntries.role(IntentionStatusEntries_Role_Entry)))
      .filter(filterNullOrUndefined)
    : [];

  const showUrl = isValidCollaborationPath(store, collaborationId);
  const historyPath = buildCollaborationLocation(store, collaborationId, undefined);
  const historyNavReplace = historyPath.pathname === location.pathname;
  const historyNavTo = historyNavReplace ? joinObjects(location, historyPath) : historyPath;

  return (
    <UsageContextProvider usageVariant={UsageVariant.inForm}>
      <div className={classes.block}>
        <div className={classes.line}>
          <Typo>{i18n`About`}</Typo>
          <IconOnlyButton
            tooltip={isAboutCollapsed ? i18n`Expand` : i18n`Collapse`}
            variant={IconOnlyButtonVariants.tertiary}
            iconName={isAboutCollapsed ? IconName.keyboard_arrow_left : IconName.expand_more}
            onClick={() => setIsAboutCollapsed((current) => !current)}
            sizeVariant={SizeVariant.small}
          />
        </div>
        {
          isAboutCollapsed
            ? null
            : (
              <div className={classes.content}>
                <div className={classes.line}>
                  <Typo color={theme.color.text.secondary}>{i18n`Created by`}</Typo>
                  <Chip
                    text={creatorChip.label}
                    tooltip={creatorChip.tooltip}
                    icon={creatorChip.icon}
                    squareColor={creatorChip.squareColor}
                    color={creatorChip.color}
                    borderStyle={creatorChip.borderStyle}
                    actions={creatorPayload !== undefined ? [{
                      key: 'open',
                      icon: IconName.output,
                      tooltip: i18n`Open`,
                      action: { to: creatorPayload.to, state: creatorPayload.state, openInNewTab: false },
                      showOnHover: true,
                    }] : undefined}
                  />
                </div>
                <div className={classes.line}>
                  <Typo color={theme.color.text.secondary}>{i18n`On the`}</Typo>
                  <Typo>{formatENDisplayDate(new Date(collaboration[Collaboration_CreatedAt] ?? 0))}</Typo>
                </div>
                <div className={classes.line}>
                  <InlineLink to={historyNavTo} replace={historyNavReplace}>
                    {showUrl ? i18n`See where the collaboration was created` : i18n`See the most relevant context`}
                  </InlineLink>
                </div>
              </div>
            )
        }
      </div>
      <span className={classes.separator} />
      <div className={classes.block}>
        <div className={classes.line}>
          <Typo>{i18n`Workflow`}</Typo>
          <IconOnlyButton
            tooltip={isWorkflowCollapsed ? i18n`Expand` : i18n`Collapse`}
            variant={IconOnlyButtonVariants.tertiary}
            iconName={isWorkflowCollapsed ? IconName.keyboard_arrow_left : IconName.expand_more}
            onClick={() => setIsWorkflowCollapsed((current) => !current)}
            sizeVariant={SizeVariant.small}
          />
        </div>
        {
          isWorkflowCollapsed
            ? null
            : (
              <div className={classes.content}>
                <div className={classes.line}>
                  <Typo maxLine={1} color={theme.color.text.secondary}>{i18n`Name`}</Typo>
                  <Chip
                    text={workflowChip.label}
                    tooltip={workflowChip.tooltip}
                    icon={workflowChip.icon}
                    squareColor={workflowChip.squareColor}
                    color={workflowChip.color}
                    borderStyle={workflowChip.borderStyle}
                  />
                </div>
                <div className={classes.line}>
                  <Typo maxLine={1} color={theme.color.text.secondary}>{i18n`Closure status`}</Typo>
                  <SpacingLine>
                    {
                      closureStatusChips.map((chip) => (
                        <Chip
                          key={chip.id}
                          text={chip.label}
                          tooltip={chip.tooltip}
                          icon={chip.icon}
                          squareColor={chip.squareColor}
                          color={chip.color}
                          borderStyle={chip.borderStyle}
                        />
                      ))
                    }
                  </SpacingLine>
                </div>
              </div>
            )
        }
      </div>
    </UsageContextProvider>
  );
};

export default CollaborationDetailPanelInfoTab;
