import type { ComponentProps, FunctionComponent } from 'react';
import CoverPicker from '../../../components/inputs/CoverPicker';
import StoreInput from './StoreInput';

interface StoreCoverPickerProps extends Omit<ComponentProps<typeof CoverPicker>, 'value' | 'onChange' | 'onSubmit' | 'onCancel'> {
  initialValue: ComponentProps<typeof CoverPicker>['value'],
  onSubmit: (content: Exclude<ComponentProps<typeof CoverPicker>['value'], undefined> | null) => void,
}

const StoreCoverPicker: FunctionComponent<StoreCoverPickerProps> = ({ initialValue, onSubmit, ...imageInputProps }) => (
  <StoreInput initialValue={initialValue} onSubmit={onSubmit}>
    {(props) => (
      <CoverPicker
        {...imageInputProps}
        {...props}
      />
    )}
  </StoreInput>
);

export default StoreCoverPicker;
