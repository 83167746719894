import { v4 as uuid } from 'uuid';
import type { DimensionsMapping, FieldStoreObject, SingleParameterDefinition, PathStep } from 'yooi-modules/modules/conceptModule';
import {
  createValuePathResolver,
  getInstanceLabel,
  getPathLastFieldInformation,
  getPathReturnedConceptDefinitionId,
  isFieldStep,
  isMappingStep,
  isMultiplePath,
  isSingleFieldResolution,
  numberFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import {
  AssociationField,
  EmbeddingField,
  Field_Title,
  KinshipRelationField,
  NumberField,
  RelationMultipleField,
  RelationSingleField,
  TimeseriesNumberField,
  TimeseriesTextField,
} from 'yooi-modules/modules/conceptModule/ids';
import type { EditableViewSeriesProperties, ViewDimension, ViewSeries } from 'yooi-modules/modules/dashboardModule';
import { ViewType } from 'yooi-modules/modules/dashboardModule';
import { DashboardParameter, DashboardParameter_Label, UnsetDashboardParameterOption } from 'yooi-modules/modules/dashboardModule/ids';
import type { SeriesFeatureDefinition } from 'yooi-modules/modules/dashboardModule/views/common/series/seriesFeatureDefinition';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import type { FrontObjectStore } from '../../../../../store/useStore';
import i18n from '../../../../../utils/i18n';
import { getFieldLabel, getInstanceMaxMinValues, TickResolutionStatus } from '../../../fieldUtils';
import type { PathConfigurationHandler } from '../../../pathConfigurationHandler';
import { createPathConfigurationHandler, StepValidationState } from '../../../pathConfigurationHandler';
import { getDimensionLabel, getTypeLabel } from '../../data/dataResolution';
import { getDefaultLabel } from '../viewUtils';
import type { SeriesFeatureResolvedDefinition } from './seriesFeatureDefinition';

export const getViewDefinitionSeries = (definition: SeriesFeatureDefinition): ViewSeries[] => definition.series ?? [];

export const addSeries = <T extends SeriesFeatureDefinition>(oldView: T): T => {
  const newView = { ...oldView };
  newView.series = [
    ...getViewDefinitionSeries(oldView),
    {
      id: uuid(),
      path: [],
      displayOptions: {
        color: `#${Math.random().toString(16).substring(2, 8)}`,
      },
    },
  ];
  return newView;
};

export const deleteSeries = <T extends SeriesFeatureDefinition>(oldView: T, seriesId: string): T => {
  const newView = { ...oldView };
  newView.series = [...getViewDefinitionSeries(oldView).filter(({ id }) => id !== seriesId)];
  return newView;
};

export const updateSeries = <T extends SeriesFeatureDefinition>(
  oldView: T,
  dimensionId: string,
  properties: Partial<EditableViewSeriesProperties>
): T => {
  const newView = { ...oldView };
  newView.series = getViewDefinitionSeries(oldView).map((series) => {
    if (series.id === dimensionId) {
      return {
        id: dimensionId,
        label: properties.label ?? series.label,
        path: properties.path ?? series.path,
        displayOptions: properties.displayOptions ?? series.displayOptions,
        exportOptions: properties.exportOptions === null ? undefined : (properties.exportOptions ?? series.exportOptions),
      };
    } else {
      return series;
    }
  });
  return newView;
};

export const getSeriesLabelFromParameters = (
  store: FrontObjectStore,
  label: string | undefined,
  seriesIndex: number,
  path: PathStep[],
  parameterDefinitions: SingleParameterDefinition[]
): string => {
  if (label) {
    return getDefaultLabel(label, seriesIndex, i18n`Series`);
  }
  if (path.length === 2) {
    const lastStep = path[1];
    if (isMappingStep(lastStep)) {
      const dimensionIndex = parameterDefinitions?.findIndex(({ id }) => lastStep.mapping.id === id);
      if (parameterDefinitions && dimensionIndex >= 0) {
        const dimensionLabel = getTypeLabel(store, parameterDefinitions[dimensionIndex].label, dimensionIndex, parameterDefinitions[dimensionIndex].typeId);
        return getDefaultLabel(dimensionLabel, seriesIndex, i18n`Series`);
      }
    }
  }
  const lastFieldStepId = getPathLastFieldInformation(path)?.fieldId;
  const field = lastFieldStepId ? store.getObjectOrNull(lastFieldStepId) ?? undefined : undefined;
  return field && field[Field_Title] ? field[Field_Title] as string : getDefaultLabel(label, seriesIndex, i18n`Series`);
};

export const getPathLabel = (
  store: FrontObjectStore,
  label: string | undefined,
  seriesIndex: number,
  path: PathStep[],
  dimensions: ViewDimension[],
  parametersIds: string[],
  defaultLabel: string
): string => {
  if (label) {
    return getDefaultLabel(label, seriesIndex, defaultLabel);
  }
  if (path.length === 2) {
    const lastStep = path[1];
    if (isMappingStep(lastStep)) {
      const dimensionIndex = dimensions?.findIndex(({ id }) => lastStep.mapping.id === id);
      if (dimensions && dimensionIndex >= 0) {
        const dimensionLabel = getDimensionLabel(store, dimensions[dimensionIndex].label, dimensionIndex, dimensions[dimensionIndex].path);
        return getDefaultLabel(dimensionLabel, seriesIndex, defaultLabel);
      }
      const parameterId = parametersIds.find((id) => id === lastStep.mapping.id);
      if (parameterId) {
        return getDefaultLabel(store.getObjectOrNull(parameterId)?.[DashboardParameter_Label] as string | undefined, seriesIndex, defaultLabel);
      }
    }
  }
  const lastFieldStepId = getPathLastFieldInformation(path)?.fieldId;
  const field = lastFieldStepId ? store.getObjectOrNull<FieldStoreObject>(lastFieldStepId) ?? undefined : undefined;
  return field ? getFieldLabel(store, field) : getDefaultLabel(label, seriesIndex, defaultLabel);
};

export const getSeriesLabel = (
  store: FrontObjectStore,
  label: string | undefined,
  seriesIndex: number,
  path: PathStep[],
  dimensions: ViewDimension[],
  parametersIds: string[]
): string => getPathLabel(store, label, seriesIndex, path, dimensions, parametersIds, i18n`Series`);
export const getProjectionValueLabel = (objectStore: FrontObjectStore, value: string | undefined): string | undefined => {
  if (value === undefined || isInstanceOf(objectStore.getObject(value), DashboardParameter)) {
    return i18n`All`;
  } else if (value === UnsetDashboardParameterOption) {
    return i18n`Not set`;
  } else {
    return getInstanceLabel(objectStore, objectStore.getObject(value));
  }
};
export const getDimensionCompositionFromSeries = (dimensionCompositions: DimensionsMapping[], series: ViewSeries[]): DimensionsMapping[] => (
  (dimensionCompositions.length > 0 ? dimensionCompositions : [{}]
  ).flatMap((composition) => series.map((s) => (joinObjects(composition, { serieId: s.id }))))
);

export const getSeriesMinMax = (store: FrontObjectStore, series: SeriesFeatureDefinition['series']): { min?: number, max?: number, label?: string }[] => {
  const result = [] as { min?: number, max?: number, label?: string }[];
  if (series && series.length > 0) {
    const pathResolver = createValuePathResolver(store, {});
    series.forEach(({ path }) => {
      const resolutionField = pathResolver.resolvePathField(path);
      if (isSingleFieldResolution(resolutionField) && isInstanceOf(store.getObjectOrNull(resolutionField.fieldId), NumberField)) {
        const fieldHandler = numberFieldHandler(store, resolutionField.fieldId);
        const numberField = fieldHandler.resolveConfiguration();
        const { min, max } = getInstanceMaxMinValues(store, numberField.id, undefined, {});
        result.push({
          min: min?.status === TickResolutionStatus.Resolved ? min.value : undefined,
          max: max?.status === TickResolutionStatus.Resolved ? max.value : undefined,
          label: numberField.title,
        });
      }
    });
  }
  return result;
};

const viewTypeToAuthorizedField: { [key in ViewType]: string[] | undefined } = {
  [ViewType.LineChart]: [NumberField, TimeseriesNumberField],
  [ViewType.TemporalBarChart]: [NumberField, TimeseriesNumberField],
  [ViewType.StructuralBarChart]: [NumberField, TimeseriesNumberField],
  [ViewType.Gauge]: [NumberField, TimeseriesNumberField],
  [ViewType.Table]: undefined,
  [ViewType.Swimlane]: undefined,
  [ViewType.Matrix]: undefined,
  [ViewType.Timeline]: undefined,
  [ViewType.Cards]: undefined,
  [ViewType.Chip]: undefined,
  [ViewType.TimeseriesTable]: [TimeseriesNumberField, TimeseriesTextField],
};

export const getPathSeriesPathConfigurationHandler = (
  store: FrontObjectStore,
  parameterDefinitions: SingleParameterDefinition[],
  viewType: ViewType
): PathConfigurationHandler => createPathConfigurationHandler(
  store,
  parameterDefinitions,
  [
    ({ pathStep: lastStep, isNPath, path, isLastStep }) => {
      const result = [];
      const fieldTypeErrorMessage = i18n`Input end with an unauthorized field.`;
      const authorizedField = viewTypeToAuthorizedField[viewType];
      if (isFieldStep(lastStep)) {
        const fieldDefinitionId = store.getObjectOrNull(lastStep.fieldId)?.[Instance_Of] as string;
        const isRelationField = [AssociationField, RelationMultipleField, EmbeddingField, KinshipRelationField, RelationSingleField].includes(fieldDefinitionId);
        if (isLastStep && isMultiplePath(store, path.slice(0, path.length - 1))) {
          if (isRelationField) {
            result.push({ state: StepValidationState.partiallyValid, reasonMessage: i18n`Input should be unique, use a mapping in your path.` });
          } else {
            result.push({ state: StepValidationState.invalid, reasonMessage: i18n`Input should be unique, use a mapping in your path.` });
          }
        } else if (!authorizedField || authorizedField.includes(fieldDefinitionId)) {
          result.push({ state: StepValidationState.valid });
        } else if (isRelationField) {
          result.push({ state: StepValidationState.partiallyValid, reasonMessage: fieldTypeErrorMessage });
        } else {
          result.push({ state: StepValidationState.invalid, reasonMessage: fieldTypeErrorMessage });
        }
      } else if (isNPath) {
        result.push({ state: StepValidationState.partiallyValid, reasonMessage: i18n`Input should be unique, use a mapping in your path.` });
      } else if (isMappingStep(lastStep) && path.length === 2 && viewType === ViewType.Table) {
        result.push({ state: StepValidationState.valid });
      } else {
        result.push({ state: StepValidationState.partiallyValid, reasonMessage: fieldTypeErrorMessage });
      }
      return result;
    },
  ]
);

export const getViewDimensionsAsParameterDefinitions = (store: FrontObjectStore, viewDimensions: ViewDimension[]): SingleParameterDefinition[] => {
  const dimensionToParameterDefinition = (dimension: ViewDimension, index: number): SingleParameterDefinition | undefined => {
    const typeId = getPathReturnedConceptDefinitionId(store, dimension.path);
    return typeId ? { id: dimension.id, label: getDimensionLabel(store, dimension.label, index, dimension.path), typeId, type: 'dimension' } : undefined;
  };
  return viewDimensions.map(dimensionToParameterDefinition).filter(filterNullOrUndefined);
};

export const getSeriesError = (
  store: FrontObjectStore,
  viewDefinition: SeriesFeatureResolvedDefinition,
  parameterDefinitions: SingleParameterDefinition[],
  viewType: ViewType
): undefined | string => {
  if (!viewDefinition.series.length) {
    return i18n`Missing series`;
  } else {
    const pathConfigurationHandler = getPathSeriesPathConfigurationHandler(store, parameterDefinitions, viewType);
    if (viewDefinition.series.every(({ path }) => path.length === 0 || pathConfigurationHandler.getErrors(path))) {
      return i18n`Missing valid series`;
    }
  }
  return undefined;
};
