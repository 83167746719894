import { equals } from 'ramda';
import type { Activity, SendActivity } from './networkEventClient';

interface ActivityNotifier {
  onConnected: () => void,
  onView: (objectId: string | null) => void,
  onEnterEdition: (objectId: string, fieldId: string) => void,
  onExitEdition: (objectId: string, fieldId: string) => void,
}

const createActivityNotifier = (sendActivity: SendActivity): ActivityNotifier => {
  let lastViewed: Activity = { objectId: null, fieldId: null };
  let lastEdited: Activity | undefined;

  return {
    onConnected: () => {
      sendActivity(lastEdited ?? lastViewed);
    },
    onView: (objectId) => {
      const viewing = { objectId, fieldId: null };
      if (!equals(viewing, lastViewed)) {
        lastViewed = viewing;
        sendActivity(viewing);
      }
    },
    onEnterEdition: (objectId, fieldId) => {
      const editing = { objectId, fieldId };
      if (!equals(lastEdited, editing)) {
        lastEdited = editing;
        sendActivity(editing);
      }
    },
    onExitEdition: (objectId, fieldId) => {
      const exiting = { objectId, fieldId };
      if (equals(lastEdited, exiting)) {
        lastEdited = undefined;
        sendActivity(lastViewed);
      }
    },
  };
};

export default createActivityNotifier;
