import {
  Block,
  Block_ConceptDefinition,
  Block_FieldDisplays,
  Block_Parent,
  Block_Rank,
  Block_Type,
  BlockType_Column,
  BlockType_Section,
  BlockType_Tab,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStore, StoreObject } from 'yooi-store';
import { newError } from 'yooi-utils';
import type { FrontObjectStore } from '../../../../../store/useStore';
import type { DisplayItemLibrary } from '../displayItemLibrary';
import { registerDisplayItem } from '../displayItemLibrary';
import type { DisplayItem } from './displayItemDefinitionUtils';
import { computeChildBlockList, getFlattenedTree, getSortedBlockFields, updateFieldParentBlock } from './displayItemDefinitionUtils';

const COMPATIBLE_PARENT_TYPES_IDS = [BlockType_Tab, BlockType_Section];

const createColumn = (store: ObjectStore, parentId: string, rank: string): string => store.createObject(
  {
    [Instance_Of]: Block,
    [Block_ConceptDefinition]: store.getObject(parentId)[Block_ConceptDefinition],
    [Block_Rank]: rank,
    [Block_Type]: BlockType_Column,
    [Block_Parent]: parentId,
  }
);

const getClosestAboveParent = (block: StoreObject): StoreObject | null => {
  if (COMPATIBLE_PARENT_TYPES_IDS.includes(block[Block_Type] as string)) {
    return block;
  } else {
    return block.navigateOrNull(Block_Parent);
  }
};

const createBelow = (
  store: FrontObjectStore,
  flattenedTree: DisplayItem[],
  aboveBlockId?: string,
  aboveFieldBlockDisplayId?: string
): string => {
  if (aboveBlockId) {
    const block = store.getObject(aboveBlockId);
    const parentBlock = getClosestAboveParent(block);
    if (!parentBlock) {
      throw newError('Cannot create a column outside of a Parent block');
    }
    const parentBlockChildBlockList = computeChildBlockList(parentBlock);
    const aboveDisplayItem = parentBlockChildBlockList.find(({ item: { id } }) => id === aboveBlockId);
    const rank = aboveDisplayItem ? aboveDisplayItem.insertAfterRank() : parentBlockChildBlockList.insertBeforeFirstItemRank();

    const itemIndex = flattenedTree.findIndex(({ blockId: bId, fieldBlockDisplayId: fBDId }) => bId === aboveBlockId && fBDId === aboveFieldBlockDisplayId);
    const itemsToReroute = flattenedTree
      .filter((_, i) => i > itemIndex)
      .filter(({ blockId: bId, fieldBlockDisplayId: fBDId }) => {
        if (fBDId) {
          return aboveBlockId === bId;
        } else {
          return false;
        }
      });

    const newColumnId = createColumn(store, parentBlock.id, rank);
    itemsToReroute.forEach(({ fieldBlockDisplayId: fBDId }) => {
      if (fBDId) {
        updateFieldParentBlock(store, fBDId, newColumnId);
      }
    });
    return newColumnId;
  } else {
    throw newError('Column should always have above block');
  }
};

const blockColumnHandler: DisplayItemLibrary['itemHandler'] = (store, blockId, conceptDefinition, flattenedTree) => {
  const removeBlockItems = (blockToRemoveId: string) => {
    store.getObject(blockToRemoveId).navigateBack(Block_FieldDisplays).forEach((fieldDisplay) => store.deleteObject(fieldDisplay.id));
    store.getObject(blockToRemoveId).navigateBack(Block_Parent).forEach((childBlock) => removeBlockItems(childBlock.id));
  };
  const deleteBlockAndContent = () => {
    removeBlockItems(blockId);
    store.deleteObject(blockId);
  };

  const deleteBlock = () => {
    const flattenedTreeUpdated = getFlattenedTree(store, conceptDefinition);
    const blockIdIndex = flattenedTreeUpdated.findIndex(({ blockId: bId, fieldBlockDisplayId }) => blockId === bId && !fieldBlockDisplayId);
    const newParentBlockId = flattenedTreeUpdated[blockIdIndex - 1].blockId;
    getSortedBlockFields(store, blockId).forEach((field) => {
      updateFieldParentBlock(store, field.id, newParentBlockId);
    });
    store.deleteObject(blockId);
  };

  return {
    canMoveUp: () => flattenedTree.findIndex(({ blockId: bId, fieldBlockDisplayId }) => blockId === bId && !fieldBlockDisplayId) > 1,
    canMoveDown: () => flattenedTree.findIndex(({ blockId: bId, fieldBlockDisplayId }) => blockId === bId && !fieldBlockDisplayId) < flattenedTree.length - 1,
    moveUp: () => {
      const columnDividerIndex = flattenedTree.findIndex(({ blockId: bId, fieldBlockDisplayId }) => blockId === bId && !fieldBlockDisplayId);
      const { blockId: belowBlockId, fieldBlockDisplayId: belowFieldId } = flattenedTree[columnDividerIndex - 2];
      createBelow(store, flattenedTree, belowBlockId, belowFieldId);
      deleteBlock();
      return belowBlockId;
    },
    moveDown: () => {
      const columnDividerIndex = flattenedTree.findIndex(({ blockId: bId, fieldBlockDisplayId }) => blockId === bId && !fieldBlockDisplayId);
      const { blockId: belowBlockId, fieldBlockDisplayId: belowFieldId } = flattenedTree[columnDividerIndex + 1];
      createBelow(store, flattenedTree, belowBlockId, belowFieldId);
      deleteBlock();
      return belowBlockId;
    },
    deleteBlockAndContent,
  };
};

const blockColumnDefinitionHandler: DisplayItemLibrary['itemDefinitionHandler'] = (store, _, flattenedTree) => ({
  createBelow: (topBlockId?: string, topFieldId?: string) => createBelow(store, flattenedTree, topBlockId, topFieldId),
  createAbove: (fromBlockId: string, fromFieldBlockDisplayId?: string) => {
    const itemIndex = flattenedTree.findIndex(({ blockId: bId, fieldBlockDisplayId: fBDId }) => fromBlockId === bId && fromFieldBlockDisplayId === fBDId);
    const { blockId: bId, fieldBlockDisplayId: fBDId } = flattenedTree[itemIndex - 1] ?? {};
    return createBelow(store, flattenedTree, bId, fBDId);
  },
});

registerDisplayItem({
  id: BlockType_Column,
  itemHandler: blockColumnHandler,
  itemDefinitionHandler: blockColumnDefinitionHandler,
});
