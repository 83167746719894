import classnames from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import type { ListChildComponentProps } from 'react-window';
import { buildPadding, Spacing, spacingRem } from '../../../theme/spacingDefinition';
import makeStyles from '../../../utils/makeStyles';
import { formatOrUndef } from '../../../utils/stringUtils';
import useNavigation from '../../../utils/useNavigation';
import useTheme from '../../../utils/useTheme';
import MasterDetailListLine from '../../atoms/MasterDetailListLine';
import Tooltip from '../../atoms/Tooltip';
import Typo, { TypoVariant } from '../../atoms/Typo';
import InlineLink from '../InlineLink';

const escapeHtml = (stringToEscape: string | undefined) => stringToEscape?.replaceAll('|', '%7C');

const useStyles = makeStyles((theme) => ({
  lineContainer: buildPadding({ left: Spacing.m, right: Spacing.s }),
  inner: {
    outline: 0,
    borderTopWidth: '0.1rem',
    borderTopStyle: 'solid',
    borderTopColor: theme.color.border.default,
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
    '&:hover, &:focus': {
      borderTopColor: theme.color.border.dark,
      borderBottomColor: theme.color.border.dark,
      '& + div': {
        borderTopWidth: '0.1rem',
        borderTopStyle: 'solid',
        borderTopColor: theme.color.border.dark,
      },
    },
  },
  link: {
    cursor: 'pointer',
  },
  borderBottom: {
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
    '&:hover, &:focus': {
      borderBottomColor: theme.color.border.dark,
    },
  },
  group: {
    cursor: 'auto',
    outline: 0,
    marginBottom: spacingRem.xs,
    marginTop: spacingRem.xs,
  },
}), 'masterDetailLine');

export interface MasterDetailLineItemEntry {
  key: string,
  type: 'item',
  render: () => ReactElement,
  to: () => { pathname?: string, hash?: string, search?: string },
}

export interface MasterDetailLineGroupEntry {
  key: string,
  type: 'group',
  label: string | undefined,
  color: string | undefined,
}

interface MasterDetailLineProps extends ListChildComponentProps<(MasterDetailLineItemEntry | MasterDetailLineGroupEntry)[]> {}

const MasterDetailLine: FunctionComponent<MasterDetailLineProps> = ({ data, index, style }) => {
  const theme = useTheme();
  const classes = useStyles();

  const navigation = useNavigation();
  const location = useLocation();

  const line = data[index];

  const renderLine = (borderBottom: boolean, isActive: boolean) => {
    if (line.type === 'item') {
      const lineDisplay = (
        <div
          tabIndex={0}
          className={classnames({
            [classes.inner]: true,
            [classes.link]: line.to().pathname !== location.pathname,
            [classes.borderBottom]: borderBottom,
          })}
          onKeyDown={(event) => {
            if (line.key && event.key === 'Enter') {
              navigation.push(line.key, line.to());
            }
          }}
          role="link"
        >
          <MasterDetailListLine active={isActive}>
            {line.render()}
          </MasterDetailListLine>
        </div>
      );
      if (line.to().pathname === location.pathname) {
        return lineDisplay;
      } else {
        return (<InlineLink {...navigation.createNavigationPayload(line.key, line.to(), { replace: true })} noStyle>{lineDisplay}</InlineLink>);
      }
    } else {
      return (
        <div className={classes.group}>
          <MasterDetailListLine isGroup color={line.color}>
            <Tooltip title={formatOrUndef(line.label)}>
              <Typo maxLine={1} color={theme.color.text.white} variant={TypoVariant.blockSecondaryTitle}>
                {formatOrUndef(line.label)}
              </Typo>
            </Tooltip>
          </MasterDetailListLine>
        </div>
      );
    }
  };

  return (
    <span
      key={line.type === 'group' ? `group_${line.key}` : line.key}
      style={style}
      className={classes.lineContainer}
    >
      {renderLine(index === data.length - 1 || (data[index + 1]?.type === 'group'), escapeHtml(line.key) === location.pathname.split('/').pop())}
    </span>
  );
};

export default MasterDetailLine;
