import type { ComponentProps, FunctionComponent, ReactElement } from 'react';
import type {
  ConceptInstanceFilterConditions,
  ConceptMultipleInstanceFilterConditions,
  ConceptStoreObject,
  FilterCondition,
  Filters,
  FilterValue,
  FilterValuePath,
  FilterValueRaw,
  InstanceReferenceValue,
  ParametersMapping,
  PathStep,
  SingleParameterDefinition,
  StakeholdersFieldStoreObject,
  WorkflowFieldStoreObject,
} from 'yooi-modules/modules/conceptModule';
import {
  FILTER_PARAMETER_CURRENT,
  FilterConditionOperators,
  FilterValueType,
  getReverseField,
  InstanceReferenceType,
  isDimensionStep,
  isFieldStep,
  isFilterStep,
  isFilterValuePath,
  isFilterValueRaw,
  isGlobalDimensionStep,
  isMappingStep,
  isMultiplePath,
  isPathTargetingConcept,
  isRelationalType,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import {
  Concept,
  ConceptDefinition_Roles,
  ConceptRole,
  KinshipRelation,
  StakeholdersField,
  WorkflowEntry,
  WorkflowEntry_Rank,
  WorkflowEntry_Role_Concept,
  WorkflowEntry_Role_Workflow,
  WorkflowField,
  WorkflowField_Workflow,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { compareRank, compareString, extractAndCompareValue, filterNullOrUndefined, joinObjects } from 'yooi-utils';
import SearchAndSelect from '../../components/molecules/SearchAndSelect';
import SearchAndSelectMultiple from '../../components/molecules/SearchAndSelectMultiple';
import type { FrontObjectStore } from '../../store/useStore';
import base from '../../theme/base';
import { spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import type { StepOption } from './fields/_global/pathUtils';
import { getAllInstancesChipOption, getNthStep } from './fields/_global/pathUtils';
import { getFieldHandler } from './fields/FieldLibrary';
import type { FrontFilterCondition, RenderFilter, SetValue, ToFrontInstanceFilter } from './fields/FieldLibraryTypes';
import type { Option } from './modelTypeUtils';
import { defaultOptionComparator, getChipOptions, getConceptDefinitionNameOrEntity, getModelTypeInstances, getParameterOption } from './modelTypeUtils';
import type { NavigationFilter } from './navigationUtils';
import PathStepsInput from './path/PathStepsInput';
import type { PathConfigurationHandler, PathStepValidator } from './pathConfigurationHandler';
import { createPathConfigurationHandler, StepValidationState } from './pathConfigurationHandler';

export const getFilterValuePathHandler = (
  store: ObjectStoreWithTimeseries,
  conceptDefinitionIds: string[],
  parameterDefinitions: SingleParameterDefinition[],
  extraValidators: PathStepValidator[] = [],
  requiredParameters = [FILTER_PARAMETER_CURRENT]
): PathConfigurationHandler => (
  createPathConfigurationHandler(
    store,
    parameterDefinitions,
    [
      ({ pathStep, path: currentPath, isNPath }) => {
        if (isGlobalDimensionStep(pathStep)) {
          return [{ state: StepValidationState.invalid, reasonMessage: i18n`Global dimension step not supported.` }];
        } else if (isFilterStep(pathStep) && pathStep.filters !== undefined) {
          return [{ state: StepValidationState.invalid, reasonMessage: i18n`Filters are not supported.` }];
        } else if (isFieldStep(pathStep) && isNPath) {
          if (!store.getObjectOrNull(pathStep.fieldId)) {
            return [{ state: StepValidationState.invalid }];
          }
          const field = store.getObject(pathStep.fieldId);
          const isRelationField = isRelationalType(field[Instance_Of] as string);
          if (!isRelationField) {
            return [{ state: StepValidationState.invalid, reasonMessage: i18n`Cannot use non relational fields on multiple value paths.` }];
          }
        }

        const firstStep = currentPath[0];
        if (!firstStep) {
          return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`First step must be a dimension step.` }];
        } else if (firstStep && !isDimensionStep(firstStep)) {
          return [{
            state: StepValidationState.invalid,
            reasonMessage: i18n`First step must be of type "${conceptDefinitionIds
              .map((conceptDefinitionId) => getConceptDefinitionNameOrEntity(store, conceptDefinitionId))
              .join(i18n` or `)}".`,
          }];
        } else if (firstStep && isDimensionStep(firstStep) && !conceptDefinitionIds.includes(firstStep.conceptDefinitionId)) {
          return [{
            state: StepValidationState.invalid,
            reasonMessage: i18n`First step must be of type "${conceptDefinitionIds
              .map((conceptDefinitionId) => getConceptDefinitionNameOrEntity(store, conceptDefinitionId))
              .join(i18n` or `)}".`,
          }];
        }
        const secondStep = currentPath[1];
        if (!secondStep) {
          return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`Second step must be a mapping step.` }];
        } else if (secondStep && !isMappingStep(secondStep)) {
          return [{ state: StepValidationState.invalid, reasonMessage: i18n`Second step must be a mapping step.` }];
        } else if (secondStep && isMappingStep(secondStep) && !requiredParameters.includes(secondStep.mapping.id)) {
          return [{ state: StepValidationState.invalid, reasonMessage: i18n`Second step must be a mapping step with one of the allowed parameters.` }];
        }

        if (currentPath.length > 2 && currentPath[currentPath.length - 1]) {
          const lastStep = currentPath[currentPath.length - 1];
          if (!isFieldStep(lastStep) && !isFilterStep(lastStep) && !isDimensionStep(lastStep)) {
            return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`Last step is not filterable.` }];
          }
        }
        return [{ state: StepValidationState.valid }];
      },
      ...extraValidators,
    ]
  )
);

export const getDefaultInstanceFilter = (modelTypeId: string, instanceDimensionId: string): Filters => ({
  leftValue: [
    { type: PathStepType.dimension, conceptDefinitionId: modelTypeId },
    { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
  ],
  operator: 'EQUALS',
  rightValue: { type: FilterValueType.raw, raw: { id: instanceDimensionId, type: InstanceReferenceType.instance } },
});

interface RenderPartProps<T> {
  store: FrontObjectStore,
  mustBeMultipleValue?: boolean,
  optionObjects?: Option[],
  valueToCompare: T,
  setValue: SetValue<T>,
  parameterDefinitions?: SingleParameterDefinition[],
  readOnly: boolean,
  suggestedBasePaths?: { label: string, path: PathStep[] }[],
  rootPath?: { label: string, path: PathStep[] },
  placeholder?: string,
}

const renderMultipleInstanceSelectorFilter = ({
  store,
  optionObjects = [],
  valueToCompare,
  setValue,
  parameterDefinitions = [],
  readOnly,
  placeholder,
}: RenderPartProps<FilterValueRaw<InstanceReferenceValue[]>>): ReactElement => {
  const { raw } = valueToCompare;
  const computeOptions = (ids?: string[]) => (): StepOption[] => optionObjects
    .filter(({ id }) => !(ids ?? []).some((v) => v === id))
    .filter(filterNullOrUndefined);

  const options: Option[] = raw?.map(({ id }) => {
    if (parameterDefinitions?.some((p) => p.id === id)) {
      return getParameterOption(store, parameterDefinitions?.find((p) => p.id === id) as SingleParameterDefinition);
    } else {
      return getChipOptions(store, id);
    }
  }).filter(filterNullOrUndefined) ?? [];

  const sanitizeExistingValue = (values: InstanceReferenceValue[]) => {
    const authorizedOptions = computeOptions()().map(({ id }) => id);
    return values.filter((v) => authorizedOptions.includes(v.id));
  };
  return (
    <SearchAndSelectMultiple<Option>
      placeholder={placeholder}
      computeOptions={computeOptions(raw?.map(({ id }) => id) ?? [])}
      readOnly={readOnly}
      forceSingleLine
      selectedOptions={options}
      onSelect={({ id }) => setValue((current) => {
        const valueType = parameterDefinitions.some((p) => p.id === id) ? InstanceReferenceType.parameter : InstanceReferenceType.instance;
        return {
          type: FilterValueType.raw,
          raw: isFilterValueRaw(current) && current.raw
            ? [...sanitizeExistingValue(current.raw), { id, type: valueType }] : [{ id, type: valueType }],
        };
      })}
      onDelete={({ id }) => setValue(({ raw: rawValue }) => {
        const index = rawValue.findIndex((item) => item.id === id);
        const array = [...rawValue];
        array.splice(index, 1);
        return ({ type: FilterValueType.raw, raw: array });
      })}
    />
  );
};

const renderSingleInstanceSelectorFilter = ({
  store,
  optionObjects = [],
  valueToCompare,
  setValue,
  readOnly,
  parameterDefinitions,
  placeholder,
}: RenderPartProps<FilterValueRaw<InstanceReferenceValue>>): ReactElement => {
  const { raw } = valueToCompare;
  const computeOptions = (v: string | undefined) => (): Option[] => optionObjects.filter(({ id }) => v !== id)
    .filter(filterNullOrUndefined);
  const activeParameter = parameterDefinitions?.find(({ id }) => id === raw?.id);
  const selectedValue = (raw ? getChipOptions(store, raw.id) : undefined)
    ?? (activeParameter ? getParameterOption(store, activeParameter) : undefined);
  return (
    <SearchAndSelect
      placeholder={placeholder}
      readOnly={readOnly}
      computeOptions={computeOptions(raw?.id)}
      selectedOption={selectedValue}
      onSelect={(v) => {
        if (v) {
          setValue(() => (
            {
              type: FilterValueType.raw,
              raw:
                {
                  id: v.id,
                  type: parameterDefinitions?.some((p) => p.id === v.id) ? InstanceReferenceType.parameter : InstanceReferenceType.instance,
                },
            }));
        }
      }}
    />
  );
};

const renderInstancePathSelector = ({
  store,
  mustBeMultipleValue,
  valueToCompare,
  setValue,
  parameterDefinitions,
  readOnly,
  suggestedBasePaths,
  rootPath,
  placeholder,
}: RenderPartProps<FilterValuePath>): ReactElement => {
  const { path } = valueToCompare;
  const valuePathHandler = createPathConfigurationHandler(
    store,
    parameterDefinitions ?? [],
    [
      ({ pathStep, isNPath }) => {
        if (!mustBeMultipleValue && isNPath) {
          return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`Condition requires a single value.` }];
        }
        if (isGlobalDimensionStep(pathStep)) {
          return [{ state: StepValidationState.invalid, reasonMessage: i18n`Global dimension step not supported.` }];
        } else if (isFilterStep(pathStep) && pathStep.filters !== undefined) {
          return [{ state: StepValidationState.invalid, reasonMessage: i18n`Filters are not supported.` }];
        }
        return [{ state: StepValidationState.valid }];
      },
    ]
  );
  const getStepChipOption: ComponentProps<typeof PathStepsInput>['getStepChipOption'] = (pathStep, index, self, fallbackStepChipOption) => {
    const n1Step = getNthStep(self, index, 1);
    const lastStep = pathStep.steps[pathStep.steps.length - 1];
    if (isFilterStep(lastStep)) {
      if (n1Step && isDimensionStep(n1Step)) {
        const allInstancesChipOption = getAllInstancesChipOption(store, n1Step.conceptDefinitionId);
        return joinObjects(allInstancesChipOption, { step: pathStep, id: `${allInstancesChipOption.id}_${index}`, index });
      }
    }
    return fallbackStepChipOption?.(pathStep, index, self);
  };

  return (
    <PathStepsInput
      placeholder={placeholder}
      onSubmit={(newPath) => {
        setValue({ type: FilterValueType.path, path: newPath });
      }}
      initialPath={path ?? []}
      valuePathHandler={valuePathHandler}
      parameterDefinitions={parameterDefinitions}
      getStepChipOption={getStepChipOption}
      readOnly={readOnly}
      suggestedBasePaths={suggestedBasePaths}
      rootPath={rootPath}
    />
  );
};

const useStyles = makeStyles({
  grid: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'minmax(10rem, 1fr)',
    columnGap: spacingRem.s,
    borderRadius: base.borderRadius.medium,
    width: '100%',
  },
  complexGrid: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '10rem minmax(10rem, 1fr)',
    columnGap: spacingRem.s,
    borderRadius: base.borderRadius.medium,
    width: '100%',
  },
}, 'frontFilterRenderers');

interface TypeSelectorProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: FilterValue<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: SetValue<FilterValue<any>>,
  readOnly: boolean,
  valueSelector: ReactElement,
  withSelector: boolean,
}

const TypeSelector: FunctionComponent<TypeSelectorProps> = ({ value, setValue, readOnly, valueSelector, withSelector }) => {
  const classes = useStyles();

  const { type } = value;
  const typeOptions = [
    { id: FilterValueType.path, label: i18n`Path` },
    { id: FilterValueType.raw, label: i18n`Value` },
  ].sort(extractAndCompareValue(({ label }) => label, compareString));

  return (
    <div className={withSelector ? classes.complexGrid : classes.grid}>
      {withSelector && (
        <SearchAndSelect<{ id: FilterValueType, label: string }>
          selectedOption={typeOptions.find((v) => v.id === (type ?? FilterValueType.raw))}
          computeOptions={() => typeOptions}
          onSelect={(option) => {
            if (option) {
              setValue({ ...(option.id === FilterValueType.path ? { type: FilterValueType.path, path: [] } : { type: FilterValueType.raw, raw: undefined }) });
            } else {
              setValue({ type: FilterValueType.raw, raw: undefined });
            }
          }}
          readOnly={readOnly}
          clearable
        />
      )}
      {valueSelector}
    </div>
  );
};

const getValueOptionsForPath = (store: FrontObjectStore, parameterDefinitions: SingleParameterDefinition[], path: PathStep[] | undefined): Option[] => {
  const pathConfigurationHandler = createPathConfigurationHandler(store, parameterDefinitions);
  const lastField = pathConfigurationHandler.getLastFieldInformation(path ?? []);
  const conceptDefinitionId = pathConfigurationHandler.getReturnedConceptDefinitionId(path ?? []);
  if (!conceptDefinitionId) {
    return [];
  }

  const field = lastField ? store.getObjectOrNull(lastField.fieldId) : undefined;

  let options: Option[];
  if (conceptDefinitionId === ConceptRole && isInstanceOf<StakeholdersFieldStoreObject>(field, StakeholdersField) && lastField && lastField.conceptDefinitionId) {
    options = store.getObject(lastField.conceptDefinitionId)
      .navigateBack(ConceptDefinition_Roles)
      .map(({ id }) => getChipOptions(store, id))
      .filter(filterNullOrUndefined)
      .sort(defaultOptionComparator);
  } else if (isInstanceOf<WorkflowFieldStoreObject>(field, WorkflowField) && field && field[WorkflowField_Workflow]) {
    options = store.withAssociation(WorkflowEntry)
      .withRole(WorkflowEntry_Role_Workflow, field[WorkflowField_Workflow])
      .list()
      .sort(extractAndCompareValue((workflowEntry) => workflowEntry.object[WorkflowEntry_Rank] as string, compareRank))
      .map((workflowEntry) => workflowEntry.navigateRole(WorkflowEntry_Role_Concept))
      .filter((concept) => isInstanceOf<ConceptStoreObject>(concept, conceptDefinitionId))
      .map(({ id }) => getChipOptions(store, id))
      .filter(filterNullOrUndefined);
  } else {
    options = getModelTypeInstances(store, conceptDefinitionId)
      .map(({ id }) => getChipOptions(store, id))
      .filter(filterNullOrUndefined)
      .sort(defaultOptionComparator);
  }

  return [
    ...(conceptDefinitionId === Concept ? parameterDefinitions : parameterDefinitions.filter(({ typeId }) => conceptDefinitionId === typeId))
      .map((parameter) => getParameterOption(store, parameter))
      .sort(defaultOptionComparator),
    ...options,
  ];
};

const renderMultiSelectFilter: (store: FrontObjectStore) => RenderFilter<FilterValue<InstanceReferenceValue[]>> = (store) => (
  value,
  setValue,
  parameterDefinitions,
  readOnly,
  path,
  isSimple,
  placeholder
) => {
  let valueSelector;
  if (value && isFilterValuePath(value)) {
    valueSelector = renderInstancePathSelector({
      store,
      mustBeMultipleValue: true,
      valueToCompare: value,
      setValue: setValue as SetValue<FilterValuePath>,
      parameterDefinitions,
      readOnly,
      placeholder,
    });
  } else {
    const optionObjects = getValueOptionsForPath(store, parameterDefinitions, path);
    valueSelector = renderMultipleInstanceSelectorFilter({
      store,
      optionObjects,
      valueToCompare: value,
      setValue: setValue as SetValue<FilterValueRaw<InstanceReferenceValue[]>>,
      parameterDefinitions,
      readOnly,
      placeholder,
    });
  }
  return (
    <TypeSelector value={value} readOnly={readOnly} setValue={setValue} valueSelector={valueSelector} withSelector={!isSimple} />
  );
};

const renderSingleSelectFilter: (store: FrontObjectStore) => RenderFilter<FilterValue<InstanceReferenceValue>> = (store) => (
  value,
  setValue,
  parameters,
  readOnly,
  path,
  isSimple,
  placeholder
) => {
  let valueSelector;
  if (value && isFilterValuePath(value)) {
    valueSelector = renderInstancePathSelector({
      store,
      mustBeMultipleValue: false,
      valueToCompare: value,
      setValue: setValue as SetValue<FilterValuePath>,
      parameterDefinitions: parameters,
      readOnly,
    });
  } else {
    const optionObjects = getValueOptionsForPath(store, parameters, path);
    valueSelector = renderSingleInstanceSelectorFilter({
      store,
      optionObjects,
      valueToCompare: value,
      setValue: setValue as SetValue<FilterValueRaw<InstanceReferenceValue>>,
      parameterDefinitions: parameters,
      readOnly,
      placeholder,
    });
  }
  return (
    <TypeSelector value={value} readOnly={readOnly} setValue={setValue} valueSelector={valueSelector} withSelector={!isSimple} />
  );
};

const getInstanceFilterConditionsRenderers = (store: FrontObjectStore): ToFrontInstanceFilter<ConceptInstanceFilterConditions> => ({
  EQUALS: {
    name: i18n`Is`,
    renderFilter: renderSingleSelectFilter(store),
  },
  NOT_EQUALS: {
    name: i18n`Is not`,
    renderFilter: renderSingleSelectFilter(store),
  },
  IS_EMPTY: {
    name: i18n`Is empty`,
  },
  IS_NOT_EMPTY: {
    name: i18n`Is not empty`,
  },
  NOT_IN: {
    name: i18n`Not in`,
    renderFilter: renderMultiSelectFilter(store),
  },
  IN: {
    name: i18n`In`,
    renderFilter: renderMultiSelectFilter(store),
  },
});

const getMultipleInstanceFilterConditionsRenderers = (store: FrontObjectStore): ToFrontInstanceFilter<ConceptMultipleInstanceFilterConditions> => ({
  IS_EMPTY: {
    name: i18n`Is empty`,
  },
  IS_NOT_EMPTY: {
    name: i18n`Is not empty`,
  },
  DOES_NOT_CONTAIN: {
    name: i18n`Does not contain`,
    renderFilter: renderMultiSelectFilter(store),
  },
  CONTAINS: {
    name: i18n`Contains (all values)`,
    renderFilter: renderMultiSelectFilter(store),
  },
  CONTAINS_SOME: {
    name: i18n`Contains (at least one value)`,
    renderFilter: renderMultiSelectFilter(store),
  },
});

export const getRenderersFromFilterPath = (
  store: FrontObjectStore,
  filterPath: PathStep[],
  parameterDefinitions: SingleParameterDefinition[]
): Record<string, FrontFilterCondition<FilterValue<unknown>>> | undefined => {
  const isNPath = isMultiplePath(store, filterPath);
  const isTargetingConcept = isPathTargetingConcept(store, filterPath);
  if (isNPath && isTargetingConcept) {
    return getMultipleInstanceFilterConditionsRenderers(store);
  } else if (!isNPath && isTargetingConcept) {
    return getInstanceFilterConditionsRenderers(store);
  } else if (!isNPath) {
    const { fieldId: lastFieldId } = createPathConfigurationHandler(store, parameterDefinitions).getLastFieldInformation(filterPath) ?? {};
    return lastFieldId ? getFieldHandler(store, lastFieldId)?.filterConditions as Record<string, FrontFilterCondition<FilterValue<unknown>>> : undefined;
  }
  return undefined;
};

export const getNavigationFilter = (
  store: FrontObjectStore,
  fieldId: string,
  targetTypeId: string,
  instanceId: string | undefined,
  globalFilters?: Filters,
  globalParametersMapping?: ParametersMapping
): NavigationFilter | undefined => {
  const reverseField = getReverseField(store, fieldId, instanceId);
  if (!reverseField?.id || !instanceId) {
    return { globalFilters, globalParametersMapping };
  }
  const path: PathStep[] = [
    { type: PathStepType.dimension, conceptDefinitionId: targetTypeId },
    { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
    { type: PathStepType.field, fieldId: reverseField.id, embeddingFieldId: reverseField.id === KinshipRelation ? fieldId : undefined },
  ];
  const conditions = getRenderersFromFilterPath(store, path, []);
  let operator = 'EQUALS';
  if (conditions?.CONTAINS) {
    operator = 'CONTAINS';
  } else if (conditions?.IN) {
    operator = 'IN';
  }

  const navigationFilters: FilterCondition = {
    leftValue: path,
    operator,
    rightValue: { type: FilterValueType.raw, raw: [{ id: instanceId, type: InstanceReferenceType.instance }] },
  };

  return {
    navigationFilters: {
      children: [navigationFilters],
      condition: FilterConditionOperators.AND,
    },
    globalFilters,
    globalParametersMapping,
  };
};
