import type { FunctionComponent } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { ChipViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import ScrollableWidgetContainer from '../../../../components/templates/ScrollableWidgetContainer';
import WidgetLoadingPlaceholder from '../../../../components/templates/WidgetLoadingPlaceholder';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import useDeepMemo from '../../../../utils/useDeepMemo';
import WidgetInvalidConfiguration from '../../fields/_global/WidgetInvalidConfiguration';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { getViewDefinitionHandler } from '../viewDsl';
import { isResolutionError } from '../viewResolutionUtils';
import ChipView from './ChipView';
import type { ChipViewDefinitionHandler } from './chipViewDefinitionHandler';

const useStyles = makeStyles({
  chipContainer: {
    overflowX: 'auto',
    overflowY: 'auto',
    paddingRight: spacingRem.s,
  },
}, 'chipViewWidget');

interface ChipViewWidgetProps {
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinition: ChipViewStoredDefinition,
  parametersMapping: ParametersMapping,
  width: number,
  height: number,
  readOnly: boolean | undefined,
}

const ChipViewWidget: FunctionComponent<ChipViewWidgetProps> = ({
  viewDimensions,
  viewFilters,
  viewDefinition,
  parametersMapping,
  width,
  height,
  readOnly,
}) => {
  const classes = useStyles();

  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();

  const viewHandler = getViewDefinitionHandler(viewDefinition) as ChipViewDefinitionHandler;
  const resolvedViewDefinition = viewHandler.getDefinition(store, viewDimensions);

  const chipResolution = useDeepMemo(
    () => viewHandler.resolveView(store, { viewDimensions, parametersMapping, readOnly, aclHandler, userId: loggedUserId }),
    [store.getSerial(), viewDimensions, parametersMapping]
  );

  if (chipResolution) {
    if (isResolutionError(chipResolution)) {
      return (<WidgetInvalidConfiguration width={width} height={height} reason={chipResolution.error} />);
    } else {
      return (
        <ScrollableWidgetContainer asContent width={width} height={height}>
          <div className={classes.chipContainer}>
            <ChipView
              chipResolution={chipResolution}
              readOnly={resolvedViewDefinition.readOnly || Boolean(readOnly)}
              filterKey={viewFilters.filterKey}
              viewDimensions={viewDimensions}
              parametersMapping={parametersMapping}
            />
          </div>
        </ScrollableWidgetContainer>
      );
    }
  } else {
    return (<WidgetLoadingPlaceholder />);
  }
};

export default ChipViewWidget;
