export class InvalidFieldError extends Error {
  override readonly name = 'InvalidFieldError';

  readonly fieldId: string;

  constructor(fieldId: string, cause: Error) {
    super('Field is not valid');
    this.fieldId = fieldId;
    this.cause = cause;
  }
}
