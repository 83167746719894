import type { ReactElement } from 'react';
import { useState } from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryContainer, VictoryGroup, VictoryLine, VictoryStack, VictoryTooltip } from 'victory';
import type { PeriodicityType } from 'yooi-utils';
import { compareNumber, dateFormats, formatDisplayDate, isNumber, joinObjects, periodicities } from 'yooi-utils';
import { hexColorWithAlpha } from '../../../theme/colorUtils';
import { getFontStyle } from '../../../utils/fontUtils';
import { remToPx, SCROLLBAR_WIDTH_IN_REM } from '../../../utils/sizeUtils';
import useTheme from '../../../utils/useTheme';
import ChartLegend, { CHART_LEGEND_LINE_HEIGHT } from '../ChartLegend';
import type { ChartTooltipData, ColorStepsValue, Labels, SerieInfo, SerieInput, StackedRowInfo } from '../ChartTypes';
import { chartAxisStyle } from '../internal/chartStylesUtils';
import ChartTooltipForVictory from '../internal/ChartTooltipForVictory';
import DependentAxis from '../internal/DependentAxis';
import FlyoutWrapper from '../internal/FlyoutWrapper';
import NoLabel from '../NoLabel';
import BarChartXAxis from './BarChartXAxis';

interface Value {
  x: Date,
  y: number,
  time: number,
  value: number,
}

interface TemporalBarChartProps<ValueType extends Value> {
  minValue: ColorStepsValue,
  maxValue: ColorStepsValue,
  steps: ColorStepsValue[],
  periodicity?: PeriodicityType,
  series: SerieInput<ValueType>[],
  seriesStacked: boolean,
  xDomain: [number, number],
  yDomain: [number, number],
  height: number,
  width: number,
  renderTooltip: (tooltipData: ChartTooltipData<ValueType>) => ReactElement,
  labels: Labels[],
}

interface ChartSeriesRowValueComputed {
  x: string,
  y: number,
  color?: string,
  time: number,
  value: number,
}

const generateMinMaxLines = (
  minValue: ColorStepsValue,
  maxValue: ColorStepsValue,
  steps: ColorStepsValue[]
): { value: number, color: string | undefined }[] | [] => {
  const minMaxLines = [];

  if (isNumber(minValue.value)) {
    minMaxLines.push({ value: minValue.value, color: minValue.color });
  }
  if (steps && steps?.length > 0) {
    steps.forEach((step) => {
      if (isNumber(step.value)) {
        minMaxLines.push({ value: step.value, color: step.color });
      }
    });
  }
  if (isNumber(maxValue.value)) {
    minMaxLines.push({ value: maxValue.value, color: maxValue.color });
  }
  return minMaxLines;
};

const TemporalBarChart = <ValueType extends Value>({
  periodicity,
  series,
  seriesStacked,
  xDomain,
  yDomain,
  height,
  width,
  renderTooltip,
  minValue,
  maxValue,
  steps,
  labels: generatedLabels,
}: TemporalBarChartProps<ValueType>): ReactElement | null => {
  const theme = useTheme();

  const chartLeftPadding = remToPx(5);
  const charRightPadding = remToPx(5 - SCROLLBAR_WIDTH_IN_REM);
  const actualWidth = Math.max(0, width);
  const timePoint: number[] = [];
  series.forEach((s) => s.rows.forEach((r) => r.stackedRows.forEach((sr) => sr.values.forEach((v) => {
    if (!timePoint.includes(v.time) && v.time >= xDomain[0] && v.time <= xDomain[1]) {
      timePoint.push(v.time);
    }
  }))));
  timePoint.sort(compareNumber);

  const minMaxLines = generateMinMaxLines(minValue, maxValue, steps);

  const computeValues = (values: ValueType[]): ChartSeriesRowValueComputed[] => timePoint.filter((time) => time >= xDomain[0] && time <= xDomain[1])
    .map((time) => {
      const currentValue = values.find((v) => v.time === time) ?? { x: new Date(time), y: 0, time, value: 0 };
      return (joinObjects(currentValue, {
        x: formatDisplayDate(new Date(time), periodicity ? periodicities[periodicity].getFormatString() : dateFormats.timestamp),
      }));
    });
  const computedSeries = series.map((serie) => (joinObjects(
    serie,
    {
      rows: serie.rows.map((row) => (joinObjects(
        row,
        { stackedRows: row.stackedRows.map((stackedRow) => (joinObjects(stackedRow, { values: computeValues(stackedRow.values) }))) }
      ))),
    }
  )));
  const groupNumber = Math.max(1, !seriesStacked
    ? computedSeries.reduce((acc, { rows }) => (acc + rows.length), 0)
    : Math.max(...computedSeries.map(({ rows }) => rows.length)));
  const groupWidth = timePoint.length === 0 ? 0 : Math.max(0, Math.round((actualWidth - chartLeftPadding - charRightPadding) / timePoint.length));

  const axisXHeight = 5.5 - SCROLLBAR_WIDTH_IN_REM;
  const firstLegendLineHeightInRem = generatedLabels.length > 0 ? CHART_LEGEND_LINE_HEIGHT : 0;
  const svgHeight = height - remToPx(firstLegendLineHeightInRem);
  const xAxisY = svgHeight - remToPx(axisXHeight) + remToPx(0.1);

  const [displayKeyList, setDisplayKeyList] = useState<string[]>([]);

  const getOpacity = (key: string) => (displayKeyList.length === 0 || displayKeyList.includes(key) ? 1 : 0.2);

  const renderBar = (
    key: string,
    seriesInfo: SerieInfo,
    stackedRowInfo: StackedRowInfo,
    values: ChartSeriesRowValueComputed[],
    getTotalByTime: (time: number) => number,
    isLastBarSegment: (y: number, group?: number, stack?: number) => boolean
  ) => (
    <VictoryBar
      key={key}
      labels={() => seriesInfo.label}
      cornerRadius={{
        // eslint-disable-next-line no-underscore-dangle
        top: ({ datum }) => (isLastBarSegment(datum._y, datum._group, datum._stack) ? 4 : 0),
      }}
      labelComponent={(
        <VictoryTooltip
          pointerLength={0}
          constrainToVisibleArea
          flyoutComponent={(
            <FlyoutWrapper>
              {({ x, y, datum }) => (
                <ChartTooltipForVictory
                  chartWidth={actualWidth}
                  chartHeight={height}
                  renderTooltip={datum
                    ? () => renderTooltip({
                      seriesFieldId: seriesInfo.fieldId,
                      seriesLabel: seriesInfo.label,
                      dimensionsMapping: stackedRowInfo.dimensionsMapping,
                      datum: datum as ValueType,
                      totalValue: getTotalByTime((datum as ValueType).time),
                    })
                    : () => null}
                  x={x}
                  y={y}
                />
              )}
            </FlyoutWrapper>
          )}
          // We explicitly wants to avoid the label component
          labelComponent={<NoLabel />}
          style={joinObjects(
            getFontStyle(theme.font.small),
            // lineHeight not supported by VictoryTooltip
            { lineHeight: undefined }
          )}
        />
      )}
      data={values}
      name={stackedRowInfo.legendLabel}
      style={{
        data: {
          fill: ({ datum: { color: c } }) => c ?? stackedRowInfo.color ?? seriesInfo.color,
          opacity: getOpacity(stackedRowInfo.key),
        },
      }}
    />
  );

  const labels = generatedLabels.map(({ label, color, key }) => ({
    name: label,
    symbol: { fill: hexColorWithAlpha(color ?? theme.color.background.neutral.default, getOpacity(key)) },
    displayStroke: !color,
    key,
  }));

  const barRoundedCorners: Record<number, number> = {};
  const isLastBarSegment = (y: number, group = 0, stack = 0) => {
    if (y > 0 && !(group in barRoundedCorners)) {
      barRoundedCorners[group] = stack;
    }
    return (group in barRoundedCorners && stack === barRoundedCorners[group]);
  };

  return (
    <>
      <VictoryChart
        // using height as key to prevent flickering when resizing (Victory renders bars AFTER svg)
        key={svgHeight}
        height={svgHeight}
        width={actualWidth}
        minDomain={{ y: yDomain[0] }}
        maxDomain={{ y: yDomain[1] }}
        // domain prevent very small y axis value when there is no y value for every data in the chart
        domainPadding={{ x: Math.round(groupWidth / 2) }}
        padding={{ top: remToPx(2.5), bottom: remToPx(axisXHeight), left: chartLeftPadding, right: charRightPadding }}
        containerComponent={(
          <VictoryContainer
            preserveAspectRatio="xMinYMin meet"
            responsive={false}
          />
        )}
      >
        <VictoryAxis
          // prevent x axis value when there is no x value
          tickFormat={undefined}
          style={chartAxisStyle(theme)}
          tickLabelComponent={(
            <BarChartXAxis
              y={xAxisY}
              axisHeightInRem={axisXHeight}
              xAxisLabelAngle={35}
            />
          )}
        />
        <DependentAxis />
        {seriesStacked && computedSeries.some(({ rows }) => rows.some(({ stackedRows }) => stackedRows.length > 0)) && (
          <VictoryGroup offset={Math.round(groupWidth / groupNumber)}>
            {computedSeries[0].rows.map(({ info: { key: stackKey } }, rowIndex) => (
              <VictoryStack key={stackKey}>
                {timePoint.length > 0 && (
                  computedSeries.flatMap(({ rows, info: seriesInfo }) => rows[rowIndex].stackedRows
                    .map(({ info: stackedRowInfo, values }) => renderBar(
                      stackedRowInfo.key,
                      seriesInfo,
                      stackedRowInfo,
                      values,
                      (time) => computedSeries.reduce((acc, { rows: reducedRow }) => acc + reducedRow[rowIndex].stackedRows
                        .reduce((stackedAcc, sRow) => stackedAcc + (sRow.values.find(({ time: stackedTime }) => stackedTime === time)?.value ?? 0), 0), 0),
                      isLastBarSegment
                    )))
                )}
              </VictoryStack>
            ))}
          </VictoryGroup>
        )}
        {!seriesStacked && computedSeries.some(({ rows }) => rows.some(({ stackedRows }) => stackedRows.length > 0)) && (
          <VictoryGroup offset={Math.round(groupWidth / groupNumber)}>
            {computedSeries.flatMap(({ key: seriesKey, info: seriesInfo, rows }) => rows
              .map(({ stackedRows }) => (
                <VictoryStack key={seriesKey}>
                  {timePoint.length > 0 && (
                    stackedRows.map(({ key, info: stackedRowInfo, values }) => (
                      renderBar(
                        key,
                        seriesInfo,
                        stackedRowInfo,
                        values,
                        (time) => stackedRows
                          .reduce((stackedAcc, sRow) => stackedAcc + (sRow.values.find(({ time: stackedTime }) => stackedTime === time)?.value ?? 0), 0),
                        isLastBarSegment
                      )
                    )))}
                </VictoryStack>
              )))}
          </VictoryGroup>
        )}
        {minMaxLines.slice(0).reverse().map(({ color, value: lineValue }, index) => {
          const key = index;
          return (
            <VictoryLine
              key={key}
              style={{
                data: { stroke: color },
              }}
              y={() => lineValue}
            />
          );
        })}
      </VictoryChart>
      {labels.length > 0 && (
        <ChartLegend
          labels={labels}
          onLabelClick={(selectedKey) => {
            setDisplayKeyList((current) => {
              if (current.includes(selectedKey)) {
                return current.filter((key) => key !== selectedKey);
              } else {
                return current.length === computedSeries.flatMap(({ rows }) => rows.flatMap(({ stackedRows }) => stackedRows)).length - 1 ? [] : [...current, selectedKey];
              }
            });
          }}
        />
      )}
    </>
  );
};

export default TemporalBarChart;
