import { DateTime } from 'luxon';
import type { DateFieldStoreValue, DateRange, FormulaType } from 'yooi-utils';
import { anyType, computeEffectiveRangeForPeriodAndDates, isDateFieldStoreValue, newError, numberType, periodicities, textType } from 'yooi-utils';
import { newFunctionLibrary } from 'yooi-utils/src/formula2/engine/functionLibraryBuilder';
import { checkArgumentCount, checkArgumentType, checkFlattenType, checks, flattenForEach } from 'yooi-utils/src/formula2/functionLibrary/utils';

export const dateType: FormulaType = {
  name: 'date',
  equals: (type) => type === dateType,
  isAssignableFrom: (type) => type === dateType || type === anyType,
};

export const dateRangeType: FormulaType = {
  name: 'dateRange',
  equals: (type) => type === dateRangeType,
  isAssignableFrom: (type) => type === dateRangeType || type === anyType,
};

const dateMapper: { type: FormulaType, jsFunction: CallableFunction, transpile?: (transpiledArg: string) => string } = {
  type: numberType,
  jsFunction: (arg: DateFieldStoreValue | undefined) => arg?.date,
  transpile: (arg) => `(${arg})?.date`,
};

const d1900 = -2208988800000; // 01/01/1900
const d190003 = -2203891200000; // 01/03/1900

// Copied from fast-formula-parser
const WEEK_TYPES = [
  undefined,
  [1, 2, 3, 4, 5, 6, 7],
  [7, 1, 2, 3, 4, 5, 6],
  [6, 0, 1, 2, 3, 4, 5],
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  [7, 1, 2, 3, 4, 5, 6],
  [6, 7, 1, 2, 3, 4, 5],
  [5, 6, 7, 1, 2, 3, 4],
  [4, 5, 6, 7, 1, 2, 3],
  [3, 4, 5, 6, 7, 1, 2],
  [2, 3, 4, 5, 6, 7, 1],
  [1, 2, 3, 4, 5, 6, 7],
];

const parseDate = (functionName: string, date: number | string | DateFieldStoreValue): number => {
  if (typeof date === 'number') {
    return date;
  } else if (typeof date === 'string') {
    const dateValue = Date.parse(date);
    // eslint-disable-next-line yooi/number-is-nan-call
    if (Number.isNaN(dateValue)) {
      throw newError(`${functionName} unable to parse date`);
    } else {
      return dateValue;
    }
  } else {
    return date.date;
  }
};

const getSerial = (functionName: string, timestamp: number): number => {
  const addOn = (timestamp > d190003) ? 2 : 1;
  const serial = Math.floor((timestamp - d1900) / 86400000) + addOn;
  if (serial < 0 || serial > 2958465) {
    throw newError(`${functionName} invalid serial`);
  } else {
    return serial;
  }
};

const serialToTimestamp = (serial: number): number => (d1900 + (serial - (serial <= 60 ? 1 : 2)) * 86400000);

export const dateFunctions = newFunctionLibrary()
  .addFunction(
    '+',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 0),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 1)
        ) ?? argTypes.map((type) => (dateType.isAssignableFrom(type) ? dateMapper : { type }))
      ),
    })
  )
  .addFunction(
    '-',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 0),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 1)
        ) ?? argTypes.map((type) => (dateType.isAssignableFrom(type) ? dateMapper : { type }))
      ),
    })
  )
  .addFunction(
    '/',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 0),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 1)
        ) ?? argTypes.map((type) => (dateType.isAssignableFrom(type) ? dateMapper : { type }))
      ),
    })
  )
  .addFunction(
    '=',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 0),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 1)
        ) ?? argTypes.map((type) => (dateType.isAssignableFrom(type) ? dateMapper : { type }))
      ),
    })
  )
  .addFunction(
    '>',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 0),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 1)
        ) ?? argTypes.map((type) => (dateType.isAssignableFrom(type) ? dateMapper : { type }))
      ),
    })
  )
  .addFunction(
    '>=',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 0),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 1)
        ) ?? argTypes.map((type) => (dateType.isAssignableFrom(type) ? dateMapper : { type }))
      ),
    })
  )
  .addFunction(
    '<',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 0),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 1)
        ) ?? argTypes.map((type) => (dateType.isAssignableFrom(type) ? dateMapper : { type }))
      ),
    })
  )
  .addFunction(
    '<=',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 0),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 1)
        ) ?? argTypes.map((type) => (dateType.isAssignableFrom(type) ? dateMapper : { type }))
      ),
    })
  )
  .addFunction(
    '<>',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 0),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 1)
        ) ?? argTypes.map((type) => (dateType.isAssignableFrom(type) ? dateMapper : { type }))
      ),
    })
  )
  .addFunction(
    'DATECONVERT',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, [numberType, textType, dateType], argTypes, 0),
          () => checkArgumentType(name, textType, argTypes, 1)
        ) ?? {
          type: numberType,
          jsFunction: (date: number | string | DateFieldStoreValue | undefined, periodicity: string | undefined) => {
            if (date === undefined) {
              return undefined;
            } else {
              const timestamp = parseDate(name, date);
              if (periodicity === undefined || !Object.keys(periodicities).includes(periodicity.toLowerCase())) {
                throw newError(`${name} has invalid periodicity`);
              } else {
                return periodicities[periodicity.toLowerCase() as keyof typeof periodicities].getStartOfPeriod(new Date(timestamp)).getTime();
              }
            }
          },
        }
      ),
    })
  )
  .addFunction(
    'DATEDIF',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 3, 3),
          () => checkArgumentType(name, [numberType, textType, dateType], argTypes, 0),
          () => checkArgumentType(name, [numberType, textType, dateType], argTypes, 1),
          () => checkArgumentType(name, textType, argTypes, 2)
        ) ?? {
          type: numberType,
          jsFunction: (start: number | string | DateFieldStoreValue | undefined, end: number | string | DateFieldStoreValue | undefined, rawUnit: string | undefined) => {
            const startSerial = start === undefined || typeof start === 'number' ? start : getSerial(name, parseDate(name, start));
            if (startSerial === undefined) {
              return undefined;
            }
            const endSerial = end === undefined || typeof end === 'number' ? end : getSerial(name, parseDate(name, end));
            if (endSerial === undefined) {
              return undefined;
            }

            if (startSerial > endSerial) {
              throw newError('DATEDIF start is after end');
            }

            const unit = rawUnit?.toLowerCase();

            const startDate = new Date(serialToTimestamp(startSerial));
            const endDate = new Date(serialToTimestamp(endSerial));

            const yearDiff = endDate.getUTCFullYear() - startDate.getUTCFullYear();
            const monthDiff = endDate.getUTCMonth() - startDate.getUTCMonth();
            const dayDiff = endDate.getUTCDate() - startDate.getUTCDate();

            switch (unit) {
              case 'y': {
                const offset = (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) ? -1 : 0;
                return offset + yearDiff;
              }
              case 'm': {
                const offset = dayDiff < 0 ? -1 : 0;
                return yearDiff * 12 + monthDiff + offset;
              }
              case 'd':
                return Math.floor(endDate.getTime() - startDate.getTime()) / 86400000;
              case 'md':
                // The months and years of the dates are ignored.
                startDate.setUTCFullYear(endDate.getUTCFullYear());
                if (dayDiff < 0) {
                  startDate.setUTCMonth(endDate.getUTCMonth() - 1);
                } else {
                  startDate.setUTCMonth(endDate.getUTCMonth());
                }
                return Math.floor(endDate.getTime() - startDate.getTime()) / 86400000;
              case 'ym': {
                // The days and years of the dates are ignored
                const offset = dayDiff < 0 ? -1 : 0;
                return (offset + yearDiff * 12 + monthDiff) % 12;
              }
              case 'yd':
                // The years of the dates are ignored.
                if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
                  startDate.setUTCFullYear(endDate.getUTCFullYear() - 1);
                } else {
                  startDate.setUTCFullYear(endDate.getUTCFullYear());
                }
                return Math.floor(endDate.getTime() - startDate.getTime()) / 86400000;
              default:
                throw newError('DATEDIF invalid unit provided');
            }
          },
        }
      ),
    })
  )
  .addFunction(
    'DATESTR',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 0)
        ) ?? {
          type: textType,
          jsFunction: (date: number | DateFieldStoreValue | undefined) => {
            if (date === undefined) {
              return undefined;
            } else {
              return DateTime.fromMillis(typeof date === 'number' ? date : date.date).toFormat('yyyy/MM/dd HH:mm:ss');
            }
          },
        }
      ),
    })
  )
  .addFunction(
    'DATEVALUE',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, [numberType, textType, dateType], argTypes, 0)
        ) ?? {
          type: numberType,
          jsFunction: (date: number | string | DateFieldStoreValue | undefined): number | undefined => {
            if (date === undefined) {
              return undefined;
            } else {
              return getSerial(name, parseDate(name, date));
            }
          },
        }
      ),
    })
  )
  .addFunction(
    'DAY',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, [numberType, textType, dateType], argTypes, 0)
        ) ?? {
          type: numberType,
          jsFunction: (date: number | string | DateFieldStoreValue | undefined) => {
            if (date === undefined) {
              return undefined;
            } else if (isDateFieldStoreValue(date)) {
              return periodicities[date.period].getStartOfPeriod(new Date(date.date)).getDate();
            } else {
              const timestamp = parseDate(name, date);
              return new Date(timestamp).getDate();
            }
          },
        }
      ),
    })
  )
  .addFunction(
    'DAYS',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, [numberType, textType, dateType], argTypes, 0),
          () => checkArgumentType(name, [numberType, textType, dateType], argTypes, 1)
        ) ?? {
          type: numberType,
          jsFunction: (start: number | string | DateFieldStoreValue | undefined, end: number | string | DateFieldStoreValue | undefined) => {
            const startSerial = start === undefined || typeof start === 'number' ? start : getSerial(name, parseDate(name, start));
            if (startSerial === undefined) {
              return undefined;
            }
            const endSerial = end === undefined || typeof end === 'number' ? end : getSerial(name, parseDate(name, end));
            if (endSerial === undefined) {
              return undefined;
            }

            const startTimestamp = serialToTimestamp(startSerial);
            const endTimestamp = serialToTimestamp(endSerial);

            let offset = 0;
            if (startTimestamp < d190003 && endTimestamp > d190003) {
              offset = 1;
            }
            return Math.floor(endTimestamp - startTimestamp) / 86400000 + offset;
          },
        }
      ),
    })
  )
  .addFunction(
    'EARLIESTAFTER',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1),
          () => checkFlattenType(name, [numberType, textType, dateType, dateRangeType], argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (...args: unknown[]) => {
            const timestamps: number[] = [];
            flattenForEach(args, (date: number | string | DateFieldStoreValue | DateRange | undefined) => {
              if (date === undefined) {
                // Skip
              } else if (typeof date === 'number') {
                timestamps.push(date);
              } else if (typeof date === 'string') {
                const timestamp = parseDate(name, date);
                if (timestamp !== undefined) {
                  timestamps.push(timestamp);
                }
              } else if (isDateFieldStoreValue(date)) {
                timestamps.push(date.date);
              } else if (date.to?.value !== undefined || date.from?.value !== undefined) {
                const { to } = computeEffectiveRangeForPeriodAndDates(date.period, date.from, date.to);
                if (to !== undefined) {
                  timestamps.push(to.getTime());
                }
              }
            });

            const maxTimestamp = timestamps.reduce<number | undefined>(
              (previousValue, currentValue) => (previousValue === undefined || previousValue < currentValue ? currentValue : previousValue),
              undefined
            );

            if (maxTimestamp === undefined) {
              return undefined;
            } else {
              return periodicities.day.getPreviousDateInAmountOfPeriod(new Date(maxTimestamp), -1).getTime();
            }
          },
        }
      ),
    })
  )
  .addFunction(
    'MAX',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1),
          () => checkFlattenType(name, [numberType, textType, dateType, dateRangeType], argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (...args: unknown[]) => {
            let max: number | undefined;
            flattenForEach(args, (value: number | string | DateFieldStoreValue | DateRange | undefined) => {
              if (value === undefined) {
                // Skip
              } else if (typeof value === 'number') {
                if (max === undefined || value > max) {
                  max = value;
                }
              } else if (typeof value === 'string') {
                const timestamp = parseDate(name, value);
                if (max === undefined || timestamp > max) {
                  max = timestamp;
                }
              } else if (isDateFieldStoreValue(value)) {
                if (max === undefined || value.date > max) {
                  max = value.date;
                }
              } else if (value.to?.value !== undefined) {
                if (max === undefined || value.to.value > max) {
                  max = value.to.value;
                }
              }
            });

            return max;
          },
        }),
    })
  )
  .addFunction(
    'MIN',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1),
          () => checkFlattenType(name, [numberType, textType, dateType, dateRangeType], argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (...args: unknown[]) => {
            let min: number | undefined;
            flattenForEach(args, (value: number | string | DateFieldStoreValue | DateRange | undefined) => {
              if (value === undefined) {
                // Skip
              } else if (typeof value === 'number') {
                if (min === undefined || value < min) {
                  min = value;
                }
              } else if (typeof value === 'string') {
                const timestamp = parseDate(name, value);
                if (min === undefined || timestamp < min) {
                  min = timestamp;
                }
              } else if (isDateFieldStoreValue(value)) {
                if (min === undefined || value.date < min) {
                  min = value.date;
                }
              } else if (value.from?.value !== undefined) {
                if (min === undefined || value.from.value < min) {
                  min = value.from.value;
                }
              }
            });

            return min;
          },
        }),
    })
  )
  .addFunction(
    'MONTH',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, [numberType, textType, dateType], argTypes, 0)
        ) ?? {
          type: numberType,
          jsFunction: (date: number | string | DateFieldStoreValue | undefined) => {
            if (date === undefined) {
              return undefined;
            } else if (isDateFieldStoreValue(date)) {
              return periodicities[date.period].getStartOfPeriod(new Date(date.date)).getMonth() + 1;
            } else {
              const timestamp = parseDate(name, date);
              return new Date(timestamp).getMonth() + 1;
            }
          },
        }
      ),
    })
  )
  .addFunction(
    'TODAY',
    (name) => ({
      isDeterminist: false,
      resolve: (argTypes) => (
        checkArgumentCount(name, argTypes, 0, 0) ?? {
          type: numberType,
          jsFunction: () => getSerial(name, Date.now()),
        }
      ),
    })
  )
  .addFunction(
    'WEEK',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, [numberType, textType, dateType], argTypes, 0)
        ) ?? {
          type: numberType,
          jsFunction: (date: number | string | DateFieldStoreValue | undefined) => {
            if (date === undefined) {
              return undefined;
            } else if (isDateFieldStoreValue(date)) {
              return DateTime.fromJSDate(periodicities[date.period].getStartOfPeriod(new Date(date.date))).weekNumber;
            } else {
              const timestamp = parseDate(name, date);
              return DateTime.fromJSDate(new Date(timestamp)).weekNumber;
            }
          },
        }
      ),
    })
  )
  .addFunction(
    'WEEKDAY',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 2),
          () => checkArgumentType(name, [numberType, textType, dateType], argTypes, 0),
          () => (argTypes.length < 2 ? undefined : checkArgumentType(name, numberType, argTypes, 1))
        ) ?? {
          type: numberType,
          jsFunction: (date: number | string | DateFieldStoreValue | undefined, weekType: number | undefined = 1) => {
            if (date === undefined) {
              return undefined;
            } else {
              const weekTypes = WEEK_TYPES[weekType];
              if (!weekTypes) {
                throw newError(`${name} has invalid weekType`);
              } else if (isDateFieldStoreValue(date)) {
                return weekTypes[periodicities[date.period].getStartOfPeriod(new Date(date.date)).getDay()];
              } else {
                const timestamp = parseDate(name, date);
                return weekTypes[new Date(timestamp).getDay()];
              }
            }
          },
        }
      ),
    })
  )
  .addFunction(
    'YEAR',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, [numberType, textType, dateType], argTypes, 0)
        ) ?? {
          type: numberType,
          jsFunction: (date: number | string | DateFieldStoreValue | undefined) => {
            if (date === undefined) {
              return undefined;
            } else if (isDateFieldStoreValue(date)) {
              return periodicities[date.period].getStartOfPeriod(new Date(date.date)).getFullYear();
            } else {
              const timestamp = parseDate(name, date);
              return new Date(timestamp).getFullYear();
            }
          },
        }
      ),
    })
  )
  .build();
