import { Field_Documentation, Field_Title } from 'yooi-modules/modules/conceptModule/ids';
import type { CardFieldRaw, CardFieldStoreObject } from 'yooi-modules/modules/dashboardModule';
import { cardFieldHandler } from 'yooi-modules/modules/dashboardModule';
import type { CardFieldButton } from 'yooi-modules/modules/dashboardModule/fields/cardField';
import {
  CardField_Button, CardField_ButtonPositionX, CardField_ButtonPositionY,
  CardField_Image,
  CardField_ImageHeight,
  CardField_ImageMode,
  CardField_ImagePositionX,
  CardField_ImagePositionY,
  CardField_Subtitle,
  CardField_Text,
} from 'yooi-modules/modules/dashboardModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { CoverHeight, CoverMode } from 'yooi-utils';
import { CoverPositionX, CoverPositionY } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import i18n from '../../../../utils/i18n';
import StoreCoverPicker from '../../input/StoreCoverPicker';
import type { OptionRecord } from '../../modelTypeUtils';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode } from '../FieldLibraryTypes';
import CardFieldButtonInput from './CardFieldButtonInput';
import CardFieldWidget from './CardFieldWidget';

interface CardFieldConfigurationState {
  [CardField_Image]: { type: 'url', url: string } | { type: 'buffer', data: ArrayBuffer, contentType: string } | null | undefined,
  [CardField_ImageMode]: CoverMode | null | undefined,
  [CardField_ImagePositionX]: CoverPositionX | null | undefined,
  [CardField_ImagePositionY]: CoverPositionY | null | undefined,
  [CardField_ImageHeight]: CoverHeight | number | null | undefined,
  [CardField_Subtitle]: string | null | undefined,
  [CardField_Text]: string | null | undefined,
  [CardField_Button]: CardFieldButton[] | null | undefined,
  [CardField_ButtonPositionX]: CoverPositionX | null | undefined,
  [CardField_ButtonPositionY]: CoverPositionY | null | undefined,
}

type CardFieldDefinition = GetFieldDefinitionHandler<typeof cardFieldHandler, CardFieldConfigurationState>;

export const cardFieldDefinition: CardFieldDefinition = registerFieldDefinition(cardFieldHandler, {
  configuration: {
    typeIcon: IconName.id_card_outline,
    getTypeLabel: () => i18n`Card`,
    asWidget: true,
    getEditionOptions: () => ({ editionHandler, mode }) => {
      const xOptions: OptionRecord<CoverPositionX> = {
        [CoverPositionX.Left]: { id: CoverPositionX.Left, label: i18n`Left` },
        [CoverPositionX.Center]: { id: CoverPositionX.Center, label: i18n`Center` },
        [CoverPositionX.Right]: { id: CoverPositionX.Right, label: i18n`Right` },
      };
      const yOptions: OptionRecord<CoverPositionY> = {
        [CoverPositionY.Top]: { id: CoverPositionY.Top, label: i18n`Top` },
        [CoverPositionY.Center]: { id: CoverPositionY.Center, label: i18n`Center` },
        [CoverPositionY.Bottom]: { id: CoverPositionY.Bottom, label: i18n`Bottom` },
      };

      return (
        mode !== FieldEditionOptionMode.Widget
          ? []
          : [
            {
              key: 'display',
              type: 'section',
              title: i18n`Display`,
              options: [
                {
                  key: CardField_Image,
                  title: i18n`Image`,
                  hasValue: () => editionHandler.getValue(CardField_Image) !== undefined,
                  clearValue: () => editionHandler.updateValues({ [CardField_Image]: null }),
                  type: EditionOptionTypes.custom,
                  props: {
                    render: () => {
                      const image = editionHandler.getValueOrDefault(CardField_Image);

                      return (
                        <StoreCoverPicker
                          initialValue={image ? {
                            image,
                            mode: editionHandler.getValueOrDefault(CardField_ImageMode),
                            positionX: editionHandler.getValueOrDefault(CardField_ImagePositionX),
                            positionY: editionHandler.getValueOrDefault(CardField_ImagePositionY),
                            height: editionHandler.getValueOrDefault(CardField_ImageHeight),
                          } : undefined}
                          onSubmit={(newValue) => {
                            if (newValue) {
                              editionHandler.updateValues({
                                [CardField_Image]: newValue.image,
                                [CardField_ImageMode]: newValue.mode,
                                [CardField_ImagePositionX]: newValue.positionX,
                                [CardField_ImagePositionY]: newValue.positionY,
                                [CardField_ImageHeight]: newValue.height,
                              });
                            } else {
                              editionHandler.updateValues({
                                [CardField_Image]: null,
                                [CardField_ImageMode]: null,
                                [CardField_ImagePositionX]: null,
                                [CardField_ImagePositionY]: null,
                                [CardField_ImageHeight]: null,
                              });
                            }
                          }}
                        />
                      );
                    },
                  },
                },
                {
                  key: CardField_Subtitle,
                  title: i18n`Subtitle`,
                  hasValue: () => editionHandler.getValue(CardField_Subtitle) !== undefined,
                  clearValue: () => editionHandler.updateValues({ [CardField_Subtitle]: null }),
                  type: EditionOptionTypes.text,
                  props: {
                    placeholder: i18n`Add subtitle`,
                    value: editionHandler.getValueOrDefault(CardField_Subtitle),
                    onChange: (value) => editionHandler.updateValues({ [CardField_Subtitle]: value }),
                  },
                },
                {
                  key: CardField_Text,
                  title: i18n`Text`,
                  hasValue: () => editionHandler.getValue(CardField_Text) !== undefined,
                  clearValue: () => editionHandler.updateValues({ [CardField_Text]: null }),
                  type: EditionOptionTypes.text,
                  props: {
                    placeholder: i18n`Add text`,
                    value: editionHandler.getValueOrDefault(CardField_Text),
                    onChange: (value) => editionHandler.updateValues({ [CardField_Text]: value }),
                  },
                },
                {
                  key: CardField_Button,
                  title: i18n`Button`,
                  hasValue: () => editionHandler.getValue(CardField_Button) !== undefined,
                  clearValue: () => editionHandler.updateValues({ [CardField_Button]: null }),
                  isVertical: true,
                  padded: true,
                  type: EditionOptionTypes.custom,
                  props: {
                    render: () => (
                      <CardFieldButtonInput
                        initialValue={editionHandler.getValueOrDefault(CardField_Button)}
                        onUpdate={(newAction) => {
                          editionHandler.updateValues({ [CardField_Button]: newAction });
                        }}
                      />
                    ),
                  },
                },
                {
                  key: CardField_ButtonPositionY,
                  title: i18n`Buttons vertical position`,
                  hasValue: () => editionHandler.getValue(CardField_ButtonPositionY) !== undefined,
                  clearValue: () => editionHandler.updateValues({ [CardField_ButtonPositionY]: undefined }),
                  type: EditionOptionTypes.select,
                  props: {
                    clearable: false,
                    selectedOption: editionHandler.getValue(CardField_ButtonPositionY)
                      ? yOptions[editionHandler.getValue(CardField_ButtonPositionY) as CoverPositionY] : yOptions[CoverPositionY.Bottom],
                    computeOptions: () => Object.values(yOptions),
                    onChange: (value) => editionHandler.updateValues({ [CardField_ButtonPositionY]: value?.id as CoverPositionY | null | undefined }),
                  },
                },
                {
                  key: CardField_ButtonPositionX,
                  title: i18n`Buttons horizontal position`,
                  hasValue: () => editionHandler.getValue(CardField_ButtonPositionX) !== undefined,
                  clearValue: () => editionHandler.updateValues({ [CardField_ButtonPositionX]: undefined }),
                  type: EditionOptionTypes.select,
                  props: {
                    clearable: false,
                    selectedOption: editionHandler.getValue(CardField_ButtonPositionX)
                      ? xOptions[editionHandler.getValue(CardField_ButtonPositionX) as CoverPositionX] : xOptions[CoverPositionX.Left],
                    computeOptions: () => Object.values(xOptions),
                    onChange: (value) => editionHandler.updateValues({ [CardField_ButtonPositionX]: value?.id as CoverPositionX | null | undefined }),
                  },
                },
              ],
            },
          ]
      );
    },
    ofField: (objectStore, fieldId) => ({
      getInitialState: () => {
        const field = objectStore.getObject<CardFieldStoreObject>(fieldId);

        const imageUrl = field[CardField_Image] ? objectStore.getAttachmentUrl(fieldId, CardField_Image)(field[CardField_Image], true) : undefined;
        return {
          [CardField_Image]: imageUrl ? { type: 'url', url: imageUrl } : undefined,
          [CardField_ImageMode]: field[CardField_ImageMode],
          [CardField_ImagePositionX]: field[CardField_ImagePositionX],
          [CardField_ImagePositionY]: field[CardField_ImagePositionY],
          [CardField_ImageHeight]: field[CardField_ImageHeight],
          [CardField_Subtitle]: field[CardField_Subtitle],
          [CardField_Text]: field[CardField_Text],
          [CardField_Button]: field[CardField_Button],
          [CardField_ButtonPositionX]: field[CardField_ButtonPositionX],
          [CardField_ButtonPositionY]: field[CardField_ButtonPositionY],
        };
      },
      submitFieldUpdate: (stateToSubmit) => {
        const image = stateToSubmit[CardField_Image];
        if (image) {
          if (image.type === 'buffer') {
            objectStore.uploadAttachment(
              {
                objectId: fieldId,
                propertyId: CardField_Image,
                contentType: image.contentType,
                data: image.data,
              },
              (revisionId) => {
                objectStore.updateObject(fieldId, {
                  [CardField_Image]: revisionId,
                });
              }
            );
          }
          objectStore.updateObject<CardFieldRaw>(fieldId, {
            [CardField_ImageMode]: stateToSubmit[CardField_ImageMode],
            [CardField_ImagePositionX]: stateToSubmit[CardField_ImagePositionX],
            [CardField_ImagePositionY]: stateToSubmit[CardField_ImagePositionY],
            [CardField_ImageHeight]: stateToSubmit[CardField_ImageHeight],
          });
        } else {
          objectStore.updateObject<CardFieldRaw>(fieldId, { [CardField_Image]: null });
        }
        objectStore.updateObject<CardFieldRaw>(fieldId, {
          [CardField_Subtitle]: stateToSubmit[CardField_Subtitle],
          [CardField_Text]: stateToSubmit[CardField_Text],
          [CardField_Button]: stateToSubmit[CardField_Button],
          [CardField_ButtonPositionX]: stateToSubmit[CardField_ButtonPositionX],
          [CardField_ButtonPositionY]: stateToSubmit[CardField_ButtonPositionY],
        });
      },
      duplicateFieldDefinition: () => {
        const field = objectStore.getObject(fieldId);
        const newFieldId = objectStore.createObject({
          [Instance_Of]: field[Instance_Of],
          [Field_Title]: `${field[Field_Title]} (copy)`,
          [Field_Documentation]: field[Field_Documentation],
          [CardField_Subtitle]: field[CardField_Subtitle],
          [CardField_Text]: field[CardField_Text],
          [CardField_Button]: field[CardField_Button],
          [CardField_ImageMode]: field[CardField_ImageMode],
          [CardField_ImagePositionX]: field[CardField_ImagePositionX],
          [CardField_ImagePositionY]: field[CardField_ImagePositionY],
          [CardField_ImageHeight]: field[CardField_ImageHeight],
          [CardField_ButtonPositionX]: field[CardField_ButtonPositionX],
          [CardField_ButtonPositionY]: field[CardField_ButtonPositionY],
        });

        if (field[CardField_Image]) {
          objectStore.cloneAttachment(
            {
              objectId: newFieldId,
              propertyId: CardField_Image,
              originObjectId: fieldId,
              originPropertyId: CardField_Image,
              originRevisionId: field[CardField_Image] as string,
            },
            (revisionId) => {
              objectStore.updateObject(newFieldId, {
                [CardField_Image]: revisionId,
              });
            }
          );
        }

        return newFieldId;
      },
    }),
  },
  renderWidget: (_, fieldId) => () => (<CardFieldWidget fieldId={fieldId} />),
});
