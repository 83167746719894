import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import type { BooleanFieldStoreObject, BooleanFieldValue, DimensionsMapping } from 'yooi-modules/modules/conceptModule';
import { booleanFieldHandler, canWrite, hasPlatformCapability, isEmbeddedAsIntegrationOnly, ParsedDimensionType, parseDimensionMapping } from 'yooi-modules/modules/conceptModule';
import { BooleanField_FalseValue, BooleanField_TrueValue, PlatformCapabilityAdmin, User_IsEnabled } from 'yooi-modules/modules/conceptModule/ids';
import { joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName, IconSizeVariant } from '../../../../components/atoms/Icon';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { darken } from '../../../../theme/colorUtils';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import useDerivedState from '../../../../utils/useDerivedState';
import useHideOnDisappearRef from '../../../../utils/useHideOnDisappearRef';
import useSizeContext, { buildInputSizeVariantMinusBorderClasses, SizeVariant } from '../../../../utils/useSizeContext';
import { useTooltip } from '../../../../utils/useTooltip';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';
import { formatFieldResolutionErrorForUser } from '../../errorUtils';
import { getLastUpdateBy } from '../../historyUtils';

const useStyles = makeStyles((theme) => (
  joinObjects(
    {
      baseContainer: {
        display: 'flex',
        alignItems: 'center',
        height: spacingRem.iconSize,
        minWidth: spacingRem.iconSize,
      },
      hovered: {
        cursor: 'pointer',
      },
      inTable: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
      },
      notInTable: {
        maxWidth: spacingRem.iconSize,
        borderWidth: '0.1rem',
        borderStyle: 'solid',
        borderColor: theme.color.transparent,
      },
      container: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '0.8rem',
      },
    },
    buildInputSizeVariantMinusBorderClasses('valueContainer', ['minHeight'])
  )
), 'booleanFieldRenderer');

interface BooleanFieldRendererProps {
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
  readOnly: boolean,
}

const BooleanFieldRenderer: FunctionComponent<BooleanFieldRendererProps> = ({ fieldId, dimensionsMapping, readOnly }) => {
  const classes = useStyles();

  const store = useStore();
  const { loggedUserId } = useAuth();
  const { canWriteObject } = useAcl();

  const { displayTooltip, hideTooltip } = useTooltip();
  const usageVariant = useUsageContext();
  const { sizeVariant } = useSizeContext();

  const [isIconHovered, setIsIconHovered] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { monitorRef } = useHideOnDisappearRef<HTMLDivElement>(false);

  const fieldHandler = booleanFieldHandler(store, fieldId);
  const parsedDimension = parseDimensionMapping(dimensionsMapping);
  const configuration = fieldHandler.resolveConfiguration();

  const isReadOnly = readOnly
    || configuration.integrationOnly
    || Boolean(configuration.formula)
    || !canWrite(dimensionsMapping, canWriteObject)
    || (parsedDimension.type === ParsedDimensionType.MonoDimensional && isEmbeddedAsIntegrationOnly(store.getObject(parsedDimension.objectId)))
    // Special case for User_IsEnabled that is currently protected by a business rule
    || (fieldId === User_IsEnabled && (
      parsedDimension.type === ParsedDimensionType.MultiDimensional
      || parsedDimension.objectId === loggedUserId
      || !hasPlatformCapability(store, store.getLoggedUserId(), PlatformCapabilityAdmin)
    ));

  const { icon: trueIcon, color: trueColor, tooltip: trueTooltip } = store.getObject<BooleanFieldStoreObject>(fieldId)[BooleanField_TrueValue] as BooleanFieldValue;
  const { icon: falseIcon, color: falseColor, tooltip: falseTooltip } = store.getObject<BooleanFieldStoreObject>(fieldId)[BooleanField_FalseValue] as BooleanFieldValue;
  const darkenedFalseColor = falseColor ? darken(falseColor, 10) : undefined;
  const darkenedTrueColor = trueColor ? darken(trueColor, 10) : undefined;

  const valueResolution = fieldHandler.getValueResolution(dimensionsMapping);
  const error = valueResolution.error ? formatFieldResolutionErrorForUser(store, valueResolution.error, fieldId) : undefined;

  const displayedIcon = valueResolution.value ? trueIcon as IconName : falseIcon as IconName;
  const [displayedColor, setDisplayedColor] = useDerivedState(() => (valueResolution.value ? trueColor : falseColor), [valueResolution.value, trueColor, falseColor]);
  const booleanTooltip = (valueResolution.value ? trueTooltip : falseTooltip) || i18n`Change state`;
  const historyTooltip = parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => getLastUpdateBy(store, parsedDimension.objectId, fieldId, undefined) : undefined;

  const getIconColor = (): string | undefined => {
    if (!valueResolution.value) {
      if (isIconHovered) {
        return darkenedFalseColor || falseColor;
      } else {
        return falseColor;
      }
    } else {
      if (isIconHovered) {
        return darkenedTrueColor || trueColor;
      }
      return trueColor;
    }
  };

  const onMouseOver = () => {
    if (!isReadOnly) {
      setIsIconHovered(true);
      setDisplayedColor(getIconColor());
      if (containerRef.current) {
        if (historyTooltip) {
          displayTooltip({
            element: containerRef.current,
            text: historyTooltip,
            prefix: `${booleanTooltip}\n\n`,
          });
        } else {
          displayTooltip({
            element: containerRef.current,
            text: booleanTooltip,
          });
        }
      }
    }
  };

  const onMouseOut = () => {
    if (!isReadOnly) {
      setIsIconHovered(false);
      setDisplayedColor(valueResolution.value ? trueColor : falseColor);
      hideTooltip();
    }
  };

  const input = (
    <div
      ref={composeReactRefs<HTMLDivElement>(monitorRef, containerRef)}
      className={classnames({
        [classes.baseContainer]: true,
        [classes.inTable]: usageVariant === UsageVariant.inTable,
        [classes.notInTable]: usageVariant !== UsageVariant.inTable,
        [classes.hovered]: !isReadOnly && isIconHovered,
        [classes[`valueContainer_${sizeVariant}`]]: usageVariant !== UsageVariant.inTable,
      })}
      onClick={() => {
        if (!isReadOnly) {
          hideTooltip();
          fieldHandler.updateValue(dimensionsMapping, !valueResolution.value);
          setDisplayedColor(!valueResolution.value ? trueColor : falseColor);
        }
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && !isReadOnly) {
          hideTooltip();
          fieldHandler.updateValue(dimensionsMapping, !valueResolution.value);
        }
      }}
      onMouseOver={onMouseOver}
      onFocus={onMouseOver}
      onMouseOut={onMouseOut}
      onBlur={onMouseOut}
      tabIndex={0}
      role="option"
      aria-selected={isIconHovered}
      aria-label={booleanTooltip}
    >
      {
        error !== undefined
          ? (<Icon name={IconName.dangerous} tooltip={error} colorVariant={IconColorVariant.error} />)
          : (<Icon size={IconSizeVariant.l} name={displayedIcon} color={displayedColor} />)
      }
    </div>
  );

  if (sizeVariant === SizeVariant.main && usageVariant !== UsageVariant.inTable) {
    return (
      <span className={classes.container}>
        {input}
      </span>
    );
  } else {
    return input;
  }
};

export default BooleanFieldRenderer;
