// eslint-disable-next-line yooi/no-restricted-dependency
import { setUseFormulaV2, setUsePathV2 } from 'yooi-modules';
// eslint-disable-next-line yooi/no-restricted-dependency
import type { ImageWithSize, PlatformConfigurationRaw } from 'yooi-modules/modules/platformConfigurationModule';
// eslint-disable-next-line yooi/no-restricted-dependency
import { PlatformLanguage } from 'yooi-modules/modules/platformConfigurationModule';
// eslint-disable-next-line yooi/no-restricted-dependency
import {
  PlatformConfiguration_AccentColor,
  PlatformConfiguration_AccentColorType,
  PlatformConfiguration_IntegrationDefaultExpiration,
  PlatformConfiguration_Language,
  PlatformConfiguration_Logo,
  PlatformConfiguration_Motto,
  PlatformConfiguration_Name,
} from 'yooi-modules/modules/platformConfigurationModule/ids';
// eslint-disable-next-line yooi/no-restricted-dependency
import { ConfigurationType } from 'yooi-modules/modules/platformConfigurationModule/model';
// eslint-disable-next-line yooi/no-restricted-dependency
import type { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { newError } from 'yooi-utils';

export interface SSOConfiguration {
  domain: string,
  authorizedDomains: string[],
  button?: { label: string, iconURL?: string },
}

interface Options {
  featureFlags: { group: string, name: string }[],
  hostname: string,
  fileSizeLimit: number,
  sso: SSOConfiguration[],
  configuration: Omit<PlatformConfigurationRaw, typeof Instance_Of>,
  translations: Record<string, string>,
}

declare global {
  interface Window {
    frontOptions?: Options,
    updateFeature?: (feature: string, activated: boolean) => void,
  }
}

const defaultOptions: Options = {
  featureFlags: [],
  hostname: 'localhost',
  fileSizeLimit: 5,
  sso: [],
  configuration: { [PlatformConfiguration_IntegrationDefaultExpiration]: 30 },
  translations: {},
};

const getOptions = () => {
  if (typeof window !== 'undefined') {
    const { frontOptions } = window;
    if (frontOptions) {
      return frontOptions;
    }
  }
  return defaultOptions;
};

export const hasFeature = (group: string, name: string): boolean => (
  getOptions().featureFlags.findIndex((feature) => (feature.group === group && feature.name === name)) !== -1
);

export const listFeatures = (): { group: string, name: string }[] => getOptions().featureFlags;

export const getFileSizeLimit = (): number => getOptions().fileSizeLimit;

export const getPlatformBranding = (): { name: string, motto: string | undefined } | undefined => {
  const rawPlatformName = getOptions().configuration[PlatformConfiguration_Name];
  const platformName = rawPlatformName === undefined || rawPlatformName === '' ? undefined : rawPlatformName;

  if (platformName === undefined) {
    return undefined;
  }

  const rawPlatformMotto = getOptions().configuration[PlatformConfiguration_Motto];
  const platformMotto = rawPlatformMotto === undefined || rawPlatformMotto === '' ? undefined : rawPlatformMotto;

  return { name: platformName, motto: platformMotto };
};

export const getPlatformLogo = (): ImageWithSize | undefined => getOptions().configuration[PlatformConfiguration_Logo];

export const getPlatformLanguage = (): PlatformLanguage => (getOptions().configuration[PlatformConfiguration_Language] ?? PlatformLanguage.en);

export const getTranslations = (): Record<string, string> => getOptions().translations;

export const getPlatformAccentColor = (): string | undefined => (
  getOptions().configuration[PlatformConfiguration_AccentColorType] === ConfigurationType.custom
    ? getOptions().configuration[PlatformConfiguration_AccentColor]
    : undefined
);

export const getSSOConfiguration = (): SSOConfiguration[] => getOptions().sso;

export const getHostname = (): string => getOptions().hostname;

if (hasFeature('early', 'formula2')) {
  setUseFormulaV2(true);
}
if (hasFeature('early', 'path2')) {
  setUsePathV2(true);
}

const featureHandlers: Record<string, (activated: boolean) => void> = {
  'early:formula2': setUseFormulaV2,
  'early:path2': setUsePathV2,
};

if (typeof window !== 'undefined') {
  window.updateFeature = (feature, activated) => {
    const handler = featureHandlers[feature];
    if (handler) {
      handler(activated);
    } else {
      throw newError('Not a dynamic feature', { feature });
    }
  };
}
