import type { FunctionComponent } from 'react';
import type { DimensionsMapping } from 'yooi-modules/modules/conceptModule';
import { canWrite, iconFieldHandler, isEmbeddedAsIntegrationOnly, ParsedDimensionType, parseDimensionMapping } from 'yooi-modules/modules/conceptModule';
import type { IconName } from '../../../../components/atoms/Icon';
import { getDefaultStandardColors } from '../../../../components/inputs/ColorPicker';
import useAcl from '../../../../store/useAcl';
import useActivity from '../../../../store/useActivity';
import useStore from '../../../../store/useStore';
import useUpdateActivity from '../../../../store/useUpdateActivity';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import useSizeContext, { SizeVariant } from '../../../../utils/useSizeContext';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';
import { getColorPalette } from '../../../utils/standardColorsUtils';
import { getLastUpdateBy } from '../../historyUtils';
import StoreColoredIconPicker from '../../input/StoreColoredIconPicker';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    marginLeft: '0.8rem',
  },
}, 'iconFieldRenderer');

interface IconFieldRendererProps {
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
  readOnly: boolean,
  focusOnMount: boolean,
}

const IconFieldRenderer: FunctionComponent<IconFieldRendererProps> = ({ fieldId, dimensionsMapping, readOnly, focusOnMount }) => {
  const classes = useStyles();

  const store = useStore();
  const { canWriteObject } = useAcl();

  const activity = useActivity();
  const updateActivity = useUpdateActivity();

  const { sizeVariant } = useSizeContext();
  const usageVariant = useUsageContext();

  const fieldHandler = iconFieldHandler(store, fieldId);
  const colorField = fieldHandler.resolveConfiguration();

  const parsedDimension = parseDimensionMapping(dimensionsMapping);

  const valueResolution = fieldHandler.getValueResolution(dimensionsMapping);

  const isReadOnly = readOnly
    || colorField.integrationOnly
    || !canWrite(dimensionsMapping, canWriteObject)
    || (parsedDimension.type === ParsedDimensionType.MonoDimensional && isEmbeddedAsIntegrationOnly(store.getObject(parsedDimension.objectId)));

  const isEditing = parsedDimension.type === ParsedDimensionType.MonoDimensional ? activity.listEditor(parsedDimension.objectId, fieldId).length > 0 : false;
  const input = (
    <StoreColoredIconPicker
      initialValue={valueResolution.value as { icon: IconName, color: string } | undefined}
      onSubmit={(newValue) => fieldHandler.updateValue(dimensionsMapping, newValue)}
      colorPalette={getColorPalette(store) ?? getDefaultStandardColors()}
      readOnly={isReadOnly}
      focusOnMount={focusOnMount}
      clearMode={{ type: 'clear' }}
      onEditionStart={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => updateActivity.onEnterEdition(parsedDimension.objectId, fieldId) : undefined}
      onEditionStop={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => updateActivity.onExitEdition(parsedDimension.objectId, fieldId) : undefined}
      withMultiplayerOutline={isEditing}
      placeholder={i18n`Add icon`}
      restingTooltip={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => getLastUpdateBy(store, parsedDimension.objectId, fieldId, undefined) : undefined}
    />
  );

  if (sizeVariant === SizeVariant.main && usageVariant !== UsageVariant.inTable) {
    return (
      <span className={classes.container}>
        {input}
      </span>
    );
  } else {
    return input;
  }
};

export default IconFieldRenderer;
