import { format } from 'ssf';
import { newError } from '../../errorUtils';
import { newFunctionLibrary } from '../engine/functionLibraryBuilder';
import { booleanType, isNaS, NaS, numberType, textType } from '../typeSystem';
import { checkArgumentCount, checkArgumentType, checkFlattenType, checks, flattenForEach } from './utils';

const clearRegex = new RegExp(`[${String.fromCharCode(0x00)}-${String.fromCharCode(0x1F)}]`, 'g');

export const textFunctions = newFunctionLibrary()
  .addFunction(
    'CLEAN',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, textType, argTypes, 0)
        ) ?? {
          type: textType,
          jsFunction: (str: string | undefined) => (str !== undefined && !isNaS(str) ? str.replace(clearRegex, '') : NaS()),
        }),
    })
  )
  .addFunction(
    ['CONCAT', 'CONCATENATE'],
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1),
          () => checkFlattenType(name, [textType, numberType], argTypes)
        ) ?? {
          type: textType,
          jsFunction: (...args: unknown[]) => {
            let hasNaS = false;
            const elements: string[] = [];
            flattenForEach(args, (e: string | number | undefined) => {
              if (typeof e === 'number') {
                elements.push(e.toString());
              } else if (isNaS(e)) {
                hasNaS = true;
              } else if (e !== undefined) {
                elements.push(e);
              }
            });
            return hasNaS ? NaS() : elements.join('');
          },
        }),
    })
  )
  .addFunction(
    'ES',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, textType, argTypes)
        ) ?? {
          type: textType,
          jsFunction: (t: string | undefined) => (t ?? ''),
        }),
    })
  )
  .addFunction(
    'IFNAS',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, textType, argTypes)
        ) ?? {
          type: textType,
          jsFunctionArgsMode: 'lazy',
          jsFunction: (t: () => string | undefined, elseT: () => string | undefined) => {
            const tValue = t();
            return isNaS(tValue) ? elseT() : tValue;
          },
        }),
    })
  )
  .addFunction(
    'INDEXOF',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 3),
          () => checkArgumentType(name, textType, argTypes, 0),
          () => checkArgumentType(name, textType, argTypes, 1),
          () => (argTypes.length < 3 ? undefined : checkArgumentType(name, numberType, argTypes, 2))
        ) ?? {
          type: numberType,
          jsFunction: (str: string | undefined, subStr: string | undefined, position: number | undefined) => {
            if (subStr === undefined || isNaS(subStr)) {
              throw newError('INDEXOF subStr need to be defined');
            } else if (str === undefined || isNaS(str)) {
              return -1;
            } else {
              return str.indexOf(subStr, position);
            }
          },
        }),
    })
  )
  .addFunction(
    'ISNAS',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, textType, argTypes)
        ) ?? {
          type: booleanType,
          jsFunction: (t: string) => isNaS(t),
        }),
    })
  )
  .addFunction(
    'LEFT',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 2),
          () => checkArgumentType(name, textType, argTypes, 0),
          () => (argTypes.length < 2 ? undefined : checkArgumentType(name, numberType, argTypes, 1))
        ) ?? {
          type: textType,
          jsFunction: (str: string | undefined, numChars: number | undefined) => {
            if (str === undefined || isNaS(str)) {
              return NaS();
            } else if (numChars === undefined) {
              return str.slice(0, 1);
              // eslint-disable-next-line yooi/number-is-nan-call
            } else if (Number.isNaN(numChars)) {
              throw newError('LEFT numChars should be a valid number');
            } else if (numChars < 0) {
              throw newError('LEFT numChars should be a positive integer');
            } else if (numChars > str.length) {
              return str;
            } else {
              return str.slice(0, numChars);
            }
          },
        }),
    })
  )
  .addFunction(
    'LEN',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, textType, argTypes, 0)
        ) ?? {
          type: numberType,
          jsFunction: (str: string | undefined) => (str?.length ?? Number.NaN),
          transpile: ([arg]) => `(${arg})?.length ?? Number.NaN`,
        }),
    })
  )
  .addFunction(
    'LOWER',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, textType, argTypes, 0)
        ) ?? {
          type: textType,
          jsFunction: (str: string | undefined) => (str !== undefined && !isNaS(str) ? str.toLowerCase() : NaS()),
        }),
    })
  )
  .addFunction(
    'MID',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 3, 3),
          () => checkArgumentType(name, textType, argTypes, 0),
          () => checkArgumentType(name, numberType, argTypes, 1),
          () => checkArgumentType(name, numberType, argTypes, 2)
        ) ?? {
          type: textType,
          jsFunction: (str: string | undefined, startNum: number | undefined, numChars: number | undefined) => {
            if (str === undefined || isNaS(str)) {
              return NaS();
            } else if (startNum === undefined) {
              throw newError('MID startNum need to be defined');
              // eslint-disable-next-line yooi/number-is-nan-call
            } else if (Number.isNaN(startNum)) {
              throw newError('MID startNum should be a valid number');
            } else if (startNum < 0) {
              throw newError('MID startNum should be a positive integer');
            } else if (numChars === undefined) {
              throw newError('MID numChars need to be defined');
              // eslint-disable-next-line yooi/number-is-nan-call
            } else if (Number.isNaN(numChars)) {
              throw newError('MID numChars should be a valid number');
            } else if (numChars < 0) {
              throw newError('MID numChars should be a positive integer');
            } else if (startNum > str.length) {
              return '';
            } else {
              return str.slice(startNum - 1, startNum + numChars - 1);
            }
          },
        }),
    })
  )
  .addFunction(
    'PROPER',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, textType, argTypes, 0)
        ) ?? {
          type: textType,
          jsFunction: (str: string | undefined) => {
            if (str === undefined || isNaS(str)) {
              return NaS();
            } else {
              let text = str.toLowerCase();
              text = text.charAt(0).toUpperCase() + text.slice(1);
              return text.replace(/(?:[^a-zA-Z])([a-zA-Z])/g, (letter) => letter.toUpperCase());
            }
          },
        }),
    })
  )
  .addFunction(
    'REPLACE',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 4, 4),
          () => checkArgumentType(name, textType, argTypes, 0),
          () => checkArgumentType(name, numberType, argTypes, 1),
          () => checkArgumentType(name, numberType, argTypes, 2),
          () => checkArgumentType(name, textType, argTypes, 3)
        ) ?? {
          type: textType,
          jsFunction: (str: string | undefined, startNum: number | undefined, numChars: number | undefined, newText: string | undefined) => {
            if (str === undefined || isNaS(str)) {
              return NaS();
            } else if (startNum === undefined) {
              throw newError('REPLACE startNum need to be defined');
              // eslint-disable-next-line yooi/number-is-nan-call
            } else if (Number.isNaN(startNum)) {
              throw newError('REPLACE startNum should be a valid number');
            } else if (startNum <= 0) {
              throw newError('REPLACE startNum should be greater than 0');
            } else if (numChars === undefined) {
              throw newError('REPLACE numChars need to be defined');
              // eslint-disable-next-line yooi/number-is-nan-call
            } else if (Number.isNaN(numChars)) {
              throw newError('REPLACE numChars should be a valid number');
            } else if (numChars < 0) {
              throw newError('REPLACE numChars should be a positive integer');
            } else if (newText === undefined) {
              throw newError('REPLACE newText need to be defined');
            } else {
              const arr = str.split('');
              arr.splice(startNum - 1, numChars, newText);
              return arr.join('');
            }
          },
        }),
    })
  )
  .addFunction(
    'RIGHT',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 2),
          () => checkArgumentType(name, textType, argTypes, 0),
          () => (argTypes.length < 2 ? undefined : checkArgumentType(name, numberType, argTypes, 1))
        ) ?? {
          type: textType,
          jsFunction: (str: string | undefined, numChars: number | undefined) => {
            if (str === undefined || isNaS(str)) {
              return NaS();
            } else if (numChars === undefined) {
              return str.slice(str.length - 1);
              // eslint-disable-next-line yooi/number-is-nan-call
            } else if (Number.isNaN(numChars)) {
              throw newError('RIGHT numChars should be a valid number');
            } else if (numChars < 0) {
              throw newError('RIGHT numChars should be a positive integer');
            } else if (numChars > str.length) {
              return str;
            } else {
              return str.slice(str.length - numChars);
            }
          },
        }),
    })
  )
  .addFunction(
    'TEXT',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, numberType, argTypes, 0),
          () => checkArgumentType(name, textType, argTypes, 1)
        ) ?? {
          type: textType,
          jsFunction: (value: number | undefined, formatText: string | undefined) => {
            if (value === undefined || isNaS(value)) {
              return NaS();
            } else if (formatText === undefined) {
              throw newError('TEXT formatText need to be defined');
            } else {
              // I know ssf contains bugs...
              return format(formatText, value);
            }
          },
        }),
    })
  )
  .addFunction(
    'TEXTJOIN',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 3),
          () => checkArgumentType(name, textType, argTypes, 0),
          () => checkArgumentType(name, booleanType, argTypes, 1),
          () => checkFlattenType(name, textType, argTypes.slice(2))
        ) ?? {
          type: textType,
          jsFunction: (delimiter: string | undefined, ignoreEmpty: boolean | undefined, ...texts: (string | string[])[]) => {
            if (isNaS(delimiter)) {
              return NaS();
            }

            let hasNaS = false;
            const parts: string[] = [];
            // does not allow union
            flattenForEach(texts, (text: string | undefined) => {
              if (isNaS(text)) {
                hasNaS = true;
              } else if (!ignoreEmpty || (text !== undefined && text !== '')) {
                parts.push(text ?? '');
              }
            });

            return hasNaS ? NaS() : parts.join(delimiter ?? '');
          },
        }),
    })
  )
  .addFunction(
    'TRIM',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, textType, argTypes, 0)
        ) ?? {
          type: textType,
          jsFunction: (str: string | undefined) => (str !== undefined && !isNaS(str) ? str.replace(/^\s+|\s+$/g, '') : NaS()),
        }),
    })
  )
  .addFunction(
    'UPPER',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, textType, argTypes, 0)
        ) ?? {
          type: textType,
          jsFunction: (str: string | undefined) => (str !== undefined && !isNaS(str) ? str.toUpperCase() : NaS()),
        }),
    })
  )
  .build();
