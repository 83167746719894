import type { ComponentProps } from 'react';
import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import { BlockFieldLayoutOption } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { AttachmentFieldRaw, AttachmentFieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { attachmentFieldHandler } from 'yooi-modules/modules/conceptModule';
import {
  AttachmentField,
  Field_ApiAlias,
  Field_Documentation,
  Field_IntegrationOnly,
  Field_IsCore,
  Field_IsDocumentationInline,
  Field_Title,
} from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareNumber, comparing, joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import { TableSortDirection } from '../../../../components/molecules/Table';
import i18n from '../../../../utils/i18n';
import { hasFeature } from '../../../../utils/options';
import type { OptionRecord } from '../../modelTypeUtils';
import { defaultOptionComparator } from '../../modelTypeUtils';
import { getBlockFieldLayoutOption, getDefaultDisplayOptions, getLayoutDisplayOption } from '../_global/blockFieldUtils';
import { getApiAliasInitialState, getDocumentationFieldEditionSection, getIntegrationFieldEditionSection } from '../_global/editionHandlerUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  duplicateFieldDimensionWithNewField,
  FIELD_DIMENSIONS_READONLY,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
  submitDimensionUpdate,
} from '../fieldDimensionUtils';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, FieldComparatorHandler, GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldIntegrationOnlyDisabled } from '../FieldLibraryTypes';
import AttachmentFieldRenderer from './AttachmentFieldRenderer';
import { getAttachmentList } from './attachmentUtils';

interface AttachmentFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [FIELD_DIMENSIONS_READONLY]: boolean | undefined,
  [Field_Title]: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
  [Field_IntegrationOnly]: boolean | null | undefined,
  [FieldIntegrationOnlyDisabled]: boolean | undefined,
}

enum DisplayVariantOption {
  card = 'card',
  table = 'table',
}

type AttachmentBlockDisplayOptions = FieldBlockDisplayOptions & { displayVariant: DisplayVariantOption };

type AttachmentFieldDefinition = GetFieldDefinitionHandler<
  typeof attachmentFieldHandler,
  AttachmentFieldConfigurationState,
  { layoutDisplayType: BlockFieldLayoutOption },
  AttachmentBlockDisplayOptions
>;

const displayVariantOption: OptionRecord<DisplayVariantOption> = ({
  [DisplayVariantOption.card]: { id: DisplayVariantOption.card, label: i18n`Card` },
  [DisplayVariantOption.table]: { id: DisplayVariantOption.table, label: i18n`Table` },
});

const defaultDisplayVariant = DisplayVariantOption.card;

export const attachmentFieldDefinition: AttachmentFieldDefinition = registerFieldDefinition(attachmentFieldHandler, {
  configuration: {
    typeIcon: IconName.attach_file,
    getTypeLabel: () => i18n`Attachment`,
    asWidget: false,
    getEditionOptions: (store) => ({ mode, editionHandler }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        return [];
      }
      return [
        getDocumentationFieldEditionSection(editionHandler),
        getIntegrationFieldEditionSection(store, editionHandler, mode),
      ];
    },
    isCreationEnabled: () => () => hasFeature('custom', 'attachment'),
    onCreate: (store) => (editionHandler) => {
      const fieldEditionDimensions = editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {};

      const fieldId = store.createObject<AttachmentFieldRaw>({
        [Instance_Of]: AttachmentField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [Field_ApiAlias]: editionHandler.getValue(Field_ApiAlias),
        [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
      });
      linkFieldToFieldDimensions(store, fieldId, fieldEditionDimensions);
      return fieldId;
    },
    ofField: (store, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = store.getObject<AttachmentFieldStoreObject>(fieldId);
        return joinObjects(
          getApiAliasInitialState(store, fieldId),
          {
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            [Field_IntegrationOnly]: field[Field_IntegrationOnly],
            [FieldIntegrationOnlyDisabled]: field[Field_IsCore],
            [FIELD_DIMENSIONS_READONLY]: field[Field_IsCore],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(store, fieldId, conceptDefinitionId),
          }
        );
      },
      submitFieldUpdate: (stateToSubmit, conceptDefinitionId) => {
        store.updateObject<AttachmentFieldRaw>(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: stateToSubmit[Field_IntegrationOnly],
        });
        submitDimensionUpdate(store, fieldId, conceptDefinitionId, stateToSubmit[FIELD_EDITION_DIMENSIONS] ?? {});
      },
      duplicateFieldDefinition: () => {
        const attachmentField = store.getObject<AttachmentFieldStoreObject>(fieldId);
        const fieldDimensionMapping = generateDuplicatedFieldDimensionId(store, attachmentField.id);
        const newAttachmentFieldId = store.createObject<AttachmentFieldRaw>({
          [Instance_Of]: AttachmentField,
          [Field_Title]: `${attachmentField[Field_Title]} (copy)`,
          [Field_Documentation]: attachmentField[Field_Documentation],
          [Field_IsDocumentationInline]: attachmentField[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: attachmentField[Field_IntegrationOnly],
        });
        duplicateFieldDimensionWithNewField(store, newAttachmentFieldId, fieldDimensionMapping);
        return newAttachmentFieldId;
      },
    }),
  },
  renderField: (_, fieldId) => ({ dimensionsMapping, readOnly, fieldDisplayOptions }) => {
    let variant: ComponentProps<typeof AttachmentFieldRenderer>['variant'];
    if (fieldDisplayOptions?.displayVariant === DisplayVariantOption.card) {
      variant = 'cards';
    } else if (fieldDisplayOptions?.displayVariant === DisplayVariantOption.table) {
      variant = 'table';
    } else {
      variant = 'cell';
    }
    return (
      <AttachmentFieldRenderer
        fieldId={fieldId}
        dimensionsMapping={dimensionsMapping}
        readOnly={readOnly}
        variant={variant}
      />
    );
  },
  getActivityProperties: () => () => [],
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({
    key: fieldId,
    propertyId: fieldId,
    sortable: true,
    focusable: true,
  }),
  getComparatorHandler: (store) => (direction) => ({
    comparator: comparing(compareNumber, direction === TableSortDirection.desc),
    extractValue: (dimensionsMapping) => getAttachmentList(store, dimensionsMapping).length,
  }) satisfies FieldComparatorHandler<number>,
  blockDisplayOptionsHandler: (objectStore) => (fieldBlockDisplayId) => ({
    getDisplayOptions: () => joinObjects(
      { displayVariant: defaultDisplayVariant },
      getDefaultDisplayOptions(objectStore, fieldBlockDisplayId)
    ) as AttachmentBlockDisplayOptions,
    renderSummary: ({ layoutDisplayType, displayVariant }) => [
      displayVariantOption[displayVariant ?? defaultDisplayVariant].label,
      getBlockFieldLayoutOption()[layoutDisplayType ?? BlockFieldLayoutOption.auto].label,
    ],
    getBlockEditionOptionSections: (state, setState) => [
      {
        key: 'displayVariant',
        title: i18n`Display type`,
        action: {
          type: EditionOptionTypes.select,
          props: {
            computeOptions: () => Object.values(displayVariantOption).sort(defaultOptionComparator),
            selectedOption: displayVariantOption[state.displayVariant ?? defaultDisplayVariant],
            onChange: (selectedOption) => {
              if (selectedOption) {
                const newState = { ...state };
                newState.displayVariant = selectedOption?.id as DisplayVariantOption | undefined ?? defaultDisplayVariant;
                setState(newState);
              }
            },
          },
        },
        options: [],
      },
      getLayoutDisplayOption(state, setState),
    ],
    onSubmit: (state) => {
      objectStore.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
});
