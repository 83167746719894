import type { FunctionComponent } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { ChipViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import Loading from '../../../../components/molecules/Loading';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import makeStyles from '../../../../utils/makeStyles';
import useDeepMemo from '../../../../utils/useDeepMemo';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import BlockViewError from '../common/BlockViewError';
import { getViewDefinitionHandler } from '../viewDsl';
import { isResolutionError } from '../viewResolutionUtils';
import ChipView from './ChipView';
import type { ChipViewDefinitionHandler } from './chipViewDefinitionHandler';

const useStyles = makeStyles({
  container: {
    borderRadius: '0.4rem',
  },
}, 'chipViewBlock');

interface ChipViewBlockProps {
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinition: ChipViewStoredDefinition,
  parametersMapping: ParametersMapping,
  readOnly: boolean | undefined,
}

const ChipViewBlock: FunctionComponent<ChipViewBlockProps> = ({
  viewDimensions,
  viewFilters,
  viewDefinition,
  parametersMapping,
  readOnly,
}) => {
  const classes = useStyles();

  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();

  const viewHandler = getViewDefinitionHandler(viewDefinition) as ChipViewDefinitionHandler;
  const resolvedViewDefinition = viewHandler.getDefinition(store, viewDimensions);

  const chipResolution = useDeepMemo(
    () => viewHandler.resolveView(store, { viewDimensions, parametersMapping, readOnly, aclHandler, userId: loggedUserId }),
    [store.getSerial(), viewDimensions, parametersMapping]
  );

  if (chipResolution) {
    if (isResolutionError(chipResolution)) {
      return <BlockViewError error={chipResolution.error} />;
    } else {
      return (
        <div className={classes.container}>
          <ChipView
            chipResolution={chipResolution}
            readOnly={resolvedViewDefinition.readOnly}
            filterKey={viewFilters.filterKey}
            viewDimensions={viewDimensions}
            parametersMapping={parametersMapping}
          />
        </div>
      );
    }
  } else {
    return <Loading />;
  }
};

export default ChipViewBlock;
