import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import type { ConceptStoreObject, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { FILTER_PARAMETER_CURRENT, getConceptUrl, getFilterFunction, getInstanceLabel, GROUP_BY_PARAMETER } from 'yooi-modules/modules/conceptModule';
import { Concept_SwimlaneRank } from 'yooi-modules/modules/conceptModule/ids';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { compareProperty, compareRank, filterNullOrUndefined, joinObjects, ranker } from 'yooi-utils';
import { IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import Typo from '../../../../../components/atoms/Typo';
import Chip from '../../../../../components/molecules/Chip';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import BlockContent from '../../../../../components/templates/BlockContent';
import VerticalBlock from '../../../../../components/templates/VerticalBlock';
import useAcl from '../../../../../store/useAcl';
import useStore from '../../../../../store/useStore';
import base from '../../../../../theme/base';
import { getMostReadableColorFromBackgroundColor, hexColorWithAlpha } from '../../../../../theme/colorUtils';
import { buildPadding, getSpacing, Spacing, spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeSelectorsClasses from '../../../../../utils/makeSelectorsClasses';
import makeStyles from '../../../../../utils/makeStyles';
import { remToPx } from '../../../../../utils/sizeUtils';
import { formatOrUndef } from '../../../../../utils/stringUtils';
import useNavigation from '../../../../../utils/useNavigation';
import { useSessionStorageState } from '../../../../../utils/useSessionStorage';
import { SizeContextProvider, SizeVariant } from '../../../../../utils/useSizeContext';
import useTheme from '../../../../../utils/useTheme';
import withAsyncTask from '../../../../../utils/withAsyncTask';
import { resolveConceptChipIcon, resolveConceptColorValue } from '../../../conceptDisplayUtils';
import ArcherContainer from '../../../fields/graphChartField/archer/ArcherContainer';
import ArcherElement from '../../../fields/graphChartField/archer/ArcherElement';
import type { AnchorPositionType } from '../../../fields/graphChartField/archer/types';
import { ArrowSense } from '../../../fields/graphChartField/archer/types';
import { getFieldColumnComparator, getFieldLabel } from '../../../fieldUtils';
import type { FilterConfiguration, ViewFilters } from '../../../filter/useFilterSessionStorage';
import { useViewFilters } from '../../../filter/useViewFilters';
import { getChipOptions, getUnknownChip } from '../../../modelTypeUtils';
import type { SwimlaneV2Configuration } from '../../../sessionStorageTypes';
import useFilterAndSort from '../../../useFilterAndSort';
import { buildInstanceCardBody, buildInstanceCardHeader } from '../../cards/cardsViewUtils';
import InstanceCard from '../../cards/InstanceCard';
import { getViewNavigationFilters } from '../../common/viewUtils';
import type { SwimlaneViewResolvedDefinition } from '../swimlaneViewDefinitionHandler';
import type { SwimlaneViewResolution } from '../swimlaneViewResolution';
import ViewConceptSwimlaneChip from './ViewConceptSwimlaneChip';
import ViewConceptSwimlaneColumn from './ViewConceptSwimlaneColumn';
import ViewConceptSwimlaneDraggableItem from './ViewConceptSwimlaneDraggableItem';
import ViewConceptSwimlaneGroup from './ViewConceptSwimlaneGroup';

const getDragAndDropType = (key: string) => `${key}_ConceptSwimlaneCard`;

interface ViewConceptSwimlaneProps {
  viewDefinition: SwimlaneViewResolvedDefinition,
  viewDimensions: ViewDimension[],
  viewResolution: SwimlaneViewResolution,
  viewFilters: ViewFilters,
  parametersMapping: ParametersMapping,
  filterKey: string,
  configurationKey: string,
  readOnly?: boolean,
  width: number,
  height?: number,
}

const selectorsClasses = makeSelectorsClasses('visibilityHandler');

const useStyles = makeStyles((theme) => ({
  scrollContainer: {
    overflow: 'auto',
  },
  container: joinObjects(
    {
      display: 'grid',
      columnGap: getSpacing(Spacing.splus),
      rowGap: getSpacing(Spacing.s),
    },
    buildPadding({ top: Spacing.xs, bottom: Spacing.s })
  ),
  columnContainer: joinObjects(
    buildPadding({ x: Spacing.s }),
    {
      display: 'flex',
      alignItems: 'center',
      borderRadius: base.borderRadius.medium,
      border: `0.2rem solid ${theme.color.transparent}`,
      '&:hover, &:focus, &:focus-within': {
        [`& .${selectorsClasses.visibilityHandler}`]: {
          display: 'block',
        },
      },
    }
  ),
  emptyValueColumn: {
    border: `0.2rem dashed ${theme.color.border.default}`,
  },
  hiddenButton: {
    display: 'none',
  },
}), 'viewConceptSwimlane');

const columnGroupSeparator = '|';
const groupValueSeparator = '_';
const defaultLineHeight = '3.2rem';

const ViewConceptSwimlane: FunctionComponent<ViewConceptSwimlaneProps> = withAsyncTask(({
  viewDefinition,
  viewDimensions,
  viewResolution,
  viewFilters,
  parametersMapping,
  filterKey,
  configurationKey,
  readOnly = false,
  width,
  height,
  executeAsyncTask,
}) => {
  const classes = useStyles();
  const store = useStore();
  const theme = useTheme();
  const navigation = useNavigation();
  const aclHandler = useAcl();
  const [instanceInEdition, setInstanceInEdition] = useState<{ id: string, groupId: string | undefined, columnId: string | undefined } | undefined>();
  const openCardsRef = useRef<string[]>([]);

  const filterConfiguration = useViewFilters(viewFilters, viewDimensions);
  const filterFunction = getFilterFunction(store, viewDefinition.columnBy.filters);
  const getParametersMapping = (partialMapping?: ParametersMapping): ParametersMapping => (partialMapping ? joinObjects(
    parametersMapping,
    partialMapping
  ) : parametersMapping);

  const defaultHiddenColumns = viewResolution.columns.filter((value) => {
    if (value === undefined) {
      return !viewDefinition.columnBy.withEmpty;
    } else {
      return filterFunction && !filterFunction(getParametersMapping({ [FILTER_PARAMETER_CURRENT]: { type: 'single', id: value?.id } }));
    }
  }).map((instance) => instance?.id);

  const [filtersConfiguration] = useSessionStorageState<FilterConfiguration | undefined>(filterKey, undefined);
  const defaultConfiguration: SwimlaneV2Configuration = {
    hiddenColumns: defaultHiddenColumns,
    groupBy: viewDefinition.groupBy.find(({ isDefault }) => isDefault)?.id,
    showDependencies: viewDefinition.dependencies.length > 0,
  };
  const [swimlaneConfig] = useSessionStorageState<SwimlaneV2Configuration>(configurationKey, defaultConfiguration);
  const groupById = swimlaneConfig.groupBy !== null ? swimlaneConfig.groupBy ?? viewResolution.groupBy.find(({ isDefault }) => isDefault)?.id : undefined;
  const columns = viewResolution.columns.filter((instance) => !swimlaneConfig.hiddenColumns.includes(instance?.id ?? null));

  let chipWidth: number;
  let columnWidth: number;
  const columnPadding = remToPx(spacingRem[Spacing.s]);
  if (viewDefinition.columnBy.size.type === 'percent' && viewDefinition.columnBy.size.value !== undefined) {
    const gapWidth = (columns.length - 1) * remToPx(spacingRem[Spacing.splus]);
    columnWidth = width * (viewDefinition.columnBy.size.value / 100) - (gapWidth / columns.length);
    chipWidth = columnWidth - (2 * columnPadding) - 2;
  } else if (viewDefinition.columnBy.size.type === 'rem' && viewDefinition.columnBy.size.value !== undefined) {
    columnWidth = remToPx(viewDefinition.columnBy.size.value);
    chipWidth = columnWidth - (2 * columnPadding) - 2;
  } else {
    columnWidth = Math.max(remToPx(20), (width - ((columns.length - 1) * remToPx(spacingRem[Spacing.splus]) /* column gap */)) / columns.length);
    chipWidth = columnWidth - (2 * columnPadding) - 2;
  }

  const { generateList } = useFilterAndSort(
    filterKey,
    viewResolution.instances,
    viewResolution.filterFunction,
    { getComparatorHandler: getFieldColumnComparator(store), initial: viewResolution.defaultSort },
    undefined,
    [viewDefinition, parametersMapping, filterConfiguration],
    executeAsyncTask
  );

  const items = generateList().list.map(({ item }) => item);

  const columnAndGroupToItemMap = new Map<string, ConceptStoreObject[]>();
  const columnItemCounter = new Map<string | undefined, number>();
  const groupItemCounter = new Map<string | undefined, number>();
  const groupValueIdSet = new Set<string | undefined>();

  const completeList = ranker.decorateList(
    items.sort(compareProperty(Concept_SwimlaneRank, compareRank)),
    (concept) => concept[Concept_SwimlaneRank]
  );

  const lastItemRank = completeList.insertAfterLastItemRank();
  const groupBy = viewResolution.groupBy.find(({ id }) => id === groupById);
  const groupByParametersMapping: ParametersMapping | undefined = groupBy?.fieldId ? {
    [GROUP_BY_PARAMETER]: {
      type: 'single' as const,
      id: groupBy.fieldId,
    },
  } : undefined;

  for (let i = 0; i < completeList.length; i += 1) {
    const { item } = completeList[i];
    let groupId: string | undefined;
    let columnId: string | undefined;
    if (instanceInEdition?.id === item.id) {
      columnId = instanceInEdition.columnId;
      groupId = instanceInEdition.groupId;
    } else {
      const columnValue = viewResolution.getItemColumnValue(item);
      const groupValues = groupBy ? viewResolution.getItemGroupValue(item, groupBy.id) : [];
      columnId = columnValue?.id;
      groupId = groupValues.length > 0 ? groupValues.map((instance) => instance.id).join(groupValueSeparator) : undefined;
    }

    const id = `${columnId}${columnGroupSeparator}${groupId}`;
    if (columns.some((c) => c?.id === columnId)) {
      if (!groupValueIdSet.has(groupId)) {
        groupValueIdSet.add(groupId);
      }
      if (columnAndGroupToItemMap.has(id)) {
        const oldValue = columnAndGroupToItemMap.get(id) ?? [];
        oldValue.push(item);
      } else {
        columnAndGroupToItemMap.set(id, [item]);
      }
      groupItemCounter.set(groupId, (groupItemCounter.get(groupId) ?? 0) + 1);
      columnItemCounter.set(columnId, (columnItemCounter.get(columnId) ?? 0) + 1);
    }
  }

  const groupByValues = groupBy ? Array.from(groupValueIdSet)
    .map((value) => (value ? value.split(groupValueSeparator).map((id) => store.getObjectOrNull(id)).filter(filterNullOrUndefined) : []))
    .sort(groupBy.comparator) : [[]];

  const columnsNumber = columns.length;
  const groupsNumber = groupByValues.length;
  const getInstanceColor = (instanceId: string): string => {
    const computedColor = resolveConceptColorValue(store, instanceId) ?? resolveConceptChipIcon(store, instanceId)?.color;
    return computedColor ?? theme.color.text.brand;
  };

  const getArrowPosition = (source: ConceptStoreObject, target: ConceptStoreObject): { targetAnchor: AnchorPositionType, sourceAnchor: AnchorPositionType } => {
    const sourceColum = viewResolution.getItemColumnValue(source);
    const targetColumn = viewResolution.getItemColumnValue(target);
    const sourceColumIndex = columns.findIndex((column) => column?.id === sourceColum?.id);
    const targetColumIndex = columns.findIndex((column) => column?.id === targetColumn?.id);
    if (sourceColumIndex === targetColumIndex) {
      if (groupById) {
        const sourceRow = viewResolution.getItemGroupValue(source, groupById);
        const targetRow = viewResolution.getItemGroupValue(target, groupById);
        const sourceRowIndex = groupByValues.findIndex((group) => group.every(({ id }, index) => id === sourceRow.at(index)?.id));
        const targetRowIndex = groupByValues.findIndex((group) => group.every(({ id }, index) => id === targetRow.at(index)?.id));

        if (sourceRowIndex !== targetRowIndex) {
          return sourceRowIndex < targetRowIndex ? { sourceAnchor: 'bottom', targetAnchor: 'top' } : { sourceAnchor: 'top', targetAnchor: 'bottom' };
        }
      }
      const sourceRank = source[Concept_SwimlaneRank];
      const targetRank = target[Concept_SwimlaneRank];
      return compareRank(sourceRank, targetRank) < 0 ? { sourceAnchor: 'bottom', targetAnchor: 'top' } : { sourceAnchor: 'top', targetAnchor: 'bottom' };
    } else {
      return sourceColumIndex < targetColumIndex ? {
        sourceAnchor: 'right',
        targetAnchor: 'left',
      } : { sourceAnchor: 'left', targetAnchor: 'right' };
    }
  };

  let navigationFilters = getViewNavigationFilters(store, viewDimensions, filtersConfiguration, parametersMapping);
  navigationFilters = navigationFilters
    ? joinObjects(navigationFilters, { globalParametersMapping: groupByParametersMapping })
    : navigationFilters;
  const defaultLineHeightPx = remToPx(defaultLineHeight);

  const getCardHeader = (forceReadOnly = false) => (
    buildInstanceCardHeader(store, viewResolution.cardHeader, viewDefinition.readOnly || forceReadOnly, defaultLineHeightPx, 200, navigationFilters)
  );
  const getCardBody = (forceReadOnly = false) => (
    buildInstanceCardBody(store, viewResolution.cardBody, viewDefinition.readOnly || forceReadOnly, defaultLineHeightPx, 200, navigationFilters)
  );

  return (
    <VerticalBlock asBlockContent compact>
      <BlockContent fullWidth>
        <div
          className={classes.scrollContainer}
          style={{ height }}
        >
          <ArcherContainer>
            <div
              className={classes.container}
              style={{
                gridTemplateRows: `3.2rem repeat(${groupsNumber}, auto)`,
                gridTemplateColumns: `repeat(${columnsNumber}, ${columnWidth}px)`,
              }}
            >
              {columns.map((instance) => {
                const backgroundColor = instance ? getInstanceColor(instance.id) : theme.color.background.neutral.default;
                const textColor = getMostReadableColorFromBackgroundColor(backgroundColor);
                const chipOptions = instance ? getChipOptions(store, instance.id) ?? getUnknownChip(instance.id) : undefined;
                const navigationPayload = chipOptions?.getNavigationPayload?.(navigation);
                const label = instance ? getInstanceLabel(store, instance) : i18n`No ${formatOrUndef(viewDefinition.columnBy.fieldId ? getFieldLabel(store, store.getObject(viewDefinition.columnBy.fieldId)) : undefined)}`;
                return viewDefinition.color === 'column' ? (
                  <div
                    key={`swimlane_column_${instance?.id}`}
                    className={classnames({ [classes.columnContainer]: true, [classes.emptyValueColumn]: instance === undefined })}
                    style={{ backgroundColor: instance !== undefined ? backgroundColor : undefined }}
                  >
                    <SpacingLine>
                      <Typo maxLine={1} color={textColor}>
                        {label}
                      </Typo>
                      <Typo color={textColor}>
                        {columnItemCounter.get(instance?.id) ?? 0}
                      </Typo>
                      {instance !== undefined && (
                        <SizeContextProvider sizeVariant={SizeVariant.small}>
                          <div className={classnames(classes.hiddenButton, selectorsClasses.visibilityHandler)}>
                            <SpacingLine>
                              <IconOnlyButton
                                tooltip={i18n`Open`}
                                iconName={IconName.open_in_new}
                                onClick={() => navigation.push(instance.id, { pathname: getConceptUrl(store, instance.id), navigationFilters })}
                                variant={IconOnlyButtonVariants.secondary}
                              />
                            </SpacingLine>
                          </div>
                        </SizeContextProvider>
                      )}
                    </SpacingLine>
                  </div>
                ) : (
                  <SpacingLine key={`swimlane_column_${instance?.id}`}>
                    {chipOptions ? (
                      <Chip
                        tooltip={chipOptions.tooltip}
                        color={chipOptions.color}
                        text={chipOptions.label}
                        icon={chipOptions.icon}
                        squareColor={chipOptions.squareColor}
                        actions={navigationPayload ? [{
                          key: 'open',
                          icon: IconName.output,
                          tooltip: i18n`Open`,
                          action: { to: navigationPayload.to, state: navigationPayload.state, openInNewTab: false },
                          showOnHover: true,
                        }] : []}
                      />
                    ) : (
                      <Chip
                        tooltip={label}
                        text={label}
                        borderStyle="dashed"
                      />
                    )}
                    <Typo>
                      {columnItemCounter.get(instance?.id) ?? 0}
                    </Typo>
                  </SpacingLine>
                );
              })}
              {groupByValues.map((values) => {
                const groupId = values.length > 0 ? values.map((instance) => instance.id).join(groupValueSeparator) : undefined;
                let groupColor: string | undefined = theme.color.text.brand;
                if (values.length === 0) {
                  groupColor = theme.color.background.neutral.default;
                } else if (values.length === 1) {
                  groupColor = getInstanceColor(values[0].id);
                }
                return (
                  <ViewConceptSwimlaneGroup
                    key={`swimlane_group_${groupId}`}
                    colorBy={viewDefinition.color}
                    values={values}
                    groupBy={groupBy}
                    columnsNumber={columnsNumber}
                    itemCounter={groupItemCounter.get(groupId) ?? 0}
                    navigationFilters={navigationFilters}
                  >
                    {columns.map((column) => {
                      const columnColor = column ? getInstanceColor(column.id) : undefined;
                      const emptyValueZone = (groupBy && values.length === 0) || column === undefined;
                      return (
                        <ViewConceptSwimlaneColumn
                          key={`swimlane_item_container_${groupId}_${column?.id}`}
                          emptyValueZone={emptyValueZone}
                          type={getDragAndDropType(filterKey)}
                          backgroundColor={!emptyValueZone ? hexColorWithAlpha(viewDefinition.color === 'group' ? groupColor : columnColor, 0.2) : undefined}
                          onDrop={(droppedInstanceId) => {
                            setInstanceInEdition(undefined);
                            viewResolution.moveItem(droppedInstanceId, lastItemRank, column?.id, values.map(({ id }) => id), groupBy?.id);
                          }}
                          canDrop={(droppedInstanceId) => viewResolution.canDrop(droppedInstanceId, column?.id, values.map(({ id }) => id), groupBy?.id)}
                          readOnly={readOnly}
                          onCreate={viewResolution.generateOnCreate(column?.id, values.map(({ id }) => id), groupBy?.id)}
                        >
                          {columnAndGroupToItemMap.get(`${column?.id}${columnGroupSeparator}${groupId}`)?.map((instance) => {
                            const onEditionStart = () => {
                              setInstanceInEdition({ id: instance.id, columnId: column?.id, groupId });
                            };
                            const onEditionEnd = () => {
                              if (instanceInEdition?.id === instance.id) {
                                setInstanceInEdition(undefined);
                              }
                            };
                            return (
                              <ArcherElement
                                key={`swimlane_item_${instance.id}`}
                                ids={[instance.id]}
                                relations={swimlaneConfig.showDependencies ? viewResolution.getRelations(instance.id).map((targetId) => {
                                  const { targetAnchor, sourceAnchor } = getArrowPosition(instance, store.getObject(targetId));
                                  return {
                                    targetId,
                                    targetAnchor,
                                    sourceAnchor,
                                    lineType: {
                                      sense: ArrowSense.sourceToTarget,
                                      marker: { arrowLength: 10, arrowThickness: 6 },
                                      strokeWidth: 1,
                                      strokeColor: theme.color.border.dark,
                                    },
                                  };
                                }) : undefined}
                              >
                                <ViewConceptSwimlaneDraggableItem
                                  type={getDragAndDropType(filterKey)}
                                  instanceId={instance.id}
                                  canDrop={(droppedInstanceId) => viewResolution.canDrop(droppedInstanceId, column?.id, values.map(({ id }) => id), groupBy?.id).result}
                                  onDrop={(droppedInstanceId) => {
                                    const decoratedCurrentInstance = completeList.find(({ item }) => item.id === instance.id);
                                    const newRank = decoratedCurrentInstance?.insertBeforeRank();
                                    if (newRank) {
                                      viewResolution.moveItem(droppedInstanceId, newRank, column?.id, values.map(({ id }) => id), groupBy?.id);
                                      setInstanceInEdition(undefined);
                                    }
                                  }}
                                  canDragItem={!readOnly && aclHandler.canWriteObject(instance.id)}
                                >
                                  {({ isDragging }) => (viewDefinition.instanceDisplay === 'card' ? (
                                    <InstanceCard
                                      inEdition={instanceInEdition?.id === instance.id}
                                      onEditionStart={onEditionStart}
                                      onEditionEnd={onEditionEnd}
                                      withEditionMode
                                      collapsable
                                      initialIsCollapsed={!openCardsRef.current.includes(instance.id)}
                                      onOpen={() => {
                                        openCardsRef.current.push(instance.id);
                                      }}
                                      onCollapse={() => {
                                        openCardsRef.current = openCardsRef.current.filter((id) => id !== instance.id);
                                      }}
                                      header={getCardHeader(instanceInEdition?.id !== instance.id)}
                                      body={getCardBody(instanceInEdition?.id !== instance.id)}
                                      getNavigationPayload={() => navigation.createNavigationPayload(
                                        instance.id,
                                        {
                                          pathname: getConceptUrl(store, instance.id),
                                          navigationFilters,
                                        }
                                      )}
                                      color={viewResolution.cardColor}
                                      icon={viewResolution.cardIcon}
                                      boolean={viewResolution.cardBoolean}
                                      readOnly={readOnly || instanceInEdition?.id !== instance.id}
                                      parametersMapping={getParametersMapping({ [viewDimensions[0].id]: { type: 'single', id: instance.id } })}
                                    />
                                  ) : (
                                    <ViewConceptSwimlaneChip
                                      width={chipWidth}
                                      instance={instance}
                                      header={getCardHeader()}
                                      body={getCardBody()}
                                      icon={viewResolution.cardIcon}
                                      boolean={viewResolution.cardBoolean}
                                      color={viewResolution.cardColor}
                                      parametersMapping={getParametersMapping({ [viewDimensions[0].id]: { type: 'single', id: instance.id } })}
                                      inEdition={instanceInEdition?.id === instance.id}
                                      onEditionStart={onEditionStart}
                                      onEditionEnd={onEditionEnd}
                                      navigationFilters={navigationFilters}
                                      getNavigationPayload={() => navigation.createNavigationPayload(
                                        instance.id,
                                        {
                                          pathname: getConceptUrl(store, instance.id),
                                          navigationFilters,
                                        }
                                      )}
                                      showTooltip={!isDragging}
                                    />
                                  ))}
                                </ViewConceptSwimlaneDraggableItem>
                              </ArcherElement>
                            );
                          })}
                        </ViewConceptSwimlaneColumn>
                      );
                    })}
                  </ViewConceptSwimlaneGroup>
                );
              })}
            </div>
          </ArcherContainer>
        </div>
      </BlockContent>
    </VerticalBlock>
  );
});
export default ViewConceptSwimlane;
