import { v4 as uuid } from 'uuid';
import type { FieldDimensionRaw, FieldDimensionStoreObject, FieldDimensionTypesRaw, FieldDimensionTypesStoreObject } from 'yooi-modules/modules/conceptModule';
import {
  ConceptDefinition,
  Field_FieldDimensions,
  FieldDimension,
  FieldDimension_Field,
  FieldDimension_IsMandatory,
  FieldDimension_Label,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStore } from 'yooi-store';

export const FIELD_EDITION_DIMENSIONS = 'fieldEditionDimensions';
export const FIELD_DIMENSIONS_READONLY = 'fieldDimensionsReadOnly';

interface FieldEditionDimension {
  typeId: string,
  label: string | undefined,
  mandatory: boolean,
  readOnly: boolean,
}

export type FieldEditionDimensions = Record<string, FieldEditionDimension>;

export const getFieldDimensionsEditionHandlerValue = (store: ObjectStore, fieldId: string, conceptDefinitionId: string): FieldEditionDimensions => {
  const dimensions = store.getObject(fieldId)
    .navigateBack<FieldDimensionStoreObject>(Field_FieldDimensions)
    .map((dimension) => ({
      dimension,
      dimensionTypeIds: (
        store.withAssociation(FieldDimensionTypes)
          .withRole(FieldDimensionTypes_Role_FieldDimension, dimension.id)
          .withExternalRole(FieldDimensionTypes_Role_ConceptDefinition)
          .list<FieldDimensionTypesStoreObject>()
          .map((fieldDimensionTypes) => fieldDimensionTypes.role(FieldDimensionTypes_Role_ConceptDefinition))
      ),
    }));

  const firstDimensionOfCurrentConceptDefinitionIndex = dimensions.findIndex(({ dimensionTypeIds }) => dimensionTypeIds.includes(conceptDefinitionId));

  return Object.fromEntries(
    dimensions
      .map(({ dimension, dimensionTypeIds }, index) => [
        dimension.id,
        {
          typeId: dimensionTypeIds.includes(conceptDefinitionId) ? conceptDefinitionId : dimensionTypeIds[0],
          label: dimension[FieldDimension_Label],
          mandatory: dimension[FieldDimension_IsMandatory] ?? false,
          readOnly: firstDimensionOfCurrentConceptDefinitionIndex === index,
        },
      ])
  );
};

export const generateDuplicatedFieldDimensionId = (store: ObjectStore, fieldId: string): Record<string, string> => (
  Object.fromEntries(
    store.getObject(fieldId)
      .navigateBack(Field_FieldDimensions)
      .map(({ id: oldFieldDimensionId }) => ([oldFieldDimensionId, uuid()]))
  )
);

export const duplicateFieldDimensionWithNewField = (store: ObjectStore, newFieldId: string, dimensionsMapping: Record<string, string>): void => {
  Object.entries(dimensionsMapping)
    .forEach(([oldFieldDimensionId, newFieldDimensionId]) => {
      const oldFieldDimension = store.getObject<FieldDimensionStoreObject>(oldFieldDimensionId);
      store.updateObject<FieldDimensionRaw>(
        newFieldDimensionId,
        {
          [Instance_Of]: FieldDimension,
          [FieldDimension_Field]: newFieldId,
          [FieldDimension_Label]: oldFieldDimension[FieldDimension_Label],
          [FieldDimension_IsMandatory]: oldFieldDimension[FieldDimension_IsMandatory],
        }
      );
      store.withAssociation(FieldDimensionTypes)
        .withRole(FieldDimensionTypes_Role_FieldDimension, oldFieldDimensionId)
        .list<FieldDimensionTypesStoreObject>()
        .forEach((assoc) => {
          store.withAssociation(FieldDimensionTypes)
            .withRole(FieldDimensionTypes_Role_FieldDimension, newFieldDimensionId)
            .withRole(FieldDimensionTypes_Role_ConceptDefinition, assoc.role(FieldDimensionTypes_Role_ConceptDefinition))
            .updateObject<FieldDimensionTypesRaw>({});
        });
    });
};

export const linkFieldDimensionToConceptDefinition = (store: ObjectStore, fieldDimensionId: string, conceptDefinitionId: string): void => {
  const modelType = store.getObjectOrNull(conceptDefinitionId);

  if (isInstanceOf(modelType, ConceptDefinition)) {
    store.withAssociation(FieldDimensionTypes)
      .withRole(FieldDimensionTypes_Role_FieldDimension, fieldDimensionId)
      .withRole(FieldDimensionTypes_Role_ConceptDefinition, conceptDefinitionId)
      .updateObject<FieldDimensionTypesRaw>({});
  }
};

export const createAndLinkFieldToConceptDefinitions = (store: ObjectStore, fieldId: string, conceptDefinitionIds: string[]): void => {
  const dimensionId = store.createObject<FieldDimensionRaw>({ [Instance_Of]: FieldDimension, [FieldDimension_Field]: fieldId });
  conceptDefinitionIds.forEach((modelTypeId) => linkFieldDimensionToConceptDefinition(store, dimensionId, modelTypeId));
};

export const linkFieldToFieldDimensions = (store: ObjectStore, fieldId: string, fieldDimensions: FieldEditionDimensions): void => {
  Object.entries(fieldDimensions)
    .forEach(([fieldDimensionId, { typeId, label, mandatory }]) => {
      store.updateObject<FieldDimensionRaw>(
        fieldDimensionId,
        {
          [Instance_Of]: FieldDimension,
          [FieldDimension_Field]: fieldId,
          [FieldDimension_Label]: label !== undefined && label !== '' ? label : null,
          [FieldDimension_IsMandatory]: mandatory,
        }
      );
      linkFieldDimensionToConceptDefinition(store, fieldDimensionId, typeId);
    });
};

export const submitDimensionUpdate = (objectStore: ObjectStore, fieldId: string, conceptDefinitionId: string, fieldDimensions: FieldEditionDimensions): void => {
  const previousFieldEditionDimensions: FieldEditionDimensions = getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId);

  Object.entries(previousFieldEditionDimensions)
    .filter(([dimensionId]) => !fieldDimensions[dimensionId])
    .forEach(([dimensionId]) => {
      objectStore.deleteObject(dimensionId);
    });

  linkFieldToFieldDimensions(objectStore, fieldId, fieldDimensions);
};
