import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import FormTextInputField from '../../../app/_global/input/FormTextInputField';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { sanitizeSearchValue } from '../../../utils/searchUtils';
import { SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import { UsageContextProvider, UsageVariant } from '../../../utils/useUsageContext';
import Button, { ButtonVariant } from '../../atoms/Button';
import { IconName } from '../../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../atoms/IconOnlyButton';
import IconPickerOption from './IconPickerOption';

const firstIcons = [
  IconName.home,
  IconName.favorite,
  IconName.folder,
  IconName.rocket_launch,
  IconName.star,
  IconName.water_drop,
  IconName.contrast,
  IconName.info,
  IconName.inbox,
  IconName.pets,
  IconName.group,
  IconName.work,
  IconName.language,
  IconName.bar_chart,
  IconName.sim_card,
  IconName.back_hand,
  IconName.play_circle,
  IconName.lightbulb,
  IconName.shopping_bag,
  IconName.shape_line,
  IconName.chat_bubble,
  IconName.route,
  IconName.thumb_up,
  IconName.thumb_down,
  IconName.school,
  IconName.auto_awesome,
  IconName.mode_night,
  IconName.monetization_on,
  IconName.store,
  IconName.airplanemode_active,
  IconName.category,
  IconName.adjust,
  IconName.palette,
  IconName.storefront,
  IconName.extension,
];

const allIcons = Object.values(IconName);

const numberOfColumns = 7;
const numberOfLines = 5;
const iconsPerPages = numberOfColumns * numberOfLines;

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.splus,
    paddingLeft: spacingRem.splus,
    paddingRight: spacingRem.splus,
  },
  withVerticalPadding: {
    paddingTop: spacingRem.splus,
    paddingBottom: spacingRem.splus,
  },
  iconsContainer: {
    display: 'grid',
    gridTemplateColumns: `repeat(${numberOfColumns}, 2.6rem)`,
    gridTemplateRows: `repeat(${numberOfLines}, 2.6rem)`,
    rowGap: spacingRem.splus,
    columnGap: spacingRem.splus,
  },
  paginationContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto auto',
    columnGap: spacingRem.splus,
  },
}, 'iconPickerOptions');

interface IconPickerOptionsProps {
  value: IconName | undefined,
  onChange: (newValue: { newIcon: IconName, newColor?: string } | undefined) => void,
  color: string,
  withVerticalPadding?: boolean,
  clearMode?: { type: 'clear' } | { type: 'reset', value: IconName, color?: string, tooltip?: string },
}

const IconPickerOptions: FunctionComponent<IconPickerOptionsProps> = ({ value, onChange, color, withVerticalPadding = false, clearMode }) => {
  const classes = useStyles();

  const [search, setSearch] = useState('');
  const sanitizedSearch = sanitizeSearchValue(search);

  const icons = (search === '' ? [...firstIcons, ...allIcons] : allIcons)
    .filter((name) => (
      sanitizedSearch === ''
      || sanitizeSearchValue(name).includes(sanitizedSearch)
      || sanitizeSearchValue(name.replaceAll('_', ' ')).includes(sanitizedSearch)
    ));

  const numberOfPages = Math.ceil(icons.length / iconsPerPages);

  let selectedIconPage: number | undefined;
  if (value !== undefined) {
    const iconIndex = icons.indexOf(value);
    if (iconIndex >= 0) {
      selectedIconPage = Math.floor(iconIndex / iconsPerPages);
    }
  }

  const [page, setPage] = useState(selectedIconPage ?? 0);
  const currentPage = Math.min(page, numberOfPages - 1);

  const displayedIcons = icons.slice(currentPage * iconsPerPages, (currentPage + 1) * iconsPerPages);

  return (
    <SizeContextProvider sizeVariant={SizeVariant.main}>
      <span className={classnames({ [classes.container]: true, [classes.withVerticalPadding]: withVerticalPadding })}>
        <UsageContextProvider usageVariant={UsageVariant.inForm}>
          <SizeContextProvider sizeVariant={SizeVariant.small}>
            <FormTextInputField
              initialValue={search}
              placeholder={i18n`Search icons`}
              onChangeDebounced={(newSearch) => {
                setSearch(newSearch ?? '');
                setPage(0);
              }}
              onClear={() => setSearch('')}
            />
          </SizeContextProvider>
        </UsageContextProvider>
        <span className={classes.iconsContainer}>
          {
            displayedIcons
              .map((name) => (
                <IconPickerOption
                  key={name}
                  icon={name}
                  color={color}
                  tooltip={name}
                  onClick={() => onChange({ newIcon: name })}
                  active={value === name}
                />
              ))
          }
        </span>
        <span className={classes.paginationContainer}>
          <SizeContextProvider sizeVariant={SizeVariant.small}>
            {
              clearMode !== undefined
                ? (
                  <Button
                    title={clearMode.type === 'reset' ? i18n`Reset` : i18n`Clear`}
                    variant={ButtonVariant.secondary}
                    onClick={() => onChange(clearMode.type === 'reset' ? { newIcon: clearMode.value, newColor: clearMode.color } : undefined)}
                    disabled={(clearMode.type === 'reset' && value === clearMode.value) || (clearMode.type === 'clear' && value === undefined)}
                    tooltip={(clearMode.type === 'reset' ? clearMode.tooltip : undefined)}
                  />
                )
                : (<span />)
            }
            <span />
            <IconOnlyButton
              iconName={IconName.keyboard_arrow_left}
              tooltip={i18n`Previous`}
              variant={IconOnlyButtonVariants.secondary}
              onClick={() => setPage(currentPage - 1)}
              disabled={currentPage <= 0}
            />
            <IconOnlyButton
              iconName={IconName.keyboard_arrow_right}
              tooltip={i18n`Next`}
              variant={IconOnlyButtonVariants.secondary}
              onClick={() => setPage(currentPage + 1)}
              disabled={currentPage >= (numberOfPages - 1)}
            />
          </SizeContextProvider>
        </span>
      </span>
    </SizeContextProvider>
  );
};

export default IconPickerOptions;
