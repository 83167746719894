import type { FunctionComponent, ReactElement } from 'react';
import { Profiler, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { sleep } from 'yooi-utils';

declare global {
  interface Window {
    measureNavigationPerformance?: (uri: string, waitingTime: number) => Promise<{
      duration: DOMHighResTimeStamp,
      entryType: string,
      name: string,
      startTime: DOMHighResTimeStamp,
      detail?: unknown,
    }[]>,
  }
}

const navigationPerformanceStartMarkName = 'navigation-performance-start';

interface NavigationPerformanceMeasurerProps {
  children: ReactElement,
}

export const NavigationPerformanceMeasurer: FunctionComponent<NavigationPerformanceMeasurerProps> = ({ children }) => {
  const navigate = useNavigate();

  const measureNavigationPerformance = useCallback(async (uri: string, waitingTime = 500) => {
    const performanceEvents: PerformanceEntryList = [];
    const observer = new PerformanceObserver((list) => {
      performanceEvents.push(...list.getEntries().filter(({ entryType, name }) => entryType !== 'mark' || name === navigationPerformanceStartMarkName));
    });
    observer.observe({ entryTypes: ['layout-shift', 'longtask', 'measure', 'mark'] });

    try {
      performance.mark(navigationPerformanceStartMarkName);
      navigate(uri);

      // wait for performance observer to be idle
      let numberOfEvents = -1;
      while (performanceEvents.length !== numberOfEvents) {
        numberOfEvents = performanceEvents.length;
        await sleep(waitingTime);
      }

      // return the events sorted by time and in a serializable way (unlike objects, PerformanceEntry/Mark are not serializable)
      return performanceEvents
        .sort(({ startTime: startTimeA }, { startTime: startTimeB }) => startTimeA - startTimeB)
        .map((e) => {
          const { duration, entryType, name, startTime, detail } = e as PerformanceMark;
          return ({ duration, entryType, name, startTime, detail });
        });
    } finally {
      observer.disconnect();
    }
  }, [navigate]);

  useEffect(() => {
    window.measureNavigationPerformance = measureNavigationPerformance;
    return () => {
      window.measureNavigationPerformance = undefined;
    };
  }, [measureNavigationPerformance]);

  return children;
};

interface PerformanceProfilerProps {
  children: ReactElement,
}

const isPerformanceMeasureSupported = () => {
  try {
    performance.measure('test', { start: performance.now() });
    return true;
  } catch (_) {
    return false;
  }
};

export const PerformanceProfiler: FunctionComponent<PerformanceProfilerProps> = isPerformanceMeasureSupported()
  ? ({ children }) => (
    <Profiler
      id="App"
      onRender={(id, phase, actualDuration, baseDuration, startTime, commitTime) => {
        performance.measure('react-render', {
          start: startTime,
          duration: actualDuration,
          detail: { id, phase, actualDuration, baseDuration, startTime, commitTime },
        });
      }}
    >
      {children}
    </Profiler>
  )
  : ({ children }) => children;
