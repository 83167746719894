import { getPathLastFieldInformation, getPathReturnedConceptDefinitionId } from 'yooi-modules/modules/conceptModule';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { DimensionDisplayAxis } from 'yooi-modules/modules/dashboardModule';
import type { FrontObjectStore } from '../../../../store/useStore';
import type { TableViewResolvedDefinition } from './tableViewHandler';

export const viewsFieldTableHasOption = (objectStore: FrontObjectStore, viewDimensions: ViewDimension[], viewDefinition: TableViewResolvedDefinition): boolean => {
  const fieldOptions = Boolean(viewDefinition.groupBy?.fields?.some((groupByField) => Boolean(getPathLastFieldInformation(groupByField?.path ?? [])?.fieldId)));
  return fieldOptions || Boolean(viewDefinition.groupBy?.dimensionIds?.some((dimensionId) => {
    const dimension = viewDimensions.find(({ id }) => dimensionId === id);
    const dimensionDisplay = dimension ? viewDefinition.getDimensionDisplay(dimension) : undefined;
    const conceptDefinition = getPathReturnedConceptDefinitionId(objectStore, dimension?.path ?? []);
    return Boolean(dimension && dimensionDisplay && dimensionDisplay.axis !== DimensionDisplayAxis.y && conceptDefinition !== undefined);
  }));
};
