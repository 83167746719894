import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { MouseEventHandler } from 'react';
import { forwardRef } from 'react';
import base from '../../theme/base';
import makeStyles from '../../utils/makeStyles';
import useTooltipRef from '../../utils/useTooltipRef';
import Typo, { TypoVariant } from './Typo';

export enum AvatarSizes {
  normal = 'normal',
  small = 'small',
  extraSmall = 'extraSmall',
}

export enum AvatarVariant {
  leftBar = 'leftBar',
  currentUser = 'currentUser',
  user = 'user',
  messageUser = 'messageUser',
}

const useStyles = makeStyles((theme) => ({
  peopleBubble: {
    flexShrink: 0,
    flexGrow: 0,
    width: '3.2rem',
    height: '3.2rem',
    borderRadius: base.borderRadius.circle,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.white,
    cursor: 'default',
  },
  peopleBubbleSmall: {
    width: '2.4rem',
    height: '2.4rem',
  },
  peopleBubbleExtraSmall: {
    width: '1.6rem',
    height: '1.6rem',
  },
  leftBar: {
    backgroundColor: theme.color.background.primarylight.default,
    borderColor: theme.color.border.primary,
    color: theme.color.text.brand,
  },
  currentUser: {
    backgroundColor: theme.color.background.primarylight.default,
    borderColor: theme.color.border.primary,
    color: theme.color.text.brand,
  },
  user: {
    backgroundColor: theme.color.transparent,
    borderColor: theme.color.border.primary,
    color: theme.color.text.primary,
  },
  messageUser: {
    backgroundColor: theme.color.background.neutral.default,
    borderColor: theme.color.border.default,
  },
  chip: {
    position: 'absolute',
    width: '0.8rem',
    height: '0.8rem',
    borderRadius: base.borderRadius.circle,
    right: '1rem',
    top: '-0.4rem',
    background: theme.color.text.info,
  },
  relativeParent: {
    position: 'relative',
  },
}), 'avatar');

interface AvatarProps {
  name: string,
  tooltip?: string,
  letterColor?: string,
  onClick?: MouseEventHandler<HTMLElement>,
  size?: AvatarSizes,
  variant: AvatarVariant,
  showChip?: boolean,
}

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(({ name, tooltip, letterColor, onClick, size = AvatarSizes.normal, variant, showChip }, ref) => {
  const classes = useStyles();

  const tooltipRef = useTooltipRef(tooltip);

  return (
    <div
      ref={composeReactRefs<HTMLDivElement>(tooltipRef, ref)}
      onClick={onClick}
      className={classnames({
        [classes.peopleBubble]: true,
        [classes.peopleBubbleSmall]: size === AvatarSizes.small,
        [classes.peopleBubbleExtraSmall]: size === AvatarSizes.extraSmall,
        [classes[variant]]: variant,
      })}
      style={{ cursor: onClick ? 'pointer' : undefined }}
      aria-hidden="true"
    >
      <div className={classes.relativeParent}>
        <Typo
          color={letterColor}
          variant={size !== AvatarSizes.normal ? TypoVariant.small : undefined}
        >
          {showChip ? (<span className={classes.chip} />) : null}
          {name}
        </Typo>
      </div>
    </div>
  );
});

export default Avatar;
