import type { FunctionComponent } from 'react';
import { Fragment } from 'react';
import Icon, { IconName } from '../../../../components/atoms/Icon';
import Typo, { TypoVariant } from '../../../../components/atoms/Typo';
import Chip from '../../../../components/molecules/Chip';
import CompositeField, { DropdownSectionTitleVariants } from '../../../../components/molecules/CompositeField';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import useStore from '../../../../store/useStore';
import base from '../../../../theme/base';
import { buildMargins, Spacing, spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import useNavigation from '../../../../utils/useNavigation';
import useTheme from '../../../../utils/useTheme';
import type { InstanceTreeNode } from '../../conceptUtils';
import { getChipOptions } from '../../modelTypeUtils';
import type { NavigationFilter } from '../../navigationUtils';

const useStyles = makeStyles({
  nodeLinesGrid: {
    display: 'grid',
    rowGap: spacingRem.xs,
  },
  treeNodeChipMargin: buildMargins({ top: Spacing.xs }),
}, 'detailHierarchyTreeComposite');

interface DetailHierarchyTreeCompositeProps {
  node: InstanceTreeNode,
  focusOnMount?: boolean,
}

const DetailHierarchyTreeComposite: FunctionComponent<DetailHierarchyTreeCompositeProps> = ({ node, focusOnMount = false }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const navigation = useNavigation<NavigationFilter>();

  const renderNodeChip = (n: InstanceTreeNode) => {
    const instance = store.getObject(n.key);
    const chipOptions = getChipOptions(store, n.key);
    let color = chipOptions?.color;
    if (!n.children.length) {
      color = base.color.cyan['300'];
    } else if (n.children.every((child) => child.children.length)) {
      color = base.color.yellow['300'];
    }
    const navigationPayload = chipOptions?.getNavigationPayload?.(navigation);

    return (
      <Chip
        key={instance.id}
        text={chipOptions?.label}
        tooltip={chipOptions?.tooltip}
        icon={chipOptions?.icon}
        color={color}
        actions={navigationPayload ? [
          {
            key: 'open',
            icon: IconName.output,
            tooltip: i18n`Open`,
            action: { to: navigationPayload.to, state: navigationPayload.state },
            showOnHover: true,
          }] : undefined}
      />
    );
  };

  const renderTreeNodeAsLines = (treeNode: InstanceTreeNode) => {
    const children = treeNode.children.filter((n) => n.children.length);
    const renderLine = (key: string, refNode: InstanceTreeNode, parentNode: InstanceTreeNode) => (
      <SpacingLine>
        <Fragment key={key}>
          {renderNodeChip(refNode)}
          <Typo variant={TypoVariant.blockInlineTitle} color={theme.color.text.secondary}>{i18n`from`}</Typo>
          {renderNodeChip(parentNode)}
        </Fragment>
      </SpacingLine>
    );
    return (
      <div className={classes.nodeLinesGrid}>
        {
          node.children.filter((n) => !n.children.length)
            .map((nodeLastChild) => renderLine(nodeLastChild.key, nodeLastChild, node))
        }
        {
          children.flatMap((n) => (n.children.filter((child) => !child.children.length)
            .map((nodeLastChild) => renderLine(n.key, nodeLastChild, n))))
        }
      </div>
    );
  };

  const renderTreeNode = (treeNode: InstanceTreeNode, maxSubNodes: number) => {
    const children = treeNode.children.filter((n) => n.children.length);

    let remainingNodes = maxSubNodes;
    return (
      <SpacingLine>
        {renderNodeChip(node)}
        {
          children
            .filter(() => {
              remainingNodes -= 1;
              return remainingNodes >= 0;
            })
            .map((n, index) => (
              <div key={n.key} className={classes.treeNodeChipMargin}>
                <SpacingLine>
                  <Icon name={IconName.keyboard_arrow_right} />
                  {renderNodeChip(n)}
                  {maxSubNodes && children.length > maxSubNodes && index === (maxSubNodes - 1) && (
                    <Chip text={`+ ${children.length - maxSubNodes}`} />
                  )}
                </SpacingLine>
              </div>
            ))
        }
      </SpacingLine>
    );
  };

  return (
    <CompositeField
      headerLinesRenderers={[
        {
          id: 'Chips',
          render: (inDropDown) => renderTreeNode(node, inDropDown ? Number.MAX_SAFE_INTEGER : 1),
        },
      ]}
      getDropdownSectionDefinitions={() => [
        {
          id: 'main',
          titleVariant: DropdownSectionTitleVariants.inlineSecondary,
          title: i18n`References`,
          lines: [
            {
              id: 'Chips',
              render: (renderTreeNodeAsLines(node)),
            },
          ],
        },
      ]}
      headerHeight="6.6rem"
      openOnMount={focusOnMount}
    />
  );
};

export default DetailHierarchyTreeComposite;
