import type { FunctionComponent, ReactElement } from 'react';
import { createContext, useContext, useEffect, useRef, useSyncExternalStore } from 'react';
import { newError } from 'yooi-utils';
import type { GlobalObserver } from '../../../../utils/globalObserver';
import createGlobalObserver from '../../../../utils/globalObserver';

interface Context {
  observer: GlobalObserver<never>,
  status: 'loading' | 'loaded',
}

const LoadingStateContext = createContext<Context | undefined>(undefined);

interface ViewLoadingStateContextProviderProps {
  children: ReactElement | null,
}

export const ViewLoadingStateContextProvider: FunctionComponent<ViewLoadingStateContextProviderProps> = ({ children }) => {
  const contextRef = useRef<Context>({
    observer: createGlobalObserver<never>(),
    status: 'loaded',
  });

  return (
    <LoadingStateContext.Provider value={contextRef.current}>
      {children}
    </LoadingStateContext.Provider>
  );
};

export const useViewLoadingStateUpdater = (status: 'loading' | 'loaded'): void => {
  const context = useContext(LoadingStateContext);
  if (context === undefined) {
    throw newError('View loading context has not been initialized, add a ViewLoadingStateContextProvider in the React parent component hierarchy');
  }

  useEffect(() => {
    if (status !== context.status) {
      context.status = status;
      context.observer.notify();
    }
  }, [status, context.status, context]);
};

const useViewLoadingState = (): 'loading' | 'loaded' => {
  const context = useContext(LoadingStateContext);
  if (context === undefined) {
    throw newError('View loading context has not been initialized, add a ViewLoadingStateContextProvider in the React parent component hierarchy');
  }

  useSyncExternalStore(
    context.observer.register,
    context.observer.serial
  );

  return context.status;
};

export default useViewLoadingState;
