import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { joinObjects, newError } from 'yooi-utils';
import { sessionStoragePrefix } from './sessionStorageUtils';
import { SessionStorageKeys } from './useSessionStorage';

export enum PanelState {
  list = 'list',
  detail = 'detail',
  creation = 'creation',
}

export interface PanelContext {
  state: PanelState,
  context?: string[],
  scrollToChip?: boolean,
  isOpen: boolean,
  urlContext?: { collaborationId: string, messageId?: string },
  intention?: string,
  collaborationId?: string,
  messageId?: string,
}

interface Collaboration {
  pageContext: string[][] | undefined,
  panelContext: PanelContext,
  panelObserver: {
    register: (callback: () => void) => void,
    unregister: (callback: () => void) => void,
    notify: () => void,
  },
  updatePanelContext: (newPanelContext: PanelContext) => void,
}

export const CollaborationContext = createContext<Collaboration | undefined>(undefined);

export const DEFAULT_PANEL_CONTEXT: PanelContext = { state: PanelState.list, isOpen: false };

export const storeContextInSessionStorage = (panelContext: PanelContext, pageContext: string[][] | undefined): void => {
  sessionStorage.setItem(`${sessionStoragePrefix}${SessionStorageKeys.collaboration}`, JSON.stringify({ panelContext, pageContext }));
};

interface CollaborationPanelContextProviderProps {
  children: ReactNode,
}

export const CollaborationPanelContextProvider: FunctionComponent<CollaborationPanelContextProviderProps> = ({ children }) => {
  const notifyObservers = useDebouncedCallback((observers: Set<() => void>) => {
    observers.forEach((o) => o());
  }, 0);

  const context: Collaboration = useMemo(() => {
    const panelObservers = new Set<() => void>();

    let previousCollaborationContext;
    const storageValue = sessionStorage.getItem(`${sessionStoragePrefix}${SessionStorageKeys.collaboration}`);
    if (storageValue !== null) {
      try {
        previousCollaborationContext = JSON.parse(storageValue) as { panelContext: PanelContext | undefined, pageContext: unknown };
      } catch (_) {
        // Ignore error
      }
    }

    let panelContext = DEFAULT_PANEL_CONTEXT;
    if (previousCollaborationContext && previousCollaborationContext.panelContext && previousCollaborationContext.pageContext) {
      panelContext = joinObjects(
        previousCollaborationContext.panelContext,
        { context: undefined } // Reset context as we always want to select the top chip
      );
    }

    const state: Collaboration = {
      pageContext: undefined, // pageContext contains all context for the page ( [[Use Case], [UC1]] ). Always length = 1 for sub array as we only support objects
      panelContext,
      panelObserver: {
        register: (observer) => panelObservers.add(observer),
        unregister: (observer) => panelObservers.delete(observer),
        notify: () => notifyObservers(panelObservers),
      },
      updatePanelContext: (newPanelContext) => {
        state.panelContext = newPanelContext;
        storeContextInSessionStorage(state.panelContext, state.pageContext);
      },
    };
    return state;
  }, [notifyObservers]);

  return (
    <CollaborationContext.Provider value={context}>
      {children}
    </CollaborationContext.Provider>
  );
};

interface CollaborationPanelUpdater {
  togglePanel: () => void,
  closePanel: () => void,
  openCreation: (context: string[] | undefined, intention?: string, isOpen?: boolean) => void,
  openList: (context: string[] | undefined, isOpen?: boolean) => void,
  openDetail: (context: string[] | undefined, collaborationId: string, messageId?: string, isOpen?: boolean) => void,
  hasScrolledToChip: () => void,
}

export const useCollaborationPanelUpdater = (): CollaborationPanelUpdater => {
  const collaborationContext = useContext(CollaborationContext);
  if (collaborationContext === undefined) {
    throw newError('CollaborationContext has not been initialized, add a CollaborationPanelContextProvider in the React parent component hierarchy');
  }

  const { panelContext, updatePanelContext, panelObserver } = collaborationContext;

  return {
    togglePanel: () => {
      updatePanelContext(joinObjects(panelContext, {
        isOpen: !panelContext.isOpen,
      }));
      panelObserver.notify();
    },
    closePanel: () => {
      updatePanelContext(joinObjects(panelContext, {
        isOpen: false,
      }));
      panelObserver.notify();
    },
    openCreation: (context, intention, isOpen) => {
      updatePanelContext({
        state: PanelState.creation,
        context,
        intention,
        isOpen: isOpen ?? panelContext.isOpen,
      });
      panelObserver.notify();
    },
    openList: (context, isOpen) => {
      updatePanelContext({
        state: PanelState.list,
        context,
        isOpen: isOpen ?? panelContext.isOpen,
      });
      panelObserver.notify();
    },
    openDetail: (context, collaborationId, messageId, isOpen) => {
      updatePanelContext({
        state: PanelState.detail,
        context,
        isOpen: isOpen ?? panelContext.isOpen,
        collaborationId,
        messageId,
      });
      panelObserver.notify();
    },
    hasScrolledToChip: () => {
      updatePanelContext(joinObjects(
        panelContext,
        {
          scrollToChip: false,
        }
      ));
      panelObserver.notify();
    },
  };
};
