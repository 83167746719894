import { equals } from 'ramda';
import { joinObjects } from 'yooi-utils';
import useStore from '../../../store/useStore';
import useDeepMemo from '../../../utils/useDeepMemo';
import useDerivedState from '../../../utils/useDerivedState';
import { getFieldConfigurationHandler } from './FieldLibrary';
import type { EditionHandler } from './FieldLibraryTypes';

const useStoreFieldEditionHandler = (fieldId: string, conceptDefinitionId: string): EditionHandler => {
  const store = useStore();
  const initialState = getFieldConfigurationHandler(store, fieldId).getInitialState(conceptDefinitionId);
  const memoizedInitialState = useDeepMemo<typeof initialState>(() => initialState, [initialState]);
  const [creationObject, setCreationObject, resetCreationObject] = useDerivedState(() => memoizedInitialState, [memoizedInitialState]);

  return {
    getValue: <T>(id: string) => (creationObject?.[id] ?? undefined) as T,
    getValueOrDefault: <T>(id: string) => (creationObject?.[id] ?? undefined) as T,
    updateValues: (update) => {
      resetCreationObject();
      getFieldConfigurationHandler(store, fieldId).submitFieldUpdate(joinObjects(creationObject, update), conceptDefinitionId);
    },
    reset: () => {
      setCreationObject(memoizedInitialState);
      return memoizedInitialState;
    },
    resetValue: (id) => {
      setCreationObject(joinObjects(creationObject, { [id]: memoizedInitialState?.[id] }));
    },
    hasModification: () => !equals(memoizedInitialState, creationObject),
  };
};

export default useStoreFieldEditionHandler;
