import classnames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import { spacingRem } from '../../theme/spacingDefinition';
import makeSelectorsClasses from '../../utils/makeSelectorsClasses';
import makeStyles from '../../utils/makeStyles';
import useTheme from '../../utils/useTheme';
import Tooltip from '../atoms/Tooltip';
import { TableCellAlign } from './TableCell';

export const tableHeaderCellSelectorsClasses = makeSelectorsClasses('visibilitySensor');

const useStyles = makeStyles((theme) => ({
  base: {
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.default,
    overflow: 'hidden',
    padding: 0,
    verticalAlign: 'middle',
    '&:hover, &:focus, &:focus-within': {
      [`& .${tableHeaderCellSelectorsClasses.visibilitySensor}`]: {
        visibility: 'visible',
      },
    },
  },
  inner: {
    display: 'flex',
  },
  innerPadded: {
    marginBottom: spacingRem.s,
    marginTop: spacingRem.s,
  },
  clickable: {
    '&:hover, &:focus': {
      backgroundColor: theme.color.background.neutral.subtle,
    },
    '&:active': {
      backgroundColor: theme.color.border.default,
    },
    cursor: 'pointer',
  },
  action: {
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    width: '4.2rem',
  },
}), 'tableHeaderCell');

export enum HeaderVariant {
  'light' = 'light',
  'dark' = 'dark',
}

interface TableHeaderCellProps {
  align?: TableCellAlign,
  colSpan?: number,
  width?: string,
  minWidth?: string,
  maxWidth?: string,
  noSeparator?: boolean,
  verticalAlign?: 'top' | 'bottom' | 'middle',
  onClick?: () => void,
  tooltip?: string,
  children?: ReactNode,
  action?: boolean,
  fullHeight?: boolean,
  borderBottomColor?: string,
  variant?: HeaderVariant,
}

const TableHeaderCell: FunctionComponent<TableHeaderCellProps> = ({
  align = TableCellAlign.left,
  colSpan,
  width,
  minWidth,
  maxWidth,
  noSeparator = false,
  verticalAlign,
  onClick,
  tooltip,
  children,
  action = false,
  fullHeight = false,
  borderBottomColor,
  variant = HeaderVariant.light,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  let content;
  if (action) {
    content = children;
  } else {
    content = (
      <div className={classnames({ [classes.inner]: true, [classes.innerPadded]: !fullHeight })}>
        {children}
      </div>
    );
  }

  const backgroundColor = variant === HeaderVariant.dark ? theme.color.background.neutral.subtle : undefined;

  return (
    <th
      className={classnames({
        [classes.base]: !noSeparator,
        [classes.clickable]: onClick,
        [classes.action]: action,
      })}
      align={align}
      style={{ width, maxWidth, minWidth, borderBottomColor, backgroundColor, verticalAlign }}
      onClick={onClick}
      colSpan={colSpan}
    >
      {tooltip ? (<Tooltip title={tooltip}>{content}</Tooltip>) : content}
    </th>
  );
};

export default TableHeaderCell;
