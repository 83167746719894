import type { FieldBlockDisplayOptions, IFrameFieldRaw, IFrameFieldStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import { iFrameFieldHandler } from 'yooi-modules/modules/conceptLayoutModule';
import type { IFrameConfiguration } from 'yooi-modules/modules/conceptLayoutModule/fields/iFrameField/utils';
import { isDirectUrl, isTargetPathUrl, UrlType } from 'yooi-modules/modules/conceptLayoutModule/fields/iFrameField/utils';
import { FieldBlockDisplay_FieldDisplayConfiguration, IFrameField, IFrameField_Url } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { dimensionsMappingToParametersMapping } from 'yooi-modules/modules/conceptModule';
import { Field_Documentation, Field_IsDocumentationInline, Field_Title, TextField } from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { isUrl, joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import i18n from '../../../../utils/i18n';
import { getLoggedUserParameterDefinition } from '../../filter/filterUtils';
import { defaultOptionComparator } from '../../modelTypeUtils';
import { createPathConfigurationHandler } from '../../pathConfigurationHandler';
import { getFieldTypeValidator } from '../../pathConfigurationHandlerUtils';
import type { WidgetDisplay } from '../_global/widgetUtils';
import { defaultWidgetDisplay, getWidgetDisplayTypeLabel, getWidgetEditionOptionSection } from '../_global/widgetUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  duplicateFieldDimensionWithNewField,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
} from '../fieldDimensionUtils';
import type { FieldEditionOption } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode } from '../FieldLibraryTypes';
import IFrameBlockField from './IFrameBlockField';
import IFrameFieldWidget from './IFrameFieldWidget';

const getUrlTypeOption = (display: UrlType) => ({ id: display, label: display });

interface IFrameFieldBlockDisplayOptions extends FieldBlockDisplayOptions {
  widgetDisplay?: WidgetDisplay,
}

const defaultDisplayOption: IFrameFieldBlockDisplayOptions = { widgetDisplay: defaultWidgetDisplay };

interface IFrameFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
  [IFrameField_Url]: IFrameConfiguration | null | undefined,
}

type IFrameFieldDefinition = GetFieldDefinitionHandler<typeof iFrameFieldHandler, IFrameFieldConfigurationState, never, IFrameFieldBlockDisplayOptions>;

export const iFrameFieldDefinition: IFrameFieldDefinition = registerFieldDefinition(iFrameFieldHandler, {
  configuration: {
    typeIcon: IconName.web_asset,
    getTypeLabel: () => i18n`IFrame`,
    asWidget: true,
    getEditionOptions: (objectStore) => ({ mode, editionHandler, dashboardParameterDefinitions, readOnly }) => {
      if (mode !== FieldEditionOptionMode.Field && mode !== FieldEditionOptionMode.FieldDeveloperMode && mode !== FieldEditionOptionMode.Widget) {
        return [];
      }

      const configuration = editionHandler.getValue(IFrameField_Url);

      const displayOptions: FieldEditionOption[] = [];

      displayOptions.push({
        key: `${IFrameField_Url}_TYPE`,
        title: i18n`URL type`,
        hasValue: () => Boolean(configuration),
        clearValue: () => editionHandler.updateValues({ [IFrameField_Url]: undefined }),
        type: EditionOptionTypes.select,
        props: {
          readOnly,
          placeholder: i18n`Select a type`,
          selectedOption: configuration?.type ? getUrlTypeOption(configuration.type) : undefined,
          computeOptions: () => Object.values(UrlType).map((option) => getUrlTypeOption(option)).sort(defaultOptionComparator),
          onChange: (option) => {
            if (option) {
              editionHandler.updateValues({ [IFrameField_Url]: { type: option.id as UrlType } });
            }
          },
        },
      });

      if (isDirectUrl(configuration)) {
        const { url } = configuration;
        displayOptions.push({
          key: `${IFrameField_Url}_VALUE`,
          title: i18n`URL`,
          hasValue: () => Boolean(url),
          clearValue: () => editionHandler.updateValues({
            [IFrameField_Url]: joinObjects(configuration, { url: undefined }),
          }),
          type: EditionOptionTypes.text,
          props: {
            readOnly,
            placeholder: i18n`Add a URL`,
            value: url,
            onChange: (value) => {
              if (value !== undefined) {
                editionHandler.updateValues({
                  [IFrameField_Url]: joinObjects(configuration, { url: value ?? undefined }),
                });
              }
            },
            error: url && !isUrl(url) ? i18n`Invalid URL format` : undefined,
          },
        });
      } else if (isTargetPathUrl(configuration)) {
        const fieldEditionDimensions: FieldEditionDimensions = editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {};
        const parameterDefinitions: SingleParameterDefinition[] = [
          ...dashboardParameterDefinitions ?? [],
          ...Object.entries(fieldEditionDimensions).map(([id, { typeId }]): SingleParameterDefinition => ({ id, typeId, label: i18n`Dimension`, type: 'dimension' })),
          getLoggedUserParameterDefinition(),
        ];

        displayOptions.push({
          key: `${IFrameField_Url}_VALUE`,
          title: i18n`Path`,
          hasValue: () => Boolean(configuration.path),
          clearValue: () => editionHandler.updateValues({
            [IFrameField_Url]: joinObjects(configuration, { path: undefined }),
          }),
          type: EditionOptionTypes.path,
          props: {
            readOnly,
            parameterDefinitions,
            placeholder: i18n`Add a text field path`,
            initialPath: configuration.path ?? [],
            onSubmit: (value) => {
              if (value) {
                editionHandler.updateValues({
                  [IFrameField_Url]: joinObjects(configuration, { path: value }),
                });
              }
            },
            valuePathHandler: createPathConfigurationHandler(
              objectStore,
              parameterDefinitions,
              [getFieldTypeValidator(objectStore, [TextField], i18n`Input should end with a text.`)]
            ),
          },
        });
      }

      return [
        {
          key: 'display',
          type: 'section',
          title: i18n`Display`,
          options: displayOptions,
        },
      ];
    },
    isCreationEnabled: () => () => true,
    onCreate: (objectStore) => (editionHandler) => {
      const fieldId = objectStore.createObject({
        [Instance_Of]: IFrameField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [IFrameField_Url]: editionHandler.getValue(IFrameField_Url),
      });
      linkFieldToFieldDimensions(objectStore, fieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});
      return fieldId;
    },
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<IFrameFieldStoreObject>(fieldId);
        return {
          [Field_Title]: field[Field_Title],
          [Field_Documentation]: field[Field_Documentation],
          [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
          [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
          [IFrameField_Url]: field[IFrameField_Url],
        };
      },
      submitFieldUpdate: (stateToSubmit) => {
        objectStore.updateObject<IFrameFieldRaw>(fieldId, {
          [IFrameField_Url]: stateToSubmit[IFrameField_Url],
        });
      },
      duplicateFieldDefinition: () => {
        const iFrameField = iFrameFieldHandler(objectStore, fieldId).resolveConfiguration();
        const newFieldId = objectStore.createObject({
          [Instance_Of]: IFrameField,
          [Field_Title]: `${iFrameField.title} (copy)`,
          [Field_Documentation]: iFrameField.documentation,
          [Field_IsDocumentationInline]: iFrameField.isDocumentationInline,
          [IFrameField_Url]: iFrameField.url,
        });
        duplicateFieldDimensionWithNewField(objectStore, newFieldId, generateDuplicatedFieldDimensionId(objectStore, fieldId));
        return newFieldId;
      },
    }),
  },
  renderWidget: (_, fieldId) => ({ parametersMapping }) => (<IFrameFieldWidget fieldId={fieldId} parametersMapping={parametersMapping} />),
  renderBlockField: (_, fieldId) => (dimensionsMapping, displayOptions, blockFieldProps, layoutParametersMapping) => (
    <IFrameBlockField
      parametersMapping={joinObjects(dimensionsMappingToParametersMapping(dimensionsMapping), layoutParametersMapping)}
      fieldId={fieldId}
      blockFieldProps={blockFieldProps}
      widgetDisplay={displayOptions?.widgetDisplay ?? defaultWidgetDisplay}
    />
  ),
  blockDisplayOptionsHandler: (objectStore) => (fieldBlockDisplayId) => ({
    getDisplayOptions: () => {
      const fieldBlockDisplay = objectStore.getObjectOrNull(fieldBlockDisplayId);
      if (fieldBlockDisplay?.[FieldBlockDisplay_FieldDisplayConfiguration]) {
        return fieldBlockDisplay[FieldBlockDisplay_FieldDisplayConfiguration] as IFrameFieldBlockDisplayOptions;
      } else {
        return defaultDisplayOption;
      }
    },
    renderSummary: (state) => [getWidgetDisplayTypeLabel((state.widgetDisplay ?? defaultWidgetDisplay).type)],
    getBlockEditionOptionSections: (state, setState) => [
      getWidgetEditionOptionSection(
        state.widgetDisplay ?? defaultWidgetDisplay,
        (newWidgetDisplay) => setState(joinObjects(state, { widgetDisplay: newWidgetDisplay }))
      ),
    ],
    onSubmit: (state) => objectStore.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state }),
  }),
});
