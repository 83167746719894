import type { FunctionComponent, ReactElement } from 'react';
import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { useLocation } from 'react-router-dom';
import type { ConceptDefinitionLibraryTableStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import { LibraryDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import {
  ConceptDefinitionLibraryTable,
  ConceptDefinitionLibraryTable_Role_ConceptDefinition,
  ConceptDefinitionLibraryTable_Role_Field,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { AssociationFilterStoreObject, ConceptDefinitionStoreObject, ConceptStoreObject, ConditionFilterStoreObject } from 'yooi-modules/modules/conceptModule';
import { FILTER_PARAMETER_CURRENT, hasPlatformCapability, isConceptValid } from 'yooi-modules/modules/conceptModule';
import {
  Concept_FunctionalId,
  Concept_Name,
  ConceptDefinition_Description,
  ConceptDefinition_LibraryDisplayOption,
  ConceptDefinition_LibraryShowMatrix,
  ConceptDefinition_LibraryShowSwimlane,
  ConceptDefinition_LibraryShowTable,
  ConceptDefinition_LibraryShowTimeline,
  ConceptDefinition_ViewFilters,
  PlatformCapabilityAdmin,
} from 'yooi-modules/modules/conceptModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../components/atoms/Icon';
import IconOnlyButton from '../../components/atoms/IconOnlyButton';
import Typo from '../../components/atoms/Typo';
import Chooser from '../../components/molecules/Chooser';
import Link from '../../components/molecules/Link';
import SpacingLine from '../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../components/molecules/Table';
import BaseLayout from '../../components/templates/BaseLayout';
import BlockContent from '../../components/templates/BlockContent';
import Header from '../../components/templates/Header';
import VerticalBlock from '../../components/templates/VerticalBlock';
import useAcl from '../../store/useAcl';
import useAuth from '../../store/useAuth';
import useStore from '../../store/useStore';
import { spacingRem } from '../../theme/spacingDefinition';
import type { SessionStorageHash } from '../../utils/historyUtils';
import { createHash } from '../../utils/historyUtils';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import useNavigation, { NavigationElementContainer } from '../../utils/useNavigation';
import { useFocusNewLineNotify } from '../../utils/useNewLineFocus';
import { SessionStorageKeys, useSessionStorageState } from '../../utils/useSessionStorage';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import ConceptViewTopBar, { DisplayedLine } from '../_global/ConceptViewTopBar';
import { dndManager } from '../_global/fields/_global/dndUtils';
import ConceptMatrix from '../_global/fields/_global/matrix/ConceptMatrix';
import ConceptSwimlane from '../_global/fields/_global/swimlane/ConceptSwimlane';
import ConceptTimeline from '../_global/fields/_global/timeline/ConceptTimeline';
import ConceptTimelineOptions from '../_global/fields/_global/timeline/ConceptTimelineOptions';
import { getFieldColumnComparator } from '../_global/fieldUtils';
import { ConceptDefinitionFavoriteFiltersBar } from '../_global/filter/FavoriteFiltersBar';
import { getLoggedUserParameterDefinition } from '../_global/filter/filterUtils';
import { getDefaultFilterConfiguration } from '../_global/filter/quickFiltersUtils';
import type { FilterConfiguration } from '../_global/filter/useFilterSessionStorage';
import { getFieldGroupByHandler } from '../_global/groupByUtils';
import HeaderStatic from '../_global/HeaderStatic';
import { getConceptFilterFunction } from '../_global/listFilterFunctions';
import { getConceptDefinitionNameOrEntity, getTableGroupByField } from '../_global/modelTypeUtils';
import type { NavigationFilter } from '../_global/navigationUtils';
import CollaborationRightPanel from '../_global/rightPanel/collaboration/CollaborationRightPanel';
import type { TableConfiguration } from '../_global/sessionStorageTypes';
import TopBar from '../_global/topBar/TopBar';
import useFilterAndSort from '../_global/useFilterAndSort';
import FirstClassConceptList from './FirstClassConceptList';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: spacingRem.l,
  },
  headerRightContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.m,
  },
}, 'conceptLibrary');

interface ConceptLibraryProps {
  conceptDefinitionId: string,
}

const ConceptLibrary: FunctionComponent<ConceptLibraryProps> = ({ conceptDefinitionId }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const { loggedUserId } = useAuth();
  const { canCreateObject } = useAcl();

  const location = useLocation();
  const navigation = useNavigation<NavigationFilter>();

  const [displayedLine, setDisplayedLine] = useState<DisplayedLine | undefined>(undefined);

  const [{ hash: tabState }, setTabState] = useSessionStorageState<SessionStorageHash>(`${conceptDefinitionId}-state-hash`, { hash: null });
  const conceptDefinition = store.getObject<ConceptDefinitionStoreObject>(conceptDefinitionId);
  const filterId = conceptDefinitionId;

  const [tableConfig] = useSessionStorageState<TableConfiguration | undefined>(`${SessionStorageKeys.tableConfig}_${conceptDefinitionId}`, undefined);
  const tableGroupByField = getTableGroupByField(store, conceptDefinitionId, tableConfig);

  const libraryDisplayOption = conceptDefinition?.[ConceptDefinition_LibraryDisplayOption] ?? LibraryDisplayOptions.table;

  const hasFunctionalId = Boolean(
    store.withAssociation(ConceptDefinitionLibraryTable)
      .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
      .withRole(ConceptDefinitionLibraryTable_Role_Field, Concept_FunctionalId)
      .getObjectOrNull<ConceptDefinitionLibraryTableStoreObject>()
  );

  const defaultFilterConfiguration = getDefaultFilterConfiguration(
    store,
    loggedUserId,
    () => store.getObject(conceptDefinitionId).navigateBack<AssociationFilterStoreObject | ConditionFilterStoreObject>(ConceptDefinition_ViewFilters),
    [
      { id: FILTER_PARAMETER_CURRENT, label: i18n`Current`, typeId: conceptDefinitionId, type: 'parameter' },
      getLoggedUserParameterDefinition(),
    ]
  );

  const [filtersConfiguration] = useSessionStorageState<FilterConfiguration | undefined>(conceptDefinitionId, defaultFilterConfiguration);

  const { generateList, generatePageList, generateGroupedList, generateGroupedPageList, doSort, sortCriteria, forceFollowingIds, forceShowId } = useFilterAndSort(
    conceptDefinitionId,
    conceptDefinition.navigateBack<ConceptStoreObject>(Class_Instances).filter((instance) => isConceptValid(store, instance.id)),
    getConceptFilterFunction(store, conceptDefinitionId, filtersConfiguration, loggedUserId),
    {
      getComparatorHandler: getFieldColumnComparator(store),
      initial: hasFunctionalId ? { key: Concept_FunctionalId, direction: TableSortDirection.desc } : { key: Concept_Name, direction: TableSortDirection.asc },
    },
    libraryDisplayOption === LibraryDisplayOptions.table && tableGroupByField ? getFieldGroupByHandler(store, tableGroupByField.id) : undefined
  );

  const hasTable = conceptDefinition[ConceptDefinition_LibraryShowTable];
  const hasTimeline = conceptDefinition[ConceptDefinition_LibraryShowTimeline];
  const hasMatrix = conceptDefinition[ConceptDefinition_LibraryShowMatrix];
  const hasSwimlane = conceptDefinition[ConceptDefinition_LibraryShowSwimlane];

  const viewTabs: {
    key: string,
    name: string,
    component: ReactElement,
    hash: string,
  }[] = [];
  if (hasTable) {
    viewTabs.push({
      key: 'list',
      name: i18n`List`,
      component: (
        <FirstClassConceptList
          key={conceptDefinitionId}
          conceptDefinitionId={conceptDefinitionId}
          generatePageList={generatePageList}
          generateList={generateList}
          generateGroupedPageList={generateGroupedPageList}
          generateGroupedList={generateGroupedList}
          doSort={doSort}
          sortCriteria={sortCriteria}
          forceFollowingIds={forceFollowingIds}
          forceShowId={forceShowId}
          filterId={filterId}
          libraryDisplayOption={libraryDisplayOption}
          displayedLine={displayedLine}
        />
      ),
      hash: '#list',
    });
  }
  if (hasTimeline) {
    viewTabs.push({
      key: 'timeline',
      name: i18n`Timeline`,
      component: (
        <>
          <BlockContent>
            {displayedLine === DisplayedLine.options && (
              <ConceptTimelineOptions conceptDefinitionId={conceptDefinitionId} filterKey={filterId} />
            )}
            {displayedLine === DisplayedLine.filters && (
              <ConceptDefinitionFavoriteFiltersBar filterKey={filterId} conceptDefinitionId={conceptDefinitionId} />
            )}
          </BlockContent>
          <ConceptTimeline
            filterKey={filterId}
            generateList={generateList}
            conceptDefinitionId={conceptDefinitionId}
          />
        </>
      ),
      hash: '#timeline',
    });
  }
  if (hasSwimlane) {
    viewTabs.push({
      key: 'swimlane',
      name: i18n`Swimlane`,
      component: (
        <DndProvider manager={dndManager}>
          <ConceptSwimlane
            filterKey={filterId}
            generateList={() => generateList().list.map(({ item }) => item)}
            conceptDefinitionId={conceptDefinitionId}
            displayedLine={displayedLine}
          />
        </DndProvider>
      ),
      hash: '#swimlane',
    });
  }
  if (hasMatrix) {
    viewTabs.push({
      key: 'matrix',
      name: i18n`Matrix`,
      component: (
        <ConceptMatrix
          filterKey={filterId}
          generateList={() => generateList().list.map(({ item }) => item)}
          conceptDefinitionId={conceptDefinitionId}
          displayedLine={displayedLine}
        />
      ),
      hash: '#matrix',
    });
  }

  let currentIndex = 0;
  if (location.hash) {
    currentIndex = viewTabs.findIndex(({ hash }) => (location.hash) === hash);
  } else if (tabState) {
    currentIndex = viewTabs.findIndex(({ hash }) => (tabState) === hash);
  }
  if (currentIndex === -1) {
    currentIndex = 0;
  }

  const handleViewChange = (selectedIndex: number) => {
    if (selectedIndex !== undefined) {
      const hash = createHash(viewTabs[selectedIndex].hash);
      navigation.replace(conceptDefinitionId, joinObjects(location, { hash }));
      setTabState({ hash });
    }
  };
  const focusNewLineNotify = useFocusNewLineNotify();
  const onCreateFirstClassConceptClick = () => {
    const objectId = store.createObject({ [Instance_Of]: conceptDefinitionId });
    forceShowId(objectId);
    focusNewLineNotify(objectId);
    return objectId;
  };
  const isAdmin = hasPlatformCapability(store, loggedUserId, PlatformCapabilityAdmin);
  const viewTabComponent = viewTabs[currentIndex]?.component;

  return (
    <NavigationElementContainer element={{ key: conceptDefinitionId }}>
      <BaseLayout
        topBar={(<TopBar />)}
        rightPanel={(<CollaborationRightPanel />)}
        header={(
          <Header
            firstLine={(
              <HeaderStatic
                text={getConceptDefinitionNameOrEntity(store, conceptDefinition.id)}
                newItem={canCreateObject(conceptDefinition.id) ? (
                  <IconOnlyButton
                    onClick={onCreateFirstClassConceptClick}
                    iconName={IconName.add}
                    tooltip={i18n`Add`}
                  />
                ) : undefined}
                actions={isAdmin ? [(
                  <Link key="config" title={i18n`Access configuration`} to={`/settings/organization/${conceptDefinition.id}`} />
                )] : undefined}
              />
            )}
          />
        )}
        content={(
          <VerticalBlock>
            <>
              {conceptDefinition[ConceptDefinition_Description] && (
                <BlockContent padded>
                  <Typo color={theme.color.text.secondary}>
                    {conceptDefinition[ConceptDefinition_Description] as string | undefined}
                  </Typo>
                </BlockContent>
              )}
              {!viewTabComponent && (
                <BlockContent padded>
                  <SpacingLine>
                    <Icon name={IconName.dangerous} tooltip={i18n`No view has been configured`} colorVariant={IconColorVariant.error} />
                    <Typo>{i18n`No view has been configured`}</Typo>
                  </SpacingLine>
                </BlockContent>
              )}
              <BlockContent padded>
                <div className={classes.headerContainer}>
                  <div className={classes.headerRightContainer}>
                    <SizeContextProvider sizeVariant={SizeVariant.title} hierarchyVariant={HierarchyVariant.content}>
                      <ConceptViewTopBar
                        filterKey={conceptDefinitionId}
                        onOptionsClick={() => {
                          setDisplayedLine((current) => (current === DisplayedLine.options ? undefined : DisplayedLine.options));
                        }}
                        onFiltersClick={() => {
                          setDisplayedLine((current) => (current === DisplayedLine.filters ? undefined : DisplayedLine.filters));
                        }}
                        activeFilters={displayedLine === DisplayedLine.filters}
                        activeOptions={displayedLine === DisplayedLine.options}
                      />
                      {viewTabs.length > 1 && (
                        <Chooser actions={viewTabs} onClick={handleViewChange} selectedIndexes={[currentIndex]} />
                      )}
                    </SizeContextProvider>
                  </div>
                </div>
              </BlockContent>
            </>
            {viewTabComponent}
          </VerticalBlock>
        )}
      />
    </NavigationElementContainer>
  );
};

export default ConceptLibrary;
