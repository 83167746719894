import type { FunctionComponent } from 'react';
import { compareString, comparing } from 'yooi-utils';
import { TableSortDirection } from '../../../../components/molecules/Table';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import type { ComparatorHandler } from '../../../_global/useFilterAndSort';
import { getInstanceHint } from '../_global/explorerUtils';
import { useExplorerHint } from '../_global/GetHintContextProvider';
import { ValueType } from '../_global/objectRenderType';
import ValueRenderer from '../_global/ValueRenderer';
import InstanceTable from './InstanceTable';

const useStyles = makeStyles({
  cell: {
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    display: 'flex',
  },
}, 'instanceAsPropertyTab');

interface InstanceAsPropertyTabProps {
  instanceId: string,
}

const InstanceAsPropertyTab: FunctionComponent<InstanceAsPropertyTabProps> = ({ instanceId }) => {
  const classes = useStyles();

  const store = useStore();
  const getHint = useExplorerHint();

  const references = store.listObjects()
    .map((o) => {
      if (Object.keys(o.asRawObject()).some((key) => key === instanceId)) {
        return [{
          id: o.key,
          key: o.key,
          object: o,
          hint: getInstanceHint(store, getHint, o.id),
        }];
      } else {
        return [];
      }
    })
    .flat();

  return (
    <InstanceTable
      filterId={`InstanceAsPropertyTab_${instanceId}`}
      list={references}
      searchStrings={(item) => [item.id, item.hint]}
      sort={{
        getComparatorHandler: (key, direction) => {
          switch (key) {
            case 'id':
              return {
                comparator: comparing(compareString, direction === TableSortDirection.desc),
                extractValue: (item) => item.hint ?? item.id,
              } satisfies ComparatorHandler<typeof references[0], string | undefined>;
            default:
              return undefined;
          }
        },
        initial: { key: 'id', direction: TableSortDirection.asc },
      }}
      columnsDefinition={[
        {
          propertyId: 'id',
          name: i18n`Id`,
          sortable: true,
          cellRender: ({ object: { key }, hint }) => (
            <div className={classes.cell}>
              <ValueRenderer key={key} value={{ type: ValueType.string, value: key, hint, href: `/settings/explorer/instance/${key}`, maxLine: 1 }} />
            </div>
          ),
        },
      ]}
    />
  );
};

export default InstanceAsPropertyTab;
