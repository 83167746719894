import type { ReactNode } from 'react';
import { Component } from 'react';
import { StoreObjectNotFoundError } from 'yooi-utils';
import { ObjectNotFoundError } from '../utils/ObjectNotFoundError';

interface StoreObjectNotFoundErrorWithContextProps {
  children: ReactNode,
  objectName: string,
}

interface StoreObjectNotFoundErrorWithContextState {
  error?: Error,
}

// Unfortunately, error boundaries cannot be implemented using hooks for the moment
// componentDidCatch & getDerivedStateFromError does not have any equivalent as hooks for the moment
// see (https://reactjs.org/docs/hooks-faq.html#how-do-lifecycle-methods-correspond-to-hooks)
class StoreObjectNotFoundErrorBoundary extends Component<StoreObjectNotFoundErrorWithContextProps, StoreObjectNotFoundErrorWithContextState> {
  constructor(props: StoreObjectNotFoundErrorWithContextProps) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error): StoreObjectNotFoundErrorWithContextState {
    return { error };
  }

  override render(): ReactNode {
    const { objectName, children } = this.props;
    const { error } = this.state;

    if (error instanceof StoreObjectNotFoundError) {
      throw new ObjectNotFoundError('Object not found', objectName, error.data.objectId, error);
    } else if (error) {
      throw error;
    } else {
      return children;
    }
  }
}

export default StoreObjectNotFoundErrorBoundary;
