import { computeEffectiveRangeForPeriodAndDates } from 'yooi-utils';
import i18n from '../../../../../utils/i18n';
import type { TemporalFeatureResolvedDefinition } from './temporalFeatureResolvedDefinition';

export const getTemporalRange = (definition: TemporalFeatureResolvedDefinition): { from: number, to: number } | undefined => {
  const formatRange = computeEffectiveRangeForPeriodAndDates(
    definition.dateRange?.period,
    definition.dateRange?.from,
    definition.dateRange?.to
  );

  const from = formatRange?.from?.getTime();
  const to = formatRange?.to?.getTime();

  if (!from || !to) {
    return undefined;
  }

  return { from, to };
};

export const getTemporalError = (viewDefinition: TemporalFeatureResolvedDefinition): undefined | string => {
  if (!viewDefinition.dateRange) {
    return i18n`Missing time range`;
  }
  return undefined;
};
