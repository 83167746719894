import type { FunctionComponent } from 'react';
import type { ConceptStoreObject } from 'yooi-modules/modules/conceptModule';
import { getFieldDimensionOfModelType, getFieldUtilsHandler, hasPlatformCapability, InvalidFieldError } from 'yooi-modules/modules/conceptModule';
import { Concept, Group_Users, PlatformCapabilityAdmin, User_Groups } from 'yooi-modules/modules/conceptModule/ids';
import { doExtends } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { StoreObject } from 'yooi-store';
import { joinObjects, newError } from 'yooi-utils';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { formatErrorForUser, formatFieldResolutionErrorForUser } from '../../errorUtils';
import type { BlockFieldProps } from '../FieldLibraryTypes';
import ConceptTableBlockField from './ConceptTableBlockField';

interface ConceptListDisplayProps {
  fieldId: string,
  conceptId: string,
  blockFieldProps: BlockFieldProps,
  lineContext?: (item: StoreObject) => string[],
  createNewInstance?: () => string,
}

const ConceptListDisplay: FunctionComponent<ConceptListDisplayProps> = ({
  fieldId,
  conceptId,
  blockFieldProps,
  lineContext,
  createNewInstance,
}) => {
  const store = useStore();
  const { loggedUserId } = useAuth();
  const { canCreateObject } = useAcl();

  const { getTargetType, getValueResolution } = getFieldUtilsHandler(store, fieldId);
  const targetType = getTargetType?.();
  const concept = store.getObjectOrNull(conceptId);
  const dimensionId = concept ? getFieldDimensionOfModelType(store, fieldId, concept[Instance_Of] as string) : undefined;

  if (!targetType) {
    throw newError('Target type is missing for field', { fieldId });
  }

  let resolvedError;
  const valueResolution = getValueResolution(dimensionId ? { [dimensionId]: conceptId } : {});
  const { value: resolvedValue, isComputed } = valueResolution;
  const value = resolvedValue as ConceptStoreObject[] ?? [];
  if (valueResolution.error) {
    resolvedError = formatFieldResolutionErrorForUser(store, valueResolution.error, fieldId);
  }
  const isRelationMultipleComputed = isComputed;
  const userIsAdmin = hasPlatformCapability(store, loggedUserId, PlatformCapabilityAdmin);
  const isReadOnlyGroupMembershipAssociation = ((fieldId === User_Groups) || (fieldId === Group_Users)) && !userIsAdmin;
  const { error: sanityError } = getFieldUtilsHandler(store, fieldId).isSaneValue(conceptId) ?? {};
  let error: string | undefined;
  if (resolvedError) {
    error = resolvedError;
  } else if (sanityError) {
    error = formatErrorForUser(store, new InvalidFieldError(fieldId, sanityError));
  }
  const targetsMultipleModels = getTargetType?.()?.id === Concept;

  const canCreate = doExtends(targetType, Concept) && canCreateObject(targetType.id);
  const blockFieldPropsExtended: BlockFieldProps = joinObjects(
    blockFieldProps,
    { readOnly: blockFieldProps.readOnly || isRelationMultipleComputed || isReadOnlyGroupMembershipAssociation }
  );
  return (
    <ConceptTableBlockField
      fieldId={fieldId}
      conceptId={conceptId}
      value={value}
      error={targetsMultipleModels ? i18n`Table is displaying instances of different types` : error}
      createNewInstance={canCreate ? createNewInstance : undefined}
      blockFieldProps={blockFieldPropsExtended}
      lineContext={lineContext}
    />
  );
};

export default ConceptListDisplay;
