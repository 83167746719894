import type { FunctionComponent } from 'react';
import Typo from '../../../../components/atoms/Typo';
import TextInputString from '../../../../components/inputs/TextInputString';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import { Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { SizeContextProvider, SizeVariant } from '../../../../utils/useSizeContext';
import useTheme from '../../../../utils/useTheme';
import TooltipContainer from '../../TooltipContainer';

const useStyles = makeStyles({
  firstColumnContainer: {
    width: '10rem',
  },
  inlineContainer: {
    minWidth: '15rem',
  },
}, 'blockFieldTooltip');

interface BlockFieldTooltipProps {
  documentation?: { value: string, isInline: boolean },
}

const BlockFieldTooltip: FunctionComponent<BlockFieldTooltipProps> = ({ documentation }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <TooltipContainer>
      <SpacedContainer margin={Spacing.s}>
        <SizeContextProvider sizeVariant={SizeVariant.small}>
          {documentation?.value && !documentation?.isInline && (
            <div>
              <SpacingLine>
                <div className={classes.firstColumnContainer}>
                  <Typo color={theme.color.text.disabled} noWrap>{i18n`Documentation`}</Typo>
                </div>
                <div className={classes.inlineContainer}>
                  <TextInputString value={documentation?.value} readOnly />
                </div>
              </SpacingLine>
            </div>
          )}
        </SizeContextProvider>
      </SpacedContainer>
    </TooltipContainer>
  );
};

export default BlockFieldTooltip;
