import type { DateFieldStoreValue } from 'yooi-utils';
import { newError } from 'yooi-utils';
import { compareNumber, compareProperty, comparing } from 'yooi-utils/src/comparators';
import { DATE_MAX_TIMESTAMP, DATE_MIN_TIMESTAMP } from 'yooi-utils/src/dateUtils';
import type { FormulaType } from 'yooi-utils/src/formula2/engine/formula';
import { newFunctionLibrary } from 'yooi-utils/src/formula2/engine/functionLibraryBuilder';
import { FunctionInvalidArgumentTypeError } from 'yooi-utils/src/formula2/functionLibrary/errors';
import { checkArgumentCount, checkArgumentType, checks } from 'yooi-utils/src/formula2/functionLibrary/utils';
import { anyType, arrayOf, getMostCommonType, numberType } from 'yooi-utils/src/formula2/typeSystem';
import { dateType } from './dateFunctions';

export interface TimeseriesFormulaType extends FormulaType {
  readonly elementType: FormulaType,
}

export const isRhythmicTimeseriesFormulaType = (formulaType: FormulaType): formulaType is TimeseriesFormulaType => (
  Object.prototype.hasOwnProperty.call(formulaType, 'elementType')
  && formulaType.name.startsWith('rhythmicTimeseries')
);

export const rhythmicTimeseriesOf = (elementType: FormulaType): TimeseriesFormulaType => {
  const equals = (type: FormulaType) => Boolean(isRhythmicTimeseriesFormulaType(type) && type.elementType.equals(elementType));
  return {
    name: `rhythmicTimeseries<${elementType.name}>`,
    elementType,
    equals,
    isAssignableFrom: (type) => ((isRhythmicTimeseriesFormulaType(type) ? type.elementType : type) === anyType) || equals(type),
  };
};

export const isArrhythmicTimeseriesFormulaType = (formulaType: FormulaType): formulaType is TimeseriesFormulaType => (
  Object.prototype.hasOwnProperty.call(formulaType, 'elementType')
  && formulaType.name.startsWith('arrhythmicTimeseries')
);

export const arrhythmicTimeseriesOf = (elementType: FormulaType): TimeseriesFormulaType => {
  const equals = (type: FormulaType) => Boolean(isArrhythmicTimeseriesFormulaType(type) && type.elementType.equals(elementType));
  return {
    name: `arrhythmicTimeseries<${elementType.name}>`,
    elementType,
    equals,
    isAssignableFrom: (type) => ((isArrhythmicTimeseriesFormulaType(type) ? type.elementType : type) === anyType) || equals(type),
  };
};

const isTimeseriesFormulaType = (formulaType: FormulaType): formulaType is TimeseriesFormulaType => (
  isRhythmicTimeseriesFormulaType(formulaType) || isArrhythmicTimeseriesFormulaType(formulaType)
);

const checkTimeseriesArgumentType = (functionName: string, expectedType: FormulaType, argTypes: FormulaType[], argIndex: number): Error | undefined => {
  const arg = argTypes[argIndex];
  return isTimeseriesFormulaType(arg) && expectedType.isAssignableFrom(arg.elementType)
    ? undefined
    : new FunctionInvalidArgumentTypeError(functionName, [rhythmicTimeseriesOf(expectedType), arrhythmicTimeseriesOf(expectedType)], arg, argIndex);
};

export const timeseriesFunctions = newFunctionLibrary()
  .addFunction(
    'LASTVALUE',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => {
        const checkError = checks(
          () => checkArgumentCount(name, argTypes, 1, 3),
          () => checkTimeseriesArgumentType(name, anyType, argTypes, 0),
          () => (argTypes.length <= 1 ? undefined : checkArgumentType(name, [numberType, dateType], argTypes, 1)),
          () => (
            argTypes.length <= 2
              ? undefined
              : checkArgumentType(
                name,
                isTimeseriesFormulaType(argTypes[0]) ? argTypes[0].elementType : anyType,
                argTypes,
                2
              )
          )
        );

        if (checkError) {
          return checkError;
        }

        const { mostCommonType } = getMostCommonType([
          isTimeseriesFormulaType(argTypes[0]) ? argTypes[0].elementType : anyType,
          argTypes.length > 2 ? argTypes[2] : anyType,
        ]);

        return {
          type: mostCommonType,
          jsFunction: (timeseries: { time: number, value: unknown }[] | undefined, date: number | DateFieldStoreValue | undefined, defaultValue: unknown) => {
            // eslint-disable-next-line yooi/number-is-nan-call
            if (Number.isNaN(date)) {
              throw newError('LASTVALUE date is invalid', { date });
            } else if (timeseries === undefined || timeseries.length === 0) {
              return defaultValue;
            }

            let timestamp: number;
            if (date === undefined) {
              timestamp = DATE_MAX_TIMESTAMP;
            } else if (typeof date === 'number') {
              timestamp = date;
            } else {
              timestamp = date.date;
            }

            const foundValue = [...timeseries].sort(comparing(compareProperty('time', compareNumber), true)).find(({ time }) => time <= timestamp)?.value;
            return foundValue ?? defaultValue;
          },
        };
      },
    })
  )
  .addFunction(
    'VALUEAT',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => {
        const checkError = checks(
          () => checkArgumentCount(name, argTypes, 2, 3),
          () => checkTimeseriesArgumentType(name, anyType, argTypes, 0),
          () => checkArgumentType(name, [numberType, dateType], argTypes, 1),
          () => (
            argTypes.length <= 2
              ? undefined
              : checkArgumentType(
                name,
                isTimeseriesFormulaType(argTypes[0]) ? argTypes[0].elementType : anyType,
                argTypes,
                2
              )
          )
        );

        if (checkError) {
          return checkError;
        }

        const { mostCommonType } = getMostCommonType([
          isTimeseriesFormulaType(argTypes[0]) ? argTypes[0].elementType : anyType,
          argTypes.length > 2 ? argTypes[2] : anyType,
        ]);

        return {
          type: mostCommonType,
          jsFunction: (timeseries: { time: number, value: unknown }[] | undefined, date: number | DateFieldStoreValue | undefined, defaultValue: unknown) => {
            // eslint-disable-next-line yooi/number-is-nan-call
            if (Number.isNaN(date)) {
              throw newError('VALUEAT date is invalid', { date });
            } else if (timeseries === undefined || timeseries.length === 0 || date === undefined) {
              return defaultValue;
            }

            const timestamp = typeof date === 'number' ? date : date.date;
            return timeseries.find(({ time }) => time === timestamp)?.value ?? defaultValue;
          },
        };
      },
    })
  )
  .addFunction(
    'VALUESIN',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 3),
          () => checkTimeseriesArgumentType(name, anyType, argTypes, 0),
          () => (argTypes.length <= 1 ? undefined : checkArgumentType(name, [numberType, dateType], argTypes, 1)),
          () => (argTypes.length <= 2 ? undefined : checkArgumentType(name, [numberType, dateType], argTypes, 2))
        ) ?? {
          type: arrayOf((argTypes[0] as TimeseriesFormulaType).elementType),
          jsFunction: (
            timeseries: { time: number, value: unknown }[] | undefined,
            startDate: number | DateFieldStoreValue | undefined,
            endDate: number | DateFieldStoreValue | undefined
          ) => {
            // eslint-disable-next-line yooi/number-is-nan-call
            if (Number.isNaN(startDate)) {
              throw newError('VALUESIN startDate is invalid', { startDate });
              // eslint-disable-next-line yooi/number-is-nan-call
            } else if (Number.isNaN(endDate)) {
              throw newError('VALUESIN endDate is invalid', { endDate });
            } else if (timeseries === undefined || timeseries.length === 0) {
              return [];
            }

            let startTimestamp: number;
            if (startDate === undefined) {
              startTimestamp = DATE_MIN_TIMESTAMP;
            } else if (typeof startDate === 'number') {
              startTimestamp = startDate;
            } else {
              startTimestamp = startDate.date;
            }

            let endTimestamp: number;
            if (endDate === undefined) {
              endTimestamp = DATE_MAX_TIMESTAMP;
            } else if (typeof endDate === 'number') {
              endTimestamp = endDate;
            } else {
              endTimestamp = endDate.date;
            }

            return timeseries
              .filter(({ value }) => value !== undefined)
              .filter(({ time }) => time >= startTimestamp && time <= endTimestamp)
              .map(({ value }) => value);
          },
        }),
    })
  )
  .build();
