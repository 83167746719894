import classnames from 'classnames';
import type { FunctionComponent, RefCallback } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import type { VariableSizeList } from 'react-window';
import { VariableSizeList as List } from 'react-window';
import { useDebouncedCallback } from 'use-debounce';
import { Opacity } from '../../theme/base';
import { generateColorFromOpacity, getMostReadableColorFromBackgroundColor } from '../../theme/colorUtils';
import { spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import useDerivedState from '../../utils/useDerivedState';
import useTheme from '../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../utils/useUsageContext';
import Typo from '../atoms/Typo';
import TextInputString from '../inputs/TextInputString';
import type { MasterDetailLineGroupEntry, MasterDetailLineItemEntry } from './internal/MasterDetailLine';
import MasterDetailLine from './internal/MasterDetailLine';
import Spinner, { SpinnerVariant } from './Spinner';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.s,
    paddingTop: spacingRem.m,
    paddingLeft: spacingRem.m,
    paddingRight: spacingRem.m,
    zIndex: 1,
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '5.2rem',
    marginRight: spacingRem.xs,
  },
  resultsContainer: {
    display: 'flex',
    columnGap: spacingRem.s,
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '4.2rem',
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.transparent,
  },
  resultContainerBorder: {
    borderBottomColor: theme.color.border.default,
  },
  topShadow: {
    boxShadow: 'rgba(0, 0, 0, 0.02) 0 2rem 0 -1.6rem',
  },
}), 'masterDetailList');

interface ItemEntry extends MasterDetailLineItemEntry {
  customHeight?: number,
}

interface GroupEntry extends MasterDetailLineGroupEntry {
  customHeight?: number,
}

interface MasterDetailListProps {
  list: (ItemEntry | GroupEntry)[],
  search: { value: string | undefined, setValue: (newSearch: string | undefined) => void },
  loading?: boolean,
}

const MasterDetailList: FunctionComponent<MasterDetailListProps> = ({ list, search, loading = false }) => {
  const theme = useTheme();
  const classes = useStyles();

  const numberOfItem = list.filter(({ type }) => type === 'item').length;

  const topRef = useRef<HTMLDivElement | null>(null);
  const resultContainerRef = useRef<HTMLDivElement | null>(null);
  const lastScrollDivRef = useRef<HTMLElement | null>(null);
  const onScroll = useCallback(() => {
    if (lastScrollDivRef.current !== null) {
      if (topRef.current !== null) {
        if (lastScrollDivRef.current.scrollTop === 0) {
          if (topRef.current.className.includes(classes.topShadow)) {
            topRef.current.className = topRef.current.className.split(' ').filter((name) => name !== classes.topShadow).join(' ');
          }
        } else if (!topRef.current.className.includes(classes.topShadow)) {
          topRef.current.className += ` ${classes.topShadow}`;
        }
      }
      if (resultContainerRef.current !== null) {
        if (lastScrollDivRef.current.scrollTop === 0) {
          if (resultContainerRef.current.className.includes(classes.resultContainerBorder)) {
            resultContainerRef.current.className = resultContainerRef.current.className.split(' ').filter((name) => name !== classes.resultContainerBorder).join(' ');
          }
        } else if (!resultContainerRef.current.className.includes(classes.resultContainerBorder)) {
          resultContainerRef.current.className += ` ${classes.resultContainerBorder}`;
        }
      }
    }
  }, [classes.topShadow, classes.resultContainerBorder]);
  const onScrollDiv = useCallback<RefCallback<HTMLDivElement>>((node) => {
    if (lastScrollDivRef.current !== null) {
      lastScrollDivRef.current.removeEventListener('scroll', onScroll);
    }
    lastScrollDivRef.current = node;
    if (node !== null) {
      node.addEventListener('scroll', onScroll);
    }
  }, [onScroll]);

  const lazyListContainerRef = useRef<VariableSizeList>(null);

  // Effect is used to track list change and tell the list component to reset its size state
  useEffect(() => {
    if (lazyListContainerRef.current && list.length) {
      lazyListContainerRef.current.resetAfterIndex(0, true);
    }
  }, [list]);

  const [searchValue, setSearchValue] = useDerivedState<string | undefined>(() => search.value, [search.value]);
  const debouncedSetSearchValue = useDebouncedCallback(search.setValue, 300);

  const mostReadableColor = getMostReadableColorFromBackgroundColor(theme.color.background.neutral.default);

  return (
    <div className={classes.container}>
      <div
        ref={topRef}
        className={classnames({
          [classes.top]: true,
          [classes.topShadow]: lastScrollDivRef.current !== null && lastScrollDivRef.current.scrollTop > 0,
        })}
      >
        <div className={classes.searchContainer}>
          <UsageContextProvider usageVariant={UsageVariant.inForm}>
            <TextInputString
              value={searchValue}
              placeholder={i18n`Search`}
              onChange={(newSearchValue) => {
                setSearchValue(newSearchValue);
                debouncedSetSearchValue(newSearchValue);
              }}
              onClear={() => {
                setSearchValue(undefined);
                debouncedSetSearchValue(undefined);
              }}
              fullWidth
            />
          </UsageContextProvider>
        </div>
        <div
          ref={resultContainerRef}
          className={classnames({
            [classes.resultsContainer]: true,
            [classes.resultContainerBorder]: lastScrollDivRef.current !== null && lastScrollDivRef.current.scrollTop > 0,
          })}
        >
          {
            loading ? (
              <Spinner
                size={SpinnerVariant.small}
                color={generateColorFromOpacity(mostReadableColor, theme.color.background.neutral.default, Opacity.fifty)}
              />
            ) : (<span />)
          }
          <Typo color={theme.color.text.secondary}>{numberOfItem !== 1 ? i18n`${numberOfItem} results` : i18n`1 result`}</Typo>
        </div>
      </div>
      <div className={classes.listContainer}>
        <AutoSizer>
          {({ height = 0, width = 0 }) => (
            <List<(ItemEntry | GroupEntry)[]>
              ref={lazyListContainerRef}
              outerRef={onScrollDiv}
              height={height}
              width={width}
              itemCount={list.length}
              itemData={list}
              itemSize={(index) => list[index].customHeight ?? (list[index].type === 'group' ? remToPx(3.6) : remToPx(4))}
              estimatedItemSize={remToPx(4)}
              // Override list styles
              // eslint-disable-next-line yooi/check-constant-styles
              style={{ overflow: 'scroll' }}
            >
              {MasterDetailLine}
            </List>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

export default MasterDetailList;
