import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { CollaborationMessageStoreObject } from 'yooi-modules/modules/collaborationModule';
import { buildCollaborationLocation, getCollaborationContextsObjectIds } from 'yooi-modules/modules/collaborationModule';
import {
  CollaborationMessage_Collaboration,
  CollaborationMessage_CreatedAt,
  CollaborationMessage_CreatedBy,
  CollaborationMessage_Text,
} from 'yooi-modules/modules/collaborationModule/ids';
import { Concept_Name, ConceptDefinition } from 'yooi-modules/modules/conceptModule/ids';
import type { NotificationStoreObject } from 'yooi-modules/modules/notificationModule';
import { Notification_Seen } from 'yooi-modules/modules/notificationModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import type { RichText } from 'yooi-utils';
import { richTextToText } from 'yooi-utils';
import Avatar, { AvatarSizes, AvatarVariant } from '../../../components/atoms/Avatar';
import { IconName } from '../../../components/atoms/Icon';
import Typo from '../../../components/atoms/Typo';
import Chip from '../../../components/molecules/Chip';
import OverflowMenu from '../../../components/molecules/OverflowMenu';
import useAuth from '../../../store/useAuth';
import useStore from '../../../store/useStore';
import { FontVariant } from '../../../theme/fontDefinition';
import { getSpacingAsNumber, Spacing, spacingRem } from '../../../theme/spacingDefinition';
import { formatENDisplayDate } from '../../../utils/dateUtilsFront';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { remToPx } from '../../../utils/sizeUtils';
import useNavigation from '../../../utils/useNavigation';
import { SessionStorageKeys, useSessionStorageState } from '../../../utils/useSessionStorage';
import useTheme from '../../../utils/useTheme';
import useTooltipRef from '../../../utils/useTooltipRef';
import { getChipOptionWithUnknown } from '../modelTypeUtils';
import { getCurrentNavigationId } from '../navigationUtils';
import { formatRelativeDate } from '../rightPanel/collaboration/utils/collaborationUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacingRem.m,
    rowGap: spacingRem.xs,
    backgroundColor: theme.color.background.neutral.default,
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: theme.color.background.neutral.subtle,
    },
  },
  topLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '3.2rem',
    columnGap: spacingRem.s,
  },
  authorLine: {
    display: 'grid',
    gridTemplateColumns: 'auto auto minmax(9rem, 1fr)',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
  lineGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
  contentLine: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: spacingRem.s,
    paddingTop: spacingRem.s,
  },
  fade: {
    opacity: 0.6,
  },
  greyLine: {
    borderBottom: `0.1rem solid ${theme.color.border.default}`,
    marginLeft: spacingRem.m,
    marginRight: spacingRem.m,
  },
}), 'notificationCard');

interface NotificationCardProps {
  notificationId: string[],
}

const NotificationCard: FunctionComponent<NotificationCardProps> = ({ notificationId }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const [isHovered, setIsHovered] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const { loggedUserId } = useAuth();
  const navigation = useNavigation();
  const [, setIsNotificationsPopoverOpened] = useSessionStorageState<boolean>(SessionStorageKeys.notifications, false);

  const updateReadState = (notificationStoreId: string[], state: boolean) => store.updateObject(notificationStoreId, { [Notification_Seen]: state });

  const notification = store.getObject<NotificationStoreObject>(notificationId);
  const messageId = notificationId[notificationId.length - 1];
  const message = store.getObject<CollaborationMessageStoreObject>(messageId);
  const authorName = richTextToText(message.navigate(CollaborationMessage_CreatedBy)?.[Concept_Name] as RichText | undefined);
  const date = new Date(message[CollaborationMessage_CreatedAt] ?? 0);
  const formattedFullDate = formatENDisplayDate(date);
  const relativeDate = formatRelativeDate(date);
  const authorTooltipRef = useTooltipRef(authorName);
  const dateTooltipRef = useTooltipRef(formattedFullDate);
  const collaboration = message.navigateOrNull(CollaborationMessage_Collaboration);
  const readNotifAndNavigateToCollaboration = () => {
    if (collaboration) {
      const collaborationHistoryLocation = buildCollaborationLocation(store, collaboration.id, message.id);
      const conceptId = getCurrentNavigationId(store, collaborationHistoryLocation.pathname ?? '', loggedUserId);
      if (conceptId) {
        store.updateObject(notificationId, { [Notification_Seen]: true });
        return navigation.pushPayload(navigation.createNavigationPayload(conceptId, collaborationHistoryLocation));
      }
      return undefined;
    }
  };
  const seen = notification[Notification_Seen] ?? false;
  let onInstanceId;
  // TODO : getSubscriptionInstanceForCollaboration from YOOI-2114
  if (collaboration) {
    const collaborationContexts = getCollaborationContextsObjectIds(store, collaboration.id);
    onInstanceId = collaborationContexts.find((id) => {
      const object = store.getObjectOrNull(id);
      return !isInstanceOf(object, ConceptDefinition);
    });
  }
  const instanceChipOptions = onInstanceId ? getChipOptionWithUnknown(store, onInstanceId) : undefined;

  return (
    <>
      <div
        className={classnames({ [classes.container]: true, [classes.fade]: seen })}
        tabIndex={0}
        onClick={() => {
          readNotifAndNavigateToCollaboration();
          setIsNotificationsPopoverOpened(false);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && !event.shiftKey) {
            readNotifAndNavigateToCollaboration();
            setIsNotificationsPopoverOpened(false);
          }
        }}
        role="button"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={classes.topLine}>
          <div className={classes.authorLine}>
            <Avatar name={authorName?.charAt(0).toUpperCase() || '...'} tooltip={authorName} variant={AvatarVariant.messageUser} size={AvatarSizes.small} showChip={!seen} />
            <Typo ref={authorTooltipRef} maxLine={1}>{authorName}</Typo>
            <Typo ref={dateTooltipRef} maxLine={1} color={theme.color.text.secondary} variant={FontVariant.small}>{relativeDate}</Typo>
          </div>
          {(isHovered || isMenuOpened)
            && (
              <OverflowMenu
                menuItems={[
                  {
                    key: 'read/unread',
                    name: seen ? i18n`Mark as unread` : i18n`Mark as read`,
                    icon: seen ? IconName.mark_chat_unread : IconName.mark_chat_read,
                    onClick: () => updateReadState(notificationId, !seen),
                  },
                  {
                    key: 'delete',
                    name: i18n`Delete`,
                    icon: IconName.delete,
                    onClick: () => store.deleteObject(notificationId),
                    danger: true,
                  }]}
                placement="bottom-end"
                offset={[0, remToPx(getSpacingAsNumber(Spacing.xs))]}
                onOpen={() => setIsMenuOpened(true)}
                onClose={() => setIsMenuOpened(false)}
              />
            )}
        </div>
        <div className={classes.contentLine}>
          <Typo maxLine={1}>{message[CollaborationMessage_Text]}</Typo>
        </div>
        <div className={classes.bottomLine}>
          <div className={classes.lineGroup}>
            {
              instanceChipOptions !== undefined
                ? (
                  <Chip
                    text={instanceChipOptions.label}
                    tooltip={instanceChipOptions.tooltip}
                    icon={instanceChipOptions.icon}
                    squareColor={instanceChipOptions.squareColor}
                    color={instanceChipOptions.color}
                    borderStyle={instanceChipOptions.borderStyle}
                  />
                )
                : null
            }
          </div>
        </div>
      </div>
      <div className={classes.greyLine} />
    </>
  );
};

export default NotificationCard;
