let id = 0;

const makeSelectorsClasses = <Key extends string>(...args: Key[]): Record<Key, string> => {
  const result: Record<Key, string> = {} as Record<Key, string>;
  args.forEach((a) => {
    id += 1;
    result[a] = `yooiSelector-${a}-${id}`;
  });
  return result;
};

export default makeSelectorsClasses;
