import { allFunctions as baseFunctions } from 'yooi-utils';
import { newFunctionLibrary } from 'yooi-utils/src/formula2/engine/functionLibraryBuilder';
import { dateFunctions } from './dateFunctions';
import { modelFunctions } from './modelFunctions';
import { richTextFunctions } from './richTextFunctions';
import { timeseriesFunctions } from './timeseriesFunctions';

export const allFunctions = newFunctionLibrary()
  .addLibrary(baseFunctions)
  .addLibrary(dateFunctions)
  .addLibrary(modelFunctions)
  .addLibrary(richTextFunctions)
  .addLibrary(timeseriesFunctions)
  .build();
