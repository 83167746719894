import type { FieldBlockDisplayOptions, FieldBlockDisplayStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import type {
  AssociationFilterStoreObject,
  ConceptDefinitionStoreObject,
  ConditionFilterStoreObject, MultipleRelationFieldExportConfiguration,
  ReverseWorkflowFieldRaw,
  ReverseWorkflowFieldStoreObject,
  WorkflowFieldRaw,
} from 'yooi-modules/modules/conceptModule';
import { getAllFieldDimensionsLinkedConceptDefinitionIds, reverseWorkflowFieldHandler } from 'yooi-modules/modules/conceptModule';
import {
  ConceptDefinition,
  ConceptDefinition_ChipBackgroundColor,
  ConceptDefinition_Icon,
  Field_Title,
  FieldBlockDisplay_ViewFilters,
  ReverseWorkflowField_ReverseField,
} from 'yooi-modules/modules/conceptModule/ids';
import type { ViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { filterNullOrUndefined } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import SearchAndSelectMultiple from '../../../../components/molecules/SearchAndSelectMultiple';
import i18n from '../../../../utils/i18n';
import { computeBorderColor, conceptDefinitionChipBackgroundColor } from '../../conceptDisplayUtils';
import { defaultOptionComparator, getChipOptions, getConceptDefinitionNameOrEntity } from '../../modelTypeUtils';
import MultipleRelationExportConfiguration from '../_global/MultipleRelationExportConfiguration';
import { getViewsBlockDisplayOptionsHandler } from '../_global/viewsBlockFieldUtils';
import { defaultWidgetDisplay } from '../_global/widgetUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import { FIELD_EDITION_DIMENSIONS, getFieldDimensionsEditionHandlerValue } from '../fieldDimensionUtils';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode } from '../FieldLibraryTypes';
import { ViewLoadingStateContextProvider } from '../viewsField/useViewLoadingState';
import ViewsGroupBlock from '../viewsField/ViewsGroupBlock';

interface ReverseWorkflowFieldBlockDisplayOptions extends FieldBlockDisplayOptions {
  viewDefinitions?: ViewStoredDefinition[],
  withFilters?: boolean,
}

interface ReverseWorkflowFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  reverseName: string | null | undefined,
  [ReverseWorkflowField_ReverseField]: string | undefined,
}

type ReverseWorkflowFieldDefinition = GetFieldDefinitionHandler<
  typeof reverseWorkflowFieldHandler,
  ReverseWorkflowFieldConfigurationState,
  never,
  ReverseWorkflowFieldBlockDisplayOptions,
  MultipleRelationFieldExportConfiguration
>;

export const reverseWorkflowFieldDefinition: ReverseWorkflowFieldDefinition = registerFieldDefinition(reverseWorkflowFieldHandler, {
  configuration: {
    typeIcon: IconName.rebase,
    getTypeLabel: () => i18n`Reverse workflow`,
    asWidget: false,
    getFieldTitle: (objectStore) => (optionHandler) => {
      const workflowFieldId = optionHandler.getValue(ReverseWorkflowField_ReverseField);
      if (workflowFieldId !== undefined) {
        const conceptDefinitionIds = getAllFieldDimensionsLinkedConceptDefinitionIds(objectStore, workflowFieldId);
        if (conceptDefinitionIds.length === 1) {
          return i18n`Reverse workflow with "${getConceptDefinitionNameOrEntity(objectStore, conceptDefinitionIds[0])}"`;
        }
      }
      return i18n`Reverse workflow`;
    },
    getEditionOptions: (objectStore) => ({ mode, editionHandler }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        return [];
      }

      const workflowFieldId = editionHandler.getValue(ReverseWorkflowField_ReverseField);
      let targetTypeId: string | undefined;
      if (workflowFieldId !== undefined) {
        const conceptDefinitionIds = getAllFieldDimensionsLinkedConceptDefinitionIds(objectStore, workflowFieldId);
        if (conceptDefinitionIds.length === 1) {
          targetTypeId = conceptDefinitionIds.at(0);
        }
      }

      const fieldTitle = editionHandler.getValue(Field_Title);
      return [
        {
          key: 'display',
          type: 'section',
          title: i18n`Data`,
          options: [
            {
              key: 'targetType',
              title: i18n`With`,
              hasValue: () => true,
              clearValue: () => {},
              type: EditionOptionTypes.select,
              props: {
                selectedOption: typeof targetTypeId === 'string' ? getChipOptions(objectStore, targetTypeId) : undefined,
                computeOptions: () => [],
                onChange: () => {},
                readOnly: true,
              },
            },
            {
              key: 'reverseName',
              title: i18n`Reverse name`,
              hasValue: () => editionHandler.getValue('reverseName') !== undefined,
              clearValue: () => editionHandler.updateValues({ reverseName: null }),
              type: EditionOptionTypes.text,
              props: {
                placeholder: fieldTitle ? i18n`Reverse of "${fieldTitle}"` : i18n`Add reverse name`,
                value: editionHandler.getValueOrDefault('reverseName'),
                onChange: (value) => editionHandler.updateValues({ reverseName: value }),
              },
            },
          ],
        },
      ];
    },
    ofField: (objectStore, fieldId, handler) => ({
      getTypeLabel: () => {
        const targetType = handler.getTargetType?.();
        if (targetType && isInstanceOf<ConceptDefinitionStoreObject>(targetType, ConceptDefinition)) {
          return i18n`Reverse workflow with "${getConceptDefinitionNameOrEntity(objectStore, targetType.id)}"`;
        } else {
          return i18n`Reverse workflow`;
        }
      },
      getIcon: () => {
        const targetType = handler.getTargetType?.();
        if (targetType && isInstanceOf<ConceptDefinitionStoreObject>(targetType, ConceptDefinition)) {
          return {
            name: targetType[ConceptDefinition_Icon] as IconName,
            borderColor: computeBorderColor(targetType[ConceptDefinition_ChipBackgroundColor] ?? conceptDefinitionChipBackgroundColor),
            color: conceptDefinitionChipBackgroundColor,
          };
        } else {
          return undefined;
        }
      },
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<ReverseWorkflowFieldStoreObject>(fieldId);
        return {
          [Field_Title]: field[Field_Title],
          reverseName: field.navigateOrNull<ReverseWorkflowFieldStoreObject>(ReverseWorkflowField_ReverseField)?.[Field_Title],
          [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
          [ReverseWorkflowField_ReverseField]: field[ReverseWorkflowField_ReverseField],
        };
      },
      submitFieldUpdate: (stateToSubmit) => {
        objectStore.updateObject<ReverseWorkflowFieldRaw>(fieldId, { [Field_Title]: stateToSubmit[Field_Title] });
        const workflowField = objectStore.getObjectOrNull<ReverseWorkflowFieldStoreObject>(fieldId)?.navigateOrNull(ReverseWorkflowField_ReverseField) ?? undefined;
        if (workflowField !== undefined) {
          objectStore.updateObject<WorkflowFieldRaw>(workflowField.id, { [Field_Title]: stateToSubmit.reverseName });
        }
      },
    }),
  },
  renderField: (store, fieldId) => ({ dimensionsMapping }) => {
    const fieldHandler = reverseWorkflowFieldHandler(store, fieldId);
    const valueResolution = fieldHandler.getValueResolution(dimensionsMapping);
    return (
      <SearchAndSelectMultiple
        selectedOptions={
          valueResolution.value
            .map(({ id }) => getChipOptions(store, id))
            .filter(filterNullOrUndefined)
            .sort(defaultOptionComparator)
        }
        readOnly
      />
    );
  },
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({ key: fieldId, propertyId: fieldId, sortable: true, focusable: false }),
  blockDisplayOptionsHandler: getViewsBlockDisplayOptionsHandler,
  renderBlockField: (store, fieldId) => (_, displayOptions, blockFieldProps, layoutParametersMapping, viewDimensions, fieldBlockDisplayId) => {
    const { viewDefinitions, withFilters } = displayOptions;
    return (
      <ViewLoadingStateContextProvider>
        <ViewsGroupBlock
          fieldId={fieldId}
          viewDimensions={viewDimensions}
          viewFilters={{
            filterKey: fieldBlockDisplayId,
            hasFilters: withFilters,
            getViewFilters: () => store.getObject<FieldBlockDisplayStoreObject>(fieldBlockDisplayId)
              .navigateBack<AssociationFilterStoreObject | ConditionFilterStoreObject>(FieldBlockDisplay_ViewFilters),
          }}
          viewDefinitions={viewDefinitions ?? []}
          layoutParametersMapping={layoutParametersMapping}
          blockFieldProps={blockFieldProps}
          widgetDisplay={defaultWidgetDisplay}
        />
      </ViewLoadingStateContextProvider>
    );
  },
  renderExportConfiguration: () => ({ configuration, onChange, conceptDefinitionId }) => (
    <MultipleRelationExportConfiguration
      configuration={configuration}
      conceptDefinitionId={conceptDefinitionId}
      onChange={onChange}
    />
  ),
});
