import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { resolvePath } from 'react-router-dom';
import type { CardFieldButton } from 'yooi-modules/modules/conceptLayoutModule';
import { getConceptUrl, getFieldDimensionOfModelType, getFieldUtilsHandler } from 'yooi-modules/modules/conceptModule';
import { ConceptDefinition } from 'yooi-modules/modules/conceptModule/ids';
import type { CardFieldStoreObject } from 'yooi-modules/modules/dashboardModule';
import { CardFieldActionTypes } from 'yooi-modules/modules/dashboardModule';
import {
  CardField_Button, CardField_ButtonPositionX, CardField_ButtonPositionY,
  CardField_Image,
  CardField_ImageHeight,
  CardField_ImageMode,
  CardField_ImagePositionX,
  CardField_ImagePositionY,
  CardField_Subtitle,
  CardField_Text,
} from 'yooi-modules/modules/dashboardModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { CoverPositionX, CoverPositionY, filterNullOrUndefined, isValidLink } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../../components/atoms/Button';
import Cover from '../../../../components/atoms/Cover';
import Typo, { TypoVariant } from '../../../../components/atoms/Typo';
import InlineLink from '../../../../components/molecules/InlineLink';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import ScrollableWidgetContainer from '../../../../components/templates/ScrollableWidgetContainer';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import { formatOrUndef } from '../../../../utils/stringUtils';
import useNavigation from '../../../../utils/useNavigation';
import { SizeContextProvider, SizeVariant } from '../../../../utils/useSizeContext';
import useWidgetSizeContext from '../../../../utils/useWidgetSizeContext';
import type { NavigationFilter } from '../../navigationUtils';
import { getCurrentNavigationId } from '../../navigationUtils';
import { getFieldHandler } from '../FieldLibrary';

const buttonVariants: Record<Exclude<CardFieldButton['variant'], undefined>, ButtonVariant> = {
  primary: ButtonVariant.primary,
  secondary: ButtonVariant.secondary,
  tertiary: ButtonVariant.tertiary,
};

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: spacingRem.s,
    columnGap: spacingRem.s,
    paddingBottom: '0.2rem',
  },
  containerLeft: {
    justifyContent: 'start',
  },
  containerCenterV: {
    justifyContent: 'center',
  },
  containerRight: {
    justifyContent: 'end',
  },
  containerTop: {
    marginBottom: 'auto',
  },
  containerCenterH: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  containerBottom: {
    marginTop: 'auto',
  },
}, 'cardFieldWidget');

interface CardFieldWidgetProps {
  fieldId: string,
}

const CardFieldWidget: FunctionComponent<CardFieldWidgetProps> = ({ fieldId }) => {
  const classes = useStyles();

  const store = useStore();
  const { loggedUserId } = useAuth();
  const { canCreateObject } = useAcl();

  const navigation = useNavigation<NavigationFilter>();

  const { width, height } = useWidgetSizeContext();

  const field = store.getObject<CardFieldStoreObject>(fieldId);

  const imageUrl = field[CardField_Image] ? store.getAttachmentUrl(fieldId, CardField_Image)(field[CardField_Image], true) : undefined;

  const renderButtons = () => {
    const buttons = (field[CardField_Button] as CardFieldButton[] ?? [])
      .map((button) => {
        if (button.action === CardFieldActionTypes.CreateInstance) {
          const conceptDefinitionId = button.data;
          if (conceptDefinitionId && isInstanceOf(store.getObjectOrNull(conceptDefinitionId), ConceptDefinition)) {
            return (
              <Button
                key={button.id}
                title={formatOrUndef(button.label)}
                maxLine={1}
                disabled={!canCreateObject(conceptDefinitionId)}
                variant={button.variant === undefined ? undefined : buttonVariants[button.variant]}
                onClick={() => {
                  const objectId = store.createObject({ [Instance_Of]: conceptDefinitionId });
                  Object.entries(button.defaultValues ?? {})
                    .forEach(([fId, value]) => {
                      if (store.getObjectOrNull(fId)) {
                        const dimensionId = getFieldDimensionOfModelType(store, fId, conceptDefinitionId);
                        if (dimensionId) {
                          if (getFieldUtilsHandler(store, fId).resolveConfiguration().formula === undefined) {
                            getFieldHandler(store, fId)?.input?.persistStateOnConcept({ [dimensionId]: objectId }, value);
                          }
                        }
                      }
                    });
                  navigation.push(objectId, { pathname: getConceptUrl(store, objectId) ?? undefined });
                }}
              />
            );
          } else {
            return undefined;
          }
        } else if (button.action === CardFieldActionTypes.OpenUrl && button.data && isValidLink(button.data)) {
          const conceptId = getCurrentNavigationId(store, button.data, loggedUserId);
          const navigationPayload = conceptId ? navigation.createNavigationPayload(conceptId, resolvePath(button.data)) : undefined;
          return (
            <InlineLink isUserControlled {...navigationPayload} key={button.id} to={button.data} noStyle>
              <Button
                title={formatOrUndef(button.label)}
                maxLine={1}
                variant={button.variant === undefined ? undefined : buttonVariants[button.variant]}
              />
            </InlineLink>
          );
        } else {
          return undefined;
        }
      })
      .filter(filterNullOrUndefined);

    if (buttons.length > 0) {
      return (
        <div
          className={classnames({
            [classes.buttonContainer]: true,
            [classes.containerLeft]: !field[CardField_ButtonPositionX] || field[CardField_ButtonPositionX] === CoverPositionX.Left,
            [classes.containerCenterV]: field[CardField_ButtonPositionX] === CoverPositionX.Center,
            [classes.containerRight]: field[CardField_ButtonPositionX] === CoverPositionX.Right,
            [classes.containerTop]: field[CardField_ButtonPositionY] === CoverPositionY.Top,
            [classes.containerCenterH]: field[CardField_ButtonPositionY] === CoverPositionY.Center,
            [classes.containerBottom]: !field[CardField_ButtonPositionY] || field[CardField_ButtonPositionY] === CoverPositionY.Bottom,
          })}
        >
          {buttons}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <ScrollableWidgetContainer withRowGap height={height} width={width}>
      <>
        {imageUrl ? (
          <Cover
            url={imageUrl}
            mode={field[CardField_ImageMode]}
            positionX={field[CardField_ImagePositionX]}
            positionY={field[CardField_ImagePositionY]}
            height={field[CardField_ImageHeight]}
          />
        ) : null}
        {field[CardField_Subtitle] ? (
          <SpacingLine>
            <Typo variant={TypoVariant.tabTitle} fullWidth>{field[CardField_Subtitle]}</Typo>
          </SpacingLine>
        ) : null}
        {field[CardField_Text] ? (
          <SizeContextProvider sizeVariant={SizeVariant.small}>
            <Typo fullWidth>{field[CardField_Text]}</Typo>
          </SizeContextProvider>
        ) : null}
        {renderButtons()}
      </>
    </ScrollableWidgetContainer>
  );
};

export default CardFieldWidget;
