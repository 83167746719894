import { equals } from 'ramda';
import { joinObjects } from 'yooi-utils';
import useStore from '../../../store/useStore';
import useDeepMemo from '../../../utils/useDeepMemo';
import useDerivedState from '../../../utils/useDerivedState';
import { getFieldConfigurationHandler } from '../../_global/fields/FieldLibrary';
import type { EditionHandler } from '../../_global/fields/FieldLibraryTypes';

const useStoreFieldOverrideEditionHandler = (objectId: string, fieldId: string, conceptDefinitionId: string): EditionHandler => {
  const store = useStore();
  const fieldHandler = getFieldConfigurationHandler(store, fieldId);

  const defaultState = fieldHandler.getInitialState(conceptDefinitionId);

  const initialState = { ...(fieldHandler.getInitialOverrideState?.(objectId) ?? {}) };
  const memoizedInitialState = useDeepMemo<typeof initialState>(() => initialState, [initialState]);
  const [creationObject, setCreationObject, resetCreationObject] = useDerivedState(() => memoizedInitialState, [memoizedInitialState]);

  return {
    getValue: <T>(id: string) => (creationObject?.[id] ?? undefined) as T,
    getValueOrDefault: <T>(id: string) => (creationObject?.[id] ?? defaultState?.[id] ?? undefined) as T,
    updateValues: (update) => {
      setCreationObject((current) => joinObjects(current, update));
    },
    reset: () => {
      const beforeReset = creationObject;
      resetCreationObject();
      return beforeReset;
    },
    resetValue: (id) => {
      setCreationObject(joinObjects(creationObject, { [id]: memoizedInitialState?.[id] }));
    },
    hasModification: () => !equals(memoizedInitialState, creationObject),
  };
};

export default useStoreFieldOverrideEditionHandler;
