import type { FunctionComponent, ReactElement } from 'react';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';

const useStyles = makeStyles({
  toggleContainer: {
    display: 'flex',
    alignContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    WebkitLineClamp: 2,
    rowGap: spacingRem.s,
    columnGap: spacingRem.s,
  },
}, 'tagContainer');

interface TagContainerProps {
  children: ReactElement[],
}

const TagContainer: FunctionComponent<TagContainerProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.toggleContainer}>
      {children}
    </div>
  );
};

export default TagContainer;
