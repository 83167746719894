import { forwardRef } from 'react';
import type { ConnectDragSource } from 'react-dnd';
import type { PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { FilterConditionOperators } from 'yooi-modules/modules/conceptModule';
import { joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName, IconSizeVariant } from '../../../../components/atoms/Icon';
import { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import OverflowMenu from '../../../../components/molecules/OverflowMenu';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import BlockFilterInput from '../BlockFilterInput';
import type { FrontFilterCondition, FrontFilterNode, FrontFilters } from '../filterUtils';
import { isConditionSimple } from '../filterUtils';
import FilterOperator from './FilterOperator';

const useStyles = makeStyles({
  groupFiltersContainer: {
    display: 'grid',
    gridTemplateColumns: '6.5rem 1fr',
    gap: spacingRem.s,
  },
  groupFilterBlockMenu: {
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.s,
  },
  dragCursor: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
  },
}, 'filterCondition');

interface FilterConditionProps {
  filters: FrontFilterCondition,
  parentNode?: FrontFilterNode,
  readOnly?: boolean,
  filterParameterDefinitions: SingleParameterDefinition[],
  requiredConceptDefinitionId?: string,
  suggestedPaths?: { label: string, path: PathStep[] }[],
  rootPath?: { label: string, path: PathStep[] },
  onChange: (filterCondition: FrontFilters) => void,
  onNodeConditionChange?: (condition: FilterConditionOperators) => void,
  onDuplicate: (filterNode: FrontFilters) => void,
  onDelete: () => void,
  connectDragSource?: ConnectDragSource,
}

const FilterCondition = forwardRef<HTMLDivElement, FilterConditionProps>(({
  filters,
  parentNode,
  readOnly = false,
  filterParameterDefinitions,
  requiredConceptDefinitionId,
  suggestedPaths,
  rootPath,
  onNodeConditionChange,
  onChange,
  onDelete,
  onDuplicate,
  connectDragSource,
}, ref) => {
  const classes = useStyles();

  const index = parentNode?.children?.findIndex(({ id }) => id === filters.id) ?? 0;
  const { id: filterId, leftValue, operator, rightValue, isSimpleCondition } = filters;
  const selectedCondition = parentNode?.condition ?? FilterConditionOperators.OR;

  const basePath = rootPath ?? (suggestedPaths?.length === 1 ? suggestedPaths[0] : undefined);

  return (
    <div className={classes.groupFiltersContainer} ref={ref}>
      <FilterOperator index={index} selectedCondition={selectedCondition} readOnly={readOnly} onNodeConditionChange={onNodeConditionChange} />
      <div className={classes.groupFilterBlockMenu}>
        <BlockFilterInput
          isSimple={isSimpleCondition}
          filter={{ leftValue: leftValue ?? basePath?.path, operator, rightValue }}
          requiredConceptDefinitionId={requiredConceptDefinitionId}
          readOnly={readOnly}
          parameterDefinitions={filterParameterDefinitions}
          onChange={(newFilter) => {
            const newCondition = joinObjects(newFilter, { id: filterId });
            onChange(joinObjects(newCondition, { isSimpleCondition }));
          }}
          suggestedPaths={suggestedPaths}
          rootPath={rootPath}
        />
        {
          readOnly
            ? null
            : (
              <>
                <OverflowMenu
                  disabled={readOnly}
                  buttonVariant={IconOnlyButtonVariants.tertiary}
                  menuItems={[
                    {
                      key: 'duplicate',
                      name: i18n`Duplicate`,
                      icon: IconName.content_copy_outline,
                      onClick: () => {
                        onDuplicate(filters);
                      },
                    },
                    {
                      key: 'switch',
                      name: isSimpleCondition ? i18n`Turn into advanced condition` : i18n`Turn into simple condition`,
                      icon: IconName.repeat,
                      onClick: () => {
                        onChange(joinObjects(filters, { isSimpleCondition: !isSimpleCondition }));
                      },
                      hidden: !isSimpleCondition && !isConditionSimple(filters, basePath?.path),
                    },
                    {
                      key: 'delete',
                      name: i18n`Delete`,
                      icon: IconName.delete,
                      danger: true,
                      onClick: onDelete,
                    },
                  ]}
                />
                <div ref={connectDragSource} className={classes.dragCursor}>
                  <Icon name={IconName.drag_indicator} colorVariant={IconColorVariant.secondary} size={IconSizeVariant.l} />
                </div>
              </>
            )
        }
      </div>
    </div>
  );
});

export default FilterCondition;
