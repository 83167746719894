import type { FunctionComponent } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { TableViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import ScrollableWidgetContainer from '../../../../components/templates/ScrollableWidgetContainer';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { remToPx, SCROLLBAR_WIDTH_IN_REM } from '../../../../utils/sizeUtils';
import useDeepMemo from '../../../../utils/useDeepMemo';
import WidgetInvalidConfiguration from '../../fields/_global/WidgetInvalidConfiguration';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { useViewFilters } from '../../filter/useViewFilters';
import { getViewDefinitionHandler } from '../viewDsl';
import { isResolutionError } from '../viewResolutionUtils';
import TableView from './TableView';
import type { TableViewDefinitionHandler } from './tableViewHandler';

interface TableViewWidgetProps {
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinition: TableViewStoredDefinition,
  parametersMapping: ParametersMapping,
  groupByDefault: { groupById: string | undefined },
  width: number,
  height: number,
  readOnly: boolean | undefined,
}

const TableViewWidget: FunctionComponent<TableViewWidgetProps> = ({
  viewDimensions,
  viewFilters,
  viewDefinition,
  parametersMapping,
  groupByDefault,
  width,
  height,
  readOnly,
}) => {
  const store = useStore();
  const { loggedUserId } = useAuth();
  const aclHandler = useAcl();

  const filterConfiguration = useViewFilters(viewFilters, viewDimensions);
  const viewHandler = getViewDefinitionHandler(viewDefinition) as TableViewDefinitionHandler;
  const resolvedViewDefinition = viewHandler.getDefinition(store, viewDimensions);
  const tableViewResolution = useDeepMemo(
    () => viewHandler.resolveView(store, {
      aclHandler,
      viewDimensions,
      parametersMapping,
      userId: loggedUserId,
      filterConfiguration,
      readOnly,
    }),
    [store.getSerial(), viewDimensions, viewDefinition, parametersMapping, filterConfiguration]
  );

  if (isResolutionError(tableViewResolution)) {
    return (<WidgetInvalidConfiguration width={width} height={height} reason={tableViewResolution.error} />);
  } else {
    const tableLineHeightRem = 3.8/* line height */ + 0.1/* border */;
    const paginationHeightRem = 3.2/* line height */ + 0.4/* row gap */;
    const tableScrollbarHeightRem = SCROLLBAR_WIDTH_IN_REM;
    const scrollableWidgetContainerScrollbarHeightRem = SCROLLBAR_WIDTH_IN_REM;

    const tableHeightPx = height - remToPx(tableLineHeightRem + paginationHeightRem + tableScrollbarHeightRem + scrollableWidgetContainerScrollbarHeightRem);

    let itemPerPage = Math.floor(tableHeightPx / remToPx(tableLineHeightRem));
    if (itemPerPage < 2) {
      itemPerPage = 2;
    }
    return (
      <ScrollableWidgetContainer asContent width={width} height={height}>
        <TableView
          filterKey={viewFilters.filterKey}
          viewResolution={tableViewResolution}
          itemPerPage={itemPerPage}
          viewDimensions={viewDimensions}
          parametersMapping={parametersMapping}
          groupByDefault={groupByDefault}
          dimensionsDisplay={resolvedViewDefinition.getDimensionsDisplay(viewDimensions)}
          readOnly={resolvedViewDefinition.readOnly}
        />
      </ScrollableWidgetContainer>
    );
  }
};

export default TableViewWidget;
