import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import Button, { ButtonVariant } from '../../../../components/atoms/Button';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import Card from '../../../../components/molecules/Card';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import type { ItemEntry } from '../../../../components/templates/internal/DataTableBodyRenderer';
import { getSpacing, Spacing, spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeSelectorsClasses from '../../../../utils/makeSelectorsClasses';
import makeStyles from '../../../../utils/makeStyles';
import { SizeVariant } from '../../../../utils/useSizeContext';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';
import type { AttachmentEntry } from './AttachmentFieldRenderer';

const selectorsClasses = makeSelectorsClasses('visibilityHandler');

const useStyles = makeStyles(
  (theme) => ({
    inlinePaddedContainer: {
      borderLeftWidth: '0.1rem',
      borderLeftStyle: 'solid',
      borderLeftColor: theme.color.transparent,
      paddingLeft: spacingRem.s,
    },
    inDropdownContainer: {
      padding: spacingRem.s,
      maxHeight: '50vh',
      overflowY: 'auto',
    },
    card: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: spacingRem.s,
      flexGrow: 1,
      alignItems: 'center',
      paddingLeft: spacingRem.s,
      paddingTop: spacingRem.xs,
      paddingRight: spacingRem.s,
      paddingBottom: spacingRem.xs,
      '&:hover, &:focus, &:focus-within': {
        [`& .${selectorsClasses.visibilityHandler}`]: {
          visibility: 'visible',
        },
      },
    },
    hiddenActions: {
      visibility: 'hidden',
      display: 'flex',
      alignItems: 'center',
    },
    listContainer: {
      flexGrow: 1,
      '& > button': {
        marginTop: spacingRem.s,
      },
    },
    attachmentContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(28rem, 1fr))',
      alignContent: 'flex-start',
      alignItems: 'start',
      gap: spacingRem.s,
    },
    attachmentContainerInAttachment: {
      gridTemplateColumns: 'auto',
    },
    baseBottomContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      columnGap: spacingRem.s,
    },
    withListBottomContainer: {
      marginTop: spacingRem.s,
    },
    emptyListBottomContainer: {
      borderTopWidth: '0.1rem',
      borderTopStyle: 'solid',
      borderTopColor: theme.color.transparent,
      marginTop: spacingRem.xxs,
    },
  }),
  'attachmentFieldCardsRenderer'
);

interface AttachmentFieldCardsRendererProps {
  attachments: ItemEntry<AttachmentEntry>[],
  onCreate: () => void,
  readOnly: boolean,
  variant?: 'inline' | 'dropdown',
}

const AttachmentFieldCardsRenderer: FunctionComponent<AttachmentFieldCardsRendererProps> = ({ attachments, onCreate, readOnly, variant = 'inline' }) => {
  const classes = useStyles();

  const contextUsageVariant = useUsageContext();

  return (
    <div
      className={classnames({
        [classes.inlinePaddedContainer]: variant === 'inline',
        [classes.inDropdownContainer]: variant === 'dropdown',
      })}
    >
      <div className={classes.listContainer}>
        <div
          className={classnames({
            [classes.attachmentContainer]: true,
            [classes.attachmentContainerInAttachment]: contextUsageVariant === UsageVariant.inCard,
          })}
        >
          {attachments.length > 0 && attachments.map(({ key, item: { buildLink, onDownload, onDelete } }) => (
            <Card noHoverShadow key={key}>
              <div className={classes.card}>
                <SpacingLine>
                  <Icon name={IconName.draft_outline} colorVariant={IconColorVariant.secondary} />
                  {buildLink()}
                </SpacingLine>
                <div className={classnames(classes.hiddenActions, selectorsClasses.visibilityHandler)}>
                  <SpacingLine customColumnGap={getSpacing(Spacing.xs)}>
                    <IconOnlyButton
                      tooltip={i18n`Download`}
                      iconName={IconName.download}
                      variant={IconOnlyButtonVariants.tertiary}
                      sizeVariant={SizeVariant.small}
                      onClick={() => onDownload()}
                    />
                    {!readOnly ? (
                      <IconOnlyButton
                        tooltip={i18n`Delete`}
                        iconName={IconName.delete}
                        variant={IconOnlyButtonVariants.danger}
                        sizeVariant={SizeVariant.small}
                        onClick={() => onDelete()}
                      />
                    ) : null}
                  </SpacingLine>
                </div>
              </div>
            </Card>
          ))}
        </div>
        {variant === 'inline' ? (
          <div
            className={classnames({
              [classes.baseBottomContainer]: true,
              [classes.withListBottomContainer]: attachments.length > 0,
              [classes.emptyListBottomContainer]: attachments.length === 0,
            })}
          >
            <Button
              title={i18n`Attach file`}
              iconName={IconName.add}
              onClick={() => onCreate()}
              variant={ButtonVariant.secondary}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AttachmentFieldCardsRenderer;
