import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import makeStyles from '../../../utils/makeStyles';
import { UsageContextProvider, UsageVariant } from '../../../utils/useUsageContext';
import FormTextInputField from '../input/FormTextInputField';
import { FilterParams, useFilterStorage } from './useFilterSessionStorage';

const useStyles = makeStyles({
  fullWidthNameSearchContainer: {
    minWidth: '24rem',
  },
  nameSearchContainer: {
    width: '24rem',
  },
}, 'searchTextInput');

interface SearchTextInputProps {
  placeholder: string,
  element: string,
  focusOnMount?: boolean,
  fullWidth?: boolean,
}

const SearchTextInput: FunctionComponent<SearchTextInputProps> = ({ placeholder, element, focusOnMount, fullWidth = false }) => {
  const classes = useStyles();

  const [search, setSearch, clearSearch] = useFilterStorage(element, FilterParams.nameSearch);

  return (
    <UsageContextProvider usageVariant={UsageVariant.inForm}>
      <span className={classnames({ [classes.nameSearchContainer]: !fullWidth, [classes.fullWidthNameSearchContainer]: fullWidth })}>
        <FormTextInputField
          initialValue={search}
          placeholder={placeholder}
          onChangeDebounced={(value) => {
            if (value && value.length > 0) {
              setSearch(value);
            } else {
              clearSearch();
            }
          }}
          onClear={() => clearSearch()}
          focusOnMount={focusOnMount}
          fullWidth={fullWidth}
        />
      </span>
    </UsageContextProvider>
  );
};

export default SearchTextInput;
