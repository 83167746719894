export enum Direction {
  up = 'up',
  down = 'down',
}

export const moveElementInArray = <T>(direction: Direction, index: number, array: T[] = []): T[] => {
  const newArray = [...array];
  const elementToMove = newArray[index];
  newArray.splice(index, 1);
  newArray.splice(index + (direction === Direction.down ? 1 : -1), 0, elementToMove);
  return newArray;
};

export const removeElementInArray = <T>(array: T[], index: number): T[] => {
  const newArray = [...array];
  newArray.splice(index, 1);
  return newArray;
};
