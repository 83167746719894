import { FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import { BlockFieldLayoutOption } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import type { TimeStampFieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { ParsedDimensionType, parseDimensionMapping, timeStampFieldHandler } from 'yooi-modules/modules/conceptModule';
import type { Field_Title } from 'yooi-modules/modules/conceptModule/ids';
import { Field_ApiAlias, Field_Documentation, Field_IsDocumentationInline } from 'yooi-modules/modules/conceptModule/ids';
import { compareNumber, comparing, joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import { TableSortDirection } from '../../../../components/molecules/Table';
import { Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import { getBlockFieldLayoutOption, getDefaultDisplayOptions, getLayoutDisplayOption } from '../_global/blockFieldUtils';
import { getApiAliasEditionOption, getApiAliasInitialState, getDocumentationFieldEditionSection } from '../_global/editionHandlerUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import { FIELD_EDITION_DIMENSIONS, getFieldDimensionsEditionHandlerValue } from '../fieldDimensionUtils';
import type { FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { FieldComparatorHandler, GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode } from '../FieldLibraryTypes';
import TimestampFieldRenderer from './TimestampFieldRenderer';

interface TimeStampFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
}

type TimestampFieldDefinition = GetFieldDefinitionHandler<typeof timeStampFieldHandler, TimeStampFieldConfigurationState, never, FieldBlockDisplayOptions>;

export const timeStampFieldDefinition: TimestampFieldDefinition = registerFieldDefinition(timeStampFieldHandler, {
  configuration: {
    typeIcon: IconName.schedule_outline,
    getTypeLabel: () => i18n`Timestamp`,
    asWidget: false,
    getEditionOptions: (objectStore) => ({ mode, editionHandler }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      sections.push(getDocumentationFieldEditionSection(editionHandler));

      if (mode === FieldEditionOptionMode.FieldDeveloperMode) {
        sections.push({
          key: 'integration',
          type: 'section',
          title: i18n`Integration`,
          options: [getApiAliasEditionOption(objectStore, editionHandler)],
        });
      }

      return sections;
    },
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<TimeStampFieldStoreObject>(fieldId);
        return joinObjects(
          getApiAliasInitialState(objectStore, fieldId),
          {
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
          }
        );
      },
      submitFieldUpdate: (stateToSubmit) => {
        objectStore.updateObject(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
        });
      },
    }),
  },
  renderField: (_, fieldId) => ({ dimensionsMapping }) => {
    const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
    if (parsedDimensionMapping.type !== ParsedDimensionType.MonoDimensional) {
      return null;
    }

    return (<TimestampFieldRenderer fieldId={fieldId} objectId={parsedDimensionMapping.objectId} />);
  },
  renderExportConfiguration: () => () => (
    <SpacedContainer margin={{ x: Spacing.splus }}>
      <Tooltip title={i18n`Date`}>
        <Typo maxLine={1}>{i18n`Date`}</Typo>
      </Tooltip>
    </SpacedContainer>
  ),
  getActivityProperties: (_, fieldId) => () => [fieldId],
  getColumnDefinition: (_, fieldId) => () => ({
    propertyId: fieldId,
    sortable: true,
    focusable: true,
  }),
  estimatedColumnWidth: () => () => '17rem',
  getComparatorHandler: (_, __, { getValueResolution }) => (direction) => ({
    comparator: comparing(compareNumber, direction === TableSortDirection.desc),
    extractValue: (dimensionsMapping) => getValueResolution(dimensionsMapping).value,
  }) satisfies FieldComparatorHandler<number | undefined>,
  blockDisplayOptionsHandler: (objectStore) => (fieldBlockDisplayId) => ({
    getDisplayOptions: () => getDefaultDisplayOptions(objectStore, fieldBlockDisplayId),
    renderSummary: ({ layoutDisplayType }) => ([getBlockFieldLayoutOption()[layoutDisplayType ?? BlockFieldLayoutOption.auto].label]),
    getBlockEditionOptionSections: (state, setState) => [getLayoutDisplayOption(state, setState)],
    onSubmit: (state) => {
      objectStore.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
});
