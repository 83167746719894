import { createModule } from '../common/types/TypeModuleDsl';
import * as NavigationModuleIds from './ids';
import { NavigationModuleId } from './ids';
import migrations from './migrations';

const { initTypedModule, ...registerModelDsl } = createModule({ label: 'Navigation' }, NavigationModuleIds);
export const registerModel = registerModelDsl;

export const initModule = initTypedModule(() => ({
  id: NavigationModuleId,
  migrations,
}));
