import { useAsync, useAsyncFn } from 'react-use';
import type { AsyncFnReturn } from 'react-use/lib/useAsyncFn';
import type { DoFetchInit, FetchJSONResult } from './fetchUtils';
import { fetchJSON } from './fetchUtils';

const noProcess = <T>(r: T) => r;

export const useFetchJSON = <T extends FetchJSONResult>(
  url: string,
  params?: { skip?: boolean, postProcess?: (result: T) => T, deps?: unknown[] }
): ReturnType<typeof useAsync<() => Promise<T | undefined>>> => {
  const { skip = false, postProcess = noProcess, deps = [] } = params ?? {};
  return useAsync<() => Promise<T | undefined>>(async () => {
    if (skip) {
      return undefined;
    } else {
      return postProcess(await fetchJSON<T>(url));
    }
  }, [url, skip, noProcess, ...deps]);
};

type UseFetchJSONReturn<T extends FetchJSONResult> = AsyncFnReturn<(init?: DoFetchInit, onResponse?: (result: T) => void, onError?: (e: Error) => void) => Promise<T>>;

export const useFetchJSONFunction = <T extends FetchJSONResult>(url: string): UseFetchJSONReturn<T> => (
  useAsyncFn(async (init, onResponse, onError) => {
    try {
      const result = await fetchJSON<T>(url, init);
      onResponse?.(result);
      return result;
    } catch (e) {
      onError?.(e as Error);
      throw e;
    }
  }, [url])
);
