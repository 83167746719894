import classnames from 'classnames';
import { equals } from 'ramda';
import type { FunctionComponent, MouseEventHandler } from 'react';
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import base from '../../theme/base';
import { spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeSelectorsClasses from '../../utils/makeSelectorsClasses';
import makeStyles from '../../utils/makeStyles';
import useDerivedState from '../../utils/useDerivedState';
import useForceUpdate from '../../utils/useForceUpdate';
import Icon, { IconName } from './Icon';
import { bondedValue, computeMaxSize, computeScaleLimits } from './imageUtils';
import Tooltip from './Tooltip';

const selectorsClasses = makeSelectorsClasses('visibilityHandler');

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    '&:hover, &:focus, &:focus-within': {
      [`& .${selectorsClasses.visibilityHandler}`]: {
        visibility: 'visible',
      },
    },
  },
  window: {
    position: 'relative',
    overflow: 'hidden',
    cursor: 'move',
  },
  image: {
    position: 'absolute',
  },
  button: {
    display: 'flex',
    padding: spacingRem.xs,
    border: 0,
    cursor: 'pointer',
    backgroundColor: theme.color.background.neutral.default,
    color: theme.color.text.brand,
    '&:hover': {
      backgroundColor: theme.color.background.primarylight.default,
    },
    '&:focus, &:pressed': {
      backgroundColor: theme.color.background.primarylight.pressed,
    },
    '&:disabled': {
      cursor: 'auto',
      opacity: base.opacity.twenty,
    },
  },
  buttonsContainer: {
    visibility: 'hidden',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    backgroundColor: theme.color.background.neutral.default,
  },
  resetButtonContainer: {
    top: 0,
    right: 0,
    borderBottomLeftRadius: base.borderRadius.medium,
  },
  zoomContainer: {
    bottom: 0,
    right: 0,
    borderTopLeftRadius: base.borderRadius.medium,
  },
}), 'imageCropper');

interface ImageCropperProps {
  aspectRatio: { x: number, y: number },
  imageUrl: string,
  position: { top: number, left: number, scale: number } | undefined,
  onPositionChanged: (newPosition: { top: number, left: number, scale: number } | undefined) => void,
  maxHeightRem?: number,
  onDragStart?: () => void,
  onDragEnd?: () => void,
}

const ImageCropper: FunctionComponent<ImageCropperProps> = ({ aspectRatio, imageUrl, position, onPositionChanged, maxHeightRem, onDragStart, onDragEnd }) => {
  const classes = useStyles();

  const windowRef = useRef<HTMLDivElement | null>(null);

  const onDragRef = useRef({ start: onDragStart, end: onDragEnd });
  onDragRef.current = { start: onDragStart, end: onDragEnd };

  const panStartRef = useRef<{ x: number, y: number }>();
  const [imageBounds, setImageBounds] = useDerivedState<Record<'naturalWidth' | 'naturalHeight' | 'width' | 'height', number> | undefined>(() => undefined, [imageUrl]);

  const onPositionChangedDebounced = useDebouncedCallback(onPositionChanged, 250);

  const forceUpdate = useForceUpdate();
  const imagePositionRef = useRef<Record<'top' | 'left' | 'scale', number> | undefined>();
  const imagePositionDepsRef = useRef<unknown[] | undefined>();
  const deps = [position, imageBounds, aspectRatio];
  if (!equals(deps, imagePositionDepsRef.current)) {
    imagePositionDepsRef.current = deps;
    if (imageBounds) {
      const { fitScale, minScale } = computeScaleLimits(imageBounds, aspectRatio);
      const scale = bondedValue(position?.scale ?? fitScale, minScale, Number.MAX_SAFE_INTEGER);
      const { minLeft, minTop, maxLeft, maxTop } = computeMaxSize(imageBounds, aspectRatio, scale);
      imagePositionRef.current = {
        left: bondedValue(position?.left ?? (minLeft + ((maxLeft - minLeft) / 2)), minLeft, maxLeft),
        top: bondedValue(position?.top ?? (minTop + ((maxTop - minTop) / 2)), minTop, maxTop),
        scale,
      };
    } else {
      imagePositionRef.current = undefined;
    }
  }

  const onMouseMove = useCallback(({ clientX, clientY }: MouseEvent) => {
    const newPosition = { x: clientX, y: clientY };
    if (panStartRef.current && windowRef.current) {
      const { clientWidth, clientHeight } = windowRef.current;
      const offsetX = panStartRef.current.x - newPosition.x;
      const offsetY = panStartRef.current.y - newPosition.y;
      if (imagePositionRef.current !== undefined && imageBounds !== undefined) {
        const { minLeft, minTop, maxLeft, maxTop } = computeMaxSize(imageBounds, aspectRatio, imagePositionRef.current.scale);
        imagePositionRef.current = {
          left: bondedValue(imagePositionRef.current.left - (offsetX / clientWidth), minLeft, maxLeft),
          top: bondedValue(imagePositionRef.current.top - (offsetY / clientHeight), minTop, maxTop),
          scale: imagePositionRef.current.scale,
        };
        forceUpdate();
      }
    }
    panStartRef.current = newPosition;
  }, [aspectRatio, forceUpdate, imageBounds]);

  const onMouseUp = useCallback(() => {
    if (panStartRef.current && imagePositionRef.current) {
      onPositionChangedDebounced(imagePositionRef.current);
    }
    panStartRef.current = undefined;
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
    setImmediate(() => onDragRef.current.end?.());
  }, [onMouseMove, onPositionChangedDebounced]);

  const onMouseDown = useCallback<MouseEventHandler>(({ clientX, clientY }) => {
    panStartRef.current = { x: clientX, y: clientY };
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
    onDragRef.current.start?.();
  }, [onMouseMove, onMouseUp]);

  // Ensure event are properly removed
  useEffect(() => () => {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);
  }, [onMouseMove, onMouseUp]);

  const doScale = useCallback((factor: number) => {
    if (imagePositionRef.current !== undefined && imageBounds !== undefined) {
      const { minScale } = computeScaleLimits(imageBounds, aspectRatio);
      const scale = bondedValue(imagePositionRef.current.scale * factor, minScale, Number.MAX_SAFE_INTEGER);
      if (scale > minScale || (scale === minScale && imagePositionRef.current.scale !== minScale)) {
        const { minLeft, minTop, maxLeft, maxTop } = computeMaxSize(imageBounds, aspectRatio, scale);
        imagePositionRef.current = {
          left: bondedValue(imagePositionRef.current.left * factor, minLeft, maxLeft),
          top: bondedValue(imagePositionRef.current.top * factor, minTop, maxTop),
          scale,
        };
        onPositionChangedDebounced(imagePositionRef.current);
        forceUpdate();
      }
    }
  }, [aspectRatio, forceUpdate, imageBounds, onPositionChangedDebounced]);

  useLayoutEffect(() => {
    const node = windowRef.current;
    if (node) {
      // onWheel react handler don't allow to call preventDefault
      const onWheel = (event: WheelEvent) => {
        event.preventDefault();
        if (event.deltaY < 0) {
          doScale(1.05);
        } else if (event.deltaY > 0) {
          doScale(1 / 1.05);
        }
      };

      node.addEventListener('wheel', onWheel);

      return () => {
        node.removeEventListener('wheel', onWheel);
      };
    } else {
      return () => {};
    }
  }, [doScale]);

  return (
    <div
      className={classes.container}
      style={{ maxWidth: maxHeightRem ? `${(maxHeightRem * aspectRatio.x) / aspectRatio.y}rem` : undefined }}
    >
      <div
        ref={windowRef}
        className={classes.window}
        style={{ paddingBottom: `${((aspectRatio.y / aspectRatio.x) * 100).toFixed(1)}%` }}
      >
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img
          src={imageUrl}
          className={classes.image}
          style={{
            visibility: imagePositionRef.current === undefined || imageBounds === undefined ? 'hidden' : undefined,
            width: imagePositionRef.current !== undefined && imageBounds !== undefined ? `${(imageBounds.width / aspectRatio.x) * imagePositionRef.current.scale * 100}%` : undefined,
            height: imagePositionRef.current !== undefined && imageBounds !== undefined ? `${(imageBounds.height / aspectRatio.y) * imagePositionRef.current.scale * 100}%` : undefined,
            top: imagePositionRef.current !== undefined && imageBounds !== undefined ? `${(imagePositionRef.current.top) * 100}%` : undefined,
            left: imagePositionRef.current !== undefined && imageBounds !== undefined ? `${(imagePositionRef.current.left) * 100}%` : undefined,
          }}
          onLoad={(event) => {
            const { naturalWidth, naturalHeight, width, height } = event.currentTarget;
            const bounds = { naturalWidth, naturalHeight, width, height };
            setImageBounds(bounds);
          }}
          draggable={false}
          onMouseDown={onMouseDown}
          alt=""
        />
        {imageBounds !== undefined ? (
          <>
            <div className={classnames(classes.buttonsContainer, classes.resetButtonContainer, selectorsClasses.visibilityHandler)}>
              <Tooltip title={i18n`Reset position`} asFlexbox>
                <button
                  type="button"
                  className={classes.button}
                  onClick={() => onPositionChanged(undefined)}
                  aria-label={i18n`Reset position`}
                >
                  <Icon name={IconName.sync} />
                </button>
              </Tooltip>
            </div>
            <div className={classnames(classes.buttonsContainer, classes.zoomContainer, selectorsClasses.visibilityHandler)}>
              <Tooltip title={i18n`Zoom in`} asFlexbox>
                <button
                  type="button"
                  className={classes.button}
                  onClick={() => doScale(1.05)}
                  aria-label={i18n`Zoom in`}
                >
                  <Icon name={IconName.zoom_in} />
                </button>
              </Tooltip>
              <Tooltip title={i18n`Zoom out`} asFlexbox>
                <button
                  type="button"
                  className={classes.button}
                  onClick={() => doScale(1 / 1.05)}
                  disabled={imagePositionRef.current?.scale === computeScaleLimits(imageBounds, aspectRatio).minScale}
                  aria-label={i18n`Zoom out`}
                >
                  <Icon name={IconName.zoom_out} />
                </button>
              </Tooltip>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ImageCropper;
