import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';

interface Context {
  parentContext: string[][],
  context?: string,
}

const ChipBlockContext = createContext<Context>({ parentContext: [] });

interface ChipBlockContextProviderProps {
  children: ReactNode,
  context?: string,
}

export const ChipBlockContextProvider: FunctionComponent<ChipBlockContextProviderProps> = ({ children, context }) => {
  const { context: parentContext, parentContext: parentParentContext } = useContext(ChipBlockContext);
  const currentContext = useMemo(() => ({
    // parentContext order is important (latest is the more precise context)
    parentContext: parentContext ? [...parentParentContext, [parentContext]] : parentParentContext,
    context,
  }), [context, parentContext, parentParentContext]);

  return (
    <ChipBlockContext.Provider value={currentContext}>
      {children}
    </ChipBlockContext.Provider>
  );
};

export const useChipBlockContext = (): Context => useContext(ChipBlockContext);
