export interface SessionStorageHash {
  hash: string | null | undefined,
}

const hashify = (string: string): string => string.replace(/#/g, '').replace(/[\s_]/g, '-').toLowerCase();

export const createHash = (hash: string | undefined, position?: string, index?: number): string | undefined => {
  if (!hash) {
    return undefined;
  } else if (position) {
    return `#${hashify(hash)}${position ? `_${hashify(position.toString())}` : ''}${index ? `-${hashify(index.toString())}` : ''}`;
  } else {
    return `#${hashify(hash)}${index ? `-${hashify(index.toString())}` : ''}${position ? `_${hashify(position.toString())}` : ''}`;
  }
};

export const getPositionFromHash = (hash: string | undefined): string | undefined => {
  const hashes = hash?.split('_');
  if (!hashes) {
    return hash;
  }
  return hashes.length > 1 ? `#${hashes[1]}` : hash;
};
