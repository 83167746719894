import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { Filters, SingleParameterDefinition, ViewFilterStoreObject } from 'yooi-modules/modules/conceptModule';
import { FILTER_PARAMETER_CURRENT } from 'yooi-modules/modules/conceptModule';
import { ConceptDefinition_ViewFilters } from 'yooi-modules/modules/conceptModule/ids';
import { IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import useStore from '../../../../store/useStore';
import { getSpacing, Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { SizeContextProvider, SizeVariant } from '../../../../utils/useSizeContext';
import AllFiltersComposite from '../../filter/AllFiltersComposite';
import { countValidFilters, getLoggedUserParameterDefinition } from '../../filter/filterUtils';
import GraphChartSearchTextButton from './GraphChartSearchTextButton';

const useStyles = makeStyles({
  headerButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: getSpacing(Spacing.xs),
  },
}, 'graphChartColumnTopBar');

interface GraphChartColumnTopBarProps {
  filters: Record<string, Filters> | undefined,
  setFilters: (filters: Record<string, Filters> | undefined) => void,
  onResetFilters: () => void,
  conceptDefinitionId: string,
  search: string | undefined,
  setSearch: (text: string | undefined) => void,
  showSearch?: boolean,
}

const GraphChartColumnTopBar: FunctionComponent<GraphChartColumnTopBarProps> = ({
  search,
  setSearch,
  filters,
  setFilters,
  onResetFilters,
  conceptDefinitionId,
  showSearch,
}) => {
  const classes = useStyles();
  const store = useStore();

  const [showDropDown, setShowDropDown] = useState(false);

  const filterParameterDefinitions: SingleParameterDefinition[] = [
    { id: FILTER_PARAMETER_CURRENT, label: i18n`Current`, typeId: conceptDefinitionId, type: 'parameter' },
    getLoggedUserParameterDefinition(),
  ];

  const getViewsFilters: () => ViewFilterStoreObject[] = () => store.getObject(conceptDefinitionId).navigateBack<ViewFilterStoreObject>(ConceptDefinition_ViewFilters);

  const filtersCount = Object.values(filters ?? {}).reduce((acc, filter) => acc + countValidFilters(store, filter), 0);

  return (
    <SizeContextProvider sizeVariant={SizeVariant.small}>
      <div className={classes.headerButtonContainer}>
        <GraphChartSearchTextButton
          conceptDefinitionId={conceptDefinitionId}
          placeholder={i18n`Search`}
          search={search}
          setSearch={setSearch}
          clearSearch={() => setSearch(undefined)}
          showSearch={showSearch}
        />
        {getViewsFilters().length > 0 && (
          <AllFiltersComposite
            filters={filters}
            onChange={setFilters}
            onReset={onResetFilters}
            parameterDefinitions={filterParameterDefinitions}
            getViewFilters={getViewsFilters}
            showDropDown={showDropDown}
            onClose={() => setShowDropDown(false)}
            getIconOnlyButton={(onClick) => (
              <IconOnlyButton
                iconName={IconName.filter_alt}
                tooltip={i18n`Open filters`}
                onClick={onClick}
                variant={IconOnlyButtonVariants.tertiary}
                count={filtersCount > 0 ? filtersCount : undefined}
              />
            )}
          />
        )}
      </div>
    </SizeContextProvider>
  );
};

export default GraphChartColumnTopBar;
