import { ResolutionTypeError } from 'yooi-modules/modules';
import type { FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import {
  FormulaInputError,
  getAllFieldDimensionsLinkedConceptDefinitionIds,
  InvalidFieldError,
  ResolutionError,
  StepResolutionError,
  StepResolutionErrorStepType,
} from 'yooi-modules/modules/conceptModule';
import { Field_Widget, Widget_Title } from 'yooi-modules/modules/dashboardModule/ids';
import { FormulaError, InfiniteComputingRecursionError, newError, StoreObjectNotFoundError } from 'yooi-utils';
import type { FrontObjectStore } from '../../store/useStore';
import i18n from '../../utils/i18n';
import { formatOrUndef } from '../../utils/stringUtils';
import { getFieldLabel } from './fieldUtils';
import { getConceptDefinitionNameOrEntity } from './modelTypeUtils';
import { DataResolutionError } from './views/data/dataResolution';
import { SeriesResolutionError } from './views/viewResolutionUtils';

const formatErrorMessageWithCause = (message: string, cause: string, depth = 1): string => i18n`${message}\n${Array(depth).join('  ')}↳️ Caused by: ${cause}`;

const formatFieldResolutionErrorMessage = (store: FrontObjectStore, fieldId: string): string => {
  const field = store.getObjectOrNull<FieldStoreObject>(fieldId);
  if (field === null) {
    return i18n`Error while resolving field`;
  } else if (field.navigateBack(Field_Widget).length > 0) {
    return i18n`Error while resolving widget: ${formatOrUndef(field.navigateBack(Field_Widget)[0][Widget_Title] as string | undefined)}`;
  } else {
    const fieldName = getFieldLabel(store, field);
    const conceptName = getAllFieldDimensionsLinkedConceptDefinitionIds(store, fieldId)
      .map((conceptDefinitionId) => getConceptDefinitionNameOrEntity(store, conceptDefinitionId)).join(' X ');
    return i18n`Error while resolving field: ${fieldName} (${conceptName})`;
  }
};

const assertUnreachable = (_x: never): never => {
  throw newError('Didn\'t expect to get here');
};

const getStepResolutionErrorKeyMessage = (errorKey: ResolutionError): string => {
  switch (errorKey) {
    case ResolutionError.cannotNavigatePath:
      return i18n`Path cannot be navigated`;
    case ResolutionError.unknownResolverError:
      return i18n`unknown resolver error`;
    case ResolutionError.invalidEmbeddingField:
      return i18n`Invalid embedding field`;
    case ResolutionError.invalidMapping:
      return i18n`Invalid Mapping`;
    case ResolutionError.invalidDimension:
      return i18n`Invalid Dimension`;
    case ResolutionError.invalidFilter:
      return i18n`Invalid Filter`;
    case ResolutionError.invalidField:
      return i18n`Invalid Field`;
    default:
      return assertUnreachable(errorKey);
  }
};

const getStepResolutionErrorMessage = (store: FrontObjectStore, { data: { stepType, resolutionError } }: StepResolutionError): string => {
  let message: string;
  switch (stepType.type) {
    case StepResolutionErrorStepType.globalDimension:
      message = i18n`Error while resolving global dimension`;
      break;
    case StepResolutionErrorStepType.dimension:
      message = i18n`Error while resolving dimension: ${formatOrUndef(getConceptDefinitionNameOrEntity(store, stepType.conceptDefinitionId))}`;
      break;
    case StepResolutionErrorStepType.filter:
      message = i18n`Error while resolving filtering`;
      break;
    case StepResolutionErrorStepType.mapping:
      message = i18n`Error while resolving mapping`;
      break;
    case StepResolutionErrorStepType.field:
      message = formatFieldResolutionErrorMessage(store, stepType.fieldId);
      break;
    default:
      message = i18n`Unknown error`;
      break;
  }
  return resolutionError ? `${message}: ${getStepResolutionErrorKeyMessage(resolutionError)}` : message;
};

export class FieldResolutionError extends Error {
  override readonly name = 'FieldResolutionError';

  readonly fieldId: string;

  constructor(fieldId: string, cause: Error) {
    super('Field resolution error');
    this.fieldId = fieldId;
    this.cause = cause;
  }
}

const getErrorMessage = (store: FrontObjectStore, error: Error): string => {
  if (error instanceof FormulaError) {
    return i18n`Formula error: ${error.message ? error.message : error.name}`;
  } else if (error instanceof FormulaInputError) {
    return i18n`Error while resolving formula input: ${error.data.inputName}`;
  } else if (error instanceof StepResolutionError) {
    return getStepResolutionErrorMessage(store, error);
  } else if (error instanceof DataResolutionError) {
    return i18n`Error while resolving data: ${error.dimensionLabel}`;
  } else if (error instanceof SeriesResolutionError) {
    return i18n`Error while resolving series: ${error.seriesLabel}`;
  } else if (error instanceof ResolutionTypeError) {
    return i18n`Invalid resolution type: ${error.data.resolutionType} (expected: ${error.data.expectedTypes.join(' | ')})`;
  } else if (error instanceof InfiniteComputingRecursionError) {
    return i18n`Infinite computing loop`;
  } else if (error instanceof StoreObjectNotFoundError) {
    return i18n`This object does not exist`;
  } else if (error instanceof InvalidFieldError) {
    return i18n`Field is not valid.`;
  } else if (error instanceof FieldResolutionError) {
    return formatFieldResolutionErrorMessage(store, error.fieldId);
  } else if (error.message) {
    return error.message;
  } else {
    return error.name;
  }
};

const formatErrorWithCause = (store: FrontObjectStore, message: string, cause?: Error, depth = 1): string => {
  if (cause) {
    const causeErrorMessage = formatErrorMessageWithCause(message, getErrorMessage(store, cause), depth);
    const { cause: subCause } = cause;
    if (subCause instanceof Error) {
      return formatErrorWithCause(store, causeErrorMessage, subCause, depth + 1);
    } else {
      return causeErrorMessage;
    }
  } else {
    return message;
  }
};

export const formatErrorForUser = (
  store: FrontObjectStore,
  error: Error
): string => formatErrorWithCause(store, getErrorMessage(store, error), error.cause as Error | undefined);

export const formatFieldResolutionErrorForUser = (store: FrontObjectStore, error: Error, fieldId: string): string => (
  formatErrorWithCause(store, formatFieldResolutionErrorMessage(store, fieldId), error)
);
