import type { IconName } from '../components/atoms/Icon';
import declareBus from './declareBus';

interface NotificationOptions {
  persist?: boolean,
  closeable?: boolean,
  actions?: { key: string, icon: IconName, tooltip: string, onClick: () => void }[],
  onToast?: (toastId: string) => void,
  icon?: { icon?: IconName, iconColor?: string, tooltip?: string },
}

export interface ToastEvent {
  variant: 'success' | 'warning' | 'error' | 'info',
  message: string,
  options: NotificationOptions,
}

const toastBus = declareBus<ToastEvent>('toasts');
export const useToastBus = toastBus.useBus;

const clearToastBus = declareBus<string>('clear-toasts');
export const useClearToastBus = clearToastBus.useBus;

const notify = (
  variant: 'success' | 'warning' | 'error' | 'info',
  message: string,
  options: NotificationOptions = {}
): void => toastBus.dispatch({ variant, message, options });

export const notifySuccess = (message: string, options?: NotificationOptions): void => notify('success', message, options);
export const notifyWarning = (message: string, options?: NotificationOptions): void => notify('warning', message, options);
export const notifyError = (message: string, options?: NotificationOptions): void => notify('error', message, options);
export const notifyInfo = (message: string, options?: NotificationOptions): void => notify('info', message, options);

export const clearNotification = (toastId: string): void => clearToastBus.dispatch(toastId);
