import classnames from 'classnames';
import type { ReactElement } from 'react';
import { Fragment } from 'react';
import { getSpacing, getSpacingAsNumber, Spacing, spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import useTheme from '../../utils/useTheme';
import useUsageContext, { UsageVariant } from '../../utils/useUsageContext';
import type { ButtonVariant } from '../atoms/Button';
import Button from '../atoms/Button';
import type { IconName } from '../atoms/Icon';
import Typo, { TypoVariant } from '../atoms/Typo';
import SpacingLine from '../molecules/SpacingLine';
import type { Pagination } from './PageSelector';
import PageSelector from './PageSelector';

const bottomContainerMarginTop = Spacing.s;

export const cardListGap = spacingRem.splus;
export const cardListMinCardWidth = '33rem';
export const cardListBottomContainerHeight = 3.2 + getSpacingAsNumber(bottomContainerMarginTop);

const useStyles = makeStyles({
  listContainer: {
    flexGrow: 1,
    '& > button': {
      marginTop: spacingRem.s,
    },
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${cardListMinCardWidth}, 1fr))`,
    alignContent: 'flex-start',
    alignItems: 'start',
    gap: cardListGap,
  },
  cardContainerInCard: {
    gridTemplateColumns: 'auto',
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: spacingRem.s,
    marginTop: getSpacing(bottomContainerMarginTop),
  },
}, 'cardList');

interface InlineCreation {
  button: {
    title: string,
    icon: IconName,
    variant?: ButtonVariant,
    onClick: (title?: string, values?: Record<string, unknown>) => (string | void),
  },
  lineFocus: { id: string | undefined, reset: () => void } | undefined,
}

interface InlineTransaction {
  showCard: boolean,
  renderCard: () => ReactElement | null,
}

interface CardListProps<T> {
  list: T[],
  pagination?: Pagination,
  numberOfColumns?: number,
  renderCard: (entry: T, focusOnMount: boolean) => (ReactElement | null),
  inlineCreation?: ({ type: 'creation' } & InlineCreation) | ({ type: 'transaction' } & InlineCreation & InlineTransaction),
}

const CardList = <T extends { key: string }>({ list, pagination, numberOfColumns, renderCard, inlineCreation }: CardListProps<T>): ReactElement | null => {
  const theme = useTheme();
  const classes = useStyles();

  const contextUsageVariant = useUsageContext();

  return (
    <div className={classes.listContainer}>
      <div
        className={classnames({
          [classes.cardContainer]: true,
          [classes.cardContainerInCard]: contextUsageVariant === UsageVariant.inCard,
        })}
        style={numberOfColumns !== undefined ? { gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)` } : undefined}
      >
        {list.length > 0 && list.map((entry) => {
          const focusAndScrollOnMount = inlineCreation?.lineFocus?.id === entry.key;
          if (focusAndScrollOnMount) {
            inlineCreation?.lineFocus?.reset();
          }
          return (
            <Fragment key={entry.key}>
              {renderCard(entry, focusAndScrollOnMount)}
            </Fragment>
          );
        })}
        {list.length === 0 && inlineCreation?.type === 'transaction' && !inlineCreation?.showCard ? (
          <SpacingLine>
            <Typo variant={TypoVariant.blockInlineTitle} color={theme.color.text.secondary}>{i18n`No content to display`}</Typo>
          </SpacingLine>
        ) : null}
        {inlineCreation?.type === 'transaction' && inlineCreation?.showCard ? inlineCreation.renderCard() : null}
      </div>
      {(pagination || inlineCreation) ? (
        <div className={classes.bottomContainer}>
          <div>
            {inlineCreation ? (
              <Button
                title={inlineCreation.button.title}
                iconName={inlineCreation.button.icon}
                onClick={() => inlineCreation.button.onClick()}
                variant={inlineCreation.button.variant}
              />
            ) : null}
          </div>
          <PageSelector pagination={pagination} />
        </div>
      ) : null}
    </div>
  );
};

export default CardList;
