import type { FieldBlockDisplayOptions, FieldBlockDisplayStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import { BlockFieldLayoutOption } from 'yooi-modules/modules/conceptLayoutModule';
import { Block_ConceptDefinition, FieldBlockDisplay_Block, FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { getFieldUtilsHandler } from 'yooi-modules/modules/conceptModule';
import type { ViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import { ViewType } from 'yooi-modules/modules/dashboardModule';
import { filterError, joinObjects } from 'yooi-utils';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { getViewTypeSummary } from '../../views/common/viewUtils';
import { getViewDefinitionHandler } from '../../views/viewDsl';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import type { BlockDisplayOptionsHandler } from '../FieldLibraryTypes';
import ViewsGroupDisplayTable from '../viewsField/ViewsGroupDisplayTable';
import { getBlockFieldLayoutOption, getFieldBlockDisplayDimensions, getLayoutDisplayOption } from './blockFieldUtils';

interface ViewFieldBlockDisplayOptions extends FieldBlockDisplayOptions {
  viewDefinitions?: ViewStoredDefinition[],
  withFilters?: boolean,
}

export const getViewsBlockDisplayOptionsHandler = <T extends ViewFieldBlockDisplayOptions>(
  objectStore: FrontObjectStore,
  fieldId: string
) => (fieldBlockDisplayId: string, parameterDefinitions: SingleParameterDefinition[]): BlockDisplayOptionsHandler<T> => ({
  getDisplayOptions: () => joinObjects(
    {
      viewDefinitions: [{ id: fieldBlockDisplayId, type: ViewType.Chip }],
      withFilters: true,
    },
    objectStore.getObjectOrNull<FieldBlockDisplayStoreObject>(fieldBlockDisplayId)?.[FieldBlockDisplay_FieldDisplayConfiguration]
  ) as T,
  getErrors: (state) => {
    if (!state.viewDefinitions) {
      return [i18n`Missing view configuration`];
    } else if (state.viewDefinitions?.some((v) => getViewDefinitionHandler(v)
      .getDefinitionErrors(objectStore, getFieldBlockDisplayDimensions(objectStore, fieldBlockDisplayId), parameterDefinitions))) {
      return [i18n`Some views configuration are invalid`];
    } else {
      return [];
    }
  },
  renderSummary: (state) => {
    const targetTypeId = getFieldUtilsHandler(objectStore, fieldId).getTargetType?.()?.id;
    const viewDimensions = getFieldBlockDisplayDimensions(objectStore, fieldBlockDisplayId);
    return targetTypeId ? [
      getBlockFieldLayoutOption()[state.layoutDisplayType ?? BlockFieldLayoutOption.auto].label,
      getViewTypeSummary(state.viewDefinitions?.map((v) => getViewDefinitionHandler(v).getDefinition(objectStore, viewDimensions)) ?? []),
    ] : [];
  },
  getBlockEditionOptionSections: (state, setState) => {
    const targetTypeId = getFieldUtilsHandler(objectStore, fieldId).getTargetType?.()?.id;
    if (!targetTypeId) {
      return []; // Invalid configuration, abort
    }
    const viewDimensions = getFieldBlockDisplayDimensions(objectStore, fieldBlockDisplayId);
    const conceptDefinitionId = objectStore.getObject<FieldBlockDisplayStoreObject>(fieldBlockDisplayId).navigate(FieldBlockDisplay_Block)[Block_ConceptDefinition];
    return [
      getLayoutDisplayOption(state, setState),
      {
        key: 'viewsGroup',
        title: i18n`Display as`,
        action: {
          type: EditionOptionTypes.custom,
          props: {
            render: () => (
              <ViewsGroupDisplayTable
                viewDefinitions={state.viewDefinitions?.map((viewDef) => getViewDefinitionHandler(viewDef).getDefinition(objectStore, viewDimensions))
                  .filter(filterError) ?? []}
                viewDimensions={viewDimensions}
                parameterDefinitions={parameterDefinitions}
                updateViewDefinitions={(updateFunction) => {
                  const newState = { ...state };
                  newState.viewDefinitions = updateFunction(state.viewDefinitions ?? []);
                  setState(newState);
                }}
                getNavigationData={() => ({
                  viewUrl: `/settings/organization/${conceptDefinitionId}/fieldBlockDisplay/${fieldBlockDisplayId}`,
                  navigationKey: fieldBlockDisplayId,
                })}
                onOpen={() => {
                  if (JSON.stringify(state) !== JSON.stringify(objectStore.getObject(fieldBlockDisplayId)[FieldBlockDisplay_FieldDisplayConfiguration])) {
                    objectStore.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
                  }
                }}
              />
            ),
          },
        },
        options: [],
      },
    ];
  },
  onSubmit: (state) => {
    objectStore.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
  },
});
