import type { FunctionComponent } from 'react';
import type { DimensionsMapping, Filters, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { dimensionsMappingToParametersMapping, ParsedDimensionType, parseDimensionMapping } from 'yooi-modules/modules/conceptModule';
import { RelationSingleField_TargetType } from 'yooi-modules/modules/conceptModule/ids';
import { Resource } from 'yooi-modules/modules/resourceModule/ids';
import { joinObjects } from 'yooi-utils';
import useStore from '../../../../store/useStore';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';
import ConceptResourceField from '../_global/ConceptResourceField';
import RelationSingleRenderer from './RelationSingleRenderer';

interface RelationSingleRendererSwitchProps {
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
  value?: unknown,
  onSubmit?: (value: string | null) => void,
  readOnly: boolean,
  focusOnMount?: boolean,
  filters?: Filters,
  parametersMapping?: ParametersMapping,
}

const RelationSingleRendererSwitch: FunctionComponent<RelationSingleRendererSwitchProps> = ({
  fieldId,
  dimensionsMapping,
  value,
  onSubmit,
  readOnly,
  focusOnMount,
  filters,
  parametersMapping = {},
}) => {
  const store = useStore();
  const usageVariant = useUsageContext();

  const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
  if (parsedDimensionMapping.type !== ParsedDimensionType.MonoDimensional) {
    return null;
  }

  if (usageVariant !== UsageVariant.inTable && store.getObject(fieldId)[RelationSingleField_TargetType] === Resource) {
    return (<ConceptResourceField conceptId={parsedDimensionMapping.objectId} resourceFieldId={fieldId} readOnly={readOnly} />);
  } else {
    return (
      <RelationSingleRenderer
        fieldId={fieldId}
        conceptId={parsedDimensionMapping.objectId}
        readOnly={readOnly}
        globalFilter={{ globalFilters: filters, globalParametersMapping: joinObjects(dimensionsMappingToParametersMapping(dimensionsMapping), parametersMapping) }}
        focusOnMount={focusOnMount ?? false}
        value={value as string | undefined}
        onSubmit={onSubmit}
      />
    );
  }
};

export default RelationSingleRendererSwitch;
