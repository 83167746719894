import type { FunctionComponent } from 'react';
import { extractLinksFromText } from 'yooi-utils';
import InlineLink from './InlineLink';

interface TextWithLinksProps {
  text?: string,
}

const TextWithLinks: FunctionComponent<TextWithLinksProps> = ({ text }) => (
  <>
    {
      extractLinksFromText(text ?? '')
        .map((part, i) => (
          part.isLink
            // eslint-disable-next-line react/no-array-index-key
            ? (<InlineLink isUserControlled key={i} to={part.text}>{part.text}</InlineLink>)
            // eslint-disable-next-line react/no-array-index-key
            : (<span key={i}>{part.text}</span>)))
    }
  </>
);

export default TextWithLinks;
