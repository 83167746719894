import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import useUsageContext, { UsageVariant } from '../../utils/useUsageContext';

const useStyles = makeStyles({
  checkbox: {
    margin: 0,
  },
  inTable: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginLeft: spacingRem.s,
    marginRight: spacingRem.s,
  },
}, 'checkbox');

interface CheckboxProps {
  checked: boolean,
  onChange?: (value: boolean) => void,
  disabled?: boolean,
}

const Checkbox: FunctionComponent<CheckboxProps> = ({ checked, onChange, disabled = false }) => {
  const classes = useStyles();

  const usageVariant = useUsageContext();

  return (
    <input
      type="checkbox"
      checked={checked}
      onChange={(event) => onChange?.(event.target.checked)}
      disabled={disabled}
      className={classnames({
        [classes.checkbox]: true,
        [classes.inTable]: usageVariant === UsageVariant.inTable,
      })}
    />
  );
};

export default Checkbox;
