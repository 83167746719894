import type { FunctionComponent } from 'react';
import { ConceptDefinition } from 'yooi-modules/modules/conceptModule/ids';
import { Integration_Template } from 'yooi-modules/modules/integrationModule/ids';
import type { TemplateConceptDefinitionRaw, TemplateConceptDefinitionStoreObject, TemplateConceptRaw, TemplateFieldRaw } from 'yooi-modules/modules/templateModule';
import {
  Template_TemplateConceptDefinitions,
  TemplateConcept_MappedConcept,
  TemplateConceptDefinition_MappedConceptDefinition,
  TemplateConceptDefinition_Metadata,
  TemplateConceptDefinition_Name,
  TemplateConceptDefinition_TemplateConcepts,
  TemplateConceptDefinition_TemplateFields,
  TemplateField_MappedField,
} from 'yooi-modules/modules/templateModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import { compareProperty, compareString, comparing, pushUndefinedToEnd } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../components/molecules/SpacingLine';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { notifySuccess } from '../../../utils/notify';
import { createConceptDefinition } from '../../_global/conceptDefinitionUtils';
import { defaultOptionComparator, getChipOptions, getChipOptionWithUnknown } from '../../_global/modelTypeUtils';
import IntegrationTemplateConceptMappingBlock from './IntegrationTemplateConceptMappingBlock';
import IntegrationTemplateFieldMappingBlock from './IntegrationTemplateFieldMappingBlock';

interface IntegrationDetailMappingTabProps {
  integrationId: string,
}

const IntegrationDetailMappingTab: FunctionComponent<IntegrationDetailMappingTabProps> = ({ integrationId }) => {
  const store = useStore();

  return (
    <VerticalBlock>
      <BlockTitle title={i18n`Mapping`} subtitle={i18n`You can map integration object to any YOOI concept below`} />
      {
        store.getObject(integrationId)
          .navigateOrNull(Integration_Template)
          ?.navigateBack<TemplateConceptDefinitionStoreObject>(Template_TemplateConceptDefinitions)
          .sort(compareProperty(TemplateConceptDefinition_Name, comparing<string>(pushUndefinedToEnd).thenComparing(compareString)))
          .map((templateConceptDefinition) => (
            <VerticalBlock key={templateConceptDefinition.id} withSeparation asBlockContent>
              <HorizontalBlock asBlockContent>
                <BlockTitle title={i18n`Integration object`} />
                <BlockContent>
                  <SpacingLine>
                    <SearchAndSelect
                      selectedOption={getChipOptions(store, templateConceptDefinition.id)}
                      readOnly
                    />
                    <IconOnlyButton
                      tooltip={i18n`Copy Metadata to clipboard`}
                      iconName={IconName.description}
                      onClick={() => {
                        navigator.clipboard.writeText(JSON.stringify(templateConceptDefinition[TemplateConceptDefinition_Metadata]));
                        notifySuccess(i18n`Copied to clipboard`);
                      }}
                      variant={IconOnlyButtonVariants.tertiary}
                    />
                  </SpacingLine>
                </BlockContent>
              </HorizontalBlock>
              <HorizontalBlock asBlockContent>
                <BlockTitle title={i18n`Mapped on`} />
                <BlockContent>
                  <SearchAndSelect
                    computeOptions={() => store
                      .getObject(ConceptDefinition)
                      .navigateBack(Class_Instances)
                      .map(({ id }) => getChipOptionWithUnknown(store, id))
                      .sort(defaultOptionComparator)}
                    selectedOption={
                      templateConceptDefinition[TemplateConceptDefinition_MappedConceptDefinition] !== undefined
                        ? getChipOptionWithUnknown(store, templateConceptDefinition[TemplateConceptDefinition_MappedConceptDefinition])
                        : undefined
                    }
                    onSelect={(newSelection) => {
                      store.updateObject<TemplateConceptDefinitionRaw>(
                        templateConceptDefinition.id,
                        { [TemplateConceptDefinition_MappedConceptDefinition]: newSelection?.id ?? null }
                      );
                      if (!newSelection) {
                        templateConceptDefinition
                          .navigateBack(TemplateConceptDefinition_TemplateFields)
                          .forEach(({ id }) => {
                            store.updateObject<TemplateFieldRaw>(id, { [TemplateField_MappedField]: null });
                          });
                        templateConceptDefinition
                          .navigateBack(TemplateConceptDefinition_TemplateConcepts)
                          .forEach(({ id }) => {
                            store.updateObject<TemplateConceptRaw>(id, { [TemplateConcept_MappedConcept]: null });
                          });
                      }
                    }}
                    placeholder={i18n`Select concept`}
                    getInlineCreation={() => ({
                      type: 'inline',
                      onCreate: (title) => {
                        const conceptDefinitionId = createConceptDefinition(store, title);
                        store.updateObject<TemplateConceptDefinitionRaw>(
                          templateConceptDefinition.id,
                          { [TemplateConceptDefinition_MappedConceptDefinition]: conceptDefinitionId }
                        );
                        return conceptDefinitionId;
                      },
                    })}
                    clearable
                  />
                </BlockContent>
              </HorizontalBlock>
              {Boolean(templateConceptDefinition.navigateOrNull(TemplateConceptDefinition_MappedConceptDefinition)) && (
                <>
                  {templateConceptDefinition.navigateBack(TemplateConceptDefinition_TemplateFields).length > 0 && (
                    <IntegrationTemplateFieldMappingBlock templateConceptDefinitionId={templateConceptDefinition.id} />
                  )}
                  {templateConceptDefinition.navigateBack(TemplateConceptDefinition_TemplateConcepts).length > 0 && (
                    <IntegrationTemplateConceptMappingBlock templateConceptDefinitionId={templateConceptDefinition.id} />
                  )}
                </>
              )}
            </VerticalBlock>
          ))
      }
    </VerticalBlock>
  );
};

export default IntegrationDetailMappingTab;
