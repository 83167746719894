import type { FunctionComponent } from 'react';
import { useState } from 'react';
import {
  getCollaborationContextsObjectIds,
  getCollaborationGroupRecipientsIds,
  getCollaborationRoleRecipientsIds,
  getCollaborationUserRecipientsIds,
} from 'yooi-modules/modules/collaborationModule';
import {
  CollaborationGroups,
  CollaborationGroups_Role_Collaboration,
  CollaborationGroups_Role_Group, CollaborationRoles, CollaborationRoles_Role_Collaboration, CollaborationRoles_Role_Role,
  CollaborationUsers,
  CollaborationUsers_Role_Collaboration,
  CollaborationUsers_Role_User,
} from 'yooi-modules/modules/collaborationModule/ids';
import { getViewerGroupsSet } from 'yooi-modules/modules/conceptModule';
import { Concept, ConceptDefinition, ConceptRole_ConceptDefinition, Group, User } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined } from 'yooi-utils';
import { IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import Typo from '../../../../../components/atoms/Typo';
import SearchAndSelectMultiple from '../../../../../components/molecules/SearchAndSelectMultiple';
import useAcl from '../../../../../store/useAcl';
import useStore from '../../../../../store/useStore';
import { spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import { SizeVariant } from '../../../../../utils/useSizeContext';
import useTheme from '../../../../../utils/useTheme';
import useTooltipRef from '../../../../../utils/useTooltipRef';
import { UsageContextProvider, UsageVariant } from '../../../../../utils/useUsageContext';
import { getInlineCreationBuilder, getViewerUsersSet } from '../../../conceptUtils';
import { defaultOptionComparator, getChipOptions, getModelTypeInstances, getSearchChipOptions } from '../../../modelTypeUtils';

const useStyles = makeStyles((theme) => ({
  titleLine: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: spacingRem.s,
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '3.2rem',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: spacingRem.s,
    alignItems: 'center',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.s,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  separator: {
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
  },
}), 'collaborationDetailPanelSubscribersTab');

interface CollaborationDetailPanelSubscribersTabProps {
  collaborationId: string,
}

const CollaborationDetailPanelSubscribersTab: FunctionComponent<CollaborationDetailPanelSubscribersTabProps> = ({ collaborationId }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const { canAssignCollaborator, canCreateObject } = useAcl();

  const [isUsersCollapsed, setIsUsersCollapsed] = useState(false);
  const [isGroupsCollapsed, setIsGroupsCollapsed] = useState(false);
  const [isRolesCollapsed, setIsRolesCollapsed] = useState(false);

  const userRecipientsIds = getCollaborationUserRecipientsIds(store, collaborationId);
  const groupRecipientsIds = getCollaborationGroupRecipientsIds(store, collaborationId);
  const roleRecipientsIds = getCollaborationRoleRecipientsIds(store, collaborationId);
  const collaborationContextsObjectIds = getCollaborationContextsObjectIds(store, collaborationId);

  const firstClassConceptId = collaborationContextsObjectIds?.find((objectId) => isInstanceOf(store.getObject(objectId), Concept));
  let conceptDefinitionId: string | undefined;
  if (firstClassConceptId) {
    const concept = store.getObjectOrNull(firstClassConceptId);
    conceptDefinitionId = concept?.[Instance_Of] as string | undefined;
  } else {
    conceptDefinitionId = collaborationContextsObjectIds?.find((id) => id.length === 1 && isInstanceOf(store.getObject(id[0]), ConceptDefinition))?.[0];
  }

  const usersTooltipRef = useTooltipRef(i18n`Users that will be notified of all new messages.`);
  const groupsTooltipRef = useTooltipRef(i18n`Groups that will be notified of all new messages.`);
  const rolesTooltipRef = useTooltipRef(i18n`Roles that will be notified of all new messages.`);

  return (
    <UsageContextProvider usageVariant={UsageVariant.inForm}>
      <div className={classes.block}>
        <div className={classes.titleLine}>
          <div className={classes.title}>
            <Typo ref={usersTooltipRef}>{i18n`Users`}</Typo>
            {userRecipientsIds.length > 0 ? (<Typo color={theme.color.text.secondary}>{userRecipientsIds.length}</Typo>) : null}
          </div>
          <IconOnlyButton
            tooltip={isUsersCollapsed ? i18n`Expand` : i18n`Collapse`}
            variant={IconOnlyButtonVariants.tertiary}
            iconName={isUsersCollapsed ? IconName.keyboard_arrow_left : IconName.expand_more}
            onClick={() => setIsUsersCollapsed((current) => !current)}
            sizeVariant={SizeVariant.small}
          />
        </div>
        {
          isUsersCollapsed
            ? null
            : (
              <div className={classes.content}>
                <SearchAndSelectMultiple
                  placeholder={i18n`Add user`}
                  selectedOptions={userRecipientsIds.map((id) => getChipOptions(store, id)).filter(filterNullOrUndefined).sort(defaultOptionComparator)}
                  computeOptions={() => {
                    const users = firstClassConceptId && !canAssignCollaborator(firstClassConceptId)
                      ? Array.from(getViewerUsersSet(store, firstClassConceptId))
                      : getModelTypeInstances(store, User).map(({ id }) => id);
                    return users.map((id) => getChipOptions(store, id)).filter(filterNullOrUndefined).sort(defaultOptionComparator);
                  }}
                  searchOptions={getSearchChipOptions(store, User)}
                  onSelect={({ id }) => {
                    store.withAssociation(CollaborationUsers)
                      .withRole(CollaborationUsers_Role_Collaboration, collaborationId)
                      .withRole(CollaborationUsers_Role_User, id)
                      .updateObject({});
                  }}
                  onDelete={({ id }) => {
                    store.withAssociation(CollaborationUsers)
                      .withRole(CollaborationUsers_Role_Collaboration, collaborationId)
                      .withRole(CollaborationUsers_Role_User, id)
                      .deleteObject();
                  }}
                  getInlineCreation={
                    canCreateObject(User) && firstClassConceptId && canAssignCollaborator(firstClassConceptId)
                      ? getInlineCreationBuilder(
                        store,
                        User,
                        (id) => {
                          store.withAssociation(CollaborationUsers)
                            .withRole(CollaborationUsers_Role_Collaboration, collaborationId)
                            .withRole(CollaborationUsers_Role_User, id)
                            .updateObject({});
                        }
                      )
                      : undefined
                  }
                />
              </div>
            )
        }
      </div>
      <span className={classes.separator} />
      <div className={classes.block}>
        <div className={classes.titleLine}>
          <div className={classes.title}>
            <Typo ref={groupsTooltipRef}>{i18n`Groups`}</Typo>
            {groupRecipientsIds.length > 0 ? (<Typo color={theme.color.text.secondary}>{groupRecipientsIds.length}</Typo>) : null}
          </div>
          <IconOnlyButton
            tooltip={isGroupsCollapsed ? i18n`Expand` : i18n`Collapse`}
            variant={IconOnlyButtonVariants.tertiary}
            iconName={isGroupsCollapsed ? IconName.keyboard_arrow_left : IconName.expand_more}
            onClick={() => setIsGroupsCollapsed((current) => !current)}
            sizeVariant={SizeVariant.small}
          />
        </div>
        {
          isGroupsCollapsed
            ? null
            : (
              <div className={classes.content}>
                <SearchAndSelectMultiple
                  placeholder={i18n`Add group`}
                  selectedOptions={groupRecipientsIds.map((id) => getChipOptions(store, id)).filter(filterNullOrUndefined).sort(defaultOptionComparator)}
                  computeOptions={() => {
                    const groups = firstClassConceptId && !canAssignCollaborator(firstClassConceptId)
                      ? Array.from(getViewerGroupsSet(store, firstClassConceptId))
                      : getModelTypeInstances(store, Group).map(({ id }) => id);
                    return groups.map((id) => getChipOptions(store, id)).filter(filterNullOrUndefined).sort(defaultOptionComparator);
                  }}
                  searchOptions={getSearchChipOptions(store, Group)}
                  onSelect={({ id }) => {
                    store.withAssociation(CollaborationGroups)
                      .withRole(CollaborationGroups_Role_Collaboration, collaborationId)
                      .withRole(CollaborationGroups_Role_Group, id)
                      .updateObject({});
                  }}
                  onDelete={({ id }) => {
                    store.withAssociation(CollaborationGroups)
                      .withRole(CollaborationGroups_Role_Collaboration, collaborationId)
                      .withRole(CollaborationGroups_Role_Group, id)
                      .deleteObject();
                  }}
                  getInlineCreation={
                    canCreateObject(Group) && firstClassConceptId && canAssignCollaborator(firstClassConceptId)
                      ? getInlineCreationBuilder(
                        store,
                        Group,
                        (id) => {
                          store.withAssociation(CollaborationGroups)
                            .withRole(CollaborationGroups_Role_Collaboration, collaborationId)
                            .withRole(CollaborationGroups_Role_Group, id)
                            .updateObject({});
                        }
                      )
                      : undefined
                  }
                  readOnly={!!firstClassConceptId && !canAssignCollaborator(firstClassConceptId)}
                />
              </div>
            )
        }
      </div>
      <span className={classes.separator} />
      <div className={classes.block}>
        <div className={classes.titleLine}>
          <div className={classes.title}>
            <Typo ref={rolesTooltipRef}>{i18n`Roles`}</Typo>
            {roleRecipientsIds.length > 0 && (<Typo color={theme.color.text.secondary}>{roleRecipientsIds.length}</Typo>)}
          </div>
          <IconOnlyButton
            tooltip={isRolesCollapsed ? i18n`Expand` : i18n`Collapse`}
            variant={IconOnlyButtonVariants.tertiary}
            iconName={isRolesCollapsed ? IconName.keyboard_arrow_left : IconName.expand_more}
            onClick={() => setIsRolesCollapsed((current) => !current)}
            sizeVariant={SizeVariant.small}
          />
        </div>
        {
          !isRolesCollapsed && (
          <div className={classes.content}>
            <SearchAndSelectMultiple
              placeholder={i18n`Add role`}
              selectedOptions={roleRecipientsIds.map((id) => getChipOptions(store, id)).filter(filterNullOrUndefined).sort(defaultOptionComparator)}
              computeOptions={() => (conceptDefinitionId ? store.getObject(conceptDefinitionId)
                .navigateBack(ConceptRole_ConceptDefinition)
                .map((role) => getChipOptions(store, role.id))
                .filter(filterNullOrUndefined)
                .sort(defaultOptionComparator)
                : [])}
              onSelect={({ id }) => {
                store.withAssociation(CollaborationRoles)
                  .withRole(CollaborationRoles_Role_Collaboration, collaborationId)
                  .withRole(CollaborationRoles_Role_Role, id)
                  .updateObject({});
              }}
              onDelete={({ id }) => {
                store.withAssociation(CollaborationRoles)
                  .withRole(CollaborationRoles_Role_Collaboration, collaborationId)
                  .withRole(CollaborationRoles_Role_Role, id)
                  .deleteObject();
              }}
              readOnly={!!firstClassConceptId && !canAssignCollaborator(firstClassConceptId)}
            />
          </div>
          )
        }
      </div>
    </UsageContextProvider>
  );
};

export default CollaborationDetailPanelSubscribersTab;
