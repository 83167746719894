import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { newError } from 'yooi-utils';
import { getDslFieldHandler } from '../../common/fields/FieldModuleDsl';
import type { GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import { isInstanceOf } from '../../typeModule';
import { Field as FieldId } from '../ids';
import type { Field } from '../index';
import type { FieldFilterConditions } from './filters/filters';

interface GetFieldUtilsHandler {
  // TODO This API should include the conceptDefinitionId: string
  (
    objectStore: ObjectStoreWithTimeseries,
    fieldId: string
  ): ReturnType<GetDslFieldHandler<
    Field, unknown, unknown, unknown, unknown, unknown, FieldFilterConditions<unknown> | never, Record<string, unknown> | undefined, object | undefined, object | undefined>
  > | Record<string, never>,
}

export const getFieldUtilsHandler: GetFieldUtilsHandler = (objectStore, fieldId) => {
  const field = objectStore.getObjectOrNull(fieldId);
  if (!field) {
    throw newError('getFieldUtilsHandler: Field does not exist', { fieldId });
  } else if (!isInstanceOf(field, FieldId)) {
    return {};
  }

  return getDslFieldHandler(objectStore, fieldId);
};
