import { Intention, Intention_Name } from 'yooi-modules/modules/collaborationModule/ids';
import type { FieldBlockDisplayStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import {
  FieldBlockDisplay,
  FieldBlockDisplay_FieldPath,
  SearchFieldDisplay,
  SearchFieldDisplay_Role_ConceptDefinition,
  SearchFieldDisplay_Role_Field,
  TextConstantField,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import type {
  AssociationFieldStoreObject,
  ConceptCapabilityStoreObject,
  ConceptDefinitionStoreObject,
  ConceptStoreObject,
  DimensionsMapping,
  ExternalKeyFieldStoreObject,
  FieldDefinitionStoreObject,
  FieldDimensionStoreObject,
  FieldDimensionTypesStoreObject,
  FieldStoreObject,
  IdFieldStoreObject,
  KinshipRelationFieldStoreObject,
  MultipleParameterDefinition,
  PlatformCapabilityStoreObject,
  RelationSingleFieldStoreObject,
  SingleParameterDefinition,
  TextFieldStoreObject,
  WorkflowFieldStoreObject,
  WorkflowStoreObject,
  WorkflowTransitionStoreObject,
} from 'yooi-modules/modules/conceptModule';
import {
  associationFieldHandler,
  displayInstanceFieldAsText,
  getAllFieldDimensionsIds,
  getConceptChipFields,
  getConceptDefinitionValidFields,
  getConceptUrl,
  getFields,
  getInstanceLabelOrUndefined,
  getPathLastFieldInformation,
  getRawInstanceLabel,
  getRelevantFields,
  hasPlatformCapability,
  isConceptValid,
  kinshipRelationFieldHandler,
  relationSingleFieldHandler,
  workflowFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import {
  AssociationField,
  AssociationField_Definition,
  AssociationField_Field_TargetFilter,
  AssociationField_SourceRole,
  AssociationFieldDefinition_Role1Type,
  AssociationFieldDefinition_Role2Type,
  ColorField,
  Concept,
  ConceptCapability,
  ConceptCapability_Name,
  ConceptDefinition,
  ConceptDefinition_ChipBackgroundColor,
  ConceptDefinition_ChipDisplayIcon,
  ConceptDefinition_Color,
  ConceptDefinition_Icon,
  ConceptDefinition_MatrixAbs,
  ConceptDefinition_MatrixOrd,
  ConceptDefinition_Name,
  ConceptDefinition_SwimlaneColumnBy,
  ConceptDefinition_SwimlaneGroupBy,
  ConceptDefinition_SwimlaneProgress,
  ConceptDefinition_TableGroupBy,
  ConceptDefinition_Timeline,
  ConceptDefinition_TimelineDependency,
  ConceptDefinition_TimelineGroupBy,
  ConceptDefinition_TimelineProgress,
  DateField,
  ExternalKeyField,
  Field,
  Field_FieldDimensions,
  Field_Formula,
  Field_Title,
  FieldDefinition,
  FieldDimension,
  FieldDimension_Label,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  IdField,
  KinshipRelationField,
  NumberField,
  PlatformCapability,
  PlatformCapabilityAdmin,
  RelationMultipleField_Field_TargetFilter,
  RelationSingleField,
  RelationSingleField_Field_TargetFilter,
  TextField,
  TimelineField,
  User,
  User_Email,
  Workflow,
  Workflow_Name,
  WorkflowEntry,
  WorkflowEntry_Rank,
  WorkflowEntry_Role_Concept,
  WorkflowEntry_Role_Workflow,
  WorkflowField,
  WorkflowTransition,
  WorkflowTransition_From,
  WorkflowTransition_Name,
  WorkflowTransition_To,
} from 'yooi-modules/modules/conceptModule/ids';
import type { DashboardParameterStoreObject } from 'yooi-modules/modules/dashboardModule';
import { DashboardParameter, DashboardParameter_Label, DashboardParameter_Type } from 'yooi-modules/modules/dashboardModule/ids';
import type { DataAssetTypeStoreObject } from 'yooi-modules/modules/dataAssetModule';
import { DataAsset, DataAssetType, DataAssetType_Name } from 'yooi-modules/modules/dataAssetModule/ids';
import type { ResourceTypeStoreObject } from 'yooi-modules/modules/resourceModule';
import { ResourceType, ResourceType_Name } from 'yooi-modules/modules/resourceModule/ids';
import type { TemplateConceptDefinitionStoreObject } from 'yooi-modules/modules/templateModule';
import { TemplateConceptDefinition, TemplateConceptDefinition_Name } from 'yooi-modules/modules/templateModule/ids';
import { doExtends, isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreReadOnly, ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import { compareBoolean, compareProperty, compareRank, compareString, comparing, filterNullOrUndefined, newError, pushUndefinedToEnd } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../components/atoms/Icon';
import type { FrontObjectStore } from '../../store/useStore';
import base from '../../theme/base';
import i18n from '../../utils/i18n';
import { formatOrUndef } from '../../utils/stringUtils';
import type { NavigationPayload, UseNavigation } from '../../utils/useNavigation';
import { platformCapabilities } from '../utils/platformCapabilityUtils';
import {
  computeBorderColor,
  conceptDefinitionChipBackgroundColor,
  getConceptModelDisplayField,
  getModelBackgroundColor,
  isFieldAvailableForColoration,
  resolveConceptChipIcon,
  resolveConceptColorValue,
} from './conceptDisplayUtils';
import { getFieldConfigurationHandler, getFieldDefinitionHandler } from './fields/FieldLibrary';
import type { Chip } from './fieldUtils';
import { getFieldChip, getFieldLabel } from './fieldUtils';
import type { ChipOption } from './modelTypeUtilsType';
import type { NavigationFilter } from './navigationUtils';

export const getModelTypeIcon = (store: ObjectStoreReadOnly, modelTypeId: string | undefined): IconName | undefined => {
  if (!modelTypeId) {
    return undefined;
  }
  const modelType = store.getObject(modelTypeId);
  if (doExtends(modelType, Concept)) {
    return modelType[ConceptDefinition_Icon] as IconName;
  } else {
    return IconName.spoke;
  }
};

export const getAutomationRuleUrl = (ruleId: string, isPersonal: boolean): string => {
  if (isPersonal) {
    return `/settings/notification/${ruleId}`;
  } else {
    return `/settings/automation/${ruleId}`;
  }
};

export const getConceptDefinitionNameOrEntity = (store: ObjectStoreReadOnly, conceptDefinitionId: string): string => (
  formatOrUndef(store.getObjectOrNull<ConceptDefinitionStoreObject>(conceptDefinitionId)?.[ConceptDefinition_Name])
);

export const getObjectName = (store: ObjectStoreReadOnly, object: StoreObject): string => {
  if (doExtends(object, Concept)) {
    return getConceptDefinitionNameOrEntity(store, object.id);
  } else if (object.id === Concept) {
    return i18n`Concept`;
  } else if (object.id === DataAssetType) {
    return i18n`${getConceptDefinitionNameOrEntity(store, DataAsset)} type`;
  } else if (object.id === ResourceType) {
    return i18n`Resource type`;
  } else {
    throw newError('getObjectName: Not able to get object name', { objectId: object.id });
  }
};

export const getTypeLabel = (store: ObjectStoreReadOnly, instance: StoreObject): string | undefined => getObjectName(store, instance.navigate(Instance_Of));

export const getChipOptions = (store: FrontObjectStore, instanceId: string, navigationFilters?: NavigationFilter): ChipOption | undefined => {
  const instance = store.getObjectOrNull<StoreObject>(instanceId);
  if (!instance || !instance.navigateOrNull(Instance_Of)) {
    return undefined;
  }

  if (isInstanceOf<FieldDefinitionStoreObject>(instance, FieldDefinition)) {
    const fieldDefinitionHandler = getFieldDefinitionHandler(store, instanceId);
    const label = fieldDefinitionHandler.getTypeLabel();
    return {
      id: instanceId,
      object: instance,
      isLabelUndefined: false,
      label,
      tooltip: label,
      icon: fieldDefinitionHandler.typeIcon,
    };
  } else if (isInstanceOf<FieldStoreObject>(instance, Field)) {
    const fieldHandler = getFieldConfigurationHandler(store, instanceId);
    const label = getFieldLabel(store, instance);

    let getNavigationPayload: Chip['getNavigationPayload'];
    if (hasPlatformCapability(store, store.getLoggedUserId(), PlatformCapabilityAdmin)) {
      const fieldDimension = instance.navigateBack<FieldDimensionStoreObject>(Field_FieldDimensions).at(0);
      if (fieldDimension !== undefined) {
        const fieldDimensionTypes = store.withAssociation(FieldDimensionTypes)
          .withRole(FieldDimensionTypes_Role_FieldDimension, fieldDimension.id)
          .list()
          .at(0);
        if (fieldDimensionTypes !== undefined) {
          const conceptDefinitionId = fieldDimensionTypes.role(FieldDimensionTypes_Role_ConceptDefinition);
          getNavigationPayload = () => ({ to: `/settings/organization/${conceptDefinitionId}/field/${instanceId}` });
        }
      }
    }

    return {
      id: instanceId,
      object: instance,
      icon: fieldHandler.getIcon(),
      isLabelUndefined: !fieldHandler.getTitle(),
      label,
      tooltip: `${label} (${fieldHandler.getTypeLabel()})`,
      color: base.color.gray['300'],
      startIcons: [
        getAllFieldDimensionsIds(store, instanceId).length > 1
          ? { key: 'multidimensional', icon: IconName.pivot_table_chart, colorVariant: IconColorVariant.info, tooltip: i18n`Multidimensional` } : undefined,
        instance[Field_Formula]
          ? { key: 'computed', icon: IconName.fx, colorVariant: IconColorVariant.info, tooltip: i18n`Computed` } : undefined,
        instance[AssociationField_Field_TargetFilter] || instance[RelationMultipleField_Field_TargetFilter] || instance[RelationSingleField_Field_TargetFilter]
          ? { key: 'isFiltered', icon: IconName.filter_list, colorVariant: IconColorVariant.info, tooltip: i18n`Display filters applied on field.` } : undefined,
      ].filter(filterNullOrUndefined),
      getNavigationPayload,
    };
  } else if (isInstanceOf<ConceptCapabilityStoreObject>(instance, ConceptCapability)) {
    const rawLabel = instance[ConceptCapability_Name];
    return {
      id: instanceId,
      object: instance,
      isLabelUndefined: !rawLabel,
      label: formatOrUndef(rawLabel),
      tooltip: i18n`Capability`,
    };
  } else if (isInstanceOf<PlatformCapabilityStoreObject>(instance, PlatformCapability)) {
    const rawLabel = platformCapabilities[instance.id]?.name;
    return {
      id: instanceId,
      object: instance,
      isLabelUndefined: !rawLabel,
      label: formatOrUndef(rawLabel),
      tooltip: platformCapabilities[instance.id]?.description ?? i18n`Platform capability`,
    };
  } else if (instanceId === ResourceType) {
    const rawLabel = i18n`Resource type`;
    return {
      id: instanceId,
      object: instance,
      label: rawLabel,
      tooltip: rawLabel,
    };
  } else if (instanceId === DataAssetType) {
    const rawLabel = i18n`Data asset type`;
    return {
      id: instanceId,
      object: instance,
      label: rawLabel,
      tooltip: rawLabel,
    };
  } else if (isInstanceOf<ResourceTypeStoreObject>(instance, ResourceType)) {
    const rawLabel = instance[ResourceType_Name];
    return {
      id: instanceId,
      object: instance,
      isLabelUndefined: !rawLabel,
      label: formatOrUndef(rawLabel),
      tooltip: i18n`${formatOrUndef(rawLabel)} (Resource Type)`,
    };
  } else if (isInstanceOf<DataAssetTypeStoreObject>(instance, DataAssetType)) {
    const rawLabel = instance[DataAssetType_Name];
    return {
      id: instanceId,
      object: instance,
      isLabelUndefined: !rawLabel,
      label: formatOrUndef(rawLabel),
      tooltip: i18n`${formatOrUndef(rawLabel)} (Data Asset Type)`,
    };
  } else if (isInstanceOf<ConceptStoreObject>(instance, Concept)) {
    if (!isConceptValid(store, instance.id)) {
      return undefined;
    }

    const rawInstanceLabel = getRawInstanceLabel(store, instance);

    const isLabelUndefined = rawInstanceLabel.every((element) => element === undefined);
    const label = isLabelUndefined ? i18n`Undefined` : rawInstanceLabel.map(formatOrUndef).join(' - ');
    let tooltipExtraElement = '';
    if (instance[Instance_Of] === User && instance[User_Email] && !getConceptChipFields(store, instance).find(({ id }) => id === User_Email)) {
      tooltipExtraElement += ` (${instance[User_Email]})`;
    }
    const typeLabel = getTypeLabel(store, instance);
    if (typeLabel) {
      tooltipExtraElement += ` (${typeLabel})`;
    }

    const conceptDefinition = instance.navigateOrNull<ConceptDefinitionStoreObject>(Instance_Of) ?? undefined;

    const icon = resolveConceptChipIcon(store, instanceId);

    return {
      id: instanceId,
      object: instance,
      isLabelUndefined,
      label,
      tooltip: `${label}${tooltipExtraElement}`,
      icon: conceptDefinition?.[ConceptDefinition_ChipDisplayIcon] ? getModelTypeIcon(store, instance[Instance_Of]) : undefined,
      color: getModelBackgroundColor(instance),
      squareColor: resolveConceptColorValue(store, instance.id),
      getNavigationPayload: (navigation) => navigation.createNavigationPayload(
        instance.id,
        { pathname: getConceptUrl(store, instance.id), navigationFilters }
      ),
      startIcons: icon !== undefined ? [{ key: 'iconField', icon: icon.icon as IconName, color: icon.color }] : undefined,
    };
  } else if (isInstanceOf<ConceptDefinitionStoreObject>(instance, ConceptDefinition)) {
    const rawLabel = store.getObjectOrNull<ConceptDefinitionStoreObject>(instanceId)?.[ConceptDefinition_Name];
    return {
      id: instanceId,
      object: instance,
      isLabelUndefined: !rawLabel,
      label: formatOrUndef(rawLabel),
      tooltip: formatOrUndef(rawLabel),
      icon: getModelTypeIcon(store, instanceId),
      getNavigationPayload: hasPlatformCapability(store, store.getLoggedUserId(), PlatformCapabilityAdmin)
        ? () => ({ to: `/settings/organization/${instanceId}` })
        : undefined,
      color: conceptDefinitionChipBackgroundColor,
      borderColor: computeBorderColor(instance[ConceptDefinition_ChipBackgroundColor] ?? conceptDefinitionChipBackgroundColor),
      borderStyle: 'solid',
    };
  } else if (instanceId === Concept) {
    const label = i18n`Concept`;
    return {
      id: instanceId,
      object: instance,
      label,
      tooltip: label,
    };
  } else if (isInstanceOf<DashboardParameterStoreObject>(instance, DashboardParameter)) {
    const rawLabel = instance[DashboardParameter_Label];
    return {
      id: instanceId,
      object: instance,
      isLabelUndefined: !rawLabel,
      label: formatOrUndef(rawLabel),
      tooltip: i18n`${formatOrUndef(rawLabel)} (Parameter)`,
      color: instance.navigateOrNull<ConceptDefinitionStoreObject>(DashboardParameter_Type)?.[ConceptDefinition_ChipBackgroundColor],
      borderStyle: 'dashed',
    };
  } else if (isInstanceOf<FieldDimensionStoreObject>(instance, FieldDimension)) {
    const conceptDefinitions = store.withAssociation(FieldDimensionTypes)
      .withRole(FieldDimensionTypes_Role_FieldDimension, instanceId)
      .list<FieldDimensionTypesStoreObject>()
      .map((assoc) => assoc.navigateRole<ConceptDefinitionStoreObject>(FieldDimensionTypes_Role_ConceptDefinition));

    return {
      id: instanceId,
      object: instance,
      isLabelUndefined: false,
      label: instance[FieldDimension_Label] ?? i18n`Dimension`,
      tooltip: i18n`${instance[FieldDimension_Label] ?? conceptDefinitions.map((conceptDefinition) => conceptDefinition[ConceptDefinition_Name])
        .join('|')} (Dimension)`,
      color: conceptDefinitions.length === 1 ? conceptDefinitions[0][ConceptDefinition_ChipBackgroundColor] : undefined,
      borderStyle: 'dashed',
    };
  } else if (isInstanceOf<TemplateConceptDefinitionStoreObject>(instance, TemplateConceptDefinition)) {
    const rawLabel = instance[TemplateConceptDefinition_Name];
    return {
      id: instanceId,
      object: instance,
      isLabelUndefined: !rawLabel,
      label: formatOrUndef(rawLabel),
      tooltip: formatOrUndef(rawLabel),
    };
  } else if (isInstanceOf<WorkflowStoreObject>(instance, Workflow)) {
    const rawLabel = instance[Workflow_Name];
    return {
      id: instanceId,
      object: instance,
      isLabelUndefined: !rawLabel,
      label: formatOrUndef(rawLabel),
      tooltip: formatOrUndef(rawLabel),
      getNavigationPayload: () => ({ to: `/settings/workflow/${instanceId}` }),
    };
  } else if (isInstanceOf<WorkflowTransitionStoreObject>(instance, WorkflowTransition)) {
    const rawLabel = instance[WorkflowTransition_Name];
    const label = formatOrUndef(rawLabel);
    const from = instance.navigateOrNull(WorkflowTransition_From);
    const to = instance.navigateOrNull(WorkflowTransition_To);

    return {
      id: instanceId,
      object: instance,
      isLabelUndefined: !rawLabel,
      label,
      tooltip: i18n`${label} : from ${from ? getInstanceLabelOrUndefined(store, from) : i18n`Undefined`} to ${to ? getInstanceLabelOrUndefined(store, to) : i18n`Undefined`}`,
    };
  } else if (isInstanceOf<FieldBlockDisplayStoreObject>(instance, FieldBlockDisplay)) {
    let label: string;
    const lastFieldInfo = instance[FieldBlockDisplay_FieldPath] ? getPathLastFieldInformation(instance[FieldBlockDisplay_FieldPath]) : undefined;
    if (lastFieldInfo?.fieldId) {
      const field = store.getObjectOrNull(lastFieldInfo.fieldId);
      label = `${formatOrUndef(field?.[Field_Title] as string | undefined)} - Display Options`;
    } else {
      label = `${formatOrUndef(undefined)} - Display Options`;
    }
    return {
      id: instanceId,
      object: instance,
      isLabelUndefined: !label,
      label,
      tooltip: label,
      borderStyle: 'dashed',
    };
  } else if (isInstanceOf(instance, Intention)) {
    const rawLabel = instance[Intention_Name] as string | undefined;
    return {
      id: instanceId,
      object: instance,
      isLabelUndefined: !rawLabel,
      label: formatOrUndef(rawLabel),
      tooltip: formatOrUndef(rawLabel),
    };
  } else {
    throw newError('getChipOptions: Instance not supported', { instanceId: instance.id });
  }
};

export const getUnknownChip = (id: string): Option => ({
  id,
  label: i18n`Unknown`,
  tooltip: i18n`Unknown (Has been deleted or cannot be accessed)`,
  icon: { name: IconName.dangerous, colorVariant: IconColorVariant.error },
});

export const getChipOptionWithUnknown = (store: FrontObjectStore, instanceId: string): ChipOption | Option => {
  const chipOption = getChipOptions(store, instanceId);
  return chipOption !== undefined ? chipOption : getUnknownChip(instanceId);
};

export const getFieldChipOrUnknown = (store: FrontObjectStore, conceptDefinitionId: string, fieldId: string): Chip => {
  if (store.getObjectOrNull(fieldId) === null) {
    return getUnknownChip(fieldId);
  } else {
    return getFieldChip(store, conceptDefinitionId, fieldId);
  }
};

export interface Option<Id extends string = string> {
  id: Id,
  label: string,
  color?: string,
  borderStyle?: 'solid' | 'dashed',
  borderColor?: string,
  tooltip?: string,
  icon?: IconName | { name: IconName, colorVariant: IconColorVariant } | { name: IconName, color: string },
  squareColor?: string,
  noDelete?: boolean,
  getNavigationPayload?: (navigation: UseNavigation) => NavigationPayload,
  startIcons?: { key: string, icon: IconName, colorVariant?: IconColorVariant, color?: string, tooltip?: string }[],
  endIcons?: { key: string, icon: IconName, colorVariant?: IconColorVariant, color?: string, tooltip?: string }[],
}

export type OptionRecord<Id extends string = string> = { [key in Id]: Option<key> };

export const defaultOptionComparator = (
  comparing<{ isLabelUndefined?: boolean, label: string, iconName?: string }>(compareProperty('isLabelUndefined', compareBoolean), true)
    .thenComparing(compareProperty('label', compareString))
    .thenComparing(compareProperty('iconName', compareString))
);

export const getParameterOption = (store: FrontObjectStore, { id, label, typeId: parameterTypeId, type }: (SingleParameterDefinition | MultipleParameterDefinition)): Option => {
  if (store.getObjectOrNull(id)) {
    return getChipOptions(store, id) ?? getUnknownChip(id);
  } else if (parameterTypeId) {
    const conceptDefinition = store.getObjectOrNull<ConceptDefinitionStoreObject>(parameterTypeId);
    if (conceptDefinition) {
      let tooltip;
      switch (type) {
        case 'parameter':
          tooltip = i18n`Parameter`;
          break;
        case 'dimension':
          tooltip = i18n`Dimension`;
          break;
        case 'parameterList':
          tooltip = i18n`Multiple parameter`;
          break;
      }
      return {
        id,
        label,
        tooltip: `${label} (${tooltip})`,
        color: conceptDefinition[ConceptDefinition_ChipBackgroundColor],
        borderStyle: 'dashed',
      };
    } else {
      return getUnknownChip(id);
    }
  } else {
    return getUnknownChip(id);
  }
};

export const getOption = (store: FrontObjectStore, instanceId: string, parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[]): Option => {
  const chipOption = getChipOptions(store, instanceId);
  if (chipOption) {
    return chipOption;
  }
  const parameter = parameterDefinitions.find(({ id: parameterId }) => parameterId === instanceId);
  if (parameter) {
    return getParameterOption(store, parameter);
  } else {
    return getUnknownChip(instanceId);
  }
};

export const getModelTypeInstances = (store: ObjectStoreReadOnly, modelTypeId: string): StoreObject[] => {
  if (modelTypeId === Concept) {
    return store.getObject(ConceptDefinition)
      .navigateBack(Class_Instances)
      .flatMap((conceptDefinition) => conceptDefinition.navigateBack(Class_Instances).filter(({ id }) => isConceptValid(store, id)));
  }

  const modelType = store.getObject(modelTypeId);
  if (doExtends(modelType, Concept)) {
    return modelType.navigateBack(Class_Instances).filter(({ id }) => isConceptValid(store, id));
  } else {
    return modelType.navigateBack(Class_Instances);
  }
};

export const getModelTypeOptions = (store: FrontObjectStore, modelTypeId: string): ChipOption[] => (
  getModelTypeInstances(store, modelTypeId)
    .map(({ id }) => getChipOptions(store, id))
    .filter(filterNullOrUndefined)
    .sort(defaultOptionComparator)
);

export const listColorFieldOptions = (store: FrontObjectStore, conceptDefinitionId: string): Chip[] => (
  getRelevantFields(store, conceptDefinitionId, [ColorField, RelationSingleField, WorkflowField])
    .filter(({ id }) => isFieldAvailableForColoration(store, conceptDefinitionId, id))
    .map(({ id }) => getFieldChip(store, conceptDefinitionId, id))
);

export const getColorField = (
  store: ObjectStoreWithTimeseries,
  conceptDefinitionId: string,
  sessionStorageConfig: { [ConceptDefinition_Color]: string | null | undefined } | undefined,
  viewDefaultColorFieldId: string | undefined,
  isInView: boolean
): FieldStoreObject | undefined => {
  if (isInView && sessionStorageConfig?.[ConceptDefinition_Color] === undefined) {
    if (viewDefaultColorFieldId) {
      return store.getObjectOrNull<FieldStoreObject>(viewDefaultColorFieldId) ?? undefined;
    } else {
      return undefined;
    }
  } else {
    return getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_Color, sessionStorageConfig) ?? undefined;
  }
};

export const listProgressFieldOptions = (store: FrontObjectStore, conceptDefinitionId: string): Chip[] => (
  getFields(store, conceptDefinitionId, [NumberField])
    .map((field) => getFieldChip(store, conceptDefinitionId, field.id))
    .sort(defaultOptionComparator)
);

export const getTimelineProgressField = (
  store: ObjectStoreWithTimeseries,
  conceptDefinitionId: string,
  sessionStorageConfig: { [ConceptDefinition_TimelineProgress]: string | null | undefined } | undefined,
  viewDefaultTimelineProgressFieldId: string | undefined,
  isInView = false
): StoreObject | undefined => {
  if (isInView && sessionStorageConfig?.[ConceptDefinition_TimelineProgress] === undefined) {
    if (viewDefaultTimelineProgressFieldId) {
      return store.getObjectOrNull(viewDefaultTimelineProgressFieldId) ?? undefined;
    } else {
      return undefined;
    }
  } else {
    return getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_TimelineProgress, sessionStorageConfig) ?? undefined;
  }
};

export const getSwimlaneProgressField = (
  store: ObjectStoreWithTimeseries,
  conceptDefinitionId: string,
  sessionStorageConfig: { [ConceptDefinition_SwimlaneProgress]: string | null | undefined } | undefined,
  viewDefaultProgressFieldId: string | undefined,
  isInView = false
): FieldStoreObject | undefined => {
  if (isInView && sessionStorageConfig?.[ConceptDefinition_SwimlaneProgress] === undefined) {
    if (viewDefaultProgressFieldId) {
      return store.getObjectOrNull<FieldStoreObject>(viewDefaultProgressFieldId) ?? undefined;
    } else {
      return undefined;
    }
  } else {
    return getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_SwimlaneProgress, sessionStorageConfig) ?? undefined;
  }
};

export const listMatrixFieldOptions = (store: FrontObjectStore, conceptDefinitionId: string): Chip[] => (
  getFields(store, conceptDefinitionId, [NumberField])
    .map((field) => getFieldChip(store, conceptDefinitionId, field.id))
    .sort(defaultOptionComparator)
);

export const getMatrixOrdFieldId = (
  store: FrontObjectStore,
  conceptDefinitionId: string,
  sessionStorageConfig: { [ConceptDefinition_MatrixOrd]: string | null | undefined } | undefined
): string | undefined => {
  const matrixOrdField = getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_MatrixOrd, sessionStorageConfig)?.id;
  if (matrixOrdField) {
    return matrixOrdField;
  } else {
    const matrixOptions = listMatrixFieldOptions(store, conceptDefinitionId);
    if (matrixOptions.length === 0) {
      return undefined;
    } else {
      return matrixOptions[0].id;
    }
  }
};

export const getMatrixAbsFieldId = (
  store: FrontObjectStore,
  conceptDefinitionId: string,
  sessionStorageConfig: { [ConceptDefinition_MatrixAbs]: string | null | undefined } | undefined
): string | undefined => {
  const matrixAbsField = getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_MatrixAbs, sessionStorageConfig)?.id;
  if (matrixAbsField) {
    return matrixAbsField;
  } else {
    const matrixOptions = listMatrixFieldOptions(store, conceptDefinitionId);
    if (matrixOptions.length < 2) {
      return undefined;
    } else {
      return matrixOptions[1].id;
    }
  }
};

export const listColumnByFieldOptions = (store: FrontObjectStore, conceptDefinitionId: string): Chip[] => (
  getRelevantFields(store, conceptDefinitionId, [KinshipRelationField, RelationSingleField, WorkflowField])
    .map((field) => getFieldChip(store, conceptDefinitionId, field.id))
);

export const listGroupByFieldOptions = (store: FrontObjectStore, conceptDefinitionId: string, notSelf = false): Chip[] => {
  const chips = getRelevantFields(store, conceptDefinitionId, [KinshipRelationField, RelationSingleField, WorkflowField, AssociationField])
    .map((field) => getFieldChip(store, conceptDefinitionId, field.id));

  if (!notSelf) {
    const option = getChipOptions(store, conceptDefinitionId);
    if (option) {
      chips.push(option);
    }
  }

  return chips;
};

export const getGroupByValueResolver = (store: FrontObjectStore, fieldId: string): ((dimensionsMapping: DimensionsMapping) => StoreObject | undefined) => {
  const field = store.getObject(fieldId);
  if (isInstanceOf<KinshipRelationFieldStoreObject>(field, KinshipRelationField)) {
    const fieldHandler = kinshipRelationFieldHandler(store, fieldId);
    return (dimensionsMapping) => fieldHandler.getValueResolution(dimensionsMapping).value;
  } else if (isInstanceOf<RelationSingleFieldStoreObject>(field, RelationSingleField)) {
    const fieldHandler = relationSingleFieldHandler(store, fieldId);
    return (dimensionsMapping) => fieldHandler.getValueResolution(dimensionsMapping).value;
  } else if (isInstanceOf<WorkflowFieldStoreObject>(field, WorkflowField)) {
    const fieldHandler = workflowFieldHandler(store, fieldId);
    return (dimensionsMapping) => fieldHandler.getValueResolution(dimensionsMapping).value.value;
  } else {
    throw newError('Unsupported groupBy field type', { fieldId });
  }
};

export const listGroupByValueOptions = (store: FrontObjectStore, field: StoreObject, conceptDefinitionId: string): ChipOption[] => {
  if (isInstanceOf<KinshipRelationFieldStoreObject>(field, KinshipRelationField)) {
    const targetedConceptDefinitionIds: string[] = [];
    const { getTargetTypes, getTargetType } = kinshipRelationFieldHandler(store, field.id);
    if (getTargetTypes) {
      targetedConceptDefinitionIds.push(...getTargetTypes(conceptDefinitionId).map(({ id }) => id));
    } else if (getTargetType) {
      const targetType = getTargetType();
      if (targetType) {
        targetedConceptDefinitionIds.push(targetType.id);
      }
    }
    const options = targetedConceptDefinitionIds.flatMap((id) => getModelTypeOptions(store, id));
    return options.sort(compareProperty('label', comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString)));
  } else if (isInstanceOf<RelationSingleFieldStoreObject>(field, RelationSingleField)) {
    const targetedConceptDefinitionIds: string[] = [];
    const { getTargetType } = relationSingleFieldHandler(store, field.id);
    if (getTargetType) {
      const targetType = getTargetType();
      if (targetType) {
        targetedConceptDefinitionIds.push(targetType.id);
      }
    }
    const options = targetedConceptDefinitionIds.flatMap((id) => getModelTypeOptions(store, id));
    return options.sort(compareProperty('label', comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString)));
  } else if (isInstanceOf<AssociationFieldStoreObject>(field, AssociationField)) {
    const targetedConceptDefinitionIds: string[] = [];
    const { getTargetType } = associationFieldHandler(store, field.id);
    if (getTargetType) {
      const targetType = getTargetType();
      if (targetType) {
        targetedConceptDefinitionIds.push(targetType.id);
      }
    }
    const options = targetedConceptDefinitionIds.flatMap((id) => getModelTypeOptions(store, id));
    return options.sort(compareProperty('label', comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString)));
  } else if (isInstanceOf<WorkflowFieldStoreObject>(field, WorkflowField)) {
    const { workflowId } = workflowFieldHandler(store, field.id).resolveConfiguration();
    if (workflowId && store.getObjectOrNull(workflowId)) {
      return store.withAssociation(WorkflowEntry)
        .withRole(WorkflowEntry_Role_Workflow, workflowId)
        .list()
        .sort(compareProperty('object', compareProperty(WorkflowEntry_Rank, compareRank)))
        .map((workflowEntry) => getChipOptions(store, workflowEntry.role(WorkflowEntry_Role_Concept)))
        .filter(filterNullOrUndefined);
    } else {
      return [];
    }
  } else {
    throw newError('Unsupported groupBy field type', { fieldId: field?.id });
  }
};

export const getSwimlaneColumnByField = (
  store: FrontObjectStore,
  conceptDefinitionId: string,
  sessionStorageConfig: { [ConceptDefinition_SwimlaneColumnBy]: string | null | undefined } | undefined,
  viewDefaultColumnByFieldId: string | undefined,
  isInView: boolean
): FieldStoreObject | undefined => {
  let columnByField: FieldStoreObject | undefined;
  if (isInView && sessionStorageConfig?.[ConceptDefinition_SwimlaneColumnBy] === undefined) {
    if (viewDefaultColumnByFieldId) {
      columnByField = store.getObjectOrNull<FieldStoreObject>(viewDefaultColumnByFieldId) ?? undefined;
    }
  } else {
    columnByField = getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_SwimlaneColumnBy, sessionStorageConfig) ?? undefined;
  }
  if (columnByField) {
    return columnByField;
  } else {
    const columnByOptions = listColumnByFieldOptions(store, conceptDefinitionId);
    if (columnByOptions.length === 0) {
      return undefined;
    } else {
      return store.getObject<FieldStoreObject>(columnByOptions[0].id);
    }
  }
};

export const getSwimlaneGroupByField = (
  store: ObjectStoreWithTimeseries,
  conceptDefinitionId: string,
  sessionStorageConfig: { [ConceptDefinition_SwimlaneGroupBy]: string | null | undefined } | undefined,
  viewDefaultGroupByFieldId: string | undefined,
  isInView: boolean
): FieldStoreObject | undefined => {
  if (isInView && sessionStorageConfig?.[ConceptDefinition_SwimlaneGroupBy] === undefined) {
    if (viewDefaultGroupByFieldId) {
      return store.getObjectOrNull<FieldStoreObject>(viewDefaultGroupByFieldId) ?? undefined;
    } else {
      return undefined;
    }
  } else {
    return getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_SwimlaneGroupBy, sessionStorageConfig) ?? undefined;
  }
};

export const getTableGroupByField = (
  store: ObjectStoreWithTimeseries,
  conceptDefinitionId: string,
  sessionStorageConfig: { [ConceptDefinition_TableGroupBy]: string | null | undefined } | undefined
): StoreObject | undefined => (
  getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_TableGroupBy, sessionStorageConfig) ?? undefined
);

export const getTimelineGroupByField = (
  store: ObjectStoreWithTimeseries,
  conceptDefinitionId: string,
  sessionStorageConfig: { [ConceptDefinition_TimelineGroupBy]: string | null | undefined } | undefined,
  viewDefaultTimelineGroupByFieldId: string | undefined,
  isInView: boolean
): FieldStoreObject | undefined => {
  if (isInView && sessionStorageConfig?.[ConceptDefinition_TimelineGroupBy] === undefined) {
    if (viewDefaultTimelineGroupByFieldId) {
      return store.getObjectOrNull<FieldStoreObject>(viewDefaultTimelineGroupByFieldId) ?? undefined;
    } else {
      return undefined;
    }
  } else {
    return getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_TimelineGroupBy, sessionStorageConfig) ?? undefined;
  }
};

export const listTimelineFieldOptions = (store: FrontObjectStore, conceptDefinitionId: string): Chip[] => (
  getFields(store, conceptDefinitionId, [TimelineField, DateField])
    .map((field) => getFieldChip(store, conceptDefinitionId, field.id))
    .sort(defaultOptionComparator)
);

export const getTimelineField = (
  store: FrontObjectStore,
  conceptDefinitionId: string,
  sessionStorageConfig: { [ConceptDefinition_Timeline]: string | null | undefined } | undefined,
  viewDefaultTimelineFieldId: string | undefined,
  isInView: boolean
): FieldStoreObject | undefined => {
  let timelineField;
  if (isInView && sessionStorageConfig?.[ConceptDefinition_Timeline] === undefined) {
    if (viewDefaultTimelineFieldId) {
      timelineField = store.getObjectOrNull<FieldStoreObject>(viewDefaultTimelineFieldId) ?? undefined;
    }
  } else {
    timelineField = getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_Timeline, sessionStorageConfig);
  }
  if (timelineField) {
    return timelineField;
  } else {
    const timelineOptions = listTimelineFieldOptions(store, conceptDefinitionId);
    if (timelineOptions.length === 0) {
      return undefined;
    } else {
      return store.getObject<FieldStoreObject>(timelineOptions[0].id);
    }
  }
};

export const listLabelByFieldOptions = (store: FrontObjectStore, conceptDefinitionId: string): Chip[] => (
  getFields(store, conceptDefinitionId, [TextField, TextConstantField, NumberField, IdField, ExternalKeyField])
    .map((field) => getFieldChip(store, conceptDefinitionId, field.id))
    .sort(defaultOptionComparator)
);

export const listDependenciesFieldOptions = (store: FrontObjectStore, conceptDefinitionId: string): Chip[] => (
  getFields(store, conceptDefinitionId, [AssociationField])
    .filter((field) => field[AssociationField_SourceRole] === 1
      && field.navigate(AssociationField_Definition)[AssociationFieldDefinition_Role1Type] === conceptDefinitionId
      && field.navigate(AssociationField_Definition)[AssociationFieldDefinition_Role2Type] === conceptDefinitionId)
    .map((field) => getFieldChip(store, conceptDefinitionId, field.id))
    .sort(defaultOptionComparator)
);

export const getTimelineDependencyField = (
  store: ObjectStoreWithTimeseries,
  conceptDefinitionId: string,
  sessionStorageConfig: { [ConceptDefinition_TimelineDependency]: string | null | undefined } | undefined,
  viewDefaultTimelineDependencyFieldId: string | undefined,
  isInView = false
): StoreObject | undefined => {
  if (isInView && sessionStorageConfig?.[ConceptDefinition_TimelineDependency] === undefined) {
    if (viewDefaultTimelineDependencyFieldId) {
      return store.getObjectOrNull(viewDefaultTimelineDependencyFieldId) ?? undefined;
    } else {
      return undefined;
    }
  } else {
    return getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_TimelineDependency, sessionStorageConfig) ?? undefined;
  }
};

export const getSearchableConfigFields = (store: FrontObjectStore, conceptDefinitionId: string): string[] => store.withAssociation(SearchFieldDisplay)
  .withRole(SearchFieldDisplay_Role_ConceptDefinition, conceptDefinitionId)
  .list()
  .map((assoc) => (assoc.role(SearchFieldDisplay_Role_Field)))
  .filter((fieldId) => store.getObjectOrNull(fieldId) !== null)
  .map((fieldId) => fieldId);

// Get all field used for search (configured one or all text and external key)
export const getSearchableFields = (store: FrontObjectStore, conceptDefinitionId: string): string[] => {
  const searchableConfigFields = getSearchableConfigFields(store, conceptDefinitionId);
  if (searchableConfigFields.length > 0) {
    return searchableConfigFields;
  } else {
    return getConceptDefinitionValidFields(store, conceptDefinitionId)
      .filter((field) => (
        isInstanceOf<TextFieldStoreObject>(field, TextField)
        || isInstanceOf<ExternalKeyFieldStoreObject>(field, ExternalKeyField)
        || isInstanceOf<IdFieldStoreObject>(field, IdField)
      ))
      .map((field) => field.id);
  }
};

export const getSearchChipOptions = (store: FrontObjectStore, modelTypeId: string): {
  searchKeys: string[], extractValue: (option: Option & { object?: StoreObject }, searchKey: string) => (string | undefined),
} => ({
  searchKeys: getSearchableFields(store, modelTypeId),
  extractValue: ({ object }, searchKey) => {
    if (!object) {
      return undefined;
    } else if (store.getObjectOrNull(searchKey)?.navigateOrNull(Instance_Of)?.[Instance_Of] === FieldDefinition) {
      return displayInstanceFieldAsText(store, object.id, searchKey) ?? (object[searchKey] ? `${object[searchKey]}` : undefined);
    } else {
      return (object[searchKey] ? `${object[searchKey]}` : '');
    }
  },
});
