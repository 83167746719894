import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { FunctionComponent, KeyboardEventHandler, ReactNode } from 'react';
import makeStyles from '../../utils/makeStyles';
import { scrollMarginTop } from '../../utils/useAnchorScroll';
import useDeepMemo from '../../utils/useDeepMemo';
import useHighlight, { HighlightVariant } from '../../utils/useHighlight';
import { tableSelectorsClasses } from './Table';

const useStyles = makeStyles({
  scroll: {
    scrollMarginTop,
  },
  clickable: {
    cursor: 'pointer',
  },
  base: {
    outline: 0,
    height: '3.8rem',
    '&:hover': {
      [`& .${tableSelectorsClasses.navigationButton}`]: {
        visibility: 'visible',
      },
    },
  },
}, 'tableLine');

interface TableLineProps {
  onClick?: () => void,
  onKeyDown?: KeyboardEventHandler<HTMLTableRowElement>,
  height?: string,
  noTabIndex?: boolean,
  context?: string[],
  highlightContext?: string[],
  children: ReactNode,
}

const TableLine: FunctionComponent<TableLineProps> = ({ onClick, onKeyDown, height, noTabIndex = false, context, highlightContext, children }) => {
  const classes = useStyles();

  const chipContextMemo = useDeepMemo(() => context, [context]);
  const highlightRef = useHighlight<HTMLTableRowElement>(chipContextMemo);
  const highlightContextRef = useHighlight<HTMLTableRowElement>(highlightContext, { highlight: true, fade: false, blink: false, highlightVariant: HighlightVariant.tableLine });

  return (
    <tr
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={noTabIndex ? undefined : 0}
      className={classnames({
        [classes.scroll]: true,
        [classes.base]: true,
        [classes.clickable]: onClick,
      })}
      style={{ height }}
      ref={composeReactRefs<HTMLTableRowElement>(context ? highlightRef : undefined, highlightContextRef)}
    >
      {children}
    </tr>
  );
};

export default TableLine;
