import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import makeStyles from '../../utils/makeStyles';

export enum CroppedDirection {
  right = 'right',
  left = 'left',
}

const useStyles = makeStyles((theme) => ({
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  croppedRight: {
    position: 'absolute',
    right: '0.2rem',
  },
  withoutLabelCroppedLeft: {
    position: 'absolute',
  },
  indicatorDefault: {
    height: '3rem',
  },
  childIndicator: {
    height: '2.4rem',
  },
  secondIndicator: {
    width: '0.5rem',
    borderRightWidth: '0.1rem',
    borderRightStyle: 'solid',
    borderRightColor: theme.color.border.default,
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.border.default,
  },
  leftIndicator:
    {
      width: '0.5rem',
      borderRightWidth: '0.1rem',
      borderRightStyle: 'solid',
      borderRightColor: theme.color.border.default,
      borderLeftWidth: '0.2rem',
      borderLeftStyle: 'solid',
      borderLeftColor: theme.color.border.default,
    },
  rightIndicator: {
    width: '0.5rem',
    borderRightWidth: '0.2rem',
    borderRightStyle: 'solid',
    borderRightColor: theme.color.border.default,
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.border.default,
  },
  leftOnlyIndicator: {
    width: '0.1rem',
    borderLeftWidth: '0.2rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.border.default,
  },
  rightOnlyIndicator: {
    width: '0.1rem',
    marginRight: '0.3rem',
    borderRightWidth: '0.2rem',
    borderRightStyle: 'solid',
    borderRightColor: theme.color.border.default,
  },
}), 'timelineEntryCroppedIndicators');

interface TimelineEntryCroppedIndicatorsProps {
  rectSize: number,
  cardWidthInPx: number,
  withLabel?: boolean,
  croppedDirection: CroppedDirection,
  isChild?: boolean,
}

const TimelineEntryCroppedIndicators: FunctionComponent<TimelineEntryCroppedIndicatorsProps> = ({
  rectSize,
  cardWidthInPx,
  withLabel = false,
  croppedDirection,
  isChild = false,
}) => {
  const classes = useStyles();

  return (
    <>
      {(croppedDirection === CroppedDirection.left) && (
        <div
          className={classnames({
            [classes.flexRow]: true,
            [classes.withoutLabelCroppedLeft]: !withLabel,
          })}
        >
          {cardWidthInPx > rectSize && (
            <>
              <div
                className={classnames({
                  [classes.leftIndicator]: true,
                  [classes.childIndicator]: isChild,
                  [classes.indicatorDefault]: true,
                })}
              />
              <div
                className={classnames({
                  [classes.secondIndicator]: true,
                  [classes.childIndicator]: isChild,
                  [classes.indicatorDefault]: true,
                })}
              />
            </>
          )}
          {cardWidthInPx <= rectSize && (
            <div
              className={classnames({
                [classes.leftOnlyIndicator]: true,
                [classes.childIndicator]: isChild,
                [classes.indicatorDefault]: true,
              })}
            />
          )}
        </div>
      )}
      {(croppedDirection === CroppedDirection.right) && (
        <div
          className={classnames(
            classes.flexRow,
            classes.croppedRight
          )}
        >
          {cardWidthInPx > rectSize && (
            <>
              <div
                className={classnames({
                  [classes.secondIndicator]: true,
                  [classes.childIndicator]: isChild,
                  [classes.indicatorDefault]: true,
                })}
              />
              <div
                className={classnames({
                  [classes.rightIndicator]: true,
                  [classes.childIndicator]: isChild,
                  [classes.indicatorDefault]: true,
                })}
              />
            </>
          )}
          {cardWidthInPx <= rectSize && (
            <div
              className={classnames({
                [classes.rightOnlyIndicator]: true,
                [classes.childIndicator]: isChild,
                [classes.indicatorDefault]: true,
              })}
            />
          )}
        </div>
      )}
    </>
  );
};

export default TimelineEntryCroppedIndicators;
