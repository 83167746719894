import type { FunctionComponent } from 'react';
import { useEffect } from 'react';
import type { PlatformConfigurationStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import { CurrentPlatformConfiguration, PlatformConfiguration_AutoLogoutTime } from 'yooi-modules/modules/platformConfigurationModule/ids';
import useStore from '../store/useStore';
import { createBroadcastChannel } from '../utils/broadcastChannelUtils';
import { doFetch } from '../utils/fetchUtils';

const CLIENT_ACTIVITY_EVENTS = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

const createUserActivityPostBroadcastChannel = () => createBroadcastChannel<undefined>(new BroadcastChannel('user_activity-v1'));
const createUserActivityListenerBroadcastChannel = () => createBroadcastChannel<undefined>(new BroadcastChannel('user_activity-v1'));

const AutoLogout: FunctionComponent = () => {
  const store = useStore();
  const logoutTimeoutInMin = store.getObject<PlatformConfigurationStoreObject>(CurrentPlatformConfiguration)[PlatformConfiguration_AutoLogoutTime];
  const logoutTimeout = logoutTimeoutInMin ? logoutTimeoutInMin * 60 * 1_000 : undefined;

  let timer: ReturnType<typeof setTimeout> | undefined;

  const resetLogoutTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      doFetch('/logout');
    }, logoutTimeout);
  };

  useEffect(() => {
    if (logoutTimeout) {
      const userActivityPostBroadcastChannel = createUserActivityPostBroadcastChannel();
      let batchTimeout: ReturnType<typeof setTimeout> | undefined;
      const activityListener = () => {
        if (!batchTimeout) {
          CLIENT_ACTIVITY_EVENTS.forEach((event) => window.removeEventListener(event, activityListener));
          batchTimeout = setTimeout(() => {
            userActivityPostBroadcastChannel.postMessage();
            batchTimeout = undefined;
            CLIENT_ACTIVITY_EVENTS.forEach((event) => window.addEventListener(event, activityListener));
          }, 60_000);
        }
      };
      CLIENT_ACTIVITY_EVENTS.forEach((event) => window.addEventListener(event, activityListener));

      const userActivityListenerBroadcastChannel = createUserActivityListenerBroadcastChannel();
      userActivityListenerBroadcastChannel.registerMessageHandler(() => {
        resetLogoutTimer();
      });

      resetLogoutTimer();

      return () => {
        userActivityPostBroadcastChannel.close();
        userActivityListenerBroadcastChannel.close();
        CLIENT_ACTIVITY_EVENTS.forEach((event) => window.removeEventListener(event, activityListener));
        if (timer) {
          clearTimeout(timer);
        }
        if (batchTimeout) {
          clearTimeout(batchTimeout);
        }
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoutTimeout]);

  return null;
};

export default AutoLogout;
