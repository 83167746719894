import type { ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import { isStoreObject } from 'yooi-store';
import { filterNullOrUndefined, newError } from 'yooi-utils';
import type { GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import { Concept_Resource } from '../../conceptModule/ids';
import type { ConceptReference, DimensionsMapping, ResolutionStack } from '../../conceptModule/utils';
import {
  conceptRefApiSchema,
  isConceptValid,
  isValueResolutionOfType,
  ParsedDimensionType,
  parseDimensionMapping,
  resolveFieldValue,
  toConceptReference,
} from '../../conceptModule/utils';
import { getResourcesToDataAssetsEmbeddingFieldInstanceId } from '../../resourceModule';
import { registerField } from '../module';
import type { DataAssetHierarchicalUsageField } from './types';

const getValueResolution = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack) => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
  if (isValueResolutionOfType(valueResolution, (value): value is StoreObject[] => Array.isArray(value) && value.every(isStoreObject))) {
    return valueResolution;
  } else {
    return {
      value: [],
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['StoreObject[]'], typeof valueResolution.value),
      getDisplayValue: () => [],
      isTimeseries: false,
    };
  }
};

type DataAssetFieldHandler = GetDslFieldHandler<
  DataAssetHierarchicalUsageField,
  StoreObject[],
  undefined,
  StoreObject[],
  StoreObject[],
  ConceptReference[],
  undefined,
  undefined,
  undefined,
  undefined
>;

export const dataAssetHierarchicalUsageFieldHandler: DataAssetFieldHandler = registerField({
  model: { label: 'DataAssetHierarchicalUsageField', title: 'Hierarchical usages', withApiAlias: true },
  handler: (objectStore, fieldId) => ({
    describe: () => ({ hasData: false }),
    restApi: {
      returnTypeSchema: { type: 'array', items: conceptRefApiSchema },
      formatValue: (value) => (value ?? []).map(toConceptReference).filter(filterNullOrUndefined),
    },
    getStoreValue: (dimensionsMapping) => {
      const parsedDimension = parseDimensionMapping(dimensionsMapping);
      if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
        return objectStore
          .getObject(parsedDimension.objectId)
          .navigateBack(getResourcesToDataAssetsEmbeddingFieldInstanceId(objectStore))
          .flatMap((resource) => resource.navigateBack(Concept_Resource))
          .filter((reference) => isConceptValid(objectStore, reference.id));
      } else {
        return [];
      }
    },
    getValueWithoutFormula: (dimensionsMapping) => {
      const parsedDimension = parseDimensionMapping(dimensionsMapping);
      if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
        return objectStore
          .getObject(parsedDimension.objectId)
          .navigateBack(getResourcesToDataAssetsEmbeddingFieldInstanceId(objectStore))
          .flatMap((resource) => resource.navigateBack(Concept_Resource))
          .filter((reference) => isConceptValid(objectStore, reference.id));
      } else {
        return [];
      }
    },
    getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
    updateValue: () => {
      throw newError('updateValue not supported');
    },
    resolvePathStepConfiguration: () => ({ hasData: false }),
    isEmpty: (dimensionsMapping) => !getValueResolution(objectStore, fieldId, dimensionsMapping).value?.length,
    isSaneValue: () => ({ isValid: true }),
  }),
});
