import type { FunctionComponent } from 'react';
import {
  addTimeToDate,
  dateFormats,
  DurationType,
  formatDisplayDate,
  getDateFromString,
  getIsoWeekYear,
  getWeekInYear,
  getWeekNumber,
  isFiniteNumber,
  periodicities,
  PeriodicityType,
} from 'yooi-utils';
import InCompositeInput from '../../../app/_global/input/InCompositeInput';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useDerivedState from '../../../utils/useDerivedState';
import useSizeContext from '../../../utils/useSizeContext';
import SearchAndSelect from '../../molecules/SearchAndSelect';
import NumberPicker from '../NumberPicker';
import { DatePickerStrategy } from './datePickerUtils';

const useStyles = makeStyles({
  inputSpacings: {
    display: 'grid',
    gridAutoFlow: 'column',
    columnGap: spacingRem.xs,
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
  },
}, 'weekPicker');

interface WeekPickerProps {
  value: Date | undefined,
  onSubmit: (date: Date | undefined) => void,
  strategy?: DatePickerStrategy,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
}

const WeekPicker: FunctionComponent<WeekPickerProps> = ({ value, onSubmit, strategy = DatePickerStrategy.startOf, onEditionStart, onEditionStop }) => {
  const classes = useStyles();
  const { sizeVariant } = useSizeContext();

  const valueWeek = value ? getWeekNumber(value) : undefined;
  const valueYear = getIsoWeekYear(value ?? new Date());

  const [week, setWeek, resetWeek] = useDerivedState(() => (valueWeek), [valueWeek]);
  const [year, setYear, resetYear] = useDerivedState<number | undefined>(() => (valueYear), [valueYear]);

  const submitInvalidDate = () => {
    onSubmit(undefined);
  };

  const submitDate = ({ w, y }: { w: number | undefined, y: number | undefined }) => {
    if (w != null && y != null) {
      resetWeek();
      resetYear();
      const submittedDate = getDateFromString(`${y} ${w}`, 'kk W');
      if (strategy === DatePickerStrategy.endOf) {
        const tmp = periodicities.week.getEndOfPeriod(submittedDate);
        onSubmit(tmp);
      } else {
        const tmp = periodicities.week.getStartOfPeriod(submittedDate);
        onSubmit(tmp);
      }
    }
  };

  const options = year != null ? Array
    .from({ length: getWeekInYear(new Date(valueYear, 2)) }, (_, i) => i)
    .map((id) => {
      const date = getDateFromString(`${valueYear} 1`, 'kk W');
      const d = periodicities[PeriodicityType.week].getStartOfPeriod(addTimeToDate(date, id, DurationType.weeks));
      return ({ id, label: `${formatDisplayDate(d, dateFormats.week)} (${formatDisplayDate(d, dateFormats.dayNumberAndMonth)})` });
    }) : Array.from({ length: 52 }, (_, i) => i + 1).map((id) => ({ id, label: `W${id}` }));

  const computeSelectSize = () => {
    if (sizeVariant === 'main') {
      return { width: '21.5rem' };
    } else {
      return { width: '16.5rem' };
    }
  };

  return (
    <div
      className={classes.inputSpacings}
    >
      <div style={computeSelectSize()}>
        <SearchAndSelect
          placeholder={i18n`Week`}
          clearable
          onSelect={(w) => {
            const weekToSubmit = w ? w.id + 1 : undefined;
            if (weekToSubmit == null) {
              setWeek(undefined);
              submitInvalidDate();
            } else {
              setWeek(weekToSubmit);
              submitDate({ w: weekToSubmit, y: year });
            }
          }}
          computeOptions={() => options}
          selectedOption={week != null ? options[week - 1] : undefined}
          onEditionStart={onEditionStart}
          onEditionStop={onEditionStop}
        />
      </div>
      <InCompositeInput
        initialValue={year}
        setInputValue={(yearToSubmit) => {
          if (yearToSubmit == null || yearToSubmit > 3000 || yearToSubmit < 1) {
            setYear(undefined);
            submitInvalidDate();
          } else {
            setYear(yearToSubmit);
            submitDate({ w: week, y: yearToSubmit });
          }
        }}
      >
        {({ value: innerValue, onChange, ...props }) => (
          <NumberPicker
            min={1}
            max={3000}
            placeholder={i18n`yyyy`}
            withFormatting={false}
            onEditionStart={onEditionStart}
            onEditionStop={onEditionStop}
            value={innerValue}
            onChange={(v) => {
              if (isFiniteNumber(v) || v === undefined) {
                onChange(typeof v === 'string' ? parseInt(v, 10) : v);
              }
            }}
            {...props}
          />
        )}
      </InCompositeInput>
    </div>
  );
};

export default WeekPicker;
