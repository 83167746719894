import { equals } from 'ramda';
import type { BusinessRuleHandler, ObjectStoreReadOnly, ObjectStoreWithTimeseries } from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import type { DateRange, StoredDateObject } from 'yooi-utils';
import {
  compareRank,
  computeEffectiveRangeForPeriodAndDates,
  computeEffectiveValueFrom,
  DateStorageTypeKeys,
  filterNullOrUndefined,
  getFormattedTextDateByPeriod,
  isEffectiveDateIncluded,
  newError,
  PeriodicityType,
  ranker,
} from 'yooi-utils';
import { asImport, CommonAsType } from '../../common/fields/commonPropertyType';
import type { GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import type { BusinessRuleRegistration } from '../../common/types/TypeModuleDslType';
import { Class_Instances, Instance_Of } from '../../typeModule/ids';
import { preventComputedFieldUpdate, validateIntegrationOnlyPropertyUpdate } from '../common/commonFieldUtils';
import { validateTimelineFieldValue } from '../common/dateFieldUtils';
import { TimelineField_Period, TimelineField_Rank } from '../ids';
import { registerField } from '../module';
import type { FilterValueRaw, PathStep } from '../moduleType';
import { FilterValueType } from '../moduleType';
import type { DimensionsMapping, FieldFilterCondition, FieldFilterConditions, ResolutionStack } from '../utils';
import {
  adminOnlyAcl,
  buildDimensionalId,
  createValuePathResolver,
  dimensionsMappingToParametersMapping,
  isFilterValueRaw,
  isSingleValueResolution,
  isValueResolutionOfType,
  ParsedDimensionType,
  parseDimensionMapping,
  resolveFieldValue,
} from '../utils';
import { dateRangeType } from '../utils/formula/dateFunctions';
import type { TimelineField } from './types';

const checkTimelineFieldCreation: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  const object = getObjectOrNull(id);
  if (!object && properties) {
    if (!properties[TimelineField_Rank]) {
      return {
        rule: 'timelineField.mandatoryField.missing.rank',
        status: ValidationStatus.REJECTED,
      };
    } else if (!properties[TimelineField_Period]) {
      return {
        rule: 'timelineField.mandatoryField.missing.period',
        status: ValidationStatus.REJECTED,
      };
    } else {
      return {
        rule: 'timelineField.mandatoryField.present',
        status: ValidationStatus.ACCEPTED,
      };
    }
  }
  return undefined;
};

const preventTimelineFieldFieldReset = (fieldId: string, fieldName: string): BusinessRuleRegistration => () => (_, { properties }) => {
  if (properties && properties[fieldId] === null) {
    return {
      rule: `timelineField.mandatoryField.reset.${fieldName}`,
      status: ValidationStatus.REJECTED,
    };
  }
  return undefined;
};

const checkTimelineValue = (propertyId: string): BusinessRuleHandler => (_, { properties }) => {
  if (!properties) {
    return undefined;
  } else if (properties[propertyId] === null) {
    return { rule: 'field.timeline.allowClear', status: ValidationStatus.ACCEPTED };
  } else if (validateTimelineFieldValue(properties[propertyId])) {
    return { rule: 'field.timeline.valid', status: ValidationStatus.ACCEPTED };
  } else {
    return { rule: 'field.timeline.invalidPropValues', status: ValidationStatus.REJECTED };
  }
};

const assignDefaultRank = (objectStore: ObjectStoreReadOnly, propertyId: string): BusinessRuleHandler => (_, { id, properties }) => {
  if (!properties || properties[propertyId] === null) {
    return undefined;
  } else {
    const timelineField = objectStore.getObject(propertyId).navigateBack(TimelineField_Period)[0];
    const concept = objectStore.getObjectOrNull(id);

    if (concept?.[timelineField[TimelineField_Rank] as string] !== undefined) {
      return undefined;
    }

    const conceptDefinitionId = concept?.[Instance_Of] as string | undefined ?? properties[Instance_Of] as string | undefined;
    if (!conceptDefinitionId) {
      return undefined;
    }

    return {
      rule: 'timelineField.nextRank',
      status: ValidationStatus.ACCEPTED,
      generateSystemEvent: ({ updateObject }) => {
        const lastRank = objectStore.getObject(conceptDefinitionId)
          .navigateBack(Class_Instances)
          .map((c) => c[timelineField[TimelineField_Rank] as string] as string | undefined)
          .filter(filterNullOrUndefined)
          .sort(compareRank)
          .at(-1);

        const nextRank = ranker.createNextRankGenerator(lastRank)();

        updateObject(id, { [timelineField[TimelineField_Rank] as string]: nextRank });
      },
    };
  }
};

const getValueResolution = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack) => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
  if (isValueResolutionOfType(valueResolution, (value): value is DateRange | undefined => value === undefined || validateTimelineFieldValue(value))) {
    return valueResolution;
  } else {
    return {
      value: undefined,
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['DateRange', 'undefined'], typeof valueResolution.value),
      getDisplayValue: () => undefined,
      isTimeseries: valueResolution.isTimeseries,
    };
  }
};

const sanitizeDateRangeValue = (value: unknown) => (value ?? { type: FilterValueType.raw, raw: undefined }) as FilterValueRaw<DateRange>;
const isDateRangeFilterApplicable = (value: FilterValueRaw<DateRange>) => value !== undefined && value !== null && isFilterValueRaw<DateRange>(value) && Boolean(value.raw);
const isTimelineElementIncluded = (
  filterPeriodicity: string | undefined,
  filterStart: StoredDateObject | number | undefined,
  filterEnd: StoredDateObject | number | undefined
) => (element: DateRange | undefined) => {
  const {
    from: elementStart,
    to: elementEnd,
    error: elementError,
  } = computeEffectiveRangeForPeriodAndDates(element?.period, element?.from, element?.to);
  return isEffectiveDateIncluded(filterPeriodicity, filterStart, filterEnd, elementStart, elementEnd, elementError);
};

interface TimelineFieldFilterConditions extends FieldFilterConditions<DateRange | undefined> {
  EQUALS: FieldFilterCondition<DateRange, DateRange | undefined>,
  NOT_EQUALS: FieldFilterCondition<DateRange, DateRange | undefined>,
  INTERSECT: FieldFilterCondition<DateRange, DateRange | undefined>,
  IS_EMPTY: FieldFilterCondition<undefined, DateRange | undefined>,
  IS_NOT_EMPTY: FieldFilterCondition<undefined, DateRange | undefined>,
}

interface TimelineUpdateConfiguration {
  INITIALIZE: { type: 'value', value: DateRange | undefined } | { type: 'path', path: PathStep[] },
  REPLACE: { type: 'value', value: DateRange | undefined } | { type: 'path', path: PathStep[] },
  CLEAR: undefined,
}

type TimelineFieldHandler = GetDslFieldHandler<
  TimelineField,
  DateRange | undefined,
  DateRange | null,
  DateRange | undefined,
  DateRange | undefined,
  DateRange | undefined,
  TimelineFieldFilterConditions,
  TimelineUpdateConfiguration,
  undefined,
  { isStart: boolean }
>;
export const timelineFieldHandler: TimelineFieldHandler = registerField({
  model: {
    label: 'TimelineField',
    title: 'Date Range',
    withApiAlias: true,
    properties: [
      { label: 'Rank', as: CommonAsType.string, businessRules: [preventTimelineFieldFieldReset(TimelineField_Rank, 'rank')] },
      { label: 'Period', as: CommonAsType.string, businessRules: [preventTimelineFieldFieldReset(TimelineField_Period, 'period')] },
      { label: 'DefaultPeriod', as: CommonAsType.PeriodicityType },
      { label: 'StartConstraint', as: asImport('PathStep', 'modules/conceptModule', true) },
    ],
    businessRules: [checkTimelineFieldCreation],
    asPropertyBusinessRules: [
      validateIntegrationOnlyPropertyUpdate('timelineField'),
      preventComputedFieldUpdate('timelineField'),
    ],
    extraModel: ({ registerCustomBusinessRules, registerCustomAccessControlList }) => {
      registerCustomBusinessRules((objectStore, { onObject, onProperty }) => {
        onObject(TimelineField_Period).register(([propertyId]) => onProperty(propertyId).validate(checkTimelineValue(propertyId)));
        onObject(TimelineField_Period).register(([propertyId]) => onProperty(propertyId).validate(assignDefaultRank(objectStore, propertyId)));
        onObject(TimelineField_Rank).register(([propertyId]) => onProperty(propertyId).validate(() => ({ rule: 'field.timeline.rank.accept', status: ValidationStatus.ACCEPTED })));
      });

      registerCustomAccessControlList((objectStore, { onObject }) => {
        onObject(TimelineField_Rank).allow('READ', (_, objectId) => {
          const timelineFieldRank = objectStore.getObject(objectId);
          const timelineField = timelineFieldRank.navigateBack(TimelineField_Rank)[0];
          if (timelineField) {
            return { rule: 'timelineField.rank.instance.read', status: ValidationStatus.DELEGATED, targetId: [timelineField.id], targetAction: 'READ' };
          } else {
            return { rule: 'timelineField.rank.instance.missing', status: ValidationStatus.REJECTED };
          }
        });
        onObject(TimelineField_Period).allow('READ', (_, objectId) => {
          const timelineFieldPeriod = objectStore.getObject(objectId);
          const timelineField = timelineFieldPeriod.navigateBack(TimelineField_Period)[0];
          if (timelineField) {
            return { rule: 'timelineField.period.instance.read', status: ValidationStatus.DELEGATED, targetId: [timelineField.id], targetAction: 'READ' };
          } else {
            return { rule: 'timelineField.period.instance.missing', status: ValidationStatus.REJECTED };
          }
        });

        onObject(TimelineField_Rank).allow('WRITE', ({ userId }) => adminOnlyAcl(objectStore, userId, 'timelineField.rank', 'WRITE'));
        onObject(TimelineField_Period).allow('WRITE', ({ userId }) => adminOnlyAcl(objectStore, userId, 'timelineField.period', 'WRITE'));
      });
    },
  },
  handler: (objectStore, fieldId, { resolveConfiguration }) => {
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => {
      const { period, from, to } = getValueResolution(objectStore, fieldId, dimensionsMapping).value ?? {};
      const { from: effectiveFrom, to: effectiveTo, error } = computeEffectiveRangeForPeriodAndDates(period ?? PeriodicityType.day, from, to);
      if (error) {
        return undefined;
      } else if (effectiveFrom || effectiveTo) {
        return `${from?.value && effectiveFrom ? `${getFormattedTextDateByPeriod(effectiveFrom, period ?? PeriodicityType.day)} ` : ''}-${to?.value && effectiveTo ? ` ${getFormattedTextDateByPeriod(effectiveTo, period ?? PeriodicityType.day)}` : ''}`;
      } else {
        return undefined;
      }
    };

    const getStoreValue = (dimensionsMapping: DimensionsMapping) => {
      const configuration = resolveConfiguration();
      if (!configuration.period) {
        throw newError('Invalid timeline field', { fieldId });
      }

      const parsedDimensions = parseDimensionMapping(dimensionsMapping);
      if (parsedDimensions.type === ParsedDimensionType.MonoDimensional) {
        return objectStore.getObject(parsedDimensions.objectId)[configuration.period] as DateRange | undefined;
      } else {
        return objectStore.getObject(buildDimensionalId(dimensionsMapping), true)[configuration.period] as DateRange | undefined;
      }
    };

    return {
      describe: () => ({ hasData: true, returnType: dateRangeType, timeseriesMode: 'none' }),
      resolvePathStepConfiguration: () => ({
        hasData: true,
        timeseriesMode: 'none',
        getValueResolutionType: () => dateRangeType,
        resolveValue: (dimensionsMappings, _, resolutionStack) => {
          const { error, value } = getValueResolution(objectStore, fieldId, dimensionsMappings, resolutionStack);
          if (error) {
            throw error;
          } else {
            return value;
          }
        },
      }),
      restApi: {
        returnTypeSchema: {
          type: 'object',
          properties: {
            period: { type: 'string', enum: Object.values(PeriodicityType), nullable: true },
            from: {
              type: 'object',
              properties: {
                objects: { type: 'string', enum: Object.values(DateStorageTypeKeys), nullable: true },
                value: { type: 'number', nullable: true },
              },
            },
            to: {
              type: 'object',
              properties: {
                objects: { type: 'string', enum: Object.values(DateStorageTypeKeys), nullable: true },
                value: { type: 'number', nullable: true },
              },
            },
          },
        },
        formatValue: (value) => value ?? undefined,
      },
      getStoreValue,
      getValueWithoutFormula: (dimensionsMapping, resolutionStack) => {
        const configuredValue = getStoreValue(dimensionsMapping);
        if (!configuredValue) {
          return undefined;
        }

        const configuration = resolveConfiguration();
        const value = {
          period: configuredValue?.period ?? configuration.defaultPeriod ?? PeriodicityType.day,
          from: configuredValue?.from ?? { type: configuration.startConstraint ? DateStorageTypeKeys.constraint : DateStorageTypeKeys.date },
          to: configuredValue?.to,
        };

        if (value.from.type === DateStorageTypeKeys.constraint && configuration.startConstraint) {
          const valuePathResolver = createValuePathResolver(objectStore, dimensionsMappingToParametersMapping(dimensionsMapping));
          const resolution = valuePathResolver.resolvePathValue(configuration.startConstraint, resolutionStack);
          if (!isSingleValueResolution(resolution)) {
            return undefined;
          } else if (typeof resolution.value === 'number') {
            return {
              period: value.period,
              from: computeEffectiveValueFrom(value, resolution.value, value.period),
              to: value.to,
            };
          } else if (resolution.value && typeof resolution.value === 'object' && typeof (resolution.value as Record<string, unknown>).date === 'number') {
            return {
              period: value.period,
              from: computeEffectiveValueFrom(value, (resolution.value as { date: number }).date, value.period),
              to: value.to,
            };
          }
        }

        return value;
      },
      getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
      updateValue: (dimensionsMapping, value) => {
        const configuration = resolveConfiguration();
        if (!configuration.period) {
          throw newError('Invalid timeline field', { fieldId });
        }

        const parsedDimensions = parseDimensionMapping(dimensionsMapping);
        if (parsedDimensions.type === ParsedDimensionType.MonoDimensional) {
          objectStore.updateObject(parsedDimensions.objectId, { [configuration.period]: value });
        } else {
          objectStore.updateObject(buildDimensionalId(dimensionsMapping), { [configuration.period]: value });
        }
      },
      isEmpty: (dimensionsMapping) => {
        const { value } = getValueResolution(objectStore, fieldId, dimensionsMapping);
        return !value || (!value.from && !value.to);
      },
      isSaneValue: () => ({ isValid: true }),
      updateOperationHandlers: {
        INITIALIZE: {
          applyOperation: (dimensionsMapping, parameters, value) => {
            const fieldHandler = timelineFieldHandler(objectStore, fieldId);
            if (fieldHandler.getStoreValue(dimensionsMapping) !== undefined) {
              return;
            }
            if (value.type === 'value' && value.value) {
              fieldHandler.updateValue(dimensionsMapping, value.value);
            } else if (value.type === 'path') {
              const pathValueResolution = createValuePathResolver(objectStore, parameters).resolvePathValue(value.path);
              if (isSingleValueResolution(pathValueResolution) && pathValueResolution.value) {
                // TODO check value is DateRange
                fieldHandler.updateValue(dimensionsMapping, pathValueResolution.value as DateRange);
              }
            }
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: undefined },
        },
        REPLACE: {
          applyOperation: (dimensionsMapping, parameters, value) => {
            const fieldHandler = timelineFieldHandler(objectStore, fieldId);
            if (value.type === 'value' && value.value) {
              fieldHandler.updateValue(dimensionsMapping, value.value);
            } else if (value.type === 'path') {
              const pathValueResolution = createValuePathResolver(objectStore, parameters).resolvePathValue(value.path);
              if (isSingleValueResolution(pathValueResolution) && pathValueResolution.value) {
                // TODO check value is DateRange
                fieldHandler.updateValue(dimensionsMapping, pathValueResolution.value as DateRange);
              }
            }
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: undefined },
        },
        CLEAR: {
          applyOperation: (dimensionsMapping) => {
            const fieldHandler = timelineFieldHandler(objectStore, fieldId);
            fieldHandler.updateValue(dimensionsMapping, null);
          },
          sanitizeOperation: () => undefined,
        },
      },
      getValueAsText,
      getExportColumnHeaders: (_, fieldLabel) => ({
        columnsNumber: 2,
        getHeaders: (index) => [{ format: 'string', value: fieldLabel }, { format: 'string', value: index === 0 ? 'Start' : 'End' }],
        getColumnConfiguration: (index) => ({ isStart: index === 0 }),
      }),
      getExportValue: (dimensionsMapping, configuration) => {
        const { period, from, to } = getValueResolution(objectStore, fieldId, dimensionsMapping).value ?? {};
        const { from: effectiveFrom, to: effectiveTo, error } = computeEffectiveRangeForPeriodAndDates(period ?? PeriodicityType.day, from, to);
        if (error) {
          return {
            format: 'date',
            value: undefined,
            period: undefined,
          };
        }
        return {
          format: 'date',
          value: configuration?.isStart ? effectiveFrom?.valueOf() : effectiveTo?.valueOf(),
          period,
        };
      },
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else {
            return undefined;
          }
        },
      }),
      filterConditions: {
        EQUALS: {
          sanitizeValue: sanitizeDateRangeValue,
          isFilterApplicable: isDateRangeFilterApplicable,
          filterFunction: (leftValue, rightValue) => (
            rightValue.period === leftValue?.period && rightValue.from?.value === leftValue?.from?.value && leftValue?.to?.value === rightValue.to?.value
          ),
        },
        NOT_EQUALS: {
          sanitizeValue: sanitizeDateRangeValue,
          isFilterApplicable: isDateRangeFilterApplicable,
          filterFunction: (leftValue, rightValue) => (
            rightValue.period !== leftValue?.period || rightValue.from?.value !== leftValue?.from?.value || leftValue?.to?.value !== rightValue.to?.value
          ),
        },
        INTERSECT: {
          sanitizeValue: sanitizeDateRangeValue,
          isFilterApplicable: isDateRangeFilterApplicable,
          filterFunction: (leftValue, rightValue) => Boolean(isTimelineElementIncluded(rightValue.period, rightValue.from, rightValue.to)(leftValue)),
        },
        IS_EMPTY: {
          sanitizeValue: () => ({ type: FilterValueType.raw, raw: undefined }),
          isFilterApplicable: () => true,
          filterFunction: (leftValue) => !leftValue || (!leftValue.from && !leftValue.to),
        },
        IS_NOT_EMPTY: {
          sanitizeValue: () => ({ type: FilterValueType.raw, raw: undefined }),
          isFilterApplicable: () => true,
          filterFunction: (leftValue) => Boolean(leftValue && leftValue.from && leftValue.to),
        },
      },
    };
  },
  historyEventProducer: ({ getObjectOrNull, getObject }, fieldId) => ({
    value: {
      collectImpactedInstances: ({ id, properties }) => {
        const field = getObject(fieldId);
        if (
          properties?.[field[TimelineField_Period] as string] !== undefined // A value is in the update for the current field
          || (properties === null && getObjectOrNull(id)?.[field[TimelineField_Period] as string] !== undefined) // Object is deleted and store had a value for the field
        ) {
          return [id];
        } else {
          return [];
        }
      },
      getValue: (id) => {
        const field = getObject(fieldId);
        return ({ value: (getObjectOrNull(id)?.[field[TimelineField_Period] as string] as number | undefined) ?? null, version: 1 });
      },
      areValuesEquals: (value1, value2) => equals(value1?.value, value2?.value),
    },
  }),
});
