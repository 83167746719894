import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { getPathLastFieldInformation } from 'yooi-modules/modules/conceptModule';
import { AssociationField, EmbeddingField, RelationMultipleField, ReverseWorkflowField } from 'yooi-modules/modules/conceptModule/ids';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { getConceptPathConfigurationHandler } from '../common/viewUtils';

export const chipViewSupportedFieldTypeIds: readonly string[] = [
  AssociationField,
  EmbeddingField,
  RelationMultipleField,
  ReverseWorkflowField,
];

export const getChipViewError = (store: FrontObjectStore, viewDimensions: ViewDimension[], parameterDefinitions: SingleParameterDefinition[]): string | undefined => {
  if (viewDimensions.length !== 1) {
    return viewDimensions.length === 0 ? i18n`Invalid data: Missing dimension` : i18n`Invalid data: Only one dimension is authorized for this type of view`;
  } else {
    const dimensionPath = viewDimensions[0].path;
    const pathLastFieldInformation = getPathLastFieldInformation(dimensionPath);
    if (!pathLastFieldInformation) {
      return i18n`Invalid data: Path should target a field`;
    }
    const { pathBeforeField, fieldId } = pathLastFieldInformation;
    const error = getConceptPathConfigurationHandler(store, parameterDefinitions, false, true).getErrors(pathBeforeField);
    const field = store.getObjectOrNull(fieldId);
    if (error) {
      return [i18n`Invalid data: Dimension must target an association or embedded items on a single instance`, ...error].join('\n');
    } else if (!fieldId) {
      return i18n`Invalid data: Ending field does not exist anymore.`;
    } else if (chipViewSupportedFieldTypeIds.some((typeId) => isInstanceOf(field, typeId))) {
      return undefined;
    } else {
      return i18n`Invalid data: Ending field does not return multiple instances.`;
    }
  }
};
