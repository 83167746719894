import { v4 as uuid } from 'uuid';
import { getPathReturnedConceptDefinitionId } from 'yooi-modules/modules/conceptModule';
import type { ViewDimensionProperties, ViewsFieldDefinition } from 'yooi-modules/modules/dashboardModule';
import {
  DimensionDisplayAxis,
  isCardsViewDefinition,
  isChipViewDefinition,
  isMatrixViewDefinition,
  isStructuralBarChartViewDefinition,
  isSwimlaneViewDefinition,
  isTableViewDefinition,
  isTimelineViewDefinition,
} from 'yooi-modules/modules/dashboardModule';
import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { joinObjects } from 'yooi-utils';

export const addDimension = (oldDefinition: ViewsFieldDefinition): ViewsFieldDefinition => {
  const id = uuid();
  return joinObjects(
    oldDefinition,
    {
      data: [
        ...oldDefinition.data,
        { id, display: {}, path: [] },
      ],
    }
  );
};

export const deleteDimension = (oldDefinition: ViewsFieldDefinition, dimensionId: string): ViewsFieldDefinition => {
  const data = oldDefinition.data.filter(({ id }) => id !== dimensionId);
  const switchToMonoData = data.length === 1;

  return {
    data,
    hasFilters: oldDefinition.hasFilters,
    views: oldDefinition.views.map((oldView) => {
      if (isCardsViewDefinition(oldView) || isChipViewDefinition(oldView)) {
        return { ...oldView };
      } else if (isMatrixViewDefinition(oldView)) {
        if (oldDefinition.data[0].id === dimensionId) {
          // in matrix, only the first data is used
          const newView = { ...oldView };
          newView.yAxisFieldId = undefined;
          newView.xAxisFieldId = undefined;
          newView.dependenciesFieldId = undefined;
          newView.labelFieldId = undefined;
          newView.colorFieldId = undefined;
          return newView;
        } else {
          return { ...oldView };
        }
      } else if (isSwimlaneViewDefinition(oldView)) {
        if (oldDefinition.data[0].id === dimensionId) {
          // in matrix, only the first data is used
          const newView = { ...oldView };
          newView.columnBy = { fieldId: undefined, filters: undefined, withEmpty: oldView.columnBy.withEmpty, size: { type: 'auto' } };
          newView.cardColor = undefined;
          newView.cardBody = [];
          newView.cardHeader = [];
          newView.dependencies = [];
          newView.groupBy = [];
          return newView;
        } else {
          return { ...oldView };
        }
      } else if (isTimelineViewDefinition(oldView)) {
        if (oldDefinition.data[0].id === dimensionId) {
          // in matrix, only the first data is used
          const newView = { ...oldView };
          newView.withCustomConfig = switchToMonoData || oldView.withCustomConfig;
          newView.defaultTimelineFieldId = undefined;
          newView.defaultTimelineProgressFieldId = undefined;
          newView.defaultTimelineDependencyFieldId = undefined;
          newView.defaultTimelineGroupByFieldId = undefined;
          newView.defaultTimelineColorFieldId = undefined;
          return newView;
        } else {
          return joinObjects(oldView, { withCustomConfig: switchToMonoData || oldView.withCustomConfig });
        }
      } else if (isStructuralBarChartViewDefinition(oldView)) {
        const newView = { ...oldView };
        newView.xAxis = oldView.xAxis === dimensionId ? undefined : oldView.xAxis;
        newView.dimensionsDisplay = oldView.dimensionsDisplay?.filter((display) => display.id !== dimensionId);
        return newView;
      } else if (isTableViewDefinition(oldView)) {
        const newView = { ...oldView };
        newView.dimensionsDisplay = oldView.dimensionsDisplay?.filter((display) => display.id !== dimensionId);
        newView.withCustomConfig = switchToMonoData || oldView.withCustomConfig;
        return newView;
      } else {
        const newView = { ...oldView };
        newView.dimensionsDisplay = oldView.dimensionsDisplay?.filter((display) => display.id !== dimensionId);
        return newView;
      }
    }),
  };
};

export const updateDimension = (
  store: ObjectStoreWithTimeseries,
  oldDefinition: ViewsFieldDefinition,
  dimensionId: string,
  properties: Partial<ViewDimensionProperties>
): ViewsFieldDefinition => (joinObjects(
  oldDefinition,
  {
    data: oldDefinition.data.map((dimension) => {
      if (dimension.id === dimensionId) {
        return {
          id: dimensionId,
          label: properties.label ?? dimension.label,
          display: properties.display ?? dimension.display,
          path: properties.path ?? dimension.path,
        };
      } else {
        return dimension;
      }
    }),
    views: oldDefinition.views.map((view) => {
      if (isStructuralBarChartViewDefinition(view)) {
        const newView = { ...view };
        newView.xAxis = view.xAxis === dimensionId && view.dimensionsDisplay?.find((dim) => dim.id === dimensionId)?.axis === DimensionDisplayAxis.y ? undefined : view.xAxis;
        return newView;
      } else if (isSwimlaneViewDefinition(view)) {
        // in swimlane only the first data is used
        // we reset only if path returned concept definition is different
        const isFirstData = oldDefinition.data[0].id === dimensionId;
        if (isFirstData) {
          const hasNewConceptDefinition = getPathReturnedConceptDefinitionId(store, oldDefinition.data[0].path)
            !== getPathReturnedConceptDefinitionId(store, properties.path ?? []);
          if (hasNewConceptDefinition) {
            const newView = { ...view };
            newView.columnBy = { fieldId: undefined, filters: undefined, withEmpty: view.columnBy.withEmpty, size: { type: 'auto' } };
            newView.cardColor = undefined;
            newView.cardBody = [];
            newView.cardHeader = [];
            newView.dependencies = [];
            newView.groupBy = [];
            return newView;
          }
        }
      } else if (isMatrixViewDefinition(view)) {
        // in matrix only the first data is used
        // we reset only if path returned concept definition is different
        const isFirstData = oldDefinition.data[0].id === dimensionId;
        if (isFirstData) {
          const hasNewConceptDefinition = getPathReturnedConceptDefinitionId(store, oldDefinition.data[0].path)
            !== getPathReturnedConceptDefinitionId(store, properties.path ?? []);
          if (hasNewConceptDefinition) {
            const newView = { ...view };
            newView.yAxisFieldId = undefined;
            newView.xAxisFieldId = undefined;
            newView.dependenciesFieldId = undefined;
            newView.labelFieldId = undefined;
            newView.colorFieldId = undefined;
            return newView;
          }
        }
      } else if (isTimelineViewDefinition(view)) {
        // in matrix only the first data is used
        // we reset only if path returned concept definition is different
        const isFirstData = oldDefinition.data[0].id === dimensionId;
        if (isFirstData) {
          const hasNewConceptDefinition = getPathReturnedConceptDefinitionId(store, oldDefinition.data[0].path)
            !== getPathReturnedConceptDefinitionId(store, properties.path ?? []);
          if (hasNewConceptDefinition) {
            const newView = { ...view };
            newView.defaultTimelineFieldId = undefined;
            newView.defaultTimelineProgressFieldId = undefined;
            newView.defaultTimelineDependencyFieldId = undefined;
            newView.defaultTimelineGroupByFieldId = undefined;
            newView.defaultTimelineColorFieldId = undefined;
            return newView;
          }
        }
      }
      return view;
    }),
  }
));
