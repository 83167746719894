import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { forgetAsyncPromise, isValidPassword } from 'yooi-utils';
import Button from '../../../components/atoms/Button';
import Typo, { TypoVariant } from '../../../components/atoms/Typo';
import PasswordInput from '../../../components/inputs/PasswordInput';
import ScreenModal from '../../../components/molecules/ScreenModal';
import SpacingLine from '../../../components/molecules/SpacingLine';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useAuth from '../../../store/useAuth';
import i18n, { i18nMap } from '../../../utils/i18n';
import { useFetchJSONFunction } from '../../../utils/useFetchJSON';
import useTheme from '../../../utils/useTheme';

interface ChangePasswordModalProps {
  userId: string,
  onClose: () => void,
}

type FetchPasswd = { status: 200, response: { status: 'success' } } | { status: 400 | 401 | 404, response: { error: string } };

const ChangePasswordModal: FunctionComponent<ChangePasswordModalProps> = ({ userId, onClose }) => {
  const theme = useTheme();

  const { loggedUserId } = useAuth();
  const changeOwnPassword = userId === loggedUserId;

  const [currentPassword, setCurrentPassword] = useState(changeOwnPassword ? '' : undefined);
  const [newPassword, setNewPassword] = useState('');
  const [canValidatePassword, setCanValidatePassword] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [{ value: fetchValue, error, loading }, doFetchJSON] = useFetchJSONFunction<FetchPasswd>(`/platform/user/${userId}/passwd`);

  let errorMessage: string | undefined;
  if (fetchValue && fetchValue.status !== 200 && fetchValue.response.error !== 'BAD_PASSWORD') {
    errorMessage = i18nMap(
      {
        INVALID_REQUEST: () => i18n`Request is not valid.`,
        UNAUTHORIZED: () => i18n`You don't have the permission to perform this action.`,
        USER_NOT_FOUND: () => i18n`User not found.`,
      },
      () => i18n`Unknown error`
    )(fetchValue.response.error);
  } else if (error) {
    errorMessage = i18n`Unknown error`;
  }

  return (
    <ScreenModal
      title={i18n`Change user password`}
      open
      hide={() => onClose()}
      render={() => (
        <VerticalBlock>
          {changeOwnPassword && (
            <HorizontalBlock asBlockContent>
              <BlockTitle title={i18n`Current password`} />
              <BlockContent>
                <PasswordInput
                  name="currentPassword"
                  value={currentPassword}
                  onChange={(value) => setCurrentPassword(value)}
                  onCancel={(value) => setCurrentPassword(value ?? '')}
                  error={fetchValue && fetchValue.status !== 200 && fetchValue.response.error === 'BAD_PASSWORD' ? i18n`Password is incorrect` : undefined}
                  focusOnMount
                  fullWidth
                />
              </BlockContent>
            </HorizontalBlock>
          )}
          <HorizontalBlock asBlockContent>
            <BlockTitle title={i18n`New password`} />
            <BlockContent>
              <PasswordInput
                name="newPassword"
                value={newPassword}
                onChange={(value) => setNewPassword(value)}
                onSubmit={() => setCanValidatePassword(true)}
                onCancel={(value) => setNewPassword(value ?? '')}
                error={canValidatePassword && !isValidPassword(newPassword) ? i18n`Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character` : undefined}
                focusOnMount={!changeOwnPassword}
                fullWidth
              />
            </BlockContent>
          </HorizontalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle title={i18n`Confirm new password`} />
            <BlockContent>
              <PasswordInput
                name="passwordConfirmation"
                value={passwordConfirmation}
                onChange={(value) => setPasswordConfirmation(value)}
                onCancel={(value) => setPasswordConfirmation(value ?? '')}
                error={passwordConfirmation && passwordConfirmation !== newPassword ? i18n`Passwords do not match` : undefined}
                fullWidth
              />
            </BlockContent>
          </HorizontalBlock>
          <VerticalBlock asBlockContent>
            {errorMessage && (
              <BlockContent padded>
                <SpacingLine>
                  <Typo variant={TypoVariant.small} color={theme.color.text.danger}>{errorMessage}</Typo>
                </SpacingLine>
              </BlockContent>
            )}
            <BlockContent padded>
              <Button
                title={i18n`Save`}
                disabled={(changeOwnPassword && !currentPassword) || !newPassword || !isValidPassword(newPassword) || passwordConfirmation !== newPassword || loading}
                onClick={() => forgetAsyncPromise(doFetchJSON)({
                  method: 'POST',
                  json: { currentPassword, newPassword },
                }, onClose)}
              />
            </BlockContent>
          </VerticalBlock>
        </VerticalBlock>
      )}
    />
  );
};

export default ChangePasswordModal;
