import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { getPathReturnedConceptDefinitionId } from 'yooi-modules/modules/conceptModule';
import { ConceptDefinition_Color } from 'yooi-modules/modules/conceptModule/ids';
import type { MatrixViewStoredDefinition, ViewDimension, WidgetStoreObject } from 'yooi-modules/modules/dashboardModule';
import { Widget_DisplayMode } from 'yooi-modules/modules/dashboardModule/ids';
import { IconName } from '../../../../components/atoms/Icon';
import Typo from '../../../../components/atoms/Typo';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import ToggleButton, { ElementPosition } from '../../../../components/molecules/ToggleButton';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { useSessionStorageState } from '../../../../utils/useSessionStorage';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../../utils/useSizeContext';
import useTheme from '../../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../../utils/useUsageContext';
import { getConceptModelDisplayField } from '../../conceptDisplayUtils';
import { getChipOptions, listColorFieldOptions } from '../../modelTypeUtils';
import { getViewDefinitionHandler } from '../viewDsl';
import type { MatrixViewDefinitionHandler } from './matrixViewDefinitionHandler';
import type { MatrixViewConfiguration } from './matrixViewResolution';
import { getMatrixViewDependenciesField } from './matrixViewResolution';

const useStyles = makeStyles({
  baseConfigurationContainer: {
    display: 'inline-flex',
    justifyItems: 'center',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: spacingRem.s,
    paddingBottom: spacingRem.s,
  },
  cardsConfigurationContainer: {
    paddingLeft: spacingRem.splus,
    paddingRight: spacingRem.splus,
  },
}, 'matrixViewOptions');

interface MatrixViewOptionsProps {
  filterKey: string,
  viewDefinition: MatrixViewStoredDefinition,
  viewDimensions: ViewDimension[],
  widgetId: string | undefined,
}

const MatrixViewOptions: FunctionComponent<MatrixViewOptionsProps> = ({ filterKey, viewDefinition, viewDimensions, widgetId }) => {
  const theme = useTheme();
  const store = useStore();
  const classes = useStyles();
  const [matrixConfig, , updateMatrixConfig] = useSessionStorageState<MatrixViewConfiguration>(`MatrixView:${filterKey}`, {});

  const viewHandler = getViewDefinitionHandler(viewDefinition) as MatrixViewDefinitionHandler;
  const resolvedViewDefinition = viewHandler.getDefinition(store, viewDimensions);

  const isBorderless = !widgetId || store.getObject<WidgetStoreObject>(widgetId)[Widget_DisplayMode]?.type === 'borderless';

  const conceptDefinitionId = getPathReturnedConceptDefinitionId(store, viewDimensions.at(0)?.path ?? []);
  if (!conceptDefinitionId) {
    return null;
  }

  const colorFieldId = matrixConfig.colorFieldId === null
    ? undefined
    : matrixConfig.colorFieldId ?? resolvedViewDefinition.colorFieldId ?? getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_Color)?.id;

  const showDependencies = matrixConfig.showDependencies ?? true;

  return (
    <div
      className={classnames({
        [classes.baseConfigurationContainer]: true,
        [classes.cardsConfigurationContainer]: !isBorderless,
      })}
    >
      <SizeContextProvider sizeVariant={SizeVariant.small}>
        <Typo color={theme.color.text.secondary}>{i18n`Color by`}</Typo>
        <UsageContextProvider usageVariant={UsageVariant.inForm}>
          <SearchAndSelect
            computeOptions={() => listColorFieldOptions(store, conceptDefinitionId)}
            selectedOption={colorFieldId ? getChipOptions(store, colorFieldId) : undefined}
            onSelect={(value) => updateMatrixConfig({ colorFieldId: value?.id ?? null })}
            clearable
          />
        </UsageContextProvider>
        {
          getMatrixViewDependenciesField(store, resolvedViewDefinition, conceptDefinitionId) !== undefined
            ? (
              <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
                <ToggleButton
                  name={showDependencies ? i18n`Dependencies` : i18n`Dependencies`}
                  icon={showDependencies ? IconName.visibility_off : IconName.visibility}
                  active={showDependencies}
                  type={ElementPosition.alone}
                  onClick={() => updateMatrixConfig({ showDependencies: !showDependencies })}
                />
              </SizeContextProvider>
            )
            : null
        }
      </SizeContextProvider>
    </div>
  );
};

export default MatrixViewOptions;
