import type { FunctionComponent } from 'react';
import type { AutomationRuleStoreObject, CollaborationEventTrigger } from 'yooi-modules/modules/automationModule';
import { isCollaborationEventTrigger, TriggerType } from 'yooi-modules/modules/automationModule';
import { AutomationRule_Trigger } from 'yooi-modules/modules/automationModule/ids';
import { Intention_Name } from 'yooi-modules/modules/collaborationModule/ids';
import type { ConceptDefinitionStoreObject } from 'yooi-modules/modules/conceptModule';
import { ConceptDefinition, ConceptDefinition_Name } from 'yooi-modules/modules/conceptModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import { compareProperty, compareString, comparing, filterNullOrUndefined, joinObjects, pushUndefinedToEnd } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SearchAndSelectMultiple from '../../../components/molecules/SearchAndSelectMultiple';
import SpacingLine from '../../../components/molecules/SpacingLine';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import HorizontalBlock, { HorizontalBlockVariant } from '../../../components/templates/HorizontalBlock';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { getChipOptions, getConceptDefinitionNameOrEntity, getUnknownChip } from '../../_global/modelTypeUtils';
import { getIntentions, isIntentionAvailableOnConcept } from '../../_global/rightPanel/collaboration/utils/collaborationUtils';

interface AutomationRuleCollaborationEventTriggerOptionsProps {
  ruleId: string,
}

const AutomationRuleCollaborationEventTriggerOptions: FunctionComponent<AutomationRuleCollaborationEventTriggerOptionsProps> = ({ ruleId }) => {
  const store = useStore();

  const automationRule = store.getObject<AutomationRuleStoreObject>(ruleId);
  const eventTrigger = automationRule[AutomationRule_Trigger];

  if (!eventTrigger || !isCollaborationEventTrigger(eventTrigger)) {
    return null;
  }

  return (
    <>
      <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compact}>
        <BlockTitle
          title={i18n`Concept`}
        />
        <BlockContent
          action={(
            <SpacingLine>
              <Icon
                name={IconName.info}
                colorVariant={IconColorVariant.info}
                tooltip={i18n`Leave this empty to listen to all new collaboration messages whatever the concept.`}
              />
            </SpacingLine>
          )}
        >
          <SearchAndSelect
            clearable
            computeOptions={() => store.getObject(ConceptDefinition)
              .navigateBack<ConceptDefinitionStoreObject>(Class_Instances)
              .sort(compareProperty(ConceptDefinition_Name, comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString)))
              .map(({ id: instanceId }) => getChipOptions(store, instanceId))
              .filter(filterNullOrUndefined)}
            placeholder={i18n`All concepts`}
            selectedOption={eventTrigger?.conceptDefinitionId
              ? getChipOptions(store, eventTrigger.conceptDefinitionId) ?? getUnknownChip(eventTrigger.conceptDefinitionId)
              : undefined}
            onSelect={(option) => {
              store.updateObject<AutomationRuleStoreObject>(ruleId, {
                [AutomationRule_Trigger]: {
                  type: TriggerType.collaboration,
                  conceptDefinitionId: option?.id,
                  intentionIds: eventTrigger.intentionIds,
                } satisfies CollaborationEventTrigger,
              });
            }}
          />
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent variant={HorizontalBlockVariant.compact}>
        <BlockTitle
          title={i18n`Intention(s)`}
        />
        <BlockContent
          action={(
            <SpacingLine>
              <Icon
                name={IconName.info}
                colorVariant={IconColorVariant.info}
                tooltip={i18n`Leave this empty to listen to all new collaboration messages whatever the intention.`}
              />
            </SpacingLine>
          )}
        >
          <SearchAndSelectMultiple
            computeOptions={() => getIntentions(store, eventTrigger.conceptDefinitionId)
              .sort(compareProperty(Intention_Name, comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString)))
              .map(({ id: instanceId }) => getChipOptions(store, instanceId))
              .filter(filterNullOrUndefined)}
            selectedOptions={(eventTrigger.intentionIds ?? [])
              .map((intentionId) => {
                if (!eventTrigger.conceptDefinitionId || isIntentionAvailableOnConcept(store, intentionId, eventTrigger.conceptDefinitionId)) {
                  return getChipOptions(store, intentionId) ?? getUnknownChip(intentionId);
                } else {
                  return joinObjects(getChipOptions(store, intentionId) ?? getUnknownChip(intentionId), {
                    icon: { name: IconName.dangerous, colorVariant: IconColorVariant.error },
                    tooltip: i18n`Intention not available on concept '${getConceptDefinitionNameOrEntity(store, eventTrigger.conceptDefinitionId)}'`,
                  });
                }
              })}
            placeholder={i18n`All intentions`}
            onSelect={(option) => {
              store.updateObject<AutomationRuleStoreObject>(ruleId, {
                [AutomationRule_Trigger]: {
                  type: TriggerType.collaboration,
                  conceptDefinitionId: eventTrigger.conceptDefinitionId,
                  intentionIds: [...new Set(eventTrigger.intentionIds ?? []).add(option.id)],
                } satisfies CollaborationEventTrigger,
              });
            }}
            onDelete={(option) => {
              const intentionsSet = new Set(eventTrigger.intentionIds ?? []);
              intentionsSet.delete(option.id);
              store.updateObject<AutomationRuleStoreObject>(ruleId, {
                [AutomationRule_Trigger]: {
                  type: TriggerType.collaboration,
                  conceptDefinitionId: eventTrigger.conceptDefinitionId,
                  intentionIds: [...intentionsSet],
                } satisfies CollaborationEventTrigger,
              });
            }}
          />
        </BlockContent>
      </HorizontalBlock>
    </>
  );
};

export default AutomationRuleCollaborationEventTriggerOptions;
