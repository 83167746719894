import { isNaS } from '../typeSystem';
import { OperatorInvalidArgumentTypeError } from './errors';
import type { EngineContext, FormulaType, FunctionLibrary } from './formula';
import { newFunctionLibrary } from './functionLibraryBuilder';

const expectType = (operator: string, expectedType: FormulaType, argType: FormulaType, operand?: string) => (
  expectedType.isAssignableFrom(argType) ? undefined : new OperatorInvalidArgumentTypeError(operator, expectedType, argType, operand)
);

const validateHomogeneousOperator = (expectedType: FormulaType) => (operator: string, leftType: FormulaType, rightType?: FormulaType) => (
  expectType(operator, expectedType, leftType, rightType !== undefined ? 'left' : undefined) ?? (rightType && expectType(operator, expectedType, rightType, 'right'))
);

export const createBuiltinOperators = ({ booleanType, numberType, textType }: EngineContext['typeSystem']): FunctionLibrary => {
  const validateNumberOperator = validateHomogeneousOperator(numberType);
  const validateStringOperator = validateHomogeneousOperator(textType);

  const validateComparisonType = (operator: string, leftType: FormulaType, rightType: FormulaType) => {
    if (!(numberType.isAssignableFrom(leftType) || textType.isAssignableFrom(leftType) || booleanType.isAssignableFrom(leftType))) {
      return new OperatorInvalidArgumentTypeError(operator, [numberType, textType, booleanType], leftType, 'left');
    }
    return expectType(operator, leftType, rightType, 'right');
  };

  return newFunctionLibrary()
    .addFunction('%', (name) => ({
      name,
      isDeterminist: true,
      resolve: ([operandType]) => validateNumberOperator(name, operandType) ?? ({
        type: numberType,
        jsFunction: (n: number) => n / 100,
        transpile: ([operand]) => `(${operand})/100`,
      }),
    }))
    .addFunction('+', (name) => ({
        name,
        isDeterminist: true,
        resolve: ([operandType1, operandType2]) => {
          if (operandType2 !== undefined) {
            return validateNumberOperator(name, operandType1, operandType2) ?? {
              type: numberType,
              jsFunction: (left: number, right: number) => left + right,
              transpile: ([left, right]) => `(${left})+(${right})`,
            };
          } else {
            return validateNumberOperator(name, operandType1) ?? {
              type: numberType,
              jsFunction: (n: number) => +n,
              transpile: ([operand]) => `+(${operand})`,
            };
          }
        },
      }
    ))
    .addFunction('-', (name) => ({
        name,
        isDeterminist: true,
        resolve: ([operandType1, operandType2]) => {
          if (operandType2 !== undefined) {
            return validateNumberOperator(name, operandType1, operandType2) ?? {
              type: numberType,
              jsFunction: (left: number, right: number) => left - right,
              transpile: ([left, right]) => `(${left})-(${right})`,
            };
          } else {
            return validateNumberOperator(name, operandType1) ?? {
              type: numberType,
              jsFunction: (n: number) => -n,
              transpile: ([operand]) => `-(${operand})`,
            };
          }
        },
      }
    ))
    .addFunction('^', (name) => ({
      name,
      isDeterminist: true,
      resolve: ([leftType, rightType]) => validateNumberOperator(name, leftType, rightType) ?? {
        type: numberType,
        jsFunction: (left: number, right: number) => left ** right,
        transpile: ([left, right]) => `(${left})**(${right})`,
      },
    }))
    .addFunction('*', (name) => ({
      name,
      isDeterminist: true,
      resolve: ([leftType, rightType]) => validateNumberOperator(name, leftType, rightType) ?? {
        type: numberType,
        jsFunction: (left: number, right: number) => left * right,
        transpile: ([left, right]) => `(${left})*(${right})`,
      },
    }))
    .addFunction('/', (name) => ({
      name,
      isDeterminist: true,
      resolve: ([leftType, rightType]) => validateNumberOperator(name, leftType, rightType) ?? {
        type: numberType,
        jsFunction: (left: number, right: number) => left / right,
        transpile: ([left, right]) => `(${left})/(${right})`,
      },
    }))
    .addFunction('&', (name) => ({
      name,
      isDeterminist: true,
      resolve: ([leftType, rightType]) => validateStringOperator(name, leftType, rightType) ?? {
        type: textType,
        jsFunction: (left: string | undefined, right: string | undefined) => {
          if (left === undefined || right === undefined || isNaS(left) || isNaS(right)) {
            return Symbol('NaS');
          } else {
            return left + right;
          }
        },
      },
    }))
    .addFunction('=', (name) => ({
      name,
      isDeterminist: true,
      resolve: ([leftType, rightType]) => validateComparisonType(name, leftType, rightType) ?? {
        type: booleanType,
        jsFunction: <T extends boolean | number | string>(left: T, right: T) => left === right,
        transpile: ([left, right]) => `(${left})===(${right})`,
      },
    }))
    .addFunction('>', (name) => ({
      name,
      isDeterminist: true,
      resolve: ([leftType, rightType]) => validateComparisonType(name, leftType, rightType) ?? {
        type: booleanType,
        jsFunction: <T extends boolean | number | string>(left: T, right: T) => left > right,
        transpile: ([left, right]) => `(${left})>(${right})`,
      },
    }))
    .addFunction('>=', (name) => ({
      name,
      isDeterminist: true,
      resolve: ([leftType, rightType]) => validateComparisonType(name, leftType, rightType) ?? {
        type: booleanType,
        jsFunction: <T extends boolean | number | string>(left: T, right: T) => left >= right,
        transpile: ([left, right]) => `(${left})>=(${right})`,
      },
    }))
    .addFunction('<', (name) => ({
      name,
      isDeterminist: true,
      resolve: ([leftType, rightType]) => validateComparisonType(name, leftType, rightType) ?? {
        type: booleanType,
        jsFunction: <T extends boolean | number | string>(left: T, right: T) => left < right,
        transpile: ([left, right]) => `(${left})<(${right})`,
      },
    }))
    .addFunction('<=', (name) => ({
      name,
      isDeterminist: true,
      resolve: ([leftType, rightType]) => validateComparisonType(name, leftType, rightType) ?? {
        type: booleanType,
        jsFunction: <T extends boolean | number | string>(left: T, right: T) => left <= right,
        transpile: ([left, right]) => `(${left})<=(${right})`,
      },
    }))
    .addFunction('<>', (name) => ({
      name,
      isDeterminist: true,
      resolve: ([leftType, rightType]) => validateComparisonType(name, leftType, rightType) ?? {
        type: booleanType,
        jsFunction: <T extends boolean | number | string>(left: T, right: T) => left !== right,
        transpile: ([left, right]) => `(${left})!==(${right})`,
      },
    }))
    .build();
};
