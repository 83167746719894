import type { FunctionComponent } from 'react';
import { formatOrUndef } from '../../utils/stringUtils';
import useTheme from '../../utils/useTheme';
import Typo from '../atoms/Typo';

interface FunctionalIdProps {
  functionalId: string | undefined,
}

const FunctionalId: FunctionComponent<FunctionalIdProps> = ({ functionalId }) => {
  const theme = useTheme();

  return (<Typo color={theme.color.text.secondary} maxLine={1}>{formatOrUndef(functionalId)}</Typo>);
};

export default FunctionalId;
