import type { FunctionComponent } from 'react';
import { joinObjects } from 'yooi-utils';
import Typo from '../../../../components/atoms/Typo';
import BlockContent from '../../../../components/templates/BlockContent';
import VerticalBlock from '../../../../components/templates/VerticalBlock';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { generateHintedValue } from '../_global/explorerUtils';
import { useExplorerHint } from '../_global/GetHintContextProvider';
import ValueRenderer from '../_global/ValueRenderer';

interface InstanceDetailTabProps {
  instanceId: string | string[],
}

const InstanceDetailTab: FunctionComponent<InstanceDetailTabProps> = ({ instanceId }) => {
  const store = useStore();
  const explorerHint = useExplorerHint();

  const instance = store.getObjectOrNull(instanceId);

  if (!instance) {
    return (
      <VerticalBlock>
        <BlockContent padded>
          <Typo>{i18n`Instance does not exists!`}</Typo>
        </BlockContent>
      </VerticalBlock>
    );
  }

  const object = joinObjects({ key: instance.key, id: instance.id }, instance.asRawObject());

  return (
    <VerticalBlock>
      <BlockContent padded>
        <ValueRenderer
          // Force recreation of object as we navigate to make sure we clear component states
          key={instance.key}
          value={generateHintedValue(store, explorerHint, object, true)}
        />
      </BlockContent>
    </VerticalBlock>
  );
};

export default InstanceDetailTab;
