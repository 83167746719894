import classnames from 'classnames';
import type { ComponentProps, FunctionComponent } from 'react';
import { useState } from 'react';
import type { DimensionsMapping, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import {
  canCopyConcept,
  CardColorMode,
  dimensionsMappingToParametersMapping,
  getConceptUrl,
  getInstanceLabel,
  isEmbeddedAsIntegrationOnly,
  ParsedDimensionType,
  parseDimensionMapping,
} from 'yooi-modules/modules/conceptModule';
import { ConceptRole } from 'yooi-modules/modules/conceptModule/ids';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { DataAsset } from 'yooi-modules/modules/dataAssetModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareString, comparing, filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { ButtonVariant } from '../../../../components/atoms/Button';
import { IconName } from '../../../../components/atoms/Icon';
import Card from '../../../../components/molecules/Card';
import type { InlineCreationInline, InlineCreationTransactional } from '../../../../components/molecules/inlineCreationTypes';
import type { MenuItem } from '../../../../components/molecules/Menu';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import { TableSortDirection } from '../../../../components/molecules/Table';
import CardList, { cardListBottomContainerHeight, cardListGap, cardListMinCardWidth } from '../../../../components/templates/CardList';
import useAcl from '../../../../store/useAcl';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { remToPx } from '../../../../utils/sizeUtils';
import useNavigation from '../../../../utils/useNavigation';
import type { NewLineFocusRefContent } from '../../../../utils/useNewLineFocus';
import useNewLineFocus, { useFocusNewLineNotify } from '../../../../utils/useNewLineFocus';
import { useSessionStorageState } from '../../../../utils/useSessionStorage';
import { duplicateConcept } from '../../conceptUtils';
import { getFieldHandler } from '../../fields/FieldLibrary';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { getChipOptions, getSearchChipOptions } from '../../modelTypeUtils';
import type { NavigationFilter } from '../../navigationUtils';
import { getNavigationPayload } from '../../navigationUtils';
import { share } from '../../shareUtils';
import useConceptDeleteModal from '../../useConceptDeleteModal';
import useFilterAndSort from '../../useFilterAndSort';
import { canDuplicateInstance, getViewNavigationFilters } from '../common/viewUtils';
import type { CardsViewResolvedDefinition } from './cardsViewDefinitionHandler';
import type { CardsViewResolution } from './cardsViewResolution';
import { buildInstanceCardBody, buildInstanceCardHeader } from './cardsViewUtils';
import InstanceCard from './InstanceCard';

const contentRowGap = spacingRem.xs;
const defaultLineHeight = '3.2rem';
const contentVerticalPadding = spacingRem.s;
const fullVerticalPadding = spacingRem.xs;

const useStyles = makeStyles({
  cardHeader: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: contentRowGap,
  },
  cardHeaderPaddingTop: {
    paddingTop: contentVerticalPadding,
  },
  cardHeaderPaddingBottom: {
    paddingBottom: contentVerticalPadding,
  },
}, 'cardsView');

interface CardsViewProps {
  viewDefinition: CardsViewResolvedDefinition,
  viewDimensions: ViewDimension[],
  viewResolution: CardsViewResolution,
  parametersMapping: ParametersMapping,
  filterKey: string,
  availableWidth: number,
  availableHeight?: number,
}

const CardsView: FunctionComponent<CardsViewProps> = ({ viewDefinition, viewDimensions, viewResolution, parametersMapping, filterKey, availableWidth, availableHeight }) => {
  const classes = useStyles();

  const store = useStore();
  const aclHandler = useAcl();

  const navigation = useNavigation<NavigationFilter>();

  const [doDelete, popupComponent] = useConceptDeleteModal(false);

  const [filtersConfiguration] = useSessionStorageState<FilterConfiguration | undefined>(filterKey, undefined);

  const [showInlineCreation, setShowInlineCreation] = useState(false);
  const focusNewLineNotify = useFocusNewLineNotify();
  const [newLineFocus] = useNewLineFocus();

  const { generatePageList, forceShowId, forceFollowingIds } = useFilterAndSort(
    filterKey,
    viewResolution.cards,
    viewResolution.filter,
    viewResolution.sort === undefined ? undefined : {
      getComparatorHandler: (_, direction) => {
        const { sort } = viewResolution;
        if (sort?.type === 'field') {
          const comparatorHandler = getFieldHandler(store, sort.fieldId)?.getComparatorHandler?.(direction);
          if (comparatorHandler === undefined) {
            return undefined;
          } else {
            return {
              comparator: comparatorHandler.comparator,
              extractValue: ({ dimensionsMapping }) => {
                const resolution = sort.resolve(joinObjects(parametersMapping, dimensionsMappingToParametersMapping(dimensionsMapping)));
                if (resolution === undefined) {
                  return undefined;
                } else {
                  return comparatorHandler.extractValue(resolution);
                }
              },
            };
          }
        } else if (sort?.type === 'instance') {
          return {
            comparator: comparing(compareString, direction === TableSortDirection.desc),
            extractValue: ({ dimensionsMapping }) => {
              const instanceId = sort.resolve(joinObjects(parametersMapping, dimensionsMappingToParametersMapping(dimensionsMapping)));
              const instance = instanceId ? store.getObjectOrNull(instanceId) : undefined;
              return instance ? getInstanceLabel(store, instance) : undefined;
            },
          };
        } else {
          return undefined;
        }
      },
      initial: { key: 'sort', direction: viewResolution.sort.direction === 'asc' ? TableSortDirection.asc : TableSortDirection.desc },
    },
    undefined,
    [filtersConfiguration, viewDimensions, JSON.stringify(parametersMapping)]
  );

  const cardListGapPx = remToPx(cardListGap);
  const defaultLineHeightPx = remToPx(defaultLineHeight);

  const numberOfColumns = viewDefinition.layout.columns === 'auto'
    ? Math.max(Math.floor((availableWidth + cardListGapPx) / (remToPx(cardListMinCardWidth) + cardListGapPx)), 1)
    : viewDefinition.layout.columns;

  const cardWidth = numberOfColumns === 1 ? availableWidth : ((availableWidth - ((numberOfColumns - 1) * cardListGapPx)) / numberOfColumns);
  const cardInnerWidth = cardWidth - remToPx(0.1/* border */ * 2);
  const cardBodyInnerWidth = cardInnerWidth - remToPx(1.6 /* padding */ * 2) - remToPx(1.6 /* column 1 */) - remToPx(0.8 /* column gap */) - remToPx(0.8 /* inner padding */ * 2);

  const getCardActions = (cardActionsDimensionsMapping: DimensionsMapping): MenuItem[] => {
    const actions: MenuItem[] = [];

    const parsedDimension = parseDimensionMapping(cardActionsDimensionsMapping);
    if (parsedDimension.type === ParsedDimensionType.MultiDimensional) {
      return [];
    }

    const concept = store.getObject(parsedDimension.objectId);

    actions.push({
      key: 'open',
      icon: IconName.output,
      name: i18n`Open`,
      onClick: () => navigation.push(
        parsedDimension.objectId,
        {
          pathname: getConceptUrl(store, parsedDimension.objectId),
          navigationFilters: getViewNavigationFilters(store, viewDimensions, filtersConfiguration, parametersMapping),
        }
      ),
    });

    actions.push({
      key: 'share',
      icon: IconName.link,
      name: i18n`Copy link`,
      onClick: () => share(
        store,
        getNavigationPayload(
          navigation,
          parsedDimension.objectId,
          getConceptUrl(store, parsedDimension.objectId),
          getViewNavigationFilters(store, viewDimensions, filtersConfiguration, parametersMapping)
        )
      ),
    });

    if (!viewDefinition.readOnly) {
      if (canDuplicateInstance(store, viewDimensions[0]) && canCopyConcept(store, aclHandler, parsedDimension.objectId)) {
        actions.push({
          key: 'duplicate',
          icon: IconName.content_copy_outline,
          name: i18n`Duplicate`,
          onClick: () => duplicateConcept(store, aclHandler, parsedDimension.objectId, (id) => forceFollowingIds(parsedDimension.objectId, id)),
        });
        if (concept[Instance_Of] !== DataAsset) {
          actions.push({
            key: 'duplicate_with_child',
            icon: IconName.content_copy_outline,
            name: i18n`Duplicate with its child elements`,
            onClick: () => duplicateConcept(store, aclHandler, parsedDimension.objectId, (id) => forceFollowingIds(parsedDimension.objectId, id), true),
          });
        }
      }
      if (viewResolution.linkOptions) {
        actions.push({
          key: 'unlink',
          name: i18n`Unlink`,
          icon: IconName.link_off,
          danger: true,
          onClick: () => viewResolution.linkOptions?.onUnlink(parsedDimension.objectId),
        });
      } else if (concept[Instance_Of] !== ConceptRole && !isEmbeddedAsIntegrationOnly(concept) && aclHandler.canDeleteObject(parsedDimension.objectId)) {
        actions.push({
          key: 'delete',
          icon: IconName.delete,
          name: i18n`Delete`,
          onClick: () => doDelete(concept.id),
          danger: true,
        });
      }
    }

    return actions;
  };

  const navigationFilters = getViewNavigationFilters(store, viewDimensions, filtersConfiguration, parametersMapping);

  const header = buildInstanceCardHeader(store, viewResolution.header, viewDefinition.readOnly, defaultLineHeightPx, cardInnerWidth, navigationFilters);

  const body = buildInstanceCardBody(store, viewResolution.body, viewDefinition.readOnly, defaultLineHeightPx, cardBodyInnerWidth, navigationFilters);

  const includeHeaderPaddingTop = !header.at(0)?.fullWidth || (header.length === 0 && viewResolution.color?.as === CardColorMode.Dot);
  const includeHeaderPaddingBottom = !header.at(-1)?.fullWidth || (header.length === 0 && viewResolution.color?.as === CardColorMode.Dot);

  let numberOfLines = 1;
  if (typeof viewDefinition.layout.lines === 'number') {
    numberOfLines = viewDefinition.layout.lines;
  } else if (availableHeight !== undefined && viewDefinition.layout.lines === 'auto') {
    const contentRowGapPx = remToPx(contentRowGap);
    const contentVerticalPaddingPx = remToPx(contentVerticalPadding);
    const fullVerticalPaddingPx = remToPx(fullVerticalPadding);
    const headerContentHeightPx = header.length === 0
      ? 0
      : (
        header.reduce(
          (accumulator, { estimatedHeight, fullWidth }, index) => (
            accumulator
            + estimatedHeight
            + (fullWidth && index !== 0 ? fullVerticalPaddingPx : 0)
            + (fullWidth && index !== (header.length - 1) ? fullVerticalPaddingPx : 0)
          ),
          0
        ) + (contentRowGapPx * (header.length - 1))
      );
    const bodyContentHeightPx = body.length === 0
      ? 0
      : body.reduce((accumulator, { estimatedHeight }) => (accumulator + estimatedHeight + defaultLineHeightPx), 0) + contentRowGapPx * (body.length - 1);

    const colorHeightPx = viewResolution.color?.as === CardColorMode.Bar ? remToPx(0.8) : 0;
    const headerHeightPx = header.length === 0
      ? 0
      : (headerContentHeightPx + (includeHeaderPaddingTop ? contentVerticalPaddingPx : 0) + (includeHeaderPaddingBottom ? contentVerticalPaddingPx : 0));
    const bodyHeightPx = body.length === 0 ? 0 : (contentVerticalPaddingPx * 2 + bodyContentHeightPx);
    const cardHeightPx = colorHeightPx + headerHeightPx + bodyHeightPx + remToPx(0.1 * 2 /* border */);
    const cardsAvailableHeightPx = availableHeight - remToPx(cardListBottomContainerHeight) - remToPx(0.8 /* scroll height */);
    numberOfLines = Math.max(Math.floor((cardsAvailableHeightPx + cardListGapPx) / (cardHeightPx + cardListGapPx)), 1);
  }

  const { list, pagination } = generatePageList(numberOfColumns * numberOfLines);

  const getInlineCreation = (): ComponentProps<typeof CardList>['inlineCreation'] => {
    const { linkOptions, inlineCreationOptions, createOptions } = viewResolution;

    if (!inlineCreationOptions && !linkOptions && !createOptions) {
      return undefined;
    }

    let newItemTitle: string = '';
    let onNewItem: () => void = () => {};
    if (inlineCreationOptions) {
      newItemTitle = i18n`Add`;
      onNewItem = () => setShowInlineCreation(true);
    } else if (linkOptions) {
      newItemTitle = i18n`Add`;
      onNewItem = () => setShowInlineCreation(true);
    } else if (createOptions) {
      newItemTitle = i18n`Create`;
      onNewItem = () => {
        const newInstanceId = createOptions.onCreate();
        if (newInstanceId) {
          forceShowId(newInstanceId);
          focusNewLineNotify(`${filterKey}|${newInstanceId}`);
        }
      };
    }

    const getNewLineFocus = (): NewLineFocusRefContent | undefined => {
      const newLineFocusId = newLineFocus?.current.id;
      if (!newLineFocusId) {
        return undefined;
      }
      const [newLineFilterId, newInstanceId] = newLineFocusId.split('|');
      if (filterKey === newLineFilterId) {
        return joinObjects(newLineFocus.current, { id: newInstanceId });
      }
      return undefined;
    };

    return {
      type: 'transaction',
      button: {
        title: newItemTitle,
        icon: IconName.add,
        variant: ButtonVariant.tertiary,
        onClick: onNewItem,
      },
      lineFocus: getNewLineFocus(),
      showCard: showInlineCreation,
      renderCard: () => (
        <Card scrollOnMount>
          <div
            className={classnames({
              [classes.cardHeader]: true,
              [classes.cardHeaderPaddingTop]: includeHeaderPaddingTop,
              [classes.cardHeaderPaddingBottom]: includeHeaderPaddingBottom,
            })}
          >
            <SearchAndSelect
              placeholder={i18n`Select element`}
              onEscape={() => setShowInlineCreation(false)}
              onClickAway={() => setShowInlineCreation(false)}
              onSelect={(v) => {
                if (v) {
                  linkOptions?.onLink?.(v.id);
                  setShowInlineCreation(false);
                }
              }}
              getInlineCreation={inlineCreationOptions ? (): InlineCreationInline | InlineCreationTransactional => {
                const inlineCreation = inlineCreationOptions?.buildInlineCreation();

                if (inlineCreation?.type === 'inline') {
                  return {
                    type: 'inline',
                    onCreate: (title) => {
                      setShowInlineCreation(false);
                      const newInstanceId = inlineCreation.onCreate(title);
                      forceShowId(newInstanceId);
                      return newInstanceId;
                    },
                  };
                } else if (inlineCreation?.type === 'transactional') {
                  return {
                    type: 'transactional',
                    creationOptions: inlineCreation.creationOptions,
                    onCreate: (creationOptionState) => {
                      setShowInlineCreation(false);
                      const newInstanceId = inlineCreation.onCreate(creationOptionState);
                      forceShowId(newInstanceId);
                      return newInstanceId;
                    },
                    getChipLabel: inlineCreation.getChipLabel,
                    getInitialState: inlineCreation.getInitialState,
                  };
                } else {
                  throw new Error('Missing inline creation type');
                }
              } : undefined}
              searchOptions={viewResolution.targetConceptDefinitionId ? getSearchChipOptions(store, viewResolution.targetConceptDefinitionId) : undefined}
              computeOptions={linkOptions ? () => linkOptions.computeLinkableInstanceIds().map((id) => getChipOptions(
                store,
                id,
                getViewNavigationFilters(store, viewDimensions, filtersConfiguration, parametersMapping)
              ))
                .filter(filterNullOrUndefined) : undefined}
              editOnMount
              disableFitContent
            />
          </div>
        </Card>
      ),
    };
  };

  return (
    <>
      <CardList
        list={list.map(({ item }) => item)}
        pagination={pagination}
        numberOfColumns={viewDefinition.layout.columns !== 'auto' ? viewDefinition.layout.columns : undefined}
        renderCard={({ key, dimensionsMapping }) => (
          <InstanceCard
            key={key}
            header={header}
            body={body}
            getNavigationPayload={viewDimensions.length === 1 ? () => {
              // The openButton function check that this is a monoDim line
              const parsedMapping = parseDimensionMapping(dimensionsMapping);
              if (parsedMapping.type === ParsedDimensionType.MonoDimensional) {
                return navigation.createNavigationPayload(
                  key,
                  {
                    pathname: getConceptUrl(store, parsedMapping.objectId),
                    navigationFilters: getViewNavigationFilters(store, viewDimensions, filtersConfiguration, parametersMapping),
                  }
                );
              } else {
                return undefined;
              }
            } : undefined}
            menuActions={viewDimensions.length === 1 ? getCardActions(dimensionsMapping) : undefined}
            color={viewResolution.color}
            icon={viewResolution.icon}
            boolean={viewResolution.boolean}
            readOnly={viewDefinition.readOnly}
            parametersMapping={joinObjects(parametersMapping, dimensionsMappingToParametersMapping(dimensionsMapping))}
          />
        )}
        inlineCreation={getInlineCreation()}
      />
      {popupComponent}
    </>
  );
};

export default CardsView;
