import classnames from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';
import type { ConceptDefinitionStoreObject, ConceptStoreObject } from 'yooi-modules/modules/conceptModule';
import { CardColorMode, colorFieldHandler, getFieldDimensionOfModelType, textFieldHandler } from 'yooi-modules/modules/conceptModule';
import { ColorField, ConceptDefinition_CardColorMode } from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { StoreObject } from 'yooi-store';
import { joinObjects } from 'yooi-utils';
import { ColorPickerVariant } from '../../../components/inputs/ColorPicker';
import Card from '../../../components/molecules/Card';
import type { CardLineDefinition } from '../../../components/molecules/CardLine';
import CardLine from '../../../components/molecules/CardLine';
import type { MenuItem } from '../../../components/molecules/Menu';
import useStore from '../../../store/useStore';
import base, { Opacity } from '../../../theme/base';
import { colorWithAlpha } from '../../../theme/colorUtils';
import { FontVariant } from '../../../theme/fontDefinition';
import { Spacing, spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import type { NavigationPayload } from '../../../utils/useNavigation';
import { getColorPalette } from '../../utils/standardColorsUtils';
import { resolveColor } from '../conceptDisplayUtils';
import StoreColorPickerInput from '../input/StoreColorPickerInput';
import StoreRichTextInputField from '../input/StoreRichTextInputField';
import { ImageDisplayType, imageFieldDefinition } from './imageField/imageFieldDefinition';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    height: '4rem',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: colorWithAlpha(theme.color.border.default, Opacity.twentyFive),
  },
  titleWithColorDot: {
    paddingLeft: spacingRem.m,
    columnGap: spacingRem.s,
  },
}), 'conceptCard');

interface ConceptCardProps {
  getNavigationPayload: () => NavigationPayload,
  actions: MenuItem[],
  focusOnMount: boolean,
  colorFieldId: string | undefined,
  titleFieldId: string | undefined,
  imageFieldId: string | undefined,
  linesDefinition: (Omit<CardLineDefinition, 'render'> & { render: (concept: StoreObject, focusOnMount: boolean) => ReactElement | null })[],
  conceptId: string,
  readOnly: boolean,
}

const ConceptCard: FunctionComponent<ConceptCardProps> = ({
  getNavigationPayload,
  actions,
  focusOnMount,
  colorFieldId,
  titleFieldId,
  imageFieldId,
  linesDefinition,
  conceptId,
  readOnly,
}) => {
  const classes = useStyles();

  const store = useStore();
  const concept = store.getObject<ConceptStoreObject>(conceptId);
  const conceptDefinition = concept.navigate<ConceptDefinitionStoreObject>(Instance_Of);

  const titleFieldDimensionId = titleFieldId ? getFieldDimensionOfModelType(store, titleFieldId, conceptDefinition.id) : undefined;
  const titleFieldHandler = titleFieldId ? textFieldHandler(store, titleFieldId) : undefined;
  const imageFieldDimensionId = imageFieldId ? getFieldDimensionOfModelType(store, imageFieldId, conceptDefinition.id) : undefined;
  const imageFieldRenderer = imageFieldId ? imageFieldDefinition(store).getHandler(imageFieldId)?.renderField : undefined;

  const renderImage = () => {
    if (imageFieldDimensionId && imageFieldRenderer) {
      return imageFieldRenderer({
        dimensionsMapping: { [imageFieldDimensionId]: conceptId },
        readOnly,
        fieldDisplayOptions: { displayType: ImageDisplayType.BorderlessValue },
      });
    } else {
      return null;
    }
  };

  const renderColor = (variant: ColorPickerVariant) => {
    if (colorFieldId) {
      const isColorField = store.getObject(colorFieldId)[Instance_Of] === ColorField;
      const colorPalette = getColorPalette(store);

      return (
        <StoreColorPickerInput
          initialValue={resolveColor(store, conceptDefinition.id, conceptId, colorFieldId)}
          defaultValue={isColorField ? colorFieldHandler(store, colorFieldId).resolveConfiguration().defaultColor ?? base.color.gray['300'] : base.color.gray['300']}
          onSubmit={
            isColorField
              ? (newColor) => {
                const colorFieldDimensionId = getFieldDimensionOfModelType(store, colorFieldId, conceptDefinition.id);
                if (colorFieldDimensionId) {
                  colorFieldHandler(store, colorFieldId).updateValue({ [colorFieldDimensionId]: conceptId }, newColor ?? null);
                }
              }
              : () => {}
          }
          colorPalette={colorPalette}
          readOnly={readOnly || !isColorField}
          variant={variant}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <Card
      getNavigationPayload={getNavigationPayload}
      menuActions={actions}
      scrollOnMount={focusOnMount}
      buttonExtraOffsetTop={colorFieldId && conceptDefinition[ConceptDefinition_CardColorMode] !== CardColorMode.Dot ? Spacing.s : undefined}
    >
      <div className={classes.container}>
        {colorFieldId && conceptDefinition[ConceptDefinition_CardColorMode] !== CardColorMode.Dot ? renderColor(ColorPickerVariant.bar) : undefined}
        {
          ((titleFieldDimensionId && titleFieldHandler) || (colorFieldId && conceptDefinition[ConceptDefinition_CardColorMode] === CardColorMode.Dot)) ? (
            <div
              className={classnames({
                [classes.title]: true,
                [classes.titleWithColorDot]: colorFieldId && conceptDefinition[ConceptDefinition_CardColorMode] === CardColorMode.Dot,
              })}
            >
              {colorFieldId && conceptDefinition[ConceptDefinition_CardColorMode] === CardColorMode.Dot ? renderColor(ColorPickerVariant.dot) : undefined}
              {titleFieldDimensionId && titleFieldHandler ? (
                <StoreRichTextInputField
                  variant={FontVariant.tabTitle}
                  maxLine={1}
                  initialValue={titleFieldHandler.getValueResolution({ [titleFieldDimensionId]: conceptId })?.getDisplayValue()?.valueOf()}
                  onSubmit={(newValue) => titleFieldHandler.updateValue({ [titleFieldDimensionId]: conceptId }, newValue)}
                  readOnly={readOnly}
                  placeholder={i18n`Add text`}
                />
              ) : null}
            </div>
          ) : null
        }
        {renderImage()}
        {linesDefinition.length > 0 ? (
          <CardLine
            linesDefinition={
              linesDefinition.map(({ render, ...definition }) => (joinObjects(definition, { render: () => render(concept, focusOnMount) })))
            }
          />
        ) : null}
      </div>
    </Card>
  );
};

export default ConceptCard;
