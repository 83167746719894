import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useContext, useRef } from 'react';
import type { IFrameAllowedUrlStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import { IFrameAllowedUrl, IFrameAllowedUrl_Url } from 'yooi-modules/modules/platformConfigurationModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined, isUrl } from 'yooi-utils';
import useStoreContext from '../../../../store/useStoreContext';

interface IFrameContextProviderProps {
  children: ReactNode,
}

const IFrameContext = createContext<string[]>([]);

export const IFrameContextProvider: FunctionComponent<IFrameContextProviderProps> = ({ children }) => {
  const storeContext = useStoreContext();

  const instances = useRef<string[] | undefined>(undefined);

  if (instances.current === undefined) {
    instances.current = storeContext.data.getObject(IFrameAllowedUrl)
      .navigateBack<IFrameAllowedUrlStoreObject>(Instance_Of)
      .map(({ [IFrameAllowedUrl_Url]: allowedUrl }) => allowedUrl)
      .filter(filterNullOrUndefined)
      .filter((allowedUrl) => isUrl(allowedUrl))
      .map((allowedUrl) => new URL(allowedUrl).origin);
  }

  return (
    <IFrameContext.Provider value={instances.current}>
      {children}
    </IFrameContext.Provider>
  );
};

export const useIFrameContext = (): string[] => useContext(IFrameContext);
