import { newError } from 'yooi-utils';
import { asLocal } from '../../common/fields/commonPropertyType';
import type { GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import type { Filters } from '../../conceptModule';
import { ConceptDefinition } from '../../conceptModule/ids';
import { registerField } from '../module';
import type { RadarChartField } from './types';

export interface RadarChartSeries {
  id: string,
  label?: string,
  color?: string,
  rank: string,
  valuePath?: string[],
  colorPath?: string[],
}

export interface RadarChartFieldConfiguration {
  labelPath?: string[],
  colorPath?: string[],
  filters?: Filters,
  series?: RadarChartSeries[],
}

type RadarChartFieldHandler = GetDslFieldHandler<RadarChartField, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined>;
export const radarChartFieldHandler: RadarChartFieldHandler = registerField({
  model: {
    label: 'RadarChartField',
    title: 'Radar chart',
    properties: [
      { label: 'Configuration', as: asLocal('RadarChartFieldConfiguration') },
    ],
    relations: [
      { label: 'ModelType', targetTypeId: ConceptDefinition, reverseLabel: 'ConceptDefinition_RadarChartFields' },
    ],
  },
  handler: () => ({
    describe: () => ({ hasData: false }),
    restApi: {
      returnTypeSchema: {},
      formatValue: () => undefined,
    },
    getStoreValue: () => undefined,
    getValueWithoutFormula: () => undefined,
    getValueResolution: () => ({ value: undefined, getDisplayValue: () => undefined, isComputed: false, isTimeseries: false }),
    updateValue: () => {
      throw newError('updateValue not supported');
    },
    resolvePathStepConfiguration: () => ({ hasData: false }),
    isEmpty: () => false,
    isSaneValue: () => ({ isValid: true }),
  }),
});
