import { doYield, errorToObject, filterNullOrUndefined, newError } from 'yooi-utils';
import type {
  AccessControlListHandler,
  AccessControlListLibrary,
  AccessControlListObjectResolutionResult,
  AccessControlListResolutionResult,
  BusinessRuleResult,
  BusinessRulesLibrary,
  ExecutionContext,
  GarbageCollectorRulesLibrary,
  GenerateSystemEvent,
  MetadataGeneratorHandler,
  MetadataGeneratorLibrary,
  NewChanges,
} from '../modules';
import type { ObjectStore, RawObjectStore } from '../ObjectStoreType';
import OriginSources from '../OriginSources';
import { encodeId, isTimeseriesOperation } from '../Protocol';
import type { AuditEvent, EventOrigin, Operation, TimeseriesOperation } from '../ProtocolType';
import { ProcessedEventStatus } from '../ProtocolType';
import { ValidationStatus } from './ValidationStatus';

export interface PendingOperation {
  id: string | string[],
  properties?: Record<string, unknown> | null,
  timeseries?: Record<string, TimeseriesOperation>,
  systemEvent?: boolean,
}

const createOperationAccumulator = (objectStore: RawObjectStore, businessRulesLibrary: BusinessRulesLibrary) => {
  const pendingOperations: PendingOperation[] = [];
  const rollbackEvent: PendingOperation[] = [];

  const sanitizeProperties = (properties: Record<string, unknown> | null | undefined) => {
    if (properties) {
      return Object.fromEntries(Object.entries(properties).filter(([, propValue]) => propValue !== undefined));
    }
    return properties;
  };

  const wrappedObjectStore = objectStore
    .interceptUpdate(() => (id, properties) => {
      pendingOperations.push({ id, properties: sanitizeProperties(properties) });
      return null;
    });

  return {
    wrappedObjectStore,
    updateObjectAndTimeseries: (id: string | string[], properties: Record<string, unknown> | null | undefined, timeseries: Record<string, TimeseriesOperation> | undefined) => {
      pendingOperations.push({ id, properties: sanitizeProperties(properties), timeseries });
    },
    getPendingOperations: () => pendingOperations,
    applyNextPendingOperation: () => {
      const pendingOperation = pendingOperations.shift();
      if (!pendingOperation) {
        throw newError('No pending operation available');
      }

      const { id, properties, timeseries } = pendingOperation;
      if (properties !== undefined) {
        businessRulesLibrary.onObjectUpdate(id, properties);
        rollbackEvent.push({ id, properties: objectStore.updateObject(id, properties) });
      }
      return { id, properties, timeseries };
    },
    commit: () => rollbackEvent.splice(0, rollbackEvent.length),
    rollback: () => {
      pendingOperations.splice(0, pendingOperations.length);
      rollbackEvent.reverse().forEach(({ id, properties }) => {
        if (properties !== undefined) {
          objectStore.updateObject(id, properties);
        }
      });
      rollbackEvent.splice(0, rollbackEvent.length);
    },
  };
};

interface ValidationAccumulator {
  onAccepted: (accepted: boolean) => void,
  onStatus: (status: ValidationStatus) => void,
  isValidated: () => boolean,
}

const createValidationAccumulator = (strict: boolean): ValidationAccumulator => {
  let countAccepted = 0;
  let countRejected = 0;

  const onAccepted: ValidationAccumulator['onAccepted'] = (accepted) => {
    if (accepted) {
      countAccepted += 1;
    } else {
      countRejected += 1;
    }
  };

  return {
    onAccepted,
    onStatus: (status) => onAccepted(status === ValidationStatus.ACCEPTED),
    isValidated: () => (!strict || countAccepted > 0) && countRejected === 0,
  };
};

export interface CanPerformObjectActionsWithAudit {
  (action: string, origin: EventOrigin, id: string[], newChanges?: NewChanges): (AccessControlListResolutionResult | undefined),
}

export interface CanPerformObjectActions {
  (action: string, origin: EventOrigin, id: string[], newChanges?: NewChanges): boolean | undefined,
}

export interface CanPerformObjectActionsWithCache {
  canPerformObjectActions: CanPerformObjectActions,
  resetCache: () => void,
}

export interface CanPerformObjectActionsWithCacheAndAudit {
  canPerformObjectActions: CanPerformObjectActionsWithAudit,
  resetCache: () => void,
}

export interface CanReadObject {
  canReadObject: (id: string[]) => boolean,
  resetCache: () => void,
}

const doCanPerformObjectActions = (
  accessControlListLibrary: AccessControlListLibrary,
  resolvePermission: string[],
  action: string,
  origin: EventOrigin,
  objectId: string[],
  newChanges: NewChanges = {}
): AccessControlListResolutionResult | undefined => {
  const { newProperties } = newChanges;
  const resolutionKey = `${objectId.join('|')}_${action}`;
  if (resolvePermission.includes(resolutionKey)) {
    return {
      objects: { '*': { rule: 'resolution.loop', status: ValidationStatus.REJECTED } },
      validated: false,
    };
  }
  resolvePermission.push(resolutionKey);

  const validationTuples: [string, AccessControlListObjectResolutionResult][] = accessControlListLibrary
    .getObjectACLForOperation(action, { id: objectId, properties: newProperties })
    .map(({ ruleKeyId, actionHandler }) => {
      const result = actionHandler(
        origin,
        objectId,
        newChanges,
        (a, id, o = origin) => doCanPerformObjectActions(
          accessControlListLibrary,
          [...resolvePermission],
          a,
          o,
          Array.isArray(id) ? id : [id],
          id === objectId ? newChanges : {}
        )?.validated
      );
      if (!result) {
        return undefined;
      } else if (result.status === ValidationStatus.DELEGATED) {
        if (!result.targetId || !result.targetAction) {
          throw newError('Invalid delegation state');
        }
        const sameId = objectId.length === result.targetId.length && result.targetId.every((id, index) => objectId[index] === id);
        const delegatedResult = doCanPerformObjectActions(accessControlListLibrary, resolvePermission, result.targetAction, origin, result.targetId, sameId ? newChanges : {});
        if (!delegatedResult) {
          return undefined;
        }
        const resultWithDelegated: AccessControlListObjectResolutionResult = {
          rule: result.rule,
          targetAction: result.targetAction,
          targetId: result.targetId,
          status: delegatedResult.validated ? ValidationStatus.ACCEPTED : ValidationStatus.REJECTED,
          delegatedResult,
        };
        return [ruleKeyId, resultWithDelegated];
      }
      return [ruleKeyId, result];
    })
    .filter((result): result is [string, AccessControlListObjectResolutionResult] => Boolean(result));

  if (validationTuples.length === 0) {
    return undefined;
  }
  return {
    objects: Object.fromEntries(validationTuples),
    validated: !validationTuples.some(([, { status }]) => ValidationStatus.ACCEPTED !== status),
  };
};

export const buildCanPerformObjectActionsWithAudit = (accessControlListLibrary: AccessControlListLibrary): CanPerformObjectActionsWithCacheAndAudit => {
  const resolutionCache: Map<string, AccessControlListResolutionResult | undefined> = new Map<string, AccessControlListResolutionResult | undefined>();

  return {
    canPerformObjectActions: (action, origin, objectId, newChanges) => {
      const resolutionCacheKey = origin.userId ? `${objectId.join('|')}_${action}_${origin.userId}` : `${objectId.join('|')}_${action}`;
      if (resolutionCache.has(resolutionCacheKey)) {
        return resolutionCache.get(resolutionCacheKey);
      }

      const result = doCanPerformObjectActions(accessControlListLibrary, [], action, origin, objectId, newChanges);
      resolutionCache.set(resolutionCacheKey, result);
      return result;
    },
    resetCache: () => {
      resolutionCache.clear();
    },
  };
};

export const buildCanPerformObjectActions = (accessControlListLibrary: AccessControlListLibrary): CanPerformObjectActionsWithCache => {
  const resolutionCache: Map<string, boolean | undefined> = new Map<string, boolean | undefined>();

  return {
    canPerformObjectActions: (action, origin, objectId, newChanges) => {
      const resolutionCacheKey = origin.userId ? `${objectId.join('|')}_${action}_${origin.userId}` : `${objectId.join('|')}_${action}`;
      if (resolutionCache.has(resolutionCacheKey)) {
        return resolutionCache.get(resolutionCacheKey);
      }

      const result = doCanPerformObjectActions(accessControlListLibrary, [], action, origin, objectId, newChanges)?.validated;
      resolutionCache.set(resolutionCacheKey, result);
      return result;
    },
    resetCache: () => {
      resolutionCache.clear();
    },
  };
};

export const buildCanReadObject = (getObjectReadHandlers: (objectId: string[]) => AccessControlListHandler[], origin: EventOrigin): CanReadObject => {
  enum CacheValue {
    readAllowed = 'readAllowed',
    readRejected = 'readRejected',
    readNeutral = 'readNeutral',
    loading = 'loading',
  }

  type CacheResult = Exclude<CacheValue, CacheValue.loading>;

  const cache: Map<string, CacheValue> = new Map();

  const canReadObject = (objectId: string[]): CacheResult => {
    const cacheKey = objectId.join('|');
    const cached = cache.get(cacheKey);
    if (cached === CacheValue.loading) {
      throw newError('Recursive canReadObject', { cacheKey });
    } else if (cached !== undefined) {
      return cached;
    }
    cache.set(cacheKey, CacheValue.loading);

    let canReadResult: CacheResult = CacheValue.readNeutral;
    const handlers = getObjectReadHandlers(objectId);
    for (let i = 0; canReadResult !== CacheValue.readRejected && i < handlers.length; i += 1) {
      const result = handlers[i](
        origin,
        objectId,
        {},
        (a, id, o = origin) => {
          if (a !== 'READ' && o !== origin) {
            throw newError('Invalid checkAcl state');
          }
          const checkAclResult = canReadObject(encodeId(id));
          return checkAclResult === CacheValue.readNeutral ? undefined : checkAclResult === CacheValue.readAllowed;
        }
      );
      if (result) {
        const { status } = result;
        if (status === ValidationStatus.DELEGATED) {
          if (result.targetAction !== 'READ') {
            throw newError('Invalid delegation state');
          }
          const delegatedResult = canReadObject(result.targetId);
          if (delegatedResult !== CacheValue.readNeutral) {
            canReadResult = delegatedResult;
          }
        } else {
          canReadResult = status === ValidationStatus.ACCEPTED ? CacheValue.readAllowed : CacheValue.readRejected;
        }
      }
    }

    cache.set(cacheKey, canReadResult);
    return canReadResult;
  };

  return {
    canReadObject: (id) => canReadObject(id) === CacheValue.readAllowed,
    resetCache: () => {
      cache.clear();
    },
  };
};

interface GarbageCollectorObjectUpdate {
  (operation: Operation<string | string[]>): {
    generateGarbageSystemEvent: GenerateSystemEvent,
    cascadingGarbageCollectorRules: { ruleName: string, objectIds: (string | string[])[] }[],
  },
}

export const buildGarbageCollectorObjectUpdate = (garbageCollectorRulesLibrary: GarbageCollectorRulesLibrary): GarbageCollectorObjectUpdate => (operation) => {
  const cascadingGarbageCollectorRules = garbageCollectorRulesLibrary.collect(operation);
  const generateGarbageSystemEvent = (store: ObjectStore) => {
    cascadingGarbageCollectorRules.forEach(({ objectIds, shouldDelete }) => {
      objectIds
        .filter((objectId) => Boolean(store.getObjectOrNull(objectId)) && shouldDelete(objectId))
        .forEach((objectId) => store.deleteObject(objectId));
    });
  };
  return { generateGarbageSystemEvent, cascadingGarbageCollectorRules };
};

interface ValidateObjectUpdate {
  (
    origin: EventOrigin,
    executionContext: ExecutionContext,
    eventValidation: ValidationAccumulator,
    operation: Operation<string | string[]>
  ): {
    objectValidation: {
      id: string[],
      objects: { [objectId: string]: { ACCEPTED?: { rule: string }[], REJECTED?: { rule: string, errorMessage?: string, errorStack?: string[] }[], validated: boolean } },
      properties: { [propertyId: string]: { ACCEPTED?: { rule: string }[], REJECTED?: { rule: string, errorMessage?: string, errorStack?: string[] }[], validated: boolean } },
      validated: boolean,
    },
    toGenerateSystemEvents: GenerateSystemEvent[],
  },
}

export const buildValidateObjectUpdate = (objectStore: ObjectStore, businessRulesLibrary: BusinessRulesLibrary): ValidateObjectUpdate => {
  const { getObjectOrNull, objectEntries } = objectStore;

  const validateObjectBusinessRules = (
    origin: EventOrigin,
    executionContext: ExecutionContext,
    operation: Operation<string | string[]>
  ): { objectId: string, results: BusinessRuleResult[] }[] => {
    const checkersForOperation = businessRulesLibrary.getObjectBusinessRulesForOperation(operation);
    return Object.entries(checkersForOperation).map(([objectId, checkers]) => {
      const results: BusinessRuleResult[] = [];
      if (origin.source === OriginSources.MIGRATION) {
        results.push({ rule: 'source.migration', status: ValidationStatus.ACCEPTED });
      } else {
        results.push(...checkers.flatMap(
          (checker): BusinessRuleResult | BusinessRuleResult[] | undefined => {
            try {
              return checker(origin, { ...operation, id: encodeId(operation.id) }, executionContext);
            } catch (e) {
              if (e instanceof Error) {
                const objectError = errorToObject(e);
                return {
                  rule: 'businessRule.execution.error',
                  status: ValidationStatus.REJECTED,
                  errorMessage: objectError.message,
                  errorStack: objectError.stacktrace,
                };
              } else {
                return { rule: 'businessRule.execution.error', status: ValidationStatus.REJECTED, errorMessage: 'Unknown error' };
              }
            }
          }
        ).filter(filterNullOrUndefined));
      }
      return { objectId, results };
    });
  };

  const validateObjectProperties = (
    origin: EventOrigin,
    executionContext: ExecutionContext,
    operation: Operation<string | string[]>,
    propertiesIds: string[]
  ): { propertyId: string, results: BusinessRuleResult[] }[] => (
    propertiesIds.map((propertyId) => {
      const results = [];
      if (origin.source === OriginSources.MIGRATION) {
        results.push({ rule: 'source.migration', status: ValidationStatus.ACCEPTED });
      } else {
        results.push(...(businessRulesLibrary.property(propertyId)).map((checker) => {
          try {
            return checker(origin, { ...operation, id: encodeId(operation.id) }, executionContext);
          } catch (e) {
            return {
              status: ValidationStatus.REJECTED,
              error: errorToObject(e),
            };
          }
        })
          .filter((r): r is BusinessRuleResult[] | BusinessRuleResult => Boolean(r))
          .flat());
      }
      return { propertyId, results };
    }));

  return (origin, executionContext, eventValidation, operation) => {
    const objectValidation: ReturnType<ValidateObjectUpdate>['objectValidation'] = { id: encodeId(operation.id), objects: {}, properties: {}, validated: false };
    const overallValidationAccumulator = createValidationAccumulator(true);

    const currentObject = getObjectOrNull(operation.id);

    // delete is equivalent to update all existing properties to null
    let propertiesIds: string[];
    if (operation.properties || operation.timeseries) {
      propertiesIds = [...(operation.properties ? Object.keys(operation.properties) : []), ...(operation.timeseries ? Object.keys(operation.timeseries) : [])];
    } else if (currentObject) {
      propertiesIds = objectEntries(currentObject).map(([propertyId]) => propertyId);
    } else {
      propertiesIds = [];
    }

    // We don't need all objects to be validated, we just want to make sure one of them was validated
    const objectsBusinessRuleValidationAccumulator = createValidationAccumulator(true);
    const toGenerateSystemEvents: GenerateSystemEvent[] = [];
    const businessRulesValidationResults = validateObjectBusinessRules(origin, executionContext, operation);
    businessRulesValidationResults.forEach(({ objectId, results }) => {
      const objectIdValidationAccumulator = createValidationAccumulator(false);
      const accepted: { rule: string }[] = [];
      const rejected: { rule: string, errorMessage?: string, errorStack?: string[] }[] = [];

      results.forEach(({ status, rule, errorMessage, errorStack, generateSystemEvent }) => {
        objectIdValidationAccumulator.onStatus(status);
        if (status === ValidationStatus.ACCEPTED) {
          if (generateSystemEvent) {
            toGenerateSystemEvents.push(generateSystemEvent);
          }
          accepted.push({ rule });
        } else {
          rejected.push({ rule, errorMessage, errorStack });
        }
      });

      const isAccepted = objectIdValidationAccumulator.isValidated();
      objectValidation.objects[objectId] = {
        [ValidationStatus.ACCEPTED]: accepted.length > 0 ? accepted : undefined,
        [ValidationStatus.REJECTED]: rejected.length > 0 ? rejected : undefined,
        validated: isAccepted,
      };
      overallValidationAccumulator.onAccepted(isAccepted);
      objectsBusinessRuleValidationAccumulator.onAccepted(isAccepted);
    });
    overallValidationAccumulator.onAccepted(objectsBusinessRuleValidationAccumulator.isValidated());
    const propertiesValidationResultsFromObject = businessRulesValidationResults
      .flatMap((validation) => validation.results)
      .filter((result) => Boolean(result.propertyIds))
      .reduce((accumulator, result) => {
        result.propertyIds?.forEach((propertyId) => {
          const propertyEntry = accumulator.find(({ propertyId: knownId }) => knownId === propertyId);
          if (propertyEntry) {
            propertyEntry.results.push(result);
          } else {
            accumulator.push({ propertyId, results: [result] });
          }
        });
        return accumulator;
      }, ([] as { propertyId: string, results: BusinessRuleResult[] }[]));
    const propertiesValidationResults = validateObjectProperties(origin, executionContext, operation, propertiesIds);
    [...propertiesValidationResults, ...propertiesValidationResultsFromObject]
      .reduce((accumulator, { propertyId, results }) => {
        const propertyEntry = accumulator.find(({ propertyId: knownId }) => knownId === propertyId);
        if (propertyEntry) {
          propertyEntry.results.push(...results);
        } else {
          accumulator.push({ propertyId, results: [...results] });
        }
        return accumulator;
      }, ([] as { propertyId: string, results: BusinessRuleResult[] }[]))
      .forEach(({ propertyId, results }) => {
        const propertyValidationAccumulator = createValidationAccumulator(true);
        const accepted: { rule: string }[] = [];
        const rejected: { rule: string, errorMessage?: string, errorStack?: string[] }[] = [];
        results.forEach((r) => {
          const { status, rule, errorMessage, errorStack, generateSystemEvent } = r;
          propertyValidationAccumulator.onStatus(status);
          if (status === ValidationStatus.ACCEPTED) {
            if (generateSystemEvent) {
              toGenerateSystemEvents.push(generateSystemEvent);
            }
            accepted.push({ rule });
          } else {
            rejected.push({ rule, errorMessage, errorStack });
          }
        });

        const isAccepted = propertyValidationAccumulator.isValidated();
        objectValidation.properties[propertyId] = {
          [ValidationStatus.ACCEPTED]: accepted.length > 0 ? accepted : undefined,
          [ValidationStatus.REJECTED]: rejected.length > 0 ? rejected : undefined,
          validated: isAccepted,
        };
        overallValidationAccumulator.onAccepted(isAccepted);
      });

    const isObjectUpdateAccepted = overallValidationAccumulator.isValidated();

    objectValidation.validated = isObjectUpdateAccepted;
    eventValidation.onAccepted(isObjectUpdateAccepted);
    return { objectValidation, toGenerateSystemEvents };
  };
};

export interface EventValidatorResult {
  status: ProcessedEventStatus,
  event: PendingOperation[],
  rollbackEvent: PendingOperation[],
  audit: AuditEvent['audit'],
  metadata: Record<string, unknown>,
}

export interface OperationValidationTransaction {
  validateOperation: (id: string | string[], properties: Record<string, unknown> | null | undefined, timeseries: Record<string, TimeseriesOperation> | undefined) => boolean,
  completeTransaction: () => EventValidatorResult,
}

export interface EventValidator {
  validateEvent: (event: PendingOperation[], origin: EventOrigin, executionContext: ExecutionContext) => EventValidatorResult,
  asyncValidateEvent: (event: PendingOperation[], origin: EventOrigin, executionContext: ExecutionContext) => Promise<EventValidatorResult>,
  createOperationValidationTransaction: (origin: EventOrigin, executionContext: ExecutionContext) => OperationValidationTransaction,
}

export const createEventValidator = (
  objectStore: RawObjectStore,
  businessRulesLibrary: BusinessRulesLibrary,
  garbageCollectorRulesLibrary?: GarbageCollectorRulesLibrary,
  accessControlListLibrary?: AccessControlListLibrary,
  metadataGeneratorLibrary?: MetadataGeneratorLibrary
): EventValidator => {
  const createOperationValidationTransaction: EventValidator['createOperationValidationTransaction'] = (origin, executionContext) => {
    const { wrappedObjectStore, ...operationAccumulator } = createOperationAccumulator(objectStore, businessRulesLibrary);
    const validateObjectUpdate = buildValidateObjectUpdate(wrappedObjectStore, businessRulesLibrary);
    const garbageCollectorObjectUpdate = garbageCollectorRulesLibrary ? buildGarbageCollectorObjectUpdate(garbageCollectorRulesLibrary) : undefined;

    const validationResult: EventValidatorResult['audit'] = [];
    const eventValidation = createValidationAccumulator(true);
    const event: PendingOperation[] = [];

    const metadataInterceptors: ReturnType<MetadataGeneratorHandler>['interceptUpdate'][] = [];
    const metadataGenerators: [string, ReturnType<MetadataGeneratorHandler>['generate']][] = [];
    Object.entries(metadataGeneratorLibrary ?? {}).forEach(([key, handler]) => {
      const { interceptUpdate, generate } = handler();
      metadataInterceptors.push(interceptUpdate);
      metadataGenerators.push([key, generate]);
    });

    const validateOperation: OperationValidationTransaction['validateOperation'] = (id, properties, timeseries) => {
      operationAccumulator.updateObjectAndTimeseries(id, properties, timeseries);
      let systemEvent = false; // Index 0 is always the user event object
      const pendingOperations = operationAccumulator.getPendingOperations();
      let i = 0;
      while (pendingOperations.length > 0) {
        const pendingOperation = pendingOperations[0];
        const isSystemEvent = systemEvent;
        try {
          if (timeseries !== undefined) {
            Object.values(timeseries).forEach((value) => {
              if (!isTimeseriesOperation(value)) {
                throw newError('Invalid timeseries operation format', { timeseries });
              }
            });
          }
          const { objectValidation, toGenerateSystemEvents } = validateObjectUpdate(
            isSystemEvent ? { ...origin, source: OriginSources.SYSTEM } : origin,
            executionContext,
            eventValidation,
            pendingOperation
          );
          const toGenerateGarbageSystemEvents = garbageCollectorObjectUpdate?.({
            id: Array.isArray(pendingOperation.id) && pendingOperation.id.length === 1 ? pendingOperation.id[0] : pendingOperation.id,
            properties: pendingOperation.properties,
          });

          // Deleting a non-existing object ? Accept
          if (!wrappedObjectStore.getObjectOrNull(pendingOperation.id) && pendingOperation.properties === null) {
            eventValidation.onAccepted(true);
          } else {
            let aclResult;
            if (
              accessControlListLibrary
              && !isSystemEvent
              && [
                OriginSources.MIGRATION, OriginSources.GARBAGE, OriginSources.AUTHENTICATION, OriginSources.USER_SERVICE, OriginSources.AUTOMATION,
              ].indexOf(origin.source as OriginSources) === -1
            ) {
              const { canPerformObjectActions } = buildCanPerformObjectActionsWithAudit(accessControlListLibrary);
              aclResult = canPerformObjectActions(pendingOperation.properties !== null ? 'WRITE' : 'DELETE', origin, encodeId(pendingOperation.id), {
                newProperties: pendingOperation.properties,
                newTimeseries: pendingOperation.timeseries,
              });

              if (!aclResult) {
                aclResult = { objects: { '*': { status: 'REJECTED', rule: 'noAclForType' } }, validated: false };
                eventValidation.onAccepted(false);
              } else {
                eventValidation.onAccepted(aclResult.validated);
              }
            }

            let gcRules: { [rule: string]: { objectIds: (string | string[])[] } } | undefined;
            if (toGenerateGarbageSystemEvents?.cascadingGarbageCollectorRules) {
              gcRules = Object.fromEntries(
                toGenerateGarbageSystemEvents?.cascadingGarbageCollectorRules
                  .map(({ ruleName, objectIds }) => [ruleName, { objectIds }])
              );
            }

            validationResult.push({
              id: objectValidation.id,
              acl: aclResult,
              br: { properties: objectValidation.properties, objects: objectValidation.objects },
              gc: gcRules ? { rules: gcRules } : undefined,
            });
          }

          metadataInterceptors.forEach((interceptUpdate) => interceptUpdate(encodeId(pendingOperation.id), {
            newProperties: pendingOperation.properties,
            newTimeseries: pendingOperation.timeseries,
          }));

          const appliedOperation = operationAccumulator.applyNextPendingOperation();

          const nbrOfPendingOperationsBeforeSystemEventGeneration = pendingOperations.length;

          toGenerateSystemEvents.forEach((generateSystemEvent) => generateSystemEvent(wrappedObjectStore));
          toGenerateGarbageSystemEvents?.generateGarbageSystemEvent?.(wrappedObjectStore);

          // If we added a system event to the list then increment the counter
          if (pendingOperations.length > nbrOfPendingOperationsBeforeSystemEventGeneration) {
            i += 1;
          }
          if (appliedOperation) {
            event.push(
              { id: appliedOperation.id, properties: appliedOperation.properties, timeseries: appliedOperation.timeseries, systemEvent: isSystemEvent }
            );
          }
          systemEvent = true;
          if (i > 100) {
            throw newError('Infinite event loop');
          }
        } catch (e) {
          eventValidation.onAccepted(false);
          validationResult.push({
            id: encodeId(pendingOperation.id),
            error: errorToObject(e),
          });
          return false;
        }
      }
      return eventValidation.isValidated();
    };

    return {
      validateOperation,
      completeTransaction: () => {
        const metadata: Record<string, unknown> = {};
        try {
          if (eventValidation.isValidated()) {
            metadataGenerators.forEach(([key, generate]) => {
              const value = generate();
              if (value) {
                metadata[key] = value;
              }
            });
          }
        } catch (e) {
          eventValidation.onAccepted(false);
          validationResult.push({
            id: ['metadataGenerator'],
            metadataGeneratorsKeys: metadataGenerators.map(([key]) => key),
            error: errorToObject(e),
          });
        }

        const rollbackEvent = [];
        if (eventValidation.isValidated()) {
          const pendingOperations = operationAccumulator.commit();
          for (let i = 0; i < pendingOperations.length; i += 1) {
            const pendingOperation = pendingOperations[i];
            rollbackEvent.push(pendingOperation);
          }
        } else {
          operationAccumulator.rollback();
        }

        return {
          status: (eventValidation.isValidated() ? ProcessedEventStatus.validated : ProcessedEventStatus.rejected),
          event,
          rollbackEvent,
          audit: validationResult,
          metadata,
        };
      },
    };
  };

  const validateEvent: EventValidator['validateEvent'] = (event, origin, executionContext) => {
    try {
      const { validateOperation, completeTransaction } = createOperationValidationTransaction(origin, executionContext);
      event.every(({ id, properties, timeseries }) => validateOperation(id, properties, timeseries));
      return completeTransaction();
    } catch (e) {
      return { status: ProcessedEventStatus.rejected, event, rollbackEvent: [], audit: [{ error: errorToObject(e) }], metadata: {} };
    }
  };

  const asyncValidateEvent: EventValidator['asyncValidateEvent'] = async (event, origin, executionContext) => {
    try {
      const { validateOperation, completeTransaction } = createOperationValidationTransaction(origin, executionContext);
      for (let i = 0; i < event.length; i += 1) {
        await doYield();
        const { id, properties, timeseries } = event[i];
        if (!validateOperation(id, properties, timeseries)) {
          return completeTransaction();
        }
      }
      return completeTransaction();
    } catch (e) {
      return { status: ProcessedEventStatus.rejected, event, rollbackEvent: [], audit: [{ error: errorToObject(e) }], metadata: {} };
    }
  };
  return { validateEvent, asyncValidateEvent, createOperationValidationTransaction };
};
