import type { Classes, Styles } from 'jss';
import jss from 'jss';
import camelCase from 'jss-plugin-camel-case';
import defaultUnit from 'jss-plugin-default-unit';
import global from 'jss-plugin-global';
import nested from 'jss-plugin-nested';
import propsSort from 'jss-plugin-props-sort';
import vendorPrefixer from 'jss-plugin-vendor-prefixer';
import appliedTheme from '../theme/appliedTheme';
import type { Theme } from '../theme/themeUtils';

let counter = 0;

jss.setup({
  plugins: [
    global(),
    nested(),
    camelCase(),
    defaultUnit(),
    vendorPrefixer(),
    propsSort(),
  ],
  createGenerateId: () => (rule) => {
    counter += 1;
    if (rule.options.sheet?.options.classNamePrefix) {
      return `yooi-${rule.options.sheet.options.classNamePrefix}-${rule.key}-${counter}`;
    } else {
      return `yooi-${rule.key}-${counter}`;
    }
  },
});

interface MakeStyles {
  <Name extends string>(styles: Styles<Name> | ((theme: Theme) => Styles<Name>), prefix?: string): () => Classes<Name>,
}

const styleSheets: string[] = [];

export const attachStyles = (): void => {
  if (styleSheets.length > 0) {
    const styles = styleSheets.join('\n');
    styleSheets.splice(0);

    const styleElement = document.createElement('style');
    styleElement.setAttribute('data-meta', 'yooi');
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);
  }
};

if (import.meta.hot) {
  // handle HMR update on dev
  import.meta.hot.on('vite:afterUpdate', () => attachStyles());
}

// eslint-disable-next-line yooi/check-classname-attribute
const makeStyles: MakeStyles = (styles, prefix) => {
  if (typeof styles === 'function') {
    const currentStyleSheet = jss.createStyleSheet(styles(appliedTheme), { classNamePrefix: prefix });
    styleSheets.push(currentStyleSheet.toString());
    const lightClasses = currentStyleSheet.classes;

    return () => lightClasses;
  } else {
    const styleSheet = jss.createStyleSheet(styles, { classNamePrefix: prefix });
    styleSheets.push(styleSheet.toString());
    const noThemeClasses = styleSheet.classes;
    return () => noThemeClasses;
  }
};

export default makeStyles;
