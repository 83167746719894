import type { FunctionComponent } from 'react';
import { DndProvider } from 'react-dnd';
import useResizeObserver from 'use-resize-observer';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { SwimlaneViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import useDeepMemo from '../../../../utils/useDeepMemo';
import { SessionStorageKeys } from '../../../../utils/useSessionStorage';
import { dndManager } from '../../fields/_global/dndUtils';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { useViewFilters } from '../../filter/useViewFilters';
import BlockViewError from '../common/BlockViewError';
import { getViewDefinitionHandler } from '../viewDsl';
import { isResolutionError } from '../viewResolutionUtils';
import ViewConceptSwimlane from './internal/ViewConceptSwimlane';
import type { SwimlaneViewDefinitionHandler } from './swimlaneViewDefinitionHandler';

const useStyles = makeStyles((theme) => ({
  paddedContainer: {
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
    paddingLeft: spacingRem.s,
  },
  container: {
    marginBottom: spacingRem.blockSpacing,
    paddingBottom: spacingRem.xs,
    borderRadius: '0.4rem',
    background: theme.color.background.neutral.default,
  },
}), 'swimlaneViewBlock');

interface SwimlaneViewBlockProps {
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinition: SwimlaneViewStoredDefinition,
  parametersMapping: ParametersMapping,
  readOnly: boolean | undefined,
}

const SwimlaneViewBlock: FunctionComponent<SwimlaneViewBlockProps> = ({
  viewDimensions,
  viewFilters,
  viewDefinition,
  parametersMapping,
  readOnly,
}) => {
  const classes = useStyles();

  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();

  const viewHandler = getViewDefinitionHandler(viewDefinition) as SwimlaneViewDefinitionHandler;
  const viewResolvedDefinition = viewHandler.getDefinition(store, viewDimensions);
  const filterConfiguration = useViewFilters(viewFilters, viewDimensions);
  const viewResolution = useDeepMemo(
    () => viewHandler.resolveView(store, { viewDimensions, aclHandler, parametersMapping, userId: loggedUserId, filterConfiguration, readOnly }),
    [store.getSerial(), viewDimensions, viewDefinition, parametersMapping, filterConfiguration]
  );

  const { filterKey } = viewFilters;
  const configurationKey = `${SessionStorageKeys.swimlaneConfig}_${viewDefinition.id}`;

  const { ref: containerRef, width: containerWidthPx } = useResizeObserver();

  if (isResolutionError(viewResolution)) {
    return <BlockViewError error={viewResolution.error} />;
  } else {
    return (
      <div className={classes.paddedContainer}>
        <div className={classes.container} ref={containerRef}>
          <DndProvider manager={dndManager}>
            {containerWidthPx ? (
              <ViewConceptSwimlane
                viewDefinition={viewResolvedDefinition}
                viewResolution={viewResolution}
                viewDimensions={viewDimensions}
                parametersMapping={parametersMapping}
                readOnly={viewDefinition.readOnly || readOnly}
                filterKey={filterKey}
                configurationKey={configurationKey}
                width={containerWidthPx}
                viewFilters={viewFilters}
              />
            ) : null}
          </DndProvider>
        </div>
      </div>
    );
  }
};

export default SwimlaneViewBlock;
