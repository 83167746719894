import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import type { IntegrationStoreObject } from 'yooi-modules/modules/integrationModule';
import { Integration, Integration_Name, Integration_Template } from 'yooi-modules/modules/integrationModule/ids';
import type { PlatformConfigurationStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import { CurrentPlatformConfiguration, PlatformConfiguration_IntegrationDefaultExpiration } from 'yooi-modules/modules/platformConfigurationModule/ids';
import { Template_TemplateConceptDefinitions } from 'yooi-modules/modules/templateModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import { forgetAsyncPromise, getFormattedTextDateByPeriod, joinObjects, periodicities, PeriodicityType } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../components/atoms/Button';
import Icon, { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import Tooltip from '../../../components/atoms/Tooltip';
import Typo, { TypoVariant } from '../../../components/atoms/Typo';
import type { DateInputValue } from '../../../components/inputs/datePickers/DateInput';
import DateInput from '../../../components/inputs/datePickers/DateInput';
import SimpleInput from '../../../components/inputs/strategy/SimpleInput';
import Banner, { BannerVariant } from '../../../components/molecules/Banner';
import Chooser from '../../../components/molecules/Chooser';
import ConfirmationModal, { ConfirmationModalVariant } from '../../../components/molecules/ConfirmationModal';
import MasterDetailList from '../../../components/molecules/MasterDetailList';
import OverflowMenu from '../../../components/molecules/OverflowMenu';
import SpacingLine from '../../../components/molecules/SpacingLine';
import Spinner, { SpinnerVariant } from '../../../components/molecules/Spinner';
import { TableSortDirection } from '../../../components/molecules/Table';
import BaseLayout from '../../../components/templates/BaseLayout';
import Header from '../../../components/templates/Header';
import HeaderTabs from '../../../components/templates/HeaderTabs';
import LeftPanel from '../../../components/templates/LeftPanel';
import useStore from '../../../store/useStore';
import { Opacity } from '../../../theme/base';
import { generateColorFromOpacity } from '../../../theme/colorUtils';
import { getSpacing, getSpacingAsNumber, Spacing, spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { notifyError, notifySuccess } from '../../../utils/notify';
import { hasFeature } from '../../../utils/options';
import { safeSessionStorageValue } from '../../../utils/sessionStorageUtils';
import { formatOrUndef } from '../../../utils/stringUtils';
import useDerivedState from '../../../utils/useDerivedState';
import { useFetchJSON, useFetchJSONFunction } from '../../../utils/useFetchJSON';
import useNavigation, { NavigationElementContainer } from '../../../utils/useNavigation';
import useTheme from '../../../utils/useTheme';
import type { FilterConfiguration } from '../../_global/filter/useFilterSessionStorage';
import HeaderFromStore from '../../_global/HeaderFromStore';
import { searchFilterFunction } from '../../_global/listFilterFunctions';
import type { NavigationFilter } from '../../_global/navigationUtils';
import { NavigationTitlePathElements } from '../../_global/navigationUtils';
import TopBar from '../../_global/topBar/TopBar';
import useFilterAndSort, { buildStringColumnComparatorHandler } from '../../_global/useFilterAndSort';
import IntegrationDetailConfigurationTab from './IntegrationDetailConfigurationTab';
import IntegrationDetailMappingTab from './IntegrationDetailMappingTab';
import IntegrationDetailPermissionTab from './IntegrationDetailPermissionTab';
import IntegrationDetailStakeholdersTab from './IntegrationDetailStakeholdersTab';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingRem.s,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingRem.s,
  },
  separation: {
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
    marginTop: getSpacing(Spacing.l, -getSpacingAsNumber(Spacing.blockSpacing)),
    marginBottom: getSpacing(Spacing.l, -getSpacingAsNumber(Spacing.blockSpacing)),
    marginLeft: getSpacing(Spacing.s, 0.1 /* border */),
    gridColumnStart: 2,
  },
}), 'integrationDetailPage');

interface IntegrationDetailPageProps {
  integrationId: string,
}

const IntegrationDetailPage: FunctionComponent<IntegrationDetailPageProps> = ({ integrationId }) => {
  const store = useStore();
  const classes = useStyles();

  const navigation = useNavigation<NavigationFilter>();
  const location = useLocation();
  const theme = useTheme();

  const integration = store.getObject<IntegrationStoreObject>(integrationId);

  const [tempToken, setTempToken] = useDerivedState(() => '', [integrationId]);
  const [activeModal, setActiveModal] = useState<string>();
  const [tokenExpiration, setTokenExpiration] = useState<{ type: 'default' | 'custom' | 'none', date?: number }>({ type: 'default' });

  const defaultExpirationInDay = store.getObject<PlatformConfigurationStoreObject>(CurrentPlatformConfiguration)[PlatformConfiguration_IntegrationDefaultExpiration];

  const [
    { loading: generateApiTokenLoading }, generateApiTokenCall,
  ] = useFetchJSONFunction<{ status: 200, response: { token: string } } | { status: 400, response: { error: string } }>(`/platform/integration/${integrationId}/token`);

  let expirationDate: number | undefined;
  switch (tokenExpiration.type) {
    case 'default':
      expirationDate = periodicities[PeriodicityType.day].getNextDateInAmountOfPeriod(new Date(), defaultExpirationInDay).getTime();
      break;
    case 'custom':
      expirationDate = tokenExpiration.date;
      break;
    case 'none':
      break;
  }

  const generateApiToken = () => generateApiTokenCall(
    { method: 'POST', json: expirationDate ? { expirationDate } : undefined },
    ({ status, response }) => {
      if (status === 200) {
        setTempToken(response.token);
        setActiveModal('created');
      } else {
        notifyError(i18n`Error while generating integration token.`);
      }
    },
    () => {
      notifyError(i18n`Error while generating integration token.`);
    }
  );

  const [
    { loading: revokeApiTokenLoading }, revokeApiTokenCall,
  ] = useFetchJSONFunction<{ status: 200, response: { status: 'success' } } | { status: 400, response: { error: string } }>(`/platform/integration/${integrationId}/token`);

  const revokeApiToken = () => revokeApiTokenCall(
    { method: 'DELETE' },
    ({ status }) => {
      if (status === 200) {
        setActiveModal(undefined);
        setTempToken('');
      } else {
        notifyError(i18n`Error while revoking integration token.`);
      }
    },
    () => {
      notifyError(i18n`Error while revoking integration token.`);
    }
  );

  const reGenerateApiToken = () => revokeApiTokenCall(
    { method: 'DELETE' },
    ({ status }) => {
      if (status === 200) {
        setTempToken('');
        generateApiToken();
      } else {
        notifyError(i18n`Error while revoking integration token.`);
      }
    },
    () => {
      notifyError(i18n`Error while revoking integration token.`);
    }
  );

  const { loading: isTokenLoading, value: tokenValue, error: getTokenError } = useFetchJSON<
    { status: 200, response: { hasToken: boolean, isEnabled: boolean, expirationDate: number | undefined } }
    | { status: 400, response: { error: string } }
  >(`/platform/integration/${integrationId}/token`, { deps: [tempToken] });

  let hasToken = false;
  let token: { expirationDate: number | undefined } | undefined;
  if (!generateApiTokenLoading && !getTokenError && tokenValue) {
    if (tokenValue.status === 200) {
      hasToken = tokenValue.response.hasToken;
      if (hasToken) {
        token = { expirationDate: tokenValue.response.expirationDate };
      }
    }
  }

  const configurationOptions: { key: 'default' | 'custom' | 'none', name: string }[] = [{
    key: 'default',
    name: i18n`Default`,
  }, {
    key: 'custom',
    name: i18n`Custom`,
  }, {
    key: 'none',
    name: i18n`None`,
  }];

  const renderTokenGenerationOption = () => (
    <div className={classes.modalContainer}>
      <SpacingLine>
        <Typo variant={TypoVariant.blockInlineTitle} color={theme.color.text.secondary}>{i18n`Expiration`}</Typo>
        <Chooser
          actions={configurationOptions}
          onClick={(index) => {
            const option = configurationOptions[index];
            setTokenExpiration((current) => (joinObjects(current, { type: option.key })));
          }}
          selectedIndexes={[configurationOptions.findIndex(({ key }) => key === tokenExpiration.type)]}
        />
      </SpacingLine>
      {tokenExpiration.type === 'default' && (
        <SpacingLine>
          <Typo>{i18n`This token will expire in ${defaultExpirationInDay} day(s) (${getFormattedTextDateByPeriod(periodicities[PeriodicityType.day].getNextDateInAmountOfPeriod(new Date(), defaultExpirationInDay), PeriodicityType.day)})`}</Typo>
        </SpacingLine>
      )}
      {tokenExpiration.type === 'custom' && (
        <>
          <SpacingLine>
            <Typo>{i18n`This token will expire on:`}</Typo>
          </SpacingLine>
          <SpacingLine>
            <Typo variant={TypoVariant.blockInlineTitle} color={theme.color.text.secondary}>{i18n`Date:`}</Typo>
            <SimpleInput<DateInputValue>
              initialValue={{ period: PeriodicityType.day, date: tokenExpiration.date }}
              onSubmit={(newDate) => {
                setTokenExpiration({ type: 'custom', date: newDate?.date });
              }}
            >
              {(props) => (
                <DateInput
                  {...props}
                  placeholder={i18n`Add a date`}
                  fixedPeriodicity
                  defaultPeriodicity={PeriodicityType.day}
                  error={props.value?.date !== undefined && props.value?.date < Date.now() ? i18n`The selected date has already passed.` : undefined}
                />
              )}
            </SimpleInput>
          </SpacingLine>
        </>
      )}
      {tokenExpiration.type === 'none' && (
        <Banner variant={BannerVariant.warning} title={i18n`For security reasons, it's not recommended creating an integration token without expiration date.`} />
      )}
    </div>
  );

  const modals = [
    {
      action: 'create',
      title: i18n`Token configuration`,
      confirmLabel: i18n`Create`,
      isConfirmDisable: generateApiTokenLoading || (tokenExpiration.type === 'custom' && (tokenExpiration.date === undefined || Date.now() > tokenExpiration.date)),
      onConfirm: forgetAsyncPromise(generateApiToken),
      cancelLabel: i18n`Cancel`,
      cancelButtonVariant: ButtonVariant.secondary,
      isCancelDisable: generateApiTokenLoading,
      render: () => (
        <div className={classes.modalContainer}>
          {renderTokenGenerationOption()}
        </div>
      ),
    },
    {
      action: 'created',
      title: i18n`Your new API token`,
      onConfirm: () => {
        navigator.clipboard.writeText(tempToken);
        notifySuccess(i18n`Copied to clipboard`);
      },
      confirmLabel: i18n`Copy`,
      cancelLabel: i18n`Close`,
      cancelButtonVariant: ButtonVariant.secondary,
      render: () => (
        <div className={classes.modalContainer}>
          <Typo>{i18n`Make sure you copy the token. You won't be able to see it again.`}</Typo>
          <SpacingLine>
            <Typo variant={TypoVariant.code} color={theme.color.text.secondary}>{tempToken}</Typo>
            <IconOnlyButton
              tooltip={i18n`Copy to clipboard`}
              variant={IconOnlyButtonVariants.tertiary}
              iconName={IconName.content_copy_outline}
              onClick={() => {
                navigator.clipboard.writeText(tempToken);
                notifySuccess(i18n`Copied to clipboard`);
              }}
            />
          </SpacingLine>
        </div>
      ),
    },
    {
      action: 'regenerate',
      title: i18n`Regenerate API token`,
      confirmLabel: i18n`Regenerate`,
      confirmButtonVariant: ButtonVariant.secondary,
      isConfirmDisable: revokeApiTokenLoading || generateApiTokenLoading || (tokenExpiration.type === 'custom' && (tokenExpiration.date === undefined || Date.now() > tokenExpiration.date)),
      cancelButtonVariant: ButtonVariant.tertiary,
      isCancelDisable: revokeApiTokenLoading || generateApiTokenLoading,
      onConfirm: forgetAsyncPromise(reGenerateApiToken),
      render: () => (
        <div className={classes.modalContainer}>
          <div className={classes.textContainer}>
            <Typo>{i18n`Are you sure you want to regenerate this API token ?`}</Typo>
            <Typo>{i18n`Entities using the current token will no longer be able to access YOOI.`}</Typo>
          </div>
          <div className={classes.separation} />
          <div>
            {renderTokenGenerationOption()}
          </div>
        </div>
      ),
    },
    {
      action: 'revoke',
      variant: ConfirmationModalVariant.delete,
      title: i18n`Revoke API token`,
      titleIcon: {
        name: IconName.warning,
        color: theme.color.text.danger,
      },
      confirmLabel: i18n`Revoke`,
      isConfirmDisable: revokeApiTokenLoading,
      onConfirm: forgetAsyncPromise(revokeApiToken),
      cancelButtonVariant: ButtonVariant.secondary,
      isCancelDisable: revokeApiTokenLoading,
      render: () => (
        <>
          <Typo>{i18n`Are you sure you want to revoke this API token ? This action is not reversible.`}</Typo>
          <Typo>{i18n`Entities using this token will no longer be able to access YOOI.`}</Typo>
        </>
      ),
    },
  ];

  const activeModalProps = modals.find((modal) => modal.action === activeModal);

  const tabs = [
    {
      key: 'configuration',
      hash: '#configuration',
      name: i18n`Configuration`,
      render: () => (
        <IntegrationDetailConfigurationTab
          integrationId={integrationId}
          tokenData={token}
        />
      ),
    },
  ];

  if ((store.getObject(integrationId).navigateOrNull(Integration_Template)?.navigateBack(Template_TemplateConceptDefinitions)?.length ?? 0) > 0) {
    tabs.push(
      {
        key: 'mapping',
        hash: '#mapping',
        name: i18n`Mapping`,
        render: () => (<IntegrationDetailMappingTab integrationId={integrationId} />),
      }
    );
  }

  if (hasFeature('connectors', 'orange-jira') || location.hash === '#stakeholders') {
    tabs.push({
      key: 'stakeholders',
      hash: '#stakeholders',
      name: i18n`Stakeholders`,
      render: () => (<IntegrationDetailStakeholdersTab integrationId={integrationId} />),
    });
  }

  tabs.push({
    key: 'permission',
    hash: '#permission',
    name: i18n`Permission`,
    render: () => (<IntegrationDetailPermissionTab integrationId={integrationId} />),
  });

  const tabIndex = tabs.findIndex((tab) => tab.hash === location.hash);
  const selectedTabIndex = tabIndex >= 0 ? tabIndex : 0;
  const selectedTab = tabs[selectedTabIndex];
  const filterId = Integration;

  const [search, setSearch] = useState<string | undefined>(undefined);
  const { generateList } = useFilterAndSort(
    filterId,
    store.getObject(Integration).navigateBack<IntegrationStoreObject>(Class_Instances),
    searchFilterFunction(store, [safeSessionStorageValue<FilterConfiguration | undefined>(filterId)?.nameSearch, search], [Integration_Name]),
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case Integration_Name:
            return buildStringColumnComparatorHandler(key, direction);
          default:
            return undefined;
        }
      },
      initial: { key: Integration_Name, direction: TableSortDirection.asc },
    },
    undefined,
    [search]
  );

  let headerAction;
  if (isTokenLoading) {
    headerAction = (
      <Spinner
        key="actions"
        size={SpinnerVariant.small}
        color={generateColorFromOpacity(theme.color.text.brand, theme.color.background.neutral.default, Opacity.fifty)}
      />
    );
  } else if (getTokenError) {
    headerAction = <Icon key="actions" name={IconName.dangerous} colorVariant={IconColorVariant.error} tooltip={i18n`Error while communicating with token service, please reload the page.`} />;
  } else if (hasToken) {
    headerAction = (
      <SpacingLine key="actions">
        <Button
          title={i18n`Regenerate API token`}
          variant={ButtonVariant.secondary}
          onClick={() => {
            setActiveModal('regenerate');
          }}
        />
        <Button
          title={i18n`Revoke API token`}
          variant={ButtonVariant.danger}
          onClick={() => {
            setActiveModal('revoke');
          }}
        />
      </SpacingLine>
    );
  } else {
    headerAction = (
      <Button
        key="actions"
        title={i18n`Create API token`}
        onClick={() => {
          setActiveModal('create');
        }}
      />
    );
  }

  return (
    <NavigationElementContainer
      element={{
        key: integrationId,
        name: formatOrUndef(integration[Integration_Name]),
        element: NavigationTitlePathElements.integration,
        path: `/settings/integration/${integrationId}`,
      }}
    >
      <BaseLayout
        topBar={(<TopBar />)}
        leftPanel={(
          <LeftPanel>
            <MasterDetailList
              list={
                generateList().list
                  .map(({ item, ...entry }) => (
                    joinObjects(
                      entry,
                      {
                        render: () => (
                          <Tooltip title={formatOrUndef(item[Integration_Name])}>
                            <Typo maxLine={1}>{formatOrUndef(item[Integration_Name])}</Typo>
                          </Tooltip>
                        ),
                        to: () => ({ pathname: `/settings/integration/${item.id}`, hash: location.hash }),
                      }
                    )
                  ))
              }
              search={{ value: search, setValue: setSearch }}
            />
          </LeftPanel>
        )}
        header={(
          <Header
            firstLine={(
              <HeaderFromStore
                propertyId={Integration_Name}
                instanceId={integrationId}
                placeholder={i18n`Click to edit name`}
                actions={[
                  headerAction,
                  (
                    activeModalProps ? (
                      <ConfirmationModal
                        key="integrationConfirmationModal"
                        variant={activeModalProps.variant}
                        title={activeModalProps.title}
                        titleIcon={activeModalProps.titleIcon}
                        open={activeModal !== undefined}
                        onConfirm={activeModalProps.onConfirm}
                        confirmLabel={activeModalProps.confirmLabel}
                        confirmButtonVariant={activeModalProps.confirmButtonVariant}
                        cancelLabel={activeModalProps.cancelLabel}
                        cancelButtonVariant={activeModalProps.cancelButtonVariant}
                        onCancel={() => setActiveModal(undefined)}
                        render={activeModalProps.render}
                        isConfirmDisable={activeModalProps.isConfirmDisable}
                        isCancelDisable={activeModalProps.isCancelDisable}
                      />
                    ) : null
                  ),
                  (
                    <OverflowMenu
                      key="overflowMenu"
                      menuItems={[
                        {
                          key: 'delete',
                          name: i18n`Delete`,
                          icon: IconName.delete,
                          onClick: () => {
                            store.deleteObject(integrationId);
                            navigation.replace('integration', { pathname: '/settings/integration' });
                          },
                          danger: true,
                        },
                      ]}
                    />
                  )]}
              />
            )}
            tabsLine={(
              <HeaderTabs
                selectedTabIndex={selectedTabIndex}
                tabs={tabs}
                onSelectedIndexChanged={(index) => {
                  navigation.replace(integrationId, joinObjects(location, { hash: tabs[index].hash }));
                }}
              />
            )}
          />
        )}
        content={selectedTab.render()}
      />
    </NavigationElementContainer>
  );
};
export default IntegrationDetailPage;
