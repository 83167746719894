import type { FieldStep, FilterCondition, Filters, FilterStep, Formula, InstanceReferenceValue, MappingStep, PathStep } from 'yooi-modules/modules/conceptModule';
import {
  FilterValueType,
  InstanceReferenceType,
  isFieldStep,
  isFilterNode,
  isFilterStep,
  isFilterValuePath,
  isFilterValueRaw,
  isMappingStep,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import { joinObjects } from 'yooi-utils';

export const duplicatePathSteps = (path: PathStep[], dimensionsMapping: Record<string, string>): PathStep[] => {
  const getNewFilterCondition = (filter: FilterCondition): FilterCondition => {
    let { rightValue } = filter;
    if (rightValue && isFilterValuePath(rightValue)) {
      const { path: valuePath } = rightValue;
      rightValue = {
        type: FilterValueType.path,
        path: valuePath.map((step) => ((isMappingStep(step) && dimensionsMapping[step.mapping.id])
          ? joinObjects(step, { mapping: { id: dimensionsMapping[step.mapping.id], type: step.mapping.type } })
          : step)),
      };
    } else if (rightValue && isFilterValueRaw(rightValue)) {
      if (Array.isArray(rightValue.raw)) {
        const values = rightValue.raw;
        rightValue = {
          type: FilterValueType.raw,
          raw: values.map((value) => (
            dimensionsMapping[value.id] ? { id: dimensionsMapping[value.id], type: InstanceReferenceType.parameter } : { id: value.id, type: InstanceReferenceType.instance }
          )),
        };
      } else if (
        typeof rightValue.raw === 'object'
        && rightValue.raw !== null
        && (Object.values(InstanceReferenceType) as unknown[]).includes((rightValue.raw as Record<string, unknown>).type)
        && typeof (rightValue.raw as Record<string, unknown>).id === 'string'
      ) {
        const oldValue = rightValue.raw as { type: InstanceReferenceType, id: string };
        rightValue = {
          type: FilterValueType.raw,
          raw: dimensionsMapping[oldValue.id]
            ? { id: dimensionsMapping[oldValue.id], type: InstanceReferenceType.parameter } : { id: oldValue.id, type: InstanceReferenceType.instance },
        };
      }
    }
    return (joinObjects(
      filter,
      { rightValue }
    ));
  };

  const getNewFilters = (filters: Filters): Filters => {
    if (isFilterNode(filters)) {
      return joinObjects(
        filters,
        { children: filters.children?.map((child) => getNewFilters(child)) }
      );
    } else {
      return getNewFilterCondition(filters);
    }
  };

  return path.map((pathStep) => {
    if (isMappingStep(pathStep)) {
      return {
        type: PathStepType.mapping,
        mapping: { id: dimensionsMapping[pathStep.mapping.id] ?? pathStep.mapping.id, type: pathStep.mapping.type },
      } satisfies MappingStep;
    } else if (isFilterStep(pathStep)) {
      return {
        type: PathStepType.filter,
        filters: pathStep.filters ? getNewFilters(pathStep.filters) : undefined,
      } satisfies FilterStep;
    } else if (isFieldStep(pathStep) && pathStep.mapping !== undefined) {
      return joinObjects(
        pathStep,
        {
          mapping: Object.fromEntries(
            Object.entries(pathStep.mapping)
              .map(([key, value]): [string, InstanceReferenceValue | undefined] => {
                if (value === undefined) {
                  return [key, value];
                } else {
                  return [key, { id: dimensionsMapping[value.id] ?? value.id, type: value.type }];
                }
              })
          ),
        }
      ) satisfies FieldStep;
    } else {
      return pathStep;
    }
  });
};

export const duplicateFormula = (formula: Formula | undefined, dimensionsMapping: Record<string, string>): Formula | undefined => {
  if (formula !== undefined) {
    return {
      formula: formula.formula,
      inputs: formula.inputs.map(({ name, input }) => ({ name, input: { type: input.type, path: duplicatePathSteps(input.path, dimensionsMapping) } })),
    };
  } else {
    return undefined;
  }
};
