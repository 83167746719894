import { equals } from 'ramda';
import type { FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import type { AssociationFilterStoreObject, ConceptStoreObject, InstanceReferenceValue, SingleParameterDefinition, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { FilterValueType, getInstanceLabelOrUndefined, InstanceReferenceType, isFilterNode, isFilterValueRaw } from 'yooi-modules/modules/conceptModule';
import { AssociationFilter_Path } from 'yooi-modules/modules/conceptModule/ids';
import { joinObjects } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../components/atoms/Button';
import { IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import Tooltip from '../../../components/atoms/Tooltip';
import Typo from '../../../components/atoms/Typo';
import CompositeField, { CompositeFieldVariants } from '../../../components/molecules/CompositeField';
import TagContainer from '../../../components/molecules/TagContainer';
import Toggle from '../../../components/molecules/Toggle';
import useAuth from '../../../store/useAuth';
import useStore from '../../../store/useStore';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { formatOrUndef } from '../../../utils/stringUtils';
import useForceUpdate from '../../../utils/useForceUpdate';
import { SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import { getModelBackgroundColor, resolveConceptColorValue } from '../conceptDisplayUtils';
import { searchFilterFunction } from '../listFilterFunctions';
import { getQuickFilterInstances, getQuickFilterLabel, getQuickFilterOperator, getQuickFiltersTargetedConceptDefinitionId } from './quickFiltersUtils';
import SearchTextInput from './SearchTextInput';
import { FilterParams, useFilterStorage } from './useFilterSessionStorage';

const useStyles = makeStyles({
  toggleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingRem.s,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: spacingRem.s,
    flexGrow: 1,
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    gap: spacingRem.xs,
  },
}, 'instanceFilterComposite');

interface InstanceFilterCompositeProps {
  instanceFilterId: string,
  filterKey: string,
  parameterDefinitions: SingleParameterDefinition[],
  getPrefilteredInstances?: (conceptDefinitionIdToFilter: string, quickFilter: AssociationFilterStoreObject) => string[] | undefined,
  parametersMapping?: ParametersMapping,
}

const InstanceFilterComposite: FunctionComponent<InstanceFilterCompositeProps> = ({
  instanceFilterId, filterKey, parameterDefinitions, getPrefilteredInstances, parametersMapping,
}) => {
  const store = useStore();
  const { loggedUserId } = useAuth();
  const classes = useStyles();

  const forceUpdate = useForceUpdate();
  const [filterSessionStorageState = {}, setFilterSessionStorageState] = useFilterStorage(filterKey, FilterParams.filters);
  const [search] = useFilterStorage(instanceFilterId, FilterParams.nameSearch);
  const [showSearchBar, setShowSearchBar] = useState(search !== undefined);
  const [showAll, setShowAll] = useState(false);

  const filterFromSessionStorage = filterSessionStorageState[instanceFilterId];
  const activeElementsFromSessionStorage = (
    filterFromSessionStorage && !isFilterNode(filterFromSessionStorage) && isFilterValueRaw<InstanceReferenceValue[]>(filterFromSessionStorage.rightValue)
      ? filterFromSessionStorage.rightValue.raw.map((v) => v.id)
      : []
  );
  const lastActiveElementsFromSessionStorageRef = useRef(activeElementsFromSessionStorage);
  const activeElementsRef = useRef(activeElementsFromSessionStorage);
  if (!equals(lastActiveElementsFromSessionStorageRef.current, activeElementsFromSessionStorage)) {
    // Session storage changed, reset states
    lastActiveElementsFromSessionStorageRef.current = activeElementsFromSessionStorage;
    activeElementsRef.current = activeElementsFromSessionStorage;
  }

  const quickFilter = store.getObject<AssociationFilterStoreObject>(instanceFilterId);

  const debounceFilterStorageUpdate = useDebouncedCallback((newValue: string[]) => {
    if (newValue.length === 0) {
      setFilterSessionStorageState((current) => {
        const newFilters = { ...current };
        delete newFilters[instanceFilterId];
        return newFilters;
      });
    } else {
      const operator = getQuickFilterOperator(store, quickFilter[AssociationFilter_Path]);
      if (operator) {
        setFilterSessionStorageState((current) => {
          const newState = { ...current };
          newState[quickFilter.id] = {
            leftValue: quickFilter[AssociationFilter_Path],
            operator,
            rightValue: { type: FilterValueType.raw, raw: newValue.map((value) => ({ id: value, type: InstanceReferenceType.instance })) },
          };
          return newState;
        });
      }
    }
  }, 500);

  const quickFilterTargetConceptDefinitionId = getQuickFiltersTargetedConceptDefinitionId(store, quickFilter[AssociationFilter_Path], parameterDefinitions);
  if (!quickFilterTargetConceptDefinitionId) {
    return null;
  }

  let instances = getQuickFilterInstances(
    store,
    quickFilter,
    parameterDefinitions,
    loggedUserId,
    getPrefilteredInstances,
    parametersMapping
  );

  if (instances.length === 0) {
    return null;
  }

  const conceptFilterFunction = searchFilterFunction<Record<string, unknown>>(store, search, ['conceptLabel']);

  if (conceptFilterFunction) {
    instances = instances.filter((instance) => conceptFilterFunction(joinObjects(instance, { conceptLabel: getInstanceLabelOrUndefined(store, instance) })));
  }

  let buttonTitle = i18n`Hide`;
  if (!showAll) {
    const extraActiveToggles = instances.slice(10).filter(({ id }) => activeElementsRef.current.includes(id));
    if (extraActiveToggles.length > 0) {
      buttonTitle = i18n`Show all (${extraActiveToggles.length} other selected)`;
    } else {
      buttonTitle = i18n`Show all`;
    }
  }

  const onToggle = (toggleId: string) => {
    if (activeElementsRef.current.includes(toggleId)) {
      activeElementsRef.current = activeElementsRef.current.filter((e) => e !== toggleId);
    } else {
      activeElementsRef.current = [...activeElementsRef.current, toggleId];
    }
    debounceFilterStorageUpdate(activeElementsRef.current);
    forceUpdate();
  };

  const renderToggles = (toggles: ConceptStoreObject[]) => (
    <TagContainer>
      {toggles.map((toggle) => (
        <Toggle
          key={toggle.id}
          text={getInstanceLabelOrUndefined(store, toggle)}
          isActive={activeElementsRef.current.includes(toggle.id)}
          color={resolveConceptColorValue(store, toggle.id)}
          backgroundColor={getModelBackgroundColor(toggle)}
          onClick={() => onToggle(toggle.id)}
        />
      ))}
    </TagContainer>
  );

  return (
    <CompositeField
      dropdownMaxWidth="50rem"
      variant={CompositeFieldVariants.button}
      isSelected={activeElementsRef.current.length > 0}
      headerLinesRenderers={[
        {
          id: 'title',
          render: (inDropdown) => {
            const label = activeElementsRef.current.length > 0 ? `${getQuickFilterLabel(store, quickFilter.id, parameterDefinitions)} (${activeElementsRef.current.length})` : getQuickFilterLabel(store, quickFilter.id, parameterDefinitions);
            return (
              <div className={classes.titleContainer}>
                <Tooltip title={formatOrUndef(label)}>
                  <Typo maxLine={1}>{label}</Typo>
                </Tooltip>
                {inDropdown && (
                  <SizeContextProvider sizeVariant={SizeVariant.small}>
                    <div className={classes.buttonContainer}>
                      <IconOnlyButton
                        tooltip={showSearchBar ? i18n`Hide search bar` : i18n`Show search bar`}
                        variant={IconOnlyButtonVariants.secondary}
                        onClick={() => {
                          setShowSearchBar((current) => !current);
                        }}
                        iconName={IconName.search}
                        disabled={search !== undefined}
                      />
                      {activeElementsRef.current.length > 0 && (
                        <IconOnlyButton
                          tooltip={i18n`Clear filters`}
                          variant={IconOnlyButtonVariants.secondary}
                          onClick={() => {
                            setFilterSessionStorageState((current) => {
                              const newFilters = { ...current };
                              delete newFilters[instanceFilterId];
                              return newFilters;
                            });
                            forceUpdate();
                          }}
                          iconName={IconName.delete}
                        />
                      )}
                    </div>
                  </SizeContextProvider>
                )}
              </div>
            );
          },
        },
      ]}
      getDropdownSectionDefinitions={() => [{
        id: 'instances',
        lines: [{
          id: 'values',
          render: (
            <div className={classes.toggleContainer}>
              {(showSearchBar || search !== undefined) && (<SearchTextInput placeholder={i18n`Search`} element={instanceFilterId} focusOnMount fullWidth />)}
              {renderToggles(instances.slice(0, instances.length > 15 ? 10 : 15))}
              {instances.length > 15 ? (
                <span>
                  <Button
                    iconName={showAll ? IconName.expand_more : IconName.keyboard_arrow_right}
                    title={buttonTitle}
                    variant={ButtonVariant.tertiary}
                    onClick={() => {
                      setShowAll((current) => !current);
                    }}
                  />
                </span>
              ) : null}
              {showAll && instances.length > 15 ? renderToggles(instances.slice(10)) : null}
            </div>
          ),
        }],
      }]}
      onOpenDropdown={() => {}}
      onCloseDropdown={() => {}}
    />
  );
};
export default InstanceFilterComposite;
