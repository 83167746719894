import {
  Block,
  Block_ConceptDefinition,
  Block_FieldDisplays,
  Block_Parent,
  Block_Rank,
  Block_Type,
  BlockType_Column,
  BlockType_Section,
  BlockType_Tab,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStore, StoreObject } from 'yooi-store';
import { newError } from 'yooi-utils';
import type { DisplayItemLibrary } from '../displayItemLibrary';
import { registerDisplayItem } from '../displayItemLibrary';
import { computeChildBlockList, updateFieldParentBlock } from './displayItemDefinitionUtils';

const COMPATIBLE_PARENT_TYPES_IDS = [BlockType_Tab];

const createSection = (store: ObjectStore, parentId: string, rank: string) => store.createObject(
  {
    [Instance_Of]: Block,
    [Block_ConceptDefinition]: store.getObject(parentId)[Block_ConceptDefinition],
    [Block_Rank]: rank,
    [Block_Type]: BlockType_Section,
    [Block_Parent]: parentId,
  }
);

const getClosestAboveParent = (block: StoreObject | null): StoreObject | null => {
  if (!block) {
    return null;
  } else if (COMPATIBLE_PARENT_TYPES_IDS.includes(block[Block_Type] as string)) {
    return block;
  } else {
    return getClosestAboveParent(block.navigateOrNull(Block_Parent));
  }
};

const blockSectionHandler: DisplayItemLibrary['itemHandler'] = (store, blockId, _, flattenedTree) => {
  const moveSectionBelow = (aboveBlockId: string) => {
    const aboveBlock = store.getObject(aboveBlockId);
    const parentBlock = getClosestAboveParent(aboveBlock);
    if (!parentBlock) {
      throw newError('Cannot move section below. No compatible parent block above');
    }
    const parentBlockChildBlockList = computeChildBlockList(parentBlock);
    const aboveDisplayItem = parentBlockChildBlockList.find(({ item: { id } }) => id === aboveBlockId || store.getObject(aboveBlockId)[Block_Parent] === id);
    let rank;
    if (aboveDisplayItem) {
      rank = aboveDisplayItem.insertAfterRank();
    } else {
      const firstSectionChild = parentBlockChildBlockList.find(({ item }) => item[Block_Type] === BlockType_Section);
      if (firstSectionChild) {
        rank = firstSectionChild.insertBeforeRank();
      } else {
        rank = parentBlockChildBlockList.insertAfterLastItemRank();
      }
    }
    store.updateObject(blockId, {
      [Block_Parent]: parentBlock.id,
      [Block_Rank]: rank,
    });
  };

  const getFlattenedTabAndSectionTree = () => flattenedTree
    .filter(({ blockId: bId, fieldBlockDisplayId }) => !fieldBlockDisplayId && (BlockType_Column !== store.getObject(bId)[Block_Type] as string));

  const removeBlockItems = (blockToRemoveId: string) => {
    store.getObject(blockToRemoveId).navigateBack(Block_FieldDisplays).forEach(({ id }) => store.deleteObject(id));
    store.getObject(blockToRemoveId).navigateBack(Block_Parent).forEach((childBlock) => removeBlockItems(childBlock.id));
  };

  const deleteBlockAndContent = () => {
    removeBlockItems(blockId);
    store.deleteObject(blockId);
  };

  return {
    canMoveUp: () => getFlattenedTabAndSectionTree().findIndex(({ blockId: bId }) => blockId === bId) > 1,
    canMoveDown: () => {
      const flattenedTabAndSectionTree = getFlattenedTabAndSectionTree();
      return flattenedTabAndSectionTree.findIndex(({ blockId: bId }) => blockId === bId) < flattenedTabAndSectionTree.length - 1;
    },
    moveUp: () => {
      const tabAndSectionFlattenedTree = getFlattenedTabAndSectionTree();
      const sectionIndex = tabAndSectionFlattenedTree.findIndex(({ blockId: bId }) => blockId === bId);
      const previousSectionOrTab = tabAndSectionFlattenedTree[sectionIndex - 2];
      if (!previousSectionOrTab) {
        throw newError('Cannot move section up. Nothing is above the section.');
      }
      moveSectionBelow(previousSectionOrTab.blockId);
      return previousSectionOrTab.blockId;
    },
    moveDown: () => {
      const blockFlattenedTree = getFlattenedTabAndSectionTree();
      const blockIndex = blockFlattenedTree.findIndex(({ blockId: bId }) => blockId === bId);
      const nextSectionOrTab = blockFlattenedTree[blockIndex + 1];
      if (!nextSectionOrTab) {
        throw newError('Cannot move section down. Nothing is under the section.');
      }
      moveSectionBelow(nextSectionOrTab.blockId);
      return nextSectionOrTab.blockId;
    },
    deleteBlockAndContent,
  };
};

const blockSectionDefinitionHandler: DisplayItemLibrary['itemDefinitionHandler'] = (store, _, flattenedTree) => {
  const createBelow = (aboveBlockId?: string, aboveFieldId?: string) => {
    if (aboveBlockId) {
      const aboveBlock = store.getObject(aboveBlockId);
      let aboveSiblingBlock: StoreObject | undefined;
      if (aboveBlock[Block_Type] === BlockType_Column) {
        const parentBlock = aboveBlock.navigate(Block_Parent);
        aboveSiblingBlock = parentBlock[Block_Type] === BlockType_Tab ? aboveBlock : parentBlock;
      } else if (aboveBlock[Block_Type] === BlockType_Section) {
        aboveSiblingBlock = aboveBlock;
      }
      const parentBlock = getClosestAboveParent(aboveBlock);
      if (!parentBlock) {
        throw newError('Cannot create a section below. No compatible parent block above');
      }
      const parentBlockChildBlockList = computeChildBlockList(parentBlock);
      let rank;
      if (!aboveSiblingBlock) {
        rank = parentBlockChildBlockList.insertBeforeFirstItemRank();
      } else {
        const aboveSiblingBlockItem = parentBlockChildBlockList.find(({ item: { id } }) => id === aboveSiblingBlock?.id);
        if (!aboveSiblingBlockItem) {
          throw newError('Cannot create a section below. No compatible parent block above');
        }
        rank = aboveSiblingBlockItem.insertAfterRank();
      }

      const itemIndex = flattenedTree.findIndex(({ blockId: bId, fieldBlockDisplayId: fBDId }) => bId === aboveBlockId && fBDId === aboveFieldId);
      const itemsToReroute = flattenedTree
        .filter((__, i) => i > itemIndex)
        .filter(({ blockId: bId, fieldBlockDisplayId: fBDId }) => {
          if (fBDId) {
            return aboveBlockId === bId;
          } else {
            const block = store.getObject(bId);
            return block[Block_Type] === BlockType_Column && (block[Block_Parent] === parentBlock.id || block[Block_Parent] === aboveSiblingBlock?.id);
          }
        });

      const newSectionId = createSection(store, parentBlock.id, rank);
      itemsToReroute.forEach(({ blockId: bId, fieldBlockDisplayId: fBDId }) => {
        if (fBDId) {
          updateFieldParentBlock(store, fBDId, newSectionId);
        } else {
          store.updateObject(bId, {
            [Block_Parent]: newSectionId,
          });
        }
      });
      return newSectionId;
    } else {
      throw newError('Section should always have above block');
    }
  };

  return {
    createBelow,
    createAbove: (fromBlockId: string, fromFieldId?: string) => {
      const itemIndex = flattenedTree.findIndex(({ blockId: bId, fieldBlockDisplayId: fBDId }) => fromBlockId === bId && fromFieldId === fBDId);
      const { blockId: bId, fieldBlockDisplayId: fBDId } = flattenedTree[itemIndex - 1] ?? {};
      return createBelow(bId, fBDId);
    },
  };
};

registerDisplayItem({
  id: BlockType_Section,
  itemHandler: blockSectionHandler,
  itemDefinitionHandler: blockSectionDefinitionHandler,
});
