import type { FunctionComponent } from 'react';
import type { FieldStoreObject, PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import {
  CardColorMode,
  getPathLastFieldInformation,
  getPathReturnedConceptDefinitionId,
  InstanceReferenceType,
  isFieldStep,
  isMultiplePath,
  isRelationalType,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import { BooleanField, Concept, IconField, TextField } from 'yooi-modules/modules/conceptModule/ids';
import type { Block } from 'yooi-modules/modules/dashboardModule/fields/graphChartField';
import { CardDisplayMode } from 'yooi-modules/modules/dashboardModule/fields/graphChartField';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { joinObjects } from 'yooi-utils';
import Banner, { BannerVariant } from '../../../../../components/molecules/Banner';
import Chooser from '../../../../../components/molecules/Chooser';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import BlockContent from '../../../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../../../components/templates/BlockTitle';
import CardBlock from '../../../../../components/templates/CardBlock';
import HorizontalBlock, { HorizontalBlockVariant } from '../../../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../../../components/templates/VerticalBlock';
import type { FrontObjectStore } from '../../../../../store/useStore';
import useStore from '../../../../../store/useStore';
import base from '../../../../../theme/base';
import { buildPadding, getSpacing, Spacing, spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import useTheme from '../../../../../utils/useTheme';
import { getColorPathPickerValidator, getFieldPathPickerValidator } from '../../../conceptDisplayUtils';
import type { Chip } from '../../../fieldUtils';
import { getFieldLabel } from '../../../fieldUtils';
import StoreColorPickerInput from '../../../input/StoreColorPickerInput';
import StoreNumberPickerInput from '../../../input/StoreNumberPickerInput';
import StoreTextInputField from '../../../input/StoreTextInputField';
import type { OptionRecord } from '../../../modelTypeUtils';
import { defaultOptionComparator, getChipOptionWithUnknown, getConceptDefinitionNameOrEntity } from '../../../modelTypeUtils';
import PathInput from '../../../path/PathInput';
import { createPathConfigurationHandler, StepValidationState } from '../../../pathConfigurationHandler';
import { getFieldTypeValidator } from '../../../pathConfigurationHandlerUtils';
import CardsBodyFieldsDataTable from '../../../views/cards/CardsBodyFieldsDataTable';
import CardsHeaderFieldsDataTable from '../../../views/cards/CardsHeaderFieldsDataTable';
import { getSeriesLabelFromParameters } from '../../../views/common/series/viewWithSeriesFeatureUtils';
import { getDefaultLabel } from '../../../views/common/viewUtils';
import { getArrowColor, getDefaultArrowColor } from '../graphChartUtils';
import GraphChartBlockCardTitle from './GraphChartBlockCardTitle';

const useStyles = makeStyles((theme) => ({
  line: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    borderRadius: base.borderRadius.medium,
    backgroundColor: theme.color.background.neutral.default,
  },
  cardContainer: {
    ...buildPadding(Spacing.m),
  },
  lineContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    columnGap: getSpacing(Spacing.s),
    gridTemplateColumns: '1fr fit-content(100%)',
  },
  paddedContainer: {
    marginTop: spacingRem.xxs,
    marginBottom: spacingRem.xxs,
    borderWidth: 0,
    borderTopWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
  },
  separator: {
    borderTop: 'solid 0.1rem',
    borderColor: theme.color.border.default,
    gridColumnStart: 1,
    gridColumnEnd: 'last',
  },
  paddedTable: {
    paddingLeft: spacingRem.s,
  },
}), 'graphChartBlockCard');

interface GraphChartBlockCardProps {
  block: Block | undefined,
  columnIndex: number,
  parameterDefinitions: SingleParameterDefinition[],
  inheritedParameterDefinitions: SingleParameterDefinition[] | undefined,
  onChange: (newBlock: Block) => void,
  itemPerPage: number | undefined,
  onChangePagination: (itemPerPage: number | undefined) => void,
}

export const getBlockLabel = (store: FrontObjectStore, index: number, path: PathStep[] | undefined, label: string | undefined): string => {
  const conceptDefinitionId = getPathReturnedConceptDefinitionId(store, path ?? []);
  if (!label && conceptDefinitionId) {
    if (conceptDefinitionId === Concept) {
      return i18n`Concept`;
    }
    return getConceptDefinitionNameOrEntity(store, conceptDefinitionId);
  }
  return getDefaultLabel(label, index, i18n`Node`);
};

export const getBlockArrowLabel = (store: FrontObjectStore, path: PathStep[] | undefined, label: string | undefined): string | undefined => {
  const lastFieldInformation = getPathLastFieldInformation(path ?? []);
  if (!label && lastFieldInformation) {
    const field = store.getObjectOrNull<FieldStoreObject>(lastFieldInformation.fieldId);
    if (field) {
      return getFieldLabel(store, field);
    }
  }
  return label;
};

export const getBlockMultiplicity = (store: FrontObjectStore, path: PathStep[] | undefined): string | undefined => {
  const lastFieldInformation = getPathLastFieldInformation(path ?? []);
  if (lastFieldInformation || getPathReturnedConceptDefinitionId(store, path ?? [])) {
    return isMultiplePath(store, path ?? []) ? 'n' : '1';
  } else {
    return undefined;
  }
};

const getSuggestedBasePaths = (parameterDefinitions: SingleParameterDefinition[]): { label: string, path: PathStep[] }[] => (parameterDefinitions.map((parameterDefinition) => ({
  label: parameterDefinition.label,
  path: [
    { type: PathStepType.dimension, conceptDefinitionId: parameterDefinition.typeId },
    { type: PathStepType.mapping, mapping: { id: parameterDefinition.id, type: InstanceReferenceType.parameter } },
  ],
})));

const GraphChartBlockCard: FunctionComponent<GraphChartBlockCardProps> = ({
  block,
  columnIndex,
  onChange,
  parameterDefinitions,
  inheritedParameterDefinitions = [],
  itemPerPage,
  onChangePagination,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const store = useStore();

  if (!block) {
    return null;
  }

  const updateBlock = (id: string, newOperation: Omit<Block, 'id'>) => onChange(joinObjects({ id }, newOperation));

  const {
    id: blockId,
    index,
    path,
    label,
    arrowColor: confArrowColor,
    arrowLabel = { type: 'value', value: undefined },
    groupByFieldPath,
    collapsedGroups,
    display = { type: 'chip' },
  } = block;

  const blockLabel = getBlockLabel(store, index, path, label);
  const conceptDefinitionId = getPathReturnedConceptDefinitionId(store, path ?? []);
  const chipOptions = conceptDefinitionId ? getChipOptionWithUnknown(store, conceptDefinitionId) : undefined;

  const arrowColor = getArrowColor(confArrowColor, conceptDefinitionId);

  const currentParameterDefinitions: SingleParameterDefinition[] = conceptDefinitionId ? [{ id: blockId, typeId: conceptDefinitionId, label: blockLabel, type: 'parameter' }] : [];

  const displayOption: OptionRecord<'chip' | 'card'> = {
    chip: { id: 'chip', label: i18n`Chip` },
    card: { id: 'card', label: i18n`Card` },
  };

  const cardColorModeOptions: OptionRecord<CardColorMode> = {
    [CardColorMode.Bar]: { id: CardColorMode.Bar, label: i18n`As bar` },
    [CardColorMode.Dot]: { id: CardColorMode.Dot, label: i18n`As dot` },
  };

  const cardDisplayModeOptions: OptionRecord<CardDisplayMode> = {
    [CardDisplayMode.Opened]: { id: CardDisplayMode.Opened, label: i18n`Opened` },
    [CardDisplayMode.Closed]: { id: CardDisplayMode.Closed, label: i18n`Closed` },
  };

  return (
    <div className={classes.line}>
      <CardBlock
        titleContent={(
          <GraphChartBlockCardTitle
            icon={(typeof chipOptions?.icon === 'string') ? chipOptions.icon : chipOptions?.icon?.name}
            backgroundColor={chipOptions?.color}
            placeholder={blockLabel}
            onLabelUpdate={(newLabel) => updateBlock(blockId, joinObjects(block, { label: newLabel ?? undefined }))}
            label={label ? blockLabel : undefined}
          />
        )}
        content={(
          <div className={classes.cardContainer}>
            <VerticalBlock>
              <VerticalBlock asBlockContent>
                <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                  <BlockTitle title={i18n`Path`} errorTooltip={!conceptDefinitionId ? 'Missing targeted concept' : undefined} />
                  <BlockContent>
                    <PathInput
                      path={block.path ?? []}
                      parameterDefinitions={[...parameterDefinitions, ...inheritedParameterDefinitions]}
                      onChange={(newPath) => updateBlock(blockId, joinObjects(block, { path: newPath }))}
                      placeholder={i18n`Add path...`}
                      valuePathHandler={createPathConfigurationHandler(
                        store,
                        [...parameterDefinitions, ...inheritedParameterDefinitions],
                        [
                          ({ pathStep: lastStep }) => {
                            const result = [];
                            const instanceErrorMessage = i18n`Input should end with one or more instances.`;
                            if (isFieldStep(lastStep)) {
                              if (isRelationalType(store.getObjectOrNull(lastStep.fieldId)?.[Instance_Of] as string)) {
                                result.push({ state: StepValidationState.valid });
                              } else {
                                result.push({ state: StepValidationState.invalid, reasonMessage: instanceErrorMessage });
                              }
                            } else {
                              result.push({ state: StepValidationState.valid });
                            }
                            return result;
                          },
                        ]
                      )}
                      suggestedBasePaths={inheritedParameterDefinitions.length ? getSuggestedBasePaths(inheritedParameterDefinitions) : getSuggestedBasePaths(parameterDefinitions)}
                    />
                  </BlockContent>
                </HorizontalBlock>
                <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                  <BlockTitle title={i18n`# of items per page`} />
                  <BlockContent>
                    <StoreNumberPickerInput
                      initialValue={itemPerPage}
                      onSubmit={(newValue) => {
                        if (typeof newValue !== 'number' || (Number.isSafeInteger(newValue) && newValue > 0)) {
                          onChangePagination(typeof newValue === 'number' && newValue >= 1 ? newValue : undefined);
                        } else {
                          onChangePagination(undefined);
                        }
                      }}
                      placeholder={i18n`All`}
                      min={1}
                      withDecimals={false}
                    />
                  </BlockContent>
                </HorizontalBlock>
                {columnIndex > 0 && (
                  <>
                    <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                      <BlockTitle title={i18n`Left arrows color`} />
                      <BlockContent padded>
                        <div className={classes.lineContainer}>
                          <SpacingLine notCentered fullWidth>
                            <div className={classes.paddedContainer}>
                              <Chooser
                                actions={[{ key: 'value', name: i18n`Value` }, { key: 'path', name: i18n`Path` }]}
                                onClick={(i) => {
                                  updateBlock(
                                    blockId,
                                    joinObjects(block, { arrowColor: i === 1 ? getDefaultArrowColor(conceptDefinitionId) : { type: 'value' as const, value: undefined } })
                                  );
                                }}
                                selectedIndexes={[arrowColor.type === 'path' ? 1 : 0]}
                              />
                            </div>
                            {arrowColor.type === 'value'
                              ? (
                                <StoreColorPickerInput
                                  initialValue={arrowColor.value}
                                  defaultValue={theme.color.border.default}
                                  onSubmit={(newColor) => updateBlock(
                                    blockId,
                                    joinObjects(block, { arrowColor: { type: 'value' as const, value: newColor ?? undefined } })
                                  )}
                                />
                              )
                              : (
                                <PathInput
                                  path={arrowColor.path}
                                  parameterDefinitions={[...parameterDefinitions, ...inheritedParameterDefinitions, ...currentParameterDefinitions]}
                                  onChange={(newPath) => updateBlock(
                                    blockId,
                                    joinObjects(block, { arrowColor: { type: 'path' as const, path: newPath } })
                                  )}
                                  placeholder={i18n`Select a color field`}
                                  valuePathHandler={createPathConfigurationHandler(
                                    store,
                                    [...parameterDefinitions, ...inheritedParameterDefinitions, ...currentParameterDefinitions],
                                    [getColorPathPickerValidator(store)]
                                  )}
                                  suggestedBasePaths={getSuggestedBasePaths([...inheritedParameterDefinitions, ...currentParameterDefinitions])}
                                />
                              )}
                          </SpacingLine>
                        </div>
                      </BlockContent>
                    </HorizontalBlock>
                    <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                      <BlockTitle title={i18n`Left arrows label`} />
                      <BlockContent padded>
                        <div className={classes.lineContainer}>
                          <SpacingLine notCentered fullWidth>
                            <div className={classes.paddedContainer}>
                              <Chooser
                                actions={[{ key: 'value', name: i18n`Value` }, { key: 'path', name: i18n`Path` }]}
                                onClick={(i) => {
                                  updateBlock(
                                    blockId,
                                    joinObjects(block, { arrowLabel: i === 1 ? { type: 'path' as const, path: [] } : { type: 'value' as const } })
                                  );
                                }}
                                selectedIndexes={[arrowLabel.type === 'path' ? 1 : 0]}
                              />
                            </div>
                            {arrowLabel.type === 'value'
                              ? (
                                <StoreTextInputField
                                  initialValue={arrowLabel.value}
                                  onSubmit={(value) => updateBlock(
                                    blockId,
                                    joinObjects(block, { arrowLabel: { type: 'value' as const, value: value ?? undefined } })
                                  )}
                                  placeholder={getBlockArrowLabel(store, block.path, block.arrowLabel?.type === 'value' ? block.arrowLabel.value : undefined)}
                                  withDropdown
                                  maxLine={1}
                                  dropdownMaxLine={2}
                                />
                              )
                              : (
                                <PathInput
                                  path={arrowLabel.path}
                                  parameterDefinitions={[...parameterDefinitions, ...inheritedParameterDefinitions, ...currentParameterDefinitions]}
                                  onChange={(newPath) => updateBlock(blockId, joinObjects(block, { arrowLabel: { type: 'path' as const, path: newPath } }))}
                                  placeholder={i18n`Select a text field`}
                                  valuePathHandler={createPathConfigurationHandler(store, [...parameterDefinitions, ...inheritedParameterDefinitions, ...currentParameterDefinitions], [getFieldTypeValidator(store, [TextField], i18n`Input should end with a text field.`)])}
                                  suggestedBasePaths={getSuggestedBasePaths([...inheritedParameterDefinitions, ...currentParameterDefinitions])}
                                />
                              )}
                          </SpacingLine>
                        </div>
                      </BlockContent>
                    </HorizontalBlock>
                  </>
                )}
                <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                  <BlockTitle title={i18n`Group by field`} />
                  <BlockContent padded={!conceptDefinitionId}>
                    {conceptDefinitionId
                      ? (
                        <PathInput
                          path={groupByFieldPath ?? []}
                          onChange={(newPath) => updateBlock(blockId, joinObjects(block, { groupByFieldPath: newPath }))}
                          placeholder={i18n`Add path...`}
                          valuePathHandler={createPathConfigurationHandler(
                            store,
                            [...parameterDefinitions, ...currentParameterDefinitions],
                            [
                              ({ pathStep: lastStep, isNPath }) => {
                                const result = [];
                                const fieldTypeErrorMessage = i18n`Input end with an unauthorized field.`;
                                if (isFieldStep(lastStep)) {
                                  const fieldDefinitionId = store.getObjectOrNull(lastStep.fieldId)?.[Instance_Of] as string;
                                  if (isRelationalType(fieldDefinitionId)) {
                                    result.push({ state: StepValidationState.valid });
                                  } else {
                                    result.push({ state: StepValidationState.invalid, reasonMessage: fieldTypeErrorMessage });
                                  }
                                } else if (isNPath) {
                                  result.push({ state: StepValidationState.partiallyValid, reasonMessage: i18n`Input should be unique, use a mapping in your path.` });
                                } else {
                                  result.push({ state: StepValidationState.partiallyValid, reasonMessage: fieldTypeErrorMessage });
                                }
                                return result;
                              }]
                          )}
                          parameterDefinitions={[...parameterDefinitions, ...currentParameterDefinitions]}
                          suggestedBasePaths={getSuggestedBasePaths(currentParameterDefinitions)}
                        />
                      )
                      : (
                        <Banner
                          variant={BannerVariant.info}
                          title={i18n`Path should target a concept definition`}
                        />
                      )}
                  </BlockContent>
                </HorizontalBlock>
                {groupByFieldPath?.length ? (
                  <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                    <BlockTitle title={i18n`Group display`} />
                    <BlockContent>
                      <SearchAndSelect
                        computeOptions={() => Object.values(cardDisplayModeOptions).sort(defaultOptionComparator)}
                        selectedOption={cardDisplayModeOptions[collapsedGroups ?? CardDisplayMode.Opened]}
                        onSelect={(newDisplay) => {
                          if (newDisplay !== null) {
                            updateBlock(blockId, joinObjects(block, { collapsedGroups: newDisplay.id }));
                          }
                        }}
                      />
                    </BlockContent>
                  </HorizontalBlock>
                ) : null}
                <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                  <BlockTitle title={i18n`Display type`} />
                  <BlockContent>
                    <SearchAndSelect<Chip>
                      placeholder={i18n`Select a field`}
                      computeOptions={() => Object.values(displayOption).sort(defaultOptionComparator)}
                      selectedOption={displayOption[display.type]}
                      onSelect={(newDisplay) => {
                        if (newDisplay !== null) {
                          updateBlock(blockId, joinObjects(block, { display: { type: newDisplay.id === 'card' ? 'card' as const : 'chip' as const } }));
                        }
                      }}
                    />
                  </BlockContent>
                </HorizontalBlock>
              </VerticalBlock>
              {display.type === 'card' && (
                <VerticalBlock asBlockContent>
                  <div className={classes.separator} />
                  <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                    <BlockTitle
                      title={i18n`Cards configuration`}
                      variant={BlockTitleVariant.tertiary}
                    />
                  </HorizontalBlock>
                  <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                    <BlockTitle title={i18n`Default display`} />
                    <BlockContent>
                      <SearchAndSelect
                        computeOptions={() => Object.values(cardDisplayModeOptions).sort(defaultOptionComparator)}
                        selectedOption={cardDisplayModeOptions[display.defaultDisplay ?? CardDisplayMode.Closed]}
                        onSelect={(newDisplay) => {
                          if (newDisplay !== null) {
                            updateBlock(blockId, joinObjects(block, { display: joinObjects(display, { defaultDisplay: newDisplay.id }) }));
                          }
                        }}
                      />
                    </BlockContent>
                  </HorizontalBlock>
                  <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                    <BlockTitle title={i18n`Color`} />
                    <BlockContent>
                      <div className={classes.lineContainer}>
                        <SpacingLine fullWidth>
                          <PathInput
                            path={display.color?.path ?? []}
                            parameterDefinitions={[...parameterDefinitions, ...currentParameterDefinitions]}
                            onChange={(newPath) => updateBlock(blockId, joinObjects(
                              block,
                              { display: joinObjects(display, { color: { path: newPath, as: display.color?.as ?? CardColorMode.Bar } }) }
                            ))}
                            placeholder={i18n`Add path...`}
                            valuePathHandler={createPathConfigurationHandler(
                              store,
                              [...parameterDefinitions, ...currentParameterDefinitions],
                              [getColorPathPickerValidator(store)]
                            )}
                            suggestedBasePaths={getSuggestedBasePaths(currentParameterDefinitions)}
                          />
                        </SpacingLine>
                      </div>
                    </BlockContent>
                  </HorizontalBlock>
                  <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                    <BlockTitle title={i18n`Display color as`} />
                    <BlockContent>
                      <SearchAndSelect
                        computeOptions={() => Object.values(cardColorModeOptions).sort(defaultOptionComparator)}
                        selectedOption={cardColorModeOptions[display.color?.as ?? CardColorMode.Bar]}
                        onSelect={(newDisplay) => {
                          if (newDisplay !== null) {
                            updateBlock(
                              blockId,
                              joinObjects(block, { display: joinObjects(display, { color: { path: display.color?.path ?? [], as: newDisplay.id } }) })
                            );
                          }
                        }}
                      />
                    </BlockContent>
                  </HorizontalBlock>
                  <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                    <BlockTitle title={i18n`Icon`} />
                    <BlockContent>
                      <div className={classes.lineContainer}>
                        <SpacingLine fullWidth>
                          <PathInput
                            path={display.icon?.path ?? []}
                            parameterDefinitions={[...parameterDefinitions, ...currentParameterDefinitions]}
                            onChange={(newPath) => {
                              updateBlock(blockId, joinObjects(block, { display: joinObjects(display, { icon: { path: newPath } }) }));
                            }}
                            placeholder={i18n`Add path...`}
                            valuePathHandler={createPathConfigurationHandler(
                              store,
                              [...parameterDefinitions, ...currentParameterDefinitions],
                              [getFieldPathPickerValidator(store, IconField)]
                            )}
                            suggestedBasePaths={getSuggestedBasePaths(currentParameterDefinitions)}
                          />
                        </SpacingLine>
                      </div>
                    </BlockContent>
                  </HorizontalBlock>
                  <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                    <BlockTitle title={i18n`Boolean`} />
                    <BlockContent>
                      <div className={classes.lineContainer}>
                        <SpacingLine fullWidth>
                          <PathInput
                            path={display.boolean?.path ?? []}
                            parameterDefinitions={[...parameterDefinitions, ...currentParameterDefinitions]}
                            onChange={(newPath) => {
                              updateBlock(blockId, joinObjects(block, { display: joinObjects(display, { boolean: { path: newPath } }) }));
                            }}
                            placeholder={i18n`Add path...`}
                            valuePathHandler={createPathConfigurationHandler(
                              store,
                              [...parameterDefinitions, ...currentParameterDefinitions],
                              [
                                getFieldTypeValidator(store, [BooleanField], i18n`Input should end with a boolean field.`),
                              ]
                            )}
                            suggestedBasePaths={getSuggestedBasePaths(currentParameterDefinitions)}
                          />
                        </SpacingLine>
                      </div>
                    </BlockContent>
                  </HorizontalBlock>
                  <BlockContent fullWidth>
                    <BlockTitle title={i18n`Card header fields`} variant={BlockTitleVariant.inline} />
                  </BlockContent>
                  <BlockContent fullWidth>
                    <div className={classes.paddedTable}>
                      <CardsHeaderFieldsDataTable
                        header={display.header ?? []}
                        onChange={(func) => updateBlock(
                          blockId,
                          joinObjects(block, { display: joinObjects(display, { header: func(display.header ?? []) }) })
                        )}
                        suggestedBasePaths={getSuggestedBasePaths(currentParameterDefinitions)}
                        parameterDefinitions={[...parameterDefinitions, ...currentParameterDefinitions]}
                      />
                    </div>
                  </BlockContent>
                  <BlockContent fullWidth>
                    <BlockTitle title={i18n`Card body fields`} variant={BlockTitleVariant.inline} />
                  </BlockContent>
                  <BlockContent fullWidth>
                    <div className={classes.paddedTable}>
                      <CardsBodyFieldsDataTable
                        body={display.body ?? []}
                        onChange={(func) => updateBlock(
                          blockId,
                          joinObjects(block, { display: joinObjects(display, { body: func(display.body ?? []) }) })
                        )}
                        suggestedBasePaths={getSuggestedBasePaths(currentParameterDefinitions)}
                        parameterDefinitions={[...parameterDefinitions, ...currentParameterDefinitions]}
                        getSeriesLabel={(seriesLabel, seriesIndex, seriesPath) => (
                          getSeriesLabelFromParameters(store, seriesLabel, seriesIndex, seriesPath, [...parameterDefinitions, ...currentParameterDefinitions])
                        )}
                      />
                    </div>
                  </BlockContent>
                </VerticalBlock>
              )}
            </VerticalBlock>
          </div>
        )}
      />
    </div>
  );
};

export default GraphChartBlockCard;
