import type { FunctionComponent } from 'react';
import { joinObjects } from 'yooi-utils';
import Avatar, { AvatarSizes, AvatarVariant } from '../../../components/atoms/Avatar';
import useActivity from '../../../store/useActivity';
import useAuth from '../../../store/useAuth';
import useStore from '../../../store/useStore';
import { spacingRem } from '../../../theme/spacingDefinition';
import makeStyles from '../../../utils/makeStyles';
import { getUserName } from '../../utils/userUtils';

const MAX_USERS = 5;

const useStyles = makeStyles({
  bar: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacingRem.s,
    columnGap: spacingRem.xs,
  },
}, 'activityBar');

interface ActivityBarProps {
  objectId: string,
}

const ActivityBar: FunctionComponent<ActivityBarProps> = ({ objectId }) => {
  const classes = useStyles();
  const store = useStore();
  const { listViewer } = useActivity();
  const { loggedUserId } = useAuth();

  const activeSessions = listViewer(objectId)
    .map((session) => (joinObjects(session, { user: store.getObject(session.userId) })));

  const extraUsers = activeSessions.length <= MAX_USERS ? [] : activeSessions.slice(MAX_USERS - 1)
    .map(({ user }) => getUserName(user));

  return (
    <div className={classes.bar}>
      {
        activeSessions
          .slice(0, activeSessions.length <= MAX_USERS ? MAX_USERS : MAX_USERS - 1)
          .map((session) => ({
            id: session.sessionId,
            userId: session.userId,
            name: getUserName(session.user),
          }))
          .filter((s) => Boolean(s.name))
          .map((s) => (
            <Avatar
              key={s.id}
              name={s.name.charAt(0).toUpperCase() || '.'}
              tooltip={s.name}
              variant={loggedUserId === s.userId ? AvatarVariant.currentUser : AvatarVariant.user}
              size={AvatarSizes.small}
            />
          ))
      }
      {
        extraUsers.length > 0 && (
          <Avatar
            name={`+${extraUsers.length}`}
            tooltip={extraUsers.join('\n')}
            variant={AvatarVariant.user}
            size={AvatarSizes.small}
          />
        )
      }
    </div>
  );
};

export default ActivityBar;
