import { equals } from 'ramda';
import type { BusinessRuleHandler, ObjectStoreReadOnly, ObjectStoreWithTimeseries } from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import { booleanType, isBoolean } from 'yooi-utils';
import { asImport } from '../../common/fields/commonPropertyType';
import type { GetDslFieldHandler, UpdateOperationHandlers } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import { preventComputedFieldUpdate, validateFieldIdAsProperty, validateIntegrationOnlyPropertyUpdate } from '../common/commonFieldUtils';
import { registerField } from '../module';
import type { FilterValueRaw, PathStep } from '../moduleType';
import { FilterValueType } from '../moduleType';
import type {
  DimensionsMapping,
  FieldFilterCondition,
  FieldFilterConditions,
  MultipleParameterValue,
  ParametersMapping,
  ResolutionStack,
  SingleParameterValue,
  ValueResolution,
} from '../utils';
import {
  buildDimensionalId,
  createValuePathResolver,
  isFilterValueRaw,
  isSingleValueResolution,
  isValueResolutionOfType,
  ParsedDimensionType,
  parseDimensionMapping,
  resolveFieldValue,
} from '../utils';
import type { BooleanField } from './types';

interface BooleanUpdateOperationTypes {
  REPLACE: { type: 'value', value: boolean } | { type: 'path', path: PathStep[] },
}

const sanitizeBooleanValue = (value: unknown) => (value ?? { type: FilterValueType.raw, raw: false }) as FilterValueRaw<boolean>;
const isBooleanFilterApplicable = (value: FilterValueRaw<boolean>) => Boolean(value) && isFilterValueRaw<boolean>(value);

interface BooleanFieldFilterConditions extends FieldFilterConditions<boolean> {
  EQUALS: FieldFilterCondition<boolean, boolean>,
}

const checkBooleanField = ({ getObjectOrNull }: ObjectStoreReadOnly, propertyId: string): BusinessRuleHandler => (_, { properties }) => {
  const propertyInstance = getObjectOrNull(propertyId);
  if (propertyInstance) {
    const propertyValue = properties?.[propertyId];
    if (propertyValue !== undefined) { // Check with undefined as we want to validate null updates
      if (propertyValue === null || (isBoolean(propertyValue) && typeof propertyValue === 'boolean')) {
        return { rule: 'field.booleanField.allowBoolean', status: ValidationStatus.ACCEPTED };
      } else {
        return { rule: 'field.booleanField.InvalidBoolean', status: ValidationStatus.REJECTED };
      }
    }
  }
  return undefined;
};

const getValueResolution = (
  objectStore: ObjectStoreWithTimeseries,
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
  resolutionStack?: ResolutionStack
): ValueResolution<boolean> => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);

  if (isValueResolutionOfType(valueResolution, (value): value is boolean => typeof value === 'boolean')) {
    return valueResolution;
  } else if (isValueResolutionOfType(valueResolution, (value): value is undefined => value === undefined)) {
    return {
      isComputed: valueResolution.isComputed,
      isTimeseries: valueResolution.isTimeseries,
      value: false,
      getDisplayValue: () => false,
      error: valueResolution.error,
    };
  } else {
    return {
      value: false,
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['boolean'], typeof valueResolution.value),
      getDisplayValue: () => false,
      isTimeseries: false,
    };
  }
};

type BooleanFieldHandler = GetDslFieldHandler<
  BooleanField,
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
  BooleanFieldFilterConditions,
  BooleanUpdateOperationTypes,
  undefined,
  undefined
>;

export const booleanFieldHandler: BooleanFieldHandler = registerField({
  model: {
    label: 'BooleanField',
    title: 'Boolean',
    withApiAlias: true,
    properties: [
      {
        label: 'TrueValue',
        as: asImport('BooleanFieldValue', 'modules/conceptModule/moduleType'),
        initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
      },
      {
        label: 'FalseValue',
        as: asImport('BooleanFieldValue', 'modules/conceptModule/moduleType'),
        initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
      },
    ],
    asPropertyBusinessRules: [
      checkBooleanField,
      validateIntegrationOnlyPropertyUpdate('booleanField'),
      validateFieldIdAsProperty('booleanField'),
      preventComputedFieldUpdate('booleanField'),
    ],
  },
  handler: (objectStore, fieldId) => {
    const extractOperationValueBoolean = (
      parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
      operationValue: { type: 'value', value: boolean | undefined } | { type: 'path', path: PathStep[] }
    ): boolean => {
      if (operationValue.type === 'path') {
        const { path } = operationValue;
        const resolution = createValuePathResolver(objectStore, parametersMapping).resolvePathValue(path);
        if (isSingleValueResolution(resolution)) {
          const resolvedValue = resolution.value;
          if (isBoolean(resolvedValue)) {
            return resolvedValue;
          }
        }
        return false;
      } else {
        return !!operationValue.value;
      }
    };
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => getValueResolution(objectStore, fieldId, dimensionsMapping).value?.toString();
    const getStoreValue = (dimensionsMapping: DimensionsMapping): boolean => {
      const parsedDimensions = parseDimensionMapping(dimensionsMapping);
      if (parsedDimensions.type === ParsedDimensionType.MonoDimensional) {
        return !!objectStore.getObject(parsedDimensions.objectId)[fieldId];
      } else {
        return !!objectStore.getObject(buildDimensionalId(dimensionsMapping), true)[fieldId];
      }
    };

    return {
      describe: () => ({ hasData: true, timeseriesMode: 'none', returnType: booleanType }),
      restApi: {
        returnTypeSchema: { type: 'boolean', nullable: false },
        formatValue: (value) => (typeof value === 'boolean' ? value : false),
      },
      getStoreValue,
      getValueWithoutFormula: getStoreValue,
      getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
      resolvePathStepConfiguration: () => ({
        hasData: true,
        timeseriesMode: 'none',
        getValueResolutionType: () => booleanType,
        resolveValue: (dimensionsMapping, _, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack).value,
      }),
      updateValue: (dimensionsMapping, value) => {
        const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
        if (parsedDimensionMapping.type === ParsedDimensionType.MonoDimensional) {
          objectStore.updateObject(parsedDimensionMapping.objectId, { [fieldId]: value });
        } else {
          objectStore.updateObject(buildDimensionalId(dimensionsMapping), { [fieldId]: value });
        }
      },
      isEmpty: () => false,
      isSaneValue: () => ({ isValid: true }),
      getValueAsText,
      getExportColumnHeaders: (configuration, fieldLabel) => ({
        columnsNumber: 1,
        getHeaders: () => [{ format: 'string', value: fieldLabel }],
        getColumnConfiguration: () => configuration,
      }),
      getExportValue: (dimensionsMapping) => ({
        format: 'boolean',
        value: getValueResolution(objectStore, fieldId, dimensionsMapping).value,
      }),
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else {
            return undefined;
          }
        },
      }),
      filterConditions: {
        EQUALS: {
          sanitizeValue: sanitizeBooleanValue,
          isFilterApplicable: isBooleanFilterApplicable,
          filterFunction: (leftValue, rightValue) => (leftValue === rightValue),
        },
      },
      updateOperationHandlers: {
        REPLACE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            booleanFieldHandler(objectStore, fieldId).updateValue(dimensionsMapping, extractOperationValueBoolean(parametersMapping, value));
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: false },
        },
      } satisfies UpdateOperationHandlers<BooleanUpdateOperationTypes>,
    };
  },
  historyEventProducer: ({ getObjectOrNull }, fieldId) => ({
    value: {
      collectImpactedInstances: ({ id, properties }) => {
        if (
          properties?.[fieldId] !== undefined // A value is in the update for the current field
          || (properties === null && getObjectOrNull(id)?.[fieldId] !== undefined) // Object is deleted and store had a value for the field
        ) {
          return [id];
        } else {
          return [];
        }
      },
      getValue: (id) => ({ value: (getObjectOrNull(id)?.[fieldId] as boolean) ?? null, version: 1 }),
      areValuesEquals: (value1, value2) => equals(value1?.value, value2?.value),
    },
  }),
});
