import type { ComponentProps, FunctionComponent } from 'react';
import type { ImageWithSize, PlatformConfigurationStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import { MasterDetailMode, PlatformLanguage } from 'yooi-modules/modules/platformConfigurationModule';
import {
  CurrentPlatformConfiguration,
  PlatformConfiguration_Language,
  PlatformConfiguration_Logo,
  PlatformConfiguration_MasterDetailMode,
  PlatformConfiguration_Motto,
  PlatformConfiguration_Name,
} from 'yooi-modules/modules/platformConfigurationModule/ids';
import RawImagePicker from '../../../components/inputs/RawImagePicker';
import Banner, { BannerVariant } from '../../../components/molecules/Banner';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../components/molecules/SpacingLine';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { getPlatformLanguage } from '../../../utils/options';
import StoreInput from '../../_global/input/StoreInput';
import StoreTextInputField from '../../_global/input/StoreTextInputField';
import type { OptionRecord } from '../../_global/modelTypeUtils';
import { defaultOptionComparator } from '../../_global/modelTypeUtils';

const GeneralTab: FunctionComponent = () => {
  const store = useStore();

  const platformConfiguration = store.getObject<PlatformConfigurationStoreObject>(CurrentPlatformConfiguration);

  const languageOptions: OptionRecord<PlatformLanguage> = {
    [PlatformLanguage.fr]: { id: PlatformLanguage.fr, label: i18n`French` },
    [PlatformLanguage.en]: { id: PlatformLanguage.en, label: i18n`English` },
  };

  const selectedLanguage = platformConfiguration[PlatformConfiguration_Language] ?? PlatformLanguage.en;

  const loginImage = platformConfiguration[PlatformConfiguration_Logo];
  const loginImageValue: ComponentProps<typeof RawImagePicker>['value'] = loginImage !== undefined
    ? {
      type: 'url',
      url: store.getAttachmentUrl(CurrentPlatformConfiguration, PlatformConfiguration_Logo)(loginImage.revisionId, true),
      size: { width: loginImage.width, height: loginImage.height },
    } : undefined;

  const masterDetailModeOptions: OptionRecord<MasterDetailMode> = {
    [MasterDetailMode.openedByDefault]: { id: MasterDetailMode.openedByDefault, label: i18n`Opened` },
    [MasterDetailMode.closedByDefault]: { id: MasterDetailMode.closedByDefault, label: i18n`Closed` },
  };

  const selectedMasterDetailMode = platformConfiguration[PlatformConfiguration_MasterDetailMode] ?? MasterDetailMode.openedByDefault;

  return (
    <VerticalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Platform name`} />
        <BlockContent>
          <StoreTextInputField
            initialValue={platformConfiguration[PlatformConfiguration_Name]}
            onSubmit={(newValue) => {
              store.updateObject(CurrentPlatformConfiguration, { [PlatformConfiguration_Name]: newValue });
            }}
            placeholder={i18n`Add text`}
            maxLine={1}
            withDropdown
          />
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Platform motto`} />
        <BlockContent>
          <StoreTextInputField
            initialValue={platformConfiguration[PlatformConfiguration_Motto]}
            onSubmit={(newValue) => {
              store.updateObject(CurrentPlatformConfiguration, { [PlatformConfiguration_Motto]: newValue });
            }}
            placeholder={i18n`Add text`}
            maxLine={1}
            withDropdown
          />
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle
          title={i18n`Logo`}
          infoTooltip={i18n`Logo that will be displayed on the login & loading pages`}
        />
        <BlockContent padded={loginImageValue !== undefined}>
          <StoreInput<ComponentProps<typeof RawImagePicker>['value']>
            initialValue={loginImageValue}
            onSubmit={(newValue) => {
              if (newValue) {
                if (newValue.type === 'buffer') {
                  store.uploadAttachment(
                    {
                      objectId: CurrentPlatformConfiguration,
                      propertyId: PlatformConfiguration_Logo,
                      contentType: newValue.contentType,
                      data: newValue.data,
                    },
                    (revisionId) => {
                      store.updateObject(CurrentPlatformConfiguration, {
                        [PlatformConfiguration_Logo]: {
                          revisionId,
                          width: newValue.size.width,
                          height: newValue.size.height,
                        } satisfies ImageWithSize,
                      });
                    }
                  );
                }
              } else {
                store.updateObject(CurrentPlatformConfiguration, { [PlatformConfiguration_Logo]: null });
              }
            }}
          >
            {(props) => (
              <RawImagePicker
                maxSize={{ width: 450, height: 250 }}
                alt={i18n`Application logo`}
                {...props}
              />
            )}
          </StoreInput>
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Language`} />
        <BlockContent>
          <SpacingLine>
            <SearchAndSelect
              computeOptions={() => Object.values(languageOptions).sort(defaultOptionComparator)}
              selectedOption={languageOptions[selectedLanguage]}
              onSelect={(option) => {
                store.updateObject(CurrentPlatformConfiguration, { [PlatformConfiguration_Language]: option?.id ?? null });
              }}
            />
            {
              getPlatformLanguage() !== selectedLanguage ? (
                <BlockContent padded>
                  <Banner variant={BannerVariant.warning} title={i18n`Language update will be applied at the next page reload.`} />
                </BlockContent>
              ) : null
            }
          </SpacingLine>
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle
          title={i18n`Default left panel state`}
          infoTooltip={i18n`This panel corresponds to the left panel where instances are listed, allowing you to navigate from one instance to another.`}
        />
        <BlockContent>
          <SearchAndSelect
            computeOptions={() => Object.values(masterDetailModeOptions).sort(defaultOptionComparator)}
            selectedOption={masterDetailModeOptions[selectedMasterDetailMode]}
            onSelect={(option) => {
              store.updateObject(CurrentPlatformConfiguration, { [PlatformConfiguration_MasterDetailMode]: option?.id ?? null });
            }}
          />
        </BlockContent>
      </HorizontalBlock>
    </VerticalBlock>
  );
};

export default GeneralTab;
