import { Buffer } from 'buffer/';
import { newError } from 'yooi-utils';
import type { Snapshot, SnapshotOperation } from './concurrencyHandler';

const dictRefWeight = 0.1;

// same constants than in yooi-back/src/features/storeSnapshot/snapshotSerializer.ts, should be kept synchronized
const maxOneByteInt = 0xe0;
const types = {
  undefined: 0xe0,
  null: 0xe1,
  true: 0xe2,
  false: 0xe3,
  number: 0xe4,
  record: 0xe5,

  // int
  int_base: 0xe5,
  int8: 0xe6,
  int16: 0xe7,
  int32: 0xe8,

  // array
  array8: 0xe9,
  array16: 0xea,
  array32: 0xeb,

  // string
  string8: 0xec,
  string16: 0xed,
  string32: 0xef,

  // ref
  ref8: 0xf0,
  ref16: 0xf1,
  ref32: 0xf2,

  // uuid(s)
  string_uuid: 0xf3,
  string_uuids: 0xf4,

  end: 0xff,
};

export const readSnapshot = async (
  readChunk: () => Promise<ReadableStreamReadResult<Uint8Array>>,
  canContinue: () => boolean,
  onDownloadProgress: (progress: number) => void = () => {}
): Promise<
  {
    snapshot: Snapshot | undefined,
    stats: Record<string, unknown>,
  }
> => {
  const parseStart = performance.now();

  let totalReadDuration = 0;

  let buffer: Buffer = Buffer.from([]);
  let offset = 0;
  let pastChunksSize = 0;

  const readLoop = async (parse: (i: number) => boolean, onReadChunk?: (i: number) => void): Promise<{ readDuration: number, parseDuration: number }> => {
    const startReadDuration = totalReadDuration;
    const loopStart = performance.now();

    let continueLoop = true;
    let i = 0;
    while (continueLoop) {
      const initialOffset = offset;
      try {
        continueLoop = parse(i);
        i += 1;
      } catch (e) {
        if (e instanceof RangeError) {
          onReadChunk?.(i);
          const readChunkStart = performance.now();
          const chunk = await readChunk();
          const readChunkEnd = performance.now();
          totalReadDuration += (readChunkEnd - readChunkStart);

          if (chunk.value === undefined) {
            throw newError('Snapshot deserialization error: unexpected end of stream', { offset });
          }

          const chunkBuffer = Buffer.from(chunk.value);
          buffer = initialOffset < buffer.length ? Buffer.concat([buffer.subarray(initialOffset), chunkBuffer]) : chunkBuffer;
          pastChunksSize += initialOffset;
          offset = 0;
        } else {
          throw e;
        }
      }
    }

    const readDuration = totalReadDuration - startReadDuration;
    const parseDuration = (performance.now() - loopStart) - readDuration;
    return { readDuration, parseDuration };
  };

  const readItem = async <T>(fun: () => T): Promise<{ value: T, readDuration: number, parseDuration: number }> => {
    let result: T | undefined;
    const { readDuration, parseDuration } = await readLoop(() => {
      result = fun();
      return false;
    });
    if (result) {
      return { value: result, readDuration, parseDuration };
    } else {
      throw newError('Should not happen, parse method has not been called');
    }
  };

  const refs: unknown[] = [];

  const typesReader: ((() => unknown) | undefined)[] = new Array(256);

  const readNext = (): unknown => {
    const type = buffer.readUInt8(offset);
    offset += 1;
    if (type < maxOneByteInt) {
      return type;
    } else {
      const typeReader = typesReader[type];
      if (!typeReader) {
        throw newError('Snapshot deserialization error: unknown type', { offset, type });
      }
      return typeReader();
    }
  };

  const readUUID = () => {
    const i1 = buffer.readUInt32LE(offset).toString(16).padStart(8, '0');
    offset += 4;
    const i2 = buffer.readUInt16LE(offset).toString(16).padStart(4, '0');
    offset += 2;
    const i3 = buffer.readUInt16LE(offset).toString(16).padStart(4, '0');
    offset += 2;
    const i4 = buffer.readUInt16LE(offset).toString(16).padStart(4, '0');
    offset += 2;
    const i5 = buffer.readUInt16LE(offset).toString(16).padStart(4, '0');
    offset += 2;
    const i6 = buffer.readUInt32LE(offset).toString(16).padStart(8, '0');
    offset += 4;
    return `${i1}-${i2}-${i3}-${i4}-${i5}${i6}`;
  };

  const readString = (bytesLength: number) => {
    // beware buffer.toString don't throw RangeError, so range must be checked before
    if (offset + bytesLength > buffer.length) {
      throw RangeError('read string out of range');
    }
    const value = buffer.toString('utf-8', offset, offset + bytesLength);
    offset += bytesLength;
    return value;
  };

  const readArray = (length: number) => {
    const result = new Array(length);
    for (let i = 0; i < length; i += 1) {
      result[i] = readNext();
    }
    return result;
  };

  const readRecord = () => {
    const result: Record<string, unknown> = {};
    let keyType = buffer.readUInt8(offset);
    while (keyType !== types.end) {
      const key = readNext() as string;
      result[key] = readNext();
      keyType = buffer.readUInt8(offset);
    }
    offset += 1;
    return result;
  };

  typesReader[types.undefined] = () => undefined;
  typesReader[types.null] = () => null;
  typesReader[types.true] = () => true;
  typesReader[types.false] = () => false;
  typesReader[types.number] = () => {
    const value = buffer.readDoubleLE(offset);
    offset += 8;
    return value;
  };
  typesReader[types.record] = readRecord;
  typesReader[types.int8] = () => {
    const value = buffer.readUInt8(offset);
    offset += 1;
    return value;
  };
  typesReader[types.int16] = () => {
    const value = buffer.readUInt16LE(offset);
    offset += 2;
    return value;
  };
  typesReader[types.int32] = () => {
    const value = buffer.readUInt32LE(offset);
    offset += 4;
    return value;
  };
  typesReader[types.array8] = () => {
    const length = buffer.readUInt8(offset);
    offset += 1;
    return readArray(length);
  };
  typesReader[types.array16] = () => {
    const length = buffer.readUInt16LE(offset);
    offset += 2;
    return readArray(length);
  };
  typesReader[types.array32] = () => {
    const length = buffer.readUInt32LE(offset);
    offset += 4;
    return readArray(length);
  };
  typesReader[types.string8] = () => {
    const bytesLength = buffer.readUInt8(offset);
    offset += 1;
    return readString(bytesLength);
  };
  typesReader[types.string16] = () => {
    const bytesLength = buffer.readUInt16LE(offset);
    offset += 2;
    return readString(bytesLength);
  };
  typesReader[types.string32] = () => {
    const bytesLength = buffer.readUInt32LE(offset);
    offset += 4;
    return readString(bytesLength);
  };
  typesReader[types.ref8] = () => {
    const i = buffer.readUInt8(offset);
    offset += 1;
    return refs[i];
  };
  typesReader[types.ref16] = () => {
    const i = buffer.readUInt16LE(offset);
    offset += 2;
    return refs[i];
  };
  typesReader[types.ref32] = () => {
    const i = buffer.readUInt32LE(offset);
    offset += 4;
    return refs[i];
  };
  typesReader[types.string_uuid] = readUUID;
  typesReader[types.string_uuids] = () => {
    const count = buffer.readUInt8(offset);
    offset += 1;
    const result: string[] = new Array(count);
    for (let i = 0; i < count; i += 1) {
      result[i] = readUUID();
    }
    return result.join('|');
  };

  const {
    value: { eventId, checksum, storeSize, refsCount },
    readDuration: headerReadDuration,
    parseDuration: headerParseDuration,
  } = await readItem(() => ({
    eventId: readNext() as string,
    checksum: readNext() as string,
    storeSize: readNext() as number,
    refsCount: readNext() as number,
  }));

  const dictObjectsRatio = (refsCount * dictRefWeight) / ((refsCount * dictRefWeight) + storeSize);

  refs.length = refsCount;
  const dictionaryStats = await readLoop(
    (i) => {
      refs[i] = readNext();
      return i + 1 < refsCount;
    },
    (i) => onDownloadProgress(dictObjectsRatio * (i / refsCount))
  );

  const snapshotHeaderSerializedSize = pastChunksSize + offset;

  const operations: SnapshotOperation[] = [];
  let objectTotalCount = 0;
  let objectAcceptedCount = 0;
  const operationsStats = await readLoop(
    () => {
      if (!canContinue()) {
        return false;
      }

      // don't increment objectTotalCount now since the function can fail because of buffer limit reached before the end of the object and this will be played again
      const skippedCount = readNext() as number;
      if (objectTotalCount + skippedCount === storeSize) {
        return false;
      }

      const idLength = readNext() as number;
      let id: string | string[];
      if (idLength === 1) {
        id = readNext() as string;
      } else {
        id = new Array(idLength);
        for (let i = 0; i < idLength; i += 1) {
          id[i] = readNext() as string;
        }
      }
      const props = readRecord();

      // all reads are now done, the function won't be executed again for the same item, so it's now safe to change the external state
      operations.push([id, props]);
      objectTotalCount += 1 + skippedCount;
      objectAcceptedCount += 1;

      // snapshot serialization always finish with a skipped count before the end marker
      return true;
    },
    () => onDownloadProgress(dictObjectsRatio + (1 - dictObjectsRatio) * (objectTotalCount / storeSize))
  );

  const canReturnSnapshot = canContinue();
  if (canReturnSnapshot) {
    const { value: endSnapshot } = await readItem(() => buffer.readUInt8(offset));
    offset += 1;
    if (endSnapshot !== types.end) {
      throw newError('Snapshot deserialization error: end marker was expected', { offset: pastChunksSize + offset, value: endSnapshot });
    }

    if (offset !== buffer.length || (await readChunk()).value) {
      throw newError('Snapshot deserialization error: data remains after end', { offset: pastChunksSize + offset, bufferLength: buffer.length });
    }
  }

  const snapshotSerializedSize = pastChunksSize + offset;

  const snapshot = canReturnSnapshot ? { eventId, checksum, operations, rollbackOperations: [] } : undefined;

  const parseEnd = performance.now();
  const parseDuration = Math.round((parseEnd - parseStart) - totalReadDuration);

  const stats = {
    totalReadDuration: Math.round(totalReadDuration),
    parseDuration,
    headerReadDuration: Math.round(headerReadDuration),
    headerParseDuration: Math.round(headerParseDuration),
    dictionaryReadDuration: Math.round(dictionaryStats.readDuration),
    dictionaryParseDuration: Math.round(dictionaryStats.parseDuration),
    operationsReadDuration: Math.round(operationsStats.readDuration),
    operationsParseDuration: Math.round(operationsStats.parseDuration),
    eventId,
    checksum,
    objectTotalCount,
    objectAcceptedCount,
    dictionaryRefsCount: refs.length,
    snapshotHeaderSerializedSize,
    snapshotObjectsSerializedSize: snapshotSerializedSize - snapshotHeaderSerializedSize,
    snapshotSerializedSize,
  };

  return { snapshot, stats };
};
