import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { getFirstClassInstance, getInstanceLabelOrUndefined } from 'yooi-modules/modules/conceptModule';
import { ConceptRole, Group, User } from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Typo from '../../../../components/atoms/Typo';
import Banner, { BannerVariant } from '../../../../components/molecules/Banner';
import Link from '../../../../components/molecules/Link';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import useNavigation from '../../../../utils/useNavigation';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacingRem.s,
    overflow: 'hidden',
  },
  containerInCard: {
    height: '3.2rem',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
  },
  containerInTable: {
    outlineWidth: '0.1rem',
    outlineStyle: 'solid',
    outlineColor: theme.color.transparent,
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    height: '100%',
    '&:hover': {
      outlineColor: theme.color.border.hover,
    },
    '&:focus-within': {
      outlineColor: theme.color.border.dark,
    },
  },
  textContainer: {
    display: 'flex',
    gap: spacingRem.xs,
    alignItems: 'center',
    overflow: 'hidden',
  },
}), 'embeddedStakeholdersRenderer');

interface EmbeddedStakeholdersRendererProps {
  instanceId: string,
  targetType: string,
}

const EmbeddedStakeholdersRenderer: FunctionComponent<EmbeddedStakeholdersRendererProps> = ({ instanceId, targetType }) => {
  const classes = useStyles();

  const store = useStore();
  const navigation = useNavigation();
  const usageVariant = useUsageContext();

  const [isHovered, setIsHovered] = useState(false);

  const instance = store.getObject(instanceId);
  const firstParent = getFirstClassInstance(instance);
  const firstParentName = getInstanceLabelOrUndefined(store, firstParent);

  const message = i18n`"${getInstanceLabelOrUndefined(store, instance)}" is embedded by "${firstParentName}". If you want to edit its ${targetType !== Group ? i18n`users` : ''}${targetType === ConceptRole ? i18n` and ` : ''}${targetType !== User ? i18n`groups` : ''}, please refer to the parent instance.`;

  return (usageVariant !== UsageVariant.inTable && usageVariant !== UsageVariant.inCard) ? (
    <Banner
      fullWidth
      variant={BannerVariant.info}
      title={message}
      actions={[{
        key: 'openParent',
        icon: IconName.open_in_new,
        title: i18n`Parent instance`,
        onClick: () => (navigation.push(firstParent.id, { pathname: `/concept/${firstParent[Instance_Of]}/${firstParent.id}` })),
      }]}
    />
  ) : (
    <div
      className={classnames({
        [classes.container]: true,
        [classes.containerInTable]: usageVariant === UsageVariant.inTable,
        [classes.containerInCard]: usageVariant === UsageVariant.inCard,
      })}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={classes.textContainer}>
        <Typo>{i18n`See`}</Typo>
        <Link
          iconName={IconName.open_in_new}
          maxLine={1}
          title={firstParentName}
          to={{ pathname: `/concept/${firstParent[Instance_Of]}/${firstParent.id}` }}
          withoutSpacingLines
        />
      </div>
      {isHovered && (
        <Icon
          name={IconName.info}
          colorVariant={IconColorVariant.info}
          tooltip={message}
        />
      )}
    </div>
  );
};

export default EmbeddedStakeholdersRenderer;
