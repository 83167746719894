import type { FunctionComponent, ReactNode, RefObject } from 'react';
import { createContext, useContext } from 'react';

const OverlayContext = createContext<RefObject<HTMLElement>>({ current: null });

interface OverlayContextProviderProps {
  containerRef: RefObject<HTMLElement>,
  children: ReactNode,
}

export const OverlayContextProvider: FunctionComponent<OverlayContextProviderProps> = ({ containerRef, children }) => (
  <OverlayContext.Provider value={containerRef}>
    {children}
  </OverlayContext.Provider>
);

const useOverlayContainerRef = (): RefObject<HTMLElement> => useContext(OverlayContext);

export default useOverlayContainerRef;
