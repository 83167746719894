import type { FunctionComponent, ReactElement } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { GaugeViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { joinObjects } from 'yooi-utils';
import type { ChartTooltipData } from '../../../../components/charts/ChartTypes';
import GaugeChart from '../../../../components/charts/GaugeChart/GaugeChart';
import Loading from '../../../../components/molecules/Loading';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import base from '../../../../theme/base';
import { buildPadding, Spacing, spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import useDeepMemo from '../../../../utils/useDeepMemo';
import type { WidgetDisplay } from '../../fields/_global/widgetUtils';
import { getWidgetHeight } from '../../fields/_global/widgetUtils';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { useViewFilters } from '../../filter/useViewFilters';
import BlockViewError from '../common/BlockViewError';
import ViewsTooltip from '../common/ViewsTooltip';
import { getViewDefinitionHandler } from '../viewDsl';
import { isResolutionError } from '../viewResolutionUtils';
import type { GaugeViewDefinitionHandler } from './gaugeViewDefinitionHandler';

const useStyles = makeStyles((theme) => ({
  container: joinObjects({
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
    marginBottom: spacingRem.blockSpacing,
    boxShadow: base.shadowElevation.low,
    borderRadius: base.borderRadius.medium,
    background: theme.color.background.neutral.default,
  }, buildPadding({ y: Spacing.xs, right: Spacing.xs, left: Spacing.splus })),
  preventChartFlick: {
    position: 'absolute',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
}), 'gaugeViewBlock');

interface GaugeViewBlockProps {
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinition: GaugeViewStoredDefinition,
  parametersMapping: ParametersMapping,
  widgetDisplay: WidgetDisplay,
}

const GaugeViewBlock: FunctionComponent<GaugeViewBlockProps> = ({
  viewDimensions,
  viewFilters,
  viewDefinition,
  parametersMapping,
  widgetDisplay,
}) => {
  const classes = useStyles();

  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();

  const filterConfiguration = useViewFilters(viewFilters, viewDimensions);
  let content: ReactElement;
  const height = getWidgetHeight(widgetDisplay);

  const viewHandler = getViewDefinitionHandler(viewDefinition) as GaugeViewDefinitionHandler;

  const value = useDeepMemo(
    () => viewHandler.resolveView(store, { viewDimensions, parametersMapping, userId: loggedUserId, filterConfiguration, aclHandler }),
    [store.getSerial(), viewDimensions, viewDefinition, parametersMapping, filterConfiguration]
  );

  if (value && isResolutionError(value)) {
    return <BlockViewError error={value.error} />;
  } else if (value) {
    content = (
      <div className={classes.container}>
        <AutoSizer disableHeight>
          {({ width = 0 }) => (
            <div style={{ height }}>
              <div className={classes.preventChartFlick} style={{ height, width }}>
                <GaugeChart
                  minValue={value.minValue}
                  maxValue={value.maxValue}
                  steps={value.steps}
                  series={value.series ?? []}
                  labels={value.labels}
                  height={Math.max(0, height - 24)}
                  width={Math.max(0, width - 24)}
                  renderTooltip={({ seriesFieldId, seriesLabel, dimensionsMapping, datum, totalValue }: ChartTooltipData<{ value: number }>): ReactElement => (
                    <ViewsTooltip
                      fieldId={seriesFieldId}
                      seriesLabel={seriesLabel}
                      dimensionsMapping={dimensionsMapping}
                      value={datum.value}
                      totalValue={totalValue}
                    />
                  )}
                />
              </div>
            </div>
          )}
        </AutoSizer>
      </div>
    );
  } else {
    content = <Loading />;
  }
  return content;
};

export default GaugeViewBlock;
