import type { FunctionComponent } from 'react';
import type { StoreObject } from 'yooi-store';
import { IconName } from '../../../../components/atoms/Icon';
import Typo from '../../../../components/atoms/Typo';
import Chip from '../../../../components/molecules/Chip';
import CompositeField from '../../../../components/molecules/CompositeField';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import useNavigation from '../../../../utils/useNavigation';
import type { InstanceTreeNode } from '../../conceptUtils';
import { getChipOptions, getTypeLabel } from '../../modelTypeUtils';
import type { NavigationFilter } from '../../navigationUtils';

const useStyles = makeStyles({
  chipsGrid: {
    display: 'grid',
    rowGap: spacingRem.xs,
  },
}, 'listHierarchyTreeComposite');

interface ListHierarchyTreeCompositeProps {
  nodes: InstanceTreeNode[],
  focusOnMount?: boolean,
}

const ListHierarchyTreeComposite: FunctionComponent<ListHierarchyTreeCompositeProps> = ({ nodes, focusOnMount = false }) => {
  const classes = useStyles();

  const store = useStore();

  const navigation = useNavigation<NavigationFilter>();

  const usageCountByTypeLabel = nodes.reduce<{ [key: string]: StoreObject[] }>((acc, node) => {
    const nodeTypeLabel = getTypeLabel(store, store.getObject(node.key));
    if (nodeTypeLabel) {
      acc[nodeTypeLabel] = acc[nodeTypeLabel] ? [...acc[nodeTypeLabel], store.getObject(node.key)] : [store.getObject(node.key)];
    }
    return acc;
  }, {});

  if (Object.entries(usageCountByTypeLabel).length) {
    return (
      <CompositeField
        headerLinesRenderers={[
          {
            id: 'Chips',
            render: () => (
              <SpacingLine>
                <Typo maxLine={1}>
                  {Object.entries(usageCountByTypeLabel).map(([typeLabel, objects], index) => `${index > 0 ? ' ' : ''}${objects.length} ${typeLabel}`).toString()}
                </Typo>
              </SpacingLine>
            ),
          },
        ]}
        getDropdownSectionDefinitions={() => [
          {
            id: 'main',
            lines: Object.entries(usageCountByTypeLabel).map(([typeLabel, objects]) => (
              {
                id: typeLabel,
                render: (
                  <div className={classes.chipsGrid}>
                    {
                      objects.map((object) => {
                        const chipOptions = getChipOptions(store, object.id);
                        const navigationPayload = chipOptions?.getNavigationPayload?.(navigation);
                        return (
                          <Chip
                            key={object.id}
                            tooltip={chipOptions?.tooltip}
                            color={chipOptions?.color}
                            text={chipOptions?.label}
                            icon={chipOptions?.icon}
                            actions={navigationPayload ? [
                              {
                                key: 'open',
                                icon: IconName.output,
                                tooltip: i18n`Open`,
                                action: { to: navigationPayload.to, state: navigationPayload.state },
                                showOnHover: true,
                              },
                            ] : undefined}
                          />
                        );
                      })
                    }
                  </div>
                ),
              }
            )),
          }]}
        openOnMount={focusOnMount}
      />
    );
  } else {
    return null;
  }
};

export default ListHierarchyTreeComposite;
