import { newError } from 'yooi-utils';
import { asImport } from '../../common/fields/commonPropertyType';
import type { GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import { FieldDefinition } from '../../conceptModule/ids';
import { registerField } from '../module';
import type { MirrorField } from './types';

type MirrorFieldHandler = GetDslFieldHandler<MirrorField, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined>;
export const mirrorFieldHandler: MirrorFieldHandler = registerField({
  model: {
    label: 'MirrorField',
    title: 'Mirror',
    properties: [
      { label: 'Path', as: asImport('PathStep', 'modules/conceptModule', true) },
    ],
    relations: [
      { label: 'TargetedFieldDefinition', targetTypeId: FieldDefinition, reverseLabel: 'FieldDefinition_MirroredByField' },
    ],
  },
  handler: () => ({
    describe: () => ({ hasData: false }),
    restApi: {
      returnTypeSchema: {},
      formatValue: () => undefined,
    },
    getStoreValue: () => undefined,
    getValueWithoutFormula: () => undefined,
    getValueResolution: () => ({ value: undefined, getDisplayValue: () => undefined, isComputed: false, isTimeseries: false }),
    updateValue: () => {
      throw newError('updateValue not supported');
    },
    resolvePathStepConfiguration: () => ({ hasData: false }),
    isEmpty: () => false,
    isSaneValue: () => ({ isValid: true }),
  }),
});
