import { equals } from 'ramda';
import type { Ref } from 'react';
import { useCallback, useLayoutEffect, useRef } from 'react';
import base, { Opacity } from '../theme/base';
import { colorWithAlpha } from '../theme/colorUtils';
import declareBus from './declareBus';
import makeStyles from './makeStyles';

interface HighlightEvent {
  context: string[] | undefined,
  blink: boolean,
  fade: boolean,
}

export enum HighlightVariant {
  blockField = 'blockField',
  tableLine = 'tableLine',
}

const useHighlightBus = declareBus<HighlightEvent>('highlight').useBus;

// We need important as we inject style dynamically and want to be sure to override the existing background
// eslint-disable-next-line yooi/check-forbidden-classnames
const useStyles = makeStyles((theme) => ({
  '@keyframes blink': {
    '0%': { backgroundColor: theme.color.background.warning.light },
    '25%': { backgroundColor: theme.color.transparent },
    '50%': { backgroundColor: theme.color.background.warning.light },
    '70%': { backgroundColor: theme.color.transparent },
    '100%': { backgroundColor: theme.color.background.warning.light },
  },
  blink: {
    animation: '2s $blink',
  },
  highlightBlockField: {
    backgroundColor: colorWithAlpha(`${theme.color.background.info.light}`, Opacity.fifty),
    borderRadius: base.borderRadius.large,
    outline: `0.1rem dashed ${theme.color.text.info} !important`,
    outlineOffset: '0.2rem',
    boxShadow: '0 0 0 0.2rem #ebf4fe80',
  },
  highlightTableLine: {
    backgroundColor: colorWithAlpha(`${theme.color.background.info.light}`, Opacity.fifty),
    outline: `0.1rem dashed ${theme.color.text.info} !important`,
    outlineOffset: '-0.1rem',
  },
  '@keyframes fadeOut': {
    '0%': { backgroundColor: theme.color.background.warning.light },
    '70%': { backgroundColor: theme.color.background.warning.light },
    '100%': { backgroundColor: theme.color.transparent },
  },
  fadeOut: {
    animation: '10s $fadeOut',
  },
}), 'useHighlight');

const useHighlight = <T extends HTMLElement>(
  context: string[] | undefined,
  listen: { highlight?: boolean, blink?: boolean, fade?: boolean, highlightVariant?: HighlightVariant } = {
    highlight: true,
    blink: true,
    fade: true,
    highlightVariant: HighlightVariant.blockField,
  }
): Ref<T> => {
  const classes = useStyles();

  const ref = useRef<T>(null);
  const state = useRef({ highlight: false, blink: false, fade: false });
  const pendingUpdate = useRef(false);

  const { highlight: listenHighlight = false, blink: listenBlink = false, fade: listenFade = false, highlightVariant } = listen;

  const applyClasses = useCallback(() => {
    if (!pendingUpdate.current) {
      return;
    }
    pendingUpdate.current = false;

    const removeClassName = (className: string) => {
      if (!ref.current) {
        return;
      }

      if (ref.current.className && ref.current.className.includes(className)) {
        ref.current.className = ref.current.className.split(' ').filter((name) => name !== className).join(' ');
      }
    };

    const addClassName = (className: string, clearOnAnimationEnd: boolean) => {
      if (!ref.current) {
        return;
      }

      let addedClassName = false;
      if (ref.current.className) {
        if (!ref.current.className.includes(className)) {
          addedClassName = true;
          ref.current.className += ` ${className}`;
        }
      } else {
        addedClassName = true;
        ref.current.className = className;
      }

      if (clearOnAnimationEnd && addedClassName) {
        const onAnimationEnd = () => {
          ref.current?.removeEventListener('animationend', onAnimationEnd);
          removeClassName(className);
        };
        ref.current.addEventListener('animationend', onAnimationEnd);
      }
    };

    const highlightClass = highlightVariant === HighlightVariant.blockField ? classes.highlightBlockField : classes.highlightTableLine;

    if (state.current.blink) {
      addClassName(classes.blink, true);
    } else if (state.current.fade) {
      addClassName(classes.fadeOut, true);
    } else if (state.current.highlight) {
      addClassName(highlightClass, false);
    } else {
      removeClassName(highlightClass);
    }
  }, [classes.blink, classes.fadeOut, highlightVariant, classes.highlightBlockField, classes.highlightTableLine]);

  const callback = useCallback(({ context: contextNotify, blink, fade }: HighlightEvent) => {
    if (!ref.current) {
      return;
    }

    const isContextMatch = Boolean(contextNotify && context && contextNotify.length === context.length && (contextNotify.every((v, i) => v === context[i])));
    const newState = { ...state.current };
    if (blink) {
      if (!newState.highlight && isContextMatch) {
        newState.blink = listenBlink;
      }
    } else if (fade) {
      if (!newState.highlight && isContextMatch) {
        newState.fade = listenFade;
      }
    } else {
      newState.highlight = listenHighlight && isContextMatch;
    }

    if (!equals(state.current, newState)) {
      state.current = newState;
      pendingUpdate.current = true;
      applyClasses();
    }
  }, [applyClasses, context, listenBlink, listenFade, listenHighlight]);

  useLayoutEffect(() => {
    applyClasses();
  });

  useHighlightBus(callback);

  return ref;
};

export const useHighlightNotify = (): (context: string[] | undefined, blink?: boolean, fade?: boolean) => void => {
  const dispatchUpdated = useHighlightBus();
  return useCallback((context, blink = false, fade = false) => {
    dispatchUpdated({ context, blink, fade });
  }, [dispatchUpdated]);
};

export default useHighlight;
