import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo, { TypoVariant } from '../../../../components/atoms/Typo';
import InlineLink from '../../../../components/molecules/InlineLink';
import base from '../../../../theme/base';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { notifySuccess } from '../../../../utils/notify';
import useNavigation from '../../../../utils/useNavigation';
import useTheme from '../../../../utils/useTheme';
import type { NavigationFilter } from '../../../_global/navigationUtils';
import { TagVariant } from './objectRenderType';

const useStyles = makeStyles((theme) => ({
  valueAndTypeContainer: {
    display: 'flex',
    columnGap: spacingRem.s,
  },
  container: {
    paddingLeft: spacingRem.xs,
    paddingRight: spacingRem.xs,
    display: 'flex',
    lineHeight: '1.6rem',
    cursor: 'pointer',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderRadius: base.borderRadius.medium,
  },
  action: {
    display: 'flex',
    marginLeft: spacingRem.s,
  },
  hint_key: {
    backgroundColor: theme.color.background.success.default,
    borderColor: theme.color.transparent,
    color: theme.color.text.white,
  },
  hint_key_raw: {
    borderColor: theme.color.background.success.default,
    color: theme.color.text.primary,
  },
  hint_value: {
    backgroundColor: theme.color.background.danger.default,
    borderColor: theme.color.transparent,
    color: theme.color.text.white,
  },
  hint_value_raw: {
    borderColor: theme.color.background.danger.default,
    color: theme.color.text.primary,
  },
  noHint: {
    borderColor: theme.color.border.white,
  },
}), 'tagRenderer');

interface TagRendererProps {
  hint?: string,
  value: unknown,
  href?: string,
  variant?: TagVariant,
  type?: string,
  maxLine?: number,
}

const TagRenderer: FunctionComponent<TagRendererProps> = ({ hint, value, href, variant, type, maxLine }) => {
  const theme = useTheme();
  const classes = useStyles();

  const navigation = useNavigation<NavigationFilter>();

  const [showHint, setShowHint] = useState(Boolean(hint));
  const [isHovered, setHovered] = useState(false);

  const tag = (
    <div
      className={classnames({
        [classes.container]: true,
        [classes.hint_key]: variant === TagVariant.key && showHint,
        [classes.hint_key_raw]: variant === TagVariant.key && !showHint,
        [classes.hint_value]: variant === TagVariant.value && showHint,
        [classes.hint_value_raw]: variant === TagVariant.value && !showHint,
        [classes.noHint]: !hint,
      })}
      onClick={() => {
        if (hint) {
          setShowHint((current) => !current);
        }
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      aria-hidden="true"
    >
      {
        maxLine !== undefined
          ? (
            <Tooltip title={showHint ? hint ?? '' : `${value}`}>
              <Typo variant={TypoVariant.code} maxLine={maxLine}>
                {showHint ? hint : `${value}`}
              </Typo>
            </Tooltip>
          )
          : (
            <Typo variant={TypoVariant.code}>
              {showHint ? hint : `${value}`}
            </Typo>
          )
      }
      {href && (
        <InlineLink {...navigation.createNavigationPayload(`explorer:${value}`, { pathname: href })} noStyle>
          <div className={classes.action}>
            <Icon name={IconName.output} colorVariant={variant === undefined ? IconColorVariant.secondary : undefined} />
          </div>
        </InlineLink>
      )}
      <div
        style={{ visibility: isHovered ? 'visible' : 'hidden' }}
        className={classes.action}
        onClick={(event) => {
          // We stop the propagation to no trigger the toggle operation (hint <> value) of the containing div
          event.stopPropagation();
          const data = showHint && hint ? hint : value;
          navigator.clipboard.writeText(`${data}`);
          notifySuccess(i18n`Value "${`${data}`}" copied to clipboard`);
        }}
        aria-hidden="true"
      >
        <Icon name={IconName.description} colorVariant={variant === undefined ? IconColorVariant.secondary : undefined} />
      </div>
    </div>
  );

  if (type) {
    return (
      <span className={classes.valueAndTypeContainer}>
        {tag}
        <Typo variant={TypoVariant.code} color={theme.color.text.disabled}>{type}</Typo>
      </span>
    );
  } else {
    return tag;
  }
};

export default TagRenderer;
