import type { FunctionComponent } from 'react';
import base from '../../../theme/base';
import { spacingRem } from '../../../theme/spacingDefinition';
import makeStyles from '../../../utils/makeStyles';
import useTheme from '../../../utils/useTheme';
import Tooltip from '../../atoms/Tooltip';
import Typo, { TypoAlign, TypoVariant } from '../../atoms/Typo';

interface DateIndicatorProps {
  date: Date,
  label?: string,
  scale: (date: Date) => number,
  size: number,
  direction?: string,
  labelOffset?: number,
}

const useStyles = makeStyles((theme) => ({
  todayLabel: {
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    paddingBottom: spacingRem.xxs,
    paddingTop: spacingRem.xxs,
    borderRadius: base.borderRadius.medium,
    backgroundColor: theme.color.background.info.default,
    height: '2.2rem',
    verticalAlign: 'center',
  },
  todayLine: {
    borderRightWidth: '0.1rem',
    borderRightStyle: 'dashed',
    borderRightColor: theme.color.background.info.default,
    zIndex: 1,
    position: 'absolute',
  },
}), 'dateIndicator');

type GetTransform = (
  scale: (date: Date) => number,
  date: Date,
  labelOffset: number,
  direction: string | undefined,
  size: number,
  scaleOffset?: number
) => string;

const getTransform: GetTransform = (scale, date, labelOffset, direction, size, scaleOffset?) => {
  if (direction === 'right') {
    return `translate(${size + labelOffset}, ${scale(date) - 10})`;
  } else if (direction === 'left') {
    return `translate(${labelOffset - 15}, ${scale(date) - 10})`;
  } else if (direction === 'bottom') {
    return `translate(${scale(date) - (scaleOffset == null ? 27.5 : scaleOffset)}, ${size + labelOffset})`;
  } else {
    return `translate(${scale(date) - (scaleOffset == null ? 27.5 : scaleOffset)}, ${labelOffset})`;
  }
};

type GetLine = (
  scale: (date: Date) => number,
  date: Date,
  labelOffset: number,
  direction: string | undefined,
  size: number,
) => { x1: number, y1: number, x2: number, y2: number };

const getLine: GetLine = (scale, date, labelOffset, direction, size) => {
  if (direction === 'right') {
    return { x1: 0, y1: scale(date), x2: size + labelOffset, y2: scale(date) };
  } else if (direction === 'left') {
    return { x1: labelOffset + 30, y1: scale(date), x2: size, y2: scale(date) };
  } else if (direction === 'bottom') {
    return { x1: scale(date), y1: 0, x2: scale(date), y2: size + labelOffset };
  } else {
    return { x1: scale(date), y1: labelOffset + 20, x2: scale(date), y2: size };
  }
};

const DateIndicator: FunctionComponent<DateIndicatorProps> = ({ date, label, scale, size, direction, labelOffset = -15 }) => {
  const theme = useTheme();
  const classes = useStyles();

  const transform = getTransform(scale, date, labelOffset, direction, size);
  const lineTransform = getTransform(scale, date, labelOffset, direction, size, 0);
  const line = getLine(scale, date, labelOffset - 22, direction, size);
  const todayWidth = 55;
  if (label) {
    return (
      <>
        <g transform={transform}>
          <foreignObject
            width={todayWidth}
            height={22}
          >
            <Tooltip title={label}>
              <div className={classes.todayLabel}>
                <Typo maxLine={1} variant={TypoVariant.small} color={theme.color.text.white} align={TypoAlign.center}>{label}</Typo>
              </div>
            </Tooltip>
          </foreignObject>
        </g>
        <g transform={lineTransform}>
          <foreignObject
            width={2}
            height={22 + line.y2 - line.y1}
          >
            <div className={classes.todayLine} style={{ height: line.y2 - line.y1 }} />
          </foreignObject>
        </g>
      </>
    );
  } else {
    return null;
  }
};

export default DateIndicator;
