import type { ObjectStoreWithTimeseries } from 'yooi-store';
import type { Constant, FormulaNode, Input, InputSet } from 'yooi-utils';
import { anyType, booleanType, createFormulaEngineV2, engineTypeSystem, newError, numberType } from 'yooi-utils';
import type { Formula } from '../model';
import { allFunctions } from './formula/allFunctions';
import { getPathReturnedType } from './path/pathResolver';

const formulaEngine = createFormulaEngineV2({
  functionLibrary: allFunctions,
  typeSystem: engineTypeSystem,
});

const builtInConstants: Record<string, Constant> = {
  true: { kind: 'constant', name: 'TRUE', type: booleanType, value: true },
  false: { kind: 'constant', name: 'FALSE', type: booleanType, value: false },
  null: { kind: 'constant', name: 'NULL', type: anyType, value: undefined },
};

export const parseFormula = (formula: string, inputSet: InputSet<(name: string) => unknown>): FormulaNode<(name: string) => unknown> => (
  formulaEngine.prepareFormula(
    formula,
    {
      getInput: (name) => {
        const caseInsensitiveName = name.toLowerCase();
        return builtInConstants[caseInsensitiveName] ?? inputSet.getInput(name);
      },
    }
  )
);

export const buildInputSet = (
  objectStore: ObjectStoreWithTimeseries,
  parameters: { id: string, typeIds: string[] }[],
  inputs: Formula['inputs'],
  isTimeseriesField: boolean
): { inputSet: InputSet<(name: string) => unknown>, implicitTimeseriesInputNames: Set<string> } => {
  const implicitTimeseriesInputNames = new Set<string>();

  const inputsByName: Record<string, Input<(name: string) => unknown>> = {};
  inputs
    .filter(({ input }) => input.path.length > 0)
    .forEach(({ name, input }) => {
      const caseInsensitiveName = name.toLowerCase();

      const typeResolution = getPathReturnedType(objectStore, parameters, input.path, input.type);
      if (typeResolution.type === 'unresolvable') {
        throw newError('Input is unresolvable', { name, path: input.path, reason: typeResolution.reason, data: typeResolution.data });
      }

      inputsByName[caseInsensitiveName] = {
        kind: 'variable',
        name,
        type: typeResolution.returnType,
        resolve: (context) => context(name),
      };

      if (input.type === 'timeseries') {
        const timeseriesResolution = getPathReturnedType(objectStore, parameters, input.path, 'timeseriesIterator');
        if (timeseriesResolution.type === 'unresolvable') {
          throw newError('Input is unresolvable', { name, path: input.path, reason: timeseriesResolution.reason, data: timeseriesResolution.data });
        }
        if (timeseriesResolution.timeseriesMode === 'implicit') {
          implicitTimeseriesInputNames.add(caseInsensitiveName);
        }

        inputsByName[`${caseInsensitiveName}_i`] = {
          kind: 'variable',
          name: `${name}_i`,
          type: timeseriesResolution.returnType,
          resolve: (context) => context(`${name}_i`),
        };
      }
    });

  if (isTimeseriesField) {
    inputsByName.executioninstant_i = {
      kind: 'variable',
      name: 'ExecutionInstant_i',
      type: numberType,
      resolve: (context) => context('ExecutionInstant_i'),
    };
  }

  const inputSet: InputSet<(name: string) => unknown> = {
    getInput: (name) => {
      const caseInsensitiveName = name.toLowerCase();
      return inputsByName[caseInsensitiveName];
    },
  };

  return { inputSet, implicitTimeseriesInputNames };
};
