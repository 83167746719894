import type { FunctionComponent } from 'react';
import type { IconName } from '../../components/atoms/Icon';
import Icon from '../../components/atoms/Icon';
import Typo from '../../components/atoms/Typo';
import InlineLink from '../../components/molecules/InlineLink';
import base, { Opacity } from '../../theme/base';
import { colorWithAlpha } from '../../theme/colorUtils';
import { FontVariant } from '../../theme/fontDefinition';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import useTheme from '../../utils/useTheme';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '30rem',
    height: '9.4rem',
    padding: spacingRem.m,
    rowGap: spacingRem.s,
    boxShadow: base.shadowElevation.low,
    borderRadius: base.borderRadius.medium,
    backgroundColor: theme.color.background.neutral.default,
    '&:hover, &:focus-visible': {
      backgroundColor: colorWithAlpha(theme.color.background.neutral.subtle, Opacity.fifty),
    },
  },
  cardTitleLine: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    columnGap: spacingRem.s,
  },
  icon: {
    display: 'flex',
    padding: spacingRem.xxs,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.background.primarylight.default,
    borderRadius: base.borderRadius.medium,
    backgroundColor: theme.color.background.primarylight.default,
  },
}), 'settingButton');

interface SettingButtonProps {
  icon: IconName,
  name: string,
  description: string,
  target: string,
}

const SettingButton: FunctionComponent<SettingButtonProps> = ({ icon, name, description, target }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <InlineLink to={target} noStyle>
      <span className={classes.card}>
        <span className={classes.cardTitleLine}>
          <span className={classes.icon}><Icon name={icon} color={theme.color.text.brand} /></span>
          <Typo variant={FontVariant.blockTertiaryTitle}>{name}</Typo>
        </span>
        <Typo variant={FontVariant.small} color={theme.color.text.secondary}>{description}</Typo>
      </span>
    </InlineLink>
  );
};

export default SettingButton;
