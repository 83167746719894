import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { ConceptRoleStoreObject } from 'yooi-modules/modules/conceptModule';
import { stakeholdersFieldHandler } from 'yooi-modules/modules/conceptModule';
import {
  Concept_Name,
  ConceptRole,
  ConceptRole_ConceptDefinition,
  ConceptRole_ForCollaboration,
  ConceptRoleGroupAssignation,
  ConceptRoleGroupAssignation_Role_Concept,
  ConceptRoleGroupAssignation_Role_ConceptRole,
  ConceptRoleGroupAssignation_Role_Group,
  ConceptRoleUserAssignation,
  ConceptRoleUserAssignation_Role_Concept,
  ConceptRoleUserAssignation_Role_ConceptRole,
  ConceptRoleUserAssignation_Role_User,
  Group,
  StakeholdersField_TargetType,
  User,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { RichText } from 'yooi-utils';
import { compareProperty, compareString, comparing, filterNullOrUndefined, joinObjects, pushUndefinedToEnd, richTextToText } from 'yooi-utils';
import { ButtonVariant } from '../../../../components/atoms/Button';
import { IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton from '../../../../components/atoms/IconOnlyButton';
import OverflowMenu from '../../../../components/molecules/OverflowMenu';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SearchAndSelectMultiple from '../../../../components/molecules/SearchAndSelectMultiple';
import { TableSortDirection } from '../../../../components/molecules/Table';
import TableCell, { TableCellAlign } from '../../../../components/molecules/TableCell';
import TableLine from '../../../../components/molecules/TableLine';
import DataTable from '../../../../components/templates/DataTable';
import useAcl from '../../../../store/useAcl';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { useHighlightNotify } from '../../../../utils/useHighlight';
import { getInlineCreationBuilder } from '../../conceptUtils';
import { getFieldColumnComparator } from '../../fieldUtils';
import { getNavigationFilter } from '../../FrontFilterRenderers';
import { defaultOptionComparator, getChipOptions, getConceptDefinitionNameOrEntity, getModelTypeOptions, getSearchChipOptions } from '../../modelTypeUtils';
import type { ChipOption } from '../../modelTypeUtilsType';
import useFilterAndSort from '../../useFilterAndSort';

interface StakeholdersFieldUserGroupBlockRendererProps {
  dimensions: { n1InstanceId: string },
  fieldId: string,
  readOnly: boolean,
}

const StakeholdersFieldUserGroupBlockRenderer: FunctionComponent<StakeholdersFieldUserGroupBlockRendererProps> = ({ dimensions, fieldId, readOnly }) => {
  const store = useStore();
  const [showInline, setShowInline] = useState(false);
  const [addConceptId, setAddConceptId] = useState<string>();
  const [addRoleIds, setAddRoleIds] = useState<string[]>([]);
  const { canAssignStakeholders, canCreateObject } = useAcl();
  const highlight = useHighlightNotify();

  const currentInstance = store.getObject(dimensions.n1InstanceId);

  const targetTypeId = store.getObject(fieldId)[StakeholdersField_TargetType] as string;

  const { generateList, doSort, sortCriteria } = useFilterAndSort(
    `conceptrole_${dimensions.n1InstanceId}_${targetTypeId}`,
    stakeholdersFieldHandler(store, fieldId).getValueResolution(dimensions).value,
    undefined,
    { getComparatorHandler: getFieldColumnComparator(store), initial: { key: 'instance', direction: TableSortDirection.asc } }
  );

  const effectiveTarget = isInstanceOf<ConceptRoleStoreObject>(currentInstance, ConceptRole) && currentInstance[ConceptRole_ConceptDefinition]
    ? currentInstance[ConceptRole_ConceptDefinition] : ConceptRole;

  const handleReset = (force = false) => {
    if (showInline) {
      if (force || (!addConceptId && addRoleIds.length === 0)) {
        setAddConceptId(undefined);
        setAddRoleIds([]);
        setShowInline(false);
      }
    }
  };

  const navigationFilters = getNavigationFilter(store, fieldId, targetTypeId, dimensions.n1InstanceId);

  const computeRoleOptions = () => store.getObject(dimensions.n1InstanceId)
    .navigate(Class_Instances)
    .navigateBack(ConceptRole_ConceptDefinition)
    .map(({ id }) => getChipOptions(store, id, navigationFilters))
    .filter(filterNullOrUndefined)
    .sort(defaultOptionComparator);

  return (
    <DataTable
      list={generateList().list}
      doSort={doSort}
      sortCriteria={sortCriteria}
      lineContext={(instance) => [dimensions.n1InstanceId, fieldId, instance.id]}
      handleClickAway={handleReset}
      newItemTitle={i18n`Add`}
      newItemIcon={IconName.person}
      newItemButtonVariant={ButtonVariant.tertiary}
      onNewItem={!isInstanceOf(currentInstance, ConceptRole) && canAssignStakeholders(dimensions.n1InstanceId) && !showInline ? () => {
        setShowInline(true);
      } : undefined}
      columnsDefinition={[
        {
          propertyId: Concept_Name,
          sortable: true,
          width: 33,
          name: getConceptDefinitionNameOrEntity(store, targetTypeId),
          cellRender: ({ id }) => (
            <SearchAndSelect
              selectedOption={getChipOptions(store, id, navigationFilters)}
              readOnly
            />
          ),
        },
        {
          key: effectiveTarget,
          propertyId: effectiveTarget,
          name: getConceptDefinitionNameOrEntity(store, effectiveTarget),
          cellRender: ({ id: itemId, [User]: userList, [Group]: groupList, [ConceptRole_ForCollaboration]: forCollaborationList }) => {
            const instanceList: ChipOption[] = [];
            if (targetTypeId === User && userList) {
              instanceList.push(
                ...userList
                  .map((roleId) => getChipOptions(store, roleId, navigationFilters))
                  .filter(filterNullOrUndefined)
              );
            }

            if (groupList) {
              if (targetTypeId === User) {
                instanceList.push(
                  ...groupList
                    .map((roleId) => getChipOptions(store, roleId, navigationFilters))
                    .filter(filterNullOrUndefined)
                    .map((option) => (joinObjects(
                      option,
                      {
                        id: `${option.id}_${Group}`,
                        tooltip: i18n`Assigned via group`,
                        noDelete: targetTypeId === User,
                      }
                    )))
                );
              } else {
                instanceList.push(
                  ...groupList
                    .map((roleId) => getChipOptions(store, roleId, navigationFilters))
                    .filter(filterNullOrUndefined)
                );
              }
            }

            if (forCollaborationList) {
              instanceList.push(
                ...forCollaborationList
                  .map((roleId) => getChipOptions(store, roleId, navigationFilters))
                  .filter(filterNullOrUndefined)
                  .map((option) => (joinObjects(
                    option,
                    {
                      id: `${ConceptRole_ForCollaboration}_${option.id}`,
                      tooltip: i18n`Assigned via collaborations`,
                      noDelete: true,
                    }
                  )))
              );
            }

            return (
              <SearchAndSelectMultiple
                selectedOptions={instanceList}
                onSelect={({ id }) => {
                  store.withAssociation(targetTypeId === User ? ConceptRoleUserAssignation : ConceptRoleGroupAssignation)
                    .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_Concept : ConceptRoleGroupAssignation_Role_Concept, dimensions.n1InstanceId)
                    .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_ConceptRole : ConceptRoleGroupAssignation_Role_ConceptRole, id)
                    .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_User : ConceptRoleGroupAssignation_Role_Group, itemId)
                    .updateObject({});
                }}
                computeOptions={computeRoleOptions}
                onDelete={({ id }) => store.withAssociation(targetTypeId === User ? ConceptRoleUserAssignation : ConceptRoleGroupAssignation)
                  .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_Concept : ConceptRoleGroupAssignation_Role_Concept, dimensions.n1InstanceId)
                  .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_ConceptRole : ConceptRoleGroupAssignation_Role_ConceptRole, id)
                  .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_User : ConceptRoleGroupAssignation_Role_Group, itemId)
                  .deleteObject()}
                readOnly={readOnly || isInstanceOf(currentInstance, ConceptRole)}
              />
            );
          },
        },
        {
          propertyId: 'actions',
          action: true,
          align: TableCellAlign.center,
          cellRender: ({ id }) => {
            const menuItems = store.withAssociation(targetTypeId === User ? ConceptRoleUserAssignation : ConceptRoleGroupAssignation)
              .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_Concept : ConceptRoleGroupAssignation_Role_Concept, dimensions.n1InstanceId)
              .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_User : ConceptRoleGroupAssignation_Role_Group, id)
              .list()
              .map((assignation) => {
                const role = assignation.navigateRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_ConceptRole : ConceptRoleGroupAssignation_Role_ConceptRole);
                return {
                  key: role.id,
                  name: `Remove from ${richTextToText(role[Concept_Name] as RichText | undefined)}`,
                  icon: IconName.person,
                  onClick: () => store
                    .withAssociation(targetTypeId === User ? ConceptRoleUserAssignation : ConceptRoleGroupAssignation)
                    .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_Concept : ConceptRoleGroupAssignation_Role_Concept, dimensions.n1InstanceId)
                    .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_ConceptRole : ConceptRoleGroupAssignation_Role_ConceptRole, role.id)
                    .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_User : ConceptRoleGroupAssignation_Role_Group, id)
                    .deleteObject(),
                };
              });
            return (
              <OverflowMenu
                menuItems={menuItems}
                disabled={menuItems.length === 0 || !canAssignStakeholders(dimensions.n1InstanceId)}
              />
            );
          },
        },
      ]}
      inlineCreation={({
        render: showInline ? (
          <TableLine>
            <TableCell>
              <SearchAndSelect
                computeOptions={() => getModelTypeOptions(store, targetTypeId)}
                searchOptions={getSearchChipOptions(store, targetTypeId)}
                selectedOption={addConceptId ? getChipOptions(store, addConceptId, navigationFilters) : undefined}
                onSelect={(option) => setAddConceptId(option ? option.id : undefined)}
                getInlineCreation={
                  canCreateObject(User) && currentInstance[Instance_Of] && !readOnly && canAssignStakeholders(currentInstance[Instance_Of] as string)
                    ? getInlineCreationBuilder(store, User, setAddConceptId)
                    : undefined
                }
                editOnMount
              />
            </TableCell>
            <TableCell>
              <SearchAndSelectMultiple
                onSelect={({ id }) => setAddRoleIds((current) => [...current, id])}
                onDelete={({ id }) => setAddRoleIds((current) => current.filter((cid) => cid !== id))}
                computeOptions={computeRoleOptions}
                selectedOptions={
                  addRoleIds
                    .map((roleId) => getChipOptions(store, roleId, navigationFilters))
                    .filter(filterNullOrUndefined)
                    .sort(compareProperty('label', comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString)))
                }
              />
            </TableCell>
            <TableCell action>
              <IconOnlyButton
                tooltip={i18n`Add`}
                onClick={() => {
                  if (addConceptId) {
                    addRoleIds.forEach((roleId) => store.withAssociation(targetTypeId === User ? ConceptRoleUserAssignation : ConceptRoleGroupAssignation)
                      .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_Concept : ConceptRoleGroupAssignation_Role_Concept, dimensions.n1InstanceId)
                      .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_ConceptRole : ConceptRoleGroupAssignation_Role_ConceptRole, roleId)
                      .withRole(targetTypeId === User ? ConceptRoleUserAssignation_Role_User : ConceptRoleGroupAssignation_Role_Group, addConceptId)
                      .updateObject({}));
                    handleReset(true);
                    highlight([dimensions.n1InstanceId, fieldId, addConceptId], false, true);
                  }
                }}
                iconName={IconName.add}
                disabled={addRoleIds.length === 0 || !addConceptId}
              />
            </TableCell>
          </TableLine>
        ) : null,
      })}
    />
  );
};

export default StakeholdersFieldUserGroupBlockRenderer;
