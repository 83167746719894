import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import base from '../../theme/base';
import { spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import { buildComponentSizeVariantClasses, SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import { IconName } from '../atoms/Icon';
import Menu from './Menu';
import ToggleButton, { ElementPosition } from './ToggleButton';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    backgroundColor: theme.color.background.neutral.default,
    borderRadius: '0.4rem',
  },
  boxShadow: {
    boxShadow: base.button.boxShadow,
  },
  floating: {
    boxShadow: base.shadowElevation.low,
  },
  ...buildComponentSizeVariantClasses('chooser', ['height']),
}), 'chooser');

type Action =
  | { key: string, name: string, icon?: IconName, tooltip?: string, disable?: boolean }
  | { key: string, name?: string, icon: IconName, tooltip: string, disable?: boolean };

interface ChooserProps {
  actions: Action[],
  onClick: (index: number) => void,
  selectedIndexes: number[],
  floating?: boolean,
  readOnly?: boolean,
  limit?: number,
  noBoxShadow?: boolean,
}

const Chooser: FunctionComponent<ChooserProps> = ({ actions, onClick, selectedIndexes, floating = false, readOnly = false, limit, noBoxShadow = false }) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  let displayedActions: Action[];
  let hiddenActions: Action[] | undefined;
  let firstHiddenActionSelectedIndex: number | undefined;
  if (limit && actions.length > limit) {
    displayedActions = actions.slice(0, limit);
    hiddenActions = actions.slice(limit);
    firstHiddenActionSelectedIndex = selectedIndexes.find((i) => i >= limit);
  } else {
    displayedActions = actions;
  }
  return (
    <div className={classnames({ [classes.container]: true, [classes.floating]: floating, [classes.boxShadow]: !noBoxShadow })}>
      {displayedActions.map(({ key, name, icon, disable, tooltip }, index, self) => {
        let buttonType = ElementPosition.middle;
        if (self.length === 1) {
          buttonType = ElementPosition.alone;
        } else if (index === 0) {
          buttonType = ElementPosition.first;
        } else if (!hiddenActions && index === self.length - 1) {
          buttonType = ElementPosition.last;
        }
        const isActive = selectedIndexes.includes(index);

        return (
          <ToggleButton
            key={key}
            name={name}
            icon={icon}
            tooltip={tooltip}
            type={buttonType}
            onClick={(event) => {
              event.stopPropagation();
              onClick(index);
            }}
            active={isActive}
            disable={readOnly || disable}
            withShadow={false}
          />
        );
      })}
      {limit && hiddenActions && (
        <>
          <div ref={containerRef}>
            <ToggleButton
              key="chooserMoreButton"
              name={firstHiddenActionSelectedIndex ? actions[firstHiddenActionSelectedIndex]?.name : i18n`More`}
              tooltip={firstHiddenActionSelectedIndex ? actions[firstHiddenActionSelectedIndex]?.tooltip : i18n`More`}
              rightIcon={IconName.keyboard_arrow_down}
              type={ElementPosition.last}
              onClick={(event) => {
                event.stopPropagation();
                setOpen(true);
              }}
              active={!!firstHiddenActionSelectedIndex}
              withShadow={false}
            />
          </div>
          {isOpen ? (
            <SizeContextProvider sizeVariant={SizeVariant.main}>
              <Menu
                items={hiddenActions.map((action, index) => ({
                  key: action.key,
                  name: action.name ?? '',
                  icon: action.icon,
                  tooltip: action.tooltip,
                  active: selectedIndexes.some((i) => i === index + limit),
                  onClick: () => {
                    onClick(index + limit);
                  },
                }))}
                anchorRef={containerRef}
                onClose={() => setOpen(false)}
                placement="bottom-end"
                offset={[0, remToPx(spacingRem.xs)]}
              />
            </SizeContextProvider>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Chooser;
