import type { ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { ConceptStoreObject } from 'yooi-modules/modules/conceptModule';
import { getConceptDefinitionValidFields, getOpenConceptCollaborationIds } from 'yooi-modules/modules/conceptModule';
import {
  Association,
  Association_Role_Definition,
  Association_Role_Role1TypeInstance,
  Association_Role_Role2TypeInstance,
  AssociationField,
  AssociationField_Definition,
  Concept,
  EmbeddingField,
  KinshipRelation,
  RelationMultipleField,
  RelationSingleField,
  User,
} from 'yooi-modules/modules/conceptModule/ids';
import { Dashboard } from 'yooi-modules/modules/dashboardModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import Typo from '../../components/atoms/Typo';
import type { NavigationElement } from '../../components/molecules/NavigationTitle';
import useStore from '../../store/useStore';
import i18n from '../../utils/i18n';
import { notifySuccess } from '../../utils/notify';
import useDeleteModal from '../../utils/useDeleteModal';
import useNavigation from '../../utils/useNavigation';
import type { NavigationFilter } from './navigationUtils';
import { getNavigationElement, getNavigationElements } from './navigationUtils';

const useConceptDeleteModal = (redirectAfterDelete: boolean): [(conceptId: string) => void, ReactElement | null] => {
  const store = useStore();

  const navigate = useNavigate();
  const navigation = useNavigation<NavigationFilter>();
  const location = useLocation();

  const deleteConcept = (conceptId: string, promoteChildren: boolean, redirect: boolean) => {
    if (redirect) {
      const navigationElements = getNavigationElements(store, location).map((el) => (getNavigationElement(store, el)));
      const backElement = navigationElements.at(-2);
      if (backElement !== undefined) {
        const navToKey = navigation.getGetBackLocationHistory(backElement.key);
        // Typescript guarantee that the element before the last item always have a path when more than one item, cast is safe
        const goBackToElement = (navigationElements.find(({ key: navKey }) => navToKey.toLowerCase() === navKey) ?? backElement) as NavigationElement;
        navigation.goBackTo(goBackToElement.key, goBackToElement.path);
      } else {
        navigate('/', { replace: true });
      }
    }

    if (promoteChildren) {
      const concept = store.getObjectOrNull<ConceptStoreObject>(conceptId);
      if (concept) {
        getConceptDefinitionValidFields(store, concept[Instance_Of])
          .filter((field) => isInstanceOf(field, EmbeddingField))
          .forEach((field) => (
            concept.navigateBack(field.id)
              .forEach((child) => {
                store.updateObject(child.id, { [KinshipRelation]: null, [field.id]: null });
              })
          ));
      }
    }
    store.deleteObject(conceptId);
  };

  const hasDependencies = (conceptId: string): boolean => {
    const concept = store.getObjectOrNull<ConceptStoreObject>(conceptId);
    if (!concept || !isInstanceOf(concept, Concept)) {
      throw new Error('conceptHasDependencies called with a non-concept instance');
    }
    const allFields = getConceptDefinitionValidFields(store, concept[Instance_Of]);

    const dependenciesFields = allFields
      .filter((field) => [RelationSingleField, RelationMultipleField, AssociationField].includes(field[Instance_Of]));
    const findFirstDependency = dependenciesFields.find((field) => {
      if (field[Instance_Of] === AssociationField) {
        const fieldDefinitionId = field[AssociationField_Definition] as string;
        return (
          store
            .withAssociation(Association)
            .withRole(Association_Role_Definition, fieldDefinitionId)
            .withRole(Association_Role_Role1TypeInstance, conceptId)
            .withExternalRole(Association_Role_Role2TypeInstance)
            .list()
            .length
          || store
            .withAssociation(Association)
            .withRole(Association_Role_Definition, fieldDefinitionId)
            .withRole(Association_Role_Role2TypeInstance, conceptId)
            .withExternalRole(Association_Role_Role1TypeInstance)
            .list()
            .length
        );
      } else {
        return false;
      }
    });
    return !!findFirstDependency;
  };

  const hasEmbedded = (conceptId: string): boolean => {
    const concept = store.getObjectOrNull<ConceptStoreObject>(conceptId);
    if (!concept || !isInstanceOf(concept, Concept)) {
      throw new Error('conceptHasEmbedded called with a non-concept instance');
    }
    const allFields = getConceptDefinitionValidFields(store, concept[Instance_Of]);

    return allFields.filter((field) => isInstanceOf(field, EmbeddingField))
      .some((field) => Boolean(concept.navigateBack(field.id).length));
  };

  return useDeleteModal<string>({
    doDelete: (id, promoteEmbedded) => {
      deleteConcept(id, promoteEmbedded, redirectAfterDelete);
      if (redirectAfterDelete) {
        notifySuccess(i18n`Instance successfully deleted`);
      }
    },
    shouldConfirm: (id) => (hasDependencies(id) || hasEmbedded(id) || isInstanceOf(store.getObject(id), Dashboard)),
    getModalProps: (id) => {
      const showAlternateDelete = hasEmbedded(id);
      const concept = store.getObject<ConceptStoreObject>(id);
      const collaborationNote = getOpenConceptCollaborationIds(store, id).length > 0
        ? (
          <Typo>
            {i18n`It will also delete opened collaborations on this instance.`}
          </Typo>
        ) : undefined;

      const note = isInstanceOf(concept, User)
        ? (
          <Typo>
            {i18n`Confirming this action will revoke the user's access to the platform and remove all associations, roles, and collaborations linked to this user.`}
          </Typo>
        )
        : undefined;

      return {
        title: i18n`Are you sure you want to delete this instance?`,
        confirmLabel: showAlternateDelete ? i18n`Delete everything` : undefined,
        alternateConfirmLabel: showAlternateDelete ? i18n`Delete instance only` : undefined,
        content: showAlternateDelete
          ? (
            <>
              <Typo>{i18n`Delete instance only: embedded instances will remain accessible through their library.`}</Typo>
              <Typo>{i18n`Or delete everything, including all embedded instances.`}</Typo>
              {note}
              {collaborationNote}
            </>
          )
          : (note ?? (
            <>
              <Typo>
                {i18n`Confirming this action will break all associations with this instance.`}
              </Typo>
              {collaborationNote}
            </>
          )),
      };
    },
  });
};

export default useConceptDeleteModal;
