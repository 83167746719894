import { fromError, newError } from 'yooi-utils';
import { reportClientError } from '../utils/clientReporterUtils';
import { fetchJSON } from '../utils/fetchUtils';

interface NetworkAttachmentClient {
  uploadAttachment: (options: { objectId: string, propertyId: string, contentType: string, data: unknown }) => Promise<string>,
  cloneAttachment: (options: {
    objectId: string,
    propertyId: string,
    originObjectId: string,
    originPropertyId: string,
    originRevisionId: string,
  }) => Promise<string>,
  isSafeAttachment: (options: { objectId: string, propertyId: string, revisionId: string }) => Promise<'pending' | 'safe' | 'unsafe'>,
  getAttachmentUrl: (objectId: string, propertyId: string) => (revisionId: string, wait: boolean) => string,
}

const createNetworkAttachmentClient = (): NetworkAttachmentClient => {
  const uploadAttachment: NetworkAttachmentClient['uploadAttachment'] = async ({ objectId, propertyId, contentType, data }) => {
    try {
      const { status, response } = await fetchJSON<
        { status: 200, response: { status: 'accepted', revisionId: string } } | { status: 403 | 400, response: { status: string } }
      >(`/store/attachments/${objectId}/${propertyId}`, {
        method: 'POST',
        headers: { 'Content-Type': contentType },
        body: data as ArrayBuffer,
      });
      if (status === 200) {
        return response.revisionId;
      }
      throw newError('HTTP error', { status, statusText: response.status });
    } catch (e) {
      reportClientError(fromError(e, 'Failed to upload attachment', { objectId, propertyId, contentType }));
      throw e;
    }
  };

  const cloneAttachment: NetworkAttachmentClient['cloneAttachment'] = async ({ objectId, propertyId, originObjectId, originPropertyId, originRevisionId }) => {
    try {
      const { status, response } = await fetchJSON<{ status: 200, response: { status: 'accepted', revisionId: string } } | { status: 400 | 404, response: { status: 'rejected' } }>(
        `/store/attachments/${objectId}/${propertyId}/clone`,
        { method: 'POST', json: { originObjectId, originPropertyId, originRevisionId } }
      );
      if (status === 200) {
        return response.revisionId;
      }
      throw newError('HTTP error', { status, statusText: response.status });
    } catch (e) {
      reportClientError(fromError(e, 'Failed to clone attachment', { objectId, propertyId, originObjectId, originPropertyId, originRevisionId }));
      throw e;
    }
  };

  const isSafeAttachment: NetworkAttachmentClient['isSafeAttachment'] = async ({ objectId, propertyId, revisionId }) => {
    try {
      const { status, response } = await fetchJSON<
        { status: 200, response: { status: 'accepted', value: 'pending' | 'safe' | 'unsafe' } }
        | { status: 400 | 404, response: { status: 'rejected' } }
      >(
        `/store/attachments/${objectId}/${propertyId}/${revisionId}/malware/status`,
        { method: 'GET' }
      );
      if (status === 200) {
        return response.value;
      }
      throw newError('HTTP error', { status, statusText: response.status });
    } catch (e) {
      reportClientError(fromError(e, 'Failed to get attachment safe status', { objectId, propertyId }));
      throw e;
    }
  };

  const getAttachmentUrl: NetworkAttachmentClient['getAttachmentUrl'] = (objectId, propertyId) => (
    revisionId,
    wait = false
  ) => `/store/attachments/${objectId}/${propertyId}/${revisionId}?wait=${wait}`;
  return { uploadAttachment, cloneAttachment, isSafeAttachment, getAttachmentUrl };
};

export default createNetworkAttachmentClient;
