export const MigrationUpdateTimeseriesFormula = 278;
export const MigrationUpdateMatrixView = 279;
export const MigrationIntroduceIntegrationDefaultExpiration = 280;
export const MigrationTimeseriesTextField = 281;
export const MigrationAutomationFixDuplicatedIds = 282;
export const MigrationDropUselessFieldLocalOverride = 283;
export const MigrationCollaboration = 284;
export const MigrationConceptCollaboration = 285;
export const MigrationCollaborationField = 286;
export const MigrationDashboardNotSetOption = 287;
export const MigrationWorkflowOnSecondLine = 288;
export const MigrationMultiHomepage = 289;
export const MigrationRemWidthInViews = 290;
export const MigrationDropNotifications = 291;
export const MigrationConceptRoleCollaboration = 292;
export const MigrationSwimlaneViews = 293;
export const MigrationDropRoarField = 294;
export const MigrationIconLibraryToMaterialSymbols = 295;
export const MigrationIconField = 296;
export const MigrationFieldFilter = 297;
export const MigrationTimeseriesTableDimensionDisplay = 298;
export const MigrationBooleanField = 299;
export const MigrationMainBooleanField = 300;
export const MigrationAttachmentField = 301;
export const MigrationPlatformCollaborationMessage = 302;
export const MigrationUserIsEnabledAsField = 303;
export const MigrationViewFilters = 304;
export const MigrationIconFieldInChip = 305;
export const MigrationLeftBarFilters = 306;
export const MigrationCardFieldButtonPosition = 307;
export const MigrationSupportFixedWidthInConceptDefinitionInstancesTab = 308;
export const MigrationReverseWorkflowField = 309;
