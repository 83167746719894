import classnames from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';
import { useState } from 'react';
import { getConceptUrl, getInstanceLabelOrUndefined } from 'yooi-modules/modules/conceptModule';
import type { StoreObject } from 'yooi-store';
import Icon, { IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import Typo from '../../../../../components/atoms/Typo';
import Chip from '../../../../../components/molecules/Chip';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import useStore from '../../../../../store/useStore';
import base from '../../../../../theme/base';
import { getMostReadableColorFromBackgroundColor } from '../../../../../theme/colorUtils';
import { buildPadding, Spacing } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeSelectorsClasses from '../../../../../utils/makeSelectorsClasses';
import makeStyles from '../../../../../utils/makeStyles';
import useNavigation from '../../../../../utils/useNavigation';
import { SizeContextProvider, SizeVariant } from '../../../../../utils/useSizeContext';
import useTheme from '../../../../../utils/useTheme';
import { resolveConceptColorValue } from '../../../conceptDisplayUtils';
import { getChipOptionWithUnknown } from '../../../modelTypeUtils';
import type { NavigationFilter } from '../../../navigationUtils';

const groupValueSeparator = '_';

const selectorsClasses = makeSelectorsClasses('visibilityHandler');

const useStyles = makeStyles({
  hiddenButton: {
    display: 'none',
  },
  groupContainer: {
    gridColumnStart: 1,
    borderRadius: base.borderRadius.medium,
    '&:hover, &:focus, &:focus-within': {
      [`& .${selectorsClasses.visibilityHandler}`]: {
        display: 'block',
      },
    },
  },
  groupContainerPadding: {
    ...buildPadding({ x: Spacing.s }),
  },
}, 'viewConceptSwimlaneGroup');

interface ViewConceptSwimlaneGroupProps {
  values: StoreObject<string>[],
  groupBy: { id: string, label: string, icon: IconName | undefined, comparator: (a: StoreObject<string>[], b: StoreObject<string>[]) => number, isDefault: boolean } | undefined,
  colorBy: 'group' | 'column',
  columnsNumber: number,
  itemCounter: number,
  navigationFilters: NavigationFilter,
  children: ReactElement[],
}

const ViewConceptSwimlaneGroup: FunctionComponent<ViewConceptSwimlaneGroupProps> = ({
  values,
  groupBy,
  colorBy,
  columnsNumber,
  itemCounter,
  navigationFilters,
  children,
}) => {
  const store = useStore();
  const theme = useTheme();
  const classes = useStyles();
  const navigation = useNavigation();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const getInstanceColor = (instanceId: string): string => {
    const computedColor = resolveConceptColorValue(store, instanceId);
    return computedColor ?? theme.color.text.brand;
  };

  const groupId = values.length > 0 ? values.map((instance) => instance.id).join(groupValueSeparator) : undefined;
  let groupColor: string | undefined = theme.color.text.brand;
  if (values.length === 0) {
    groupColor = theme.color.background.neutral.default;
  } else if (values.length === 1) {
    groupColor = getInstanceColor(values[0].id);
  }
  const textColor = getMostReadableColorFromBackgroundColor(groupColor ?? '#fff');

  let groupElement = null;
  if (groupBy && colorBy === 'group') {
    groupElement = (
      <div
        className={classnames(classes.groupContainer, classes.groupContainerPadding)}
        style={{
          backgroundColor: groupColor,
          gridColumnEnd: columnsNumber + 1,
          border: groupId === undefined ? `0.2rem dashed ${theme.color.border.default}` : undefined,
        }}
      >
        <SpacingLine>
          <Typo color={textColor} maxLine={1} noWrap>
            {values.length > 0 ? values.map((value) => getInstanceLabelOrUndefined(store, value)).join(' x ') : i18n`No ${groupBy.label}`}
          </Typo>
          <Typo color={textColor}>
            {itemCounter}
          </Typo>
          <div className={classnames(classes.hiddenButton, selectorsClasses.visibilityHandler)}>
            <SizeContextProvider sizeVariant={SizeVariant.small}>
              <SpacingLine>
                <IconOnlyButton
                  tooltip={isCollapsed ? i18n`Expand group` : i18n`Collapse group`}
                  iconName={isCollapsed ? IconName.keyboard_arrow_right : IconName.expand_more}
                  onClick={() => setIsCollapsed((current) => !current)}
                  variant={IconOnlyButtonVariants.secondary}
                />
                {values.length === 1 && (
                  <IconOnlyButton
                    tooltip={i18n`Open`}
                    iconName={IconName.open_in_new}
                    onClick={() => navigation.push(values[0].id, { pathname: getConceptUrl(store, values[0].id), navigationFilters })}
                    variant={IconOnlyButtonVariants.secondary}
                  />
                )}
              </SpacingLine>
            </SizeContextProvider>
          </div>
        </SpacingLine>
      </div>
    );
  } else if (groupBy && colorBy === 'column') {
    groupElement = (
      <div
        className={classes.groupContainer}
        style={{ gridColumnEnd: columnsNumber + 1 }}
      >
        <SpacingLine>
          {values.length > 0 ? values.flatMap((value, index) => {
            const chipOptions = getChipOptionWithUnknown(store, value.id);
            const navigationPayload = chipOptions.getNavigationPayload?.(navigation);
            return [
              ...(index > 0 ? [(<Icon key={`swimlane_group_value_${groupId}_${value.id}_cross`} name={IconName.close} />)] : []),
              (
                <Chip
                  key={`swimlane_group_value_${groupId}_${value.id}_value`}
                  tooltip={chipOptions.tooltip}
                  color={chipOptions.color}
                  text={chipOptions.label}
                  icon={chipOptions.icon}
                  startIcons={chipOptions.startIcons}
                  endIcons={chipOptions.endIcons}
                  squareColor={chipOptions.squareColor}
                  actions={navigationPayload ? [{
                    key: 'open',
                    icon: IconName.output,
                    tooltip: i18n`Open`,
                    action: { to: navigationPayload.to, state: navigationPayload.state, openInNewTab: false },
                    showOnHover: true,
                  }] : []}
                />
              ),
            ];
          }) : <Chip text={i18n`No ${groupBy.label}`} borderStyle="dashed" icon={IconName.inbox} />}
          <Typo>
            {itemCounter}
          </Typo>
          <div className={classnames(classes.hiddenButton, selectorsClasses.visibilityHandler)}>
            <IconOnlyButton
              tooltip={i18n`Collapse group`}
              iconName={isCollapsed ? IconName.keyboard_arrow_right : IconName.expand_more}
              onClick={() => setIsCollapsed((current) => !current)}
              variant={IconOnlyButtonVariants.secondary}
            />
          </div>
        </SpacingLine>
      </div>
    );
  }
  return (
    <>
      {groupElement}
      {(!isCollapsed || !groupBy) && children}
    </>
  );
};

export default ViewConceptSwimlaneGroup;
