import chroma from 'chroma-js';
import base from './base';
import light from './light';
import type { Theme } from './themeUtils';
import { extendsTheme } from './themeUtils';

const createCustomTheme = (color: string): Theme => {
  const colors = Object.fromEntries(
    chroma.scale([base.color.gray['100'], color, base.color.gray['900']])
      .colors(21)
      .map((value, index) => [index * 5, value])
  );

  return extendsTheme(
    light,
    {
      name: 'custom',
      color: {
        background: {
          primary: {
            default: colors['50'],
            hover: colors['60'],
            pressed: colors['70'],
          },
          primarylight: {
            default: colors['5'],
            hover: colors['10'],
            pressed: colors['20'],
          },
        },
        text: {
          brand: colors['50'],
        },
        border: {
          primary: colors['50'],
          primarylight: colors['10'],
        },
      },
    }
  );
};

export default createCustomTheme;
