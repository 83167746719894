import classnames from 'classnames';
import type { ChangeEvent, FunctionComponent, MouseEventHandler } from 'react';
import { useMemo, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { FixedSizeList } from 'react-window';
import { useDebouncedCallback } from 'use-debounce';
import { joinObjects } from 'yooi-utils';
import base from '../../theme/base';
import { Spacing, spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { notifySuccess } from '../../utils/notify';
import { sanitizeSearchValue } from '../../utils/searchUtils';
import useSizeContext from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import Icon, { IconColorVariant, IconName } from '../atoms/Icon';
import Typo, { sizeVariantToTypoVariant } from '../atoms/Typo';
import InlineLoading from './InlineLoading';
import SpacedContainer from './SpacedContainer';
import type { TreeNodeChipOption } from './TreeNode';
import TreeNode from './TreeNode';

const useStyles = makeStyles((theme) => (joinObjects(
  {
    treeWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    treeSearchContainer: {
      width: '100%',
    },
    treeInfoContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: spacingRem.s,
      marginBottom: spacingRem.m,
      marginTop: spacingRem.s,
    },
    treeInfoIconContainer: {
      marginTop: spacingRem.xxs,
      marginLeft: '0.3rem',
    },
    treeContainer: {
      maxHeight: '27rem',
      overflow: 'auto',
    },
    searchContainer: {
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      flexGrow: 1,
      borderRadius: base.borderRadius.medium,
      boxShadow: 'none',
      textShadow: 'none',
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: theme.color.border.default,
      width: '100%',
      color: theme.color.text.primary,
      '& ::placeholder': {
        color: theme.color.text.disabled,
      },
      '&:focus-within': {
        borderColor: theme.color.border.dark,
        backgroundColor: theme.color.background.neutral.default,
      },
    },
    textarea: {
      verticalAlign: 'top',
      border: 'none',
      backgroundColor: theme.color.transparent,
      resize: 'none',
      outline: 'none',
      padding: `0.3rem ${spacingRem.s}`,
      flexGrow: 1,
      width: '100%',
    },
  },
  theme.font
)), 'tree');

export interface TreeNodeEntry {
  getKey: (parentPath?: string) => string,
  matchFunction: (searchInput: string) => boolean,
  getPath?: (parentPath?: string) => string,
  generateGetChipOption: (parentPath?: string) => () => TreeNodeChipOption | undefined,
  label: string,
  generateOnChipClick: (copyValue: (value: string, message: string) => void, parentPath?: string) => MouseEventHandler<HTMLDivElement>,
  returnTypeId: string | undefined,
  getChildrenNode: (() => TreeNodeEntry[]) | undefined,
}

interface FlattenedNode {
  key: string,
  onOpen?: () => void,
  onClose?: () => void,
  getChipOption: () => TreeNodeChipOption | undefined,
  onClick?: MouseEventHandler,
  depth: number,
  firstChild: boolean,
  lastChild: boolean,
  lastChildForDepth: number[],
}

const copyValue = (value: string, message: string) => {
  navigator.clipboard.writeText(value);
  notifySuccess(message);
};

const SEARCH_DEPTH = 2;

const getFilteredData = (
  nodes: TreeNodeEntry[],
  searchValue: string,
  depth = 0,
  cache = new Map<string, TreeNodeEntry[] | undefined>()
): TreeNodeEntry[] => nodes.flatMap((node) => {
  if (depth < SEARCH_DEPTH + 2) {
    let filteredChildren: TreeNodeEntry[] | undefined = node.returnTypeId ? cache.get(node.returnTypeId) : undefined;
    if (!filteredChildren && node.getChildrenNode) {
      const children = node.getChildrenNode();
      if (children?.length) {
        filteredChildren = getFilteredData(children, searchValue, depth + 1, cache);
      }
      if (node.returnTypeId) {
        cache.set(node.returnTypeId, filteredChildren ?? []);
      }
    }
    if (filteredChildren?.length || node.matchFunction(searchValue)) {
      return [joinObjects(node, { getChildrenNode: filteredChildren?.length ? (() => filteredChildren ?? []) : undefined })];
    }
  }
  return [];
});

interface TreeProps {
  data: TreeNodeEntry[],
  search?: boolean,
  treeInfo?: string,
}

const Tree: FunctionComponent<TreeProps> = ({ data, search, treeInfo }) => {
  const theme = useTheme();
  const classes = useStyles();

  const { sizeVariant } = useSizeContext();
  const computedTextVariant = sizeVariantToTypoVariant[sizeVariant];

  const [searchTextAreaInput, setSearchTextAreaInput] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [debouncedSearchInput, setDebouncedSearchInput] = useState<string | null>(null);
  const [openedNodesId, setOpenedNodesId] = useState<Set<string>>(new Set());
  const debounceSearchInput = useDebouncedCallback((v: string) => {
    const getIdToOpen = (nodes: TreeNodeEntry[], depth = 0, ids = new Set<string>(), parentPath?: string) => {
      if (v !== '' && depth < SEARCH_DEPTH) {
        nodes.forEach((node) => {
          ids.add(node.getKey(parentPath));
          if (node.getChildrenNode && node.getPath) {
            getIdToOpen(node.getChildrenNode(), depth + 1, ids, node.getPath(parentPath));
          }
        });
      }
      return ids;
    };
    setDebouncedSearchInput(v);
    setOpenedNodesId(getIdToOpen(data));
    setLoading(false);
  }, 500);

  const filteredData: TreeNodeEntry[] = useMemo(() => {
    let result: TreeNodeEntry[];
    if (debouncedSearchInput?.length) {
      result = getFilteredData(data, sanitizeSearchValue(debouncedSearchInput));
    } else {
      result = data;
    }
    return result;
  }, [debouncedSearchInput, data]);

  const flattenedAndFilteredData: FlattenedNode[] = useMemo(() => {
    const flattenOpened = (
      filteredTreeNode: TreeNodeEntry[],
      depth = 0,
      parentLastChildForDepth: number[] = [],
      parentPath?: string
    ): FlattenedNode[] => filteredTreeNode.flatMap((node, index) => {
      const isOpen = openedNodesId.has(node.getKey(parentPath));
      const hasChildren = Boolean(node.getChildrenNode);
      const childLastChildForDepth: number[] = [];
      const isLastNodeFromParent = index === filteredTreeNode.length - 1;
      if (isLastNodeFromParent) {
        childLastChildForDepth.push(...parentLastChildForDepth);
        childLastChildForDepth.push(depth - 1);
      }
      const lastChild = isLastNodeFromParent && (!hasChildren || !isOpen);
      const flattenedNode: FlattenedNode = {
        onOpen: hasChildren && !isOpen ? () => setOpenedNodesId((oldSet) => {
          const newSet = new Set(oldSet);
          return newSet.add(node.getKey(parentPath));
        }) : undefined,
        onClose: hasChildren && isOpen ? () => setOpenedNodesId((oldSet) => {
          const newSet = new Set(oldSet);
          const deleteNodeAndChildren = (treeNodes: TreeNodeEntry[], toDeleteNodeParentPath?: string): void => {
            treeNodes.forEach((n) => {
              if (newSet.has(n.getKey(toDeleteNodeParentPath))) {
                newSet.delete(n.getKey(toDeleteNodeParentPath));
                if (n.getChildrenNode && n.getPath) {
                  deleteNodeAndChildren(n.getChildrenNode(), n.getPath(toDeleteNodeParentPath));
                }
              }
            });
          };
          newSet.delete(node.getKey(parentPath));
          if (node.getChildrenNode && node.getPath) {
            deleteNodeAndChildren(node.getChildrenNode(), node.getPath(parentPath));
          }
          return newSet;
        }) : undefined,
        getChipOption: node.generateGetChipOption(parentPath),
        onClick: node.generateOnChipClick(copyValue, parentPath),
        depth,
        firstChild: index === 0,
        lastChild,
        lastChildForDepth: childLastChildForDepth,
        key: node.getKey(parentPath),
      };
      return [flattenedNode, ...isOpen ? flattenOpened(node.getChildrenNode?.() ?? [], depth + 1, flattenedNode.lastChildForDepth, node.getPath?.(parentPath)) : []];
    });
    return flattenOpened(filteredData);
  }, [filteredData, openedNodesId]);

  return (
    <div className={classes.treeSearchContainer}>
      {search && (
        <SpacedContainer margin={{ bottom: Spacing.s }}>
          <div
            className={classes.searchContainer}
            role="textbox"
            aria-label={i18n`Search element`}
          >
            <TextareaAutosize
              tabIndex={0}
              value={searchTextAreaInput ?? ''}
              placeholder={`${i18n`Search element`}...`}
              className={classnames(classes.textarea, classes[computedTextVariant])}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                setSearchTextAreaInput(event.target.value);
                setLoading(true);
                debounceSearchInput(event.target.value);
              }}
            />
          </div>
        </SpacedContainer>
      )}
      <div className={classes.treeContainer}>
        {treeInfo && (
          <div className={classes.treeInfoContainer}>
            <div className={classes.treeInfoIconContainer}>
              <Icon
                colorVariant={IconColorVariant.secondary}
                name={IconName.info}
              />
            </div>
            <Typo color={theme.color.text.secondary}>{treeInfo}</Typo>
            {loading && (<InlineLoading />)}
          </div>
        )}
        <div className={classes.treeWrapper}>
          <FixedSizeList
            height={214}
            width={300}
            itemCount={flattenedAndFilteredData.length}
            itemData={flattenedAndFilteredData}
            itemSize={28}
          >
            {({ data: nodes, index, style }) => (
              <div key={nodes[index].key} style={style}>
                <TreeNode
                  chipOption={nodes[index].getChipOption()}
                  onClick={nodes[index].onClick}
                  onOpen={nodes[index].onOpen}
                  onClose={nodes[index].onClose}
                  depth={nodes[index].depth}
                  firstChild={nodes[index].firstChild}
                  lastChild={nodes[index].lastChild}
                  parentLastChildForDepth={nodes[index].lastChildForDepth}
                />
              </div>
            )}
          </FixedSizeList>
        </div>
      </div>
    </div>
  );
};
export default Tree;
