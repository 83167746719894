import type { BusinessRuleHandler, ObjectStoreReadOnly } from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import { joinObjects } from 'yooi-utils';
import { CommonAsType } from '../../common/fields/commonPropertyType';
import type { BusinessRuleRegistration } from '../../common/types/TypeModuleDslType';
import { Instance_Of } from '../../typeModule/ids';
import {
  Concept,
  ConceptCollaborationFieldDimension,
  ConceptCreatedAtDimension,
  ConceptDefinition,
  ConceptDefinition_IsCore,
  ConceptDefinition_LibraryShowTable,
  ConceptFunctionalIdDimension,
  ConceptNameDimension,
  ConceptRole,
  ConceptRole_ConceptDefinition,
  ConceptRole_ForCollaboration,
  ConceptRoleGroupAssignation_Role_Concept,
  ConceptRoleGroupAssignation_Role_ConceptRole,
  ConceptRoleUserAssignation_Role_Concept,
  ConceptRoleUserAssignation_Role_ConceptRole,
  ConceptStakeholdersGroupsDimension,
  ConceptStakeholdersUsersDimension,
  ConceptUpdatedAtDimension,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  Group,
  KinshipRelationDimension,
  User,
} from '../ids';
import { registerModel } from '../module';
import { adminOnlyAcl } from '../utils';

const { type, association, associate } = registerModel;

const acceptAnyPropertyDeletionOnInstances: BusinessRuleRegistration = ({ getObjectOrNull, objectEntries }) => (_, { id, properties }) => {
  if (properties === null) {
    const currentObject = getObjectOrNull(id);
    if (currentObject) {
      return {
        rule: 'conceptRole.deletion.accept',
        status: ValidationStatus.ACCEPTED,
        propertyIds: objectEntries(currentObject).map(([key]) => key),
      };
    }
  }
  return undefined;
};

const validateConceptRole = ({ getObjectOrNull }: ObjectStoreReadOnly): BusinessRuleHandler => (_, { id, properties }) => {
  const object = getObjectOrNull(id)?.asRawObject() ?? {};
  const allProperties = joinObjects((object ?? {}), properties);

  if (!allProperties[ConceptRole_ConceptDefinition]) {
    return {
      rule: 'concept.role.missingConceptDefinition',
      status: ValidationStatus.REJECTED,
    };
  } else if (allProperties[ConceptRole_ForCollaboration] !== undefined && typeof allProperties[ConceptRole_ForCollaboration] !== 'boolean') {
    return {
      rule: 'concept.role.invalidForCollaboration',
      status: ValidationStatus.REJECTED,
    };
  } else {
    return {
      rule: 'concept.role.valid',
      propertyIds: [ConceptRole_ConceptDefinition, ConceptRole_ForCollaboration],
      status: ValidationStatus.ACCEPTED,
    };
  }
};

const checkConceptRoleUserAssignationMatch = (
  ruleKey: string,
  assignationRoleConcept: number,
  assignationRoleConceptRole: number
): BusinessRuleRegistration => ({ getObject }: ObjectStoreReadOnly) => (_, { id, properties }) => {
  // Timeseries update or Deletion ? Don't care
  if (!properties) {
    return undefined;
  }

  const conceptId = id[assignationRoleConcept + 1];
  const conceptRoleId = id[assignationRoleConceptRole + 1];

  const concept = getObject(conceptId);
  const conceptRole = getObject(conceptRoleId);
  const conceptElement = concept[Instance_Of];
  const conceptRoleElement = conceptRole[ConceptRole_ConceptDefinition];
  if (conceptElement === conceptRoleElement) {
    return {
      rule: `conceptRole.${ruleKey}.valid`,
      status: ValidationStatus.ACCEPTED,
    };
  } else {
    return {
      rule: `conceptRole.${ruleKey}.conceptRoleConceptMismatch`,
      status: ValidationStatus.REJECTED,
    };
  }
};

type({
  label: 'ConceptRole',
  extends: Concept,
  instanceOf: ConceptDefinition,
  extraProperties: {
    [ConceptDefinition_IsCore]: true,
    [ConceptDefinition_LibraryShowTable]: true,
  },
  accessControlList: {
    CREATE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'conceptRole', 'CREATE'),
    READ: () => () => ({ rule: 'conceptRole.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'conceptRole', 'WRITE'),
    DELETE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'conceptRole', 'DELETE'),
  },
  businessRules: [
    acceptAnyPropertyDeletionOnInstances,
    validateConceptRole,
  ],
})
  .property({ label: 'ConceptRole_ForCollaboration', as: CommonAsType.boolean })
  .relation({ label: 'ConceptRole_ConceptDefinition', targetTypeId: ConceptDefinition, reverseLabel: 'ConceptDefinition_Roles' })
  .property({ label: 'ConceptRole_AssignedByDefault', as: CommonAsType.boolean });

association({
  label: 'ConceptRoleUserAssignation',
  roles: [{ label: 'Concept', targetTypeId: Concept }, { label: 'ConceptRole', targetTypeId: ConceptRole }, { label: 'User', targetTypeId: User }],
  accessControlList: {
    READ: () => () => ({ rule: 'conceptRoleUserAssignation.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: () => (_, objectId) => ({
      rule: 'conceptRoleUserAssignation.write.delegate',
      status: ValidationStatus.DELEGATED,
      targetId: [objectId[ConceptRoleUserAssignation_Role_Concept + 1]],
      targetAction: 'ASSIGN_STAKEHOLDERS',
    }),
    DELETE: () => (_, objectId) => ({
      rule: 'conceptRoleUserAssignation.delete.delegate',
      status: ValidationStatus.DELEGATED,
      targetId: [objectId[ConceptRoleUserAssignation_Role_Concept + 1]],
      targetAction: 'ASSIGN_STAKEHOLDERS',
    }),
  },
  businessRules: [checkConceptRoleUserAssignationMatch('userAssignation', ConceptRoleUserAssignation_Role_Concept, ConceptRoleUserAssignation_Role_ConceptRole)],
});

association({
  label: 'ConceptRoleGroupAssignation',
  roles: [{ label: 'Concept', targetTypeId: Concept }, { label: 'ConceptRole', targetTypeId: ConceptRole }, { label: 'Group', targetTypeId: Group }],
  accessControlList: {
    READ: () => () => ({ rule: 'conceptRoleGroupAssignation.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: () => (_, objectId) => ({
      rule: 'conceptRoleGroupAssignation.write.delegate',
      status: ValidationStatus.DELEGATED,
      targetId: [objectId[ConceptRoleGroupAssignation_Role_Concept + 1]],
      targetAction: 'ASSIGN_STAKEHOLDERS',
    }),
    DELETE: () => (_, objectId) => ({
      rule: 'conceptRoleGroupAssignation.delete.delegate',
      status: ValidationStatus.DELEGATED,
      targetId: [objectId[ConceptRoleGroupAssignation_Role_Concept + 1]],
      targetAction: 'ASSIGN_STAKEHOLDERS',
    }),
  },
  businessRules: [checkConceptRoleUserAssignationMatch('groupAssignation', ConceptRoleGroupAssignation_Role_Concept, ConceptRoleGroupAssignation_Role_ConceptRole)],
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, ConceptRole).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptNameDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, ConceptRole).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCreatedAtDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, ConceptRole).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptUpdatedAtDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, ConceptRole).withRole(FieldDimensionTypes_Role_FieldDimension, KinshipRelationDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, ConceptRole).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptFunctionalIdDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, ConceptRole).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptStakeholdersUsersDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, ConceptRole)
    .withRole(FieldDimensionTypes_Role_FieldDimension, ConceptStakeholdersGroupsDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, ConceptRole)
    .withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCollaborationFieldDimension),
});

export const testables = { validateConceptRole, checkConceptRoleUserAssignationMatch };
