import type { FunctionComponent } from 'react';
import type { ConditionFilterStoreObject, Filters, SingleParameterDefinition, ViewFilterStoreObject } from 'yooi-modules/modules/conceptModule';
import { ConditionFilter_Filters, ViewFilter_Rank } from 'yooi-modules/modules/conceptModule/ids';
import { compareProperty, compareRank, joinObjects } from 'yooi-utils';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { formatOrUndef } from '../../../utils/stringUtils';
import { getQuickFilterLabel, isConditionFilter } from './quickFiltersUtils';
import type { ToggleEntry } from './ToggleBlock';
import ToggleBlock from './ToggleBlock';

interface ConditionFilterBlockProps {
  filters: Record<string, Filters>,
  onChange: (fun: (current: Record<string, Filters> | undefined) => Record<string, Filters> | undefined) => void,
  parameterDefinitions: SingleParameterDefinition[],
  getViewFilters: () => ViewFilterStoreObject[],
}

const ConditionFilterBlock: FunctionComponent<ConditionFilterBlockProps> = ({ filters, onChange, parameterDefinitions, getViewFilters }) => {
  const store = useStore();

  const conditionFilters: ViewFilterStoreObject[] = getViewFilters()
    .filter(isConditionFilter)
    .filter((conditionFilter) => conditionFilter[ConditionFilter_Filters] !== undefined)
    .sort(compareProperty(ViewFilter_Rank, compareRank));

  if (conditionFilters.length === 0) {
    return null;
  }

  const toggles = conditionFilters.map((filter): ToggleEntry => ({
    id: filter.id,
    name: formatOrUndef(getQuickFilterLabel(store, filter.id, parameterDefinitions)),
    color: undefined,
    backgroundColor: undefined,
  }));
  const isActiveIds = conditionFilters.filter((filter) => filters[filter.id])
    .map(({ id }) => id);

  return (
    <ToggleBlock
      title={i18n`Saved filters`}
      toggles={toggles}
      activeIds={isActiveIds}
      onToggle={(id) => {
        const updatedFilters = store.getObject<ConditionFilterStoreObject>(id)[ConditionFilter_Filters] as Filters;
        onChange((current) => {
          if (isActiveIds.includes(id)) {
            const { [id]: unusedId, ...others } = current ?? {};
            return others;
          } else {
            return joinObjects(
              current,
              { [id]: updatedFilters }
            );
          }
        });
      }}
      onClear={() => {
        onChange((current) => Object.fromEntries(
          Object.entries(current ?? {}).filter(([key]) => !conditionFilters.some(({ id }) => key === id))
        ));
      }}
      tooltip={i18n`Saved filters`}
    />
  );
};

export default ConditionFilterBlock;
