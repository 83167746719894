import type { PendingOperation, TimeseriesOperation, TimeseriesRepository, TimeseriesUpdate } from 'yooi-store';
import { DATE_MAX_TIMESTAMP, DATE_MIN_TIMESTAMP } from 'yooi-utils';

interface TimeseriesEventHandler {
  applyTimeseriesOperation: (eventId: string | undefined, operation: PendingOperation, isLocalOrRollback: boolean) => TimeseriesUpdate[],
  applyTimeseriesEvent: (eventId: string | undefined, event: PendingOperation[], isLocalOrRollback: boolean) => TimeseriesUpdate[],
}

const createTimeseriesEventHandler = (
  timeseriesRepository: TimeseriesRepository
): TimeseriesEventHandler => {
  const operationToTimeseriesUpdate = (
    eventId: string | undefined,
    objectId: string | string[],
    properties: Record<string, unknown> | null | undefined,
    timeseries: Record<string, TimeseriesOperation> | undefined
  ): TimeseriesUpdate[] => {
    const operations = [];
    if (properties) {
      const time = eventId ? Number.parseInt(eventId.split('-')[0], 10) : Date.now();
      operations.push(
        ...Object.entries(properties)
          .map(([propertyId, value]) => ({ id: objectId, propertyId, values: [{ time, value }], timeRange: { from: time, to: DATE_MAX_TIMESTAMP } }))
      );
    }
    if (timeseries) {
      Object.entries(timeseries).forEach(([propertyId, timeseriesOperation]) => {
        const { truncate, values } = timeseriesOperation;
        if (truncate) {
          if (typeof truncate === 'boolean') {
            operations.push({ id: objectId, propertyId, values: [], timeRange: { from: DATE_MIN_TIMESTAMP, to: DATE_MAX_TIMESTAMP } });
          } else {
            operations.push({ id: objectId, propertyId, values: [], timeRange: { from: truncate.from, to: truncate.to } });
          }
        }
        if (values) {
          Object.entries(values).forEach(([timestamp, value]) => {
            operations.push({ id: objectId, propertyId, values: [{ time: timestamp, value }], timeRange: { from: Number(timestamp), to: Number(timestamp) + 1 } });
          });
        }
      });
    }
    return operations;
  };

  const applyTimeseriesOperation: TimeseriesEventHandler['applyTimeseriesOperation'] = (eventId, { id, properties, timeseries }, isLocalOrRollback) => {
    const updates: TimeseriesUpdate[] = operationToTimeseriesUpdate(eventId, id, properties, timeseries);
    if (isLocalOrRollback) {
      return timeseriesRepository.updateTimeseries(updates);
    } else {
      return timeseriesRepository.insertInFetchedTimeseries(updates);
    }
  };

  return {
    applyTimeseriesOperation,
    applyTimeseriesEvent: (eventId, operations, isLocalOrRollback) => {
      const rollbackOperations: TimeseriesUpdate[] = [];
      operations.forEach((operation) => {
        rollbackOperations.push(...applyTimeseriesOperation(eventId, operation, isLocalOrRollback));
      });
      return rollbackOperations;
    },
  };
};

export default createTimeseriesEventHandler;
