import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { JssStyle } from 'jss';
import type { MouseEventHandler } from 'react';
import { forwardRef } from 'react';
import { joinObjects } from 'yooi-utils';
import base from '../../theme/base';
import fontDefinition from '../../theme/fontDefinition';
import makeStyles from '../../utils/makeStyles';
import useSizeContext, { buildComponentSizeVariantClasses, HierarchyVariant, SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import useTooltipRef from '../../utils/useTooltipRef';
import type { ButtonVariant } from './Button';
import type { IconColorVariant, IconName } from './Icon';
import Icon from './Icon';

export enum IconOnlyButtonVariants {
  primary = 'primary',
  danger = 'danger',
  secondary = 'secondary',
  tertiary = 'tertiary',
}

export const isIconOnlyButton = (variant: ButtonVariant | IconOnlyButtonVariants): variant is IconOnlyButtonVariants => variant in IconOnlyButtonVariants;

const buildVariantStyles = <VariantName extends IconOnlyButtonVariants>(
  { variantName, normal, hover, focus = {}, pressed = {} }: { variantName: VariantName, normal: JssStyle, hover: JssStyle, focus?: JssStyle, pressed?: JssStyle }
): Record<VariantName | `${VariantName}_hover` | `${VariantName}_focus` | `${VariantName}_pressed`, JssStyle> => {
  const styles: JssStyle = joinObjects(
    {
      '&:hover': hover,
      '&:focus-visible': focus,
      '&:active': pressed,
    },
    normal
  );

  return {
    [variantName]: styles,
    [`${variantName}_hover`]: joinObjects(styles, hover),
    [`${variantName}_focus`]: joinObjects(styles, focus),
    [`${variantName}_pressed`]: joinObjects(styles, pressed),
  } as Record<VariantName | `${VariantName}_hover` | `${VariantName}_focus` | `${VariantName}_pressed`, JssStyle>;
};

const useStyles = makeStyles((theme) => ({
  base: {
    flexShrink: 0, // Prevent icon button from shirking when in a flex container
    flexGrow: 0, // Prevent icon button from growing when in a flex container
    position: 'relative',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
    borderRadius: base.borderRadius.medium,
    boxShadow: base.button.boxShadow,
    borderColor: theme.color.border.button,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    cursor: 'pointer',
    height: 'fit-content',
    padding: 'unset',
    '&:disabled': {
      cursor: 'auto',
    },
    '&:active': {
      boxShadow: base.button.shadow.pressed,
    },
  },
  ...buildVariantStyles({
    variantName: IconOnlyButtonVariants.primary,
    normal: {
      backgroundColor: theme.color.background.primary.default,
      color: theme.color.text.white,

      '&:disabled': {
        opacity: base.opacity.twenty,
      },
    },
    hover: {
      backgroundColor: theme.color.background.primary.hover,
    },
    focus: {
      backgroundColor: theme.color.background.primary.hover,
    },
    pressed: {
      backgroundColor: theme.color.background.primary.pressed,
    },
  }),
  ...buildVariantStyles({
    variantName: IconOnlyButtonVariants.secondary,
    normal: {
      color: theme.color.text.primary,
      backgroundColor: theme.color.background.neutral.default,
      '&:disabled': {
        opacity: base.opacity.twenty,
      },
    },
    hover: {
      backgroundColor: theme.color.background.neutral.subtle,
    },
    focus: {
      backgroundColor: theme.color.background.neutral.subtle,
    },
    pressed: {
      backgroundColor: theme.color.background.neutral.muted,
    },
  }),
  ...buildVariantStyles({
    variantName: IconOnlyButtonVariants.danger,
    normal: {
      backgroundColor: theme.color.background.neutral.default,
      color: theme.color.text.danger,
      '&:disabled': {
        opacity: base.opacity.twenty,
      },
    },
    hover: {
      backgroundColor: theme.color.background.danger.hover,
      color: theme.color.text.white,
    },
    focus: {
      backgroundColor: theme.color.background.danger.hover,
      color: theme.color.text.white,
    },
    pressed: {
      backgroundColor: theme.color.background.danger.pressed,
      color: theme.color.text.white,
    },
  }),
  ...buildVariantStyles({
    variantName: IconOnlyButtonVariants.tertiary,
    normal: {
      boxShadow: 'none',
      backgroundColor: theme.color.transparent,
      borderColor: theme.color.transparent,
      color: theme.color.text.brand,

      '&:disabled': {
        opacity: base.opacity.twenty,
      },
    },
    hover: {
      backgroundColor: theme.color.background.primarylight.default,
    },
    focus: {
      backgroundColor: theme.color.background.primarylight.default,
    },
    pressed: {
      backgroundColor: theme.color.background.primarylight.pressed,
      boxShadow: 'none',
    },
  }),
  chip: {
    position: 'absolute',
    width: '1.6rem',
    height: '1.6rem',
    borderRadius: base.borderRadius.circle,
    top: '-0.4rem',
    right: '-0.4rem',
    background: theme.color.text.info,
    color: theme.color.text.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: fontDefinition.tabTitle.fontFamily,
    fontStyle: fontDefinition.tabTitle.fontStyle,
    fontWeight: fontDefinition.tabTitle.fontWeight,
    fontSize: '1rem',
  },
  mainChip: {
    width: '1.6rem',
    height: '1.6rem',
    top: '-0.4rem',
    right: '-0.4rem',
    fontWeight: fontDefinition.tabTitle.fontWeight,
    fontSize: '1rem',
  },
  smallChip: {
    width: '1.2rem',
    height: '1.2rem',
    top: '-0.1rem',
    right: '-0.1rem',
    fontWeight: fontDefinition.buttonMain.fontWeight,
    fontSize: '0.8rem',
  },
  extraSmallChip: {
    width: '1rem',
    height: '1rem',
    top: '-0.1rem',
    right: '-0.1rem',
    fontWeight: fontDefinition.buttonMain.fontWeight,
    fontSize: '0.8rem',
  },
  outOfBoundChip: {
    top: '-0.3rem',
    right: '-0.3rem',
  },
  ...buildComponentSizeVariantClasses('button', ['height', 'width']),
}), 'iconOnlyButton');

interface IconOnlyButtonProps {
  tooltip: string,
  iconName: IconName,
  sizeVariant?: SizeVariant,
  variant?: IconOnlyButtonVariants,
  colorVariant?: IconColorVariant,
  state?: 'hover' | 'pressed',
  disabled?: boolean,
  onClick?: MouseEventHandler<HTMLButtonElement>,
  onMouseDown?: MouseEventHandler<HTMLButtonElement>,
  count?: number,
  outOfBoundChip?: boolean,
}

const IconOnlyButton = forwardRef<HTMLButtonElement, IconOnlyButtonProps>(({
  tooltip,
  iconName,
  sizeVariant,
  variant = IconOnlyButtonVariants.primary,
  colorVariant,
  state,
  disabled = false,
  onClick,
  onMouseDown,
  count,
  outOfBoundChip,
}, ref) => {
  const classes = useStyles();

  const tooltipRef = useTooltipRef(tooltip);

  const { sizeVariant: contextSizeVariant, hierarchyVariant } = useSizeContext();
  const sizeContextComputed = sizeVariant ?? contextSizeVariant ?? SizeVariant.main;
  let variantClassName: IconOnlyButtonVariants | `${IconOnlyButtonVariants}_hover` | `${IconOnlyButtonVariants}_pressed`;
  switch (state) {
    case 'hover':
      variantClassName = `${variant}_hover`;
      break;
    case 'pressed':
      variantClassName = `${variant}_pressed`;
      break;
    default:
      variantClassName = variant;
  }

  return (
    <button
      ref={composeReactRefs<HTMLButtonElement>(tooltipRef, ref)}
      className={classnames(classes.base, classes[variantClassName], classes[`button_${sizeContextComputed}_${hierarchyVariant}`])}
      type="button"
      aria-label={tooltip}
      disabled={disabled}
      onKeyDown={(e) => {
        if (onClick !== undefined) {
          e.stopPropagation();
        }
      }}
      onKeyUp={(e) => {
        if (onClick !== undefined) {
          e.stopPropagation();
        }
      }}
      onMouseDown={(e) => {
        if (onMouseDown) {
          onMouseDown(e);
        }
      }}
      onClick={(e) => {
        if (onClick !== undefined) {
          e.stopPropagation();
          onClick(e);
        }
      }}
    >
      <SizeContextProvider sizeVariant={sizeContextComputed}>
        <Icon name={iconName} colorVariant={colorVariant} />
      </SizeContextProvider>
      {(count !== undefined || outOfBoundChip) && (
        <div
          className={classnames({
            [classes.chip]: true,
            [classes.mainChip]: (sizeContextComputed === SizeVariant.title || sizeContextComputed === SizeVariant.main) && hierarchyVariant === HierarchyVariant.content,
            [classes.smallChip]: sizeContextComputed === SizeVariant.subtitle || sizeContextComputed === SizeVariant.tabs || hierarchyVariant !== HierarchyVariant.content,
            [classes.extraSmallChip]: sizeContextComputed === SizeVariant.small || hierarchyVariant !== HierarchyVariant.content,
            [classes.outOfBoundChip]: outOfBoundChip,
          })}
        >
          {count}
        </div>
      )}
    </button>
  );
});

export default IconOnlyButton;
