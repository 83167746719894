import type { BusinessRuleHandler, ObjectStoreReadOnly } from 'yooi-store';
import { OriginSources, ValidationStatus } from 'yooi-store';
import { asImport, CommonAsType } from '../common/fields/commonPropertyType';
import { PropertyMandatoryType } from '../common/types/TypeModuleDslType';
import { adminOnlyAcl } from '../conceptModule';
import {
  Concept,
  ConceptCapabilityAssignCollaborator,
  ConceptCapabilityAssignUser,
  ConceptCapabilityCreate,
  ConceptCapabilityDelete,
  ConceptCapabilityEdit,
  ConceptCapabilityRead,
  ConceptDefinition,
  ConceptIntegrationCapability,
  ConceptIntegrationCapability_Role_ConceptCapability,
  ConceptIntegrationCapability_Role_Definition,
  ConceptIntegrationCapability_Role_Integration,
  Field,
  FieldDefinition,
} from '../conceptModule/ids';
import { Template_Integrations } from '../integrationModule/ids';
import {
  ConceptDefinition_MappedTemplateConceptDefinitions,
  Template,
  TemplateConcept_Name,
  TemplateConcept_TemplateConceptDefinition,
  TemplateConceptDefinition,
  TemplateConceptDefinition_MappedConceptDefinition,
  TemplateConceptDefinition_Name,
  TemplateConceptDefinition_Template,
  TemplateConceptDefinition_TemplateConcepts,
  TemplateConceptDefinition_TemplateFields,
  TemplateField_Name,
  TemplateField_TemplateConceptDefinition,
} from './ids';
import { registerModel } from './module';

const { type } = registerModel;

type({
  label: 'Template',
  accessControlList: {
    READ: (store) => ({ userId, source }, objectId) => {
      switch (source) {
        case OriginSources.API: {
          const integrationId = store.getObjectOrNull(objectId[0])?.navigateBack(Template_Integrations)[0]?.id;
          if (userId === integrationId) {
            return { rule: 'template.integration.read.allowed', status: ValidationStatus.ACCEPTED };
          } else {
            return { rule: 'template.integration.read.denied', status: ValidationStatus.REJECTED };
          }
        }
        default:
          return adminOnlyAcl(store, userId, 'template', 'READ');
      }
    },
    WRITE: (store) => ({ userId, source }, objectId) => {
      switch (source) {
        case OriginSources.API: {
          const object = store.getObjectOrNull(objectId[0]);
          if (object) {
            const integrationId = object?.navigateBack(Template_Integrations)[0]?.id;
            if (userId === integrationId) {
              return { rule: 'template.integration.update.allowed', status: ValidationStatus.ACCEPTED };
            } else {
              return { rule: 'template.integration.update.denied', status: ValidationStatus.REJECTED };
            }
          } else {
            return { rule: 'template.integration.creation.allowed', status: ValidationStatus.ACCEPTED };
          }
        }
        default:
          return adminOnlyAcl(store, userId, 'template', 'WRITE');
      }
    },
    DELETE: (store) => ({ userId, source }, objectId) => {
      switch (source) {
        case OriginSources.API: {
          const integrationId = store.getObjectOrNull(objectId[0])?.navigateBack(Template_Integrations)[0]?.id;
          if (userId === integrationId) {
            return { rule: 'template.integration.delete.allowed', status: ValidationStatus.ACCEPTED };
          } else {
            return { rule: 'template.integration.delete.denied', status: ValidationStatus.REJECTED };
          }
        }
        default:
          return adminOnlyAcl(store, userId, 'template', 'DELETE');
      }
    },
  },
});

const handleMissingPermissions = ({ getObject }: ObjectStoreReadOnly): BusinessRuleHandler => (_, { id, properties }) => {
  if (!id || !properties || !properties[TemplateConceptDefinition_MappedConceptDefinition]) {
    return undefined;
  }
  const conceptDefinitionId = properties[TemplateConceptDefinition_MappedConceptDefinition] as string;
  const integrationId = getObject(id).navigateOrNull(TemplateConceptDefinition_Template)?.navigateBack(Template_Integrations)[0]?.id as string;

  if (!integrationId) {
    return undefined;
  }

  return {
    rule: 'templateConceptDefinition.permissions.accept',
    status: ValidationStatus.ACCEPTED,
    generateSystemEvent: ({ withAssociation }) => {
      let collectMappingCapabilities: string[] = [];
      const templateConceptDefinitions = getObject(conceptDefinitionId)
        .navigateBack(ConceptDefinition_MappedTemplateConceptDefinitions)
        .filter((tcd) => tcd.navigateOrNull(TemplateConceptDefinition_Template)
          ?.navigateBack(Template_Integrations)
          .some(({ id: iId }) => iId === integrationId) ?? false);
      if (templateConceptDefinitions.some((tcd) => tcd.navigateBack(TemplateConceptDefinition_TemplateFields).length > 0)) {
        collectMappingCapabilities = [
          ConceptCapabilityRead,
          ConceptCapabilityCreate,
          ConceptCapabilityEdit,
          ConceptCapabilityDelete,
          ConceptCapabilityAssignUser,
          ConceptCapabilityAssignCollaborator,
        ];
      } else if (templateConceptDefinitions.some((tcd) => tcd.navigateBack(TemplateConceptDefinition_TemplateConcepts).length > 0)) {
        collectMappingCapabilities = [ConceptCapabilityRead];
      }

      const assignedCapabilitiesIds = withAssociation(ConceptIntegrationCapability)
        .withRole(ConceptIntegrationCapability_Role_Integration, integrationId)
        .withRole(ConceptIntegrationCapability_Role_Definition, conceptDefinitionId)
        .list()
        .map((cic) => cic.role(ConceptIntegrationCapability_Role_ConceptCapability));

      const leftToMap = collectMappingCapabilities.filter((cId) => !assignedCapabilitiesIds.includes(cId));
      if (leftToMap.length > 0) {
        leftToMap.forEach((cId) => withAssociation(ConceptIntegrationCapability)
          .withRole(ConceptIntegrationCapability_Role_Integration, integrationId)
          .withRole(ConceptIntegrationCapability_Role_Definition, conceptDefinitionId)
          .withRole(ConceptIntegrationCapability_Role_ConceptCapability, cId)
          .updateObject({}));
      }
    },
  };
};
type({
  label: 'TemplateConceptDefinition',
  accessControlList: {
    READ: (store) => ({ userId, source }, objectId) => {
      switch (source) {
        case OriginSources.API: {
          const integrationId = store.getObjectOrNull(objectId[0])?.navigateOrNull(TemplateConceptDefinition_Template)?.navigateBack(Template_Integrations)[0]?.id;
          if (userId === integrationId) {
            return { rule: 'templateConceptDefinition.integration.read.allowed', status: ValidationStatus.ACCEPTED };
          } else {
            return { rule: 'templateConceptDefinition.integration.read.denied', status: ValidationStatus.REJECTED };
          }
        }
        default:
          return adminOnlyAcl(store, userId, 'templateConceptDefinition', 'READ');
      }
    },
    WRITE: (store) => ({ userId, source }, objectId) => {
      const { getObjectOrNull } = store;
      switch (source) {
        case OriginSources.API: {
          const object = getObjectOrNull(objectId[0]);
          if (object) {
            const integrationId = object?.navigateOrNull(TemplateConceptDefinition_Template)?.navigateBack(Template_Integrations)[0]?.id;
            if (userId === integrationId) {
              return { rule: 'templateConceptDefinition.integration.update.allowed', status: ValidationStatus.ACCEPTED };
            } else {
              return { rule: 'templateConceptDefinition.integration.update.denied', status: ValidationStatus.REJECTED };
            }
          } else {
            return { rule: 'templateConceptDefinition.integration.creation.allowed', status: ValidationStatus.ACCEPTED };
          }
        }
        default:
          return adminOnlyAcl(store, userId, 'templateConceptDefinition', 'WRITE');
      }
    },
    DELETE: (store) => ({ userId, source }, objectId) => {
      const { getObjectOrNull } = store;
      switch (source) {
        case OriginSources.API: {
          const integrationId = getObjectOrNull(objectId[0])?.navigateOrNull(TemplateConceptDefinition_Template)?.navigateBack(Template_Integrations)[0]?.id;
          if (userId === integrationId) {
            return { rule: 'templateConceptDefinition.integration.delete.allowed', status: ValidationStatus.ACCEPTED };
          } else {
            return { rule: 'templateConceptDefinition.integration.delete.denied', status: ValidationStatus.REJECTED };
          }
        }
        default:
          return adminOnlyAcl(store, userId, 'templateConceptDefinition', 'DELETE');
      }
    },
  },
  objectDebugLabel: ({ getObject }) => (objectId) => getObject(objectId)[TemplateConceptDefinition_Name] as string,
})
  .property({ label: 'TemplateConceptDefinition_Name', as: CommonAsType.string })
  .property({ label: 'TemplateConceptDefinition_Metadata', as: CommonAsType.unknown })
  .relation({ label: 'TemplateConceptDefinition_Template', reverseLabel: 'Template_TemplateConceptDefinitions', targetTypeId: Template })
  .relation({
    label: 'TemplateConceptDefinition_MappedConceptDefinition',
    reverseLabel: 'ConceptDefinition_MappedTemplateConceptDefinitions',
    targetTypeId: ConceptDefinition,
    businessRules: [handleMissingPermissions],
  });

type({
  label: 'TemplateField',
  accessControlList: {
    READ: (store) => ({ userId, source }, objectId) => {
      switch (source) {
        case OriginSources.API: {
          const integrationId = store.getObjectOrNull(objectId[0])
            ?.navigateOrNull(TemplateField_TemplateConceptDefinition)
            ?.navigateOrNull(TemplateConceptDefinition_Template)
            ?.navigateBack(Template_Integrations)[0]?.id;
          if (userId === integrationId) {
            return { rule: 'templateField.integration.read.allowed', status: ValidationStatus.ACCEPTED };
          } else {
            return { rule: 'templateField.integration.read.denied', status: ValidationStatus.REJECTED };
          }
        }
        default:
          return adminOnlyAcl(store, userId, 'templateField', 'READ');
      }
    },
    WRITE: (store) => ({ userId, source }, objectId) => {
      switch (source) {
        case OriginSources.API: {
          const object = store.getObjectOrNull(objectId[0]);
          if (object) {
            const integrationId = object
              ?.navigateOrNull(TemplateField_TemplateConceptDefinition)
              ?.navigateOrNull(TemplateConceptDefinition_Template)
              ?.navigateBack(Template_Integrations)[0]?.id;
            if (userId === integrationId) {
              return { rule: 'templateField.integration.update.allowed', status: ValidationStatus.ACCEPTED };
            } else {
              return { rule: 'templateField.integration.update.denied', status: ValidationStatus.REJECTED };
            }
          } else {
            return { rule: 'templateField.integration.creation.allowed', status: ValidationStatus.ACCEPTED };
          }
        }
        default:
          return adminOnlyAcl(store, userId, 'templateField', 'WRITE');
      }
    },
    DELETE: (store) => ({ userId, source }, objectId) => {
      switch (source) {
        case OriginSources.API: {
          const integrationId = store.getObjectOrNull(objectId[0])
            ?.navigateOrNull(TemplateField_TemplateConceptDefinition)
            ?.navigateOrNull(TemplateConceptDefinition_Template)
            ?.navigateBack(Template_Integrations)[0]?.id;
          if (userId === integrationId) {
            return { rule: 'templateField.integration.delete.allowed', status: ValidationStatus.ACCEPTED };
          } else {
            return { rule: 'templateField.integration.delete.denied', status: ValidationStatus.REJECTED };
          }
        }
        default:
          return adminOnlyAcl(store, userId, 'templateField', 'DELETE');
      }
    },
  },
  objectDebugLabel: ({ getObject }) => (objectId) => getObject(objectId)[TemplateField_Name] as string,
})
  .property({ label: 'TemplateField_Name', as: CommonAsType.string })
  .property({ label: 'TemplateField_Metadata', as: CommonAsType.unknown })
  .relation({ label: 'TemplateField_Type', reverseLabel: 'FieldDefinition_TemplateFields', targetTypeId: FieldDefinition, mandatory: { type: PropertyMandatoryType.mandatory } })
  .property({ label: 'TemplateField_Mode', as: asImport('TemplateFieldMode', 'modules/templateModule/module') })
  .relation({
    label: 'TemplateField_TemplateConceptDefinition',
    reverseLabel: 'TemplateConceptDefinition_TemplateFields',
    targetTypeId: TemplateConceptDefinition,
    mandatory: { type: PropertyMandatoryType.mandatory },
  })
  .relation({ label: 'TemplateField_MappedField', reverseLabel: 'Field_MappedTemplateFields', targetTypeId: Field });

type({
  label: 'TemplateConcept',
  accessControlList: {
    READ: (store) => ({ userId, source }, objectId) => {
      switch (source) {
        case OriginSources.API: {
          const integrationId = store.getObjectOrNull(objectId[0])
            ?.navigateOrNull(TemplateConcept_TemplateConceptDefinition)
            ?.navigateOrNull(TemplateConceptDefinition_Template)
            ?.navigateBack(Template_Integrations)[0]?.id;
          if (userId === integrationId) {
            return { rule: 'templateConcept.integration.read.allowed', status: ValidationStatus.ACCEPTED };
          } else {
            return { rule: 'templateConcept.integration.read.denied', status: ValidationStatus.REJECTED };
          }
        }
        default:
          return adminOnlyAcl(store, userId, 'templateConcept', 'READ');
      }
    },
    WRITE: (store) => ({ userId, source }, objectId) => {
      switch (source) {
        case OriginSources.API: {
          const object = store.getObjectOrNull(objectId[0]);
          if (object) {
            const integrationId = object
              ?.navigateOrNull(TemplateConcept_TemplateConceptDefinition)
              ?.navigateOrNull(TemplateConceptDefinition_Template)
              ?.navigateBack(Template_Integrations)[0]?.id;
            if (userId === integrationId) {
              return { rule: 'templateConcept.integration.update.allowed', status: ValidationStatus.ACCEPTED };
            } else {
              return { rule: 'templateConcept.integration.update.denied', status: ValidationStatus.REJECTED };
            }
          } else {
            return { rule: 'templateConcept.integration.creation.allowed', status: ValidationStatus.ACCEPTED };
          }
        }
        default:
          return adminOnlyAcl(store, userId, 'templateConcept', 'WRITE');
      }
    },
    DELETE: (store) => ({ userId, source }, objectId) => {
      switch (source) {
        case OriginSources.API: {
          const integrationId = store.getObjectOrNull(objectId[0])
            ?.navigateOrNull(TemplateConcept_TemplateConceptDefinition)
            ?.navigateOrNull(TemplateConceptDefinition_Template)
            ?.navigateBack(Template_Integrations)[0]?.id;
          if (userId === integrationId) {
            return { rule: 'templateConcept.integration.delete.allowed', status: ValidationStatus.ACCEPTED };
          } else {
            return { rule: 'templateConcept.integration.delete.denied', status: ValidationStatus.REJECTED };
          }
        }
        default:
          return adminOnlyAcl(store, userId, 'templateConcept', 'DELETE');
      }
    },
  },
  objectDebugLabel: ({ getObject }) => (objectId) => getObject(objectId)[TemplateConcept_Name] as string,
})
  .property({ label: 'TemplateConcept_Name', as: CommonAsType.string })
  .property({ label: 'TemplateConcept_Metadata', as: CommonAsType.unknown })
  .property({ label: 'TemplateConcept_Mandatory', as: CommonAsType.boolean })
  .relation({ label: 'TemplateConcept_TemplateConceptDefinition', reverseLabel: 'TemplateConceptDefinition_TemplateConcepts', targetTypeId: TemplateConceptDefinition })
  .relation({ label: 'TemplateConcept_MappedConcept', reverseLabel: 'Field_MappedTemplateConcepts', targetTypeId: Concept });
