import type { FunctionComponent } from 'react';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import useWidgetSizeContext from '../../utils/useWidgetSizeContext';
import Loading from '../molecules/Loading';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: spacingRem.splus,
    paddingRight: spacingRem.xs,
    paddingBottom: spacingRem.xs,
    paddingTop: spacingRem.splus,
  },
}, 'widgetLoadingPlaceholder');

const WidgetLoadingPlaceholder: FunctionComponent = () => {
  const classes = useStyles();

  const { width, height } = useWidgetSizeContext();

  return (
    <div className={classes.container} style={{ height, width }}>
      <Loading />
    </div>
  );
};

export default WidgetLoadingPlaceholder;
