import type {
  ColorFieldStoreObject,
  ConceptDefinitionStoreObject,
  ConceptStoreObject,
  FieldStoreObject,
  IconFieldStoreObject,
  ParametersMapping,
  PathStep,
  RelationSingleFieldStoreObject,
  WorkflowFieldStoreObject,
} from 'yooi-modules/modules/conceptModule';
import {
  colorFieldHandler,
  createValuePathResolver,
  getFieldDimensionOfModelType,
  getFieldUtilsHandler,
  getPathLastFieldInformation,
  iconFieldHandler,
  isConceptValid,
  isDimensionLibraryPath,
  isFieldStep,
  isGlobalDimensionStep,
  isMappingStep,
  isSingleFieldResolution,
  isSingleRelationalType,
  isSingleValueResolution,
  PathStepType,
  relationSingleFieldHandler,
  workflowFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import {
  ColorField,
  ColorField_DefaultColor,
  ConceptDefinition_ChipBackgroundColor,
  ConceptDefinition_ChipIconField,
  ConceptDefinition_Color,
  Group,
  IconField,
  RelationSingleField,
  RelationSingleField_TargetType,
  User,
  WorkflowField,
  WorkflowField_Workflow,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreReadOnly, ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import type { ProgressFieldData } from '../../components/charts/TimelineEntry';
import type { FrontObjectStore } from '../../store/useStore';
import base from '../../theme/base';
import { darken } from '../../theme/colorUtils';
import i18n from '../../utils/i18n';
import { getInstanceMaxMinValues, TickResolutionStatus } from './fieldUtils';
import type { PathStepValidator } from './pathConfigurationHandler';
import { StepValidationState } from './pathConfigurationHandler';

export const getConceptModelDisplayField = <FieldId extends string>(
  store: ObjectStoreWithTimeseries,
  conceptDefinitionId: string,
  fieldId: FieldId,
  sessionStorageConfig?: Record<FieldId, string | null | undefined> | undefined
): FieldStoreObject | undefined | null => {
  if (!conceptDefinitionId) {
    return undefined;
  }
  if (sessionStorageConfig?.[fieldId] !== undefined) {
    if (sessionStorageConfig[fieldId] === null) {
      return null;
    }
    return store.getObjectOrNull<FieldStoreObject>(sessionStorageConfig[fieldId]);
  }
  const conceptDefinition = store.getObject(conceptDefinitionId);
  return conceptDefinition.navigateOrNull<FieldStoreObject>(fieldId);
};

export const getConceptInstanceDisplayField = (
  store: ObjectStoreWithTimeseries,
  conceptId: string,
  fieldId: string,
  defaultViewFieldId: string | undefined,
  isInView: boolean,
  sessionStorageConfig?: Record<string, unknown>
): null | StoreObject => {
  if (isInView && !sessionStorageConfig?.[fieldId]) {
    return defaultViewFieldId ? store.getObjectOrNull(defaultViewFieldId) : null;
  } else {
    if (sessionStorageConfig?.[fieldId] !== undefined) {
      if (sessionStorageConfig[fieldId] === null || typeof sessionStorageConfig[fieldId] !== 'string') {
        return null;
      }
      return store.getObjectOrNull(sessionStorageConfig[fieldId]);
    }
    const concept = store.getObject(conceptId);
    return concept.navigate(Instance_Of).navigateOrNull(fieldId);
  }
};

export const resolveConceptColorField = (
  store: ObjectStoreWithTimeseries,
  conceptId: string,
  sessionStorageConfig?: { [ConceptDefinition_Color]: string | null | undefined } | undefined,
  viewDefaultColorFieldId?: string | undefined,
  isInView?: boolean
): null | undefined | StoreObject => {
  const concept = store.getObjectOrNull(conceptId);
  if (!concept || !isConceptValid(store, concept.id)) {
    return undefined;
  } else if (sessionStorageConfig?.[ConceptDefinition_Color] !== undefined) {
    const id = sessionStorageConfig[ConceptDefinition_Color];
    if (id === null || id === undefined || typeof id !== 'string') {
      return null;
    }
    return store.getObjectOrNull(id);
  } else if (isInView && viewDefaultColorFieldId) {
    return store.getObjectOrNull(viewDefaultColorFieldId);
  } else {
    return concept.navigate(Instance_Of).navigateOrNull(ConceptDefinition_Color);
  }
};

const isColor = (strColor: string | undefined): boolean => {
  if (!strColor) {
    return false;
  }
  const s = new Option().style;
  s.color = strColor;
  return s.color !== '';
};

export const resolveConceptDefinitionColorValue = (store: ObjectStoreWithTimeseries, conceptDefinitionId: string): string | undefined => {
  const field = getConceptModelDisplayField(store, conceptDefinitionId, ConceptDefinition_Color);
  if (!field) {
    return undefined;
  } else if (isInstanceOf<ColorFieldStoreObject>(field, ColorField)) {
    const defaultColor = field[ColorField_DefaultColor];
    return isColor(defaultColor) ? defaultColor : undefined;
  } else if (isInstanceOf<RelationSingleFieldStoreObject>(field, RelationSingleField)) {
    const targetType = field.navigateOrNull(RelationSingleField_TargetType);
    if (targetType) {
      return resolveConceptDefinitionColorValue(store, targetType.id);
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const resolveColor = (store: ObjectStoreWithTimeseries, conceptDefinitionId: string, conceptId: string, fieldId: string): string | undefined => {
  const field = store.getObject(fieldId);
  if (isInstanceOf<ColorFieldStoreObject>(field, ColorField)) {
    const { resolveConfiguration, resolveConfigurationWithOverride, getValueResolution } = colorFieldHandler(store, fieldId);

    const dimensionId = getFieldDimensionOfModelType(store, field.id, conceptDefinitionId);

    const { defaultColor } = dimensionId !== undefined ? resolveConfigurationWithOverride({ [dimensionId]: conceptId }) : resolveConfiguration();

    const value = dimensionId ? getValueResolution({ [dimensionId]: conceptId }).value ?? defaultColor : defaultColor;

    return isColor(value) ? value : undefined;
  } else if (isInstanceOf<RelationSingleFieldStoreObject>(field, RelationSingleField)) {
    const { getValueResolution } = relationSingleFieldHandler(store, field.id);

    const dimensionId = getFieldDimensionOfModelType(store, field.id, conceptDefinitionId);
    const value = dimensionId ? getValueResolution({ [dimensionId]: conceptId }).value : undefined;

    if (value) {
      const targetConceptColorField = resolveConceptColorField(store, value.id, undefined, undefined, false);
      return targetConceptColorField ? resolveColor(store, value[Instance_Of] as string, value.id, targetConceptColorField.id) : undefined;
    } else {
      const targetType = field.navigateOrNull(RelationSingleField_TargetType);
      return targetType ? resolveConceptDefinitionColorValue(store, targetType.id) : undefined;
    }
  } else if (isInstanceOf<WorkflowFieldStoreObject>(field, WorkflowField)) {
    const { getValueResolution } = workflowFieldHandler(store, field.id);

    const dimensionId = getFieldDimensionOfModelType(store, field.id, conceptDefinitionId);
    const value = dimensionId ? getValueResolution({ [dimensionId]: conceptId }).value.value : undefined;

    if (value) {
      const targetConceptColorField = resolveConceptColorField(store, value.id, undefined, undefined, false);
      return targetConceptColorField ? resolveColor(store, value[Instance_Of], value.id, targetConceptColorField.id) : undefined;
    } else {
      const targetType = field.navigateOrNull(RelationSingleField_TargetType);
      return targetType ? resolveConceptDefinitionColorValue(store, targetType.id) : undefined;
    }
  } else {
    return undefined;
  }
};

export const resolveInstanceColor = (store: ObjectStoreWithTimeseries, conceptId: string): string | undefined => {
  const concept = store.getObject<ConceptStoreObject>(conceptId);
  const conceptDefinition = concept.navigate<ConceptDefinitionStoreObject>(Instance_Of);
  const conceptColorField = conceptDefinition.navigateOrNull(ConceptDefinition_Color);
  if (conceptColorField === null) {
    return undefined;
  }

  return resolveColor(store, conceptDefinition.id, conceptId, conceptColorField.id);
};

export const resolveConceptColorValue = (
  store: ObjectStoreWithTimeseries,
  conceptId: string,
  sessionStorageConfig?: { [ConceptDefinition_Color]: string | null | undefined },
  viewDefaultColorFieldId?: string | undefined,
  isInView?: boolean
): string | undefined => {
  const concept = store.getObjectOrNull<ConceptStoreObject>(conceptId);
  if (!concept || !isConceptValid(store, conceptId)) {
    return undefined;
  }

  const field = resolveConceptColorField(store, concept.id, sessionStorageConfig, viewDefaultColorFieldId, isInView);
  if (field) {
    return resolveColor(store, concept[Instance_Of], conceptId, field.id);
  } else {
    return undefined;
  }
};

export const resolveConceptChipIcon = (store: ObjectStoreWithTimeseries, conceptId: string): { icon: string, color: string } | undefined => {
  // conceptId could be deleted or a custom undefined key
  const concept = store.getObjectOrNull<ConceptStoreObject>(conceptId);
  if (!concept || !isConceptValid(store, conceptId)) {
    return undefined;
  }

  const chipIconField = concept.navigate(Instance_Of).navigateOrNull(ConceptDefinition_ChipIconField);

  if (chipIconField === null) {
    return undefined;
  }

  const dimensionId = getFieldDimensionOfModelType(store, chipIconField.id, concept[Instance_Of]);
  if (dimensionId === undefined) {
    return undefined;
  }

  if (isInstanceOf<IconFieldStoreObject>(chipIconField, IconField)) {
    return iconFieldHandler(store, chipIconField.id).getValueResolution({ [dimensionId]: conceptId }).value;
  } else if (isInstanceOf<RelationSingleFieldStoreObject>(chipIconField, RelationSingleField)) {
    const targetedConcept = relationSingleFieldHandler(store, chipIconField.id)
      .getValueResolution({ [dimensionId]: conceptId })
      .value;

    return targetedConcept === undefined ? undefined : resolveConceptChipIcon(store, targetedConcept.id);
  } else if (isInstanceOf<WorkflowFieldStoreObject>(chipIconField, WorkflowField)) {
    const targetedConcept = workflowFieldHandler(store, chipIconField.id)
      .getValueResolution({ [dimensionId]: conceptId })
      .value
      .value;

    return targetedConcept === undefined ? undefined : resolveConceptChipIcon(store, targetedConcept.id);
  } else {
    return undefined;
  }
};

export const getColorPathPickerValidator = (store: ObjectStoreReadOnly): PathStepValidator => ({ pathStep, isNPath, path }) => {
  if (isGlobalDimensionStep(pathStep)) {
    return [{ state: StepValidationState.invalid, reasonMessage: i18n`Filters do not support global dimension steps` }];
  } else if (isFieldStep(pathStep)) {
    const fieldDefinitionId = store.getObjectOrNull<FieldStoreObject>(pathStep.fieldId)?.[Instance_Of];
    if (!fieldDefinitionId) {
      return [{ state: StepValidationState.invalid, reasonMessage: i18n`Input end with an unknown field.` }];
    } else if (isSingleRelationalType(fieldDefinitionId) || ColorField === fieldDefinitionId) {
      return [{ state: StepValidationState.valid }];
    } else {
      return [{ state: StepValidationState.invalid, reasonMessage: i18n`Input end with an unauthorized field.` }];
    }
  } else if (isNPath) {
    if (isDimensionLibraryPath(path)) {
      return [{ state: StepValidationState.valid }];
    } else {
      return [{
        state: path.length === 1 ? StepValidationState.partiallyValid : StepValidationState.invalid,
        reasonMessage: i18n`Input should be unique, use a mapping in your path.`,
      }];
    }
  } else if (isMappingStep(pathStep) && path.length === 2) {
    return [{ state: StepValidationState.valid }];
  } else {
    return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`Input end with an unauthorized element.` }];
  }
};

export const getFieldPathPickerValidator = (store: ObjectStoreReadOnly, targetTypeId: string): PathStepValidator => ({ pathStep, isNPath, path }) => {
  if (isGlobalDimensionStep(pathStep)) {
    return [{ state: StepValidationState.invalid, reasonMessage: i18n`Filters do not support global dimension steps` }];
  } else if (isFieldStep(pathStep)) {
    const fieldDefinitionId = store.getObjectOrNull<FieldStoreObject>(pathStep.fieldId)?.[Instance_Of];
    if (!fieldDefinitionId) {
      return [{ state: StepValidationState.invalid, reasonMessage: i18n`Input end with an unknown field.` }];
    } else if (isSingleRelationalType(fieldDefinitionId)) {
      return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`Input end with an unauthorized field.` }];
    } else if (targetTypeId === fieldDefinitionId) {
      return [{ state: StepValidationState.valid }];
    } else {
      return [{ state: StepValidationState.invalid, reasonMessage: i18n`Input end with an unauthorized field.` }];
    }
  } else if (isNPath) {
    if (isDimensionLibraryPath(path)) {
      return [{ state: StepValidationState.valid }];
    } else {
      return [{
        state: path.length === 1 ? StepValidationState.partiallyValid : StepValidationState.invalid,
        reasonMessage: i18n`Input should be unique, use a mapping in your path.`,
      }];
    }
  } else if (isMappingStep(pathStep) && path.length === 2) {
    return [{ state: StepValidationState.valid }];
  } else {
    return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`Input end with an unauthorized element.` }];
  }
};

export const getConceptProgressValue = <FieldId extends string>(
  store: FrontObjectStore,
  conceptId: string,
  fieldId: FieldId,
  config: Record<FieldId, string | null | undefined> | undefined,
  defaultViewFieldId: string | undefined,
  isInView: boolean
): ProgressFieldData | undefined => {
  const progressField = getConceptInstanceDisplayField(store, conceptId, fieldId, defaultViewFieldId, isInView, config);
  const concept = store.getObjectOrNull(conceptId);
  const dimension = concept && progressField ? getFieldDimensionOfModelType(store, progressField.id, concept[Instance_Of] as string) : undefined;
  const progressValue = progressField
    ? getFieldUtilsHandler(store, progressField.id).getValueResolution(dimension ? { [dimension]: conceptId } : {})?.value as number | undefined
    : undefined;
  const instanceMaxMin = progressField ? getInstanceMaxMinValues(store, progressField.id, conceptId, {}) : undefined;
  return progressField ? {
    field: isInView && !config?.[fieldId] && defaultViewFieldId ? defaultViewFieldId : fieldId,
    value: progressValue,
    min: instanceMaxMin?.min?.status === TickResolutionStatus.Resolved ? instanceMaxMin?.min.value : undefined,
    max: instanceMaxMin?.max?.status === TickResolutionStatus.Resolved ? instanceMaxMin?.max.value : undefined,
  } : undefined;
};

// This needs to handle circular dependency in coloration

export const isFieldAvailableForColoration = (store: ObjectStoreWithTimeseries, conceptDefinitionId: string, fieldId: string): boolean => {
  const field = store.getObject(fieldId);
  if (isInstanceOf<ColorFieldStoreObject>(field, ColorField)) {
    return true;
  } else if (isInstanceOf<RelationSingleFieldStoreObject>(field, RelationSingleField)) {
    const targetTypeId = field[RelationSingleField_TargetType];
    if ([conceptDefinitionId, User, Group].includes(targetTypeId)) {
      return false;
    }
    const targetColorationField = getConceptModelDisplayField(store, targetTypeId, ConceptDefinition_Color);
    if (targetColorationField) {
      return isFieldAvailableForColoration(store, conceptDefinitionId, targetColorationField.id);
    } else {
      return true;
    }
  } else if (isInstanceOf<WorkflowFieldStoreObject>(field, WorkflowField)) {
    const workflow = field.navigateOrNull(WorkflowField_Workflow);
    if (workflow) {
      const targetColorationField = getConceptModelDisplayField(store, workflow.id, ConceptDefinition_Color);
      if (targetColorationField) {
        return isFieldAvailableForColoration(store, conceptDefinitionId, targetColorationField.id);
      } else {
        return true;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};
export const chipDefaultBackgroundColor = base.color.gray['300'];

export const conceptDefinitionChipBackgroundColor = base.color.gray['100'];
export const computeBorderColor = (color: string): string => darken(color, 10);

export const getModelBackgroundColor = (instance: ConceptStoreObject): string => {
  const conceptDefinition = instance.navigateOrNull<ConceptDefinitionStoreObject>(Instance_Of);
  return conceptDefinition?.[ConceptDefinition_ChipBackgroundColor] || chipDefaultBackgroundColor;
};

export const resolveColorFromPath = (store: FrontObjectStore, parametersMapping: ParametersMapping, path: PathStep[]): string | undefined => {
  const lastFieldInformation = getPathLastFieldInformation(path);
  if (lastFieldInformation !== undefined) {
    const lastField = store.getObjectOrNull<FieldStoreObject>(lastFieldInformation.fieldId);
    if (lastField !== null) {
      if (isInstanceOf(lastField, ColorField)) {
        const resolvedField = createValuePathResolver(store, parametersMapping).resolvePathField(path);
        if (isSingleFieldResolution(resolvedField) && resolvedField.dimensionsMapping) {
          const valueResolution = colorFieldHandler(store, resolvedField.fieldId).getValueResolution(resolvedField.dimensionsMapping);
          if (!valueResolution.error) {
            return valueResolution.value;
          }
        }
        return undefined;
      } else if (isSingleRelationalType(lastField[Instance_Of])) {
        const valuePathResolver = createValuePathResolver(store, parametersMapping);
        const resolvedValue = valuePathResolver.resolvePathValue<StoreObject | undefined>(path);
        if (isSingleValueResolution(resolvedValue) && resolvedValue.value) {
          return resolveConceptColorValue(store, resolvedValue.value.id);
        } else {
          return undefined;
        }
      }
    }
  } else if (path.length === 2 && path[1].type === PathStepType.mapping) {
    const valuePathResolver = createValuePathResolver(store, parametersMapping);
    const resolvedValue = valuePathResolver.resolvePathValue<StoreObject | undefined>(path);
    if (isSingleValueResolution(resolvedValue) && resolvedValue.value) {
      return resolveConceptColorValue(store, resolvedValue.value.id);
    } else {
      return undefined;
    }
  } else if (isDimensionLibraryPath(path)) {
    const { conceptDefinitionId } = path[0];
    return computeBorderColor(store.getObjectOrNull(conceptDefinitionId)?.[ConceptDefinition_ChipBackgroundColor] as string | undefined ?? conceptDefinitionChipBackgroundColor);
  }
  return undefined;
};
