import type { FunctionComponent, ReactNode } from 'react';
import makeStyles from '../../utils/makeStyles';

const useStyles = makeStyles({
  container: {
    display: 'contents',
  },
}, 'stopClickPropagation');

interface StopClickPropagationProps {
  children: ReactNode,
}

const StopClickPropagation: FunctionComponent<StopClickPropagationProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <span
      className={classes.container}
      onClick={(event) => {
        event.stopPropagation();
      }}
      aria-hidden="true"
    >
      {children}
    </span>
  );
};

export default StopClickPropagation;
