import type { FunctionComponent } from 'react';
import { ColorService } from 'react-color-palette';
import type { DimensionsMapping } from 'yooi-modules/modules/conceptModule';
import { canWrite, colorFieldHandler, isEmbeddedAsIntegrationOnly, ParsedDimensionType, parseDimensionMapping } from 'yooi-modules/modules/conceptModule';
import ColorPicker, { ColorPickerVariant } from '../../../../components/inputs/ColorPicker';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import useAcl from '../../../../store/useAcl';
import useActivity from '../../../../store/useActivity';
import useStore from '../../../../store/useStore';
import useUpdateActivity from '../../../../store/useUpdateActivity';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';
import { getColorPalette } from '../../../utils/standardColorsUtils';
import StoreColorPickerInput from '../../input/StoreColorPickerInput';

interface ColorFieldRendererProps {
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
  readOnly: boolean,
  focusOnMount: boolean,
  value?: string | undefined,
  onSubmit?: (newColor: string | null) => void,
  variant?: ColorPickerVariant,
}

const ColorFieldRenderer: FunctionComponent<ColorFieldRendererProps> = ({ fieldId, dimensionsMapping, readOnly = false, value, onSubmit, focusOnMount = false, variant }) => {
  const store = useStore();
  const { canWriteObject } = useAcl();

  const activity = useActivity();
  const updateActivity = useUpdateActivity();

  const fieldHandler = colorFieldHandler(store, fieldId);
  const colorField = fieldHandler.resolveConfiguration();

  const parsedDimension = parseDimensionMapping(dimensionsMapping);

  const usageVariant = useUsageContext();
  const isEditing = parsedDimension.type === ParsedDimensionType.MonoDimensional ? activity.listEditor(parsedDimension.objectId, fieldId).length > 0 : false;

  const valueResolution = fieldHandler.getValueResolution(dimensionsMapping);
  const isReadOnly = readOnly
    || colorField.integrationOnly
    || !canWrite(dimensionsMapping, canWriteObject)
    || (parsedDimension.type === ParsedDimensionType.MonoDimensional && isEmbeddedAsIntegrationOnly(store.getObject(parsedDimension.objectId)));

  const colorPalette = getColorPalette(store);

  let component;
  if (onSubmit) {
    component = (
      <ColorPicker
        value={value ? ColorService.convert('hex', value) : undefined}
        defaultValue={colorField.defaultColor ? ColorService.convert('hex', colorField.defaultColor) : undefined}
        onChange={(color) => onSubmit(color?.hex ?? null)}
        onEditionStart={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => updateActivity.onEnterEdition(parsedDimension.objectId, fieldId) : undefined}
        onEditionStop={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => updateActivity.onExitEdition(parsedDimension.objectId, fieldId) : undefined}
        readOnly={isReadOnly}
        isEditing={isEditing}
        focusOnMount={focusOnMount}
        colorPalette={colorPalette}
        variant={variant}
      />
    );
  } else {
    component = (
      <StoreColorPickerInput
        initialValue={valueResolution.value}
        defaultValue={colorField.defaultColor}
        onSubmit={(newColor) => fieldHandler.updateValue(dimensionsMapping, newColor ?? null)}
        onEditionStart={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => updateActivity.onEnterEdition(parsedDimension.objectId, fieldId) : undefined}
        onEditionStop={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => updateActivity.onExitEdition(parsedDimension.objectId, fieldId) : undefined}
        readOnly={isReadOnly}
        isEditing={isEditing}
        focusOnMount={focusOnMount}
        colorPalette={colorPalette}
        variant={variant}
      />
    );
  }

  if (usageVariant === UsageVariant.inCard && variant !== ColorPickerVariant.bar && variant !== ColorPickerVariant.dot) {
    return (
      <SpacingLine>
        {component}
      </SpacingLine>
    );
  } else {
    return component;
  }
};

export default ColorFieldRenderer;
