import type { FunctionComponent, ReactNode } from 'react';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    columnGap: spacingRem.s,
    alignItems: 'center',
    justifyContent: 'end',
    gridAutoFlow: 'column',
  },
}, 'headerActions');

interface HeaderActionsProps {
  children: ReactNode,
}

const HeaderActions: FunctionComponent<HeaderActionsProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {children}
    </div>
  );
};

export default HeaderActions;
