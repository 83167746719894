import type { ObjectStoreReadOnly } from 'yooi-store';
import { newError } from 'yooi-utils';
import { EmbeddingField, EmbeddingField_FromType, EmbeddingField_ToType } from '../conceptModule/ids';
import { DataAsset } from '../dataAssetModule/ids';
import { Class_Instances } from '../typeModule/ids';
import { Resource } from './ids';

export const getResourcesToDataAssetsEmbeddingFieldInstanceId = (store: ObjectStoreReadOnly): string => {
  const embeddingField = store.getObject(EmbeddingField)
    .navigateBack(Class_Instances)
    .find((i) => i[EmbeddingField_ToType] === Resource && i[EmbeddingField_FromType] === DataAsset);
  if (!embeddingField) {
    throw newError('Embedding field not found');
  }
  return embeddingField.id;
};
