import type { CollaborationMessageStoreObject, CollaborationStoreObject, CollaborationUserInfoStoreObject, IntentionStoreObject } from 'yooi-modules/modules/collaborationModule';
import { getCollaborationContextsObjectIds } from 'yooi-modules/modules/collaborationModule';
import {
  Collaboration_Hash,
  Collaboration_Messages,
  Collaboration_Path,
  Collaboration_UpdatedAt,
  CollaborationGroups,
  CollaborationGroups_Role_Collaboration,
  CollaborationGroups_Role_Group,
  CollaborationMessage_Collaboration,
  CollaborationMessage_CreatedAt,
  CollaborationMessage_CreatedBy,
  CollaborationMessage_UpdatedAt,
  CollaborationOnMultipleContext,
  CollaborationOnMultipleContext_Role_Collaboration,
  CollaborationRoles,
  CollaborationRoles_Role_Collaboration,
  CollaborationRoles_Role_Role,
  CollaborationUserInfo,
  CollaborationUserInfo_LastRead,
  CollaborationUserInfo_Role_Collaboration,
  CollaborationUserInfo_Role_User,
  CollaborationUsers,
  CollaborationUsers_Role_Collaboration,
  CollaborationUsers_Role_User,
  Intention,
  Intention_Available,
  Intention_Name,
  Intention_Workflow,
  IntentionConcepts,
  IntentionConcepts_Role_ConceptDefinition,
  IntentionConcepts_Role_Intention,
  IntentionGroups,
  IntentionGroups_Role_Group,
  IntentionGroups_Role_Intention,
  IntentionStatusEntries,
  IntentionStatusEntries_Role_Entry,
  IntentionStatusEntries_Role_Intention,
  IntentionStatusIsClosedStatus,
  IntentionUsers,
  IntentionUsers_Role_Intention,
  IntentionUsers_Role_User,
} from 'yooi-modules/modules/collaborationModule/ids';
import { Concept, ConceptDefinition, WorkflowEntry, WorkflowEntry_Rank, WorkflowEntry_Role_Concept, WorkflowEntry_Role_Workflow } from 'yooi-modules/modules/conceptModule/ids';
import { Notification_Role_Message } from 'yooi-modules/modules/notificationModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStore, ObjectStoreAssociation, ObjectStoreReadOnly, StoreObject } from 'yooi-store';
import { compareNumber, compareProperty, compareRank, durationFromNow, extractAndCompareValue, filterNullOrUndefined, formatForStorage, joinObjects } from 'yooi-utils';
import i18n from '../../../../../utils/i18n';

export const formatRelativeDate = (date: Date): string => {
  const duration = durationFromNow(date);
  if (duration.years < 0) {
    return duration.years === -1 ? i18n`1 year ago` : i18n`${Math.abs(duration.years)} years ago`;
  } else if (duration.months < 0) {
    return duration.months === -1 ? i18n`1 month ago` : i18n`${Math.abs(duration.months)} months ago`;
  } else if (duration.weeks < 0) {
    return duration.weeks === -1 ? i18n`1 week ago` : i18n`${Math.abs(duration.weeks)} weeks ago`;
  } else if (duration.days < 0) {
    return duration.days === -1 ? i18n`1 day ago` : i18n`${Math.abs(duration.days)} days ago`;
  } else if (duration.hours < 0) {
    return duration.hours === -1 ? i18n`1 hour ago` : i18n`${Math.abs(duration.hours)} hours ago`;
  } else if (duration.minutes < 0) {
    return duration.minutes === -1 ? i18n`1 minute ago` : i18n`${Math.abs(duration.minutes)} minutes ago`;
  } else {
    return i18n`Just now`;
  }
};

export const getLastRead = (store: ObjectStoreReadOnly, collaborationId: string, userId: string): number => (
  store.withAssociation(CollaborationUserInfo)
    .withRole(CollaborationUserInfo_Role_Collaboration, collaborationId)
    .withRole(CollaborationUserInfo_Role_User, userId)
    .getObjectOrNull<CollaborationUserInfoStoreObject>()
    ?.[CollaborationUserInfo_LastRead] ?? 0
);

export const updateLastRead = (store: ObjectStore, collaborationId: string, userId: string): number => {
  const lastRead = formatForStorage(new Date());
  store
    .withAssociation(CollaborationUserInfo)
    .withRole(CollaborationUserInfo_Role_Collaboration, collaborationId)
    .withRole(CollaborationUserInfo_Role_User, userId)
    .updateObject({ [CollaborationUserInfo_LastRead]: lastRead });
  return lastRead ?? 0;
};

export const buildCollaborationLink = (store: ObjectStoreReadOnly, collaborationId: string, window: Window): string => {
  const collaboration = store.getObject(collaborationId);
  return `${window.location.origin}${collaboration[Collaboration_Path]}?collaboration=${collaborationId}${collaboration[Collaboration_Hash] ? `${collaboration[Collaboration_Hash]}` : ''}`;
};

export const getCollaborationMessages = (store: ObjectStoreReadOnly, collaborationId: string, sorted: boolean): CollaborationMessageStoreObject[] => {
  const collaboration = store.getObjectOrNull<CollaborationStoreObject>(collaborationId);
  if (collaboration === null) {
    return [];
  } else {
    const messages = collaboration.navigateBack<CollaborationMessageStoreObject>(CollaborationMessage_Collaboration);
    if (sorted) {
      return messages.sort(compareProperty(CollaborationMessage_CreatedAt, compareNumber));
    } else {
      return messages;
    }
  }
};

export const getLastUpdatedDateForCollaboration = (store: ObjectStoreReadOnly, collaboration: CollaborationStoreObject): number | undefined => {
  const datesToCompare: number[] = [];
  if (collaboration[Collaboration_UpdatedAt]) {
    datesToCompare.push(collaboration[Collaboration_UpdatedAt]);
  }
  const messages = getCollaborationMessages(store, collaboration.id, false);
  const lastUpdatedMessageDate = messages
    .sort(compareProperty(CollaborationMessage_UpdatedAt, compareNumber))
    .at(-1)
    ?.[CollaborationMessage_UpdatedAt];
  if (lastUpdatedMessageDate) {
    datesToCompare.push(lastUpdatedMessageDate);
  }
  return datesToCompare.sort(compareNumber).at(-1);
};

export const isUserRecipient = (store: ObjectStoreReadOnly, collaborationId: string, userId: string, userGroupsIds: string[], userRolesIds: string[]): boolean => (
  store.withAssociation(CollaborationUsers)
    .withRole(CollaborationUsers_Role_Collaboration, collaborationId)
    .withRole(CollaborationUsers_Role_User, userId)
    .getObjectOrNull() !== null
  || userGroupsIds.some((groupId) => (
    store.withAssociation(CollaborationGroups)
      .withRole(CollaborationGroups_Role_Collaboration, collaborationId)
      .withRole(CollaborationGroups_Role_Group, groupId)
      .getObjectOrNull() !== null
  ))
  || (!!collaborationId && userRolesIds.some((roleId) => (
    store.withAssociation(CollaborationRoles)
      .withRole(CollaborationRoles_Role_Collaboration, collaborationId)
      .withRole(CollaborationRoles_Role_Role, roleId)
      .getObjectOrNull() !== null
  )))
);

const isMessageNotRead = (store: ObjectStoreReadOnly, message: CollaborationMessageStoreObject, userId: string): boolean => {
  if (!message[CollaborationMessage_CreatedBy] || message[CollaborationMessage_CreatedBy] === userId) {
    return false;
  } else {
    const thread = store
      .withAssociation(CollaborationUserInfo)
      .withRole(CollaborationUserInfo_Role_Collaboration, message[CollaborationMessage_Collaboration])
      .withRole(CollaborationUserInfo_Role_User, userId)
      .getObjectOrNull<CollaborationUserInfoStoreObject>();
    return !thread || (message[CollaborationMessage_CreatedAt] ?? 0) > (thread[CollaborationUserInfo_LastRead] ?? 0);
  }
};

export const getCollaborationsUnreadMessageCount = (store: ObjectStoreReadOnly, collaborations: CollaborationStoreObject[], recipientId: string): number => (
  collaborations
    .flatMap((collaboration) => collaboration.navigateBack<CollaborationMessageStoreObject>(Collaboration_Messages))
    .reduce((acc, message) => (isMessageNotRead(store, message, recipientId) ? acc + 1 : acc), 0)
);

export const getCollaborationStatusIds = (store: ObjectStoreReadOnly, intentionId: string): string[] => {
  const workflowId = intentionId ? store.getObjectOrNull<IntentionStoreObject>(intentionId)?.[Intention_Workflow] : undefined;
  if (workflowId === undefined) {
    return [];
  } else {
    return store.withAssociation(WorkflowEntry)
      .withRole(WorkflowEntry_Role_Workflow, workflowId)
      .list()
      .sort(extractAndCompareValue((({ object }) => object[WorkflowEntry_Rank] as string), compareRank))
      .map((assoc) => assoc.role(WorkflowEntry_Role_Concept));
  }
};

export const getMostRelevantContextForCollaboration = (store: ObjectStoreReadOnly, collaborationId: string, context?: string[], pageContext?: string[][]): string[] | undefined => {
  const multipleContextCollabs = store.withAssociation(CollaborationOnMultipleContext).withRole(CollaborationOnMultipleContext_Role_Collaboration, collaborationId).list();
  let relevantContext = context;
  let derivedContext: string[][];
  const lastPageContextId = pageContext && pageContext[pageContext.length - 1].length === 1 ? pageContext[pageContext.length - 1][0] : undefined;
  if (context) {
    const assoIdsWithContext = multipleContextCollabs.map((assoId) => (context.every((contextId) => assoId.object.id.includes(contextId)) ? assoId : undefined))
      .filter(filterNullOrUndefined);
    const longestContext = assoIdsWithContext.length > 0
      ? assoIdsWithContext.filter((assId) => assId.object.id.length === Math.max(...assoIdsWithContext.map((id) => id.object.id.length)))[0].object.id.slice(2)
      : undefined;
    if (longestContext && longestContext.length > context.length) {
      relevantContext = longestContext;
    } else {
      derivedContext = multipleContextCollabs.map((assoId) => {
        const lastId = assoId.object.id[assoId.object.id.length - 1];
        const object = store.getObject(lastId);
        if (!isInstanceOf(object, ConceptDefinition) && lastId !== lastPageContextId) {
          return assoId.object.id.slice(2);
        }
        return undefined;
      }).filter(filterNullOrUndefined);
      if (derivedContext.length === 1) {
        relevantContext = [...derivedContext[0]];
      }
    }
  }
  return relevantContext;
};

export const getSubscriptionInstanceForCollaboration = (store: ObjectStoreReadOnly, collaborationId: string): string | undefined => {
  const collaborationContexts = getCollaborationContextsObjectIds(store, collaborationId);
  return collaborationContexts.find((id) => {
    const object = store.getObjectOrNull(id);
    return !isInstanceOf(object, ConceptDefinition);
  });
};

export const getIntentionUsers = (store: ObjectStore, intentionId: string): string[] => store.withAssociation(IntentionUsers)
  .withRole(IntentionUsers_Role_Intention, intentionId)
  .list()
  .map((assoc) => assoc.role(IntentionUsers_Role_User));

export const getIntentionGroups = (store: ObjectStore, intentionId: string): string[] => store.withAssociation(IntentionGroups)
  .withRole(IntentionGroups_Role_Intention, intentionId)
  .list()
  .map((assoc) => assoc.role(IntentionGroups_Role_Group));

export const duplicateIntention = (store: ObjectStore, id: string, onDuplicate?: (id: string) => void): string => {
  const objectToCopy = store.getObject(id).asRawObject();
  const newIntentionId = store.createObject(joinObjects(
    objectToCopy,
    { [Intention_Name]: `${objectToCopy[Intention_Name]} (copy)` }
  ));
  const intentionStatus = store.withAssociation(IntentionStatusEntries).withRole(IntentionStatusEntries_Role_Intention, id).list();
  intentionStatus.forEach((assoc) => {
    store.withAssociation(IntentionStatusEntries)
      .withRole(IntentionStatusEntries_Role_Intention, newIntentionId)
      .withRole(IntentionStatusEntries_Role_Entry, assoc.role(IntentionStatusEntries_Role_Entry))
      .updateObject({ [IntentionStatusIsClosedStatus]: assoc.object[IntentionStatusIsClosedStatus] });
  });
  const intentionUsers = getIntentionUsers(store, id);
  intentionUsers.forEach((user) => {
    store.withAssociation(IntentionUsers)
      .withRole(IntentionUsers_Role_Intention, newIntentionId)
      .withRole(IntentionUsers_Role_User, user)
      .updateObject({});
  });

  const intentionGroups = getIntentionGroups(store, id);
  intentionGroups.forEach((group) => {
    store.withAssociation(IntentionGroups)
      .withRole(IntentionGroups_Role_Intention, newIntentionId)
      .withRole(IntentionGroups_Role_Group, group)
      .updateObject({});
  });

  const intentionConcepts = store.withAssociation(IntentionConcepts).withRole(IntentionConcepts_Role_Intention, id).list();
  intentionConcepts.forEach((assoc) => {
    store.withAssociation(IntentionConcepts)
      .withRole(IntentionConcepts_Role_Intention, newIntentionId)
      .withRole(IntentionConcepts_Role_ConceptDefinition, assoc.role(IntentionConcepts_Role_ConceptDefinition))
      .updateObject({});
  });

  if (onDuplicate) {
    onDuplicate(newIntentionId);
  }
  return newIntentionId;
};

export const isIntentionAvailableOnConcept = (store: ObjectStore, intentionId: string, conceptId: string): boolean => {
  const intention = store.getObjectOrNull(intentionId);
  if (intention) {
    const concept = store.getObjectOrNull(conceptId);
    const intentionAvailableAssoc = concept && store.withAssociation(IntentionConcepts)
      .withRole(IntentionConcepts_Role_ConceptDefinition, concept.id)
      .withRole(IntentionConcepts_Role_Intention, intention.id).getObjectOrNull();
    return intention[Intention_Available] === 'all'
      || (intention[Intention_Available] === 'all_except' && !intentionAvailableAssoc)
      || (intention[Intention_Available] === 'only' && !!intentionAvailableAssoc);
  } else {
    return false;
  }
};

export const getIntentions = (store: ObjectStore, conceptDefinitionId: string | undefined): IntentionStoreObject[] => {
  if (conceptDefinitionId !== undefined) {
    return store.getObject(Intention).navigateBack<IntentionStoreObject>(Instance_Of)
      .filter((intention) => isIntentionAvailableOnConcept(store, intention.id, conceptDefinitionId));
  } else {
    return store.getObject(Intention).navigateBack<IntentionStoreObject>(Instance_Of);
  }
};

export const collaborationAvailableFilter = (store: ObjectStore, notificationAssociation: ObjectStoreAssociation<StoreObject<string[]>>): boolean => {
  let intentions: IntentionStoreObject[] = [];
  const message = notificationAssociation.navigateRoleOrNull(Notification_Role_Message) ?? undefined;
  if (message) {
    const collaboration = message.navigateOrNull(CollaborationMessage_Collaboration) ?? undefined;
    if (collaboration) {
      const collaborationContextsObjectIds = getCollaborationContextsObjectIds(store, collaboration.id);
      const firstClassConceptId = collaborationContextsObjectIds?.find((objectId) => isInstanceOf(store.getObject(objectId), Concept));
      let conceptDefinitionId: string | undefined;
      if (firstClassConceptId) {
        const concept = store.getObjectOrNull(firstClassConceptId);
        conceptDefinitionId = concept?.[Instance_Of] as string | undefined;
      } else {
        conceptDefinitionId = collaborationContextsObjectIds?.find((id) => id.length === 1 && isInstanceOf(store.getObject(id[0]), ConceptDefinition))?.[0];
      }
      intentions = getIntentions(store, conceptDefinitionId);
    }
  }
  return intentions.length > 0;
};
