import { useMemo } from 'react';
import useStoreContext from './useStoreContext';

interface UpdateActivity {
  onView: (objectId: string | null) => void,
  onEnterEdition: (objectId: string, fieldId: string) => void,
  onExitEdition: (objectId: string, fieldId: string) => void,
}

const useUpdateActivity = (): UpdateActivity => {
  const { activity: { onView, onEnterEdition, onExitEdition } } = useStoreContext();

  // when possible, return a stable object across renders
  return useMemo(
    () => ({ onView, onEnterEdition, onExitEdition }),
    [onView, onEnterEdition, onExitEdition]
  );
};

export default useUpdateActivity;
