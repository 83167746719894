import composeReactRefs from '@seznam/compose-react-refs';
import type { FunctionComponent } from 'react';
import { useRef } from 'react';
import i18n from '../../utils/i18n';
import useInViewCallback from '../../utils/useInViewCallback';
import { SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import type { IconName } from '../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../atoms/IconOnlyButton';
import type { MenuItem } from './Menu';
import Menu from './Menu';

interface MoreMenuButtonProps {
  icon: IconName,
  items: MenuItem[],
  isOpen: boolean,
  open: () => void,
  close: () => void,
}

const MoreMenuButton: FunctionComponent<MoreMenuButtonProps> = ({ icon, items, isOpen, open, close }) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const observeRef = useInViewCallback((inView) => {
    if (!inView) {
      close();
    }
  }, isOpen);

  return (
    <>
      <IconOnlyButton
        ref={composeReactRefs<HTMLButtonElement>(buttonRef, observeRef)}
        tooltip={i18n`More`}
        onClick={open}
        iconName={icon}
        variant={IconOnlyButtonVariants.tertiary}
      />
      {isOpen ? (
        <SizeContextProvider sizeVariant={SizeVariant.main}>
          <Menu
            items={items}
            anchorRef={buttonRef}
            onClose={close}
            placement="bottom"
          />
        </SizeContextProvider>
      ) : null}
    </>
  );
};

export default MoreMenuButton;
