import type { GenericGetDslFieldHandler } from 'yooi-modules/modules/common/fields/FieldModuleDslType';
import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import type { FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { joinObjects, newError } from 'yooi-utils';
import type { FrontObjectStore } from '../../../store/useStore';
import { formatOrUndef } from '../../../utils/stringUtils';
import type { FieldDefinition, FieldDefinitionHandler, FieldHandler, GetFieldDefinitionHandler } from './FieldLibraryTypes';

/* eslint-disable @typescript-eslint/no-explicit-any */
const fieldDefinitionMap: Map<string, (objectStore: FrontObjectStore) => FieldDefinitionHandler<GenericGetDslFieldHandler, any, any, any, any>> = new Map();

const buildFieldDefinitionHandlerGenerator = <DslFieldHandler extends GenericGetDslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>(
  getHandler: DslFieldHandler,
  { configuration }: FieldDefinition<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>,
  fieldHandlerGenerator: (objectStore: FrontObjectStore, fieldId: string) => GetFieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>
) => (objectStore: FrontObjectStore): FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration> => ({
  typeIcon: configuration.typeIcon,
  getTypeLabel: configuration.getTypeLabel,
  asWidget: configuration.asWidget,
  getFieldTitle: configuration.getFieldTitle ? configuration.getFieldTitle(objectStore) : () => configuration.getTypeLabel(),
  getFieldSubTitle: configuration.getFieldSubTitle?.(objectStore),
  getFieldIcon: configuration.getFieldIcon?.(objectStore),
  canCreate: configuration.canCreate?.(objectStore),
  onCreate: configuration.onCreate?.(objectStore),
  onWidgetCreate: configuration.onWidgetCreate?.(objectStore),
  isCreationEnabled: configuration.isCreationEnabled?.(objectStore),
  getEditionOptions: configuration.getEditionOptions(objectStore),
  getTargetFields: configuration.getTargetFields?.(objectStore),
  inlineCreate: configuration.inlineCreate?.(objectStore),
  getHandler: (fieldId) => fieldHandlerGenerator(objectStore, fieldId),
  ofField: (fieldId) => {
    const handler = getHandler(objectStore, fieldId) as ReturnType<DslFieldHandler>;
    const { getIcon, ...ofField } = configuration.ofField(objectStore, fieldId, handler);
    return joinObjects(
      ofField,
      {
        getIcon: () => getIcon?.() ?? configuration.typeIcon,
        getTypeLabel: ofField.getTypeLabel ?? configuration.getTypeLabel,
        getTitle: () => handler.resolveConfiguration().title,
        getDocumentation: () => {
          const { documentation, isDocumentationInline } = handler.resolveConfiguration();
          if (documentation) {
            return { value: documentation, isInline: isDocumentationInline ?? false };
          } else {
            return undefined;
          }
        },
      }
    );
  },
});

export const getFieldDefinitionHandler = (
  objectStore: FrontObjectStore,
  fieldDefinitionId: string
): FieldDefinitionHandler<GenericGetDslFieldHandler, Record<string, unknown>, Record<string, unknown>, Record<string, unknown>, Record<string, unknown>> => {
  const fieldDefinition = objectStore.getObject(fieldDefinitionId);
  const registration = fieldDefinitionMap.get(fieldDefinition.id);
  if (!registration) {
    throw newError('Unknown field definition', { fieldDefinitionId });
  }
  return registration(objectStore);
};

export const getFieldConfigurationHandler = (
  objectStore: FrontObjectStore,
  fieldId: string
): (Omit<FieldDefinitionHandler<GenericGetDslFieldHandler, Record<string, unknown>, Record<string, unknown>, Record<string, unknown>, Record<string, unknown>>, 'ofField'> & ReturnType<FieldDefinitionHandler<GenericGetDslFieldHandler, Record<string, unknown>, Record<string, unknown>, Record<string, unknown>, Record<string, unknown>>['ofField']>) => {
  const field = objectStore.getObject<FieldStoreObject>(fieldId);
  const fieldDefinitionId = field[Instance_Of];

  const { ofField, ...fieldDefinitionHandler } = getFieldDefinitionHandler(objectStore, fieldDefinitionId);

  return joinObjects(
    fieldDefinitionHandler,
    ofField(fieldId)
  );
};

const buildFieldHandlerGenerator = <DslFieldHandler extends GenericGetDslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>(
  getHandler: DslFieldHandler,
  definition: FieldDefinition<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>
) => (objectStore: FrontObjectStore, fieldId: string): GetFieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration> => {
  const handler = getHandler(objectStore, fieldId) as ReturnType<DslFieldHandler>;

  const fieldConfigurationHandler = getFieldConfigurationHandler(objectStore, fieldId);

  const columnDefinitionMethod = definition.getColumnDefinition?.(objectStore, fieldId, handler);

  return {
    id: fieldId,
    definitionId: getHandler.fieldDefinitionId,
    getAdditionalBlockFieldProps: definition.getAdditionalBlockFieldProps?.(objectStore, fieldId, handler),
    renderField: definition.renderField?.(objectStore, fieldId, handler),
    input: definition.input?.(objectStore, fieldId, handler),
    getUpdateOperationInput: definition.getUpdateOperationInput?.(objectStore, fieldId, handler),
    renderBlockField: definition.renderBlockField?.(objectStore, fieldId, handler),
    renderSuggestedField: definition.renderSuggestedField?.(objectStore, fieldId, handler),
    renderWidget: definition.renderWidget?.(objectStore, fieldId, handler),
    renderExportConfiguration: definition.renderExportConfiguration?.(objectStore, fieldId, handler),
    getActivityProperties: definition.getActivityProperties?.(objectStore, fieldId, handler),
    getColumnDefinition: columnDefinitionMethod ? () => {
      const columnDefinition = columnDefinitionMethod();
      return (joinObjects(
        columnDefinition,
        {
          name: columnDefinition.name ?? formatOrUndef(fieldConfigurationHandler.getTitle()),
          tooltip: columnDefinition.tooltip ?? fieldConfigurationHandler.getDocumentation()?.value,
        }
      ));
    } : undefined,
    estimatedColumnWidth: definition.estimatedColumnWidth?.(objectStore, fieldId, handler),
    estimatedCardHeight: definition.estimatedCardHeight?.(objectStore, fieldId, handler),
    getComparatorHandler: definition.getComparatorHandler?.(objectStore, fieldId, handler),
    filterConditions: definition.filterConditions?.(objectStore, fieldId, handler),
    blockDisplayOptionsHandler: definition.blockDisplayOptionsHandler?.(objectStore, fieldId, handler),
    fieldDisplayOptionsHandler: definition.fieldDisplayOptionsHandler?.(objectStore, fieldId, handler),
    valueValidation: definition.valueValidation?.(objectStore, fieldId, handler),
    configuration: fieldConfigurationHandler,
  };
};

export type GetFieldHandler<
  DslFieldHandler extends GenericGetDslFieldHandler = GenericGetDslFieldHandler,
  FieldDisplayOptions = Record<string, unknown>,
  BlockDisplayOptions = FieldBlockDisplayOptions,
  ExportConfiguration = object
> = FieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration> & { configuration: ReturnType<typeof getFieldConfigurationHandler> };

export const getFieldHandler = (objectStore: FrontObjectStore, fieldId: string): GetFieldHandler | undefined => {
  const field = objectStore.getObjectOrNull<FieldStoreObject>(fieldId);
  if (!field) {
    return undefined;
  }
  const registration = fieldDefinitionMap.get(field[Instance_Of]);
  if (!registration) {
    return undefined;
  }
  return registration(objectStore).getHandler(fieldId);
};

export const registerFieldDefinition = <
  DslFieldHandler extends GenericGetDslFieldHandler,
  ConfigurationState,
  FieldDisplayOptions = never,
  BlockDisplayOptions = never,
  ExportConfiguration = never
>(
    handler: DslFieldHandler,
    definition: FieldDefinition<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>
  ): GetFieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration> => {
  if (fieldDefinitionMap.has(handler.fieldDefinitionId)) {
    if (import.meta.hot) {
      // Error on hmr, force reload
      window.location.reload();
    }
    throw newError('A field definition is already registered for this handler', { fieldDefinitionId: handler.fieldDefinitionId });
  }

  const fieldHandlerGenerator = buildFieldHandlerGenerator<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>(handler, definition);
  const fieldDefinitionHandlerGenerator = buildFieldDefinitionHandlerGenerator(handler, definition, fieldHandlerGenerator);

  fieldDefinitionMap.set(handler.fieldDefinitionId, fieldDefinitionHandlerGenerator);

  return (objectStore: FrontObjectStore) => fieldDefinitionHandlerGenerator(objectStore);
};
