import type { FunctionComponent } from 'react';
import type { BlockStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import { Block_Type, BlockType_Section } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type {
  ConceptDefinitionRaw,
  ConceptDefinitionStoreObject,
  IconFieldStoreObject,
  SingleParameterDefinition,
  WorkflowFieldStoreObject,
} from 'yooi-modules/modules/conceptModule';
import { BLOCK_PARAMETER_CURRENT, getFields, InstanceReferenceType, PathStepType } from 'yooi-modules/modules/conceptModule';
import {
  BooleanField,
  ConceptDefinition_MainBooleanField,
  ConceptDefinition_MainIconField,
  ConceptDefinition_MainWorkflowField,
  ConceptDefinition_MainWorkflowOnSecondLine,
  ConceptDefinition_SanityScore,
  ConceptDefinition_ShowEcosystemTab,
  ConceptDefinition_ShowMasterDetail,
  IconField,
  WorkflowField,
} from 'yooi-modules/modules/conceptModule/ids';
import Button, { ButtonVariant } from '../../../../../components/atoms/Button';
import Checkbox from '../../../../../components/atoms/Checkbox';
import { IconName } from '../../../../../components/atoms/Icon';
import Chooser from '../../../../../components/molecules/Chooser';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import BlockContent from '../../../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../../../components/templates/VerticalBlock';
import useStore from '../../../../../store/useStore';
import { spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import { formatOrUndef } from '../../../../../utils/stringUtils';
import useNewLineFocus from '../../../../../utils/useNewLineFocus';
import { SessionStorageKeys, useSessionStorageState } from '../../../../../utils/useSessionStorage';
import { getFieldChip } from '../../../../_global/fieldUtils';
import { getLoggedUserParameterDefinition } from '../../../../_global/filter/filterUtils';
import type { Option } from '../../../../_global/modelTypeUtils';
import { getConceptDefinitionNameOrEntity, getUnknownChip, listProgressFieldOptions } from '../../../../_global/modelTypeUtils';
import PathInput from '../../../../_global/path/PathInput';
import { createPathConfigurationHandler } from '../../../../_global/pathConfigurationHandler';
import { getFieldTypeValidator } from '../../../../_global/pathConfigurationHandlerUtils';
import type { LayoutCollapseConfiguration } from '../../../_global/displayItemsLibrary/definitions/displayItemDefinitionUtils';
import { getFlattenedTree } from '../../../_global/displayItemsLibrary/definitions/displayItemDefinitionUtils';
import LayoutDisplayEditorBlock from './LayoutDisplayEditorBlock';

const useStyles = makeStyles({
  collapseButtonsContainer: {
    display: 'flex',
    gap: spacingRem.s,
    gridColumnStart: 2,
    paddingLeft: spacingRem.s,
  },
}, 'layoutTab');

interface LayoutTabProps {
  conceptDefinitionId: string,
}

const LayoutTab: FunctionComponent<LayoutTabProps> = ({ conceptDefinitionId }) => {
  const classes = useStyles();

  const store = useStore();

  const [newlyCreatedId, setNewlyCreatedId] = useNewLineFocus();
  const [layoutCollapseConfiguration, setLayoutCollapseConfiguration] = useSessionStorageState<LayoutCollapseConfiguration | undefined>(`${SessionStorageKeys.layoutCollapseConfig}_${conceptDefinitionId}`, undefined);

  const conceptDefinition = store.getObject<ConceptDefinitionStoreObject>(conceptDefinitionId);
  const flattenedTree = getFlattenedTree(store, conceptDefinition);
  const blockIds = new Set(flattenedTree.map((item) => item.blockId));

  if (!layoutCollapseConfiguration && flattenedTree.length > 20) {
    const defaultLayoutCollapseConfig: LayoutCollapseConfiguration = {};
    blockIds.forEach((blockId) => {
      defaultLayoutCollapseConfig[blockId] = true;
    });
    setLayoutCollapseConfiguration(defaultLayoutCollapseConfig);
  }

  const collapseOrExpandBlocks = (blocksIds: string[], expand: boolean = false) => {
    const layoutCollapseConf: LayoutCollapseConfiguration = {};
    blocksIds.forEach((blockId) => {
      layoutCollapseConf[blockId] = !expand;
    });
    setLayoutCollapseConfiguration(layoutCollapseConf);
    newlyCreatedId.current.reset();
  };

  const getSelectedSanityScoreChip = () => {
    const sanityScoreFieldId = conceptDefinition[ConceptDefinition_SanityScore];
    if (sanityScoreFieldId) {
      if (store.getObjectOrNull(sanityScoreFieldId) !== null) {
        return getFieldChip(store, conceptDefinitionId, sanityScoreFieldId);
      } else {
        return getUnknownChip(sanityScoreFieldId);
      }
    } else {
      return undefined;
    }
  };

  let mainWorkflowOption: Option | undefined;
  const mainWorkflowFieldId = conceptDefinition[ConceptDefinition_MainWorkflowField];
  if (mainWorkflowFieldId) {
    if (store.getObjectOrNull<WorkflowFieldStoreObject>(mainWorkflowFieldId) !== null) {
      mainWorkflowOption = getFieldChip(store, conceptDefinitionId, mainWorkflowFieldId);
    } else {
      mainWorkflowOption = getUnknownChip(mainWorkflowFieldId);
    }
  }

  let mainIconOption: Option | undefined;
  const mainIconFieldId = conceptDefinition[ConceptDefinition_MainIconField];
  if (mainIconFieldId) {
    if (store.getObjectOrNull<IconFieldStoreObject>(mainIconFieldId) !== null) {
      mainIconOption = getFieldChip(store, conceptDefinitionId, mainIconFieldId);
    } else {
      mainIconOption = getUnknownChip(mainIconFieldId);
    }
  }
  const mainBooleanFieldPath = conceptDefinition[ConceptDefinition_MainBooleanField];

  const parameterDefinitions: SingleParameterDefinition[] = [{
    id: BLOCK_PARAMETER_CURRENT,
    label: i18n`Current`,
    typeId: conceptDefinitionId,
    type: 'parameter',
  }, getLoggedUserParameterDefinition()];

  const valuePathConfigurationHandler = createPathConfigurationHandler(
    store,
    parameterDefinitions,
    [
      getFieldTypeValidator(store, [BooleanField], i18n`Input should end with a boolean field.`),
    ]
  );

  return (
    <VerticalBlock>
      <VerticalBlock asBlockContent withSeparation>
        <BlockTitle title={i18n`Left panel`} anchor="#leftpanel" />
        <HorizontalBlock asBlockContent>
          <BlockTitle
            title={i18n`Display instances list in left panel`}
            infoTooltip={i18n`When enabled, instance pages come with a left panel listing instances of the same type and from which you can navigate.`}
            variant={BlockTitleVariant.inline}
          />
          <BlockContent padded>
            <SpacingLine>
              <Checkbox
                checked={conceptDefinition[ConceptDefinition_ShowMasterDetail] ?? false}
                onChange={(value) => store.updateObject<ConceptDefinitionRaw>(conceptDefinitionId, { [ConceptDefinition_ShowMasterDetail]: value ?? null })}
              />
            </SpacingLine>
          </BlockContent>
        </HorizontalBlock>
      </VerticalBlock>
      <VerticalBlock asBlockContent withSeparation>
        <BlockTitle title={i18n`Header`} anchor="#header" />
        <HorizontalBlock asBlockContent>
          <BlockTitle title={i18n`Global score`} />
          <BlockContent>
            <SearchAndSelect
              placeholder={i18n`Select element`}
              clearable
              selectedOption={getSelectedSanityScoreChip()}
              computeOptions={() => listProgressFieldOptions(store, conceptDefinitionId)}
              onSelect={(value) => store.updateObject<ConceptDefinitionRaw>(conceptDefinitionId, { [ConceptDefinition_SanityScore]: value?.id ?? null })}
            />
          </BlockContent>
        </HorizontalBlock>
        <HorizontalBlock asBlockContent>
          <BlockTitle title={i18n`Main workflow field`} />
          <BlockContent>
            <SpacingLine>
              <SearchAndSelect
                placeholder={i18n`Select field`}
                clearable
                selectedOption={mainWorkflowOption}
                computeOptions={() => getFields(store, conceptDefinitionId, [WorkflowField]).map((field) => getFieldChip(store, conceptDefinitionId, field.id))}
                onSelect={(value) => store.updateObject<ConceptDefinitionRaw>(conceptDefinitionId, { [ConceptDefinition_MainWorkflowField]: value?.id ?? null })}
              />
              {
                mainWorkflowOption !== undefined
                  ? (
                    <Chooser
                      actions={[
                        {
                          key: 'right',
                          name: i18n`On name line`,
                        },
                        {
                          key: 'line',
                          name: i18n`On a line below`,
                        },
                      ]}
                      selectedIndexes={[conceptDefinition[ConceptDefinition_MainWorkflowOnSecondLine] ? 1 : 0]}
                      onClick={(index) => {
                        store.updateObject<ConceptDefinitionRaw>(conceptDefinitionId, { [ConceptDefinition_MainWorkflowOnSecondLine]: index === 1 });
                      }}
                    />
                  )
                  : null
              }
            </SpacingLine>
          </BlockContent>
        </HorizontalBlock>
        <HorizontalBlock asBlockContent>
          <BlockTitle title={i18n`Main icon field`} />
          <BlockContent>
            <SearchAndSelect
              placeholder={i18n`Select field`}
              clearable
              selectedOption={mainIconOption}
              computeOptions={() => getFields(store, conceptDefinitionId, [IconField]).map((field) => getFieldChip(store, conceptDefinitionId, field.id))}
              onSelect={(value) => store.updateObject<ConceptDefinitionRaw>(conceptDefinitionId, { [ConceptDefinition_MainIconField]: value?.id ?? null })}
            />
          </BlockContent>
        </HorizontalBlock>
        <HorizontalBlock asBlockContent>
          <BlockTitle title={i18n`Main boolean field`} />
          <BlockContent>
            <PathInput
              path={mainBooleanFieldPath ?? []}
              placeholder={i18n`Add a path to a boolean field`}
              onChange={(newPath) => {
                store.updateObject<ConceptDefinitionRaw>(conceptDefinitionId, { [ConceptDefinition_MainBooleanField]: newPath });
              }}
              parameterDefinitions={parameterDefinitions}
              suggestedBasePaths={[
                {
                  label: `${parameterDefinitions[0].label} (${formatOrUndef(getConceptDefinitionNameOrEntity(store, conceptDefinitionId))})`,
                  path: [
                    { type: PathStepType.dimension, conceptDefinitionId },
                    { type: PathStepType.mapping, mapping: { id: BLOCK_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
                  ],
                },
              ]}
              valuePathHandler={valuePathConfigurationHandler}
            />
          </BlockContent>
        </HorizontalBlock>
      </VerticalBlock>
      <VerticalBlock asBlockContent>
        <BlockTitle title={i18n`Body`} anchor="#body" />
        <HorizontalBlock asBlockContent>
          <BlockTitle
            title={i18n`Display Ecosystem tab`}
            infoTooltip={i18n`When enabled, display a tab with all associations of the current concept.`}
            variant={BlockTitleVariant.inline}
          />
          <BlockContent padded>
            <SpacingLine>
              <Checkbox
                checked={conceptDefinition[ConceptDefinition_ShowEcosystemTab] ?? false}
                onChange={(value) => store.updateObject<ConceptDefinitionRaw>(conceptDefinitionId, { [ConceptDefinition_ShowEcosystemTab]: value ?? null })}
              />
            </SpacingLine>
          </BlockContent>
        </HorizontalBlock>
        <div className={classes.collapseButtonsContainer}>
          <Button
            variant={ButtonVariant.secondary}
            title={i18n`Expand all`}
            tooltip={i18n`Expand all`}
            maxLine={1}
            iconName={IconName.unfold_more}
            onClick={() => {
              collapseOrExpandBlocks(Array.from(blockIds), true);
            }}
          />
          <Button
            variant={ButtonVariant.secondary}
            title={i18n`Collapse all`}
            tooltip={i18n`Collapse all`}
            maxLine={1}
            iconName={IconName.unfold_less}
            onClick={() => {
              collapseOrExpandBlocks(Array.from(blockIds));
            }}
          />
          <Button
            variant={ButtonVariant.secondary}
            title={i18n`Show and collapse sections`}
            tooltip={i18n`Show and collapse sections`}
            maxLine={1}
            iconName={IconName.unfold_less}
            onClick={() => {
              collapseOrExpandBlocks(Array.from(blockIds).filter((blockId) => {
                const block = store.getObject<BlockStoreObject>(blockId);
                return block[Block_Type] === BlockType_Section;
              }));
            }}
          />
        </div>
        <LayoutDisplayEditorBlock conceptDefinitionId={conceptDefinitionId} newlyCreatedId={newlyCreatedId} setNewlyCreatedId={setNewlyCreatedId} />
      </VerticalBlock>
    </VerticalBlock>
  );
};

export default LayoutTab;
