import type { FunctionComponent } from 'react';
import type { DimensionsMapping, FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { ParsedDimensionType, parseDimensionMapping, timeseriesTextFieldHandler } from 'yooi-modules/modules/conceptModule';
import type { TimeseriesValueUpdate } from 'yooi-modules/modules/conceptModule/fields/timeseriesFields';
import { Field_Formula, Field_IntegrationOnly } from 'yooi-modules/modules/conceptModule/ids';
import useActivity from '../../../../../store/useActivity';
import useStore from '../../../../../store/useStore';
import useUpdateActivity from '../../../../../store/useUpdateActivity';
import StoreTextInputField from '../../../input/StoreTextInputField';

interface TimeseriesTextFieldTextInputProps {
  dimensionsMapping: DimensionsMapping,
  fieldId: string,
  readOnly: boolean,
  time: number,
  value: string | undefined,
  onSubmit?: (value: TimeseriesValueUpdate<string>) => void,
}

const TimeseriesTextFieldTextInput: FunctionComponent<TimeseriesTextFieldTextInputProps> = ({ dimensionsMapping, fieldId, time, value, onSubmit, readOnly = false }) => {
  const store = useStore();
  const field = store.getObject<FieldStoreObject>(fieldId);
  const isComputed = Boolean(field[Field_Formula]);
  const isIntegrationOnly = field[Field_IntegrationOnly];
  const fieldHandler = timeseriesTextFieldHandler(store, fieldId);
  const parsedDimension = parseDimensionMapping(dimensionsMapping);

  const activity = useActivity();
  const updateActivity = useUpdateActivity();

  return (
    <StoreTextInputField
      initialValue={value ?? undefined}
      onSubmit={(newValue) => {
        const update: TimeseriesValueUpdate<string> = { type: 'value', time, value: newValue };
        if (onSubmit) {
          onSubmit(update);
        } else {
          fieldHandler.updateValue(dimensionsMapping, update);
        }
      }}
      readOnly={isIntegrationOnly || isComputed || readOnly}
      maxLine={1}
      onEditionStart={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => updateActivity.onEnterEdition(parsedDimension.objectId, `${fieldId}_${time}`) : undefined}
      onEditionStop={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => updateActivity.onExitEdition(parsedDimension.objectId, `${fieldId}_${time}`) : undefined}
      isEditing={parsedDimension.type === ParsedDimensionType.MonoDimensional ? activity.listEditor(parsedDimension.objectId, `${fieldId}_${time}`).length > 0 : undefined}
    />
  );
};

export default TimeseriesTextFieldTextInput;
