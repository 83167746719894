import type { ReactElement } from 'react';
import { VictoryArea, VictoryGroup, VictoryLine } from 'victory';
import { hexColorWithAlpha } from '../../../theme/colorUtils';
import type { SerieInput } from '../ChartTypes';

interface MiniLineChartProps<ValueType> {
  series: SerieInput<ValueType> | undefined,
  height: number,
  width: number,
}

const MiniLineChart = <ValueType extends {
  x: Date,
  y: number,
  time: number,
  value: number,
}>({ series, height, width }: MiniLineChartProps<ValueType>): ReactElement | null => {
  if (!series) {
    return null;
  }
  const row = series.rows[0].stackedRows[0];
  if (row?.values.length) {
    return (
      <VictoryGroup
        domain={{ x: row.domain.xDomain, y: row.domain.yDomain }}
        width={width}
        domainPadding={{ y: 1 }}
        padding={0}
        singleQuadrantDomainPadding={{ y: false }}
        height={height}
        data={row.values}
      >
        <VictoryLine
          style={{
            data: {
              stroke: row.info.color ?? series.info.color,
              strokeWidth: 1,
            },
          }}
        />
        {series.info.withArea && (
          <VictoryArea
            style={{ data: { fill: hexColorWithAlpha(row.info.color ?? series.info.color, 0.1) } }}
          />
        )}
      </VictoryGroup>
    );
  } else {
    return null;
  }
};

export default MiniLineChart;
