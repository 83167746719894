import type { EventHandler, FunctionComponent, ReactElement, SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import makeStyles from '../../../utils/makeStyles';
import { remToPx } from '../../../utils/sizeUtils';
import useTheme from '../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../utils/useUsageContext';
import Typo from '../../atoms/Typo';
import QuadrantRectangle from './QuadrantRectangle';

const useStyles = makeStyles({
  fitContent: {
    pointerEvents: 'all',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
  },
  foreignObject: {
    pointerEvents: 'none',
  },
}, 'quadrantPoint');

interface QuadrantPointProps {
  x: number,
  y: number,
  sizePx: number,
  clickable: boolean,
  color: string | undefined,
  label: string | undefined,
  labelX: number,
  labelY: number,
  getDragData: () => [format: string, data: string][],
  onElementDrag: (() => void) | undefined,
  onElementDragEnd: (() => void) | undefined,
  onElementTooltipEditModeOpen: () => void,
  onElementTooltipEditModeClose: () => void,
  renderTooltip: ((editMode: boolean, currentAnchor: EventTarget, handleClose: () => void) => (ReactElement | null)) | undefined,
  isDragging: boolean,
  showTooltip: boolean,
  isEditedByOtherUser: boolean,
  onDoubleClick: (() => void) | undefined,
  svgXEnd: number,
}

const QuadrantPoint: FunctionComponent<QuadrantPointProps> = ({
  x,
  y,
  sizePx,
  clickable,
  color,
  label,
  labelX,
  labelY,
  getDragData,
  onElementDrag,
  onElementDragEnd,
  onElementTooltipEditModeOpen,
  onElementTooltipEditModeClose,
  renderTooltip,
  isDragging,
  showTooltip,
  isEditedByOtherUser,
  onDoubleClick,
  svgXEnd,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const [isHoveredElement, setIsHoveredElement] = useState(false);
  const [currentAnchor, setCurrentAnchor] = useState<EventTarget>();
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (isDragging || !showTooltip) {
      setEditMode(false);
      setCurrentAnchor(undefined);
      setIsHoveredElement(false);
    }
  }, [isDragging, showTooltip]);

  const handleMouseEnter: EventHandler<SyntheticEvent> = (event) => {
    if (!editMode) {
      setIsHoveredElement(true);
      setCurrentAnchor(event.currentTarget);
    }
  };

  const handleClose = () => {
    setCurrentAnchor(undefined);
    setEditMode(false);
    onElementTooltipEditModeClose();
  };

  const draggable = Boolean(onElementDrag);
  let cursor;
  if (draggable) {
    cursor = 'move';
  } else if (clickable) {
    cursor = 'pointer';
  }

  return (
    <>
      <UsageContextProvider usageVariant={UsageVariant.inForm}>
        {!isDragging && currentAnchor && renderTooltip && showTooltip ? renderTooltip(editMode, currentAnchor, handleClose) : null}
      </UsageContextProvider>
      <g
        onClick={
          clickable
            ? (event) => {
              setCurrentAnchor(event.currentTarget ?? undefined);
              setEditMode(true);
              onElementTooltipEditModeOpen();
            }
            : undefined
        }
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          if (!editMode) {
            setCurrentAnchor(undefined);
          }
          setIsHoveredElement(false);
        }}
        onDragStart={
          onElementDrag
            ? (event) => {
              getDragData().forEach(([format, data]) => event.dataTransfer.setData(format, data));
              // disable dragGhost
              event.dataTransfer.setDragImage(new Image(), 0, 0);
              onElementDrag();
            } : undefined
        }
        onDragEnd={onElementDragEnd}
      >
        {
          label === undefined
            ? null
            : (
              <g transform={`translate(${labelX},${labelY - 5})`}>
                <foreignObject
                  width={Math.min(remToPx(20), Math.abs(labelX - svgXEnd))}
                  height={remToPx(2)}
                  className={classes.foreignObject}
                >
                  <div
                    style={{ cursor }}
                    className={classes.fitContent}
                    onBlur={() => {
                      if (!editMode) {
                        setCurrentAnchor(undefined);
                      }
                      setIsHoveredElement(false);
                    }}
                    onFocus={handleMouseEnter}
                    draggable={draggable}
                  >
                    <Typo color={isHoveredElement ? theme.color.text.primary : theme.color.text.secondary} maxLine={1}>
                      {label}
                    </Typo>
                  </div>
                </foreignObject>
              </g>
            )
        }
        <QuadrantRectangle
          x={x}
          y={y}
          color={color}
          size={sizePx}
          clickable={clickable}
          selected={editMode}
          hovered={isHoveredElement}
          cursor={cursor}
          draggable={draggable}
          isEditedByOtherUser={isEditedByOtherUser}
          onDoubleClick={onDoubleClick}
        />
      </g>
    </>
  );
};

export default QuadrantPoint;
