import type { ComponentProps, FunctionComponent, ReactElement } from 'react';
import type { Filters, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { FILTER_PARAMETER_CURRENT, InstanceReferenceType, PathStepType } from 'yooi-modules/modules/conceptModule';
import { ConceptDefinition } from 'yooi-modules/modules/conceptModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import Typo from '../../../components/atoms/Typo';
import TextInputString from '../../../components/inputs/TextInputString';
import CompositeField, { CompositeFieldCloseReasons, CompositeFieldVariants } from '../../../components/molecules/CompositeField';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../components/molecules/SpacingLine';
import ToggleButton from '../../../components/molecules/ToggleButton';
import useStore from '../../../store/useStore';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { formatOrUndef } from '../../../utils/stringUtils';
import { SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import type { FilterDefinition } from '../filter/filterComposite/FilterComposite';
import FilterComposite from '../filter/filterComposite/FilterComposite';
import { getLoggedUserParameterDefinition } from '../filter/filterUtils';
import InCompositeInput from '../input/InCompositeInput';
import type { Option } from '../modelTypeUtils';
import { defaultOptionComparator, getChipOptions, getConceptDefinitionNameOrEntity } from '../modelTypeUtils';

const useStyles = makeStyles({
  headerStartContainer: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
  titleLineContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toggleContainer: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  notSetInput: {
    marginLeft: spacingRem.s,
    width: '100%',
  },
}, 'parameterConfigurationEditor');

interface ParameterConfigurationEditorProps {
  label?: string,
  onUpdateLabel: (value: string) => void,
  unsetLabel?: string,
  onUpdateUnsetLabel: (value: string) => void,
  onMoveLeft?: () => void,
  onMoveRight?: () => void,
  onDelete: () => void,
  onClose: (reason: CompositeFieldCloseReasons) => void,
  readOnly?: boolean,
  typeId?: string,
  onSelectType: ComponentProps<typeof SearchAndSelect<Option>>['onSelect'],
  filters?: Filters,
  onUpdateFilters: (currentValue: Filters) => void,
  defaultValueError?: string,
  otherParameters: { id: string, label?: string, typeId?: string }[],
  focusOnMount?: boolean,
  enableNotSetOption?: boolean,
  onUpdateEnableNotSetOption?: (value: boolean) => void,
  defaultValueRender: ReactElement,
}

const ParameterConfigurationEditor: FunctionComponent<ParameterConfigurationEditorProps> = ({
  label,
  onUpdateLabel,
  unsetLabel,
  onUpdateUnsetLabel,
  onMoveLeft,
  onMoveRight,
  onDelete,
  onClose,
  readOnly = false,
  focusOnMount = false,
  typeId,
  onSelectType,
  filters,
  onUpdateFilters,
  defaultValueError,
  defaultValueRender,
  otherParameters,
  onUpdateEnableNotSetOption,
  enableNotSetOption = false,
}) => {
  const classes = useStyles();

  const store = useStore();

  const typeError = typeId ? undefined : i18n`Type is required`;

  return (
    <SizeContextProvider sizeVariant={SizeVariant.small}>
      <CompositeField
        onCloseDropdown={onClose}
        variant={CompositeFieldVariants.button}
        width="38rem"
        openOnMount={focusOnMount}
        headerLinesRenderers={[
          {
            id: 'title',
            render: (inDropDown: boolean) => (
              <SizeContextProvider sizeVariant={SizeVariant.small}>
                <div className={classes.headerStartContainer}>
                  {inDropDown && !readOnly && onMoveLeft && (
                    <IconOnlyButton iconName={IconName.keyboard_arrow_left} tooltip={i18n`Move left`} onClick={onMoveLeft} variant={IconOnlyButtonVariants.tertiary} />
                  )}
                  {inDropDown && !readOnly && onMoveRight && (
                    <IconOnlyButton iconName={IconName.keyboard_arrow_right} tooltip={i18n`Move right`} onClick={onMoveRight} variant={IconOnlyButtonVariants.tertiary} />
                  )}
                  <Typo>{formatOrUndef(label)}</Typo>
                  {!inDropDown && (typeError ?? defaultValueError) && (
                    <Icon
                      name={IconName.dangerous}
                      tooltip={typeError ?? defaultValueError}
                      colorVariant={IconColorVariant.error}
                    />
                  )}
                </div>
                {inDropDown && !readOnly && (
                  <IconOnlyButton iconName={IconName.delete} tooltip={i18n`Delete`} onClick={onDelete} variant={IconOnlyButtonVariants.danger} />
                )}
              </SizeContextProvider>
            ),
          },
        ]}
        getDropdownSectionDefinitions={() => [
          {
            id: 'section',
            lines: [
              {
                id: 'Concept',
                title: i18n`Concept`,
                render: (
                  <div className={classes.titleLineContainer}>
                    <SizeContextProvider sizeVariant={SizeVariant.small}>
                      <SpacingLine>
                        <SearchAndSelect
                          selectedOption={typeId ? getChipOptions(store, typeId) : undefined}
                          computeOptions={() => store.getObject(ConceptDefinition)
                            .navigateBack(Class_Instances)
                            .map(({ id: instanceId }) => getChipOptions(store, instanceId))
                            .filter(filterNullOrUndefined)
                            .sort(defaultOptionComparator)}
                          onSelect={onSelectType}
                          readOnly={readOnly || Boolean(typeId)}
                          statusIcon={typeError ? { icon: IconName.dangerous, color: IconColorVariant.error, message: typeError } : undefined}
                        />
                        {typeId && (
                          <InCompositeInput
                            initialValue={filters}
                            setInputValue={(value) => {
                              if (value) {
                                onUpdateFilters(value);
                              }
                            }}
                          >
                            {({ value, onChange, onCancel }) => {
                              const filtersDefinition: FilterDefinition = {
                                updateFilters: (filterValue: Filters[]) => {
                                  onChange(filterValue[0]);
                                },
                                definition: [
                                  {
                                    filter: value,
                                  },
                                ],
                              };
                              return (
                                <FilterComposite
                                  rootPath={{
                                    label: i18n`Current (${formatOrUndef(getConceptDefinitionNameOrEntity(store, typeId))})`,
                                    path: [
                                      { type: PathStepType.dimension, conceptDefinitionId: typeId },
                                      { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
                                    ],
                                  }}
                                  requiredConceptDefinitionId={typeId}
                                  filtersDefinition={filtersDefinition}
                                  parameterDefinitions={[
                                    { id: FILTER_PARAMETER_CURRENT, label: i18n`Current`, typeId },
                                    getLoggedUserParameterDefinition(),
                                    ...otherParameters,
                                  ].filter((parameterDefinition): parameterDefinition is SingleParameterDefinition => Boolean(parameterDefinition.typeId))}
                                  readOnly={readOnly}
                                  variant={CompositeFieldVariants.button}
                                  onClose={(reason) => {
                                    if (reason === CompositeFieldCloseReasons.cancel) {
                                      onCancel();
                                    }
                                  }}
                                />
                              );
                            }}
                          </InCompositeInput>
                        )}
                      </SpacingLine>
                    </SizeContextProvider>
                  </div>
                ),
              },
              {
                id: 'label',
                title: i18n`Label`,
                render: (
                  <InCompositeInput
                    initialValue={label}
                    setInputValue={(value) => {
                      onUpdateLabel(value ?? '');
                    }}
                  >
                    {(props) => (
                      <TextInputString
                        readOnly={readOnly}
                        {...props}
                      />
                    )}
                  </InCompositeInput>),
              },
              {
                id: 'defaultValue',
                title: i18n`Default value`,
                render: defaultValueRender,
              },
              {
                id: 'notSetOption',
                title: i18n`Not set option`,
                render: (
                  <div className={classes.toggleContainer}>
                    <ToggleButton
                      icon={enableNotSetOption ? IconName.toggle_on : IconName.toggle_off}
                      onClick={() => onUpdateEnableNotSetOption?.(!enableNotSetOption)}
                      active={enableNotSetOption}
                    />
                    {enableNotSetOption && (
                      <div className={classes.notSetInput}>
                        <InCompositeInput
                          initialValue={unsetLabel}
                          setInputValue={(value) => {
                            onUpdateUnsetLabel(value ?? '');
                          }}
                        >
                          {(props) => (
                            <TextInputString
                              readOnly={readOnly}
                              placeholder={i18n`Not set`}
                              {...props}
                            />
                          )}
                        </InCompositeInput>
                      </div>
                    )}
                  </div>
                ),
              },
            ],
          },
        ]}
      />
    </SizeContextProvider>
  );
};

export default ParameterConfigurationEditor;
