import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';

interface DashboardSize {
  width: number,
}

const DashboardSizeContext = createContext<DashboardSize>({ width: 0 });

interface DashboardSizeContextProviderProps {
  width: number,
  children: ReactNode,
}

export const DashboardSizeContextProvider: FunctionComponent<DashboardSizeContextProviderProps> = ({ width = 0, children }) => {
  const value = useMemo(() => ({ width }), [width]);
  return (
    <DashboardSizeContext.Provider value={value}>
      {children}
    </DashboardSizeContext.Provider>
  );
};

const useDashboardSizeContext = (): DashboardSize => useContext(DashboardSizeContext);

export default useDashboardSizeContext;
