import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import type { ConceptDefinitionStoreObject, WorkflowRaw, WorkflowStoreObject } from 'yooi-modules/modules/conceptModule';
import { ConceptDefinition, Workflow, Workflow_Description, Workflow_Name, Workflow_TargetedConceptDefinition } from 'yooi-modules/modules/conceptModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import { joinObjects } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import Tooltip from '../../../components/atoms/Tooltip';
import Typo from '../../../components/atoms/Typo';
import MasterDetailList from '../../../components/molecules/MasterDetailList';
import OverflowMenu from '../../../components/molecules/OverflowMenu';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import { TableSortDirection } from '../../../components/molecules/Table';
import BaseLayout from '../../../components/templates/BaseLayout';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import Header from '../../../components/templates/Header';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import LeftPanel from '../../../components/templates/LeftPanel';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { safeSessionStorageValue } from '../../../utils/sessionStorageUtils';
import { formatOrUndef } from '../../../utils/stringUtils';
import useNavigation, { NavigationElementContainer } from '../../../utils/useNavigation';
import { createConceptDefinition } from '../../_global/conceptDefinitionUtils';
import type { FilterConfiguration } from '../../_global/filter/useFilterSessionStorage';
import HeaderFromStore from '../../_global/HeaderFromStore';
import StoreTextInputField from '../../_global/input/StoreTextInputField';
import { searchFilterFunction } from '../../_global/listFilterFunctions';
import type { Option } from '../../_global/modelTypeUtils';
import { defaultOptionComparator, getChipOptionWithUnknown } from '../../_global/modelTypeUtils';
import type { NavigationFilter } from '../../_global/navigationUtils';
import { NavigationTitlePathElements } from '../../_global/navigationUtils';
import TopBar from '../../_global/topBar/TopBar';
import useFilterAndSort, { buildStringColumnComparatorHandler } from '../../_global/useFilterAndSort';
import WorkflowDetailPageTransitionBlock from '../../_global/WorkflowDetailPageTransitionBlock';
import WorkflowDetailPageInstanceListBlock from './WorkflowDetailPageInstanceListBlock';
import { duplicateWorkflow } from './workflowUtils';

interface WorkflowDetailPageProps {
  workflowId: string,
}

const WorkflowDetailPage: FunctionComponent<WorkflowDetailPageProps> = ({ workflowId }) => {
  const store = useStore();

  const location = useLocation();
  const navigation = useNavigation<NavigationFilter>();

  const workflow = store.getObject<WorkflowStoreObject>(workflowId);

  let selectedConceptOption: Option | undefined;
  if (workflow[Workflow_TargetedConceptDefinition] !== undefined) {
    selectedConceptOption = getChipOptionWithUnknown(store, workflow[Workflow_TargetedConceptDefinition]);
  }

  const hasTargetConceptDefinition = Boolean(workflow[Workflow_TargetedConceptDefinition]);
  const filterId = Workflow;

  const [search, setSearch] = useState<string | undefined>(undefined);
  const { generateList, forceFollowingIds } = useFilterAndSort(
    filterId,
    store.getObject(Workflow).navigateBack<WorkflowStoreObject>(Class_Instances),
    searchFilterFunction(store, [safeSessionStorageValue<FilterConfiguration | undefined>(filterId)?.nameSearch, search], [Workflow_Name]),
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case Workflow_Name:
            return buildStringColumnComparatorHandler(key, direction);
          default:
            return undefined;
        }
      },
      initial: { key: Workflow_Name, direction: TableSortDirection.asc },
    },
    undefined,
    [search]
  );

  return (
    <NavigationElementContainer
      element={{
        key: workflowId,
        name: formatOrUndef(workflow[Workflow_Name]),
        element: NavigationTitlePathElements.integration,
        path: `/settings/workflow/${workflowId}`,
      }}
    >
      <BaseLayout
        topBar={(<TopBar />)}
        leftPanel={(
          <LeftPanel>
            <MasterDetailList
              list={
                generateList().list
                  .map(({ item, ...entry }) => (
                    joinObjects(
                      entry,
                      {
                        render: () => (
                          <Tooltip title={formatOrUndef(item[Workflow_Name])}>
                            <Typo maxLine={1}>{formatOrUndef(item[Workflow_Name])}</Typo>
                          </Tooltip>
                        ),
                        to: () => ({ pathname: `/settings/workflow/${item.id}`, hash: location.hash }),
                      }
                    )
                  ))
              }
              search={{ value: search, setValue: setSearch }}
            />
          </LeftPanel>
        )}
        header={(
          <Header
            firstLine={(
              <HeaderFromStore
                propertyId={Workflow_Name}
                instanceId={workflowId}
                placeholder={i18n`Click to edit name`}
                actions={[(
                  <OverflowMenu
                    key="overflowMenu"
                    menuItems={[
                      {
                        key: 'duplicate',
                        icon: IconName.content_copy_outline,
                        name: i18n`Duplicate`,
                        onClick: () => {
                          const duplicatedWorkflowId = duplicateWorkflow(store, workflowId);
                          forceFollowingIds(workflowId, duplicatedWorkflowId);
                          navigation.push(duplicatedWorkflowId, { pathname: `/settings/workflow/${duplicatedWorkflowId}` });
                        },
                      },
                      {
                        key: 'delete',
                        name: i18n`Delete`,
                        icon: IconName.delete,
                        onClick: () => {
                          store.deleteObject(workflowId);
                          navigation.replace('workflow', { pathname: '/settings/workflow' });
                        },
                        danger: true,
                      },
                    ]}
                  />
                )]}
              />
            )}
            withBottomBorder
          />
        )}
        content={(
          <VerticalBlock>
            <VerticalBlock withSeparation={hasTargetConceptDefinition} asBlockContent>
              <HorizontalBlock asBlockContent>
                <BlockTitle title={i18n`Description`} />
                <BlockContent>
                  <StoreTextInputField
                    initialValue={workflow[Workflow_Description]}
                    onSubmit={(description) => store.updateObject<WorkflowRaw>(workflowId, { [Workflow_Description]: description })}
                    placeholder={i18n`Add text`}
                  />
                </BlockContent>
              </HorizontalBlock>
              <HorizontalBlock asBlockContent>
                <BlockTitle title={i18n`Concept`} />
                <BlockContent>
                  <SearchAndSelect
                    selectedOption={selectedConceptOption}
                    computeOptions={() => (
                      store.getObject(ConceptDefinition)
                        .navigateBack<ConceptDefinitionStoreObject>(Class_Instances)
                        .map(({ id }) => getChipOptionWithUnknown(store, id))
                        .sort(defaultOptionComparator)
                    )}
                    onSelect={(option) => {
                      store.updateObject<WorkflowRaw>(workflowId, { [Workflow_TargetedConceptDefinition]: option?.id ?? null });
                    }}
                    placeholder={i18n`Select concept`}
                    getInlineCreation={() => ({
                      type: 'inline',
                      onCreate: (title) => {
                        const conceptDefinitionId = createConceptDefinition(store, title);
                        store.updateObject<WorkflowRaw>(workflowId, { [Workflow_TargetedConceptDefinition]: conceptDefinitionId });
                        return conceptDefinitionId;
                      },
                    })}
                    readOnly={workflow[Workflow_TargetedConceptDefinition] !== undefined}
                  />
                </BlockContent>
              </HorizontalBlock>
            </VerticalBlock>
            {hasTargetConceptDefinition ? (
              <>
                <WorkflowDetailPageInstanceListBlock workflowId={workflowId} />
                <WorkflowDetailPageTransitionBlock workflowId={workflowId} />
              </>
            ) : null}
          </VerticalBlock>
        )}
      />
    </NavigationElementContainer>
  );
};

export default WorkflowDetailPage;
