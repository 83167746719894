import type { FunctionComponent, ReactElement, RefCallback } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import type { Location, To } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { isNumber, joinObjects } from 'yooi-utils';
import type { NavigationElement } from '../components/molecules/NavigationTitle';
import { getPositionFromHash } from './historyUtils';
import { useAnchorScroll } from './useAnchorScroll';

let scrollTop = 0;

interface LocationNavigationElement<NavigationFilter = object> extends NavigationElement {
  scroll?: number,
  replace?: boolean,
  navigationFilters?: NavigationFilter,
}

interface SimplierLocationNavigationElement<NavigationFilter = object> {
  key: string,
  path?: string,
  navigationFilters?: NavigationFilter,
}

export interface NavigationLocation<NavigationFilter = object> extends Location {
  state: {
    navigationState?: (LocationNavigationElement<NavigationFilter> | SimplierLocationNavigationElement<NavigationFilter>)[],
  },
}

export interface NavigationPayload<NavigationFilter = object> {
  to: To,
  state?: NavigationLocation<NavigationFilter>['state'] & { restoreScroll?: boolean, preventScroll?: boolean },
}

export interface UseNavigation<NavigationFilter = object> {
  pushPayload: (payload: NavigationPayload<NavigationFilter>) => void,
  push: (key: string, target: { pathname?: string, hash?: string, search?: string, navigationFilters?: NavigationFilter }) => void,
  replace: (key: string, target: { pathname?: string, hash?: string, search?: string }) => void,
  goBackTo: (key: string, path: string) => void,
  getNavLocationObject: (key: string) => (SimplierLocationNavigationElement<NavigationFilter> | LocationNavigationElement<NavigationFilter>) | undefined,
  createNavigationPayload: (key: string,
    target: { pathname?: string, hash?: string, search?: string, navigationFilters?: NavigationFilter },
    options?: { preventScroll?: boolean, reset?: boolean, replace?: boolean }
  ) => NavigationPayload<NavigationFilter>,
  getGetBackLocationHistory: (key: string) => string,
  addNavigationElement: (el: NavigationElement) => void,
  scrollableRef: RefCallback<HTMLElement>,
  resetNavigationElements: () => void,
  navigationFilters: NavigationFilter | undefined,
}

const useNavigation = <NavigationFilter extends object = object>(): UseNavigation<NavigationFilter> => {
  const location = useLocation() as NavigationLocation<NavigationFilter>;
  const navigate = useNavigate();

  const { knownAnchors, scrollTo: tryScrollToAnchor } = useAnchorScroll();

  const onScrollListener = useRef<EventListener>();
  const scrollToPositionInterval = useRef<ReturnType<typeof setInterval>>();
  const scrollToAnchorInterval = useRef<ReturnType<typeof setInterval>>();
  const locationRef = useRef<{ pathname?: string, hash?: string }>({});

  const clearIntervals = useCallback(() => {
    // Cleanup intervals if they exists
    if (scrollToPositionInterval.current) {
      clearInterval(scrollToPositionInterval.current);
    }
    if (scrollToAnchorInterval.current) {
      clearInterval(scrollToAnchorInterval.current);
    }
  }, []);

  useEffect(() => () => {
    // Cleanup interval when destroying
    clearIntervals();
  }, [clearIntervals]);

  const scrollToAnchorWhenContentStable = useCallback((hash: string) => {
    scrollToAnchorInterval.current = setInterval(() => {
      tryScrollToAnchor(hash);
      if (scrollToAnchorInterval.current) {
        clearInterval(scrollToAnchorInterval.current);
      }
    }, 50);
  }, [tryScrollToAnchor]);

  const scrollableRef = useCallback((node: HTMLElement | null) => {
    // If the node is not yet available, ignore call
    if (!node) {
      return;
    }

    const scrollToPosition = (scroll: number) => {
      scrollToPositionInterval.current = setInterval(() => {
        if (scroll === 0 || node.scrollHeight >= (scroll + node.clientHeight)) {
          // eslint-disable-next-line no-param-reassign
          node.scrollTop = scroll;
          if (scrollToPositionInterval.current) {
            clearInterval(scrollToPositionInterval.current);
          }
        }
      }, 50);
    };

    // Reset scrollTop
    scrollTop = 0;

    // clear intervals because we can have the same page for multiple hash
    clearIntervals();

    // Listen scroll
    if (onScrollListener.current) {
      node.removeEventListener('scroll', onScrollListener.current);
    }
    onScrollListener.current = (e) => {
      const target = e.target as Element;
      scrollTop = target?.scrollTop;
    };
    node.addEventListener('scroll', onScrollListener.current);

    if (locationRef.current?.pathname !== location.pathname || locationRef.current?.hash !== location.hash) {
      const { pathname, hash, search, state } = location;
      const navigationState = (state as { navigationState?: Record<string, { scroll?: number }> })?.navigationState ?? {};
      locationRef.current = { pathname, hash };
      const searchParams = new URLSearchParams(search);

      // Restore the scroll
      const scroll = getPositionFromHash(location.hash);

      if (!((location.state as { preventScroll?: boolean })?.preventScroll)) {
        if (navigationState[pathname] && (state as { restoreScroll?: boolean })?.restoreScroll) {
          scrollToPosition(navigationState[pathname].scroll ?? 0);
          // Reset restoreScroll to avoid scrolling with the go back browser
          navigate(location, {
            replace: true,
            state: joinObjects(location.state as Record<string, unknown>, { restoreScroll: false }),
          });
        } else if (!searchParams.has('collaboration') && scroll && knownAnchors.has(scroll)) {
          scrollToAnchorWhenContentStable(scroll);
        } else {
          scrollToPosition(0);
        }
      }
    }
    // Only listen location part of history (other updates are irrelevant)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.hash]);

  const createNavigationPayload = (
    key: string,
    navigationFilters?: NavigationFilter,
    pathname?: string,
    search?: string,
    hash?: string,
    reset = false,
    replace = false,
    preventScroll = false
  ): NavigationPayload<NavigationFilter> => {
    const navigationState = Array.from(reset ? [] : location.state?.navigationState ?? []);
    if (navigationState.length > 0) {
      navigationState[navigationState.length - 1] = joinObjects(
        navigationState[navigationState.length - 1],
        {
          scroll: scrollTop,
          hash: location.hash,
        }
      );
    }
    if (replace) {
      const currentElement = navigationState.at(-1) ?? { key, navigationFilters: undefined };
      currentElement.key = key;
      if (navigationFilters) {
        currentElement.navigationFilters = navigationFilters;
      }
      navigationState.splice(navigationState.length - 1, 1, joinObjects(currentElement, { key, path: pathname, hash }));
    } else {
      navigationState.push({ key, path: pathname, hash, navigationFilters });
    }
    return ({
      to: joinObjects(
        location,
        {
          pathname,
          hash,
          search,
        }
      ),
      state: joinObjects(
        (location.state as { navigationState?: Record<string, { scroll?: number }> }),
        {
          restoreScroll: false,
          navigationState,
          preventScroll,
        }
      ),
    });
  };

  const getGetBackLocationHistory = (key: string): string => {
    const navigationState = Array.from(location.state?.navigationState ?? []);
    let index = navigationState.findIndex((el) => el.key.toLowerCase() === key.toLowerCase());
    if (index === -1 && navigationState.length > 1) {
      index = navigationState.length - 2;
    } else {
      return key;
    }
    return navigationState[index].key;
  };

  const getGoBackToHistoryObject = (key: string, path: string): NavigationPayload<NavigationFilter> => {
    const navigationState = Array.from(location.state?.navigationState ?? []);
    const index = navigationState.findIndex((el) => el.key.toLowerCase() === key.toLowerCase());
    let newState: NavigationLocation<NavigationFilter>['state']['navigationState'] = isNumber(index) && index > -1 ? navigationState.filter((_, i) => i <= index) : navigationState;
    if (index === -1) {
      newState = [{ key, path }];
    }
    return ({
      to: joinObjects(
        location,
        {
          pathname: path,
          hash: location.state?.navigationState?.find((el): el is LocationNavigationElement<NavigationFilter> => el.key.toLowerCase() === key.toLowerCase())?.hash,
        }
      ),
      state: joinObjects(
        location.state,
        {
          restoreScroll: true,
          navigationState: newState,
        }
      ),
    });
  };

  return {
    pushPayload: (payload: NavigationPayload<NavigationFilter>) => navigate(payload.to, { state: payload.state }),
    push: (key, { pathname, hash, search, navigationFilters }) => {
      const { to, state } = createNavigationPayload(key, navigationFilters, pathname, search, hash);
      navigate(to, { state });
    },
    replace: (key, { pathname, hash, search }) => {
      const { to, state } = createNavigationPayload(key, undefined, pathname, search, hash, false, true);
      navigate(to, { state, replace: true });
    },
    goBackTo: (key, path) => {
      const { to, state } = getGoBackToHistoryObject(key, path);
      navigate(to, { state, replace: false });
    },
    getNavLocationObject: (key: string) => location?.state?.navigationState?.find((el) => el.key.toLowerCase() === key),
    createNavigationPayload: (
      key,
      { pathname, hash, search, navigationFilters },
      options?
    ) => createNavigationPayload(key, navigationFilters, pathname, search, hash, options?.reset, options?.replace, options?.preventScroll),
    getGetBackLocationHistory: (key) => getGetBackLocationHistory(key),
    scrollableRef,
    addNavigationElement: (el: NavigationElement) => {
      location.state = joinObjects(
        location.state,
        {
          navigationState: (location.state?.navigationState ?? []).some(({ key }) => key === el.key)
            ? location.state?.navigationState
            : [...(location.state?.navigationState ?? []), el],
        }
      );
    },
    navigationFilters: location?.state?.navigationState?.at(-1)?.navigationFilters,
    resetNavigationElements: () => {
      location.state = joinObjects(
        location.state,
        { navigationState: [] }
      );
    },
  };
};

const useNavigationElement = <NavigationFilter extends object = object>(element: NavigationElement | { key: string }): void => {
  const location = useLocation() as NavigationLocation<NavigationFilter>;
  const navElement = joinObjects(element, {
    key: element.key.toLowerCase(),
  });
  const locationStateElementIndex = (location.state?.navigationState ?? []).findIndex(({ key }) => key === navElement.key);

  const newNavigationState = location.state?.navigationState ?? [];
  if (locationStateElementIndex !== -1) {
    newNavigationState[locationStateElementIndex] = joinObjects(newNavigationState[locationStateElementIndex], navElement);
  } else {
    newNavigationState.push(navElement);
  }
  location.state = joinObjects(
    location.state,
    { navigationState: newNavigationState }
  );
};

interface NavigationElementContainerProps {
  element: NavigationElement | { key: string },
  children: ReactElement | null,
}

export const NavigationElementContainer: FunctionComponent<NavigationElementContainerProps> = ({ element, children }) => {
  useNavigationElement(element);
  return children;
};

export default useNavigation;
