import classnames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import { useRef, useState } from 'react';
import base from '../../theme/base';
import { spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeSelectorsClasses from '../../utils/makeSelectorsClasses';
import makeStyles from '../../utils/makeStyles';
import { OverlayContextProvider } from '../../utils/useOverlayContainerRef';
import { SizeVariant } from '../../utils/useSizeContext';
import { IconName } from '../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../atoms/IconOnlyButton';
import SpacingLine from '../molecules/SpacingLine';

const selectorsClasses = makeSelectorsClasses('visibilityHandler');

const useStyles = makeStyles((theme) => ({
  container: {
    border: 'solid 0.1rem',
    borderColor: theme.color.transparent,
    borderRadius: base.borderRadius.medium,
    display: 'grid',
    gridTemplateColumns: '1fr',
    boxShadow: base.shadowElevation.low,
    backgroundColor: theme.color.background.neutral.default,
    '&:hover, &:focus, &:focus-within': {
      [`& .${selectorsClasses.visibilityHandler}`]: {
        visibility: 'visible',
      },
    },
  },
  contentBase: {
    padding: spacingRem.m,
  },
  divider: {
    borderTop: 'solid 0.1rem',
    borderColor: theme.color.border.default,
    marginLeft: spacingRem.m,
    marginRight: spacingRem.m,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  hiddenActions: {
    visibility: 'hidden',
  },
}), 'cardBlock');

interface CardBlockProps {
  titleContent: ReactNode,
  content: ReactNode,
  actions?: ReactNode,
  collapseHelper?: {
    isCollapsed: boolean,
    onCollapse: () => void,
    onExpand: () => void,
  },
}

const CardBlock: FunctionComponent<CardBlockProps> = ({ titleContent, actions, content, collapseHelper }) => {
  const classes = useStyles();

  const overlayContainerRef = useRef<HTMLDivElement>(null);

  const [collapsed, setCollapsed] = useState(false);

  const isCollapsed = collapseHelper?.isCollapsed ?? collapsed;
  const onCollapse: () => void = collapseHelper?.onCollapse ?? (() => setCollapsed(true));
  const onExpand = collapseHelper?.onExpand ?? (() => setCollapsed(false));

  return (
    <div ref={overlayContainerRef} className={classes.container}>
      <OverlayContextProvider containerRef={overlayContainerRef}>
        <div className={classnames(classes.titleContainer, classes.contentBase)}>
          {titleContent}
          <div className={classnames(classes.hiddenActions, selectorsClasses.visibilityHandler)}>
            <SpacingLine customColumnGap={spacingRem.m}>
              <IconOnlyButton
                tooltip={isCollapsed ? i18n`Expand` : i18n`Collapse`}
                variant={IconOnlyButtonVariants.tertiary}
                iconName={isCollapsed ? IconName.unfold_more : IconName.unfold_less}
                onClick={isCollapsed ? onExpand : onCollapse}
                sizeVariant={SizeVariant.small}
              />
              {actions}
            </SpacingLine>
          </div>
        </div>
        {!isCollapsed && (<div className={classes.divider} />)}
        {!isCollapsed && (content)}
      </OverlayContextProvider>
    </div>
  );
};

export default CardBlock;
