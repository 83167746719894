import type { FunctionComponent } from 'react';
import type { HorizontalSize } from 'react-virtualized-auto-sizer';
import AutoSizer from 'react-virtualized-auto-sizer';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { GraphChartFieldStoreObject } from 'yooi-modules/modules/dashboardModule';
import { GraphChartField_Configuration } from 'yooi-modules/modules/dashboardModule/ids';
import BlockContent from '../../../../components/templates/BlockContent';
import useStore from '../../../../store/useStore';
import base from '../../../../theme/base';
import { spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import BlockField from '../_global/BlockField';
import type { WidgetDisplay } from '../_global/widgetUtils';
import { getWidgetHeight } from '../_global/widgetUtils';
import type { BlockFieldProps } from '../FieldLibraryTypes';
import GraphChart from './GraphChart';

const useStyles = makeStyles((theme) => ({
  blockContentContainer: {
    boxShadow: base.shadowElevation.low,
    borderRadius: base.borderRadius.medium,
    background: theme.color.background.neutral.default,
    flexGrow: 1,
  },
  chartContainer: {
    width: '100%',
  },
  preventChartFlick: {
    position: 'absolute',
    overflow: 'auto',
    display: 'grid',
    alignItems: 'center',
    padding: spacingRem.m,
  },
}), 'graphChartBlockField');

interface GraphChartBlockFieldProps {
  fieldId: string,
  widgetDisplay: WidgetDisplay | undefined,
  parametersMapping: ParametersMapping,
  blockFieldProps: BlockFieldProps,
}

const GraphChartBlockField: FunctionComponent<GraphChartBlockFieldProps> = ({ fieldId, blockFieldProps, widgetDisplay, parametersMapping }) => {
  const classes = useStyles();
  const store = useStore();
  const chartHeight = getWidgetHeight(widgetDisplay);
  const field = store.getObject<GraphChartFieldStoreObject>(fieldId);
  return (
    <BlockField
      fieldId={fieldId}
      {...blockFieldProps}
      isVertical
    >
      <BlockContent padded>
        <div className={classes.blockContentContainer}>
          <AutoSizer disableHeight>
            {({ width }: HorizontalSize) => {
              const height = chartHeight;
              return (
                <div className={classes.chartContainer} style={{ height }}>
                  <div className={classes.preventChartFlick} style={{ height, width }}>
                    {field[GraphChartField_Configuration] ? (
                      <GraphChart config={field[GraphChartField_Configuration]} parametersMapping={parametersMapping} />
                    ) : null}
                  </div>
                </div>
              );
            }}
          </AutoSizer>
        </div>
      </BlockContent>
    </BlockField>
  );
};

export default GraphChartBlockField;
