import type { ConceptStoreObject, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { dimensionsMappingToParametersMapping, getFieldDimensionOfModelType, getPathReturnedConceptDefinitionId } from 'yooi-modules/modules/conceptModule';
import { AssociationField, NumberField } from 'yooi-modules/modules/conceptModule/ids';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { ViewType } from 'yooi-modules/modules/dashboardModule';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import type { StoreObject } from 'yooi-store';
import { filterNullOrUndefined } from 'yooi-utils';
import type { TableSortDirection } from '../../../../components/molecules/Table';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { getViewFilterFunction } from '../../listFilterFunctions';
import { resolveConcept } from '../common/viewWithConceptResolutionUtils';
import { getDimensionLabel } from '../data/dataResolution';
import type { ViewResolutionError } from '../viewResolutionUtils';
import { isResolutionError } from '../viewResolutionUtils';
import type { MatrixViewResolvedDefinition } from './matrixViewDefinitionHandler';

export interface MatrixViewConfiguration {
  colorFieldId?: string | null | undefined, // null when forcibly cleared
  showDependencies?: boolean | undefined,
}

export interface MatrixViewResolution {
  type: ViewType.Matrix,
  conceptDefinitionId: string,
  xAxis: { fieldId: string, dimensionId: string },
  yAxis: { fieldId: string, dimensionId: string },
  dependencies: { fieldId: string, dimensionId: string } | undefined,
  instances: ConceptStoreObject[],
  defaultSort: { key: string, direction: TableSortDirection },
  filterFunction: (item: StoreObject) => boolean,
}

export const getMatrixViewDependenciesField = (
  store: FrontObjectStore,
  viewDefinition: MatrixViewResolvedDefinition,
  conceptDefinitionId: string
): { fieldId: string, dimensionId: string } | undefined => {
  const dependenciesFieldId = (viewDefinition.dependenciesFieldId !== undefined && isInstanceOf(store.getObjectOrNull(viewDefinition.dependenciesFieldId), AssociationField)
    ? viewDefinition.dependenciesFieldId
    : undefined);

  const dependenciesDimensionId = dependenciesFieldId !== undefined ? getFieldDimensionOfModelType(store, dependenciesFieldId, conceptDefinitionId) : undefined;

  return dependenciesFieldId !== undefined && dependenciesDimensionId !== undefined ? { fieldId: dependenciesFieldId, dimensionId: dependenciesDimensionId } : undefined;
};

export const resolveMatrixView = (
  store: FrontObjectStore,
  viewDefinition: MatrixViewResolvedDefinition,
  viewDimensions: ViewDimension[],
  parametersMapping: ParametersMapping,
  filterConfiguration: FilterConfiguration | undefined
): MatrixViewResolution | ViewResolutionError => {
  const conceptResolution = resolveConcept(store, viewDimensions, parametersMapping);
  if (isResolutionError(conceptResolution)) {
    return conceptResolution;
  } else if (viewDefinition.xAxisFieldId === undefined) {
    return { type: 'error', error: i18n`Invalid configuration. No field selected for X-axis.` };
  } else if (viewDefinition.yAxisFieldId === undefined) {
    return { type: 'error', error: i18n`Invalid configuration. No field selected for Y-axis.` };
  } else if (!isInstanceOf(store.getObjectOrNull(viewDefinition.xAxisFieldId), NumberField)) {
    return { type: 'error', error: i18n`Invalid configuration. Unsupported field selected for X-axis.` };
  } else if (!isInstanceOf(store.getObjectOrNull(viewDefinition.yAxisFieldId), NumberField)) {
    return { type: 'error', error: i18n`Invalid configuration. Unsupported field selected for Y-axis.` };
  }

  const xAxisDimensionId = getFieldDimensionOfModelType(store, viewDefinition.xAxisFieldId, conceptResolution.conceptDefinitionId);
  const yAxisDimensionId = getFieldDimensionOfModelType(store, viewDefinition.yAxisFieldId, conceptResolution.conceptDefinitionId);

  if (xAxisDimensionId === undefined) {
    return { type: 'error', error: i18n`Invalid configuration. Selected field for X-axis does not belong to concept dimension.` };
  } else if (yAxisDimensionId === undefined) {
    return { type: 'error', error: i18n`Invalid configuration. Selected field for Y-axis does not belong to concept dimension.` };
  } else {
    const filterFunction = getViewFilterFunction(
      store,
      viewDimensions.map((dimension, index) => {
        const dimConceptDefinitionId = getPathReturnedConceptDefinitionId(store, dimension.path);
        return dimConceptDefinitionId ? {
          id: dimension.id,
          label: getDimensionLabel(store, dimension.label, index, dimension.path),
          typeId: dimConceptDefinitionId,
        } : undefined;
      }).filter(filterNullOrUndefined),
      filterConfiguration,
      parametersMapping
    );
    return {
      type: ViewType.Matrix,
      conceptDefinitionId: conceptResolution.conceptDefinitionId,
      xAxis: { fieldId: viewDefinition.xAxisFieldId, dimensionId: xAxisDimensionId },
      yAxis: { fieldId: viewDefinition.yAxisFieldId, dimensionId: yAxisDimensionId },
      dependencies: getMatrixViewDependenciesField(store, viewDefinition, conceptResolution.conceptDefinitionId),
      instances: conceptResolution.instances,
      defaultSort: conceptResolution.defaultSort,
      filterFunction: (item) => !filterFunction || filterFunction(dimensionsMappingToParametersMapping({ [viewDimensions[0].id]: item.id })),
    };
  }
};
