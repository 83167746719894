import type { FunctionComponent } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { BLOCK_PARAMETER_CURRENT, FILTER_PARAMETER_OPTION } from 'yooi-modules/modules/conceptModule';
import { Field_Formula } from 'yooi-modules/modules/conceptModule/ids';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import type { InlineCreationInline, InlineCreationTransactional } from '../../../../components/molecules/inlineCreationTypes';
import SearchAndSelectMultiple from '../../../../components/molecules/SearchAndSelectMultiple';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { useSessionStorageState } from '../../../../utils/useSessionStorage';
import { getFieldFilterFunction } from '../../fieldUtils';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { getLastUpdateBy } from '../../historyUtils';
import { defaultOptionComparator, getChipOptions, getSearchChipOptions } from '../../modelTypeUtils';
import { getViewNavigationFilters } from '../common/viewUtils';
import type { ChipViewResolution } from './chipViewResolution';

interface ChipViewProps {
  filterKey: string,
  chipResolution: ChipViewResolution,
  viewDimensions: ViewDimension[],
  parametersMapping: ParametersMapping,
  readOnly: boolean,
}

const ChipView: FunctionComponent<ChipViewProps> = ({
  filterKey,
  viewDimensions,
  parametersMapping,
  chipResolution,
  readOnly,
}) => {
  const store = useStore();
  const { values, fieldId, linkOptions, inlineCreationOptions, originConceptId, targetConceptDefinitionId } = chipResolution;
  const [filtersConfiguration] = useSessionStorageState<FilterConfiguration | undefined>(filterKey, undefined);
  const field = store.getObject(fieldId);

  const fieldFilter = getFieldFilterFunction(field, store);

  const navigationFilters = targetConceptDefinitionId ? getViewNavigationFilters(store, viewDimensions, filtersConfiguration, parametersMapping) : undefined;
  const computeOptions = linkOptions ? () => linkOptions?.computeLinkableInstanceIds().filter((option) => !fieldFilter
    || fieldFilter({
      [BLOCK_PARAMETER_CURRENT]: { type: 'single' as const, id: originConceptId },
      [FILTER_PARAMETER_OPTION]: { type: 'single' as const, id: option },
    }))
    .map((id) => getChipOptions(store, id, navigationFilters)).filter(filterNullOrUndefined)
    .sort(defaultOptionComparator) : undefined;
  return (
    <SearchAndSelectMultiple
      computeOptions={computeOptions}
      selectedOptions={values.map((id) => {
        const endIcons: { key: string, icon: IconName, colorVariant: IconColorVariant, tooltip: string }[] = [];
        if (fieldFilter
          && !fieldFilter({
            [BLOCK_PARAMETER_CURRENT]: { type: 'single' as const, id: originConceptId },
            [FILTER_PARAMETER_OPTION]: { type: 'single' as const, id },
          })) {
          endIcons.push({ key: 'optionFilter', icon: IconName.dangerous, colorVariant: IconColorVariant.error, tooltip: i18n`Instance not authorised by filters.` });
        }
        const chipOptions = getChipOptions(store, id, navigationFilters);
        return chipOptions === undefined
          ? undefined
          : joinObjects(chipOptions, { endIcons });
      }).filter(filterNullOrUndefined).sort(defaultOptionComparator)}
      getInlineCreation={!fieldFilter && inlineCreationOptions ? (): InlineCreationInline | InlineCreationTransactional => {
        const inlineCreation = inlineCreationOptions.buildInlineCreation();
        if (inlineCreation.type === 'inline') {
          return {
            type: 'inline',
            onCreate: (title) => inlineCreation.onCreate(title),
          };
        } else {
          return {
            type: 'transactional',
            creationOptions: inlineCreation.creationOptions,
            onCreate: (creationOptionState) => inlineCreation.onCreate(creationOptionState),
            getChipLabel: inlineCreation.getChipLabel,
            getInitialState: inlineCreation.getInitialState,
          };
        }
      } : undefined}
      onSelect={linkOptions ? (v) => linkOptions.onLink(v.id) : undefined}
      onDelete={linkOptions ? (v) => linkOptions.onUnlink(v.id) : undefined}
      searchOptions={targetConceptDefinitionId ? getSearchChipOptions(store, targetConceptDefinitionId) : undefined}
      placeholder={inlineCreationOptions || linkOptions ? i18n`Add element` : undefined}
      readOnly={readOnly}
      restingTooltip={originConceptId && store.getObject(fieldId)[Field_Formula] === undefined
        ? () => getLastUpdateBy(store, originConceptId, fieldId, undefined)
        : undefined}
    />
  );
};

export default ChipView;
