import type { ObjectStoreReadOnly } from 'yooi-store';
import { DataAsset_Type, DataAssetType } from '../../../dataAssetModule/ids';
import { Resource_Type, ResourceType } from '../../../resourceModule/ids';
import { isInstanceOf } from '../../../typeModule';
import { RelationSingleField_TargetType } from '../../ids';
import { isConceptValid } from '../../utils';

export const isRelationFieldStorageValueValid = (
  objectStore: ObjectStoreReadOnly,
  fieldId: string,
  value: unknown
): boolean => {
  const relationField = objectStore.getObjectOrNull(fieldId);
  const targetTypeId = relationField?.[RelationSingleField_TargetType] as string | undefined;
  if (!targetTypeId) {
    return false;
  } else if (value == null) {
    return true;
  } else if (typeof value !== 'string') {
    return false;
  } else if (fieldId === DataAsset_Type && isInstanceOf(objectStore.getObjectOrNull(value), DataAssetType)) {
    // Hack to deal with wierd data asset model (that will be deleted later one we delete the data asset model)
    return true;
  } else if (fieldId === Resource_Type && isInstanceOf(objectStore.getObjectOrNull(value), ResourceType)) {
    // Hack to deal with wierd data asset model (that will be deleted later one we delete the data asset model)
    return true;
  }
  return isConceptValid(objectStore, value) && isInstanceOf(objectStore.getObject(value), targetTypeId);
};
