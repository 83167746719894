import type { ClipboardEvent, FunctionComponent, ReactElement } from 'react';
import { useCallback, useState } from 'react';
import type { Offset } from 'react-overlays/usePopper';
import { formatForStorage, formatFromStorage, joinObjects, periodicities, PeriodicityType } from 'yooi-utils';
import { spacingRem } from '../../../theme/spacingDefinition';
import { formatDate, getPeriodicityOption, getPeriodicityOptions } from '../../../utils/dateUtilsFront';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { formatOrUndef } from '../../../utils/stringUtils';
import useTheme from '../../../utils/useTheme';
import useUsageContext, { UsageContextProvider, UsageVariant } from '../../../utils/useUsageContext';
import Icon, { IconColorVariant, IconName } from '../../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../atoms/IconOnlyButton';
import Typo from '../../atoms/Typo';
import EditableWithDropdown, { EditableCloseReasons } from '../../molecules/EditableWithDropdown';
import SearchAndSelect from '../../molecules/SearchAndSelect';
import SpacingLine from '../../molecules/SpacingLine';
import TextInputString from '../TextInputString';
import DatePicker from './DatePicker';
import { DatePickerStrategy } from './datePickerUtils';

const useStyles = makeStyles({
  valueContainer: {
    marginLeft: spacingRem.s,
    marginRight: spacingRem.s,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
  },
  pasteContainer: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '10rem auto',
    alignItems: 'center',
    rowGap: spacingRem.s,
    columnGap: spacingRem.s,
    margin: spacingRem.s,
  },
}, 'dateInput');

export type DateInputValue = {
  period: PeriodicityType,
  date?: number,
} | undefined;

interface DateInputProps {
  value: DateInputValue,
  onChange?: (value: DateInputValue) => void,
  onSubmit?: (value: DateInputValue) => void,
  onCancel?: () => void,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  onPaste?: (event: ClipboardEvent<HTMLDivElement>) => void,
  placeholder?: string,
  withMultiplayerOutline?: boolean,
  readOnly?: boolean,
  error?: string,
  isEditing?: boolean,
  focusOnMount?: boolean,
  fixedPeriodicity?: boolean,
  defaultPeriodicity?: PeriodicityType,
  UTC?: boolean,
  restingTooltip?: string | (() => Promise<string>),
  withoutClear?: boolean,
  offset?: Offset,
}

const DateInput: FunctionComponent<DateInputProps> = ({
  value,
  onChange,
  onSubmit,
  onCancel,
  onEditionStart,
  onEditionStop,
  onPaste,
  placeholder,
  withMultiplayerOutline = false,
  readOnly = false,
  error,
  isEditing,
  focusOnMount = false,
  fixedPeriodicity = false,
  defaultPeriodicity = PeriodicityType.day,
  UTC,
  restingTooltip,
  withoutClear,
  offset,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const usageVariant = useUsageContext();
  const isTableVariant = usageVariant === UsageVariant.inTable;
  const isFormVariant = usageVariant === UsageVariant.inForm;
  const [showDropdown, setShowDropdown] = useState(focusOnMount);

  const effectiveValue: DateInputValue = {
    period: value?.period ?? defaultPeriodicity,
    date: formatFromStorage(value?.date, UTC),
  };

  const openDropdown = useCallback(() => {
    if (!readOnly) {
      onEditionStart?.();
    }
    setShowDropdown(true);
  }, [onEditionStart, readOnly]);

  const closeDropdown = (reason: EditableCloseReasons) => {
    if (!readOnly) {
      onEditionStop?.();
    }
    setShowDropdown(false);
    if (!readOnly) {
      if (reason !== EditableCloseReasons.onEscapeKeyDown) {
        onSubmit?.(value);
      } else {
        onCancel?.();
      }
    }
  };

  const { dateFormatted, error: formattedError } = formatDate(effectiveValue.date, effectiveValue.period);

  const renderSelected = (restingMode: boolean): ReactElement | null => {
    if (effectiveValue.date) {
      return (
        <div className={classes.valueContainer}>
          <div className={classes.dateContainer}>
            <Typo maxLine={1}>{formatOrUndef(dateFormatted)}</Typo>
          </div>
          {(formattedError || error) && <Icon name={IconName.dangerous} tooltip={formattedError ?? error} colorVariant={IconColorVariant.error} />}
          {!restingMode && !withoutClear && (
            <IconOnlyButton
              iconName={IconName.delete}
              variant={IconOnlyButtonVariants.tertiary}
              tooltip={i18n`Clear`}
              onClick={() => onChange?.({ period: defaultPeriodicity })}
            />
          )}
        </div>
      );
    } else if (restingMode && placeholder && !readOnly && !isTableVariant) {
      return (
        <div className={classes.valueContainer}>
          <Typo
            maxLine={1}
            color={theme.color.text.disabled}
          >
            {placeholder}
          </Typo>
        </div>
      );
    } else if (error) {
      return (
        <div className={classes.valueContainer}>
          <Icon name={IconName.dangerous} tooltip={error} colorVariant={IconColorVariant.error} />
        </div>
      );
    } else if (onPaste && !restingMode) {
      return (
        <div className={classes.pasteContainer} onPaste={onPaste}>
          <TextInputString placeholder={i18n`Paste from clipboard here`} maxLine={1} dropdownMaxLine={1} value="" />
        </div>
      );
    } else {
      return null;
    }
  };

  const dropdownEditorSize = isTableVariant || isFormVariant ? 'unset' : '41.4rem'; // 0.2rem due to outline vs border usage
  const dropdownReadOnly = readOnly;

  return (
    <EditableWithDropdown
      variant={usageVariant}
      editableSizes={{ width: isTableVariant || isFormVariant ? undefined : 'fit-content' }}
      dropdownSizes={{ width: dropdownReadOnly ? undefined : dropdownEditorSize }}
      offset={offset}
      showDropdown={showDropdown}
      openDropdown={openDropdown}
      closeDropdown={closeDropdown}
      autoFocus
      readOnly={dropdownReadOnly}
      renderValue={(inDropdown) => renderSelected(!inDropdown || readOnly)}
      renderDropdown={dropdownReadOnly ? undefined : () => (
        <UsageContextProvider usageVariant={UsageVariant.inForm}>
          <div className={classes.container}>
            <Typo color={theme.color.text.secondary}>{i18n`Granularity`}</Typo>
            <SpacingLine>
              <SearchAndSelect
                onEditionStart={onEditionStart}
                onEditionStop={onEditionStop}
                computeOptions={() => getPeriodicityOptions()}
                onSelect={(option) => {
                  if (option) {
                    if (!effectiveValue?.date && option.id === PeriodicityType.year) {
                      onChange?.({ date: formatFromStorage(periodicities[PeriodicityType.year].getStartOfPeriod(new Date()).valueOf(), UTC), period: option.id });
                    } else {
                      onChange?.(joinObjects(effectiveValue, { period: option.id }));
                    }
                  }
                }}
                selectedOption={getPeriodicityOption(effectiveValue.period)}
                readOnly={readOnly || fixedPeriodicity}
              />
            </SpacingLine>
            <Typo color={theme.color.text.secondary}>{i18n`Date`}</Typo>
            {readOnly ? (
              <SpacingLine>
                <Typo>{dateFormatted}</Typo>
                {error && <Icon name={IconName.dangerous} tooltip={error} colorVariant={IconColorVariant.error} />}
              </SpacingLine>
            ) : (
              <DatePicker
                value={effectiveValue?.date ? new Date(effectiveValue?.date) : undefined}
                onSubmit={(v) => {
                  const date = formatForStorage(v, UTC);
                  onChange?.(joinObjects(effectiveValue, { date }));
                }}
                strategy={DatePickerStrategy.startOf}
                onEditionStart={onEditionStart}
                onEditionStop={onEditionStop}
                periodicity={effectiveValue.period}
              />
            )}
          </div>
        </UsageContextProvider>
      )}
      dropdownFixedWidth
      withMultiplayerOutline={withMultiplayerOutline}
      isEditing={usageVariant === UsageVariant.inCard && isEditing}
      restingTooltip={restingTooltip}
    />
  );
};

export default DateInput;
