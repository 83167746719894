import { newError } from 'yooi-utils';
import type { ObjectStoreReadOnly } from '../../ObjectStoreType';
import type { Operation } from '../../ProtocolType';
import type { InitializedModule } from '../platformModule';
import type { GarbageCollectorRulesLibraryRegistration } from '../PlatformModulesTypes';

interface CollectionResult {
  ruleName: string,
  objectIds: (string | string[])[],
  shouldDelete: (objectId: string | string[]) => boolean,
}

export interface GarbageCollectorRulesLibrary {
  shouldDelete: (id: string | string[]) => boolean,
  collect: (operation: Operation<string | string[]>) => CollectionResult[],
  init: () => void,
}

export const createGarbageCollectorRulesLibrary = (modules: InitializedModule[], objectStore: ObjectStoreReadOnly): GarbageCollectorRulesLibrary => {
  const garbageCollectorRulesLibrary: GarbageCollectorRulesLibraryRegistration[] = [];

  const register = (prefix: string, registration: GarbageCollectorRulesLibraryRegistration) => {
    const ruleName = `${prefix}.${registration.ruleName}`;
    if (garbageCollectorRulesLibrary.find(({ ruleName: existingRuleName }) => ruleName === existingRuleName)) {
      throw newError('Cannot register multiple garbage rules with the same name', { ruleName });
    }
    garbageCollectorRulesLibrary.push({ ...registration, ruleName });
  };

  return {
    shouldDelete: (objectId) => garbageCollectorRulesLibrary.some(({ shouldDelete }) => shouldDelete(objectId)),
    collect: (operation) => garbageCollectorRulesLibrary
      .map(({ ruleName, collect, shouldDelete }): CollectionResult => ({ ruleName, objectIds: collect(operation), shouldDelete }))
      .filter(({ objectIds }) => objectIds.length),
    init: () => {
      garbageCollectorRulesLibrary.splice(0, garbageCollectorRulesLibrary.length);
      modules.forEach((module) => {
        if (module.registerGarbageCollectorRules) {
          module.registerGarbageCollectorRules(objectStore, { register: (registration) => register(module.name, registration) });
        }
      });
    },
  };
};
