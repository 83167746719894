import type { LayoutSwitch } from '../../../utils/routerTypes';
import { NavigationElementContainer } from '../../../utils/useNavigation';
import GetHintContextProvider from './_global/GetHintContextProvider';
import { BypassReadAclProvider } from './_global/useBypassReadAcl';
import EventListPage from './EventListPage';
import ExplorerHomePage from './ExplorerHomePage';
import InstanceDetailPage from './instance/InstanceDetailPage';

const ExplorerRouter: LayoutSwitch = {
  layout: ({ children }) => (
    <NavigationElementContainer element={{ key: 'explorer' }}>
      <BypassReadAclProvider>
        <GetHintContextProvider>
          {children}
        </GetHintContextProvider>
      </BypassReadAclProvider>
    </NavigationElementContainer>
  ),
  switch: {
    '/instance/:key': ({ key }) => (
      <InstanceDetailPage instanceKey={key} />
    ),
    '/event': {
      switch: {
        '/:fromId/:toId': ({ fromId, toId }) => (
          <EventListPage fromEventId={fromId} toEventId={toId} />
        ),
        '/:id': ({ id }) => (
          <EventListPage fromEventId={id} toEventId={id} />
        ),
      },
    },

    '/': () => (<ExplorerHomePage />),
  },
};

export default ExplorerRouter;
