import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { filterNullOrUndefined } from 'yooi-utils';
import base from '../../theme/base';
import { FontVariant } from '../../theme/fontDefinition';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import { SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import Typo from '../atoms/Typo';
import SpacingLine from '../molecules/SpacingLine';

export const CHART_LEGEND_LINE_HEIGHT = 2.6;

const useStyles = makeStyles({
  dot: {
    width: '0.9rem',
    minWidth: '0.9rem',
    height: '0.9rem',
    minHeight: '0.9rem',
    borderRadius: base.borderRadius.circle,
  },
  container: {
    display: 'flex',
    columnGap: spacingRem.splus,
    flexWrap: 'wrap',
    minHeight: '1rem',
    paddingLeft: spacingRem.splus,
    paddingRight: spacingRem.splus,
  },
  clickableItem: {
    cursor: 'pointer',
  },
}, 'chartLegend');

interface ChartLegendProps {
  labels: { name: string | undefined, symbol: { fill: string | undefined }, displayStroke?: boolean, key?: string }[],
  onLabelClick?: (key: string) => void,
}

const ChartLegend: FunctionComponent<ChartLegendProps> = ({ labels, onLabelClick }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <SizeContextProvider sizeVariant={SizeVariant.small}>
        {labels.filter(filterNullOrUndefined).map(({ name, symbol: { fill }, key }) => (
          <div
            key={key}
            className={classnames({
              [classes.clickableItem]: onLabelClick && key,
            })}
            onClick={() => {
              if (onLabelClick && key) {
                onLabelClick?.(key);
              }
            }}
            role="button"
            tabIndex={0}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                if (onLabelClick && key) {
                  onLabelClick?.(key);
                }
              }
            }}
          >
            <SpacingLine>
              <div className={classes.dot} style={{ backgroundColor: fill }} />
              <Typo variant={FontVariant.small} color={theme.color.text.secondary} maxLine={1}>{name}</Typo>
            </SpacingLine>
          </div>
        ))}
      </SizeContextProvider>
    </div>
  );
};

export default ChartLegend;
