import { ValidationStatus } from 'yooi-store';
import { CollaborationMessage } from '../collaborationModule/ids';
import { CommonAsType } from '../common/fields/commonPropertyType';
import { User } from '../conceptModule/ids';
import { Notification_Role_User } from './ids';
import { registerModel } from './module';

const { association } = registerModel;

association({
  label: 'Notification',
  roles: [{ label: 'User', targetTypeId: User }, { label: 'Message', targetTypeId: CollaborationMessage }],
  accessControlList: {
    READ: () => ({ userId }, objectId) => {
      if (objectId[Notification_Role_User + 1] === userId) {
        return { rule: 'notification.currentUser', status: ValidationStatus.ACCEPTED };
      } else {
        return { rule: 'notification.otherUser', status: ValidationStatus.REJECTED };
      }
    },
    WRITE: () => (_, objectId) => ({ rule: 'notification.write.delegate', status: ValidationStatus.DELEGATED, targetId: objectId, targetAction: 'READ' }),
    DELETE: () => (_, objectId) => ({ rule: 'notification.delete.delegate', status: ValidationStatus.DELEGATED, targetId: objectId, targetAction: 'READ' }),
  },
})
  .property({ label: 'Notification_Seen', as: CommonAsType.boolean })
  .property({ label: 'Notification_CreatedAt', as: CommonAsType.number })
  .property({ label: 'Notification_Title', as: CommonAsType.string });
