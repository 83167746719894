import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { WidgetDisplayMode, WidgetRaw, WidgetStoreObject, WidgetTitleMode } from 'yooi-modules/modules/dashboardModule';
import { Widget_DisplayMode, Widget_Field, Widget_HeaderBackgroundColor, Widget_Title, Widget_TitleMode } from 'yooi-modules/modules/dashboardModule/ids';
import Button, { ButtonVariant } from '../../../components/atoms/Button';
import { IconName } from '../../../components/atoms/Icon';
import Tooltip from '../../../components/atoms/Tooltip';
import Typo, { TypoVariant } from '../../../components/atoms/Typo';
import WidgetActionButton from '../../../components/atoms/WidgetActionButton';
import { widgetContainerSelectorsClasses } from '../../../components/atoms/WidgetContainer';
import { TextInputStringFieldAlign } from '../../../components/inputs/TextInputString';
import Chooser from '../../../components/molecules/Chooser';
import InlineLink from '../../../components/molecules/InlineLink';
import Menu from '../../../components/molecules/Menu';
import Overlay from '../../../components/molecules/Overlay';
import SpacingLine from '../../../components/molecules/SpacingLine';
import useStore from '../../../store/useStore';
import base from '../../../theme/base';
import { getMostReadableColorFromBackgroundColor } from '../../../theme/colorUtils';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { remToPx } from '../../../utils/sizeUtils';
import { formatOrUndef } from '../../../utils/stringUtils';
import useDeleteModal from '../../../utils/useDeleteModal';
import useNavigation from '../../../utils/useNavigation';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import useTheme from '../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../utils/useUsageContext';
import useWidgetSizeContext, { WidgetSizeContextProvider } from '../../../utils/useWidgetSizeContext';
import { duplicateWidget, getWidgetUrl } from '../../_global/dashboardUtils';
import StoreColorPickerInput from '../../_global/input/StoreColorPickerInput';
import StoreTextInputField from '../../_global/input/StoreTextInputField';
import type { NavigationFilter } from '../../_global/navigationUtils';
import { getColorPalette } from '../../utils/standardColorsUtils';
import { DashboardLayoutVariants, useDashboardLayoutMode } from '../../utils/useDashboardLayoutMode';
import FieldWidget from './FieldWidget';

const useStyles = makeStyles((theme) => ({
  typoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  widgetContent: {
    flexGrow: 1,
  },
  widgetEditContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: spacingRem.xs,
    paddingRight: spacingRem.xs,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: spacingRem.s,
    padding: spacingRem.xs,
  },
  toolbarContainer: {
    position: 'absolute',
    top: spacingRem.xs,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: base.borderRadius.medium,
    overflow: 'hidden',
  },
  toolbarContainerLeft: {
    left: spacingRem.xs,
  },
  toolbarContainerRight: {
    right: spacingRem.xs,
  },
  toolbarContainerHidden: {
    visibility: 'hidden',
  },
  overflowContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: spacingRem.s,
    borderRadius: base.borderRadius.medium,
    backgroundColor: theme.color.background.neutral.default,
    boxShadow: base.shadowElevation.medium,
  },
  customizeMainContainer: {
    padding: spacingRem.splus,
    rowGap: spacingRem.m,
    minWidth: '27.5rem',
  },
  customizeSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.s,
  },
  customizeSeparator: {
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
  },
  customizeLabelAndValue: {
    display: 'flex',
    flexDirection: 'row',
    height: '3.2rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: spacingRem.s,
  },
}), 'dashboardWidget');

interface DashboardWidgetProps {
  widgetId: string,
  parametersMapping: ParametersMapping,
  onDelete?: () => void,
  dragHandlerClassName?: string,
  readOnly?: boolean,
  isPreview?: boolean,
}

const DashboardWidget: FunctionComponent<DashboardWidgetProps> = ({
  widgetId,
  parametersMapping,
  onDelete,
  dragHandlerClassName,
  readOnly = false,
  isPreview = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const navigation = useNavigation<NavigationFilter>();

  const widget = store.getObject<WidgetStoreObject>(widgetId);
  const field = widget.navigateOrNull(Widget_Field);

  const headerBackgroundColor = widget[Widget_HeaderBackgroundColor] ?? (widget[Widget_DisplayMode]?.type === 'color' ? widget[Widget_DisplayMode].color : undefined);
  const titleColor = headerBackgroundColor ? getMostReadableColorFromBackgroundColor(headerBackgroundColor) : undefined;

  const mode = useDashboardLayoutMode();
  const isViewMode = mode === DashboardLayoutVariants.view || readOnly;
  const { height, width } = useWidgetSizeContext();

  const [doDelete, deleteModal] = useDeleteModal({
    doDelete: onDelete ?? (() => {}),
    shouldConfirm: () => field !== null,
    getModalProps: () => ({
      title: i18n`Are you sure that you want to delete this widget?`,
      content: (<Typo>{i18n`Deleting this item is irreversible and will permanently remove it from the platform.`}</Typo>),
    }),
  });

  const displayTitle = widget[Widget_TitleMode] !== 'hide' && (widget[Widget_TitleMode] === 'always' || (widget[Widget_Title] !== undefined && widget[Widget_Title] !== ''));

  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const [toolbarPosition, setToolbarPosition] = useState<'left' | 'right'>('right');

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showCustomize, setShowCustomize] = useState(false);

  const displayModeOptions: { [key in WidgetDisplayMode['type']]: { key: key, name: string } } = {
    card: { key: 'card', name: i18n`Card` },
    borderless: { key: 'borderless', name: i18n`Borderless` },
    color: { key: 'color', name: i18n`Colored` },
  };
  const displayModeActions = Object.values(displayModeOptions);

  const titleModeOptions: { [key in WidgetTitleMode]: { key: key, name: string, tooltip: string } } = {
    auto: { key: 'auto', name: i18n`Auto`, tooltip: i18n`Show the title only when not empty` },
    always: { key: 'always', name: i18n`Always show`, tooltip: i18n`Show the title even when empty` },
    hide: { key: 'hide', name: i18n`Hide`, tooltip: i18n`Always hide the title` },
  };
  const titleModeActions = Object.values(titleModeOptions);

  const colorPalette = getColorPalette(store);

  return (
    <>
      {!isViewMode && displayTitle ? (
        <div className={classes.headerContainer} style={{ backgroundColor: headerBackgroundColor }}>
          <UsageContextProvider usageVariant={UsageVariant.inCard}>
            <StoreTextInputField
              textVariant={TypoVariant.tabTitle}
              initialValue={widget[Widget_Title]}
              onSubmit={(value) => store.updateObject(widgetId, { [Widget_Title]: value })}
              placeholder={i18n`Click to edit name`}
              maxLine={1}
              align={TextInputStringFieldAlign.center}
              color={titleColor}
              fullWidth
            />
          </UsageContextProvider>
        </div>
      ) : null}
      {isViewMode && displayTitle ? (
        <div className={classes.headerContainer} style={{ backgroundColor: headerBackgroundColor }}>
          <div className={classes.typoContainer}>
            <Tooltip title={formatOrUndef(widget[Widget_Title])}>
              <SpacingLine>
                <Typo maxLine={1} variant={TypoVariant.tabTitle} color={titleColor} fullWidth>{widget[Widget_Title]}</Typo>
              </SpacingLine>
            </Tooltip>
          </div>
        </div>
      ) : null}
      {!field && !isViewMode ? (
        <div className={classnames(classes.widgetContent, classes.widgetEditContainer)}>
          <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
            <InlineLink {...navigation.createNavigationPayload(widgetId, { pathname: getWidgetUrl(widgetId) })} noStyle>
              <Button
                title={i18n`Configure`}
                iconName={IconName.tune}
                variant={ButtonVariant.secondary}
                maxLine={1}
              />
            </InlineLink>
          </SizeContextProvider>
        </div>
      ) : null}
      {field ? (
        <WidgetSizeContextProvider height={height - (displayTitle ? (remToPx(3.2 /* input */) + (remToPx(spacingRem.xs) * 2)) : 0)} width={width}>
          <FieldWidget fieldId={field.id} parametersMapping={parametersMapping} isPreview={isPreview} readOnly={isPreview} />
        </WidgetSizeContextProvider>
      ) : null}
      {isViewMode ? null : (
        <div
          ref={toolbarRef}
          className={classnames({
            [widgetContainerSelectorsClasses.hoveredVisible]: true,
            [classes.toolbarContainer]: true,
            [classes.toolbarContainerLeft]: toolbarPosition === 'left',
            [classes.toolbarContainerRight]: toolbarPosition === 'right',
            [classes.toolbarContainerHidden]: !showMoreOptions && !showCustomize,
          })}
        >
          {
            dragHandlerClassName !== undefined
              ? (
                <WidgetActionButton
                  tooltip={i18n`Move`}
                  icon={IconName.drag_indicator}
                  action={{ type: 'drag', className: dragHandlerClassName }}
                />
              )
              : null
          }
          <WidgetActionButton
            tooltip={i18n`Customize display`}
            icon={IconName.brush}
            action={{
              type: 'click',
              onClick: () => {
                setShowCustomize((current) => (!current));
              },
            }}
            active={showCustomize}
          />
          <WidgetActionButton
            tooltip={i18n`Open configuration`}
            icon={IconName.tune}
            action={{
              type: 'click',
              onClick: ({ isCtrlKeyPressed, isMiddleClick }) => {
                const pathname = getWidgetUrl(widgetId);
                if (isCtrlKeyPressed || isMiddleClick) {
                  window.open(pathname, '_blank', 'noopener');
                } else {
                  navigation.push(widgetId, { pathname });
                }
              },
            }}
          />
          <WidgetActionButton
            tooltip={i18n`More options`}
            icon={IconName.more_horiz}
            action={{
              type: 'click',
              onClick: () => {
                setShowMoreOptions((current) => (!current));
              },
            }}
            active={showMoreOptions}
          />
        </div>
      )}
      {
        !isViewMode && showMoreOptions ? (
          <Menu
            anchorRef={toolbarRef}
            placement={toolbarPosition === 'left' ? 'bottom-end' : 'bottom-start'}
            offset={[0, remToPx(spacingRem.xs)]}
            onClose={() => setShowMoreOptions(false)}
            items={[
              {
                key: 'move',
                name: toolbarPosition === 'right' ? i18n`Move toolbar left` : i18n`Move toolbar right`,
                icon: toolbarPosition === 'right' ? IconName.arrow_back : IconName.arrow_forward,
                onClick: () => {
                  setToolbarPosition((current) => (current === 'right' ? 'left' : 'right'));
                  setShowMoreOptions(false);
                },
              },
              {
                key: 'duplicate',
                name: i18n`Duplicate`,
                icon: IconName.content_copy_outline,
                onClick: () => {
                  duplicateWidget(store, widgetId);
                  setShowMoreOptions(false);
                },
              },
              {
                key: 'delete',
                name: i18n`Delete`,
                icon: IconName.delete,
                onClick: () => {
                  doDelete();
                  setShowMoreOptions(false);
                },
                danger: true,
              },
            ]}
          />
        ) : null
      }
      {
        !isViewMode && showCustomize ? (
          <Overlay
            target={toolbarRef}
            placement={toolbarPosition === 'left' ? 'bottom-end' : 'bottom-start'}
            offset={[0, remToPx(spacingRem.xs)]}
            onBackdropClick={() => {
              setShowCustomize(false);
            }}
            onEscapeKeyDown={() => {
              setShowCustomize(false);
            }}
          >
            <div className={classnames(classes.overflowContainer, classes.customizeMainContainer)}>
              <div className={classes.customizeSectionContainer}>
                <span className={classes.customizeLabelAndValue}>
                  <Typo>{i18n`Title`}</Typo>
                  <Chooser
                    actions={titleModeActions}
                    onClick={(index) => {
                      const action = titleModeActions.at(index);
                      if (action !== undefined) {
                        store.updateObject<WidgetRaw>(widgetId, { [Widget_TitleMode]: action.key });
                      }
                    }}
                    selectedIndexes={[titleModeActions.findIndex(({ key }) => (key === (widget[Widget_TitleMode] ?? 'auto')))]}
                  />
                </span>
                <UsageContextProvider usageVariant={UsageVariant.inForm}>
                  <StoreTextInputField
                    initialValue={widget[Widget_Title]}
                    onSubmit={(newTitle) => {
                      store.updateObject<WidgetRaw>(widget.id, { [Widget_Title]: newTitle });
                    }}
                    placeholder={i18n`Add title`}
                    maxLine={1}
                  />
                </UsageContextProvider>
                {
                  displayTitle
                    ? (
                      <span className={classes.customizeLabelAndValue}>
                        <Typo color={theme.color.text.secondary}>{i18n`Header color`}</Typo>
                        <UsageContextProvider usageVariant={UsageVariant.inForm}>
                          <StoreColorPickerInput
                            initialValue={widget[Widget_HeaderBackgroundColor]}
                            onSubmit={(newColor) => {
                              store.updateObject<WidgetRaw>(widget.id, { [Widget_HeaderBackgroundColor]: newColor });
                            }}
                            colorPalette={colorPalette}
                          />
                        </UsageContextProvider>
                      </span>
                    )
                    : null
                }
              </div>
              <span className={classes.customizeSeparator} />
              <div className={classes.customizeSectionContainer}>
                <span className={classes.customizeLabelAndValue}>
                  <Typo>{i18n`Widget`}</Typo>
                  <Chooser
                    actions={displayModeActions}
                    onClick={(index) => {
                      const action = displayModeActions.at(index);
                      if (action !== undefined) {
                        store.updateObject<WidgetRaw>(widgetId, {
                          [Widget_DisplayMode]: action.key === 'color' ? { type: 'color', color: base.color.gray['300'] } : { type: action.key },
                        });
                      }
                    }}
                    selectedIndexes={[displayModeActions.findIndex(({ key }) => (key === (widget[Widget_DisplayMode]?.type ?? 'card')))]}
                  />
                </span>
                {
                  widget[Widget_DisplayMode]?.type === 'color' ? (
                    <span className={classes.customizeLabelAndValue}>
                      <Typo color={theme.color.text.secondary}>{i18n`Background color`}</Typo>
                      <UsageContextProvider usageVariant={UsageVariant.inForm}>
                        <StoreColorPickerInput
                          initialValue={widget[Widget_DisplayMode].color}
                          onSubmit={(newColor) => {
                            store.updateObject<WidgetRaw>(widget.id, { [Widget_DisplayMode]: { type: 'color', color: newColor ?? base.color.gray['300'] } });
                          }}
                          colorPalette={colorPalette}
                        />
                      </UsageContextProvider>
                    </span>
                  ) : null
                }
              </div>
            </div>
          </Overlay>
        ) : null
      }
      {deleteModal}
    </>
  );
};

export default DashboardWidget;
