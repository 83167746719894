import classnames from 'classnames';
import type { FocusEventHandler, FunctionComponent, KeyboardEvent } from 'react';
import { useCallback, useRef } from 'react';
import { joinObjects } from 'yooi-utils';
import base from '../../theme/base';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import useSizeContext, { buildInputSizeVariantMinusBorderClasses, SizeVariant } from '../../utils/useSizeContext';
import Icon, { IconColorVariant, IconName } from '../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../atoms/IconOnlyButton';
import { sizeVariantToTypoVariant, typoMaxWidth, TypoVariant } from '../atoms/Typo';

const useStyles = makeStyles((theme) => (joinObjects(
  {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      borderRadius: base.borderRadius.medium,
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      outline: 'none',
      boxShadow: 'none',
      resize: 'none',
      backgroundColor: theme.color.transparent,
      borderRadius: base.borderRadius.medium,
      verticalAlign: 'top',
      '& > textarea': {
        margin: spacingRem.none,
      },
    },
    withHoverAndFocusBorder: {
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: theme.color.transparent,
      '&:hover': {
        borderColor: theme.color.border.hover,
        backgroundColor: theme.color.background.neutral.default,
      },
      '&:focus-within': {
        borderColor: theme.color.border.dark,
        backgroundColor: theme.color.background.neutral.default,
      },
    },
    secondaryPlaceholder: {
      '& ::placeholder': {
        color: theme.color.text.disabled,
      },
    },
    disabled: {
      borderColor: theme.color.border.default,
      color: theme.color.text.disabled,
    },
    inForm: {
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: theme.color.transparent,
      '& ::placeholder': {
        color: theme.color.text.disabled,
      },
      '&:hover': {
        borderColor: theme.color.border.default,
        backgroundColor: theme.color.background.neutral.default,
      },
      '&:focus-within': {
        borderColor: theme.color.border.dark,
        backgroundColor: theme.color.background.neutral.default,
      },
    },
    withOutline: {
      borderColor: theme.color.border.default,
    },
    error: {
      borderColor: theme.color.border.danger,
      '&:active, &:focus, &:focus-within': {
        borderColor: theme.color.border.danger,
      },
      '&:hover': {
        borderColor: theme.color.border.danger,
      },
    },
    input: {
      verticalAlign: 'top',
      border: 'none',
      backgroundColor: theme.color.transparent,
      resize: 'none',
      outline: 'none',
      padding: '0',
      flexGrow: 1,
      width: '100%',
      color: theme.color.text.primary,
      '&:disabled': {
        color: theme.color.text.disabled,
      },
      // Override webkit autofill style
      '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
        '-webkit-box-shadow': `0 0 0 3rem ${theme.color.background.neutral.default} inset`,
        '-webkit-text-fill-color': theme.color.text.primary,
      },
      '&:-webkit-autofill:disabled, &:-webkit-autofill:disabled:hover, &:-webkit-autofill:disabled:focus, &:-webkit-autofill:disabled:active': {
        '-webkit-text-fill-color': theme.color.text.disabled,
      },
    },
    actionSpacer: {
      minWidth: '0.4rem',
    },
  },
  theme.font,
  buildInputSizeVariantMinusBorderClasses('container', ['minHeight'])
)), 'rawInput');

export enum RawInputType {
  text = 'text',
  password = 'password',
}

interface RawInputProps {
  name: string,
  type: RawInputType,
  value?: string,
  onSubmit?: (value: string | undefined) => void,
  onCancel?: (value: string | undefined) => void,
  onChange?: (value: string) => void,
  onEnterKeyPressed?: () => void,
  error?: string,
  disabled?: boolean,
  fullWidth?: boolean,
  focusOnMount?: boolean,
  action?: { icon: IconName, tooltip: string, onClick: () => void },
}

const RawInput: FunctionComponent<RawInputProps> = ({
  name,
  type,
  value,
  onSubmit,
  onChange,
  onCancel,
  onEnterKeyPressed,
  error,
  disabled = false,
  fullWidth = false,
  focusOnMount = false,
  action,
}) => {
  const classes = useStyles();

  const { sizeVariant } = useSizeContext();
  const computedTextVariant = sizeVariantToTypoVariant[sizeVariant];

  const containerRef = useRef<HTMLDivElement>(null);

  const inputFocusValue = useRef<string>();

  const didMount = useRef(false);

  const onContainerKeyDown = useCallback((event: KeyboardEvent) => {
    // Only care about key down directly on the current div
    if (event.target === event.currentTarget) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    }
  }, []);

  const onInputFocus: FocusEventHandler<HTMLInputElement> = useCallback((event) => {
    inputFocusValue.current = event.target.value;
  }, []);

  const onInputBlur: FocusEventHandler<HTMLInputElement> = useCallback((event) => {
    const newValue = event.target.value;
    if (inputFocusValue.current !== newValue && onSubmit) {
      onSubmit(newValue);
    }
  }, [onSubmit]);

  const onInputKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onEnterKeyPressed?.();
      // trigger onInputBlur so no need to do onSummit
      containerRef.current?.focus();
    } else if (event.key === 'Escape') {
      event.stopPropagation();
      if (onCancel) {
        onCancel(inputFocusValue.current);
      }
      containerRef.current?.focus();
    }
  }, [onCancel, onEnterKeyPressed]);

  return (
    <div
      className={classnames(classes.container, classes.withOutline, classes.inForm, classes.withHoverAndFocusBorder)}
    >
      <div
        ref={containerRef}
        className={classnames({
          [classes.inputContainer]: true,
          [classes[`container_${sizeVariant}`]]: true,
          [classes.secondaryPlaceholder]: true,
          [classes.disabled]: disabled,
          [classes.error]: Boolean(error),
        })}
        style={{
          maxWidth: !fullWidth ? typoMaxWidth[computedTextVariant] : undefined,
          padding: computedTextVariant === TypoVariant.small ? `0.1rem ${spacingRem.s}` : `0.3rem ${spacingRem.s}`,
        }}
        onKeyDown={onContainerKeyDown}
        role="none"
      >
        <input
          ref={focusOnMount && !didMount.current ? (event) => {
            didMount.current = true;
            event?.focus();
          } : undefined}
          name={name}
          className={classnames(classes.input, classes[computedTextVariant])}
          type={type}
          value={value}
          onChange={onChange ? (event) => onChange(event.target.value) : undefined}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          onKeyDown={onInputKeyDown}
          disabled={disabled}
        />
        {error && (
          <>
            <div className={classes.actionSpacer} />
            <Icon name={IconName.dangerous} tooltip={error} colorVariant={IconColorVariant.error} />
          </>
        )}

        {action && (
          <>
            <div className={classes.actionSpacer} />
            <IconOnlyButton
              sizeVariant={SizeVariant.small}
              variant={IconOnlyButtonVariants.tertiary}
              iconName={action.icon}
              tooltip={action.tooltip}
              onClick={() => action.onClick()}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default RawInput;
