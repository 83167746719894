import type { FunctionComponent } from 'react';
import type { DimensionsMapping } from 'yooi-modules/modules/conceptModule';
import { canWrite, imageFieldHandler, ParsedDimensionType, parseDimensionMapping } from 'yooi-modules/modules/conceptModule';
import { AspectRatioEnum } from 'yooi-modules/modules/conceptModule/fields/imageField';
import useAcl from '../../../../store/useAcl';
import useStore from '../../../../store/useStore';
import useUpdateActivity from '../../../../store/useUpdateActivity';
import StoreImagePicker from '../../input/StoreImagePicker';
import { aspectRatios } from './imageFieldUtils';

interface ImageBlockFieldProps {
  dimensionsMapping: DimensionsMapping,
  fieldId: string,
  borderless: boolean,
  readOnly: boolean,
}

const ImageBlockField: FunctionComponent<ImageBlockFieldProps> = ({ dimensionsMapping, fieldId, borderless, readOnly }) => {
  const store = useStore();
  const { canWriteObject } = useAcl();

  const updateActivity = useUpdateActivity();

  const parsedDimension = parseDimensionMapping(dimensionsMapping);

  const { getValueResolution, resolveConfiguration } = imageFieldHandler(store, fieldId);
  const { aspectRatio, integrationOnly } = resolveConfiguration();
  const valueResolution = getValueResolution(dimensionsMapping);

  const imageUrl = valueResolution.value && parsedDimension.type === ParsedDimensionType.MonoDimensional
    ? store.getAttachmentUrl(parsedDimension.objectId, fieldId)(valueResolution.value.revision, true) : undefined;

  return (
    <StoreImagePicker
      initialValue={imageUrl ? { image: { type: 'url', url: imageUrl }, position: valueResolution.value?.position } : undefined}
      onSubmit={(newValue) => {
        if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
          if (newValue) {
            if (newValue.image.type === 'buffer') {
              store.uploadAttachment(
                {
                  objectId: parsedDimension.objectId,
                  propertyId: fieldId,
                  contentType: newValue.image.contentType,
                  data: newValue.image.data,
                },
                (revisionId) => {
                  store.updateObject(parsedDimension.objectId, {
                    [fieldId]: revisionId,
                  });
                }
              );
            }
            store.updateObject(parsedDimension.objectId, { [`${fieldId}_position`]: newValue.position ?? null });
          } else {
            store.updateObject(parsedDimension.objectId, { [fieldId]: null });
          }
        }
      }}
      onEditionStart={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => updateActivity.onEnterEdition(parsedDimension.objectId, fieldId) : undefined}
      onEditionStop={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => updateActivity.onExitEdition(parsedDimension.objectId, fieldId) : undefined}
      readOnly={parsedDimension.type !== ParsedDimensionType.MonoDimensional || readOnly || !canWrite(dimensionsMapping, canWriteObject) || Boolean(integrationOnly)}
      aspectRatio={typeof aspectRatio === 'string' ? aspectRatios[aspectRatio] : (aspectRatio ?? aspectRatios[AspectRatioEnum.ThinBanner])}
      borderless={borderless}
    />
  );
};

export default ImageBlockField;
