import classnames from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';
import base from '../../theme/base';
import { getSpacing, Spacing, spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import useSizeContext, { SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import Button, { ButtonVariant } from '../atoms/Button';
import Icon, { IconName } from '../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../atoms/IconOnlyButton';
import Tooltip from '../atoms/Tooltip';
import Typo from '../atoms/Typo';

export enum BannerVariant {
  info = 'info',
  warning = 'warning',
  danger = 'danger',
  success = 'success',
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'inline-flex',
    flexDirection: 'row',
    columnGap: spacingRem.s,
    alignItems: 'flex-start',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderRadius: base.borderRadius.medium,
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    paddingBottom: spacingRem.xs,
    paddingTop: spacingRem.xs,
  },
  containerFullWidth: {
    display: 'flex',
  },
  containerShadow: {
    boxShadow: base.shadowElevation.medium,
  },
  containerWithActions: {
    paddingRight: spacingRem.s,
    paddingTop: getSpacing(Spacing.s, -0.1 /* action border */),
    paddingBottom: getSpacing(Spacing.s, -0.1 /* action border */),
  },
  info: {
    borderColor: theme.color.border.info,
    backgroundColor: theme.color.background.info.light,
  },
  loading: {
    borderColor: theme.color.border.info,
    backgroundColor: theme.color.background.info.light,
  },
  warning: {
    borderColor: theme.color.border.warning,
    backgroundColor: theme.color.background.warning.light,
  },
  danger: {
    borderColor: theme.color.border.danger,
    backgroundColor: theme.color.background.danger.light,
  },
  success: {
    borderColor: theme.color.border.success,
    backgroundColor: theme.color.background.success.light,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: spacingRem.s,
  },
  textContainerMaxWidth: {
    maxWidth: '64rem',
  },
  iconContainer: {
    height: '2.2rem',
    display: 'flex',
    alignItems: 'center',
  },
  iconContainerSmall: {
    height: '1.6rem',
  },
  actionsContainer: {
    // https://www.w3.org/TR/css-flexbox-1/#auto-margins
    // Prior to alignment via justify-content and align-self, any positive free space is distributed to auto margins in that dimension.
    marginLeft: 'auto',
    flexShrink: 0,
  },
}), 'banner');

interface IconOnlyAction {
  key: string,
  icon: IconName,
  tooltip: string,
  onClick: () => void,
  disabled?: boolean,
}

interface ButtonAction {
  key: string,
  icon: IconName,
  title: string,
  onClick: () => void,
  disabled?: boolean,
  tooltip?: string,
}

type Action = IconOnlyAction | ButtonAction;

interface BannerProps {
  variant: BannerVariant,
  title: string | ReactElement | null,
  actions?: Action[],
  withShadow?: boolean,
  fullWidth?: boolean,
  icon?: { icon?: IconName, iconColor?: string, tooltip?: string },
}

const isIconOnlyAction = (action: Action): action is IconOnlyAction => (
  (action as { title: string | undefined }).title === undefined
);

const Banner: FunctionComponent<BannerProps> = ({ variant, title, actions = [], withShadow = false, fullWidth = false, icon }) => {
  const theme = useTheme();
  const classes = useStyles();

  const { sizeVariant } = useSizeContext();

  const icons: Record<BannerVariant, { icon: IconName, iconColor: string, tooltip: string }> = {
    [BannerVariant.info]: { icon: IconName.info, iconColor: theme.color.text.info, tooltip: i18n`Information` },
    [BannerVariant.warning]: { icon: IconName.warning, iconColor: theme.color.text.warning, tooltip: i18n`Warning` },
    [BannerVariant.danger]: { icon: IconName.dangerous, iconColor: theme.color.text.danger, tooltip: i18n`Danger` },
    [BannerVariant.success]: { icon: IconName.check_circle, iconColor: theme.color.text.success, tooltip: i18n`Success` },
  };

  const renderTitle = () => {
    if (typeof title === 'string') {
      return (
        <span className={classnames({ [classes.textContainer]: true, [classes.textContainerMaxWidth]: !fullWidth })}>
          <Typo>
            {title}
          </Typo>
        </span>
      );
    } else {
      return (
        <div>
          {title}
        </div>
      );
    }
  };

  return (
    <div
      className={classnames({
        [classes.container]: true,
        [classes.containerFullWidth]: fullWidth,
        [classes[variant]]: true,
        [classes.containerShadow]: withShadow,
        [classes.containerWithActions]: actions.length > 0,
      })}
    >
      <span className={classnames({ [classes.iconContainer]: true, [classes.iconContainerSmall]: sizeVariant === SizeVariant.small })}>
        <Icon name={icon?.icon ?? icons[variant].icon} color={icon?.iconColor ?? icons[variant].iconColor} tooltip={icon?.tooltip ?? icons[variant].tooltip} />
      </span>
      {renderTitle()}
      {actions.length > 0 ? (
        <span className={classnames({ [classes.actionsContainer]: true, [classes.iconContainer]: true, [classes.iconContainerSmall]: sizeVariant === SizeVariant.small })}>
          {actions.map((action) => (
            (!isIconOnlyAction(action))
              ? (
                <Tooltip key={action.key} title={action.tooltip ?? action.title}>
                  <Button iconName={action.icon} title={action.title} variant={ButtonVariant.secondary} onClick={action.onClick} maxLine={1} disabled={action.disabled} />
                </Tooltip>
              )
              : (
                <IconOnlyButton
                  key={action.key}
                  iconName={action.icon}
                  tooltip={action.tooltip ?? ''}
                  variant={IconOnlyButtonVariants.secondary}
                  onClick={action.onClick}
                  disabled={action.disabled}
                />
              )
          ))}
        </span>
      ) : null}
    </div>
  );
};

export default Banner;
