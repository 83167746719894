import type { VictoryAxisProps } from 'victory';
import { joinObjects } from 'yooi-utils';
import type { Theme } from '../../../theme/themeUtils';
import { getFontStyle } from '../../../utils/fontUtils';

export const chartAxisStyle = (theme: Theme, withTransparentLine = false, withGrid = false): VictoryAxisProps['style'] => ({
  axis: {
    stroke: withTransparentLine ? theme.color.transparent : theme.color.background.neutral.subtle,
    transition: 'opacity 200ms ease-in',
    strokeWidth: 2,
    shapeRendering: 'crispedges',
  },
  ticks: {
    stroke: withTransparentLine ? theme.color.transparent : theme.color.background.neutral.subtle,
    shapeRendering: 'crispedges',
    strokeWidth: 2,
    size: 8,
  },
  tickLabels: joinObjects(
    getFontStyle(theme.font.small),
    { fill: theme.color.text.secondary }
  ),
  grid: withGrid ? {
    stroke: theme.color.background.neutral.subtle,
    strokeLinecap: 'round',
    strokeDasharray: '6 6',
    strokeWidth: '0.2rem',
  } : undefined,
});
