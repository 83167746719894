import type { FunctionComponent } from 'react';
import { computeLightShade } from '../../app/utils/standardColorsUtils';
import { spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import ColorSquare from './internal/ColorSquare';

const useStyles = makeStyles({
  colorPaletteContainer: {
    display: 'flex',
    gap: spacingRem.s,
  },
  colorGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingRem.s,
  },
}, 'colorPalette');

interface ColorPaletteProps {
  standardColors: { value: string, label?: string }[],
  withoutLighterColors?: boolean,
  selectedColor?: { index: number, lightVersion: boolean },
  onClick?: (color: string) => void,
}

const ColorPalette: FunctionComponent<ColorPaletteProps> = ({
  standardColors,
  withoutLighterColors = false,
  selectedColor,
  onClick,
}) => {
  const classes = useStyles();

  const renderColor = (colorValue: string, id: { index: number, lightVersion: boolean }, tooltip: string | undefined) => {
    const isSelected = selectedColor && selectedColor.index === id.index && selectedColor.lightVersion === id.lightVersion;
    return (
      <ColorSquare
        color={colorValue}
        isSelected={isSelected}
        onClick={onClick ? () => onClick(colorValue) : undefined}
        tooltip={tooltip}
        clearable
      />
    );
  };

  return (
    <div className={classes.colorPaletteContainer}>
      {standardColors.map((color, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={`${color.value}_${index}`} className={classes.colorGroupContainer}>
          {renderColor(color.value, { index, lightVersion: false }, color.label)}
          {
            withoutLighterColors
              ? null
              : renderColor(computeLightShade(color.value), { index, lightVersion: true }, color.label === undefined ? undefined : i18n`Lighter version of "${color.label}"`)
          }
        </span>
      ))}
    </div>
  );
};

export default ColorPalette;
