import type { FunctionComponent } from 'react';
import { Fragment } from 'react';
import type { WithOptionalField } from 'yooi-utils';
import type { SpacingDefinitionProp } from '../../theme/spacingDefinition';
import { buildPadding, Spacing, spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import useNavigation from '../../utils/useNavigation';
import useTheme from '../../utils/useTheme';
import Icon, { IconColorVariant, IconName } from '../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../atoms/IconOnlyButton';
import Tooltip from '../atoms/Tooltip';
import Typo from '../atoms/Typo';
import Link from './Link';
import SpacingLine from './SpacingLine';

export enum NavigationTitlePathElements {
  notification_settings = 'notification_settings',
  configuration = 'configuration',
  collaboration = 'collaboration',
  organization = 'organization',
  organizationInstance = 'organizationInstance',
  fieldBlockDisplay = 'fieldBlockDisplay',
  user = 'user',
  group = 'group',
  explorer = 'explorer',
  explorerInstance = 'explorerInstance',
  platformConfiguration = 'platformConfiguration',
  formulaDocumentation = 'formulaDocumentation',
  integration = 'integration',
  security = 'security',
  authorization = 'authorization',
  workflow = 'workflow',
  automation = 'automation',
}

const elementIcons: Record<NavigationTitlePathElements, IconName> = {
  notification_settings: IconName.notifications,
  collaboration: IconName.comment,
  configuration: IconName.settings,
  fieldBlockDisplay: IconName.settings,
  organization: IconName.account_tree,
  organizationInstance: IconName.category,
  user: IconName.person,
  group: IconName.group,
  explorer: IconName.explore,
  explorerInstance: IconName.pin,
  platformConfiguration: IconName.auto_fix,
  formulaDocumentation: IconName.folder,
  integration: IconName.power,
  security: IconName.lock,
  authorization: IconName.groups,
  workflow: IconName.route,
  automation: IconName.bolt,
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.color.background.neutral.default,
    display: 'flex',
    alignItems: 'center',
    minHeight: '4.6rem',
    flexGrow: 1,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: spacingRem.blockLeftColumnSpacing,
    marginRight: '0.7rem', // reproduce the activity spacing in the navigation title
  },
  chevronContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2.4rem',
  },
  dashContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemsContainer: {
    display: 'flex',
  },
  nameContainer: {
    maxWidth: '40rem',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
  },
  linkContainer: {
    maxWidth: '40rem',
  },
}), 'navigationTitle');

export interface NavigationElement {
  key: string,
  name: string,
  tooltip?: string,
  element: NavigationTitlePathElements,
  icon?: IconName,
  path: string,
  hash?: string,
  isEmbedded?: boolean,
}

// A NavigationElement[] that can eventually have a NavigationElement without path as last item
export type NavigationElements = [...NavigationElement[], WithOptionalField<NavigationElement, 'path'>] | NavigationElement[];
export type NavigationElementsOrSingleKey = [...(NavigationElement | { key: string })[], WithOptionalField<NavigationElement, 'path'>] | (NavigationElement | { key: string })[];

interface NavigationTitleProps {
  navigationElements: NavigationElements,
  withLinkOnLastElement?: boolean,
  padding?: SpacingDefinitionProp,
  onShare?: () => void,
}

const NavigationTitle: FunctionComponent<NavigationTitleProps> = ({
  navigationElements,
  withLinkOnLastElement = false,
  padding = { left: Spacing.m },
  onShare,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const navigation = useNavigation();

  if (navigationElements.length === 0) {
    return null;
  }

  const renderElement = (element: WithOptionalField<NavigationElement, 'path'>, index: number) => {
    if (!element.path || (index === navigationElements.length - 1 && !withLinkOnLastElement)) {
      return (
        <Tooltip title={element.tooltip ?? element.name}>
          <div className={classes.nameContainer}>
            <Typo maxLine={1} color={theme.color.text.secondary}>
              {element.name}
            </Typo>
          </div>
        </Tooltip>
      );
    } else {
      const { path, hash } = element;
      const locationElement = navigation.getNavLocationObject(element.key);
      const target = navigation.createNavigationPayload(element.key, { pathname: path, hash, navigationFilters: locationElement?.navigationFilters });
      return (
        <Tooltip title={element.tooltip ?? element.name}>
          <div className={classes.linkContainer}>
            <Link
              title={element.name}
              to={target.to}
              state={target.state}
              maxLine={1}
            />
          </div>
        </Tooltip>
      );
    }
  };

  const getElementIcon = (element: Pick<NavigationElement, 'icon' | 'element'>) => {
    if (element.icon) {
      return element.icon;
    } else {
      return elementIcons[element.element];
    }
  };

  const navigationPathToString = (elements: NavigationElements): string => elements.map((element, index) => {
    if (index === 0) {
      return `${element.name}`;
    }

    return `${element.isEmbedded ? '- ' : '> '} ${element.name}`;
  }).join(' ');

  const displayElements = (elements: NavigationElements, lastIndexToDisplay: number) => {
    let isHidden = false;
    return elements && elements.map((element, index) => {
      if (index === 0 || index >= lastIndexToDisplay) {
        return (
          <Fragment key={element.key}>
            {index !== 0 && (
              <div className={element.isEmbedded ? classes.dashContainer : classes.chevronContainer}>
                <Icon
                  name={element.isEmbedded ? IconName.remove : IconName.keyboard_arrow_right}
                  colorVariant={element.isEmbedded ? IconColorVariant.primary : IconColorVariant.alternative}
                />
              </div>
            )}
            <div className={classes.itemsContainer}>
              {
                element.isEmbedded ? '' : (
                  <div className={classes.iconContainer}>
                    <Icon
                      name={getElementIcon(element)}
                      colorVariant={IconColorVariant.secondary}
                    />
                  </div>
                )
              }
              {renderElement(element, index)}
            </div>
          </Fragment>
        );
      }

      if (isHidden) {
        return false;
      }

      isHidden = true;
      return (
        <Fragment key={element.key}>
          <div className={element.isEmbedded ? classes.dashContainer : classes.chevronContainer}>
            <Icon
              name={element.isEmbedded ? IconName.remove : IconName.keyboard_arrow_right}
              colorVariant={element.isEmbedded ? IconColorVariant.primary : IconColorVariant.alternative}
            />
          </div>
          <Icon
            name={IconName.more_horiz}
            colorVariant={IconColorVariant.alternative}
            tooltip={navigationPathToString(elements)}
          />
        </Fragment>
      );
    });
  };
  const renderNavigation = (elements: NavigationElements) => {
    const { length } = elements;

    let navigationElementsLength = 0;
    elements.forEach((el) => {
      if (!el?.isEmbedded) {
        navigationElementsLength += 1;
      }
    });

    if (navigationElementsLength > 4) {
      if (elements[length - 1].isEmbedded) {
        return displayElements(elements, length - 2);
      } else {
        return displayElements(elements, length - 1);
      }
    } else {
      return displayElements(elements, 0);
    }
  };

  return (
    <div className={classes.root} style={buildPadding(padding)}>
      <SpacingLine>
        {renderNavigation(navigationElements)}
        {navigationElements.length > 1 && navigationElements[navigationElements.length - 2].key && (
          <IconOnlyButton
            tooltip={i18n`Close`}
            onClick={() => {
              const { key } = navigationElements[navigationElements.length - 2];
              const navToKey = navigation.getGetBackLocationHistory(key);
              // Typescript guarantee that the element before the last item always have a path when more than one item, cast is safe
              const goBackToElement = (navigationElements.find(({ key: navKey }) => navToKey.toLowerCase() === navKey)
                ?? navigationElements[navigationElements.length - 2]) as NavigationElement;
              navigation.goBackTo(goBackToElement.key, goBackToElement.path);
            }}
            iconName={IconName.close}
            variant={IconOnlyButtonVariants.secondary}
          />
        )}
        {onShare && (
          <IconOnlyButton tooltip={i18n`Copy link`} iconName={IconName.link} onClick={onShare} variant={IconOnlyButtonVariants.tertiary} />
        )}
      </SpacingLine>
    </div>
  );
};

export default NavigationTitle;
