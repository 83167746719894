import classnames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import { editableSelectorsClasses } from '../../components/molecules/EditableWithDropdown';
import base from '../../theme/base';
import makeStyles from '../../utils/makeStyles';

const useStyles = makeStyles((theme) => ({
  tooltipContainer: {
    position: 'relative',
    background: theme.color.background.neutral.default,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    [`& .${editableSelectorsClasses.focusedVisible}`]: {
      visibility: 'visible',
    },
  },
  tooltipContainerBorder: ({
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.primary,
    borderRadius: base.borderRadius.medium,
  }),
  tooltipContainerBorderReadOnly: ({
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.default,
    borderRadius: base.borderRadius.medium,
  }),
  tooltipContainerWithShadow: {
    boxShadow: base.shadowElevation.low,
  },
}), 'tooltipContainer');

interface TooltipContainerProps {
  editMode?: boolean,
  withShadow?: boolean,
  children: ReactNode,
}

const TooltipContainer: FunctionComponent<TooltipContainerProps> = ({ editMode = false, withShadow = false, children }) => {
  const classes = useStyles();

  return (
    <div
      className={classnames({
        [classes.tooltipContainer]: true,
        [classes.tooltipContainerBorder]: editMode,
        [classes.tooltipContainerBorderReadOnly]: !editMode,
        [classes.tooltipContainerWithShadow]: withShadow,
      })}
    >
      {children}
    </div>
  );
};

export default TooltipContainer;
