import type { ReactElement } from 'react';
import { useState } from 'react';
import type { Datum } from 'victory';
import { VictoryArea, VictoryAxis, VictoryChart, VictoryContainer, VictoryGroup, VictoryLine, VictoryScatter, VictoryStack, VictoryTooltip } from 'victory';
import { isNumber, joinObjects, periodicities, PeriodicityType } from 'yooi-utils';
import { hexColorWithAlpha } from '../../../theme/colorUtils';
import { getFontStyle } from '../../../utils/fontUtils';
import i18n from '../../../utils/i18n';
import { remToPx, SCROLLBAR_WIDTH_IN_REM } from '../../../utils/sizeUtils';
import useTheme from '../../../utils/useTheme';
import ChartLegend, { CHART_LEGEND_LINE_HEIGHT } from '../ChartLegend';
import type { ChartTooltipData, ColorStepsValue, Labels, SerieInfo, SerieInput, StackedRowInfo } from '../ChartTypes';
import { chartAxisStyle } from '../internal/chartStylesUtils';
import ChartTooltipForVictory from '../internal/ChartTooltipForVictory';
import DateIndicator from '../internal/DateIndicator';
import DependentAxis from '../internal/DependentAxis';
import FlyoutWrapper from '../internal/FlyoutWrapper';
import NoLabel from '../NoLabel';

interface Value {
  x: Date,
  y: number,
  time: number,
  value: number,
}

interface LineChartProps<ValueType extends Value> {
  series: SerieInput<ValueType>[],
  minValue: ColorStepsValue,
  maxValue: ColorStepsValue,
  steps: ColorStepsValue[],
  xDomain: [number, number],
  yDomain: [number, number],
  height: number,
  width: number,
  seriesStacked: boolean,
  renderTooltip: (tooltipData: ChartTooltipData<ValueType>) => ReactElement,
  labels: Labels[],
}

const generateMinMaxLines = (
  minValue: ColorStepsValue,
  maxValue: ColorStepsValue,
  steps: ColorStepsValue[]
): { value: number, color: string | undefined }[] | [] => {
  const minMaxLines = [];

  if (isNumber(minValue.value)) {
    minMaxLines.push({ value: minValue.value, color: minValue.color });
  }
  if (steps && steps?.length > 0) {
    steps.forEach((step) => {
      if (isNumber(step.value)) {
        minMaxLines.push({ value: step.value, color: step.color });
      }
    });
  }
  if (isNumber(maxValue.value)) {
    minMaxLines.push({ value: maxValue.value, color: maxValue.color });
  }
  return minMaxLines;
};

const LineChart = <ValueType extends Value>({
  series,
  seriesStacked,
  xDomain,
  yDomain,
  height,
  width,
  renderTooltip,
  minValue,
  maxValue,
  steps,
  labels: generatedLabels,
}: LineChartProps<ValueType>): ReactElement | null => {
  const theme = useTheme();

  const chartLeftPadding = remToPx(5);
  const charRightPadding = remToPx(5 - SCROLLBAR_WIDTH_IN_REM);
  const minMaxLines = generateMinMaxLines(minValue, maxValue, steps);

  const startOfDayTime = periodicities[PeriodicityType.day].getStartOfPeriod(new Date()).getTime();

  const bottomPaddingInRem = 5.5 - SCROLLBAR_WIDTH_IN_REM;
  const firstLegendLineHeightInRem = generatedLabels.length > 0 ? CHART_LEGEND_LINE_HEIGHT : 0;
  const svgHeight = height - remToPx(firstLegendLineHeightInRem);

  const [displayKeyList, setDisplayKeyList] = useState<string[]>([]);

  const getOpacity = (key: string) => (displayKeyList.length === 0 || displayKeyList.includes(key) ? 1 : 0.2);

  const CustomDateIndicator = ({ scale }: { scale: { x: () => number } }): ReactElement => (
    <DateIndicator
      label={i18n`Today`}
      labelOffset={5}
      size={svgHeight - remToPx(bottomPaddingInRem)}
      date={new Date(startOfDayTime)}
      scale={scale.x}
    />
  );

  const renderLine = (seriesInfo: SerieInfo, rowKey: string, stackedRowInfo: StackedRowInfo, values: ValueType[]) => (
    <VictoryGroup
      data={values}
      key={rowKey}
    >
      {seriesInfo.withArea && (
        <VictoryArea
          style={{ data: { fill: hexColorWithAlpha(stackedRowInfo.color ?? seriesInfo.color, 0.1) } }}
          interpolation={seriesInfo.interpolation}
        />
      )}
      <VictoryLine
        interpolation={seriesInfo.interpolation}
        name={`line-${rowKey}`}
        style={{
          data: {
            stroke: stackedRowInfo.color ?? seriesInfo.color,
            strokeWidth: 3,
            opacity: getOpacity(stackedRowInfo.key),
          },
        }}
      />
      <VictoryScatter
        size={4}
        style={{
          data: {
            fill: stackedRowInfo.color ?? seriesInfo.color,
            opacity: getOpacity(stackedRowInfo.key),
          },
        }}
      />
    </VictoryGroup>
  );

  const renderTooltipScatter = (
    seriesInfo: SerieInfo,
    _: string,
    stackedRowInfo: StackedRowInfo,
    values: ValueType[],
    getTotalByTime: (time: number) => number
  ) => (
    <VictoryScatter // We draw scatter at the end to have the hover and the tooltip on top of other elements (eg: hover areas)
      labels={({ datum }: { datum: Datum }) => datum.y}
      key={`scatter_${stackedRowInfo.key}`}
      data={values}
      size={4}
      style={{
        data: {
          fill: theme.color.transparent,
        },
      }}
      labelComponent={(
        <VictoryTooltip
          pointerLength={0}
          constrainToVisibleArea
          flyoutComponent={(
            <FlyoutWrapper>
              {({ x, y, datum }) => (
                <ChartTooltipForVictory
                  chartWidth={width}
                  chartHeight={height}
                  renderTooltip={() => renderTooltip({
                    seriesFieldId: seriesInfo.fieldId,
                    seriesLabel: seriesInfo.label,
                    datum: datum as ValueType,
                    dimensionsMapping: stackedRowInfo.dimensionsMapping,
                    totalValue: getTotalByTime((datum as ValueType).time),
                  })}
                  x={x}
                  y={y}
                />
              )}
            </FlyoutWrapper>
          )}
          // We explicitly wants to avoid the label component
          labelComponent={<NoLabel />}
          style={joinObjects(
            getFontStyle(theme.font.small),
            { lineHeight: undefined }
          )}
        />
      )}
    />
  );

  const renderEachLine = (renderFunc: (seriesInfo: SerieInfo, rowKey: string,
    stackedRowInfo: StackedRowInfo, values: ValueType[], getTotalByTime: (time: number) => number) => JSX.Element) => {
    if (seriesStacked) {
      return series[0]?.rows.map(({ key: stackKey }, rowIndex) => (
        <VictoryStack key={stackKey}>
          {series.flatMap(({ info: seriesInfo, rows }) => rows[rowIndex].stackedRows
            .filter(({ values }) => values.length > 0)
            .map(({ values, info: stackedRowInfo }) => (
              renderFunc(
                seriesInfo,
                rows[rowIndex].info.key,
                stackedRowInfo,
                values,
                (time) => series.reduce((acc, { rows: reducedRow }) => acc + reducedRow[rowIndex].stackedRows
                  .reduce((stackedAcc, sRow) => stackedAcc + (sRow.values.find(({ time: stackedTime }) => stackedTime === time)?.value ?? 0), 0), 0)
              )
            )))}
        </VictoryStack>
      ));
    } else {
      return (series.flatMap(({ info: seriesInfo, rows }) => rows.map(({ stackedRows, info: rowInfo }) => (
        <VictoryStack key={rowInfo.key}>
          {stackedRows.filter(({ values }) => values.length > 0).map(({ values, info: stackedRowInfo }) => (
            renderFunc(seriesInfo, rowInfo.key, stackedRowInfo, values, (time) => stackedRows
              .reduce((acc, stackedRow) => acc + (stackedRow.values.find((value) => value.time === time)?.value ?? 0), 0))
          ))}
        </VictoryStack>
      ))));
    }
  };

  const labels = generatedLabels.map(({ label, color, key }) => ({
    name: label,
    symbol: { fill: hexColorWithAlpha(color ?? theme.color.background.neutral.default, getOpacity(key)) },
    displayStroke: !color,
    key,
  }));

  return (
    <>
      <VictoryChart
        // using height as key to prevent flickering when resizing (Victory renders bars AFTER svg)
        key={svgHeight}
        height={svgHeight}
        width={width}
        scale={{ x: 'time', y: 'linear' }}
        domain={{ x: xDomain, y: [yDomain[0] > 0 ? 0 : yDomain[0], yDomain[1] < 0 ? 0 : yDomain[1]] }}
        padding={{ top: remToPx(2.5), bottom: remToPx(bottomPaddingInRem), left: chartLeftPadding, right: charRightPadding }}
        containerComponent={(
          <VictoryContainer
            preserveAspectRatio="xMinYMin meet"
            responsive={false}
          />
        )}
      >
        <VictoryAxis style={chartAxisStyle(theme)} />
        <DependentAxis />
        {/* scale is directly injected by victory */}
        {minMaxLines.slice(0).reverse().map(({ color, value: lineValue }, index) => {
          const key = index;
          return (
            <VictoryLine
              key={key}
              style={{
                data: { stroke: color },
                parent: { border: '1px solid #ccc' },
              }}
              y={() => lineValue}
            />
          );
        })}
        {minMaxLines.filter((_, index) => index !== minMaxLines.length - 1).map(({ color, value: lineValue }, index) => {
          const key = index;
          return (
            <VictoryArea
              key={key}
              style={{
                data: { fill: hexColorWithAlpha(color, 0.2) },
              }}
              y={() => lineValue}
              y0={() => minMaxLines[index + 1]?.value}
            />
          );
        })}
        {
          startOfDayTime > xDomain[0] && startOfDayTime < xDomain[1]
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore CustomDateIndicator scale is injected by Victory
          && (<CustomDateIndicator />)
        }
        {renderEachLine(renderLine)}
        {renderEachLine(renderTooltipScatter)}
      </VictoryChart>
      {labels.length > 0 && (
        <ChartLegend
          labels={labels}
          onLabelClick={(selectedKey) => {
            setDisplayKeyList((current) => {
              if (current.includes(selectedKey)) {
                return current.filter((key) => key !== selectedKey);
              } else {
                return current.length === series.flatMap(({ rows }) => rows.flatMap(({ stackedRows }) => stackedRows)).length - 1 ? [] : [...current, selectedKey];
              }
            });
          }}
        />
      )}
    </>
  );
};

export default LineChart;
