// This file was generated by lezer-generator. You probably shouldn't edit it.
import { LRParser } from '@lezer/lr';

export const parser = LRParser.deserialize({
  version: 14,
  states: "&`OVQPOOOOQO'#Cc'#CcQqQPOOOVQPO'#CaOVQPO'#CiO!lQPO'#CjO!sQPO'#CkOOQO'#Cq'#CqOOQO'#Cb'#CbOOQO'#Cd'#CdOOQO'#Ce'#CeOOQO'#Cf'#CfOOQO'#Cg'#CgOOQO'#Ch'#ChOVQPO,58{OOQO,58{,58{OVQPO,58{OVQPO,58{OVQPO,58{OVQPO,58{O!zQPO,58{O$RQPO,59TO$YQPO,59UOOQO,59U,59UO$dQPO,59VOOQO,59V,59VOOQO1G.g1G.gO%oQPO1G.gO%yQPO1G.gO'XQPO1G.gO'`QPO1G.gOOQO1G.o1G.oOVQPO'#CmO'gQPO1G.pOOQO1G.p1G.pO'oQPO1G.qOOQO1G.q1G.qO'wQPO,59XOOQO-E6k-E6kOOQO7+$[7+$[OOQO7+$]7+$]",
  stateData: '(X~OdOS~OQVORVOSVO`UOgPOhPOsSOuTO~OfWOgZOhZOiXOjYOkYOl[Om]On]Oo]Op]Oq]Or]O~OwgO~PVOtiO~PVOfWObTagTahTaiTajTakTalTamTanTaoTapTaqTarTatTavTawTa~OtoO~PqOvpOwrO~PqOttOvpO~PqOfWOiXObTigTihTilTimTinTioTipTiqTirTitTivTiwTi~OjTikTi~P$nOjYOkYO~P$nOfWOgZOhZOiXOjYOkYObTimTinTioTipTiqTirTitTivTiwTi~OlTi~P&TOl[O~P&TOvpOwwO~OtxOvpO~Ovaawaataa~PqO`S~',
  goto: '$ZfPPPPPgt!Q!_!j!t!}#VgggP#^PPP#hgVORSTU^`abcpe^QdefhklmnugRORSTU^`abcpc_Qefhklmnua`Qefhlmnu_aQefhmnu]bQefhnuZcQefhuQqfQshTvqsQQOQdRQeSQfTQhUQj^Qk`QlaQmbQncRup',
  nodeNames: '⚠ Formula Text Number Input Operation ExponentialOperator PrefixOperator PostfixOperator MultiplicativeOperator AdditiveOperator ConcatOperator ComparisonOperator ExpressionGroup Array FunctionCall FunctionCallStart',
  maxTerm: 39,
  skippedNodes: [0],
  repeatNodeCount: 1,
  tokenData: ")d~RoX^#Spq#Srs#wuv$ivw$nxy$syz$xz{$}{|%S|}%X}!O%^!O!P%c!P!Q&c!Q![&h!^!_&y!_!`'`!`!a'e!c!}'r#Q#R)T#R#S(r#T#o'r#o#p)Y#q#r)_#y#z#S$f$g#S#BY#BZ#S$IS$I_#S$I|$JO#S$JT$JU#S$KV$KW#S&FU&FV#S~#XYd~X^#Spq#S#y#z#S$f$g#S#BY#BZ#S$IS$I_#S$I|$JO#S$JT$JU#S$KV$KW#S&FU&FV#S~#zTOr#wrs$Zs;'S#w;'S;=`$c<%lO#w~$`PQ~rs#w~$fP;=`<%l#w~$nOi~~$sOl~~$xOs~~$}Ot~~%SOj~~%XOg~~%^Ov~~%cOh~~%fP!Q![%i~%nRR~!Q![%i!g!h%w#X#Y%w~%zR{|&T}!O&T!Q![&Z~&WP!Q![&Z~&`PR~!Q![&Z~&hOk~~&mSR~!O!P%c!Q![&h!g!h%w#X#Y%w~'OQp~!_!`'U!`!a'Z~'ZOq~~'`Or~~'eOm~~'jPn~!_!`'m~'rOo~~'wUS~xy(Z!O!P(`!Q!['r!c!}'r#R#S(r#T#o'r~(`O`~~(cTxy(Z!O!P(`!Q![(`!c!}(`#T#o(`~(wSS~!Q![(r!c!}(r#R#S(r#T#o(r~)YOf~~)_Ou~~)dOw~",
  tokenizers: [0],
  topRules: { Formula: [0, 1] },
  tokenPrec: 328,
});
