import classnames from 'classnames';
import type { ReactElement } from 'react';
import base from '../../theme/base';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';

const useStyles = makeStyles((theme) => ({
  line: {
    height: '100%',
    width: '100%',
    background: theme.color.background.neutral.default,
    padding: spacingRem.s,
    borderRadius: base.borderRadius.medium,
  },
  lineActive: {
    background: theme.color.background.neutral.subtle,
  },
  group: {
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    paddingBottom: spacingRem.xxs,
    paddingTop: spacingRem.xxs,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
  },
}), 'masterDetailListLine');

interface MasterDetailListLineProps {
  isGroup?: boolean,
  color?: string,
  active?: boolean,
  children: ReactElement | null,
}

const MasterDetailListLine = ({ isGroup = false, color, active = false, children }: MasterDetailListLineProps): ReactElement | null => {
  const classes = useStyles();
  return (
    <div
      className={classnames({
        [classes.line]: true,
        [classes.lineActive]: active,
        [classes.group]: isGroup,
      })}
      style={color ? { backgroundColor: color, borderColor: color } : undefined}
    >
      {children}
    </div>
  );
};

export default MasterDetailListLine;
