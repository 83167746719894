import { newError } from 'yooi-utils';
import { asLocal } from '../../common/fields/commonPropertyType';
import type { GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import type { CardColorMode, PathStep } from '../../conceptModule';
import { registerField } from '../module';
import type { GraphChartField } from './types';

export enum CardDisplayMode {
  Opened = 'Opened',
  Closed = 'Closed',
}

export type GraphChartSubStepDisplay = {
  type: 'chip',
} | {
  type: 'card',
  defaultDisplay?: CardDisplayMode,
  color?: { path: PathStep[], as: CardColorMode },
  icon?: { path: PathStep[] },
  boolean?: { path: PathStep[] },
  header?: { id: string, path: PathStep[], displayOptions?: Record<string, unknown> }[],
  body?: { id: string, label: string | undefined, path: PathStep[], displayOptions?: Record<string, unknown> }[],
};

export interface Block {
  id: string,
  index: number,
  label?: string,
  path?: PathStep[],
  arrowColor?: { type: 'path', path: PathStep[] } | { type: 'value', value?: string },
  arrowLabel?: { type: 'path', path: PathStep[] } | { type: 'value', value?: string },
  groupByFieldPath?: PathStep[],
  collapsedGroups?: CardDisplayMode,
  defaultSort?: string,
  display?: GraphChartSubStepDisplay,
}

export interface Column {
  id: string,
  label?: string,
  blocks: Block[],
  itemPerPage?: number,
}

export interface GraphChartFieldConfiguration {
  graphStyle?: 'center' | 'top',
  columns?: Column[],
}

type GraphChartFieldHandler = GetDslFieldHandler<GraphChartField, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined>;
export const graphChartFieldHandler: GraphChartFieldHandler = registerField({
  model: {
    label: 'GraphChartField',
    title: 'Graph chart',
    properties: [
      { label: 'Configuration', as: asLocal('GraphChartFieldConfiguration') },
    ],
  },
  handler: (_, __, { resolveConfiguration }) => ({
    describe: () => ({ hasData: false }),
    restApi: {
      returnTypeSchema: {},
      formatValue: () => undefined,
    },
    getStoreValue: () => undefined,
    getValueWithoutFormula: () => undefined,
    getValueResolution: () => ({ value: undefined, getDisplayValue: () => undefined, isComputed: false, isTimeseries: false }),
    updateValue: () => {
      throw newError('updateValue not supported');
    },
    resolvePathStepConfiguration: () => ({ hasData: false }),
    isEmpty: () => !resolveConfiguration().configuration,
    isSaneValue: () => ({ isValid: true }),
  }),
});
