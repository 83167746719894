import { ValidationStatus } from 'yooi-store';
import { CommonAsType } from '../common/fields/commonPropertyType';
import { registerModel } from './module';

const { type } = registerModel;

type({
  label: 'Platform',
  accessControlList: {
    READ: () => () => ({ rule: 'platform.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: () => () => ({ rule: 'platform.write.deny', status: ValidationStatus.REJECTED }),
    DELETE: () => () => ({ rule: 'platform.delete.deny', status: ValidationStatus.REJECTED }),
  },
})
  .property({
    label: 'Platform_MigrationRevision',
    as: CommonAsType.number,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'Platform_InitializationRevision',
    as: CommonAsType.number,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .instance({ label: 'PlatformInstance' });
