import type { ComponentProps, FunctionComponent } from 'react';
import { useState } from 'react';
import type { DimensionsMapping, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import {
  BLOCK_PARAMETER_CURRENT,
  canCopyConcept,
  dimensionsMappingToParametersMapping,
  FILTER_PARAMETER_OPTION,
  getConceptUrl,
  getInstanceLabel,
  getPathLastFieldInformation,
  GROUP_BY_PARAMETER,
  isEmbeddedAsIntegrationOnly,
  ParsedDimensionType,
  parseDimensionMapping,
} from 'yooi-modules/modules/conceptModule';
import { ConceptRole } from 'yooi-modules/modules/conceptModule/ids';
import type { DimensionDisplayOption, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { UnsetDashboardParameterOption } from 'yooi-modules/modules/dashboardModule/ids';
import { DataAsset } from 'yooi-modules/modules/dataAssetModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { StoreObject } from 'yooi-store';
import { compareString, comparing, filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { ButtonVariant } from '../../../../components/atoms/Button';
import { IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import type { InlineCreationInline, InlineCreationTransactional } from '../../../../components/molecules/inlineCreationTypes';
import InlineLink from '../../../../components/molecules/InlineLink';
import Loading from '../../../../components/molecules/Loading';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import { TableSortDirection } from '../../../../components/molecules/Table';
import TableCell from '../../../../components/molecules/TableCell';
import TableInnerCellContainer from '../../../../components/molecules/TableInnerCellContainer';
import TableLine from '../../../../components/molecules/TableLine';
import type { ColumnDefinition, GroupEntry, ItemEntry } from '../../../../components/templates/DataTable';
import DataTable from '../../../../components/templates/DataTable';
import type { Pagination } from '../../../../components/templates/PageSelector';
import useAcl from '../../../../store/useAcl';
import useStore from '../../../../store/useStore';
import { getSpacing, Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import { formatOrUndef } from '../../../../utils/stringUtils';
import useNavigation from '../../../../utils/useNavigation';
import type { NewLineFocusRefContent } from '../../../../utils/useNewLineFocus';
import useNewLineFocus, { useFocusNewLineNotify } from '../../../../utils/useNewLineFocus';
import { SessionStorageKeys, useSessionStorageState } from '../../../../utils/useSessionStorage';
import withAsyncTask from '../../../../utils/withAsyncTask';
import { duplicateConcept } from '../../conceptUtils';
import { getFieldHandler } from '../../fields/FieldLibrary';
import type { FieldComparatorHandler } from '../../fields/FieldLibraryTypes';
import { useViewLoadingStateUpdater } from '../../fields/viewsField/useViewLoadingState';
import { getFieldFilterFunction } from '../../fieldUtils';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { defaultOptionComparator, getChipOptions, getSearchChipOptions } from '../../modelTypeUtils';
import type { NavigationFilter } from '../../navigationUtils';
import type { QueryTableConfiguration } from '../../sessionStorageTypes';
import { share } from '../../shareUtils';
import useConceptDeleteModal from '../../useConceptDeleteModal';
import useFilterAndSort from '../../useFilterAndSort';
import { canDuplicateInstance, getViewNavigationFilters } from '../common/viewUtils';
import type { TableViewResolution, TableViewResolutionLine } from './tableViewResolution';
import { isFieldLineResolution, isInstanceLineResolution } from './tableViewResolution';

interface TableViewProps {
  viewDimensions: ViewDimension[],
  viewResolution: TableViewResolution,
  parametersMapping: ParametersMapping,
  filterKey: string,
  itemPerPage: number | undefined,
  groupByDefault: { groupById: string | undefined },
  dimensionsDisplay: DimensionDisplayOption[],
  readOnly?: boolean,
}

const TableView: FunctionComponent<TableViewProps> = withAsyncTask(({
  viewDimensions,
  viewResolution,
  parametersMapping,
  filterKey,
  itemPerPage,
  groupByDefault,
  dimensionsDisplay,
  readOnly = false,
  executeAsyncTask,
}) => {
  const store = useStore();
  const aclHandler = useAcl();
  const { linkOptions, createOptions, inlineCreationOptions } = viewResolution;

  const navigation = useNavigation<NavigationFilter>();

  const focusNewLineNotify = useFocusNewLineNotify();
  const [newLineFocus] = useNewLineFocus();

  const [filtersConfiguration] = useSessionStorageState<FilterConfiguration | undefined>(filterKey, undefined);

  const [inlineCreationLine, setInlineCreationLine] = useState(false);
  const [doDelete, deleteModal] = useConceptDeleteModal(false);
  const [tableConfig] = useSessionStorageState<QueryTableConfiguration | undefined>(`${SessionStorageKeys.tableConfig}_${filterKey}`, groupByDefault);
  const groupByField = viewResolution.groupByFields.find(({ id }) => id === tableConfig?.groupById);
  const updatedParametersMapping: ParametersMapping = groupByField ? { [GROUP_BY_PARAMETER]: { type: 'single' as const, id: groupByField.fieldId } } : parametersMapping;

  // We want to reorder table when dimension path and dimension axis are changed
  const stringifyDimensions = viewDimensions.reduce((
    acc,
    dimension
  ) => `${acc}|${JSON.stringify(dimension.path)}-${dimensionsDisplay ? dimensionsDisplay.find(({ id }) => id === dimension.id)?.axis : ''}`, '');

  const monoDimPathlastFieldId = !viewResolution.isMultiDim && viewDimensions.length === 1 ? getPathLastFieldInformation(viewDimensions[0].path)?.fieldId : undefined;
  const field = monoDimPathlastFieldId ? store.getObject(monoDimPathlastFieldId) : undefined;
  const fieldFilter = field ? getFieldFilterFunction(field, store) : undefined;
  const navigationFilters = getViewNavigationFilters(store, viewDimensions, filtersConfiguration, updatedParametersMapping);
  const computeOptions = linkOptions ? () => linkOptions?.computeLinkableInstanceIds().filter((option) => !fieldFilter
    || fieldFilter({
      [BLOCK_PARAMETER_CURRENT]: { type: 'single' as const, id: monoDimPathlastFieldId },
      [FILTER_PARAMETER_OPTION]: { type: 'single' as const, id: option },
    }))
    .map((id) => getChipOptions(store, id, navigationFilters)).filter(filterNullOrUndefined)
    .sort(defaultOptionComparator) : undefined;

  const {
    generateList,
    generateGroupedList,
    generatePageList,
    generateGroupedPageList,
    forceFollowingIds,
    doSort,
    sortCriteria,
    forceShowId: filterAndSortForceShowId,
  } = useFilterAndSort(
    filterKey,
    viewResolution.lines,
    (item) => viewResolution.filterFunction(dimensionsMappingToParametersMapping(item.dimensionsMapping)),
    {
      getComparatorHandler: (columnKey, direction) => {
        let fieldComparatorHandler: FieldComparatorHandler<unknown> | undefined;
        let lineToDimensionsMapping: (line: TableViewResolutionLine) => DimensionsMapping;
        if (columnKey === tableConfig?.groupById && groupByField) {
          fieldComparatorHandler = getFieldHandler(store, groupByField.fieldId)?.getComparatorHandler?.(direction);
          lineToDimensionsMapping = groupByField.getDimensionsMapping;
        } else {
          const relatedColumn = viewResolution.columns.find(({ key }) => columnKey === key);
          if (relatedColumn?.isInstance) {
            return {
              comparator: comparing(compareString, direction === TableSortDirection.desc),
              extractValue: (line) => {
                const lineResolution = relatedColumn?.getLineResolution(line);
                if (isInstanceLineResolution(lineResolution)) {
                  const instance = lineResolution.instanceId ? store.getObjectOrNull(lineResolution.instanceId) : undefined;
                  return instance ? getInstanceLabel(store, instance) : undefined;
                }
                return null;
              },
            };
          }
          fieldComparatorHandler = relatedColumn?.fieldId ? getFieldHandler(store, relatedColumn?.fieldId)?.getComparatorHandler?.(direction) : undefined;
          lineToDimensionsMapping = (line: TableViewResolutionLine): DimensionsMapping => {
            const lineResolution = relatedColumn?.getLineResolution(line);
            return isFieldLineResolution(lineResolution) ? lineResolution.dimensionsMapping ?? {} : {};
          };
        }

        if (fieldComparatorHandler?.comparator && lineToDimensionsMapping) {
          return {
            comparator: fieldComparatorHandler.comparator,
            extractValue: (line) => fieldComparatorHandler?.extractValue?.(lineToDimensionsMapping(line)),
          };
        } else {
          return undefined;
        }
      },
      initial: viewResolution.defaultSort,
    },
    groupByField ? {
      key: groupByField.id,
      getGroupKey: groupByField.getGroupKey,
      getGroupLabel: (key) => formatOrUndef(key !== undefined ? groupByField.getGroupLabel(key) : undefined),
      getGroupColor: groupByField.getGroupColor,
    } : undefined,
    [filterKey, filtersConfiguration, groupByField?.id, groupByField?.fieldId, stringifyDimensions, JSON.stringify(parametersMapping)],
    executeAsyncTask
  );

  const forceShowId = (id: string) => {
    filterAndSortForceShowId(id);
  };

  let data: { list: (ItemEntry<TableViewResolutionLine> | GroupEntry)[], pagination?: Pagination | undefined, status: 'loading' | 'loaded' };
  if (itemPerPage === undefined && groupByField !== undefined) {
    data = generateGroupedList();
  } else if (itemPerPage === undefined) {
    data = generateList();
  } else if (groupByField !== undefined) {
    data = generateGroupedPageList(itemPerPage, true);
  } else {
    data = generatePageList(itemPerPage);
  }

  useViewLoadingStateUpdater(data.status);

  const columnDefinition: ColumnDefinition<TableViewResolutionLine>[] = [];

  const getNavigationPayload: ComponentProps<typeof DataTable<TableViewResolutionLine>>['getNavigationPayload'] = (line) => {
    const parsedDimension = parseDimensionMapping(line.dimensionsMapping);
    if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
      return navigation.createNavigationPayload(
        line.id,
        {
          pathname: getConceptUrl(store, parsedDimension.objectId),
          navigationFilters,
        }
      );
    } else {
      return undefined;
    }
  };

  if (viewResolution.openButtonColumn) {
    columnDefinition.push({
      propertyId: 'open',
      width: '3.8rem',
      cellRender: (line) => {
        const navigationPayload = getNavigationPayload(line);
        return (
          <TableInnerCellContainer padding={{ paddingLeft: getSpacing(Spacing.text), paddingRight: getSpacing(Spacing.text), paddingTop: getSpacing(Spacing.text) }}>
            {
              navigationPayload === undefined
                ? null
                : (
                  <InlineLink to={navigationPayload?.to} state={navigationPayload?.state} noStyle>
                    <IconOnlyButton
                      variant={IconOnlyButtonVariants.tertiary}
                      iconName={IconName.output}
                      tooltip={i18n`Open as page`}
                    />
                  </InlineLink>
                )
            }
          </TableInnerCellContainer>
        );
      },
    });
  }

  const openColumnKey = viewResolution.isMultiDim ? undefined : viewResolution.columns.find(({ isNavigable }) => isNavigable)?.key;
  columnDefinition.push(...viewResolution.columns.map((column): ColumnDefinition<TableViewResolutionLine> => {
    let width: number | string | undefined;
    if (column.width === undefined && column.conceptDefinitionId && column.fieldId && store.getObjectOrNull(column.fieldId)) {
      width = getFieldHandler(store, column.fieldId)?.estimatedColumnWidth?.(column.conceptDefinitionId);
    } else if (column.width !== undefined && column.width.type === 'percent') {
      width = column.width.value;
    } else if (column.width !== undefined && column.width.type === 'rem') {
      width = `${column.width.value}rem`;
    }

    return {
      focusable: column.focusable,
      propertyId: column.key,
      name: column.label,
      key: column.key,
      sortable: column.isInstance || Boolean(column.fieldId && getFieldHandler(store, column.fieldId)?.getComparatorHandler),
      width,
      openButton: openColumnKey === column.key ? (line) => {
        const parsedLineDimension = parseDimensionMapping(line.dimensionsMapping);
        if (parsedLineDimension.type !== ParsedDimensionType.MonoDimensional) {
          return false;
        }
        const lineObjectId = parsedLineDimension.objectId;

        const columnResolution = column.getLineResolution(line);
        if (isFieldLineResolution(columnResolution) && columnResolution.dimensionsMapping) {
          const parsedColumnDimension = parseDimensionMapping(columnResolution.dimensionsMapping);
          if (parsedColumnDimension.type === ParsedDimensionType.MonoDimensional) {
            return parsedColumnDimension.objectId === lineObjectId;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } : undefined,
      cellRender: (line, focusOnMount) => {
        const lineResolution = column.getLineResolution(line);
        if (isFieldLineResolution(lineResolution)) {
          const { fieldId: lineFieldId, dimensionsMapping: lineResolutionDimensionMapping } = lineResolution;
          if (lineResolutionDimensionMapping !== undefined) {
            const parsedDimension = parseDimensionMapping(lineResolutionDimensionMapping);
            return getFieldHandler(store, lineFieldId)?.renderField?.({
              readOnly: readOnly || (parsedDimension?.type === ParsedDimensionType.MonoDimensional && !aclHandler.canWriteObject(parsedDimension.objectId)),
              dimensionsMapping: lineResolutionDimensionMapping,
              focusOnMount,
            }) ?? null;
          }
        } else if (isInstanceLineResolution(lineResolution)) {
          return (
            <SearchAndSelect
              editOnMount={focusOnMount}
              selectedOption={lineResolution.instanceId
                ? getChipOptions(store, lineResolution.instanceId, navigationFilters)
                : undefined}
              readOnly
            />
          );
        }
        return null;
      },
    };
  }));

  let newItemOptions: undefined | { title: string, icon: IconName };
  if (computeOptions) {
    newItemOptions = { title: i18n`Add`, icon: IconName.add };
  } else if ((!fieldFilter && inlineCreationOptions) || createOptions) {
    newItemOptions = { title: i18n`Create`, icon: IconName.add };
  }

  const inlineCreationCellPositions = viewResolution.columns.findIndex((column) => column.focusable);
  const colSpanBeforeInlineCreation = inlineCreationCellPositions > 0 ? inlineCreationCellPositions : 0;
  const colSpanAfterInlineCreation = inlineCreationCellPositions >= 0 ? viewResolution.columns.length - inlineCreationCellPositions : viewResolution.columns.length;

  let onNewItem: undefined | (() => void);
  if (inlineCreationOptions || linkOptions) {
    onNewItem = () => setInlineCreationLine(true);
  } else if (createOptions) {
    onNewItem = () => {
      const newInstanceId = createOptions.onCreate();
      forceShowId(newInstanceId);
      focusNewLineNotify(`${filterKey}|${newInstanceId}`);
    };
  }

  const getNewLineFocus = (): NewLineFocusRefContent | undefined => {
    const newLineFocusId = newLineFocus?.current.id;
    if (!newLineFocusId) {
      return undefined;
    }
    const [newLineFilterId, newInstanceId] = newLineFocusId.split('|');
    if (filterKey === newLineFilterId) {
      return joinObjects(newLineFocus.current, { id: newInstanceId });
    }
    return undefined;
  };

  return (
    <>
      <DataTable<TableViewResolutionLine>
        list={data.list}
        pagination={data.pagination}
        sortCriteria={sortCriteria}
        doSort={doSort}
        newItemTitle={newItemOptions?.title}
        newItemIcon={newItemOptions?.icon}
        newItemButtonVariant={ButtonVariant.tertiary}
        onNewItem={onNewItem}
        newLineFocus={getNewLineFocus()}
        inlineCreation={{
          render: inlineCreationLine && newItemOptions ? (
            <TableLine>
              {colSpanBeforeInlineCreation > 0 && <TableCell colSpan={colSpanBeforeInlineCreation} />}
              <TableCell>
                <SearchAndSelect
                  editOnMount
                  computeOptions={computeOptions}
                  getInlineCreation={!fieldFilter && inlineCreationOptions ? (): InlineCreationInline | InlineCreationTransactional => {
                    const inlineCreation = inlineCreationOptions.buildInlineCreation();
                    if (inlineCreation.type === 'inline') {
                      return {
                        type: 'inline',
                        onCreate: (title) => {
                          setInlineCreationLine(false);
                          const newInstanceId = inlineCreation.onCreate(title);
                          forceShowId(newInstanceId);
                          focusNewLineNotify(newInstanceId);
                          return newInstanceId;
                        },
                      };
                    } else {
                      return {
                        type: 'transactional',
                        creationOptions: inlineCreation.creationOptions,
                        onCreate: (creationOptionState) => {
                          setInlineCreationLine(false);
                          const newInstanceId = inlineCreation.onCreate(creationOptionState);
                          forceShowId(newInstanceId);
                          focusNewLineNotify(newInstanceId);
                          return newInstanceId;
                        },
                        getChipLabel: inlineCreation.getChipLabel,
                        getInitialState: inlineCreation.getInitialState,
                      };
                    }
                  } : undefined}
                  searchOptions={linkOptions ? getSearchChipOptions(store, linkOptions.targetConceptDefinitionId) : undefined}
                  onSelect={linkOptions ? (option) => {
                    if (option) {
                      linkOptions.onLink(option.id);
                      setInlineCreationLine(false);
                    }
                  } : undefined}
                  onClickAway={() => setInlineCreationLine(false)}
                  onEscape={() => setInlineCreationLine(false)}
                />
              </TableCell>
              {colSpanAfterInlineCreation > 0 && <TableCell colSpan={colSpanAfterInlineCreation} />}
            </TableLine>
          ) : null,
        }}
        linesActions={(line) => {
          const isMonoDim = parseDimensionMapping(line.dimensionsMapping).type === ParsedDimensionType.MonoDimensional;
          return Object.entries(line.dimensionsMapping)
            .map(([dimensionId, id]): [string, StoreObject<string>] | undefined => {
              if (id) {
                const instance = store.getObjectOrNull(id);
                return instance ? [dimensionId, instance] : undefined;
              }
              return undefined;
            })
            .filter(filterNullOrUndefined)
            .filter(([, concept]) => concept.id !== UnsetDashboardParameterOption)
            .flatMap(([dimensionId, concept]) => {
              const hideDuplicate = readOnly
                || !isMonoDim
                || !canDuplicateInstance(store, viewDimensions.find((dim) => dim.id === dimensionId))
                || !canCopyConcept(store, aclHandler, concept.id);

              return [
                {
                  key: 'open',
                  icon: IconName.output,
                  name: i18n`Open`,
                  onClick: () => {
                    navigation.push(
                      concept.id,
                      {
                        pathname: getConceptUrl(store, concept.id),
                        navigationFilters,
                      }
                    );
                  },
                },
                {
                  key: 'share',
                  icon: IconName.link,
                  name: i18n`Copy link`,
                  onClick: () => share(
                    store,
                    navigation.createNavigationPayload(concept.id, {
                      pathname: getConceptUrl(store, concept.id),
                      navigationFilters,
                    })
                  ),
                },
                {
                  key: 'duplicate',
                  icon: IconName.content_copy_outline,
                  name: i18n`Duplicate`,
                  hidden: hideDuplicate,
                  onClick: () => duplicateConcept(store, aclHandler, concept.id, (id) => forceFollowingIds(concept.id, id)),
                },
                {
                  key: 'duplicate_with_child',
                  icon: IconName.content_copy_outline,
                  name: i18n`Duplicate with its child elements`,
                  hidden: hideDuplicate || concept[Instance_Of] === DataAsset,
                  onClick: () => duplicateConcept(store, aclHandler, concept.id, (id) => forceFollowingIds(concept.id, id), true),
                },
                {
                  key: 'unlink',
                  name: i18n`Unlink`,
                  icon: IconName.link_off,
                  danger: true,
                  hidden: readOnly || !linkOptions,
                  onClick: () => linkOptions?.onUnlink(concept.id),
                },
                {
                  key: 'delete',
                  icon: IconName.delete,
                  name: i18n`Delete`,
                  hidden: readOnly
                    || !!linkOptions
                    || !isMonoDim
                    || concept[Instance_Of] === ConceptRole
                    || isEmbeddedAsIntegrationOnly(store.getObject(concept.id))
                    || !aclHandler.canDeleteObject(concept.id),
                  onClick: () => doDelete(concept.id),
                  danger: true,
                },
              ];
            });
        }}
        getNavigationPayload={getNavigationPayload}
        columnsDefinition={columnDefinition}
        fullWidth
      />
      {deleteModal}
    </>
  );
}, () => (<Loading withoutBackground />));

export default TableView;
