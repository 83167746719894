import type { FunctionComponent } from 'react';
import { useState } from 'react';
import Button, { ButtonVariant } from '../../../components/atoms/Button';
import { IconName } from '../../../components/atoms/Icon';
import InlineLink from '../../../components/molecules/InlineLink';
import SpacingLine from '../../../components/molecules/SpacingLine';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../components/templates/BlockTitle';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';

const useStyles = makeStyles((theme) => ({
  largeContainer: {
    padding: spacingRem.l,
    flexGrow: 1,
    color: theme.color.text.secondary,
    backgroundColor: theme.color.background.neutral.subtle,
  },
  variable: {
    color: theme.color.text.warning,
  },
}), 'templateHelperBlock');

const TemplateHelperBlock: FunctionComponent = () => {
  const classes = useStyles();

  const [showOptions, setShowOptions] = useState(false);

  return (
    <VerticalBlock asBlockContent>
      <BlockTitle
        title={(
          <SpacingLine>
            <Button
              title={i18n`How to use variables in text`}
              iconName={showOptions ? IconName.expand_more : IconName.keyboard_arrow_right}
              variant={ButtonVariant.tertiary}
              onClick={() => setShowOptions((current) => !current)}
            />
          </SpacingLine>
        )}
        variant={BlockTitleVariant.inline}
      />
      {showOptions && (
        <BlockContent padded>
          <div className={classes.largeContainer}>
            <p>
              <p>
                <strong>{i18n`Basic syntax →`}</strong>
                {' {{ '}
                <span className={classes.variable}>{i18n`variable`}</span>
                {' }}'}
              </p>
              <br />
              {i18n`Use field API aliases to access the properties of a variable.`}
              <br />
              <p>
                <u>{i18n`Example`}</u>
                {': {{ '}
                <span className={classes.variable}>{i18n`variable.name`}</span>
                {' }}'}
              </p>
              <br />
              <p>
                <strong>{i18n`Autoescaping`}</strong>
                {': '}
                {i18n`by default all output will automatically be escaped for safe output. To manually mark output as safe, use the safe filter. Nunjucks will not escape this output`}
                {': {{ '}
                <span className={classes.variable}>{i18n`eventTime | safe`}</span>
                {' }}'}
              </p>
              <br />
              <p>
                {i18n.jsx`Please read ${(
                  <InlineLink to="https://mozilla.github.io/nunjucks/templating.html#variables">{i18n`Nunjucks templating documentation`}</InlineLink>
                )} for more information.`}
              </p>
            </p>
          </div>
        </BlockContent>
      )}
    </VerticalBlock>
  );
};

export default TemplateHelperBlock;
