import type { FunctionComponent } from 'react';
import { useMemo, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FontVariant } from '../../theme/fontDefinition';
import { buildMargins, Spacing, spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import useTheme from '../../utils/useTheme';
import useUsageContext, { UsageVariant } from '../../utils/useUsageContext';
import Button, { ButtonVariant } from '../atoms/Button';
import Icon, { IconColorVariant, IconName, IconSizeVariant } from '../atoms/Icon';
import Image from '../atoms/Image';
import ImageCropper from '../atoms/ImageCropper';
import Typo from '../atoms/Typo';
import EditableWithDropdown, { EditableCloseReasons } from '../molecules/EditableWithDropdown';
import ScreenModal from '../molecules/ScreenModal';
import SpacingLine from '../molecules/SpacingLine';
import UploadFileButton from './UploadFileButton';

const useStyles = makeStyles((theme) => ({
  image: {
    width: '100%',
    height: '100%',
    maxHeight: '80vh',
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: spacingRem.s,
    padding: spacingRem.s,
  },
  placeholderContainer: buildMargins({ x: Spacing.s, y: Spacing.xs }),
  cardPlaceholderContainer: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: theme.color.background.neutral.subtle,
  },
  cardPlaceholderActionContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: spacingRem.s,
    padding: spacingRem.xs,
  },
  imagePlaceholder: {
    maxHeight: '35rem',
  },
}), 'imagePicker');

interface Value {
  image: { type: 'url', url: string } | { type: 'buffer', data: ArrayBuffer, contentType: string },
  position: { top: number, left: number, scale: number } | undefined,
}

interface ImagePickerProps {
  value: Value | undefined,
  onChange: (content: Value | undefined) => void,
  onSubmit: (content: Value | undefined) => void,
  onCancel: () => void,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  isEditing?: boolean,
  readOnly?: boolean,
  aspectRatio?: { x: number, y: number },
  borderless?: boolean,
}

const ImagePicker: FunctionComponent<ImagePickerProps> = ({
  value,
  onSubmit,
  onChange,
  onCancel,
  onEditionStart,
  onEditionStop,
  isEditing = false,
  readOnly = false,
  aspectRatio = { x: 4, y: 1 },
  borderless = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const usageVariant = useUsageContext();

  const [updatePosition, setUpdatePosition] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const isDraggingRef = useRef(false);

  const imageUrl = useMemo(() => {
    if (value?.image.type === 'url') {
      return value.image.url;
    } else if (value?.image.type === 'buffer') {
      const blob = new Blob([value.image.data]);
      return URL.createObjectURL(blob);
    } else {
      return undefined;
    }
  }, [value?.image]);

  return (
    <>
      <EditableWithDropdown
        showDropdown={showDropdown}
        openDropdown={() => {
          isDraggingRef.current = false;
          setShowDropdown(true);
          if (!readOnly) {
            onEditionStart?.();
          }
        }}
        closeDropdown={(reason) => {
          if (!isDraggingRef.current && !showModal) {
            setShowModal(false);
            setUpdatePosition(false);
            setShowDropdown(false);
            if (!readOnly) {
              onEditionStop?.();
              if (reason === EditableCloseReasons.onEscapeKeyDown) {
                onCancel();
              } else {
                onSubmit(value);
              }
            }
          }
        }}
        renderValue={(inDropdown) => {
          if (!imageUrl || !value) {
            if (usageVariant === UsageVariant.inCard) {
              return (
                <div className={classes.cardPlaceholderContainer}>
                  <div style={{ paddingBottom: `${((aspectRatio.y / aspectRatio.x) * 100).toFixed(1)}%` }} className={classes.imagePlaceholder} />
                  <AutoSizer>
                    {({ width = 0, height = 0 }) => {
                      const iconHeight = remToPx(4.8);
                      const rowGap = remToPx(spacingRem.s);
                      const buttonHeight = remToPx(2.6);
                      const containerPadding = remToPx(spacingRem.xs) * 2;

                      const showButton = !readOnly && height >= (containerPadding + buttonHeight);
                      const showIcon = height >= (containerPadding + iconHeight + (showButton ? (buttonHeight + rowGap) : 0));

                      return (
                        <div className={classes.cardPlaceholderActionContainer} style={{ width, height }}>
                          {showIcon ? (<Icon name={IconName.image} colorVariant={IconColorVariant.disabled} size={IconSizeVariant.xxl} />) : null}
                          {showButton ? (
                            <UploadFileButton
                              value={undefined}
                              onChange={(newValue) => {
                                setShowDropdown(true);
                                onChange({ image: newValue, position: undefined });
                                setUpdatePosition(true);
                              }}
                              buttonVariant={ButtonVariant.secondary}
                              typeRestriction={{ accept: 'image/*', isAccepted: (type) => type.startsWith('image/'), errorMessage: i18n`Invalid file format, only images are accepted` }}
                            />
                          ) : null}
                        </div>
                      );
                    }}
                  </AutoSizer>
                </div>
              );
            } else if (usageVariant !== UsageVariant.inTable && !readOnly) {
              return (
                <div className={classes.placeholderContainer}>
                  <Typo maxLine={1} color={theme.color.text.disabled}>
                    {i18n`Add image`}
                  </Typo>
                </div>
              );
            } else {
              return null;
            }
          } else if (inDropdown && !readOnly && updatePosition) {
            return (
              <ImageCropper
                aspectRatio={aspectRatio}
                imageUrl={imageUrl}
                position={value.position}
                onPositionChanged={(newPosition) => {
                  if (value) {
                    onChange({ image: value.image, position: newPosition });
                  }
                }}
                onDragStart={() => {
                  isDraggingRef.current = true;
                }}
                onDragEnd={() => {
                  isDraggingRef.current = false;
                }}
                maxHeightRem={35}
              />
            );
          } else {
            return (
              <Image
                aspectRatio={aspectRatio}
                imageUrl={imageUrl}
                position={value.position}
                maxHeightRem={usageVariant === UsageVariant.inTable && !inDropdown ? 3.8 : 35}
                cursor={inDropdown ? 'zoom-in' : undefined}
                onClick={inDropdown ? () => setShowModal(true) : undefined}
              />
            );
          }
        }}
        renderDropdown={readOnly || (!value && usageVariant === UsageVariant.inCard) ? undefined : () => (
          <span className={classes.line}>
            <SpacingLine>
              <UploadFileButton
                value={value?.image}
                onChange={(newValue) => {
                  onChange({ image: newValue, position: undefined });
                  setUpdatePosition(true);
                }}
                typeRestriction={{ accept: 'image/*', isAccepted: (type) => type.startsWith('image/'), errorMessage: i18n`Invalid file format, only images are accepted` }}
              />
              {imageUrl && !updatePosition ? (
                <Button
                  title={i18n`Change position`}
                  variant={ButtonVariant.tertiary}
                  onClick={() => setUpdatePosition(true)}
                />
              ) : null}
              {!value ? (<Typo variant={FontVariant.small} color={theme.color.text.secondary}>{i18n`The maximum size per file is 5 MB.`}</Typo>) : null}
            </SpacingLine>
            <SpacingLine>
              {value ? (
                <Button
                  onClick={() => onChange(undefined)}
                  variant={ButtonVariant.danger}
                  title={i18n`Delete`}
                  iconName={IconName.delete}
                />
              ) : null}
            </SpacingLine>
          </span>
        )}
        isEditing={isEditing}
        dropdownSizes={{ minWidth: '35rem' }}
        readOnly={readOnly && !value}
        borderless={borderless}
        autoFocus
      />
      <ScreenModal
        open={showModal && !!imageUrl}
        isImageModal
        hide={() => setShowModal(false)}
        onBackdropClick={() => setShowModal(false)}
        render={() => (
          <img className={classes.image} alt="cover" src={imageUrl} />
        )}
      />
    </>
  );
};

export default ImagePicker;
