import type {
  DimensionStep,
  FieldDimensionTypesStoreObject,
  FieldStep,
  FieldStoreObject,
  FilterStep,
  GlobalDimensionStep,
  LastFieldInfo,
  MappingStep,
  MultipleMappingStep,
  MultipleParameterDefinition,
  PathStep,
} from 'yooi-modules/modules/conceptModule';
import {
  getPathLastFieldInformation,
  getPathReturnedConceptDefinitionId,
  InstanceReferenceType,
  isConceptValid,
  isDimensionStep,
  isFieldStep,
  isFilterStep,
  isGlobalDimensionStep,
  isMappingStep,
  isMultiplePath,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import {
  Concept,
  Concept_StakeholdersRoles,
  ConceptDefinition,
  ConceptDefinition_Name,
  ConceptDefinition_Roles,
  ConceptRole,
  EmbeddingField,
  EmbeddingField_ToType,
  Field,
  Field_FieldDimensions,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  KinshipRelation,
  StakeholdersField,
  WorkflowEntry,
  WorkflowEntry_Role_Concept,
  WorkflowEntry_Role_Workflow,
  WorkflowField,
  WorkflowField_Workflow,
} from 'yooi-modules/modules/conceptModule/ids';
import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule/utils';
import { getAllFieldDimensionsLinkedConceptDefinitionIds, getFieldDimensionOfModelType, getFieldUtilsHandler, getInstanceLabel } from 'yooi-modules/modules/conceptModule/utils';
import { isMultipleMappingStep } from 'yooi-modules/modules/conceptModule/utils/path/pathUtils';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Extensions, Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { joinObjects } from 'yooi-utils';
import i18n from '../../utils/i18n';
import { getFieldLabel } from './fieldUtils';
import { listFieldIdsOfWorkflowField } from './workflow/workflowUtils';

export interface PathStepOption<T = PathStep> {
  step: T,
  isParameter?: boolean,
  replacePrevious?: boolean,
}

export enum ReturnedTypes {
  concept = 'concept',
  other = 'other',
}

export interface PathConfigurationHandler {
  computeOptions: (path: PathStep[]) => PathStepOption[],
  getPathWithErrors: (path: PathStep[]) => { step: PathStep, errors: string[] }[],
  getErrors: (path: PathStep[]) => string[] | undefined,
  getReturnedConceptDefinitionId: (valuePath: PathStep[]) => string | undefined,
  // conceptDefinitionId is undefined when we have a global dimension
  getLastFieldInformation: (valuePath: PathStep[]) => LastFieldInfo | undefined,
  getPathSummarize: (valuePath: PathStep[]) => string | undefined,
  getReturnedType: (path: PathStep[]) => ({ type: ReturnedTypes.concept, isMultiple: boolean, conceptDefinitionId: string } | { type: ReturnedTypes.other }),
}

const getAllConceptDefinitionIds: (store: ObjectStoreWithTimeseries) => string[] = (store) => store.getObject(ConceptDefinition).navigateBack(Instance_Of).map(({ id }) => id);

const getFieldIds = (store: ObjectStoreWithTimeseries, conceptDefinitionIds: string[]): string[] => (
  store.getObject(Field)
    .navigateBack(Class_Extensions)
    .flatMap((fieldType) => fieldType.navigateBack<FieldStoreObject>(Class_Instances))
    .filter((field) => (
      conceptDefinitionIds.length === 0
      || conceptDefinitionIds.every((conceptDefinitionId) => field.navigateBack(Field_FieldDimensions)
        .some((dimension) => store.withAssociation(FieldDimensionTypes)
          .withRole(FieldDimensionTypes_Role_ConceptDefinition, conceptDefinitionId)
          .withRole(FieldDimensionTypes_Role_FieldDimension, dimension.id)
          .getObjectOrNull<FieldDimensionTypesStoreObject>()))
    ))
    .map(({ id }) => id)
);

const getMappingOptionParameterIds: (
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
  conceptDefinitionId: string
) => string[] = (parameters, conceptDefinitionId) => (
  parameters.filter(({ type, typeId }) => typeId === conceptDefinitionId && type !== 'parameterList').map(({ id }) => id)
);

const getMultipleMappingOptionParameterIds: (
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
  conceptDefinitionId: string
) => string[] = (parameters, conceptDefinitionId) => (
  parameters.filter(({ type, typeId }) => typeId === conceptDefinitionId && type === 'parameterList').map(({ id }) => id)
);

const getMappingOptionInstanceIds: (store: ObjectStoreWithTimeseries, conceptDefinitionId: string) => string[] = (store, conceptDefinitionId) => (
  store.getObject(conceptDefinitionId).navigateBack(Class_Instances).map(({ id }) => id)
);

const getFieldDimensionIds: (store: ObjectStoreWithTimeseries, fieldId: string, embeddingFieldId?: string, conceptDefinitionId?: string) => string[] = (
  store,
  fieldId,
  embeddingFieldId,
  conceptDefinitionId
) => {
  if (embeddingFieldId) {
    return getAllFieldDimensionsLinkedConceptDefinitionIds(store, embeddingFieldId);
  }
  if (conceptDefinitionId) {
    const targetTypes = getFieldUtilsHandler(store, fieldId)?.getTargetTypes?.(conceptDefinitionId);
    if (targetTypes) {
      return targetTypes.map(({ id }) => id);
    }
  }
  const targetType = getFieldUtilsHandler(store, fieldId)?.getTargetType?.();
  if (targetType) {
    if (targetType.id === Concept) {
      return store.getObject(ConceptDefinition).navigateBack(Instance_Of).map(({ id }) => id);
    } else {
      return [targetType].map(({ id }) => id);
    }
  }
  return [];
};

const toDimensionSteps = (ids: string[]): PathStepOption<DimensionStep>[] => ids.map((id) => ({ step: { type: PathStepType.dimension, conceptDefinitionId: id } }));

const toMappingSteps = (ids: string[], isParameter?: boolean): PathStepOption<MappingStep>[] => ids.map((id) => ({
  step: { type: PathStepType.mapping, mapping: { id, type: isParameter ? InstanceReferenceType.parameter : InstanceReferenceType.instance } },
  isParameter,
}));

const toMultipleMappingSteps = (ids: string[], isParameter?: boolean): PathStepOption<MultipleMappingStep>[] => ids.map((id) => ({
  step: { type: PathStepType.multipleMapping, id },
  isParameter,
}));

const toFieldSteps = (ids: string[]): PathStepOption<FieldStep>[] => ids.map((id) => ({ step: { type: PathStepType.field, fieldId: id } }));

const ALL_INSTANCES_STEP_OPTION: PathStepOption<FilterStep> = { step: { type: PathStepType.filter } };
const GLOBAL_STEP_OPTION: PathStepOption<GlobalDimensionStep> = { step: { type: PathStepType.global, conceptDefinitionIds: [] } };

export enum StepValidationState {
  valid = 'valid',
  partiallyValid = 'partiallyValid',
  invalid = 'invalid',
}

interface StepValidation {
  state: StepValidationState,
}

interface ValidStep extends StepValidation {
  state: StepValidationState.valid,
}

interface PartiallyValidStep extends StepValidation {
  state: StepValidationState.partiallyValid,
  reasonMessage?: string,
}

interface InvalidStep extends StepValidation {
  state: StepValidationState.invalid,
  reasonMessage?: string,
}

export type PathStepValidator = (
  { pathStep, isNPath, path, isLastStep }: { pathStep: PathStep, isNPath: boolean, path: PathStep[], isLastStep?: boolean }
) => (ValidStep | PartiallyValidStep | InvalidStep)[];

export const createPathConfigurationHandler = (
  store: ObjectStoreWithTimeseries,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
  validators: PathStepValidator[] = []
): PathConfigurationHandler => {
  const globalStepValidator: PathStepValidator = ({ pathStep, path }) => {
    const nStep = pathStep;
    const n1Step = path.length > 1 ? path[path.length - 2] : undefined;
    const n2Step = path.length > 2 ? path[path.length - 3] : undefined;
    const n3Step = path.length > 3 ? path[path.length - 4] : undefined;

    if (isDimensionStep(nStep)) {
      // Is dimension valid
      if (!store.getObjectOrNull(nStep.conceptDefinitionId)) {
        return [{ state: StepValidationState.invalid, reasonMessage: i18n`Dimension doesn't exist` }];
      }
      if (n1Step && isFieldStep(n1Step) && n3Step && isDimensionStep(n3Step)) {
        if (store.getObjectOrNull(n1Step.fieldId) === null) {
          return [{ state: StepValidationState.invalid, reasonMessage: i18n`Field is not valid.` }];
        }
        const targetTypesIds = getFieldDimensionIds(store, n1Step.fieldId, n1Step.embeddingFieldId, n3Step.conceptDefinitionId);
        if (targetTypesIds.find((targetTypeId) => targetTypeId === nStep.conceptDefinitionId)) {
          return [{ state: StepValidationState.valid }];
        } else {
          return [{ state: StepValidationState.invalid, reasonMessage: i18n`Previous field returned dimension doesn't match.` }];
        }
      }
      return [{ state: StepValidationState.valid }];
    } else if (isMappingStep(nStep)) {
      if (n1Step && isDimensionStep(n1Step) && (!isFieldStep(n2Step) || n2Step.fieldId === Concept_StakeholdersRoles)) {
        const parameter = parameterDefinitions.find(({ id: parameterId }) => parameterId === nStep.mapping.id);
        if (parameter) {
          if (parameter.type === 'parameterList') {
            return [{ state: StepValidationState.invalid, reasonMessage: i18n`A parameter multiple cannot be used by a mapping step` }];
          } else if (parameter.typeId === n1Step.conceptDefinitionId) {
            return [{ state: StepValidationState.valid }];
          } else {
            return [{ state: StepValidationState.invalid, reasonMessage: i18n`Mapping dimension doesn't match previous step` }];
          }
        } else if (!isConceptValid(store, nStep.mapping.id)) {
          return [{ state: StepValidationState.invalid, reasonMessage: i18n`Mapping is not valid` }];
        } else if (store.getObject(nStep.mapping.id)[Instance_Of] === n1Step.conceptDefinitionId) {
          return [{ state: StepValidationState.valid }];
        }
        return [{ state: StepValidationState.invalid, reasonMessage: i18n`Mapping dimension doesn't match previous step` }];
      }
      return [{ state: StepValidationState.invalid, reasonMessage: i18n`Mappings should follow a dimension.` }];
    } else if (isMultipleMappingStep(nStep)) {
      if (n1Step && isDimensionStep(n1Step)) {
        const parameter = parameterDefinitions.find(({ id: parameterId }) => parameterId === nStep.id);
        if (parameter) {
          if (parameter.type !== 'parameterList') {
            return [{ state: StepValidationState.invalid, reasonMessage: i18n`A parameter single cannot be used by a multiple mapping step` }];
          } else if (parameter.typeId === n1Step.conceptDefinitionId) {
            return [{ state: StepValidationState.valid }];
          } else {
            return [{ state: StepValidationState.invalid, reasonMessage: i18n`Mapping dimension doesn't match previous step` }];
          }
        }
        return [{ state: StepValidationState.invalid, reasonMessage: i18n`Mapping dimension doesn't match previous step` }];
      }
      return [{ state: StepValidationState.invalid, reasonMessage: i18n`Mappings should follow a dimension.` }];
    } else if (isFieldStep(nStep)) {
      if (
        nStep.embeddingFieldId
        && (nStep.fieldId !== KinshipRelation || !isInstanceOf(store.getObjectOrNull(nStep.embeddingFieldId), EmbeddingField))
      ) {
        return [{ state: StepValidationState.invalid, reasonMessage: i18n`Embedding field is invalid.` }];
      }
      if (nStep.workflowSubfieldId
        && (!isInstanceOf(store.getObjectOrNull(nStep.fieldId), WorkflowField))
      ) {
        return [{ state: StepValidationState.invalid, reasonMessage: i18n`Workflow field is invalid.` }];
      }
      if (n2Step && isDimensionStep(n2Step) && n1Step && (isMappingStep(n1Step) || isFilterStep(n1Step) || isMultipleMappingStep(n1Step))) {
        if (store.getObjectOrNull(nStep.fieldId) !== null && getFieldDimensionOfModelType(store, nStep.fieldId, n2Step.conceptDefinitionId)) {
          return [{ state: StepValidationState.valid }];
        }
      } else if (n1Step && isGlobalDimensionStep(n1Step) && store.getObjectOrNull(nStep.fieldId) !== null) {
        return [{ state: StepValidationState.valid }];
      }
      return [{ state: StepValidationState.invalid, reasonMessage: i18n`Field is not valid.` }];
    } else if (isFilterStep(nStep)) {
      if (n1Step && isDimensionStep(n1Step)) {
        return [{ state: StepValidationState.valid }];
      } else {
        return [{ state: StepValidationState.invalid, reasonMessage: i18n`Filter step should follow a dimension step.` }];
      }
    } else if (isGlobalDimensionStep(nStep)) {
      if (nStep.conceptDefinitionIds.some((id) => !store.getObjectOrNull(id))) {
        return [{ state: StepValidationState.invalid, reasonMessage: i18n`Some dimensions don't exist anymore.` }];
      }
      return [{ state: StepValidationState.valid }];
    } else {
      return [{ state: StepValidationState.invalid, reasonMessage: i18n`Step type is not supported` }];
    }
  };

  const internalValidators: PathStepValidator[] = [globalStepValidator, ...validators];

  const getFirstStepErrorMessage = (path: PathStep[], pathStep: PathStep, isNPath: boolean, isLastStep: boolean): string | undefined => {
    for (let i = 0; i < internalValidators.length; i += 1) {
      const validator = internalValidators[i];
      const validations = validator({ pathStep, isNPath, path, isLastStep });
      for (let j = 0; j < validations.length; j += 1) {
        const validation = validations[j];
        if (validation.state === StepValidationState.invalid) {
          return validation.reasonMessage ?? i18n`Invalid step`;
        }
      }
    }
    return undefined;
  };

  const isValidStep = (path: PathStep[], pathStep: PathStep, isNPath: boolean, isLastStep: boolean): boolean => !internalValidators
    .some((validator) => validator({ pathStep, isNPath, path, isLastStep })
      .some((validation) => validation.state === StepValidationState.invalid));

  const getFirstPathErrorMessage = (path: PathStep[]): string | undefined => {
    for (let i = 0; i < path.length; i += 1) {
      const step = path[i];
      const stepPath = path.slice(0, i + 1);
      const isLastStep = i === path.length - 1;
      const message = getFirstStepErrorMessage(stepPath, step, isMultiplePath(store, stepPath), isLastStep);
      if (message) {
        return message;
      }
    }
    return undefined;
  };

  const isPathValid = (path: PathStep[]): boolean => path
    .every((step, index, self) => {
      const stepPath = self.slice(0, index + 1);
      const isLastStep = index === self.length - 1;
      return isValidStep(stepPath, step, isMultiplePath(store, stepPath), isLastStep);
    });

  return {
    computeOptions: (path) => {
      if (!isPathValid(path)) {
        return [];
      }

      // we have the path cleaned and valid
      const nStep = path.length ? path[path.length - 1] : undefined;
      const n1Step = path.length > 1 ? path[path.length - 2] : undefined;
      const n2Step = path.length > 2 ? path[path.length - 3] : undefined;

      let pathStepOptions: PathStepOption[] = [];

      if (!nStep) {
        // starting the path
        // display global dimension options and all concept definitions sorted by label
        pathStepOptions = [
          GLOBAL_STEP_OPTION,
          ...toDimensionSteps(getAllConceptDefinitionIds(store)),
        ];
      } else if (isDimensionStep(nStep)) {
        let instances = null;
        if (n1Step && isFieldStep(n1Step) && isInstanceOf(store.getObject(n1Step.fieldId), StakeholdersField) && nStep.conceptDefinitionId === ConceptRole) {
          const n3Step = path.length > 3 ? path[path.length - 4] : undefined;
          if (n3Step && isDimensionStep(n3Step)) {
            instances = toMappingSteps(store.getObject(n3Step.conceptDefinitionId).navigateBack(ConceptDefinition_Roles).map(({ id }) => id), false);
          }
        } else if (n1Step && isFieldStep(n1Step) && isInstanceOf(store.getObject(n1Step.fieldId), WorkflowField)) {
          const workflowFieldId = store.getObject(n1Step.fieldId)[WorkflowField_Workflow] as string | undefined;
          instances = workflowFieldId
            ? toMappingSteps(store.withAssociation(WorkflowEntry)
              .withRole(WorkflowEntry_Role_Workflow, workflowFieldId)
              .list()
              .map((assoc) => assoc.role(WorkflowEntry_Role_Concept)))
            : [];
        }
        // last step is a dimension step
        // Next step can be a mapping step or a filter step
        pathStepOptions = [
          ALL_INSTANCES_STEP_OPTION,
          ...(instances ?? toMappingSteps(getMappingOptionInstanceIds(store, nStep.conceptDefinitionId))),
          ...toMappingSteps(getMappingOptionParameterIds(parameterDefinitions, nStep.conceptDefinitionId), true),
          ...path.length === 1 ? toMultipleMappingSteps(getMultipleMappingOptionParameterIds(parameterDefinitions, nStep.conceptDefinitionId), true) : [],
        ];
      } else if (n1Step && isDimensionStep(n1Step) && (isMappingStep(nStep) || isFilterStep(nStep) || isMultipleMappingStep(nStep))) {
        // last step is a mapping step or a filter step
        // next step is a field step
        pathStepOptions = toFieldSteps(getFieldIds(store, [n1Step.conceptDefinitionId]));
      } else if (isFieldStep(nStep)) {
        // last step is a field step
        // next step is a dimension step
        const embeddedBySteps = [];
        if (n2Step && isDimensionStep(n2Step) && nStep.fieldId === KinshipRelation && !nStep.embeddingFieldId) {
          embeddedBySteps.push(
            ...store.getObject(n2Step.conceptDefinitionId).navigateBack(EmbeddingField_ToType)
              .map((option): PathStepOption<FieldStep> => ({
                step: { type: PathStepType.field, fieldId: nStep.fieldId, embeddingFieldId: option.id },
                replacePrevious: true,
              }))
          );
        }
        const workflowFieldSteps = [];
        const stepField = store.getObject(nStep.fieldId);
        if (isInstanceOf(stepField, WorkflowField) && !nStep.workflowSubfieldId) {
          const fields = listFieldIdsOfWorkflowField(store, stepField.id);
          workflowFieldSteps.push(...fields.map((fieldId): PathStepOption<FieldStep> => ({
            step: { type: PathStepType.field, fieldId: stepField.id, workflowSubfieldId: fieldId },
            replacePrevious: true,
          })));
        }
        pathStepOptions = [
          ...embeddedBySteps,
          ...workflowFieldSteps,
          ...toDimensionSteps(getFieldDimensionIds(store, nStep.fieldId, nStep.embeddingFieldId, n2Step && isDimensionStep(n2Step) ? n2Step.conceptDefinitionId : undefined)),
        ];
      } else if (isGlobalDimensionStep(nStep)) {
        // Last step is the global dimension step
        // next step if a field step with dimension matching the concept definition ids,
        // or you can select concept definition to filter fields
        pathStepOptions = [
          ...getAllConceptDefinitionIds(store).filter((conceptDefinitionId) => !nStep.conceptDefinitionIds.includes(conceptDefinitionId))
            .map((conceptDefinitionId) => ({
              step: joinObjects(
                nStep,
                { conceptDefinitionIds: [...nStep.conceptDefinitionIds, conceptDefinitionId] }
              ),
              replacePrevious: true,
            })),
          ...toFieldSteps(getFieldIds(store, nStep.conceptDefinitionIds)),
        ];
      }
      return pathStepOptions.filter((pathStepOption) => {
        let nextPath;
        if (pathStepOption.replacePrevious) {
          nextPath = [...path.slice(0, path.length - 1), pathStepOption.step];
        } else {
          nextPath = [...path, pathStepOption.step];
        }
        return isValidStep(nextPath, pathStepOption.step, isMultiplePath(store, nextPath), true);
      });
    },
    getPathWithErrors: (path) => path.map((pathStep, index, self) => {
      const stepPath = self.slice(0, index + 1);
      const isLastStep = index === self.length - 1;
      const errors = internalValidators
        .flatMap((validator) => validator({ pathStep, isLastStep, isNPath: isMultiplePath(store, stepPath), path: stepPath }))
        .filter((result): result is InvalidStep => result.state === StepValidationState.invalid)
        .map((invalidStep) => invalidStep.reasonMessage ?? i18n`Invalid step`);
      return { step: pathStep, errors };
    }),
    getErrors: (path) => {
      const errorMessage = getFirstPathErrorMessage(path);
      if (errorMessage) {
        return [errorMessage];
      }
      const nStep = path.length ? path[path.length - 1] : undefined;
      let errors: string[] | undefined;
      if (nStep) {
        const isNPath = isMultiplePath(store, path);
        internalValidators.some((validator) => {
          const stepValidations = validator({ pathStep: nStep, isNPath, path, isLastStep: true });
          const invalidMessages = stepValidations
            .filter((stepValidation): stepValidation is InvalidStep | PartiallyValidStep => stepValidation.state !== StepValidationState.valid)
            .map((stepValidation) => stepValidation.reasonMessage ?? i18n`Last path step is not valid.`);
          if (invalidMessages.length) {
            errors = invalidMessages;
            return true;
          }
          return false;
        });
      }
      return errors;
    },
    getLastFieldInformation: (path) => {
      if (!isPathValid(path)) {
        return undefined;
      }
      return getPathLastFieldInformation(path);
    },
    getReturnedConceptDefinitionId: (path) => {
      if (!isPathValid(path)) {
        return undefined;
      }
      return getPathReturnedConceptDefinitionId(store, path);
    },
    getPathSummarize: (path) => {
      if (!isPathValid(path)) {
        return undefined;
      }
      let dimensionName: string | undefined;
      let fieldName: string | undefined;
      for (let i = 0; i < path.length; i += 1) {
        const nStep = path[i];
        if (isGlobalDimensionStep(nStep)) {
          dimensionName = 'Global';
        } else if (isDimensionStep(nStep)) {
          // a list of concept of dimension step conceptDefinition is returned
          dimensionName = store.getObjectOrNull(nStep.conceptDefinitionId)?.[ConceptDefinition_Name] as string | undefined;
        } else if (isMappingStep(nStep)) {
          // only multiplicity change
          const parameterIndex = parameterDefinitions.findIndex((p) => p.id === nStep.mapping.id);
          if (parameterIndex !== -1) {
            dimensionName = parameterDefinitions[parameterIndex].label;
          } else {
            const instance = store.getObjectOrNull(nStep.mapping.id);
            if (instance) {
              dimensionName = getInstanceLabel(store, store.getObject(nStep.mapping.id));
            } else {
              dimensionName = undefined;
            }
          }
        } else if (isMultipleMappingStep(nStep)) {
          // only multiplicity change
          const parameterIndex = parameterDefinitions.findIndex((p) => p.id === nStep.id);
          if (parameterIndex !== -1) {
            dimensionName = parameterDefinitions[parameterIndex].label;
          } else {
            dimensionName = undefined;
          }
        } else if (isFieldStep(nStep)) {
          const field = store.getObjectOrNull<FieldStoreObject>(nStep.fieldId);
          fieldName = field ? getFieldLabel(store, field) : undefined;
          if (dimensionName) {
            fieldName = `${dimensionName} ${fieldName}`;
            dimensionName = undefined;
          }
        } else if (isFilterStep(nStep)) {
          // not useful
        }
      }
      return fieldName ?? dimensionName;
    },
    getReturnedType: (path: PathStep[]) => {
      const returnTypeId = getPathReturnedConceptDefinitionId(store, path);
      if (returnTypeId) {
        return { type: ReturnedTypes.concept, isMultiple: isMultiplePath(store, path), conceptDefinitionId: returnTypeId };
      } else {
        return { type: ReturnedTypes.other };
      }
    },
  };
};
