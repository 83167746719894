import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { Filters, PathStep, SingleParameterDefinition, ViewFilterStoreObject } from 'yooi-modules/modules/conceptModule';
import { FilterConditionOperators, isFilterNode } from 'yooi-modules/modules/conceptModule';
import { joinObjects } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../components/atoms/Button';
import Icon, { IconName } from '../../../components/atoms/Icon';
import Tooltip from '../../../components/atoms/Tooltip';
import Typo from '../../../components/atoms/Typo';
import { CompositeFieldVariants } from '../../../components/molecules/CompositeField';
import { EditableButtonVariant } from '../../../components/molecules/EditableWithDropdown';
import useStore from '../../../store/useStore';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import { UsageContextProvider, UsageVariant } from '../../../utils/useUsageContext';
import FilterComposite from './filterComposite/FilterComposite';
import { countValidFilters } from './filterUtils';
import { FilterParams, useFilterStorage } from './useFilterSessionStorage';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: spacingRem.xs,
    marginTop: spacingRem.xs,
    display: 'flex',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
  titleTertiary: {
    color: theme.color.text.brand,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
}), 'temporaryFilterComposite');

interface TemporaryFilterCompositeProps {
  filterKey: string,
  rootPath?: { label: string, path: PathStep[] },
  parameterDefinitions?: SingleParameterDefinition[],
  noFiltersButtonVariant?: EditableButtonVariant,
  getViewFilters: () => ViewFilterStoreObject[],
  suggestedPaths?: { label: string, path: PathStep[] }[],
}

const TemporaryFilterComposite: FunctionComponent<TemporaryFilterCompositeProps> = ({
  filterKey, rootPath, parameterDefinitions, noFiltersButtonVariant, getViewFilters, suggestedPaths,
}) => {
  const classes = useStyles();

  const store = useStore();

  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [filterSessionStorageState = {}, setFilterSessionStorageState] = useFilterStorage(filterKey, FilterParams.filters);

  const quickFiltersIdsSet: Set<string> = new Set(getViewFilters().map(({ id }) => id));
  const temporaryFilters = Object.entries(filterSessionStorageState)
    .filter(([key]) => !quickFiltersIdsSet.has(key))
    .reduce((acc, [, filter]) => {
      if (acc === undefined) {
        return filter;
      } else if (isFilterNode(acc)) {
        return {
          condition: FilterConditionOperators.OR,
          children: [...acc.children ?? [], filter],
        };
      } else {
        return joinObjects(
          acc,
          { children: [acc, filter] }
        );
      }
    }, undefined as undefined | Filters);

  const validFiltersCount = countValidFilters(store, temporaryFilters);

  return (
    <div className={classes.container}>
      <UsageContextProvider usageVariant={UsageVariant.inForm}>
        <FilterComposite
          isEditing={validFiltersCount > 0}
          onClose={() => setShowFilterDropdown(false)}
          showDropdown={showFilterDropdown}
          variant={CompositeFieldVariants.button}
          buttonVariant={validFiltersCount === 0 ? noFiltersButtonVariant : undefined}
          filtersDefinition={{
            definition: [{
              filter: temporaryFilters,
            }],
            updateFilters: (newFilter) => {
              setFilterSessionStorageState((current) => (joinObjects(
                Object.fromEntries(Object.entries(current ?? {}).filter(([key]) => quickFiltersIdsSet.has(key))),
                { temporaryFilter: newFilter[0] }
              )));
            },
          }}
          parameterDefinitions={parameterDefinitions}
          rootPath={rootPath}
          suggestedPaths={suggestedPaths}
          renderCompositeTitle={(inDropdown) => {
            if (validFiltersCount > 0) {
              const label = `+${validFiltersCount} rule`;
              return (
                <span className={classes.title}>
                  <Tooltip title={label}>
                    <Typo maxLine={1}>{label}</Typo>
                  </Tooltip>
                </span>
              );
            } else {
              const label = inDropdown ? i18n`New rule` : i18n`Add filter`;
              return (
                <span className={classnames({ [classes.title]: true, [classes.titleTertiary]: noFiltersButtonVariant === EditableButtonVariant.tertiary && !inDropdown })}>
                  <Icon name={IconName.add} />
                  <Tooltip title={label}>
                    <Typo maxLine={1}>{label}</Typo>
                  </Tooltip>
                </span>
              );
            }
          }}
        />
      </UsageContextProvider>
      {validFiltersCount > 0 && (
        <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
          <Button iconName={IconName.add} title={i18n`Add filter`} variant={ButtonVariant.tertiary} onClick={() => setShowFilterDropdown(true)} />
        </SizeContextProvider>
      )}
    </div>
  );
};
export default TemporaryFilterComposite;
