import { equals } from 'ramda';
import { joinObjects } from 'yooi-utils';
import useDeepMemo from '../../../utils/useDeepMemo';
import useDerivedState from '../../../utils/useDerivedState';
import type { EditionHandler } from './FieldLibraryTypes';

const useEditionHandler = <ConfigurationState = Record<string, unknown>>(initialState: ConfigurationState): EditionHandler<ConfigurationState> => {
  const memoizedInitialState = useDeepMemo(() => initialState, [initialState]);
  const [creationObject, setCreationObject, resetCreationObject] = useDerivedState(() => memoizedInitialState, [memoizedInitialState]);

  return {
    getValue: (id) => (creationObject?.[id] ?? undefined),
    getValueOrDefault: (id) => (creationObject?.[id] ?? undefined),
    updateValues: (update) => {
      setCreationObject((current) => joinObjects(current ?? {}, update) as ConfigurationState);
    },
    reset: () => {
      const beforeReset = creationObject;
      resetCreationObject();
      return beforeReset;
    },
    resetValue: (id) => {
      setCreationObject((current) => joinObjects(current ?? {}, { [id]: memoizedInitialState?.[id] }) as ConfigurationState);
    },
    hasModification: () => !equals(memoizedInitialState, creationObject),
  };
};

export default useEditionHandler;
