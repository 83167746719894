import type { FunctionComponent } from 'react';
import { Opacity } from '../../theme/base';
import { generateColorFromOpacity, getMostReadableColorFromBackgroundColor } from '../../theme/colorUtils';
import makeStyles from '../../utils/makeStyles';
import useTheme from '../../utils/useTheme';
import useWidgetSizeContext from '../../utils/useWidgetSizeContext';
import { useWidgetContext } from '../atoms/WidgetContextProvider';
import Spinner, { SpinnerVariant } from './Spinner';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}, 'widgetLoader');

const WidgetLoader: FunctionComponent = () => {
  const theme = useTheme();
  const classes = useStyles();

  const { width, height } = useWidgetSizeContext();
  const { backgroundColor } = useWidgetContext();

  const mostReadableColor = getMostReadableColorFromBackgroundColor(backgroundColor ?? theme.color.background.neutral.default);

  return (
    <div className={classes.container} style={{ width, height }}>
      <Spinner size={SpinnerVariant.big} color={generateColorFromOpacity(mostReadableColor, backgroundColor, Opacity.fifty)} />
    </div>
  );
};

export default WidgetLoader;
