import type { FunctionComponent } from 'react';
import type { ExternalKeyFieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { getFieldUtilsHandler, hasPlatformCapability, InvalidFieldError, isEmbeddedAsIntegrationOnly } from 'yooi-modules/modules/conceptModule';
import { ExternalKeyField_IsImmutable, Field_IntegrationOnly, PlatformCapabilityUpdateEmail, User_Email } from 'yooi-modules/modules/conceptModule/ids';
import useAcl from '../../../../store/useAcl';
import useActivity from '../../../../store/useActivity';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import useUpdateActivity from '../../../../store/useUpdateActivity';
import { notifyError } from '../../../../utils/notify';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';
import { formatErrorForUser } from '../../errorUtils';
import StoreTextInputField from '../../input/StoreTextInputField';
import { getFieldHandler } from '../FieldLibrary';

interface ExternalKeyRendererProps {
  objectId: string,
  fieldId: string,
  placeholder?: string,
  readOnly?: boolean,
  focusOnMount?: boolean,
}

const ExternalKeyRenderer: FunctionComponent<ExternalKeyRendererProps> = ({ objectId, fieldId, placeholder, readOnly = false, focusOnMount = false }) => {
  const store = useStore();
  const { canWriteObject } = useAcl();
  const { loggedUserId } = useAuth();

  const activity = useActivity();
  const updateActivity = useUpdateActivity();
  const usageVariant = useUsageContext();

  const field = store.getObject<ExternalKeyFieldStoreObject>(fieldId);
  const object = store.getObject(objectId);

  const value = object[fieldId] as string | undefined;

  const isReadOnly = readOnly
    || field[Field_IntegrationOnly]
    || !canWriteObject(objectId)
    || isEmbeddedAsIntegrationOnly(store.getObject(objectId))
    || Boolean(field[ExternalKeyField_IsImmutable] && value)
    || (fieldId === User_Email && value !== undefined && value !== '' && !hasPlatformCapability(store, loggedUserId, PlatformCapabilityUpdateEmail));

  const onSubmit = (newExternalKey: string | null | undefined) => {
    const { error } = getFieldHandler(store, fieldId)?.valueValidation?.(newExternalKey) ?? {};
    const formattedError = error ? formatErrorForUser(store, new InvalidFieldError(fieldId, error)) : undefined;
    if (formattedError) {
      notifyError(formattedError);
      return;
    }
    store.updateObject(objectId, { [fieldId]: newExternalKey });
  };
  const inputValidationFunc = (newValue: string): string | undefined => {
    const { error } = getFieldHandler(store, fieldId)?.valueValidation?.(newValue) ?? {};
    return error ? formatErrorForUser(store, new InvalidFieldError(fieldId, error)) : undefined;
  };

  const { error } = getFieldUtilsHandler(store, fieldId)?.isSaneValue?.(objectId) ?? {};

  return (
    <StoreTextInputField
      initialValue={value}
      onSubmit={onSubmit}
      readOnly={isReadOnly}
      placeholder={usageVariant === UsageVariant.inTable ? undefined : placeholder}
      onEditionStart={() => updateActivity.onEnterEdition(objectId, fieldId)}
      onEditionStop={() => updateActivity.onExitEdition(objectId, fieldId)}
      isEditing={activity.listEditor(objectId, fieldId).length > 0}
      error={error ? formatErrorForUser(store, new InvalidFieldError(fieldId, error)) : error}
      inputValidation={inputValidationFunc}
      focusOnMount={focusOnMount}
      withDropdown
    />
  );
};

export default ExternalKeyRenderer;
