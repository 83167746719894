import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { getFieldDimensionOfModelType } from 'yooi-modules/modules/conceptModule';
import { IconName } from '../../../../../components/atoms/Icon';
import SpacedContainer from '../../../../../components/molecules/SpacedContainer';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import TooltipPopover from '../../../../../components/molecules/TooltipPopover';
import useStore from '../../../../../store/useStore';
import { Spacing } from '../../../../../theme/spacingDefinition';
import makeStyles from '../../../../../utils/makeStyles';
import useDerivedState from '../../../../../utils/useDerivedState';
import { getFieldLabel } from '../../../fieldUtils';
import type { NavigationFilter } from '../../../navigationUtils';
import { getFieldHandler } from '../../FieldLibrary';
import ConceptTooltip from '../ConceptTooltip';
import ConceptTooltipText from '../ConceptTooltipText';

const useStyles = makeStyles({
  firstColumnContainer: {
    width: '10.5rem',
  },
  container: {
    width: '38.2rem',
  },
  inlineContainer: {
    minWidth: '15rem',
  },
}, 'conceptMatrixTooltip');

interface ConceptMatrixTooltipProps {
  concept: {
    id: string,
    instanceOf: string,
    colorRawValue: string | undefined,
    ordValue: number | undefined,
    absValue: number | undefined,
  },
  colorFieldId: string | undefined,
  matrixOrdFieldId: string | undefined,
  matrixAbsFieldId: string | undefined,
  buttonView: boolean,
  editMode: boolean,
  currentAnchor: HTMLElement,
  handleClose: () => void,
  handleSubmit: (conceptId: string, values: { ordValue: number | null, absValue: number | null, color: string | null }) => void,
  readOnly?: boolean,
  navigationFilters?: NavigationFilter,
}

const ConceptMatrixTooltip: FunctionComponent<ConceptMatrixTooltipProps> = ({
  concept,
  colorFieldId,
  matrixOrdFieldId,
  matrixAbsFieldId,
  buttonView,
  editMode,
  currentAnchor,
  handleClose,
  handleSubmit,
  readOnly = false,
  navigationFilters,
}) => {
  const classes = useStyles();

  const store = useStore();

  const colorField = colorFieldId ? store.getObjectOrNull<FieldStoreObject>(colorFieldId) : undefined;
  const colorFieldHandler = colorFieldId ? getFieldHandler(store, colorFieldId) : undefined;
  const [color, setColor] = useDerivedState<string | null | undefined>(() => concept.colorRawValue, [concept.colorRawValue]);
  const colorFieldDimensionId = colorFieldId && colorFieldHandler ? getFieldDimensionOfModelType(store, colorFieldId, concept.instanceOf) : undefined;

  const matrixOrdField = matrixOrdFieldId ? store.getObjectOrNull<FieldStoreObject>(matrixOrdFieldId) : undefined;
  const matrixOrdFieldHandler = matrixOrdFieldId ? getFieldHandler(store, matrixOrdFieldId) : undefined;
  const [ordValue, setOrdValue] = useState<number | null | undefined>(concept.ordValue);
  const matrixOrdFieldDimensionId = matrixOrdFieldId && matrixOrdFieldHandler ? getFieldDimensionOfModelType(store, matrixOrdFieldId, concept.instanceOf) : undefined;

  const matrixAbsField = matrixAbsFieldId ? store.getObjectOrNull<FieldStoreObject>(matrixAbsFieldId) : undefined;
  const matrixAbsFieldHandler = matrixAbsFieldId ? getFieldHandler(store, matrixAbsFieldId) : undefined;
  const [absValue, setComplexityRate] = useState<number | null | undefined>(concept.absValue);
  const matrixAbsFieldDimensionId = matrixAbsFieldId && matrixAbsFieldHandler ? getFieldDimensionOfModelType(store, matrixAbsFieldId, concept.instanceOf) : undefined;

  return (
    <TooltipPopover
      focusOnMount={editMode}
      currentAnchor={currentAnchor}
      onEscape={editMode ? () => {
        handleClose();
      } : undefined}
      onClose={editMode ? () => {
        handleClose();
        handleSubmit(concept.id, { ordValue: ordValue ?? null, absValue: absValue ?? null, color: color ?? null });
      } : () => handleClose()}
    >
      <div className={classes.container}>
        <ConceptTooltip
          conceptId={concept.id}
          buttonView={buttonView}
          editMode={editMode}
          navigationFilters={navigationFilters}
        >
          {colorField && colorFieldHandler && colorFieldDimensionId ? (
            <SpacedContainer margin={{ bottom: Spacing.s }}>
              <SpacingLine>
                <div className={classes.firstColumnContainer}>
                  <ConceptTooltipText text={getFieldLabel(store, colorField)} />
                </div>
                <div className={classes.inlineContainer}>
                  {colorFieldHandler.renderField?.({
                    dimensionsMapping: { [colorFieldDimensionId]: concept.id },
                    value: color,
                    onSubmit: (value) => setColor(value as string | null),
                    readOnly,
                  })}
                </div>
              </SpacingLine>
            </SpacedContainer>
          ) : null}
          {matrixOrdField && matrixOrdFieldHandler && matrixAbsField && matrixAbsFieldHandler && matrixOrdFieldDimensionId && matrixAbsFieldDimensionId ? (
            <>
              <SpacedContainer margin={{ bottom: Spacing.s }}>
                <SpacingLine>
                  <div className={classes.firstColumnContainer}>
                    <ConceptTooltipText iconName={IconName.arrow_upward} text={getFieldLabel(store, matrixOrdField)} />
                  </div>
                  <div className={classes.inlineContainer}>
                    {matrixOrdFieldHandler.renderField?.({
                      dimensionsMapping: { [matrixOrdFieldDimensionId]: concept.id },
                      value: ordValue,
                      onSubmit: (value) => setOrdValue(value as number | null),
                      readOnly,
                    })}
                  </div>
                </SpacingLine>
              </SpacedContainer>
              <SpacedContainer margin={{ bottom: Spacing.s }}>
                <SpacingLine>
                  <div className={classes.firstColumnContainer}>
                    <ConceptTooltipText iconName={IconName.arrow_forward} text={getFieldLabel(store, matrixAbsField)} />
                  </div>
                  <div className={classes.inlineContainer}>
                    {matrixAbsFieldHandler.renderField?.({
                      dimensionsMapping: { [matrixAbsFieldDimensionId]: concept.id },
                      value: absValue,
                      onSubmit: (value) => setComplexityRate(value as number | null),
                      readOnly,
                    })}
                  </div>
                </SpacingLine>
              </SpacedContainer>
            </>
          ) : null}
        </ConceptTooltip>
      </div>
    </TooltipPopover>
  );
};

export default ConceptMatrixTooltip;
