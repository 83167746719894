import type { FunctionComponent } from 'react';
import type { IntegrationRaw, IntegrationStoreObject } from 'yooi-modules/modules/integrationModule';
import { Integration, Integration_Description, Integration_Name } from 'yooi-modules/modules/integrationModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { IconName } from '../../../components/atoms/Icon';
import IconOnlyButton from '../../../components/atoms/IconOnlyButton';
import Link from '../../../components/molecules/Link';
import { TableSortDirection } from '../../../components/molecules/Table';
import BaseLayout from '../../../components/templates/BaseLayout';
import BlockContent from '../../../components/templates/BlockContent';
import DataTable from '../../../components/templates/DataTable';
import Header from '../../../components/templates/Header';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { safeSessionStorageValue } from '../../../utils/sessionStorageUtils';
import useNavigation from '../../../utils/useNavigation';
import useNewLineFocus from '../../../utils/useNewLineFocus';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import SearchTextButton from '../../_global/filter/SearchTextButton';
import type { FilterConfiguration } from '../../_global/filter/useFilterSessionStorage';
import HeaderStatic from '../../_global/HeaderStatic';
import StoreTextInputField from '../../_global/input/StoreTextInputField';
import { searchFilterFunction } from '../../_global/listFilterFunctions';
import type { NavigationFilter } from '../../_global/navigationUtils';
import { getNavigationPayload } from '../../_global/navigationUtils';
import { share } from '../../_global/shareUtils';
import TopBar from '../../_global/topBar/TopBar';
import useFilterAndSort, { buildStringColumnComparatorHandler } from '../../_global/useFilterAndSort';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: spacingRem.s,
    paddingBottom: spacingRem.xs,
  },
}, 'integrationHome');

const IntegrationHome: FunctionComponent = () => {
  const classes = useStyles();

  const store = useStore();

  const navigation = useNavigation<NavigationFilter>();

  const filterId = Integration;
  const [newLineFocus, setNewLineFocus] = useNewLineFocus();
  const { generatePageList, doSort, sortCriteria, forceShowId } = useFilterAndSort(
    filterId,
    store.getObject(Integration).navigateBack<IntegrationStoreObject>(Class_Instances),
    searchFilterFunction(store, safeSessionStorageValue<FilterConfiguration | undefined>(filterId)?.nameSearch, [Integration_Name]),
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case Integration_Name:
          case Integration_Description:
            return buildStringColumnComparatorHandler(key, direction);
          default:
            return undefined;
        }
      },
      initial: { key: Integration_Name, direction: TableSortDirection.asc },
    }
  );

  const createIntegration = () => {
    const id = store.createObject({ [Instance_Of]: Integration });
    setNewLineFocus(id);
    forceShowId(id);
  };

  const { list, pagination } = generatePageList(25);

  return (
    <BaseLayout
      topBar={(<TopBar />)}
      header={(
        <Header
          firstLine={(
            <HeaderStatic
              text={i18n`Integration`}
              newItem={(<IconOnlyButton onClick={createIntegration} iconName={IconName.add} tooltip={i18n`Create`} />)}
              actions={[
                (<Link key="api" title={i18n`Access API documentation`} to="/docs/api" openInNewTab />),
                (<Link key="api_types" title={i18n`Access Read API documentation`} to="/docs/api/types" openInNewTab />),
              ]}
            />
          )}
        />
      )}
      content={(
        <VerticalBlock compact>
          <BlockContent padded>
            <div className={classes.headerContainer}>
              <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
                <SearchTextButton element={filterId} placeholder={i18n`Search`} />
              </SizeContextProvider>
            </div>
          </BlockContent>
          <DataTable
            list={list}
            pagination={pagination}
            getNavigationPayload={({ id }) => getNavigationPayload(navigation, id, `/settings/integration/${id}`)}
            doSort={doSort}
            sortCriteria={sortCriteria}
            columnsDefinition={[
              {
                propertyId: Integration_Name,
                name: i18n`Name`,
                sortable: true,
                focusable: true,
                cellRender: ({ id, [Integration_Name]: name }, focusOnMount) => (
                  <StoreTextInputField
                    initialValue={name}
                    focusOnMount={focusOnMount}
                    onSubmit={(newName) => {
                      store.updateObject<IntegrationRaw>(id, { [Integration_Name]: newName });
                    }}
                  />
                ),
                openButton: () => true,
              },
              {
                propertyId: Integration_Description,
                name: i18n`Description`,
                sortable: true,
                focusable: true,
                cellRender: ({ id, [Integration_Description]: description }, focusOnMount) => (
                  <StoreTextInputField
                    initialValue={description}
                    focusOnMount={focusOnMount}
                    onSubmit={(newDescription) => {
                      store.updateObject<IntegrationRaw>(id, { [Integration_Description]: newDescription });
                    }}
                  />
                ),
              },
            ]}
            linesActions={({ id }) => [
              {
                key: 'share',
                icon: IconName.link,
                name: i18n`Copy link`,
                onClick: () => {
                  share(store, getNavigationPayload(navigation, id, `/settings/integration/${id}`));
                },
              },
              {
                key: 'delete',
                name: i18n`Delete`,
                icon: IconName.delete,
                onClick: () => {
                  store.deleteObject(id);
                },
                danger: true,
              },
            ]}
            newItemIcon={IconName.add}
            newItemTitle={i18n`Create`}
            onNewItem={createIntegration}
            newLineFocus={newLineFocus.current}
          />
        </VerticalBlock>
      )}
    />
  );
};

export default IntegrationHome;
