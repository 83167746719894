import type { ObjectStoreWithTimeseries, TimeRange, TimeseriesValue } from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import { dateFormats, filterNullOrUndefined, formatDisplayDate, isFiniteNumber, numberType } from 'yooi-utils';
import { asImport, CommonAsType } from '../../../../common/fields/commonPropertyType';
import type { AsPropertyBusinessRuleRegistration } from '../../../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../../../common/typeErrorUtils';
import { preventComputedFieldUpdate, validateFieldIdAsProperty, validateIntegrationOnlyPropertyUpdate } from '../../../common/commonFieldUtils';
import { registerField } from '../../../module';
import type { DimensionsMapping, ResolutionStack } from '../../../utils';
import { buildDimensionalId, isValueResolutionOfType, ParsedDimensionType, parseDimensionMapping, resolveFieldValue } from '../../../utils';
import { rhythmicTimeseriesOf } from '../../../utils/formula/timeseriesFunctions';
import type { TimeseriesNumberField } from '../../types';
import { getTimeseriesExportColumnsHeaders } from '../timeseriesFieldsExportUtils';
import type { TimeseriesFieldHandler } from '../timeseriesFieldsUtils';

const allowConceptFieldAsNumberTimeseries: AsPropertyBusinessRuleRegistration = (_, propertyId) => (__, { timeseries }) => {
  if (timeseries?.[propertyId]) {
    const { values } = timeseries?.[propertyId] ?? {};

    if (values === undefined || Object.values(values).every((value) => isFiniteNumber(value) || value === null)) {
      return { rule: 'field.numberTimeseries.allowNumberTimeseries', status: ValidationStatus.ACCEPTED };
    } else {
      return { rule: 'field.numberTimeseries.rejectNotNumberTimeseries', status: ValidationStatus.REJECTED };
    }
  }
  return undefined;
};

const isTimeseriesValueNumber = (value: unknown): value is TimeseriesValue<number | null | undefined> => {
  if (value === null || typeof value !== 'object') {
    return false;
  } else if (!Object.keys(value).every((key) => ['time', 'value'].includes(key)) || !['time', 'value'].every((key) => Object.keys(value).includes(key))) {
    return false;
  } else if (typeof (value as { time: unknown }).time !== 'number') {
    return false;
  } else if ((value as { value: unknown }).value !== null && (value as { value: unknown }).value !== undefined && typeof (value as { value: unknown }).value !== 'number') {
    return false;
  } else {
    return true;
  }
};

const getValueResolution = (
  objectStore: ObjectStoreWithTimeseries,
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
  resolutionStack?: ResolutionStack,
  timeRange?: TimeRange
) => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack, timeRange, true);
  if (isValueResolutionOfType(
    valueResolution,
    (value): value is TimeseriesValue<number | null | undefined>[] | undefined => value === undefined || (Array.isArray(value) && value.every(isTimeseriesValueNumber))
  )) {
    return valueResolution;
  } else {
    return {
      value: [],
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['TimeseriesValue', 'undefined'], typeof valueResolution.value),
      getDisplayValue: () => [],
      isTimeseries: valueResolution.isTimeseries,
    };
  }
};

export const timeseriesNumberFieldHandler: TimeseriesFieldHandler<TimeseriesNumberField, number> = registerField({
  model: {
    label: 'TimeseriesNumberField',
    title: 'Numbers Timeseries',
    withApiAlias: true,
    properties: [
      { label: 'Decimals', as: CommonAsType.number, supportLocalOverride: true },
      { label: 'DefaultPeriod', as: CommonAsType.PeriodicityType, supportLocalOverride: true },
      { label: 'Cumulative', as: CommonAsType.boolean, supportLocalOverride: true },
      { label: 'InvalidColor', as: CommonAsType.string, supportLocalOverride: true },
      { label: 'MinValue', as: asImport('NumberColorStepValue', 'modules/conceptModule/moduleType'), supportLocalOverride: true },
      { label: 'MaxValue', as: asImport('NumberColorStepValue', 'modules/conceptModule/moduleType'), supportLocalOverride: true },
      { label: 'RangeValue', as: asImport('NumberColorStepsValue', 'modules/conceptModule/moduleType', true), supportLocalOverride: true },
      { label: 'Unit', as: CommonAsType.string, supportLocalOverride: true },
    ],
    asPropertyBusinessRules: [
      allowConceptFieldAsNumberTimeseries,
      validateIntegrationOnlyPropertyUpdate('timeseriesNumberField'),
      validateFieldIdAsProperty('timeseriesNumberField'),
      preventComputedFieldUpdate('timeseriesNumberField'),
    ],
  },
  handler: (objectStore, fieldId, configurationHandler) => {
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => {
      const values = getValueResolution(objectStore, fieldId, dimensionsMapping).value;
      return values?.map((v) => `${formatDisplayDate(new Date(v.time), dateFormats.timestamp)};${v.value}`).join(',');
    };

    return {
      describe: () => ({ hasData: true, returnType: numberType, timeseriesMode: 'explicit' }),
      restApi: {
        returnTypeSchema: { type: 'array', items: { type: 'object', properties: { time: { type: 'number' }, value: { type: 'number' } } } },
        formatValue: (value) => value,
      },
      getStoreValue: (dimensionsMapping) => {
        const parsedDimensions = parseDimensionMapping(dimensionsMapping);
        if (parsedDimensions.type === ParsedDimensionType.MonoDimensional) {
          return objectStore.getTimeseries<number | undefined>(parsedDimensions.objectId, fieldId);
        } else {
          return objectStore.getTimeseries<number | undefined, string[]>(buildDimensionalId(dimensionsMapping), fieldId);
        }
      },
      getValueWithoutFormula: (dimensionsMapping, _, timeRange) => {
        const parsedDimensions = parseDimensionMapping(dimensionsMapping);
        if (parsedDimensions.type === ParsedDimensionType.MonoDimensional) {
          return objectStore.getTimeseries<number | undefined>(parsedDimensions.objectId, fieldId, timeRange);
        } else if (Object.values(parsedDimensions.dimensionsMapping).filter(filterNullOrUndefined).length === 0) {
          return [];
        } else {
          return objectStore.getTimeseries<number | undefined, string[]>(buildDimensionalId(dimensionsMapping), fieldId, timeRange);
        }
      },
      getValueResolution: (dimensionsMapping, resolutionStack, timeRange) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack, timeRange),
      resolvePathStepConfiguration: () => ({
        hasData: true,
        timeseriesMode: 'explicit',
        getTimeseriesResolutionType: () => rhythmicTimeseriesOf(numberType),
        resolveTimeseries: (dimensionsMappings, _, resolutionStack, timeRange) => {
          const { error, value } = getValueResolution(objectStore, fieldId, dimensionsMappings, resolutionStack, timeRange);
          if (error) {
            throw error;
          } else {
            return value;
          }
        },
      }),
      updateValue: (dimensionsMapping, value) => {
        const parsedDimension = parseDimensionMapping(dimensionsMapping);
        const updateId = parsedDimension.type === ParsedDimensionType.MonoDimensional ? parsedDimension.objectId : buildDimensionalId(dimensionsMapping);
        switch (value.type) {
          case 'value': {
            objectStore.updateTimeValue(updateId, fieldId, value.time, value.value);
            break;
          }
          case 'delete': {
            objectStore.deleteTimeValue(updateId, fieldId, value.time);
            break;
          }
          case 'truncate': {
            objectStore.truncateRange(updateId, fieldId, value.from, value.to);
            break;
          }
          case 'move': {
            const valueToMove = objectStore.getTimeseries(updateId, fieldId, { from: value.fromTime, to: value.fromTime })?.at(0)?.value;
            if (valueToMove) {
              objectStore.deleteTimeValue(updateId, fieldId, value.fromTime);
              objectStore.updateTimeValue(updateId, fieldId, value.toTime, valueToMove);
            }
          }
        }
      },
      isEmpty: (dimensionsMapping) => getValueResolution(objectStore, fieldId, dimensionsMapping).value?.length === 0,
      isSaneValue: () => ({ isValid: true }),
      getValueAsText,
      getExportColumnHeaders: getTimeseriesExportColumnsHeaders<TimeseriesValue<number | undefined | null>>(
        (dimensionMapping, timeRange) => getValueResolution(objectStore, fieldId, dimensionMapping, undefined, timeRange).value
      ),
      getExportValue: (dimensionsMapping, configuration) => {
        if (!configuration?.time) {
          return { format: 'number', value: undefined };
        }
        const { value } = getValueResolution(objectStore, fieldId, dimensionsMapping, undefined, { from: configuration.time, to: configuration.time });
        const timeRelativeValue = value?.find(({ time }) => time === configuration.time)?.value ?? undefined;
        const timeseriesNumberField = configurationHandler.resolveConfigurationWithOverride(dimensionsMapping);
        return { format: 'number', value: timeRelativeValue, decimal: timeseriesNumberField.decimals, unit: timeseriesNumberField.unit };
      },
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else {
            return undefined;
          }
        },
      }),
      filterConditions: undefined,
    };
  },
  historyEventProducer: (_, fieldId) => ({
    timeseries: {
      isImpacted: ({ timeseries }) => timeseries !== undefined && Object.keys(timeseries).includes(fieldId),
      formatValue: (value) => ({ value, version: 1 }),
      areValuesEquals: (value1, value2) => value1?.value === value2?.value,
    },
  }),
});
