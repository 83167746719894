import { equals } from 'ramda';
import type { FormulaType } from 'yooi-utils';
import { anyType } from 'yooi-utils';
import type { GetDslFieldHandler, UpdateOperationHandlers } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import { preventComputedFieldUpdate, validateFieldIdAsProperty, validateIntegrationOnlyPropertyUpdate } from '../common/commonFieldUtils';
import { registerField } from '../module';
import type { PathStep } from '../moduleType';
import type { DimensionsMapping, MultipleParameterValue, ParametersMapping, ResolutionStack, SingleParameterValue } from '../utils';
import {
  buildDimensionalId,
  createValuePathResolver,
  isSingleValueResolution,
  isValueResolutionOfType,
  ParsedDimensionType,
  parseDimensionMapping,
  resolveFieldValue,
} from '../utils';
import type { IconField } from './types';

export interface ColoredIcon {
  icon: string,
  color: string,
}

interface IconUpdateOperationTypes {
  INITIALIZE: { type: 'value', value: ColoredIcon | undefined } | { type: 'path', path: PathStep[] },
  REPLACE: { type: 'value', value: ColoredIcon | undefined } | { type: 'path', path: PathStep[] },
  CLEAR: undefined,
}

export const iconType: FormulaType = {
  name: 'icon',
  equals: (type) => type === iconType,
  isAssignableFrom: (type) => type === iconType || type === anyType,
};

const isColoredIconOrUndefined = (value: unknown): value is ColoredIcon | undefined => (
  value === undefined
  || (
    typeof value === 'object'
    && value !== null
    && Object.keys(value).length === 2
    && Object.keys(value).every((key) => ['icon', 'color'].includes(key))
    && typeof (value as { icon: unknown }).icon === 'string'
    && typeof (value as { color: unknown }).color === 'string'
  )
);

type IconFieldHandler = GetDslFieldHandler<
  IconField,
  ColoredIcon | undefined,
  ColoredIcon | null,
  ColoredIcon | undefined,
  ColoredIcon | undefined,
  ColoredIcon | undefined,
  undefined,
  IconUpdateOperationTypes,
  undefined,
  undefined
>;

export const iconFieldHandler: IconFieldHandler = registerField({
  model: {
    label: 'IconField',
    title: 'Icon',
    withApiAlias: true,
    asPropertyBusinessRules: [
      validateIntegrationOnlyPropertyUpdate('iconField'),
      validateFieldIdAsProperty('iconField'),
      preventComputedFieldUpdate('iconField'),
    ],
  },
  handler: (objectStore, fieldId) => {
    const getStoreValue = (dimensionsMapping: DimensionsMapping): ColoredIcon | undefined => {
      const parsedMapping = parseDimensionMapping(dimensionsMapping);
      if (parsedMapping.type === ParsedDimensionType.MonoDimensional) {
        return objectStore.getObject(parsedMapping.objectId)[fieldId] as ColoredIcon | undefined;
      } else {
        return objectStore.getObject(buildDimensionalId(dimensionsMapping))[fieldId] as ColoredIcon | undefined;
      }
    };

    const getValueResolution = (dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack) => {
      const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
      if (isValueResolutionOfType(valueResolution, isColoredIconOrUndefined)) {
        return valueResolution;
      } else {
        return {
          value: undefined,
          isComputed: valueResolution.isComputed,
          error: valueResolution.error ?? new ResolutionTypeError(['ColoredIcon', 'undefined'], typeof valueResolution.value),
          getDisplayValue: () => undefined,
          isTimeseries: false,
        };
      }
    };

    const extractOperationValue = (
      parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
      operationValue: { type: 'value', value: ColoredIcon | undefined } | { type: 'path', path: PathStep[] }
    ): ColoredIcon | undefined => {
      if (operationValue.type === 'path') {
        const resolution = createValuePathResolver(objectStore, parametersMapping).resolvePathValue(operationValue.path);
        return isSingleValueResolution(resolution) && isColoredIconOrUndefined(resolution.value) ? resolution.value : undefined;
      } else {
        return operationValue.value;
      }
    };

    return {
      describe: () => ({ hasData: true, returnType: iconType, timeseriesMode: 'none' }),
      restApi: {
        returnTypeSchema: { type: 'object', nullable: true, required: ['icon', 'color'], properties: { icon: { type: 'string' }, color: { type: 'string' } } },
        formatValue: (value) => value ?? undefined,
      },
      getStoreValue,
      getValueWithoutFormula: getStoreValue,
      getValueResolution,
      resolvePathStepConfiguration: () => ({
        hasData: true,
        timeseriesMode: 'none',
        getValueResolutionType: () => iconType,
        resolveValue: (dimensionsMapping, _, resolutionStack) => {
          const { error, value } = getValueResolution(dimensionsMapping, resolutionStack);
          if (error) {
            throw error;
          } else {
            return value;
          }
        },
      }),
      updateValue: (dimensionsMapping, value) => {
        const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
        if (parsedDimensionMapping.type === ParsedDimensionType.MonoDimensional) {
          objectStore.updateObject(parsedDimensionMapping.objectId, { [fieldId]: value });
        } else {
          objectStore.updateObject(buildDimensionalId(dimensionsMapping), { [fieldId]: value });
        }
      },
      isEmpty: (dimensionsMapping) => getValueResolution(dimensionsMapping).value === undefined,
      getExportColumnHeaders: (configuration, fieldLabel) => ({
        columnsNumber: 1,
        getHeaders: () => [{ format: 'string', value: fieldLabel }],
        getColumnConfiguration: () => configuration,
      }),
      getExportValue: (dimensionsMapping) => {
        const { value } = getValueResolution(dimensionsMapping);
        return { format: 'string', value: value ? `${value?.icon};${value.color}` : undefined };
      },
      isSaneValue: () => ({ isValid: true }),
      filterConditions: undefined,
      updateOperationHandlers: {
        INITIALIZE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            const fieldHandler = iconFieldHandler(objectStore, fieldId);
            if (fieldHandler.getStoreValue(dimensionsMapping) !== undefined) {
              return;
            }
            fieldHandler.updateValue(dimensionsMapping, extractOperationValue(parametersMapping, value) ?? null);
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: undefined },
        },
        REPLACE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            iconFieldHandler(objectStore, fieldId).updateValue(dimensionsMapping, extractOperationValue(parametersMapping, value) ?? null);
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: undefined },
        },
        CLEAR: {
          applyOperation: (dimensionsMapping) => {
            iconFieldHandler(objectStore, fieldId).updateValue(dimensionsMapping, null);
          },
          sanitizeOperation: () => undefined,
        },
      } satisfies UpdateOperationHandlers<IconUpdateOperationTypes>,
    };
  },
  historyEventProducer: ({ getObjectOrNull }, fieldId) => ({
    value: {
      collectImpactedInstances: ({ id, properties }) => {
        if (
          properties?.[fieldId] !== undefined // A value is in the update for the current field
          || (properties === null && getObjectOrNull(id)?.[fieldId] !== undefined) // Object is deleted and store had a value for the field
        ) {
          return [id];
        } else {
          return [];
        }
      },
      getValue: (id) => ({ value: (getObjectOrNull(id)?.[fieldId] as ColoredIcon | undefined) ?? null, version: 1 }),
      areValuesEquals: (value1, value2) => equals(value1?.value, value2?.value),
    },
  }),
});
