import type { FunctionComponent } from 'react';
import type { DimensionsMapping } from 'yooi-modules/modules/conceptModule';
import { canWrite, isEmbeddedAsIntegrationOnly, numberFieldHandler, ParsedDimensionType, parseDimensionMapping } from 'yooi-modules/modules/conceptModule';
import { isFiniteNumber } from 'yooi-utils';
import NumberPicker from '../../../../components/inputs/NumberPicker';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import useAcl from '../../../../store/useAcl';
import useActivity from '../../../../store/useActivity';
import useStore from '../../../../store/useStore';
import useUpdateActivity from '../../../../store/useUpdateActivity';
import i18n from '../../../../utils/i18n';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';
import { formatFieldResolutionErrorForUser } from '../../errorUtils';
import { getInstanceMaxMinValues, TickResolutionStatus } from '../../fieldUtils';
import { getLastUpdateBy } from '../../historyUtils';
import InCompositeInput from '../../input/InCompositeInput';
import StoreNumberPickerInput from '../../input/StoreNumberPickerInput';
import { getNumberRangeValues } from './numberFieldUtils';

interface NumberFieldRendererProps {
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
  readOnly: boolean,
  focusOnMount: boolean,
  initialValue?: number | undefined,
  onSubmit?: (value: number | null) => void,
}

const NumberFieldRenderer: FunctionComponent<NumberFieldRendererProps> = ({ fieldId, dimensionsMapping, readOnly, focusOnMount, initialValue, onSubmit }) => {
  const store = useStore();
  const { canWriteObject } = useAcl();

  const activity = useActivity();
  const updateActivity = useUpdateActivity();

  const usageVariant = useUsageContext();

  const parsedDimension = parseDimensionMapping(dimensionsMapping);

  const fieldHandler = numberFieldHandler(store, fieldId);
  const configuration = parsedDimension.type === ParsedDimensionType.MonoDimensional
    ? fieldHandler.resolveConfigurationWithOverride(dimensionsMapping)
    : fieldHandler.resolveConfiguration();

  const isReadOnly = readOnly
    || configuration.integrationOnly
    || Boolean(configuration.formula)
    || !canWrite(dimensionsMapping, canWriteObject)
    || (parsedDimension.type === ParsedDimensionType.MonoDimensional && isEmbeddedAsIntegrationOnly(store.getObject(parsedDimension.objectId)));

  const ticks = getNumberRangeValues(store, fieldId, parsedDimension.type === ParsedDimensionType.MonoDimensional ? parsedDimension.objectId : undefined);

  const conceptId = parsedDimension.type === ParsedDimensionType.MonoDimensional ? parsedDimension.objectId : undefined;
  const { min, max } = getInstanceMaxMinValues(store, fieldId, conceptId, {});

  const isEditing = parsedDimension.type === ParsedDimensionType.MonoDimensional ? activity.listEditor(parsedDimension.objectId, fieldId).length > 0 : false;

  let component;
  if (onSubmit) {
    component = (
      <InCompositeInput<number | undefined>
        initialValue={initialValue}
        setInputValue={(v) => onSubmit(v ?? null)}
      >
        {({ value, onChange, ...props }) => (
          <NumberPicker
            value={value ?? undefined}
            onChange={(newValue) => onChange(!isFiniteNumber(newValue) ? undefined : Number(newValue))}
            {...props}
            min={min?.status === TickResolutionStatus.Resolved ? min : undefined}
            ticks={ticks}
            max={max?.status === TickResolutionStatus.Resolved ? max : undefined}
            invalidColor={configuration.invalidColor}
            decimals={configuration.decimals}
            unit={configuration.unit}
            readOnly={isReadOnly}
            withProgress
            onEditionStart={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => updateActivity.onEnterEdition(parsedDimension.objectId, fieldId) : undefined}
            onEditionStop={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => updateActivity.onExitEdition(parsedDimension.objectId, fieldId) : undefined}
            isEditing={isEditing}
            placeholder={i18n`Add number`}
            focusOnMount={focusOnMount}
          />
        )}
      </InCompositeInput>
    );
  } else {
    const valueResolution = fieldHandler.getValueResolution(dimensionsMapping);
    const error = valueResolution.error ? formatFieldResolutionErrorForUser(store, valueResolution.error, fieldId) : undefined;

    component = (
      <StoreNumberPickerInput
        initialValue={valueResolution.getDisplayValue() as number}
        onSubmit={(value) => {
          fieldHandler.updateValue(dimensionsMapping, typeof value === 'string' ? Number.parseInt(value, 10) : value);
        }}
        min={min?.status === TickResolutionStatus.Resolved ? min : undefined}
        ticks={ticks}
        max={max?.status === TickResolutionStatus.Resolved ? max : undefined}
        invalidColor={configuration.invalidColor}
        decimals={configuration.decimals}
        unit={configuration.unit}
        readOnly={isReadOnly}
        inputError={error}
        withProgress
        onEditionStart={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => updateActivity.onEnterEdition(parsedDimension.objectId, fieldId) : undefined}
        onEditionStop={parsedDimension.type === ParsedDimensionType.MonoDimensional ? () => updateActivity.onExitEdition(parsedDimension.objectId, fieldId) : undefined}
        isEditing={isEditing}
        placeholder={i18n`Add number`}
        focusOnMount={focusOnMount}
        restingTooltip={
          parsedDimension.type === ParsedDimensionType.MonoDimensional && configuration.formula === undefined
            ? () => getLastUpdateBy(store, parsedDimension.objectId, fieldId, undefined)
            : undefined
        }
      />
    );
  }

  if (usageVariant !== UsageVariant.inTable) {
    return (
      <SpacingLine>
        {component}
      </SpacingLine>
    );
  } else {
    return component;
  }
};

export default NumberFieldRenderer;
