import type { Property } from 'csstype';
import type { FunctionComponent } from 'react';
import base from '../../../theme/base';
import makeStyles from '../../../utils/makeStyles';
import useTheme from '../../../utils/useTheme';

const useStyles = makeStyles((theme) => ({
  rectangle: {
    borderRadius: base.borderRadius.small,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.default,
  },
}), 'quadrantRectangle');

interface QuadrantRectangleProps {
  x: number,
  y: number,
  size: number,
  clickable: boolean,
  color?: string | undefined,
  cursor?: Property.Cursor | undefined,
  draggable?: boolean,
  selected: boolean,
  hovered?: boolean,
  isEditedByOtherUser?: boolean,
  onDoubleClick?: (() => void) | undefined,
}

const QuadrantRectangle: FunctionComponent<QuadrantRectangleProps> = ({
  x,
  y,
  size,
  clickable = false,
  color,
  cursor,
  draggable = false,
  selected = false,
  hovered = false,
  isEditedByOtherUser = false,
  onDoubleClick,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  let borderColor;
  if (isEditedByOtherUser || selected || hovered) {
    borderColor = theme.color.border.primary;
  } else if (color !== undefined) {
    borderColor = color;
  } else if (clickable) {
    borderColor = theme.color.border.dark;
  }

  return (
    <foreignObject width={size} height={size} transform={`translate (${x}, ${y})`}>
      <div
        className={classes.rectangle}
        style={{
          cursor,
          backgroundColor: selected || !clickable ? theme.color.background.primary.default : color ?? theme.color.background.neutral.muted,
          borderColor,
          width: size,
          height: size,
        }}
        draggable={draggable}
        onDoubleClick={onDoubleClick}
      />
    </foreignObject>
  );
};

export default QuadrantRectangle;
