import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';
import { Fragment, useLayoutEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import useResizeObserver from 'use-resize-observer';
import type {
  AssociationFieldStoreObject,
  ConceptStoreObject,
  KinshipRelationFieldStoreObject,
  ParametersMapping,
  WorkflowFieldStoreObject,
} from 'yooi-modules/modules/conceptModule';
import {
  FILTER_PARAMETER_CURRENT,
  FILTER_PARAMETER_LOGGED_USER,
  getConceptUrl,
  getFieldDimensionOfModelType,
  getInstanceLabelOrUndefined,
  GROUP_BY_PARAMETER,
  idFieldHandler,
  isEmbeddedAsIntegrationOnly,
} from 'yooi-modules/modules/conceptModule';
import { isEntryValidForInstance } from 'yooi-modules/modules/conceptModule/fields/workflowField';
import {
  AssociationField,
  Concept_FunctionalId,
  Concept_Name,
  Concept_SwimlaneRank,
  ConceptDefinition_Color,
  ConceptDefinition_SwimlaneColumnBy,
  ConceptDefinition_SwimlaneGroupBy,
  ConceptDefinition_SwimlaneProgress,
  ConceptFunctionalIdDimension,
  Field_IntegrationOnly,
  KinshipRelationField,
  Workflow_Transitions,
  WorkflowField,
  WorkflowField_Workflow,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import type { StoreObject } from 'yooi-store';
import type { DecoratedListEntry, WithNonUndefinedKeys } from 'yooi-utils';
import {
  compareNumber,
  compareProperty,
  compareRank,
  compareString,
  comparing,
  filterNullOrUndefined,
  isEqualDateRangeValue,
  joinObjects,
  pushUndefinedToEnd,
  ranker,
} from 'yooi-utils';
import Tooltip from '../../../../../components/atoms/Tooltip';
import Typo from '../../../../../components/atoms/Typo';
import type { ProgressFieldData } from '../../../../../components/charts/TimelineEntry';
import { heightInRem as entryHeightRem } from '../../../../../components/charts/TimelineEntry';
import CompositeField, { CompositeFieldVariants } from '../../../../../components/molecules/CompositeField';
import GroupPanel from '../../../../../components/molecules/GroupPanel';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import SpacedContainer from '../../../../../components/molecules/SpacedContainer';
import { TableSortDirection } from '../../../../../components/molecules/Table';
import BlockContent from '../../../../../components/templates/BlockContent';
import VerticalBlock from '../../../../../components/templates/VerticalBlock';
import useAcl from '../../../../../store/useAcl';
import useAuth from '../../../../../store/useAuth';
import useStore from '../../../../../store/useStore';
import base from '../../../../../theme/base';
import { Spacing, spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import { remToPx } from '../../../../../utils/sizeUtils';
import { formatOrUndef } from '../../../../../utils/stringUtils';
import useNavigation from '../../../../../utils/useNavigation';
import { SessionStorageKeys, useSessionStorageState } from '../../../../../utils/useSessionStorage';
import { SizeContextProvider, SizeVariant } from '../../../../../utils/useSizeContext';
import useTheme from '../../../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../../../utils/useUsageContext';
import { getConceptProgressValue, resolveConceptColorValue } from '../../../conceptDisplayUtils';
import { DisplayedLine } from '../../../ConceptViewTopBar';
import { ConceptDefinitionFavoriteFiltersBar } from '../../../filter/FavoriteFiltersBar';
import type { FilterConfiguration } from '../../../filter/useFilterSessionStorage';
import { getFieldGroupByHandler } from '../../../groupByUtils';
import { getConceptFilters } from '../../../listFilterFunctions';
import {
  getChipOptions,
  getColorField,
  getGroupByValueResolver,
  getSwimlaneColumnByField,
  getSwimlaneGroupByField,
  getSwimlaneProgressField,
  listColorFieldOptions,
  listColumnByFieldOptions,
  listGroupByFieldOptions,
  listGroupByValueOptions,
  listProgressFieldOptions,
} from '../../../modelTypeUtils';
import ActivityIndicator from '../../../multiplayer/ActivityIndicator';
import type { NavigationFilter } from '../../../navigationUtils';
import type { SwimlaneConfiguration } from '../../../sessionStorageTypes';
import { getFieldHandler } from '../../FieldLibrary';
import ConceptBacklog from '../backlog/ConceptBacklog';
import ConceptSwimlaneTooltip from './ConceptSwimlaneTooltip';
import DraggableItem, { dragType } from './DraggableItem';
import DroppableZone from './DroppableZone';
import SwimlaneConstants from './SwimlaneConstants';

const activityOffsetRem = (SwimlaneConstants.conceptHeightRem - SwimlaneConstants.indicatorBox.height) / 2;

const MAX_COLUMN_NUMBER = 20;

const isEqualValue = (fromValue: Record<string, unknown>, toValue: Record<string, unknown>) => (
  (toValue?.coloration === undefined || fromValue?.coloration === toValue?.coloration)
  && (toValue?.progress === undefined || fromValue?.progress === toValue?.progress)
  && (toValue?.columnBy === undefined || fromValue?.columnBy === toValue?.columnBy)
  && (toValue?.groupBy === undefined || fromValue?.groupBy === toValue?.groupBy)
  && isEqualDateRangeValue(fromValue, toValue)
);

const useStyles = makeStyles((theme) => ({
  buttonOptions: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.s,
  },
  panelLabel: {
    backgroundColor: theme.color.background.neutral.subtle,
    borderRadius: base.borderRadius.medium,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollContainer: {
    overflowX: 'auto',
  },
  marginScrollContainer: {
    marginRight: spacingRem.blockRightColumnSpacing,
    marginLeft: spacingRem.xxl,
  },
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gridColumnStart: 2,
    gap: spacingRem.s,
  },
}), 'conceptSwimlane');

interface SwimlaneEntry {
  id: string,
  label: { id?: string, name: string | undefined },
  rightText?: string | undefined,
  progress?: ProgressFieldData | undefined,
  rank: string,
  columnById: string | undefined,
  columnByIndex: number,
  groupById: string | undefined,
  groupByIndex: number,
  isDraggable: boolean,
  color: string | undefined,
}

interface ConceptSwimlaneProps {
  filterKey: string,
  generateList: () => ConceptStoreObject[],
  conceptDefinitionId: string,
  readOnly?: boolean,
  fieldId?: string,
  displayedLine?: DisplayedLine,
  navigationFilters?: NavigationFilter,
}

const ConceptSwimlane: FunctionComponent<ConceptSwimlaneProps> = ({
  filterKey,
  generateList,
  conceptDefinitionId,
  readOnly = false,
  fieldId,
  displayedLine,
  navigationFilters,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const { loggedUserId } = useAuth();

  const navigation = useNavigation<NavigationFilter>();

  const [containerWidth, setContainerWidth] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setContainerWidth(containerRef.current?.offsetWidth ?? 0); // initialized containerRef in a layout effect to avoid useResizeObserver flickering on first render
  }, []);
  const { ref } = useResizeObserver({
    onResize: (h) => {
      if (containerWidth !== h.width) { // we know it's bad but this avoid a re render as the state is set outside react cycle
        setContainerWidth(h.width ?? 0);
      }
    },
  });

  const list = generateList();
  const entryTotalHeightRem = entryHeightRem + SwimlaneConstants.entryMarginTopBottomRem;
  const xAxisHeightPx = remToPx(SwimlaneConstants.xAxisHeightRem);
  const bottomMarginPx = remToPx(4);
  const topMarginPx = remToPx(1);
  const { canWriteObject, canOverrideWorkflow } = useAcl();
  const showOptions = displayedLine === DisplayedLine.options;
  const showFilters = displayedLine === DisplayedLine.filters;

  const canDrop = (field: StoreObject | undefined, itemId: string, previousValue: string | undefined, newValue: string | undefined) => {
    if (
      (isInstanceOf<WorkflowFieldStoreObject>(field, WorkflowField))
      && (newValue === undefined || ((field?.navigateOrNull(WorkflowField_Workflow)?.navigateBack(Workflow_Transitions) ?? []).length > 0
        && !canOverrideWorkflow(itemId) && newValue !== previousValue))
    ) {
      return false;
    } else if (field && isInstanceOf<WorkflowFieldStoreObject>(field, WorkflowField) && !isEntryValidForInstance(store, field.id, newValue, {
      [FILTER_PARAMETER_CURRENT]: { type: 'single', id: itemId },
      [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: loggedUserId },
    })) {
      return false;
    } else if (isInstanceOf<KinshipRelationFieldStoreObject>(field, KinshipRelationField) && newValue !== previousValue) {
      return false;
    } else if (isInstanceOf<AssociationFieldStoreObject>(field, AssociationField) && newValue !== previousValue) {
      return false;
    } else {
      return true;
    }
  };

  const [filtersConfiguration] = useSessionStorageState<FilterConfiguration | undefined>(filterKey, undefined);

  const [swimlaneConfig, updateSwimlaneConfig] = useSessionStorageState<SwimlaneConfiguration | undefined>(`${SessionStorageKeys.swimlaneConfig}_${filterKey}`, undefined);
  const doUpdateSwimlaneConfig = <Key extends keyof SwimlaneConfiguration>(key: Key, value: SwimlaneConfiguration[Key]) => {
    const config = swimlaneConfig ? { ...swimlaneConfig } : {
      [ConceptDefinition_SwimlaneColumnBy]: undefined,
      [ConceptDefinition_SwimlaneGroupBy]: undefined,
      [ConceptDefinition_SwimlaneProgress]: undefined,
      [ConceptDefinition_Color]: undefined,
    };
    config[key] = value;
    updateSwimlaneConfig(config);
  };
  const swimlaneColumnByField = getSwimlaneColumnByField(store, conceptDefinitionId, swimlaneConfig, undefined, false);
  const swimlaneColumnByValueResolver = swimlaneColumnByField ? getGroupByValueResolver(store, swimlaneColumnByField.id) : undefined;
  const swimlaneColumnByFieldDimensionId = swimlaneColumnByField ? getFieldDimensionOfModelType(store, swimlaneColumnByField.id, conceptDefinitionId) : undefined;
  const swimlaneColumnByFieldAllowDrop = !!swimlaneColumnByField && !swimlaneColumnByField[Field_IntegrationOnly];

  const swimlaneGroupByField = getSwimlaneGroupByField(store, conceptDefinitionId, swimlaneConfig, undefined, false);
  const swimlaneGroupByFieldDimensionId = swimlaneGroupByField ? getFieldDimensionOfModelType(store, swimlaneGroupByField.id, conceptDefinitionId) : undefined;
  const groupHandler = swimlaneGroupByField ? getFieldGroupByHandler(store, swimlaneGroupByField.id) : undefined;
  const groupComparatorHandler = swimlaneGroupByField ? getFieldHandler(store, swimlaneGroupByField.id)?.getComparatorHandler?.(TableSortDirection.asc) : undefined;
  const swimlaneGroupByFieldAllowDrop = !swimlaneGroupByField || !swimlaneGroupByField[Field_IntegrationOnly];

  const swimlaneProgressFieldId = getSwimlaneProgressField(store, conceptDefinitionId, swimlaneConfig, undefined, false)?.id;
  const colorFieldId = getColorField(store, conceptDefinitionId, swimlaneConfig, undefined, false)?.id;

  const groupByParametersMapping: ParametersMapping | undefined = swimlaneGroupByField ? {
    [GROUP_BY_PARAMETER]: {
      type: 'single' as const,
      id: swimlaneGroupByField.id,
    },
  } : undefined;

  const updatedNavigationFilters: NavigationFilter | undefined = navigationFilters
    ? joinObjects(navigationFilters, { globalParametersMapping: groupByParametersMapping })
    : {
      globalFilters: getConceptFilters(store, conceptDefinitionId, filtersConfiguration),
      globalParametersMapping: groupByParametersMapping,
    };

  const getByIndexes = (byField: StoreObject | undefined) => {
    if (!byField) {
      return { list: [], index: {} };
    }

    const byList = listGroupByValueOptions(store, byField, conceptDefinitionId);
    return { list: byList, index: Object.fromEntries(byList.map(({ id }, index) => [id, index])) };
  };

  const { list: columnByList, index: columnByIndex } = getByIndexes(swimlaneColumnByField);

  const groupValueMap = new Map<string, { value: unknown, label: string | undefined, color: string | undefined }>();
  const instanceGroupKeyMap = new Map<string, string | undefined>();
  if (groupHandler && groupComparatorHandler && swimlaneGroupByFieldDimensionId) {
    list.forEach((concept) => {
      const groupKey = groupHandler.getGroupKey(concept);
      if (groupKey !== undefined) {
        if (!groupValueMap.has(groupKey)) {
          groupValueMap.set(groupKey, {
            value: groupComparatorHandler.extractValue({ [swimlaneGroupByFieldDimensionId]: concept.id }),
            label: groupHandler.getGroupLabel(groupKey),
            color: groupHandler.getGroupColor(groupKey),
          });
        }
      }
      instanceGroupKeyMap.set(concept.id, groupKey);
    });
  }

  const groupByIndexMap = Array.from(groupValueMap.entries())
    .map(([key, { value }]) => ({ key, value }))
    .sort(groupComparatorHandler ? compareProperty('value', groupComparatorHandler.comparator) : compareProperty('key', compareString))
    .reduce((accumulator, { key }, index) => {
      accumulator.set(key, index);
      return accumulator;
    }, new Map<string, number>());

  const completeList = ranker.decorateList(
    list.map((concept): SwimlaneEntry => {
      const columnById = swimlaneColumnByValueResolver && swimlaneColumnByFieldDimensionId
        ? swimlaneColumnByValueResolver({ [swimlaneColumnByFieldDimensionId]: concept.id })?.id
        : undefined;

      const groupById = instanceGroupKeyMap.get(concept.id);

      const name = getInstanceLabelOrUndefined(store, concept, false);
      const id = idFieldHandler(store, Concept_FunctionalId).getValueAsText?.({ [ConceptFunctionalIdDimension]: concept.id });

      return ({
        id: concept.id,
        label: { id, name },
        columnById,
        columnByIndex: columnById ? columnByIndex[columnById] ?? Number.MAX_SAFE_INTEGER : columnByList.length,
        groupById,
        groupByIndex: swimlaneGroupByField && groupById ? groupByIndexMap.get(groupById) ?? Number.MAX_SAFE_INTEGER : groupByIndexMap.size,
        progress: getConceptProgressValue(store, concept.id, ConceptDefinition_SwimlaneProgress, swimlaneConfig, undefined, false) ?? undefined,
        color: resolveConceptColorValue(store, concept.id, swimlaneConfig, undefined, false),
        rank: concept[Concept_SwimlaneRank],
        isDraggable: !readOnly && canWriteObject(concept.id),
      });
    })
      .sort(compareProperty('rank', compareRank)),
    ({ rank }) => rank
  );

  const conceptMap = Object.fromEntries(completeList.map((entry) => [entry.item.id, entry.item]));

  const canDropItem = (droppedItemId: string, columnById: string | undefined, groupById: string | undefined) => {
    const droppedItem = conceptMap[droppedItemId];
    if (readOnly || !droppedItem) {
      return false;
    }

    // columnBy & groupBy target the same field but don't set the same value
    if (swimlaneColumnByField && swimlaneGroupByField && swimlaneColumnByField.id === swimlaneGroupByField.id && columnById !== groupById) {
      return false;
    }

    return swimlaneColumnByFieldAllowDrop
      && canDrop(swimlaneColumnByField, droppedItemId, droppedItem.columnById, columnById)
      && swimlaneGroupByFieldAllowDrop
      && canDrop(swimlaneGroupByField, droppedItemId, droppedItem.groupById, groupById);
  };

  const backlogList = completeList
    .filter(({ item: { columnById } }) => columnById === undefined)
    .map(({ item }) => item);
  const swimlaneList = completeList
    .filter((entry): entry is DecoratedListEntry<WithNonUndefinedKeys<SwimlaneEntry, 'columnById'>> => entry.item.columnById !== undefined)
    .filter(({ item: { columnByIndex: columnIndex, groupByIndex: groupIndex } }) => columnIndex < MAX_COLUMN_NUMBER && groupIndex !== Number.MAX_SAFE_INTEGER)
    .sort(compareProperty(
      'item',
      comparing<SwimlaneEntry>(compareProperty('groupByIndex', comparing<number | undefined>(pushUndefinedToEnd).thenComparing(compareNumber)))
        .thenComparing(compareProperty('columnByIndex', compareNumber))
        .thenComparing(compareProperty('rank', compareRank))
    ));

  const groups = swimlaneList.reduce<{
    groupById: string | undefined,
    groupByIndex: number,
    entriesPerColumnBy: Map<string, DecoratedListEntry<WithNonUndefinedKeys<SwimlaneEntry, 'columnById'>>[]>,
    maxNumberOfEntries: number,
  }[]>(
    (accumulator, entry) => {
      let lastIndex = accumulator[accumulator.length - 1];
      if (lastIndex?.groupByIndex !== entry.item.groupByIndex) {
        lastIndex = {
          groupById: entry.item.groupById,
          groupByIndex: entry.item.groupByIndex,
          entriesPerColumnBy: new Map([[entry.item.columnById, [entry]]]),
          maxNumberOfEntries: 1,
        };
        accumulator.push(lastIndex);
      } else {
        const columnByEntries = lastIndex.entriesPerColumnBy.get(entry.item.columnById);
        if (columnByEntries) {
          columnByEntries.push(entry);
          if (lastIndex.maxNumberOfEntries < columnByEntries.length) {
            lastIndex.maxNumberOfEntries = columnByEntries.length;
          }
        } else {
          lastIndex.entriesPerColumnBy.set(entry.item.columnById, [entry]);
        }
      }
      return accumulator;
    },
    []
  );

  // Undefined group is required, create it empty if missing
  if (groups.length === 0 || groups[groups.length - 1].groupById !== undefined) {
    groups.push({ groupById: undefined, groupByIndex: groupByIndexMap.size, entriesPerColumnBy: new Map(), maxNumberOfEntries: 0 });
  }

  const conceptNumberOfLongestColumn = groups.reduce((accumulator, { maxNumberOfEntries }) => accumulator + maxNumberOfEntries, 0);
  const timelineHeightPx = remToPx(conceptNumberOfLongestColumn * (entryTotalHeightRem + SwimlaneConstants.parentBottomMarginRem));
  const svgHeightPx = (timelineHeightPx || remToPx(5)) + xAxisHeightPx + bottomMarginPx + topMarginPx;

  const multiplayerIndicatorPropertyIds = [
    Concept_Name,
    swimlaneColumnByField?.id,
    swimlaneGroupByField?.id,
    colorFieldId,
    swimlaneProgressFieldId,
  ].filter(filterNullOrUndefined);

  const minColumnWidth = remToPx(SwimlaneConstants.minColumnWidthRem);
  const columnWidthPx = ((containerWidth - remToPx(SwimlaneConstants.activityWidthOffsetRem)) / columnByList.length) < minColumnWidth
    ? minColumnWidth
    : ((containerWidth - remToPx(SwimlaneConstants.activityWidthOffsetRem)) / columnByList.length);
  const conceptWidthPx = columnWidthPx - (2 * remToPx(SwimlaneConstants.conceptWidthPaddingRem));
  const labelWidthPx = columnWidthPx - remToPx(SwimlaneConstants.labelGapRem);
  const svgWidthPx = (columnByList.length * columnWidthPx) + remToPx(SwimlaneConstants.activityWidthOffsetRem);

  const computePanelHeight = (maxEntriesCount: number, isLast = false) => {
    let panelHeightPx = maxEntriesCount > 0 ? maxEntriesCount * remToPx(SwimlaneConstants.conceptHeightRem) + (maxEntriesCount - 1) * remToPx(SwimlaneConstants.conceptGapRem) : 0;
    // Add extra dropzone space for concepts without a group
    panelHeightPx = isLast && panelHeightPx === 0 ? remToPx(SwimlaneConstants.conceptHeightRem) : panelHeightPx;
    panelHeightPx = isLast ? panelHeightPx + remToPx(SwimlaneConstants.conceptHeightRem) : panelHeightPx;
    return panelHeightPx;
  };

  const isDragAndDropWithBacklogAllowed = !isInstanceOf<WorkflowFieldStoreObject>(swimlaneColumnByField, WorkflowField)
    && !isInstanceOf<KinshipRelationFieldStoreObject>(swimlaneColumnByField, KinshipRelationField);

  const [{ isOverBacklog }, dropRef] = useDrop<{ id: string, conceptId: string }, void, { isOverBacklog: boolean }>(() => ({
    accept: `${dragType.concept}-${conceptDefinitionId}-${fieldId}`,
    drop: ({ conceptId }) => {
      if (swimlaneColumnByField && canDropItem(conceptId, undefined, undefined)) {
        store.updateObject(conceptId, joinObjects(
          (
            swimlaneColumnByField && !isInstanceOf<KinshipRelationFieldStoreObject>(swimlaneColumnByField as StoreObject, KinshipRelationField)
              ? { [swimlaneColumnByField.id]: null } : {}
          ),
          ((
            swimlaneGroupByField
            && !isInstanceOf<KinshipRelationFieldStoreObject>(swimlaneGroupByField as StoreObject, KinshipRelationField)
            && !isInstanceOf<AssociationFieldStoreObject>(swimlaneGroupByField as StoreObject, AssociationField)
          ) ? { [swimlaneGroupByField.id]: null } : {})
        ));
      }
    },
    canDrop: ({ conceptId }) => canDropItem(conceptId, undefined, undefined),
    collect: (monitor) => ({
      isOverBacklog: monitor.isOver(),
    }),
  }), [swimlaneColumnByField, swimlaneColumnByField, canDropItem]);

  const handleSubmit = (paramId: string, values: { coloration?: string | null, progress?: number | null, columnBy?: string | null, groupBy?: string | null }) => {
    const { coloration, progress, columnBy, groupBy } = values;
    const param = store.getObject(paramId);
    const currentData = {
      coloration: colorFieldId ? param[colorFieldId] : undefined,
      progress: swimlaneProgressFieldId ? param[swimlaneProgressFieldId] : undefined,
      columnBy: swimlaneColumnByField ? param[swimlaneColumnByField.id] : undefined,
      groupBy: swimlaneGroupByField ? param[swimlaneGroupByField.id] : undefined,
    };

    if (!isEqualValue(currentData, values)) {
      store.updateObject(paramId, joinObjects(
        colorFieldId ? { [colorFieldId]: coloration } : {},
        swimlaneProgressFieldId ? { [swimlaneProgressFieldId]: progress } : {},
        swimlaneColumnByField && (swimlaneColumnByField.id !== colorFieldId) ? { [swimlaneColumnByField.id]: columnBy } : {},
        swimlaneGroupByField && (swimlaneGroupByField.id !== colorFieldId && swimlaneGroupByField.id !== swimlaneColumnByField?.id)
          ? { [swimlaneGroupByField.id]: groupBy } : {}
      ));
    }
  };

  const renderTooltip = (conceptId: string, editMode: boolean, currentAnchor: HTMLElement, handleClose: () => void): ReactElement | null => (
    <ConceptSwimlaneTooltip
      conceptId={conceptId}
      editMode={editMode && canWriteObject(conceptId) && !readOnly && !isEmbeddedAsIntegrationOnly(store.getObject(conceptId))}
      buttonView={editMode}
      currentAnchor={currentAnchor}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      filterId={filterKey}
      readOnly={readOnly}
      navigationFilters={updatedNavigationFilters}
    />
  );

  const computeLinePosition = (index: number) => ({ x1: index * columnWidthPx, y1: remToPx(SwimlaneConstants.lineGapRem), x2: index * columnWidthPx, y2: svgHeightPx });
  const renderLines = (index: number, isLast: boolean) => {
    const { x1, y1, x2, y2 } = computeLinePosition(index);
    if (!isLast) {
      return (
        <line
          xmlns="http://www.w3.org/2000/svg"
          x1={x1}
          y1={y1}
          x2={x2}
          y2={y2}
          stroke={theme.color.border.default}
          strokeWidth={remToPx(SwimlaneConstants.lineWidthRem)}
        />
      );
    } else {
      const { x1: x1Last, y1: y1Last, x2: x2Last, y2: y2Last } = computeLinePosition(index + 1);
      return (
        <>
          <line xmlns="http://www.w3.org/2000/svg" x1={x1} y1={y1} x2={x2} y2={y2} stroke={theme.color.border.default} strokeWidth="1" />
          <line
            xmlns="http://www.w3.org/2000/svg"
            x1={x1Last - remToPx(SwimlaneConstants.lineWidthRem)}
            y1={y1Last}
            x2={x2Last - remToPx(SwimlaneConstants.lineWidthRem)}
            y2={y2Last}
            stroke={theme.color.border.default}
            strokeWidth={remToPx(SwimlaneConstants.lineWidthRem)}
          />
        </>
      );
    }
  };

  const renderActivity = () => {
    if (swimlaneGroupByField || groups.length !== 1) {
      return null;
    }
    const { maxNumberOfEntries, entriesPerColumnBy } = groups[0];
    const columns = Array.from(entriesPerColumnBy.values());

    return Array.from(Array(maxNumberOfEntries).keys())
      .map((index) => {
        const elements = columns.filter((c) => index < c.length).map((c) => c[index].item.id);
        return (
          <foreignObject
            key={`activity-${elements.join('#')}`}
            transform={`translate(${remToPx(SwimlaneConstants.indicatorBox.leftOffset)}, ${index * (remToPx(SwimlaneConstants.conceptGapRem) + remToPx(SwimlaneConstants.conceptHeightRem))})`}
            width={remToPx(SwimlaneConstants.indicatorBox.width)}
            height={remToPx(SwimlaneConstants.indicatorBox.height)}
          >
            <ActivityIndicator propertyIds={multiplayerIndicatorPropertyIds} instanceIds={elements} />
          </foreignObject>
        );
      });
  };
  const options = (
    <>
      <Typo maxLine={1} color={theme.color.text.secondary}>{i18n`Column By`}</Typo>
      <SearchAndSelect
        computeOptions={() => listColumnByFieldOptions(store, conceptDefinitionId)}
        selectedOption={swimlaneColumnByField ? getChipOptions(store, swimlaneColumnByField.id) : undefined}
        onSelect={(value) => doUpdateSwimlaneConfig(ConceptDefinition_SwimlaneColumnBy, value?.id ?? null)}
      />
      <Typo maxLine={1} color={theme.color.text.secondary}>{i18n`Group by`}</Typo>
      <SearchAndSelect
        clearable
        computeOptions={() => listGroupByFieldOptions(store, conceptDefinitionId, true)}
        selectedOption={swimlaneGroupByField ? getChipOptions(store, swimlaneGroupByField.id) : undefined}
        onSelect={(value) => doUpdateSwimlaneConfig(ConceptDefinition_SwimlaneGroupBy, value?.id ?? null)}
      />
      <CompositeField
        variant={CompositeFieldVariants.button}
        width="41.2rem"
        headerLinesRenderers={[
          {
            id: 'title',
            render: () => (
              <div className={classes.buttonOptions}>
                <Typo>{i18n`Options`}</Typo>
              </div>
            ),
          },
        ]}
        getDropdownSectionDefinitions={() => [
          {
            id: 'main',
            lines: [
              {
                id: 'concept-progress-field',
                title: i18n`Progress`,
                render: (
                  <SearchAndSelect
                    clearable
                    computeOptions={() => listProgressFieldOptions(store, conceptDefinitionId)}
                    selectedOption={swimlaneProgressFieldId ? getChipOptions(store, swimlaneProgressFieldId) : undefined}
                    onSelect={(value) => doUpdateSwimlaneConfig(ConceptDefinition_SwimlaneProgress, value?.id ?? null)}
                  />
                ),
              },
              {
                id: 'concept-color-field',
                title: i18n`Color`,
                render: (
                  <SearchAndSelect
                    clearable
                    computeOptions={() => listColorFieldOptions(store, conceptDefinitionId)}
                    selectedOption={colorFieldId ? getChipOptions(store, colorFieldId) : undefined}
                    onSelect={(value) => doUpdateSwimlaneConfig(ConceptDefinition_Color, value?.id ?? null)}
                  />
                ),
              },
            ],
          },
        ]}
      />
    </>
  );
  return (
    <>
      <VerticalBlock asBlockContent compact>
        {showOptions && (
          <UsageContextProvider usageVariant={UsageVariant.inForm}>
            <SizeContextProvider sizeVariant={SizeVariant.small}>
              <div className={classes.optionContainer}>
                {options}
              </div>
            </SizeContextProvider>
          </UsageContextProvider>
        )}
        {showFilters && (
          <BlockContent padded>
            <ConceptDefinitionFavoriteFiltersBar filterKey={filterKey} conceptDefinitionId={conceptDefinitionId} />
          </BlockContent>
        )}
        <BlockContent fullWidth>
          <div
            ref={composeReactRefs<HTMLDivElement>(containerRef, ref)}
            className={classnames(classes.scrollContainer, classes.marginScrollContainer)}
          >
            {containerWidth > 0 && columnByList.length > 0 && (
              <svg preserveAspectRatio="xMinYMin meet" viewBox={`0 0 ${svgWidthPx} ${svgHeightPx}`} width={svgWidthPx} height={svgHeightPx}>
                <g transform={`translate(${Math.round(remToPx(SwimlaneConstants.activityWidthOffsetRem - SwimlaneConstants.labelHeightRem - SwimlaneConstants.lineGapRem))},0)`}>
                  <g transform={`translate(0, ${remToPx(SwimlaneConstants.svgHeightMarginRem + SwimlaneConstants.lineOffsetRem + SwimlaneConstants.labelHeightRem + SwimlaneConstants.conceptGroupWidthOffsetRem)})`}>
                    {groups.map((group, index) => {
                      const isLast = groups.length - 1 === index;
                      const noGroupMarginPx = isLast ? remToPx(SwimlaneConstants.conceptGapRem) : 0;
                      const panelHeightPx = computePanelHeight(group.maxNumberOfEntries, isLast);
                      const panelY = groups.slice(0, index).reduce((accumulator, g) => (
                        accumulator + computePanelHeight(g.maxNumberOfEntries) + remToPx(SwimlaneConstants.conceptGapRem)
                      ), 0);

                      return (
                        <Fragment key={group.groupById ?? 'undefined'}>
                          {group.groupById !== undefined ? (
                            <GroupPanel
                              panel={{
                                y1: panelY + remToPx(SwimlaneConstants.conceptMarginRem),
                                width: svgWidthPx,
                                height: panelHeightPx,
                                label: formatOrUndef(groupValueMap.get(group.groupById)?.label),
                                color: groupValueMap.get(group.groupById)?.color,
                              }}
                              labelMargin={SwimlaneConstants.labelToSwimlaneMargin * 2}
                            />
                          ) : null}
                          <g
                            key="column-drop-zone"
                            transform={`translate(${remToPx(SwimlaneConstants.labelHeightRem + SwimlaneConstants.lineGapRem)},${panelY + remToPx(SwimlaneConstants.conceptMarginRem)})`}
                          >
                            {columnByList.slice(0, MAX_COLUMN_NUMBER)
                              .map((columnBy, columnIndex) => (
                                <DroppableZone
                                  key={columnBy.id}
                                  labelWidthPx={columnWidthPx}
                                  svgHeightPx={panelHeightPx + noGroupMarginPx}
                                  transform={`translate(${columnWidthPx * columnIndex},0)`}
                                  type={`${dragType.concept}-${conceptDefinitionId}-${fieldId}`}
                                  onDrop={(droppedItemId) => {
                                    if (canDropItem(droppedItemId, columnBy.id, group.groupById)) {
                                      const column = group.entriesPerColumnBy.get(columnBy.id);
                                      store.updateObject(droppedItemId, joinObjects(
                                        (
                                          swimlaneColumnByField && !isInstanceOf<KinshipRelationFieldStoreObject>(swimlaneColumnByField as StoreObject, KinshipRelationField)
                                            ? { [swimlaneColumnByField.id]: columnBy.id } : {}
                                        ),
                                        ((
                                          swimlaneGroupByField
                                          && !isInstanceOf<AssociationFieldStoreObject>(swimlaneGroupByField, KinshipRelationField)
                                          && !isInstanceOf<AssociationFieldStoreObject>(swimlaneGroupByField, AssociationField)
                                        ) ? { [swimlaneGroupByField.id]: group.groupById ?? null } : {}),
                                        {
                                          [Concept_SwimlaneRank]: column ? column[column.length - 1].insertAfterRank() : undefined,
                                        }
                                      ));
                                    }
                                  }}
                                  canDrop={(droppedItemId) => canDropItem(droppedItemId, columnBy.id, group.groupById)}
                                  border
                                />
                              ))}
                          </g>
                          <g key="column-entries" transform={`translate(${remToPx(SwimlaneConstants.labelHeightRem + SwimlaneConstants.lineGapRem)},${panelY})`}>
                            {Array.from(group.entriesPerColumnBy.entries())
                              .map(([columnId, entries]) => (
                                <g key={columnId} transform={`translate(${remToPx(SwimlaneConstants.conceptWidthPaddingRem) + columnWidthPx * columnByIndex[columnId]},0)`}>
                                  {entries.map((entry, entryIndex) => {
                                    const lastDraggableItem = entries.length - 1 === index;
                                    return (
                                      <Fragment key={entry.item.id}>
                                        <DroppableZone
                                          labelWidthPx={conceptWidthPx}
                                          svgHeightPx={
                                            lastDraggableItem
                                              ? remToPx(SwimlaneConstants.conceptHeightRem) + 3
                                              : remToPx(SwimlaneConstants.conceptGapRem) + 2 + remToPx(SwimlaneConstants.conceptHeightRem)
                                          }
                                          transform={`translate(0,${entryIndex * remToPx(SwimlaneConstants.conceptGapRem + SwimlaneConstants.conceptHeightRem)})`}
                                          attachedConceptId={entry.item.id}
                                          type={`${dragType.concept}-${conceptDefinitionId}-${fieldId}`}
                                          onDrop={(droppedItemId) => {
                                            if (canDropItem(droppedItemId, columnId, group.groupById)) {
                                              store.updateObject(droppedItemId, joinObjects(
                                                (
                                                  swimlaneColumnByField
                                                  && !isInstanceOf<KinshipRelationFieldStoreObject>(swimlaneColumnByField as StoreObject, KinshipRelationField)
                                                    ? { [swimlaneColumnByField.id]: columnId } : {}
                                                ),
                                                (
                                                  swimlaneGroupByField
                                                  && !isInstanceOf<KinshipRelationFieldStoreObject>(swimlaneGroupByField as StoreObject, KinshipRelationField)
                                                  && !isInstanceOf<AssociationFieldStoreObject>(swimlaneGroupByField as StoreObject, AssociationField)
                                                    ? { [swimlaneGroupByField.id]: group.groupById ?? null } : {}
                                                ),
                                                { [Concept_SwimlaneRank]: entry.insertAfterRank() }
                                              ));
                                            }
                                          }}
                                          canDrop={(droppedItemId) => canDropItem(droppedItemId, columnId, group.groupById)}
                                          border={lastDraggableItem}
                                        />
                                        <DraggableItem
                                          index={entryIndex}
                                          columnIndex={0}
                                          columnWidthPx={columnWidthPx}
                                          groupHeight={0}
                                          colorationValue={entry.item.color}
                                          progress={entry.item.progress}
                                          conceptWidthPx={conceptWidthPx}
                                          renderTooltip={renderTooltip}
                                          concept={entry.item}
                                          multiplayerIndicatorPropertyIds={multiplayerIndicatorPropertyIds}
                                          activityFieldId={swimlaneColumnByField?.id}
                                          canDragItem={entry.item.isDraggable}
                                          type={`${dragType.concept}-${conceptDefinitionId}-${fieldId}`}
                                          onDoubleClick={(id) => {
                                            const conceptUrl = getConceptUrl(store, id);
                                            navigation.push(id, {
                                              pathname: conceptUrl,
                                              navigationFilters: updatedNavigationFilters,
                                            });
                                          }}
                                        />
                                      </Fragment>
                                    );
                                  })}
                                </g>
                              ))}
                          </g>
                        </Fragment>
                      );
                    })}
                  </g>
                  <g transform={`translate(${remToPx(SwimlaneConstants.labelHeightRem) + remToPx(SwimlaneConstants.lineGapRem)},0)`}>
                    {columnByList.map((columnBy, index) => (
                      <g key={columnBy.id} transform={`translate(0,${remToPx(SwimlaneConstants.svgHeightMarginRem)})`}>
                        <foreignObject
                          transform={`translate(${(columnWidthPx * index) + remToPx(SwimlaneConstants.labelGapRem / 2)},0)`}
                          width={labelWidthPx}
                          height={`${SwimlaneConstants.labelHeightRem}rem`}
                        >
                          <Tooltip title={columnBy.label}>
                            <div
                              className={classes.panelLabel}
                              style={{
                                width: labelWidthPx,
                                height: `${SwimlaneConstants.labelHeightRem}rem`,
                              }}
                            >
                              <SpacedContainer margin={{ x: Spacing.text }}>
                                <Typo color={columnBy.squareColor ?? theme.color.text.secondary} maxLine={1}>{columnBy.label}</Typo>
                              </SpacedContainer>
                            </div>
                          </Tooltip>
                        </foreignObject>
                      </g>
                    ))}
                    <g transform={`translate(0,${remToPx(SwimlaneConstants.svgHeightMarginRem) + remToPx(SwimlaneConstants.lineOffsetRem) + remToPx(SwimlaneConstants.labelHeightRem)})`}>
                      {columnByList.map((columnBy, index) => (
                        <Fragment key={columnBy.id}>
                          {renderLines(index, index === columnByList.length - 1)}
                        </Fragment>
                      ))}
                      {!swimlaneGroupByField ? (
                        <g transform={`translate(${remToPx(SwimlaneConstants.conceptWidthPaddingRem)},${remToPx(SwimlaneConstants.conceptGroupWidthOffsetRem) + remToPx(activityOffsetRem)})`}>
                          {renderActivity()}
                        </g>
                      ) : null}
                    </g>
                  </g>
                </g>
              </svg>
            )}
          </div>
        </BlockContent>
      </VerticalBlock>
      <ConceptBacklog
        title={i18n`Undefined ${formatOrUndef((swimlaneColumnByField ? getChipOptions(store, swimlaneColumnByField.id) : undefined)?.label)}`}
        list={backlogList}
        renderTooltip={renderTooltip}
        multiplayerPropertyIds={multiplayerIndicatorPropertyIds}
        readOnly={readOnly}
        isSwimlane
        conceptDefinitionId={conceptDefinitionId}
        fieldId={fieldId}
        isOverBacklog={isOverBacklog && isDragAndDropWithBacklogAllowed}
        dropRef={dropRef}
      />
    </>
  );
};

export default ConceptSwimlane;
