import type { ReactElement } from 'react';
import type { ExtractDslHandlerTypes, GenericGetDslFieldHandler } from 'yooi-modules/modules/common/fields/FieldModuleDslType';
import type { BlockFieldLayoutOption, WorkflowRelationDisplayType } from 'yooi-modules/modules/conceptLayoutModule';
import type {
  BackendFilterCondition,
  DimensionsMapping,
  FilterValue,
  FilterValueRaw,
  MultipleParameterDefinition,
  ParametersMapping,
  PathStep,
  SingleParameterDefinition,
} from 'yooi-modules/modules/conceptModule';
import type { Field_IntegrationOnly } from 'yooi-modules/modules/conceptModule/ids';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import type { StoreObject } from 'yooi-store';
import type { Comparator } from 'yooi-utils';
import type { IconName } from '../../../components/atoms/Icon';
import type { InlineCreationInline, InlineCreationTransactional } from '../../../components/molecules/inlineCreationTypes';
import type { TableSortDirection } from '../../../components/molecules/Table';
import type { ACLHandler } from '../../../store/useAcl';
import type { FrontObjectStore } from '../../../store/useStore';
import type { Theme } from '../../../theme/themeUtils';
import type { Option } from '../modelTypeUtils';
import type { BlockFieldDisplayStatus } from './_global/blockFieldUtils';
import type { EditionOptionSection, FieldEditionSection, FieldEditionSectionGroup } from './FieldEditionOptionType';
import type { GetFieldHandler } from './FieldLibrary';

export enum FieldEditionOptionMode {
  Field = 'Field',
  FieldDeveloperMode = 'FieldDeveloperMode',
  Override = 'Override',
  Widget = 'Widget',
  EverythingButFieldSpecific = 'EverythingButFieldSpecific',
}

export interface EditionHandler<ConfigurationState = Record<string, unknown>> {
  getValue: <Key extends keyof ConfigurationState> (id: Key) => NonNullable<ConfigurationState[Key]> | undefined,
  getValueOrDefault: <Key extends keyof ConfigurationState> (id: Key) => NonNullable<ConfigurationState[Key]> | undefined,
  updateValues: (update: Partial<ConfigurationState>) => void,
  reset: () => ConfigurationState,
  resetValue: (id: keyof ConfigurationState) => void,
  hasModification: () => boolean,
}

export const readOnlyEditionHandler = <ConfigurationState = Record<string, unknown>>(state: ConfigurationState): EditionHandler<ConfigurationState> => ({
  getValue: (id) => (state[id] ?? undefined),
  getValueOrDefault: (id) => (state[id] ?? undefined),
  updateValues: () => {},
  reset: () => state,
  resetValue: () => {},
  hasModification: () => false,
});

export enum FieldEditionVariant {
  composite = 'composite',
  page = 'page',
}

export interface FieldDefinitionHandler<DslFieldHandler extends GenericGetDslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration> {
  typeIcon: IconName,
  asWidget: boolean,
  getFieldTitle: (editionHandler: EditionHandler<ConfigurationState>, modelTypeId: string) => string,
  getFieldSubTitle?: (editionHandler: EditionHandler<ConfigurationState>, modelTypeId: string) => string | undefined,
  getFieldIcon?: (editionHandler: EditionHandler<ConfigurationState>) => IconName | undefined,
  onCreate?: (editionHandler: EditionHandler<ConfigurationState>) => string,
  onWidgetCreate?: () => string,
  isCreationEnabled?: (options: { modelTypeId: string }) => boolean,
  getEditionOptions: (options: {
    mode: FieldEditionOptionMode,
    modelTypeId: string,
    readOnly: boolean,
    dashboardParameterDefinitions?: SingleParameterDefinition[],
    editionHandler: EditionHandler<ConfigurationState>,
    isEdition?: boolean,
    variant: FieldEditionVariant,
  }) => (FieldEditionSection | FieldEditionSectionGroup)[],
  canCreate?: (editionHandler: EditionHandler<ConfigurationState>) => boolean,
  getTargetFields?: (fromType: string) => StoreObject[],
  inlineCreate?: (conceptDefinitionId: string, extraFieldOptions?: { [Field_IntegrationOnly]?: boolean }) => (InlineCreationInline | InlineCreationTransactional),
  getTypeLabel: () => string,
  getHandler: (fieldId: string) => GetFieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>,
  ofField: (fieldId: string) => {
    getIcon: () => IconName | { name: IconName, color: string, borderColor?: string },
    getTitle: () => string | undefined,
    getTypeLabel: () => string,
    getDocumentation: () => ({ value: string, isInline: boolean } | undefined),
    getInitialState: (conceptDefinitionId: string) => ConfigurationState,
    getInitialOverrideState?: (objectId: string) => ConfigurationState,
    submitFieldUpdate: (stateToSubmit: ConfigurationState, conceptDefinitionId: string) => void,
    submitFieldOverrideUpdate?: (objectId: string, stateToSubmit: ConfigurationState) => void,
    duplicateFieldDefinition?: (options: { parameterMap?: Record<string, string> }) => string,
  },
}

export interface ColumnDefinition {
  key?: string,
  propertyId: string,
  name?: string,
  sortable?: boolean,
  scrollOnMount?: boolean,
  focusable?: boolean,
  openButton?: () => boolean,
  tooltip?: string,
}

export type SetValue<Value> = (funOrValue: (Value | null | undefined) | ((current: Value) => (Value | null | undefined))) => void;

export interface RenderFilter<Value> {
  (
    value: Value,
    setValue: SetValue<Value>,
    parameterDefinitions: SingleParameterDefinition[],
    readOnly: boolean,
    path?: PathStep[],
    isSimple?: boolean,
    placeholder?: string,
  ): ReactElement | null,
}

export interface FrontFilterCondition<Value> {
  name: string,
  renderFilter?: RenderFilter<Value>,
}

export interface FieldComparatorHandler<Type> {
  comparator: Comparator<Type>,
  extractValue: (dimensionsMapping: DimensionsMapping, time?: number) => Type,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FilterValueFromType<T> = T extends BackendFilterCondition<any, infer V, any> ? FilterValue<V> : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RawFilterValueFromType<T> = T extends BackendFilterCondition<any, infer V, any> ? FilterValueRaw<V> : never;

type ToFrontFilter<T> = {
  [K in keyof T]: FrontFilterCondition<RawFilterValueFromType<T[K]>>
};

export type ToFrontInstanceFilter<T> = {
  [K in keyof T]: FrontFilterCondition<FilterValueFromType<T[K]>>
};

export interface BlockDisplayOptionsHandler<BlockDisplayOptions> {
  getDisplayOptions: () => BlockDisplayOptions,
  renderSummary: (state: BlockDisplayOptions) => string[],
  getErrors?: (state: BlockDisplayOptions) => string[],
  getBlockEditionOptionSections: (state: BlockDisplayOptions,
    setState: (newState: BlockDisplayOptions) => void,
    onOptionOrderChange?: (value: (Option | null)) => void,
  ) => EditionOptionSection[],
  onSubmit: (state: BlockDisplayOptions) => void,
}

interface FieldDisplayOptionsHandler<BlockDisplayOptions> {
  renderSummary: (state: BlockDisplayOptions) => string[],
  getErrors?: (state: BlockDisplayOptions) => string[],
  getEditionOptionSections: (state: BlockDisplayOptions, setState: (newState: BlockDisplayOptions) => void) => EditionOptionSection[],
}

export interface BlockFieldProps {
  title?: string,
  documentation?: { value: string, isInline: boolean },
  icon?: IconName,
  displayStatus?: BlockFieldDisplayStatus,
  updateDisplayStatus?: (displayStatus: BlockFieldDisplayStatus) => void,
  readOnly?: boolean,
  inColumn?: boolean,
  layoutDisplayMode?: BlockFieldLayoutOption,
  workflowFieldId?: string,
  workflowFieldDisplayType?: WorkflowRelationDisplayType,
  conceptId?: string,
}

export interface FieldHandler<DslFieldHandler extends GenericGetDslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration> {
  id: string,
  definitionId: string,
  renderField?: (options: {
    dimensionsMapping: DimensionsMapping,
    readOnly: boolean,
    path?: PathStep[],
    focusOnMount?: boolean,
    value?: ExtractDslHandlerTypes<DslFieldHandler>['StoreValue'],
    onSubmit?: (value: ExtractDslHandlerTypes<DslFieldHandler>['UpdateValue']) => void,
    fieldDisplayOptions?: FieldDisplayOptions & Partial<BlockDisplayOptions>,
    parametersMapping?: ParametersMapping,
    time?: number,
  }) => ReactElement | null,
  input?: {
    render: (options: {
      value: ExtractDslHandlerTypes<DslFieldHandler>['StoreValue'],
      onSubmit: (newValue: ExtractDslHandlerTypes<DslFieldHandler>['StoreValue']) => void,
      readOnly: boolean,
      focusOnMount?: boolean,
      onEditionStart?: () => void,
      onEditionStop?: () => void,
      isEditing?: boolean,
      aclHandler: ACLHandler,
      fieldDisplayOptions?: FieldDisplayOptions,
    }) => ReactElement | null,
    getInitialState: () => ExtractDslHandlerTypes<DslFieldHandler>['StoreValue'],
    persistStateOnConcept: (dimensionsMapping: DimensionsMapping, state: ExtractDslHandlerTypes<DslFieldHandler>['StoreValue']) => void,
  },
  getUpdateOperationInput?: (operation: ExtractDslHandlerTypes<DslFieldHandler>['UpdateOperations'] | undefined) => {
    title: string | undefined,
    getIcon: (theme: Theme) => {
      name: IconName,
      color: string,
      backgroundColor: string,
    } | undefined,
    render: (options: {
      onChange: (newValue: ExtractDslHandlerTypes<DslFieldHandler>['UpdateOperations'] | undefined) => void,
      onSubmit: (newValue: ExtractDslHandlerTypes<DslFieldHandler>['UpdateOperations'] | undefined) => void,
      onCancel: () => void,
      readOnly: boolean,
      focusOnMount?: boolean,
      onEditionStart?: () => void,
      onEditionStop?: () => void,
      isEditing?: boolean,
      parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
      conceptDefinitionId?: string,
    }) => ReactElement | null,
  },
  renderSuggestedField?: (value: unknown, setValue: (v: unknown) => void, focusOnMount: boolean) => ReactElement | null,
  renderWidget?: (options: { parametersMapping: ParametersMapping, isPreview?: boolean, readOnly?: boolean }) => ReactElement | null,
  renderBlockField?: (
    dimensionsMapping: DimensionsMapping,
    displayOptions: BlockDisplayOptions,
    blockFieldProps: BlockFieldProps,
    layoutParametersMapping: ParametersMapping,
    viewDimensions: ViewDimension[],
    fieldBlockDisplayId: string,
  ) => ReactElement | null,
  renderExportConfiguration?: (options: {
    configuration: ExportConfiguration | undefined,
    onChange: (configuration: ExportConfiguration) => void,
    conceptDefinitionId: string | undefined,
  }) => ReactElement | null,
  getAdditionalBlockFieldProps?: () => { isVertical?: boolean, fullWidth?: boolean, hideOverflowX?: boolean },
  getActivityProperties?: () => string[],
  getColumnDefinition?: () => ColumnDefinition,
  estimatedColumnWidth?: (conceptDefinitionId: string) => (string | undefined),
  estimatedCardHeight?: (width: number, fieldDisplayOptions: FieldDisplayOptions | undefined) => number,
  // Use of any is safe hare as type coherency is handled inside the comparator handler (extractValue output to compare input)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getComparatorHandler?: (direction: TableSortDirection) => FieldComparatorHandler<any>,
  filterConditions?: ExtractDslHandlerTypes<DslFieldHandler>['FilterConditions'] extends undefined ? undefined : ToFrontFilter<ExtractDslHandlerTypes<DslFieldHandler>['FilterConditions']>,
  blockDisplayOptionsHandler?: (fieldBlockDisplayId: string, parameterDefinitions: SingleParameterDefinition[]) => BlockDisplayOptionsHandler<BlockDisplayOptions> | undefined,
  fieldDisplayOptionsHandler?: (parameterDefinitions: SingleParameterDefinition[]) => FieldDisplayOptionsHandler<FieldDisplayOptions>,
  valueValidation?: (value: unknown) => { isValid: boolean, error?: Error },
}

export const FieldIntegrationOnlyDisabled = 'b6a9c360-c1e2-41a3-b090-f13a17bc9735_disabled';

type WrapWithStore<V> = (objectStore: FrontObjectStore) => V;
type WrapWithField<DslFieldHandler extends GenericGetDslFieldHandler, V> = (objectStore: FrontObjectStore, fieldId: string, handler: ReturnType<DslFieldHandler>) => V;

export interface FieldDefinition<DslFieldHandler extends GenericGetDslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration> {
  configuration: {
    typeIcon: IconName,
    getTypeLabel: FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['getTypeLabel'],
    asWidget: boolean,
    getFieldTitle?: WrapWithStore<FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['getFieldTitle']>,
    getFieldSubTitle?: WrapWithStore<FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['getFieldSubTitle']>,
    getFieldIcon?: WrapWithStore<FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['getFieldIcon']>,
    canCreate?: WrapWithStore<FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['canCreate']>,
    onCreate?: WrapWithStore<FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['onCreate']>,
    onWidgetCreate?: WrapWithStore<FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['onWidgetCreate']>,
    isCreationEnabled?: WrapWithStore<FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['isCreationEnabled']>,
    getEditionOptions: WrapWithStore<FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['getEditionOptions']>,
    getTargetFields?: WrapWithStore<FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['getTargetFields']>,
    inlineCreate?: WrapWithStore<FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['inlineCreate']>,
    ofField: WrapWithField<DslFieldHandler, {
      getIcon?: () => IconName | { name: IconName, color: string, borderColor?: string } | undefined,
      getTypeLabel?: ReturnType<FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['ofField']>['getTypeLabel'],
      getInitialState: ReturnType<FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['ofField']>['getInitialState'],
      getInitialOverrideState?: ReturnType<FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['ofField']>['getInitialOverrideState'],
      submitFieldUpdate: ReturnType<FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['ofField']>['submitFieldUpdate'],
      submitFieldOverrideUpdate?: ReturnType<FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['ofField']>['submitFieldOverrideUpdate'],
      duplicateFieldDefinition?: ReturnType<FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['ofField']>['duplicateFieldDefinition'],
    }>,
  },

  // Value
  renderField?: WrapWithField<DslFieldHandler, FieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['renderField']>,
  input?: WrapWithField<DslFieldHandler, FieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['input']>,
  getUpdateOperationInput?: WrapWithField<DslFieldHandler, FieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['getUpdateOperationInput']>,
  renderSuggestedField?: WrapWithField<DslFieldHandler, FieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['renderSuggestedField']>,
  renderWidget?: WrapWithField<DslFieldHandler, FieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['renderWidget']>,
  renderBlockField?: WrapWithField<DslFieldHandler, FieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['renderBlockField']>,
  renderExportConfiguration?: WrapWithField<DslFieldHandler, FieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['renderExportConfiguration']>,
  getAdditionalBlockFieldProps?: WrapWithField<DslFieldHandler, FieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['getAdditionalBlockFieldProps']>,
  getActivityProperties?: WrapWithField<DslFieldHandler, FieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['getActivityProperties']>,
  getColumnDefinition?: WrapWithField<DslFieldHandler, FieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['getColumnDefinition']>,
  estimatedColumnWidth?: WrapWithField<DslFieldHandler, FieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['estimatedColumnWidth']>,
  estimatedCardHeight?: WrapWithField<DslFieldHandler, FieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['estimatedCardHeight']>,
  getComparatorHandler?: WrapWithField<DslFieldHandler, FieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['getComparatorHandler']>,
  filterConditions?: WrapWithField<DslFieldHandler, FieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['filterConditions']>,
  blockDisplayOptionsHandler?: WrapWithField<DslFieldHandler, FieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['blockDisplayOptionsHandler']>,
  fieldDisplayOptionsHandler?: WrapWithField<DslFieldHandler, FieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['fieldDisplayOptionsHandler']>,
  valueValidation?: WrapWithField<DslFieldHandler, FieldHandler<DslFieldHandler, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>['valueValidation']>,
}

export interface GetFieldDefinitionHandler<DslFieldHandler extends GenericGetDslFieldHandler, ConfigurationState,
  FieldDisplayOptions = never, BlockDisplayOptions = never, ExportConfiguration = never> {
  (objectStore: FrontObjectStore): FieldDefinitionHandler<DslFieldHandler, ConfigurationState, FieldDisplayOptions, BlockDisplayOptions, ExportConfiguration>,
}
