import type { ReactElement } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { ImageField } from 'yooi-modules/modules/conceptModule/ids';
import type { IconName } from '../../../../components/atoms/Icon';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import type { FrontObjectStore } from '../../../../store/useStore';
import { getFieldHandler } from '../../fields/FieldLibrary';
import { ImageDisplayType } from '../../fields/imageField/imageFieldDefinition';
import { getChipOptions } from '../../modelTypeUtils';
import type { NavigationFilter } from '../../navigationUtils';
import type { CardEntry, CardsViewResolution } from './cardsViewResolution';

const getBuildRenderer = (store: FrontObjectStore, readOnly: boolean, defaultLineHeightPx: number, navigationFilters?: NavigationFilter) => (
  cardEntry: CardEntry,
  borderless: boolean,
  containerWidth: number
): ({ render: (parametersMapping: ParametersMapping) => ReactElement | null, fullWidth: boolean, estimatedHeight: number }) | undefined => {
  if (cardEntry.type === 'field') {
    const handler = getFieldHandler(store, cardEntry.fieldId);
    if (handler && handler.renderField) {
      const { renderField, estimatedCardHeight } = handler;

      if (handler.definitionId === ImageField && borderless) {
        return {
          fullWidth: true,
          render: (parametersMapping) => {
            const dimensionsMapping = cardEntry.resolve(parametersMapping) ?? {};
            return renderField({
              dimensionsMapping,
              readOnly,
              fieldDisplayOptions: { displayType: ImageDisplayType.BorderlessValue },
            });
          },
          estimatedHeight: estimatedCardHeight?.(containerWidth, { displayType: ImageDisplayType.BorderlessValue }) ?? defaultLineHeightPx,
        };
      } else {
        return {
          fullWidth: false,
          render: (parametersMapping) => {
            const dimensionsMapping = cardEntry.resolve(parametersMapping) ?? {};
            return renderField({
              dimensionsMapping,
              readOnly,
              fieldDisplayOptions: cardEntry.displayOptions,
            });
          },
          estimatedHeight: estimatedCardHeight?.(containerWidth, cardEntry.displayOptions) ?? defaultLineHeightPx,
        };
      }
    } else {
      return undefined;
    }
  } else {
    return {
      fullWidth: false,
      render: (dimensionsMapping) => {
        const resolvedInstanceId = cardEntry.resolve(dimensionsMapping);
        return (
          <SearchAndSelect
            selectedOption={
              resolvedInstanceId
                ? getChipOptions(store, resolvedInstanceId, navigationFilters)
                : undefined
            }
            readOnly
          />
        );
      },
      estimatedHeight: defaultLineHeightPx,
    };
  }
};

export type InstanceCardHeader = {
  key: string,
  fullWidth: boolean,
  estimatedHeight: number,
  render: (resolveParameters: ParametersMapping, readOnly?: boolean) => (ReactElement | null),
}[];
export const buildInstanceCardHeader = (
  store: FrontObjectStore,
  header: CardsViewResolution['header'],
  readOnly: boolean,
  defaultLineHeightPx: number,
  cardInnerWidth: number,
  navigationFilters?: NavigationFilter
): InstanceCardHeader => {
  const buildRenderer = getBuildRenderer(store, readOnly, defaultLineHeightPx, navigationFilters);

  const builtHeader: InstanceCardHeader = [];
  for (let i = 0; i < header.length; i += 1) {
    const headerEntry = header[i];
    const renderer = buildRenderer(headerEntry, true, cardInnerWidth);
    if (renderer) {
      builtHeader.push({
        key: headerEntry.key,
        fullWidth: renderer.fullWidth,
        estimatedHeight: renderer.estimatedHeight,
        render: renderer.render,
      });
    }
  }
  return builtHeader;
};

export type InstanceCardBody = {
  key: string,
  label: string,
  estimatedHeight: number,
  icon: IconName,
  render: (resolveParametersMapping: ParametersMapping) => (ReactElement | null),
}[];

export const buildInstanceCardBody = (
  store: FrontObjectStore,
  body: CardsViewResolution['body'],
  readOnly: boolean,
  defaultLineHeightPx: number,
  cardBodyInnerWidth: number,
  navigationFilters?: NavigationFilter
): InstanceCardBody => {
  const buildRenderer = getBuildRenderer(store, readOnly, defaultLineHeightPx, navigationFilters);

  const builtBody: InstanceCardBody = [];
  for (let i = 0; i < body.length; i += 1) {
    const bodyEntry = body[i];
    const renderer = buildRenderer(bodyEntry, false, cardBodyInnerWidth);
    if (renderer) {
      builtBody.push({ key: bodyEntry.key, label: bodyEntry.label, icon: bodyEntry.icon, estimatedHeight: renderer.estimatedHeight, render: renderer.render });
    }
  }
  return builtBody;
};
