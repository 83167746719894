import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import type { PlatformConfigurationStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import { CurrentPlatformConfiguration, PlatformConfiguration_CollaborationMessage } from 'yooi-modules/modules/platformConfigurationModule/ids';
import { joinObjects } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../../../components/atoms/Button';
import Icon, { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import Typo from '../../../../../components/atoms/Typo';
import Menu from '../../../../../components/molecules/Menu';
import useStore from '../../../../../store/useStore';
import base from '../../../../../theme/base';
import { spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import { remToPx } from '../../../../../utils/sizeUtils';
import useDerivedState from '../../../../../utils/useDerivedState';
import { SizeContextProvider, SizeVariant } from '../../../../../utils/useSizeContext';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: spacingRem.s,
    borderRadius: base.borderRadius.medium,
    backgroundColor: theme.color.background.neutral.default,
    rowGap: spacingRem.s,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.default,
    '&:hover': {
      borderColor: theme.color.border.hover,
    },
    '&:focus, &:focus-within': {
      borderColor: theme.color.border.dark,
    },
  },
  containerOnClosed: {
    color: theme.color.text.disabled,
    minHeight: '3.2rem',
    paddingTop: 0,
    paddingBottom: 0,
    justifyContent: 'center',
  },
  textarea: joinObjects(
    {
      verticalAlign: 'top',
      border: 'none',
      backgroundColor: theme.color.transparent,
      color: theme.color.text.primary,
      resize: 'none',
      outline: 'none',
      padding: 0,
      '&::placeholder': {
        color: theme.color.text.disabled,
      },
    } as const,
    theme.font.body
  ),
  buttonsLineContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    rowGap: spacingRem.xs,
  },
  buttonsAndDisclaimer: {
    justifyContent: 'space-between',
  },
  buttonsOnly: {
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    marginLeft: spacingRem.xs,
  },
}), 'collaborationInput');

export enum CollaborationInputVariant {
  New,
  Reply,
  ReplyClosed,
}

interface CollaborationTransitionButton {
  key: string,
  name: string,
  onClick: () => void,
  tooltip?: string,
}

interface CollaborationInputProps {
  variant: CollaborationInputVariant,
  onChange?: (message: string) => void,
  onSend?: (message: string) => void,
  transitions?: CollaborationTransitionButton[],
  displayDisclaimerMessage?: boolean,
}

const CollaborationInput: FunctionComponent<CollaborationInputProps> = ({ variant, onChange, onSend, transitions, displayDisclaimerMessage = true }) => {
  const classes = useStyles();
  const store = useStore();
  const platformConfiguration = store.getObject<PlatformConfigurationStoreObject>(CurrentPlatformConfiguration);
  const disclaimerMessage = displayDisclaimerMessage && platformConfiguration[PlatformConfiguration_CollaborationMessage];

  const [message, setMessage] = useState('');
  const isMessageEmpty = message === '';
  const [isOpen, setIsOpen] = useDerivedState(() => variant !== CollaborationInputVariant.ReplyClosed || !isMessageEmpty, [variant, isMessageEmpty]);
  const [isTransitionOpen, setTransitionOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  let displayedTransitions: CollaborationTransitionButton[] | undefined;
  let hiddenTransitions: CollaborationTransitionButton[] | undefined;
  if (transitions) {
    if (transitions.length > 2) {
      displayedTransitions = transitions.slice(0, 2);
      hiddenTransitions = transitions.slice(2);
    } else {
      displayedTransitions = transitions;
    }
  }
  if (isOpen) {
    let placeholder;
    switch (variant) {
      case CollaborationInputVariant.New:
        placeholder = i18n`Write your message...`;
        break;
      case CollaborationInputVariant.Reply:
        placeholder = i18n`Reply...`;
        break;
      case CollaborationInputVariant.ReplyClosed:
        placeholder = i18n`Answer anyway...`;
        break;
    }

    return (
      <div className={classes.container}>
        <TextareaAutosize
          autoFocus={variant === CollaborationInputVariant.ReplyClosed}
          className={classes.textarea}
          placeholder={placeholder}
          minRows={3}
          maxRows={15}
          value={message}
          onChange={(event) => {
            setMessage(event.target.value);
            onChange?.(event.target.value);
          }}
          onKeyDown={(event) => {
            if (variant === CollaborationInputVariant.ReplyClosed && isMessageEmpty && event?.key === 'Escape') {
              setIsOpen(false);
            }
          }}
        />
        <div
          className={classnames({
            [classes.buttonsLineContainer]: true,
            [classes.buttonsOnly]: !displayDisclaimerMessage || !disclaimerMessage,
            [classes.buttonsAndDisclaimer]: displayDisclaimerMessage && disclaimerMessage,
          })}
        >
          {displayDisclaimerMessage && disclaimerMessage && <Icon name={IconName.info} colorVariant={IconColorVariant.info} tooltip={disclaimerMessage} />}
          <div className={classes.buttonsContainer}>
            {
              hiddenTransitions
                ? (
                  <div className={classes.buttonContainer} ref={containerRef}>
                    <IconOnlyButton
                      iconName={IconName.more_horiz}
                      onClick={(event) => {
                        event.stopPropagation();
                        setTransitionOpen(true);
                      }}
                      tooltip={i18n`More`}
                      variant={IconOnlyButtonVariants.secondary}
                    />
                  </div>

                )
                : undefined
            }
            {isTransitionOpen && hiddenTransitions ? (
              <SizeContextProvider sizeVariant={SizeVariant.main}>
                <Menu
                  items={hiddenTransitions.map((transition) => ({
                    key: transition.key,
                    name: transition.name ?? '',
                    tooltip: transition.tooltip,
                    onClick: () => {
                      transition.onClick();
                      if (!isMessageEmpty) {
                        onSend?.(message);
                        setMessage('');
                      }
                    },
                  }))}
                  anchorRef={containerRef}
                  onClose={() => setTransitionOpen(false)}
                  placement="top-start"
                  offset={[0, remToPx(spacingRem.xs)]}
                />
              </SizeContextProvider>
            ) : null}
            {
              displayedTransitions
                ? (
                  displayedTransitions.map((transition) => (
                    <div key={transition.key} className={classes.buttonContainer}>
                      <Button
                        title={transition.name.length > 7 ? `${transition.name.substring(0, 7)}...` : transition.name}
                        onClick={() => {
                          transition.onClick();
                          if (!isMessageEmpty) {
                            onSend?.(message);
                            setMessage('');
                          }
                        }}
                        tooltip={transition.tooltip}
                        variant={ButtonVariant.secondary}
                      />
                    </div>
                  ))
                )
                : undefined
            }
            {
              onSend
                ? (
                  <div className={classes.buttonContainer}>
                    <Button
                      title={i18n`Send`}
                      onClick={() => {
                        if (!isMessageEmpty) {
                          onSend(message);
                          setMessage('');
                        }
                      }}
                      disabled={isMessageEmpty}
                    />
                  </div>
                )
                : undefined
            }
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={classnames(classes.container, classes.containerOnClosed)}
        tabIndex={0}
        role="button"
        onClick={() => setIsOpen(true)}
        onMouseDown={(event) => {
          if (event.button === 1) {
            setIsOpen(true);
          }
        }}
        onKeyDown={(event) => {
          if (event?.key === 'Enter') {
            setIsOpen(true);
          }
        }}
      >
        <Typo>{i18n`Answer anyway...`}</Typo>
      </div>
    );
  }
};

export default CollaborationInput;
