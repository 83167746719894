import { useRef, useState } from 'react';
import type { Row, SheetData } from 'write-excel-file';
import writeXlsxFile from 'write-excel-file';
import type { TimeRange } from 'yooi-store';
import { createAutoProvisioningMap, fromError, joinObjects, PeriodicityType, sleep } from 'yooi-utils';
import { Icon } from '../../components/atoms/icons';
import type { FrontObjectStore } from '../../store/useStore';
import useStore from '../../store/useStore';
import { reportClientTrace } from '../../utils/clientReporterUtils';
import i18n from '../../utils/i18n';
import { clearNotification, notifyError, notifyInfo } from '../../utils/notify';
import type { ExecuteAsyncTask } from '../../utils/withAsyncTask';
import { registerComputeFunction } from '../../utils/withAsyncTask';

interface PendingTimeseries {
  objectId: string | string[],
  propertyId: string,
}

export type ExcelValueType = { format: 'string', value: string | undefined }
| { format: 'number', value: number | undefined, decimal?: number | undefined, unit?: string | undefined }
| { format: 'boolean', value: boolean }
| { format: 'date', value: number | undefined, period: PeriodicityType | undefined };

interface ExportToExcelProps<Column extends { key: string, label?: string }, Line extends { key: string, label?: string }> {
  fileName: string,
  columns: Column[],
  lines: Line[],
  cellResolver: (asyncObjectStore: FrontObjectStore, line: Line, column: Column) => ExcelValueType | undefined,
  headerRowCount?: number,
  headerColumnCount?: number,
  toastId?: string,
}

interface PendingExport<Column extends { key: string, label?: string }, Line extends { key: string, label?: string }> {
  fileName: string,
  columns: Column[],
  lines: Line[],
  headerColumnCount?: number,
  headerRowCount?: number,
  cellResolver: (asyncObjectStore: FrontObjectStore, line: Line, column: Column) => ExcelValueType | undefined,
}

const asyncExportToExcel = registerComputeFunction(
  async (
    yieldProcessor: () => Promise<void>,
    store: FrontObjectStore,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    currentExport: PendingExport<any, any>
  ) => {
    try {
      const { columns, lines, cellResolver, headerColumnCount, headerRowCount, fileName } = currentExport;

      let newPendingTimeseries: PendingTimeseries[] = [];
      const pendingTimeseries: PendingTimeseries[] = [];

      const watchingFrontObjectStore = joinObjects(
        store,
        {
          getTimeseries: <Value, Id extends (string | string[])>(
            objectId: Id,
            propertyId: string,
            timeRange: TimeRange | undefined
          ) => {
            const values = store.getTimeseries<Value, Id>(objectId, propertyId, timeRange);
            if (!values) {
              newPendingTimeseries.push({ objectId, propertyId });
            }
            return values;
          },
        }
      );

      const cache = createAutoProvisioningMap<string, Map<string, ExcelValueType | undefined>>();
      for (let i = 0; i < lines.length; i += 1) {
        const line = lines[i];
        for (let j = 0; j < columns.length; j += 1) {
          const column = columns[j];
          const cellResolution = cellResolver(watchingFrontObjectStore, line, column);
          if (newPendingTimeseries.length) {
            // If pending timeseries is not empty, it means that the resolver needs to wait for some timeseries to be fetched.
            // We don't cache its result, it will be re-compute later with the fetched timeseries.
            pendingTimeseries.push(...newPendingTimeseries);
            // Reset newPendingTimeseries for the next resolution
            newPendingTimeseries = [];
          } else {
            // If pending timeseries is empty, the result is complete. We cache the result to avoid to recompute it.
            cache.getOrCreate(line.key, () => new Map<string, ExcelValueType | undefined>()).set(column.key, cellResolution);
          }
          await yieldProcessor();
        }
      }

      let allTimeseriesAreResolved = pendingTimeseries.every(({ objectId, propertyId }) => !!store.getTimeseries(objectId, propertyId));
      while (!allTimeseriesAreResolved) {
        await sleep(1000);
        allTimeseriesAreResolved = pendingTimeseries.every(({ objectId, propertyId }) => !!store.getTimeseries(objectId, propertyId));
      }

      const data: SheetData = [];
      for (let i = 0; i < lines.length; i += 1) {
        const line = lines[i];
        const dataLine: Row = [];
        for (let j = 0; j < columns.length; j += 1) {
          const column = columns[j];
          const cellResolution = cache.get(line.key)?.get(column.key) ?? cellResolver(store, line, column);
          if (!cellResolution || cellResolution.value === undefined) {
            dataLine.push(undefined);
          } else {
            switch (cellResolution.format) {
              case 'boolean': {
                dataLine.push({ value: cellResolution.value });
                break;
              }
              case 'string': {
                if (cellResolution.value && cellResolution.value.length > 32767) {
                  dataLine.push({ value: '[Unable to export content as it exceeded Excel maximum length (32767 characters)]' });
                } else {
                  dataLine.push({ value: cellResolution.value });
                }
                break;
              }
              case 'number': {
                let format = '0';
                if (cellResolution.decimal) {
                  format = `${format}.${'0'.repeat(cellResolution.decimal)}`;
                }
                if (cellResolution.unit) {
                  format = `${format} "${cellResolution.unit}"`;
                }
                dataLine.push({ value: cellResolution.value, format });
                break;
              }
              case 'date': {
                let format = 'dd/mm/yyyy hh:mm:ss';
                let value = new Date(cellResolution.value);
                if (cellResolution.period === PeriodicityType.year) {
                  format = 'yyyy';
                  value = new Date(value.getFullYear(), 0, 1, 0, 0);
                } else if (cellResolution.period === PeriodicityType.quarter || cellResolution.period === PeriodicityType.month) {
                  format = 'mm/yyyy';
                  value = new Date(value.getFullYear(), value.getMonth(), 1, 0, 0);
                } else if (cellResolution.period === PeriodicityType.week || cellResolution.period === PeriodicityType.day) {
                  format = 'dd/mm/yyyy';
                  value = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0, 0);
                }
                const localOffsetInMinute = value.getTimezoneOffset();
                dataLine.push({ value: new Date(value.getTime() - (localOffsetInMinute * 60_000)), format });
              }
            }
          }
          await yieldProcessor();
        }
        data.push(dataLine);
      }
      await writeXlsxFile(data, {
        fileName,
        stickyRowsCount: headerRowCount,
        stickyColumnsCount: headerColumnCount,
      });
      return undefined;
    } catch (e) {
      return fromError(e, i18n`Error while importing date`);
    }
  }
);

const useExport = <Column extends { key: string, label?: string }, Line extends { key: string, label?: string }>(executeAsyncTask: ExecuteAsyncTask): {
  exportToExcel: (props: ExportToExcelProps<Column, Line>) => void,
} => {
  const store = useStore();

  const [pendingExport, setPendingExport] = useState<PendingExport<Column, Line> | undefined>();
  const pendingExportToastId = useRef<string | undefined>(undefined);

  const pendingToastId = pendingExportToastId.current;
  if (pendingExport) {
    const { status, value: error } = executeAsyncTask(asyncExportToExcel, [store, pendingExport]);
    if (status === 'loaded') {
      if (error) {
        reportClientTrace(error);
        notifyError(i18n`Export failed`);
      }
      setPendingExport(undefined);
    }
  } else if (pendingToastId) {
    pendingExportToastId.current = undefined;
    clearNotification(pendingToastId);
  }

  return {
    exportToExcel: ({ fileName, columns, lines, cellResolver, headerColumnCount, headerRowCount, toastId }) => {
      if (!pendingExport) {
        if (!toastId) {
          notifyInfo(i18n`Preparing file`, {
            persist: true,
            icon: { icon: Icon.file_download },
            onToast: (newToastId) => {
              pendingExportToastId.current = newToastId;
            },
          });
        } else {
          pendingExportToastId.current = toastId;
        }
        setPendingExport({ fileName, columns, lines, headerColumnCount, headerRowCount, cellResolver });
      }
    },
  };
};

export default useExport;
