import type { FunctionComponent } from 'react';
import { v4 as uuid } from 'uuid';
import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import type { RadarChartFieldConfiguration, RadarChartSeries } from 'yooi-modules/modules/dashboardModule';
import { RadarChartField_Configuration } from 'yooi-modules/modules/dashboardModule/ids';
import { compareRank, extractAndCompareValue, filterNullOrUndefined, joinObjects, ranker } from 'yooi-utils';
import { ButtonVariant } from '../../../../components/atoms/Button';
import { IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import SearchAndSelectMultiple from '../../../../components/molecules/SearchAndSelectMultiple';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import DataTable from '../../../../components/templates/DataTable';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import StoreTextInputField from '../../input/StoreTextInputField';
import { ChartColorPathAcceptedFields, computePathOptions, filterPath, getFilteredPathOptions, getSeriesLabel } from '../_global/widgetSeriesUtils';
import { ChartValuePathAcceptedFields } from './radarChartFieldUtils';

interface RadarChartFieldSeriesOptionsProps {
  selectedXAxis: string,
  configuration: RadarChartFieldConfiguration,
  onSubmit: (update: Record<string, unknown>) => void,
  readOnly: boolean,
  parameterDefinitions: SingleParameterDefinition[],
  modelTypeId: string,
}

const RadarChartFieldSeriesOptions: FunctionComponent<RadarChartFieldSeriesOptionsProps> = ({
  selectedXAxis, configuration, onSubmit, readOnly, parameterDefinitions, modelTypeId,
}) => {
  const store = useStore();

  const allSeries = configuration.series?.sort(extractAndCompareValue((c) => c.rank, compareRank)) ?? [];

  const decoratedSeries = ranker.decorateList(
    allSeries,
    (c) => c.rank
  );

  const updateSeries = (index: number, newSeries: RadarChartSeries) => {
    const newConfiguration: RadarChartFieldConfiguration = joinObjects(
      configuration,
      { series: allSeries.map((s, i) => (i === index ? newSeries : s)) }
    );
    onSubmit({
      [RadarChartField_Configuration]: newConfiguration,
    });
  };

  return (
    <DataTable
      columnsDefinition={[
        {
          name: i18n`Label`,
          propertyId: 'Series',
          width: 30,
          cellRender: ({ item: { label, series, valuePath } }, _, index) => (
            <StoreTextInputField
              initialValue={getSeriesLabel(store, label, valuePath, index)}
              onSubmit={(value) => {
                updateSeries(index, joinObjects(series, { label: value ?? '' }));
              }}
              readOnly={readOnly}
            />
          ),
        },
        {
          name: i18n`Value`,
          propertyId: 'ValueSeries',
          width: 70,
          cellRender: ({ item: { valueError, valuePath, series } }, _, index) => (
            <SearchAndSelectMultiple
              error={valueError}
              readOnly={readOnly}
              selectedSteps={
                valuePath.map((instanceId: string) => getFilteredPathOptions({ store, instanceId, parameterDefinitions, modelTypeId }))
                  .filter(filterNullOrUndefined)
              }
              computeOptions={() => computePathOptions(store, selectedXAxis, valuePath, ChartValuePathAcceptedFields)}
              onSelect={({ id: newId }) => updateSeries(index, joinObjects(series, { valuePath: [...valuePath, newId] }))}
              onDelete={({ id: newId }) => updateSeries(index, joinObjects(series, { valuePath: valuePath.splice(0, valuePath.indexOf(newId)) }))}
            />
          ),
        },
        {
          propertyId: 'MoveUp',
          action: true,
          cellRender: (line, _, index) => {
            const { item: { series } } = line;
            return (
              <SpacingLine>
                <IconOnlyButton
                  disabled={readOnly || index === 0}
                  onClick={() => {
                    const upRank = line.moveUpRank();
                    if (upRank) {
                      updateSeries(index, joinObjects(series, { rank: upRank }));
                    }
                  }}
                  iconName={IconName.expand_less}
                  tooltip={i18n`Move Up`}
                  variant={IconOnlyButtonVariants.tertiary}
                />
              </SpacingLine>
            );
          },
        },
        {
          propertyId: 'MoveDown',
          action: true,
          cellRender: (line, _, index) => {
            const { item: { series } } = line;
            return (
              <SpacingLine>
                <IconOnlyButton
                  disabled={readOnly || index === (allSeries.length - 1)}
                  onClick={() => {
                    const downRank = line.moveDownRank();
                    if (downRank) {
                      updateSeries(index, joinObjects(series, { rank: downRank }));
                    }
                  }}
                  iconName={IconName.expand_more}
                  tooltip={i18n`Move Down`}
                  variant={IconOnlyButtonVariants.tertiary}
                />
              </SpacingLine>
            );
          },
        },
        {
          propertyId: 'Delete',
          action: true,
          cellRender: (_, __, index) => (
            <SpacingLine>
              <IconOnlyButton
                disabled={readOnly}
                onClick={() => {
                  const newConfiguration: RadarChartFieldConfiguration = joinObjects(
                    configuration,
                    { series: (configuration.series ?? []).filter((___, i) => index !== i) }
                  );
                  onSubmit({
                    [RadarChartField_Configuration]: newConfiguration,
                  });
                }}
                iconName={IconName.delete}
                tooltip={i18n`Delete`}
                variant={IconOnlyButtonVariants.danger}
              />
            </SpacingLine>
          ),
        },
      ]}
      list={decoratedSeries.map((column, index) => {
        const series = column.item;
        const { error: valueError, path: valuePath } = filterPath(store, series.valuePath, ChartValuePathAcceptedFields);
        const { error: colorError, path: colorPath } = filterPath(store, series.colorPath, ChartColorPathAcceptedFields);
        return {
          key: `${index}`,
          type: 'item',
          item: joinObjects(
            { index },
            column,
            {
              item: {
                label: series.label,
                defaultColor: series.color,
                series,
                seriesNumber: `${index + 1}`,
                valueError,
                valuePath,
                colorError,
                colorPath,
              },
            }
          ),
          color: undefined,
        };
      })}
      newItemIcon={IconName.add}
      newItemTitle={i18n`Add`}
      newItemButtonVariant={ButtonVariant.tertiary}
      onNewItem={readOnly ? undefined : () => {
        const newConfiguration: RadarChartFieldConfiguration = joinObjects(
          configuration,
          {
            series: [
              ...(configuration.series ?? []),
              {
                id: uuid(),
                color: `#${Math.random().toString(16).substring(2, 8)}`,
                rank: decoratedSeries.insertAfterLastItemRank(),
              },
            ],
          }
        );
        onSubmit({
          [RadarChartField_Configuration]: newConfiguration,
        });
      }}
      fullWidth
    />
  );
};

export default RadarChartFieldSeriesOptions;
