import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import { useDebounce } from 'use-debounce';

interface WidgetSize {
  height: number,
  width: number,
}

const WidgetSizeContext = createContext<WidgetSize>({ height: 0, width: 0 });

interface WidgetSizeContextProviderProps {
  height: number,
  width: number,
  children: ReactNode,
}

export const WidgetSizeContextProvider: FunctionComponent<WidgetSizeContextProviderProps> = ({ height = 0, width = 0, children }) => {
  const memoizedValue = useMemo(() => ({ height, width }), [height, width]);
  const [value] = useDebounce(memoizedValue, 250, { leading: true });

  return (
    <WidgetSizeContext.Provider value={value}>
      {children}
    </WidgetSizeContext.Provider>
  );
};

const useWidgetSizeContext = (): WidgetSize => useContext(WidgetSizeContext);

export default useWidgetSizeContext;
