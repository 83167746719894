import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import type { MonthRepeat } from 'yooi-modules/modules/automationModule';
import type { DayNumbers } from 'yooi-utils';
import { joinObjects } from 'yooi-utils';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../components/molecules/SpacingLine';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import type { Option } from '../../_global/modelTypeUtils';
import { getWeekOccurrenceLabel } from './automationUtils';
import { getWeekDayOptions } from './WeekRepeatRuleInput';

const useStyles = makeStyles({
  dayInputSpacings: {
    display: 'grid',
    gridAutoFlow: 'column',
    columnGap: spacingRem.m,
    gridTemplateColumns: '12rem 6rem',
    alignItems: 'center',
  },
  weekDayInputSpacings: {
    display: 'grid',
    gridAutoFlow: 'column',
    columnGap: spacingRem.m,
    gridTemplateColumns: '12rem 12rem 12rem',
    alignItems: 'center',
  },
}, 'monthRepeatRuleInput');

interface MonthRepeatRuleInputProps {
  repeatRule: MonthRepeat,
  onChange: (repeatRule: MonthRepeat) => void,
}

interface WeekOption extends Option {
  value: 1 | 2 | 3 | 4 | 5,
}

export const getWeekOptions = (): WeekOption[] => [
  { id: '1', value: 1, label: getWeekOccurrenceLabel(1) },
  { id: '2', value: 2, label: getWeekOccurrenceLabel(2) },
  { id: '3', value: 3, label: getWeekOccurrenceLabel(3) },
  { id: '4', value: 4, label: getWeekOccurrenceLabel(4) },
  { id: '5', value: 5, label: getWeekOccurrenceLabel(5) },
];

export const isWeekOption = (option: Option): option is WeekOption => !!getWeekOptions().find(({ id }) => id === option.id);

const MonthRepeatRuleInput: FunctionComponent<MonthRepeatRuleInputProps> = ({ repeatRule, onChange }) => {
  const classes = useStyles();

  const specificDayOption = { id: '0', label: i18n`Specific day` };

  const weekOptions = getWeekOptions();

  return (
    <SpacingLine>
      <div
        className={classnames({
          [classes.weekDayInputSpacings]: Boolean(repeatRule.selectedRule === 'weekDayRule'),
          [classes.dayInputSpacings]: Boolean(repeatRule.selectedRule === 'dayRule'),
        })}
      >
        <SearchAndSelect
          computeOptions={() => [specificDayOption, ...weekOptions]}
          placeholder={i18n`Select a frequency`}
          selectedOption={repeatRule.selectedRule === 'dayRule' ? specificDayOption : weekOptions.find(({ value }) => value === repeatRule.weekDayRule.week)}
          onSelect={(option) => {
            if (option && isWeekOption(option)) {
              onChange(joinObjects(repeatRule, {
                selectedRule: 'weekDayRule' as const,
                weekDayRule: joinObjects(repeatRule.weekDayRule, { week: option.value }),
              }));
            } else {
              onChange(joinObjects(repeatRule, { selectedRule: 'dayRule' as const }));
            }
          }}
        />
        {Boolean(repeatRule.selectedRule === 'dayRule') && (
          <SearchAndSelect
            computeOptions={() => Array.from({ length: 31 }).map((_, i) => i + 1).map((d) => ({ label: d.toString(), id: d.toString() }))}
            selectedOption={{ label: repeatRule.dayRule.day.toString(), id: repeatRule.dayRule.day.toString() }}
            onSelect={(option) => {
              if (option) {
                onChange(joinObjects(repeatRule, {
                  dayRule: joinObjects(repeatRule.dayRule, { day: Number(option.id) as DayNumbers }),
                }));
              }
            }}
            minWidth={6}
          />
        )}
        {Boolean(repeatRule.selectedRule === 'weekDayRule') && (
          <SearchAndSelect
            computeOptions={getWeekDayOptions}
            placeholder={i18n`Select a frequency`}
            selectedOption={getWeekDayOptions().find(({ value }) => value === repeatRule.weekDayRule.weekDay)}
            onSelect={(option) => {
              if (option) {
                onChange(joinObjects(repeatRule, { weekDayRule: joinObjects(repeatRule.weekDayRule, { weekDay: option.value }) }));
              }
            }}
          />
        )}
      </div>
    </SpacingLine>
  );
};

export default MonthRepeatRuleInput;
