import classnames from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { joinObjects } from 'yooi-utils';
import base, { Opacity } from '../../theme/base';
import { colorWithAlpha, darken, getMostReadableColorFromBackgroundColor } from '../../theme/colorUtils';
import { getSpacing, Spacing, spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import { formatOrUndef } from '../../utils/stringUtils';
import useDerivedState from '../../utils/useDerivedState';
import type { NavigationPayload } from '../../utils/useNavigation';
import useSizeContext, { buildComponentSizeVariantClasses, SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import type { IconName } from '../atoms/Icon';
import Icon, { IconColorVariant } from '../atoms/Icon';
import Tooltip from '../atoms/Tooltip';
import Typo, { TypoVariant } from '../atoms/Typo';
import ColorChip from './ColorChip';
import InlineLink from './InlineLink';

const ChipIconSizeInRem = 2.4;

const useStyles = makeStyles((theme) => (joinObjects(
  {
    chipContainer: {
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'stretch',
      borderRadius: base.borderRadius.medium,
      '&:focus-visible': {
        position: 'relative',
        outlineWidth: '0.2rem',
        outlineStyle: 'solid',
        outlineColor: theme.color.border.focus,
      },
    },
    chipMain: {
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'stretch',
      overflow: 'hidden',
    },
    clickableChip: {
      cursor: 'pointer',
    },
    withBorder: {
      borderWidth: '0.2rem',
      borderStyle: 'solid',
    },
    withBorderRadiusLeft: {
      borderTopLeftRadius: base.borderRadius.medium,
      borderBottomLeftRadius: base.borderRadius.medium,
    },
    withBorderRadiusRight: {
      borderTopRightRadius: base.borderRadius.medium,
      borderBottomRightRadius: base.borderRadius.medium,
    },
    hoverActionsRelativeContainer: {
      position: 'relative',
      alignSelf: 'stretch',
    },
    hoverActionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      position: 'absolute',
      right: 0,
      boxShadow: `-0.3rem 0 0.4rem ${colorWithAlpha(theme.color.background.neutral.dark, Opacity.fifteen)}`,
      height: '100%',
    },
    chipElement: {
      display: 'flex',
      alignItems: 'center',
      columnGap: spacingRem.s,
      paddingLeft: spacingRem.s,
      paddingRight: spacingRem.xs,
    },
    chipStartWithinBorder: {
      paddingLeft: getSpacing(Spacing.s, -0.2),
    },
    chipEndWithinBorder: {
      paddingRight: getSpacing(Spacing.s, -0.2),
    },
    chipEnd: {
      paddingRight: spacingRem.s,
    },
    chipAction: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: `${ChipIconSizeInRem}rem`,
    },
    chipActionCursor: {
      cursor: 'pointer',
    },
    iconWithBorder: {
      flexShrink: 0,
    },
  },
  buildComponentSizeVariantClasses('chip', ['height'])
)), 'chip');

interface Action {
  key: string,
  icon: IconName,
  action: string | (NavigationPayload & { openInNewTab?: boolean }) | (() => void),
  tooltip: string,
  disabled?: boolean,
  showOnHover?: boolean,
}

interface IconWithColor {
  name: IconName,
  colorVariant: IconColorVariant,
}

interface IconWithBorder {
  name: IconName,
  color: string,
  borderColor?: string,
}

interface ChipProps {
  text: string | undefined,
  width?: number,
  tooltip?: string,
  squareColor?: string,
  color?: string,
  borderColor?: string,
  borderStyle?: 'solid' | 'dashed',
  borderWidth?: number,
  icon?: IconName | IconWithColor | IconWithBorder,
  startIcons?: { key: string, icon: IconName, colorVariant?: IconColorVariant, color?: string, tooltip?: string }[],
  endIcons?: { key: string, icon: IconName, colorVariant?: IconColorVariant, color?: string, tooltip?: string }[],
  actions?: Action[],
  onClick?: () => void,
}

const isIconWithColor = (icon: ChipProps['icon']): icon is IconWithColor => typeof icon === 'object' && (icon as { colorVariant?: IconColorVariant }).colorVariant !== undefined;
const isIconWithBorder = (icon: ChipProps['icon']): icon is IconWithBorder => typeof icon === 'object' && (icon as { color?: string }).color !== undefined;
const computeBorderColor = (color: string): string => darken(color, 10);

const Chip: FunctionComponent<ChipProps> = ({
  text,
  width,
  tooltip,
  squareColor,
  color,
  borderColor,
  borderStyle,
  borderWidth,
  icon,
  startIcons,
  endIcons,
  actions = [],
  onClick,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const { sizeVariant: contextSizeVariant, hierarchyVariant } = useSizeContext();
  const sizeContextComputed = contextSizeVariant ?? SizeVariant.main;

  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredDisplayDebounced] = useDebounce(isHovered, 200);
  const [isHoveredHideDebounced] = useDebounce(isHovered, 25);

  const hoverActions = actions.filter(({ showOnHover }) => showOnHover);
  const [isHoveredHoverAction, setIsHoveredHoverAction] = useDerivedState(() => new Array(hoverActions.length).fill(false), [isHoveredDisplayDebounced]);

  const displayActions = actions.filter(({ showOnHover }) => !showOnHover);
  const [isHoveredDisplayAction, setIsHoveredDisplayAction] = useState(new Array(displayActions.length).fill(false));

  const chipColor = color ?? base.color.gray['300'];
  const backgroundColor = borderStyle === 'dashed' ? theme.color.background.neutral.default : chipColor;
  const renderAction = (
    { key, icon: actionIcon, action, tooltip: actionTooltip, disabled }: Action,
    hovered: boolean,
    onEvent: (event: 'enter' | 'leave') => () => void
  ): ReactElement => {
    if (typeof action === 'function') {
      const title = formatOrUndef(actionTooltip);
      return (
        <Tooltip key={key} title={title} asFlexbox>
          <div
            style={{ backgroundColor }}
            className={classnames({
              [classes.chipAction]: true,
              [classes.chipActionCursor]: !disabled,
            })}
            tabIndex={0}
            role="link"
            aria-label={title}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
                action();
              }
            }}
            onClick={(e) => {
              e.stopPropagation();
              action();
            }}
            onMouseOver={onEvent('enter')}
            onFocus={onEvent('enter')}
            onMouseOut={onEvent('leave')}
            onBlur={onEvent('leave')}
          >
            <Icon name={actionIcon} colorVariant={hovered ? IconColorVariant.primary : IconColorVariant.secondary} />
          </div>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip key={key} title={formatOrUndef(actionTooltip)} asFlexbox>
          <InlineLink
            to={typeof action === 'object' ? action.to : action}
            state={typeof action === 'object' ? action.state : undefined}
            openInNewTab={typeof action === 'object' ? action.openInNewTab : undefined}
            noStyle
            onMouseOver={onEvent('enter')}
            onMouseOut={onEvent('leave')}
            onFocus={onEvent('enter')}
            onBlur={onEvent('leave')}
          >
            <div
              style={{ backgroundColor }}
              className={classnames({
                [classes.chipAction]: true,
                [classes.chipActionCursor]: !disabled,
              })}
            >
              <Icon name={actionIcon} colorVariant={hovered ? IconColorVariant.primary : IconColorVariant.secondary} />
            </div>
          </InlineLink>
        </Tooltip>
      );
    }
  };

  const mainChipElementWidth = width !== undefined ? width - (remToPx(ChipIconSizeInRem) * displayActions.length) : undefined;

  const textVariant = sizeContextComputed === SizeVariant.small ? TypoVariant.buttonSmall : TypoVariant.buttonMain;

  let computedBorderColor: string;
  if (borderColor) {
    computedBorderColor = borderColor;
  } else if (borderStyle === undefined) {
    computedBorderColor = chipColor;
  } else {
    computedBorderColor = computeBorderColor(chipColor);
  }

  return (
    <div
      className={classnames({ [classes.chipContainer]: true, [classes[`chip_${sizeContextComputed}_${hierarchyVariant}`]]: true, [classes.clickableChip]: Boolean(onClick) })}
      tabIndex={hoverActions.length > 0 || displayActions.length > 0 || Boolean(onClick) ? 0 : undefined}
      role="button"
      onFocus={() => setIsHovered(true)}
      onBlur={() => setIsHovered(false)}
      onClick={onClick}
      onKeyDown={onClick ? (event) => {
        if (event?.key === 'Enter') {
          onClick();
        }
      } : undefined}
    >
      {isIconWithBorder(icon) ? (
        <div
          className={
            classnames(classes.chipElement, classes.chipStartWithinBorder, classes.chipEndWithinBorder, classes.withBorder, classes.withBorderRadiusLeft, classes.iconWithBorder)
          }
          style={joinObjects(
            {
              backgroundColor: icon.color,
              borderColor: icon.borderColor ?? computeBorderColor(icon.color),
              borderStyle: borderStyle ?? 'solid',
            },
            borderWidth ? { borderWidth: remToPx(borderWidth) } : undefined
          )}
        >
          <Tooltip title={formatOrUndef(tooltip ?? text)} asFlexbox>
            <Icon name={icon.name} color={getMostReadableColorFromBackgroundColor(icon.color)} />
          </Tooltip>
        </div>
      ) : null}
      <div
        className={classnames({
          [classes.chipMain]: true,
          [classes.withBorder]: borderStyle !== undefined,
          [classes.withBorderRadiusLeft]: !isIconWithBorder(icon),
          [classes.withBorderRadiusRight]: true,
        })}
        style={joinObjects(
          {
            backgroundColor,
            borderColor: computedBorderColor,
            borderStyle,
          },
          borderWidth ? { borderWidth: remToPx(borderWidth) } : undefined
        )}
      >
        <Tooltip title={formatOrUndef(tooltip ?? text)} asFlexbox>
          <div
            className={classnames({
              [classes.chipElement]: true,
              [classes.chipStartWithinBorder]: borderStyle !== undefined,
              [classes.chipEnd]: displayActions.length === 0 && borderStyle === undefined,
              [classes.chipEndWithinBorder]: displayActions.length === 0 && borderStyle !== undefined,
            })}
            style={{
              width: mainChipElementWidth,
              minWidth: isHoveredDisplayDebounced && isHoveredHideDebounced ? remToPx(ChipIconSizeInRem) * hoverActions.length : undefined,
            }}
            onMouseOverCapture={() => setIsHovered(true)}
            onMouseOutCapture={() => setIsHovered(false)}
          >
            {icon && !isIconWithBorder(icon) ? (
              <Icon
                name={typeof icon === 'object' ? icon.name : icon}
                colorVariant={isIconWithColor(icon) ? icon.colorVariant : undefined}
                color={!isIconWithColor(icon) ? getMostReadableColorFromBackgroundColor(chipColor) : undefined}
              />
            ) : null}
            {startIcons?.map((i) => (
              <Icon
                key={i.key}
                name={i.icon}
                colorVariant={i.colorVariant}
                color={i.colorVariant === undefined && i.color !== undefined ? i.color : undefined}
                tooltip={i.tooltip}
              />
            ))}
            <ColorChip color={squareColor} />
            <Typo variant={textVariant} maxLine={1} color={getMostReadableColorFromBackgroundColor(backgroundColor)}>
              {formatOrUndef(text)}
            </Typo>
            {endIcons?.map((i) => (
              <Icon
                key={i.key}
                name={i.icon}
                colorVariant={i.colorVariant}
                color={i.colorVariant === undefined && i.color !== undefined ? i.color : undefined}
                tooltip={i.tooltip}
              />
            ))}
          </div>
        </Tooltip>
        {isHoveredDisplayDebounced && isHoveredHideDebounced && hoverActions.length > 0 ? (
          <span
            className={classes.hoverActionsRelativeContainer}
            onMouseOverCapture={() => setIsHovered(true)}
            onMouseOutCapture={() => setIsHovered(false)}
            onFocus={() => setIsHovered(true)}
            onBlur={() => setIsHovered(false)}
          >
            <span className={classes.hoverActionsContainer}>
              {hoverActions.map((action, index) => (
                renderAction(
                  action,
                  isHoveredHoverAction[index],
                  (event: 'enter' | 'leave') => () => {
                    setIsHoveredHoverAction((current) => {
                      const arr = [...current];
                      arr[index] = event === 'enter';
                      return arr;
                    });
                  }
                )
              ))}
            </span>
          </span>
        ) : null}
        {displayActions.map((action, index) => (
          renderAction(
            action,
            isHoveredDisplayAction[index],
            (event: 'enter' | 'leave') => () => {
              setIsHoveredDisplayAction((current) => {
                const arr = [...current];
                arr[index] = event === 'enter';
                return arr;
              });
            }
          )
        ))}
      </div>
    </div>
  );
};

export default Chip;
