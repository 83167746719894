import type { FunctionComponent, ReactElement, RefObject } from 'react';
import { createContext, useContext } from 'react';
import { newError } from 'yooi-utils';

const Context = createContext<RefObject<HTMLDivElement | null> | undefined>(undefined);

interface CollaborationContentRefProviderProps {
  contentRef: RefObject<HTMLDivElement | null>,
  children: ReactElement | null,
}

export const CollaborationContentRefProvider: FunctionComponent<CollaborationContentRefProviderProps> = ({ contentRef, children }) => (
  <Context.Provider value={contentRef}>
    {children}
  </Context.Provider>
);

const useCollaborationContentRef = (): RefObject<HTMLDivElement | null> => {
  const ref = useContext(Context);
  if (ref === undefined) {
    throw newError('ContentRefContext has not been initialized, add a CollaborationContentContextProvider in the React parent component hierarchy');
  }

  return ref;
};

export default useCollaborationContentRef;
