import type { FunctionComponent } from 'react';
import { Field_Title, Group_GrantedOnCreation, Group_Users, GroupMembershipDimension } from 'yooi-modules/modules/conceptModule/ids';
import Checkbox from '../../../components/atoms/Checkbox';
import { IconName } from '../../../components/atoms/Icon';
import SpacingLine from '../../../components/molecules/SpacingLine';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { getFieldHandler } from '../../_global/fields/FieldLibrary';

interface AdminGroupDetailProps {
  groupId: string,
}

const AdminGroupDetail: FunctionComponent<AdminGroupDetailProps> = ({ groupId }) => {
  const store = useStore();

  const group = store.getObject(groupId);
  const usersFieldHandler = getFieldHandler(store, Group_Users);

  return (
    <VerticalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={store.getObject(Group_Users)[Field_Title] as string} iconName={IconName.person} />
        <BlockContent>
          {usersFieldHandler?.renderField?.({
            readOnly: false,
            dimensionsMapping: { [GroupMembershipDimension]: groupId },
          })}
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Granted on user creation`} iconName={IconName.subject} />
        <BlockContent padded>
          <SpacingLine>
            <Checkbox
              checked={Boolean(group[Group_GrantedOnCreation])}
              onChange={() => {
                store.updateObject(groupId, { [Group_GrantedOnCreation]: !group[Group_GrantedOnCreation] });
              }}
            />
          </SpacingLine>
        </BlockContent>
      </HorizontalBlock>
    </VerticalBlock>
  );
};

export default AdminGroupDetail;
