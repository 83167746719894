import type { FunctionComponent } from 'react';
import { Opacity } from '../../theme/base';
import { generateColorFromOpacity, getMostReadableColorFromBackgroundColor } from '../../theme/colorUtils';
import makeStyles from '../../utils/makeStyles';
import useTheme from '../../utils/useTheme';
import Spinner, { SpinnerVariant } from './Spinner';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
}, 'leftPanelLoader');

const LeftPanelLoader: FunctionComponent = () => {
  const theme = useTheme();
  const classes = useStyles();

  const mostReadableColor = getMostReadableColorFromBackgroundColor(theme.color.background.neutral.default);
  const color = generateColorFromOpacity(mostReadableColor, theme.color.background.neutral.default, Opacity.fifty);

  return (
    <span className={classes.container}>
      <Spinner size={SpinnerVariant.big} color={color} />
    </span>
  );
};

export default LeftPanelLoader;
