import type { FunctionComponent } from 'react';
import type { FieldStoreObject, PathStep } from 'yooi-modules/modules/conceptModule';
import {
  BLOCK_PARAMETER_CURRENT,
  createValuePathResolver,
  FILTER_PARAMETER_LOGGED_USER,
  InstanceReferenceType,
  isSingleFieldResolution,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import BlockContent from '../../../components/templates/BlockContent';
import useAuth from '../../../store/useAuth';
import useStore from '../../../store/useStore';
import { getBlockParameterDefinitionCurrent } from '../../_global/conceptFilterIdUtils';
import BlockField from '../../_global/fields/_global/BlockField';
import { getFieldHandler } from '../../_global/fields/FieldLibrary';
import { getFieldLabel } from '../../_global/fieldUtils';
import { getLoggedUserParameterDefinition } from '../../_global/filter/filterUtils';
import ActivityIndicator from '../../_global/multiplayer/ActivityIndicator';
import { createPathConfigurationHandler } from '../../_global/pathConfigurationHandler';

interface FieldRendererProps {
  fieldId: string,
  conceptId: string,
  readOnly: boolean,
}

const FieldRenderer: FunctionComponent<FieldRendererProps> = ({ fieldId, conceptId, readOnly }) => {
  const store = useStore();
  const { loggedUserId } = useAuth();

  const conceptDefinitionId = store.getObject(conceptId)[Instance_Of] as string;

  const path: PathStep[] = [{ type: PathStepType.dimension, conceptDefinitionId }, {
    type: PathStepType.mapping,
    mapping: { id: conceptId, type: InstanceReferenceType.instance },
  }, { type: PathStepType.field, fieldId }];

  const pathConfigurationHandler = createPathConfigurationHandler(store, [
    getBlockParameterDefinitionCurrent(conceptDefinitionId),
    getLoggedUserParameterDefinition(),
  ]);
  const lastField = pathConfigurationHandler.getLastFieldInformation(path);
  if (!lastField || store.getObjectOrNull(lastField.fieldId) === null) {
    return null;
  }

  const fieldHandler = getFieldHandler(store, lastField.fieldId);
  const field = store.getObjectOrNull<FieldStoreObject>(lastField.fieldId);
  if (!fieldHandler || !field) {
    return null;
  }

  const valuePathResolver = createValuePathResolver(store, {
    [BLOCK_PARAMETER_CURRENT]: { type: 'single' as const, id: conceptId },
    [FILTER_PARAMETER_LOGGED_USER]: { type: 'single' as const, id: loggedUserId },
  });
  const lastFieldResolved = valuePathResolver.resolvePathField(lastField.pathToField);
  if (lastFieldResolved !== undefined && !(lastFieldResolved instanceof Error) && !isSingleFieldResolution(lastFieldResolved)) {
    return null;
  }

  const icon = fieldHandler.configuration.getIcon();
  const title = getFieldLabel(store, field);

  const documentation = fieldHandler.configuration.getDocumentation();

  // If we weren't able to reach a field, we are dealing with a long path
  // If we don't have any mapping, we end up on a different instance (or global)
  if (!lastFieldResolved || lastFieldResolved instanceof Error || lastFieldResolved.dimensionsMapping === undefined) {
    return (
      <BlockField
        fieldId={lastField.fieldId}
        documentation={documentation}
        icon={typeof icon === 'object' ? icon.name : icon}
        title={title}
      >
        <BlockContent />
      </BlockField>
    );
  }

  const additionalBlockFieldProps = fieldHandler.getAdditionalBlockFieldProps?.() ?? {};
  const activityProperties = fieldHandler.getActivityProperties?.();

  return (
    <BlockField
      {...additionalBlockFieldProps}
      fieldId={lastField.fieldId}
      icon={typeof icon === 'object' ? icon.name : icon}
      title={title}
      documentation={documentation}
      readOnly={readOnly}
      conceptId={conceptId}
    >
      <BlockContent
        fullWidth={additionalBlockFieldProps.fullWidth}
        hideOverflowX={additionalBlockFieldProps.hideOverflowX}
        action={
          activityProperties ? (<ActivityIndicator propertyIds={activityProperties} instanceIds={conceptId} />) : null
        }
      >
        {fieldHandler.renderField?.({ dimensionsMapping: lastFieldResolved.dimensionsMapping, readOnly, path })}
      </BlockContent>
    </BlockField>
  );
};

export default FieldRenderer;
