import { OverridableDisplayType } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import i18n from '../../../../utils/i18n';

const displayTypeConfig: Record<OverridableDisplayType, string> = {
  [OverridableDisplayType.Value]: 'Value',
  [OverridableDisplayType.Configuration]: 'Configuration',
};

export const getOverridableDisplayTypeLabel = (displayType: OverridableDisplayType | undefined): string => i18n.t(displayTypeConfig[displayType ?? OverridableDisplayType.Value]);

interface OverridableDisplayTypeOption {
  id: OverridableDisplayType,
  label: string,
}

const getOverridableDisplayTypeOption = (displayType: OverridableDisplayType): OverridableDisplayTypeOption => ({
  id: displayType,
  label: getOverridableDisplayTypeLabel(displayType),
});

export const getOptionalOverridableDisplayTypeOption = (displayType: OverridableDisplayType | undefined): OverridableDisplayTypeOption | undefined => (
  displayType ? getOverridableDisplayTypeOption(displayType) : undefined
);

export const getOverridableDisplayTypeOptions = (): OverridableDisplayTypeOption[] => Object.values(OverridableDisplayType).map(getOverridableDisplayTypeOption);
