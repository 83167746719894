import type { CardsViewResolution } from './cards/cardsViewResolution';
import type { ChipViewResolution } from './chip/chipViewResolution';
import type { GaugeViewResolution } from './gauge/gaugeViewResolution';
import type { LineChartViewResolution } from './lineChart/lineChartViewResolution';
import type { MatrixViewResolution } from './matrix/matrixViewResolution';
import type { StructuralBarChartViewResolution } from './structuralBarChart/structuralBarChartViewResolution';
import type { SwimlaneViewResolution } from './swimlane/swimlaneViewResolution';
import type { TableViewResolution } from './table/tableViewResolution';
import type { TemporalBarChartViewResolution } from './temporalBarChart/temporalBarChartViewResolution';
import type { TimelineViewResolution } from './timeline/timelineViewResolution';
import type { TimeseriesTableViewResolution } from './timeseriesTable/timeseriesTableViewResolution';

export class SeriesResolutionError extends Error {
  override readonly name = 'SeriesResolutionError';

  readonly seriesLabel: string;

  constructor(seriesLabel: string, cause?: Error) {
    super('Invalid series resolution');
    this.seriesLabel = seriesLabel;
    this.cause = cause;
  }
}

export interface ViewResolutionError {
  type: 'error',
  error: string,
}

export type ViewResolution =
  TableViewResolution
  | CardsViewResolution
  | StructuralBarChartViewResolution
  | GaugeViewResolution
  | TemporalBarChartViewResolution
  | LineChartViewResolution
  | SwimlaneViewResolution
  | MatrixViewResolution
  | ChipViewResolution
  | TimelineViewResolution
  | TimeseriesTableViewResolution;

export const isResolutionError = (resolution: object | ViewResolutionError): resolution is ViewResolutionError => ((resolution as ViewResolutionError).type === 'error');
