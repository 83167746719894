import type { ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import type { ParametersMapping, PathStep, WrappedRichText } from '../../../conceptModule';
import { createValuePathResolver, isSingleValueResolution } from '../../../conceptModule';
import { isInstanceOf } from '../../../typeModule';
import { IFrameField, IFrameField_Url } from '../../ids';
import type { IFrameFieldStoreObject } from '../../model';

export enum UrlType {
  constant = 'Constant',
  field = 'Field',
}

interface IFrameDirectConfiguration {
  type: UrlType.constant,
  url?: string,
}

interface IFrameTargetPathConfiguration {
  type: UrlType.field,
  path?: PathStep[],
}

export type IFrameConfiguration = IFrameDirectConfiguration | IFrameTargetPathConfiguration;

export const isDirectUrl = (configuration: IFrameConfiguration | undefined): configuration is IFrameDirectConfiguration => configuration?.type === UrlType.constant;

export const isTargetPathUrl = (configuration: IFrameConfiguration | undefined): configuration is IFrameTargetPathConfiguration => configuration?.type === UrlType.field;

export const resolveIFramePath = (store: ObjectStoreWithTimeseries, field: StoreObject | null, parametersMapping: ParametersMapping): string | undefined => {
  if (!field || !isInstanceOf(field, IFrameField)) {
    return undefined;
  } else {
    const configuration = (field as IFrameFieldStoreObject)[IFrameField_Url];

    if (isDirectUrl(configuration)) {
      return configuration.url;
    } else if (isTargetPathUrl(configuration)) {
      const pathResolver = createValuePathResolver(store, parametersMapping);
      const resolution = pathResolver.resolvePathValue<WrappedRichText>(configuration.path ?? []);
      return isSingleValueResolution(resolution) && resolution.value ? resolution.value.toString() : undefined;
    } else {
      return undefined;
    }
  }
};
