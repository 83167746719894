import classnames from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import { joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import Tooltip from '../../../../../components/atoms/Tooltip';
import Typo from '../../../../../components/atoms/Typo';
import PercentageEvolution from '../../../../../components/charts/LineChart/PercentageEvolution';
import { buildPadding, getSpacingAsNumber, Spacing } from '../../../../../theme/spacingDefinition';
import makeStyles from '../../../../../utils/makeStyles';
import { approximateNumber } from '../../../../../utils/numberUtils';
import { remToPx } from '../../../../../utils/sizeUtils';
import useTheme from '../../../../../utils/useTheme';

const CHART_WIDTH = 11.2;
const VALUE_WIDTH = 4;
const EVOLUTION_WIDTH = 5.2;
const HEIGHT = 2.2;
const GAP = getSpacingAsNumber(Spacing.s);
const TOTAL_WIDTH = CHART_WIDTH + GAP + VALUE_WIDTH + GAP + EVOLUTION_WIDTH + GAP;

const useStyles = makeStyles({
  container: joinObjects(
    buildPadding({ x: Spacing.s }),
    { height: '100%', overflow: 'hidden' }
  ),
  gridContainer: {
    alignItems: 'center',
    display: 'grid',
    columnGap: `${GAP}rem`,
    gridTemplateColumns: `${CHART_WIDTH}rem ${VALUE_WIDTH}rem ${EVOLUTION_WIDTH}rem`,
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  chartContainer: {
    height: `${HEIGHT}rem`,
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  unitContainer: {
    display: 'flex',
  },
}, 'temporalChartInline');

interface TemporalChartIsLoading {
  loading: boolean,
}

interface TemporalChartInError {
  errorMessage: string,
}

interface TemporalChartLoaded {
  chartComponent: (height: number, width: number) => (ReactElement | null),
  evolution: number | undefined,
  lastValue: number | undefined,
  unit?: string,
}

const isLoading = (props: TemporalChartInlineProps): props is TemporalChartIsLoading => (props as TemporalChartIsLoading).loading;
const isInError = (props: TemporalChartInlineProps): props is TemporalChartInError => Boolean((props as TemporalChartInError).errorMessage);
const isLoaded = (props: TemporalChartInlineProps): props is TemporalChartLoaded => !isLoading(props) && !isInError(props);

type TemporalChartInlineProps = TemporalChartIsLoading | TemporalChartInError | TemporalChartLoaded;

const TemporalChartInline: FunctionComponent<TemporalChartInlineProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles();

  if (isInError(props)) {
    const { errorMessage } = props;
    return (
      <div className={classnames(classes.container, classes.errorContainer)}>
        <Icon name={IconName.dangerous} colorVariant={IconColorVariant.error} tooltip={errorMessage} />
      </div>
    );
  } else if (isLoaded(props)) {
    const { chartComponent, evolution, lastValue, unit } = props;

    return (
      <div className={classnames(classes.container, classes.gridContainer)}>
        <div className={classes.chartContainer}>
          {chartComponent(remToPx(2.2), remToPx(11.2))}
        </div>
        {lastValue !== undefined ? (
          <div className={classes.unitContainer}>
            <Typo maxLine={1}>
              <Tooltip title={`${approximateNumber(lastValue)}${unit ? ` ${unit}` : ''}`}>
                <span>{`${approximateNumber(lastValue)}${unit ? ` ${unit}` : ''}`}</span>
              </Tooltip>
            </Typo>
          </div>
        ) : <span />}
        {evolution !== undefined && (
          <PercentageEvolution value={evolution} />
        )}
      </div>
    );
  } else {
    return (
      <div className={classnames(classes.container, classes.loadingContainer)}>
        <ContentLoader
          height={remToPx(HEIGHT)}
          viewBox={`0 0 ${remToPx(TOTAL_WIDTH)} ${remToPx(HEIGHT)}`}
          backgroundColor={theme.color.background.neutral.subtle}
          foregroundColor={theme.color.background.neutral.default}
        >
          <rect x="0" y="0" width={remToPx(CHART_WIDTH)} height={remToPx(HEIGHT)} />
          <rect x={remToPx(CHART_WIDTH) + remToPx(GAP)} y="0" width={remToPx(VALUE_WIDTH)} height={remToPx(HEIGHT)} />
          <rect x={remToPx(CHART_WIDTH) + remToPx(GAP) + remToPx(VALUE_WIDTH) + remToPx(GAP)} y="0" width={remToPx(EVOLUTION_WIDTH)} height={remToPx(HEIGHT)} />
        </ContentLoader>
      </div>
    );
  }
};

export default TemporalChartInline;
