import type { CardColorMode, ConceptDefinitionStoreObject, DimensionsMapping, FieldStoreObject, ParametersMapping, PathStep } from 'yooi-modules/modules/conceptModule';
import {
  createValuePathResolver,
  dimensionsMappingToParametersMapping,
  getPathLastFieldInformation,
  getPathReturnedConceptDefinitionId,
  isDimensionStep,
  isSingleFieldResolution,
  isSingleRelationalType,
  isSingleValueResolution,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import { BooleanField, ColorField, ConceptDefinition_Icon, IconField } from 'yooi-modules/modules/conceptModule/ids';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { ViewType } from 'yooi-modules/modules/dashboardModule';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import type { ACLHandler } from '../../../../store/useAcl';
import type { FrontObjectStore } from '../../../../store/useStore';
import { formatErrorForUser } from '../../errorUtils';
import { getFieldConfigurationHandler } from '../../fields/FieldLibrary';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { getViewFilterFunction } from '../../listFilterFunctions';
import { getSeriesLabel } from '../common/series/viewWithSeriesFeatureUtils';
import type { CreateAndLinkOptions } from '../common/viewUtils';
import { buildFilterFunctionFromDimensions, getCreateAndLinkOptions } from '../common/viewUtils';
import { computeDimension, DataResolutionError, getDimensionLabel } from '../data/dataResolution';
import type { ViewResolutionError } from '../viewResolutionUtils';
import type { CardsViewResolvedDefinition } from './cardsViewDefinitionHandler';

interface FieldResolution {
  type: 'field',
  fieldId: string,
  icon: IconName,
  resolve: (parametersMapping: ParametersMapping) => (DimensionsMapping | undefined),
}

interface CardFieldEntry extends FieldResolution {
  key: string,
  label: string,
  displayOptions: Record<string, unknown>,
}

interface InstanceResolution {
  type: 'instance',
  icon: IconName,
  resolve: (parametersMapping: ParametersMapping) => (string | undefined),
}

interface CardInstanceEntry extends InstanceResolution {
  key: string,
  label: string,
}

export type CardEntry = CardFieldEntry | CardInstanceEntry;

export interface ColorInstance {
  type: 'instance',
  as: CardColorMode,
  resolve: (parametersMapping: ParametersMapping) => (string | undefined),
}

export interface ColorColorField {
  type: 'field',
  as: CardColorMode,
  fieldId: string,
  resolve: (parametersMapping: ParametersMapping) => (DimensionsMapping | undefined),
}

export interface CardIconField {
  fieldId: string,
  resolve: (parametersMapping: ParametersMapping) => (DimensionsMapping | undefined),
}

export interface CardBooleanField {
  fieldId: string,
  resolve: (parametersMapping: ParametersMapping) => (DimensionsMapping | undefined),
}

export interface CardsViewResolution extends CreateAndLinkOptions {
  type: ViewType.Cards,
  cards: { key: string, dimensionsMapping: DimensionsMapping }[],
  header: CardEntry[],
  body: CardEntry[],
  color: ColorInstance | ColorColorField | undefined,
  icon: CardIconField | undefined,
  boolean: CardBooleanField | undefined,
  sort: ((FieldResolution | InstanceResolution) & { direction: 'asc' | 'desc' }) | undefined,
  filter: (card: { key: string, dimensionsMapping: DimensionsMapping }) => boolean,
}

const buildResolution = (store: FrontObjectStore, path: PathStep[]): FieldResolution | InstanceResolution | undefined => {
  if (path.length === 2 && path[1].type === PathStepType.mapping) {
    return {
      type: 'instance',
      icon: isDimensionStep(path[0])
        ? store.getObjectOrNull<ConceptDefinitionStoreObject>(path[0].conceptDefinitionId)?.[ConceptDefinition_Icon] as IconName | undefined ?? IconName.category
        : IconName.category,
      resolve: (parametersMapping) => {
        const valuePathResolver = createValuePathResolver(store, parametersMapping);
        const resolvedValue = valuePathResolver.resolvePathValue<StoreObject | undefined>(path);
        if (isSingleValueResolution(resolvedValue) && resolvedValue.value) {
          return resolvedValue.value.id;
        } else {
          return undefined;
        }
      },
    };
  } else {
    const lastField = getPathLastFieldInformation(path);
    if (lastField && store.getObjectOrNull(lastField.fieldId)) {
      const icon = getFieldConfigurationHandler(store, lastField.fieldId).getIcon();
      return {
        type: 'field',
        fieldId: lastField.fieldId,
        icon: typeof icon === 'object' ? icon.name : icon,
        resolve: (parametersMapping) => {
          const valuePathResolver = createValuePathResolver(store, parametersMapping);
          const resolvedField = valuePathResolver.resolvePathField(path);
          if (isSingleFieldResolution(resolvedField) && resolvedField.dimensionsMapping) {
            return resolvedField.dimensionsMapping;
          } else {
            return undefined;
          }
        },
      };
    } else {
      return undefined;
    }
  }
};

export const resolveHeader = (
  store: FrontObjectStore,
  header: CardsViewResolvedDefinition['header'] | undefined,
  getLabel: (index: number, path: PathStep[]) => string
): CardsViewResolution['header'] => header?.map((definition, index) => {
  const entry = buildResolution(store, definition.path);
  if (!entry) {
    return undefined;
  } else if (entry.type === 'instance') {
    return joinObjects({ key: definition.id, label: getLabel(index, definition.path), displayOptions: definition.displayOptions }, entry);
  } else {
    return joinObjects({ key: definition.id, label: getLabel(index, definition.path), displayOptions: definition.displayOptions }, entry);
  }
}).filter(filterNullOrUndefined) ?? [];

export const resolveBody = (
  store: FrontObjectStore,
  body: CardsViewResolvedDefinition['body'] | undefined,
  getLabel: (label: string | undefined, index: number, path: PathStep[]) => string
): CardsViewResolution['body'] => body?.map((definition, index) => {
  const entry = buildResolution(store, definition.path);
  if (!entry) {
    return undefined;
  } else if (entry.type === 'instance') {
    return joinObjects({ key: definition.id, label: getLabel(definition.label, index, definition.path), displayOptions: {} }, entry);
  } else {
    return joinObjects({ key: definition.id, label: getLabel(definition.label, index, definition.path), displayOptions: {} }, entry);
  }
}).filter(filterNullOrUndefined) ?? [];

export const resolveColor = (store: ObjectStoreWithTimeseries, color: CardsViewResolvedDefinition['color']): CardsViewResolution['color'] => {
  if (color !== undefined) {
    const { path, as } = color;
    const lastFieldInformation = getPathLastFieldInformation(path);
    if (lastFieldInformation !== undefined) {
      const lastField = store.getObjectOrNull<FieldStoreObject>(lastFieldInformation.fieldId);
      if (lastField !== null) {
        if (isInstanceOf(lastField, ColorField)) {
          return {
            type: 'field',
            fieldId: lastField.id,
            as,
            resolve: (parametersMapping) => {
              const valuePathResolver = createValuePathResolver(store, parametersMapping);
              const resolvedField = valuePathResolver.resolvePathField(path);
              if (isSingleFieldResolution(resolvedField) && resolvedField.dimensionsMapping) {
                return resolvedField.dimensionsMapping;
              } else {
                return undefined;
              }
            },
          };
        } else if (isSingleRelationalType(lastField[Instance_Of])) {
          return {
            type: 'instance',
            as,
            resolve: (parametersMapping) => {
              const valuePathResolver = createValuePathResolver(store, parametersMapping);
              const resolvedValue = valuePathResolver.resolvePathValue<StoreObject | undefined>(path);
              if (isSingleValueResolution(resolvedValue) && resolvedValue.value) {
                return resolvedValue.value.id;
              } else {
                return undefined;
              }
            },
          };
        }
      }
    } else if (path.length === 2 && path[1].type === PathStepType.mapping) {
      return {
        type: 'instance',
        as,
        resolve: (parametersMapping) => {
          const valuePathResolver = createValuePathResolver(store, parametersMapping);
          const resolvedValue = valuePathResolver.resolvePathValue<StoreObject | undefined>(path);
          if (isSingleValueResolution(resolvedValue) && resolvedValue.value) {
            return resolvedValue.value.id;
          } else {
            return undefined;
          }
        },
      };
    }
  }
  return undefined;
};

export const resolveIcon = (store: FrontObjectStore, icon: CardsViewResolvedDefinition['icon']): CardsViewResolution['icon'] => {
  if (icon !== undefined) {
    const resolution = buildResolution(store, icon.path);
    if (resolution?.type === 'field' && isInstanceOf(store.getObject(resolution.fieldId), IconField)) {
      return { fieldId: resolution.fieldId, resolve: resolution.resolve };
    }
  }
  return undefined;
};

export const resolveBoolean = (store: FrontObjectStore, boolean: CardsViewResolvedDefinition['boolean']): CardsViewResolution['boolean'] => {
  if (boolean !== undefined) {
    const resolution = buildResolution(store, boolean.path);
    if (resolution?.type === 'field' && isInstanceOf(store.getObject(resolution.fieldId), BooleanField)) {
      return { fieldId: resolution.fieldId, resolve: resolution.resolve };
    }
  }
  return undefined;
};

export const resolveCardsView = (
  store: FrontObjectStore,
  aclHandler: ACLHandler,
  viewDimensions: ViewDimension[],
  viewDefinition: CardsViewResolvedDefinition,
  parametersMapping: ParametersMapping,
  filterConfiguration: FilterConfiguration | undefined,
  readOnly: boolean | undefined
): CardsViewResolution | ViewResolutionError => {
  const mappings = computeDimension(store, parametersMapping, viewDimensions).mapReduceHandler(viewDimensions);
  if (mappings instanceof DataResolutionError) {
    return { type: 'error', error: formatErrorForUser(store, mappings) };
  }

  let sort: CardsViewResolution['sort'];
  if (viewDefinition.sort !== undefined) {
    const sortResolution = buildResolution(store, viewDefinition.sort.path);
    if (sortResolution !== undefined) {
      sort = sortResolution.type === 'instance'
        ? joinObjects(sortResolution, { direction: viewDefinition.sort.direction })
        : joinObjects(sortResolution, { direction: viewDefinition.sort.direction });
    }
  }

  const dimensionFilterFunction = buildFilterFunctionFromDimensions(store, viewDimensions, parametersMapping);
  const filterConfFunction = getViewFilterFunction(
    store,
    viewDimensions.map((dimension, index) => {
      const dimConceptDefinitionId = getPathReturnedConceptDefinitionId(store, dimension.path);
      return dimConceptDefinitionId ? {
        id: dimension.id,
        label: getDimensionLabel(store, dimension.label, index, dimension.path),
        typeId: dimConceptDefinitionId,
      } : undefined;
    }).filter(filterNullOrUndefined),
    filterConfiguration,
    parametersMapping
  );

  return joinObjects(
    {
      type: ViewType.Cards as const,
      cards: mappings.map((dimensions) => ({ key: Object.values(dimensions).join('|'), dimensionsMapping: dimensions })),
      header: resolveHeader(
        store,
        viewDefinition.header,
        (index, path) => getSeriesLabel(store, undefined, index, path, viewDimensions, Object.keys(parametersMapping))
      ),
      body: resolveBody(
        store,
        viewDefinition.body,
        (label, index, path) => getSeriesLabel(store, label, index, path, viewDimensions, Object.keys(parametersMapping))
      ),
      icon: resolveIcon(store, viewDefinition.icon),
      boolean: resolveBoolean(store, viewDefinition.boolean),
      color: resolveColor(store, viewDefinition.color),
      sort,
      filter: (({ dimensionsMapping }) => {
        const mapping = dimensionsMappingToParametersMapping(dimensionsMapping);
        if (filterConfFunction !== undefined) {
          return filterConfFunction(mapping) && dimensionFilterFunction(mapping);
        } else {
          return dimensionFilterFunction(mapping);
        }
      }) satisfies CardsViewResolution['filter'],
    },
    viewDefinition.readOnly || readOnly || viewDimensions.length !== 1
      ? {}
      : getCreateAndLinkOptions(
        store,
        aclHandler,
        parametersMapping,
        viewDimensions,
        mappings.map((dimension) => Object.values(dimension)[0]).filter(filterNullOrUndefined),
        () => true
      )
  );
};
