import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useCallback } from 'react';
import { getFields, getFieldUtilsHandler } from 'yooi-modules/modules/conceptModule';
import {
  AssociationField,
  ConceptDefinition_Color,
  ConceptDefinition_Timeline,
  ConceptDefinition_TimelineDependency,
  ConceptDefinition_TimelineGroupBy,
  ConceptDefinition_TimelineProgress,
  DateField,
  EmbeddingField,
  RelationMultipleField,
  RelationSingleField,
  TimelineField,
} from 'yooi-modules/modules/conceptModule/ids';
import type { TimelineViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { addTimeToDate, DateStorageTypeKeys, DurationType, filterNullOrUndefined, formatForStorage, joinObjects, periodicities, PeriodicityType } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import Typo from '../../../../../components/atoms/Typo';
import CompositeField, { CompositeFieldVariants, DropdownSectionTitleVariants } from '../../../../../components/molecules/CompositeField';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import ToggleButton from '../../../../../components/molecules/ToggleButton';
import type { FrontObjectStore } from '../../../../../store/useStore';
import useStore from '../../../../../store/useStore';
import { buildPadding, Spacing, spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import { SessionStorageKeys, useMultipleSessionStorageState, useSessionStorageState } from '../../../../../utils/useSessionStorage';
import { SizeContextProvider, SizeVariant } from '../../../../../utils/useSizeContext';
import useTheme from '../../../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../../../utils/useUsageContext';
import StoreDateRangeInput from '../../../input/StoreDateRangeInput';
import {
  getChipOptions,
  getColorField,
  getConceptDefinitionNameOrEntity,
  getTimelineDependencyField,
  getTimelineField,
  getTimelineGroupByField,
  getTimelineProgressField,
  listColorFieldOptions,
  listDependenciesFieldOptions,
  listGroupByFieldOptions,
  listProgressFieldOptions,
  listTimelineFieldOptions,
} from '../../../modelTypeUtils';
import type { ChipOption } from '../../../modelTypeUtilsType';
import type { TimelineConfiguration } from '../../../sessionStorageTypes';
import type { TimelineViewResolvedDefinition } from '../../../views/timeline/timelineViewHandler';
import { getViewDefinitionHandler } from '../../../views/viewDsl';

const useStyles = makeStyles({
  buttonOptions: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.s,
  },
  timelineContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowX: 'hidden',
  },
  timelineBlockContainer: buildPadding({ left: Spacing.s }),
  timelineViewContainer: buildPadding({ x: Spacing.splus }),
  timelineConfigContainer: joinObjects(
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    buildPadding({ bottom: Spacing.s })
  ),
}, 'conceptTimelineOptions');

const listRelatedFieldWithTargetConceptDefinitions = (store: FrontObjectStore, conceptDefinitionId: string): { fieldId: string, targetConceptDefinitionId: string }[] => (
  getFields(store, conceptDefinitionId, [EmbeddingField, RelationSingleField, AssociationField, RelationMultipleField])
    .map((field) => ({ targetConceptDefinitionId: getFieldUtilsHandler(store, field.id)?.getTargetType?.()?.id, fieldId: field.id }))
    .filter((result): result is { fieldId: string, targetConceptDefinitionId: string } => Boolean(result.targetConceptDefinitionId))
);
const listRelatedConceptDefinitionChips = (store: FrontObjectStore, conceptDefinitionId: string): ChipOption[] => {
  const conceptDefinitionIds = new Set<string>(
    listRelatedFieldWithTargetConceptDefinitions(store, conceptDefinitionId)
      .map(({ targetConceptDefinitionId }) => targetConceptDefinitionId)
  );

  return (
    Array.from(conceptDefinitionIds)
      .filter((relatedConceptDefinitionId) => getFields(store, relatedConceptDefinitionId, [TimelineField, DateField]).length > 0)
      .map((relatedConceptDefinitionId) => getChipOptions(store, relatedConceptDefinitionId))
      .filter(filterNullOrUndefined)
  );
};

interface ConceptTimelineOptionsProps {
  widgetId?: string,
  viewDefinition?: TimelineViewStoredDefinition,
  viewDimensions?: ViewDimension[],
  conceptDefinitionId: string | undefined,
  filterKey: string,
}

const ConceptTimelineOptions: FunctionComponent<ConceptTimelineOptionsProps> = ({ widgetId, viewDefinition, viewDimensions, conceptDefinitionId, filterKey }) => {
  const store = useStore();
  const theme = useTheme();
  const classes = useStyles();

  const resolvedViewDefinition = viewDefinition && viewDimensions
    ? getViewDefinitionHandler(viewDefinition).getDefinition(store, viewDimensions) as TimelineViewResolvedDefinition
    : undefined;

  const initialTimelineConfig: TimelineConfiguration = {
    [ConceptDefinition_Color]: undefined,
    [ConceptDefinition_Timeline]: undefined,
    [ConceptDefinition_TimelineProgress]: undefined,
    [ConceptDefinition_TimelineGroupBy]: undefined,
    [ConceptDefinition_TimelineDependency]: undefined,
    children: [],
    dependencies: false,
    showItself: true,
    timelineRange: {
      from: {
        type: DateStorageTypeKeys.date,
        value: formatForStorage(periodicities[PeriodicityType.month].getPreviousDateInAmountOfPeriod(new Date(), 6)) ?? undefined,
      },
      to: {
        type: DateStorageTypeKeys.date,
        value: formatForStorage(addTimeToDate(periodicities[PeriodicityType.month].getNextDateInAmountOfPeriod(new Date(), 6), 1, DurationType.milliseconds)) ?? undefined,
      },
      period: PeriodicityType.month,
    },
  };

  const [timelineConfig, , updateTimelineConfig] = useSessionStorageState<TimelineConfiguration>(
    `${SessionStorageKeys.timelineConfig}_${filterKey}`,
    initialTimelineConfig,
    true,
    (config) => !config.children
  );

  const [childTimelineConfig, , updateChildTimelineConfig] = useMultipleSessionStorageState<TimelineConfiguration>(
    timelineConfig.children?.map((child: string) => `${SessionStorageKeys.timelineConfig}_${child}`),
    initialTimelineConfig,
    true,
    (config) => !config.children
  );

  const getCurrentConfigForInstanceId = useCallback((modelId: string) => {
    if (modelId === conceptDefinitionId) {
      return timelineConfig;
    }
    const json = childTimelineConfig.find(({ key }) => key === `${SessionStorageKeys.timelineConfig}_${modelId}`)?.value;
    return json ? JSON.parse(json) : undefined;
  }, [childTimelineConfig, conceptDefinitionId, timelineConfig]);

  if (!conceptDefinitionId) {
    return null;
  }

  const updateCurrentConfigForModelId = <Key extends keyof TimelineConfiguration>(modelId: string, key: Key, value: TimelineConfiguration[Key]) => {
    const config = { ...getCurrentConfigForInstanceId(modelId) };
    config[key] = value;
    updateChildTimelineConfig(`${SessionStorageKeys.timelineConfig}_${modelId}`, config);
  };

  const conceptsFieldOptions = joinObjects(
    {
      [conceptDefinitionId]: {
        colorField: getColorField(store, conceptDefinitionId, timelineConfig, resolvedViewDefinition?.defaultTimelineColorFieldId, !!widgetId),
        timelineField: getTimelineField(store, conceptDefinitionId, timelineConfig, resolvedViewDefinition?.defaultTimelineFieldId, !!widgetId),
        timelineProgressField: getTimelineProgressField(store, conceptDefinitionId, timelineConfig, resolvedViewDefinition?.defaultTimelineProgressFieldId, !!widgetId),
        timelineGroupByField: getTimelineGroupByField(store, conceptDefinitionId, timelineConfig, resolvedViewDefinition?.defaultTimelineGroupByFieldId, !!widgetId),
        timelineDependencyField: getTimelineDependencyField(store, conceptDefinitionId, timelineConfig, resolvedViewDefinition?.defaultTimelineDependencyFieldId, !!widgetId),
      },
    },
    Object.fromEntries(
      timelineConfig.children?.map((child) => {
        const json = childTimelineConfig.find(({ key }) => key === `${SessionStorageKeys.timelineConfig}_${child}`)?.value;
        const childConfig = json ? JSON.parse(json) : undefined;
        return [child, {
          colorField: getColorField(store, child, childConfig, resolvedViewDefinition?.defaultTimelineColorFieldId, !!widgetId),
          timelineField: getTimelineField(store, child, childConfig, resolvedViewDefinition?.defaultTimelineFieldId, !!widgetId),
          timelineProgressField: getTimelineProgressField(store, child, childConfig, resolvedViewDefinition?.defaultTimelineProgressFieldId, !!widgetId),
          timelineGroupByField: getTimelineGroupByField(store, child, childConfig, resolvedViewDefinition?.defaultTimelineGroupByFieldId, !!widgetId),
          timelineDependencyField: getTimelineDependencyField(store, child, childConfig, resolvedViewDefinition?.defaultTimelineDependencyFieldId, !!widgetId),
        }];
      }) ?? []
    )
  );

  const timelineGroupByFieldId = conceptsFieldOptions[conceptDefinitionId].timelineGroupByField?.id;
  const timelineFieldId = conceptsFieldOptions[conceptDefinitionId].timelineField?.id;
  const timelineProgressFieldId = conceptsFieldOptions[conceptDefinitionId].timelineProgressField?.id;
  const colorFieldId = conceptsFieldOptions[conceptDefinitionId].colorField?.id;
  const timelineDependencyFieldId = conceptsFieldOptions[conceptDefinitionId].timelineDependencyField?.id;

  return (
    <div className={classnames({ [classes.timelineContainer]: true, [classes.timelineViewContainer]: !!widgetId, [classes.timelineBlockContainer]: !widgetId })}>
      <UsageContextProvider usageVariant={UsageVariant.inForm}>
        <SizeContextProvider sizeVariant={SizeVariant.small}>
          <div className={classes.timelineConfigContainer}>
            <SpacingLine>
              <StoreDateRangeInput
                initialValue={timelineConfig.timelineRange}
                onSubmit={(dates) => {
                  if (dates !== null) {
                    updateTimelineConfig({ timelineRange: dates });
                  }
                }}
                withPredefinedRanges
                withLastAndNext
                withStartConstraint={false}
                placeholder={i18n`Add period`}
              />
            </SpacingLine>
            <SpacingLine>
              <SizeContextProvider sizeVariant={SizeVariant.small}>

                <Typo maxLine={1} color={theme.color.text.secondary}>{i18n`Group by`}</Typo>
                <SearchAndSelect
                  clearable
                  computeOptions={() => listGroupByFieldOptions(store, conceptDefinitionId)}
                  selectedOption={timelineGroupByFieldId ? getChipOptions(store, timelineGroupByFieldId) : undefined}
                  onSelect={(value) => updateTimelineConfig({ [ConceptDefinition_TimelineGroupBy]: value?.id ?? null })}
                />
                <CompositeField
                  variant={CompositeFieldVariants.button}
                  width="41.2rem"
                  headerLinesRenderers={[
                    {
                      id: 'title',
                      render: () => (
                        <div className={classes.buttonOptions}>
                          {conceptsFieldOptions[conceptDefinitionId].timelineField
                            ? null
                            : (<Icon name={IconName.dangerous} tooltip={i18n`Timeline field missing`} colorVariant={IconColorVariant.error} />)}
                          <Typo>{i18n`Options`}</Typo>
                        </div>
                      ),
                    },
                  ]}
                  getDropdownSectionDefinitions={() => [
                    {
                      id: 'concept',
                      title: getConceptDefinitionNameOrEntity(store, conceptDefinitionId),
                      titleVariant: DropdownSectionTitleVariants.inline,
                      action: (
                        <ToggleButton
                          active={timelineConfig?.showItself}
                          onClick={() => updateTimelineConfig({ showItself: !timelineConfig?.showItself })}
                          name={timelineConfig?.showItself ? i18n`Hide` : i18n`Show`}
                          icon={timelineConfig?.showItself ? IconName.visibility_off : IconName.visibility}
                        />
                      ),
                      lines: [
                        {
                          id: 'concept-timeline-field',
                          title: i18n`Timeline`,
                          render: (
                            <SearchAndSelect
                              computeOptions={() => listTimelineFieldOptions(store, conceptDefinitionId)}
                              selectedOption={timelineFieldId ? getChipOptions(store, timelineFieldId) : undefined}
                              onSelect={(value) => updateTimelineConfig({ [ConceptDefinition_Timeline]: value?.id ?? null })}
                            />
                          ),
                        },
                        {
                          id: 'concept-progress-field',
                          title: i18n`Progress`,
                          render: (
                            <SearchAndSelect
                              clearable
                              computeOptions={() => listProgressFieldOptions(store, conceptDefinitionId)}
                              selectedOption={timelineProgressFieldId ? getChipOptions(store, timelineProgressFieldId) : undefined}
                              onSelect={(value) => updateTimelineConfig({ [ConceptDefinition_TimelineProgress]: value?.id ?? null })}
                            />
                          ),
                        },
                        {
                          id: 'concept-color-field',
                          title: i18n`Color by`,
                          render: (
                            <SearchAndSelect
                              clearable
                              computeOptions={() => listColorFieldOptions(store, conceptDefinitionId)}
                              selectedOption={colorFieldId ? getChipOptions(store, colorFieldId) : undefined}
                              onSelect={(value) => updateTimelineConfig({ [ConceptDefinition_Color]: value?.id ?? null })}
                            />
                          ),
                        },
                        {
                          id: 'concept-dependencies',
                          title: i18n`Dependencies`,
                          render: (
                            <SearchAndSelect
                              clearable
                              computeOptions={() => listDependenciesFieldOptions(store, conceptDefinitionId)}
                              selectedOption={timelineDependencyFieldId ? getChipOptions(store, timelineDependencyFieldId) : undefined}
                              onSelect={(value) => updateTimelineConfig({ [ConceptDefinition_TimelineDependency]: value?.id ?? null })}
                            />
                          ),
                        },
                      ],
                    },
                    ...timelineConfig.children.map((child) => {
                      const childTimelineFieldId = conceptsFieldOptions[child].timelineField?.id;
                      const childTimelineProgressFieldId = conceptsFieldOptions[child].timelineProgressField?.id;
                      const childColorFieldId = conceptsFieldOptions[child].colorField?.id;

                      return (
                        {
                          id: `child_${child}`,
                          title: getConceptDefinitionNameOrEntity(store, child),
                          action: (
                            <IconOnlyButton
                              tooltip={i18n`Remove`}
                              onClick={() => {
                                updateTimelineConfig({ children: timelineConfig.children.filter((id) => id !== child) });
                              }}
                              variant={IconOnlyButtonVariants.danger}
                              iconName={IconName.delete}
                            />
                          ),
                          titleVariant: DropdownSectionTitleVariants.inline,
                          lines: child ? [
                            {
                              id: 'childConcept-timeline-field',
                              title: i18n`Timeline`,
                              render: (
                                <SearchAndSelect
                                  computeOptions={() => listTimelineFieldOptions(store, child)}
                                  selectedOption={childTimelineFieldId ? getChipOptions(store, childTimelineFieldId) : undefined}
                                  onSelect={(value) => updateCurrentConfigForModelId(child, ConceptDefinition_Timeline, value?.id ?? null)}
                                />
                              ),
                            },
                            {
                              id: 'childConcept-progress-field',
                              title: i18n`Progress`,
                              render: (
                                <SearchAndSelect
                                  clearable
                                  computeOptions={() => listProgressFieldOptions(store, child)}
                                  selectedOption={childTimelineProgressFieldId ? getChipOptions(store, childTimelineProgressFieldId) : undefined}
                                  onSelect={(value) => updateCurrentConfigForModelId(child, ConceptDefinition_TimelineProgress, value?.id ?? null)}
                                />
                              ),
                            },
                            {
                              id: 'childConcept-color-field',
                              title: i18n`Color by`,
                              render: (
                                <SearchAndSelect
                                  clearable
                                  computeOptions={() => listColorFieldOptions(store, child)}
                                  selectedOption={childColorFieldId ? getChipOptions(store, childColorFieldId) : undefined}
                                  onSelect={(value) => updateCurrentConfigForModelId(child, ConceptDefinition_Color, value?.id ?? null)}
                                />
                              ),
                            },
                          ] : [],
                        });
                    }),
                    {
                      id: 'child_addChild',
                      lines: [
                        {
                          id: 'addRelated',
                          render: (
                            <SearchAndSelect
                              placeholder={i18n`Display another element related to this concept`}
                              computeOptions={() => listRelatedConceptDefinitionChips(store, conceptDefinitionId)
                                .filter(({ id }) => !timelineConfig.children.includes(id)) ?? []}
                              onSelect={(value) => updateTimelineConfig({ children: value?.id ? [...timelineConfig.children, value.id] : timelineConfig.children })}
                            />
                          ),
                        },
                      ],
                    },
                  ]}
                />
              </SizeContextProvider>
            </SpacingLine>
          </div>
        </SizeContextProvider>
      </UsageContextProvider>
    </div>
  );
};

export default ConceptTimelineOptions;
