import type { ReactElement } from 'react';
import { VictoryBar, VictoryGroup, VictoryStack } from 'victory';
import { periodicities, PeriodicityType } from 'yooi-utils';
import type { SerieInput } from '../ChartTypes';

interface Value {
  x: Date,
  y: number,
  time: number,
  value: number,
}

interface MiniBarChartProps<ValueType extends Value> {
  series: SerieInput<ValueType>,
  height: number,
  width: number,
  periodicity?: PeriodicityType,
}

const MiniBarChart = <ValueType extends Value>({ series, height, width, periodicity = PeriodicityType.day }: MiniBarChartProps<ValueType>): ReactElement | null => {
  if (!series || !series.domain.xDomain) {
    return null;
  }

  const cutWidth = width / periodicities[periodicity].getDiffOfDatesOfPeriod(new Date(series.domain.xDomain[1]), new Date(series.domain.xDomain[0]));
  const padding = Math.min(4, cutWidth * 0.3);
  const barWidth = cutWidth - padding;

  const row = series.rows[0];

  if (row.stackedRows.flatMap((stackedRow) => stackedRow.values).length) {
    return (
      <VictoryGroup
        scale={{ x: 'time' }}
        domain={{ x: series.domain.xDomain, y: series.domain.yDomain }}
        width={width}
        domainPadding={{ y: 1 }}
        padding={0}
        singleQuadrantDomainPadding={{ y: false }}
        height={height}
      >
        <VictoryStack>
          {row.stackedRows.map((stackedRow) => (
            <VictoryBar
              key={stackedRow.key}
              data={stackedRow.values}
              barWidth={barWidth}
              alignment="start"
              style={{
                data: {
                  shapeRendering: 'crispedges',
                  fill: ({ datum }) => (datum.color ?? (stackedRow.info.color) ?? series.info.color),
                },
              }}
            />
          ))}
        </VictoryStack>
      </VictoryGroup>
    );
  } else {
    return null;
  }
};

export default MiniBarChart;
