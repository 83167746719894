import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useContext } from 'react';

interface Context {
  level: number,
  isVertical: boolean,
}

const BlockContext = createContext<Context>({ level: 0, isVertical: false });

interface BlockContextProviderProps {
  context: Context,
  children: ReactNode,
}

export const BlockContextProvider: FunctionComponent<BlockContextProviderProps> = ({ context, children }) => (
  <BlockContext.Provider value={context}>
    {children}
  </BlockContext.Provider>
);

const useBlockContext = (): Context => useContext(BlockContext);

export default useBlockContext;
