import type { FunctionComponent } from 'react';
import { Field_IntegrationOnly } from 'yooi-modules/modules/conceptModule/ids';
import TextInputString from '../../../../components/inputs/TextInputString';
import useAcl from '../../../../store/useAcl';
import useActivity from '../../../../store/useActivity';
import useStore from '../../../../store/useStore';
import useUpdateActivity from '../../../../store/useUpdateActivity';
import { notifyError } from '../../../../utils/notify';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';
import StoreURLInput from '../../input/StoreURLInput';
import { getUrlMetadata, updateResourceURL } from '../../resourceUtils';

interface UrlFieldRendererProps {
  objectId: string,
  fieldId: string,
  readOnly?: boolean,
  focusOnMount?: boolean,
}

const UrlFieldRenderer: FunctionComponent<UrlFieldRendererProps> = ({ objectId, fieldId, readOnly = false, focusOnMount = false }) => {
  const store = useStore();
  const { canWriteObject } = useAcl();

  const activity = useActivity();
  const updateActivity = useUpdateActivity();

  const usageVariant = useUsageContext();

  const object = store.getObject(objectId);

  if (usageVariant === UsageVariant.inTable) {
    return (<TextInputString value={object[fieldId] as string} focusOnMount={focusOnMount} readOnly />);
  } else {
    const field = store.getObject(fieldId);
    const isReadOnly = readOnly || field[Field_IntegrationOnly] as boolean || !canWriteObject(objectId);
    const resourceMetadata = object[fieldId] ? getUrlMetadata(object[fieldId] as string) : undefined;

    return (
      <StoreURLInput
        initialValue={object[fieldId] as string}
        onSubmit={(url) => {
          const error = updateResourceURL(store, objectId, url);
          if (error) {
            notifyError(error);
          }
        }}
        readOnly={isReadOnly}
        href={resourceMetadata?.generateUrl?.()}
        type={resourceMetadata?.type}
        onEditionStart={() => updateActivity.onEnterEdition(objectId, fieldId)}
        onEditionStop={() => updateActivity.onExitEdition(objectId, fieldId)}
        isEditing={usageVariant === UsageVariant.inCard && activity.listEditor(objectId, fieldId).length > 0}
        focusOnMount={focusOnMount}
      />
    );
  }
};

export default UrlFieldRenderer;
