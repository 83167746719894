import type { FunctionComponent } from 'react';
import type { IntentionStoreObject } from 'yooi-modules/modules/collaborationModule';
import {
  Intention,
  Intention_Name,
  Intention_Rank,
  Intention_Workflow,
  IntentionStatusEntries,
  IntentionStatusEntries_Role_Entry,
  IntentionStatusEntries_Role_Intention,
  IntentionStatusIsClosedStatus,
} from 'yooi-modules/modules/collaborationModule/ids';
import { Workflow, WorkflowEntry, WorkflowEntry_Rank, WorkflowEntry_Role_Concept, WorkflowEntry_Role_Workflow } from 'yooi-modules/modules/conceptModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import { compareRank, extractAndCompareValue, filterNullOrUndefined } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import Typo from '../../../components/atoms/Typo';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SearchAndSelectMultiple from '../../../components/molecules/SearchAndSelectMultiple';
import { TableSortDirection } from '../../../components/molecules/Table';
import BlockContent from '../../../components/templates/BlockContent';
import DataTable from '../../../components/templates/DataTable';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { safeSessionStorageValue } from '../../../utils/sessionStorageUtils';
import useDeleteModal from '../../../utils/useDeleteModal';
import useNavigation from '../../../utils/useNavigation';
import useNewLineFocus from '../../../utils/useNewLineFocus';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import SearchTextButton from '../../_global/filter/SearchTextButton';
import StoreTextInputField from '../../_global/input/StoreTextInputField';
import { searchFilterFunction } from '../../_global/listFilterFunctions';
import { getChipOptions } from '../../_global/modelTypeUtils';
import type { NavigationFilter } from '../../_global/navigationUtils';
import { getNavigationPayload } from '../../_global/navigationUtils';
import { duplicateIntention } from '../../_global/rightPanel/collaboration/utils/collaborationUtils';
import { share } from '../../_global/shareUtils';
import useFilterAndSort, { buildStringColumnComparatorHandler } from '../../_global/useFilterAndSort';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: spacingRem.s,
    paddingBottom: spacingRem.xs,
  },
}, 'intentionList');

interface IntentionFilterConfiguration {
  nameSearch?: string,
}

interface IntentionListProps {
  intentionIds: string[],
  onCreate: () => string,
}

const IntentionList: FunctionComponent<IntentionListProps> = ({ intentionIds, onCreate }) => {
  const classes = useStyles();
  const store = useStore();

  const navigation = useNavigation<NavigationFilter>();

  const [newLineFocus, setNewLineFocus] = useNewLineFocus();

  const filterId = Intention;

  const intentions = intentionIds.map((ruleId) => store.getObject<IntentionStoreObject>(ruleId))
    .map((intention) => ({
      key: intention.id,
      id: intention.id,
      name: intention[Intention_Name],
      workflow: intention[Intention_Workflow],
      status: '',
      compareRank: intention[Intention_Rank],
      url: `/settings/collaboration/${intention.id}`,
    }));

  const [doDelete, deleteModal] = useDeleteModal<string>({
    doDelete: (id) => {
      store.deleteObject(id);
    },
    shouldConfirm: () => true,
    getModalProps: () => ({
      title: i18n`Are you sure that you want to delete this intention?`,
      content: (
        <Typo>{i18n`All collaborations with this intention will be automatically closed, but will remain accessible via the panel.`}</Typo>
      ),
    }),
  });

  const filterFunction = searchFilterFunction<typeof intentions[0]>(store, safeSessionStorageValue<IntentionFilterConfiguration | undefined>(filterId)?.nameSearch, ['name']);

  const { generateList, doSort, sortCriteria, forceShowId } = useFilterAndSort(
    filterId,
    intentions,
    (item) => (filterFunction?.(item) ?? true),
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case 'name':
            return buildStringColumnComparatorHandler(key, direction);
          default:
            return undefined;
        }
      },
      initial: { key: 'name', direction: TableSortDirection.asc },
    }
  );

  return (
    <VerticalBlock compact>
      <BlockContent padded>
        <div className={classes.headerContainer}>
          <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
            <SearchTextButton element={filterId} placeholder={i18n`Search`} />
          </SizeContextProvider>
        </div>
      </BlockContent>
      <BlockContent fullWidth>
        <DataTable
          list={generateList().list}
          getNavigationPayload={(intention) => getNavigationPayload(navigation, intention.id, intention.url)}
          columnsDefinition={[
            {
              key: 'name',
              propertyId: 'name',
              name: i18n`Name`,
              sortable: true,
              focusable: true,
              openButton: ({ url }) => Boolean(url),
              cellRender: ({ id, name }, focusOnMount) => (
                <StoreTextInputField
                  initialValue={name}
                  focusOnMount={focusOnMount}
                  onSubmit={(newName) => {
                    store.updateObject(id, { [Intention_Name]: newName });
                  }}
                />
              ),
            },
            {
              key: 'workflow',
              propertyId: 'workflow',
              name: i18n`Workflow`,
              cellRender: ({ id, workflow }) => (
                <SearchAndSelect
                  selectedOption={workflow ? getChipOptions(store, workflow) : undefined}
                  onSelect={(w) => {
                    store.updateObject(id, { [Intention_Workflow]: w?.id ?? null });
                  }}
                  computeOptions={() => store.getObject(Workflow)
                    .navigateBack(Class_Instances)
                    .map((w) => getChipOptions(store, w.id))
                    .filter(filterNullOrUndefined)}
                />
              ),
            },
            {
              key: 'Status',
              propertyId: 'Status',
              name: i18n`Closure status`,
              cellRender: ({ id, workflow }) => (
                <SearchAndSelectMultiple
                  selectedOptions={
                    store.withAssociation(IntentionStatusEntries)
                      .withRole(IntentionStatusEntries_Role_Intention, id)
                      .list()
                      .filter((e) => e.object[IntentionStatusIsClosedStatus])
                      .map((assoc) => assoc.role(IntentionStatusEntries_Role_Entry))
                      .filter((entry) => (!workflow || Boolean(store.withAssociation(WorkflowEntry)
                        .withRole(WorkflowEntry_Role_Concept, entry)
                        .withRole(WorkflowEntry_Role_Workflow, workflow)
                        .getObjectOrNull())))
                      .map((entry) => getChipOptions(store, entry))
                      .filter(filterNullOrUndefined)
                  }
                  onDelete={(entry) => store.withAssociation(IntentionStatusEntries)
                    .withRole(IntentionStatusEntries_Role_Intention, id)
                    .withRole(IntentionStatusEntries_Role_Entry, entry.id)
                    .updateObject(null)}
                  onSelect={(entry) => store.withAssociation(IntentionStatusEntries)
                    .withRole(IntentionStatusEntries_Role_Intention, id)
                    .withRole(IntentionStatusEntries_Role_Entry, entry.id)
                    .updateObject({ [IntentionStatusIsClosedStatus]: true })}
                  computeOptions={() => (workflow ? store.withAssociation(WorkflowEntry)
                    .withRole(WorkflowEntry_Role_Workflow, workflow)
                    .list()
                    .sort(extractAndCompareValue(({ object }) => object[WorkflowEntry_Rank] as string, compareRank))
                    .filter((_el, index) => index !== 0)
                    .map((assoc) => getChipOptions(store, assoc.role(WorkflowEntry_Role_Concept)))
                    .filter(filterNullOrUndefined) : [])}
                />
              ),
            },
          ]}
          doSort={doSort}
          sortCriteria={sortCriteria}
          newItemTitle={i18n`Create`}
          newItemIcon={IconName.add}
          onNewItem={() => {
            const ruleId = onCreate();
            forceShowId(ruleId);
            setNewLineFocus(ruleId);
          }}
          newLineFocus={newLineFocus.current}
          linesActions={({ id, url }) => [
            {
              key: 'share',
              icon: IconName.link,
              name: i18n`Copy link`,
              onClick: () => share(store, getNavigationPayload(navigation, id, url)),
            },
            {
              key: 'duplicate',
              name: i18n`Duplicate`,
              icon: IconName.content_copy_outline,
              onClick: () => {
                duplicateIntention(store, id);
              },
            },
            {
              key: 'delete',
              name: i18n`Delete`,
              icon: IconName.delete,
              onClick: () => doDelete(id),
              danger: true,
            },
          ]}
        />
      </BlockContent>
      {deleteModal}
    </VerticalBlock>
  );
};

export default IntentionList;
