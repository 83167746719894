export enum DebounceMode {
  Immediate = 'Immediate',
  Fast = 'Fast',
  Slow = 'Slow',
}

const debounceModeConfig: Record<Exclude<DebounceMode, DebounceMode.Immediate>, { debounceDurationMs: number, maxDurationMs: number }> = {
  [DebounceMode.Fast]: { debounceDurationMs: 250, maxDurationMs: 500 },
  [DebounceMode.Slow]: { debounceDurationMs: 5_000, maxDurationMs: 10_000 },
};

const debounce = (action: () => void): ((mode: DebounceMode) => void) => {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  let currentDebounceMode: Exclude<DebounceMode, DebounceMode.Immediate> | undefined;
  let nextDebounceMode: Exclude<DebounceMode, DebounceMode.Immediate> | undefined; // Store the debounce mode to use if maxDurationMs is exceeded
  let lastCallbackTime: number | undefined; // Last time we call the action method
  let lastDebounceTime: number | undefined; // Last time the debounced method was called
  let wasCalledAfterFirstDebounce = false; // True if we had a call after the first debounce call (useful to no trigger a re-render if only one call is done)

  const clearState = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = undefined;
    currentDebounceMode = undefined;
    nextDebounceMode = undefined;
    lastCallbackTime = undefined;
    lastDebounceTime = undefined;
    wasCalledAfterFirstDebounce = false;
  };

  return (mode) => {
    if (mode === DebounceMode.Immediate) {
      action();
      clearState();
    } else {
      const doCallback = () => {
        timeoutId = undefined;
        const callbackTime = performance.now();

        if (
          currentDebounceMode === undefined || lastDebounceTime === undefined || lastCallbackTime === undefined // Was cleared (but not the timeout, weird)
          || (!wasCalledAfterFirstDebounce && currentDebounceMode === DebounceMode.Fast) // No extra call after the first debounce
          || lastDebounceTime < lastCallbackTime // We triggered a re-render after the last debounce
        ) {
          clearState();
        } else if (callbackTime >= lastCallbackTime + debounceModeConfig[currentDebounceMode].maxDurationMs) {
          action();
          lastCallbackTime = callbackTime;
          if (nextDebounceMode) {
            currentDebounceMode = nextDebounceMode;
            nextDebounceMode = undefined;
          }

          const { debounceDurationMs, maxDurationMs } = debounceModeConfig[currentDebounceMode];
          const untilNextDebounceMs = (lastDebounceTime + debounceDurationMs) - callbackTime;
          const untilNextMaxMs = (lastCallbackTime + maxDurationMs) - callbackTime;
          timeoutId = setTimeout(doCallback, Math.min(untilNextDebounceMs, untilNextMaxMs));
        } else if (callbackTime >= lastDebounceTime + debounceModeConfig[currentDebounceMode].debounceDurationMs) {
          action();
          clearState();
        } else {
          const { debounceDurationMs, maxDurationMs } = debounceModeConfig[currentDebounceMode];
          const untilNextDebounceMs = (lastDebounceTime + debounceDurationMs) - callbackTime;
          const untilNextMaxMs = (lastCallbackTime + maxDurationMs) - callbackTime;
          timeoutId = setTimeout(doCallback, Math.min(untilNextDebounceMs, untilNextMaxMs));
        }
      };

      lastDebounceTime = performance.now();

      if (timeoutId === undefined) {
        currentDebounceMode = mode;
        lastCallbackTime = lastDebounceTime;
        timeoutId = setTimeout(doCallback, debounceModeConfig[mode].debounceDurationMs);
        if (mode === DebounceMode.Fast) {
          action();
        }
      } else if (currentDebounceMode === undefined || lastCallbackTime === undefined || lastDebounceTime === undefined) {
        // Weird place, reset all
        clearState();
      } else if (debounceModeConfig[currentDebounceMode].debounceDurationMs > debounceModeConfig[mode].debounceDurationMs) {
        // We change the debounce mode
        if (lastCallbackTime + debounceModeConfig[mode].maxDurationMs <= lastDebounceTime) {
          action();
          currentDebounceMode = mode;
          nextDebounceMode = undefined;
          wasCalledAfterFirstDebounce = false;
          lastCallbackTime = lastDebounceTime;
          const untilNextDebounceMs = (lastDebounceTime + debounceModeConfig[mode].debounceDurationMs) - lastDebounceTime;
          const untilNextMaxMs = (lastCallbackTime + debounceModeConfig[mode].maxDurationMs) - lastDebounceTime;
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          timeoutId = setTimeout(doCallback, Math.min(untilNextDebounceMs, untilNextMaxMs));
        } else {
          if (currentDebounceMode === DebounceMode.Slow && mode === DebounceMode.Fast) {
            action();
            wasCalledAfterFirstDebounce = false;
          } else {
            wasCalledAfterFirstDebounce = true;
          }
          currentDebounceMode = mode;
          nextDebounceMode = undefined;
          const untilNextDebounceMs = (lastDebounceTime + debounceModeConfig[mode].debounceDurationMs) - lastDebounceTime;
          const untilNextMaxMs = (lastCallbackTime + debounceModeConfig[mode].maxDurationMs) - lastDebounceTime;
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          timeoutId = setTimeout(doCallback, Math.min(untilNextDebounceMs, untilNextMaxMs));
        }
      } else {
        if (mode !== currentDebounceMode) {
          nextDebounceMode = mode;
        }
        wasCalledAfterFirstDebounce = true;
      }
    }
  };
};

export const testables = {
  debounceModeConfig,
};

export default debounce;
