import { v4 as uuid } from 'uuid';
import type { Migration, StoreObject } from 'yooi-store';
import { joinObjects } from 'yooi-utils';
import {
  MigrationAttachmentField,
  MigrationBooleanField,
  MigrationCardFieldButtonPosition,
  MigrationCollaborationField,
  MigrationDropRoarField,
  MigrationDropUselessFieldLocalOverride,
  MigrationIconField,
  MigrationIconFieldInChip,
  MigrationIconLibraryToMaterialSymbols,
  MigrationMainBooleanField,
  MigrationReverseWorkflowField,
  MigrationSupportFixedWidthInConceptDefinitionInstancesTab,
  MigrationSwimlaneViews,
  MigrationTimeseriesTableDimensionDisplay,
  MigrationTimeseriesTextField,
  MigrationUpdateTimeseriesFormula,
  MigrationUserIsEnabledAsField,
  MigrationViewFilters,
  MigrationWorkflowOnSecondLine,
} from '../../src/Migrations';
import { FieldBlockDisplay, FieldBlockDisplay_FieldDisplayConfiguration, FieldBlockDisplay_FieldPath } from '../conceptLayoutModule/ids';
import type { ViewsFieldStoreObject } from '../dashboardModule';
import { CardField, CardField_ButtonPositionX, CardField_ButtonPositionY, ViewsField_Definition } from '../dashboardModule/ids';
import type { TypeHelperRevision1 } from '../typeModule';
import { isInstanceOf } from '../typeModule';
import { Class_Extend, Class_Instances, Instance_Of, Property_OfClass, TypeModuleId } from '../typeModule/ids';
import {
  AnyElement,
  AssociationField,
  AssociationField_Definition,
  AssociationField_SourceRole,
  AssociationFieldDefinition_Role1Type,
  AssociationFieldDefinition_Role2Type,
  AssociationFilter,
  AssociationFilter_Path,
  Attachment,
  Attachment_Rank,
  Attachment_Revision,
  Attachment_UploadedAt,
  Attachment_UploadedBy,
  AttachmentField,
  BooleanField,
  BooleanField_FalseValue,
  BooleanField_TrueValue,
  CollaborationField,
  Concept,
  Concept_CollaborationField,
  ConceptCollaborationFieldDimension,
  ConceptDefinition,
  ConceptDefinition_ChipIconField,
  ConceptDefinition_Color,
  ConceptDefinition_Icon,
  ConceptDefinition_MainBooleanField,
  ConceptDefinition_MainIconField,
  ConceptDefinition_MainWorkflowOnSecondLine,
  ConceptDefinition_SwimlaneColumnBy,
  ConceptDefinition_SwimlaneGroupBy,
  ConceptDefinition_SwimlaneProgress,
  ConceptDefinitionInstanceAdministrationColumn,
  ConceptDefinitionInstanceAdministrationColumn_Width,
  ConditionFilter,
  ConditionFilter_Filters,
  EmbeddingField,
  EmbeddingField_FromType,
  EmbeddingField_ToType,
  Field,
  Field_ApiAlias,
  Field_Formula,
  Field_IsCore,
  Field_Title,
  FieldDefinition,
  FieldDefinition_Title,
  FieldDimension,
  FieldDimension_Field,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  FieldLocalOverride,
  FieldLocalOverrideProperty,
  FieldLocalOverrideProperty_OverrideProperty,
  IconField,
  KinshipRelationField,
  NumberField,
  RelationMultipleField,
  RelationMultipleField_TargetType,
  RelationSingleField,
  RelationSingleField_TargetType,
  ReverseWorkflowField,
  ReverseWorkflowField_ReverseField,
  StakeholdersField,
  StakeholdersField_TargetType,
  TimeseriesNumberField,
  TimeseriesTextField,
  TimeseriesTextField_DefaultPeriod,
  User,
  User_IsEnabled,
  UserIsEnabledDimension,
  ViewFilter_ViewsField,
  Workflow_TargetedConceptDefinition,
  WorkflowField,
  WorkflowField_TargetedConceptDefinition,
  WorkflowField_Workflow,
} from './ids';
import type { AssociationFilterRaw, AssociationFilterStoreObject, ConceptDefinitionStoreObject, ConditionFilterRaw, ConditionFilterStoreObject, FieldStoreObject } from './model';
import type { FilterCondition, FilterNode, Filters, MappingStep, PathStep } from './moduleType';
import { FilterValueType, PathStepType } from './moduleType';
import type { InstanceReferenceType } from './utils';
import { FILTER_PARAMETER_CURRENT, isFieldStep, isFilterNode, isMappingStep } from './utils';

const migrations: Record<number, Migration> = {
  [MigrationUpdateTimeseriesFormula]: {
    handler: (objectStore) => {
      interface TimeseriesFormulaInput {
        type: 'timeseries',
        path: PathStep[],
      }

      interface ValueFormulaInput {
        type: 'value',
        path: PathStep[],
      }

      interface ModelFormulaInput {
        type: 'model',
        conceptId?: string,
        fieldId?: string,
      }

      interface OldFormula {
        formula: string,
        inputs: Record<string, ValueFormulaInput | ModelFormulaInput>,
      }

      interface NewFormula {
        formula: string,
        inputs: { name: string, input: ValueFormulaInput | ModelFormulaInput | TimeseriesFormulaInput }[],
      }

      objectStore.getObject(FieldDefinition)
        .navigateBack(Class_Instances)
        .forEach((fieldDefinition) => {
          fieldDefinition.navigateBack(Class_Instances)
            .forEach((field) => {
              if (field[Field_Formula] !== undefined) {
                const oldFormula = field[Field_Formula] as OldFormula;

                if (field[Instance_Of] === TimeseriesNumberField) {
                  const inputs = Object.entries(oldFormula.inputs)
                    .map(([name, input]): { name: string, input: ValueFormulaInput | ModelFormulaInput | TimeseriesFormulaInput } => {
                      if (input.type === 'value') {
                        const lastPathElement = input.path.at(-1);
                        if (
                          isFieldStep(lastPathElement)
                          && [TimeseriesNumberField, NumberField].some((fieldDefinitionId) => isInstanceOf(objectStore.getObjectOrNull(lastPathElement.fieldId), fieldDefinitionId))
                        ) {
                          return { name, input: { type: 'timeseries', path: input.path } };
                        } else {
                          return { name, input };
                        }
                      } else {
                        return { name, input };
                      }
                    });

                  const timeseriesInputs = inputs.filter(({ input }) => input.type === 'timeseries').map(({ name }) => name.toLowerCase());

                  const newFormula: NewFormula = {
                    formula: oldFormula.formula.replaceAll(/[A-Za-z0-9_]+/g, (part) => (timeseriesInputs.includes(part.toLowerCase()) ? `${part}_i` : part)),
                    inputs,
                  };

                  objectStore.updateObject(field.id, { [Field_Formula]: newFormula });
                } else {
                  const newFormula: NewFormula = {
                    formula: oldFormula.formula,
                    inputs: Object.entries(oldFormula.inputs).map(([name, input]) => ({ name, input })),
                  };

                  objectStore.updateObject(field.id, { [Field_Formula]: newFormula });
                }
              }
            });
        });
    },
  },
  [MigrationTimeseriesTextField]: {
    dependencies: [TypeModuleId],
    handler: ({ updateObject }, helpers) => {
      const typeHelper = helpers.typeHelper as TypeHelperRevision1;

      const FieldLocalOverride_TimeseriesTextFieldDefaultPeriod = '6bc61c97-b701-4816-830d-4fb3ad482a62';

      typeHelper.type(TimeseriesTextField, { of: FieldDefinition, extends: Field });
      typeHelper.property(TimeseriesTextField, TimeseriesTextField_DefaultPeriod);
      typeHelper.type(FieldLocalOverride_TimeseriesTextFieldDefaultPeriod, { of: FieldLocalOverrideProperty });
      updateObject(FieldLocalOverride_TimeseriesTextFieldDefaultPeriod, {
        [Instance_Of]: FieldLocalOverrideProperty,
        [Property_OfClass]: FieldLocalOverride,
        [FieldLocalOverrideProperty_OverrideProperty]: TimeseriesTextField_DefaultPeriod,
      });
      updateObject(TimeseriesTextField, { [FieldDefinition_Title]: 'Text Timeseries' });
    },
  },
  [MigrationDropUselessFieldLocalOverride]: {
    handler: ({ deleteObject }) => {
      const FieldLocalOverride_AssociationFieldDefinition = 'cc132692-9dab-4de5-98e3-0ab882e0b0aa';
      const FieldLocalOverride_AssociationFieldSourceRole = 'd9c1abaf-779e-46f3-a79a-4260aac7e83a';
      const FieldLocalOverride_ColorFieldDefaultColor = 'c0af1ade-85f7-4446-a01c-dfd8d8a6003b';
      const FieldLocalOverride_DateFieldDefaultPeriod = '05215c42-dd4a-49e8-9258-946186caa148';
      const FieldLocalOverride_DateFieldRank = 'dc23a39c-9dee-4a68-9da7-636b46a78a6f';
      const FieldLocalOverride_EmbeddingFieldFromType = 'fb577c75-98a5-4d35-a023-430a988e1cdc';
      const FieldLocalOverride_EmbeddingFieldLabel = '4ae4c94d-97d4-4473-a940-88a3d0877edb';
      const FieldLocalOverride_EmbeddingFieldToType = '097bf22e-2d8f-4ae8-808a-c414f54854ee';
      const FieldLocalOverride_ExternalKeyFieldIsImmutable = 'de2315c4-0795-4ab2-8a4d-3062611ddac4';
      const FieldLocalOverride_ExternalKeyFieldIsRequired = '91adc67e-a267-4924-af6e-fbce43828d99';
      const FieldLocalOverride_ExternalKeyFieldPreventKeyStealing = '2636a6b2-8787-4425-93e9-621d5c6e2d40';
      const FieldLocalOverride_ExternalKeyFieldRegexValidation = '47099a21-5fd3-4a01-af5c-dfd6008498fb';
      const FieldLocalOverride_ImageFieldAspectRatio = '2ffc8440-423e-45b1-af85-951171358fd2';
      const FieldLocalOverride_RelationMultipleFieldReverseField = '2bd068cc-fa88-46b4-bf64-5bb9443d6f5f';
      const FieldLocalOverride_RelationMultipleFieldTargetType = 'fbef99b2-ff3c-4f55-b077-30d0e5965821';
      const FieldLocalOverride_RelationSingleFieldTargetType = '49f56561-eb74-48a6-a8d2-f8e6704c6475';
      const FieldLocalOverride_StakeholdersFieldTargetType = '3ff50162-1b17-40e3-99c7-1623b43beb2d';
      const FieldLocalOverride_TimelineFieldDefaultPeriod = '52cfcac8-ecc9-40d5-84d6-a11490502846';
      const FieldLocalOverride_TimelineFieldPeriod = '0e0a669f-113e-44ea-9984-a4ec3cbf146e';
      const FieldLocalOverride_TimelineFieldRank = '2f28fc0c-5fb4-463b-9612-531f65ed79f8';
      const FieldLocalOverride_TimelineFieldStartConstraint = '1463657d-1404-4717-8802-51c88f779382';
      const FieldLocalOverride_WorkflowFieldWorkflow = '2925b460-70f9-47ba-81dd-f7f2be1db880';

      deleteObject(FieldLocalOverride_AssociationFieldDefinition);
      deleteObject(FieldLocalOverride_AssociationFieldSourceRole);
      deleteObject(FieldLocalOverride_ColorFieldDefaultColor);
      deleteObject(FieldLocalOverride_DateFieldDefaultPeriod);
      deleteObject(FieldLocalOverride_DateFieldRank);
      deleteObject(FieldLocalOverride_EmbeddingFieldFromType);
      deleteObject(FieldLocalOverride_EmbeddingFieldLabel);
      deleteObject(FieldLocalOverride_EmbeddingFieldToType);
      deleteObject(FieldLocalOverride_ExternalKeyFieldIsImmutable);
      deleteObject(FieldLocalOverride_ExternalKeyFieldIsRequired);
      deleteObject(FieldLocalOverride_ExternalKeyFieldPreventKeyStealing);
      deleteObject(FieldLocalOverride_ExternalKeyFieldRegexValidation);
      deleteObject(FieldLocalOverride_ImageFieldAspectRatio);
      deleteObject(FieldLocalOverride_RelationMultipleFieldReverseField);
      deleteObject(FieldLocalOverride_RelationMultipleFieldTargetType);
      deleteObject(FieldLocalOverride_RelationSingleFieldTargetType);
      deleteObject(FieldLocalOverride_StakeholdersFieldTargetType);
      deleteObject(FieldLocalOverride_TimelineFieldDefaultPeriod);
      deleteObject(FieldLocalOverride_TimelineFieldPeriod);
      deleteObject(FieldLocalOverride_TimelineFieldRank);
      deleteObject(FieldLocalOverride_TimelineFieldStartConstraint);
      deleteObject(FieldLocalOverride_WorkflowFieldWorkflow);
    },
  },
  [MigrationCollaborationField]: {
    dependencies: [TypeModuleId],
    handler: (objectStore, helpers) => {
      const typeHelper = helpers.typeHelper as TypeHelperRevision1;
      typeHelper.type(CollaborationField, { of: FieldDefinition, extends: Field });
      objectStore.updateObject(CollaborationField, { [FieldDefinition_Title]: 'Collaboration' });
      typeHelper.type(Concept_CollaborationField, { of: CollaborationField });
      objectStore.updateObject(Concept_CollaborationField, { [Field_Title]: 'Collaboration', [Field_IsCore]: true });
      typeHelper.type(ConceptCollaborationFieldDimension, { of: FieldDimension });
      objectStore.updateObject(ConceptCollaborationFieldDimension, { [FieldDimension_Field]: Concept_CollaborationField });
      objectStore.getObject(ConceptDefinition)
        .navigateBack(Class_Instances)
        .forEach((conceptDefinition) => {
          objectStore.withAssociation(FieldDimensionTypes)
            .withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCollaborationFieldDimension)
            .withRole(FieldDimensionTypes_Role_ConceptDefinition, conceptDefinition.id)
            .updateObject({});
        });
    },
  },
  [MigrationWorkflowOnSecondLine]: {
    dependencies: [TypeModuleId],
    handler: (_, helpers) => {
      const typeHelper = helpers.typeHelper as TypeHelperRevision1;

      typeHelper.property(ConceptDefinition, ConceptDefinition_MainWorkflowOnSecondLine);
    },
  },
  [MigrationSwimlaneViews]: {
    dependencies: [TypeModuleId],
    handler: (objectStore) => {
      interface GlobalDimensionStep {
        type: PathStepType.global,
      }

      interface DimensionStep {
        type: PathStepType.dimension,
        conceptDefinitionId: string,
      }

      interface FilterStep {
        type: PathStepType.filter,
      }

      interface CurrentMappingStep {
        type: PathStepType.mapping,
        mapping: { id: string, type: InstanceReferenceType },
      }

      interface FieldStep {
        type: PathStepType.field,
        fieldId: string,
        embeddingFieldId?: string,
      }

      type CurrentPathStep = GlobalDimensionStep | DimensionStep | FilterStep | CurrentMappingStep | FieldStep;

      const getPathReturnedConceptDefinitionId = (path: CurrentPathStep[]): string | undefined => {
        let returnTypeId: string | undefined;
        for (let i = 0; i < path.length; i += 1) {
          const nStep = path[i];
          if (nStep?.type === PathStepType.global) {
            // nothing is returned
          } else if (nStep?.type === PathStepType.dimension) {
            // a list of concept of dimension step conceptDefinition is returned
            returnTypeId = nStep.conceptDefinitionId;
          } else if (nStep?.type === PathStepType.mapping) {
            // only multiplicity change
          } else if (nStep?.type === PathStepType.field) {
            if (objectStore.getObjectOrNull(nStep.fieldId) === null) {
              return undefined;
            }
            const stepField = objectStore.getObject(nStep.fieldId);
            const previousStep = path[i - 1];
            if (previousStep && previousStep?.type === PathStepType.global) {
              // field
              returnTypeId = undefined;
            } else if (stepField[Instance_Of] as string === KinshipRelationField) {
              if (nStep.embeddingFieldId) {
                const embeddingField = objectStore.getObjectOrNull(nStep.embeddingFieldId);
                if (embeddingField && embeddingField[EmbeddingField_FromType]) {
                  returnTypeId = embeddingField[EmbeddingField_FromType] as string;
                } else {
                  returnTypeId = undefined;
                }
              } else {
                // multiple returned types not supported
                returnTypeId = undefined;
              }
            } else if (stepField[Instance_Of] === KinshipRelationField) {
              returnTypeId = Concept;
            } else if (stepField[Instance_Of] === RelationSingleField) {
              returnTypeId = objectStore.getObjectOrNull(stepField.id)?.navigateOrNull(RelationSingleField_TargetType)?.id;
            } else if (stepField[Instance_Of] === WorkflowField) {
              returnTypeId = objectStore.getObjectOrNull(stepField.id)?.navigateOrNull(WorkflowField_Workflow)?.navigateOrNull(Workflow_TargetedConceptDefinition)?.id;
            } else if (stepField[Instance_Of] === AssociationField) {
              const field = objectStore.getObjectOrNull(stepField.id);
              if (field === null) {
                returnTypeId = undefined;
              } else if (field[Field_Formula]) {
                returnTypeId = Concept;
              } else {
                const sourceRole = field[AssociationField_SourceRole];
                const targetRoleField = sourceRole === 1 ? AssociationFieldDefinition_Role2Type : AssociationFieldDefinition_Role1Type;
                const fieldDefinition = field.navigateOrNull(AssociationField_Definition);
                returnTypeId = fieldDefinition?.navigateOrNull(targetRoleField)?.id;
              }
            } else if (stepField[Instance_Of] === RelationMultipleField) {
              returnTypeId = objectStore.getObjectOrNull(stepField.id)?.navigateOrNull(RelationMultipleField_TargetType)?.id;
            } else if (stepField[Instance_Of] === EmbeddingField) {
              returnTypeId = objectStore.getObjectOrNull(stepField.id)?.navigateOrNull(EmbeddingField_ToType)?.id;
            } else if (stepField[Instance_Of] === StakeholdersField) {
              returnTypeId = objectStore.getObjectOrNull(stepField.id)?.navigateOrNull(StakeholdersField_TargetType)?.id;
            } else {
              returnTypeId = undefined;
            }
          } else if (nStep?.type === PathStepType.filter) {
            // only multiplicity change
          }
        }
        return returnTypeId;
      };

      interface ViewDefinition {
        viewDefinitions: {
          type: string,
          series?: { displayOptions?: { width?: number | undefined, [key: string]: unknown }, [key: string]: unknown }[],
          data?: { id: string, path: CurrentPathStep[] }[],
          [key: string]: unknown,
        }[],
      }

      interface OldSwimlaneConfiguration {
        id: string,
        type: 'Swimlane',
        withCustomConfig: boolean | undefined,
        readonly: boolean | undefined,
        defaultProgressFieldId: string | undefined,
        defaultColumnByFieldId: string | undefined,
        defaultGroupByFieldId: string | undefined,
        defaultColorFieldId: string | undefined,
      }

      enum CardColorMode {
        Dot = 'Dot',
        Bar = 'Bar',
      }

      interface NewSwimlaneConfiguration {
        id: string,
        type: 'Swimlane',
        columnBy: { fieldId: string | undefined, filters: Filters | undefined, withEmpty: boolean, size: { type: 'auto' | 'rem' | 'percent', value?: number } },
        groupBy: { id: string, label: string | undefined, path: CurrentPathStep[], isDefault: boolean }[],
        color: 'group' | 'column',
        dependencies: CurrentPathStep[],
        instanceDisplay: 'card' | 'chip' | undefined,
        cardColor: { path: CurrentPathStep[], as: CardColorMode } | undefined,
        cardHeader: { id: string, path: CurrentPathStep[], displayOptions?: Record<string, unknown> }[],
        cardBody: { id: string, label: string | undefined, path: CurrentPathStep[], displayOptions?: Record<string, unknown> }[],
      }

      const migrateConfiguration = (
        oldConfiguration: OldSwimlaneConfiguration,
        dimensionId: string | undefined,
        conceptDefinitionId: string | undefined
      ): NewSwimlaneConfiguration => {
        if (!conceptDefinitionId || !dimensionId) {
          return {
            id: oldConfiguration.id,
            type: 'Swimlane',
            columnBy: { fieldId: undefined, filters: undefined, withEmpty: false, size: { type: 'auto' } },
            groupBy: [],
            color: 'column',
            dependencies: [],
            instanceDisplay: undefined,
            cardColor: undefined,
            cardHeader: [],
            cardBody: [],
          };
        }
        const Concept_Name = 'bf040b6b-58be-5194-8f32-4e1c8df24b42';
        if (oldConfiguration.withCustomConfig) {
          const groupByField = oldConfiguration.defaultGroupByFieldId ? objectStore.getObjectOrNull<FieldStoreObject>(oldConfiguration.defaultGroupByFieldId) : null;
          const colorByField = oldConfiguration.defaultColorFieldId ? objectStore.getObjectOrNull<FieldStoreObject>(oldConfiguration.defaultColorFieldId) : null;
          const columnByField = oldConfiguration.defaultColumnByFieldId ? objectStore.getObjectOrNull<FieldStoreObject>(oldConfiguration.defaultColumnByFieldId) : null;
          const progressField = oldConfiguration.defaultProgressFieldId ? objectStore.getObjectOrNull<FieldStoreObject>(oldConfiguration.defaultProgressFieldId) : null;
          return {
            id: oldConfiguration.id,
            type: 'Swimlane',
            columnBy: { fieldId: oldConfiguration.defaultColumnByFieldId, filters: undefined, withEmpty: false, size: { type: 'auto' } },
            groupBy: groupByField ? [{
              id: uuid(),
              path: [
                { type: PathStepType.dimension, conceptDefinitionId },
                {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: groupByField.id,
                }] as CurrentPathStep[],
              label: undefined,
              isDefault: true,
            }] : [],
            color: 'column',
            dependencies: [],
            instanceDisplay: 'chip',
            cardColor: colorByField ? {
              path: [{
                type: PathStepType.dimension, conceptDefinitionId,
              }, {
                type: PathStepType.mapping,
                mapping: { type: 'parameter', id: dimensionId },
              }, {
                type: PathStepType.field,
                fieldId: colorByField.id,
              }] as CurrentPathStep[],
              as: CardColorMode.Dot,
            } : undefined,
            cardHeader: [{
              id: uuid(),
              path: [{
                type: PathStepType.dimension, conceptDefinitionId,
              }, {
                type: PathStepType.mapping,
                mapping: { type: 'parameter', id: dimensionId },
              }, {
                type: PathStepType.field,
                fieldId: Concept_Name,
              }] as CurrentPathStep[],
              displayOptions: {},
            }],
            cardBody: [
              ...columnByField ? [{
                id: uuid(),
                label: undefined,
                path: [{
                  type: PathStepType.dimension, conceptDefinitionId,
                }, {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: columnByField.id,
                }] as CurrentPathStep[],
                displayOptions: {},
              }] : [],
              ...groupByField ? [{
                id: uuid(),
                label: undefined,
                path: [{
                  type: PathStepType.dimension, conceptDefinitionId,
                }, {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: groupByField.id,
                }] as CurrentPathStep[],
                displayOptions: {},
              }] : [],
              ...colorByField ? [{
                id: uuid(),
                label: undefined,
                path: [{
                  type: PathStepType.dimension, conceptDefinitionId,
                }, {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: colorByField.id,
                }] as CurrentPathStep[],
                displayOptions: {},
              }] : [],
              ...progressField ? [{
                id: uuid(),
                label: undefined,
                path: [{
                  type: PathStepType.dimension, conceptDefinitionId,
                }, {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: progressField.id,
                }] as CurrentPathStep[],
                displayOptions: {},
              }] : [],
            ],
          };
        } else {
          const conceptDefinition = objectStore.getObjectOrNull<ConceptDefinitionStoreObject>(conceptDefinitionId);
          const groupByField = conceptDefinition?.[ConceptDefinition_SwimlaneGroupBy]
            ? objectStore.getObjectOrNull<FieldStoreObject>(conceptDefinition[ConceptDefinition_SwimlaneGroupBy])
            : null;
          const colorByField = conceptDefinition?.[ConceptDefinition_Color]
            ? objectStore.getObjectOrNull<FieldStoreObject>(conceptDefinition[ConceptDefinition_Color])
            : null;
          const columnByField = conceptDefinition?.[ConceptDefinition_SwimlaneColumnBy]
            ? objectStore.getObjectOrNull<FieldStoreObject>(conceptDefinition[ConceptDefinition_SwimlaneColumnBy])
            : null;
          const progressField = conceptDefinition?.[ConceptDefinition_SwimlaneProgress]
            ? objectStore.getObjectOrNull<FieldStoreObject>(conceptDefinition[ConceptDefinition_SwimlaneProgress])
            : null;
          return {
            id: oldConfiguration.id,
            type: 'Swimlane',
            columnBy: { fieldId: columnByField?.id, filters: undefined, withEmpty: false, size: { type: 'auto' } },
            groupBy: groupByField ? [{
              id: uuid(),
              path: [
                { type: PathStepType.dimension, conceptDefinitionId },
                {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: groupByField.id,
                }] as CurrentPathStep[],
              label: undefined,
              isDefault: true,
            }] : [],
            color: 'column',
            dependencies: [],
            instanceDisplay: 'chip',
            cardColor: colorByField ? {
              path: [{
                type: PathStepType.dimension, conceptDefinitionId,
              }, {
                type: PathStepType.mapping,
                mapping: { type: 'parameter', id: dimensionId },
              }, {
                type: PathStepType.field,
                fieldId: colorByField.id,
              }] as CurrentPathStep[],
              as: CardColorMode.Dot,
            } : undefined,
            cardHeader: [{
              id: uuid(),
              path: [{
                type: PathStepType.dimension, conceptDefinitionId,
              }, {
                type: PathStepType.mapping,
                mapping: { type: 'parameter', id: dimensionId },
              }, {
                type: PathStepType.field,
                fieldId: Concept_Name,
              }] as CurrentPathStep[],
              displayOptions: {},
            }],
            cardBody: [
              ...columnByField ? [{
                id: uuid(),
                label: undefined,
                path: [{
                  type: PathStepType.dimension, conceptDefinitionId,
                }, {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: columnByField.id,
                }] as CurrentPathStep[],
                displayOptions: {},
              }] : [],
              ...groupByField ? [{
                id: uuid(),
                label: undefined,
                path: [{
                  type: PathStepType.dimension, conceptDefinitionId,
                }, {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: groupByField.id,
                }] as CurrentPathStep[],
                displayOptions: {},
              }] : [],
              ...colorByField ? [{
                id: uuid(),
                label: undefined,
                path: [{
                  type: PathStepType.dimension, conceptDefinitionId,
                }, {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: colorByField.id,
                }] as CurrentPathStep[],
                displayOptions: {},
              }] : [],
              ...progressField ? [{
                id: uuid(),
                label: undefined,
                path: [{
                  type: PathStepType.dimension, conceptDefinitionId,
                }, {
                  type: PathStepType.mapping,
                  mapping: { type: 'parameter', id: dimensionId },
                }, {
                  type: PathStepType.field,
                  fieldId: progressField.id,
                }] as CurrentPathStep[],
                displayOptions: {},
              }] : [],
            ],
          };
        }
      };

      objectStore.getObject(FieldBlockDisplay)
        .navigateBack(Class_Instances)
        .filter((display): display is StoreObject & { [FieldBlockDisplay_FieldDisplayConfiguration]: ViewDefinition } => (
          Boolean((display[FieldBlockDisplay_FieldDisplayConfiguration] as ViewDefinition | undefined)?.viewDefinitions?.some(({ type }) => type === 'Swimlane'))))
        .forEach((display) => objectStore.updateObject(display.id, {
          [FieldBlockDisplay_FieldDisplayConfiguration]: joinObjects(
            display[FieldBlockDisplay_FieldDisplayConfiguration],
            {
              viewDefinitions: display[FieldBlockDisplay_FieldDisplayConfiguration].viewDefinitions.map((viewConfiguration) => {
                if (viewConfiguration.type === 'Swimlane') {
                  const fieldPath = (display[FieldBlockDisplay_FieldPath] ?? []) as CurrentPathStep[];
                  return migrateConfiguration(
                    viewConfiguration as unknown as OldSwimlaneConfiguration,
                    `${display.id}_Dimension`,
                    getPathReturnedConceptDefinitionId(fieldPath)
                  );
                }
                return viewConfiguration;
              }),
            }
          ),
        }));
    },
  },
  [MigrationDropRoarField]: {
    handler: ({ getObject, updateObject }) => {
      getObject(EmbeddingField)
        .navigateBack(Class_Instances)
        .forEach((embeddingField) => {
          if (embeddingField[Field_Formula] !== undefined) {
            updateObject(embeddingField.id, { [Field_Formula]: null });
          }
        });
    },
  },
  [MigrationIconLibraryToMaterialSymbols]: {
    handler: ({ getObject, updateObject }) => {
      const iconMap: Record<string, string | undefined> = {
        auto_fix_high: 'auto_fix',
        chevron_left: 'keyboard_arrow_left',
        chevron_right: 'keyboard_arrow_right',
        email: 'mail',
        gps_fixed: 'my_location',
        gps_off: 'location_disabled',
        message: 'comment',
        people_alt: 'group',
        plus_one: 'exposure_plus_1',
        question_answer: 'forum',
        queue: 'library_add',
      };
      const oldIcons = Object.keys(iconMap);

      getObject(ConceptDefinition)
        .navigateBack(Class_Instances)
        .forEach((conceptDefinition) => {
          const current = conceptDefinition[ConceptDefinition_Icon];
          if (typeof current !== 'string') {
            updateObject(conceptDefinition.id, { [ConceptDefinition_Icon]: 'category' });
          } else if (oldIcons.includes(current)) {
            updateObject(conceptDefinition.id, { [ConceptDefinition_Icon]: iconMap[current] ?? 'category' });
          }
        });
    },
  },
  [MigrationIconField]: {
    dependencies: [TypeModuleId],
    handler: ({ updateObject }, helpers) => {
      const typeHelper = helpers.typeHelper as TypeHelperRevision1;

      typeHelper.relation(ConceptDefinition, ConceptDefinition_MainIconField, IconField);

      updateObject(IconField, { [Instance_Of]: FieldDefinition, [Class_Extend]: Field, [FieldDefinition_Title]: 'Icon' });
    },
  },
  [MigrationTimeseriesTableDimensionDisplay]: {
    dependencies: [TypeModuleId],
    handler: (objectStore) => {
      interface ViewDefinition {
        viewDefinitions: {
          type: string,
          [key: string]: unknown,
        }[],
      }

      interface Configuration {
        id: string,
        type: 'TimeseriesTable',
        timeAxis: {
          axis: 'x' | 'y',
        },
        seriesAxis: {
          axis: 'x' | 'y',
          display: 'show' | 'hide' | undefined,
        },
        series: unknown[],
        dimensionsDisplay: {
          id: string,
          axis: 'x' | 'y',
          display: 'show' | 'hide' | undefined,
        }[] | undefined,
      }

      const migrateConfiguration = (oldConfiguration: Configuration): Configuration => {
        const { dimensionsDisplay = [] } = oldConfiguration;
        if (oldConfiguration.seriesAxis.axis === 'y' && oldConfiguration.timeAxis.axis === 'x' && dimensionsDisplay.every(({ axis }) => axis === 'x')) {
          return joinObjects(
            oldConfiguration,
            {
              seriesAxis: joinObjects(oldConfiguration.seriesAxis, {
                display: 'show' as const,
              }),
            }
          );
        } else if (oldConfiguration.seriesAxis.axis === 'x' && oldConfiguration.timeAxis.axis === 'y' && dimensionsDisplay.every(({ axis }) => axis === 'x')) {
          return joinObjects(
            oldConfiguration,
            {
              seriesAxis: dimensionsDisplay.length === 0 ? joinObjects(oldConfiguration.seriesAxis, { display: 'show' as const }) : oldConfiguration.seriesAxis,
              dimensionsDisplay: dimensionsDisplay.map((dimensionDisplay) => (joinObjects(dimensionDisplay, {
                display: 'show' as const,
              }))),
            }
          );
        }
        for (let i = 0; i < dimensionsDisplay.length; i += 1) {
          const dimensionDisplay = dimensionsDisplay[i];
          if (dimensionDisplay.axis === 'y' && oldConfiguration.seriesAxis.axis === 'x' && oldConfiguration.timeAxis.axis === 'x'
            && dimensionsDisplay.every((d, j) => d.axis === 'x' || i === j)) {
            return joinObjects(
              oldConfiguration,
              {
                seriesAxis: dimensionsDisplay.length === 0 ? joinObjects(oldConfiguration.seriesAxis, { display: 'show' as const }) : oldConfiguration.seriesAxis,
                dimensionsDisplay: dimensionsDisplay.map((d) => (joinObjects(d, {
                  display: 'show' as const,
                }))),
              }
            );
          }
        }
        return oldConfiguration;
      };

      objectStore.getObject(FieldBlockDisplay)
        .navigateBack(Class_Instances)
        .filter((display): display is StoreObject & { [FieldBlockDisplay_FieldDisplayConfiguration]: ViewDefinition } => (
          Boolean((display[FieldBlockDisplay_FieldDisplayConfiguration] as ViewDefinition | undefined)?.viewDefinitions?.some(({ type }) => type === 'TimeseriesTable'))))
        .forEach((display) => objectStore.updateObject(display.id, {
          [FieldBlockDisplay_FieldDisplayConfiguration]: joinObjects(
            display[FieldBlockDisplay_FieldDisplayConfiguration],
            {
              viewDefinitions: display[FieldBlockDisplay_FieldDisplayConfiguration].viewDefinitions.map((viewConfiguration) => {
                if (viewConfiguration.type === 'TimeseriesTable') {
                  return migrateConfiguration(viewConfiguration as unknown as Configuration);
                }
                return viewConfiguration;
              }),
            }
          ),
        }));
    },
  },
  [MigrationBooleanField]: {
    dependencies: [TypeModuleId],
    handler: ({ updateObject }, helpers) => {
      const typeHelper = helpers.typeHelper as TypeHelperRevision1;

      typeHelper.type(BooleanField, { of: FieldDefinition, extends: Field });
      typeHelper.property(BooleanField, BooleanField_TrueValue);
      typeHelper.property(BooleanField, BooleanField_FalseValue);
      updateObject(BooleanField, { [FieldDefinition_Title]: 'Boolean' });
    },
  },
  [MigrationMainBooleanField]: {
    dependencies: [TypeModuleId],
    handler: (_, helpers) => {
      const typeHelper = helpers.typeHelper as TypeHelperRevision1;
      typeHelper.property(ConceptDefinition, ConceptDefinition_MainBooleanField);
    },
  },
  [MigrationAttachmentField]: {
    dependencies: [TypeModuleId],
    handler: ({ updateObject }, helpers) => {
      const typeHelper = helpers.typeHelper as TypeHelperRevision1;

      typeHelper.type(AttachmentField, { of: FieldDefinition, extends: Field });
      updateObject(AttachmentField, { [FieldDefinition_Title]: 'Attachment' });

      typeHelper.association(Attachment, [AnyElement, AnyElement], Concept);
      typeHelper.property(Attachment, Attachment_Revision);
      typeHelper.relation(Attachment, Attachment_UploadedBy, User);
      typeHelper.property(Attachment, Attachment_UploadedAt);
      typeHelper.property(Attachment, Attachment_Rank);
    },
  },
  [MigrationUserIsEnabledAsField]: {
    handler: ({ updateObject, withAssociation }) => {
      updateObject(User_IsEnabled, {
        [Instance_Of]: BooleanField,
        [Property_OfClass]: null,
        [Field_IsCore]: true,
        [Field_Title]: 'Is enabled',
        [Field_ApiAlias]: 'is_enabled',
        [BooleanField_TrueValue]: { icon: 'toggle_on', color: '#48B164', tooltip: 'Enabled' },
        [BooleanField_FalseValue]: { icon: 'toggle_off', color: '#FF5B4C', tooltip: 'Disabled' },
      });

      updateObject(UserIsEnabledDimension, { [Instance_Of]: FieldDimension, [FieldDimension_Field]: User_IsEnabled });

      withAssociation(FieldDimensionTypes)
        .withRole(FieldDimensionTypes_Role_ConceptDefinition, User)
        .withRole(FieldDimensionTypes_Role_FieldDimension, UserIsEnabledDimension)
        .updateObject({});
    },
  },
  [MigrationViewFilters]: {
    dependencies: [TypeModuleId],
    handler: (objectStore) => {
      const migratePath = (path: PathStep[], dimensionId: string): PathStep[] => path.map((step) => {
        if (isMappingStep(step) && step.mapping.id === FILTER_PARAMETER_CURRENT) {
          return joinObjects(step, { mapping: { id: dimensionId, type: 'parameter' as const } }) as MappingStep;
        }
        return step;
      });

      const migrateFilter = (oldFilters: Filters | undefined, dimensionId: string): Filters | undefined => {
        if (!oldFilters) {
          return oldFilters;
        } else if (isFilterNode(oldFilters)) {
          return {
            condition: oldFilters.condition,
            children: oldFilters.children?.map((child) => migrateFilter(child, dimensionId)),
          } as FilterNode;
        } else if (oldFilters.rightValue?.type === FilterValueType.path) {
          return {
            leftValue: migratePath(oldFilters.leftValue ?? [], dimensionId),
            operator: oldFilters.operator,
            rightValue: {
              type: 'path',
              path: migratePath(oldFilters.rightValue?.path ?? [], dimensionId),
            },
          } as FilterCondition;
        } else {
          return {
            leftValue: migratePath(oldFilters.leftValue ?? [], dimensionId),
            operator: oldFilters.operator,
            rightValue: oldFilters.rightValue?.raw === FILTER_PARAMETER_CURRENT ? { type: FilterValueType.raw, raw: dimensionId } : oldFilters.rightValue,
          } as FilterCondition;
        }
      };

      objectStore.getObject(AssociationFilter)
        .navigateBack<AssociationFilterStoreObject>(Instance_Of)
        .forEach((viewFilter) => {
          const viewsField = viewFilter.navigateOrNull<ViewsFieldStoreObject>(ViewFilter_ViewsField);
          const dimensionId = viewsField?.[ViewsField_Definition]?.data.at?.(0)?.id;
          if (dimensionId) {
            objectStore.updateObject<AssociationFilterRaw>(viewFilter.id, { [AssociationFilter_Path]: migratePath(viewFilter[AssociationFilter_Path], dimensionId) });
          }
        });
      objectStore.getObject(ConditionFilter)
        .navigateBack<ConditionFilterStoreObject>(Instance_Of)
        .forEach((viewFilter) => {
          const viewsField = viewFilter.navigateOrNull<ViewsFieldStoreObject>(ViewFilter_ViewsField);
          const dimensionId = viewsField?.[ViewsField_Definition]?.data.at?.(0)?.id;
          if (dimensionId) {
            objectStore.updateObject<ConditionFilterRaw>(viewFilter.id, { [ConditionFilter_Filters]: migrateFilter(viewFilter[ConditionFilter_Filters], dimensionId) });
          }
        });
    },
  },
  [MigrationIconFieldInChip]: {
    dependencies: [TypeModuleId],
    handler: (_, helpers) => {
      const typeHelper = helpers.typeHelper as TypeHelperRevision1;
      typeHelper.relation(ConceptDefinition, ConceptDefinition_ChipIconField, Field);
    },
  },
  [MigrationCardFieldButtonPosition]: {
    dependencies: [TypeModuleId],
    handler: (_, helpers) => {
      const typeHelper = helpers.typeHelper as TypeHelperRevision1;
      typeHelper.property(CardField, CardField_ButtonPositionX);
      typeHelper.property(CardField, CardField_ButtonPositionY);
    },
  },
  [MigrationSupportFixedWidthInConceptDefinitionInstancesTab]: {
    handler: ({ getObject, updateObject }) => {
      getObject(ConceptDefinitionInstanceAdministrationColumn)
        .navigateBack(Class_Instances)
        .forEach((column) => {
          if (column[ConceptDefinitionInstanceAdministrationColumn_Width] !== undefined) {
            if (typeof column[ConceptDefinitionInstanceAdministrationColumn_Width] === 'number') {
              const newWidth = { type: 'percent', value: column[ConceptDefinitionInstanceAdministrationColumn_Width] };
              updateObject(column.id, { [ConceptDefinitionInstanceAdministrationColumn_Width]: newWidth });
            } else {
              updateObject(column.id, { [ConceptDefinitionInstanceAdministrationColumn_Width]: null });
            }
          }
        });
    },
  },
  [MigrationReverseWorkflowField]: {
    dependencies: [TypeModuleId],
    handler: ({ getObject, updateObject, createObject, withAssociation }, helpers) => {
      const typeHelper = helpers.typeHelper as TypeHelperRevision1;

      typeHelper.relation(WorkflowField, WorkflowField_TargetedConceptDefinition, ConceptDefinition);
      typeHelper.type(ReverseWorkflowField, { of: FieldDefinition, extends: Field });
      typeHelper.relation(ReverseWorkflowField, ReverseWorkflowField_ReverseField, WorkflowField);
      updateObject(ReverseWorkflowField, { [FieldDefinition_Title]: 'Workflow reverse' });

      getObject(WorkflowField)
        .navigateBack(Class_Instances)
        .forEach((workflowField) => {
          const workflow = workflowField.navigateOrNull(WorkflowField_Workflow);
          if (workflow !== null) {
            const targetedConceptDefinition = workflow.navigateOrNull(Workflow_TargetedConceptDefinition);
            if (targetedConceptDefinition !== null) {
              updateObject(workflowField.id, { [WorkflowField_TargetedConceptDefinition]: targetedConceptDefinition.id });

              const reverseWorkflowFieldId = createObject({ [Instance_Of]: ReverseWorkflowField, [ReverseWorkflowField_ReverseField]: workflowField.id });
              const dimensionId = createObject({ [Instance_Of]: FieldDimension, [FieldDimension_Field]: reverseWorkflowFieldId });
              withAssociation(FieldDimensionTypes)
                .withRole(FieldDimensionTypes_Role_ConceptDefinition, targetedConceptDefinition.id)
                .withRole(FieldDimensionTypes_Role_FieldDimension, dimensionId)
                .updateObject({});
            }
          }
        });
    },
  },
};

export default migrations;
