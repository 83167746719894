import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { ReactElement } from 'react';
import { forwardRef, useRef, useState } from 'react';
import type { ConceptStoreObject } from 'yooi-modules/modules/conceptModule';
import { getConceptChipFields, getConceptUrl, getInstanceLabelOrUndefined, idFieldHandler } from 'yooi-modules/modules/conceptModule';
import { Concept_FunctionalId, ConceptFunctionalIdDimension } from 'yooi-modules/modules/conceptModule/ids';
import { joinObjects } from 'yooi-utils';
import Typo from '../../../../../components/atoms/Typo';
import Overlay from '../../../../../components/molecules/Overlay';
import useStore from '../../../../../store/useStore';
import base from '../../../../../theme/base';
import { buildPadding, Spacing, spacingRem } from '../../../../../theme/spacingDefinition';
import makeStyles from '../../../../../utils/makeStyles';
import { remToPx } from '../../../../../utils/sizeUtils';
import useBackdropClick from '../../../../../utils/useBackdropClick';
import useDerivedState from '../../../../../utils/useDerivedState';
import useNavigation from '../../../../../utils/useNavigation';
import useTheme from '../../../../../utils/useTheme';
import { getChipOptionWithUnknown } from '../../../modelTypeUtils';
import type { NavigationFilter } from '../../../navigationUtils';

const useStyles = makeStyles((theme) => ({
  container: joinObjects(
    {
      backgroundColor: theme.color.background.neutral.default,
      display: 'flex',
      flex: '1',
      alignItems: 'center',
      gap: spacingRem.s,
      border: `0.1rem solid ${theme.color.transparent}`,
      borderRadius: base.borderRadius.medium,
      boxShadow: base.shadowElevation.low,
    },
    buildPadding({ x: Spacing.s, y: Spacing.xs })
  ),
  idContainer: {
    flexShrink: 0,
  },
  notInEditionContainer: {
    '&:hover': {
      borderColor: theme.color.border.default,
    },
    ' &:focus': {
      borderColor: theme.color.border.default,
    },
  },
  inEditionContainer: {
    borderColor: theme.color.border.primary,
  },
  colorSquare: {
    height: '1.2rem',
    width: '1.2rem',
    minWidth: '1.2rem',
    borderRadius: base.borderRadius.medium,
  },
}), 'instanceChip');

interface InstanceChipProps {
  instanceId: string,
  width: number,
  renderTooltip?: (editMode: boolean) => ReactElement,
  inEdition?: boolean,
  onEditionStart?: () => void,
  onEditionEnd?: () => void,
  navigationFilters?: NavigationFilter,
}

const InstanceChip = forwardRef<HTMLDivElement, InstanceChipProps>(({
  instanceId,
  width,
  renderTooltip,
  inEdition = false,
  onEditionStart,
  onEditionEnd,
  navigationFilters,
}, ref) => {
  const store = useStore();
  const theme = useTheme();
  const navigation = useNavigation();
  const classes = useStyles();
  const [editMode, setEditMode] = useDerivedState(() => inEdition, [inEdition]);
  const divRef = useRef<HTMLDivElement>(null);
  const [isHovering, setIsHovering] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  const instance = store.getObjectOrNull<ConceptStoreObject>(instanceId);
  const height = divRef.current?.clientHeight;

  useBackdropClick(divRef, () => setEditMode(false), false);

  if (!instance) {
    return null;
  }
  const conceptChipField = getConceptChipFields(store, instance);
  const name = getInstanceLabelOrUndefined(store, instance, false);
  let functionalId: string | undefined;
  if (conceptChipField.some(({ id }) => id === Concept_FunctionalId)) {
    functionalId = idFieldHandler(store, Concept_FunctionalId).getValueAsText?.({ [ConceptFunctionalIdDimension]: instance.id });
  }
  const option = getChipOptionWithUnknown(store, instance.id);

  return (
    <div
      ref={composeReactRefs(ref, divRef)}
      className={classnames({ [classes.container]: true, [classes.inEditionContainer]: editMode, [classes.notInEditionContainer]: !editMode })}
      style={{ width }}
      role="button"
      tabIndex={0}
      onMouseEnter={() => {
        timeoutRef.current = setTimeout(() => {
          setIsHovering(true);
        }, 600);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }}
      onClick={() => {
        onEditionStart?.();
        setEditMode(true);
      }}
      onDoubleClick={() => {
        const conceptUrl = getConceptUrl(store, instanceId);
        if (navigationFilters) {
          navigation.push(
            instanceId,
            {
              pathname: conceptUrl,
              navigationFilters,
            }
          );
        } else {
          navigation.push(instanceId, { pathname: conceptUrl });
        }
      }}
      onKeyDown={(event) => {
        if (event?.key === 'Enter') {
          onEditionStart?.();
          setEditMode(true);
        }
      }}
    >
      {option.squareColor && (
        <div className={classes.colorSquare} style={{ backgroundColor: option.squareColor }} />
      )}
      {functionalId && (
        <div className={classes.idContainer}>
          <Typo maxLine={1} color={theme.color.text.secondary}>{functionalId}</Typo>
        </div>
      )}
      <Typo maxLine={1}>{name}</Typo>
      {renderTooltip && (editMode || isHovering) && (
        <Overlay
          target={divRef}
          offset={[0, (height ?? 0) + remToPx(spacingRem.xs)]}
          onBackdropClick={() => {
            onEditionEnd?.();
            setEditMode(false);
          }}
        >
          {renderTooltip(editMode)}
        </Overlay>
      )}
    </div>
  );
});

export default InstanceChip;
