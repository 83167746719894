import type { FunctionComponent } from 'react';
import { BLOCK_PARAMETER_CURRENT, FILTER_PARAMETER_OPTION, InstanceReferenceType, PathStepType } from 'yooi-modules/modules/conceptModule';
import type { Filters, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { formatOrUndef } from '../../../../utils/stringUtils';
import { getBlockParameterDefinitionCurrent } from '../../conceptFilterIdUtils';
import FilterComposite from '../../filter/filterComposite/FilterComposite';
import { getConceptDefinitionNameOrEntity } from '../../modelTypeUtils';
import { countValidFilters } from '../../filter/filterUtils';

const useStyles = makeStyles({
  filter: {
    marginBottom: spacingRem.xs,
    marginTop: spacingRem.xs,
    display: 'flex',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
}, 'filterField');
interface FilterFieldProps {
  targetTypeId: string | undefined,
  modelTypeId: string,
  selectFieldFilters : Filters | undefined,
  updateFilters: (filters: Filters[]) => void,
}

const FilterField: FunctionComponent<FilterFieldProps> = ({
  targetTypeId,
  modelTypeId,
  selectFieldFilters,
  updateFilters,
}) => {
  const store = useStore();
  const classes = useStyles();
  const fieldParameterDefinition : SingleParameterDefinition[] = targetTypeId ? [{ id: FILTER_PARAMETER_OPTION, label: i18n`Current (${formatOrUndef(getConceptDefinitionNameOrEntity(store, targetTypeId))})`, typeId: targetTypeId, type: 'parameter' }] : [];
  const blockFieldFiltersDefinition = {
    updateFilters,
    definition: [
      {
        filter: selectFieldFilters,
        title: i18n`Select`,
        subtitle: i18n`Filter instances...`,
      },
    ],
  };

  return (
    <FilterComposite
      suggestedPaths={
        ([targetTypeId ? ({
          label: i18n`Current (${formatOrUndef(getConceptDefinitionNameOrEntity(store, targetTypeId))})`,
          path: [
            { type: PathStepType.dimension, conceptDefinitionId: targetTypeId },
            { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_OPTION, type: InstanceReferenceType.parameter } },
          ],
        })
          : ({
            label: i18n`Dimension (${formatOrUndef(getConceptDefinitionNameOrEntity(store, modelTypeId))})`,
            path: [
              { type: PathStepType.dimension, conceptDefinitionId: modelTypeId },
              { type: PathStepType.mapping, mapping: { id: BLOCK_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
            ],
          })])
      }
      parameterDefinitions={[
        getBlockParameterDefinitionCurrent(modelTypeId),
        ...fieldParameterDefinition,
      ]}
      filtersDefinition={blockFieldFiltersDefinition}
      renderCompositeTitle={() => {
        const filterCount = blockFieldFiltersDefinition.definition.map(({ filter }) => filter).reduce((acc, filter) => acc + countValidFilters(store, filter), 0);
        return (
          <span className={classes.filter}>
            <Icon colorVariant={IconColorVariant.alternative} name={IconName.filter_alt} />
            <Tooltip title={i18n`Only filtered instances can be selected and existing instances that do not respect the filters will appear with an error.`}>
              <div className={classes.filter}>
                <Icon colorVariant={IconColorVariant.info} name={IconName.info} />
              </div>
            </Tooltip>
            <Tooltip title={i18n`Filters (${filterCount})`}>
              <Typo maxLine={1}>{i18n`Filters (${filterCount})`}</Typo>
            </Tooltip>
          </span>
        );
      }}
    />
  );
};

export default FilterField;
