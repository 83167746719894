import type { ComponentProps, ReactElement } from 'react';
import { useState } from 'react';
import { IconName } from '../../../../components/atoms/Icon';
import Typo from '../../../../components/atoms/Typo';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import ToggleButton from '../../../../components/molecules/ToggleButton';
import BlockContent from '../../../../components/templates/BlockContent';
import type { ColumnDefinition, GroupEntry, ItemEntry } from '../../../../components/templates/DataTable';
import DataTable from '../../../../components/templates/DataTable';
import type { Pagination } from '../../../../components/templates/PageSelector';
import VerticalBlock from '../../../../components/templates/VerticalBlock';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { sanitizeSearchValue } from '../../../../utils/searchUtils';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../../utils/useSizeContext';
import useTheme from '../../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../../utils/useUsageContext';
import type { Chip } from '../../../_global/fieldUtils';
import SearchTextInput from '../../../_global/filter/SearchTextInput';
import { FilterParams, useFilterStorage } from '../../../_global/filter/useFilterSessionStorage';
import type { Data } from '../../../_global/useFilterAndSort';
import useFilterAndSort from '../../../_global/useFilterAndSort';

const useStyles = makeStyles({
  configurationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: spacingRem.xs,
  },
}, 'instanceTable');

interface InstanceTableProps<ListItem extends Data> {
  filterId: string,
  list: ListItem[],
  searchStrings: (item: ListItem) => (string | undefined)[],
  columnsDefinition: ColumnDefinition<ListItem>[],
  sort?: Parameters<typeof useFilterAndSort<ListItem>>[3],
  groupBy?: {
    defaultGroupById: string,
    computeOptions: ComponentProps<typeof SearchAndSelect<Chip>>['computeOptions'],
    computeSelectedOption: (groupId: string | undefined) => ComponentProps<typeof SearchAndSelect<Chip>>['selectedOption'],
    getGroupLabel: (key: string) => string,
  },
}

const InstanceTable = <ListItem extends Data>({ filterId, list, searchStrings, columnsDefinition, sort, groupBy }: InstanceTableProps<ListItem>): ReactElement | null => {
  const theme = useTheme();
  const classes = useStyles();

  const [search] = useFilterStorage(filterId, FilterParams.nameSearch);
  const [groupById, setGroupById] = useState(groupBy?.defaultGroupById);
  const sanitizedSearch = search ? sanitizeSearchValue(search) : undefined;
  const [showAll, setShowAll] = useState(false);

  const { generatePageList, generateList, generateGroupedList, generateGroupedPageList, doSort, sortCriteria } = useFilterAndSort(
    filterId,
    list,
    (item) => !sanitizedSearch || searchStrings(item).some((value) => value && sanitizeSearchValue(value).includes(sanitizedSearch)),
    sort,
    groupBy && groupById
      ? {
        key: groupById,
        getGroupKey: (item) => {
          const value = (item as Record<string, unknown>)[groupById];
          return typeof value === 'string' ? value : undefined;
        },
        getGroupLabel: groupBy.getGroupLabel,
        getGroupColor: () => undefined,
      }
      : undefined
  );

  let data: { list: (ItemEntry<ListItem> | GroupEntry)[], pagination?: Pagination | undefined };
  if (showAll && groupById) {
    data = generateGroupedList();
  } else if (showAll) {
    data = generateList();
  } else if (groupById) {
    data = generateGroupedPageList(25);
  } else {
    data = generatePageList(25);
  }

  return (
    <VerticalBlock>
      <BlockContent padded>
        <UsageContextProvider usageVariant={UsageVariant.inForm}>
          <SizeContextProvider sizeVariant={SizeVariant.small}>
            <div className={classes.configurationContainer}>
              <SpacingLine>
                <SearchTextInput placeholder={i18n`Search element`} element={filterId} />
                <Typo maxLine={1} color={theme.color.text.disabled}>{i18n`${data.pagination?.totalItems ?? data.list.filter(({ type }) => type === 'item').length} results`}</Typo>
              </SpacingLine>
              <SpacingLine>
                {groupBy && (
                  <>
                    <Typo maxLine={1} color={theme.color.text.secondary}>{i18n`Group by`}</Typo>
                    <SearchAndSelect<Chip>
                      clearable
                      computeOptions={groupBy.computeOptions}
                      selectedOption={groupBy.computeSelectedOption(groupById)}
                      onSelect={(selectedGroupBy) => {
                        setGroupById(selectedGroupBy?.id);
                      }}
                    />
                  </>
                )}
                <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
                  <ToggleButton
                    name={i18n`Pagination`}
                    onClick={() => setShowAll((old) => !old)}
                    active={!showAll}
                    icon={!showAll ? IconName.toggle_on : IconName.toggle_off}
                  />
                </SizeContextProvider>
              </SpacingLine>
            </div>
          </SizeContextProvider>
        </UsageContextProvider>
      </BlockContent>
      <DataTable
        list={data.list}
        pagination={data.pagination}
        doSort={doSort}
        sortCriteria={sortCriteria}
        columnsDefinition={columnsDefinition}
      />
    </VerticalBlock>
  );
};

export default InstanceTable;
