import classnames from 'classnames';
import { forwardRef } from 'react';
import { IconName } from '../../../../components/atoms/Icon';
import Chip from '../../../../components/molecules/Chip';
import i18n from '../../../../utils/i18n';
import makeSelectorsClasses from '../../../../utils/makeSelectorsClasses';
import makeStyles from '../../../../utils/makeStyles';
import type { NavigationPayload, UseNavigation } from '../../../../utils/useNavigation';
import useNavigation from '../../../../utils/useNavigation';

const selectorsClasses = makeSelectorsClasses('visibilityHandler');

const useStyles = makeStyles({
  chip: {
    width: '100%',
    display: 'inline-grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '0.5rem',
    '&:hover, &:focus, &:focus-within': {
      [`& .${selectorsClasses.visibilityHandler}`]: {
        visibility: 'visible',
      },
    },
  },
  opacity: {
    opacity: 0.3,
  },
}, 'graphChartChip');

interface GraphChartChipProps {
  isActive: boolean,
  isHigh: boolean,
  isFaded: boolean,
  width: number,
  option: {
    label: string,
    tooltip?: string,
    squareColor?: string,
    color?: string,
    borderStyle?: 'dashed' | 'solid' | undefined,
    borderColor?: string,
    getNavigationPayload?: (navigation: UseNavigation) => NavigationPayload,
  },
  onClick: () => void,
}

const GraphChartChip = forwardRef<HTMLDivElement, GraphChartChipProps>(({ isActive, isFaded, option, width, onClick, isHigh }, ref) => {
  const classes = useStyles();
  const navigation = useNavigation();

  const navigationPayload = option.getNavigationPayload?.(navigation);

  return (
    <div ref={ref} className={classnames({ [classes.chip]: true, [classes.opacity]: isFaded })}>
      <Chip
        width={width}
        text={option.label}
        tooltip={option.tooltip}
        squareColor={option.squareColor}
        color={option.color}
        borderStyle="solid"
        borderColor={isHigh ? option.borderColor : 'transparent'}
        borderWidth={0.1}
        actions={!isFaded ? [
          {
            key: 'highlight',
            icon: isActive ? IconName.location_disabled : IconName.my_location,
            tooltip: isActive ? i18n`Hide path` : i18n`Show path`,
            action: onClick,
            showOnHover: true,
          },
          ...navigationPayload ? [{
            key: 'open',
            icon: IconName.output,
            tooltip: i18n`Open`,
            action: { to: navigationPayload.to, state: navigationPayload.state, openInNewTab: false },
            showOnHover: true,
          }] : []] : undefined}
      />
    </div>
  );
});

export default GraphChartChip;
