import type { FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import {
  getPathReturnedConceptDefinitionId,
  isDimensionStep,
  isFieldStep,
  isFilterStep,
  isGlobalDimensionStep,
  isMappingStep,
  isMultiplePath,
  isRelationalType,
} from 'yooi-modules/modules/conceptModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreReadOnly, ObjectStoreWithTimeseries } from 'yooi-store';
import i18n from '../../utils/i18n';
import { getConceptDefinitionNameOrEntity } from './modelTypeUtils';
import type { PathStepValidator } from './pathConfigurationHandler';
import { StepValidationState } from './pathConfigurationHandler';

export const getFieldTypeValidator = (store: ObjectStoreReadOnly, typeIds: string[], errorMessage: string): PathStepValidator => (
  ({ pathStep: lastStep, isNPath }) => {
    const result = [];
    if (isNPath) {
      result.push({ state: StepValidationState.partiallyValid, reasonMessage: i18n`Input should be unique, use a mapping in your path.` });
    }
    if (isFieldStep(lastStep)) {
      const field = store.getObjectOrNull<FieldStoreObject>(lastStep.fieldId);
      if (field) {
        const fieldDefinitionId = field[Instance_Of];
        const isRelationField = isRelationalType(fieldDefinitionId);
        if (typeIds.includes(fieldDefinitionId)) {
          result.push({ state: StepValidationState.valid });
        } else if (isRelationField) {
          result.push({ state: StepValidationState.partiallyValid, reasonMessage: errorMessage });
        } else {
          result.push({ state: StepValidationState.invalid, reasonMessage: errorMessage });
        }
      } else {
        result.push({ state: StepValidationState.invalid, reasonMessage: i18n`Field is not valid.` });
      }
    } else {
      result.push({ state: StepValidationState.partiallyValid, reasonMessage: errorMessage });
    }
    return result;
  }
);

export const getConceptTypeValidator = (store: ObjectStoreWithTimeseries, acceptMultiplePath: boolean, targetTypeId: string | undefined): PathStepValidator => (
  ({ pathStep, isLastStep, isNPath, path }) => {
    const result = [];
    const returnedConceptDefinitionId = getPathReturnedConceptDefinitionId(store, path);
    // Swimlane must be on unique combination of instances (no n-n path)
    if (isLastStep) {
      // last step must be multiple
      if (!acceptMultiplePath && isNPath) {
        result.push({ state: StepValidationState.partiallyValid, reasonMessage: i18n`Path requires a single value.` });
      } else if (!returnedConceptDefinitionId) {
        result.push({ state: StepValidationState.partiallyValid, reasonMessage: i18n`Path should return a concept` });
      } else if (targetTypeId && targetTypeId !== returnedConceptDefinitionId) {
        result.push({
          state: StepValidationState.partiallyValid,
          reasonMessage: i18n`Path should return an instance of concept "${getConceptDefinitionNameOrEntity(store, targetTypeId)}"`,
        });
      }
    } else if (isDimensionStep(pathStep) || isMappingStep(pathStep) || isFilterStep(pathStep)) {
      result.push({ state: StepValidationState.valid });
    } else if (isGlobalDimensionStep(pathStep)) {
      result.push({ state: StepValidationState.partiallyValid, reasonMessage: i18n`Path cannot end with global, missing relational field.` });
    } else if (isFieldStep(pathStep)) {
      const field = store.getObjectOrNull(pathStep.fieldId);
      if (!field) {
        result.push({ state: StepValidationState.invalid, reasonMessage: i18n`Field does not exist anymore.` });
      } else if (!acceptMultiplePath && isMultiplePath(store, path.slice(0, -1))) {
        result.push({ state: StepValidationState.invalid, reasonMessage: i18n`Cannot select fields on multiple dimension paths.` });
      } else {
        result.push({ state: StepValidationState.valid });
      }
    } else {
      result.push({ state: StepValidationState.invalid, reasonMessage: i18n`Invalid path.` });
    }
    return result;
  }
);
