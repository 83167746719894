import type { BusinessRuleHandler, ObjectStoreReadOnly } from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import { CommonAsType } from '../../common/fields/commonPropertyType';
import { adminOnlyAcl } from '../../conceptModule';
import { ConceptDefinition, WorkflowField } from '../../conceptModule/ids';
import { Block, Block_Name, Block_Parent, Block_Type, BlockType, BlockType_Column, BlockType_Name, BlockType_Section, BlockType_Tab } from '../ids';
import { registerModel } from '../module';

const { type } = registerModel;

const blockMergeCheck = ({ getObjectOrNull }: ObjectStoreReadOnly): BusinessRuleHandler => (_, { id, properties }) => {
  if (!properties) {
    return undefined;
  }
  const object = getObjectOrNull(id);
  const parent = properties?.[Block_Parent] ? getObjectOrNull(properties[Block_Parent] as string) : undefined;
  const parentBlockType = parent?.[Block_Type] as string | undefined;
  const objectBlockType = (properties?.[Block_Type] ?? object?.[Block_Type]) as string | undefined;
  if ((parentBlockType !== BlockType_Tab && parentBlockType !== BlockType_Section) && objectBlockType === BlockType_Column) {
    return {
      rule: 'block.blockParent.columnAsParentForbidden',
      status: ValidationStatus.REJECTED,
    };
  } else if (parentBlockType !== undefined && objectBlockType === BlockType_Tab) {
    return {
      rule: 'block.blockParent.tabForbiddenParent',
      status: ValidationStatus.REJECTED,
    };
  } else if (parentBlockType !== BlockType_Tab && objectBlockType === BlockType_Section) {
    return {
      rule: 'block.blockParent.sectionForbiddenParent',
      status: ValidationStatus.REJECTED,
    };
  }

  return {
    rule: 'block.blockParent.valid',
    status: ValidationStatus.ACCEPTED,
  };
};

type({
  label: 'BlockType',
  objectDebugLabel: ({ getObjectOrNull }) => (objectId) => getObjectOrNull(objectId)?.[BlockType_Name] as string,
  accessControlList: {
    READ: () => () => ({ rule: 'blockType.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'blockType', 'WRITE'),
    DELETE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'blockType', 'DELETE'),
  },
})
  .property({ label: 'BlockType_Name', as: CommonAsType.string })
  .instance({ label: 'BlockType_Tab', extraProperties: { [BlockType_Name]: 'Tab' } })
  .instance({ label: 'BlockType_Section', extraProperties: { [BlockType_Name]: 'Section' } })
  .instance({ label: 'BlockType_Column', extraProperties: { [BlockType_Name]: 'Column divider' } });

type({
  label: 'Block',
  accessControlList: {
    READ: () => () => ({ rule: 'block.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'block', 'WRITE'),
    DELETE: () => (_, objectId) => ({ rule: 'block.delete.delegate', status: ValidationStatus.DELEGATED, targetId: objectId, targetAction: 'WRITE' }),
  },
  objectDebugLabel: ({ getObjectOrNull }) => (objectId) => {
    const block = getObjectOrNull(objectId);
    const blockType = block?.navigateOrNull(Block_Type);
    if (block && blockType) {
      return blockType.id === BlockType_Column ? `${blockType[BlockType_Name]}` : `${blockType[BlockType_Name]} - '${block[Block_Name]}'`;
    } else {
      return undefined;
    }
  },
})
  .property({ label: 'Block_Name', as: CommonAsType.string })
  .property({ label: 'Block_Rank', as: CommonAsType.string })
  .property({ label: 'Block_Documentation', as: CommonAsType.string })
  .property({ label: 'Block_IsDocumentationInline', as: CommonAsType.boolean })
  .relation({ label: 'Block_ConceptDefinition', targetTypeId: ConceptDefinition, reverseLabel: 'ConceptDefinition_Blocks' })
  .relation({ label: 'Block_Parent', targetTypeId: Block, reverseLabel: 'BlockParent_Block', businessRules: [blockMergeCheck] })
  .relation({ label: 'Block_Type', targetTypeId: BlockType, reverseLabel: 'BlockType_Blocks' })
  .relation({ label: 'Block_WorkflowField', targetTypeId: WorkflowField, reverseLabel: 'WorkflowField_Blocks' })
  .property({ label: 'Block_ViewDisplayCondition', as: CommonAsType.Filters })
  .property({ label: 'Block_EditDisplayCondition', as: CommonAsType.Filters });
