import type { ObjectStore } from 'yooi-store';
import type { ExcelValueType } from '../../common/fields/FieldModuleDslType';
import { TextConstantField } from '../../conceptLayoutModule/ids';
import { Instance_Of } from '../../typeModule/ids';
import {
  AssociationField,
  EmbeddingField,
  ExternalKeyField,
  IdField,
  KinshipRelationField,
  RelationMultipleField,
  RelationSingleField,
  ReverseWorkflowField,
  StakeholdersField,
  TextField,
  WorkflowField,
} from '../ids';
import type { FieldStoreObject } from '../model';
import type { PathStep } from '../moduleType';
import { FILTER_PARAMETER_CURRENT } from './filters/filterUtils';
import type { MultipleParameterDefinition, SingleParameterDefinition } from './parametersUtils';
import { getPathLastFieldInformation, isDimensionStep, isPathValid } from './path/pathUtils';

const singleRelationalFields = [KinshipRelationField, RelationSingleField, WorkflowField];
const multipleRelationalFields = [AssociationField, RelationMultipleField, EmbeddingField, StakeholdersField, ReverseWorkflowField];
export const isSingleRelationalType = (fieldDefinitionId: string): boolean => singleRelationalFields.includes(fieldDefinitionId);
export const isMultipleRelationalType = (fieldDefinitionId: string): boolean => multipleRelationalFields.includes(fieldDefinitionId);
export const isRelationalType = (fieldDefinitionId: string): boolean => isSingleRelationalType(fieldDefinitionId) || multipleRelationalFields.includes(fieldDefinitionId);

interface UuidSingleRelationFieldExportConfiguration {
  type: 'uuid',
}

interface PathSingleRelationFieldExportConfiguration {
  type: 'path',
  path?: PathStep[],
}

export type SingleRelationFieldExportConfiguration = UuidSingleRelationFieldExportConfiguration | PathSingleRelationFieldExportConfiguration;

interface UuidMultipleRelationFieldExportConfiguration {
  type: 'uuid',
  separator: string,
}

interface PathMultipleRelationFieldExportConfiguration {
  type: 'path',
  path?: PathStep[],
  separator: string,
}

export type MultipleRelationFieldExportConfiguration = UuidMultipleRelationFieldExportConfiguration | PathMultipleRelationFieldExportConfiguration;

export const isRelationFieldPathConfigurationValid = (
  objectStore: ObjectStore,
  configuration: PathMultipleRelationFieldExportConfiguration | PathSingleRelationFieldExportConfiguration,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[]
): boolean => {
  if (!configuration.path) {
    return true;
  }
  const { fieldId } = getPathLastFieldInformation(configuration.path) ?? {};
  const field = fieldId ? objectStore.getObjectOrNull<FieldStoreObject>(fieldId) : undefined;
  if (!field || ![TextField, TextConstantField, IdField, ExternalKeyField].includes(field[Instance_Of])) {
    return false;
  }
  const firstStep = configuration.path.at(0);
  const conceptDefinitionId = isDimensionStep(firstStep) ? firstStep.conceptDefinitionId : undefined;
  return conceptDefinitionId !== undefined && isPathValid(objectStore, configuration.path, [...parameterDefinitions, {
    id: FILTER_PARAMETER_CURRENT,
    type: 'parameter',
    typeId: conceptDefinitionId,
    label: '',
  }]);
};

export const getSingleRelationFieldExportColumnHeaders = (objectStore: ObjectStore) => (
  configuration: SingleRelationFieldExportConfiguration | undefined,
  fieldLabel: string,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[]
): { columnsNumber: number, getHeaders: () => ExcelValueType[], getColumnConfiguration: (index: number) => SingleRelationFieldExportConfiguration | undefined } | {
  error: string,
} => {
  if (configuration && configuration.type === 'path' && configuration.path
    && !isRelationFieldPathConfigurationValid(objectStore, configuration, parameterDefinitions)
  ) {
    return {
      error: 'Export configuration is not valid',
    };
  }
  return {
    columnsNumber: 1,
    getHeaders: () => [{ format: 'string', value: fieldLabel }],
    getColumnConfiguration: () => configuration,
  };
};
export const getMultipleRelationFieldExportColumnHeaders = (objectStore: ObjectStore) => (
  configuration: MultipleRelationFieldExportConfiguration | undefined,
  fieldLabel: string,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[]
): { columnsNumber: number, getHeaders: () => ExcelValueType[], getColumnConfiguration: (index: number) => MultipleRelationFieldExportConfiguration | undefined } | {
  error: string,
} => {
  if (configuration && configuration.type === 'path' && configuration.path
    && !isRelationFieldPathConfigurationValid(objectStore, configuration, parameterDefinitions)
  ) {
    return {
      error: 'Export configuration is not valid',
    };
  }
  return {
    columnsNumber: 1,
    getHeaders: () => [{ format: 'string', value: fieldLabel }],
    getColumnConfiguration: () => configuration,
  };
};
