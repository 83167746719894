import type { FunctionComponent, ReactNode } from 'react';
import { Spacing, spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import type { NavigationElements } from '../molecules/NavigationTitle';
import NavigationTitle from '../molecules/NavigationTitle';

const useStyles = makeStyles((theme) => ({
  topBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: spacingRem.xl,
    backgroundColor: theme.color.background.neutral.default,
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: spacingRem.splus,
  },
}), 'topBarTemplate');

interface TopBarTemplateProps {
  navigationElements: NavigationElements,
  actions?: ReactNode,
  onShare?: () => void,
}

const TopBarTemplate: FunctionComponent<TopBarTemplateProps> = ({ navigationElements, actions, onShare }) => {
  const classes = useStyles();

  return (
    <div className={classes.topBar}>
      <NavigationTitle navigationElements={navigationElements} padding={{ left: Spacing.splus }} onShare={onShare} />
      <div className={classes.actionsContainer}>
        {actions}
      </div>
    </div>
  );
};

export default TopBarTemplate;
