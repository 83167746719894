import { v4 as uuid } from 'uuid';
import { API_LABEL_ACCEPTED_CHARS_REGEX } from 'yooi-modules';
import { fieldDocumentationDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import { getConceptDefinitionValidFields } from 'yooi-modules/modules/conceptModule';
import {
  Field_ApiAlias,
  Field_Documentation,
  Field_IntegrationOnly,
  Field_IsDocumentationInline,
  Field_Title,
  FieldDimension_Field,
} from 'yooi-modules/modules/conceptModule/ids';
import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { filterNullOrUndefined, joinObjects, toSnakeCase } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import i18n from '../../../../utils/i18n';
import FieldDimensionsSearchAndSelect from '../FieldDimensionsSearchAndSelect';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import { FIELD_EDITION_DIMENSIONS } from '../fieldDimensionUtils';
import type { FieldEditionOption, FieldEditionSection } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import type { EditionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldIntegrationOnlyDisabled } from '../FieldLibraryTypes';

export const getDocumentationFieldEditionSection = (
  editionHandler: EditionHandler<{
    [Field_Documentation]?: string | null | undefined,
    [Field_IsDocumentationInline]?: boolean | null | undefined,
  }>
): FieldEditionSection => ({
  key: 'documentation',
  type: 'section',
  title: i18n`Documentation`,
  options: [
    {
      key: Field_Documentation,
      title: i18n`Content`,
      info: i18n`This documentation will be displayed based on choice below`,
      hasValue: () => editionHandler.getValue(Field_Documentation) !== undefined,
      clearValue: () => editionHandler.updateValues({ [Field_Documentation]: null }),
      type: EditionOptionTypes.text,
      props: {
        placeholder: i18n`Add documentation`,
        value: editionHandler.getValue(Field_Documentation),
        maxLine: 5,
        onChange: (value) => editionHandler.updateValues({ [Field_Documentation]: value }),
      },
    },
    {
      key: Field_IsDocumentationInline,
      title: i18n`Show on`,
      info: i18n`This documentation will be displayed either on a line above the field or on hover of the field`,
      hasValue: () => editionHandler.getValue(Field_IsDocumentationInline) !== undefined,
      clearValue: () => editionHandler.updateValues({ [Field_IsDocumentationInline]: null }),
      type: EditionOptionTypes.select,
      props: {
        selectedOption: fieldDocumentationDisplayOptions.find(({ id }) => id === (editionHandler.getValueOrDefault(Field_IsDocumentationInline) ?? false)),
        computeOptions: () => fieldDocumentationDisplayOptions,
        onChange: (value) => editionHandler.updateValues({ [Field_IsDocumentationInline]: (value?.id as boolean | undefined) ?? null }),
      },
    },
  ],
});

const getIntegrationOnlyFieldEditionOption = (
  editionHandler: EditionHandler<{
    [Field_IntegrationOnly]?: boolean | null | undefined,
    [FieldIntegrationOnlyDisabled]?: boolean | undefined,
  }>
): FieldEditionOption => (
  {
    key: Field_IntegrationOnly,
    title: i18n`Only updated by integration`,
    info: i18n`This field will be presented as read only to the user and can only be updated by an integration.`,
    hasValue: () => editionHandler.getValue(Field_IntegrationOnly) !== undefined,
    clearValue: () => editionHandler.updateValues({ [Field_IntegrationOnly]: null }),
    type: EditionOptionTypes.checkbox,
    padded: true,
    props: {
      checked: editionHandler.getValueOrDefault(Field_IntegrationOnly) ?? false,
      disabled: editionHandler.getValue(FieldIntegrationOnlyDisabled),
      onChange: (checked) => editionHandler.updateValues({ [Field_IntegrationOnly]: checked }),
    },
  }
);

export const getApiAliasInitialState = (store: ObjectStoreWithTimeseries, fieldId: string): { [Field_Title]: string | undefined, [Field_ApiAlias]: string | undefined } => {
  const field = store.getObject(fieldId);
  return {
    [Field_Title]: field[Field_Title] as string | undefined,
    [Field_ApiAlias]: field[Field_ApiAlias] as string | undefined,
  };
};

export const getApiAliasEditionOption = (
  store: ObjectStoreWithTimeseries,
  editionHandler: EditionHandler<{
    [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
    [Field_Title]?: string | null | undefined,
    [Field_ApiAlias]?: string | null | undefined,
  }>
): FieldEditionOption => {
  const suggestedApiAlias = toSnakeCase(editionHandler.getValue(Field_Title) ?? '');

  const fieldEditionDimensions = editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {};
  const existingFieldId: string | undefined = Object.keys(fieldEditionDimensions)
    .reduce((fieldId, dimensionId) => {
      if (fieldId) {
        // Already got the field, no need to keep looking
        return fieldId;
      }

      return store.getObjectOrNull(dimensionId)?.navigateOrNull(FieldDimension_Field)?.id;
    }, undefined as string | undefined);

  const existingApiAliases = new Set<string>(
    Object.values(fieldEditionDimensions)
      .flatMap(({ typeId }) => getConceptDefinitionValidFields(store, typeId))
      .filter(({ id }) => id !== existingFieldId)
      .map(({ [Field_ApiAlias]: apiAlias }) => apiAlias)
      .filter(filterNullOrUndefined)
  );

  const apiAlias = editionHandler.getValue(Field_ApiAlias) ?? '';

  return ({
    key: Field_ApiAlias,
    title: i18n`API alias`,
    hasValue: () => editionHandler.getValue(Field_ApiAlias) !== undefined,
    clearValue: () => editionHandler.updateValues({ [Field_ApiAlias]: null }),
    type: EditionOptionTypes.text,
    props: {
      placeholder: i18n`Add API alias`,
      value: editionHandler.getValue(Field_ApiAlias),
      onChange: (newApiAlias) => editionHandler.updateValues({ [Field_ApiAlias]: newApiAlias }),
      acceptChars: API_LABEL_ACCEPTED_CHARS_REGEX,
      acceptCharsErrorMessage: i18n`API alias should only contain letters, numbers, '-' or '_'`,
      action: suggestedApiAlias !== apiAlias
        ? {
          icon: IconName.sync,
          tooltip: i18n`Reset to default`,
          onClick: () => {
            editionHandler.updateValues({ [Field_ApiAlias]: suggestedApiAlias });
          },
        }
        : undefined,
      error: (
        apiAlias && existingApiAliases.has(apiAlias) ? i18n`Another field already use this API alias. Fields won't be available in the API until the conflict is resolved.` : undefined
      ),
    },
  });
};

export const getIntegrationFieldEditionSection = (
  store: ObjectStoreWithTimeseries,
  editionHandler: EditionHandler<{
    [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
    [Field_Title]?: string | null | undefined,
    [Field_ApiAlias]?: string | null | undefined,
    [Field_IntegrationOnly]?: boolean | null | undefined,
    [FieldIntegrationOnlyDisabled]?: boolean | undefined,
  }>,
  mode: FieldEditionOptionMode
): FieldEditionSection => {
  const integrationOptions: FieldEditionOption[] = [];
  if (mode === FieldEditionOptionMode.FieldDeveloperMode) {
    integrationOptions.push(getApiAliasEditionOption(store, editionHandler));
  }
  integrationOptions.push(getIntegrationOnlyFieldEditionOption(editionHandler));

  return {
    key: 'integration',
    type: 'section',
    title: i18n`Integration`,
    options: integrationOptions,
  };
};

export const getDimensionsEditionOption = (
  editionHandler: EditionHandler<{ [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined }>,
  isComputed: boolean,
  readOnly: boolean
): FieldEditionOption => {
  const fieldEditionDimensions: FieldEditionDimensions = editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {};

  return {
    key: FIELD_EDITION_DIMENSIONS,
    title: i18n`Dimensions`,
    hasValue: () => false,
    clearValue: () => {},
    type: EditionOptionTypes.custom,
    props: {
      render: () => (
        <FieldDimensionsSearchAndSelect
          readOnly={readOnly}
          dimensions={
            Object.entries(fieldEditionDimensions)
              .map(([dimensionId, dimensionDefinition]) => (joinObjects(dimensionDefinition, { id: dimensionId })))
          }
          isComputed={isComputed}
          onAdd={(typeId) => {
            editionHandler.updateValues({
              [FIELD_EDITION_DIMENSIONS]: joinObjects(fieldEditionDimensions, { [uuid()]: { typeId, label: undefined, mandatory: false, readOnly: false } }),
            });
          }}
          onChange={(id, label, mandatory) => {
            const prev = editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {};
            editionHandler.updateValues({
              [FIELD_EDITION_DIMENSIONS]: Object.fromEntries(
                Object.entries(prev)
                  .map(([fieldDimensionId, dimension]) => {
                    if (fieldDimensionId === id) {
                      return [fieldDimensionId, joinObjects(dimension, { label, mandatory })];
                    } else {
                      return [fieldDimensionId, dimension];
                    }
                  })
              ),
            });
          }}
          onDelete={(fieldDimensionIdToDelete) => {
            const prev = editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {};
            editionHandler.updateValues({
              [FIELD_EDITION_DIMENSIONS]: Object.fromEntries(Object.entries(prev).filter(([fieldDimensionId]) => fieldDimensionId !== fieldDimensionIdToDelete)),
            });
          }}
        />
      ),
    },
  };
};
