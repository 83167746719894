import type { ReactNode } from 'react';
import type { ExtractDslHandlerTypes, GenericGetDslFieldHandler } from 'yooi-modules/modules/common/fields/FieldModuleDslType';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Banner, { BannerVariant } from '../../../../components/molecules/Banner';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import BlockContent from '../../../../components/templates/BlockContent';
import BlockTitle from '../../../../components/templates/BlockTitle';
import HorizontalBlock, { HorizontalBlockVariant } from '../../../../components/templates/HorizontalBlock';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: spacingRem.xl,
    display: 'flex',
    flexFlow: 'row wrap',
    borderLeftWidth: '0.2rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
    alignItems: 'center',
  },
  bannerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacingRem.s,
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
  },
}), 'updateOperationSelector');

interface UpdateOperationSelectorProps<DslFieldHandler extends GenericGetDslFieldHandler> {
  selectedOperationAction: ExtractDslHandlerTypes<DslFieldHandler>['UpdateOperationsActionKeys'] | undefined,
  operationOptions: { id: ExtractDslHandlerTypes<DslFieldHandler>['UpdateOperationsActionKeys'] & string, label: string, onSelect: () => void }[],
  warningMessage?: string,
  info?: string,
}

// = never is used to force to specify the generics on usage as ts fail to infer it correctly
const UpdateOperationSelector = <H extends GenericGetDslFieldHandler = never>({
  selectedOperationAction,
  operationOptions,
  info,
  warningMessage,
}: UpdateOperationSelectorProps<H>): ReactNode => {
  const classes = useStyles();

  return (
    <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
      <BlockTitle title={i18n`Operation`} />
      <BlockContent fullWidth>
        <div className={classes.container}>
          <SearchAndSelect
            placeholder={i18n`Operation`}
            selectedOption={operationOptions.find((option) => option.id === selectedOperationAction)}
            computeOptions={() => operationOptions}
            onSelect={(option) => {
              if (option) {
                option.onSelect();
              }
            }}
          />
          {warningMessage && (
            <div className={classes.bannerContainer}>
              <Banner variant={BannerVariant.warning} title={warningMessage} fullWidth />
            </div>
          )}
          {info && (
            <div className={classes.bannerContainer}>
              <Icon name={IconName.info} tooltip={info} colorVariant={IconColorVariant.info} />
            </div>
          )}
        </div>
      </BlockContent>
    </HorizontalBlock>
  );
};

export default UpdateOperationSelector;
