import composeReactRefs from '@seznam/compose-react-refs';
import type { FunctionComponent } from 'react';
import { useRef } from 'react';
import { computeLightShade } from '../../../app/utils/standardColorsUtils';
import base from '../../../theme/base';
import makeStyles from '../../../utils/makeStyles';
import useTooltipRef from '../../../utils/useTooltipRef';
import type { IconName } from '../../atoms/Icon';
import Icon, { IconSizeVariant } from '../../atoms/Icon';

const useStyles = makeStyles((theme) => ({
  button: {
    flexShrink: 0, // Prevent icon button from shirking when in a flex container
    flexGrow: 0, // Prevent icon button from growing when in a flex container
    position: 'relative',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
    borderRadius: base.borderRadius.medium,
    boxShadow: 'none',
    borderWidth: 0,
    cursor: 'pointer',
    height: '2.6rem',
    width: '2.6rem',
    '&:disabled': {
      cursor: 'auto',
      opacity: base.opacity.twenty,
    },
    '&:hover': {
      backgroundColor: theme.color.background.primarylight.default,
    },
    '&:active': {
      backgroundColor: theme.color.background.primarylight.pressed,
      boxShadow: base.button.shadow.pressed,
    },
    '&:focus-visible': {
      position: 'relative',
      outlineWidth: '0.2rem',
      outlineStyle: 'solid',
      outlineColor: theme.color.border.focus,
    },
    backgroundColor: theme.color.transparent,
    color: theme.color.text.brand,
  },
}), 'iconPickerOption');

interface IconPickerOptionProps {
  icon: IconName,
  color?: string,
  tooltip: string,
  onClick: () => void,
  disabled?: boolean,
  active?: boolean,
}

const IconPickerOption: FunctionComponent<IconPickerOptionProps> = ({ icon, color, tooltip, onClick, disabled = false, active = false }) => {
  const classes = useStyles();

  const lightShade = color === undefined ? undefined : computeLightShade(color);

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const tooltipRef = useTooltipRef(tooltip);

  return (
    <button
      ref={composeReactRefs<HTMLButtonElement>(tooltipRef, buttonRef)}
      type="button"
      aria-label={tooltip}
      className={classes.button}
      style={active && lightShade !== undefined ? { backgroundColor: lightShade } : undefined}
      onClick={onClick}
      disabled={disabled}
      onMouseEnter={
        lightShade === undefined
          ? undefined
          : () => {
            if (buttonRef.current !== null && !active) {
              buttonRef.current.style.backgroundColor = lightShade;
            }
          }
      }
      onMouseLeave={
        !active && lightShade === undefined
          ? undefined
          : () => {
            if (buttonRef.current !== null && !active) {
              buttonRef.current.style.backgroundColor = '';
            }
          }
      }
    >
      <Icon name={icon} color={color} size={IconSizeVariant.l} />
    </button>
  );
};

export default IconPickerOption;
