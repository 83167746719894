import { equals } from 'ramda';
import { useRef } from 'react';
import type { Comparator, ComparatorChain, KeysMatching } from 'yooi-utils';
import { buildComparatorChain, compareBoolean, compareNumber, compareString, comparing, createAutoProvisioningMap, extractAndCompareValue, pushUndefinedToEnd } from 'yooi-utils';
import { TableSortDirection } from '../../components/molecules/Table';
import type { Pagination } from '../../components/templates/PageSelector';
import useStore from '../../store/useStore';
import { Opacity } from '../../theme/base';
import { generateColorFromOpacity } from '../../theme/colorUtils';
import i18n from '../../utils/i18n';
import useForceUpdate from '../../utils/useForceUpdate';
import { SessionStorageKeys, useSessionStorageState } from '../../utils/useSessionStorage';
import useTheme from '../../utils/useTheme';
import type { ExecuteAsyncTask } from '../../utils/withAsyncTask';
import { registerComputeFunction } from '../../utils/withAsyncTask';
import { useOnFilterChanged } from './filter/useFilterSessionStorage';
import type { SortConfiguration } from './sessionStorageTypes';

const NOT_SET = Symbol('NOT_SET');

interface ListItem<Item> {
  item: Item,
  groupKey?: string,
  forced?: boolean,
}

interface Page {
  lines: number,
  items: number,
  forcedItems: number,
  firstIndex: number,
  pageIndex: number,
  lastGroupKey: typeof NOT_SET | string | undefined,
}

export interface Data {
  key: string,
}

export interface ComparatorHandler<Item extends Data, Type> {
  comparator: Comparator<Type>,
  extractValue: (item: Item) => Type,
}

export const buildStringColumnComparatorHandler = <Item extends Data>(
  key: KeysMatching<Item, string | undefined>,
  direction: TableSortDirection
): ComparatorHandler<Item, string | undefined> => ({
  comparator: comparing(compareString, direction === TableSortDirection.desc),
  extractValue: (item) => item[key] as string | undefined,
});

export const buildNumberColumnComparatorHandler = <Item extends Data>(
  key: KeysMatching<Item, number | undefined>,
  direction: TableSortDirection
): ComparatorHandler<Item, number | undefined> => ({
  comparator: comparing(compareNumber, direction === TableSortDirection.desc),
  extractValue: (item) => item[key] as number | undefined,
});

export const buildBooleanColumnComparatorHandler = <Item extends Data>(
  key: KeysMatching<Item, boolean | undefined>,
  direction: TableSortDirection
): ComparatorHandler<Item, boolean | undefined> => ({
  comparator: comparing(compareBoolean, direction === TableSortDirection.desc),
  extractValue: (item) => item[key] as boolean | undefined,
});

interface ItemEntry<Item extends Data> {
  key: string,
  type: 'item',
  item: Item,
  color: string | undefined,
}

interface GroupEntry {
  key: string,
  type: 'group',
  label: string | undefined,
  color: string | undefined,
}

interface Comparable {
  key: string,
  groupKey: string | undefined,
  groupValue: unknown,
  sortValue: unknown,
}

export const getListHandlers = <Item extends Data>(
  sort: Parameters<typeof useFilterAndSort<Item>>[3],
  groupByKey: string | undefined,
  sortCriteria: { key: string, direction: TableSortDirection } | undefined
): { comparator: ComparatorChain<Comparable>, groupByExtractor: ((item: Item) => unknown) | undefined, sortExtractor: ((item: Item) => unknown) | undefined } => {
  const comparators: Comparator<Comparable>[] = [];

  const groupByComparatorHandler: ComparatorHandler<Item, unknown> | undefined = sort !== undefined && groupByKey !== undefined
    ? sort.getComparatorHandler(groupByKey, sortCriteria?.key === groupByKey ? sortCriteria.direction : TableSortDirection.asc) : undefined;

  if (groupByKey) {
    comparators.push(extractAndCompareValue((v) => v.groupKey, pushUndefinedToEnd));
    if (groupByComparatorHandler) {
      comparators.push(extractAndCompareValue((v) => v.groupValue, pushUndefinedToEnd));
      comparators.push(extractAndCompareValue((v) => v.groupValue, groupByComparatorHandler.comparator));
    }
    comparators.push(extractAndCompareValue((v) => v.groupKey, compareString));
  }

  const sortComparatorHandler = sortCriteria !== undefined && sort !== undefined
    ? sort.getComparatorHandler(sortCriteria.key, sortCriteria.direction) : undefined;

  if (sortComparatorHandler !== undefined) {
    comparators.push(extractAndCompareValue((v) => v.sortValue, pushUndefinedToEnd));
    comparators.push(extractAndCompareValue((v) => v.sortValue, sortComparatorHandler.comparator));
  }

  return {
    comparator: buildComparatorChain(comparators),
    groupByExtractor: groupByComparatorHandler?.extractValue,
    sortExtractor: sortComparatorHandler?.extractValue,
  };
};

const doAsyncFilterAndSort = registerComputeFunction(
  async <Item extends Data>(
    yieldProcessor: () => Promise<void>,
    list: Item[],
    filterFunction: ((item: Item) => boolean) | undefined,
    sort: Parameters<typeof useFilterAndSort<Item>>[3],
    groupBy: Parameters<typeof useFilterAndSort<Item>>[4],
    sortCriteria: { key: string, direction: TableSortDirection } | undefined
  ): Promise<{ key: string, groupKey: string | undefined, forced: boolean }[]> => {
    const { comparator, groupByExtractor, sortExtractor } = getListHandlers(sort, groupBy?.key, sortCriteria);

    await yieldProcessor();

    const mappedList: { key: string, groupKey: string | undefined, groupValue: unknown, sortValue: unknown }[] = [];
    for (let i = 0; i < list.length; i += 1) {
      const item = list[i];
      if (filterFunction === undefined || filterFunction(item)) {
        mappedList.push({
          key: item.key,
          groupKey: groupBy?.getGroupKey(item),
          groupValue: groupByExtractor?.(item),
          sortValue: sortExtractor?.(item),
        });
      }
      await yieldProcessor();
    }

    if (comparator !== undefined) {
      mappedList.sort(comparator);
      await yieldProcessor();
    }

    return mappedList.map(({ key, groupKey }) => ({ key, groupKey, forced: false }));
  },
  (previousParameters, newParameters) => {
    if (previousParameters[3]?.key !== newParameters[3]?.key) {
      return true;
    } else if (!equals(previousParameters[4], newParameters[4])) {
      return true;
    } else if (!equals(previousParameters[0], newParameters[0])) {
      return true;
    } else {
      return false;
    }
  }
) as ({
  // Using generic with type forwarding is tricky in typescript, we need to help it a little bit to get the final type
  <Item extends Data>(
    yieldProcessor: () => Promise<void>,
    list: Item[],
    filterFunction: ((item: Item) => boolean) | undefined,
    sort: Parameters<typeof useFilterAndSort<Item>>[3],
    groupBy: Parameters<typeof useFilterAndSort<Item>>[4],
    sortCriteria: { key: string, direction: TableSortDirection } | undefined
  ): Promise<{ key: string, groupKey: string | undefined, forced: boolean }[]>,
  id: string,
  hasParameterChanged: () => boolean,
});

const doFilterAndSort = <Item extends Data>(
  list: Item[],
  filterFunction: ((item: Item) => boolean) | undefined,
  sort: Parameters<typeof useFilterAndSort<Item>>[3],
  groupBy: Parameters<typeof useFilterAndSort<Item>>[4],
  sortCriteria: { key: string, direction: TableSortDirection } | undefined
): { key: string, groupKey: string | undefined, forced: boolean }[] => {
  const { comparator, groupByExtractor, sortExtractor } = getListHandlers(sort, groupBy?.key, sortCriteria);

  const mappedList = (filterFunction === undefined ? list : list.filter(filterFunction))
    .map((item) => ({
      key: item.key,
      groupKey: groupBy?.getGroupKey(item),
      groupValue: groupByExtractor?.(item),
      sortValue: sortExtractor?.(item),
    }));

  if (comparator !== undefined) {
    mappedList.sort(comparator);
  }

  return mappedList.map(({ key, groupKey }) => ({ key, groupKey, forced: false }));
};

const doListIdsToAdd = <Item extends Data>(list: Item[], forcedIdList: string[], keyListIndex: Map<string, number>, filterFunction: ((item: Item) => boolean) | undefined) => {
  const forcedKeysSet = new Set<string>(forcedIdList);
  const hiddenAndNewElements = list.filter(({ key }) => !keyListIndex.has(key) && !forcedKeysSet.has(key));
  return (filterFunction ? hiddenAndNewElements.filter(filterFunction) : hiddenAndNewElements).map(({ key }) => key);
};

const doAsyncListIdsToAdd = registerComputeFunction(
  async <Item extends Data>(
    yieldProcessor: () => Promise<void>,
    list: Item[],
    forcedIdList: string[],
    keyListIndex: Map<string, number>,
    filterFunction: ((item: Item) => boolean) | undefined,
    currentSerial: number // We pass the serial to make sure the method was executed with the last version of the store (and we don't miss update)
  ): Promise<{ idsToAdd: string[], currentSerial: number }> => {
    const idsToAdd: string[] = [];
    const forcedKeysSet = new Set<string>(forcedIdList);

    for (let i = 0; i < list.length; i += 1) {
      const item = list[i];
      if (!keyListIndex.has(item.key) && !forcedKeysSet.has(item.key)) {
        if (filterFunction === undefined || filterFunction(item)) {
          idsToAdd.push(item.key);
        }
      }
      await yieldProcessor();
    }

    return { idsToAdd, currentSerial };
  },
  (previousParameters, newParameters) => {
    if (!equals(previousParameters[0], newParameters[0])) {
      return true;
    } else if (!equals(previousParameters[1], newParameters[1])) {
      return true;
    } else if (previousParameters[4] !== newParameters[4]) {
      return true;
    } else {
      return false;
    }
  }
) as ({
  // Using generic with type forwarding is tricky in typescript, we need to help it a little bit to get the final type
  <Item extends Data>(
    yieldProcessor: () => Promise<void>,
    list: Item[],
    forcedIdList: string[],
    keyListIndex: Map<string, number>,
    filterFunction: ((item: Item) => boolean) | undefined,
    currentSerial: number
  ): Promise<{ idsToAdd: string[], currentSerial: number }>,
  id: string,
  hasParameterChanged: () => boolean,
});

export interface UseFilterAndSortReturnType<Item extends Data> {
  doSort: (key: string) => void,
  sortCriteria: { key: string, direction: TableSortDirection } | undefined,
  forceFollowingIds: (fromId: string, toId: string) => void,
  forceShowId: (forceId: string) => void,
  generateList: () => { list: ItemEntry<Item>[], status: 'loading' | 'loaded' },
  generatePageList: (itemPerPage: number) => { list: ItemEntry<Item>[], pagination: Pagination, status: 'loading' | 'loaded' },
  generateGroupedPageList: (itemPerPage: number, countGroupAsLine?: boolean) => { list: (ItemEntry<Item> | GroupEntry)[], pagination: Pagination, status: 'loading' | 'loaded' },
  generateGroupedList: () => { list: (ItemEntry<Item> | GroupEntry)[], status: 'loading' | 'loaded' },
}

const useFilterAndSort = <Item extends Data = Data>(
  filterKey: string,
  list: Item[],
  filterFunction: ((item: Item) => boolean) | undefined,
  sort: {
    // Use of any is safe hare as type coherency is handled inside the comparator handler (extractValue output to compare input)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getComparatorHandler: (key: string, direction: TableSortDirection) => (ComparatorHandler<Item, any> | undefined),
    initial: { key: string, direction: TableSortDirection } | undefined,
  } | undefined = undefined,
  groupBy: {
    key: string,
    getGroupKey: (item: Item) => (string | undefined),
    getGroupLabel: (key: string) => (string | undefined),
    getGroupColor: (key: string) => (string | undefined),
  } | undefined = undefined,
  deps: unknown[] = [],
  executeAsyncTask?: ExecuteAsyncTask
): UseFilterAndSortReturnType<Item> => {
  const theme = useTheme();

  const store = useStore();

  const forceUpdate = useForceUpdate();

  const currentSerial = store.getSerial();
  const lastSerialRef = useRef(currentSerial);

  const keyList = useRef<{ key: string, groupKey: string | undefined, forced: boolean }[]>([]);
  const forcedIdList = useRef<string[]>([]);
  const shouldSortAndFilter = useRef(true);
  const previousFilterId = useRef(filterKey);
  const page = useRef(0);
  const previousGroupByKey = useRef(groupBy?.key);
  const depsRef = useRef(deps);

  let isLoading = shouldSortAndFilter.current;

  const [sessionStorageSortCriteria, setSessionStorageSortCriteria] = useSessionStorageState<SortConfiguration | undefined>(`${SessionStorageKeys.sortConfig}_${filterKey}`, undefined);
  let sortCriteria: UseFilterAndSortReturnType<Item>['sortCriteria'] = sort?.initial;
  if (sessionStorageSortCriteria !== undefined && typeof sessionStorageSortCriteria.key === 'string') {
    sortCriteria = { key: sessionStorageSortCriteria.key, direction: sessionStorageSortCriteria.direction ?? TableSortDirection.asc };
  }

  const lastSortCriteriaRef = useRef(sortCriteria);

  useOnFilterChanged(filterKey, () => {
    shouldSortAndFilter.current = true;
    page.current = 0;
    forceUpdate();
  });

  if (
    filterKey !== previousFilterId.current
    || groupBy?.key !== previousGroupByKey.current
    || !equals(sortCriteria, lastSortCriteriaRef.current)
    || !equals(depsRef.current, deps)
  ) {
    shouldSortAndFilter.current = true;
    depsRef.current = deps;
    page.current = 0;
  }

  if (shouldSortAndFilter.current) {
    lastSerialRef.current = currentSerial;
    if (executeAsyncTask) {
      const { status, value } = executeAsyncTask(doAsyncFilterAndSort<Item>, [list, filterFunction, sort, groupBy, sortCriteria], deps);
      if (status === 'loaded') {
        keyList.current = value;
        forcedIdList.current = [];
        previousFilterId.current = filterKey;
        previousGroupByKey.current = groupBy?.key;
        lastSortCriteriaRef.current = sortCriteria;
        shouldSortAndFilter.current = false;
        isLoading = false;
      } else {
        isLoading = true;
      }
    } else {
      keyList.current = doFilterAndSort(list, filterFunction, sort, groupBy, sortCriteria);
      forcedIdList.current = [];
      previousFilterId.current = filterKey;
      previousGroupByKey.current = groupBy?.key;
      lastSortCriteriaRef.current = sortCriteria;
      shouldSortAndFilter.current = false;
      isLoading = false;
    }
  }

  const keyListIndex = new Map(keyList.current.map(({ key }, index) => [key, index]));

  // Only lookup for new instance if the serial changed (aka store was updated)
  if (lastSerialRef.current !== currentSerial) {
    if (executeAsyncTask) {
      const { status, value } = executeAsyncTask(doAsyncListIdsToAdd<Item>, [list, forcedIdList.current, keyListIndex, filterFunction, currentSerial], deps);
      isLoading = isLoading || status === 'loading';
      if (status === 'loaded') {
        if (value.idsToAdd.length > 0) {
          forcedIdList.current = [...forcedIdList.current, ...value.idsToAdd];
        }
        lastSerialRef.current = value.currentSerial;
      }
    } else {
      const idsToAdd = doListIdsToAdd(list, forcedIdList.current, keyListIndex, filterFunction);
      if (idsToAdd.length > 0) {
        forcedIdList.current = [...forcedIdList.current, ...idsToAdd];
      }
      lastSerialRef.current = currentSerial;
    }
  } else if (executeAsyncTask) {
    // Execute the function with an empty list to make sure we always have a cached value and avoid the global loader
    executeAsyncTask(doAsyncListIdsToAdd<Item>, [[], forcedIdList.current, keyListIndex, filterFunction, currentSerial], deps);
  }

  const generateList = (): { list: ListItem<Item>[], forcedList: ListItem<Item>[], allList: ListItem<Item>[] } => {
    const forcedIdsSet = new Set<string>(forcedIdList.current);

    const innerList: (ListItem<Item> & { index: number })[] = [];
    const forcedList: (ListItem<Item> & { index: number })[] = [];

    for (let i = 0; i < list.length; i += 1) {
      const item = list[i];
      if (forcedIdsSet.has(item.key)) {
        const itemIndex = forcedIdList.current.findIndex((key) => key === item.key);
        forcedList.push({ index: itemIndex, groupKey: undefined, item });
      } else if (keyListIndex.has(item.key)) {
        const itemIndex = keyListIndex.get(item.key) ?? -1;
        innerList.push({ item, index: itemIndex, groupKey: keyList.current[itemIndex]?.groupKey, forced: keyList.current[itemIndex]?.forced });
      }
    }
    innerList.sort((a, b) => (a.index < 0 || b.index < 0 ? 1 : a.index - b.index));
    forcedList.sort((a, b) => (a.index < 0 || b.index < 0 ? 1 : a.index - b.index));

    return {
      list: innerList,
      forcedList,
      allList: [...innerList, ...forcedList],
    };
  };

  const generatePageList = (itemPerPage: number, countGroupAsLine: boolean): {
    list: ListItem<Item>[],
    forcedList: ListItem<Item>[],
    allList: ListItem<Item>[],
    pagination: Pagination,
  } => {
    const { list: innerList, allList: innerAllList, forcedList } = generateList();

    const pages: Page[] = [];
    let lastPage: Page | undefined;
    for (let i = 0; i < innerAllList.length; i += 1) {
      const item = innerAllList[i];
      if (
        !lastPage || (!item.forced && (lastPage.lines === itemPerPage || (countGroupAsLine && lastPage.lines === (itemPerPage - 1) && lastPage.lastGroupKey !== item.groupKey)))
      ) {
        lastPage = { lines: 0, items: 0, forcedItems: 0, firstIndex: i, pageIndex: pages.length, lastGroupKey: NOT_SET };
        pages.push(lastPage);
      }

      if (countGroupAsLine && lastPage.lastGroupKey !== item.groupKey) {
        lastPage.lastGroupKey = item.groupKey;
        lastPage.lines += 1;
      }

      if (item.forced) {
        lastPage.forcedItems += 1;
      } else {
        lastPage.items += 1;
        lastPage.lines += 1;
      }
    }

    const totalPagesNumber = pages.length;

    const { items, forcedItems, firstIndex, pageIndex } = pages.at(page.current ?? 0) ?? pages.at(-1) ?? { items: 0, forcedItems: 0, firstIndex: 0, pageIndex: 0 };
    const pageList = innerList.slice(firstIndex, firstIndex + items + forcedItems);
    const allList = [...pageList, ...forcedList];

    const onPage = (pageNumber: number) => {
      if (Number.isInteger(pageNumber) && pageNumber <= totalPagesNumber && pageNumber >= 0) {
        page.current = pageNumber;
        shouldSortAndFilter.current = true;
        forceUpdate();
      }
    };

    return {
      list: pageList,
      forcedList,
      allList,
      pagination: {
        currentPage: pageIndex,
        totalPagesNumber,
        totalItems: innerAllList.length,
        itemsRange: {
          low: Math.min(innerAllList.length, firstIndex + 1),
          high: firstIndex + allList.length,
        },
        onNext: () => onPage(pageIndex + 1),
        onPrevious: () => onPage(pageIndex - 1),
        onPage,
      },
    };
  };

  const generateGroupedList = (listToGroup: ListItem<Item>[], forcedListToGroup: ListItem<Item>[]): (ItemEntry<Item> | GroupEntry)[] => {
    const filteredAndSortedList: (ItemEntry<Item> | GroupEntry)[] = [];
    if (groupBy?.key) {
      const groupMap = createAutoProvisioningMap<string, { label: string | undefined, color: string, itemColor: string }>();
      const resolveGroupEntry = (groupKey: string): { label: string | undefined, color: string, itemColor: string } => {
        const color = groupBy.getGroupColor(groupKey) ?? theme.color.text.brand;
        return ({
          label: groupBy.getGroupLabel(groupKey),
          color,
          itemColor: generateColorFromOpacity(color, theme.color.background.neutral.default, Opacity.five),
        });
      };

      for (let i = 0; i < listToGroup.length; i += 1) {
        const { groupKey } = listToGroup[i];
        if (i === 0 || groupKey !== listToGroup[i - 1].groupKey) {
          if (!groupKey) {
            filteredAndSortedList.push({
              key: 'GroupBy_NoGroupKey',
              type: 'group',
              label: i18n`Undefined`,
              color: theme.color.border.hover,
            } satisfies GroupEntry);
          } else {
            const { color, label } = groupMap.getOrCreate(groupKey, resolveGroupEntry);
            filteredAndSortedList.push({
              key: `GroupBy_${groupKey}_${page.current ?? 0}`,
              type: 'group',
              label,
              color,
            } satisfies GroupEntry);
          }
        }
        if (!groupKey) {
          filteredAndSortedList.push({
            key: listToGroup[i].item.key,
            type: 'item',
            item: listToGroup[i].item,
            color: generateColorFromOpacity(theme.color.border.hover, theme.color.background.neutral.default, Opacity.five),
          } satisfies ItemEntry<Item>);
        } else {
          filteredAndSortedList.push({
            key: listToGroup[i].item.key,
            type: 'item',
            item: listToGroup[i].item,
            color: groupMap.getOrCreate(groupKey, resolveGroupEntry).itemColor,
          } satisfies ItemEntry<Item>);
        }
      }
      for (let i = 0; i < forcedListToGroup.length; i += 1) {
        if (i === 0) {
          filteredAndSortedList.push({ key: 'GroupBy_New_element', type: 'group', label: i18n`New element`, color: theme.color.text.brand } satisfies GroupEntry);
        }
        filteredAndSortedList.push({
          key: forcedListToGroup[i].item.key,
          type: 'item',
          item: forcedListToGroup[i].item,
          color: generateColorFromOpacity(theme.color.text.brand, theme.color.background.neutral.default, Opacity.five),
        } satisfies ItemEntry<Item>);
      }
    } else {
      for (let i = 0; i < listToGroup.length; i += 1) {
        filteredAndSortedList.push({
          key: listToGroup[i].item.key,
          type: 'item',
          item: listToGroup[i].item,
          color: undefined,
        } satisfies ItemEntry<Item>);
      }
      for (let i = 0; i < forcedListToGroup.length; i += 1) {
        filteredAndSortedList.push({
          key: forcedListToGroup[i].item.key,
          type: 'item',
          item: forcedListToGroup[i].item,
          color: undefined,
        } satisfies ItemEntry<Item>);
      }
    }
    return filteredAndSortedList;
  };

  return {
    generateList: () => ({
      list: generateList().allList.map(({ item }) => ({ key: item.key, type: 'item', item, color: undefined })),
      status: isLoading ? 'loading' : 'loaded',
    }),
    generatePageList: (itemPerPage) => {
      const { allList: pageList, pagination } = generatePageList(itemPerPage, false);
      return {
        list: pageList.map(({ item }) => ({ key: item.key, type: 'item', item, color: undefined })),
        pagination,
        status: isLoading ? 'loading' : 'loaded',
      };
    },
    generateGroupedList: () => {
      const { list: innerList, forcedList } = generateList();
      return {
        list: generateGroupedList(innerList, forcedList),
        status: isLoading ? 'loading' : 'loaded',
      };
    },
    generateGroupedPageList: (itemPerPage, countGroupAsLine = false) => {
      const { list: innerList, forcedList, pagination } = generatePageList(itemPerPage, countGroupAsLine);
      return {
        list: generateGroupedList(innerList, forcedList),
        pagination,
        status: isLoading ? 'loading' : 'loaded',
      };
    },
    doSort: (key) => {
      setSessionStorageSortCriteria({
        key,
        direction: sortCriteria?.key !== key || sortCriteria.direction === TableSortDirection.desc ? TableSortDirection.asc : TableSortDirection.desc,
      });
    },
    sortCriteria,
    forceFollowingIds: (fromKey, toKey) => {
      const getSortedList = (oldSortedInstanceIdList: typeof keyList.current) => {
        const newSortedList = [...oldSortedInstanceIdList];
        const fromIdIndex = newSortedList.findIndex(({ key }) => key === fromKey);
        if (fromIdIndex >= 0) {
          if (newSortedList.findIndex(({ key }) => key === toKey) >= 0) {
            newSortedList.splice(newSortedList.findIndex(({ key }) => key === toKey), 1);
          }
          newSortedList.splice(fromIdIndex + 1, 0, { key: toKey, groupKey: newSortedList[fromIdIndex]?.groupKey, forced: true });
        }
        return newSortedList;
      };
      keyList.current = getSortedList(keyList.current);
      const getForcedSortedList = (oldSortedInstanceIdList: string[]) => {
        const newSortedList = [...oldSortedInstanceIdList];
        const fromIdIndex = newSortedList.findIndex((instanceId) => instanceId === fromKey);
        if (fromIdIndex >= 0) {
          if (newSortedList.findIndex((instanceId) => instanceId === toKey) >= 0) {
            newSortedList.splice(newSortedList.findIndex((instanceId) => instanceId === toKey), 1);
          }
          newSortedList.splice(fromIdIndex + 1, 0, toKey);
        }
        return newSortedList;
      };
      forcedIdList.current = getForcedSortedList(forcedIdList.current);
      forceUpdate();
    },
    forceShowId: (forceId) => {
      forcedIdList.current = [...forcedIdList.current, forceId];
    },
  };
};

export default useFilterAndSort;
