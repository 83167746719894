const regexString = '(https?:\\/\\/(?:www\\.)?[-A-zÀ-ú0-9@:%._\\+~#=]{1,256}\\.[A-zÀ-ú0-9()]{1,6}\\b(?:[-A-zÀ-ú0-9()@:%_\\+.~#?$&\\/=]*))';

const dashboardLinkRegex = /^(((https?:\/\/(?:www\.)?[-A-zÀ-ú0-9@:%._\\+~#=]{1,256}\.[A-zÀ-ú0-9()]{1,6}\b)|\/)(?:[-A-zÀ-ú0-9()@:%_\\+.~#?$&/=]*))$/;
const httpLinkRegex = new RegExp(regexString);
const onlyLinkRegex = new RegExp(`^${regexString}$`);

export const isUrl = (text: string): boolean => onlyLinkRegex.test(text);
export const isValidLink = (text: string): boolean => dashboardLinkRegex.test(text);

export const extractLinksFromText = (text: string): { text: string, isLink?: boolean }[] => text.split(httpLinkRegex)
  .map((s: string) => ({ text: s, ...(isUrl(s) ? { isLink: true } : {}) }));
