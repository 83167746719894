import type { FunctionComponent, ReactElement } from 'react';
import type { ConceptDefinitionStoreObject } from 'yooi-modules/modules/conceptModule';
import { ConceptDefinition_Icon } from 'yooi-modules/modules/conceptModule/ids';
import type { IconName } from '../../../components/atoms/Icon';
import StoreObjectNotFoundErrorBoundary from '../../../store/StoreObjectNotFoundErrorBoundary';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { NavigationElementContainer } from '../../../utils/useNavigation';
import { getConceptDefinitionNameOrEntity } from '../../_global/modelTypeUtils';
import { NavigationTitlePathElements } from '../../_global/navigationUtils';

interface AdminConceptDefinitionLayoutProps {
  conceptDefinitionId: string,
  children: ReactElement | null,
}

const AdminConceptDefinitionLayout: FunctionComponent<AdminConceptDefinitionLayoutProps> = ({ conceptDefinitionId, children }) => {
  const store = useStore();
  const conceptDefinition = store.getObject<ConceptDefinitionStoreObject>(conceptDefinitionId);

  return (
    <StoreObjectNotFoundErrorBoundary objectName={i18n`Concept`}>
      <NavigationElementContainer
        element={{
          key: `${conceptDefinitionId}_configuration`,
          name: getConceptDefinitionNameOrEntity(store, conceptDefinition.id),
          icon: conceptDefinition[ConceptDefinition_Icon] as IconName,
          element: NavigationTitlePathElements.organizationInstance,
          path: `/settings/organization/${conceptDefinitionId}`,
        }}
      >
        {children}
      </NavigationElementContainer>
    </StoreObjectNotFoundErrorBoundary>
  );
};

export default AdminConceptDefinitionLayout;
