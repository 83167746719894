export const isObject = (maybe: unknown): maybe is Record<string, unknown> => typeof maybe === 'object' && maybe !== null && !Array.isArray(maybe);

export const hasOwnProperty = <X extends object, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> => Object.prototype.hasOwnProperty.call(obj, prop);

export const hasPropertyOfType = <X extends object, Y extends PropertyKey, T>(
  obj: X,
  prop: Y,
  typeValidator: (value: unknown) => value is T,
  isOptional: boolean,
  onError?: (message: string, data?: Record<string, unknown>) => void
): obj is X & Record<Y, T> => {
  if (!hasOwnProperty(obj, prop)) {
    if (!isOptional) {
      onError?.('Missing property', { key: prop });
      return false;
    }
  } else if (!typeValidator(obj[prop])) {
    if (isOptional && obj[prop] === undefined) {
      return true;
    }
    onError?.('Invalid property type', { key: prop });
    return false;
  }
  return true;
};

type NonUndefined<T> = T extends undefined ? never : T;

export type PropertyMap<T> = {
  [K in keyof T]-?: object extends Pick<T, K> ? { validator: (value: unknown) => value is NonUndefined<T[K]>, isOptional: true } : { validator: (value: unknown) => value is T[K] }
};

export const isObjectOfType = <O extends object, X>(checks: PropertyMap<O>) => (obj: X, onError?: (message: string, data: Record<string, unknown>) => void): obj is X & O => (
  isObject(obj) && Object.entries(checks).every(([key, check]) => (
    // Object entries signature is not precise enough
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    hasPropertyOfType(obj, key, check.validator, check.isOptional, onError)
  ))
);

export const isRecordOfType = <T>(validator: (value: unknown) => value is T) => (maybe: unknown): maybe is Record<string, T> => (
  isObject(maybe) && Object.values(maybe).every((value) => validator(value))
);

export const isArrayOfType = <T>(validator: (value: unknown) => value is T) => (maybe: unknown): maybe is T[] => Array.isArray(maybe) && maybe.every((value) => validator(value));

export const withUndefined = <T>(validator: (value: unknown) => value is T) => (maybe: unknown): maybe is T | undefined => (maybe === undefined || validator(maybe));

export const isBoolean = (maybe: unknown): maybe is boolean => typeof maybe === 'boolean';

export const isString = (maybe: unknown): maybe is string => typeof maybe === 'string';

export const isNumber = (maybe: unknown): maybe is number => typeof maybe === 'number' && maybe - maybe === 0;

export const isFiniteNumber = (maybe: unknown): maybe is number | string => {
  if (typeof maybe === 'number') {
    return maybe - maybe === 0; // because typeof NaN === 'number', typeof Infinity === 'number'
  } else if (typeof maybe === 'string' && maybe.trim() !== '') {
    return Number.isFinite(+maybe);
  } else {
    return false;
  }
};

// from https://stackoverflow.com/questions/42123407/does-typescript-support-mutually-exclusive-types
type UnionKeys<T> = T extends T ? keyof T : never;
type StrictUnionHelper<T, TAll> = T extends unknown ? T & Partial<Record<Exclude<UnionKeys<TAll>, keyof T>, never>> : never;
export type StrictUnion<T> = StrictUnionHelper<T, T>;
