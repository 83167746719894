import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { MouseEventHandler, ReactElement, Ref } from 'react';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import { joinObjects } from 'yooi-utils';
import { getSpacing, Spacing, spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import useBackdropClick from '../../utils/useBackdropClick';
import useEscapeListenerRef from '../../utils/useEscapeListenerRef';
import type { NavigationPayload } from '../../utils/useNavigation';
import useTheme from '../../utils/useTheme';
import type { ButtonVariant } from '../atoms/Button';
import Button from '../atoms/Button';
import type { IconName } from '../atoms/Icon';
import type { MenuItem } from '../molecules/Menu';
import OverflowMenu from '../molecules/OverflowMenu';
import SpacingLine from '../molecules/SpacingLine';
import type { TableSortDirection } from '../molecules/Table';
import type { CellHighlight } from '../molecules/TableCell';
import BlockContent from './BlockContent';
import type { Data, GroupEntry, ItemEntry } from './internal/DataTableBodyRenderer';
import type { ColumnDefinition } from './internal/DataTableRenderer';
import DataTableRenderer from './internal/DataTableRenderer';
import type { Pagination } from './PageSelector';
import PageSelector from './PageSelector';
import VerticalBlock from './VerticalBlock';

export type { ColumnDefinition, ItemEntry, GroupEntry } from './internal/DataTableRenderer';

const useStyles = makeStyles((theme) => ({
  withMultiplayerRenderer: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
  withoutMultiplayerRenderer: {
    gridColumnStart: 2,
    gridColumnEnd: 3,
  },
  padded: {
    paddingLeft: spacingRem.s,
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
  },
  fullWidth: {
    gridColumnStart: 1,
    gridColumnEnd: 'last',
  },
  tableScrollContainer: {
    overflowX: 'scroll',
  },
  bottomContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: spacingRem.s,
  },
}), 'dataTable');

interface DataTableProps<Item extends Data> {
  list: (ItemEntry<Item> | GroupEntry)[],
  columnsDefinition: ColumnDefinition<Item>[],
  linesActionsHeaderRenderer?: () => ReactElement | null,
  linesActions?: (item: Item, index: number) => MenuItem[],
  globalActions?: { key: string, name: string, icon: IconName, onClick: () => void }[],
  newItemTitle?: string,
  newItemIcon?: IconName,
  newItemRef?: Ref<HTMLButtonElement>,
  newItemButtonVariant?: ButtonVariant,
  onNewItem?: MouseEventHandler<HTMLButtonElement>,
  newItemDisabled?: boolean,
  newLineFocus?: { id?: string, reset: () => void },
  newColumnFocus?: { id?: string, reset: () => void },
  doSort?: (propertyId: string) => void,
  sortCriteria?: { key: string, direction: TableSortDirection },
  multiplayerRenderer?: (item: Item, columnIds: string[]) => ReactElement,
  inlineCreation?: {
    render: ReactElement | null,
    highlight?: (propertyId: string) => CellHighlight | undefined,
  },
  handleClickAway?: (isEscape: boolean) => void,
  lineContext?: (item: Item) => string[],
  loading?: boolean,
  pagination?: Pagination,
  getNavigationPayload?: (item: Item) => NavigationPayload | undefined,
  onNavigate?: (item: Item) => void,
  fullWidth?: boolean,
  getHighlight?: (item: Item, propertyId: string) => CellHighlight | undefined,
  withoutHeaderLine?: boolean,
  separateHeaderFromBody?: boolean,
  centerHeader?: boolean,
  minColumnWidthRem?: number | undefined,
  extraButton?: ReactElement | null,
}

const DataTable = <Item extends Data>({
  list,
  columnsDefinition,
  linesActionsHeaderRenderer,
  linesActions,
  globalActions,
  newItemTitle,
  newItemIcon,
  newItemRef,
  newItemButtonVariant,
  onNewItem,
  newItemDisabled = false,
  newLineFocus,
  newColumnFocus,
  doSort,
  sortCriteria,
  getNavigationPayload,
  onNavigate,
  multiplayerRenderer,
  inlineCreation,
  handleClickAway,
  lineContext,
  loading = false,
  pagination,
  fullWidth = false,
  getHighlight,
  withoutHeaderLine,
  separateHeaderFromBody,
  centerHeader,
  minColumnWidthRem,
  extraButton,
}: DataTableProps<Item>): ReactElement | null => {
  const theme = useTheme();
  const classes = useStyles();

  const [containerWidth, setContainerWidth] = useState<number | undefined>();

  const containerRef = useRef<HTMLDivElement>(null);
  useBackdropClick(containerRef, () => handleClickAway?.(false));

  useLayoutEffect(() => {
    const computedStyle = containerRef.current ? getComputedStyle(containerRef.current) : undefined;
    const paddingLeft = computedStyle && computedStyle.paddingLeft !== '' ? parseFloat(computedStyle.paddingLeft) : 0;
    const paddingRight = computedStyle && computedStyle.paddingRight !== '' ? parseFloat(computedStyle.paddingRight) : 0;
    // initialized containerRef in a layout effect to avoid useResizeObserver flickering on first render
    setContainerWidth(containerRef.current ? containerRef.current.clientWidth - paddingLeft - paddingRight : undefined);
  }, []);

  const escapeListenerRef = useEscapeListenerRef<HTMLDivElement>(() => handleClickAway?.(true));

  const { ref } = useResizeObserver({
    onResize: (h) => {
      if (containerWidth !== h.width) { // we know it's bad but this avoid a re render as the state is set outside react cycle
        setContainerWidth(h.width);
      }
    },
  });

  const ensureTopVisible = () => {
    if (containerRef.current) {
      const nodeBoundingRect = containerRef.current.getBoundingClientRect();
      // Nav bar + sticky tab size to remove from viewport
      const stickyTabMargin = remToPx(9);
      const containerBoundingRect = containerRef.current.offsetParent?.getBoundingClientRect() ?? {
        left: 0,
        right: 0,
        bottom: window.innerHeight || document.documentElement.clientHeight,
        top: stickyTabMargin,
      };

      const isInViewPort = nodeBoundingRect.top >= stickyTabMargin && nodeBoundingRect.top >= containerBoundingRect.top;
      if (!isInViewPort) {
        containerRef.current.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
      }
    }
  };

  const columnsEffective = useMemo((): ColumnDefinition<Item>[] => {
    const result: ColumnDefinition<Item>[] = [];

    if (multiplayerRenderer) {
      result.push({
        propertyId: 'multiplayer',
        width: spacingRem.blockLeftColumnSpacing,
        noSeparator: true,
        background: theme.color.transparent,
        cellRender: (line) => multiplayerRenderer?.(line, columnsDefinition.map((cd) => cd.propertyId)),
      });
      result.push({
        propertyId: 'padding',
        width: getSpacing(Spacing.s, 0.1 /* inputs border */),
        noSeparator: true,
        background: theme.color.transparent,
        cellRender: () => null,
      });
    }

    result.push(...columnsDefinition);

    if (linesActions || globalActions) {
      result.push({
        propertyId: 'actions',
        action: true,
        headerRender: () => {
          if (globalActions) {
            return (<OverflowMenu menuItems={globalActions} />);
          } else {
            return linesActionsHeaderRenderer?.() ?? null;
          }
        },
        cellRender: (line, _, index) => {
          if (linesActions) {
            return (
              <OverflowMenu
                menuItems={linesActions(line, index)
                  .map((action) => (joinObjects(action, { hidden: Boolean(action.hidden) })))}
                disabled={linesActions(line, index).every((action) => action.hidden)}
              />
            );
          } else {
            return null;
          }
        },
      });
    }

    return result;
  }, [columnsDefinition, globalActions, linesActions, linesActionsHeaderRenderer, multiplayerRenderer, theme.color.transparent]);

  return (
    <VerticalBlock compact asBlockContent={!fullWidth}>
      <div
        ref={composeReactRefs<HTMLDivElement>(containerRef, ref, escapeListenerRef)}
        className={classnames({
          [classes.tableScrollContainer]: true,
          [classes.withMultiplayerRenderer]: !fullWidth && multiplayerRenderer,
          [classes.withoutMultiplayerRenderer]: !fullWidth && !multiplayerRenderer,
          [classes.padded]: !fullWidth && !multiplayerRenderer,
          [classes.fullWidth]: fullWidth,
        })}
      >
        {containerWidth === undefined ? null : (
          <DataTableRenderer<Item>
            containerWidth={containerWidth}
            list={list}
            columnsDefinition={columnsEffective}
            newLineFocus={newLineFocus}
            newColumnFocus={newColumnFocus}
            doSort={doSort}
            sortCriteria={sortCriteria}
            inlineCreation={inlineCreation}
            loading={loading}
            getNavigationPayload={getNavigationPayload}
            onNavigate={onNavigate}
            multiplayerRenderer={multiplayerRenderer}
            lineContext={lineContext}
            getHighlight={getHighlight}
            separateHeaderFromBody={separateHeaderFromBody}
            withoutHeaderLine={withoutHeaderLine}
            centerHeader={centerHeader}
            minColumnWidthRem={minColumnWidthRem}
          />
        )}
      </div>
      {onNewItem || pagination ? (
        <BlockContent fullWidth={fullWidth} padded={!fullWidth}>
          <span className={classes.bottomContainer}>
            {
              onNewItem || extraButton
                ? (
                  <SpacingLine>
                    {
                      onNewItem
                        ? (
                          <Button
                            ref={newItemRef}
                            title={newItemTitle ?? ''}
                            iconName={newItemIcon}
                            onClick={onNewItem}
                            variant={newItemButtonVariant}
                            disabled={newItemDisabled}
                            maxLine={1}
                          />
                        )
                        : null
                    }
                    {extraButton}
                  </SpacingLine>
                )
                : (<span />)
            }
            {
              pagination
                ? (
                  <PageSelector
                    pagination={{
                      currentPage: pagination.currentPage,
                      totalPagesNumber: pagination.totalPagesNumber,
                      totalItems: pagination.totalItems,
                      itemsRange: pagination.itemsRange,
                      onNext: () => {
                        pagination.onNext();
                        ensureTopVisible();
                      },
                      onPrevious: () => {
                        pagination.onPrevious();
                        ensureTopVisible();
                      },
                      onPage: (pageNumber) => {
                        pagination.onPage(pageNumber);
                        ensureTopVisible();
                      },
                    }}
                  />
                )
                : null
            }
          </span>
        </BlockContent>
      ) : null}
    </VerticalBlock>
  );
};

export default DataTable;
