import type { ErrorInfo, FunctionComponent, ReactNode } from 'react';
import { Component } from 'react';
import type { NavigateFunction } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { fromError } from 'yooi-utils';
import ErrorPage from '../../components/templates/ErrorPage';
import useStore from '../../store/useStore';
import { reportClientError, reportClientTrace } from '../../utils/clientReporterUtils';
import { HomepageNotConfiguredError } from '../../utils/HomepageNotConfiguredError';
import { HomepageNotFoundError } from '../../utils/HomepageNotFoundError';
import i18n from '../../utils/i18n';
import { NotFoundError } from '../../utils/NotFoundError';
import { ObjectNotFoundError } from '../../utils/ObjectNotFoundError';
import type { NavigationHistoryEntry } from '../../utils/useNavigationHistory';
import useNavigationHistory from '../../utils/useNavigationHistory';

interface RoutelessErrorBoundaryProps {
  children: ReactNode,
  getStoreConnectionDate?: () => (number | undefined),
  onNavigation?: (listener: () => void) => () => void,
  getNavigationHistory?: () => NavigationHistoryEntry[],
  navigate?: NavigateFunction,
}

interface RoutelessErrorBoundaryState {
  hasError?: unknown,
  unlisten?: () => void,
}

// Unfortunately, error boundaries cannot be implemented using hooks for the moment
// componentDidCatch & getDerivedStateFromError does not have any equivalent as hooks for the moment
// see (https://reactjs.org/docs/hooks-faq.html#how-do-lifecycle-methods-correspond-to-hooks)
export class RoutelessErrorBoundary extends Component<RoutelessErrorBoundaryProps, RoutelessErrorBoundaryState> {
  constructor(props: RoutelessErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: unknown): Partial<RoutelessErrorBoundaryState> {
    return { hasError: error };
  }

  override componentDidMount(): void {
    const { onNavigation } = this.props;

    if (onNavigation) {
      const unlisten = onNavigation(() => {
        const { hasError } = this.state;
        if (hasError) {
          this.setState({ hasError: undefined });
        }
      });
      this.setState({ unlisten });
    }
  }

  override componentDidCatch(error: Error, _errorInfo: ErrorInfo): void {
    const { getNavigationHistory, getStoreConnectionDate } = this.props;

    const navigationHistory = getNavigationHistory?.() ?? undefined;
    const { action, date, isUserControlled } = navigationHistory?.[0] ?? {};
    const storeConnectionDate = getStoreConnectionDate?.() ?? undefined;
    const now = Date.now();
    const isIdle = date && storeConnectionDate ? (now - (storeConnectionDate > date ? storeConnectionDate : date)) > 1000 : undefined;

    const errorWithData = fromError(error, 'Component error', { storeConnectionDate, now, isIdle, navigationHistory });

    const isNotFoundError = error instanceof ObjectNotFoundError || error instanceof NotFoundError;
    if (error instanceof HomepageNotFoundError || error instanceof HomepageNotConfiguredError || (isNotFoundError && (action === 'external' || action === 'POP' || isIdle || isUserControlled))) {
      // in case of external link or if the page was idle (concurrent deletion ?) or if the link was generated by an user
      // report as trace since we don't want to be bothered with these events
      reportClientTrace(errorWithData);
    } else {
      // by default report as error
      reportClientError(errorWithData);
    }
  }

  override componentWillUnmount(): void {
    const { unlisten } = this.state;

    if (unlisten) {
      unlisten();
      this.setState({ unlisten: undefined });
    }
  }

  override render(): ReactNode {
    const { navigate, children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      const tryAgain = i18n`Don't worry, you can still go back and try again.`;
      const action = navigate ? { title: i18n`Go back`, onClick: () => navigate(-1) } : undefined;
      if (hasError instanceof NotFoundError || hasError instanceof URIError) {
        return (<ErrorPage title={i18n`Oops!... Broken link`} messages={[i18n`Link you are looking for appears to be broken.`, tryAgain]} action={action} />);
      } else if (hasError instanceof HomepageNotConfiguredError) {
        return (
          <ErrorPage
            title={i18n`No homepage configured`}
            messages={[i18n`If unexpected, please contact an administrator.`, tryAgain]}
            action={action}
          />
        );
      } else if (hasError instanceof HomepageNotFoundError) {
        return (
          <ErrorPage
            title={i18n`This homepage does not exist`}
            messages={[i18n`You either can't display the instance or it has been deleted.`, tryAgain]}
            action={action}
          />
        );
      } else if (hasError instanceof ObjectNotFoundError) {
        return (
          <ErrorPage
            title={i18n`This ${hasError.data.objectName} does not exist`}
            messages={[i18n`It has either been removed or never been created.`, tryAgain]}
            action={action}
          />
        );
      } else {
        return (<ErrorPage title={i18n`Oops!... Something went wrong`} messages={[i18n`The technical team has already been notified of your issue.`, tryAgain]} action={action} />);
      }
    } else {
      return children;
    }
  }
}

interface ErrorBoundaryProps {
  children: ReactNode,
}

const ErrorBoundary: FunctionComponent<ErrorBoundaryProps> = ({ children }) => {
  const { onNavigation, getNavigationHistory } = useNavigationHistory();
  const navigate = useNavigate();
  const { getConnectionDate } = useStore();

  return (
    <RoutelessErrorBoundary
      getStoreConnectionDate={getConnectionDate}
      onNavigation={onNavigation}
      getNavigationHistory={getNavigationHistory}
      navigate={navigate}
    >
      {children}
    </RoutelessErrorBoundary>
  );
};

export default ErrorBoundary;
