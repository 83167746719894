import type { FunctionComponent } from 'react';
import type { IconColorVariant } from '../../../../../components/atoms/Icon';
import Icon, { IconName, IconSizeVariant } from '../../../../../components/atoms/Icon';
import base from '../../../../../theme/base';
import { darken } from '../../../../../theme/colorUtils';
import { FontVariant } from '../../../../../theme/fontDefinition';
import { getSpacing, Spacing, spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import StoreTextInputField from '../../../input/StoreTextInputField';

interface GraphChartBlockCardTitleProps {
  label: string | undefined,
  placeholder?: string,
  onLabelUpdate?: (newValue: string | null) => void,
  icon?: IconName | undefined,
  iconColor?: string,
  backgroundColor?: string,
  infoIcon?: {
    name: IconName,
    colorVariant: IconColorVariant,
    tooltip: string,
  },
}

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    gridTemplateColumns: 'auto 1fr',
    columnGap: getSpacing(Spacing.m),
    width: 'fit-content',
    display: 'inline-grid',
    gridAutoFlow: 'column',
    marginRight: spacingRem.m,
  },
  iconContainer: {
    width: getSpacing(Spacing.xxl),
    height: getSpacing(Spacing.xxl),
    borderRadius: base.borderRadius.large,
    borderWidth: '0.2rem',
    borderStyle: 'solid',
    borderColor: theme.color.background.neutral.subtle,
    background: theme.color.background.neutral.subtle,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    flexGrow: 1,
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}), 'graphChartBlockCardTitle');

const GraphChartBlockCardTitle: FunctionComponent<GraphChartBlockCardTitleProps> = ({ label, placeholder, onLabelUpdate, icon, iconColor, backgroundColor, infoIcon }) => {
  const classes = useStyles();

  return (
    <div className={classes.title}>
      <div className={classes.iconContainer} style={{ backgroundColor, borderColor: backgroundColor ? darken(backgroundColor, 10) : undefined }}>
        <Icon
          name={icon ?? IconName.add}
          color={iconColor}
          size={IconSizeVariant.l}
        />
      </div>
      <div className={classes.textContainer}>
        <StoreTextInputField
          textVariant={FontVariant.blockSecondaryTitle}
          initialValue={label}
          onSubmit={onLabelUpdate}
          readOnly={!onLabelUpdate}
          placeholder={placeholder ?? i18n`Add text`}
          maxLine={1}
          dropdownMaxLine={10}
          fullWidth
        />
      </div>
      {infoIcon ? (
        <span className={classes.infoContainer}>
          <Icon
            name={infoIcon.name}
            colorVariant={infoIcon.colorVariant}
            tooltip={infoIcon.tooltip}
          />
        </span>
      ) : null}
    </div>
  );
};

export default GraphChartBlockCardTitle;
