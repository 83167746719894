import type { FunctionComponent } from 'react';
import makeStyles from '../../../utils/makeStyles';
import { remToPx } from '../../../utils/sizeUtils';
import { SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import useTheme from '../../../utils/useTheme';
import Tooltip from '../../atoms/Tooltip';
import Typo, { TypoAlign } from '../../atoms/Typo';

const toRadians = (angle: number) => angle * (Math.PI / 180);

const useStyles = makeStyles({
  textContainer: {
    width: '100%',
  },
}, 'barChartXAxis');

interface BarChartXAxisProps {
  x?: number,
  y?: number,
  text?: string,
  axisHeightInRem: number,
  xAxisLabelAngle: number,
}

const BarChartXAxis: FunctionComponent<BarChartXAxisProps> = ({ x = 0, y = 0, text, axisHeightInRem, xAxisLabelAngle }) => {
  const theme = useTheme();
  const classes = useStyles();

  if (!text) {
    return <g />;
  }
  const foreignObjectHeight = remToPx(2);
  const bottomDY = (foreignObjectHeight * Math.sin(toRadians(90 - xAxisLabelAngle)));
  const foreignObjectWidth = (remToPx(axisHeightInRem) - bottomDY) / Math.sin(toRadians(xAxisLabelAngle));
  return (
    <g>
      <foreignObject
        x={x - foreignObjectWidth}
        y={y}
        width={foreignObjectWidth}
        height={foreignObjectHeight}
        transform={`rotate(-${xAxisLabelAngle},${x},${y})`}
      >
        <SizeContextProvider sizeVariant={SizeVariant.small}>
          <Tooltip title={text}>
            <div
              className={classes.textContainer}
            >
              <div>
                <Typo maxLine={1} fullWidth color={theme.color.text.secondary} align={TypoAlign.right}>{text}</Typo>
              </div>
            </div>
          </Tooltip>
        </SizeContextProvider>
      </foreignObject>
    </g>
  );
};

export default BarChartXAxis;
