import type { InitModuleFunction } from 'yooi-store';
import { compareRank, ranker, textToRichText } from 'yooi-utils';
import { AddGroupConcept, AddPlatformCapabilities, AddRoleConcept, AddSuggestedFields, AddUserConcept, InitializePlatform } from '../../src/PlatformInitializationSteps';
import {
  BlockType_Section,
  BlockType_Tab,
  FieldSuggestedDisplay,
  FieldSuggestedDisplay_Rank,
  FieldSuggestedDisplay_Role_ConceptDefinition,
  FieldSuggestedDisplay_Role_Field,
} from '../conceptLayoutModule/ids';
import type { ConceptDefinitionInstanceAdministrationColumnRaw, FieldDimensionTypesRaw } from '../conceptModule';
import { addField } from '../conceptModule';
import {
  ColorField,
  Concept,
  Concept_FunctionalId,
  Concept_Name,
  Concept_Resource,
  Concept_StakeholdersRoles,
  Concept_SwimlaneRank,
  ConceptCapabilityAssignCollaborator,
  ConceptCapabilityAssignUser,
  ConceptCapabilityCreate,
  ConceptCapabilityDelete,
  ConceptCapabilityEdit,
  ConceptCapabilityRead,
  ConceptChipDisplay,
  ConceptChipDisplay_Rank,
  ConceptChipDisplay_Role_ConceptDefinition,
  ConceptChipDisplay_Role_Field,
  ConceptCollaborationFieldDimension,
  ConceptDefinition,
  ConceptDefinition_ApiAlias,
  ConceptDefinition_Color,
  ConceptDefinition_HasStakeholders,
  ConceptDefinition_Icon,
  ConceptDefinition_LibraryShowSwimlane,
  ConceptDefinition_LibraryShowTable,
  ConceptDefinition_MatrixLabel,
  ConceptDefinition_Name,
  ConceptDefinition_ShowEcosystemTab,
  ConceptDefinition_ShowMasterDetail,
  ConceptDefinitionInstanceAdministrationColumn,
  ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition,
  ConceptDefinitionInstanceAdministrationColumn_Field,
  ConceptDefinitionInstanceAdministrationColumn_Rank,
  ConceptFunctionalIdDimension,
  ConceptGroupCapability,
  ConceptGroupCapability_Role_ConceptCapability,
  ConceptGroupCapability_Role_ConceptDefinition,
  ConceptGroupCapability_Role_ConceptGroup,
  ConceptNameDimension,
  ConceptRole,
  EmbeddingField,
  EmbeddingField_FromType,
  EmbeddingField_ToType,
  Field_ApiAlias,
  Field_IsCore,
  Field_Title,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  Group,
  Group_GrantedOnCreation,
  PlatformCapabilityAdmin,
  PlatformGroupCapability,
  PlatformGroupCapability_Role_Group,
  PlatformGroupCapability_Role_PlatformCapability,
  TextField,
  User,
  User_Email,
  Workflow,
  Workflow_Name,
  Workflow_TargetedConceptDefinition,
  WorkflowEntry,
  WorkflowEntry_Rank,
  WorkflowEntry_Role_Concept,
  WorkflowEntry_Role_Workflow,
} from '../conceptModule/ids';
import { createConceptRole } from '../conceptModule/utils';
import { Dashboard } from '../dashboardModule/ids';
import {
  DataAsset,
  DataAssetType,
  DataAssetType_DefaultResourceType,
  DataAssetType_Description,
  DataAssetType_IsDefaultType,
  DataAssetType_Name,
  DataAssetTypeResourceTypes,
  DataAssetTypeResourceTypes_Role_DataAssetType,
  DataAssetTypeResourceTypes_Role_ResourceType,
} from '../dataAssetModule/ids';
import { createDebugInitializer } from '../debugModule';
import { CurrentPlatformConfiguration, PlatformConfiguration_IntegrationDefaultExpiration } from '../platformConfigurationModule/ids';
import { Reference } from '../referenceModule/ids';
import { Resource, Resource_Image, Resource_Type, Resource_URL, ResourceType, ResourceType_Description, ResourceType_Name } from '../resourceModule/ids';
import { Class_Extend, Instance_Of } from '../typeModule/ids';
import { injectLibraryTableColumns, injectPage } from './utils';

const { moduleId } = createDebugInitializer('platform-initialization');
export const PlatformInitializationModuleId = moduleId;

export const initModule: InitModuleFunction = () => ({
  id: PlatformInitializationModuleId,
  migrations: {
    [InitializePlatform]: {
      handler: (objectStore) => {
        const { createObject, updateObject, withAssociation } = objectStore;

        updateObject(CurrentPlatformConfiguration, { [PlatformConfiguration_IntegrationDefaultExpiration]: 30 });

        const groupGenerator = ranker.createNextRankGenerator();
        const adminGroupId = createObject({
          [Instance_Of]: Group,
          [Concept_Name]: textToRichText('Administration (All capabilities)'),
          [Concept_SwimlaneRank]: groupGenerator(),
        });
        updateObject([PlatformGroupCapability, adminGroupId, PlatformCapabilityAdmin], {});
        const userGroupId = createObject({
          [Instance_Of]: Group,
          [Concept_Name]: textToRichText('Users'),
          [Concept_SwimlaneRank]: groupGenerator(),
          [Group_GrantedOnCreation]: true,
        });

        const roleRanker = ranker.createNextRankGenerator();

        // Complete resources

        {
          updateObject(Resource, {
            [ConceptDefinition_Name]: 'Resource',
            [ConceptDefinition_Icon]: 'description',
            [ConceptDefinition_ApiAlias]: 'resource',
            [ConceptDefinition_HasStakeholders]: true,
            [ConceptDefinition_ShowEcosystemTab]: true,
            [ConceptDefinition_LibraryShowTable]: true,
            [ConceptDefinition_ShowMasterDetail]: true,
          });
          withAssociation(ConceptChipDisplay)
            .withRole(ConceptChipDisplay_Role_ConceptDefinition, Resource)
            .withRole(ConceptChipDisplay_Role_Field, Concept_Name)
            .updateObject({ [ConceptChipDisplay_Rank]: ranker.createNextRankGenerator()() });

          createConceptRole(
            objectStore,
            Resource,
            'Leader',
            true,
            false,
            [ConceptCapabilityEdit, ConceptCapabilityAssignUser, ConceptCapabilityDelete, ConceptCapabilityAssignCollaborator],
            roleRanker()
          );
          createConceptRole(
            objectStore,
            Resource,
            'Viewer',
            false,
            true,
            [],
            roleRanker()
          );
          withAssociation(ConceptGroupCapability)
            .withRole(ConceptGroupCapability_Role_ConceptGroup, userGroupId)
            .withRole(ConceptGroupCapability_Role_ConceptCapability, ConceptCapabilityCreate)
            .withRole(ConceptGroupCapability_Role_ConceptDefinition, Resource)
            .updateObject({});
          withAssociation(ConceptGroupCapability)
            .withRole(ConceptGroupCapability_Role_ConceptGroup, userGroupId)
            .withRole(ConceptGroupCapability_Role_ConceptCapability, ConceptCapabilityRead)
            .withRole(ConceptGroupCapability_Role_ConceptDefinition, Resource)
            .updateObject({});

          injectLibraryTableColumns(objectStore, Resource, [Concept_Name, Resource_URL, Resource_Type, Resource_Image]);

          const libraryTableRanker = ranker.createNextRankGenerator();
          createObject<ConceptDefinitionInstanceAdministrationColumnRaw>({
            [Instance_Of]: ConceptDefinitionInstanceAdministrationColumn,
            [ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition]: Resource,
            [ConceptDefinitionInstanceAdministrationColumn_Field]: Concept_Name,
            [ConceptDefinitionInstanceAdministrationColumn_Rank]: libraryTableRanker(),
          });
          createObject<ConceptDefinitionInstanceAdministrationColumnRaw>({
            [Instance_Of]: ConceptDefinitionInstanceAdministrationColumn,
            [ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition]: Resource,
            [ConceptDefinitionInstanceAdministrationColumn_Field]: Resource_URL,
            [ConceptDefinitionInstanceAdministrationColumn_Rank]: libraryTableRanker(),
          });
        }

        // Complete reference

        {
          updateObject(Reference, {
            [ConceptDefinition_Name]: 'Reference',
            [ConceptDefinition_Icon]: 'description',
            [ConceptDefinition_ApiAlias]: 'reference',
            [ConceptDefinition_HasStakeholders]: true,
            [ConceptDefinition_ShowEcosystemTab]: true,
            [ConceptDefinition_LibraryShowTable]: true,
            [ConceptDefinition_ShowMasterDetail]: true,
          });

          injectPage(objectStore, Reference, [
            {
              name: 'Overview',
              type: BlockType_Tab,
              sections: [{
                name: 'Summary',
                type: BlockType_Section,
                fieldDefinitions: [
                  Concept_Resource,
                ],
              }],
            }, {
              name: 'Stakeholders',
              type: BlockType_Tab,
              fieldDefinitions: [Concept_StakeholdersRoles],
            },
          ]);
          injectLibraryTableColumns(objectStore, Reference, [Concept_Name, Concept_Resource]);

          const libraryTableRanker = ranker.createNextRankGenerator();
          createObject<ConceptDefinitionInstanceAdministrationColumnRaw>({
            [Instance_Of]: ConceptDefinitionInstanceAdministrationColumn,
            [ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition]: Reference,
            [ConceptDefinitionInstanceAdministrationColumn_Field]: Concept_Name,
            [ConceptDefinitionInstanceAdministrationColumn_Rank]: libraryTableRanker(),
          });
          createObject<ConceptDefinitionInstanceAdministrationColumnRaw>({
            [Instance_Of]: ConceptDefinitionInstanceAdministrationColumn,
            [ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition]: Reference,
            [ConceptDefinitionInstanceAdministrationColumn_Field]: Concept_Resource,
            [ConceptDefinitionInstanceAdministrationColumn_Rank]: libraryTableRanker(),
          });

          withAssociation(ConceptChipDisplay)
            .withRole(ConceptChipDisplay_Role_ConceptDefinition, Reference)
            .withRole(ConceptChipDisplay_Role_Field, Concept_Name)
            .updateObject({ [ConceptChipDisplay_Rank]: ranker.createNextRankGenerator()() });
          withAssociation(FieldDimensionTypes)
            .withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCollaborationFieldDimension)
            .withRole(FieldDimensionTypes_Role_ConceptDefinition, Reference)
            .updateObject<FieldDimensionTypesRaw>({});

          createConceptRole(
            objectStore,
            Reference,
            'Leader',
            true,
            false,
            [ConceptCapabilityEdit, ConceptCapabilityAssignUser, ConceptCapabilityDelete, ConceptCapabilityAssignCollaborator],
            roleRanker()
          );
          createConceptRole(
            objectStore,
            Reference,
            'Viewer',
            false,
            true,
            [],
            roleRanker()
          );
          withAssociation(ConceptGroupCapability)
            .withRole(ConceptGroupCapability_Role_ConceptGroup, userGroupId)
            .withRole(ConceptGroupCapability_Role_ConceptCapability, ConceptCapabilityCreate)
            .withRole(ConceptGroupCapability_Role_ConceptDefinition, Reference)
            .updateObject({});
          withAssociation(ConceptGroupCapability)
            .withRole(ConceptGroupCapability_Role_ConceptGroup, userGroupId)
            .withRole(ConceptGroupCapability_Role_ConceptCapability, ConceptCapabilityRead)
            .withRole(ConceptGroupCapability_Role_ConceptDefinition, Reference)
            .updateObject({});
          withAssociation(FieldDimensionTypes)
            .withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCollaborationFieldDimension)
            .withRole(FieldDimensionTypes_Role_ConceptDefinition, ConceptRole)
            .updateObject({});
        }

        // Complete Data asset
        {
          updateObject(DataAsset, {
            [ConceptDefinition_Name]: 'Data Asset',
            [ConceptDefinition_Icon]: 'dataset',
            [ConceptDefinition_ApiAlias]: 'data-asset',
            [ConceptDefinition_HasStakeholders]: true,
            [ConceptDefinition_ShowEcosystemTab]: true,
            [ConceptDefinition_MatrixLabel]: Concept_Name,
            [ConceptDefinition_LibraryShowTable]: true,
            [ConceptDefinition_LibraryShowSwimlane]: true,
            [ConceptDefinition_ShowMasterDetail]: true,
          });

          const descriptionFieldId = addField(objectStore, DataAsset, {
            [Instance_Of]: TextField,
            [Field_Title]: 'Description',
            [Field_ApiAlias]: 'description',
          });
          const personalDataFieldId = addField(objectStore, DataAsset, {
            [Instance_Of]: TextField,
            [Field_Title]: 'Does it contain personal data?',
            [Field_ApiAlias]: 'does-it-contain-personal-data',
          });
          const sensitiveDataFieldId = addField(objectStore, DataAsset, {
            [Instance_Of]: TextField,
            [Field_Title]: 'Does it contain sensitive data?',
            [Field_ApiAlias]: 'does-it-contain-sensitive-data',
          });
          const resourceListId = addField(objectStore, DataAsset, {
            [Instance_Of]: EmbeddingField,
            [Field_Title]: 'Reference List',
            [Field_ApiAlias]: 'reference-list',
            [EmbeddingField_FromType]: DataAsset,
            [EmbeddingField_ToType]: Resource,
            [Field_IsCore]: true,
          });

          injectPage(objectStore, DataAsset, [
            {
              name: 'Overview',
              type: BlockType_Tab,
              sections: [
                {
                  name: 'Summary',
                  type: BlockType_Section,
                  fieldDefinitions: [
                    descriptionFieldId,
                    resourceListId,
                  ],
                },
                {
                  name: 'Risks & Compliance',
                  type: BlockType_Section,
                  fieldDefinitions: [
                    personalDataFieldId,
                    sensitiveDataFieldId,
                  ],
                },
              ],
            },
            {
              name: 'Stakeholders',
              type: BlockType_Tab,
              fieldDefinitions: [Concept_StakeholdersRoles],
            },
          ]);

          injectLibraryTableColumns(objectStore, DataAsset, [Concept_Name]);

          const libraryTableRanker = ranker.createNextRankGenerator();
          createObject<ConceptDefinitionInstanceAdministrationColumnRaw>({
            [Instance_Of]: ConceptDefinitionInstanceAdministrationColumn,
            [ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition]: DataAsset,
            [ConceptDefinitionInstanceAdministrationColumn_Field]: Concept_Name,
            [ConceptDefinitionInstanceAdministrationColumn_Rank]: libraryTableRanker(),
          });

          withAssociation(ConceptChipDisplay)
            .withRole(ConceptChipDisplay_Role_ConceptDefinition, DataAsset)
            .withRole(ConceptChipDisplay_Role_Field, Concept_Name)
            .updateObject({ [ConceptChipDisplay_Rank]: ranker.createNextRankGenerator()() });
          withAssociation(FieldDimensionTypes)
            .withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCollaborationFieldDimension)
            .withRole(FieldDimensionTypes_Role_ConceptDefinition, DataAsset)
            .updateObject({});

          createConceptRole(
            objectStore,
            DataAsset,
            'Leader',
            true,
            false,
            [ConceptCapabilityEdit, ConceptCapabilityAssignUser, ConceptCapabilityDelete, ConceptCapabilityAssignCollaborator],
            roleRanker()
          );
          createConceptRole(
            objectStore,
            DataAsset,
            'Contributor',
            false,
            false,
            [ConceptCapabilityEdit, ConceptCapabilityAssignCollaborator],
            roleRanker()
          );
          createConceptRole(
            objectStore,
            DataAsset,
            'Viewer',
            false,
            true,
            [],
            roleRanker()
          );
          withAssociation(ConceptGroupCapability)
            .withRole(ConceptGroupCapability_Role_ConceptGroup, userGroupId)
            .withRole(ConceptGroupCapability_Role_ConceptCapability, ConceptCapabilityCreate)
            .withRole(ConceptGroupCapability_Role_ConceptDefinition, DataAsset)
            .updateObject({});
          withAssociation(ConceptGroupCapability)
            .withRole(ConceptGroupCapability_Role_ConceptGroup, userGroupId)
            .withRole(ConceptGroupCapability_Role_ConceptCapability, ConceptCapabilityRead)
            .withRole(ConceptGroupCapability_Role_ConceptDefinition, DataAsset)
            .updateObject({});

          const sampleId = createObject({
            [Instance_Of]: ResourceType,
            [ResourceType_Name]: 'Sample',
            [ResourceType_Description]: 'Represents a sample of the data',
          });
          const extractId = createObject({
            [Instance_Of]: ResourceType,
            [ResourceType_Name]: 'Extract',
            [ResourceType_Description]: 'Represents an extraction of the data',
          });
          const dataAssetTypeId = createObject({
            [Instance_Of]: DataAssetType,
            [DataAssetType_Name]: 'Data source',
            [DataAssetType_Description]: 'Represents a data',
            [DataAssetType_DefaultResourceType]: sampleId,
            [DataAssetType_IsDefaultType]: true,
          });
          withAssociation(DataAssetTypeResourceTypes)
            .withRole(DataAssetTypeResourceTypes_Role_DataAssetType, dataAssetTypeId)
            .withRole(DataAssetTypeResourceTypes_Role_ResourceType, sampleId)
            .updateObject({});
          withAssociation(DataAssetTypeResourceTypes)
            .withRole(DataAssetTypeResourceTypes_Role_DataAssetType, dataAssetTypeId)
            .withRole(DataAssetTypeResourceTypes_Role_ResourceType, extractId)
            .updateObject({});
        }

        // Complete Dashboard

        {
          updateObject(Dashboard, {
            [ConceptDefinition_HasStakeholders]: true,
            [ConceptDefinition_LibraryShowTable]: true,
            [ConceptDefinition_Name]: 'Dashboard',
            [ConceptDefinition_Icon]: 'dashboard',
          });

          withAssociation(ConceptChipDisplay)
            .withRole(ConceptChipDisplay_Role_ConceptDefinition, Dashboard)
            .withRole(ConceptChipDisplay_Role_Field, Concept_Name)
            .updateObject({ [ConceptChipDisplay_Rank]: ranker.createNextRankGenerator()() });
          withAssociation(FieldDimensionTypes)
            .withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCollaborationFieldDimension)
            .withRole(FieldDimensionTypes_Role_ConceptDefinition, Dashboard)
            .updateObject({});

          createConceptRole(
            objectStore,
            Dashboard,
            'Leader',
            true,
            false,
            [ConceptCapabilityEdit, ConceptCapabilityAssignUser, ConceptCapabilityDelete, ConceptCapabilityAssignCollaborator],
            roleRanker()
          );
          createConceptRole(
            objectStore,
            Dashboard,
            'Contributor',
            false,
            false,
            [ConceptCapabilityEdit, ConceptCapabilityAssignCollaborator],
            roleRanker()
          );
          createConceptRole(
            objectStore,
            Dashboard,
            'Viewer',
            false,
            true,
            [],
            roleRanker()
          );

          withAssociation(ConceptGroupCapability)
            .withRole(ConceptGroupCapability_Role_ConceptGroup, userGroupId)
            .withRole(ConceptGroupCapability_Role_ConceptCapability, ConceptCapabilityCreate)
            .withRole(ConceptGroupCapability_Role_ConceptDefinition, Dashboard)
            .updateObject({});
          withAssociation(ConceptGroupCapability)
            .withRole(ConceptGroupCapability_Role_ConceptGroup, userGroupId)
            .withRole(ConceptGroupCapability_Role_ConceptCapability, ConceptCapabilityRead)
            .withRole(ConceptGroupCapability_Role_ConceptDefinition, Dashboard)
            .updateObject({});

          injectLibraryTableColumns(objectStore, Dashboard, [Concept_Name]);

          const libraryTableRanker = ranker.createNextRankGenerator();
          createObject<ConceptDefinitionInstanceAdministrationColumnRaw>({
            [Instance_Of]: ConceptDefinitionInstanceAdministrationColumn,
            [ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition]: Dashboard,
            [ConceptDefinitionInstanceAdministrationColumn_Field]: Concept_Name,
            [ConceptDefinitionInstanceAdministrationColumn_Rank]: libraryTableRanker(),
          });
        }

        {
          const statusConceptDefinitionId = createObject({
            [Instance_Of]: ConceptDefinition,
            [Class_Extend]: Concept,
            [ConceptDefinition_Name]: 'Collaboration status',
            [ConceptDefinition_LibraryShowTable]: true,
            [ConceptDefinition_Icon]: 'account_tree',
            [ConceptDefinition_ShowMasterDetail]: true,
          });

          withAssociation(FieldDimensionTypes)
            .withRole(FieldDimensionTypes_Role_FieldDimension, ConceptNameDimension)
            .withRole(FieldDimensionTypes_Role_ConceptDefinition, statusConceptDefinitionId)
            .updateObject({});
          withAssociation(FieldDimensionTypes)
            .withRole(FieldDimensionTypes_Role_FieldDimension, ConceptFunctionalIdDimension)
            .withRole(FieldDimensionTypes_Role_ConceptDefinition, statusConceptDefinitionId)
            .updateObject({});
          withAssociation(FieldDimensionTypes)
            .withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCollaborationFieldDimension)
            .withRole(FieldDimensionTypes_Role_ConceptDefinition, statusConceptDefinitionId)
            .updateObject({});

          const getChipRank = ranker.createNextRankGenerator();
          withAssociation(ConceptChipDisplay)
            .withRole(ConceptChipDisplay_Role_Field, Concept_Name)
            .withRole(ConceptChipDisplay_Role_ConceptDefinition, statusConceptDefinitionId)
            .updateObject({ [ConceptChipDisplay_Rank]: getChipRank() });

          const workflowId = createObject({
            [Instance_Of]: Workflow,
            [Workflow_Name]: 'Collaboration workflow',
            [Workflow_TargetedConceptDefinition]: statusConceptDefinitionId,
          });

          const colorFieldId = addField(objectStore, statusConceptDefinitionId, {
            [Instance_Of]: ColorField,
            [Field_Title]: 'Color',
            [Field_ApiAlias]: 'color',
          });
          updateObject(statusConceptDefinitionId, { [ConceptDefinition_Color]: colorFieldId });

          injectLibraryTableColumns(objectStore, statusConceptDefinitionId, [Concept_Name, colorFieldId]);
          injectPage(objectStore, statusConceptDefinitionId, [
            {
              name: 'Overview',
              type: BlockType_Tab,
              sections: [{
                name: 'Summary',
                type: BlockType_Section,
                fieldDefinitions: [
                  Concept_Name,
                  colorFieldId,
                ],
              }],
            },
          ]);

          const libraryTableRanker = ranker.createNextRankGenerator();
          createObject<ConceptDefinitionInstanceAdministrationColumnRaw>({
            [Instance_Of]: ConceptDefinitionInstanceAdministrationColumn,
            [ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition]: statusConceptDefinitionId,
            [ConceptDefinitionInstanceAdministrationColumn_Field]: Concept_Name,
            [ConceptDefinitionInstanceAdministrationColumn_Rank]: libraryTableRanker(),
          });
          createObject<ConceptDefinitionInstanceAdministrationColumnRaw>({
            [Instance_Of]: ConceptDefinitionInstanceAdministrationColumn,
            [ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition]: statusConceptDefinitionId,
            [ConceptDefinitionInstanceAdministrationColumn_Field]: colorFieldId,
            [ConceptDefinitionInstanceAdministrationColumn_Rank]: libraryTableRanker(),
          });

          const getStatusRank = ranker.createNextRankGenerator();
          const getSwimlaneRank = ranker.createNextRankGenerator();

          [
            { name: 'In progress', color: '#7175E1' },
            { name: 'Resolved', color: '#47a693' },
            { name: 'Cancelled', color: '#47a693' },
          ]
            .forEach((status, index) => {
              const statusId = createObject({
                [Instance_Of]: statusConceptDefinitionId,
                [Concept_Name]: [{ type: 'paragraph', children: [{ text: status.name }] }],
                [colorFieldId]: status.color,
                [Concept_SwimlaneRank]: getSwimlaneRank(),
                [Concept_FunctionalId]: `${index + 1}`,
              });
              withAssociation(WorkflowEntry)
                .withRole(WorkflowEntry_Role_Workflow, workflowId)
                .withRole(WorkflowEntry_Role_Concept, statusId)
                .updateObject({ [WorkflowEntry_Rank]: getStatusRank() });
            });
        }
      },
    },
    [AddUserConcept]: {
      handler: (objectStore) => {
        const { updateObject, withAssociation, createObject } = objectStore;
        updateObject(User, {
          [ConceptDefinition_Name]: 'User',
          [ConceptDefinition_Icon]: 'person',
          [ConceptDefinition_ApiAlias]: 'user',
          [ConceptDefinition_ShowEcosystemTab]: false,
          [ConceptDefinition_MatrixLabel]: Concept_Name,
          [ConceptDefinition_LibraryShowTable]: true,
          [ConceptDefinition_ShowMasterDetail]: true,
        });

        injectPage(objectStore, User, [
          {
            name: 'Overview',
            type: BlockType_Tab,
            sections: [{
              name: 'Summary',
              type: BlockType_Section,
              fieldDefinitions: [
                Concept_Name,
                User_Email,
              ],
            }],
          },
        ]);
        injectLibraryTableColumns(objectStore, User, [Concept_Name, User_Email]);

        const libraryTableRanker = ranker.createNextRankGenerator();
        createObject<ConceptDefinitionInstanceAdministrationColumnRaw>({
          [Instance_Of]: ConceptDefinitionInstanceAdministrationColumn,
          [ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition]: User,
          [ConceptDefinitionInstanceAdministrationColumn_Field]: Concept_Name,
          [ConceptDefinitionInstanceAdministrationColumn_Rank]: libraryTableRanker(),
        });
        createObject<ConceptDefinitionInstanceAdministrationColumnRaw>({
          [Instance_Of]: ConceptDefinitionInstanceAdministrationColumn,
          [ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition]: User,
          [ConceptDefinitionInstanceAdministrationColumn_Field]: User_Email,
          [ConceptDefinitionInstanceAdministrationColumn_Rank]: libraryTableRanker(),
        });

        withAssociation(ConceptChipDisplay)
          .withRole(ConceptChipDisplay_Role_ConceptDefinition, User)
          .withRole(ConceptChipDisplay_Role_Field, Concept_Name)
          .updateObject({ [ConceptChipDisplay_Rank]: ranker.createNextRankGenerator()() });
        withAssociation(FieldDimensionTypes)
          .withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCollaborationFieldDimension)
          .withRole(FieldDimensionTypes_Role_ConceptDefinition, User)
          .updateObject<FieldDimensionTypesRaw>({});
      },
    },
    [AddSuggestedFields]: {
      handler: ({ withAssociation }) => {
        const generateNextDataGridRank = ranker.createNextRankGenerator();
        withAssociation(FieldSuggestedDisplay)
          .withRole(FieldSuggestedDisplay_Role_ConceptDefinition, User)
          .withRole(FieldSuggestedDisplay_Role_Field, Concept_Name)
          .updateObject({
            [FieldSuggestedDisplay_Rank]: generateNextDataGridRank(),
          });
        withAssociation(FieldSuggestedDisplay)
          .withRole(FieldSuggestedDisplay_Role_ConceptDefinition, User)
          .withRole(FieldSuggestedDisplay_Role_Field, User_Email)
          .updateObject({
            [FieldSuggestedDisplay_Rank]: generateNextDataGridRank(),
          });
      },
    },
    [AddRoleConcept]: {
      handler: (objectStore) => {
        const { updateObject, withAssociation, createObject } = objectStore;
        updateObject(ConceptRole, {
          [ConceptDefinition_Name]: 'Role',
          [ConceptDefinition_Icon]: 'person',
          [ConceptDefinition_ApiAlias]: 'role',
          [ConceptDefinition_ShowEcosystemTab]: false,
          [ConceptDefinition_MatrixLabel]: Concept_Name,
          [ConceptDefinition_ShowMasterDetail]: true,
        });

        injectPage(objectStore, ConceptRole, [
          {
            name: 'Overview',
            type: BlockType_Tab,
            sections: [{
              name: 'Summary',
              type: BlockType_Section,
              fieldDefinitions: [
                Concept_Name,
              ],
            }],
          },
        ]);
        injectLibraryTableColumns(objectStore, ConceptRole, [Concept_Name]);

        const libraryTableRanker = ranker.createNextRankGenerator();
        createObject<ConceptDefinitionInstanceAdministrationColumnRaw>({
          [Instance_Of]: ConceptDefinitionInstanceAdministrationColumn,
          [ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition]: ConceptRole,
          [ConceptDefinitionInstanceAdministrationColumn_Field]: Concept_Name,
          [ConceptDefinitionInstanceAdministrationColumn_Rank]: libraryTableRanker(),
        });

        withAssociation(ConceptChipDisplay)
          .withRole(ConceptChipDisplay_Role_ConceptDefinition, ConceptRole)
          .withRole(ConceptChipDisplay_Role_Field, Concept_Name)
          .updateObject({ [ConceptChipDisplay_Rank]: ranker.createNextRankGenerator()() });
      },
    },
    [AddPlatformCapabilities]: {
      handler: () => { },
    },
    [AddGroupConcept]: {
      handler: (objectStore) => {
        const { updateObject, getObject, withAssociation, createObject } = objectStore;
        updateObject(Group, {
          [ConceptDefinition_Name]: 'Group',
          [ConceptDefinition_Icon]: 'group',
          [ConceptDefinition_ApiAlias]: 'group',
          [ConceptDefinition_ShowEcosystemTab]: false,
          [ConceptDefinition_MatrixLabel]: Concept_Name,
          [ConceptDefinition_HasStakeholders]: true,
          [ConceptDefinition_LibraryShowTable]: true,
          [ConceptDefinition_ShowMasterDetail]: true,
        });

        const descriptionFieldId = addField(objectStore, Group, {
          [Instance_Of]: TextField,
          [Field_Title]: 'Description',
          [Field_ApiAlias]: 'description',
        });
        injectPage(objectStore, Group, [
          {
            name: 'Overview',
            type: BlockType_Tab,
            sections: [{
              name: 'Summary',
              type: BlockType_Section,
              fieldDefinitions: [
                Concept_Name,
                descriptionFieldId,
              ],
            }],
          }, {
            name: 'Stakeholders',
            type: BlockType_Tab,
            fieldDefinitions: [Concept_StakeholdersRoles],
          },
        ]);
        injectLibraryTableColumns(objectStore, Group, [Concept_Name, descriptionFieldId]);

        const libraryTableRanker = ranker.createNextRankGenerator();
        createObject<ConceptDefinitionInstanceAdministrationColumnRaw>({
          [Instance_Of]: ConceptDefinitionInstanceAdministrationColumn,
          [ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition]: Group,
          [ConceptDefinitionInstanceAdministrationColumn_Field]: Concept_Name,
          [ConceptDefinitionInstanceAdministrationColumn_Rank]: libraryTableRanker(),
        });
        createObject<ConceptDefinitionInstanceAdministrationColumnRaw>({
          [Instance_Of]: ConceptDefinitionInstanceAdministrationColumn,
          [ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition]: Group,
          [ConceptDefinitionInstanceAdministrationColumn_Field]: descriptionFieldId,
          [ConceptDefinitionInstanceAdministrationColumn_Rank]: libraryTableRanker(),
        });

        withAssociation(ConceptChipDisplay)
          .withRole(ConceptChipDisplay_Role_ConceptDefinition, Group)
          .withRole(ConceptChipDisplay_Role_Field, Concept_Name)
          .updateObject({ [ConceptChipDisplay_Rank]: ranker.createNextRankGenerator()() });
        withAssociation(FieldDimensionTypes)
          .withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCollaborationFieldDimension)
          .withRole(FieldDimensionTypes_Role_ConceptDefinition, Group)
          .updateObject<FieldDimensionTypesRaw>({});

        getObject(Group).navigateBack(Instance_Of).forEach((group) => {
          withAssociation(ConceptGroupCapability)
            .withRole(ConceptGroupCapability_Role_ConceptGroup, group.id)
            .withRole(ConceptGroupCapability_Role_ConceptCapability, ConceptCapabilityRead)
            .withRole(ConceptGroupCapability_Role_ConceptDefinition, Group)
            .updateObject({});
        });

        getObject(Group).navigateBack(Instance_Of)
          .filter((group) => Boolean(withAssociation(PlatformGroupCapability)
            .withRole(PlatformGroupCapability_Role_Group, group.id)
            .withRole(PlatformGroupCapability_Role_PlatformCapability, PlatformCapabilityAdmin)
            .getOrNull()))
          .forEach((adminGroup) => {
            withAssociation(ConceptGroupCapability)
              .withRole(ConceptGroupCapability_Role_ConceptGroup, adminGroup.id)
              .withRole(ConceptGroupCapability_Role_ConceptCapability, ConceptCapabilityEdit)
              .withRole(ConceptGroupCapability_Role_ConceptDefinition, Group)
              .updateObject({});
            withAssociation(ConceptGroupCapability)
              .withRole(ConceptGroupCapability_Role_ConceptGroup, adminGroup.id)
              .withRole(ConceptGroupCapability_Role_ConceptCapability, ConceptCapabilityDelete)
              .withRole(ConceptGroupCapability_Role_ConceptDefinition, Group)
              .updateObject({});
            withAssociation(ConceptGroupCapability)
              .withRole(ConceptGroupCapability_Role_ConceptGroup, adminGroup.id)
              .withRole(ConceptGroupCapability_Role_ConceptCapability, ConceptCapabilityCreate)
              .withRole(ConceptGroupCapability_Role_ConceptDefinition, Group)
              .updateObject({});
          });

        const conceptDecoratedList = ranker.decorateList(getObject(ConceptRole).navigateBack(Instance_Of)
          .sort((a, b) => compareRank(a[Concept_SwimlaneRank] as string, b[Concept_SwimlaneRank] as string)), (item) => item[Concept_SwimlaneRank] as string);

        createConceptRole(
          objectStore,
          Group,
          'Leader',
          true,
          false,
          [ConceptCapabilityEdit, ConceptCapabilityAssignUser, ConceptCapabilityDelete, ConceptCapabilityAssignCollaborator],
          conceptDecoratedList.insertAfterLastItemRank()
        );
      },
    },
  },
});
