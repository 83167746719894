import composeReactRefs from '@seznam/compose-react-refs';
import type { ReactNode, RefObject } from 'react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { ColorService } from 'react-color-palette';
import type { Location } from 'slate';
import { Editor, Range, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { RichTextMarks } from 'yooi-utils';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { remToPx } from '../../../utils/sizeUtils';
import { OverlayContextProvider } from '../../../utils/useOverlayContainerRef';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import { IconName } from '../../atoms/Icon';
import ColorPicker, { ColorPaletteVariant, ColorPickerVariant } from '../../inputs/ColorPicker';
import Chooser from '../Chooser';
import Overlay from '../Overlay';

const useStyles = makeStyles({
  actionMenuContainer: {
    position: 'absolute',
    zIndex: 1,
    marginTop: '-0.6rem',
    transition: ' opacity 0.75s',
  },
  mainMenuContainer: {
    display: 'flex',
    gap: '0.4rem',
    alignItems: 'flex-start',
  },
  subMenuContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '0.4rem',
  },
  selectionDiv: {
    pointerEvents: 'none',
    position: 'absolute',
    linHeight: '0.7rem',
    opacity: 0,
  },
}, 'richTextToolbar');

const isMarkActive = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor) as { [key: string]: unknown };
  return marks ? Boolean(marks[format]) : false;
};

const getMarkValue = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor) as { [key: string]: unknown };
  return marks ? marks[format] : undefined;
};

export const toggleMark = (editor: Editor, format: string): void => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const updateMark = (editor: Editor, format: string, value: unknown): void => {
  if (value === undefined) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, value);
  }
};

interface ActionMenuProps {
  children: ReactNode,
}

const ActionMenu = forwardRef<HTMLDivElement, ActionMenuProps>(({ children }, ref) => {
  const classes = useStyles();

  return (
    <div
      ref={ref}
      className={classes.actionMenuContainer}
    >
      {children}
    </div>
  );
});

interface RichTextToolbarProps {
  containerRef: RefObject<HTMLElement | undefined> | undefined,
}

const RichTextToolbar = forwardRef<HTMLDivElement, RichTextToolbarProps>(({ containerRef }, ref) => {
  const classes = useStyles();
  const editor = useSlate();

  const selectionRef = useRef<HTMLDivElement>(null);
  const actionMenuRef = useRef<HTMLDivElement>(null);

  const [target, setTarget] = useState<HTMLDivElement | null>(null);
  const targetRef = useRef(target);
  if (target !== targetRef.current) {
    targetRef.current = target;
  }

  const [showColorEditor, setShowColorEditor] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const element = selectionRef.current;
    if (!element) {
      setTarget(null);
      return;
    }
    if (
      !editor.selection
      || !ReactEditor.isFocused(editor)
      || Range.isCollapsed(editor.selection)
      || Editor.string(editor, editor.selection) === ''
    ) {
      setTarget(null);
    }
    if (editor.selection) {
      const domSelection = window.getSelection();
      const domRange = domSelection && domSelection.rangeCount > 0 ? domSelection?.getRangeAt(0) : undefined;
      const rect = domRange?.getBoundingClientRect() ?? { top: 0, left: 0, width: 0, bottom: 0 };
      const parent = containerRef?.current?.getBoundingClientRect() ?? { top: 0, left: 0, width: 0, bottom: 0 };
      element.style.top = `${rect.bottom - parent.top + element.offsetHeight}px`;
      element.style.width = `${rect.width}px`;
      element.style.left = `${rect.left - parent.left
      - element.offsetWidth / 2
      + rect.width / 2}px`;
      if (Math.floor(rect.width) > 0) {
        setTarget(element);
      }
    }
  });

  const handleClose = () => {
    setShowColorEditor(false);
    setTarget(null);
  };

  const actions = [
    { key: RichTextMarks.bold, icon: IconName.format_bold, tooltip: i18n`Bold` },
    { key: RichTextMarks.italic, icon: IconName.format_italic, tooltip: i18n`Italic` },
    { key: RichTextMarks.underline, icon: IconName.format_underlined, tooltip: i18n`Underline` },
    { key: RichTextMarks.code, icon: IconName.code, tooltip: i18n`Code` },
  ];

  const toggleFormat = (index: number) => {
    const format = actions[index].key;
    const { selection } = editor;
    ReactEditor.focus(editor);
    Transforms.select(editor, selection as Location);
    toggleMark(editor, format);
  };

  const selectionColor = getMarkValue(editor, RichTextMarks.color) as string | undefined;

  return (
    <>
      {target ? (
        <Overlay
          target={targetRef}
          onBackdropClick={handleClose}
          onEscapeKeyDown={handleClose}
          offset={[0, remToPx(1)]}
          isBoundary={false}
        >
          <SizeContextProvider sizeVariant={SizeVariant.main} hierarchyVariant={HierarchyVariant.content}>
            <ActionMenu ref={composeReactRefs(actionMenuRef, ref)}>
              <OverlayContextProvider containerRef={actionMenuRef}>
                <div className={classes.mainMenuContainer}>
                  <Chooser
                    actions={actions}
                    onClick={toggleFormat}
                    selectedIndexes={
                      actions.reduce((acc, { key }, index) => {
                        if (isMarkActive(editor, key)) {
                          return [...acc, index];
                        }
                        return acc;
                      }, [] as number[])
                    }
                    floating
                  />
                  <div className={classes.subMenuContainer}>
                    <Chooser
                      actions={[{ key: 'color', icon: IconName.format_color_text, tooltip: i18n`Color` }]}
                      onClick={() => {
                        setShowColorEditor((current) => !current);
                        const { selection } = editor;
                        ReactEditor.focus(editor);
                        Transforms.select(editor, selection as Location);
                        // Hack: Without an update here slate lose selection, so we remove a non-existing mark
                        Editor.removeMark(editor, 'notExisting');
                      }}
                      selectedIndexes={showColorEditor ? [0] : []}
                      floating
                    />
                    {showColorEditor && (
                      <ColorPicker
                        value={selectionColor ? ColorService.convert('hex', selectionColor) : undefined}
                        onChange={(color) => {
                          const { selection } = editor;
                          ReactEditor.focus(editor);
                          Transforms.select(editor, selection as Location);
                          updateMark(editor, RichTextMarks.color, color?.hex);
                        }}
                        variant={ColorPickerVariant.full}
                        colorPaletteVariant={ColorPaletteVariant.text}
                      />
                    )}
                  </div>
                </div>
              </OverlayContextProvider>
            </ActionMenu>
          </SizeContextProvider>
        </Overlay>
      ) : null}
      <div ref={selectionRef} className={classes.selectionDiv} />
    </>
  );
});

export default RichTextToolbar;
