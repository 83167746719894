import type { FunctionComponent, ReactElement } from 'react';
import { getConceptUrl, getInstanceLabel } from 'yooi-modules/modules/conceptModule';
import { joinObjects } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../../components/atoms/Button';
import { IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import InlineLink from '../../../../components/molecules/InlineLink';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import useStore from '../../../../store/useStore';
import base from '../../../../theme/base';
import { buildMargins, Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { formatOrUndef } from '../../../../utils/stringUtils';
import useNavigation from '../../../../utils/useNavigation';
import type { NavigationFilter } from '../../navigationUtils';
import TooltipContainer from '../../TooltipContainer';

const useStyles = makeStyles((theme) => ({
  spacingContainer: joinObjects(
    {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    buildMargins({ x: Spacing.s })
  ),
  tooltipTitleSeparator: {
    height: '0.1rem',
    backgroundColor: theme.color.background.neutral.muted,
  },
  colorSquare: {
    display: 'inline-flex',
    width: '1.2rem',
    height: '1.2rem',
    borderRadius: base.borderRadius.medium,
  },
}), 'conceptTooltip');

interface ConceptTooltipProps {
  conceptId: string,
  color?: string,
  buttonView?: boolean,
  editMode?: boolean,
  navigationFilters?: NavigationFilter,
  children: ReactElement | (ReactElement | null)[] | null,
}

const ConceptTooltip: FunctionComponent<ConceptTooltipProps> = ({ conceptId, color, buttonView = false, editMode = false, navigationFilters, children }) => {
  const classes = useStyles();

  const store = useStore();

  const navigation = useNavigation<NavigationFilter>();

  const concept = store.getObjectOrNull(conceptId);
  if (!concept) {
    return null;
  }
  const conceptUrl = getConceptUrl(store, conceptId);

  const instanceLabel = formatOrUndef(getInstanceLabel(store, concept));

  return (
    <TooltipContainer editMode={editMode} withShadow>
      <div className={classes.spacingContainer}>
        <Tooltip title={instanceLabel}>
          <SpacedContainer margin={{ right: Spacing.text }}>
            <SpacingLine>
              {color && (
                <span className={classes.colorSquare} style={{ background: color }} />
              )}
              <Typo noWrap>{instanceLabel}</Typo>
            </SpacingLine>
          </SpacedContainer>
        </Tooltip>
        {buttonView ? (
          <SpacingLine>
            <InlineLink {...navigation.createNavigationPayload(conceptId, { pathname: conceptUrl, navigationFilters })} noStyle>
              <Button
                variant={ButtonVariant.primary}
                iconName={IconName.output}
                title={i18n`Details`}
                tooltip={i18n`Open as page`}
              />
            </InlineLink>
          </SpacingLine>
        ) : null}
      </div>
      <div className={classes.tooltipTitleSeparator} />
      <SpacedContainer margin={Spacing.s}>
        {children}
      </SpacedContainer>
    </TooltipContainer>
  );
};

export default ConceptTooltip;
