import { getParentConceptInstance, isConceptValid, ParsedDimensionType, parseDimensionMapping } from 'yooi-modules/modules/conceptModule';
import type { Field_Title } from 'yooi-modules/modules/conceptModule/ids';
import { Concept_Resource, Field_ApiAlias, Field_Documentation, Field_IsDocumentationInline } from 'yooi-modules/modules/conceptModule/ids';
import type { DataAssetHierarchicalUsageFieldRaw, DataAssetHierarchicalUsageFieldStoreObject } from 'yooi-modules/modules/dataAssetModule';
import { dataAssetHierarchicalUsageFieldHandler } from 'yooi-modules/modules/dataAssetModule';
import { getResourcesToDataAssetsEmbeddingFieldInstanceId } from 'yooi-modules/modules/resourceModule';
import type { StoreObject } from 'yooi-store';
import { compareNumber, comparing, joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import { TableSortDirection } from '../../../../components/molecules/Table';
import i18n from '../../../../utils/i18n';
import { buildInstancesTree } from '../../conceptUtils';
import { getApiAliasEditionOption, getApiAliasInitialState, getDocumentationFieldEditionSection } from '../_global/editionHandlerUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import { FIELD_EDITION_DIMENSIONS, getFieldDimensionsEditionHandlerValue } from '../fieldDimensionUtils';
import type { FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, FieldComparatorHandler, GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode } from '../FieldLibraryTypes';
import DataAssetHierarchicalUsageField from './DataAssetHierarchicalUsageField';

interface DataAssetHierarchicalUsageFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
}

type DataAssetHierarchicalFieldDefinition = GetFieldDefinitionHandler<typeof dataAssetHierarchicalUsageFieldHandler, DataAssetHierarchicalUsageFieldConfigurationState>;

export const dataAssetHierarchicalUsageFieldDefinition: DataAssetHierarchicalFieldDefinition = registerFieldDefinition(
  dataAssetHierarchicalUsageFieldHandler,
  {
    configuration: {
      typeIcon: IconName.subject,
      getTypeLabel: () => i18n`Hierarchical usages`,
      asWidget: false,
      getEditionOptions: (objectStore) => ({ mode, editionHandler }) => {
        if (mode !== FieldEditionOptionMode.Field && mode !== FieldEditionOptionMode.FieldDeveloperMode) {
          return [];
        }

        const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

        sections.push(getDocumentationFieldEditionSection(editionHandler));

        if (mode === FieldEditionOptionMode.FieldDeveloperMode) {
          sections.push({
            key: 'integration',
            type: 'section',
            title: i18n`Integration`,
            options: [getApiAliasEditionOption(objectStore, editionHandler)],
          });
        }

        return sections;
      },
      ofField: (objectStore, fieldId) => ({
        getInitialState: (conceptDefinitionId) => {
          const field = objectStore.getObject<DataAssetHierarchicalUsageFieldStoreObject>(fieldId);
          return joinObjects(
            getApiAliasInitialState(objectStore, fieldId),
            {
              [Field_Documentation]: field[Field_Documentation],
              [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
              [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
            }
          );
        },
        submitFieldUpdate: (stateToSubmit) => {
          objectStore.updateObject<DataAssetHierarchicalUsageFieldRaw>(fieldId, {
            [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
            [Field_Documentation]: stateToSubmit[Field_Documentation],
            [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          });
        },
      }),
    },
    getAdditionalBlockFieldProps: () => () => ({ isVertical: true, fullWidth: true, hideOverflowX: true }),
    renderField: () => ({ dimensionsMapping, focusOnMount }) => {
      const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
      if (parsedDimensionMapping.type !== ParsedDimensionType.MonoDimensional) {
        return null;
      }

      return (<DataAssetHierarchicalUsageField dataAssetId={parsedDimensionMapping.objectId} focusOnMount={focusOnMount} />);
    },
    getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({
      propertyId: fieldId,
      sortable: true,
      focusable: true,
    }),
    getComparatorHandler: (store) => (direction) => ({
      comparator: comparing(compareNumber, direction === TableSortDirection.desc),
      extractValue: (dimensionsMapping) => {
        const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
        if (parsedDimensionMapping.type === ParsedDimensionType.MultiDimensional) {
          return undefined;
        }

        const conceptInstance = store.getObject(parsedDimensionMapping.objectId);
        const nodes = conceptInstance.navigateBack(getResourcesToDataAssetsEmbeddingFieldInstanceId(store))
          .flatMap((resourceInstance) => resourceInstance.navigateBack(Concept_Resource))
          .filter((reference) => isConceptValid(store, reference.id));
        return buildInstancesTree<StoreObject>(
          nodes,
          (node) => getParentConceptInstance(node) ?? undefined
        ).length || undefined;
      },
    } satisfies FieldComparatorHandler<number | undefined>),
  }
);
