// This file is automatically updated by the generate-constants script. Do not update it manually.

export const DataAssetModuleId = '6e754971-6de9-5a92-b4bf-4feea728cff4';
export const DataAsset = 'e731d7f3-bac8-5ccd-847a-3d191f0b1b9d';
export const DataAsset_Type = '5559e607-39b2-51a5-9370-d00bd7de1f5e';
export const DataAssetHierarchicalUsageField = '91baed08-b0dc-52c4-bb18-9d054d183f58';
export const DataAssetHierarchicalUsageFieldInstance = '4aadd20a-ed1b-5ca5-90f5-e6616ade0df0';
export const DataAssetHierarchicalUsageFieldInstanceDimension = '65188d27-5269-41dc-909c-b7679beed434';
export const DataAssetType = '8ae83562-d623-5ee5-9d0f-b2282fd0fe57';
export const DataAssetType_DataAssets = 'e30838fe-adbb-5ef8-8713-a63326df2438';
export const DataAssetType_DefaultResourceType = '92963509-aae8-5ffd-8f3e-38ccdb78d1c2';
export const DataAssetType_Description = '0d00429d-1c73-5437-a343-8bb5f6ca0258';
export const DataAssetType_IsDefaultType = 'c5343732-09b0-5ed1-a246-9a2b6f70930c';
export const DataAssetType_Name = '50f81021-e08b-5de9-a189-934c90c31c86';
export const DataAssetTypeDimension = '23a99545-e40e-4584-ad15-7bcd05724c69';
export const DataAssetTypeResourceTypes = 'f3cb0f15-adf1-5704-b9a1-ed827579cc5f';
export const DataAssetTypeResourceTypes_Role_DataAssetType = 0;
export const DataAssetTypeResourceTypes_Role_ResourceType = 1;
