import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { forwardRef, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import base, { Opacity } from '../../../../../theme/base';
import { hexColorWithAlpha } from '../../../../../theme/colorUtils';
import { buildPadding, getSpacing, Spacing } from '../../../../../theme/spacingDefinition';
import makeStyles from '../../../../../utils/makeStyles';
import useDerivedState from '../../../../../utils/useDerivedState';
import type { DraggedItem } from './types';

const useStyles = makeStyles((theme) => ({
  dropContainer: {
    ...buildPadding({ x: Spacing.s, top: Spacing.s }),
  },
  draggedContainer: {
    backgroundColor: hexColorWithAlpha(theme.color.background.neutral.dark, base.opacity[Opacity.five]),
    borderRadius: base.borderRadius.medium,
  },
  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    opacity: 1,
    gap: getSpacing(Spacing.s),
  },
  draggable: {
    cursor: 'grab',
  },
  dropImage: {
    outline: `0.2rem dashed ${theme.color.border.primary}`,
    borderRadius: base.borderRadius.medium,
  },
  dragInnerContainer: { opacity: 0 },
}), 'viewConceptSwimlaneDraggableItem');

interface DraggableItemV2ChildrenProps {
  isDragging: boolean,
}

interface SwimlaneV2DraggableItemProps {
  instanceId: string,
  canDragItem?: boolean,
  canDrop: (instanceId: string) => boolean,
  onDrop: (instanceId: string) => void,
  type: string,
  children: FunctionComponent<DraggableItemV2ChildrenProps>,
}

const ViewConceptSwimlaneDraggableItem = forwardRef<HTMLDivElement, SwimlaneV2DraggableItemProps>(({
  instanceId,
  canDragItem = false,
  canDrop,
  onDrop,
  type,
  children,
}, ref) => {
  const classes = useStyles();

  const containerRef = useRef<HTMLDivElement>(null);

  const [{ isDragging }, dragRef] = useDrag<DraggedItem, unknown, { isDragging: boolean }>(() => ({
    type,
    item: () => ({ instanceId, height: containerRef.current?.clientHeight ?? 0 }),
    end: () => {},
    canDrag: canDragItem,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [{ isOver, draggedElementHeight }, drop] = useDrop<DraggedItem | null, unknown, { isOver: boolean, draggedElementHeight: number | undefined }>(
    () => ({
      accept: type,
      canDrop: (item) => item?.instanceId !== undefined && canDrop(item.instanceId),
      drop: (item, monitor) => {
        if (item?.instanceId !== undefined && !monitor.didDrop()) {
          onDrop(item.instanceId);
        }
      },
      collect: (monitor) => {
        const item = monitor.getItem();
        return {
          isOver: item?.instanceId !== undefined && monitor.isOver() && canDrop(item.instanceId),
          draggedElementHeight: item?.height,
        };
      },
    }),
    [onDrop, canDrop]
  );
  const [isOverState] = useDerivedState(() => isOver && !isDragging, [isOver]);

  return (
    <div ref={(node) => (drop(node))} className={classes.dropContainer}>
      <div ref={(node) => (dragRef(node))} className={classnames({ [classes.draggedContainer]: isDragging })}>
        <div
          ref={composeReactRefs<HTMLDivElement>(containerRef, ref)}
          className={classnames({
            [classes.innerContainer]: !isDragging,
            [classes.dragInnerContainer]: isDragging,
            [classes.draggable]: canDragItem,
          })}
        >
          {isOverState && <div className={classes.dropImage} style={{ height: draggedElementHeight }} />}
          {children({ isDragging })}
        </div>
      </div>
    </div>
  );
});

export default ViewConceptSwimlaneDraggableItem;
