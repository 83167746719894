import type { FunctionComponent, ReactElement, Ref } from 'react';
import { getConceptUrl, getFieldDimensionOfModelType, getFieldUtilsHandler } from 'yooi-modules/modules/conceptModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ProgressFieldData } from '../../../../../components/charts/TimelineEntry';
import TimelineEntry from '../../../../../components/charts/TimelineEntry';
import useAcl from '../../../../../store/useAcl';
import useActivity from '../../../../../store/useActivity';
import useStore from '../../../../../store/useStore';
import { Spacing } from '../../../../../theme/spacingDefinition';
import { remToPx } from '../../../../../utils/sizeUtils';
import useNavigation from '../../../../../utils/useNavigation';
import type { NavigationFilter } from '../../../navigationUtils';

const timelineEntryWidthInRem = 28;

interface Data {
  id: string,
  label: { id?: string, name: string | undefined },
  rightText?: string | undefined,
  progress?: ProgressFieldData | undefined,
}

interface ConceptItemProps {
  instance: Data,
  renderTooltip: (conceptId: string, editMode: boolean, currentAnchor: HTMLElement, handleClose: () => void, isChild?: boolean) => ReactElement | null,
  readOnly?: boolean,
  multiplayerPropertyIds: string[],
  conceptRefFunc?: Ref<HTMLDivElement>,
  rectColor: string | null | undefined,
  dragLabelFieldId?: string,
  onElementDrag?: ({ id }: { id: string }) => void,
  onElementDragEnd?: ({ id }: { id: string }) => void,
  width?: number,
  noMargin?: boolean,
  inEdition?: boolean,
  onEditionStart?: () => void,
  onEditionEnd?: () => void,
}

const ConceptItem: FunctionComponent<ConceptItemProps> = ({
  instance,
  renderTooltip,
  readOnly = false,
  multiplayerPropertyIds,
  conceptRefFunc,
  rectColor,
  dragLabelFieldId,
  onElementDrag,
  onElementDragEnd,
  width,
  noMargin = false,
  inEdition,
  onEditionStart,
  onEditionEnd,
}) => {
  const store = useStore();
  const { canWriteObject } = useAcl();
  const activity = useActivity();

  const navigation = useNavigation<NavigationFilter>();

  const { id, rightText, label, progress } = instance;

  const navigateToConcept = (conceptId: string) => {
    const conceptUrl = getConceptUrl(store, conceptId);
    navigation.push(conceptId, { pathname: conceptUrl });
  };

  let dragLabel;
  if (dragLabelFieldId) {
    const objectInstance = store.getObjectOrNull(id);
    const dimension = objectInstance ? getFieldDimensionOfModelType(store, dragLabelFieldId, objectInstance[Instance_Of] as string) : undefined;
    dragLabel = getFieldUtilsHandler(store, dragLabelFieldId)?.getValueResolution?.(dimension ? { [dimension]: id } : {})?.getDisplayValue() as string;
  }

  return (
    <div ref={conceptRefFunc}>
      <TimelineEntry
        id={id}
        isDraggable={!!(canWriteObject(id) && !readOnly && onElementDrag)}
        onElementDrag={canWriteObject(id) && !readOnly ? onElementDrag : undefined}
        onElementDragEnd={onElementDragEnd}
        dragLabel={dragLabel}
        margin={!noMargin ? { right: Spacing.xl, y: Spacing.xs } : {}}
        renderTooltip={renderTooltip}
        rectColor={rectColor}
        rightText={rightText}
        label={label}
        progress={progress}
        widthInPx={width ?? remToPx(timelineEntryWidthInRem)}
        isEditedByOtherUser={multiplayerPropertyIds && multiplayerPropertyIds.some((propertyId) => activity.listEditor(id, propertyId).length > 0)}
        onDoubleClick={navigateToConcept}
        inEdition={inEdition}
        onEditionStart={onEditionStart}
        onEditionEnd={onEditionEnd}
      />
    </div>
  );
};

export default ConceptItem;
