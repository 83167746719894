import type { FunctionComponent } from 'react';
import type { ConceptDefinitionStoreObject } from 'yooi-modules/modules/conceptModule';
import { isConceptDefinitionField } from 'yooi-modules/modules/conceptModule';
import {
  ConceptDefinition_LibraryShowMatrix,
  ConceptDefinition_MatrixAbs,
  ConceptDefinition_MatrixDependency,
  ConceptDefinition_MatrixLabel,
  ConceptDefinition_MatrixOrd,
} from 'yooi-modules/modules/conceptModule/ids';
import Checkbox from '../../../../../components/atoms/Checkbox';
import { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import BlockContent from '../../../../../components/templates/BlockContent';
import BlockTitle from '../../../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../../../components/templates/HorizontalBlock';
import useStore from '../../../../../store/useStore';
import i18n from '../../../../../utils/i18n';
import { getFieldChip } from '../../../../_global/fieldUtils';
import { listDependenciesFieldOptions, listLabelByFieldOptions, listMatrixFieldOptions } from '../../../../_global/modelTypeUtils';

interface MatrixEditorProps {
  conceptDefinitionId: string,
}

const MatrixEditor: FunctionComponent<MatrixEditorProps> = ({ conceptDefinitionId }) => {
  const store = useStore();

  const conceptDefinition = store.getObject<ConceptDefinitionStoreObject>(conceptDefinitionId);

  const defaultMatrixOrdId = conceptDefinition.navigateOrNull(ConceptDefinition_MatrixOrd)?.id;
  const defaultMatrixAbsId = conceptDefinition.navigateOrNull(ConceptDefinition_MatrixAbs)?.id;
  const defaultMatrixDependencyId = conceptDefinition.navigateOrNull(ConceptDefinition_MatrixDependency)?.id;
  const defaultMatrixLabelId = conceptDefinition.navigateOrNull(ConceptDefinition_MatrixLabel)?.id;

  return (
    <>
      <HorizontalBlock asBlockContent>
        <BlockTitle
          title={i18n`Show matrix`}
          infoTooltip={i18n`Show / hide library matrix tab.`}
        />
        <BlockContent padded>
          <SpacingLine>
            <Checkbox
              checked={Boolean(conceptDefinition[ConceptDefinition_LibraryShowMatrix])}
              onChange={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_LibraryShowMatrix]: value })}
            />
          </SpacingLine>
        </BlockContent>
      </HorizontalBlock>
      {Boolean(conceptDefinition[ConceptDefinition_LibraryShowMatrix]) && (
        <>
          <HorizontalBlock asBlockContent>
            <BlockTitle
              title={i18n`Default field for Y-axis`}
              infoTooltip={i18n`Numeric field used for Y-Axis for each instance on the matrix, by default`}
            />
            <BlockContent>
              <SearchAndSelect
                clearable
                placeholder={i18n`Select field`}
                selectedOption={
                  defaultMatrixOrdId && store.getObjectOrNull(defaultMatrixOrdId) !== null
                    ? getFieldChip(store, conceptDefinitionId, defaultMatrixOrdId)
                    : undefined
                }
                computeOptions={() => listMatrixFieldOptions(store, conceptDefinitionId)}
                onSelect={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_MatrixOrd]: value?.id ?? null })}
                statusIcon={
                  !defaultMatrixOrdId || isConceptDefinitionField(store, defaultMatrixOrdId, conceptDefinitionId)
                    ? undefined : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
                }
              />
            </BlockContent>
          </HorizontalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle
              title={i18n`Default field for X-axis`}
              infoTooltip={i18n`Numeric field used for X-Axis for each instance on the matrix, by default`}
            />
            <BlockContent>
              <SearchAndSelect
                clearable
                placeholder={i18n`Select field`}
                selectedOption={
                  defaultMatrixAbsId && store.getObjectOrNull(defaultMatrixAbsId) !== null
                    ? getFieldChip(store, conceptDefinitionId, defaultMatrixAbsId)
                    : undefined
                }
                computeOptions={() => listMatrixFieldOptions(store, conceptDefinitionId)}
                onSelect={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_MatrixAbs]: value?.id ?? null })}
                statusIcon={
                  !defaultMatrixAbsId || isConceptDefinitionField(store, defaultMatrixAbsId, conceptDefinitionId)
                    ? undefined : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
                }
              />
            </BlockContent>
          </HorizontalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle
              title={i18n`Default field for dependency`}
              infoTooltip={i18n`Relation used to display the dependencies of each instance on the matrix, by default`}
            />
            <BlockContent>
              <SearchAndSelect
                clearable
                placeholder={i18n`Select field`}
                selectedOption={
                  defaultMatrixDependencyId && store.getObjectOrNull(defaultMatrixDependencyId) !== null
                    ? getFieldChip(store, conceptDefinitionId, defaultMatrixDependencyId) : undefined
                }
                computeOptions={() => listDependenciesFieldOptions(store, conceptDefinitionId)}
                onSelect={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_MatrixDependency]: value?.id ?? null })}
                statusIcon={
                  !defaultMatrixDependencyId || isConceptDefinitionField(store, defaultMatrixDependencyId, conceptDefinitionId)
                    ? undefined : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
                }
              />
            </BlockContent>
          </HorizontalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle
              title={i18n`Default field for label`}
              infoTooltip={i18n`Field used to display the label of each instance on the matrix, by default`}
            />
            <BlockContent>
              <SearchAndSelect
                clearable
                placeholder={i18n`Select field`}
                selectedOption={
                  defaultMatrixLabelId
                  && store.getObjectOrNull(defaultMatrixLabelId) !== null ? getFieldChip(store, conceptDefinitionId, defaultMatrixLabelId) : undefined
                }
                computeOptions={() => listLabelByFieldOptions(store, conceptDefinitionId)}
                onSelect={(value) => store.updateObject(conceptDefinitionId, { [ConceptDefinition_MatrixLabel]: value?.id ?? null })}
                statusIcon={
                  !defaultMatrixLabelId || isConceptDefinitionField(store, defaultMatrixLabelId, conceptDefinitionId)
                    ? undefined : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
                }
              />
            </BlockContent>
          </HorizontalBlock>
        </>
      )}
    </>
  );
};

export default MatrixEditor;
