import type { ComponentProps, ReactElement } from 'react';
import { isFiniteNumber } from 'yooi-utils';
import Checkbox from '../../../components/atoms/Checkbox';
import { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SearchAndSelectMultiple from '../../../components/molecules/SearchAndSelectMultiple';
import SpacingLine from '../../../components/molecules/SpacingLine';
import i18n from '../../../utils/i18n';
import StoreColorPickerInput from '../input/StoreColorPickerInput';
import StoreDateRangeInput from '../input/StoreDateRangeInput';
import StoreNumberPickerInput from '../input/StoreNumberPickerInput';
import StoreTextInputField from '../input/StoreTextInputField';
import PathInput from '../path/PathInput';
import type PathStepsInput from '../path/PathStepsInput';
import type { StepOption } from './_global/pathUtils';
import type {
  ColorInputProps,
  DateRangeInputProps,
  EditionOption,
  NumberInputProps,
  SearchAndSelectInputProps,
  SearchAndSelectMultipleInputProps,
  TextInputProps,
} from './FieldEditionOptionType';
import { EditionOptionTypes } from './FieldEditionOptionType';

const getColorRender = ({ value, onChange, colorPalette, readOnly }: ColorInputProps): ReactElement => (
  <StoreColorPickerInput
    initialValue={value}
    onSubmit={(newColor) => onChange(newColor ?? null)}
    readOnly={readOnly}
    colorPalette={colorPalette}
  />
);

const getNumberRender = ({ value, onChange, placeholder, min, max, readOnly }: NumberInputProps): ReactElement => (
  <StoreNumberPickerInput
    initialValue={value}
    onSubmit={(val) => {
      if (val !== null && !isFiniteNumber(val)) {
        return;
      }
      onChange(typeof val === 'string' ? Number(val) : val);
    }}
    placeholder={placeholder}
    max={max}
    min={min}
    readOnly={readOnly}
    withDecimals={false}
  />
);

const getDateRangeRender = ({ value, placeholder, onChange, readOnly }: DateRangeInputProps): ReactElement => (
  <StoreDateRangeInput
    initialValue={value}
    onSubmit={(val) => onChange(val ?? null)}
    placeholder={placeholder}
    withPredefinedRanges
    withLastAndNext
    withStartConstraint={false}
    readOnly={readOnly}
  />
);

const getTextRender = ({ value, onChange, maxLine, ...otherProps }: TextInputProps): ReactElement => (
  <StoreTextInputField
    initialValue={value}
    maxLine={maxLine}
    dropdownMaxLine={maxLine}
    onSubmit={(val) => onChange(val ?? null)}
    {...otherProps}
  />
);

const getCheckboxRender = (props: Parameters<typeof Checkbox>[0]) => (
  <SpacingLine>
    <Checkbox {...props} />
  </SpacingLine>
);

const getSelectRender = ({ readOnly, computeOptions, selectedOption, onChange, placeholder, clearable, required, getInlineCreation }: SearchAndSelectInputProps): ReactElement => (
  <SearchAndSelect
    readOnly={readOnly}
    computeOptions={computeOptions}
    selectedOption={selectedOption}
    onSelect={(val) => onChange(val)}
    placeholder={placeholder}
    clearable={clearable}
    statusIcon={required && !selectedOption ? { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Required` } : undefined}
    getInlineCreation={getInlineCreation}
  />
);

const getSelectMultipleRender = ({
  selectedSteps,
  selectedOptions,
  computeOptions,
  placeholder,
  onSelect,
  onDelete,
  error,
  readOnly,
}: SearchAndSelectMultipleInputProps<StepOption>): ReactElement => (
  <SearchAndSelectMultiple<StepOption>
    computeOptions={computeOptions}
    selectedOptions={selectedOptions}
    selectedSteps={selectedSteps}
    placeholder={placeholder}
    onSelect={onSelect}
    onDelete={onDelete}
    error={error}
    readOnly={readOnly}
  />
);

const getPathStepsRender = ({
  initialPath,
  parameterDefinitions,
  placeholder,
  onSubmit,
  readOnly,
  valuePathHandler,
  suggestedBasePaths,
}: ComponentProps<typeof PathStepsInput>): ReactElement => (
  <PathInput
    path={initialPath}
    parameterDefinitions={parameterDefinitions}
    placeholder={placeholder}
    onChange={onSubmit}
    readOnly={readOnly}
    valuePathHandler={valuePathHandler}
    suggestedBasePaths={suggestedBasePaths}
  />
);

export const getFieldEditionOptionRender = (option: EditionOption): ReactElement | null => {
  switch (option.type) {
    case EditionOptionTypes.checkbox:
      return getCheckboxRender(option.props);
    case EditionOptionTypes.color:
      return getColorRender(option.props);
    case EditionOptionTypes.custom:
      return option.props.render();
    case EditionOptionTypes.dateRange:
      return getDateRangeRender(option.props);
    case EditionOptionTypes.number:
      return getNumberRender(option.props);
    case EditionOptionTypes.select:
      return getSelectRender(option.props);
    case EditionOptionTypes.selectMultiple:
      return getSelectMultipleRender(option.props);
    case EditionOptionTypes.text:
      return getTextRender(option.props);
    case EditionOptionTypes.path:
      return getPathStepsRender(option.props);
    default:
      return null;
  }
};
