import classnames from 'classnames';
import type { FunctionComponent, MouseEvent } from 'react';
import { useCallback, useRef, useState } from 'react';
import type { RichText } from 'yooi-utils';
import { joinObjects, RichTextElementTypes, richTextToText, sanitizeRichText } from 'yooi-utils';
import base from '../../theme/base';
import { getSpacing, Spacing, spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import useSizeContext, { buildInputSizeVariantMinusBorderClasses, SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import useUsageContext, { UsageVariant } from '../../utils/useUsageContext';
import Icon, { IconColorVariant, IconName } from '../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../atoms/IconOnlyButton';
import type { TypoVariant } from '../atoms/Typo';
import { sizeVariantToTypoVariant, typoMaxWidth } from '../atoms/Typo';
import EditableWithDropdown, { EditableCloseReasons } from '../molecules/EditableWithDropdown';
import type { RichTextAlign, RichTextEditorRef } from '../molecules/RichTextEditor';
import RichTextEditor from '../molecules/RichTextEditor';

const useStyles = makeStyles((theme) => (joinObjects(
  {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      borderRadius: base.borderRadius.medium,
    },
    inputContainer: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      outline: 'none',
      boxShadow: 'none',
      resize: 'none',
      backgroundColor: theme.color.transparent,
      borderRadius: base.borderRadius.medium,
      verticalAlign: 'top',
      '& > textarea': {
        margin: spacingRem.none,
      },
    },
    [UsageVariant.inline]: {
      '& ::placeholder': {
        color: theme.color.text.disabled,
      },
    },
    [UsageVariant.inTable]: {
      border: 0,
      backgroundColor: theme.color.transparent,
      '& ::placeholder': {
        color: theme.color.text.disabled,
      },
    },
    [UsageVariant.inCard]: {
      '& ::placeholder': {
        color: theme.color.text.disabled,
      },
    },
    [UsageVariant.inForm]: {
      '& ::placeholder': {
        color: theme.color.text.disabled,
      },
      '&:hover': {
        backgroundColor: theme.color.background.neutral.default,
      },
      '&:focus-within': {
        backgroundColor: theme.color.background.neutral.default,
      },
    },
    secondaryPlaceholder: {
      '& ::placeholder': {
        color: theme.color.text.disabled,
      },
    },
    disabled: {
      borderColor: theme.color.border.default,
      color: theme.color.text.disabled,
      pointerEvents: 'none',
    },
    withOutline: {
      borderColor: theme.color.border.default,
    },
    error: {
      borderColor: theme.color.border.danger,
      '&:active, &:focus, &:focus-within': {
        borderColor: theme.color.border.danger,
      },
      '&:hover': {
        borderColor: theme.color.border.danger,
      },
    },
    errorSpacer: {
      minWidth: '0.4rem',
    },
    regularContainer: {
      flexGrow: 1,
      overflow: 'hidden',
      cursor: 'text',
    },
  },
  theme.font,
  buildInputSizeVariantMinusBorderClasses('container', ['minHeight'])
)), 'richTextInput');

interface RichTextInputProps {
  value?: RichText | undefined,
  placeholder?: string,
  onSubmit?: (value: RichText | undefined) => void,
  onChange?: ((value: RichText | undefined) => void),
  onCancel?: () => void,
  onClear?: (event: MouseEvent<HTMLButtonElement>) => void,
  disabled?: boolean,
  error?: string | boolean,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  maxLine?: number,
  dropdownMaxLine?: number,
  focusOnMount?: boolean,
  readOnly?: boolean,
  isEditing?: boolean,
  preventRichFeatures?: boolean,
  align?: RichTextAlign,
  dropdownSizes?: { sameWidth: boolean },
  restingTooltip?: string | (() => Promise<string>),
  variant?: TypoVariant,
}

const RichTextInput: FunctionComponent<RichTextInputProps> = ({
  value,
  onSubmit,
  onChange,
  onCancel,
  onClear,
  readOnly = false,
  onEditionStart,
  onEditionStop,
  placeholder,
  disabled = false,
  error,
  align,
  maxLine: maxLineProp,
  dropdownMaxLine: dropdownMaxLineProp,
  focusOnMount = false,
  isEditing = false,
  preventRichFeatures,
  dropdownSizes,
  restingTooltip,
  variant,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const { sizeVariant } = useSizeContext();

  const usageVariant = useUsageContext();
  const isCardVariant = usageVariant === UsageVariant.inCard;
  const isTableVariant = usageVariant === UsageVariant.inTable;
  const isInFormVariant = usageVariant === UsageVariant.inForm;

  const containerRef = useRef<HTMLDivElement>(null);

  const [editMode, setEditMode] = useState(Boolean(focusOnMount));

  const maxLine = maxLineProp ?? (isTableVariant || isCardVariant ? 1 : undefined);
  const dropdownMaxLine = dropdownMaxLineProp ?? (isTableVariant || isCardVariant ? 5 : undefined);

  const sanitizedValue = sanitizeRichText(value);
  const onChangeValidated = useCallback((commit: boolean) => {
    setEditMode(false);
    if (commit && !readOnly) {
      onSubmit?.(sanitizedValue);
    } else {
      onCancel?.();
    }
    if (!readOnly) {
      onEditionStop?.();
    }
  }, [onCancel, onEditionStop, onSubmit, readOnly, sanitizedValue]);

  const onClearClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    onClear?.(event);
    containerRef.current?.focus();
  }, [onClear]);
  const outline = isInFormVariant && !readOnly;
  const hasOutline = Boolean(outline);
  const placeholderColor = theme.color.text.disabled;
  const isEmptyValue = !sanitizedValue || richTextToText(sanitizedValue) === '';

  const computePadding = (inDropdown: boolean) => {
    let padding;
    if (inDropdown && isTableVariant) {
      padding = `${spacingRem.s} ${spacingRem.s} ${spacingRem.s} 0.8rem`;
    } else if (isTableVariant) {
      padding = `0.8rem ${spacingRem.s}`;
    } else {
      padding = `0.4rem ${spacingRem.s}`;
    }
    return padding;
  };

  const richTextEditorRef = useRef<RichTextEditorRef | null>(null);

  const appliedVariant = variant ?? sizeVariantToTypoVariant[sizeVariant];
  const maxWidth = typoMaxWidth[appliedVariant] ? `calc(${typoMaxWidth[appliedVariant]} + ${getSpacing(Spacing.s /* left padding */, Spacing.s /* right padding */)})` : undefined;

  const render = (inDropdown: boolean) => (
    <div
      className={classnames({
        [classes.container]: true,
        [classes.withOutline]: hasOutline,
        [classes[usageVariant]]: true,
      })}
    >
      <div
        tabIndex={-1}
        ref={containerRef}
        className={classnames({
          [classes.inputContainer]: true,
          [classes[`container_${sizeVariant}`]]: true,
          [classes.error]: error,
          [classes.disabled]: disabled,
          [classes.secondaryPlaceholder]: outline,
        })}
        style={{ padding: computePadding(inDropdown) }}
      >
        <div className={classes.regularContainer}>
          <RichTextEditor
            readOnly={readOnly || !inDropdown}
            placeholder={usageVariant !== UsageVariant.inTable && !readOnly ? placeholder : undefined}
            value={sanitizedValue ?? [{ type: RichTextElementTypes.paragraph, children: [{ text: '' }] }]}
            maxRows={inDropdown && dropdownMaxLine ? dropdownMaxLine : maxLine}
            align={align}
            preventRichFeatures={preventRichFeatures}
            color={usageVariant !== UsageVariant.inTable && isEmptyValue ? placeholderColor : undefined}
            initialSelection={inDropdown ? richTextEditorRef.current?.getSelection() : undefined}
            containerRef={containerRef}
            onChange={(val) => {
              if (onChange && !readOnly) {
                onChange(val);
              }
            }}
            richTextEditorRef={inDropdown ? null : richTextEditorRef}
            focusOnMount={inDropdown}
            variant={appliedVariant}
          />
        </div>
        {(onClear && sanitizedValue) && (
          <IconOnlyButton
            sizeVariant={SizeVariant.small}
            variant={IconOnlyButtonVariants.tertiary}
            iconName={IconName.close}
            tooltip={i18n`Clear`}
            onClick={onClearClick}
          />
        )}
        {error && (
          <>
            <div className={classes.errorSpacer} />
            <Icon
              name={IconName.dangerous}
              tooltip={typeof error === 'string' ? error : ''}
              colorVariant={IconColorVariant.error}
            />
          </>
        )}
      </div>
    </div>
  );

  return (
    <EditableWithDropdown
      readOnly={readOnly}
      renderValue={render}
      showDropdown={editMode}
      openDropdown={() => {
        if (!readOnly) {
          onEditionStart?.();
        }
        setEditMode(true);
      }}
      closeDropdown={(reason) => {
        onChangeValidated(reason !== EditableCloseReasons.onEscapeKeyDown);
        richTextEditorRef.current?.resetSelection();
      }}
      closeOnTabKeyDown
      validateOnEnterKeyDown
      variant={usageVariant}
      editableSizes={{
        flexGrow: 1,
        maxWidth,
      }}
      autoFocus
      dropdownSizes={{
        sameWidth: dropdownSizes ? dropdownSizes.sameWidth : true,
        maxWidth,
      }}
      withMultiplayerOutline={usageVariant === UsageVariant.inTable && isEditing}
      isEditing={usageVariant === UsageVariant.inCard && isEditing}
      restingTooltip={restingTooltip}
    />
  );
};

export default RichTextInput;
