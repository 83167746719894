// This file is automatically updated by the generate-constants script. Do not update it manually.

export const ResourceModuleId = '27250b91-b245-514d-a2e9-37f8d8e2e228';
export const Resource = '50da589a-4f05-58cd-bc40-56ecbc15617d';
export const Resource_Image = '85b83cc0-6625-57c8-bfc7-ae1f315727e2';
export const Resource_Type = '63af243f-45bd-5a2b-88ca-4b94dd2273b0';
export const Resource_URL = '367e53f0-06ee-5995-b59a-7f08a30e07cd';
export const ResourceConceptsDimension = 'f29f8825-4b5f-4274-8b59-4726e62ac961';
export const ResourceImageDimension = 'b6a2c15a-b005-48ea-a285-6f2eea8c5162';
export const ResourceType = 'f80337c0-43da-5de7-9bf8-3bcaeb27d9a3';
export const ResourceType_Description = 'c0f9f2fe-c593-5d11-8dfe-ff72472fcebe';
export const ResourceType_Name = '0fca9a8e-6c5f-575b-ac45-791a4e45d58f';
export const ResourceType_Resources = '5fb6a2dd-572c-5565-87e3-a6959b43d120';
export const ResourceTypeDimension = 'e5126cd5-a8df-4e44-846e-d955cefc3a4d';
export const ResourceURLDimension = '58105ed8-73fd-4bec-976c-b7883279ec61';
