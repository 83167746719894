import type { ScaleContinuousNumeric } from 'd3-scale';
import type { ReactElement } from 'react';
import { useCallback, useRef, useState } from 'react';
import base from '../../../theme/base';
import { buildMargins, Spacing, spacingRem } from '../../../theme/spacingDefinition';
import makeStyles from '../../../utils/makeStyles';
import { remToPx } from '../../../utils/sizeUtils';
import useTheme from '../../../utils/useTheme';
import Typo, { TypoVariant } from '../../atoms/Typo';
import QuadrantRectangle from './QuadrantRectangle';

const useStyles = makeStyles((theme) => ({
  line: {
    stroke: theme.color.border.info,
    strokeWidth: 1,
    strokeDasharray: '4 1',
  },
  valueContainer: {
    paddingLeft: spacingRem.none,
    paddingRight: spacingRem.none,
    paddingBottom: spacingRem.xxs,
    paddingTop: spacingRem.xxs,
    width: '4rem',
    height: '2.2rem',
    backgroundColor: theme.color.background.info.default,
    borderRadius: base.borderRadius.medium,
    textAlign: 'center',
  },
  typoContainer: buildMargins({ y: Spacing.none, x: Spacing.text }),
  dropZone: {
    width: '100%',
    height: '100%',
  },
}), 'quadrantDropZone');

interface QuadrantDropZoneProps<DragDataFormat extends string> {
  chartHeightPx: number,
  chartWidthPx: number,
  horizontalAxisOffsetPx: number,
  verticalAxisOffsetPx: number,
  rectSizePx: number,
  scaleX: ScaleContinuousNumeric<number, number>,
  scaleY: ScaleContinuousNumeric<number, number>,
  dragDataFormats: DragDataFormat[],
  onElementDrop: (dragData: Record<DragDataFormat, string>, position: { x: number, y: number }) => void,
  canDragX?: boolean,
  canDragY?: boolean,
  minX?: number,
  maxX?: number,
  minY?: number,
  maxY?: number,
}

const QuadrantDropZone = <DragDataFormat extends string>({
  chartHeightPx,
  chartWidthPx,
  horizontalAxisOffsetPx,
  verticalAxisOffsetPx,
  rectSizePx,
  scaleX,
  scaleY,
  dragDataFormats,
  onElementDrop,
  canDragX = true,
  canDragY = true,
  minX,
  maxX,
  minY,
  maxY,
}: QuadrantDropZoneProps<DragDataFormat>): ReactElement => {
  const theme = useTheme();
  const classes = useStyles();

  const dropZoneRef = useRef<HTMLDivElement>(null);
  const [dragPosition, setDragPosition] = useState<{ x: number, y: number }>();

  const positionToValueX = (position: number) => {
    const value = scaleX.invert(position);
    if (minX !== undefined && value < minX) {
      return minX;
    } else if (maxX !== undefined && value > maxX) {
      return maxX;
    }
    return Math.round(value);
  };

  const positionToValueY = (position: number) => {
    const value = scaleY.invert(position);
    if (minY !== undefined && value < minY) {
      return minY;
    } else if (maxY !== undefined && value > maxY) {
      return maxY;
    }
    return Math.round(value);
  };

  const handleDragOver = useCallback((mouseX: number, mouseY: number, dimensions: DOMRect) => {
    setDragPosition({
      x: (chartWidthPx / dimensions.width) * (mouseX - dimensions.x),
      y: (chartHeightPx / dimensions.height) * (mouseY - dimensions.y),
    });
  }, [chartHeightPx, chartWidthPx]);

  return (
    <>
      {
        dragPosition && canDragX
          ? (
            <>
              <line
                x1={dragPosition.x}
                y1={dragPosition.y}
                x2={dragPosition.x}
                y2={chartHeightPx + verticalAxisOffsetPx}
                className={classes.line}
              />
              <foreignObject
                width={remToPx(5)}
                height={remToPx(3)}
                transform={`translate (${dragPosition.x - 19} ${chartHeightPx + verticalAxisOffsetPx - 10})`}
              >
                <div className={classes.valueContainer}>
                  <div className={classes.typoContainer}>
                    <Typo variant={TypoVariant.small} color={theme.color.text.white}>{positionToValueX(dragPosition.x)}</Typo>
                  </div>
                </div>
              </foreignObject>
            </>
          )
          : null
      }
      {
        dragPosition && canDragY
          ? (
            <>
              <line
                x1={dragPosition.x}
                y1={dragPosition.y}
                x2={-horizontalAxisOffsetPx}
                y2={dragPosition.y}
                className={classes.line}
              />
              <foreignObject
                width={remToPx(5)}
                height={remToPx(3)}
                transform={`translate (${-horizontalAxisOffsetPx - 18} ${dragPosition.y - 10})`}
              >
                <div className={classes.valueContainer}>
                  <div className={classes.typoContainer}>
                    <Typo variant={TypoVariant.small} color={theme.color.text.white}>{positionToValueY(dragPosition.y)}</Typo>
                  </div>
                </div>
              </foreignObject>
            </>
          )
          : null
      }
      {
        dragPosition
          ? (
            <QuadrantRectangle
              x={dragPosition.x - rectSizePx / 2}
              y={dragPosition.y - rectSizePx / 2}
              size={rectSizePx}
              clickable
              selected
            />
          )
          : null
      }
      <foreignObject width={chartWidthPx} height={chartHeightPx}>
        <div
          ref={dropZoneRef}
          className={classes.dropZone}
          onDrop={(event) => {
            if (dragPosition) {
              const dragData = Object.fromEntries(dragDataFormats.map((format) => [format, event.dataTransfer.getData(format)])) as Record<DragDataFormat, string>;
              onElementDrop(dragData, { x: positionToValueX(dragPosition.x), y: positionToValueY(dragPosition.y) });
            }
          }}
          onDragOver={(event) => {
            // By default, data/elements cannot be dropped in other elements. To allow a drop, we must prevent the default handling of the element.
            event.preventDefault();
            if (dropZoneRef.current) {
              handleDragOver(event.clientX, event.clientY, dropZoneRef.current.getBoundingClientRect());
            }
          }}
          onDragLeave={() => {
            setDragPosition(() => undefined);
          }}
        />
      </foreignObject>
    </>
  );
};

export default QuadrantDropZone;
