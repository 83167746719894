import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { compareString, comparing, filterNullOrUndefined, isFiniteNumber, pushUndefinedToEnd } from 'yooi-utils';
import type { CollaborationMessageStoreObject, CollaborationStoreObject } from '../../collaborationModule';
import { buildCollaborationLink, getCollaborationAllUserRecipientsIds, getCollaborationContextsObjectIds, getIntentionLabel } from '../../collaborationModule';
import {
  Collaboration_Messages,
  Collaboration_Status,
  CollaborationMessage_Collaboration,
  CollaborationMessage_CreatedBy,
  CollaborationMessage_Text,
} from '../../collaborationModule/ids';
import type { ConceptDefinitionStoreObject } from '../../conceptModule';
import { getConceptInstanceProxy, getInstanceLabel, getRawInstanceLabel, handleProxyArrayProps } from '../../conceptModule';
import { formatOrUndef } from '../../conceptModule/common/commonFieldUtils';
import { Concept, ConceptDefinition, ConceptDefinition_Name, User } from '../../conceptModule/ids';
import { isInstanceOf } from '../../typeModule';
import type { AutomationRuleTrigger, AutomationRuleTriggerHandler, TriggerHandler } from './triggerHandler';
import { createActionQueryObject, TriggerType } from './utils';

export interface CollaborationEventTrigger extends AutomationRuleTrigger {
  type: TriggerType.collaboration,
  conceptDefinitionId?: string,
  intentionIds?: string[],
}

export const COLLABORATION_EVENT_TRIGGER_TRIGGERING_USER_PARAMETER = 'COLLABORATION_EVENT_TRIGGER_TRIGGERING_USER_PARAMETER';
export const COLLABORATION_EVENT_TRIGGER_RECIPIENTS_PARAMETER = 'COLLABORATION_EVENT_TRIGGER_RECIPIENTS_PARAMETER';
export const COLLABORATION_EVENT_TRIGGER_COLLABORATION_PARAMETER = 'COLLABORATION_EVENT_TRIGGER_COLLABORATION_PARAMETER';
export const COLLABORATION_EVENT_TRIGGER_COLLABORATION_MESSAGE_PARAMETER = 'COLLABORATION_EVENT_TRIGGER_COLLABORATION_MESSAGE_PARAMETER';

export const isCollaborationEventTrigger = (trigger: AutomationRuleTrigger | undefined): trigger is CollaborationEventTrigger => trigger?.type === TriggerType.collaboration;

// We use type to avoid having to extends CollaborationMessage from MailActionQueryRecord
// This avoids having all strings as key of CollaborationMessage -> usefull for the exclusive switch
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type CollaborationMessage = { author: { type: 'storeObject' }, text: { type: 'simple' }, uri: { type: 'simple' } };

export interface CollaborationEventTriggerHandler extends AutomationRuleTriggerHandler {
  parameterDefinitions: {
    [COLLABORATION_EVENT_TRIGGER_COLLABORATION_PARAMETER]: { type: 'parameter' },
    [COLLABORATION_EVENT_TRIGGER_COLLABORATION_MESSAGE_PARAMETER]: { type: 'parameter' },
    [COLLABORATION_EVENT_TRIGGER_TRIGGERING_USER_PARAMETER]: { type: 'parameter' },
    [COLLABORATION_EVENT_TRIGGER_RECIPIENTS_PARAMETER]: { type: 'parameterList' },
  },
  mailActionQuery: {
    collaboration_message: {
      type: 'object',
      object: CollaborationMessage,
    },
    collaboration: {
      type: 'object',
      object: {
        creator: { type: 'storeObject' },
        intention: { type: 'simple' },
        instance_label: { type: 'simple' },
        messages: {
          type: 'object',
          object: CollaborationMessage,
        },
        recipients: { type: 'storeObject' },
        status: { type: 'simple' },
        uri: { type: 'simple' },
      },
    },
  },
}

const getCollaborationMessageProxy = (store: ObjectStoreWithTimeseries, collaborationMessage: CollaborationMessageStoreObject) => (
  createActionQueryObject<CollaborationMessage>((prop) => {
    switch (prop) {
      case 'toString':
      case Symbol.toStringTag:
        return collaborationMessage[CollaborationMessage_Text];
      case 'uri':
        return buildCollaborationLink(store, collaborationMessage[CollaborationMessage_Collaboration], collaborationMessage.id);
      case 'text':
        return collaborationMessage[CollaborationMessage_Text];
      case 'author':
        return getConceptInstanceProxy(store, collaborationMessage[CollaborationMessage_CreatedBy] as string);
    }
    return undefined;
  })
);

export const createCollaborationEventTriggerHandler = (): TriggerHandler<CollaborationEventTriggerHandler> => ({
  parameterDefinitions: {
    COLLABORATION_EVENT_TRIGGER_TRIGGERING_USER_PARAMETER: {
      id: COLLABORATION_EVENT_TRIGGER_TRIGGERING_USER_PARAMETER,
      typeId: User,
      label: 'Collaboration message author',
      type: 'parameter',
    },
    COLLABORATION_EVENT_TRIGGER_RECIPIENTS_PARAMETER: {
      id: COLLABORATION_EVENT_TRIGGER_RECIPIENTS_PARAMETER,
      typeId: User,
      label: 'Collaboration recipients',
      type: 'parameterList',
    },
    COLLABORATION_EVENT_TRIGGER_COLLABORATION_PARAMETER: undefined,
    COLLABORATION_EVENT_TRIGGER_COLLABORATION_MESSAGE_PARAMETER: undefined,
  },
  mailActionQuery: {
    collaboration_message: {
      type: 'constant',
      getValue: ({ store, parametersMapping }) => {
        const collaborationMessageParameter = parametersMapping[COLLABORATION_EVENT_TRIGGER_COLLABORATION_MESSAGE_PARAMETER];
        if (collaborationMessageParameter?.type === 'single' && collaborationMessageParameter.id) {
          return getCollaborationMessageProxy(store, store.getObject<CollaborationMessageStoreObject>(collaborationMessageParameter.id));
        } else {
          return undefined;
        }
      },
    },
    collaboration: {
      type: 'constant',
      getValue: ({ store, parametersMapping }) => {
        const collaborationParameter = parametersMapping[COLLABORATION_EVENT_TRIGGER_COLLABORATION_PARAMETER];
        if (collaborationParameter?.type === 'single' && collaborationParameter.id) {
          const collaboration = store.getObjectOrNull<CollaborationStoreObject>(collaborationParameter.id);
          if (collaboration) {
            return createActionQueryObject<CollaborationEventTriggerHandler['mailActionQuery']['collaboration']['object']>((prop) => {
              switch (prop) {
                case 'toString':
                case Symbol.toStringTag:
                  return buildCollaborationLink(store, collaboration.id, undefined);
                case 'creator': {
                  const creator = collaboration.navigateBack<CollaborationMessageStoreObject>(Collaboration_Messages)[0].navigate(CollaborationMessage_CreatedBy);
                  if (creator) {
                    return getConceptInstanceProxy(store, creator.id);
                  } else {
                    return undefined;
                  }
                }
                case 'uri':
                  return buildCollaborationLink(store, collaboration.id, undefined);
                case 'status': {
                  const status = collaboration.navigateOrNull(Collaboration_Status);
                  if (status) {
                    return formatOrUndef(getInstanceLabel(store, collaboration.navigate(Collaboration_Status)));
                  } else {
                    return 'Undefined';
                  }
                }
                case 'messages': {
                  return new Proxy({}, {
                    get(__, messagesProp) {
                      const collaborationMessages = collaboration.navigateBack<CollaborationMessageStoreObject>(Collaboration_Messages);
                      if (messagesProp === 'toString' || messagesProp === Symbol.toStringTag) {
                        return () => collaborationMessages
                          .map((collaborationMessage) => formatOrUndef(collaborationMessage[CollaborationMessage_Text]))
                          .join('\n');
                      } else if (messagesProp === 'length') {
                        return collaborationMessages[messagesProp];
                      } else if (isFiniteNumber(messagesProp)) {
                        const collaborationMessage = collaborationMessages[Number(messagesProp)];
                        return getCollaborationMessageProxy(store, collaborationMessage);
                      } else {
                        return undefined;
                      }
                    },
                  });
                }
                case 'instance_label': {
                  const lastObjectId = getCollaborationContextsObjectIds(store, collaboration.id).reverse().find((id) => store.getObjectOrNull(id) !== null);
                  if (lastObjectId) {
                    const lastObject = store.getObject(lastObjectId);
                    if (isInstanceOf(lastObject, Concept)) {
                      const rawInstanceLabel = getRawInstanceLabel(store, lastObject);
                      const isLabelUndefined = rawInstanceLabel.every((element) => element === undefined);
                      return isLabelUndefined ? 'Undefined' : rawInstanceLabel.map(formatOrUndef).join(' - ');
                    } else if (isInstanceOf<ConceptDefinitionStoreObject>(lastObject, ConceptDefinition)) {
                      return formatOrUndef(lastObject[ConceptDefinition_Name]);
                    }
                  }
                  return 'Undefined';
                }
                case 'intention':
                  return formatOrUndef(getIntentionLabel(store, collaboration.id));
                case 'recipients': {
                  return new Proxy({}, {
                    get(__, recipientsProp) {
                      const recipientsIds = [...new Set(getCollaborationAllUserRecipientsIds(store, collaboration.id))];
                      if (recipientsProp === 'toString' || recipientsProp === Symbol.toStringTag) {
                        return () => recipientsIds
                          .map((recipientId) => store.getObjectOrNull(recipientId))
                          .filter(filterNullOrUndefined)
                          .map((instance) => formatOrUndef(getInstanceLabel(store, instance)))
                          .sort(comparing<string>(pushUndefinedToEnd).thenComparing(compareString))
                          .join(', ');
                      } else {
                        return handleProxyArrayProps(
                          recipientsProp,
                          recipientsIds.map((id) => ({ id })),
                          ({ id }) => getConceptInstanceProxy(store, id)
                        );
                      }
                    },
                  });
                }
              }
              return undefined;
            });
          }
        }
        return undefined;
      },
    },
  },
});
