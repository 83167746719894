import type { FunctionComponent } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { NavigationPerformanceMeasurer, PerformanceProfiler } from '../utils/performanceUtils';
import { BrowserNetworkManagerContextProvider } from '../utils/useBrowserNetworkManager';
import { RoutelessErrorBoundary } from './error/ErrorBoundary';
import Root from './Root';
import Swagger from './swagger/Swagger';

const App: FunctionComponent = () => {
  if (window.location.pathname === '/docs/api' || window.location.pathname === '/docs/api/') {
    return <Swagger pathname="/docs/api" openApiPath="/api/openapi.json" />;
  } else if (window.location.pathname === '/docs/api/types' || window.location.pathname === '/docs/api/types/') {
    return <Swagger pathname="/docs/api/types" openApiPath="/api/openapi-types.json" />;
  }

  return (
    <PerformanceProfiler>
      <RoutelessErrorBoundary>
        <BrowserNetworkManagerContextProvider>
          <RouterProvider
            future={{ v7_startTransition: true }}
            router={createBrowserRouter([
              {
                path: '*',
                element: (
                  <NavigationPerformanceMeasurer>
                    <Root />
                  </NavigationPerformanceMeasurer>
                ),
              },
            ], {
              future: { v7_relativeSplatPath: true },
            })}
          />
        </BrowserNetworkManagerContextProvider>
      </RoutelessErrorBoundary>
    </PerformanceProfiler>
  );
};

export default App;
