import classnames from 'classnames';
import type { CSSProperties, FunctionComponent, ReactElement } from 'react';
import { useState } from 'react';
import { joinObjects } from 'yooi-utils';
import base from '../../theme/base';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import type { HierarchyVariant } from '../../utils/useSizeContext';
import { SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import type { IconColorVariant, IconSizeVariant } from '../atoms/Icon';
import Icon, { IconName } from '../atoms/Icon';
import type { TypoVariant } from '../atoms/Typo';
import Typo from '../atoms/Typo';
import type { MenuItem } from './Menu';
import MoreMenuButton from './MoreMenuButton';
import SpacingLine from './SpacingLine';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  defaultHeight: {
    height: '4.2rem',
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
  },
  smallHeight: {
    height: '3.2rem',
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
    columnGap: spacingRem.s,
  },
  padded: {
    paddingLeft: spacingRem.m,
    paddingRight: spacingRem.m,
  },
  root: joinObjects(
    theme.font.tabTitle,
    {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      outline: 0,
      borderBottomWidth: '0.2rem',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.color.transparent,
      '&:hover': {
        color: theme.color.text.brand,
      },
      '&:active, &:focus, &:focus-within': {
        color: theme.color.text.brand,
      },
    }
  ),
  isGreyed: {
    color: theme.color.text.disabled,
    '&:hover': {
      color: theme.color.text.secondary,
    },
  },
  active: {
    borderBottomWidth: '0.2rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.primary,
  },
  overflowTabsContainer: {
    display: 'flex',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
  },
  tab: {
    display: 'flex',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
  },
  iconContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  iconNotificationDot: {
    position: 'absolute',
    width: '1rem',
    height: '1rem',
    borderRadius: base.borderRadius.large,
    top: '-0.5rem',
    right: '-0.5rem',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.white,
    background: theme.color.text.info,
  },
  tabSpacer: {
    flexGrow: 1,
  },
}), 'tabs');

export interface Tab {
  key: string,
  name?: string,
  tooltip?: string,
  hash?: string,
  pathname?: string,
  textColor?: string,
  isGreyed?: boolean,
  icon?: IconName,
  iconColor?: IconColorVariant,
  iconTooltip?: string,
  iconNotificationDot?: boolean,
  iconSize?: IconSizeVariant,
  inOverflow?: boolean,
  menuItems?: MenuItem[],
  alignRight?: boolean,
}

export interface TabsProps {
  tabs: Tab[],
  selectedTabIndex: number,
  onSelectedIndexChanged: (newIndex: number, event: { isCtrlKeyPressed: boolean, isMiddleClick: boolean }) => void,
  sizeVariant?: SizeVariant,
  hierarchyVariant?: HierarchyVariant,
  typoVariant?: TypoVariant,
  tabStyle?: CSSProperties,
  padded?: boolean,
}

const Tabs: FunctionComponent<TabsProps> = ({ tabs, selectedTabIndex, onSelectedIndexChanged, sizeVariant, hierarchyVariant, typoVariant, tabStyle, padded }) => {
  const classes = useStyles();

  const [displayedMenuIndex, setDisplayedMenuIndex] = useState<number | 'overflow' | undefined>(undefined);

  const tabsTuples: [Tab, number][] = tabs.map((tab, index) => [tab, index]);
  const leftDisplayTabs = tabsTuples.filter(([{ inOverflow, alignRight }]) => (!inOverflow && !alignRight));
  const overflowTabs = tabsTuples.filter(([{ inOverflow }]) => inOverflow);

  const rightDisplayTabs = tabsTuples.filter(([{ inOverflow, alignRight }]) => (!inOverflow && alignRight));

  const renderTab = (tab: Tab, index: number): ReactElement => (
    <div
      className={classnames({
        [classes.root]: true,
        [classes.defaultHeight]: sizeVariant !== SizeVariant.small,
        [classes.smallHeight]: sizeVariant === SizeVariant.small,
        [classes.active]: selectedTabIndex === index,
        [classes.isGreyed]: tab.isGreyed,
        [classes.padded]: padded,
      })}
      key={tab.key ?? tab.hash ?? tab.name}
      tabIndex={0}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onSelectedIndexChanged(index, { isCtrlKeyPressed: event.ctrlKey, isMiddleClick: false });
        }
      }}
      onClick={(event) => onSelectedIndexChanged(index, { isCtrlKeyPressed: event.ctrlKey, isMiddleClick: false })}
      onMouseDown={(event) => {
        if (event.button === 1) {
          onSelectedIndexChanged(index, { isCtrlKeyPressed: event.ctrlKey, isMiddleClick: true });
        }
      }}
      role="tab"
      style={tabStyle}
    >
      <div className={classes.tab} style={tabStyle}>
        <SpacingLine>
          <SpacingLine>
            {tab.icon && (
              <div className={classes.iconContainer}>
                <Icon name={tab.icon} colorVariant={tab.iconColor} tooltip={tab.iconTooltip} size={tab.iconSize} />
                {tab.iconNotificationDot && (<span className={classes.iconNotificationDot} />)}
              </div>
            )}
            {tab.name ? (<Typo color={tab.textColor} maxLine={1} variant={typoVariant}>{tab.name}</Typo>) : null}
          </SpacingLine>
          {tab.menuItems && tab.menuItems.length > 0 ? (
            <MoreMenuButton
              icon={IconName.expand_more}
              items={tab.menuItems}
              isOpen={displayedMenuIndex === index}
              open={() => setDisplayedMenuIndex(index)}
              close={() => setDisplayedMenuIndex(undefined)}
            />
          ) : null}
        </SpacingLine>
      </div>
    </div>
  );

  return (
    <SizeContextProvider sizeVariant={sizeVariant ?? SizeVariant.tabs} hierarchyVariant={hierarchyVariant}>
      <div
        className={classnames({
          [classes.container]: true,
          [classes.defaultHeight]: sizeVariant !== SizeVariant.small,
          [classes.smallHeight]: sizeVariant === SizeVariant.small,
          [classes.padded]: padded,
        })}
      >
        {leftDisplayTabs.map(([tab, index]) => renderTab(tab, index))}
        {overflowTabs.length > 0 ? (
          <div
            className={classnames({
              [classes.root]: true,
              [classes.defaultHeight]: sizeVariant !== SizeVariant.small,
              [classes.smallHeight]: sizeVariant === SizeVariant.small,
              [classes.padded]: padded,
            })}
          >
            <div className={classes.overflowTabsContainer}>
              <SpacingLine>
                <MoreMenuButton
                  icon={IconName.keyboard_arrow_right}
                  items={
                    overflowTabs
                      .map(([{ key, name, tooltip }, index]) => ({
                        key,
                        name: name ?? tooltip as string,
                        onClick: (event) => {
                          onSelectedIndexChanged(index, event);
                        },
                      }))
                  }
                  isOpen={displayedMenuIndex === 'overflow'}
                  open={() => setDisplayedMenuIndex('overflow')}
                  close={() => setDisplayedMenuIndex(undefined)}
                />
              </SpacingLine>
            </div>
          </div>
        ) : null}
        {rightDisplayTabs.length > 0 ? (<span className={classes.tabSpacer} />) : null}
        {rightDisplayTabs.map(([tab, index]) => renderTab(tab, index))}
      </div>
    </SizeContextProvider>
  );
};

export default Tabs;
