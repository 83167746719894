import type { ComponentProps, FunctionComponent } from 'react';
import { FILTER_PARAMETER_CURRENT, FILTER_PARAMETER_LOGGED_USER, getFilterFunction, isConceptValid, segregateParametersMapping } from 'yooi-modules/modules/conceptModule';
import { User } from 'yooi-modules/modules/conceptModule/ids';
import { UnsetDashboardParameterOption } from 'yooi-modules/modules/dashboardModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import Typo from '../../../components/atoms/Typo';
import { CompositeFieldCloseReasons } from '../../../components/molecules/CompositeField';
import SearchAndSelectMultiple from '../../../components/molecules/SearchAndSelectMultiple';
import SpacingLine from '../../../components/molecules/SpacingLine';
import useAuth from '../../../store/useAuth';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { formatOrUndef } from '../../../utils/stringUtils';
import useDerivedState from '../../../utils/useDerivedState';
import { buildUnsetOption, getLoggedUserOption } from '../dashboardUtils';
import type { Option } from '../modelTypeUtils';
import { defaultOptionComparator, getChipOptions } from '../modelTypeUtils';
import ParameterConfigurationEditor from './ParameterConfigurationEditor';
import type { MultipleParameterInterface, ParameterData } from './ParameterLine';

export interface ParameterMultipleData extends ParameterData {
  type: 'parameterList',
  selectedInstanceIds?: string[],
  defaultInstanceIds?: string[],
}

interface ParameterMultipleProps extends MultipleParameterInterface {
  parameterData: ParameterMultipleData,
}

const useStyles = makeStyles({
  readOnlyParameter: {
    margin: '0 0.9rem',
  },
  searchAndSelectContainer: {
    minWidth: '10rem',
    maxWidth: '32rem',
  },
}, 'parameterMultiple');

const ParameterMultiple: FunctionComponent<ParameterMultipleProps> = ({
  parameterData, handleSubmit, onMoveLeft, onMoveRight, onDelete, readOnly, focusOnMount, parameterDefinitions, parametersMapping,
}) => {
  const classes = useStyles();

  const store = useStore();
  const { loggedUserId } = useAuth();

  const { singleParametersMapping } = segregateParametersMapping(parametersMapping);

  const [label, setLabel, resetLabel] = useDerivedState(() => parameterData.label, [parameterData.label]);
  const [unsetLabel, setUnsetLabel, resetUnsetLabel] = useDerivedState(() => parameterData.unsetLabel, [parameterData.unsetLabel]);
  const [enableNotSetOption, setEnableNotSetOption, resetEnableNotSetOption] = useDerivedState(
    () => parameterData.enableNotSetOption,
    [parameterData.enableNotSetOption]
  );
  const [filters, setFilters, resetFilters] = useDerivedState(() => parameterData.filters, [parameterData.filters]);
  const [defaultInstanceIds, setDefaultInstanceIds, resetDefaultInstanceIds] = useDerivedState(
    () => parameterData.defaultInstanceIds,
    [parameterData.defaultInstanceIds]
  );
  const [typeId, setTypeId, resetTypeId] = useDerivedState(() => parameterData.typeId, [parameterData.typeId]);

  const filterFunction = typeId ? getFilterFunction(store, filters) : undefined;

  const unsetOption = buildUnsetOption(unsetLabel);
  const filterOption = (id: string) => {
    if (filterFunction) {
      return filterFunction(joinObjects(
        singleParametersMapping,
        {
          [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: id === FILTER_PARAMETER_LOGGED_USER ? loggedUserId : id },
          [FILTER_PARAMETER_LOGGED_USER]: { type: 'single' as const, id: loggedUserId },
        }
      ));
    } else {
      return true;
    }
  };

  const defaultValueError = filterFunction
  && defaultInstanceIds?.some(
    (defaultInstanceId) => defaultInstanceId !== UnsetDashboardParameterOption && defaultInstanceId !== FILTER_PARAMETER_LOGGED_USER && !filterOption(defaultInstanceId)
  )
    ? i18n`Selected option don't match the parameter filter` : undefined;

  const reset = () => {
    resetTypeId();
    resetFilters();
    resetLabel();
    resetUnsetLabel();
    resetEnableNotSetOption();
    resetDefaultInstanceIds();
  };

  const renderInstancesSelector = (
    selectedInstanceIds: string[],
    withLoggedUserOption: boolean,
    onSelectInstance: ComponentProps<typeof SearchAndSelectMultiple<Option>>['onSelect'],
    onDeleteInstance: ComponentProps<typeof SearchAndSelectMultiple<Option>>['onDelete']
  ) => {
    let hasError = false;
    const selectedOptions = (selectedInstanceIds ?? []).map((selectedInstanceId): Option | undefined => {
      if (selectedInstanceId === UnsetDashboardParameterOption) {
        return enableNotSetOption ? unsetOption : undefined;
      } else if (selectedInstanceId === FILTER_PARAMETER_LOGGED_USER) {
        return withLoggedUserOption ? getLoggedUserOption(store) : getChipOptions(store, loggedUserId);
      } else {
        const selectedOption = selectedInstanceId ? getChipOptions(store, selectedInstanceId) : undefined;
        if (!selectedOption) {
          return undefined;
        } else if (!filterOption(selectedOption.id)) {
          hasError = true;
          return joinObjects(selectedOption, {
            icon: { name: IconName.dangerous, colorVariant: IconColorVariant.error },
          });
        } else {
          return selectedOption;
        }
      }
    }).filter(filterNullOrUndefined);
    return (
      <SearchAndSelectMultiple
        error={hasError ? i18n`Some selected option don't match the parameter filter` : undefined}
        forceSingleLine
        selectedOptions={selectedOptions}
        computeOptions={typeId ? () => [
          ...enableNotSetOption ? [unsetOption] : [],
          ...typeId === User && withLoggedUserOption ? [getLoggedUserOption(store)] : [],
          ...store.getObjectOrNull(typeId)
            ?.navigateBack(Class_Instances)
            .filter(({ id: cId }) => isConceptValid(store, cId) && filterOption(cId))
            .map(({ id: instanceId }) => getChipOptions(store, instanceId))
            .filter(filterNullOrUndefined)
            .sort(defaultOptionComparator) ?? [],
        ] : undefined}
        onSelect={onSelectInstance}
        onDelete={onDeleteInstance}
      />
    );
  };

  return (
    <SpacingLine>
      {readOnly && (
        <div className={classes.readOnlyParameter}>
          <Typo>{formatOrUndef(label)}</Typo>
        </div>
      )}
      {!readOnly && (
        <ParameterConfigurationEditor
          label={label}
          onUpdateLabel={setLabel}
          unsetLabel={unsetLabel}
          onUpdateUnsetLabel={setUnsetLabel}
          onMoveLeft={onMoveLeft}
          onMoveRight={onMoveRight}
          onDelete={onDelete}
          onClose={(reason) => {
            if (reason !== CompositeFieldCloseReasons.cancel) {
              handleSubmit({
                id: parameterData.id,
                type: parameterData.type,
                label,
                unsetLabel,
                filters,
                enableNotSetOption,
                defaultInstanceIds,
                typeId,
                selectedInstanceIds: parameterData.selectedInstanceIds,
              });
            }
            reset();
          }}
          readOnly={readOnly}
          focusOnMount={focusOnMount}
          typeId={typeId}
          onSelectType={(option) => {
            setTypeId(option?.id);
            if (option) {
              const labels = parameterDefinitions.map((p) => p.label);
              const tmpLabel = option.label;
              let newLabel = option.label;
              let i = 1;
              while (labels.indexOf(newLabel) !== -1) {
                newLabel = `${tmpLabel} ${i}`;
                i += 1;
              }
              setLabel(newLabel);
            }
          }}
          filters={filters}
          onUpdateFilters={setFilters}
          defaultValueRender={renderInstancesSelector(
            defaultInstanceIds ?? [],
            true,
            (option) => {
              const newDefaultInstanceIdsSet = new Set(defaultInstanceIds);
              newDefaultInstanceIdsSet.add(option.id);
              setDefaultInstanceIds([...newDefaultInstanceIdsSet]);
            },
            (option) => {
              const newDefaultInstanceIdsSet = new Set(defaultInstanceIds);
              newDefaultInstanceIdsSet.delete(option.id);
              setDefaultInstanceIds([...newDefaultInstanceIdsSet]);
            }
          )}
          defaultValueError={defaultValueError}
          otherParameters={parameterDefinitions.filter(({ id: pId }) => pId !== parameterData.id)}
          onUpdateEnableNotSetOption={setEnableNotSetOption}
          enableNotSetOption={enableNotSetOption}
        />
      )}
      <div className={classes.searchAndSelectContainer}>
        {renderInstancesSelector(
          parameterData.selectedInstanceIds ?? [],
          false,
          (option) => {
            const newSelectedInstanceIdsSet = new Set(parameterData.selectedInstanceIds);
            newSelectedInstanceIdsSet.add(option.id);
            handleSubmit({
              id: parameterData.id,
              type: parameterData.type,
              label,
              unsetLabel,
              filters,
              enableNotSetOption,
              defaultInstanceIds,
              typeId,
              selectedInstanceIds: [...newSelectedInstanceIdsSet],
            });
            reset();
          },
          (option) => {
            const newSelectedInstanceIdsSet = new Set(parameterData.selectedInstanceIds);
            newSelectedInstanceIdsSet.delete(option.id);
            handleSubmit({
              id: parameterData.id,
              type: parameterData.type,
              label,
              unsetLabel,
              filters,
              enableNotSetOption,
              defaultInstanceIds,
              typeId,
              selectedInstanceIds: [...newSelectedInstanceIdsSet],
            });
            reset();
          }
        )}
      </div>
    </SpacingLine>
  );
};

export default ParameterMultiple;
