import type { FunctionComponent } from 'react';
import { useDebounce } from 'use-debounce';
import type { DimensionsMapping, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { numberFieldHandler } from 'yooi-modules/modules/conceptModule';
import { useWidgetContext } from '../../../../components/atoms/WidgetContextProvider';
import ReactiveSizeNumber from '../../../../components/molecules/ReactiveSizeNumber';
import WidgetLoader from '../../../../components/molecules/WidgetLoader';
import type { FrontObjectStore } from '../../../../store/useStore';
import useStore from '../../../../store/useStore';
import useDashboardSizeContext from '../../../../utils/useDashboardSizeContext';
import useWidgetSizeContext from '../../../../utils/useWidgetSizeContext';
import withAsyncTask, { registerComputeFunction } from '../../../../utils/withAsyncTask';

const doResolveValue = registerComputeFunction(async (_, store: FrontObjectStore, dimensionsMapping: DimensionsMapping, fieldId: string) => {
  const fieldHandler = numberFieldHandler(store, fieldId);
  const numberField = fieldHandler.resolveConfigurationWithOverride(dimensionsMapping);

  const resolvedValue = fieldHandler.getValueResolution(dimensionsMapping);

  return {
    decimals: resolvedValue.isComputed ? numberField.decimals ?? 1 : 0,
    value: resolvedValue.getDisplayValue() as number | undefined,
    unit: numberField.unit,
  };
});

interface NumberWidgetFieldProps {
  fieldId: string,
  parametersMapping: ParametersMapping,
}

const NumberWidgetField: FunctionComponent<NumberWidgetFieldProps> = withAsyncTask(
  ({ executeAsyncTask, parametersMapping, fieldId }) => {
    const store = useStore();

    const { width: widgetWidth, height: widgetHeight } = useWidgetSizeContext();
    const { width: dashboardWidth } = useDashboardSizeContext();
    const { backgroundColor } = useWidgetContext();

    const { status, value: { decimals, value, unit } } = executeAsyncTask(
      doResolveValue,
      [store, Object.fromEntries(Object.entries(parametersMapping).filter(([, { type }]) => type === 'single').map(([id, { id: valueId }]) => [id, valueId])), fieldId],
      [store.getSerial()]
    );
    const [debouncedStatus] = useDebounce(status, 100);

    return (
      <ReactiveSizeNumber
        width={widgetWidth}
        height={widgetHeight}
        containerWidth={dashboardWidth}
        decimals={decimals}
        value={value}
        unit={unit}
        backgroundColor={backgroundColor}
        withSpinner={debouncedStatus === 'loading'}
      />
    );
  },
  WidgetLoader
);

export default NumberWidgetField;
