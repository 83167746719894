import type { FunctionComponent } from 'react';
import type {
  AssociationFilterRaw,
  AssociationFilterStoreObject,
  ConditionFilterRaw,
  ConditionFilterStoreObject,
  Filters,
  SingleParameterDefinition,
  ViewFilterRaw,
} from 'yooi-modules/modules/conceptModule';
import { FILTER_PARAMETER_CURRENT, InstanceReferenceType, isFieldStep, PathStepType } from 'yooi-modules/modules/conceptModule';
import {
  AssociationField,
  AssociationFilter,
  AssociationFilter_Filters,
  AssociationFilter_LibraryDefaultValueFilters,
  AssociationFilter_Path,
  ConditionFilter,
  ConditionFilter_ActivateByDefault,
  ConditionFilter_Filters,
  KinshipRelationField,
  RelationMultipleField,
  RelationSingleField,
  StakeholdersField,
  ViewFilter_ConceptDefinition,
  ViewFilter_Label,
  ViewFilter_Rank,
  ViewFilter_ViewsField,
  WorkflowField,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { DecoratedList, DecoratedListEntry } from 'yooi-utils';
import { compareProperty, compareRank, ranker } from 'yooi-utils';
import { ButtonVariant } from '../../../components/atoms/Button';
import Checkbox from '../../../components/atoms/Checkbox';
import { IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import SpacingLine from '../../../components/molecules/SpacingLine';
import BlockTitle, { BlockTitleVariant } from '../../../components/templates/BlockTitle';
import DataTable from '../../../components/templates/DataTable';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { formatOrUndef } from '../../../utils/stringUtils';
import useNewLineFocus from '../../../utils/useNewLineFocus';
import { getFilterValuePathHandler } from '../FrontFilterRenderers';
import StoreTextInputField from '../input/StoreTextInputField';
import { getConceptDefinitionNameOrEntity } from '../modelTypeUtils';
import PathStepsInput from '../path/PathStepsInput';
import { StepValidationState } from '../pathConfigurationHandler';
import type { FilterDefinition } from './filterComposite/FilterComposite';
import FilterComposite from './filterComposite/FilterComposite';
import { getQuickFilterLabel, getQuickFiltersTargetedConceptDefinitionId, isAssociationFilter, isConditionFilter } from './quickFiltersUtils';

export enum QuickFilterType {
  association = 'association',
  condition = 'condition',
}

export enum QuickFiltersEditorVariant {
  secondary = 'secondary',
  tertiary = 'tertiary',
}

interface QuickFiltersEditorProps {
  getQuickFilters: () => (AssociationFilterStoreObject | ConditionFilterStoreObject)[],
  onNewItem: (type: QuickFilterType, rank: string) => string,
  filterParametersDefinitions?: SingleParameterDefinition[],
  dimensionParameters?: SingleParameterDefinition[],
  variant?: QuickFiltersEditorVariant,
}

const QuickFiltersEditor: FunctionComponent<QuickFiltersEditorProps> = ({
  getQuickFilters,
  onNewItem,
  filterParametersDefinitions = [],
  dimensionParameters = [],
  variant = QuickFiltersEditorVariant.secondary,
}) => {
  const store = useStore();

  const [newLineFocus, setNewLineFocus] = useNewLineFocus();

  const quickFilters: DecoratedList<AssociationFilterStoreObject | ConditionFilterStoreObject> = ranker.decorateList(
    getQuickFilters().sort(compareProperty(ViewFilter_Rank, compareRank)),
    (quickFilter) => quickFilter[ViewFilter_Rank]
  );

  const associationFilters = quickFilters.filter((item): item is DecoratedListEntry<AssociationFilterStoreObject> => isAssociationFilter(item.item));
  const conditionFilters = quickFilters.filter((item): item is DecoratedListEntry<ConditionFilterStoreObject> => isConditionFilter(item.item));

  const isMonoDim = dimensionParameters.length === 1;

  const associationFilterValuePathHandler = getFilterValuePathHandler(store, dimensionParameters.map(({ typeId }) => typeId), filterParametersDefinitions, [
    ({ pathStep }) => {
      if (isFieldStep(pathStep)) {
        const field = store.getObjectOrNull(pathStep.fieldId);
        if (isInstanceOf(field, KinshipRelationField) && !pathStep.embeddingFieldId) {
          return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`You should select a specific field or type` }];
        }
        if (![
          AssociationField, RelationSingleField, RelationMultipleField, WorkflowField, KinshipRelationField, StakeholdersField,
        ].some((fieldTypeId) => isInstanceOf(field, fieldTypeId))) {
          return [{ state: StepValidationState.invalid, reasonMessage: i18n`Last step type is invalid` }];
        }
      }
      return [{ state: StepValidationState.valid }];
    },
  ], dimensionParameters.map((param) => param.id));

  return (
    <>
      <BlockTitle
        title={i18n`Sets of conditions`}
        subtitle={i18n`The filters defined below will be available to all users and added to their favorite bar.`}
        variant={variant === QuickFiltersEditorVariant.secondary ? BlockTitleVariant.secondary : BlockTitleVariant.tertiary}
      />
      <DataTable<DecoratedListEntry<ConditionFilterStoreObject>>
        list={conditionFilters.map((q) => ({ key: q.item.id, type: 'item', item: q, color: undefined }))}
        columnsDefinition={[
          {
            propertyId: ViewFilter_Label,
            name: i18n`Label`,
            focusable: true,
            cellRender: ({ item: quickFilter }, focusAndScrollOnMount) => (
              <StoreTextInputField
                focusOnMount={focusAndScrollOnMount}
                initialValue={getQuickFilterLabel(store, quickFilter.id, filterParametersDefinitions)}
                onSubmit={(value) => {
                  store.updateObject<ViewFilterRaw>(quickFilter.id, { [ViewFilter_Label]: value });
                }}
              />
            ),
          },
          {
            propertyId: ConditionFilter_Filters,
            name: i18n`Condition(s)`,
            width: 40,
            cellRender: ({ item: quickFilter }) => (
              <FilterComposite
                rootPath={isMonoDim ? {
                  label: i18n`Current (${formatOrUndef(getConceptDefinitionNameOrEntity(store, dimensionParameters[0].typeId))})`,
                  path: [
                    { type: PathStepType.dimension, conceptDefinitionId: dimensionParameters[0].typeId },
                    { type: PathStepType.mapping, mapping: { id: dimensionParameters[0].id, type: InstanceReferenceType.parameter } },
                  ],
                } : undefined}
                suggestedPaths={dimensionParameters.map((parameter) => ({
                  label: `${parameter.label} (${formatOrUndef(getConceptDefinitionNameOrEntity(store, parameter.typeId))})`,
                  path: [
                    { type: PathStepType.dimension, conceptDefinitionId: parameter.typeId },
                    { type: PathStepType.mapping, mapping: { id: parameter.id, type: InstanceReferenceType.parameter } },
                  ],
                }))}
                parameterDefinitions={filterParametersDefinitions}
                filtersDefinition={{
                  updateFilters: (filters) => store.updateObject<ConditionFilterRaw>(quickFilter.id, { [ConditionFilter_Filters]: filters[0] }),
                  definition: [{ filter: quickFilter[ConditionFilter_Filters] }],
                }}
              />
            ),
          },
          {
            propertyId: ConditionFilter_ActivateByDefault,
            name: i18n`Activate by default`,
            width: 10,
            cellRender: ({ item: quickFilter }) => (
              <Checkbox
                checked={quickFilter[ConditionFilter_ActivateByDefault] ?? false}
                onChange={(value) => store.updateObject<ConditionFilterRaw>(quickFilter.id, { [ConditionFilter_ActivateByDefault]: value })}
              />
            ),
          },
          {
            propertyId: 'MoveUp',
            action: true,
            cellRender: ({ item: { id }, moveUpRank }, _, index) => (
              <SpacingLine>
                <IconOnlyButton
                  disabled={index === 0}
                  onClick={() => store.updateObject<ViewFilterRaw>(id, { [ViewFilter_Rank]: moveUpRank() })}
                  iconName={IconName.expand_less}
                  tooltip={i18n`Move Up`}
                  variant={IconOnlyButtonVariants.tertiary}
                />
              </SpacingLine>
            ),
          },
          {
            propertyId: 'MoveDown',
            action: true,
            cellRender: ({ item: { id }, moveDownRank }, _, index) => (
              <SpacingLine>
                <IconOnlyButton
                  disabled={index === (conditionFilters.length - 1)}
                  onClick={() => store.updateObject<ViewFilterRaw>(id, { [ViewFilter_Rank]: moveDownRank() })}
                  iconName={IconName.expand_more}
                  tooltip={i18n`Move Down`}
                  variant={IconOnlyButtonVariants.tertiary}
                />
              </SpacingLine>
            ),
          },
        ]}
        linesActions={({ item, insertAfterRank }) => [
          {
            key: 'duplicate',
            name: i18n`Duplicate`,
            icon: IconName.content_copy_outline,
            onClick: () => {
              store.createObject({
                [Instance_Of]: ConditionFilter,
                [ViewFilter_Label]: item[ViewFilter_Label],
                [ViewFilter_ViewsField]: item[ViewFilter_ViewsField],
                [ViewFilter_ConceptDefinition]: item[ViewFilter_ConceptDefinition],
                [ViewFilter_Rank]: insertAfterRank(),
                [ConditionFilter_ActivateByDefault]: item[ConditionFilter_ActivateByDefault],
                [ConditionFilter_Filters]: item[ConditionFilter_Filters],
              });
            },
          },
          {
            key: 'delete',
            name: i18n`Delete`,
            icon: IconName.delete,
            onClick: () => {
              store.deleteObject(item.id);
            },
            danger: true,
          }]}
        newItemIcon={IconName.add}
        newItemTitle={i18n`Create`}
        newLineFocus={newLineFocus.current}
        newItemButtonVariant={ButtonVariant.tertiary}
        onNewItem={() => {
          setNewLineFocus(onNewItem(QuickFilterType.condition, conditionFilters.at(-1)?.insertAfterRank() ?? quickFilters.insertBeforeFirstItemRank()));
        }}
      />
      <BlockTitle
        title={i18n`Filters on dimension`}
        subtitle={i18n`Allow user to filter a dimension via its instance or via associations.
For example, if you provide a filter for the Status dimension, users will be able to filter the list by any Status with one click.
And if you provide a filter for the Country dimension via the path Country > Continent, users will be able to filter the Country dimension by choosing any Continent with one click. Only Country instances that have an association with the chosen Continent will be kept.`}
        variant={variant === QuickFiltersEditorVariant.secondary ? BlockTitleVariant.secondary : BlockTitleVariant.tertiary}
      />
      <DataTable<DecoratedListEntry<AssociationFilterStoreObject>>
        list={associationFilters.map((q) => ({ key: q.item.id, type: 'item', item: q, color: undefined }))}
        columnsDefinition={[
          {
            propertyId: ViewFilter_Label,
            name: i18n`Label`,
            width: 20,
            cellRender: ({ item: quickFilter }) => (
              <StoreTextInputField
                initialValue={getQuickFilterLabel(store, quickFilter.id, filterParametersDefinitions)}
                onSubmit={(value) => {
                  store.updateObject<ViewFilterRaw>(quickFilter.id, { [ViewFilter_Label]: value });
                }}
              />
            ),
          },
          {
            propertyId: AssociationFilter_Path,
            name: i18n`Association/Relation`,
            focusable: true,
            cellRender: ({ item: quickFilter }, focusAndScrollOnMount) => (
              <PathStepsInput
                focusOnMount={focusAndScrollOnMount}
                initialPath={quickFilter[AssociationFilter_Path]}
                valuePathHandler={associationFilterValuePathHandler}
                onSubmit={(path) => store.updateObject<AssociationFilterRaw>(quickFilter.id, { [AssociationFilter_Path]: path, [AssociationFilter_Filters]: null })}
                parameterDefinitions={filterParametersDefinitions}
                suggestedBasePaths={dimensionParameters.map((parameter) => ({
                  label: `${parameter.label} (${formatOrUndef(getConceptDefinitionNameOrEntity(store, parameter.typeId))})`,
                  path: [
                    { type: PathStepType.dimension, conceptDefinitionId: parameter.typeId },
                    { type: PathStepType.mapping, mapping: { id: parameter.id, type: InstanceReferenceType.parameter } },
                  ],
                }))}
              />
            ),
          },
          {
            propertyId: AssociationFilter_Filters,
            name: i18n`Filter(s)`,
            width: 15,
            cellRender: ({ item: quickFilter }) => {
              const targetedConceptDefinitionId = getQuickFiltersTargetedConceptDefinitionId(store, quickFilter[AssociationFilter_Path], filterParametersDefinitions);
              if (targetedConceptDefinitionId) {
                return (
                  <FilterComposite
                    rootPath={{
                      label: i18n`Current (${formatOrUndef(getConceptDefinitionNameOrEntity(store, targetedConceptDefinitionId))})`,
                      path: [
                        { type: PathStepType.dimension, conceptDefinitionId: targetedConceptDefinitionId },
                        { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
                      ],
                    }}
                    requiredConceptDefinitionId={targetedConceptDefinitionId}
                    parameterDefinitions={[
                      ...filterParametersDefinitions.filter(({ id }) => dimensionParameters.some((dimParam) => dimParam.id === id)),
                      { id: FILTER_PARAMETER_CURRENT, label: i18n`Current`, typeId: targetedConceptDefinitionId, type: 'parameter' },
                    ]}
                    filtersDefinition={{
                      updateFilters: (filters) => store.updateObject<AssociationFilterRaw>(quickFilter.id, { [AssociationFilter_Filters]: filters[0] }),
                      definition: [{ filter: quickFilter[AssociationFilter_Filters] }],
                    }}
                  />
                );
              } else {
                return null;
              }
            },
          },
          {
            propertyId: AssociationFilter_LibraryDefaultValueFilters,
            name: i18n`Default selection`,
            width: 15,
            cellRender: ({ item: quickFilter }) => {
              const targetedConceptDefinitionId = getQuickFiltersTargetedConceptDefinitionId(store, quickFilter[AssociationFilter_Path], filterParametersDefinitions);
              if (targetedConceptDefinitionId) {
                const filtersDefinition: FilterDefinition = {
                  updateFilters: (filters: Filters[]) => {
                    store.updateObject<AssociationFilterRaw>(quickFilter.id, { [AssociationFilter_LibraryDefaultValueFilters]: filters[0] });
                  },
                  definition: [{ filter: quickFilter[AssociationFilter_LibraryDefaultValueFilters] }],
                };
                return (
                  <FilterComposite
                    rootPath={{
                      label: i18n`Current (${formatOrUndef(getConceptDefinitionNameOrEntity(store, targetedConceptDefinitionId))})`,
                      path: [
                        { type: PathStepType.dimension, conceptDefinitionId: targetedConceptDefinitionId },
                        { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
                      ],
                    }}
                    parameterDefinitions={[
                      ...filterParametersDefinitions.filter(({ id }) => dimensionParameters.some((dimParam) => dimParam.id === id)),
                      { id: FILTER_PARAMETER_CURRENT, label: i18n`Current`, typeId: targetedConceptDefinitionId, type: 'parameter' },
                    ]}
                    filtersDefinition={filtersDefinition}
                    requiredConceptDefinitionId={targetedConceptDefinitionId}
                  />
                );
              } else {
                return null;
              }
            },
          },
          {
            propertyId: 'MoveUp',
            action: true,
            cellRender: ({ item: { id }, moveUpRank }, _, index) => (
              <SpacingLine>
                <IconOnlyButton
                  disabled={index === 0}
                  onClick={() => store.updateObject<ViewFilterRaw>(id, { [ViewFilter_Rank]: moveUpRank() })}
                  iconName={IconName.expand_less}
                  tooltip={i18n`Move Up`}
                  variant={IconOnlyButtonVariants.tertiary}
                />
              </SpacingLine>
            ),
          },
          {
            propertyId: 'MoveDown',
            action: true,
            cellRender: ({ item: { id }, moveDownRank }, _, index) => (
              <SpacingLine>
                <IconOnlyButton
                  disabled={index === (associationFilters.length - 1)}
                  onClick={() => store.updateObject<ViewFilterRaw>(id, { [ViewFilter_Rank]: moveDownRank() })}
                  iconName={IconName.expand_more}
                  tooltip={i18n`Move Down`}
                  variant={IconOnlyButtonVariants.tertiary}
                />
              </SpacingLine>
            ),
          },
        ]}
        linesActions={({ item, insertAfterRank }) => [
          {
            key: 'duplicate',
            name: i18n`Duplicate`,
            icon: IconName.content_copy_outline,
            onClick: () => {
              store.createObject({
                [Instance_Of]: AssociationFilter,
                [ViewFilter_Label]: item[ViewFilter_Label],
                [ViewFilter_ViewsField]: item[ViewFilter_ViewsField],
                [ViewFilter_ConceptDefinition]: item[ViewFilter_ConceptDefinition],
                [ViewFilter_Rank]: insertAfterRank(),
                [AssociationFilter_Filters]: item[AssociationFilter_Filters],
                [AssociationFilter_Path]: item[AssociationFilter_Path],
                [AssociationFilter_LibraryDefaultValueFilters]: item[AssociationFilter_LibraryDefaultValueFilters],
              });
            },
          },
          {
            key: 'delete',
            name: i18n`Delete`,
            icon: IconName.delete,
            onClick: () => {
              store.deleteObject(item.id);
            },
            danger: true,
          },
        ]}
        newItemIcon={IconName.add}
        newItemTitle={i18n`Create`}
        newLineFocus={newLineFocus.current}
        newItemButtonVariant={ButtonVariant.tertiary}
        onNewItem={() => {
          const rank = quickFilters.insertAfterLastItemRank();
          setNewLineFocus(onNewItem(QuickFilterType.association, rank));
        }}
      />
    </>
  );
};

export default QuickFiltersEditor;
