import { createAutoProvisioningMap, filterNullOrUndefined, newError } from 'yooi-utils';
import type { ObjectStoreReadOnly } from '../../ObjectStoreType';
import type { Operation } from '../../ProtocolType';
import type { InitializedModule } from '../platformModule';
import type { AccessControlListHandler, AccessControlListRegisterObject, AccessControlListRegistrationHandler } from '../PlatformModulesTypes';

export interface AccessControlListLibrary {
  getObjectACLForOperation: (action: string, operation: Operation<string | string[]>) => { ruleKeyId: string, actionHandler: AccessControlListHandler }[],
}

export const createAccessControlListLibrary = (
  modules: InitializedModule[],
  objectStore: ObjectStoreReadOnly,
  isFeatureEnabled: (feature: string, name: string) => boolean
): AccessControlListLibrary => {
  const objectAccessControlList = createAutoProvisioningMap<string, Map<string, AccessControlListHandler>>();

  const onObject: AccessControlListRegistrationHandler['onObject'] = (objectId) => {
    const registrationHandler: AccessControlListRegisterObject = {
      allow: (action, handler) => {
        const actionACL = objectAccessControlList.getOrCreate(action, () => new Map<string, AccessControlListHandler>());
        if (actionACL.has(objectId)) {
          throw newError('An ACL is already registered', { objectId, action });
        }
        actionACL.set(objectId, handler);
        return registrationHandler;
      },
    };
    return registrationHandler;
  };

  const moduleGetACLKeys = modules
    .map((module) => module.registerAccessControlList?.(objectStore, { onObject }, isFeatureEnabled)?.getACLLibraryKeys)
    .filter(filterNullOrUndefined);

  return {
    getObjectACLForOperation: (action, operation) => {
      const actionACL = objectAccessControlList.get(action);
      return actionACL
        ? moduleGetACLKeys
          .flatMap((getACLLibraryKeys) => getACLLibraryKeys(objectStore, operation))
          .map((ruleKeyId) => ({ ruleKeyId, actionHandler: actionACL.get(ruleKeyId) }))
          .filter((value): value is { ruleKeyId: string, actionHandler: AccessControlListHandler } => Boolean(value.actionHandler))
        : [];
    },
  };
};
