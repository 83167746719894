import type { ComponentProps, ReactElement } from 'react';
import type { PaletteColor } from 'yooi-modules/modules/platformConfigurationModule/model';
import type { DateRange } from 'yooi-utils';
import type Checkbox from '../../../components/atoms/Checkbox';
import type { IconName } from '../../../components/atoms/Icon';
import type TextInputString from '../../../components/inputs/TextInputString';
import type { DropdownSectionTitleVariants } from '../../../components/molecules/CompositeField';
import type SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import type { SearchAndSelectMultipleProps } from '../../../components/molecules/SearchAndSelectMultiple';
import type { ChipOption } from '../modelTypeUtilsType';
import type PathStepsInput from '../path/PathStepsInput';
import type { StepOption } from './_global/pathUtils';

export enum EditionOptionTypes {
  text = 'text',
  select = 'select',
  selectMultiple = 'selectMultiple',
  dateRange = 'dateRange',
  number = 'number',
  color = 'color',
  checkbox = 'checkbox',
  path = 'path',
  custom = 'custom',
}

interface BaseEditionOption<Type extends EditionOptionTypes, PropTypes> {
  type: Type,
  props: PropTypes,
}

type ExtractTypes<T> = T extends BaseEditionOption<infer Type, infer PropTypes> ? { Type: Type, PropTypes: PropTypes } : never;

interface BaseLineEditionOption<Base extends BaseEditionOption<EditionOptionTypes, unknown>> extends BaseEditionOption<ExtractTypes<Base>['Type'], ExtractTypes<Base>['PropTypes']> {
  key: string,
  title?: string,
  info?: string,
  error?: string,
  fullWidth?: boolean,
  skipBlockContent?: boolean,
  isVertical?: boolean,
  padded?: boolean,
}

export interface ColorInputProps {
  value: string | undefined,
  onChange: (value: string | null) => void,
  colorPalette: PaletteColor[] | undefined,
  readOnly?: boolean,
}

type ColorBaseEditionOption = BaseEditionOption<EditionOptionTypes.color, ColorInputProps>;
type ColorLineEditionOption = BaseLineEditionOption<ColorBaseEditionOption>;

export interface NumberInputProps {
  value: number | undefined,
  onChange: (value?: number | null) => void,
  placeholder?: string,
  min?: number,
  max?: number,
  readOnly?: boolean,
}

type NumberBaseEditionOption = BaseEditionOption<EditionOptionTypes.number, NumberInputProps>;
type NumberLineEditionOption = BaseLineEditionOption<NumberBaseEditionOption>;

export interface DateRangeInputProps {
  value: DateRange | undefined,
  onChange: (value: DateRange | null) => void,
  placeholder?: string,
  readOnly?: boolean,
  predefinedRangeOption?: string,
}

type DateRangeBaseEditionOption = BaseEditionOption<EditionOptionTypes.dateRange, DateRangeInputProps>;
type DateRangeLineEditionOption = BaseLineEditionOption<DateRangeBaseEditionOption>;

export interface TextInputProps {
  value: ComponentProps<typeof TextInputString>['value'],
  placeholder?: ComponentProps<typeof TextInputString>['placeholder'],
  maxLine?: ComponentProps<typeof TextInputString>['dropdownMaxLine'],
  acceptChars?: ComponentProps<typeof TextInputString>['acceptChars'],
  acceptCharsErrorMessage?: ComponentProps<typeof TextInputString>['acceptCharsErrorMessage'],
  withDropdown?: ComponentProps<typeof TextInputString>['withDropdown'],
  error?: ComponentProps<typeof TextInputString>['error'],
  onChange: (value: string | null) => void,
  readOnly?: boolean,
  action?: ComponentProps<typeof TextInputString>['action'],
  getTreeRender?: () => ReactElement | null,
  treeTooltip?: string,
  textInputAction?: { getRender: () => ReactElement, iconName: IconName, tooltip: string },
}

type TextBaseEditionOption = BaseEditionOption<EditionOptionTypes.text, TextInputProps>;
type TextLineEditionOption = BaseLineEditionOption<TextBaseEditionOption>;

type CheckboxBaseEditionOption = BaseEditionOption<EditionOptionTypes.checkbox, ComponentProps<typeof Checkbox>>;
type CheckboxLineEditionOption = BaseLineEditionOption<CheckboxBaseEditionOption>;

export interface SearchAndSelectInputProps {
  selectedOption: ComponentProps<typeof SearchAndSelect>['selectedOption'],
  computeOptions: Exclude<ComponentProps<typeof SearchAndSelect>['computeOptions'], undefined>,
  onChange: Exclude<ComponentProps<typeof SearchAndSelect>['onSelect'], undefined>,
  placeholder?: ComponentProps<typeof SearchAndSelect>['placeholder'],
  clearable?: ComponentProps<typeof SearchAndSelect>['clearable'],
  getInlineCreation?: ComponentProps<typeof SearchAndSelect>['getInlineCreation'],
  required?: boolean,
  readOnly?: boolean,
}

type SearchAndSelectBaseEditionOption = BaseEditionOption<EditionOptionTypes.select, SearchAndSelectInputProps>;
type SearchAndSelectLineEditionOption = BaseLineEditionOption<SearchAndSelectBaseEditionOption>;

export interface SearchAndSelectMultipleInputProps<T extends { id: string, label: string } = ChipOption> {
  selectedOptions?: SearchAndSelectMultipleProps<T>['selectedOptions'],
  selectedSteps?: SearchAndSelectMultipleProps<T>['selectedSteps'],
  computeOptions: Exclude<SearchAndSelectMultipleProps<T>['computeOptions'], undefined>,
  onSelect: Exclude<SearchAndSelectMultipleProps<T>['onSelect'], undefined>,
  onDelete: Exclude<SearchAndSelectMultipleProps<T>['onDelete'], undefined>,
  placeholder?: SearchAndSelectMultipleProps<T>['placeholder'],
  error?: SearchAndSelectMultipleProps<T>['error'],
  readOnly?: boolean,
}

type SearchAndSelectMultipleBaseEditionOption = BaseEditionOption<EditionOptionTypes.selectMultiple, SearchAndSelectMultipleInputProps<StepOption>>;
type SearchAndSelectMultipleLineEditionOption = BaseLineEditionOption<SearchAndSelectMultipleBaseEditionOption>;

type PathBaseEditionOption = BaseEditionOption<EditionOptionTypes.path, ComponentProps<typeof PathStepsInput>>;
type PathLineEditionOption = BaseLineEditionOption<PathBaseEditionOption>;

type CustomBaseEditionOption = BaseEditionOption<EditionOptionTypes.custom, { render: () => (ReactElement | null) }>;
type CustomLineEditionOption = BaseLineEditionOption<CustomBaseEditionOption>;

export type LineEditionOption =
  | ColorLineEditionOption
  | NumberLineEditionOption
  | DateRangeLineEditionOption
  | TextLineEditionOption
  | CheckboxLineEditionOption
  | SearchAndSelectLineEditionOption
  | SearchAndSelectMultipleLineEditionOption
  | PathLineEditionOption
  | CustomLineEditionOption;

export type FieldEditionOption = LineEditionOption & { hasValue: () => boolean, clearValue: () => void };

export interface FieldEditionSectionGroup {
  key: string,
  type: 'group',
  title: string,
  rightActions?: ReactElement | null,
  sections: {
    key: string,
    title?: string,
    info?: string,
    rightActions?: ReactElement | null,
    options: FieldEditionOption[],
  }[],
}

export interface FieldEditionSection {
  key: string,
  type: 'section',
  title?: string,
  rightActions?: ReactElement | null,
  options: FieldEditionOption[],
}

export type EditionOption =
  | ColorBaseEditionOption
  | NumberBaseEditionOption
  | DateRangeBaseEditionOption
  | TextBaseEditionOption
  | CheckboxBaseEditionOption
  | SearchAndSelectBaseEditionOption
  | SearchAndSelectMultipleBaseEditionOption
  | PathBaseEditionOption
  | CustomBaseEditionOption;

export interface EditionOptionSection {
  key: string,
  title?: string,
  info?: string,
  titleVariant?: DropdownSectionTitleVariants,
  action?: EditionOption,
  options: LineEditionOption[],
}
