import type { FunctionComponent } from 'react';
import {
  ConceptCapability,
  ConceptCapability_Name,
  ConceptCapabilityCreate,
  ConceptCapabilityRead,
  ConceptDefinition,
  ConceptRole_AssignedByDefault,
  ConceptRole_ConceptDefinition,
  ConceptRole_ForCollaboration,
  ConceptRoleCapability,
  ConceptRoleCapability_Role_ConceptCapability,
  ConceptRoleCapability_Role_ConceptRole,
} from 'yooi-modules/modules/conceptModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import { compareProperty, compareString, filterNullOrUndefined } from 'yooi-utils';
import Checkbox from '../../../components/atoms/Checkbox';
import { IconName } from '../../../components/atoms/Icon';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SearchAndSelectMultiple from '../../../components/molecules/SearchAndSelectMultiple';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import { buildMargins, Spacing } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { defaultOptionComparator, getChipOptions } from '../../_global/modelTypeUtils';

const useStyles = makeStyles({
  spacing: buildMargins({ left: Spacing.s }),
}, 'adminRoleDetail');

interface AdminRoleDetailProps {
  roleId: string,
}

const AdminRoleDetail: FunctionComponent<AdminRoleDetailProps> = ({ roleId }) => {
  const classes = useStyles();
  const store = useStore();

  const roleInstance = store.getObject(roleId);

  return (
    <VerticalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Available on`} iconName={IconName.person} />
        <BlockContent>
          <SearchAndSelect
            readOnly
            computeOptions={() => store.getObject(ConceptDefinition)
              .navigateBack(Class_Instances)
              .map((cd) => getChipOptions(store, cd.id))
              .filter(filterNullOrUndefined)
              .sort(defaultOptionComparator)}
            selectedOption={typeof roleInstance[ConceptRole_ConceptDefinition] === 'string' ? getChipOptions(store, roleInstance[ConceptRole_ConceptDefinition]) : undefined}
          />
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Capabilities`} iconName={IconName.person} />
        <BlockContent>
          <SearchAndSelectMultiple
            placeholder={i18n`Add element`}
            onSelect={({ id }) => store.withAssociation(ConceptRoleCapability)
              .withRole(ConceptRoleCapability_Role_ConceptRole, roleId)
              .withRole(ConceptRoleCapability_Role_ConceptCapability, id)
              .updateObject({})}
            onDelete={({ id }) => store.withAssociation(ConceptRoleCapability)
              .withRole(ConceptRoleCapability_Role_ConceptRole, roleId)
              .withRole(ConceptRoleCapability_Role_ConceptCapability, id)
              .deleteObject()}
            computeOptions={() => store.getObject(ConceptCapability)
              .navigateBack(Class_Instances)
              .filter(({ id }) => ![ConceptCapabilityCreate, ConceptCapabilityRead].includes(id))
              .map((capability) => getChipOptions(store, capability.id))
              .filter(filterNullOrUndefined)
              .sort(defaultOptionComparator)}
            selectedOptions={store.withAssociation(ConceptRoleCapability)
              .withRole(ConceptRoleCapability_Role_ConceptRole, roleId)
              .list()
              .map((assoc) => assoc.navigateRole(ConceptRoleCapability_Role_ConceptCapability))
              .sort(compareProperty(ConceptCapability_Name, compareString))
              .map((capa) => getChipOptions(store, capa.id))
              .filter(filterNullOrUndefined)}
          />
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Role for creator`} iconName={IconName.person} />
        <BlockContent>
          <div className={classes.spacing}>
            <Checkbox
              checked={Boolean(roleInstance[ConceptRole_AssignedByDefault])}
              onChange={(value) => store.updateObject(roleId, { [ConceptRole_AssignedByDefault]: value || null })}
            />
          </div>
        </BlockContent>
      </HorizontalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Role for collaborators`} iconName={IconName.person} />
        <BlockContent>
          <div className={classes.spacing}>
            <Checkbox
              checked={Boolean(roleInstance[ConceptRole_ForCollaboration])}
              onChange={(value) => store.updateObject(roleId, { [ConceptRole_ForCollaboration]: value })}
            />
          </div>
        </BlockContent>
      </HorizontalBlock>
    </VerticalBlock>
  );
};

export default AdminRoleDetail;
