import type { DateRange, StoredDateObject } from 'yooi-utils';
import {
  computeEffectiveRangeForPeriodAndDates,
  computeEffectiveValueFrom,
  dateFormats,
  DateStorageTypeKeys,
  formatDisplayDate,
  getDateFromString,
  getFormattedTextDateByPeriod,
  isDateValid,
  newError,
  PeriodicityType,
} from 'yooi-utils';
import { IconName } from '../components/atoms/Icon';
import i18n from './i18n';

enum DateInputFormatTypes {
  day = 'day',
  month = 'month',
  year = 'year',
}

const supportedDateInputFormats: Record<PeriodicityType, DateInputFormatTypes[]> = {
  [PeriodicityType.day]: [DateInputFormatTypes.day],
  [PeriodicityType.week]: [DateInputFormatTypes.day],
  [PeriodicityType.month]: [DateInputFormatTypes.day, DateInputFormatTypes.month],
  [PeriodicityType.quarter]: [DateInputFormatTypes.day, DateInputFormatTypes.month],
  [PeriodicityType.year]: [DateInputFormatTypes.day, DateInputFormatTypes.month, DateInputFormatTypes.year],
};

const dateInputFormats: Record<DateInputFormatTypes, string[]> = {
  [DateInputFormatTypes.day]: ['yyyy-MM-dd', 'dd/MM/yyyy', 'yyyy-MM-dd\'T\'HH:mm:ss\'Z\''],
  [DateInputFormatTypes.month]: ['yyyy-MM', 'MM/yyyy'],
  [DateInputFormatTypes.year]: ['yyyy'],
};

export const getDateTypeLabel = (type: DateStorageTypeKeys, periodicity: PeriodicityType): string => {
  switch (type) {
    case DateStorageTypeKeys.date:
      return i18n`Date`;
    case DateStorageTypeKeys.constraint:
      return i18n`Use Constraint`;
    case DateStorageTypeKeys.duration:
      return i18n`Duration`;
    case DateStorageTypeKeys.last:
      switch (periodicity) {
        case PeriodicityType.day:
          return i18n`Current day -`;
        case PeriodicityType.month:
          return i18n`Current month -`;
        case PeriodicityType.quarter:
          return i18n`Current quarter -`;
        case PeriodicityType.week:
          return i18n`Current week -`;
        case PeriodicityType.year:
          return i18n`Current year -`;
        default:
          throw newError('Unsupported periodicity type');
      }
    case DateStorageTypeKeys.next:
      switch (periodicity) {
        case PeriodicityType.day:
          return i18n`Current day +`;
        case PeriodicityType.month:
          return i18n`Current month +`;
        case PeriodicityType.quarter:
          return i18n`Current quarter +`;
        case PeriodicityType.week:
          return i18n`Current week +`;
        case PeriodicityType.year:
          return i18n`Current year +`;
        default:
          throw newError('Unsupported periodicity type');
      }
    default:
      throw newError('Unsupported date type');
  }
};

const getPeriodicityLabel = (periodicity: PeriodicityType): string => {
  switch (periodicity) {
    case PeriodicityType.day:
      return i18n`Daily`;
    case PeriodicityType.week:
      return i18n`Weekly`;
    case PeriodicityType.month:
      return i18n`Monthly`;
    case PeriodicityType.quarter:
      return i18n`Quarterly`;
    case PeriodicityType.year:
      return i18n`Yearly`;
    default:
      throw newError('Unsupported periodicity type');
  }
};

const getPeriodicityPeriodLabel = (periodicity: PeriodicityType): string => {
  switch (periodicity) {
    case PeriodicityType.day:
      return i18n`day`;
    case PeriodicityType.week:
      return i18n`week`;
    case PeriodicityType.month:
      return i18n`month`;
    case PeriodicityType.quarter:
      return i18n`quarter`;
    case PeriodicityType.year:
      return i18n`year`;
    default:
      throw newError('Unsupported periodicity type');
  }
};

export const getPeriodicityOptions = (): { id: PeriodicityType, label: string }[] => (
  Object.values(PeriodicityType).map((periodicity) => ({ id: periodicity, label: getPeriodicityLabel(periodicity) }))
);

export const getPeriodicityOption = (periodicity: PeriodicityType | undefined): { id: PeriodicityType, label: string } | undefined => {
  if (periodicity && Object.values(PeriodicityType).includes(periodicity)) {
    return { id: periodicity, label: getPeriodicityLabel(periodicity) };
  } else {
    return undefined;
  }
};

export const formatDate = (
  date: StoredDateObject | number | undefined,
  periodicity = PeriodicityType.day
): { date: Date | undefined, dateFormatted: string | undefined, error: string | undefined } => {
  const { from: dateValue, error } = computeEffectiveRangeForPeriodAndDates(periodicity, date);
  const dateFormatted = dateValue ? getFormattedTextDateByPeriod(dateValue, periodicity) : undefined;
  return {
    date: dateValue,
    dateFormatted,
    error,
  };
};

export const formatDateRange = (
  dateRange: DateRange | undefined,
  startConstraint?: number,
  defaultPeriodicity = PeriodicityType.day,
  displayStartConstraintIfNoValue = false
): {
  fromIcon: IconName | undefined,
  fromTooltipText: string | undefined,
  toIcon: IconName | undefined,
  toTooltipText: string | undefined,
  dateFromFormatted: string | undefined,
  dateToFormatted: string | undefined,
  text: string | undefined,
  error: string | undefined,
  from: Date | undefined,
  to: Date | undefined,
} => {
  const periodicity = dateRange?.period ?? defaultPeriodicity;
  const { from: dateFrom, to: dateTo } = dateRange ?? {};
  const effectiveValueFrom = computeEffectiveValueFrom(dateRange, startConstraint, periodicity);
  const { from, to, error } = computeEffectiveRangeForPeriodAndDates(periodicity, effectiveValueFrom, dateRange?.to);

  const fromValue = typeof dateFrom === 'object' ? dateFrom?.value : dateFrom;
  const toValue = typeof dateTo === 'object' ? dateTo?.value : dateTo;

  const relativeFromDate = dateFrom?.type && [DateStorageTypeKeys.last, DateStorageTypeKeys.next].includes(dateFrom.type);
  const relativeToDate = (dateTo?.type && [DateStorageTypeKeys.last, DateStorageTypeKeys.next].includes(dateTo.type))
    || (relativeFromDate && DateStorageTypeKeys.duration === dateTo?.type);

  let dateFromFormatted = from ? getFormattedTextDateByPeriod(from, periodicity) : undefined;
  const { type: dateFromType, value: dateFromValue } = (dateFrom ?? {}) as StoredDateObject;
  if (displayStartConstraintIfNoValue && !effectiveValueFrom && dateFromType === DateStorageTypeKeys.constraint) {
    if (dateFromValue && dateFromValue > 0) {
      dateFromFormatted = i18n`[start constraint] +${dateFromValue} ${getPeriodicityPeriodLabel(periodicity)}`;
    } else {
      dateFromFormatted = i18n`[start constraint]`;
    }
  }
  let dateToFormatted = to ? getFormattedTextDateByPeriod(to, periodicity) : undefined;
  const { type: dateToType, value: dateToValue } = (dateTo ?? {}) as StoredDateObject;
  if (displayStartConstraintIfNoValue && !dateToFormatted && dateFromType === DateStorageTypeKeys.constraint && dateToType === DateStorageTypeKeys.duration) {
    if (dateToValue === 0) {
      dateToFormatted = dateFromFormatted;
    } else {
      dateToFormatted = i18n`${dateToValue ?? 0} ${getPeriodicityPeriodLabel(periodicity)} after`;
    }
  }

  let toIcon;
  if (relativeToDate) {
    toIcon = IconName.bolt;
  }
  let fromIcon;
  if (relativeFromDate) {
    fromIcon = IconName.bolt;
  }

  const dateToIconTooltip = (date: StoredDateObject | number | undefined): string | undefined => {
    if (date === undefined || typeof date === 'number' || !date.type) {
      return undefined;
    } else {
      return `${getDateTypeLabel(date.type, periodicity)} ${date.value} ${periodicity}`;
    }
  };

  if (from && to) {
    if (dateFromFormatted === dateToFormatted) {
      return {
        fromIcon,
        fromTooltipText: dateToIconTooltip(dateFrom),
        toIcon,
        toTooltipText: dateToIconTooltip(dateTo),
        dateFromFormatted: `${dateFromFormatted === undefined ? '-' : dateFromFormatted}`,
        dateToFormatted: `${dateFromFormatted === undefined ? '-' : dateFromFormatted}`,
        // If fromValue and toValue are undefined, there is an Invalid Date involve
        text: `${fromValue == null && toValue != null ? '- ' : ''}${dateFromFormatted}${(toValue == null && fromValue != null) ? ' -' : ''}`,
        error,
        from,
        to,
      };
    }
    return {
      fromIcon,
      fromTooltipText: dateToIconTooltip(dateFrom),
      toIcon,
      toTooltipText: dateToIconTooltip(dateTo),
      dateFromFormatted,
      dateToFormatted,
      text: `${dateFromFormatted} - ${dateToFormatted}`,
      error,
      from,
      to,
    };
  } else {
    let text = fromValue !== undefined && toValue !== undefined ? `${dateFromFormatted} - ${dateToFormatted}` : undefined;
    if (dateFromFormatted === dateToFormatted) {
      text = dateFromFormatted;
    }
    return {
      fromIcon,
      fromTooltipText: dateToIconTooltip(dateFrom),
      toIcon,
      toTooltipText: dateToIconTooltip(dateTo),
      dateFromFormatted,
      dateToFormatted,
      text,
      error,
      from,
      to,
    };
  }
};

const isFormatAcceptedForPeriodicity = (format: string, periodicity: PeriodicityType) => {
  const supportedFormatTypes = supportedDateInputFormats[periodicity];
  const supportedFormats = supportedFormatTypes.flatMap((formatType) => dateInputFormats[formatType]);
  return supportedFormats.includes(format);
};

export const getClipboardDateFormat = (timeString: unknown): string | undefined => {
  const allFormats = Object.values(dateInputFormats).flat();
  return typeof timeString === 'string' ? allFormats.find((format) => isDateValid(getDateFromString(timeString, format))) : undefined;
};

export const isClipboardDateAcceptedForPeriodicity = (timeString: string, periodicity: PeriodicityType): boolean => {
  const format = getClipboardDateFormat(timeString);
  if (!format) {
    return false;
  }
  const formatAcceptedForPeriodicity = isFormatAcceptedForPeriodicity(format, periodicity);
  if (!formatAcceptedForPeriodicity) {
    return false;
  }
  if (periodicity === PeriodicityType.day) {
    return true;
  }
  const parsedDate = getDateFromString(timeString, format);
  if (periodicity === PeriodicityType.week) {
    return parsedDate.getDay() === 1; // Accept only mondays
  } else if (periodicity === PeriodicityType.month && dateInputFormats[DateInputFormatTypes.day].includes(format)) {
    return parsedDate.getDate() === 1; // Accept only 1st
  } else if (periodicity === PeriodicityType.month) {
    return true; // Accept only 1st
  } else if (periodicity === PeriodicityType.quarter && dateInputFormats[DateInputFormatTypes.day].includes(format)) {
    return parsedDate.getDate() === 1 && [0, 3, 6, 9].includes(parsedDate.getMonth()); // Accept only 1st quarter months
  } else if (periodicity === PeriodicityType.quarter && dateInputFormats[DateInputFormatTypes.month].includes(format)) {
    return [0, 3, 6, 9].includes(parsedDate.getMonth()); // Accept only quarter months
  } else if (periodicity === PeriodicityType.quarter) {
    return true;
  } else if (periodicity === PeriodicityType.year && dateInputFormats[DateInputFormatTypes.day].includes(format)) {
    return parsedDate.getDate() === 1 && parsedDate.getMonth() === 0;
  } else if (periodicity === PeriodicityType.year && dateInputFormats[DateInputFormatTypes.month].includes(format)) {
    return parsedDate.getMonth() === 0;
  } else if (periodicity === PeriodicityType.year) {
    return true;
  }
  return false;
};

export const formatENDisplayDate = (date: Date): string => formatDisplayDate(date, dateFormats.localSupport);

export const getMonthsOptions = (): { id: number, label: string }[] => (
  Array.from({ length: 12 }, (_, i) => i)
    .map((number) => ({ label: formatDisplayDate(new Date(2022, number), dateFormats.month), id: number }))
);
