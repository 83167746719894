import type { ObjectStoreAssociation, ObjectStoreReadOnly, StoreObject } from 'yooi-store';
import { CollaborationMessage_Collaboration } from '../collaborationModule/ids';
import { Notification, Notification_Role_Message, Notification_Role_User, Notification_Seen } from './ids';

export const getUnreadNotificationsForUser = (store: ObjectStoreReadOnly, userId: string): ObjectStoreAssociation<StoreObject<string[]>>[] => store
  .withAssociation(Notification)
  .withRole(Notification_Role_User, userId)
  .list()
  .filter((notificationAssociation) => !notificationAssociation.object[Notification_Seen]
    && notificationAssociation.navigateRoleOrNull(Notification_Role_Message)?.navigateOrNull(CollaborationMessage_Collaboration));
