import type { FunctionComponent } from 'react';
import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { DimensionDisplayAxis } from 'yooi-modules/modules/dashboardModule';
import SearchAndSelectMultiple from '../../../../components/molecules/SearchAndSelectMultiple';
import BlockContent from '../../../../components/templates/BlockContent';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { getOption } from '../../modelTypeUtils';
import type { TableViewResolvedDefinition } from './tableViewHandler';

interface TableViewDefinitionGroupByDimensionOptionsProps {
  viewDefinition: TableViewResolvedDefinition,
  viewDimensions: ViewDimension[],
  parameterDefinitions: SingleParameterDefinition[],
  onChange: (dimensionIds: string[]) => void,
  readOnly: boolean,
}

const TableViewDefinitionGroupByDimensionOptions: FunctionComponent<TableViewDefinitionGroupByDimensionOptionsProps> = ({
  viewDefinition,
  viewDimensions,
  parameterDefinitions,
  onChange,
  readOnly,
}) => {
  const store = useStore();

  const groupedDimensionIds = viewDefinition.groupBy?.dimensionIds ?? [];
  const groupedDimensionIdSet = new Set(groupedDimensionIds);

  return (
    <BlockContent>
      <SearchAndSelectMultiple
        selectedOptions={viewDefinition.getDimensionsDisplay(viewDimensions)
          .filter(({ id: dimensionId, axis }) => axis !== DimensionDisplayAxis.y && groupedDimensionIdSet.has(dimensionId))
          .map(({ id: dimensionId }) => getOption(
            store,
            dimensionId,
            parameterDefinitions
          )) ?? []}
        computeOptions={() => viewDefinition.getDimensionsDisplay(viewDimensions)
          .filter(({ axis }) => axis !== DimensionDisplayAxis.y)
          .map(({ id: dimensionId }) => getOption(
            store,
            dimensionId,
            parameterDefinitions
          )) ?? []}
        onSelect={(option) => {
          onChange([...groupedDimensionIds, option.id]);
        }}
        onDelete={(option) => {
          onChange(groupedDimensionIds.filter((id) => id !== option.id) ?? []);
        }}
        placeholder={i18n`Add a dimension`}
        readOnly={readOnly}
      />
    </BlockContent>
  );
};

export default TableViewDefinitionGroupByDimensionOptions;
