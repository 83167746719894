import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import { BlockFieldLayoutOption } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import { WorkflowRelationDisplayType } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import type {
  ConceptDefinitionStoreObject,
  Filters,
  ReverseWorkflowFieldRaw,
  ReverseWorkflowFieldStoreObject,
  SingleRelationFieldExportConfiguration,
  TransitionRights,
  WorkflowEntryStoreObject,
  WorkflowFieldEntryRaw,
  WorkflowFieldEntryStoreObject,
  WorkflowFieldRaw,
  WorkflowFieldStoreObject,
  WorkflowFieldTransitionRaw,
  WorkflowFieldTransitionStoreObject,
  WorkflowRaw,
  WorkflowStoreObject,
} from 'yooi-modules/modules/conceptModule';
import {
  FILTER_PARAMETER_CURRENT,
  getConceptDefinitionValidFields,
  InstanceReferenceType,
  ParsedDimensionType,
  parseDimensionMapping,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import type { TransitionConfiguration } from 'yooi-modules/modules/conceptModule/fields/workflowField';
import { WORKFLOW_FIELD_PARAMETER_SUB_FIELD_DIMENSION, workflowFieldHandler } from 'yooi-modules/modules/conceptModule/fields/workflowField';
import {
  ConceptDefinition,
  ConceptDefinition_ChipBackgroundColor,
  ConceptDefinition_Icon,
  Field_ApiAlias,
  Field_Documentation,
  Field_IntegrationOnly,
  Field_IsCore,
  Field_IsDocumentationInline,
  Field_Title,
  ReverseWorkflowField,
  ReverseWorkflowField_ReverseField,
  Workflow,
  Workflow_Name,
  Workflow_TargetedConceptDefinition,
  WorkflowEntry,
  WorkflowEntry_Rank,
  WorkflowEntry_Role_Concept,
  WorkflowEntry_Role_Workflow,
  WorkflowField,
  WorkflowField_ReverseFields,
  WorkflowField_TargetedConceptDefinition,
  WorkflowField_Workflow,
  WorkflowFieldEntry,
  WorkflowFieldEntry_Filters,
  WorkflowFieldEntry_Role_Entry,
  WorkflowFieldEntry_Role_WorkflowField,
  WorkflowFieldFields,
  WorkflowFieldFields_Role_Field,
  WorkflowFieldFields_Role_WorkflowField,
  WorkflowFieldTransition,
  WorkflowFieldTransition_Filters,
  WorkflowFieldTransition_Rights,
  WorkflowFieldTransition_Role_Transition,
  WorkflowFieldTransition_Role_WorkflowField,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareProperty, compareRank, comparing, filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import { TableSortDirection } from '../../../../components/molecules/Table';
import DataTable from '../../../../components/templates/DataTable';
import i18n from '../../../../utils/i18n';
import { formatOrUndef } from '../../../../utils/stringUtils';
import { computeBorderColor, conceptDefinitionChipBackgroundColor } from '../../conceptDisplayUtils';
import type { FilterDefinition } from '../../filter/filterComposite/FilterComposite';
import FilterComposite from '../../filter/filterComposite/FilterComposite';
import { getLoggedUserParameterDefinition } from '../../filter/filterUtils';
import { defaultOptionComparator, getChipOptions, getConceptDefinitionNameOrEntity, getUnknownChip } from '../../modelTypeUtils';
import { createPathConfigurationHandler } from '../../pathConfigurationHandler';
import { getConceptTypeValidator } from '../../pathConfigurationHandlerUtils';
import { getBlockFieldLayoutOption, getLayoutDisplayOption } from '../_global/blockFieldUtils';
import { getApiAliasInitialState, getDocumentationFieldEditionSection, getIntegrationFieldEditionSection } from '../_global/editionHandlerUtils';
import SingleRelationExportConfiguration from '../_global/SingleRelationExportConfiguration';
import { getGenericOperationMetadata } from '../_global/updateOperationHandlerUtils';
import UpdateOperationSelector from '../_global/UpdateOperationSelector';
import { getOptionalWorkflowRelationDisplayTypeOption, getWorkflowRelationDisplayTypeLabel, getWorkflowRelationDisplayTypeOptions } from '../_global/workflowRelationUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  createAndLinkFieldToConceptDefinitions,
  duplicateFieldDimensionWithNewField,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
} from '../fieldDimensionUtils';
import type { FieldEditionOption, FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, FieldComparatorHandler, GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldEditionVariant, FieldIntegrationOnlyDisabled } from '../FieldLibraryTypes';
import WorkflowFieldRenderer from './WorkflowFieldRenderer';
import WorkflowFieldRunTransitionOperationInput from './WorkflowFieldRunTransitionOperationInput';
import WorkflowFieldTransactionConfiguration from './WorkflowFieldTransactionConfiguration';
import WorkflowFieldUpdateOperationInput from './WorkflowFieldUpdateOperationInput';

interface WorkflowFieldDisplayOptions {
  displayType?: WorkflowRelationDisplayType,
}

interface WorkflowFieldBlockDisplayOptions extends WorkflowFieldDisplayOptions, FieldBlockDisplayOptions {}

const defaultDisplayOptions = { displayType: WorkflowRelationDisplayType.Value, layoutDisplayType: BlockFieldLayoutOption.auto } satisfies WorkflowFieldBlockDisplayOptions;

interface EntriesConfiguration {
  [WorkflowFieldEntry_Filters]: Filters,
}

interface WorkflowFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  reverseName: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
  [Field_IntegrationOnly]: boolean | null | undefined,
  [FieldIntegrationOnlyDisabled]: boolean | undefined,
  [WorkflowField_Workflow]: string | null | undefined,
  [WorkflowField_TargetedConceptDefinition]: string | undefined,
  [WorkflowFieldFields]: string[] | null | undefined,
  [WorkflowFieldTransition]: Record<string, {
    [WorkflowFieldTransition_Filters]: Filters | undefined,
    [WorkflowFieldTransition_Rights]: TransitionRights | undefined,
  }> | null | undefined,
  [WorkflowFieldEntry]: Record<string, { [WorkflowFieldEntry_Filters]: Filters | undefined }> | null | undefined,
}

type WorkflowFieldDefinition = GetFieldDefinitionHandler<
  typeof workflowFieldHandler,
  WorkflowFieldConfigurationState,
  WorkflowFieldDisplayOptions,
  WorkflowFieldBlockDisplayOptions,
  SingleRelationFieldExportConfiguration
>;

export const workflowFieldDefinition: WorkflowFieldDefinition = registerFieldDefinition(workflowFieldHandler, {
  configuration: {
    typeIcon: IconName.route,
    getTypeLabel: () => i18n`Workflow`,
    asWidget: false,
    isCreationEnabled: ({ getObject }) => () => getObject(Workflow).navigateBack(Class_Instances).length > 0,
    onCreate: (objectStore) => (editionHandler) => {
      const fieldId = objectStore.createObject<WorkflowFieldRaw>({
        [Instance_Of]: WorkflowField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [Field_ApiAlias]: editionHandler.getValue(Field_ApiAlias),
        [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
        [WorkflowField_Workflow]: editionHandler.getValue(WorkflowField_Workflow),
      });
      linkFieldToFieldDimensions(objectStore, fieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});

      const fieldsToLink = editionHandler.getValue(WorkflowFieldFields) ?? [];
      fieldsToLink.forEach((fieldToLink) => {
        objectStore.withAssociation(WorkflowFieldFields)
          .withRole(WorkflowFieldFields_Role_WorkflowField, fieldId)
          .withRole(WorkflowFieldFields_Role_Field, fieldToLink)
          .updateObject({});
      });

      const currentField = objectStore.getObject<WorkflowFieldStoreObject>(fieldId);
      if (currentField[WorkflowField_TargetedConceptDefinition] !== undefined) {
        const reverseWorkflowFields = currentField.navigateBack<ReverseWorkflowFieldStoreObject>(WorkflowField_ReverseFields);

        if (reverseWorkflowFields.length === 0) {
          const reverseFieldId = objectStore.createObject<ReverseWorkflowFieldRaw>({
            [Instance_Of]: ReverseWorkflowField,
            [Field_Title]: editionHandler.getValue('reverseName'),
            [ReverseWorkflowField_ReverseField]: fieldId,
          });
          createAndLinkFieldToConceptDefinitions(objectStore, reverseFieldId, [currentField[WorkflowField_TargetedConceptDefinition]]);
        }
      }

      return fieldId;
    },
    getEditionOptions: (store) => ({ mode, editionHandler, readOnly, modelTypeId, variant }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode, FieldEditionOptionMode.EverythingButFieldSpecific].includes(mode)) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      const dataOptions: FieldEditionOption[] = [];

      const workflowId = editionHandler.getValue(WorkflowField_Workflow);
      const targetedConceptDefinitionId = editionHandler.getValue(WorkflowField_TargetedConceptDefinition);
      dataOptions.push({
        key: WorkflowField_Workflow,
        title: i18n`Workflow`,
        hasValue: () => workflowId !== undefined,
        clearValue: () => editionHandler.updateValues({ [WorkflowField_Workflow]: null }),
        type: EditionOptionTypes.select,
        props: {
          readOnly,
          selectedOption: typeof workflowId === 'string' ? getChipOptions(store, workflowId) ?? getUnknownChip(workflowId) : undefined,
          computeOptions: () => {
            const currentWorkflow = workflowId === undefined ? undefined : store.getObjectOrNull(workflowId);
            const expectedConceptDefinition = targetedConceptDefinitionId ?? currentWorkflow?.[Workflow_TargetedConceptDefinition];
            if (workflowId !== undefined && expectedConceptDefinition === undefined) {
              // Targeting a deleted workflow, no options/updates available
              return [];
            }

            return store.getObject(Workflow)
              .navigateBack<WorkflowStoreObject>(Class_Instances)
              .filter((workflow) => (targetedConceptDefinitionId === undefined || workflow[Workflow_TargetedConceptDefinition] === expectedConceptDefinition))
              .map(({ id }) => getChipOptions(store, id))
              .filter(filterNullOrUndefined)
              .sort(defaultOptionComparator);
          },
          onChange: (value) => {
            editionHandler.updateValues({ [WorkflowField_Workflow]: (value?.id as string | undefined) ?? null });
          },
          getInlineCreation: () => ({
            type: 'inline',
            onCreate: (title) => {
              const newWorkflowId = store.createObject<WorkflowRaw>({ [Instance_Of]: Workflow, [Workflow_Name]: title });
              editionHandler.updateValues({ [WorkflowField_Workflow]: newWorkflowId });
              return newWorkflowId;
            },
          }),
          placeholder: i18n`Select workflow`,
          required: true,
        },
      });

      const workflowFields = editionHandler.getValue(WorkflowFieldFields) ?? [];
      dataOptions.push({
        key: WorkflowFieldFields,
        title: i18n`Applied on fields`,
        info: i18n`If you want to see a workflow on a field, don't forget to select it in its display options (Layout tab)`,
        hasValue: () => workflowFields.length > 0,
        clearValue: () => editionHandler.updateValues({ [WorkflowFieldFields]: [] }),
        type: EditionOptionTypes.selectMultiple,
        props: {
          readOnly,
          placeholder: i18n`Fields`,
          computeOptions: () => getConceptDefinitionValidFields(store, modelTypeId)
            .map((field) => getChipOptions(store, field.id))
            .filter(filterNullOrUndefined)
            .sort(defaultOptionComparator),
          selectedOptions: workflowFields.map((fieldId) => getChipOptions(store, fieldId)).filter(filterNullOrUndefined),
          onSelect: (option) => {
            editionHandler.updateValues({ [WorkflowFieldFields]: [...workflowFields, option.id] });
          },
          onDelete: (option) => {
            editionHandler.updateValues({ [WorkflowFieldFields]: workflowFields.filter((id) => id !== option.id) });
          },
        },
      });

      const workflowFieldEntries = editionHandler.getValue(WorkflowFieldEntry) ?? {};
      if (workflowId) {
        dataOptions.push({
          key: 'workflowEntryFilter',
          title: i18n`Value condition(s)`,
          hasValue: () => true,
          clearValue: () => editionHandler.updateValues({ [WorkflowFieldEntry]: {} }),
          isVertical: true,
          skipBlockContent: true,
          type: EditionOptionTypes.custom,
          props: {
            render: () => {
              const workflow = editionHandler.getValue(WorkflowField_Workflow);
              const workflowEntries = workflow ? store.withAssociation(WorkflowEntry)
                .withRole(WorkflowEntry_Role_Workflow, workflow)
                .list()
                .sort(compareProperty('object', compareProperty(WorkflowEntry_Rank, compareRank)))
                .map((assoc) => assoc.navigateRole(WorkflowEntry_Role_Concept)) : [];
              return (
                <DataTable
                  list={workflowEntries.map((item) => ({ key: item.id, type: 'item', item, color: undefined }))}
                  columnsDefinition={[
                    {
                      name: i18n`Entry`,
                      propertyId: WorkflowFieldEntry,
                      cellRender: (entry) => (
                        <SearchAndSelect
                          readOnly
                          selectedOption={getChipOptions(store, entry.id)}
                          computeOptions={() => []}
                        />
                      ),
                    },
                    {
                      name: i18n`Filters`,
                      propertyId: WorkflowFieldEntry_Filters,
                      cellRender: (entry) => {
                        const viewFilters = workflowFieldEntries[entry.id]?.[WorkflowFieldEntry_Filters];
                        const filtersDefinition: FilterDefinition = {
                          updateFilters: (filters: Filters[]) => {
                            editionHandler.updateValues({
                              [WorkflowFieldEntry]: joinObjects(
                                workflowFieldEntries,
                                { [entry.id]: { [WorkflowFieldEntry_Filters]: filters[0] } }
                              ),
                            });
                          },
                          definition: [
                            {
                              filter: viewFilters,
                            },
                          ],
                        };
                        return (
                          <FilterComposite
                            filtersDefinition={filtersDefinition}
                            rootPath={{
                              label: i18n`Current (${formatOrUndef(getConceptDefinitionNameOrEntity(store, modelTypeId))})`,
                              path: [
                                { type: PathStepType.dimension, conceptDefinitionId: modelTypeId },
                                { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
                              ],
                            }}
                            requiredConceptDefinitionId={modelTypeId}
                            parameterDefinitions={[
                              { id: FILTER_PARAMETER_CURRENT, label: i18n`Current`, typeId: modelTypeId, type: 'parameter' },
                              getLoggedUserParameterDefinition(),
                            ]}
                          />
                        );
                      },
                    },
                  ]}
                  fullWidth={variant === FieldEditionVariant.composite}
                />
              );
            },
          },
        });

        dataOptions.push({
          key: WorkflowFieldTransition,
          title: i18n`Transition condition(s) & user rights`,
          hasValue: () => true,
          clearValue: () => editionHandler.updateValues({ [WorkflowFieldTransition]: {} }),
          isVertical: true,
          skipBlockContent: true,
          type: EditionOptionTypes.custom,
          props: {
            render: () => (
              <WorkflowFieldTransactionConfiguration
                workflowId={editionHandler.getValue(WorkflowField_Workflow)}
                workflowTransitions={editionHandler.getValue(WorkflowFieldTransition) ?? {}}
                modelTypeId={modelTypeId}
                onUpdate={(transitions) => editionHandler.updateValues({ [WorkflowFieldTransition]: transitions })}
                fullWidth={variant === FieldEditionVariant.composite}
              />
            ),
          },
        });

        const fieldTitle = editionHandler.getValue(Field_Title);
        dataOptions.push({
          key: 'reverseName',
          title: i18n`Reverse name`,
          hasValue: () => editionHandler.getValue('reverseName') !== undefined,
          clearValue: () => editionHandler.updateValues({ reverseName: null }),
          type: EditionOptionTypes.text,
          props: {
            placeholder: fieldTitle ? i18n`Reverse of "${fieldTitle}"` : i18n`Add reverse name`,
            value: editionHandler.getValueOrDefault('reverseName'),
            onChange: (value) => editionHandler.updateValues({ reverseName: value }),
          },
        });
      }

      sections.push({
        key: 'data',
        type: 'section',
        title: i18n`Data`,
        options: dataOptions,
      });

      if ([FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        sections.push(getDocumentationFieldEditionSection(editionHandler));
        sections.push(getIntegrationFieldEditionSection(store, editionHandler, mode));
      }

      return sections;
    },
    inlineCreate: (objectStore) => (conceptDefinitionId, extraFieldOptions) => ({
      type: 'inline',
      onCreate: (title) => {
        const newFieldId = objectStore.createObject<WorkflowFieldRaw>(joinObjects(
          extraFieldOptions,
          {
            [Instance_Of]: WorkflowField,
            [Field_Title]: title,
          }
        ));
        createAndLinkFieldToConceptDefinitions(objectStore, newFieldId, [conceptDefinitionId]);
        return newFieldId;
      },
    }),
    ofField: (objectStore, fieldId, handler) => ({
      getIcon: () => {
        const targetType = handler.getTargetType?.();
        if (targetType && isInstanceOf<ConceptDefinitionStoreObject>(targetType, ConceptDefinition)) {
          return {
            name: targetType[ConceptDefinition_Icon] as IconName,
            borderColor: computeBorderColor(targetType[ConceptDefinition_ChipBackgroundColor] ?? conceptDefinitionChipBackgroundColor),
            color: conceptDefinitionChipBackgroundColor,
          };
        } else {
          return undefined;
        }
      },
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<WorkflowFieldStoreObject>(fieldId);
        const reverseField = field.navigateBack<ReverseWorkflowFieldStoreObject>(WorkflowField_ReverseFields).at(0);
        return joinObjects(
          getApiAliasInitialState(objectStore, fieldId),
          {
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            reverseName: reverseField?.[Field_Title],
            [Field_IntegrationOnly]: field[Field_IntegrationOnly],
            [FieldIntegrationOnlyDisabled]: field[Field_IsCore],
            [WorkflowField_Workflow]: field[WorkflowField_Workflow],
            [WorkflowField_TargetedConceptDefinition]: field[WorkflowField_TargetedConceptDefinition],
            [WorkflowFieldFields]: objectStore.withAssociation(WorkflowFieldFields)
              .withRole(WorkflowFieldFields_Role_WorkflowField, fieldId)
              .list()
              .map((assoc) => assoc.role(WorkflowFieldFields_Role_Field)),
            [WorkflowFieldTransition]: Object.fromEntries(
              objectStore.withAssociation(WorkflowFieldTransition)
                .withRole(WorkflowFieldTransition_Role_WorkflowField, fieldId)
                .list<WorkflowFieldTransitionStoreObject>()
                .map((assoc) => ([assoc.role(WorkflowFieldTransition_Role_Transition), {
                  [WorkflowFieldTransition_Filters]: assoc.object[WorkflowFieldTransition_Filters],
                  [WorkflowFieldTransition_Rights]: assoc.object[WorkflowFieldTransition_Rights],
                }]))
            ),
            [WorkflowFieldEntry]: Object.fromEntries(objectStore.withAssociation(WorkflowFieldEntry)
              .withRole(WorkflowFieldEntry_Role_WorkflowField, fieldId)
              .list<WorkflowFieldEntryStoreObject>()
              .map((assoc) => [assoc.role(WorkflowFieldEntry_Role_Entry), { [WorkflowFieldEntry_Filters]: assoc.object[WorkflowFieldEntry_Filters] }])),
          }
        );
      },
      submitFieldUpdate: (stateToSubmit) => {
        objectStore.updateObject<WorkflowFieldRaw>(fieldId, {
          [Field_Title]: stateToSubmit[Field_Title],
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: stateToSubmit[Field_IntegrationOnly],
          [WorkflowField_Workflow]: stateToSubmit[WorkflowField_Workflow],
        });

        const currentField = objectStore.getObject<WorkflowFieldStoreObject>(fieldId);
        if (currentField[WorkflowField_TargetedConceptDefinition] !== undefined) {
          const reverseWorkflowFields = currentField.navigateBack<ReverseWorkflowFieldStoreObject>(WorkflowField_ReverseFields);

          if (reverseWorkflowFields.length === 0) {
            const reverseFieldId = objectStore.createObject<ReverseWorkflowFieldRaw>({
              [Instance_Of]: ReverseWorkflowField,
              [Field_Title]: stateToSubmit.reverseName ?? undefined,
              [ReverseWorkflowField_ReverseField]: fieldId,
            });
            createAndLinkFieldToConceptDefinitions(objectStore, reverseFieldId, [currentField[WorkflowField_TargetedConceptDefinition]]);
          } else {
            objectStore.updateObject(reverseWorkflowFields[0].id, { [Field_Title]: stateToSubmit.reverseName });
          }
        }

        const fieldList = stateToSubmit[WorkflowFieldFields] as string[] ?? [];

        const associationList = objectStore.withAssociation(WorkflowFieldFields)
          .withRole(WorkflowFieldFields_Role_WorkflowField, fieldId)
          .list();

        associationList.filter((assoc) => !fieldList.includes(assoc.role(WorkflowFieldFields_Role_Field)))
          .forEach((assocToRemove) => {
            objectStore.deleteObject(assocToRemove.object.id);
          });

        fieldList
          .filter((newFieldId) => !associationList.map((assoc) => assoc.role(WorkflowFieldFields_Role_Field)).includes(newFieldId))
          .forEach((fieldToLink) => {
            objectStore.withAssociation(WorkflowFieldFields)
              .withRole(WorkflowFieldFields_Role_WorkflowField, fieldId)
              .withRole(WorkflowFieldFields_Role_Field, fieldToLink)
              .updateObject({});
          });

        const transitionsConfiguration = stateToSubmit[WorkflowFieldTransition] as Record<string, TransitionConfiguration> ?? {};
        Object.entries(transitionsConfiguration).forEach(([transitionId, configuration]) => {
          objectStore
            .withAssociation(WorkflowFieldTransition)
            .withRole(WorkflowFieldTransition_Role_Transition, transitionId)
            .withRole(WorkflowFieldTransition_Role_WorkflowField, fieldId)
            .updateObject<WorkflowFieldTransitionRaw>({ ...configuration });
        });
        const entriesConfiguration = stateToSubmit[WorkflowFieldEntry] as Record<string, EntriesConfiguration> ?? {};
        Object.entries(entriesConfiguration).forEach(([entryId, configuration]) => {
          objectStore
            .withAssociation(WorkflowFieldEntry)
            .withRole(WorkflowFieldEntry_Role_Entry, entryId)
            .withRole(WorkflowFieldEntry_Role_WorkflowField, fieldId)
            .updateObject<WorkflowFieldEntryRaw>({ ...configuration });
        });
      },
      duplicateFieldDefinition: () => {
        const field = objectStore.getObject<WorkflowFieldStoreObject>(fieldId);

        const fieldDimensionMapping = generateDuplicatedFieldDimensionId(objectStore, fieldId);

        const newFieldId = objectStore.createObject<WorkflowFieldRaw>({
          [Instance_Of]: field[Instance_Of],
          [Field_Title]: `${field[Field_Title]} (copy)`,
          [Field_Documentation]: field[Field_Documentation],
          [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: field[Field_IntegrationOnly],
          [WorkflowField_Workflow]: field.navigateOrNull(WorkflowField_Workflow)?.id,
          [WorkflowField_TargetedConceptDefinition]: field[WorkflowField_TargetedConceptDefinition],
        });

        if (field[WorkflowField_TargetedConceptDefinition] !== undefined) {
          const reverseField = field.navigateBack(WorkflowField_ReverseFields).at(0);
          const reverseFieldId = objectStore.createObject<ReverseWorkflowFieldRaw>({
            [Instance_Of]: ReverseWorkflowField,
            [Field_Title]: `${reverseField?.[Field_Title]} (copy)`,
            [ReverseWorkflowField_ReverseField]: newFieldId,
          });
          createAndLinkFieldToConceptDefinitions(objectStore, reverseFieldId, [field[WorkflowField_TargetedConceptDefinition]]);
        }

        objectStore.withAssociation(WorkflowFieldFields)
          .withRole(WorkflowFieldFields_Role_WorkflowField, fieldId)
          .list()
          .forEach((assoc) => {
            objectStore.withAssociation(WorkflowFieldFields)
              .withRole(WorkflowFieldFields_Role_WorkflowField, newFieldId)
              .withRole(WorkflowFieldFields_Role_Field, assoc.role(WorkflowFieldFields_Role_Field))
              .updateObject({});
          });

        objectStore.withAssociation(WorkflowFieldEntry)
          .withRole(WorkflowFieldEntry_Role_WorkflowField, fieldId)
          .list()
          .forEach((assoc) => {
            objectStore
              .withAssociation(WorkflowFieldEntry)
              .withRole(WorkflowFieldEntry_Role_WorkflowField, newFieldId)
              .withRole(WorkflowFieldEntry_Role_Entry, assoc.role(WorkflowFieldEntry_Role_Entry))
              .updateObject({ [WorkflowFieldEntry_Filters]: assoc.object[WorkflowFieldEntry_Filters] });
          });

        objectStore.withAssociation(WorkflowFieldTransition)
          .withRole(WorkflowFieldTransition_Role_WorkflowField, fieldId)
          .list()
          .forEach((assoc) => {
            objectStore
              .withAssociation(WorkflowFieldTransition)
              .withRole(WorkflowFieldTransition_Role_WorkflowField, newFieldId)
              .withRole(WorkflowFieldTransition_Role_Transition, assoc.role(WorkflowFieldTransition_Role_Transition))
              .updateObject({
                [WorkflowFieldTransition_Rights]: assoc.object[WorkflowFieldTransition_Rights],
                [WorkflowFieldTransition_Filters]: assoc.object[WorkflowFieldTransition_Filters],
              });
          });

        duplicateFieldDimensionWithNewField(objectStore, newFieldId, fieldDimensionMapping);
        return newFieldId;
      },
    }),
  },
  renderField: (_, fieldId) => ({ dimensionsMapping, readOnly, fieldDisplayOptions, focusOnMount }) => {
    const { [WORKFLOW_FIELD_PARAMETER_SUB_FIELD_DIMENSION]: subFieldId, ...dimensions } = dimensionsMapping;
    const parsedDimensionMapping = parseDimensionMapping(dimensions);
    if (parsedDimensionMapping.type !== ParsedDimensionType.MonoDimensional) {
      return null;
    }

    return (
      <WorkflowFieldRenderer
        fieldId={fieldId}
        subFieldId={subFieldId}
        conceptId={parsedDimensionMapping.objectId}
        readOnly={readOnly}
        displayType={fieldDisplayOptions?.displayType ?? defaultDisplayOptions.displayType}
        focusOnMount={focusOnMount}
      />
    );
  },
  getUpdateOperationInput: (store, fieldId, { getTargetType, updateOperationHandlers: { REPLACE, RUN_TRANSITION } }) => (operation) => {
    const { title, getIcon } = getGenericOperationMetadata(store, operation, fieldId);
    return {
      title,
      getIcon,
      render: ({ onSubmit, parameterDefinitions }) => {
        const operationSelectorLine = (
          <UpdateOperationSelector<typeof workflowFieldHandler>
            selectedOperationAction={operation?.action}
            operationOptions={[
              { id: 'REPLACE', label: i18n`Replace`, onSelect: () => onSubmit({ action: 'REPLACE', payload: REPLACE.sanitizeOperation(operation) }) },
              { id: 'RUN_TRANSITION', label: i18n`Run Transition`, onSelect: () => onSubmit({ action: 'RUN_TRANSITION', payload: RUN_TRANSITION.sanitizeOperation(operation) }) },
            ]}
          />
        );

        let operationInputsLines = null;
        const targetTypeId = getTargetType?.()?.id;
        const { workflowId } = workflowFieldHandler(store, fieldId).resolveConfiguration();
        if (targetTypeId && workflowId && operation && operation.action === 'REPLACE' && operation.payload) {
          operationInputsLines = (
            <WorkflowFieldUpdateOperationInput
              workflowId={workflowId}
              targetTypeId={targetTypeId}
              initialValue={operation.payload}
              onTypeChange={(newType) => {
                onSubmit({ action: operation.action, payload: newType === 'value' ? { type: 'value', value: undefined } : { type: 'path', path: [] } });
              }}
              onValueSubmit={(newValue) => onSubmit({ action: operation.action, payload: newValue })}
              parameterDefinitions={parameterDefinitions}
              valuePathHandler={createPathConfigurationHandler(store, parameterDefinitions, [getConceptTypeValidator(store, false, targetTypeId)])}
            />
          );
        } else if (workflowId && operation && operation.action === 'RUN_TRANSITION' && operation.payload) {
          operationInputsLines = (
            <WorkflowFieldRunTransitionOperationInput
              workflowId={workflowId}
              initialValue={operation.payload}
              onValueSubmit={(newValue) => onSubmit({ action: operation.action, payload: newValue })}
            />
          );
        }

        return (
          <>
            {operationSelectorLine}
            {operationInputsLines}
          </>
        );
      },
    };
  },
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({ propertyId: fieldId, sortable: true, focusable: true }),
  renderExportConfiguration: () => ({ configuration, conceptDefinitionId, onChange }) => (
    <SingleRelationExportConfiguration configuration={configuration} conceptDefinitionId={conceptDefinitionId} onChange={onChange} />
  ),
  getActivityProperties: (_, fieldId) => () => [fieldId],
  getComparatorHandler: (store, fieldId, { getValueResolution }) => {
    const workflow = store.getObject(fieldId).navigateOrNull(WorkflowField_Workflow);
    if (workflow) {
      return (direction) => ({
        comparator: comparing(compareRank, direction === TableSortDirection.desc),
        extractValue: (dimensionsMapping) => {
          const selectedValueId = getValueResolution(dimensionsMapping).value.value?.id;

          if (selectedValueId) {
            return store.withAssociation(WorkflowEntry)
              .withRole(WorkflowEntry_Role_Workflow, workflow.id)
              .withRole(WorkflowEntry_Role_Concept, selectedValueId)
              .getObjectOrNull<WorkflowEntryStoreObject>()
              ?.[WorkflowEntry_Rank];
          } else {
            return undefined;
          }
        },
      } satisfies FieldComparatorHandler<string | undefined>);
    } else {
      return undefined;
    }
  },
  blockDisplayOptionsHandler: (objectStore) => (fieldBlockDisplayId) => ({
    getDisplayOptions: () => {
      const fieldBlockDisplay = objectStore.getObjectOrNull(fieldBlockDisplayId);
      if (fieldBlockDisplay?.[FieldBlockDisplay_FieldDisplayConfiguration]) {
        return fieldBlockDisplay[FieldBlockDisplay_FieldDisplayConfiguration] as WorkflowFieldBlockDisplayOptions;
      } else {
        return defaultDisplayOptions;
      }
    },
    renderSummary: (state) => [
      getWorkflowRelationDisplayTypeLabel(state.displayType ?? defaultDisplayOptions.displayType),
      getBlockFieldLayoutOption()[state.layoutDisplayType ?? BlockFieldLayoutOption.auto].label,
    ],
    getBlockEditionOptionSections: (state, setState) => [
      getLayoutDisplayOption(state, setState),
      {
        key: 'displayType',
        title: i18n`Display type`,
        action: {
          type: EditionOptionTypes.select,
          props: {
            computeOptions: getWorkflowRelationDisplayTypeOptions,
            selectedOption: getOptionalWorkflowRelationDisplayTypeOption(state.displayType),
            onChange: (selectedOption) => {
              if (selectedOption) {
                setState(joinObjects(state, { displayType: selectedOption.id as WorkflowRelationDisplayType }));
              }
            },
          },
        },
        options: [],
      },
    ],
    onSubmit: (state) => {
      objectStore.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
});
