/* https://github.com/nfriedly/approximate-number */

const UNINT_MAP = {
  THOUSAND: {
    value: 1000,
    symbol: 'k',
  },
  TEN_THOUSAND: {
    value: 10000,
    symbol: 'k',
  },
  MILLION: {
    value: 1000000,
    symbol: 'm',
  },
  BILLION: {
    value: 1000000000,
    symbol: 'b',
  },
  TRILLION: {
    value: 1000000000000,
    symbol: 't',
  },
};

interface Opts {
  decimal?: string | boolean, // Decimal - set to a string (e.g. ',') to use that or set to false to avoid outputting values with a decimal
  round?: boolean, // Round numbers off rather than flooring/truncating. When true, 105000 would become '11m', when false it becomes '10m'
  min10k?: boolean, // Do not abbreviate numbers below 10000. E.g. 9999 would become '9,999' rather than '9k'. (Stack Overflow-style)
  prefix?: string, // Optional string to prepend to the value, e.g. '$'
  suffix?: string, // Optional string to append to the value, e.g. '%'
  capital?: boolean, // Set to true to use capital letters, e.g. 3.9M instead of 3.9m
  precision?: number, // Optional number of significant digits. Must be greater than 0.
}

const formatDec = (num: number, base: number, opts: Opts): string => {
  let innerNum: number | string = num;
  const workingNum = innerNum / base;
  const ROUND = opts.round ? 'round' : 'floor';
  if (opts.decimal === false) {
    innerNum = Math[ROUND](workingNum);
    return innerNum.toString();
  }
  if (opts.precision !== undefined) {
    innerNum = workingNum;
  } else {
    innerNum = workingNum < 10 ? (Math[ROUND](workingNum * 10) / 10) : Math[ROUND](workingNum);
  }
  innerNum = innerNum.toString();
  if (typeof opts.decimal === 'string') {
    innerNum = innerNum.replace('.', opts.decimal);
  }
  return innerNum;
};

export const approximateNumber = (num: number, opts: Opts = {}): string => {
  let numString;

  // if we're working on a negative number, convert it to positive and then prefix the final result with a -
  let innerNum = num;
  const negative = innerNum < 0;
  if (negative) {
    innerNum = Math.abs(innerNum);
  }

  if (opts.precision !== undefined) {
    innerNum = parseFloat(innerNum.toPrecision(opts.precision));
  }

  const thousandsBreak = opts.min10k ? UNINT_MAP.TEN_THOUSAND.value : UNINT_MAP.THOUSAND.value;

  if (!Number.isFinite(innerNum)) {
    numString = 'Infinity';
  } else if (innerNum < thousandsBreak) {
    numString = formatDec(innerNum, 1, opts);
  } else if (opts.precision !== undefined && opts.precision > Math.log10(innerNum)) {
    numString = formatDec(innerNum, 1, opts);
  } else if (innerNum < UNINT_MAP.MILLION.value) {
    numString = `${formatDec(innerNum, UNINT_MAP.THOUSAND.value, opts)}${UNINT_MAP.THOUSAND.symbol}`;
  } else if (innerNum < UNINT_MAP.BILLION.value) {
    numString = `${formatDec(innerNum, UNINT_MAP.MILLION.value, opts)}${UNINT_MAP.MILLION.symbol}`;
  } else if (innerNum < UNINT_MAP.TRILLION.value) {
    numString = `${formatDec(innerNum, UNINT_MAP.BILLION.value, opts)}${UNINT_MAP.BILLION.symbol}`;
  } else {
    numString = `${formatDec(innerNum, UNINT_MAP.TRILLION.value, opts)}${UNINT_MAP.TRILLION.symbol}`;
  }

  if (negative) {
    numString = `-${numString}`;
  }

  if (opts.capital) {
    numString = numString.toUpperCase();
  }

  if (opts.prefix !== undefined) {
    numString = opts.prefix + numString;
  }
  if (opts.suffix !== undefined) {
    numString += opts.suffix;
  }

  return numString;
};
