import type { FunctionComponent } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { ViewDimension, ViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import WidgetContextProvider from '../../../../components/atoms/WidgetContextProvider';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import type { WidgetDisplay } from '../_global/widgetUtils';
import type { BlockFieldProps } from '../FieldLibraryTypes';
import ViewsGroup from './ViewsGroup';

interface ViewsGroupBlockProps {
  fieldId: string,
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinitions: ViewStoredDefinition[],
  layoutParametersMapping: ParametersMapping,
  blockFieldProps: BlockFieldProps,
  widgetDisplay: WidgetDisplay,
}

const ViewsGroupBlock: FunctionComponent<ViewsGroupBlockProps> = ({
  fieldId,
  viewDimensions,
  viewFilters,
  viewDefinitions,
  layoutParametersMapping,
  blockFieldProps,
  widgetDisplay,
}) => (
  <WidgetContextProvider padded={false} backgroundColor={undefined}>
    <ViewsGroup
      fieldId={fieldId}
      viewDimensions={viewDimensions}
      viewFilters={viewFilters}
      viewDefinitions={viewDefinitions}
      parametersMapping={layoutParametersMapping}
      widgetDisplay={widgetDisplay}
      blockFieldProps={blockFieldProps}
      readOnly={blockFieldProps.readOnly}
    />
  </WidgetContextProvider>
);

export default ViewsGroupBlock;
