import type { FunctionComponent } from 'react';
import type { PlatformConfigurationRaw, PlatformConfigurationStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import {
  CurrentPlatformConfiguration,
  PlatformConfiguration_AccentColor,
  PlatformConfiguration_AccentColorType,
  PlatformConfiguration_ColorPalette,
  PlatformConfiguration_ColorPaletteType,
} from 'yooi-modules/modules/platformConfigurationModule/ids';
import { ConfigurationType } from 'yooi-modules/modules/platformConfigurationModule/model';
import { joinObjects } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import ColorPalette from '../../../components/inputs/ColorPalette';
import { getDefaultStandardColors } from '../../../components/inputs/ColorPicker';
import SimpleInput from '../../../components/inputs/strategy/SimpleInput';
import TextInputString from '../../../components/inputs/TextInputString';
import Banner, { BannerVariant } from '../../../components/molecules/Banner';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../components/molecules/SpacingLine';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../components/templates/BlockTitle';
import DataTable from '../../../components/templates/DataTable';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import base from '../../../theme/base';
import i18n from '../../../utils/i18n';
import { getPlatformAccentColor } from '../../../utils/options';
import StoreColorPickerInput from '../../_global/input/StoreColorPickerInput';
import StoreTextInputField from '../../_global/input/StoreTextInputField';
import type { OptionRecord } from '../../_global/modelTypeUtils';
import { computeLightShade, getColorPalette } from '../../utils/standardColorsUtils';

const ThemeTab: FunctionComponent = () => {
  const store = useStore();

  const platformConfiguration = store.getObject<PlatformConfigurationStoreObject>(CurrentPlatformConfiguration);
  const accentColorType = platformConfiguration[PlatformConfiguration_AccentColorType] ?? ConfigurationType.default;
  const accentColor = platformConfiguration[PlatformConfiguration_AccentColor];

  const colorPaletteType = platformConfiguration[PlatformConfiguration_ColorPaletteType] ?? ConfigurationType.default;
  const colorPalette = platformConfiguration[PlatformConfiguration_ColorPalette] ?? [];

  const themeOptions: OptionRecord<ConfigurationType> = {
    default: { id: ConfigurationType.default, label: i18n`Default` },
    custom: { id: ConfigurationType.custom, label: i18n`Custom` },
  };

  const inUseColorPalette = getColorPalette(store);
  const standardColors = inUseColorPalette ?? getDefaultStandardColors();

  return (
    <VerticalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Interface theme`} />
        <BlockContent>
          <SpacingLine>
            <SearchAndSelect
              selectedOption={themeOptions[accentColorType]}
              computeOptions={() => Object.values(themeOptions)}
              onSelect={(option) => {
                if (option !== null) {
                  store.updateObject<PlatformConfigurationRaw>(CurrentPlatformConfiguration, { [PlatformConfiguration_AccentColorType]: option.id });
                }
              }}
            />
            {
              accentColorType === ConfigurationType.default && getPlatformAccentColor() !== undefined ? (
                <BlockContent padded>
                  <Banner variant={BannerVariant.warning} title={i18n`Theme update will be applied at the next page reload.`} />
                </BlockContent>
              ) : null
            }
          </SpacingLine>
        </BlockContent>
      </HorizontalBlock>
      {
        accentColorType === ConfigurationType.custom ? (
          <HorizontalBlock asBlockContent>
            <BlockTitle title={i18n`Accent color`} />
            <BlockContent>
              <SpacingLine>
                <StoreColorPickerInput
                  colorPalette={inUseColorPalette}
                  initialValue={accentColor}
                  onSubmit={(newColor) => {
                    store.updateObject<PlatformConfigurationRaw>(CurrentPlatformConfiguration, { [PlatformConfiguration_AccentColor]: newColor });
                  }}
                />
                {
                  getPlatformAccentColor() !== accentColor ? (
                    <BlockContent padded>
                      <Banner variant={BannerVariant.warning} title={i18n`Accent color update will be applied at the next page reload.`} />
                    </BlockContent>
                  ) : null
                }
              </SpacingLine>
            </BlockContent>
          </HorizontalBlock>
        ) : null
      }
      <HorizontalBlock asBlockContent>
        <BlockTitle
          title={i18n`Color picker theme`}
          infoTooltip={i18n`You can customize color suggestions in color pickers: Choose the default palette including your accent color or create a custom palette.`}
        />
        <BlockContent>
          <SpacingLine>
            <SearchAndSelect
              selectedOption={themeOptions[colorPaletteType]}
              computeOptions={() => Object.values(themeOptions)}
              onSelect={(option) => {
                if (option !== null) {
                  store.updateObject<PlatformConfigurationRaw>(CurrentPlatformConfiguration, {
                    [PlatformConfiguration_ColorPaletteType]: option.id,
                    [PlatformConfiguration_ColorPalette]: platformConfiguration[PlatformConfiguration_ColorPalette] === undefined ? getDefaultStandardColors() ?? [] : undefined,
                  });
                }
              }}
            />
          </SpacingLine>
        </BlockContent>
      </HorizontalBlock>
      {standardColors ? (
        <HorizontalBlock asBlockContent>
          <BlockTitle title={i18n`Color picker preview`} />
          <BlockContent padded>
            <ColorPalette
              standardColors={standardColors}
            />
          </BlockContent>
        </HorizontalBlock>
      ) : null}
      {colorPaletteType === ConfigurationType.custom ? (
        <VerticalBlock asBlockContent>
          <BlockTitle
            title={i18n`Color picker configuration`}
            variant={BlockTitleVariant.inline}
          />
          <DataTable
            list={colorPalette.map((color, index) => ({
              key: index.toString(),
              type: 'item',
              color: undefined,
              item: joinObjects(color, {
                lightColor: computeLightShade(color.value),
              }),
            }))}
            columnsDefinition={[
              {
                key: 'label',
                propertyId: 'label',
                name: i18n`Color label`,
                cellRender: ({ label }, _, index) => (
                  <StoreTextInputField
                    initialValue={label}
                    onSubmit={(newLabel) => {
                      const newValue = [...colorPalette];
                      newValue[index] = joinObjects(newValue[index], { label: newLabel ?? undefined });
                      store.updateObject<PlatformConfigurationRaw>(CurrentPlatformConfiguration, { [PlatformConfiguration_ColorPalette]: newValue });
                    }}
                  />
                ),
              },
              {
                key: 'color',
                propertyId: 'color',
                name: i18n`Color`,
                cellRender: ({ value }, _, index) => (
                  <StoreColorPickerInput
                    initialValue={value}
                    withoutReset
                    withoutColorPalette
                    onSubmit={(newColor) => {
                      const newValue = [...colorPalette];
                      if (newColor) {
                        newValue[index] = joinObjects(newValue[index], { value: newColor });
                        store.updateObject<PlatformConfigurationRaw>(CurrentPlatformConfiguration, { [PlatformConfiguration_ColorPalette]: newValue });
                      }
                    }}
                  />
                ),
              }, {
                key: 'colorHex',
                propertyId: 'colorHex',
                name: i18n`Color HEX code`,
                cellRender: ({ value }, _, index) => (
                  <SimpleInput<string | undefined>
                    initialValue={value.toLowerCase()}
                    onSubmit={(newColor) => {
                      const newValue = [...colorPalette];
                      if (newColor) {
                        newValue[index] = joinObjects(newValue[index], { value: newColor });
                        store.updateObject<PlatformConfigurationRaw>(CurrentPlatformConfiguration, { [PlatformConfiguration_ColorPalette]: newValue });
                      }
                    }}
                  >
                    {({ ...props }) => (
                      <TextInputString
                        {...props}
                        acceptChars={/^[#a-f0-9]+$/}
                      />
                    )}
                  </SimpleInput>

                ),
              }, {
                key: 'shade',
                propertyId: 'shade',
                name: i18n`Lighter shade`,
                icon: { iconName: IconName.fx, color: IconColorVariant.info, text: i18n`Computed using main color` },
                cellRender: ({ lightColor }) => (
                  <StoreColorPickerInput
                    colorPalette={colorPalette}
                    initialValue={lightColor}
                    readOnly
                    onSubmit={() => {}}
                  />
                ),
              }, {
                key: 'shadeHex',
                propertyId: 'shadeHex',
                name: i18n`Lighter shade HEX code`,
                icon: { iconName: IconName.fx, color: IconColorVariant.info, text: i18n`Computed using main color` },
                cellRender: ({ lightColor }) => (
                  <TextInputString
                    readOnly
                    value={lightColor.toLowerCase()}
                  />
                ),
              }]}
            globalActions={[
              {
                key: 'reset',
                name: i18n`Reset color palette to default`,
                icon: IconName.sync,
                onClick: () => {
                  store.updateObject<PlatformConfigurationRaw>(CurrentPlatformConfiguration, { [PlatformConfiguration_ColorPalette]: getDefaultStandardColors() });
                },
              },
            ]}
            linesActions={(_, index) => [
              {
                key: 'moveUp',
                name: i18n`Move Up`,
                icon: IconName.expand_less,
                hidden: index === 0,
                onClick: () => {
                  const newPalette = [...colorPalette];
                  const previousColor = newPalette[index - 1];
                  newPalette[index - 1] = newPalette[index];
                  newPalette[index] = previousColor;
                  store.updateObject<PlatformConfigurationRaw>(CurrentPlatformConfiguration, { [PlatformConfiguration_ColorPalette]: newPalette });
                },
              },
              {
                key: 'moveDown',
                name: i18n`Move Down`,
                icon: IconName.expand_more,
                hidden: index === colorPalette.length - 1,
                onClick: () => {
                  const newPalette = [...colorPalette];
                  const previousColor = newPalette[index + 1];
                  newPalette[index + 1] = newPalette[index];
                  newPalette[index] = previousColor;
                  store.updateObject<PlatformConfigurationRaw>(CurrentPlatformConfiguration, { [PlatformConfiguration_ColorPalette]: newPalette });
                },
              },
              {
                key: 'delete',
                name: i18n`Delete`,
                icon: IconName.delete,
                onClick: () => {
                  const newPalette = [...colorPalette];
                  newPalette.splice(index, 1);
                  store.updateObject<PlatformConfigurationRaw>(CurrentPlatformConfiguration, { [PlatformConfiguration_ColorPalette]: newPalette });
                },
                danger: true,
              },
            ]}
            newItemIcon={IconName.add}
            newItemTitle={i18n`Add a color`}
            newItemDisabled={colorPalette.length >= 10}
            onNewItem={() => {
              store.updateObject<PlatformConfigurationRaw>(CurrentPlatformConfiguration, { [PlatformConfiguration_ColorPalette]: [...colorPalette, { value: base.color.gray['500'] }] });
            }}
          />
        </VerticalBlock>
      ) : null}
    </VerticalBlock>
  );
};

export default ThemeTab;
