import type { FunctionComponent } from 'react';
import type { ParametersMapping, PathStep } from 'yooi-modules/modules/conceptModule';
import { createValuePathResolver, dimensionsMappingToParametersMapping, isSingleFieldResolution } from 'yooi-modules/modules/conceptModule';
import { MirrorField_Path } from 'yooi-modules/modules/dashboardModule/ids';
import { joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Typo from '../../../../components/atoms/Typo';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import useWidgetSizeContext from '../../../../utils/useWidgetSizeContext';
import { getFieldHandler } from '../FieldLibrary';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: spacingRem.xs,
    paddingRight: spacingRem.xs,
  },
}, 'mirrorWidgetField');

interface MirrorWidgetFieldProps {
  fieldId: string,
  parametersMapping: ParametersMapping,
  readOnly: boolean,
}

const MirrorWidgetField: FunctionComponent<MirrorWidgetFieldProps> = ({ fieldId, parametersMapping, readOnly }) => {
  const store = useStore();
  const { width, height } = useWidgetSizeContext();
  const classes = useStyles();

  const field = store.getObject(fieldId);

  const pathResolver = createValuePathResolver(store, parametersMapping);
  const resolution = pathResolver.resolvePathField(field[MirrorField_Path] as PathStep[] | undefined ?? []);

  if (isSingleFieldResolution(resolution)) {
    const widget = getFieldHandler(store, resolution.fieldId)?.renderWidget?.({
      parametersMapping: joinObjects(dimensionsMappingToParametersMapping(resolution.dimensionsMapping ?? {}), parametersMapping),
      readOnly,
    });
    if (widget) {
      return widget;
    }
  }

  return (
    <div className={classes.container} style={{ width, height }}>
      <SpacingLine>
        <Icon name={IconName.dangerous} colorVariant={IconColorVariant.error} />
        <Typo maxLine={1}>{i18n`Invalid configuration`}</Typo>
      </SpacingLine>
    </div>
  );
};

export default MirrorWidgetField;
