import type { FunctionComponent } from 'react';
import BlockContent from '../../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../../components/templates/VerticalBlock';
import { getFieldEditionOptionRender } from '../../fields/fieldEditionOptionsUtils';
import type { LineEditionOption } from '../../fields/FieldEditionOptionType';
import { EditionOptionTypes } from '../../fields/FieldEditionOptionType';

interface ViewOptionBlockProps {
  option: LineEditionOption,
}

const ViewOptionBlock: FunctionComponent<ViewOptionBlockProps> = ({ option }) => {
  if (option.type === EditionOptionTypes.custom) {
    if (option.isVertical) {
      return (
        <VerticalBlock key={option.key} asBlockContent>
          {
            option.title
              ? (
                <BlockTitle
                  title={option.title}
                  infoTooltip={option.info}
                  errorTooltip={option.error}
                  variant={BlockTitleVariant.inline}
                />
              )
              : null
          }
          {
            option.skipBlockContent
              ? getFieldEditionOptionRender(option)
              : (
                <BlockContent padded={option.padded}>
                  {getFieldEditionOptionRender(option)}
                </BlockContent>
              )
          }
        </VerticalBlock>
      );
    } else {
      return (
        <HorizontalBlock key={option.key} asBlockContent>
          {option.title ? (
            <BlockTitle
              title={option.title}
              infoTooltip={option.info}
              errorTooltip={option.error}
              variant={BlockTitleVariant.inline}
            />
          ) : null}
          <BlockContent padded={option.padded} fullWidth={option.fullWidth}>
            {getFieldEditionOptionRender(option)}
          </BlockContent>
        </HorizontalBlock>
      );
    }
  } else {
    return (
      <HorizontalBlock key={option.key} asBlockContent>
        {option.title ? (
          <BlockTitle title={option.title} infoTooltip={option.info} errorTooltip={option.error} />
        ) : null}
        <BlockContent padded={option.padded}>
          {getFieldEditionOptionRender(option)}
        </BlockContent>
      </HorizontalBlock>
    );
  }
};

export default ViewOptionBlock;
