import type { ComponentProps, FunctionComponent } from 'react';
import { ColorService } from 'react-color-palette';
import ColorPicker from '../../../components/inputs/ColorPicker';
import StoreInput from './StoreInput';

interface StoreColorPickerInputProps extends Omit<ComponentProps<typeof ColorPicker>, 'value' | 'defaultValue' | 'onChange' | 'onSubmit' | 'onCancel'> {
  initialValue: string | undefined,
  defaultValue?: string | undefined,
  onSubmit: (newValue: string | null) => void,
}

const StoreColorPickerInput: FunctionComponent<StoreColorPickerInputProps> = ({ initialValue, defaultValue, onSubmit, ...colorPickerProps }) => (
  <StoreInput
    initialValue={initialValue ? ColorService.convert('hex', initialValue) : undefined}
    onSubmit={(newValue) => {
      onSubmit(newValue?.hex ?? null);
    }}
  >
    {(formInputProps) => (
      <ColorPicker
        defaultValue={defaultValue ? ColorService.convert('hex', defaultValue) : undefined}
        {...colorPickerProps}
        {...formInputProps}
      />
    )}
  </StoreInput>
);

export default StoreColorPickerInput;
