import classnames from 'classnames';
import type { DragEventHandler, EventHandler, FunctionComponent, ReactElement, SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import { joinObjects } from 'yooi-utils';
import base from '../../theme/base';
import type { SpacingDefinitionProp } from '../../theme/spacingDefinition';
import { buildMargins, getSpacing, Spacing, spacingRem } from '../../theme/spacingDefinition';
import type { Theme } from '../../theme/themeUtils';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import { formatOrUndef } from '../../utils/stringUtils';
import useDerivedState from '../../utils/useDerivedState';
import { SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../utils/useUsageContext';
import Typo, { TypoVariant } from '../atoms/Typo';
import TimelineEntryCroppedIndicators, { CroppedDirection } from './TimelineEntryCroppedIndicators';
import TimelineProgress from './TimelineProgress';

export enum TimelineEntryCroppedVariants {
  croppedRight = 'croppedRight',
  croppedLeft = 'croppedLeft',
  full = 'full',
  none = 'none',
}

const activeEntryStyle = (theme: Theme) => ({
  borderColor: theme.color.border.primary,
});

export const heightInRem = 3.2;
export const entryChildHeightRem = 2.6;
const rectSizeInRem = 1.2;
const rectBorderWidthInRem = 0.1;
const rectWidthInRem = rectSizeInRem + (rectBorderWidthInRem * 2);
const entryBorderWidthInRem = 0.1;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
    borderRadius: base.borderRadius.medium,
    boxShadow: base.shadowElevation.low,
  },
  labelAndRectContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  labelContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    overflow: 'hidden',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  rect: {
    alignSelf: 'center',
    width: `${rectSizeInRem}rem`,
    minWidth: `${rectSizeInRem}rem`,
    height: `${rectSizeInRem}rem`,
    borderRadius: base.borderRadius.medium,
    borderWidth: `${rectBorderWidthInRem}rem`,
    borderStyle: 'solid',
  },
  rectChild: {
    borderRadius: base.borderRadius.circle,
  },
  entry: {
    display: 'flex',
    borderWidth: `${entryBorderWidthInRem}rem`,
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
    height: `${heightInRem}rem`,
    lineHeight: `${heightInRem}rem`,
    '&:hover': {
      borderColor: theme.color.border.hover,
    },
    '&:focus': activeEntryStyle(theme),
  },
  entryChild: {
    borderStyle: 'dashed',
    height: `${entryChildHeightRem}rem`,
    lineHeight: `${entryChildHeightRem}rem`,
  },
  textContainer: {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginLeft: spacingRem.text,
    marginRight: spacingRem.text,
  },
  rightTextContainer: {
    display: 'flex',
    gap: getSpacing(Spacing.s),
    overflow: 'hidden',
  },
  idContainer: {
    flexShrink: 0,
  },
  containerWithLabel: {
    // without a set minWidth, ellipsis are not done correctly on a flex row container in firefox.
    minWidth: 0,
  },
  globalContainer: {
    position: 'relative',
  },
  subContainer: {
    position: 'absolute',
    height: '100%',
  },
  rightTextLabel: {
    marginLeft: spacingRem.text,
  },
}), 'timelineEntry');

const getBorderRadius = (cropped: TimelineEntryCroppedVariants) => {
  if (cropped === TimelineEntryCroppedVariants.croppedLeft) {
    return '0 0.4rem 0.4rem 0';
  } else if (cropped === TimelineEntryCroppedVariants.croppedRight) {
    return '0.4rem 0 0 0.4rem';
  } else if (cropped === TimelineEntryCroppedVariants.full) {
    return '0';
  } else {
    return '0.4rem';
  }
};

export interface ProgressFieldData {
  field: string,
  value: number | undefined,
  min: number | undefined,
  max: number | undefined,
}

interface TimelineEntryProps {
  id: string,
  label: { id?: string, name: string | undefined },
  onElementDrag?: ({ id }: { id: string }) => void,
  onElementDragEnd?: ({ id }: { id: string }) => void,
  dragLabel?: string,
  onElementTooltipEditModeOpen?: () => void,
  onElementTooltipEditModeClose?: () => void,
  widthInPx: number,
  margin: SpacingDefinitionProp,
  renderTooltip?: (conceptId: string, editMode: boolean, currentAnchor: HTMLElement, handleClose: () => void, isChild?: boolean) => (ReactElement | null),
  showTooltip?: boolean,
  croppedVariant?: TimelineEntryCroppedVariants,
  isEditedByOtherUser: boolean,
  isChild?: boolean,
  rectColor: string | undefined | null,
  rightText?: string,
  onMouseEnter?: (event: SyntheticEvent) => void,
  onMouseLeave?: (event: SyntheticEvent) => void,
  onDoubleClick: (id: string) => void,
  isDraggable: boolean,
  progress?: ProgressFieldData,
  inEdition?: boolean,
  onEditionStart?: () => void,
  onEditionEnd?: () => void,
}

const TimelineEntry: FunctionComponent<TimelineEntryProps> = ({
  id,
  label,
  onElementDrag,
  onElementDragEnd,
  dragLabel,
  onElementTooltipEditModeOpen,
  onElementTooltipEditModeClose,
  widthInPx,
  margin,
  renderTooltip,
  showTooltip = true,
  croppedVariant = TimelineEntryCroppedVariants.none,
  isEditedByOtherUser,
  isChild,
  rectColor,
  rightText,
  onMouseEnter,
  onMouseLeave,
  onDoubleClick,
  isDraggable,
  progress,
  inEdition,
  onEditionStart,
  onEditionEnd,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const [currentAnchor, setCurrentAnchor] = useState<HTMLElement>();
  const [editMode, setEditMode] = useDerivedState(() => (inEdition === undefined ? false : inEdition), [inEdition]);

  const textVariant = isChild ? TypoVariant.small : TypoVariant.body;
  const showLabel = widthInPx > 40;
  const rectSizeInPx = remToPx(rectWidthInRem);
  const borderRadius = getBorderRadius(croppedVariant);
  const cursor = onElementDrag || isDraggable ? 'move' : 'pointer';
  const rectBorderColor = editMode || currentAnchor ? theme.color.border.primary : rectColor ?? theme.color.border.primarylight;
  const rectColorEffective = editMode ? theme.color.border.primary : rectColor ?? theme.color.border.primarylight;
  // if label is not showed we need to center the rectangle on the card so we set up a custom margin of (width - rectangleSize) / 2.
  const rectMargin = showLabel ? buildMargins({
    left: Spacing.s,
    y: Spacing.none,
  }) : {
    marginLeft: `${(widthInPx - rectSizeInPx) / 2}px`,
  };
  // if the card is too short to contain the rectangle, we need to center it behind the rectangle.
  const divMargin = showLabel ? undefined : { marginLeft: `${Math.max((rectSizeInPx - widthInPx) / 2, 0)}px` };

  useEffect(() => {
    if (!showTooltip) {
      setEditMode(false);
      setCurrentAnchor(undefined);
      onEditionEnd?.();
    }
  }, [showTooltip, onEditionEnd, setEditMode]);

  const handleDrag: DragEventHandler<HTMLDivElement> | undefined = onElementDrag && isDraggable ? (event) => {
    event.dataTransfer.setData('id', id);
    if (dragLabel) {
      event.dataTransfer.setData('label', dragLabel);
    }
    // disable dragGhost
    event.dataTransfer.setDragImage(new Image(), 0, 0);
    onElementDrag({ id });
  } : undefined;

  const handleMouseEnter: EventHandler<SyntheticEvent<HTMLElement>> = (event) => {
    if (!editMode) {
      setCurrentAnchor(event.currentTarget);
    }
    onMouseEnter?.(event);
  };

  const handleMouseLeave: EventHandler<SyntheticEvent<HTMLElement>> = (event) => {
    if (!editMode) {
      setCurrentAnchor(undefined);
    }
    onMouseLeave?.(event);
  };

  const handleClick: EventHandler<SyntheticEvent<HTMLElement>> = (event) => {
    setCurrentAnchor(event.currentTarget);
    setEditMode(true);
    onEditionStart?.();
    if (onElementTooltipEditModeOpen) {
      onElementTooltipEditModeOpen();
    }
  };

  const handleClose = () => {
    setCurrentAnchor(undefined);
    setEditMode(false);
    onEditionEnd?.();
    if (onElementTooltipEditModeClose) {
      onElementTooltipEditModeClose();
    }
  };

  return (
    <SizeContextProvider sizeVariant={isChild ? SizeVariant.small : SizeVariant.main}>
      <UsageContextProvider usageVariant={UsageVariant.inForm}>
        {showTooltip && currentAnchor && renderTooltip && renderTooltip(id, editMode, currentAnchor, handleClose, isChild)}
      </UsageContextProvider>
      <div style={divMargin}>
        <div
          tabIndex={0}
          draggable={Boolean(handleDrag)}
          onDragStart={handleDrag}
          onDragEnd={onElementDragEnd ? () => onElementDragEnd({ id }) : undefined}
          className={classnames({
            [classes.entry]: true,
            [classes.entryChild]: isChild,
          })}
          style={joinObjects(
            {
              cursor,
            },
            // Margin is a component props, no way to move it as classes

            buildMargins(margin),
            {
              width: `${widthInPx}px`,
              borderRadius,
              borderColor: isEditedByOtherUser ? theme.color.border.primary : undefined,
            },
            (showTooltip && editMode ? activeEntryStyle(theme) : {})
          )}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onFocus={handleMouseEnter}
          onBlur={handleMouseLeave}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleClick(event);
            }
          }}
          role="button"
        >
          <div
            className={classnames({ [classes.root]: true, [classes.containerWithLabel]: showLabel, [classes.globalContainer]: true })}
          >
            <div
              className={classnames({ [classes.labelAndRectContainer]: true, [classes.containerWithLabel]: showLabel, [classes.subContainer]: true })}
            >
              <TimelineProgress
                key={progress?.field}
                progress={progress?.value ?? 0}
                min={progress?.min}
                max={progress?.max}
                widthInPx={widthInPx}
                isChild={isChild}
              />
            </div>
            <div
              className={classnames({ [classes.labelAndRectContainer]: true, [classes.containerWithLabel]: showLabel, [classes.subContainer]: true })}
              onDoubleClick={() => onDoubleClick(id)}
            >
              {(croppedVariant === TimelineEntryCroppedVariants.croppedLeft || croppedVariant === TimelineEntryCroppedVariants.full) && (
                <TimelineEntryCroppedIndicators
                  cardWidthInPx={widthInPx}
                  rectSize={rectSizeInPx}
                  withLabel={showLabel}
                  croppedDirection={CroppedDirection.left}
                  isChild={isChild}
                />
              )}
              <div
                style={joinObjects(
                  rectMargin,
                  {
                    backgroundColor: rectColorEffective,
                    borderColor: editMode || currentAnchor ? theme.color.border.primary : rectBorderColor,
                  }
                )}
                className={classnames({
                  [classes.rect]: true,
                  [classes.rectChild]: isChild,
                })}
              />
              <div className={classes.labelContainer}>
                {showLabel && (
                  <div className={classes.textContainer}>
                    <div className={classes.rightTextContainer}>
                      {label.id && (
                        <div className={classes.idContainer}>
                          <Typo noWrap variant={textVariant} color={theme.color.text.secondary}>{label.id}</Typo>
                        </div>
                      )}
                      <Typo noWrap variant={textVariant}>{formatOrUndef(label.name)}</Typo>
                    </div>
                    {rightText && (
                      <div className={classes.rightTextLabel}>
                        <Typo maxLine={1} variant={textVariant} color={rectColor ?? undefined}>
                          {formatOrUndef(rightText)}
                        </Typo>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {(croppedVariant === TimelineEntryCroppedVariants.croppedRight || croppedVariant === TimelineEntryCroppedVariants.full) && (
              <TimelineEntryCroppedIndicators
                cardWidthInPx={widthInPx}
                rectSize={rectSizeInPx}
                withLabel={showLabel}
                croppedDirection={CroppedDirection.right}
                isChild={isChild}
              />
            )}
          </div>
        </div>
      </div>
    </SizeContextProvider>
  );
};

export default TimelineEntry;
