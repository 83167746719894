import { equals } from 'ramda';
import type { FunctionComponent } from 'react';
import type { DocumentationOverride, FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import { fieldDocumentationDisplayOptions, WorkflowRelationDisplayType } from 'yooi-modules/modules/conceptLayoutModule';
import {
  FieldBlockDisplay_DocumentationOverride,
  FieldBlockDisplay_FieldPath,
  FieldBlockDisplay_TitleOverride,
  FieldBlockDisplay_VisibleFromStatus,
  FieldBlockDisplay_VisibleToStatus,
  FieldBlockDisplay_WorkflowField,
  FieldBlockDisplay_WorkflowFieldDisplayType,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { getConceptDefinitionValidFields, getFieldDimensionOfModelType } from 'yooi-modules/modules/conceptModule';
import { Field_Title, WorkflowField, WorkflowFieldFields, WorkflowFieldFields_Role_Field, WorkflowFieldFields_Role_WorkflowField } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { compareBoolean, compareProperty, compareString, comparing, filterNullOrUndefined, joinObjects, pushUndefinedToEnd } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import Typo from '../../../../../components/atoms/Typo';
import Chooser from '../../../../../components/molecules/Chooser';
import type { CompositeDropdownSection } from '../../../../../components/molecules/CompositeField';
import CompositeField, { CompositeFieldCloseReasons } from '../../../../../components/molecules/CompositeField';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import TableInnerCellContainer from '../../../../../components/molecules/TableInnerCellContainer';
import useStore from '../../../../../store/useStore';
import i18n from '../../../../../utils/i18n';
import useDeepMemo from '../../../../../utils/useDeepMemo';
import useDerivedState from '../../../../../utils/useDerivedState';
import { getFieldEditionOptionRender } from '../../../../_global/fields/fieldEditionOptionsUtils';
import { getFieldHandler } from '../../../../_global/fields/FieldLibrary';
import StoreTextInputField from '../../../../_global/input/StoreTextInputField';
import { getChipOptions, getUnknownChip } from '../../../../_global/modelTypeUtils';
import { createPathConfigurationHandler } from '../../../../_global/pathConfigurationHandler';

const getWorkflowRelationDisplayTypeOption = (displayType: WorkflowRelationDisplayType): { id: WorkflowRelationDisplayType, label: string } => ({
  id: displayType,
  label: displayType === WorkflowRelationDisplayType.Color ? i18n`As color at the end of the line` : i18n`As field below the value`,
});

interface DisplayOptionsFieldCellProps {
  fieldBlockDisplayId: string,
  workflowFieldId?: string,
  workflowConceptIds?: string[],
  parameterDefinitions: SingleParameterDefinition[],
  onOpen: () => void,
  onClose: (reason: CompositeFieldCloseReasons) => void,
}

const DisplayOptionsFieldCell: FunctionComponent<DisplayOptionsFieldCellProps> = ({
  fieldBlockDisplayId,
  workflowFieldId,
  workflowConceptIds,
  parameterDefinitions,
  onOpen,
  onClose,
}) => {
  const store = useStore();

  const fieldBlockDisplay = store.getObject(fieldBlockDisplayId);
  const fieldPath = fieldBlockDisplay[FieldBlockDisplay_FieldPath] as PathStep[] | undefined ?? [];

  const pathConfigurationHandler = createPathConfigurationHandler(store, parameterDefinitions);
  const lastField = pathConfigurationHandler.getLastFieldInformation(fieldPath);

  const isValid = !!lastField;
  const fieldHandler = isValid ? getFieldHandler(store, lastField.fieldId) : undefined;
  const dimensionId = isValid && lastField.conceptDefinitionId ? getFieldDimensionOfModelType(store, lastField.fieldId, lastField.conceptDefinitionId) : undefined;
  const handleBlockDisplayOptions = fieldHandler?.blockDisplayOptionsHandler?.(
    fieldBlockDisplay.id,
    dimensionId && lastField?.conceptDefinitionId
      ? [...parameterDefinitions, { id: dimensionId, typeId: lastField.conceptDefinitionId, label: i18n`Current`, type: 'dimension' }]
      : parameterDefinitions
  );

  const initialState: {
    [FieldBlockDisplay_TitleOverride]: string | null | undefined,
    [FieldBlockDisplay_DocumentationOverride]: DocumentationOverride | null | undefined,
    [FieldBlockDisplay_WorkflowField]: string | null | undefined,
    [FieldBlockDisplay_WorkflowFieldDisplayType]: WorkflowRelationDisplayType,
    displayOptions: FieldBlockDisplayOptions,
  } = {
    [FieldBlockDisplay_TitleOverride]: fieldBlockDisplay[FieldBlockDisplay_TitleOverride] as string | null | undefined,
    [FieldBlockDisplay_DocumentationOverride]: fieldBlockDisplay[FieldBlockDisplay_DocumentationOverride] as DocumentationOverride | null | undefined,
    [FieldBlockDisplay_WorkflowField]: fieldBlockDisplay[FieldBlockDisplay_WorkflowField] as string | null | undefined,
    [FieldBlockDisplay_WorkflowFieldDisplayType]: (
      fieldBlockDisplay[FieldBlockDisplay_WorkflowFieldDisplayType] as WorkflowRelationDisplayType | undefined ?? WorkflowRelationDisplayType.Color
    ),
    displayOptions: handleBlockDisplayOptions?.getDisplayOptions() ?? {},
  };

  const memoizedInitialState = useDeepMemo(() => initialState, [initialState]);
  const [state, setState, resetState] = useDerivedState(() => memoizedInitialState, [memoizedInitialState]);

  if (!isValid || !fieldHandler || !lastField || !handleBlockDisplayOptions || !state) {
    return (<TableInnerCellContainer />);
  }

  const { renderSummary, getErrors, getBlockEditionOptionSections, onSubmit } = handleBlockDisplayOptions;

  return (
    <CompositeField
      width="84rem"
      headerLinesRenderers={[
        {
          id: 'main',
          render: () => {
            const options: string[] = [];
            if (state[FieldBlockDisplay_WorkflowField]) {
              options.push(i18n`With workflow`);
            }
            options.push(...renderSummary(state.displayOptions));
            const errors = getErrors?.(state.displayOptions);
            return (
              <SpacingLine>
                <Typo maxLine={1}>{options.join(', ')}</Typo>
                {Boolean(errors?.length) && (
                  <Icon name={IconName.dangerous} colorVariant={IconColorVariant.error} tooltip={errors ? errors.join(', ') : undefined} />
                )}
              </SpacingLine>
            );
          },
        },
      ]}
      getDropdownSectionDefinitions={() => {
        const sections: CompositeDropdownSection[] = [];

        if (lastField.conceptDefinitionId) {
          const workflowFields = getConceptDefinitionValidFields(store, lastField.conceptDefinitionId)
            .filter((field) => isInstanceOf(field, WorkflowField))
            .map((workflowField) => ({
              id: workflowField.id,
              title: workflowField[Field_Title],
              isAssociatedToField: store.withAssociation(WorkflowFieldFields)
                .withRole(WorkflowFieldFields_Role_Field, lastField.fieldId)
                .withRole(WorkflowFieldFields_Role_WorkflowField, workflowField.id)
                .getOrNull(),
            }));
          if (workflowFields.length > 0 || state[FieldBlockDisplay_WorkflowField]) {
            let selectedOption;
            let statusIcon: { message: string, icon: IconName, color: IconColorVariant } | undefined;
            if (state[FieldBlockDisplay_WorkflowField]) {
              const workflowField = store.getObjectOrNull(state[FieldBlockDisplay_WorkflowField]);
              if (workflowField) {
                selectedOption = getChipOptions(store, state[FieldBlockDisplay_WorkflowField]);
                if (!getFieldDimensionOfModelType(store, state[FieldBlockDisplay_WorkflowField], lastField.conceptDefinitionId)) {
                  statusIcon = {
                    icon: IconName.dangerous,
                    color: IconColorVariant.error,
                    message: i18n`Selected workflow is not an instance of the expected concept`,
                  };
                } else if (
                  !store.withAssociation(WorkflowFieldFields)
                    .withRole(WorkflowFieldFields_Role_Field, lastField.fieldId)
                    .withRole(WorkflowFieldFields_Role_WorkflowField, state[FieldBlockDisplay_WorkflowField])
                    .getOrNull()
                ) {
                  if (
                    fieldBlockDisplay[FieldBlockDisplay_WorkflowField] === undefined
                    || fieldBlockDisplay[FieldBlockDisplay_WorkflowField] !== state[FieldBlockDisplay_WorkflowField]
                  ) {
                    statusIcon = { icon: IconName.info, color: IconColorVariant.info, message: i18n`Current field will be automatically added to the selected workflow` };
                  } else {
                    statusIcon = { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Current field is not associated to selected workflow` };
                  }
                }
              } else {
                selectedOption = getUnknownChip(state[FieldBlockDisplay_WorkflowField]);
              }
            }

            const workflowSection: CompositeDropdownSection = {
              id: FieldBlockDisplay_WorkflowField,
              title: i18n`Workflow`,
              useGridForTitle: true,
              action: (
                <SearchAndSelect
                  clearable
                  computeOptions={() => (
                    workflowFields
                      .sort(comparing<typeof workflowFields[0]>(compareProperty('isAssociatedToField', compareBoolean))
                        .thenComparing(compareProperty('title', comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString))))
                      .map((field) => getChipOptions(store, field.id))
                      .filter(filterNullOrUndefined)
                  )}
                  selectedOption={selectedOption}
                  statusIcon={statusIcon}
                  onSelect={(option) => setState((current) => (joinObjects(current, { [FieldBlockDisplay_WorkflowField]: option ? option.id : null })))}
                />
              ),
              lines: [],
            };

            if (state[FieldBlockDisplay_WorkflowField]) {
              workflowSection.lines.push({
                id: FieldBlockDisplay_WorkflowFieldDisplayType,
                title: i18n`Display`,
                render: (
                  <SearchAndSelect
                    selectedOption={
                      state[FieldBlockDisplay_WorkflowFieldDisplayType] ? getWorkflowRelationDisplayTypeOption(state[FieldBlockDisplay_WorkflowFieldDisplayType]) : undefined
                    }
                    onSelect={(option) => {
                      if (option !== null) {
                        setState((current) => (joinObjects(current, { [FieldBlockDisplay_WorkflowFieldDisplayType]: option.id })));
                      }
                    }}
                    computeOptions={() => [WorkflowRelationDisplayType.Color, WorkflowRelationDisplayType.ValueAndButton].map(getWorkflowRelationDisplayTypeOption)}
                  />
                ),
              });
            }

            sections.push(workflowSection);
          }
        }

        const titleOverride = state[FieldBlockDisplay_TitleOverride] as string | undefined;
        const titleSection: CompositeDropdownSection = {
          id: FieldBlockDisplay_TitleOverride,
          title: i18n`Title`,
          useGridForTitle: true,
          action: (
            <SpacingLine>
              <Chooser
                actions={[
                  {
                    key: 'use_field',
                    name: i18n`Use field name`,
                  },
                  {
                    key: 'override_field',
                    name: i18n`Override`,
                  },
                ]}
                onClick={(index) => {
                  if (index === 0) {
                    setState((current) => (joinObjects(current, { [FieldBlockDisplay_TitleOverride]: null })));
                  } else {
                    setState((current) => (joinObjects(current, { [FieldBlockDisplay_TitleOverride]: '' })));
                  }
                }}
                selectedIndexes={[typeof titleOverride === 'string' ? 1 : 0]}
              />
            </SpacingLine>
          ),
          lines: typeof titleOverride === 'string' ? [
            {
              id: 'value',
              title: i18n`Content`,
              info: i18n`This name will be displayed below the section instead of the field name`,
              render: (
                <StoreTextInputField
                  initialValue={titleOverride}
                  onSubmit={(text) => setState((current) => (joinObjects(current, { [FieldBlockDisplay_TitleOverride]: (text ?? '') })))}
                />
              ),
            },
          ] : [],
        };
        sections.push(titleSection);

        const documentationOverride = state[FieldBlockDisplay_DocumentationOverride] as DocumentationOverride | undefined;
        const documentationSection: CompositeDropdownSection = {
          id: FieldBlockDisplay_DocumentationOverride,
          title: i18n`Documentation`,
          useGridForTitle: true,
          action: (
            <SpacingLine>
              <Chooser
                actions={[
                  {
                    key: 'use_field',
                    name: i18n`Use field documentation`,
                  },
                  {
                    key: 'override_field',
                    name: i18n`Override`,
                  },
                ]}
                onClick={(index) => {
                  if (index === 0) {
                    setState((current) => (joinObjects(current, { [FieldBlockDisplay_DocumentationOverride]: null })));
                  } else {
                    setState((current) => (joinObjects(current, { [FieldBlockDisplay_DocumentationOverride]: { value: '', isInline: false } })));
                  }
                }}
                selectedIndexes={[documentationOverride ? 1 : 0]}
              />
            </SpacingLine>
          ),
          lines: documentationOverride ? [
            {
              id: 'value',
              title: i18n`Content`,
              info: i18n`This documentation will be displayed instead of the field documentation`,
              render: (
                <StoreTextInputField
                  initialValue={documentationOverride.value}
                  onSubmit={(text) => setState((current) => (joinObjects(current, { [FieldBlockDisplay_DocumentationOverride]: joinObjects(documentationOverride, { value: text ?? '' }) })))}
                />
              ),
            },
            {
              id: 'location',
              title: i18n`Show on`,
              info: i18n`This documentation will be displayed either on a line above the field or on hover of the field`,
              render: (
                <SearchAndSelect
                  computeOptions={() => fieldDocumentationDisplayOptions}
                  selectedOption={fieldDocumentationDisplayOptions.find(({ id }) => id === (documentationOverride?.isInline ?? false))}
                  onSelect={(option) => setState((current) => (joinObjects(
                    current,
                    { [FieldBlockDisplay_DocumentationOverride]: joinObjects(documentationOverride, { isInline: option?.id ?? false }) }
                  )))}
                />
              ),
            },
          ] : [],
        };
        sections.push(documentationSection);

        let highlightSection: CompositeDropdownSection;
        if (workflowConceptIds && workflowFieldId) {
          const highlightStatusHelp = i18n`In order to avoid information overload, and guide the user in completing the requested information, you can choose to highlight fields by Status.\nFields that are not highlighted will be greyed out, but users can still choose to highlight them and fill them.`;
          let fromStatusError: string | undefined;
          const fromStatusConceptId = fieldBlockDisplay[FieldBlockDisplay_VisibleFromStatus] as string;
          const toStatusConceptId = fieldBlockDisplay[FieldBlockDisplay_VisibleToStatus] as string;
          const fromStatusIndex = fromStatusConceptId ? workflowConceptIds.findIndex((id) => id === fromStatusConceptId) : -1;
          const toStatusIndex = toStatusConceptId ? workflowConceptIds.findIndex((id) => id === toStatusConceptId) : -1;
          const invalidConceptErrorLabel = i18n`Selected concept doesn't belong to the selected main workflow.`;
          const inconsistentOrderErrorLabel = i18n`Status configuration is inconsistent. The "From" status must be equal or before the "To" in the Workflow configuration below`;
          if (fromStatusConceptId) {
            if (fromStatusIndex === -1) {
              fromStatusError = invalidConceptErrorLabel;
            } else if (toStatusIndex > -1 && fromStatusIndex > toStatusIndex) {
              fromStatusError = inconsistentOrderErrorLabel;
            }
          }

          let toStatusError: string | undefined;
          if (toStatusConceptId) {
            if (toStatusIndex === -1) {
              toStatusError = invalidConceptErrorLabel;
            } else if (fromStatusIndex > -1 && fromStatusIndex > toStatusIndex) {
              toStatusError = inconsistentOrderErrorLabel;
            }
          }
          const computeStatusOptions = () => workflowConceptIds.map((instanceId) => getChipOptions(store, instanceId)).filter(filterNullOrUndefined);
          highlightSection = {
            id: 'highlight',
            title: i18n`Highlight`,
            info: highlightStatusHelp,
            useGridForTitle: true,
            action: (<div />),
            lines: [
              {
                id: FieldBlockDisplay_VisibleFromStatus,
                title: i18n`From status`,
                render: (
                  <SearchAndSelect
                    clearable
                    computeOptions={computeStatusOptions}
                    statusIcon={fromStatusError ? { icon: IconName.dangerous, color: IconColorVariant.error, message: fromStatusError } : undefined}
                    selectedOption={fromStatusConceptId ? getChipOptions(store, fromStatusConceptId) : undefined}
                    onSelect={(result) => store.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_VisibleFromStatus]: result ? result.id : null })}
                  />
                ),
              },
              {
                id: FieldBlockDisplay_VisibleToStatus,
                title: i18n`To status`,
                render: (
                  <SearchAndSelect
                    clearable
                    computeOptions={computeStatusOptions}
                    statusIcon={toStatusError ? { icon: IconName.dangerous, color: IconColorVariant.error, message: toStatusError } : undefined}
                    selectedOption={toStatusConceptId ? getChipOptions(store, toStatusConceptId) : undefined}
                    onSelect={(result) => store.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_VisibleToStatus]: result ? result.id : null })}
                  />
                ),
              },
            ],
          };
          sections.push(highlightSection);
        }

        sections.push(
          ...getBlockEditionOptionSections(state.displayOptions, (newState) => setState((current) => (joinObjects(current, { displayOptions: newState }))))
            .map((section) => ({
              id: section.key,
              title: section.title,
              info: section.info,
              titleVariant: section.titleVariant,
              useGridForTitle: Boolean(section.action),
              action: section.action ? getFieldEditionOptionRender(section.action) : null,
              lines: section.options.map((option) => ({
                id: option.key,
                title: option.title,
                isVertical: option.isVertical,
                info: option.info,
                render: getFieldEditionOptionRender(option),
              })),
            }))
        );

        return sections;
      }}
      onOpenDropdown={onOpen}
      onCloseDropdown={(reason) => {
        if (reason === CompositeFieldCloseReasons.validate && !equals(memoizedInitialState, state)) {
          onSubmit(state.displayOptions);

          if (fieldBlockDisplay[FieldBlockDisplay_WorkflowField] !== state[FieldBlockDisplay_WorkflowField]) {
            store.updateObject(fieldBlockDisplay.id, { [FieldBlockDisplay_WorkflowField]: state[FieldBlockDisplay_WorkflowField] });
          }

          if (state[FieldBlockDisplay_WorkflowField]) {
            if (fieldBlockDisplay[FieldBlockDisplay_WorkflowFieldDisplayType] !== state[FieldBlockDisplay_WorkflowFieldDisplayType]) {
              store.updateObject(fieldBlockDisplay.id, { [FieldBlockDisplay_WorkflowFieldDisplayType]: state[FieldBlockDisplay_WorkflowFieldDisplayType] });
            }

            const association = store.withAssociation(WorkflowFieldFields)
              .withRole(WorkflowFieldFields_Role_WorkflowField, state[FieldBlockDisplay_WorkflowField])
              .withRole(WorkflowFieldFields_Role_Field, lastField.fieldId);
            if (!association.getOrNull()) {
              association.updateObject({});
            }
          } else if (fieldBlockDisplay[FieldBlockDisplay_WorkflowFieldDisplayType]) {
            store.updateObject(fieldBlockDisplay.id, { [FieldBlockDisplay_WorkflowFieldDisplayType]: null });
          }

          if (fieldBlockDisplay[FieldBlockDisplay_TitleOverride] !== state[FieldBlockDisplay_TitleOverride]) {
            store.updateObject(fieldBlockDisplay.id, { [FieldBlockDisplay_TitleOverride]: state[FieldBlockDisplay_TitleOverride] });
          }

          if (!equals(fieldBlockDisplay[FieldBlockDisplay_DocumentationOverride], state[FieldBlockDisplay_DocumentationOverride])) {
            store.updateObject(fieldBlockDisplay.id, { [FieldBlockDisplay_DocumentationOverride]: state[FieldBlockDisplay_DocumentationOverride] });
          }
        } else {
          resetState();
        }
        onClose?.(reason);
      }}
    />
  );
};

export default DisplayOptionsFieldCell;
