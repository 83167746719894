import type { ComponentProps, FunctionComponent } from 'react';
import type { DateFieldStoreValue } from 'yooi-utils';
import type { DateInputValue } from '../../../components/inputs/datePickers/DateInput';
import DateInput from '../../../components/inputs/datePickers/DateInput';
import StoreInput from './StoreInput';

interface StoreDateInputProps extends Omit<ComponentProps<typeof DateInput>, 'value' | 'onChange' | 'onSubmit' | 'onCancel'> {
  initialValue: DateFieldStoreValue | undefined,
  onSubmit: (value: DateFieldStoreValue | null) => void,
}

const StoreDateInput: FunctionComponent<StoreDateInputProps> = ({ initialValue, onSubmit, ...dateInputProps }) => (
  <StoreInput<DateInputValue>
    initialValue={initialValue}
    onSubmit={(value) => {
      onSubmit(value?.date ? { period: value.period, date: value.date } : null);
    }}
  >
    {(formInputProps) => (
      <DateInput
        {...dateInputProps}
        {...formInputProps}
      />
    )}
  </StoreInput>
);

export default StoreDateInput;
