import type { FunctionComponent } from 'react';
import type { ConceptDefinitionStoreObject, ConceptStoreObject } from 'yooi-modules/modules/conceptModule';
import { ConceptDefinition_Name } from 'yooi-modules/modules/conceptModule/ids';
import { Dashboard } from 'yooi-modules/modules/dashboardModule/ids';
import { Resource } from 'yooi-modules/modules/resourceModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import StoreObjectNotFoundErrorBoundary from '../../store/StoreObjectNotFoundErrorBoundary';
import useStore from '../../store/useStore';
import i18n from '../../utils/i18n';
import { NavigationElementContainer } from '../../utils/useNavigation';
import ConceptDetailPage from './ConceptDetailPage';
import DashboardDetailPage from './dashboard/DashboardDetailPage';
import ResourceDetailPage from './resource/ResourceDetailPage';

interface ConceptPageProps {
  conceptDefinitionId: string,
  conceptId: string,
}

const ConceptPageInner: FunctionComponent<ConceptPageProps> = ({ conceptDefinitionId, conceptId }) => {
  const store = useStore();

  const concept = store.getObject<ConceptStoreObject>(conceptId);

  if (isInstanceOf(concept, Resource)) {
    return (<ResourceDetailPage resourceId={conceptId} />);
  } else if (isInstanceOf(concept, Dashboard)) {
    return (<DashboardDetailPage dashboardId={conceptId} />);
  } else {
    return (<ConceptDetailPage conceptDefinitionId={conceptDefinitionId} conceptId={conceptId} />);
  }
};

const ConceptPage: FunctionComponent<ConceptPageProps> = ({ conceptDefinitionId, conceptId }) => {
  const store = useStore();
  const conceptDefinition = store.getObjectOrNull<ConceptDefinitionStoreObject>(conceptDefinitionId);
  return (
    <StoreObjectNotFoundErrorBoundary objectName={conceptDefinition?.[ConceptDefinition_Name] ?? i18n`Instance`}>
      <NavigationElementContainer element={{ key: conceptId }}>
        <ConceptPageInner conceptDefinitionId={conceptDefinitionId} conceptId={conceptId} />
      </NavigationElementContainer>
    </StoreObjectNotFoundErrorBoundary>
  );
};

export default ConceptPage;
