import type { FunctionComponent, ReactElement } from 'react';
import { resolveIFramePath } from 'yooi-modules/modules/conceptLayoutModule/fields/iFrameField/utils';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { hasPlatformCapability } from 'yooi-modules/modules/conceptModule';
import { Field_Title, PlatformCapabilityAdmin } from 'yooi-modules/modules/conceptModule/ids';
import type { IFrameAllowedUrlStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import { IFrameAllowedUrl, IFrameAllowedUrl_Url } from 'yooi-modules/modules/platformConfigurationModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined, isUrl } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../../components/atoms/Button';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Typo from '../../../../components/atoms/Typo';
import InlineLink from '../../../../components/molecules/InlineLink';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { useIFrameContext } from './useIFrameContext';

const useStyles = makeStyles({
  widget: {
    flex: 1,
    border: 0,
  },
  error: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: spacingRem.m,
    paddingLeft: spacingRem.xs,
    paddingRight: spacingRem.xs,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}, 'iFrameFieldRenderer');

interface IFrameFieldRendererProps {
  height?: number,
  width?: number,
  fieldId: string,
  parametersMapping: ParametersMapping,
}

const IFrameFieldRenderer: FunctionComponent<IFrameFieldRendererProps> = ({ height, width, fieldId, parametersMapping }) => {
  const { loggedUserId } = useAuth();
  const store = useStore();
  const classes = useStyles();
  const initialIFrameInstances = useIFrameContext();

  const field = store.getObject(fieldId);
  const isAdmin = hasPlatformCapability(store, loggedUserId, PlatformCapabilityAdmin);
  const url = field ? resolveIFramePath(store, field, parametersMapping) : undefined;

  const renderError = (message: ReactElement | string, refresh = false) => (
    <div style={{ width, height }} className={classes.error}>
      <SpacingLine>
        <div className={classes.iconContainer}>
          <Icon
            name={IconName.dangerous}
            colorVariant={IconColorVariant.error}
          />
        </div>

        <Typo multiline maxLine={2}>{message}</Typo>
      </SpacingLine>
      {refresh ? (
        <Button
          title={i18n`Refresh`}
          variant={ButtonVariant.secondary}
          iconName={IconName.sync}
          iconColor={IconColorVariant.secondary}
          onClick={() => window.location.reload()}
        />
      ) : null}
    </div>
  );

  if (!url) {
    return renderError(i18n`No data available`);
  } else if (!isUrl(url)) {
    return renderError(i18n`Invalid URL format`);
  }

  const currentIFrameInstances = store.getObject(IFrameAllowedUrl)
    .navigateBack<IFrameAllowedUrlStoreObject>(Instance_Of)
    .map(({ [IFrameAllowedUrl_Url]: allowedUrl }) => allowedUrl)
    .filter(filterNullOrUndefined)
    .filter((allowedUrl) => isUrl(allowedUrl))
    .map((allowedUrl) => new URL(allowedUrl).origin);

  const source = new URL(url);
  const configAllowDisplay = currentIFrameInstances.includes(source.origin);
  const browserAllowDisplay = initialIFrameInstances.includes(source.origin);
  if (configAllowDisplay) {
    if (browserAllowDisplay) {
      return (
        <iframe
          title={`iframe-${field[Field_Title]}`}
          className={classes.widget}
          height={height}
          width={width}
          src={source.href}
        />
      );
    } else {
      return renderError(i18n`A new source was just added. Please refresh the page`, true);
    }
  } else if (isAdmin) {
    return renderError(i18n.jsx`The content cannot be displayed.\nPlease go to ${
      <InlineLink key="iframe-sources" to="/settings/security#iframe-sources">{i18n`Security Settings`}</InlineLink>
    } to add this source (${source.origin})`);
  } else {
    return renderError(i18n`The content cannot be displayed.\nPlease contact an administrator to authorize this source`);
  }
};

export default IFrameFieldRenderer;
