import { equals } from 'ramda';
import type { ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import { isStoreObject, ValidationStatus } from 'yooi-store';
import { arrayOf, filterNullOrUndefined, isRichText, joinObjects, newError, richTextToText } from 'yooi-utils';
import { CommonAsType } from '../../common/fields/commonPropertyType';
import type { GetDslFieldHandler, UpdateOperationHandlers } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import type { BusinessRuleRegistration } from '../../common/types/TypeModuleDslType';
import { PropertyMandatoryType } from '../../common/types/TypeModuleDslType';
import { Instance_Of, ModelType } from '../../typeModule/ids';
import { formatOrUndef, isSaneAssociationValue } from '../common/commonFieldUtils';
import {
  Concept_Name,
  ConceptDefinition_RestrictedAccess,
  Field_IntegrationOnly,
  RelationMultipleField_ReverseField,
  RelationMultipleField_TargetType,
  RelationSingleField,
} from '../ids';
import type { ConceptStoreObject } from '../model';
import { registerField } from '../module';
import type { Filters, PathStep } from '../moduleType';
import { PathStepType } from '../moduleType';
import type { ConceptReference, DimensionsMapping, MultipleParameterValue, ParametersMapping, ResolutionStack, SingleParameterValue, ValueResolution } from '../utils';
import {
  conceptRefApiSchema,
  createValuePathResolver,
  FILTER_PARAMETER_CURRENT,
  getConceptInstanceProxy,
  getFieldDimensionOfModelType,
  getFilterFunction,
  getInstanceLabel,
  getMultipleRelationFieldExportColumnHeaders,
  handleProxyArrayProps,
  InstanceReferenceType,
  isConceptValid,
  isMultiValueResolution,
  isSingleValueResolution,
  isValueResolutionOfType,
  ParsedDimensionType,
  parseDimensionMapping,
  resolveFieldValue,
  segregateParametersMapping,
  toConceptReference,
} from '../utils';
import { conceptType } from '../utils/formula/modelFunctions';
import type { MultipleRelationFieldExportConfiguration } from '../utils/relationFieldUtils';
import type { RelationMultipleField } from './types';

const checkRelationMultipleField: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  if (!properties) {
    return undefined;
  }
  const object = getObjectOrNull(id)?.asRawObject() ?? {};
  const allProperties = joinObjects(object, properties);
  const targetType = allProperties[RelationMultipleField_TargetType];
  const reverseField = allProperties[RelationMultipleField_ReverseField];
  if (!targetType) {
    return { rule: 'field.RelationMultipleField.hasTargetType', status: ValidationStatus.REJECTED };
  }
  if (!reverseField) {
    return { rule: 'field.RelationMultipleField.hasReverseField', status: ValidationStatus.REJECTED };
  }
  return { rule: 'field.RelationMultipleField.checkRelationSingleField', status: ValidationStatus.ACCEPTED };
};

const cannotCreateRelationMultipleOnRestrictedConcept: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  if (properties && !getObjectOrNull(id[0])) {
    const sourceConcept = getObjectOrNull(properties[RelationMultipleField_TargetType] as string);
    if (sourceConcept?.[ConceptDefinition_RestrictedAccess]) {
      return { rule: 'field.relationMultipleField.cannotCreateRelationMultipleOnRestrictedConcept', status: ValidationStatus.REJECTED };
    }
  }
  return undefined;
};

const getValueWithoutFormula = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping) => {
  const parsedDimensions = parseDimensionMapping(dimensionsMapping);
  if (parsedDimensions.type === ParsedDimensionType.MonoDimensional) {
    const field = objectStore.getObject(fieldId);
    const reverseField = field.navigate(RelationMultipleField_ReverseField);
    return objectStore.getObject(parsedDimensions.objectId).navigateBack(reverseField.id).filter(({ id }) => isConceptValid(objectStore, id));
  } else {
    return [];
  }
};

const getValueResolution = (
  objectStore: ObjectStoreWithTimeseries,
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
  resolutionStack?: ResolutionStack
): ValueResolution<StoreObject[]> => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
  if (isValueResolutionOfType(valueResolution, (value): value is StoreObject[] => Array.isArray(value) && value.every(isStoreObject))) {
    return valueResolution;
  } else {
    return {
      value: [],
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['StoreObject[]'], typeof valueResolution.value),
      getDisplayValue: () => [],
      isTimeseries: valueResolution.isTimeseries,
    };
  }
};

const syncIntegrationOnly: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  if (!properties) {
    // deletion don't care, reverse will be deleted
    return undefined;
  }

  const relationMultipleField = getObjectOrNull(id[0]);
  if (!relationMultipleField) {
    // creation, looking at the reverse field
    const relationSingleFieldId = properties[RelationMultipleField_ReverseField] as string | undefined;
    if (!relationSingleFieldId) {
      // should never happen, no reverse field at creation
      return undefined;
    }
    const relationSingleField = getObjectOrNull(relationSingleFieldId);
    if (!relationSingleField) {
      // should never happen, reverse field should exist
      return undefined;
    }
    if (relationSingleField && (properties[Field_IntegrationOnly] === true || relationSingleField[Field_IntegrationOnly] === true)) {
      return {
        rule: 'relationMultipleField.syncIntegrationOnly',
        status: ValidationStatus.ACCEPTED,
        generateSystemEvent: ({ updateObject }) => {
          updateObject(relationSingleField.id, {
            [Field_IntegrationOnly]: true,
          });
        },
      };
    }
  } else {
    // update integration only
    const relationSingleField = relationMultipleField.navigateOrNull(RelationMultipleField_ReverseField);
    if (!relationSingleField) {
      // should never happen, no reverse field
      return undefined;
    }
    if (Boolean(relationSingleField[Field_IntegrationOnly]) !== Boolean(properties[Field_IntegrationOnly])) {
      // Only update if necessary, or we will have an infinite event loop (we use boolean because integration only can be undefined / null / false)
      return {
        rule: 'relationMultipleField.syncIntegrationOnly',
        status: ValidationStatus.ACCEPTED,
        generateSystemEvent: ({ updateObject }) => {
          updateObject(relationSingleField.id, {
            [Field_IntegrationOnly]: Boolean(properties[Field_IntegrationOnly]),
          });
        },
      };
    }
  }
  return undefined;
};

const getTargetType = (objectStore: ObjectStoreWithTimeseries, fieldId: string) => objectStore.getObject(fieldId).navigate(RelationMultipleField_TargetType);

interface RelationMultipleAddUpdate {
  action: 'add',
  objectIds: string[],
}

interface RelationMultipleSetUpdate {
  action: 'set',
  objectIds: string[],
}

interface RelationMultipleRemoveUpdate {
  action: 'remove',
  objectIds: string[],
}

export type RelationMultipleUpdate = RelationMultipleAddUpdate | RelationMultipleSetUpdate | RelationMultipleRemoveUpdate;

interface RelationMultipleUpdateOperationTypes {
  INITIALIZE: { type: 'value', value: string[] } | { type: 'path', path: PathStep[] },
  REPLACE: { type: 'value', value: string[] } | { type: 'path', path: PathStep[] },
  ADD: { type: 'value', value: string[] } | { type: 'path', path: PathStep[] },
  REMOVE: { type: 'value', value: string[] } | { type: 'path', path: PathStep[] },
  CLEAR: undefined,
}

export interface RelationMultipleFieldStepConfiguration {
  returnType?: string,
  filters?: Filters,
}

type RelationMultipleFieldHandler = GetDslFieldHandler<
  RelationMultipleField,
  string[],
  RelationMultipleUpdate,
  StoreObject[],
  StoreObject[],
  ConceptReference[],
  undefined,
  RelationMultipleUpdateOperationTypes,
  RelationMultipleFieldStepConfiguration,
  MultipleRelationFieldExportConfiguration
>;

export const relationMultipleFieldHandler: RelationMultipleFieldHandler = registerField({
  model: {
    label: 'RelationMultipleField',
    title: 'Association one to many (1-n)',
    withApiAlias: true,
    properties: [{ label: 'Field_TargetFilter', as: CommonAsType.Filters }],
    relations: [
      { label: 'TargetType', targetTypeId: ModelType, reverseLabel: 'ModelType_TargetedByRelationMultipleFields', mandatory: { type: PropertyMandatoryType.mandatory } },
      { label: 'ReverseField', targetTypeId: RelationSingleField, reverseLabel: 'RelationSingleField_ReverseFields', mandatory: { type: PropertyMandatoryType.mandatory } },
    ],
    businessRules: [
      checkRelationMultipleField,
      cannotCreateRelationMultipleOnRestrictedConcept,
      syncIntegrationOnly,
    ],
  },
  handler: (objectStore, fieldId, { resolveConfiguration }) => {
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => (getValueResolution(objectStore, fieldId, dimensionsMapping).value ?? [])
      .map((e) => formatOrUndef(getInstanceLabel(objectStore, e))).join(', ');

    const extractOperationValueObjectIds = (
      parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
      operationValue: { type: 'value', value: string[] } | { type: 'path', path: PathStep[] }
    ): string[] => {
      const extractConceptIds = (conceptOrConcepts: unknown | unknown[]): string[] => {
        if (Array.isArray(conceptOrConcepts)) {
          return conceptOrConcepts
            .filter((concept) => isStoreObject(concept) && isConceptValid(objectStore, concept.id))
            .map(({ id }) => id);
        } else if (isStoreObject(conceptOrConcepts) && isConceptValid(objectStore, conceptOrConcepts.id)) {
          return [conceptOrConcepts.id];
        } else {
          return [];
        }
      };

      if (operationValue.type === 'path') {
        const { path } = operationValue;
        const resolution = createValuePathResolver(objectStore, parametersMapping).resolvePathValue(path);
        if (isSingleValueResolution(resolution)) {
          return extractConceptIds(resolution.value);
        } else if (isMultiValueResolution(resolution)) {
          return resolution.values.flatMap((maybeConceptOrConcepts) => extractConceptIds(maybeConceptOrConcepts));
        } else {
          return [];
        }
      } else {
        return operationValue.value;
      }
    };
    return {
      describe: () => ({ hasData: true, returnType: arrayOf(conceptType(getTargetType(objectStore, fieldId).id)), timeseriesMode: 'none' }),
      restApi: {
        returnTypeSchema: { type: 'array', items: conceptRefApiSchema },
        formatValue: (value, canRead) => (value ?? [])
          .filter((concept) => canRead([concept.id]))
          .map(toConceptReference)
          .filter(filterNullOrUndefined),
      },
      getStoreValue: (dimensionsMapping) => {
        const parsedDimensions = parseDimensionMapping(dimensionsMapping);
        if (parsedDimensions.type === ParsedDimensionType.MonoDimensional) {
          const field = objectStore.getObject(fieldId);
          const reverseField = field.navigate(RelationMultipleField_ReverseField);
          return objectStore.getObject(parsedDimensions.objectId).navigateBack(reverseField.id).map(({ id }) => id);
        } else {
          return [];
        }
      },
      getValueWithoutFormula: (dimensionsMapping) => getValueWithoutFormula(objectStore, fieldId, dimensionsMapping),
      getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
      updateValue: (dimensionsMapping, update) => {
        const parsedDimension = parseDimensionMapping(dimensionsMapping);
        if (parsedDimension.type !== ParsedDimensionType.MonoDimensional) {
          throw new Error('Association field only support mono-dimensional absorbed values');
        }
        const { reverseFieldId } = resolveConfiguration();
        const existingObjectIds = update.objectIds.filter((objectId) => objectStore.getObjectOrNull(objectId) !== null);

        switch (update.action) {
          case 'add': {
            existingObjectIds.forEach((id) => {
              objectStore.updateObject(id, { [reverseFieldId]: parsedDimension.objectId });
            });
            break;
          }
          case 'set': {
            objectStore.getObject(parsedDimension.objectId).navigateBack(reverseFieldId).forEach(({ id }) => {
              objectStore.updateObject(id, { [reverseFieldId]: null });
            });
            existingObjectIds
              .forEach((id) => objectStore.updateObject(id, { [reverseFieldId]: parsedDimension.objectId }));
            break;
          }
          case 'remove': {
            existingObjectIds
              .forEach((id) => objectStore.updateObject(id, { [reverseFieldId]: null }));
            break;
          }
        }
      },
      isEmpty: (dimensionsMapping) => !getValueResolution(objectStore, fieldId, dimensionsMapping).value?.length,
      isSaneValue: (objectId) => {
        const instance = objectStore.getObjectOrNull(objectId);
        const dimensionId = instance ? getFieldDimensionOfModelType(objectStore, fieldId, instance[Instance_Of] as string) : undefined;

        if (dimensionId) {
          const isValid = isSaneAssociationValue(
            objectStore,
            objectId,
            getTargetType(objectStore, fieldId)?.id,
            getValueWithoutFormula(objectStore, fieldId, { [dimensionId]: objectId })
          );
          return joinObjects({ isValid }, (isValid ? {} : { error: newError('Some selected elements are not allowed') }));
        } else {
          return { isValid: false, error: newError('Invalid field') };
        }
      },
      getValueAsText,
      getExportColumnHeaders: getMultipleRelationFieldExportColumnHeaders(objectStore),
      getExportValue: (dimensionsMapping, configurationProps) => {
        const configuration: MultipleRelationFieldExportConfiguration = configurationProps ?? { type: 'path', separator: ', ' };
        const { value } = getValueResolution(objectStore, fieldId, dimensionsMapping);
        return {
          format: 'string',
          value: value.map((instance) => {
            if (configuration.type === 'path') {
              const defaultPath: PathStep[] = [
                { type: PathStepType.dimension, conceptDefinitionId: instance[Instance_Of] as string },
                { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
                { type: PathStepType.field, fieldId: Concept_Name },
              ];
              const pathResolution = createValuePathResolver(objectStore, { [FILTER_PARAMETER_CURRENT]: { type: 'single', id: instance.id } })
                .resolvePathValue(configuration.path ?? defaultPath);
              if (isSingleValueResolution(pathResolution)) {
                return isRichText(pathResolution.value) ? richTextToText(pathResolution.value) : pathResolution.value as string | undefined;
              }
              return undefined;
            } else {
              return instance.id;
            }
          }).filter(filterNullOrUndefined)
            .join(configuration.separator),
        };
      },
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else {
            const valueResolution = getValueResolution(objectStore, fieldId, dimensionsMapping);
            const value = valueResolution && !valueResolution.error ? valueResolution.value : [];
            return handleProxyArrayProps(prop, value, (v) => getConceptInstanceProxy(objectStore, v.id));
          }
        },
      }),
      getTargetType: () => getTargetType(objectStore, fieldId),
      resolvePathStepConfiguration: (config) => {
        const resolveValue = (
          dimensionsMapping: DimensionsMapping,
          parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
          resolutionStack: ResolutionStack
        ): StoreObject[] => {
          const { value, error } = getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack);
          if (error) {
            throw error;
          } else {
            const { filters } = config;
            if (filters) {
              const { singleParametersMapping } = segregateParametersMapping(parametersMapping);
              const filterFunction = getFilterFunction(objectStore, filters);
              if (filterFunction) {
                return value.filter((v) => filterFunction(joinObjects(singleParametersMapping, { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: v.id } })));
              }
            }
            return value;
          }
        };
        return ({
          hasData: true,
          timeseriesMode: 'none',
          getValueResolutionType: () => arrayOf(conceptType(getTargetType(objectStore, fieldId).id)),
          resolveDimension: (dimensionsMapping, parametersMapping, resolutionStack) => (
            { type: 'multiple', instances: resolveValue(dimensionsMapping, parametersMapping, resolutionStack) as ConceptStoreObject[] }
          ),
          resolveValue,
        });
      },
      filterConditions: undefined,
      updateOperationHandlers: {
        INITIALIZE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            const fieldHandler = relationMultipleFieldHandler(objectStore, fieldId);
            const currentValue = fieldHandler.getStoreValue(dimensionsMapping);
            if (currentValue !== undefined && currentValue.length) {
              return;
            }
            fieldHandler.updateValue(
              dimensionsMapping,
              { action: 'set', objectIds: extractOperationValueObjectIds(parametersMapping, value) }
            );
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: [] },
        },
        REPLACE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            relationMultipleFieldHandler(objectStore, fieldId)
              .updateValue(
                dimensionsMapping,
                { action: 'set', objectIds: extractOperationValueObjectIds(parametersMapping, value) }
              );
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: [] },
        },
        ADD: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            relationMultipleFieldHandler(objectStore, fieldId)
              .updateValue(
                dimensionsMapping,
                { action: 'add', objectIds: extractOperationValueObjectIds(parametersMapping, value) }
              );
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: [] },
        },
        REMOVE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            relationMultipleFieldHandler(objectStore, fieldId)
              .updateValue(
                dimensionsMapping,
                { action: 'remove', objectIds: extractOperationValueObjectIds(parametersMapping, value) }
              );
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: [] },
        },
        CLEAR: {
          applyOperation: (dimensionsMapping) => {
            relationMultipleFieldHandler(objectStore, fieldId).updateValue(dimensionsMapping, { action: 'set', objectIds: [] });
          },
          sanitizeOperation: () => undefined,
        },
      } satisfies UpdateOperationHandlers<RelationMultipleUpdateOperationTypes>,
    };
  },
  historyEventProducer: ({ getObject, getObjectOrNull }, fieldId) => ({
    value: {
      collectImpactedInstances: ({ id, properties }) => {
        if (id.length === 1) {
          const field = getObject(fieldId);
          const relationSingleFieldId = field.navigateOrNull(RelationMultipleField_ReverseField)?.id;
          if (relationSingleFieldId) {
            const impactedInstances = [];
            if (properties?.[relationSingleFieldId] !== undefined || properties === null) {
              if (properties?.[relationSingleFieldId] !== null && typeof properties?.[relationSingleFieldId] === 'string') {
                impactedInstances.push([properties[relationSingleFieldId]]);
              }
              const previousValue = getObjectOrNull(id);
              if (previousValue && previousValue[relationSingleFieldId] && typeof previousValue[relationSingleFieldId] === 'string') {
                impactedInstances.push([previousValue[relationSingleFieldId]]);
              }
            }
            return impactedInstances;
          }
        }
        return [];
      },
      getValue: (id) => {
        const field = getObject(fieldId);
        const relationSingleFieldId = field.navigateOrNull(RelationMultipleField_ReverseField)?.id;
        return {
          value: relationSingleFieldId ? getObjectOrNull(id)?.navigateBack(relationSingleFieldId).map(({ id: targetId }) => targetId) ?? [] : [],
          version: 1,
        };
      },
      areValuesEquals: (value1, value2) => equals(value1?.value, value2?.value),
    },
  }),
});
