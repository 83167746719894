import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import type { InstanceOperation } from 'yooi-modules/modules/automationModule';
import { INSTANCE_OPERATION_CURRENT_PARAMETER } from 'yooi-modules/modules/automationModule';
import type { FieldStoreObject, MultipleParameterDefinition, PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import {
  associationFieldHandler,
  embeddingFieldHandler,
  getFieldUtilsHandler,
  getPathLastFieldInformation,
  getPathReturnedConceptDefinitionId,
  isDimensionLibraryPath,
  isFilterStep,
  isMappingStep,
  isMultiplePath,
  isRelationalType,
  relationMultipleFieldHandler,
  relationSingleFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import {
  AssociationField,
  Concept,
  EmbeddingField,
  Field_Formula,
  Field_IntegrationOnly,
  RelationMultipleField,
  RelationSingleField,
} from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { joinObjects } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../components/atoms/Button';
import Icon, { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import Typo, { TypoVariant } from '../../../components/atoms/Typo';
import OverflowMenu from '../../../components/molecules/OverflowMenu';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../components/molecules/SpacingLine';
import TextWithLinks from '../../../components/molecules/TextWithLinks';
import BlockContent from '../../../components/templates/BlockContent';
import type { FrontObjectStore } from '../../../store/useStore';
import useStore from '../../../store/useStore';
import base from '../../../theme/base';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeSelectorsClasses from '../../../utils/makeSelectorsClasses';
import makeStyles from '../../../utils/makeStyles';
import { formatOrUndef } from '../../../utils/stringUtils';
import { OverlayContextProvider } from '../../../utils/useOverlayContainerRef';
import { SizeVariant } from '../../../utils/useSizeContext';
import { UsageContextProvider, UsageVariant } from '../../../utils/useUsageContext';
import { countValidFilters } from '../../_global/filter/filterUtils';
import type { OptionRecord } from '../../_global/modelTypeUtils';
import { getConceptDefinitionNameOrEntity, getOption } from '../../_global/modelTypeUtils';
import PathMappingAndFiltersInput from '../../_global/path/PathMappingAndFiltersInput';
import PathStepsInput from '../../_global/path/PathStepsInput';
import type { PathConfigurationHandler } from '../../_global/pathConfigurationHandler';
import { createPathConfigurationHandler, StepValidationState } from '../../_global/pathConfigurationHandler';
import FieldOperationsList from './FieldOperationsList';
import FieldOperationsListSummary from './FieldOperationsListSummary';

const selectorsClasses = makeSelectorsClasses('visibilityHandler');

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    padding: spacingRem.m,
    backgroundColor: theme.color.background.neutral.subtle,
    borderRadius: base.borderRadius.medium,
    display: 'grid',
    gridTemplateColumns: '1fr',
    '&:hover, &:focus, &:focus-within': {
      [`& .${selectorsClasses.visibilityHandler}`]: {
        visibility: 'visible',
      },
    },
  },
  firstLineContainer: {
    display: 'flex',
    columnGap: spacingRem.blockLeftColumnSpacing,
  },
  firstLineContent: {
    flexGrow: 1,
    display: 'grid',
    minHeight: '3.2rem',
    gridTemplateColumns: `15.5rem ${spacingRem.blockLeftColumnSpacing} auto`,
    alignItems: 'start',
  },
  line: {
    display: 'grid',
    gridTemplateColumns: `calc(15.5rem + ${spacingRem.blockLeftColumnSpacing}) auto`,
    alignItems: 'start',
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
    marginTop: '1.4rem',
    minHeight: '3.2rem',
  },
  operationContainer: {
    paddingLeft: spacingRem.s,
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
    marginBottom: spacingRem.s,
    marginTop: spacingRem.s,
  },
  pathContainer: {
    display: 'flex',
    columnGap: spacingRem.blockLeftColumnSpacing,
  },
  paddedContainer: {
    marginLeft: spacingRem.s,
    marginRight: spacingRem.s,
  },
  addContainer: {
    height: '3.2rem',
  },
  hiddenActions: {
    visibility: 'hidden',
  },
}), 'instanceOperationCard');

const getUpdatePathState = (
  store: FrontObjectStore,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
  dimensionPath: PathStep[]
): { state: StepValidationState, hint: string } => {
  if (dimensionPath.length === 0) {
    return { state: StepValidationState.partiallyValid, hint: i18n`Missing creation path` };
  }
  const lastStep = dimensionPath[dimensionPath.length - 1];
  let updateMessage: string;
  if (isMappingStep(lastStep)) {
    updateMessage = i18n`Update '${formatOrUndef(getOption(store, lastStep.mapping.id, parameterDefinitions)?.label)}' of`;
  } else if (isFilterStep(lastStep) && countValidFilters(store, lastStep.filters) > 0) {
    updateMessage = i18n`Update all filtered instances of`;
  } else {
    updateMessage = i18n`Update all instances of`;
  }

  if (isDimensionLibraryPath(dimensionPath)) {
    const targetConceptDefinitionId = dimensionPath[0].conceptDefinitionId;
    return {
      state: StepValidationState.valid,
      hint: isFilterStep(lastStep) && lastStep.filters ? i18n`Update all filtered instances of '${getConceptDefinitionNameOrEntity(store, targetConceptDefinitionId)}'`
        : `${updateMessage} '${getConceptDefinitionNameOrEntity(store, targetConceptDefinitionId)}'`,
    };
  } else {
    const pathConfigurationHandler = createPathConfigurationHandler(store, parameterDefinitions);
    const pathLastFieldInformation = getPathLastFieldInformation(dimensionPath);
    if (!pathLastFieldInformation) {
      if (isMappingStep(lastStep)) {
        return { state: StepValidationState.valid, hint: updateMessage };
      } else {
        return { state: StepValidationState.invalid, hint: i18n`Path contains errors` };
      }
    }
    if (pathConfigurationHandler.getErrors(pathLastFieldInformation.pathToField)) {
      return { state: StepValidationState.invalid, hint: i18n`Path contains errors` };
    }
    const isMultiple = isMultiplePath(store, pathLastFieldInformation.pathBeforeField);
    const fieldUtilsHandler = getFieldUtilsHandler(store, pathLastFieldInformation.fieldId);
    const { fieldDefinitionId } = fieldUtilsHandler.resolveConfiguration();
    if (!isRelationalType(fieldDefinitionId)) {
      return { state: StepValidationState.invalid, hint: i18n`Path should only contains relational fields` };
    }
    const originConceptDefinitionId = getPathReturnedConceptDefinitionId(store, pathLastFieldInformation.pathBeforeField);
    if (!originConceptDefinitionId) {
      return { state: StepValidationState.partiallyValid, hint: i18n`Path should end with a unique concept` };
    }
    const targetConceptDefinitionId = fieldUtilsHandler.getTargetType?.()?.id;
    if (!targetConceptDefinitionId) {
      return { state: StepValidationState.partiallyValid, hint: i18n`Path should target an association to a concept` };
    }
    const field = store.getObjectOrNull<FieldStoreObject>(pathLastFieldInformation.fieldId);
    if (!field) {
      return { state: StepValidationState.invalid, hint: i18n`Path contains errors` };
    }
    if (targetConceptDefinitionId === Concept) {
      return { state: StepValidationState.partiallyValid, hint: i18n`Path should not target Concept.` };
    }
    if (fieldDefinitionId === AssociationField) {
      const fieldHandler = associationFieldHandler(store, pathLastFieldInformation.fieldId);
      return {
        state: StepValidationState.valid,
        hint: isMultiple ? i18n`${updateMessage} '${getConceptDefinitionNameOrEntity(store, targetConceptDefinitionId)}' linked to the list of selected '${getConceptDefinitionNameOrEntity(store, originConceptDefinitionId)}' through the association (n-n) '${formatOrUndef(fieldHandler.resolveConfiguration().title)}'.`
          : i18n`${updateMessage} '${getConceptDefinitionNameOrEntity(store, targetConceptDefinitionId)}' linked to the selected '${getConceptDefinitionNameOrEntity(store, originConceptDefinitionId)}' through the association (n-n) '${formatOrUndef(fieldHandler.resolveConfiguration().title)}'.`,
      };
    } else if (fieldDefinitionId === EmbeddingField) {
      const fieldHandler = embeddingFieldHandler(store, pathLastFieldInformation.fieldId);
      return {
        state: StepValidationState.valid,
        hint: isMultiple ? i18n`${updateMessage} '${getConceptDefinitionNameOrEntity(store, targetConceptDefinitionId)}' embedded in the list of selected '${getConceptDefinitionNameOrEntity(store, originConceptDefinitionId)}' through the embedding field '${formatOrUndef(fieldHandler.resolveConfiguration().title)}'.`
          : i18n`${updateMessage} '${getConceptDefinitionNameOrEntity(store, targetConceptDefinitionId)}' embedded in the selected '${getConceptDefinitionNameOrEntity(store, originConceptDefinitionId)}' through the embedding field '${formatOrUndef(fieldHandler.resolveConfiguration().title)}'.`,
      };
    } else if (fieldDefinitionId === RelationMultipleField) {
      const fieldHandler = relationMultipleFieldHandler(store, pathLastFieldInformation.fieldId);
      return {
        state: StepValidationState.valid,
        hint: isMultiple ? i18n`${updateMessage} '${getConceptDefinitionNameOrEntity(store, targetConceptDefinitionId)}' linked to the list of selected '${getConceptDefinitionNameOrEntity(store, originConceptDefinitionId)}' through the association (1-n) '${formatOrUndef(fieldHandler.resolveConfiguration().title)}'.`
          : i18n`${updateMessage} '${getConceptDefinitionNameOrEntity(store, targetConceptDefinitionId)}' linked to the selected '${getConceptDefinitionNameOrEntity(store, originConceptDefinitionId)}' through the association (1-n) '${formatOrUndef(fieldHandler.resolveConfiguration().title)}'.`,
      };
    } else if (fieldDefinitionId === RelationSingleField) {
      const fieldHandler = relationSingleFieldHandler(store, pathLastFieldInformation.fieldId);
      return {
        state: StepValidationState.valid,
        hint: isMultiple ? i18n`${updateMessage} '${getConceptDefinitionNameOrEntity(store, targetConceptDefinitionId)}' linked to the list of selected '${getConceptDefinitionNameOrEntity(store, originConceptDefinitionId)}' through the association (n-1) '${formatOrUndef(fieldHandler.resolveConfiguration().title)}'.`
          : i18n`${updateMessage} '${getConceptDefinitionNameOrEntity(store, targetConceptDefinitionId)}' linked to the selected '${getConceptDefinitionNameOrEntity(store, originConceptDefinitionId)}' through the single (n-1) '${formatOrUndef(fieldHandler.resolveConfiguration().title)}'.`,
      };
    } else {
      return { state: StepValidationState.invalid, hint: i18n`Path should end with a relational field (association n-n / 1-n / n-1 / embedding)` };
    }
  }
};

const getCreatePathState = (
  store: FrontObjectStore,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
  dimensionPath: PathStep[]
): { state: StepValidationState, hint: string } => {
  if (dimensionPath.length === 0) {
    return { state: StepValidationState.partiallyValid, hint: i18n`Missing creation path` };
  }
  if (isDimensionLibraryPath(dimensionPath)) {
    const targetConceptDefinitionId = dimensionPath[0].conceptDefinitionId;
    return {
      state: StepValidationState.valid,
      hint: i18n`Create an instance of ${getConceptDefinitionNameOrEntity(store, targetConceptDefinitionId)}`,
    };
  } else {
    const pathConfigurationHandler = createPathConfigurationHandler(store, parameterDefinitions);
    const pathLastFieldInformation = getPathLastFieldInformation(dimensionPath);
    if (!pathLastFieldInformation) {
      return { state: StepValidationState.partiallyValid, hint: i18n`Creation path should end with a field` };
    }
    if (pathConfigurationHandler.getErrors(pathLastFieldInformation.pathToField)) {
      return { state: StepValidationState.invalid, hint: i18n`Path contains errors` };
    }
    const isMultiple = isMultiplePath(store, pathLastFieldInformation.pathBeforeField);
    const fieldUtilsHandler = getFieldUtilsHandler(store, pathLastFieldInformation.fieldId);
    const { fieldDefinitionId } = fieldUtilsHandler.resolveConfiguration();
    if (!isRelationalType(fieldDefinitionId)) {
      return { state: StepValidationState.invalid, hint: i18n`Path should only contains relational fields` };
    }
    const originConceptDefinitionId = getPathReturnedConceptDefinitionId(store, pathLastFieldInformation.pathBeforeField);
    if (!originConceptDefinitionId) {
      return { state: StepValidationState.partiallyValid, hint: i18n`Path should end with a unique concept` };
    }
    const targetConceptDefinitionId = fieldUtilsHandler.getTargetType?.()?.id;
    if (!targetConceptDefinitionId) {
      return { state: StepValidationState.partiallyValid, hint: i18n`Path should target an association to a concept` };
    }
    const field = store.getObjectOrNull<FieldStoreObject>(pathLastFieldInformation.fieldId);
    if (!field) {
      return { state: StepValidationState.invalid, hint: i18n`Path contains errors` };
    }
    if (field[Field_IntegrationOnly]) {
      return {
        state: isRelationalType(field[Instance_Of]) ? StepValidationState.partiallyValid : StepValidationState.invalid,
        hint: i18n`Path should not end with an integration only field.`,
      };
    }
    if (field[Field_Formula]) {
      return { state: StepValidationState.partiallyValid, hint: i18n`Path should not end with a computed field.` };
    }
    if (targetConceptDefinitionId === Concept) {
      return { state: StepValidationState.partiallyValid, hint: i18n`Path should not target Concept.` };
    }
    if (fieldDefinitionId === AssociationField) {
      const fieldHandler = associationFieldHandler(store, pathLastFieldInformation.fieldId);
      return {
        state: StepValidationState.valid,
        hint: i18n`Create an instance of '${getConceptDefinitionNameOrEntity(store, targetConceptDefinitionId)}' linked to ${isMultiple ? i18n`the list of selected` : i18n`the selected`} '${getConceptDefinitionNameOrEntity(store, originConceptDefinitionId)}' through the association '${formatOrUndef(fieldHandler.resolveConfiguration().title)}'.`,
      };
    } else if (fieldDefinitionId === EmbeddingField) {
      const fieldHandler = embeddingFieldHandler(store, pathLastFieldInformation.fieldId);
      return {
        state: StepValidationState.valid,
        hint: i18n`Create an instance of '${getConceptDefinitionNameOrEntity(store, targetConceptDefinitionId)}' embedded in ${isMultiple ? i18n`the list of selected` : i18n`the selected`} '${getConceptDefinitionNameOrEntity(store, originConceptDefinitionId)}' through the embedding field '${formatOrUndef(fieldHandler.resolveConfiguration().title)}'.`,
      };
    } else if (fieldDefinitionId === RelationMultipleField) {
      const fieldHandler = relationMultipleFieldHandler(store, pathLastFieldInformation.fieldId);
      return {
        state: StepValidationState.valid,
        hint: i18n`Create an instance of '${getConceptDefinitionNameOrEntity(store, targetConceptDefinitionId)}' linked to ${isMultiple ? i18n`the list of selected` : i18n`the selected`} '${getConceptDefinitionNameOrEntity(store, originConceptDefinitionId)}' through the association 1-n '${formatOrUndef(fieldHandler.resolveConfiguration().title)}'.`,
      };
    } else if (fieldDefinitionId === RelationSingleField) {
      const fieldHandler = relationSingleFieldHandler(store, pathLastFieldInformation.fieldId);
      return {
        state: StepValidationState.valid,
        hint: i18n`Create an instance of '${getConceptDefinitionNameOrEntity(store, targetConceptDefinitionId)}' linked to ${isMultiple ? i18n`the list of selected` : i18n`the selected`} '${getConceptDefinitionNameOrEntity(store, originConceptDefinitionId)}' through the association n-1 '${formatOrUndef(fieldHandler.resolveConfiguration().title)}'.`,
      };
    } else {
      return { state: StepValidationState.invalid, hint: i18n`Path should end with a relational field (association n-n / 1-n / n-1 / embedding)` };
    }
  }
};

interface InstanceOperationCardProps {
  instanceOperation: InstanceOperation,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
  onChange: (newOperation: Omit<InstanceOperation, 'id'>) => void,
  onMoveUp?: () => void,
  onMoveDown?: () => void,
  onDelete: () => void,
  onDuplicate: () => void,
  focusOnMount?: boolean,
}

const InstanceOperationCard: FunctionComponent<InstanceOperationCardProps> = ({
  instanceOperation,
  parameterDefinitions,
  onChange,
  onMoveUp,
  onMoveDown,
  onDelete,
  onDuplicate,
  focusOnMount = false,
}) => {
  const classes = useStyles();

  const store = useStore();

  const overlayContainerRef = useRef<HTMLDivElement>(null);

  const createValuePathHandler = createPathConfigurationHandler(
    store,
    parameterDefinitions ?? [],
    [
      ({ path }) => {
        const { state, hint } = getCreatePathState(store, parameterDefinitions, path);
        return [{ state, reasonMessage: hint }];
      },
    ]
  );

  const updateValuePathHandler = createPathConfigurationHandler(
    store,
    parameterDefinitions ?? [],
    [
      ({ path }) => {
        const { state, hint } = getUpdatePathState(store, parameterDefinitions, path);
        return [{ state, reasonMessage: hint }];
      },
    ]
  );

  const newLineIdRef = useRef<string | undefined>();

  const typesOptions: OptionRecord<'create' | 'update'> = {
    create: { id: 'create', label: i18n`Create` },
    update: { id: 'update', label: i18n`Update` },
  };

  const [expanded, setExpanded] = useState(true);
  const [collapsedFieldOperations, setCollapsedFieldOperations] = useState(new Set<string>());

  let pathState: { state: StepValidationState, hint: string };
  let valuePathHandler: PathConfigurationHandler;
  if (instanceOperation.type === 'create') {
    pathState = getCreatePathState(store, parameterDefinitions, instanceOperation.path) ?? {};
    valuePathHandler = createValuePathHandler;
  } else {
    pathState = getUpdatePathState(store, parameterDefinitions, instanceOperation.path) ?? {};
    valuePathHandler = updateValuePathHandler;
  }
  const returnedConceptDefinitionId = getPathReturnedConceptDefinitionId(store, instanceOperation.path);
  const hasOperations = Boolean(instanceOperation.fieldOperations?.length);
  return (
    <BlockContent padded key={instanceOperation.id}>
      <div ref={overlayContainerRef} className={classes.cardContainer}>
        <OverlayContextProvider containerRef={overlayContainerRef}>
          <div className={classes.firstLineContainer}>
            <div className={classes.firstLineContent}>
              <SearchAndSelect
                clearable={false}
                selectedOption={typesOptions[instanceOperation.type]}
                computeOptions={() => Object.values(typesOptions)}
                onSelect={(option) => {
                  if (option) {
                    onChange(joinObjects(instanceOperation, { type: option.id }));
                  }
                }}
              />
              {pathState.state === StepValidationState.valid && (
                <SpacingLine>
                  <Icon name={IconName.info} colorVariant={IconColorVariant.info} tooltip={pathState.hint} />
                </SpacingLine>
              )}
              {pathState.state !== StepValidationState.valid && (<span />)}
              <div className={classes.pathContainer}>
                <PathStepsInput
                  initialPath={instanceOperation.path ?? []}
                  parameterDefinitions={parameterDefinitions}
                  onSubmit={(newPath) => onChange(joinObjects(instanceOperation, { path: newPath }))}
                  placeholder={instanceOperation.type === 'create' ? i18n`Add creation path...` : i18n`Add update path...`}
                  valuePathHandler={valuePathHandler}
                  focusOnMount={focusOnMount}
                />
                <UsageContextProvider usageVariant={UsageVariant.inForm}>
                  <PathMappingAndFiltersInput
                    path={instanceOperation.path ?? []}
                    parameterDefinitions={parameterDefinitions}
                    onChange={(newPath) => onChange(joinObjects(instanceOperation, { path: newPath }))}
                  />
                </UsageContextProvider>
              </div>
            </div>
            <div className={classnames(classes.hiddenActions, selectorsClasses.visibilityHandler)}>
              <SpacingLine customColumnGap={spacingRem.m}>
                <IconOnlyButton
                  tooltip={expanded ? i18n`Collapse` : i18n`Expand`}
                  variant={IconOnlyButtonVariants.tertiary}
                  iconName={expanded ? IconName.unfold_less : IconName.unfold_more}
                  onClick={() => setExpanded((old) => !old)}
                  sizeVariant={SizeVariant.small}
                />
                <SpacingLine>
                  <IconOnlyButton
                    tooltip={i18n`Move Up`}
                    sizeVariant={SizeVariant.small}
                    variant={IconOnlyButtonVariants.secondary}
                    iconName={IconName.expand_less}
                    disabled={!onMoveUp}
                    onClick={() => onMoveUp?.()}
                  />
                  <IconOnlyButton
                    tooltip={i18n`Move Down`}
                    sizeVariant={SizeVariant.small}
                    variant={IconOnlyButtonVariants.secondary}
                    iconName={IconName.expand_more}
                    disabled={!onMoveDown}
                    onClick={() => onMoveDown?.()}
                  />
                  <OverflowMenu
                    menuItems={[
                      {
                        key: 'expandAll',
                        name: i18n`Expand all`,
                        icon: IconName.unfold_more,
                        onClick: () => setCollapsedFieldOperations(new Set()),
                      },
                      {
                        key: 'collapseAll',
                        name: i18n`Collapse all`,
                        icon: IconName.unfold_less,
                        onClick: () => setCollapsedFieldOperations(new Set(instanceOperation.fieldOperations?.map(({ id }) => id))),
                      },
                      {
                        key: 'duplicate',
                        icon: IconName.content_copy_outline,
                        name: i18n`Duplicate`,
                        onClick: onDuplicate,
                      },
                      {
                        key: 'delete',
                        name: i18n`Delete`,
                        icon: IconName.delete,
                        onClick: onDelete,
                        danger: true,
                      },
                    ]}
                    sizeVariant={SizeVariant.small}
                  />
                </SpacingLine>
              </SpacingLine>
            </div>
          </div>
          <div className={classes.line}>
            <div className={classes.paddedContainer}>
              <SpacingLine>
                <Typo variant={TypoVariant.blockInlineTitle}>
                  <TextWithLinks text={i18n`Operations (${instanceOperation.fieldOperations?.length ?? 0})`} />
                </Typo>
              </SpacingLine>
            </div>
            <div className={classes.paddedContainer}>
              <SpacingLine notCentered>
                {Boolean(!expanded && hasOperations) && (
                  <FieldOperationsListSummary fieldOperations={instanceOperation.fieldOperations ?? []} />
                )}
                {Boolean(!expanded || !hasOperations) && (
                  <div className={classes.addContainer}>
                    <SpacingLine>
                      <Button
                        title={i18n`Add an operation`}
                        iconName={IconName.add}
                        variant={ButtonVariant.tertiary}
                        onClick={() => {
                          const id = uuid();
                          onChange(joinObjects(instanceOperation, { fieldOperations: [...instanceOperation.fieldOperations ?? [], { id }] }));
                          newLineIdRef.current = id;
                          setExpanded(true);
                        }}
                      />
                    </SpacingLine>
                  </div>
                )}
              </SpacingLine>
            </div>
          </div>
          {Boolean(expanded && hasOperations) && (
            <div className={classes.operationContainer}>
              <FieldOperationsList
                parameterDefinitions={returnedConceptDefinitionId ? [...parameterDefinitions, {
                  id: INSTANCE_OPERATION_CURRENT_PARAMETER,
                  type: 'parameter',
                  typeId: returnedConceptDefinitionId,
                  label: instanceOperation.type === 'create' ? i18n`Created instance` : i18n`Updated instance`,
                }] : parameterDefinitions}
                conceptDefinitionId={returnedConceptDefinitionId}
                fieldOperations={instanceOperation.fieldOperations ?? []}
                onChange={(newFieldOperations) => {
                  onChange(joinObjects(instanceOperation, { fieldOperations: newFieldOperations }));
                }}
                newLineFocus={newLineIdRef.current ? {
                  id: newLineIdRef.current,
                  reset: () => {
                    newLineIdRef.current = undefined;
                  },
                } : undefined}
                collapseHelper={{
                  isCollapsed: (id: string) => collapsedFieldOperations.has(id),
                  onCollapse: (id: string) => {
                    setCollapsedFieldOperations((old) => {
                      const newSet = new Set(old);
                      newSet.add(id);
                      return newSet;
                    });
                  },
                  onExpand: (id: string) => {
                    setCollapsedFieldOperations((old) => {
                      const newSet = new Set(old);
                      newSet.delete(id);
                      return newSet;
                    });
                  },
                }}
              />
            </div>
          )}
        </OverlayContextProvider>
      </div>
    </BlockContent>
  );
};

export default InstanceOperationCard;
