import type { PropertyAs, PropertyType } from './FieldModuleDslType';
import { PropertyTypeMode } from './FieldModuleDslType';

const basicType = (name: string): PropertyType => ({ mode: PropertyTypeMode.Basic, name });
const localType = (name: string): PropertyType => ({ mode: PropertyTypeMode.Local, name });
const importType = (name: string, path: string, isAbsolutePath?: boolean): PropertyType => ({ mode: PropertyTypeMode.Import, name, path, isAbsolutePath: isAbsolutePath ?? false });

export const asLocal = (name: string, isArray = false): PropertyAs => ({ type: localType(name), isArray });
export const asImport = (name: string, path: string, isArray = false, isAbsolutePath = false): PropertyAs => ({ type: importType(name, path, isAbsolutePath), isArray });

const CommonPropertyType: Record<'string' | 'number' | 'boolean' | 'unknown' | 'DateRange' | 'PeriodicityType' | 'Filters' | 'PathStep' | 'undefined', PropertyType> = {
  string: basicType('string'),
  number: basicType('number'),
  boolean: basicType('boolean'),
  unknown: basicType('unknown'),
  DateRange: importType('DateRange', 'yooi-utils', true),
  PeriodicityType: importType('PeriodicityType', 'yooi-utils', true),
  Filters: importType('Filters', 'modules/conceptModule'),
  PathStep: importType('PathStep', 'modules/conceptModule'),
  undefined: basicType('undefined'),
};

export const CommonAsType: Record<keyof typeof CommonPropertyType | 'stringArray' | 'Path', PropertyAs> = {
  string: { type: CommonPropertyType.string },
  stringArray: { type: CommonPropertyType.string, isArray: true },
  number: { type: CommonPropertyType.number },
  boolean: { type: CommonPropertyType.boolean },
  unknown: { type: CommonPropertyType.unknown },
  DateRange: { type: CommonPropertyType.DateRange },
  PeriodicityType: { type: CommonPropertyType.PeriodicityType },
  Filters: { type: CommonPropertyType.Filters },
  PathStep: { type: CommonPropertyType.PathStep },
  Path: { type: CommonPropertyType.PathStep, isArray: true },
  undefined: { type: CommonPropertyType.undefined },
};
