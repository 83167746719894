import type { PlatformConfigurationStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import { CurrentPlatformConfiguration, PlatformConfiguration_ColorPalette, PlatformConfiguration_ColorPaletteType } from 'yooi-modules/modules/platformConfigurationModule/ids';
import type { PaletteColor } from 'yooi-modules/modules/platformConfigurationModule/model';
import { ConfigurationType } from 'yooi-modules/modules/platformConfigurationModule/model';
import type { ObjectStore } from 'yooi-store';
import { Opacity } from '../../theme/base';
import { generateColorFromOpacity } from '../../theme/colorUtils';

export const computeLightShade = (color: string): string => generateColorFromOpacity(color, '#fff', Opacity.fifteen);
export const getColorPalette = (store: ObjectStore): PaletteColor[] | undefined => {
  const configuration = store.getObject<PlatformConfigurationStoreObject>(CurrentPlatformConfiguration);
  return configuration[PlatformConfiguration_ColorPaletteType] === ConfigurationType.custom ? configuration[PlatformConfiguration_ColorPalette] : undefined;
};
