import type { FunctionComponent } from 'react';
import { isFiniteNumber } from 'yooi-utils';
import { Opacity } from '../../theme/base';
import { generateColorFromOpacity, getMostReadableColorFromBackgroundColor } from '../../theme/colorUtils';
import { spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import useTheme from '../../utils/useTheme';
import Tooltip from '../atoms/Tooltip';
import SpacingLine from './SpacingLine';
import Spinner, { SpinnerVariant } from './Spinner';

const useStyles = makeStyles({
  base: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacingRem.splus,
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    lineHeight: 1.2,
  },
  spinnerContainer: {
    position: 'absolute',
    bottom: spacingRem.s,
    right: spacingRem.s,
  },
}, 'reactiveSizeNumber');

interface ReactiveSizeNumberProps {
  width: number,
  height: number,
  containerWidth: number,
  value: number | undefined,
  decimals: number,
  unit: string | undefined,
  color?: string,
  backgroundColor?: string,
  withSpinner?: boolean,
}

const ReactiveSizeNumber: FunctionComponent<ReactiveSizeNumberProps> = ({ width, height, containerWidth, value, decimals, unit, color, backgroundColor, withSpinner = false }) => {
  const theme = useTheme();
  const classes = useStyles();

  const mostReadableColor = getMostReadableColorFromBackgroundColor(backgroundColor ?? theme.color.background.neutral.default);
  const textColor = color ?? mostReadableColor;

  const formattedValue = isFiniteNumber(value) ? value.toLocaleString(i18n.locale, decimals > 0 ? { maximumFractionDigits: decimals, minimumFractionDigits: decimals } : {}) : '-';

  return (
    <div
      className={classes.base}
      style={{ width, height, fontSize: height * (containerWidth / 3000) }}
    >
      {withSpinner ? (
        <span className={classes.spinnerContainer}>
          <Spinner
            size={SpinnerVariant.small}
            color={generateColorFromOpacity(mostReadableColor, backgroundColor, Opacity.fifty)}
          />
        </span>
      ) : null}
      <Tooltip title={unit ? `${formattedValue} ${unit}` : formattedValue}>
        <span>
          <SpacingLine>
            <div className={classes.text} style={{ color: textColor }}>
              {formattedValue}
            </div>
            {unit ? (
              <div className={classes.text} style={{ color: textColor }}>
                {unit}
              </div>
            ) : null}
          </SpacingLine>
        </span>
      </Tooltip>
    </div>
  );
};

export default ReactiveSizeNumber;
