import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import { BlockFieldLayoutOption } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type {
  ConceptDefinitionRaw,
  ConceptDefinitionStoreObject,
  FilterValueRaw,
  IdFieldAssociationRaw,
  IdFieldAssociationStoreObject,
  IdFieldRaw,
  IdFieldStoreObject,
} from 'yooi-modules/modules/conceptModule';
import { buildFunctionalIdFormatter, FilterValueType, idFieldHandler, ParsedDimensionType, parseDimensionMapping } from 'yooi-modules/modules/conceptModule';
import type { Field_Title } from 'yooi-modules/modules/conceptModule/ids';
import {
  Concept_FunctionalId,
  ConceptDefinition_Prefix,
  Field_ApiAlias,
  Field_Documentation,
  Field_IsDocumentationInline,
  IdFieldAssociation,
  IdFieldAssociation_NextId,
  IdFieldAssociation_Role_ConceptDefinition,
  IdFieldAssociation_Role_Field,
} from 'yooi-modules/modules/conceptModule/ids';
import { compareString, comparing, isFiniteNumber, joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo, { TypoVariant } from '../../../../components/atoms/Typo';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import { TableSortDirection } from '../../../../components/molecules/Table';
import { Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import { remToPx } from '../../../../utils/sizeUtils';
import { getTextMeasurerForVariant } from '../../../../utils/textUtils';
import StoreNumberPickerInput from '../../input/StoreNumberPickerInput';
import StoreTextInputField from '../../input/StoreTextInputField';
import { getBlockFieldLayoutOption, getDefaultDisplayOptions, getLayoutDisplayOption } from '../_global/blockFieldUtils';
import { getApiAliasEditionOption, getApiAliasInitialState, getDocumentationFieldEditionSection } from '../_global/editionHandlerUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import { FIELD_EDITION_DIMENSIONS, getFieldDimensionsEditionHandlerValue } from '../fieldDimensionUtils';
import type { FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, FieldComparatorHandler, GetFieldDefinitionHandler, RenderFilter } from '../FieldLibraryTypes';
import { FieldEditionOptionMode } from '../FieldLibraryTypes';
import IdFieldRenderer from './IdFieldRenderer';

interface IdFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
  [IdFieldAssociation_NextId]: string | null | undefined,
  [ConceptDefinition_Prefix]: string | null | undefined,
}

type IdFieldDefinition = GetFieldDefinitionHandler<typeof idFieldHandler, IdFieldConfigurationState, never, FieldBlockDisplayOptions>;

export const idFieldDefinition: IdFieldDefinition = registerFieldDefinition(idFieldHandler, {
  configuration: {
    getTypeLabel: () => i18n`Id`,
    typeIcon: IconName.pin,
    asWidget: false,
    getEditionOptions: (objectStore) => ({ mode, editionHandler }) => {
      if (mode !== FieldEditionOptionMode.Field && mode !== FieldEditionOptionMode.FieldDeveloperMode) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      const nextIdRaw = editionHandler.getValue(IdFieldAssociation_NextId);
      const nextId = typeof nextIdRaw === 'string' ? Number.parseInt(nextIdRaw, 10) : nextIdRaw;

      sections.push({
        key: 'data',
        type: 'section',
        title: i18n`Data`,
        options: [
          {
            key: ConceptDefinition_Prefix,
            title: i18n`Concept ID prefix`,
            hasValue: () => editionHandler.getValue(ConceptDefinition_Prefix) !== undefined,
            clearValue: () => {},
            type: EditionOptionTypes.text,
            props: {
              maxLine: 1,
              onChange: (value) => editionHandler.updateValues({ [ConceptDefinition_Prefix]: value }),
              value: editionHandler.getValue(ConceptDefinition_Prefix),
            },
          },
          {
            key: IdFieldAssociation_NextId,
            title: i18n`Next concept ID`,
            info: i18n`Updating this value could lead to conflict, handle with care`,
            hasValue: () => nextId !== undefined,
            clearValue: () => {},
            type: EditionOptionTypes.number,
            props: {
              onChange: (value) => editionHandler.updateValues({ [IdFieldAssociation_NextId]: typeof value === 'number' ? value.toString() : null }),
              value: nextId,
            },
          },
        ],
      });

      sections.push(getDocumentationFieldEditionSection(editionHandler));

      if (mode === FieldEditionOptionMode.FieldDeveloperMode) {
        sections.push({
          key: 'integration',
          type: 'section',
          title: i18n`Integration`,
          options: [getApiAliasEditionOption(objectStore, editionHandler)],
        });
      }

      return sections;
    },
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<IdFieldStoreObject>(fieldId);
        const nextId = objectStore.withAssociation(IdFieldAssociation)
          .withRole(IdFieldAssociation_Role_ConceptDefinition, conceptDefinitionId)
          .withRole(IdFieldAssociation_Role_Field, Concept_FunctionalId)
          .getObjectOrNull<IdFieldAssociationStoreObject>()
          ?.[IdFieldAssociation_NextId] ?? '1';
        const conceptDefinition = objectStore.getObject<ConceptDefinitionStoreObject>(conceptDefinitionId);
        return joinObjects(
          getApiAliasInitialState(objectStore, fieldId),
          {
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            [IdFieldAssociation_NextId]: nextId,
            [ConceptDefinition_Prefix]: conceptDefinition[ConceptDefinition_Prefix],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
          }
        );
      },
      submitFieldUpdate: (stateToSubmit, conceptDefinitionId) => {
        objectStore.updateObject<IdFieldRaw>(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
        });
        objectStore.updateObject<ConceptDefinitionRaw>(conceptDefinitionId, { [ConceptDefinition_Prefix]: stateToSubmit[ConceptDefinition_Prefix] });
        objectStore.withAssociation(IdFieldAssociation)
          .withRole(IdFieldAssociation_Role_ConceptDefinition, conceptDefinitionId)
          .withRole(IdFieldAssociation_Role_Field, Concept_FunctionalId)
          .updateObject<IdFieldAssociationRaw>({ [IdFieldAssociation_NextId]: stateToSubmit[IdFieldAssociation_NextId] });
      },
    }),
  },
  renderField: (_, fieldId) => ({ dimensionsMapping }) => {
    const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
    if (parsedDimensionMapping.type !== ParsedDimensionType.MonoDimensional) {
      return null;
    }

    return (<IdFieldRenderer fieldId={fieldId} objectId={parsedDimensionMapping.objectId} />);
  },
  renderExportConfiguration: () => () => (
    <SpacedContainer margin={{ x: Spacing.splus }}>
      <Tooltip title={i18n`Text`}>
        <Typo maxLine={1}>{i18n`Text`}</Typo>
      </Tooltip>
    </SpacedContainer>
  ),
  getActivityProperties: (_, fieldId) => () => [fieldId],
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({
    propertyId: fieldId,
    sortable: true,
  }),
  estimatedColumnWidth: (objectStore, fieldId) => (conceptDefinitionId) => {
    const nextId = objectStore.withAssociation(IdFieldAssociation)
      .withRole(IdFieldAssociation_Role_Field, fieldId)
      .withRole(IdFieldAssociation_Role_ConceptDefinition, conceptDefinitionId)
      .getObjectOrNull()
      ?.[IdFieldAssociation_NextId] as string | number | undefined;

    const longestId = '4'.repeat(((typeof nextId === 'number' ? `${nextId}` : nextId) ?? '0').length);
    const formattedLongestId = buildFunctionalIdFormatter(objectStore, conceptDefinitionId, fieldId)(longestId);

    const measureText = getTextMeasurerForVariant(TypoVariant.body);
    const estimatedWidth = measureText(formattedLongestId) + remToPx(1.6 /* padding */ + 0.1 /* border */);

    const minWidth = remToPx(3);

    return `${Math.max(minWidth, estimatedWidth)}px`;
  },
  getComparatorHandler: (_, __, { getValueResolution }) => (direction) => ({
    comparator: comparing(compareString, direction === TableSortDirection.desc),
    extractValue: (dimensionsMapping) => getValueResolution(dimensionsMapping).value,
  } satisfies FieldComparatorHandler<string | undefined>),
  blockDisplayOptionsHandler: (objectStore) => (fieldBlockDisplayId) => ({
    getDisplayOptions: () => getDefaultDisplayOptions(objectStore, fieldBlockDisplayId),
    renderSummary: ({ layoutDisplayType }) => ([getBlockFieldLayoutOption()[layoutDisplayType ?? BlockFieldLayoutOption.auto].label]),
    getBlockEditionOptionSections: (state, setState) => [getLayoutDisplayOption(state, setState)],
    onSubmit: (state) => {
      objectStore.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
  filterConditions: () => {
    const renderTextFilter: RenderFilter<FilterValueRaw<string>> = (value, setValue, _, readOnly) => (
      <StoreTextInputField
        initialValue={value.raw}
        onSubmit={(v) => setValue({ type: FilterValueType.raw, raw: v ?? '' })}
        readOnly={readOnly}
        maxLine={1}
        withDropdown
        dropdownMaxLine={5}
        fullWidth
        placeholder={i18n`Value`}
      />
    );
    const renderNumberFilter: RenderFilter<FilterValueRaw<number | undefined>> = (value, setValue, _, readOnly) => (
      <StoreNumberPickerInput
        placeholder={i18n`Value`}
        initialValue={value.raw}
        onSubmit={(newValue) => {
          if (newValue !== null && !isFiniteNumber(newValue)) {
            return;
          }
          setValue({ type: FilterValueType.raw, raw: typeof newValue === 'string' ? Number(newValue) : (newValue ?? undefined) });
        }}
        readOnly={readOnly}
      />
    );
    return {
      CONTAINS: {
        name: i18n`Contains`,
        renderFilter: renderTextFilter,
      },
      DOES_NOT_CONTAIN: {
        name: i18n`Does not contain`,
        renderFilter: renderTextFilter,
      },
      EQUALS: {
        name: i18n`Is`,
        renderFilter: renderNumberFilter,
      },
      NOT_EQUALS: {
        name: i18n`Is not`,
        renderFilter: renderNumberFilter,
      },
      GREATER: {
        name: i18n`>`,
        renderFilter: renderNumberFilter,
      },
      GREATER_OR_EQUALS: {
        name: i18n`≥`,
        renderFilter: renderNumberFilter,
      },
      LOWER: {
        name: i18n`<`,
        renderFilter: renderNumberFilter,
      },
      LOWER_OR_EQUALS: {
        name: i18n`≤`,
        renderFilter: renderNumberFilter,
      },
    };
  },
});
