import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { dimensionsMappingToParametersMapping, FILTER_PARAMETER_CURRENT, InstanceReferenceType, PathStepType } from 'yooi-modules/modules/conceptModule';
import { Concept_Name, ConceptDefinition, Field_Documentation, Field_IsDocumentationInline, Field_Title } from 'yooi-modules/modules/conceptModule/ids';
import type { RadarChartFieldConfiguration, RadarChartFieldRaw, RadarChartFieldStoreObject } from 'yooi-modules/modules/dashboardModule';
import { radarChartFieldHandler } from 'yooi-modules/modules/dashboardModule';
import { RadarChartField, RadarChartField_Configuration, RadarChartField_ModelType } from 'yooi-modules/modules/dashboardModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreReadOnly } from 'yooi-store';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import { CompositeFieldVariants } from '../../../../components/molecules/CompositeField';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import i18n from '../../../../utils/i18n';
import { formatOrUndef } from '../../../../utils/stringUtils';
import { UsageContextProvider, UsageVariant } from '../../../../utils/useUsageContext';
import FilterComposite from '../../filter/filterComposite/FilterComposite';
import { getLoggedUserParameterDefinition } from '../../filter/filterUtils';
import { getDefaultInstanceFilter } from '../../FrontFilterRenderers';
import { defaultOptionComparator, getChipOptions, getConceptDefinitionNameOrEntity } from '../../modelTypeUtils';
import { getDocumentationFieldEditionSection } from '../_global/editionHandlerUtils';
import type { StepOption } from '../_global/pathUtils';
import { ChartColorPathAcceptedFields, computePathOptions, filterPath } from '../_global/widgetSeriesUtils';
import type { WidgetDisplay } from '../_global/widgetUtils';
import { defaultWidgetDisplay, getWidgetDisplayTypeLabel, getWidgetEditionOptionSection, getWidgetHeight } from '../_global/widgetUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  duplicateFieldDimensionWithNewField,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
  submitDimensionUpdate,
} from '../fieldDimensionUtils';
import type { FieldEditionOption, FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode } from '../FieldLibraryTypes';
import RadarChartBlockField from './RadarChartBlockField';
import RadarChartFieldSeriesOptions from './RadarChartFieldSeriesOptions';
import { ChartLabelPathAcceptedFields } from './radarChartFieldUtils';
import RadarChartWidget from './RadarChartWidget';

interface RadarChartFieldBlockDisplayOptions extends FieldBlockDisplayOptions {
  widgetDisplay?: WidgetDisplay,
}

const getDisplayOptions = (objectStore: ObjectStoreReadOnly, fieldBlockDisplayId: string): RadarChartFieldBlockDisplayOptions => {
  const fieldBlockDisplay = objectStore.getObjectOrNull(fieldBlockDisplayId);
  if (fieldBlockDisplay?.[FieldBlockDisplay_FieldDisplayConfiguration]) {
    return fieldBlockDisplay[FieldBlockDisplay_FieldDisplayConfiguration] as RadarChartFieldBlockDisplayOptions;
  } else {
    return defaultWidgetDisplay;
  }
};

interface RadarChartFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
  [RadarChartField_Configuration]: RadarChartFieldConfiguration | null | undefined,
  [RadarChartField_ModelType]: string | null | undefined,
}

type RadarChartFieldDefinition = GetFieldDefinitionHandler<typeof radarChartFieldHandler, RadarChartFieldConfigurationState, never, RadarChartFieldBlockDisplayOptions>;

export const radarChartFieldDefinition: RadarChartFieldDefinition = registerFieldDefinition(radarChartFieldHandler, {
  configuration: {
    typeIcon: IconName.radar,
    getTypeLabel: () => i18n`Radar chart`,
    asWidget: true,
    getEditionOptions: (store) => ({ mode, editionHandler, readOnly, dashboardParameterDefinitions, modelTypeId }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode, FieldEditionOptionMode.Widget].includes(mode)) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      const fieldEditionDimensions: FieldEditionDimensions = editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {};
      const parameterDefinitions = [
        ...dashboardParameterDefinitions ?? [],
        ...Object.entries(fieldEditionDimensions).map(([id, { typeId }]): SingleParameterDefinition => ({ id, typeId, label: i18n`Dimension`, type: 'dimension' })),
      ];

      if ([FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        sections.push(getDocumentationFieldEditionSection(editionHandler));
      }

      const selectedModelType = editionHandler.getValueOrDefault(RadarChartField_ModelType);
      const selectedModelTypeOption = selectedModelType ? getChipOptions(store, selectedModelType) : undefined;
      const hasValidModelType = !!selectedModelType && Boolean(store.getObjectOrNull(selectedModelType));

      const viewFilters = editionHandler.getValue(RadarChartField_Configuration)?.filters;
      const displayOptions: FieldEditionOption[] = [];
      displayOptions.push(
        {
          key: RadarChartField_ModelType,
          title: i18n`Data`,
          hasValue: () => editionHandler.getValue(RadarChartField_ModelType) !== undefined,
          clearValue: () => editionHandler.updateValues({ [RadarChartField_ModelType]: null }),
          fullWidth: true,
          type: EditionOptionTypes.custom,
          props: {
            render: () => (
              <SpacingLine>
                <SearchAndSelect
                  placeholder={i18n`Add data`}
                  computeOptions={() => store.getObject(ConceptDefinition)
                    .navigateBack(Class_Instances)
                    .map(({ id: instanceId }) => getChipOptions(store, instanceId))
                    .filter(filterNullOrUndefined)
                    .sort(defaultOptionComparator)}
                  selectedOption={selectedModelTypeOption}
                  onSelect={(option) => {
                    if (!option) {
                      return;
                    }

                    const labelPath = [];
                    labelPath.push(Concept_Name);
                    let currentParameterDefinition: SingleParameterDefinition | undefined;
                    if (mode === FieldEditionOptionMode.Field) {
                      currentParameterDefinition = parameterDefinitions.find(({ typeId }) => typeId === modelTypeId);
                    }
                    const newConfiguration: RadarChartFieldConfiguration = joinObjects(
                      editionHandler.getValue(RadarChartField_Configuration),
                      {
                        labelPath,
                        series: [],
                        filters: currentParameterDefinition && option?.id ? getDefaultInstanceFilter(option.id, currentParameterDefinition.id) : undefined,
                      }
                    );
                    editionHandler.updateValues({
                      [RadarChartField_ModelType]: option.id,
                      [RadarChartField_Configuration]: newConfiguration,
                    });
                  }}
                  readOnly={readOnly}
                />
                {hasValidModelType && (
                  <UsageContextProvider usageVariant={UsageVariant.inForm}>
                    <FilterComposite
                      rootPath={{
                        label: i18n`Current (${formatOrUndef(getConceptDefinitionNameOrEntity(store, selectedModelType))})`,
                        path: [
                          { type: PathStepType.dimension, conceptDefinitionId: selectedModelType },
                          { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
                        ],
                      }}
                      requiredConceptDefinitionId={selectedModelType}
                      filtersDefinition={{
                        updateFilters: (filters) => {
                          const configuration = editionHandler.getValue(RadarChartField_Configuration);
                          const newConfiguration: RadarChartFieldConfiguration = joinObjects((configuration ?? {}), { filters: filters[0] });
                          editionHandler.updateValues({
                            [RadarChartField_Configuration]: newConfiguration,
                          });
                        },
                        definition: [{ filter: viewFilters }],
                      }}
                      parameterDefinitions={[
                        { id: FILTER_PARAMETER_CURRENT, label: i18n`Current`, typeId: selectedModelType },
                        getLoggedUserParameterDefinition(),
                        ...parameterDefinitions,
                      ].filter((parameterDefinition): parameterDefinition is SingleParameterDefinition => Boolean(parameterDefinition.typeId))}
                      readOnly={readOnly}
                      variant={CompositeFieldVariants.button}
                    />
                  </UsageContextProvider>
                )}
              </SpacingLine>
            ),
          },
        }
      );
      if (hasValidModelType) {
        const {
          error: labelError,
          path: labelFilteredPath,
        } = filterPath(store, editionHandler.getValue(RadarChartField_Configuration)?.labelPath, ChartLabelPathAcceptedFields);

        displayOptions.push({
          key: 'legendLabelPath',
          title: i18n`Legend label`,
          hasValue: () => editionHandler.getValue(RadarChartField_Configuration) !== undefined,
          clearValue: () => {},
          type: EditionOptionTypes.selectMultiple,
          props: {
            placeholder: i18n`Select legend label`,
            error: labelFilteredPath?.length ? labelError : undefined,
            readOnly,
            selectedSteps: labelFilteredPath
              .map((instanceId) => getChipOptions(store, instanceId))
              .filter(filterNullOrUndefined),
            computeOptions: () => computePathOptions(store, selectedModelType, labelFilteredPath, ChartLabelPathAcceptedFields),
            onSelect: ({ id }: StepOption) => {
              const configuration = editionHandler.getValueOrDefault(RadarChartField_Configuration);
              const newConfiguration: RadarChartFieldConfiguration = joinObjects((configuration ?? {}), { labelPath: [...labelFilteredPath, id] });
              editionHandler.updateValues({ [RadarChartField_Configuration]: newConfiguration });
            },
            onDelete: ({ id }: StepOption) => {
              const configuration = editionHandler.getValueOrDefault(RadarChartField_Configuration);
              const newConfiguration: RadarChartFieldConfiguration = joinObjects(
                configuration,
                { labelPath: labelFilteredPath.splice(0, labelFilteredPath.indexOf(id)) }
              );
              editionHandler.updateValues({
                [RadarChartField_Configuration]: newConfiguration,
              });
            },
          },
        });

        const {
          error: colorError,
          path: colorFilteredPath,
        } = filterPath(store, editionHandler.getValue(RadarChartField_Configuration)?.colorPath, ChartColorPathAcceptedFields);

        displayOptions.push({
          key: 'instanceColor',
          title: i18n`Color`,
          hasValue: () => editionHandler.getValue(RadarChartField_Configuration) !== undefined,
          clearValue: () => {},
          type: EditionOptionTypes.selectMultiple,
          props: {
            placeholder: i18n`Select color`,
            error: colorFilteredPath?.length ? colorError : undefined,
            readOnly,
            selectedSteps: colorFilteredPath
              .map((instanceId) => getChipOptions(store, instanceId))
              .filter(filterNullOrUndefined),
            computeOptions: () => computePathOptions(store, selectedModelType, colorFilteredPath, ChartColorPathAcceptedFields),
            onSelect: ({ id }: StepOption) => {
              const configuration = editionHandler.getValueOrDefault(RadarChartField_Configuration);
              const newConfiguration: RadarChartFieldConfiguration = joinObjects((configuration ?? {}), { colorPath: [...colorFilteredPath, id] });
              editionHandler.updateValues({ [RadarChartField_Configuration]: newConfiguration });
            },
            onDelete: ({ id }: StepOption) => {
              const configuration = editionHandler.getValueOrDefault(RadarChartField_Configuration);
              const newConfiguration: RadarChartFieldConfiguration = joinObjects(
                configuration,
                { colorPath: colorFilteredPath.splice(0, colorFilteredPath.indexOf(id)) }
              );
              editionHandler.updateValues({
                [RadarChartField_Configuration]: newConfiguration,
              });
            },
          },
        });

        displayOptions.push({
          key: 'series',
          title: i18n`Series values`,
          hasValue: () => editionHandler.getValue(RadarChartField_Configuration) !== undefined,
          clearValue: () => {},
          type: EditionOptionTypes.custom,
          isVertical: true,
          padded: true,
          props: {
            render: () => (
              <RadarChartFieldSeriesOptions
                selectedXAxis={editionHandler.getValueOrDefault(RadarChartField_ModelType) as string}
                configuration={editionHandler.getValueOrDefault(RadarChartField_Configuration) as RadarChartFieldConfiguration}
                onSubmit={editionHandler.updateValues}
                readOnly={readOnly}
                parameterDefinitions={parameterDefinitions}
                modelTypeId={modelTypeId}
              />
            ),
          },
        });
      }

      sections.push({
        key: 'display',
        type: 'section',
        title: i18n`Display`,
        options: displayOptions,
      });

      return sections;
    },
    isCreationEnabled: () => () => true,
    onCreate: (objectStore) => (editionHandler) => {
      const fieldId = objectStore.createObject<RadarChartFieldRaw>({
        [Instance_Of]: RadarChartField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [RadarChartField_ModelType]: editionHandler.getValue(RadarChartField_ModelType),
        [RadarChartField_Configuration]: editionHandler.getValue(RadarChartField_Configuration),
      });
      linkFieldToFieldDimensions(objectStore, fieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});
      return fieldId;
    },
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<RadarChartFieldStoreObject>(fieldId);
        return {
          [Field_Title]: field[Field_Title],
          [Field_Documentation]: field[Field_Documentation],
          [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
          [RadarChartField_ModelType]: field[RadarChartField_ModelType],
          [RadarChartField_Configuration]: field[RadarChartField_Configuration],
          [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
        };
      },
      submitFieldUpdate: (stateToSubmit, conceptDefinitionId) => {
        objectStore.updateObject(fieldId, {
          [Field_Title]: stateToSubmit[Field_Title],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [RadarChartField_ModelType]: stateToSubmit[RadarChartField_ModelType],
          [RadarChartField_Configuration]: stateToSubmit[RadarChartField_Configuration],
        });
        submitDimensionUpdate(objectStore, fieldId, conceptDefinitionId, stateToSubmit[FIELD_EDITION_DIMENSIONS] ?? {});
      },
      duplicateFieldDefinition: ({ parameterMap }) => {
        const fieldInstance = objectStore.getObject(fieldId);
        const xAxis = fieldInstance[RadarChartField_ModelType] ? objectStore.getObjectOrNull(fieldInstance[RadarChartField_ModelType] as string) : undefined;

        const fieldDimensionMapping = generateDuplicatedFieldDimensionId(objectStore, fieldId);
        let newConfiguration = fieldInstance[RadarChartField_Configuration] as RadarChartFieldConfiguration | undefined;
        if (xAxis && newConfiguration && parameterMap) {
          let updatedConfiguration = JSON.stringify(newConfiguration);
          Object.entries(parameterMap).forEach(([pid, npid]) => {
            updatedConfiguration = updatedConfiguration.replaceAll(pid, npid);
          });
          Object.entries(fieldDimensionMapping).forEach(([pid, npid]) => {
            updatedConfiguration = updatedConfiguration.replaceAll(pid, npid);
          });
          newConfiguration = JSON.parse(updatedConfiguration);
        }
        const newFieldId = objectStore.createObject({
          [Instance_Of]: fieldInstance[Instance_Of],
          [Field_Title]: `${fieldInstance[Field_Title]} (copy)`,
          [Field_Documentation]: fieldInstance[Field_Documentation],
          [Field_IsDocumentationInline]: fieldInstance[Field_IsDocumentationInline],
          [RadarChartField_ModelType]: xAxis ? fieldInstance[RadarChartField_ModelType] : undefined,
          [RadarChartField_Configuration]: xAxis ? newConfiguration : {},
        });
        duplicateFieldDimensionWithNewField(objectStore, newFieldId, fieldDimensionMapping);
        return newFieldId;
      },
    }),
  },
  renderBlockField: (_, fieldId) => (dimensionsMapping, displayOptions, blockFieldProps, layoutParametersMapping) => (
    <RadarChartBlockField
      fieldId={fieldId}
      chartHeight={getWidgetHeight((displayOptions ?? defaultWidgetDisplay).widgetDisplay)}
      parametersMapping={joinObjects(dimensionsMappingToParametersMapping(dimensionsMapping), layoutParametersMapping)}
      blockFieldProps={blockFieldProps}
    />
  ),
  renderWidget: (_, fieldId) => ({ parametersMapping }) => (
    <RadarChartWidget
      fieldId={fieldId}
      parametersMapping={parametersMapping}
    />
  ),
  blockDisplayOptionsHandler: (objectStore) => (fieldBlockDisplayId) => ({
    getDisplayOptions: () => getDisplayOptions(objectStore, fieldBlockDisplayId),
    renderSummary: (state) => [getWidgetDisplayTypeLabel((state.widgetDisplay ?? defaultWidgetDisplay).type)],
    getBlockEditionOptionSections: (state, setState) => [
      getWidgetEditionOptionSection(
        state.widgetDisplay ?? defaultWidgetDisplay,
        (newWidgetDisplay) => setState(joinObjects(state, { widgetDisplay: newWidgetDisplay }))
      ),
    ],
    onSubmit: (state) => {
      objectStore.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
});
