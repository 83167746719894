import type { FunctionComponent } from 'react';
import { DndProvider } from 'react-dnd';
import useResizeObserver from 'use-resize-observer';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { MatrixViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import { remToPx } from '../../../../utils/sizeUtils';
import useDeepMemo from '../../../../utils/useDeepMemo';
import { dndManager } from '../../fields/_global/dndUtils';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { useViewFilters } from '../../filter/useViewFilters';
import BlockViewError from '../common/BlockViewError';
import { getViewNavigationFilters } from '../common/viewUtils';
import { getViewDefinitionHandler } from '../viewDsl';
import { isResolutionError } from '../viewResolutionUtils';
import ConceptMatrixView from './ConceptMatrixView';
import type { MatrixViewDefinitionHandler } from './matrixViewDefinitionHandler';

const useStyles = makeStyles((theme) => ({
  paddedContainer: {
    display: 'grid',
    gridAutoRows: 'minmax(min-content, max-content)',
    gridTemplateColumns: '1fr',
    rowGap: spacingRem.s,
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
    paddingLeft: spacingRem.s,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.s,
    overflow: 'hidden',
  },
}), 'matrixViewBlock');

interface MatrixViewBlockProps {
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinition: MatrixViewStoredDefinition,
  parametersMapping: ParametersMapping,
  readOnly: boolean | undefined,
}

const MatrixViewBlock: FunctionComponent<MatrixViewBlockProps> = ({
  viewDimensions,
  viewFilters,
  viewDefinition,
  parametersMapping,
  readOnly,
}) => {
  const classes = useStyles();

  const store = useStore();
  const { loggedUserId } = useAuth();
  const aclHandler = useAcl();

  const { ref: containerRef, width: containerWidthPx } = useResizeObserver();

  const filterConfiguration = useViewFilters(viewFilters, viewDimensions);

  const viewHandler = getViewDefinitionHandler(viewDefinition) as MatrixViewDefinitionHandler;
  const resolvedViewDefinition = viewHandler.getDefinition(store, viewDimensions);
  const viewResolution = useDeepMemo(
    () => viewHandler.resolveView(store, { viewDimensions, parametersMapping, aclHandler, userId: loggedUserId, filterConfiguration }),
    [store.getSerial(), viewDefinition, viewDimensions, parametersMapping, filterConfiguration]
  );

  if (isResolutionError(viewResolution)) {
    return (<BlockViewError error={viewResolution.error} />);
  } else {
    return (
      <span className={classes.paddedContainer}>
        <div ref={containerRef} className={classes.container}>
          {
            containerWidthPx === undefined
              ? null
              : (
                <DndProvider manager={dndManager}>
                  <ConceptMatrixView
                    filterKey={viewFilters.filterKey}
                    parametersMapping={parametersMapping}
                    viewResolution={viewResolution}
                    viewDefinition={resolvedViewDefinition}
                    readOnly={viewDefinition.readOnly || readOnly}
                    navigationFilters={getViewNavigationFilters(store, viewDimensions, filterConfiguration, parametersMapping)}
                    containerWidthPx={containerWidthPx}
                    containerHeightPx={remToPx(65)}
                  />
                </DndProvider>
              )
          }
        </div>
      </span>
    );
  }
};

export default MatrixViewBlock;
