import type { FunctionComponent } from 'react';
import { useCallback } from 'react';
import { joinObjects } from 'yooi-utils';
import { buildPadding, Spacing } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { buildComponentPropertyStyleInRem, HierarchyVariant, SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import Icon, { IconName } from '../atoms/Icon';
import StopClickPropagation from '../atoms/StopClickPropagation';
import Tooltip from '../atoms/Tooltip';
import Typo, { TypoVariant } from '../atoms/Typo';
import CompositeField, { CompositeFieldCloseReasons } from '../molecules/CompositeField';
import Link from '../molecules/Link';
import SimpleInput from './strategy/SimpleInput';
import TextInputString from './TextInputString';

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    minWidth: '2.4rem',
    minHeight: '2.4rem',
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
  },
  navigationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  fakeAnchor: {
    height: '2.6rem',
    display: 'flex',
    alignItems: 'center',
    color: theme.color.text.info,
    cursor: 'pointer',
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
  urlContainer: joinObjects(
    buildComponentPropertyStyleInRem('height', SizeVariant.main, HierarchyVariant.inline),
    {
      display: 'flex',
      alignItems: 'center',
    }
  ),
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}), 'uRLInput');

interface URLInputProps {
  value: string | undefined,
  onChange: (value: string | undefined) => void,
  onSubmit: (value: string | undefined) => void,
  onCancel: () => void,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  href?: string,
  type?: string,
  isEditing?: boolean,
  readOnly?: boolean,
  focusOnMount?: boolean,
}

const URLInput: FunctionComponent<URLInputProps> = ({
  value,
  onChange,
  onSubmit,
  onCancel,
  onEditionStart,
  onEditionStop,
  href,
  type,
  isEditing = false,
  readOnly = false,
  focusOnMount = false,
}) => {
  const classes = useStyles();

  const onOpenDropdown = useCallback(() => {
    if (!readOnly) {
      onEditionStart?.();
    }
  }, [onEditionStart, readOnly]);

  const onCloseDropdown = useCallback((reason: CompositeFieldCloseReasons) => {
    if (!readOnly) {
      onEditionStop?.();
      if (reason === CompositeFieldCloseReasons.cancel) {
        onCancel();
      } else {
        onSubmit(value);
      }
    }
  }, [onCancel, onEditionStop, onSubmit, readOnly, value]);

  return (
    <CompositeField
      isEditing={isEditing}
      placeholder={readOnly ? undefined : i18n`Add URL`}
      doNotExpand={readOnly}
      openOnMount={focusOnMount}
      headerLinesRenderers={(value) ? [
        {
          id: 'URL',
          render: () => (
            <div className={classes.urlContainer}>
              <Tooltip title={value}>
                <Typo variant={TypoVariant.code} maxLine={1}>
                  {value}
                </Typo>
              </Tooltip>
            </div>
          ),
        },
        {
          id: 'navigate',
          customContainerStyle: { ...buildPadding({ left: Spacing.s, right: Spacing.none }) },
          render: () => (
            <div className={classes.navigationContainer}>
              <SizeContextProvider sizeVariant={SizeVariant.small}>
                <Typo variant={TypoVariant.code}>{type}</Typo>
                <div className={classes.linkContainer}>
                  {!href && (
                    <StopClickPropagation>
                      <div
                        tabIndex={0}
                        className={classes.fakeAnchor}
                        onKeyDown={(event) => {
                          event.stopPropagation();
                          navigator.clipboard.writeText(value);
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText(value);
                        }}
                        role="button"
                      >
                        <Typo maxLine={1}>{i18n`Copy URL`}</Typo>
                        <div className={classes.iconContainer}><Icon name={IconName.link} /></div>
                      </div>
                    </StopClickPropagation>
                  )}
                  {href && (
                    <StopClickPropagation>
                      <Link
                        isUserControlled
                        title={i18n`Navigate to`}
                        to={href}
                        iconName={IconName.output}
                        maxLine={1}
                        openInNewTab
                      />
                    </StopClickPropagation>
                  )}
                </div>
              </SizeContextProvider>
            </div>
          ),
        },
      ] : []}
      getDropdownSectionDefinitions={readOnly ? undefined : () => [
        {
          id: 'main',
          lines: [
            {
              id: 'URL',
              title: i18n`URL`,
              render: (
                <SimpleInput
                  initialValue={value}
                  onSubmit={onChange}
                >
                  {(props) => (
                    <TextInputString
                      placeholder={i18n`Add URL`}
                      focusOnMount
                      maxLine={1}
                      {...props}
                    />
                  )}
                </SimpleInput>
              ),
            },
          ],
        },
      ]}
      readOnly={readOnly}
      headerHeight="5.2rem"
      onOpenDropdown={onOpenDropdown}
      onCloseDropdown={onCloseDropdown}
    />
  );
};

export default URLInput;
