import { newError } from './errorUtils';

export interface ResolvablePromise<T = unknown> {
  promise: Promise<T>,
  resolve: (value: T | PromiseLike<T>) => void,
  reject: (reason?: unknown) => void,
  reset: () => void,
  isSettled: () => boolean,
}

export const createResolvablePromise = <T>(): ResolvablePromise<T> => {
  const resolvablePromise: {
    promise?: Promise<T>,
    resolve?: (value: T | PromiseLike<T>) => void,
    reject?: (reason?: unknown) => void,
    isSettled?: () => boolean,
    reset: () => void,
  } = {
    reset: () => {
      let settled = false;
      resolvablePromise.isSettled = () => settled;
      resolvablePromise.promise = new Promise((resolve, reject) => {
        resolvablePromise.resolve = (value) => {
          if (settled) {
            throw newError('Promise has already been settled');
          }
          settled = true;
          resolve(value);
        };
        resolvablePromise.reject = (reason?: unknown) => {
          if (settled) {
            throw newError('Promise has already been settled');
          }
          settled = true;
          reject(reason);
        };
      });
    },
  };

  resolvablePromise.reset();

  return resolvablePromise as ResolvablePromise<T>;
};
