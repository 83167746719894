import { createValuePathResolver, FILTER_PARAMETER_LOGGED_USER, getFilterFunction, isSingleValueResolution } from 'yooi-modules/modules/conceptModule';
import type { HomepageStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import { Homepage, Homepage_DisplayConditions, Homepage_Path, Homepage_Rank } from 'yooi-modules/modules/platformConfigurationModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
// eslint-disable-next-line yooi/no-restricted-dependency
import { isStoreObject } from 'yooi-store';
import { compareProperty, compareRank } from 'yooi-utils';

export const getHomepageConcept = (store: ObjectStoreWithTimeseries, loggedUserId: string): StoreObject | undefined => {
  const pathResolver = createValuePathResolver(store, { [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: loggedUserId } });

  return store.getObject(Homepage)
    .navigateBack<HomepageStoreObject>(Class_Instances)
    .sort(compareProperty(Homepage_Rank, compareRank))
    .reduce<StoreObject | undefined>(
      (acc, homepage) => {
        if (acc !== undefined) {
          return acc;
        } else if (homepage[Homepage_Path] !== undefined) {
          const filterFunction = getFilterFunction(store, homepage[Homepage_DisplayConditions]);
          if (filterFunction === undefined || filterFunction({ [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: loggedUserId } })) {
            const resolvedValue = pathResolver.resolvePathValue(homepage[Homepage_Path]);
            if (isSingleValueResolution(resolvedValue) && isStoreObject(resolvedValue.value)) {
              return resolvedValue.value;
            } else {
              return undefined;
            }
          } else {
            return undefined;
          }
        } else {
          return undefined;
        }
      },
      undefined
    );
};
