import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { ComponentProps, CSSProperties, ReactNode } from 'react';
import { forwardRef } from 'react';
import base from '../../theme/base';
import { getSpacing, Spacing, spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeSelectorsClasses from '../../utils/makeSelectorsClasses';
import makeStyles from '../../utils/makeStyles';
import type { NavigationPayload } from '../../utils/useNavigation';
import useScrollOnMountRef from '../../utils/useScrollOnMountRef';
import { UsageContextProvider, UsageVariant } from '../../utils/useUsageContext';
import Button from '../atoms/Button';
import { IconName } from '../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../atoms/IconOnlyButton';
import InlineLink from './InlineLink';
import type { MenuItem } from './Menu';
import OverflowMenu from './OverflowMenu';
import SpacingLine from './SpacingLine';
import ToggleButton from './ToggleButton';

const selectorsClasses = makeSelectorsClasses('visibilityHandler');

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    '&:hover, &:focus, &:focus-within': {
      [`& .${selectorsClasses.visibilityHandler}`]: {
        visibility: 'visible',
      },
    },
    backgroundColor: theme.color.background.neutral.default,
    margin: '0.1rem',
  },
  shadowedCard: {
    boxShadow: base.shadowElevation.low,
  },
  shadowedCardHover: {
    '&:hover, &:focus': {
      boxShadow: base.shadowElevation.medium,
    },
  },
  shadowedMediumCard: {
    boxShadow: base.shadowElevation.medium,
  },
  shadowedMediumCardHover: {
    '&:hover, &:focus': {
      boxShadow: base.shadowElevation.high,
    },
  },
  floatingLine: {
    visibility: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
    marginRight: spacingRem.s,
    marginTop: spacingRem.xs,
  },
}), 'card');

interface CardProps {
  children: ReactNode,
  scrollOnMount?: boolean,
  actions?: ({
    type: 'button',
    key: string,
    iconName: ComponentProps<typeof IconOnlyButton>['iconName'],
    sizeVariant?: ComponentProps<typeof IconOnlyButton>['sizeVariant'],
    onClick: ComponentProps<typeof IconOnlyButton>['onClick'],
    tooltip: ComponentProps<typeof IconOnlyButton>['tooltip'],
  } | {
    type: 'toggle',
    key: string,
    icon: ComponentProps<typeof ToggleButton>['icon'],
    onClick: ComponentProps<typeof ToggleButton>['onClick'],
    tooltip: ComponentProps<typeof ToggleButton>['tooltip'],
    active: ComponentProps<typeof ToggleButton>['active'],
  })[],
  menuActions?: MenuItem[],
  getNavigationPayload?: () => (NavigationPayload | undefined),
  buttonExtraOffsetTop?: Spacing | Spacing[],
  noShadow?: boolean,
  noHoverShadow?: boolean,
  borderColor?: string,
}

const Card = forwardRef<HTMLDivElement, CardProps>(({
  children,
  actions,
  menuActions = [],
  getNavigationPayload,
  scrollOnMount = false,
  buttonExtraOffsetTop,
  noShadow = false,
  noHoverShadow = false,
  borderColor,
}, ref) => {
  const classes = useStyles();

  const scrollOnMountRef = useScrollOnMountRef(scrollOnMount);

  const renderOpenButton = () => {
    if (getNavigationPayload === undefined) {
      return null;
    } else {
      const navigationPayload = getNavigationPayload();
      if (navigationPayload === undefined) {
        return null;
      } else {
        return (
          <InlineLink noStyle {...navigationPayload}>
            <Button iconName={IconName.output} title={i18n`Open`} />
          </InlineLink>
        );
      }
    }
  };

  let marginTop: CSSProperties['marginTop'];
  if (Array.isArray(buttonExtraOffsetTop)) {
    if (buttonExtraOffsetTop.length > 0) {
      marginTop = getSpacing(Spacing.xs, ...buttonExtraOffsetTop);
    }
  } else if (buttonExtraOffsetTop !== undefined) {
    marginTop = getSpacing(Spacing.xs, buttonExtraOffsetTop);
  }

  return (
    <UsageContextProvider usageVariant={UsageVariant.inCard}>
      <div
        ref={composeReactRefs<HTMLDivElement>(scrollOnMountRef, ref)}
        className={classnames({
          [classes.card]: true,
          [classes.shadowedCard]: !noShadow && !borderColor,
          [classes.shadowedCardHover]: !noShadow && !borderColor && !noHoverShadow,
          [classes.shadowedMediumCard]: !noShadow && borderColor,
          [classes.shadowedMediumCardHover]: !noShadow && borderColor && !noHoverShadow,
        })}
        style={borderColor ? { outline: `0.1rem solid ${borderColor}` } : undefined}
      >
        {children}
        <div
          className={classnames(classes.floatingLine, selectorsClasses.visibilityHandler)}
          style={marginTop !== undefined ? { marginTop } : undefined}
        >
          <SpacingLine customColumnGap={getSpacing(Spacing.xs)}>
            {renderOpenButton()}
            {actions?.length ? actions.map((action) => {
              if (action.type === 'button') {
                return (
                  <IconOnlyButton
                    key={action.key}
                    variant={IconOnlyButtonVariants.secondary}
                    iconName={action.iconName}
                    onClick={action.onClick}
                    sizeVariant={action.sizeVariant}
                    tooltip={action.tooltip}
                  />
                );
              } else {
                return (
                  <ToggleButton
                    key={action.key}
                    icon={action.icon}
                    onClick={action.onClick}
                    tooltip={action.tooltip}
                    active={action.active}
                  />
                );
              }
            }) : null}
            {menuActions.length > 0 ? (
              <OverflowMenu menuItems={menuActions} />
            ) : null}
          </SpacingLine>
        </div>
      </div>
    </UsageContextProvider>
  );
});

export default Card;
