import { motion } from 'framer-motion';
import type { JssStyle } from 'jss';
import type { FunctionComponent } from 'react';
import { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';
import type { WithMandatoryField } from 'yooi-utils';
import { checkIsEmailValid, forgetAsyncPromise, getEmailDomain, joinObjects } from 'yooi-utils';
import logofull from '../../assets/images/logofull.svg';
import logosmall from '../../assets/images/logosmall.svg';
import { ButtonVariant } from '../../components/atoms/Button';
import Icon, { IconName } from '../../components/atoms/Icon';
import Typo, { TypoVariant } from '../../components/atoms/Typo';
import PasswordInput from '../../components/inputs/PasswordInput';
import RawInput, { RawInputType } from '../../components/inputs/RawInput';
import InlineLink from '../../components/molecules/InlineLink';
import SpacingLine from '../../components/molecules/SpacingLine';
import base from '../../theme/base';
import { spacingRem } from '../../theme/spacingDefinition';
import { doFetch, fetchJSON } from '../../utils/fetchUtils';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import type { SSOConfiguration } from '../../utils/options';
import { getPlatformBranding, getPlatformLogo, getSSOConfiguration } from '../../utils/options';
import useTheme from '../../utils/useTheme';
import LoginButton from './LoginButton';

const buildVariantStyles = <VariantName extends ButtonVariant>(
  { variantName, normal, hover, focus = {}, pressed = {} }: { variantName: VariantName, normal: JssStyle, hover: JssStyle, focus?: JssStyle, pressed?: JssStyle }
): Record<VariantName | `${VariantName}_hover` | `${VariantName}_focus` | `${VariantName}_pressed`, JssStyle> => {
  const style: JssStyle = joinObjects({ '&:hover': hover, '&:focus': focus, '&:active': pressed }, normal);

  return {
    [variantName]: style,
    [`${variantName}_hover`]: joinObjects(style, hover),
    [`${variantName}_focus`]: joinObjects(style, focus),
    [`${variantName}_pressed`]: joinObjects(style, pressed),
  } as Record<VariantName | `${VariantName}_hover` | `${VariantName}_focus` | `${VariantName}_pressed`, JssStyle>;
};

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    rowGap: spacingRem.l,
    minHeight: '100%',
    minWidth: '100%',
    backgroundColor: theme.color.background.neutral.subtle,
    overflowY: 'auto',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '5rem',
    marginTop: '15vh', // Using vh unit to have the same behavior as top 15%
  },
  brandingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: spacingRem.s,
  },
  logo: {
    marginBottom: spacingRem.s,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.s,
    width: '46rem',
    zIndex: 2,
    backgroundColor: theme.color.background.neutral.default,
    boxShadow: base.shadowElevation.low,
    borderRadius: base.borderRadius.medium,
    padding: spacingRem.xxl,
  },
  ...buildVariantStyles({
    variantName: ButtonVariant.primary,
    normal: {
      backgroundColor: theme.color.background.primary.default,
      color: theme.color.text.white,

      '&:disabled': {
        opacity: base.opacity.twenty,
      },
    },
    hover: {
      backgroundColor: theme.color.background.primary.hover,
    },
    focus: {
      backgroundColor: theme.color.background.primary.pressed,
    },
    pressed: {
      backgroundColor: theme.color.background.primary.pressed,
    },
  }),
  ...buildVariantStyles({
    variantName: ButtonVariant.tertiary,
    normal: {
      backgroundColor: theme.color.transparent,
      boxShadow: 'none',
      color: theme.color.text.brand,

      '&:disabled': {
        opacity: base.opacity.twenty,
      },
    },
    hover: {
      backgroundColor: theme.color.background.primarylight.default,
    },
    focus: {
      backgroundColor: theme.color.background.primarylight.hover,
    },
    pressed: {
      backgroundColor: theme.color.background.primarylight.hover,
    },
  }),
  ssoIconContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.color.transparent,
  },
  ssoIconImage: {
    height: '1.6rem',
    width: '1.6rem',
    verticalAlign: 'middle',
  },
  separatorContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '6rem',
    columnGap: spacingRem.splus,
  },
  greyBar: {
    flexGrow: 1,
    borderBottomWidth: '0.2rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
  },
  poweredByContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: spacingRem.s,
    marginBottom: spacingRem.l,
    alignItems: 'center',
    justifyContent: 'center',
    justifySelf: 'flex-end',
  },
  yooiLogo: {
    display: 'flex',
    height: '1.6rem',
  },
}), 'login');

const LoginState = {
  EnterEmail: 'EnterEmail',
  EnterPassword: 'EnterPassword',
};

interface LoginProps {
  onLoginSucceeded: () => void,
}

const Login: FunctionComponent<LoginProps> = ({ onLoginSucceeded }) => {
  const theme = useTheme();
  const classes = useStyles();

  const location = useLocation();

  const [loginState, setLoginState] = useState(LoginState.EnterEmail);
  const [email, setEmail] = useState('');
  const [canValidateEmail, setCanValidateEmail] = useState(false);
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [toggleIsSSO, setToggleIsSSO] = useState(true);

  const [error, setError] = useState(location.hash.startsWith('#ssoErr') ? i18n`Your email is not authorized` : undefined);

  const ssoConfiguration = getSSOConfiguration();

  const ssoWithButton = ssoConfiguration.filter((configuration): configuration is WithMandatoryField<SSOConfiguration, 'button'> => Boolean(configuration.button));
  const isEmailValid = checkIsEmailValid(email);
  const displayError = canValidateEmail && email && !isEmailValid ? i18n`Please enter a valid email address` : error;

  const authWithSSO = async (domain: string, loginEmail?: string) => {
    const url = `${location.pathname}${location.search}${location.hash}`;
    await doFetch('/logout');
    window.location.href = `/auth/sso/authenticate?domain=${encodeURIComponent(domain)}&redirect=${encodeURIComponent(url)}${loginEmail ? `&loginEmail=${encodeURIComponent(loginEmail)}` : ''}`;
  };

  const onContinue = async () => {
    setPassword('');
    const emailDomain = isEmailValid && getEmailDomain(email);
    const idpConf = emailDomain && ssoConfiguration.find(({ authorizedDomains }) => authorizedDomains.includes(emailDomain));
    if (idpConf) {
      await authWithSSO(idpConf.domain, email);
    } else {
      setLoginState(LoginState.EnterPassword);
    }
  };

  // We are calling plain REST services here, we need to use async await
  const onLocalLogin = async () => {
    setSubmitting(true);
    try {
      await doFetch('/logout');
      const { status, response } = await fetchJSON<{ status: 200, response: { status: 'success', id: string } | { status: 'invalid_credentials' } }>(
        '/auth/internal/authenticate',
        { method: 'POST', json: { email, password } }
      );
      if (status === 200 && response.status === 'success') {
        // Allow to log with a redirection on something else than react
        if (location.search) {
          const callbackUrl = new URLSearchParams(location.search).get('callbackUrl');
          if (callbackUrl) {
            window.open(callbackUrl, '_self', 'noopener');
          } else {
            onLoginSucceeded();
          }
        } else {
          onLoginSucceeded();
        }
      } else {
        const responseError = response.status === 'invalid_credentials' ? i18n`Your email or password is incorrect.` : i18n`An unexpected error occurred.`;
        setError(responseError);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const logo = getPlatformLogo();
  const logoSrc = logo !== undefined ? `/app/logo/${logo.revisionId}` : logosmall;
  const logoHeight = logo !== undefined ? logo.height : 50;
  const logoWidth = logo !== undefined ? logo.width : 50;

  const branding = getPlatformBranding();
  document.title = branding?.name ?? i18n`YOOI`;

  const welcome = {
    title: i18n`Welcome back to ${branding?.name ?? i18n`YOOI`}`,
    subTitle: branding !== undefined ? branding.motto : i18n`The catalyst for your data strategy`,
  };

  return (
    <div className={classes.pageContainer}>
      <div className={classes.loginContainer}>
        <motion.div
          initial={{ y: -10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ ease: 'easeOut', delay: 0.1, duration: 0.5 }}
          className={classes.brandingContainer}
        >
          <img className={classes.logo} src={logoSrc} alt={i18n`Application logo`} style={{ width: `${logoWidth / 10}rem`, height: `${logoHeight / 10}rem` }} />
          <Typo variant={TypoVariant.blockPrimaryTitle}>{welcome.title}</Typo>
          {welcome.subTitle !== undefined ? (<Typo color={theme.color.text.secondary}>{welcome.subTitle}</Typo>) : undefined}
        </motion.div>
        <motion.div
          className={classes.card}
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ ease: 'easeOut', duration: 0.5 }}
        >
          {(!toggleIsSSO || ssoWithButton.length === 0) ? (
            <>
              <SpacingLine>
                <Typo variant={TypoVariant.small} color={theme.color.text.secondary}>{i18n`Email`}</Typo>
              </SpacingLine>
              <RawInput
                name="email"
                type={RawInputType.text}
                value={email}
                onChange={(value) => {
                  setError(undefined);
                  setEmail(value);
                }}
                onCancel={(value) => setEmail(value ?? '')}
                onSubmit={(value) => setCanValidateEmail(!!value && value.length > 0)}
                onEnterKeyPressed={() => {
                  if (isEmailValid) {
                    onContinue();
                  }
                }}
                disabled={loginState !== LoginState.EnterEmail}
                action={loginState === LoginState.EnterEmail ? undefined : {
                  icon: IconName.edit,
                  tooltip: i18n`Edit email`,
                  onClick: () => {
                    setError(undefined);
                    setLoginState(LoginState.EnterEmail);
                  },
                }}
                focusOnMount
                fullWidth
              />

              {loginState !== LoginState.EnterEmail ? (
                <>
                  <SpacingLine>
                    <Typo variant={TypoVariant.small} color={theme.color.text.secondary}>{i18n`Password`}</Typo>
                  </SpacingLine>
                  <PasswordInput
                    name="password"
                    value={password}
                    onChange={(value) => {
                      setError(undefined);
                      setPassword(value);
                    }}
                    onCancel={(value) => setPassword(value ?? '')}
                    onEnterKeyPressed={forgetAsyncPromise(onLocalLogin)}
                    focusOnMount
                    fullWidth
                  />
                </>
              ) : undefined}

              <SpacingLine>
                <Typo variant={TypoVariant.small} color={theme.color.text.danger}>{displayError ?? ''}</Typo>
              </SpacingLine>

              <LoginButton
                title={loginState === LoginState.EnterPassword ? i18n`Login` : i18n`Continue`}
                variant={ButtonVariant.primary}
                disabled={!email || !isEmailValid || (loginState === LoginState.EnterPassword && !password) || submitting}
                onClick={forgetAsyncPromise(loginState === LoginState.EnterPassword ? onLocalLogin : onContinue)}
              />
            </>
          ) : undefined}

          {toggleIsSSO && ssoWithButton.length > 0 ? ssoWithButton.map(({ domain, button }) => (
            <Fragment key={domain}>
              <LoginButton
                title={button.label}
                variant={ButtonVariant.primary}
                onClick={forgetAsyncPromise(() => authWithSSO(domain))}
                renderIcon={() => (
                  <>
                    {button.iconURL && domain !== 'yooi.com' ? (
                      <div className={classes.ssoIconContainer}>
                        <img className={classes.ssoIconImage} src={button?.iconURL} alt={i18n`Icon`} />
                      </div>
                    ) : undefined}
                    {domain === 'yooi.com' ? (
                      <div className={classes.ssoIconContainer}>
                        <Icon name={IconName.yooi_logo_white} />
                      </div>
                    ) : undefined}
                  </>
                )}
              />
              {error ? (
                <SpacingLine>
                  <Typo variant={TypoVariant.small} color={theme.color.text.danger}>{i18n`An error occurred when login with SSO`}</Typo>
                </SpacingLine>
              ) : undefined}
            </Fragment>
          )) : undefined}
          {ssoWithButton.length > 0 ? (
            <div className={classes.separatorContainer}>
              <div className={classes.greyBar} />
              <Typo color={theme.color.text.disabled}>{i18n`OR`}</Typo>
              <div className={classes.greyBar} />
            </div>
          ) : undefined}
          {ssoWithButton.length > 0 && toggleIsSSO ? (
            <LoginButton
              title={i18n`Continue with your email`}
              variant={ButtonVariant.tertiary}
              onClick={() => setToggleIsSSO(false)}
            />
          ) : undefined}
          {!toggleIsSSO ? ssoWithButton.map(({ domain, button }) => (
            <LoginButton
              key={domain}
              title={button?.label}
              variant={ButtonVariant.tertiary}
              onClick={forgetAsyncPromise(() => authWithSSO(domain))}
            />
          )) : undefined}
        </motion.div>
      </div>
      {branding !== undefined || logo !== undefined ? (
        <span className={classes.poweredByContainer}>
          <Typo color={theme.color.text.secondary}>
            {i18n.jsx`Powered by`}
          </Typo>
          <InlineLink key="yooi" to="https://www.yooi.com/"><img className={classes.yooiLogo} src={logofull} alt={i18n`YOOI`} /></InlineLink>
        </span>
      ) : undefined}
    </div>
  );
};

export default Login;
