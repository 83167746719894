import type { ObjectStoreAssociation } from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import { compareRank, extractAndCompareValue, formatForStorage, joinObjects } from 'yooi-utils';
import { CommonAsType } from '../common/fields/commonPropertyType';
import type { BusinessRuleRegistration } from '../common/types/TypeModuleDslType';
import { PropertyMandatoryType } from '../common/types/TypeModuleDslType';
import { AnyElement, Concept, ConceptDefinition, ConceptRole, Group, User, Workflow, WorkflowTransition } from '../conceptModule/ids';
import { adminOnlyAcl, getViewerGroupsSet, isConceptValid } from '../conceptModule/utils';
import {
  Collaboration,
  Collaboration_Contexts,
  CollaborationGroups_Role_Collaboration,
  CollaborationGroups_Role_Group,
  CollaborationMessage_Collaboration,
  CollaborationMessage_CreatedBy,
  CollaborationOnMultipleContext,
  CollaborationOnMultipleContext_Rank,
  CollaborationOnMultipleContext_Role_Collaboration,
  CollaborationOnMultipleContext_VarRoles_Contexts,
  CollaborationRoles_Role_Collaboration,
  CollaborationUserInfo,
  CollaborationUserInfo_LastRead,
  CollaborationUserInfo_Role_Collaboration,
  CollaborationUserInfo_Role_User,
  CollaborationUsers_Role_Collaboration,
  CollaborationUsers_Role_User,
  Intention,
} from './ids';
import type { CollaborationMessageStoreObject, CollaborationOnMultipleContextStoreObject, CollaborationStoreObject } from './modelTypes';
import { registerModel } from './module';

const { type, association } = registerModel;

type({
  label: 'Collaboration',
  accessControlList: {
    READ: ({ getObjectOrNull }) => (_, objectId) => {
      const contextObjectAssociations = (
        getObjectOrNull<CollaborationStoreObject>(objectId[0])?.[Collaboration_Contexts] as ObjectStoreAssociation<CollaborationOnMultipleContextStoreObject>[] | undefined ?? []
      );

      if (contextObjectAssociations.length === 0) {
        return { rule: 'collaboration.read.noContext', status: ValidationStatus.REJECTED };
      } else {
        return {
          rule: 'collaboration.read.delegated',
          status: ValidationStatus.DELEGATED,
          targetId: [contextObjectAssociations[contextObjectAssociations.length - 1].role(CollaborationOnMultipleContext_VarRoles_Contexts)],
          targetAction: 'READ',
        };
      }
    },
    WRITE: ({ getObjectOrNull }) => (_, objectId) => {
      // if creation allow
      if (!getObjectOrNull(objectId)) {
        return { rule: 'collaboration.write.allowCreation', status: ValidationStatus.ACCEPTED };
      } else {
        return { rule: 'collaboration.write.delegate', status: ValidationStatus.DELEGATED, targetAction: 'READ', targetId: objectId };
      }
    },
    DELETE: () => (_, objectId) => ({ rule: 'collaboration.delete.delegateToWrite', status: ValidationStatus.DELEGATED, targetId: objectId, targetAction: 'WRITE' }),
  },
})
  .property({ label: 'Collaboration_Status', as: CommonAsType.string })
  .property({ label: 'Collaboration_CreatedAt', as: CommonAsType.number })
  .property({ label: 'Collaboration_UpdatedAt', as: CommonAsType.number })
  .property({ label: 'Collaboration_Path', as: CommonAsType.string })
  .property({ label: 'Collaboration_Hash', as: CommonAsType.string })
  .relation({ label: 'Collaboration_Intention', targetTypeId: Intention, reverseLabel: 'Intention_Collaborations' })
  .propertyFunction({
    label: 'Collaboration_Contexts',
    computeFunction: ({ withAssociation }) => (objectId) => (
      withAssociation(CollaborationOnMultipleContext)
        .withRole(CollaborationOnMultipleContext_Role_Collaboration, objectId[0])
        .list<CollaborationOnMultipleContextStoreObject>()
        .sort(extractAndCompareValue(({ object }) => object[CollaborationOnMultipleContext_Rank], compareRank))
    ),
  });

const applyCreatedBy: BusinessRuleRegistration = ({ getObjectOrNull }) => ({ userId }, { id, properties }, executionContext) => {
  // Deletion ? Don't care
  if (properties === null) {
    return undefined;
  }

  // Existing object ? Don't care
  const currentObject = getObjectOrNull(id);
  if (currentObject) {
    return undefined;
  }

  // No userId in origin ? Skip update
  if (!userId) {
    return undefined;
  }

  return {
    rule: 'collaboration.message.createdBy',
    status: ValidationStatus.ACCEPTED,
    generateSystemEvent: ({ updateObject, withAssociation }) => {
      updateObject(id, {
        [CollaborationMessage_CreatedBy]: userId,
      });
      withAssociation(CollaborationUserInfo)
        .withRole(CollaborationUserInfo_Role_Collaboration, properties?.[CollaborationMessage_Collaboration] as string)
        .withRole(CollaborationUserInfo_Role_User, userId)
        .updateObject({ [CollaborationUserInfo_LastRead]: formatForStorage(executionContext.date) });
    },
  };
};

type({
  label: 'CollaborationMessage',
  accessControlList: {
    READ: ({ getObjectOrNull }) => (_, objectId) => {
      const collaborationMessage = getObjectOrNull<CollaborationMessageStoreObject>(objectId[0]);
      if (collaborationMessage?.[CollaborationMessage_Collaboration]) {
        return {
          rule: 'collaborationMessage.read.delegate',
          status: ValidationStatus.DELEGATED,
          targetId: [collaborationMessage[CollaborationMessage_Collaboration]],
          targetAction: 'READ',
        };
      } else {
        return { rule: 'collaborationMessage.deletedCollaboration.rejectRead', status: ValidationStatus.REJECTED };
      }
    },
    WRITE: ({ getObjectOrNull }) => (_, objectId, { newProperties }) => {
      const collaborationMessage = getObjectOrNull(objectId) ?? newProperties;
      return {
        rule: 'collaborationMessage.write.delegate',
        status: ValidationStatus.DELEGATED,
        targetId: [collaborationMessage?.[CollaborationMessage_Collaboration] as string],
        targetAction: 'WRITE',
      };
    },
    DELETE: () => () => ({ rule: 'collaborationMessage.delete.forbidden', status: ValidationStatus.REJECTED }),
  },
  businessRules: [applyCreatedBy],
})
  .property({ label: 'CollaborationMessage_CreatedAt', as: CommonAsType.number })
  .property({ label: 'CollaborationMessage_UpdatedAt', as: CommonAsType.number })
  .property({ label: 'CollaborationMessage_Text', as: CommonAsType.string })
  .relation({ label: 'CollaborationMessage_CreatedBy', targetTypeId: User, reverseLabel: 'User_CollaborationMessages' })
  .relation({
    label: 'CollaborationMessage_Collaboration',
    targetTypeId: Collaboration,
    reverseLabel: 'Collaboration_Messages',
    mandatory: { type: PropertyMandatoryType.mandatory },
  });

association({
  label: 'CollaborationUsers',
  roles: [{ label: 'User', targetTypeId: User }, { label: 'Collaboration', targetTypeId: Collaboration }],
  accessControlList: {
    READ: () => () => ({ rule: 'collaborationUsers.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: ({ getObjectOrNull }) => (origin, objectId, _, readAcl) => {
      const addedUserCanSeeCollaboration = (
        readAcl('READ', objectId[CollaborationUsers_Role_Collaboration + 1], joinObjects(origin, { userId: objectId[CollaborationUsers_Role_User + 1] })) ?? false
      );

      if (addedUserCanSeeCollaboration) {
        return {
          rule: 'collaborationUsers.write.delegated',
          status: ValidationStatus.DELEGATED,
          targetId: objectId,
          targetAction: 'READ',
        };
      } else {
        const contextObjectAssociations = (
          getObjectOrNull<CollaborationStoreObject>(objectId[CollaborationUsers_Role_Collaboration + 1])
            ?.[Collaboration_Contexts] as ObjectStoreAssociation<CollaborationOnMultipleContextStoreObject>[] | undefined ?? []
        );

        if (contextObjectAssociations.length === 0) {
          return {
            rule: 'collaborationUsers.write.noContext',
            status: ValidationStatus.REJECTED,
          };
        }

        const contextId = [contextObjectAssociations[contextObjectAssociations.length - 1].role(CollaborationOnMultipleContext_VarRoles_Contexts)];
        return {
          rule: 'collaborationUsers.write.delegated',
          status: ValidationStatus.DELEGATED,
          targetId: contextId,
          targetAction: 'ASSIGN_COLLABORATOR',
        };
      }
    },
    DELETE: () => (_, objectId) => ({
      rule: 'collaborationUsers.write.delegated',
      status: ValidationStatus.DELEGATED,
      targetId: objectId,
      targetAction: 'READ',
    }),
  },
});

association({
  label: 'CollaborationGroups',
  roles: [{ label: 'Group', targetTypeId: Group }, { label: 'Collaboration', targetTypeId: Collaboration }],
  accessControlList: {
    READ: () => () => ({ rule: 'collaborationGroups.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => (_, objectId) => {
      const contextObjectAssociations = (
        store.getObjectOrNull<CollaborationStoreObject>(objectId[CollaborationGroups_Role_Collaboration + 1])
          ?.[Collaboration_Contexts] as ObjectStoreAssociation<CollaborationOnMultipleContextStoreObject>[] | undefined ?? []
      );

      if (contextObjectAssociations.length === 0) {
        return { rule: 'collaborationGroups.write.noContext', status: ValidationStatus.REJECTED };
      }

      const collaborationContextId = contextObjectAssociations[contextObjectAssociations.length - 1].role(CollaborationOnMultipleContext_VarRoles_Contexts);
      const collaborationContext = collaborationContextId ? store.getObjectOrNull(collaborationContextId) : undefined;
      if (!collaborationContext || !isConceptValid(store, collaborationContextId)) {
        return { rule: 'collaborationGroups.write.invalidCollaborationContext', status: ValidationStatus.REJECTED };
      } else if (getViewerGroupsSet(store, collaborationContext.id).has(objectId[CollaborationGroups_Role_Group + 1])) {
        return {
          rule: 'collaborationGroups.write.delegated',
          status: ValidationStatus.DELEGATED,
          targetId: objectId,
          targetAction: 'READ',
        };
      } else {
        return {
          rule: 'collaborationGroups.write.delegated',
          status: ValidationStatus.DELEGATED,
          targetId: [contextObjectAssociations[contextObjectAssociations.length - 1].role(CollaborationOnMultipleContext_VarRoles_Contexts)],
          targetAction: 'ASSIGN_COLLABORATOR',
        };
      }
    },
    DELETE: () => (_, objectId) => ({
      rule: 'collaborationGroups.write.delegated',
      status: ValidationStatus.DELEGATED,
      targetId: objectId,
      targetAction: 'ASSIGN_COLLABORATOR',
    }),
  },
});

association({
  label: 'CollaborationUserInfo',
  roles: [{ label: 'User', targetTypeId: User }, { label: 'Collaboration', targetTypeId: Collaboration }],
  accessControlList: {
    READ: () => () => ({ rule: 'collaborationUserInfo.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: () => ({ userId }, objectId) => {
      if (objectId[CollaborationUserInfo_Role_User + 1] === userId) {
        return { rule: 'collaborationUserInfo.write.selfUpdate.allow', status: ValidationStatus.ACCEPTED };
      }
      return { rule: 'collaborationUserInfo.write.forbidden', status: ValidationStatus.REJECTED };
    },
    DELETE: () => () => ({ rule: 'collaborationUserInfo.delete.forbidden', status: ValidationStatus.REJECTED }),
  },
})
  .property({ label: 'CollaborationUserInfo_LastRead', as: CommonAsType.number });

type({
  label: 'Intention',
  accessControlList: {
    READ: () => () => ({ rule: 'intention.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'intentionAction', 'WRITE')),
    DELETE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'intentionAction', 'DELETE')),
  },
})
  .property({ label: 'Intention_Name', as: CommonAsType.string })
  .property({ label: 'Intention_Rank', as: CommonAsType.string })
  .property({ label: 'Intention_Description', as: CommonAsType.string })
  .property({ label: 'Intention_Available', as: CommonAsType.string })
  .relation({
    label: 'Intention_Workflow', targetTypeId: Workflow, reverseLabel: 'Workflow_UseAsWorkflowInIntention',
  });

association({
  label: 'CollaborationOnMultipleContext',
  roles: [
    { label: 'Collaboration', targetTypeId: Collaboration },
  ],
  varRoles: { label: 'Contexts', targetTypeId: AnyElement },
  accessControlList: {
    READ: () => () => ({ rule: 'collaborationOnObjectAndPropertyContext.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: () => (_, objectId) => ({
      rule: 'collaborationOnObjectAndPropertyContext.write.delegate',
      status: ValidationStatus.DELEGATED,
      targetAction: 'READ',
      targetId: [objectId[CollaborationOnMultipleContext_VarRoles_Contexts + 1]],
    }),
    DELETE: () => () => ({ rule: 'collaborationOnObjectAndPropertyContext.delete.forbidden', status: ValidationStatus.REJECTED }),
  },
})
  .property({ label: 'CollaborationOnMultipleContext_Rank', as: CommonAsType.string, mandatory: { type: PropertyMandatoryType.mandatory } });

association({
  label: 'IntentionStatusEntries',
  roles: [{ label: 'Intention', targetTypeId: Intention }, { label: 'Entry', targetTypeId: Concept }],
  accessControlList: {
    READ: () => () => ({ rule: 'IntentionStatusEntries.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'intentionStatusEntries', 'WRITE')),
    DELETE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'intentionStatusEntries', 'DELETE')),
  },
}).property({ label: 'IntentionStatusIsClosedStatus', as: CommonAsType.string });

association({
  label: 'IntentionUsers',
  roles: [{ label: 'Intention', targetTypeId: Intention }, { label: 'User', targetTypeId: User }],
  accessControlList: {
    READ: () => () => ({ rule: 'intentionUsers.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'intentionUsers', 'WRITE')),
    DELETE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'intentionUsers', 'DELETE')),
  },
});

association({
  label: 'IntentionGroups',
  roles: [{ label: 'Intention', targetTypeId: Intention }, { label: 'Group', targetTypeId: Group }],
  accessControlList: {
    READ: () => () => ({ rule: 'intentionGroups.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'intentionGroups', 'WRITE')),
    DELETE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'intentionGroups', 'DELETE')),
  },
});

association({
  label: 'IntentionConcepts',
  roles: [{ label: 'Intention', targetTypeId: Intention }, { label: 'ConceptDefinition', targetTypeId: ConceptDefinition }],
  accessControlList: {
    READ: () => () => ({ rule: 'intentionConcept.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'intentionConcepts', 'WRITE')),
    DELETE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'intentionConcepts', 'DELETE')),
  },
});

association({
  label: 'ConceptIntentionUsers',
  roles: [{ label: 'Intention', targetTypeId: Intention }, { label: 'User', targetTypeId: User }, { label: 'Concept', targetTypeId: ConceptDefinition }],
  accessControlList: {
    READ: () => () => ({ rule: 'conceptIntentionUsers.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'conceptIntentionUsers', 'WRITE')),
    DELETE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'conceptIntentionUsers', 'DELETE')),
  },
});

association({
  label: 'ConceptIntentionGroups',
  roles: [{ label: 'Intention', targetTypeId: Intention }, { label: 'Group', targetTypeId: Group }, { label: 'Concept', targetTypeId: ConceptDefinition }],
  accessControlList: {
    READ: () => () => ({ rule: 'conceptIntentionGroups.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'conceptIntentionGroups', 'WRITE')),
    DELETE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'conceptIntentionGroups', 'DELETE')),
  },
});

association({
  label: 'ConceptIntentionRoles',
  roles: [{ label: 'Intention', targetTypeId: Intention }, { label: 'Role', targetTypeId: ConceptRole }, { label: 'Concept', targetTypeId: ConceptDefinition }],
  accessControlList: {
    READ: () => () => ({ rule: 'conceptIntentionRoles.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'conceptIntentionRoles', 'WRITE')),
    DELETE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'conceptIntentionRoles', 'DELETE')),
  },
});

association({
  label: 'ConceptIntentionTransitionGroups',
  roles: [{ label: 'Intention', targetTypeId: Intention }, { label: 'Concept', targetTypeId: ConceptDefinition }, { label: 'Transition', targetTypeId: WorkflowTransition }, { label: 'Group', targetTypeId: Group }],
  accessControlList: {
    READ: () => () => ({ rule: 'conceptIntentionTransitionGroups.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'conceptIntentionTransitionGroups', 'WRITE')),
    DELETE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'conceptIntentionTransitionGroups', 'DELETE')),
  },
});

association({
  label: 'ConceptIntentionTransitionRoles',
  roles: [{ label: 'Intention', targetTypeId: Intention }, { label: 'Concept', targetTypeId: ConceptDefinition }, { label: 'Transition', targetTypeId: WorkflowTransition }, { label: 'Role', targetTypeId: ConceptRole }],
  accessControlList: {
    READ: () => () => ({ rule: 'conceptIntentionTransitionRoles.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'conceptIntentionTransitionRoles', 'WRITE')),
    DELETE: (store) => ({ userId }) => (adminOnlyAcl(store, userId, 'conceptIntentionTransitionRoles', 'DELETE')),
  },
});

association({
  label: 'CollaborationRoles',
  roles: [{ label: 'Role', targetTypeId: ConceptRole }, { label: 'Collaboration', targetTypeId: Collaboration }],
  accessControlList: {
    READ: () => () => ({ rule: 'collaborationRoles.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => (_, objectId) => {
      const contextObjectAssociations = (
        store.getObjectOrNull<CollaborationStoreObject>(objectId[CollaborationRoles_Role_Collaboration + 1])
          ?.[Collaboration_Contexts] as ObjectStoreAssociation<CollaborationOnMultipleContextStoreObject>[] | undefined ?? []
      );

      if (contextObjectAssociations.length === 0) {
        return { rule: 'collaborationRoles.write.noContext', status: ValidationStatus.REJECTED };
      }

      const collaborationContextId = contextObjectAssociations[contextObjectAssociations.length - 1].role(CollaborationOnMultipleContext_VarRoles_Contexts);
      const collaborationContext = collaborationContextId ? store.getObjectOrNull(collaborationContextId) : undefined;
      if (!collaborationContext || !isConceptValid(store, collaborationContextId)) {
        return { rule: 'collaborationRoles.write.invalidCollaborationContext', status: ValidationStatus.REJECTED };
      } else {
        return {
          rule: 'collaborationRoles.write.delegated',
          status: ValidationStatus.DELEGATED,
          targetId: [contextObjectAssociations[contextObjectAssociations.length - 1].role(CollaborationOnMultipleContext_VarRoles_Contexts)],
          targetAction: 'ASSIGN_COLLABORATOR',
        };
      }
    },
    DELETE: () => (_, objectId) => ({
      rule: 'collaborationRoles.write.delegated',
      status: ValidationStatus.DELEGATED,
      targetId: objectId,
      targetAction: 'ASSIGN_COLLABORATOR',
    }),
  },
});
