export enum Icon {
  '10k' = '10k',
  '10k_outline' = '10k_outline',
  '10mp' = '10mp',
  '10mp_outline' = '10mp_outline',
  '11mp' = '11mp',
  '11mp_outline' = '11mp_outline',
  '123_' = '123',
  '12mp' = '12mp',
  '12mp_outline' = '12mp_outline',
  '13mp' = '13mp',
  '13mp_outline' = '13mp_outline',
  '14mp' = '14mp',
  '14mp_outline' = '14mp_outline',
  '15mp' = '15mp',
  '15mp_outline' = '15mp_outline',
  '16mp' = '16mp',
  '16mp_outline' = '16mp_outline',
  '17mp' = '17mp',
  '17mp_outline' = '17mp_outline',
  '18_up_rating' = '18_up_rating',
  '18_up_rating_outline' = '18_up_rating_outline',
  '18mp' = '18mp',
  '18mp_outline' = '18mp_outline',
  '19mp' = '19mp',
  '19mp_outline' = '19mp_outline',
  '1k' = '1k',
  '1k_outline' = '1k_outline',
  '1k_plus' = '1k_plus',
  '1k_plus_outline' = '1k_plus_outline',
  '1x_mobiledata' = '1x_mobiledata',
  '1x_mobiledata_badge' = '1x_mobiledata_badge',
  '1x_mobiledata_badge_outline' = '1x_mobiledata_badge_outline',
  '20mp' = '20mp',
  '20mp_outline' = '20mp_outline',
  '21mp' = '21mp',
  '21mp_outline' = '21mp_outline',
  '22mp' = '22mp',
  '22mp_outline' = '22mp_outline',
  '23mp' = '23mp',
  '23mp_outline' = '23mp_outline',
  '24mp' = '24mp',
  '24mp_outline' = '24mp_outline',
  '2d' = '2d',
  '2d_outline' = '2d_outline',
  '2k' = '2k',
  '2k_outline' = '2k_outline',
  '2k_plus' = '2k_plus',
  '2k_plus_outline' = '2k_plus_outline',
  '2mp' = '2mp',
  '2mp_outline' = '2mp_outline',
  '30fps' = '30fps',
  '30fps_select' = '30fps_select',
  '360_' = '360',
  '3d_rotation' = '3d_rotation',
  '3d_rotation_outline' = '3d_rotation_outline',
  '3g_mobiledata' = '3g_mobiledata',
  '3g_mobiledata_badge' = '3g_mobiledata_badge',
  '3g_mobiledata_badge_outline' = '3g_mobiledata_badge_outline',
  '3k' = '3k',
  '3k_outline' = '3k_outline',
  '3k_plus' = '3k_plus',
  '3k_plus_outline' = '3k_plus_outline',
  '3mp' = '3mp',
  '3mp_outline' = '3mp_outline',
  '3p' = '3p',
  '3p_outline' = '3p_outline',
  '4g_mobiledata' = '4g_mobiledata',
  '4g_mobiledata_badge' = '4g_mobiledata_badge',
  '4g_mobiledata_badge_outline' = '4g_mobiledata_badge_outline',
  '4g_plus_mobiledata' = '4g_plus_mobiledata',
  '4k' = '4k',
  '4k_outline' = '4k_outline',
  '4k_plus' = '4k_plus',
  '4k_plus_outline' = '4k_plus_outline',
  '4mp' = '4mp',
  '4mp_outline' = '4mp_outline',
  '50mp' = '50mp',
  '50mp_outline' = '50mp_outline',
  '5g' = '5g',
  '5g_mobiledata_badge' = '5g_mobiledata_badge',
  '5g_mobiledata_badge_outline' = '5g_mobiledata_badge_outline',
  '5k' = '5k',
  '5k_outline' = '5k_outline',
  '5k_plus' = '5k_plus',
  '5k_plus_outline' = '5k_plus_outline',
  '5mp' = '5mp',
  '5mp_outline' = '5mp_outline',
  '6_ft_apart' = '6_ft_apart',
  '60fps' = '60fps',
  '60fps_select' = '60fps_select',
  '6k' = '6k',
  '6k_outline' = '6k_outline',
  '6k_plus' = '6k_plus',
  '6k_plus_outline' = '6k_plus_outline',
  '6mp' = '6mp',
  '6mp_outline' = '6mp_outline',
  '7k' = '7k',
  '7k_outline' = '7k_outline',
  '7k_plus' = '7k_plus',
  '7k_plus_outline' = '7k_plus_outline',
  '7mp' = '7mp',
  '7mp_outline' = '7mp_outline',
  '8k' = '8k',
  '8k_outline' = '8k_outline',
  '8k_plus' = '8k_plus',
  '8k_plus_outline' = '8k_plus_outline',
  '8mp' = '8mp',
  '8mp_outline' = '8mp_outline',
  '9k' = '9k',
  '9k_outline' = '9k_outline',
  '9k_plus' = '9k_plus',
  '9k_plus_outline' = '9k_plus_outline',
  '9mp' = '9mp',
  '9mp_outline' = '9mp_outline',
  'abc' = 'abc',
  'ac_unit' = 'ac_unit',
  'accessibility' = 'accessibility',
  'accessibility_new' = 'accessibility_new',
  'accessible' = 'accessible',
  'accessible_forward' = 'accessible_forward',
  'account_balance' = 'account_balance',
  'account_balance_outline' = 'account_balance_outline',
  'account_balance_wallet' = 'account_balance_wallet',
  'account_balance_wallet_outline' = 'account_balance_wallet_outline',
  'account_box' = 'account_box',
  'account_box_outline' = 'account_box_outline',
  'account_child' = 'account_child',
  'account_child_outline' = 'account_child_outline',
  'account_child_invert' = 'account_child_invert',
  'account_circle' = 'account_circle',
  'account_circle_outline' = 'account_circle_outline',
  'account_circle_off' = 'account_circle_off',
  'account_circle_off_outline' = 'account_circle_off_outline',
  'account_tree' = 'account_tree',
  'account_tree_outline' = 'account_tree_outline',
  'action_key' = 'action_key',
  'action_key_outline' = 'action_key_outline',
  'activity_zone' = 'activity_zone',
  'activity_zone_outline' = 'activity_zone_outline',
  'acute' = 'acute',
  'acute_outline' = 'acute_outline',
  'ad' = 'ad',
  'ad_group' = 'ad_group',
  'ad_group_outline' = 'ad_group_outline',
  'ad_group_off' = 'ad_group_off',
  'ad_group_off_outline' = 'ad_group_off_outline',
  'ad_off' = 'ad_off',
  'ad_units' = 'ad_units',
  'ad_units_outline' = 'ad_units_outline',
  'adaptive_audio_mic' = 'adaptive_audio_mic',
  'adaptive_audio_mic_outline' = 'adaptive_audio_mic_outline',
  'adaptive_audio_mic_off' = 'adaptive_audio_mic_off',
  'adaptive_audio_mic_off_outline' = 'adaptive_audio_mic_off_outline',
  'adb' = 'adb',
  'add' = 'add',
  'add_a_photo' = 'add_a_photo',
  'add_a_photo_outline' = 'add_a_photo_outline',
  'add_ad' = 'add_ad',
  'add_ad_outline' = 'add_ad_outline',
  'add_alert' = 'add_alert',
  'add_alert_outline' = 'add_alert_outline',
  'add_box' = 'add_box',
  'add_box_outline' = 'add_box_outline',
  'add_business' = 'add_business',
  'add_business_outline' = 'add_business_outline',
  'add_call' = 'add_call',
  'add_call_outline' = 'add_call_outline',
  'add_card' = 'add_card',
  'add_card_outline' = 'add_card_outline',
  'add_chart' = 'add_chart',
  'add_chart_outline' = 'add_chart_outline',
  'add_circle' = 'add_circle',
  'add_circle_outline' = 'add_circle_outline',
  'add_comment' = 'add_comment',
  'add_comment_outline' = 'add_comment_outline',
  'add_diamond' = 'add_diamond',
  'add_diamond_outline' = 'add_diamond_outline',
  'add_home' = 'add_home',
  'add_home_outline' = 'add_home_outline',
  'add_home_work' = 'add_home_work',
  'add_home_work_outline' = 'add_home_work_outline',
  'add_link' = 'add_link',
  'add_location' = 'add_location',
  'add_location_outline' = 'add_location_outline',
  'add_location_alt' = 'add_location_alt',
  'add_location_alt_outline' = 'add_location_alt_outline',
  'add_moderator' = 'add_moderator',
  'add_moderator_outline' = 'add_moderator_outline',
  'add_notes' = 'add_notes',
  'add_notes_outline' = 'add_notes_outline',
  'add_photo_alternate' = 'add_photo_alternate',
  'add_photo_alternate_outline' = 'add_photo_alternate_outline',
  'add_reaction' = 'add_reaction',
  'add_reaction_outline' = 'add_reaction_outline',
  'add_road' = 'add_road',
  'add_shopping_cart' = 'add_shopping_cart',
  'add_task' = 'add_task',
  'add_to_drive' = 'add_to_drive',
  'add_to_home_screen' = 'add_to_home_screen',
  'add_to_home_screen_outline' = 'add_to_home_screen_outline',
  'add_to_queue' = 'add_to_queue',
  'add_to_queue_outline' = 'add_to_queue_outline',
  'add_triangle' = 'add_triangle',
  'add_triangle_outline' = 'add_triangle_outline',
  'adf_scanner' = 'adf_scanner',
  'adf_scanner_outline' = 'adf_scanner_outline',
  'adjust' = 'adjust',
  'adjust_outline' = 'adjust_outline',
  'admin_meds' = 'admin_meds',
  'admin_meds_outline' = 'admin_meds_outline',
  'admin_panel_settings' = 'admin_panel_settings',
  'admin_panel_settings_outline' = 'admin_panel_settings_outline',
  'ads_click' = 'ads_click',
  'agender' = 'agender',
  'agriculture' = 'agriculture',
  'agriculture_outline' = 'agriculture_outline',
  'air' = 'air',
  'air_freshener' = 'air_freshener',
  'air_freshener_outline' = 'air_freshener_outline',
  'air_purifier' = 'air_purifier',
  'air_purifier_outline' = 'air_purifier_outline',
  'air_purifier_gen' = 'air_purifier_gen',
  'air_purifier_gen_outline' = 'air_purifier_gen_outline',
  'airline_seat_flat' = 'airline_seat_flat',
  'airline_seat_flat_outline' = 'airline_seat_flat_outline',
  'airline_seat_flat_angled' = 'airline_seat_flat_angled',
  'airline_seat_flat_angled_outline' = 'airline_seat_flat_angled_outline',
  'airline_seat_individual_suite' = 'airline_seat_individual_suite',
  'airline_seat_individual_suite_outline' = 'airline_seat_individual_suite_outline',
  'airline_seat_legroom_extra' = 'airline_seat_legroom_extra',
  'airline_seat_legroom_normal' = 'airline_seat_legroom_normal',
  'airline_seat_legroom_reduced' = 'airline_seat_legroom_reduced',
  'airline_seat_recline_extra' = 'airline_seat_recline_extra',
  'airline_seat_recline_normal' = 'airline_seat_recline_normal',
  'airline_stops' = 'airline_stops',
  'airlines' = 'airlines',
  'airlines_outline' = 'airlines_outline',
  'airplane_ticket' = 'airplane_ticket',
  'airplane_ticket_outline' = 'airplane_ticket_outline',
  'airplanemode_active' = 'airplanemode_active',
  'airplanemode_inactive' = 'airplanemode_inactive',
  'airplay' = 'airplay',
  'airplay_outline' = 'airplay_outline',
  'airport_shuttle' = 'airport_shuttle',
  'airport_shuttle_outline' = 'airport_shuttle_outline',
  'airware' = 'airware',
  'alarm' = 'alarm',
  'alarm_outline' = 'alarm_outline',
  'alarm_add' = 'alarm_add',
  'alarm_add_outline' = 'alarm_add_outline',
  'alarm_off' = 'alarm_off',
  'alarm_off_outline' = 'alarm_off_outline',
  'alarm_on' = 'alarm_on',
  'alarm_on_outline' = 'alarm_on_outline',
  'alarm_smart_wake' = 'alarm_smart_wake',
  'alarm_smart_wake_outline' = 'alarm_smart_wake_outline',
  'album' = 'album',
  'album_outline' = 'album_outline',
  'align_center' = 'align_center',
  'align_end' = 'align_end',
  'align_flex_center' = 'align_flex_center',
  'align_flex_end' = 'align_flex_end',
  'align_flex_start' = 'align_flex_start',
  'align_horizontal_center' = 'align_horizontal_center',
  'align_horizontal_left' = 'align_horizontal_left',
  'align_horizontal_right' = 'align_horizontal_right',
  'align_items_stretch' = 'align_items_stretch',
  'align_justify_center' = 'align_justify_center',
  'align_justify_flex_end' = 'align_justify_flex_end',
  'align_justify_flex_start' = 'align_justify_flex_start',
  'align_justify_space_around' = 'align_justify_space_around',
  'align_justify_space_between' = 'align_justify_space_between',
  'align_justify_space_even' = 'align_justify_space_even',
  'align_justify_stretch' = 'align_justify_stretch',
  'align_self_stretch' = 'align_self_stretch',
  'align_space_around' = 'align_space_around',
  'align_space_between' = 'align_space_between',
  'align_space_even' = 'align_space_even',
  'align_start' = 'align_start',
  'align_stretch' = 'align_stretch',
  'align_vertical_bottom' = 'align_vertical_bottom',
  'align_vertical_center' = 'align_vertical_center',
  'align_vertical_top' = 'align_vertical_top',
  'all_inbox' = 'all_inbox',
  'all_inbox_outline' = 'all_inbox_outline',
  'all_inclusive' = 'all_inclusive',
  'all_match' = 'all_match',
  'all_match_outline' = 'all_match_outline',
  'all_out' = 'all_out',
  'all_out_outline' = 'all_out_outline',
  'allergies' = 'allergies',
  'allergies_outline' = 'allergies_outline',
  'allergy' = 'allergy',
  'alt_route' = 'alt_route',
  'alternate_email' = 'alternate_email',
  'altitude' = 'altitude',
  'altitude_outline' = 'altitude_outline',
  'ambient_screen' = 'ambient_screen',
  'ambient_screen_outline' = 'ambient_screen_outline',
  'ambulance' = 'ambulance',
  'ambulance_outline' = 'ambulance_outline',
  'amend' = 'amend',
  'amend_outline' = 'amend_outline',
  'amp_stories' = 'amp_stories',
  'amp_stories_outline' = 'amp_stories_outline',
  'analytics' = 'analytics',
  'analytics_outline' = 'analytics_outline',
  'anchor' = 'anchor',
  'android' = 'android',
  'animated_images' = 'animated_images',
  'animated_images_outline' = 'animated_images_outline',
  'animation' = 'animation',
  'aod' = 'aod',
  'aod_outline' = 'aod_outline',
  'aod_tablet' = 'aod_tablet',
  'aod_tablet_outline' = 'aod_tablet_outline',
  'aod_watch' = 'aod_watch',
  'aod_watch_outline' = 'aod_watch_outline',
  'apartment' = 'apartment',
  'api' = 'api',
  'apk_document' = 'apk_document',
  'apk_document_outline' = 'apk_document_outline',
  'apk_install' = 'apk_install',
  'apk_install_outline' = 'apk_install_outline',
  'app_badging' = 'app_badging',
  'app_badging_outline' = 'app_badging_outline',
  'app_blocking' = 'app_blocking',
  'app_blocking_outline' = 'app_blocking_outline',
  'app_promo' = 'app_promo',
  'app_promo_outline' = 'app_promo_outline',
  'app_registration' = 'app_registration',
  'app_registration_outline' = 'app_registration_outline',
  'app_shortcut' = 'app_shortcut',
  'app_shortcut_outline' = 'app_shortcut_outline',
  'apparel' = 'apparel',
  'apparel_outline' = 'apparel_outline',
  'approval' = 'approval',
  'approval_outline' = 'approval_outline',
  'approval_delegation' = 'approval_delegation',
  'approval_delegation_outline' = 'approval_delegation_outline',
  'apps' = 'apps',
  'apps_outage' = 'apps_outage',
  'aq' = 'aq',
  'aq_indoor' = 'aq_indoor',
  'aq_indoor_outline' = 'aq_indoor_outline',
  'ar_on_you' = 'ar_on_you',
  'ar_on_you_outline' = 'ar_on_you_outline',
  'ar_stickers' = 'ar_stickers',
  'ar_stickers_outline' = 'ar_stickers_outline',
  'architecture' = 'architecture',
  'archive' = 'archive',
  'archive_outline' = 'archive_outline',
  'area_chart' = 'area_chart',
  'area_chart_outline' = 'area_chart_outline',
  'arming_countdown' = 'arming_countdown',
  'arming_countdown_outline' = 'arming_countdown_outline',
  'arrow_and_edge' = 'arrow_and_edge',
  'arrow_back' = 'arrow_back',
  'arrow_back_ios' = 'arrow_back_ios',
  'arrow_back_ios_new' = 'arrow_back_ios_new',
  'arrow_circle_down' = 'arrow_circle_down',
  'arrow_circle_down_outline' = 'arrow_circle_down_outline',
  'arrow_circle_left' = 'arrow_circle_left',
  'arrow_circle_left_outline' = 'arrow_circle_left_outline',
  'arrow_circle_right' = 'arrow_circle_right',
  'arrow_circle_right_outline' = 'arrow_circle_right_outline',
  'arrow_circle_up' = 'arrow_circle_up',
  'arrow_circle_up_outline' = 'arrow_circle_up_outline',
  'arrow_cool_down' = 'arrow_cool_down',
  'arrow_downward' = 'arrow_downward',
  'arrow_downward_alt' = 'arrow_downward_alt',
  'arrow_drop_down' = 'arrow_drop_down',
  'arrow_drop_down_circle' = 'arrow_drop_down_circle',
  'arrow_drop_down_circle_outline' = 'arrow_drop_down_circle_outline',
  'arrow_drop_up' = 'arrow_drop_up',
  'arrow_forward' = 'arrow_forward',
  'arrow_forward_ios' = 'arrow_forward_ios',
  'arrow_insert' = 'arrow_insert',
  'arrow_left' = 'arrow_left',
  'arrow_left_alt' = 'arrow_left_alt',
  'arrow_or_edge' = 'arrow_or_edge',
  'arrow_outward' = 'arrow_outward',
  'arrow_range' = 'arrow_range',
  'arrow_right' = 'arrow_right',
  'arrow_right_alt' = 'arrow_right_alt',
  'arrow_selector_tool' = 'arrow_selector_tool',
  'arrow_selector_tool_outline' = 'arrow_selector_tool_outline',
  'arrow_split' = 'arrow_split',
  'arrow_top_left' = 'arrow_top_left',
  'arrow_top_right' = 'arrow_top_right',
  'arrow_upward' = 'arrow_upward',
  'arrow_upward_alt' = 'arrow_upward_alt',
  'arrow_warm_up' = 'arrow_warm_up',
  'arrows_more_down' = 'arrows_more_down',
  'arrows_more_up' = 'arrows_more_up',
  'arrows_outward' = 'arrows_outward',
  'art_track' = 'art_track',
  'art_track_outline' = 'art_track_outline',
  'article' = 'article',
  'article_outline' = 'article_outline',
  'article_shortcut' = 'article_shortcut',
  'article_shortcut_outline' = 'article_shortcut_outline',
  'artist' = 'artist',
  'artist_outline' = 'artist_outline',
  'aspect_ratio' = 'aspect_ratio',
  'aspect_ratio_outline' = 'aspect_ratio_outline',
  'assignment' = 'assignment',
  'assignment_outline' = 'assignment_outline',
  'assignment_add' = 'assignment_add',
  'assignment_add_outline' = 'assignment_add_outline',
  'assignment_ind' = 'assignment_ind',
  'assignment_ind_outline' = 'assignment_ind_outline',
  'assignment_late' = 'assignment_late',
  'assignment_late_outline' = 'assignment_late_outline',
  'assignment_return' = 'assignment_return',
  'assignment_return_outline' = 'assignment_return_outline',
  'assignment_returned' = 'assignment_returned',
  'assignment_returned_outline' = 'assignment_returned_outline',
  'assignment_turned_in' = 'assignment_turned_in',
  'assignment_turned_in_outline' = 'assignment_turned_in_outline',
  'assist_walker' = 'assist_walker',
  'assistant' = 'assistant',
  'assistant_outline' = 'assistant_outline',
  'assistant_device' = 'assistant_device',
  'assistant_device_outline' = 'assistant_device_outline',
  'assistant_direction' = 'assistant_direction',
  'assistant_direction_outline' = 'assistant_direction_outline',
  'assistant_navigation' = 'assistant_navigation',
  'assistant_navigation_outline' = 'assistant_navigation_outline',
  'assistant_on_hub' = 'assistant_on_hub',
  'assistant_on_hub_outline' = 'assistant_on_hub_outline',
  'assured_workload' = 'assured_workload',
  'assured_workload_outline' = 'assured_workload_outline',
  'asterisk' = 'asterisk',
  'astrophotography_auto' = 'astrophotography_auto',
  'astrophotography_off' = 'astrophotography_off',
  'atm' = 'atm',
  'atr' = 'atr',
  'attach_email' = 'attach_email',
  'attach_email_outline' = 'attach_email_outline',
  'attach_file' = 'attach_file',
  'attach_file_add' = 'attach_file_add',
  'attach_file_off' = 'attach_file_off',
  'attach_money' = 'attach_money',
  'attachment' = 'attachment',
  'attractions' = 'attractions',
  'attractions_outline' = 'attractions_outline',
  'attribution' = 'attribution',
  'attribution_outline' = 'attribution_outline',
  'audio_description' = 'audio_description',
  'audio_file' = 'audio_file',
  'audio_file_outline' = 'audio_file_outline',
  'audio_video_receiver' = 'audio_video_receiver',
  'audio_video_receiver_outline' = 'audio_video_receiver_outline',
  'auto_activity_zone' = 'auto_activity_zone',
  'auto_activity_zone_outline' = 'auto_activity_zone_outline',
  'auto_awesome' = 'auto_awesome',
  'auto_awesome_outline' = 'auto_awesome_outline',
  'auto_awesome_mosaic' = 'auto_awesome_mosaic',
  'auto_awesome_mosaic_outline' = 'auto_awesome_mosaic_outline',
  'auto_awesome_motion' = 'auto_awesome_motion',
  'auto_awesome_motion_outline' = 'auto_awesome_motion_outline',
  'auto_delete' = 'auto_delete',
  'auto_delete_outline' = 'auto_delete_outline',
  'auto_detect_voice' = 'auto_detect_voice',
  'auto_detect_voice_outline' = 'auto_detect_voice_outline',
  'auto_fix' = 'auto_fix',
  'auto_fix_outline' = 'auto_fix_outline',
  'auto_fix_normal' = 'auto_fix_normal',
  'auto_fix_normal_outline' = 'auto_fix_normal_outline',
  'auto_fix_off' = 'auto_fix_off',
  'auto_fix_off_outline' = 'auto_fix_off_outline',
  'auto_graph' = 'auto_graph',
  'auto_label' = 'auto_label',
  'auto_label_outline' = 'auto_label_outline',
  'auto_meeting_room' = 'auto_meeting_room',
  'auto_meeting_room_outline' = 'auto_meeting_room_outline',
  'auto_mode' = 'auto_mode',
  'auto_read_pause' = 'auto_read_pause',
  'auto_read_pause_outline' = 'auto_read_pause_outline',
  'auto_read_play' = 'auto_read_play',
  'auto_read_play_outline' = 'auto_read_play_outline',
  'auto_schedule' = 'auto_schedule',
  'auto_schedule_outline' = 'auto_schedule_outline',
  'auto_stories' = 'auto_stories',
  'auto_stories_outline' = 'auto_stories_outline',
  'auto_timer' = 'auto_timer',
  'auto_timer_outline' = 'auto_timer_outline',
  'auto_towing' = 'auto_towing',
  'auto_transmission' = 'auto_transmission',
  'auto_transmission_outline' = 'auto_transmission_outline',
  'auto_videocam' = 'auto_videocam',
  'auto_videocam_outline' = 'auto_videocam_outline',
  'autofps_select' = 'autofps_select',
  'autopause' = 'autopause',
  'autopay' = 'autopay',
  'autoplay' = 'autoplay',
  'autorenew' = 'autorenew',
  'autostop' = 'autostop',
  'av_timer' = 'av_timer',
  'av1' = 'av1',
  'av1_outline' = 'av1_outline',
  'avc' = 'avc',
  'avc_outline' = 'avc_outline',
  'avg_pace' = 'avg_pace',
  'avg_time' = 'avg_time',
  'avg_time_outline' = 'avg_time_outline',
  'award_star' = 'award_star',
  'award_star_outline' = 'award_star_outline',
  'azm' = 'azm',
  'azm_outline' = 'azm_outline',
  'baby_changing_station' = 'baby_changing_station',
  'back_hand' = 'back_hand',
  'back_hand_outline' = 'back_hand_outline',
  'back_to_tab' = 'back_to_tab',
  'background_blur_full' = 'background_blur_full',
  'background_blur_light' = 'background_blur_light',
  'background_dot_large' = 'background_dot_large',
  'background_dot_large_outline' = 'background_dot_large_outline',
  'background_dot_small' = 'background_dot_small',
  'background_dot_small_outline' = 'background_dot_small_outline',
  'background_grid_small' = 'background_grid_small',
  'background_grid_small_outline' = 'background_grid_small_outline',
  'background_replace' = 'background_replace',
  'background_replace_outline' = 'background_replace_outline',
  'backlight_high' = 'backlight_high',
  'backlight_high_off' = 'backlight_high_off',
  'backlight_low' = 'backlight_low',
  'backpack' = 'backpack',
  'backpack_outline' = 'backpack_outline',
  'backspace' = 'backspace',
  'backspace_outline' = 'backspace_outline',
  'backup' = 'backup',
  'backup_outline' = 'backup_outline',
  'backup_table' = 'backup_table',
  'badge' = 'badge',
  'badge_outline' = 'badge_outline',
  'bakery_dining' = 'bakery_dining',
  'bakery_dining_outline' = 'bakery_dining_outline',
  'balance' = 'balance',
  'balcony' = 'balcony',
  'ballot' = 'ballot',
  'ballot_outline' = 'ballot_outline',
  'bar_chart' = 'bar_chart',
  'bar_chart_4_bars' = 'bar_chart_4_bars',
  'barcode' = 'barcode',
  'barcode_reader' = 'barcode_reader',
  'barcode_reader_outline' = 'barcode_reader_outline',
  'barcode_scanner' = 'barcode_scanner',
  'barefoot' = 'barefoot',
  'batch_prediction' = 'batch_prediction',
  'bath_outdoor' = 'bath_outdoor',
  'bath_outdoor_outline' = 'bath_outdoor_outline',
  'bath_private' = 'bath_private',
  'bath_private_outline' = 'bath_private_outline',
  'bath_public_large' = 'bath_public_large',
  'bathroom' = 'bathroom',
  'bathroom_outline' = 'bathroom_outline',
  'bathtub' = 'bathtub',
  'bathtub_outline' = 'bathtub_outline',
  'battery_0_bar' = 'battery_0_bar',
  'battery_1_bar' = 'battery_1_bar',
  'battery_2_bar' = 'battery_2_bar',
  'battery_20' = 'battery_20',
  'battery_3_bar' = 'battery_3_bar',
  'battery_4_bar' = 'battery_4_bar',
  'battery_5_bar' = 'battery_5_bar',
  'battery_6_bar' = 'battery_6_bar',
  'battery_alert' = 'battery_alert',
  'battery_alert_outline' = 'battery_alert_outline',
  'battery_change' = 'battery_change',
  'battery_change_outline' = 'battery_change_outline',
  'battery_charging_20' = 'battery_charging_20',
  'battery_charging_30' = 'battery_charging_30',
  'battery_charging_50' = 'battery_charging_50',
  'battery_charging_60' = 'battery_charging_60',
  'battery_charging_80' = 'battery_charging_80',
  'battery_charging_90' = 'battery_charging_90',
  'battery_charging_full' = 'battery_charging_full',
  'battery_charging_full_outline' = 'battery_charging_full_outline',
  'battery_error' = 'battery_error',
  'battery_error_outline' = 'battery_error_outline',
  'battery_full' = 'battery_full',
  'battery_full_alt' = 'battery_full_alt',
  'battery_horiz_000' = 'battery_horiz_000',
  'battery_horiz_050' = 'battery_horiz_050',
  'battery_horiz_075' = 'battery_horiz_075',
  'battery_low' = 'battery_low',
  'battery_plus' = 'battery_plus',
  'battery_plus_outline' = 'battery_plus_outline',
  'battery_profile' = 'battery_profile',
  'battery_profile_outline' = 'battery_profile_outline',
  'battery_share' = 'battery_share',
  'battery_status_good' = 'battery_status_good',
  'battery_status_good_outline' = 'battery_status_good_outline',
  'battery_unknown' = 'battery_unknown',
  'battery_unknown_outline' = 'battery_unknown_outline',
  'battery_vert_005' = 'battery_vert_005',
  'battery_vert_020' = 'battery_vert_020',
  'battery_vert_050' = 'battery_vert_050',
  'battery_very_low' = 'battery_very_low',
  'beach_access' = 'beach_access',
  'beach_access_outline' = 'beach_access_outline',
  'bed' = 'bed',
  'bed_outline' = 'bed_outline',
  'bedroom_baby' = 'bedroom_baby',
  'bedroom_baby_outline' = 'bedroom_baby_outline',
  'bedroom_child' = 'bedroom_child',
  'bedroom_child_outline' = 'bedroom_child_outline',
  'bedroom_parent' = 'bedroom_parent',
  'bedroom_parent_outline' = 'bedroom_parent_outline',
  'bedtime' = 'bedtime',
  'bedtime_outline' = 'bedtime_outline',
  'bedtime_off' = 'bedtime_off',
  'bedtime_off_outline' = 'bedtime_off_outline',
  'beenhere' = 'beenhere',
  'beenhere_outline' = 'beenhere_outline',
  'bento' = 'bento',
  'bento_outline' = 'bento_outline',
  'bia' = 'bia',
  'bia_outline' = 'bia_outline',
  'bid_landscape' = 'bid_landscape',
  'bid_landscape_outline' = 'bid_landscape_outline',
  'bid_landscape_disabled' = 'bid_landscape_disabled',
  'bid_landscape_disabled_outline' = 'bid_landscape_disabled_outline',
  'bigtop_updates' = 'bigtop_updates',
  'bike_scooter' = 'bike_scooter',
  'bike_scooter_outline' = 'bike_scooter_outline',
  'biotech' = 'biotech',
  'biotech_outline' = 'biotech_outline',
  'blanket' = 'blanket',
  'blanket_outline' = 'blanket_outline',
  'blender' = 'blender',
  'blender_outline' = 'blender_outline',
  'blind' = 'blind',
  'blinds' = 'blinds',
  'blinds_closed' = 'blinds_closed',
  'block' = 'block',
  'blood_pressure' = 'blood_pressure',
  'blood_pressure_outline' = 'blood_pressure_outline',
  'bloodtype' = 'bloodtype',
  'bloodtype_outline' = 'bloodtype_outline',
  'bluetooth' = 'bluetooth',
  'bluetooth_connected' = 'bluetooth_connected',
  'bluetooth_disabled' = 'bluetooth_disabled',
  'bluetooth_drive' = 'bluetooth_drive',
  'bluetooth_drive_outline' = 'bluetooth_drive_outline',
  'bluetooth_searching' = 'bluetooth_searching',
  'blur_circular' = 'blur_circular',
  'blur_circular_outline' = 'blur_circular_outline',
  'blur_linear' = 'blur_linear',
  'blur_linear_outline' = 'blur_linear_outline',
  'blur_medium' = 'blur_medium',
  'blur_off' = 'blur_off',
  'blur_on' = 'blur_on',
  'blur_short' = 'blur_short',
  'body_fat' = 'body_fat',
  'body_system' = 'body_system',
  'bolt' = 'bolt',
  'bolt_outline' = 'bolt_outline',
  'bomb' = 'bomb',
  'bomb_outline' = 'bomb_outline',
  'book' = 'book',
  'book_outline' = 'book_outline',
  'book_2' = 'book_2',
  'book_2_outline' = 'book_2_outline',
  'book_3' = 'book_3',
  'book_3_outline' = 'book_3_outline',
  'book_4' = 'book_4',
  'book_4_outline' = 'book_4_outline',
  'book_5' = 'book_5',
  'book_5_outline' = 'book_5_outline',
  'book_online' = 'book_online',
  'book_online_outline' = 'book_online_outline',
  'bookmark' = 'bookmark',
  'bookmark_outline' = 'bookmark_outline',
  'bookmark_add' = 'bookmark_add',
  'bookmark_add_outline' = 'bookmark_add_outline',
  'bookmark_added' = 'bookmark_added',
  'bookmark_added_outline' = 'bookmark_added_outline',
  'bookmark_manager' = 'bookmark_manager',
  'bookmark_manager_outline' = 'bookmark_manager_outline',
  'bookmark_remove' = 'bookmark_remove',
  'bookmark_remove_outline' = 'bookmark_remove_outline',
  'bookmarks' = 'bookmarks',
  'bookmarks_outline' = 'bookmarks_outline',
  'border_all' = 'border_all',
  'border_all_outline' = 'border_all_outline',
  'border_bottom' = 'border_bottom',
  'border_clear' = 'border_clear',
  'border_color' = 'border_color',
  'border_color_outline' = 'border_color_outline',
  'border_horizontal' = 'border_horizontal',
  'border_inner' = 'border_inner',
  'border_left' = 'border_left',
  'border_outer' = 'border_outer',
  'border_right' = 'border_right',
  'border_style' = 'border_style',
  'border_top' = 'border_top',
  'border_vertical' = 'border_vertical',
  'bottom_app_bar' = 'bottom_app_bar',
  'bottom_app_bar_outline' = 'bottom_app_bar_outline',
  'bottom_drawer' = 'bottom_drawer',
  'bottom_drawer_outline' = 'bottom_drawer_outline',
  'bottom_navigation' = 'bottom_navigation',
  'bottom_navigation_outline' = 'bottom_navigation_outline',
  'bottom_panel_close' = 'bottom_panel_close',
  'bottom_panel_close_outline' = 'bottom_panel_close_outline',
  'bottom_panel_open' = 'bottom_panel_open',
  'bottom_panel_open_outline' = 'bottom_panel_open_outline',
  'bottom_right_click' = 'bottom_right_click',
  'bottom_sheets' = 'bottom_sheets',
  'bottom_sheets_outline' = 'bottom_sheets_outline',
  'box' = 'box',
  'box_outline' = 'box_outline',
  'box_add' = 'box_add',
  'box_add_outline' = 'box_add_outline',
  'box_edit' = 'box_edit',
  'box_edit_outline' = 'box_edit_outline',
  'boy' = 'boy',
  'brand_awareness' = 'brand_awareness',
  'brand_awareness_outline' = 'brand_awareness_outline',
  'brand_family' = 'brand_family',
  'branding_watermark' = 'branding_watermark',
  'branding_watermark_outline' = 'branding_watermark_outline',
  'breakfast_dining' = 'breakfast_dining',
  'breakfast_dining_outline' = 'breakfast_dining_outline',
  'breaking_news' = 'breaking_news',
  'breaking_news_outline' = 'breaking_news_outline',
  'breaking_news_alt_1' = 'breaking_news_alt_1',
  'breaking_news_alt_1_outline' = 'breaking_news_alt_1_outline',
  'breastfeeding' = 'breastfeeding',
  'brightness_3' = 'brightness_3',
  'brightness_3_outline' = 'brightness_3_outline',
  'brightness_4' = 'brightness_4',
  'brightness_4_outline' = 'brightness_4_outline',
  'brightness_alert' = 'brightness_alert',
  'brightness_alert_outline' = 'brightness_alert_outline',
  'brightness_auto' = 'brightness_auto',
  'brightness_auto_outline' = 'brightness_auto_outline',
  'brightness_empty' = 'brightness_empty',
  'brightness_empty_outline' = 'brightness_empty_outline',
  'brightness_high' = 'brightness_high',
  'brightness_high_outline' = 'brightness_high_outline',
  'brightness_low' = 'brightness_low',
  'brightness_low_outline' = 'brightness_low_outline',
  'brightness_medium' = 'brightness_medium',
  'brightness_medium_outline' = 'brightness_medium_outline',
  'bring_your_own_ip' = 'bring_your_own_ip',
  'broadcast_on_home' = 'broadcast_on_home',
  'broadcast_on_home_outline' = 'broadcast_on_home_outline',
  'broadcast_on_personal' = 'broadcast_on_personal',
  'broadcast_on_personal_outline' = 'broadcast_on_personal_outline',
  'broken_image' = 'broken_image',
  'broken_image_outline' = 'broken_image_outline',
  'browse' = 'browse',
  'browse_outline' = 'browse_outline',
  'browse_activity' = 'browse_activity',
  'browse_activity_outline' = 'browse_activity_outline',
  'browse_gallery' = 'browse_gallery',
  'browse_gallery_outline' = 'browse_gallery_outline',
  'browser_updated' = 'browser_updated',
  'brunch_dining' = 'brunch_dining',
  'brunch_dining_outline' = 'brunch_dining_outline',
  'brush' = 'brush',
  'brush_outline' = 'brush_outline',
  'bubble' = 'bubble',
  'bubble_outline' = 'bubble_outline',
  'bubble_chart' = 'bubble_chart',
  'bubble_chart_outline' = 'bubble_chart_outline',
  'bubbles' = 'bubbles',
  'bug_report' = 'bug_report',
  'bug_report_outline' = 'bug_report_outline',
  'build' = 'build',
  'build_outline' = 'build_outline',
  'build_circle' = 'build_circle',
  'build_circle_outline' = 'build_circle_outline',
  'bungalow' = 'bungalow',
  'bungalow_outline' = 'bungalow_outline',
  'burst_mode' = 'burst_mode',
  'burst_mode_outline' = 'burst_mode_outline',
  'bus_alert' = 'bus_alert',
  'bus_alert_outline' = 'bus_alert_outline',
  'business_center' = 'business_center',
  'business_center_outline' = 'business_center_outline',
  'business_chip' = 'business_chip',
  'business_chip_outline' = 'business_chip_outline',
  'business_messages' = 'business_messages',
  'business_messages_outline' = 'business_messages_outline',
  'buttons_alt' = 'buttons_alt',
  'buttons_alt_outline' = 'buttons_alt_outline',
  'cabin' = 'cabin',
  'cable' = 'cable',
  'cached' = 'cached',
  'cadence' = 'cadence',
  'cake' = 'cake',
  'cake_outline' = 'cake_outline',
  'cake_add' = 'cake_add',
  'cake_add_outline' = 'cake_add_outline',
  'calculate' = 'calculate',
  'calculate_outline' = 'calculate_outline',
  'calendar_add_on' = 'calendar_add_on',
  'calendar_add_on_outline' = 'calendar_add_on_outline',
  'calendar_apps_script' = 'calendar_apps_script',
  'calendar_apps_script_outline' = 'calendar_apps_script_outline',
  'calendar_clock' = 'calendar_clock',
  'calendar_clock_outline' = 'calendar_clock_outline',
  'calendar_month' = 'calendar_month',
  'calendar_month_outline' = 'calendar_month_outline',
  'calendar_today' = 'calendar_today',
  'calendar_today_outline' = 'calendar_today_outline',
  'calendar_view_day' = 'calendar_view_day',
  'calendar_view_day_outline' = 'calendar_view_day_outline',
  'calendar_view_month' = 'calendar_view_month',
  'calendar_view_month_outline' = 'calendar_view_month_outline',
  'calendar_view_week' = 'calendar_view_week',
  'calendar_view_week_outline' = 'calendar_view_week_outline',
  'call' = 'call',
  'call_outline' = 'call_outline',
  'call_end' = 'call_end',
  'call_end_outline' = 'call_end_outline',
  'call_log' = 'call_log',
  'call_log_outline' = 'call_log_outline',
  'call_merge' = 'call_merge',
  'call_missed' = 'call_missed',
  'call_missed_outgoing' = 'call_missed_outgoing',
  'call_quality' = 'call_quality',
  'call_quality_outline' = 'call_quality_outline',
  'call_split' = 'call_split',
  'call_to_action' = 'call_to_action',
  'call_to_action_outline' = 'call_to_action_outline',
  'camera' = 'camera',
  'camera_outline' = 'camera_outline',
  'camera_enhance' = 'camera_enhance',
  'camera_enhance_outline' = 'camera_enhance_outline',
  'camera_front' = 'camera_front',
  'camera_front_outline' = 'camera_front_outline',
  'camera_indoor' = 'camera_indoor',
  'camera_indoor_outline' = 'camera_indoor_outline',
  'camera_outdoor' = 'camera_outdoor',
  'camera_outdoor_outline' = 'camera_outdoor_outline',
  'camera_rear' = 'camera_rear',
  'camera_rear_outline' = 'camera_rear_outline',
  'camera_roll' = 'camera_roll',
  'camera_roll_outline' = 'camera_roll_outline',
  'camera_video' = 'camera_video',
  'camera_video_outline' = 'camera_video_outline',
  'cameraswitch' = 'cameraswitch',
  'cameraswitch_outline' = 'cameraswitch_outline',
  'campaign' = 'campaign',
  'campaign_outline' = 'campaign_outline',
  'camping' = 'camping',
  'camping_outline' = 'camping_outline',
  'cancel' = 'cancel',
  'cancel_outline' = 'cancel_outline',
  'cancel_presentation' = 'cancel_presentation',
  'cancel_presentation_outline' = 'cancel_presentation_outline',
  'cancel_schedule_send' = 'cancel_schedule_send',
  'cancel_schedule_send_outline' = 'cancel_schedule_send_outline',
  'candle' = 'candle',
  'candle_outline' = 'candle_outline',
  'candlestick_chart' = 'candlestick_chart',
  'candlestick_chart_outline' = 'candlestick_chart_outline',
  'captive_portal' = 'captive_portal',
  'capture' = 'capture',
  'capture_outline' = 'capture_outline',
  'car_crash' = 'car_crash',
  'car_crash_outline' = 'car_crash_outline',
  'car_rental' = 'car_rental',
  'car_rental_outline' = 'car_rental_outline',
  'car_repair' = 'car_repair',
  'car_repair_outline' = 'car_repair_outline',
  'car_tag' = 'car_tag',
  'car_tag_outline' = 'car_tag_outline',
  'card_membership' = 'card_membership',
  'card_membership_outline' = 'card_membership_outline',
  'card_travel' = 'card_travel',
  'card_travel_outline' = 'card_travel_outline',
  'cardio_load' = 'cardio_load',
  'cardio_load_outline' = 'cardio_load_outline',
  'cardiology' = 'cardiology',
  'cardiology_outline' = 'cardiology_outline',
  'cards' = 'cards',
  'cards_outline' = 'cards_outline',
  'carpenter' = 'carpenter',
  'carpenter_outline' = 'carpenter_outline',
  'carry_on_bag' = 'carry_on_bag',
  'carry_on_bag_outline' = 'carry_on_bag_outline',
  'carry_on_bag_checked' = 'carry_on_bag_checked',
  'carry_on_bag_checked_outline' = 'carry_on_bag_checked_outline',
  'carry_on_bag_inactive' = 'carry_on_bag_inactive',
  'carry_on_bag_inactive_outline' = 'carry_on_bag_inactive_outline',
  'carry_on_bag_question' = 'carry_on_bag_question',
  'carry_on_bag_question_outline' = 'carry_on_bag_question_outline',
  'cases' = 'cases',
  'cases_outline' = 'cases_outline',
  'casino' = 'casino',
  'casino_outline' = 'casino_outline',
  'cast' = 'cast',
  'cast_outline' = 'cast_outline',
  'cast_connected' = 'cast_connected',
  'cast_connected_outline' = 'cast_connected_outline',
  'cast_for_education' = 'cast_for_education',
  'cast_for_education_outline' = 'cast_for_education_outline',
  'cast_pause' = 'cast_pause',
  'cast_pause_outline' = 'cast_pause_outline',
  'cast_warning' = 'cast_warning',
  'cast_warning_outline' = 'cast_warning_outline',
  'castle' = 'castle',
  'castle_outline' = 'castle_outline',
  'category' = 'category',
  'category_outline' = 'category_outline',
  'celebration' = 'celebration',
  'celebration_outline' = 'celebration_outline',
  'cell_merge' = 'cell_merge',
  'cell_tower' = 'cell_tower',
  'cell_wifi' = 'cell_wifi',
  'center_focus_strong' = 'center_focus_strong',
  'center_focus_strong_outline' = 'center_focus_strong_outline',
  'center_focus_weak' = 'center_focus_weak',
  'center_focus_weak_outline' = 'center_focus_weak_outline',
  'chair' = 'chair',
  'chair_outline' = 'chair_outline',
  'chair_alt' = 'chair_alt',
  'chair_alt_outline' = 'chair_alt_outline',
  'chalet' = 'chalet',
  'chalet_outline' = 'chalet_outline',
  'change_circle' = 'change_circle',
  'change_circle_outline' = 'change_circle_outline',
  'change_history' = 'change_history',
  'change_history_outline' = 'change_history_outline',
  'charger' = 'charger',
  'charger_outline' = 'charger_outline',
  'charging_station' = 'charging_station',
  'charging_station_outline' = 'charging_station_outline',
  'chart_data' = 'chart_data',
  'chart_data_outline' = 'chart_data_outline',
  'chat' = 'chat',
  'chat_outline' = 'chat_outline',
  'chat_add_on' = 'chat_add_on',
  'chat_add_on_outline' = 'chat_add_on_outline',
  'chat_apps_script' = 'chat_apps_script',
  'chat_apps_script_outline' = 'chat_apps_script_outline',
  'chat_bubble' = 'chat_bubble',
  'chat_bubble_outline' = 'chat_bubble_outline',
  'chat_error' = 'chat_error',
  'chat_error_outline' = 'chat_error_outline',
  'chat_info' = 'chat_info',
  'chat_info_outline' = 'chat_info_outline',
  'chat_paste_go' = 'chat_paste_go',
  'chat_paste_go_outline' = 'chat_paste_go_outline',
  'check' = 'check',
  'check_box' = 'check_box',
  'check_box_outline' = 'check_box_outline',
  'check_box_outline_blank' = 'check_box_outline_blank',
  'check_circle' = 'check_circle',
  'check_circle_outline' = 'check_circle_outline',
  'check_in_out' = 'check_in_out',
  'check_indeterminate_small' = 'check_indeterminate_small',
  'check_small' = 'check_small',
  'checkbook' = 'checkbook',
  'checkbook_outline' = 'checkbook_outline',
  'checked_bag' = 'checked_bag',
  'checked_bag_outline' = 'checked_bag_outline',
  'checked_bag_question' = 'checked_bag_question',
  'checked_bag_question_outline' = 'checked_bag_question_outline',
  'checklist' = 'checklist',
  'checklist_rtl' = 'checklist_rtl',
  'checkroom' = 'checkroom',
  'cheer' = 'cheer',
  'cheer_outline' = 'cheer_outline',
  'chess' = 'chess',
  'chess_outline' = 'chess_outline',
  'child_care' = 'child_care',
  'child_care_outline' = 'child_care_outline',
  'child_friendly' = 'child_friendly',
  'child_friendly_outline' = 'child_friendly_outline',
  'chip_extraction' = 'chip_extraction',
  'chips' = 'chips',
  'chips_outline' = 'chips_outline',
  'chrome_maximize' = 'chrome_maximize',
  'chrome_maximize_outline' = 'chrome_maximize_outline',
  'chrome_minimize' = 'chrome_minimize',
  'chrome_reader_mode' = 'chrome_reader_mode',
  'chrome_reader_mode_outline' = 'chrome_reader_mode_outline',
  'chrome_restore' = 'chrome_restore',
  'chrome_restore_outline' = 'chrome_restore_outline',
  'chrome_tote' = 'chrome_tote',
  'chrome_tote_outline' = 'chrome_tote_outline',
  'chromecast_2' = 'chromecast_2',
  'chromecast_device' = 'chromecast_device',
  'chromecast_device_outline' = 'chromecast_device_outline',
  'chronic' = 'chronic',
  'chronic_outline' = 'chronic_outline',
  'church' = 'church',
  'church_outline' = 'church_outline',
  'cinematic_blur' = 'cinematic_blur',
  'cinematic_blur_outline' = 'cinematic_blur_outline',
  'circle' = 'circle',
  'circle_outline' = 'circle_outline',
  'circle_notifications' = 'circle_notifications',
  'circle_notifications_outline' = 'circle_notifications_outline',
  'circles' = 'circles',
  'circles_outline' = 'circles_outline',
  'circles_ext' = 'circles_ext',
  'circles_ext_outline' = 'circles_ext_outline',
  'clarify' = 'clarify',
  'clarify_outline' = 'clarify_outline',
  'clean_hands' = 'clean_hands',
  'clean_hands_outline' = 'clean_hands_outline',
  'cleaning' = 'cleaning',
  'cleaning_outline' = 'cleaning_outline',
  'cleaning_bucket' = 'cleaning_bucket',
  'cleaning_bucket_outline' = 'cleaning_bucket_outline',
  'cleaning_services' = 'cleaning_services',
  'cleaning_services_outline' = 'cleaning_services_outline',
  'clear_all' = 'clear_all',
  'clear_night' = 'clear_night',
  'clear_night_outline' = 'clear_night_outline',
  'climate_mini_split' = 'climate_mini_split',
  'climate_mini_split_outline' = 'climate_mini_split_outline',
  'clinical_notes' = 'clinical_notes',
  'clinical_notes_outline' = 'clinical_notes_outline',
  'clock_loader_10' = 'clock_loader_10',
  'clock_loader_20' = 'clock_loader_20',
  'clock_loader_40' = 'clock_loader_40',
  'clock_loader_60' = 'clock_loader_60',
  'clock_loader_80' = 'clock_loader_80',
  'clock_loader_90' = 'clock_loader_90',
  'close' = 'close',
  'close_fullscreen' = 'close_fullscreen',
  'close_small' = 'close_small',
  'close_small_outline' = 'close_small_outline',
  'closed_caption' = 'closed_caption',
  'closed_caption_outline' = 'closed_caption_outline',
  'closed_caption_add' = 'closed_caption_add',
  'closed_caption_add_outline' = 'closed_caption_add_outline',
  'closed_caption_disabled' = 'closed_caption_disabled',
  'closed_caption_disabled_outline' = 'closed_caption_disabled_outline',
  'cloud' = 'cloud',
  'cloud_outline' = 'cloud_outline',
  'cloud_circle' = 'cloud_circle',
  'cloud_circle_outline' = 'cloud_circle_outline',
  'cloud_done' = 'cloud_done',
  'cloud_done_outline' = 'cloud_done_outline',
  'cloud_download' = 'cloud_download',
  'cloud_download_outline' = 'cloud_download_outline',
  'cloud_off' = 'cloud_off',
  'cloud_off_outline' = 'cloud_off_outline',
  'cloud_sync' = 'cloud_sync',
  'cloud_sync_outline' = 'cloud_sync_outline',
  'cloudy' = 'cloudy',
  'cloudy_outline' = 'cloudy_outline',
  'cloudy_snowing' = 'cloudy_snowing',
  'cloudy_snowing_outline' = 'cloudy_snowing_outline',
  'co_present' = 'co_present',
  'co_present_outline' = 'co_present_outline',
  'co2' = 'co2',
  'code' = 'code',
  'code_blocks' = 'code_blocks',
  'code_blocks_outline' = 'code_blocks_outline',
  'code_off' = 'code_off',
  'coffee' = 'coffee',
  'coffee_outline' = 'coffee_outline',
  'coffee_maker' = 'coffee_maker',
  'coffee_maker_outline' = 'coffee_maker_outline',
  'cognition' = 'cognition',
  'cognition_outline' = 'cognition_outline',
  'collapse_all' = 'collapse_all',
  'collapse_content' = 'collapse_content',
  'collections_bookmark' = 'collections_bookmark',
  'collections_bookmark_outline' = 'collections_bookmark_outline',
  'colorize' = 'colorize',
  'colorize_outline' = 'colorize_outline',
  'colors' = 'colors',
  'comedy_mask' = 'comedy_mask',
  'comedy_mask_outline' = 'comedy_mask_outline',
  'comic_bubble' = 'comic_bubble',
  'comic_bubble_outline' = 'comic_bubble_outline',
  'comment' = 'comment',
  'comment_outline' = 'comment_outline',
  'comment_bank' = 'comment_bank',
  'comment_bank_outline' = 'comment_bank_outline',
  'comments_disabled' = 'comments_disabled',
  'comments_disabled_outline' = 'comments_disabled_outline',
  'commit' = 'commit',
  'communication' = 'communication',
  'communities' = 'communities',
  'communities_outline' = 'communities_outline',
  'commute' = 'commute',
  'commute_outline' = 'commute_outline',
  'compare' = 'compare',
  'compare_arrows' = 'compare_arrows',
  'compass_calibration' = 'compass_calibration',
  'compass_calibration_outline' = 'compass_calibration_outline',
  'component_exchange' = 'component_exchange',
  'compost' = 'compost',
  'compress' = 'compress',
  'computer' = 'computer',
  'computer_outline' = 'computer_outline',
  'concierge' = 'concierge',
  'concierge_outline' = 'concierge_outline',
  'conditions' = 'conditions',
  'confirmation_number' = 'confirmation_number',
  'confirmation_number_outline' = 'confirmation_number_outline',
  'congenital' = 'congenital',
  'congenital_outline' = 'congenital_outline',
  'connect_without_contact' = 'connect_without_contact',
  'connect_without_contact_outline' = 'connect_without_contact_outline',
  'connected_tv' = 'connected_tv',
  'connected_tv_outline' = 'connected_tv_outline',
  'connecting_airports' = 'connecting_airports',
  'construction' = 'construction',
  'contact_emergency' = 'contact_emergency',
  'contact_emergency_outline' = 'contact_emergency_outline',
  'contact_mail' = 'contact_mail',
  'contact_mail_outline' = 'contact_mail_outline',
  'contact_page' = 'contact_page',
  'contact_page_outline' = 'contact_page_outline',
  'contact_phone' = 'contact_phone',
  'contact_phone_outline' = 'contact_phone_outline',
  'contact_support' = 'contact_support',
  'contact_support_outline' = 'contact_support_outline',
  'contactless' = 'contactless',
  'contactless_outline' = 'contactless_outline',
  'contactless_off' = 'contactless_off',
  'contactless_off_outline' = 'contactless_off_outline',
  'contacts' = 'contacts',
  'contacts_outline' = 'contacts_outline',
  'contacts_product' = 'contacts_product',
  'contacts_product_outline' = 'contacts_product_outline',
  'content_copy' = 'content_copy',
  'content_copy_outline' = 'content_copy_outline',
  'content_paste' = 'content_paste',
  'content_paste_go' = 'content_paste_go',
  'content_paste_off' = 'content_paste_off',
  'content_paste_search' = 'content_paste_search',
  'contextual_token' = 'contextual_token',
  'contextual_token_outline' = 'contextual_token_outline',
  'contextual_token_add' = 'contextual_token_add',
  'contextual_token_add_outline' = 'contextual_token_add_outline',
  'contract' = 'contract',
  'contract_outline' = 'contract_outline',
  'contract_delete' = 'contract_delete',
  'contract_delete_outline' = 'contract_delete_outline',
  'contract_edit' = 'contract_edit',
  'contract_edit_outline' = 'contract_edit_outline',
  'contrast' = 'contrast',
  'contrast_circle' = 'contrast_circle',
  'contrast_rtl_off' = 'contrast_rtl_off',
  'contrast_rtl_off_outline' = 'contrast_rtl_off_outline',
  'contrast_square' = 'contrast_square',
  'control_camera' = 'control_camera',
  'control_point_duplicate' = 'control_point_duplicate',
  'control_point_duplicate_outline' = 'control_point_duplicate_outline',
  'controller_gen' = 'controller_gen',
  'controller_gen_outline' = 'controller_gen_outline',
  'conversion_path' = 'conversion_path',
  'conversion_path_off' = 'conversion_path_off',
  'conveyor_belt' = 'conveyor_belt',
  'conveyor_belt_outline' = 'conveyor_belt_outline',
  'cookie' = 'cookie',
  'cookie_outline' = 'cookie_outline',
  'cookie_off' = 'cookie_off',
  'cookie_off_outline' = 'cookie_off_outline',
  'cooking' = 'cooking',
  'cooking_outline' = 'cooking_outline',
  'cool_to_dry' = 'cool_to_dry',
  'cool_to_dry_outline' = 'cool_to_dry_outline',
  'copy_all' = 'copy_all',
  'copy_all_outline' = 'copy_all_outline',
  'copyright' = 'copyright',
  'copyright_outline' = 'copyright_outline',
  'coronavirus' = 'coronavirus',
  'coronavirus_outline' = 'coronavirus_outline',
  'corporate_fare' = 'corporate_fare',
  'cottage' = 'cottage',
  'cottage_outline' = 'cottage_outline',
  'counter_0' = 'counter_0',
  'counter_0_outline' = 'counter_0_outline',
  'counter_1' = 'counter_1',
  'counter_1_outline' = 'counter_1_outline',
  'counter_2' = 'counter_2',
  'counter_2_outline' = 'counter_2_outline',
  'counter_3' = 'counter_3',
  'counter_3_outline' = 'counter_3_outline',
  'counter_4' = 'counter_4',
  'counter_4_outline' = 'counter_4_outline',
  'counter_5' = 'counter_5',
  'counter_5_outline' = 'counter_5_outline',
  'counter_6' = 'counter_6',
  'counter_6_outline' = 'counter_6_outline',
  'counter_7' = 'counter_7',
  'counter_7_outline' = 'counter_7_outline',
  'counter_8' = 'counter_8',
  'counter_8_outline' = 'counter_8_outline',
  'counter_9' = 'counter_9',
  'counter_9_outline' = 'counter_9_outline',
  'countertops' = 'countertops',
  'countertops_outline' = 'countertops_outline',
  'create_new_folder' = 'create_new_folder',
  'create_new_folder_outline' = 'create_new_folder_outline',
  'credit_card' = 'credit_card',
  'credit_card_outline' = 'credit_card_outline',
  'credit_card_gear' = 'credit_card_gear',
  'credit_card_gear_outline' = 'credit_card_gear_outline',
  'credit_card_heart' = 'credit_card_heart',
  'credit_card_heart_outline' = 'credit_card_heart_outline',
  'credit_card_off' = 'credit_card_off',
  'credit_card_off_outline' = 'credit_card_off_outline',
  'credit_score' = 'credit_score',
  'credit_score_outline' = 'credit_score_outline',
  'crib' = 'crib',
  'crib_outline' = 'crib_outline',
  'crisis_alert' = 'crisis_alert',
  'crop' = 'crop',
  'crop_16_9' = 'crop_16_9',
  'crop_16_9_outline' = 'crop_16_9_outline',
  'crop_3_2' = 'crop_3_2',
  'crop_3_2_outline' = 'crop_3_2_outline',
  'crop_5_4' = 'crop_5_4',
  'crop_5_4_outline' = 'crop_5_4_outline',
  'crop_7_5' = 'crop_7_5',
  'crop_7_5_outline' = 'crop_7_5_outline',
  'crop_9_16' = 'crop_9_16',
  'crop_9_16_outline' = 'crop_9_16_outline',
  'crop_free' = 'crop_free',
  'crop_portrait' = 'crop_portrait',
  'crop_portrait_outline' = 'crop_portrait_outline',
  'crop_rotate' = 'crop_rotate',
  'crossword' = 'crossword',
  'crossword_outline' = 'crossword_outline',
  'crowdsource' = 'crowdsource',
  'cruelty_free' = 'cruelty_free',
  'css' = 'css',
  'csv' = 'csv',
  'csv_outline' = 'csv_outline',
  'currency_bitcoin' = 'currency_bitcoin',
  'currency_exchange' = 'currency_exchange',
  'currency_franc' = 'currency_franc',
  'currency_lira' = 'currency_lira',
  'currency_pound' = 'currency_pound',
  'currency_ruble' = 'currency_ruble',
  'currency_rupee' = 'currency_rupee',
  'currency_yen' = 'currency_yen',
  'currency_yuan' = 'currency_yuan',
  'curtains' = 'curtains',
  'curtains_outline' = 'curtains_outline',
  'curtains_closed' = 'curtains_closed',
  'curtains_closed_outline' = 'curtains_closed_outline',
  'custom_typography' = 'custom_typography',
  'cut' = 'cut',
  'cycle' = 'cycle',
  'cyclone' = 'cyclone',
  'dangerous' = 'dangerous',
  'dangerous_outline' = 'dangerous_outline',
  'dark_mode' = 'dark_mode',
  'dark_mode_outline' = 'dark_mode_outline',
  'dashboard' = 'dashboard',
  'dashboard_outline' = 'dashboard_outline',
  'dashboard_customize' = 'dashboard_customize',
  'dashboard_customize_outline' = 'dashboard_customize_outline',
  'data_alert' = 'data_alert',
  'data_array' = 'data_array',
  'data_check' = 'data_check',
  'data_exploration' = 'data_exploration',
  'data_exploration_outline' = 'data_exploration_outline',
  'data_info_alert' = 'data_info_alert',
  'data_loss_prevention' = 'data_loss_prevention',
  'data_loss_prevention_outline' = 'data_loss_prevention_outline',
  'data_object' = 'data_object',
  'data_saver_on' = 'data_saver_on',
  'data_table' = 'data_table',
  'data_table_outline' = 'data_table_outline',
  'data_thresholding' = 'data_thresholding',
  'data_thresholding_outline' = 'data_thresholding_outline',
  'data_usage' = 'data_usage',
  'database' = 'database',
  'database_outline' = 'database_outline',
  'dataset' = 'dataset',
  'dataset_outline' = 'dataset_outline',
  'dataset_linked' = 'dataset_linked',
  'dataset_linked_outline' = 'dataset_linked_outline',
  'date_range' = 'date_range',
  'date_range_outline' = 'date_range_outline',
  'deblur' = 'deblur',
  'deblur_outline' = 'deblur_outline',
  'deceased' = 'deceased',
  'deceased_outline' = 'deceased_outline',
  'decimal_decrease' = 'decimal_decrease',
  'decimal_increase' = 'decimal_increase',
  'deck' = 'deck',
  'deck_outline' = 'deck_outline',
  'dehaze' = 'dehaze',
  'delete' = 'delete',
  'delete_outline' = 'delete_outline',
  'delete_forever' = 'delete_forever',
  'delete_forever_outline' = 'delete_forever_outline',
  'delete_history' = 'delete_history',
  'delete_sweep' = 'delete_sweep',
  'delete_sweep_outline' = 'delete_sweep_outline',
  'demography' = 'demography',
  'demography_outline' = 'demography_outline',
  'density_large' = 'density_large',
  'density_medium' = 'density_medium',
  'density_small' = 'density_small',
  'dentistry' = 'dentistry',
  'dentistry_outline' = 'dentistry_outline',
  'departure_board' = 'departure_board',
  'departure_board_outline' = 'departure_board_outline',
  'deployed_code' = 'deployed_code',
  'deployed_code_outline' = 'deployed_code_outline',
  'deployed_code_account' = 'deployed_code_account',
  'deployed_code_account_outline' = 'deployed_code_account_outline',
  'deployed_code_alert' = 'deployed_code_alert',
  'deployed_code_alert_outline' = 'deployed_code_alert_outline',
  'deployed_code_history' = 'deployed_code_history',
  'deployed_code_history_outline' = 'deployed_code_history_outline',
  'deployed_code_update' = 'deployed_code_update',
  'deployed_code_update_outline' = 'deployed_code_update_outline',
  'dermatology' = 'dermatology',
  'dermatology_outline' = 'dermatology_outline',
  'description' = 'description',
  'description_outline' = 'description_outline',
  'deselect' = 'deselect',
  'design_services' = 'design_services',
  'design_services_outline' = 'design_services_outline',
  'desk' = 'desk',
  'deskphone' = 'deskphone',
  'deskphone_outline' = 'deskphone_outline',
  'desktop_access_disabled' = 'desktop_access_disabled',
  'desktop_access_disabled_outline' = 'desktop_access_disabled_outline',
  'desktop_mac' = 'desktop_mac',
  'desktop_mac_outline' = 'desktop_mac_outline',
  'desktop_windows' = 'desktop_windows',
  'desktop_windows_outline' = 'desktop_windows_outline',
  'destruction' = 'destruction',
  'destruction_outline' = 'destruction_outline',
  'details' = 'details',
  'detection_and_zone' = 'detection_and_zone',
  'detector' = 'detector',
  'detector_outline' = 'detector_outline',
  'detector_alarm' = 'detector_alarm',
  'detector_alarm_outline' = 'detector_alarm_outline',
  'detector_battery' = 'detector_battery',
  'detector_battery_outline' = 'detector_battery_outline',
  'detector_co' = 'detector_co',
  'detector_co_outline' = 'detector_co_outline',
  'detector_offline' = 'detector_offline',
  'detector_offline_outline' = 'detector_offline_outline',
  'detector_smoke' = 'detector_smoke',
  'detector_smoke_outline' = 'detector_smoke_outline',
  'detector_status' = 'detector_status',
  'detector_status_outline' = 'detector_status_outline',
  'developer_board' = 'developer_board',
  'developer_board_outline' = 'developer_board_outline',
  'developer_board_off' = 'developer_board_off',
  'developer_board_off_outline' = 'developer_board_off_outline',
  'developer_guide' = 'developer_guide',
  'developer_guide_outline' = 'developer_guide_outline',
  'developer_mode' = 'developer_mode',
  'developer_mode_outline' = 'developer_mode_outline',
  'developer_mode_tv' = 'developer_mode_tv',
  'developer_mode_tv_outline' = 'developer_mode_tv_outline',
  'device_hub' = 'device_hub',
  'device_thermostat' = 'device_thermostat',
  'device_unknown' = 'device_unknown',
  'device_unknown_outline' = 'device_unknown_outline',
  'devices' = 'devices',
  'devices_outline' = 'devices_outline',
  'devices_fold' = 'devices_fold',
  'devices_fold_outline' = 'devices_fold_outline',
  'devices_off' = 'devices_off',
  'devices_off_outline' = 'devices_off_outline',
  'devices_other' = 'devices_other',
  'devices_other_outline' = 'devices_other_outline',
  'devices_wearables' = 'devices_wearables',
  'devices_wearables_outline' = 'devices_wearables_outline',
  'dew_point' = 'dew_point',
  'dew_point_outline' = 'dew_point_outline',
  'diagnosis' = 'diagnosis',
  'diagnosis_outline' = 'diagnosis_outline',
  'dialer_sip' = 'dialer_sip',
  'dialer_sip_outline' = 'dialer_sip_outline',
  'dialogs' = 'dialogs',
  'dialogs_outline' = 'dialogs_outline',
  'dialpad' = 'dialpad',
  'diamond' = 'diamond',
  'diamond_outline' = 'diamond_outline',
  'dictionary' = 'dictionary',
  'dictionary_outline' = 'dictionary_outline',
  'difference' = 'difference',
  'difference_outline' = 'difference_outline',
  'digital_out_of_home' = 'digital_out_of_home',
  'digital_out_of_home_outline' = 'digital_out_of_home_outline',
  'digital_wellbeing' = 'digital_wellbeing',
  'digital_wellbeing_outline' = 'digital_wellbeing_outline',
  'dining' = 'dining',
  'dining_outline' = 'dining_outline',
  'dinner_dining' = 'dinner_dining',
  'dinner_dining_outline' = 'dinner_dining_outline',
  'directions' = 'directions',
  'directions_outline' = 'directions_outline',
  'directions_alt' = 'directions_alt',
  'directions_alt_outline' = 'directions_alt_outline',
  'directions_alt_off' = 'directions_alt_off',
  'directions_alt_off_outline' = 'directions_alt_off_outline',
  'directions_bike' = 'directions_bike',
  'directions_boat' = 'directions_boat',
  'directions_boat_outline' = 'directions_boat_outline',
  'directions_bus' = 'directions_bus',
  'directions_bus_outline' = 'directions_bus_outline',
  'directions_car' = 'directions_car',
  'directions_car_outline' = 'directions_car_outline',
  'directions_off' = 'directions_off',
  'directions_off_outline' = 'directions_off_outline',
  'directions_railway' = 'directions_railway',
  'directions_railway_outline' = 'directions_railway_outline',
  'directions_run' = 'directions_run',
  'directions_subway' = 'directions_subway',
  'directions_subway_outline' = 'directions_subway_outline',
  'directions_walk' = 'directions_walk',
  'directory_sync' = 'directory_sync',
  'dirty_lens' = 'dirty_lens',
  'dirty_lens_outline' = 'dirty_lens_outline',
  'disabled_by_default' = 'disabled_by_default',
  'disabled_by_default_outline' = 'disabled_by_default_outline',
  'disabled_visible' = 'disabled_visible',
  'disc_full' = 'disc_full',
  'disc_full_outline' = 'disc_full_outline',
  'discover_tune' = 'discover_tune',
  'dishwasher' = 'dishwasher',
  'dishwasher_outline' = 'dishwasher_outline',
  'dishwasher_gen' = 'dishwasher_gen',
  'dishwasher_gen_outline' = 'dishwasher_gen_outline',
  'display_external_input' = 'display_external_input',
  'display_settings' = 'display_settings',
  'display_settings_outline' = 'display_settings_outline',
  'distance' = 'distance',
  'distance_outline' = 'distance_outline',
  'diversity_1' = 'diversity_1',
  'diversity_1_outline' = 'diversity_1_outline',
  'diversity_2' = 'diversity_2',
  'diversity_2_outline' = 'diversity_2_outline',
  'diversity_3' = 'diversity_3',
  'diversity_4' = 'diversity_4',
  'diversity_4_outline' = 'diversity_4_outline',
  'dns' = 'dns',
  'dns_outline' = 'dns_outline',
  'do_not_disturb_off' = 'do_not_disturb_off',
  'do_not_disturb_off_outline' = 'do_not_disturb_off_outline',
  'do_not_disturb_on' = 'do_not_disturb_on',
  'do_not_disturb_on_outline' = 'do_not_disturb_on_outline',
  'do_not_disturb_on_total_silence' = 'do_not_disturb_on_total_silence',
  'do_not_disturb_on_total_silence_outline' = 'do_not_disturb_on_total_silence_outline',
  'do_not_step' = 'do_not_step',
  'do_not_step_outline' = 'do_not_step_outline',
  'do_not_touch' = 'do_not_touch',
  'do_not_touch_outline' = 'do_not_touch_outline',
  'dock' = 'dock',
  'dock_outline' = 'dock_outline',
  'dock_to_bottom' = 'dock_to_bottom',
  'dock_to_bottom_outline' = 'dock_to_bottom_outline',
  'dock_to_left' = 'dock_to_left',
  'dock_to_left_outline' = 'dock_to_left_outline',
  'dock_to_right' = 'dock_to_right',
  'dock_to_right_outline' = 'dock_to_right_outline',
  'docs_add_on' = 'docs_add_on',
  'docs_apps_script' = 'docs_apps_script',
  'docs_apps_script_outline' = 'docs_apps_script_outline',
  'document_scanner' = 'document_scanner',
  'document_scanner_outline' = 'document_scanner_outline',
  'domain' = 'domain',
  'domain_add' = 'domain_add',
  'domain_disabled' = 'domain_disabled',
  'domain_verification' = 'domain_verification',
  'domain_verification_outline' = 'domain_verification_outline',
  'domain_verification_off' = 'domain_verification_off',
  'domino_mask' = 'domino_mask',
  'domino_mask_outline' = 'domino_mask_outline',
  'done_outline' = 'done_outline',
  'done_all' = 'done_all',
  'donut_large' = 'donut_large',
  'donut_small' = 'donut_small',
  'donut_small_outline' = 'donut_small_outline',
  'door_back' = 'door_back',
  'door_back_outline' = 'door_back_outline',
  'door_front' = 'door_front',
  'door_front_outline' = 'door_front_outline',
  'door_open' = 'door_open',
  'door_open_outline' = 'door_open_outline',
  'door_sensor' = 'door_sensor',
  'door_sensor_outline' = 'door_sensor_outline',
  'door_sliding' = 'door_sliding',
  'door_sliding_outline' = 'door_sliding_outline',
  'doorbell' = 'doorbell',
  'doorbell_outline' = 'doorbell_outline',
  'doorbell_3p' = 'doorbell_3p',
  'doorbell_3p_outline' = 'doorbell_3p_outline',
  'doorbell_chime' = 'doorbell_chime',
  'doorbell_chime_outline' = 'doorbell_chime_outline',
  'double_arrow' = 'double_arrow',
  'downhill_skiing' = 'downhill_skiing',
  'download' = 'download',
  'download_2' = 'download_2',
  'download_2_outline' = 'download_2_outline',
  'download_done' = 'download_done',
  'download_for_offline' = 'download_for_offline',
  'download_for_offline_outline' = 'download_for_offline_outline',
  'downloading' = 'downloading',
  'draft' = 'draft',
  'draft_outline' = 'draft_outline',
  'draft_orders' = 'draft_orders',
  'draft_orders_outline' = 'draft_orders_outline',
  'drafts' = 'drafts',
  'drafts_outline' = 'drafts_outline',
  'drag_click' = 'drag_click',
  'drag_handle' = 'drag_handle',
  'drag_indicator' = 'drag_indicator',
  'drag_pan' = 'drag_pan',
  'draw' = 'draw',
  'draw_outline' = 'draw_outline',
  'draw_abstract' = 'draw_abstract',
  'draw_abstract_outline' = 'draw_abstract_outline',
  'draw_collage' = 'draw_collage',
  'draw_collage_outline' = 'draw_collage_outline',
  'dresser' = 'dresser',
  'dresser_outline' = 'dresser_outline',
  'drive_file_move' = 'drive_file_move',
  'drive_file_move_outline' = 'drive_file_move_outline',
  'drive_file_move_rtl' = 'drive_file_move_rtl',
  'drive_file_move_rtl_outline' = 'drive_file_move_rtl_outline',
  'drive_file_rename_outline' = 'drive_file_rename_outline',
  'drive_file_rename_outline_outline' = 'drive_file_rename_outline_outline',
  'drive_folder_upload' = 'drive_folder_upload',
  'drive_folder_upload_outline' = 'drive_folder_upload_outline',
  'dropdown' = 'dropdown',
  'dropdown_outline' = 'dropdown_outline',
  'dry' = 'dry',
  'dry_outline' = 'dry_outline',
  'dry_cleaning' = 'dry_cleaning',
  'dry_cleaning_outline' = 'dry_cleaning_outline',
  'dual_screen' = 'dual_screen',
  'dual_screen_outline' = 'dual_screen_outline',
  'duo' = 'duo',
  'duo_outline' = 'duo_outline',
  'dvr' = 'dvr',
  'dvr_outline' = 'dvr_outline',
  'dynamic_feed' = 'dynamic_feed',
  'dynamic_form' = 'dynamic_form',
  'dynamic_form_outline' = 'dynamic_form_outline',
  'e_mobiledata' = 'e_mobiledata',
  'e_mobiledata_badge' = 'e_mobiledata_badge',
  'e_mobiledata_badge_outline' = 'e_mobiledata_badge_outline',
  'e911_avatar' = 'e911_avatar',
  'e911_avatar_outline' = 'e911_avatar_outline',
  'e911_emergency' = 'e911_emergency',
  'e911_emergency_outline' = 'e911_emergency_outline',
  'earbuds' = 'earbuds',
  'earbuds_outline' = 'earbuds_outline',
  'earbuds_battery' = 'earbuds_battery',
  'earbuds_battery_outline' = 'earbuds_battery_outline',
  'early_on' = 'early_on',
  'early_on_outline' = 'early_on_outline',
  'earthquake' = 'earthquake',
  'east' = 'east',
  'ecg' = 'ecg',
  'ecg_outline' = 'ecg_outline',
  'ecg_heart' = 'ecg_heart',
  'ecg_heart_outline' = 'ecg_heart_outline',
  'eco' = 'eco',
  'eco_outline' = 'eco_outline',
  'eda' = 'eda',
  'eda_outline' = 'eda_outline',
  'edgesensor_high' = 'edgesensor_high',
  'edgesensor_high_outline' = 'edgesensor_high_outline',
  'edgesensor_low' = 'edgesensor_low',
  'edgesensor_low_outline' = 'edgesensor_low_outline',
  'edit' = 'edit',
  'edit_outline' = 'edit_outline',
  'edit_attributes' = 'edit_attributes',
  'edit_attributes_outline' = 'edit_attributes_outline',
  'edit_calendar' = 'edit_calendar',
  'edit_calendar_outline' = 'edit_calendar_outline',
  'edit_document' = 'edit_document',
  'edit_document_outline' = 'edit_document_outline',
  'edit_location' = 'edit_location',
  'edit_location_outline' = 'edit_location_outline',
  'edit_location_alt' = 'edit_location_alt',
  'edit_location_alt_outline' = 'edit_location_alt_outline',
  'edit_note' = 'edit_note',
  'edit_note_outline' = 'edit_note_outline',
  'edit_notifications' = 'edit_notifications',
  'edit_notifications_outline' = 'edit_notifications_outline',
  'edit_off' = 'edit_off',
  'edit_off_outline' = 'edit_off_outline',
  'edit_road' = 'edit_road',
  'edit_road_outline' = 'edit_road_outline',
  'edit_square' = 'edit_square',
  'edit_square_outline' = 'edit_square_outline',
  'editor_choice' = 'editor_choice',
  'editor_choice_outline' = 'editor_choice_outline',
  'egg' = 'egg',
  'egg_outline' = 'egg_outline',
  'egg_alt' = 'egg_alt',
  'egg_alt_outline' = 'egg_alt_outline',
  'eject' = 'eject',
  'eject_outline' = 'eject_outline',
  'elderly' = 'elderly',
  'elderly_woman' = 'elderly_woman',
  'electric_bike' = 'electric_bike',
  'electric_bike_outline' = 'electric_bike_outline',
  'electric_bolt' = 'electric_bolt',
  'electric_bolt_outline' = 'electric_bolt_outline',
  'electric_car' = 'electric_car',
  'electric_car_outline' = 'electric_car_outline',
  'electric_meter' = 'electric_meter',
  'electric_meter_outline' = 'electric_meter_outline',
  'electric_moped' = 'electric_moped',
  'electric_moped_outline' = 'electric_moped_outline',
  'electric_rickshaw' = 'electric_rickshaw',
  'electric_rickshaw_outline' = 'electric_rickshaw_outline',
  'electric_scooter' = 'electric_scooter',
  'electric_scooter_outline' = 'electric_scooter_outline',
  'electrical_services' = 'electrical_services',
  'elevation' = 'elevation',
  'elevation_outline' = 'elevation_outline',
  'elevator' = 'elevator',
  'elevator_outline' = 'elevator_outline',
  'emergency' = 'emergency',
  'emergency_heat' = 'emergency_heat',
  'emergency_heat_outline' = 'emergency_heat_outline',
  'emergency_heat_2' = 'emergency_heat_2',
  'emergency_home' = 'emergency_home',
  'emergency_home_outline' = 'emergency_home_outline',
  'emergency_recording' = 'emergency_recording',
  'emergency_recording_outline' = 'emergency_recording_outline',
  'emergency_share' = 'emergency_share',
  'emergency_share_outline' = 'emergency_share_outline',
  'emergency_share_off' = 'emergency_share_off',
  'emergency_share_off_outline' = 'emergency_share_off_outline',
  'emoji_food_beverage' = 'emoji_food_beverage',
  'emoji_food_beverage_outline' = 'emoji_food_beverage_outline',
  'emoji_language' = 'emoji_language',
  'emoji_language_outline' = 'emoji_language_outline',
  'emoji_nature' = 'emoji_nature',
  'emoji_nature_outline' = 'emoji_nature_outline',
  'emoji_objects' = 'emoji_objects',
  'emoji_objects_outline' = 'emoji_objects_outline',
  'emoji_people' = 'emoji_people',
  'emoji_symbols' = 'emoji_symbols',
  'emoji_transportation' = 'emoji_transportation',
  'emoji_transportation_outline' = 'emoji_transportation_outline',
  'emoticon' = 'emoticon',
  'empty_dashboard' = 'empty_dashboard',
  'empty_dashboard_outline' = 'empty_dashboard_outline',
  'enable' = 'enable',
  'encrypted' = 'encrypted',
  'encrypted_outline' = 'encrypted_outline',
  'endocrinology' = 'endocrinology',
  'endocrinology_outline' = 'endocrinology_outline',
  'energy' = 'energy',
  'energy_program_saving' = 'energy_program_saving',
  'energy_program_saving_outline' = 'energy_program_saving_outline',
  'energy_program_time_used' = 'energy_program_time_used',
  'energy_program_time_used_outline' = 'energy_program_time_used_outline',
  'energy_savings_leaf' = 'energy_savings_leaf',
  'energy_savings_leaf_outline' = 'energy_savings_leaf_outline',
  'engineering' = 'engineering',
  'engineering_outline' = 'engineering_outline',
  'enhanced_encryption' = 'enhanced_encryption',
  'enhanced_encryption_outline' = 'enhanced_encryption_outline',
  'ent' = 'ent',
  'ent_outline' = 'ent_outline',
  'enterprise' = 'enterprise',
  'enterprise_outline' = 'enterprise_outline',
  'enterprise_off' = 'enterprise_off',
  'enterprise_off_outline' = 'enterprise_off_outline',
  'equal' = 'equal',
  'equalizer' = 'equalizer',
  'error' = 'error',
  'error_outline' = 'error_outline',
  'error_circle_rounded' = 'error_circle_rounded',
  'error_circle_rounded_outline' = 'error_circle_rounded_outline',
  'error_med' = 'error_med',
  'error_med_outline' = 'error_med_outline',
  'escalator' = 'escalator',
  'escalator_outline' = 'escalator_outline',
  'escalator_warning' = 'escalator_warning',
  'euro' = 'euro',
  'euro_symbol' = 'euro_symbol',
  'ev_charger' = 'ev_charger',
  'ev_charger_outline' = 'ev_charger_outline',
  'ev_mobiledata_badge' = 'ev_mobiledata_badge',
  'ev_mobiledata_badge_outline' = 'ev_mobiledata_badge_outline',
  'ev_shadow' = 'ev_shadow',
  'ev_shadow_outline' = 'ev_shadow_outline',
  'ev_shadow_add' = 'ev_shadow_add',
  'ev_shadow_add_outline' = 'ev_shadow_add_outline',
  'ev_shadow_minus' = 'ev_shadow_minus',
  'ev_shadow_minus_outline' = 'ev_shadow_minus_outline',
  'event' = 'event',
  'event_outline' = 'event_outline',
  'event_available' = 'event_available',
  'event_available_outline' = 'event_available_outline',
  'event_busy' = 'event_busy',
  'event_busy_outline' = 'event_busy_outline',
  'event_list' = 'event_list',
  'event_list_outline' = 'event_list_outline',
  'event_note' = 'event_note',
  'event_note_outline' = 'event_note_outline',
  'event_repeat' = 'event_repeat',
  'event_repeat_outline' = 'event_repeat_outline',
  'event_seat' = 'event_seat',
  'event_seat_outline' = 'event_seat_outline',
  'event_upcoming' = 'event_upcoming',
  'event_upcoming_outline' = 'event_upcoming_outline',
  'exclamation' = 'exclamation',
  'exercise' = 'exercise',
  'exercise_outline' = 'exercise_outline',
  'exit_to_app' = 'exit_to_app',
  'expand' = 'expand',
  'expand_all' = 'expand_all',
  'expand_circle_down' = 'expand_circle_down',
  'expand_circle_down_outline' = 'expand_circle_down_outline',
  'expand_circle_right' = 'expand_circle_right',
  'expand_circle_right_outline' = 'expand_circle_right_outline',
  'expand_circle_up' = 'expand_circle_up',
  'expand_circle_up_outline' = 'expand_circle_up_outline',
  'expand_content' = 'expand_content',
  'expand_less' = 'expand_less',
  'expand_more' = 'expand_more',
  'experiment' = 'experiment',
  'experiment_outline' = 'experiment_outline',
  'explicit' = 'explicit',
  'explicit_outline' = 'explicit_outline',
  'explore' = 'explore',
  'explore_outline' = 'explore_outline',
  'explore_nearby' = 'explore_nearby',
  'explore_off' = 'explore_off',
  'explore_off_outline' = 'explore_off_outline',
  'explosion' = 'explosion',
  'explosion_outline' = 'explosion_outline',
  'export_notes' = 'export_notes',
  'export_notes_outline' = 'export_notes_outline',
  'exposure' = 'exposure',
  'exposure_neg_1' = 'exposure_neg_1',
  'exposure_neg_2' = 'exposure_neg_2',
  'exposure_plus_1' = 'exposure_plus_1',
  'exposure_plus_2' = 'exposure_plus_2',
  'exposure_zero' = 'exposure_zero',
  'extension' = 'extension',
  'extension_outline' = 'extension_outline',
  'extension_off' = 'extension_off',
  'extension_off_outline' = 'extension_off_outline',
  'eye_tracking' = 'eye_tracking',
  'eye_tracking_outline' = 'eye_tracking_outline',
  'eyeglasses' = 'eyeglasses',
  'face' = 'face',
  'face_outline' = 'face_outline',
  'face_2' = 'face_2',
  'face_2_outline' = 'face_2_outline',
  'face_3' = 'face_3',
  'face_3_outline' = 'face_3_outline',
  'face_4' = 'face_4',
  'face_4_outline' = 'face_4_outline',
  'face_5' = 'face_5',
  'face_6' = 'face_6',
  'face_6_outline' = 'face_6_outline',
  'face_retouching_natural' = 'face_retouching_natural',
  'face_retouching_natural_outline' = 'face_retouching_natural_outline',
  'face_retouching_off' = 'face_retouching_off',
  'face_retouching_off_outline' = 'face_retouching_off_outline',
  'fact_check' = 'fact_check',
  'fact_check_outline' = 'fact_check_outline',
  'factory' = 'factory',
  'factory_outline' = 'factory_outline',
  'falling' = 'falling',
  'familiar_face_and_zone' = 'familiar_face_and_zone',
  'familiar_face_and_zone_outline' = 'familiar_face_and_zone_outline',
  'family_history' = 'family_history',
  'family_history_outline' = 'family_history_outline',
  'family_home' = 'family_home',
  'family_home_outline' = 'family_home_outline',
  'family_link' = 'family_link',
  'family_link_outline' = 'family_link_outline',
  'family_restroom' = 'family_restroom',
  'family_star' = 'family_star',
  'family_star_outline' = 'family_star_outline',
  'farsight_digital' = 'farsight_digital',
  'farsight_digital_outline' = 'farsight_digital_outline',
  'fast_forward' = 'fast_forward',
  'fast_forward_outline' = 'fast_forward_outline',
  'fast_rewind' = 'fast_rewind',
  'fast_rewind_outline' = 'fast_rewind_outline',
  'fastfood' = 'fastfood',
  'fastfood_outline' = 'fastfood_outline',
  'faucet' = 'faucet',
  'faucet_outline' = 'faucet_outline',
  'favorite' = 'favorite',
  'favorite_outline' = 'favorite_outline',
  'fax' = 'fax',
  'fax_outline' = 'fax_outline',
  'feature_search' = 'feature_search',
  'feature_search_outline' = 'feature_search_outline',
  'featured_play_list' = 'featured_play_list',
  'featured_play_list_outline' = 'featured_play_list_outline',
  'featured_seasonal_and_gifts' = 'featured_seasonal_and_gifts',
  'featured_video' = 'featured_video',
  'featured_video_outline' = 'featured_video_outline',
  'feedback' = 'feedback',
  'feedback_outline' = 'feedback_outline',
  'female' = 'female',
  'femur' = 'femur',
  'femur_outline' = 'femur_outline',
  'femur_alt' = 'femur_alt',
  'femur_alt_outline' = 'femur_alt_outline',
  'fence' = 'fence',
  'fertile' = 'fertile',
  'fertile_outline' = 'fertile_outline',
  'festival' = 'festival',
  'fiber_dvr' = 'fiber_dvr',
  'fiber_dvr_outline' = 'fiber_dvr_outline',
  'fiber_manual_record' = 'fiber_manual_record',
  'fiber_manual_record_outline' = 'fiber_manual_record_outline',
  'fiber_new' = 'fiber_new',
  'fiber_new_outline' = 'fiber_new_outline',
  'fiber_pin' = 'fiber_pin',
  'fiber_pin_outline' = 'fiber_pin_outline',
  'fiber_smart_record' = 'fiber_smart_record',
  'fiber_smart_record_outline' = 'fiber_smart_record_outline',
  'file_copy' = 'file_copy',
  'file_copy_outline' = 'file_copy_outline',
  'file_copy_off' = 'file_copy_off',
  'file_copy_off_outline' = 'file_copy_off_outline',
  'file_download' = 'file_download',
  'file_download_off' = 'file_download_off',
  'file_map' = 'file_map',
  'file_map_outline' = 'file_map_outline',
  'file_open' = 'file_open',
  'file_open_outline' = 'file_open_outline',
  'file_present' = 'file_present',
  'file_present_outline' = 'file_present_outline',
  'file_save' = 'file_save',
  'file_save_outline' = 'file_save_outline',
  'file_save_off' = 'file_save_off',
  'file_save_off_outline' = 'file_save_off_outline',
  'file_upload' = 'file_upload',
  'file_upload_off' = 'file_upload_off',
  'filter' = 'filter',
  'filter_outline' = 'filter_outline',
  'filter_1' = 'filter_1',
  'filter_1_outline' = 'filter_1_outline',
  'filter_2' = 'filter_2',
  'filter_2_outline' = 'filter_2_outline',
  'filter_3' = 'filter_3',
  'filter_3_outline' = 'filter_3_outline',
  'filter_4' = 'filter_4',
  'filter_4_outline' = 'filter_4_outline',
  'filter_5' = 'filter_5',
  'filter_5_outline' = 'filter_5_outline',
  'filter_6' = 'filter_6',
  'filter_6_outline' = 'filter_6_outline',
  'filter_7' = 'filter_7',
  'filter_7_outline' = 'filter_7_outline',
  'filter_8' = 'filter_8',
  'filter_8_outline' = 'filter_8_outline',
  'filter_9' = 'filter_9',
  'filter_9_outline' = 'filter_9_outline',
  'filter_9_plus' = 'filter_9_plus',
  'filter_9_plus_outline' = 'filter_9_plus_outline',
  'filter_alt' = 'filter_alt',
  'filter_alt_outline' = 'filter_alt_outline',
  'filter_alt_off' = 'filter_alt_off',
  'filter_alt_off_outline' = 'filter_alt_off_outline',
  'filter_b_and_w' = 'filter_b_and_w',
  'filter_center_focus' = 'filter_center_focus',
  'filter_center_focus_outline' = 'filter_center_focus_outline',
  'filter_drama' = 'filter_drama',
  'filter_drama_outline' = 'filter_drama_outline',
  'filter_frames' = 'filter_frames',
  'filter_frames_outline' = 'filter_frames_outline',
  'filter_list' = 'filter_list',
  'filter_list_off' = 'filter_list_off',
  'filter_none' = 'filter_none',
  'filter_none_outline' = 'filter_none_outline',
  'filter_retrolux' = 'filter_retrolux',
  'filter_retrolux_outline' = 'filter_retrolux_outline',
  'filter_tilt_shift' = 'filter_tilt_shift',
  'filter_vintage' = 'filter_vintage',
  'filter_vintage_outline' = 'filter_vintage_outline',
  'finance' = 'finance',
  'finance_chip' = 'finance_chip',
  'finance_chip_outline' = 'finance_chip_outline',
  'finance_mode' = 'finance_mode',
  'find_in_page' = 'find_in_page',
  'find_in_page_outline' = 'find_in_page_outline',
  'find_replace' = 'find_replace',
  'fingerprint' = 'fingerprint',
  'fingerprint_off' = 'fingerprint_off',
  'fire_extinguisher' = 'fire_extinguisher',
  'fire_hydrant' = 'fire_hydrant',
  'fire_hydrant_outline' = 'fire_hydrant_outline',
  'fire_truck' = 'fire_truck',
  'fire_truck_outline' = 'fire_truck_outline',
  'fireplace' = 'fireplace',
  'fireplace_outline' = 'fireplace_outline',
  'first_page' = 'first_page',
  'fit_page' = 'fit_page',
  'fit_screen' = 'fit_screen',
  'fit_screen_outline' = 'fit_screen_outline',
  'fit_width' = 'fit_width',
  'fitness_center' = 'fitness_center',
  'flag' = 'flag',
  'flag_outline' = 'flag_outline',
  'flag_circle' = 'flag_circle',
  'flag_circle_outline' = 'flag_circle_outline',
  'flaky' = 'flaky',
  'flare' = 'flare',
  'flash_auto' = 'flash_auto',
  'flash_auto_outline' = 'flash_auto_outline',
  'flash_off' = 'flash_off',
  'flash_off_outline' = 'flash_off_outline',
  'flash_on' = 'flash_on',
  'flash_on_outline' = 'flash_on_outline',
  'flashlight_off' = 'flashlight_off',
  'flashlight_off_outline' = 'flashlight_off_outline',
  'flashlight_on' = 'flashlight_on',
  'flashlight_on_outline' = 'flashlight_on_outline',
  'flatware' = 'flatware',
  'flex_direction' = 'flex_direction',
  'flex_no_wrap' = 'flex_no_wrap',
  'flex_wrap' = 'flex_wrap',
  'flight' = 'flight',
  'flight_class' = 'flight_class',
  'flight_class_outline' = 'flight_class_outline',
  'flight_land' = 'flight_land',
  'flight_takeoff' = 'flight_takeoff',
  'flights_and_hotels' = 'flights_and_hotels',
  'flights_and_hotels_outline' = 'flights_and_hotels_outline',
  'flip' = 'flip',
  'flip_camera_android' = 'flip_camera_android',
  'flip_camera_ios' = 'flip_camera_ios',
  'flip_camera_ios_outline' = 'flip_camera_ios_outline',
  'flip_to_back' = 'flip_to_back',
  'flip_to_front' = 'flip_to_front',
  'float_landscape' = 'float_landscape',
  'float_landscape_outline' = 'float_landscape_outline',
  'float_portrait' = 'float_portrait',
  'float_portrait_outline' = 'float_portrait_outline',
  'flood' = 'flood',
  'flood_outline' = 'flood_outline',
  'floor' = 'floor',
  'floor_lamp' = 'floor_lamp',
  'floor_lamp_outline' = 'floor_lamp_outline',
  'flourescent' = 'flourescent',
  'flourescent_outline' = 'flourescent_outline',
  'flowsheet' = 'flowsheet',
  'flowsheet_outline' = 'flowsheet_outline',
  'fluid' = 'fluid',
  'fluid_outline' = 'fluid_outline',
  'fluid_balance' = 'fluid_balance',
  'fluid_balance_outline' = 'fluid_balance_outline',
  'fluid_med' = 'fluid_med',
  'fluid_med_outline' = 'fluid_med_outline',
  'fluorescent' = 'fluorescent',
  'fluorescent_outline' = 'fluorescent_outline',
  'flutter' = 'flutter',
  'flutter_dash' = 'flutter_dash',
  'fmd_bad' = 'fmd_bad',
  'fmd_bad_outline' = 'fmd_bad_outline',
  'foggy' = 'foggy',
  'foggy_outline' = 'foggy_outline',
  'folded_hands' = 'folded_hands',
  'folded_hands_outline' = 'folded_hands_outline',
  'folder' = 'folder',
  'folder_outline' = 'folder_outline',
  'folder_copy' = 'folder_copy',
  'folder_copy_outline' = 'folder_copy_outline',
  'folder_data' = 'folder_data',
  'folder_data_outline' = 'folder_data_outline',
  'folder_delete' = 'folder_delete',
  'folder_delete_outline' = 'folder_delete_outline',
  'folder_limited' = 'folder_limited',
  'folder_limited_outline' = 'folder_limited_outline',
  'folder_managed' = 'folder_managed',
  'folder_managed_outline' = 'folder_managed_outline',
  'folder_off' = 'folder_off',
  'folder_off_outline' = 'folder_off_outline',
  'folder_open' = 'folder_open',
  'folder_open_outline' = 'folder_open_outline',
  'folder_shared' = 'folder_shared',
  'folder_shared_outline' = 'folder_shared_outline',
  'folder_special' = 'folder_special',
  'folder_special_outline' = 'folder_special_outline',
  'folder_supervised' = 'folder_supervised',
  'folder_supervised_outline' = 'folder_supervised_outline',
  'folder_zip' = 'folder_zip',
  'folder_zip_outline' = 'folder_zip_outline',
  'follow_the_signs' = 'follow_the_signs',
  'font_download' = 'font_download',
  'font_download_outline' = 'font_download_outline',
  'font_download_off' = 'font_download_off',
  'font_download_off_outline' = 'font_download_off_outline',
  'food_bank' = 'food_bank',
  'food_bank_outline' = 'food_bank_outline',
  'foot_bones' = 'foot_bones',
  'foot_bones_outline' = 'foot_bones_outline',
  'footprint' = 'footprint',
  'for_you' = 'for_you',
  'for_you_outline' = 'for_you_outline',
  'forest' = 'forest',
  'forest_outline' = 'forest_outline',
  'fork_left' = 'fork_left',
  'fork_right' = 'fork_right',
  'forklift' = 'forklift',
  'forklift_outline' = 'forklift_outline',
  'format_align_center' = 'format_align_center',
  'format_align_justify' = 'format_align_justify',
  'format_align_left' = 'format_align_left',
  'format_align_right' = 'format_align_right',
  'format_bold' = 'format_bold',
  'format_clear' = 'format_clear',
  'format_color_fill' = 'format_color_fill',
  'format_color_reset' = 'format_color_reset',
  'format_color_reset_outline' = 'format_color_reset_outline',
  'format_color_text' = 'format_color_text',
  'format_h1' = 'format_h1',
  'format_h2' = 'format_h2',
  'format_h3' = 'format_h3',
  'format_h4' = 'format_h4',
  'format_h4_outline' = 'format_h4_outline',
  'format_h5' = 'format_h5',
  'format_h6' = 'format_h6',
  'format_image_left' = 'format_image_left',
  'format_image_right' = 'format_image_right',
  'format_indent_decrease' = 'format_indent_decrease',
  'format_indent_increase' = 'format_indent_increase',
  'format_ink_highlighter' = 'format_ink_highlighter',
  'format_ink_highlighter_outline' = 'format_ink_highlighter_outline',
  'format_italic' = 'format_italic',
  'format_letter_spacing' = 'format_letter_spacing',
  'format_letter_spacing_2' = 'format_letter_spacing_2',
  'format_letter_spacing_2_outline' = 'format_letter_spacing_2_outline',
  'format_letter_spacing_standard' = 'format_letter_spacing_standard',
  'format_letter_spacing_wide' = 'format_letter_spacing_wide',
  'format_letter_spacing_wider' = 'format_letter_spacing_wider',
  'format_line_spacing' = 'format_line_spacing',
  'format_list_bulleted' = 'format_list_bulleted',
  'format_list_bulleted_add' = 'format_list_bulleted_add',
  'format_list_numbered' = 'format_list_numbered',
  'format_list_numbered_rtl' = 'format_list_numbered_rtl',
  'format_overline' = 'format_overline',
  'format_paint' = 'format_paint',
  'format_paint_outline' = 'format_paint_outline',
  'format_paragraph' = 'format_paragraph',
  'format_quote' = 'format_quote',
  'format_quote_outline' = 'format_quote_outline',
  'format_shapes' = 'format_shapes',
  'format_shapes_outline' = 'format_shapes_outline',
  'format_size' = 'format_size',
  'format_strikethrough' = 'format_strikethrough',
  'format_text_clip' = 'format_text_clip',
  'format_text_overflow' = 'format_text_overflow',
  'format_text_wrap' = 'format_text_wrap',
  'format_textdirection_l_to_r' = 'format_textdirection_l_to_r',
  'format_textdirection_l_to_r_outline' = 'format_textdirection_l_to_r_outline',
  'format_textdirection_r_to_l' = 'format_textdirection_r_to_l',
  'format_textdirection_r_to_l_outline' = 'format_textdirection_r_to_l_outline',
  'format_textdirection_vertical' = 'format_textdirection_vertical',
  'format_textdirection_vertical_outline' = 'format_textdirection_vertical_outline',
  'format_underlined' = 'format_underlined',
  'format_underlined_squiggle' = 'format_underlined_squiggle',
  'forms_add_on' = 'forms_add_on',
  'forms_apps_script' = 'forms_apps_script',
  'fort' = 'fort',
  'fort_outline' = 'fort_outline',
  'forum' = 'forum',
  'forum_outline' = 'forum_outline',
  'forward' = 'forward',
  'forward_10' = 'forward_10',
  'forward_30' = 'forward_30',
  'forward_5' = 'forward_5',
  'forward_circle' = 'forward_circle',
  'forward_circle_outline' = 'forward_circle_outline',
  'forward_media' = 'forward_media',
  'forward_to_inbox' = 'forward_to_inbox',
  'forward_to_inbox_outline' = 'forward_to_inbox_outline',
  'foundation' = 'foundation',
  'frame_inspect' = 'frame_inspect',
  'frame_person' = 'frame_person',
  'frame_person_outline' = 'frame_person_outline',
  'frame_person_mic' = 'frame_person_mic',
  'frame_person_mic_outline' = 'frame_person_mic_outline',
  'frame_person_off' = 'frame_person_off',
  'frame_person_off_outline' = 'frame_person_off_outline',
  'frame_reload' = 'frame_reload',
  'frame_source' = 'frame_source',
  'free_cancellation' = 'free_cancellation',
  'free_cancellation_outline' = 'free_cancellation_outline',
  'front_hand' = 'front_hand',
  'front_hand_outline' = 'front_hand_outline',
  'front_loader' = 'front_loader',
  'front_loader_outline' = 'front_loader_outline',
  'full_coverage' = 'full_coverage',
  'full_coverage_outline' = 'full_coverage_outline',
  'full_hd' = 'full_hd',
  'full_hd_outline' = 'full_hd_outline',
  'full_stacked_bar_chart' = 'full_stacked_bar_chart',
  'fullscreen' = 'fullscreen',
  'fullscreen_exit' = 'fullscreen_exit',
  'function' = 'function',
  'functions' = 'functions',
  'fx' = 'fx',
  'g_mobiledata' = 'g_mobiledata',
  'g_mobiledata_badge' = 'g_mobiledata_badge',
  'g_mobiledata_badge_outline' = 'g_mobiledata_badge_outline',
  'g_translate' = 'g_translate',
  'gallery_thumbnail' = 'gallery_thumbnail',
  'gallery_thumbnail_outline' = 'gallery_thumbnail_outline',
  'gamepad' = 'gamepad',
  'gamepad_outline' = 'gamepad_outline',
  'garage' = 'garage',
  'garage_outline' = 'garage_outline',
  'garage_door' = 'garage_door',
  'garage_door_outline' = 'garage_door_outline',
  'garage_home' = 'garage_home',
  'garage_home_outline' = 'garage_home_outline',
  'garden_cart' = 'garden_cart',
  'garden_cart_outline' = 'garden_cart_outline',
  'gas_meter' = 'gas_meter',
  'gas_meter_outline' = 'gas_meter_outline',
  'gastroenterology' = 'gastroenterology',
  'gastroenterology_outline' = 'gastroenterology_outline',
  'gate' = 'gate',
  'gate_outline' = 'gate_outline',
  'gavel' = 'gavel',
  'general_device' = 'general_device',
  'general_device_outline' = 'general_device_outline',
  'generating_tokens' = 'generating_tokens',
  'generating_tokens_outline' = 'generating_tokens_outline',
  'genetics' = 'genetics',
  'genres' = 'genres',
  'genres_outline' = 'genres_outline',
  'gesture' = 'gesture',
  'gesture_select' = 'gesture_select',
  'gesture_select_outline' = 'gesture_select_outline',
  'gif' = 'gif',
  'gif_box' = 'gif_box',
  'gif_box_outline' = 'gif_box_outline',
  'girl' = 'girl',
  'gite' = 'gite',
  'glass_cup' = 'glass_cup',
  'glass_cup_outline' = 'glass_cup_outline',
  'globe' = 'globe',
  'globe_asia' = 'globe_asia',
  'globe_uk' = 'globe_uk',
  'glucose' = 'glucose',
  'glucose_outline' = 'glucose_outline',
  'glyphs' = 'glyphs',
  'go_to_line' = 'go_to_line',
  'golf_course' = 'golf_course',
  'google_home_devices' = 'google_home_devices',
  'google_plus_reshare' = 'google_plus_reshare',
  'google_tv_remote' = 'google_tv_remote',
  'google_tv_remote_outline' = 'google_tv_remote_outline',
  'google_wifi' = 'google_wifi',
  'google_wifi_outline' = 'google_wifi_outline',
  'gpp_bad' = 'gpp_bad',
  'gpp_bad_outline' = 'gpp_bad_outline',
  'gpp_maybe' = 'gpp_maybe',
  'gpp_maybe_outline' = 'gpp_maybe_outline',
  'gradient' = 'gradient',
  'gradient_outline' = 'gradient_outline',
  'grading' = 'grading',
  'grain' = 'grain',
  'graphic_eq' = 'graphic_eq',
  'grass' = 'grass',
  'grid_3x3' = 'grid_3x3',
  'grid_3x3_off' = 'grid_3x3_off',
  'grid_4x4' = 'grid_4x4',
  'grid_goldenratio' = 'grid_goldenratio',
  'grid_guides' = 'grid_guides',
  'grid_off' = 'grid_off',
  'grid_off_outline' = 'grid_off_outline',
  'grid_on' = 'grid_on',
  'grid_on_outline' = 'grid_on_outline',
  'grid_view' = 'grid_view',
  'grid_view_outline' = 'grid_view_outline',
  'grocery' = 'grocery',
  'group' = 'group',
  'group_outline' = 'group_outline',
  'group_add' = 'group_add',
  'group_add_outline' = 'group_add_outline',
  'group_auto' = 'group_auto',
  'group_auto_outline' = 'group_auto_outline',
  'group_off' = 'group_off',
  'group_off_outline' = 'group_off_outline',
  'group_remove' = 'group_remove',
  'group_remove_outline' = 'group_remove_outline',
  'group_work' = 'group_work',
  'group_work_outline' = 'group_work_outline',
  'grouped_bar_chart' = 'grouped_bar_chart',
  'groups' = 'groups',
  'groups_outline' = 'groups_outline',
  'groups_2' = 'groups_2',
  'groups_2_outline' = 'groups_2_outline',
  'groups_3' = 'groups_3',
  'groups_3_outline' = 'groups_3_outline',
  'guardian' = 'guardian',
  'gynecology' = 'gynecology',
  'gynecology_outline' = 'gynecology_outline',
  'h_mobiledata' = 'h_mobiledata',
  'h_mobiledata_badge' = 'h_mobiledata_badge',
  'h_mobiledata_badge_outline' = 'h_mobiledata_badge_outline',
  'h_plus_mobiledata' = 'h_plus_mobiledata',
  'h_plus_mobiledata_badge' = 'h_plus_mobiledata_badge',
  'h_plus_mobiledata_badge_outline' = 'h_plus_mobiledata_badge_outline',
  'hail' = 'hail',
  'hallway' = 'hallway',
  'hallway_outline' = 'hallway_outline',
  'hand_bones' = 'hand_bones',
  'hand_bones_outline' = 'hand_bones_outline',
  'hand_gesture' = 'hand_gesture',
  'hand_gesture_outline' = 'hand_gesture_outline',
  'handheld_controller' = 'handheld_controller',
  'handheld_controller_outline' = 'handheld_controller_outline',
  'handshake' = 'handshake',
  'handshake_outline' = 'handshake_outline',
  'handyman' = 'handyman',
  'handyman_outline' = 'handyman_outline',
  'hangout_meeting' = 'hangout_meeting',
  'hangout_meeting_outline' = 'hangout_meeting_outline',
  'hangout_video' = 'hangout_video',
  'hangout_video_outline' = 'hangout_video_outline',
  'hangout_video_off' = 'hangout_video_off',
  'hangout_video_off_outline' = 'hangout_video_off_outline',
  'hard_drive' = 'hard_drive',
  'hard_drive_outline' = 'hard_drive_outline',
  'hard_drive_2' = 'hard_drive_2',
  'hard_drive_2_outline' = 'hard_drive_2_outline',
  'hardware' = 'hardware',
  'hardware_outline' = 'hardware_outline',
  'hd' = 'hd',
  'hd_outline' = 'hd_outline',
  'hdr_auto' = 'hdr_auto',
  'hdr_auto_outline' = 'hdr_auto_outline',
  'hdr_auto_select' = 'hdr_auto_select',
  'hdr_enhanced_select' = 'hdr_enhanced_select',
  'hdr_enhanced_select_outline' = 'hdr_enhanced_select_outline',
  'hdr_off' = 'hdr_off',
  'hdr_off_select' = 'hdr_off_select',
  'hdr_off_select_outline' = 'hdr_off_select_outline',
  'hdr_on' = 'hdr_on',
  'hdr_on_select' = 'hdr_on_select',
  'hdr_on_select_outline' = 'hdr_on_select_outline',
  'hdr_plus' = 'hdr_plus',
  'hdr_plus_outline' = 'hdr_plus_outline',
  'hdr_plus_off' = 'hdr_plus_off',
  'hdr_plus_off_outline' = 'hdr_plus_off_outline',
  'hdr_strong' = 'hdr_strong',
  'hdr_weak' = 'hdr_weak',
  'head_mounted_device' = 'head_mounted_device',
  'head_mounted_device_outline' = 'head_mounted_device_outline',
  'headphones' = 'headphones',
  'headphones_outline' = 'headphones_outline',
  'headphones_battery' = 'headphones_battery',
  'headphones_battery_outline' = 'headphones_battery_outline',
  'headset_mic' = 'headset_mic',
  'headset_mic_outline' = 'headset_mic_outline',
  'headset_off' = 'headset_off',
  'headset_off_outline' = 'headset_off_outline',
  'healing' = 'healing',
  'healing_outline' = 'healing_outline',
  'health_and_beauty' = 'health_and_beauty',
  'health_and_beauty_outline' = 'health_and_beauty_outline',
  'health_and_safety' = 'health_and_safety',
  'health_and_safety_outline' = 'health_and_safety_outline',
  'health_metrics' = 'health_metrics',
  'health_metrics_outline' = 'health_metrics_outline',
  'heap_snapshot_large' = 'heap_snapshot_large',
  'heap_snapshot_large_outline' = 'heap_snapshot_large_outline',
  'heap_snapshot_multiple' = 'heap_snapshot_multiple',
  'heap_snapshot_multiple_outline' = 'heap_snapshot_multiple_outline',
  'heap_snapshot_thumbnail' = 'heap_snapshot_thumbnail',
  'heap_snapshot_thumbnail_outline' = 'heap_snapshot_thumbnail_outline',
  'hearing' = 'hearing',
  'hearing_disabled' = 'hearing_disabled',
  'heart_broken' = 'heart_broken',
  'heart_broken_outline' = 'heart_broken_outline',
  'heart_check' = 'heart_check',
  'heart_check_outline' = 'heart_check_outline',
  'heart_minus' = 'heart_minus',
  'heart_minus_outline' = 'heart_minus_outline',
  'heart_plus' = 'heart_plus',
  'heart_plus_outline' = 'heart_plus_outline',
  'heat' = 'heat',
  'heat_pump' = 'heat_pump',
  'heat_pump_outline' = 'heat_pump_outline',
  'heat_pump_balance' = 'heat_pump_balance',
  'heat_pump_balance_outline' = 'heat_pump_balance_outline',
  'height' = 'height',
  'helicopter' = 'helicopter',
  'helicopter_outline' = 'helicopter_outline',
  'help' = 'help',
  'help_outline' = 'help_outline',
  'help_center' = 'help_center',
  'help_center_outline' = 'help_center_outline',
  'help_clinic' = 'help_clinic',
  'help_clinic_outline' = 'help_clinic_outline',
  'hematology' = 'hematology',
  'hematology_outline' = 'hematology_outline',
  'hevc' = 'hevc',
  'hexagon' = 'hexagon',
  'hexagon_outline' = 'hexagon_outline',
  'hide' = 'hide',
  'hide_image' = 'hide_image',
  'hide_image_outline' = 'hide_image_outline',
  'hide_source' = 'hide_source',
  'hide_source_outline' = 'hide_source_outline',
  'high_density' = 'high_density',
  'high_density_outline' = 'high_density_outline',
  'high_quality' = 'high_quality',
  'high_quality_outline' = 'high_quality_outline',
  'high_res' = 'high_res',
  'high_res_outline' = 'high_res_outline',
  'highlight' = 'highlight',
  'highlight_outline' = 'highlight_outline',
  'highlight_keyboard_focus' = 'highlight_keyboard_focus',
  'highlight_keyboard_focus_outline' = 'highlight_keyboard_focus_outline',
  'highlight_mouse_cursor' = 'highlight_mouse_cursor',
  'highlight_text_cursor' = 'highlight_text_cursor',
  'highlight_text_cursor_outline' = 'highlight_text_cursor_outline',
  'highlighter_size_1' = 'highlighter_size_1',
  'highlighter_size_2' = 'highlighter_size_2',
  'highlighter_size_3' = 'highlighter_size_3',
  'highlighter_size_4' = 'highlighter_size_4',
  'highlighter_size_5' = 'highlighter_size_5',
  'hiking' = 'hiking',
  'history' = 'history',
  'history_edu' = 'history_edu',
  'history_edu_outline' = 'history_edu_outline',
  'history_off' = 'history_off',
  'history_toggle_off' = 'history_toggle_off',
  'hive' = 'hive',
  'hive_outline' = 'hive_outline',
  'hls' = 'hls',
  'hls_off' = 'hls_off',
  'holiday_village' = 'holiday_village',
  'holiday_village_outline' = 'holiday_village_outline',
  'home' = 'home',
  'home_outline' = 'home_outline',
  'home_and_garden' = 'home_and_garden',
  'home_and_garden_outline' = 'home_and_garden_outline',
  'home_app_logo' = 'home_app_logo',
  'home_health' = 'home_health',
  'home_health_outline' = 'home_health_outline',
  'home_improvement_and_tools' = 'home_improvement_and_tools',
  'home_improvement_and_tools_outline' = 'home_improvement_and_tools_outline',
  'home_iot_device' = 'home_iot_device',
  'home_iot_device_outline' = 'home_iot_device_outline',
  'home_max' = 'home_max',
  'home_max_outline' = 'home_max_outline',
  'home_max_dots' = 'home_max_dots',
  'home_max_dots_outline' = 'home_max_dots_outline',
  'home_mini' = 'home_mini',
  'home_mini_outline' = 'home_mini_outline',
  'home_pin' = 'home_pin',
  'home_pin_outline' = 'home_pin_outline',
  'home_repair_service' = 'home_repair_service',
  'home_repair_service_outline' = 'home_repair_service_outline',
  'home_speaker' = 'home_speaker',
  'home_speaker_outline' = 'home_speaker_outline',
  'home_storage' = 'home_storage',
  'home_storage_outline' = 'home_storage_outline',
  'home_work' = 'home_work',
  'home_work_outline' = 'home_work_outline',
  'horizontal_distribute' = 'horizontal_distribute',
  'horizontal_rule' = 'horizontal_rule',
  'horizontal_split' = 'horizontal_split',
  'hot_tub' = 'hot_tub',
  'hotel' = 'hotel',
  'hotel_outline' = 'hotel_outline',
  'hotel_class' = 'hotel_class',
  'hotel_class_outline' = 'hotel_class_outline',
  'hourglass' = 'hourglass',
  'hourglass_outline' = 'hourglass_outline',
  'hourglass_bottom' = 'hourglass_bottom',
  'hourglass_disabled' = 'hourglass_disabled',
  'hourglass_empty' = 'hourglass_empty',
  'hourglass_full' = 'hourglass_full',
  'hourglass_top' = 'hourglass_top',
  'house' = 'house',
  'house_outline' = 'house_outline',
  'house_siding' = 'house_siding',
  'house_with_shield' = 'house_with_shield',
  'house_with_shield_outline' = 'house_with_shield_outline',
  'houseboat' = 'houseboat',
  'houseboat_outline' = 'houseboat_outline',
  'household_supplies' = 'household_supplies',
  'household_supplies_outline' = 'household_supplies_outline',
  'how_to_reg' = 'how_to_reg',
  'how_to_reg_outline' = 'how_to_reg_outline',
  'how_to_vote' = 'how_to_vote',
  'how_to_vote_outline' = 'how_to_vote_outline',
  'hr_resting' = 'hr_resting',
  'hr_resting_outline' = 'hr_resting_outline',
  'html' = 'html',
  'http' = 'http',
  'hub' = 'hub',
  'hub_outline' = 'hub_outline',
  'humerus' = 'humerus',
  'humerus_outline' = 'humerus_outline',
  'humerus_alt' = 'humerus_alt',
  'humerus_alt_outline' = 'humerus_alt_outline',
  'humidity_helper' = 'humidity_helper',
  'humidity_helper_outline' = 'humidity_helper_outline',
  'humidity_high' = 'humidity_high',
  'humidity_indoor' = 'humidity_indoor',
  'humidity_indoor_outline' = 'humidity_indoor_outline',
  'humidity_low' = 'humidity_low',
  'humidity_mid' = 'humidity_mid',
  'humidity_percentage' = 'humidity_percentage',
  'humidity_percentage_outline' = 'humidity_percentage_outline',
  'hvac' = 'hvac',
  'hvac_outline' = 'hvac_outline',
  'ice_skating' = 'ice_skating',
  'ice_skating_outline' = 'ice_skating_outline',
  'icecream' = 'icecream',
  'icecream_outline' = 'icecream_outline',
  'id_card' = 'id_card',
  'id_card_outline' = 'id_card_outline',
  'ifl' = 'ifl',
  'ifl_outline' = 'ifl_outline',
  'iframe' = 'iframe',
  'iframe_outline' = 'iframe_outline',
  'iframe_off' = 'iframe_off',
  'iframe_off_outline' = 'iframe_off_outline',
  'image' = 'image',
  'image_outline' = 'image_outline',
  'image_aspect_ratio' = 'image_aspect_ratio',
  'image_aspect_ratio_outline' = 'image_aspect_ratio_outline',
  'image_not_supported' = 'image_not_supported',
  'image_not_supported_outline' = 'image_not_supported_outline',
  'image_search' = 'image_search',
  'imagesearch_roller' = 'imagesearch_roller',
  'imagesearch_roller_outline' = 'imagesearch_roller_outline',
  'imagesmode' = 'imagesmode',
  'imagesmode_outline' = 'imagesmode_outline',
  'immunology' = 'immunology',
  'immunology_outline' = 'immunology_outline',
  'import_contacts' = 'import_contacts',
  'import_contacts_outline' = 'import_contacts_outline',
  'important_devices' = 'important_devices',
  'important_devices_outline' = 'important_devices_outline',
  'in_home_mode' = 'in_home_mode',
  'in_home_mode_outline' = 'in_home_mode_outline',
  'inactive_order' = 'inactive_order',
  'inactive_order_outline' = 'inactive_order_outline',
  'inbox' = 'inbox',
  'inbox_outline' = 'inbox_outline',
  'inbox_customize' = 'inbox_customize',
  'inbox_customize_outline' = 'inbox_customize_outline',
  'incomplete_circle' = 'incomplete_circle',
  'indeterminate_question_box' = 'indeterminate_question_box',
  'info' = 'info',
  'info_outline' = 'info_outline',
  'info_i' = 'info_i',
  'infrared' = 'infrared',
  'ink_eraser' = 'ink_eraser',
  'ink_eraser_outline' = 'ink_eraser_outline',
  'ink_eraser_off' = 'ink_eraser_off',
  'ink_eraser_off_outline' = 'ink_eraser_off_outline',
  'ink_highlighter' = 'ink_highlighter',
  'ink_highlighter_outline' = 'ink_highlighter_outline',
  'ink_highlighter_move' = 'ink_highlighter_move',
  'ink_highlighter_move_outline' = 'ink_highlighter_move_outline',
  'ink_marker' = 'ink_marker',
  'ink_pen' = 'ink_pen',
  'ink_pen_outline' = 'ink_pen_outline',
  'inpatient' = 'inpatient',
  'inpatient_outline' = 'inpatient_outline',
  'input' = 'input',
  'input_circle' = 'input_circle',
  'insert_chart' = 'insert_chart',
  'insert_chart_outline' = 'insert_chart_outline',
  'insert_page_break' = 'insert_page_break',
  'insert_page_break_outline' = 'insert_page_break_outline',
  'insert_text' = 'insert_text',
  'insert_text_outline' = 'insert_text_outline',
  'insights' = 'insights',
  'install_desktop' = 'install_desktop',
  'install_mobile' = 'install_mobile',
  'install_mobile_outline' = 'install_mobile_outline',
  'instant_mix' = 'instant_mix',
  'integration_instructions' = 'integration_instructions',
  'integration_instructions_outline' = 'integration_instructions_outline',
  'interactive_space' = 'interactive_space',
  'interactive_space_outline' = 'interactive_space_outline',
  'interests' = 'interests',
  'interests_outline' = 'interests_outline',
  'interpreter_mode' = 'interpreter_mode',
  'interpreter_mode_outline' = 'interpreter_mode_outline',
  'inventory' = 'inventory',
  'inventory_2' = 'inventory_2',
  'inventory_2_outline' = 'inventory_2_outline',
  'invert_colors' = 'invert_colors',
  'invert_colors_outline' = 'invert_colors_outline',
  'invert_colors_off' = 'invert_colors_off',
  'ios' = 'ios',
  'ios_share' = 'ios_share',
  'iron' = 'iron',
  'iron_outline' = 'iron_outline',
  'jamboard_kiosk' = 'jamboard_kiosk',
  'jamboard_kiosk_outline' = 'jamboard_kiosk_outline',
  'javascript' = 'javascript',
  'join' = 'join',
  'join_outline' = 'join_outline',
  'join_full' = 'join_full',
  'join_inner' = 'join_inner',
  'join_left' = 'join_left',
  'join_right' = 'join_right',
  'joystick' = 'joystick',
  'joystick_outline' = 'joystick_outline',
  'jump_to_element' = 'jump_to_element',
  'kayaking' = 'kayaking',
  'kebab_dining' = 'kebab_dining',
  'kebab_dining_outline' = 'kebab_dining_outline',
  'keep' = 'keep',
  'keep_outline' = 'keep_outline',
  'keep_off' = 'keep_off',
  'keep_off_outline' = 'keep_off_outline',
  'keep_public' = 'keep_public',
  'keep_public_outline' = 'keep_public_outline',
  'kettle' = 'kettle',
  'kettle_outline' = 'kettle_outline',
  'key' = 'key',
  'key_outline' = 'key_outline',
  'key_off' = 'key_off',
  'key_off_outline' = 'key_off_outline',
  'key_vertical' = 'key_vertical',
  'key_vertical_outline' = 'key_vertical_outline',
  'key_visualizer' = 'key_visualizer',
  'keyboard' = 'keyboard',
  'keyboard_outline' = 'keyboard_outline',
  'keyboard_alt' = 'keyboard_alt',
  'keyboard_alt_outline' = 'keyboard_alt_outline',
  'keyboard_arrow_down' = 'keyboard_arrow_down',
  'keyboard_arrow_left' = 'keyboard_arrow_left',
  'keyboard_arrow_right' = 'keyboard_arrow_right',
  'keyboard_arrow_up' = 'keyboard_arrow_up',
  'keyboard_backspace' = 'keyboard_backspace',
  'keyboard_capslock' = 'keyboard_capslock',
  'keyboard_capslock_badge' = 'keyboard_capslock_badge',
  'keyboard_capslock_badge_outline' = 'keyboard_capslock_badge_outline',
  'keyboard_command_key' = 'keyboard_command_key',
  'keyboard_control_key' = 'keyboard_control_key',
  'keyboard_double_arrow_down' = 'keyboard_double_arrow_down',
  'keyboard_double_arrow_left' = 'keyboard_double_arrow_left',
  'keyboard_double_arrow_right' = 'keyboard_double_arrow_right',
  'keyboard_double_arrow_up' = 'keyboard_double_arrow_up',
  'keyboard_external_input' = 'keyboard_external_input',
  'keyboard_external_input_outline' = 'keyboard_external_input_outline',
  'keyboard_full' = 'keyboard_full',
  'keyboard_hide' = 'keyboard_hide',
  'keyboard_hide_outline' = 'keyboard_hide_outline',
  'keyboard_keys' = 'keyboard_keys',
  'keyboard_lock' = 'keyboard_lock',
  'keyboard_lock_outline' = 'keyboard_lock_outline',
  'keyboard_lock_off' = 'keyboard_lock_off',
  'keyboard_lock_off_outline' = 'keyboard_lock_off_outline',
  'keyboard_off' = 'keyboard_off',
  'keyboard_off_outline' = 'keyboard_off_outline',
  'keyboard_onscreen' = 'keyboard_onscreen',
  'keyboard_onscreen_outline' = 'keyboard_onscreen_outline',
  'keyboard_option_key' = 'keyboard_option_key',
  'keyboard_previous_language' = 'keyboard_previous_language',
  'keyboard_previous_language_outline' = 'keyboard_previous_language_outline',
  'keyboard_return' = 'keyboard_return',
  'keyboard_tab' = 'keyboard_tab',
  'keyboard_tab_rtl' = 'keyboard_tab_rtl',
  'keyboard_voice' = 'keyboard_voice',
  'keyboard_voice_outline' = 'keyboard_voice_outline',
  'kid_star' = 'kid_star',
  'kid_star_outline' = 'kid_star_outline',
  'king_bed' = 'king_bed',
  'king_bed_outline' = 'king_bed_outline',
  'kitchen' = 'kitchen',
  'kitchen_outline' = 'kitchen_outline',
  'kitesurfing' = 'kitesurfing',
  'kitesurfing_outline' = 'kitesurfing_outline',
  'lab_panel' = 'lab_panel',
  'lab_panel_outline' = 'lab_panel_outline',
  'lab_profile' = 'lab_profile',
  'lab_profile_outline' = 'lab_profile_outline',
  'lab_research' = 'lab_research',
  'lab_research_outline' = 'lab_research_outline',
  'label' = 'label',
  'label_outline' = 'label_outline',
  'label_important' = 'label_important',
  'label_important_outline' = 'label_important_outline',
  'label_off' = 'label_off',
  'label_off_outline' = 'label_off_outline',
  'labs' = 'labs',
  'labs_outline' = 'labs_outline',
  'lan' = 'lan',
  'lan_outline' = 'lan_outline',
  'landscape' = 'landscape',
  'landscape_outline' = 'landscape_outline',
  'landscape_2' = 'landscape_2',
  'landscape_2_outline' = 'landscape_2_outline',
  'landscape_2_off' = 'landscape_2_off',
  'landscape_2_off_outline' = 'landscape_2_off_outline',
  'landslide' = 'landslide',
  'landslide_outline' = 'landslide_outline',
  'language' = 'language',
  'language_chinese_array' = 'language_chinese_array',
  'language_chinese_cangjie' = 'language_chinese_cangjie',
  'language_chinese_dayi' = 'language_chinese_dayi',
  'language_chinese_pinyin' = 'language_chinese_pinyin',
  'language_chinese_quick' = 'language_chinese_quick',
  'language_chinese_wubi' = 'language_chinese_wubi',
  'language_french' = 'language_french',
  'language_gb_english' = 'language_gb_english',
  'language_international' = 'language_international',
  'language_japanese_kana' = 'language_japanese_kana',
  'language_korean_latin' = 'language_korean_latin',
  'language_pinyin' = 'language_pinyin',
  'language_spanish' = 'language_spanish',
  'language_us' = 'language_us',
  'language_us_colemak' = 'language_us_colemak',
  'language_us_dvorak' = 'language_us_dvorak',
  'laps' = 'laps',
  'laptop_chromebook' = 'laptop_chromebook',
  'laptop_chromebook_outline' = 'laptop_chromebook_outline',
  'laptop_mac' = 'laptop_mac',
  'laptop_mac_outline' = 'laptop_mac_outline',
  'laptop_windows' = 'laptop_windows',
  'laptop_windows_outline' = 'laptop_windows_outline',
  'lasso_select' = 'lasso_select',
  'last_page' = 'last_page',
  'laundry' = 'laundry',
  'laundry_outline' = 'laundry_outline',
  'layers' = 'layers',
  'layers_outline' = 'layers_outline',
  'layers_clear' = 'layers_clear',
  'layers_clear_outline' = 'layers_clear_outline',
  'lda' = 'lda',
  'lda_outline' = 'lda_outline',
  'leaderboard' = 'leaderboard',
  'leaderboard_outline' = 'leaderboard_outline',
  'leaf_spark' = 'leaf_spark',
  'leaf_spark_outline' = 'leaf_spark_outline',
  'leak_add' = 'leak_add',
  'leak_remove' = 'leak_remove',
  'left_click' = 'left_click',
  'left_panel_close' = 'left_panel_close',
  'left_panel_close_outline' = 'left_panel_close_outline',
  'left_panel_open' = 'left_panel_open',
  'left_panel_open_outline' = 'left_panel_open_outline',
  'legend_toggle' = 'legend_toggle',
  'letter_switch' = 'letter_switch',
  'library_add' = 'library_add',
  'library_add_outline' = 'library_add_outline',
  'library_add_check' = 'library_add_check',
  'library_add_check_outline' = 'library_add_check_outline',
  'library_books' = 'library_books',
  'library_books_outline' = 'library_books_outline',
  'library_music' = 'library_music',
  'library_music_outline' = 'library_music_outline',
  'license' = 'license',
  'license_outline' = 'license_outline',
  'lift_to_talk' = 'lift_to_talk',
  'light' = 'light',
  'light_outline' = 'light_outline',
  'light_group' = 'light_group',
  'light_group_outline' = 'light_group_outline',
  'light_mode' = 'light_mode',
  'light_mode_outline' = 'light_mode_outline',
  'light_off' = 'light_off',
  'light_off_outline' = 'light_off_outline',
  'lightbulb' = 'lightbulb',
  'lightbulb_outline' = 'lightbulb_outline',
  'lightbulb_circle' = 'lightbulb_circle',
  'lightbulb_circle_outline' = 'lightbulb_circle_outline',
  'lightning_stand' = 'lightning_stand',
  'lightning_stand_outline' = 'lightning_stand_outline',
  'line_axis' = 'line_axis',
  'line_curve' = 'line_curve',
  'line_end' = 'line_end',
  'line_end_arrow' = 'line_end_arrow',
  'line_end_arrow_outline' = 'line_end_arrow_outline',
  'line_end_arrow_notch' = 'line_end_arrow_notch',
  'line_end_circle' = 'line_end_circle',
  'line_end_circle_outline' = 'line_end_circle_outline',
  'line_end_diamond' = 'line_end_diamond',
  'line_end_diamond_outline' = 'line_end_diamond_outline',
  'line_end_square' = 'line_end_square',
  'line_end_square_outline' = 'line_end_square_outline',
  'line_start' = 'line_start',
  'line_start_arrow' = 'line_start_arrow',
  'line_start_arrow_outline' = 'line_start_arrow_outline',
  'line_start_arrow_notch' = 'line_start_arrow_notch',
  'line_start_circle' = 'line_start_circle',
  'line_start_circle_outline' = 'line_start_circle_outline',
  'line_start_diamond' = 'line_start_diamond',
  'line_start_diamond_outline' = 'line_start_diamond_outline',
  'line_start_square' = 'line_start_square',
  'line_start_square_outline' = 'line_start_square_outline',
  'line_style' = 'line_style',
  'line_weight' = 'line_weight',
  'linear_scale' = 'linear_scale',
  'link' = 'link',
  'link_off' = 'link_off',
  'linked_camera' = 'linked_camera',
  'linked_camera_outline' = 'linked_camera_outline',
  'linked_services' = 'linked_services',
  'linked_services_outline' = 'linked_services_outline',
  'liquor' = 'liquor',
  'liquor_outline' = 'liquor_outline',
  'list' = 'list',
  'list_alt' = 'list_alt',
  'list_alt_outline' = 'list_alt_outline',
  'list_alt_add' = 'list_alt_add',
  'list_alt_add_outline' = 'list_alt_add_outline',
  'lists' = 'lists',
  'live_help' = 'live_help',
  'live_help_outline' = 'live_help_outline',
  'live_tv' = 'live_tv',
  'live_tv_outline' = 'live_tv_outline',
  'living' = 'living',
  'living_outline' = 'living_outline',
  'local_activity' = 'local_activity',
  'local_activity_outline' = 'local_activity_outline',
  'local_atm' = 'local_atm',
  'local_atm_outline' = 'local_atm_outline',
  'local_bar' = 'local_bar',
  'local_bar_outline' = 'local_bar_outline',
  'local_cafe' = 'local_cafe',
  'local_cafe_outline' = 'local_cafe_outline',
  'local_car_wash' = 'local_car_wash',
  'local_car_wash_outline' = 'local_car_wash_outline',
  'local_convenience_store' = 'local_convenience_store',
  'local_convenience_store_outline' = 'local_convenience_store_outline',
  'local_dining' = 'local_dining',
  'local_drink' = 'local_drink',
  'local_drink_outline' = 'local_drink_outline',
  'local_fire_department' = 'local_fire_department',
  'local_fire_department_outline' = 'local_fire_department_outline',
  'local_florist' = 'local_florist',
  'local_florist_outline' = 'local_florist_outline',
  'local_gas_station' = 'local_gas_station',
  'local_gas_station_outline' = 'local_gas_station_outline',
  'local_hospital' = 'local_hospital',
  'local_hospital_outline' = 'local_hospital_outline',
  'local_laundry_service' = 'local_laundry_service',
  'local_laundry_service_outline' = 'local_laundry_service_outline',
  'local_library' = 'local_library',
  'local_library_outline' = 'local_library_outline',
  'local_mall' = 'local_mall',
  'local_mall_outline' = 'local_mall_outline',
  'local_parking' = 'local_parking',
  'local_pharmacy' = 'local_pharmacy',
  'local_pharmacy_outline' = 'local_pharmacy_outline',
  'local_pizza' = 'local_pizza',
  'local_pizza_outline' = 'local_pizza_outline',
  'local_police' = 'local_police',
  'local_police_outline' = 'local_police_outline',
  'local_post_office' = 'local_post_office',
  'local_post_office_outline' = 'local_post_office_outline',
  'local_see' = 'local_see',
  'local_see_outline' = 'local_see_outline',
  'local_shipping' = 'local_shipping',
  'local_shipping_outline' = 'local_shipping_outline',
  'local_taxi' = 'local_taxi',
  'local_taxi_outline' = 'local_taxi_outline',
  'location_automation' = 'location_automation',
  'location_away' = 'location_away',
  'location_away_outline' = 'location_away_outline',
  'location_chip' = 'location_chip',
  'location_chip_outline' = 'location_chip_outline',
  'location_city' = 'location_city',
  'location_disabled' = 'location_disabled',
  'location_home' = 'location_home',
  'location_home_outline' = 'location_home_outline',
  'location_off' = 'location_off',
  'location_off_outline' = 'location_off_outline',
  'location_on' = 'location_on',
  'location_on_outline' = 'location_on_outline',
  'location_searching' = 'location_searching',
  'lock' = 'lock',
  'lock_outline' = 'lock_outline',
  'lock_clock' = 'lock_clock',
  'lock_clock_outline' = 'lock_clock_outline',
  'lock_open' = 'lock_open',
  'lock_open_outline' = 'lock_open_outline',
  'lock_open_right' = 'lock_open_right',
  'lock_open_right_outline' = 'lock_open_right_outline',
  'lock_person' = 'lock_person',
  'lock_person_outline' = 'lock_person_outline',
  'lock_reset' = 'lock_reset',
  'login' = 'login',
  'logo_dev' = 'logo_dev',
  'logo_dev_outline' = 'logo_dev_outline',
  'logout' = 'logout',
  'looks' = 'looks',
  'looks_3' = 'looks_3',
  'looks_3_outline' = 'looks_3_outline',
  'looks_4' = 'looks_4',
  'looks_4_outline' = 'looks_4_outline',
  'looks_5' = 'looks_5',
  'looks_5_outline' = 'looks_5_outline',
  'looks_6' = 'looks_6',
  'looks_6_outline' = 'looks_6_outline',
  'looks_one' = 'looks_one',
  'looks_one_outline' = 'looks_one_outline',
  'looks_two' = 'looks_two',
  'looks_two_outline' = 'looks_two_outline',
  'loupe' = 'loupe',
  'loupe_outline' = 'loupe_outline',
  'low_density' = 'low_density',
  'low_priority' = 'low_priority',
  'lowercase' = 'lowercase',
  'loyalty' = 'loyalty',
  'loyalty_outline' = 'loyalty_outline',
  'lte_mobiledata' = 'lte_mobiledata',
  'lte_mobiledata_badge' = 'lte_mobiledata_badge',
  'lte_mobiledata_badge_outline' = 'lte_mobiledata_badge_outline',
  'lte_plus_mobiledata' = 'lte_plus_mobiledata',
  'lte_plus_mobiledata_badge' = 'lte_plus_mobiledata_badge',
  'lte_plus_mobiledata_badge_outline' = 'lte_plus_mobiledata_badge_outline',
  'luggage' = 'luggage',
  'luggage_outline' = 'luggage_outline',
  'lunch_dining' = 'lunch_dining',
  'lunch_dining_outline' = 'lunch_dining_outline',
  'lyrics' = 'lyrics',
  'lyrics_outline' = 'lyrics_outline',
  'macro_auto' = 'macro_auto',
  'macro_auto_outline' = 'macro_auto_outline',
  'macro_off' = 'macro_off',
  'macro_off_outline' = 'macro_off_outline',
  'magic_button' = 'magic_button',
  'magic_exchange' = 'magic_exchange',
  'magic_tether' = 'magic_tether',
  'magnification_large' = 'magnification_large',
  'magnification_large_outline' = 'magnification_large_outline',
  'magnification_small' = 'magnification_small',
  'magnification_small_outline' = 'magnification_small_outline',
  'magnify_docked' = 'magnify_docked',
  'magnify_fullscreen' = 'magnify_fullscreen',
  'magnify_fullscreen_outline' = 'magnify_fullscreen_outline',
  'mail' = 'mail',
  'mail_outline' = 'mail_outline',
  'mail_lock' = 'mail_lock',
  'mail_lock_outline' = 'mail_lock_outline',
  'mail_off' = 'mail_off',
  'mail_off_outline' = 'mail_off_outline',
  'male' = 'male',
  'man' = 'man',
  'man_2' = 'man_2',
  'man_3' = 'man_3',
  'man_4' = 'man_4',
  'manage_accounts' = 'manage_accounts',
  'manage_accounts_outline' = 'manage_accounts_outline',
  'manage_history' = 'manage_history',
  'manage_search' = 'manage_search',
  'manga' = 'manga',
  'manga_outline' = 'manga_outline',
  'manufacturing' = 'manufacturing',
  'map' = 'map',
  'map_outline' = 'map_outline',
  'maps_ugc' = 'maps_ugc',
  'maps_ugc_outline' = 'maps_ugc_outline',
  'margin' = 'margin',
  'margin_outline' = 'margin_outline',
  'mark_as_unread' = 'mark_as_unread',
  'mark_as_unread_outline' = 'mark_as_unread_outline',
  'mark_chat_read' = 'mark_chat_read',
  'mark_chat_read_outline' = 'mark_chat_read_outline',
  'mark_chat_unread' = 'mark_chat_unread',
  'mark_chat_unread_outline' = 'mark_chat_unread_outline',
  'mark_email_read' = 'mark_email_read',
  'mark_email_read_outline' = 'mark_email_read_outline',
  'mark_email_unread' = 'mark_email_unread',
  'mark_email_unread_outline' = 'mark_email_unread_outline',
  'mark_unread_chat_alt' = 'mark_unread_chat_alt',
  'mark_unread_chat_alt_outline' = 'mark_unread_chat_alt_outline',
  'markdown' = 'markdown',
  'markdown_outline' = 'markdown_outline',
  'markdown_copy' = 'markdown_copy',
  'markdown_copy_outline' = 'markdown_copy_outline',
  'markdown_paste' = 'markdown_paste',
  'markunread_mailbox' = 'markunread_mailbox',
  'markunread_mailbox_outline' = 'markunread_mailbox_outline',
  'masked_transitions' = 'masked_transitions',
  'masks' = 'masks',
  'masks_outline' = 'masks_outline',
  'match_case' = 'match_case',
  'match_word' = 'match_word',
  'matter' = 'matter',
  'maximize' = 'maximize',
  'measuring_tape' = 'measuring_tape',
  'measuring_tape_outline' = 'measuring_tape_outline',
  'media_bluetooth_off' = 'media_bluetooth_off',
  'media_bluetooth_on' = 'media_bluetooth_on',
  'media_link' = 'media_link',
  'media_link_outline' = 'media_link_outline',
  'media_output' = 'media_output',
  'media_output_outline' = 'media_output_outline',
  'media_output_off' = 'media_output_off',
  'media_output_off_outline' = 'media_output_off_outline',
  'mediation' = 'mediation',
  'medical_information' = 'medical_information',
  'medical_information_outline' = 'medical_information_outline',
  'medical_mask' = 'medical_mask',
  'medical_mask_outline' = 'medical_mask_outline',
  'medical_services' = 'medical_services',
  'medical_services_outline' = 'medical_services_outline',
  'medication' = 'medication',
  'medication_outline' = 'medication_outline',
  'medication_liquid' = 'medication_liquid',
  'medication_liquid_outline' = 'medication_liquid_outline',
  'meeting_room' = 'meeting_room',
  'meeting_room_outline' = 'meeting_room_outline',
  'memory' = 'memory',
  'memory_outline' = 'memory_outline',
  'memory_alt' = 'memory_alt',
  'memory_alt_outline' = 'memory_alt_outline',
  'menstrual_health' = 'menstrual_health',
  'menstrual_health_outline' = 'menstrual_health_outline',
  'menu' = 'menu',
  'menu_book' = 'menu_book',
  'menu_book_outline' = 'menu_book_outline',
  'menu_open' = 'menu_open',
  'merge' = 'merge',
  'merge_type' = 'merge_type',
  'metabolism' = 'metabolism',
  'mfg_nest_yale_lock' = 'mfg_nest_yale_lock',
  'mfg_nest_yale_lock_outline' = 'mfg_nest_yale_lock_outline',
  'mic' = 'mic',
  'mic_outline' = 'mic_outline',
  'mic_double' = 'mic_double',
  'mic_double_outline' = 'mic_double_outline',
  'mic_external_off' = 'mic_external_off',
  'mic_external_off_outline' = 'mic_external_off_outline',
  'mic_external_on' = 'mic_external_on',
  'mic_external_on_outline' = 'mic_external_on_outline',
  'mic_noise_cancel_high' = 'mic_noise_cancel_high',
  'mic_noise_cancel_low' = 'mic_noise_cancel_low',
  'mic_noise_cancel_off' = 'mic_noise_cancel_off',
  'mic_noise_cancel_off_outline' = 'mic_noise_cancel_off_outline',
  'mic_off' = 'mic_off',
  'mic_off_outline' = 'mic_off_outline',
  'microbiology' = 'microbiology',
  'microbiology_outline' = 'microbiology_outline',
  'microwave' = 'microwave',
  'microwave_outline' = 'microwave_outline',
  'microwave_gen' = 'microwave_gen',
  'microwave_gen_outline' = 'microwave_gen_outline',
  'military_tech' = 'military_tech',
  'military_tech_outline' = 'military_tech_outline',
  'mimo' = 'mimo',
  'mimo_outline' = 'mimo_outline',
  'mimo_disconnect' = 'mimo_disconnect',
  'mimo_disconnect_outline' = 'mimo_disconnect_outline',
  'mindfulness' = 'mindfulness',
  'mindfulness_outline' = 'mindfulness_outline',
  'minimize' = 'minimize',
  'minor_crash' = 'minor_crash',
  'minor_crash_outline' = 'minor_crash_outline',
  'mintmark' = 'mintmark',
  'missed_video_call' = 'missed_video_call',
  'missed_video_call_outline' = 'missed_video_call_outline',
  'missing_controller' = 'missing_controller',
  'mist' = 'mist',
  'mitre' = 'mitre',
  'mitre_outline' = 'mitre_outline',
  'mixture_med' = 'mixture_med',
  'mixture_med_outline' = 'mixture_med_outline',
  'mms' = 'mms',
  'mms_outline' = 'mms_outline',
  'mobile_friendly' = 'mobile_friendly',
  'mobile_friendly_outline' = 'mobile_friendly_outline',
  'mobile_off' = 'mobile_off',
  'mobile_off_outline' = 'mobile_off_outline',
  'mobile_screen_share' = 'mobile_screen_share',
  'mobile_screen_share_outline' = 'mobile_screen_share_outline',
  'mobiledata_off' = 'mobiledata_off',
  'mode_comment' = 'mode_comment',
  'mode_comment_outline' = 'mode_comment_outline',
  'mode_cool_off' = 'mode_cool_off',
  'mode_dual' = 'mode_dual',
  'mode_fan' = 'mode_fan',
  'mode_fan_outline' = 'mode_fan_outline',
  'mode_fan_off' = 'mode_fan_off',
  'mode_fan_off_outline' = 'mode_fan_off_outline',
  'mode_heat_cool' = 'mode_heat_cool',
  'mode_heat_cool_outline' = 'mode_heat_cool_outline',
  'mode_heat_off' = 'mode_heat_off',
  'mode_heat_off_outline' = 'mode_heat_off_outline',
  'mode_night' = 'mode_night',
  'mode_night_outline' = 'mode_night_outline',
  'mode_of_travel' = 'mode_of_travel',
  'mode_off_on' = 'mode_off_on',
  'model_training' = 'model_training',
  'model_training_outline' = 'model_training_outline',
  'monetization_on' = 'monetization_on',
  'monetization_on_outline' = 'monetization_on_outline',
  'money' = 'money',
  'money_outline' = 'money_outline',
  'money_off' = 'money_off',
  'monitor_heart' = 'monitor_heart',
  'monitor_heart_outline' = 'monitor_heart_outline',
  'monitor_weight' = 'monitor_weight',
  'monitor_weight_outline' = 'monitor_weight_outline',
  'monitor_weight_gain' = 'monitor_weight_gain',
  'monitor_weight_gain_outline' = 'monitor_weight_gain_outline',
  'monitor_weight_loss' = 'monitor_weight_loss',
  'monitor_weight_loss_outline' = 'monitor_weight_loss_outline',
  'monitoring' = 'monitoring',
  'monochrome_photos' = 'monochrome_photos',
  'mood' = 'mood',
  'mood_outline' = 'mood_outline',
  'mood_bad' = 'mood_bad',
  'mood_bad_outline' = 'mood_bad_outline',
  'mop' = 'mop',
  'mop_outline' = 'mop_outline',
  'more' = 'more',
  'more_outline' = 'more_outline',
  'more_down' = 'more_down',
  'more_horiz' = 'more_horiz',
  'more_time' = 'more_time',
  'more_up' = 'more_up',
  'more_vert' = 'more_vert',
  'mosque' = 'mosque',
  'mosque_outline' = 'mosque_outline',
  'motion_blur' = 'motion_blur',
  'motion_mode' = 'motion_mode',
  'motion_photos_auto' = 'motion_photos_auto',
  'motion_photos_auto_outline' = 'motion_photos_auto_outline',
  'motion_photos_on' = 'motion_photos_on',
  'motion_photos_on_outline' = 'motion_photos_on_outline',
  'motion_photos_paused' = 'motion_photos_paused',
  'motion_photos_paused_outline' = 'motion_photos_paused_outline',
  'motion_sensor_active' = 'motion_sensor_active',
  'motion_sensor_alert' = 'motion_sensor_alert',
  'motion_sensor_idle' = 'motion_sensor_idle',
  'motion_sensor_urgent' = 'motion_sensor_urgent',
  'motorcycle' = 'motorcycle',
  'motorcycle_outline' = 'motorcycle_outline',
  'mountain_flag' = 'mountain_flag',
  'mountain_flag_outline' = 'mountain_flag_outline',
  'mouse' = 'mouse',
  'mouse_outline' = 'mouse_outline',
  'mouse_lock' = 'mouse_lock',
  'mouse_lock_outline' = 'mouse_lock_outline',
  'mouse_lock_off' = 'mouse_lock_off',
  'mouse_lock_off_outline' = 'mouse_lock_off_outline',
  'move' = 'move',
  'move_outline' = 'move_outline',
  'move_down' = 'move_down',
  'move_group' = 'move_group',
  'move_item' = 'move_item',
  'move_location' = 'move_location',
  'move_location_outline' = 'move_location_outline',
  'move_selection_down' = 'move_selection_down',
  'move_selection_down_outline' = 'move_selection_down_outline',
  'move_selection_left' = 'move_selection_left',
  'move_selection_left_outline' = 'move_selection_left_outline',
  'move_selection_right' = 'move_selection_right',
  'move_selection_right_outline' = 'move_selection_right_outline',
  'move_selection_up' = 'move_selection_up',
  'move_selection_up_outline' = 'move_selection_up_outline',
  'move_to_inbox' = 'move_to_inbox',
  'move_to_inbox_outline' = 'move_to_inbox_outline',
  'move_up' = 'move_up',
  'moved_location' = 'moved_location',
  'movie' = 'movie',
  'movie_outline' = 'movie_outline',
  'movie_edit' = 'movie_edit',
  'movie_edit_outline' = 'movie_edit_outline',
  'movie_filter' = 'movie_filter',
  'movie_filter_outline' = 'movie_filter_outline',
  'movie_info' = 'movie_info',
  'movie_info_outline' = 'movie_info_outline',
  'movie_off' = 'movie_off',
  'movie_off_outline' = 'movie_off_outline',
  'moving' = 'moving',
  'moving_beds' = 'moving_beds',
  'moving_beds_outline' = 'moving_beds_outline',
  'moving_ministry' = 'moving_ministry',
  'moving_ministry_outline' = 'moving_ministry_outline',
  'mp' = 'mp',
  'mp_outline' = 'mp_outline',
  'multicooker' = 'multicooker',
  'multicooker_outline' = 'multicooker_outline',
  'multiline_chart' = 'multiline_chart',
  'multiple_stop' = 'multiple_stop',
  'museum' = 'museum',
  'museum_outline' = 'museum_outline',
  'music_cast' = 'music_cast',
  'music_note' = 'music_note',
  'music_off' = 'music_off',
  'music_video' = 'music_video',
  'music_video_outline' = 'music_video_outline',
  'my_location' = 'my_location',
  'my_location_outline' = 'my_location_outline',
  'mystery' = 'mystery',
  'mystery_outline' = 'mystery_outline',
  'nat' = 'nat',
  'nature' = 'nature',
  'nature_outline' = 'nature_outline',
  'nature_people' = 'nature_people',
  'nature_people_outline' = 'nature_people_outline',
  'navigation' = 'navigation',
  'navigation_outline' = 'navigation_outline',
  'near_me' = 'near_me',
  'near_me_outline' = 'near_me_outline',
  'near_me_disabled' = 'near_me_disabled',
  'near_me_disabled_outline' = 'near_me_disabled_outline',
  'nearby' = 'nearby',
  'nearby_error' = 'nearby_error',
  'nearby_off' = 'nearby_off',
  'nephrology' = 'nephrology',
  'nephrology_outline' = 'nephrology_outline',
  'nest_audio' = 'nest_audio',
  'nest_audio_outline' = 'nest_audio_outline',
  'nest_cam_floodlight' = 'nest_cam_floodlight',
  'nest_cam_floodlight_outline' = 'nest_cam_floodlight_outline',
  'nest_cam_indoor' = 'nest_cam_indoor',
  'nest_cam_iq' = 'nest_cam_iq',
  'nest_cam_iq_outdoor' = 'nest_cam_iq_outdoor',
  'nest_cam_iq_outdoor_outline' = 'nest_cam_iq_outdoor_outline',
  'nest_cam_magnet_mount' = 'nest_cam_magnet_mount',
  'nest_cam_magnet_mount_outline' = 'nest_cam_magnet_mount_outline',
  'nest_cam_outdoor' = 'nest_cam_outdoor',
  'nest_cam_outdoor_outline' = 'nest_cam_outdoor_outline',
  'nest_cam_stand' = 'nest_cam_stand',
  'nest_cam_stand_outline' = 'nest_cam_stand_outline',
  'nest_cam_wall_mount' = 'nest_cam_wall_mount',
  'nest_cam_wall_mount_outline' = 'nest_cam_wall_mount_outline',
  'nest_cam_wired_stand' = 'nest_cam_wired_stand',
  'nest_cam_wired_stand_outline' = 'nest_cam_wired_stand_outline',
  'nest_clock_farsight_analog' = 'nest_clock_farsight_analog',
  'nest_clock_farsight_analog_outline' = 'nest_clock_farsight_analog_outline',
  'nest_clock_farsight_digital' = 'nest_clock_farsight_digital',
  'nest_clock_farsight_digital_outline' = 'nest_clock_farsight_digital_outline',
  'nest_connect' = 'nest_connect',
  'nest_connect_outline' = 'nest_connect_outline',
  'nest_detect' = 'nest_detect',
  'nest_detect_outline' = 'nest_detect_outline',
  'nest_display' = 'nest_display',
  'nest_display_outline' = 'nest_display_outline',
  'nest_display_max' = 'nest_display_max',
  'nest_display_max_outline' = 'nest_display_max_outline',
  'nest_doorbell_visitor' = 'nest_doorbell_visitor',
  'nest_doorbell_visitor_outline' = 'nest_doorbell_visitor_outline',
  'nest_eco_leaf' = 'nest_eco_leaf',
  'nest_eco_leaf_outline' = 'nest_eco_leaf_outline',
  'nest_farsight_weather' = 'nest_farsight_weather',
  'nest_farsight_weather_outline' = 'nest_farsight_weather_outline',
  'nest_found_savings' = 'nest_found_savings',
  'nest_found_savings_outline' = 'nest_found_savings_outline',
  'nest_heat_link_e' = 'nest_heat_link_e',
  'nest_heat_link_gen_3' = 'nest_heat_link_gen_3',
  'nest_heat_link_gen_3_outline' = 'nest_heat_link_gen_3_outline',
  'nest_hello_doorbell' = 'nest_hello_doorbell',
  'nest_hello_doorbell_outline' = 'nest_hello_doorbell_outline',
  'nest_locator_tag' = 'nest_locator_tag',
  'nest_mini' = 'nest_mini',
  'nest_mini_outline' = 'nest_mini_outline',
  'nest_multi_room' = 'nest_multi_room',
  'nest_multi_room_outline' = 'nest_multi_room_outline',
  'nest_protect' = 'nest_protect',
  'nest_remote_comfort_sensor' = 'nest_remote_comfort_sensor',
  'nest_remote_comfort_sensor_outline' = 'nest_remote_comfort_sensor_outline',
  'nest_secure_alarm' = 'nest_secure_alarm',
  'nest_secure_alarm_outline' = 'nest_secure_alarm_outline',
  'nest_sunblock' = 'nest_sunblock',
  'nest_tag' = 'nest_tag',
  'nest_thermostat' = 'nest_thermostat',
  'nest_thermostat_outline' = 'nest_thermostat_outline',
  'nest_thermostat_e_eu' = 'nest_thermostat_e_eu',
  'nest_thermostat_e_eu_outline' = 'nest_thermostat_e_eu_outline',
  'nest_thermostat_gen_3' = 'nest_thermostat_gen_3',
  'nest_thermostat_sensor' = 'nest_thermostat_sensor',
  'nest_thermostat_sensor_outline' = 'nest_thermostat_sensor_outline',
  'nest_thermostat_sensor_eu' = 'nest_thermostat_sensor_eu',
  'nest_thermostat_sensor_eu_outline' = 'nest_thermostat_sensor_eu_outline',
  'nest_thermostat_zirconium_eu' = 'nest_thermostat_zirconium_eu',
  'nest_thermostat_zirconium_eu_outline' = 'nest_thermostat_zirconium_eu_outline',
  'nest_true_radiant' = 'nest_true_radiant',
  'nest_wake_on_approach' = 'nest_wake_on_approach',
  'nest_wake_on_approach_outline' = 'nest_wake_on_approach_outline',
  'nest_wake_on_press' = 'nest_wake_on_press',
  'nest_wake_on_press_outline' = 'nest_wake_on_press_outline',
  'nest_wifi_gale' = 'nest_wifi_gale',
  'nest_wifi_mistral' = 'nest_wifi_mistral',
  'nest_wifi_mistral_outline' = 'nest_wifi_mistral_outline',
  'nest_wifi_point' = 'nest_wifi_point',
  'nest_wifi_point_outline' = 'nest_wifi_point_outline',
  'nest_wifi_point_vento' = 'nest_wifi_point_vento',
  'nest_wifi_point_vento_outline' = 'nest_wifi_point_vento_outline',
  'nest_wifi_pro' = 'nest_wifi_pro',
  'nest_wifi_pro_outline' = 'nest_wifi_pro_outline',
  'nest_wifi_pro_2' = 'nest_wifi_pro_2',
  'nest_wifi_pro_2_outline' = 'nest_wifi_pro_2_outline',
  'nest_wifi_router' = 'nest_wifi_router',
  'nest_wifi_router_outline' = 'nest_wifi_router_outline',
  'network_cell' = 'network_cell',
  'network_check' = 'network_check',
  'network_intelligence_history' = 'network_intelligence_history',
  'network_intelligence_update' = 'network_intelligence_update',
  'network_locked' = 'network_locked',
  'network_locked_outline' = 'network_locked_outline',
  'network_manage' = 'network_manage',
  'network_node' = 'network_node',
  'network_ping' = 'network_ping',
  'network_wifi' = 'network_wifi',
  'network_wifi_1_bar' = 'network_wifi_1_bar',
  'network_wifi_1_bar_locked' = 'network_wifi_1_bar_locked',
  'network_wifi_2_bar' = 'network_wifi_2_bar',
  'network_wifi_2_bar_locked' = 'network_wifi_2_bar_locked',
  'network_wifi_3_bar' = 'network_wifi_3_bar',
  'network_wifi_3_bar_locked' = 'network_wifi_3_bar_locked',
  'network_wifi_locked' = 'network_wifi_locked',
  'neurology' = 'neurology',
  'neurology_outline' = 'neurology_outline',
  'new_label' = 'new_label',
  'new_label_outline' = 'new_label_outline',
  'new_window' = 'new_window',
  'news' = 'news',
  'news_outline' = 'news_outline',
  'newsmode' = 'newsmode',
  'newsmode_outline' = 'newsmode_outline',
  'newspaper' = 'newspaper',
  'newsstand' = 'newsstand',
  'next_plan' = 'next_plan',
  'next_plan_outline' = 'next_plan_outline',
  'next_week' = 'next_week',
  'next_week_outline' = 'next_week_outline',
  'nfc' = 'nfc',
  'nfc_outline' = 'nfc_outline',
  'night_shelter' = 'night_shelter',
  'night_shelter_outline' = 'night_shelter_outline',
  'night_sight_auto' = 'night_sight_auto',
  'night_sight_auto_outline' = 'night_sight_auto_outline',
  'night_sight_auto_off' = 'night_sight_auto_off',
  'night_sight_auto_off_outline' = 'night_sight_auto_off_outline',
  'night_sight_max' = 'night_sight_max',
  'night_sight_max_outline' = 'night_sight_max_outline',
  'nightlife' = 'nightlife',
  'nightlight' = 'nightlight',
  'nightlight_outline' = 'nightlight_outline',
  'nightlight_badge' = 'nightlight_badge',
  'nightlight_badge_outline' = 'nightlight_badge_outline',
  'nightlight_off' = 'nightlight_off',
  'nightlight_off_outline' = 'nightlight_off_outline',
  'no_accounts' = 'no_accounts',
  'no_accounts_outline' = 'no_accounts_outline',
  'no_adult_content' = 'no_adult_content',
  'no_backpack' = 'no_backpack',
  'no_backpack_outline' = 'no_backpack_outline',
  'no_crash' = 'no_crash',
  'no_crash_outline' = 'no_crash_outline',
  'no_drinks' = 'no_drinks',
  'no_drinks_outline' = 'no_drinks_outline',
  'no_encryption' = 'no_encryption',
  'no_encryption_outline' = 'no_encryption_outline',
  'no_flash' = 'no_flash',
  'no_flash_outline' = 'no_flash_outline',
  'no_food' = 'no_food',
  'no_food_outline' = 'no_food_outline',
  'no_luggage' = 'no_luggage',
  'no_luggage_outline' = 'no_luggage_outline',
  'no_meals' = 'no_meals',
  'no_meeting_room' = 'no_meeting_room',
  'no_meeting_room_outline' = 'no_meeting_room_outline',
  'no_photography' = 'no_photography',
  'no_photography_outline' = 'no_photography_outline',
  'no_sim' = 'no_sim',
  'no_sim_outline' = 'no_sim_outline',
  'no_sound' = 'no_sound',
  'no_sound_outline' = 'no_sound_outline',
  'no_stroller' = 'no_stroller',
  'no_stroller_outline' = 'no_stroller_outline',
  'no_transfer' = 'no_transfer',
  'no_transfer_outline' = 'no_transfer_outline',
  'noise_aware' = 'noise_aware',
  'noise_control_off' = 'noise_control_off',
  'noise_control_on' = 'noise_control_on',
  'nordic_walking' = 'nordic_walking',
  'north' = 'north',
  'north_east' = 'north_east',
  'north_west' = 'north_west',
  'not_accessible' = 'not_accessible',
  'not_accessible_forward' = 'not_accessible_forward',
  'not_listed_location' = 'not_listed_location',
  'not_listed_location_outline' = 'not_listed_location_outline',
  'not_started' = 'not_started',
  'not_started_outline' = 'not_started_outline',
  'note' = 'note',
  'note_outline' = 'note_outline',
  'note_add' = 'note_add',
  'note_add_outline' = 'note_add_outline',
  'note_alt' = 'note_alt',
  'note_alt_outline' = 'note_alt_outline',
  'note_stack' = 'note_stack',
  'note_stack_outline' = 'note_stack_outline',
  'note_stack_add' = 'note_stack_add',
  'note_stack_add_outline' = 'note_stack_add_outline',
  'notes' = 'notes',
  'notification_add' = 'notification_add',
  'notification_add_outline' = 'notification_add_outline',
  'notification_important' = 'notification_important',
  'notification_important_outline' = 'notification_important_outline',
  'notification_multiple' = 'notification_multiple',
  'notification_multiple_outline' = 'notification_multiple_outline',
  'notifications' = 'notifications',
  'notifications_outline' = 'notifications_outline',
  'notifications_active' = 'notifications_active',
  'notifications_active_outline' = 'notifications_active_outline',
  'notifications_off' = 'notifications_off',
  'notifications_off_outline' = 'notifications_off_outline',
  'notifications_paused' = 'notifications_paused',
  'notifications_paused_outline' = 'notifications_paused_outline',
  'notifications_unread' = 'notifications_unread',
  'notifications_unread_outline' = 'notifications_unread_outline',
  'nutrition' = 'nutrition',
  'nutrition_outline' = 'nutrition_outline',
  'ods' = 'ods',
  'ods_outline' = 'ods_outline',
  'odt' = 'odt',
  'odt_outline' = 'odt_outline',
  'offline_bolt' = 'offline_bolt',
  'offline_bolt_outline' = 'offline_bolt_outline',
  'offline_pin' = 'offline_pin',
  'offline_pin_outline' = 'offline_pin_outline',
  'offline_pin_off' = 'offline_pin_off',
  'offline_pin_off_outline' = 'offline_pin_off_outline',
  'offline_share' = 'offline_share',
  'offline_share_outline' = 'offline_share_outline',
  'oil_barrel' = 'oil_barrel',
  'oil_barrel_outline' = 'oil_barrel_outline',
  'on_device_training' = 'on_device_training',
  'on_device_training_outline' = 'on_device_training_outline',
  'on_hub_device' = 'on_hub_device',
  'on_hub_device_outline' = 'on_hub_device_outline',
  'oncology' = 'oncology',
  'oncology_outline' = 'oncology_outline',
  'online_prediction' = 'online_prediction',
  'onsen' = 'onsen',
  'open_in_browser' = 'open_in_browser',
  'open_in_full' = 'open_in_full',
  'open_in_new' = 'open_in_new',
  'open_in_new_down' = 'open_in_new_down',
  'open_in_new_off' = 'open_in_new_off',
  'open_in_phone' = 'open_in_phone',
  'open_in_phone_outline' = 'open_in_phone_outline',
  'open_jam' = 'open_jam',
  'open_jam_outline' = 'open_jam_outline',
  'open_run' = 'open_run',
  'open_with' = 'open_with',
  'ophthalmology' = 'ophthalmology',
  'ophthalmology_outline' = 'ophthalmology_outline',
  'oral_disease' = 'oral_disease',
  'oral_disease_outline' = 'oral_disease_outline',
  'order_approve' = 'order_approve',
  'order_approve_outline' = 'order_approve_outline',
  'order_play' = 'order_play',
  'order_play_outline' = 'order_play_outline',
  'orders' = 'orders',
  'orders_outline' = 'orders_outline',
  'orthopedics' = 'orthopedics',
  'other_admission' = 'other_admission',
  'other_admission_outline' = 'other_admission_outline',
  'other_houses' = 'other_houses',
  'other_houses_outline' = 'other_houses_outline',
  'outbound' = 'outbound',
  'outbound_outline' = 'outbound_outline',
  'outbox' = 'outbox',
  'outbox_outline' = 'outbox_outline',
  'outbox_alt' = 'outbox_alt',
  'outbox_alt_outline' = 'outbox_alt_outline',
  'outdoor_garden' = 'outdoor_garden',
  'outdoor_garden_outline' = 'outdoor_garden_outline',
  'outdoor_grill' = 'outdoor_grill',
  'outdoor_grill_outline' = 'outdoor_grill_outline',
  'outgoing_mail' = 'outgoing_mail',
  'outgoing_mail_outline' = 'outgoing_mail_outline',
  'outlet' = 'outlet',
  'outlet_outline' = 'outlet_outline',
  'outpatient_med' = 'outpatient_med',
  'outpatient_med_outline' = 'outpatient_med_outline',
  'output' = 'output',
  'output_circle' = 'output_circle',
  'oven' = 'oven',
  'oven_outline' = 'oven_outline',
  'oven_gen' = 'oven_gen',
  'oven_gen_outline' = 'oven_gen_outline',
  'overview' = 'overview',
  'overview_outline' = 'overview_outline',
  'overview_key' = 'overview_key',
  'overview_key_outline' = 'overview_key_outline',
  'oxygen_saturation' = 'oxygen_saturation',
  'oxygen_saturation_outline' = 'oxygen_saturation_outline',
  'p2p' = 'p2p',
  'p2p_outline' = 'p2p_outline',
  'pace' = 'pace',
  'pacemaker' = 'pacemaker',
  'pacemaker_outline' = 'pacemaker_outline',
  'package' = 'package',
  'package_outline' = 'package_outline',
  'package_2' = 'package_2',
  'package_2_outline' = 'package_2_outline',
  'padding' = 'padding',
  'padding_outline' = 'padding_outline',
  'page_control' = 'page_control',
  'page_info' = 'page_info',
  'page_info_outline' = 'page_info_outline',
  'pageless' = 'pageless',
  'pages' = 'pages',
  'pages_outline' = 'pages_outline',
  'pageview' = 'pageview',
  'pageview_outline' = 'pageview_outline',
  'paid' = 'paid',
  'paid_outline' = 'paid_outline',
  'palette' = 'palette',
  'palette_outline' = 'palette_outline',
  'pallet' = 'pallet',
  'pallet_outline' = 'pallet_outline',
  'pan_tool' = 'pan_tool',
  'pan_tool_outline' = 'pan_tool_outline',
  'pan_tool_alt' = 'pan_tool_alt',
  'pan_tool_alt_outline' = 'pan_tool_alt_outline',
  'pan_zoom' = 'pan_zoom',
  'panorama' = 'panorama',
  'panorama_outline' = 'panorama_outline',
  'panorama_horizontal' = 'panorama_horizontal',
  'panorama_horizontal_outline' = 'panorama_horizontal_outline',
  'panorama_photosphere' = 'panorama_photosphere',
  'panorama_photosphere_outline' = 'panorama_photosphere_outline',
  'panorama_vertical' = 'panorama_vertical',
  'panorama_vertical_outline' = 'panorama_vertical_outline',
  'panorama_wide_angle' = 'panorama_wide_angle',
  'panorama_wide_angle_outline' = 'panorama_wide_angle_outline',
  'paragliding' = 'paragliding',
  'paragliding_outline' = 'paragliding_outline',
  'park' = 'park',
  'park_outline' = 'park_outline',
  'partly_cloudy_day' = 'partly_cloudy_day',
  'partly_cloudy_day_outline' = 'partly_cloudy_day_outline',
  'partly_cloudy_night' = 'partly_cloudy_night',
  'partly_cloudy_night_outline' = 'partly_cloudy_night_outline',
  'partner_exchange' = 'partner_exchange',
  'partner_exchange_outline' = 'partner_exchange_outline',
  'partner_reports' = 'partner_reports',
  'party_mode' = 'party_mode',
  'party_mode_outline' = 'party_mode_outline',
  'passkey' = 'passkey',
  'password' = 'password',
  'password_2' = 'password_2',
  'password_2_off' = 'password_2_off',
  'patient_list' = 'patient_list',
  'patient_list_outline' = 'patient_list_outline',
  'pattern' = 'pattern',
  'pause' = 'pause',
  'pause_outline' = 'pause_outline',
  'pause_circle' = 'pause_circle',
  'pause_circle_outline' = 'pause_circle_outline',
  'pause_presentation' = 'pause_presentation',
  'pause_presentation_outline' = 'pause_presentation_outline',
  'payments' = 'payments',
  'payments_outline' = 'payments_outline',
  'pdf_off' = 'pdf_off',
  'pdf_off_outline' = 'pdf_off_outline',
  'pedal_bike' = 'pedal_bike',
  'pedal_bike_outline' = 'pedal_bike_outline',
  'pediatrics' = 'pediatrics',
  'pediatrics_outline' = 'pediatrics_outline',
  'pen_size_1' = 'pen_size_1',
  'pen_size_2' = 'pen_size_2',
  'pen_size_3' = 'pen_size_3',
  'pen_size_4' = 'pen_size_4',
  'pen_size_5' = 'pen_size_5',
  'pending' = 'pending',
  'pending_outline' = 'pending_outline',
  'pending_actions' = 'pending_actions',
  'pentagon' = 'pentagon',
  'pentagon_outline' = 'pentagon_outline',
  'percent' = 'percent',
  'performance_max' = 'performance_max',
  'performance_max_outline' = 'performance_max_outline',
  'pergola' = 'pergola',
  'pergola_outline' = 'pergola_outline',
  'perm_camera_mic' = 'perm_camera_mic',
  'perm_camera_mic_outline' = 'perm_camera_mic_outline',
  'perm_contact_calendar' = 'perm_contact_calendar',
  'perm_contact_calendar_outline' = 'perm_contact_calendar_outline',
  'perm_data_setting' = 'perm_data_setting',
  'perm_data_setting_outline' = 'perm_data_setting_outline',
  'perm_device_information' = 'perm_device_information',
  'perm_device_information_outline' = 'perm_device_information_outline',
  'perm_media' = 'perm_media',
  'perm_media_outline' = 'perm_media_outline',
  'perm_phone_msg' = 'perm_phone_msg',
  'perm_phone_msg_outline' = 'perm_phone_msg_outline',
  'perm_scan_wifi' = 'perm_scan_wifi',
  'perm_scan_wifi_outline' = 'perm_scan_wifi_outline',
  'person' = 'person',
  'person_outline' = 'person_outline',
  'person_2' = 'person_2',
  'person_2_outline' = 'person_2_outline',
  'person_3' = 'person_3',
  'person_3_outline' = 'person_3_outline',
  'person_4' = 'person_4',
  'person_4_outline' = 'person_4_outline',
  'person_add' = 'person_add',
  'person_add_outline' = 'person_add_outline',
  'person_add_disabled' = 'person_add_disabled',
  'person_add_disabled_outline' = 'person_add_disabled_outline',
  'person_alert' = 'person_alert',
  'person_alert_outline' = 'person_alert_outline',
  'person_apron' = 'person_apron',
  'person_apron_outline' = 'person_apron_outline',
  'person_book' = 'person_book',
  'person_book_outline' = 'person_book_outline',
  'person_cancel' = 'person_cancel',
  'person_cancel_outline' = 'person_cancel_outline',
  'person_celebrate' = 'person_celebrate',
  'person_check' = 'person_check',
  'person_check_outline' = 'person_check_outline',
  'person_edit' = 'person_edit',
  'person_edit_outline' = 'person_edit_outline',
  'person_filled' = 'person_filled',
  'person_filled_outline' = 'person_filled_outline',
  'person_off' = 'person_off',
  'person_off_outline' = 'person_off_outline',
  'person_pin' = 'person_pin',
  'person_pin_outline' = 'person_pin_outline',
  'person_pin_circle' = 'person_pin_circle',
  'person_pin_circle_outline' = 'person_pin_circle_outline',
  'person_play' = 'person_play',
  'person_play_outline' = 'person_play_outline',
  'person_raised_hand' = 'person_raised_hand',
  'person_raised_hand_outline' = 'person_raised_hand_outline',
  'person_remove' = 'person_remove',
  'person_remove_outline' = 'person_remove_outline',
  'person_search' = 'person_search',
  'person_search_outline' = 'person_search_outline',
  'personal_bag' = 'personal_bag',
  'personal_bag_outline' = 'personal_bag_outline',
  'personal_bag_off' = 'personal_bag_off',
  'personal_bag_off_outline' = 'personal_bag_off_outline',
  'personal_bag_question' = 'personal_bag_question',
  'personal_bag_question_outline' = 'personal_bag_question_outline',
  'personal_injury' = 'personal_injury',
  'personal_injury_outline' = 'personal_injury_outline',
  'personal_places' = 'personal_places',
  'personal_places_outline' = 'personal_places_outline',
  'pest_control' = 'pest_control',
  'pest_control_rodent' = 'pest_control_rodent',
  'pest_control_rodent_outline' = 'pest_control_rodent_outline',
  'pet_supplies' = 'pet_supplies',
  'pet_supplies_outline' = 'pet_supplies_outline',
  'pets' = 'pets',
  'phishing' = 'phishing',
  'phishing_outline' = 'phishing_outline',
  'phone_android' = 'phone_android',
  'phone_android_outline' = 'phone_android_outline',
  'phone_bluetooth_speaker' = 'phone_bluetooth_speaker',
  'phone_bluetooth_speaker_outline' = 'phone_bluetooth_speaker_outline',
  'phone_callback' = 'phone_callback',
  'phone_callback_outline' = 'phone_callback_outline',
  'phone_disabled' = 'phone_disabled',
  'phone_disabled_outline' = 'phone_disabled_outline',
  'phone_enabled' = 'phone_enabled',
  'phone_enabled_outline' = 'phone_enabled_outline',
  'phone_forwarded' = 'phone_forwarded',
  'phone_forwarded_outline' = 'phone_forwarded_outline',
  'phone_in_talk' = 'phone_in_talk',
  'phone_in_talk_outline' = 'phone_in_talk_outline',
  'phone_in_talk_watchface_indicator' = 'phone_in_talk_watchface_indicator',
  'phone_in_talk_watchface_indicator_outline' = 'phone_in_talk_watchface_indicator_outline',
  'phone_iphone' = 'phone_iphone',
  'phone_iphone_outline' = 'phone_iphone_outline',
  'phone_locked' = 'phone_locked',
  'phone_locked_outline' = 'phone_locked_outline',
  'phone_missed' = 'phone_missed',
  'phone_missed_outline' = 'phone_missed_outline',
  'phone_paused' = 'phone_paused',
  'phone_paused_outline' = 'phone_paused_outline',
  'phonelink_erase' = 'phonelink_erase',
  'phonelink_erase_outline' = 'phonelink_erase_outline',
  'phonelink_lock' = 'phonelink_lock',
  'phonelink_lock_outline' = 'phonelink_lock_outline',
  'phonelink_ring' = 'phonelink_ring',
  'phonelink_ring_outline' = 'phonelink_ring_outline',
  'phonelink_ring_off' = 'phonelink_ring_off',
  'phonelink_ring_off_outline' = 'phonelink_ring_off_outline',
  'phonelink_setup' = 'phonelink_setup',
  'phonelink_setup_outline' = 'phonelink_setup_outline',
  'photo_album' = 'photo_album',
  'photo_album_outline' = 'photo_album_outline',
  'photo_auto_merge' = 'photo_auto_merge',
  'photo_auto_merge_outline' = 'photo_auto_merge_outline',
  'photo_camera' = 'photo_camera',
  'photo_camera_outline' = 'photo_camera_outline',
  'photo_camera_back' = 'photo_camera_back',
  'photo_camera_back_outline' = 'photo_camera_back_outline',
  'photo_camera_front' = 'photo_camera_front',
  'photo_camera_front_outline' = 'photo_camera_front_outline',
  'photo_filter' = 'photo_filter',
  'photo_frame' = 'photo_frame',
  'photo_frame_outline' = 'photo_frame_outline',
  'photo_library' = 'photo_library',
  'photo_prints' = 'photo_prints',
  'photo_prints_outline' = 'photo_prints_outline',
  'photo_size_select_large' = 'photo_size_select_large',
  'photo_size_select_small' = 'photo_size_select_small',
  'php' = 'php',
  'physical_therapy' = 'physical_therapy',
  'physical_therapy_outline' = 'physical_therapy_outline',
  'piano' = 'piano',
  'piano_off' = 'piano_off',
  'picture_as_pdf' = 'picture_as_pdf',
  'picture_as_pdf_outline' = 'picture_as_pdf_outline',
  'picture_in_picture' = 'picture_in_picture',
  'picture_in_picture_outline' = 'picture_in_picture_outline',
  'picture_in_picture_alt' = 'picture_in_picture_alt',
  'picture_in_picture_alt_outline' = 'picture_in_picture_alt_outline',
  'picture_in_picture_center' = 'picture_in_picture_center',
  'picture_in_picture_center_outline' = 'picture_in_picture_center_outline',
  'picture_in_picture_large' = 'picture_in_picture_large',
  'picture_in_picture_medium' = 'picture_in_picture_medium',
  'picture_in_picture_mobile' = 'picture_in_picture_mobile',
  'picture_in_picture_mobile_outline' = 'picture_in_picture_mobile_outline',
  'picture_in_picture_off' = 'picture_in_picture_off',
  'picture_in_picture_off_outline' = 'picture_in_picture_off_outline',
  'picture_in_picture_small' = 'picture_in_picture_small',
  'pie_chart' = 'pie_chart',
  'pie_chart_outline' = 'pie_chart_outline',
  'pill' = 'pill',
  'pill_outline' = 'pill_outline',
  'pill_off' = 'pill_off',
  'pill_off_outline' = 'pill_off_outline',
  'pin' = 'pin',
  'pin_outline' = 'pin_outline',
  'pin_drop' = 'pin_drop',
  'pin_drop_outline' = 'pin_drop_outline',
  'pin_end' = 'pin_end',
  'pin_invoke' = 'pin_invoke',
  'pinch' = 'pinch',
  'pinch_outline' = 'pinch_outline',
  'pinch_zoom_in' = 'pinch_zoom_in',
  'pinch_zoom_in_outline' = 'pinch_zoom_in_outline',
  'pinch_zoom_out' = 'pinch_zoom_out',
  'pinch_zoom_out_outline' = 'pinch_zoom_out_outline',
  'pip' = 'pip',
  'pip_exit' = 'pip_exit',
  'pip_exit_outline' = 'pip_exit_outline',
  'pivot_table_chart' = 'pivot_table_chart',
  'pixel_3_3xl_3a' = 'pixel_3_3xl_3a',
  'pixel_3_3xl_3a_outline' = 'pixel_3_3xl_3a_outline',
  'place_item' = 'place_item',
  'plagiarism' = 'plagiarism',
  'plagiarism_outline' = 'plagiarism_outline',
  'planner_banner_ad_pt' = 'planner_banner_ad_pt',
  'planner_banner_ad_pt_outline' = 'planner_banner_ad_pt_outline',
  'planner_review' = 'planner_review',
  'play_arrow' = 'play_arrow',
  'play_arrow_outline' = 'play_arrow_outline',
  'play_circle' = 'play_circle',
  'play_circle_outline' = 'play_circle_outline',
  'play_disabled' = 'play_disabled',
  'play_disabled_outline' = 'play_disabled_outline',
  'play_for_work' = 'play_for_work',
  'play_lesson' = 'play_lesson',
  'play_lesson_outline' = 'play_lesson_outline',
  'play_pause' = 'play_pause',
  'play_shapes' = 'play_shapes',
  'play_shapes_outline' = 'play_shapes_outline',
  'playing_cards' = 'playing_cards',
  'playing_cards_outline' = 'playing_cards_outline',
  'playlist_add' = 'playlist_add',
  'playlist_add_check' = 'playlist_add_check',
  'playlist_add_check_circle' = 'playlist_add_check_circle',
  'playlist_add_check_circle_outline' = 'playlist_add_check_circle_outline',
  'playlist_add_circle' = 'playlist_add_circle',
  'playlist_add_circle_outline' = 'playlist_add_circle_outline',
  'playlist_play' = 'playlist_play',
  'playlist_remove' = 'playlist_remove',
  'plumbing' = 'plumbing',
  'podcasts' = 'podcasts',
  'podiatry' = 'podiatry',
  'podiatry_outline' = 'podiatry_outline',
  'podium' = 'podium',
  'podium_outline' = 'podium_outline',
  'point_of_sale' = 'point_of_sale',
  'point_scan' = 'point_scan',
  'poker_chip' = 'poker_chip',
  'policy' = 'policy',
  'policy_outline' = 'policy_outline',
  'polyline' = 'polyline',
  'polyline_outline' = 'polyline_outline',
  'polymer' = 'polymer',
  'pool' = 'pool',
  'portrait_lighting' = 'portrait_lighting',
  'portrait_lighting_off' = 'portrait_lighting_off',
  'position_bottom_left' = 'position_bottom_left',
  'position_bottom_left_outline' = 'position_bottom_left_outline',
  'position_bottom_right' = 'position_bottom_right',
  'position_bottom_right_outline' = 'position_bottom_right_outline',
  'position_top_right' = 'position_top_right',
  'position_top_right_outline' = 'position_top_right_outline',
  'post' = 'post',
  'post_outline' = 'post_outline',
  'post_add' = 'post_add',
  'potted_plant' = 'potted_plant',
  'potted_plant_outline' = 'potted_plant_outline',
  'power' = 'power',
  'power_outline' = 'power_outline',
  'power_input' = 'power_input',
  'power_off' = 'power_off',
  'power_off_outline' = 'power_off_outline',
  'power_rounded' = 'power_rounded',
  'power_settings_new' = 'power_settings_new',
  'power_settings_new_outline' = 'power_settings_new_outline',
  'prayer_times' = 'prayer_times',
  'prayer_times_outline' = 'prayer_times_outline',
  'precision_manufacturing' = 'precision_manufacturing',
  'precision_manufacturing_outline' = 'precision_manufacturing_outline',
  'pregnancy' = 'pregnancy',
  'preliminary' = 'preliminary',
  'preliminary_outline' = 'preliminary_outline',
  'prescriptions' = 'prescriptions',
  'prescriptions_outline' = 'prescriptions_outline',
  'present_to_all' = 'present_to_all',
  'present_to_all_outline' = 'present_to_all_outline',
  'preview' = 'preview',
  'preview_outline' = 'preview_outline',
  'preview_off' = 'preview_off',
  'price_change' = 'price_change',
  'price_change_outline' = 'price_change_outline',
  'price_check' = 'price_check',
  'print' = 'print',
  'print_outline' = 'print_outline',
  'print_add' = 'print_add',
  'print_add_outline' = 'print_add_outline',
  'print_connect' = 'print_connect',
  'print_connect_outline' = 'print_connect_outline',
  'print_disabled' = 'print_disabled',
  'print_disabled_outline' = 'print_disabled_outline',
  'print_error' = 'print_error',
  'print_error_outline' = 'print_error_outline',
  'print_lock' = 'print_lock',
  'print_lock_outline' = 'print_lock_outline',
  'priority' = 'priority',
  'priority_outline' = 'priority_outline',
  'priority_high' = 'priority_high',
  'privacy' = 'privacy',
  'privacy_outline' = 'privacy_outline',
  'privacy_screen' = 'privacy_screen',
  'privacy_screen_outline' = 'privacy_screen_outline',
  'privacy_screen_off' = 'privacy_screen_off',
  'privacy_screen_off_outline' = 'privacy_screen_off_outline',
  'privacy_tip' = 'privacy_tip',
  'privacy_tip_outline' = 'privacy_tip_outline',
  'private_connectivity' = 'private_connectivity',
  'private_connectivity_outline' = 'private_connectivity_outline',
  'problem' = 'problem',
  'problem_outline' = 'problem_outline',
  'procedure' = 'procedure',
  'procedure_outline' = 'procedure_outline',
  'process_chart' = 'process_chart',
  'production_quantity_limits' = 'production_quantity_limits',
  'productivity' = 'productivity',
  'productivity_outline' = 'productivity_outline',
  'progress_activity' = 'progress_activity',
  'prompt_suggestion' = 'prompt_suggestion',
  'propane' = 'propane',
  'propane_outline' = 'propane_outline',
  'propane_tank' = 'propane_tank',
  'propane_tank_outline' = 'propane_tank_outline',
  'psychiatry' = 'psychiatry',
  'psychiatry_outline' = 'psychiatry_outline',
  'psychology' = 'psychology',
  'psychology_outline' = 'psychology_outline',
  'psychology_alt' = 'psychology_alt',
  'psychology_alt_outline' = 'psychology_alt_outline',
  'public' = 'public',
  'public_off' = 'public_off',
  'publish' = 'publish',
  'published_with_changes' = 'published_with_changes',
  'pulmonology' = 'pulmonology',
  'pulmonology_outline' = 'pulmonology_outline',
  'pulse_alert' = 'pulse_alert',
  'pulse_alert_outline' = 'pulse_alert_outline',
  'punch_clock' = 'punch_clock',
  'punch_clock_outline' = 'punch_clock_outline',
  'push_pin' = 'push_pin',
  'push_pin_outline' = 'push_pin_outline',
  'qr_code' = 'qr_code',
  'qr_code_2' = 'qr_code_2',
  'qr_code_2_add' = 'qr_code_2_add',
  'qr_code_scanner' = 'qr_code_scanner',
  'query_stats' = 'query_stats',
  'question_exchange' = 'question_exchange',
  'question_mark' = 'question_mark',
  'queue_music' = 'queue_music',
  'queue_play_next' = 'queue_play_next',
  'queue_play_next_outline' = 'queue_play_next_outline',
  'quick_phrases' = 'quick_phrases',
  'quick_reference' = 'quick_reference',
  'quick_reference_outline' = 'quick_reference_outline',
  'quick_reference_all' = 'quick_reference_all',
  'quick_reference_all_outline' = 'quick_reference_all_outline',
  'quick_reorder' = 'quick_reorder',
  'quick_reorder_outline' = 'quick_reorder_outline',
  'quickreply' = 'quickreply',
  'quickreply_outline' = 'quickreply_outline',
  'quiz' = 'quiz',
  'quiz_outline' = 'quiz_outline',
  'r_mobiledata' = 'r_mobiledata',
  'radar' = 'radar',
  'radio' = 'radio',
  'radio_outline' = 'radio_outline',
  'radio_button_checked' = 'radio_button_checked',
  'radio_button_checked_outline' = 'radio_button_checked_outline',
  'radio_button_partial' = 'radio_button_partial',
  'radio_button_partial_outline' = 'radio_button_partial_outline',
  'radio_button_unchecked' = 'radio_button_unchecked',
  'radiology' = 'radiology',
  'radiology_outline' = 'radiology_outline',
  'railway_alert' = 'railway_alert',
  'railway_alert_outline' = 'railway_alert_outline',
  'rainy' = 'rainy',
  'rainy_outline' = 'rainy_outline',
  'rainy_heavy' = 'rainy_heavy',
  'rainy_light' = 'rainy_light',
  'rainy_snow' = 'rainy_snow',
  'ramen_dining' = 'ramen_dining',
  'ramen_dining_outline' = 'ramen_dining_outline',
  'ramp_left' = 'ramp_left',
  'ramp_right' = 'ramp_right',
  'range_hood' = 'range_hood',
  'range_hood_outline' = 'range_hood_outline',
  'rate_review' = 'rate_review',
  'rate_review_outline' = 'rate_review_outline',
  'raven' = 'raven',
  'raven_outline' = 'raven_outline',
  'raw_off' = 'raw_off',
  'raw_on' = 'raw_on',
  'read_more' = 'read_more',
  'readiness_score' = 'readiness_score',
  'readiness_score_outline' = 'readiness_score_outline',
  'real_estate_agent' = 'real_estate_agent',
  'real_estate_agent_outline' = 'real_estate_agent_outline',
  'rear_camera' = 'rear_camera',
  'rear_camera_outline' = 'rear_camera_outline',
  'rebase' = 'rebase',
  'rebase_outline' = 'rebase_outline',
  'rebase_edit' = 'rebase_edit',
  'rebase_edit_outline' = 'rebase_edit_outline',
  'receipt' = 'receipt',
  'receipt_outline' = 'receipt_outline',
  'receipt_long' = 'receipt_long',
  'receipt_long_outline' = 'receipt_long_outline',
  'recent_actors' = 'recent_actors',
  'recent_actors_outline' = 'recent_actors_outline',
  'recent_patient' = 'recent_patient',
  'recent_patient_outline' = 'recent_patient_outline',
  'recenter' = 'recenter',
  'recommend' = 'recommend',
  'recommend_outline' = 'recommend_outline',
  'record_voice_over' = 'record_voice_over',
  'record_voice_over_outline' = 'record_voice_over_outline',
  'rectangle' = 'rectangle',
  'rectangle_outline' = 'rectangle_outline',
  'recycling' = 'recycling',
  'redeem' = 'redeem',
  'redo' = 'redo',
  'reduce_capacity' = 'reduce_capacity',
  'refresh' = 'refresh',
  'regular_expression' = 'regular_expression',
  'relax' = 'relax',
  'relax_outline' = 'relax_outline',
  'release_alert' = 'release_alert',
  'release_alert_outline' = 'release_alert_outline',
  'remember_me' = 'remember_me',
  'remember_me_outline' = 'remember_me_outline',
  'reminder' = 'reminder',
  'reminder_outline' = 'reminder_outline',
  'remote_gen' = 'remote_gen',
  'remote_gen_outline' = 'remote_gen_outline',
  'remove' = 'remove',
  'remove_done' = 'remove_done',
  'remove_from_queue' = 'remove_from_queue',
  'remove_from_queue_outline' = 'remove_from_queue_outline',
  'remove_moderator' = 'remove_moderator',
  'remove_moderator_outline' = 'remove_moderator_outline',
  'remove_road' = 'remove_road',
  'remove_selection' = 'remove_selection',
  'remove_shopping_cart' = 'remove_shopping_cart',
  'reopen_window' = 'reopen_window',
  'reorder' = 'reorder',
  'repartition' = 'repartition',
  'repeat' = 'repeat',
  'repeat_on' = 'repeat_on',
  'repeat_one' = 'repeat_one',
  'repeat_one_on' = 'repeat_one_on',
  'replay' = 'replay',
  'replay_10' = 'replay_10',
  'replay_30' = 'replay_30',
  'replay_5' = 'replay_5',
  'replay_circle_filled' = 'replay_circle_filled',
  'reply' = 'reply',
  'reply_all' = 'reply_all',
  'report' = 'report',
  'report_outline' = 'report_outline',
  'report_off' = 'report_off',
  'report_off_outline' = 'report_off_outline',
  'request_page' = 'request_page',
  'request_page_outline' = 'request_page_outline',
  'request_quote' = 'request_quote',
  'request_quote_outline' = 'request_quote_outline',
  'reset_image' = 'reset_image',
  'reset_tv' = 'reset_tv',
  'reset_tv_outline' = 'reset_tv_outline',
  'reset_wrench' = 'reset_wrench',
  'resize' = 'resize',
  'respiratory_rate' = 'respiratory_rate',
  'respiratory_rate_outline' = 'respiratory_rate_outline',
  'responsive_layout' = 'responsive_layout',
  'responsive_layout_outline' = 'responsive_layout_outline',
  'restart_alt' = 'restart_alt',
  'restaurant' = 'restaurant',
  'restore_from_trash' = 'restore_from_trash',
  'restore_from_trash_outline' = 'restore_from_trash_outline',
  'restore_page' = 'restore_page',
  'restore_page_outline' = 'restore_page_outline',
  'resume' = 'resume',
  'resume_outline' = 'resume_outline',
  'reviews' = 'reviews',
  'reviews_outline' = 'reviews_outline',
  'rewarded_ads' = 'rewarded_ads',
  'rewarded_ads_outline' = 'rewarded_ads_outline',
  'rheumatology' = 'rheumatology',
  'rheumatology_outline' = 'rheumatology_outline',
  'rib_cage' = 'rib_cage',
  'rice_bowl' = 'rice_bowl',
  'rice_bowl_outline' = 'rice_bowl_outline',
  'right_click' = 'right_click',
  'right_panel_close' = 'right_panel_close',
  'right_panel_close_outline' = 'right_panel_close_outline',
  'right_panel_open' = 'right_panel_open',
  'right_panel_open_outline' = 'right_panel_open_outline',
  'ring_volume' = 'ring_volume',
  'ring_volume_outline' = 'ring_volume_outline',
  'ripples' = 'ripples',
  'ripples_outline' = 'ripples_outline',
  'robot' = 'robot',
  'robot_outline' = 'robot_outline',
  'robot_2' = 'robot_2',
  'robot_2_outline' = 'robot_2_outline',
  'rocket' = 'rocket',
  'rocket_outline' = 'rocket_outline',
  'rocket_launch' = 'rocket_launch',
  'rocket_launch_outline' = 'rocket_launch_outline',
  'roller_shades' = 'roller_shades',
  'roller_shades_outline' = 'roller_shades_outline',
  'roller_shades_closed' = 'roller_shades_closed',
  'roller_shades_closed_outline' = 'roller_shades_closed_outline',
  'roller_skating' = 'roller_skating',
  'roller_skating_outline' = 'roller_skating_outline',
  'roofing' = 'roofing',
  'roofing_outline' = 'roofing_outline',
  'room_preferences' = 'room_preferences',
  'room_preferences_outline' = 'room_preferences_outline',
  'room_service' = 'room_service',
  'room_service_outline' = 'room_service_outline',
  'rotate_90_degrees_ccw' = 'rotate_90_degrees_ccw',
  'rotate_90_degrees_ccw_outline' = 'rotate_90_degrees_ccw_outline',
  'rotate_90_degrees_cw' = 'rotate_90_degrees_cw',
  'rotate_90_degrees_cw_outline' = 'rotate_90_degrees_cw_outline',
  'rotate_left' = 'rotate_left',
  'rotate_right' = 'rotate_right',
  'roundabout_left' = 'roundabout_left',
  'roundabout_right' = 'roundabout_right',
  'rounded_corner' = 'rounded_corner',
  'route' = 'route',
  'route_outline' = 'route_outline',
  'router' = 'router',
  'router_outline' = 'router_outline',
  'routine' = 'routine',
  'routine_outline' = 'routine_outline',
  'rowing' = 'rowing',
  'rss_feed' = 'rss_feed',
  'rsvp' = 'rsvp',
  'rtt' = 'rtt',
  'rubric' = 'rubric',
  'rule' = 'rule',
  'rule_folder' = 'rule_folder',
  'rule_folder_outline' = 'rule_folder_outline',
  'rule_settings' = 'rule_settings',
  'run_circle' = 'run_circle',
  'running_with_errors' = 'running_with_errors',
  'rv_hookup' = 'rv_hookup',
  'rv_hookup_outline' = 'rv_hookup_outline',
  'sad_tab' = 'sad_tab',
  'sad_tab_outline' = 'sad_tab_outline',
  'safety_check' = 'safety_check',
  'safety_check_outline' = 'safety_check_outline',
  'safety_check_off' = 'safety_check_off',
  'safety_check_off_outline' = 'safety_check_off_outline',
  'safety_divider' = 'safety_divider',
  'sailing' = 'sailing',
  'sailing_outline' = 'sailing_outline',
  'salinity' = 'salinity',
  'salinity_outline' = 'salinity_outline',
  'sanitizer' = 'sanitizer',
  'sanitizer_outline' = 'sanitizer_outline',
  'satellite' = 'satellite',
  'satellite_outline' = 'satellite_outline',
  'satellite_alt' = 'satellite_alt',
  'satellite_alt_outline' = 'satellite_alt_outline',
  'sauna' = 'sauna',
  'sauna_outline' = 'sauna_outline',
  'save' = 'save',
  'save_outline' = 'save_outline',
  'save_as' = 'save_as',
  'save_as_outline' = 'save_as_outline',
  'saved_search' = 'saved_search',
  'savings' = 'savings',
  'savings_outline' = 'savings_outline',
  'scale' = 'scale',
  'scale_outline' = 'scale_outline',
  'scan' = 'scan',
  'scan_outline' = 'scan_outline',
  'scan_delete' = 'scan_delete',
  'scan_delete_outline' = 'scan_delete_outline',
  'scanner' = 'scanner',
  'scanner_outline' = 'scanner_outline',
  'scatter_plot' = 'scatter_plot',
  'scatter_plot_outline' = 'scatter_plot_outline',
  'scene' = 'scene',
  'scene_outline' = 'scene_outline',
  'schedule' = 'schedule',
  'schedule_outline' = 'schedule_outline',
  'schedule_send' = 'schedule_send',
  'schedule_send_outline' = 'schedule_send_outline',
  'schema' = 'schema',
  'schema_outline' = 'schema_outline',
  'school' = 'school',
  'school_outline' = 'school_outline',
  'science' = 'science',
  'science_outline' = 'science_outline',
  'science_off' = 'science_off',
  'science_off_outline' = 'science_off_outline',
  'score' = 'score',
  'score_outline' = 'score_outline',
  'scoreboard' = 'scoreboard',
  'scoreboard_outline' = 'scoreboard_outline',
  'screen_lock_landscape' = 'screen_lock_landscape',
  'screen_lock_landscape_outline' = 'screen_lock_landscape_outline',
  'screen_lock_portrait' = 'screen_lock_portrait',
  'screen_lock_portrait_outline' = 'screen_lock_portrait_outline',
  'screen_lock_rotation' = 'screen_lock_rotation',
  'screen_lock_rotation_outline' = 'screen_lock_rotation_outline',
  'screen_record' = 'screen_record',
  'screen_rotation' = 'screen_rotation',
  'screen_rotation_outline' = 'screen_rotation_outline',
  'screen_rotation_alt' = 'screen_rotation_alt',
  'screen_rotation_up' = 'screen_rotation_up',
  'screen_search_desktop' = 'screen_search_desktop',
  'screen_search_desktop_outline' = 'screen_search_desktop_outline',
  'screen_share' = 'screen_share',
  'screen_share_outline' = 'screen_share_outline',
  'screencast' = 'screencast',
  'screenshot' = 'screenshot',
  'screenshot_outline' = 'screenshot_outline',
  'screenshot_frame' = 'screenshot_frame',
  'screenshot_keyboard' = 'screenshot_keyboard',
  'screenshot_keyboard_outline' = 'screenshot_keyboard_outline',
  'screenshot_monitor' = 'screenshot_monitor',
  'screenshot_monitor_outline' = 'screenshot_monitor_outline',
  'screenshot_region' = 'screenshot_region',
  'screenshot_tablet' = 'screenshot_tablet',
  'screenshot_tablet_outline' = 'screenshot_tablet_outline',
  'scrollable_header' = 'scrollable_header',
  'scrollable_header_outline' = 'scrollable_header_outline',
  'scuba_diving' = 'scuba_diving',
  'sd' = 'sd',
  'sd_outline' = 'sd_outline',
  'sd_card' = 'sd_card',
  'sd_card_outline' = 'sd_card_outline',
  'sd_card_alert' = 'sd_card_alert',
  'sd_card_alert_outline' = 'sd_card_alert_outline',
  'sdk' = 'sdk',
  'sdk_outline' = 'sdk_outline',
  'search' = 'search',
  'search_check' = 'search_check',
  'search_hands_free' = 'search_hands_free',
  'search_insights' = 'search_insights',
  'search_off' = 'search_off',
  'security' = 'security',
  'security_key' = 'security_key',
  'security_key_outline' = 'security_key_outline',
  'security_update_good' = 'security_update_good',
  'security_update_good_outline' = 'security_update_good_outline',
  'security_update_warning' = 'security_update_warning',
  'security_update_warning_outline' = 'security_update_warning_outline',
  'segment' = 'segment',
  'select' = 'select',
  'select_all' = 'select_all',
  'select_check_box' = 'select_check_box',
  'select_to_speak' = 'select_to_speak',
  'select_to_speak_outline' = 'select_to_speak_outline',
  'select_window' = 'select_window',
  'select_window_2' = 'select_window_2',
  'select_window_2_outline' = 'select_window_2_outline',
  'select_window_off' = 'select_window_off',
  'self_care' = 'self_care',
  'self_improvement' = 'self_improvement',
  'sell' = 'sell',
  'sell_outline' = 'sell_outline',
  'send' = 'send',
  'send_outline' = 'send_outline',
  'send_and_archive' = 'send_and_archive',
  'send_and_archive_outline' = 'send_and_archive_outline',
  'send_money' = 'send_money',
  'send_time_extension' = 'send_time_extension',
  'send_time_extension_outline' = 'send_time_extension_outline',
  'send_to_mobile' = 'send_to_mobile',
  'send_to_mobile_outline' = 'send_to_mobile_outline',
  'sensor_door' = 'sensor_door',
  'sensor_door_outline' = 'sensor_door_outline',
  'sensor_occupied' = 'sensor_occupied',
  'sensor_occupied_outline' = 'sensor_occupied_outline',
  'sensor_window' = 'sensor_window',
  'sensor_window_outline' = 'sensor_window_outline',
  'sensors' = 'sensors',
  'sensors_krx' = 'sensors_krx',
  'sensors_krx_outline' = 'sensors_krx_outline',
  'sensors_krx_off' = 'sensors_krx_off',
  'sensors_krx_off_outline' = 'sensors_krx_off_outline',
  'sensors_off' = 'sensors_off',
  'sentiment_calm' = 'sentiment_calm',
  'sentiment_calm_outline' = 'sentiment_calm_outline',
  'sentiment_content' = 'sentiment_content',
  'sentiment_content_outline' = 'sentiment_content_outline',
  'sentiment_dissatisfied' = 'sentiment_dissatisfied',
  'sentiment_dissatisfied_outline' = 'sentiment_dissatisfied_outline',
  'sentiment_excited' = 'sentiment_excited',
  'sentiment_excited_outline' = 'sentiment_excited_outline',
  'sentiment_extremely_dissatisfied' = 'sentiment_extremely_dissatisfied',
  'sentiment_extremely_dissatisfied_outline' = 'sentiment_extremely_dissatisfied_outline',
  'sentiment_frustrated' = 'sentiment_frustrated',
  'sentiment_frustrated_outline' = 'sentiment_frustrated_outline',
  'sentiment_neutral' = 'sentiment_neutral',
  'sentiment_neutral_outline' = 'sentiment_neutral_outline',
  'sentiment_sad' = 'sentiment_sad',
  'sentiment_sad_outline' = 'sentiment_sad_outline',
  'sentiment_satisfied' = 'sentiment_satisfied',
  'sentiment_satisfied_outline' = 'sentiment_satisfied_outline',
  'sentiment_stressed' = 'sentiment_stressed',
  'sentiment_stressed_outline' = 'sentiment_stressed_outline',
  'sentiment_very_dissatisfied' = 'sentiment_very_dissatisfied',
  'sentiment_very_dissatisfied_outline' = 'sentiment_very_dissatisfied_outline',
  'sentiment_very_satisfied' = 'sentiment_very_satisfied',
  'sentiment_very_satisfied_outline' = 'sentiment_very_satisfied_outline',
  'sentiment_worried' = 'sentiment_worried',
  'sentiment_worried_outline' = 'sentiment_worried_outline',
  'serif' = 'serif',
  'serif_outline' = 'serif_outline',
  'service_toolbox' = 'service_toolbox',
  'service_toolbox_outline' = 'service_toolbox_outline',
  'set_meal' = 'set_meal',
  'set_meal_outline' = 'set_meal_outline',
  'settings' = 'settings',
  'settings_outline' = 'settings_outline',
  'settings_accessibility' = 'settings_accessibility',
  'settings_account_box' = 'settings_account_box',
  'settings_account_box_outline' = 'settings_account_box_outline',
  'settings_alert' = 'settings_alert',
  'settings_alert_outline' = 'settings_alert_outline',
  'settings_applications' = 'settings_applications',
  'settings_applications_outline' = 'settings_applications_outline',
  'settings_b_roll' = 'settings_b_roll',
  'settings_b_roll_outline' = 'settings_b_roll_outline',
  'settings_backup_restore' = 'settings_backup_restore',
  'settings_bluetooth' = 'settings_bluetooth',
  'settings_brightness' = 'settings_brightness',
  'settings_brightness_outline' = 'settings_brightness_outline',
  'settings_cell' = 'settings_cell',
  'settings_cell_outline' = 'settings_cell_outline',
  'settings_cinematic_blur' = 'settings_cinematic_blur',
  'settings_cinematic_blur_outline' = 'settings_cinematic_blur_outline',
  'settings_ethernet' = 'settings_ethernet',
  'settings_heart' = 'settings_heart',
  'settings_heart_outline' = 'settings_heart_outline',
  'settings_input_antenna' = 'settings_input_antenna',
  'settings_input_component' = 'settings_input_component',
  'settings_input_component_outline' = 'settings_input_component_outline',
  'settings_input_hdmi' = 'settings_input_hdmi',
  'settings_input_hdmi_outline' = 'settings_input_hdmi_outline',
  'settings_input_svideo' = 'settings_input_svideo',
  'settings_input_svideo_outline' = 'settings_input_svideo_outline',
  'settings_motion_mode' = 'settings_motion_mode',
  'settings_motion_mode_outline' = 'settings_motion_mode_outline',
  'settings_night_sight' = 'settings_night_sight',
  'settings_night_sight_outline' = 'settings_night_sight_outline',
  'settings_overscan' = 'settings_overscan',
  'settings_overscan_outline' = 'settings_overscan_outline',
  'settings_panorama' = 'settings_panorama',
  'settings_panorama_outline' = 'settings_panorama_outline',
  'settings_phone' = 'settings_phone',
  'settings_phone_outline' = 'settings_phone_outline',
  'settings_photo_camera' = 'settings_photo_camera',
  'settings_photo_camera_outline' = 'settings_photo_camera_outline',
  'settings_power' = 'settings_power',
  'settings_remote' = 'settings_remote',
  'settings_remote_outline' = 'settings_remote_outline',
  'settings_slow_motion' = 'settings_slow_motion',
  'settings_slow_motion_outline' = 'settings_slow_motion_outline',
  'settings_suggest' = 'settings_suggest',
  'settings_suggest_outline' = 'settings_suggest_outline',
  'settings_system_daydream' = 'settings_system_daydream',
  'settings_system_daydream_outline' = 'settings_system_daydream_outline',
  'settings_timelapse' = 'settings_timelapse',
  'settings_timelapse_outline' = 'settings_timelapse_outline',
  'settings_video_camera' = 'settings_video_camera',
  'settings_video_camera_outline' = 'settings_video_camera_outline',
  'settings_voice' = 'settings_voice',
  'settings_voice_outline' = 'settings_voice_outline',
  'settop_component' = 'settop_component',
  'settop_component_outline' = 'settop_component_outline',
  'severe_cold' = 'severe_cold',
  'shadow' = 'shadow',
  'shadow_add' = 'shadow_add',
  'shadow_minus' = 'shadow_minus',
  'shape_line' = 'shape_line',
  'shape_line_outline' = 'shape_line_outline',
  'shapes' = 'shapes',
  'shapes_outline' = 'shapes_outline',
  'share' = 'share',
  'share_outline' = 'share_outline',
  'share_location' = 'share_location',
  'share_off' = 'share_off',
  'share_off_outline' = 'share_off_outline',
  'share_reviews' = 'share_reviews',
  'share_reviews_outline' = 'share_reviews_outline',
  'share_windows' = 'share_windows',
  'sheets_rtl' = 'sheets_rtl',
  'shelf_auto_hide' = 'shelf_auto_hide',
  'shelf_auto_hide_outline' = 'shelf_auto_hide_outline',
  'shelf_position' = 'shelf_position',
  'shelf_position_outline' = 'shelf_position_outline',
  'shelves' = 'shelves',
  'shelves_outline' = 'shelves_outline',
  'shield' = 'shield',
  'shield_outline' = 'shield_outline',
  'shield_lock' = 'shield_lock',
  'shield_lock_outline' = 'shield_lock_outline',
  'shield_locked' = 'shield_locked',
  'shield_locked_outline' = 'shield_locked_outline',
  'shield_moon' = 'shield_moon',
  'shield_moon_outline' = 'shield_moon_outline',
  'shield_person' = 'shield_person',
  'shield_person_outline' = 'shield_person_outline',
  'shield_question' = 'shield_question',
  'shield_question_outline' = 'shield_question_outline',
  'shield_spark' = 'shield_spark',
  'shield_spark_outline' = 'shield_spark_outline',
  'shield_with_heart' = 'shield_with_heart',
  'shield_with_heart_outline' = 'shield_with_heart_outline',
  'shield_with_house' = 'shield_with_house',
  'shield_with_house_outline' = 'shield_with_house_outline',
  'shift' = 'shift',
  'shift_outline' = 'shift_outline',
  'shift_lock' = 'shift_lock',
  'shift_lock_outline' = 'shift_lock_outline',
  'shift_lock_off' = 'shift_lock_off',
  'shift_lock_off_outline' = 'shift_lock_off_outline',
  'shop' = 'shop',
  'shop_outline' = 'shop_outline',
  'shop_two' = 'shop_two',
  'shop_two_outline' = 'shop_two_outline',
  'shopping_bag' = 'shopping_bag',
  'shopping_bag_outline' = 'shopping_bag_outline',
  'shopping_basket' = 'shopping_basket',
  'shopping_basket_outline' = 'shopping_basket_outline',
  'shopping_cart' = 'shopping_cart',
  'shopping_cart_outline' = 'shopping_cart_outline',
  'shopping_cart_checkout' = 'shopping_cart_checkout',
  'shopping_cart_off' = 'shopping_cart_off',
  'shopping_cart_off_outline' = 'shopping_cart_off_outline',
  'shoppingmode' = 'shoppingmode',
  'shoppingmode_outline' = 'shoppingmode_outline',
  'short_stay' = 'short_stay',
  'short_stay_outline' = 'short_stay_outline',
  'short_text' = 'short_text',
  'show_chart' = 'show_chart',
  'shower' = 'shower',
  'shower_outline' = 'shower_outline',
  'shuffle' = 'shuffle',
  'shuffle_on' = 'shuffle_on',
  'shutter_speed' = 'shutter_speed',
  'shutter_speed_outline' = 'shutter_speed_outline',
  'shutter_speed_add' = 'shutter_speed_add',
  'shutter_speed_add_outline' = 'shutter_speed_add_outline',
  'shutter_speed_minus' = 'shutter_speed_minus',
  'shutter_speed_minus_outline' = 'shutter_speed_minus_outline',
  'sick' = 'sick',
  'sick_outline' = 'sick_outline',
  'side_navigation' = 'side_navigation',
  'sign_language' = 'sign_language',
  'sign_language_outline' = 'sign_language_outline',
  'signal_cellular_0_bar' = 'signal_cellular_0_bar',
  'signal_cellular_1_bar' = 'signal_cellular_1_bar',
  'signal_cellular_2_bar' = 'signal_cellular_2_bar',
  'signal_cellular_3_bar' = 'signal_cellular_3_bar',
  'signal_cellular_4_bar' = 'signal_cellular_4_bar',
  'signal_cellular_add' = 'signal_cellular_add',
  'signal_cellular_add_outline' = 'signal_cellular_add_outline',
  'signal_cellular_alt' = 'signal_cellular_alt',
  'signal_cellular_alt_1_bar' = 'signal_cellular_alt_1_bar',
  'signal_cellular_alt_2_bar' = 'signal_cellular_alt_2_bar',
  'signal_cellular_connected_no_internet_0_bar' = 'signal_cellular_connected_no_internet_0_bar',
  'signal_cellular_connected_no_internet_4_bar' = 'signal_cellular_connected_no_internet_4_bar',
  'signal_cellular_nodata' = 'signal_cellular_nodata',
  'signal_cellular_nodata_outline' = 'signal_cellular_nodata_outline',
  'signal_cellular_off' = 'signal_cellular_off',
  'signal_cellular_off_outline' = 'signal_cellular_off_outline',
  'signal_cellular_pause' = 'signal_cellular_pause',
  'signal_cellular_pause_outline' = 'signal_cellular_pause_outline',
  'signal_disconnected' = 'signal_disconnected',
  'signal_wifi_0_bar' = 'signal_wifi_0_bar',
  'signal_wifi_4_bar' = 'signal_wifi_4_bar',
  'signal_wifi_bad' = 'signal_wifi_bad',
  'signal_wifi_bad_outline' = 'signal_wifi_bad_outline',
  'signal_wifi_off' = 'signal_wifi_off',
  'signal_wifi_off_outline' = 'signal_wifi_off_outline',
  'signal_wifi_statusbar_not_connected' = 'signal_wifi_statusbar_not_connected',
  'signature' = 'signature',
  'signpost' = 'signpost',
  'signpost_outline' = 'signpost_outline',
  'sim_card' = 'sim_card',
  'sim_card_outline' = 'sim_card_outline',
  'sim_card_download' = 'sim_card_download',
  'sim_card_download_outline' = 'sim_card_download_outline',
  'single_bed' = 'single_bed',
  'single_bed_outline' = 'single_bed_outline',
  'sip' = 'sip',
  'sip_outline' = 'sip_outline',
  'skateboarding' = 'skateboarding',
  'skeleton' = 'skeleton',
  'skeleton_outline' = 'skeleton_outline',
  'skillet' = 'skillet',
  'skillet_outline' = 'skillet_outline',
  'skillet_cooktop' = 'skillet_cooktop',
  'skillet_cooktop_outline' = 'skillet_cooktop_outline',
  'skip_next' = 'skip_next',
  'skip_next_outline' = 'skip_next_outline',
  'skip_previous' = 'skip_previous',
  'skip_previous_outline' = 'skip_previous_outline',
  'skull' = 'skull',
  'skull_outline' = 'skull_outline',
  'slab_serif' = 'slab_serif',
  'slab_serif_outline' = 'slab_serif_outline',
  'sledding' = 'sledding',
  'sleep' = 'sleep',
  'sleep_outline' = 'sleep_outline',
  'sleep_score' = 'sleep_score',
  'sleep_score_outline' = 'sleep_score_outline',
  'slide_library' = 'slide_library',
  'sliders' = 'sliders',
  'slideshow' = 'slideshow',
  'slideshow_outline' = 'slideshow_outline',
  'slow_motion_video' = 'slow_motion_video',
  'smart_button' = 'smart_button',
  'smart_card_reader' = 'smart_card_reader',
  'smart_card_reader_outline' = 'smart_card_reader_outline',
  'smart_card_reader_off' = 'smart_card_reader_off',
  'smart_card_reader_off_outline' = 'smart_card_reader_off_outline',
  'smart_display' = 'smart_display',
  'smart_display_outline' = 'smart_display_outline',
  'smart_outlet' = 'smart_outlet',
  'smart_outlet_outline' = 'smart_outlet_outline',
  'smart_screen' = 'smart_screen',
  'smart_screen_outline' = 'smart_screen_outline',
  'smart_toy' = 'smart_toy',
  'smart_toy_outline' = 'smart_toy_outline',
  'smart_ventilation' = 'smart_ventilation',
  'smart_ventilation_outline' = 'smart_ventilation_outline',
  'smartphone' = 'smartphone',
  'smartphone_outline' = 'smartphone_outline',
  'smb_share' = 'smb_share',
  'smb_share_outline' = 'smb_share_outline',
  'smoke_free' = 'smoke_free',
  'smoking_rooms' = 'smoking_rooms',
  'sms' = 'sms',
  'sms_outline' = 'sms_outline',
  'sms_failed' = 'sms_failed',
  'sms_failed_outline' = 'sms_failed_outline',
  'snippet_folder' = 'snippet_folder',
  'snippet_folder_outline' = 'snippet_folder_outline',
  'snooze' = 'snooze',
  'snooze_outline' = 'snooze_outline',
  'snowboarding' = 'snowboarding',
  'snowing' = 'snowing',
  'snowing_heavy' = 'snowing_heavy',
  'snowmobile' = 'snowmobile',
  'snowmobile_outline' = 'snowmobile_outline',
  'snowshoeing' = 'snowshoeing',
  'soap' = 'soap',
  'soap_outline' = 'soap_outline',
  'social_distance' = 'social_distance',
  'social_leaderboard' = 'social_leaderboard',
  'social_leaderboard_outline' = 'social_leaderboard_outline',
  'solar_power' = 'solar_power',
  'solar_power_outline' = 'solar_power_outline',
  'sort' = 'sort',
  'sort_by_alpha' = 'sort_by_alpha',
  'sos' = 'sos',
  'sound_detection_dog_barking' = 'sound_detection_dog_barking',
  'sound_detection_dog_barking_outline' = 'sound_detection_dog_barking_outline',
  'sound_detection_glass_break' = 'sound_detection_glass_break',
  'sound_detection_glass_break_outline' = 'sound_detection_glass_break_outline',
  'sound_detection_loud_sound' = 'sound_detection_loud_sound',
  'sound_detection_loud_sound_outline' = 'sound_detection_loud_sound_outline',
  'sound_sampler' = 'sound_sampler',
  'soup_kitchen' = 'soup_kitchen',
  'soup_kitchen_outline' = 'soup_kitchen_outline',
  'source_environment' = 'source_environment',
  'source_notes' = 'source_notes',
  'source_notes_outline' = 'source_notes_outline',
  'south' = 'south',
  'south_america' = 'south_america',
  'south_east' = 'south_east',
  'south_west' = 'south_west',
  'spa' = 'spa',
  'spa_outline' = 'spa_outline',
  'space_bar' = 'space_bar',
  'space_dashboard' = 'space_dashboard',
  'space_dashboard_outline' = 'space_dashboard_outline',
  'spatial_audio' = 'spatial_audio',
  'spatial_audio_outline' = 'spatial_audio_outline',
  'spatial_audio_off' = 'spatial_audio_off',
  'spatial_audio_off_outline' = 'spatial_audio_off_outline',
  'spatial_speaker' = 'spatial_speaker',
  'spatial_speaker_outline' = 'spatial_speaker_outline',
  'spatial_tracking' = 'spatial_tracking',
  'spatial_tracking_outline' = 'spatial_tracking_outline',
  'speaker' = 'speaker',
  'speaker_outline' = 'speaker_outline',
  'speaker_group' = 'speaker_group',
  'speaker_group_outline' = 'speaker_group_outline',
  'speaker_notes' = 'speaker_notes',
  'speaker_notes_outline' = 'speaker_notes_outline',
  'speaker_notes_off' = 'speaker_notes_off',
  'speaker_notes_off_outline' = 'speaker_notes_off_outline',
  'speaker_phone' = 'speaker_phone',
  'speaker_phone_outline' = 'speaker_phone_outline',
  'special_character' = 'special_character',
  'specific_gravity' = 'specific_gravity',
  'specific_gravity_outline' = 'specific_gravity_outline',
  'speech_to_text' = 'speech_to_text',
  'speed' = 'speed',
  'speed_outline' = 'speed_outline',
  'speed_0_25' = 'speed_0_25',
  'speed_0_2x' = 'speed_0_2x',
  'speed_0_5' = 'speed_0_5',
  'speed_0_5x' = 'speed_0_5x',
  'speed_0_75' = 'speed_0_75',
  'speed_0_7x' = 'speed_0_7x',
  'speed_1_2' = 'speed_1_2',
  'speed_1_25' = 'speed_1_25',
  'speed_1_2x' = 'speed_1_2x',
  'speed_1_5' = 'speed_1_5',
  'speed_1_5x' = 'speed_1_5x',
  'speed_1_75' = 'speed_1_75',
  'speed_1_7x' = 'speed_1_7x',
  'speed_2x' = 'speed_2x',
  'spellcheck' = 'spellcheck',
  'splitscreen' = 'splitscreen',
  'splitscreen_outline' = 'splitscreen_outline',
  'splitscreen_add' = 'splitscreen_add',
  'splitscreen_add_outline' = 'splitscreen_add_outline',
  'splitscreen_bottom' = 'splitscreen_bottom',
  'splitscreen_left' = 'splitscreen_left',
  'splitscreen_right' = 'splitscreen_right',
  'splitscreen_top' = 'splitscreen_top',
  'splitscreen_vertical_add' = 'splitscreen_vertical_add',
  'splitscreen_vertical_add_outline' = 'splitscreen_vertical_add_outline',
  'spo2' = 'spo2',
  'spo2_outline' = 'spo2_outline',
  'spoke' = 'spoke',
  'spoke_outline' = 'spoke_outline',
  'spoof' = 'spoof',
  'sports' = 'sports',
  'sports_outline' = 'sports_outline',
  'sports_and_outdoors' = 'sports_and_outdoors',
  'sports_bar' = 'sports_bar',
  'sports_bar_outline' = 'sports_bar_outline',
  'sports_baseball' = 'sports_baseball',
  'sports_baseball_outline' = 'sports_baseball_outline',
  'sports_basketball' = 'sports_basketball',
  'sports_basketball_outline' = 'sports_basketball_outline',
  'sports_cricket' = 'sports_cricket',
  'sports_cricket_outline' = 'sports_cricket_outline',
  'sports_esports' = 'sports_esports',
  'sports_esports_outline' = 'sports_esports_outline',
  'sports_football' = 'sports_football',
  'sports_football_outline' = 'sports_football_outline',
  'sports_golf' = 'sports_golf',
  'sports_golf_outline' = 'sports_golf_outline',
  'sports_gymnastics' = 'sports_gymnastics',
  'sports_handball' = 'sports_handball',
  'sports_hockey' = 'sports_hockey',
  'sports_kabaddi' = 'sports_kabaddi',
  'sports_martial_arts' = 'sports_martial_arts',
  'sports_mma' = 'sports_mma',
  'sports_mma_outline' = 'sports_mma_outline',
  'sports_motorsports' = 'sports_motorsports',
  'sports_motorsports_outline' = 'sports_motorsports_outline',
  'sports_rugby' = 'sports_rugby',
  'sports_rugby_outline' = 'sports_rugby_outline',
  'sports_score' = 'sports_score',
  'sports_soccer' = 'sports_soccer',
  'sports_tennis' = 'sports_tennis',
  'sports_tennis_outline' = 'sports_tennis_outline',
  'sports_volleyball' = 'sports_volleyball',
  'sports_volleyball_outline' = 'sports_volleyball_outline',
  'sprinkler' = 'sprinkler',
  'sprint' = 'sprint',
  'square' = 'square',
  'square_outline' = 'square_outline',
  'square_foot' = 'square_foot',
  'ssid_chart' = 'ssid_chart',
  'stack' = 'stack',
  'stack_outline' = 'stack_outline',
  'stack_off' = 'stack_off',
  'stack_off_outline' = 'stack_off_outline',
  'stack_star' = 'stack_star',
  'stack_star_outline' = 'stack_star_outline',
  'stacked_bar_chart' = 'stacked_bar_chart',
  'stacked_email' = 'stacked_email',
  'stacked_email_outline' = 'stacked_email_outline',
  'stacked_inbox' = 'stacked_inbox',
  'stacked_inbox_outline' = 'stacked_inbox_outline',
  'stacked_line_chart' = 'stacked_line_chart',
  'stacks' = 'stacks',
  'stacks_outline' = 'stacks_outline',
  'stadia_controller' = 'stadia_controller',
  'stadia_controller_outline' = 'stadia_controller_outline',
  'stadium' = 'stadium',
  'stadium_outline' = 'stadium_outline',
  'stairs' = 'stairs',
  'stairs_outline' = 'stairs_outline',
  'star' = 'star',
  'star_outline' = 'star_outline',
  'star_half' = 'star_half',
  'star_rate' = 'star_rate',
  'star_rate_outline' = 'star_rate_outline',
  'star_rate_half' = 'star_rate_half',
  'stars' = 'stars',
  'stars_outline' = 'stars_outline',
  'start' = 'start',
  'stat_0' = 'stat_0',
  'stat_0_outline' = 'stat_0_outline',
  'stat_1' = 'stat_1',
  'stat_2' = 'stat_2',
  'stat_3' = 'stat_3',
  'stat_minus_1' = 'stat_minus_1',
  'stat_minus_2' = 'stat_minus_2',
  'stat_minus_3' = 'stat_minus_3',
  'stay_current_landscape' = 'stay_current_landscape',
  'stay_current_landscape_outline' = 'stay_current_landscape_outline',
  'step' = 'step',
  'step_into' = 'step_into',
  'step_out' = 'step_out',
  'step_over' = 'step_over',
  'steppers' = 'steppers',
  'steps' = 'steps',
  'steps_outline' = 'steps_outline',
  'stethoscope' = 'stethoscope',
  'stethoscope_outline' = 'stethoscope_outline',
  'stethoscope_arrow' = 'stethoscope_arrow',
  'stethoscope_check' = 'stethoscope_check',
  'sticky_note' = 'sticky_note',
  'sticky_note_outline' = 'sticky_note_outline',
  'sticky_note_2' = 'sticky_note_2',
  'sticky_note_2_outline' = 'sticky_note_2_outline',
  'stock_media' = 'stock_media',
  'stock_media_outline' = 'stock_media_outline',
  'stockpot' = 'stockpot',
  'stockpot_outline' = 'stockpot_outline',
  'stop' = 'stop',
  'stop_outline' = 'stop_outline',
  'stop_circle' = 'stop_circle',
  'stop_circle_outline' = 'stop_circle_outline',
  'stop_screen_share' = 'stop_screen_share',
  'stop_screen_share_outline' = 'stop_screen_share_outline',
  'storage' = 'storage',
  'store' = 'store',
  'store_outline' = 'store_outline',
  'storefront' = 'storefront',
  'storefront_outline' = 'storefront_outline',
  'storm' = 'storm',
  'storm_outline' = 'storm_outline',
  'straight' = 'straight',
  'straighten' = 'straighten',
  'straighten_outline' = 'straighten_outline',
  'strategy' = 'strategy',
  'strategy_outline' = 'strategy_outline',
  'stream' = 'stream',
  'stream_apps' = 'stream_apps',
  'stream_apps_outline' = 'stream_apps_outline',
  'streetview' = 'streetview',
  'stress_management' = 'stress_management',
  'stress_management_outline' = 'stress_management_outline',
  'strikethrough_s' = 'strikethrough_s',
  'stroke_full' = 'stroke_full',
  'stroke_partial' = 'stroke_partial',
  'stroller' = 'stroller',
  'stroller_outline' = 'stroller_outline',
  'style' = 'style',
  'style_outline' = 'style_outline',
  'styler' = 'styler',
  'styler_outline' = 'styler_outline',
  'stylus' = 'stylus',
  'stylus_outline' = 'stylus_outline',
  'stylus_laser_pointer' = 'stylus_laser_pointer',
  'stylus_note' = 'stylus_note',
  'stylus_note_outline' = 'stylus_note_outline',
  'subdirectory_arrow_left' = 'subdirectory_arrow_left',
  'subdirectory_arrow_right' = 'subdirectory_arrow_right',
  'subheader' = 'subheader',
  'subheader_outline' = 'subheader_outline',
  'subject' = 'subject',
  'subscript' = 'subscript',
  'subscriptions' = 'subscriptions',
  'subscriptions_outline' = 'subscriptions_outline',
  'subtitles' = 'subtitles',
  'subtitles_outline' = 'subtitles_outline',
  'subtitles_off' = 'subtitles_off',
  'subtitles_off_outline' = 'subtitles_off_outline',
  'subway' = 'subway',
  'summarize' = 'summarize',
  'summarize_outline' = 'summarize_outline',
  'sunny' = 'sunny',
  'sunny_outline' = 'sunny_outline',
  'sunny_snowing' = 'sunny_snowing',
  'sunny_snowing_outline' = 'sunny_snowing_outline',
  'superscript' = 'superscript',
  'supervised_user_circle' = 'supervised_user_circle',
  'supervised_user_circle_outline' = 'supervised_user_circle_outline',
  'supervised_user_circle_off' = 'supervised_user_circle_off',
  'supervised_user_circle_off_outline' = 'supervised_user_circle_off_outline',
  'supervisor_account' = 'supervisor_account',
  'supervisor_account_outline' = 'supervisor_account_outline',
  'support' = 'support',
  'support_agent' = 'support_agent',
  'surfing' = 'surfing',
  'surgical' = 'surgical',
  'surgical_outline' = 'surgical_outline',
  'surround_sound' = 'surround_sound',
  'surround_sound_outline' = 'surround_sound_outline',
  'swap_calls' = 'swap_calls',
  'swap_driving_apps' = 'swap_driving_apps',
  'swap_driving_apps_outline' = 'swap_driving_apps_outline',
  'swap_driving_apps_wheel' = 'swap_driving_apps_wheel',
  'swap_driving_apps_wheel_outline' = 'swap_driving_apps_wheel_outline',
  'swap_horiz' = 'swap_horiz',
  'swap_horizontal_circle' = 'swap_horizontal_circle',
  'swap_horizontal_circle_outline' = 'swap_horizontal_circle_outline',
  'swap_vert' = 'swap_vert',
  'swap_vertical_circle' = 'swap_vertical_circle',
  'swap_vertical_circle_outline' = 'swap_vertical_circle_outline',
  'sweep' = 'sweep',
  'sweep_outline' = 'sweep_outline',
  'swipe' = 'swipe',
  'swipe_outline' = 'swipe_outline',
  'swipe_down' = 'swipe_down',
  'swipe_down_outline' = 'swipe_down_outline',
  'swipe_down_alt' = 'swipe_down_alt',
  'swipe_down_alt_outline' = 'swipe_down_alt_outline',
  'swipe_left' = 'swipe_left',
  'swipe_left_outline' = 'swipe_left_outline',
  'swipe_left_alt' = 'swipe_left_alt',
  'swipe_left_alt_outline' = 'swipe_left_alt_outline',
  'swipe_right' = 'swipe_right',
  'swipe_right_outline' = 'swipe_right_outline',
  'swipe_right_alt' = 'swipe_right_alt',
  'swipe_right_alt_outline' = 'swipe_right_alt_outline',
  'swipe_up' = 'swipe_up',
  'swipe_up_outline' = 'swipe_up_outline',
  'swipe_up_alt' = 'swipe_up_alt',
  'swipe_up_alt_outline' = 'swipe_up_alt_outline',
  'swipe_vertical' = 'swipe_vertical',
  'swipe_vertical_outline' = 'swipe_vertical_outline',
  'switch' = 'switch',
  'switch_outline' = 'switch_outline',
  'switch_access' = 'switch_access',
  'switch_access_outline' = 'switch_access_outline',
  'switch_access_2' = 'switch_access_2',
  'switch_access_2_outline' = 'switch_access_2_outline',
  'switch_access_shortcut' = 'switch_access_shortcut',
  'switch_access_shortcut_add' = 'switch_access_shortcut_add',
  'switch_account' = 'switch_account',
  'switch_account_outline' = 'switch_account_outline',
  'switch_camera' = 'switch_camera',
  'switch_camera_outline' = 'switch_camera_outline',
  'switch_left' = 'switch_left',
  'switch_right' = 'switch_right',
  'switch_video' = 'switch_video',
  'switch_video_outline' = 'switch_video_outline',
  'switches' = 'switches',
  'switches_outline' = 'switches_outline',
  'sword_rose' = 'sword_rose',
  'sword_rose_outline' = 'sword_rose_outline',
  'swords' = 'swords',
  'swords_outline' = 'swords_outline',
  'symptoms' = 'symptoms',
  'symptoms_outline' = 'symptoms_outline',
  'synagogue' = 'synagogue',
  'synagogue_outline' = 'synagogue_outline',
  'sync' = 'sync',
  'sync_alt' = 'sync_alt',
  'sync_disabled' = 'sync_disabled',
  'sync_lock' = 'sync_lock',
  'sync_problem' = 'sync_problem',
  'sync_problem_outline' = 'sync_problem_outline',
  'sync_saved_locally' = 'sync_saved_locally',
  'sync_saved_locally_outline' = 'sync_saved_locally_outline',
  'syringe' = 'syringe',
  'syringe_outline' = 'syringe_outline',
  'system_update' = 'system_update',
  'system_update_outline' = 'system_update_outline',
  'system_update_alt' = 'system_update_alt',
  'tab' = 'tab',
  'tab_outline' = 'tab_outline',
  'tab_close' = 'tab_close',
  'tab_close_outline' = 'tab_close_outline',
  'tab_close_right' = 'tab_close_right',
  'tab_close_right_outline' = 'tab_close_right_outline',
  'tab_duplicate' = 'tab_duplicate',
  'tab_duplicate_outline' = 'tab_duplicate_outline',
  'tab_group' = 'tab_group',
  'tab_group_outline' = 'tab_group_outline',
  'tab_move' = 'tab_move',
  'tab_new_right' = 'tab_new_right',
  'tab_new_right_outline' = 'tab_new_right_outline',
  'tab_recent' = 'tab_recent',
  'tab_recent_outline' = 'tab_recent_outline',
  'tab_unselected' = 'tab_unselected',
  'table' = 'table',
  'table_bar' = 'table_bar',
  'table_bar_outline' = 'table_bar_outline',
  'table_chart' = 'table_chart',
  'table_chart_outline' = 'table_chart_outline',
  'table_chart_view' = 'table_chart_view',
  'table_chart_view_outline' = 'table_chart_view_outline',
  'table_lamp' = 'table_lamp',
  'table_lamp_outline' = 'table_lamp_outline',
  'table_restaurant' = 'table_restaurant',
  'table_restaurant_outline' = 'table_restaurant_outline',
  'table_rows' = 'table_rows',
  'table_rows_outline' = 'table_rows_outline',
  'table_rows_narrow' = 'table_rows_narrow',
  'table_rows_narrow_outline' = 'table_rows_narrow_outline',
  'table_view' = 'table_view',
  'tablet' = 'tablet',
  'tablet_outline' = 'tablet_outline',
  'tablet_android' = 'tablet_android',
  'tablet_android_outline' = 'tablet_android_outline',
  'tablet_mac' = 'tablet_mac',
  'tablet_mac_outline' = 'tablet_mac_outline',
  'tabs' = 'tabs',
  'tabs_outline' = 'tabs_outline',
  'tactic' = 'tactic',
  'tag' = 'tag',
  'takeout_dining' = 'takeout_dining',
  'takeout_dining_outline' = 'takeout_dining_outline',
  'tamper_detection_off' = 'tamper_detection_off',
  'tamper_detection_off_outline' = 'tamper_detection_off_outline',
  'tamper_detection_on' = 'tamper_detection_on',
  'tamper_detection_on_outline' = 'tamper_detection_on_outline',
  'tap_and_play' = 'tap_and_play',
  'tap_and_play_outline' = 'tap_and_play_outline',
  'tapas' = 'tapas',
  'tapas_outline' = 'tapas_outline',
  'target' = 'target',
  'task' = 'task',
  'task_outline' = 'task_outline',
  'task_alt' = 'task_alt',
  'taunt' = 'taunt',
  'taxi_alert' = 'taxi_alert',
  'taxi_alert_outline' = 'taxi_alert_outline',
  'team_dashboard' = 'team_dashboard',
  'team_dashboard_outline' = 'team_dashboard_outline',
  'temp_preferences_custom' = 'temp_preferences_custom',
  'temp_preferences_custom_outline' = 'temp_preferences_custom_outline',
  'temp_preferences_eco' = 'temp_preferences_eco',
  'temp_preferences_eco_outline' = 'temp_preferences_eco_outline',
  'temple_buddhist' = 'temple_buddhist',
  'temple_buddhist_outline' = 'temple_buddhist_outline',
  'temple_hindu' = 'temple_hindu',
  'temple_hindu_outline' = 'temple_hindu_outline',
  'tenancy' = 'tenancy',
  'tenancy_outline' = 'tenancy_outline',
  'terminal' = 'terminal',
  'text_ad' = 'text_ad',
  'text_ad_outline' = 'text_ad_outline',
  'text_decrease' = 'text_decrease',
  'text_fields' = 'text_fields',
  'text_fields_alt' = 'text_fields_alt',
  'text_format' = 'text_format',
  'text_increase' = 'text_increase',
  'text_rotate_up' = 'text_rotate_up',
  'text_rotate_vertical' = 'text_rotate_vertical',
  'text_rotation_angledown' = 'text_rotation_angledown',
  'text_rotation_angleup' = 'text_rotation_angleup',
  'text_rotation_down' = 'text_rotation_down',
  'text_rotation_none' = 'text_rotation_none',
  'text_select_end' = 'text_select_end',
  'text_select_jump_to_beginning' = 'text_select_jump_to_beginning',
  'text_select_jump_to_end' = 'text_select_jump_to_end',
  'text_select_move_back_character' = 'text_select_move_back_character',
  'text_select_move_back_word' = 'text_select_move_back_word',
  'text_select_move_back_word_outline' = 'text_select_move_back_word_outline',
  'text_select_move_down' = 'text_select_move_down',
  'text_select_move_forward_character' = 'text_select_move_forward_character',
  'text_select_move_forward_word' = 'text_select_move_forward_word',
  'text_select_move_forward_word_outline' = 'text_select_move_forward_word_outline',
  'text_select_move_up' = 'text_select_move_up',
  'text_select_start' = 'text_select_start',
  'text_snippet' = 'text_snippet',
  'text_snippet_outline' = 'text_snippet_outline',
  'text_to_speech' = 'text_to_speech',
  'text_up' = 'text_up',
  'texture' = 'texture',
  'texture_add' = 'texture_add',
  'texture_minus' = 'texture_minus',
  'theater_comedy' = 'theater_comedy',
  'theater_comedy_outline' = 'theater_comedy_outline',
  'theaters' = 'theaters',
  'theaters_outline' = 'theaters_outline',
  'thermometer' = 'thermometer',
  'thermometer_outline' = 'thermometer_outline',
  'thermometer_add' = 'thermometer_add',
  'thermometer_gain_outline' = 'thermometer_gain_outline',
  'thermometer_loss' = 'thermometer_loss',
  'thermometer_loss_outline' = 'thermometer_loss_outline',
  'thermometer_minus' = 'thermometer_minus',
  'thermostat' = 'thermostat',
  'thermostat_auto' = 'thermostat_auto',
  'thermostat_carbon' = 'thermostat_carbon',
  'thermostat_carbon_outline' = 'thermostat_carbon_outline',
  'things_to_do' = 'things_to_do',
  'things_to_do_outline' = 'things_to_do_outline',
  'thread_unread' = 'thread_unread',
  'thumb_down' = 'thumb_down',
  'thumb_down_outline' = 'thumb_down_outline',
  'thumb_up' = 'thumb_up',
  'thumb_up_outline' = 'thumb_up_outline',
  'thumbnail_bar' = 'thumbnail_bar',
  'thumbnail_bar_outline' = 'thumbnail_bar_outline',
  'thumbs_up_down' = 'thumbs_up_down',
  'thumbs_up_down_outline' = 'thumbs_up_down_outline',
  'thunderstorm' = 'thunderstorm',
  'thunderstorm_outline' = 'thunderstorm_outline',
  'tibia' = 'tibia',
  'tibia_outline' = 'tibia_outline',
  'tibia_alt' = 'tibia_alt',
  'tibia_alt_outline' = 'tibia_alt_outline',
  'time_auto' = 'time_auto',
  'time_auto_outline' = 'time_auto_outline',
  'timelapse' = 'timelapse',
  'timelapse_outline' = 'timelapse_outline',
  'timeline' = 'timeline',
  'timer' = 'timer',
  'timer_outline' = 'timer_outline',
  'timer_10' = 'timer_10',
  'timer_10_alt_1' = 'timer_10_alt_1',
  'timer_10_alt_1_outline' = 'timer_10_alt_1_outline',
  'timer_10_select' = 'timer_10_select',
  'timer_3' = 'timer_3',
  'timer_3_alt_1' = 'timer_3_alt_1',
  'timer_3_alt_1_outline' = 'timer_3_alt_1_outline',
  'timer_3_select' = 'timer_3_select',
  'timer_5' = 'timer_5',
  'timer_5_outline' = 'timer_5_outline',
  'timer_5_shutter' = 'timer_5_shutter',
  'timer_5_shutter_outline' = 'timer_5_shutter_outline',
  'timer_off' = 'timer_off',
  'timer_off_outline' = 'timer_off_outline',
  'timer_pause' = 'timer_pause',
  'timer_pause_outline' = 'timer_pause_outline',
  'timer_play' = 'timer_play',
  'timer_play_outline' = 'timer_play_outline',
  'tips_and_updates' = 'tips_and_updates',
  'tips_and_updates_outline' = 'tips_and_updates_outline',
  'tire_repair' = 'tire_repair',
  'tire_repair_outline' = 'tire_repair_outline',
  'title' = 'title',
  'titlecase' = 'titlecase',
  'toast' = 'toast',
  'toast_outline' = 'toast_outline',
  'toc' = 'toc',
  'today' = 'today',
  'today_outline' = 'today_outline',
  'toggle_off' = 'toggle_off',
  'toggle_off_outline' = 'toggle_off_outline',
  'toggle_on' = 'toggle_on',
  'toggle_on_outline' = 'toggle_on_outline',
  'token' = 'token',
  'token_outline' = 'token_outline',
  'toll' = 'toll',
  'toll_outline' = 'toll_outline',
  'tonality' = 'tonality',
  'toolbar' = 'toolbar',
  'toolbar_outline' = 'toolbar_outline',
  'tools_flat_head' = 'tools_flat_head',
  'tools_flat_head_outline' = 'tools_flat_head_outline',
  'tools_installation_kit' = 'tools_installation_kit',
  'tools_installation_kit_outline' = 'tools_installation_kit_outline',
  'tools_ladder' = 'tools_ladder',
  'tools_level' = 'tools_level',
  'tools_level_outline' = 'tools_level_outline',
  'tools_phillips' = 'tools_phillips',
  'tools_phillips_outline' = 'tools_phillips_outline',
  'tools_pliers_wire_stripper' = 'tools_pliers_wire_stripper',
  'tools_pliers_wire_stripper_outline' = 'tools_pliers_wire_stripper_outline',
  'tools_power_drill' = 'tools_power_drill',
  'tools_power_drill_outline' = 'tools_power_drill_outline',
  'tools_wrench' = 'tools_wrench',
  'tools_wrench_outline' = 'tools_wrench_outline',
  'tooltip' = 'tooltip',
  'tooltip_outline' = 'tooltip_outline',
  'top_panel_close' = 'top_panel_close',
  'top_panel_close_outline' = 'top_panel_close_outline',
  'top_panel_open' = 'top_panel_open',
  'top_panel_open_outline' = 'top_panel_open_outline',
  'topic' = 'topic',
  'topic_outline' = 'topic_outline',
  'tornado' = 'tornado',
  'tornado_outline' = 'tornado_outline',
  'total_dissolved_solids' = 'total_dissolved_solids',
  'total_dissolved_solids_outline' = 'total_dissolved_solids_outline',
  'touch_app' = 'touch_app',
  'touch_app_outline' = 'touch_app_outline',
  'touchpad_mouse' = 'touchpad_mouse',
  'touchpad_mouse_outline' = 'touchpad_mouse_outline',
  'touchpad_mouse_off' = 'touchpad_mouse_off',
  'touchpad_mouse_off_outline' = 'touchpad_mouse_off_outline',
  'tour' = 'tour',
  'tour_outline' = 'tour_outline',
  'toys' = 'toys',
  'toys_outline' = 'toys_outline',
  'toys_and_games' = 'toys_and_games',
  'toys_and_games_outline' = 'toys_and_games_outline',
  'toys_fan' = 'toys_fan',
  'track_changes' = 'track_changes',
  'trackpad_input' = 'trackpad_input',
  'trackpad_input_outline' = 'trackpad_input_outline',
  'traffic' = 'traffic',
  'traffic_outline' = 'traffic_outline',
  'trail_length' = 'trail_length',
  'trail_length_outline' = 'trail_length_outline',
  'trail_length_medium' = 'trail_length_medium',
  'trail_length_medium_outline' = 'trail_length_medium_outline',
  'trail_length_short' = 'trail_length_short',
  'trail_length_short_outline' = 'trail_length_short_outline',
  'train' = 'train',
  'train_outline' = 'train_outline',
  'tram' = 'tram',
  'tram_outline' = 'tram_outline',
  'transcribe' = 'transcribe',
  'transcribe_outline' = 'transcribe_outline',
  'transfer_within_a_station' = 'transfer_within_a_station',
  'transform' = 'transform',
  'transgender' = 'transgender',
  'transit_enterexit' = 'transit_enterexit',
  'transition_chop' = 'transition_chop',
  'transition_chop_outline' = 'transition_chop_outline',
  'transition_dissolve' = 'transition_dissolve',
  'transition_fade' = 'transition_fade',
  'transition_push' = 'transition_push',
  'transition_push_outline' = 'transition_push_outline',
  'transition_slide' = 'transition_slide',
  'transition_slide_outline' = 'transition_slide_outline',
  'translate' = 'translate',
  'transportation' = 'transportation',
  'transportation_outline' = 'transportation_outline',
  'travel' = 'travel',
  'travel_explore' = 'travel_explore',
  'travel_luggage_and_bags' = 'travel_luggage_and_bags',
  'travel_luggage_and_bags_outline' = 'travel_luggage_and_bags_outline',
  'trending_down' = 'trending_down',
  'trending_flat' = 'trending_flat',
  'trending_up' = 'trending_up',
  'trip' = 'trip',
  'trip_outline' = 'trip_outline',
  'trip_origin' = 'trip_origin',
  'trolley' = 'trolley',
  'trolley_outline' = 'trolley_outline',
  'trophy' = 'trophy',
  'trophy_outline' = 'trophy_outline',
  'troubleshoot' = 'troubleshoot',
  'tsunami' = 'tsunami',
  'tsunami_outline' = 'tsunami_outline',
  'tsv' = 'tsv',
  'tsv_outline' = 'tsv_outline',
  'tty' = 'tty',
  'tty_outline' = 'tty_outline',
  'tune' = 'tune',
  'turn_left' = 'turn_left',
  'turn_right' = 'turn_right',
  'turn_sharp_left' = 'turn_sharp_left',
  'turn_sharp_right' = 'turn_sharp_right',
  'turn_slight_left' = 'turn_slight_left',
  'turn_slight_right' = 'turn_slight_right',
  'tv' = 'tv',
  'tv_outline' = 'tv_outline',
  'tv_gen' = 'tv_gen',
  'tv_gen_outline' = 'tv_gen_outline',
  'tv_guide' = 'tv_guide',
  'tv_guide_outline' = 'tv_guide_outline',
  'tv_off' = 'tv_off',
  'tv_off_outline' = 'tv_off_outline',
  'tv_options_edit_channels' = 'tv_options_edit_channels',
  'tv_options_input_settings' = 'tv_options_input_settings',
  'tv_options_input_settings_outline' = 'tv_options_input_settings_outline',
  'tv_remote' = 'tv_remote',
  'tv_remote_outline' = 'tv_remote_outline',
  'tv_signin' = 'tv_signin',
  'tv_signin_outline' = 'tv_signin_outline',
  'tv_with_assistant' = 'tv_with_assistant',
  'tv_with_assistant_outline' = 'tv_with_assistant_outline',
  'two_pager' = 'two_pager',
  'two_pager_outline' = 'two_pager_outline',
  'two_wheeler' = 'two_wheeler',
  'two_wheeler_outline' = 'two_wheeler_outline',
  'type_specimen' = 'type_specimen',
  'type_specimen_outline' = 'type_specimen_outline',
  'u_turn_left' = 'u_turn_left',
  'u_turn_right' = 'u_turn_right',
  'ulna_radius' = 'ulna_radius',
  'ulna_radius_outline' = 'ulna_radius_outline',
  'ulna_radius_alt' = 'ulna_radius_alt',
  'ulna_radius_alt_outline' = 'ulna_radius_alt_outline',
  'umbrella' = 'umbrella',
  'unarchive' = 'unarchive',
  'unarchive_outline' = 'unarchive_outline',
  'undo' = 'undo',
  'unfloat_landscape' = 'unfloat_landscape',
  'unfloat_landscape_outline' = 'unfloat_landscape_outline',
  'unfloat_portrait' = 'unfloat_portrait',
  'unfloat_portrait_outline' = 'unfloat_portrait_outline',
  'unfold_less' = 'unfold_less',
  'unfold_less_double' = 'unfold_less_double',
  'unfold_more' = 'unfold_more',
  'unfold_more_double' = 'unfold_more_double',
  'ungroup' = 'ungroup',
  'universal_currency' = 'universal_currency',
  'universal_currency_outline' = 'universal_currency_outline',
  'universal_currency_alt' = 'universal_currency_alt',
  'universal_currency_alt_outline' = 'universal_currency_alt_outline',
  'universal_local' = 'universal_local',
  'universal_local_outline' = 'universal_local_outline',
  'unknown_5' = 'unknown_5',
  'unknown_document' = 'unknown_document',
  'unknown_document_outline' = 'unknown_document_outline',
  'unknown_med' = 'unknown_med',
  'unlicense' = 'unlicense',
  'unlicense_outline' = 'unlicense_outline',
  'unpublished' = 'unpublished',
  'unpublished_outline' = 'unpublished_outline',
  'unsubscribe' = 'unsubscribe',
  'unsubscribe_outline' = 'unsubscribe_outline',
  'upcoming' = 'upcoming',
  'upcoming_outline' = 'upcoming_outline',
  'update' = 'update',
  'update_disabled' = 'update_disabled',
  'upgrade' = 'upgrade',
  'upload' = 'upload',
  'upload_2' = 'upload_2',
  'upload_2_outline' = 'upload_2_outline',
  'upload_file' = 'upload_file',
  'upload_file_outline' = 'upload_file_outline',
  'uppercase' = 'uppercase',
  'urology' = 'urology',
  'urology_outline' = 'urology_outline',
  'usb' = 'usb',
  'usb_off' = 'usb_off',
  'user_attributes' = 'user_attributes',
  'user_attributes_outline' = 'user_attributes_outline',
  'vaccines' = 'vaccines',
  'vaccines_outline' = 'vaccines_outline',
  'vacuum' = 'vacuum',
  'vacuum_outline' = 'vacuum_outline',
  'valve' = 'valve',
  'valve_outline' = 'valve_outline',
  'vape_free' = 'vape_free',
  'vaping_rooms' = 'vaping_rooms',
  'variable_add' = 'variable_add',
  'variable_add_outline' = 'variable_add_outline',
  'variable_insert' = 'variable_insert',
  'variable_insert_outline' = 'variable_insert_outline',
  'variable_remove' = 'variable_remove',
  'variable_remove_outline' = 'variable_remove_outline',
  'variables' = 'variables',
  'variables_outline' = 'variables_outline',
  'ventilator' = 'ventilator',
  'ventilator_outline' = 'ventilator_outline',
  'verified' = 'verified',
  'verified_outline' = 'verified_outline',
  'verified_user' = 'verified_user',
  'verified_user_outline' = 'verified_user_outline',
  'vertical_align_bottom' = 'vertical_align_bottom',
  'vertical_align_center' = 'vertical_align_center',
  'vertical_align_top' = 'vertical_align_top',
  'vertical_distribute' = 'vertical_distribute',
  'vertical_shades' = 'vertical_shades',
  'vertical_shades_outline' = 'vertical_shades_outline',
  'vertical_shades_closed' = 'vertical_shades_closed',
  'vertical_split' = 'vertical_split',
  'vertical_split_outline' = 'vertical_split_outline',
  'vibration' = 'vibration',
  'vibration_outline' = 'vibration_outline',
  'video_call' = 'video_call',
  'video_call_outline' = 'video_call_outline',
  'video_camera_back' = 'video_camera_back',
  'video_camera_back_outline' = 'video_camera_back_outline',
  'video_camera_front' = 'video_camera_front',
  'video_camera_front_outline' = 'video_camera_front_outline',
  'video_camera_front_off' = 'video_camera_front_off',
  'video_camera_front_off_outline' = 'video_camera_front_off_outline',
  'video_chat' = 'video_chat',
  'video_chat_outline' = 'video_chat_outline',
  'video_file' = 'video_file',
  'video_file_outline' = 'video_file_outline',
  'video_label' = 'video_label',
  'video_library' = 'video_library',
  'video_library_outline' = 'video_library_outline',
  'video_search' = 'video_search',
  'video_settings' = 'video_settings',
  'video_stable' = 'video_stable',
  'video_stable_outline' = 'video_stable_outline',
  'videocam' = 'videocam',
  'videocam_outline' = 'videocam_outline',
  'videocam_off' = 'videocam_off',
  'videocam_off_outline' = 'videocam_off_outline',
  'videogame_asset' = 'videogame_asset',
  'videogame_asset_outline' = 'videogame_asset_outline',
  'videogame_asset_off' = 'videogame_asset_off',
  'videogame_asset_off_outline' = 'videogame_asset_off_outline',
  'view_agenda_outline' = 'view_agenda_outline',
  'view_array' = 'view_array',
  'view_array_outline' = 'view_array_outline',
  'view_carousel' = 'view_carousel',
  'view_carousel_outline' = 'view_carousel_outline',
  'view_column' = 'view_column',
  'view_column_outline' = 'view_column_outline',
  'view_column_2' = 'view_column_2',
  'view_column_2_outline' = 'view_column_2_outline',
  'view_comfy' = 'view_comfy',
  'view_comfy_outline' = 'view_comfy_outline',
  'view_comfy_alt' = 'view_comfy_alt',
  'view_comfy_alt_outline' = 'view_comfy_alt_outline',
  'view_compact' = 'view_compact',
  'view_compact_outline' = 'view_compact_outline',
  'view_compact_alt' = 'view_compact_alt',
  'view_compact_alt_outline' = 'view_compact_alt_outline',
  'view_cozy' = 'view_cozy',
  'view_cozy_outline' = 'view_cozy_outline',
  'view_day' = 'view_day',
  'view_day_outline' = 'view_day_outline',
  'view_headline' = 'view_headline',
  'view_in_ar' = 'view_in_ar',
  'view_in_ar_outline' = 'view_in_ar_outline',
  'view_in_ar_new' = 'view_in_ar_new',
  'view_in_ar_new_outline' = 'view_in_ar_new_outline',
  'view_in_ar_off' = 'view_in_ar_off',
  'view_in_ar_off_outline' = 'view_in_ar_off_outline',
  'view_kanban' = 'view_kanban',
  'view_kanban_outline' = 'view_kanban_outline',
  'view_list' = 'view_list',
  'view_list_outline' = 'view_list_outline',
  'view_module' = 'view_module',
  'view_module_outline' = 'view_module_outline',
  'view_quilt' = 'view_quilt',
  'view_quilt_outline' = 'view_quilt_outline',
  'view_real_size' = 'view_real_size',
  'view_sidebar' = 'view_sidebar',
  'view_sidebar_outline' = 'view_sidebar_outline',
  'view_stream' = 'view_stream',
  'view_stream_outline' = 'view_stream_outline',
  'view_timeline' = 'view_timeline',
  'view_timeline_outline' = 'view_timeline_outline',
  'view_week' = 'view_week',
  'view_week_outline' = 'view_week_outline',
  'vignette' = 'vignette',
  'vignette_outline' = 'vignette_outline',
  'villa' = 'villa',
  'villa_outline' = 'villa_outline',
  'visibility' = 'visibility',
  'visibility_outline' = 'visibility_outline',
  'visibility_lock' = 'visibility_lock',
  'visibility_lock_outline' = 'visibility_lock_outline',
  'visibility_off' = 'visibility_off',
  'visibility_off_outline' = 'visibility_off_outline',
  'vital_signs' = 'vital_signs',
  'vitals' = 'vitals',
  'vo2_max' = 'vo2_max',
  'vo2_max_outline' = 'vo2_max_outline',
  'voice_chat' = 'voice_chat',
  'voice_chat_outline' = 'voice_chat_outline',
  'voice_over_off' = 'voice_over_off',
  'voice_over_off_outline' = 'voice_over_off_outline',
  'voice_selection' = 'voice_selection',
  'voicemail' = 'voicemail',
  'volcano' = 'volcano',
  'volcano_outline' = 'volcano_outline',
  'volume_down' = 'volume_down',
  'volume_down_outline' = 'volume_down_outline',
  'volume_mute' = 'volume_mute',
  'volume_mute_outline' = 'volume_mute_outline',
  'volume_off' = 'volume_off',
  'volume_off_outline' = 'volume_off_outline',
  'volume_up' = 'volume_up',
  'volume_up_outline' = 'volume_up_outline',
  'volunteer_activism' = 'volunteer_activism',
  'volunteer_activism_outline' = 'volunteer_activism_outline',
  'voting_chip' = 'voting_chip',
  'voting_chip_outline' = 'voting_chip_outline',
  'vpn_key' = 'vpn_key',
  'vpn_key_outline' = 'vpn_key_outline',
  'vpn_key_alert' = 'vpn_key_alert',
  'vpn_key_alert_outline' = 'vpn_key_alert_outline',
  'vpn_key_off' = 'vpn_key_off',
  'vpn_key_off_outline' = 'vpn_key_off_outline',
  'vpn_lock' = 'vpn_lock',
  'vr180_create2d' = 'vr180_create2d',
  'vr180_create2d_outline' = 'vr180_create2d_outline',
  'vr180_create2d_off' = 'vr180_create2d_off',
  'vr180_create2d_off_outline' = 'vr180_create2d_off_outline',
  'vrpano' = 'vrpano',
  'vrpano_outline' = 'vrpano_outline',
  'wall_art' = 'wall_art',
  'wall_art_outline' = 'wall_art_outline',
  'wall_lamp' = 'wall_lamp',
  'wall_lamp_outline' = 'wall_lamp_outline',
  'wallet' = 'wallet',
  'wallpaper' = 'wallpaper',
  'wallpaper_slideshow' = 'wallpaper_slideshow',
  'ward' = 'ward',
  'ward_outline' = 'ward_outline',
  'warehouse' = 'warehouse',
  'warehouse_outline' = 'warehouse_outline',
  'warning' = 'warning',
  'warning_outline' = 'warning_outline',
  'warning_off' = 'warning_off',
  'warning_off_outline' = 'warning_off_outline',
  'wash' = 'wash',
  'wash_outline' = 'wash_outline',
  'watch' = 'watch',
  'watch_outline' = 'watch_outline',
  'watch_button_press' = 'watch_button_press',
  'watch_button_press_outline' = 'watch_button_press_outline',
  'watch_off' = 'watch_off',
  'watch_off_outline' = 'watch_off_outline',
  'watch_screentime' = 'watch_screentime',
  'watch_screentime_outline' = 'watch_screentime_outline',
  'watch_wake' = 'watch_wake',
  'watch_wake_outline' = 'watch_wake_outline',
  'water' = 'water',
  'water_bottle' = 'water_bottle',
  'water_bottle_outline' = 'water_bottle_outline',
  'water_bottle_large' = 'water_bottle_large',
  'water_bottle_large_outline' = 'water_bottle_large_outline',
  'water_damage' = 'water_damage',
  'water_damage_outline' = 'water_damage_outline',
  'water_do' = 'water_do',
  'water_do_outline' = 'water_do_outline',
  'water_drop' = 'water_drop',
  'water_drop_outline' = 'water_drop_outline',
  'water_ec' = 'water_ec',
  'water_ec_outline' = 'water_ec_outline',
  'water_full' = 'water_full',
  'water_full_outline' = 'water_full_outline',
  'water_heater' = 'water_heater',
  'water_heater_outline' = 'water_heater_outline',
  'water_lock' = 'water_lock',
  'water_lock_outline' = 'water_lock_outline',
  'water_loss' = 'water_loss',
  'water_loss_outline' = 'water_loss_outline',
  'water_lux' = 'water_lux',
  'water_lux_outline' = 'water_lux_outline',
  'water_medium' = 'water_medium',
  'water_medium_outline' = 'water_medium_outline',
  'water_orp' = 'water_orp',
  'water_orp_outline' = 'water_orp_outline',
  'water_ph' = 'water_ph',
  'water_ph_outline' = 'water_ph_outline',
  'water_pump' = 'water_pump',
  'water_pump_outline' = 'water_pump_outline',
  'water_voc' = 'water_voc',
  'water_voc_outline' = 'water_voc_outline',
  'waterfall_chart' = 'waterfall_chart',
  'waves' = 'waves',
  'waving_hand' = 'waving_hand',
  'waving_hand_outline' = 'waving_hand_outline',
  'wb_auto' = 'wb_auto',
  'wb_auto_outline' = 'wb_auto_outline',
  'wb_incandescent' = 'wb_incandescent',
  'wb_incandescent_outline' = 'wb_incandescent_outline',
  'wb_shade' = 'wb_shade',
  'wb_sunny' = 'wb_sunny',
  'wb_sunny_outline' = 'wb_sunny_outline',
  'wb_twilight' = 'wb_twilight',
  'wb_twilight_outline' = 'wb_twilight_outline',
  'wc' = 'wc',
  'weather_hail' = 'weather_hail',
  'weather_hail_outline' = 'weather_hail_outline',
  'weather_mix' = 'weather_mix',
  'weather_mix_outline' = 'weather_mix_outline',
  'weather_snowy' = 'weather_snowy',
  'weather_snowy_outline' = 'weather_snowy_outline',
  'web' = 'web',
  'web_asset' = 'web_asset',
  'web_asset_off' = 'web_asset_off',
  'web_stories' = 'web_stories',
  'web_stories_outline' = 'web_stories_outline',
  'web_traffic' = 'web_traffic',
  'webhook' = 'webhook',
  'weekend' = 'weekend',
  'weekend_outline' = 'weekend_outline',
  'weight' = 'weight',
  'weight_outline' = 'weight_outline',
  'west' = 'west',
  'whatshot' = 'whatshot',
  'whatshot_outline' = 'whatshot_outline',
  'wheelchair_pickup' = 'wheelchair_pickup',
  'where_to_vote' = 'where_to_vote',
  'where_to_vote_outline' = 'where_to_vote_outline',
  'widgets' = 'widgets',
  'widgets_outline' = 'widgets_outline',
  'width' = 'width',
  'width_full' = 'width_full',
  'width_full_outline' = 'width_full_outline',
  'width_normal' = 'width_normal',
  'width_normal_outline' = 'width_normal_outline',
  'width_wide' = 'width_wide',
  'width_wide_outline' = 'width_wide_outline',
  'wifi' = 'wifi',
  'wifi_1_bar' = 'wifi_1_bar',
  'wifi_2_bar' = 'wifi_2_bar',
  'wifi_add' = 'wifi_add',
  'wifi_add_outline' = 'wifi_add_outline',
  'wifi_calling' = 'wifi_calling',
  'wifi_calling_outline' = 'wifi_calling_outline',
  'wifi_calling_1' = 'wifi_calling_1',
  'wifi_calling_1_outline' = 'wifi_calling_1_outline',
  'wifi_channel' = 'wifi_channel',
  'wifi_find' = 'wifi_find',
  'wifi_find_outline' = 'wifi_find_outline',
  'wifi_home' = 'wifi_home',
  'wifi_home_outline' = 'wifi_home_outline',
  'wifi_lock' = 'wifi_lock',
  'wifi_lock_outline' = 'wifi_lock_outline',
  'wifi_notification' = 'wifi_notification',
  'wifi_notification_outline' = 'wifi_notification_outline',
  'wifi_off' = 'wifi_off',
  'wifi_password' = 'wifi_password',
  'wifi_protected_setup' = 'wifi_protected_setup',
  'wifi_proxy' = 'wifi_proxy',
  'wifi_proxy_outline' = 'wifi_proxy_outline',
  'wifi_tethering' = 'wifi_tethering',
  'wifi_tethering_error' = 'wifi_tethering_error',
  'wifi_tethering_off' = 'wifi_tethering_off',
  'wind_power' = 'wind_power',
  'wind_power_outline' = 'wind_power_outline',
  'window' = 'window',
  'window_outline' = 'window_outline',
  'window_closed' = 'window_closed',
  'window_closed_outline' = 'window_closed_outline',
  'window_open' = 'window_open',
  'window_open_outline' = 'window_open_outline',
  'window_sensor' = 'window_sensor',
  'window_sensor_outline' = 'window_sensor_outline',
  'wine_bar' = 'wine_bar',
  'wine_bar_outline' = 'wine_bar_outline',
  'woman' = 'woman',
  'woman_2' = 'woman_2',
  'work' = 'work',
  'work_outline' = 'work_outline',
  'work_alert' = 'work_alert',
  'work_alert_outline' = 'work_alert_outline',
  'work_history' = 'work_history',
  'work_history_outline' = 'work_history_outline',
  'work_update' = 'work_update',
  'work_update_outline' = 'work_update_outline',
  'workspace_premium' = 'workspace_premium',
  'workspace_premium_outline' = 'workspace_premium_outline',
  'workspaces' = 'workspaces',
  'workspaces_outline' = 'workspaces_outline',
  'wounds_injuries' = 'wounds_injuries',
  'wrap_text' = 'wrap_text',
  'wrist' = 'wrist',
  'wrist_outline' = 'wrist_outline',
  'wrong_location' = 'wrong_location',
  'wrong_location_outline' = 'wrong_location_outline',
  'wysiwyg' = 'wysiwyg',
  'yard' = 'yard',
  'yard_outline' = 'yard_outline',
  'yooi_logo' = 'yooi_logo',
  'yooi_logo_white' = 'yooi_logo_white',
  'your_trips' = 'your_trips',
  'your_trips_outline' = 'your_trips_outline',
  'youtube_activity' = 'youtube_activity',
  'youtube_searched_for' = 'youtube_searched_for',
  'zone_person_alert' = 'zone_person_alert',
  'zone_person_idle' = 'zone_person_idle',
  'zone_person_urgent' = 'zone_person_urgent',
  'zoom_in' = 'zoom_in',
  'zoom_in_map' = 'zoom_in_map',
  'zoom_out' = 'zoom_out',
  'zoom_out_map' = 'zoom_out_map',
}
