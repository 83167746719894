import type { InitializedModules, Logger, ObjectStore } from 'yooi-store';
import { doInitModules } from 'yooi-store';
import type { RevisionUpdateHandler } from 'yooi-store/src/processing/migrationHandler';
import Modules from '../modules';
import { PlatformInitializationModuleId } from '../modules/platformInitializationModule/module';
import { Platform_InitializationRevision, Platform_MigrationRevision, PlatformInstance } from '../modules/platformModule/ids';
import * as MigrationSteps from './Migrations';
import * as PlatformInitializationSteps from './PlatformInitializationSteps';

export const initModules = (logger: Logger): InitializedModules => doInitModules(Modules, PlatformInitializationModuleId, logger);

export const getMigrationAndInitializationSteps = (): {
  migrations: Record<string, number>,
  initializations: Record<string, number>,
} => ({
  migrations: MigrationSteps,
  initializations: PlatformInitializationSteps,
});

const updateMigrationRevision = (objectStore: ObjectStore, targetRevision: number): void => {
  objectStore.updateObject(PlatformInstance, { [Platform_MigrationRevision]: targetRevision });
};
const updateInitializationRevision = (objectStore: ObjectStore, targetInitializationRevision: number): void => {
  objectStore.updateObject(PlatformInstance, { [Platform_InitializationRevision]: targetInitializationRevision });
};

export const revisionUpdateHandler: RevisionUpdateHandler = {
  updateMigrationRevision,
  updateInitializationRevision,
};
