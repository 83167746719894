import type { FunctionComponent } from 'react';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo, { TypoVariant } from '../../../../components/atoms/Typo';
import base from '../../../../theme/base';
import { spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import useTheme from '../../../../utils/useTheme';

const useStyles = makeStyles((theme) => ({
  arrowLabelContainer: {
    backgroundColor: theme.color.background.neutral.default,
    borderRadius: base.borderRadius.medium,
    paddingLeft: spacingRem.xs,
    paddingRight: spacingRem.xs,
    boxShadow: base.shadowElevation.low,
    opacity: 0.9,
  },
}), 'graphChartArrowLabel');

interface GraphChartArrowLabelProps {
  label: string,
}

const GraphChartArrowLabel: FunctionComponent<GraphChartArrowLabelProps> = ({ label }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.arrowLabelContainer}>
      <Tooltip title={label}>
        <Typo variant={TypoVariant.small} color={theme.color.text.secondary} maxLine={1}>{label}</Typo>
      </Tooltip>
    </div>
  );
};

export default GraphChartArrowLabel;
