import type { FunctionComponent, ReactElement } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { newError } from 'yooi-utils';
import NetworkStoreContextProvider from '../../../../store/NetworkStoreContextProvider';
import { StoreProvider } from '../../../../store/useStore';

type BypassReadAcl = [boolean, () => void];

const BypassReadAclContext = createContext<BypassReadAcl | undefined>(undefined);

interface BypassReadAclProviderProps {
  children: ReactElement | null,
}

export const BypassReadAclProvider: FunctionComponent<BypassReadAclProviderProps> = ({ children }) => {
  const [bypassReadAcl, setBypassReadAcl] = useState(false);
  const toggleBypassAcl = useCallback(() => setBypassReadAcl((current) => !current), []);
  const context = useMemo<BypassReadAcl>(() => [bypassReadAcl, toggleBypassAcl], [bypassReadAcl, toggleBypassAcl]);
  let content;
  if (bypassReadAcl) {
    content = (
      <NetworkStoreContextProvider
        renderLoaded={() => (
          <StoreProvider>
            {children}
          </StoreProvider>
        )}
        renderUnauthenticated={() => null}
        bypassReadAcl={bypassReadAcl}
      />
    );
  } else {
    content = children;
  }

  return (
    <BypassReadAclContext.Provider value={context}>
      {content}
    </BypassReadAclContext.Provider>
  );
};

const useBypassReadAcl = (): BypassReadAcl => {
  const bypassAclContext = useContext(BypassReadAclContext);
  if (!bypassAclContext) {
    throw newError('BypassReadAclContext has not been initialized, add a BypassAclProvider in the React parent component hierarchy');
  }
  return bypassAclContext;
};

export default useBypassReadAcl;
