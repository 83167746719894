import { v4 as uuid } from 'uuid';
import type { InitializedModule, ObjectStore, PropertyFunctionWithTimeseriesLibrary, TimeseriesOperation, TimeseriesStore, TimeseriesUpdate, TimeseriesValue } from 'yooi-store';
import {
  createAccessControlListLibrary,
  createBusinessRulesLibrary,
  createEventDecoder,
  createGarbageCollectorRulesLibrary,
  createObjectDebugLabelLibrary,
  createObjectStore,
  createPropertyFunctionLibrary,
  createPropertyFunctionWithTimeseriesLibrary,
  createTimeseriesRepository,
  ProcessedEventStatus,
} from 'yooi-store';
import { forgetAsyncPromise, fromError, joinObjects, newError } from 'yooi-utils';
import { IconName } from '../components/atoms/Icon';
import type { BrowserNetworkManager } from '../utils/browserNetworkManager';
import buildInfo from '../utils/buildInfo';
import { reportClientError } from '../utils/clientReporterUtils';
import createGlobalObserver from '../utils/globalObserver';
import i18n from '../utils/i18n';
import { clearNotification, notifyError, notifyWarning } from '../utils/notify';
import { hasFeature } from '../utils/options';
import createActivityNotifier from './activityNotifier';
import createActivityStore from './activityStore';
import createAttachmentStore from './attachmentStore';
import { createBrowserSnapshotNetwork } from './browserSnapshotNetwork';
import createBusinessRulesHandler from './businessRulesHandler';
import createConcurrencyHandler from './concurrencyHandler';
import type { OnConnectionProgress } from './connectionProgress';
import createEventPublisher from './eventPublisher';
import createNetworkAttachmentClient from './networkAttachmentClient';
import type { Activity, SendActivity } from './networkEventClient';
import createNetworkEventClient from './networkEventClient';
import createNetworkTimeseriesClient from './networkTimeseriesClient';
import createTimeseriesEventHandler from './timeseriesEventHandler';
import createTimeseriesInitializationHandler from './timeseriesInitializationHandler';
import createTimeseriesSnapshotHandler, { TimeseriesSnapshotStatus } from './timeseriesSnapshotHandler';
import type { StoreContext } from './useStoreContext';

const createNetworkStoreContext = (
  {
    browserNetworkManager,
    modules,
    activityStoreFindViewedObjectId,
    onConnect,
    onUnauthorized,
    onHeartbeat,
    reportMetric,
    bypassReadAcl,
    onConnectionProgress,
  }: {
    browserNetworkManager: BrowserNetworkManager,
    modules: InitializedModule[],
    activityStoreFindViewedObjectId: (store: ObjectStore, objectId: string) => string,
    onConnect: (userId: string) => void,
    onUnauthorized: () => void,
    onHeartbeat: () => void,
    reportMetric: (name: string, data: Record<string, unknown>) => void,
    bypassReadAcl: boolean,
    onConnectionProgress: OnConnectionProgress,
  }
): StoreContext => {
  const clientId = uuid();

  let canDoUpdate = true;

  const objectStore = createObjectStore();
  const { updateActivity, clearStore: clearActivityStore, listViewer, listEditor, rebuildCache } = createActivityStore({
    clientId,
    findViewedObjectId: (objectId: string) => activityStoreFindViewedObjectId(objectStore, objectId),
  });

  const accessControlListLibrary = createAccessControlListLibrary(modules, objectStore.preventUpdate(), hasFeature);
  const businessRulesLibrary = createBusinessRulesLibrary(modules, objectStore.preventUpdate());
  const garbageCollectorRulesLibrary = createGarbageCollectorRulesLibrary(modules, objectStore.preventUpdate());
  const propertyFunctionLibrary = createPropertyFunctionLibrary(modules, objectStore);
  const objectDebugLabelLibrary = createObjectDebugLabelLibrary(modules, objectStore.preventUpdate());

  const propertyFunctionWithTimeseriesLibraryRef: { current: PropertyFunctionWithTimeseriesLibrary } = {
    current: {
      init: () => {},
      onObjectRepositoryUpdate: () => {},
      onTimeseriesStoreUpdate: () => {},
    },
  };
  const timeseriesRepository = createTimeseriesRepository().onUpdate(() => propertyFunctionWithTimeseriesLibraryRef.current.onTimeseriesStoreUpdate());

  const globalObservers = createGlobalObserver<never>();
  const activityGlobalObservers = createGlobalObserver<never>();
  const connectionObservers = createGlobalObserver<never>();

  const { applyRemoteEvent, createOperationValidationTransaction, validateAndApplyLocalEvent, initBusinessRulesHandler, initGarbageCollectorHandler } = (
    createBusinessRulesHandler(objectStore, accessControlListLibrary, businessRulesLibrary, garbageCollectorRulesLibrary)
  );

  const activityEventProcessor = createEventDecoder((id, properties) => updateActivity(id as string, properties as unknown as Activity & { userId: string }));

  const {
    downloadSnapshot: downloadSnapshotFromNetwork,
    sendOutgoingEvent,
    createServerEventConnector,
  } = createNetworkEventClient({ clientId, bypassReadAcl, onUnauthorized });

  const timeseriesClient = createNetworkTimeseriesClient({ clientId, onUnauthorized });
  const { downloadTimeseriesSnapshot, resetRetry } = createTimeseriesSnapshotHandler(timeseriesClient, clientId);
  const { applyTimeseriesEvent, applyTimeseriesOperation } = createTimeseriesEventHandler(timeseriesRepository);

  let timeseriesToastId: string | undefined;
  let connectingToastId: string | undefined;

  let pendingTimeseriesSnapshotCount = 0;

  const {
    handleIncomingEvent,
    handleOutgoingEvent,
    handleTimeseriesInit,
    handleTimeseriesInsertion,
    onConnected,
    onTimeseriesConnected,
    close,
    hasUnsavedChanges,
  } = createConcurrencyHandler({
    clientId,
    bypassReadAcl,
    registerBrowserSnapshotNetwork: createBrowserSnapshotNetwork(browserNetworkManager, bypassReadAcl ? 'bypassReadAcl' : 'main', objectStore, clientId),
    downloadSnapshotFromNetwork,
    fetchTimeseries: async (requests) => {
      pendingTimeseriesSnapshotCount += 1;
      try {
        const result = await downloadTimeseriesSnapshot(requests);
        if (result.status === TimeseriesSnapshotStatus.abortedDueToMaxRetry) {
          canDoUpdate = false;
          notifyError(
            i18n`Server request error. Displayed data may be inaccurate and any changes will be rejected. Please refresh the page.`,
            {
              persist: true,
              closeable: false,
              onToast: (id) => {
                timeseriesToastId = id;
              },
              actions: [{ key: 'refresh', icon: IconName.sync, tooltip: i18n`Refresh`, onClick: () => window.location.reload() }],
            }
          );
          reportClientError(newError('Timeseries loading error', { clientId }));
        }
        return result;
      } finally {
        pendingTimeseriesSnapshotCount -= 1;
      }
    },
    hasPendingTimeseriesFetch: () => pendingTimeseriesSnapshotCount > 0,
    resetTimeseriesRepository: () => {
      if (timeseriesToastId) {
        clearNotification(timeseriesToastId);
        timeseriesToastId = undefined;
      }
      resetRetry();
      timeseriesRepository.reset();
      globalObservers.notify();
    },
    connectionNotificationHandler: {
      onConnection: () => {
        notifyWarning(i18n`Reconnecting to YOOI server ...`, {
          persist: true,
          closeable: false,
          onToast: (id) => {
            connectingToastId = id;
          },
        });
      },
      onConnected: () => {
        if (connectingToastId) {
          clearNotification(connectingToastId);
          connectingToastId = undefined;
        }
      },
    },
    sendOutgoingEvent,
    updateTimeseries: timeseriesRepository.updateTimeseries,
    applyTimeseriesEvent,
    applyRemoteEvent: (objectId, properties, isRollback) => {
      if (properties !== undefined) {
        if (!isRollback) {
          propertyFunctionLibrary.onObjectRepositoryUpdate(objectId, properties);
          propertyFunctionWithTimeseriesLibraryRef.current.onObjectRepositoryUpdate(objectId, properties);
        }
        applyRemoteEvent(objectId, properties);
      }
    },
    validateAndApplyLocalEvent,
    flushEvents: () => {
      globalObservers.notify();
    },
    flushStore: objectStore.flush,
    onDesynchronized: (cause) => {
      canDoUpdate = false;
      notifyError(
        i18n`Server request error. Displayed data may be inaccurate and any changes will be rejected. Please refresh the page.`,
        {
          persist: true,
          closeable: false,
          actions: [{ key: 'refresh', icon: IconName.sync, tooltip: i18n`Refresh`, onClick: () => window.location.reload() }],
        }
      );
      reportClientError(fromError(cause, 'Store is desynchronized', { clientId }));
    },
    onUnauthorized,
    onPermissionUpdated: () => {
      notifyWarning(i18n`Your permissions have been updated. Data displayed may be inaccurate. Please refresh the page to apply changes.`, {
        persist: true,
        closeable: false,
        actions: [{ key: 'refresh', icon: IconName.sync, tooltip: i18n`Refresh`, onClick: () => window.location.reload() }],
      });
    },
    reportMetric,
    onConnectionProgress,
  });

  const { initTimeseries } = createTimeseriesInitializationHandler(handleTimeseriesInit);

  const timeseriesStoreReadOnly: TimeseriesStore = {
    updateTimeValue: () => {
      throw newError('Timeseries update is disabled');
    },
    deleteTimeValue: () => {
      throw newError('Timeseries update is disabled');
    },
    truncateRange: () => {
      throw newError('Timeseries update is disabled');
    },
    getTimeseries: <Value, Id extends (string | string[])>(objectId: Id, propertyId: string, timeRange?: { from: number, to: number }) => {
      const timeseries = timeseriesRepository.getTimeseries(objectId, propertyId, timeRange, true);
      if (!timeseries) {
        initTimeseries(objectId, propertyId, timeRange);
      }
      return timeseries?.values as TimeseriesValue<Value>[] | undefined;
    },
  };

  propertyFunctionWithTimeseriesLibraryRef.current = createPropertyFunctionWithTimeseriesLibrary(modules, joinObjects(objectStore, timeseriesStoreReadOnly));

  const { publishOperation } = createEventPublisher({
    handleOutgoingEvent,
    createOperationValidationTransaction: () => {
      const transaction = createOperationValidationTransaction();
      let transactionTimeseriesRollback: TimeseriesUpdate[] = [];
      return {
        completeTransaction: () => {
          transactionTimeseriesRollback = [];
          const result = transaction.completeTransaction();
          if (result.status === ProcessedEventStatus.rejected) {
            timeseriesRepository.updateTimeseries(Array.from(transactionTimeseriesRollback).reverse());
          }
          return joinObjects(result, { rollbackTimeseriesEvent: Array.from(transactionTimeseriesRollback) });
        },
        validateOperation: (id, properties, timeseries) => {
          const validated = transaction.validateOperation(id, properties, timeseries);
          if (validated) {
            transactionTimeseriesRollback.push(...applyTimeseriesOperation(undefined, { id, properties, timeseries }, true));
          }
          return validated;
        },
      };
    },
  });

  let initialConnexion = true;

  const connectTimeseries = timeseriesClient.createServerTimeseriesEventConnector({
    onTimeseriesUpdate: ({ eventId }) => {
      handleTimeseriesInsertion(eventId);
    },
    onTimeseriesConnected: ({ lastBroadcastedMessageId: lastTimeseriesEventIdInserted, userId }) => onTimeseriesConnected(lastTimeseriesEventIdInserted, userId),
  });

  const handleTimeseriesUpdate = (objectId: string | string[], valueUpdate: Record<string, TimeseriesOperation>) => {
    if (canDoUpdate) {
      const { status, audit } = publishOperation(objectId, undefined, valueUpdate);
      if (status === ProcessedEventStatus.validated) {
        globalObservers.notify();
      } else {
        const error = newError('Event rejected by the client', { audit });
        reportClientError(error);
        notifyError(i18n`rejected`);
        throw error;
      }
    }
  };

  const timeseriesStore: TimeseriesStore = {
    updateTimeValue: (objectId, propertyId, time, newValue) => {
      handleTimeseriesUpdate(objectId, { [propertyId]: { values: { [time]: newValue } } });
    },
    deleteTimeValue: (objectId, propertyId, time) => {
      handleTimeseriesUpdate(objectId, { [propertyId]: { truncate: { from: time, to: time + 1 } } });
    },
    truncateRange: (objectId, propertyId, from, to) => {
      handleTimeseriesUpdate(objectId, { [propertyId]: { truncate: { from, to } } });
    },
    getTimeseries: timeseriesStoreReadOnly.getTimeseries,
  };

  const { uploadAttachment, cloneAttachment, isSafeAttachment, getAttachmentUrl } = createNetworkAttachmentClient();

  let connectionDate: number;
  let connectedUserId: string;

  const sendActivity: SendActivity = (activity) => {
    updateActivity(clientId, joinObjects(activity, { userId: connectedUserId }));
    return sendOutgoingEvent({ activity });
  };
  const activityNotifier = createActivityNotifier(sendActivity);

  const connect = createServerEventConnector({
    onIncomingEvent: (event) => {
      handleIncomingEvent(event);
    },
    onHeartbeat,
    onActivity: (activity) => {
      activityEventProcessor(activity);
      activityGlobalObservers.notify();
    },
    onConnected: forgetAsyncPromise(async ({ lastBroadcastedMessageId, userId, buildSourceHash }) => {
      if (buildSourceHash !== 'development' && buildSourceHash !== buildInfo.sourceHash) {
        notifyError(
          i18n`YOOI application has been updated. Data displayed may be inaccurate and any changes will be rejected. Please refresh the page to access the latest version.`,
          {
            persist: true,
            closeable: false,
            actions: [{ key: 'refresh', icon: IconName.sync, tooltip: i18n`Refresh`, onClick: () => window.location.reload() }],
          }
        );
        canDoUpdate = false;
        return;
      }

      const timeStart = performance.now();
      connectedUserId = userId;

      clearActivityStore();
      activityNotifier.onConnected();
      const timeActivityStoreInitialized = performance.now();

      const connectionResult = await onConnected(lastBroadcastedMessageId, userId);
      if (!connectionResult) {
        return;
      }

      const { metrics: { durationMs: storeInitializationDurationMs, ...snapshotMetrics } } = connectionResult;

      const timeStoreInitialized = performance.now();

      objectStore.unregisterAllPropertyFunctions();
      propertyFunctionLibrary.init();
      propertyFunctionWithTimeseriesLibraryRef.current.init();

      initBusinessRulesHandler(userId);
      const timeBusinessRulesInitialized = performance.now();

      initGarbageCollectorHandler();
      const timeGarbageCollectorInitialized = performance.now();

      connectionDate = Date.now();
      onConnect(userId);

      rebuildCache();
      activityGlobalObservers.notify();

      const timeCompleted = performance.now();

      reportMetric('initializeStore', joinObjects(
        {
          minimalEventId: lastBroadcastedMessageId,
          isInitialConnexion: initialConnexion,
          durationMs: Math.ceil(timeCompleted - timeStart),
          activityStoreInitializationDurationMs: Math.ceil(timeActivityStoreInitialized - timeStart),
          storeInitializationDurationMs,
          businessRulesInitializationDurationMs: Math.ceil(timeBusinessRulesInitialized - timeStoreInitialized),
          garbageCollectorInitializationDurationMs: Math.ceil(timeGarbageCollectorInitialized - timeBusinessRulesInitialized),
        },
        snapshotMetrics
      ));

      initialConnexion = false;
    }),
  });

  const {
    listObjects,
    getObject,
    getObjectOrNull,
    objectEntries,
    updateObject,
    createObject,
    deleteObject,
    withAssociation,
    forEachObject,
    objectsIterator,
    size,
    registerPropertyFunction,
    unregisterPropertyFunction,
    invalidatePropertyCache,
    unregisterAllPropertyFunctions,
    hasPropertyFunction,
  } = objectStore.interceptUpdate(
    () => (id, properties) => {
      if (canDoUpdate) {
        const { status, audit } = publishOperation(id, properties, undefined);
        if (status === ProcessedEventStatus.validated) {
          globalObservers.notify();
        } else {
          const error = newError('Event rejected by the client', { audit });
          reportClientError(error);
          notifyError(i18n`rejected`);
          throw error;
        }
      }
      return null;
    }
  );

  const attachmentStore = createAttachmentStore({ uploadAttachment, cloneAttachment, isSafeAttachment, getAttachmentUrl });

  return {
    clientId,
    connect: () => {
      const { disconnect: timeseriesDisconnect } = connectTimeseries();
      const { disconnect: storeDisconnect } = connect();
      return {
        disconnect: () => {
          timeseriesDisconnect();
          storeDisconnect();
        },
      };
    },
    data: {
      getObject,
      getObjectOrNull,
      objectEntries,
      listObjects,
      updateObject,
      createObject,
      deleteObject,
      withAssociation,
      forEachObject,
      objectsIterator,
      size,
      globalObservers,
      accessControlListLibrary,
      objectDebugLabelLibrary,
      getConnectionDate: () => connectionDate,
      registerPropertyFunction,
      unregisterPropertyFunction,
      invalidatePropertyCache,
      unregisterAllPropertyFunctions,
      hasPropertyFunction,
    },
    attachment: attachmentStore,
    activity: {
      listViewer,
      listEditor,
      updateActivity: forgetAsyncPromise((activity) => sendActivity(activity)),
      globalObservers: activityGlobalObservers,
      connectionObservers,
      onView: (objectId) => activityNotifier.onView(objectId),
      onEnterEdition: (objectId, fieldId) => activityNotifier.onEnterEdition(objectId, fieldId),
      onExitEdition: (objectId, fieldId) => activityNotifier.onExitEdition(objectId, fieldId),
    },
    timeseries: timeseriesStore,
    close,
    hasUnsavedChanges,
  };
};

export default createNetworkStoreContext;
