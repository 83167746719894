import type { FunctionComponent } from 'react';
import type { GlobalFilter } from 'yooi-modules/modules/conceptModule';
import {
  BLOCK_PARAMETER_CURRENT,
  canCreateAssociation,
  FILTER_PARAMETER_CURRENT,
  FILTER_PARAMETER_LOGGED_USER,
  FILTER_PARAMETER_OPTION,
  getFilterFunction,
  isConceptValid,
  isEmbeddedAsIntegrationOnly,
} from 'yooi-modules/modules/conceptModule';
import { Field_IntegrationOnly, RelationSingleField_Field_TargetFilter, RelationSingleField_TargetType } from 'yooi-modules/modules/conceptModule/ids';
import { Resource } from 'yooi-modules/modules/resourceModule/ids';
import type { StoreObject } from 'yooi-store';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import useAcl from '../../../../store/useAcl';
import useActivity from '../../../../store/useActivity';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import useUpdateActivity from '../../../../store/useUpdateActivity';
import i18n from '../../../../utils/i18n';
import { useSessionStorageState } from '../../../../utils/useSessionStorage';
import { buildEmbeddingFieldFilterId } from '../../conceptFilterIdUtils';
import { getInlineCreationBuilder } from '../../conceptUtils';
import { composeFilters } from '../../filter/filterUtils';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { getNavigationFilter } from '../../FrontFilterRenderers';
import { getLastUpdateBy } from '../../historyUtils';
import { getConceptFilterFunction, getConceptFilters } from '../../listFilterFunctions';
import { defaultOptionComparator, getChipOptions, getModelTypeInstances, getSearchChipOptions } from '../../modelTypeUtils';
import type { ChipOption } from '../../modelTypeUtilsType';

interface RelationSingleRendererProps {
  fieldId: string,
  conceptId: string,
  readOnly: boolean,
  globalFilter: GlobalFilter,
  focusOnMount: boolean,
  value?: string | undefined,
  onSubmit?: (value: string | null) => void,
}

const RelationSingleRenderer: FunctionComponent<RelationSingleRendererProps> = ({ fieldId, conceptId, readOnly, globalFilter, focusOnMount, value, onSubmit }) => {
  const store = useStore();
  const { canCreateObject, canWriteObject } = useAcl();
  const { loggedUserId } = useAuth();

  const activity = useActivity();
  const updateActivity = useUpdateActivity();

  const field = store.getObject(fieldId);
  const concept = store.getObject(conceptId);

  const targetType = field.navigate(RelationSingleField_TargetType);
  const fieldFilter = field[RelationSingleField_Field_TargetFilter] ? getFilterFunction(store, field[RelationSingleField_Field_TargetFilter]) : undefined;
  const canCreate = !fieldFilter && canCreateObject(targetType.id);
  const editField = (v: string | null) => (onSubmit ? onSubmit(v) : store.updateObject(conceptId, { [fieldId]: v }));
  const selectedId = value !== undefined ? value : concept[fieldId] as string | undefined;

  const filterId = buildEmbeddingFieldFilterId(targetType.id, fieldId);

  const [filtersConfiguration] = useSessionStorageState<FilterConfiguration | undefined>(filterId, undefined);

  const navigationFilters = getNavigationFilter(
    store,
    fieldId,
    targetType.id,
    conceptId,
    composeFilters([globalFilter?.globalFilters, getConceptFilters(store, targetType.id, filtersConfiguration)]),
    globalFilter?.globalParametersMapping
  );

  const filterFunctions: ((item: StoreObject) => boolean)[] = [];
  const globalFilterFunction = getFilterFunction(store, globalFilter?.globalFilters);
  if (globalFilterFunction) {
    filterFunctions.push((item) => globalFilterFunction(joinObjects(
      globalFilter?.globalParametersMapping,
      {
        [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: item.id },
        [FILTER_PARAMETER_LOGGED_USER]: { type: 'single' as const, id: loggedUserId },
      }
    )));
  }
  const conceptFilterFunction = getConceptFilterFunction(store, targetType.id, filtersConfiguration, loggedUserId);
  if (conceptFilterFunction) {
    filterFunctions.push(conceptFilterFunction);
  }

  const filterFunction: ((option: StoreObject) => boolean) | undefined = filterFunctions.length > 0 ? (object) => filterFunctions.every((filter) => filter(object)) : undefined;

  let selectedOption: ChipOption | undefined;
  if (selectedId && isConceptValid(store, selectedId) && (!filterFunction || filterFunction(store.getObject(selectedId)))) {
    const endIcons: { key: string, icon: IconName, colorVariant: IconColorVariant, tooltip: string }[] = [];
    if (fieldFilter
      && !fieldFilter({
        [BLOCK_PARAMETER_CURRENT]: { type: 'single' as const, id: conceptId },
        [FILTER_PARAMETER_OPTION]: { type: 'single' as const, id: selectedId },
      })) {
      endIcons.push({ key: 'optionFilter', icon: IconName.dangerous, colorVariant: IconColorVariant.error, tooltip: i18n`Instance not authorised by filters.` });
    }

    const chipOptions = getChipOptions(store, selectedId, navigationFilters);
    selectedOption = chipOptions === undefined
      ? undefined
      : joinObjects(chipOptions, { endIcons });
  }

  const selectedOptionId = selectedOption?.id;
  const isSaneSelection = selectedOptionId ? Boolean(getModelTypeInstances(store, targetType.id).find((instance) => instance.id === selectedOptionId)) : true;
  const error = !isSaneSelection ? i18n`Selected element is not allowed` : undefined;

  const isEditing = activity.listEditor(conceptId, fieldId).length > 0;

  const isReadOnly = readOnly
    || field[Field_IntegrationOnly] as boolean
    || isEmbeddedAsIntegrationOnly(store.getObject(conceptId))
    || !canWriteObject(conceptId);

  const computeOptions = () => (
    (filterFunction ? getModelTypeInstances(store, targetType.id).filter(filterFunction)
      : getModelTypeInstances(store, targetType.id))
      .filter((option) => !fieldFilter
        || fieldFilter({
          [BLOCK_PARAMETER_CURRENT]: { type: 'single' as const, id: conceptId },
          [FILTER_PARAMETER_OPTION]: { type: 'single' as const, id: option.id },
        }))
      .map(({ id }) => getChipOptions(store, id))
      .filter(filterNullOrUndefined)
      .sort(defaultOptionComparator)
  );

  return (
    <SearchAndSelect
      clearable={targetType?.id !== Resource}
      placeholder={i18n`Select element`}
      searchOptions={getSearchChipOptions(store, targetType.id)}
      computeOptions={computeOptions}
      selectedOption={selectedOption}
      onSelect={(result) => (result?.id ? editField(result.id) : editField(null))}
      readOnly={isReadOnly}
      getInlineCreation={
        canCreate && canCreateAssociation(targetType.id)
          ? getInlineCreationBuilder(store, targetType.id, (newInstanceId) => store.updateObject(conceptId, { [fieldId]: newInstanceId }))
          : undefined
      }
      statusIcon={error ? { icon: IconName.dangerous, color: IconColorVariant.error, message: error } : undefined}
      onEditionStart={() => updateActivity.onEnterEdition(conceptId, fieldId)}
      onEditionStop={() => updateActivity.onExitEdition(conceptId, fieldId)}
      isEditing={isEditing}
      withMultiplayerOutline={isEditing}
      editOnMount={focusOnMount}
      restingTooltip={() => getLastUpdateBy(store, conceptId, fieldId, undefined)}
    />
  );
};

export default RelationSingleRenderer;
