import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import type { CollaborationMessageStoreObject, CollaborationStoreObject } from 'yooi-modules/modules/collaborationModule';
import {
  Collaboration_Messages,
  CollaborationMessage_Collaboration,
  CollaborationMessage_CreatedAt,
  CollaborationMessage_CreatedBy,
  CollaborationMessage_Text,
} from 'yooi-modules/modules/collaborationModule/ids';
import { Concept_Name } from 'yooi-modules/modules/conceptModule/ids';
import type { RichText } from 'yooi-utils';
import { compareNumber, compareProperty, richTextToText } from 'yooi-utils';
import Avatar, { AvatarSizes, AvatarVariant } from '../../../../../components/atoms/Avatar';
import Typo, { TypoAlign } from '../../../../../components/atoms/Typo';
import useAuth from '../../../../../store/useAuth';
import useStore from '../../../../../store/useStore';
import base from '../../../../../theme/base';
import { FontVariant } from '../../../../../theme/fontDefinition';
import { spacingRem } from '../../../../../theme/spacingDefinition';
import { formatENDisplayDate } from '../../../../../utils/dateUtilsFront';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import useTheme from '../../../../../utils/useTheme';
import useTooltipRef from '../../../../../utils/useTooltipRef';
import { formatRelativeDate } from '../utils/collaborationUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    columnGap: spacingRem.s,
    gridTemplateRows: '3.2rem auto',
    alignItems: 'center',
  },
  containerOwn: {
    gridTemplateColumns: '1fr auto',
  },
  containerOther: {
    gridTemplateColumns: 'auto 1fr',
  },
  infoContainer: {
    display: 'inline-grid',
    columnGap: spacingRem.s,
    alignItems: 'center',
  },
  infoContainerOwn: {
    gridTemplateColumns: 'minmax(5rem, 1fr) auto',
  },
  infoContainerCreatorOwn: {
    gridTemplateColumns: 'minmax(5rem, 1fr) auto auto',
  },
  infoContainerOther: {
    gridTemplateColumns: 'auto minmax(5rem, 1fr)',
  },
  infoContainerCreatorOther: {
    gridTemplateColumns: 'auto auto minmax(5rem, 1fr)',
  },
  message: {
    display: 'flex',
    padding: spacingRem.s,
    borderRadius: base.borderRadius.medium,
  },
  messageOwn: {
    backgroundColor: theme.color.background.primarylight.default,
    gridColumnStart: 1,
  },
  messageOther: {
    boxShadow: base.shadowElevation.low,
    backgroundColor: theme.color.background.neutral.default,
    gridColumnStart: 2,
  },
  unreadIndicator: {
    display: 'flex',
    borderWidth: '0.4rem',
    borderStyle: 'solid',
    borderColor: theme.color.background.info.default,
    borderRadius: base.borderRadius.circle,
    width: 0,
  },
  infoAndUnreadContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
}), 'collaborationMessage');

interface CollaborationMessageProps {
  messageId: string,
  unread?: boolean,
}

const CollaborationMessage: FunctionComponent<CollaborationMessageProps> = ({ messageId, unread = false }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const { loggedUserId } = useAuth();

  const message = store.getObject<CollaborationMessageStoreObject>(messageId);
  const collaboration = message.navigate<CollaborationStoreObject>(CollaborationMessage_Collaboration);
  const collaborationCreatorId = collaboration.navigateBack<CollaborationMessageStoreObject>(Collaboration_Messages)
    .sort(compareProperty(CollaborationMessage_CreatedAt, compareNumber))
    .at(0)
    ?.[CollaborationMessage_CreatedBy];

  const authorName = richTextToText(message.navigate(CollaborationMessage_CreatedBy)?.[Concept_Name] as RichText | undefined);
  const date = new Date(message[CollaborationMessage_CreatedAt] ?? 0);
  const formattedDate = formatENDisplayDate(date);
  const relativeDate = formatRelativeDate(date);

  const authorTooltipRef = useTooltipRef(authorName);
  const creatorTooltipRef = useTooltipRef(i18n`CREATOR`);
  const formattedDateTooltipRef = useTooltipRef(formattedDate);
  const unreadIndicatorTooltipRef = useTooltipRef(i18n`Unread message`);

  const isCreator = collaborationCreatorId === message[CollaborationMessage_CreatedBy];

  if (message[CollaborationMessage_CreatedBy] === loggedUserId) {
    return (
      <div className={classnames(classes.container, classes.containerOwn)}>
        <div
          className={classnames({
            [classes.infoContainer]: true,
            [classes.infoContainerOwn]: !isCreator,
            [classes.infoContainerCreatorOwn]: isCreator,
          })}
        >
          <Typo ref={formattedDateTooltipRef} maxLine={1} color={theme.color.text.secondary} variant={FontVariant.small} align={TypoAlign.right}>
            {relativeDate}
          </Typo>
          {isCreator ? (
            <Typo ref={creatorTooltipRef} maxLine={1} color={theme.color.text.info} variant={FontVariant.code}>{i18n`CREATOR`}</Typo>
          ) : null}
          <Typo ref={authorTooltipRef} maxLine={1}>{authorName}</Typo>
        </div>
        <Avatar name={authorName?.charAt(0).toUpperCase() || '...'} tooltip={authorName} variant={AvatarVariant.currentUser} size={AvatarSizes.small} />
        <div className={classnames(classes.message, classes.messageOwn)}>
          <Typo>{message[CollaborationMessage_Text]}</Typo>
        </div>
      </div>
    );
  } else {
    const infoContainer = (
      <div
        className={classnames({
          [classes.infoContainer]: true,
          [classes.infoContainerOther]: !isCreator,
          [classes.infoContainerCreatorOther]: isCreator,
        })}
      >
        <Typo ref={authorTooltipRef} maxLine={1}>{authorName}</Typo>
        {isCreator ? (
          <Typo ref={creatorTooltipRef} maxLine={1} color={theme.color.text.info} variant={FontVariant.code}>{i18n`CREATOR`}</Typo>
        ) : null}
        <Typo ref={formattedDateTooltipRef} maxLine={1} color={theme.color.text.secondary} variant={FontVariant.small}>
          {relativeDate}
        </Typo>
      </div>
    );

    return (
      <div className={classnames(classes.container, classes.containerOther)}>
        <Avatar name={authorName?.charAt(0).toUpperCase() || '...'} tooltip={authorName} variant={AvatarVariant.messageUser} size={AvatarSizes.small} />
        {
          unread
            ? (
              <div className={classes.infoAndUnreadContainer}>
                {infoContainer}
                <span ref={unreadIndicatorTooltipRef} className={classes.unreadIndicator} />
              </div>
            )
            : infoContainer
        }
        <div className={classnames(classes.message, classes.messageOther)}>
          <Typo>{message[CollaborationMessage_Text]}</Typo>
        </div>
      </div>
    );
  }
};

export default CollaborationMessage;
