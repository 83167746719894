import type { FunctionComponent } from 'react';
import type { NumberValue } from './objectRenderType';
import TagRenderer from './TagRenderer';

interface NumberRendererProps {
  number: NumberValue,
}

const NumberRenderer: FunctionComponent<NumberRendererProps> = ({ number: { value } }) => (
  <TagRenderer value={value} type="number" />
);

export default NumberRenderer;
