import type { FunctionComponent, ReactElement } from 'react';
import type { RenderLeafProps } from 'slate-react';
import type { CustomText } from 'yooi-utils';
import makeStyles from '../../../utils/makeStyles';
import useSizeContext, { SizeVariant } from '../../../utils/useSizeContext';
import Typo, { TypoVariant } from '../../atoms/Typo';

const useStyles = makeStyles({
  bold: { fontWeight: 'bold' },
  italic: { fontStyle: 'italic' },
}, 'richTextLeaf');

interface RichTextLeafProps {
  attributes: RenderLeafProps['attributes'],
  children: ReactElement,
  leaf: CustomText,
}

const RichTextLeaf: FunctionComponent<RichTextLeafProps> = ({ attributes, children, leaf }) => {
  const classes = useStyles();

  const { sizeVariant } = useSizeContext();

  let childrenResult = children;

  if (leaf.bold) {
    childrenResult = (<span className={classes.bold}>{childrenResult}</span>);
  }

  if (leaf.code && sizeVariant === SizeVariant.main) {
    childrenResult = (<Typo variant={TypoVariant.code}>{childrenResult}</Typo>);
  }

  if (leaf.italic) {
    childrenResult = (<span className={classes.italic}>{childrenResult}</span>);
  }

  if (leaf.underline) {
    childrenResult = (<u>{childrenResult}</u>);
  }

  if (leaf.color) {
    childrenResult = (<span style={{ color: leaf.color }}>{childrenResult}</span>);
  }

  return (<span {...attributes}>{childrenResult}</span>);
};

export default RichTextLeaf;
