export enum TagVariant {
  key = 'key',
  value = 'value',
}

interface Tag {
  value: string,
  hint?: string,
  href?: string,
  variant?: TagVariant,
}

export interface ObjectKey extends Tag {
  key: string,
}

export type Values = StringValue | NumberValue | ObjectValue | ArrayValue | NullValue | BooleanValue | DateValue | EventIdValue;

export interface ObjectEntry {
  key: ObjectKey,
  value: Values,
}

export enum ValueType {
  string = 'string',
  number = 'number',
  object = 'object',
  array = 'array',
  null = 'null',
  boolean = 'boolean',
  date = 'date',
  eventId = 'eventId',
}

interface Value<Type extends ValueType> {
  type: Type,
}

export interface StringValue extends Tag, Value<ValueType.string> {
  maxLine?: number,
}

export interface NumberValue extends Value<ValueType.number> {
  value: number,
}

export interface DateValue extends Value<ValueType.date> {
  value: number,
}

export interface EventIdValue extends Value<ValueType.eventId> {
  value: string,
  href?: string,
}

type NullValue = Value<ValueType.null>;

export interface ArrayValue extends Value<ValueType.array> {
  elements: Values[],
  collapseByDefault?: boolean,
}

export interface ObjectValue extends Value<ValueType.object> {
  entries: ObjectEntry[],
  collapsedLabel?: string,
  collapseByDefault?: boolean,
}

export interface BooleanValue extends Value<ValueType.boolean> {
  value: boolean,
}
