import type { FunctionComponent, ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';
import { newError } from 'yooi-utils';
import type { BrowserNetworkManager } from './browserNetworkManager';
import createBrowserNetworkManager from './browserNetworkManager';

const BrowserNetworkManagerContext = createContext<BrowserNetworkManager | undefined>(undefined);

interface BrowserNetworkManagerContextProviderProps {
  children: ReactNode,
}

export const BrowserNetworkManagerContextProvider: FunctionComponent<BrowserNetworkManagerContextProviderProps> = ({ children }) => {
  const [browserNetworkManager] = useState(() => createBrowserNetworkManager());
  return (
    <BrowserNetworkManagerContext.Provider value={browserNetworkManager}>
      {children}
    </BrowserNetworkManagerContext.Provider>
  );
};

const useBrowserNetworkManager = (): BrowserNetworkManager => {
  const browserNetwork = useContext(BrowserNetworkManagerContext);
  if (!browserNetwork) {
    throw newError('BrowserNetworkContext is not present in component tree, you must add it by using BrowserNetworkContextProvider component');
  }
  return browserNetwork;
};

export default useBrowserNetworkManager;
