import { ValidationStatus } from 'yooi-store';
import { compareProperty, compareRank, ranker } from 'yooi-utils';
import { checkObjectRankConflicts } from '../../common/businessRules';
import { asImport, CommonAsType } from '../../common/fields/commonPropertyType';
import type { BusinessRuleRegistration, PropertyGetDefaultValueFunction } from '../../common/types/TypeModuleDslType';
import { PropertyMandatoryType } from '../../common/types/TypeModuleDslType';
import { FieldBlockDisplay } from '../../conceptLayoutModule/ids';
import { ViewsField } from '../../dashboardModule/ids';
import { Instance_Of } from '../../typeModule/ids';
import {
  ConceptDefinition,
  ConceptDefinition_Name,
  ConceptDefinition_ViewFilters,
  Field,
  ViewFilter,
  ViewFilter_ConceptDefinition,
  ViewFilter_FieldBlockDisplay,
  ViewFilter_Label,
  ViewFilter_Rank,
  ViewFilter_ViewsField,
  ViewsField_ViewFilters,
} from '../ids';
import { registerModel } from '../module';
import { adminOnlyAcl } from '../utils';
import type { ViewFilterStoreObject } from './types';

const { association, type } = registerModel;

association({
  label: 'ConceptChipDisplay',
  roles: [{ label: 'ConceptDefinition', targetTypeId: ConceptDefinition }, { label: 'Field', targetTypeId: Field }],
  accessControlList: {
    READ: () => () => ({ rule: 'conceptRoleCapability.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'conceptChipDisplay', 'WRITE'),
    DELETE: () => (_, objectId) => ({ rule: 'conceptRoleCapability.delete.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: objectId }),
  },
})
  .property({ label: 'ConceptChipDisplay_Rank', as: CommonAsType.string });

const viewFilterParentCheck: BusinessRuleRegistration = (objectStore) => (_, { id, properties }) => {
  const object = objectStore.getObjectOrNull(id);
  if (properties === null || properties === undefined) {
    return undefined;
  }
  if (properties[ViewFilter_ConceptDefinition] === null || properties[ViewFilter_ViewsField] === null || properties[ViewFilter_FieldBlockDisplay] === null) {
    return { rule: 'viewFilters.parent.cannotDeleteParent.rejected', status: ValidationStatus.REJECTED };
  }
  if (object && (properties[ViewFilter_ConceptDefinition] !== undefined
    || properties[ViewFilter_ViewsField] !== undefined || properties[ViewFilter_FieldBlockDisplay] !== undefined)) {
    return { rule: 'viewFilters.parent.cannotUpdateParent.rejected', status: ValidationStatus.REJECTED };
  }
  if (properties[ViewFilter_ConceptDefinition] !== undefined && properties[ViewFilter_ViewsField] !== undefined && properties[ViewFilter_FieldBlockDisplay] !== undefined) {
    return { rule: 'viewFilters.parent.cannotHaveMultipleParents.rejected', status: ValidationStatus.REJECTED };
  }
  if (!object && properties[ViewFilter_ConceptDefinition] === undefined
    && properties[ViewFilter_ViewsField] === undefined && properties[ViewFilter_FieldBlockDisplay] === undefined) {
    return { rule: 'viewFilters.parent.mustHaveAParent.rejected', status: ValidationStatus.REJECTED };
  }
  return { rule: 'viewFilters.parent.onlyOneParent.accepted', status: ValidationStatus.ACCEPTED };
};

const preventAbstractClassCreation: BusinessRuleRegistration = () => (_, { properties }) => {
  if (properties === null || properties === undefined) {
    return undefined;
  }
  if (properties[Instance_Of] === ViewFilter) {
    return { rule: 'viewFilters.instanceOf.cannotBeAbstract.rejected', status: ValidationStatus.REJECTED };
  }
  return undefined;
};

const checkViewFilterRankConflicts: BusinessRuleRegistration = (objectStore) => (origin, operation, executionContext) => {
  const { properties } = operation;
  if (!properties) {
    return undefined;
  }
  if (properties[ViewFilter_ViewsField] !== undefined) {
    return checkObjectRankConflicts(ViewFilter_ViewsField, ViewFilter_Rank)(objectStore)(origin, operation, executionContext);
  } else if (properties[ViewFilter_FieldBlockDisplay] !== undefined) {
    return checkObjectRankConflicts(ViewFilter_FieldBlockDisplay, ViewFilter_Rank)(objectStore)(origin, operation, executionContext);
  } else if (properties[ViewFilter_ConceptDefinition] !== undefined) {
    return checkObjectRankConflicts(ViewFilter_ConceptDefinition, ViewFilter_Rank)(objectStore)(origin, operation, executionContext);
  } else {
    return undefined;
  }
};

const getDefaultViewFilterRank: PropertyGetDefaultValueFunction<string> = (objectStoreAfterEvent, id) => {
  const viewFilter = objectStoreAfterEvent.getObject<ViewFilterStoreObject>(id[0]);
  const { insertAfterLastItemRank } = ranker.decorateList(((
    viewFilter.navigateOrNull(ViewFilter_ViewsField)?.navigateBack<ViewFilterStoreObject>(ViewsField_ViewFilters)
    ?? viewFilter.navigateOrNull(ViewFilter_ConceptDefinition)?.navigateBack<ViewFilterStoreObject>(ConceptDefinition_ViewFilters)
  ) ?? [])
    .filter((sibling) => sibling[ViewFilter_Rank] !== undefined)
    .sort(compareProperty(ViewFilter_Rank, compareRank)), (item) => item[ViewFilter_Rank]);

  return insertAfterLastItemRank();
};

type({
  label: 'ViewFilter',
  businessRules: [preventAbstractClassCreation],
  accessControlList: {
    READ: () => () => ({ rule: 'viewFilters.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }, objectId, { newProperties }) => {
      const existingViewId = store.getObjectOrNull<ViewFilterStoreObject>(objectId[0])?.[ViewFilter_ViewsField];
      const createViewId = newProperties && newProperties[ViewFilter_ViewsField] as string;
      if (existingViewId) {
        return { rule: 'viewFilters.write.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: [existingViewId] };
      } else if (createViewId) {
        return { rule: 'viewFilters.write.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: [createViewId] };
      }
      const existingFieldBlockDisplay = store.getObjectOrNull<ViewFilterStoreObject>(objectId[0])?.[ViewFilter_FieldBlockDisplay];
      const createFieldBlockDisplay = newProperties && newProperties[ViewFilter_FieldBlockDisplay] as string;
      if (existingFieldBlockDisplay) {
        return { rule: 'viewFilters.write.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: [existingFieldBlockDisplay] };
      } else if (createFieldBlockDisplay) {
        return { rule: 'viewFilters.write.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: [createFieldBlockDisplay] };
      }
      return adminOnlyAcl(store, userId, 'viewFilters', 'WRITE');
    },
    DELETE: () => (_, objectId) => ({ rule: 'viewFilters.delete.delegate', status: ValidationStatus.DELEGATED, targetAction: 'WRITE', targetId: objectId }),
  },
})
  .relation({
    label: 'ViewFilter_ConceptDefinition',
    targetTypeId: ConceptDefinition,
    reverseLabel: 'ConceptDefinition_ViewFilters',
    businessRules: [viewFilterParentCheck, checkViewFilterRankConflicts],
  })
  .relation({ label: 'ViewFilter_ViewsField', targetTypeId: ViewsField, reverseLabel: 'ViewsField_ViewFilters', businessRules: [viewFilterParentCheck] })
  .relation({ label: 'ViewFilter_FieldBlockDisplay', targetTypeId: FieldBlockDisplay, reverseLabel: 'FieldBlockDisplay_ViewFilters', businessRules: [viewFilterParentCheck] })
  .property({ label: 'ViewFilter_Label', as: CommonAsType.string })
  .property({ label: 'ViewFilter_Rank', as: CommonAsType.string, mandatory: { type: PropertyMandatoryType.mandatoryWithDefaultValue, getDefaultValue: getDefaultViewFilterRank } });

type({
  label: 'AssociationFilter',
  extends: ViewFilter,
  objectDebugLabel: ({ getObject }) => (id) => {
    const conceptDef = getObject(id).navigateOrNull(ViewFilter_ConceptDefinition);
    return `AssociationFilter of '${conceptDef ? conceptDef[ConceptDefinition_Name] : getObject(id)[ViewFilter_Label]}'`;
  },
})
  .property({ label: 'AssociationFilter_Path', as: asImport('PathStep', 'modules/conceptModule', true), mandatory: { type: PropertyMandatoryType.mandatory } })
  .property({ label: 'AssociationFilter_Filters', as: CommonAsType.Filters })
  .property({ label: 'AssociationFilter_LibraryDefaultValueFilters', as: CommonAsType.Filters });

type({
  label: 'ConditionFilter',
  extends: ViewFilter,
  objectDebugLabel: ({ getObject }) => (id) => {
    const origin = getObject(id).navigateOrNull(ViewFilter_ConceptDefinition)?.[ConceptDefinition_Name] ?? getObject(id)[ViewFilter_ViewsField];
    return `ConditionFilter of '${origin}'`;
  },
})
  .property({ label: 'ConditionFilter_Filters', as: CommonAsType.Filters })
  .property({ label: 'ConditionFilter_ActivateByDefault', as: CommonAsType.boolean });
