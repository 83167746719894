import type { FunctionComponent } from 'react';
import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { getPathReturnedConceptDefinitionId } from 'yooi-modules/modules/conceptModule';
import type { DimensionDisplayOption, TableViewStoredDefinition, ViewDimension, ViewSeries } from 'yooi-modules/modules/dashboardModule';
import { DimensionDisplayAxis, ViewType } from 'yooi-modules/modules/dashboardModule';
import { Direction, joinObjects, moveElementInArray, newError } from 'yooi-utils';
import Checkbox from '../../../../components/atoms/Checkbox';
import Chip from '../../../../components/molecules/Chip';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../../../components/molecules/Table';
import TableInnerCellContainer, { TableInnerCellContainerVariants } from '../../../../components/molecules/TableInnerCellContainer';
import DataTable from '../../../../components/templates/DataTable';
import useStore from '../../../../store/useStore';
import { buildPadding, Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import type { LineEditionOption } from '../../fields/FieldEditionOptionType';
import { EditionOptionTypes } from '../../fields/FieldEditionOptionType';
import type { UpdateViewDefinition } from '../../fields/viewsField/ViewsFieldDefinitionOptions';
import type { Option } from '../../modelTypeUtils';
import { getUnknownChip } from '../../modelTypeUtils';
import { isDefaultConfigError } from '../common/defaultConfiguration/defaultConfigurationFeatureDefinition';
import { getDefaultConfigEditionOption } from '../common/defaultConfiguration/viewWithDefaultConfigurationUtils';
import { getViewDefinitionDimensionsDisplayOptions } from '../common/dimensions/viewWithDimensionDisplayOptionsFeatureUtils';
import DimensionExportConfiguration from '../common/series/DimensionExportConfiguration';
import ViewDefinitionSeriesOptions from '../common/series/ViewDefinitionSeriesOptions';
import { addSeries, deleteSeries, getSeriesError, getSeriesLabel, getViewDimensionsAsParameterDefinitions, updateSeries } from '../common/series/viewWithSeriesFeatureUtils';
import ViewOptionBlock from '../common/ViewOptionBlock';
import { getExportEditionOptions } from '../common/viewUtils';
import { getDimensionLabel } from '../data/dataResolution';
import TableViewDefinitionGroupByDimensionOptions from './TableViewDefinitionGroupByDimensionOptions';
import TableViewDefinitionGroupByFieldsOptions from './TableViewDefinitionGroupByFieldsOptions';
import type { TableViewResolvedDefinition } from './tableViewHandler';

interface TableViewDefinitionOptionsProps {
  widgetName: string | undefined,
  viewDimensions: ViewDimension[],
  viewDefinition: TableViewResolvedDefinition,
  updateViewDefinition: UpdateViewDefinition<TableViewStoredDefinition>,
  readOnly: boolean,
  parameterDefinitions: SingleParameterDefinition[],
  isWidget: boolean,
}

interface ItemPerPageOption extends Option {
  value: number | undefined,
}

const getAxisChipOption = (axis: DimensionDisplayAxis): { id: DimensionDisplayAxis, label: string } => ({
  id: axis,
  label: (axis === DimensionDisplayAxis.x ? i18n`Line` : i18n`Column`),
});

const TableViewDefinitionOptions: FunctionComponent<TableViewDefinitionOptionsProps> = ({
  widgetName,
  viewDimensions,
  viewDefinition,
  updateViewDefinition,
  readOnly,
  parameterDefinitions,
  isWidget,
}) => {
  const store = useStore();
  const viewParameterDefinitions: SingleParameterDefinition[] = [...parameterDefinitions, ...getViewDimensionsAsParameterDefinitions(store, viewDimensions)];
  const editionOptions: LineEditionOption[] = [];
  const { defaultConfigOptions } = viewDefinition;
  const isCustomView = isDefaultConfigError(defaultConfigOptions) || !defaultConfigOptions.checked;
  editionOptions.push(getDefaultConfigEditionOption(viewDefinition, readOnly, updateViewDefinition));
  if (isCustomView) {
    editionOptions.push({
      key: 'DisplayOptions',
      type: EditionOptionTypes.custom,
      isVertical: true,
      padded: true,
      title: i18n`Display options`,
      props: {
        render: () => (
          <DataTable<DimensionDisplayOption>
            columnsDefinition={[
              {
                propertyId: 'label',
                name: i18n`Dimension`,
                cellRender: ({ id }, _, index) => {
                  const d = viewDimensions.find((viewDimension) => viewDimension.id === id);
                  return (
                    <TableInnerCellContainer variant={TableInnerCellContainerVariants.centeredFlex} padding={buildPadding({ x: Spacing.s })}>
                      <Chip text={getDimensionLabel(store, d?.label, index, d?.path ?? [])} />
                    </TableInnerCellContainer>
                  );
                },
              },
              {
                propertyId: 'direction',
                name: i18n`Display`,
                cellRender: ({ axis }, _, index) => (
                  <SearchAndSelect<{ id: DimensionDisplayAxis, label: string }>
                    computeOptions={() => Object.values(DimensionDisplayAxis).map((ax) => ({ id: ax, label: (ax === DimensionDisplayAxis.x ? i18n`As rows` : i18n`As columns`) }))}
                    selectedOption={{ id: axis, label: (axis === DimensionDisplayAxis.x ? i18n`As rows` : i18n`As columns`) }}
                    onSelect={(option) => {
                      if (option) {
                        updateViewDefinition((oldViewDefinition) => {
                          const dimensionsDisplay = [...getViewDefinitionDimensionsDisplayOptions(oldViewDefinition, viewDimensions)];
                          dimensionsDisplay[index].axis = option.id;
                          return joinObjects(
                            oldViewDefinition,
                            { dimensionsDisplay }
                          );
                        });
                      }
                    }}
                  />
                ),
              },
              {
                propertyId: 'withLegend',
                name: i18n`Display instances chips`,
                cellRender: ({ withLegend, axis }, _, index) => {
                  if (axis === DimensionDisplayAxis.x) {
                    return (
                      <Checkbox
                        checked={Boolean(withLegend)}
                        onChange={(newWithLegend) => {
                          updateViewDefinition((oldViewDefinition) => {
                            const dimensionsDisplay = [...getViewDefinitionDimensionsDisplayOptions(oldViewDefinition, viewDimensions)];
                            dimensionsDisplay[index].withLegend = newWithLegend;
                            return joinObjects(
                              oldViewDefinition,
                              { dimensionsDisplay }
                            );
                          });
                        }}
                      />
                    );
                  } else {
                    return null;
                  }
                },
              },
              ...viewDefinition.export
                ? [{
                  propertyId: 'exportConfiguration',
                  name: i18n`Export configuration`,
                  cellRender: (displayOption: DimensionDisplayOption, _: boolean, index: number) => {
                    const conceptDefinitionId = getPathReturnedConceptDefinitionId(store, viewDimensions[index].path);
                    return displayOption.withLegend && conceptDefinitionId ? (
                      <DimensionExportConfiguration
                        configuration={displayOption.exportConfiguration}
                        conceptDefinitionId={conceptDefinitionId}
                        onChange={(newConfiguration) => updateViewDefinition((oldViewDefinition) => {
                          const dimensionsDisplay = [...getViewDefinitionDimensionsDisplayOptions(oldViewDefinition, viewDimensions)];
                          dimensionsDisplay[index].exportConfiguration = newConfiguration;
                          return joinObjects(
                            oldViewDefinition,
                            { dimensionsDisplay }
                          );
                        })}
                      />
                    ) : null;
                  },
                }] : [],
            ]}
            list={viewDefinition.getDimensionsDisplay(viewDimensions).map((item) => ({ key: item.id, type: 'item', item, color: undefined }))}
            fullWidth
          />
        ),
      },
    });
  }
  editionOptions.push(
    {
      key: 'ReadOnly',
      title: i18n`Read only`,
      padded: true,
      type: EditionOptionTypes.checkbox,
      props: {
        checked: viewDefinition.readOnly ?? false,
        disabled: readOnly,
        onChange: (value) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { readOnly: value }))),
      },
    }
  );

  if (viewDimensions.length === 1) {
    editionOptions.push(
      {
        key: 'open',
        title: i18n`Add open action as first column`,
        padded: true,
        type: EditionOptionTypes.checkbox,
        props: {
          checked: viewDefinition.openButtonColumn ?? false,
          disabled: readOnly,
          onChange: (value) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { openButtonColumn: value }))),
        },
      }
    );
  }

  if (isCustomView) {
    editionOptions.push(
      {
        key: 'SeriesAxis',
        title: i18n`Series axis`,
        type: EditionOptionTypes.select,
        props: {
          readOnly,
          selectedOption: getAxisChipOption(viewDefinition.seriesAxis),
          computeOptions: () => Object.values(DimensionDisplayAxis).map((ax) => getAxisChipOption(ax)),
          onChange: (axis) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { seriesAxis: axis?.id as DimensionDisplayAxis }))),
        },
      }
    );

    editionOptions.push({
      key: 'Series',
      title: i18n`Series`,
      type: EditionOptionTypes.custom,
      isVertical: true,
      padded: true,
      error: getSeriesError(store, viewDefinition, viewParameterDefinitions, viewDefinition.type),
      props: {
        render: () => (
          <ViewDefinitionSeriesOptions
            viewType={ViewType.Table}
            series={viewDefinition.series ?? []}
            dimensions={viewDimensions}
            onCreateSeries={() => updateViewDefinition((oldViewDefinition) => addSeries(oldViewDefinition))}
            onDeleteSeries={(seriesId) => updateViewDefinition((oldViewDefinition) => deleteSeries(oldViewDefinition, seriesId))}
            onUpdateSeries={(seriesId, properties) => updateViewDefinition((oldViewDefinition) => updateSeries(oldViewDefinition, seriesId, properties))}
            onMoveUpSeries={(index) => updateViewDefinition(
              (oldViewDefinition) => joinObjects(oldViewDefinition, { series: moveElementInArray<ViewSeries>(Direction.up, index, viewDefinition.series ?? []) })
            )}
            onMoveDownSeries={(index) => updateViewDefinition(
              (oldViewDefinition) => joinObjects(oldViewDefinition, { series: moveElementInArray<ViewSeries>(Direction.down, index, viewDefinition.series ?? []) })
            )}
            readOnly={readOnly}
            parameterDefinitions={viewParameterDefinitions}
            withExportOptions={viewDefinition.export}
          />
        ),
      },
    });
  }

  getExportEditionOptions(widgetName, updateViewDefinition, viewDefinition, readOnly).forEach((exportEditionOption) => editionOptions.push(exportEditionOption));

  if (isCustomView) {
    const getDirectionChip = (direction: TableSortDirection) => {
      switch (direction) {
        case TableSortDirection.asc:
          return { id: direction, label: i18n`Ascending sort` };
        case TableSortDirection.desc:
          return { id: direction, label: i18n`Descending sort` };
        default:
          throw newError('Unknown direction', { direction });
      }
    };

    if (viewDefinition.seriesAxis !== DimensionDisplayAxis.x) {
      const getSeriesChip = ({ id, label, path }: ViewSeries, index: number) => ({
        id,
        label: getSeriesLabel(store, label, index, path, viewDimensions ?? [], viewParameterDefinitions.map((parameter) => parameter.id)),
      });

      editionOptions.push({
        key: 'sortBy',
        title: i18n`Default sort`,
        type: EditionOptionTypes.custom,
        props: {
          render: () => {
            const getDimensionChip = (id: string): Option | undefined => {
              const viewDimensionIndex = viewDimensions.findIndex((vd) => vd.id === id);
              if (viewDimensionIndex !== -1) {
                const viewDimension = viewDimensions[viewDimensionIndex];
                return {
                  id,
                  label: getDimensionLabel(store, viewDimension.label, viewDimensionIndex, viewDimension.path),
                };
              } else {
                return undefined;
              }
            };

            let selectedOption;
            if (viewDefinition.defaultSort?.key) {
              const foundIndex = viewDefinition.series.findIndex(({ id }) => id === viewDefinition.defaultSort?.key);
              if (foundIndex !== -1) {
                selectedOption = getSeriesChip(viewDefinition.series[foundIndex], foundIndex);
              } else if (viewDefinition.getDimensionsDisplay(viewDimensions).some((vd) => vd.withLegend && vd.id === viewDefinition.defaultSort?.key)) {
                selectedOption = getDimensionChip(viewDefinition.defaultSort?.key);
              }
            }

            return (
              <SpacingLine>
                <SearchAndSelect
                  selectedOption={viewDefinition.defaultSort?.key ? (selectedOption ?? getUnknownChip(viewDefinition.defaultSort.key)) : undefined}
                  computeOptions={() => {
                    const options: Option[] = [];
                    viewDefinition.getDimensionsDisplay(viewDimensions).forEach(({ id, withLegend }) => {
                      if (withLegend) {
                        const option = getDimensionChip(id);
                        if (option) {
                          options.push(option);
                        }
                      }
                    });
                    if (viewDefinition.series) {
                      viewDefinition.series.forEach((series, index) => options.push(getSeriesChip(series, index)));
                    }
                    return options;
                  }}
                  onSelect={(option) => {
                    if (viewDefinition.defaultSort?.key !== option?.id) {
                      updateViewDefinition((oldViewDefinition) => joinObjects(oldViewDefinition, {
                        defaultSort: option ? {
                          key: option.id,
                          direction: viewDefinition.defaultSort?.direction ?? TableSortDirection.asc,
                        } : undefined,
                      }));
                    }
                  }}
                  placeholder={i18n`Select series`}
                  clearable
                />
                {viewDefinition.defaultSort && selectedOption ? (
                  <SearchAndSelect
                    selectedOption={getDirectionChip(viewDefinition.defaultSort.direction)}
                    computeOptions={() => Object.values(TableSortDirection).map(getDirectionChip)}
                    onSelect={(option) => {
                      if (option && viewDefinition.defaultSort && viewDefinition.defaultSort.direction !== option.id) {
                        updateViewDefinition((oldViewDefinition) => {
                          if (oldViewDefinition.defaultSort) {
                            return joinObjects(oldViewDefinition, { defaultSort: { key: oldViewDefinition.defaultSort.key, direction: option.id } });
                          } else {
                            return oldViewDefinition;
                          }
                        });
                      }
                    }}
                  />
                ) : null}
              </SpacingLine>
            );
          },
        },
      });
    }

    editionOptions.push(
      {
        key: 'groupByDimension',
        title: i18n`Use favorite fields of`,
        type: EditionOptionTypes.custom,
        props: {
          render: () => (
            <TableViewDefinitionGroupByDimensionOptions
              viewDefinition={viewDefinition}
              parameterDefinitions={viewParameterDefinitions}
              onChange={(dimensionIds) => {
                updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { groupBy: joinObjects(viewDefinition.groupBy, { dimensionIds }) })));
              }}
              readOnly={readOnly}
              viewDimensions={viewDimensions}
            />
          ),
        },
      }
    );

    editionOptions.push(
      {
        key: 'groupByFields',
        title: i18n`Group by fields`,
        type: EditionOptionTypes.custom,
        isVertical: true,
        padded: true,
        props: {
          render: () => (
            <TableViewDefinitionGroupByFieldsOptions
              viewDefinition={viewDefinition}
              parameterDefinitions={viewParameterDefinitions}
              onFieldsChange={(fields) => {
                updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { groupBy: joinObjects(viewDefinition.groupBy, { fields }) })));
              }}
              onDefaultChange={(defaultGroupBy) => {
                updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { defaultGroupBy })));
              }}
              readOnly={readOnly}
              viewDimensions={viewDimensions ?? []}
            />
          ),
        },
      }
    );

    const noItemPerPageOption: ItemPerPageOption = {
      id: 'All',
      label: i18n`All`,
      value: undefined,
    };
    let selectedItemPerPage = noItemPerPageOption;
    const itemPerPage = viewDefinition.numberOfItems;
    if (itemPerPage) {
      selectedItemPerPage = {
        id: (itemPerPage).toString(),
        label: (itemPerPage).toString(),
        value: itemPerPage,
      };
    }

    if (!isWidget) {
      editionOptions.push(
        {
          key: 'tableNumberOfItems',
          title: i18n`Items per page`,
          info: i18n`Select the number of items shown in each page of the library table.`,
          type: EditionOptionTypes.select,
          props: {
            readOnly,
            selectedOption: selectedItemPerPage,
            computeOptions: () => [noItemPerPageOption, ...[10, 20, 30, 50, 100].map((i) => ({
              id: (i).toString(),
              label: (i).toString(),
              value: i,
            }))],
            onChange: (selectedItem) => updateViewDefinition(
              (oldViewDefinition) => joinObjects(oldViewDefinition, { numberOfItems: (selectedItem as ItemPerPageOption)?.value })
            ),
          },
        }
      );
    }
  }

  return (
    <>
      {editionOptions.map((option) => (<ViewOptionBlock key={option.key} option={option} />))}
    </>
  );
};

export default TableViewDefinitionOptions;
