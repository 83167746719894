import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { KeyboardEvent, MouseEvent } from 'react';
import { forwardRef } from 'react';
import { joinObjects } from 'yooi-utils';
import base from '../../theme/base';
import fontDefinition from '../../theme/fontDefinition';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import useSizeContext, { buildComponentSizeVariantClasses, HierarchyVariant, SizeVariant } from '../../utils/useSizeContext';
import useTooltipRef from '../../utils/useTooltipRef';
import type { IconColorVariant, IconName } from '../atoms/Icon';
import Icon from '../atoms/Icon';
import Typo, { TypoVariant } from '../atoms/Typo';

const useStyles = makeStyles((theme) => joinObjects(
  {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: spacingRem.xs,
      outline: 0,
      paddingLeft: spacingRem.s,
      paddingRight: spacingRem.s,
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      position: 'relative',
      borderColor: theme.color.border.default,
      backgroundColor: theme.color.background.neutral.default,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.color.background.neutral.subtle,
      },
      '&:focus-visible': {
        backgroundColor: theme.color.background.neutral.subtle,
      },
      '&:active': {
        backgroundColor: theme.color.background.neutral.muted,
        boxShadow: base.button.shadow.pressed,
      },
    },
    active: {
      backgroundColor: theme.color.background.primarylight.default,
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: theme.color.border.default,
      color: theme.color.text.brand,
      '&:hover': {
        backgroundColor: theme.color.background.primarylight.hover,
      },
      '&:focus-visible': {
        backgroundColor: theme.color.background.primarylight.hover,
      },
      '&:active': {
        backgroundColor: theme.color.background.primarylight.pressed,
        boxShadow: base.button.shadow.pressed,
      },
    },
    activeDisable: {
      backgroundColor: theme.color.background.primarylight.default,
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: theme.color.border.default,
      color: theme.color.text.brand,
      cursor: 'default',
      '&:hover': {
        backgroundColor: theme.color.background.primarylight.default,
      },
      '&:focus': {
        backgroundColor: theme.color.background.primarylight.default,
      },
      '&:active': {
        backgroundColor: theme.color.background.primarylight.default,
      },
    },
    disable: {
      backgroundColor: theme.color.background.neutral.default,
      opacity: 0.5,
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: theme.color.border.default,
      cursor: 'default',
      '&:hover': {
        backgroundColor: theme.color.background.neutral.default,
      },
      '&:focus': {
        backgroundColor: theme.color.background.neutral.default,
      },
      '&:active': {
        backgroundColor: theme.color.background.neutral.default,
      },
    },
    firstElement: {
      borderRadius: `${base.borderRadius.medium} 0 0 ${base.borderRadius.medium}`,
      borderRight: 0,
    },
    lastElement: {
      borderRadius: `0 ${base.borderRadius.medium} ${base.borderRadius.medium} 0`,
      borderLeft: 0,
    },
    aloneElement: {
      borderRadius: base.borderRadius.medium,
    },
    element: {
      borderRight: 0,
      borderLeft: 0,
    },
    withShadow: {
      boxShadow: base.button.boxShadow,
    },
    withActiveShadow: {
      boxShadow: `${base.button.boxShadow}, ${base.button.shadow.pressed}`,
    },
    chip: {
      position: 'absolute',
      width: '1.6rem',
      height: '1.6rem',
      borderRadius: base.borderRadius.circle,
      top: '-0.4rem',
      right: '-0.4rem',
      background: theme.color.text.info,
      color: theme.color.text.white,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: fontDefinition.tabTitle.fontFamily,
      fontStyle: fontDefinition.tabTitle.fontStyle,
      fontWeight: fontDefinition.tabTitle.fontWeight,
      fontSize: '1rem',
    },
    mainChip: {
      width: '1.6rem',
      height: '1.6rem',
      top: '-0.4rem',
      right: '-0.4rem',
      fontWeight: fontDefinition.tabTitle.fontWeight,
      fontSize: '1rem',
    },
    smallChip: {
      width: '1.2rem',
      height: '1.2rem',
      top: '-0.1rem',
      right: '-0.1rem',
      fontWeight: fontDefinition.buttonMain.fontWeight,
      fontSize: '0.8rem',
    },
    extraSmallChip: {
      width: '1rem',
      height: '1rem',
      top: '-0.1rem',
      right: '-0.1rem',
      fontWeight: fontDefinition.buttonMain.fontWeight,
      fontSize: '0.8rem',
    },
    outOfBoundChip: {
      top: '-0.3rem',
      right: '-0.3rem',
    },
  },
  buildComponentSizeVariantClasses('toggleButton', ['height']),
  buildComponentSizeVariantClasses('toggleButtonIconOnly', ['height', 'width'])
), 'toggleButton');

export enum ElementPosition {
  first = 'first',
  last = 'last',
  middle = 'middle',
  alone = 'alone',
}

interface ToggleButtonProps {
  icon?: IconName,
  name?: string,
  tooltip?: string,
  active?: boolean,
  disable?: boolean,
  type?: ElementPosition,
  onClick: (event: KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>) => void,
  withShadow?: boolean,
  iconColor?: IconColorVariant,
  rightIcon?: IconName,
  count?: number,
  outOfBoundChip?: boolean,
}

const ToggleButton = forwardRef<HTMLDivElement, ToggleButtonProps>(({
  icon,
  name,
  tooltip,
  active = false,
  disable = false,
  type = ElementPosition.alone,
  onClick,
  withShadow = true,
  iconColor,
  rightIcon,
  count,
  outOfBoundChip,
}, ref) => {
  const classes = useStyles();

  const { sizeVariant: contextSizeVariant, hierarchyVariant } = useSizeContext();
  const sizeContextComputed = contextSizeVariant ?? SizeVariant.main;

  const tooltipRef = useTooltipRef(tooltip);

  const isIconOnly = !name && icon;

  return (
    <div
      ref={composeReactRefs<HTMLDivElement>(tooltipRef, ref)}
      className={
        classnames({
          [classes.root]: true,
          [classes.active]: active,
          [classes.withShadow]: withShadow,
          [classes.withActiveShadow]: active && withShadow,
          [classes.element]: type === ElementPosition.middle,
          [classes.firstElement]: type === ElementPosition.first,
          [classes.lastElement]: type === ElementPosition.last,
          [classes.aloneElement]: type === ElementPosition.alone,
          [classes.disable]: !active && disable,
          [classes.activeDisable]: active && disable,
          [classes[`toggleButton_${sizeContextComputed}_${hierarchyVariant}`]]: !isIconOnly,
          [classes[`toggleButtonIconOnly_${sizeContextComputed}_${hierarchyVariant}`]]: isIconOnly,
        })
      }
      tabIndex={0}
      onClick={(event) => {
        if (!disable) {
          onClick(event);
        }
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && !disable) {
          onClick(event);
        }
      }}
      role="option"
      aria-selected={active}
    >
      {icon ? (<Icon name={icon} colorVariant={iconColor} />) : null}
      {name ? (<Typo variant={sizeContextComputed === SizeVariant.small ? TypoVariant.buttonSmall : TypoVariant.buttonMain} maxLine={1}>{name}</Typo>) : null}
      {rightIcon ? (<Icon name={rightIcon} colorVariant={iconColor} />) : null}
      {(count !== undefined) && (
        <div
          className={classnames({
            [classes.chip]: true,
            [classes.mainChip]: (sizeContextComputed === SizeVariant.title || sizeContextComputed === SizeVariant.main) && hierarchyVariant === HierarchyVariant.content,
            [classes.smallChip]: sizeContextComputed === SizeVariant.subtitle || sizeContextComputed === SizeVariant.tabs || hierarchyVariant !== HierarchyVariant.content,
            [classes.extraSmallChip]: sizeContextComputed === SizeVariant.small || hierarchyVariant !== HierarchyVariant.content,
            [classes.outOfBoundChip]: outOfBoundChip,
          })}
        >
          {count}
        </div>
      )}
    </div>
  );
});

export default ToggleButton;
