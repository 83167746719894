import classnames from 'classnames';
import type { JssStyle } from 'jss';
import type { KeyboardEventHandler, ReactElement } from 'react';
import { useMemo, useRef, useState } from 'react';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import type { FontVariant } from '../../theme/fontDefinition';
import { Spacing, spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { sanitizeSearchValue } from '../../utils/searchUtils';
import { remToPx } from '../../utils/sizeUtils';
import useDerivedState from '../../utils/useDerivedState';
import useFocusOnMount from '../../utils/useFocusOnMount';
import type { NavigationPayload, UseNavigation } from '../../utils/useNavigation';
import useNavigation from '../../utils/useNavigation';
import useScrollOnMountRef from '../../utils/useScrollOnMountRef';
import useSizeContext, { buildInputSizeVariantClasses, getComponentSizeInRem, getInputSize, HierarchyVariant, SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import useUsageContext, { UsageContextProvider, UsageVariant } from '../../utils/useUsageContext';
import Button from '../atoms/Button';
import Icon, { IconColorVariant, IconName } from '../atoms/Icon';
import Typo, { sizeVariantToTypoVariant } from '../atoms/Typo';
import Chip from './Chip';
import EditableWithDropdown, { EditableCloseReasons, editableSelectorsClasses } from './EditableWithDropdown';
import type { InlineCreationInline, InlineCreationTransactional } from './inlineCreationTypes';
import type { ListItem, ListNavigationHandler } from './ItemList';
import ItemList from './ItemList';
import ItemListNavigableChipItem from './ItemListNavigableChipItem';
import SpacedContainer from './SpacedContainer';
import SpacingLine from './SpacingLine';

const useStyles = makeStyles((theme) => (joinObjects(
  buildInputSizeVariantClasses('optionSize', ['minHeight', 'height']),
  (
    Object.fromEntries(
      Object.values(SizeVariant).map((sizeVariant) => [`inputHeight_${sizeVariant}`, { height: `calc(${getInputSize(sizeVariant)} - 0.2rem)` }])
    ) as Record<`inputHeight_${SizeVariant}`, JssStyle>
  ),
  (
    Object.fromEntries(
      Object.entries(theme.font).map(([name, properties]) => [`inputFont_${name}`, joinObjects(properties, { padding: 0 })])
    ) as Record<`inputFont_${FontVariant}`, JssStyle>
  ),
  {
    valueContainer: {
      paddingLeft: spacingRem.s,
      paddingRight: spacingRem.s,
      flexGrow: 1,
      display: 'flex',
      columnGap: spacingRem.s,
      alignSelf: 'stretch',
      alignItems: 'stretch',
    },
    valueOptionContainer: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },
    option: {
      display: 'flex',
      paddingLeft: spacingRem.s,
      paddingRight: spacingRem.s,
      cursor: 'pointer',
      '&:focus': {
        background: theme.color.background.primarylight.default,
      },
      alignItems: 'center',
    },
    input: {
      border: 'none',
      backgroundColor: theme.color.transparent,
      resize: 'none',
      padding: '0',
      flexGrow: 1,
      position: 'absolute',
      width: '1ch',
    },
    inputFullWidth: {
      width: `calc(100% - (${spacingRem.s} * 2) - ${spacingRem.s} - 1.2rem)`, // size of container - padding - dropdown icon margin - icon width
    },
    chevronDownIcon: {
      display: 'flex',
      alignItems: 'center',
    },
    chevronDownIconHidden: {
      visibility: 'hidden',
    },
    chevronDownIconNone: {
      display: 'none',
    },
    greyBar: {
      flexGrow: 1,
      borderBottomWidth: '0.2rem',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.color.border.default,
    },
    transactionalFieldContainer: {
      display: 'grid',
      gridTemplateColumns: '8rem minmax(auto, 25.2rem)',
      paddingLeft: spacingRem.s,
      paddingRight: spacingRem.s,
      paddingBottom: spacingRem.xs,
      paddingTop: spacingRem.xs,
      alignItems: 'center',
    },
    transactionButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      flex: 1,
      paddingLeft: spacingRem.s,
      paddingRight: spacingRem.s,
      paddingBottom: spacingRem.xs,
      paddingTop: spacingRem.xs,
    },
    errorContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }
)), 'searchAndSelect');

const SEARCH_AND_SELECT_EMPTY_VALUE = { label: '\u00a0', id: 'SELECT#CLEAR_VALUE' };

interface Option<T = string | number | boolean> {
  key?: string,
  id: T,
  label: string,
  tooltip?: string,
  icon?: IconName | { name: IconName, colorVariant: IconColorVariant } | { name: IconName, color: string },
  squareColor?: string,
  textColor?: string,
  color?: string,
  borderColor?: string,
  borderStyle?: 'solid' | 'dashed',
  getNavigationPayload?: (navigation: UseNavigation) => NavigationPayload,
  startIcons?: { key: string, icon: IconName, colorVariant?: IconColorVariant, color?: string, tooltip?: string }[],
  endIcons?: { key: string, icon: IconName, colorVariant?: IconColorVariant, color?: string, tooltip?: string }[],
}

interface OptionsHandler<T extends Option = Option> {
  filterOptions: (search: string) => T[],
  perfectMatch: (search: string) => boolean,
}

interface SearchAndSelectProps<T extends Option = Option> {
  selectedOption?: T,
  computeOptions?: () => T[],
  readOnly?: boolean,
  onSelect?: (option: T | null) => void,
  onEscape?: () => void,
  getInlineCreation?: () => (InlineCreationInline | InlineCreationTransactional),
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  searchOptions?: { searchKeys: string[], extractValue: (option: T, searchKey: string) => (string | undefined) },
  clearable?: boolean,
  placeholder?: string,
  editOnMount?: boolean,
  scrollOnMount?: boolean,
  isEditing?: boolean,
  withMultiplayerOutline?: boolean,
  statusIcon?: { message: string, icon: IconName, color: IconColorVariant },
  onClickAway?: () => void,
  minWidth?: number,
  restingTooltip?: string | (() => Promise<string>),
  hideCaret?: boolean,
  disableFitContent?: boolean,
}

const SearchAndSelect = <T extends Option = Option>({
  selectedOption, computeOptions, readOnly = false,
  onSelect, onEscape, onEditionStart, onEditionStop,
  searchOptions, clearable = false, placeholder, editOnMount = false, scrollOnMount = false,
  isEditing = false, withMultiplayerOutline = false, statusIcon, onClickAway, minWidth,
  getInlineCreation, restingTooltip, hideCaret = false, disableFitContent = false,
}: SearchAndSelectProps<T>): ReactElement | null => {
  const theme = useTheme();
  const navigation = useNavigation();
  const classes = useStyles();

  const { sizeVariant } = useSizeContext();
  const typoVariant = sizeVariantToTypoVariant[sizeVariant];

  const usageVariant = useUsageContext();
  const isTableVariant = usageVariant === UsageVariant.inTable;
  const isFormVariant = usageVariant === UsageVariant.inForm;

  const [showDropdown, setShowDropdown] = useState(Boolean(editOnMount));
  const [searchInput, setSearchInput] = useDerivedState(() => '', [showDropdown]);

  const inlineCreationRef = useRef<InlineCreationInline | InlineCreationTransactional | undefined>(undefined);
  if (inlineCreationRef.current === undefined && getInlineCreation && searchInput !== '') {
    inlineCreationRef.current = getInlineCreation();
  } else if (inlineCreationRef.current && !showDropdown) {
    inlineCreationRef.current = undefined;
  }
  const inlineCreation = inlineCreationRef.current;
  const [inlineCreationValues, setInlineCreationValues] = useDerivedState<Record<string, unknown> | undefined>(() => undefined, [showDropdown]);

  const lazyLoadNavigationRef = useRef<ListNavigationHandler>(null);

  const autofocusRef = useRef<HTMLInputElement>(null);
  useFocusOnMount(autofocusRef, showDropdown);

  const scrollRef = useScrollOnMountRef(scrollOnMount);

  const { filterOptions, perfectMatch } = useMemo<OptionsHandler<T>>(() => {
    // only calculate in when dropdown is shown, else large tables will have a big performance penalty
    if (showDropdown && computeOptions && !readOnly) {
      const options = computeOptions();
      let lazyLoadedSearchableOptions: { option: T, searchValues: string[] }[] | undefined;
      const getSearchableOptions = () => {
        // only generate the searchableOptions list if someone is actually searching for something
        if (!lazyLoadedSearchableOptions) {
          if (searchOptions) {
            lazyLoadedSearchableOptions = options.map((option) => ({
              option,
              searchValues: searchOptions.searchKeys.map((key) => searchOptions.extractValue(option, key)).filter(filterNullOrUndefined).map(sanitizeSearchValue),
            }));
          } else {
            lazyLoadedSearchableOptions = options.map((option) => ({ option, searchValues: [sanitizeSearchValue(option.label)] }));
          }
        }
        return lazyLoadedSearchableOptions;
      };

      return {
        filterOptions: (search) => {
          if (search) {
            const sanitizedSearch = sanitizeSearchValue(search);
            return getSearchableOptions().filter(({ searchValues }) => searchValues.some((value) => value.includes(sanitizedSearch))).map(({ option }) => option);
          } else {
            return options;
          }
        },
        perfectMatch: (search) => {
          if (search) {
            const sanitizedSearch = sanitizeSearchValue(search);
            return getSearchableOptions().some(({ searchValues }) => searchValues.some((value) => value === sanitizedSearch));
          } else {
            return false;
          }
        },
      };
    } else {
      return { filterOptions: () => [], perfectMatch: () => false };
    }
  }, [readOnly, computeOptions, searchOptions, showDropdown]);

  const filteredOptions: T[] = [];
  if (clearable && !searchInput) {
    filteredOptions.push(SEARCH_AND_SELECT_EMPTY_VALUE as T);
  }
  filteredOptions.push(...filterOptions(searchInput));

  const openDropdown = () => {
    setShowDropdown(true);
    if (!readOnly) {
      onEditionStart?.();
    }
  };

  const closeDropdown = (closeReason?: EditableCloseReasons) => {
    setShowDropdown(false);
    if (!readOnly) {
      onEditionStop?.();
    }
    if (closeReason === EditableCloseReasons.onBackdropClick) {
      onClickAway?.();
    }
    if (closeReason === EditableCloseReasons.onEscapeKeyDown) {
      onEscape?.();
    }
  };

  const handleSelect = (option: T) => {
    closeDropdown();
    onSelect?.(option.id !== SEARCH_AND_SELECT_EMPTY_VALUE.id ? option : null);
  };

  const renderOption = (option: T | undefined, context: 'value' | 'option') => {
    if (option?.getNavigationPayload || option?.tooltip || option?.icon || option?.squareColor || option?.color) {
      const navigationPayload = option.getNavigationPayload?.(navigation);
      return (
        <Chip
          text={option.label}
          tooltip={option.tooltip}
          icon={option.icon}
          squareColor={option.squareColor}
          color={option.color}
          borderColor={option.borderColor}
          borderStyle={option.borderStyle}
          startIcons={option.startIcons}
          endIcons={option.endIcons}
          actions={
            navigationPayload && context === 'value'
              ? [{
                key: 'open',
                icon: IconName.output,
                tooltip: i18n`Open`,
                action: { to: navigationPayload.to, state: navigationPayload.state, openInNewTab: false },
                showOnHover: true,
              }]
              : undefined
          }
        />
      );
    } else if (option) {
      return (
        <Typo maxLine={1} color={option.textColor}>{option.label}</Typo>
      );
    } else if (placeholder && context === 'value' && !readOnly && !isTableVariant) {
      return (
        <Typo
          maxLine={1}
          color={isFormVariant ? theme.color.text.disabled : theme.color.text.disabled}
        >
          {placeholder}
        </Typo>
      );
    } else {
      return undefined;
    }
  };

  const renderValue = (inDropdown: boolean) => (
    <div ref={scrollRef} className={classes.valueContainer}>
      {/* False positives. We use the onClick to mimic keyboard interactions */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={classes.valueOptionContainer}
        onClick={inDropdown ? () => {
          autofocusRef.current?.focus();
        } : undefined}
      >
        {!(inDropdown && searchInput) ? renderOption(selectedOption, 'value') : null}
        {!readOnly && inDropdown && (
          <input
            type="text"
            ref={autofocusRef}
            value={searchInput}
            className={classnames({
              [classes.input]: true,
              [classes[`inputFont_${typoVariant}`]]: true,
              [classes[`inputHeight_${sizeVariant}`]]: true,
              [classes.inputFullWidth]: !!searchInput,
            })}
            onChange={(event) => {
              lazyLoadNavigationRef.current?.resetNavigation();
              setSearchInput(event.target.value);
              setInlineCreationValues(undefined);
            }}
          />
        )}
      </div>
      {statusIcon && !searchInput && (<span className={classes.errorContainer}><Icon name={statusIcon.icon} tooltip={statusIcon.message} colorVariant={statusIcon.color} /></span>)}
      <div
        className={classnames({
          [classes.chevronDownIcon]: true,
          [classes.chevronDownIconHidden]: !isTableVariant && !hideCaret && (!isFormVariant || readOnly),
          [editableSelectorsClasses.focusedVisible]: (!isTableVariant && !hideCaret) && !readOnly,
          [classes.chevronDownIconNone]: isTableVariant || hideCaret,
          [editableSelectorsClasses.focusedFlex]: (isTableVariant || hideCaret) && !readOnly,
        })}
      >
        <Icon
          colorVariant={isFormVariant ? IconColorVariant.disabled : IconColorVariant.alternative}
          name={IconName.expand_more}
        />
      </div>
    </div>
  );

  const renderDropdown = () => {
    if (inlineCreation?.type === 'transactional' && !!inlineCreationValues) {
      return (
        <div className={classes.list}>
          <div
            className={classnames({
              [classes.option]: true,
              [classes[`optionSize_${sizeVariant}`]]: Boolean(sizeVariant),
            })}
            aria-hidden="true"
            onClick={() => setInlineCreationValues(undefined)}
          >
            <Typo>{i18n`Create`}</Typo>
            <SpacedContainer margin={{ left: Spacing.s }}>
              <SpacingLine>
                <Chip text={inlineCreation.getChipLabel(inlineCreationValues) ?? searchInput} />
              </SpacingLine>
            </SpacedContainer>
          </div>
          <UsageContextProvider usageVariant={UsageVariant.inForm}>
            <SizeContextProvider sizeVariant={SizeVariant.main}>
              {inlineCreation.creationOptions.map(({ key, title, render }) => (
                <div
                  key={key}
                  className={classes.transactionalFieldContainer}
                  aria-hidden="true"
                >
                  <Typo>{title}</Typo>
                  {render(
                    inlineCreationValues[key],
                    (value) => setInlineCreationValues((current) => (joinObjects(current, {
                      [key]: value,
                    })))
                  )}
                </div>
              ))}
            </SizeContextProvider>
          </UsageContextProvider>
          <div className={classes.transactionButtonContainer}>
            <Button
              title={i18n`Create`}
              onClick={() => {
                inlineCreation.onCreate(inlineCreationValues);
                setInlineCreationValues(undefined);
                closeDropdown();
              }}
              disabled={inlineCreation.creationOptions.some(({ key, isValueValid }) => !isValueValid(inlineCreationValues[key]))}
            />
          </div>
        </div>
      );
    } else {
      const optionHeightInRem = getComponentSizeInRem(sizeVariant, HierarchyVariant.content);
      const optionHeightInPx = remToPx(optionHeightInRem);
      const separatorHeightInPx = remToPx(0.2);

      const listItems: ListItem[] = filteredOptions.map((item) => ({
        onSelect: () => handleSelect(item),
        withKeyNavigation: true,
        getSize: () => optionHeightInPx,
        scrollOnMount: item.id === selectedOption?.id,
        key: item.key ?? `${item.id}`,
        renderRow: (style, onNode, onHover, initialIsActive) => (
          <ItemListNavigableChipItem
            key={item.key ?? `${item.id}`}
            ref={onNode}
            style={style}
            onSelect={() => handleSelect(item)}
            onHover={onHover}
            renderContent={() => renderOption(item, 'option')}
            isNavigable
            isSelected={item.id === selectedOption?.id}
            initialIsActive={initialIsActive}
          />
        ),
      }));

      if (inlineCreation && searchInput && !perfectMatch(searchInput)) {
        if (listItems.length > 0) {
          listItems.push({
            key: 'createSeparator',
            getSize: () => separatorHeightInPx,
            renderRow: (style) => (<div key="createSeparator" style={style} className={classes.greyBar} />),
          });
        }

        const onSelectCreation = () => {
          if (inlineCreation.type === 'inline') {
            inlineCreation.onCreate(searchInput);
            closeDropdown();
          } else {
            setInlineCreationValues(inlineCreation.getInitialState(searchInput));
          }
        };

        listItems.push({
          onSelect: onSelectCreation,
          withKeyNavigation: true,
          getSize: () => optionHeightInPx,
          key: 'createItem',
          renderRow: (style, onNode, onHover, initialIsActive) => (
            <ItemListNavigableChipItem
              key="createItem"
              ref={onNode}
              style={style}
              onSelect={onSelectCreation}
              onHover={onHover}
              renderContent={() => (
                <>
                  <Typo>{i18n`Create`}</Typo>
                  <SpacedContainer margin={{ left: Spacing.s }}>
                    <SpacingLine>
                      <Chip text={searchInput} />
                    </SpacingLine>
                  </SpacedContainer>
                </>
              )}
              isNavigable
              initialIsActive={initialIsActive}
            />
          ),
        });
      }

      if (listItems.length > 0) {
        return (
          <ItemList
            estimatedItemHeight={optionHeightInRem}
            navigationRef={lazyLoadNavigationRef}
            items={listItems}
            lazyMinWidth={minWidth ? minWidth - 0.2 : 35} // 0.2 is for border width
          />
        );
      } else {
        return null;
      }
    }
  };

  const handleDropdownKeyDown: KeyboardEventHandler<HTMLElement> = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // To prevent a line-break
      event.stopPropagation(); // prevent queryTable to (probably queryTable to should not handle Enter but provide a focus (tabindex) on the Open button)
      lazyLoadNavigationRef.current?.onEnter();
    } else if (event.key === 'ArrowUp') {
      lazyLoadNavigationRef.current?.onUp();
    } else if (event.key === 'ArrowDown') {
      lazyLoadNavigationRef.current?.onDown();
    }
  };

  let editableMinWidth: string;
  if (showDropdown && inlineCreation?.type === 'transactional') {
    editableMinWidth = '30rem';
  } else if (minWidth !== undefined) {
    editableMinWidth = `${minWidth}rem`;
  } else if (sizeVariant === SizeVariant.main) {
    editableMinWidth = 'unset';
  } else {
    editableMinWidth = '10rem';
  }

  return (
    <EditableWithDropdown
      variant={usageVariant}
      editableSizes={{
        minWidth: editableMinWidth,
        width: isTableVariant || isFormVariant || disableFitContent ? undefined : 'fit-content',
      }}
      dropdownSizes={{
        maxWidth: '64rem',
      }}
      readOnly={readOnly}
      showDropdown={showDropdown}
      openDropdown={openDropdown}
      closeDropdown={closeDropdown}
      closeOnTabKeyDown
      onDropdownKeyDown={handleDropdownKeyDown}
      renderValue={renderValue}
      renderDropdown={readOnly ? undefined : renderDropdown}
      isEditing={usageVariant === UsageVariant.inCard && isEditing}
      withMultiplayerOutline={withMultiplayerOutline}
      dropdownFixedWidth
      restingTooltip={restingTooltip}
    />
  );
};

export default SearchAndSelect;
