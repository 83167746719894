import type { FunctionComponent } from 'react';
import { BLOCK_PARAMETER_CURRENT, FILTER_PARAMETER_OPTION, getFieldUtilsHandler, isConceptValid } from 'yooi-modules/modules/conceptModule';
import { Concept, EmbeddingField, Field_Formula, Field_IntegrationOnly } from 'yooi-modules/modules/conceptModule/ids';
import { doExtends, isInstanceOf } from 'yooi-modules/modules/typeModule';
import type { StoreObject } from 'yooi-store';
import { compareProperty, compareString, comparing, filterNullOrUndefined, joinObjects, newError, pushUndefinedToEnd } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import type { InlineCreationInline, InlineCreationTransactional } from '../../../../components/molecules/inlineCreationTypes';
import SearchAndSelectMultiple from '../../../../components/molecules/SearchAndSelectMultiple';
import useAcl from '../../../../store/useAcl';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';
import { getFieldFilterFunction } from '../../fieldUtils';
import { getNavigationFilter } from '../../FrontFilterRenderers';
import { getLastUpdateBy } from '../../historyUtils';
import { defaultOptionComparator, getChipOptions, getModelTypeInstances, getSearchChipOptions } from '../../modelTypeUtils';

interface ConceptChipListProps {
  fieldId: string,
  conceptId: string,
  value: StoreObject[],
  error?: string,
  getInlineCreation?: () => (InlineCreationInline | InlineCreationTransactional),
  onLink?: (selectedId: string, conceptId: string) => void,
  onUnlink?: (selectedId: string, conceptId: string) => void,
  readOnly?: boolean,
  focusOnMount?: boolean,
}

const ConceptChipList: FunctionComponent<ConceptChipListProps> = ({
  fieldId,
  conceptId,
  value,
  error,
  getInlineCreation,
  onLink,
  onUnlink,
  readOnly = false,
  focusOnMount = false,
}) => {
  const store = useStore();
  const usageVariant = useUsageContext();
  const { canCreateObject, canWriteObject } = useAcl();

  const targetType = getFieldUtilsHandler(store, fieldId).getTargetType?.();
  if (!targetType) {
    throw newError('Target type is missing for field', { fieldId });
  }
  const field = store.getObject(fieldId);
  const fieldFilter = getFieldFilterFunction(field, store);
  const isEmbedding = isInstanceOf(field, EmbeddingField);
  const canCreate = !fieldFilter && (isEmbedding ? canWriteObject(conceptId) : doExtends(targetType, Concept) && canCreateObject(targetType.id));
  const isReadOnly = readOnly || field[Field_IntegrationOnly] as boolean | undefined || targetType.id === Concept || !canWriteObject(conceptId);

  const fieldOptionFilter = (optionId: string) => !fieldFilter
    || fieldFilter({
      [BLOCK_PARAMETER_CURRENT]: { type: 'single' as const, id: conceptId },
      [FILTER_PARAMETER_OPTION]: { type: 'single' as const, id: optionId },
    });
  const computeOptions = onLink
    ? () => (
      getModelTypeInstances(store, targetType.id)
        .filter((e) => (e[fieldId] === conceptId || !e[fieldId] || !isConceptValid(store, e[fieldId] as string)))
        .filter(({ id }) => fieldOptionFilter(id))
        .map(({ id }) => getChipOptions(store, id))
        .filter(filterNullOrUndefined)
        .sort(defaultOptionComparator)
    )
    : () => [];

  const navigationFilters = getNavigationFilter(store, fieldId, targetType.id, conceptId);

  const selectedOptions = value
    .map(({ id }) => {
      const endIcons: { key: string, icon: IconName, colorVariant: IconColorVariant, tooltip: string }[] = [];
      if (fieldFilter
        && !fieldFilter({
          [BLOCK_PARAMETER_CURRENT]: { type: 'single' as const, id: conceptId },
          [FILTER_PARAMETER_OPTION]: { type: 'single' as const, id },
        })) {
        endIcons.push({ key: 'optionFilter', icon: IconName.dangerous, colorVariant: IconColorVariant.error, tooltip: i18n`Instance not authorised by filters.` });
      }
      const chipOptions = getChipOptions(store, id, navigationFilters);
      return chipOptions === undefined
        ? undefined
        : joinObjects(chipOptions, { endIcons });
    })
    .filter(filterNullOrUndefined)
    .sort(compareProperty('label', comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString)));

  return (
    <SearchAndSelectMultiple
      computeOptions={computeOptions}
      selectedOptions={selectedOptions}
      error={error}
      getInlineCreation={canCreate ? getInlineCreation : undefined}
      onSelect={onLink ? (v) => onLink(v.id, conceptId) : () => {}}
      onDelete={onUnlink ? (v) => onUnlink(v.id, conceptId) : () => {}}
      searchOptions={getSearchChipOptions(store, targetType.id)}
      placeholder={isReadOnly || !canWriteObject(conceptId) || usageVariant === UsageVariant.inTable ? undefined : i18n`Add element`}
      readOnly={isReadOnly}
      focusOnMount={focusOnMount}
      restingTooltip={field[Field_Formula] === undefined ? () => getLastUpdateBy(store, conceptId, fieldId, undefined) : undefined}
    />
  );
};

export default ConceptChipList;
