import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { FunctionComponent, ReactElement, RefCallback } from 'react';
import { useCallback, useRef } from 'react';
import { IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import Typo from '../../../../../components/atoms/Typo';
import type { MenuItem } from '../../../../../components/molecules/Menu';
import OverflowMenu from '../../../../../components/molecules/OverflowMenu';
import { FontVariant } from '../../../../../theme/fontDefinition';
import { spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import { useCollaborationPanelUpdater } from '../../../../../utils/useCollaborationContext';
import { useHighlightNotify } from '../../../../../utils/useHighlight';
import { OverlayContextProvider } from '../../../../../utils/useOverlayContainerRef';
import useTooltipRef from '../../../../../utils/useTooltipRef';
import { CollaborationContentRefProvider } from './useCollaborationContentRef';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: spacingRem.s, // TODO When moving the collaboration panel below the nav bar, set to Spacing.m
    rowGap: spacingRem.s,
    zIndex: 1,
  },
  headerPaddedBottom: {
    paddingBottom: spacingRem.s,
  },
  headerLine: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: spacingRem.s,
    paddingLeft: spacingRem.m,
    paddingRight: spacingRem.m,
    height: '3.2rem',
  },
  borderBottom: {
    display: 'flex',
    flexDirection: 'column',
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
    paddingBottom: spacingRem.s,
  },
  headerTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
  headerActionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: theme.color.background.neutral.default,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    paddingLeft: spacingRem.m,
    paddingRight: spacingRem.xs,
    marginRight: spacingRem.xs,
  },
  contentLarge: {
    rowGap: spacingRem.m,
    paddingTop: spacingRem.m,
    paddingBottom: spacingRem.m,
  },
  contentCompact: {
    rowGap: spacingRem.s,
    paddingTop: spacingRem.s,
    paddingBottom: spacingRem.s,
  },
  contentGrow: {
    flexGrow: 1,
  },
  contentFit: {
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
  },
  separator: {
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.default,
    marginLeft: spacingRem.m,
    marginRight: spacingRem.m,
    zIndex: 1,
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.s,
    padding: spacingRem.m,
  },
  topShadow: {
    boxShadow: 'rgba(0, 0, 0, 0.02) 0 2rem 0 -1.6rem',
  },
}), 'collaborationBaseLayout');

interface CollaborationBaseLayoutProps {
  title: string,
  titleButton?: { icon: IconName, tooltip: string, onClick: () => void },
  titleTooltip?: string,
  headerActions?: ReactElement[],
  headerExtraLine?: ReactElement | null,
  overflowActions?: MenuItem[],
  tabsLine?: ReactElement | null,
  content: ReactElement | null,
  footer?: ReactElement | null,
  mainBackgroundColor?: string,
  contentBackgroundColor?: string,
  contentFit?: boolean,
  contentCompact?: boolean,
}

const CollaborationBaseLayout: FunctionComponent<CollaborationBaseLayoutProps> = ({
  title,
  titleButton,
  titleTooltip,
  headerActions,
  headerExtraLine,
  overflowActions = [],
  tabsLine,
  content,
  footer,
  mainBackgroundColor,
  contentBackgroundColor,
  contentFit = false,
  contentCompact = false,
}) => {
  const classes = useStyles();

  const contentRef = useRef<HTMLDivElement | null>(null);

  const { closePanel } = useCollaborationPanelUpdater();
  const highlight = useHighlightNotify();
  const titleTooltipRef = useTooltipRef(titleTooltip ?? title);

  const headerRef = useRef<HTMLDivElement | null>(null);
  const lastScrollNodeRef = useRef<HTMLDivElement | null>(null);
  const onScroll = useCallback(() => {
    if (headerRef.current !== null && lastScrollNodeRef.current !== null) {
      if (lastScrollNodeRef.current.scrollTop === 0) {
        if (headerRef.current.className.includes(classes.topShadow)) {
          headerRef.current.className = headerRef.current.className.split(' ').filter((name) => name !== classes.topShadow).join(' ');
        }
      } else if (!headerRef.current.className.includes(classes.topShadow)) {
        headerRef.current.className += ` ${classes.topShadow}`;
      }
    }
  }, [classes.topShadow]);
  const onScrollDiv = useCallback<RefCallback<HTMLDivElement>>((node) => {
    if (lastScrollNodeRef.current !== null) {
      lastScrollNodeRef.current.removeEventListener('scroll', onScroll);
    }
    lastScrollNodeRef.current = node;
    if (node !== null) {
      node.addEventListener('scroll', onScroll);
    }
  }, [onScroll]);

  return (
    <>
      <div
        ref={headerRef}
        className={classnames({
          [classes.headerContainer]: true,
          [classes.headerPaddedBottom]: !tabsLine && !headerExtraLine,
          [classes.topShadow]: contentRef.current !== null && contentRef.current.scrollTop > 0,
        })}
      >
        <OverlayContextProvider containerRef={headerRef}>
          <div className={classes.headerLine}>
            <div className={classes.headerTitleContainer}>
              {titleButton ? (
                <IconOnlyButton
                  iconName={titleButton.icon}
                  tooltip={titleButton.tooltip}
                  onClick={titleButton.onClick}
                  variant={IconOnlyButtonVariants.tertiary}
                />
              ) : null}
              <Typo ref={titleTooltipRef} variant={FontVariant.blockPrimaryTitle} maxLine={1}>{title}</Typo>
            </div>
            <div className={classes.headerActionContainer}>
              {headerActions}
              {overflowActions.length > 0 ? (<OverflowMenu menuItems={overflowActions} />) : null}
              <IconOnlyButton
                iconName={IconName.close}
                tooltip={i18n`Close`}
                onClick={() => {
                  highlight(undefined, false, false);
                  closePanel();
                }}
                variant={IconOnlyButtonVariants.tertiary}
              />
            </div>
          </div>
          {
            headerExtraLine !== null && headerExtraLine !== undefined
              ? (
                <div className={classes.headerLine}>
                  {headerExtraLine}
                </div>
              )
              : null
          }
          {tabsLine}
          {headerExtraLine && !tabsLine ? (<span className={classes.borderBottom} />) : null}
        </OverlayContextProvider>
      </div>
      <div className={classes.main} style={mainBackgroundColor !== undefined ? { backgroundColor: mainBackgroundColor } : undefined}>
        <div
          ref={composeReactRefs(contentRef, onScrollDiv)}
          className={classnames({
            [classes.content]: true,
            [classes.contentGrow]: !contentFit,
            [classes.contentFit]: contentFit,
            [classes.contentLarge]: !contentCompact,
            [classes.contentCompact]: contentCompact,
          })}
          style={contentBackgroundColor !== undefined ? { backgroundColor: contentBackgroundColor } : undefined}
        >
          <CollaborationContentRefProvider contentRef={contentRef}>
            {content}
          </CollaborationContentRefProvider>
        </div>
        {
          footer !== null && footer !== undefined
            ? (
              <>
                <span className={classes.separator} />
                <div className={classes.footer}>
                  {footer}
                </div>
              </>
            )
            : null
        }
      </div>
    </>
  );
};

export default CollaborationBaseLayout;
