import { sha256 } from 'js-sha256';

type CreateHash = (initialValue?: string) => {
  update: (data: string) => string,
  value: () => string,
  reset: (resetValue?: string) => void,
};

const defaultInitValue = sha256('');

export const createHash: CreateHash = (initialValue) => {
  let value = initialValue ?? defaultInitValue;

  const update = (data: string) => {
    value = sha256(value ? value + data : data);
    return value;
  };

  return {
    update,
    value: () => value,
    reset: (resetValue) => {
      value = resetValue ?? defaultInitValue;
    },
  };
};
