import type { FunctionComponent } from 'react';
import type { EventIdValue } from './objectRenderType';
import TagRenderer from './TagRenderer';

interface EventIdRendererProps {
  eventId: EventIdValue,
}

const EventIdRenderer: FunctionComponent<EventIdRendererProps> = ({ eventId: { value } }) => {
  const [timestamp, sequence] = value.split('-').map((part) => Number.parseInt(part, 10));

  return (
    <TagRenderer value={value} hint={`${new Date(timestamp).toISOString()} - ${sequence.toString(10)}`} href={`/settings/explorer/event/${value}`} />
  );
};

export default EventIdRenderer;
