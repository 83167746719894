import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { newError, textType } from 'yooi-utils';
import { CommonAsType } from '../../common/fields/commonPropertyType';
import type { GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import type { DimensionsMapping, InstanceReferenceValue, ResolutionStack } from '../../conceptModule';
import { isValueResolutionOfType, resolveFieldValue } from '../../conceptModule';
import { TextConstantField_Text } from '../ids';
import { registerField } from '../module';
import type { TextConstantField } from './types';

const getValueResolution = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack) => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);

  if (isValueResolutionOfType(valueResolution, (value): value is string | undefined => value === undefined || typeof value === 'string')) {
    return valueResolution;
  } else {
    return {
      value: undefined,
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['string', 'undefined'], typeof valueResolution.value),
      getDisplayValue: () => undefined,
      isTimeseries: valueResolution.isTimeseries,
    };
  }
};

interface TextConstantFieldStepConfiguration {
  mapping?: Record<string, InstanceReferenceValue | undefined>,
}

type TextConstantFieldHandler = GetDslFieldHandler<
  TextConstantField,
  undefined,
  undefined,
  string | undefined,
  string | undefined,
  undefined,
  undefined,
  undefined,
  TextConstantFieldStepConfiguration,
  undefined
>;
export const textConstantFieldHandler: TextConstantFieldHandler = registerField({
  model: {
    label: 'TextConstantField',
    title: 'Text Constant',
    properties: [
      { label: 'Text', as: CommonAsType.string },
    ],
  },
  handler: (objectStore, fieldId) => {
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => getValueResolution(objectStore, fieldId, dimensionsMapping).value;
    return {
      describe: () => ({ hasData: false }),
      restApi: {
        returnTypeSchema: {},
        formatValue: () => undefined,
      },
      getStoreValue: () => undefined,
      getValueWithoutFormula: () => objectStore.getObject(fieldId)[TextConstantField_Text] as string | undefined,
      getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
      updateValue: () => {
        throw newError('updateValue not supported');
      },
      isEmpty: (dimensionsMapping) => !getValueResolution(objectStore, fieldId, dimensionsMapping).value,
      isSaneValue: () => ({ isValid: true }),
      getValueAsText,
      getExportColumnHeaders: (configuration, fieldLabel) => ({
        columnsNumber: 1,
        getHeaders: () => [{ format: 'string', value: fieldLabel }],
        getColumnConfiguration: () => configuration,
      }),
      getExportValue: (dimensionsMapping) => {
        const { value } = getValueResolution(objectStore, fieldId, dimensionsMapping);
        return { format: 'string', value };
      },
      resolvePathStepConfiguration: () => ({
        hasData: true,
        timeseriesMode: 'none',
        getValueResolutionType: () => textType,
        resolveValue: (dimensionsMapping, _, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack).value,
      }),
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else {
            return undefined;
          }
        },
      }),
      filterConditions: undefined,
    };
  },
});
