import type { CardColorMode, Filters, PathStep } from 'yooi-modules/modules/conceptModule';
import type { SwimlaneViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import { ViewType } from 'yooi-modules/modules/dashboardModule';
import { joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import i18n from '../../../../utils/i18n';
import type { CommonViewResolvedDefinition } from '../common/commonViewResolvedDefinition';
import { getViewConceptConceptError } from '../common/viewUtils';
import type { ViewDefinitionHandler } from '../viewDsl';
import { registerView } from '../viewDsl';
import ViewConceptSwimlaneOptions from './internal/ViewConceptSwimlaneOptions';
import SwimlaneViewBlock from './SwimlaneViewBlock';
import SwimlaneViewDefinitionOptions from './SwimlaneViewDefinitionOptions';
import type { SwimlaneViewResolution } from './swimlaneViewResolution';
import { resolveSwimlaneView } from './swimlaneViewResolution';
import SwimlaneViewWidget from './SwimlaneViewWidget';

export interface SwimlaneViewResolvedDefinition extends CommonViewResolvedDefinition {
  type: ViewType.Swimlane,
  columnBy: { fieldId: string | undefined, filters: Filters | undefined, withEmpty: boolean, size: { type: 'auto' | 'rem' | 'percent', value?: number } },
  groupBy: { id: string, label: string | undefined, path: PathStep[], isDefault: boolean }[],
  color: 'group' | 'column',
  dependencies: PathStep[],
  instanceDisplay: 'card' | 'chip',
  cardColor: { path: PathStep[], as: CardColorMode } | undefined,
  cardIcon: { path: PathStep[] } | undefined,
  cardBoolean: { path: PathStep[] } | undefined,
  cardHeader: { id: string, path: PathStep[], displayOptions: Record<string, unknown> }[],
  cardBody: { id: string, label: string | undefined, path: PathStep[], displayOptions: Record<string, unknown> }[],
}

export type SwimlaneViewDefinitionHandler = ViewDefinitionHandler<SwimlaneViewStoredDefinition, SwimlaneViewResolvedDefinition, SwimlaneViewResolution>;

export const swimlaneViewHandler = registerView<SwimlaneViewStoredDefinition, SwimlaneViewResolvedDefinition, SwimlaneViewResolution>({
  type: ViewType.Swimlane,
  icon: IconName.view_kanban,
  getLabel: () => i18n`Swimlane`,
  optionType: 'popover',
  withFilters: true,
  withExport: false,
  resolveView: (store, viewDefinition, { viewDimensions, parametersMapping, filterConfiguration, aclHandler }) => (
    resolveSwimlaneView(store, viewDimensions, viewDefinition, parametersMapping, aclHandler, filterConfiguration)
  ),
  renderBlock: (_, viewDefinition, { viewDimensions, viewFilters, layoutParametersMapping, readOnly }) => (
    <SwimlaneViewBlock
      viewDimensions={viewDimensions}
      viewDefinition={viewDefinition}
      parametersMapping={layoutParametersMapping}
      viewFilters={viewFilters}
      readOnly={readOnly}
    />
  ),
  renderWidget: (
    _,
    viewDefinition,
    { widgetId, viewDimensions, viewFilters, parametersMapping, width, height, readOnly }
  ) => (
    <SwimlaneViewWidget
      widgetId={widgetId}
      viewDimensions={viewDimensions}
      viewFilters={viewFilters}
      viewDefinition={viewDefinition}
      parametersMapping={parametersMapping}
      width={width}
      height={height}
      readOnly={readOnly}
    />
  ),
  hasOptions: () => true,
  renderOptions: (_, viewDefinition, { viewDimensions, parametersMapping }) => (
    <ViewConceptSwimlaneOptions
      viewDefinition={viewDefinition}
      viewDimensions={viewDimensions}
      parametersMapping={parametersMapping}
    />
  ),
  renderDefinitionOptions: (_, viewDefinition, { viewDimensions, updateViewDefinition, readOnly, parameterDefinitions }) => (
    <SwimlaneViewDefinitionOptions
      viewDimensions={viewDimensions}
      viewDefinition={viewDefinition}
      updateViewDefinition={updateViewDefinition}
      readOnly={readOnly}
      parameterDefinitions={parameterDefinitions}
    />
  ),
  getDefinitionErrors: (store, _, viewDimensions, parameters) => {
    const error = getViewConceptConceptError(store, viewDimensions, parameters, true);
    if (error) {
      return [error];
    }
    return undefined;
  },
  resolveDefinition: (_, viewDefinition) => {
    const swimlaneViewResolvedDefinition: SwimlaneViewResolvedDefinition = {
      id: viewDefinition.id,
      type: viewDefinition.type,
      label: viewDefinition.label !== undefined && viewDefinition.label !== '' ? viewDefinition.label : i18n`Swimlane`,
      readOnly: viewDefinition.readOnly ?? false,
      columnBy: viewDefinition.columnBy ?? { fieldId: undefined, filters: undefined, withEmpty: false, size: { type: 'auto' } },
      groupBy: viewDefinition.groupBy ?? [],
      color: viewDefinition.color ?? 'column',
      dependencies: viewDefinition.dependencies ?? [],
      instanceDisplay: viewDefinition.instanceDisplay ?? 'chip',
      cardColor: viewDefinition.cardColor,
      cardIcon: viewDefinition.cardIcon,
      cardBoolean: viewDefinition.cardBoolean,
      cardHeader: (viewDefinition.cardHeader ?? []).map((cardHeader) => (
        joinObjects(
          cardHeader,
          {
            displayOptions: cardHeader.displayOptions ?? {},
          }
        ))),
      cardBody: (viewDefinition.cardBody ?? []).map((cardBody) => (
        joinObjects(
          cardBody,
          {
            displayOptions: cardBody.displayOptions ?? {},
          }
        ))),
    };

    return swimlaneViewResolvedDefinition;
  },
  getInitialStoredDefinition: (id) => ({
    id,
    type: ViewType.Swimlane,
    columnBy: { fieldId: undefined, filters: undefined, withEmpty: false, size: { type: 'auto' } },
    groupBy: [],
    color: 'column',
    dependencies: [],
    instanceDisplay: 'chip',
    cardColor: undefined,
    cardIcon: undefined,
    cardBoolean: undefined,
    cardHeader: [],
    cardBody: [],
  }),
});
