import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { compareProperty, compareString, comparing, createFormulaEngine, FormulaError, joinObjects } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import Typo, { TypoVariant } from '../../../components/atoms/Typo';
import SimpleInput from '../../../components/inputs/strategy/SimpleInput';
import TextInputString from '../../../components/inputs/TextInputString';
import SpacingLine from '../../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../../components/molecules/Table';
import TableInnerCellContainer from '../../../components/molecules/TableInnerCellContainer';
import BaseLayout from '../../../components/templates/BaseLayout';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import type { ColumnDefinition } from '../../../components/templates/DataTable';
import DataTable from '../../../components/templates/DataTable';
import Header from '../../../components/templates/Header';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import { buildPadding, Spacing } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import { NavigationElementContainer } from '../../../utils/useNavigation';
import HeaderStatic from '../../_global/HeaderStatic';
import StoreTextInputField from '../../_global/input/StoreTextInputField';
import TopBar from '../../_global/topBar/TopBar';

const FormulaDocumentation: FunctionComponent = () => {
  const [playgroundFormula, setPlaygroundFormula] = useState<string | undefined>('MAX(5,9,3,2)');
  const [inputs, setInputs] = useState<{ key: string, value: string }[]>([]);

  const [sort, setSort] = useState<Record<number, TableSortDirection | undefined>>({});

  let playgroundResult = '';
  let playgroundError = null;
  const { executeFormula } = createFormulaEngine();
  try {
    const resolvedInputs: { [key: string]: { value: unknown } } = {};
    inputs.forEach(({ key, value }) => {
      resolvedInputs[key.toLowerCase()] = { value: value ? JSON.parse(value) : undefined };
    });
    const value = playgroundFormula ? executeFormula(playgroundFormula, resolvedInputs) : '';
    if (typeof value === 'number') {
      playgroundResult = `${+value.toPrecision(15)}`;
    } else {
      playgroundResult = `${value}`;
    }
  } catch (e) {
    if (e instanceof FormulaError) {
      playgroundError = e.message || e.error;
    } else if (e instanceof Error) {
      playgroundError = e.message;
    } else {
      playgroundError = JSON.stringify(e);
    }
  }

  const statsFunctions = [
    {
      name: 'COUNT',
      description: i18n`Returns the number of defined and non empty arguments.`,
      syntax: 'COUNT(arg1, [arg2], …)',
      example: 'COUNT(58, "France", NULL, "", "USA") = 3\n'
        + 'COUNT({"Lyon", "Paris"}) = 2',
    },
    {
      name: 'MIN',
      description: i18n`Returns the smallest number in a set of values.`,
      syntax: 'MIN(number1, [number2], …)',
      example: 'MIN(4,6,12) = 4',
    },
    {
      name: 'MAX',
      description: i18n`Returns the largest number in a set of values.`,
      syntax: 'MAX(number1, [number2], …)',
      example: 'MAX(4,6,12) = 12',
    },
    {
      name: 'COMPLETENESS',
      description: i18n`Returns the ratio of completed arguments out of the total number of arguments.`,
      syntax: 'COMPLETENESS(arg1, [arg2], …)',
      example: 'COMPLETENESS(58, NULL, 35, 86) = 0.75',
    },
    {
      name: 'AVERAGEW',
      description: i18n`Returns the weighted average of its arguments.`,
      syntax: 'AVERAGEW({number 1, weight1}, [{number2, weight2}], ...)',
      example: 'AVERAGEW({10, 2}, {8, 3}, {9, 3}) = 8.875',
    },
    {
      name: 'AVERAGE',
      description: i18n`Returns the average of its arguments.`,
      syntax: 'AVERAGE(number1, [number2], ...)',
      example: 'AVERAGE(10, 8, 9) = 9',
    },
  ];

  const mathFunctions = [
    {
      name: 'ABS',
      description: i18n`Returns the absolute value of a number.`,
      syntax: 'ABS(number)',
      example: 'ABS(-2) = 2',
    },
    {
      name: 'MOD',
      description: i18n`Returns the remainder from division.`,
      syntax: 'MOD(number, divisor)',
      example: 'MOD(3, 2) = 1',
    },
    {
      name: 'POWER',
      description: i18n`Returns the result of a number raised to a power.`,
      syntax: 'POWER(number, power)',
      example: 'POWER(5,2) = 25',
    },
    {
      name: 'PRODUCT',
      description: i18n`Multiplies all the numbers given as arguments and returns the product.`,
      syntax: 'PRODUCT(number1, [number2], ...)',
      example: 'PRODUCT(25, 15, 30) = 2250',
    },
    {
      name: 'QUOTIENT',
      description: i18n`Returns the integer portion of a division.`,
      syntax: 'QUOTIENT(numerator, denominator)',
      example: 'QUOTIENT(5, 2) = 2',
    },
    {
      name: 'SUM',
      description: i18n`Adds its arguments.`,
      syntax: 'SUM(number1, [number2], …)',
      example: 'SUM(5, 10) = 15',
    },
    {
      name: 'INT',
      description: i18n`Rounds a number down to the nearest integer.`,
      syntax: 'INT(number)',
      example: 'INT(8.9) = 8',
    },
    {
      name: 'TRUNC',
      description: i18n`Truncates a number to an integer by removing the fractional part of the number.`,
      syntax: 'TRUNC(number, [num_digits])',
      example: 'TRUNC(8.9) = 8',
    },
    {
      name: 'ROUND',
      description: i18n`Rounds a number to a specified number of digits.`,
      syntax: 'ROUND(number, num_digits)',
      example: 'ROUND(2.15, 1) = 2.2',
    },
    {
      name: 'ROUNDDOWN',
      description: i18n`Rounds a number down, toward zero.`,
      syntax: 'ROUNDDOWN(number, num_digits)',
      example: 'ROUNDDOWN(3.14159, 3) = 3.141',
    },
    {
      name: 'ROUNDUP',
      description: i18n`Rounds a number up, away from zero.`,
      syntax: 'ROUNDUP(number, num_digits)',
      example: 'ROUNDUP(3.14159, 3) = 3.142',
    },
    {
      name: 'MROUND',
      description: i18n`Returns a number rounded to the desired multiple.`,
      syntax: 'MROUND(number, multiple)',
      example: 'MROUND(10, 3) = 9',
    },
    {
      name: 'FLOOR',
      description: i18n`Rounds number down, toward zero, to the nearest multiple of significance.`,
      syntax: 'FLOOR(number, significance)',
      example: 'FLOOR(3.7,2) = 2',
    },
    {
      name: 'CEILING',
      description: i18n`Returns number rounded up, away from zero, to the nearest multiple of significance.`,
      syntax: 'CEILING(number, significance)',
      example: 'CEILING(2.5, 1) = 3',
    },
    {
      name: 'EXP',
      description: i18n`Returns e raised to the power of number.`,
      syntax: 'EXP(number)',
      example: 'EXP(1) = 2.71828182845905',
    },
    {
      name: 'LN',
      description: i18n`Returns the natural logarithm of a number.`,
      syntax: 'LN(number)',
      example: 'LN(86) = 4.45434729625351',
    },
    {
      name: 'LOG',
      description: i18n`Returns the logarithm of a number to the base you specify.`,
      syntax: 'LOG(number, [base])',
      example: 'LOG(8, 2) = 3',
    },
    {
      name: 'LOG10',
      description: i18n`Returns the base-10 logarithm of a number.`,
      syntax: 'LOG10(number)',
      example: 'LOG10(100) = 2',
    },
    {
      name: 'SQRT',
      description: i18n`Returns a positive square root.`,
      syntax: 'SQRT(number)',
      example: 'SQRT(16) = 4',
    },
    {
      name: 'ZN',
      description: i18n`Returns the argument if not null, 0 otherwise.`,
      syntax: 'ZN(value)',
      example: 'ZN(42) = 42\nZN(NULL) = 0',
    },
  ];

  const logicalFunctions = [
    {
      name: 'NOT',
      description: i18n`Reverses the logic of its argument.`,
      syntax: 'NOT(boolean)',
      example: 'NOT(50>100) = TRUE',
    },
    {
      name: 'AND',
      description: i18n`Returns TRUE if all of its arguments are TRUE.`,
      syntax: 'AND(boolean1, [boolean2], ...)',
      example: 'AND(50>1, 80<100) = TRUE',
    },
    {
      name: 'OR',
      description: i18n`Returns TRUE if any argument is TRUE.`,
      syntax: 'OR(boolean1, [boolean2], ...)',
      example: 'OR(50>1, 120<100) = TRUE',
    },
    {
      name: 'XOR',
      description: i18n`Returns a logical exclusive OR of all arguments.`,
      syntax: 'XOR(boolean1, [boolean2],…)',
      example: 'XOR(3>0,2<9) = FALSE',
    },
    {
      name: 'IF',
      description: i18n`Specifies a logical test to perform.`,
      syntax: 'IF(boolean, value_if_true, [value_if_false])',
      example: 'IF(60>50,"Over Budget","OK") = Over Budget',
    },
    {
      name: 'IFS',
      description: i18n`Checks whether one or more conditions are met and returns a value that corresponds to the first TRUE condition. If a value is multiple, it will be used as condition, value tuples. To use this function with x-n association as value, use a getObjects function around.`,
      syntax: 'IFS(boolean1, value_if_true1, [boolean2, value_if_true2], [boolean3, value_if_true3],…)',
      example: 'IFS(25>50,"Over Budget", 30>15,"Done" ) = Done; IFS(25<30, GETOBJECTS(instances)) = [instanceA, instanceB, ...]',
    },
    {
      name: 'ISBLANK',
      description: i18n`Returns TRUE if the argument is undefined.`,
      syntax: 'ISBLANK(value)',
      example: 'ISBLANK(42) = FALSE',
    },
    {
      name: 'ISNUMBER',
      description: i18n`Returns TRUE if the argument is a number.`,
      syntax: 'ISNUMBER(value)',
      example: 'ISNUMBER(42) = TRUE',
    },
    {
      name: 'SWITCH',
      description: i18n`Evaluates an expression against a list of values and returns the result corresponding to the first matching value. If there is no match, an optional default value may be returned.`,
      syntax: 'SWITCH(expression, value1, result1, [value2, result2], …, [default_result])',
      example: 'SWITCH(3,1,"Sunday",7,"Saturday","weekday") = weekday',
    },
    {
      name: 'COALESCE',
      description: i18n`Evaluates the arguments in order and returns the first value that is not NULL.`,
      syntax: 'COALESCE(value1, value2, …)',
      example: 'COALESCE(NULL,42,10) = 42',
    },
  ];

  const dateFunctions = [
    {
      name: 'MIN',
      description: i18n`Returns the min date of inputs.`,
      syntax: 'MIN(dateObject1, [dateObject2], ...)',
      example: 'MIN(1625090400000, 622505600000) = 1622505600000',
    },
    {
      name: 'MAX',
      description: i18n`Returns the max date of inputs.`,
      syntax: 'MAX(dateObject1, [dateObject2], ...)',
      example: 'MAX(1625090400000, 1622505600000) = 1625090400000',
    },
    {
      name: 'DATEVALUE',
      description: i18n`Converts a date to a serial number.`,
      syntax: 'DATEVALUE(date)',
      example: 'DATEVALUE(Input1) = 40777\nDATEVALUE("2011-08-22") = 40777',
    },
    {
      name: 'DATEDIF',
      description: i18n`Calculates the number of days, months, or years between two dates.`,
      syntax: 'DATEDIF(start_date, end_date, unit)',
      example: 'DATEDIF(DATEVALUE(Input1),DATEVALUE(Input2),"Y") = 1\nDATEDIF("2001-06-01","2002-08-15","Y") = 1',
    },
    {
      name: 'DATECONVERT',
      description: i18n`Returns the start of the first period starting after the date, periodicity is one of ("DAY", "WEEK", "MONTH", "QUARTER", "YEAR").`,
      syntax: 'DATECONVERT(date, periodicity)',
      example: 'DATECONVERT(1625890400000, "MONTH") = 1622505600000',
    },
    {
      name: 'EARLIESTAFTER',
      description: i18n`Returns the start of the next day after inputs.`,
      syntax: 'EARLIESTAFTER(dateObject1, [dateObject2], ...)',
      example: 'EARLIESTAFTER(1625090400000, 1622505600000) = 1625176800000',
    },
    {
      name: 'TODAY',
      description: i18n`Returns the serial number of the current date.`,
      syntax: 'TODAY()',
      example: 'TODAY() = current date',
    },
    {
      name: 'YEAR',
      description: i18n`Returns the year corresponding to a date.`,
      syntax: 'YEAR(date)',
      example: 'YEAR(1699369382000) = 2023',
    },
    {
      name: 'MONTH',
      description: i18n`Returns the month of a date represented by a number. The month is given as an integer, ranging from 1 (January) to 12 (December).`,
      syntax: 'MONTH(date)',
      example: 'MONTH(1699369382000) = 11',
    },
    {
      name: 'WEEK',
      description: i18n`Returns the week number of a specific date. The week is given as an integer ranging from 1 to 52.`,
      syntax: 'WEEK(date)',
      example: 'WEEK(1699369382000) = 45',
    },
    {
      name: 'DAY',
      description: i18n`Returns the day of a date, represented by a number. The day is given as an integer ranging from 1 to 31.`,
      syntax: 'DAY(date)',
      example: 'DAY(1699369382000) = 7',
    },
    {
      name: 'WEEKDAY',
      description: i18n`Returns the day of the week corresponding to a date. The day is given as an integer, ranging from 1 (Sunday) to 7 (Saturday), by default. To get a week starting on monday, pass 2 a second argument.`,
      syntax: 'WEEKDAY(date, returnType)',
      example: 'WEEKDAY(1699369382000, 2) = 2',
    },
  ];

  const timeseriesFunctions = [
    {
      name: 'LASTVALUE',
      description: i18n`Returns the last value of the timeseries before the specified date. Date is optional and if not set, LASTVALUE returns the last value of the timeseries. DefaultValue is optional, if set, returns the given value when no value is found.`,
      syntax: 'LASTVALUE(timeseries, date, defaultValue)',
      example: 'LASTVALUE(Input1=[{ "time": 1622505600000, "value": 10 }, { "time": 1625090400000, "value": 12 }, { "time": 2955090400000, "value": 20 }], NOW()) = 12\nLASTVALUE(Input1=[{ "time": 1622505600000, "value": 10 }], 1622505600000, 10) = 42\nLASTVALUE(Input1=[{ "time": 1622505600000, "value": 10 }], 1000000000000, 42) = 42',
    },
    {
      name: 'VALUEAT',
      description: i18n`Returns the value of the timeseries at the specified date. DefaultValue is optional, if set, returns the given value when no value is found.`,
      syntax: 'VALUEAT(timeseries, date, defaultValue)',
      example: 'VALUEAT(Input1=[{ "time": 1622505600000, "value": 10 }, { "time": 1625090400000, "value": 12 }, { "time": 2955090400000, "value": 20 }], 1622505600001) = undefined\nVALUEAT(Input1=[{ "time": 1622505600000, "value": 10 }, { "time": 1625090400000, "value": 12 }, { "time": 2955090400000, "value": 20 }], 1622505600001, 42) = 42',
    },
    {
      name: 'VALUESIN',
      description: i18n`Returns the list of value of the timeseries between startDate (included) and endDate (included). StartDate and EndDate are optional.`,
      syntax: 'VALUESIN(timeseries, startDate, endDate)',
      example: 'VALUESIN(Input1=[{ "time": 1622505600000, "value": 10 }, { "time": 1625090400000, "value": 12 }, { "time": 2955090400000, "value": 20 }], 1622505600000, 1625090400000) = [10 ,12]\nVALUESIN(Input1=[{ "time": 1622505600000, "value": 10 }, { "time": 1625090400000, "value": 12 }, { "time": 2955090400000, "value": 20 }], 1625090400000) = [12, 20]\nVALUESIN(Input1=[{ "time": 1622505600000, "value": 10 }, { "time": 1625090400000, "value": 12 }, { "time": 2955090400000, "value": 20 }], NULL, 1625090400000) = [10, 12]',
    },
  ];

  const textFunctions = [
    {
      name: 'CONCATENATE',
      description: i18n`Joins two or more text strings into one string. You can also use CONCAT as alias.`,
      syntax: 'CONCATENATE(text1, [text2], ...)',
      example: 'CONCATENATE("hello ", "world") = hello world',
    },
    {
      name: 'CLEAN',
      description: i18n`Removes all nonprintable characters from text.`,
      syntax: 'CLEAN(string)',
      example: 'CLEAN(text) = cleanText',
    },
    {
      name: 'INDEXOF',
      description: i18n`Given two argument: a string and a substring to search for, searches the substring in the string, and returns the index of the first occurrence of the specified substring. Given a third argument: a number, the method returns the first occurrence of the specified substring at an index greater than or equal to the specified number. Returns -1 if not found.`,
      syntax: 'INDEXOF(string, substring, position)',
      example: 'INDEXOF("hello world", "world") = 6\nINDEXOF("hello world", "missing") = -1\nINDEXOF("hello world", "world", 7) = -1\nINDEXOF("hello world", "o") = 4\nINDEXOF("hello world", "o", 5) = 7',
    },
    {
      name: 'LEFT',
      description: i18n`Returns the first character or n first characters.`,
      syntax: 'LEFT(string, numberOfChars)',
      example: 'LEFT("Hello world!") = "H"\nLEFT("Hello world!", 5) = "Hello"',
    },
    {
      name: 'LEN',
      description: i18n`Returns the number of characters.`,
      syntax: 'LEN(string)',
      example: 'LEN("Hello world!") = 12',
    },
    {
      name: 'LOWER',
      description: i18n`Converts all uppercase letters to lowercase.`,
      syntax: 'LOWER(string)',
      example: 'LOWER("Hello World!") = "hello world!"',
    },
    {
      name: 'MID',
      description: i18n`Returns a specific number of characters, starting at the position you specify, based on the number of characters you specify.`,
      syntax: 'MID(string, startPosition, length)',
      example: 'MID("Hello world!", 7, 5) = "world"',
    },
    {
      name: 'PROPER',
      description: i18n`Capitalizes the first letter of each words. Converts all other letters to lowercase.`,
      syntax: 'PROPER(string)',
      example: 'PROPER("hellO wOrld!") = "Hello World!"',
    },
    {
      name: 'REPLACE',
      description: i18n`Replaces part of a text, based on the position and the number of characters you specify, with a different text string.`,
      syntax: 'REPLACE(string, startPosition, numberOfCharacters, newString)',
      example: 'REPLACE("Hello World!", 7, 5, "YOOI") = "Hello YOOI!"',
    },
    {
      name: 'RIGHT',
      description: i18n`Returns the last character or n last characters.`,
      syntax: 'RIGHT(string, numberOfChars)',
      example: 'RIGHT("Hello world!") = "!"\nRIGHT("Hello world!", 6) = "world!"',
    },
    {
      name: 'TEXT',
      description: i18n`Lets you change the way a number appears by applying formatting to it with format codes. It's useful in situations where you want to display numbers in a more readable format, or you want to combine numbers with text or symbols.`,
      syntax: 'TEXT(value, format)',
      example: 'TEXT(1234.56789,"$#,##0.00") = "$1,234.57"\nTEXT(0.285,"0.0%") = "28.5%"\nTEXT(DATEVALUE(NOW()), "DDDD DD MMMM YYYY") = "Friday 03 November 2023"',
    },
    {
      name: 'TEXTJOIN',
      description: i18n`Join multiple text using the given separator.`,
      syntax: 'TEXTJOIN(delimiter, ignoreEmpty, ...text)',
      example: 'TEXTJOIN(" ", FALSE, "Hello", "World", "!") = "Hello World !"\nTEXTJOIN(";", TRUE, "First", "", "Second", "", "Third") = "First;Second;Third"\nTEXTJOIN(";", FALSE, "First", "", "Second", "", "Third") = "First;;Second;;Third"',
    },
    {
      name: 'TRIM',
      description: i18n`Removes all useless space before and after the text.`,
      syntax: 'TRIM(string)',
      example: 'TRIM("     Hello World!    ") = "Hello World!"',
    },
    {
      name: 'UPPER',
      description: i18n`Converts all lowercase letters to uppercase.`,
      syntax: 'UPPER(string)',
      example: 'UPPER("Hello World!") = "HELLO WORLD!"',
    },
  ];

  const objectFunctions = [
    {
      name: 'GETOBJECTS',
      description: i18n`Joins two or more lists of objects. Also removes duplicates.`,
      syntax: 'GETOBJECTS(list1, [list2], ...)',
    },
  ];

  const categories = [
    { name: i18n`Stat functions`, functions: statsFunctions },
    { name: i18n`Math functions`, functions: mathFunctions },
    { name: i18n`Logical functions`, functions: logicalFunctions },
    { name: i18n`Date functions`, functions: dateFunctions },
    { name: i18n`Timeseries functions`, functions: timeseriesFunctions },
    { name: i18n`Text functions`, functions: textFunctions },
    { name: i18n`Object functions`, functions: objectFunctions },
  ];

  const columnDefinitions: ColumnDefinition<{ name: string, description: string, syntax: string, example?: string }>[] = [
    {
      key: 'name',
      propertyId: 'name',
      name: i18n`Name`,
      width: '20rem',
      sortable: true,
      cellRender: ({ name }) => (
        <TableInnerCellContainer padding={buildPadding(Spacing.s)}>
          <Typo>{name}</Typo>
        </TableInnerCellContainer>
      ),
    },
    {
      key: 'description',
      propertyId: 'description',
      name: i18n`Description`,
      width: '60rem',
      cellRender: ({ description }) => (
        <TableInnerCellContainer padding={buildPadding(Spacing.s)}>
          <Typo>{description}</Typo>
        </TableInnerCellContainer>
      ),
    },
    {
      key: 'syntax',
      propertyId: 'syntax',
      name: i18n`Syntax`,
      width: '40rem',
      cellRender: ({ syntax }) => (
        <TableInnerCellContainer padding={buildPadding(Spacing.s)}>
          <Typo>{syntax}</Typo>
        </TableInnerCellContainer>
      ),
    },
    {
      key: 'example',
      propertyId: 'example',
      name: i18n`Example`,
      width: '50rem',
      cellRender: ({ example }) => (
        <TableInnerCellContainer padding={buildPadding(Spacing.s)}>
          <Typo>{example}</Typo>
        </TableInnerCellContainer>
      ),
    },
  ];

  return (
    <NavigationElementContainer element={{ key: 'formula' }}>
      <BaseLayout
        topBar={(<TopBar />)}
        header={(<Header firstLine={(<HeaderStatic text={i18n`Formula documentation`} />)} />)}
        content={(
          <VerticalBlock>
            <VerticalBlock asBlockContent withSeparation>
              <BlockTitle title={i18n`Playground`} />
              <HorizontalBlock asBlockContent>
                <BlockTitle title={i18n`Formula`} />
                <BlockContent>
                  <SimpleInput
                    initialValue={playgroundFormula}
                    onSubmit={setPlaygroundFormula}
                  >
                    {(props) => (<TextInputString {...props} />)}
                  </SimpleInput>
                </BlockContent>
              </HorizontalBlock>
              <HorizontalBlock asBlockContent>
                <BlockTitle title={i18n`Inputs`} />
                <DataTable
                  columnsDefinition={[
                    {
                      propertyId: 'name',
                      name: i18n`Name`,
                      width: '15rem',
                      cellRender: ({ key }, _, index) => (
                        <StoreTextInputField
                          initialValue={key}
                          onSubmit={(newName) => setInputs((current) => {
                            const cloned = [...current];
                            cloned[index] = { key: newName ?? '', value: current[index].value };
                            return cloned;
                          })}
                        />
                      ),
                    }, {
                      propertyId: 'value',
                      name: i18n`Value`,
                      width: '45rem',
                      cellRender: ({ value }, _, index) => (
                        <StoreTextInputField
                          initialValue={value}
                          onSubmit={(newValue) => setInputs((current) => {
                            const cloned = [...current];
                            cloned[index] = { key: current[index].key, value: newValue ?? '' };
                            return cloned;
                          })}
                        />
                      ),
                    },
                  ]}
                  list={inputs.map((item) => ({ key: item.key, type: 'item', item, color: undefined }))}
                  newItemIcon={IconName.subject}
                  onNewItem={() => setInputs((current) => [...current, { key: '', value: '' }])}
                  newItemTitle={i18n`Add`}
                />
              </HorizontalBlock>
              <HorizontalBlock asBlockContent>
                <BlockTitle title={i18n`Result`} />
                <BlockContent>
                  {playgroundError ? (
                    <SpacingLine>
                      <Typo variant={TypoVariant.code}>{playgroundError.trim()}</Typo>
                    </SpacingLine>
                  ) : (
                    <TextInputString
                      value={playgroundResult}
                      readOnly
                    />
                  )}
                </BlockContent>
              </HorizontalBlock>
            </VerticalBlock>
            {categories.map(({ name, functions }, index, array) => {
              const functionsList = [...functions]; // We copy the list as it is sorted afterward
              if (sort[index] !== undefined) {
                functionsList.sort(comparing(compareProperty('name', compareString), sort[index] === TableSortDirection.asc));
              }

              return (
                <VerticalBlock key={name} asBlockContent withSeparation={index < (array.length - 1)}>
                  <BlockTitle title={name} />
                  <DataTable
                    list={functionsList.map((item) => ({ key: item.name, type: 'item', item, color: undefined }))}
                    columnsDefinition={columnDefinitions}
                    sortCriteria={sort[index] !== undefined ? { key: 'name', direction: sort[index] } : undefined}
                    doSort={() => {
                      setSort((current) => {
                        let newValue;
                        if (current[index] === TableSortDirection.asc) {
                          newValue = TableSortDirection.desc;
                        } else if (current[index] === TableSortDirection.desc) {
                          newValue = undefined;
                        } else {
                          newValue = TableSortDirection.asc;
                        }
                        return joinObjects(current, { [index]: newValue });
                      });
                    }}
                  />
                </VerticalBlock>
              );
            })}
          </VerticalBlock>
        )}
      />
    </NavigationElementContainer>
  );
};

export default FormulaDocumentation;
