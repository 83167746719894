import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { Fragment, useState } from 'react';
import Icon, { IconName } from '../../../../components/atoms/Icon';
import Typo, { TypoVariant } from '../../../../components/atoms/Typo';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeSelectorsClasses from '../../../../utils/makeSelectorsClasses';
import makeStyles from '../../../../utils/makeStyles';
import { notifySuccess } from '../../../../utils/notify';
import useDerivedState from '../../../../utils/useDerivedState';
import useTheme from '../../../../utils/useTheme';
import { generateExportFromValues } from './explorerUtils';
import type { ArrayValue } from './objectRenderType';
import { ValueType } from './objectRenderType';
// Yes, we have a cycle, but this is expected
// eslint-disable-next-line import/no-cycle
import ValueRenderer from './ValueRenderer';

const defaultNumberOfItems = 15;
const selectorsClasses = makeSelectorsClasses('actionHovered');

const useStyles = makeStyles((theme) => ({
  groupContainer: {
    lineHeight: 'initial',
  },
  container: {
    marginLeft: spacingRem.xs,
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.border.default,
  },
  entry: {
    paddingLeft: spacingRem.l,
    paddingRight: spacingRem.s,
    paddingBottom: spacingRem.xxs,
    paddingTop: spacingRem.xxs,
    display: 'flex',
    flexDirection: 'row',
    '& .action': {
      visibility: 'hidden',
    },
    '&:hover .action': {
      visibility: 'visible',
    },
    '&:hover': {
      borderColor: theme.color.text.disabled,
    },
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
  },
  arrayTagContainer: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    columnGap: spacingRem.s,
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
    alignItems: 'center',
    [`& .${selectorsClasses.actionHovered}`]: {
      visibility: 'hidden',
    },
    [`&:hover .${selectorsClasses.actionHovered}`]: {
      visibility: 'visible',
    },
  },
  arrayActionContainer: {
    cursor: 'pointer',
  },
  moreElementEntry: {
    columnGap: spacingRem.s,
    alignItems: 'center',
    color: theme.color.text.info,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}), 'arrayRenderer');

interface ArrayRendererProps {
  array: ArrayValue,
}

const ArrayRenderer: FunctionComponent<ArrayRendererProps> = ({ array }) => {
  const theme = useTheme();
  const classes = useStyles();

  const [collapsed, setCollapsed] = useDerivedState(() => array.collapseByDefault ?? false, [array.collapseByDefault]);
  const [maxDisplayedElements, setMaxDisplayedElements] = useState<number>(defaultNumberOfItems);

  const toggleCollapsed = () => {
    setCollapsed((current) => !current);
    setMaxDisplayedElements(defaultNumberOfItems);
  };

  if (array.elements.length === 0) {
    return (
      <span className={classes.groupContainer}>
        <span className={classes.arrayTagContainer}>
          <Typo variant={TypoVariant.code}>&#91;&#93;</Typo>
        </span>
      </span>
    );
  } else if (array.elements.length <= 3 && array.elements.every((element) => [ValueType.string, ValueType.number, ValueType.date, ValueType.boolean].includes(element.type))) {
    return (
      <span className={classes.groupContainer}>
        <span className={classes.arrayTagContainer}>
          <Typo variant={TypoVariant.code}>&#91;</Typo>
          {
            array.elements.map((element, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>
                {index > 0 && <Typo variant={TypoVariant.code}>&#44;</Typo>}
                <ValueRenderer value={element} />
              </Fragment>
            ))
          }
          <Typo variant={TypoVariant.code}>&#93;</Typo>
        </span>
      </span>
    );
  } else {
    const toggle = (
      <span className={classes.arrayActionContainer} onClick={toggleCollapsed} aria-hidden="true">
        <Icon name={collapsed ? IconName.keyboard_arrow_right : IconName.expand_more} />
      </span>
    );

    const clipboard = (
      <span
        className={classnames(classes.arrayActionContainer, selectorsClasses.actionHovered)}
        onClick={(event) => {
          // We stop the propagation to no trigger the toggle operation (hint <> value) of the containing div
          event.stopPropagation();

          navigator.clipboard.writeText(JSON.stringify(generateExportFromValues(array)));
          notifySuccess(i18n`Copied to clipboard`);
        }}
        aria-hidden="true"
      >
        <Icon name={IconName.description} />
      </span>
    );

    if (collapsed) {
      return (
        <span className={classes.groupContainer}>
          <span className={classes.arrayTagContainer}>
            <Typo variant={TypoVariant.code}>&#91;</Typo>
            {toggle}
            {clipboard}
            <Typo variant={TypoVariant.code} color={theme.color.text.disabled}>{i18n`${array.elements.length} elements`}</Typo>
            <Typo variant={TypoVariant.code}>&#93;</Typo>
          </span>
        </span>
      );
    } else {
      const hiddenElementsCount = array.elements.length - maxDisplayedElements;

      return (
        <span className={classes.groupContainer}>
          <span className={classes.arrayTagContainer}>
            <Typo variant={TypoVariant.code}>&#91;</Typo>
            {toggle}
            {clipboard}
            <Typo variant={TypoVariant.code} color={theme.color.text.disabled}>{i18n`${array.elements.length} elements`}</Typo>
          </span>
          <div className={classes.container}>
            {
              array.elements.slice(0, maxDisplayedElements).map((element, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <span key={index} className={classes.entry}>
                  <ValueRenderer value={element} />
                </span>
              ))
            }
            {
              hiddenElementsCount > 0
                ? (
                  <span
                    className={classnames(classes.entry, classes.moreElementEntry)}
                    onClick={(event) => {
                      if (event.ctrlKey) {
                        setMaxDisplayedElements((current) => (current + hiddenElementsCount));
                      } else {
                        setMaxDisplayedElements((current) => (current + defaultNumberOfItems));
                      }
                    }}
                    aria-hidden="true"
                  >
                    <Icon name={IconName.add} />
                    <Typo>
                      {
                        hiddenElementsCount < defaultNumberOfItems
                          ? i18n`Load last ${hiddenElementsCount} elements`
                          : i18n`Load ${defaultNumberOfItems} more elements (out of ${hiddenElementsCount})`
                      }
                    </Typo>
                  </span>
                )
                : null
            }
          </div>
          <span className={classes.arrayTagContainer}>
            <Typo variant={TypoVariant.code}>&#93;</Typo>
          </span>
        </span>
      );
    }
  }
};

export default ArrayRenderer;
