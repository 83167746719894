import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type {
  ConceptDefinitionInstanceAdministrationColumnRaw,
  ConceptDefinitionInstanceAdministrationColumnStoreObject,
  ConceptDefinitionStoreObject,
  ConceptStoreObject,
} from 'yooi-modules/modules/conceptModule';
import { getFieldDimensionOfModelType, isConceptValid } from 'yooi-modules/modules/conceptModule';
import {
  Concept_FunctionalId,
  Concept_Name,
  ConceptDefinition_AdministrationColumns,
  ConceptDefinition_LibraryItemPerPage,
  ConceptDefinition_TableGroupBy,
  ConceptDefinitionInstanceAdministrationColumn,
  ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition,
  ConceptDefinitionInstanceAdministrationColumn_Field,
  ConceptDefinitionInstanceAdministrationColumn_Rank,
  ConceptDefinitionInstanceAdministrationColumn_Width,
  StakeholdersField,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { StoreObject } from 'yooi-store';
import { compareProperty, compareRank, filterNullOrUndefined, joinObjects, ranker } from 'yooi-utils';
import Icon, { IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import Tooltip from '../../../../../components/atoms/Tooltip';
import Typo, { TypoVariant } from '../../../../../components/atoms/Typo';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import { TableSortDirection } from '../../../../../components/molecules/Table';
import { tableHeaderCellSelectorsClasses } from '../../../../../components/molecules/TableHeaderCell';
import BlockContent from '../../../../../components/templates/BlockContent';
import type { ColumnDefinition, GroupEntry, ItemEntry } from '../../../../../components/templates/DataTable';
import type { Pagination } from '../../../../../components/templates/PageSelector';
import VerticalBlock from '../../../../../components/templates/VerticalBlock';
import useAcl from '../../../../../store/useAcl';
import useAuth from '../../../../../store/useAuth';
import useStore from '../../../../../store/useStore';
import { spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import useDerivedState from '../../../../../utils/useDerivedState';
import { SessionStorageKeys, useSessionStorageState } from '../../../../../utils/useSessionStorage';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../../../utils/useSizeContext';
import useTheme from '../../../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../../../utils/useUsageContext';
import ConceptListExportButton from '../../../../_global/ConceptListExportButton';
import ConceptViewTopBar, { DisplayedLine } from '../../../../_global/ConceptViewTopBar';
import ConceptTable from '../../../../_global/fields/_global/ConceptTable';
import { getFieldHandler } from '../../../../_global/fields/FieldLibrary';
import { getFieldColumnComparator } from '../../../../_global/fieldUtils';
import { ConceptDefinitionFavoriteFiltersBar } from '../../../../_global/filter/FavoriteFiltersBar';
import type { FilterConfiguration } from '../../../../_global/filter/useFilterSessionStorage';
import { getFieldGroupByHandler } from '../../../../_global/groupByUtils';
import { getConceptFilterFunction } from '../../../../_global/listFilterFunctions';
import { getChipOptions, getTableGroupByField, listGroupByFieldOptions } from '../../../../_global/modelTypeUtils';
import type { TableConfiguration } from '../../../../_global/sessionStorageTypes';
import useFilterAndSort from '../../../../_global/useFilterAndSort';
import UpdateColumnAction from './UpdateColumnAction';

const useStyles = makeStyles({
  configContainer: {
    marginBottom: spacingRem.xs,
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.s,
    justifyContent: 'flex-end',
  },
  filtersContainer: {
    marginBottom: spacingRem.xs,
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.s,
  },
  headerContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: spacingRem.l,
  },
  headerRightContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.m,
    paddingBottom: spacingRem.xs,
  },
  tableHeaderContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
    columnGap: spacingRem.xs,
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    minHeight: spacingRem.xl,
  },
  tableHeaderNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    userSelect: 'none',
    overflow: 'hidden',
    columnGap: spacingRem.xs,
  },
  tableHeaderActionContainer: {
    position: 'absolute',
    right: spacingRem.xs,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 0,
  },
  tableHeaderAddContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    visibility: 'hidden',
  },
}, 'instancesTab');

interface InstancesTabProps {
  conceptDefinitionId: string,
}

const InstancesTab: FunctionComponent<InstancesTabProps> = ({ conceptDefinitionId }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const { canCreateObject } = useAcl();
  const { loggedUserId } = useAuth();

  const [focusOnMountId, setFocusOnMountId] = useDerivedState<string | undefined>(() => undefined, [conceptDefinitionId]);

  const [displayedLine, setDisplayedLine] = useState<DisplayedLine | undefined>(undefined);
  const showOptions = displayedLine === DisplayedLine.options;
  const showFilters = displayedLine === DisplayedLine.filters;

  const [filtersConfiguration] = useSessionStorageState<FilterConfiguration | undefined>(conceptDefinitionId, undefined);
  const [tableConfig, , updateTableConfig] = useSessionStorageState<TableConfiguration | undefined>(`${SessionStorageKeys.tableConfig}_${conceptDefinitionId}`, undefined);
  const tableGroupByField = getTableGroupByField(store, conceptDefinitionId, tableConfig);

  const conceptDefinition = store.getObject<ConceptDefinitionStoreObject>(conceptDefinitionId);
  const columns = ranker.decorateList(
    conceptDefinition
      .navigateBack<ConceptDefinitionInstanceAdministrationColumnStoreObject>(ConceptDefinition_AdministrationColumns)
      .sort(compareProperty(ConceptDefinitionInstanceAdministrationColumn_Rank, compareRank)),
    (column) => column[ConceptDefinitionInstanceAdministrationColumn_Rank]
  );

  const hasFunctionalId = columns.some(({ item: { [ConceptDefinitionInstanceAdministrationColumn_Field]: fieldId } }) => fieldId === Concept_FunctionalId);

  const { generateList, generateGroupedPageList, generateGroupedList, doSort, sortCriteria, forceFollowingIds, forceShowId } = useFilterAndSort(
    conceptDefinitionId,
    conceptDefinition.navigateBack<ConceptStoreObject>(Class_Instances).filter((instance) => isConceptValid(store, instance.id)),
    getConceptFilterFunction(store, conceptDefinitionId, filtersConfiguration, loggedUserId),
    {
      getComparatorHandler: getFieldColumnComparator(store),
      initial: hasFunctionalId ? { key: Concept_FunctionalId, direction: TableSortDirection.desc } : { key: Concept_Name, direction: TableSortDirection.asc },
    },
    tableGroupByField ? getFieldGroupByHandler(store, tableGroupByField.id) : undefined
  );

  const columnDefinitions = columns
    .map((
      {
        item: { id, [ConceptDefinitionInstanceAdministrationColumn_Field]: fieldId, [ConceptDefinitionInstanceAdministrationColumn_Width]: width },
        moveDownRank,
        moveUpRank,
      },
      index,
      { length }
    ): ColumnDefinition<StoreObject> => {
      const field = fieldId ? store.getObjectOrNull(fieldId) ?? undefined : undefined;
      const fieldHandler = field ? getFieldHandler(store, field.id) : undefined;
      const columnDefinition = fieldHandler?.getColumnDefinition?.();
      const dimensionId = field ? getFieldDimensionOfModelType(store, field.id, conceptDefinitionId) : undefined;

      let actualWidth: number | string | undefined;
      if (width === undefined) {
        if (fieldHandler) {
          actualWidth = fieldHandler?.estimatedColumnWidth?.(conceptDefinitionId);
        } else {
          actualWidth = '15rem';
        }
      } else if (width.type === 'percent') {
        actualWidth = width.value;
      } else if (width.type === 'rem') {
        actualWidth = `${width.value}rem`;
      }

      const cellRender: ColumnDefinition<StoreObject>['cellRender'] = (instance, focusOnMount) => (
        dimensionId
          ? (
            fieldHandler?.renderField?.({
              dimensionsMapping: isInstanceOf(field, StakeholdersField) ? { n1InstanceId: instance.id } : { [dimensionId]: instance.id },
              readOnly: false,
              focusOnMount,
            }) ?? null
          )
          : null
      );

      const headerRender: ColumnDefinition<StoreObject>['headerRender'] = (sort) => (
        <div className={classes.tableHeaderContainer}>
          <span className={classes.tableHeaderNameContainer}>
            {columnDefinition?.name !== undefined ? (
              <Tooltip title={columnDefinition.name}>
                <Typo variant={TypoVariant.small} maxLine={1}>
                  {columnDefinition.name}
                </Typo>
              </Tooltip>
            ) : null}
            {sort !== undefined ? (
              <Icon
                name={sort === 'asc' ? IconName.arrow_upward : IconName.arrow_downward}
                tooltip={sort === 'asc' ? i18n`Ascending sort` : i18n`Descending sort`}
              />
            ) : null}
          </span>
          <SizeContextProvider sizeVariant={SizeVariant.small}>
            <span className={classes.tableHeaderActionContainer}>
              <UsageContextProvider usageVariant={UsageVariant.inline}>
                <UpdateColumnAction
                  conceptDefinitionInstanceAdministrationColumnId={id}
                  moveLeft={index === 0 ? undefined : () => {
                    store.updateObject<ConceptDefinitionInstanceAdministrationColumnRaw>(
                      id,
                      { [ConceptDefinitionInstanceAdministrationColumn_Rank]: moveUpRank() }
                    );
                  }}
                  moveRight={index === length - 1 ? undefined : () => {
                    store.updateObject<ConceptDefinitionInstanceAdministrationColumnRaw>(
                      id,
                      { [ConceptDefinitionInstanceAdministrationColumn_Rank]: moveDownRank() }
                    );
                  }}
                  focusOnMount={focusOnMountId === id}
                />
              </UsageContextProvider>
            </span>
          </SizeContextProvider>
        </div>
      );

      return joinObjects(
        { propertyId: fieldId ?? id },
        columnDefinition,
        { key: id, width: actualWidth, headerRender, cellRender }
      );
    });

  const itemPerPage = conceptDefinition[ConceptDefinition_LibraryItemPerPage];
  let data: { list: (ItemEntry<StoreObject> | GroupEntry)[], pagination?: Pagination | undefined };
  if (itemPerPage) {
    data = generateGroupedPageList(itemPerPage);
  } else {
    data = generateGroupedList();
  }

  return (
    <VerticalBlock compact>
      <BlockContent padded>
        <div className={classes.headerContainer}>
          <div className={classes.headerRightContainer}>
            <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
              <ConceptViewTopBar
                filterKey={conceptDefinitionId}
                onOptionsClick={() => {
                  setDisplayedLine((current) => (current === DisplayedLine.options ? undefined : DisplayedLine.options));
                }}
                onFiltersClick={() => {
                  setDisplayedLine((current) => (current === DisplayedLine.filters ? undefined : DisplayedLine.filters));
                }}
                activeFilters={showFilters}
                activeOptions={showOptions}
                renderExportButton={() => (
                  <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
                    <ConceptListExportButton
                      generateList={() => generateList().list.map(({ item }) => item)}
                      typeId={conceptDefinitionId}
                      displayedFieldsIds={
                        columns.map(({ item: { [ConceptDefinitionInstanceAdministrationColumn_Field]: fieldId } }) => fieldId).filter(filterNullOrUndefined)
                      }
                    />
                  </SizeContextProvider>
                )}
              />
            </SizeContextProvider>
          </div>
        </div>
      </BlockContent>
      {showOptions && (
        <BlockContent padded>
          <UsageContextProvider usageVariant={UsageVariant.inForm}>
            <div className={classes.configContainer}>
              <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.inline}>
                <Typo maxLine={1} color={theme.color.text.secondary}>{i18n`Group by`}</Typo>
                <SearchAndSelect
                  clearable
                  computeOptions={() => listGroupByFieldOptions(store, conceptDefinitionId, true)}
                  selectedOption={tableGroupByField ? getChipOptions(store, tableGroupByField.id) : undefined}
                  onSelect={(value) => {
                    updateTableConfig({ [ConceptDefinition_TableGroupBy]: value?.id ?? null });
                  }}
                />
              </SizeContextProvider>
            </div>
          </UsageContextProvider>
        </BlockContent>
      )}
      {showFilters && (
        <BlockContent padded>
          <div className={classes.filtersContainer}>
            <ConceptDefinitionFavoriteFiltersBar filterKey={conceptDefinitionId} conceptDefinitionId={conceptDefinitionId} />
          </div>
        </BlockContent>
      )}
      <ConceptTable
        conceptDefinitionId={conceptDefinitionId}
        columnsDefinition={columnDefinitions}
        list={data.list}
        pagination={data.pagination}
        sortCriteria={sortCriteria}
        doSort={doSort}
        forceFollowingIds={forceFollowingIds}
        forceShowId={forceShowId}
        newItemTitle={i18n`Create`}
        newItemIcon={IconName.add}
        onNewItem={canCreateObject(conceptDefinitionId) ? () => store.createObject({ [Instance_Of]: conceptDefinitionId }) : undefined}
        linesActionsHeaderRenderer={() => (
          <span className={classnames(classes.tableHeaderAddContainer, tableHeaderCellSelectorsClasses.visibilitySensor)}>
            <SizeContextProvider sizeVariant={SizeVariant.small}>
              <IconOnlyButton
                iconName={IconName.add}
                variant={IconOnlyButtonVariants.secondary}
                tooltip={i18n`Add column`}
                onClick={() => {
                  const id = store.createObject<ConceptDefinitionInstanceAdministrationColumnRaw>({
                    [Instance_Of]: ConceptDefinitionInstanceAdministrationColumn,
                    [ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition]: conceptDefinitionId,
                    [ConceptDefinitionInstanceAdministrationColumn_Rank]: columns.insertAfterLastItemRank(),
                  });
                  setFocusOnMountId(id);
                }}
              />
            </SizeContextProvider>
          </span>
        )}
        minColumnWidthRem={3}
        withoutActivityIndicator
      />
    </VerticalBlock>
  );
};

export default InstancesTab;
