import type { FormulaType } from 'yooi-utils';
import { StructuredError } from 'yooi-utils';
import type { ConceptStoreObject } from '../../model';
import type { FilterValue, PathStep } from '../../moduleType';
import type { DimensionsMapping, ParametersMapping } from '../parametersUtils';
import type { GlobalDimensionResolution } from './pathResolver1';

export enum ResolutionError {
  cannotNavigatePath = 'cannotNavigatePath',
  unknownResolverError = 'unknownResolverError',
  invalidEmbeddingField = 'invalidEmbeddingField',
  invalidMapping = 'invalidMapping',
  invalidDimension = 'invalidDimension',
  invalidFilter = 'invalidFilter',
  invalidField = 'invalidField',
}

export enum StepResolutionErrorStepType {
  globalDimension = 'globalDimension',
  dimension = 'dimension',
  filter = 'filter',
  mapping = 'mapping',
  field = 'field',
  unknown = 'unknown',
}

export type StepResolutionErrorType =
  | { type: StepResolutionErrorStepType.globalDimension }
  | { type: StepResolutionErrorStepType.dimension, conceptDefinitionId: string }
  | { type: StepResolutionErrorStepType.filter }
  | { type: StepResolutionErrorStepType.mapping }
  | { type: StepResolutionErrorStepType.field, fieldId: string }
  | { type: StepResolutionErrorStepType.unknown };

export class StepResolutionError extends StructuredError<{ stepType: StepResolutionErrorType, resolutionError?: ResolutionError }> {
  override readonly name = 'StepResolutionError';

  constructor(stepType: StepResolutionErrorType, origin: { cause?: Error, resolutionError?: ResolutionError }) {
    super(origin.cause?.name ?? origin.resolutionError ?? 'Unknown step resolution error', { cause: origin.cause, data: { stepType, resolutionError: origin.resolutionError } });
  }
}

export enum FieldResolutionType {
  single = 'single',
  multi = 'multi',
  error = 'error',
}

export interface FieldResolution {
  type: FieldResolutionType,
}

export interface SingleFieldResolution extends FieldResolution {
  type: FieldResolutionType.single,
  dimensionsMapping: DimensionsMapping | undefined,
  fieldId: string,
}

export interface MultiFieldResolution extends FieldResolution {
  type: FieldResolutionType.multi,
  resolutions: SingleFieldResolution[],
  fieldId: string,
}

export enum DimensionResolutionType {
  single = 'single',
  multi = 'multi',
  global = 'global',
}

export interface DimensionResolution {
  type: DimensionResolutionType,
}

export interface SingleDimensionResolution extends DimensionResolution {
  type: DimensionResolutionType.single,
  instance: ConceptStoreObject | undefined,
  resolutionType?: FormulaType,
}

export interface MultiDimensionResolution extends DimensionResolution {
  type: DimensionResolutionType.multi,
  instances: ConceptStoreObject[],
  resolutionType?: FormulaType,
}

export enum ValueResolutionType {
  single = 'single',
  multi = 'multi',
  error = 'error',
}

interface ValueResolution {
  type: ValueResolutionType,
}

export interface SingleValueResolution<T = unknown> extends ValueResolution {
  type: ValueResolutionType.single,
  isArrhythmicTimeseries: boolean,
  value: T | undefined,
  isTimeseries: boolean,
  resolutionType?: FormulaType,
}

export interface MultiValueResolution<T = unknown> extends ValueResolution {
  type: ValueResolutionType.multi,
  isArrhythmicTimeseries: boolean,
  values: T[],
  isTimeseries: boolean,
  resolutionType?: FormulaType,
}

export const isSingleFieldResolution = (fieldResolution: FieldResolution | Error | undefined): fieldResolution is SingleFieldResolution => (
  !(fieldResolution instanceof Error) && fieldResolution?.type === FieldResolutionType.single
);
export const isMultiFieldResolution = (fieldResolution: FieldResolution | Error | undefined): fieldResolution is MultiFieldResolution => (
  !(fieldResolution instanceof Error) && fieldResolution?.type === FieldResolutionType.multi
);
export const isSingleDimensionResolution = (DimensionResolution: DimensionResolution): DimensionResolution is SingleDimensionResolution => (
  DimensionResolution.type === DimensionResolutionType.single
);
export const isMultiDimensionResolution = (DimensionResolution: DimensionResolution): DimensionResolution is MultiDimensionResolution => (
  DimensionResolution.type === DimensionResolutionType.multi
);
export const isGlobalDimensionResolution = (DimensionResolution: DimensionResolution): DimensionResolution is GlobalDimensionResolution => (
  DimensionResolution.type === DimensionResolutionType.global
);
export const isValidValuePathResolution = (valueResolution: ValueResolution | Error | undefined): valueResolution is SingleValueResolution | MultiValueResolution => (
  !(valueResolution instanceof Error) && valueResolution !== undefined
);
export const isMultiValueResolution = (valueResolution: ValueResolution | Error | undefined): valueResolution is MultiValueResolution => (
  !(valueResolution instanceof Error) && valueResolution?.type === ValueResolutionType.multi
);
export const isSingleValueResolution = (valueResolution: ValueResolution | Error | undefined): valueResolution is SingleValueResolution => (
  !(valueResolution instanceof Error) && valueResolution?.type === ValueResolutionType.single
);
export const isValidDimensionsResolution = (
  dimensionsResolution: SingleDimensionResolution | MultiDimensionResolution | GlobalDimensionResolution | Error | undefined
): dimensionsResolution is SingleDimensionResolution | MultiDimensionResolution => !(dimensionsResolution instanceof Error) && dimensionsResolution !== undefined;

export type FilterFunction = ((parameters: ParametersMapping) => boolean) | undefined;

export interface FilterConditionExecutionContext {
  sanitizedValue: FilterValue<unknown>,
  leftValue: PathStep[],
  filterFunction: (leftValue: unknown, rightValue: unknown, parameters: ParametersMapping) => boolean,
  valueShouldBeMultiple: boolean,
  isNPath: boolean,
  isTargetingConcept: boolean,
}
