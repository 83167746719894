// This file is automatically updated by the generate-constants script. Do not update it manually.

export const PlatformConfigurationModuleId = '2235218b-3e40-50a5-9d99-074205e382ec';
export const CurrentPlatformConfiguration = 'bb9a6c6b-d19a-4491-8bac-d78fbfc9cd7b';
export const Homepage = 'c364c444-48be-41fc-ae58-4044dbf0644a';
export const Homepage_DisplayConditions = 'b05fdd7c-0569-465e-ae5d-53b78496d080';
export const Homepage_Path = '898b2a18-32ae-4409-9e1a-10ba88047a07';
export const Homepage_Rank = 'b18756bf-fe34-48a2-a6c8-147c39235ac2';
export const IFrameAllowedUrl = '5ff00c23-7b42-4a00-8d80-72802e43d95d';
export const IFrameAllowedUrl_Url = '14684996-b81b-41eb-b4bf-0661fa61dc56';
export const LeftBarItem = 'a64ca655-c224-54b2-bcc1-f46b9d84dbbe';
export const LeftBarItem_DisplayConditions = 'cf5f3209-cc3a-4c35-a40e-ef7152d7e94a';
export const LeftBarItem_Path = '81ba6bcc-9622-403a-afcc-d8ebe709c45b';
export const LeftBarItem_Rank = '3854ac09-27cd-5a76-a234-414e60d43f9b';
export const PlatformConfiguration = '347d93d8-efb8-4eb9-9bfc-3df18e142daf';
export const PlatformConfiguration_AccentColor = '31d0e46e-53d5-41de-ae2c-4f3d3f4f98be';
export const PlatformConfiguration_AccentColorType = '37be901a-6337-4b0d-9b09-98da9d113c57';
export const PlatformConfiguration_AutoLogoutTime = '1ed2464c-8242-45ef-baf1-b9be4a5a4261';
export const PlatformConfiguration_CollaborationMessage = '07bcb9d8-fd54-4eba-bce3-6f6c9538e942';
export const PlatformConfiguration_ColorPalette = '41669b77-c7c6-4e85-aee4-998d854837f8';
export const PlatformConfiguration_ColorPaletteType = 'eb5cf330-3a34-45bc-b954-f27f7cce1e1b';
export const PlatformConfiguration_IntegrationDefaultExpiration = '8e877bbc-a7b1-4942-8c4e-4f36dd524f63';
export const PlatformConfiguration_Language = '49b1c6da-6462-4939-bee9-8137cd3e2be4';
export const PlatformConfiguration_LeftBarImage = 'ffa19798-ba7d-4807-a110-343c83b41fcb';
export const PlatformConfiguration_Logo = '242d44dd-f71d-461a-b244-757c2bb4c56e';
export const PlatformConfiguration_MasterDetailMode = 'b88b9d94-3e3c-4284-8bb2-16bde7f71505';
export const PlatformConfiguration_Motto = '3351a8c0-2c2a-4908-b970-1a169b12405c';
export const PlatformConfiguration_Name = '7b94835d-f055-4d55-bc2c-8201e26837b0';
