import type { ObjectStoreReadOnly } from '../../ObjectStoreType';
import type { InitializedModule } from '../platformModule';
import type { ObjectDebugLabelHandler, RegisterObjectDebugLabel } from '../PlatformModulesTypes';

interface ObjectDebugLabelRegisterType {
  register: (handler: ObjectDebugLabelHandler) => void,
}

export interface ObjectDebugLabelRegistrationHandler {
  onObject: (objectId: string) => ObjectDebugLabelRegisterType,
}

export interface ObjectDebugLabelLibrary {
  object: (objectId: string) => ObjectDebugLabelHandler[],
}

export const createObjectDebugLabelLibrary = (modules: InitializedModule[], objectStore: ObjectStoreReadOnly): ObjectDebugLabelLibrary => {
  const typedObjectDebugLabel: { objects: Record<string, ObjectDebugLabelHandler[]> } = { objects: {} };

  const onObject: ObjectDebugLabelRegistrationHandler['onObject'] = (objectId) => {
    const typeRegistrationHandler: ObjectDebugLabelRegisterType = {
      register: (handler) => {
        typedObjectDebugLabel.objects[objectId] = [...(typedObjectDebugLabel.objects[objectId] ?? []), handler];
        return typeRegistrationHandler;
      },
    };
    return typeRegistrationHandler;
  };

  modules
    .filter((module) => !!module.registerObjectDebugLabel)
    .map((module) => module.registerObjectDebugLabel)
    .filter((registerObjectDebugLabel): registerObjectDebugLabel is RegisterObjectDebugLabel => !!registerObjectDebugLabel)
    .forEach((registerObjectDebugLabel) => registerObjectDebugLabel(objectStore, { onObject }));

  return {
    object: (objectId) => typedObjectDebugLabel.objects[objectId] ?? [],
  };
};
