import classnames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import makeStyles from '../../utils/makeStyles';
import useTooltipRef from '../../utils/useTooltipRef';

interface TooltipProps {
  title: string | (() => Promise<string>),
  children: ReactNode,
  asFlexbox?: boolean,
}

const useStyles = makeStyles({
  flexbox: {
    display: 'flex',
  },
}, 'tooltip');

const Tooltip: FunctionComponent<TooltipProps> = ({ title, children, asFlexbox = false }) => {
  const classes = useStyles();

  const tooltipRef = useTooltipRef(title);

  return (
    <span
      ref={tooltipRef}
      className={classnames({ [classes.flexbox]: asFlexbox })}
    >
      {children}
    </span>
  );
};

export default Tooltip;
