enum OriginSources {
  CLIENT = 'CLIENT',
  API = 'API',
  AUTOMATION = 'AUTOMATION',
  SYSTEM = 'SYSTEM',
  GARBAGE = 'GARBAGE',
  MIGRATION = 'MIGRATION',
  AUTHENTICATION = 'AUTHENTICATION',
  USER_SERVICE = 'USER_SERVICE',
  YOOI_TOOLS = 'YOOI_TOOLS',
}

export default OriginSources;
