import type { FunctionComponent } from 'react';
import { useState } from 'react';
import {
  FieldRelevantFieldDisplay,
  FieldRelevantFieldDisplay_Rank,
  FieldRelevantFieldDisplay_Role_ConceptDefinition,
  FieldRelevantFieldDisplay_Role_Field,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import { getConceptDefinitionValidFields, isConceptDefinitionField } from 'yooi-modules/modules/conceptModule';
import { AssociationField, Field_Title, KinshipRelationField, RelationSingleField, WorkflowField } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { compareRank, filterNullOrUndefined, ranker } from 'yooi-utils';
import { ButtonVariant } from '../../../../../components/atoms/Button';
import { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import TableCell, { TableCellAlign } from '../../../../../components/molecules/TableCell';
import TableLine from '../../../../../components/molecules/TableLine';
import DataTable from '../../../../../components/templates/DataTable';
import useStore from '../../../../../store/useStore';
import i18n from '../../../../../utils/i18n';
import { getFieldHandler } from '../../../../_global/fields/FieldLibrary';
import { getFieldChip } from '../../../../_global/fieldUtils';
import { defaultOptionComparator, getChipOptions } from '../../../../_global/modelTypeUtils';

interface RelevantDimensionsEditorProps {
  conceptDefinitionId: string,
}

const RelevantDimensionsEditor: FunctionComponent<RelevantDimensionsEditorProps> = ({ conceptDefinitionId }) => {
  const store = useStore();

  const [showLine, setShowLine] = useState(false);

  const fields = ranker.decorateList(
    store.withAssociation(FieldRelevantFieldDisplay)
      .withRole(FieldRelevantFieldDisplay_Role_ConceptDefinition, conceptDefinitionId)
      .list()
      .map((assoc) => ({ rank: assoc.object[FieldRelevantFieldDisplay_Rank], id: assoc.role(FieldRelevantFieldDisplay_Role_Field) }))
      .sort((a, b) => compareRank(a.rank as string, b.rank as string)),
    (field) => field.rank as string
  );
  const fieldList = [...fields.map((field) => field.item)];

  const selectableFields = () => getConceptDefinitionValidFields(store, conceptDefinitionId)
    .filter((field) => !fields.some(({ item: { id } }) => id === field.id))
    .filter((field) => (
      isInstanceOf(field, KinshipRelationField)
      || isInstanceOf(field, RelationSingleField)
      || isInstanceOf(field, WorkflowField)
      || isInstanceOf(field, AssociationField)
    ))
    .filter((field) => getFieldHandler(store, field.id)?.getColumnDefinition)
    .map((field) => getChipOptions(store, field.id))
    .filter(filterNullOrUndefined)
    .sort(defaultOptionComparator);

  const moveField = (fieldId: string, rank: string) => {
    store.withAssociation(FieldRelevantFieldDisplay)
      .withRole(FieldRelevantFieldDisplay_Role_Field, fieldId)
      .withRole(FieldRelevantFieldDisplay_Role_ConceptDefinition, conceptDefinitionId)
      .updateObject({ [FieldRelevantFieldDisplay_Rank]: rank });
  };
  const moveFieldUp = (fieldIdToMove: string) => {
    const fieldToInsertBefore = fields[fields.findIndex(({ item }) => item.id === fieldIdToMove) - 1];
    if (fieldToInsertBefore) {
      moveField(fieldIdToMove, fieldToInsertBefore.insertBeforeRank());
    }
  };
  const moveFieldDown = (fieldIdToMove: string) => {
    const fieldToInsertAfter = fields[fields.findIndex(({ item }) => item.id === fieldIdToMove) + 1];
    if (fieldToInsertAfter) {
      moveField(fieldIdToMove, fieldToInsertAfter.insertAfterRank());
    }
  };

  return (
    <DataTable
      list={fields.map(({ item }) => ({ key: item.id, type: 'item', item, color: undefined }))}
      columnsDefinition={[
        {
          name: i18n`Name`,
          width: 100,
          propertyId: Field_Title,
          cellRender: ({ id }) => (
            <SearchAndSelect
              readOnly
              selectedOption={getFieldChip(store, conceptDefinitionId, id)}
              statusIcon={
                isConceptDefinitionField(store, id, conceptDefinitionId)
                  ? undefined : { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` }
              }
            />
          ),
        },
        {
          name: '',
          propertyId: 'move-up',
          action: true,
          align: TableCellAlign.center,
          cellRender: ({ id }) => (
            <IconOnlyButton
              onClick={() => moveFieldUp(id)}
              disabled={fieldList.findIndex((f) => f.id === id) === 0}
              iconName={IconName.expand_less}
              tooltip={i18n`Move Up`}
              variant={IconOnlyButtonVariants.tertiary}
            />
          ),
        },
        {
          name: '',
          propertyId: 'move-down',
          action: true,
          align: TableCellAlign.center,
          cellRender: ({ id }) => (
            <IconOnlyButton
              onClick={() => moveFieldDown(id)}
              disabled={fieldList.findIndex((f) => f.id === id) === fields.length}
              iconName={IconName.expand_more}
              tooltip={i18n`Move Down`}
              variant={IconOnlyButtonVariants.tertiary}
            />
          ),
        },
      ]}
      linesActions={(line) => [
        {
          key: 'unlink',
          name: i18n`Unlink field`,
          icon: IconName.delete,
          danger: true,
          onClick: () => {
            store.withAssociation(FieldRelevantFieldDisplay)
              .withRole(FieldRelevantFieldDisplay_Role_Field, line.id)
              .withRole(FieldRelevantFieldDisplay_Role_ConceptDefinition, conceptDefinitionId)
              .deleteObject();
          },
        },
      ]}
      inlineCreation={{
        render: showLine ? (
          <TableLine>
            <TableCell>
              <SearchAndSelect
                computeOptions={selectableFields}
                editOnMount
                onSelect={(value) => {
                  store.withAssociation(FieldRelevantFieldDisplay)
                    .withRole(FieldRelevantFieldDisplay_Role_Field, value?.id as string)
                    .withRole(FieldRelevantFieldDisplay_Role_ConceptDefinition, conceptDefinitionId)
                    .updateObject({ [FieldRelevantFieldDisplay_Rank]: fields.insertAfterLastItemRank() });
                  setShowLine(false);
                }}
                onEscape={() => setShowLine(false)}
              />
            </TableCell>
            <TableCell action />
            <TableCell action />
            <TableCell action />
          </TableLine>
        ) : null,
      }}
      onNewItem={() => setShowLine(true)}
      newItemIcon={IconName.add}
      newItemTitle={i18n`Add`}
      newItemButtonVariant={ButtonVariant.tertiary}
      handleClickAway={() => setShowLine(false)}
    />
  );
};

export default RelevantDimensionsEditor;
