import { createContext } from 'react';
import type { SourceToTargetType } from './types';

export interface ArcherContainerContextType {
  registerChild: (id: string, element: HTMLElement) => void,
  registerTransitions: (id: string, sourceToTarget: SourceToTargetType[]) => void,
  unregisterChild: (id: string) => void,
  unregisterTransitions: (id: string) => void,
}

export const ArcherContainerContext = createContext<ArcherContainerContextType | null>(null);
export const ArcherContainerContextProvider = ArcherContainerContext.Provider;
