import type { FunctionComponent, MutableRefObject } from 'react';
import type { BlockStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import {
  Block,
  Block_EditDisplayCondition,
  Block_FieldDisplays,
  Block_Name,
  Block_Parent,
  Block_Rank,
  Block_Type,
  Block_ViewDisplayCondition,
  BlockParent_Block,
  BlockType_Column,
  BlockType_Section,
  BlockType_Tab,
  FieldBlockDisplay_Block,
  FieldBlockDisplay_Rank,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { Filters, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { FILTER_PARAMETER_CURRENT, InstanceReferenceType, PathStepType } from 'yooi-modules/modules/conceptModule';
import type { StoreObject } from 'yooi-store';
import type { DecoratedList } from 'yooi-utils';
import { compareProperty, compareRank, ranker } from 'yooi-utils';
import { IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import Typo, { TypoVariant } from '../../../../../components/atoms/Typo';
import OverflowMenu from '../../../../../components/molecules/OverflowMenu';
import TableCell, { TableCellVAlign } from '../../../../../components/molecules/TableCell';
import TableLine from '../../../../../components/molecules/TableLine';
import useStore from '../../../../../store/useStore';
import { spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import { formatOrUndef } from '../../../../../utils/stringUtils';
import useDeleteModal from '../../../../../utils/useDeleteModal';
import { useHighlightNotify } from '../../../../../utils/useHighlight';
import type { NewLineFocusRefContent } from '../../../../../utils/useNewLineFocus';
import useScrollOnMountRef from '../../../../../utils/useScrollOnMountRef';
import { SessionStorageKeys, useSessionStorageState } from '../../../../../utils/useSessionStorage';
import { SizeVariant } from '../../../../../utils/useSizeContext';
import useTheme from '../../../../../utils/useTheme';
import type { FilterDefinition } from '../../../../_global/filter/filterComposite/FilterComposite';
import FilterComposite from '../../../../_global/filter/filterComposite/FilterComposite';
import { getLoggedUserParameterDefinition } from '../../../../_global/filter/filterUtils';
import StoreTextInputField from '../../../../_global/input/StoreTextInputField';
import { getConceptDefinitionNameOrEntity } from '../../../../_global/modelTypeUtils';
import type { DisplayItem, LayoutCollapseConfiguration } from '../../../_global/displayItemsLibrary/definitions/displayItemDefinitionUtils';
import { getChildrenCount, isFieldDisplayItem } from '../../../_global/displayItemsLibrary/definitions/displayItemDefinitionUtils';
import { getDisplayItemDefinitionHandler, getDisplayItemHandler } from '../../../_global/displayItemsLibrary/displayItemLibrary';
import DisplayOptionsLayoutCell from './DisplayOptionsLayoutCell';
import { expandBlock } from './layoutUtils';

const useStyles = makeStyles({
  blockNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: spacingRem.s,
  },
  childrenCount: {
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    minWidth: '5rem',
  },
}, 'layoutDisplayTableBlockLine');

const getDeleteModalLabels = (block: BlockStoreObject) => {
  const blockTypeId = block[Block_Type];
  if (blockTypeId === BlockType_Tab) {
    return {
      title: i18n`Are you sure you want to remove this tab?`,
      confirmLabel: i18n`Remove tab and content`,
      alternateConfirmLabel: i18n`Remove tab only`,
      content: i18n`You can choose to remove the tab and its content or only the tab`,
    };
  } else if (blockTypeId === BlockType_Column) {
    return {
      title: i18n`Are you sure you want to remove this column?`,
      confirmLabel: i18n`Remove column and content`,
      alternateConfirmLabel: i18n`Remove column only`,
      content: i18n`You can choose to remove the column and its content or only the column`,
    };
  } else {
    return {
      title: i18n`Are you sure you want to remove this section?`,
      confirmLabel: i18n`Remove section and content`,
      alternateConfirmLabel: i18n`Remove section only`,
      content: i18n`You can choose to remove the section and its content or only the section`,
    };
  }
};

interface LayoutDisplayTableBlockLineProps {
  conceptDefinitionId: string,
  blockId: string,
  index: number,
  parameterDefinitions: SingleParameterDefinition[],
  newlyCreatedId: MutableRefObject<NewLineFocusRefContent>,
  setNewlyCreatedId: (id: string) => void,
  flattenedTree: DisplayItem[],
  sortedBlockFields: (blockId: string) => StoreObject[],
  addNewFieldToBlock: (blockId: string, rank?: string) => string,
  computeChildBlockList: (block?: StoreObject) => DecoratedList<unknown>,
}

const LayoutDisplayTableBlockLine: FunctionComponent<LayoutDisplayTableBlockLineProps> = ({
  conceptDefinitionId,
  blockId,
  index,
  parameterDefinitions,
  newlyCreatedId,
  setNewlyCreatedId,
  flattenedTree,
  sortedBlockFields,
  addNewFieldToBlock,
  computeChildBlockList,
}) => {
  const theme = useTheme();

  const store = useStore();
  const classes = useStyles();
  const highlight = useHighlightNotify();
  const scrollOnMountRef = useScrollOnMountRef(true);

  const [layoutCollapseConfiguration, setLayoutCollapseConfiguration] = useSessionStorageState<LayoutCollapseConfiguration | undefined>(`${SessionStorageKeys.layoutCollapseConfig}_${conceptDefinitionId}`, undefined);

  const block = store.getObject<BlockStoreObject>(blockId);

  const attributeCorrectParent = (currentBlockId: string, previousElement: { blockId: string }) => {
    const currentBlock = store.getObjectOrNull(currentBlockId);
    let previousElementInstance = store.getObjectOrNull(previousElement.blockId);
    const findParent = ({ blockId: id }: { blockId: string }) => previousElementInstance?.[Block_Parent] === id;
    const currentBlockType = currentBlock?.[Block_Type];
    let previousElementBlockType = previousElementInstance?.[Block_Type];
    while ((currentBlockType === BlockType_Section && previousElementBlockType !== BlockType_Tab)
    || (currentBlockType === BlockType_Column && (previousElementBlockType !== BlockType_Tab && previousElementBlockType !== BlockType_Section))) {
      const parentIndex = flattenedTree.findIndex(findParent);
      previousElementInstance = store.getObjectOrNull(flattenedTree[parentIndex].blockId);
      previousElementBlockType = previousElementInstance?.[Block_Type];
    }
    return previousElementInstance?.id;
  };

  const deleteBlockAndMoveFieldHigher = (currentBlockId: string) => {
    // Re generate the rank for all the item to simplify the reordering
    const generateItemRank = ranker.createNextRankGenerator();
    flattenedTree.forEach((elem) => {
      const rank = generateItemRank();
      if (isFieldDisplayItem(elem)) {
        store.updateObject(elem.fieldBlockDisplayId, {
          [FieldBlockDisplay_Rank]: rank,
        });
      } else {
        store.updateObject(elem.blockId, {
          [Block_Rank]: rank,
        });
      }
    });

    const tabIndex = flattenedTree.findIndex(({ blockId: id }) => id === currentBlockId);
    const previousElement = flattenedTree[tabIndex - 1];

    const currentBlock = store.getObject(currentBlockId);

    // Block columns
    currentBlock.navigateBack(BlockParent_Block)
      .sort(compareProperty(Block_Rank, compareRank))
      .forEach((column) => {
        store.updateObject(column.id, {
          [Block_Parent]: attributeCorrectParent(column.id, previousElement),
        });
      });

    // Block fields
    currentBlock.navigateBack(Block_FieldDisplays)
      .sort(compareProperty(FieldBlockDisplay_Rank, compareRank))
      .forEach((blockField) => {
        store.updateObject(blockField.id, {
          [FieldBlockDisplay_Block]: previousElement.blockId,
        });
      });

    store.deleteObject(currentBlockId);
    newlyCreatedId.current.reset();
  };

  const isFirstTab = (currentBlock: StoreObject) => flattenedTree[0]?.blockId === currentBlock.id;
  const childrenCount = getChildrenCount(store, block, store.getObject(conceptDefinitionId));

  const showAlternateModal = (currentBlockId: string) => {
    const currentBlock = store.getObject(currentBlockId);
    const hasSection = computeChildBlockList(currentBlock).length > 0;
    const hasBlockFields = sortedBlockFields(currentBlockId).length > 0;
    return !isFirstTab(currentBlock) && (hasSection || hasBlockFields);
  };

  const hasDependencies = (currentBlockId: string) => {
    const currentBlock = store.getObject(currentBlockId);
    const hasSection = computeChildBlockList(currentBlock).length > 0;
    const hasBlockFields = sortedBlockFields(currentBlockId).length > 0;
    return isFirstTab(currentBlock) && (hasSection || hasBlockFields);
  };

  const [doDelete, deleteModal] = useDeleteModal<string>({
    doDelete: (currentBlockId, alternate) => {
      if (alternate) {
        deleteBlockAndMoveFieldHigher(currentBlockId);
      } else {
        getDisplayItemHandler(store, currentBlockId, flattenedTree).deleteBlockAndContent();
      }
    },
    shouldConfirm: (id) => hasDependencies(id) || showAlternateModal(id),
    getModalProps: (id) => {
      const alternateModal = showAlternateModal(id);
      const { title, confirmLabel, alternateConfirmLabel, content } = getDeleteModalLabels(store.getObject<BlockStoreObject>(id));

      return {
        title,
        confirmLabel,
        alternateConfirmLabel: alternateModal ? alternateConfirmLabel : undefined,
        content: alternateModal ? (<Typo>{content}</Typo>) : null,
      };
    },
  });

  let textColor;
  if (block[Block_Type] === BlockType_Tab) {
    textColor = theme.color.text.brand;
  } else if (block[Block_Type] === BlockType_Column) {
    textColor = theme.color.text.disabled;
  }

  const viewBlockFilters = block[Block_ViewDisplayCondition];
  const editableBlockFilters = block[Block_EditDisplayCondition];
  const blockFiltersDefinition: FilterDefinition = {
    updateFilters: (filters: Filters[]) => {
      store.updateObject(block.id, {
        [Block_ViewDisplayCondition]: filters[0],
      });
      store.updateObject(block.id, {
        [Block_EditDisplayCondition]: filters[1],
      });
    },
    definition: [
      {
        filter: viewBlockFilters,
        title: i18n`Display`,
        subtitle: i18n`Users can see this section...`,
      },
      {
        filter: editableBlockFilters,
        title: i18n`Edit`,
        subtitle: i18n`Users can edit this section...`,
      },
    ],
  };

  const getCollapseButtonVariant = () => {
    switch (block[Block_Type]) {
      case BlockType_Tab:
        return IconOnlyButtonVariants.primary;
      case BlockType_Section:
        return IconOnlyButtonVariants.secondary;
      default:
        return IconOnlyButtonVariants.tertiary;
    }
  };

  return (
    <>
      <TableLine noTabIndex>
        <TableCell valign={TableCellVAlign.top} colSpan={2}>
          <div className={classes.blockNameContainer} ref={newlyCreatedId.current.id === blockId ? scrollOnMountRef : undefined}>
            <IconOnlyButton
              tooltip={layoutCollapseConfiguration && layoutCollapseConfiguration[blockId] ? i18n`Expand` : i18n`Collapse`}
              iconName={layoutCollapseConfiguration && layoutCollapseConfiguration[blockId] ? IconName.keyboard_arrow_right : IconName.keyboard_arrow_down}
              sizeVariant={SizeVariant.small}
              variant={getCollapseButtonVariant()}
              onClick={() => {
                newlyCreatedId.current.reset();
                if (layoutCollapseConfiguration) {
                  layoutCollapseConfiguration[blockId] = !layoutCollapseConfiguration[blockId];
                  setLayoutCollapseConfiguration(layoutCollapseConfiguration);
                } else {
                  const layoutCollapseConf: LayoutCollapseConfiguration = {};
                  layoutCollapseConf[blockId] = true;
                  setLayoutCollapseConfiguration(layoutCollapseConf);
                }
              }}
            />
            <div className={classes.childrenCount}>
              <Typo color={theme.color.text.secondary}>
                {`(${childrenCount})`}
              </Typo>
            </div>
            <StoreTextInputField
              initialValue={block[Block_Type] === BlockType_Column ? i18n`Column divider` : block[Block_Name]}
              readOnly={block[Block_Type] === BlockType_Column}
              onSubmit={(value?: string | null) => store.updateObject(blockId, { [Block_Name]: value })}
              color={textColor}
              textVariant={TypoVariant.tabTitle}
              focusOnMount={newlyCreatedId.current.id === blockId}
              maxLine={1}
            />
          </div>
        </TableCell>
        <TableCell />
        <TableCell valign={TableCellVAlign.top}>
          <DisplayOptionsLayoutCell
            conceptDefinitionId={conceptDefinitionId}
            blockId={blockId}
            onOpen={() => highlight([conceptDefinitionId, Block, blockId])}
            onClose={() => highlight(undefined)}
          />
        </TableCell>
        <TableCell>
          <FilterComposite
            suggestedPaths={[{
              label: i18n`Current (${formatOrUndef(getConceptDefinitionNameOrEntity(store, conceptDefinitionId))})`,
              path: [
                { type: PathStepType.dimension, conceptDefinitionId },
                { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
              ],
            }]}
            parameterDefinitions={[
              { id: FILTER_PARAMETER_CURRENT, label: i18n`Current`, typeId: conceptDefinitionId, type: 'parameter' },
              getLoggedUserParameterDefinition(),
              ...parameterDefinitions,
            ]}
            filtersDefinition={blockFiltersDefinition}
            onOpen={() => highlight([conceptDefinitionId, Block, blockId])}
            onClose={() => highlight(undefined)}
          />
        </TableCell>
        <TableCell action>
          <IconOnlyButton
            onClick={() => {
              const destinationId = getDisplayItemHandler(store, blockId, flattenedTree).moveUp();
              if (destinationId) {
                expandBlock(store, layoutCollapseConfiguration, setLayoutCollapseConfiguration, destinationId);
              }
              newlyCreatedId.current.reset();
            }}
            disabled={!getDisplayItemHandler(store, blockId, flattenedTree).canMoveUp()}
            iconName={IconName.expand_less}
            tooltip={i18n`Move Up`}
            variant={IconOnlyButtonVariants.tertiary}
          />
        </TableCell>
        <TableCell action>
          <IconOnlyButton
            onClick={() => {
              const destinationId = getDisplayItemHandler(store, blockId, flattenedTree).moveDown();
              if (destinationId) {
                expandBlock(store, layoutCollapseConfiguration, setLayoutCollapseConfiguration, destinationId);
              }
              newlyCreatedId.current.reset();
            }}
            disabled={!getDisplayItemHandler(store, blockId, flattenedTree).canMoveDown()}
            iconName={IconName.expand_more}
            tooltip={i18n`Move Down`}
            variant={IconOnlyButtonVariants.tertiary}
          />
        </TableCell>
        <TableCell action>
          <OverflowMenu
            onOpen={() => highlight([conceptDefinitionId, Block, blockId])}
            onClose={() => highlight(undefined)}
            menuItems={[
              {
                key: 'add_tab_above',
                name: i18n`Add tab above`,
                icon: IconName.description,
                onClick: () => setNewlyCreatedId(getDisplayItemDefinitionHandler(store, BlockType_Tab, conceptDefinitionId, flattenedTree).createAbove(blockId)),
              },
              {
                key: 'add_tab_below',
                name: i18n`Add tab below`,
                icon: IconName.description,
                onClick: () => setNewlyCreatedId(getDisplayItemDefinitionHandler(store, BlockType_Tab, conceptDefinitionId, flattenedTree).createBelow(blockId)),
              },
              ...(block[Block_Type] !== BlockType_Tab || index > 0) ? [
                {
                  key: 'add_section_above',
                  name: i18n`Add section above`,
                  icon: IconName.subject,
                  onClick: () => {
                    const newId = getDisplayItemDefinitionHandler(store, BlockType_Section, conceptDefinitionId, flattenedTree).createAbove(blockId);
                    if (newId) {
                      expandBlock(store, layoutCollapseConfiguration, setLayoutCollapseConfiguration, newId);
                    }
                    setNewlyCreatedId(newId);
                  },
                },
              ] : [],
              {
                key: 'add_section_below',
                name: i18n`Add section below`,
                icon: IconName.subject,
                onClick: () => {
                  expandBlock(store, layoutCollapseConfiguration, setLayoutCollapseConfiguration, blockId);
                  setNewlyCreatedId(getDisplayItemDefinitionHandler(store, BlockType_Section, conceptDefinitionId, flattenedTree).createBelow(blockId));
                },
              },
              ...(block[Block_Type] !== BlockType_Tab || index > 0) ? [
                {
                  key: 'add_column_above',
                  name: i18n`Add column above`,
                  icon: IconName.subject,
                  onClick: () => {
                    const newId = getDisplayItemDefinitionHandler(store, BlockType_Column, conceptDefinitionId, flattenedTree).createAbove(blockId);
                    if (newId) {
                      expandBlock(store, layoutCollapseConfiguration, setLayoutCollapseConfiguration, newId);
                    }
                    setNewlyCreatedId(newId);
                  },
                },
              ] : [],
              {
                key: 'add_column_below',
                name: i18n`Add column below`,
                icon: IconName.subject,
                onClick: () => {
                  expandBlock(store, layoutCollapseConfiguration, setLayoutCollapseConfiguration, blockId);
                  setNewlyCreatedId(getDisplayItemDefinitionHandler(store, BlockType_Column, conceptDefinitionId, flattenedTree).createBelow(blockId));
                },
              },
              ...(block[Block_Type] !== BlockType_Tab || index > 0) ? [
                {
                  key: 'add_field_above',
                  name: i18n`Add field above`,
                  icon: IconName.tune,
                  onClick: () => {
                    const displayItem = flattenedTree.find((_, i, list) => blockId === list[i + 1].blockId && !list[i + 1].fieldBlockDisplayId);
                    if (!displayItem) {
                      return;
                    }
                    const rank = ranker.decorateList(sortedBlockFields(displayItem.blockId), (display) => display[FieldBlockDisplay_Rank] as string).insertAfterLastItemRank();
                    addNewFieldToBlock(displayItem.blockId, rank);
                    expandBlock(store, layoutCollapseConfiguration, setLayoutCollapseConfiguration, displayItem.blockId);
                  },
                },
              ] : [],
              {
                key: 'add_field_below',
                name: i18n`Add field below`,
                icon: IconName.tune,
                onClick: () => {
                  expandBlock(store, layoutCollapseConfiguration, setLayoutCollapseConfiguration, blockId);
                  const rank = ranker.decorateList(sortedBlockFields(blockId), (display) => display[FieldBlockDisplay_Rank] as string).insertBeforeFirstItemRank();
                  addNewFieldToBlock(blockId, rank);
                },
              },
              {
                key: 'delete',
                name: i18n`Delete`,
                icon: IconName.delete,
                onClick: () => doDelete(block.id),
                danger: true,
              },
            ]}
          />
        </TableCell>
      </TableLine>
      {deleteModal}
    </>
  );
};

export default LayoutDisplayTableBlockLine;
