import type { FunctionComponent } from 'react';
import type {
  Filters,
  MultipleParameterValue,
  SingleParameterDefinition,
  ParametersMapping,
  SingleParameterValue,
  MultipleParameterDefinition,
} from 'yooi-modules/modules/conceptModule';
import { spacingRem } from '../../../theme/spacingDefinition';
import makeStyles from '../../../utils/makeStyles';
import { SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import { UsageContextProvider, UsageVariant } from '../../../utils/useUsageContext';
import type { ParameterMultipleData } from './ParameterMultiple';
import ParameterMultiple from './ParameterMultiple';
import type { ParameterSingleData } from './ParameterSingle';
import ParameterSingle from './ParameterSingle';

export interface ParameterData {
  id: string,
  typeId?: string,
  filters?: Filters,
  label?: string,
  enableNotSetOption?: boolean,
  unsetLabel?: string,
}

interface CommonParameterInterface {
  onMoveLeft?: () => void,
  onMoveRight?: () => void,
  onDelete: () => void,
  readOnly?: boolean,
  focusOnMount?: boolean,
  parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[],
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
}

export interface SingleParameterInterface extends CommonParameterInterface {
  type: 'parameter',
  parameterData: ParameterSingleData,
  handleSubmit: (data: ParameterSingleData) => void,
}

export interface MultipleParameterInterface extends CommonParameterInterface {
  type: 'parameterList',
  parameterData: ParameterMultipleData,
  handleSubmit: (data: ParameterMultipleData) => void,
}

const useStyles = makeStyles({
  parametersLine: {
    display: 'flex',
    flexDirection: 'row',
  },
  parametersContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: spacingRem.l,
    rowGap: spacingRem.s,
    flexGrow: 1,
    flexWrap: 'wrap',
  },
}, 'parameterLine');

interface ParameterLineProps {
  parameterInterfaces: (SingleParameterInterface | MultipleParameterInterface)[],
}

const ParameterLine: FunctionComponent<ParameterLineProps> = ({ parameterInterfaces }) => {
  const classes = useStyles();

  if (parameterInterfaces.length === 0) {
    return null;
  } else {
    return (
      <SizeContextProvider sizeVariant={SizeVariant.small}>
        <UsageContextProvider usageVariant={UsageVariant.inForm}>
          <div className={classes.parametersLine}>
            <div className={classes.parametersContainer}>
              {
                parameterInterfaces
                  .map((props) => {
                    if (props.type === 'parameter') {
                      return (<ParameterSingle key={props.parameterData.id} {...props} />);
                    } else {
                      return (<ParameterMultiple key={props.parameterData.id} {...props} />);
                    }
                  })
              }
            </div>
          </div>
        </UsageContextProvider>
      </SizeContextProvider>
    );
  }
};

export default ParameterLine;
