import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import type { ConceptDefinitionInstanceAdministrationColumnRaw, ConceptDefinitionInstanceAdministrationColumnStoreObject } from 'yooi-modules/modules/conceptModule';
import { getConceptDefinitionValidFields } from 'yooi-modules/modules/conceptModule';
import {
  ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition,
  ConceptDefinitionInstanceAdministrationColumn_Field,
  ConceptDefinitionInstanceAdministrationColumn_Width,
} from 'yooi-modules/modules/conceptModule/ids';
import Button, { ButtonVariant } from '../../../../../components/atoms/Button';
import { IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import Typo from '../../../../../components/atoms/Typo';
import Overlay from '../../../../../components/molecules/Overlay';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import { tableHeaderCellSelectorsClasses } from '../../../../../components/molecules/TableHeaderCell';
import useStore from '../../../../../store/useStore';
import base from '../../../../../theme/base';
import { getSpacing, getSpacingAsNumber, Spacing, spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import { remToPx } from '../../../../../utils/sizeUtils';
import useHideOnDisappearRef from '../../../../../utils/useHideOnDisappearRef';
import { SizeContextProvider, SizeVariant } from '../../../../../utils/useSizeContext';
import useTheme from '../../../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../../../utils/useUsageContext';
import { getFieldHandler } from '../../../../_global/fields/FieldLibrary';
import { getFieldChip } from '../../../../_global/fieldUtils';
import { defaultOptionComparator } from '../../../../_global/modelTypeUtils';
import WidthPicker from '../../../../_global/views/common/series/WidthPicker';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainerHidden: {
    visibility: 'hidden',
  },
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.m,
    padding: getSpacing(Spacing.splus, -0.1 /* remove border */),
    backgroundColor: theme.color.background.neutral.default,
    boxShadow: base.shadowElevation.medium,
  },
  inputContainer: {
    display: 'grid',
    gridTemplateColumns: '4.2rem 1fr',
    columnGap: spacingRem.s,
    rowGap: spacingRem.s,
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  moveContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: spacingRem.xs,
  },
}), 'updateColumnAction');

interface UpdateColumnActionProps {
  conceptDefinitionInstanceAdministrationColumnId: string,
  moveLeft: (() => void) | undefined,
  moveRight: (() => void) | undefined,
  focusOnMount: boolean,
}

const UpdateColumnAction: FunctionComponent<UpdateColumnActionProps> = ({ conceptDefinitionInstanceAdministrationColumnId, moveLeft, moveRight, focusOnMount }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const column = store.getObject<ConceptDefinitionInstanceAdministrationColumnStoreObject>(conceptDefinitionInstanceAdministrationColumnId);

  const [showDropdown, setShowDropdown] = useState(focusOnMount);
  const { hideRef, monitorRef } = useHideOnDisappearRef(showDropdown);

  const placementRef = useRef<HTMLSpanElement | null>(null);

  return (
    <>
      <span
        ref={composeReactRefs<HTMLSpanElement>(placementRef, monitorRef)}
        className={classnames({
          [tableHeaderCellSelectorsClasses.visibilitySensor]: true,
          [classes.buttonContainer]: true,
          [classes.buttonContainerHidden]: !showDropdown,
        })}
      >
        <IconOnlyButton
          tooltip={i18n`Configure`}
          iconName={IconName.tune}
          variant={IconOnlyButtonVariants.secondary}
          onClick={() => setShowDropdown((current) => !current)}
        />
      </span>
      {
        showDropdown ? (
          <Overlay
            target={placementRef}
            sameWidth={false}
            onBackdropClick={() => setShowDropdown(false)}
            onEscapeKeyDown={() => setShowDropdown(false)}
            placement="bottom-end"
            // if direction is vertical, we add an horizontal offset -9 to align icons
            offset={[0, remToPx(getSpacingAsNumber(Spacing.xs))]}
            // Include a bottom margin to make sure the bottom shadow is visible when in scroll container
            containerMarginBottom="0.4rem"
          >
            <SizeContextProvider sizeVariant={SizeVariant.main}>
              <UsageContextProvider usageVariant={UsageVariant.inForm}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <span ref={hideRef} className={classes.container} onClick={(e) => e.stopPropagation()}>
                  <span className={classes.inputContainer}>
                    <SpacingLine>
                      <Typo color={theme.color.text.secondary}>{i18n`Field`}</Typo>
                    </SpacingLine>
                    <SearchAndSelect
                      selectedOption={
                        column[ConceptDefinitionInstanceAdministrationColumn_Field] && column.navigateOrNull(ConceptDefinitionInstanceAdministrationColumn_Field)
                          ? getFieldChip(
                            store,
                            column[ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition],
                            column[ConceptDefinitionInstanceAdministrationColumn_Field]
                          )
                          : undefined
                      }
                      computeOptions={() => (
                        getConceptDefinitionValidFields(store, column[ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition])
                          .filter((field) => getFieldHandler(store, field.id)?.getColumnDefinition && getFieldHandler(store, field.id)?.renderField)
                          .map((field) => getFieldChip(store, column[ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition], field.id))
                          .sort(defaultOptionComparator)
                      )}
                      onSelect={(value) => {
                        if (value) {
                          store.updateObject<ConceptDefinitionInstanceAdministrationColumnRaw>(
                            conceptDefinitionInstanceAdministrationColumnId,
                            { [ConceptDefinitionInstanceAdministrationColumn_Field]: value.id }
                          );
                        }
                      }}
                    />
                    <SpacingLine>
                      <Typo color={theme.color.text.secondary}>{i18n`Width`}</Typo>
                    </SpacingLine>
                    <WidthPicker
                      value={column[ConceptDefinitionInstanceAdministrationColumn_Width]}
                      onChange={(newWidth) => {
                        store.updateObject<ConceptDefinitionInstanceAdministrationColumnRaw>(
                          conceptDefinitionInstanceAdministrationColumnId,
                          { [ConceptDefinitionInstanceAdministrationColumn_Width]: newWidth ?? null }
                        );
                      }}
                    />
                  </span>
                  <span className={classes.actionContainer}>
                    <span className={classes.moveContainer}>
                      <IconOnlyButton
                        iconName={IconName.keyboard_arrow_left}
                        tooltip={i18n`Move left`}
                        variant={IconOnlyButtonVariants.secondary}
                        onClick={() => moveLeft?.()}
                        disabled={moveLeft === undefined}
                      />
                      <IconOnlyButton
                        iconName={IconName.keyboard_arrow_right}
                        tooltip={i18n`Move right`}
                        variant={IconOnlyButtonVariants.secondary}
                        onClick={() => moveRight?.()}
                        disabled={moveRight === undefined}
                      />
                    </span>
                    <Button
                      iconName={IconName.visibility_off}
                      title={i18n`Hide field`}
                      variant={ButtonVariant.secondary}
                      onClick={() => {
                        store.deleteObject(conceptDefinitionInstanceAdministrationColumnId);
                      }}
                    />
                  </span>
                </span>
              </UsageContextProvider>
            </SizeContextProvider>
          </Overlay>
        ) : null
      }
    </>
  );
};

export default UpdateColumnAction;
