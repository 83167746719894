import type { FunctionComponent } from 'react';
import { v4 as uuid } from 'uuid';
import type { PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import {
  FILTER_PARAMETER_CURRENT,
  getConceptDefinitionValidFields,
  getFieldUtilsHandler,
  InstanceReferenceType,
  isFieldStep,
  isMappingStep,
  isMultipleRelationalType,
  isRelationalType,
  isSingleRelationalType,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import { KinshipRelationField } from 'yooi-modules/modules/conceptModule/ids';
import type { SwimlaneViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { joinObjects } from 'yooi-utils';
import { ButtonVariant } from '../../../../components/atoms/Button';
import Checkbox from '../../../../components/atoms/Checkbox';
import { IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import SimpleInput from '../../../../components/inputs/strategy/SimpleInput';
import TextInputString from '../../../../components/inputs/TextInputString';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import BlockContent from '../../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../../components/templates/BlockTitle';
import DataTable from '../../../../components/templates/DataTable';
import VerticalBlock from '../../../../components/templates/VerticalBlock';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { SessionStorageKeys, useSessionStorageState } from '../../../../utils/useSessionStorage';
import { EditionOptionTypes } from '../../fields/FieldEditionOptionType';
import type { UpdateViewDefinition } from '../../fields/viewsField/ViewsFieldDefinitionOptions';
import { getFieldChip, getFieldLabel } from '../../fieldUtils';
import FilterComposite from '../../filter/filterComposite/FilterComposite';
import { getOption } from '../../modelTypeUtils';
import PathStepsInput from '../../path/PathStepsInput';
import { createPathConfigurationHandler, StepValidationState } from '../../pathConfigurationHandler';
import CardConfigurationOption from '../common/CardConfigurationOption';
import { getPathLabel, getSeriesLabel, getViewDimensionsAsParameterDefinitions } from '../common/series/viewWithSeriesFeatureUtils';
import WidthPicker from '../common/series/WidthPicker';
import ViewOptionBlock from '../common/ViewOptionBlock';
import { getLineErrorContent, getViewConceptConceptDefinitionId } from '../common/viewUtils';
import type { SwimlaneViewResolvedDefinition } from './swimlaneViewDefinitionHandler';

interface SwimlaneViewDefinitionOptionsProps {
  viewDimensions: ViewDimension[],
  viewDefinition: SwimlaneViewResolvedDefinition,
  updateViewDefinition: UpdateViewDefinition<SwimlaneViewStoredDefinition>,
  readOnly: boolean,
  parameterDefinitions: SingleParameterDefinition[],
}

const SwimlaneViewDefinitionOptions: FunctionComponent<SwimlaneViewDefinitionOptionsProps> = ({
  viewDimensions,
  viewDefinition,
  updateViewDefinition,
  readOnly,
  parameterDefinitions,
}) => {
  const store = useStore();
  const configurationKey = `${SessionStorageKeys.swimlaneConfig}_${viewDefinition.id}`;
  const [, , , resetSwimlaneConfiguration] = useSessionStorageState(configurationKey, undefined);

  const result = getViewConceptConceptDefinitionId(store, viewDimensions, parameterDefinitions, true);
  if (result.error !== undefined) {
    return (<BlockContent padded>{getLineErrorContent(result.error)}</BlockContent>);
  }
  const { conceptDefinitionId } = result;

  const seriesParameters = [...parameterDefinitions, ...getViewDimensionsAsParameterDefinitions(store, viewDimensions)];

  const suggestedBasePaths: { label: string, path: PathStep[] }[] = getViewDimensionsAsParameterDefinitions(store, viewDimensions)
    .map(({ id: parameterId, typeId, label }) => ({
      label,
      path: [
        { type: PathStepType.dimension, conceptDefinitionId: typeId },
        { type: PathStepType.mapping, mapping: { id: parameterId, type: InstanceReferenceType.parameter } },
      ],
    }));

  let targetType: string | undefined;
  if (viewDefinition.columnBy.fieldId && store.getObjectOrNull(viewDefinition.columnBy.fieldId)) {
    targetType = getFieldUtilsHandler(store, viewDefinition.columnBy.fieldId).getTargetType?.()?.id;
  }

  const groupByPathHandler = createPathConfigurationHandler(
    store,
    seriesParameters,
    [
      ({ pathStep, isNPath, path }) => {
        if (isFieldStep(pathStep)) {
          const fieldDefinitionId = store.getObjectOrNull(pathStep.fieldId)?.[Instance_Of] as string | undefined;
          if (!fieldDefinitionId) {
            return [{ state: StepValidationState.invalid, reasonMessage: i18n`Input end with an unknown field.` }];
          } else if (isRelationalType(fieldDefinitionId)) {
            return [{ state: StepValidationState.valid }];
          } else {
            return [{ state: StepValidationState.invalid, reasonMessage: i18n`Input end with an unauthorized field.` }];
          }
        } else if (isNPath) {
          return [{
            state: path.length === 1 ? StepValidationState.partiallyValid : StepValidationState.invalid,
            reasonMessage: i18n`Input should be unique, use a mapping in your path.`,
          }];
        } else if (isMappingStep(pathStep) && path.length === 2) {
          return [{ state: StepValidationState.valid }];
        } else {
          return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`Input end with an unauthorized element.` }];
        }
      },
    ]
  );

  const colorOptions: { id: 'column' | 'group', label: string }[] = [{ id: 'column', label: i18n`Columns background` }, { id: 'group', label: i18n`Groups background` }];

  const dependenciesPathHandler = createPathConfigurationHandler(
    store,
    seriesParameters,
    [
      ({ pathStep, isNPath, path }) => {
        if (isFieldStep(pathStep)) {
          const fieldDefinitionId = store.getObjectOrNull(pathStep.fieldId)?.[Instance_Of] as string | undefined;
          if (!fieldDefinitionId) {
            return [{ state: StepValidationState.invalid, reasonMessage: i18n`Input end with an unknown field.` }];
          } else if (isMultipleRelationalType(fieldDefinitionId)) {
            return [{ state: StepValidationState.valid }];
          } else {
            return [{ state: StepValidationState.invalid, reasonMessage: i18n`Input end with an unauthorized field.` }];
          }
        } else if (isNPath) {
          return [{
            state: path.length === 1 ? StepValidationState.partiallyValid : StepValidationState.invalid,
            reasonMessage: i18n`Input should be unique, use a mapping in your path.`,
          }];
        } else if (isMappingStep(pathStep) && path.length === 2) {
          return [{ state: StepValidationState.valid }];
        } else {
          return [{ state: StepValidationState.partiallyValid, reasonMessage: i18n`Input end with an unauthorized element.` }];
        }
      },
    ]
  );

  const options = [{ id: 'card', label: i18n`As cards` }, { id: 'chip', label: i18n`As chips` }];
  return (
    <>
      <ViewOptionBlock
        option={{
          key: 'readOnly',
          title: i18n`Read only`,
          type: EditionOptionTypes.checkbox,
          padded: true,
          props: {
            disabled: readOnly,
            checked: viewDefinition.readOnly,
            onChange: (value) => {
              updateViewDefinition((oldViewDefinition) => joinObjects(oldViewDefinition, { readOnly: value }));
            },
          },
        }}
      />
      <VerticalBlock asBlockContent withSeparation>
        <BlockTitle title={i18n`Display`} variant={BlockTitleVariant.secondary} />
        <ViewOptionBlock
          option={{
            key: 'columnBy',
            title: i18n`Column by`,
            type: EditionOptionTypes.custom,
            props: {
              render: () => (
                <SpacingLine>
                  <SearchAndSelect
                    selectedOption={viewDefinition.columnBy.fieldId ? getOption(store, viewDefinition.columnBy.fieldId, seriesParameters) : undefined}
                    computeOptions={() => getConceptDefinitionValidFields(store, conceptDefinitionId)
                      .filter((field) => isSingleRelationalType(field[Instance_Of]) && field[Instance_Of] !== KinshipRelationField)
                      .map((field) => getFieldChip(store, conceptDefinitionId, field.id))}
                    placeholder={i18n`Select a field`}
                    readOnly={viewDefinition.readOnly}
                    onSelect={(option) => {
                      if (option) {
                        updateViewDefinition((oldViewDefinition) => (
                          joinObjects(
                            oldViewDefinition,
                            { columnBy: { fieldId: option.id, filters: undefined, withEmpty: oldViewDefinition.columnBy.withEmpty, size: oldViewDefinition.columnBy.size } }
                          )
                        ));
                      }
                    }}
                    clearable={false}
                  />
                  {targetType && (
                    <FilterComposite
                      filtersDefinition={{
                        updateFilters: (filters) => {
                          resetSwimlaneConfiguration();
                          updateViewDefinition((oldViewDefinition) => joinObjects(
                            oldViewDefinition,
                            { columnBy: joinObjects(oldViewDefinition.columnBy, { filters: filters[0] }) }
                          ));
                        },
                        definition: [{ filter: viewDefinition.columnBy.filters, subtitle: i18n`Display columns` }],
                      }}
                      parameterDefinitions={[...seriesParameters, { id: FILTER_PARAMETER_CURRENT, type: 'parameter', label: i18n`Current`, typeId: targetType }]}
                      rootPath={{
                        label: i18n`Current`,
                        path: [
                          { type: PathStepType.dimension, conceptDefinitionId: targetType },
                          { type: PathStepType.mapping, mapping: { type: InstanceReferenceType.parameter, id: FILTER_PARAMETER_CURRENT } },
                        ],
                      }}
                      readOnly={viewDefinition.readOnly}
                    />
                  )}
                </SpacingLine>
              ),
            },
          }}
        />
        {viewDefinition.columnBy.fieldId && (
          <ViewOptionBlock
            option={{
              key: 'withEmptyColumn',
              title: i18n`Display "No ${getFieldLabel(store, store.getObject(viewDefinition.columnBy.fieldId))}" column`,
              type: EditionOptionTypes.checkbox,
              padded: true,
              props: {
                disabled: readOnly,
                checked: viewDefinition.columnBy.withEmpty,
                onChange: (withEmpty) => {
                  resetSwimlaneConfiguration();
                  updateViewDefinition((oldViewDefinition) => (joinObjects(
                    oldViewDefinition,
                    {
                      columnBy: joinObjects(oldViewDefinition.columnBy, {
                        withEmpty,
                      }),
                    }
                  )));
                },
              },
            }}
          />
        )}
        <ViewOptionBlock
          option={{
            key: 'columnSize',
            title: i18n`Column size`,
            type: EditionOptionTypes.custom,
            props: {
              render: () => (
                <SpacingLine>
                  <SearchAndSelect
                    selectedOption={viewDefinition.columnBy.size.type === 'rem' || viewDefinition.columnBy.size.type === 'percent' ? {
                      id: 'custom',
                      label: i18n`Custom`,
                    } : { id: 'auto', label: i18n`Auto` }}
                    computeOptions={() => [{ id: 'auto', label: i18n`Auto` }, { id: 'custom', label: i18n`Custom` }]}
                    onSelect={(option) => {
                      if (option?.id === 'auto') {
                        updateViewDefinition((oldViewDefinition) => (joinObjects(
                          oldViewDefinition,
                          {
                            columnBy: joinObjects(
                              oldViewDefinition.columnBy,
                              {
                                size: { type: 'auto', value: oldViewDefinition.columnBy.size.value },
                              }
                            ) as SwimlaneViewStoredDefinition['columnBy'],
                          }
                        )));
                      } else {
                        updateViewDefinition((oldViewDefinition) => (joinObjects(
                          oldViewDefinition,
                          {
                            columnBy: joinObjects(
                              oldViewDefinition.columnBy,
                              {
                                size: { type: oldViewDefinition.columnBy.size.type === 'rem' ? 'rem' : 'percent', value: oldViewDefinition.columnBy.size.value },
                              }
                            ) as SwimlaneViewStoredDefinition['columnBy'],
                          }
                        )));
                      }
                    }}
                  />
                  {viewDefinition.columnBy.size.type !== 'auto' && (
                    <WidthPicker
                      value={viewDefinition.columnBy.size.value ? { type: viewDefinition.columnBy.size.type, value: viewDefinition.columnBy.size.value } : undefined}
                      onChange={(newWidth) => {
                        updateViewDefinition((oldViewDefinition) => (joinObjects(
                          oldViewDefinition,
                          {
                            columnBy: joinObjects(
                              oldViewDefinition.columnBy,
                              {
                                size: { type: newWidth?.type ?? 'percent', value: newWidth?.value },
                              }
                            ) as SwimlaneViewStoredDefinition['columnBy'],
                          }
                        )));
                      }}
                    />
                  )}
                </SpacingLine>
              ),
            },
          }}
        />
        <ViewOptionBlock
          option={{
            key: 'groupBy',
            title: i18n`Group by`,
            type: EditionOptionTypes.custom,
            isVertical: true,
            fullWidth: true,
            skipBlockContent: true,
            props: {
              render: () => (
                <DataTable
                  list={viewDefinition.groupBy.map((g) => ({ item: g, key: g.id, type: 'item', color: undefined }))}
                  newItemIcon={IconName.add}
                  newItemTitle={i18n`Add`}
                  newItemButtonVariant={ButtonVariant.tertiary}
                  onNewItem={() => {
                    updateViewDefinition((oldViewDefinition) => (joinObjects(
                      oldViewDefinition,
                      {
                        groupBy: [
                          ...(oldViewDefinition.groupBy ?? []),
                          { id: uuid(), label: undefined, path: [], isDefault: !oldViewDefinition.groupBy || oldViewDefinition.groupBy.length === 0 },
                        ],
                      }
                    )));
                  }}
                  columnsDefinition={[{
                    propertyId: 'label',
                    name: i18n`Label`,
                    cellRender: ({ id, label, path }, __, index) => (
                      <SimpleInput<string | undefined>
                        initialValue={getPathLabel(store, label, index, path, viewDimensions, seriesParameters.map((param) => param.id), i18n`Group By`)}
                        onSubmit={(newValue) => updateViewDefinition((oldViewDefinition) => (joinObjects(
                          oldViewDefinition,
                          {
                            groupBy: oldViewDefinition.groupBy.map((item) => (item.id === id ? joinObjects(item, {
                              label: newValue,
                            }) : item)),
                          }
                        )))}
                      >
                        {(props) => (
                          <TextInputString
                            value={props.value}
                            onSubmit={props.onSubmit}
                            onChange={props.onChange}
                            onCancel={props.onCancel}
                            readOnly={readOnly}
                            warning={path.length !== 3 ? i18n`Path is too complex, drag & drop will not be available` : undefined}
                          />
                        )}
                      </SimpleInput>
                    ),
                  }, {
                    propertyId: 'path',
                    name: i18n`Path`,
                    cellRender: ({ id, path }) => (
                      <PathStepsInput
                        readOnly={readOnly}
                        onSubmit={(newPath) => updateViewDefinition((oldViewDefinition) => (joinObjects(
                          oldViewDefinition,
                          {
                            groupBy: oldViewDefinition.groupBy.map((item) => (item.id === id ? joinObjects(item, {
                              path: newPath,
                            }) : item)),
                          }
                        )))}
                        initialPath={path}
                        parameterDefinitions={seriesParameters}
                        suggestedBasePaths={suggestedBasePaths}
                        valuePathHandler={groupByPathHandler}
                      />
                    ),
                  }, {
                    propertyId: 'isDefault',
                    name: i18n`Is default`,
                    cellRender: ({ id, isDefault }) => (
                      <Checkbox
                        checked={isDefault}
                        onChange={(newValue) => {
                          updateViewDefinition((oldViewDefinition) => (joinObjects(
                            oldViewDefinition,
                            {
                              groupBy: oldViewDefinition.groupBy.map((groupBy) => {
                                if (newValue) {
                                  return joinObjects(groupBy, {
                                    isDefault: id === groupBy.id,
                                  });
                                } else {
                                  return id === groupBy.id ? joinObjects(groupBy, {
                                    isDefault: newValue,
                                  }) : groupBy;
                                }
                              }),
                            }
                          )));
                        }}
                      />
                    ),
                  }, {
                    propertyId: 'delete',
                    action: true,
                    cellRender: (_, __, index) => (
                      <IconOnlyButton
                        onClick={() => {
                          updateViewDefinition((oldViewDefinition) => (joinObjects(
                            oldViewDefinition,
                            {
                              groupBy: oldViewDefinition.groupBy.filter((___, i) => i !== index),
                            }
                          )));
                        }}
                        tooltip={i18n`Delete`}
                        iconName={IconName.delete}
                        variant={IconOnlyButtonVariants.danger}
                      />
                    ),
                  }]}
                />
              ),
            },
          }}
        />
        <ViewOptionBlock
          option={{
            key: 'color',
            type: EditionOptionTypes.select,
            title: i18n`Apply color on`,
            props: {
              clearable: false,
              readOnly,
              selectedOption: colorOptions.find(({ id }) => id === viewDefinition.color),
              computeOptions: () => colorOptions,
              onChange: (option) => {
                if (option) {
                  updateViewDefinition((oldViewDefinition) => (joinObjects(
                    oldViewDefinition,
                    {
                      color: option.id as 'column' | 'group',
                    }
                  )));
                }
              },
            },
          }}
        />
        <ViewOptionBlock
          option={{
            key: 'dependencies',
            type: EditionOptionTypes.path,
            title: i18n`Dependencies`,
            props: {
              readOnly,
              parameterDefinitions: seriesParameters,
              initialPath: viewDefinition.dependencies,
              onSubmit: (newPath) => {
                updateViewDefinition((oldViewDefinition) => (joinObjects(
                  oldViewDefinition,
                  {
                    dependencies: newPath,
                  }
                )));
              },
              suggestedBasePaths,
              valuePathHandler: dependenciesPathHandler,
              placeholder: i18n`Add a path`,
            },
          }}
        />
      </VerticalBlock>
      <BlockTitle title={i18n`Instance`} variant={BlockTitleVariant.secondary} />
      <ViewOptionBlock
        option={{
          key: 'intanceDisplay',
          type: EditionOptionTypes.select,
          title: i18n`Display instances`,
          props: {
            readOnly,
            selectedOption: options.find(({ id }) => id === viewDefinition.instanceDisplay),
            computeOptions: () => options,
            onChange: (option) => {
              if (option) {
                updateViewDefinition((oldViewDefinition) => (joinObjects(
                  oldViewDefinition,
                  {
                    instanceDisplay: option.id as 'card' | 'chip',
                  }
                )));
              }
            },
            required: true,
          },
        }}
      />
      <CardConfigurationOption
        value={
          {
            color: viewDefinition.cardColor,
            icon: viewDefinition.cardIcon,
            boolean: viewDefinition.cardBoolean,
            body: viewDefinition.cardBody,
            header: viewDefinition.cardHeader,
          }
        }
        onIconUpdate={(newIcon) => {
          updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { cardIcon: newIcon })));
        }}
        onBooleanUpdate={(newBoolean) => {
          updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { cardBoolean: newBoolean })));
        }}
        onColorUpdate={(newColor) => {
          updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { cardColor: newColor })));
        }}
        onHeaderUpdate={(newHeader) => {
          updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { cardHeader: newHeader })));
        }}
        onBodyUpdate={(newBody) => {
          updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { cardBody: newBody })));
        }}
        getBodySeriesLabel={(label, index, path) => getSeriesLabel(store, label, index, path, viewDimensions, parameterDefinitions.map((parameter) => parameter.id))}
        suggestedBasePaths={suggestedBasePaths}
        parameterDefinitions={seriesParameters}
        readOnly={readOnly}
        sectionTitle={viewDefinition.instanceDisplay === 'card' ? i18n`Cards` : i18n`Tooltips`}
        sectionSubtitle={viewDefinition.instanceDisplay === 'card' ? undefined : i18n`The tooltip bellow will be displayed while hovering for each instance`}
        headerSectionTitle={i18n`Header`}
        headerSectionSubtitle={viewDefinition.instanceDisplay === 'card' ? i18n`The field below are always visible` : undefined}
        bodySectionTitle={i18n`Body`}
        bodySectionSubtitle={viewDefinition.instanceDisplay === 'card' ? i18n`The field below will be displayed only when the card is open` : undefined}
      />
    </>
  );
};

export default SwimlaneViewDefinitionOptions;
