import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { FILTER_PARAMETER_CURRENT, getFieldDimensionOfModelType, getFieldUtilsHandler, getFilterFunction, isConceptValid } from 'yooi-modules/modules/conceptModule';
import { IdField, NumberField, TextField } from 'yooi-modules/modules/conceptModule/ids';
import type { RadarChartFieldConfiguration } from 'yooi-modules/modules/dashboardModule';
import { RadarChartField_Configuration, RadarChartField_ModelType } from 'yooi-modules/modules/dashboardModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import type { RadarChartData } from '../../../../components/charts/RadarChart/RadarChart';
import type { FrontObjectStore } from '../../../../store/useStore';
import { getInstanceMaxMinValues, TickResolutionStatus } from '../../fieldUtils';
import { ChartColorPathAcceptedFields, filterPath, getLastConcept, getSeriesLabel } from '../_global/widgetSeriesUtils';

export const ChartLabelPathAcceptedFields = [TextField, NumberField, IdField];
export const ChartValuePathAcceptedFields = [NumberField];

export const computeRadarChartFieldSeries = (store: FrontObjectStore, fieldId: string, parametersMapping: ParametersMapping): RadarChartData | undefined => {
  const field = store.getObject(fieldId);
  const conceptDefinitionId = field[RadarChartField_ModelType] as string;

  if (!conceptDefinitionId) {
    return undefined;
  }

  const conceptDefinition = store.getObjectOrNull(conceptDefinitionId);
  if (!conceptDefinition) {
    return undefined;
  }

  const configuration = field[RadarChartField_Configuration] as RadarChartFieldConfiguration | undefined;
  let instances = conceptDefinition
    .navigateBack(Class_Instances)
    .filter(({ id }) => isConceptValid(store, id));

  const filterFunction = getFilterFunction(store, configuration?.filters);
  if (filterFunction) {
    instances = instances.filter((instance) => filterFunction(joinObjects(parametersMapping, { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: instance.id } })));
  }
  const { error: colorPathError, path: colorPath } = filterPath(store, configuration?.colorPath, ChartColorPathAcceptedFields);
  const { error: labelPathError, path: labelPath } = filterPath(store, configuration?.labelPath, ChartLabelPathAcceptedFields);
  const instanceData: RadarChartData['instanceData'] = instances.reduce((obj, { id }) => {
    const concept = store.getObject(id);
    const lastColorConcept = !colorPathError ? getLastConcept(store, concept, colorPath) : undefined;
    const lastLabelConcept = !labelPathError ? getLastConcept(store, concept, labelPath) : undefined;
    const colorDimension = lastColorConcept ? getFieldDimensionOfModelType(store, colorPath[colorPath.length - 1], lastColorConcept[Instance_Of] as string) : undefined;
    const labelDimension = lastLabelConcept ? getFieldDimensionOfModelType(store, labelPath[labelPath.length - 1], lastLabelConcept[Instance_Of] as string) : undefined;
    return (joinObjects(
      obj,
      {
        [id]: {
          data: [],
          label: lastLabelConcept ? getFieldUtilsHandler(store, labelPath[labelPath.length - 1])
            .getValueAsText?.(labelDimension ? { [labelDimension]: lastLabelConcept.id } : {}) : '',
          color: lastColorConcept ? getFieldUtilsHandler(store, colorPath[colorPath.length - 1])
            .getValueResolution(colorDimension ? { [colorDimension]: lastColorConcept.id } : {}).value : undefined,
        },
      }
    ));
  }, {});
  const fieldData = configuration?.series
    ?.filter((series) => {
      const { error: valuePathError } = filterPath(store, series.valuePath, ChartValuePathAcceptedFields);
      return !valuePathError;
    })
    .flatMap((series, index) => {
      const { path: valuePath } = filterPath(store, series.valuePath, ChartValuePathAcceptedFields);

      const seriesLabel = getSeriesLabel(store, series.label, valuePath, index);
      const dataField = valuePath[valuePath.length - 1] ? store.getObject(valuePath[valuePath.length - 1]) : undefined;
      if (!dataField) {
        return [];
      }

      let data: { conceptId: string, value?: number, y: number | null, max: number | undefined, min: number | undefined }[] = instances
        .map((concept) => {
          const { min, max } = getInstanceMaxMinValues(store, dataField.id, concept.id, {});
          const lastValueConcept = getLastConcept(store, concept, valuePath);
          const dimension = lastValueConcept ? getFieldDimensionOfModelType(store, dataField.id, lastValueConcept[Instance_Of] as string) : undefined;
          const valueResolution = lastValueConcept && dataField
            ? getFieldUtilsHandler(store, dataField.id).getValueResolution(dimension ? { [dimension]: lastValueConcept.id } : {})
            : undefined;
          const value = valueResolution?.getDisplayValue?.() as number | undefined;
          return {
            conceptId: concept.id,
            value: value == null || (max?.status === TickResolutionStatus.Resolved && value > max.value) || (min?.status === TickResolutionStatus.Resolved && value < min.value)
              ? undefined : value,
            y: null,
            min: min?.status === TickResolutionStatus.Resolved ? min.value : undefined,
            max: max?.status === TickResolutionStatus.Resolved ? max.value : undefined,
          };
        });
      const values: number[] = data.map((d) => d.value).filter(filterNullOrUndefined);
      let seriesMin = Math.min(...values);
      if (seriesMin > 0) {
        seriesMin = 0;
      }
      let seriesMax = Math.max(...values);
      if (seriesMax < 0) {
        seriesMax = 0;
      }
      const maxs: number[] = data.map((d) => d.max).filter(filterNullOrUndefined);
      const mins: number[] = data.map((d) => d.min).filter(filterNullOrUndefined);
      const yMin = mins.length ? Math.min(...mins) : seriesMin;
      let yMax = maxs.length ? Math.max(...maxs) : seriesMax;
      if (yMin === 0 && yMax === 0) {
        yMax = 1;
      }
      data = data.map((d) => {
        if (d.value != null) {
          return (joinObjects(d, { y: (d.value - yMin) / (yMax - yMin) }));
        } else {
          return ({ ...d });
        }
      });
      data.forEach(({ conceptId, y, value }) => {
        instanceData[conceptId].data.push({ x: series.id, y, value });
      });
      return [{ key: series.id, label: seriesLabel, yMax, yMin }];
    }) || [];

  if (fieldData.some((bcs) => !bcs)) {
    return undefined;
  }

  return { fieldData, instanceData };
};
