import type { ObjectStoreReadOnly, ObjectStoreWithTimeseries } from 'yooi-store';
import { isInstanceOf } from '../../../typeModule';
import type { WorkflowFieldTransition_Filters } from '../../ids';
import {
  Concept_StakeholdersUsers,
  ConceptRole_ForCollaboration,
  Group,
  Group_Users,
  GroupMembershipDimension,
  User,
  Workflow_TargetedConceptDefinition,
  WorkflowEntry,
  WorkflowEntry_Role_Concept,
  WorkflowEntry_Role_Workflow,
  WorkflowField_Workflow,
  WorkflowFieldEntry,
  WorkflowFieldEntry_Filters,
  WorkflowFieldEntry_Role_Entry,
  WorkflowFieldEntry_Role_WorkflowField,
  WorkflowFieldTransition,
  WorkflowFieldTransition_Rights,
  WorkflowFieldTransition_Role_Transition,
  WorkflowFieldTransition_Role_WorkflowField,
} from '../../ids';
import type { Filters } from '../../moduleType';
import type { ParametersMapping } from '../../utils';
import { getFilterFunction, isConceptValid } from '../../utils';
import { associationFieldHandler } from '../associationField';
import { stakeholdersFieldHandler } from '../stakeholdersField';

export interface TransitionRights {
  roles?: string[],
  groups?: string[],
}

export interface TransitionConfiguration {
  [WorkflowFieldTransition_Rights]: TransitionRights | undefined,
  [WorkflowFieldTransition_Filters]: Filters | undefined,
}

export const isEntryValidForInstance = (store: ObjectStoreWithTimeseries, fieldId: string, value: string | undefined, parametersMapping: ParametersMapping): boolean => {
  if (!value) {
    return true;
  }
  const configuration = store.withAssociation(WorkflowFieldEntry)
    .withRole(WorkflowFieldEntry_Role_WorkflowField, fieldId)
    .withRole(WorkflowFieldEntry_Role_Entry, value)
    .getObjectOrNull();
  return getFilterFunction(store, configuration?.[WorkflowFieldEntry_Filters] as Filters | undefined)?.(parametersMapping) !== false;
};

export const isWorkflowFieldStorageValueValid = (
  objectStore: ObjectStoreReadOnly,
  fieldId: string,
  value: unknown
): boolean => {
  const workflowField = objectStore.getObjectOrNull(fieldId);
  const workflow = workflowField?.navigateOrNull(WorkflowField_Workflow);
  const targetTypeId = workflow?.[Workflow_TargetedConceptDefinition] as string | undefined;
  if (!workflow || !targetTypeId) {
    return false;
  }

  if (value === undefined) {
    return true;
  } else if (typeof value !== 'string') {
    return false;
  }

  const relatedEntry = objectStore.withAssociation(WorkflowEntry)
    .withRole(WorkflowEntry_Role_Workflow, workflow.id)
    .withRole(WorkflowEntry_Role_Concept, value)
    .getObjectOrNull();

  return isConceptValid(objectStore, value) && isInstanceOf(objectStore.getObject(value), targetTypeId) && Boolean(relatedEntry);
};

export const isTransitionValidForUser = (store: ObjectStoreWithTimeseries, fieldId: string, transitionId: string, userId: string, conceptId: string): boolean => {
  const { roles = [], groups = [] } = store.withAssociation(WorkflowFieldTransition)
    .withRole(WorkflowFieldTransition_Role_Transition, transitionId)
    .withRole(WorkflowFieldTransition_Role_WorkflowField, fieldId)
    .getObjectOrNull()?.[WorkflowFieldTransition_Rights] as TransitionRights ?? {};

  const validRoles = roles.filter((role) => isConceptValid(store, role));
  const validGroups = groups.filter((group) => isConceptValid(store, group));

  const assignations = stakeholdersFieldHandler(store, Concept_StakeholdersUsers).getValueResolution({ n1InstanceId: conceptId }).value.find(({ id }) => id === userId);
  const userRoleForConcept = [...assignations?.[Group] ?? [], ...assignations?.[User] ?? [], ...assignations?.[ConceptRole_ForCollaboration] ?? []];
  const hasRole = validRoles.some((roleId) => userRoleForConcept.includes(roleId));

  const isInGroup = validGroups.some((groupId) => {
    const fieldUtils = associationFieldHandler(store, Group_Users);
    const users = fieldUtils.getValueWithoutFormula({ [GroupMembershipDimension]: groupId });
    return users.some((user) => user.id === userId);
  });

  return (validRoles.length === 0 && validGroups?.length === 0) || hasRole || isInGroup;
};
