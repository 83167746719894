import classnames from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';
import { BeatLoader } from 'react-spinners';
import logofull from '../../assets/images/logofull.svg';
import base from '../../theme/base';
import makeStyles from '../../utils/makeStyles';
import { getPlatformLogo } from '../../utils/options';
import useTheme from '../../utils/useTheme';

const useStyles = makeStyles((theme) => ({
  mainContainerBackground: {
    background: theme.color.background.neutral.default,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },
  loadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '15vh',
  },
  beatContainer: {
    marginTop: '5rem',
  },
  imageYOOI: {
    width: '10rem',
  },
}), 'loading');

interface LoadingProps {
  withLogo?: boolean,
  withoutBackground?: boolean,
  renderProgress?: () => ReactElement,
}

const Loading: FunctionComponent<LoadingProps> = ({ withLogo = false, withoutBackground = false, renderProgress }) => {
  const theme = useTheme();
  const classes = useStyles();

  const brandingLogo = getPlatformLogo();
  const logoSrc = brandingLogo ? `/app/logo/${brandingLogo.revisionId}` : logofull;

  return (
    <div className={classnames({ [classes.mainContainer]: true, [classes.mainContainerBackground]: !withoutBackground })}>
      <div className={classes.loadContainer}>
        {withLogo ? (<img src={logoSrc} alt="logo" className={classnames({ [classes.imageYOOI]: brandingLogo === undefined })} />) : null}
        {/* Loading is used in FeatureContextProvider without underlying ThemeTemplate. */}
        {/* undefined theme must be handled here, fallback value is from standard theme : brand['100'] */}
        {renderProgress ? renderProgress() : (
          <div className={classes.beatContainer}>
            <BeatLoader size={10} color={theme?.color.background.primary.default ?? base.color.brand['100']} loading />
          </div>
        )}
      </div>
    </div>
  );
};

export default Loading;
