import type { AttachmentStoreObject, DimensionsMapping } from 'yooi-modules/modules/conceptModule';
import { buildDimensionalId } from 'yooi-modules/modules/conceptModule';
import { Attachment, Attachment_Role_FieldDimensions, Attachment_Role_FileName, Attachment_VarRoles_Dimensions } from 'yooi-modules/modules/conceptModule/ids';
import type { ObjectStoreAssociation } from 'yooi-store';
import type { FrontObjectStore } from '../../../../store/useStore';

export const getAttachmentList = (store: FrontObjectStore, dimensionsMapping: DimensionsMapping): ObjectStoreAssociation<AttachmentStoreObject>[] => {
  const dimensionalId = buildDimensionalId(dimensionsMapping);
  return store
    .withAssociation(Attachment)
    .withRole(Attachment_Role_FieldDimensions, dimensionalId[0])
    .withVarRoles(Attachment_VarRoles_Dimensions, dimensionalId.slice(1))
    .withExternalRole(Attachment_Role_FileName)
    .list<AttachmentStoreObject>();
};
