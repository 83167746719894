import { booleanType, filterNullOrUndefined, isArrayFormulaType, numberType } from 'yooi-utils';
import type { FormulaType } from 'yooi-utils/src/formula2/engine/formula';
import { newFunctionLibrary } from 'yooi-utils/src/formula2/engine/functionLibraryBuilder';
import { checkArgumentCount, checkArgumentType, checkFlattenType, checks } from 'yooi-utils/src/formula2/functionLibrary/utils';
import { anyType, arrayOf, textType } from 'yooi-utils/src/formula2/typeSystem';
import type { WrappedRichText } from '../../fields';

export const richTextType: FormulaType = {
  name: 'richText',
  equals: (type) => type === richTextType,
  isAssignableFrom: (type) => type === richTextType || type === textType || type === anyType,
};

const richTextMapper: { type: FormulaType, jsFunction: CallableFunction, transpile?: (transpiledArg: string) => string } = {
  type: textType,
  jsFunction: (arg: WrappedRichText | undefined) => arg?.toString(),
  transpile: (arg) => `(${arg})?.toString()`,
};

const richTextArrayMapper: { type: FormulaType, jsFunction: CallableFunction, transpile?: (transpiledArg: string) => string } = {
  type: arrayOf(textType),
  jsFunction: (arg: (WrappedRichText | undefined)[] | undefined) => arg?.map((a) => a?.toString()),
  transpile: (arg) => `(${arg})?.map((arg) => arg?.toString())`,
};

const getMapper = (type: FormulaType) => {
  if (isArrayFormulaType(type) && richTextType.isAssignableFrom(type.array.elementType)) {
    return richTextArrayMapper;
  } else if (richTextType.isAssignableFrom(type)) {
    return richTextMapper;
  } else {
    return { type };
  }
};

export const richTextFunctions = newFunctionLibrary()
  .addFunction(
    '=',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, [textType, richTextType], argTypes, 0),
          () => checkArgumentType(name, [textType, richTextType], argTypes, 1)
        ) ?? argTypes.map(getMapper)),
    })
  )
  .addFunction(
    'CLEAN',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, richTextType, argTypes, 0)
        ) ?? [richTextMapper]),
    })
  )
  .addFunction(
    ['CONCAT', 'CONCATENATE'],
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1),
          () => checkFlattenType(name, [textType, numberType, richTextType], argTypes)
        ) ?? argTypes.map(getMapper)),
    })
  )
  .addFunction(
    'ES',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkFlattenType(name, richTextType, argTypes)
        ) ?? argTypes.map(getMapper)),
    })
  )
  .addFunction(
    'IFNAS',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkFlattenType(name, richTextType, argTypes)
        ) ?? argTypes.map(getMapper)),
    })
  )
  .addFunction(
    'INDEXOF',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 3),
          () => checkArgumentType(name, [textType, richTextType], argTypes, 0),
          () => checkArgumentType(name, [textType, richTextType], argTypes, 1),
          () => (argTypes.length < 3 ? undefined : checkArgumentType(name, numberType, argTypes, 2))
        ) ?? [getMapper(argTypes[0]), getMapper(argTypes[1]), argTypes.length < 3 ? undefined : { type: argTypes[2] }].filter(filterNullOrUndefined)),
    })
  )
  .addFunction(
    'ISBLANK',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, richTextType, argTypes, 0)
        ) ?? [richTextMapper]),
    })
  )
  .addFunction(
    'LEFT',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 2),
          () => checkArgumentType(name, richTextType, argTypes, 0),
          () => (argTypes.length < 2 ? undefined : checkArgumentType(name, numberType, argTypes, 1))
        ) ?? (argTypes.length === 1 ? [richTextMapper] : [richTextMapper, { type: argTypes[1] }])),
    })
  )
  .addFunction(
    'LEN',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, richTextType, argTypes, 0)
        ) ?? [richTextMapper]),
    })
  )
  .addFunction(
    'LOWER',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, richTextType, argTypes, 0)
        ) ?? [richTextMapper]),
    })
  )
  .addFunction(
    'MID',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 3, 3),
          () => checkArgumentType(name, richTextType, argTypes, 0),
          () => checkArgumentType(name, numberType, argTypes, 1),
          () => checkArgumentType(name, numberType, argTypes, 2)
        ) ?? [getMapper(argTypes[0]), { type: argTypes[1] }, { type: argTypes[2] }]),
    })
  )
  .addFunction(
    'PROPER',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, richTextType, argTypes, 0)
        ) ?? [richTextMapper]),
    })
  )
  .addFunction(
    'REPLACE',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 4, 4),
          () => checkArgumentType(name, [textType, richTextType], argTypes, 0),
          () => checkArgumentType(name, numberType, argTypes, 1),
          () => checkArgumentType(name, numberType, argTypes, 2),
          () => checkArgumentType(name, [textType, richTextType], argTypes, 3)
        ) ?? [getMapper(argTypes[0]), { type: argTypes[1] }, { type: argTypes[2] }, getMapper(argTypes[3])]),
    })
  )
  .addFunction(
    'RIGHT',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 2),
          () => checkArgumentType(name, richTextType, argTypes, 0),
          () => (argTypes.length < 2 ? undefined : checkArgumentType(name, numberType, argTypes, 1))
        ) ?? (argTypes.length === 1 ? [richTextMapper] : [richTextMapper, { type: argTypes[1] }])),
    })
  )
  .addFunction(
    'TEXT',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 2, 2),
          () => checkArgumentType(name, numberType, argTypes, 0),
          () => checkArgumentType(name, richTextType, argTypes, 1)
        ) ?? [{ type: argTypes[0] }, richTextMapper]),
    })
  )
  .addFunction(
    'TEXTJOIN',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 3),
          () => checkArgumentType(name, [textType, richTextType], argTypes, 0),
          () => checkArgumentType(name, booleanType, argTypes, 1),
          () => checkFlattenType(name, [textType, richTextType], argTypes.slice(2))
        ) ?? [getMapper(argTypes[0]), { type: argTypes[1] }, ...argTypes.slice(2).map(getMapper)]),
    })
  )
  .addFunction(
    'TRIM',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, richTextType, argTypes, 0)
        ) ?? [richTextMapper]),
    })
  )
  .addFunction(
    'UPPER',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1, 1),
          () => checkArgumentType(name, richTextType, argTypes, 0)
        ) ?? [richTextMapper]),
    })
  )
  .build();
