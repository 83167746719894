import type {
  DimensionsMapping,
  NumberColorStepValueResolved,
  ParametersMapping,
} from 'yooi-modules/modules/conceptModule';
import {
  createValuePathResolver,
  dimensionsMappingToParametersMapping,
  getInstanceLabel,
  getPathLastFieldInformation,
  isMultiFieldResolution,
  isSingleValueResolution,
  isValidValuePathResolution,
  numberFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import { Concept, NumberField } from 'yooi-modules/modules/conceptModule/ids';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { SERIE_GROUP_BY_OPTION, ViewType } from 'yooi-modules/modules/dashboardModule';
import { UnsetDashboardParameterOption } from 'yooi-modules/modules/dashboardModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import type { StoreObject } from 'yooi-store';
import { joinObjects, newError } from 'yooi-utils';
import type { Labels } from '../../../../components/charts/ChartTypes';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { FieldResolutionError, formatErrorForUser } from '../../errorUtils';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { getDimensionCompositionFromSeries, getProjectionValueLabel, getSeriesLabel } from '../common/series/viewWithSeriesFeatureUtils';
import { isSeriesStacked } from '../common/stackedSeries/viewWithStackedSeriesFeatureUtils';
import { resolveChartColors } from '../common/viewUtils';
import { computeDimension, getDimensionCompositions } from '../data/dataResolution';
import type { ViewResolutionError } from '../viewResolutionUtils';
import { SeriesResolutionError } from '../viewResolutionUtils';
import type { StructuralBarChartViewResolvedDefinition } from './structuralBarChartViewHandler';

const MAX_COMPOSITION_VALUE = 50;

export interface StructuralBarChartViewResolution {
  type: ViewType.StructuralBarChart,
  data: BarValue[][][],
  minValue: NumberColorStepValueResolved,
  maxValue: NumberColorStepValueResolved,
  steps: NumberColorStepValueResolved[],
  yDomain: [number, number],
  labels: Labels[],
}

export interface BarValue {
  y: number,
  xLabel?: string,
  value: number,
  color: string,
  dimensionsMapping?: DimensionsMapping,
  serie: {
    label: string,
    fieldId: string,
  },
  key: string,
}

const getLabel = (objectStore: FrontObjectStore, instance: StoreObject): string => {
  if (instance.id === UnsetDashboardParameterOption) {
    return i18n`Not set`;
  } else if (isInstanceOf(instance, Concept)) {
    return getInstanceLabel(objectStore, objectStore.getObject(instance.id)) ?? '';
  } else {
    return '';
  }
};

interface BarValueError {
  error: string,
}

const isValidBarValue = (value: BarValue | BarValueError): value is BarValue => !(value as BarValueError).error;

export const resolveStructuralChartView = (
  store: FrontObjectStore,
  viewDimensions: ViewDimension[],
  viewDefinition: StructuralBarChartViewResolvedDefinition,
  parametersMapping: ParametersMapping,
  filterConfiguration?: FilterConfiguration
): StructuralBarChartViewResolution | ViewResolutionError => {
  const { series: inputSeries, seriesAxis, minValue: graphMinValue, maxValue: graphMaxValue, rangeValues: graphRangeValues, xAxis } = viewDefinition;

  const series = inputSeries.filter(({ path }) => getPathLastFieldInformation(path)?.fieldId !== undefined);
  if (series.length === 0) {
    return { type: 'error', error: i18n`Missing series` };
  }

  const generateLabel = (labelDimensionsMapping: DimensionsMapping): Labels | undefined => {
    const seriesIndex = series.findIndex(({ id: serieId }) => serieId === labelDimensionsMapping.serieId);
    const serie = series[seriesIndex];
    if (!serie) {
      return undefined;
    }
    const { label, path, displayOptions } = serie;
    const { xLabel, ...dimensionsMapping } = labelDimensionsMapping;
    const fullProjectedValue = Object.entries(dimensionsMapping)
      .filter(([dimId]) => viewDefinition.getDimensionsDisplay(viewDimensions).find((dim) => dim.id === dimId)?.withLegend)
      .map(([__, dimValue]) => dimValue)
      .map((val) => getProjectionValueLabel(store, val))
      .join(' x ');
    const resolutionParametersMapping = joinObjects(parametersMapping, dimensionsMappingToParametersMapping(labelDimensionsMapping));
    const seriesLabel = getSeriesLabel(store, label, seriesIndex, path, viewDimensions, Object.keys(resolutionParametersMapping));
    const serieLegendLabel = displayOptions?.withLegend ? seriesLabel : '';
    let value: Labels | undefined;
    const onlyDimensionLabel = series.every(({ displayOptions: options }) => !options?.withLegend);
    const onlySeriesLabel = viewDimensions.length === 0 || viewDefinition.getDimensionsDisplay(viewDimensions).every((dimensionDisplay) => !dimensionDisplay.withLegend);
    if ((onlyDimensionLabel || onlySeriesLabel) && !(onlyDimensionLabel && onlySeriesLabel)) {
      value = { label: fullProjectedValue || serieLegendLabel, color: displayOptions?.color ?? '', key: Object.values(labelDimensionsMapping).join('|') };
    } else if (!displayOptions?.withLegend) {
      value = undefined;
    } else {
      const legendLabel = `${fullProjectedValue} - ${serieLegendLabel}`;
      value = { label: legendLabel, color: displayOptions?.color ?? '', key: Object.values(labelDimensionsMapping).join('|') };
    }

    const pathFieldId = getPathLastFieldInformation(path)?.fieldId;
    const pathResolver = createValuePathResolver(store, resolutionParametersMapping);
    const valueResolution = pathResolver.resolvePathValue(path ?? []);
    if (isValidValuePathResolution(valueResolution) && pathFieldId) {
      if (valueResolution && isSingleValueResolution(valueResolution) && valueResolution.value !== undefined && valueResolution.value !== null) {
        if (displayOptions?.colorPath) {
          const colorResolution = pathResolver.resolvePathValue<string>(displayOptions.colorPath);
          if (colorResolution && isSingleValueResolution(colorResolution) && colorResolution.value) {
            value = value ? joinObjects(value, { color: colorResolution.value }) : value;
          }
        }
      }
    }

    return value;
  };

  const getValue = (valueDimensionsMapping: DimensionsMapping): BarValue | BarValueError | undefined => {
    const seriesIndex = series.findIndex(({ id: serieId }) => serieId === valueDimensionsMapping.serieId);
    const { xLabel, serieId, ...dimensionsMapping } = valueDimensionsMapping;
    const serie = series[seriesIndex];
    if (!serie) {
      return undefined;
    }
    const { label, path: seriesPath, displayOptions: seriesDisplayOptions } = serie;
    const seriesFieldId = getPathLastFieldInformation(seriesPath)?.fieldId;
    const seriesParametersMapping = joinObjects(parametersMapping, dimensionsMappingToParametersMapping(valueDimensionsMapping));
    const seriesLabel = getSeriesLabel(store, label, seriesIndex, seriesPath, viewDimensions, Object.keys(seriesParametersMapping));
    if (!seriesFieldId) {
      return { error: formatErrorForUser(store, new SeriesResolutionError(seriesLabel, new Error(i18n`Series should end with a field`))) };
    }
    const pathResolver = createValuePathResolver(store, seriesParametersMapping);
    const resolutionField = pathResolver.resolvePathField(seriesPath);
    if (resolutionField instanceof Error) {
      return { error: formatErrorForUser(store, new SeriesResolutionError(seriesLabel, resolutionField)) };
    } else if (!resolutionField || isMultiFieldResolution(resolutionField) || !isInstanceOf(store.getObjectOrNull(resolutionField.fieldId), NumberField)) {
      return { error: formatErrorForUser(store, new SeriesResolutionError(seriesLabel, newError(i18n`Series path is invalid`))) };
    } else {
      const valueResolution = numberFieldHandler(store, resolutionField.fieldId).getValueResolution(resolutionField.dimensionsMapping ?? {});
      if (valueResolution.error !== undefined) {
        return { error: formatErrorForUser(store, new SeriesResolutionError(seriesLabel, new FieldResolutionError(resolutionField.fieldId, valueResolution.error))) };
      }
      const value: BarValue = {
        xLabel: viewDefinition.xAxis ? valueDimensionsMapping.xLabel : undefined,
        serie: { label: seriesLabel, fieldId: resolutionField.fieldId },
        color: seriesDisplayOptions?.color ?? '',
        key: Object.values(valueDimensionsMapping).join('|'),
        y: valueResolution.value as number ?? 0,
        value: valueResolution.value as number ?? 0,
        dimensionsMapping,
      };
      if (seriesDisplayOptions?.colorPath) {
        const colorResolution = pathResolver.resolvePathValue<string>(seriesDisplayOptions.colorPath);
        if (colorResolution && isSingleValueResolution(colorResolution) && colorResolution.value) {
          value.color = colorResolution.value;
        }
      }
      return value;
    }
  };

  const { mapReduceHandler: computeDimensionHandler, mapHandler } = computeDimension(store, parametersMapping, viewDimensions, filterConfiguration);
  const compositions = getDimensionCompositions(
    computeDimensionHandler,
    viewDimensions,
    viewDefinition.getDimensionsDisplay(viewDimensions),
    ({ id }) => !viewDefinition.xAxis || id !== viewDefinition.xAxis
  );

  if (compositions instanceof Error) {
    return { type: 'error', error: formatErrorForUser(store, compositions) };
  }
  let { xDimensionCompositions, yDimensionCompositions } = compositions;
  if (yDimensionCompositions.length > MAX_COMPOSITION_VALUE) {
    return { type: 'error', error: i18n`Too many y dimensions. Please filter the stacked dimensions` };
  }
  const xGroupDimensionIndex = viewDimensions.findIndex(({ id }) => viewDefinition.xAxis && id === viewDefinition.xAxis);
  let xGroupDimensions: ({ xLabel: string, [x: string]: string | undefined })[] = [];
  if (xGroupDimensionIndex !== -1) {
    const computed = mapHandler(viewDimensions[xGroupDimensionIndex], xGroupDimensionIndex);
    if (computed instanceof Error) {
      return { type: 'error', error: formatErrorForUser(store, computed) };
    }
    xGroupDimensions = computed.instances.map((instance) => ({
      [computed.dimId]: instance?.id,
      xLabel: instance ? getLabel(store, instance) : i18n`With all`,
    }));
  } else if (viewDefinition.xAxis === SERIE_GROUP_BY_OPTION) {
    xGroupDimensions = series.map(({ id, label, path }, index) => ({
      serieId: id,
      xLabel: getSeriesLabel(store, label, index, path, viewDimensions, Object.keys(parametersMapping)) ?? '',
    }));
  }
  let xGroupDimensionValues: DimensionsMapping[] = xGroupDimensions.sort((a, b) => a.xLabel.localeCompare(b.xLabel) ?? 1);
  xGroupDimensionValues = (xGroupDimensionValues.length > 0 ? xGroupDimensionValues : [{}]);
  if (xGroupDimensionValues.length * xDimensionCompositions.length > MAX_COMPOSITION_VALUE) {
    return { type: 'error', error: i18n`Too many x dimensions. Please filter the in line dimensions.` };
  }

  if (isSeriesStacked(seriesAxis)) {
    xDimensionCompositions = (xDimensionCompositions.length > 0 ? xDimensionCompositions : [{}]);
    yDimensionCompositions = getDimensionCompositionFromSeries(yDimensionCompositions, series);
  } else if (!(viewDefinition.xAxis === SERIE_GROUP_BY_OPTION)) {
    yDimensionCompositions = (yDimensionCompositions.length > 0 ? yDimensionCompositions : [{}]);
    xDimensionCompositions = getDimensionCompositionFromSeries(xDimensionCompositions, series);
  } else {
    yDimensionCompositions = (yDimensionCompositions.length > 0 ? yDimensionCompositions : [{}]);
    xDimensionCompositions = (xDimensionCompositions.length > 0 ? xDimensionCompositions : [{}]);
  }

  const barStackValueCumulated = [];
  const result: BarValue[][][] = [];
  const labels: Labels[] = [];

  for (let i = 0; i < xDimensionCompositions.length; i += 1) {
    const barStack: BarValue[][] = [];
    const xDimensionMapping = xDimensionCompositions[i];
    for (let j = 0; j < yDimensionCompositions.length; j += 1) {
      const groupValues: BarValue[] = [];
      const yDimensionMapping = yDimensionCompositions[j];
      for (let k = 0; k < xGroupDimensionValues.length; k += 1) {
        const xGroupMapping = xGroupDimensionValues[k];
        const mapping = joinObjects(yDimensionMapping, xDimensionMapping, xGroupMapping);
        const label = generateLabel(mapping);
        if (label) {
          labels.push(label);
        }
        const value = getValue(mapping);
        if (value !== undefined && isValidBarValue(value)) {
          groupValues.push(value);
        } else if (value !== undefined) {
          return { type: 'error', error: value.error };
        }
      }
      if (groupValues.length > 0) {
        if (xAxis) {
          barStack.push(groupValues);
          const maxLineSize = barStack[0].length;
          for (let l = 0; l < maxLineSize; l += 1) {
            let sum = 0;
            for (let m = 0; m < barStack.length; m += 1) {
              sum += barStack[m][l].value;
            }
            barStackValueCumulated.push(sum);
          }
        } else {
          barStack.push(groupValues);
          barStackValueCumulated.push(barStack.reduce((acc, currentVal) => {
            let sum = 0;
            currentVal.forEach((barVal) => {
              sum += barVal.value;
            });
            return acc + sum;
          }, 0));
        }
      }
    }
    if (barStack.length > 0) {
      result.push(barStack);
    }
  }
  const existingLabel = new Set();
  const deduplicateLabels = labels.filter(({ label, color }) => {
    const key = `${label}|${color}`;
    const isDuplicate = existingLabel.has(key);
    if (!isDuplicate) {
      existingLabel.add(key);
    }
    return !isDuplicate;
  });
  const yDomain = [Math.min(...barStackValueCumulated), Math.max(...barStackValueCumulated)] as [number, number];
  if (yDomain[0] > 0) {
    yDomain[0] = 0;
  }
  const { min, max, steps } = resolveChartColors(store, graphMinValue, graphMaxValue, graphRangeValues, parametersMapping);
  return {
    type: ViewType.StructuralBarChart,
    data: result,
    yDomain,
    minValue: min,
    maxValue: max,
    steps: steps?.filter(({ color, value: stepValue }) => color && (stepValue || stepValue === 0)),
    labels: deduplicateLabels,
  };
};
