import ndjson from 'fetch-ndjson';
import { equals } from 'ramda';
import type { FunctionComponent } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { v4 as uuid } from 'uuid';
import type { ProcessedEvent } from 'yooi-store';
import type { DateRange as DateRangeType } from 'yooi-utils';
import { dateFormats, DateStorageTypeKeys, DurationType, formatDisplayDate, joinObjects, newError, periodicities, PeriodicityType, subtractTimeFromDate } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import Typo from '../../../../components/atoms/Typo';
import DateRange from '../../../../components/inputs/datePickers/DateRange';
import NumberPicker from '../../../../components/inputs/NumberPicker';
import Chooser from '../../../../components/molecules/Chooser';
import Loading from '../../../../components/molecules/Loading';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import BlockContent from '../../../../components/templates/BlockContent';
import VerticalBlock from '../../../../components/templates/VerticalBlock';
import useStore from '../../../../store/useStore';
import { doFetch } from '../../../../utils/fetchUtils';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { notifyError } from '../../../../utils/notify';
import useDeepMemo from '../../../../utils/useDeepMemo';
import useForceUpdate from '../../../../utils/useForceUpdate';
import useTheme from '../../../../utils/useTheme';
import FormInput from '../../../_global/input/FormInput';
import type { OptionRecord } from '../../../_global/modelTypeUtils';
import { formatEvent } from '../_global/explorerUtils';
import { useExplorerHint } from '../_global/GetHintContextProvider';
import ValueRenderer from '../_global/ValueRenderer';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}, 'instanceEventsTab');

interface MandatoryDateRange {
  period: PeriodicityType,
  from: { type: DateStorageTypeKeys, value: number },
  to: { type: DateStorageTypeKeys, value: number },
}

interface InstanceEventsTabProps {
  instanceId: string | string[],
}

type QueryMode = { type: 'last', duration: DurationType, scalar: number } | { type: 'custom', range: MandatoryDateRange };

const InstanceEventsTab: FunctionComponent<InstanceEventsTabProps> = ({ instanceId }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const explorerHint = useExplorerHint();

  const [loading, setLoading] = useState(true);

  const resultsRef = useRef<{ id: string, range: { from: Date, to: Date }, events: ProcessedEvent[] } | undefined>(undefined);
  const forceUpdate = useForceUpdate();
  const doForceUpdate = useDebouncedCallback(forceUpdate, 150, { maxWait: 300 });

  const [error, setError] = useState<unknown>();

  const requestObjectId = useDeepMemo(() => (Array.isArray(instanceId) ? instanceId : [instanceId]), [instanceId]);

  const [queryMode, setQueryMode] = useState<QueryMode>({ type: 'last', duration: DurationType.hours, scalar: 6 });
  const [appliedQueryMode, setAppliedQueryMode] = useState<QueryMode>({ type: 'last', duration: DurationType.hours, scalar: 6 });

  const [streamIndex, setStreamIndex] = useState<number>(1);
  const streams = [{ key: 'processed', name: 'processed' }, { key: 'minified', name: 'minified' }];
  const currentStreamIndex = streamIndex < 0 || streamIndex >= streams.length ? 0 : streamIndex;
  const currentStream = streams[currentStreamIndex].key;

  useEffect(() => {
    const getEvent = async () => {
      const requestId = uuid();
      try {
        let from: Date;
        let to: Date;
        if (appliedQueryMode.type === 'last') {
          to = new Date();
          from = subtractTimeFromDate(to, appliedQueryMode.scalar, appliedQueryMode.duration);
        } else {
          const periodicity = periodicities[appliedQueryMode.range.period];
          from = periodicity.getStartOfPeriod(new Date(appliedQueryMode.range.from.value));
          to = appliedQueryMode.range.to.type === DateStorageTypeKeys.duration
            ? subtractTimeFromDate(periodicity.getNextDateInAmountOfPeriod(from, appliedQueryMode.range.to.value - 1), 1, DurationType.milliseconds)
            : periodicity.getEndOfPeriod(new Date(appliedQueryMode.range.to.value));
        }

        resultsRef.current = { id: requestId, range: { from, to }, events: [] };
        setLoading(true);

        const { response } = await doFetch('/store/explorer/event/object', {
          method: 'POST',
          json: { objectId: requestObjectId, fromEventId: `${from.getTime()}-0`, toEventId: `${to.getTime()}-0`, stream: currentStream },
          timeout: 15 * 60 * 1000,
          maxIterations: 2,
        });

        if (response.status !== 200) {
          setError(newError('Got error response code while querying /store/explorer/event/object', { status: response.status }));
        } else if (response.body) {
          const asyncGenerator = ndjson(response.body.getReader());

          let isDone = false;
          do {
            const next = await asyncGenerator.next();
            isDone = next.done ?? false;

            if (next.value && resultsRef.current?.id === requestId) {
              resultsRef.current.events.push(next.value);
              doForceUpdate();
            }
          } while (!isDone);
        }
      } catch (e) {
        if (resultsRef.current?.id === requestId) {
          setError(e);
        }
      } finally {
        if (resultsRef.current?.id === requestId) {
          setLoading(false);
        }
      }
    };
    getEvent();
  }, [requestObjectId, doForceUpdate, appliedQueryMode, currentStream]);

  // Got an error while loading ? break the view
  if (error) {
    // eslint-disable-next-line @typescript-eslint/only-throw-error
    throw error;
  }

  const queryModeType: OptionRecord<QueryMode['type']> = {
    last: { id: 'last', label: i18n`Last` },
    custom: { id: 'custom', label: i18n`Custom` },
  };

  const durationOptions: OptionRecord<DurationType> = {
    [DurationType.milliseconds]: { id: DurationType.milliseconds, label: i18n`Milliseconds` },
    [DurationType.seconds]: { id: DurationType.seconds, label: i18n`Seconds` },
    [DurationType.minutes]: { id: DurationType.minutes, label: i18n`Minutes` },
    [DurationType.hours]: { id: DurationType.hours, label: i18n`Hours` },
    [DurationType.days]: { id: DurationType.days, label: i18n`Days` },
    [DurationType.weeks]: { id: DurationType.weeks, label: i18n`Weeks` },
    [DurationType.months]: { id: DurationType.months, label: i18n`Months` },
    [DurationType.quarters]: { id: DurationType.quarters, label: i18n`Quarters` },
    [DurationType.years]: { id: DurationType.years, label: i18n`Years` },
  };

  return (
    <VerticalBlock>
      <BlockContent>
        <div className={classes.container}>
          <SpacingLine>
            <SearchAndSelect
              selectedOption={queryModeType[queryMode.type]}
              computeOptions={() => Object.values(queryModeType)}
              onSelect={(option) => {
                if (option?.id === 'last') {
                  setQueryMode({ type: 'last', duration: DurationType.hours, scalar: 6 });
                } else if (option?.id === 'custom') {
                  setQueryMode({
                    type: 'custom',
                    range: {
                      period: PeriodicityType.day,
                      from: { type: DateStorageTypeKeys.date, value: periodicities.day.getStartOfPeriod(new Date()).getTime() },
                      to: { type: DateStorageTypeKeys.duration, value: 1 },
                    },
                  });
                }
              }}
            />
            {
              queryMode.type === 'last' ? (
                <>
                  <SearchAndSelect
                    selectedOption={durationOptions[queryMode.duration]}
                    computeOptions={() => Object.values(durationOptions)}
                    onSelect={(option) => {
                      if (option) {
                        setQueryMode((current) => (current.type === 'last' ? joinObjects(current, { duration: option.id }) : current));
                      }
                    }}
                  />
                  <FormInput<number | string | undefined>
                    initialValue={queryMode.scalar}
                    onSubmit={(rawScalar) => {
                      const newScalar = typeof rawScalar === 'string' ? Number.parseInt(rawScalar, 10) : rawScalar;
                      if (newScalar !== undefined && newScalar !== null && Number.isSafeInteger(newScalar)) {
                        setQueryMode((current) => (current.type === 'last' ? joinObjects(current, { scalar: newScalar }) : current));
                      } else {
                        notifyError(i18n`Invalid value`);
                      }
                    }}
                  >
                    {(props) => (<NumberPicker {...props} />)}
                  </FormInput>
                </>
              ) : null
            }
            {
              queryMode.type === 'custom' ? (
                <FormInput<DateRangeType>
                  initialValue={queryMode.range}
                  onSubmit={(newValue) => {
                    if (newValue && newValue.period && newValue.from?.type && newValue.from.value !== undefined && newValue.to?.type && newValue.to.value !== undefined) {
                      const newRange: MandatoryDateRange = {
                        period: newValue.period,
                        from: { type: newValue.from.type, value: newValue.from.value },
                        to: { type: newValue.to.type, value: newValue.to.value },
                      };
                      setQueryMode((current) => (current.type === 'custom' ? joinObjects(current, { range: newRange }) : current));
                    } else {
                      notifyError(i18n`Invalid value`);
                    }
                  }}
                >
                  {(props) => (<DateRange {...props} withStartConstraint={false} />)}
                </FormInput>
              ) : null
            }
            <IconOnlyButton
              tooltip={i18n`Apply`}
              iconName={IconName.check}
              onClick={() => setAppliedQueryMode(queryMode)}
              disabled={equals(queryMode, appliedQueryMode)}
              variant={IconOnlyButtonVariants.tertiary}
            />
            <Chooser
              selectedIndexes={[currentStreamIndex]}
              actions={streams}
              onClick={(index) => setStreamIndex(index)}
            />
          </SpacingLine>
          {resultsRef.current ? (
            <SpacingLine>
              <Typo color={theme.color.text.secondary}>
                {i18n`Query from ${formatDisplayDate(resultsRef.current.range.from, dateFormats.timestamp)} to ${formatDisplayDate(resultsRef.current.range.to, dateFormats.timestamp)}`}
              </Typo>
              <IconOnlyButton
                variant={IconOnlyButtonVariants.tertiary}
                iconName={IconName.sync}
                tooltip={i18n`Refresh`}
                onClick={() => {
                  // Update state to for ré-executing effect
                  setAppliedQueryMode((current) => ({ ...current }));
                }}
                disabled={loading}
              />
            </SpacingLine>
          ) : null}

        </div>
      </BlockContent>
      {resultsRef.current?.events.length === 0 && (
        <BlockContent padded>
          <Typo>{i18n`No events found`}</Typo>
        </BlockContent>
      )}
      {resultsRef.current?.events.map((event, index, events) => (
        <BlockContent key={event.eventId} padded>
          <ValueRenderer value={joinObjects(formatEvent(store, explorerHint, event), { collapseByDefault: index < (events.length - 5) })} />
        </BlockContent>
      ))}
      {loading && (
        <BlockContent padded>
          <Loading />
        </BlockContent>
      )}
    </VerticalBlock>
  );
};

export default InstanceEventsTab;
