import type { ObjectStoreReadOnly } from 'yooi-store';
import { getOptionalHistoryProducer } from '../../common/fields/FieldModuleDsl';
import type { TimeseriesHistoryEventProducer, ValueHistoryEventProducer } from '../../common/fields/FieldModuleDslType';
import { isInstanceOf } from '../../typeModule';
import { Class_Instances, Instance_Of } from '../../typeModule/ids';
import { Field as FieldId, FieldDefinition } from '../ids';
import type { FieldStoreObject } from '../model';

export interface FieldValueProducersHandler {
  initiateFieldValueProducers: (objectStore: ObjectStoreReadOnly) => void,
  updateFieldValueProducers: (objectId: string[], objectStore: ObjectStoreReadOnly) => void,
  fieldValueProducers: Map<string, { typeId: string, producer: ValueHistoryEventProducer }>,
  fieldTimeseriesProducers: Map<string, { typeId: string, producer: TimeseriesHistoryEventProducer }>,
}

export const createFieldValueProducerHandler = (): FieldValueProducersHandler => {
  const fieldValueProducers: FieldValueProducersHandler['fieldValueProducers'] = new Map();
  const fieldTimeseriesProducers: FieldValueProducersHandler['fieldTimeseriesProducers'] = new Map();

  const initiateFieldValueProducers: FieldValueProducersHandler['initiateFieldValueProducers'] = (objectStore) => {
    objectStore.getObjectOrNull(FieldDefinition)
      ?.navigateBack(Class_Instances)
      .flatMap((fieldDefinition) => fieldDefinition.navigateBack(Class_Instances))
      .forEach(({ id, [Instance_Of]: instanceOf }) => {
        const historyProducer = getOptionalHistoryProducer(objectStore, id);
        if (historyProducer) {
          if ('value' in historyProducer) {
            fieldValueProducers.set(id, { typeId: instanceOf as string, producer: historyProducer.value });
          }
          if ('timeseries' in historyProducer) {
            fieldTimeseriesProducers.set(id, { typeId: instanceOf as string, producer: historyProducer.timeseries });
          }
        }
      });
  };

  const updateFieldValueProducers: FieldValueProducersHandler['updateFieldValueProducers'] = (id, objectStore) => {
    const object = objectStore.getObjectOrNull(id[0]);
    if (object && isInstanceOf<FieldStoreObject>(object, FieldId) && !fieldValueProducers.has(id[0]) && !fieldTimeseriesProducers.has(id[0])) {
      const historyProducer = getOptionalHistoryProducer(objectStore, id[0]);
      if (historyProducer) {
        if ('value' in historyProducer) {
          fieldValueProducers.set(id[0], { typeId: object[Instance_Of], producer: historyProducer.value });
        }
        if ('timeseries' in historyProducer) {
          fieldTimeseriesProducers.set(id[0], { typeId: object[Instance_Of], producer: historyProducer.timeseries });
        }
      }
    } else if (id.length === 1 && object === null) {
      if (fieldValueProducers.has(id[0])) {
        fieldValueProducers.delete(id[0]);
      }
      if (fieldTimeseriesProducers.has(id[0])) {
        fieldTimeseriesProducers.delete(id[0]);
      }
    }
  };

  return {
    initiateFieldValueProducers,
    updateFieldValueProducers,
    fieldValueProducers,
    fieldTimeseriesProducers,
  };
};
