import type { FunctionComponent } from 'react';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import base from '../../../../theme/base';
import makeStyles from '../../../../utils/makeStyles';

const useStyles = makeStyles((theme) => ({
  dragItemPlaceholder: {
    minWidth: '67rem',
    borderRadius: base.borderRadius.medium,
    backgroundColor: theme.color.background.info.light,
    cursor: 'grabbing',
    height: '100%',
  },
}), 'dragItemPlaceholder');

interface DragItemPlaceholderProps {
  width?: number,
}

const DragItemPlaceholder: FunctionComponent<DragItemPlaceholderProps> = ({ width }) => {
  const classes = useStyles();
  return (
    <SpacingLine>
      <div
        style={width ? { minWidth: width } : undefined}
        className={classes.dragItemPlaceholder}
      />
    </SpacingLine>
  );
};

export default DragItemPlaceholder;
