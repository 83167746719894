import { createBuiltinOperators } from './builtinOperators';
import { FormulaParsingError, FormulaResolutionError } from './errors';
import type { EngineContext, FormulaEngine, FormulaNode, InputSet } from './formula';
import { createResolverFactory } from './formulaResolver';
import { visitFormula } from './formulaVisitor';
import { newFunctionLibrary } from './functionLibraryBuilder';
import { parser } from './grammar/formulaParser';

const strictParser = parser.configure({ strict: true });

const parseFormula = (formula: string) => {
  try {
    return strictParser.parse(formula);
  } catch (e) {
    throw new FormulaParsingError(e, formula);
  }
};

export const createFormulaEngineV2 = ({ functionLibrary, typeSystem, ...engineContext }: EngineContext): FormulaEngine => {
  const contextWithBuiltinOperators = {
    functionLibrary: newFunctionLibrary()
      .addLibrary(createBuiltinOperators(typeSystem))
      .addLibrary(functionLibrary)
      .build(),
    typeSystem,
    ...engineContext,
  };

  const createResolver = createResolverFactory(contextWithBuiltinOperators);

  return {
    prepareFormula: <C>(formula: string, inputSet: InputSet<C>): FormulaNode<C> => {
      const root = visitFormula(
        formula,
        parseFormula(formula),
        createResolver(inputSet)
      );

      if (root instanceof Error) {
        throw new FormulaResolutionError(root);
      } else {
        return root;
      }
    },
  };
};
