import { compareEventId, doYield, joinObjects, sleep } from 'yooi-utils';
import { acquireLockImmediately } from '../utils/webLocksUtils';
import type { DownloadBrowserSnapshot } from './browserSnapshotNetwork';
import type { Snapshot } from './concurrencyHandler';
import type { OnConnectionProgress } from './connectionProgress';
import { ConnectionPhase } from './connectionProgress';
import type { DownloadNetworkSnapshot } from './networkEventClient';

interface DownloadSnapshotMetric {
  source: 'network' | 'browser' | 'client',
  status: 'success' | 'failure' | 'invalid' | 'unauthorized' | 'interrupted' | 'throttle' | 'broadcast',
  duration?: number,
  [key: string]: unknown,
}

const networkDownloadLockName = 'yooi:snapshot:downloadFromNetwork';
const downloadProgressKey = 'yooi:snapshot:downloadFromNetwork:progress';

interface CreateSnapshotDownloaderProps {
  downloadBrowserSnapshot: DownloadBrowserSnapshot,
  registerBrowserSnapshotDownloadedBroadcastHandler: () => {
    close: () => void,
    getSnapshot: () => Snapshot | undefined,
  },
  broadcastBrowserDownloadedSnapshot: (snapshot: Snapshot) => Promise<{ duration: number } | undefined>,
  downloadSnapshotFromNetwork: DownloadNetworkSnapshot,
  onConnectionProgress: OnConnectionProgress,
}

interface DownloadSnapshot {
  (userId: string, minimalEventId: string, abortSignal: AbortSignal): Promise<{
    snapshot?: Snapshot | undefined,
    metrics: DownloadSnapshotMetric[],
    disconnected?: boolean,
  }>,
}

export const createSnapshotDownloader = ({
  downloadBrowserSnapshot,
  registerBrowserSnapshotDownloadedBroadcastHandler,
  broadcastBrowserDownloadedSnapshot,
  downloadSnapshotFromNetwork,
  onConnectionProgress,
}: CreateSnapshotDownloaderProps): {
  downloadSnapshot: DownloadSnapshot,
} => {
  const downloadSnapshot: DownloadSnapshot = async (userId, minimalEventId, abortSignal) => {
    const metrics: DownloadSnapshotMetric[] = [];

    const downloadFromBrowser = async () => {
      const start = performance.now();
      const { snapshot, reasons } = await downloadBrowserSnapshot(userId, minimalEventId, onConnectionProgress);
      let browserSnapshotStatus: 'success' | 'failure' | 'invalid';
      if (snapshot === undefined) {
        browserSnapshotStatus = 'failure';
      } else if (compareEventId(minimalEventId, snapshot.eventId) > 0) {
        browserSnapshotStatus = 'invalid';
      } else {
        browserSnapshotStatus = 'success';
      }
      metrics.push({
        source: 'browser',
        status: browserSnapshotStatus,
        eventId: snapshot?.eventId,
        checksum: snapshot?.checksum,
        operationsCount: snapshot?.operations.length,
        rollbackOperationsCount: snapshot?.rollbackOperations.length,
        duration: Math.ceil(performance.now() - start),
        reasons,
      });
      if (browserSnapshotStatus === 'success' && snapshot !== undefined) {
        return { snapshot, retryBrowserDownload: false };
      } else {
        const timeStart = performance.now();
        const downloadLockExists = async () => Boolean((await navigator.locks.query()).held?.find((lockInfo) => lockInfo.name === networkDownloadLockName));
        const shouldWaitLock = await downloadLockExists();
        if (shouldWaitLock) {
          const listener = ({ key }: StorageEvent) => {
            if (key === downloadProgressKey) {
              const progress = localStorage.getItem(downloadProgressKey);
              if (progress) {
                onConnectionProgress(ConnectionPhase.DownloadFromNetworkInOtherTab, Number.parseFloat(progress));
              }
            }
          };
          window.addEventListener('storage', listener);
          const { close, getSnapshot } = registerBrowserSnapshotDownloadedBroadcastHandler();
          try {
            while (await downloadLockExists()) {
              await sleep(100);
            }
            const downloadedSnapshot = getSnapshot();

            let downloadedSnapshotStatus: 'success' | 'failure' | 'invalid';
            if (downloadedSnapshot === undefined) {
              downloadedSnapshotStatus = 'failure';
            } else if (compareEventId(minimalEventId, downloadedSnapshot.eventId) > 0) {
              downloadedSnapshotStatus = 'invalid';
            } else {
              downloadedSnapshotStatus = 'success';
            }

            metrics.push({
              source: 'browser',
              status: downloadedSnapshotStatus,
              eventId: downloadedSnapshot?.eventId,
              checksum: downloadedSnapshot?.checksum,
              operationsCount: downloadedSnapshot?.operations.length,
              rollbackOperationsCount: downloadedSnapshot?.rollbackOperations.length,
              duration: Math.round(performance.now() - timeStart),
              broadcastedOnDownload: true,
            });

            if (downloadedSnapshotStatus === 'success' && downloadedSnapshot !== undefined) {
              return { snapshot: downloadedSnapshot };
            }
          } finally {
            window.removeEventListener('storage', listener);
            close();
          }
        }
        return { retryBrowserDownload: shouldWaitLock };
      }
    };

    const downloadFromNetwork = async () => {
      const { snapshot, status, stats } = await downloadSnapshotFromNetwork(
        minimalEventId,
        abortSignal,
        (connectionPhase, percentage) => {
          if (connectionPhase === ConnectionPhase.DownloadFromNetwork && percentage !== undefined) {
            window.localStorage.setItem(downloadProgressKey, percentage.toString());
          }
          onConnectionProgress(connectionPhase, percentage);
        }
      );
      metrics.push(joinObjects(stats, { source: 'network' as const, status }) as DownloadSnapshotMetric);

      if (status === 'unauthorized') {
        return { disconnected: true };
      } else {
        return { snapshot };
      }
    };

    const throttle = async () => {
      // Wait between 1 and 3s to retry
      const waitDuration = Math.round(1_000 + 2_000 * Math.random());
      metrics.push({ source: 'client', status: 'throttle', duration: waitDuration });
      await sleep(Math.min(500, waitDuration));
    };

    while (!abortSignal.aborted) {
      onConnectionProgress(ConnectionPhase.WaitingForSnapshot);
      await doYield(true);

      const { snapshot: snapshotFromBrowser, retryBrowserDownload } = await downloadFromBrowser();
      if (snapshotFromBrowser) {
        return { snapshot: snapshotFromBrowser, metrics };
      } else if (!retryBrowserDownload) {
        const downloadLock = await acquireLockImmediately(networkDownloadLockName);
        if (downloadLock) {
          try {
            const { snapshot, disconnected } = await downloadFromNetwork();
            if (snapshot) {
              const broadcastResult = await broadcastBrowserDownloadedSnapshot(snapshot);
              if (broadcastResult) {
                metrics.push({ source: 'client', status: 'broadcast', duration: broadcastResult.duration });
              }
              return { snapshot, metrics };
            } else if (disconnected) {
              return { metrics, disconnected };
            }
          } finally {
            window.localStorage.removeItem(downloadProgressKey);
            downloadLock.unlock();
          }
        }

        if (!abortSignal.aborted) {
          await throttle();
        }
      }
    }

    metrics.push({ source: 'client', status: 'interrupted' });
    return { metrics };
  };

  return {
    downloadSnapshot,
  };
};
