import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { dateFormats, formatDisplayDate, newError, numberType } from 'yooi-utils';
import type { GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import { validateFieldIdAsProperty } from '../common/commonFieldUtils';
import { registerField } from '../module';
import type { DimensionsMapping, ResolutionStack } from '../utils';
import { isValueResolutionOfType, ParsedDimensionType, parseDimensionMapping, resolveFieldValue } from '../utils';
import type { TimeStampField } from './types';

const getValueResolution = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack) => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
  if (isValueResolutionOfType(valueResolution, (value): value is number | undefined => value === undefined || typeof value === 'number')) {
    return valueResolution;
  } else {
    return {
      value: undefined,
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['number', 'undefined'], typeof valueResolution.value),
      getDisplayValue: () => undefined,
      isTimeseries: valueResolution.isTimeseries,
    };
  }
};

type TimestampFieldHandler = GetDslFieldHandler<
  TimeStampField,
  number | undefined,
  undefined,
  number | undefined,
  number | undefined,
  number | undefined,
  undefined,
  undefined,
  undefined,
  undefined
>;

export const timeStampFieldHandler: TimestampFieldHandler = registerField({
  model: {
    label: 'TimeStampField',
    title: 'Timestamp',
    withApiAlias: true,
    asPropertyBusinessRules: [validateFieldIdAsProperty('timestampField')],
  },
  handler: (objectStore, fieldId) => {
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => {
      const timestamp = getValueResolution(objectStore, fieldId, dimensionsMapping).value;
      if (timestamp) {
        return formatDisplayDate(new Date(timestamp), dateFormats.timestamp);
      } else {
        return undefined;
      }
    };

    const getStoreValue = (dimensionsMapping: DimensionsMapping): number | undefined => {
      const parsedDimension = parseDimensionMapping(dimensionsMapping);
      if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
        return objectStore.getObject(parsedDimension.objectId)[fieldId] as number | undefined;
      } else {
        return undefined;
      }
    };

    return {
      describe: () => ({ hasData: true, returnType: numberType, timeseriesMode: 'none' }),
      resolvePathStepConfiguration: () => ({
        hasData: true,
        timeseriesMode: 'none',
        getValueResolutionType: () => numberType,
        resolveValue: (dimensionsMappings, _, resolutionStack) => {
          const { error, value } = getValueResolution(objectStore, fieldId, dimensionsMappings, resolutionStack);
          if (error) {
            throw error;
          } else {
            return value;
          }
        },
      }),
      restApi: {
        returnTypeSchema: { type: 'number', nullable: true },
        formatValue: (value) => value ?? undefined,
      },
      getStoreValue,
      getValueWithoutFormula: getStoreValue,
      getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
      updateValue: () => {
        throw newError('updateValue not supported');
      },
      isEmpty: (dimensionsMapping) => !getValueResolution(objectStore, fieldId, dimensionsMapping).value,
      isSaneValue: () => ({ isValid: true }),
      getValueAsText,
      getExportColumnHeaders: (configuration, fieldLabel) => ({
        columnsNumber: 1,
        getHeaders: () => [{ format: 'string', value: fieldLabel }],
        getColumnConfiguration: () => configuration,
      }),
      getExportValue: (dimensionsMapping) => ({
        format: 'date',
        value: getValueResolution(objectStore, fieldId, dimensionsMapping).value,
        period: undefined,
      }),
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else {
            return undefined;
          }
        },
      }),
      filterConditions: undefined,
    };
  },
});
