import type { PathStep } from '../../../conceptModule';

export interface ViewDimensionProperties {
  label?: string,
  display: {
    withNotSet?: boolean,
    withAll?: boolean,
  },
  path: PathStep[],
}

export interface ViewDimension extends ViewDimensionProperties {
  id: string,
}

export const isValidDimension = (dimension: unknown): dimension is ViewDimension => Boolean((dimension as ViewDimension).id && (dimension as ViewDimension).path);
