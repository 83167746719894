import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import Icon, { IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import Typo from '../../../../components/atoms/Typo';
import EditableWithDropdown from '../../../../components/molecules/EditableWithDropdown';
import type { ItemEntry } from '../../../../components/templates/internal/DataTableBodyRenderer';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeSelectorsClasses from '../../../../utils/makeSelectorsClasses';
import makeStyles from '../../../../utils/makeStyles';
import { SizeVariant } from '../../../../utils/useSizeContext';
import useTheme from '../../../../utils/useTheme';
import AttachmentFieldCardsRenderer from './AttachmentFieldCardsRenderer';
import type { AttachmentEntry } from './AttachmentFieldRenderer';

const selectorsClasses = makeSelectorsClasses('visibilityHandler');

const useStyles = makeStyles(
  {
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: spacingRem.s,
      flexGrow: 1,
      alignItems: 'center',
      marginLeft: spacingRem.s,
      marginRight: spacingRem.s,
      '&:hover, &:focus, &:focus-within': {
        [`& .${selectorsClasses.visibilityHandler}`]: {
          visibility: 'visible',
        },
      },
    },
    attachButton: {
      display: 'flex',
      alignItems: 'center',
    },
    hiddenActions: {
      visibility: 'hidden',
    },
    leftContainer: {
      display: 'inline-grid',
      gridAutoFlow: 'column',
      alignItems: 'center',
      width: 'fit-content',
      columnGap: spacingRem.s,
    },
  },
  'attachmentFieldCellRenderer'
);

interface AttachmentFieldCellRendererProps {
  attachments: ItemEntry<AttachmentEntry>[],
  onCreate: () => void,
  readOnly: boolean,
}

const AttachmentFieldCellRenderer: FunctionComponent<AttachmentFieldCellRendererProps> = ({ attachments, onCreate, readOnly }) => {
  const theme = useTheme();
  const classes = useStyles();

  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <EditableWithDropdown
      showDropdown={showDropdown}
      openDropdown={() => {
        setShowDropdown(true);
      }}
      closeDropdown={() => {
        setShowDropdown(false);
      }}
      renderValue={
        () => (
          <div className={classes.headerContainer}>
            <div className={classes.leftContainer}>
              {attachments.length > 0 ? (<Icon name={IconName.file_copy} color={theme.color.link.default} />) : null}
              <Typo maxLine={1} color={theme.color.link.default}>{i18n`${attachments.length ?? 0} file(s)`}</Typo>
            </div>
            {!readOnly ? (
              <div
                className={classnames({
                  [classes.attachButton]: true,
                  [classes.hiddenActions]: !showDropdown,
                  [selectorsClasses.visibilityHandler]: true,
                })}
              >
                <IconOnlyButton
                  tooltip={i18n`Attach file`}
                  variant={IconOnlyButtonVariants.secondary}
                  sizeVariant={SizeVariant.small}
                  iconName={IconName.add}
                  onClick={() => onCreate()}
                />
              </div>
            ) : null}
          </div>
        )
      }
      renderDropdown={() => (attachments.length > 0 ? (
        <div>
          <AttachmentFieldCardsRenderer attachments={attachments} onCreate={onCreate} readOnly={readOnly} variant="dropdown" />
        </div>
      ) : null)}
    />
  );
};

export default AttachmentFieldCellRenderer;
