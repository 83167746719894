import type { FunctionComponent } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import ScrollableWidgetContainer from '../../../../components/templates/ScrollableWidgetContainer';
import useWidgetSizeContext from '../../../../utils/useWidgetSizeContext';
import IFrameFieldRenderer from './IFrameFieldRenderer';

interface IFrameFieldWidgetProps {
  fieldId: string,
  parametersMapping: ParametersMapping,
}

const IFrameFieldWidget: FunctionComponent<IFrameFieldWidgetProps> = ({ fieldId, parametersMapping }) => {
  const { width, height } = useWidgetSizeContext();

  return (
    <ScrollableWidgetContainer withRowGap width={width} height={height}>
      <IFrameFieldRenderer
        fieldId={fieldId}
        parametersMapping={parametersMapping}
      />
    </ScrollableWidgetContainer>
  );
};

export default IFrameFieldWidget;
