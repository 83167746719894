import type { MutableRefObject } from 'react';
import { useLayoutEffect } from 'react';

const useFocusOnMount = (ref: MutableRefObject<HTMLElement | null>, enabled: unknown): void => {
  const enabledBool = Boolean(enabled);
  useLayoutEffect(() => {
    if (enabledBool) {
      ref.current?.focus({ preventScroll: true });
    }
  }, [enabledBool, ref]);
};

export default useFocusOnMount;
