import { sleep } from './promiseUtils';

export type YieldMethod = (force?: boolean) => Promise<boolean>;

let yieldMethod: YieldMethod = async (force?: boolean) => {
  if (force) {
    await sleep(0);
    return true;
  } else {
    return false;
  }
};

export const registerYieldStrategy = (yieldMethodToRegister: YieldMethod): void => {
  yieldMethod = yieldMethodToRegister;
};

// This method must be called regularly by methods that risk to block the event loop too much long, preventing other event or rendering to be processed.
// The method has been named accordingly https://en.wikipedia.org/wiki/Yield_(multithreading). The method is named 'doYield' instead of simply 'yield' since 'yield' is reserved keyword in javascript
// The real action of this method is delegated to the yield strategy implementation.
export const doYield: YieldMethod = (force) => yieldMethod(force);

// This strategy ensure that the event loop is not blocked more than a fixed time.
// To ensure that, an event is enqueued in the event loop, and it's execution is monitored
// If the task has not been executed at the expected time, the yield method waits for its execution
// The task is only active when there is currently calls to doYield method
export const createEventLoopMaxBlockDurationYieldStrategy = (
  eventLoopMaxBlockDuration = 100,
  // if the running tasks are cooperative, we don't want to force them to have unexpected await, so we must have an execution delay lower than the permitted timeout
  eventLoopMonitorDelay = Math.round(eventLoopMaxBlockDuration / 2)
): YieldMethod => {
  let hasYield: (() => Promise<boolean>) | undefined;

  const monitorEventLoop = () => {
    const yieldTimeout = Date.now() + eventLoopMaxBlockDuration;
    const yieldPromise = new Promise<void>((resolve) => {
      setTimeout(() => {
        hasYield = undefined;
        resolve();
      }, eventLoopMonitorDelay);
    });

    hasYield = async () => {
      if (Date.now() > yieldTimeout) {
        await yieldPromise;
        return true;
      } else {
        return false;
      }
    };
  };

  return async (force = false) => {
    if (force) {
      await sleep(0);
      return true;
    } else {
      let result = false;
      while (await hasYield?.()) {
        result = true;
      }

      if (!hasYield) {
        monitorEventLoop();
      }
      return result;
    }
  };
};
