import type { ReactElement } from 'react';
import { useRef } from 'react';

interface InCompositeInputChildrenProps<T> {
  value: T | undefined,
  onChange: (newValue: T | undefined) => void,
  onSubmit: (value: T | undefined) => void,
  onCancel: () => void,
}

interface InCompositeInputProps<T> {
  initialValue: T | undefined,
  setInputValue: (value: T | null) => void,
  children: (props: InCompositeInputChildrenProps<T>) => ReactElement,
}

const InCompositeInput = <T>({ initialValue, setInputValue, children }: InCompositeInputProps<T>): ReactElement | null => {
  const previousValue = useRef(initialValue);
  const props: InCompositeInputChildrenProps<T> = {
    value: initialValue,
    onChange: (valueToSubmit) => {
      setInputValue(valueToSubmit ?? null);
    },
    onSubmit: () => {},
    onCancel: () => {
      setInputValue(previousValue.current ?? null);
    },
  };

  return (
    children(props)
  );
};

export default InCompositeInput;
