import type { FunctionComponent, ReactElement } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import type { ConceptDefinitionRaw, ConceptDefinitionStoreObject, EmbeddingFieldStoreObject, FieldDimensionStoreObject } from 'yooi-modules/modules/conceptModule';
import { embeddingFieldHandler, getConceptDefinitionValidFields, isConceptValid } from 'yooi-modules/modules/conceptModule';
import {
  ConceptDefinition,
  ConceptDefinition_Description,
  ConceptDefinition_HasStakeholders,
  ConceptDefinition_Icon,
  ConceptDefinition_IsCore,
  ConceptDefinition_Name,
  ConceptDefinition_RestrictedAccess,
  EmbeddingField,
  FieldDimension_Field,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  KinshipRelation,
} from 'yooi-modules/modules/conceptModule/ids';
import { Dashboard } from 'yooi-modules/modules/dashboardModule/ids';
import { DataAsset } from 'yooi-modules/modules/dataAssetModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import { compareString, comparing, joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import { BannerVariant } from '../../../../components/molecules/Banner';
import Link from '../../../../components/molecules/Link';
import MasterDetailList from '../../../../components/molecules/MasterDetailList';
import OverflowMenu from '../../../../components/molecules/OverflowMenu';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../../../components/molecules/Table';
import type { Tab } from '../../../../components/molecules/Tabs';
import BaseLayout from '../../../../components/templates/BaseLayout';
import Header from '../../../../components/templates/Header';
import HeaderActions from '../../../../components/templates/HeaderActions';
import HeaderLine from '../../../../components/templates/HeaderLine';
import HeaderTabs from '../../../../components/templates/HeaderTabs';
import LeftPanel from '../../../../components/templates/LeftPanel';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { safeSessionStorageValue } from '../../../../utils/sessionStorageUtils';
import { formatOrUndef } from '../../../../utils/stringUtils';
import useDeleteModal from '../../../../utils/useDeleteModal';
import useNavigation from '../../../../utils/useNavigation';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../../utils/useSizeContext';
import type { FilterConfiguration } from '../../../_global/filter/useFilterSessionStorage';
import StoreIconPicker from '../../../_global/input/StoreIconPicker';
import StoreTextInputField from '../../../_global/input/StoreTextInputField';
import { searchFilterFunction } from '../../../_global/listFilterFunctions';
import type { NavigationFilter } from '../../../_global/navigationUtils';
import TopBar from '../../../_global/topBar/TopBar';
import type { ComparatorHandler } from '../../../_global/useFilterAndSort';
import useFilterAndSort, { buildNumberColumnComparatorHandler } from '../../../_global/useFilterAndSort';
import BehaviorTab from './behavior/BehaviorTab';
import ChipTab from './chip/ChipTab';
import IntentionTab from './collaboration/IntentionTab';
import DataAssetTypesTab from './dataAssetType/DataAssetTypesTab';
import FieldsTab from './fields/FieldsTab';
import InstancesTab from './instances/InstancesTab';
import LayoutTab from './layout/LayoutTab';
import LibraryTab from './library/LibraryTab';
import PermissionTab, { getGroupConfigurationStatus, getRolesProjectConfigurationStatus } from './permission/PermissionTab';
import WorkflowTab from './workflow/WorkflowTab';

const useStyles = makeStyles({
  masterDetailLine: {
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.s,
  },
}, 'conceptDefinitionConfigurationPage');

interface ConceptDefinitionConfigurationPageProps {
  conceptDefinitionId: string,
}

const ConceptDefinitionConfigurationPage: FunctionComponent<ConceptDefinitionConfigurationPageProps> = ({ conceptDefinitionId }) => {
  const classes = useStyles();

  const store = useStore();

  const location = useLocation();
  const navigation = useNavigation<NavigationFilter>();

  const conceptDefinition = store.getObject<ConceptDefinitionStoreObject>(conceptDefinitionId);

  const [doDelete, deleteModal] = useDeleteModal({
    doDelete: (promoteChildren) => {
      if (promoteChildren) {
        const allEmbeddingFields = getConceptDefinitionValidFields(store, conceptDefinitionId).filter((field) => isInstanceOf(field, EmbeddingField));
        store.getObject(conceptDefinitionId)
          .navigateBack(Class_Instances)
          .filter(({ id: conceptId }) => isConceptValid(store, conceptId))
          .forEach((instance) => {
            allEmbeddingFields.forEach((field) => (
              instance.navigateBack(field.id)
                .forEach((child) => {
                  store.updateObject(child.id, {
                    [KinshipRelation]: null,
                    [field.id]: null,
                  });
                })
            ));
          });
      }
      store.deleteObject(conceptDefinitionId);
      navigation.push('organization_settings', { pathname: '/settings/organization' });
    },
    shouldConfirm: () => (conceptDefinition.navigateBack(Class_Instances).filter(({ id }) => isConceptValid(store, id)).length > 0),
    getModalProps: () => {
      const hasEmbedding = store.withAssociation(FieldDimensionTypes)
        .withRole(FieldDimensionTypes_Role_ConceptDefinition, conceptDefinitionId)
        .list()
        .map((fieldDimensionType) => fieldDimensionType.navigateRole<FieldDimensionStoreObject>(FieldDimensionTypes_Role_FieldDimension))
        .filter((fieldDimension) => isInstanceOf<EmbeddingFieldStoreObject>(fieldDimension.navigate(FieldDimension_Field), EmbeddingField))
        .some((embeddingFieldDimension) => {
          const fieldHandler = embeddingFieldHandler(store, embeddingFieldDimension[FieldDimension_Field]);
          return store.getObject(conceptDefinitionId)
            .navigateBack(Class_Instances)
            .filter(({ id }) => isConceptValid(store, id))
            .some((instance) => (
              fieldHandler.getValueResolution({ [embeddingFieldDimension.id]: instance.id })
                .value
                .filter(({ id: conceptId }) => isConceptValid(store, conceptId))
                .length > 0
            ));
        });

      return {
        title: i18n`Are you sure that you want to delete this concept?`,
        confirmLabel: hasEmbedding ? i18n`Delete everything` : undefined,
        alternateConfirmLabel: hasEmbedding ? i18n`Delete concept and instance only` : undefined,
        content: hasEmbedding
          ? (
            <>
              <Typo>{i18n`Delete concept and instance only: Concept, associated elements & instances will be deleted, embedded instances will remain accessible`}</Typo>
              <Typo>{i18n`Delete everything: Concept, associated elements, instances & embedded instances will be deleted`}</Typo>
            </>
          )
          : (<Typo>{i18n`It will also remove all associated elements.`}</Typo>),
      };
    },
  });

  const tabs: (Tab & { content: ReactElement | null })[] = [];

  if (conceptDefinitionId !== Dashboard) {
    tabs.push({
      key: 'layout',
      name: i18n`Layout`,
      hash: '#layout',
      content: (<LayoutTab conceptDefinitionId={conceptDefinitionId} />),
      menuItems: [
        { name: i18n`Left panel`, hash: '#layout_leftpanel' },
        { name: i18n`Header`, hash: '#layout_header' },
        { name: i18n`Body`, hash: '#layout_body' },
      ].map(({ name, hash }) => ({
        key: name,
        name,
        onClick: () => navigation.replace(conceptDefinitionId, joinObjects(location, { hash })),
      })),
    });
  }

  tabs.push({
    key: 'fields',
    name: i18n`Fields`,
    hash: '#fields',
    content: (<FieldsTab conceptDefinitionId={conceptDefinitionId} />),
  });

  tabs.push({
    key: 'library',
    name: i18n`Library`,
    hash: '#library',
    content: (<LibraryTab conceptDefinitionId={conceptDefinitionId} />),
    menuItems: [
      { name: i18n`Predefined filters`, hash: '#library_predefinedfilters' },
      { name: i18n`Table and Card display`, hash: '#library_tableandcarddisplay' },
      { name: i18n`Timeline display`, hash: '#library_timelinedisplay' },
      { name: i18n`Matrix display`, hash: '#library_matrixdisplay' },
      { name: i18n`Swimlane display`, hash: '#library_swimlanedisplay' },
    ].map(({ name, hash }) => ({
      key: name,
      name,
      onClick: () => navigation.replace(conceptDefinitionId, joinObjects(location, { hash })),
    })),
  });

  tabs.push({
    key: 'chip',
    name: i18n`Chip`,
    hash: '#chip',
    content: (<ChipTab conceptDefinitionId={conceptDefinitionId} />),
  });

  tabs.push({
    key: 'behavior',
    name: i18n`Default behavior`,
    hash: '#behavior',
    content: (<BehaviorTab conceptDefinitionId={conceptDefinitionId} />),
    menuItems: [
      { name: i18n`Favorite fields`, hash: '#behavior_favoritefields' },
      { name: i18n`Search configuration`, hash: '#behavior_searchconfiguration' },
    ].map(({ name, hash }) => ({
      key: name,
      name,
      onClick: () => navigation.replace(conceptDefinitionId, joinObjects(location, { hash })),
    })),
  });

  tabs.push({
    key: 'workflows',
    name: i18n`Workflows`,
    hash: '#workflows',
    content: (<WorkflowTab conceptDefinitionId={conceptDefinitionId} />),
  });

  if (conceptDefinition[ConceptDefinition_HasStakeholders]) {
    const { variant: roleStatusVariant } = getRolesProjectConfigurationStatus(store, conceptDefinitionId);
    const { variant: groupStatusVariant } = getGroupConfigurationStatus(store, conceptDefinitionId);
    tabs.push({
      key: 'roles',
      name: i18n`User rights`,
      icon: roleStatusVariant === BannerVariant.danger || groupStatusVariant === BannerVariant.danger ? IconName.dangerous : undefined,
      iconColor: roleStatusVariant === BannerVariant.danger || groupStatusVariant === BannerVariant.danger ? IconColorVariant.error : undefined,
      hash: '#roles',
      content: (<PermissionTab conceptDefinitionId={conceptDefinitionId} />),
      menuItems: [
        { name: i18n`On each instance`, hash: '#roles_oneachinstance' },
        { name: i18n`On all instances`, hash: '#roles_onallinstances' },
        { name: i18n`Available capabilities`, hash: '#roles_availablecapabilities' },
        { name: i18n`Available workflow capabilities`, hash: '#roles_availableworkflowcapabilities' },
      ].map(({ name, hash }) => ({
        key: name,
        name,
        onClick: () => navigation.replace(conceptDefinitionId, joinObjects(location, { hash })),
      })),
    });
  }

  if (conceptDefinitionId === DataAsset) {
    tabs.push({
      key: 'types',
      name: i18n`Types`,
      hash: '#types',
      content: (<DataAssetTypesTab />),
    });
  }

  tabs.push({
    key: 'collaboration',
    name: i18n`Collaboration`,
    icon: undefined,
    iconColor: undefined,
    hash: '#collaboration',
    content: (<IntentionTab conceptDefinitionId={conceptDefinitionId} />),
  });

  tabs.push({
    key: 'instances',
    name: i18n`Instances`,
    hash: '#instance',
    content: (<InstancesTab conceptDefinitionId={conceptDefinitionId} />),
    alignRight: true,
  });

  const selectedTabIndex = tabs.findIndex((tab) => tab.hash === location.hash.split('_')[0]);

  const filterId = `admin_${ConceptDefinition}`;

  const [search, setSearch] = useState<string | undefined>(undefined);
  const filters = safeSessionStorageValue<FilterConfiguration | undefined>(filterId);
  const conceptDefinitionFilterFunction = searchFilterFunction(store, [filters?.nameSearch, search], [ConceptDefinition_Name]);
  const { generateList } = useFilterAndSort(
    filterId,
    store.getObject(ConceptDefinition)
      .navigateBack<ConceptDefinitionStoreObject>(Class_Instances)
      .filter((cd) => !cd[ConceptDefinition_RestrictedAccess])
      .map((cd) => ({
        key: cd.id,
        conceptDefinition: cd,
        instancesCount: cd.navigateBack(Class_Instances).filter(({ id }) => isConceptValid(store, id)).length,
        fieldsCount: getConceptDefinitionValidFields(store, cd.id).length,
      })),
    conceptDefinitionFilterFunction ? (item) => conceptDefinitionFilterFunction(item.conceptDefinition) : undefined,
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case 'fieldsCount':
          case 'instancesCount':
            return buildNumberColumnComparatorHandler(key, direction);
          case ConceptDefinition_Name:
          case ConceptDefinition_Description:
            return {
              comparator: comparing(compareString, direction === TableSortDirection.desc),
              extractValue: (item) => item.conceptDefinition[key],
            } satisfies ComparatorHandler<{ key: string, conceptDefinition: ConceptDefinitionStoreObject }, string | undefined>;
          default:
            return undefined;
        }
      },
      initial: { key: ConceptDefinition_Name, direction: TableSortDirection.asc },
    },
    undefined,
    [search]
  );

  return (
    <BaseLayout
      topBar={(<TopBar />)}
      leftPanel={(
        <LeftPanel>
          <MasterDetailList
            list={
              generateList().list
                .map(({ key, type, item }) => ({
                  key,
                  type,
                  render: () => {
                    const title = formatOrUndef(item.conceptDefinition[ConceptDefinition_Name]);

                    return (
                      <span className={classes.masterDetailLine}>
                        <Icon name={item.conceptDefinition[ConceptDefinition_Icon] as IconName} tooltip={title} />
                        <Tooltip title={title}>
                          <Typo maxLine={1}>{title}</Typo>
                        </Tooltip>
                      </span>
                    );
                  },
                  to: () => ({ pathname: `/settings/organization/${item.key}`, hash: location.hash }),
                }))
            }
            search={{ value: search, setValue: setSearch }}
          />
        </LeftPanel>
      )}
      header={(
        <Header
          firstLine={(
            <HeaderLine
              actions={(
                <HeaderActions>
                  <Link
                    title={i18n`Access library (${conceptDefinition.navigateBack(Class_Instances).filter(({ id: conceptId }) => isConceptValid(store, conceptId)).length})`}
                    to={`/concept/${conceptDefinitionId}`}
                    maxLine={1}
                  />
                  {
                    conceptDefinition[ConceptDefinition_IsCore]
                      ? null
                      : (<OverflowMenu menuItems={[{ key: 'delete', name: i18n`Delete`, icon: IconName.delete, onClick: () => doDelete(), danger: true }]} />)
                  }
                </HeaderActions>
              )}
              padded
            >
              <SizeContextProvider sizeVariant={SizeVariant.title} hierarchyVariant={HierarchyVariant.content}>
                <SpacingLine fullWidth>
                  <StoreIconPicker
                    initialValue={conceptDefinition[ConceptDefinition_Icon] as IconName}
                    onSubmit={(newIcon) => store.updateObject<ConceptDefinitionRaw>(conceptDefinitionId, { [ConceptDefinition_Icon]: newIcon ?? IconName.category })}
                    clearMode={{ type: 'reset', value: IconName.category }}
                  />
                  <StoreTextInputField
                    placeholder={i18n`Click to edit name`}
                    initialValue={conceptDefinition[ConceptDefinition_Name]}
                    onSubmit={(newName) => store.updateObject<ConceptDefinitionRaw>(conceptDefinitionId, { [ConceptDefinition_Name]: newName })}
                  />
                </SpacingLine>
              </SizeContextProvider>
            </HeaderLine>
          )}
          tabsLine={(
            <HeaderTabs
              tabs={tabs}
              selectedTabIndex={selectedTabIndex >= 0 ? selectedTabIndex : 0}
              onSelectedIndexChanged={(index) => {
                navigation.replace(`${conceptDefinitionId}`, joinObjects(location, { hash: tabs[index].hash }));
              }}
            />
          )}
        />
      )}
      content={(
        <>
          {tabs[selectedTabIndex >= 0 ? selectedTabIndex : 0].content}
          {deleteModal}
        </>
      )}
    />
  );
};

export default ConceptDefinitionConfigurationPage;
