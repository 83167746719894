import classnames from 'classnames';
import type { ComponentProps, FunctionComponent } from 'react';
import { useState } from 'react';
import { getMostReadableColorFromBackgroundColor } from '../../theme/colorUtils';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import useSizeContext, { SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import useUsageContext, { UsageVariant } from '../../utils/useUsageContext';
import Icon, { IconName, IconSizeVariant } from '../atoms/Icon';
import Typo from '../atoms/Typo';
import FloatingEditableWithDropdown, { FloatingEditableCloseReasons } from '../molecules/FloatingEditableWithDropdown';
import IconPickerOptions from './internal/IconPickerOptions';

const useStyles = makeStyles((theme) => ({
  valueContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  valueContainerDefault: {
    minWidth: '3.2rem',
    minHeight: '3rem',
  },
  valueContainerInTable: {
    flexGrow: 1,
  },
  valueContainerDefaultPadded: {
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
  },
  valueContainerBig: {
    minWidth: '5rem',
    minHeight: '5rem',
  },
  valueContainerBigFilled: {
    backgroundColor: theme.color.background.neutral.subtle,
  },
}), 'iconPicker');

interface IconPickerProps {
  value: IconName | undefined,
  onChange: (newIcon: IconName | undefined) => void,
  onSubmit?: (newIcon: IconName | undefined) => void,
  onCancel?: () => void,
  readOnly?: boolean,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  withMultiplayerOutline?: boolean,
  focusOnMount?: boolean,
  clearMode?: { type: 'clear' } | { type: 'reset', value: IconName },
  placeholder?: string,
}

const IconPicker: FunctionComponent<IconPickerProps> = ({
  value,
  onChange,
  onSubmit,
  onCancel,
  readOnly = false,
  onEditionStart,
  onEditionStop,
  withMultiplayerOutline = false,
  focusOnMount = false,
  clearMode,
  placeholder,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const usageVariant = useUsageContext();
  const { sizeVariant } = useSizeContext();

  const [showDropdown, setShowDropdown] = useState(focusOnMount);

  const openDropdown = () => {
    setShowDropdown(true);
    onEditionStart?.();
  };
  const closeDropdown: ComponentProps<typeof FloatingEditableWithDropdown>['closeDropdown'] = (reason) => {
    setShowDropdown(false);
    onEditionStop?.();
    if (reason === FloatingEditableCloseReasons.onBackdropClick) {
      onSubmit?.(value);
    } else {
      onCancel?.();
    }
  };

  return (
    <FloatingEditableWithDropdown
      showDropdown={showDropdown}
      openDropdown={openDropdown}
      closeDropdown={closeDropdown}
      renderValue={() => (
        <span
          className={classnames({
            [classes.valueContainer]: true,
            [classes.valueContainerInTable]: usageVariant === UsageVariant.inTable,
            [classes.valueContainerDefault]: sizeVariant !== SizeVariant.title,
            [classes.valueContainerDefaultPadded]: sizeVariant !== SizeVariant.title && value === undefined,
            [classes.valueContainerBig]: sizeVariant === SizeVariant.title,
            [classes.valueContainerBigFilled]: sizeVariant === SizeVariant.title && value !== undefined,
          })}
        >
          {
            value === undefined && sizeVariant === SizeVariant.title
              ? (<Icon name={IconName.category} size={IconSizeVariant.xl} color={theme.color.background.neutral.subtle} tooltip={placeholder} />)
              : null
          }
          {value === undefined && sizeVariant !== SizeVariant.title ? (<Typo maxLine={1} color={theme.color.text.disabled}>{placeholder}</Typo>) : null}
          {value !== undefined ? (<Icon name={value} size={sizeVariant === SizeVariant.title ? IconSizeVariant.xl : IconSizeVariant.l} />) : null}
        </span>
      )}
      renderDropdown={() => (
        readOnly
          ? null
          : (
            <IconPickerOptions
              value={value}
              onChange={(icon) => onChange(icon?.newIcon)}
              color={getMostReadableColorFromBackgroundColor(theme.color.background.neutral.default)}
              withVerticalPadding
              clearMode={clearMode}
            />
          )
      )}
      valueSizes={usageVariant !== UsageVariant.inTable ? { width: 'fit-content' } : undefined}
      readOnly={readOnly}
      withMultiplayerOutline={withMultiplayerOutline}
      withDashedBorder={sizeVariant === SizeVariant.title && value === undefined}
      withoutOverlayValueMinWidth
      autoFocusDropdown
    />
  );
};

export default IconPicker;
