import type { FunctionComponent } from 'react';
import type { Filters, FilterStep, MultipleMappingStep, MultipleParameterDefinition, PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import {
  FILTER_PARAMETER_CURRENT,
  InstanceReferenceType,
  isFieldStep,
  isFilterStep,
  isGlobalDimensionStep,
  isSingleParameterDefinition,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import { isMultipleMappingStep } from 'yooi-modules/modules/conceptModule/utils/path/pathUtils';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import Tooltip from '../../../components/atoms/Tooltip';
import Typo from '../../../components/atoms/Typo';
import CompositeField from '../../../components/molecules/CompositeField';
import DataTable from '../../../components/templates/DataTable';
import useStore from '../../../store/useStore';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { formatOrUndef } from '../../../utils/stringUtils';
import type { FilterDefinition } from '../filter/filterComposite/FilterComposite';
import FilterComposite from '../filter/filterComposite/FilterComposite';
import { countValidFilters, getFiltersPath } from '../filter/filterUtils';
import { getConceptDefinitionNameOrEntity } from '../modelTypeUtils';
import type { PathConfigurationHandler } from '../pathConfigurationHandler';
import { createPathConfigurationHandler } from '../pathConfigurationHandler';
import MappingComposite from './MappingComposite';
import PathStepsInput from './PathStepsInput';

interface PathMappingAndFiltersInputProps {
  path: PathStep[],
  parameterDefinitions?: (SingleParameterDefinition | MultipleParameterDefinition)[],
  onChange?: (newPath: PathStep[]) => void,
  readOnly?: boolean,
  valuePathHandler?: PathConfigurationHandler,
  suggestedBasePaths?: { label: string, path: PathStep[] }[],
  rootPath?: { label: string, path: PathStep[] },
  withSearchInId?: boolean,
}

const useStyles = makeStyles({
  title: {
    marginBottom: spacingRem.xs,
    marginTop: spacingRem.xs,
    display: 'flex',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
}, 'pathMappingAndFiltersInput');

const PathMappingAndFiltersInput: FunctionComponent<PathMappingAndFiltersInputProps> = ({
  path,
  parameterDefinitions = [],
  onChange,
  readOnly,
  valuePathHandler,
  suggestedBasePaths,
  rootPath,
  withSearchInId,
}) => {
  const classes = useStyles();

  const store = useStore();

  const { getErrors, getReturnedConceptDefinitionId } = valuePathHandler ?? createPathConfigurationHandler(store, parameterDefinitions);
  if (getErrors(path)) {
    return null;
  }

  if ((path.length === 2 || path.length === 3) && isGlobalDimensionStep(path[0]) && isFieldStep(path[1])) {
    return (
      <MappingComposite
        path={path}
        parameterDefinitions={parameterDefinitions.filter(isSingleParameterDefinition)}
        onChange={onChange}
        readOnly={readOnly}
        withSearchInId={withSearchInId}
      />
    );
  }

  const filtersPaths = getFiltersPath(path);

  if (!filtersPaths.length) {
    return null;
  }

  return (
    <CompositeField
      width="75rem"
      minWidth="3rem"
      readOnly={readOnly}
      headerLinesRenderers={[
        {
          id: 'title',
          render: () => {
            const titles: string[] = [];

            const firstPathItem = path.at(0);
            const secondPathItem = path.at(1);
            if (isGlobalDimensionStep(firstPathItem) && isFieldStep(secondPathItem)) {
              titles.push(i18n`${Object.values(secondPathItem.mapping ?? {}).filter(filterNullOrUndefined).length} dimensions mapped`);
            }

            const countTotalFilters = filtersPaths.reduce((acc, { path: filterPath }) => {
              const lastStep = filterPath.at(-1);
              if (isFilterStep(lastStep) || isMultipleMappingStep(lastStep)) {
                const returnedConceptDefinitionId = getReturnedConceptDefinitionId(filterPath);
                if (returnedConceptDefinitionId) {
                  const filterCount = countValidFilters(store, lastStep.filters);
                  return acc + filterCount;
                } else {
                  return acc;
                }
              } else {
                return acc;
              }
            }, 0);
            titles.push(i18n`${countTotalFilters} filters applied`);

            return (
              <span className={classes.title}>
                <Icon colorVariant={IconColorVariant.alternative} name={IconName.tune} />
                <Tooltip title={titles.join(', ')}>
                  <Typo maxLine={1}>{titles.join(', ')}</Typo>
                </Tooltip>
              </span>
            );
          },
        },
      ]}
      getDropdownSectionDefinitions={() => ([
        {
          id: 'filterSection',
          lines: [{
            id: 'filterLine',
            render: (
              <DataTable
                list={filtersPaths.map((filterPath, index) => ({ key: index.toString(), type: 'item', item: filterPath, color: undefined }))}
                columnsDefinition={[
                  {
                    propertyId: 'field',
                    name: i18n`Elements to filter`,
                    width: 70,
                    cellRender: ({ path: filterPath }) => (
                      <PathStepsInput
                        initialPath={filterPath}
                        readOnly
                        showEndOnly
                        parameterDefinitions={parameterDefinitions}
                        suggestedBasePaths={suggestedBasePaths}
                        rootPath={rootPath}
                      />
                    ),
                  },
                  {
                    propertyId: 'filters',
                    name: i18n`Filters`,
                    width: 30,
                    cellRender: ({ type, path: filterPath }) => {
                      if (type === 'mapping') {
                        return (
                          <MappingComposite
                            path={filterPath}
                            parameterDefinitions={parameterDefinitions.filter(isSingleParameterDefinition)}
                            onChange={(newPath) => {
                              onChange?.([...newPath, ...path.slice(2)]);
                            }}
                            readOnly={readOnly}
                            withSearchInId={withSearchInId}
                          />
                        );
                      }

                      const returnedConceptDefinitionId = getReturnedConceptDefinitionId(filterPath);
                      if (!returnedConceptDefinitionId) {
                        return null;
                      } else {
                        const viewFilters = ([...filterPath].pop() as FilterStep | MultipleMappingStep).filters;
                        const filtersDefinition: FilterDefinition = {
                          updateFilters: (filters: Filters[]) => {
                            const index = filterPath.length - 1;
                            const newPath = path.map((step, stepIndex) => {
                              if (stepIndex === index && isFilterStep(step)) {
                                return joinObjects(
                                  step,
                                  { filters: filters[0] }
                                );
                              } else if (stepIndex === index && isMultipleMappingStep(step)) {
                                return joinObjects(
                                  step,
                                  { filters: filters[0] }
                                );
                              } else {
                                return step;
                              }
                            });
                            onChange?.(newPath);
                          },
                          definition: [
                            {
                              filter: viewFilters,
                            },
                          ],
                        };

                        return (
                          <FilterComposite
                            readOnly={readOnly}
                            suggestedPaths={[{
                              label: i18n`Current (${formatOrUndef(getConceptDefinitionNameOrEntity(store, returnedConceptDefinitionId))})`,
                              path: [
                                { type: PathStepType.dimension, conceptDefinitionId: returnedConceptDefinitionId },
                                { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
                              ],
                            }]}
                            filtersDefinition={filtersDefinition}
                            parameterDefinitions={[
                              { id: FILTER_PARAMETER_CURRENT, label: i18n`Current`, typeId: returnedConceptDefinitionId, type: 'parameter' },
                              ...parameterDefinitions.filter(isSingleParameterDefinition),
                            ]}
                          />
                        );
                      }
                    },
                  },
                ]}
                fullWidth
              />
            ),
          }],
        },
      ])}
    />
  );
};

export default PathMappingAndFiltersInput;
