import type { ComponentProps, FunctionComponent } from 'react';
import type { IconName } from '../../../components/atoms/Icon';
import IconPicker from '../../../components/inputs/IconPicker';
import StoreInput from './StoreInput';

interface StoreIconPickerProps extends Omit<ComponentProps<typeof IconPicker>, 'value' | 'onChange' | 'onSubmit' | 'onCancel'> {
  initialValue: IconName | undefined,
  onSubmit: (value: IconName | null) => void,
}

const StoreIconPicker: FunctionComponent<StoreIconPickerProps> = ({ initialValue, onSubmit, ...iconPickerProps }) => (
  <StoreInput initialValue={initialValue} onSubmit={onSubmit}>
    {(formInputProps) => (
      <IconPicker
        {...iconPickerProps}
        {...formInputProps}
      />
    )}
  </StoreInput>
);

export default StoreIconPicker;
