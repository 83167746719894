import { Navigate } from 'react-router-dom';
import { useFormulaV2 } from 'yooi-modules';
import StoreObjectNotFoundErrorBoundary from '../../store/StoreObjectNotFoundErrorBoundary';
import i18n from '../../utils/i18n';
import type { LayoutSwitch, Route } from '../../utils/routerTypes';
import { NavigationElementContainer } from '../../utils/useNavigation';
import './_global/displayItemsLibrary/initDisplayItemLibrary';
import AboutPage from './about/AboutPage';
import AdminPermissionPortal from './AdminPermissionPortal';
import AuthorizationPage from './authorization/AuthorizationPage';
import AdminAutomationRuleList from './automation/AdminAutomationRuleList';
import AutomationRuleDetail from './automation/AutomationRuleDetail';
import UserNotificationSettings from './automation/UserNotificationSettings';
import AdminConceptDefinitionLayout from './concept/AdminConceptDefinitionLayout';
import IntentionDetailPage from './concept/detail/collaboration/IntentionDetailPage';
import ConceptDefinitionConfigurationPage from './concept/detail/ConceptDefinitionConfigurationPage';
import FieldBlockDisplayEditorPage from './concept/FieldBlockDisplayEditorPage';
import FieldEditorPage from './concept/FieldEditorPage';
import OrganizationSettingsPage from './concept/OrganizationSettingsPage';
import WorkflowFieldDetailPage from './concept/WorkflowFieldDetailPage';
import AdminCollaborationConfigurationPage from './content/AdminCollaborationConfigurationPage';
import IntentionDetail from './content/IntentionDetail';
import CustomizationPage from './customization/CustomizationPage';
import ExplorerRouter from './explorer/ExplorerRouter';
import FormulaDocumentation from './formula-documentation/FormulaDocumentation';
import FormulaDocumentationV2 from './formula-documentation/FormulaDocumentationV2';
import IntegrationDetailPage from './integration/IntegrationDetailPage';
import IntegrationHome from './integration/IntegrationHome';
import AdminSecurityConfigurationPage from './security/AdminSecurityConfigurationPage';
import SettingsHome from './SettingsHome';
import WorkflowDetailPage from './workflow/WorkflowDetailPage';
import WorkflowListPage from './workflow/WorkflowListPage';

const withPermissionPortal = (route: Route): LayoutSwitch => ({
  layout: ({ children }) => (
    <AdminPermissionPortal>
      {children}
    </AdminPermissionPortal>
  ),
  switch: { '/': route },
});

const SettingsRouter: LayoutSwitch = {
  layout: ({ children }) => (
    <NavigationElementContainer element={{ key: 'settings_admin' }}>
      {children}
    </NavigationElementContainer>
  ),
  switch: {
    '/': () => (<SettingsHome />),
    '/about': withPermissionPortal(() => (<AboutPage />)),
    // Named like a hook but isn't
    '/formula-documentation': withPermissionPortal(() => (useFormulaV2() ? (<FormulaDocumentationV2 />) : (<FormulaDocumentation />))),
    '/automation': withPermissionPortal({
      layout: ({ children }) => (
        <NavigationElementContainer element={{ key: 'automation' }}>
          {children}
        </NavigationElementContainer>
      ),
      switch: {
        '/': () => (<AdminAutomationRuleList />),
        '/:ruleId': ({ ruleId }) => (
          <StoreObjectNotFoundErrorBoundary objectName={i18n`Rule`}>
            <AutomationRuleDetail ruleId={ruleId} navigationElement="automation" />
          </StoreObjectNotFoundErrorBoundary>
        ),
      },
    }),
    '/notification': {
      layout: ({ children }) => (
        <NavigationElementContainer element={{ key: 'notification_settings' }}>
          {children}
        </NavigationElementContainer>
      ),
      switch: {
        '/': () => (<UserNotificationSettings />),
        '/:ruleId': ({ ruleId }) => (
          <StoreObjectNotFoundErrorBoundary objectName={i18n`Rule`}>
            <AutomationRuleDetail ruleId={ruleId} navigationElement="notification_settings" />
          </StoreObjectNotFoundErrorBoundary>
        ),
      },
    },
    '/collaboration': withPermissionPortal({
      switch: {
        '/': () => (<AdminCollaborationConfigurationPage />),
        '/:intentionId': ({ intentionId }) => (
          <StoreObjectNotFoundErrorBoundary objectName={i18n`Collaboration`}>
            <IntentionDetail intentionId={intentionId} navigationElement="collaboration" />
          </StoreObjectNotFoundErrorBoundary>
        ),
      },
    }),
    '/organization': withPermissionPortal({
      layout: ({ children }) => (
        <NavigationElementContainer element={{ key: 'organization_settings' }}>
          {children}
        </NavigationElementContainer>
      ),
      switch: {
        '/:conceptDefinitionId': {
          layout: ({ children, parameters: { conceptDefinitionId } }) => (
            <StoreObjectNotFoundErrorBoundary objectName={i18n`Concept`}>
              <AdminConceptDefinitionLayout conceptDefinitionId={conceptDefinitionId}>
                {children}
              </AdminConceptDefinitionLayout>
            </StoreObjectNotFoundErrorBoundary>
          ),
          switch: {
            '/field/:fieldId': ({ conceptDefinitionId, fieldId }) => (
              <StoreObjectNotFoundErrorBoundary objectName={i18n`Field`}>
                <FieldEditorPage conceptDefinitionId={conceptDefinitionId} fieldId={fieldId} />
              </StoreObjectNotFoundErrorBoundary>
            ),
            '/fieldBlockDisplay/:fieldBlockDisplayId': ({ conceptDefinitionId, fieldBlockDisplayId }) => (
              <StoreObjectNotFoundErrorBoundary objectName={i18n`Block`}>
                <FieldBlockDisplayEditorPage conceptDefinitionId={conceptDefinitionId} fieldBlockDisplayId={fieldBlockDisplayId} />
              </StoreObjectNotFoundErrorBoundary>
            ),
            '/workflow/:fieldId': ({ conceptDefinitionId, fieldId }) => (
              <StoreObjectNotFoundErrorBoundary objectName={i18n`Workflow`}>
                <WorkflowFieldDetailPage conceptDefinitionId={conceptDefinitionId} fieldId={fieldId} />
              </StoreObjectNotFoundErrorBoundary>
            ),
            '/intention/:intentionId': ({ conceptDefinitionId, intentionId }) => (
              <StoreObjectNotFoundErrorBoundary objectName={i18n`Intention`}>
                <IntentionDetailPage conceptDefinitionId={conceptDefinitionId} intentionId={intentionId} />
              </StoreObjectNotFoundErrorBoundary>
            ),
            '/': ({ conceptDefinitionId }) => (
              <ConceptDefinitionConfigurationPage conceptDefinitionId={conceptDefinitionId} />
            ),
          },
        },
        '/': () => (<OrganizationSettingsPage />),
      },
    }),
    '/customization': withPermissionPortal(() => (<CustomizationPage />)),
    '/explorer': withPermissionPortal(ExplorerRouter),
    '/integration': withPermissionPortal({
      layout: ({ children }) => (
        <NavigationElementContainer element={{ key: 'integration' }}>
          {children}
        </NavigationElementContainer>
      ),
      switch: {
        '/:integrationId': ({ integrationId }) => (
          <StoreObjectNotFoundErrorBoundary objectName={i18n`Integration`}>
            <IntegrationDetailPage integrationId={integrationId} />
          </StoreObjectNotFoundErrorBoundary>
        ),
        '/': () => (<IntegrationHome />),
      },
    }),
    '/security': withPermissionPortal(() => (<AdminSecurityConfigurationPage />)),
    '/authorization': withPermissionPortal(() => (<AuthorizationPage />)),
    '/workflow': withPermissionPortal({
      layout: ({ children }) => (
        <NavigationElementContainer element={{ key: 'workflow' }}>
          {children}
        </NavigationElementContainer>
      ),
      switch: {
        '/:workflowId': ({ workflowId }) => (<WorkflowDetailPage workflowId={workflowId} />),
        '/': () => (<WorkflowListPage />),
      },
    }),
    // Keep backward compatibility
    '/notifications': withPermissionPortal({
      switch: {
        '/': '/settings/automation',
        '/rule/:ruleId': ({ ruleId }) => (<Navigate to={`/settings/automation/${ruleId}`} replace />),
      },
    }),
    '/user': '/settings/authorization',
  },
};

export default SettingsRouter;
