import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { v4 as uuid } from 'uuid';
import declareBus from './declareBus';

export interface TooltipConfig {
  element: HTMLElement | SVGSVGElement,
  text: string | (() => Promise<string>),
  prefix?: string,
}

type TooltipListener = (callerId: string, tooltipConfig: TooltipConfig | undefined) => void;

interface TooltipManager {
  displayTooltip: (tooltipConfig: TooltipConfig) => void,
  hideTooltip: () => void,
}

const useTooltipBus = declareBus<{ callerId: string, tooltipConfig: TooltipConfig | undefined }>('tooltip').useBus;

export const useTooltip = (listener?: TooltipListener): TooltipManager => {
  const callerIdRef = useRef(uuid());
  const onUnsubscribe = useCallback(() => listener?.(callerIdRef.current, undefined), [listener]);

  const sendEvent = useTooltipBus(
    listener ? (event) => listener(event.callerId, event.tooltipConfig) : undefined,
    undefined,
    onUnsubscribe
  );

  // Ensure tooltip is unmount when destroying component
  useEffect(() => () => sendEvent({ callerId: callerIdRef.current, tooltipConfig: undefined }), [sendEvent]);

  const debouncedSendEvent = useDebouncedCallback((tooltipConfig: TooltipConfig | undefined) => sendEvent({ callerId: callerIdRef.current, tooltipConfig }), 25);

  return useMemo(() => ({
    displayTooltip: (tooltipConfig) => debouncedSendEvent(tooltipConfig),
    hideTooltip: () => debouncedSendEvent(undefined),
  }), [debouncedSendEvent]);
};
