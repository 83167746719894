import type { ReactElement } from 'react';
import { useState } from 'react';
import { hasPlatformCapability } from 'yooi-modules/modules/conceptModule';
import { PlatformCapabilityAdmin, User, User_IsEnabled, User_Type } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { IconName } from '../../../components/atoms/Icon';
import type { MenuItem } from '../../../components/molecules/Menu';
import type { FrontObjectStore } from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import ChangePasswordModal from './ChangePasswordModal';

interface UseAdminMenuItemsResult {
  passwordModal: ReactElement | null,
  buildAdminMenuItems: (id: string) => MenuItem[],
}

const useAdminMenuItems = (store: FrontObjectStore, connectedUserId: string): UseAdminMenuItemsResult => {
  const [openPasswordEditionModal, setOpenPasswordEditionModal] = useState<string | undefined>(undefined);

  return {
    passwordModal: openPasswordEditionModal ? (
      <ChangePasswordModal userId={openPasswordEditionModal} onClose={() => setOpenPasswordEditionModal(undefined)} />
    ) : null,
    buildAdminMenuItems: (conceptId) => {
      const user = store.getObject(conceptId);
      if (isInstanceOf(user, User)) {
        const connectedUser = store.getObject(connectedUserId);
        const connectedUserIsAdmin = hasPlatformCapability(store, connectedUserId, PlatformCapabilityAdmin);

        const menuItems: MenuItem[] = [];
        if (user[User_Type] === 'internal' && (connectedUserIsAdmin || connectedUser.id === user.id)) {
          menuItems.push({
            key: 'password',
            name: i18n`Change password`,
            icon: IconName.password,
            hidden: false,
            onClick: () => setOpenPasswordEditionModal(conceptId),
          });
        }
        if (connectedUserIsAdmin) {
          if (user[User_IsEnabled]) {
            menuItems.push({
              key: 'disable',
              name: i18n`Disable access`,
              icon: IconName.key_off,
              onClick: () => {
                store.updateObject(conceptId, { [User_IsEnabled]: false });
              },
              hidden: false,
            });
          } else {
            menuItems.push({
              key: 'enable',
              name: i18n`Enable access`,
              icon: IconName.key,
              onClick: () => {
                store.updateObject(conceptId, { [User_IsEnabled]: true });
              },
              hidden: false,
            });
          }
        }

        return menuItems;
      } else {
        return [];
      }
    },
  };
};

export default useAdminMenuItems;
