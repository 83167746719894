import type { FunctionComponent } from 'react';
import { useState } from 'react';
import type { ConceptDefinitionLibraryTableRaw, ConceptDefinitionLibraryTableStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import {
  ConceptDefinitionLibraryTable,
  ConceptDefinitionLibraryTable_HideOnCard,
  ConceptDefinitionLibraryTable_Rank,
  ConceptDefinitionLibraryTable_Role_ConceptDefinition,
  ConceptDefinitionLibraryTable_Role_Field,
  ConceptDefinitionLibraryTable_Width,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import { getConceptDefinitionValidFields, isConceptDefinitionField } from 'yooi-modules/modules/conceptModule';
import { Field_Title } from 'yooi-modules/modules/conceptModule/ids';
import { compareProperty, compareRank, isFiniteNumber, ranker } from 'yooi-utils';
import { ButtonVariant } from '../../../../../components/atoms/Button';
import Checkbox from '../../../../../components/atoms/Checkbox';
import { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import NumberPicker from '../../../../../components/inputs/NumberPicker';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import TableCell, { TableCellAlign } from '../../../../../components/molecules/TableCell';
import TableLine from '../../../../../components/molecules/TableLine';
import DataTable from '../../../../../components/templates/DataTable';
import useStore from '../../../../../store/useStore';
import i18n from '../../../../../utils/i18n';
import { getFieldHandler } from '../../../../_global/fields/FieldLibrary';
import { getFieldChip } from '../../../../_global/fieldUtils';
import { defaultOptionComparator } from '../../../../_global/modelTypeUtils';

interface DataLibraryViewEditorProps {
  conceptDefinitionId: string,
}

const DataLibraryViewEditor: FunctionComponent<DataLibraryViewEditorProps> = ({ conceptDefinitionId }) => {
  const store = useStore();

  const [showLine, setShowLine] = useState(false);

  const fields = ranker.decorateList(
    store.withAssociation(ConceptDefinitionLibraryTable)
      .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
      .list<ConceptDefinitionLibraryTableStoreObject>()
      .map((conceptDefinitionLibraryTable) => ({
        id: conceptDefinitionLibraryTable.role(ConceptDefinitionLibraryTable_Role_Field),
        rank: conceptDefinitionLibraryTable.object[ConceptDefinitionLibraryTable_Rank],
      }))
      .sort(compareProperty('rank', compareRank)),
    (field) => field.rank
  );

  const selectableFields = () => getConceptDefinitionValidFields(store, conceptDefinitionId)
    .filter((field) => !fields.some(({ item: { id } }) => id === field.id))
    .filter((field) => getFieldHandler(store, field.id)?.getColumnDefinition && getFieldHandler(store, field.id)?.renderField)
    .map((field) => getFieldChip(store, conceptDefinitionId, field.id))
    .sort(defaultOptionComparator);

  const moveField = (fieldId: string, rank: string) => {
    store.withAssociation(ConceptDefinitionLibraryTable)
      .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
      .withRole(ConceptDefinitionLibraryTable_Role_Field, fieldId)
      .updateObject<ConceptDefinitionLibraryTableRaw>({ [ConceptDefinitionLibraryTable_Rank]: rank });
  };
  const moveFieldUp = (fieldId: string) => {
    const fieldToInsertBefore = fields[fields.findIndex(({ item }) => item.id === fieldId) - 1];
    if (fieldToInsertBefore) {
      moveField(fieldId, fieldToInsertBefore.insertBeforeRank());
    }
  };
  const moveFieldDown = (fieldId: string) => {
    const fieldToInsertAfter = fields[fields.findIndex(({ item }) => item.id === fieldId) + 1];
    if (fieldToInsertAfter) {
      moveField(fieldId, fieldToInsertAfter.insertAfterRank());
    }
  };

  return (
    <DataTable
      list={fields.map(({ item }) => ({ key: item.id, type: 'item', item, color: undefined }))}
      columnsDefinition={[
        {
          name: i18n`Name`,
          propertyId: Field_Title,
          cellRender: ({ id }) => (
            <SearchAndSelect
              readOnly
              selectedOption={getFieldChip(store, conceptDefinitionId, id)}
              statusIcon={(() => {
                if (!isConceptDefinitionField(store, id, conceptDefinitionId)) {
                  return { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not belong to the current concept` };
                }
                if (!getFieldHandler(store, id)?.getColumnDefinition || !getFieldHandler(store, id)?.renderField) {
                  return { icon: IconName.dangerous, color: IconColorVariant.error, message: i18n`Field does not have table/card display representation` };
                }
                return undefined;
              })()}
            />
          ),
        },
        {
          name: i18n`Width %`,
          propertyId: ConceptDefinitionLibraryTable_Width,
          width: 10,
          cellRender: ({ id }) => (
            <NumberPicker
              value={store.withAssociation(ConceptDefinitionLibraryTable)
                .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
                .withRole(ConceptDefinitionLibraryTable_Role_Field, id)
                .getObject<ConceptDefinitionLibraryTableStoreObject>()[ConceptDefinitionLibraryTable_Width]}
              onChange={(value) => store.withAssociation(ConceptDefinitionLibraryTable)
                .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
                .withRole(ConceptDefinitionLibraryTable_Role_Field, id)
                .updateObject<ConceptDefinitionLibraryTableRaw>({ [ConceptDefinitionLibraryTable_Width]: !isFiniteNumber(value) ? null : Number(value) })}
              decimals={0}
              min={0}
              unit="%"
            />
          ),
        },
        {
          name: i18n`Hide on card`,
          propertyId: ConceptDefinitionLibraryTable_HideOnCard,
          width: 10,
          cellRender: ({ id }) => (
            <Checkbox
              checked={Boolean(store.withAssociation(ConceptDefinitionLibraryTable)
                .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
                .withRole(ConceptDefinitionLibraryTable_Role_Field, id)
                .getObject<ConceptDefinitionLibraryTableStoreObject>()[ConceptDefinitionLibraryTable_HideOnCard])}
              onChange={(value) => store.withAssociation(ConceptDefinitionLibraryTable)
                .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
                .withRole(ConceptDefinitionLibraryTable_Role_Field, id)
                .updateObject<ConceptDefinitionLibraryTableRaw>({ [ConceptDefinitionLibraryTable_HideOnCard]: value })}
            />
          ),
        },
        {
          name: '',
          propertyId: 'move-up',
          action: true,
          align: TableCellAlign.center,
          cellRender: ({ id }, _, index) => (
            <IconOnlyButton
              onClick={() => moveFieldUp(id)}
              disabled={index === 0}
              iconName={IconName.expand_less}
              tooltip={i18n`Move Up`}
              variant={IconOnlyButtonVariants.tertiary}
            />
          ),
        },
        {
          name: '',
          propertyId: 'move-down',
          action: true,
          align: TableCellAlign.center,
          cellRender: ({ id }, _, index) => (
            <IconOnlyButton
              onClick={() => moveFieldDown(id)}
              disabled={index === fields.length - 1}
              iconName={IconName.expand_more}
              tooltip={i18n`Move Down`}
              variant={IconOnlyButtonVariants.tertiary}
            />
          ),
        },
      ]}
      linesActions={(line) => [
        {
          key: 'unlink',
          name: i18n`Unlink field`,
          icon: IconName.delete,
          danger: true,
          onClick: () => {
            store.withAssociation(ConceptDefinitionLibraryTable)
              .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
              .withRole(ConceptDefinitionLibraryTable_Role_Field, line.id)
              .deleteObject();
          },
        },
      ]}
      inlineCreation={{
        render: showLine ? (
          <TableLine>
            <TableCell>
              <SearchAndSelect
                computeOptions={selectableFields}
                editOnMount
                onSelect={(value) => {
                  store.withAssociation(ConceptDefinitionLibraryTable)
                    .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
                    .withRole(ConceptDefinitionLibraryTable_Role_Field, value?.id as string)
                    .updateObject<ConceptDefinitionLibraryTableRaw>({ [ConceptDefinitionLibraryTable_Rank]: fields.insertAfterLastItemRank() });
                  setShowLine(false);
                }}
                onEscape={() => setShowLine(false)}
              />
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
          </TableLine>
        ) : null,
      }}
      onNewItem={() => setShowLine(true)}
      newItemIcon={IconName.add}
      newItemTitle={i18n`Add`}
      newItemButtonVariant={ButtonVariant.tertiary}
      handleClickAway={() => setShowLine(false)}
    />
  );
};

export default DataLibraryViewEditor;
