export const computePosition = (
  viewportWidth: number,
  viewportHeight: number,
  contentX: number,
  contentY: number,
  contentWidth: number,
  contentHeight: number,
  offset: number,
  tooltipWidth: number,
  tooltipHeight: number
): { tooltipX: number, tooltipY: number } => {
  let tooltipX = contentX + (contentWidth / 2) - (tooltipWidth / 2);
  if (tooltipX < 0) {
    // If the x position is below 0, then move it to 0
    tooltipX = 0;
  } else if ((tooltipX + tooltipWidth) > viewportWidth) {
    // if the tooltip x position + width exceed max X, then move if to limit
    tooltipX = viewportWidth - tooltipWidth;
  }

  let tooltipY = contentY + contentHeight + offset;
  if ((tooltipY + tooltipHeight) > viewportHeight) {
    // if the tooltip y position + height exceed max Y, then put the tooltip above the element
    tooltipY = contentY - offset - tooltipHeight;
  }

  return { tooltipX, tooltipY };
};
