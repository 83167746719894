import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import { joinObjects } from 'yooi-utils';
import base from '../../theme/base';
import { getMostReadableColorFromBackgroundColor } from '../../theme/colorUtils';
import { buildPadding, getSpacing, getSpacingAsNumber, Spacing, spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import { getTextMeasurerForVariant } from '../../utils/textUtils';
import useForceUpdate from '../../utils/useForceUpdate';
import useHideOnDisappearRef from '../../utils/useHideOnDisappearRef';
import type { NavigationPayload } from '../../utils/useNavigation';
import useScrollOnMountRef from '../../utils/useScrollOnMountRef';
import { SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import Button, { ButtonVariant } from '../atoms/Button';
import Icon, { IconColorVariant, IconName } from '../atoms/Icon';
import Typo, { TypoVariant } from '../atoms/Typo';
import InlineLink from './InlineLink';
import Overlay from './Overlay';
import { tableSelectorsClasses } from './Table';

const useStyles = makeStyles((theme) => ({
  border: {
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.default,
  },
  noBorder: {
    border: 'none',
  },
  base: {
    position: 'relative',
    backgroundClip: 'padding-box',
    padding: '0',
  },
  action: {
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    width: '1%',
  },
  navigationButtonContainer: {
    position: 'absolute',
    right: '1rem',
    top: '50%',
    transform: 'translate(0%, -50%)',
    visibility: 'hidden',
  },
  highlightContainer: joinObjects(
    {
      display: 'flex',
      gap: getSpacing(Spacing.xs),
    },
    buildPadding({ y: Spacing.xxs, x: Spacing.xs }),
    {
      borderRadius: `0 0 ${base.borderRadius.medium} ${base.borderRadius.medium}`,
    }
  ),
}), 'tableCell');

export enum TableCellAlign {
  left = 'left',
  center = 'center',
  right = 'right',
}

export enum TableCellVAlign {
  top = 'top',
}

export interface CellHighlight {
  color: string,
  icon: IconName,
  message: string,
}

interface TableCellProps {
  rowSpan?: number,
  colSpan?: number,
  align?: TableCellAlign,
  valign?: TableCellVAlign,
  width?: string,
  maxWidth?: string,
  navigationPayload?: NavigationPayload,
  afterOpen?: () => void,
  children?: ReactNode,
  noSeparator?: boolean,
  background?: string,
  action?: boolean,
  scrollOnMount?: boolean,
  noBorder?: boolean,
  borderColor?: { top?: string, right?: string, bottom?: string, left?: string },
  message?: CellHighlight,
}

const TableCell: FunctionComponent<TableCellProps> = ({
  rowSpan,
  colSpan,
  align,
  valign,
  width,
  maxWidth,
  navigationPayload,
  afterOpen,
  children,
  noSeparator = false,
  background,
  action = false,
  scrollOnMount = false,
  noBorder = false,
  borderColor,
  message,
}) => {
  const classes = useStyles();

  const ref = useRef<HTMLTableDataCellElement | null>(null);
  const forceUpdate = useForceUpdate();
  const scrollOnMountRef = useScrollOnMountRef(scrollOnMount);
  const { hideRef, monitorRef } = useHideOnDisappearRef<HTMLDivElement>(true, false);

  const textMeasurer = getTextMeasurerForVariant(TypoVariant.small);
  const textSize = textMeasurer(message?.message);

  useEffect(() => {
    if (ref.current && message) {
      forceUpdate();
    }
  }, [message, forceUpdate]);

  return (
    <>
      <td
        className={classnames({
          [classes.base]: true,
          [classes.border]: !noSeparator,
          [classes.action]: action,
          [classes.noBorder]: noBorder,
        })}
        colSpan={colSpan}
        rowSpan={rowSpan}
        align={align}
        valign={valign}
        style={{
          backgroundColor: background,
          width,
          maxWidth,
          height: `calc(${rowSpan ?? 1} * 3.9rem`,
          borderLeftColor: borderColor?.left,
          borderTopColor: borderColor?.top,
          borderRightColor: borderColor?.right,
          borderBottomColor: borderColor?.bottom,
        }}
        ref={composeReactRefs<HTMLTableDataCellElement>(scrollOnMountRef, ref, monitorRef)}
      >
        {children}
        {navigationPayload && (
          <div
            className={classnames(classes.navigationButtonContainer, tableSelectorsClasses.navigationButton)}
          >
            <InlineLink onClick={afterOpen} to={navigationPayload?.to} state={navigationPayload?.state} noStyle>
              <Button
                variant={ButtonVariant.primary}
                iconName={IconName.output}
                title={i18n`Open`}
                tooltip={i18n`Open as page`}
                iconColor={IconColorVariant.light}
              />
            </InlineLink>
          </div>
        )}
      </td>
      {ref.current && message && (
        <Overlay
          target={ref.current}
          isBoundary={false}
          onBackdropClick={() => {}}
          placement="bottom-end"
          offset={[-1 * remToPx(getSpacingAsNumber(Spacing.s)), -1]}
          zIndex={0} // We want to be bellow inputs Overlay
        >
          <SizeContextProvider sizeVariant={SizeVariant.small}>
            <div
              className={classes.highlightContainer}
              style={{ backgroundColor: message.color }}
              ref={hideRef}
            >
              {textSize > ref.current?.getBoundingClientRect().width ? (
                <Icon tooltip={message.message} name={message.icon} color={getMostReadableColorFromBackgroundColor(message.color)} />
              ) : (
                <>
                  <Icon tooltip={message.message} name={message.icon} color={getMostReadableColorFromBackgroundColor(message.color)} />
                  <Typo maxLine={1} noWrap color={getMostReadableColorFromBackgroundColor(message.color)}>{message.message}</Typo>
                </>
              )}
            </div>
          </SizeContextProvider>
        </Overlay>
      )}
    </>
  );
};

export default TableCell;
