import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { JssStyle } from 'jss';
import type { MouseEventHandler } from 'react';
import { forwardRef } from 'react';
import { joinObjects } from 'yooi-utils';
import base from '../../theme/base';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import useSizeContext, { buildComponentSizeVariantClasses, SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import useTooltipRef from '../../utils/useTooltipRef';
import type { IconColorVariant, IconName } from './Icon';
import Icon from './Icon';
import type { IconOnlyButtonVariants } from './IconOnlyButton';
import Typo, { TypoVariant } from './Typo';

export enum ButtonVariant {
  primary = 'primary',
  danger = 'danger',
  secondary = 'secondary',
  tertiary = 'tertiary',
  link = 'link',
}

export const isButtonVariant = (variant: ButtonVariant | IconOnlyButtonVariants): variant is ButtonVariant => variant in ButtonVariant;

const buildVariantStyles = <VariantName extends ButtonVariant>(
  { variantName, normal, hover, focus = {}, pressed = {} }: { variantName: VariantName, normal: JssStyle, hover: JssStyle, focus?: JssStyle, pressed?: JssStyle }
): Record<VariantName | `${VariantName}_hover` | `${VariantName}_focus` | `${VariantName}_pressed`, JssStyle> => {
  const styles: JssStyle = joinObjects(
    {
      '&:hover': hover,
      '&:focus-visible': focus,
      '&:active': pressed,
    },
    normal
  );

  return {
    [variantName]: styles,
    [`${variantName}_hover`]: joinObjects(styles, hover),
    [`${variantName}_focus`]: joinObjects(styles, focus),
    [`${variantName}_pressed`]: joinObjects(styles, pressed),
  } as Record<VariantName | `${VariantName}_hover` | `${VariantName}_focus` | `${VariantName}_pressed`, JssStyle>;
};

const useStyles = makeStyles((theme) => ({
  base: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    outline: 0,
    borderRadius: base.borderRadius.medium,
    boxShadow: base.button.boxShadow,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.button,
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'auto',
    },
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    width: '-moz-fit-content',
    '&:active': {
      boxShadow: base.button.shadow.pressed,
    },
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacingRem.s,
  },
  ...buildVariantStyles({
    variantName: ButtonVariant.primary,
    normal: {
      backgroundColor: theme.color.background.primary.default,
      color: theme.color.text.white,

      '&:disabled': {
        opacity: base.opacity.twenty,
      },
    },
    hover: {
      backgroundColor: theme.color.background.primary.hover,
    },
    focus: {
      backgroundColor: theme.color.background.primary.pressed,
    },
    pressed: {
      backgroundColor: theme.color.background.primary.pressed,
      boxShadow: base.button.shadow.pressed,
    },
  }),
  ...buildVariantStyles({
    variantName: ButtonVariant.secondary,
    normal: {
      color: theme.color.text.primary,
      backgroundColor: theme.color.background.neutral.default,
      '&:disabled': {
        opacity: base.opacity.twenty,
      },
    },
    hover: {
      backgroundColor: theme.color.background.neutral.subtle,
    },
    focus: {
      backgroundColor: theme.color.background.neutral.muted,
    },
    pressed: {
      backgroundColor: theme.color.background.neutral.muted,
      boxShadow: base.button.shadow.pressed,
    },
  }),
  ...buildVariantStyles({
    variantName: ButtonVariant.danger,
    normal: {
      backgroundColor: theme.color.background.neutral.default,
      color: theme.color.text.danger,
      '&:disabled': {
        opacity: base.opacity.twenty,
      },
    },
    hover: {
      backgroundColor: theme.color.background.danger.hover,
      color: theme.color.text.white,
    },
    focus: {
      backgroundColor: theme.color.background.danger.pressed,
      color: theme.color.text.white,
    },
    pressed: {
      backgroundColor: theme.color.background.danger.pressed,
      color: theme.color.text.white,
      boxShadow: base.button.shadow.pressed,
    },
  }),
  ...buildVariantStyles({
    variantName: ButtonVariant.tertiary,
    normal: {
      borderColor: theme.color.transparent,
      backgroundColor: theme.color.transparent,
      boxShadow: 'none',
      color: theme.color.text.brand,

      '&:disabled': {
        opacity: base.opacity.twenty,
      },
    },
    hover: {
      backgroundColor: theme.color.background.primarylight.default,
    },
    focus: {
      backgroundColor: theme.color.background.primarylight.hover,
    },
    pressed: {
      backgroundColor: theme.color.background.primarylight.hover,
      boxShadow: 'none',
    },
  }),
  ...buildVariantStyles({
    variantName: ButtonVariant.link,
    normal: {
      padding: 0,
      border: 'none',
      backgroundColor: theme.color.transparent,
      boxShadow: 'none',
      color: theme.color.link.default,
      textDecoration: 'none',
      cursor: 'pointer',

      '&:disabled': {
        color: theme.color.link.disabled,
      },
    },
    hover: {
      textDecoration: 'underline',
      boxShadow: 'none',
    },
    focus: {
      textDecoration: 'underline',
      boxShadow: 'none',
    },
    pressed: {
      textDecoration: 'underline',
      color: theme.color.link.pressed,
      boxShadow: 'none',
    },
  }),
  ...buildComponentSizeVariantClasses('button', ['height']),
}), 'button');

interface ButtonProps {
  title: string,
  tooltip?: string,
  iconName?: IconName,
  variant?: ButtonVariant,
  sizeVariant?: SizeVariant,
  /**
   * The state option is only used to showcase the different states in the Design System storybook. It's not supposed to be used in the production code.
   */
  state?: 'hover' | 'pressed',
  disabled?: boolean,
  onClick?: MouseEventHandler<HTMLButtonElement>,
  iconColor?: IconColorVariant,
  maxLine?: number,
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((
  { title, tooltip, iconName, variant = ButtonVariant.primary, sizeVariant, state, disabled = false, onClick, iconColor, maxLine },
  ref
) => {
  const classes = useStyles();

  const tooltipRef = useTooltipRef<HTMLButtonElement>(tooltip);

  const { sizeVariant: contextSizeVariant, hierarchyVariant } = useSizeContext();
  const sizeContextComputed = sizeVariant ?? contextSizeVariant ?? SizeVariant.main;
  let variantClassName: `${ButtonVariant}` | `${ButtonVariant}_hover` | `${ButtonVariant}_pressed`;
  switch (state) {
    case 'hover':
      variantClassName = `${variant}_hover`;
      break;
    case 'pressed':
      variantClassName = `${variant}_pressed`;
      break;
    default:
      variantClassName = variant;
  }

  return (
    <button
      className={classnames(classes.base, classes[variantClassName], classes[`button_${sizeContextComputed}_${hierarchyVariant}`])}
      type="button"
      disabled={disabled}
      ref={composeReactRefs(ref, tooltipRef)}
      onKeyDown={(e) => {
        if (onClick && e.key !== 'Escape') {
          e.stopPropagation();
        }
      }}
      onKeyUp={(e) => {
        if (onClick) {
          e.stopPropagation();
        }
      }}
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
          onClick(e);
        }
      }}
    >
      {iconName ? (
        <div className={classes.iconContainer}>
          <SizeContextProvider sizeVariant={sizeContextComputed}>
            <Icon name={iconName} colorVariant={iconColor} />
          </SizeContextProvider>
        </div>
      ) : null}
      <Typo variant={sizeContextComputed === SizeVariant.small ? TypoVariant.buttonSmall : TypoVariant.buttonMain} maxLine={maxLine}>{title}</Typo>
    </button>
  );
});

export default Button;
