import type { PropertyMap } from 'yooi-utils';
import { isArrayOfType, isObjectOfType, isString } from 'yooi-utils';
import type { PathStep } from '../conceptModule';

export enum InputType {
  users = 'users',
  query = 'query',
  template = 'template',
}

export interface AutomationAction {
  id: string,
  rule: string,
  aclContext: string,
}

export interface ActionInputProperty {
  propertyId: string,
  name: string,
  type: InputType,
}

interface Operation {
  action: string,
  payload?: unknown,
}

const OperationPropertyMap: PropertyMap<Operation> = {
  action: { validator: isString },
  payload: { validator: (_): _ is unknown => true, isOptional: true },
};

export interface FieldOperation {
  id: string,
  fieldId?: string,
  label?: string,
  operation?: Operation,
}

const FieldOperationPropertyMap: PropertyMap<FieldOperation> = {
  id: { validator: isString },
  fieldId: { validator: isString, isOptional: true },
  label: { validator: isString, isOptional: true },
  operation: { validator: isObjectOfType(OperationPropertyMap), isOptional: true },
};

export interface InstanceOperation {
  id: string,
  type: 'create' | 'update',
  path: PathStep[],
  fieldOperations?: FieldOperation[],
}

export const InstanceOperationPropertyMap: PropertyMap<InstanceOperation> = {
  id: { validator: isString },
  type: { validator: (o: unknown): o is 'create' | 'update' => o === 'create' || o === 'update' },
  path: { validator: isArrayOfType((_): _ is PathStep => true) },
  fieldOperations: { validator: isArrayOfType(isObjectOfType(FieldOperationPropertyMap)), isOptional: true },
};

export type AutomationActionQueryRecord = Record<string, PathStep[]>;

export type AutomationRuleParameters = Record<string, { path: PathStep[] }>;

export interface AutomationEmailActionPathRecipient {
  id: string,
  type: 'path',
  value: PathStep[],
}

export interface AutomationEmailActionEmailRecipient {
  id: string,
  type: 'email',
  value?: string,
}

export type AutomationEmailActionRecipient = AutomationEmailActionPathRecipient | AutomationEmailActionEmailRecipient;

export const isPathRecipient = (recipient: AutomationEmailActionRecipient | undefined): recipient is AutomationEmailActionPathRecipient => recipient?.type === 'path';

export const isEmailRecipient = (recipient: AutomationEmailActionRecipient | undefined): recipient is AutomationEmailActionEmailRecipient => recipient?.type === 'email';
