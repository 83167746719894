import type { FunctionComponent } from 'react';
import type { LinkProps as RouterLinkProps } from 'react-router-dom';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import type { IconName } from '../atoms/Icon';
import Icon from '../atoms/Icon';
import Typo from '../atoms/Typo';
import InlineLink from './InlineLink';
import SpacingLine from './SpacingLine';

const useStyles = makeStyles((theme) => ({
  base: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: spacingRem.xs,
    backgroundColor: theme.color.transparent,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
  },
}), 'link');

interface LinkProps {
  title: string,
  to: string | { pathname: string, search?: string, hash?: string, state?: unknown } | RouterLinkProps['to'],
  iconName?: IconName,
  openInNewTab?: boolean,
  maxLine?: number,
  replace?: boolean,
  state?: Record<string, unknown>,
  isUserControlled?: boolean,
  withoutSpacingLines?: boolean,
}

/**
 * The link is available is different variants to address different use cases:
 * <ul>
 *   <li>Navigation inside the application using relative or absolute path (without http(s))</li>
 *   <li>Navigation to an external website (using http(s))</li>
 * </ul>
 */
const Link: FunctionComponent<LinkProps> = ({
  title,
  to,
  iconName,
  openInNewTab = false,
  maxLine,
  replace = false, state,
  isUserControlled = false,
  withoutSpacingLines = false,
}) => {
  const classes = useStyles();

  const render = (
    <InlineLink
      to={to}
      openInNewTab={openInNewTab}
      replace={replace}
      state={state}
      isUserControlled={isUserControlled}
    >
      <div className={classes.base}>
        {iconName ? (<Icon name={iconName} />) : null}
        <Typo maxLine={maxLine}>{title}</Typo>
      </div>
    </InlineLink>
  );

  return (
    withoutSpacingLines ? render : (
      <SpacingLine>
        {render}
      </SpacingLine>
    )
  );
};

export default Link;
