import classnames from 'classnames';
import type { ReactElement } from 'react';
import type { NumberColorStepValue, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { NumberColorStepValueType } from 'yooi-modules/modules/conceptModule';
import { NumberField } from 'yooi-modules/modules/conceptModule/ids';
import { isFiniteNumber, newError } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { getColorPalette } from '../../../utils/standardColorsUtils';
import StoreColorPickerInput from '../../input/StoreColorPickerInput';
import StoreNumberPickerInput from '../../input/StoreNumberPickerInput';
import PathInput from '../../path/PathInput';
import { createPathConfigurationHandler } from '../../pathConfigurationHandler';
import { getFieldTypeValidator } from '../../pathConfigurationHandlerUtils';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    columnGap: spacingRem.s,
    alignItems: 'center',
  },
  gridWithInfoAndDelete: {
    gridTemplateColumns: '5.8rem 9rem 1fr 2.6rem 2.6rem',
  },
  gridWithDelete: {
    gridTemplateColumns: '5.8rem 9rem 1fr 2.6rem',
  },
}, 'numberColorStepInput');

interface NumberColorStepInputProps<Value extends NumberColorStepValue> {
  value: Value,
  onChange: (value: Value) => void,
  onDelete?: () => void,
  parameterDefinitions: SingleParameterDefinition[],
  readOnly: boolean,
  info?: string,
}

const generateOptions = (type: NumberColorStepValueType): { id: NumberColorStepValueType, label: string } => {
  if (type === NumberColorStepValueType.value) {
    return { id: NumberColorStepValueType.value, label: i18n`Value` };
  } else {
    return { id: NumberColorStepValueType.field, label: i18n`Field` };
  }
};

const NumberColorStepInput = <Value extends NumberColorStepValue = NumberColorStepValue>(
  { value, onChange, onDelete, parameterDefinitions, readOnly, info }: NumberColorStepInputProps<Value>
): ReactElement | null => {
  const classes = useStyles();

  const store = useStore();

  const renderEditor = () => {
    switch (value.type) {
      case NumberColorStepValueType.value: {
        return (
          <StoreNumberPickerInput
            initialValue={value.value}
            onSubmit={(newStep) => {
              if (newStep !== null && !isFiniteNumber(newStep)) {
                return;
              }
              const newValue = { ...value };
              newValue.type = NumberColorStepValueType.value;
              newValue.value = typeof newStep === 'string' ? Number(newStep) : (newStep ?? undefined);
              onChange(newValue);
            }}
            placeholder={i18n`Add value`}
            readOnly={readOnly}
          />
        );
      }
      case NumberColorStepValueType.field: {
        const valuePathHandler = createPathConfigurationHandler(
          store,
          parameterDefinitions ?? [],
          [getFieldTypeValidator(store, [NumberField], i18n`Input should end with a number.`)]
        );

        return (
          <PathInput
            path={Array.isArray(value.value) ? value.value : []}
            valuePathHandler={valuePathHandler}
            readOnly={readOnly}
            parameterDefinitions={parameterDefinitions}
            onChange={(newPath) => {
              const newValue = { ...value };
              newValue.type = NumberColorStepValueType.field;
              newValue.value = newPath;
              onChange(newValue);
            }}
            placeholder={i18n`Select field`}
          />
        );
      }
      default:
        throw newError('Unsupported gauge step value type');
    }
  };

  return (
    <div
      className={classnames({
        [classes.container]: true,
        [classes.gridWithInfoAndDelete]: !readOnly && onDelete && info,
        [classes.gridWithDelete]: !(!readOnly && onDelete && info),
      })}
    >
      <StoreColorPickerInput
        initialValue={value.color}
        onSubmit={(newColor) => {
          const newValue = { ...value };
          newValue.color = newColor ?? undefined;
          onChange(newValue);
        }}
        readOnly={readOnly}
        colorPalette={getColorPalette(store)}
      />
      <SearchAndSelect
        selectedOption={generateOptions(value.type)}
        computeOptions={() => Object.values(NumberColorStepValueType).map((type) => generateOptions(type))}
        onSelect={(option) => {
          if (option) {
            switch (option.id) {
              case NumberColorStepValueType.value: {
                const newValue = { ...value };
                newValue.type = NumberColorStepValueType.value;
                newValue.value = undefined;
                onChange(newValue);
                break;
              }
              case NumberColorStepValueType.field: {
                const newValue = { ...value };
                newValue.type = NumberColorStepValueType.field;
                newValue.value = [];
                onChange(newValue);
                break;
              }
              default:
                break;
            }
          }
        }}
        readOnly={readOnly}
      />
      {renderEditor()}
      {info && (
        <Icon
          name={IconName.info}
          colorVariant={IconColorVariant.info}
          tooltip={info}
        />
      )}
      {!readOnly && onDelete && (
        <IconOnlyButton
          iconName={IconName.delete}
          onClick={() => onDelete()}
          tooltip={i18n`Remove`}
          variant={IconOnlyButtonVariants.danger}
        />
      )}
    </div>
  );
};

export default NumberColorStepInput;
