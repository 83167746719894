import type { FunctionComponent } from 'react';
import { TextConstantField } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { PathStep, SingleParameterDefinition, SingleRelationFieldExportConfiguration } from 'yooi-modules/modules/conceptModule';
import { FILTER_PARAMETER_CURRENT, InstanceReferenceType, PathStepType } from 'yooi-modules/modules/conceptModule';
import { Concept_Name, ExternalKeyField, IdField, TextField } from 'yooi-modules/modules/conceptModule/ids';
import { joinObjects } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import CompositeField from '../../../../components/molecules/CompositeField';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import useStore from '../../../../store/useStore';
import { getSpacing, Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { getConceptDefinitionNameOrEntity } from '../../modelTypeUtils';
import PathInput from '../../path/PathInput';
import { createPathConfigurationHandler } from '../../pathConfigurationHandler';
import { getFieldTypeValidator } from '../../pathConfigurationHandlerUtils';

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: getSpacing(Spacing.xs),
  },
}, 'singleRelationExportConfiguration');

interface SingleRelationExportConfigurationProps {
  configuration: SingleRelationFieldExportConfiguration | undefined,
  conceptDefinitionId: string | undefined,
  onChange: (configuration: SingleRelationFieldExportConfiguration) => void,
}

const SingleRelationExportConfiguration: FunctionComponent<SingleRelationExportConfigurationProps> = ({
  configuration: configurationProps,
  conceptDefinitionId,
  onChange,
}) => {
  const objectStore = useStore();
  const classes = useStyles();

  if (!conceptDefinitionId) {
    return null;
  }

  const defaultPath: PathStep[] = [
    { type: PathStepType.dimension, conceptDefinitionId },
    { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
    { type: PathStepType.field, fieldId: Concept_Name },
  ];

  const configuration: SingleRelationFieldExportConfiguration = configurationProps ?? { type: 'path', path: defaultPath };

  const typeOptions = [{ id: 'uuid' as const, label: i18n`Uuid` }, { id: 'path' as const, label: i18n`Path` }];

  const parameterDefinitions: SingleParameterDefinition[] = [{
    id: FILTER_PARAMETER_CURRENT,
    type: 'parameter',
    typeId: conceptDefinitionId,
    label: i18n`Current ${getConceptDefinitionNameOrEntity(objectStore, conceptDefinitionId)}`,
  }];

  const pathHandler = createPathConfigurationHandler(objectStore, parameterDefinitions, [getFieldTypeValidator(objectStore, [TextField, TextConstantField, IdField, ExternalKeyField], i18n`Input should end with a text, ID or external key field.`)]);
  const errors = configuration.type === 'path' ? pathHandler.getErrors(!configuration.path ? defaultPath : configuration.path) : undefined;
  const title = i18n`Text - ${configuration.type === 'uuid' ? i18n`Id` : i18n`Path`}`;

  return (
    <Tooltip title={title}>
      <CompositeField
        headerLinesRenderers={[{
          id: 'title',
          render: () => (
            <div className={classes.titleContainer}>
              <Typo>{title}</Typo>
              {errors && errors.length > 0 && (
                <Icon name={IconName.dangerous} colorVariant={IconColorVariant.error} tooltip={errors.join('\n')} />
              )}
            </div>
          ),
        }]}
        getDropdownSectionDefinitions={() => [
          {
            id: 'edition',
            lines: [
              {
                id: 'format',
                title: i18n`Format`,
                render: (<SearchAndSelect
                  selectedOption={typeOptions.find(({ id }) => id === configuration.type)}
                  computeOptions={() => typeOptions}
                  onSelect={(option) => {
                    if (option) {
                      onChange(joinObjects(configuration, { type: option.id }));
                    }
                  }}
                />),
              },
              ...configuration.type === 'path' ? [{
                id: 'path',
                title: i18n`Path`,
                render: (
                  <PathInput
                    path={!configuration.path ? defaultPath : configuration.path}
                    parameterDefinitions={parameterDefinitions}
                    suggestedBasePaths={[{
                      label: i18n`Current ${getConceptDefinitionNameOrEntity(objectStore, conceptDefinitionId)}`,
                      path: [
                        { type: PathStepType.dimension, conceptDefinitionId },
                        { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
                      ],
                    }]}
                    valuePathHandler={pathHandler}
                    showEndOnly
                    onChange={(path) => onChange(joinObjects(configuration, { path }))}
                  />),
              }] : [],
            ],
          },
        ]}
      />
    </Tooltip>
  );
};

export default SingleRelationExportConfiguration;
