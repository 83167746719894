import type { FunctionComponent } from 'react';
import type { BlockStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import { WorkflowRelationDisplayType } from 'yooi-modules/modules/conceptLayoutModule';
import { Block_Documentation, Block_IsDocumentationInline, Block_Name, Block_Type, Block_WorkflowField, BlockType_Tab } from 'yooi-modules/modules/conceptLayoutModule/ids';
import { IconName } from '../../components/atoms/Icon';
import BlockTitle from '../../components/templates/BlockTitle';
import VerticalBlock from '../../components/templates/VerticalBlock';
import useStore from '../../store/useStore';
import { spacingRem } from '../../theme/spacingDefinition';
import { getPositionFromHash } from '../../utils/historyUtils';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import useDerivedState from '../../utils/useDerivedState';
import { computeSectionHash } from '../_global/conceptHashUtils';
import { BlockFieldDisplayStatus } from '../_global/fields/_global/blockFieldUtils';
import WorkflowFieldRenderer from '../_global/fields/workflowField/WorkflowFieldRenderer';
import type { ColumnBlockType } from './ConceptBlock';
import ConceptBlock from './ConceptBlock';

const useStyles = makeStyles({
  sectionBlocksContainer: {
    display: 'grid',
    gridAutoColumns: '1fr',
    gridAutoFlow: 'column',
    gap: spacingRem.l,
    gridColumn: 'span 4',
  },
}, 'conceptSection');

export interface SectionBlockType extends ColumnBlockType {
  columns: ColumnBlockType[],
}

interface ConceptSectionProps {
  conceptId: string,
  section: SectionBlockType,
  readOnly: boolean,
  withSeparation: boolean,
}

const ConceptSection: FunctionComponent<ConceptSectionProps> = ({ conceptId, section, readOnly, withSeparation }) => {
  const store = useStore();
  const classes = useStyles();
  const block = store.getObject<BlockStoreObject>(section.object.id);

  const fields = [
    ...section.fields,
    ...section.columns.flatMap((column) => column.fields),
  ];
  const areAllFieldsHidden = !fields.some(({ displayStatus, isFiltered }) => displayStatus !== BlockFieldDisplayStatus.hidden && !isFiltered);

  const [expandBlock, setExpendBlock] = useDerivedState(() => !areAllFieldsHidden, [conceptId]);

  const isSectionGreyed = section.object[Block_Type] !== BlockType_Tab && !expandBlock && areAllFieldsHidden;
  const isSectionHidden = fields.length === 0;

  if (isSectionHidden) {
    return null;
  }

  const hasDocumentation = !isSectionGreyed && Boolean(block[Block_Documentation]);
  const hasInlineDocumentation = hasDocumentation && Boolean(block[Block_IsDocumentationInline]);

  const workflowField = section.object.navigateOrNull(Block_WorkflowField) ?? undefined;

  return (
    <VerticalBlock key={section.object.id} withSeparation={withSeparation} asBlockContent>
      {section.object[Block_Type] === BlockType_Tab ? null : (
        <BlockTitle
          title={section.object[Block_Name]}
          anchor={getPositionFromHash(computeSectionHash(section.object))}
          isGreyed={isSectionGreyed}
          buttonIcon={(areAllFieldsHidden) ? {
            tooltip: !expandBlock ? i18n`Hide` : i18n`Show`,
            iconName: !expandBlock ? IconName.expand_more : IconName.keyboard_arrow_right,
            onClick: () => setExpendBlock((show) => !show),
          } : undefined}
          infoTooltip={!hasInlineDocumentation ? block[Block_Documentation] : undefined}
          subtitle={hasInlineDocumentation ? block[Block_Documentation] : undefined}
          rightActions={workflowField ? (
            <WorkflowFieldRenderer
              fieldId={workflowField.id}
              conceptId={conceptId}
              displayType={WorkflowRelationDisplayType.Button}
              readOnly={readOnly}
            />
          ) : null}
        />
      )}
      {workflowField ? (
        <WorkflowFieldRenderer
          fieldId={workflowField.id}
          conceptId={conceptId}
          displayType={WorkflowRelationDisplayType.Color}
          readOnly={readOnly}
        />
      ) : null}
      {isSectionGreyed ? null : (
        <div className={classes.sectionBlocksContainer}>
          {
            [section, ...section.columns].map((sectionBlock, _, self) => (
              <ConceptBlock key={sectionBlock.object.id} conceptId={conceptId} block={sectionBlock} readOnly={readOnly || sectionBlock.readOnly} inColumn={self.length > 1} />
            ))
          }
        </div>
      )}
    </VerticalBlock>
  );
};

export default ConceptSection;
