import type { ObjectStoreReadOnly } from 'yooi-store';
import { compareString, extractAndCompareValue, filterNullOrUndefined } from 'yooi-utils';
import { UnsetDashboardParameterOption } from '../../../dashboardModule/ids';
import type { FieldStep, FilterNode, Filters, FilterValue, PathStep } from '../../moduleType';
import { FilterValueType } from '../../moduleType';
import type { ParametersMapping } from '../parametersUtils';
import { isDimensionStep, isFieldStep, isFilterStep } from '../path/pathUtils';
import { isConceptValid } from '../utils';
import type { ConceptInstanceFilterConditions, ConceptMultipleInstanceFilterConditions } from './filters';
import { InstanceReferenceType, isFilterValuePath, isFilterValueRaw } from './filters';

export const FILTER_PARAMETER_CURRENT = 'FILTER_PARAMETER_CURRENT';
export const FILTER_PARAMETER_OPTION = 'FILTER_PARAMETER_OPTION';
export const FILTER_PARAMETER_LOGGED_USER = 'FILTER_PARAMETER_LOGGED_USER';
export const BLOCK_PARAMETER_CURRENT = 'BLOCK_PARAMETER_CURRENT';
export const GROUP_BY_PARAMETER = 'GROUP_BY_PARAMETER';

const sanitizeSelectValue = <T>(value: FilterValue<T>): FilterValue<T> => (value ?? { type: FilterValueType.raw, raw: undefined });
const isSelectFilterApplicable = <T>(value: FilterValue<T>) => (
  (isFilterValuePath(value) && Boolean(value.path)) || (isFilterValueRaw<T>(value) && Boolean(value.raw))
);

const sanitizeMultiSelectValue = <T>(value: FilterValue<T>): FilterValue<T> => (value ?? { type: FilterValueType.raw, raw: [] });
const isMultiSelectFilterApplicable = <T>(value: FilterValue<T>) => (
  (isFilterValuePath(value) && value.path !== undefined) || (isFilterValueRaw<T>(value) && value.raw !== undefined)
);

export const getInstanceFilterConditions = (store: ObjectStoreReadOnly): ConceptInstanceFilterConditions => (
  {
    EQUALS: {
      sanitizeValue: sanitizeSelectValue,
      isFilterApplicable: isSelectFilterApplicable,
      filterFunction: (leftValue, rightValue, parameters) => {
        if (Array.isArray(rightValue) || !rightValue || !rightValue.id || (InstanceReferenceType.parameter === rightValue.type && !parameters[rightValue.id])) {
          return true;
        }

        const value = InstanceReferenceType.parameter === rightValue.type ? parameters[rightValue.id]?.id : rightValue.id;
        if (!value) {
          return true;
        } else if (value === UnsetDashboardParameterOption) {
          return leftValue === undefined || !isConceptValid(store, leftValue);
        } else if (isConceptValid(store, value)) {
          return value === leftValue;
        } else {
          return true;
        }
      },
    },
    NOT_EQUALS: {
      sanitizeValue: sanitizeSelectValue,
      isFilterApplicable: isSelectFilterApplicable,
      filterFunction: (leftValue, rightValue, parameters) => {
        if (Array.isArray(rightValue) || !rightValue || !rightValue.id || (InstanceReferenceType.parameter === rightValue.type && !parameters[rightValue.id])) {
          return true;
        }

        const value = InstanceReferenceType.parameter === rightValue.type ? parameters[rightValue.id]?.id : rightValue.id;
        if (!value) {
          return true;
        } else if (value === UnsetDashboardParameterOption) {
          return leftValue !== undefined && isConceptValid(store, leftValue);
        } else if (isConceptValid(store, value)) {
          return value !== leftValue;
        } else {
          return true;
        }
      },
    },
    IN: {
      sanitizeValue: sanitizeMultiSelectValue,
      isFilterApplicable: isSelectFilterApplicable,
      filterFunction: (leftValue, rightValues, parameters) => {
        if (!Array.isArray(rightValues) || rightValues.length === 0) {
          return true;
        }

        const instanceIds = rightValues.map(({ id, type }) => {
          if (!id || (type === InstanceReferenceType.parameter && !parameters[id])) {
            return undefined;
          }

          const value = InstanceReferenceType.parameter === type ? parameters[id]?.id : id;
          if (!value) {
            return undefined;
          } else if (value === UnsetDashboardParameterOption || isConceptValid(store, value)) {
            return value;
          } else {
            return undefined;
          }
        })
          .filter(filterNullOrUndefined);

        return instanceIds.length === 0 || instanceIds.some((rightValue) => {
          if (rightValue === UnsetDashboardParameterOption) {
            return leftValue === undefined || !isConceptValid(store, leftValue);
          } else {
            return rightValue === leftValue;
          }
        });
      },
    },
    NOT_IN: {
      sanitizeValue: sanitizeMultiSelectValue,
      isFilterApplicable: isSelectFilterApplicable,
      filterFunction: (leftValue, rightValues, parameters) => {
        if (!Array.isArray(rightValues) || rightValues?.length === 0) {
          return true;
        }

        return rightValues
          .map(({ id, type }) => {
            if (!id || (type === InstanceReferenceType.parameter && !parameters[id])) {
              return undefined;
            }

            const value = InstanceReferenceType.parameter === type ? parameters[id]?.id : id;
            if (!value) {
              return undefined;
            } else if (value === UnsetDashboardParameterOption || isConceptValid(store, value)) {
              return value;
            } else {
              return undefined;
            }
          })
          .filter(filterNullOrUndefined)
          .every((rightValue) => {
            if (rightValue === UnsetDashboardParameterOption) {
              return leftValue !== undefined && isConceptValid(store, leftValue);
            } else {
              return rightValue !== leftValue;
            }
          });
      },
    },
    IS_EMPTY: {
      sanitizeValue: () => ({ type: FilterValueType.raw, raw: undefined }),
      isFilterApplicable: () => true,
      filterFunction: (leftValue) => !leftValue || !isConceptValid(store, leftValue),
    },
    IS_NOT_EMPTY: {
      sanitizeValue: () => ({ type: FilterValueType.raw, raw: undefined }),
      isFilterApplicable: () => true,
      filterFunction: (leftValue) => !!leftValue && isConceptValid(store, leftValue),
    },
  }
);

export const getMultipleInstanceFilterConditions = (store: ObjectStoreReadOnly): ConceptMultipleInstanceFilterConditions => (
  {
    CONTAINS: {
      sanitizeValue: sanitizeSelectValue,
      isFilterApplicable: isMultiSelectFilterApplicable,
      filterFunction: (leftValues, rightValues, parameters) => {
        if (!Array.isArray(rightValues) || rightValues.length === 0) {
          return true;
        }

        return rightValues
          .map(({ id, type }) => {
            if (!id || (type === InstanceReferenceType.parameter && !parameters[id])) {
              return undefined;
            }

            const value = InstanceReferenceType.parameter === type ? parameters[id]?.id : id;
            if (!value) {
              return undefined;
            } else if (isConceptValid(store, value)) {
              return value;
            } else {
              return undefined;
            }
          })
          .filter(filterNullOrUndefined)
          .every((rightValue) => leftValues.includes(rightValue));
      },
    },
    CONTAINS_SOME: {
      sanitizeValue: sanitizeSelectValue,
      isFilterApplicable: isMultiSelectFilterApplicable,
      filterFunction: (leftValues, rightValues, parameters) => {
        if (!Array.isArray(rightValues) || rightValues.length === 0) {
          return true;
        }

        const rightValuesFiltered = rightValues
          .map(({ id, type }) => {
            if (!id || (type === InstanceReferenceType.parameter && !parameters[id])) {
              return undefined;
            }

            const value = InstanceReferenceType.parameter === type ? parameters[id]?.id : id;
            if (!value) {
              return undefined;
            } else if (isConceptValid(store, value)) {
              return value;
            } else {
              return undefined;
            }
          })
          .filter(filterNullOrUndefined);
        return rightValuesFiltered.length === 0 || rightValuesFiltered.some((id) => leftValues.includes(id));
      },
    },
    DOES_NOT_CONTAIN: {
      sanitizeValue: sanitizeSelectValue,
      isFilterApplicable: isMultiSelectFilterApplicable,
      filterFunction: (leftValues, rightValues, parameters) => {
        if (!Array.isArray(rightValues) || rightValues?.length === 0) {
          return true;
        }

        return rightValues
          .map(({ id, type }) => {
            if (!id || (type === InstanceReferenceType.parameter && !parameters[id])) {
              return undefined;
            }

            const value = InstanceReferenceType.parameter === type ? parameters[id]?.id : id;
            if (!value) {
              return undefined;
            } else if (isConceptValid(store, value)) {
              return value;
            } else {
              return undefined;
            }
          })
          .filter(filterNullOrUndefined)
          .every((rightValue) => leftValues.every((leftValue) => leftValue !== rightValue));
      },
    },
    IS_EMPTY: {
      sanitizeValue: () => ({ type: FilterValueType.raw, raw: undefined }),
      isFilterApplicable: () => true,
      filterFunction: (leftValues) => leftValues.filter((leftValue) => isConceptValid(store, leftValue)).length === 0,
    },
    IS_NOT_EMPTY: {
      sanitizeValue: () => ({ type: FilterValueType.raw, raw: undefined }),
      isFilterApplicable: () => true,
      filterFunction: (leftValues) => leftValues.filter((leftValue) => isConceptValid(store, leftValue)).length > 0,
    },
  }
);

export const getEndOfPathFieldStep = (filterPath: PathStep[]): { step: FieldStep | undefined, index?: number } => {
  if (filterPath && filterPath.length > 0) {
    const nStep = filterPath[filterPath.length - 1];
    const n1Step = filterPath[filterPath.length - 2];
    const n2Step = filterPath[filterPath.length - 3];
    if (isFieldStep(nStep)) {
      return { step: nStep, index: filterPath.length - 1 };
    } else if (n1Step && isFieldStep(n1Step) && isDimensionStep(nStep)) {
      return { step: n1Step, index: filterPath.length - 2 };
    } else if (n2Step && isFieldStep(n2Step) && isDimensionStep(n1Step) && isFilterStep(nStep)) {
      return { step: n2Step, index: filterPath.length - 3 };
    }
  }
  return { step: undefined };
};

export const isFilterNode = (filters: Filters): filters is FilterNode => !!(filters as Partial<FilterNode>).condition;

export const buildFilterId = (parametersMapping: ParametersMapping): string[] => {
  const ids: string[] = [];
  const parametersValue: string[] = [];
  Object.entries(parametersMapping)
    .sort(extractAndCompareValue(([id]) => id, compareString))
    .forEach(([id, parameterValue]) => {
      if (parameterValue?.id) {
        ids.push(id);
        parametersValue.push(parameterValue.id.toString());
      }
    });
  return [ids.join(':'), ...parametersValue];
};
