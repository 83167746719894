import type { FunctionComponent } from 'react';
import type { BooleanFieldValue } from 'yooi-modules/modules/conceptModule';
import { joinObjects } from 'yooi-utils';
import type { IconName } from '../../../../components/atoms/Icon';
import { getDefaultStandardColors } from '../../../../components/inputs/ColorPicker';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import StoreColoredIconPicker from '../../input/StoreColoredIconPicker';
import StoreTextInputField from '../../input/StoreTextInputField';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: spacingRem.s,
  },
}, 'booleanFieldValueConfiguration');

interface BooleanFieldValueConfigurationProps {
  booleanFieldValue: BooleanFieldValue | undefined,
  onSubmit: (newValue: BooleanFieldValue) => void,
  clearMode?: { type: 'clear' } | { type: 'reset', value: IconName, color?: string, tooltip?: string },
}

const BooleanFieldValueConfiguration: FunctionComponent<BooleanFieldValueConfigurationProps> = ({ booleanFieldValue, onSubmit, clearMode }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <StoreColoredIconPicker
        initialValue={booleanFieldValue ? { icon: booleanFieldValue.icon as IconName, color: booleanFieldValue.color as string } : undefined}
        onSubmit={(newIcon) => {
          onSubmit(joinObjects(booleanFieldValue, {
            icon: newIcon?.icon,
            color: newIcon?.color,
            tooltip: booleanFieldValue?.tooltip,
          }));
        }}
        colorPalette={getDefaultStandardColors()}
        placeholder="Add icon"
        clearMode={clearMode}
      />
      <StoreTextInputField
        initialValue={booleanFieldValue?.tooltip}
        placeholder={i18n`Add a tooltip`}
        withDropdown={false}
        fullWidth
        maxLine={1}
        onSubmit={(newValue) => {
          if (newValue !== null) {
            onSubmit(joinObjects(booleanFieldValue, {
              icon: booleanFieldValue?.icon,
              color: booleanFieldValue?.color,
              tooltip: newValue,
            }));
          }
        }}
      />
    </div>
  );
};

export default BooleanFieldValueConfiguration;
