import { ValidationStatus } from 'yooi-store';
import { CommonAsType } from '../common/fields/commonPropertyType';
import { NavigationContext_Context } from './ids';
import { registerModel } from './module';

const { type } = registerModel;

type({
  label: 'NavigationContext',
  accessControlList: {
    READ: () => () => ({ rule: 'NavigationContext.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: () => () => ({ rule: 'NavigationContext.write.allow', status: ValidationStatus.ACCEPTED }),
    DELETE: () => () => ({ rule: 'NavigationContext.delete.disable', status: ValidationStatus.REJECTED }),
  },
  objectDebugLabel: ({ getObject }) => (id) => {
    const navigationContext = getObject(id)[NavigationContext_Context] as {
      locationState: { navigationState: { path: string | undefined, hash: string | undefined }[] | undefined } | undefined,
    } | undefined;
    const lastNavigationState = navigationContext?.locationState?.navigationState?.at(-1);
    return `NavigationContext on ${lastNavigationState?.path}${lastNavigationState?.hash ?? ''}`;
  },
})
  .property({ label: 'NavigationContext_Context', as: CommonAsType.unknown });
