import classnames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import { useState } from 'react';
import { spacingRem } from '../../../../../theme/spacingDefinition';
import makeStyles from '../../../../../utils/makeStyles';
import { useHoveredArrow } from './useOnHoverArrowRef';

const computeLabelDimensions = (xStart: number, yStart: number, xEnd: number, yEnd: number): { xLabel: number, yLabel: number, labelWidth: number, labelHeight: number } => {
  const labelWidth = Math.max(Math.abs(xEnd - xStart), 1);
  const labelHeight = Math.max(Math.abs(yEnd - yStart), 1);
  const xLabel = xEnd > xStart ? xStart : xEnd;
  const yLabel = yEnd > yStart ? yStart : yEnd;
  return { xLabel, yLabel, labelWidth, labelHeight };
};

const useStyles = makeStyles({
  arrowLabel: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    width: '100%',
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  withPointerEvent: {
    pointerEvents: 'auto',
  },
  arrowLabelContainer: {
    overflow: 'visible',
  },
  slowAppear: {
    transition: 'visibility 0s linear 200ms',
  },
  hidden: {
    visibility: 'hidden',
    transition: 'visibility 0s',
    pointerEvents: 'none',
    zIndex: -1,
  },
}, 'svgArrowLabel');

interface SvgArrowLabelProps {
  xStart: number,
  yStart: number,
  xEnd: number,
  yEnd: number,
  arrowLabel: ReactNode,
  withArrowLabel?: boolean,
  arrowKey: string,
}

const SvgArrowLabel: FunctionComponent<SvgArrowLabelProps> = ({ xStart, yStart, xEnd, yEnd, arrowLabel, withArrowLabel = false, arrowKey }) => {
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);
  useHoveredArrow((hoveredArrowKey, unHoveredArrowKey, reset) => {
    setHovered((old) => {
      if (old && (unHoveredArrowKey === arrowKey || reset)) {
        return !old;
      } else if (!old && hoveredArrowKey === arrowKey) {
        return !old;
      } else {
        return old;
      }
    });
  });

  const { xLabel, yLabel, labelWidth, labelHeight } = computeLabelDimensions(xStart, yStart, xEnd, yEnd);

  return (
    <g>
      <foreignObject x={xLabel} y={yLabel} width={labelWidth} height={labelHeight} className={classes.arrowLabelContainer}>
        <div
          className={classnames({
            [classes.arrowLabel]: true,
            [classes.withPointerEvent]: withArrowLabel,
            [classes.slowAppear]: !withArrowLabel,
            [classes.hidden]: !withArrowLabel && !hovered,
          })}
        >
          {arrowLabel}
        </div>
      </foreignObject>
    </g>
  );
};

export default SvgArrowLabel;
