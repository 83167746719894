import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import useTooltipRef from '../../utils/useTooltipRef';
import type { IconName } from './Icon';
import Icon from './Icon';

const useStyles = makeStyles((theme) => ({
  action: {
    display: 'flex',
    padding: spacingRem.s,
    border: 0,
    color: theme.color.toolbar.foreground.default,
    backgroundColor: theme.color.toolbar.background.default,
  },
  button: {
    cursor: 'pointer',
    '&:hover, &:focus-visible': {
      color: theme.color.toolbar.foreground.hover,
      backgroundColor: theme.color.toolbar.background.hover,
    },
  },
  buttonActive: {
    color: theme.color.toolbar.foreground.selected,
    backgroundColor: theme.color.toolbar.background.selected,
  },
  drag: {
    cursor: 'move',
    '&:hover, &:focus': {
      color: theme.color.toolbar.foreground.selected,
    },
  },
  dragActive: {
    color: theme.color.toolbar.foreground.selected,
  },
}), 'widgetActionButton');

interface WidgetActionButtonProps {
  tooltip: string,
  icon: IconName,
  action: { type: 'click', onClick: (event: { isCtrlKeyPressed: boolean, isMiddleClick: boolean }) => void } | { type: 'drag', className: string },
  active?: boolean,
}

const WidgetActionButton: FunctionComponent<WidgetActionButtonProps> = ({ tooltip, icon, action, active = false }) => {
  const classes = useStyles();

  const tooltipRef = useTooltipRef(tooltip);

  switch (action.type) {
    case 'click':
      return (
        <button
          ref={tooltipRef}
          type="button"
          className={classnames({
            [classes.action]: true,
            [classes.button]: true,
            [classes.buttonActive]: active,
          })}
          onClick={(event) => {
            event.stopPropagation();
            action.onClick({ isCtrlKeyPressed: event.ctrlKey, isMiddleClick: false });
          }}
          onMouseDown={(event) => {
            if (event.button === 1) {
              event.stopPropagation();
              action.onClick({ isCtrlKeyPressed: event.ctrlKey, isMiddleClick: true });
            }
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.stopPropagation();
              action.onClick({ isCtrlKeyPressed: event.ctrlKey, isMiddleClick: false });
            }
          }}
          aria-label={tooltip}
        >
          <Icon name={icon} />
        </button>
      );
    case 'drag':
      return (
        <span
          ref={tooltipRef}
          className={classnames({
            // Necessary to handle react grid layout drag (the lib use the class dragHandler to localize the drag handler)
            // eslint-disable-next-line yooi/check-classname-attribute
            [action.className]: true,
            [classes.action]: true,
            [classes.drag]: true,
            [classes.dragActive]: active,
          })}
        >
          <Icon name={icon} />
        </span>
      );
  }
};

export default WidgetActionButton;
