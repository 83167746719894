import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { newError, textType } from 'yooi-utils';
import type { GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import { validateFieldIdAsProperty } from '../common/commonFieldUtils';
import { registerField } from '../module';
import type { DimensionsMapping, ResolutionStack } from '../utils';
import { isValueResolutionOfType, ParsedDimensionType, parseDimensionMapping, resolveFieldValue } from '../utils';
import type { UrlField } from './types';

const getValueResolution = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack) => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
  if (isValueResolutionOfType(valueResolution, (value): value is string | undefined => value === undefined || typeof value === 'string')) {
    return valueResolution;
  } else {
    return {
      value: undefined,
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['string', 'undefined'], typeof valueResolution.value),
      getDisplayValue: () => undefined,
      isTimeseries: valueResolution.isTimeseries,
    };
  }
};

type UrlFieldHandler = GetDslFieldHandler<
  UrlField,
  string | undefined,
  undefined,
  string | undefined,
  string | undefined,
  string | undefined,
  undefined,
  undefined,
  undefined,
  undefined
>;

export const urlFieldHandler: UrlFieldHandler = registerField({
  model: {
    label: 'UrlField',
    title: 'URL',
    withApiAlias: true,
    asPropertyBusinessRules: [validateFieldIdAsProperty('urlField')],
  },
  handler: (objectStore, fieldId) => {
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => getValueResolution(objectStore, fieldId, dimensionsMapping).value;

    const getStoreValue = (dimensionsMapping: DimensionsMapping): string | undefined => {
      const parsedDimension = parseDimensionMapping(dimensionsMapping);
      if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
        return objectStore.getObject(parsedDimension.objectId)[fieldId] as string | undefined;
      } else {
        return undefined;
      }
    };

    return {
      describe: () => ({ hasData: true, returnType: textType, timeseriesMode: 'none' }),
      resolvePathStepConfiguration: () => ({
        hasData: true,
        timeseriesMode: 'none',
        getValueResolutionType: () => textType,
        resolveValue: (dimensionsMappings, _, resolutionStack) => {
          const { error, value } = getValueResolution(objectStore, fieldId, dimensionsMappings, resolutionStack);
          if (error) {
            throw error;
          } else {
            return value;
          }
        },
      }),
      restApi: {
        returnTypeSchema: { type: 'string', nullable: true },
        formatValue: (value) => value ?? undefined,
      },
      getStoreValue,
      getValueWithoutFormula: getStoreValue,
      getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
      updateValue: () => {
        throw newError('updateValue not supported');
      },
      isEmpty: (dimensionsMapping) => !getValueResolution(objectStore, fieldId, dimensionsMapping).value,
      isSaneValue: () => ({ isValid: true }),
      getValueAsText,
      getExportColumnHeaders: (configuration, fieldLabel) => ({
        columnsNumber: 1,
        getHeaders: () => [{ format: 'string', value: fieldLabel }],
        getColumnConfiguration: () => configuration,
      }),
      getExportValue: (dimensionsMapping) => ({
          format: 'string',
          value: getValueResolution(objectStore, fieldId, dimensionsMapping).value,
        }),
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else {
            return undefined;
          }
        },
      }),
      filterConditions: undefined,
    };
  },
});
