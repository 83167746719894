import type { FilterCondition, Filters, ParameterDefinition, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import {
  buildFunctionalIdFormatter,
  FILTER_PARAMETER_CURRENT,
  FILTER_PARAMETER_LOGGED_USER,
  FilterConditionOperators,
  FilterValueType,
  getFilterFunction,
  InstanceReferenceType,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import { Concept_FunctionalId } from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStore, StoreObject } from 'yooi-store';
import { isRichText, joinObjects, richTextToText } from 'yooi-utils';
import type { FrontObjectStore } from '../../store/useStore';
import { sanitizeSearchValue } from '../../utils/searchUtils';
import { getCompleteLeftBarFilters } from './filter/filterUtils';
import type { FilterConfiguration } from './filter/useFilterSessionStorage';
import { FilterParams } from './filter/useFilterSessionStorage';
import { getSearchableFields } from './modelTypeUtils';

// Retrieved all required field (from Search config table or all) and convert richText to Text
export const searchFilterFunction = <T extends Record<string, unknown> = StoreObject>(
  store: ObjectStore,
  nameSearch: (string | undefined)[] | string | undefined,
  nameFields: string[]
): ((item: T) => boolean) | undefined => {
  const searchValues = (Array.isArray(nameSearch) ? nameSearch : [nameSearch]).filter((search): search is string => Boolean(search));
  if (searchValues.length > 0) {
    const sanitizedSearchValues = searchValues.map(sanitizeSearchValue);
    return (item) => nameFields.some((key) => {
      const itemValue = item[key];
      if (itemValue) {
        let value: string | undefined;
        if (key === Concept_FunctionalId) {
          value = buildFunctionalIdFormatter(store, item[Instance_Of] as string, key)(itemValue as string);
        } else if (isRichText(itemValue)) {
          value = richTextToText(itemValue);
        } else if (typeof itemValue === 'string') {
          value = itemValue;
        } else {
          value = `${itemValue}`;
        }

        if (value !== undefined) {
          const valueCopy = value; // Just make typescript happy by providing a const to a function
          return sanitizedSearchValues.every((sanitizedSearchValue) => sanitizeSearchValue(valueCopy).includes(sanitizedSearchValue));
        } else {
          return false;
        }
      } else {
        return false;
      }
    });
  } else {
    return undefined;
  }
};

export const getConceptFilters = (
  store: FrontObjectStore,
  conceptDefinitionId: string,
  filtersConfiguration: FilterConfiguration | undefined
): Filters => {
  const filters: Filters = {
    condition: FilterConditionOperators.AND,
    children: [],
  };
  if (filtersConfiguration?.filters) {
    const filter = Object.values(filtersConfiguration.filters);
    filters.children?.push({
      condition: FilterConditionOperators.AND,
      children: filter,
    });
  }
  if (filtersConfiguration?.nameSearch) {
    const { nameSearch } = filtersConfiguration;
    const searchFilters = getSearchableFields(store, conceptDefinitionId).map((fieldId) => {
      const filter: FilterCondition = {
        operator: 'CONTAINS',
        leftValue: [{ type: PathStepType.dimension, conceptDefinitionId },
          { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
          { type: PathStepType.field, fieldId },
        ],
        rightValue: { type: FilterValueType.raw, raw: nameSearch },
      };
      return filter;
    });
    filters.children?.push(
      {
        condition: FilterConditionOperators.OR,
        children: searchFilters,
      }
    );
  }

  return filters;
};

export const getConceptFilterFunction = <T extends StoreObject = StoreObject>(
  store: FrontObjectStore,
  conceptDefinitionId: string,
  filterConfiguration: FilterConfiguration | undefined,
  userId: string,
  parametersMapping?: ParametersMapping
): ((item: T) => boolean) | undefined => {
  if (!filterConfiguration) {
    return undefined;
  }

  const filterFunctions: ((item: T) => boolean)[] = [];
  Object.entries(filterConfiguration).forEach(([key, value]) => {
    if (key === FilterParams.nameSearch && typeof value === 'string') {
      const extraFilterFunction = searchFilterFunction<T>(store, value, getSearchableFields(store, conceptDefinitionId));
      if (extraFilterFunction) {
        filterFunctions.push(extraFilterFunction);
      }
    } else if (key === FilterParams.filters && value) {
      const filters = Object.values(value as Record<string, FilterCondition>);
      const singleFilterFunction = getFilterFunction(store, getCompleteLeftBarFilters(filters));
      if (singleFilterFunction) {
        filterFunctions.push((item) => singleFilterFunction(joinObjects(
          {
            [FILTER_PARAMETER_CURRENT]: { type: 'single', id: item.id },
            [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: userId },
          } as const,
          parametersMapping ?? {}
        )));
      }
    }
  });

  if (filterFunctions.length > 0) {
    return (item) => filterFunctions.every((filterFunction) => filterFunction(item));
  } else {
    return undefined;
  }
};

export const getViewFilterFunction = (
  store: FrontObjectStore,
  parameterDefinitions: ParameterDefinition[],
  filterConfiguration: FilterConfiguration | undefined,
  parametersMapping: ParametersMapping
): ((mapping: ParametersMapping) => boolean) | undefined => {
  if (!filterConfiguration) {
    return undefined;
  }

  const filterFunctions: ((mapping: ParametersMapping) => boolean)[] = [];
  const searchFilterFunctions: ((mapping: ParametersMapping) => boolean)[] = [];
  Object.entries(filterConfiguration).forEach(([key, value]) => {
    if (key === FilterParams.nameSearch && typeof value === 'string') {
      for (let i = 0; i < parameterDefinitions.length; i += 1) {
        const parameterDefinition = parameterDefinitions[i];
        const conceptDefinitionId = parameterDefinition.typeId;
        const extraFilterFunction = searchFilterFunction(store, value, getSearchableFields(store, conceptDefinitionId));
        if (extraFilterFunction) {
          searchFilterFunctions.push((mapping) => {
            const { id } = mapping[parameterDefinition.id] ?? {};
            const item = id ? store.getObjectOrNull(id) : null;
            return item ? extraFilterFunction(item) : false;
          });
        }
      }
    } else if (key === FilterParams.filters && value) {
      const filters = Object.values(value as Record<string, FilterCondition>);
      const singleFilterFunction = getFilterFunction(store, getCompleteLeftBarFilters(filters));
      if (singleFilterFunction) {
        filterFunctions.push((mapping) => singleFilterFunction(joinObjects(
          mapping,
          parametersMapping
        )));
      }
    }
  });

  if (filterFunctions.length > 0 || searchFilterFunctions.length > 0) {
    return (mapping) => (searchFilterFunctions.length === 0 || searchFilterFunctions.some((filterFunction) => filterFunction(mapping)))
      && filterFunctions.every((filterFunction) => filterFunction(mapping));
  } else {
    return undefined;
  }
};
