import classnames from 'classnames';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import type { FunctionComponent, ReactElement } from 'react';
import { Fragment, useCallback, useState } from 'react';
import useResizeObserver from 'use-resize-observer';
import type { PeriodicityType } from 'yooi-utils';
import { compareDate, compareRank, ranker } from 'yooi-utils';
import base from '../../theme/base';
import { Spacing } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import { formatOrUndef } from '../../utils/stringUtils';
import useTheme from '../../utils/useTheme';
import GroupPanel from '../molecules/GroupPanel';
import DateIndicator from './internal/DateIndicator';
import TimeAxis, { TimeAxisDirection, TimeAxisVariant } from './internal/TimeAxis';
import TimelineDropZone from './internal/TimelineDropZone';
import type { ProgressFieldData } from './TimelineEntry';
import TimelineEntry, { entryChildHeightRem, heightInRem as entryHeightRem, TimelineEntryCroppedVariants } from './TimelineEntry';

const entryMarginTopBottomRem = 0.4;
const entryMarginTopOrBottomSpacingDefinition = Spacing.xxs; // should be equal to half entryMarginTopBottomRem
const timelineParentBottomMargin = 0.6;
const entryTotalHeightRem = entryHeightRem + entryMarginTopBottomRem;
const entryChildTotalHeightRem = entryChildHeightRem + entryMarginTopBottomRem;
const xAxisHeightRem = 5;
const topMarginRem = 1;
const indicatorBox = { width: 2, height: 2.5, leftOffset: -3.3, topOffset: 1.6, topOffsetChild: 1.2 };
const groupLabelWidth = 2.6;
const labelToTimelineMargin = 1.2;
const lineBreakThreshold = 14;

export interface TimelineLineEntry {
  id: string,
  label: { id?: string, name: string | undefined },
  startDate: Date | undefined,
  endDate: Date,
  progress: ProgressFieldData | undefined,
  color: null | undefined | string,
  rightText: string,
  dependingOn: string[] | undefined,
  shouldBeDisplayed: boolean,
  row: string,
  draggable: boolean,
}

export interface TimelineLine extends TimelineLineEntry {
  children: TimelineLineEntry[],
  groupByFieldValue?: string,
  groupByFieldValueName?: string,
  groupByFieldValueColor?: string | undefined,
}

const useStyles = makeStyles((theme) => ({
  svg: {
    overflow: 'visible',
  },
  parentBoxForeignObject: {
    pointerEvents: 'none',
  },
  parentBox: {
    pointerEvents: 'none',
    borderColor: theme.color.border.default,
    borderWidth: '0.1rem',
    borderRadius: base.borderRadius.medium,
    borderStyle: 'dashed',
    backgroundColor: `${theme.color.background.neutral.default}85`,
  },
  highlightedParentBox: {
    borderColor: theme.color.border.hover,
  },
  container: {
    width: '100%',
  },
  entryContainer: {
    position: 'fixed',
  },
}), 'timeline');

export interface TimelinePeriod {
  period: PeriodicityType,
  from: Date,
  to: Date,
}

interface TimelineProps {
  lines: Record<string, TimelineLine[]>,
  period: TimelinePeriod,
  onElementDrop?: (droppedConceptId: string, drop: { timelineRow: string, position: string }) => void,
  showSelf?: boolean,
  showDependencies?: boolean,
  isEntryEditedByOtherUser: (id: string) => boolean,
  renderTooltip: (conceptId: string, editMode: boolean, currentAnchor: HTMLElement, handleClose: () => void, isChild?: boolean) => (ReactElement | null),
  onEntryDrag?: (id: string) => void,
  onEntryDragEnd?: (id: string) => void,
  activityComponent: ({ progressField, ids }: { progressField: string, ids: string[] }) => (ReactElement | null),
  onDoubleClick: (id: string, isEmbedded?: boolean) => void,
}

const Timeline: FunctionComponent<TimelineProps> = ({
  lines,
  period,
  onElementDrop,
  showSelf = false,
  showDependencies = false,
  isEntryEditedByOtherUser,
  renderTooltip,
  onEntryDrag,
  onEntryDragEnd,
  activityComponent,
  onDoubleClick,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const { ref, width } = useResizeObserver();
  const containerWidth = width;

  const [draggedData, setDraggedData] = useState<TimelineLine | undefined>();
  const [highlightedGroup, setHighlightedGroup] = useState<string | null>(null);
  const [currentTooltipOpenId, setCurrentTooltipOpenId] = useState<string | undefined>();

  const entries: Record<string, TimelineLine> = Object.fromEntries(Object.values(lines).flat().map((entry) => ([entry.id, entry])));
  const parentLines = { ...lines };
  const allLines: Record<string, TimelineLineEntry[]> = { ...lines };

  const svgWidth = Math.max((containerWidth ?? 0) - remToPx(groupLabelWidth) - remToPx(labelToTimelineMargin) || 0, 0);
  const scaleX = d3Scale.scaleTime().domain([period.from, period.to]).range([0, svgWidth]);

  const getCardStartDate = useCallback((startDate: Date | undefined) => (startDate && startDate.getTime() > period.from.getTime() ? startDate : period.from), [period.from]);
  const getCardEndDate = useCallback((endDate: Date) => (endDate.getTime() < period.to.getTime() ? endDate : period.to), [period.to]);

  const getCardWidth = useCallback((data: TimelineLineEntry) => {
    const cardStartDate = getCardStartDate(data.startDate);
    const cardEndDate = getCardEndDate(data.endDate);
    return scaleX(cardEndDate) - scaleX(cardStartDate);
  }, [getCardEndDate, getCardStartDate, scaleX]);

  const computeLineMap = (list: TimelineLineEntry[]) => {
    const lineMap: Record<string, TimelineLineEntry[]> = {};
    const childrenList = [...list];
    childrenList.sort((a, b) => compareDate(a.startDate, b.startDate));
    childrenList.forEach((child) => {
      const nbrExistingLines = Object.keys(lineMap).length;
      let line = 0;
      while (line < nbrExistingLines) {
        const nbrElements = lineMap[line].length;
        const previousElement = lineMap[line][nbrElements - 1];
        if (child.startDate && child.startDate.getTime() > previousElement.endDate.getTime() && getCardWidth(previousElement) > lineBreakThreshold) {
          lineMap[line].push(child);
          break;
        }
        line += 1;
      }
      if (line === nbrExistingLines) {
        lineMap[nbrExistingLines] = [child];
      }
    });
    return lineMap;
  };

  Object.entries(parentLines)
    .sort(([a], [b]) => compareRank(a, b))
    .reduce((accumulator: Record<string, number>, [lineId, elements], index, array) => {
      const lineChildren = elements?.flatMap((l) => l.children) ?? [];
      const childrenLineMap = computeLineMap(lineChildren);
      accumulator[lineId] = Object.keys(accumulator).length;
      let newId = ranker.computeRankBetween(lineId, array[index + 1]?.[0]);
      Object.entries(childrenLineMap).forEach(([, children]) => {
        newId = ranker.computeRankBetween(newId, array[index + 1]?.[0]);
        children.forEach((child) => {
          // eslint-disable-next-line no-param-reassign
          child.row = newId;
        });
        allLines[newId] = children;
        accumulator[newId] = Object.keys(accumulator).length;
      });
      return accumulator;
    }, {});
  const numberOfLines = Object.keys(allLines).length;
  const numberParentLines = Object.keys(parentLines).length;
  const numberOfLinesWithChildren = Object.values(parentLines).filter((l) => l.some(({ children }) => children.length > 0)).length;
  const chipMinWidthPx = remToPx(1.4);
  const topMarginPx = remToPx(topMarginRem);
  const bottomMarginPx = remToPx(4);
  const timelineHeightPx = showSelf
    ? remToPx(numberParentLines * (entryTotalHeightRem + timelineParentBottomMargin) + (numberOfLines - numberParentLines) * entryChildTotalHeightRem)
    : remToPx((numberOfLines - numberParentLines) * entryChildTotalHeightRem + (numberOfLinesWithChildren * timelineParentBottomMargin));
  const xAxisHeightPx = remToPx(xAxisHeightRem);
  const svgHeightPx = Math.max((timelineHeightPx || remToPx(5)) + xAxisHeightPx + bottomMarginPx + topMarginPx, 0);
  const chartHeight = svgHeightPx - xAxisHeightPx - bottomMarginPx;

  const yPositionFromRow = (row: string) => {
    const parentLineKeys = Object.keys(parentLines);
    const sortedAllLines = Object.keys(allLines).sort((a, b) => compareRank(a, b));
    const rowIndex = sortedAllLines.indexOf(row);
    let yPosition = 0;
    sortedAllLines.forEach((line, lineIndex) => {
      if (lineIndex < rowIndex) {
        if (parentLineKeys.includes(line) && !showSelf) {
          return;
        } else if (parentLineKeys.includes(line)) {
          yPosition += entryTotalHeightRem;
        } else {
          yPosition += entryChildTotalHeightRem;
        }
        if (sortedAllLines[lineIndex + 1] && parentLineKeys.includes(sortedAllLines[lineIndex + 1])) {
          yPosition += timelineParentBottomMargin;
        }
      }
    });
    return remToPx(yPosition);
  };

  const verticalLink = d3Shape.linkVertical<{ source: { x: number, y: number }, target: { x: number, y: number } }, { x: number, y: number }>()
    .x((d) => d.x)
    .y((d) => d.y);
  const horizontalLink = d3Shape.linkHorizontal<{ source: { x: number, y: number }, target: { x: number, y: number } }, { x: number, y: number }>()
    .x((d) => d.x)
    .y((d) => d.y);

  const getTransform = (data: TimelineLineEntry) => {
    const cardStartDate = getCardStartDate(data.startDate);
    return `translate(${Math.min((getCardWidth(data) - chipMinWidthPx) / 2, 0) + scaleX(cardStartDate)}, ${yPositionFromRow(data.row)})`;
  };
  const getActivityTransform = (data: TimelineLineEntry) => {
    const isChild = !Object.keys(parentLines).includes(data.row);
    const topOffset = isChild ? indicatorBox.topOffsetChild : indicatorBox.topOffset;
    return `translate(${remToPx(indicatorBox.leftOffset)}, ${yPositionFromRow(data.row) + remToPx(topOffset)})`;
  };

  const arrowAnchorPosition = ['left', 'right', 'bottom', 'bottom-right', 'bottom-left', 'top', 'top-right', 'top-left'];

  const getArrowAnchorPosition = (data: TimelineLine, position: string): { x?: number, y?: number } => {
    const cardStartDate = data.startDate && data.startDate.getTime() > period.from.getTime() ? data.startDate : period.from;
    const cardEndDate = data.endDate.getTime() < period.to.getTime() ? data.endDate : period.to;
    const { row: line } = data;
    let x;
    let y;
    if (position === 'top' || position === 'bottom') {
      x = scaleX(cardStartDate) + getCardWidth(data) / 2;
      if (position === 'top') {
        y = yPositionFromRow(line) + remToPx(entryMarginTopBottomRem / 2);
      } else {
        y = yPositionFromRow(line) + remToPx(entryTotalHeightRem - entryMarginTopBottomRem / 2);
      }
    } else if (position === 'left' || position === 'right') {
      y = yPositionFromRow(line) + entryTotalHeightRem * (remToPx(1) / 2);
      if (position === 'left') {
        x = scaleX(cardStartDate);
      } else {
        x = scaleX(cardEndDate);
      }
    } else if (position === 'bottom-left') {
      x = scaleX(cardStartDate) + getCardWidth(data) / 4;
      y = yPositionFromRow(line) + remToPx(entryTotalHeightRem - entryMarginTopBottomRem / 2);
    } else if (position === 'bottom-right') {
      x = scaleX(cardStartDate) + (3 * getCardWidth(data)) / 4;
      y = yPositionFromRow(line) + remToPx(entryTotalHeightRem - entryMarginTopBottomRem / 2);
    } else if (position === 'top-left') {
      x = scaleX(cardStartDate) + getCardWidth(data) / 4;
      y = yPositionFromRow(line) + remToPx(entryMarginTopBottomRem / 2);
    } else if (position === 'top-right') {
      x = scaleX(cardStartDate) + (3 * getCardWidth(data)) / 4;
      y = yPositionFromRow(line) + remToPx(entryMarginTopBottomRem / 2);
    }
    return { x, y };
  };
  const getElementBox = (data: TimelineLineEntry, isChild?: boolean) => {
    const cardStartDate = getCardStartDate(data.startDate);
    const cardEndDate = getCardEndDate(data.endDate);
    const x1 = scaleX(cardStartDate);
    const x2 = scaleX(cardEndDate);
    const y1 = yPositionFromRow(data.row) + remToPx(entryMarginTopBottomRem / 2);
    const y2 = yPositionFromRow(data.row) + remToPx(entryMarginTopBottomRem / 2) + remToPx(isChild ? entryChildHeightRem : entryHeightRem);
    return { x1, x2, y1, y2 };
  };
  const getParentBox = (data: TimelineLine) => {
    let box = getElementBox(data);
    if (!showSelf && data.children.length > 0) {
      box = getElementBox(data.children[0], true);
    }
    data.children.forEach((child) => {
      const childBox = getElementBox(child, true);
      box.x1 = Math.min(box.x1, childBox.x1);
      box.x2 = Math.max(box.x2, childBox.x2);
      box.y1 = Math.min(box.y1, childBox.y1);
      box.y2 = Math.max(box.y2, childBox.y2);
    });
    return box;
  };
  const getParentBoxDates = (data: TimelineLine) => {
    let { startDate, endDate } = data;
    data.children.forEach((child) => {
      const { startDate: childStartDate, endDate: childEndDate } = child;
      if (childStartDate && startDate && childStartDate.getTime() < startDate.getTime()) {
        startDate = childStartDate;
      }
      if (endDate.getTime() < childEndDate.getTime()) {
        endDate = childEndDate;
      }
    });
    return { startDate, endDate };
  };

  const getArrowPosition = (fromData: TimelineLine, toData: TimelineLine): string | null => {
    let fromAnchor: { x?: number, y?: number } = {};
    let toAnchor: { x?: number, y?: number } = {};
    let dist = Number.MAX_SAFE_INTEGER;
    let position;
    arrowAnchorPosition.forEach((fromPosition) => {
      const tmpFromAnchor = getArrowAnchorPosition(fromData, fromPosition);
      arrowAnchorPosition.forEach((toPosition) => {
        const tmpToAnchor = getArrowAnchorPosition(toData, toPosition);
        if (tmpFromAnchor.x && tmpFromAnchor.y && tmpToAnchor.x && tmpToAnchor.y) {
          const tmpDist = Math.hypot(tmpFromAnchor.x - tmpToAnchor.x, tmpFromAnchor.y - tmpToAnchor.y);
          if (tmpDist < dist) {
            fromAnchor = tmpFromAnchor;
            toAnchor = tmpToAnchor;
            position = toPosition;
            dist = tmpDist;
          }
        }
      });
    });
    if (!fromAnchor.x || !fromAnchor.y || !toAnchor.x || !toAnchor.y) {
      return '';
    }
    return position === 'right' || position === 'left'
      ? horizontalLink({
        source: {
          x: fromAnchor.x,
          y: fromAnchor.y,
        },
        target: {
          x: toAnchor.x,
          y: toAnchor.y,
        },
      })
      : verticalLink({
        source: {
          x: fromAnchor.x,
          y: fromAnchor.y,
        },
        target: {
          x: toAnchor.x,
          y: toAnchor.y,
        },
      });
  };

  const hasConflict = (data: TimelineLine, row: string) => {
    const rowDataList = parentLines[row].filter((d) => d.id !== data.id);
    const { startDate, endDate } = getParentBoxDates(data);
    return rowDataList.some((d) => {
      const { startDate: compareStartDate, endDate: compareEndDate } = getParentBoxDates(d);
      return compareStartDate && startDate && ((compareStartDate.getTime() < endDate.getTime() && compareStartDate.getTime() > startDate.getTime())
        || (compareEndDate.getTime() < endDate.getTime() && compareEndDate.getTime() > startDate.getTime())
        || (compareStartDate.getTime() < startDate.getTime() && compareEndDate.getTime() > endDate.getTime()));
    });
  };

  const today = new Date();

  const computeCroppedVariant = (croppedLeft: boolean, croppedRight: boolean) => {
    if (croppedRight && croppedLeft) {
      return TimelineEntryCroppedVariants.full;
    } else if (croppedRight) {
      return TimelineEntryCroppedVariants.croppedRight;
    } else if (croppedLeft) {
      return TimelineEntryCroppedVariants.croppedLeft;
    } else {
      return TimelineEntryCroppedVariants.none;
    }
  };

  const groupPanel = Object.values(entries)
    .filter((entry) => (showSelf || entry.children.length > 0))
    .reduce((acc: Record<string, TimelineLine[]>, entry: TimelineLine) => {
      if (entry.groupByFieldValue) {
        acc[entry.groupByFieldValue] = [...acc[entry.groupByFieldValue] ?? [], entry];
      }
      return acc;
    }, {});
  const groupPanelCoord = Object.entries(groupPanel).map(([key, panel]) => {
    const label = formatOrUndef(panel[0].groupByFieldValueName);
    const { groupByFieldValueColor } = panel[0];
    const firstBox = getParentBox(panel[0]);
    const lastBox = getParentBox(panel[panel.length - 1]);
    return {
      key,
      y1: firstBox.y1,
      width: svgWidth,
      height: lastBox.y2 - firstBox.y1,
      label,
      color: groupByFieldValueColor ?? theme.color.text.brand,
    };
  });

  const timelineRowsAndRowNumbers = Object.keys(lines);
  return (
    <div ref={ref} className={classes.container}>
      <svg preserveAspectRatio="xMinYMin meet" viewBox={`0 0 ${svgWidth} ${svgHeightPx}`} width="100%" height={svgHeightPx} className={classes.svg}>
        <g transform={`translate(${remToPx(groupLabelWidth) + remToPx(labelToTimelineMargin)}, ${xAxisHeightPx})`}>
          <TimeAxis
            hideStartTimeTick
            showArrow
            timeScale={scaleX}
            initialCoordinate={chartHeight}
            periodicity={period.period}
            direction={TimeAxisDirection.top}
            tickSize={chartHeight + 10}
            margin={topMarginPx}
            variant={TimeAxisVariant.grey}
          />
          <g transform={`translate(0, ${topMarginPx})`}>
            <g transform={`translate(-${remToPx(groupLabelWidth) + remToPx(labelToTimelineMargin)}, 0)`}>
              {groupPanelCoord.map((panel) => (<GroupPanel key={panel.key} panel={panel} labelMargin={labelToTimelineMargin * 2} />))}
            </g>
            {Object.values(entries).map((data) => {
              const box = getParentBox(data);
              const hasChildren = data.children?.length > 0;
              return (
                <Fragment key={data.id}>
                  {hasChildren && showSelf && (
                    <foreignObject
                      key={`${data.id}-box`}
                      transform={`translate(${box.x1},${box.y1})`}
                      width={box.x2 - box.x1}
                      height={box.y2 - box.y1}
                      className={classes.parentBoxForeignObject}
                    >
                      <div
                        style={{
                          width: box.x2 - box.x1,
                          height: box.y2 - box.y1,
                        }}
                        className={classnames({
                          [classes.parentBox]: true,
                          [classes.highlightedParentBox]: highlightedGroup === data.id,
                        })}
                      />
                    </foreignObject>
                  )}
                  {showSelf && (
                    <foreignObject
                      transform={getTransform(data)}
                      width={Math.max(getCardWidth(data), chipMinWidthPx)}
                      height={remToPx(entryTotalHeightRem)}
                    >
                      <div className={classes.entryContainer}>
                        <TimelineEntry
                          id={data.id}
                          widthInPx={getCardWidth(data)}
                          renderTooltip={renderTooltip}
                          rectColor={data.color}
                          rightText={data.rightText}
                          label={data.label}
                          onElementDrag={data.draggable && onEntryDrag ? () => {
                            // on Chrome changing the dom in the dragStart event trigger a dragEnd event the setTimeout 0 is a simple hack to prevent this
                            setTimeout(() => setDraggedData(data), 0);
                            onEntryDrag(data.id);
                          } : undefined}
                          onElementDragEnd={onEntryDragEnd ? () => {
                            setDraggedData(undefined);
                            onEntryDragEnd(data.id);
                          } : undefined}
                          onElementTooltipEditModeOpen={() => setCurrentTooltipOpenId(data.id)}
                          onElementTooltipEditModeClose={() => setCurrentTooltipOpenId(undefined)}
                          margin={{ x: Spacing.none, y: entryMarginTopOrBottomSpacingDefinition }}
                          croppedVariant={
                            computeCroppedVariant(
                              Boolean(data.startDate && data.startDate.getTime() < period.from.getTime()),
                              data.endDate.getTime() > period.to.getTime()
                            )
                          }
                          showTooltip={!draggedData && (!currentTooltipOpenId || currentTooltipOpenId === data.id)}
                          isEditedByOtherUser={isEntryEditedByOtherUser(data.id)}
                          onDoubleClick={onDoubleClick}
                          onMouseEnter={() => setHighlightedGroup(data.id)}
                          onMouseLeave={() => setHighlightedGroup(null)}
                          isDraggable={data.draggable}
                          progress={data.progress}
                        />
                      </div>
                    </foreignObject>
                  )}
                  {data.children.map((child) => (
                    <Fragment key={`${data.id}_${child.id}`}>
                      <foreignObject
                        transform={getTransform(child)}
                        width={Math.max(getCardWidth(child), chipMinWidthPx)}
                        height={remToPx(entryTotalHeightRem)}
                      >
                        <div className={classes.entryContainer}>
                          <TimelineEntry
                            id={child.id}
                            widthInPx={getCardWidth(child)}
                            renderTooltip={renderTooltip}
                            rectColor={child.color}
                            rightText={child.rightText}
                            label={child.label}
                            onElementTooltipEditModeOpen={() => setCurrentTooltipOpenId(child.id)}
                            onElementTooltipEditModeClose={() => setCurrentTooltipOpenId(undefined)}
                            margin={{ x: Spacing.none, y: entryMarginTopOrBottomSpacingDefinition }}
                            isEditedByOtherUser={isEntryEditedByOtherUser(child.id)}
                            showTooltip={!draggedData && (!currentTooltipOpenId || currentTooltipOpenId === child.id)}
                            isChild
                            onMouseEnter={() => setHighlightedGroup(data.id)}
                            onMouseLeave={() => setHighlightedGroup(null)}
                            onDoubleClick={(id) => onDoubleClick(id, true)}
                            isDraggable={false}
                            croppedVariant={
                              computeCroppedVariant(
                                Boolean(child.startDate && child.startDate.getTime() < period.from.getTime()),
                                child.endDate.getTime() > period.to.getTime()
                              )
                            }
                            progress={child.progress}
                          />
                        </div>
                      </foreignObject>
                    </Fragment>
                  ))}
                </Fragment>
              );
            })}
            <defs>
              <marker id="arrow" markerWidth="10" markerHeight="10" refX="7" refY="3" orient="auto" markerUnits="strokeWidth">
                <path d="M0,0 L0,6 L9,3 z" fill={theme.color.text.secondary} />
              </marker>
            </defs>
            {showDependencies && showSelf && Object.values(entries)
              .filter((from) => from.dependingOn && from.dependingOn.length > 0)
              .map((from) => from.dependingOn
                ?.map((id) => entries[id])
                .filter((to) => Boolean(to))
                .map((to) => (
                  <path
                    key={`line ${from.id} ${to.id}`}
                    d={getArrowPosition(from, to) ?? undefined}
                    stroke={theme.color.text.secondary}
                    strokeDasharray="5 4"
                    fill="none"
                    markerEnd="url(#arrow)"
                  />
                )))}
            {draggedData && (
              <TimelineDropZone
                timelineRowsAndRowNumbers={timelineRowsAndRowNumbers}
                onElementDrop={onElementDrop}
                yPositionFromRow={(line) => yPositionFromRow(line) - 1}
                height={remToPx(entryHeightRem) + (remToPx(timelineParentBottomMargin) * 2)}
                width={scaleX(period.to) - scaleX(period.from)}
                conflictingLines={timelineRowsAndRowNumbers.filter((row) => hasConflict(draggedData, row))}
              />
            )}
          </g>
          {today.getTime() > period.from.getTime() && today.getTime() < period.to.getTime() && (
            <DateIndicator label={i18n`Today`} date={today} size={chartHeight} scale={scaleX} labelOffset={-12} direction="top" />
          )}
        </g>
        <g transform={`translate(0, ${xAxisHeightPx})`}>
          {allLines && Object.values(allLines)
            .filter((elements) => elements[0].progress)
            .map((elements) => {
              // checked by the filter
              const progressField: string = elements[0].progress?.field as string;
              return (
                <foreignObject
                  key={`activity-${elements.map((e) => e.id).join('#')}`}
                  transform={getActivityTransform(elements[0])}
                  width={remToPx(indicatorBox.width)}
                  height={remToPx(indicatorBox.height)}
                >
                  {activityComponent({ progressField, ids: elements.map((e) => e.id) })}
                </foreignObject>
              );
            })}
        </g>
      </svg>
    </div>
  );
};

export default Timeline;
