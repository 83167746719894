import type { FunctionComponent, ReactElement } from 'react';
import type { FieldBlockDisplayStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration, FieldBlockDisplay_FieldPath } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type {
  AssociationFilterRaw,
  AssociationFilterStoreObject,
  ConditionFilterRaw,
  ConditionFilterStoreObject,
  FieldStoreObject,
  SingleParameterDefinition,
} from 'yooi-modules/modules/conceptModule';
import { getFieldDimensionOfModelType, getPathLastFieldInformation } from 'yooi-modules/modules/conceptModule';
import {
  AssociationFilter,
  AssociationFilter_Path,
  ConditionFilter,
  FieldBlockDisplay_ViewFilters,
  ViewFilter_FieldBlockDisplay,
  ViewFilter_Rank,
} from 'yooi-modules/modules/conceptModule/ids';
import type { ViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { joinObjects, newError } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import Tooltip from '../../../components/atoms/Tooltip';
import Typo, { TypoVariant } from '../../../components/atoms/Typo';
import SpacingLine from '../../../components/molecules/SpacingLine';
import BaseLayout from '../../../components/templates/BaseLayout';
import BlockContent from '../../../components/templates/BlockContent';
import Header from '../../../components/templates/Header';
import HeaderLine from '../../../components/templates/HeaderLine';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { NavigationElementContainer } from '../../../utils/useNavigation';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import useTheme from '../../../utils/useTheme';
import { getBlockParameterDefinitionCurrent } from '../../_global/conceptFilterIdUtils';
import { getFieldBlockDisplayDimensions } from '../../_global/fields/_global/blockFieldUtils';
import { getFieldHandler } from '../../_global/fields/FieldLibrary';
import ViewsFieldDefinitionOptions, { ViewsFieldDefinitionOptionsVariant } from '../../_global/fields/viewsField/ViewsFieldDefinitionOptions';
import { getFieldLabel } from '../../_global/fieldUtils';
import { getLoggedUserParameterDefinition } from '../../_global/filter/filterUtils';
import { QuickFilterType } from '../../_global/filter/QuickFiltersEditor';
import { NavigationTitlePathElements } from '../../_global/navigationUtils';
import TopBar from '../../_global/topBar/TopBar';

const useStyles = makeStyles((theme) => ({
  titleTypoContainer: {
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
    marginLeft: spacingRem.s,
    marginRight: spacingRem.s,
  },
}), 'fieldBlockDisplayEditorPage');

interface FieldBlockDisplayEditorPageProps {
  conceptDefinitionId: string,
  fieldBlockDisplayId: string,
}

const FieldBlockDisplayEditorPage: FunctionComponent<FieldBlockDisplayEditorPageProps> = ({ conceptDefinitionId, fieldBlockDisplayId }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const fieldBlockDisplay = store.getObject<FieldBlockDisplayStoreObject>(fieldBlockDisplayId);

  const lastFieldInformation = fieldBlockDisplay[FieldBlockDisplay_FieldPath] ? getPathLastFieldInformation(fieldBlockDisplay[FieldBlockDisplay_FieldPath]) : undefined;
  const fieldId = fieldBlockDisplay[FieldBlockDisplay_FieldPath] ? lastFieldInformation?.fieldId : undefined;

  if (!fieldId || !lastFieldInformation) {
    throw newError('Unsupported field type');
  }
  const fieldHandler = getFieldHandler(store, fieldId);
  const dimensionId = lastFieldInformation.conceptDefinitionId
    ? getFieldDimensionOfModelType(store, lastFieldInformation.fieldId, lastFieldInformation.conceptDefinitionId) : undefined;

  const blockParameterCurrent = getBlockParameterDefinitionCurrent(conceptDefinitionId);

  const parameterDefinitions: SingleParameterDefinition[] = [blockParameterCurrent, getLoggedUserParameterDefinition()];

  const handleBlockDisplayOptions = fieldHandler?.blockDisplayOptionsHandler?.(
    fieldBlockDisplay.id,
    dimensionId && lastFieldInformation?.conceptDefinitionId ? [...parameterDefinitions, {
      id: dimensionId,
      typeId: lastFieldInformation.conceptDefinitionId,
      label: i18n`Current`,
      type: 'dimension',
    }] : parameterDefinitions
  );
  if (!fieldHandler || !handleBlockDisplayOptions) {
    throw newError('Unsupported field type');
  }
  const { viewDefinitions, withFilters } = handleBlockDisplayOptions.getDisplayOptions() as { viewDefinitions?: ViewStoredDefinition[], withFilters?: boolean };
  const path = fieldBlockDisplay[FieldBlockDisplay_FieldPath];

  const title = i18n`${getFieldLabel(store, store.getObject<FieldStoreObject>(fieldId))} - Display Options`;

  let content: ReactElement;
  if (!viewDefinitions || !path) {
    content = (
      <BlockContent padded>
        <SpacingLine>
          <Icon name={IconName.dangerous} colorVariant={IconColorVariant.error} />
          <Typo color={theme.color.text.secondary} maxLine={1} noWrap>{i18n`Content not supported yet`}</Typo>
        </SpacingLine>
      </BlockContent>
    );
  } else {
    content = (
      <ViewsFieldDefinitionOptions
        variant={ViewsFieldDefinitionOptionsVariant.primary}
        viewsDefinition={{
          data: getFieldBlockDisplayDimensions(store, fieldBlockDisplay.id),
          views: viewDefinitions,
          hasFilters: withFilters,
        }}
        updateViewsDefinition={(newDefinition) => {
          store.updateObject(fieldBlockDisplay.id, {
            [FieldBlockDisplay_FieldDisplayConfiguration]: joinObjects(
              fieldBlockDisplay[FieldBlockDisplay_FieldDisplayConfiguration],
              {
                withFilters: newDefinition?.hasFilters,
                viewDefinitions: newDefinition?.views,
              }
            ),
          });
        }}
        onNewQuickFilter={(type, rank) => {
          if (type === QuickFilterType.association) {
            return store.createObject<AssociationFilterRaw>({
              [Instance_Of]: AssociationFilter,
              [ViewFilter_FieldBlockDisplay]: fieldBlockDisplay.id,
              [ViewFilter_Rank]: rank,
              [AssociationFilter_Path]: [],
            });
          } else {
            return store.createObject<ConditionFilterRaw>({
              [Instance_Of]: ConditionFilter,
              [ViewFilter_FieldBlockDisplay]: fieldBlockDisplay.id,
              [ViewFilter_Rank]: rank,
            });
          }
        }}
        getQuickFilters={() => fieldBlockDisplay.navigateBack<AssociationFilterStoreObject | ConditionFilterStoreObject>(FieldBlockDisplay_ViewFilters)}
        getNavigationData={() => ({
          viewUrl: `/settings/organization/${conceptDefinitionId}/fieldBlockDisplay/${fieldBlockDisplayId}`,
          navigationKey: fieldBlockDisplayId,
        })}
        dataIsReadOnly
        readOnly={false}
        parameterDefinitions={[blockParameterCurrent]}
        isWidget={false}
      />
    );
  }

  return (
    <NavigationElementContainer
      element={{
        key: fieldBlockDisplayId,
        name: title,
        tooltip: title,
        element: NavigationTitlePathElements.fieldBlockDisplay,
        path: `/settings/organization/${conceptDefinitionId}/field/${fieldBlockDisplayId}`,
      }}
    >
      <BaseLayout
        topBar={(<TopBar />)}
        header={(
          <Header
            firstLine={(
              <HeaderLine>
                <div className={classes.titleTypoContainer}>
                  <SizeContextProvider sizeVariant={SizeVariant.title} hierarchyVariant={HierarchyVariant.content}>
                    <SpacingLine>
                      <Tooltip title={title}>
                        <Typo maxLine={1} variant={TypoVariant.display}>{title}</Typo>
                      </Tooltip>
                    </SpacingLine>
                  </SizeContextProvider>
                </div>
              </HeaderLine>
            )}
          />
        )}
        content={(
          <VerticalBlock>
            {content}
          </VerticalBlock>
        )}
      />
    </NavigationElementContainer>
  );
};

export default FieldBlockDisplayEditorPage;
