// eslint-disable-next-line yooi/no-restricted-dependency,import/no-unused-modules
import { PlatformLanguage } from 'yooi-modules/modules/platformConfigurationModule';
import { i18nConfigure } from './utils/i18n';
import { getPlatformLanguage, getTranslations } from './utils/options';

switch (getPlatformLanguage()) {
  case PlatformLanguage.fr: {
    i18nConfigure('fr-FR', { w: 'S', q: 'T' }, getTranslations());
    break;
  }
  case PlatformLanguage.en:
    break;
}
