import type { ReactElement } from 'react';
import type { FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { getFieldDimensionOfModelType } from 'yooi-modules/modules/conceptModule';
import { Field_Documentation } from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { StoreObject } from 'yooi-store';
import { IconName } from '../../../../components/atoms/Icon';
import type { CardLineDefinition } from '../../../../components/molecules/CardLine';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { formatOrUndef } from '../../../../utils/stringUtils';
import { getFieldLabel } from '../../fieldUtils';
import { getFieldHandler } from '../FieldLibrary';

export const getConceptFieldForCard = (
  store: FrontObjectStore,
  fieldId: string,
  readOnly: boolean,
  focusOnMount: boolean
): Omit<CardLineDefinition, 'render'> & { render: (concept: StoreObject, focusOnMount: boolean) => ReactElement | null } => {
  const fieldHandler = getFieldHandler(store, fieldId);
  const field = store.getObjectOrNull<FieldStoreObject>(fieldId);

  if (fieldHandler && field) {
    const icon = fieldHandler.configuration.getIcon();
    return {
      id: fieldId,
      title: getFieldLabel(store, field),
      documentation: (store.getObject(fieldId))[Field_Documentation] as string | undefined,
      icon: typeof icon === 'object' ? icon.name : icon,
      render: (concept, renderFocusOnMount) => {
        let content;
        const dimensionId = getFieldDimensionOfModelType(store, fieldId, concept[Instance_Of] as string);
        if (dimensionId) {
          content = fieldHandler.renderField?.({ dimensionsMapping: { [dimensionId]: concept.id }, readOnly, focusOnMount: focusOnMount && renderFocusOnMount });
        }

        return content ?? (<div>{i18n`content not yet supported`}</div>);
      },
    };
  } else {
    return {
      id: fieldId,
      title: formatOrUndef(undefined),
      icon: IconName.subject,
      render: () => (<div>{i18n`content not yet supported`}</div>),
    };
  }
};
