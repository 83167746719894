import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { usePageCollaborationContext } from '../../collaboration/usePageCollaborationContext';
import CollaborationPanel from './CollaborationPanel';

const CollaborationRightPanel: FunctionComponent = () => {
  const [phaseState, setPhaseState] = useState('OPEN');

  usePageCollaborationContext();

  return (
    <CollaborationPanel phaseState={phaseState} setPhaseState={setPhaseState} />
  );
};

export default CollaborationRightPanel;
