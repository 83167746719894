import type { BusinessRuleHandler, ObjectStoreReadOnly } from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import { createModule } from '../common/types/TypeModuleDsl';
import { encodeExternalKey } from '../conceptModule';
import {
  ConceptExternalKeyMapping,
  ConceptExternalKeyMapping_ObjectId,
  ConceptExternalKeyMapping_Role_ExternalKey,
  ConceptExternalKeyMapping_Role_ExternalKeyPropertyId,
} from '../conceptModule/ids';
import {
  TemplateConcept,
  TemplateConcept_TemplateConceptDefinition,
  TemplateConceptDefinition,
  TemplateConceptDefinition_Template,
  TemplateField,
  TemplateField_TemplateConceptDefinition,
} from '../templateModule/ids';
import { Instance_Of } from '../typeModule/ids';
import * as IntegrationModuleIds from './ids';
import { Integration, Integration_Template, IntegrationModuleId } from './ids';
import migrations from './migrations';

const { initTypedModule, ...registerModelDsl } = createModule({ label: 'Integration' }, IntegrationModuleIds);
export const registerModel = registerModelDsl;

const handleIntegrationIdAsProperty = (propertyId: string, { getObjectOrNull }: ObjectStoreReadOnly): BusinessRuleHandler => (_, { id, properties }) => {
  const object = getObjectOrNull(id);
  const currentValue = object?.[propertyId] as string;

  if (!properties || properties?.[propertyId] === null) {
    return {
      rule: 'integration.AddIntegrationAsProperty.handleRemoveValue',
      status: ValidationStatus.ACCEPTED,
      generateSystemEvent: ({ withAssociation }) => {
        const assoc = withAssociation(ConceptExternalKeyMapping)
          .withRole(ConceptExternalKeyMapping_Role_ExternalKey, encodeExternalKey(currentValue))
          .withRole(ConceptExternalKeyMapping_Role_ExternalKeyPropertyId, propertyId);
        if (assoc?.getObjectOrNull()?.[ConceptExternalKeyMapping_ObjectId] === id[0]) {
          assoc.deleteObject();
        }
      },
    };
  }
  const integration = getObjectOrNull(propertyId);
  const integrationTemplateId = integration?.[Integration_Template];
  const objectInstanceOfId = object?.[Instance_Of] ?? properties?.[Instance_Of];
  let currentTemplateId;
  let templateConceptDefinitionId;
  if (objectInstanceOfId === TemplateConceptDefinition) {
    templateConceptDefinitionId = id;
    currentTemplateId = object?.[TemplateConceptDefinition_Template] ?? properties[TemplateConceptDefinition_Template];
  }
  if (objectInstanceOfId === TemplateConcept) {
    templateConceptDefinitionId = object?.[TemplateConcept_TemplateConceptDefinition] as string ?? properties[TemplateConcept_TemplateConceptDefinition];
    currentTemplateId = templateConceptDefinitionId ? getObjectOrNull(templateConceptDefinitionId)?.[TemplateConceptDefinition_Template] : undefined;
  }
  if (objectInstanceOfId === TemplateField) {
    templateConceptDefinitionId = object?.[TemplateField_TemplateConceptDefinition] as string ?? properties[TemplateField_TemplateConceptDefinition];
    currentTemplateId = templateConceptDefinitionId ? getObjectOrNull(templateConceptDefinitionId)?.[TemplateConceptDefinition_Template] : undefined;
  }
  if (integration && integrationTemplateId === currentTemplateId) {
    return {
      rule: 'integration.AddIntegrationAsProperty.handled',
      status: ValidationStatus.ACCEPTED,
      generateSystemEvent: ({ withAssociation }) => {
        if (properties?.[propertyId]) {
          const assoc = withAssociation(ConceptExternalKeyMapping)
            .withRole(ConceptExternalKeyMapping_Role_ExternalKey, encodeExternalKey(properties?.[propertyId] as string))
            .withRole(ConceptExternalKeyMapping_Role_ExternalKeyPropertyId, propertyId);
          const associatedObjectId = assoc.getObjectOrNull()?.[ConceptExternalKeyMapping_ObjectId] as string[];
          if (!associatedObjectId || associatedObjectId !== object?.id) {
            assoc.updateObject({ [ConceptExternalKeyMapping_ObjectId]: id[0] });
          }
        }
      },
    };
  }
  return undefined;
};

export const initModule = initTypedModule(() => ({
  id: IntegrationModuleId,
  migrations,
  registerBusinessRules: (objectStore, { onObject, onProperty }) => {
    onObject(Integration).register(([propertyId]) => onProperty(propertyId).validate(handleIntegrationIdAsProperty(propertyId, objectStore)));
  },
}));
