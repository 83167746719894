import type { ComponentProps, FunctionComponent } from 'react';
import type { AssociationFilterStoreObject, Filters, SingleParameterDefinition, ParametersMapping, ViewFilterStoreObject } from 'yooi-modules/modules/conceptModule';
import { ViewFilter_Rank } from 'yooi-modules/modules/conceptModule/ids';
import { compareProperty, compareRank } from 'yooi-utils';
import Icon, { IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import Typo from '../../../components/atoms/Typo';
import CompositeField, { CompositeFieldVariants } from '../../../components/molecules/CompositeField';
import { EditableButtonVariant } from '../../../components/molecules/EditableWithDropdown';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import ConditionFilterBlock from './ConditionFilterBlock';
import QuickFilterBlock from './QuickFilterBlock';
import { isAssociationFilter } from './quickFiltersUtils';

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.s,
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  titleRightContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: spacingRem.xs,
  },
  filterBlockContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.m,
  },
}, 'allFiltersComposite');

interface AllFiltersCompositeProps {
  filters: Record<string, Filters> | undefined,
  onChange: (filters: Record<string, Filters> | undefined) => void,
  onReset: () => void,
  parameterDefinitions: SingleParameterDefinition[],
  getPrefilteredInstances?: (conceptDefinitionIdToFilter: string, quickFilter: AssociationFilterStoreObject) => string[] | undefined,
  parametersMapping?: ParametersMapping,
  getViewFilters: () => ViewFilterStoreObject[],
  showDropDown?: boolean,
  onClose?: () => void,
  getIconOnlyButton?: ComponentProps<typeof CompositeField>['getIconOnlyButton'],
}

const AllFiltersComposite: FunctionComponent<AllFiltersCompositeProps> = ({
  filters, onChange, getViewFilters, onReset, parameterDefinitions, getPrefilteredInstances, parametersMapping, showDropDown, onClose, getIconOnlyButton,
}) => {
  const classes = useStyles();

  const quickFilters: ViewFilterStoreObject[] = getViewFilters().sort(compareProperty(ViewFilter_Rank, compareRank));

  return (
    <div>
      <CompositeField
        dropdownMaxWidth="50rem"
        variant={CompositeFieldVariants.button}
        getIconOnlyButton={getIconOnlyButton}
        buttonVariant={EditableButtonVariant.free}
        headerLinesRenderers={[{
          id: 'title',
          render: (inDropdown) => (
            <div className={classes.titleContainer}>
              <div className={classes.titleRightContainer}>
                <Icon name={IconName.filter_alt} />
                <Typo>{i18n`All filters`}</Typo>
              </div>
              {inDropdown && (
                <SizeContextProvider sizeVariant={SizeVariant.small}>
                  <IconOnlyButton
                    tooltip={i18n`Reset all filters`}
                    variant={IconOnlyButtonVariants.secondary}
                    onClick={() => {
                      onReset();
                    }}
                    iconName={IconName.sync}
                  />
                </SizeContextProvider>
              )}
            </div>
          ),
        }]}
        getDropdownSectionDefinitions={() => [{
          id: 'instances',
          lines: [{
            id: 'values',
            render: (
              <div className={classes.filterBlockContainer}>
                <ConditionFilterBlock
                  filters={filters ?? {}}
                  onChange={(setFilters) => {
                    onChange(setFilters(filters));
                  }}
                  parameterDefinitions={parameterDefinitions}
                  getViewFilters={getViewFilters}
                />
                {quickFilters.filter(isAssociationFilter).map((qf) => (
                  <QuickFilterBlock
                    key={qf.key}
                    quickFilterId={qf.id}
                    parameterDefinitions={parameterDefinitions}
                    filters={filters ?? {}}
                    onChange={(setFilters) => {
                      onChange(setFilters(filters));
                    }}
                    getPrefilteredInstances={getPrefilteredInstances}
                    parametersMapping={parametersMapping}
                  />
                ))}
              </div>),
          }],
        }]}
        onOpenDropdown={() => {}}
        showDropdown={showDropDown}
        onCloseDropdown={onClose}
      />
    </div>
  );
};
export default AllFiltersComposite;
