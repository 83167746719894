import type { RefObject } from 'react';
import { useLayoutEffect, useRef } from 'react';

interface ByRef {
  ref: RefObject<HTMLElement | null>,
  onBackdropClick: (event: MouseEvent) => void,
  isBoundary: boolean,
}

// presume that handlers mounted before the one which got the click don't consider the click as a backdrop click
const stack: { readonly current: ByRef }[] = [];

document.addEventListener(
  'click',
  (event) => {
    if (event.target instanceof Node) {
      for (let i = stack.length - 1; i >= 0; i -= 1) {
        const { current: { ref: { current: htmlElement }, onBackdropClick, isBoundary } } = stack[i];
        if (htmlElement !== null) {
          if (htmlElement.contains(event.target)) {
            return;
          } else if (isBoundary) {
            onBackdropClick(event);
            if (stack.findIndex((e) => e.current.isBoundary) !== i) {
              // Prevent click on other boundaries if we have boundaries inside boundaries
              // This prevent opening boundaries inside another stack even if the previous is not totally closed
              event.stopPropagation();
              // This prevent to click on links event if we are inside a boundaries stack
              event.preventDefault();
            }
            return;
          } else {
            onBackdropClick(event);
          }
        }
      }
    }
  },
  { capture: true }
);

const useBackdropClick = (ref: RefObject<HTMLElement | null>, onBackdropClick: (event: MouseEvent) => void, isBoundary = false, shouldInject = true): void => {
  const byRef = useRef<ByRef>({ ref, onBackdropClick, isBoundary });
  byRef.current = { ref, onBackdropClick, isBoundary };

  useLayoutEffect(() => {
    if (shouldInject) {
      stack.push(byRef);
      return () => {
        stack.splice(stack.indexOf(byRef), 1);
      };
    } else {
      return () => {};
    }
  }, [shouldInject]);
};

export default useBackdropClick;
