import classnames from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';
import { useRef } from 'react';
import { getSpacing, Spacing, spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import { OverlayContextProvider } from '../../utils/useOverlayContainerRef';
import { baseLayoutSelectorsClasses } from './BaseLayout';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: `${spacingRem.blockLeftColumnSpacing} 1fr ${spacingRem.blockRightColumnSpacing}`,
    alignItems: 'center',
    rowGap: spacingRem.s,
    paddingTop: spacingRem.m,
    paddingLeft: spacingRem.splus,
    paddingRight: getSpacing(Spacing.splus, 0.8 /* scroll width */),
    zIndex: 1,
  },
  bottomLine: {
    gridColumnStart: 1,
    gridColumnEnd: 'span 3',
    marginTop: spacingRem.s,
    borderBottomWidth: '0.1rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.transparent,
  },
  bottomLineVisible: {
    borderBottomColor: theme.color.border.default,
  },
}), 'header');

interface HeaderProps {
  firstLine: ReactElement | null,
  secondLine?: ReactElement | null,
  tabsLine?: ReactElement | null,
  withBottomBorder?: boolean,
}

const Header: FunctionComponent<HeaderProps> = ({ firstLine, secondLine, tabsLine, withBottomBorder = false }) => {
  const classes = useStyles();

  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      ref={containerRef}
      className={classnames(classes.container, baseLayoutSelectorsClasses.onScrollShadow)}
    >
      <OverlayContextProvider containerRef={containerRef}>
        {firstLine}
        {secondLine}
        {tabsLine}
        {tabsLine === null || tabsLine === undefined ? (
          <span
            className={classnames({
              [classes.bottomLine]: true,
              [classes.bottomLineVisible]: withBottomBorder,
              [baseLayoutSelectorsClasses.onScrollBorder]: true,
            })}
          />
        ) : null}
      </OverlayContextProvider>
    </div>
  );
};

export default Header;
