import type { FunctionComponent } from 'react';
import { Fragment } from 'react';
import type { ConceptStoreObject, FieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { getFieldDimensionOfModelType, getFieldUtilsHandler, numberFieldHandler } from 'yooi-modules/modules/conceptModule';
import { NumberField } from 'yooi-modules/modules/conceptModule/ids';
import type { RadarChartFieldConfiguration } from 'yooi-modules/modules/dashboardModule';
import { RadarChartField_Configuration } from 'yooi-modules/modules/dashboardModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { filterNullOrUndefined } from 'yooi-utils';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import useStore from '../../../../store/useStore';
import makeStyles from '../../../../utils/makeStyles';
import { getFieldLabel, getInstanceMaxMinValues, TickResolutionStatus } from '../../fieldUtils';
import StoreNumberPickerInput from '../../input/StoreNumberPickerInput';
import ConceptTooltip from '../_global/ConceptTooltip';
import ConceptTooltipText from '../_global/ConceptTooltipText';
import { filterPath, getLastConcept } from '../_global/widgetSeriesUtils';
import { getNumberRangeValues } from '../numberField/numberFieldUtils';
import { ChartValuePathAcceptedFields } from './radarChartFieldUtils';

const useStyles = makeStyles({
  tooltipLabelContainer: {
    width: '10.5rem',
  },
  tooltipValueContainer: {
    width: '14.1rem',
  },
}, 'radarChartTooltip');

interface RadarChartTooltipProps {
  instanceId: string,
  fieldId: string,
  seriesIds: string[],
  color: string,
}

const RadarChartTooltip: FunctionComponent<RadarChartTooltipProps> = ({ instanceId, fieldId, seriesIds, color }) => {
  const classes = useStyles();
  const store = useStore();
  const field = store.getObject(fieldId);
  const configuration = field[RadarChartField_Configuration] as RadarChartFieldConfiguration | undefined;

  const concept = store.getObjectOrNull<ConceptStoreObject>(instanceId);
  if (!concept || !configuration) {
    return null;
  }

  return (
    <ConceptTooltip
      conceptId={concept.id}
      color={color}
    >
      {seriesIds
        .map((seriesId) => configuration?.series?.find(({ id }) => seriesId === id))
        .filter(filterNullOrUndefined)
        .map((series) => {
          const { path: valuePath } = filterPath(store, series.valuePath, ChartValuePathAcceptedFields);
          const lastValueConcept = getLastConcept(store, concept, valuePath);
          const dataField = valuePath[valuePath.length - 1] ? store.getObject<FieldStoreObject>(valuePath[valuePath.length - 1]) : undefined;
          if (!lastValueConcept || !dataField) {
            return null;
          }
          const dimension = getFieldDimensionOfModelType(store, dataField.id, lastValueConcept[Instance_Of] as string);
          const valueResolution = getFieldUtilsHandler(store, dataField.id).getValueResolution(dimension ? { [dimension]: lastValueConcept.id } : {});

          let min: { value: number, color: string | undefined } | undefined;
          let ticks: { value: number, color: string | undefined }[] | undefined;
          let max: { value: number, color: string | undefined } | undefined;
          let invalidColor: string | undefined;
          let unit: string | undefined;
          let decimals: number | undefined;

          if (isInstanceOf(dataField, NumberField)) {
            const dimensionId = getFieldDimensionOfModelType(store, dataField.id, concept[Instance_Of]);
            const { resolveConfiguration, resolveConfigurationWithOverride } = numberFieldHandler(store, dataField.id);
            const numberField = dimensionId !== undefined ? resolveConfigurationWithOverride({ [dimensionId]: instanceId }) : resolveConfiguration();
            const resolutions = getInstanceMaxMinValues(store, dataField.id, instanceId, {});
            min = resolutions.min?.status === TickResolutionStatus.Resolved ? resolutions.min : undefined;
            ticks = getNumberRangeValues(store, dataField.id, instanceId);
            max = resolutions.max?.status === TickResolutionStatus.Resolved ? resolutions.max : undefined;
            invalidColor = numberField.invalidColor;
            unit = numberField.unit;
            decimals = numberField.decimals;
          }

          return (
            <Fragment key={series.id}>
              <SpacingLine>
                <div className={classes.tooltipLabelContainer}>
                  <ConceptTooltipText text={getFieldLabel(store, dataField)} />
                </div>
                <div className={classes.tooltipValueContainer}>
                  <StoreNumberPickerInput
                    initialValue={valueResolution?.value as number | undefined}
                    readOnly
                    min={min}
                    ticks={ticks}
                    max={max}
                    invalidColor={invalidColor}
                    unit={unit}
                    withProgress
                    decimals={decimals}
                  />
                </div>
              </SpacingLine>
            </Fragment>
          );
        })}
    </ConceptTooltip>
  );
};

export default RadarChartTooltip;
