import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import type { BooleanFieldRaw, BooleanFieldStoreObject, BooleanFieldValue, FilterValueRaw, Formula, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { booleanFieldHandler, FilterValueType } from 'yooi-modules/modules/conceptModule';
import {
  BooleanField,
  BooleanField_FalseValue,
  BooleanField_TrueValue,
  Field_ApiAlias,
  Field_Documentation,
  Field_Formula,
  Field_IntegrationOnly,
  Field_IsCore,
  Field_IsDocumentationInline,
  Field_Title,
} from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { booleanType, compareBoolean, comparing, joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import SimpleInput from '../../../../components/inputs/strategy/SimpleInput';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import { TableSortDirection } from '../../../../components/molecules/Table';
import base, { Color } from '../../../../theme/base';
import { Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import { getPlatformAccentColor } from '../../../../utils/options';
import { getFormulaFieldEditionSection } from '../../formulaRendererUtils';
import type { Option } from '../../modelTypeUtils';
import { createPathConfigurationHandler } from '../../pathConfigurationHandler';
import { getFieldTypeValidator } from '../../pathConfigurationHandlerUtils';
import { getDimensionsEditionOption, getDocumentationFieldEditionSection, getIntegrationFieldEditionSection } from '../_global/editionHandlerUtils';
import { getGenericOperationMetadata } from '../_global/updateOperationHandlerUtils';
import UpdateOperationInput from '../_global/UpdateOperationInput';
import UpdateOperationSelector from '../_global/UpdateOperationSelector';
import { duplicateFormula } from '../duplicationUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  duplicateFieldDimensionWithNewField,
  FIELD_DIMENSIONS_READONLY,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
  submitDimensionUpdate,
} from '../fieldDimensionUtils';
import type { FieldEditionOption, FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, FieldComparatorHandler, GetFieldDefinitionHandler, RenderFilter } from '../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldIntegrationOnlyDisabled } from '../FieldLibraryTypes';
import BooleanChooser from './BooleanChooser';
import BooleanFieldRenderer from './BooleanFieldRenderer';
import BooleanFieldValueConfiguration from './BooleanFieldValueConfiguration';

interface BooleanFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [FIELD_DIMENSIONS_READONLY]: boolean | undefined,
  [Field_Title]: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]?: string | null | undefined,
  [Field_IsDocumentationInline]?: boolean | null | undefined,
  [Field_IntegrationOnly]: boolean | null | undefined,
  [FieldIntegrationOnlyDisabled]: boolean | undefined,
  [BooleanField_TrueValue]: BooleanFieldValue | null | undefined,
  [BooleanField_FalseValue]: BooleanFieldValue | null | undefined,
  [Field_Formula]?: Formula | null | undefined,
}

const defaultLightGrey = base.colorPalette.default.find((color) => color.code === Color.LightGrey)?.value ?? base.color.gray['300'];

type BooleanFieldDefinition = GetFieldDefinitionHandler<typeof booleanFieldHandler, BooleanFieldConfigurationState, never, FieldBlockDisplayOptions>;

export const booleanFieldDefinition: BooleanFieldDefinition = registerFieldDefinition(booleanFieldHandler, {
  configuration: {
    typeIcon: IconName.toggle_on,
    getTypeLabel: () => i18n`Boolean`,
    asWidget: false,
    getEditionOptions: (objectStore) => ({ mode, editionHandler, readOnly }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      if (mode === FieldEditionOptionMode.Field || mode === FieldEditionOptionMode.FieldDeveloperMode) {
        sections.push({
          key: 'data',
          type: 'section',
          title: i18n`Data`,
          options: [
            getDimensionsEditionOption(editionHandler, false, readOnly || Boolean(editionHandler.getValue(FIELD_DIMENSIONS_READONLY))),
          ],
        });
      }

      if ([FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        sections.push(getDocumentationFieldEditionSection(editionHandler));
        sections.push(getIntegrationFieldEditionSection(objectStore, editionHandler, mode));
      }

      const trueValue = editionHandler.getValue(BooleanField_TrueValue);
      const falseValue = editionHandler.getValue(BooleanField_FalseValue);
      const defaultTrueValue = { icon: IconName.toggle_on, color: getPlatformAccentColor() ?? base.color.brand['700'], tooltip: undefined };
      const defaultFalseValue = trueValue && trueValue.icon
        ? { icon: trueValue.icon as IconName, color: defaultLightGrey, tooltip: undefined }
        : { icon: IconName.toggle_off, color: defaultLightGrey, tooltip: undefined };
      if (!trueValue) {
        editionHandler.updateValues({ [BooleanField_TrueValue]: defaultTrueValue });
      }
      if (!falseValue) {
        editionHandler.updateValues({ [BooleanField_FalseValue]: defaultFalseValue });
      }
      const trueValueOption: FieldEditionOption = {
        key: BooleanField_TrueValue,
        title: i18n`True`,
        hasValue: () => editionHandler.getValue(BooleanField_TrueValue) !== undefined,
        clearValue: () => editionHandler.updateValues({ [BooleanField_TrueValue]: null }),
        type: EditionOptionTypes.custom,
        props: {
          render: () => (
            <BooleanFieldValueConfiguration
              booleanFieldValue={
                editionHandler.getValueOrDefault(BooleanField_TrueValue)
              }
              onSubmit={(newValue) => {
                let updates = { [BooleanField_TrueValue]: newValue };
                if ((falseValue?.icon === defaultFalseValue.icon && falseValue?.color === defaultFalseValue.color)
                  || (falseValue?.icon === trueValue?.icon && falseValue?.color === defaultFalseValue.color)) {
                  updates = joinObjects(updates, { [BooleanField_FalseValue]: { icon: newValue?.icon, color: defaultLightGrey, tooltip: falseValue.tooltip } });
                }
                editionHandler.updateValues(updates);
              }}
              clearMode={{ type: 'reset', value: defaultTrueValue.icon, color: defaultTrueValue.color }}
            />
          ),
        },
      };

      const falseValueOption: FieldEditionOption = {
        key: BooleanField_FalseValue,
        title: i18n`False`,
        hasValue: () => editionHandler.getValue(BooleanField_FalseValue) !== undefined,
        clearValue: () => editionHandler.updateValues({ [BooleanField_FalseValue]: null }),
        type: EditionOptionTypes.custom,
        props: {
          render: () => (
            <BooleanFieldValueConfiguration
              booleanFieldValue={editionHandler.getValueOrDefault(BooleanField_FalseValue)}
              onSubmit={(newValue) => editionHandler.updateValues({ [BooleanField_FalseValue]: newValue })}
              clearMode={{
                type: 'reset',
                value: defaultFalseValue.icon,
                color: defaultFalseValue.color,
                tooltip: i18n`Reset to the same icon as the "true" one with light grey color`,
              }}
            />
          ),
        },
      };
      sections.push({
        key: 'display',
        type: 'section',
        title: i18n`Display`,
        options: [trueValueOption, falseValueOption],
      });

      if ([FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        const fieldEditionDimensions: FieldEditionDimensions = editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {};
        const parameterDefinitions: SingleParameterDefinition[] = Object.entries(fieldEditionDimensions)
          .map(([id, { typeId, label }]) => ({ id, typeId, label: label || i18n`Dimension`, type: 'dimension' }));

        sections.push(getFormulaFieldEditionSection({
          store: objectStore,
          parameterDefinitions,
          value: editionHandler.getValueOrDefault(Field_Formula),
          onChange: (value: Formula | null) => {
            editionHandler.updateValues({ [Field_Formula]: value });
          },
          hasValue: () => editionHandler.getValue(Field_Formula) !== undefined,
          clearValue: () => editionHandler.updateValues({ [Field_Formula]: null }),
          returnType: booleanType,
        }));
      }

      return sections;
    },
    isCreationEnabled: () => () => true,
    onCreate: (objectStore) => (editionHandler) => {
      const fieldId = objectStore.createObject<BooleanFieldRaw>({
        [Instance_Of]: BooleanField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_ApiAlias]: editionHandler.getValue(Field_ApiAlias),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
        [Field_Formula]: editionHandler.getValue(Field_Formula),
        [BooleanField_TrueValue]: editionHandler.getValue(BooleanField_TrueValue),
        [BooleanField_FalseValue]: editionHandler.getValue(BooleanField_FalseValue),
      });
      linkFieldToFieldDimensions(objectStore, fieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});
      return fieldId;
    },
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<BooleanFieldStoreObject>(fieldId);
        return {
          [Field_Title]: field[Field_Title],
          [Field_ApiAlias]: field[Field_ApiAlias],
          [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
          [FIELD_DIMENSIONS_READONLY]: field[Field_IsCore],
          [Field_Documentation]: field[Field_Documentation],
          [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: field[Field_IntegrationOnly],
          [Field_Formula]: field[Field_Formula],
          [FieldIntegrationOnlyDisabled]: field[Field_IsCore],
          [BooleanField_TrueValue]: field[BooleanField_TrueValue],
          [BooleanField_FalseValue]: field[BooleanField_FalseValue],
        };
      },
      submitFieldUpdate: (stateToSubmit, conceptDefinitionId) => {
        objectStore.updateObject<BooleanFieldStoreObject>(fieldId, {
          [Field_Title]: stateToSubmit[Field_Title],
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: stateToSubmit[Field_IntegrationOnly],
          [Field_Formula]: stateToSubmit[Field_Formula],
          [BooleanField_TrueValue]: stateToSubmit[BooleanField_TrueValue],
          [BooleanField_FalseValue]: stateToSubmit[BooleanField_FalseValue],
        });
        submitDimensionUpdate(objectStore, fieldId, conceptDefinitionId, stateToSubmit[FIELD_EDITION_DIMENSIONS] ?? {});
      },
      duplicateFieldDefinition: () => {
        const field = objectStore.getObject<BooleanFieldStoreObject>(fieldId);
        const fieldDimensionMapping = generateDuplicatedFieldDimensionId(objectStore, fieldId);
        const newFieldId = objectStore.createObject<BooleanFieldRaw>({
          [Instance_Of]: field[Instance_Of],
          [Field_Title]: `${field[Field_Title]} (copy)`,
          [Field_Documentation]: field[Field_Documentation],
          [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: field[Field_IntegrationOnly],
          [Field_Formula]: duplicateFormula(field[Field_Formula], fieldDimensionMapping),
          [BooleanField_TrueValue]: field[BooleanField_TrueValue],
          [BooleanField_FalseValue]: field[BooleanField_FalseValue],
        });
        duplicateFieldDimensionWithNewField(objectStore, newFieldId, fieldDimensionMapping);
        return newFieldId;
      },
    }),
  },
  renderField: (_, fieldId) => ({ dimensionsMapping, readOnly }) => (
    <BooleanFieldRenderer fieldId={fieldId} dimensionsMapping={dimensionsMapping} readOnly={readOnly} />
  ),
  getComparatorHandler: (_, __, { getValueResolution }) => (direction) => ({
    comparator: comparing(compareBoolean, direction === TableSortDirection.desc),
    extractValue: (dimensionsMapping) => {
      const { value } = getValueResolution(dimensionsMapping);
      return typeof value === 'boolean' ? value : undefined;
    },
  }) satisfies FieldComparatorHandler<boolean | undefined>,
  input: (_, __, handler) => ({
    render: ({ value, onSubmit, readOnly }) => (
      <SimpleInput initialValue={value} onSubmit={onSubmit}>
        {(props) => (
          <BooleanChooser value={props.value} onSubmit={props.onSubmit} readOnly={readOnly} />
        )}
      </SimpleInput>
    ),
    getInitialState: () => false,
    persistStateOnConcept: (dimension, state) => handler.updateValue(dimension, state ?? null),
  }),
  getUpdateOperationInput: (store, fieldId, { updateOperationHandlers: { REPLACE } }) => (operation) => {
    const { title, getIcon } = getGenericOperationMetadata(store, operation, fieldId);

    return {
      title,
      getIcon,
      render: ({ onSubmit, parameterDefinitions }) => {
        const { input } = booleanFieldDefinition(store).getHandler(fieldId);

        return (
          <>
            <UpdateOperationSelector<typeof booleanFieldHandler>
              selectedOperationAction={operation?.action}
              operationOptions={[
                { id: 'REPLACE', label: i18n`Replace`, onSelect: () => onSubmit({ action: 'REPLACE', payload: REPLACE.sanitizeOperation(operation) }) },
              ]}
            />
            {
              input && operation && operation.payload
                ? (
                  <UpdateOperationInput<typeof booleanFieldHandler>
                    valueInput={input}
                    initialValue={operation.payload}
                    onTypeChange={(newType) => {
                      onSubmit({ action: operation.action, payload: newType === 'value' ? { type: 'value', value: false } : { type: 'path', path: [] } });
                    }}
                    onValueSubmit={(newValue) => {
                      onSubmit({ action: operation.action, payload: newValue });
                    }}
                    parameterDefinitions={parameterDefinitions}
                    valuePathHandler={createPathConfigurationHandler(store, parameterDefinitions, [getFieldTypeValidator(store, [BooleanField], i18n`Input should end with a boolean value.`)])}
                  />
                )
                : null
            }
          </>
        );
      },
    };
  },
  renderExportConfiguration: () => () => (
    <SpacedContainer margin={{ x: Spacing.splus }}>
      <Tooltip title={i18n`Boolean`}>
        <Typo>{i18n`Boolean`}</Typo>
      </Tooltip>
    </SpacedContainer>
  ),
  getActivityProperties: (_, fieldId) => () => [fieldId],
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({
    propertyId: fieldId,
    sortable: true,
  }),
  estimatedColumnWidth: () => () => '3.6rem',
  filterConditions: (objectStore, fieldId) => {
    const renderBooleanFilter: RenderFilter<FilterValueRaw<boolean>> = (value, setValue, _, readOnly) => {
      const field = objectStore.getObject<BooleanFieldStoreObject>(fieldId);
      const { icon: trueIcon, color: trueColor } = field[BooleanField_TrueValue] as BooleanFieldValue;
      const { icon: falseIcon, color: falseColor } = field[BooleanField_FalseValue] as BooleanFieldValue;
      const { raw } = value;
      const booleanOptions: Option[] = [
        {
          id: 'true',
          label: i18n`True`,
          icon: { name: trueIcon as IconName ?? IconName.toggle_on, color: trueColor ?? defaultLightGrey },
        },
        {
          id: 'false',
          label: i18n`False`,
          icon: { name: falseIcon as IconName ?? IconName.toggle_off, color: falseColor ?? defaultLightGrey },
        }];
      const selectedValue = raw ? booleanOptions[0] : booleanOptions[1];
      return (
        <SearchAndSelect
          readOnly={readOnly}
          computeOptions={() => booleanOptions}
          selectedOption={selectedValue}
          onSelect={(newValue) => {
            if (newValue !== null) {
              setValue({ type: FilterValueType.raw, raw: newValue.id === 'true' });
            }
          }}
        />
      );
    };

    return {
      EQUALS: {
        name: i18n`Is`,
        renderFilter: renderBooleanFilter,
      },
    };
  },
});
