import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import { BlockFieldLayoutOption } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import { OverridableDisplayType } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import type {
  FieldLocalOverrideRaw,
  FieldLocalOverrideStoreObject,
  FieldStoreObject,
  FilterValueRaw,
  Formula,
  NumberColorStepsValue,
  NumberColorStepValue,
  NumberFieldRaw,
  NumberFieldStoreObject,
  SingleParameterDefinition,
} from 'yooi-modules/modules/conceptModule';
import { duplicatePath, FilterValueType, NumberColorStepValueType, numberFieldHandler } from 'yooi-modules/modules/conceptModule';
import {
  Field_ApiAlias,
  Field_Documentation,
  Field_Formula,
  Field_IntegrationOnly,
  Field_IsCore,
  Field_IsDocumentationInline,
  Field_Title,
  FieldLocalOverride,
  FieldLocalOverride_NumberFieldDecimals,
  FieldLocalOverride_NumberFieldInvalidColor,
  FieldLocalOverride_NumberFieldMaxValue,
  FieldLocalOverride_NumberFieldMinValue,
  FieldLocalOverride_NumberFieldRangeValue,
  FieldLocalOverride_NumberFieldUnit,
  FieldLocalOverride_Role_Concept,
  FieldLocalOverride_Role_Field,
  NumberField,
  NumberField_Decimals,
  NumberField_InvalidColor,
  NumberField_MaxValue,
  NumberField_MinValue,
  NumberField_RangeValue,
  NumberField_Unit,
} from 'yooi-modules/modules/conceptModule/ids';
import type { PlatformConfigurationStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import { CurrentPlatformConfiguration, PlatformConfiguration_ColorPalette } from 'yooi-modules/modules/platformConfigurationModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreReadOnly } from 'yooi-store';
import { compareNumber, comparing, filterNullOrUndefined, isFiniteNumber, joinObjects, numberType } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import NumberPicker from '../../../../components/inputs/NumberPicker';
import SimpleInput from '../../../../components/inputs/strategy/SimpleInput';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import { TableSortDirection } from '../../../../components/molecules/Table';
import { Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import { getInstanceMaxMinValues, TickResolutionStatus } from '../../fieldUtils';
import { getFormulaFieldEditionSection } from '../../formulaRendererUtils';
import StoreNumberPickerInput from '../../input/StoreNumberPickerInput';
import { createPathConfigurationHandler } from '../../pathConfigurationHandler';
import { getFieldTypeValidator } from '../../pathConfigurationHandlerUtils';
import { getBlockFieldLayoutOption, getLayoutDisplayOption } from '../_global/blockFieldUtils';
import { getApiAliasInitialState, getDimensionsEditionOption, getDocumentationFieldEditionSection, getIntegrationFieldEditionSection } from '../_global/editionHandlerUtils';
import NumberColorStepInput from '../_global/NumberColorStepInput';
import NumberColorStepsInput from '../_global/NumberColorStepsInput';
import { getOptionalOverridableDisplayTypeOption, getOverridableDisplayTypeLabel, getOverridableDisplayTypeOptions } from '../_global/overridableFieldUtils';
import { getGenericOperationMetadata } from '../_global/updateOperationHandlerUtils';
import UpdateOperationInput from '../_global/UpdateOperationInput';
import UpdateOperationSelector from '../_global/UpdateOperationSelector';
import { duplicateFormula } from '../duplicationUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  createAndLinkFieldToConceptDefinitions,
  duplicateFieldDimensionWithNewField,
  FIELD_DIMENSIONS_READONLY,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
  submitDimensionUpdate,
} from '../fieldDimensionUtils';
import type { FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, FieldComparatorHandler, GetFieldDefinitionHandler, RenderFilter } from '../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldIntegrationOnlyDisabled } from '../FieldLibraryTypes';
import NumberFieldRenderer from './NumberFieldRenderer';
import { getNumberRangeValues } from './numberFieldUtils';
import NumberWidgetField from './NumberWidgetField';

interface NumberFieldBlockDisplayOptions extends FieldBlockDisplayOptions {
  displayType?: OverridableDisplayType,
}

const defaultDisplayOption: NumberFieldBlockDisplayOptions = { displayType: OverridableDisplayType.Value };

const getDisplayOptions = (objectStore: ObjectStoreReadOnly, fieldBlockDisplayId: string): NumberFieldBlockDisplayOptions => {
  const fieldBlockDisplay = objectStore.getObject(fieldBlockDisplayId);
  if (fieldBlockDisplay[FieldBlockDisplay_FieldDisplayConfiguration]) {
    return fieldBlockDisplay[FieldBlockDisplay_FieldDisplayConfiguration] as NumberFieldBlockDisplayOptions;
  } else {
    return defaultDisplayOption;
  }
};

interface NumberFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [FIELD_DIMENSIONS_READONLY]: boolean | undefined,
  [Field_Title]?: string | null | undefined,
  [Field_ApiAlias]?: string | null | undefined,
  [Field_Documentation]?: string | null | undefined,
  [Field_IsDocumentationInline]?: boolean | null | undefined,
  [Field_IntegrationOnly]?: boolean | null | undefined,
  [FieldIntegrationOnlyDisabled]?: boolean | undefined,
  [Field_Formula]?: Formula | null | undefined,
  [NumberField_MaxValue]: NumberColorStepValue | null | undefined,
  [NumberField_RangeValue]: NumberColorStepsValue[] | null | undefined,
  [NumberField_MinValue]: NumberColorStepValue | null | undefined,
  [NumberField_Unit]: string | null | undefined,
  [NumberField_Decimals]: number | null | undefined,
  [NumberField_InvalidColor]: string | null | undefined,
}

type NumberFieldDefinition = GetFieldDefinitionHandler<typeof numberFieldHandler, NumberFieldConfigurationState, never, NumberFieldBlockDisplayOptions>;

export const numberFieldDefinition: NumberFieldDefinition = registerFieldDefinition(numberFieldHandler, {
  configuration: {
    typeIcon: IconName.tag,
    getTypeLabel: () => i18n`Number`,
    asWidget: true,
    getEditionOptions: (store) => ({ mode, editionHandler, readOnly }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode, FieldEditionOptionMode.Override].includes(mode)) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      if (mode === FieldEditionOptionMode.Field || mode === FieldEditionOptionMode.FieldDeveloperMode) {
        sections.push({
          key: 'data',
          type: 'section',
          title: i18n`Data`,
          options: [
            getDimensionsEditionOption(editionHandler, Boolean(editionHandler.getValue(Field_Formula)), readOnly || Boolean(editionHandler.getValue(FIELD_DIMENSIONS_READONLY))),
          ],
        });
      }

      const fieldEditionDimensions: FieldEditionDimensions = editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {};
      const parameterDefinitions: SingleParameterDefinition[] = Object.entries(fieldEditionDimensions)
        .map(([id, { typeId, label }]) => ({ id, typeId, label: label || i18n`Dimension`, type: 'dimension' }));

      if ([FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        sections.push(getDocumentationFieldEditionSection(editionHandler));
        sections.push(getIntegrationFieldEditionSection(store, editionHandler, mode));
      }

      sections.push({
        key: 'display',
        type: 'section',
        title: i18n`Display`,
        options: [
          {
            key: NumberField_InvalidColor,
            title: i18n`Invalid color`,
            info: i18n`Before min value and after max value`,
            hasValue: () => editionHandler.getValue(NumberField_InvalidColor) !== undefined,
            clearValue: () => editionHandler.updateValues({ [NumberField_InvalidColor]: null }),
            type: EditionOptionTypes.color,
            props: {
              readOnly,
              value: editionHandler.getValueOrDefault(NumberField_InvalidColor),
              onChange: (value) => editionHandler.updateValues({ [NumberField_InvalidColor]: value }),
              colorPalette: store.getObject<PlatformConfigurationStoreObject>(CurrentPlatformConfiguration)[PlatformConfiguration_ColorPalette],
            },
          },
          {
            key: NumberField_MinValue,
            title: i18n`Min value`,
            hasValue: () => editionHandler.getValue(NumberField_MinValue) !== undefined,
            clearValue: () => editionHandler.updateValues({ [NumberField_MinValue]: null }),
            type: EditionOptionTypes.custom,
            props: {
              render: () => (
                <NumberColorStepInput
                  value={
                    editionHandler.getValueOrDefault(NumberField_MinValue)
                    ?? { type: NumberColorStepValueType.value, value: undefined, color: undefined }
                  }
                  parameterDefinitions={parameterDefinitions}
                  onChange={(newValue) => editionHandler.updateValues({ [NumberField_MinValue]: newValue })}
                  readOnly={readOnly}
                />
              ),
            },
          },
          {
            key: NumberField_RangeValue,
            title: i18n`Ranges`,
            hasValue: () => editionHandler.getValue(NumberField_RangeValue) !== undefined,
            clearValue: () => editionHandler.updateValues({ [NumberField_RangeValue]: null }),
            type: EditionOptionTypes.custom,
            props: {
              render: () => (
                <NumberColorStepsInput
                  steps={editionHandler.getValueOrDefault(NumberField_RangeValue) ?? []}
                  onChange={(newSteps) => {
                    editionHandler.updateValues({
                      [NumberField_RangeValue]: newSteps,
                    });
                  }}
                  parameterDefinitions={parameterDefinitions}
                  readOnly={readOnly}
                />
              ),
            },
          },
          {
            key: NumberField_MaxValue,
            title: i18n`Max value`,
            hasValue: () => editionHandler.getValue(NumberField_MaxValue) !== undefined,
            clearValue: () => editionHandler.updateValues({ [NumberField_MaxValue]: null }),
            type: EditionOptionTypes.custom,
            props: {
              render: () => (
                <NumberColorStepInput
                  value={editionHandler.getValueOrDefault(NumberField_MaxValue) ?? { type: NumberColorStepValueType.value, value: undefined, color: undefined }}
                  parameterDefinitions={parameterDefinitions}
                  onChange={(newValue) => editionHandler.updateValues({ [NumberField_MaxValue]: newValue })}
                  readOnly={readOnly}
                />
              ),
            },
          },
          {
            key: NumberField_Unit,
            title: i18n`Unit`,
            hasValue: () => editionHandler.getValue(NumberField_Unit) !== undefined,
            clearValue: () => editionHandler.updateValues({ [NumberField_Unit]: null }),
            type: EditionOptionTypes.text,
            props: {
              readOnly,
              placeholder: i18n`Add unit`,
              value: editionHandler.getValueOrDefault(NumberField_Unit),
              onChange: (value) => editionHandler.updateValues({ [NumberField_Unit]: value }),
            },
          },
          {
            key: NumberField_Decimals,
            title: i18n`Decimals`,
            hasValue: () => editionHandler.getValue(NumberField_Decimals) !== undefined,
            clearValue: () => editionHandler.updateValues({ [NumberField_Decimals]: null }),
            type: EditionOptionTypes.number,
            props: {
              readOnly,
              placeholder: i18n`Add decimal`,
              value: editionHandler.getValueOrDefault(NumberField_Decimals),
              onChange: (value) => editionHandler.updateValues({ [NumberField_Decimals]: value }),
            },
          },
        ],
      });

      if ([FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        sections.push(getFormulaFieldEditionSection({
          store,
          parameterDefinitions,
          value: editionHandler.getValueOrDefault(Field_Formula),
          onChange: (value: Formula | null) => {
            editionHandler.updateValues({ [Field_Formula]: value });
          },
          hasValue: () => editionHandler.getValue(Field_Formula) !== undefined,
          clearValue: () => editionHandler.updateValues({ [Field_Formula]: null }),
          returnType: numberType,
        }));
      }

      return sections;
    },
    isCreationEnabled: () => () => true,
    onCreate: (objectStore) => (editionHandler) => {
      const fieldId = objectStore.createObject<NumberFieldRaw>({
        [Instance_Of]: NumberField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [Field_ApiAlias]: editionHandler.getValue(Field_ApiAlias),
        [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
        [Field_Formula]: editionHandler.getValue(Field_Formula),
        [NumberField_MinValue]: editionHandler.getValue(NumberField_MinValue),
        [NumberField_RangeValue]: editionHandler.getValue(NumberField_RangeValue),
        [NumberField_MaxValue]: editionHandler.getValue(NumberField_MaxValue),
        [NumberField_Unit]: editionHandler.getValue(NumberField_Unit),
        [NumberField_Decimals]: editionHandler.getValue(NumberField_Decimals),
        [NumberField_InvalidColor]: editionHandler.getValue(NumberField_InvalidColor),
      });
      linkFieldToFieldDimensions(objectStore, fieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});
      return fieldId;
    },
    inlineCreate: (objectStore) => (conceptDefinitionId, extraFieldOptions) => ({
      type: 'inline',
      onCreate: (title) => {
        const newFieldId = objectStore.createObject<NumberFieldRaw>(joinObjects(
          extraFieldOptions,
          { [Instance_Of]: NumberField, [Field_Title]: title }
        ));
        createAndLinkFieldToConceptDefinitions(objectStore, newFieldId, [conceptDefinitionId]);
        return newFieldId;
      },
    }),
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<NumberFieldStoreObject>(fieldId);
        return joinObjects(
          getApiAliasInitialState(objectStore, fieldId),
          {
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            [NumberField_MinValue]: field[NumberField_MinValue],
            [NumberField_RangeValue]: field[NumberField_RangeValue],
            [NumberField_MaxValue]: field[NumberField_MaxValue],
            [NumberField_Unit]: field[NumberField_Unit],
            [Field_Formula]: field[Field_Formula],
            [NumberField_Decimals]: field[NumberField_Decimals],
            [Field_IntegrationOnly]: field[Field_IntegrationOnly],
            [FieldIntegrationOnlyDisabled]: field[Field_IsCore],
            [NumberField_InvalidColor]: field[NumberField_InvalidColor],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
            [FIELD_DIMENSIONS_READONLY]: field[Field_IsCore],
          }
        );
      },
      getInitialOverrideState: (objectId) => {
        const localOverride = objectStore.withAssociation(FieldLocalOverride)
          .withRole(FieldLocalOverride_Role_Concept, objectId)
          .withRole(FieldLocalOverride_Role_Field, fieldId)
          .getObjectOrNull<FieldLocalOverrideStoreObject>();

        const conceptDefinitionId = objectStore.getObject(objectId)[Instance_Of] as string;
        const field = objectStore.getObject<FieldStoreObject>(fieldId);

        return {
          [NumberField_MinValue]: localOverride?.[FieldLocalOverride_NumberFieldMinValue],
          [NumberField_RangeValue]: localOverride?.[FieldLocalOverride_NumberFieldRangeValue],
          [NumberField_MaxValue]: localOverride?.[FieldLocalOverride_NumberFieldMaxValue],
          [NumberField_Unit]: localOverride?.[FieldLocalOverride_NumberFieldUnit],
          [NumberField_Decimals]: localOverride?.[FieldLocalOverride_NumberFieldDecimals],
          [NumberField_InvalidColor]: localOverride?.[FieldLocalOverride_NumberFieldInvalidColor],
          [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
          [FIELD_DIMENSIONS_READONLY]: field[Field_IsCore],
        };
      },
      submitFieldOverrideUpdate: (objectId, stateToSubmit) => {
        const update = {
          [FieldLocalOverride_NumberFieldMinValue]: stateToSubmit[NumberField_MinValue],
          [FieldLocalOverride_NumberFieldRangeValue]: stateToSubmit[NumberField_RangeValue],
          [FieldLocalOverride_NumberFieldMaxValue]: stateToSubmit[NumberField_MaxValue],
          [FieldLocalOverride_NumberFieldUnit]: stateToSubmit[NumberField_Unit],
          [FieldLocalOverride_NumberFieldDecimals]: stateToSubmit[NumberField_Decimals],
          [FieldLocalOverride_NumberFieldInvalidColor]: stateToSubmit[NumberField_InvalidColor],
        };

        objectStore.withAssociation(FieldLocalOverride)
          .withRole(FieldLocalOverride_Role_Field, fieldId)
          .withRole(FieldLocalOverride_Role_Concept, objectId)
          .updateObject<FieldLocalOverrideRaw>(update);
      },
      submitFieldUpdate: (stateToSubmit, conceptDefinitionId) => {
        objectStore.updateObject<NumberFieldRaw>(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [NumberField_MinValue]: stateToSubmit[NumberField_MinValue],
          [NumberField_RangeValue]: stateToSubmit[NumberField_RangeValue],
          [NumberField_MaxValue]: stateToSubmit[NumberField_MaxValue],
          [NumberField_Unit]: stateToSubmit[NumberField_Unit],
          [Field_Formula]: stateToSubmit[Field_Formula],
          [NumberField_Decimals]: stateToSubmit[NumberField_Decimals],
          [Field_IntegrationOnly]: stateToSubmit[Field_IntegrationOnly],
          [NumberField_InvalidColor]: stateToSubmit[NumberField_InvalidColor],
        });
        submitDimensionUpdate(objectStore, fieldId, conceptDefinitionId, stateToSubmit[FIELD_EDITION_DIMENSIONS] ?? {});
      },
      duplicateFieldDefinition: () => {
        const field = objectStore.getObject<NumberFieldStoreObject>(fieldId);
        const fieldDimensionMapping = generateDuplicatedFieldDimensionId(objectStore, fieldId);

        const duplicateStep = <Step extends NumberColorStepValue = NumberColorStepValue>(step: Step | undefined): Step | undefined => {
          if (!step) {
            return undefined;
          } else if (step.type === NumberColorStepValueType.field) {
            const newStep = { ...step };
            newStep.value = duplicatePath(step.value, fieldDimensionMapping);
            return newStep;
          } else {
            return step;
          }
        };

        const newFieldId = objectStore.createObject<NumberFieldRaw>({
          [Instance_Of]: field[Instance_Of],
          [Field_Title]: `${field[Field_Title]} (copy)`,
          [Field_Documentation]: field[Field_Documentation],
          [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: field[Field_IntegrationOnly],
          [NumberField_MinValue]: duplicateStep(field[NumberField_MinValue]),
          [NumberField_RangeValue]: field[NumberField_RangeValue]?.map((step) => duplicateStep(step)).filter(filterNullOrUndefined),
          [NumberField_MaxValue]: duplicateStep(field[NumberField_MaxValue]),
          [NumberField_Unit]: field[NumberField_Unit],
          [NumberField_Decimals]: field[NumberField_Decimals],
          [Field_Formula]: duplicateFormula(field[Field_Formula], fieldDimensionMapping),
          [NumberField_InvalidColor]: field[NumberField_InvalidColor],
        });
        duplicateFieldDimensionWithNewField(objectStore, newFieldId, fieldDimensionMapping);
        return newFieldId;
      },
    }),
  },
  renderWidget: (_, fieldId) => ({ parametersMapping }) => <NumberWidgetField fieldId={fieldId} parametersMapping={parametersMapping} />,
  renderField: (_, fieldId) => ({ dimensionsMapping, readOnly, onSubmit, value, focusOnMount }) => (
    <NumberFieldRenderer
      fieldId={fieldId}
      dimensionsMapping={dimensionsMapping}
      readOnly={readOnly}
      focusOnMount={focusOnMount ?? false}
      initialValue={value}
      onSubmit={onSubmit}
    />
  ),
  input: (store, fieldId, handler) => ({
    render: ({ value, onSubmit, focusOnMount, readOnly, onEditionStart, onEditionStop, isEditing }) => {
      const numberField = handler.resolveConfiguration();
      const { min, max } = getInstanceMaxMinValues(store, fieldId, undefined, {});
      const ticks = getNumberRangeValues(store, fieldId, undefined);

      return (
        <SimpleInput initialValue={value} onSubmit={onSubmit}>
          {(props) => (
            <NumberPicker
              value={props.value}
              onSubmit={(newValue) => props.onSubmit(isFiniteNumber(newValue) ? newValue : undefined)}
              onChange={(newValue) => props.onChange(isFiniteNumber(newValue) ? newValue as number : undefined)}
              onCancel={props.onCancel}
              min={min?.status === TickResolutionStatus.Resolved ? min : undefined}
              ticks={ticks}
              max={max?.status === TickResolutionStatus.Resolved ? max : undefined}
              invalidColor={numberField.invalidColor}
              decimals={numberField.decimals}
              unit={numberField.unit}
              readOnly={readOnly}
              withProgress
              onEditionStart={onEditionStart}
              onEditionStop={onEditionStop}
              isEditing={isEditing}
              placeholder={i18n`Add number`}
              focusOnMount={focusOnMount}
            />
          )}
        </SimpleInput>
      );
    },
    getInitialState: () => undefined,
    persistStateOnConcept: (dimension, value) => handler.updateValue(dimension, value ?? null),
  }),
  renderExportConfiguration: () => () => (
    <SpacedContainer margin={{ x: Spacing.splus }}>
      <Tooltip title={i18n`Number`}>
        <Typo maxLine={1}>{i18n`Number`}</Typo>
      </Tooltip>
    </SpacedContainer>
  ),
  getActivityProperties: (_, fieldId) => () => [fieldId],
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({
    propertyId: fieldId,
    focusable: true,
    sortable: true,
  }),
  estimatedColumnWidth: () => () => '15rem',
  getComparatorHandler: (_, __, { getValueResolution }) => (direction) => ({
    comparator: comparing(compareNumber, direction === TableSortDirection.desc),
    extractValue: (dimensionsMapping) => {
      const { value } = getValueResolution(dimensionsMapping);
      return typeof value === 'number' ? value : undefined;
    },
  }) satisfies FieldComparatorHandler<number | undefined>,
  filterConditions: () => {
    const renderNumberFilter: RenderFilter<FilterValueRaw<number | undefined>> = (value, setValue, _, readOnly) => (
      <StoreNumberPickerInput
        placeholder={i18n`Value`}
        initialValue={value.raw}
        onSubmit={(newValue) => {
          if (newValue !== null && !isFiniteNumber(newValue)) {
            return;
          }
          setValue({ type: FilterValueType.raw, raw: typeof newValue === 'string' ? Number(newValue) : (newValue ?? undefined) });
        }}
        readOnly={readOnly}
      />
    );

    return {
      EQUALS: {
        name: '=',
        renderFilter: renderNumberFilter,
      },
      NOT_EQUALS: {
        name: '≠',
        renderFilter: renderNumberFilter,
      },
      GREATER: {
        name: '>',
        renderFilter: renderNumberFilter,
      },
      GREATER_OR_EQUALS: {
        name: '≥',
        renderFilter: renderNumberFilter,
      },
      LOWER: {
        name: '<',
        renderFilter: renderNumberFilter,
      },
      LOWER_OR_EQUALS: {
        name: '≤',
        renderFilter: renderNumberFilter,
      },
      IS_EMPTY: {
        name: i18n`Is empty`,
      },
      IS_NOT_EMPTY: {
        name: i18n`Is not empty`,
      },
    };
  },
  getUpdateOperationInput: (store, fieldId, { updateOperationHandlers: { INITIALIZE, REPLACE, INCREMENT, CLEAR } }) => (operation) => {
    const { title, getIcon } = getGenericOperationMetadata(store, operation, fieldId);
    return {
      title,
      getIcon,
      render: ({ onSubmit, parameterDefinitions }) => {
        const operationSelectorLine = (
          <UpdateOperationSelector<typeof numberFieldHandler>
            selectedOperationAction={operation?.action}
            operationOptions={[
              { id: 'INITIALIZE', label: i18n`Initialize`, onSelect: () => onSubmit({ action: 'INITIALIZE', payload: INITIALIZE.sanitizeOperation(operation) }) },
              { id: 'REPLACE', label: i18n`Replace`, onSelect: () => onSubmit({ action: 'REPLACE', payload: REPLACE.sanitizeOperation(operation) }) },
              { id: 'INCREMENT', label: i18n`Increment`, onSelect: () => onSubmit({ action: 'INCREMENT', payload: INCREMENT.sanitizeOperation(operation) }) },
              { id: 'CLEAR', label: i18n`Clear`, onSelect: () => onSubmit({ action: 'CLEAR', payload: CLEAR.sanitizeOperation(operation) }) },
            ]}
          />
        );

        let operationInputsLines = null;
        const { input } = numberFieldDefinition(store).getHandler(fieldId);
        if (input && operation && operation.action !== 'CLEAR' && operation.payload) {
          operationInputsLines = (
            <UpdateOperationInput<typeof numberFieldHandler>
              valueInput={input}
              initialValue={operation.payload}
              onTypeChange={(newType) => {
                onSubmit({ action: operation.action, payload: newType === 'value' ? { type: 'value', value: undefined } : { type: 'path', path: [] } });
              }}
              onValueSubmit={(newValue) => onSubmit({ action: operation.action, payload: newValue })}
              parameterDefinitions={parameterDefinitions}
              valuePathHandler={createPathConfigurationHandler(store, parameterDefinitions, [getFieldTypeValidator(store, [NumberField], i18n`Input should end with a number.`)])}
            />
          );
        }

        return (
          <>
            {operationSelectorLine}
            {operationInputsLines}
          </>
        );
      },
    };
  },
  blockDisplayOptionsHandler: (objectStore) => (fieldDisplayBlockId) => ({
    getDisplayOptions: () => getDisplayOptions(objectStore, fieldDisplayBlockId),
    renderSummary: (state) => [
      getOverridableDisplayTypeLabel(state.displayType),
      getBlockFieldLayoutOption()[state.layoutDisplayType ?? BlockFieldLayoutOption.auto].label,
    ],
    getBlockEditionOptionSections: (state, setState) => [
      getLayoutDisplayOption(state, setState),
      {
        key: 'displayType',
        title: i18n`Display type`,
        action: {
          type: EditionOptionTypes.select,
          props: {
            computeOptions: () => getOverridableDisplayTypeOptions(),
            selectedOption: getOptionalOverridableDisplayTypeOption(state.displayType),
            onChange: (selectedOption) => {
              if (selectedOption) {
                setState(joinObjects(state, { displayType: selectedOption.id as OverridableDisplayType }));
              }
            },
          },
        },
        options: [],
      },
    ],
    onSubmit: (state) => {
      objectStore.updateObject(fieldDisplayBlockId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
});
