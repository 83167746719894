import type { FunctionComponent } from 'react';
import type { AssociationFilterStoreObject, ParametersMapping, PathStep, SingleParameterDefinition, ViewFilterStoreObject } from 'yooi-modules/modules/conceptModule';
import {
  createValuePathResolver,
  FILTER_PARAMETER_CURRENT,
  InstanceReferenceType,
  isDimensionStep,
  isMappingStep,
  isMultiValueResolution,
  isSingleValueResolution,
  isValidValuePathResolution,
  PathStepType,
} from 'yooi-modules/modules/conceptModule';
import { AssociationFilter_Path, ConceptDefinition_ViewFilters, ViewFilter_Rank } from 'yooi-modules/modules/conceptModule/ids';
import type { StoreObject } from 'yooi-store';
import { compareProperty, compareRank } from 'yooi-utils';
import { EditableButtonVariant } from '../../../components/molecules/EditableWithDropdown';
import useAuth from '../../../store/useAuth';
import useStore from '../../../store/useStore';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { formatOrUndef } from '../../../utils/stringUtils';
import { SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import { getConceptDefinitionNameOrEntity } from '../modelTypeUtils';
import AllFiltersComposite from './AllFiltersComposite';
import ConditionFilterToggle from './ConditionFilterToggle';
import { getLoggedUserParameterDefinition } from './filterUtils';
import InstanceFilterComposite from './InstanceFilterComposite';
import { getQuickFilterInstances, isAssociationFilter, isConditionFilter } from './quickFiltersUtils';
import TemporaryFilterComposite from './TemporaryFilterComposite';
import { FilterParams, useFilterStorage, useResetFilterStorage } from './useFilterSessionStorage';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacingRem.s,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  child: {
    display: 'flex',
    flexGrow: 0,
  },
}, 'favoriteFiltersBar');

interface CommonFavoriteFiltersBarProps {
  filterKey: string,
  parameterDefinitions?: SingleParameterDefinition[],
  dimensionParameters?: SingleParameterDefinition[],
  parametersMapping?: ParametersMapping,
}

interface FavoriteFiltersBarProps extends CommonFavoriteFiltersBarProps {
  getViewFilters: () => ViewFilterStoreObject[],
  getViewPath?: () => PathStep[],
}

export const FavoriteFiltersBar: FunctionComponent<FavoriteFiltersBarProps> = ({
  filterKey,
  parameterDefinitions = [],
  dimensionParameters = [],
  parametersMapping,
  getViewFilters,
  getViewPath,
}) => {
  const store = useStore();
  const classes = useStyles();
  const { loggedUserId } = useAuth();

  const getPrefilteredInstances = getViewPath ? (
    conceptDefinitionIdToFilter: string,
    associationFilter: AssociationFilterStoreObject
  ): string[] | undefined => {
    const filterPath = associationFilter[AssociationFilter_Path];
    if (filterPath.length === 2 && isDimensionStep(filterPath[0]) && filterPath[0].conceptDefinitionId === conceptDefinitionIdToFilter
      && isMappingStep(filterPath[1]) && filterPath[1].mapping.id === FILTER_PARAMETER_CURRENT) {
      const path = getViewPath();
      const pathResolver = parametersMapping ? createValuePathResolver(store, parametersMapping) : undefined;
      const pathResolution = path && pathResolver && pathResolver.resolvePathValue<StoreObject>(path);
      if (pathResolution && isValidValuePathResolution(pathResolution)) {
        if (isMultiValueResolution(pathResolution)) {
          return pathResolution.values.map((object) => object.id);
        } else if (isSingleValueResolution(pathResolution)) {
          return pathResolution.value ? [pathResolution.value.id] : [];
        }
      }
    }
    return undefined;
  } : undefined;

  const filterParameterDefinitions: SingleParameterDefinition[] = [
    ...parameterDefinitions,
    getLoggedUserParameterDefinition(),
  ];
  const quickFilters: ViewFilterStoreObject[] = getViewFilters()
    .filter((quickFilter) => !isAssociationFilter(quickFilter)
      || getQuickFilterInstances(store, quickFilter, filterParameterDefinitions, loggedUserId, undefined, parametersMapping).length > 0)
    .sort(compareProperty(ViewFilter_Rank, compareRank));

  const resetFilterStorage = useResetFilterStorage(filterKey);
  const [filterSessionStorageState = {}, setFilterSessionStorageState] = useFilterStorage(filterKey, FilterParams.filters);

  return (
    <SizeContextProvider sizeVariant={SizeVariant.small}>
      <div className={classes.container}>
        {quickFilters.length > 0 && (
          <AllFiltersComposite
            filters={filterSessionStorageState}
            onChange={setFilterSessionStorageState}
            onReset={resetFilterStorage}
            parameterDefinitions={filterParameterDefinitions}
            getPrefilteredInstances={getPrefilteredInstances}
            parametersMapping={parametersMapping}
            getViewFilters={getViewFilters}
          />
        )}
        {
          quickFilters.map((viewFilter) => {
            if (isConditionFilter(viewFilter)) {
              return (
                <div className={classes.child} key={`${filterKey}_${viewFilter.id}`}>
                  <ConditionFilterToggle
                    conditionFilterId={viewFilter.id}
                    filterKey={filterKey}
                  />
                </div>
              );
            } else if (isAssociationFilter(viewFilter)) {
              return (
                <div className={classes.child} key={`${filterKey}_${viewFilter.id}`}>
                  <InstanceFilterComposite
                    parameterDefinitions={filterParameterDefinitions}
                    filterKey={filterKey}
                    instanceFilterId={viewFilter.id}
                    getPrefilteredInstances={getPrefilteredInstances}
                    parametersMapping={parametersMapping}
                  />
                </div>
              );
            }
            return null;
          })
        }
        {dimensionParameters.length === 1 && (
          <TemporaryFilterComposite
            filterKey={filterKey}
            rootPath={dimensionParameters.length === 1 ? {
              label: i18n`Current (${formatOrUndef(getConceptDefinitionNameOrEntity(store, dimensionParameters[0].typeId))})`,
              path: [
                { type: PathStepType.dimension, conceptDefinitionId: dimensionParameters[0].typeId },
                { type: PathStepType.mapping, mapping: { type: InstanceReferenceType.parameter, id: dimensionParameters[0].id } },
              ],
            } : undefined}
            suggestedPaths={dimensionParameters.map((parameter) => ({
              label: parameter.label,
              path: [{ type: PathStepType.dimension, conceptDefinitionId: parameter.typeId }, {
                type: PathStepType.mapping,
                mapping: { type: InstanceReferenceType.parameter, id: parameter.id },
              }],
            }))}
            noFiltersButtonVariant={quickFilters.length > 0 ? EditableButtonVariant.tertiary : undefined}
            getViewFilters={getViewFilters}
            parameterDefinitions={filterParameterDefinitions}
          />
        )}
      </div>
    </SizeContextProvider>
  );
};

type ConceptDefinitionFavoriteFiltersBarProps = CommonFavoriteFiltersBarProps & { conceptDefinitionId: string };
export const ConceptDefinitionFavoriteFiltersBar: FunctionComponent<ConceptDefinitionFavoriteFiltersBarProps> = ({
  conceptDefinitionId,
  ...props
}) => {
  const store = useStore();
  const getViewsFilters: () => ViewFilterStoreObject[] = () => store.getObject(conceptDefinitionId).navigateBack<ViewFilterStoreObject>(ConceptDefinition_ViewFilters);
  const currentDimension: SingleParameterDefinition = {
    id: FILTER_PARAMETER_CURRENT,
    type: 'dimension',
    typeId: conceptDefinitionId,
    label: i18n`Current (${getConceptDefinitionNameOrEntity(store, conceptDefinitionId)})`,
  };
  return (
    <FavoriteFiltersBar
      {...props}
      parameterDefinitions={[
        ...props.parameterDefinitions ?? [],
        currentDimension,
      ]}
      dimensionParameters={[currentDimension]}
      getViewFilters={getViewsFilters}
    />
  );
};
