import type { FunctionComponent } from 'react';
import type { SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import type { MatrixViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { joinObjects } from 'yooi-utils';
import BlockContent from '../../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../../components/templates/BlockTitle';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { getColorPalette } from '../../../utils/standardColorsUtils';
import type { LineEditionOption } from '../../fields/FieldEditionOptionType';
import { EditionOptionTypes } from '../../fields/FieldEditionOptionType';
import type { UpdateViewDefinition } from '../../fields/viewsField/ViewsFieldDefinitionOptions';
import StoreColorPickerInput from '../../input/StoreColorPickerInput';
import StoreTextInputField from '../../input/StoreTextInputField';
import { getFieldChipOrUnknown, listColorFieldOptions, listDependenciesFieldOptions, listLabelByFieldOptions, listMatrixFieldOptions } from '../../modelTypeUtils';
import ViewOptionBlock from '../common/ViewOptionBlock';
import { getLineErrorContent, getViewConceptConceptDefinitionId } from '../common/viewUtils';
import type { MatrixViewResolvedDefinition } from './matrixViewDefinitionHandler';

const useStyles = makeStyles({
  quadrantBlock: {
    gridColumnStart: 4,
    display: 'flex',
    flexDirection: 'row',
    minHeight: '3.2rem',
    columnGap: spacingRem.s,
  },
}, 'matrixViewDefinitionOptions');

interface MatrixViewDefinitionOptionsProps {
  viewDimensions: ViewDimension[],
  viewDefinition: MatrixViewResolvedDefinition,
  updateViewDefinition: UpdateViewDefinition<MatrixViewStoredDefinition>,
  readOnly: boolean,
  parameterDefinitions: SingleParameterDefinition[],
}

const MatrixViewDefinitionOptions: FunctionComponent<MatrixViewDefinitionOptionsProps> = ({
  viewDimensions,
  viewDefinition,
  updateViewDefinition,
  readOnly,
  parameterDefinitions,
}) => {
  const classes = useStyles();

  const store = useStore();

  const result = getViewConceptConceptDefinitionId(store, viewDimensions, parameterDefinitions, true);
  if (result.error !== undefined) {
    return (<BlockContent padded>{getLineErrorContent(result.error)}</BlockContent>);
  }
  const { conceptDefinitionId } = result;

  const getQuadrantOption = (key: 'topLeftQuadrant' | 'topRightQuadrant' | 'bottomLeftQuadrant' | 'bottomRightQuadrant', title: string): LineEditionOption => ({
    key,
    title,
    skipBlockContent: true,
    type: EditionOptionTypes.custom,
    props: {
      render: () => (
        <span className={classes.quadrantBlock}>
          <StoreColorPickerInput
            initialValue={viewDefinition[key].color}
            onSubmit={(newColor) => (
              updateViewDefinition((oldViewDefinition) => {
                const newViewDefinition = { ...oldViewDefinition };
                newViewDefinition[key] = { color: newColor ?? undefined, name: oldViewDefinition[key]?.name };
                return newViewDefinition;
              })
            )}
            readOnly={readOnly}
            colorPalette={getColorPalette(store)}
          />
          <StoreTextInputField
            initialValue={viewDefinition[key].name}
            maxLine={1}
            dropdownMaxLine={1}
            onSubmit={(newName) => (
              updateViewDefinition((oldViewDefinition) => {
                const newViewDefinition = { ...oldViewDefinition };
                newViewDefinition[key] = { color: oldViewDefinition[key]?.color, name: newName ?? undefined };
                return newViewDefinition;
              })
            )}
            placeholder={i18n`Add name`}
          />
        </span>
      ),
    },
  });

  let yAxisError: string | undefined;
  if (viewDefinition.yAxisFieldId === undefined) {
    yAxisError = i18n`Y-axis is not defined.`;
  } else if (store.getObjectOrNull(viewDefinition.yAxisFieldId) === null) {
    yAxisError = i18n`Y-axis is invalid.`;
  }

  let xAxisError: string | undefined;
  if (viewDefinition.xAxisFieldId === undefined) {
    xAxisError = i18n`X-axis is not defined.`;
  } else if (store.getObjectOrNull(viewDefinition.xAxisFieldId) === null) {
    xAxisError = i18n`X-axis is invalid.`;
  }

  return (
    <>
      <BlockTitle title={i18n`Axis`} variant={BlockTitleVariant.tertiary} />
      <ViewOptionBlock
        option={{
          key: 'Y_AXIS_FIELD',
          title: i18n`Y-axis`,
          error: yAxisError,
          type: EditionOptionTypes.select,
          props: {
            clearable: true,
            placeholder: i18n`Select field`,
            selectedOption: viewDefinition.yAxisFieldId ? getFieldChipOrUnknown(store, conceptDefinitionId, viewDefinition.yAxisFieldId) : undefined,
            computeOptions: () => listMatrixFieldOptions(store, conceptDefinitionId),
            readOnly,
            onChange: (value) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { yAxisFieldId: value?.id as string | undefined }))),
          },
        }}
      />
      <ViewOptionBlock
        option={{
          key: 'X_AXIS_FIELD',
          title: i18n`X-axis`,
          error: xAxisError,
          type: EditionOptionTypes.select,
          props: {
            clearable: true,
            placeholder: i18n`Select field`,
            selectedOption: viewDefinition.xAxisFieldId ? getFieldChipOrUnknown(store, conceptDefinitionId, viewDefinition.xAxisFieldId) : undefined,
            computeOptions: () => listMatrixFieldOptions(store, conceptDefinitionId),
            readOnly,
            onChange: (value) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { xAxisFieldId: value?.id as string | undefined }))),
          },
        }}
      />

      <BlockTitle title={i18n`Quadrants`} variant={BlockTitleVariant.tertiary} />
      <ViewOptionBlock option={getQuadrantOption('topLeftQuadrant', i18n`Top left`)} />
      <ViewOptionBlock option={getQuadrantOption('topRightQuadrant', i18n`Top right`)} />
      <ViewOptionBlock option={getQuadrantOption('bottomLeftQuadrant', i18n`Bottom left`)} />
      <ViewOptionBlock option={getQuadrantOption('bottomRightQuadrant', i18n`Bottom right`)} />

      <BlockTitle title={i18n`Chips`} variant={BlockTitleVariant.tertiary} />
      <ViewOptionBlock
        option={{
          key: 'LABEL_FIELD',
          title: i18n`Field for label`,
          type: EditionOptionTypes.select,
          props: {
            clearable: true,
            placeholder: i18n`Select field`,
            selectedOption: viewDefinition.labelFieldId ? getFieldChipOrUnknown(store, conceptDefinitionId, viewDefinition.labelFieldId) : undefined,
            computeOptions: () => listLabelByFieldOptions(store, conceptDefinitionId),
            readOnly,
            onChange: (value) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { labelFieldId: value?.id as string | undefined }))),
          },
        }}
      />
      <ViewOptionBlock
        option={{
          key: 'COLOR_FIELD',
          title: i18n`Field for color by`,
          type: EditionOptionTypes.select,
          props: {
            clearable: true,
            placeholder: i18n`Select field`,
            selectedOption: viewDefinition.colorFieldId ? getFieldChipOrUnknown(store, conceptDefinitionId, viewDefinition.colorFieldId) : undefined,
            computeOptions: () => listColorFieldOptions(store, conceptDefinitionId),
            readOnly,
            onChange: (value) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { colorFieldId: value?.id as string | undefined }))),
          },
        }}
      />
      <ViewOptionBlock
        option={{
          key: 'DEPENDENCY_FIELD',
          title: i18n`Field for dependency`,
          type: EditionOptionTypes.select,
          props: {
            clearable: true,
            placeholder: i18n`Select field`,
            selectedOption: viewDefinition.dependenciesFieldId ? getFieldChipOrUnknown(store, conceptDefinitionId, viewDefinition.dependenciesFieldId) : undefined,
            computeOptions: () => listDependenciesFieldOptions(store, conceptDefinitionId),
            readOnly,
            onChange: (value) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { dependenciesFieldId: value?.id as string | undefined }))),
          },
        }}
      />

      <BlockTitle title={i18n`Behaviors`} variant={BlockTitleVariant.tertiary} />
      <ViewOptionBlock
        option={{
          key: 'DEFAULT_MATRIX_READ_ONLY_FIELD',
          title: i18n`Read only`,
          padded: true,
          type: EditionOptionTypes.checkbox,
          props: {
            checked: viewDefinition.readOnly ?? false,
            disabled: readOnly,
            onChange: (value) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { readOnly: value }))),
          },
        }}
      />
      <ViewOptionBlock
        option={{
          key: 'SHOW_UNASSESSED',
          title: i18n`Show unassessed`,
          type: EditionOptionTypes.checkbox,
          padded: true,
          props: {
            checked: viewDefinition.showBacklog,
            onChange: (newValue) => updateViewDefinition((oldViewDefinition) => (joinObjects(oldViewDefinition, { showBacklog: newValue }))),
            disabled: readOnly,
          },
        }}
      />
    </>
  );
};

export default MatrixViewDefinitionOptions;
