import type { FunctionComponent } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { ViewsFieldStoreObject } from 'yooi-modules/modules/dashboardModule';
import { isLineChartViewDefinition, isTemporalBarChartViewDefinition } from 'yooi-modules/modules/dashboardModule';
import { ViewsField_Definition } from 'yooi-modules/modules/dashboardModule/ids';
import { compareNumber, extractAndCompareValue } from 'yooi-utils';
import MiniBarChart from '../../../../components/charts/BarChart/MiniBarChart';
import MiniLineChart from '../../../../components/charts/LineChart/MiniLineChart';
import type { FrontObjectStore } from '../../../../store/useStore';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import useDeepMemo from '../../../../utils/useDeepMemo';
import { lineChartViewHandler } from '../../views/lineChart/lineChartViewDefinitionHandler';
import type { LineChartViewResolution } from '../../views/lineChart/lineChartViewResolution';
import { isLineChartLoaded, resolveLineChartView } from '../../views/lineChart/lineChartViewResolution';
import { temporalBarChartViewHandler } from '../../views/temporalBarChart/temporalBarChartViewHandler';
import type { TemporalBarChartViewResolution } from '../../views/temporalBarChart/temporalBarChartViewResolution';
import { isTemporalBarChartLoaded, resolveTemporalBarChart } from '../../views/temporalBarChart/temporalBarChartViewResolution';
import type { ViewResolutionError } from '../../views/viewResolutionUtils';
import { isResolutionError } from '../../views/viewResolutionUtils';
import TemporalChartInline from '../_global/chart/TemporalChartInline';

interface ViewsGroupCellProps {
  fieldId: string,
  parametersMapping: ParametersMapping,
}

const getValueResolution = (
  store: FrontObjectStore,
  fieldId: string,
  parametersMapping: ParametersMapping
): TemporalBarChartViewResolution | LineChartViewResolution | ViewResolutionError => {
  const field = store.getObjectOrNull<ViewsFieldStoreObject>(fieldId);
  const viewsDefinition = field?.[ViewsField_Definition];
  const viewDefinition = viewsDefinition?.views?.[0];
  if (!viewDefinition || !viewsDefinition?.data) {
    return { type: 'error', error: i18n`Missing Data` };
  }

  if (isTemporalBarChartViewDefinition(viewDefinition)) {
    const resolveDefinition = temporalBarChartViewHandler.resolveDefinition(store, viewDefinition, viewsDefinition.data);
    return resolveTemporalBarChart(store, viewsDefinition.data, resolveDefinition, parametersMapping);
  } else if (isLineChartViewDefinition(viewDefinition)) {
    const resolveDefinition = lineChartViewHandler.resolveDefinition(store, viewDefinition, viewsDefinition.data);
    return resolveLineChartView(store, viewsDefinition.data, resolveDefinition, parametersMapping);
  } else {
    return { type: 'error', error: i18n`Invalid configuration` };
  }
};

const ViewsGroupCell: FunctionComponent<ViewsGroupCellProps> = ({ fieldId, parametersMapping }) => {
  const store = useStore();

  const field = store.getObject<ViewsFieldStoreObject>(fieldId);
  const configuration = field[ViewsField_Definition]?.views.filter((v) => isLineChartViewDefinition(v) || isTemporalBarChartViewDefinition(v))?.[0];

  const valueResolution = useDeepMemo(
    () => (configuration ? getValueResolution(store, fieldId, parametersMapping) : undefined),
    [store.getSerial(), fieldId, parametersMapping, configuration]
  );

  if (configuration && valueResolution) {
    if (isResolutionError(valueResolution)) {
      return <TemporalChartInline errorMessage={valueResolution.error} />;
    } else if ((isLineChartLoaded(valueResolution) || isTemporalBarChartLoaded(valueResolution))) {
      const seriesToShow = valueResolution.series.at(0);
      const rowToShow = seriesToShow?.rows.at(0)?.stackedRows.at(0);
      const values = rowToShow?.values
        .filter(({ time }) => rowToShow.domain.xDomain && time >= rowToShow.domain.xDomain[0] && time <= rowToShow.domain.xDomain[1])
        .sort(extractAndCompareValue((({ time }) => time), compareNumber))
        .map(({ y }) => y) ?? [];
      const lastValue = values.length > 0 ? values[values.length - 1] : undefined;
      const originalValue = values.length > 1 ? values[values.length - 2] : undefined;
      const evolution = lastValue !== undefined && originalValue !== undefined ? ((lastValue - originalValue) / originalValue) * 100 : undefined;

      return (
        <TemporalChartInline
          chartComponent={(height, w) => {
            if (seriesToShow === undefined) {
              return null;
            } else if (isTemporalBarChartViewDefinition(configuration)) {
              return (
                <MiniBarChart series={seriesToShow} height={height} width={w} periodicity={valueResolution.periodicity} />
              );
            } else {
              return (
                <MiniLineChart series={seriesToShow} height={height} width={w} />
              );
            }
          }}
          lastValue={lastValue}
          evolution={evolution}
          unit={rowToShow?.info.unit}
        />
      );
    } else {
      return <TemporalChartInline loading />;
    }
  } else {
    return <TemporalChartInline errorMessage="Invalid configuration" />;
  }
};

export default ViewsGroupCell;
