import type { FunctionComponent } from 'react';
import { PeriodicityType } from 'yooi-utils';
import { ButtonVariant } from '../../../../components/atoms/Button';
import { IconName } from '../../../../components/atoms/Icon';
import DateInput from '../../../../components/inputs/datePickers/DateInput';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import type { TableSortDirection } from '../../../../components/molecules/Table';
import TableInnerCellContainer, { TableInnerCellContainerVariants } from '../../../../components/molecules/TableInnerCellContainer';
import type { ItemEntry } from '../../../../components/templates/DataTable';
import DataTable from '../../../../components/templates/DataTable';
import type { Pagination } from '../../../../components/templates/PageSelector';
import useStore from '../../../../store/useStore';
import { buildPadding, Spacing, spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { getChipOptions } from '../../modelTypeUtils';
import type { AttachmentEntry } from './AttachmentFieldRenderer';

const useStyles = makeStyles(
  (theme) => ({
    paddedContainer: {
      borderLeftWidth: '0.1rem',
      borderLeftStyle: 'solid',
      borderLeftColor: theme.color.transparent,
      paddingLeft: spacingRem.s,
    },
  }),
  'attachmentFieldTableRenderer'
);

interface AttachmentFieldTableRendererProps {
  attachments: ItemEntry<AttachmentEntry>[],
  pagination: Pagination,
  doSort: (propertyId: string) => void,
  sortCriteria: { key: string, direction: TableSortDirection } | undefined,
  onCreate: () => void,
  readOnly: boolean,
}

const AttachmentFieldTableRenderer: FunctionComponent<AttachmentFieldTableRendererProps> = ({ attachments, pagination, onCreate, readOnly, doSort, sortCriteria }) => {
  const store = useStore();
  const classes = useStyles();

  return (
    <div className={classes.paddedContainer}>
      <DataTable
        list={attachments.map((attachment) => ({ key: attachment.key, type: 'item', item: attachment, color: undefined }))}
        newItemTitle={!readOnly ? i18n`Attach file` : undefined}
        newItemIcon={!readOnly ? IconName.add : undefined}
        newItemButtonVariant={!readOnly ? ButtonVariant.secondary : undefined}
        onNewItem={!readOnly ? () => onCreate() : undefined}
        doSort={doSort}
        pagination={pagination}
        sortCriteria={sortCriteria}
        columnsDefinition={[
          {
            propertyId: 'name',
            name: i18n`Name`,
            sortable: true,
            cellRender: ({ item: { buildLink } }) => (
              <TableInnerCellContainer variant={TableInnerCellContainerVariants.centeredFlex} padding={buildPadding({ x: Spacing.s })}>
                {buildLink()}
              </TableInnerCellContainer>
            ),
          },
          {
            propertyId: 'uploadedOn',
            name: i18n`Uploaded on`,
            sortable: true,
            width: 20,
            cellRender: ({ item: { uploadedOn } }) => (uploadedOn ? (
              <DateInput
                value={{ period: PeriodicityType.day, date: uploadedOn }}
                readOnly
              />
            ) : null),
          },
          {
            propertyId: 'uploadedBy',
            name: i18n`Uploaded by`,
            sortable: true,
            width: 20,
            cellRender: ({ item: { uploadedBy } }) => (uploadedBy ? (
              <SearchAndSelect selectedOption={getChipOptions(store, uploadedBy)} readOnly />
            ) : null),
          },
        ]}
        linesActions={({ item: { onDownload, onDelete } }) => ([
          {
            key: 'Download',
            icon: IconName.download,
            name: i18n`Download`,
            onClick: () => onDownload(),
          },
          {
            key: 'Delete',
            icon: IconName.delete,
            name: i18n`Delete`,
            hidden: readOnly,
            onClick: () => onDelete(),
            danger: true,
          },
        ])}
        fullWidth
      />
    </div>
  );
};

export default AttachmentFieldTableRenderer;
