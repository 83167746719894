import type { ObjectStore, ObjectStoreReadOnly, StoreObject } from 'yooi-store';
import { compareDate, compareProperty, compareRank, comparing, extractAndCompareValue, newError, pushUndefinedToEnd, textToRichText } from 'yooi-utils';
import type { CollaborationStoreObject, IntentionStoreObject } from '../../collaborationModule';
import {
  Collaboration_CreatedAt,
  Collaboration_Intention,
  Collaboration_Status,
  CollaborationGroups,
  CollaborationGroups_Role_Collaboration,
  CollaborationGroups_Role_Group,
  CollaborationOnMultipleContext,
  CollaborationOnMultipleContext_Role_Collaboration,
  CollaborationOnMultipleContext_VarRoles_Contexts,
  CollaborationUsers,
  CollaborationUsers_Role_Collaboration,
  CollaborationUsers_Role_User,
  Intention_Workflow,
  IntentionStatusEntries,
  IntentionStatusEntries_Role_Entry,
  IntentionStatusEntries_Role_Intention,
  IntentionStatusIsClosedStatus,
} from '../../collaborationModule/ids';
import { Integration } from '../../integrationModule/ids';
import { isInstanceOf } from '../../typeModule';
import { Class_Instances, Instance_Of } from '../../typeModule/ids';
import {
  Association,
  Association_Role_Definition,
  Association_Role_Role1TypeInstance,
  Association_Role_Role2TypeInstance,
  Concept,
  Concept_FunctionalId,
  Concept_IsValid,
  Concept_Name,
  Concept_SwimlaneRank,
  ConceptCapabilityAssignCollaborator,
  ConceptCapabilityAssignUser,
  ConceptCapabilityCreate,
  ConceptCapabilityDelete,
  ConceptCapabilityEdit,
  ConceptCapabilityOverrideCollaborationWorkflow,
  ConceptCapabilityOverrideWorkflow,
  ConceptCapabilityRead,
  ConceptDefinition_Roles,
  ConceptGroupCapability,
  ConceptGroupCapability_Role_ConceptCapability,
  ConceptGroupCapability_Role_ConceptDefinition,
  ConceptGroupCapability_Role_ConceptGroup,
  ConceptIntegrationCapability,
  ConceptIntegrationCapability_Role_ConceptCapability,
  ConceptIntegrationCapability_Role_Definition,
  ConceptIntegrationCapability_Role_Integration,
  ConceptRole,
  ConceptRole_AssignedByDefault,
  ConceptRole_ConceptDefinition,
  ConceptRole_ForCollaboration,
  ConceptRoleCapability,
  ConceptRoleCapability_Role_ConceptCapability,
  ConceptRoleCapability_Role_ConceptRole,
  ConceptRoleGroupAssignation,
  ConceptRoleGroupAssignation_Role_Concept,
  ConceptRoleGroupAssignation_Role_ConceptRole,
  ConceptRoleGroupAssignation_Role_Group,
  ConceptRoleUserAssignation,
  ConceptRoleUserAssignation_Role_Concept,
  ConceptRoleUserAssignation_Role_ConceptRole,
  ConceptRoleUserAssignation_Role_User,
  EmbeddingField_FromType,
  EmbeddingField_ToType,
  Field_IntegrationOnly,
  Group,
  GroupMembershipAssociationDefinition,
  IdFieldAssociation,
  IdFieldAssociation_NextId,
  IdFieldAssociation_Role_ConceptDefinition,
  IdFieldAssociation_Role_Field,
  KinshipRelation,
  User,
  WorkflowEntry,
  WorkflowEntry_Rank,
  WorkflowEntry_Role_Concept,
  WorkflowEntry_Role_Workflow,
} from '../ids';
import type { ConceptStoreObject, UserStoreObject, WorkflowEntryStoreObject } from '../model';

export const getKinshipFieldId = (concept: StoreObject): string => concept[KinshipRelation] as string;

export const isEmbeddedConceptInstance = (concept: StoreObject): boolean => !!getKinshipFieldId(concept) && !!concept.navigateOrNull(KinshipRelation);

export const getParentConceptInstance = (concept: StoreObject): StoreObject | null => (
  isEmbeddedConceptInstance(concept) ? concept.navigateOrNull(getKinshipFieldId(concept)) : null
);

export const getEmbeddedByTypes = (store: ObjectStoreReadOnly, modelTypeId: string): string[] => (
  store.getObject(modelTypeId)
    .navigateBack(EmbeddingField_ToType)
    .filter((embeddingFieldInstance) => !!embeddingFieldInstance.navigateOrNull(EmbeddingField_FromType))
    .filter((value, index, self) => self.findIndex((v) => v[EmbeddingField_FromType] === value[EmbeddingField_FromType]) === index)
    .map((embeddingFieldInstance) => embeddingFieldInstance[EmbeddingField_FromType] as string)
);

export const getFirstClassInstance = (object: StoreObject): StoreObject => {
  if (isInstanceOf<UserStoreObject>(object, User)) {
    return object;
  }
  if (!isInstanceOf<ConceptStoreObject>(object, Concept)) {
    throw newError('Invalid object, should be an instance of Concept', { objectKey: object.key });
  }
  if (!isEmbeddedConceptInstance(object)) {
    return object;
  }
  let parent: StoreObject | null = object;
  while (parent && isEmbeddedConceptInstance(parent)) {
    parent = getParentConceptInstance(parent);
  }
  if (parent && isInstanceOf<ConceptStoreObject>(parent, Concept)) {
    return parent;
  } else {
    throw newError('First class concept not found', { objectKey: object.key });
  }
};

// Check that:
// * Concept exist
// * All embedded concept parents exists
// Private should never be used outside isConceptValid or a property function
export const isConceptValidPrivate = (store: ObjectStoreReadOnly, conceptId: string): boolean => {
  const concept = store.getObjectOrNull(conceptId);
  if (!concept || !isInstanceOf(concept, Concept)) {
    return false;
  } else if (getKinshipFieldId(concept)) {
    const parentConcept = getParentConceptInstance(concept);
    return parentConcept ? isConceptValidPrivate(store, parentConcept.id) : false;
  }
  return !(isInstanceOf(concept, ConceptRole) && !concept.navigateOrNull(ConceptRole_ConceptDefinition));
};

export const isConceptValid = (store: ObjectStoreReadOnly, conceptId: string): boolean => {
  if (store.hasPropertyFunction(Concept_IsValid)) {
    return store.getObjectOrNull(conceptId)?.[Concept_IsValid] as boolean | undefined ?? false;
  } else {
    return isConceptValidPrivate(store, conceptId);
  }
};

export const canCreateAssociation = (targetTypeId: string): boolean => (
  targetTypeId !== Concept
);

// Check that you can edit the parent concept or that the concept has no parent
export const canCopyConcept = (
  store: ObjectStore,
  aclHandlers: {
    canCreateObject: (id: string | string[]) => boolean,
    canWriteObject: (id: string | string[]) => boolean,
  },
  conceptId: string
): boolean => {
  const concept = store.getObject(conceptId);
  return !(isEmbeddedConceptInstance(concept) && !aclHandlers.canWriteObject(concept[getKinshipFieldId(concept)] as string))
    && aclHandlers.canCreateObject(concept[Instance_Of] as string)
    && !(concept[Instance_Of] === User);
};

const computeDenormalizedPermissionForUser = (
  objectStore: ObjectStoreReadOnly,
  userId: string,
  conceptInstanceId: string,
  conceptInstanceTypeId: string
): Record<string, boolean> | undefined => {
  const { getObjectOrNull, withAssociation } = objectStore;
  const roleIds = new Set<string>();
  const groupCapabilitiesIds = new Set<string>();
  const groupMemberships = withAssociation(Association)
    .withRole(Association_Role_Definition, GroupMembershipAssociationDefinition)
    .withRole(Association_Role_Role2TypeInstance, userId)
    .list();

  withAssociation(ConceptRoleUserAssignation)
    .withRole(ConceptRoleUserAssignation_Role_User, userId)
    .withRole(ConceptRoleUserAssignation_Role_Concept, conceptInstanceId)
    .list()
    .forEach((assignation) => {
      roleIds.add(assignation.role(ConceptRoleUserAssignation_Role_ConceptRole));
    });

  const addCollaboratorRole = withAssociation(CollaborationOnMultipleContext)
    .withRole(CollaborationOnMultipleContext_VarRoles_Contexts, conceptInstanceId)
    .list()
    .some((context) => {
      const collaborationId = context.role(CollaborationOnMultipleContext_Role_Collaboration);
      const collaboration = getObjectOrNull<CollaborationStoreObject>(collaborationId);
      const closedEntries = collaboration && collaboration[Collaboration_Intention]
        ? withAssociation(IntentionStatusEntries).withRole(IntentionStatusEntries_Role_Intention, collaboration[Collaboration_Intention])
          .list()
          .map((assoc) => assoc.role(IntentionStatusEntries_Role_Entry))
        : [];

      const statusId = context.navigateRole(CollaborationOnMultipleContext_Role_Collaboration)
        ?.[Collaboration_Status] as string | undefined;
      const intention = collaboration && collaboration[Collaboration_Intention] ? objectStore.getObjectOrNull<IntentionStoreObject>(collaboration[Collaboration_Intention]) : null;
      const statusBelongsToWorkflow = !(intention && intention[Intention_Workflow] && objectStore.getObjectOrNull(intention[Intention_Workflow])
        && statusId && objectStore.getObjectOrNull(statusId)
        && !(objectStore.withAssociation(WorkflowEntry)
          .withRole(WorkflowEntry_Role_Workflow, intention[Intention_Workflow])
          .withRole(WorkflowEntry_Role_Concept, statusId)
          .getObjectOrNull()));

      if (!statusId || closedEntries.includes(statusId) || !statusBelongsToWorkflow) {
        return false;
      }

      const collaborationUserAssociation = withAssociation(CollaborationUsers)
        .withRole(CollaborationUsers_Role_User, userId)
        .withRole(CollaborationUsers_Role_Collaboration, collaborationId)
        .getOrNull();
      if (collaborationUserAssociation) {
        return true;
      }

      return groupMemberships.some((membership) => {
        const groupId = membership.role(Association_Role_Role1TypeInstance);

        return withAssociation(CollaborationGroups)
          .withRole(CollaborationGroups_Role_Group, groupId)
          .withRole(CollaborationUsers_Role_Collaboration, collaborationId)
          .getOrNull();
      });
    });

  if (addCollaboratorRole) {
    getObjectOrNull(conceptInstanceId)
      ?.navigateOrNull(Instance_Of)
      ?.navigateBack(ConceptDefinition_Roles)
      .filter((role) => role[ConceptRole_ForCollaboration])
      .forEach((role) => {
        roleIds.add(role.id);
      });
  }

  groupMemberships.forEach((membership) => {
    const groupId = membership.role(Association_Role_Role1TypeInstance);

    withAssociation(ConceptRoleGroupAssignation)
      .withRole(ConceptRoleGroupAssignation_Role_Group, groupId)
      .withRole(ConceptRoleGroupAssignation_Role_Concept, conceptInstanceId)
      .list()
      .forEach((assignation) => {
        roleIds.add(assignation.role(ConceptRoleGroupAssignation_Role_ConceptRole));
      });

    withAssociation(ConceptGroupCapability)
      .withRole(ConceptGroupCapability_Role_ConceptDefinition, conceptInstanceTypeId)
      .withRole(ConceptGroupCapability_Role_ConceptGroup, groupId)
      .list()
      .forEach((conceptGroupCapability) => groupCapabilitiesIds.add(conceptGroupCapability.role(ConceptGroupCapability_Role_ConceptCapability)));
  });

  if (roleIds.size === 0 && groupCapabilitiesIds.size === 0) {
    return undefined;
  } else {
    const capabilitiesFromRoles = Array.from(roleIds).flatMap((roleId) => withAssociation(ConceptRoleCapability)
      .withRole(ConceptRoleCapability_Role_ConceptRole, roleId)
      .list()
      .map((conceptRoleCapability) => conceptRoleCapability.role(ConceptRoleCapability_Role_ConceptCapability)));
    const capabilities = [...capabilitiesFromRoles, ...Array.from(groupCapabilitiesIds)];
    const computedCapabilities: Record<string, boolean> = {
      [ConceptCapabilityCreate]: capabilities.indexOf(ConceptCapabilityCreate) !== -1,
      [ConceptCapabilityEdit]: capabilities.indexOf(ConceptCapabilityEdit) !== -1,
      [ConceptCapabilityDelete]: capabilities.indexOf(ConceptCapabilityDelete) !== -1,
      [ConceptCapabilityAssignUser]: capabilities.indexOf(ConceptCapabilityAssignUser) !== -1,
      [ConceptCapabilityAssignCollaborator]: capabilities.indexOf(ConceptCapabilityAssignCollaborator) !== -1,
      [ConceptCapabilityOverrideWorkflow]: capabilities.indexOf(ConceptCapabilityOverrideWorkflow) !== -1,
      [ConceptCapabilityOverrideCollaborationWorkflow]: capabilities.indexOf(ConceptCapabilityOverrideCollaborationWorkflow) !== -1,
    };
    const hasReadRole = Array.from(roleIds).length > 0;
    computedCapabilities[ConceptCapabilityRead] = capabilities.indexOf(ConceptCapabilityRead) !== -1 || hasReadRole;
    return computedCapabilities;
  }
};

export const computeDenormalizedPermissionForIntegration = (
  { withAssociation }: ObjectStoreReadOnly,
  integrationId: string,
  conceptInstanceTypeId: string
): Record<string, boolean> | undefined => {
  const hasDirectCapability = (capabilityId: string): boolean => Boolean(
    withAssociation(ConceptIntegrationCapability)
      .withRole(ConceptIntegrationCapability_Role_Definition, conceptInstanceTypeId)
      .withRole(ConceptIntegrationCapability_Role_ConceptCapability, capabilityId)
      .withRole(ConceptIntegrationCapability_Role_Integration, integrationId)
      .getObjectOrNull()
  );

  return {
    [ConceptCapabilityCreate]: hasDirectCapability(ConceptCapabilityCreate),
    [ConceptCapabilityEdit]: hasDirectCapability(ConceptCapabilityEdit),
    [ConceptCapabilityDelete]: hasDirectCapability(ConceptCapabilityDelete),
    [ConceptCapabilityAssignUser]: hasDirectCapability(ConceptCapabilityAssignUser),
    [ConceptCapabilityAssignCollaborator]: hasDirectCapability(ConceptCapabilityAssignCollaborator),
    [ConceptCapabilityRead]: hasDirectCapability(ConceptCapabilityRead),
    [ConceptCapabilityOverrideWorkflow]: hasDirectCapability(ConceptCapabilityOverrideWorkflow),
    [ConceptCapabilityOverrideCollaborationWorkflow]: hasDirectCapability(ConceptCapabilityOverrideCollaborationWorkflow),
  };
};

export const computeDenormalizedPermission = (
  objectStore: ObjectStoreReadOnly,
  objectId: string,
  conceptInstanceId: string,
  conceptInstanceTypeId: string
): Record<string, boolean> | undefined => {
  if (objectStore.getObjectOrNull(objectId)?.[Instance_Of] === User) {
    return computeDenormalizedPermissionForUser(objectStore, objectId, conceptInstanceId, conceptInstanceTypeId);
  } else if (objectStore.getObjectOrNull(objectId)?.[Instance_Of] === Integration) {
    return computeDenormalizedPermissionForIntegration(objectStore, objectId, conceptInstanceTypeId);
  } else {
    return undefined;
  }
};

export const isEmbeddedAsIntegrationOnly = (conceptInstance: StoreObject): boolean => (
  isEmbeddedConceptInstance(conceptInstance) && Boolean(conceptInstance.navigate(KinshipRelation)[Field_IntegrationOnly])
);

export const getSiblingsConceptInstances = (store: ObjectStoreReadOnly, concept: StoreObject): StoreObject[] => {
  if (!isConceptValid(store, concept.id)) {
    return [];
  }

  const parentConceptInstance = getParentConceptInstance(concept);
  if (parentConceptInstance) {
    const kinshipRelationId = getKinshipFieldId(concept);
    return parentConceptInstance.navigateBack(kinshipRelationId).filter(({ id: conceptId }) => isConceptValid(store, conceptId));
  }
  return concept.navigate(Instance_Of)
    .navigateBack(Class_Instances)
    .filter(({ id: conceptId }) => isConceptValid(store, conceptId))
    .filter((instance) => !isEmbeddedConceptInstance(instance));
};

export const getFirstIntentionStatus = (store: ObjectStoreReadOnly, intentionId: string): string | undefined => {
  const workflowId = store.getObjectOrNull<IntentionStoreObject>(intentionId)?.[Intention_Workflow];
  const workflowEntries = workflowId ? store.withAssociation(WorkflowEntry)
    .withRole(WorkflowEntry_Role_Workflow, workflowId)
    .withExternalRole(WorkflowEntry_Role_Concept)
    .list()
    .sort(compareProperty('object', compareProperty(WorkflowEntry_Rank, compareRank)))
    .filter((entry) => !!store.getObjectOrNull(entry.role(WorkflowEntry_Role_Concept))) : [];
  return workflowEntries.length > 0 ? workflowEntries[0].role(WorkflowEntry_Role_Concept) : undefined;
};

export const isStatusBelongingToWorkflow = (
  store: ObjectStoreReadOnly,
  intentionWorkflow: string | undefined,
  collaborationStatus: string | undefined
): boolean => !(intentionWorkflow && collaborationStatus && store.getObjectOrNull(collaborationStatus) && store.getObjectOrNull(intentionWorkflow)
  && !store.withAssociation(WorkflowEntry)
    .withRole(WorkflowEntry_Role_Workflow, intentionWorkflow)
    .withRole(WorkflowEntry_Role_Concept, collaborationStatus)
    .getObjectOrNull());

export const isCollaborationClosed = (store: ObjectStoreReadOnly, collaboration: CollaborationStoreObject): boolean => {
  const intention = collaboration[Collaboration_Intention] ? store.getObjectOrNull<IntentionStoreObject>(collaboration[Collaboration_Intention]) : null;

  if (!collaboration[Collaboration_Intention] || !collaboration[Collaboration_Status] || !intention) {
    return true;
  }
  // first status can't be a closed status
  const intentionClosedStatus = getFirstIntentionStatus(store, collaboration[Collaboration_Intention]) !== collaboration[Collaboration_Status]
    && (store.withAssociation(IntentionStatusEntries)
      .withRole(IntentionStatusEntries_Role_Intention, collaboration[Collaboration_Intention])
      .withRole(IntentionStatusEntries_Role_Entry, collaboration[Collaboration_Status])
      .getObjectOrNull()?.[IntentionStatusIsClosedStatus] ?? false);
  const statusBelongingToWorkflow = isStatusBelongingToWorkflow(store, intention[Intention_Workflow], collaboration[Collaboration_Status]);
  return !!intentionClosedStatus || !statusBelongingToWorkflow;
};

export const getCollaborationsForContext = (store: ObjectStoreReadOnly, context: string[] | undefined, sorted: boolean, phaseState: string): CollaborationStoreObject[] => {
  if (!context || context.length === 0) {
    return [];
  } else {
    let phaseFilter: (collaboration: CollaborationStoreObject) => boolean;
    if (phaseState === 'OPEN') {
      // status can be null as it is set by a br -> it will be not in closure
      phaseFilter = (collaboration) => !isCollaborationClosed(store, collaboration);
    } else if (phaseState === 'CLOSED') {
      phaseFilter = (collaboration) => isCollaborationClosed(store, collaboration);
    } else {
      phaseFilter = () => true;
    }

    const collaborations = store.withAssociation(CollaborationOnMultipleContext)
      .withVarRoles(CollaborationOnMultipleContext_VarRoles_Contexts, context)
      .list()
      .filter((collaborationOnMultipleContext) => collaborationOnMultipleContext.varRoles(CollaborationOnMultipleContext_VarRoles_Contexts).length === context.length)
      .map((collaborationOnMultipleContext) => collaborationOnMultipleContext.navigateRole<CollaborationStoreObject>(CollaborationOnMultipleContext_Role_Collaboration))
      .filter(phaseFilter);

    if (sorted) {
      const extractStatusRank = (collaboration: CollaborationStoreObject) => {
        const collaborationWorkflow = collaboration[Collaboration_Intention]
          ? store.getObjectOrNull<IntentionStoreObject>(collaboration[Collaboration_Intention])?.[Intention_Workflow]
          : undefined;
        return (
          collaborationWorkflow
            ? store
              .withAssociation(WorkflowEntry)
              .withRole(WorkflowEntry_Role_Workflow, collaborationWorkflow)
              .withRole(WorkflowEntry_Role_Concept, collaboration[Collaboration_Status] as string)
              .getObjectOrNull<WorkflowEntryStoreObject>()
              ?.[WorkflowEntry_Rank]
            : undefined
        );
      };
      return collaborations
        .sort(
          comparing(
            extractAndCompareValue(
              extractStatusRank,
              comparing<string | undefined>(pushUndefinedToEnd)
                .thenComparing(
                  (a, b) => {
                    if (a === undefined && b === undefined) {
                      return 0;
                    } else if (a === undefined) {
                      return 1;
                    } else if (b === undefined) {
                      return -1;
                    } else {
                      return compareRank(a, b);
                    }
                  }
                )
            )
          )
            .thenComparing(compareProperty(Collaboration_CreatedAt, compareDate), true)
        );
    } else {
      return collaborations;
    }
  }
};

export const getOpenConceptCollaborationIds = (store: ObjectStoreReadOnly, contextId: string): string[] => (
  store.withAssociation(CollaborationOnMultipleContext)
    .withVarRoles(CollaborationOnMultipleContext_VarRoles_Contexts, [contextId])
    .list()
    .filter((comc) => {
      const collaboration = comc.navigateRole<CollaborationStoreObject>(CollaborationOnMultipleContext_Role_Collaboration);
      return !isCollaborationClosed(store, collaboration);
    })
    .map((comc) => comc.role(CollaborationOnMultipleContext_Role_Collaboration))
);

export const getViewerGroupsSet = (store: ObjectStoreReadOnly, conceptId: string): Set<string> => {
  const firstClassConcept = getFirstClassInstance(store.getObject(conceptId));
  return new Set([
    ...store.withAssociation(ConceptRoleGroupAssignation)
      .withRole(ConceptRoleGroupAssignation_Role_Concept, firstClassConcept.id)
      .list()
      .map((assignation) => assignation.role(ConceptRoleGroupAssignation_Role_Group)),
    ...getOpenConceptCollaborationIds(store, firstClassConcept.id)
      .flatMap((collaborationId) => store.withAssociation(CollaborationGroups)
        .withRole(CollaborationGroups_Role_Collaboration, collaborationId)
        .list()
        .map((assoc) => assoc.role(CollaborationGroups_Role_Group))),
  ]);
};

export const doesUserHasRole = (store: ObjectStoreReadOnly, userId: string, conceptId: string, roleId: string): boolean => {
  const firstClassConcept = getFirstClassInstance(store.getObject(conceptId));
  // check direct assign
  const isDirectlyAssign = store.withAssociation(ConceptRoleUserAssignation)
    .withRole(ConceptRoleUserAssignation_Role_User, userId)
    .withRole(ConceptRoleUserAssignation_Role_Concept, firstClassConcept.id)
    .withRole(ConceptRoleUserAssignation_Role_ConceptRole, roleId)
    .getObjectOrNull();
  if (isDirectlyAssign) {
    return true;
  }
  const groupIds = store.withAssociation(Association)
    .withRole(Association_Role_Definition, GroupMembershipAssociationDefinition)
    .withRole(Association_Role_Role2TypeInstance, userId)
    .list()
    .map((gm) => gm.role(Association_Role_Role1TypeInstance));
  const isDirectlyAssignByGroup = groupIds.some((groupId) => store.withAssociation(ConceptRoleGroupAssignation)
    .withRole(ConceptRoleGroupAssignation_Role_Concept, firstClassConcept.id)
    .withRole(ConceptRoleGroupAssignation_Role_Group, groupId)
    .withRole(ConceptRoleUserAssignation_Role_ConceptRole, roleId)
    .getObjectOrNull());
  if (isDirectlyAssignByGroup) {
    return true;
  }
  // check collaboration assignations
  const role = store.getObjectOrNull(roleId);
  if (!role?.[ConceptRole_ForCollaboration]) {
    return false;
  }
  const openCollaborationIds = getOpenConceptCollaborationIds(store, firstClassConcept.id);
  const isDirectlyAssignedWithCollab = openCollaborationIds
    .some((collaborationId) => store.withAssociation(CollaborationUsers)
      .withRole(CollaborationUsers_Role_Collaboration, collaborationId)
      .withRole(CollaborationUsers_Role_User, userId)
      .getOrNull());
  if (isDirectlyAssignedWithCollab) {
    return true;
  }
  // is assigned via group in collab
  return groupIds
    .filter((groupId) => openCollaborationIds.some((collaborationId) => store.withAssociation(CollaborationGroups)
      .withRole(CollaborationGroups_Role_Collaboration, collaborationId)
      .withRole(CollaborationGroups_Role_Group, groupId)
      .getOrNull())).length > 0;
};

export const canUserViewConcept = (store: ObjectStoreReadOnly, userId: string, conceptId: string): boolean => {
  const concept = store.getObjectOrNull(conceptId);
  if (!concept || !isConceptValid(store, conceptId)) {
    return false;
  }
  const firstClassConcept = getFirstClassInstance(concept);
  // check direct assign
  const isDirectlyAssign = store.withAssociation(ConceptRoleUserAssignation)
    .withRole(ConceptRoleUserAssignation_Role_User, userId)
    .withRole(ConceptRoleUserAssignation_Role_Concept, firstClassConcept.id)
    .list().length > 0;
  if (isDirectlyAssign) {
    return true;
  }
  // users can see groups they belong to
  const belongsToGroup = concept[Instance_Of] === Group && store.withAssociation(Association)
    .withRole(Association_Role_Definition, GroupMembershipAssociationDefinition)
    .withRole(Association_Role_Role1TypeInstance, conceptId)
    .withRole(Association_Role_Role2TypeInstance, userId)
    .list().length > 0;
  if (belongsToGroup) {
    return true;
  }
  const groupIds = store.withAssociation(Association)
    .withRole(Association_Role_Definition, GroupMembershipAssociationDefinition)
    .withRole(Association_Role_Role2TypeInstance, userId)
    .list()
    .map((gm) => gm.navigateRole(Association_Role_Role1TypeInstance))
    .map(({ id }) => id);
  const isDirectlyAssignByGroup = groupIds.some((groupId) => store.withAssociation(ConceptRoleGroupAssignation)
    .withRole(ConceptRoleGroupAssignation_Role_Concept, firstClassConcept.id)
    .withRole(ConceptRoleGroupAssignation_Role_Group, groupId)
    .list().length > 0);
  if (isDirectlyAssignByGroup) {
    return true;
  }
  // check default assignations
  const conceptDefinition = firstClassConcept.navigate(Instance_Of);
  const isGlobalConceptDefinitionViewer = groupIds.some((groupId) => store.withAssociation(ConceptGroupCapability)
    .withRole(ConceptGroupCapability_Role_ConceptGroup, groupId)
    .withRole(ConceptGroupCapability_Role_ConceptDefinition, conceptDefinition.id)
    .withRole(ConceptGroupCapability_Role_ConceptCapability, ConceptCapabilityRead)
    .getObjectOrNull());
  if (isGlobalConceptDefinitionViewer) {
    return true;
  }
  // check collaboration assignations
  const hasConceptRoleForCollaboration = conceptDefinition.navigateBack(ConceptDefinition_Roles)
    .some((conceptRole) => conceptRole[ConceptRole_ForCollaboration]);
  if (!hasConceptRoleForCollaboration) {
    return false;
  }
  const openCollaborationIds = getOpenConceptCollaborationIds(store, firstClassConcept.id);
  const isDirectlyAssignedWithCollab = openCollaborationIds
    .some((collaborationId) => store.withAssociation(CollaborationUsers)
      .withRole(CollaborationUsers_Role_Collaboration, collaborationId)
      .withRole(CollaborationUsers_Role_User, userId)
      .getOrNull());
  if (isDirectlyAssignedWithCollab) {
    return true;
  }
  // is assigned via group in collab
  return groupIds
    .filter((groupId) => openCollaborationIds.some((collaborationId) => store.withAssociation(CollaborationGroups)
      .withRole(CollaborationGroups_Role_Collaboration, collaborationId)
      .withRole(CollaborationGroups_Role_Group, groupId)
      .getOrNull())).length > 0;
};

export const createConceptRole = (
  { createObject, withAssociation }: ObjectStore,
  conceptDefinition: string,
  name?: string,
  assignedByDefault?: boolean,
  forCollaboration?: boolean,
  capabilities?: string[],
  rank?: string
): string => {
  const nextId = Number.parseInt(
    withAssociation(IdFieldAssociation)
      .withRole(IdFieldAssociation_Role_ConceptDefinition, ConceptRole)
      .withRole(IdFieldAssociation_Role_Field, Concept_FunctionalId)
      .getObjectOrNull()?.[IdFieldAssociation_NextId] as string | undefined ?? '1',
    10
  );
  const roleId = createObject({
    [Instance_Of]: ConceptRole,
    [Concept_Name]: textToRichText(name),
    [ConceptRole_ConceptDefinition]: conceptDefinition,
    [ConceptRole_AssignedByDefault]: assignedByDefault,
    [ConceptRole_ForCollaboration]: forCollaboration,
    [Concept_SwimlaneRank]: rank,
    [Concept_FunctionalId]: nextId.toString(),
  });

  capabilities?.forEach((capabilityId) => {
    withAssociation(ConceptRoleCapability)
      .withRole(ConceptRoleCapability_Role_ConceptCapability, capabilityId)
      .withRole(ConceptRoleCapability_Role_ConceptRole, roleId)
      .updateObject({});
  });
  withAssociation(IdFieldAssociation)
    .withRole(IdFieldAssociation_Role_ConceptDefinition, ConceptRole)
    .withRole(IdFieldAssociation_Role_Field, Concept_FunctionalId)
    .updateObject({ [IdFieldAssociation_NextId]: (nextId + 1).toString() });
  return roleId;
};

export const getConceptDefinitionUrl = (conceptDefinitionId: string): string => `/concept/${conceptDefinitionId}`;

export const getConceptUrl = (store: ObjectStoreReadOnly, conceptId: string): string => (
  `${getConceptDefinitionUrl((store.getObject(conceptId))[Instance_Of] as string)}/${conceptId}`
);
