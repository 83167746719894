import type { BusinessRuleHandler } from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import { registerCreationAndUpdateDateBusinessRules } from '../common/businessRules';
import { createModule } from '../common/types/TypeModuleDsl';
import type { ConceptStoreObject } from '../conceptModule';
import { isConceptValid } from '../conceptModule/utils/utils';
import { Concept, Group, User } from '../conceptModule/ids';
import { isInstanceOf } from '../typeModule';
import * as CollaborationModuleIds from './ids';
import {
  Collaboration,
  Collaboration_CreatedAt,
  Collaboration_Hash,
  Collaboration_Messages,
  Collaboration_Path,
  Collaboration_UpdatedAt,
  CollaborationGroups,
  CollaborationGroups_Role_Collaboration,
  CollaborationGroups_Role_Group,
  CollaborationMessage,
  CollaborationMessage_Collaboration,
  CollaborationMessage_CreatedAt,
  CollaborationMessage_UpdatedAt,
  CollaborationModuleId,
  CollaborationOnMultipleContext,
  CollaborationOnMultipleContext_Role_Collaboration,
  CollaborationOnMultipleContext_VarRoles_Contexts, CollaborationRoles, CollaborationRoles_Role_Collaboration, CollaborationRoles_Role_Role,
  CollaborationUserInfo,
  CollaborationUserInfo_Role_Collaboration,
  CollaborationUserInfo_Role_User,
  CollaborationUsers,
  CollaborationUsers_Role_Collaboration,
  CollaborationUsers_Role_User,
} from './ids';
import migrations from './migrations';
import type { CollaborationStoreObject } from './modelTypes';

const { initTypedModule, ...registerModelDsl } = createModule({ label: 'Collaboration' }, CollaborationModuleIds);
export const registerModel = registerModelDsl;

const applyCollaborationCleanup: BusinessRuleHandler = (_, { id, properties }) => {
  // Update or Association Deletion ? don't care
  if (properties !== null || id.length !== 1) {
    return undefined;
  }

  return {
    rule: 'collaboration.cleanup',
    status: ValidationStatus.ACCEPTED,
    generateSystemEvent: ({ updateObject, withAssociation }) => {
      const collaborationsIds = withAssociation(CollaborationOnMultipleContext)
        .withVarRoles(CollaborationOnMultipleContext_VarRoles_Contexts, [id[0]])
        .list()
        .map((assoc) => assoc.role(CollaborationOnMultipleContext_Role_Collaboration));
      collaborationsIds.forEach((collaborationId) => updateObject(collaborationId, {
        [Collaboration_Path]: null,
        [Collaboration_Hash]: null,
      }));
    },
  };
};

export const initModule = initTypedModule(() => ({
  id: CollaborationModuleId,
  migrations,
  registerBusinessRules: (objectStore, registerBusinessRules) => {
    const { onObject } = registerBusinessRules;
    registerCreationAndUpdateDateBusinessRules(objectStore, registerBusinessRules, CollaborationMessage, CollaborationMessage_CreatedAt, CollaborationMessage_UpdatedAt);
    registerCreationAndUpdateDateBusinessRules(objectStore, registerBusinessRules, Collaboration, Collaboration_CreatedAt, Collaboration_UpdatedAt);
    onObject('*').validate(applyCollaborationCleanup);
  },
  registerGarbageCollectorRules: (objectStore, { register }) => {
    register({
      ruleName: 'invalidCollaboration',
      collect: ({ id, properties }) => {
        if (properties === null && typeof id === 'string') {
          const deletedObject = objectStore.getObjectOrNull(id);
          if (deletedObject && isInstanceOf<ConceptStoreObject>(deletedObject, Concept)) {
            return objectStore.withAssociation(CollaborationOnMultipleContext)
              .withVarRoles(CollaborationOnMultipleContext_VarRoles_Contexts, [id])
              .list()
              .map((collaborationOnMultipleContext) => collaborationOnMultipleContext.navigateRole<CollaborationStoreObject>(CollaborationOnMultipleContext_Role_Collaboration).id);
          }
        }
        return [];
      },
      shouldDelete: (id) => {
        if (Array.isArray(id)) {
          return false;
        } else {
          const object = objectStore.getObject(id);
          if (isInstanceOf(object, Collaboration)) {
            const isLinkedToAValidConcept = objectStore.withAssociation(CollaborationOnMultipleContext)
              .withRole(CollaborationOnMultipleContext_Role_Collaboration, id)
              .list()
              .some(({ varRoles }) => varRoles(CollaborationOnMultipleContext_VarRoles_Contexts).some((contextId) => isConceptValid(objectStore, contextId)));
            return !isLinkedToAValidConcept;
          } else {
            return false;
          }
        }
      },
    });
    register({
      ruleName: 'invalidCollaborationOnMultipleContext',
      collect: ({ id, properties }) => {
        if (properties === null && typeof id === 'string') {
          const deletedObject = objectStore.getObjectOrNull(id);
          if (deletedObject && isInstanceOf(deletedObject, Collaboration)) {
            return objectStore.withAssociation(CollaborationOnMultipleContext)
              .withRole(CollaborationOnMultipleContext_Role_Collaboration, id)
              .listObjects()
              .map(({ id: associationId }) => associationId);
          }
        }
        return [];
      },
      shouldDelete: (id) => {
        if (!Array.isArray(id)) {
          return false;
        } else {
          return id[0] === CollaborationOnMultipleContext && objectStore.getObjectOrNull(id[CollaborationOnMultipleContext_Role_Collaboration + 1]) === null;
        }
      },
    });
    register({
      ruleName: 'invalidCollaborationUserInfo',
      collect: ({ id, properties }) => {
        if (properties === null && typeof id === 'string') {
          const deletedObject = objectStore.getObjectOrNull(id);
          if (deletedObject && isInstanceOf(deletedObject, Collaboration)) {
            return objectStore.withAssociation(CollaborationUserInfo)
              .withRole(CollaborationUserInfo_Role_Collaboration, id)
              .listObjects()
              .map(({ id: associationId }) => associationId);
          } else if (deletedObject && isInstanceOf(deletedObject, User)) {
            return objectStore.withAssociation(CollaborationUserInfo)
              .withRole(CollaborationUserInfo_Role_User, id)
              .listObjects()
              .map(({ id: associationId }) => associationId);
          }
        }
        return [];
      },
      shouldDelete: (id) => {
        if (!Array.isArray(id)) {
          return false;
        } else {
          return id[0] === CollaborationUserInfo
            && (
              objectStore.getObjectOrNull(id[CollaborationUserInfo_Role_Collaboration + 1]) === null
              || objectStore.getObjectOrNull(id[CollaborationUserInfo_Role_User + 1]) === null
            );
        }
      },
    });
    register({
      ruleName: 'invalidCollaborationUsers',
      collect: ({ id, properties }) => {
        if (properties === null && typeof id === 'string') {
          const deletedObject = objectStore.getObjectOrNull(id);
          if (deletedObject && isInstanceOf(deletedObject, Collaboration)) {
            return objectStore.withAssociation(CollaborationUsers)
              .withRole(CollaborationUsers_Role_Collaboration, id)
              .listObjects()
              .map(({ id: associationId }) => associationId);
          } else if (deletedObject && isInstanceOf(deletedObject, User)) {
            return objectStore.withAssociation(CollaborationUsers)
              .withRole(CollaborationUsers_Role_User, id)
              .listObjects()
              .map(({ id: associationId }) => associationId);
          }
        }
        return [];
      },
      shouldDelete: (id) => {
        if (!Array.isArray(id)) {
          return false;
        } else {
          return id[0] === CollaborationUsers
            && (
              objectStore.getObjectOrNull(id[CollaborationUsers_Role_Collaboration + 1]) === null
              || objectStore.getObjectOrNull(id[CollaborationUsers_Role_User + 1]) === null
            );
        }
      },
    });
    register({
      ruleName: 'invalidCollaborationGroups',
      collect: ({ id, properties }) => {
        if (properties === null && typeof id === 'string') {
          const deletedObject = objectStore.getObjectOrNull(id);
          if (deletedObject && isInstanceOf(deletedObject, Collaboration)) {
            return objectStore.withAssociation(CollaborationGroups)
              .withRole(CollaborationGroups_Role_Collaboration, id)
              .listObjects()
              .map(({ id: associationId }) => associationId);
          } else if (deletedObject && isInstanceOf(deletedObject, Group)) {
            return objectStore.withAssociation(CollaborationGroups)
              .withRole(CollaborationGroups_Role_Group, id)
              .listObjects()
              .map(({ id: associationId }) => associationId);
          }
        }
        return [];
      },
      shouldDelete: (id) => {
        if (!Array.isArray(id)) {
          return false;
        } else {
          return id[0] === CollaborationGroups
            && (
              objectStore.getObjectOrNull(id[CollaborationGroups_Role_Collaboration + 1]) === null
              || objectStore.getObjectOrNull(id[CollaborationGroups_Role_Group + 1]) === null
            );
        }
      },
    });
    register({
      ruleName: 'invalidCollaborationMessage',
      collect: ({ id, properties }) => {
        if (properties === null && typeof id === 'string') {
          const deletedObject = objectStore.getObjectOrNull(id);
          if (deletedObject && isInstanceOf(deletedObject, Collaboration)) {
            return deletedObject.navigateBack(Collaboration_Messages).map((message) => message.id);
          }
        }
        return [];
      },
      shouldDelete: (id) => {
        if (Array.isArray(id)) {
          return false;
        } else {
          const object = objectStore.getObject(id);
          return isInstanceOf(object, CollaborationMessage) && object.navigateOrNull(CollaborationMessage_Collaboration) === null;
        }
      },
    });
    register({
      ruleName: 'invalidCollaborationRoles',
      collect: ({ id, properties }) => {
        if (properties === null && typeof id === 'string') {
          const deletedObject = objectStore.getObjectOrNull(id);
          if (deletedObject && isInstanceOf(deletedObject, Collaboration)) {
            return objectStore.withAssociation(CollaborationRoles)
              .withRole(CollaborationRoles_Role_Collaboration, id)
              .listObjects()
              .map(({ id: associationId }) => associationId);
          } else if (deletedObject && isInstanceOf(deletedObject, Group)) {
            return objectStore.withAssociation(CollaborationRoles)
              .withRole(CollaborationRoles_Role_Role, id)
              .listObjects()
              .map(({ id: associationId }) => associationId);
          }
        }
        return [];
      },
      shouldDelete: (id) => {
        if (!Array.isArray(id)) {
          return false;
        } else {
          return id[0] === CollaborationRoles
            && (
              objectStore.getObjectOrNull(id[CollaborationRoles_Role_Collaboration + 1]) === null
              || objectStore.getObjectOrNull(id[CollaborationRoles_Role_Role + 1]) === null
            );
        }
      },
    });
  },
}));
