import type { TimeRange } from 'yooi-store';
import { mergeTimeValidities } from 'yooi-store';
import { DATE_MAX_TIMESTAMP, DATE_MIN_TIMESTAMP } from 'yooi-utils';
import type { TimeseriesSnapshotRequest, TimeseriesSnapshotStatus } from './timeseriesSnapshotHandler';

interface FetchStack {
  addToStack: (objectId: string | string[], propertyId: string, timeRange?: TimeRange) => void,
  completeStack: () => TimeseriesSnapshotRequest[],
}

interface TimeseriesInitializationHandler {
  initTimeseries: (objectId: string | string[], propertyId: string, timeRange?: TimeRange) => void,
}

const encodeId = (id: string | string[]): string => (Array.isArray(id) ? id.join('|') : id);
const decodeId = (encodedId: string): string | string[] => (encodedId.includes('|') ? encodedId.split('|') : encodedId);

const createTimeseriesInitializationHandler = (
  handleTimeseriesInit: (request: TimeseriesSnapshotRequest[]) => Promise<TimeseriesSnapshotStatus>
): TimeseriesInitializationHandler => {
  const createFetchStack = (): FetchStack => {
    let initStack: Record<string, Record<string, TimeRange[]>> = {};
    const addToStack: FetchStack['addToStack'] = (objectId, propertyId, timeRange) => {
      const effectiveTimeRange = {
        from: timeRange?.from ?? DATE_MIN_TIMESTAMP,
        to: timeRange?.to ?? DATE_MAX_TIMESTAMP,
      };
      const key = encodeId(objectId);
      if (!initStack[propertyId]) {
        initStack[propertyId] = {
          [key]: [effectiveTimeRange],
        };
      } else if (!initStack[propertyId][key]) {
        initStack[propertyId][key] = [effectiveTimeRange];
      } else {
        initStack[propertyId][key] = mergeTimeValidities(initStack[propertyId][key], effectiveTimeRange);
      }
    };
    const completeStack: FetchStack['completeStack'] = () => {
      const stack = { ...initStack };
      initStack = {};
      const result: Record<string, { objectId: string | string[], timeRange: TimeRange }[]> = {};
      Object.entries(stack).forEach(([propertyId, objectsTimeRanges]) => {
        Object.entries(objectsTimeRanges).forEach(([objectId, timeRanges]) => {
          timeRanges.forEach((timeRange) => {
            if (result[propertyId]) {
              result[propertyId].push({ objectId: decodeId(objectId), timeRange });
            } else {
              result[propertyId] = [{ objectId: decodeId(objectId), timeRange }];
            }
          });
        });
      });
      return Object.entries(result).map(([pId, timeseriesToFetch]) => ({ propertyId: pId, timeseries: timeseriesToFetch }));
    };

    return {
      addToStack,
      completeStack,
    };
  };

  let stack: FetchStack | undefined;

  return {
    initTimeseries: (objectId, propertyId, timeRange) => {
      if (!stack) {
        stack = createFetchStack();
        setImmediate(() => {
          if (stack === undefined) {
            return;
          }
          const fetchStack = stack.completeStack();
          stack = undefined;
          handleTimeseriesInit(fetchStack);
        });
      }
      stack.addToStack(objectId, propertyId, timeRange);
    },
  };
};

export default createTimeseriesInitializationHandler;
