import type { FunctionComponent, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { joinObjects } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../components/atoms/Button';
import { IconName } from '../../../components/atoms/Icon';
import Typo from '../../../components/atoms/Typo';
import TextInputString from '../../../components/inputs/TextInputString';
import InlineLink from '../../../components/molecules/InlineLink';
import Link from '../../../components/molecules/Link';
import Loading from '../../../components/molecules/Loading';
import SpacingLine from '../../../components/molecules/SpacingLine';
import BaseLayout from '../../../components/templates/BaseLayout';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import DataTable from '../../../components/templates/DataTable';
import Header from '../../../components/templates/Header';
import HeaderTabs from '../../../components/templates/HeaderTabs';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import { FontVariant } from '../../../theme/fontDefinition';
import buildInfo from '../../../utils/buildInfo';
import { formatENDisplayDate } from '../../../utils/dateUtilsFront';
import i18n from '../../../utils/i18n';
import { hasFeature, listFeatures } from '../../../utils/options';
import { useFetchJSON } from '../../../utils/useFetchJSON';
import useNavigation, { NavigationElementContainer } from '../../../utils/useNavigation';
import HeaderStatic from '../../_global/HeaderStatic';
import TopBar from '../../_global/topBar/TopBar';

const AboutPage: FunctionComponent = () => {
  const location = useLocation();
  const navigation = useNavigation();

  const featureList = listFeatures();

  const showDependencies = hasFeature('admin', 'report3rdPartyDependencies');
  const { loading, value, error } = useFetchJSON<{
    status: 200,
    response: { name: string, licenses: string, repository: string, publisher: string, email: string, url: string }[],
  }>('/platform/dependencies/json', { skip: !showDependencies });
  const dependencies = (!loading && !error && value) ? value.response : undefined;

  const tabs: { key: string, name: string, hash: string, render: () => ReactElement | null }[] = [];
  tabs.push({
    key: 'general',
    name: i18n`General`,
    hash: '#general',
    render: () => (
      <VerticalBlock>
        <HorizontalBlock asBlockContent>
          <BlockTitle title={i18n`Version`} />
          <VerticalBlock asBlockContent compact>
            <BlockContent padded>
              <SpacingLine>
                <Typo>{formatENDisplayDate(new Date(buildInfo.date))}</Typo>
              </SpacingLine>
            </BlockContent>
            <BlockContent padded>
              <SpacingLine>
                <Typo variant={FontVariant.code}>{buildInfo.sourceHash}</Typo>
              </SpacingLine>
            </BlockContent>
            <BlockContent padded>
              <SpacingLine>
                <InlineLink to="https://help.yooi.com/doc/release-notes" noStyle>
                  <Button
                    variant={ButtonVariant.secondary}
                    iconName={IconName.open_in_new}
                    title={i18n`Release notes`}
                  />
                </InlineLink>
              </SpacingLine>
            </BlockContent>
          </VerticalBlock>
        </HorizontalBlock>
        <HorizontalBlock asBlockContent>
          <BlockTitle title={i18n`Enabled features`} />
          <VerticalBlock asBlockContent compact>
            {featureList.map(({ group, name }) => `${group}:${name}`).map((featureName) => (
              <BlockContent key={featureName} padded>
                <SpacingLine>
                  <Typo>{featureName}</Typo>
                </SpacingLine>
              </BlockContent>
            ))}
          </VerticalBlock>
        </HorizontalBlock>
      </VerticalBlock>
    ),
  });

  if (showDependencies) {
    tabs.push({
      key: 'dependencies',
      name: i18n`Dependencies`,
      hash: '#dependencies',
      render: () => (
        <VerticalBlock>
          <VerticalBlock asBlockContent>
            <BlockContent padded>
              <SpacingLine>
                <Link title={i18n`Download as JSON`} to="/platform/dependencies/json" openInNewTab />
                <Link title={i18n`Download as CSV`} to="/platform/dependencies/csv" openInNewTab />
              </SpacingLine>
            </BlockContent>
            {dependencies ? (
              <DataTable
                list={dependencies.map((item) => ({ key: item.name, type: 'item', item, color: undefined }))}
                columnsDefinition={[
                  {
                    propertyId: 'name',
                    name: i18n`Name`,
                    cellRender: ({ name }) => (<TextInputString value={name} maxLine={1} readOnly />),
                  },
                  {
                    propertyId: 'licenses',
                    name: i18n`Licenses`,
                    cellRender: ({ licenses }) => (<TextInputString value={licenses} maxLine={1} readOnly />),
                  },
                  {
                    propertyId: 'repository',
                    name: i18n`Repository`,
                    cellRender: ({ repository }) => (<TextInputString value={repository} maxLine={1} readOnly />),
                  },
                  {
                    propertyId: 'publisher',
                    name: i18n`Publisher`,
                    cellRender: ({ publisher }) => (<TextInputString value={publisher} maxLine={1} readOnly />),
                  },
                  {
                    propertyId: 'email',
                    name: i18n`Email`,
                    cellRender: ({ email }) => (<TextInputString value={email} maxLine={1} readOnly />),
                  },
                  {
                    propertyId: 'url',
                    name: i18n`URL`,
                    cellRender: ({ url }) => (<TextInputString value={url} maxLine={1} readOnly />),
                  },
                ]}
              />
            ) : (
              <BlockContent>
                <Loading />
              </BlockContent>
            )}
          </VerticalBlock>
        </VerticalBlock>
      ),
    });
  }

  const tabIndex = tabs.findIndex((tab) => tab.hash === location.hash);
  const selectedTabIndex = tabIndex >= 0 ? tabIndex : 0;

  return (
    <NavigationElementContainer element={{ key: 'about' }}>
      <BaseLayout
        topBar={(<TopBar />)}
        header={(
          <Header
            firstLine={(<HeaderStatic text={i18n`About`} />)}
            tabsLine={tabs.length > 1 ? (
              <HeaderTabs
                tabs={tabs}
                selectedTabIndex={selectedTabIndex >= 0 ? selectedTabIndex : 0}
                onSelectedIndexChanged={(index) => {
                  navigation.replace('about', joinObjects(location, { hash: tabs[index].hash }));
                }}
              />
            ) : undefined}
          />
        )}
        content={tabs[selectedTabIndex].render()}
      />
    </NavigationElementContainer>
  );
};

export default AboutPage;
