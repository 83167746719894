import type { DimensionDisplayOption, DimensionsDisplayOptionsFeatureDefinition, ViewDimension, ViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import { DimensionDisplayAxis } from 'yooi-modules/modules/dashboardModule';
import Checkbox from '../../../../../components/atoms/Checkbox';
import Chip from '../../../../../components/molecules/Chip';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import TableInnerCellContainer, { TableInnerCellContainerVariants } from '../../../../../components/molecules/TableInnerCellContainer';
import type { ColumnDefinition } from '../../../../../components/templates/internal/DataTableRenderer';
import type { FrontObjectStore } from '../../../../../store/useStore';
import { buildPadding, Spacing } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import type { UpdateViewDefinition } from '../../../fields/viewsField/ViewsFieldDefinitionOptions';
import { getDimensionLabel } from '../../data/dataResolution';

export const getViewDefinitionDefaultDimensionDisplayOption = (viewDimension: ViewDimension): DimensionDisplayOption => ({
  id: viewDimension.id,
  axis: DimensionDisplayAxis.x,
  withLegend: false,
});

export const getViewDefinitionDimensionDisplayOption = (
  definition: DimensionsDisplayOptionsFeatureDefinition,
  viewDimension: ViewDimension,
  defaultAxis = DimensionDisplayAxis.x
): DimensionDisplayOption => {
  const persistedDimensionDisplayOption = definition.dimensionsDisplay?.find(({ id }) => id === viewDimension.id);
  if (persistedDimensionDisplayOption !== undefined) {
    return persistedDimensionDisplayOption;
  } else {
    return {
      id: viewDimension.id,
      axis: defaultAxis,
      withLegend: false,
    };
  }
};

export const getViewDefinitionDefaultDimensionsDisplayOptions = (
  viewDimensions: ViewDimension[]
): DimensionDisplayOption[] => viewDimensions.map(({ id }) => ({
  id,
  axis: DimensionDisplayAxis.x,
  withLegend: false,
}));

export const getViewDefinitionDimensionsDisplayOptions = (
  definition: DimensionsDisplayOptionsFeatureDefinition,
  viewDimensions: ViewDimension[],
  defaultAxis = DimensionDisplayAxis.x
): DimensionDisplayOption[] => {
  const persistedDimensionDisplayOptionMap = new Map(
    (definition.dimensionsDisplay ?? []).map((dimensionsDisplay) => [dimensionsDisplay.id, dimensionsDisplay])
  );
  return viewDimensions.map(({ id }) => {
    const persistedDimensionDisplayOption = persistedDimensionDisplayOptionMap.get(id);
    if (persistedDimensionDisplayOption !== undefined) {
      return persistedDimensionDisplayOption;
    } else {
      return {
        id,
        axis: defaultAxis,
        withLegend: false,
      };
    }
  });
};

export const getDimensionDisplayOptionsColumns = <T extends ViewStoredDefinition>(
  store: FrontObjectStore,
  viewDimensions: ViewDimension[],
  updateViewDefinition: UpdateViewDefinition<DimensionsDisplayOptionsFeatureDefinition & T>,
  withAxis = false
): ColumnDefinition<DimensionDisplayOption>[] => {
  const axisOption: ColumnDefinition<DimensionDisplayOption> = {
    propertyId: 'Axis',
    name: i18n`Axis`,
    cellRender: ({ axis }, _, index) => (
      <SearchAndSelect<{ id: DimensionDisplayAxis, label: string }>
        computeOptions={() => Object.values(DimensionDisplayAxis).map((ax) => ({ id: ax, label: (ax === DimensionDisplayAxis.x ? i18n`In line` : i18n`Stacked`) }))}
        selectedOption={{ id: axis, label: (axis === DimensionDisplayAxis.x ? i18n`In line` : i18n`Stacked`) }}
        onSelect={(option) => {
          if (option) {
            updateViewDefinition((oldViewDefinition) => {
              const dimensionsDisplay = [...getViewDefinitionDimensionsDisplayOptions(oldViewDefinition, viewDimensions)];
              dimensionsDisplay[index].axis = option.id;
              const newViewDefinition = { ...oldViewDefinition };
              newViewDefinition.dimensionsDisplay = dimensionsDisplay;
              return newViewDefinition;
            });
          }
        }}
      />
    ),
  };
  return [
    {
      name: i18n`Dimension`,
      propertyId: 'Label',
      cellRender: ({ id }, _, index) => {
        const d = viewDimensions.find((viewDimension) => viewDimension.id === id);
        return (
          <TableInnerCellContainer variant={TableInnerCellContainerVariants.centeredFlex} padding={buildPadding({ x: Spacing.s })}>
            <Chip text={getDimensionLabel(store, d?.label, index, d?.path ?? [])} />
          </TableInnerCellContainer>
        );
      },
    },
    ...withAxis ? [axisOption] : [],
    {
      propertyId: 'withLegend',
      name: i18n`Displayed in legend`,
      cellRender: ({ withLegend }, _, index) => (
        <Checkbox
          checked={Boolean(withLegend)}
          onChange={(newWithLegend) => {
            updateViewDefinition((oldViewDefinition) => {
              const dimensionsDisplay = [...getViewDefinitionDimensionsDisplayOptions(oldViewDefinition, viewDimensions)];
              dimensionsDisplay[index].withLegend = newWithLegend;
              const newViewDefinition = { ...oldViewDefinition };
              newViewDefinition.dimensionsDisplay = dimensionsDisplay;
              return newViewDefinition;
            });
          }}
        />
      ),
    },
  ];
};
