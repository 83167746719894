import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { FunctionComponent, ReactElement, ReactNode } from 'react';
import { useMemo, useRef, useState } from 'react';
import { filterNullOrUndefined } from 'yooi-utils';
import fontDefinition from '../../theme/fontDefinition';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import { scrollMarginTop, useScrollableAnchorsRef } from '../../utils/useAnchorScroll';
import useBlockContext from '../../utils/useBlockContext';
import { useChipBlockContext } from '../../utils/useChipBlockContext';
import useHighlight from '../../utils/useHighlight';
import useSizeContext, { getInputSize, SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import Icon, { IconColorVariant, IconName, IconSizeVariant } from '../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../atoms/IconOnlyButton';
import Typo, { TypoVariant } from '../atoms/Typo';
import SpacingLine from '../molecules/SpacingLine';
import TextWithLinks from '../molecules/TextWithLinks';
import TooltipPopover from '../molecules/TooltipPopover';

export enum BlockTitleVariant {
  primary = 'primary',
  inline = 'inline',
  secondary = 'secondary',
  tertiary = 'tertiary',
}

const useStyles = makeStyles((theme) => ({
  root: {
    scrollMarginTop,
    display: 'grid',
    gridTemplateColumns: '2.6rem 1fr auto',
    justifyContent: 'start',
    alignItems: 'center',
    flexGrow: 1,
  },
  greyed: {
    color: theme.color.text.disabled,
  },
  title: {
    marginLeft: spacingRem.s,
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
  },
  subtitle: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    minHeight: '3.2rem',
    paddingTop: spacingRem.s,
  },
  blockContainer: {
    scrollMarginTop,
    display: 'grid',
    minHeight: '3.2rem',
    columnGap: '2rem',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gridColumnStart: 2,
  },
  iconButtonContainer: {
    height: '2.2rem',
    alignSelf: 'start',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconContainer: {
    gridColumnStart: 1,
  },
  verticalAligner: {
    borderTopWidth: '0.1rem',
    borderTopStyle: 'solid',
    borderTopColor: theme.color.transparent,
    marginTop: spacingRem.xs,
    display: 'inline-grid',
    gridAutoFlow: 'column',
  },
  sizeContext_title: {
    maxHeight: getInputSize(SizeVariant.title),
  },
  sizeContext_subtitle: {
    maxHeight: getInputSize(SizeVariant.subtitle),
  },
  sizeContext_tabs: {
    maxHeight: getInputSize(SizeVariant.tabs),
  },
  sizeContext_main: {
    maxHeight: getInputSize(SizeVariant.main),
  },
  sizeContext_small: {
    maxHeight: getInputSize(SizeVariant.small),
  },
  rightActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'start',
  },
  mandatoryIconContainer: {
    display: 'flex',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}), 'blockTitle');

type Tooltip = { label: string, icon?: IconName, color?: IconColorVariant } | { render: () => ReactElement | null, icon?: IconName, color?: IconColorVariant } | string;

interface BlockTitleProps {
  title?: string | ReactElement | null,
  mandatoryIcon?: {
    tooltip?: Tooltip,
    color?: IconColorVariant,
  },
  infoTooltip?: Tooltip,
  errorTooltip?: Tooltip,
  anchor?: string,
  actions?: ReactNode,
  actionsFullHeight?: boolean,
  variant?: BlockTitleVariant,
  iconName?: IconName,
  isGreyed?: boolean,
  buttonIcon?: { onClick: () => void, iconName: IconName, alwaysVisible?: boolean, tooltip: string },
  rightActions?: ReactNode,
  subtitle?: string | ReactElement | null,
  context?: string | undefined,
  withHighlight?: boolean,
  verticalSpan?: number,
  unPadded?: boolean,
  onTitleMouseEnter?: () => void,
  onTitleMouseLeave?: () => void,
}

const BlockTitle: FunctionComponent<BlockTitleProps> = ({
  title,
  mandatoryIcon,
  infoTooltip,
  errorTooltip,
  anchor,
  actions,
  actionsFullHeight = false,
  variant = BlockTitleVariant.primary,
  iconName,
  isGreyed = false,
  buttonIcon,
  rightActions,
  subtitle,
  context,
  withHighlight = false,
  verticalSpan,
  unPadded,
  onTitleMouseEnter,
  onTitleMouseLeave,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { sizeVariant } = useSizeContext();
  const anchorRef = useScrollableAnchorsRef<HTMLDivElement>(anchor ? [anchor] : []);
  const [currentAnchor, setCurrentAnchor] = useState<HTMLDivElement>();

  // Props of the parent Block injected to this component by its parent
  const { isVertical } = useBlockContext();
  const isMainBlockTitle = (variant === BlockTitleVariant.primary || variant === BlockTitleVariant.secondary) && isVertical;

  const chipContext = useChipBlockContext();
  const currentContext = useMemo(() => [chipContext.context, context].filter(filterNullOrUndefined), [chipContext.context, context]);

  const highlightIconRef = useHighlight<HTMLDivElement>(currentContext, withHighlight ? undefined : {});
  const highlightTitleRef = useHighlight<HTMLDivElement>(currentContext, withHighlight ? undefined : {});

  const buttonIconContainerRef = useRef<HTMLDivElement>(null);
  const iconContainerRef = useRef<HTMLDivElement>(null);
  const buttonVisibleState = useRef(buttonIcon?.alwaysVisible);

  const renderSubtitle = () => {
    if (typeof subtitle === 'string') {
      return (
        <div className={classes.subtitle}>
          <Typo color={theme.color.text.secondary} fullWidth>
            {subtitle}
          </Typo>
        </div>
      );
    } else {
      return (
        <div className={classes.subtitle}>
          {subtitle}
        </div>
      );
    }
  };

  const renderTooltip = (
    tooltipDefinition: Tooltip | undefined,
    defaultIcon: IconName,
    defaultColor: IconColorVariant,
    forceIconDisplay: boolean = false,
    size?: IconSizeVariant
  ) => {
    const tooltip = typeof tooltipDefinition === 'string' ? { label: tooltipDefinition } : tooltipDefinition;

    if (tooltip && !isGreyed) {
      const icon = tooltip.icon ?? defaultIcon;
      const color = tooltip.color ?? defaultColor;

      if ('label' in tooltip) {
        return (<Icon name={icon} colorVariant={color} tooltip={tooltip.label} size={size} />);
      } else {
        return (
          <>
            <div
              className={classes.infoContainer}
              onMouseEnter={(event) => setCurrentAnchor(event.currentTarget)}
              onMouseLeave={() => setCurrentAnchor(undefined)}
            >
              <Icon name={icon} colorVariant={color} size={size} />
            </div>
            {currentAnchor && (<TooltipPopover currentAnchor={currentAnchor}>{tooltip.render()}</TooltipPopover>)}
          </>
        );
      }
    } else if (forceIconDisplay) {
      return (<Icon name={defaultIcon} colorVariant={defaultColor} size={size} />);
    } else {
      return null;
    }
  };

  const renderTitle = () => {
    if (typeof title !== 'string') {
      return (
        <div className={classnames({ [classes.title]: !unPadded })}>
          {title}
          {subtitle && renderSubtitle()}
        </div>
      );
    }
    const renderedInfoTooltip = renderTooltip(infoTooltip, IconName.info, IconColorVariant.info);
    const renderedErrorTooltip = renderTooltip(errorTooltip, IconName.dangerous, IconColorVariant.error);
    const renderedMandatoryTooltip = renderTooltip(
      mandatoryIcon?.tooltip,
      IconName.emergency,
      mandatoryIcon?.color ?? IconColorVariant.error,
      !!mandatoryIcon,
      IconSizeVariant.xs
    );

    if (isMainBlockTitle) {
      return (
        <div className={classes.title}>
          <SpacingLine notCentered>
            <div className={classes.verticalAligner}>
              <Typo
                variant={variant === BlockTitleVariant.primary ? TypoVariant.blockPrimaryTitle : TypoVariant.blockSecondaryTitle}
                color={isGreyed ? theme.color.text.disabled : undefined}
              >
                <TextWithLinks text={title} />
              </Typo>
              {renderedMandatoryTooltip ? (
                <div
                  className={classes.mandatoryIconContainer}
                  style={{ height: variant === BlockTitleVariant.primary ? fontDefinition.blockPrimaryTitle.lineHeight : fontDefinition.blockSecondaryTitle.lineHeight }}
                >
                  {renderedMandatoryTooltip}
                </div>
              ) : null}
            </div>
            {renderedInfoTooltip ? (
              <div className={classnames(classes.iconButtonContainer, classes.verticalAligner)}>
                {renderedInfoTooltip}
              </div>
            ) : null}
            {renderedErrorTooltip ? (
              <div className={classnames(classes.iconButtonContainer, classes.verticalAligner)}>
                {renderedErrorTooltip}
              </div>
            ) : null}
            {actions && !actionsFullHeight ? (<div className={classes.verticalAligner}>{actions}</div>) : null}
            {actions && actionsFullHeight ? actions : null}
          </SpacingLine>
          {subtitle && renderSubtitle()}
        </div>
      );
    } else {
      let color: string | undefined;
      if (isGreyed) {
        color = theme.color.text.disabled;
      } else if (variant !== BlockTitleVariant.tertiary) {
        color = theme.color.text.secondary;
      }
      return (
        <div className={classes.title} onMouseEnter={onTitleMouseEnter} onMouseLeave={onTitleMouseLeave}>
          <SpacingLine notCentered>
            <div className={classes.verticalAligner}>
              <Typo
                variant={variant === BlockTitleVariant.tertiary ? TypoVariant.blockTertiaryTitle : TypoVariant.blockInlineTitle}
                color={color}
              >
                <TextWithLinks text={title} />
              </Typo>
              {renderedMandatoryTooltip ? (
                <div
                  className={classes.mandatoryIconContainer}
                  style={{ height: variant === BlockTitleVariant.tertiary ? fontDefinition.blockTertiaryTitle.lineHeight : fontDefinition.blockInlineTitle.lineHeight }}
                >
                  {renderedMandatoryTooltip}
                </div>
              ) : null}
            </div>
            {renderedInfoTooltip ? (
              <div className={classnames(classes.iconButtonContainer, classes.verticalAligner)}>
                {renderedInfoTooltip}
              </div>
            ) : null}
            {renderedErrorTooltip ? (
              <div className={classnames(classes.iconButtonContainer, classes.verticalAligner)}>
                {renderedErrorTooltip}
              </div>
            ) : null}
            {actions ? (
              <div className={classes[`sizeContext_${sizeVariant ?? SizeVariant.main}`]}>
                <SpacingLine>
                  {actions}
                </SpacingLine>
              </div>
            ) : null}
          </SpacingLine>
          {subtitle && renderSubtitle()}
        </div>
      );
    }
  };

  const onMouseEnter = buttonIcon && !buttonIcon.alwaysVisible ? () => {
    if (buttonIconContainerRef.current) {
      buttonIconContainerRef.current.style.display = 'flex';
    }
    if (iconContainerRef.current) {
      iconContainerRef.current.style.display = 'none';
    }
    buttonVisibleState.current = true;
  } : undefined;

  const onMouseLeave = buttonIcon && !buttonIcon.alwaysVisible ? () => {
    if (buttonIconContainerRef.current) {
      buttonIconContainerRef.current.style.display = 'none';
    }
    if (iconContainerRef.current) {
      iconContainerRef.current.style.display = 'flex';
    }
    buttonVisibleState.current = false;
  } : undefined;

  return (
    <>
      {buttonIcon || iconName ? (
        <span
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          ref={highlightIconRef}
          className={classnames({
            [classes.iconContainer]: true,
            [classes.iconButtonContainer]: true,
            [classes.verticalAligner]: true,
            [classes.greyed]: isGreyed,
          })}
        >
          {buttonIcon && (
            <div
              ref={buttonIconContainerRef}
              style={{ display: buttonVisibleState.current ? 'flex' : 'none' }}
            >
              <IconOnlyButton
                tooltip={buttonIcon.tooltip}
                onClick={() => {
                  buttonIcon.onClick();
                }}
                onMouseDown={(e) => e.preventDefault()}
                iconName={buttonIcon.iconName}
                sizeVariant={SizeVariant.small}
                variant={IconOnlyButtonVariants.tertiary}
              />
            </div>
          )}
          {iconName && (
            <div
              ref={iconContainerRef}
              style={buttonIcon ? { display: buttonVisibleState.current ? 'none' : 'flex' } : { display: 'flex' }}
            >
              <Icon name={iconName} colorVariant={IconColorVariant.secondary} />
            </div>
          )}
        </span>
      ) : null}
      <span
        ref={composeReactRefs(anchorRef, highlightTitleRef)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={classes.blockContainer}
        style={verticalSpan ? { gridRowStart: `span ${verticalSpan}` } : undefined}
      >
        {renderTitle()}
        {rightActions ? (<span className={classes.rightActions}>{rightActions}</span>) : null}
      </span>
    </>
  );
};

export default BlockTitle;
