import { equals } from 'ramda';
import type { ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import { isStoreObject, OriginSources, ValidationStatus } from 'yooi-store';
import { arrayOf, filterNullOrUndefined, isRichText, joinObjects, newError, richTextToText } from 'yooi-utils';
import { CommonAsType } from '../../common/fields/commonPropertyType';
import type { GetDslFieldHandler, UpdateOperationHandlers } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import type { BusinessRuleRegistration } from '../../common/types/TypeModuleDslType';
import { PropertyMandatoryType } from '../../common/types/TypeModuleDslType';
import { isInstanceOf } from '../../typeModule';
import { Class_IsExternal, Instance_Of, ModelType } from '../../typeModule/ids';
import { formatOrUndef, isSaneAssociationValue } from '../common/commonFieldUtils';
import {
  AnyElement,
  Association,
  Association_Role_Definition,
  Association_Role_Role1TypeInstance,
  Association_Role_Role2TypeInstance,
  AssociationField_Definition,
  AssociationField_SourceRole,
  AssociationFieldDefinition,
  AssociationFieldDefinition_Fields,
  AssociationFieldDefinition_Role1Type,
  AssociationFieldDefinition_Role2Type,
  Concept,
  Concept_Name,
  ConceptDefinition_Name,
  ConceptDefinition_RestrictedAccess,
  Field_Formula,
  Field_IntegrationOnly,
  GroupMembershipAssociationDefinition,
} from '../ids';
import type { ConceptStoreObject } from '../model';
import { registerField } from '../module';
import type { Filters, PathStep } from '../moduleType';
import { PathStepType } from '../moduleType';
import type { ConceptReference, DimensionsMapping, MultipleParameterValue, ParametersMapping, ResolutionStack, SingleParameterValue, ValueResolution } from '../utils';
import {
  adminOnlyAcl,
  conceptRefApiSchema,
  createValuePathResolver,
  FILTER_PARAMETER_CURRENT,
  getConceptInstanceProxy,
  getFieldDimensionOfModelType,
  getFilterFunction,
  getInstanceLabel,
  getMultipleRelationFieldExportColumnHeaders,
  getReverseAssociationField,
  handleProxyArrayProps,
  InstanceReferenceType,
  isConceptValid,
  isMultiValueResolution,
  isSingleValueResolution,
  isValueResolutionOfType,
  ParsedDimensionType,
  parseDimensionMapping,
  resolveFieldValue,
  segregateParametersMapping,
  toConceptReference,
} from '../utils';
import { conceptType } from '../utils/formula/modelFunctions';
import type { MultipleRelationFieldExportConfiguration } from '../utils/relationFieldUtils';
import type { AssociationField } from './types';

const cannotAssociateRestrictedConcept: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  if (properties && !getObjectOrNull(id[0])) {
    const role1Concept = getObjectOrNull(properties[AssociationFieldDefinition_Role1Type] as string);
    const role2Concept = getObjectOrNull(properties[AssociationFieldDefinition_Role2Type] as string);
    if (role1Concept?.[ConceptDefinition_RestrictedAccess] || role2Concept?.[ConceptDefinition_RestrictedAccess]) {
      return { rule: 'field.associationField.cannotAssociateRestrictedConcept', status: ValidationStatus.REJECTED };
    }
  }
  return undefined;
};

const syncIntegrationOnly: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  if (!properties) {
    // deletion don't care, reverse will be deleted
    return undefined;
  }
  const associationField = getObjectOrNull(id[0]);
  if (!associationField) {
    // creation
    const associationFieldDefinitionId = properties[AssociationField_Definition] as string | undefined;
    if (!associationFieldDefinitionId) {
      // should never happen, association field should always have a definition
      return undefined;
    }
    const reverseAssociationFields = getObjectOrNull(associationFieldDefinitionId)?.navigateBack(AssociationField_Definition) ?? [];

    if (!reverseAssociationFields.length) {
      // reverse association field is not yet created
      return undefined;
    }
    // We can only have one reverse
    const reverseAssociationField = reverseAssociationFields[0];
    if (properties[Field_IntegrationOnly] === true) {
      // We explicitly want it to be integration only at the creation, let's put the reverse field in integration only
      return {
        rule: 'associationField.syncIntegrationOnly',
        status: ValidationStatus.ACCEPTED,
        generateSystemEvent: ({ updateObject }) => {
          updateObject(reverseAssociationField.id, {
            [Field_IntegrationOnly]: true,
          });
        },
      };
    } else if (reverseAssociationField[Field_IntegrationOnly] === true) {
      // other association field is integration only, we want to create our association field with the same status
      return {
        rule: 'associationField.syncIntegrationOnly',
        status: ValidationStatus.ACCEPTED,
        generateSystemEvent: ({ updateObject }) => {
          updateObject(id, {
            [Field_IntegrationOnly]: true,
          });
        },
      };
    }
  } else if (properties[Field_IntegrationOnly] !== undefined) {
    // update
    const reverseAssociationField = getReverseAssociationField(associationField);
    if (reverseAssociationField && Boolean(reverseAssociationField[Field_IntegrationOnly]) !== Boolean(properties[Field_IntegrationOnly])) {
      // Only update if necessary, or we will have an infinite event loop (we use boolean because integration only can be undefined / null / false)
      return {
        rule: 'associationField.syncIntegrationOnly',
        status: ValidationStatus.ACCEPTED,
        generateSystemEvent: ({ updateObject }) => {
          updateObject(reverseAssociationField.id, {
            [Field_IntegrationOnly]: Boolean(properties[Field_IntegrationOnly]),
          });
        },
      };
    }
  }
  // no update
  return undefined;
};

const checkAssociationField: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  if (!properties) {
    return undefined;
  }
  const object = getObjectOrNull(id)?.asRawObject() ?? {};
  const allProperties = joinObjects(object, properties);
  const sourceRole = allProperties[AssociationField_SourceRole];
  const fieldDefinition = getObjectOrNull(allProperties[AssociationField_Definition] as string);
  if (!fieldDefinition) {
    return { rule: 'field.associationField.hasFieldDefinition', status: ValidationStatus.REJECTED };
  }
  if (sourceRole === undefined || sourceRole === null) {
    return { rule: 'field.associationField.hasValidSourceRole', status: ValidationStatus.REJECTED };
  }
  return { rule: 'field.associationField.checkAssociationField', status: ValidationStatus.ACCEPTED };
};

const checkAssociationFieldDefinitionDeletion: BusinessRuleRegistration = ({ getObject }) => (origin, { id, properties }) => {
  if (origin.source === OriginSources.GARBAGE) {
    return undefined;
  }
  if (properties === null) {
    const associationFieldDefinition = getObject(id);
    const associatedFields = associationFieldDefinition.navigateBack(AssociationFieldDefinition_Fields);
    const hasNoTargetType = associationFieldDefinition.navigateOrNull(AssociationFieldDefinition_Role2Type) === null;
    const hasNoSourceType = associationFieldDefinition.navigateOrNull(AssociationFieldDefinition_Role1Type) === null;
    if (hasNoTargetType || hasNoSourceType || associatedFields.length === 0 || (associatedFields.length === 1 && !associatedFields[0][Field_Formula])) {
      return { rule: 'field.associationFieldDefinition.allowDeletion', status: ValidationStatus.ACCEPTED };
    } else {
      return { rule: 'field.associationFieldDefinition.allowDeletion', status: ValidationStatus.REJECTED };
    }
  }
  return undefined;
};

const preventRoleTypeUpdate: (propertyId: string) => BusinessRuleRegistration = (propertyId) => ({ getObjectOrNull }) => (_, { id, properties }) => {
  if (!properties) {
    // Deletion, timeseries ? Don't care
    return undefined;
  }
  const currentDefinition = getObjectOrNull(id);
  if (!currentDefinition) {
    // Creation ? Don't care
    return undefined;
  }

  if (properties[propertyId] === currentDefinition[propertyId]) {
    // Same value? Don't care
    return undefined;
  } else {
    return { rule: `field.associationFieldDefinition.${propertyId}.preventTargetTypeUpdate`, status: ValidationStatus.REJECTED };
  }
};

const checkAssociationValues: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  if (properties === null) {
    // Deletion ? Don't care
    return undefined;
  }

  const [, definitionId, role1TargetId, role2TargetId] = id;

  const definition = getObjectOrNull(definitionId);
  if (!definition) {
    return { rule: 'field.association.unknownDefinition', status: ValidationStatus.REJECTED };
  }

  const role1 = definition.navigate(AssociationFieldDefinition_Role1Type);
  if (!role1[Class_IsExternal] && !isInstanceOf(getObjectOrNull(role1TargetId), role1.id)) {
    return { rule: 'field.association.invalidRole1', status: ValidationStatus.REJECTED };
  }

  const role2 = definition.navigate(AssociationFieldDefinition_Role2Type);
  if (!role2[Class_IsExternal] && !isInstanceOf(getObjectOrNull(role2TargetId), role2.id)) {
    return { rule: 'field.association.invalidRole2', status: ValidationStatus.REJECTED };
  }

  return { rule: 'field.association.valid', status: ValidationStatus.ACCEPTED };
};

const getValueResolution = (
  objectStore: ObjectStoreWithTimeseries,
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
  resolutionStack?: ResolutionStack
): ValueResolution<StoreObject[]> => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
  if (isValueResolutionOfType(valueResolution, (value): value is StoreObject[] => Array.isArray(value) && value.every(isStoreObject))) {
    return valueResolution;
  } else {
    return {
      value: [],
      isComputed: valueResolution.isComputed,
      isTimeseries: valueResolution.isTimeseries,
      error: valueResolution.error ?? new ResolutionTypeError(['StoreObject[]'], typeof valueResolution.value),
      getDisplayValue: () => [],
    };
  }
};

const getTargetType = (objectStore: ObjectStoreWithTimeseries, fieldId: string) => {
  const field = objectStore.getObject(fieldId);
  const withFormula = Boolean(field[Field_Formula]);
  if (withFormula) {
    return objectStore.getObject(Concept);
  } else {
    const sourceRole = field[AssociationField_SourceRole];
    const targetRoleField = sourceRole === 1 ? AssociationFieldDefinition_Role2Type : AssociationFieldDefinition_Role1Type;
    const fieldDefinition = field.navigate(AssociationField_Definition);
    return fieldDefinition.navigateOrNull(targetRoleField) ?? objectStore.getObject(Concept);
  }
};

interface AssociationSetUpdate {
  action: 'set',
  objectIds: string[],
}

interface AssociationAddUpdate {
  action: 'add',
  objectIds: string[],
}

interface AssociationRemoveUpdate {
  action: 'remove',
  objectIds: string[],
}

export type AssociationUpdate = AssociationAddUpdate | AssociationSetUpdate | AssociationRemoveUpdate;

interface AssociationUpdateOperationTypes {
  INITIALIZE: { type: 'value', value: string[] } | { type: 'path', path: PathStep[] },
  REPLACE: { type: 'value', value: string[] } | { type: 'path', path: PathStep[] },
  ADD: { type: 'value', value: string[] } | { type: 'path', path: PathStep[] },
  REMOVE: { type: 'value', value: string[] } | { type: 'path', path: PathStep[] },
  CLEAR: undefined,
}

export interface AssociationFieldStepConfiguration {
  filters?: Filters,
  conceptDefinitionId?: string,
}

type AssociationFieldHandler = GetDslFieldHandler<
  AssociationField,
  string[],
  AssociationUpdate,
  StoreObject[],
  StoreObject[],
  ConceptReference[],
  undefined,
  AssociationUpdateOperationTypes,
  AssociationFieldStepConfiguration,
  MultipleRelationFieldExportConfiguration
>;

export const associationFieldHandler: AssociationFieldHandler = registerField({
  model: {
    label: 'AssociationField',
    title: 'Association many to many (n-n)',
    withApiAlias: true,
    properties: [{ label: 'SourceRole', as: CommonAsType.number, mandatory: { type: PropertyMandatoryType.mandatory } }, { label: 'Field_TargetFilter', as: CommonAsType.Filters }],
    relations: [{
      label: 'Definition',
      targetTypeId: AssociationFieldDefinition,
      reverseLabel: 'AssociationFieldDefinition_Fields',
      mandatory: { type: PropertyMandatoryType.mandatory },
    }],
    businessRules: [checkAssociationField, syncIntegrationOnly],
    extraModel: ({ type, association }) => {
      // Specific type required by Association to mark any value of from & to as external
      type({
        label: 'AnyElement',
        isExternal: true,
        accessControlList: {
          READ: () => () => ({ rule: 'anyElement.read.allow', status: ValidationStatus.ACCEPTED }),
          WRITE: () => () => ({ rule: 'anyElement.write.forbidden', status: ValidationStatus.REJECTED }),
          DELETE: () => () => ({ rule: 'anyElement.delete.forbidden', status: ValidationStatus.REJECTED }),
        },
      });

      // Many to Many
      type({
        label: 'AssociationFieldDefinition',
        accessControlList: {
          READ: () => () => ({ rule: 'associationFieldDefinition.read.allow', status: ValidationStatus.ACCEPTED }),
          WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'associationFieldDefinition', 'WRITE'),
          DELETE: () => (_, objectId) => ({
            rule: 'associationFieldDefinition.delete.delegate',
            status: ValidationStatus.DELEGATED,
            targetAction: 'WRITE',
            targetId: objectId,
          }),
        },
        businessRules: [
          cannotAssociateRestrictedConcept,
          checkAssociationFieldDefinitionDeletion,
        ],
        objectDebugLabel: ({ getObject }) => (id) => {
          const object = getObject(id);
          const role1 = object.navigateOrNull(AssociationFieldDefinition_Role1Type)?.[ConceptDefinition_Name] ?? object[AssociationFieldDefinition_Role1Type];
          const role2 = object.navigateOrNull(AssociationFieldDefinition_Role2Type)?.[ConceptDefinition_Name] ?? object[AssociationFieldDefinition_Role2Type];
          return `Association definition between '${role1}' and '${role2}'`;
        },
      })
        .relation({
          label: 'AssociationFieldDefinition_Role1Type',
          targetTypeId: ModelType,
          reverseLabel: 'ModelType_AssociationFieldDefinitionAsRole1',
          businessRules: [preventRoleTypeUpdate(AssociationFieldDefinition_Role1Type)],
        })
        .relation({
          label: 'AssociationFieldDefinition_Role2Type',
          targetTypeId: ModelType,
          reverseLabel: 'ModelType_AssociationFieldDefinitionAsRole2',
          businessRules: [preventRoleTypeUpdate(AssociationFieldDefinition_Role2Type)],
        });

      association({
        label: 'Association',
        roles: [
          { label: 'Definition', targetTypeId: AssociationFieldDefinition },
          { label: 'Role1TypeInstance', targetTypeId: AnyElement },
          { label: 'Role2TypeInstance', targetTypeId: AnyElement },
        ],
        accessControlList: {
          READ: () => (origin, objectId, _, readAcl) => {
            if (
              !readAcl('READ', objectId[Association_Role_Role1TypeInstance + 1], origin)
              || !readAcl('READ', objectId[Association_Role_Role2TypeInstance + 1], origin)
            ) {
              return { status: ValidationStatus.REJECTED, rule: 'association.read.cannotReadRoles' };
            } else {
              return { status: ValidationStatus.ACCEPTED, rule: 'association.read.canReadRoles' };
            }
          },
          WRITE: (store) => (origin, objectId, _, readAcl) => {
            const canReadField = readAcl('READ', objectId, origin) ?? { validated: false };

            if (!canReadField) {
              return { status: ValidationStatus.REJECTED, rule: 'association.write.cannotReadRoles' };
            }
            if (objectId[1] === GroupMembershipAssociationDefinition) {
              return adminOnlyAcl(store, origin.userId, 'groupMembership', 'WRITE');
            }
            const canWriteInstance1 = readAcl('WRITE', objectId[Association_Role_Role1TypeInstance + 1], origin);
            const canWriteInstance2 = readAcl('WRITE', objectId[Association_Role_Role2TypeInstance + 1], origin);

            if (canWriteInstance1 || canWriteInstance2) {
              return { status: ValidationStatus.ACCEPTED, rule: 'association.write.allow' };
            }
            return { status: ValidationStatus.REJECTED, rule: 'association.write.cannotWriteAnyInstance' };
          },
          DELETE: () => (_, objectId) => ({ rule: 'association.delete.delegate', status: ValidationStatus.DELEGATED, targetId: objectId, targetAction: 'WRITE' }),
        },
        businessRules: [checkAssociationValues],
      });
    },
  },
  handler: (objectStore, fieldId, { resolveConfiguration }) => {
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => {
      const values = getValueResolution(objectStore, fieldId, dimensionsMapping).value ?? [];
      return values.map((object) => formatOrUndef(getInstanceLabel(objectStore, object))).join(', ');
    };

    const extractOperationValueObjectIds = (
      parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
      operationValue: { type: 'value', value: string[] } | { type: 'path', path: PathStep[] }
    ): string[] => {
      const extractConceptIds = (conceptOrConcepts: unknown | unknown[]): string[] => {
        if (Array.isArray(conceptOrConcepts)) {
          return conceptOrConcepts
            .filter((concept) => isStoreObject(concept) && isConceptValid(objectStore, concept.id))
            .map(({ id }) => id);
        } else if (isStoreObject(conceptOrConcepts) && isConceptValid(objectStore, conceptOrConcepts.id)) {
          return [conceptOrConcepts.id];
        } else {
          return [];
        }
      };

      if (operationValue.type === 'path') {
        const { path } = operationValue;
        const resolution = createValuePathResolver(objectStore, parametersMapping).resolvePathValue(path);
        if (isSingleValueResolution(resolution)) {
          return extractConceptIds(resolution.value);
        } else if (isMultiValueResolution(resolution)) {
          return resolution.values.flatMap((maybeConceptOrConcepts) => extractConceptIds(maybeConceptOrConcepts));
        } else {
          return [];
        }
      } else {
        return operationValue.value;
      }
    };
    return {
      describe: () => ({ hasData: true, returnType: arrayOf(conceptType(getTargetType(objectStore, fieldId).id)), timeseriesMode: 'none' }),
      restApi: {
        returnTypeSchema: { type: 'array', items: conceptRefApiSchema },
        formatValue: (value, canRead) => (value ?? [])
          .filter((concept) => canRead([concept.id]))
          .map(toConceptReference)
          .filter(filterNullOrUndefined),
      },
      getStoreValue: (dimensionsMapping) => {
        const parsedDimension = parseDimensionMapping(dimensionsMapping);
        if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
          const configuration = resolveConfiguration();
          const { sourceRole, definitionId } = configuration;
          if (sourceRole === undefined || definitionId === undefined) {
            throw newError('Invalid association field', { fieldId });
          }

          const targetRole = sourceRole === 1 ? 2 : 1;
          return objectStore.withAssociation(Association)
            .withRole(Association_Role_Definition, definitionId)
            .withRole(sourceRole, parsedDimension.objectId)
            .withExternalRole(targetRole)
            .list()
            .map((association) => association.role(targetRole));
        } else {
          return [];
        }
      },
      getValueWithoutFormula: (dimensionsMapping) => {
        const parsedDimension = parseDimensionMapping(dimensionsMapping);
        if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
          const { sourceRole, definitionId } = resolveConfiguration();
          if (sourceRole === undefined || definitionId === undefined) {
            throw newError('Invalid association field', { fieldId });
          }

          const association = objectStore.withAssociation(Association)
            .withRole(Association_Role_Definition, definitionId)
            .withRole(sourceRole, parsedDimension.objectId);

          const targetRole = sourceRole === 1 ? 2 : 1;
          return (getTargetType(objectStore, fieldId)?.[Class_IsExternal] ? association.withExternalRole(targetRole) : association)
            .list()
            .map((assoc) => assoc.navigateRole(targetRole))
            .filter((instance) => isConceptValid(objectStore, instance.id));
        } else {
          return [];
        }
      },
      getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
      updateValue: (dimensionsMapping, update) => {
        const parsedDimension = parseDimensionMapping(dimensionsMapping);
        if (parsedDimension.type !== ParsedDimensionType.MonoDimensional) {
          throw newError('Association field only support mono-dimensional absorbed values');
        }
        const existingObjectIds = update.objectIds.filter((objectId) => objectStore.getObjectOrNull(objectId) !== null);

        const associationField = resolveConfiguration();

        switch (update.action) {
          case 'add': {
            existingObjectIds
              .forEach((id) => {
                objectStore.withAssociation(Association)
                  .withRole(Association_Role_Definition, associationField.definitionId)
                  .withRole(associationField.sourceRole, parsedDimension.objectId)
                  .withRole(associationField.sourceRole === 1 ? 2 : 1, id)
                  .updateObject({});
              });
            break;
          }
          case 'set': {
            const currentIds = objectStore.withAssociation(Association)
              .withRole(Association_Role_Definition, associationField.definitionId)
              .withRole(associationField.sourceRole, parsedDimension.objectId)
              .list()
              .map((association) => association.role(associationField.sourceRole === 1 ? 2 : 1));

            existingObjectIds.filter((id) => !currentIds.includes(id))
              .forEach((id) => {
                objectStore.withAssociation(Association)
                  .withRole(Association_Role_Definition, associationField.definitionId)
                  .withRole(associationField.sourceRole, parsedDimension.objectId)
                  .withRole(associationField.sourceRole === 1 ? 2 : 1, id)
                  .updateObject({});
              });

            currentIds.filter((id) => !existingObjectIds.includes(id))
              .forEach((id) => {
                objectStore.withAssociation(Association)
                  .withRole(Association_Role_Definition, associationField.definitionId)
                  .withRole(associationField.sourceRole, parsedDimension.objectId)
                  .withRole(associationField.sourceRole === 1 ? 2 : 1, id)
                  .deleteObject();
              });
            break;
          }
          case 'remove': {
            existingObjectIds
              .forEach((id) => {
                objectStore.withAssociation(Association)
                  .withRole(Association_Role_Definition, associationField.definitionId)
                  .withRole(associationField.sourceRole, parsedDimension.objectId)
                  .withRole(associationField.sourceRole === 1 ? 2 : 1, id)
                  .deleteObject();
              });
            break;
          }
        }
      },
      isEmpty: (dimensionsMapping) => getValueResolution(objectStore, fieldId, dimensionsMapping).value.length === 0,
      isSaneValue: (objectId) => {
        const instance = objectStore.getObjectOrNull(objectId);
        const dimension = instance ? getFieldDimensionOfModelType(objectStore, fieldId, instance[Instance_Of] as string) : undefined;
        const isValid = isSaneAssociationValue(
          objectStore,
          objectId,
          getTargetType(objectStore, fieldId)?.id,
          getValueResolution(objectStore, fieldId, dimension ? { [dimension]: objectId } : {})?.value
        );
        return joinObjects({ isValid }, (isValid ? {} : { error: newError('Some selected elements are not allowed') }));
      },
      getValueAsText,
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else {
            const valueResolution = getValueResolution(objectStore, fieldId, dimensionsMapping);
            const value = valueResolution && !valueResolution.error ? valueResolution.value : [];
            return handleProxyArrayProps(prop, value, (v) => getConceptInstanceProxy(objectStore, v.id));
          }
        },
      }),
      getExportColumnHeaders: getMultipleRelationFieldExportColumnHeaders(objectStore),
      getExportValue: (dimensionsMapping, configurationProp) => {
        const configuration: MultipleRelationFieldExportConfiguration = configurationProp ?? { type: 'path', separator: ',' };
        const values = getValueResolution(objectStore, fieldId, dimensionsMapping);
        return {
          format: 'string',
          value: values.value.map((instance) => {
            if (configuration.type === 'path') {
              const defaultPath: PathStep[] = [
                { type: PathStepType.dimension, conceptDefinitionId: instance[Instance_Of] as string },
                { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
                { type: PathStepType.field, fieldId: Concept_Name },
              ];
              const pathResolution = createValuePathResolver(objectStore, { [FILTER_PARAMETER_CURRENT]: { type: 'single', id: instance.id } })
                .resolvePathValue(configuration.path ?? defaultPath);
              if (isSingleValueResolution(pathResolution)) {
                return isRichText(pathResolution.value) ? richTextToText(pathResolution.value) : pathResolution.value;
              } else {
                return undefined;
              }
            } else {
              return instance.id;
            }
          }).filter(filterNullOrUndefined)
            .join(configuration.separator),
        };
      },
      getTargetType: () => getTargetType(objectStore, fieldId),
      resolvePathStepConfiguration: (config) => {
        const resolveValue = (
          dimensionsMapping: DimensionsMapping,
          parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
          resolutionStack: ResolutionStack
        ): StoreObject[] => {
          const { value, error } = getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack);
          if (error) {
            throw error;
          } else {
            const { filters } = config;
            if (filters) {
              const { singleParametersMapping } = segregateParametersMapping(parametersMapping);
              const filterFunction = getFilterFunction(objectStore, filters);
              if (filterFunction) {
                return value.filter((v) => filterFunction(joinObjects(singleParametersMapping, { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: v.id } })));
              }
            }
            return value;
          }
        };
        return {
          hasData: true,
          timeseriesMode: 'none',
          getValueResolutionType: () => arrayOf(conceptType(config.conceptDefinitionId ?? getTargetType(objectStore, fieldId).id)),
          resolveDimension: (dimensionsMapping, parametersMapping, resolutionStack) => {
            const value = resolveValue(dimensionsMapping, parametersMapping, resolutionStack);
            return { type: 'multiple' as const, instances: value as ConceptStoreObject[] };
          },
          resolveValue,
        };
      },
      updateOperationHandlers: {
        INITIALIZE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            const fieldHandler = associationFieldHandler(objectStore, fieldId);
            if (fieldHandler.getStoreValue(dimensionsMapping).length) {
              return;
            }
            fieldHandler.updateValue(dimensionsMapping, { action: 'set', objectIds: extractOperationValueObjectIds(parametersMapping, value) });
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: [] },
        },
        REPLACE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            associationFieldHandler(objectStore, fieldId)
              .updateValue(dimensionsMapping, { action: 'set', objectIds: extractOperationValueObjectIds(parametersMapping, value) });
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: [] },
        },
        ADD: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            associationFieldHandler(objectStore, fieldId)
              .updateValue(dimensionsMapping, { action: 'add', objectIds: extractOperationValueObjectIds(parametersMapping, value) });
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: [] },
        },
        REMOVE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            associationFieldHandler(objectStore, fieldId)
              .updateValue(dimensionsMapping, { action: 'remove', objectIds: extractOperationValueObjectIds(parametersMapping, value) });
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: [] },
        },
        CLEAR: {
          applyOperation: (dimensionsMapping) => {
            associationFieldHandler(objectStore, fieldId).updateValue(dimensionsMapping, { action: 'set', objectIds: [] });
          },
          sanitizeOperation: () => undefined,
        },
      } satisfies UpdateOperationHandlers<AssociationUpdateOperationTypes>,
    };
  },
  historyEventProducer: ({ getObjectOrNull, withAssociation }, fieldId) => ({
    value: {
      collectImpactedInstances: ({ id }) => {
        if (id.length > 1 && id[0] === Association) {
          const field = getObjectOrNull(fieldId);
          if (field && field[AssociationField_Definition] === id[Association_Role_Definition + 1] /* In reality, Field is the AssociationFieldDefinition */) {
            return [[id[field[AssociationField_SourceRole] as number + 1]]];
          }
        }
        return [];
      },
      getValue: (id) => {
        const field = getObjectOrNull(fieldId);
        return {
          value: field ? withAssociation(Association)
            .withRole(Association_Role_Definition, field[AssociationField_Definition] as string)
            .withRole(field[AssociationField_SourceRole] as number, id[0])
            .list()
            .map((association) => association.role(field[AssociationField_SourceRole] as number === 1 ? 2 : 1)) : [],
          version: 1,
        };
      },
      areValuesEquals: (value1, value2) => equals(value1?.value, value2?.value),
    },
  }),
});
