import type { DimensionsMapping, NumberColorStepValueResolved, ParametersMapping, ValuePathResolver } from 'yooi-modules/modules/conceptModule';
import {
  createValuePathResolver,
  dimensionsMappingToParametersMapping,
  isSingleFieldResolution,
  isSingleValueResolution,
  numberFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import { NumberField } from 'yooi-modules/modules/conceptModule/ids';
import type { DisplayOptions, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { ViewType } from 'yooi-modules/modules/dashboardModule';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { joinObjects } from 'yooi-utils';
import type { Labels } from '../../../../components/charts/ChartTypes';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { FieldResolutionError, formatErrorForUser } from '../../errorUtils';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { getProjectionValueLabel, getSeriesLabel, getViewDefinitionSeries } from '../common/series/viewWithSeriesFeatureUtils';
import { resolveChartColors } from '../common/viewUtils';
import { computeDimension } from '../data/dataResolution';
import type { ViewResolutionError } from '../viewResolutionUtils';
import { SeriesResolutionError } from '../viewResolutionUtils';
import type { GaugeViewResolvedDefinition } from './gaugeViewDefinitionHandler';

export interface GaugeViewResolution {
  type: ViewType.Gauge,
  instanceId?: string,
  error?: undefined,
  series?: GaugeChartSeriesWithIdResolved[],
  minValue: NumberColorStepValueResolved,
  maxValue: NumberColorStepValueResolved,
  steps: NumberColorStepValueResolved[],
  configurationError?: string,
  labels: Labels[],
}

export interface GaugeChartSeriesWithIdResolved {
  id: string,
  dimensionsMapping: DimensionsMapping | undefined,
  color?: string,
  fieldId: string,
  label: string | undefined,
  value: number,
  key?: string,
}

const resolveSeriesColor = (displayOptions: DisplayOptions | undefined, pathResolver: ValuePathResolver) => {
  if (displayOptions?.colorPath && displayOptions.colorPath.length > 0) {
    const colorResolution = pathResolver.resolvePathValue<string>(displayOptions.colorPath);
    if (colorResolution && isSingleValueResolution(colorResolution) && colorResolution.value) {
      return colorResolution.value;
    }
  } else if (displayOptions?.color) {
    return displayOptions.color;
  }
  return undefined;
};

export const resolveGaugeChartView = (
  store: FrontObjectStore,
  viewDimensions: ViewDimension[],
  gaugeViewDefinition: GaugeViewResolvedDefinition,
  parametersMapping: ParametersMapping,
  filterConfiguration?: FilterConfiguration
): GaugeViewResolution | ViewResolutionError => {
  const { minValue: graphMinValue, maxValue: graphMaxValue, rangeValues: graphRangeValues } = gaugeViewDefinition;
  const seriesDefinitions = getViewDefinitionSeries(gaugeViewDefinition);
  const dimensionDisplayOptions = gaugeViewDefinition.getDimensionsDisplay(viewDimensions);

  const { mapReduceHandler: computeDimensionHandler } = computeDimension(store, parametersMapping, viewDimensions, filterConfiguration);
  const xDimensionCompositions = computeDimensionHandler(viewDimensions);
  if (xDimensionCompositions instanceof Error) {
    return { type: 'error', error: formatErrorForUser(store, xDimensionCompositions) };
  }

  if (seriesDefinitions.length > 0) {
    const valueSeries = [] as GaugeChartSeriesWithIdResolved[];
    let conceptId;
    const labels: Labels[] = [];
    for (let seriesDefinitionIndex = 0; seriesDefinitionIndex < seriesDefinitions.length; seriesDefinitionIndex += 1) {
      const seriesDefinition = seriesDefinitions[seriesDefinitionIndex];
      const onlyDimensionLabel = seriesDefinitions.length === 0 || seriesDefinitions.every(({ displayOptions }) => !displayOptions?.withLegend);
      const onlySeriesLabel = viewDimensions.length === 0
        || dimensionDisplayOptions.every((dimensionDisplay) => !dimensionDisplay.withLegend);

      const { label: seriesLabel, path: seriesPath, displayOptions: seriesDisplayOptions } = seriesDefinition;
      for (let i = 0; i < xDimensionCompositions.length; i += 1) {
        const dimComp = xDimensionCompositions[i];
        const parameterCompositions = joinObjects(dimensionsMappingToParametersMapping(dimComp), parametersMapping);
        const resolutionField = createValuePathResolver(store, parameterCompositions).resolvePathField(seriesPath);
        if (isSingleFieldResolution(resolutionField) && isInstanceOf(store.getObjectOrNull(resolutionField.fieldId), NumberField)) {
          conceptId = resolutionField.dimensionsMapping
          && Object.values(resolutionField.dimensionsMapping).length === 1 ? Object.values(resolutionField.dimensionsMapping)[0] : undefined;
          const valueResolution = numberFieldHandler(store, resolutionField.fieldId).getValueResolution(resolutionField.dimensionsMapping ?? {});
          if (valueResolution.error !== undefined) {
            return {
              type: 'error',
              error: formatErrorForUser(
                store,
                new SeriesResolutionError(
                  getSeriesLabel(store, seriesLabel, seriesDefinitionIndex, seriesPath, viewDimensions, Object.keys(parametersMapping)),
                  new FieldResolutionError(resolutionField.fieldId, valueResolution.error)
                )
              ),
            };
          }

          const fullProjectedValue = Object.entries(dimComp)
            .filter(([dimId]) => dimensionDisplayOptions.find((dim) => dim.id === dimId)?.withLegend)
            .map(([__, dimValue]) => dimValue).map((val) => getProjectionValueLabel(store, val))
            .join(' x ');
          const serieLegendLabel = seriesDisplayOptions?.withLegend ? getSeriesLabel(store, seriesLabel, 0, seriesPath, viewDimensions, Object.keys(parametersMapping)) : '';

          let labelValue;
          if ((onlyDimensionLabel || onlySeriesLabel) && !(onlyDimensionLabel && onlySeriesLabel)) {
            labelValue = { label: fullProjectedValue || serieLegendLabel, color: seriesDisplayOptions?.color ?? '', key: Object.values(dimComp).join('|') };
          } else if (!seriesDisplayOptions?.withLegend) {
            labelValue = null;
          } else {
            const legendLabel = `${fullProjectedValue} - ${serieLegendLabel}`;
            labelValue = { label: legendLabel, color: seriesDisplayOptions?.color ?? '', key: Object.values(dimComp).join('|') };
          }

          if (labelValue) {
            labels.push(labelValue);
          }

          const value: GaugeChartSeriesWithIdResolved = {
            id: resolutionField.fieldId,
            color: resolveSeriesColor(seriesDisplayOptions, createValuePathResolver(store, parameterCompositions)),
            dimensionsMapping: resolutionField.dimensionsMapping,
            fieldId: resolutionField.fieldId,
            label: getSeriesLabel(store, seriesLabel, 0, seriesPath, viewDimensions, Object.keys(parametersMapping)),
            value: valueResolution.getDisplayValue() as number,
            key: Object.values(dimComp).join('|'),
          };
          valueSeries.push(value);
        }
      }
    }
    const { min, max, steps } = resolveChartColors(store, graphMinValue, graphMaxValue, graphRangeValues, parametersMapping);
    const existingLabel = new Set();
    const deduplicateLabels = labels.filter(({ label, color }) => {
      const key = `${label}|${color}`;
      const isDuplicate = existingLabel.has(key);
      if (!isDuplicate) {
        existingLabel.add(key);
      }
      return !isDuplicate;
    });
    return {
      type: ViewType.Gauge,
      instanceId: conceptId,
      series: valueSeries,
      minValue: min,
      maxValue: max,
      steps: steps?.filter(({ color, value: stepValue }) => color && (stepValue || stepValue === 0)),
      labels: deduplicateLabels,
    };
  } else {
    return { type: 'error', error: i18n`Missing series` };
  }
};
