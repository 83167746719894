import type { FunctionComponent } from 'react';
import { buildFunctionalIdFormatter } from 'yooi-modules/modules/conceptModule/fields/idField';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import FunctionalId from '../../../../components/molecules/FunctionalId';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import TableInnerCellContainer, { TableInnerCellContainerVariants } from '../../../../components/molecules/TableInnerCellContainer';
import useStore from '../../../../store/useStore';
import { buildPadding, Spacing, spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import useUsageContext, { UsageVariant } from '../../../../utils/useUsageContext';

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: spacingRem.s,
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
    borderWidth: 0,
    borderLeftWidth: '0.1rem',
  },
}), 'idFieldRenderer');

interface IdFieldRendererProps {
  objectId: string,
  fieldId: string,
}

const IdFieldRenderer: FunctionComponent<IdFieldRendererProps> = ({ objectId, fieldId }) => {
  const classes = useStyles();

  const store = useStore();

  const usageVariant = useUsageContext();

  const object = store.getObject(objectId);
  const functionalId = buildFunctionalIdFormatter(store, object[Instance_Of] as string, fieldId)(object[fieldId] as string | undefined);

  if (usageVariant === UsageVariant.inTable) {
    return (
      <TableInnerCellContainer variant={TableInnerCellContainerVariants.centeredFlex} padding={buildPadding({ x: Spacing.s })}>
        <FunctionalId functionalId={functionalId} />
      </TableInnerCellContainer>
    );
  } else {
    return (
      <div className={classes.container}>
        <SpacingLine>
          <FunctionalId functionalId={functionalId} />
        </SpacingLine>
      </div>
    );
  }
};

export default IdFieldRenderer;
