import type { FunctionComponent, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { joinObjects } from 'yooi-utils';
import BaseLayout from '../../../components/templates/BaseLayout';
import Header from '../../../components/templates/Header';
import HeaderTabs from '../../../components/templates/HeaderTabs';
import i18n from '../../../utils/i18n';
import useNavigation, { NavigationElementContainer } from '../../../utils/useNavigation';
import HeaderStatic from '../../_global/HeaderStatic';
import type { NavigationFilter } from '../../_global/navigationUtils';
import TopBar from '../../_global/topBar/TopBar';
import GeneralTab from './GeneralTab';
import IFrameTab from './IFrameTab';

const AdminSecurityConfigurationPage: FunctionComponent = () => {
  const location = useLocation();
  const navigation = useNavigation<NavigationFilter>();

  const tabs: { key: string, hash: string, name: string, render: () => ReactElement | null }[] = [
    {
      key: 'general',
      name: i18n`General`,
      hash: '#general',
      render: () => (<GeneralTab />),
    },
    {
      key: 'iframe sources',
      name: i18n`iFrame sources`,
      hash: '#iframe-sources',
      render: () => (<IFrameTab />),
    },
  ];

  const tabIndex = tabs.findIndex((tab) => tab.hash === location.hash);
  const selectedTabIndex = tabIndex >= 0 ? tabIndex : 0;

  return (
    <NavigationElementContainer element={{ key: 'security' }}>
      <BaseLayout
        topBar={(<TopBar />)}
        header={(
          <Header
            firstLine={(<HeaderStatic text={i18n`Security`} />)}
            tabsLine={(
              <HeaderTabs
                selectedTabIndex={selectedTabIndex}
                tabs={tabs}
                onSelectedIndexChanged={(index) => {
                  navigation.replace('security', joinObjects(location, { hash: tabs[index].hash }));
                }}
              />
            )}
          />
        )}
        content={(tabs[selectedTabIndex].render())}
      />
    </NavigationElementContainer>
  );
};

export default AdminSecurityConfigurationPage;
