import type { DayNumbers, MonthNumbers } from 'luxon';
import { DateTime } from 'luxon';

export type { MonthNumbers, WeekdayNumbers, DayNumbers } from 'luxon';

export const DATE_MIN_TIMESTAMP = -8640000000000000;
export const DATE_MAX_TIMESTAMP = 8640000000000000;

export const isValidTimestamp = (timestamp: number): boolean => Number.isSafeInteger(timestamp) && timestamp >= DATE_MIN_TIMESTAMP && timestamp <= DATE_MAX_TIMESTAMP;

export interface StoredDateObject {
  type?: DateStorageTypeKeys,
  value?: number,
}

export interface DateFieldStoreValue {
  period: PeriodicityType,
  date: number,
}

export interface DateRange {
  period?: PeriodicityType,
  from?: { type?: DateStorageTypeKeys, value?: number },
  to?: { type?: DateStorageTypeKeys, value?: number },
}

export enum DateStorageTypeKeys {
  date = 'date',
  constraint = 'constraint',
  duration = 'duration',
  last = 'last',
  next = 'next',
}

export enum PeriodicityType {
  day = 'day',
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  year = 'year',
}

// noinspection JSUnusedGlobalSymbols
export enum DurationType {
  years = 'years',
  quarters = 'quarters',
  months = 'months',
  weeks = 'weeks',
  days = 'days',
  hours = 'hours',
  minutes = 'minutes',
  seconds = 'seconds',
  milliseconds = 'milliseconds',
}

export const isValidDateFieldDateProperty = (date: unknown): date is Omit<DateFieldStoreValue, 'period'> => (
  date !== null && typeof date === 'object'
  && Number.isInteger((date as DateFieldStoreValue).date)
);

export const isDateFieldStoreValue = (date: unknown): date is DateFieldStoreValue | undefined => (
  date === undefined
  || (
    isValidDateFieldDateProperty(date)
    && Object.values(PeriodicityType).includes((date as DateFieldStoreValue).period)
  )
);

// Format a date from storage, return the corresponding timestamp in ms.
// If utc is specified, we put back local timezone to the date
// eg: Sun, 13 Jul 2014 00:00:00 GMT will return 13 Jul 2014 02:00:00 GMT +02
export const formatFromStorage = (date: number | undefined, utc?: boolean): number | undefined => {
  if (!utc || date === undefined) {
    return date;
  } else {
    const isoDate = DateTime.fromMillis(date)?.toISODate({ format: 'basic' });
    return isoDate ? DateTime.fromISO(isoDate).valueOf() : undefined;
  }
};

// Format a date for storage, return the corresponding timestamp in ms.
// If utc is specified, we remove timezone related data from the date and return the timestamp of this date in UTC
// eg: Sun, 13 Jul 2014 19:00:00 GMT +10 will return Sun, 13 Jul 2014 00:00:00 GMT
export const formatForStorage = (date: Date | undefined, utc?: boolean): number | undefined => {
  const dateTime = date ? DateTime.fromJSDate(date) : undefined;
  if (dateTime?.isValid) {
    if (utc) {
      const isoDate = dateTime.toISODate({ format: 'basic' });
      return isoDate ? DateTime.fromISO(isoDate, { zone: 'UTC' }).valueOf() : undefined;
    }
    return dateTime.valueOf();
  }
  return undefined;
};

// Offset a UTC date by the specified timezone, if no timezone is specified: return the same date
// eg: Sun, 13 Jul 2014 19:00:00 GMT offset by +2 (Europe/Paris) will return Sun, 13 Jul 2014 19:00:00 GMT +2
export const offsetFromUTC = (date: Date | undefined, tz?: string): number | undefined => {
  const dateTime = date ? DateTime.fromJSDate(date) : undefined;
  if (dateTime?.isValid) {
    if (tz) {
      const isoDate = dateTime.toISO({ includeOffset: false });
      return isoDate ? DateTime.fromISO(isoDate, { zone: tz }).valueOf() : undefined;
    }
    return dateTime.valueOf();
  }
  return undefined;
};

// Be careful, this set is updated at runtime for i18n
export const dateFormats = {
  year: 'y',
  quarter: '\'Q\'q',
  month: 'MMMM',
  week: '\'W\'W',
  dayNumberAndMonth: 'dd MMMM',
  dayNumberAndMonthAndYear: 'dd MMMM y',
  monthAndYear: 'MMMM y',
  quarterAndYear: '\'Q\'q y',
  weekOfYear: '\'W\'W kkkk',
  localSupport: 'ff',
  localDateWithAbbreviatedMonth: 'DD',
  isoDateFormat: 'yyyy-MM-dd',
  default: 'yyyy/MM/dd',
  timestamp: 'yyyy/MM/dd HH:mm:ss',
  dateAndHour: 'yyyy/MM/dd HH:mm',
  standardDateTime: 'yyyy-MM-dd HH:mm:ss',
  hourWithSeconds: 'HH:mm:ss',
} satisfies Record<string, string>;

export const updateDateFormatsLiterals = ({ w, q }: { w: string, q: string }): void => {
  dateFormats.week = `'${w}'W`;
  dateFormats.weekOfYear = `'${w}'W kkkk`;
  dateFormats.quarter = `'${q}'q`;
  dateFormats.quarterAndYear = `'${q}'q y`;
};

export const addTimeToDate = (date: Date, amount: number, type: DurationType, tz?: string): Date => DateTime.fromJSDate(date).setZone(tz).plus({ [type]: amount }).toJSDate();
export const subtractTimeFromDate = (date: Date, amount: number, type: DurationType, tz?: string): Date => (
  DateTime.fromJSDate(date).setZone(tz).minus({ [type]: amount }).toJSDate()
);

interface Periodicity {
  getFormatString: () => string,
  getStartOfPeriod: (date: Date, tz?: string) => Date,
  getEndOfPeriod: (date: Date, tz?: string) => Date,
  getPreviousDateInAmountOfPeriod: (date: Date, amount: number, tz?: string) => Date,
  getNextDateInAmountOfPeriod: (date: Date, amount: number, tz?: string) => Date,
  getDiffOfDatesOfPeriod: (date1: Date, date2: Date, tz?: string) => number,
}

export const periodicities: Record<PeriodicityType, Periodicity> = {
  [PeriodicityType.day]: {
    getFormatString: () => dateFormats.dayNumberAndMonthAndYear,
    getStartOfPeriod: (date, tz) => DateTime.fromJSDate(date).setZone(tz).startOf('day').toJSDate(),
    getEndOfPeriod: (date, tz) => DateTime.fromJSDate(date).setZone(tz).endOf('day').toJSDate(),
    getPreviousDateInAmountOfPeriod: (date, amount, tz) => DateTime.fromJSDate(date).setZone(tz).minus({ days: amount }).startOf('day').toJSDate(),
    getNextDateInAmountOfPeriod: (date, amount, tz) => DateTime.fromJSDate(date).setZone(tz).plus({ days: amount }).endOf('day').toJSDate(),
    getDiffOfDatesOfPeriod: (date1, date2, tz) => DateTime.fromJSDate(date1).setZone(tz).diff(DateTime.fromJSDate(date2).setZone(tz), 'days').days,
  },
  [PeriodicityType.week]: {
    getFormatString: () => dateFormats.weekOfYear,
    getStartOfPeriod: (date, tz?) => DateTime.fromJSDate(date).setZone(tz).startOf('week').toJSDate(),
    getEndOfPeriod: (date, tz) => DateTime.fromJSDate(date).setZone(tz).endOf('week').toJSDate(),
    getPreviousDateInAmountOfPeriod: (date, amount, tz) => DateTime.fromJSDate(date).setZone(tz).minus({ weeks: amount }).startOf('week').toJSDate(),
    getNextDateInAmountOfPeriod: (date, amount, tz) => DateTime.fromJSDate(date).setZone(tz).plus({ weeks: amount }).endOf('week').toJSDate(),
    getDiffOfDatesOfPeriod: (date1, date2, tz) => DateTime.fromJSDate(date1).setZone(tz).diff(DateTime.fromJSDate(date2).setZone(tz), 'weeks').weeks,
  },
  [PeriodicityType.month]: {
    getFormatString: () => dateFormats.monthAndYear,
    getStartOfPeriod: (date, tz) => DateTime.fromJSDate(date).setZone(tz).startOf('month').toJSDate(),
    getEndOfPeriod: (date, tz) => DateTime.fromJSDate(date).setZone(tz).endOf('month').toJSDate(),
    getPreviousDateInAmountOfPeriod: (date, amount, tz) => DateTime.fromJSDate(date).setZone(tz).minus({ months: amount }).startOf('month').toJSDate(),
    getNextDateInAmountOfPeriod: (date, amount, tz) => DateTime.fromJSDate(date).setZone(tz).plus({ months: amount }).endOf('month').toJSDate(),
    getDiffOfDatesOfPeriod: (date1, date2, tz) => DateTime.fromJSDate(date1).setZone(tz).diff(DateTime.fromJSDate(date2).setZone(tz), 'months').months,
  },
  [PeriodicityType.quarter]: {
    getFormatString: () => dateFormats.quarterAndYear,
    getStartOfPeriod: (date, tz) => DateTime.fromJSDate(date).setZone(tz).startOf('quarter').toJSDate(),
    getEndOfPeriod: (date, tz) => DateTime.fromJSDate(date).setZone(tz).endOf('quarter').toJSDate(),
    getPreviousDateInAmountOfPeriod: (date, amount, tz?) => DateTime.fromJSDate(date).setZone(tz).minus({ quarters: amount }).startOf('quarter').toJSDate(),
    getNextDateInAmountOfPeriod: (date, amount, tz) => DateTime.fromJSDate(date).setZone(tz).plus({ quarters: amount }).endOf('quarter').toJSDate(),
    getDiffOfDatesOfPeriod: (date1, date2, tz) => DateTime.fromJSDate(date1).setZone(tz).diff(DateTime.fromJSDate(date2).setZone(tz), 'quarters').quarters,
  },
  [PeriodicityType.year]: {
    getFormatString: () => dateFormats.year,
    getStartOfPeriod: (date, tz) => DateTime.fromJSDate(date).setZone(tz).startOf('year').toJSDate(),
    getEndOfPeriod: (date, tz) => DateTime.fromJSDate(date).setZone(tz).endOf('year').toJSDate(),
    getPreviousDateInAmountOfPeriod: (date, amount, tz) => DateTime.fromJSDate(date).setZone(tz).minus({ years: amount }).startOf('year').toJSDate(),
    getNextDateInAmountOfPeriod: (date, amount, tz) => DateTime.fromJSDate(date).setZone(tz).plus({ years: amount }).endOf('year').toJSDate(),
    getDiffOfDatesOfPeriod: (date1, date2, tz) => DateTime.fromJSDate(date1).setZone(tz).diff(DateTime.fromJSDate(date2).setZone(tz), 'years').years,
  },
};

export const getFormattedTextDateByPeriod = (dateInput: Date, periodicity: PeriodicityType): string => (
  DateTime.fromJSDate(dateInput).toFormat(periodicities[periodicity].getFormatString())
);

export const getDateFromString = (dateString: string, format: string): Date => (DateTime.fromFormat(dateString, format).toJSDate());

export const isDateValid = (date: Date): boolean => (DateTime.fromJSDate(date).isValid);

export const formatDisplayDate = (date: Date, dateFormat = dateFormats.default, zone?: string): string => DateTime.fromJSDate(date, { zone }).toFormat(dateFormat);

export const durationFromNow = (date: Date): { years: number, months: number, weeks: number, days: number, hours: number, minutes: number } => {
  const { years, months, weeks, days, hours, minutes } = DateTime.fromJSDate(date).diffNow(['years', 'months', 'weeks', 'days', 'hours', 'minutes']);
  return { years, months, weeks, days, hours, minutes: minutes > 0 ? Math.floor(minutes) : Math.ceil(minutes) };
};

export const getNumberOfDaysInMonth = (date: Date, tz?: string): number | undefined => DateTime.fromJSDate(date).setZone(tz).daysInMonth;
export const getDayInMonth = (date: Date, tz?: string): number => DateTime.fromJSDate(date).setZone(tz).day;
export const getWeekInYear = (date: Date, tz?: string): number => DateTime.fromJSDate(date).setZone(tz).weeksInWeekYear;

export const getCurrentMonth = (tz?: string): MonthNumbers => DateTime.local({ zone: tz }).month;
export const getIsoWeekYear = (date: Date, tz?: string): number => DateTime.fromJSDate(date).setZone(tz).weekYear;

export const getWeekNumber = (date: Date, tz?: string): number => DateTime.fromJSDate(date).setZone(tz).weekNumber;
export const getCurrentDay = (tz?: string): DayNumbers => DateTime.local({ zone: tz }).day;

export const getLocalZoneName = (): string => DateTime.local().zoneName;
