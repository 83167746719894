import type { FunctionComponent } from 'react';
import type { IFrameAllowedUrlStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import { IFrameAllowedUrl, IFrameAllowedUrl_Url } from 'yooi-modules/modules/platformConfigurationModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { isUrl } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import Typo from '../../../components/atoms/Typo';
import InlineLink from '../../../components/molecules/InlineLink';
import { TableSortDirection } from '../../../components/molecules/Table';
import BlockTitle from '../../../components/templates/BlockTitle';
import DataTable from '../../../components/templates/DataTable';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import useNewLineFocus from '../../../utils/useNewLineFocus';
import useTheme from '../../../utils/useTheme';
import StoreTextInputField from '../../_global/input/StoreTextInputField';
import useFilterAndSort, { buildStringColumnComparatorHandler } from '../../_global/useFilterAndSort';

const validateUrlFormat = (newValue: string): string | undefined => (!isUrl(newValue) ? i18n`Invalid URL format` : undefined);

const IFrameTab: FunctionComponent = () => {
  const theme = useTheme();

  const store = useStore();

  const [newLineFocus, setNewLineFocus] = useNewLineFocus();
  const { generateList, doSort, sortCriteria, forceShowId } = useFilterAndSort(
    IFrameAllowedUrl,
    store.getObject(IFrameAllowedUrl).navigateBack<IFrameAllowedUrlStoreObject>(Class_Instances),
    undefined,
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case IFrameAllowedUrl_Url:
            return buildStringColumnComparatorHandler(key, direction);
          default:
            return undefined;
        }
      },
      initial: { key: IFrameAllowedUrl_Url, direction: TableSortDirection.asc },
    }
  );

  const addUrl = () => {
    const id = store.createObject({ [Instance_Of]: IFrameAllowedUrl });
    setNewLineFocus(id);
    forceShowId(id);
  };

  return (
    <VerticalBlock>
      <BlockTitle
        title={i18n`Authorized iFrame sources`}
        subtitle={(
          <Typo fullWidth color={theme.color.text.secondary}>
            {i18n.jsx`You can specify valid sources for iFrame widget or field using the table below.\nPlease note the URL to use depends on the source. You must refer to its documentation for more information (eg: ${(
              <InlineLink key="csp" to="https://support.google.com/datastudio/answer/7450249?hl=en#embed-via-html-iframe&zippy=%2Cin-this-article">
                {i18n`Embed Google Data Studio`}
              </InlineLink>
            )})`}
          </Typo>
        )}
      />
      <DataTable
        doSort={doSort}
        sortCriteria={sortCriteria}
        columnsDefinition={[
          {
            propertyId: IFrameAllowedUrl_Url,
            name: i18n`Source`,
            sortable: true,
            focusable: true,
            cellRender: ({ id, [IFrameAllowedUrl_Url]: name }, focusOnMount) => (
              <StoreTextInputField
                initialValue={name}
                focusOnMount={focusOnMount}
                onSubmit={(newName) => {
                  store.updateObject(id, { [IFrameAllowedUrl_Url]: newName });
                }}
                inputValidation={validateUrlFormat}
                error={name && !isUrl(name) ? i18n`Invalid URL format` : undefined}
              />
            ),
          },
        ]}
        linesActions={({ id }) => [
          {
            key: 'delete',
            name: i18n`Delete`,
            icon: IconName.delete,
            onClick: () => {
              store.deleteObject(id);
            },
            danger: true,
          },
        ]}
        list={generateList().list}
        newItemIcon={IconName.add}
        newItemTitle={i18n`Add`}
        onNewItem={addUrl}
        newLineFocus={newLineFocus.current}
      />
    </VerticalBlock>
  );
};

export default IFrameTab;
