import type { ObjectStoreReadOnly } from '../../ObjectStoreType';
import type { Operation } from '../../ProtocolType';
import type { InitializedModule } from '../platformModule';
import type {
  BusinessRuleHandler,
  BusinessRuleLibraryRegisterProperty,
  BusinessRuleRegistration,
  BusinessRulesLibraryRegisterObject,
  BusinessRulesLibraryRegistrationHandler,
  DynamicBusinessRuleHandler,
  RegisterBusinessRules,
} from '../PlatformModulesTypes';

export interface BusinessRulesLibrary {
  getObjectBusinessRulesForOperation: (operation: Operation<string | string[]>) => Record<string, BusinessRuleHandler[]>,
  property: (propertyId: string) => BusinessRuleHandler[],
  onObjectUpdate: (id: string | string[], properties: Record<string, unknown> | null) => void,
  init: () => void,
}

export interface ObjectBusinessRules {
  object: Record<string, BusinessRuleHandler[]>,
  properties: Record<string, BusinessRuleHandler[]>,
  typeRegistrations: Record<string, DynamicBusinessRuleHandler[]>,
}

export const createBusinessRulesLibrary = (modules: InitializedModule[], objectStore: ObjectStoreReadOnly): BusinessRulesLibrary => {
  const objectBusinessRules: ObjectBusinessRules = { object: {}, properties: {}, typeRegistrations: {} };
  let moduleExtensions: BusinessRuleRegistration[] = [];

  const onObject: BusinessRulesLibraryRegistrationHandler['onObject'] = (type) => {
    const typeRegistrationHandler: BusinessRulesLibraryRegisterObject = {
      validate: (handler) => {
        objectBusinessRules.object[type] = [...(objectBusinessRules.object[type] ?? []), handler];
        return typeRegistrationHandler;
      },
      register: (register) => {
        objectBusinessRules.typeRegistrations[type] = [...(objectBusinessRules.typeRegistrations[type] ?? []), register];
        return typeRegistrationHandler;
      },
    };
    return typeRegistrationHandler;
  };

  const onProperty: BusinessRulesLibraryRegistrationHandler['onProperty'] = (property) => {
    const registrationHandler: BusinessRuleLibraryRegisterProperty = {
      validate: (handler) => {
        objectBusinessRules.properties[property] = [...(objectBusinessRules.properties[property] ?? []), handler];
        return registrationHandler;
      },
    };
    return registrationHandler;
  };

  return {
    getObjectBusinessRulesForOperation: (operation) => {
      let ruleKeys = ['*'];
      moduleExtensions.forEach(({ getRuleLibraryKeys }) => {
        if (getRuleLibraryKeys) {
          ruleKeys = [...ruleKeys, ...getRuleLibraryKeys(objectStore, operation)];
        }
      });
      return Object.fromEntries(ruleKeys.map((key) => [key, objectBusinessRules.object[key] ?? []]));
    },
    property: (propertyId) => objectBusinessRules.properties[propertyId] ?? [],
    onObjectUpdate: (id, properties) => {
      if (!objectStore.getObjectOrNull(id)) {
        // If we handle a newly created object, we can register dynamic business rules
        objectBusinessRules.typeRegistrations['*']?.forEach((register) => register(Array.isArray(id) ? id : [id]));
      }
      moduleExtensions.forEach(({ onUpdate }) => {
        onUpdate?.(id, properties, objectBusinessRules);
      });
    },
    init: () => {
      objectBusinessRules.object = {};
      objectBusinessRules.properties = {};
      objectBusinessRules.typeRegistrations = {};

      moduleExtensions = modules
        .map((module) => module.registerBusinessRules)
        .filter((registerBusinessRules): registerBusinessRules is RegisterBusinessRules => !!registerBusinessRules)
        .map((registerBusinessRules) => registerBusinessRules(objectStore, { onObject, onProperty }))
        .filter((value: BusinessRuleRegistration | void): value is BusinessRuleRegistration => value !== null && value !== undefined);

      // after all registrations
      moduleExtensions.forEach(({ onInit }) => {
        onInit?.(objectBusinessRules);
      });
    },
  };
};
