import {
  ConceptCollaborationFieldDimension,
  ConceptCreatedAtDimension,
  ConceptFunctionalIdDimension,
  ConceptNameDimension,
  ConceptStakeholdersGroupsDimension,
  ConceptStakeholdersRolesDimension,
  ConceptStakeholdersUsersDimension,
  ConceptUpdatedAtDimension,
  Field_ApiAlias,
  Field_IsCore,
  Field_Title,
  FieldDimension,
  FieldDimension_Field,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  ImageField,
  KinshipRelationDimension,
  RelationMultipleField,
  RelationMultipleField_ReverseField,
  RelationMultipleField_TargetType,
  RelationSingleField,
  RelationSingleField_TargetType,
  Resource_Concepts,
  UrlField,
} from '../../conceptModule/ids';
import {
  Resource,
  Resource_Image,
  Resource_Type,
  Resource_URL,
  ResourceConceptsDimension,
  ResourceImageDimension,
  ResourceType,
  ResourceTypeDimension,
  ResourceURLDimension,
} from '../ids';
import { registerModel } from '../module';

const { associate, instance } = registerModel;

instance({ typeId: UrlField, label: 'Resource_URL', extraProperties: { [Field_Title]: 'URL', [Field_ApiAlias]: 'url', [Field_IsCore]: true } });
instance({ typeId: FieldDimension, label: 'ResourceURLDimension', extraProperties: { [FieldDimension_Field]: Resource_URL } });

instance({ typeId: ImageField, label: 'Resource_Image', extraProperties: { [Field_Title]: 'Image', [Field_ApiAlias]: 'image', [Field_IsCore]: true } });
instance({ typeId: FieldDimension, label: 'ResourceImageDimension', extraProperties: { [FieldDimension_Field]: Resource_Image } });

instance({
  typeId: RelationSingleField,
  label: 'Resource_Type',
  extraProperties: {
    [Field_Title]: 'Type',
    [RelationSingleField_TargetType]: ResourceType,
    [Field_IsCore]: true,
  },
});
instance({ typeId: FieldDimension, label: 'ResourceTypeDimension', extraProperties: { [FieldDimension_Field]: Resource_Type } });
instance({
  typeId: RelationMultipleField,
  label: 'ResourceType_Resources',
  extraProperties: {
    [Field_Title]: 'Resources',
    [RelationMultipleField_ReverseField]: Resource_Type,
    [RelationMultipleField_TargetType]: Resource,
    [Field_IsCore]: true,
  },
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Resource).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptNameDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Resource).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCreatedAtDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Resource).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptUpdatedAtDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Resource).withRole(FieldDimensionTypes_Role_FieldDimension, ResourceURLDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Resource).withRole(FieldDimensionTypes_Role_FieldDimension, ResourceTypeDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Resource).withRole(FieldDimensionTypes_Role_FieldDimension, ResourceImageDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Resource).withRole(FieldDimensionTypes_Role_FieldDimension, ResourceConceptsDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Resource).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptFunctionalIdDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Resource).withRole(FieldDimensionTypes_Role_FieldDimension, KinshipRelationDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Resource).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptStakeholdersRolesDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Resource).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptStakeholdersUsersDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Resource).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptStakeholdersGroupsDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Resource).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCollaborationFieldDimension),
});

instance({
  typeId: FieldDimension,
  label: 'ResourceConceptsDimension',
  extraProperties: {
    [FieldDimension_Field]: Resource_Concepts,
  },
});
