export interface DefaultConfig {
  checked: boolean,
  conceptDefinitionId: string,
  viewDimensionId: string,
}

export interface DefaultConfigError {
  error: string,
}

export interface DefaultConfigurationFeatureResolvedDefinition {
  defaultConfigOptions: DefaultConfig | DefaultConfigError,
}

export const isDefaultConfigError = (
  defaultConfig: DefaultConfig | DefaultConfigError
): defaultConfig is DefaultConfigError => Boolean((defaultConfig as DefaultConfigError).error);
