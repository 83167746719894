import type { FunctionComponent } from 'react';
import base from '../../theme/base';
import makeStyles from '../../utils/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'row',
    height: '0.8rem',
    backgroundColor: theme.color.background.neutral.muted,
    overflow: 'hidden',
    borderRadius: base.borderRadius.extraLarge,
  },
  barLoaded: {
    display: 'flex',
    backgroundColor: theme.color.background.primary.default,
  },
}), 'progressBar');

interface ProgressBarProps {
  width: number,
  percentage: number,
}

const ProgressBar: FunctionComponent<ProgressBarProps> = ({ width, percentage }) => {
  const classes = useStyles();
  const progressWidth = Math.round(percentage * width);

  return (
    <div className={classes.container} style={{ width }}>
      <div className={classes.barLoaded} style={{ width: progressWidth }} />
    </div>
  );
};

export default ProgressBar;
