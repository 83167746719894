import classnames from 'classnames';
import type { ScaleContinuousNumeric } from 'd3-scale';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import type { FunctionComponent, ReactElement, RefCallback } from 'react';
import { useState } from 'react';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import base from '../../theme/base';
import { darken, getMostReadableColorFromBackgroundColor, isLight, lighten } from '../../theme/colorUtils';
import { FontVariant } from '../../theme/fontDefinition';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import useTheme from '../../utils/useTheme';
import useTooltipRef from '../../utils/useTooltipRef';
import Typo from '../atoms/Typo';
import { axisNameContainerHeightRem } from './internal/Axis';
import NumberAxis, { NumberAxisDirection, NumberAxisVariant } from './internal/NumberAxis';
import QuadrantDropZone from './internal/QuadrantDropZone';
import QuadrantPoint from './internal/QuadrantPoint';

enum Position {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
}

interface LabelPosition {
  label: string | undefined,
  x: number,
  y: number,
}

/**
 * Returns an array of n positions around x,y for radius r
 * @param x axis position
 * @param y axis position
 * @param r radius
 * @param n number of points
 */
const getCirclePositions = (x: number, y: number, r: number, n: number): { x: number, y: number }[] => {
  const positions = [];
  const startAngle = -(3 * Math.PI) / 4;
  const endAngle = (3 * Math.PI) / 4; // avoid left positions
  for (let i = 0; i < n; i += 1) {
    const angle = startAngle + (2 * Math.PI * i) / n;
    if (angle <= endAngle) {
      positions.push({
        x: x + r * Math.cos(angle),
        y: y + r * Math.sin(angle),
      });
    }
  }
  return positions;
};

const labelIsColliding = (existingElements: LabelPosition[], labelPosition: LabelPosition) => {
  const supposedAlignedD = 5;
  const minXDistance = 20;
  const minDistance = 15; // bigger means more collisions
  const minYDistance = 13;

  for (let i = 0; i < existingElements.length; i += 1) {
    const element = existingElements[i];
    if (element.label !== labelPosition.label) {
      const xDistance = Math.abs(labelPosition.x - element.x);
      const yDistance = Math.abs(labelPosition.y - element.y);
      const distance = Math.sqrt(xDistance ** 2 + yDistance ** 2);
      if ((xDistance < supposedAlignedD) && (yDistance < minYDistance)) {
        return true;
      } else if ((yDistance < supposedAlignedD) && (xDistance < minXDistance)) {
        return true;
      } else if (distance < minDistance) {
        return true;
      }
    }
  }
  return false;
};

const getLabelPosition = (occupiedPositions: LabelPosition[], x: number, y: number, label: string | undefined): LabelPosition => {
  const offset = 15;
  const initialPosition: LabelPosition = { label, x: x + offset, y };

  if (labelIsColliding(occupiedPositions, initialPosition)) {
    const possiblePositions = getCirclePositions(x, y, offset, 8);
    for (let i = 1; i < possiblePositions.length; i += 1) {
      const newPosition = joinObjects(possiblePositions[i], { label });
      if (!labelIsColliding(occupiedPositions, newPosition)) {
        return newPosition;
      }
    }
  }

  return initialPosition;
};

interface Point {
  data: QuadrantData,
  position: { x: number, y: number },
  labelPosition: { x: number, y: number },
}

const getPoints = (dataArray: QuadrantData[], scaleX: ScaleContinuousNumeric<number, number>, scaleY: ScaleContinuousNumeric<number, number>, pointSizePx: number): Point[] => {
  const positionedPoints = dataArray.map((d) => ({
    data: d,
    position: { x: scaleX(d.x) - pointSizePx / 2, y: scaleY(d.y) - pointSizePx / 2 },
  }));

  const occupiedPositions: LabelPosition[] = positionedPoints.map(({ position: { x, y }, data: { label } }) => ({ x, y, label: label.name }));
  return positionedPoints.map(({ data, position }) => {
    const labelPosition = getLabelPosition(occupiedPositions, position.x, position.y, data.label.name);
    occupiedPositions.push(labelPosition);

    return {
      data,
      position,
      labelPosition: { x: labelPosition.x, y: labelPosition.y },
    };
  });
};

const getArrowAnchorPosition = (point: Point, position: Position, pointSizePx: number) => {
  let { x, y } = point.labelPosition;
  if (position === Position.top || position === Position.bottom) {
    if (position === Position.top) {
      x -= pointSizePx * 0.75;
      y -= pointSizePx / 1.5;
    } else {
      x -= pointSizePx * 0.75;
      y += pointSizePx * 2;
    }
  } else if (position === Position.left || position === Position.right) {
    if (position === Position.left) {
      x -= pointSizePx * 2;
      y += pointSizePx / 2;
    } else {
      x += pointSizePx;
      y += pointSizePx / 2;
    }
  }
  return { x, y };
};

const svgRightPaddingRem = 0.8; // White space at the right of the svg

export const yMarginTopRem = 1.2; // White space at the top of the svg
export const yMarginBottomRem = 1.2; // White space at the bottom of the svg

export const innerQuadrantPaddingRem = 1; // Colored space without data point

const xAxisEstimatedTickHeightRem = 2; // Estimated height of the line + margin + text
const yAxisLabelWidthRem = 4; // Size before the axis to display axis labels
const axisMarginRem = 1.2; // White space between axis (or axis label) and the start of the color squares

export const horizontalAxisEstimatedHeightRem = axisNameContainerHeightRem + xAxisEstimatedTickHeightRem + axisMarginRem;
const verticalAxisEstimatedWidthRem = yAxisLabelWidthRem + axisNameContainerHeightRem + axisMarginRem;

const quadrantNameContainerHeightRem = 2;
const pointSizeRem = 1.2;

const useStyles = makeStyles({
  quadrantNameContainer: {
    display: 'flex',
    height: `${quadrantNameContainerHeightRem}rem`,
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
  },
  quadrantNameContainerLeft: {
    flexDirection: 'row',
  },
  quadrantNameContainerRight: {
    flexDirection: 'row-reverse',
  },
  quadrantName: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
  },
  quadrantNameTopRadius: {
    borderTopLeftRadius: base.borderRadius.medium,
    borderTopRightRadius: base.borderRadius.medium,
  },
  quadrantNameBottomRadius: {
    borderBottomLeftRadius: base.borderRadius.medium,
    borderBottomRightRadius: base.borderRadius.medium,
  },
}, 'quadrant');

export interface QuadrantData {
  key: string,
  x: number,
  y: number,
  clickable: boolean,
  color: string | undefined,
  label: { id?: string, name: string | undefined },
  draggable: boolean,
  dependingOn: string[],
}

interface QuadrantProps {
  data: QuadrantData[],
  topLeft: { name: string | undefined, color: string },
  topRight: { name: string | undefined, color: string },
  bottomLeft: { name: string | undefined, color: string },
  bottomRight: { name: string | undefined, color: string },
  xName: string | undefined,
  xMin: number,
  xMax: number,
  xCanDrag?: boolean,
  yName: string | undefined,
  yMin: number,
  yMax: number,
  yCanDrag?: boolean,
  heightPx: number,
  widthPx: number,
  onElementDrag?: (data: QuadrantData) => void,
  onElementDragEnd?: (data: QuadrantData) => void,
  onElementDrop?: (id: string, position: { x: number, y: number }) => void,
  isDragging?: boolean,
  renderTooltip?: (id: string, editMode: boolean, currentAnchor: EventTarget, handleClose: () => void) => ReactElement | null,
  isEntryEditedByOtherUser: (id: string) => boolean,
  showDependencies?: boolean,
  onDoubleClick?: (key: string) => void,
}

const Quadrant: FunctionComponent<QuadrantProps> = ({
  data,
  topLeft, topRight, bottomLeft, bottomRight,
  widthPx, heightPx,
  xName, xMin, xMax,
  yName, yMin, yMax,
  onElementDrag, onElementDragEnd, onElementDrop, isDragging = false, xCanDrag = true, yCanDrag = true,
  renderTooltip, onDoubleClick, isEntryEditedByOtherUser, showDependencies = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const topLeftTooltipRef = useTooltipRef(topLeft.name);
  const topRightTooltipRef = useTooltipRef(topRight.name);
  const bottomLeftTooltipRef = useTooltipRef(bottomLeft.name);
  const bottomRightTooltipRef = useTooltipRef(bottomRight.name);

  const [currentTooltipOpenId, setCurrentTooltipOpenId] = useState<string>();

  const yMarginTopPx = remToPx(yMarginTopRem);
  const yMarginBottomPx = remToPx(yMarginBottomRem);
  const axisNameContainerHeightPx = remToPx(axisNameContainerHeightRem);
  const axisMarginPx = remToPx(axisMarginRem);
  const yAxisLabelWidthPx = remToPx(yAxisLabelWidthRem);
  const svgRightPaddingPx = remToPx(svgRightPaddingRem);
  const verticalAxisEstimatedWidthPx = remToPx(verticalAxisEstimatedWidthRem);

  const graphWidthPx = widthPx - verticalAxisEstimatedWidthPx - svgRightPaddingPx;

  const hasTopQuadrantLabel = (topLeft.name ?? '') !== '' || (topRight.name ?? '') !== '';
  const hasBottomQuadrantLabel = (bottomLeft.name ?? '') !== '' || (bottomRight.name ?? '') !== '';
  const quadrantNameContainerHeightPx = remToPx(quadrantNameContainerHeightRem);

  const graphHeightPx = heightPx
    - yMarginTopPx
    - remToPx(horizontalAxisEstimatedHeightRem)
    - yMarginBottomPx
    - (hasTopQuadrantLabel ? quadrantNameContainerHeightPx : 0)
    - (hasBottomQuadrantLabel ? quadrantNameContainerHeightPx : 0);

  const innerQuadrantPaddingPx = remToPx(innerQuadrantPaddingRem);
  const xStart = innerQuadrantPaddingPx;
  const xEnd = graphWidthPx - innerQuadrantPaddingPx;
  const yStart = graphHeightPx - innerQuadrantPaddingPx;
  const yEnd = innerQuadrantPaddingPx;

  const scaleX = d3Scale.scaleLinear().domain([xMin, xMax]).nice().range([xStart, xEnd]);
  const scaleY = d3Scale.scaleLinear().domain([yMin, yMax]).nice().range([yStart, yEnd]);

  const pointSizePx = remToPx(pointSizeRem);

  const points = getPoints(data, scaleX, scaleY, pointSizePx);
  const pointMap = new Map(showDependencies ? points.map((point) => [point.data.key, point]) : undefined);

  const horizontalArrowAnchorPosition = [Position.left, Position.right];
  const verticalArrowAnchorPosition = [Position.bottom, Position.top];
  const arrowAnchorPosition = [...horizontalArrowAnchorPosition, ...verticalArrowAnchorPosition];
  const verticalLink = d3Shape.linkVertical().x(([x]) => x).y(([, y]) => y);
  const horizontalLink = d3Shape.linkHorizontal().x(([x]) => x).y(([, y]) => y);

  const getArrowPosition = (from: Point, to: Point): string | undefined => {
    let fromAnchor: { x: number, y: number } | undefined;
    let toAnchor: { x: number, y: number } | undefined;
    let dist = Number.MAX_SAFE_INTEGER;
    let toPosition: Position | undefined;

    arrowAnchorPosition.forEach((tmpFromPosition) => {
      const tmpFromAnchor = getArrowAnchorPosition(from, tmpFromPosition, pointSizePx);
      (verticalArrowAnchorPosition.includes(tmpFromPosition) ? verticalArrowAnchorPosition : horizontalArrowAnchorPosition)
        .forEach((tmpToPosition) => {
          const tmpToAnchor = getArrowAnchorPosition(to, tmpToPosition, pointSizePx);
          const tmpDist = Math.hypot(tmpFromAnchor.x - tmpToAnchor.x, tmpFromAnchor.y - tmpToAnchor.y);
          if (tmpDist < dist) {
            fromAnchor = tmpFromAnchor;
            toAnchor = tmpToAnchor;
            toPosition = tmpToPosition;
            dist = tmpDist;
          }
        });
    });

    if (fromAnchor?.x === undefined || fromAnchor?.y === undefined || toAnchor?.x === undefined || toAnchor?.y === undefined) {
      return undefined;
    } else if (horizontalArrowAnchorPosition.includes(toPosition as Position)) {
      return horizontalLink({ source: [fromAnchor.x, fromAnchor.y], target: [toAnchor.x, toAnchor.y] }) ?? undefined;
    } else {
      return verticalLink({ source: [fromAnchor.x, fromAnchor.y], target: [toAnchor.x, toAnchor.y] }) ?? undefined;
    }
  };

  const renderZone = (tooltipRef: RefCallback<HTMLSpanElement | null>, name: string | undefined, color: string, vertical: 'top' | 'bottom', horizontal: 'left' | 'right') => {
    const nameBackgroundColor = isLight(color) ? darken(color, 10) : lighten(color, 10);

    return (
      <>
        {
          name !== undefined && name !== ''
            ? (
              <foreignObject
                x={horizontal === 'left' ? 0 : graphWidthPx / 2}
                y={(vertical === 'top' ? 0 : graphHeightPx + (hasTopQuadrantLabel ? quadrantNameContainerHeightPx : 0))}
                width={graphWidthPx / 2}
                height={quadrantNameContainerHeightPx}
              >
                <span
                  className={classnames({
                    [classes.quadrantNameContainer]: true,
                    [classes.quadrantNameContainerLeft]: horizontal === 'left',
                    [classes.quadrantNameContainerRight]: horizontal === 'right',
                  })}
                >
                  <span
                    ref={tooltipRef}
                    className={classnames({
                      [classes.quadrantName]: true,
                      [classes.quadrantNameTopRadius]: vertical === 'top',
                      [classes.quadrantNameBottomRadius]: vertical === 'bottom',
                    })}
                    style={{ backgroundColor: nameBackgroundColor }}
                  >
                    <Typo maxLine={1} variant={FontVariant.small} color={getMostReadableColorFromBackgroundColor(nameBackgroundColor)}>{name}</Typo>
                  </span>
                </span>
              </foreignObject>
            )
            : null
        }
        <rect
          x={horizontal === 'left' ? 0 : graphWidthPx / 2}
          y={(vertical === 'top' ? 0 : graphHeightPx / 2) + (hasTopQuadrantLabel ? quadrantNameContainerHeightPx : 0)}
          width={graphWidthPx / 2}
          height={graphHeightPx / 2}
          fill={color}
        />
      </>
    );
  };

  return (
    <svg preserveAspectRatio="xMinYMin meet" viewBox={`0 0 ${widthPx} ${heightPx}`} width={widthPx} height={heightPx}>
      <g transform={`translate(${yAxisLabelWidthPx}, ${yMarginTopPx + (hasTopQuadrantLabel ? quadrantNameContainerHeightPx : 0)})`}>
        <NumberAxis
          axisEdgeMargin={innerQuadrantPaddingPx}
          variant={NumberAxisVariant.grey}
          scale={scaleY}
          direction={NumberAxisDirection.left}
          axisName={yName}
          showArrow
          ellipseLabel
        />
      </g>
      <g transform={`translate(${verticalAxisEstimatedWidthPx}, ${yMarginTopPx + graphHeightPx + axisMarginPx + axisNameContainerHeightPx + (hasTopQuadrantLabel ? quadrantNameContainerHeightPx : 0) + (hasBottomQuadrantLabel ? quadrantNameContainerHeightPx : 0)})`}>
        <NumberAxis
          axisEdgeMargin={innerQuadrantPaddingPx}
          variant={NumberAxisVariant.grey}
          scale={scaleX}
          direction={NumberAxisDirection.bottom}
          axisName={xName}
          showArrow
        />
      </g>
      {
        graphWidthPx / 2 > 0 && graphHeightPx / 2 > 0
          ? (
            <g transform={`translate(${verticalAxisEstimatedWidthPx}, ${yMarginTopPx})`}>
              {renderZone(topLeftTooltipRef, topLeft.name, topLeft.color, 'top', 'left')}
              {renderZone(topRightTooltipRef, topRight.name, topRight.color, 'top', 'right')}
              {renderZone(bottomLeftTooltipRef, bottomLeft.name, bottomLeft.color, 'bottom', 'left')}
              {renderZone(bottomRightTooltipRef, bottomRight.name, bottomRight.color, 'bottom', 'right')}
            </g>
          )
          : null
      }
      <g transform={`translate(${verticalAxisEstimatedWidthPx}, ${yMarginTopPx + (hasTopQuadrantLabel ? quadrantNameContainerHeightPx : 0)})`}>
        <defs>
          <marker id="arrow" markerWidth="10" markerHeight="10" refX="7" refY="3" orient="auto" markerUnits="strokeWidth">
            <path d="M0,0 L0,6 L9,3 z" fill={theme.color.text.secondary} />
          </marker>
        </defs>
        {
          showDependencies
            ? points
              .filter((from) => from.data.dependingOn.length > 0)
              .map((from) => (
                from.data.dependingOn
                  .map((key) => pointMap.get(key))
                  .filter(filterNullOrUndefined)
                  .map((to) => (
                    <path
                      key={`line ${from.data.key} ${to.data.key}`}
                      d={getArrowPosition(from, to)}
                      stroke={theme.color.border.dark}
                      strokeDasharray="5 4"
                      fill="none"
                      markerEnd="url(#arrow)"
                    />
                  ))
              ))
            : null
        }
        {points.map((point) => (
          <QuadrantPoint
            key={point.data.key}
            x={point.position.x}
            y={point.position.y}
            sizePx={pointSizePx}
            clickable={point.data.clickable}
            color={point.data.color}
            label={point.data.label.name}
            labelX={point.labelPosition.x}
            labelY={point.labelPosition.y}
            onElementTooltipEditModeOpen={() => setCurrentTooltipOpenId(point.data.key)}
            onElementTooltipEditModeClose={() => setCurrentTooltipOpenId(undefined)}
            getDragData={() => [['id', point.data.key]]}
            onElementDrag={onElementDrag && point.data.draggable && (xCanDrag || yCanDrag) ? () => onElementDrag(point.data) : undefined}
            onElementDragEnd={onElementDragEnd ? () => onElementDragEnd(point.data) : undefined}
            renderTooltip={renderTooltip ? (editMode, currentAnchor, handleClose) => renderTooltip(point.data.key, editMode, currentAnchor, handleClose) : undefined}
            isDragging={isDragging}
            showTooltip={currentTooltipOpenId === undefined || currentTooltipOpenId === point.data.key}
            isEditedByOtherUser={isEntryEditedByOtherUser(point.data.key)}
            onDoubleClick={onDoubleClick ? () => onDoubleClick(point.data.key) : undefined}
            svgXEnd={xEnd + innerQuadrantPaddingPx + svgRightPaddingPx}
          />
        ))}
        {
          isDragging && onElementDrop
            ? (
              <QuadrantDropZone
                dragDataFormats={['id']}
                onElementDrop={(dragData, position) => onElementDrop(dragData.id, position)}
                chartHeightPx={graphHeightPx}
                chartWidthPx={graphWidthPx}
                horizontalAxisOffsetPx={axisNameContainerHeightPx + axisMarginPx}
                verticalAxisOffsetPx={(hasBottomQuadrantLabel ? quadrantNameContainerHeightPx : 0) + axisMarginPx + axisNameContainerHeightPx}
                rectSizePx={pointSizePx}
                scaleX={scaleX}
                scaleY={scaleY}
                canDragX={xCanDrag}
                canDragY={yCanDrag}
                minX={xMin}
                maxX={xMax}
                minY={yMin}
                maxY={yMax}
              />
            )
            : null
        }
      </g>
    </svg>
  );
};

export default Quadrant;
