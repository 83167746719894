import { NavigationContext, NavigationContext_Context } from 'yooi-modules/modules/navigationModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { FrontObjectStore } from '../../store/useStore';
import i18n from '../../utils/i18n';
import { notifySuccess } from '../../utils/notify';
import { sessionStoragePrefix } from '../../utils/sessionStorageUtils';
import type { NavigationPayload } from '../../utils/useNavigation';

export const share = (
  store: FrontObjectStore,
  navigationPayload: NavigationPayload
): string => {
  let shareLink: string;
  const sessionStorageState: Record<string, string> = {};
  Object.entries(window.sessionStorage).forEach(([key, value]) => {
    if (key.startsWith(sessionStoragePrefix)) {
      sessionStorageState[key] = value;
    }
  });
  const contextId = store.createObject({
    [Instance_Of]: NavigationContext,
    [NavigationContext_Context]: {
      locationState: navigationPayload?.state,
      sessionStorageState,
    },
  });
  if (typeof navigationPayload.to !== 'string') {
    shareLink = `${window.location.origin}${navigationPayload.to.pathname}?shared=${contextId}${navigationPayload.to.hash ?? ''}`;
  } else {
    // when to is a string, assuming it's an external link
    shareLink = `${navigationPayload.to}`;
  }
  navigator.clipboard.writeText(shareLink);
  notifySuccess(i18n`Link copied`);
  return shareLink;
};
