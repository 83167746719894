import { ValidationStatus } from 'yooi-store';
import { createModule } from '../common/types/TypeModuleDsl';
import type { BusinessRuleRegistration } from '../common/types/TypeModuleDslType';
import { isConceptValid } from '../conceptModule/utils';
import { DataAsset } from '../dataAssetModule/ids';
import { Class_Instances } from '../typeModule/ids';
import * as ResourceModuleIds from './ids';
import { Resource, Resource_Image, Resource_URL, ResourceModuleId } from './ids';
import migrations from './migrations';
import { getResourcesToDataAssetsEmbeddingFieldInstanceId } from './utils';

const { initTypedModule, ...registerModelDsl } = createModule({ label: 'Resource' }, ResourceModuleIds);
export const registerModel = registerModelDsl;

const checkResourceStringFormat = (propertyId: string): BusinessRuleRegistration => () => (_, { properties }) => {
  if (properties === undefined) {
    return undefined;
  }
  if (properties?.[propertyId] === null) {
    return { rule: 'field.resource.allowDeletion', status: ValidationStatus.ACCEPTED };
  }
  if (properties?.[propertyId]) {
    if (typeof properties[propertyId] === 'string') {
      return { rule: 'field.resource.allowStringFormat', status: ValidationStatus.ACCEPTED };
    } else {
      return { rule: 'field.resource.InvalidStringFormat', status: ValidationStatus.REJECTED };
    }
  }
  return undefined;
};

const cleanResourcesOnDataAssetDeletion: BusinessRuleRegistration = (store) => (_, { id, properties }) => {
  // only on data asset deletion
  if (properties === null) {
    const dataAssetInstance = store.getObjectOrNull(id);
    if (dataAssetInstance) {
      return {
        rule: 'concept.resource.removeAssociatedResourcesToDeletedDataAsset',
        status: ValidationStatus.ACCEPTED,
        generateSystemEvent: ({ deleteObject }) => {
          dataAssetInstance
            .navigateBack(getResourcesToDataAssetsEmbeddingFieldInstanceId(store))
            .forEach((resourceInstance) => deleteObject(resourceInstance.id));
        },
      };
    }
  }
  return undefined;
};

const cannotHaveMultipleResourcesWithTheSameUrl = (propertyId: string): BusinessRuleRegistration => (store) => (_, { properties }) => {
  if (properties?.[propertyId]) {
    const existingResource = store.getObject(Resource)
      .navigateBack(Class_Instances)
      .filter(({ id: conceptId }) => isConceptValid(store, conceptId))
      .find((resource) => (resource[Resource_URL] as string)?.toLowerCase() === (properties[propertyId] as string)?.toLowerCase());
    if (existingResource) {
      return {
        rule: 'concept.resource.cannotHaveMultipleResourcesWithTheSameUrl',
        status: ValidationStatus.REJECTED,
      };
    }
  }
  return undefined;
};

export const initModule = initTypedModule(() => ({
  id: ResourceModuleId,
  migrations,
  registerBusinessRules: (objectStore, registerBusinessRules) => {
    const { onProperty, onObject } = registerBusinessRules;
    onObject(DataAsset).validate(cleanResourcesOnDataAssetDeletion(objectStore));
    onProperty(Resource_URL).validate(cannotHaveMultipleResourcesWithTheSameUrl(Resource_URL)(objectStore));
    onProperty(Resource_URL).validate(checkResourceStringFormat(Resource_URL)(objectStore));
    onProperty(Resource_Image).validate(checkResourceStringFormat(Resource_Image)(objectStore));
  },
}));

export const testables = {
  cleanResourcesOnDataAssetDeletion,
  cannotHaveMultipleResourcesWithTheSameUrl,
};
