import ndjson from 'fetch-ndjson';
import type { FunctionComponent } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { v4 as uuid } from 'uuid';
import type { ProcessedEvent } from 'yooi-store';
import { joinObjects, newError } from 'yooi-utils';
import Typo from '../../../components/atoms/Typo';
import Chooser from '../../../components/molecules/Chooser';
import Loading from '../../../components/molecules/Loading';
import { NavigationTitlePathElements } from '../../../components/molecules/NavigationTitle';
import BaseLayout from '../../../components/templates/BaseLayout';
import BlockContent from '../../../components/templates/BlockContent';
import Header from '../../../components/templates/Header';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import { doFetch } from '../../../utils/fetchUtils';
import i18n from '../../../utils/i18n';
import useForceUpdate from '../../../utils/useForceUpdate';
import { NavigationElementContainer } from '../../../utils/useNavigation';
import HeaderStatic from '../../_global/HeaderStatic';
import TopBar from '../../_global/topBar/TopBar';
import { formatEvent } from './_global/explorerUtils';
import { useExplorerHint } from './_global/GetHintContextProvider';
import ValueRenderer from './_global/ValueRenderer';

interface EventListPageProps {
  fromEventId: string,
  toEventId: string,
}

const EventListPage: FunctionComponent<EventListPageProps> = ({ fromEventId, toEventId }) => {
  const store = useStore();
  const explorerHint = useExplorerHint();

  const [loading, setLoading] = useState(true);

  const requestIdRef = useRef<string | undefined>(undefined);
  const eventsRef = useRef<ProcessedEvent[]>([]);
  const forceUpdate = useForceUpdate();
  const doForceUpdate = useDebouncedCallback(forceUpdate, 150, { maxWait: 300 });

  const [error, setError] = useState<unknown>();

  const [streamIndex, setStreamIndex] = useState<number>(1);
  const streams = [{ key: 'processed', name: 'processed' }, { key: 'minified', name: 'minified' }];
  const currentStreamIndex = streamIndex < 0 || streamIndex >= streams.length ? 0 : streamIndex;
  const currentStream = streams[currentStreamIndex].key;

  useEffect(() => {
    const getEvent = async () => {
      const requestId = uuid();
      requestIdRef.current = requestId;

      try {
        eventsRef.current = [];
        setLoading(true);

        const { response } = await doFetch('/store/explorer/event', {
          method: 'POST',
          json: { fromEventId, toEventId, stream: currentStream },
          timeout: 15 * 60 * 1000,
          maxIterations: 2,
        });

        if (response.status !== 200) {
          setError(newError('Got response error code while querying /store/explorer/event', { status: response.status }));
        } else if (response.body && response.status === 200) {
          const asyncGenerator = ndjson(response.body.getReader());

          let isDone = false;
          do {
            const next = await asyncGenerator.next();
            isDone = next.done ?? false;

            if (next.value && requestIdRef.current === requestId) {
              eventsRef.current.push(next.value);
              doForceUpdate();
            }
          } while (!isDone);
        }
      } catch (e) {
        if (requestIdRef.current === requestId) {
          setError(e);
        }
      } finally {
        if (requestIdRef.current === requestId) {
          setLoading(false);
        }
      }
    };
    getEvent();
  }, [doForceUpdate, fromEventId, toEventId, currentStream]);

  // Got an error while loading ? break the view
  if (error) {
    // eslint-disable-next-line @typescript-eslint/only-throw-error
    throw error;
  }

  const title = fromEventId === toEventId ? fromEventId : `${fromEventId} -> ${toEventId}`;
  return (
    <NavigationElementContainer
      element={{
        key: `explorer:${fromEventId === toEventId ? fromEventId : `${fromEventId}:${toEventId}`}`,
        name: title,
        element: NavigationTitlePathElements.explorerInstance,
        path: `/settings/explorer/event/${fromEventId}${fromEventId === toEventId ? '' : `/${toEventId}`}`,
      }}
    >
      <BaseLayout
        topBar={(<TopBar />)}
        header={(
          <Header
            firstLine={(
              <HeaderStatic
                text={title}
                actions={[
                  (
                    <Chooser
                      key="stream"
                      selectedIndexes={[currentStreamIndex]}
                      actions={streams}
                      onClick={(index) => setStreamIndex(index)}
                    />
                  ),
                ]}
              />
            )}
          />
        )}
        content={(
          <VerticalBlock>
            {eventsRef.current.length === 0 && (
              <BlockContent padded>
                <Typo>{i18n`No events found`}</Typo>
              </BlockContent>
            )}
            {eventsRef.current.map((event, index, events) => (
              <BlockContent key={event.eventId} padded>
                <ValueRenderer value={joinObjects(formatEvent(store, explorerHint, event), { collapseByDefault: index < (events.length - 5) })} />
              </BlockContent>
            ))}
            {loading && (
              <BlockContent padded>
                <Loading />
              </BlockContent>
            )}
          </VerticalBlock>
        )}
      />
    </NavigationElementContainer>
  );
};

export default EventListPage;
