import classnames from 'classnames';
import { equals } from 'ramda';
import type { FunctionComponent, ReactElement, Ref } from 'react';
import { Fragment, useCallback, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import useResizeObserver from 'use-resize-observer';
import { buildComparatorChain, compareProperty, compareString, comparing, joinObjects, pushUndefinedToEnd } from 'yooi-utils';
import Icon, { IconColorVariant, IconName } from '../../../../../components/atoms/Icon';
import Typo, { TypoVariant } from '../../../../../components/atoms/Typo';
import type { ProgressFieldData } from '../../../../../components/charts/TimelineEntry';
import { heightInRem as entryHeightRem } from '../../../../../components/charts/TimelineEntry';
import BlockContent from '../../../../../components/templates/BlockContent';
import base from '../../../../../theme/base';
import { buildMargins, Spacing, spacingRem } from '../../../../../theme/spacingDefinition';
import makeStyles from '../../../../../utils/makeStyles';
import useTheme from '../../../../../utils/useTheme';
import ActivityIndicator from '../../../multiplayer/ActivityIndicator';
import { dragType } from '../swimlane/DraggableItem';
import ConceptItem from './ConceptItem';
import DraggableConceptItem from './DraggableConceptItem';

const entryMarginTopBottomRem = 0.8; // because y: spacings.xs
const entryTotalHeightRem = entryHeightRem + entryMarginTopBottomRem;

const useStyles = makeStyles((theme) => ({
  backlog: {
    borderColor: theme.color.border.default,
    borderRadius: base.borderRadius.medium,
    borderStyle: 'dashed',
    borderWidth: '0.2rem',
  },
  backlogContainer: {
    // Relative is for positioning the tooltip, and the flex is to handle the margin-collapsing issue
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
  },
  backlogContainerDrag: {
    borderColor: theme.color.border.info,
  },
  multiplayerIndicatorContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  multiplayerEntryLine: {
    height: `${entryTotalHeightRem}rem`,
    paddingTop: spacingRem.xs,
  },
  titleContainer: {
    display: 'flex',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    margin: spacingRem.xl,
  },
  icon: {
    display: 'flex',
    marginLeft: spacingRem.s,
  },
  entriesContainer: joinObjects(
    {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    buildMargins({ x: Spacing.xl, bottom: Spacing.xxl })
  ),
}), 'conceptBacklog');

interface Data {
  id: string,
  label: { id?: string, name: string | undefined },
  rightText?: string | undefined,
  color: string | undefined,
  progress?: ProgressFieldData | undefined,
  isDraggable?: boolean,
}

interface ConceptBacklogProps {
  title: string,
  list: Data[],
  onElementDrag?: ({ id }: { id: string }) => void,
  onElementDragEnd?: ({ id }: { id: string }) => void,
  onElementDrop?: (id: string) => void,
  dragLabelFieldId?: string,
  renderTooltip: (conceptId: string, editMode: boolean, currentAnchor: HTMLElement, handleClose: () => void, isChild?: boolean) => ReactElement | null,
  multiplayerPropertyIds: string[],
  readOnly?: boolean,
  isSwimlane?: boolean,
  conceptDefinitionId?: string,
  fieldId?: string,
  isOverBacklog?: boolean,
  dropRef?: Ref<HTMLDivElement>,
  iconText?: string,
  fullWidth?: boolean,
}

const ConceptBacklog: FunctionComponent<ConceptBacklogProps> = ({
  title,
  list,
  onElementDrop,
  onElementDrag,
  onElementDragEnd,
  dragLabelFieldId,
  renderTooltip,
  multiplayerPropertyIds,
  readOnly = false,
  isSwimlane,
  conceptDefinitionId,
  fieldId,
  isOverBacklog,
  dropRef,
  iconText,
  fullWidth = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const titleRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<Record<string, HTMLDivElement>>({});

  const [draggingOverBackLog, setDraggingOverBackLog] = useState(false);

  const computeMultiplayerLines = useCallback(() => Object.entries(itemRefs.current)
    .filter(([, node]) => node)
    .reduce((accumulator: Record<string, string[]>, [id, node]) => {
      const { offsetTop } = node;
      if (!offsetTop) {
        return accumulator;
      }
      return joinObjects(
        accumulator,
        { [offsetTop]: [...(accumulator[offsetTop] ?? []), id] }
      );
    }, {}), []);

  const [multiplayerLines, setMultiplayerLines] = useState<Record<string, string[]>>({});

  const updateMultiplayerLines = useDebouncedCallback(() => {
    const useCaseLines = computeMultiplayerLines();
    setMultiplayerLines((current) => (equals(current, useCaseLines) ? current : useCaseLines));
  }, 300);

  const { ref: contentRef } = useResizeObserver({
    onResize: () => {
      updateMultiplayerLines();
    },
  });

  return (
    <BlockContent
      fullWidth={fullWidth}
      padded={!fullWidth}
      action={multiplayerPropertyIds && (
        <div className={classes.multiplayerIndicatorContainer}>
          <div style={{ height: titleRef.current?.clientHeight }} />
          {Object.entries(multiplayerLines).sort(([a], [b]) => Number(a) - Number(b)).map(([position, ids]) => (
            <div key={position} className={classes.multiplayerEntryLine}>
              <ActivityIndicator propertyIds={multiplayerPropertyIds} instanceIds={ids} />
            </div>
          ))}
        </div>
      )}
    >
      <div
        className={classnames({
          [classes.backlogContainer]: true,
          [classes.backlogContainerDrag]: isOverBacklog || draggingOverBackLog,
        })}
        ref={dropRef}
        onDrop={onElementDrop ? (event) => {
          onElementDrop(event.dataTransfer.getData('id'));
          setDraggingOverBackLog(false);
        } : undefined}
        onDragOver={onElementDrop ? (event) => {
          // By default, data/elements cannot be dropped in other elements. To allow a drop, we must prevent the default handling of the element.
          event.preventDefault();
          setDraggingOverBackLog(true);
        } : undefined}
        onDragLeave={onElementDrop ? () => {
          setDraggingOverBackLog(false);
        } : undefined}
      >
        <div className={classes.backlog}>
          <div ref={titleRef} className={classes.titleContainer}>
            <div className={classes.title}>
              <Typo
                color={theme.color.text.secondary}
                variant={TypoVariant.blockInlineTitle}
              >
                {title}
              </Typo>
              {iconText && (
                <div className={classes.icon}>
                  <Icon
                    name={IconName.info}
                    colorVariant={IconColorVariant.info}
                    tooltip={iconText}
                  />
                </div>
              )}
            </div>
          </div>
          <div ref={contentRef} className={classes.entriesContainer}>
            {list
              .sort(buildComparatorChain([
                compareProperty('label', compareProperty('id', comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString))),
                compareProperty('label', compareProperty('name', comparing<string | undefined>(pushUndefinedToEnd).thenComparing(compareString))),
              ]))
              .map((instance) => {
                const conceptRefFunc: Ref<HTMLDivElement> = (node) => {
                  if (node) {
                    itemRefs.current[instance.id] = node;
                    updateMultiplayerLines();
                  }
                };
                return (
                  <Fragment key={instance.id}>
                    {isSwimlane ? (
                      <DraggableConceptItem
                        instance={instance}
                        renderTooltip={renderTooltip}
                        readOnly={readOnly}
                        isDraggable={!!instance.isDraggable}
                        multiplayerPropertyIds={multiplayerPropertyIds}
                        conceptRefFunc={conceptRefFunc}
                        rectColor={instance.color}
                        dragKey={`${dragType.concept}-${conceptDefinitionId}-${fieldId}`}
                        onElementDrag={onElementDrag}
                        onElementDragEnd={onElementDragEnd}
                      />
                    ) : (
                      <ConceptItem
                        instance={instance}
                        onElementDrag={onElementDrag}
                        onElementDragEnd={onElementDragEnd}
                        dragLabelFieldId={dragLabelFieldId}
                        renderTooltip={renderTooltip}
                        readOnly={readOnly}
                        multiplayerPropertyIds={multiplayerPropertyIds}
                        conceptRefFunc={conceptRefFunc}
                        rectColor={instance.color}
                      />
                    )}
                  </Fragment>
                );
              })}
          </div>
        </div>
      </div>
    </BlockContent>
  );
};

export default ConceptBacklog;
