import type { EventOrigin, ObjectStoreReadOnly, ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import { filterNullOrUndefined, newError } from 'yooi-utils';
import type { MultipleParameterDefinition, MultipleParameterValue, ParametersMapping, PathStep, SingleParameterDefinition, SingleParameterValue } from '../conceptModule';
import {
  createValuePathResolver,
  getConceptInstanceProxy,
  getFieldUtilsHandler,
  getInstanceLabel,
  getPathReturnedConceptDefinitionId,
  handleProxyArrayProps,
  InstanceReferenceType,
  isMultiDimensionResolution,
  isMultiFieldResolution,
  isMultiValueResolution,
  isSingleDimensionResolution,
  isSingleFieldResolution,
  isSingleValueResolution,
  isValidValuePathResolution,
  PathStepType,
} from '../conceptModule';
import { formatOrUndef } from '../conceptModule/common/commonFieldUtils';
import { isInstanceOf } from '../typeModule';
import { AutomationAction_Query, AutomationAction_Rule, AutomationActionEmail, AutomationRule_Parameters, AutomationRule_Trigger } from './ids';
import type { AutomationActionEmailStoreObject, AutomationActionStoreObject, AutomationRuleStoreObject } from './modelTypes';
import type { ConstantActionQuery } from './triggers/triggerHandler';
import { getTriggerHandler } from './triggers/triggerHandler';

export const RULE_FOR_EACH_PARAMETER = 'RULE_FOR_EACH_PARAMETER';
export const INSTANCE_OPERATION_CURRENT_PARAMETER = 'INSTANCE_OPERATION_CURRENT_PARAMETER';

export interface PathActionQuery {
  type: 'path',
  path: PathStep[],
}

export const getForEachParameters = (
  objectStore: ObjectStoreReadOnly,
  ruleId: string,
  triggerParameters?: ParametersMapping<SingleParameterValue | MultipleParameterValue>
): ParametersMapping[] => {
  const rule = objectStore.getObject<AutomationRuleStoreObject>(ruleId);
  const ruleParameters = rule[AutomationRule_Parameters] as Record<string, PathStep[]> | undefined;

  const getForEachParametersValues = (): StoreObject<string>[] => {
    const forEachParametersValues: StoreObject<string>[] = [];
    if (ruleParameters && Object.keys(ruleParameters).length) {
      const pathResolver = createValuePathResolver(objectStore as ObjectStoreWithTimeseries, triggerParameters ?? {});
      const ruleParametersValues: PathStep[][] = Object.values(ruleParameters);
      for (let x = 0; x < ruleParametersValues.length; x += 1) {
        const resolution = pathResolver.resolvePathValue<StoreObject>(ruleParametersValues[x]);
        if (resolution && isValidValuePathResolution(resolution)) {
          if (isMultiValueResolution(resolution) && resolution.values) {
            forEachParametersValues.push(...resolution.values);
          } else if (isSingleValueResolution(resolution) && resolution.value) {
            forEachParametersValues.push(resolution.value);
          }
        }
      }
    }
    return [...new Map(forEachParametersValues.map((forEachParam) => [forEachParam.id, forEachParam])).values()];
  };

  const forEachParameters: ParametersMapping[] = [];
  if (ruleParameters && Object.keys(ruleParameters).length) {
    const resolvedParameterValues = getForEachParametersValues();
    if (resolvedParameterValues.length > 0) {
      forEachParameters.push(...resolvedParameterValues.map(({ id }): ParametersMapping => ({ [RULE_FOR_EACH_PARAMETER]: { type: 'single' as const, id } })));
    }
  } else {
    return [{}];
  }

  return forEachParameters;
};

export type TemplateResolutionActionQuery = ({ id: string } & (PathActionQuery | ConstantActionQuery))[];

export const escapeQueryLabel = (name: string): string => name.trim().match(/[A-Z]{2,}(?=[A-Z][a-z]+|\b)|[A-Z]?[a-z]+|[A-Z]|[0-9]+/g)?.map((segment) => segment.toLowerCase())
  .join('_') ?? '';

export const getTriggerOutputParameterDefinitions = (store: ObjectStoreReadOnly, ruleId: string): (SingleParameterDefinition | MultipleParameterDefinition)[] => {
  const rule = store.getObject<AutomationRuleStoreObject>(ruleId);
  const trigger = rule[AutomationRule_Trigger];
  const parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[] = [];

  if (trigger && trigger.type) {
    parameterDefinitions.push(...Object.values(getTriggerHandler(trigger).parameterDefinitions).filter(filterNullOrUndefined));
  }

  return parameterDefinitions;
};

export const getForEachOutputParameterDefinitions = (store: ObjectStoreReadOnly, ruleId: string): (SingleParameterDefinition | MultipleParameterDefinition)[] => {
  const rule = store.getObject<AutomationRuleStoreObject>(ruleId);
  const trigger = rule[AutomationRule_Trigger];
  const ruleParameters = rule[AutomationRule_Parameters] as Record<string, PathStep[]> | undefined;
  const parameterDefinitions: (SingleParameterDefinition | MultipleParameterDefinition)[] = [];

  if (trigger && trigger.type) {
    parameterDefinitions.push(...Object.values(getTriggerHandler(trigger).parameterDefinitions).filter(filterNullOrUndefined));
  }

  if (ruleParameters) {
    Object.entries(ruleParameters).forEach(([key, path]) => {
      const typeId = path ? getPathReturnedConceptDefinitionId(store as ObjectStoreWithTimeseries, path) : undefined;
      if (typeId) {
        parameterDefinitions.push({ id: RULE_FOR_EACH_PARAMETER, typeId, label: key, type: 'parameter' });
      }
    });
  }

  return parameterDefinitions;
};

export const getTemplateResolutionActionQuery = (store: ObjectStoreReadOnly, actionId: string): TemplateResolutionActionQuery => {
  const action = store.getObjectOrNull<AutomationActionStoreObject>(actionId);
  const rule = action?.navigate<AutomationRuleStoreObject>(AutomationAction_Rule);
  const trigger = rule?.[AutomationRule_Trigger];
  if (!action || !rule || !trigger) {
    return [];
  }

  const query: TemplateResolutionActionQuery = [];
  const triggerHandler = getTriggerHandler(trigger);

  getForEachOutputParameterDefinitions(store, rule.id).forEach((parameterDefinition) => {
    query.push({
      id: escapeQueryLabel(parameterDefinition.label),
      type: 'path',
      path: [
        { type: PathStepType.dimension, conceptDefinitionId: parameterDefinition.typeId },
        parameterDefinition.type !== 'parameterList'
          ? { type: PathStepType.mapping, mapping: { id: parameterDefinition.id, type: InstanceReferenceType.parameter } }
          : { type: PathStepType.multipleMapping, id: parameterDefinition.id },
      ],
    });
  });

  Object.entries(action[AutomationAction_Query] ?? {}).forEach(([key, path]) => query.push({ id: key, type: 'path', path }));

  if (isInstanceOf<AutomationActionEmailStoreObject>(action, AutomationActionEmail)) {
    Object.entries(triggerHandler.mailActionQuery).forEach(([id, { type, getValue }]) => query.push({ id, type, getValue }));
  }

  return query;
};

export const getAutomationTemplateContext = (
  store: ObjectStoreWithTimeseries,
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
  actionQuery: TemplateResolutionActionQuery,
  eventOrigin: EventOrigin | undefined
): Record<string, unknown> => Object.fromEntries(actionQuery.map(({ id, ...query }): [string, object | string] | undefined => {
  if (query.type === 'path') {
    const { path } = query;
    const pathResolver = createValuePathResolver(store, parametersMapping);
    const pathField = pathResolver.resolvePathField(path);
    if (pathField instanceof Error) {
      throw newError('Impossible to evaluate path', { query, pathField });
    } else if (!pathField) {
      const pathDimension = pathResolver.resolvePathDimension(path);
      if (!pathDimension) {
        return undefined;
      } else if (pathDimension instanceof Error) {
        throw newError('Impossible to evaluate path', { query, pathDimension });
      } else if (isSingleDimensionResolution(pathDimension)) {
        if (pathDimension.instance) {
          return [id, getConceptInstanceProxy(store, pathDimension.instance.id)];
        } else {
          return undefined;
        }
      } else if (isMultiDimensionResolution(pathDimension)) {
        return [id, new Proxy({}, {
          get(_, prop) {
            if (prop === 'toString' || prop === Symbol.toStringTag) {
              return () => pathDimension.instances.filter(filterNullOrUndefined)
                .map((instance) => formatOrUndef(getInstanceLabel(store, instance)))
                .join(', ');
            } else {
              return handleProxyArrayProps(
                prop,
                pathDimension.instances.filter(filterNullOrUndefined),
                (instance) => getConceptInstanceProxy(store, instance.id)
              );
            }
          },
        })];
      } else {
        return undefined;
      }
    } else if (isSingleFieldResolution(pathField)) {
      const fieldUtil = getFieldUtilsHandler(store, pathField.fieldId);
      const fieldObject = fieldUtil.getValueProxy?.(pathField.dimensionsMapping ?? {});
      if (!fieldObject) {
        return undefined;
      } else {
        return [id, fieldObject];
      }
    } else if (isMultiFieldResolution(pathField)) {
      return [id, new Proxy({}, {
        get(_, prop) {
          const { getValueProxy, getValueAsText } = getFieldUtilsHandler(store, pathField.fieldId);
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => {
              if (!getValueAsText) {
                return 'Na';
              } else {
                return pathField.resolutions
                  .map(({ dimensionsMapping }) => getValueAsText(dimensionsMapping ?? {}))
                  .join(', ');
              }
            };
          } else if (getValueProxy) {
            return handleProxyArrayProps(
              prop,
              pathField.resolutions,
              ({ dimensionsMapping }) => getValueProxy(dimensionsMapping ?? {})
            );
          } else {
            return undefined;
          }
        },
      })];
    } else {
      return undefined;
    }
  } else {
    const value = query.getValue({ store, eventOrigin, parametersMapping });
    return value ? [id, value] : undefined;
  }
}).filter((o): o is [string, object] => Boolean(o)));
