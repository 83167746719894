import { isFiniteNumber, isNumber, isObject, isValidTimestamp } from 'yooi-utils';
import type { Operation, TimeseriesOperation } from './ProtocolType';

type CreateEventDecoder = <Id extends string | string[] = string[]> (onObject: (
  id: string | string[],
  properties?: Record<string, unknown> | null,
  timeseries?: Record<string, TimeseriesOperation>
) => void) => (event: Operation<Id>[]) => void;

export const createEventDecoder: CreateEventDecoder = (onObject) => (event) => {
  if (event) {
    event.forEach(({ id, properties, timeseries }) => onObject(id, properties, timeseries));
  }
};

export const encodeId = (id: string | string[]): string[] => (Array.isArray(id) ? id : [id]);

const encodeEvent = (id: string | string[], properties?: Record<string, unknown> | null, timeseries?: Record<string, TimeseriesOperation>): Operation => ({
  id: encodeId(id),
  properties,
  timeseries,
});

export interface Encoder {
  getEncodedEvent: () => Operation[],
  encodeObjectUpdate: (
    id: string | string[],
    properties?: Record<string, unknown> | null,
    timeseries?: Record<string, TimeseriesOperation>,
    systemEvent?: boolean,
  ) => Encoder,
}

export const createEventEncoder = (): Encoder => {
  const event: Operation[] = [];
  const encoder: Encoder = {
    getEncodedEvent: () => event,
    encodeObjectUpdate: (id, properties, timeseries, systemEvent) => {
      event.push({
        ...encodeEvent(id, properties, timeseries),
        systemEvent,
      });
      return encoder;
    },
  };
  return encoder;
};

export const isTimeseriesOperation = (maybe: unknown): maybe is TimeseriesOperation => {
  try {
    if (!isObject(maybe)) {
      return false;
    }

    if (Object.keys(maybe).filter((key) => !['values', 'truncate'].includes(key)).length > 0) {
      return false;
    }

    const { values, truncate } = maybe as Record<'values' | 'truncate', unknown>;
    if (values !== undefined) {
      if (!isObject(values)) {
        return false;
      }
      if (Object.keys(values).some((key) => !isFiniteNumber(key) || !isValidTimestamp(Number(key)))) {
        return false;
      }
    }
    if (truncate !== undefined) {
      if (truncate === null) {
        return false;
      } else if (typeof truncate === 'boolean') {
        // Ok
      } else if (isObject(truncate)) {
        if (Object.keys(truncate).filter((key) => !['from', 'to'].includes(key)).length > 0) {
          return false;
        }
        const { from, to } = truncate as Record<'from' | 'to', unknown>;
        if (from !== undefined && (!isNumber(from) || !isValidTimestamp(from))) {
          return false;
        } else if (to !== undefined && (!isNumber(to) || !isValidTimestamp(to))) {
          return false;
        }
        // Ok
      } else {
        return false;
      }
    }
    if (truncate !== undefined) {
      if (truncate === null) {
        return false;
      } else if (typeof truncate === 'boolean') {
        // Ok
      } else if (isObject(truncate)) {
        if (Object.keys(truncate).filter((key) => !['from', 'to'].includes(key)).length > 0) {
          return false;
        }
        const { from, to } = truncate as Record<'from' | 'to', unknown>;
        if (from !== undefined && !isNumber(from)) {
          return false;
        } else if (to !== undefined && !isNumber(to)) {
          return false;
        }
        // Ok
      } else {
        return false;
      }
    }
    return true;
  } catch (_) {
    return false;
  }
};
