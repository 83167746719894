import type { FunctionComponent, ReactElement } from 'react';
import { canCopyConcept, getConceptUrl, isEmbeddedAsIntegrationOnly } from 'yooi-modules/modules/conceptModule';
import { ConceptRole } from 'yooi-modules/modules/conceptModule/ids';
import { DataAsset } from 'yooi-modules/modules/dataAssetModule/ids';
import type { StoreObject } from 'yooi-store';
import type { ButtonVariant } from '../../../../components/atoms/Button';
import { IconName } from '../../../../components/atoms/Icon';
import type { TableSortDirection } from '../../../../components/molecules/Table';
import type { ColumnDefinition, GroupEntry, ItemEntry } from '../../../../components/templates/DataTable';
import DataTable from '../../../../components/templates/DataTable';
import type { Pagination } from '../../../../components/templates/PageSelector';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import useNavigation from '../../../../utils/useNavigation';
import useNewLineFocus, { useFocusNewLineNotify } from '../../../../utils/useNewLineFocus';
import { duplicateConcept } from '../../conceptUtils';
import ActivityIndicator from '../../multiplayer/ActivityIndicator';
import type { NavigationFilter } from '../../navigationUtils';
import { getNavigationPayload } from '../../navigationUtils';
import { share } from '../../shareUtils';
import useConceptDeleteModal from '../../useConceptDeleteModal';
import useAdminMenuItems from '../../userAdmin/useAdminMenuItems';

interface ConceptTableProps {
  conceptDefinitionId: string,
  columnsDefinition: ColumnDefinition<StoreObject>[],
  list: (ItemEntry<StoreObject> | GroupEntry)[],
  newItemTitle?: string,
  newItemIcon?: IconName,
  newItemButtonVariant?: ButtonVariant,
  onNewItem?: () => (string | undefined),
  doSort: (propertyId: string) => void,
  sortCriteria?: { key: string, direction: TableSortDirection },
  forceFollowingIds: (fromId: string, toId: string) => void,
  forceShowId: (forceId: string) => void,
  inlineCreation?: { render: ReactElement | null },
  onUnlink?: (lineId: string) => void,
  readOnly?: boolean,
  lineContext?: (item: StoreObject) => string[],
  pagination?: Pagination,
  navigationFilters?: NavigationFilter,
  linesActionsHeaderRenderer?: () => ReactElement | null,
  minColumnWidthRem?: number | undefined,
  withoutActivityIndicator?: boolean,
}

const ConceptTable: FunctionComponent<ConceptTableProps> = ({
  conceptDefinitionId,
  columnsDefinition,
  list,
  newItemTitle,
  newItemIcon,
  newItemButtonVariant,
  onNewItem,
  doSort,
  sortCriteria,
  forceFollowingIds,
  forceShowId,
  inlineCreation,
  onUnlink,
  readOnly = false,
  lineContext,
  pagination,
  navigationFilters,
  linesActionsHeaderRenderer,
  minColumnWidthRem,
  withoutActivityIndicator = false,
}) => {
  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();

  const navigation = useNavigation<NavigationFilter>();

  const focusNewLineNotify = useFocusNewLineNotify();
  const [newLineFocus] = useNewLineFocus();

  const [doDelete, deleteModal] = useConceptDeleteModal(false);

  const { passwordModal, buildAdminMenuItems } = useAdminMenuItems(store, loggedUserId);

  const linesActions = ({ id }: StoreObject) => {
    if (readOnly) {
      return [];
    }

    return [
      {
        key: 'open',
        icon: IconName.output,
        name: i18n`Open`,
        onClick: () => navigation.push(id, { pathname: getConceptUrl(store, id), navigationFilters }),
      },
      {
        key: 'share',
        icon: IconName.link,
        name: i18n`Copy link`,
        onClick: () => share(store, getNavigationPayload(navigation, id, getConceptUrl(store, id), navigationFilters)),
      },
      {
        key: 'duplicate',
        icon: IconName.content_copy_outline,
        name: i18n`Duplicate`,
        hidden: !canCopyConcept(store, aclHandler, id),
        onClick: () => duplicateConcept(store, aclHandler, id, (newId) => forceFollowingIds(id, newId)),
      },
      {
        key: 'duplicate_with_child',
        icon: IconName.content_copy_outline,
        name: i18n`Duplicate with its child elements`,
        hidden: conceptDefinitionId === DataAsset || !canCopyConcept(store, aclHandler, id),
        onClick: () => duplicateConcept(store, aclHandler, id, (newId) => forceFollowingIds(id, newId), true),
      },
      ...buildAdminMenuItems(id),
      {
        key: 'remove',
        name: i18n`Remove`,
        icon: IconName.delete,
        danger: true,
        hidden: !onUnlink,
        onClick: () => onUnlink?.(id),
      },
      {
        key: 'delete',
        icon: IconName.delete,
        name: i18n`Delete`,
        hidden: conceptDefinitionId === ConceptRole || isEmbeddedAsIntegrationOnly(store.getObject(id))
          || Boolean(onUnlink || !aclHandler.canDeleteObject(id)),
        onClick: () => doDelete(id),
        danger: true,
      },
    ];
  };

  const onNewItemFunction = onNewItem && (() => {
    const newId = onNewItem();
    if (newId) {
      forceShowId(newId);
      focusNewLineNotify(newId);
    }
  });

  return (
    <>
      <DataTable<StoreObject>
        columnsDefinition={columnsDefinition}
        list={list}
        getNavigationPayload={(instance) => getNavigationPayload(navigation, instance.id, getConceptUrl(store, instance.id), navigationFilters)}
        linesActions={linesActions}
        linesActionsHeaderRenderer={linesActionsHeaderRenderer}
        doSort={doSort}
        sortCriteria={sortCriteria}
        newItemTitle={newItemTitle}
        newItemIcon={newItemIcon}
        newItemButtonVariant={newItemButtonVariant}
        newLineFocus={newLineFocus?.current}
        onNewItem={onNewItemFunction}
        lineContext={lineContext ?? ((instance) => [instance.id])}
        multiplayerRenderer={
          withoutActivityIndicator
            ? undefined
            : (line, propertyIds) => (<ActivityIndicator instanceIds={line.id} propertyIds={propertyIds} padding={Spacing.none} />)
        }
        inlineCreation={inlineCreation}
        pagination={pagination}
        minColumnWidthRem={minColumnWidthRem}
      />
      {deleteModal}
      {passwordModal}
    </>
  );
};

export default ConceptTable;
