import type { FunctionComponent, ReactElement } from 'react';
import { IconName } from '../../../../components/atoms/Icon';
import ToggleButton from '../../../../components/molecules/ToggleButton';
import BaseLayout from '../../../../components/templates/BaseLayout';
import i18n from '../../../../utils/i18n';
import TopBar from '../../../_global/topBar/TopBar';
import useBypassReadAcl from './useBypassReadAcl';

interface ExplorerMainContainerProps {
  leftPanel?: ReactElement | null,
  header: ReactElement | null,
  content: ReactElement | null,
}

const ExplorerMainContainer: FunctionComponent<ExplorerMainContainerProps> = ({ leftPanel, header, content }) => {
  const [bypassReadAcl, toggleBypassReadAcl] = useBypassReadAcl();

  return (
    <BaseLayout
      leftPanel={leftPanel}
      topBar={(
        <TopBar
          actions={(
            <ToggleButton
              name={i18n`Bypass Read ACL`}
              icon={bypassReadAcl ? IconName.toggle_on : IconName.toggle_off}
              active={bypassReadAcl}
              onClick={toggleBypassReadAcl}
            />
          )}
        />
      )}
      header={header}
      content={content}
    />
  );
};

export default ExplorerMainContainer;
