import { equals } from 'ramda';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import useForceUpdate from '../utils/useForceUpdate';
import useStoreContext from './useStoreContext';

interface ActiveSession {
  sessionId: string,
  userId: string,
}

interface Activity {
  listViewer: (objectId: string) => ActiveSession[],
  listEditor: (objectId: string, fieldId: string) => ActiveSession[],
}

const useActivity = (): Activity => {
  const { activity: { globalObservers, listViewer, listEditor } } = useStoreContext();
  const forceUpdate = useForceUpdate();

  const view = useRef<Record<string, ActiveSession[]>>({});
  const edit = useRef<Record<string, ActiveSession[]>>({});

  const doUpdate = useCallback(() => {
    let doForceUpdate = false;
    Object.entries(view.current)
      .forEach(([instanceId, cache]) => {
        const newViewer = listViewer(instanceId);
        if (!equals(cache, newViewer)) {
          view.current[instanceId] = newViewer;
          doForceUpdate = true;
        }
      });

    Object.entries(edit.current)
      .forEach(([key, cache]) => {
        const [instanceId, propertyId] = key.split('|');
        const newEditors = listEditor(instanceId, propertyId);
        if (!equals(cache, newEditors)) {
          edit.current[key] = newEditors;
          doForceUpdate = true;
        }
      });

    if (doForceUpdate) {
      forceUpdate();
    }
  }, [forceUpdate, listEditor, listViewer]);

  useEffect(() => {
    // Since React 18, the activity can be received between the first render and the useEffect call.
    // We check for new update before registering to make sure we didn't miss anything.
    doUpdate();
    return globalObservers.register(doUpdate);
  }, [doUpdate, globalObservers]);

  return useMemo(() => ({
    listViewer: (instanceId) => {
      if (!view.current[instanceId]) {
        view.current[instanceId] = listViewer(instanceId);
      }
      return view.current[instanceId];
    },
    listEditor: (instanceId, propertyId) => {
      const key = `${instanceId}|${propertyId}`;
      if (!edit.current[key]) {
        edit.current[key] = listEditor(instanceId, propertyId);
      }
      return edit.current[key];
    },
  }), [listEditor, listViewer]);
};

export default useActivity;
