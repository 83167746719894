const match = (url: string): {
  type: string,
  assetKey: { protocol: string, host: string | undefined, pathname: string | undefined, query: string | undefined, hash: string | undefined },
  generateUrl: () => string | undefined,
} | undefined => {
  const parsedUrl = new URL(url);
  const { protocol, host, pathname, hostname, search, hash, username, password } = parsedUrl;
  if ((protocol === 'http:' || protocol === 'https:') && !username && !password && hostname) {
    return {
      assetKey: {
        protocol,
        host,
        pathname,
        query: search ? search.substring(1) : undefined,
        hash: hash || undefined,
      },
      generateUrl: () => parsedUrl.toString(),
      type: 'HTTP',
    };
  } else {
    return undefined;
  }
};

export default {
  name: 'HTTP',
  match,
};
