import type { FunctionComponent } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { StructuralBarChartViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import StructuralBarChart from '../../../../components/charts/BarChart/StructuralBarChart';
import ScrollableWidgetContainer, {
  SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_HEIGHT_PADDING,
  SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_WIDTH_PADDING,
} from '../../../../components/templates/ScrollableWidgetContainer';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { remToPx } from '../../../../utils/sizeUtils';
import useDeepMemo from '../../../../utils/useDeepMemo';
import WidgetInvalidConfiguration from '../../fields/_global/WidgetInvalidConfiguration';
import WidgetNoData from '../../fields/_global/WidgetNoData';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { useViewFilters } from '../../filter/useViewFilters';
import ViewsTooltip from '../common/ViewsTooltip';
import { getViewDefinitionHandler } from '../viewDsl';
import { isResolutionError } from '../viewResolutionUtils';
import type { StructuralBarChartViewDefinitionHandler } from './structuralBarChartViewHandler';
import type { BarValue } from './structuralBarChartViewResolution';

interface StructuralBarChartViewWidgetProps {
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinition: StructuralBarChartViewStoredDefinition,
  parametersMapping: ParametersMapping,
  width: number,
  height: number,
}

const renderStructuralTooltip = (datum: BarValue, totalByX: number | undefined) => (
  <ViewsTooltip fieldId={datum.serie.fieldId} seriesLabel={datum.serie.label} dimensionsMapping={datum.dimensionsMapping ?? {}} value={datum.value} totalValue={totalByX} />
);

const StructuralBarChartViewWidget: FunctionComponent<StructuralBarChartViewWidgetProps> = ({
  viewDimensions,
  viewFilters,
  viewDefinition,
  parametersMapping,
  width,
  height,
}) => {
  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();
  const filterConfiguration = useViewFilters(viewFilters, viewDimensions);

  const viewHandler = getViewDefinitionHandler(viewDefinition) as StructuralBarChartViewDefinitionHandler;
  const structuralChartResolution = useDeepMemo(
    () => viewHandler.resolveView(store, {
      viewDimensions,
      parametersMapping,
      userId: loggedUserId,
      filterConfiguration,
      aclHandler,
    }),
    [store.getSerial(), viewDimensions, viewDefinition, parametersMapping, filterConfiguration]
  );
  if (isResolutionError(structuralChartResolution)) {
    return <WidgetInvalidConfiguration width={width} height={height} reason={structuralChartResolution.error} />;
  } else if (!structuralChartResolution.data || structuralChartResolution.data.length === 0) {
    return (
      <WidgetNoData width={width} height={height} />
    );
  } else {
    const chartHeight = height - remToPx(SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_HEIGHT_PADDING);
    return (
      <ScrollableWidgetContainer asContent width={width} height={height}>
        <StructuralBarChart<BarValue>
          minValue={structuralChartResolution.minValue}
          maxValue={structuralChartResolution.maxValue}
          steps={structuralChartResolution.steps}
          data={structuralChartResolution.data}
          labels={structuralChartResolution.labels}
          height={chartHeight}
          width={width - remToPx(SCROLLABLE_WIDGET_CONTAINER_AS_CONTENT_WIDTH_PADDING)}
          renderTooltip={renderStructuralTooltip}
          groupBy={Boolean(viewDefinition.xAxis)}
          yDomain={structuralChartResolution.yDomain}
        />
      </ScrollableWidgetContainer>
    );
  }
};

export default StructuralBarChartViewWidget;
