import type { FunctionComponent } from 'react';
import { useRef } from 'react';
import type { BlockStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import {
  FieldBlockConceptInstanceDisplay,
  FieldBlockConceptInstanceDisplay_DisplayOverride,
  FieldBlockConceptInstanceDisplay_Role_Concept,
  FieldBlockConceptInstanceDisplay_Role_FieldBlockDisplay,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { ConceptStoreObject } from 'yooi-modules/modules/conceptModule';
import { compareProperty, withObjectWithIdsAtEnd } from 'yooi-utils';
import { IconName } from '../../components/atoms/Icon';
import BlockTitle, { BlockTitleVariant } from '../../components/templates/BlockTitle';
import VerticalBlock from '../../components/templates/VerticalBlock';
import useAuth from '../../store/useAuth';
import useStore from '../../store/useStore';
import i18n from '../../utils/i18n';
import useDerivedState from '../../utils/useDerivedState';
import { BlockFieldDisplayStatus } from '../_global/fields/_global/blockFieldUtils';
import type { BlockFieldDisplay } from './conceptUtils';
import { getBlockFieldDisplays } from './conceptUtils';
import FieldBlockRenderer from './field/FieldBlockRenderer';

export interface ColumnBlockType {
  object: BlockStoreObject,
  fields: BlockFieldDisplay[],
  readOnly: boolean,
}

interface ConceptBlockProps {
  conceptId: string,
  block: ColumnBlockType,
  readOnly: boolean,
  inColumn: boolean,
}

const ConceptBlock: FunctionComponent<ConceptBlockProps> = ({ conceptId, block, readOnly, inColumn }) => {
  const store = useStore();
  const { loggedUserId } = useAuth();

  const concept = store.getObject<ConceptStoreObject>(conceptId);

  const fields = getBlockFieldDisplays(store, concept, block.object, loggedUserId);

  const [showMoreFields, setShowMoreFields] = useDerivedState(() => false, [conceptId]);

  const fieldOrder = useRef<{ instanceId?: string, mainFieldsOrder: string[], moreFieldsOrder: string[] }>({ mainFieldsOrder: [], moreFieldsOrder: [] });

  if (fieldOrder.current.instanceId !== conceptId) {
    fieldOrder.current = {
      instanceId: conceptId,
      mainFieldsOrder: fields.filter(({ displayStatus }) => displayStatus !== BlockFieldDisplayStatus.hidden).map(({ blockFieldDisplayId }) => blockFieldDisplayId),
      moreFieldsOrder: fields.filter(({ displayStatus }) => displayStatus === BlockFieldDisplayStatus.hidden).map(({ blockFieldDisplayId }) => blockFieldDisplayId),
    };
  }

  const mainFields = fields
    .filter(({ fieldPath, isFiltered }) => !isFiltered && fieldPath.length > 0)
    .filter(({ blockFieldDisplayId }) => fieldOrder.current.mainFieldsOrder.findIndex((id) => id === blockFieldDisplayId) !== -1)
    .sort(compareProperty('blockFieldDisplayId', withObjectWithIdsAtEnd(fieldOrder.current.mainFieldsOrder)));

  const moreFields = fields
    .filter(({ fieldPath, isFiltered }) => !isFiltered && fieldPath.length > 0)
    .filter(({ blockFieldDisplayId }) => fieldOrder.current.moreFieldsOrder.findIndex((id) => id === blockFieldDisplayId) !== -1)
    .sort(compareProperty('blockFieldDisplayId', withObjectWithIdsAtEnd(fieldOrder.current.moreFieldsOrder)));

  const updateDisplayStatus = ({ blockFieldDisplayId, isDisplayRecommended }: BlockFieldDisplay) => (newDisplayStatus: BlockFieldDisplayStatus): void => {
    const newStatus = newDisplayStatus === BlockFieldDisplayStatus.visible;
    store.withAssociation(FieldBlockConceptInstanceDisplay)
      .withRole(FieldBlockConceptInstanceDisplay_Role_FieldBlockDisplay, blockFieldDisplayId)
      .withRole(FieldBlockConceptInstanceDisplay_Role_Concept, conceptId)
      .updateObject({ [FieldBlockConceptInstanceDisplay_DisplayOverride]: isDisplayRecommended === newStatus ? null : newStatus });
  };

  const isBlockHidden = moreFields.length === 0 && mainFields.length === 0;

  if (isBlockHidden) {
    return null;
  }

  const hasDisplayedFieldInMoreFields = moreFields.some(({ displayStatus }) => displayStatus !== BlockFieldDisplayStatus.hidden);
  const expandsMoreFields = showMoreFields || hasDisplayedFieldInMoreFields;

  return (
    <VerticalBlock asBlockContent={!inColumn}>
      {((mainFields.length > 0 || inColumn) ? mainFields : moreFields)
        .map((field) => (
          <FieldBlockRenderer
            key={field.blockFieldDisplayId}
            fieldBlockDisplayId={field.blockFieldDisplayId}
            conceptId={conceptId}
            displayStatus={field.displayStatus}
            updateDisplayStatus={updateDisplayStatus(field)}
            readOnly={readOnly || field.readOnly}
            inColumn={inColumn}
          />
        ))}

      {(mainFields.length > 0 || inColumn) && moreFields.length > 0 && (
        <>
          <BlockTitle
            title={expandsMoreFields ? i18n`Fewer fields` : i18n`More fields`}
            variant={BlockTitleVariant.inline}
            buttonIcon={{
              tooltip: expandsMoreFields ? i18n`Hide` : i18n`Show`,
              iconName: expandsMoreFields ? IconName.expand_more : IconName.keyboard_arrow_right,
              onClick: () => setShowMoreFields(!expandsMoreFields),
            }}
            isGreyed
          />
          {expandsMoreFields && moreFields.map((field) => (
            <FieldBlockRenderer
              key={field.blockFieldDisplayId}
              fieldBlockDisplayId={field.blockFieldDisplayId}
              conceptId={conceptId}
              displayStatus={field.displayStatus}
              updateDisplayStatus={updateDisplayStatus(field)}
              readOnly={readOnly || field.readOnly}
              inColumn={inColumn}
            />
          ))}
        </>
      )}
    </VerticalBlock>
  );
};

export default ConceptBlock;
