import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import { BlockFieldLayoutOption } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { ImageFieldRaw, ImageFieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { imageFieldHandler } from 'yooi-modules/modules/conceptModule';
import type { AspectRatio } from 'yooi-modules/modules/conceptModule/fields/imageField';
import { AspectRatioEnum } from 'yooi-modules/modules/conceptModule/fields/imageField';
import {
  Field_ApiAlias,
  Field_Documentation,
  Field_IntegrationOnly,
  Field_IsCore,
  Field_IsDocumentationInline,
  Field_Title,
  ImageField,
  ImageField_AspectRatio,
} from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreReadOnly } from 'yooi-store';
import { joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import { Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import { remToPx } from '../../../../utils/sizeUtils';
import StoreNumberPickerInput from '../../input/StoreNumberPickerInput';
import type { Option, OptionRecord } from '../../modelTypeUtils';
import { getBlockFieldLayoutOption, getLayoutDisplayOption } from '../_global/blockFieldUtils';
import { getApiAliasInitialState, getDocumentationFieldEditionSection, getIntegrationFieldEditionSection } from '../_global/editionHandlerUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  duplicateFieldDimensionWithNewField,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
} from '../fieldDimensionUtils';
import type { FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldIntegrationOnlyDisabled } from '../FieldLibraryTypes';
import ImageBlockField from './ImageBlockField';
import { aspectRatios } from './imageFieldUtils';

export enum ImageDisplayType {
  Value = 'Value',
  BorderlessValue = 'BorderlessValue',
}

interface ImageFieldDisplayOptions {
  displayType?: ImageDisplayType,
}

interface ImageFieldBlockDisplayOptions extends ImageFieldDisplayOptions, FieldBlockDisplayOptions {}

const defaultDisplayOption: ImageFieldBlockDisplayOptions = { displayType: ImageDisplayType.Value };

const getDisplayOptions = (objectStore: ObjectStoreReadOnly, fieldBlockDisplayId: string): ImageFieldBlockDisplayOptions => {
  const fieldBlockDisplay = objectStore.getObject(fieldBlockDisplayId);
  if (fieldBlockDisplay[FieldBlockDisplay_FieldDisplayConfiguration]) {
    return fieldBlockDisplay[FieldBlockDisplay_FieldDisplayConfiguration] as ImageFieldBlockDisplayOptions;
  } else {
    return defaultDisplayOption;
  }
};

interface ImageFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
  [Field_IntegrationOnly]: boolean | null | undefined,
  [FieldIntegrationOnlyDisabled]: boolean | undefined,
  [ImageField_AspectRatio]: AspectRatio | null | undefined,
}

type ImageFieldDefinition = GetFieldDefinitionHandler<typeof imageFieldHandler, ImageFieldConfigurationState, ImageFieldDisplayOptions, ImageFieldBlockDisplayOptions>;

export const imageFieldDefinition: ImageFieldDefinition = registerFieldDefinition(imageFieldHandler, {
  configuration: {
    typeIcon: IconName.image,
    getTypeLabel: () => i18n`Image`,
    asWidget: false,
    isCreationEnabled: () => () => true,
    onCreate: (objectStore) => (editionHandler) => {
      const fieldId = objectStore.createObject<ImageFieldRaw>({
        [Instance_Of]: ImageField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [Field_ApiAlias]: editionHandler.getValue(Field_ApiAlias),
        [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
        [ImageField_AspectRatio]: editionHandler.getValue(ImageField_AspectRatio),
      });
      linkFieldToFieldDimensions(objectStore, fieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});
      return fieldId;
    },
    getEditionOptions: (objectStore) => ({ mode, editionHandler }) => {
      if (mode !== FieldEditionOptionMode.Field && mode !== FieldEditionOptionMode.FieldDeveloperMode) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      sections.push(getDocumentationFieldEditionSection(editionHandler));
      sections.push(getIntegrationFieldEditionSection(objectStore, editionHandler, mode));

      sections.push({
        key: 'display',
        type: 'section',
        title: i18n`Display`,
        options: [
          {
            key: ImageField_AspectRatio,
            title: i18n`Aspect ratio`,
            hasValue: () => editionHandler.getValue(ImageField_AspectRatio) !== undefined,
            clearValue: () => editionHandler.updateValues({ [ImageField_AspectRatio]: null }),
            type: EditionOptionTypes.custom,
            props: {
              render: () => {
                const aspectRatio = editionHandler.getValue(ImageField_AspectRatio);
                const options: OptionRecord<AspectRatioEnum | 'custom'> = {
                  [AspectRatioEnum.ThinBanner]: {
                    id: AspectRatioEnum.ThinBanner,
                    label: i18n`Thin banner (${aspectRatios[AspectRatioEnum.ThinBanner].x}:${aspectRatios[AspectRatioEnum.ThinBanner].y})`,
                  },
                  [AspectRatioEnum.Banner]: {
                    id: AspectRatioEnum.Banner,
                    label: i18n`Banner (${aspectRatios[AspectRatioEnum.Banner].x}:${aspectRatios[AspectRatioEnum.Banner].y})`,
                  },
                  [AspectRatioEnum.Wide]: {
                    id: AspectRatioEnum.Wide,
                    label: i18n`Wide (${aspectRatios[AspectRatioEnum.Wide].x}:${aspectRatios[AspectRatioEnum.Wide].y})`,
                  },
                  custom: {
                    id: 'custom',
                    label: i18n`Custom`,
                  },
                };

                let selectedOption: Option<AspectRatioEnum | 'custom'> = options[AspectRatioEnum.ThinBanner];
                if (aspectRatio && typeof aspectRatio === 'object') {
                  selectedOption = options.custom;
                } else if (aspectRatio) {
                  selectedOption = options[aspectRatio];
                }

                const searchAndSelect = (
                  <SearchAndSelect
                    selectedOption={selectedOption}
                    computeOptions={() => Object.values(options)}
                    onSelect={(option) => {
                      if (option) {
                        if (option.id === 'custom') {
                          editionHandler.updateValues({ [ImageField_AspectRatio]: { x: 1, y: 1 } });
                        } else {
                          editionHandler.updateValues({ [ImageField_AspectRatio]: option.id });
                        }
                      }
                    }}
                  />
                );

                if (aspectRatio && typeof aspectRatio === 'object') {
                  return (
                    <SpacingLine>
                      {searchAndSelect}
                      <StoreNumberPickerInput
                        initialValue={aspectRatio.x}
                        onSubmit={(newX) => {
                          const newXNumber = typeof newX === 'string' ? Number.parseInt(newX, 10) : newX;
                          if (newXNumber !== null && Number.isSafeInteger(newXNumber) && newXNumber > 0) {
                            editionHandler.updateValues({ [ImageField_AspectRatio]: { x: newXNumber, y: aspectRatio.y } });
                          }
                        }}
                      />
                      <Typo>:</Typo>
                      <StoreNumberPickerInput
                        initialValue={aspectRatio.y}
                        onSubmit={(newY) => {
                          const newYNumber = typeof newY === 'string' ? Number.parseInt(newY, 10) : newY;
                          if (newYNumber !== null && Number.isSafeInteger(newYNumber) && newYNumber > 0) {
                            editionHandler.updateValues({ [ImageField_AspectRatio]: { x: aspectRatio.x, y: newYNumber } });
                          }
                        }}
                      />
                    </SpacingLine>
                  );
                } else {
                  return (
                    <SpacingLine>
                      {searchAndSelect}
                    </SpacingLine>
                  );
                }
              },
            },
          },
        ],
      });

      return sections;
    },
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<ImageFieldStoreObject>(fieldId);
        return joinObjects(
          getApiAliasInitialState(objectStore, fieldId),
          {
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            [Field_IntegrationOnly]: field[Field_IntegrationOnly],
            [FieldIntegrationOnlyDisabled]: field[Field_IsCore],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
            [ImageField_AspectRatio]: field[ImageField_AspectRatio],
          }
        );
      },
      duplicateFieldDefinition: () => {
        const field = objectStore.getObject<ImageFieldStoreObject>(fieldId);
        const fieldDimensionMapping = generateDuplicatedFieldDimensionId(objectStore, fieldId);
        const newFieldId = objectStore.createObject<ImageFieldRaw>({
          [Instance_Of]: field[Instance_Of],
          [Field_Title]: `${field[Field_Title]} (copy)`,
          [Field_Documentation]: field[Field_Documentation],
          [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: field[Field_IntegrationOnly],
          [ImageField_AspectRatio]: field[ImageField_AspectRatio],
        });
        duplicateFieldDimensionWithNewField(objectStore, newFieldId, fieldDimensionMapping);
        return newFieldId;
      },
      submitFieldUpdate: (stateToSubmit) => {
        objectStore.updateObject<ImageFieldRaw>(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: stateToSubmit[Field_IntegrationOnly],
          [ImageField_AspectRatio]: stateToSubmit[ImageField_AspectRatio],
        });
      },
    }),
  },
  renderField: (_, fieldId) => ({ dimensionsMapping, readOnly, fieldDisplayOptions }) => {
    const displayOption = fieldDisplayOptions ?? defaultDisplayOption;

    return (
      <ImageBlockField fieldId={fieldId} dimensionsMapping={dimensionsMapping} readOnly={readOnly} borderless={displayOption.displayType === ImageDisplayType.BorderlessValue} />
    );
  },
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({ propertyId: fieldId, sortable: false }),
  estimatedCardHeight: (_, __, handler) => (width, fieldDisplayOptions) => {
    const configuration = handler.resolveConfiguration();
    const aspectRatio = typeof configuration.aspectRatio === 'string' ? aspectRatios[configuration.aspectRatio] : (configuration.aspectRatio ?? aspectRatios[AspectRatioEnum.ThinBanner]);

    if (fieldDisplayOptions?.displayType === ImageDisplayType.BorderlessValue) {
      return (aspectRatio.y * width) / aspectRatio.x;
    } else {
      const imageHeight = (aspectRatio.y * (width - remToPx(0.1 /* editable with dropdown border */ * 2))) / aspectRatio.x;
      return imageHeight + remToPx(0.1 /* editable with dropdown border */ * 2);
    }
  },
  renderExportConfiguration: () => () => (
    <SpacedContainer margin={{ x: Spacing.splus }}>
      <Tooltip title={i18n`Text - URL`}>
        <Typo maxLine={1}>{i18n`Text - URL`}</Typo>
      </Tooltip>
    </SpacedContainer>
  ),
  getActivityProperties: (_, fieldId) => () => [fieldId],
  blockDisplayOptionsHandler: (objectStore) => (fieldBlockDisplayId) => ({
    getDisplayOptions: () => getDisplayOptions(objectStore, fieldBlockDisplayId),
    renderSummary: ({ layoutDisplayType }) => ([getBlockFieldLayoutOption()[layoutDisplayType ?? BlockFieldLayoutOption.auto].label]),
    getBlockEditionOptionSections: (state, setState) => [getLayoutDisplayOption(state, setState)],
    onSubmit: (state) => {
      objectStore.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
});
