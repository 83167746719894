import type { FunctionComponent, ReactElement } from 'react';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { buildCanPerformObjectActions } from 'yooi-store';
import { newError } from 'yooi-utils';
import useAuth from './useAuth';
import useStoreContext from './useStoreContext';

export interface ACLHandler {
  canCreateObject: (id: string | string[]) => boolean,
  canReadObject: (id: string | string[]) => boolean,
  canDeleteObject: (id: string | string[]) => boolean,
  canWriteObject: (id: string | string[]) => boolean,
  canAssignStakeholders: (id: string | string[]) => boolean,
  canAssignCollaborator: (id: string | string[]) => boolean,
  canOverrideWorkflow: (id: string | string[]) => boolean,
  canOverrideCollaborationWorkflow: (id: string | string[]) => boolean,
}

const ACLHandlerContext = createContext<ACLHandler | undefined>(undefined);

interface ACLHandlerProviderProps {
  children: ReactElement | null,
}

export const ACLHandlerProvider: FunctionComponent<ACLHandlerProviderProps> = ({ children }) => {
  const { loggedUserId } = useAuth();

  const { data: { globalObservers, accessControlListLibrary } } = useStoreContext();

  const { canPerformObjectActions, resetCache } = useMemo(() => buildCanPerformObjectActions(accessControlListLibrary), [accessControlListLibrary]);

  useEffect(() => globalObservers.register(resetCache), [globalObservers, resetCache]);

  const aclHandler = useMemo((): ACLHandler => {
    const runAcl = (action: string, objectId: string | string[]) => (
      canPerformObjectActions(action, { userId: loggedUserId }, Array.isArray(objectId) ? objectId : [objectId]) ?? false
    );

    return {
      canCreateObject: (objectId) => runAcl('CREATE', objectId),
      canReadObject: (objectId) => runAcl('READ', objectId),
      canDeleteObject: (objectId) => runAcl('DELETE', objectId),
      canWriteObject: (objectId) => runAcl('WRITE', objectId),
      canAssignStakeholders: (objectId) => runAcl('ASSIGN_STAKEHOLDERS', objectId),
      canAssignCollaborator: (objectId) => runAcl('ASSIGN_COLLABORATOR', objectId),
      canOverrideWorkflow: (objectId) => runAcl('OVERRIDE_WORKFLOW', objectId),
      canOverrideCollaborationWorkflow: (objectId) => runAcl('OVERRIDE_COLLABORATION_WORKFLOW', objectId),
    };
  }, [canPerformObjectActions, loggedUserId]);

  return (
    <ACLHandlerContext.Provider value={aclHandler}>
      {children}
    </ACLHandlerContext.Provider>
  );
};

const useAcl = (): ACLHandler => {
  const aclHandler = useContext(ACLHandlerContext);
  if (aclHandler === undefined) {
    throw newError('ACLHandlerContext has not been initialized, add a ACLHandlerProviderProps in the React parent component hierarchy');
  }

  return aclHandler;
};

export default useAcl;
