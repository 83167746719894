import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { isFiniteNumber, newError, numberType } from 'yooi-utils';
import type { GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import { registerField } from '../module';
import type { FilterValueRaw } from '../moduleType';
import { FilterValueType } from '../moduleType';
import type {
  DimensionsMapping,
  FieldFilterCondition,
  FieldFilterConditions,
  ResolutionStack,
} from '../utils';
import {
  getCollaborationsForContext,
  isFilterValueRaw,
  isValueResolutionOfType,
  ParsedDimensionType,
  parseDimensionMapping,
  resolveFieldValue,
} from '../utils';
import type { CollaborationField } from './types';

const getValueResolution = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack) => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);

  if (isValueResolutionOfType(valueResolution, (value): value is number | undefined => value === undefined || typeof value === 'number')) {
    return valueResolution;
  } else {
    return {
      value: undefined,
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['number', 'undefined'], typeof valueResolution.value),
      getDisplayValue: () => undefined,
      isTimeseries: valueResolution.isTimeseries,
    };
  }
};

interface CollaborationFieldFilterConditions extends FieldFilterConditions<number> {
  EQUALS: FieldFilterCondition<number | undefined, number>,
  NOT_EQUALS: FieldFilterCondition<number | undefined, number>,
  GREATER: FieldFilterCondition<number | undefined, number>,
  GREATER_OR_EQUALS: FieldFilterCondition<number | undefined, number>,
  LOWER: FieldFilterCondition<number | undefined, number>,
  LOWER_OR_EQUALS: FieldFilterCondition<number | undefined, number>,
}

const isNumberFilterApplicable = (value: FilterValueRaw<number | undefined>) => isFilterValueRaw(value);
const sanitizeNumberValue = (value: unknown) => (value ?? { type: FilterValueType.raw, raw: undefined }) as FilterValueRaw<number>;

type CollaborationFieldHandler = GetDslFieldHandler<
  CollaborationField,
  undefined,
  undefined,
  number | undefined,
  number | undefined,
  undefined,
  CollaborationFieldFilterConditions,
  undefined,
  undefined,
  undefined
>;

export const collaborationFieldHandler: CollaborationFieldHandler = registerField({
  model: {
    label: 'CollaborationField',
    title: 'Collaboration',
  },
  handler: (objectStore, fieldId) => {
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => getValueResolution(objectStore, fieldId, dimensionsMapping).value?.toString();
    const getValueWithoutFormula = (dimensionsMapping: DimensionsMapping) => {
      const parsedDimension = parseDimensionMapping(dimensionsMapping);
      if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
        return getCollaborationsForContext(objectStore, [parsedDimension.objectId], false, 'OPEN').length;
      } else {
        return undefined;
      }
    };
    return {
      describe: () => ({ hasData: true, timeseriesMode: 'none', returnType: numberType }),
      restApi: {
        returnTypeSchema: {},
        formatValue: () => undefined,
      },
      getStoreValue: () => undefined,
      getValueWithoutFormula,
      getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
      updateValue: () => {
        throw newError('updateValue not supported');
      },
      isEmpty: (dimensionsMapping) => !getValueResolution(objectStore, fieldId, dimensionsMapping).value,
      isSaneValue: () => ({ isValid: true }),
      getValueAsText,
      getExportColumnHeaders: (configuration, fieldLabel) => ({
        columnsNumber: 1,
        getHeaders: () => [{ format: 'string', value: fieldLabel }],
        getColumnConfiguration: () => configuration,
      }),
      getExportValue: (dimensionsMapping) => ({
        format: 'number',
        value: getValueResolution(objectStore, fieldId, dimensionsMapping).value,
      }),
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else {
            return undefined;
          }
        },
      }),
      resolvePathStepConfiguration: () => ({
        hasData: true,
        timeseriesMode: 'none',
        getValueResolutionType: () => numberType,
        resolveValue: (dimensionsMapping, _, resolutionStack) => {
          const { value, error } = getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack);
          if (error) {
            throw error;
          } else {
            return value;
          }
        },
      }),
      filterConditions: {
        EQUALS: {
          isFilterApplicable: isNumberFilterApplicable,
          sanitizeValue: sanitizeNumberValue,
          filterFunction: (leftValue, rightValue) => (leftValue ?? 0) === rightValue,
        },
        NOT_EQUALS: {
          isFilterApplicable: isNumberFilterApplicable,
          sanitizeValue: sanitizeNumberValue,
          filterFunction: (leftValue, rightValue) => (leftValue ?? 0) !== rightValue,
        },
        GREATER: {
          sanitizeValue: sanitizeNumberValue,
          isFilterApplicable: isNumberFilterApplicable,
          filterFunction: (leftValue, rightValue) => {
            if (!isFiniteNumber(rightValue)) {
              return false;
            }
            return Number(leftValue ?? 0) > Number(rightValue);
          },
        },
        GREATER_OR_EQUALS: {
          sanitizeValue: sanitizeNumberValue,
          isFilterApplicable: isNumberFilterApplicable,
          filterFunction: (leftValue, rightValue) => {
            if (!isFiniteNumber(rightValue)) {
              return false;
            }
            return Number(leftValue ?? 0) >= Number(rightValue);
          },
        },
        LOWER: {
          sanitizeValue: sanitizeNumberValue,
          isFilterApplicable: isNumberFilterApplicable,
          filterFunction: (leftValue, rightValue) => {
            if (!isFiniteNumber(rightValue)) {
              return false;
            }
            return Number(leftValue ?? 0) < Number(rightValue);
          },
        },
        LOWER_OR_EQUALS: {
          sanitizeValue: sanitizeNumberValue,
          isFilterApplicable: isNumberFilterApplicable,
          filterFunction: (leftValue, rightValue) => {
            if (!isFiniteNumber(rightValue)) {
              return false;
            }
            return Number(leftValue ?? 0) <= Number(rightValue);
          },
        },
      },
    };
  },
});
