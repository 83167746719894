import type { ReactNode } from 'react';
import type { WorkflowTransitionStoreObject } from 'yooi-modules/modules/conceptModule';
import { Workflow_Transitions } from 'yooi-modules/modules/conceptModule/ids';
import { filterNullOrUndefined } from 'yooi-utils';
import Banner, { BannerVariant } from '../../../../components/molecules/Banner';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import BlockContent from '../../../../components/templates/BlockContent';
import BlockTitle from '../../../../components/templates/BlockTitle';
import HorizontalBlock, { HorizontalBlockVariant } from '../../../../components/templates/HorizontalBlock';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import StoreInput from '../../input/StoreInput';
import { getChipOptions } from '../../modelTypeUtils';

const useStyles = makeStyles((theme) => ({
  operationContainer: {
    marginLeft: spacingRem.xl,
    display: 'flex',
    flexFlow: 'row wrap',
    borderLeftWidth: '0.2rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
    alignItems: 'center',
  },
  bannerContainer: {
    marginLeft: spacingRem.s,
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
  },
}), 'workflowFieldRunTransitionOperationInput');

interface WorkflowFieldUpdateOperationInputProps {
  workflowId: string,
  initialValue: { type: 'value', value: string | undefined },
  onValueSubmit: (newValue: { type: 'value', value: string | undefined }) => void,
}

const WorkflowFieldRunTransitionOperationInput = ({
  workflowId,
  initialValue,
  onValueSubmit,
}: WorkflowFieldUpdateOperationInputProps): ReactNode => {
  const classes = useStyles();

  const store = useStore();

  return (
    <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
      <BlockTitle title={i18n`Transition`} />
      <BlockContent fullWidth>
        <div className={classes.operationContainer}>
          <StoreInput
            initialValue={initialValue.value}
            apply={(__, update) => update ?? undefined}
            onSubmit={(value) => onValueSubmit({ type: 'value', value: value ?? undefined })}
          >
            {({ value: formValue, onSubmit: formOnSubmit }) => (
              <SearchAndSelect
                computeOptions={() => store.getObject(workflowId)
                  .navigateBack<WorkflowTransitionStoreObject>(Workflow_Transitions)
                  .map((transition) => getChipOptions(store, transition.id))
                  .filter(filterNullOrUndefined)}
                selectedOption={formValue ? getChipOptions(store, formValue) : undefined}
                onSelect={(option) => formOnSubmit(option?.id ?? undefined)}
                placeholder={i18n`Select element`}
              />
            )}
          </StoreInput>
          <div className={classes.bannerContainer}>
            <Banner
              variant={BannerVariant.warning}
              title={i18n`This transition will be applied regardless of any restrictions that might exist, and of the status the workflow is currently in.`}
              fullWidth
            />
          </div>
        </div>
      </BlockContent>
    </HorizontalBlock>
  );
};

export default WorkflowFieldRunTransitionOperationInput;
