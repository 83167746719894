import { FunctionNotFoundError, FunctionResolutionError } from './errors';
import type { FormulaFunction, FunctionLibrary } from './formula';

interface FunctionLibraryBuilder {
  addFunction: (name: string | string[], build: (name: string) => Omit<FormulaFunction, 'name'>) => FunctionLibraryBuilder,
  addLibrary: (library: FunctionLibrary) => FunctionLibraryBuilder,
  build: () => FunctionLibrary,
}

export const newFunctionLibrary = (): FunctionLibraryBuilder => {
  const functions = new Map<string, FormulaFunction[]>();

  const addFunction = (formulaFunction: FormulaFunction) => {
    const caseInsensitiveName = formulaFunction.name.toLowerCase();
    const existingFunctions = functions.get(caseInsensitiveName) ?? [];
    functions.set(caseInsensitiveName, [...existingFunctions, formulaFunction]);
  };

  const builder: FunctionLibraryBuilder = {
    addFunction: (name, build) => {
      (Array.isArray(name) ? name : [name]).forEach((functionName) => {
        addFunction({ name: functionName, ...build(functionName) });
      });
      return builder;
    },
    addLibrary: (library) => {
      library.functions.forEach((formulaFunction) => addFunction(formulaFunction));
      return builder;
    },
    build: () => ({
      functions: [...functions.values()].flat(),
      resolveFunction: (name, argTypes) => {
        const candidates = functions.get(name.toLowerCase());
        if (candidates === undefined || candidates.length === 0) {
          return new FunctionNotFoundError(name);
        } else {
          const errors: Error[] = [];
          for (const formulaFunction of candidates) {
            const resolution = formulaFunction.resolve(argTypes);
            if (resolution instanceof Error) {
              errors.push(resolution);
            } else if (Array.isArray(resolution)) {
              return resolution;
            } else {
              return {
                formulaFunction,
                resolution,
              };
            }
          }
          return new FunctionResolutionError(name, argTypes, errors);
        }
      },
    }),
  };

  return builder;
};
