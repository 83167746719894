import type { FunctionComponent } from 'react';
import type { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import Icon, { IconSizeVariant } from '../../../components/atoms/Icon';
import base from '../../../theme/base';
import { FontVariant } from '../../../theme/fontDefinition';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import StoreTextInputField from '../../_global/input/StoreTextInputField';

interface AutomationCardTitleProps {
  label: string | undefined,
  placeholder?: string,
  onLabelUpdate?: (newValue: string | null) => void,
  icon: IconName,
  iconColor?: string,
  backgroundColor?: string,
  infoIcon?: {
    name: IconName,
    colorVariant: IconColorVariant,
    tooltip: string,
  },
}

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    gridTemplateColumns: 'auto 1fr',
    columnGap: spacingRem.m,
    width: 'fit-content',
    display: 'inline-grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    marginRight: spacingRem.m,
  },
  iconContainer: {
    width: spacingRem.xxl,
    height: spacingRem.xxl,
    borderRadius: base.borderRadius.circle,
    background: theme.color.background.neutral.subtle,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    flexGrow: 1,
  },
}), 'automationCardTitle');

const AutomationCardTitle: FunctionComponent<AutomationCardTitleProps> = ({ label, placeholder, onLabelUpdate, icon, iconColor, backgroundColor, infoIcon }) => {
  const classes = useStyles();

  return (
    <div className={classes.title}>
      <div className={classes.iconContainer} style={{ backgroundColor }}>
        <Icon
          name={icon}
          color={iconColor}
          size={IconSizeVariant.l}
        />
      </div>
      <div className={classes.textContainer}>
        <StoreTextInputField
          textVariant={FontVariant.blockSecondaryTitle}
          initialValue={label}
          onSubmit={onLabelUpdate}
          readOnly={!onLabelUpdate}
          placeholder={placeholder ?? i18n`Add text`}
          maxLine={1}
          dropdownMaxLine={10}
          fullWidth
        />
      </div>
      {infoIcon ? (
        <Icon
          name={infoIcon.name}
          colorVariant={infoIcon.colorVariant}
          tooltip={infoIcon.tooltip}
        />
      ) : null}
    </div>
  );
};

export default AutomationCardTitle;
