import type { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import type { AssociationFilterStoreObject, ConditionFilterStoreObject, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { getPathReturnedConceptDefinitionId } from 'yooi-modules/modules/conceptModule';
import { Concept } from 'yooi-modules/modules/conceptModule/ids';
import type { ViewsFieldDefinition, ViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import { Direction, filterError, filterNullOrUndefined, joinObjects, moveElementInArray } from 'yooi-utils';
import Checkbox from '../../../../components/atoms/Checkbox';
import { IconColorVariant, IconName, IconSizeVariant } from '../../../../components/atoms/Icon';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import Tabs from '../../../../components/molecules/Tabs';
import BlockContent from '../../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../../components/templates/VerticalBlock';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { useScrollableAnchorsRef } from '../../../../utils/useAnchorScroll';
import useNavigation from '../../../../utils/useNavigation';
import { SizeVariant } from '../../../../utils/useSizeContext';
import type { QuickFilterType } from '../../filter/QuickFiltersEditor';
import QuickFiltersEditor, { QuickFiltersEditorVariant } from '../../filter/QuickFiltersEditor';
import type { NavigationFilter } from '../../navigationUtils';
import { getNavigationElements } from '../../navigationUtils';
import { getDimensionLabel } from '../../views/data/dataResolution';
import { getViewDefinitionHandler } from '../../views/viewDsl';
import ViewsFieldDimensionsOptions from './ViewsFieldDimensionsOptions';
import ViewsGroupDisplayTable from './ViewsGroupDisplayTable';
import { addDimension, deleteDimension, updateDimension } from './viewsGroupUtils';

export type UpdateViewDefinition<V extends ViewStoredDefinition> = (fun: (oldViewDefinition: V) => V) => void;

export enum ViewsFieldDefinitionOptionsVariant {
  primary = 'primary',
  secondary = 'secondary',
}

interface ViewsFieldDefinitionOptionsProps {
  viewsDefinition: ViewsFieldDefinition,
  updateViewsDefinition: (viewsDefinition: ViewsFieldDefinition | null) => void,
  onNewQuickFilter: (type: QuickFilterType, rank: string) => string,
  getQuickFilters: () => (AssociationFilterStoreObject | ConditionFilterStoreObject)[],
  getNavigationData: () => { viewUrl: string, navigationKey: string } | undefined,
  readOnly: boolean,
  dataIsReadOnly?: boolean,
  parameterDefinitions: SingleParameterDefinition[],
  isWidget: boolean,
  widgetName?: string,
  variant?: ViewsFieldDefinitionOptionsVariant,
}

const ViewsFieldDefinitionOptions: FunctionComponent<ViewsFieldDefinitionOptionsProps> = ({
  viewsDefinition,
  updateViewsDefinition,
  getQuickFilters,
  onNewQuickFilter,
  getNavigationData,
  dataIsReadOnly,
  readOnly,
  parameterDefinitions,
  isWidget,
  widgetName,
  variant = ViewsFieldDefinitionOptionsVariant.primary,
}) => {
  const store = useStore();

  const navigation = useNavigation<NavigationFilter>();
  const location = useLocation();

  const navigationElementKey = getNavigationElements(store, location)?.at(-1)?.key;

  const getUpdateViewDefinition = <V extends ViewStoredDefinition>(oldValue: V) => {
    const updateViewDefinition: UpdateViewDefinition<V> = (updateFunction) => {
      const newViewDefinition = updateFunction(oldValue);
      updateViewsDefinition(joinObjects(
        viewsDefinition,
        { views: [...viewsDefinition.views.map((v): ViewStoredDefinition => (oldValue.id === v.id ? newViewDefinition : v))] }
      ));
    };
    return updateViewDefinition;
  };

  const tabs = [
    {
      key: 'overview',
      name: i18n`Overview`,
      hash: '#overview',
      render: () => (
        <VerticalBlock asBlockContent>
          <BlockTitle
            title={i18n`Display as`}
            variant={BlockTitleVariant.inline}
          />
          <BlockContent padded>
            <ViewsGroupDisplayTable
              viewDefinitions={viewsDefinition.views.map((viewDef) => getViewDefinitionHandler(viewDef).getDefinition(store, viewsDefinition.data)).filter(filterError)}
              viewDimensions={viewsDefinition.data}
              parameterDefinitions={parameterDefinitions}
              updateViewDefinitions={(updateFunction) => updateViewsDefinition(joinObjects(
                viewsDefinition,
                { views: updateFunction(viewsDefinition.views) }
              ))}
              readOnly={readOnly}
              getNavigationData={getNavigationData}
            />
          </BlockContent>
        </VerticalBlock>
      ),
    },
    ...viewsDefinition.views.map((viewDefinition) => {
      const viewDefinitionHandler = getViewDefinitionHandler(viewDefinition);
      const errors = viewDefinitionHandler.getDefinitionErrors(store, viewsDefinition.data, parameterDefinitions);
      return ({
        key: `${viewDefinition.id}_key`,
        icon: errors ? IconName.dangerous : viewDefinitionHandler.icon,
        iconSize: variant === ViewsFieldDefinitionOptionsVariant.primary ? IconSizeVariant.l : IconSizeVariant.m,
        iconColor: errors ? IconColorVariant.error : undefined,
        iconTooltip: errors ? errors.join(', ') : undefined,
        name: viewDefinitionHandler.label,
        hash: `#${viewDefinition.id}`,
        render: () => viewDefinitionHandler.renderDefinitionOptions(
          store,
          {
            widgetName,
            viewDimensions: viewsDefinition.data,
            updateViewDefinition: getUpdateViewDefinition(viewDefinition),
            readOnly,
            parameterDefinitions,
            isWidget,
          }
        ),
      });
    }),
  ];
  let selectedTabIndex = tabs.findIndex((tab) => tab.hash === location.hash);
  if (selectedTabIndex === -1) {
    selectedTabIndex = 0;
  }
  const scrollableRef = useScrollableAnchorsRef(tabs.map(({ hash }) => hash));

  const conceptDefinitionId = viewsDefinition.data.length === 1 ? getPathReturnedConceptDefinitionId(store, viewsDefinition.data[0].path) : undefined;
  const dimensionParameters: SingleParameterDefinition[] = (viewsDefinition ?? []).data.map((data, index) => {
    const returnTypeId = getPathReturnedConceptDefinitionId(store, data.path);
    return returnTypeId ? {
      id: data.id,
      type: 'dimension' as const,
      label: getDimensionLabel(store, data.label, index, data.path),
      typeId: returnTypeId,
    } : undefined;
  }).filter(filterNullOrUndefined);

  return (
    <>
      <VerticalBlock asBlockContent withSeparation={variant === ViewsFieldDefinitionOptionsVariant.primary}>
        <BlockTitle title={i18n`Data`} variant={variant === ViewsFieldDefinitionOptionsVariant.primary ? BlockTitleVariant.primary : BlockTitleVariant.secondary} />
        <ViewsFieldDimensionsOptions
          dimensions={viewsDefinition.data}
          onCreateDimension={() => {
            updateViewsDefinition(addDimension(viewsDefinition));
          }}
          onDeleteDimension={(dimensionId) => {
            updateViewsDefinition(deleteDimension(viewsDefinition, dimensionId));
          }}
          onUpdateDimension={(dimensionId, properties) => {
            updateViewsDefinition(updateDimension(store, viewsDefinition, dimensionId, properties));
          }}
          onMoveUpDimension={(index) => {
            updateViewsDefinition(joinObjects(viewsDefinition, { data: moveElementInArray(Direction.up, index, viewsDefinition.data) }));
          }}
          onMoveDownDimension={(index) => {
            updateViewsDefinition(joinObjects(viewsDefinition, { data: moveElementInArray(Direction.down, index, viewsDefinition.data) }));
          }}
          readOnly={readOnly || Boolean(dataIsReadOnly)}
          parameterDefinitions={parameterDefinitions}
        />
      </VerticalBlock>
      <VerticalBlock asBlockContent withSeparation={variant === ViewsFieldDefinitionOptionsVariant.primary}>
        <BlockTitle title={i18n`Filters`} variant={variant === ViewsFieldDefinitionOptionsVariant.primary ? BlockTitleVariant.primary : BlockTitleVariant.secondary} />
        {conceptDefinitionId !== Concept ? (
          <>
            <HorizontalBlock asBlockContent>
              <BlockTitle title={i18n`Use filters on this widget`} />
              <BlockContent padded>
                <SpacingLine>
                  <Checkbox
                    checked={viewsDefinition.hasFilters ?? false}
                    onChange={(value) => {
                      updateViewsDefinition(joinObjects(viewsDefinition, { hasFilters: value }));
                    }}
                  />
                </SpacingLine>
              </BlockContent>
            </HorizontalBlock>
            {viewsDefinition.hasFilters && (
              <QuickFiltersEditor
                getQuickFilters={getQuickFilters}
                onNewItem={onNewQuickFilter}
                filterParametersDefinitions={[
                  ...dimensionParameters,
                  ...parameterDefinitions,
                ]}
                dimensionParameters={dimensionParameters}
                variant={variant === ViewsFieldDefinitionOptionsVariant.primary ? QuickFiltersEditorVariant.secondary : QuickFiltersEditorVariant.tertiary}
              />
            )}
          </>
        ) : (<BlockTitle title={i18n`Filters are not available for this configuration.`} variant={BlockTitleVariant.inline} />)}
      </VerticalBlock>
      <VerticalBlock asBlockContent>
        <span ref={scrollableRef} />
        <BlockTitle title={i18n`Views`} variant={variant === ViewsFieldDefinitionOptionsVariant.primary ? BlockTitleVariant.primary : BlockTitleVariant.secondary} />
        <BlockContent padded>
          <Tabs
            tabs={tabs}
            sizeVariant={variant === ViewsFieldDefinitionOptionsVariant.primary ? SizeVariant.tabs : SizeVariant.main}
            selectedTabIndex={selectedTabIndex !== -1 ? selectedTabIndex : 0}
            onSelectedIndexChanged={(index) => {
              navigation.pushPayload(navigation.createNavigationPayload(
                navigationElementKey ?? '',
                joinObjects(location, { hash: tabs[index].hash }),
                { preventScroll: true }
              ));
            }}
          />
        </BlockContent>
        {tabs[selectedTabIndex].render()}
      </VerticalBlock>
    </>
  );
};

export default ViewsFieldDefinitionOptions;
