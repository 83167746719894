import { ExecutionContextClients, ValidationStatus } from 'yooi-store';
import { formatForStorage } from 'yooi-utils';
import { getCollaborationContextsObjectIds, getCollaborationRoleRecipientsIds } from '../collaborationModule';
import {
  Collaboration_Messages,
  CollaborationGroups,
  CollaborationGroups_Role_Collaboration,
  CollaborationGroups_Role_Group,
  CollaborationMessage,
  CollaborationMessage_Collaboration,
  CollaborationMessage_Text,
  CollaborationUsers,
  CollaborationUsers_Role_Collaboration,
  CollaborationUsers_Role_User,
} from '../collaborationModule/ids';
import { createModule } from '../common/types/TypeModuleDsl';
import type { BusinessRuleRegistration } from '../common/types/TypeModuleDslType';
import {
  Association,
  Association_Role_Definition,
  Association_Role_Role1TypeInstance,
  Association_Role_Role2TypeInstance,
  Concept,
  ConceptRoleUserAssignation,
  ConceptRoleUserAssignation_Role_Concept,
  ConceptRoleUserAssignation_Role_ConceptRole,
  ConceptRoleUserAssignation_Role_User,
  GroupMembershipAssociationDefinition,
} from '../conceptModule/ids';
import { isInstanceOf } from '../typeModule';
import * as NotificationModuleIds from './ids';
import { Notification, Notification_CreatedAt, Notification_Role_Message, Notification_Role_User, Notification_Seen, Notification_Title, NotificationModuleId } from './ids';
import migrations from './migrations';

const { initTypedModule, ...registerModelDsl } = createModule({ label: 'Notification' }, NotificationModuleIds);
export const registerModel = registerModelDsl;

const sendNotifications: BusinessRuleRegistration = ({ getObject, getObjectOrNull }) => (origin, { id, properties }, executionContext) => {
  if (executionContext.client === ExecutionContextClients.FRONT) {
    return undefined;
  }

  // Deletion ? Don't care
  if (!properties) {
    return undefined;
  }

  // Existing object ? Don't care
  const currentObject = getObjectOrNull(id);
  if (currentObject) {
    return undefined;
  }

  // No userId in origin ? Skip update
  if (!origin.userId) {
    return undefined;
  }

  return {
    rule: 'collaboration.message.sendNotificationsToStakeholders',
    status: ValidationStatus.ACCEPTED,
    generateSystemEvent: (store) => {
      const collaboration = properties[CollaborationMessage_Collaboration] as string;
      const recipientsAsUserId = store.withAssociation(CollaborationUsers)
        .withRole(CollaborationUsers_Role_Collaboration, collaboration)
        .withExternalRole(CollaborationUsers_Role_User)
        .list()
        .map((assoc) => assoc.role(CollaborationUsers_Role_User));
      const recipientsAsGroupId = store.withAssociation(CollaborationGroups)
        .withRole(CollaborationGroups_Role_Collaboration, collaboration)
        .list()
        .map((assoc) => assoc.role(CollaborationGroups_Role_Group));
      const recipientAsGroupUserId = recipientsAsGroupId
        .flatMap((groupId) => store.withAssociation(Association)
          .withRole(Association_Role_Definition, GroupMembershipAssociationDefinition)
          .withRole(Association_Role_Role1TypeInstance, groupId)
          .list()
          .map((assoc) => assoc.role(Association_Role_Role2TypeInstance)));

      const recipientsAsRoleId = getCollaborationRoleRecipientsIds(store, collaboration);
      const collaborationContextsObjectIds = getCollaborationContextsObjectIds(store, collaboration);
      const firstClassConceptId = collaborationContextsObjectIds?.find((objectId) => isInstanceOf(store.getObject(objectId), Concept));
      const recipientAsRoleUserId = recipientsAsRoleId
        .flatMap((role) => (firstClassConceptId ? store.withAssociation(ConceptRoleUserAssignation)
          .withRole(ConceptRoleUserAssignation_Role_Concept, firstClassConceptId)
          .withRole(ConceptRoleUserAssignation_Role_ConceptRole, role)
          .list()
          .map((assignation) => assignation.role(ConceptRoleUserAssignation_Role_User)) : []));
      const allRecipientsAsUserId = [...recipientAsGroupUserId, ...recipientsAsUserId, ...recipientAsRoleUserId].filter((item, index, array) => array.indexOf(item) === index);
      allRecipientsAsUserId.filter((uid) => uid !== origin.userId).forEach((userId) => {
        store.withAssociation(Notification)
          .withRole(Notification_Role_Message, id[0])
          .withRole(Notification_Role_User, userId)
          .updateObject({
            [Notification_Title]: `${getObject(collaboration)
              .navigateBack(Collaboration_Messages).length ? 'New collaboration answer' : 'New collaboration'} : ${properties[CollaborationMessage_Text]}`,
            [Notification_Seen]: false,
            [Notification_CreatedAt]: formatForStorage(executionContext.date),
          });
      });
    },
  };
};

export const initModule = initTypedModule(() => ({
  id: NotificationModuleId,
  migrations,
  registerBusinessRules: (objectStore, registerBusinessRules) => {
    const { onObject } = registerBusinessRules;
    onObject(CollaborationMessage).validate(sendNotifications(objectStore));
  },
}));
