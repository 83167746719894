import type { UrlFieldRaw, UrlFieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { ParsedDimensionType, parseDimensionMapping, urlFieldHandler } from 'yooi-modules/modules/conceptModule';
import type { Field_Title } from 'yooi-modules/modules/conceptModule/ids';
import { Field_ApiAlias, Field_Documentation, Field_IntegrationOnly, Field_IsCore, Field_IsDocumentationInline } from 'yooi-modules/modules/conceptModule/ids';
import { compareString, comparing, joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import { TableSortDirection } from '../../../../components/molecules/Table';
import { Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import { getApiAliasInitialState, getDocumentationFieldEditionSection, getIntegrationFieldEditionSection } from '../_global/editionHandlerUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import { FIELD_EDITION_DIMENSIONS, getFieldDimensionsEditionHandlerValue } from '../fieldDimensionUtils';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, FieldComparatorHandler, GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldIntegrationOnlyDisabled } from '../FieldLibraryTypes';
import UrlFieldRenderer from './UrlFieldRenderer';

interface UrlFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
  [Field_IntegrationOnly]: boolean | null | undefined,
  [FieldIntegrationOnlyDisabled]: boolean | undefined,
}

type UrlFieldDefinition = GetFieldDefinitionHandler<typeof urlFieldHandler, UrlFieldConfigurationState>;

export const urlFieldDefinition: UrlFieldDefinition = registerFieldDefinition(urlFieldHandler, {
  configuration: {
    typeIcon: IconName.subject,
    getTypeLabel: () => i18n`URL`,
    asWidget: false,
    getEditionOptions: (objectStore) => ({ mode, editionHandler }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        return [];
      }

      return [
        getDocumentationFieldEditionSection(editionHandler),
        getIntegrationFieldEditionSection(objectStore, editionHandler, mode),
      ];
    },
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<UrlFieldStoreObject>(fieldId);
        return joinObjects(
          getApiAliasInitialState(objectStore, fieldId),
          {
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            [Field_IntegrationOnly]: field[Field_IntegrationOnly],
            [FieldIntegrationOnlyDisabled]: field[Field_IsCore],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
          }
        );
      },
      submitFieldUpdate: (stateToSubmit) => {
        objectStore.updateObject<UrlFieldRaw>(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: stateToSubmit[Field_IntegrationOnly],
        });
      },
    }),
  },
  renderField: (_, fieldId) => ({ dimensionsMapping, readOnly, focusOnMount }) => {
    const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
    if (parsedDimensionMapping.type !== ParsedDimensionType.MonoDimensional) {
      return null;
    }

    return (<UrlFieldRenderer fieldId={fieldId} objectId={parsedDimensionMapping.objectId} readOnly={readOnly} focusOnMount={focusOnMount} />);
  },
  renderExportConfiguration: () => () => (
    <SpacedContainer margin={{ x: Spacing.splus }}>
      <Tooltip title={i18n`Text`}>
        <Typo maxLine={1}>{i18n`Text`}</Typo>
      </Tooltip>
    </SpacedContainer>
  ),
  getActivityProperties: (_, fieldId) => () => [fieldId],
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({
    propertyId: fieldId,
    sortable: true,
    focusable: true,
  }),
  getComparatorHandler: (_, __, { getValueResolution }) => (direction) => ({
    comparator: comparing(compareString, direction === TableSortDirection.desc),
    extractValue: (dimensionsMapping) => getValueResolution(dimensionsMapping).value,
  }) satisfies FieldComparatorHandler<string | undefined>,
});
