import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import { Fragment } from 'react';
import type { FieldOperation } from 'yooi-modules/modules/automationModule';
import { filterNullOrUndefined } from 'yooi-utils';
import Icon, { IconName, IconSizeVariant } from '../../../components/atoms/Icon';
import useStore from '../../../store/useStore';
import base from '../../../theme/base';
import { spacingRem } from '../../../theme/spacingDefinition';
import makeStyles from '../../../utils/makeStyles';
import useTheme from '../../../utils/useTheme';
import { getFieldHandler } from '../../_global/fields/FieldLibrary';

const useStyles = makeStyles((theme) => ({
  line: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  iconContainer: {
    width: spacingRem.xxl,
    height: spacingRem.xxl,
    borderRadius: base.borderRadius.circle,
    background: theme.color.background.neutral.subtle,
    borderWidth: '0.2rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconOverlay: {
    marginRight: '-0.6rem',
  },
}), 'fieldOperationsListSummary');

interface FieldOperationsListSummaryProps {
  fieldOperations: FieldOperation[],
}

const FieldOperationsListSummary: FunctionComponent<FieldOperationsListSummaryProps> = ({ fieldOperations }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  return (
    <div className={classes.line}>
      {fieldOperations.map(({ id, fieldId, operation }, index, { length }) => {
        let updateOperationHandler;
        let icon;
        if (fieldId && operation) {
          updateOperationHandler = getFieldHandler(store, fieldId)?.getUpdateOperationInput?.(operation);
          icon = updateOperationHandler?.getIcon(theme);
        }

        return (
          <Fragment key={id}>
            <div
              className={classnames({ [classes.iconContainer]: true, [classes.iconOverlay]: index !== (length - 1) })}
              style={{ backgroundColor: icon?.backgroundColor ?? theme.color.background.primarylight.default }}
            >
              <Icon
                name={icon?.name ?? IconName.add}
                color={icon?.color ?? theme.color.text.success}
                tooltip={updateOperationHandler?.title}
                size={IconSizeVariant.l}
              />
            </div>
          </Fragment>
        );
      }).filter(filterNullOrUndefined)}
    </div>
  );
};

export default FieldOperationsListSummary;
