import classnames from 'classnames';
import { forwardRef } from 'react';
import base from '../../theme/base';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import { widgetContainerSelectorsClasses } from './WidgetContainer';

const useStyles = makeStyles((theme) => ({
  handler: {
    width: '1.5rem',
    height: '1.5rem',
    position: 'absolute',
    right: 0,
    bottom: 0,
    cursor: 'nw-resize',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    visibility: 'hidden',
    padding: spacingRem.xxs,
  },
  element: {
    display: 'flex',
    width: '1rem',
    height: '1rem',
    borderBottomWidth: '0.2rem',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.color.border.primary,
    borderRightWidth: '0.2rem',
    borderRightStyle: 'solid',
    borderRightColor: theme.color.border.primary,
    borderBottomRightRadius: base.borderRadius.medium,
  },
}), 'widgetResizeHandle');

interface WidgetResizeHandleProps {
  handleAxis?: string,
}

// we use ...other to transit props from react grid layout (like event handler)
// forwardRef is required to be directly injected as children of ReactGridLayout
const WidgetResizeHandle = forwardRef<HTMLSpanElement, WidgetResizeHandleProps>(({ handleAxis, ...props }, ref) => {
  const classes = useStyles();

  return (
    <span
      ref={ref}
      // Specific case as we forward styles from react-grid-layout
      // eslint-disable-next-line yooi/check-classname-attribute
      className={classnames(`handle-${handleAxis}`, classes.handler, widgetContainerSelectorsClasses.hoveredVisible)}
      {...props}
    >
      <span className={classes.element} />
    </span>
  );
});

export default WidgetResizeHandle;
