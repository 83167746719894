import classnames from 'classnames';
import type { ReactElement } from 'react';
import { useState } from 'react';
import { VictoryContainer, VictoryPie, VictoryTooltip } from 'victory';
import { isNumber, joinObjects } from 'yooi-utils';
import { getFontStyle } from '../../../utils/fontUtils';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { approximateNumber } from '../../../utils/numberUtils';
import { remToPx } from '../../../utils/sizeUtils';
import useTheme from '../../../utils/useTheme';
import Tooltip from '../../atoms/Tooltip';
import ChartLegend, { CHART_LEGEND_LINE_HEIGHT } from '../ChartLegend';
import type { ChartTooltipData, ColorStepsValue, Labels } from '../ChartTypes';
import ChartTooltipForVictory from '../internal/ChartTooltipForVictory';
import FlyoutWrapper from '../internal/FlyoutWrapper';
import NoLabel from '../NoLabel';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    lineHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  maxContainer: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    lineHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  label: {
    color: theme.color.text.primary,
  },
}), 'gaugeChart');

interface GaugeChartStep {
  value: number,
  color: string | undefined,
}

interface GaugeChartSeries {
  value: number | undefined,
  color?: string | undefined,
  fieldId: string,
  label?: string | undefined,
  dimensionsMapping?: Record<string, string | undefined>,
  totalValue?: number,
  legendLabel?: string,
  key?: string,
}

interface GaugeChartProps<Series extends GaugeChartSeries = GaugeChartSeries> {
  height: number,
  width: number,
  minValue: ColorStepsValue,
  maxValue: ColorStepsValue,
  steps: ColorStepsValue[],
  series: Series[],
  renderTooltip: (series: ChartTooltipData<{ value: number }>) => ReactElement,
  labels: Labels[],
}

const GaugeChart = <Series extends GaugeChartSeries = GaugeChartSeries>({
  minValue,
  maxValue,
  steps,
  series,
  height,
  width,
  renderTooltip,
  labels: generatedLabels,
}: GaugeChartProps<Series>): ReactElement | null => {
  const theme = useTheme();
  const classes = useStyles();

  const { color: minColor, value: minValueValue } = minValue;
  const { color: maxColor, value: maxValueValue } = maxValue;

  const [showGlobalTooltip, setShowGlobalTooltip] = useState(false);
  const [displayKeyList, setDisplayKeyList] = useState<string[]>([]);

  const firstLegendLineHeightInRem = generatedLabels.length > 0 ? CHART_LEGEND_LINE_HEIGHT : 0;
  const svgHeight = height - remToPx(firstLegendLineHeightInRem);

  const getOpacity = (key: string) => (displayKeyList.length === 0 || displayKeyList.includes(key) ? 1 : 0.2);
  const getTooltipData = (tooltipSeries: Series[], tooltipValue?: number) => (tooltipSeries[0] ? {
    seriesFieldId: tooltipSeries[0].fieldId,
    seriesLabel: tooltipSeries[0].label ?? '',
    dimensionsMapping: !(tooltipValue || tooltipValue === 0) ? tooltipSeries[0].dimensionsMapping ?? {} : {},
    datum: { value: (tooltipValue || tooltipValue === 0) ? tooltipValue : tooltipSeries[0].value ?? 0 },
  } : undefined);

  const displaySteps: GaugeChartStep[] = [];
  const displaySeries: { value: number, displayValue: number, color: string | undefined, key?: string }[] = [];
  let displayRange = 1; // Use 1 as display range to make sure we display the gauge background when there is no min/maxValue

  if (isNumber(minValueValue) && isNumber(maxValueValue) && minValueValue < maxValueValue) {
    // If we have at least a min & a max

    // we can use some display steps
    displaySteps.push({ color: minColor, value: minValueValue });
    steps?.forEach(({ value: stepValue, color: stepColor }) => {
      // Only include step that are between ]min, max[ and that are greater than the previous one
      if (isNumber(stepValue) && stepValue > displaySteps[displaySteps.length - 1].value && stepValue < maxValueValue) {
        displaySteps.push({ value: stepValue, color: stepColor });
      }
    });
    displaySteps.push({ color: maxColor, value: maxValueValue });

    const displayMaxValue = maxValueValue * (10 / 9);
    displayRange = displayMaxValue - minValueValue;

    // we can display series
    series.forEach(({ value: serieValue, color, key }) => {
      const sanitizedValue = Math.max(0, serieValue ?? 0);
      const displaySeriesTotal = displaySeries.reduce((sum, { displayValue }) => displayValue + sum, 0);

      let displayValue = 0;
      if (displaySeriesTotal + sanitizedValue <= displayMaxValue) {
        displayValue = sanitizedValue;
      } else if (displaySeriesTotal < displayMaxValue) {
        displayValue = displayMaxValue - displaySeriesTotal;
      }

      displaySeries.push({ value: sanitizedValue, displayValue, color, key });
    });
  }

  const gaugeTotalValue = series.reduce<number>((sum, { value: serieValue }) => sum + (serieValue ?? 0), 0);

  // Add some space for min/max label
  const gaugeHeight = 0.9 * svgHeight;

  // automargin define by Victory, can't be overridden
  const pieChartMargin = width * 0.08;

  const pieChartHeight = Math.max(0, Math.min(width, gaugeHeight * 2));
  const radius = Math.max(0, pieChartHeight / 2 - pieChartMargin);
  const innerRadius = Math.max(0, 0.75 * radius);
  const origin = { x: width / 2, y: Math.max(0, pieChartHeight < width ? gaugeHeight - pieChartMargin / 2 : gaugeHeight / 2 + width / 4 - pieChartMargin / 2) };
  const mainValueFontSize = 0.14 * pieChartHeight - 14;
  const minMaxLabelWidth = Math.max(0, 2 * (pieChartHeight / 2 - innerRadius - pieChartMargin));

  const displayLabel = (number: number | undefined) => (number !== undefined ? approximateNumber(number) : '-');

  const formatNumber = (number: number | undefined) => (number !== undefined ? number.toLocaleString(i18n.locale, { maximumFractionDigits: 0, minimumFractionDigits: 0 }) : '-');

  const currentStep = displaySteps.reduce<GaugeChartStep | undefined>((accumulator, step) => (step.value <= gaugeTotalValue ? step : accumulator), undefined);

  const maxLinePosX1 = Math.cos((-18 * Math.PI) / 180) * innerRadius * 0.94 + origin.x;
  const maxLinePosY1 = Math.sin((-18 * Math.PI) / 180) * innerRadius * 0.94 + origin.y;
  const maxLinePosX2 = Math.cos((-18 * Math.PI) / 180) * radius * 1.01 + origin.x;
  const maxLinePosY2 = Math.sin((-18 * Math.PI) / 180) * radius * 1.01 + origin.y;
  const maxLabelPosX = Math.cos((-18 * Math.PI) / 180) * radius * 1.025 + origin.x + remToPx(0.4);
  const maxLabelPosY = Math.sin((-18 * Math.PI) / 180) * radius * 1.025 + origin.y - pieChartHeight / 40;

  const gaugeValueWidth = 1.5 * innerRadius;
  const gaugeValueX = Math.max(0, width / 2 - 0.75 * innerRadius);
  const gaugeValueY = Math.max(0, origin.y - 0.5 * innerRadius);

  const labels = generatedLabels.map(({ label, color, key }) => ({
    name: label,
    symbol: { fill: color },
    displayStroke: !color,
    key,
  }));

  const maxLabels = 20;
  const truncatedLabels = labels.length > maxLabels ? [
    ...labels.slice(0, maxLabels),
    {
      name: `${labels.length - maxLabels} other labels ...`,
      symbol: { fill: theme.color.text.secondary },
      displayStroke: false,
      key: 'others',
    },
  ] : labels;

  return (
    <>
      <VictoryContainer width={width} height={svgHeight} style={{ width, height: svgHeight }}>
        <>
          {displaySteps.length && (
            <VictoryPie
              // Draw step color wheel
              standalone={false}
              width={width}
              height={pieChartHeight}
              origin={origin}
              radius={innerRadius * 0.97}
              innerRadius={innerRadius * 0.95}
              startAngle={-90}
              endAngle={90}
              // We explicitly wants to avoid the label component
              labelComponent={<NoLabel />}
              colorScale={displaySteps.map(({ color }) => color ?? theme.color.border.default)}
              data={
                displaySteps.map((step, index) => {
                  if (displaySteps[index + 1]) {
                    return { y: displaySteps[index + 1].value - step.value };
                  } else {
                    const range = step.value - displaySteps[0].value;
                    return { y: (range * (10 / 9)) - range };
                  }
                })
              }
              padAngle={0.5}
              padding={pieChartMargin}
            />
          )}
          <VictoryPie
            // Draw value color wheel
            standalone={false}
            width={width}
            height={pieChartHeight}
            origin={origin}
            innerRadius={innerRadius}
            startAngle={-90}
            endAngle={90}
            labelComponent={(
              <VictoryTooltip
                pointerLength={0}
                constrainToVisibleArea
                center={{ x: origin.x, y: undefined }}
                flyoutComponent={(
                  <FlyoutWrapper>
                    {({ x, y, datum }) => (
                      <ChartTooltipForVictory
                        chartWidth={width}
                        chartHeight={svgHeight}
                        renderTooltip={() => {
                          const { x: dataX } = datum as { x: number };
                          const tooltipData = getTooltipData([series[dataX]]);
                          if (dataX < series.length && tooltipData) {
                            return renderTooltip(tooltipData);
                          } else {
                            return null;
                          }
                        }}
                        x={x}
                        y={y}
                      />
                    )}
                  </FlyoutWrapper>
                )}
                // We explicitly wants to avoid the label component
                labelComponent={<NoLabel />}
                style={joinObjects(
                  getFontStyle(theme.font.small),
                  { lineHeight: undefined }
                )}
              />
            )}
            colorScale={[
              ...displaySeries.map(({ color }) => color ?? currentStep?.color ?? theme.color.border.default),
              theme.color.background.neutral.subtle,
            ]}
            data={[
              ...displaySeries.map(({ displayValue, key }, index) => ({ x: index, y: displayValue, key })),
              {
                x: series.length,
                y: Math.max(0, displayRange - displaySeries.reduce((sum, { displayValue }) => sum + displayValue, 0)),
              },
            ]}
            padding={pieChartMargin}
            style={{
              data: {
                opacity: ({ datum: { key } }) => getOpacity(key),
              },
            }}
          />
          {maxValueValue && (
            <>
              <line
                // Draw a white background line to make sure we have enough respiration
                x1={maxLinePosX1}
                y1={maxLinePosY1}
                x2={maxLinePosX2}
                y2={maxLinePosY2}
                strokeWidth={remToPx(0.9)}
                stroke={theme.color.background.neutral.default}
              />
              <line
                // Draw a gray line to represent gauge max value
                x1={maxLinePosX1}
                y1={maxLinePosY1}
                x2={maxLinePosX2}
                y2={maxLinePosY2}
                strokeWidth={remToPx(0.3)}
                stroke={theme.color.border.dark}
                strokeLinecap="round"
              />
              <foreignObject
                // Place max value label
                x={maxLabelPosX}
                y={maxLabelPosY}
                width={Math.max(0, width - maxLabelPosX)}
                height={pieChartHeight / 20}
              >
                <div className={classes.maxContainer} style={{ fontSize: mainValueFontSize / 4 }}>
                  <Tooltip title={formatNumber(maxValueValue)}>
                    <p className={classnames(classes.text, classes.label)}>
                      {displayLabel(maxValueValue)}
                    </p>
                  </Tooltip>
                </div>
              </foreignObject>
            </>
          )}
          {minValueValue && (
            <foreignObject
              // Place min value label
              x={Math.max(0, width / 2 - pieChartHeight / 4 + pieChartMargin / 2 - innerRadius / 2 - minMaxLabelWidth / 2)}
              y={1.05 * origin.y}
              width={minMaxLabelWidth}
              height={pieChartHeight / 20}
            >
              <div
                className={classes.container}
                style={{
                  fontSize: mainValueFontSize / 4,
                }}
              >
                <Tooltip title={formatNumber(minValueValue)}>
                  <p className={classnames(classes.text, classes.label)}>
                    {displayLabel(minValueValue)}
                  </p>
                </Tooltip>
              </div>
            </foreignObject>
          )}
        </>

        <VictoryTooltip
          text={gaugeTotalValue}
          active={showGlobalTooltip}
          pointerLength={0}
          constrainToVisibleArea
          center={{ x: origin.x, y: undefined }}
          x={gaugeValueX}
          y={gaugeValueY}
          flyoutComponent={(
            <FlyoutWrapper>
              {({ x, y, center }) => (
                <ChartTooltipForVictory
                  chartWidth={width}
                  chartHeight={svgHeight}
                  renderTooltip={() => {
                    const tooltipData = getTooltipData(series, gaugeTotalValue);
                    return tooltipData ? renderTooltip(tooltipData) : null;
                  }}
                  x={x}
                  y={y}
                  center={center}
                />
              )}
            </FlyoutWrapper>
          )}
          // We explicitly wants to avoid the label component
          labelComponent={<NoLabel />}
          style={joinObjects(
            getFontStyle(theme.font.small),
            { lineHeight: undefined }
          )}
        />

        <foreignObject
          x={gaugeValueX}
          y={gaugeValueY}
          width={gaugeValueWidth}
          height={innerRadius / 2}
          onMouseEnter={() => setShowGlobalTooltip(true)}
          onMouseLeave={() => setShowGlobalTooltip(false)}
        >
          <div
            className={classes.container}
            style={{
              color: currentStep?.color,
              fontSize: mainValueFontSize,
            }}
          >
            <p className={classes.text}>
              {displayLabel(gaugeTotalValue)}
            </p>
          </div>
          {showGlobalTooltip && getTooltipData(series) && renderTooltip(getTooltipData(series) as ChartTooltipData<{ value: number }>)}
        </foreignObject>
      </VictoryContainer>
      {labels.length > 0 && (
        <ChartLegend
          labels={truncatedLabels}
          onLabelClick={(selectedKey) => {
            setDisplayKeyList((current) => {
              if (current.includes(selectedKey)) {
                return current.filter((key) => key !== selectedKey);
              } else {
                return current.length === series.length - 1 ? [] : [...current, selectedKey];
              }
            });
          }}
        />
      )}
    </>
  );
};

export default GaugeChart;
