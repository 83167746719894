import classnames from 'classnames';
import type { Property } from 'csstype';
import type { JssStyle } from 'jss';
import type { FunctionComponent } from 'react';
import { isWindows } from 'react-device-detect';
import type { Location, PathMatch } from 'react-router-dom';
import { matchPath, NavLink, useLocation } from 'react-router-dom';
import { joinObjects } from 'yooi-utils';
import base from '../../theme/base';
import fontDefinition from '../../theme/fontDefinition';
import makeStyles from '../../utils/makeStyles';
import useNavigation from '../../utils/useNavigation';
import type { IconName } from './Icon';
import Icon, { IconSizeVariant } from './Icon';
import Tooltip from './Tooltip';

export enum SidebarLinkVariants {
  normal = 'normal',
  logo = 'logo',
}

const buildVariantStyles = <VariantName extends SidebarLinkVariants | `${SidebarLinkVariants}Active`>(
  { variantName, normal, hover, pressed }: {
    variantName: VariantName,
    normal: { color: Property.Color, borderColor?: Property.Color, backgroundColor?: Property.Color },
    hover: { color?: Property.Color, borderColor?: Property.Color, backgroundColor?: Property.Color },
    pressed: { color?: Property.Color, borderColor?: Property.Color, backgroundColor?: Property.Color },
  }
): Record<VariantName | `${VariantName}_hover` | `${VariantName}_pressed`, JssStyle> => {
  const styles: JssStyle = joinObjects(
    {
      '&:hover': hover,
      '&:focus-visible': hover,
      '&:active': pressed,
    },
    normal
  );
  return {
    [variantName]: styles,
    [`${variantName}_hover`]: joinObjects(styles, hover),
    [`${variantName}_pressed`]: joinObjects(styles, pressed),
  } as Record<VariantName | `${VariantName}_hover` | `${VariantName}_pressed`, JssStyle>;
};

const useStyles = makeStyles((theme) => ({
  base: {
    position: 'relative',
    outline: 0,
    textDecoration: 'none',
    borderRadius: base.borderRadius.medium,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    marginTop: '0.6rem',
    marginBottom: '0.6rem',
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'auto',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '3rem', // + 0.2rem as border
    height: '3rem', // + 0.2rem as border
    minWidth: '3rem', // + 0.2rem as border
    minHeight: '3rem', // + 0.2rem as border
  },
  ...buildVariantStyles({
    variantName: SidebarLinkVariants.normal,
    normal: {
      color: theme.color.text.primary,
      borderColor: theme.color.transparent,
      backgroundColor: theme.color.transparent,
    },
    hover: {
      color: theme.color.text.brand,
      backgroundColor: theme.color.background.primarylight.hover,
    },
    pressed: {
      backgroundColor: theme.color.background.primarylight.pressed,
    },
  }),
  ...buildVariantStyles({
    variantName: `${SidebarLinkVariants.normal}Active`,
    normal: {
      color: theme.color.text.brand,
      backgroundColor: theme.color.background.primarylight.default,
    },
    hover: {
      backgroundColor: theme.color.background.primarylight.hover,
    },
    pressed: {
      backgroundColor: theme.color.background.primarylight.pressed,
    },
  }),
  ...buildVariantStyles({
    variantName: SidebarLinkVariants.logo,
    normal: {
      color: theme.color.text.primary,
      borderColor: theme.color.transparent,
      backgroundColor: theme.color.transparent,
    },
    hover: {
      color: theme.color.text.brand,
      backgroundColor: theme.color.background.primarylight.hover,
    },
    pressed: {
      backgroundColor: theme.color.background.primarylight.pressed,
    },
  }),
  ...buildVariantStyles({
    variantName: `${SidebarLinkVariants.logo}Active`,
    normal: {
      color: theme.color.text.brand,
      backgroundColor: theme.color.background.primarylight.default,
    },
    hover: {
      backgroundColor: theme.color.background.primarylight.hover,
    },
    pressed: {
      backgroundColor: theme.color.background.primarylight.pressed,
    },
  }),
  letter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: fontDefinition.display.fontFamily,
    fontStyle: fontDefinition.display.fontStyle,
    fontWeight: fontDefinition.display.fontWeight,
    fontSize: '1.6rem',
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chip: {
    position: 'absolute',
    width: '1rem',
    height: '1rem',
    borderRadius: base.borderRadius.large,
    top: 0,
    right: 0,
    background: theme.color.text.info,
  },
}), 'sidebarLink');

interface SidebarLinkProps {
  id: string,
  name: string,
  to: string,
  iconName?: IconName,
  image?: { url: string, alt: string, size: { width: number, height: number } },
  state?: undefined | 'hover' | 'pressed',
  variant?: SidebarLinkVariants,
  customMatch?: (match: PathMatch | null, location: Location) => boolean,
}

/**
 * The Sidebar link is only used to handle the left bar navigation.
 * It can be normal or active if the path match the current path or have a specific logo variant to handle the application logo.
 */
const SidebarLink: FunctionComponent<SidebarLinkProps> = ({ id, name, image, iconName, to, state, variant = SidebarLinkVariants.normal, customMatch }) => {
  const classes = useStyles();

  const location = useLocation();
  const navigation = useNavigation();

  let variantClassName: SidebarLinkVariants | `${SidebarLinkVariants}_hover` | `${SidebarLinkVariants}_pressed`;
  let activeClassName: `${SidebarLinkVariants}Active` | `${SidebarLinkVariants}Active_hover` | `${SidebarLinkVariants}Active_pressed`;
  switch (state) {
    case 'hover':
      variantClassName = `${variant}_hover`;
      activeClassName = `${variant}Active_hover`;
      break;
    case 'pressed':
      variantClassName = `${variant}_pressed`;
      activeClassName = `${variant}Active_pressed`;
      break;
    default:
      variantClassName = variant;
      activeClassName = `${variant}Active`;
  }

  const getLetters = () => {
    const [firstChar, secondChar, thirdChar] = [...name];
    if (!firstChar && !secondChar) {
      return '-';
    } else if (!secondChar) {
      return firstChar;
    } else if (firstChar.charCodeAt(0) > 32 && firstChar.charCodeAt(0) < 127) {
      if (secondChar.charCodeAt(0) > 32 && secondChar.charCodeAt(0) < 127) {
        return `${firstChar}${secondChar}`;
      } else {
        return firstChar;
      }
    } else if (isWindows && secondChar.charCodeAt(0) === 8205 /* Zero Width Joiner */ && thirdChar.charCodeAt(0) >= 127) {
      return `${firstChar}${secondChar}${thirdChar}`;
    } else {
      return firstChar;
    }
  };

  return (
    <Tooltip title={name}>
      <NavLink
        {...navigation.createNavigationPayload(id, { pathname: to }, { reset: true })}
        // eslint-disable-next-line yooi/check-classname-attribute
        className={({ isActive }) => {
          const match = customMatch ? customMatch(matchPath(location.pathname, to), location) : isActive;
          return classnames({
            [classes.base]: true,
            [classes[variantClassName]]: true,
            [classes[activeClassName]]: match,
          });
        }}
      >
        {image !== undefined ? (<span className={classes.image}><img src={image.url} alt={image.alt} width={image.size.width} height={image.size.height} /></span>) : null}
        {image === undefined && iconName !== undefined ? (<Icon name={iconName} size={IconSizeVariant.l} />) : null}
        {image === undefined && iconName === undefined ? (<span className={classes.letter}>{getLetters()}</span>) : null}
      </NavLink>
    </Tooltip>
  );
};

export default SidebarLink;
