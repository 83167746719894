import type { ObjectStore, ObjectStoreReadOnly, ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import { compareRank, extractAndCompareValue, filterNullOrUndefined, joinObjects, ranker } from 'yooi-utils';
import {
  Concept,
  ConceptRoleUserAssignation,
  ConceptRoleUserAssignation_Role_Concept,
  ConceptRoleUserAssignation_Role_ConceptRole,
  ConceptRoleUserAssignation_Role_User,
  Group_Users,
  GroupMembershipDimension,
} from '../conceptModule/ids';
import { getFieldUtilsHandler } from '../conceptModule/utils/fieldUtilsHandler';
import { getConceptUrl, getFirstIntentionStatus } from '../conceptModule/utils/utils';
import { isInstanceOf } from '../typeModule';
import { Instance_Of } from '../typeModule/ids';
import {
  Collaboration,
  Collaboration_Hash,
  Collaboration_Intention,
  Collaboration_Path,
  Collaboration_Status,
  CollaborationGroups,
  CollaborationGroups_Role_Collaboration,
  CollaborationGroups_Role_Group,
  CollaborationMessage,
  CollaborationMessage_Collaboration,
  CollaborationMessage_Text,
  CollaborationOnMultipleContext,
  CollaborationOnMultipleContext_Rank,
  CollaborationOnMultipleContext_Role_Collaboration,
  CollaborationOnMultipleContext_VarRoles_Contexts,
  CollaborationRoles,
  CollaborationRoles_Role_Collaboration,
  CollaborationRoles_Role_Role,
  CollaborationUsers,
  CollaborationUsers_Role_Collaboration,
  CollaborationUsers_Role_User,
  Intention_Name,
} from './ids';
import type { CollaborationStoreObject, IntentionStoreObject } from './modelTypes';

export const getCollaborationsForUserId = (store: ObjectStoreReadOnly, userId: string): CollaborationStoreObject[] => store.withAssociation(CollaborationUsers)
  .withExternalRole(CollaborationUsers_Role_Collaboration)
  .withRole(CollaborationUsers_Role_User, userId)
  .list()
  .map((collabUserAsso) => collabUserAsso.navigateRoleOrNull<CollaborationStoreObject>(CollaborationUsers_Role_Collaboration))
  .filter(filterNullOrUndefined);

export const getCollaborationsForGroup = (store: ObjectStoreReadOnly, groupId: string): CollaborationStoreObject[] => store.withAssociation(CollaborationGroups)
  .withExternalRole(CollaborationUsers_Role_Collaboration)
  .withRole(CollaborationGroups_Role_Group, groupId)
  .list()
  .map((collabForGroupAsso) => collabForGroupAsso.navigateRoleOrNull<CollaborationStoreObject>(CollaborationUsers_Role_Collaboration))
  .filter(filterNullOrUndefined);

export const getCollaborationsForRole = (store: ObjectStoreReadOnly, roleId: string): CollaborationStoreObject[] => store.withAssociation(CollaborationRoles)
  .withRole(CollaborationRoles_Role_Role, roleId)
  .list()
  .map((collabForRoleAsso) => collabForRoleAsso.navigateRoleOrNull<CollaborationStoreObject>(CollaborationRoles_Role_Collaboration))
  .filter(filterNullOrUndefined);

export const getCollaborationUserRecipientsIds = (store: ObjectStoreReadOnly, collaborationId: string): string[] => store.withAssociation(CollaborationUsers)
  .withRole(CollaborationUsers_Role_Collaboration, collaborationId)
  .withExternalRole(CollaborationUsers_Role_User)
  .list()
  .map((assoc) => assoc.role(CollaborationUsers_Role_User));

export const getCollaborationGroupRecipientsIds = (store: ObjectStoreReadOnly, collaborationId: string): string[] => store.withAssociation(CollaborationGroups)
  .withRole(CollaborationGroups_Role_Collaboration, collaborationId)
  .list()
  .map((assoc) => assoc.role(CollaborationGroups_Role_Group));

export const getCollaborationRoleRecipientsIds = (store: ObjectStoreReadOnly, collaborationId: string): string[] => store.withAssociation(CollaborationRoles)
  .withRole(CollaborationRoles_Role_Collaboration, collaborationId)
  .list()
  .map((assoc) => assoc.role(CollaborationRoles_Role_Role));

export const getCollaborationAllUserRecipientsIdsFromIds = (
  store: ObjectStoreReadOnly,
  userRecipientsIds: string[],
  groupRecipientsIds: string[],
  rolesRecipientsIds: string[],
  conceptId: string | undefined
): string[] => [
  ...userRecipientsIds,
  ...groupRecipientsIds
    .flatMap((groupId) => (getFieldUtilsHandler(store as ObjectStoreWithTimeseries, Group_Users)
      .getValueWithoutFormula({ [GroupMembershipDimension]: groupId }) as StoreObject[]).map((user) => user.id)),
  ...rolesRecipientsIds.flatMap((role) => (conceptId ? store.withAssociation(ConceptRoleUserAssignation)
    .withRole(ConceptRoleUserAssignation_Role_Concept, conceptId)
    .withRole(ConceptRoleUserAssignation_Role_ConceptRole, role)
    .list()
    .map((assignation) => assignation.role(ConceptRoleUserAssignation_Role_User)) : [])),
];

export const getCollaborationContextsObjectIds = (store: ObjectStoreReadOnly, collaborationId: string): string[] => (
  store.withAssociation(CollaborationOnMultipleContext)
    .withRole(CollaborationOnMultipleContext_Role_Collaboration, collaborationId)
    .list()
    .sort(extractAndCompareValue((({ object }) => object[CollaborationOnMultipleContext_Rank] as string), compareRank))
    .map((assoc) => assoc.varRole(CollaborationOnMultipleContext_VarRoles_Contexts, 0))
);

export const getCollaborationAllUserRecipientsIds = (
  store: ObjectStoreReadOnly,
  collaborationId: string
): string[] => {
  const collaborationContextsObjectIds = getCollaborationContextsObjectIds(store, collaborationId);
  const conceptId = collaborationContextsObjectIds?.find((objectId) => isInstanceOf(store.getObject(objectId), Concept));
  return getCollaborationAllUserRecipientsIdsFromIds(
    store,
    getCollaborationUserRecipientsIds(store, collaborationId),
    getCollaborationGroupRecipientsIds(store, collaborationId),
    getCollaborationRoleRecipientsIds(store, collaborationId),
    conceptId
  );
};

export const getIntentionLabel = (store: ObjectStoreReadOnly, collaborationId: string): string | undefined => {
  const intention = store.getObject(collaborationId).navigateOrNull<IntentionStoreObject>(Collaboration_Intention);
  return intention ? intention[Intention_Name] ?? 'Undefined' : 'Deleted intention';
};

export const isValidCollaborationPath = (store: ObjectStoreReadOnly, collaborationId: string): boolean => {
  if (!collaborationId) {
    return false;
  } else {
    const collaboration = store.getObjectOrNull(collaborationId);
    return collaboration?.[Collaboration_Path] != null;
  }
};

export const buildCollaborationLocation = (
  store: ObjectStoreReadOnly,
  collaborationId: string,
  messageId: string | undefined
): { pathname?: string, hash?: string, search?: string } => {
  const historyLocation = { search: `?collaboration=${collaborationId}${messageId ? `&message=${messageId}` : ''}` };
  if (isValidCollaborationPath(store, collaborationId)) {
    const collaboration = store.getObject(collaborationId);
    return joinObjects(
      historyLocation,
      {
        pathname: collaboration[Collaboration_Path] as string,
        hash: collaboration[Collaboration_Hash] as string,
      }
    );
  }

  const collaborationContextsObjectIds = getCollaborationContextsObjectIds(store, collaborationId);
  collaborationContextsObjectIds.reverse();
  for (let i = 0; i < collaborationContextsObjectIds.length; i += 1) {
    const objectId = collaborationContextsObjectIds[i];
    const object = store.getObjectOrNull(objectId);
    if (object && isInstanceOf(object, Concept)) {
      return joinObjects(historyLocation, { pathname: getConceptUrl(store, object.id) });
    }
  }

  return historyLocation;
};

export const buildCollaborationLink = (
  store: ObjectStoreReadOnly,
  collaborationId: string,
  messageId: string | undefined
): string => {
  const { pathname, hash, search } = buildCollaborationLocation(store, collaborationId, messageId);
  if (!pathname) {
    return '';
  } else {
    return `${pathname}${search ?? ''}${hash ?? ''}`;
  }
};

export const createCollaboration = ({ store, intention, path, hash, context, userRecipientsIds, groupRecipientsIds, roleRecipientsIds, text, collaborationId }: {
  store: ObjectStore,
  intention: {
    intentionId: string | undefined,
    statusId?: string,
  },
  text: string,
  context?: string[][],
  path?: string,
  hash?: string,
  userRecipientsIds?: string[],
  groupRecipientsIds?: string[],
  roleRecipientsIds?: string[],
  collaborationId?: string,
}): string => {
  const collaborationProperties = {
    [Instance_Of]: Collaboration,
    [Collaboration_Intention]: intention.intentionId,
    [Collaboration_Path]: path,
    [Collaboration_Hash]: hash,
    [Collaboration_Status]: intention.statusId ?? (intention.intentionId ? getFirstIntentionStatus(store, intention.intentionId) : undefined),
  };
  let createdCollaborationId: string;
  if (collaborationId) {
    createdCollaborationId = collaborationId;
    store.updateObject(createdCollaborationId, collaborationProperties);
  } else {
    createdCollaborationId = store.createObject(collaborationProperties);
  }

  const generateNextContextRank = ranker.createNextRankGenerator();
  context?.forEach((ctx) => {
    store.withAssociation(CollaborationOnMultipleContext)
      .withRole(CollaborationOnMultipleContext_Role_Collaboration, createdCollaborationId)
      .withVarRoles(CollaborationOnMultipleContext_VarRoles_Contexts, ctx)
      .updateObject({
        [CollaborationOnMultipleContext_Rank]: generateNextContextRank(),
      });
  });

  userRecipientsIds?.forEach((id) => {
    store.withAssociation(CollaborationUsers)
      .withRole(CollaborationUsers_Role_Collaboration, createdCollaborationId)
      .withRole(CollaborationUsers_Role_User, id)
      .updateObject({});
  });

  groupRecipientsIds?.forEach((id) => {
    store.withAssociation(CollaborationGroups)
      .withRole(CollaborationGroups_Role_Collaboration, createdCollaborationId)
      .withRole(CollaborationGroups_Role_Group, id)
      .updateObject({});
  });

  roleRecipientsIds?.forEach((id) => {
    store.withAssociation(CollaborationRoles)
      .withRole(CollaborationRoles_Role_Collaboration, createdCollaborationId)
      .withRole(CollaborationRoles_Role_Role, id)
      .updateObject({});
  });

  store.createObject({
    [Instance_Of]: CollaborationMessage,
    [CollaborationMessage_Collaboration]: createdCollaborationId,
    [CollaborationMessage_Text]: text,
  });

  return createdCollaborationId;
};
