import type { GaugeViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import { ViewType } from 'yooi-modules/modules/dashboardModule';
import { IconName } from '../../../../components/atoms/Icon';
import i18n from '../../../../utils/i18n';
import type { CommonViewResolvedDefinition } from '../common/commonViewResolvedDefinition';
import type { DimensionsDisplayOptionsFeatureResolvedDefinition } from '../common/dimensions/dimensionDisplayOptionsFeatureDefinition';
import { getViewDefinitionDimensionDisplayOption, getViewDefinitionDimensionsDisplayOptions } from '../common/dimensions/viewWithDimensionDisplayOptionsFeatureUtils';
import type { SeriesFeatureResolvedDefinition } from '../common/series/seriesFeatureDefinition';
import { getSeriesError, getViewDimensionsAsParameterDefinitions } from '../common/series/viewWithSeriesFeatureUtils';
import type { ValueRangeFeatureResolvedDefinition } from '../common/valueRange/valueRangeFeatureResolvedDefinition';
import type { ViewDefinitionHandler } from '../viewDsl';
import { registerView } from '../viewDsl';
import GaugeViewBlock from './GaugeViewBlock';
import GaugeViewDefinitionOptions from './GaugeViewDefinitionOptions';
import type { GaugeViewResolution } from './gaugeViewResolution';
import { resolveGaugeChartView } from './gaugeViewResolution';
import GaugeViewWidget from './GaugeViewWidget';

export interface GaugeViewResolvedDefinition extends CommonViewResolvedDefinition,
  DimensionsDisplayOptionsFeatureResolvedDefinition,
  ValueRangeFeatureResolvedDefinition,
  SeriesFeatureResolvedDefinition {
  type: ViewType.Gauge,
}

export type GaugeViewDefinitionHandler = ViewDefinitionHandler<GaugeViewStoredDefinition, GaugeViewResolvedDefinition, GaugeViewResolution>;

export const gaugeViewHandler = registerView<GaugeViewStoredDefinition, GaugeViewResolvedDefinition, GaugeViewResolution>({
  type: ViewType.Gauge,
  icon: IconName.speed,
  getLabel: () => i18n`Gauge Chart`,
  optionType: undefined,
  withFilters: true,
  withExport: false,
  resolveView: (store, viewDefinition, { viewDimensions, parametersMapping, filterConfiguration }) => (
    resolveGaugeChartView(store, viewDimensions, viewDefinition, parametersMapping, filterConfiguration)
  ),
  renderBlock: (_, viewDefinition, { viewDimensions, viewFilters, layoutParametersMapping, widgetDisplay }) => (
    <GaugeViewBlock
      viewDimensions={viewDimensions}
      viewFilters={viewFilters}
      viewDefinition={viewDefinition}
      parametersMapping={layoutParametersMapping}
      widgetDisplay={widgetDisplay}
    />
  ),
  renderWidget: (_, viewDefinition, { widgetId, viewDimensions, viewFilters, parametersMapping, parameterDefinitions, width, height }) => (
    <GaugeViewWidget
      widgetId={widgetId}
      viewDimensions={viewDimensions}
      viewFilters={viewFilters}
      viewDefinition={viewDefinition}
      parametersMapping={parametersMapping}
      parameterDefinitions={parameterDefinitions}
      width={width}
      height={height}
    />
  ),
  renderDefinitionOptions: (_, viewDefinition, { viewDimensions, updateViewDefinition, readOnly, parameterDefinitions }) => (
    <GaugeViewDefinitionOptions
      viewDimensions={viewDimensions}
      viewDefinition={viewDefinition}
      updateViewDefinition={updateViewDefinition}
      readOnly={readOnly}
      parameterDefinitions={parameterDefinitions}
    />
  ),
  getDefinitionErrors: (store, viewDefinition, viewDimensions, parameters) => {
    const error = getSeriesError(store, viewDefinition, [...parameters, ...getViewDimensionsAsParameterDefinitions(store, viewDimensions)], viewDefinition.type);
    return error !== undefined ? [error] : undefined;
  },
  resolveDefinition: (_, viewDefinition) => ({
    id: viewDefinition.id,
    type: viewDefinition.type,
    label: viewDefinition.label !== undefined && viewDefinition.label !== '' ? viewDefinition.label : i18n`Gauge Chart`,
    readOnly: viewDefinition.readOnly ?? false,
    getDimensionsDisplay: (viewDimensions) => getViewDefinitionDimensionsDisplayOptions(viewDefinition, viewDimensions),
    getDimensionDisplay: (viewDimension) => getViewDefinitionDimensionDisplayOption(viewDefinition, viewDimension),
    minValue: viewDefinition.minValue,
    rangeValues: viewDefinition.rangeValues ?? [],
    maxValue: viewDefinition.maxValue,
    series: viewDefinition.series ?? [],
  }),
  getInitialStoredDefinition: (id) => ({ id, type: ViewType.Gauge }),
});
