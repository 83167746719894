import type { FunctionComponent, ReactElement } from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { collectTypes } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { createAutoProvisioningMap, newError } from 'yooi-utils';
import useObjectDebugLabelLibrary from '../../../../store/useObjectDebugLabelLibrary';
import useStore from '../../../../store/useStore';

interface GetHintContextProviderProps {
  children: ReactElement | null,
}

export interface Context {
  (id: string): string | undefined,
}

const GetHintContext = createContext<Context | undefined>(undefined);

const GetHintContextProvider: FunctionComponent<GetHintContextProviderProps> = ({ children }) => {
  const store = useStore();
  const objectDebugLabelLibrary = useObjectDebugLabelLibrary();

  const storeSerial = store.getSerial();

  const getHint = useCallback((oid: string | string[]) => {
    const object = store.getObjectOrNull(oid);
    const types = [...collectTypes(store, object?.[Instance_Of] as string | undefined), '*'];
    for (let i = 0; i < types.length; i += 1) {
      const type = types[i];
      const hints = objectDebugLabelLibrary.object(type).map((computeHint) => computeHint(oid)).filter((r) => r).flat();
      if (hints.length > 0) {
        return hints[0];
      }
    }
    return undefined;
  }, [objectDebugLabelLibrary, store]);

  const hintMap = useMemo(
    () => createAutoProvisioningMap<string, string | undefined>(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storeSerial]
  );

  const innerGetHint = useCallback((id: string) => hintMap.getOrCreate(id, (hintId) => getHint(hintId)), [hintMap, getHint]);

  return (
    <GetHintContext.Provider value={innerGetHint}>
      {children}
    </GetHintContext.Provider>
  );
};

export const useExplorerHint = (): Context => {
  const getHintContext = useContext(GetHintContext);
  if (getHintContext === undefined) {
    throw newError('get hint is missing in context');
  } else {
    return getHintContext;
  }
};

export default GetHintContextProvider;
