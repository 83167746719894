import type { FunctionComponent } from 'react';
import base, { Opacity } from '../../theme/base';
import { colorWithAlpha, hexColorWithAlpha } from '../../theme/colorUtils';
import { Spacing } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import { remToPx } from '../../utils/sizeUtils';
import useTheme from '../../utils/useTheme';
import Tooltip from '../atoms/Tooltip';
import Typo from '../atoms/Typo';
import SpacedContainer from './SpacedContainer';

const labelToTimelineMargin = 1.2;
const groupLabelWidth = 2.6;

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    backgroundColor: theme.color.background.primary.default,
    borderRadius: base.borderRadius.medium,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    backgroundColor: colorWithAlpha(theme.color.background.primary.default, Opacity.ten),
    borderRadius: base.borderRadius.medium,
  },
}), 'groupPanel');

interface GroupPanelCoord {
  y1: number,
  width: number,
  height: number,
  label: string,
  color: string | undefined,
}

interface GroupPanelProps {
  panel: GroupPanelCoord,
  labelMargin: number,
}

const GroupPanel: FunctionComponent<GroupPanelProps> = ({ panel, labelMargin }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <>
      <foreignObject
        transform={`translate(0,${panel.y1 + panel.height}) rotate(-90)`}
        width={panel.height}
        height={`${groupLabelWidth}rem`}
      >
        <Tooltip title={panel.label}>
          <div
            className={classes.labelContainer}
            style={{
              // Using panel height for the width because the rotation inverse them
              width: panel.height,
              height: `${groupLabelWidth}rem`,
              backgroundColor: panel.color,
            }}
          >
            <SpacedContainer margin={{ x: Spacing.text }}>
              <Typo color={theme.color.text.white} maxLine={1}>{panel.label}</Typo>
            </SpacedContainer>
          </div>
        </Tooltip>
      </foreignObject>
      <foreignObject
        transform={`translate(${labelMargin ? remToPx(labelMargin) : 0},${panel.y1})`}
        width={panel.width + remToPx(labelToTimelineMargin)}
        height={panel.height}
      >
        <div
          className={classes.contentContainer}
          style={{
            width: panel.width + remToPx(labelToTimelineMargin),
            height: panel.height,
            backgroundColor: hexColorWithAlpha(panel.color, 0.1),
          }}
        />
      </foreignObject>
    </>
  );
};

export default GroupPanel;
