import classnames from 'classnames';
import type { Property } from 'csstype';
import type { FunctionComponent } from 'react';
import { CoverHeight, CoverMode, CoverPositionX, CoverPositionY } from 'yooi-utils';
import makeStyles from '../../utils/makeStyles';

const useStyles = makeStyles({
  containerSmall: {
    paddingBottom: '10rem',
  },
  containerMedium: {
    paddingBottom: '24rem',
  },
  containerHigh: {
    paddingBottom: '35rem',
  },
  modeFill: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  modeFit: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  modeTile: {
    backgroundRepeat: 'repeat',
  },
  xPositionLeft: {
    backgroundPositionX: 'left',
  },
  xPositionCenter: {
    backgroundPositionX: 'center',
  },
  xPositionRight: {
    backgroundPositionX: 'right',
  },
  yPositionTop: {
    backgroundPositionY: 'top',
  },
  yPositionCenter: {
    backgroundPositionY: 'center',
  },
  yPositionBottom: {
    backgroundPositionY: 'bottom',
  },
}, 'cover');

interface CoverProps {
  url: string,
  mode: CoverMode | undefined,
  positionX: CoverPositionX | undefined,
  positionY: CoverPositionY | undefined,
  height: CoverHeight | number | undefined,
  width?: number,
  cursor?: Property.Cursor,
  onClick?: () => void,
}

const Cover: FunctionComponent<CoverProps> = ({
  url,
  mode = CoverMode.Fill,
  positionX = CoverPositionX.Center,
  positionY = CoverPositionY.Center,
  height = CoverHeight.Medium,
  width,
  cursor,
  onClick,
}) => {
  const classes = useStyles();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={classnames({
        [classes.containerHigh]: height === CoverHeight.High,
        [classes.containerMedium]: height === CoverHeight.Medium,
        [classes.containerSmall]: height === CoverHeight.Small,
        [classes.modeFill]: mode === CoverMode.Fill,
        [classes.modeFit]: mode === CoverMode.Fit,
        [classes.modeTile]: mode === CoverMode.Tile,
        [classes.xPositionLeft]: positionX === CoverPositionX.Left,
        [classes.xPositionCenter]: positionX === CoverPositionX.Center,
        [classes.xPositionRight]: positionX === CoverPositionX.Right,
        [classes.yPositionTop]: positionY === CoverPositionY.Top,
        [classes.yPositionCenter]: positionY === CoverPositionY.Center,
        [classes.yPositionBottom]: positionY === CoverPositionY.Bottom,
      })}
      style={{
        backgroundImage: `url(${url})`,
        paddingBottom: typeof height === 'number' ? `${height}rem` : undefined,
        width: typeof width === 'number' ? `${width}rem` : undefined,
        cursor,
      }}
      onClick={onClick}
    />
  );
};

export default Cover;
