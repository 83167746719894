import type { FunctionComponent } from 'react';
import makeStyles from '../../../../../utils/makeStyles';
import type { AnchorPositionType, ValidLineType } from './types';

const computeStartingAnchorPosition = (xStart: number, yStart: number, xEnd: number): { xAnchor1: number, yAnchor1: number } => (
  { xAnchor1: xStart + (xEnd - xStart) / 2, yAnchor1: yStart }
);

const computeEndingAnchorPosition = (xStart: number, xEnd: number, yEnd: number): { xAnchor2: number, yAnchor2: number } => (
  { xAnchor2: xEnd - (xEnd - xStart) / 2, yAnchor2: yEnd }
);

const computePathString = ({ xStart, yStart, xAnchor1, yAnchor1, xAnchor2, yAnchor2, xEnd, yEnd, lineStyle }: {
  xStart: number, yStart: number,
  xAnchor1: number, yAnchor1: number, xAnchor2: number, yAnchor2: number,
  xEnd: number, yEnd: number,
  lineStyle: string,
}): string => {
  let linePath = `M${xStart},${yStart} `;
  if (['curve', 'angle'].includes(lineStyle)) {
    linePath += `${
      lineStyle === 'curve' ? 'C' : ''
    }${xAnchor1},${yAnchor1} ${xAnchor2},${yAnchor2} `;
  }
  linePath += `${xEnd},${yEnd}`;
  return linePath;
};

const useStyles = makeStyles({
  linePath: {
    fill: 'none',
  },
}, 'svgArrow');

interface SvgArrowProps {
  xStart: number,
  xEnd: number,
  yStart: number,
  yEnd: number,
  lineType: ValidLineType,
  markerOrientation: AnchorPositionType,
}

const getRotateByOrientation = (markerOrientation: AnchorPositionType, arrowThickness: number) => {
  switch (markerOrientation) {
    case 'right':
      return `rotate(180,0,${arrowThickness / 2})`;
    case 'left':
      return '';
    case 'bottom':
      return `rotate(-90,0,${arrowThickness / 2})`;
    case 'top':
      return `rotate(90,0,${arrowThickness / 2})`;
  }
};

const SvgArrow: FunctionComponent<SvgArrowProps> = ({ xStart, xEnd, yStart, yEnd, lineType, markerOrientation }) => {
  const classes = useStyles();

  // Starting position
  const { xAnchor1, yAnchor1 } = computeStartingAnchorPosition(xStart, yStart, xEnd);
  // Ending position
  const { xAnchor2, yAnchor2 } = computeEndingAnchorPosition(xStart, xEnd, yEnd);

  const pathString = computePathString({ xStart, yStart, xAnchor1, yAnchor1, xAnchor2, yAnchor2, xEnd, yEnd, lineStyle: lineType.lineStyle });
  const { strokeWidth, strokeColor, marker: { arrowThickness, arrowLength } } = lineType;

  return (
    <g>
      <path
        d={`M0,0 L0,${arrowThickness} L${arrowLength},${arrowThickness / 2} z`}
        transform={`translate(${xStart},${yStart - (arrowThickness / 2)})
        ${getRotateByOrientation(markerOrientation, arrowThickness)}`}
        fill={strokeColor}
      />
      <path className={classes.linePath} d={pathString} style={{ stroke: strokeColor, strokeWidth }} />
    </g>
  );
};

export default SvgArrow;
