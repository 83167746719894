import classnames from 'classnames';
import type { Property } from 'csstype';
import type { FunctionComponent, ReactNode } from 'react';
import makeStyles from '../../utils/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'default',
    outline: 'none',
    minHeight: '100%',
    backgroundColor: theme.color.transparent,
    '&:active,&:focus,&:focus-within': {
      outlineWidth: '0.1rem',
      outlineStyle: 'solid',
      outlineColor: theme.color.text.disabled,
    },
  },
  centeredFlex: {
    display: 'flex',
    alignItems: 'center',
  },
}), 'tableInnerCellContainer');

export enum TableInnerCellContainerVariants {
  centeredFlex = 'centeredFlex',
}

type Paddings =
  { padding: Property.Padding }
  | { paddingLeft?: Property.PaddingLeft, paddingRight?: Property.PaddingRight, paddingTop?: Property.PaddingTop, paddingBottom?: Property.PaddingBottom };

interface TableInnerCellContainerProps {
  padding?: Paddings,
  variant?: TableInnerCellContainerVariants,
  children?: ReactNode,
}

const TableInnerCellContainer: FunctionComponent<TableInnerCellContainerProps> = ({ padding, children, variant }) => {
  const classes = useStyles();

  return (
    <div
      style={{ ...padding }}
      className={classnames({
        [classes.container]: true,
        [classes.centeredFlex]: variant === TableInnerCellContainerVariants.centeredFlex,
      })}
      tabIndex={0}
      role="button"
    >
      {children}
    </div>
  );
};

export default TableInnerCellContainer;
