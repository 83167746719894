import type { ObjectStoreReadOnly, ObjectStoreWithTimeseries, TimeRange, TimeseriesEntry } from 'yooi-store';
import type { FormulaNode } from 'yooi-utils';
import { compareNumber, createFormulaEngine, filterNullOrUndefined, fromError, joinObjects, newError } from 'yooi-utils';
import { useFormulaV2 } from '../../../src/formulaFeatureFlag';
import { isInstanceOf } from '../../typeModule';
import type { TimeseriesNumberField } from '../fields/types';
import {
  Field_FieldDimensions,
  Field_Formula,
  Field_ParsedFormula,
  FieldDimension_IsMandatory,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  TimeseriesNumberField as TimeseriesNumberFieldId,
  TimeseriesNumberField_Cumulative,
} from '../ids';
import type { FieldDimensionStoreObject, FieldDimensionTypesStoreObject, FieldStoreObject, FormulaInput, TimeseriesNumberFieldStoreObject, ValueFormulaInput } from '../model';
import type { TimeseriesFormulaInput } from '../model/field';
import type { PathStep } from '../moduleType';
import { getFieldUtilsHandler } from './fieldUtilsHandler';
import { FormulaInputError } from './FormulaInputError';
import { buildInputSet, parseFormula } from './formulaUtils';
import type { DimensionsMapping } from './parametersUtils';
import { buildDimensionalId, dimensionsMappingFromDimensionalIds, dimensionsMappingToParametersMapping } from './parametersUtils';
import { isValidValuePathResolution, ValueResolutionType } from './path/common';
import { createValuePathResolver } from './path/pathResolver';
import type { ResolutionStack } from './resolutionStackUtils';
import { createResolutionStack } from './resolutionStackUtils';

interface ResolvedInput {
  type: FormulaInput['type'],
  isMatrix?: boolean,
  value?: unknown,
  isArrhythmicTimeseries?: boolean,
  isTimeseries?: boolean,
}

const { executeFormula: executeFormulaV1 } = createFormulaEngine();

export interface ParsedFormula {
  rootNode: FormulaNode<(name: string) => unknown>,
  execute: (dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack, dateRange?: TimeRange, isTimeseriesValue?: boolean) => unknown,
}

export const parseFormulaPropertyFunction = (objectStore: ObjectStoreWithTimeseries) => ([id]: string[]): ParsedFormula | undefined => {
  const field = objectStore.getObject<FieldStoreObject>(id);
  if (field[Field_Formula]) {
    const { formula, inputs } = field[Field_Formula];

    const parameters: { id: string, typeIds: string[] }[] = field.navigateBack<FieldDimensionStoreObject>(Field_FieldDimensions)
      .map((dimension) => ({
        id: dimension.id,
        typeIds: objectStore.withAssociation(FieldDimensionTypes)
          .withRole(FieldDimensionTypes_Role_FieldDimension, dimension.id)
          .withExternalRole(FieldDimensionTypes_Role_ConceptDefinition)
          .list<FieldDimensionTypesStoreObject>()
          .map((fieldDimensionTypes) => fieldDimensionTypes.role(FieldDimensionTypes_Role_ConceptDefinition)),
      }));

    const isCumulative = isInstanceOf<TimeseriesNumberFieldStoreObject>(field, TimeseriesNumberFieldId) ? field[TimeseriesNumberField_Cumulative] ?? false : false;

    const { inputSet, implicitTimeseriesInputNames } = buildInputSet(objectStore, parameters, inputs, isInstanceOf(field, TimeseriesNumberFieldId));

    const rootNode = parseFormula(formula, inputSet);
    const description = getFieldUtilsHandler(objectStore, id).describe();
    if (!description.hasData) {
      throw newError('Formula doesn\'t define return type', { formulaType: rootNode.type });
    } else if (
      !description.returnType.isAssignableFrom(rootNode.type)
      && (description.extraAcceptedTypes === undefined || !description.extraAcceptedTypes.some((type) => type.isAssignableFrom(rootNode.type)))
    ) {
      throw newError('Formula return type is not of the expected type', { formulaType: rootNode.type, expectedType: description.returnType });
    }

    const inputSets = new Set(rootNode.referencedInputNames.map((name) => name.toLowerCase()));
    const valueInputs = inputs
      .filter((entry): entry is { name: string, input: ValueFormulaInput } => (entry.input.type === 'value' && inputSets.has(entry.name.toLowerCase())));
    const timeseriesInputs = inputs
      .filter((entry): entry is { name: string, input: TimeseriesFormulaInput } => (
        entry.input.type === 'timeseries' && (inputSets.has(entry.name.toLowerCase()) || inputSets.has(`${entry.name.toLowerCase()}_i`))
      ));

    if (description.timeseriesMode !== 'explicit' && timeseriesInputs.length > 0) {
      throw newError('Timeseries inputs are not supported on non timeseries field');
    }

    const compute = rootNode.createCompute();

    const buildResolveValue = (dimensionsMapping: DimensionsMapping, resolutionStack: ResolutionStack | undefined, dateRange: TimeRange | undefined) => {
      const valuePathResolver = createValuePathResolver(objectStore, dimensionsMappingToParametersMapping(dimensionsMapping));

      return (
        inputName: string,
        path: PathStep[],
        requestTimeseries: boolean
      ): { type: ValueResolutionType.single, data: unknown } | { type: ValueResolutionType.multi, data: unknown[] } | undefined => {
        const resolution = requestTimeseries
          ? valuePathResolver.resolvePathTimeseries(path, dateRange, resolutionStack)
          : valuePathResolver.resolvePathValue(path, resolutionStack);
        if (resolution instanceof Error) {
          throw new FormulaInputError(inputName, { cause: resolution });
        } else if (!resolution) {
          return undefined;
        } else if (isValidValuePathResolution(resolution)) {
          if (resolution.type === ValueResolutionType.single) {
            return { type: resolution.type, data: resolution.value };
          } else {
            return { type: resolution.type, data: resolution.values };
          }
        } else {
          throw newError('Invalid value path resolution');
        }
      };
    };

    let execute: ParsedFormula['execute'];
    if (description.timeseriesMode === 'explicit') {
      execute = (dimensionsMapping, resolutionStack, dateRange, isTimeseriesValue) => {
        const resolveValue = buildResolveValue(dimensionsMapping, resolutionStack, dateRange);

        const resolvedValueInputs: Record<string, unknown> = {};
        for (let i = 0; i < valueInputs.length; i += 1) {
          const { name, input } = valueInputs[i];
          resolvedValueInputs[name.toLowerCase()] = resolveValue(name, input.path, false)?.data;
        }

        type TsValue = { time: number, value: unknown }[] | ({ time: number, value: unknown }[] | undefined)[] | undefined;
        let hasUnresolvedTimeseriesInput = false;
        const resolvedTimeseriesInputs: Record<string, TsValue> = {};
        for (let i = 0; i < timeseriesInputs.length; i += 1) {
          const { name, input } = timeseriesInputs[i];
          const resolution = resolveValue(name, input.path, isTimeseriesValue ?? false);
          if (
            resolution !== undefined
            && (
              (resolution.type === ValueResolutionType.single && resolution.data === undefined)
              || (resolution.type === ValueResolutionType.multi && resolution.data.some((entry) => entry === undefined))
            )
          ) {
            hasUnresolvedTimeseriesInput = true;
          }
          resolvedTimeseriesInputs[name.toLowerCase()] = resolution?.data as TsValue;
        }

        if (hasUnresolvedTimeseriesInput) {
          // We don't stop the timeseries input resolution as soon as possible to make sure they are all queued
          return undefined;
        }

        const times = Array.from(
          new Set(Object.values(resolvedTimeseriesInputs).flatMap((value) => (value ?? []).flat().filter(filterNullOrUndefined).map(({ time }) => time)))
        ).sort(compareNumber);

        const cumulatedInputValues: Record<string, number> = {};
        const previousImplicitInputValues: Record<string, number> = {};

        return times
          .map((executionInstant) => {
            const executionInputs: Record<string, unknown> = joinObjects(resolvedValueInputs, resolvedTimeseriesInputs);
            executionInputs.executioninstant_i = executionInstant;

            Object.entries(resolvedTimeseriesInputs)
              .forEach(([name, value]) => {
                if (value === undefined) {
                  return;
                }

                const isBiDimensionalArray = value.some((ts) => Array.isArray(ts));
                if (isBiDimensionalArray) {
                  executionInputs[`${name}_i`] = (value as ({ time: number, value: number | null }[] | undefined)[])
                    .map((v) => v?.find(({ time }) => time === executionInstant)?.value);
                } else {
                  const executionValue = (value as { time: number, value: number | null }[]).find(({ time }) => time === executionInstant)?.value;
                  if (typeof executionValue === 'number') {
                    if (isCumulative) {
                      const inputValue = executionValue + (cumulatedInputValues[`${name}_i`] ?? 0);
                      cumulatedInputValues[`${name}_i`] = inputValue;
                      executionInputs[`${name}_i`] = inputValue;
                    } else {
                      executionInputs[`${name}_i`] = executionValue;
                      previousImplicitInputValues[`${name}_i`] = executionValue;
                    }
                  } else if (isCumulative) {
                    executionInputs[`${name}_i`] = cumulatedInputValues[`${name}_i`] ?? 0;
                  } else if (executionValue === undefined && implicitTimeseriesInputNames.has(name)) {
                    executionInputs[`${name}_i`] = previousImplicitInputValues[`${name}_i`];
                  }
                }
              });

            const value = compute((name) => executionInputs[name.toLowerCase()]);

            // eslint-disable-next-line yooi/number-is-nan-call
            if (Number.isNaN(value)) {
              throw newError('Got a NaN value', { executionInstant });
            }

            return {
              time: executionInstant,
              value,
            };
          });
      };
    } else {
      execute = (dimensionsMapping, resolutionStack, dateRange) => {
        const resolveValue = buildResolveValue(dimensionsMapping, resolutionStack, dateRange);

        const resolvedInputs: Record<string, unknown> = {};
        for (let i = 0; i < valueInputs.length; i += 1) {
          const { name, input } = valueInputs[i];
          resolvedInputs[name.toLowerCase()] = resolveValue(name, input.path, false)?.data;
        }

        const value = compute((name) => resolvedInputs[name.toLowerCase()]);
        // eslint-disable-next-line yooi/number-is-nan-call
        if (Number.isNaN(value)) {
          throw newError('Got a NaN value');
        }
        return value;
      };
    }

    return {
      rootNode,
      execute,
    };
  } else {
    return undefined;
  }
};

const getComputedValueV1 = (
  store: ObjectStoreWithTimeseries,
  field: FieldStoreObject,
  dimensionsMapping: DimensionsMapping,
  resolutionStack?: ResolutionStack,
  dateRange?: TimeRange,
  isTimeseriesValue?: boolean
): unknown => {
  if (!field[Field_Formula]) {
    throw newError('getComputedValue: should not be called with a non computed field');
  }

  const valuePathResolver = createValuePathResolver(store, dimensionsMappingToParametersMapping(dimensionsMapping));
  const resolveInput = (name: string, input: FormulaInput): ResolvedInput => {
    const resolution = input.type === 'timeseries' && isTimeseriesValue
      ? valuePathResolver.resolvePathTimeseries(input.path, dateRange, resolutionStack)
      : valuePathResolver.resolvePathValue(input.path, resolutionStack);
    if (resolution instanceof Error) {
      throw new FormulaInputError(name, { cause: resolution });
    } else if (!resolution) {
      return {
        type: input.type,
        isMatrix: false,
        isArrhythmicTimeseries: false,
        isTimeseries: false,
        value: undefined,
      };
    } else if (isValidValuePathResolution(resolution)) {
      if (resolution.type === ValueResolutionType.single) {
        return {
          type: input.type,
          isMatrix: false,
          isArrhythmicTimeseries: resolution.isArrhythmicTimeseries,
          isTimeseries: resolution.isTimeseries,
          value: resolution.value,
        };
      } else {
        return {
          type: input.type,
          isMatrix: true,
          isArrhythmicTimeseries: resolution.isArrhythmicTimeseries,
          isTimeseries: resolution.isTimeseries,
          value: resolution.values.map((v) => [v]),
        };
      }
    } else {
      throw newError('Invalid value path resolution');
    }
  };

  const { formula, inputs } = field[Field_Formula];
  const resolvedInputs: Record<string, ResolvedInput> = Object.fromEntries(
    inputs.map(({ name, input }) => [name.toLowerCase(), resolveInput(name, input)])
  );

  if (isInstanceOf(field, TimeseriesNumberFieldId) || isTimeseriesValue) {
    let timeseriesNumberField: TimeseriesNumberField | undefined;
    if (isInstanceOf(field, TimeseriesNumberFieldId)) {
      // Because of a dependency cycle, we can't rely on timeseriesNumberFieldHandler
      const fieldHandler = getFieldUtilsHandler(store, field.id);
      timeseriesNumberField = fieldHandler.resolveConfigurationWithOverride(dimensionsMapping);
    }
    // Checking if all values in TimeSeries are loaded
    if (Object.values(resolvedInputs).some(({ isMatrix, isTimeseries, value }) => {
      if (isTimeseries) {
        if (isMatrix) {
          return (value as unknown[][]).some((x) => x.some((y) => y === undefined));
        } else {
          return value === undefined;
        }
      } else {
        return false;
      }
    })) {
      // we return undefined to display a loader
      return undefined;
    }

    const times = Array.from(
      new Set(
        Object.values(resolvedInputs)
          .filter(({ type }) => type === 'timeseries')
          .flatMap(({ isMatrix, value }) => {
            if (!Array.isArray(value)) {
              return undefined;
            } else if (isMatrix) {
              return (value as { time: number, value: number }[][][])?.flatMap((x) => x?.flatMap((y) => y?.map?.(({ time }) => time)));
            } else {
              return (value as { time: number, value: number }[])?.map(({ time }) => time);
            }
          })
          .filter(filterNullOrUndefined)
      )
    )
      .sort(compareNumber);

    let previousInputs: Record<string, ResolvedInput> = {};
    return times.map((currentTime) => {
      const timeInputs: Record<string, ResolvedInput> = Object
        .entries(resolvedInputs)
        .reduce<Record<string, ResolvedInput>>(
          (acc, [inputName, input]) => {
            if (input.type !== 'timeseries') {
              return joinObjects(acc, { [inputName]: input });
            } else if (!input.isMatrix) {
              const currentTimeValue = (input.value as { time: number, value: number }[])?.find(({ time }) => time === currentTime)?.value;
              let effectiveValue;
              if (currentTimeValue !== null) {
                if (timeseriesNumberField?.cumulative) {
                  effectiveValue = Number(currentTimeValue ?? 0) + Number(previousInputs[`${inputName}_i`]?.value ?? 0);
                } else if (input.isArrhythmicTimeseries) {
                  effectiveValue = currentTimeValue ?? previousInputs[`${inputName}_i`]?.value;
                } else {
                  effectiveValue = currentTimeValue;
                }
              }
              return joinObjects(
                acc,
                {
                  [inputName]: input,
                  [`${inputName}_i`]: joinObjects(
                    input,
                    { value: effectiveValue }
                  ),
                }
              );
            } else {
              return joinObjects(
                acc,
                {
                  [inputName]: input,
                  [`${inputName}_i`]: joinObjects(
                    input,
                    {
                      value: (input.value as TimeseriesEntry[][][])
                        .map((x, index) => x.flatMap((values) => {
                          const timeValue = (values !== null && Array.isArray(values)) ? values.find(({ time }) => time === currentTime)?.value : null;
                          if (input.isArrhythmicTimeseries) {
                            return timeValue !== null ? timeValue ?? (previousInputs[`${inputName}_i`]?.value as unknown[])?.[index] : undefined;
                          } else {
                            return timeValue ?? undefined;
                          }
                        })),
                    }
                  ),
                }
              );
            }
          },
          {}
        );
      timeInputs.executioninstant_i = { type: 'value', isTimeseries: false, isArrhythmicTimeseries: false, isMatrix: false, value: currentTime };

      previousInputs = timeInputs;
      return { time: currentTime, value: executeFormulaV1(formula, timeInputs) };
    });
  } else {
    return executeFormulaV1(formula, resolvedInputs);
  }
};

const getComputedValue = (
  store: ObjectStoreWithTimeseries,
  field: FieldStoreObject,
  dimensionsMapping: DimensionsMapping,
  resolutionStack?: ResolutionStack,
  dateRange?: TimeRange,
  isTimeseriesValue?: boolean
): unknown => {
  if (!field[Field_Formula]) {
    throw newError('getComputedValue: should not be called with a non computed field');
  }

  if (useFormulaV2()) {
    const parsedFormula = field[Field_ParsedFormula] as ParsedFormula | undefined;
    if (parsedFormula === undefined) {
      throw newError('Missing parsed formula');
    }
    return parsedFormula.execute(dimensionsMapping, resolutionStack, dateRange, isTimeseriesValue);
  } else {
    return getComputedValueV1(store, field, dimensionsMapping, resolutionStack, dateRange, isTimeseriesValue);
  }
};

export const getComputedPropertyId = (fieldId: string): string => `computed_${fieldId}`;

export const registerPropertyFunctionForTimeseriesComputedField = (objectStore: ObjectStoreWithTimeseries, fieldId: string): void => {
  const propertyIdForComputedValue = getComputedPropertyId(fieldId);
  const computeFunction = (id: string[]) => {
    const field = objectStore.getObject<FieldStoreObject>(fieldId);
    const dimensionsMapping = dimensionsMappingFromDimensionalIds(id);
    const valueForDateRange: Map<string, unknown> = new Map();
    return (dateRange?: TimeRange) => {
      const key = dateRange ? `${dateRange.from}:${dateRange.to}` : 'noDateRange';
      if (valueForDateRange.has(key)) {
        return valueForDateRange.get(key);
      } else {
        const val = getComputedValue(objectStore, field, dimensionsMapping, undefined, dateRange, true);
        valueForDateRange.set(key, val);
        return val;
      }
    };
  };
  objectStore.registerPropertyFunction(propertyIdForComputedValue, computeFunction);
};

export const registerPropertyFunctionForComputedField = (objectStore: ObjectStoreWithTimeseries, fieldId: string): void => {
  const propertyIdForComputedValue = getComputedPropertyId(fieldId);
  if (!objectStore.hasPropertyFunction(propertyIdForComputedValue)) {
    const computeFunction = (id: string[]) => {
      const field = objectStore.getObject<FieldStoreObject>(fieldId);
      const dimensionsMapping = dimensionsMappingFromDimensionalIds(id);
      return getComputedValue(objectStore, field, dimensionsMapping);
    };
    objectStore.registerPropertyFunction(propertyIdForComputedValue, computeFunction);
  }
};

export const unregisterPropertyFunctionForComputedField = (objectStore: ObjectStoreReadOnly, fieldId: string): void => {
  const propertyId = getComputedPropertyId(fieldId);
  if (objectStore.hasPropertyFunction(propertyId)) {
    objectStore.unregisterPropertyFunction(propertyId);
  }
};

export interface ValueResolution<T = unknown> {
  isComputed: boolean,
  isTimeseries: boolean,
  value: T,
  getDisplayValue: () => T,
  error?: Error,
}

export const isValueResolutionOfType = <T>(valueResolution: ValueResolution, isType: (value: unknown) => value is T): valueResolution is ValueResolution<T> => (
  !valueResolution.isComputed || isType(valueResolution.value)
);

export const resolveFieldValue = (
  store: ObjectStoreWithTimeseries,
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
  parentResolutionStack: ResolutionStack | undefined,
  dateRange?: TimeRange,
  isTimeseriesValue = false
): ValueResolution => {
  try {
    const field = store.getObject<FieldStoreObject>(fieldId);

    const resolutionStack = parentResolutionStack ? parentResolutionStack.fork() : createResolutionStack();

    return resolutionStack.ensureNonRecursiveResolution<ValueResolution>(dimensionsMapping, field.id, () => {
      if (
        field.navigateBack<FieldDimensionStoreObject>(Field_FieldDimensions)
          .some(({ id, [FieldDimension_IsMandatory]: isMandatory }) => isMandatory && !dimensionsMapping[id])
      ) {
        throw newError('Missing mandatory dimensions');
      }

      if (field[Field_Formula]) {
        const propertyFunctionId = getComputedPropertyId(field.id);
        if (store.hasPropertyFunction(propertyFunctionId)) {
          const cachedProperty = store.getObject(buildDimensionalId(dimensionsMapping), true)[getComputedPropertyId(field.id)];
          const value = isTimeseriesValue ? (cachedProperty as ((date?: TimeRange) => unknown))(dateRange) : cachedProperty;
          return {
            isTimeseries: isTimeseriesValue,
            isComputed: true,
            value,
            getDisplayValue: () => value,
          };
        } else {
          const value = getComputedValue(store, field, dimensionsMapping, resolutionStack, dateRange, isTimeseriesValue);
          return {
            isComputed: true,
            isTimeseries: isTimeseriesValue,
            value,
            getDisplayValue: () => value,
          };
        }
      } else {
        const fieldHandler = getFieldUtilsHandler(store, field.id);
        let value: unknown | undefined;
        let isTimeseries;
        if (isTimeseriesValue && fieldHandler?.getTimeseriesValueWithoutFormula) {
          isTimeseries = true;
          const instanceId = Object.values(dimensionsMapping).length === 1 ? Object.values(dimensionsMapping)[0] : undefined;
          value = instanceId ? fieldHandler?.getTimeseriesValueWithoutFormula?.(instanceId, dateRange) : undefined;
        } else {
          isTimeseries = isInstanceOf(field, TimeseriesNumberFieldId);
          value = fieldHandler?.getValueWithoutFormula?.(dimensionsMapping, resolutionStack);
        }
        return {
          isComputed: false,
          isTimeseries,
          value,
          getDisplayValue: () => value,
        };
      }
    });
  } catch (e) {
    return {
      value: undefined,
      getDisplayValue: () => undefined,
      isComputed: true, // should be the case ?
      isTimeseries: false,
      error: e instanceof Error ? e : fromError(e, 'Unknown error while resolving field value'),
    };
  }
};
