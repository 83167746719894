import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { CoverHeight, CoverMode, CoverPositionX, CoverPositionY, isNumber, joinObjects } from 'yooi-utils';
import StoreNumberPickerInput from '../../app/_global/input/StoreNumberPickerInput';
import type { Option, OptionRecord } from '../../app/_global/modelTypeUtils';
import { FontVariant } from '../../theme/fontDefinition';
import { buildMargins, Spacing, spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import useTheme from '../../utils/useTheme';
import Button, { ButtonVariant } from '../atoms/Button';
import Cover from '../atoms/Cover';
import { IconName } from '../atoms/Icon';
import Typo from '../atoms/Typo';
import EditableWithDropdown, { EditableCloseReasons } from '../molecules/EditableWithDropdown';
import ScreenModal from '../molecules/ScreenModal';
import SearchAndSelect from '../molecules/SearchAndSelect';
import SpacingLine from '../molecules/SpacingLine';
import UploadFileButton from './UploadFileButton';

const useStyles = makeStyles({
  image: {
    width: '100%',
    height: '100%',
    maxHeight: '80vh',
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: spacingRem.s,
    padding: spacingRem.s,
  },
  placeholderContainer: buildMargins({ x: Spacing.s }),
  coverContainer: {
    flexGrow: 1,
  },
  configuration: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gap: spacingRem.s,
    margin: spacingRem.s,
  },
}, 'coverPicker');

interface Value {
  image: { type: 'url', url: string } | { type: 'buffer', data: ArrayBuffer, contentType: string },
  mode: CoverMode | undefined,
  positionX: CoverPositionX | undefined,
  positionY: CoverPositionY | undefined,
  height: CoverHeight | number | undefined,
}

interface CoverPickerProps {
  value: Value | undefined,
  onChange: (newValue: Value | undefined) => void,
  onSubmit: (newValue: Value | undefined) => void,
  onCancel: () => void,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  isEditing?: boolean,
  readOnly?: boolean,

}

const CoverPicker: FunctionComponent<CoverPickerProps> = ({ value, onChange, onSubmit, onCancel, onEditionStart, onEditionStop, isEditing, readOnly }) => {
  const theme = useTheme();
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  let imageUrl: string | undefined;
  if (value && value.image.type === 'url') {
    imageUrl = value.image.url;
  } else if (value && value.image.type === 'buffer') {
    const blob = new Blob([value.image.data]);
    imageUrl = URL.createObjectURL(blob);
  }

  const renderSize = () => {
    const options: OptionRecord<CoverHeight | 'custom'> = {
      [CoverHeight.Small]: { id: CoverHeight.Small, label: i18n`Small` },
      [CoverHeight.Medium]: { id: CoverHeight.Medium, label: i18n`Medium` },
      [CoverHeight.High]: { id: CoverHeight.High, label: i18n`High` },
      custom: { id: 'custom', label: i18n`Custom` },
    };

    let selectedOption: Option<CoverHeight | 'custom'> = options[CoverHeight.Medium];
    if (typeof value?.height === 'number') {
      selectedOption = options.custom;
    } else if (value?.height !== undefined) {
      selectedOption = options[value.height];
    }

    const searchAndSelect = (
      <SearchAndSelect
        selectedOption={selectedOption}
        computeOptions={() => Object.values(options)}
        onSelect={(option) => {
          if (option && value) {
            if (option.id === 'custom') {
              onChange(joinObjects(value, {
                height: 10,
              }));
            } else {
              onChange(joinObjects(value, {
                height: option.id,
              }));
            }
          }
        }}
      />
    );

    if (typeof value?.height === 'number') {
      return (
        <SpacingLine>
          {searchAndSelect}
          <StoreNumberPickerInput
            initialValue={value.height}
            onSubmit={(newValue) => {
              const newValueNumber = typeof newValue === 'string' ? Number.parseFloat(newValue) : newValue;
              if (newValueNumber !== null && isNumber(newValueNumber) && newValueNumber > 0) {
                onChange(joinObjects(value, {
                  height: newValueNumber,
                }));
              }
            }}
            decimals={1}
            unit="rem"
          />
        </SpacingLine>
      );
    } else {
      return (
        <SpacingLine>
          {searchAndSelect}
        </SpacingLine>
      );
    }
  };

  const renderMode = () => {
    const options: OptionRecord<CoverMode> = {
      [CoverMode.Fill]: { id: CoverMode.Fill, label: i18n`Fill` },
      [CoverMode.Fit]: { id: CoverMode.Fit, label: i18n`Fit` },
      [CoverMode.Tile]: { id: CoverMode.Tile, label: i18n`Tile` },
    };

    return (
      <SearchAndSelect
        selectedOption={value?.mode === undefined ? options[CoverMode.Fill] : options[value.mode]}
        computeOptions={() => Object.values(options)}
        onSelect={(option) => {
          if (option && value) {
            onChange(joinObjects(value, {
              mode: option.id,
            }));
          }
        }}
      />
    );
  };

  const renderPositionX = () => {
    const options: OptionRecord<CoverPositionX> = {
      [CoverPositionX.Left]: { id: CoverPositionX.Left, label: i18n`Left` },
      [CoverPositionX.Center]: { id: CoverPositionX.Center, label: i18n`Center` },
      [CoverPositionX.Right]: { id: CoverPositionX.Right, label: i18n`Right` },
    };

    return (
      <SpacingLine>
        <SearchAndSelect
          selectedOption={value?.positionX !== undefined ? options[value.positionX] : options[CoverPositionX.Center]}
          computeOptions={() => Object.values(options)}
          onSelect={(option) => {
            if (option && value) {
              onChange(joinObjects(value, {
                positionX: option.id,
              }));
            }
          }}
        />
      </SpacingLine>
    );
  };

  const renderPositionY = () => {
    const options: OptionRecord<CoverPositionY> = {
      [CoverPositionY.Top]: { id: CoverPositionY.Top, label: i18n`Top` },
      [CoverPositionY.Center]: { id: CoverPositionY.Center, label: i18n`Center` },
      [CoverPositionY.Bottom]: { id: CoverPositionY.Bottom, label: i18n`Bottom` },
    };

    return (
      <SpacingLine>
        <SearchAndSelect
          selectedOption={value?.positionY !== undefined ? options[value.positionY] : options[CoverPositionY.Center]}
          computeOptions={() => Object.values(options)}
          onSelect={(option) => {
            if (option && value) {
              onChange(joinObjects(value, {
                positionY: option.id,
              }));
            }
          }}
        />
      </SpacingLine>
    );
  };

  return (
    <>
      <EditableWithDropdown
        showDropdown={showDropdown}
        openDropdown={() => {
          setShowDropdown(true);
          setOpenModal(false);
          if (!readOnly) {
            onEditionStart?.();
          }
        }}
        closeDropdown={(reason) => {
          if (!openModal) {
            setShowDropdown(false);
            if (!readOnly) {
              onEditionStop?.();
              if (reason === EditableCloseReasons.onEscapeKeyDown) {
                onCancel();
              } else {
                onSubmit(value);
              }
            }
          }
        }}
        renderValue={(inDropdown) => {
          if (!value || !imageUrl) {
            if (readOnly) {
              return null;
            } else {
              return (
                <div className={classes.placeholderContainer}>
                  <Typo maxLine={1} color={theme.color.text.disabled}>
                    {i18n`Add image`}
                  </Typo>
                </div>
              );
            }
          } else {
            return (
              <div className={classes.coverContainer}>
                <Cover
                  url={imageUrl}
                  mode={value.mode}
                  positionX={value.positionX}
                  positionY={value.positionY}
                  height={value.height}
                  cursor={inDropdown ? 'zoom-in' : undefined}
                  onClick={inDropdown ? () => setOpenModal(true) : undefined}
                />
              </div>
            );
          }
        }}
        renderDropdown={() => (
          <>
            <span className={classes.line}>
              <SpacingLine>
                <UploadFileButton
                  value={value?.image}
                  onChange={(newValue) => {
                    onChange({ image: newValue, mode: value?.mode, positionX: value?.positionX, positionY: value?.positionY, height: value?.height });
                  }}
                  typeRestriction={{ accept: 'image/*', isAccepted: (type) => type.startsWith('image/'), errorMessage: i18n`Invalid file format, only images are accepted` }}
                />
                {
                  !value
                    ? (<Typo variant={FontVariant.small} color={theme.color.text.secondary}>{i18n`The maximum size per file is 5 MB.`}</Typo>)
                    : null
                }
              </SpacingLine>
              <SpacingLine>
                {value ? (
                  <Button
                    onClick={() => onChange(undefined)}
                    variant={ButtonVariant.danger}
                    title={i18n`Delete`}
                    iconName={IconName.delete}
                  />
                ) : null}
              </SpacingLine>
            </span>
            {value ? (
              <span className={classes.configuration}>
                <SpacingLine>
                  <Typo color={theme.color.text.secondary}>{i18n`Height`}</Typo>
                </SpacingLine>
                {renderSize()}
                <SpacingLine>
                  <Typo color={theme.color.text.secondary}>{i18n`Fit`}</Typo>
                </SpacingLine>
                {renderMode()}
                <SpacingLine>
                  <Typo color={theme.color.text.secondary}>{i18n`Horizontal position`}</Typo>
                </SpacingLine>
                {renderPositionX()}
                <SpacingLine>
                  <Typo color={theme.color.text.secondary}>{i18n`Vertical position`}</Typo>
                </SpacingLine>
                {renderPositionY()}
              </span>
            ) : null}
          </>
        )}
        isEditing={isEditing}
        autoFocus
      />
      <ScreenModal
        open={openModal && !!imageUrl}
        isImageModal
        hide={() => setOpenModal(false)}
        onBackdropClick={() => setOpenModal(false)}
        render={() => (
          <img className={classes.image} alt="cover" src={imageUrl} />
        )}
      />
    </>
  );
};

export default CoverPicker;
