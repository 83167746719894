import type { FunctionComponent, ReactNode } from 'react';
import { useState } from 'react';
import type { AutomationActionDefinitionStoreObject, AutomationActionStoreObject, AutomationRuleStoreObject, TriggerType } from 'yooi-modules/modules/automationModule';
import {
  AutomationActionDefinition,
  AutomationActionEmail,
  AutomationActionGenerateData,
  AutomationRule,
  AutomationRule_Actions,
  AutomationRule_Description,
  AutomationRule_IsEnabled,
  AutomationRule_Name,
  AutomationRule_Trigger,
  AutomationRule_UserId,
} from 'yooi-modules/modules/automationModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { joinObjects } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import Tooltip from '../../../components/atoms/Tooltip';
import Typo from '../../../components/atoms/Typo';
import TextInputString from '../../../components/inputs/TextInputString';
import CompositeField, { CompositeFieldVariants } from '../../../components/molecules/CompositeField';
import ConfirmationModal, { ConfirmationModalVariant } from '../../../components/molecules/ConfirmationModal';
import SpacingLine from '../../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../../components/molecules/Table';
import TableInnerCellContainer, { TableInnerCellContainerVariants } from '../../../components/molecules/TableInnerCellContainer';
import TagContainer from '../../../components/molecules/TagContainer';
import Toggle from '../../../components/molecules/Toggle';
import ToggleButton, { ElementPosition } from '../../../components/molecules/ToggleButton';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../components/templates/BlockTitle';
import DataTable from '../../../components/templates/DataTable';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import { buildPadding, Spacing, spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { safeSessionStorageValue } from '../../../utils/sessionStorageUtils';
import useNavigation from '../../../utils/useNavigation';
import useNewLineFocus from '../../../utils/useNewLineFocus';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import useTheme from '../../../utils/useTheme';
import SearchTextButton from '../../_global/filter/SearchTextButton';
import { useFilterStorage } from '../../_global/filter/useFilterSessionStorage';
import StoreTextInputField from '../../_global/input/StoreTextInputField';
import { searchFilterFunction } from '../../_global/listFilterFunctions';
import { defaultOptionComparator, getAutomationRuleUrl, getUnknownChip } from '../../_global/modelTypeUtils';
import type { NavigationFilter } from '../../_global/navigationUtils';
import { getNavigationPayload } from '../../_global/navigationUtils';
import { share } from '../../_global/shareUtils';
import useFilterAndSort, { buildBooleanColumnComparatorHandler, buildStringColumnComparatorHandler } from '../../_global/useFilterAndSort';
import { actionTypesOptions, duplicateRule, getRuleTriggerDescription, triggerTypesOptions } from './automationUtils';

const useStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: spacingRem.s,
    paddingBottom: spacingRem.xs,
  },
  filtersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: spacingRem.s,
    justifyContent: 'flex-start',
    marginBottom: spacingRem.xs,
  },
  toggleContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacingRem.s,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: spacingRem.s,
    flexGrow: 1,
    alignItems: 'center',
  },
}, 'automationRuleList');

interface ConfirmationModalState {
  title: string,
  confirmLabel?: string,
  render?: () => ReactNode,
  variant?: ConfirmationModalVariant,
  callback: () => void,
}

export interface AutomationFilterConfiguration {
  nameSearch?: string,
  triggerFilters?: TriggerType[],
  actionFilters?: string[],
  activationFilters?: boolean[],
}

interface AutomationRuleListProps {
  title?: string,
  subtitle?: string,
  ruleIds: string[],
  onCreate: () => string,
  includeActionFilters?: boolean,
}

const AutomationRuleList: FunctionComponent<AutomationRuleListProps> = ({ title, subtitle, ruleIds, onCreate, includeActionFilters = false }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const navigation = useNavigation<NavigationFilter>();

  const [newLineFocus, setNewLineFocus] = useNewLineFocus();

  const filterId = AutomationRule;
  const [showFilters, setShowFilters] = useState(true);
  const [triggerFilters = [], setTriggerFilters] = useFilterStorage<'triggerFilters', AutomationFilterConfiguration>(filterId, 'triggerFilters');
  const [actionFilters = [], setActionFilters] = useFilterStorage<'actionFilters', AutomationFilterConfiguration>(filterId, 'actionFilters');
  const [activationFilters = [], setActivationFilters] = useFilterStorage<'activationFilters', AutomationFilterConfiguration>(filterId, 'activationFilters');
  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModalState | undefined>();

  let filterCount = 0;
  filterCount += (triggerFilters.length > 0 ? 1 : 0);
  filterCount += (includeActionFilters && actionFilters.length > 0 ? 1 : 0);
  filterCount += (activationFilters.length > 0 ? 1 : 0);

  const rules = ruleIds.map((ruleId) => store.getObject<AutomationRuleStoreObject>(ruleId))
    .map((rule) => ({
      key: rule.id,
      id: rule.id,
      name: rule[AutomationRule_Name],
      description: rule[AutomationRule_Description],
      enabled: Boolean(rule[AutomationRule_IsEnabled]),
      url: getAutomationRuleUrl(rule.id, !!rule[AutomationRule_UserId]),
      triggerType: rule[AutomationRule_Trigger]?.type,
      actionType: rule.navigateBack<AutomationActionStoreObject>(AutomationRule_Actions).at(0)?.[Instance_Of],
    }));

  const filterFunction = searchFilterFunction<typeof rules[0]>(store, safeSessionStorageValue<AutomationFilterConfiguration | undefined>(filterId)?.nameSearch, ['name']);

  const { generatePageList, doSort, sortCriteria, forceShowId } = useFilterAndSort(
    filterId,
    rules,
    (item) => (
      (filterFunction?.(item) ?? true)
      && (triggerFilters.length === 0 || (item.triggerType !== undefined && triggerFilters.includes(item.triggerType)))
      && (!includeActionFilters || actionFilters.length === 0 || (item.actionType !== undefined && actionFilters.includes(item.actionType)))
      && (activationFilters.length === 0 || activationFilters.includes(item.enabled))
    ),
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case 'name':
          case 'description':
          case 'url':
            return buildStringColumnComparatorHandler(key, direction);
          case 'enabled':
            return buildBooleanColumnComparatorHandler(key, direction);
          default:
            return undefined;
        }
      },
      initial: { key: 'name', direction: TableSortDirection.asc },
    }
  );

  const actions = (
    <div className={classes.headerContainer}>
      <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
        <SearchTextButton element={filterId} placeholder={i18n`Search`} />
        <ToggleButton
          active={showFilters}
          tooltip={i18n`Filters`}
          onClick={() => setShowFilters((current) => !current)}
          icon={IconName.filter_alt}
          count={filterCount > 0 ? filterCount : undefined}
        />
      </SizeContextProvider>
    </div>
  );

  const { list, pagination } = generatePageList(25);

  return (
    <VerticalBlock compact>
      {title !== undefined ? (<BlockTitle title={title} />) : null}
      {
        subtitle !== undefined
          ? (
            <BlockTitle
              title={(
                <SpacingLine>
                  <Typo color={theme.color.text.secondary} fullWidth>
                    {subtitle}
                  </Typo>
                </SpacingLine>
              )}
              rightActions={actions}
              variant={BlockTitleVariant.inline}
            />
          )
          : (
            <BlockContent padded>
              {actions}
            </BlockContent>
          )
      }
      {showFilters ? (
        <BlockContent padded>
          <div className={classes.filtersContainer}>
            <SizeContextProvider sizeVariant={SizeVariant.small}>
              <CompositeField
                dropdownMaxWidth="50rem"
                variant={CompositeFieldVariants.button}
                isSelected={triggerFilters.length > 0}
                headerLinesRenderers={[
                  {
                    id: 'title',
                    render: (inDropdown) => {
                      const label = triggerFilters.length > 0 ? i18n`Trigger (${triggerFilters.length})` : i18n`Trigger`;
                      return (
                        <div className={classes.titleContainer}>
                          <Tooltip title={label}>
                            <Typo maxLine={1}>{label}</Typo>
                          </Tooltip>
                          {triggerFilters.length > 0 && inDropdown ? (
                            <SizeContextProvider sizeVariant={SizeVariant.small}>
                              <IconOnlyButton
                                tooltip={i18n`Clear filters`}
                                variant={IconOnlyButtonVariants.secondary}
                                onClick={() => setTriggerFilters([])}
                                iconName={IconName.delete}
                              />
                            </SizeContextProvider>
                          ) : null}
                        </div>
                      );
                    },
                  },
                ]}
                getDropdownSectionDefinitions={() => [{
                  id: 'instances',
                  lines: [{
                    id: 'values',
                    render: (
                      <div className={classes.toggleContainer}>
                        <TagContainer>
                          {
                            Object.values(triggerTypesOptions)
                              .sort(defaultOptionComparator)
                              .map(({ id, label, icon }) => (
                                <Toggle
                                  key={id}
                                  text={label}
                                  icon={icon}
                                  onClick={() => {
                                    setTriggerFilters((current = []) => (
                                      current.includes(id) ? current.filter((i) => i !== id) : [...current, id]
                                    ));
                                  }}
                                  isActive={triggerFilters.includes(id)}
                                />
                              ))
                          }
                        </TagContainer>
                      </div>
                    ),
                  }],
                }]}
                onOpenDropdown={() => {}}
                onCloseDropdown={() => {}}
                flexGrow={0}
              />
              {includeActionFilters ? (
                <CompositeField
                  dropdownMaxWidth="50rem"
                  variant={CompositeFieldVariants.button}
                  isSelected={actionFilters.length > 0}
                  headerLinesRenderers={[
                    {
                      id: 'title',
                      render: (inDropdown) => {
                        const label = actionFilters.length > 0 ? i18n`Action (${actionFilters.length})` : i18n`Action`;
                        return (
                          <div className={classes.titleContainer}>
                            <Tooltip title={label}>
                              <Typo maxLine={1}>{label}</Typo>
                            </Tooltip>
                            {actionFilters.length > 0 && inDropdown ? (
                              <SizeContextProvider sizeVariant={SizeVariant.small}>
                                <IconOnlyButton
                                  tooltip={i18n`Clear filters`}
                                  variant={IconOnlyButtonVariants.secondary}
                                  onClick={() => setActionFilters([])}
                                  iconName={IconName.delete}
                                />
                              </SizeContextProvider>
                            ) : null}
                          </div>
                        );
                      },
                    },
                  ]}
                  getDropdownSectionDefinitions={() => [{
                    id: 'instances',
                    lines: [{
                      id: 'values',
                      render: (
                        <div className={classes.toggleContainer}>
                          <TagContainer>
                            {
                              store.getObject(AutomationActionDefinition)
                                .navigateBack<AutomationActionDefinitionStoreObject>(Class_Instances)
                                .map(({ id }) => actionTypesOptions[id] ?? getUnknownChip(id))
                                .sort(defaultOptionComparator)
                                .map(({ id, label, icon }) => (
                                  <Toggle
                                    key={id}
                                    text={label}
                                    icon={icon}
                                    onClick={() => {
                                      setActionFilters((current = []) => (
                                        current.includes(id) ? current.filter((i) => i !== id) : [...current, id]
                                      ));
                                    }}
                                    isActive={actionFilters.includes(id)}
                                  />
                                ))
                            }
                          </TagContainer>
                        </div>
                      ),
                    }],
                  }]}
                  onOpenDropdown={() => {}}
                  onCloseDropdown={() => {}}
                  flexGrow={0}
                />
              ) : null}
              <CompositeField
                dropdownMaxWidth="50rem"
                variant={CompositeFieldVariants.button}
                isSelected={activationFilters.length > 0}
                headerLinesRenderers={[
                  {
                    id: 'title',
                    render: (inDropdown) => {
                      const label = activationFilters.length > 0 ? i18n`Activation status (${activationFilters.length})` : i18n`Activation status`;
                      return (
                        <div className={classes.titleContainer}>
                          <Tooltip title={label}>
                            <Typo maxLine={1}>{label}</Typo>
                          </Tooltip>
                          {activationFilters.length > 0 && inDropdown ? (
                            <SizeContextProvider sizeVariant={SizeVariant.small}>
                              <IconOnlyButton
                                tooltip={i18n`Clear filters`}
                                variant={IconOnlyButtonVariants.secondary}
                                onClick={() => setActivationFilters([])}
                                iconName={IconName.delete}
                              />
                            </SizeContextProvider>
                          ) : null}
                        </div>
                      );
                    },
                  },
                ]}
                getDropdownSectionDefinitions={() => [{
                  id: 'instances',
                  lines: [{
                    id: 'values',
                    render: (
                      <div className={classes.toggleContainer}>
                        <TagContainer>
                          <Toggle
                            text={i18n`Enabled`}
                            icon={IconName.toggle_on}
                            onClick={() => {
                              setActivationFilters((current = []) => (
                                current.includes(true) ? current.filter((i) => i !== true) : [...current, true]
                              ));
                            }}
                            isActive={activationFilters.includes(true)}
                          />
                          <Toggle
                            text={i18n`Disabled`}
                            icon={IconName.toggle_off}
                            onClick={() => {
                              setActivationFilters((current = []) => (
                                current.includes(false) ? current.filter((i) => i !== false) : [...current, false]
                              ));
                            }}
                            isActive={activationFilters.includes(false)}
                          />
                        </TagContainer>
                      </div>
                    ),
                  }],
                }]}
                onOpenDropdown={() => {}}
                onCloseDropdown={() => {}}
                flexGrow={0}
              />
            </SizeContextProvider>
          </div>
        </BlockContent>
      ) : null}
      <BlockContent fullWidth hideOverflowX>
        <DataTable
          list={list}
          pagination={pagination}
          getNavigationPayload={(rule) => getNavigationPayload(navigation, rule.id, rule.url)}
          columnsDefinition={[
            {
              key: 'name',
              propertyId: 'name',
              name: i18n`Name`,
              sortable: true,
              focusable: true,
              width: 20,
              openButton: ({ url }) => Boolean(url),
              cellRender: ({ id, name }, focusOnMount) => (
                <StoreTextInputField
                  initialValue={name}
                  focusOnMount={focusOnMount}
                  onSubmit={(newName) => {
                    store.updateObject(id, { [AutomationRule_Name]: newName });
                  }}
                />
              ),
            },
            {
              key: 'description',
              propertyId: 'description',
              name: i18n`Description`,
              sortable: true,
              width: 30,
              cellRender: ({ id, description }) => (
                <StoreTextInputField
                  initialValue={description}
                  onSubmit={(newDescription) => {
                    store.updateObject(id, { [AutomationRule_Description]: newDescription });
                  }}
                />
              ),
            },
            {
              key: 'Trigger',
              propertyId: 'trigger',
              name: i18n`Trigger`,
              width: 25,
              cellRender: ({ id: ruleId }) => {
                const rule = store.getObject<AutomationRuleStoreObject>(ruleId);
                const trigger = rule[AutomationRule_Trigger];
                const triggerDescription = trigger?.label !== undefined && trigger.label !== '' ? trigger.label : getRuleTriggerDescription(store, ruleId);
                return (
                  <StoreTextInputField
                    initialValue={triggerDescription}
                    onSubmit={(newValue) => {
                      if (newValue !== null) {
                        store.updateObject<AutomationRuleStoreObject>(ruleId, { [AutomationRule_Trigger]: joinObjects(trigger, { label: newValue }) });
                      } else {
                        const newScheduledTrigger = { ...trigger };
                        delete newScheduledTrigger.label;
                        store.updateObject<AutomationRuleStoreObject>(ruleId, { [AutomationRule_Trigger]: newScheduledTrigger });
                      }
                    }}
                    readOnly
                  />
                );
              },
            },
            {
              key: 'action',
              propertyId: 'action',
              name: i18n`Action`,
              width: 25,
              cellRender: ({ id }) => {
                let actionLabel = '-';
                const rule = store.getObject(id);
                const action = rule.navigateBack(AutomationRule_Actions)[0];
                if (isInstanceOf(action, AutomationActionEmail)) {
                  actionLabel = i18n`Email`;
                } else if (isInstanceOf(action, AutomationActionGenerateData)) {
                  actionLabel = i18n`Generate data`;
                }
                return (<TextInputString value={actionLabel} readOnly />);
              },
            },
            {
              key: 'Enabled',
              propertyId: 'enabled',
              name: i18n`Status`,
              width: '12rem',
              sortable: true,
              cellRender: ({ id, enabled }) => (
                <TableInnerCellContainer variant={TableInnerCellContainerVariants.centeredFlex} padding={buildPadding({ x: Spacing.s })}>
                  <ToggleButton
                    name={enabled ? i18n`Enabled` : i18n`Disabled`}
                    icon={enabled ? IconName.toggle_on : IconName.toggle_off}
                    onClick={() => {
                      if (enabled) {
                        setConfirmationModal({
                          title: i18n`Are you sure you want to disable this automation ?`,
                          confirmLabel: i18n`Disable`,
                          variant: ConfirmationModalVariant.delete,
                          callback: () => store.updateObject(id, { [AutomationRule_IsEnabled]: false }),
                        });
                      } else {
                        setConfirmationModal({
                          title: i18n`Are you sure you want to enable this automation ?`,
                          confirmLabel: i18n`Enable`,
                          callback: () => store.updateObject(id, { [AutomationRule_IsEnabled]: true }),
                        });
                      }
                    }}
                    active={Boolean(enabled)}
                    type={ElementPosition.alone}
                  />
                </TableInnerCellContainer>
              ),
            },
          ]}
          doSort={doSort}
          sortCriteria={sortCriteria}
          newItemTitle={i18n`Create`}
          newItemIcon={IconName.add}
          onNewItem={() => {
            const ruleId = onCreate();
            forceShowId(ruleId);
            setNewLineFocus(ruleId);
          }}
          newLineFocus={newLineFocus.current}
          linesActions={({ id, url }) => [
            {
              key: 'share',
              icon: IconName.link,
              name: i18n`Copy link`,
              onClick: () => share(store, getNavigationPayload(navigation, id, url)),
            },
            {
              key: 'duplicate',
              name: i18n`Duplicate`,
              icon: IconName.content_copy_outline,
              onClick: () => {
                duplicateRule(store, id);
              },
            },
            {
              key: 'delete',
              name: i18n`Delete`,
              icon: IconName.delete,
              onClick: () => {
                setConfirmationModal({
                  title: i18n`Are you sure you want to delete this automation ?`,
                  confirmLabel: i18n`Delete`,
                  variant: ConfirmationModalVariant.delete,
                  callback: () => store.deleteObject(id),
                });
              },
              danger: true,
            },
          ]}
        />
      </BlockContent>
      {confirmationModal !== undefined ? (
        <ConfirmationModal
          variant={confirmationModal.variant}
          title={confirmationModal?.title}
          open
          onConfirm={() => {
            confirmationModal.callback();
            setConfirmationModal(undefined);
          }}
          confirmLabel={confirmationModal.confirmLabel}
          onCancel={() => setConfirmationModal(undefined)}
          render={() => confirmationModal.render?.() ?? null}
        />
      ) : null}
    </VerticalBlock>
  );
};

export default AutomationRuleList;
