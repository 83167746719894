import { createFieldDsl } from '../common/fields/FieldModuleDsl';
import { createModule } from '../common/types/TypeModuleDsl';
import * as ConceptLayoutModuleIds from './ids';
import { ConceptLayoutModuleId } from './ids';
import migrations from './migrations';

const moduleDsl = createModule({ label: 'ConceptLayout' }, ConceptLayoutModuleIds);
export const { registerField } = createFieldDsl(moduleDsl);

const { initTypedModule, ...registerModelDsl } = moduleDsl;
export const registerModel = registerModelDsl;

export enum LibraryDisplayOptions {
  table = 'table',
  cardList = 'cardList',
}

export const fieldDocumentationDisplayOptions = [
  { id: true, label: 'A separate line' },
  { id: false, label: 'Hover' },
];

export const initModule = initTypedModule(() => ({
  id: ConceptLayoutModuleId,
  migrations,
}));
