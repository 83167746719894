import type { FunctionComponent, ReactElement } from 'react';
import { Fragment } from 'react';
import { joinObjects } from 'yooi-utils';
import { getSpacing, Spacing, spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import { buildComponentPropertyStyleInRem, HierarchyVariant, SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import Icon, { IconColorVariant, IconName } from '../atoms/Icon';
import Tooltip from '../atoms/Tooltip';
import Typo from '../atoms/Typo';
import SpacingLine from './SpacingLine';

const useStyles = makeStyles({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'minmax(auto, 2.2rem) auto',
    rowGap: spacingRem.xs,
    columnGap: spacingRem.s,
    alignItems: 'start',
  },
  rightContainer: {
    display: 'grid',
    marginRight: getSpacing(Spacing.xs, 0.1),
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: getSpacing(Spacing.s, 0.1),
  },
  iconContainer: joinObjects(
    buildComponentPropertyStyleInRem('height', SizeVariant.main, HierarchyVariant.content),
    {
      justifyItems: 'center',
      alignItems: 'center',
      display: 'grid',
      gridTemplateColumns: '2.2rem',
    }
  ),
  cardContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: spacingRem.splus,
    paddingRight: spacingRem.splus,
    paddingBottom: spacingRem.s,
    paddingTop: spacingRem.s,
    flexGrow: 1,
  },
}, 'cardLine');

export interface CardLineDefinition {
  id: string,
  title: string,
  icon: IconName,
  render: () => ReactElement | null,
  documentation?: string,
}

interface CardLineProps {
  linesDefinition: CardLineDefinition[],
}

const CardLine: FunctionComponent<CardLineProps> = ({ linesDefinition }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.cardContentContainer}>
      <div className={classes.gridContainer}>
        {linesDefinition.map((line) => (
          <Fragment key={line.id}>
            <div className={classes.iconContainer}>
              <Icon name={line.icon} colorVariant={IconColorVariant.secondary} />
            </div>
            <span className={classes.rightContainer}>
              <span className={classes.titleContainer}>
                <SpacingLine>
                  <Tooltip title={line.title}><Typo maxLine={1} color={theme.color.text.secondary}>{line.title}</Typo></Tooltip>
                  {line.documentation ? (<Icon colorVariant={IconColorVariant.info} name={IconName.info} tooltip={line.documentation} />) : null}
                </SpacingLine>
              </span>
              {line.render()}
            </span>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default CardLine;
