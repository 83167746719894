import type { Modifier } from 'react-overlays/usePopper';

export const computeStylesNoGpu = {
  name: 'computeStyles',
  options: {
    adaptive: true,
    gpuAcceleration: false,
    roundOffsets: false,
  },
};
export const overlayMeasurements: Modifier<string, { [key: string]: unknown }> = {
  name: 'overlayMeasurements',
  enabled: true,
  phase: 'beforeRead',
  fn: ({ state }) => {
    // for overlay placement, ignore the reference height, used with Popper.js 'bottom-start' placement it permit to overlay the component behind
    Object.assign(state.rects.reference, { height: 0 });
  },
};
