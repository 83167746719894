import type { FunctionComponent } from 'react';
import { getConceptUrl, hasPlatformCapability } from 'yooi-modules/modules/conceptModule';
import { PlatformCapabilityAdmin } from 'yooi-modules/modules/conceptModule/ids';
import { ButtonVariant } from '../../components/atoms/Button';
import Icon, { IconColorVariant, IconName } from '../../components/atoms/Icon';
import Typo from '../../components/atoms/Typo';
import Link from '../../components/molecules/Link';
import OverflowMenu from '../../components/molecules/OverflowMenu';
import SpacingLine from '../../components/molecules/SpacingLine';
import BaseLayout from '../../components/templates/BaseLayout';
import BlockContent from '../../components/templates/BlockContent';
import BlockTitle from '../../components/templates/BlockTitle';
import Header from '../../components/templates/Header';
import VerticalBlock from '../../components/templates/VerticalBlock';
import useAuth from '../../store/useAuth';
import useStore from '../../store/useStore';
import { spacingRem } from '../../theme/spacingDefinition';
import buildInfo from '../../utils/buildInfo';
import { formatENDisplayDate } from '../../utils/dateUtilsFront';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import useNavigation from '../../utils/useNavigation';
import useTheme from '../../utils/useTheme';
import HeaderStatic from '../_global/HeaderStatic';
import TopBar from '../_global/topBar/TopBar';
import SettingButton from './SettingButton';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    display: 'inline-grid',
    gridTemplateColumns: 'repeat(auto-fit, 30rem)',
    gridTemplateRow: 'repeat(auto-fit, min-max(10rem, max-content))',
    columnGap: spacingRem.splus,
    rowGap: spacingRem.splus,
    gridColumnStart: 2,
    gridColumnEnd: 'last',
    paddingLeft: spacingRem.s,
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
  },
}), 'settingsHome');

const SettingsHome: FunctionComponent = () => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const { loggedUserId } = useAuth();
  const navigation = useNavigation();

  const hasPlatformCapabilityAdmin = hasPlatformCapability(store, loggedUserId, PlatformCapabilityAdmin);

  return (
    <BaseLayout
      topBar={(<TopBar />)}
      header={(
        <Header
          firstLine={(
            <HeaderStatic
              text={i18n`Settings`}
              actions={[
                (
                  <OverflowMenu
                    key="help"
                    title={i18n`Help`}
                    iconName={IconName.help}
                    menuItems={[
                      {
                        key: 'platform',
                        name: i18n`Platform documentation`,
                        icon: IconName.book_5,
                        onClick: () => {
                          window.open('https://help.yooi.com', '_blank', 'noopener');
                        },
                      },
                      {
                        key: 'formula',
                        name: i18n`Formula documentation`,
                        icon: IconName.function,
                        onClick: ({ isMiddleClick, isCtrlKeyPressed }) => {
                          const pathname = '/settings/formula-documentation';
                          if (isCtrlKeyPressed || isMiddleClick) {
                            window.open(pathname, '_blank', 'noopener');
                          } else {
                            navigation.push('formula', { pathname });
                          }
                        },
                      },
                    ]}
                    buttonVariant={ButtonVariant.tertiary}
                  />
                ),
                (
                  <OverflowMenu
                    key="extra"
                    menuItems={[{
                      key: 'explorer',
                      name: i18n`Explorer`,
                      icon: IconName.explore,
                      onClick: () => navigation.push('explorer', { pathname: '/settings/explorer' }),
                    }]}
                  />
                ),
              ]}
            />
          )}
        />
      )}
      content={(
        <VerticalBlock large>
          <VerticalBlock asBlockContent>
            <BlockTitle title={i18n`Personal`} />
            <span className={classes.gridContainer}>
              <SettingButton
                icon={IconName.person}
                name={i18n`Account`}
                description={i18n`Manage your personal information & login credentials.`}
                target={getConceptUrl(store, loggedUserId)}
              />
              <SettingButton
                icon={IconName.notifications}
                name={i18n`Notifications`}
                description={i18n`Customize how and when you receive updates and alerts from the application.`}
                target="/settings/notification"
              />
            </span>
          </VerticalBlock>
          {
            hasPlatformCapabilityAdmin
              ? (
                <>
                  <VerticalBlock asBlockContent>
                    <BlockTitle title={i18n`Model`} />
                    <span className={classes.gridContainer}>
                      <SettingButton
                        icon={IconName.account_tree}
                        name={i18n`Information model`}
                        description={i18n`Define and configure the concepts of the platform (fields, layout, user rights, workflows...)`}
                        target="/settings/organization"
                      />
                      <SettingButton
                        icon={IconName.route}
                        name={i18n`Workflows`}
                        description={i18n`Define and configure workflows that will be available across the platform.`}
                        target="/settings/workflow"
                      />
                      <SettingButton
                        icon={IconName.bolt}
                        name={i18n`Automation`}
                        description={i18n`Create rules and triggers to automate repetitive tasks and actions.`}
                        target="/settings/automation"
                      />
                    </span>
                  </VerticalBlock>
                  <VerticalBlock asBlockContent>
                    <BlockTitle title={i18n`Platform`} />
                    <BlockContent padded>
                      <SpacingLine>
                        <Icon name={IconName.info} colorVariant={IconColorVariant.info} />
                        <Typo color={theme.color.text.secondary}>{i18n`Version ${formatENDisplayDate(new Date(buildInfo.date))}`}</Typo>
                        <Link title={i18n`More information`} to="/settings/about" iconName={IconName.open_in_new} withoutSpacingLines />
                      </SpacingLine>
                    </BlockContent>
                    <span className={classes.gridContainer}>
                      <SettingButton
                        icon={IconName.auto_fix}
                        name={i18n`Customization`}
                        description={i18n`Personalize the appearance and behavior of the platform to suit your needs.`}
                        target="/settings/customization"
                      />
                      <SettingButton
                        icon={IconName.power}
                        name={i18n`Integration`}
                        description={i18n`Connect and sync with third-party applications and services.`}
                        target="/settings/integration"
                      />
                      <SettingButton
                        icon={IconName.comment}
                        name={i18n`Collaboration`}
                        description={i18n`Configure collaboration intentions and their availability across concepts.`}
                        target="/settings/collaboration"
                      />
                      <SettingButton
                        icon={IconName.groups}
                        name={i18n`Authorization`}
                        description={i18n`Manage users, groups and access permissions.`}
                        target="/settings/authorization"
                      />
                      <SettingButton
                        icon={IconName.security}
                        name={i18n`Security`}
                        description={i18n`Configure platform security-related behaviors.`}
                        target="/settings/security"
                      />
                    </span>
                  </VerticalBlock>
                </>
              )
              : null
          }
        </VerticalBlock>
      )}
    />
  );
};

export default SettingsHome;
