import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { getCollaborationContextsObjectIds } from 'yooi-modules/modules/collaborationModule';
import { CollaborationMessage_CreatedAt } from 'yooi-modules/modules/collaborationModule/ids';
import { associationFieldHandler, getCollaborationsForContext } from 'yooi-modules/modules/conceptModule';
import {
  Concept,
  ConceptRoleUserAssignation,
  ConceptRoleUserAssignation_Role_Concept,
  ConceptRoleUserAssignation_Role_ConceptRole,
  ConceptRoleUserAssignation_Role_User,
  User_Groups,
  UserGroupMembershipDimension,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareBoolean, compareNumber, compareProperty, comparing } from 'yooi-utils';
import Button from '../../../../../components/atoms/Button';
import Icon, { IconName, IconSizeVariant } from '../../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../../components/atoms/IconOnlyButton';
import Typo, { TypoAlign } from '../../../../../components/atoms/Typo';
import SearchAndSelect from '../../../../../components/molecules/SearchAndSelect';
import useAuth from '../../../../../store/useAuth';
import useStore from '../../../../../store/useStore';
import base from '../../../../../theme/base';
import { spacingRem } from '../../../../../theme/spacingDefinition';
import i18n from '../../../../../utils/i18n';
import makeStyles from '../../../../../utils/makeStyles';
import { useCollaborationPanelUpdater } from '../../../../../utils/useCollaborationContext';
import { SizeVariant } from '../../../../../utils/useSizeContext';
import useTheme from '../../../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../../../utils/useUsageContext';
import CollaborationBaseLayout from '../_global/CollaborationBaseLayout';
import usePanelObserver from '../usePanelObserver';
import { getCollaborationMessages, getCollaborationsUnreadMessageCount, getIntentions, isUserRecipient } from '../utils/collaborationUtils';
import CollaborationCard from './CollaborationCard';

const useStyles = makeStyles((theme) => ({
  emptyContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.m,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '30vh',
  },
  emptyMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacingRem.s,
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleLine: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: spacingRem.s,
    height: '3.2rem',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: spacingRem.s,
  },
  counter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: spacingRem.xs,
  },
  unreadIndicator: {
    display: 'flex',
    borderWidth: '0.4rem',
    borderStyle: 'solid',
    borderColor: theme.color.background.info.default,
    borderRadius: base.borderRadius.circle,
    width: 0,
  },
}), 'collaborationListPanel');

interface CollaborationListPanelProps {
  setPhaseState: (state: string) => void,
  phaseState: string,
}

const CollaborationListPanel: FunctionComponent<CollaborationListPanelProps> = ({ setPhaseState, phaseState }) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const { loggedUserId } = useAuth();

  const panel = useCollaborationPanelUpdater();
  const { fullContext, context } = usePanelObserver();

  const userGroupIds = associationFieldHandler(store, User_Groups)
    .getValueWithoutFormula({ [UserGroupMembershipDimension]: loggedUserId })
    .map((group) => group.id);

  const collaborations = getCollaborationsForContext(store, fullContext?.[fullContext.length - 1], true, phaseState)
    .map((collaboration) => {
      const unreadCount = getCollaborationsUnreadMessageCount(store, [collaboration], loggedUserId);
      const collaborationContextsObjectIds = getCollaborationContextsObjectIds(store, collaboration.id);
      const firstClassConceptId = collaborationContextsObjectIds?.find((objectId) => isInstanceOf(store.getObject(objectId), Concept));
      const userRoleIds = firstClassConceptId ? store.withAssociation(ConceptRoleUserAssignation)
        .withRole(ConceptRoleUserAssignation_Role_Concept, firstClassConceptId)
        .withRole(ConceptRoleUserAssignation_Role_User, loggedUserId)
        .list()
        .map((assignation) => assignation.role(ConceptRoleUserAssignation_Role_ConceptRole)) : [];
      return {
        collaboration,
        date: getCollaborationMessages(store, collaboration.id, true)[0][CollaborationMessage_CreatedAt] ?? 0,
        unreadCount,
        hasUnread: unreadCount > 0,
        isRecipient: isUserRecipient(store, collaboration.id, loggedUserId, userGroupIds, userRoleIds),
        conceptDefinitionId: firstClassConceptId ? store.getObjectOrNull(firstClassConceptId)?.[Instance_Of] : null,
      };
    });

  const forYouCollaborations = collaborations
    .filter(({ isRecipient, conceptDefinitionId }) => isRecipient && getIntentions(store, conceptDefinitionId as string ?? undefined).length > 0)
    .sort(comparing<typeof collaborations[0]>(compareProperty('unreadCount', compareBoolean)).thenComparing(compareProperty('date', compareNumber), true));
  const forYouCollaborationsUnreadCount = forYouCollaborations.filter(({ hasUnread }) => hasUnread).length;

  const otherCollaborations = collaborations
    .filter(({ isRecipient, conceptDefinitionId }) => getIntentions(store, conceptDefinitionId as string ?? undefined).length > 0 && !isRecipient)
    .sort(comparing<typeof collaborations[0]>(compareProperty('unreadCount', compareBoolean)).thenComparing(compareProperty('date', compareNumber), true));
  const otherCollaborationsUnreadCount = otherCollaborations.filter(({ hasUnread }) => hasUnread).length;

  const [isForYouCollapsed, setIsForYouCollapsed] = useState(false);
  const [isOthersCollapsed, setIsOthersCollapsed] = useState(false);

  const phaseOptions = [
    { id: 'ALL', label: i18n`All` },
    { id: 'OPEN', label: i18n`Open` },
    { id: 'CLOSED', label: i18n`Closed` },
  ];

  const getContent = () => {
    if (collaborations.length === 0) {
      return (
        <div className={classes.emptyContainer}>
          <Icon name={IconName.forum} size={IconSizeVariant.xxxl} color={theme.color.text.disabled} />
          <div className={classes.emptyMessageContainer}>
            <Typo color={theme.color.text.disabled}>{i18n`Start a conversation`}</Typo>
            <Typo color={theme.color.text.disabled} align={TypoAlign.center}>{i18n`Collaborate with your team\ndirectly in YOOI.`}</Typo>
          </div>
        </div>
      );
    } else if (forYouCollaborations.length === 0 && otherCollaborations.length === 0) {
      return (
        <div className={classes.emptyContainer}>
          <Icon name={IconName.forum} size={IconSizeVariant.xxxl} color={theme.color.text.disabled} />
          <div className={classes.emptyMessageContainer}>
            <Typo color={theme.color.text.disabled} align={TypoAlign.center}>{i18n`Collaboration is no longer accessible.`}</Typo>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className={classes.titleLine}>
            <div className={classes.title}>
              <Typo>{i18n`For you`}</Typo>
              {
                forYouCollaborationsUnreadCount > 0
                  ? (
                    <>
                      <span className={classes.counter}>
                        <Typo>{forYouCollaborationsUnreadCount}</Typo>
                        <Typo color={theme.color.text.secondary}>{`/ ${forYouCollaborations.length}`}</Typo>
                      </span>
                      <span className={classes.unreadIndicator} />
                    </>
                  )
                  : (
                    <Typo color={theme.color.text.secondary}>{forYouCollaborations.length}</Typo>
                  )
              }
            </div>
            {
              forYouCollaborations.length > 0
                ? (
                  <IconOnlyButton
                    tooltip={isForYouCollapsed ? i18n`Expand` : i18n`Collapse`}
                    variant={IconOnlyButtonVariants.tertiary}
                    iconName={isForYouCollapsed ? IconName.keyboard_arrow_left : IconName.expand_more}
                    onClick={() => setIsForYouCollapsed((current) => !current)}
                    sizeVariant={SizeVariant.small}
                  />
                )
                : null
            }
          </div>
          {
            isForYouCollapsed
              ? null
              : forYouCollaborations.map(({ collaboration }) => (<CollaborationCard key={collaboration.id} collaborationId={collaboration.id} />))
          }
          <div className={classes.titleLine}>
            <div className={classes.title}>
              <Typo>{i18n`Others`}</Typo>
              {
                otherCollaborationsUnreadCount > 0
                  ? (
                    <>
                      <span className={classes.counter}>
                        <Typo>{otherCollaborationsUnreadCount}</Typo>
                        <Typo color={theme.color.text.secondary}>{`/ ${otherCollaborations.length}`}</Typo>
                      </span>
                      <span className={classes.unreadIndicator} />
                    </>
                  )
                  : (
                    <Typo color={theme.color.text.secondary}>{otherCollaborations.length}</Typo>
                  )
              }
            </div>
            {
              otherCollaborations.length > 0
                ? (
                  <IconOnlyButton
                    tooltip={isOthersCollapsed ? i18n`Expand` : i18n`Collapse`}
                    variant={IconOnlyButtonVariants.tertiary}
                    iconName={isOthersCollapsed ? IconName.keyboard_arrow_left : IconName.expand_more}
                    onClick={() => setIsOthersCollapsed((current) => !current)}
                    sizeVariant={SizeVariant.small}
                  />
                )
                : null
            }
          </div>
          {
            isOthersCollapsed
              ? null
              : otherCollaborations.map(({ collaboration }) => (<CollaborationCard key={collaboration.id} collaborationId={collaboration.id} />))
          }
        </>
      );
    }
  };
  return (
    <CollaborationBaseLayout
      title={i18n`Collaborations`}
      headerActions={
        (fullContext?.length ?? 0) > 1
          ? [
            (
              <Button
                key="new"
                title={i18n`New`}
                iconName={IconName.add}
                onClick={() => panel.openCreation(context)}
              />
            ),
          ]
          : undefined
      }
      headerExtraLine={(
        <UsageContextProvider usageVariant={UsageVariant.inForm}>
          <Typo>{i18n`Show`}</Typo>
          <SearchAndSelect
            selectedOption={phaseOptions.find(({ id }) => id === phaseState)}
            onSelect={(phase) => {
              if (phase) {
                setPhaseState(phase.id);
              }
            }}
            computeOptions={() => phaseOptions}
          />
        </UsageContextProvider>
      )}
      contentBackgroundColor={theme.color.background.neutral.subtle}
      contentCompact
      content={getContent()}
    />
  );
};

export default CollaborationListPanel;
