import type { FunctionComponent, ReactElement } from 'react';
import HeaderTitleLine from '../../components/templates/HeaderTitleLine';

interface HeaderStaticProps {
  text: string,
  actions?: (ReactElement | null)[],
  newItem?: ReactElement | null,
}

const HeaderStatic: FunctionComponent<HeaderStaticProps> = ({ text, actions, newItem }) => (
  <HeaderTitleLine value={text} newItem={newItem} actions={actions} placeholder="" />
);

export default HeaderStatic;
