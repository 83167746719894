import type { FunctionComponent, ReactElement } from 'react';
import type { DateRange } from 'yooi-utils';
import { addTimeToDate, DateStorageTypeKeys, DurationType, formatForStorage, periodicities, PeriodicityType, subtractTimeFromDate } from 'yooi-utils';
import { Spacing } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useTheme from '../../../utils/useTheme';
import Typo from '../../atoms/Typo';
import SpacedContainer from '../../molecules/SpacedContainer';
import SpacingLine from '../../molecules/SpacingLine';
import ToggleButton from '../../molecules/ToggleButton';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',
  },
  label: {
    width: '7rem',
  },
}, 'dateRangePredefined');

export enum DateRangeOptions {
  lastAndCurrent = 'lastAndCurrent',
  currentAndRolling = 'currentAndRolling',
}

interface DateRangePredefinedProps {
  value?: DateRange,
  onSubmit: (value: DateRange) => void,
  withLastAndNext?: boolean,
  dateRangeOption?: DateRangeOptions,
}

const DateRangePredefined: FunctionComponent<DateRangePredefinedProps> = ({
  value,
  onSubmit,
  withLastAndNext,
  dateRangeOption = DateRangeOptions.currentAndRolling,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const { from: dateFromLocal, to: dateToLocal } = value || {};

  const isRollingMonthsActive = (periodicity: PeriodicityType, amount: number) => {
    if (withLastAndNext) {
      return value?.period === periodicity
        && value?.from?.type === DateStorageTypeKeys.last && value?.from?.value === amount
        && value?.to?.type === DateStorageTypeKeys.next && value?.to?.value === amount;
    } else {
      const expectedFrom = periodicities[periodicity].getPreviousDateInAmountOfPeriod(new Date(), amount);
      const expectedTo = periodicities[periodicity].getNextDateInAmountOfPeriod(new Date(), amount);
      // Subtract a millisecond to use the included end of period
      const filterTo = dateToLocal?.value ? subtractTimeFromDate(new Date(dateToLocal?.value), 1, DurationType.milliseconds) : undefined;
      return dateFromLocal?.value === expectedFrom.getTime() && filterTo?.getTime() === expectedTo.getTime();
    }
  };

  const setRollingDate = (periodicity: PeriodicityType, amount: number) => {
    if (withLastAndNext) {
      onSubmit({
        period: periodicity,
        from: { type: DateStorageTypeKeys.last, value: amount },
        to: { type: DateStorageTypeKeys.next, value: amount },
      });
    } else {
      const from = periodicities[periodicity].getPreviousDateInAmountOfPeriod(new Date(), amount);
      // Add a millisecond to store the excluded end of period
      const to = addTimeToDate(periodicities[periodicity].getNextDateInAmountOfPeriod(new Date(), amount), 1, DurationType.milliseconds);
      onSubmit({
        period: periodicity,
        from: { type: DateStorageTypeKeys.date, value: formatForStorage(from) ?? undefined },
        to: { type: DateStorageTypeKeys.date, value: formatForStorage(to) ?? undefined },
      });
    }
  };

  const isCurrentDatePeriodActive = (periodicityInput: PeriodicityType) => {
    if (withLastAndNext) {
      return value?.period === periodicityInput
        && value?.from?.type === DateStorageTypeKeys.last && value?.from?.value === 0
        && value?.to?.type === DateStorageTypeKeys.next && value?.to?.value === 0;
    } else {
      const expectedFrom = periodicities[periodicityInput].getStartOfPeriod(new Date());
      // Add a millisecond to store the excluded end of period
      const expectedTo = addTimeToDate(periodicities[periodicityInput].getEndOfPeriod(new Date()), 1, DurationType.milliseconds);
      // Subtract a millisecond to use the included end of period
      return dateFromLocal?.value === expectedFrom?.getTime() && dateToLocal?.value === expectedTo.getTime();
    }
  };

  const setCurrentDatePeriod = (periodicityInput: PeriodicityType) => {
    if (withLastAndNext) {
      onSubmit({
        period: periodicityInput,
        from: { type: DateStorageTypeKeys.last, value: 0 },
        to: { type: DateStorageTypeKeys.next, value: 0 },
      });
    } else {
      const from = periodicities[periodicityInput].getStartOfPeriod(new Date());
      // Add a millisecond to store the excluded end of period
      const to = addTimeToDate(periodicities[periodicityInput].getEndOfPeriod(new Date()), 1, DurationType.milliseconds);
      onSubmit({
        period: PeriodicityType.month,
        from: { type: DateStorageTypeKeys.date, value: formatForStorage(from) ?? undefined },
        to: { type: DateStorageTypeKeys.date, value: formatForStorage(to) ?? undefined },
      });
    }
  };

  const isLastPeriodicityActive = (periodicity: PeriodicityType) => {
    if (withLastAndNext) {
      return value?.period === periodicity
        && value?.from?.type === DateStorageTypeKeys.last && value?.from?.value === 1
        && value?.to?.type === DateStorageTypeKeys.last && value?.to?.value === 1;
    } else {
      const expectedFrom = periodicities[periodicity].getPreviousDateInAmountOfPeriod(new Date(), 1);
      // Add a millisecond to store the excluded end of period
      const expectedTo = expectedFrom ? addTimeToDate(periodicities[periodicity].getEndOfPeriod(expectedFrom), 1, DurationType.milliseconds) : undefined;
      return dateFromLocal?.value === expectedFrom?.getTime() && dateToLocal?.value === expectedTo?.getTime();
    }
  };

  const setLastPeriodicity = (periodicity: PeriodicityType) => {
    if (withLastAndNext) {
      onSubmit({
        period: periodicity,
        from: { type: DateStorageTypeKeys.last, value: 1 },
        to: { type: DateStorageTypeKeys.last, value: 1 },
      });
    } else {
      const from = periodicities[periodicity].getPreviousDateInAmountOfPeriod(new Date(), 1);
      // Add a millisecond to store the excluded end of period
      const endOfLastPeriod = periodicities[periodicity].getNextDateInAmountOfPeriod(new Date(), -1);
      const to = endOfLastPeriod ? addTimeToDate(endOfLastPeriod, 1, DurationType.milliseconds) : undefined;
      onSubmit({
        period: PeriodicityType.month,
        from: { type: DateStorageTypeKeys.date, value: formatForStorage(from) ?? undefined },
        to: { type: DateStorageTypeKeys.date, value: formatForStorage(to) ?? undefined },
      });
    }
  };

  const renderPeriodicityToggles = (label: string, periodicity: PeriodicityType, rollingPeriodicity: PeriodicityType, rollingAmount: number, isLast = false): ReactElement => (
    <SpacedContainer margin={!isLast ? { bottom: Spacing.s } : undefined}>
      <SpacingLine>
        <div className={classes.label}>
          <Typo color={theme.color.text.secondary}>{label}</Typo>
        </div>
        <ToggleButton
          name={i18n`Current`}
          active={isCurrentDatePeriodActive(periodicity)}
          onClick={() => setCurrentDatePeriod(periodicity)}
        />
        {dateRangeOption === DateRangeOptions.currentAndRolling
          ? (
            <ToggleButton
              name={i18n`Rolling`}
              active={isRollingMonthsActive(rollingPeriodicity, rollingAmount)}
              onClick={() => setRollingDate(rollingPeriodicity, rollingAmount)}
            />
          )
          : (
            <ToggleButton
              name={i18n`Last`}
              active={isLastPeriodicityActive(periodicity)}
              onClick={() => setLastPeriodicity(periodicity)}
            />
          )}
      </SpacingLine>
    </SpacedContainer>
  );

  return (
    <div className={classes.container}>
      {renderPeriodicityToggles(i18n`Month`, PeriodicityType.month, PeriodicityType.day, 15)}
      {renderPeriodicityToggles(i18n`Quarter`, PeriodicityType.quarter, PeriodicityType.month, 1)}
      {renderPeriodicityToggles(i18n`Year`, PeriodicityType.year, PeriodicityType.month, 6, true)}
    </div>
  );
};

export default DateRangePredefined;
