import { useCallback } from 'react';
import type { AssociationFilterStoreObject, ConditionFilterStoreObject, Filters } from 'yooi-modules/modules/conceptModule';
import { joinObjects } from 'yooi-utils';
import declareBus from '../../../utils/declareBus';
import useForceUpdate from '../../../utils/useForceUpdate';
import { useSessionStorageState } from '../../../utils/useSessionStorage';

export enum FilterElement {
  subscriptionFilters = 'subscriptionFilters',
  subscriptionsInstancesFilters = 'subscriptionsInstancesFilters',
  subscriptionsAuthorFilters = 'subscriptionsAuthorFilters',
  subscriptionsIntentionFilters = 'subscriptionsIntentionFilters',
}

export enum FilterParams {
  nameSearch = 'nameSearch',
  filters = 'filters',
}

export interface ViewFilters {
  filterKey: string,
  hasFilters?: boolean,
  getViewFilters: () => (AssociationFilterStoreObject | ConditionFilterStoreObject)[],
}

export interface FilterConfiguration {
  nameSearch?: string,
  filters?: Record<string, Filters>,
}

interface FilterSessionStorageEvent {
  element: string,
  key: string | undefined,
}

const useFilterSessionStorageBus = declareBus<FilterSessionStorageEvent>('filterSessionStorage').useBus;

const useFilterStorageChange = (callback?: (event: FilterSessionStorageEvent) => void): (element: string, key?: string) => void => {
  const dispatchUpdated = useFilterSessionStorageBus(callback);
  return (element, key) => dispatchUpdated({ element, key });
};

export const useOnFilterChanged = (element: string, changeCallback: () => void): void => {
  const callback = useCallback(({ element: elementChanged }: FilterSessionStorageEvent) => {
    if (elementChanged === element) {
      changeCallback();
    }
  }, [element, changeCallback]);
  useFilterStorageChange(callback);
};

type Set<T> = (funOrValue: T | ((current: T | undefined) => T)) => void;

export const useFilterStorage = <Key extends keyof Configuration, Configuration = FilterConfiguration>(
  element: string, key: Key
): [Configuration[Key] | undefined, Set<Configuration[Key]>, () => void] => {
  const forceUpdate = useForceUpdate();

  const callback = useCallback(({ element: elementChanged, key: keyChanged }: FilterSessionStorageEvent) => {
    if (elementChanged === element) {
      if (!keyChanged) {
        forceUpdate();
      }
      if (key) {
        if (keyChanged === key) {
          forceUpdate();
        }
      }
    }
  }, [element, forceUpdate, key]);

  const notify = useFilterStorageChange(callback);

  const [sessionStorageElementItem, setSessionStorageElementItem] = useSessionStorageState<Partial<Configuration> | undefined>(element, undefined, false);
  const sessionStorageItem = sessionStorageElementItem?.[key];

  const setSessionStorageItem: Set<Configuration[Key]> = (funOrValue) => {
    if (!element || !key) {
      return;
    }

    const value = funOrValue instanceof Function ? funOrValue(sessionStorageElementItem?.[key]) : funOrValue;

    setSessionStorageElementItem(joinObjects(sessionStorageElementItem, { [key]: value }) as unknown as Partial<Configuration>);
    notify(element, key as string);
  };

  const clearSessionStorageItem = () => {
    if (!element || !key) {
      return;
    }

    if (sessionStorageElementItem) {
      delete sessionStorageElementItem[key];
      setSessionStorageElementItem(sessionStorageElementItem);
      notify(element, key as string);
    }
  };

  return [sessionStorageItem, setSessionStorageItem, clearSessionStorageItem];
};

export const useResetFilterStorage = (key: string): () => void => {
  const [, , , clearFilters] = useSessionStorageState(key, undefined);
  const notify = useFilterStorageChange();

  return useCallback(() => {
    clearFilters();
    notify(key);
  }, [clearFilters, key, notify]);
};
