import { v4 as uuid } from 'uuid';
import type { Migration } from 'yooi-store';
import { joinObjects } from 'yooi-utils';
import { MigrationAutomationFixDuplicatedIds } from '../../src/Migrations';
import { isInstanceOf } from '../typeModule';
import { Class_Instances, TypeModuleId } from '../typeModule/ids';
import { AutomationActionGenerateData, AutomationActionGenerateData_Operations, AutomationRule, AutomationRule_Actions } from './ids';
import type { AutomationActionGenerateDataStoreObject } from './modelTypes';

const migrations: Record<number, Migration> = {
  [MigrationAutomationFixDuplicatedIds]: {
    dependencies: [TypeModuleId],
    handler: (objectStore) => {
      objectStore.getObject(AutomationRule).navigateBack(Class_Instances).forEach((rule) => {
        const action = rule.navigateBack(AutomationRule_Actions)[0];
        if (isInstanceOf<AutomationActionGenerateDataStoreObject>(action, AutomationActionGenerateData)) {
          const instanceOperations = action[AutomationActionGenerateData_Operations];
          if (instanceOperations) {
            let hasUpdate = false;
            const duplicatedInstanceOperationsIds = new Set(instanceOperations.map(({ id }) => id).filter((item, index, self) => self.indexOf(item) !== index));
            if (duplicatedInstanceOperationsIds.size > 0) {
              hasUpdate = true;
            }
            const newInstanceOperations = instanceOperations.map((instanceOperation) => {
              const duplicatedFieldOperationsIds = new Set(instanceOperation.fieldOperations?.map(({ id }) => id).filter((item, index, self) => self.indexOf(item) !== index));
              if (duplicatedFieldOperationsIds.size > 0) {
                hasUpdate = true;
              }
              return (joinObjects(
                instanceOperation,
                {
                  fieldOperations: instanceOperation.fieldOperations?.map((fieldOperation) => (
                    joinObjects(fieldOperation, { id: duplicatedFieldOperationsIds.has(fieldOperation.id) ? uuid() : fieldOperation.id }))),
                  id: duplicatedInstanceOperationsIds.has(instanceOperation.id) ? uuid() : instanceOperation.id,
                }
              ));
            });
            if (hasUpdate) {
              objectStore.updateObject(action.id, {
                [AutomationActionGenerateData_Operations]: newInstanceOperations,
              });
            }
          }
        }
      });
    },
  },
};
export default migrations;
