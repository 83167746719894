import type { FunctionComponent } from 'react';
import type { PeriodicityType } from 'yooi-utils';
import { isDateValid, isFiniteNumber, periodicities } from 'yooi-utils';
import InCompositeInput from '../../../app/_global/input/InCompositeInput';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useDerivedState from '../../../utils/useDerivedState';
import NumberPicker from '../NumberPicker';
import { DatePickerStrategy } from './datePickerUtils';

const useStyles = makeStyles({
  inputSpacings: {
    display: 'flex',
  },
}, 'yearPicker');

interface YearPickerProps {
  value: Date | undefined,
  onSubmit: (date: Date | undefined) => void,
  strategy?: DatePickerStrategy,
  periodicity?: PeriodicityType,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
}

const YearPicker: FunctionComponent<YearPickerProps> = ({ value, onSubmit, strategy = DatePickerStrategy.startOf, onEditionStart, onEditionStop }) => {
  const classes = useStyles();

  let valueYear: number | undefined;
  if (value && isDateValid(value)) {
    valueYear = value?.getFullYear();
  }

  const [year, setYear, resetYear] = useDerivedState(() => (valueYear), [valueYear]);

  const submitInvalidDate = () => {
    onSubmit(undefined);
  };

  const submitDate = ({ y }: { y: number | undefined }) => {
    if (y != null) {
      resetYear();
      const submittedDate = new Date(y, 1);
      submittedDate.setUTCFullYear(y); // new Date(year, month) make some years becoming 19XX need to set it after
      if (strategy === DatePickerStrategy.endOf) {
        onSubmit(periodicities.year.getEndOfPeriod(submittedDate));
      } else {
        onSubmit(periodicities.year.getStartOfPeriod(submittedDate));
      }
    }
  };

  return (
    <div
      className={classes.inputSpacings}
    >
      <InCompositeInput
        initialValue={year}
        setInputValue={(yearToSubmit) => {
          if (yearToSubmit == null || yearToSubmit > 3000 || yearToSubmit < 1) {
            setYear(undefined);
            submitInvalidDate();
          } else {
            setYear(yearToSubmit);
            submitDate({ y: yearToSubmit });
          }
        }}
      >
        {({ value: innerValue, onChange, ...props }) => (
          <NumberPicker
            min={1}
            max={3000}
            placeholder={i18n`yyyy`}
            withFormatting={false}
            onEditionStart={onEditionStart}
            onEditionStop={onEditionStop}
            value={innerValue}
            onChange={(v) => {
              let newValue: number | undefined;
              if (v === undefined) {
                onChange(undefined);
              } else if (isFiniteNumber(v)) {
                if (typeof v === 'string') {
                  newValue = parseInt(v, 10);
                } else {
                  newValue = v;
                }
                if (newValue < 0) {
                  newValue = 0;
                } else if (newValue > 3000) {
                  newValue = 3000;
                }
                onChange(newValue);
              }
            }}
            {...props}
          />
        )}
      </InCompositeInput>
    </div>
  );
};

export default YearPicker;
