import type { Tree } from '@lezer/common';
import { newError } from '../../errorUtils';
import * as terms from './grammar/formulaParser.terms';

export interface FormulaVisitorProcessor<T> {
  readonly visitText: (value: string) => T,
  readonly visitNumber: (value: number) => T,
  readonly visitInput: (inputName: string) => T,
  readonly visitExpressionGroup: (expression: T) => T,
  readonly visitArray: (elements: T[]) => T,
  readonly visitFunctionCall: (functionName: string, args: T[], isOperator: boolean) => T,
}

export const visitFormula = <T>(
  formula: string,
  tree: Tree,
  {
    visitText,
    visitNumber,
    visitInput,
    visitExpressionGroup,
    visitArray,
    visitFunctionCall,
  }: FormulaVisitorProcessor<T>
): T => {
  const cursor = tree.cursor();

  const traverseExpression = (): T => {
    switch (cursor.type.id) {
      case terms.Text: {
        const value = formula.substring(cursor.from + 1, cursor.to - 1).replaceAll('""', '"');
        return visitText(value);
      }

      case terms.Number: {
        const numStr = formula.substring(cursor.from, cursor.to);
        let pos = 0;
        while (numStr[pos] === '0') {
          pos += 1;
        }
        const value = Number(numStr.substring(pos < numStr.length ? pos : pos - 1));
        return visitNumber(value);
      }

      case terms.Input: {
        const inputName = formula.substring(cursor.from, cursor.to);
        return visitInput(inputName);
      }

      case terms.Operation: {
        const innerCursor = cursor; // trick to workaround typescript false positive
        innerCursor.firstChild();
        if (innerCursor.type.id === terms.PrefixOperator) {
          const operator = formula.substring(innerCursor.from, innerCursor.to);
          innerCursor.nextSibling();
          const operand = traverseExpression();
          innerCursor.parent();
          return visitFunctionCall(operator, [operand], true);
        } else {
          const leftOperand = traverseExpression();
          innerCursor.nextSibling();
          const operator = formula.substring(innerCursor.from, innerCursor.to);
          if (innerCursor.type.id === terms.PostfixOperator) {
            innerCursor.parent();
            return visitFunctionCall(operator, [leftOperand], true);
          } else {
            innerCursor.nextSibling();
            const rightOperand = traverseExpression();
            innerCursor.parent();
            return visitFunctionCall(operator, [leftOperand, rightOperand], true);
          }
        }
      }

      case terms.ExpressionGroup: {
        cursor.firstChild();
        const expression = traverseExpression();
        cursor.parent();
        return visitExpressionGroup(expression);
      }

      case terms.Array: {
        const elements: T[] = [];
        if (cursor.firstChild()) {
          do {
            elements.push(traverseExpression());
          } while (cursor.nextSibling());
          cursor.parent();
        }
        return visitArray(elements);
      }

      case terms.FunctionCall: {
        cursor.firstChild();
        const functionName = formula.substring(cursor.from, cursor.to - 1); // FunctionCallStart token include the opening parenthesis at the end
        const args: T[] = [];
        while (cursor.nextSibling()) {
          args.push(traverseExpression());
        }
        cursor.parent();

        return visitFunctionCall(functionName, args, false);
      }

      default:
        throw newError('Unsupported term', { termName: cursor.type.name, termId: cursor.type.id });
    }
  };

  cursor.firstChild();
  return traverseExpression();
};
