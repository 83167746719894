import type { FunctionComponent, ReactElement } from 'react';
import { createContext, useContext, useEffect, useMemo, useSyncExternalStore } from 'react';
// eslint-disable-next-line yooi/no-restricted-dependency
import { hasPlatformCapability } from 'yooi-modules/modules/conceptModule';
// eslint-disable-next-line yooi/no-restricted-dependency
import { PlatformCapabilityAdmin } from 'yooi-modules/modules/conceptModule/ids';
import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { newError } from 'yooi-utils';
import type { AttachmentStore } from './attachmentStore';
import { ACLHandlerProvider } from './useAcl';
import useAuth from './useAuth';
import useStoreContext from './useStoreContext';

export interface FrontObjectStore extends Omit<ObjectStoreWithTimeseries, 'flush'>, AttachmentStore {
  getConnectionDate: () => number | undefined,
  getSerial: () => number,
  getLoggedUserId: () => string,
}

declare global {
  interface Window {
    getStore?: () => FrontObjectStore,
  }
}

const StoreContext = createContext<FrontObjectStore | undefined>(undefined);

interface StoreProviderProps {
  children: ReactElement | null,
}

export const StoreProvider: FunctionComponent<StoreProviderProps> = ({ children }) => {
  const storeContext = useStoreContext();
  const { loggedUserId } = useAuth();

  const store = useMemo(() => {
    const {
      getObject,
      getObjectOrNull,
      listObjects,
      forEachObject,
      objectsIterator,
      size,
      objectEntries,
      updateObject,
      createObject,
      deleteObject,
      withAssociation,
      globalObservers,
      getConnectionDate,
      registerPropertyFunction,
      unregisterPropertyFunction,
      invalidatePropertyCache,
      unregisterAllPropertyFunctions,
      hasPropertyFunction,
    } = storeContext.data;
    const { uploadAttachment, cloneAttachment, getAttachmentUrl, isSafeAttachment } = storeContext.attachment;
    const { getTimeseries, updateTimeValue, deleteTimeValue, truncateRange } = storeContext.timeseries;

    return {
      getObject,
      getObjectOrNull,
      listObjects,
      forEachObject,
      objectsIterator,
      objectEntries,
      size,
      updateObject,
      createObject,
      deleteObject,
      withAssociation,
      uploadAttachment,
      cloneAttachment,
      isSafeAttachment,
      getAttachmentUrl,
      getTimeseries,
      updateTimeValue,
      deleteTimeValue,
      truncateRange,
      getConnectionDate,
      getSerial: globalObservers.serial,
      registerPropertyFunction,
      unregisterPropertyFunction,
      invalidatePropertyCache,
      unregisterAllPropertyFunctions,
      hasPropertyFunction,
      getLoggedUserId: () => loggedUserId,
    };
  }, [loggedUserId, storeContext]);

  useEffect(() => {
    window.getStore = () => {
      if (hasPlatformCapability(store, loggedUserId, PlatformCapabilityAdmin)) {
        return store;
      } else {
        throw newError('Logged user doesn\'t have the administration platform capability');
      }
    };
    return () => {
      window.getStore = undefined;
    };
  }, [loggedUserId, store]);

  return (
    <StoreContext.Provider value={store}>
      <ACLHandlerProvider>
        {children}
      </ACLHandlerProvider>
    </StoreContext.Provider>
  );
};

const useStore = (): FrontObjectStore => {
  const storeContext = useStoreContext();
  const store = useContext(StoreContext);

  if (store === undefined) {
    throw newError('StoreContext has not been initialized, add a StoreContextProvider in the React parent component hierarchy');
  }

  // Some warnings about useSyncExternalStore
  // * notifying the subscribed hook is not enough to trigger a rerender. Snapshot must be different too
  // * as explained in React documentation, successive calls to getSnapshot must return the same value if the store has not changed
  // * the hook always call the getSnapshot method defined during the first rendering. For next renderings, the getSnapshot method defined for this rendering is not called
  //
  // So the useSyncExternalStore is not used in a 'conventional' way here. Since the store object stabilization work is already done, the only job of the hook is to connect
  // with the store update mechanism. Result of the useSyncExternalStore is not needed. So only the serial of the store is used to materialize the different version of the store.

  const { globalObservers } = storeContext.data;
  useSyncExternalStore(
    globalObservers.register,
    globalObservers.serial
  );

  return store;
};

export default useStore;
