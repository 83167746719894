import type { FunctionComponent } from 'react';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { CardsViewStoredDefinition, ViewDimension, WidgetStoreObject } from 'yooi-modules/modules/dashboardModule';
import { Widget_DisplayMode } from 'yooi-modules/modules/dashboardModule/ids';
import ScrollableWidgetContainer from '../../../../components/templates/ScrollableWidgetContainer';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { remToPx } from '../../../../utils/sizeUtils';
import useDeepMemo from '../../../../utils/useDeepMemo';
import WidgetInvalidConfiguration from '../../fields/_global/WidgetInvalidConfiguration';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { useViewFilters } from '../../filter/useViewFilters';
import { getViewDefinitionHandler } from '../viewDsl';
import { isResolutionError } from '../viewResolutionUtils';
import CardsView from './CardsView';
import type { CardsViewDefinitionHandler } from './cardsViewDefinitionHandler';

interface CardsViewWidgetProps {
  widgetId: string,
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinition: CardsViewStoredDefinition,
  parametersMapping: ParametersMapping,
  width: number,
  height: number,
  readOnly: boolean | undefined,
}

const CardsViewWidget: FunctionComponent<CardsViewWidgetProps> = ({
  widgetId, viewDimensions, viewFilters, viewDefinition, parametersMapping, width, height, readOnly,
}) => {
  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();
  const filterConfiguration = useViewFilters(viewFilters, viewDimensions);
  const isBorderless = store.getObject<WidgetStoreObject>(widgetId)[Widget_DisplayMode]?.type === 'borderless';

  const viewHandler = getViewDefinitionHandler(viewDefinition) as CardsViewDefinitionHandler;

  const viewResolvedDefinition = viewHandler.getDefinition(store, viewDimensions);
  const viewResolution = useDeepMemo(
    () => viewHandler.resolveView(store, { viewDimensions, aclHandler, parametersMapping, userId: loggedUserId, readOnly, filterConfiguration }),
    [store.getSerial(), viewDimensions, viewDefinition, parametersMapping, filterConfiguration]
  );
  if (isResolutionError(viewResolution)) {
    return (<WidgetInvalidConfiguration width={width} height={height} reason={viewResolution.error} />);
  }

  return (
    <ScrollableWidgetContainer asContent width={width} height={height}>
      <CardsView
        viewDefinition={viewResolvedDefinition}
        viewDimensions={viewDimensions}
        viewResolution={viewResolution}
        parametersMapping={parametersMapping}
        filterKey={viewFilters.filterKey}
        availableWidth={width - (isBorderless ? remToPx(0.8 /* scroll width */) : remToPx(1.2) * 2) /* remove internal paddings */}
        availableHeight={height}
      />
    </ScrollableWidgetContainer>
  );
};

export default CardsViewWidget;
