import { joinObjects } from 'yooi-utils';
import type { Font } from '../theme/fontDefinition';
import { remToPx } from './sizeUtils';

export const getFontStyle = (font: Font): Font => (joinObjects(
  font,
  {
    fontSize: typeof font.fontSize === 'string' && font.fontSize.endsWith('rem') ? `${remToPx(font.fontSize)}` : font.fontSize,
    lineHeight: typeof font.lineHeight === 'string' && font.lineHeight.endsWith('rem') ? `${remToPx(font.lineHeight)}px` : font.lineHeight,
  }
));
