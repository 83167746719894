import type { FunctionComponent, MouseEventHandler } from 'react';
import { Fragment } from 'react';
import { spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { formatOrUndef } from '../../utils/stringUtils';
import type { NavigationPayload } from '../../utils/useNavigation';
import type { IconColorVariant } from '../atoms/Icon';
import { IconName } from '../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../atoms/IconOnlyButton';
import Chip from './Chip';

const useStyles = makeStyles((theme) => ({
  nodeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    minHeight: '2.8rem',
    minWidth: '15rem',
  },
  iconElement: {
    marginRight: spacingRem.s,
    alignSelf: 'center',
    cursor: 'pointer',
    minWidth: spacingRem.iconSize,
  },
  nodeElement: {
    marginRight: spacingRem.s,
    alignSelf: 'center',
    cursor: 'pointer',
  },
  spacer: {
    minWidth: '0.9rem',
  },
  spacerL: {
    minWidth: spacingRem.l,
  },
  line: {
    minWidth: '0.1rem',
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.border.default,
  },
}), 'treeNode');

export interface TreeNodeChipOption {
  key: string,
  label: string,
  icon?: IconName | { name: IconName, colorVariant: IconColorVariant } | { name: IconName, color: string },
  tooltip?: string,
  color?: string,
  borderStyle?: 'solid' | 'dashed',
  state?: NavigationPayload['state'],
}

interface TreeNodeProps {
  chipOption: TreeNodeChipOption | undefined,
  onClick: MouseEventHandler | undefined,
  onOpen: MouseEventHandler<HTMLButtonElement> | undefined,
  onClose: MouseEventHandler<HTMLButtonElement> | undefined,
  depth: number,
  firstChild: boolean,
  lastChild: boolean,
  parentLastChildForDepth: number[],
}

const TreeNode: FunctionComponent<TreeNodeProps> = ({
  chipOption,
  onClick,
  onOpen,
  onClose,
  depth,
  firstChild,
  lastChild,
  parentLastChildForDepth,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.nodeWrapper}>
      {Array.from({ length: depth }, (_, i) => (
        <Fragment key={`frag${i}`}>
          <div className={classes.spacer} />
          <div
            className={classes.line}
            style={{
              marginTop: firstChild && i === depth - 1 ? '0.4rem' : 'Orem',
              marginBottom: lastChild && (i === depth - 1 || parentLastChildForDepth.includes(i)) ? '0.4rem' : 'Orem',
            }}
          />
          <div className={classes.spacerL} />
        </Fragment>
      ))}
      <div className={classes.iconElement}>
        {onOpen && (
          <IconOnlyButton
            tooltip={i18n`Expand`}
            iconName={IconName.keyboard_arrow_right}
            variant={IconOnlyButtonVariants.tertiary}
            onClick={onOpen}
          />
        )}
        {onClose && (
          <IconOnlyButton
            tooltip={i18n`Collapse`}
            iconName={IconName.expand_more}
            variant={IconOnlyButtonVariants.tertiary}
            onClick={onClose}
          />
        )}
      </div>
      <div
        className={classes.nodeElement}
        tabIndex={0}
        onClick={onClick}
        onKeyDown={() => {}}
        role="button"
        aria-label={formatOrUndef(chipOption?.tooltip)}
      >
        <Chip
          key={`${chipOption?.key}_chip`}
          tooltip={chipOption?.tooltip}
          color={chipOption?.color}
          text={chipOption?.label}
          icon={chipOption?.icon}
          borderStyle={chipOption?.borderStyle}
        />
      </div>
    </div>
  );
};

export default TreeNode;
