import type { FunctionComponent } from 'react';
import type { WorkflowEntryStoreObject, WorkflowTransitionStoreObject } from 'yooi-modules/modules/conceptModule';
import { FILTER_PARAMETER_CURRENT, getInstanceLabelOrUndefined, InstanceReferenceType, PathStepType } from 'yooi-modules/modules/conceptModule';
import type { TransitionConfiguration } from 'yooi-modules/modules/conceptModule/fields/workflowField';
import {
  Concept_Name,
  ConceptRole_ConceptDefinition,
  Group,
  Workflow_Transitions,
  WorkflowEntry,
  WorkflowEntry_Rank,
  WorkflowEntry_Role_Concept,
  WorkflowEntry_Role_Workflow,
  WorkflowFieldTransition_Filters,
  WorkflowFieldTransition_Rights,
  WorkflowTransition_From,
  WorkflowTransition_Name,
  WorkflowTransition_To,
} from 'yooi-modules/modules/conceptModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import type { RichText } from 'yooi-utils';
import { compareRank, comparing, filterNullOrUndefined, joinObjects, richTextToText } from 'yooi-utils';
import Typo from '../../../../components/atoms/Typo';
import TextInputString from '../../../../components/inputs/TextInputString';
import CompositeField from '../../../../components/molecules/CompositeField';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SearchAndSelectMultiple from '../../../../components/molecules/SearchAndSelectMultiple';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import { TableSortDirection } from '../../../../components/molecules/Table';
import DataTable from '../../../../components/templates/DataTable';
import useStore from '../../../../store/useStore';
import { Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import { formatOrUndef } from '../../../../utils/stringUtils';
import { resolveConceptChipIcon, resolveConceptColorValue } from '../../conceptDisplayUtils';
import FilterComposite from '../../filter/filterComposite/FilterComposite';
import { getLoggedUserParameterDefinition } from '../../filter/filterUtils';
import { getChipOptions, getConceptDefinitionNameOrEntity } from '../../modelTypeUtils';
import type { ComparatorHandler } from '../../useFilterAndSort';
import useFilterAndSort from '../../useFilterAndSort';

interface WorkflowFieldTransactionConfigurationProps {
  workflowId: string | undefined,
  workflowTransitions: Record<string, TransitionConfiguration>,
  onUpdate: (transitions: Record<string, TransitionConfiguration>) => void,
  modelTypeId: string,
  fullWidth: boolean,
}

const WorkflowFieldTransactionConfiguration: FunctionComponent<WorkflowFieldTransactionConfigurationProps> = ({
  workflowId,
  workflowTransitions,
  onUpdate,
  modelTypeId,
  fullWidth,
}) => {
  const store = useStore();

  const { generateGroupedList, sortCriteria, doSort } = useFilterAndSort(
    `${workflowId}_${Workflow_Transitions}`,
    workflowId ? store.getObjectOrNull(workflowId)?.navigateBack<WorkflowTransitionStoreObject>(Workflow_Transitions) ?? [] : [],
    undefined,
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case WorkflowTransition_From:
            return {
              comparator: comparing(compareRank, direction === TableSortDirection.desc),
              extractValue: (transition) => {
                const from = transition.navigateOrNull(WorkflowTransition_From);
                if (workflowId && from) {
                  return store.withAssociation(WorkflowEntry)
                    .withRole(WorkflowEntry_Role_Workflow, workflowId)
                    .withRole(WorkflowEntry_Role_Concept, from.id)
                    .getObjectOrNull<WorkflowEntryStoreObject>()
                    ?.[WorkflowEntry_Rank];
                } else {
                  return undefined;
                }
              },
            } satisfies ComparatorHandler<WorkflowTransitionStoreObject, string | undefined>;
          default:
            return undefined;
        }
      },
      initial: { key: WorkflowTransition_From, direction: TableSortDirection.asc },
    },
    {
      key: WorkflowTransition_From,
      getGroupKey: (item) => item.navigateOrNull(WorkflowTransition_From)?.id,
      getGroupLabel: (key) => getInstanceLabelOrUndefined(store, store.getObject(key)),
      getGroupColor: (key) => (resolveConceptColorValue(store, key) ?? resolveConceptChipIcon(store, key)?.color),
    }
  );

  return (
    <DataTable
      list={generateGroupedList().list}
      sortCriteria={sortCriteria}
      doSort={doSort}
      columnsDefinition={[
        {
          name: i18n`From`,
          propertyId: WorkflowTransition_From,
          width: 20,
          sortable: true,
          cellRender: (transition) => (
            <SearchAndSelect
              readOnly
              selectedOption={typeof transition[WorkflowTransition_From] === 'string' ? getChipOptions(store, transition[WorkflowTransition_From]) : undefined}
              computeOptions={() => []}
            />
          ),
        },
        {
          name: i18n`To`,
          propertyId: WorkflowTransition_To,
          width: 20,
          cellRender: (transition) => (
            <SearchAndSelect
              readOnly
              selectedOption={typeof transition[WorkflowTransition_To] === 'string' ? getChipOptions(store, transition[WorkflowTransition_To]) : undefined}
              computeOptions={() => []}
            />
          ),
        },
        {
          name: i18n`Label`,
          propertyId: WorkflowTransition_Name,
          width: 20,
          cellRender: (transition) => (
            <TextInputString value={transition[WorkflowTransition_Name]} readOnly />
          ),
        },
        {
          name: i18n`Filters`,
          propertyId: WorkflowFieldTransition_Filters,
          width: 20,
          cellRender: (transition) => {
            const transitionConfiguration = workflowTransitions[transition.id] ?? {};
            return (
              <FilterComposite
                filtersDefinition={{
                  updateFilters: (filters) => {
                    onUpdate(joinObjects(
                      workflowTransitions,
                      {
                        [transition.id]: joinObjects(
                          transitionConfiguration,
                          { [WorkflowFieldTransition_Filters]: filters[0] }
                        ),
                      }
                    ));
                  },
                  definition: [
                    {
                      filter: transitionConfiguration[WorkflowFieldTransition_Filters],
                    },
                  ],
                }}
                rootPath={{
                  label: i18n`${formatOrUndef(getConceptDefinitionNameOrEntity(store, modelTypeId))}[current]`,
                  path: [
                    { type: PathStepType.dimension, conceptDefinitionId: modelTypeId },
                    { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
                  ],
                }}
                requiredConceptDefinitionId={modelTypeId}
                parameterDefinitions={[
                  { id: FILTER_PARAMETER_CURRENT, label: i18n`Current`, typeId: modelTypeId, type: 'parameter' },
                  getLoggedUserParameterDefinition(),
                ]}
              />
            );
          },
        },
        {
          name: i18n`Rights`,
          propertyId: WorkflowFieldTransition_Rights,
          width: 20,
          cellRender: (transition) => {
            const transitionConfiguration = workflowTransitions[transition.id] ?? {};
            const rights = transitionConfiguration[WorkflowFieldTransition_Rights];
            const groups = rights?.groups ?? [];
            const roles = rights?.roles ?? [];
            return (
              <CompositeField
                headerLinesRenderers={[
                  {
                    id: 'title',
                    render: () => (
                      <SpacedContainer margin={{ y: Spacing.xs }}>
                        <Typo maxLine={1}>
                          {[...roles, ...groups].map((id) => richTextToText(store.getObjectOrNull(id)?.[Concept_Name] as RichText | undefined))
                            .join(', ') || i18n`No configuration`}
                        </Typo>
                      </SpacedContainer>
                    ),
                  },
                ]}
                getDropdownSectionDefinitions={() => [{
                  id: 'line',
                  lines: [
                    {
                      id: 'roles',
                      title: i18n`Roles`,
                      render: (
                        <div>
                          <SearchAndSelectMultiple
                            selectedOptions={roles
                              .map((roleId) => getChipOptions(store, roleId))
                              .filter(filterNullOrUndefined)}
                            onSelect={(option) => onUpdate(joinObjects(
                              workflowTransitions,
                              {
                                [transition.id]: joinObjects(
                                  transitionConfiguration,
                                  {
                                    [WorkflowFieldTransition_Rights]: joinObjects(
                                      transitionConfiguration[WorkflowFieldTransition_Rights],
                                      { roles: [...roles, option.id] }
                                    ),
                                  }
                                ),
                              }
                            ))}
                            onDelete={(option) => onUpdate(joinObjects(
                              workflowTransitions,
                              {
                                [transition.id]: joinObjects(
                                  transitionConfiguration,
                                  {
                                    [WorkflowFieldTransition_Rights]: joinObjects(
                                      transitionConfiguration[WorkflowFieldTransition_Rights],
                                      { roles: [...roles.filter((id) => id !== option.id)] }
                                    ),
                                  }
                                ),
                              }
                            ))}
                            computeOptions={() => store.getObject(modelTypeId)
                              .navigateBack(ConceptRole_ConceptDefinition)
                              .map((role) => getChipOptions(store, role.id))
                              .filter(filterNullOrUndefined)}
                          />
                        </div>
                      ),
                    },
                    {
                      id: 'group',
                      title: i18n`Groups`,
                      render: (
                        <div>
                          <SearchAndSelectMultiple
                            selectedOptions={groups
                              .map((groupId) => getChipOptions(store, groupId))
                              .filter(filterNullOrUndefined)}
                            onSelect={(option) => onUpdate(joinObjects(
                              workflowTransitions,
                              {
                                [transition.id]: joinObjects(
                                  transitionConfiguration,
                                  {
                                    [WorkflowFieldTransition_Rights]: joinObjects(
                                      transitionConfiguration[WorkflowFieldTransition_Rights],
                                      { groups: [...groups, option.id] }
                                    ),
                                  }
                                ),
                              }
                            ))}
                            onDelete={(option) => onUpdate(joinObjects(
                              workflowTransitions,
                              {
                                [transition.id]: joinObjects(
                                  transitionConfiguration,
                                  {
                                    [WorkflowFieldTransition_Rights]: joinObjects(
                                      transitionConfiguration[WorkflowFieldTransition_Rights],
                                      { groups: [...groups.filter((groupId) => groupId !== option.id)] }
                                    ),
                                  }
                                ),
                              }
                            ))}
                            computeOptions={() => store.getObject(Group)
                              .navigateBack(Class_Instances)
                              .map((group) => getChipOptions(store, group.id))
                              .filter(filterNullOrUndefined)}
                          />
                        </div>
                      ),
                    },
                  ],
                }]}
              />
            );
          },
        },
      ]}
      fullWidth={fullWidth}
    />
  );
};

export default WorkflowFieldTransactionConfiguration;
