import { equals } from 'ramda';
import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import type { RichText } from 'yooi-utils';
import { isRichText, richTextToText, textToRichText, textType } from 'yooi-utils';
import type { AsPropertyBusinessRuleRegistration, GetDslFieldHandler, UpdateOperationHandlers } from '../../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../../common/typeErrorUtils';
import {
  preventComputedFieldUpdate,
  sanitizeFilterValue,
  validateFieldIdAsProperty,
  validateIntegrationOnlyPropertyUpdate,
} from '../../common/commonFieldUtils';
import { registerField } from '../../module';
import type { FilterValueRaw, PathStep } from '../../moduleType';
import { FilterValueType } from '../../moduleType';
import type {
  DimensionsMapping,
  FieldFilterCondition,
  FieldFilterConditions,
  MultipleParameterValue,
  ParametersMapping,
  ResolutionStack,
  SingleParameterValue,
  ValueResolution,
} from '../../utils';
import {
  buildDimensionalId,
  createValuePathResolver,
  isFilterValueRaw,
  isSingleValueResolution,
  isValueResolutionOfType,
  ParsedDimensionType,
  parseDimensionMapping,
  resolveFieldValue,
} from '../../utils';
import { richTextType } from '../../utils/formula/richTextFunctions';
import type { TextField } from '../types';
import type { WrappedRichText } from './utils';
import { isWrappedRichText, richTextWrapper, updateTextWithLinks } from './utils';

const validateTextField: AsPropertyBusinessRuleRegistration = (_, fieldId) => (__, { properties }) => {
  const newValue = properties?.[fieldId];

  if (newValue === undefined || newValue === null) {
    return undefined;
  }
  if (isRichText(newValue)) {
    return {
      rule: 'textField.isRichText',
      status: ValidationStatus.ACCEPTED,
    };
  } else {
    return {
      rule: 'textField.isRichText',
      status: ValidationStatus.REJECTED,
    };
  }
};

const convertLinkTextField: AsPropertyBusinessRuleRegistration = (_, fieldId) => (__, { id, properties }) => {
  const text = properties?.[fieldId];
  if (!isRichText(text) || text === undefined || text === null) {
    return undefined;
  }
  const textLinkUpdated = updateTextWithLinks(text);
  if (equals(text, textLinkUpdated)) {
    return undefined;
  } else {
    return {
      rule: 'textField.convertLink',
      status: ValidationStatus.ACCEPTED,
      generateSystemEvent: ({ updateObject }) => {
        updateObject(id, { [fieldId]: textLinkUpdated });
      },
    };
  }
};

const getValueResolution = (
  objectStore: ObjectStoreWithTimeseries,
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
  resolutionStack?: ResolutionStack
): ValueResolution<WrappedRichText | undefined> => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
  if (isValueResolutionOfType(valueResolution, (value): value is WrappedRichText | undefined => (value === undefined || isWrappedRichText(value)))) {
    return valueResolution;
  } else if (valueResolution.isComputed && isValueResolutionOfType(valueResolution, (value): value is string => (typeof value === 'string'))) {
    const rickTextValue = richTextWrapper(textToRichText(valueResolution.value));
    return {
      value: rickTextValue,
      isComputed: valueResolution.isComputed,
      error: valueResolution.error,
      getDisplayValue: () => rickTextValue,
      isTimeseries: valueResolution.isTimeseries,
    };
  } else {
    return {
      value: undefined,
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['WrappedRichText', 'undefined'], typeof valueResolution.value),
      getDisplayValue: () => undefined,
      isTimeseries: valueResolution.isTimeseries,
    };
  }
};

const sanitizeTextValue = (value: unknown) => (value ?? { type: FilterValueType.raw, raw: undefined }) as FilterValueRaw<string>;
const isTextFilterApplicable = (value: FilterValueRaw<string>) => Boolean(value) && isFilterValueRaw<string>(value);

interface TextFieldFilterConditions extends FieldFilterConditions<WrappedRichText> {
  CONTAINS: FieldFilterCondition<string, WrappedRichText>,
  DOES_NOT_CONTAIN: FieldFilterCondition<string, WrappedRichText>,
  EQUALS: FieldFilterCondition<string, WrappedRichText>,
  NOT_EQUALS: FieldFilterCondition<string, WrappedRichText>,
  STARTS_WITH: FieldFilterCondition<string, WrappedRichText>,
  ENDS_WITH: FieldFilterCondition<string, WrappedRichText>,
  IS_EMPTY: FieldFilterCondition<undefined, WrappedRichText>,
  IS_NOT_EMPTY: FieldFilterCondition<undefined, WrappedRichText>,
}

interface TextUpdateOperationTypes {
  INITIALIZE: { type: 'value', value: RichText | undefined } | { type: 'path', path: PathStep[] },
  REPLACE: { type: 'value', value: RichText | undefined } | { type: 'path', path: PathStep[] },
  CLEAR: undefined,
}

type TextFieldHandler = GetDslFieldHandler<
  TextField,
  RichText | undefined,
  RichText | null,
  WrappedRichText | undefined,
  WrappedRichText | undefined,
  RichText | undefined,
  TextFieldFilterConditions,
  TextUpdateOperationTypes,
  undefined,
  undefined
>;

export const textFieldHandler: TextFieldHandler = registerField({
  model: {
    label: 'TextField',
    title: 'Text',
    withApiAlias: true,
    asPropertyBusinessRules: [
      validateIntegrationOnlyPropertyUpdate('textField'),
      validateTextField,
      convertLinkTextField,
      validateFieldIdAsProperty('textField'),
      preventComputedFieldUpdate('textField'),
    ],
  },
  handler: (objectStore, fieldId) => {
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => getValueResolution(objectStore, fieldId, dimensionsMapping).value?.toString();

    const extractOperationValueText = (
      parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
      operationValue: { type: 'value', value: RichText | undefined } | { type: 'path', path: PathStep[] }
    ): RichText | undefined => {
      if (operationValue.type === 'path') {
        const { path } = operationValue;
        const resolution = createValuePathResolver(objectStore, parametersMapping).resolvePathValue(path);
        if (isSingleValueResolution(resolution)) {
          if (typeof resolution.value === 'string') {
            return textToRichText(resolution.value);
          } else if (isWrappedRichText(resolution.value)) {
            const richText = resolution.value?.valueOf();
            if (isRichText(richText)) {
              return richText;
            }
          }
        }
        return undefined;
      } else {
        return operationValue.value;
      }
    };

    return {
      describe: () => ({ hasData: true, returnType: richTextType, extraAcceptedTypes: [textType], timeseriesMode: 'none' }),
      restApi: {
        // Because RichText refers to self, we need to defined it externally with a schema
        returnTypeSchema: { $ref: '#/components/schemas/richTextCustomElement' },
        formatValue: (value) => value?.valueOf(),
      },
      getStoreValue: (dimensionsMapping) => {
        const parsedDimension = parseDimensionMapping(dimensionsMapping);
        if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
          return objectStore.getObject(parsedDimension.objectId)[fieldId] as RichText | undefined;
        } else {
          return objectStore.getObject(buildDimensionalId(dimensionsMapping), true)[fieldId] as RichText | undefined;
        }
      },
      getValueWithoutFormula: (dimensionsMapping) => {
        const parsedDimension = parseDimensionMapping(dimensionsMapping);
        let value;
        if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
          value = objectStore.getObject(parsedDimension.objectId)[fieldId] as RichText | undefined;
        } else {
          value = objectStore.getObject(buildDimensionalId(dimensionsMapping), true)[fieldId] as RichText | undefined;
        }
        return richTextWrapper(value);
      },
      getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
      updateValue: (dimensionsMapping, value) => {
        const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
        if (parsedDimensionMapping.type === ParsedDimensionType.MonoDimensional) {
          objectStore.updateObject(parsedDimensionMapping.objectId, { [fieldId]: value });
        } else {
          objectStore.updateObject(buildDimensionalId(dimensionsMapping), { [fieldId]: value });
        }
      },
      isEmpty: (dimensionsMapping) => {
        const resolvedValue = getValueResolution(objectStore, fieldId, dimensionsMapping).value;
        return !resolvedValue || resolvedValue.toString() === '';
      },
      isSaneValue: () => ({ isValid: true }),
      getValueAsText,
      getExportColumnHeaders: (configuration, fieldLabel) => ({
        columnsNumber: 1,
        getHeaders: () => [{ format: 'string', value: fieldLabel }],
        getColumnConfiguration: () => configuration,
      }),
      getExportValue: (dimensionsMapping) => ({ format: 'string', value: getValueAsText(dimensionsMapping) }),
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else {
            return undefined;
          }
        },
      }),
      filterConditions: {
        CONTAINS: {
          isFilterApplicable: isTextFilterApplicable,
          sanitizeValue: sanitizeTextValue,
          filterFunction: (leftValue, rightValue) => sanitizeFilterValue(leftValue?.toString()).includes(sanitizeFilterValue(rightValue)),
        },
        DOES_NOT_CONTAIN: {
          isFilterApplicable: isTextFilterApplicable,
          sanitizeValue: sanitizeTextValue,
          filterFunction: (leftValue, rightValue) => !sanitizeFilterValue(leftValue?.toString()).includes(sanitizeFilterValue(rightValue)),
        },
        EQUALS: {
          isFilterApplicable: isTextFilterApplicable,
          sanitizeValue: sanitizeTextValue,
          filterFunction: (leftValue, rightValue) => sanitizeFilterValue(leftValue?.toString()) === sanitizeFilterValue(rightValue),
        },
        NOT_EQUALS: {
          isFilterApplicable: isTextFilterApplicable,
          sanitizeValue: sanitizeTextValue,
          filterFunction: (leftValue, rightValue) => sanitizeFilterValue(leftValue?.toString()) !== sanitizeFilterValue(rightValue),
        },
        STARTS_WITH: {
          isFilterApplicable: isTextFilterApplicable,
          sanitizeValue: sanitizeTextValue,
          filterFunction: (leftValue, rightValue) => sanitizeFilterValue(leftValue?.toString()).startsWith(sanitizeFilterValue(rightValue)),
        },
        ENDS_WITH: {
          isFilterApplicable: isTextFilterApplicable,
          sanitizeValue: sanitizeTextValue,
          filterFunction: (leftValue, rightValue) => sanitizeFilterValue(leftValue?.toString()).endsWith(sanitizeFilterValue(rightValue)),
        },
        IS_EMPTY: {
          sanitizeValue: () => ({ type: FilterValueType.raw, raw: undefined }),
          isFilterApplicable: () => true,
          filterFunction: (leftValue) => (leftValue?.toString() ?? '').trim() === '',
        },
        IS_NOT_EMPTY: {
          sanitizeValue: () => ({ type: FilterValueType.raw, raw: undefined }),
          isFilterApplicable: () => true,
          filterFunction: (leftValue) => (leftValue?.toString() ?? '').trim() !== '',
        },
      },
      resolvePathStepConfiguration: () => ({
        hasData: true,
        timeseriesMode: 'none',
        getValueResolutionType: () => richTextType,
        resolveValue: (dimensionsMappings, _, resolutionStack) => {
          const { error, value } = getValueResolution(objectStore, fieldId, dimensionsMappings, resolutionStack);
          if (error) {
            throw error;
          } else {
            return value;
          }
        },
      }),
      updateOperationHandlers: {
        INITIALIZE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            const fieldHandler = textFieldHandler(objectStore, fieldId);
            const oldRichText = fieldHandler.getStoreValue(dimensionsMapping);
            const oldText = richTextToText(oldRichText);
            if (oldText !== undefined && oldText !== '') {
              return;
            }
            fieldHandler.updateValue(dimensionsMapping, extractOperationValueText(parametersMapping, value) ?? null);
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: undefined },
        },
        REPLACE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            textFieldHandler(objectStore, fieldId)
              .updateValue(dimensionsMapping, extractOperationValueText(parametersMapping, value) ?? null);
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: undefined },
        },
        CLEAR: {
          applyOperation: (dimensionsMapping) => {
            textFieldHandler(objectStore, fieldId).updateValue(dimensionsMapping, null);
          },
          sanitizeOperation: () => undefined,
        },
      } satisfies UpdateOperationHandlers<TextUpdateOperationTypes>,
    };
  },
  historyEventProducer: ({ getObjectOrNull }, fieldId) => ({
    value: {
      collectImpactedInstances: ({ id, properties }) => {
        if (
          properties?.[fieldId] !== undefined // A value is in the update for the current field
          || (properties === null && getObjectOrNull(id)?.[fieldId] !== undefined) // Object is deleted and store had a value for the field
        ) {
          return [id];
        } else {
          return [];
        }
      },
      getValue: (id) => {
        const value = getObjectOrNull(id)?.[fieldId] ?? null;
        const saneValue: RichText | null = typeof value === 'string' ? textToRichText(value) ?? null : value as RichText | null;
        return { value: saneValue, version: 1 };
      },
      areValuesEquals: (value1, value2) => equals(value1?.value, value2?.value),
    },
  }),
});
