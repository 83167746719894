import { newError } from 'yooi-utils';
import type { Font, FontVariant } from '../theme/fontDefinition';
import fontDefinition from '../theme/fontDefinition';
import { remToPx } from './sizeUtils';

interface TextMeasurer {
  (text: string | undefined): number,
}

const measurerByVariant = new Map<FontVariant, TextMeasurer>();
window.addEventListener('load', () => {
  measurerByVariant.clear();
});

const buildTextMeasurer = ({ fontStyle, fontWeight, fontSize, fontFamily, letterSpacing }: Font): TextMeasurer => {
  const canvas = document.createElement('canvas');

  const context = canvas.getContext('2d');
  if (!context) {
    throw newError('Unsupported canvas type');
  }

  const appliedFontSize = typeof fontSize === 'string' && fontSize.endsWith('rem') ? `${remToPx(fontSize)}px` : fontSize;
  context.font = `${fontStyle} ${fontWeight} ${appliedFontSize} ${fontFamily}`;

  return (text) => {
    if (text) {
      const { width } = context.measureText(text);
      if (typeof letterSpacing === 'string' && letterSpacing.endsWith('rem')) {
        // canvas context doesn't support letter spacing, we need to manually adjust the width
        return Math.ceil(width + (text.length * remToPx(letterSpacing)));
      } else {
        return Math.ceil(width);
      }
    } else {
      return 0;
    }
  };
};

export const getTextMeasurerForVariant = (variant: FontVariant): TextMeasurer => {
  let measurer = measurerByVariant.get(variant);
  if (!measurer) {
    measurer = buildTextMeasurer(fontDefinition[variant]);
    measurerByVariant.set(variant, measurer);
  }
  return measurer;
};
