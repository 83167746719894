import type { InitModuleFunction } from 'yooi-store';
import * as AutomationModule from './automationModule';
import * as CollaborationModule from './collaborationModule';
import * as ConceptLayoutModule from './conceptLayoutModule';
import * as ConceptModule from './conceptModule';
import * as DashboardModule from './dashboardModule';
import * as DataAssetModule from './dataAssetModule';
import * as DebugModule from './debugModule';
import * as IntegrationModule from './integrationModule';
import * as NavigationModelModule from './navigationModule';
import * as NotificationModelModule from './notificationModule';
import * as PlatformConfigurationModule from './platformConfigurationModule';
import * as PlatformInitializationModule from './platformInitializationModule';
import * as PlatformModule from './platformModule';
import * as ReferenceModule from './referenceModule';
import * as ResourceModule from './resourceModule';
import * as TemplateModule from './templateModule';
import * as TypeModule from './typeModule';

export * from './common/typeErrorUtils';

const modules: Record<string, { initModule: InitModuleFunction }> = {
  AutomationModule,
  CollaborationModule,
  DashboardModule,
  ConceptModule,
  DataAssetModule,
  DebugModule,
  ConceptLayoutModule,
  IntegrationModule,
  NavigationModelModule,
  NotificationModelModule,
  PlatformConfigurationModule,
  PlatformInitializationModule,
  PlatformModule,
  ReferenceModule,
  ResourceModule,
  TemplateModule,
  TypeModule,
};

export default modules;
