import type { AutomationRuleStoreObject } from 'yooi-modules/modules/automationModule';
import { AutomationRule_Name } from 'yooi-modules/modules/automationModule/ids';
import type { IntegrationStoreObject } from 'yooi-modules/modules/integrationModule';
import { Integration_Name } from 'yooi-modules/modules/integrationModule/ids';
import type { HistoryChangeIndex, ObjectStoreReadOnly } from 'yooi-store';
// eslint-disable-next-line yooi/no-restricted-dependency
import { OriginSources } from 'yooi-store';
import { dateFormats, formatDisplayDate, fromError } from 'yooi-utils';
import { reportClientTrace } from '../../utils/clientReporterUtils';
import { fetchJSON } from '../../utils/fetchUtils';
import i18n from '../../utils/i18n';
import { formatOrUndef } from '../../utils/stringUtils';
import { getUserName } from '../utils/userUtils';

export const getLastUpdateBy = async ({ getObjectOrNull }: ObjectStoreReadOnly, objectId: string, fieldId: string, dataTime: number | undefined): Promise<string> => {
  try {
    const path = `/history/${objectId}/${fieldId}${dataTime !== undefined ? `?dataTime=${dataTime}` : ''}`;
    const { status, response } = await fetchJSON<{ status: 200, response: { result: HistoryChangeIndex | undefined } } | { status: 500 | 403, response: { status: string } }>(path);
    if (status === 200 && response.result) {
      const { origin, eventTime } = response.result;
      const formattedDate = formatDisplayDate(new Date(eventTime), dateFormats.dateAndHour);
      switch (origin.source) {
        case OriginSources.CLIENT: {
          const user = getObjectOrNull(origin.userId);
          if (user) {
            return i18n`Last updated by ${formatOrUndef(getUserName(user))}\non ${formattedDate}`;
          } else {
            return i18n`Last updated by unknown source\non ${formattedDate}`;
          }
        }
        case OriginSources.API: {
          const integration = getObjectOrNull<IntegrationStoreObject>(origin.integrationId);
          if (integration?.[Integration_Name]) {
            return i18n`Last updated by integration '${integration[Integration_Name]}'\non ${formattedDate}`;
          } else {
            return i18n`Last updated by integration\non ${formattedDate}`;
          }
        }
        case OriginSources.AUTOMATION: {
          const automation = origin.automationRuleId === undefined ? undefined : getObjectOrNull<AutomationRuleStoreObject>(origin.automationRuleId);

          if (automation?.[AutomationRule_Name]) {
            return i18n`Last updated by automation '${automation[AutomationRule_Name]}'\non ${formattedDate}`;
          } else {
            return i18n`Last updated by automation\non ${formattedDate}`;
          }
        }
        default:
          return i18n`Last updated by ${origin.source.toLowerCase()}\non ${formattedDate}`;
      }
    } else {
      return i18n`No history`;
    }
  } catch (e) {
    reportClientTrace(fromError(e, 'Unable to request last history update', { objectId, fieldId, dataTime }));
    return i18n`Unable to request last update`;
  }
};
