import type { FunctionComponent } from 'react';
import { Concept } from 'yooi-modules/modules/conceptModule/ids';
import { conceptType } from 'yooi-modules/modules/conceptModule/utils/formula/modelFunctions';
import { rhythmicTimeseriesOf } from 'yooi-modules/modules/conceptModule/utils/formula/timeseriesFunctions';
import { arrayOf, NaS, numberType, textType } from 'yooi-utils';
import BaseLayout from '../../../components/templates/BaseLayout';
import BlockTitle from '../../../components/templates/BlockTitle';
import Header from '../../../components/templates/Header';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import i18n from '../../../utils/i18n';
import { NavigationElementContainer } from '../../../utils/useNavigation';
import HeaderStatic from '../../_global/HeaderStatic';
import TopBar from '../../_global/topBar/TopBar';
import FormulaFunctionBlock from './FormulaFunctionBlock';
import FormulaPlaygroundBlock from './FormulaPlaygroundBlock';

const NaNInput = {
  kind: 'constant',
  name: 'NaN',
  type: numberType,
  value: Number.NaN,
} as const;
const NaSInput = {
  kind: 'constant',
  name: 'NaS',
  type: textType,
  value: NaS(),
} as const;

const FormulaDocumentationV2: FunctionComponent = () => (
  <NavigationElementContainer element={{ key: 'formula' }}>
    <BaseLayout
      topBar={(<TopBar />)}
      header={(<Header firstLine={(<HeaderStatic text={i18n`Formula documentation`} />)} />)}
      content={(
        <VerticalBlock>
          <FormulaPlaygroundBlock />
          <VerticalBlock asBlockContent withSeparation>
            <BlockTitle title={i18n`Statistical functions`} anchor="#statisticalfunctions" />
            <FormulaFunctionBlock
              name="COUNT"
              syntax="COUNT(arg1, [arg2], …)"
              output={i18n`Number`}
              description={i18n`Accepts any number of arguments and returns the count of non-empty arguments.\nIf an argument is an array, it counts the number of non-empty elements within that array.`}
              example={['COUNT(58, "France", NULL, "", "USA")', 'COUNT({"Lyon", "Paris", NULL})']}
            />
            <FormulaFunctionBlock
              name="MIN"
              syntax="MIN(number1, [number2], …)"
              output={[i18n`Number`, 'NULL']}
              description={i18n`Accepts any number of number/date arguments and returns the min value.\nIf an argument is an array, it returns the min value within that array.`}
              example={['MIN(4 ,6, 12, NULL)', 'MIN({2, 4, 6})', 'MIN({10, NULL, 12}, 5)', 'MIN(NULL)']}
            />
            <FormulaFunctionBlock
              name="MAX"
              syntax="MAX(number1, [number2], …)"
              output={[i18n`Number`, 'NULL']}
              description={i18n`Accepts any number of number/date arguments and returns the max value.\nIf an argument is an array, it returns the max value within that array.`}
              example={['MAX(4 ,6, 12, NULL)', 'MAX({2, 4, 6})', 'MAX({10, NULL, 12}, 5)', 'MAX(NULL)']}
            />
            <FormulaFunctionBlock
              name="AVERAGE"
              syntax="AVERAGE(number1, [number2], ...)"
              output={[i18n`Number`, 'NaN']}
              description={i18n`Accepts any number of number arguments and returns the average value.\nIf an argument is an array, it take the average value of the array.\nIf the average cannot be computed, it returns NaN (Not a Number).`}
              example={['AVERAGE(10, 8, 9)', 'AVERAGE(10, 8, NULL)', 'AVERAGE(10, 20, {30, 40})', 'AVERAGE(NULL)']}
            />
            <FormulaFunctionBlock
              name="AVERAGEW"
              syntax="AVERAGEW(number1, weight1, number2, weight2, ...)"
              output={[i18n`Number`, 'NULL']}
              description={i18n`Accepts multiple pairs of arguments, each composed of a number (or an array of numbers) followed by its corresponding weight, and returns the weighted average.\nIf an array is provided, all values within the array will receive the same weight.`}
              example={['AVERAGEW(10, 2, 8, 3, 9, 3)', 'AVERAGEW(NULL, 2, 8, 3, 9, 3)', 'AVERAGEW({10, 20}, 2, {5, 20}, 3, 9, 3)', 'AVERAGEW(NULL, 2)']}
            />
            <FormulaFunctionBlock
              name="COMPLETENESS"
              syntax="COMPLETENESS(arg1, [arg2], …)"
              output={i18n`Number`}
              description={i18n`Accepts any number of arguments and returns the ratio of completed (non-null, non-empty) arguments to the total number of arguments.`}
              example={['COMPLETENESS(58, NULL, 35, 86)', 'COMPLETENESS(58, NULL, "", "Value", {})']}
            />
          </VerticalBlock>
          <VerticalBlock asBlockContent withSeparation>
            <BlockTitle title={i18n`Mathematical functions`} anchor="#mathematicalfunctions" />
            <FormulaFunctionBlock
              name="ABS"
              syntax="ABS(number)"
              output={[i18n`Number`, 'NaN']}
              description={i18n`Accepts a single number and returns its absolute value.\nIf the value is NULL, it returns NaN (Not a Number).`}
              example={['ABS(-2)', 'ABS(42)', 'ABS(NULL)']}
            />
            <FormulaFunctionBlock
              name="MOD"
              syntax="MOD(number, divisor)"
              output={[i18n`Number`, 'NaN']}
              description={i18n`Accepts a number and a divisor argument and returns the remainder from division.\nIf the value is NULL, it returns NaN (Not a Number).`}
              example={['MOD(3, 2)', 'MOD(NULL, 2)']}
            />
            <FormulaFunctionBlock
              name="POWER"
              syntax="POWER(number, power)"
              output={[i18n`Number`, 'NaN']}
              description={i18n`Accepts a number and a power argument and returns the number raised to the specified power.\nIf the number is NULL, it returns NaN (Not a Number).`}
              example={['POWER(5,2)', 'POWER(NULL,2)']}
            />
            <FormulaFunctionBlock
              name="PRODUCT"
              syntax="PRODUCT(number1, [number2], ...)"
              output={i18n`Number`}
              description={i18n`Accepts any number of number arguments and returns the product of all non-NULL values.\nReturns 1 if all arguments are NULL.`}
              example={['PRODUCT(25, 15, 1, 30)', 'PRODUCT(NULL)']}
            />
            <FormulaFunctionBlock
              name="QUOTIENT"
              syntax="QUOTIENT(numerator, denominator)"
              output={[i18n`Number`, 'NaN']}
              description={i18n`Accepts a numerator and a denominator argument and returns the integer portion of the division result.\nIf the numerator is NULL, it returns NaN (Not a Number).`}
              example={['QUOTIENT(5, 2)', 'QUOTIENT(NULL, 2)']}
            />
            <FormulaFunctionBlock
              name="SUM"
              syntax="SUM(number1, [number2], …)"
              output={i18n`Number`}
              description={i18n`Accepts any number of number arguments and returns the sum of all non-NULL values.\nReturns 0 if all arguments are NULL.`}
              example={['SUM(5, 10)', 'SUM(NULL)']}
            />
            <FormulaFunctionBlock
              name="INT"
              syntax="INT(number)"
              output={[i18n`Number`, 'NaN']}
              description={i18n`Accepts a single number and returns the rounded-down nearest integer.\nIf the number is NULL, it returns NaN (Not a Number).`}
              example={['INT(8.9)', 'INT(-8.9)', 'INT(NULL)']}
            />
            <FormulaFunctionBlock
              name="TRUNC"
              syntax="TRUNC(number, [num_digits])"
              output={[i18n`Number`, 'NaN']}
              description={i18n`Accepts a number argument and an optional number of digits and returns the number truncated to the specified number of digits.\nIf the number is NULL, it returns NaN (Not a Number).`}
              example={['TRUNC(3.14159265359)', 'TRUNC(3.14159265359, 2)', 'TRUNC(12345, -2)', 'TRUNC(NULL, 2)']}
            />
            <FormulaFunctionBlock
              name="ROUND"
              syntax="ROUND(number, num_digits)"
              output={[i18n`Number`, 'NaN']}
              description={i18n`Accepts a number argument and an optional number of digits and returns the number rounded to the specified number of digits.\nIf the number is NULL, it returns NaN (Not a Number).`}
              example={['ROUND(3.49)', 'ROUND(3.51)', 'ROUND(3.14159265359, 3)', 'ROUND(12345, -3)', 'ROUND(98765, -3)', 'ROUND(NULL, 2)']}
            />
            <FormulaFunctionBlock
              name="ROUNDDOWN"
              syntax="ROUNDDOWN(number, num_digits)"
              output={[i18n`Number`, 'NaN']}
              description={i18n`Accepts a number argument and an optional number of digits and returns the number rounded down to the specified number of digits.\nIf the number is NULL, it returns NaN (Not a Number).`}
              example={['ROUNDDOWN(3.49)', 'ROUNDDOWN(3.51)', 'ROUNDDOWN(3.14159265359, 3)', 'ROUNDDOWN(12345, -3)', 'ROUNDDOWN(98765, -3)', 'ROUNDDOWN(NULL, 2)']}
            />
            <FormulaFunctionBlock
              name="ROUNDUP"
              syntax="ROUNDUP(number, num_digits)"
              output={[i18n`Number`, 'NaN']}
              description={i18n`Accepts a number argument and an optional number of digits and returns the number rounded up to the specified number of digits.\nIf the number is NULL, it returns NaN (Not a Number).`}
              example={['ROUNDUP(3.49)', 'ROUNDUP(3.51)', 'ROUNDUP(3.14159265359, 3)', 'ROUNDUP(12345, -3)', 'ROUNDUP(98765, -3)', 'ROUNDUP(NULL, 2)']}
            />
            <FormulaFunctionBlock
              name="MROUND"
              syntax="MROUND(number, multiple)"
              output={[i18n`Number`, 'NaN']}
              description={i18n`Accepts a number argument and a multiple number and returns the number rounded to the specified multiple.\nIf the number is NULL, it returns NaN (Not a Number).`}
              example={['MROUND(2, 3)', 'MROUND(4, 3)', 'MROUND(12345, 42)', 'MROUND(NULL, 2)']}
            />
            <FormulaFunctionBlock
              name="FLOOR"
              syntax="FLOOR(number, significance)"
              output={[i18n`Number`, 'NaN']}
              description={i18n`Accepts a number argument and significance argument and return the rounded down number, to the nearest multiple of significance.\nIf the number is NULL, it returns NaN (Not a Number).`}
              example={['FLOOR(3.7, 2)', 'FLOOR(3.7, 1.6)', 'FLOOR(3.7, 4)', 'FLOOR(NULL, 4)']}
            />
            <FormulaFunctionBlock
              name="CEILING"
              syntax="CEILING(number, significance)"
              output={[i18n`Number`, 'NaN']}
              description={i18n`Accepts a number argument and significance argument and return the rounded up number, to the nearest multiple of significance.\nIf the number is NULL, it returns NaN (Not a Number).`}
              example={['CEILING(3.7, 2)', 'CEILING(3.7, 1.6)', 'CEILING(3.7, 4)', 'CEILING(NULL, 4)']}
            />
            <FormulaFunctionBlock
              name="EXP"
              syntax="EXP(number)"
              output={[i18n`Number`, 'NaN']}
              description={i18n`Accepts a number argument and returns the number raised to the power of number.\nIf the number is NULL, it returns NaN (Not a Number).`}
              example={['EXP(1)', 'EXP(5)', 'EXP(NULL)']}
            />
            <FormulaFunctionBlock
              name="LN"
              syntax="LN(number)"
              output={[i18n`Number`, 'NaN']}
              description={i18n`Accepts a number argument and returns the natural logarithm of the number.\nIf the number is NULL, it returns NaN (Not a Number).`}
              example={['LN(1)', 'LN(5)', 'LN(NULL)']}
            />
            <FormulaFunctionBlock
              name="LOG"
              syntax="LOG(number, [base])"
              output={[i18n`Number`, 'NaN']}
              description={i18n`Accepts a number and an optional base number argument and returns the logarithm of a number to the specified base.\nIf the number is NULL, it returns NaN (Not a Number).`}
              example={['LOG(8, 2)', 'LOG(10, 3)', 'LOG(NULL, 3)']}
            />
            <FormulaFunctionBlock
              name="LOG10"
              syntax="LOG10(number)"
              output={[i18n`Number`, 'NaN']}
              description={i18n`Accepts a number argument and returns the base-10 logarithm of the number.\nIf the number is NULL, it returns NaN (Not a Number).`}
              example={['LOG10(8)', 'LOG10(10)', 'LOG10(NULL)']}
            />
            <FormulaFunctionBlock
              name="SQRT"
              syntax="SQRT(number)"
              output={[i18n`Number`, 'NaN']}
              description={i18n`Accepts a number argument and returns the square root of the number.\nIf the number is NULL, it returns NaN (Not a Number).`}
              example={['SQRT(16)', 'SQRT(NULL)']}
            />
            <FormulaFunctionBlock
              name="ZN"
              syntax="ZN(value)"
              output={i18n`Number`}
              description={i18n`Accepts a number argument and returns the argument if not null, 0 otherwise.`}
              example={['ZN(42)', 'ZN(NULL)']}
            />
            <FormulaFunctionBlock
              name="IFNAN"
              syntax="IFNAN(number, number)"
              output={i18n`Number`}
              description={i18n`Accepts two number arguments. If the first argument is NaN (Not a Number), it returns the second argument. Otherwise, it returns the first one.`}
              example={['IFNAN(42, 99)', ['IFNAN(', NaNInput, ', 99)']]}
            />
            <FormulaFunctionBlock
              name="ISNAN"
              syntax="ISNAN(number)"
              output={i18n`Boolean`}
              description={i18n`Accepts a single number arguments and returns TRUE if the argument is NaN (Not a Number), FALSE otherwise.`}
              example={['ISNAN(42)', ['ISNAN(', NaNInput, ')']]}
            />
          </VerticalBlock>
          <VerticalBlock asBlockContent withSeparation>
            <BlockTitle title={i18n`Logical functions`} anchor="#logicalfunctions" />
            <FormulaFunctionBlock
              name="TRUE"
              syntax="TRUE()"
              output={i18n`Boolean`}
              description={i18n`Return TRUE.`}
              example={['TRUE()']}
            />
            <FormulaFunctionBlock
              name="FALSE"
              syntax="FALSE()"
              output={i18n`Boolean`}
              description={i18n`Return FALSE.`}
              example={['FALSE()']}
            />
            <FormulaFunctionBlock
              name="NOT"
              syntax="NOT(boolean)"
              output={i18n`Boolean`}
              description={i18n`Accepts a single boolean argument and return the opposite.`}
              example={['NOT(TRUE)', 'NOT(FALSE)', 'NOT(50>100)']}
            />
            <FormulaFunctionBlock
              name="AND"
              syntax="AND(boolean1, [boolean2], ...)"
              output={i18n`Boolean`}
              description={i18n`Accepts any number of boolean arguments and returns TRUE if all of its arguments are TRUE, FALSE otherwise.`}
              example={['AND(TRUE, TRUE, TRUE)', 'AND(FALSE, FALSE, FALSE)', 'AND(TRUE, FALSE, TRUE, TRUE)', 'AND(50>1, 80<100)']}
            />
            <FormulaFunctionBlock
              name="OR"
              syntax="OR(boolean1, [boolean2], ...)"
              output={i18n`Boolean`}
              description={i18n`Accepts any number of boolean arguments and returns TRUE if one of its arguments are TRUE, FALSE otherwise.`}
              example={['OR(TRUE, TRUE, TRUE)', 'OR(FALSE, FALSE, FALSE)', 'OR(TRUE, FALSE, TRUE, TRUE)', 'OR(50>1, 120<100)']}
            />
            <FormulaFunctionBlock
              name="XOR"
              syntax="XOR(boolean1, [boolean2],…)"
              output={i18n`Boolean`}
              description={i18n`Accepts any number of boolean arguments and returns TRUE if the number of TRUE arguments is odd, FALSE otherwise.`}
              example={['XOR(TRUE, TRUE)', 'XOR(TRUE, FALSE)', 'XOR(TRUE, TRUE, TRUE)', 'XOR(TRUE, FALSE, TRUE)', 'XOR(3>0, 2<9)']}
            />
            <FormulaFunctionBlock
              name="IF"
              syntax="IF(boolean, value_if_true, [value_if_false])"
              output={i18n`Type of value_if_true/value_if_false`}
              description={i18n`Accepts a boolean argument and one or two result arguments (of the same type). If the boolean argument is TRUE, returns the first result argument, the second one otherwise (NULL if the second argument is not defined).`}
              example={['IF(60>50, "Over Budget", "OK")', 'IF(50>60, "Over Budget", "OK")', 'IF(50>60, "Over Budget")']}
            />
            <FormulaFunctionBlock
              name="IFS"
              syntax="IFS(boolean1, value_if_true1, [boolean2, value_if_true2], [boolean3, value_if_true3],…, [value_if_false])"
              output={i18n`Type of value_if_trueX/value_if_false`}
              description={i18n`Accepts any number of pair of boolean argument and result argument and a final optional "result if FALSE" argument. Returns the first result argument with an associated TRUE boolean argument. If none of the boolean argument is TRUE, return the final 'result is FALSE' argument (or NULL if not defined).`}
              example={['IFS(25>50, "Over Budget", 30>15, "Done")', 'IFS(25>50, "Over Budget", 25>30, "Done")', 'IFS(25>50, "Over Budget", 25>30, "Done", "Invalid")']}
            />
            <FormulaFunctionBlock
              name="COALESCE"
              syntax="COALESCE(value1, value2, …)"
              output={i18n`Type of value1/value2/...`}
              description={i18n`Accepts any number of arguments and return the first non NULL argument, NULL otherwise.`}
              example={['COALESCE(NULL, 42, 10)', 'COALESCE(NULL, NULL)']}
            />
            <FormulaFunctionBlock
              name="ISNUMBER"
              syntax="ISNUMBER(value)"
              output={i18n`Boolean`}
              description={i18n`Accepts a single argument and returns TRUE if the argument is a number.`}
              example={['ISNUMBER(42)', 'ISNUMBER(NULL)', 'ISNUMBER("Hello, world!")', ['ISNUMBER(', NaNInput, ')']]}
            />
            <FormulaFunctionBlock
              name="SWITCH"
              syntax="SWITCH(expression, value1, result1, [value2, result2], …, [default_result])"
              output={i18n`Type of result1/result2/.../default_result`}
              description={i18n`Accepts a value argument followed by any number of value/result arguments and a final default result argument. Returns the result corresponding to the first matching value argument, the default result argument otherwise.`}
              example={['SWITCH(3, 6, "Saturday", 7, "Sunday", "weekday")', 'SWITCH(7, 6, "Saturday", 7, "Sunday", "weekday")']}
            />
          </VerticalBlock>
          <VerticalBlock asBlockContent withSeparation>
            <BlockTitle title={i18n`Date functions`} anchor="#datefunctions" />
            <FormulaFunctionBlock
              name="DATECONVERT"
              syntax="DATECONVERT(date, periodicity)"
              output={[i18n`Number`, 'NULL']}
              description={i18n`Accepts a date/number argument followed by a periodicity and returns the timestamp of the start of the first period starting after the date.\nAccepted periodicities are one of "DAY", "WEEK", "MONTH", "QUARTER" & "YEAR".`}
              example={['DATECONVERT(1625890400000, "MONTH")', 'DATECONVERT("2024-04-07", "MONTH")', 'DATECONVERT(NULL, "MONTH")']}
            />
            <FormulaFunctionBlock
              name="DATEVALUE"
              syntax="DATEVALUE(date)"
              output={[i18n`Number`, 'NULL']}
              description={i18n`Accepts a single date/number argument and returns a serial representation of it.`}
              example={['DATEVALUE("2011-08-22")', 'DATEVALUE(1625890400000)', 'DATEVALUE(NULL)']}
            />
            <FormulaFunctionBlock
              name="DATEDIF"
              syntax="DATEDIF(start_date, end_date, period)"
              output={i18n`Number`}
              description={i18n`Accepts two date/number argument and a period argument. Returns the number of periods between two dates.\nAccepted period are "Y", "M" and "D".`}
              example={['DATEDIF("2001-06-01","2002-01-15","Y")', 'DATEDIF("2001-06-01","2004-08-15","Y")', 'DATEDIF("2001-06-01","2001-07-15","M")', 'DATEDIF("2001-06-10","2001-07-01","M")']}
            />
            <FormulaFunctionBlock
              name="EARLIESTAFTER"
              syntax="EARLIESTAFTER(dateObject1, [dateObject2], ...)"
              output={[i18n`Number`, 'NULL']}
              description={i18n`Accepts any number of date/number arguments and returns the first day after all arguments.`}
              example={['EARLIESTAFTER(1625090400000, 1622505600000)', 'EARLIESTAFTER(NULL)']}
            />
            <FormulaFunctionBlock
              name="TODAY"
              syntax="TODAY()"
              output={i18n`Number`}
              description={i18n`Return a serial number representing the current day.`}
              example={['TODAY()']}
            />
            <FormulaFunctionBlock
              name="DATESTR"
              syntax="DATESTR(date)"
              output={[i18n`Text`, 'NULL']}
              description={i18n`Accepts a single date/number argument and return its text representation with the format "yyyy/MM/dd HH:mm:ss".`}
              example={['DATESTR(1720528557000)', 'DATESTR(NULL)']}
            />
            <FormulaFunctionBlock
              name="YEAR"
              syntax="YEAR(date)"
              output={[i18n`Number`, 'NULL']}
              description={i18n`Accepts a single number/date argument and returns the corresponding year.`}
              example={['YEAR(1699369382000)', 'YEAR("2024-04-07")', 'YEAR(NULL)']}
            />
            <FormulaFunctionBlock
              name="MONTH"
              syntax="MONTH(date)"
              output={[i18n`Number`, 'NULL']}
              description={i18n`Accepts a single number/date argument and returns the corresponding month.\nThe month is given as an integer, ranging from 1 (January) to 12 (December).`}
              example={['MONTH(1699369382000)', 'MONTH("2024-04-07")', 'MONTH(NULL)']}
            />
            <FormulaFunctionBlock
              name="WEEK"
              syntax="WEEK(date)"
              output={[i18n`Number`, 'NULL']}
              description={i18n`Accepts a single number/date argument and returns the corresponding week.\nThe week is given as an integer ranging from 1 to 52.`}
              example={['WEEK(1699369382000)', 'WEEK("2024-04-07")', 'WEEK(NULL)']}
            />
            <FormulaFunctionBlock
              name="DAY"
              syntax="DAY(date)"
              output={[i18n`Number`, 'NULL']}
              description={i18n`Accepts a single number/date argument and returns the corresponding day.\nThe day is given as an integer ranging from 1 to 31.`}
              example={['DAY(1699369382000)', 'DAY("2024-04-07")', 'DAY(NULL)']}
            />
            <FormulaFunctionBlock
              name="WEEKDAY"
              syntax="WEEKDAY(date, returnType)"
              output={[i18n`Number`, 'NULL']}
              description={i18n`Accepts a number/date argument and a number representing a return type.\nReturns the corresponding day as an integer, ranging from 1 (Sunday) to 7 (Saturday), by default. To get a week starting on Monday, pass 2 a second argument.`}
              example={['WEEKDAY(1699369382000)', 'WEEKDAY(1699369382000, 2)', 'WEEKDAY("2024-04-07")', 'WEEKDAY("2024-04-07", 2)', 'WEEKDAY(NULL, 2)']}
            />
            <FormulaFunctionBlock
              name="DAYS"
              syntax="DAYS(start_serial, end_serial)"
              output={i18n`Number`}
              description={i18n`Accepts two number (date serial) arguments and returns the number of days between the two dates.`}
              example={['DAYS(DATEVALUE(1699369382000), DATEVALUE(1699788957000))', 'DAYS("2024-04-07", "2024-04-15")']}
            />
          </VerticalBlock>
          <VerticalBlock asBlockContent withSeparation>
            <BlockTitle title={i18n`Timeseries functions`} anchor="#timeseriesfunctions" />
            <FormulaFunctionBlock
              name="LASTVALUE"
              syntax="LASTVALUE(timeseries, date, defaultValue)"
              output={i18n`Type of the value of the timeseries argument`}
              description={i18n`Accepts a timeseries argument, an optional date argument, and an optional default value argument.\nIt returns the last value of the timeseries before the specified date. If the date argument is not provided, it returns the last value of the timeseries. If no value is found, it returns the default value.`}
              example={[
                [
                  'LASTVALUE(',
                  {
                    kind: 'constant',
                    name: 'TimeseriesInput',
                    type: rhythmicTimeseriesOf(numberType),
                    value: [{ time: 1622505600000, value: 10 }, { time: 1625090400000, value: 12 }, { time: 2955090400000, value: 20 }],
                  },
                  ', NOW())',
                ],
                [
                  'LASTVALUE(',
                  {
                    kind: 'constant',
                    name: 'TimeseriesInput',
                    type: rhythmicTimeseriesOf(numberType),
                    value: [{ time: 1622505600000, value: 10 }],
                  },
                  ', 1622505600000, 10)',
                ],
                [
                  'LASTVALUE(',
                  {
                    kind: 'constant',
                    name: 'TimeseriesInput',
                    type: rhythmicTimeseriesOf(numberType),
                    value: [{ time: 1622505600000, value: 10 }],
                  },
                  ', 1000000000000, 42)',
                ],
                [
                  'LASTVALUE(',
                  {
                    kind: 'constant',
                    name: 'EmptyTimeseriesInput',
                    type: rhythmicTimeseriesOf(numberType),
                    value: [],
                  },
                  ', NULL, 42)',
                ],
              ]}
            />
            <FormulaFunctionBlock
              name="VALUEAT"
              syntax="VALUEAT(timeseries, date, defaultValue)"
              output={i18n`Type of the value of the timeseries argument`}
              description={i18n`Accepts a timeseries argument, a date argument, and an optional default value argument.\nIt returns the value of the timeseries at the specified date, the default value otherwise.`}
              example={[
                [
                  'VALUEAT(',
                  {
                    kind: 'constant',
                    name: 'TimeseriesInput',
                    type: rhythmicTimeseriesOf(numberType),
                    value: [{ time: 1622505600000, value: 10 }, { time: 1625090400000, value: 12 }, { time: 2955090400000, value: 20 }],
                  },
                  ', 1622505600000)',
                ],
                [
                  'VALUEAT(',
                  {
                    kind: 'constant',
                    name: 'TimeseriesInput',
                    type: rhythmicTimeseriesOf(numberType),
                    value: [{ time: 1622505600000, value: 10 }, { time: 1625090400000, value: 12 }, { time: 2955090400000, value: 20 }],
                  },
                  ', 1622505600001)',
                ],
                [
                  'VALUEAT(',
                  {
                    kind: 'constant',
                    name: 'TimeseriesInput',
                    type: rhythmicTimeseriesOf(numberType),
                    value: [{ time: 1622505600000, value: 10 }, { time: 1625090400000, value: 12 }, { time: 2955090400000, value: 20 }],
                  },
                  ', 1622505600001, 42)',
                ],
                [
                  'VALUEAT(',
                  {
                    kind: 'constant',
                    name: 'EmptyTimeseriesInput',
                    type: rhythmicTimeseriesOf(numberType),
                    value: [],
                  },
                  ', NULL, 42)',
                ],
              ]}
            />
            <FormulaFunctionBlock
              name="VALUESIN"
              syntax="VALUESIN(timeseries, startDate, endDate)"
              output={i18n`Array of the type of the value of the timeseries argument`}
              description={i18n`Accepts a timeseries argument, an optional start date argument, and an optional end date argument. It returns an array of all values of the timeseries between start date and end date (start date and end date included).`}
              example={[

                [
                  'VALUESIN(',
                  {
                    kind: 'constant',
                    name: 'TimeseriesInput',
                    type: rhythmicTimeseriesOf(numberType),
                    value: [{ time: 1622505600000, value: 10 }, { time: 1625090400000, value: 12 }, { time: 2955090400000, value: 20 }],
                  },
                  ', 1622505600000, 1625090400000)',
                ],
                [
                  'VALUESIN(',
                  {
                    kind: 'constant',
                    name: 'TimeseriesInput',
                    type: rhythmicTimeseriesOf(numberType),
                    value: [{ time: 1622505600000, value: 10 }, { time: 1625090400000, value: 12 }, { time: 2955090400000, value: 20 }],
                  },
                  ', 1625090400000)',
                ],
                [
                  'VALUESIN(',
                  {
                    kind: 'constant',
                    name: 'TimeseriesInput',
                    type: rhythmicTimeseriesOf(numberType),
                    value: [{ time: 1622505600000, value: 10 }, { time: 1625090400000, value: 12 }, { time: 2955090400000, value: 20 }],
                  },
                  ', NULL, 1625090400000)',
                ],
                [
                  'VALUESIN(',
                  {
                    kind: 'constant',
                    name: 'TimeseriesInput',
                    type: rhythmicTimeseriesOf(numberType),
                    value: [{ time: 1622505600000, value: 10 }, { time: 1625090400000, value: 12 }, { time: 2955090400000, value: 20 }],
                  },
                  ')',
                ],
              ]}
            />
          </VerticalBlock>
          <VerticalBlock asBlockContent withSeparation>
            <BlockTitle title={i18n`Text functions`} anchor="#textfunctions" />
            <FormulaFunctionBlock
              name="CLEAN"
              syntax="CLEAN(text)"
              output={[i18n`Text`, 'NaS']}
              description={i18n`Accepts a single text argument and return the same text with all non-printable characters removed.\nIf the value is NULL, it returns NaS (Not a String).`}
              example={['CLEAN("\tHello,\tworld!")', 'CLEAN(NULL)']}
            />
            <FormulaFunctionBlock
              name="CONCATENATE / CONCAT"
              anchor="#concatenate"
              syntax="CONCATENATE(text1, [text2], ...)"
              output={i18n`Text`}
              description={i18n`Accepts any number of text argument and return a joined single text joining all argument into one text.\nNULL arguments are ignored.`}
              example={['CONCATENATE("Hello", " ", "World")', 'CONCATENATE("Hello", NULL, "World")']}
            />
            <FormulaFunctionBlock
              name="ES"
              syntax="ES(value)"
              output={i18n`Text`}
              description={i18n`Accepts a text argument and returns the argument if not NULL, empty string otherwise.`}
              example={['ES("Hello, world!")', 'ES(NULL)']}
            />
            <FormulaFunctionBlock
              name="IFNAS"
              syntax="IFNAS(text, text)"
              output={i18n`Text`}
              description={i18n`Accepts two text arguments. If the first argument is NaS (Not a String), it returns the second argument. Otherwise, it returns the first one.`}
              example={['IFNAS("Hello, world!", "Hello, YOOI!")', ['IFNAS(', NaSInput, ', "Hello, YOOI!")']]}
            />
            <FormulaFunctionBlock
              name="ISBLANK"
              syntax="ISBLANK(value)"
              output={i18n`Boolean`}
              description={i18n`Accepts a single argument and returns TRUE if the argument is not NULL or an empty text.`}
              example={['ISBLANK(42)', 'ISBLANK(NULL)', 'ISBLANK("Hello, world!")', 'ISBLANK("")']}
            />
            <FormulaFunctionBlock
              name="ISNAS"
              syntax="ISNAS(text)"
              output={i18n`Boolean`}
              description={i18n`Accepts a single text arguments and returns TRUE if the argument is NaS (Not a String), FALSE otherwise.`}
              example={['ISNAS("Hello, world!")', ['ISNAS(', NaSInput, ')']]}
            />
            <FormulaFunctionBlock
              name="INDEXOF"
              syntax="INDEXOF(text, subtext, position)"
              output={i18n`Number`}
              description={i18n`Accepts a text argument, a subtext argument and an optional position. Returns the index of the first occurrence of the specified subtext into the text. If a position was provided, start the search at the given position. Returns -1 if not found.`}
              example={['INDEXOF("hello world", "world")', 'INDEXOF("hello world", "h")', 'INDEXOF("hello world", "missing")', 'INDEXOF("hello world", "world", 7)', 'INDEXOF("hello world", "o")', 'INDEXOF("hello world", "o", 5)']}
            />
            <FormulaFunctionBlock
              name="LEFT"
              syntax="LEFT(text, numberOfChars)"
              output={[i18n`Text`, 'NaS']}
              description={i18n`Accepts a text argument and an optional number of characters. Returns the first character or n first characters.\nIf the value is NULL, it returns NaS (Not a String).`}
              example={['LEFT("Hello world!")', 'LEFT("Hello world!", 5)', 'LEFT(NULL)']}
            />
            <FormulaFunctionBlock
              name="LEN"
              syntax="LEN(text)"
              output={i18n`Number`}
              description={i18n`Accepts a single text argument and returns the number of characters.\nIf the value is NULL, it returns NaN (Not a Number).`}
              example={['LEN("Hello world!")', 'LEN(NULL)']}
            />
            <FormulaFunctionBlock
              name="LOWER"
              syntax="LOWER(text)"
              output={[i18n`Text`, 'NaS']}
              description={i18n`Accepts a single text argument and return the same text with all uppercase letters converted to lowercase.\nIf the value is NULL, it returns NaS (Not a String).`}
              example={['LOWER("Hello World!")', 'LOWER(NULL)']}
            />
            <FormulaFunctionBlock
              name="MID"
              syntax="MID(text, startPosition, length)"
              output={[i18n`Text`, 'NaS']}
              description={i18n`Accepts a text argument, a start position number argument and a length number argument. Returns a subset of the text argument starting at start position and with the specified length.\nIf the value is NULL, it returns NaS (Not a String).`}
              example={['MID("Hello world!", 7, 5)', 'MID(NULL, 7, 5)']}
            />
            <FormulaFunctionBlock
              name="PROPER"
              syntax="PROPER(text)"
              output={[i18n`Text`, 'NaS']}
              description={i18n`Accepts a single text argument and return the same text with all words converted to have an uppercase first letter.\nIf the value is NULL, it returns NaS (Not a String).`}
              example={['PROPER("hellO wOrld!")', 'PROPER(NULL)']}
            />
            <FormulaFunctionBlock
              name="REPLACE"
              syntax="REPLACE(text, startPosition, numberOfCharacters, newText)"
              output={[i18n`Text`, 'NaS']}
              description={i18n`Accepts a text argument, a start position argument, a number of characters argument and a new text argument. Replaces part of a text, based on the position and the number of characters you specify, with a different text and returns the result.\nIf the value is NULL, it returns NaS (Not a String).`}
              example={['REPLACE("Hello World!", 7, 5, "YOOI")', 'REPLACE(NULL, 7, 5, "YOOI")']}
            />
            <FormulaFunctionBlock
              name="RIGHT"
              syntax="RIGHT(text, numberOfChars)"
              output={[i18n`Text`, 'NaS']}
              description={i18n`Accepts a text argument and an optional number of characters. Returns the last character or n last characters.\nIf the value is NULL, it returns NaS (Not a String).`}
              example={['RIGHT("Hello world!")', 'RIGHT("Hello world!", 6)', 'RIGHT(NULL)']}
            />
            <FormulaFunctionBlock
              name="TEXT"
              syntax="TEXT(value, format)"
              output={i18n`Text`}
              description={i18n`Accepts a number argument and a format argument. Returns the formated number using the provided format.`}
              example={['TEXT(1234.56789, "$#,##0.00")', 'TEXT(0.285, "0.0%")', 'TEXT(DATEVALUE(NOW()), "DDDD DD MMMM YYYY")']}
            />
            <FormulaFunctionBlock
              name="TEXTJOIN"
              syntax="TEXTJOIN(delimiter, ignoreEmpty, ...text)"
              output={i18n`Text`}
              description={i18n`Accepts a delimiter text arguments, a boolean parameter and any number of text argument. Returns a text with all extra argument joined using the given separator. If the boolean argument is TRUE, empty parts are ignored.`}
              example={['TEXTJOIN(" ", FALSE, "Hello", "World", "!")', 'TEXTJOIN(";", TRUE, "First", "", "Second", "", "Third")', 'TEXTJOIN(";", FALSE, "First", "", "Second", "", "Third")']}
            />
            <FormulaFunctionBlock
              name="TRIM"
              syntax="TRIM(text)"
              output={[i18n`Text`, 'NaS']}
              description={i18n`Accepts a single text argument and return the same text with all useless space before and after the text removed.\nIf the value is NULL, it returns NaS (Not a String).`}
              example={['TRIM("     Hello World!    ")', 'TRIM(NULL)']}
            />
            <FormulaFunctionBlock
              name="UPPER"
              syntax="UPPER(text)"
              output={[i18n`Text`, 'NaS']}
              description={i18n`Accepts a single text argument and return the same text with all lowercase letters converted to uppercase.\nIf the value is NULL, it returns NaS (Not a String).`}
              example={['UPPER("Hello World!")', 'UPPER(NULL)']}
            />
          </VerticalBlock>
          <VerticalBlock asBlockContent>
            <BlockTitle title={i18n`Object functions`} anchor="#objectfunctions" />
            <FormulaFunctionBlock
              name="GETOBJECTS"
              syntax="GETOBJECTS(objectOrlist1, [objectOrList2], ...)"
              output={i18n`List of concept instances`}
              description={i18n`Accepts any number of concept or concept array and return an array with unique instances (instances are de-duplicated).`}
              example={[
                [
                  'GETOBJECTS(',
                  {
                    kind: 'constant',
                    name: 'AConcept',
                    type: conceptType(Concept),
                    value: { id: 'a' },
                  },
                  ', ',
                  {
                    kind: 'constant',
                    name: 'BConcept',
                    type: conceptType(Concept),
                    value: { id: 'b' },
                  },
                  ')',
                ],
                [
                  'GETOBJECTS(',
                  {
                    kind: 'constant',
                    name: 'AConcept',
                    type: conceptType(Concept),
                    value: { id: 'a' },
                  },
                  ', ',
                  {
                    kind: 'constant',
                    name: 'ABCConcepts',
                    type: arrayOf(conceptType(Concept)),
                    value: [{ id: 'a' }, { id: 'b' }, { id: 'c' }],
                  },
                  ')',
                ],
                [
                  'GETOBJECTS(',
                  {
                    kind: 'constant',
                    name: 'ABCConcepts',
                    type: arrayOf(conceptType(Concept)),
                    value: [{ id: 'a' }, { id: 'b' }, { id: 'c' }],
                  },
                  ', ',
                  {
                    kind: 'constant',
                    name: 'CDEConcepts',
                    type: arrayOf(conceptType(Concept)),
                    value: [{ id: 'c' }, { id: 'd' }, { id: 'e' }],
                  },
                  ')',
                ],
              ]}
            />
          </VerticalBlock>
        </VerticalBlock>
      )}
    />
  </NavigationElementContainer>
);

export default FormulaDocumentationV2;
