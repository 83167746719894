// This file was generated by lezer-generator. You probably shouldn't edit it.
export const
  Formula = 1;
export const Text = 2;
export const Number = 3;
export const Input = 4;
export const Operation = 5;
export const ExponentialOperator = 6;
export const PrefixOperator = 7;
export const PostfixOperator = 8;
export const MultiplicativeOperator = 9;
export const AdditiveOperator = 10;
export const ConcatOperator = 11;
export const ComparisonOperator = 12;
export const ExpressionGroup = 13;
export const Array = 14;
export const FunctionCall = 15;
export const FunctionCallStart = 16;
