import * as parser from 'cron-parser';
import type { FunctionComponent } from 'react';
import type {
  AutomationRuleStoreObject,
  AutomationRuleTrigger,
  CronRule,
  DayRepeat,
  MonthRepeat,
  ScheduledTrigger,
  WeekRepeat,
  YearRepeat,
} from 'yooi-modules/modules/automationModule';
import { extractCronRule, isScheduledTrigger, RepeatType } from 'yooi-modules/modules/automationModule';
import { AutomationRule_Trigger } from 'yooi-modules/modules/automationModule/ids';
import type { DateFieldStoreValue } from 'yooi-utils';
import { dateFormats, formatDisplayDate, getCurrentDay, getCurrentMonth, getLocalZoneName, joinObjects } from 'yooi-utils';
import { IconColorVariant, IconName } from '../../../components/atoms/Icon';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../components/templates/BlockTitle';
import CardBlock from '../../../components/templates/CardBlock';
import HorizontalBlock, { HorizontalBlockVariant } from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import StoreDateInput from '../../_global/input/StoreDateInput';
import { getUnknownChip } from '../../_global/modelTypeUtils';
import AutomationCardTitle from './AutomationCardTitle';
import { getRuleTriggerDescription } from './automationUtils';
import MonthRepeatRuleInput from './MonthRepeatRuleInput';
import RepeatRuleTimesInput from './RepeatRuleTimesInput';
import WeekRepeatRuleInput from './WeekRepeatRuleInput';
import YearRepeatRuleInput from './YearRepeatRuleInput';

interface AutomationRuleScheduledTriggerOptionsProps {
  ruleId: string,
}

const getPreviousExecutions = ({ start, end, rule, extraRule }: CronRule, tz: string): Date[] | undefined => {
  const previousDates: Date[] = [];
  let i = 0;
  const expression = parser.parseExpression(rule, { tz });
  if (end && end < Date.now()) {
    expression.reset(end);
  }
  while (previousDates.length < 2 && i < 1000) {
    const prevDate = expression.prev().toDate();
    if (prevDate.getTime() < start) {
      return previousDates;
    }
    if (extraRule) {
      if (extraRule(prevDate)) {
        previousDates.push(prevDate);
      }
    } else {
      previousDates.push(prevDate);
    }
    i += 1;
  }
  previousDates.reverse();
  return previousDates;
};

const getNextExecutions = ({ start, end, rule, extraRule }: CronRule, tz: string): Date[] | undefined => {
  const nextDates: Date[] = [];
  let i = 0;
  const expression = parser.parseExpression(rule, { tz });
  if (start > Date.now()) {
    expression.reset(start);
  }
  while (nextDates.length < 5 && i < 1000) {
    const nextDate = expression.next().toDate();
    if (end && nextDate.getTime() > end) {
      return nextDates;
    }
    if (extraRule) {
      if (extraRule(nextDate)) {
        nextDates.push(nextDate);
      }
    } else {
      nextDates.push(nextDate);
    }
    i += 1;
  }
  return nextDates;
};

const getExecutions = (
  repeatRule: DayRepeat | WeekRepeat | MonthRepeat | YearRepeat,
  from: DateFieldStoreValue | undefined,
  to: DateFieldStoreValue | undefined,
  { hour, tz }: { hour: number, tz: string }
): { previousDates: Date[] | undefined, nextDates: Date[] | undefined } | undefined => {
  try {
    const cronRule = extractCronRule(repeatRule, from, to, hour, tz);
    if (cronRule) {
      return {
        previousDates: getPreviousExecutions(cronRule, tz),
        nextDates: getNextExecutions(cronRule, tz),
      };
    } else {
      return undefined;
    }
  } catch {
    return undefined;
  }
};

const getRepeatOptions = (): { id: RepeatType, label: string }[] => [
  { id: RepeatType.day, label: i18n`Day` },
  { id: RepeatType.week, label: i18n`Week` },
  { id: RepeatType.month, label: i18n`Month` },
  { id: RepeatType.year, label: i18n`Year` },
];

const useStyles = makeStyles({
  cardContainer: {
    padding: spacingRem.m,
  },
}, 'automationRuleScheduledTriggerOptions');

const AutomationRuleScheduledTriggerOptions: FunctionComponent<AutomationRuleScheduledTriggerOptionsProps> = ({ ruleId }) => {
  const classes = useStyles();

  const store = useStore();

  const scheduledTrigger = store.getObject(ruleId)[AutomationRule_Trigger] as AutomationRuleTrigger | undefined;
  const defaultDay: DayRepeat = { type: RepeatType.day, times: 1 };

  const defaultWeek: WeekRepeat = { type: RepeatType.week, times: 1, weekDays: [1] };

  const defaultMonth: MonthRepeat = {
    type: RepeatType.month,
    times: 1,
    selectedRule: 'dayRule',
    dayRule: { day: getCurrentDay() },
    weekDayRule: { week: 1, weekDay: 1 },
  };

  const defaultYear: YearRepeat = {
    type: RepeatType.year,
    times: 1,
    month: getCurrentMonth(),
    dayRule: { day: getCurrentDay() },
    weekDayRule: { week: 1, weekDay: 1 },
    selectedRule: 'dayRule',
  };

  if (!scheduledTrigger || !isScheduledTrigger(scheduledTrigger)) {
    return null;
  }

  const repeatTypeSearchAndSelect = (
    <SearchAndSelect
      computeOptions={getRepeatOptions}
      placeholder={i18n`Select a frequency`}
      selectedOption={
        scheduledTrigger.repeatRule.type
          ? getRepeatOptions().find(({ id }) => id === scheduledTrigger.repeatRule.type) ?? getUnknownChip(scheduledTrigger.repeatRule.type)
          : undefined
      }
      onSelect={(option) => {
        if (option) {
          const result = getRepeatOptions().find(({ id }) => id === option.id);
          if (result) {
            if (result.id === RepeatType.day) {
              store.updateObject<AutomationRuleStoreObject>(ruleId, {
                [AutomationRule_Trigger]: joinObjects(
                  scheduledTrigger,
                  { repeatRule: joinObjects(defaultDay, { times: scheduledTrigger.repeatRule.times }) }
                ),
              });
            } else if (result.id === RepeatType.week) {
              store.updateObject<AutomationRuleStoreObject>(ruleId, {
                [AutomationRule_Trigger]: joinObjects(
                  scheduledTrigger,
                  { repeatRule: joinObjects(defaultWeek, { times: scheduledTrigger.repeatRule.times }) }
                ),
              });
            } else if (result.id === RepeatType.month) {
              store.updateObject<AutomationRuleStoreObject>(ruleId, {
                [AutomationRule_Trigger]: joinObjects(
                  scheduledTrigger,
                  { repeatRule: joinObjects(defaultMonth, { times: scheduledTrigger.repeatRule.times }) }
                ),
              });
            } else if (result.id === RepeatType.year) {
              store.updateObject<AutomationRuleStoreObject>(ruleId, {
                [AutomationRule_Trigger]: joinObjects(
                  scheduledTrigger,
                  { repeatRule: defaultYear }
                ),
              });
            }
          }
        }
      }}
    />
  );

  const isWeekRule = (trigger: ScheduledTrigger): trigger is ScheduledTrigger<WeekRepeat> => trigger.repeatRule.type === RepeatType.week;
  const isMonthRule = (trigger: ScheduledTrigger): trigger is ScheduledTrigger<MonthRepeat> => trigger.repeatRule.type === RepeatType.month;
  const isYearRule = (trigger: ScheduledTrigger): trigger is ScheduledTrigger<YearRepeat> => trigger.repeatRule.type === RepeatType.year;

  const executions = getExecutions(scheduledTrigger.repeatRule, scheduledTrigger.fromDate, scheduledTrigger.toDate, scheduledTrigger.at);
  let executionsTooltip = i18n`Based on this configuration it would have never run in the past or in the future`;
  if (executions && (executions.previousDates?.length || executions.nextDates?.length)) {
    const formatDate = (date: Date) => {
      let result = `• ${formatDisplayDate(date, dateFormats.dateAndHour, scheduledTrigger.at.tz)} ${scheduledTrigger.at.tz}`;
      const localZoneName = getLocalZoneName();
      if (localZoneName !== scheduledTrigger.at.tz) {
        result += ` (${formatDisplayDate(date, dateFormats.dateAndHour)} ${localZoneName})`;
      }
      return result;
    };
    if (!executions.previousDates || executions.previousDates.length === 0) {
      executionsTooltip = i18n`Based on this configuration it would have never run in the past.`;
      executionsTooltip += '\t\n';
    } else {
      executionsTooltip = i18n`Based on this configuration it would have run on:`;
      executionsTooltip += '\t\n';
      executionsTooltip += executions.previousDates.map(formatDate).join('\n');
      executionsTooltip += '\n';
    }
    executionsTooltip += '\n';
    if (!executions.nextDates || executions.nextDates.length === 0) {
      executionsTooltip += i18n`Based on this configuration it will never run in the future.`;
      executionsTooltip += '\t';
    } else {
      executionsTooltip += i18n`Based on this configuration it will run on:`;
      executionsTooltip += '\t\n';
      executionsTooltip += executions.nextDates.map(formatDate).join('\n');
    }
  }

  const getEndDateError = (): string | undefined => {
    if (scheduledTrigger.fromDate && scheduledTrigger.toDate && scheduledTrigger.fromDate.date > scheduledTrigger.toDate.date) {
      return i18n`Ending is before starting. This trigger will never be executed.`;
    } else if (executions?.nextDates?.length === 0) {
      return i18n`Ending is in the past. This trigger will no longer be executed.`;
    } else {
      return undefined;
    }
  };

  return (
    <VerticalBlock asBlockContent>
      <BlockTitle title={i18n`Schedule`} variant={BlockTitleVariant.inline} />
      <BlockContent padded>
        <CardBlock
          titleContent={(
            <AutomationCardTitle
              label={scheduledTrigger.label || `${getRuleTriggerDescription(store, ruleId)}`}
              onLabelUpdate={(newValue) => {
                if (newValue !== null) {
                  store.updateObject<AutomationRuleStoreObject>(ruleId, { [AutomationRule_Trigger]: joinObjects(scheduledTrigger, { label: newValue }) });
                } else {
                  const newScheduledTrigger = { ...scheduledTrigger };
                  delete newScheduledTrigger.label;
                  store.updateObject<AutomationRuleStoreObject>(ruleId, { [AutomationRule_Trigger]: newScheduledTrigger });
                }
              }}
              icon={IconName.schedule}
              infoIcon={{
                name: executions?.nextDates?.length ? IconName.info : IconName.warning,
                colorVariant: executions?.nextDates?.length ? IconColorVariant.info : IconColorVariant.warning,
                tooltip: executionsTooltip,
              }}
            />
          )}
          content={(
            <div className={classes.cardContainer}>
              <VerticalBlock>
                <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                  <BlockTitle title={i18n`Interval type`} />
                  <BlockContent>
                    {repeatTypeSearchAndSelect}
                  </BlockContent>
                </HorizontalBlock>
                <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                  <BlockTitle title={i18n`Timing`} />
                  <BlockContent padded>
                    <RepeatRuleTimesInput
                      scheduledTrigger={scheduledTrigger}
                      onChange={(newScheduledTrigger) => store.updateObject<AutomationRuleStoreObject>(ruleId, { [AutomationRule_Trigger]: newScheduledTrigger })}
                    />
                  </BlockContent>
                </HorizontalBlock>
                {isWeekRule(scheduledTrigger) && (
                  <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                    <BlockTitle title={i18n`On`} />
                    <BlockContent>
                      <WeekRepeatRuleInput
                        repeatRule={scheduledTrigger.repeatRule}
                        onChange={(weekRepeatRule) => store.updateObject<AutomationRuleStoreObject>(ruleId, {
                          [AutomationRule_Trigger]: joinObjects(scheduledTrigger, { repeatRule: weekRepeatRule }),
                        })}
                      />
                    </BlockContent>
                  </HorizontalBlock>
                )}
                {isMonthRule(scheduledTrigger) && (
                  <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                    <BlockTitle title={i18n`On`} />
                    <BlockContent>
                      <MonthRepeatRuleInput
                        repeatRule={scheduledTrigger.repeatRule}
                        onChange={(monthRepeatRule) => store.updateObject<AutomationRuleStoreObject>(ruleId, {
                          [AutomationRule_Trigger]: joinObjects(scheduledTrigger, { repeatRule: monthRepeatRule }),
                        })}
                      />
                    </BlockContent>
                  </HorizontalBlock>
                )}
                {isYearRule(scheduledTrigger) && (
                  <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                    <BlockTitle title={i18n`On`} />
                    <BlockContent>
                      <YearRepeatRuleInput
                        repeatRule={scheduledTrigger.repeatRule}
                        onChange={(yearRepeatRule) => store.updateObject<AutomationRuleStoreObject>(ruleId, {
                          [AutomationRule_Trigger]: joinObjects(scheduledTrigger, { repeatRule: yearRepeatRule }),
                        })}
                      />
                    </BlockContent>
                  </HorizontalBlock>
                )}
                <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                  <BlockTitle
                    title={i18n`Starting`}
                    mandatoryIcon={{
                      tooltip: scheduledTrigger.fromDate ? undefined : i18n`Required field`,
                      color: scheduledTrigger.fromDate ? IconColorVariant.secondary : undefined,
                    }}
                  />
                  <BlockContent>
                    <StoreDateInput
                      placeholder={i18n`Select a date`}
                      initialValue={scheduledTrigger.fromDate ? { period: scheduledTrigger.fromDate.period, date: scheduledTrigger.fromDate.date } : undefined}
                      onSubmit={(fromDate) => store.updateObject<AutomationRuleStoreObject>(ruleId, { [AutomationRule_Trigger]: joinObjects(scheduledTrigger, { fromDate }) })}
                      UTC
                    />
                  </BlockContent>
                </HorizontalBlock>
                <HorizontalBlock variant={HorizontalBlockVariant.compactInCardWithoutFirstColumn} asBlockContent>
                  <BlockTitle title={i18n`Ending`} />
                  <BlockContent>
                    <StoreDateInput
                      placeholder={i18n`Select a date`}
                      initialValue={scheduledTrigger.toDate ? { period: scheduledTrigger.toDate.period, date: scheduledTrigger.toDate.date } : undefined}
                      onSubmit={(toDate) => store.updateObject<AutomationRuleStoreObject>(ruleId, { [AutomationRule_Trigger]: joinObjects(scheduledTrigger, { toDate }) })}
                      UTC
                      error={getEndDateError()}
                    />
                  </BlockContent>
                </HorizontalBlock>
              </VerticalBlock>
            </div>
          )}
        />
      </BlockContent>
    </VerticalBlock>
  );
};

export default AutomationRuleScheduledTriggerOptions;
