// This file is automatically updated by the generate-constants script. Do not update it manually.

export const TemplateModuleId = 'c9431346-16a4-4401-b4b1-490a821c107a';
export const Template = 'a942d7cc-c448-4a94-9563-36ba234360f6';
export const TemplateConcept = '7ad3c186-c752-4d92-82bd-3e08324ddf4a';
export const TemplateConcept_Mandatory = 'da5f070a-f164-4c2f-b691-ee42ee95781f';
export const TemplateConcept_MappedConcept = '16b62188-95f0-4763-ae2e-50b1fdd0f1d4';
export const TemplateConcept_Metadata = '5e1b5ea2-1842-4142-935d-a1ad489e9388';
export const TemplateConcept_Name = '407bad15-2b09-44c2-ab23-e7660e53745e';
export const TemplateConcept_TemplateConceptDefinition = '5606cfb3-03ad-41a5-85b3-91f6d636735d';
export const TemplateConceptDefinition = '4c3155bc-2c69-4907-9004-0a9230d11858';
export const TemplateConceptDefinition_MappedConceptDefinition = '356bad81-8b5a-4534-82ba-f945da767332';
export const TemplateConceptDefinition_Metadata = 'cfc5710d-c2df-4303-8a19-deda64758607';
export const TemplateConceptDefinition_Name = '9088a74c-db95-4ce3-a489-c113351f526f';
export const TemplateConceptDefinition_Template = 'af28e9e0-0fd0-4f9b-9b60-7f357e939d6e';
export const TemplateField = '2636dce0-728d-4db4-873e-256bdf06217c';
export const TemplateField_MappedField = 'c03e4c17-8c65-4949-86c5-250557e4a82a';
export const TemplateField_Metadata = 'ab22475e-91f4-4e82-84ce-7cd5cfdb8b97';
export const TemplateField_Mode = '6b96cf00-9c02-417f-9346-153a113c7089';
export const TemplateField_Name = '0ef111a2-7fc8-40f3-9939-37c20f2baa2b';
export const TemplateField_TemplateConceptDefinition = '8f40e471-d480-4cc1-97bf-c5a418756cc4';
export const TemplateField_Type = 'f1c457b3-8e4a-4716-a47d-45f52add6dca';
export const ConceptDefinition_MappedTemplateConceptDefinitions = TemplateConceptDefinition_MappedConceptDefinition;
export const Field_MappedTemplateConcepts = TemplateConcept_MappedConcept;
export const Field_MappedTemplateFields = TemplateField_MappedField;
export const FieldDefinition_TemplateFields = TemplateField_Type;
export const Template_TemplateConceptDefinitions = TemplateConceptDefinition_Template;
export const TemplateConceptDefinition_TemplateConcepts = TemplateConcept_TemplateConceptDefinition;
export const TemplateConceptDefinition_TemplateFields = TemplateField_TemplateConceptDefinition;
