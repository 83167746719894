import classnames from 'classnames';
import { clamp } from 'ramda';
import type { FunctionComponent, ReactElement } from 'react';
import { useCallback, useRef, useState } from 'react';
import type { IColor } from 'react-color-palette';
import { ColorService, Hue, Saturation } from 'react-color-palette';
import 'react-color-palette/dist/css/rcp.css';
import { joinObjects } from 'yooi-utils';
import StoreTextInputField from '../../app/_global/input/StoreTextInputField';
import type { Option } from '../../app/_global/modelTypeUtils';
import { computeLightShade } from '../../app/utils/standardColorsUtils';
import base, { Color } from '../../theme/base';
import { spacingRem } from '../../theme/spacingDefinition';
import i18n, { i18nMap } from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { getPlatformAccentColor } from '../../utils/options';
import { remToPx } from '../../utils/sizeUtils';
import useDerivedState from '../../utils/useDerivedState';
import useSizeContext, { buildComponentSizeVariantClasses, SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import useUsageContext, { UsageContextProvider, UsageVariant } from '../../utils/useUsageContext';
import Button, { ButtonVariant } from '../atoms/Button';
import { IconName } from '../atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../atoms/IconOnlyButton';
import Typo from '../atoms/Typo';
import ColorWithDropdown, { ColorWithDropdownVariant } from '../molecules/ColorWithDropdown';
import EditableWithDropdown, { EditableCloseReasons } from '../molecules/EditableWithDropdown';
import SearchAndSelect from '../molecules/SearchAndSelect';
import ColorPalette from './ColorPalette';
import ColorSquare from './internal/ColorSquare';

const useStyles = makeStyles((theme) => (joinObjects(
  {
    input: {
      background: 'none',
      border: 'none',
      padding: spacingRem.none,
      opacity: 0,
    },
    colorContainer: {
      borderRadius: base.borderRadius.medium,
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: theme.color.border.default,
      width: '4rem',
    },
    container: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: spacingRem.s,
      marginRight: spacingRem.s,
    },
    placeholderContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    pickerContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      rowGap: spacingRem.s,
      minWidth: '23rem',
      margin: spacingRem.s,
    },
    standardColorContainer: {
      borderRadius: base.borderRadius.medium,
      width: '2.6rem',
      height: '2.6rem',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    standardColorGroupContainer: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: spacingRem.s,
    },
    pickerTitleContainer: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    colorGridContainer: {
      display: 'flex',
      columnGap: spacingRem.s,
    },
    rowContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
    },
    typeColorSelector: {
      marginRight: spacingRem.s,
      width: 'fit-content',
    },
    withoutDropdownContainer: {
      backgroundColor: theme.color.background.neutral.default,
      borderRadius: base.borderRadius.medium,
      borderWidth: '0.1rem',
      borderStyle: 'solid',
      borderColor: theme.color.border.default,
      boxShadow: base.shadowElevation.low,
    },
    customColorValueContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: spacingRem.s,
      alignItems: 'center',
    },
    actionContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    saturationContainer: {
      minWidth: '21rem',
      '& .rcp-saturation': {
        // Override react-color-palette border radius
        borderRadius: base.borderRadius.medium,
      },
    },
    hueContainer: {
      paddingTop: spacingRem.xs,
      paddingBottom: spacingRem.xs,
    },
  },
  buildComponentSizeVariantClasses('container', ['height'])
)), 'colorPicker');

export enum ColorPickerVariant {
  dot = 'dot',
  bar = 'bar',
  compact = 'compact',
  full = 'full',
}

export enum ColorPaletteVariant {
  text = 'text',
  default = 'default',
}

const getColorLabel = i18nMap(
  {
    [Color.Blue]: () => i18n`Blue`,
    [Color.Purple]: () => i18n`Purple`,
    [Color.Yellow]: () => i18n`Yellow`,
    [Color.LightGreen]: () => i18n`Light green`,
    [Color.LightGrey]: () => i18n`Light grey`,
    [Color.Pink]: () => i18n`Pink`,
    [Color.Red]: () => i18n`Red`,
    [Color.Green]: () => i18n`Green`,
    [Color.Grey]: () => i18n`Grey`,
  }
);

export const getDefaultStandardColors = (colorPaletteVariant?: ColorPaletteVariant): PaletteColor[] => {
  if (colorPaletteVariant === ColorPaletteVariant.text) {
    return [...base.colorPalette.text.map((color) => ({ value: color.value, label: getColorLabel(color.code) }))];
  } else {
    const accentColor = getPlatformAccentColor() ?? base.color.brand['700'];
    return [
      ...base.colorPalette.default
        .filter((defaultColor) => defaultColor.value !== accentColor)
        .map((color) => ({ value: color.value, label: getColorLabel(color.code) })),
      { label: i18n`Accent color`, value: accentColor },
    ];
  }
};

interface PaletteColor {
  value: string,
  label?: string,
}

interface ColorPickerProps {
  value: IColor | undefined,
  defaultValue?: IColor,
  onChange: (newValue: IColor | undefined) => void,
  onSubmit?: (newValue: IColor | undefined) => void,
  onCancel?: () => void,
  readOnly?: boolean,
  onEditionStart?: () => void,
  onEditionStop?: () => void,
  colorPalette?: PaletteColor[],
  colorPaletteVariant?: ColorPaletteVariant,
  isEditing?: boolean,
  focusOnMount?: boolean,
  variant?: ColorPickerVariant,
  withoutColorPalette?: boolean,
  withoutReset?: boolean,
}

interface ColorTypeOption extends Option {
  value: string | null,
}

const typeColorSelectorOptions: ColorTypeOption[] = ['HEX', 'RGB', 'HSV'].map((type): ColorTypeOption => ({ id: type, label: type, value: type }));

const validHex = (value: string): boolean => {
  const valueHex = value.slice(1);
  return /[0-9A-F]/i.test(valueHex[valueHex.length - 1]);
};

const toHex = (value: string): IColor['hex'] | undefined => {
  let valueHex;
  if (!value.startsWith('#')) {
    valueHex = `#${value}`;
  } else {
    valueHex = value;
  }
  if (valueHex.length === 4 || valueHex.length === 5) {
    valueHex = valueHex
      .split('')
      .map((v, i) => (i ? v + v : '#'))
      .join('');
  }
  if (valueHex.length === 7 && validHex(valueHex)) {
    return valueHex;
  } else {
    // invalid color, default color will be used
    return undefined;
  }
};

const convertRGBValues = ([r, g, b]: string[]) => ColorService.convert('rgb', {
  r: clamp(0, 255, Number(r)),
  g: clamp(0, 255, Number(g)),
  b: clamp(0, 255, Number(b)),
  a: 1,
});

const convertHSVValues = ([h, s, v]: string[]) => ColorService.convert('hsv', {
  h: clamp(0, 360, Number(h)),
  s: clamp(0, 100, Number(s)),
  v: clamp(0, 100, Number(v)),
  a: 1,
});

const getTypeColorSelectorOptions = () => typeColorSelectorOptions;

const ColorPicker: FunctionComponent<ColorPickerProps> = ({
  value,
  defaultValue,
  onChange: onChangeProp,
  onSubmit,
  onCancel,
  readOnly = false,
  onEditionStart,
  onEditionStop,
  colorPalette,
  colorPaletteVariant = ColorPaletteVariant.default,
  isEditing = false,
  focusOnMount = false,
  variant = ColorPickerVariant.compact,
  withoutColorPalette = false,
  withoutReset = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const contextUsageVariant = useUsageContext();
  const usageVariant = contextUsageVariant || UsageVariant.inForm;
  const { sizeVariant: contextSizeVariant, hierarchyVariant } = useSizeContext();
  const sizeContextComputed = contextSizeVariant ?? SizeVariant.main;

  const [showDropdown, setShowDropdown] = useState(focusOnMount);

  const [typeColorSelector, setTypeColorSelector] = useState(typeColorSelectorOptions[0]);
  const effectiveValueRef = useRef<IColor | undefined>(undefined);
  if (effectiveValueRef.current?.hex !== (value ?? defaultValue)?.hex) {
    effectiveValueRef.current = value ?? defaultValue;
  }
  const effectiveValue = effectiveValueRef.current;

  let standardColors: PaletteColor[] | undefined;
  if (!withoutColorPalette && !colorPalette) {
    standardColors = getDefaultStandardColors(colorPaletteVariant);
  } else if (colorPalette) {
    standardColors = colorPalette;
  }

  const [showOptions, setShowOptions] = useDerivedState(() => !standardColors
    || (
      effectiveValue
      && !standardColors.some(({ value: paletteColor }) => paletteColor === effectiveValue.hex || computeLightShade(paletteColor) === effectiveValue.hex)
    ), [showDropdown]);
  const onChange = useCallback<ColorPickerProps['onChange']>((color) => {
    effectiveValueRef.current = color;
    onChangeProp(color);
  }, [onChangeProp]);

  const selectedStandardColor = standardColors?.reduce<{ index: number, lightVersion: boolean } | undefined>((acc, { value: paletteValue }, index) => {
    if (paletteValue === effectiveValue?.hex) {
      return { index, lightVersion: false };
    } else if (effectiveValue?.hex === computeLightShade(paletteValue)) {
      return { index, lightVersion: true };
    } else {
      return acc;
    }
  }, undefined);

  const renderDropdown = () => (
    <UsageContextProvider usageVariant={UsageVariant.inline}>
      <SizeContextProvider sizeVariant={SizeVariant.main}>
        <div className={classes.pickerContainer}>
          {standardColors && (
            <>
              <ColorPalette
                standardColors={standardColors}
                onClick={(color) => {
                  onChange(color === effectiveValue?.hex ? undefined : ColorService.convert('hex', color));
                }}
                selectedColor={selectedStandardColor}
                withoutLighterColors={colorPaletteVariant === ColorPaletteVariant.text}
              />
              <div className={classes.pickerTitleContainer}>
                <Button
                  title={i18n`Custom color`}
                  iconName={showOptions ? IconName.expand_more : IconName.keyboard_arrow_right}
                  variant={ButtonVariant.tertiary}
                  onClick={() => setShowOptions((current) => !current)}
                />
                {effectiveValue && !selectedStandardColor ? (
                  <div className={classes.customColorValueContainer}>
                    <Typo color={theme.color.text.secondary} maxLine={1} noWrap={false}>{effectiveValue.hex}</Typo>
                    <ColorSquare
                      color={effectiveValue.hex}
                      onClick={() => {
                        onChange(undefined);
                      }}
                      isSelected
                      clearable
                    />
                  </div>
                ) : null}
              </div>
            </>
          )}
          {showOptions ? (
            <>
              <div className={classes.saturationContainer}>
                <Saturation height={remToPx(14)} color={effectiveValue ?? ColorService.convert('hex', theme.color.border.default)} onChange={onChange} />
              </div>
              <div className={classes.hueContainer}>
                <Hue color={effectiveValue ?? ColorService.convert('hex', theme.color.border.default)} onChange={onChange} />
              </div>
              <div className={classes.rowContainer}>
                <UsageContextProvider usageVariant={UsageVariant.inForm}>
                  <div className={classes.typeColorSelector}>
                    <SearchAndSelect
                      selectedOption={typeColorSelector}
                      computeOptions={getTypeColorSelectorOptions}
                      onSelect={(selectedItem) => {
                        setTypeColorSelector(selectedItem || typeColorSelector);
                      }}
                    />
                  </div>
                  {typeColorSelector.id === 'HEX' ? (
                    <StoreTextInputField
                      initialValue={effectiveValue?.hex}
                      onSubmit={(color) => {
                        const hex = color ? toHex(color) : undefined;
                        onChange(hex ? ColorService.convert('hex', hex) : defaultValue);
                      }}
                    />
                  ) : null}
                  {typeColorSelector.id === 'RGB' ? (
                    <StoreTextInputField
                      initialValue={effectiveValue?.rgb ? `${Math.round(effectiveValue.rgb.r)}, ${Math.round(effectiveValue.rgb.g)}, ${Math.round(effectiveValue.rgb.b)}` : ''}
                      onSubmit={(color) => {
                        const rgbValues = color?.match(/\d+(?:\.\d+)?/g);
                        const rgb = rgbValues?.length === 3 ? convertRGBValues(rgbValues) : defaultValue;
                        onChange(rgb);
                      }}
                    />
                  ) : null}
                  {typeColorSelector.id === 'HSV' ? (
                    <StoreTextInputField
                      initialValue={effectiveValue?.hsv ? `${Math.round(effectiveValue.hsv.h)}°, ${Math.round(effectiveValue.hsv.s)}%, ${Math.round(effectiveValue.hsv.v)}%` : ''}
                      onSubmit={(color) => {
                        const hsvValues = color?.match(/\d+(?:\.\d+)?/g);
                        const hsv = hsvValues?.length === 3 ? convertHSVValues(hsvValues) : defaultValue;
                        onChange(hsv);
                      }}
                    />
                  ) : null}
                </UsageContextProvider>
              </div>
            </>
          ) : null}
        </div>
      </SizeContextProvider>
    </UsageContextProvider>
  );

  if (variant === ColorPickerVariant.full) {
    return (
      <div className={classes.withoutDropdownContainer}>
        {renderDropdown()}
      </div>
    );
  } else if (variant === ColorPickerVariant.dot || variant === ColorPickerVariant.bar) {
    return (
      <ColorWithDropdown
        color={effectiveValue?.hex ?? defaultValue?.hex ?? '#FFFFFF'}
        showDropdown={showDropdown}
        readOnly={readOnly}
        renderDropdownContent={() => [{ key: 'content', content: renderDropdown() }]}
        openDropdown={() => {
          if (!readOnly) {
            onEditionStart?.();
            setShowDropdown(true);
          }
        }}
        closeDropdown={(closeReason) => {
          if (!readOnly) {
            onEditionStop?.();
            setShowDropdown(false);
            if (closeReason === EditableCloseReasons.onBackdropClick) {
              onSubmit?.(value);
            } else {
              onCancel?.();
            }
          }
        }}
        variant={variant === ColorPickerVariant.dot ? ColorWithDropdownVariant.leftDot : ColorWithDropdownVariant.bar}
      />
    );
  } else {
    return (
      <EditableWithDropdown
        showDropdown={showDropdown}
        withMultiplayerOutline={usageVariant === UsageVariant.inTable && isEditing}
        isEditing={usageVariant === UsageVariant.inCard && isEditing}
        readOnly={readOnly}
        autoFocus
        renderValue={(inDropdown) => {
          let content: ReactElement | null;
          if (effectiveValue) {
            content = (
              <>
                <div
                  className={classes.colorContainer}
                  style={{ background: effectiveValue.hex }}
                />
                {inDropdown && !withoutReset && showDropdown && value?.hex !== defaultValue?.hex ? (
                  <IconOnlyButton
                    tooltip={i18n`Reset to default`}
                    iconName={IconName.sync}
                    onClick={() => onChange(defaultValue)}
                    variant={IconOnlyButtonVariants.tertiary}
                  />
                ) : null}
              </>
            );
          } else if (usageVariant !== UsageVariant.inTable) {
            content = (
              <div className={classes.placeholderContainer}>
                <Typo maxLine={1} color={theme.color.text.disabled}>
                  {i18n`Add color`}
                </Typo>
              </div>
            );
          } else {
            content = null;
          }

          return (
            <div className={classnames(classes.container, classes[`container_${sizeContextComputed}_${hierarchyVariant}`])}>
              {content}
            </div>
          );
        }}
        renderDropdown={renderDropdown}
        openDropdown={() => {
          if (!readOnly) {
            onEditionStart?.();
            setShowDropdown(true);
          }
        }}
        closeDropdown={(closeReason) => {
          if (!readOnly) {
            onEditionStop?.();
            setShowDropdown(false);
            if (closeReason === EditableCloseReasons.onBackdropClick) {
              onSubmit?.(value);
            } else {
              onCancel?.();
            }
          }
        }}
        variant={usageVariant}
        editableSizes={{ width: usageVariant === UsageVariant.inTable ? undefined : 'fit-content' }}
      />
    );
  }
};

export default ColorPicker;
