import type { BusinessRuleHandler, ObjectStoreReadOnly, ObjectStoreWithTimeseries } from 'yooi-store';
import { OriginSources, ValidationStatus } from 'yooi-store';
import { newError, textType } from 'yooi-utils';
import { CommonAsType } from '../../common/fields/commonPropertyType';
import type { GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import type { BusinessRuleRegistration } from '../../common/types/TypeModuleDslType';
import { Instance_Of } from '../../typeModule/ids';
import { sanitizeFilterValue, validateFieldIdAsProperty, validateIntegrationOnlyPropertyUpdate } from '../common/commonFieldUtils';
import {
  AnyElement,
  ConceptExternalKeyMapping,
  ConceptExternalKeyMapping_ObjectId,
  ConceptExternalKeyMapping_Role_ExternalKey,
  ConceptExternalKeyMapping_Role_ExternalKeyPropertyId,
  ExternalKeyField_IsImmutable,
  ExternalKeyField_IsRequired,
  ExternalKeyField_PreventKeyStealing,
  ExternalKeyField_RegexValidation,
  Field_Title,
} from '../ids';
import type { ConceptExternalKeyMappingStoreObject } from '../model';
import { registerField } from '../module';
import type { FilterValueRaw } from '../moduleType';
import { FilterValueType } from '../moduleType';
import type { DimensionsMapping, FieldFilterCondition, FieldFilterConditions, ResolutionStack } from '../utils';
import {
  decodeExternalKey,
  encodeExternalKey,
  getFieldDimensionOfModelType,
  isFilterValueRaw,
  isValueResolutionOfType,
  ParsedDimensionType,
  parseDimensionMapping,
  resolveFieldValue,
} from '../utils';
import type { ExternalKeyField } from './types';

export const checkValidValue = (
  store: ObjectStoreWithTimeseries,
  fieldId: string,
  value: string | undefined,
  validateBeforeCommit?: boolean
): { isValid: boolean, error?: Error } => {
  const field = store.getObject(fieldId);
  if (!value && field[ExternalKeyField_IsRequired]) {
    return { isValid: false, error: newError('Field is necessary for platform usage.', { fieldTitle: field[Field_Title] }) };
  } else if (!value) {
    return { isValid: true };
  } else if (field[ExternalKeyField_PreventKeyStealing] && validateBeforeCommit) {
    const existingObjectId = store
      .withAssociation(ConceptExternalKeyMapping)
      .withRole(ConceptExternalKeyMapping_Role_ExternalKeyPropertyId, field.id)
      .withRole(ConceptExternalKeyMapping_Role_ExternalKey, encodeExternalKey(value))
      .getObjectOrNull<ConceptExternalKeyMappingStoreObject>()
      ?.[ConceptExternalKeyMapping_ObjectId];

    if (existingObjectId) {
      return { isValid: false, error: newError('Field value already used. It must be unique.', { fieldTitle: field[Field_Title] }) };
    }
  }

  if (field[ExternalKeyField_RegexValidation]) {
    const isValidFormat = RegExp(field[ExternalKeyField_RegexValidation] as string).test(value);
    if (!isValidFormat) {
      return { isValid: false, error: newError('Field value doesn\'t match validation pattern.', { fieldTitle: field[Field_Title] }) };
    }
  }

  return { isValid: true };
};

const updateExternalIdAssociation = ({ getObjectOrNull }: ObjectStoreReadOnly, propertyId: string): BusinessRuleHandler => (_, { id, properties }) => {
  if (properties === undefined) {
    return undefined;
  }

  const previousValue = getObjectOrNull(id)?.[propertyId] as string | undefined;

  return {
    rule: 'externalIdField.handled',
    status: ValidationStatus.ACCEPTED,
    generateSystemEvent: ({ withAssociation, updateObject, getObjectOrNull: systemGetObjectOrNull }) => {
      const newValue = properties === null ? null : properties[propertyId] as string | null;
      if (newValue === null || previousValue !== newValue) {
        // If deleting the instance or updating the key, clear the previous mapping
        if (
          withAssociation(ConceptExternalKeyMapping)
            .withRole(ConceptExternalKeyMapping_Role_ExternalKeyPropertyId, propertyId)
            .withRole(ConceptExternalKeyMapping_Role_ExternalKey, encodeExternalKey(previousValue))
            .getObjectOrNull<ConceptExternalKeyMappingStoreObject>()
            ?.[ConceptExternalKeyMapping_ObjectId] === id[0]
        ) {
          withAssociation(ConceptExternalKeyMapping)
            .withRole(ConceptExternalKeyMapping_Role_ExternalKeyPropertyId, propertyId)
            .withRole(ConceptExternalKeyMapping_Role_ExternalKey, encodeExternalKey(previousValue))
            .deleteObject();
        }
      }

      if (newValue !== null) {
        const associatedObjectId = withAssociation(ConceptExternalKeyMapping)
          .withRole(ConceptExternalKeyMapping_Role_ExternalKeyPropertyId, propertyId)
          .withRole(ConceptExternalKeyMapping_Role_ExternalKey, encodeExternalKey(newValue))
          .getObjectOrNull<ConceptExternalKeyMappingStoreObject>()
          ?.[ConceptExternalKeyMapping_ObjectId];

        if (associatedObjectId === undefined || associatedObjectId !== id[0]) {
          // No object associated or stealing a key? update mapping
          withAssociation(ConceptExternalKeyMapping)
            .withRole(ConceptExternalKeyMapping_Role_ExternalKeyPropertyId, propertyId)
            .withRole(ConceptExternalKeyMapping_Role_ExternalKey, encodeExternalKey(newValue))
            .updateObject({ [ConceptExternalKeyMapping_ObjectId]: id[0] });
        }

        if (associatedObjectId !== undefined && systemGetObjectOrNull(associatedObjectId) && id[0] !== associatedObjectId) {
          // If an instance was previously bound to the key ? clear the value external key value
          updateObject(associatedObjectId, { [propertyId]: null });
        }
      }
    },
  };
};

const preventIsImmutablePropertyUpdate = ({ getObjectOrNull }: ObjectStoreReadOnly, fieldId: string): BusinessRuleHandler => (origin, { id, properties }) => {
  const object = getObjectOrNull(id);
  const field = getObjectOrNull(fieldId);

  if (
    object === null // creating a new object
    || properties === null // Deleting the object
    || properties === undefined // Not doing anything on properties
    || object[fieldId] === undefined // stored object don't have a value
    || object[fieldId] === properties[fieldId] // property is unchanged
    || field === null // Field definition is missing
    || !field[ExternalKeyField_IsImmutable] // field is not immutable
  ) {
    return undefined;
  } else if (origin.source === OriginSources.YOOI_TOOLS || origin.source === OriginSources.MIGRATION) {
    return { rule: 'externalKeyField.isImmutable.preventUpdate.accepted', status: ValidationStatus.ACCEPTED };
  } else {
    return { rule: 'externalKeyField.isImmutable.preventUpdate.rejected', status: ValidationStatus.REJECTED };
  }
};

const preventKeyStealing = ({ getObjectOrNull, withAssociation }: ObjectStoreReadOnly, fieldId: string): BusinessRuleHandler => (_, { id, properties }) => {
  const field = getObjectOrNull(fieldId);
  if (
    !field?.[ExternalKeyField_PreventKeyStealing]
    || !properties
    || !properties[fieldId]
  ) {
    return undefined;
  }

  const externalKeyMapping = withAssociation(ConceptExternalKeyMapping)
    .withRole(ConceptExternalKeyMapping_Role_ExternalKey, encodeExternalKey(properties[fieldId] as string))
    .withRole(ConceptExternalKeyMapping_Role_ExternalKeyPropertyId, fieldId)
    .getObjectOrNull();
  if (
    externalKeyMapping
    && externalKeyMapping[ConceptExternalKeyMapping_ObjectId] !== id[0]
    && externalKeyMapping.navigateOrNull(ConceptExternalKeyMapping_ObjectId)?.[fieldId] === properties[fieldId] as string
  ) {
    return { rule: 'externalKeyField.preventKeyStealing.rejected', status: ValidationStatus.REJECTED };
  } else {
    return { rule: 'externalKeyField.preventKeyStealing.accepted', status: ValidationStatus.ACCEPTED };
  }
};

const preventInvalidRegexUpdate = (fieldId: string): BusinessRuleRegistration => () => (_, { properties }) => {
  if (!properties?.[fieldId]) {
    return undefined;
  }
  try {
    RegExp(properties[fieldId] as string);
    return undefined;
  } catch (__) {
    return { rule: 'externalKeyField.validation.invalidFormat.rejected', status: ValidationStatus.REJECTED };
  }
};

const validateExternalKeyFormat = ({ getObject }: ObjectStoreReadOnly, propertyId: string): BusinessRuleHandler => (_, { properties }) => {
  if (!properties) {
    return undefined;
  }

  if (properties[propertyId]) {
    const field = getObject(propertyId);
    const error = !RegExp(field[ExternalKeyField_RegexValidation] as string).test(properties[propertyId] as string);
    if (error) {
      return { rule: 'externalKey.validation.invalidInput.rejected', status: ValidationStatus.REJECTED };
    }
  }

  return undefined;
};

const getValueWithoutFormula = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping) => {
  const parsedDimensions = parseDimensionMapping(dimensionsMapping);
  if (parsedDimensions.type === ParsedDimensionType.MonoDimensional) {
    return objectStore.getObject(parsedDimensions.objectId)[fieldId] as string | undefined;
  } else {
    return undefined;
  }
};

const getValueResolution = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack) => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
  if (isValueResolutionOfType(valueResolution, (value): value is string | undefined => value === undefined || typeof value === 'string')) {
    return valueResolution;
  } else {
    return {
      value: undefined,
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['string', 'undefined'], typeof valueResolution.value),
      getDisplayValue: () => undefined,
      isTimeseries: false,
    };
  }
};

const sanitizeTextValue = (value: unknown) => (value ?? { type: FilterValueType.raw, raw: undefined }) as FilterValueRaw<string>;
const isTextFilterApplicable = (value: FilterValueRaw<string>) => Boolean(value) && isFilterValueRaw<string>(value);

interface ExternalKeyFilterConditions extends FieldFilterConditions<string | undefined> {
  CONTAINS: FieldFilterCondition<string, string | undefined>,
  DOES_NOT_CONTAIN: FieldFilterCondition<string, string | undefined>,
  EQUALS: FieldFilterCondition<string, string | undefined>,
  NOT_EQUALS: FieldFilterCondition<string, string | undefined>,
  STARTS_WITH: FieldFilterCondition<string, string | undefined>,
  ENDS_WITH: FieldFilterCondition<string, string | undefined>,
  IS_EMPTY: FieldFilterCondition<undefined, string | undefined>,
  IS_NOT_EMPTY: FieldFilterCondition<undefined, string | undefined>,
}

type ExternalKeyFieldHandler = GetDslFieldHandler<
  ExternalKeyField,
  string | undefined,
  undefined,
  string | undefined,
  string | undefined,
  string | undefined,
  ExternalKeyFilterConditions,
  undefined,
  undefined,
  undefined
>;

export const externalKeyFieldHandler: ExternalKeyFieldHandler = registerField({
  model: {
    label: 'ExternalKeyField',
    title: 'External key',
    withApiAlias: true,
    properties: [
      {
        label: 'IsImmutable',
        as: CommonAsType.boolean,
        initialStateValidationHandler: () => ({ validated: true }),
      },
      {
        label: 'IsRequired',
        as: CommonAsType.boolean,
        initialStateValidationHandler: () => ({ validated: true }),
      },
      {
        label: 'RegexValidation',
        as: CommonAsType.string,
        initialStateValidationHandler: () => ({ validated: true }),
        businessRules: [preventInvalidRegexUpdate(ExternalKeyField_RegexValidation)],
      },
      {
        label: 'PreventKeyStealing',
        as: CommonAsType.boolean,
        initialStateValidationHandler: () => ({ validated: true }),
      },
    ],
    extraModel: ({ association }) => {
      association({
        label: 'ConceptExternalKeyMapping',
        roles: [{ label: 'ExternalKeyPropertyId', targetTypeId: AnyElement }, { label: 'ExternalKey', targetTypeId: AnyElement }],
        accessControlList: {
          READ: (store) => (origin, objectId) => {
            if (origin.userId === decodeExternalKey(objectId[ConceptExternalKeyMapping_Role_ExternalKey + 1])) {
              return { rule: 'ConceptExternalKeyMapping.read.userMapping', status: ValidationStatus.ACCEPTED };
            }

            const targetId = store.getObjectOrNull<ConceptExternalKeyMappingStoreObject>(objectId)?.[ConceptExternalKeyMapping_ObjectId];
            if (targetId) {
              return { rule: 'ConceptExternalKeyMapping.read.delegate', status: ValidationStatus.DELEGATED, targetId: [targetId], targetAction: 'READ' };
            } else {
              return undefined;
            }
          },
          WRITE: (store) => (_, objectId) => {
            const conceptInstanceId = store.getObjectOrNull(objectId)?.[ConceptExternalKeyMapping_ObjectId] as string;
            if (conceptInstanceId) {
              return ({ rule: 'ConceptExternalKeyMapping.write.delegate', status: ValidationStatus.DELEGATED, targetId: [conceptInstanceId], targetAction: 'WRITE' });
            }
            return undefined;
          },
          DELETE: (store) => (_, objectId) => {
            const conceptInstanceId = store.getObjectOrNull(objectId)?.[ConceptExternalKeyMapping_ObjectId] as string;
            if (conceptInstanceId) {
              return ({ rule: 'ConceptExternalKeyMapping.delete.delegate', status: ValidationStatus.DELEGATED, targetId: [conceptInstanceId], targetAction: 'DELETE' });
            }
            return undefined;
          },
        },
      })
        .property({ label: 'ConceptExternalKeyMapping_ObjectId', as: CommonAsType.string });
    },
    asPropertyBusinessRules: [
      preventIsImmutablePropertyUpdate,
      updateExternalIdAssociation,
      validateIntegrationOnlyPropertyUpdate('externalKeyField'),
      validateExternalKeyFormat,
      preventKeyStealing,
      validateFieldIdAsProperty('externalKeyField'),
    ],
  },
  handler: (objectStore, fieldId) => {
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => getValueResolution(objectStore, fieldId, dimensionsMapping).value;
    return {
      describe: () => ({ hasData: true, returnType: textType, timeseriesMode: 'none' }),
      restApi: {
        returnTypeSchema: { type: 'string', nullable: true },
        formatValue: (value) => value ?? undefined,
      },
      getStoreValue: (dimensionsMapping) => getValueWithoutFormula(objectStore, fieldId, dimensionsMapping),
      getValueWithoutFormula: (dimensionsMapping) => getValueWithoutFormula(objectStore, fieldId, dimensionsMapping),
      getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
      resolvePathStepConfiguration: () => ({
        hasData: true,
        timeseriesMode: 'none',
        getValueResolutionType: () => textType,
        resolveValue: (dimensionsMappings, _, resolutionStack) => {
          const { error, value } = getValueResolution(objectStore, fieldId, dimensionsMappings, resolutionStack);
          if (error) {
            throw error;
          } else {
            return value;
          }
        },
      }),
      updateValue: () => {
        throw newError('updateValue not supported');
      },
      isEmpty: (dimensionsMapping) => !getValueResolution(objectStore, fieldId, dimensionsMapping).value,
      isSaneValue: (objectId) => {
        const instance = objectStore.getObjectOrNull(objectId);
        const dimensionId = instance ? getFieldDimensionOfModelType(objectStore, fieldId, instance[Instance_Of] as string) : undefined;

        if (dimensionId) {
          const value = getValueWithoutFormula(objectStore, fieldId, { [dimensionId]: objectId });
          return checkValidValue(objectStore, fieldId, value);
        } else {
          return { isValid: false, error: newError('Invalid field') };
        }
      },
      getValueAsText,
      getExportColumnHeaders: (configuration, fieldLabel) => ({
        columnsNumber: 1,
        getHeaders: () => [{ format: 'string', value: fieldLabel }],
        getColumnConfiguration: () => configuration,
      }),
      getExportValue: (dimensionsMapping) => {
        const value = getValueResolution(objectStore, fieldId, dimensionsMapping);
        return { format: 'string', value: value.value };
      },
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else {
            return undefined;
          }
        },
      }),
      filterConditions: {
        CONTAINS: {
          sanitizeValue: sanitizeTextValue,
          isFilterApplicable: isTextFilterApplicable,
          filterFunction: (leftValue, rightValue) => sanitizeFilterValue(leftValue).includes(sanitizeFilterValue(rightValue)),
        },
        DOES_NOT_CONTAIN: {
          sanitizeValue: sanitizeTextValue,
          isFilterApplicable: isTextFilterApplicable,
          filterFunction: (leftValue, rightValue) => !sanitizeFilterValue(leftValue).includes(sanitizeFilterValue(rightValue)),
        },
        EQUALS: {
          sanitizeValue: sanitizeTextValue,
          isFilterApplicable: isTextFilterApplicable,
          filterFunction: (leftValue, rightValue) => sanitizeFilterValue(leftValue) === sanitizeFilterValue(rightValue),
        },
        NOT_EQUALS: {
          sanitizeValue: sanitizeTextValue,
          isFilterApplicable: isTextFilterApplicable,
          filterFunction: (leftValue, rightValue) => sanitizeFilterValue(leftValue) !== sanitizeFilterValue(rightValue),
        },
        STARTS_WITH: {
          sanitizeValue: sanitizeTextValue,
          isFilterApplicable: isTextFilterApplicable,
          filterFunction: (leftValue, rightValue) => sanitizeFilterValue(leftValue).startsWith(sanitizeFilterValue(rightValue)),
        },
        ENDS_WITH: {
          sanitizeValue: sanitizeTextValue,
          isFilterApplicable: isTextFilterApplicable,
          filterFunction: (leftValue, rightValue) => sanitizeFilterValue(leftValue).endsWith(sanitizeFilterValue(rightValue)),
        },
        IS_EMPTY: {
          sanitizeValue: () => ({ type: FilterValueType.raw, raw: undefined }),
          isFilterApplicable: () => true,
          filterFunction: (leftValue) => (leftValue ?? '').trim() === '',
        },
        IS_NOT_EMPTY: {
          sanitizeValue: () => ({ type: FilterValueType.raw, raw: undefined }),
          isFilterApplicable: () => true,
          filterFunction: (leftValue) => (leftValue ?? '').trim() !== '',
        },
      },
    };
  },
});
