import type { FieldBlockDisplayOptions, TextConstantFieldRaw, TextConstantFieldStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import { BlockFieldLayoutOption, textConstantFieldHandler } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration, TextConstantField, TextConstantField_Text } from 'yooi-modules/modules/conceptLayoutModule/ids';
import { Field_IsTitleHidden, Field_Title } from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import TextInputString from '../../../../components/inputs/TextInputString';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import BlockContent from '../../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../../components/templates/BlockTitle';
import { Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import BlockField from '../_global/BlockField';
import { getBlockFieldLayoutOption, getDefaultDisplayOptions, getLayoutDisplayOption } from '../_global/blockFieldUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  duplicateFieldDimensionWithNewField,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
} from '../fieldDimensionUtils';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode } from '../FieldLibraryTypes';

interface TextConstantFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  [Field_IsTitleHidden]: boolean | null | undefined,
  [TextConstantField_Text]: string | null | undefined,
}

type TextConstantFieldDefinition = GetFieldDefinitionHandler<typeof textConstantFieldHandler, TextConstantFieldConfigurationState, never, FieldBlockDisplayOptions>;

export const textConstantFieldDefinition: TextConstantFieldDefinition = registerFieldDefinition(textConstantFieldHandler, {
  configuration: {
    typeIcon: IconName.subject,
    getTypeLabel: () => i18n`Text Constant`,
    asWidget: false,
    getEditionOptions: () => ({ mode, editionHandler }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        return [];
      }

      return [
        {
          key: 'display',
          type: 'section',
          title: i18n`Display`,
          options: [
            {
              key: TextConstantField_Text,
              title: i18n`Text`,
              hasValue: () => editionHandler.getValue(TextConstantField_Text) !== undefined,
              clearValue: () => editionHandler.updateValues({ [TextConstantField_Text]: null }),
              type: EditionOptionTypes.text,
              props: {
                placeholder: i18n`Add text`,
                value: editionHandler.getValueOrDefault(TextConstantField_Text),
                maxLine: 5,
                onChange: (value) => editionHandler.updateValues({ [TextConstantField_Text]: value }),
              },
            },
            {
              key: Field_IsTitleHidden,
              title: i18n`Hide field title`,
              hasValue: () => editionHandler.getValueOrDefault(Field_IsTitleHidden) !== undefined,
              clearValue: () => editionHandler.updateValues({ [Field_IsTitleHidden]: null }),
              type: EditionOptionTypes.checkbox,
              padded: true,
              props: {
                checked: editionHandler.getValue(Field_IsTitleHidden) ?? false,
                onChange: (checked) => editionHandler.updateValues({ [Field_IsTitleHidden]: checked }),
              },
            },
          ],
        },
      ];
    },
    isCreationEnabled: () => () => true,
    onCreate: (objectStore) => (editionHandler) => {
      const fieldId = objectStore.createObject<TextConstantFieldRaw>({
        [Instance_Of]: TextConstantField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [TextConstantField_Text]: editionHandler.getValue(TextConstantField_Text),
        [Field_IsTitleHidden]: editionHandler.getValue(Field_IsTitleHidden),
      });
      linkFieldToFieldDimensions(objectStore, fieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});
      return fieldId;
    },
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<TextConstantFieldStoreObject>(fieldId);
        return {
          [Field_Title]: field[Field_Title],
          [TextConstantField_Text]: field[TextConstantField_Text],
          [Field_IsTitleHidden]: field[Field_IsTitleHidden],
          [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
        };
      },
      submitFieldUpdate: (stateToSubmit) => {
        objectStore.updateObject<TextConstantFieldRaw>(fieldId, {
          [TextConstantField_Text]: stateToSubmit[TextConstantField_Text],
          [Field_IsTitleHidden]: stateToSubmit[Field_IsTitleHidden],
        });
      },
      duplicateFieldDefinition: () => {
        const fieldInstance = objectStore.getObject(fieldId);
        const fieldDimensionMapping = generateDuplicatedFieldDimensionId(objectStore, fieldId);
        const newFieldId = objectStore.createObject({
          [Instance_Of]: fieldInstance[Instance_Of],
          [Field_Title]: `${fieldInstance[Field_Title]} (copy)`,
          [TextConstantField_Text]: fieldInstance[TextConstantField_Text],
          [Field_IsTitleHidden]: fieldInstance[Field_IsTitleHidden],
        });
        duplicateFieldDimensionWithNewField(objectStore, newFieldId, fieldDimensionMapping);
        return newFieldId;
      },
    }),
  },
  renderField: ({ getObject }, fieldId) => () => (
    <TextInputString readOnly value={getObject(fieldId)[TextConstantField_Text] as string} />
  ),
  renderBlockField: (objectStore, fieldId) => (_, __, blockFieldProps) => {
    const field = objectStore.getObject(fieldId);
    const hideBlockTitle = Boolean(field[Field_IsTitleHidden]);
    const content = (<TextInputString readOnly value={field[TextConstantField_Text] as string} />);
    return (
      <BlockField
        fieldId={fieldId}
        {...blockFieldProps}
        hideBlockTitle={hideBlockTitle}
      >
        {hideBlockTitle ? (
          <BlockTitle
            variant={BlockTitleVariant.inline}
            title={field[TextConstantField_Text] as string}
            iconName={IconName.subject}
          />
        )
          : (
            <BlockContent>{content}</BlockContent>
          )}
      </BlockField>
    );
  },
  renderExportConfiguration: () => () => (
    <SpacedContainer margin={{ x: Spacing.splus }}>
      <Tooltip title={i18n`Text`}>
        <Typo maxLine={1}>{i18n`Text`}</Typo>
      </Tooltip>
    </SpacedContainer>
  ),
  getActivityProperties: (_, fieldId) => () => [fieldId],
  blockDisplayOptionsHandler: (objectStore) => (fieldBlockDisplayId) => ({
    getDisplayOptions: () => getDefaultDisplayOptions(objectStore, fieldBlockDisplayId),
    renderSummary: ({ layoutDisplayType }) => ([getBlockFieldLayoutOption()[layoutDisplayType ?? BlockFieldLayoutOption.auto].label]),
    getBlockEditionOptionSections: (state, setState) => [
      getLayoutDisplayOption(state, setState),
    ],
    onSubmit: (state) => {
      objectStore.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
});
