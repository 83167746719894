import { WorkflowRelationDisplayType } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import { newError } from 'yooi-utils';
import i18n from '../../../../utils/i18n';

export const getWorkflowRelationDisplayTypeLabel = (displayType: WorkflowRelationDisplayType): string => {
  switch (displayType) {
    case WorkflowRelationDisplayType.Value:
      return i18n`Value`;
    case WorkflowRelationDisplayType.ValueAndButton:
      return i18n`Value + Button`;
    case WorkflowRelationDisplayType.ButtonAndValue:
      return i18n`Button + Value`;
    case WorkflowRelationDisplayType.Button:
      return i18n`Button`;
    case WorkflowRelationDisplayType.Color:
      return i18n`Color`;
    default:
      throw newError('Unsupported WorkflowRelationDisplayType');
  }
};

interface WorkflowRelationDisplayTypeOption {
  id: WorkflowRelationDisplayType,
  label: string,
}

const getWorkflowRelationDisplayTypeOption = (displayType: WorkflowRelationDisplayType): WorkflowRelationDisplayTypeOption => ({
  id: displayType,
  label: getWorkflowRelationDisplayTypeLabel(displayType),
});

export const getOptionalWorkflowRelationDisplayTypeOption = (displayType: WorkflowRelationDisplayType | undefined): WorkflowRelationDisplayTypeOption | undefined => (
  displayType ? getWorkflowRelationDisplayTypeOption(displayType) : undefined
);

export const getWorkflowRelationDisplayTypeOptions = (): WorkflowRelationDisplayTypeOption[] => (
  [
    WorkflowRelationDisplayType.Value,
    WorkflowRelationDisplayType.ValueAndButton,
    WorkflowRelationDisplayType.Button,
  ].map(getWorkflowRelationDisplayTypeOption)
);
