import { v4 as uuid } from 'uuid';
import type { ObjectStore, ObjectStoreReadOnly, ObjectStoreWithTimeseries, StoreObject, TimeRange } from 'yooi-store';
import type { ArrayFormulaType, FormulaType } from 'yooi-utils';
import { anyType, arrayOf, errorToObject, filterNullOrUndefined, fromError, isArrayFormulaType, isArrayFormulaTypeOfType, joinObjects, newError } from 'yooi-utils';
import { UnsetDashboardParameterOption } from '../../../dashboardModule/ids';
import { isInstanceOf } from '../../../typeModule';
import { Instance_Of } from '../../../typeModule/ids';
import type { FieldStepConfiguration } from '../../common/commonFieldUtils';
import type { AssociationFieldStepConfiguration } from '../../fields/associationField';
import type { StakeholdersPathStepConfiguration } from '../../fields/stakeholdersField';
import {
  AssociationField,
  Concept,
  Concept_GetFilterConditionContext,
  Concept_GetFilterFunction,
  ConceptRole,
  Field_FieldDimensions,
  Field_Formula,
  FieldDimension_IsMandatory,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  Group,
  KinshipRelationField,
  StakeholdersField,
  StakeholdersField_TargetType,
  User,
} from '../../ids';
import type { AssociationFieldStoreObject, ConceptDefinitionStoreObject, ConceptStoreObject, FieldDimensionStoreObject, StakeholdersFieldStoreObject } from '../../model';
import type {
  DimensionStep,
  FieldStep,
  FilterCondition,
  Filters,
  FilterStep,
  FilterValue,
  GlobalDimensionStep,
  MappingStep,
  MultipleMappingStep,
  PathStep,
} from '../../moduleType';
import { FilterConditionOperators, PathStepType } from '../../moduleType';
import { getFieldDimensionOfModelType } from '../fieldUtils';
import { getFieldUtilsHandler } from '../fieldUtilsHandler';
import type { BackendFilterCondition, BackendFilterConditions, FieldFilterConditions, InstanceReferenceValue } from '../filters/filters';
import { InstanceReferenceType, isFilterValuePath, isFilterValueRaw } from '../filters/filters';
import { buildFilterId, FILTER_PARAMETER_CURRENT, getInstanceFilterConditions, getMultipleInstanceFilterConditions, isFilterNode } from '../filters/filterUtils';
import type { ConceptType } from '../formula/modelFunctions';
import { conceptType, isConceptType } from '../formula/modelFunctions';
import { arrhythmicTimeseriesOf, isArrhythmicTimeseriesFormulaType, isRhythmicTimeseriesFormulaType, rhythmicTimeseriesOf } from '../formula/timeseriesFunctions';
import type { DimensionsMapping, MultipleParameterValue, ParametersMapping, SingleParameterValue } from '../parametersUtils';
import { dimensionsMappingFromDimensionalIds, dimensionsMappingToParametersMapping, segregateParametersMapping } from '../parametersUtils';
import { isRelationalType } from '../relationFieldUtils';
import type { ResolutionStack } from '../resolutionStackUtils';
import { createResolutionStack } from '../resolutionStackUtils';
import type { DimensionResolution, FilterConditionExecutionContext, FilterFunction, MultiFieldResolution, SingleFieldResolution } from './common';
import {
  DimensionResolutionType,
  FieldResolutionType,
  isMultiValueResolution,
  isSingleValueResolution,
  ResolutionError,
  StepResolutionError,
  StepResolutionErrorStepType,
  ValueResolutionType,
} from './common';
import { isDimensionStep, isFieldStep, isGlobalDimensionStep, isMappingStep } from './pathUtils';

interface PathResolution<T = unknown> {
  value: T,
  resolutionType: FormulaType,
}

type ConceptResolution =
  | { type: 'single', instance: ConceptStoreObject | undefined, resolutionType: ConceptType }
  | { type: 'multiple', instances: ConceptStoreObject[], resolutionType: ArrayFormulaType<ConceptType> };

type DimensionsResolution = { type: 'single', dimension: DimensionsMapping } | { type: 'multiple', dimensions: DimensionsMapping[] };

type StepRestriction =
  | undefined
  | { type: 'filters', filters?: Filters }
  | { type: 'instance', id: string }
  | { type: 'parameter', id: string }
  | { type: 'parameterMultiple', id: string, filters?: Filters };

interface CompiledConceptStep {
  type: 'concept',
  conceptDefinitionId: string,
  restriction: StepRestriction,
}

interface CompiledFieldStep {
  type: 'field',
  fieldId: string,
  mapping: Record<string, string>,
  configuration?: FieldStepConfiguration,
}

interface ConceptPath {
  dimensions?: undefined,
  step: CompiledConceptStep,
}

interface FieldPath {
  dimensions: Dimensions,
  step: CompiledFieldStep,
}

export type CompiledPath = ConceptPath | FieldPath;

interface StakeholderMetadata {
  type: string,
  restrictionType?: string,
  restriction?: InstanceReferenceValue,
}

type Dimensions = Record<string, { isOptional: boolean, path: CompiledPath }>;

export interface SingleValueResolution<T = unknown> {
  type: ValueResolutionType.single,
  isArrhythmicTimeseries: boolean,
  value: T | undefined,
  isTimeseries: boolean,
  resolutionType: FormulaType,
}

export interface MultiValueResolution<T = unknown> {
  type: ValueResolutionType.multi,
  isArrhythmicTimeseries: boolean,
  values: T[],
  isTimeseries: boolean,
  resolutionType: FormulaType,
}

const isConceptPath = (path: CompiledPath | undefined): path is ConceptPath => path?.step.type === 'concept';

const isFieldPath = (path: CompiledPath | undefined): path is FieldPath => path?.step.type === 'field';

const isMultipleDimensions = (store: ObjectStoreWithTimeseries, dimensions: Dimensions) => Object.values(dimensions).some((dimension) => {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const returnType = getCompiledPathReturnType(store, dimension.path, 'value');
  return returnType !== undefined && isArrayFormulaType(returnType);
});

const compilePathWithStakeholders = (
  store: ObjectStoreReadOnly,
  path: PathStep[]
): (GlobalDimensionStep | DimensionStep | FilterStep | MappingStep | MultipleMappingStep | (FieldStep & { stakeholder?: StakeholderMetadata }))[] => (
  path.reduce<(PathStep | (FieldStep & { stakeholder: StakeholderMetadata }))[]>(
    (accumulator, step) => {
      if (isFieldStep(step) && isInstanceOf(store.getObjectOrNull(step.fieldId), StakeholdersField)) {
        const prevStep = accumulator.at(-1);
        const prev2Step = accumulator.at(-2);
        const prev3Step = accumulator.at(-3);
        if (
          isMappingStep(prevStep)
          && isDimensionStep(prev2Step)
          && isFieldStep(prev3Step) && isInstanceOf(store.getObjectOrNull(prev3Step.fieldId), StakeholdersField)
        ) {
          const prevStepType = store.getObject<StakeholdersFieldStoreObject>(prev3Step.fieldId)[StakeholdersField_TargetType];
          const stepType = store.getObject<StakeholdersFieldStoreObject>(step.fieldId)[StakeholdersField_TargetType];
          if (prevStepType && stepType) {
            return [
              ...accumulator.slice(0, -3),
              { type: PathStepType.field, fieldId: step.fieldId, stakeholder: { type: stepType, restrictionType: prevStepType, restriction: prevStep.mapping } },
            ];
          } else {
            throw newError('cannot compile stakeholder path');
          }
        } else {
          const stepType = store.getObject<StakeholdersFieldStoreObject>(step.fieldId)[StakeholdersField_TargetType];
          if (stepType) {
            accumulator.push(joinObjects(step, { stakeholder: { type: stepType } }));
            return accumulator;
          } else {
            throw newError('cannot compile stakeholder path 2');
          }
        }
      }
      accumulator.push(step);
      return accumulator;
    }, [])
);

const getDimensionsReturnedConceptDefinitionId = (store: ObjectStoreWithTimeseries, dimensions: Dimensions): { id: string, conceptDefinitionId: string }[] => (
  Object.entries(dimensions).map(([id, { path }]) => {
    if (isConceptPath(path)) {
      return { id, conceptDefinitionId: path.step.conceptDefinitionId };
    } else {
      if (store.getObjectOrNull(path.step.fieldId) === null) {
        throw newError('Cannot getDimensionsReturnedType: path contains invalid field');
      }
      const resolver = getFieldUtilsHandler(store, path.step.fieldId).resolvePathStepConfiguration(path.step.configuration);
      if (!resolver) {
        throw newError('Cannot getDimensionsReturnedType: missing resolvePathStepConfiguration');
      } else if (!resolver.hasData) {
        throw newError('Cannot getDimensionsReturnedType: missing resolvePathStepConfiguration hasData');
      } else if (resolver.timeseriesMode === 'explicit') {
        throw newError('Cannot getDimensionsReturnedType: dimensions contains timeseries');
      }
      const resolutionType = resolver.getValueResolutionType();
      if (isConceptType(resolutionType)) {
        return { id, conceptDefinitionId: resolutionType.conceptDefinitionId };
      } else if (isArrayFormulaType(resolutionType) && isConceptType(resolutionType.array.elementType)) {
        return { id, conceptDefinitionId: resolutionType.array.elementType.conceptDefinitionId };
      } else {
        throw newError('Cannot getDimensionsReturnedType: resolution type is not compatible with concept type');
      }
    }
  })
);

const checkDimensionsValidity = (objectStore: ObjectStore, fieldId: string, dimensionsMapping: DimensionsMapping, isComputed: boolean): void => {
  const allFieldDimensions = objectStore.getObject(fieldId).navigateBack<FieldDimensionStoreObject>(Field_FieldDimensions);
  const mandatoryDimensions = allFieldDimensions.filter((fieldDimension) => fieldDimension[FieldDimension_IsMandatory]);
  if (mandatoryDimensions.length > 0) {
    // all mandatory check
    if (mandatoryDimensions.some((mandatoryDimension) => {
      const dimensionMapping = dimensionsMapping[mandatoryDimension.id];
      return dimensionMapping === undefined || objectStore.getObjectOrNull(dimensionMapping) === null;
    })) {
      throw new StepResolutionError({ type: StepResolutionErrorStepType.field, fieldId }, { resolutionError: ResolutionError.invalidDimension });
    }
  } else if (!isComputed) {
    // at least one optional check
    if (allFieldDimensions.every((optionalFieldDimension) => {
      const dimensionMapping = dimensionsMapping[optionalFieldDimension.id];
      return dimensionMapping === undefined || objectStore.getObjectOrNull(dimensionMapping) === null;
    })) {
      throw new StepResolutionError({ type: StepResolutionErrorStepType.field, fieldId }, { resolutionError: ResolutionError.invalidDimension });
    }
  }
};

export const compilePath = (store: ObjectStoreWithTimeseries, valuePath: PathStep[]): CompiledPath | undefined => {
  if (valuePath.length === 0) {
    return undefined;
  }

  const compiledPathWithStakeholders = compilePathWithStakeholders(store, valuePath);

  const getStakeholderFieldConfiguration = ({ type, restrictionType, restriction }: StakeholderMetadata): StakeholdersPathStepConfiguration | undefined => {
    if (type === Group) {
      return { type: 'group', anyRole: restrictionType === ConceptRole && restriction ? [restriction] : [] };
    } else if (type === User) {
      return { type: 'user', anyRole: restrictionType === ConceptRole && restriction ? [restriction] : [] };
    } else if (type === ConceptRole && (!restrictionType || restrictionType === User)) {
      return {
        type: 'usersRole',
        allUsers: restriction ? [restriction] : [],
      };
    } else if (type === ConceptRole && restrictionType === Group) {
      return {
        type: 'groupsRole',
        allGroups: restriction ? [restriction] : [],
      };
    } else {
      return undefined;
    }
  };

  return compiledPathWithStakeholders.reduce<CompiledPath | undefined>((acc, step): CompiledPath | undefined => {
    switch (step.type) {
      case PathStepType.global: {
        return acc;
      }
      case PathStepType.dimension: {
        if (isFieldPath(acc)) {
          const field = store.getObjectOrNull(acc.step.fieldId);
          if (isInstanceOf(field, KinshipRelationField) || (isInstanceOf<AssociationFieldStoreObject>(field, AssociationField) && field[Field_Formula])) {
            if (acc.step.configuration) {
              (acc.step.configuration as AssociationFieldStepConfiguration).conceptDefinitionId = step.conceptDefinitionId;
            } else {
              acc.step.configuration = { conceptDefinitionId: step.conceptDefinitionId };
            }
          }
        } else {
          return { step: { type: 'concept', conceptDefinitionId: step.conceptDefinitionId, restriction: undefined } };
        }
        return acc;
      }
      case PathStepType.field: {
        if (isFieldPath(acc) || isConceptPath(acc)) {
          const dimensionId = uuid();
          const dimensions = { [dimensionId]: { isOptional: false, path: acc } };
          const field = store.getObjectOrNull(step.fieldId);
          if (!field) {
            return {
              dimensions,
              step: {
                type: 'field',
                fieldId: step.fieldId,
                mapping: {},
                configuration: joinObjects(
                  step.embeddingFieldId ? { embeddingFieldId: step.embeddingFieldId } : undefined,
                  step.workflowSubfieldId ? { workflowSubfieldId: step.workflowSubfieldId } : undefined,
                  step.stakeholder ? getStakeholderFieldConfiguration(step.stakeholder) : undefined
                ),
              },
            };
          }
          const dimensionsReturnedConceptDefinitionId = getDimensionsReturnedConceptDefinitionId(store, dimensions);
          return {
            dimensions,
            step: {
              type: 'field',
              fieldId: step.fieldId,
              mapping: Object.fromEntries(
                dimensionsReturnedConceptDefinitionId
                  .map(({ id, conceptDefinitionId }) => {
                    const fieldDimensionId = getFieldDimensionOfModelType(store, step.fieldId, conceptDefinitionId);
                    return fieldDimensionId ? [fieldDimensionId, id] : undefined;
                  })
                  .filter(filterNullOrUndefined)
              ),
              configuration: joinObjects(
                step.embeddingFieldId ? { embeddingFieldId: step.embeddingFieldId } : undefined,
                step.workflowSubfieldId ? { workflowSubfieldId: step.workflowSubfieldId } : undefined,
                step.stakeholder ? getStakeholderFieldConfiguration(step.stakeholder) : undefined
              ),
            },
          };
        } else { // global
          const fieldStep: CompiledFieldStep = {
            type: 'field',
            fieldId: step.fieldId,
            mapping: {},
            configuration: joinObjects(
              step.embeddingFieldId ? { embeddingFieldId: step.embeddingFieldId } : undefined,
              step.workflowSubfieldId ? { workflowSubfieldId: step.workflowSubfieldId } : undefined,
              step.stakeholder ? getStakeholderFieldConfiguration(step.stakeholder) : undefined
            ),
          };
          const dimensions: Dimensions = {};
          Object.entries(step.mapping ?? {}).forEach(([fieldDimensionId, instanceReferenceValue]) => {
            if (instanceReferenceValue) {
              const conceptDefinitionIds = store.withAssociation(FieldDimensionTypes)
                .withRole(FieldDimensionTypes_Role_FieldDimension, fieldDimensionId)
                .list()
                .map((assoc) => assoc.role(FieldDimensionTypes_Role_ConceptDefinition));
              const dimensionId = uuid();
              let conceptDefinitionId = Concept;
              if (conceptDefinitionIds.length === 1) {
                [conceptDefinitionId] = conceptDefinitionIds;
              } else if (instanceReferenceValue.type === 'instance') {
                const instance = store.getObjectOrNull<ConceptStoreObject>(instanceReferenceValue.id);
                if (instance) {
                  conceptDefinitionId = instance[Instance_Of];
                }
              }
              dimensions[dimensionId] = {
                isOptional: true,
                path: {
                  step: {
                    type: 'concept',
                    conceptDefinitionId,
                    restriction: { type: instanceReferenceValue.type, id: instanceReferenceValue.id },
                  },
                },
              };
              fieldStep.mapping[fieldDimensionId] = dimensionId;
            }
          });
          return { dimensions, step: fieldStep };
        }
      }
      case PathStepType.multipleMapping: {
        if (isConceptPath(acc)) {
          acc.step.restriction = { type: 'parameterMultiple', id: step.id, filters: step.filters };
        }
        return acc;
      }
      case PathStepType.mapping: {
        if (isConceptPath(acc)) {
          acc.step.restriction = { type: step.mapping.type, id: step.mapping.id };
        }
        return acc;
      }
      case PathStepType.filter: {
        if (isConceptPath(acc)) {
          acc.step.restriction = { type: 'filters', filters: step.filters };
        } else if (isFieldPath(acc)) {
          acc.step.configuration = joinObjects(acc.step.configuration, { filters: step.filters });
        }
        return acc;
      }
      default:
        return acc;
    }
  }, undefined);
};

const getCompiledPathReturnType = (store: ObjectStoreWithTimeseries, path: CompiledPath, mode: 'value' | 'timeseries' | 'timeseriesIterator'): FormulaType | undefined => {
  if (isConceptPath(path)) {
    const { restriction, conceptDefinitionId } = path.step;
    if (!restriction || restriction.type === 'filters' || restriction.type === 'parameterMultiple') {
      return arrayOf(conceptType(conceptDefinitionId));
    } else {
      return conceptType(conceptDefinitionId);
    }
  } else {
    const { step: { fieldId, configuration }, dimensions } = path;
    if (store.getObjectOrNull(fieldId) === null) {
      return undefined;
    }
    const resolver = getFieldUtilsHandler(store, fieldId).resolvePathStepConfiguration?.(configuration);
    if (!resolver?.hasData) {
      return undefined;
    }
    let resolutionType;
    if (mode === 'value') {
      if (resolver.timeseriesMode !== 'explicit') {
        resolutionType = resolver.getValueResolutionType();
      } else {
        resolutionType = resolver.getTimeseriesResolutionType();
      }
    } else if (mode === 'timeseries' && resolver.timeseriesMode !== 'none') {
      resolutionType = resolver.getTimeseriesResolutionType();
    } else if (mode === 'timeseriesIterator' && resolver.timeseriesMode !== 'none') {
      resolutionType = resolver.getTimeseriesResolutionType().elementType;
    } else {
      return undefined;
    }
    if (isArrayFormulaType(resolutionType)) {
      return resolutionType;
    } else if (isMultipleDimensions(store, dimensions)) {
      return arrayOf(resolutionType);
    } else {
      return resolutionType;
    }
  }
};

export const getFilterConditionsFromFilterPath = (store: ObjectStoreWithTimeseries, filterPath: CompiledPath | undefined): BackendFilterConditions | undefined => {
  const getFilterConditionsFromFieldFilterPath = (): FieldFilterConditions<unknown> | undefined => {
    if (isFieldPath(filterPath)) {
      const { step: { fieldId } } = filterPath;
      if (store.getObjectOrNull(fieldId) !== null) {
        return getFieldUtilsHandler(store, fieldId)?.filterConditions;
      }
    }
    return undefined;
  };
  const returnType = filterPath ? getCompiledPathReturnType(store, filterPath, 'value') : undefined;
  if (!filterPath || !returnType) {
    return undefined;
  } else if (isArrayFormulaTypeOfType(returnType, conceptType(Concept))) {
    return getMultipleInstanceFilterConditions(store);
  } else if (isConceptType(returnType)) {
    return getFilterConditionsFromFieldFilterPath() ?? getInstanceFilterConditions(store);
  } else if (!isArrayFormulaType(returnType)) {
    return getFilterConditionsFromFieldFilterPath();
  }
  return undefined;
};

export const getFilterState = (store: ObjectStoreWithTimeseries, filter: FilterCondition): undefined | BackendFilterCondition<FilterValue<unknown>, unknown, unknown> => {
  const { leftValue, operator, rightValue } = filter;
  const isTransitiveCondition = (cond: string) => !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(cond);
  let filterCondition: BackendFilterCondition<FilterValue<unknown>, unknown, unknown> | null | undefined = null;
  if (!operator) {
    return undefined;
  }
  if (!leftValue || !leftValue[0]) {
    return undefined;
  }
  let compiledLeftValue;
  try {
    compiledLeftValue = compilePath(store, leftValue);
  } catch {
    return undefined;
  }
  if (!compiledLeftValue) {
    return undefined;
  }

  const filterConditions = getFilterConditionsFromFilterPath(store, compiledLeftValue);
  filterCondition = filterConditions?.[operator];

  if (!filterCondition) {
    return undefined;
  }

  const { sanitizeValue } = filterCondition;
  const sanitizedValue = sanitizeValue(rightValue as FilterValue<unknown>);
  if (isTransitiveCondition(operator)) {
    let valueContent;
    if (isFilterValueRaw(sanitizedValue)) {
      valueContent = sanitizedValue.raw;
    } else if (isFilterValuePath(sanitizedValue)) {
      valueContent = sanitizedValue.path;
    }
    if (Array.isArray(valueContent) && valueContent.length === 0) {
      return undefined;
    } else if (valueContent == null || valueContent === '') {
      return undefined;
    }
  }

  return filterCondition;
};

export const isValidValuePathResolution = (
  valueResolution: SingleValueResolution | MultiValueResolution | Error | undefined
): valueResolution is SingleValueResolution | MultiValueResolution => !(valueResolution instanceof Error) && valueResolution !== undefined;

export const getFilterConditionContext = (
  store: ObjectStoreWithTimeseries,
  filter: FilterCondition
): FilterConditionExecutionContext | undefined => {
  const filterCondition = getFilterState(store, filter);
  if (!filterCondition) {
    return undefined;
  }
  const { sanitizeValue, filterFunction, isFilterApplicable } = filterCondition;
  const { leftValue = [], operator, rightValue } = filter;
  const sanitizedValue = sanitizeValue(rightValue as FilterValue<unknown>);
  if (!isFilterApplicable(sanitizedValue)) {
    return undefined;
  }
  let compiledLeftValue;
  try {
    compiledLeftValue = compilePath(store, leftValue);
  } catch {
    compiledLeftValue = undefined;
  }
  const returnType = compiledLeftValue ? getCompiledPathReturnType(store, compiledLeftValue, 'value') : undefined;
  const isTargetingConcept = returnType ? isArrayFormulaTypeOfType(returnType, conceptType(Concept)) || isConceptType(returnType) : false;
  const isNPath = returnType ? isArrayFormulaType(returnType) : false;
  const valueShouldBeMultiple = Boolean(operator) && isTargetingConcept && ['IN', 'NOT_IN', 'CONTAINS', 'CONTAINS_SOME', 'DOES_NOT_CONTAIN'].includes(operator ?? '');
  return { sanitizedValue, leftValue, filterFunction, valueShouldBeMultiple, isNPath, isTargetingConcept };
};

export const getFilterFunctionHandler = (store: ObjectStoreWithTimeseries, filters: Filters): FilterFunction => {
  const executeFilterCondition = (
    { sanitizedValue, leftValue, filterFunction, valueShouldBeMultiple, isNPath, isTargetingConcept }: FilterConditionExecutionContext,
    parametersMapping: ParametersMapping = {}
  ): boolean => {
    let resolvedSanitizedValue;
    if (isFilterValuePath(sanitizedValue)) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const pathResolver = createValuePathResolver(store, parametersMapping);
      const valueResolution = pathResolver.resolvePathValue(sanitizedValue.path ?? []);
      if (isSingleValueResolution(valueResolution)) {
        const result = (valueResolution.value as StoreObject | undefined)?.id;
        resolvedSanitizedValue = valueShouldBeMultiple ? [result].filter(filterNullOrUndefined) : result;
      } else if (isMultiValueResolution(valueResolution)) {
        resolvedSanitizedValue = (valueResolution.values as (StoreObject | undefined)[] | undefined)
          ?.filter(filterNullOrUndefined)
          ?.map(({ id }) => id) ?? [];
      }
      if (isTargetingConcept) {
        if (Array.isArray(resolvedSanitizedValue)) {
          resolvedSanitizedValue = resolvedSanitizedValue.map((resolvedValue) => ({ id: resolvedValue, type: InstanceReferenceType.instance }));
        } else {
          resolvedSanitizedValue = resolvedSanitizedValue ? { id: resolvedSanitizedValue, type: InstanceReferenceType.instance } : undefined;
        }
      }
    } else {
      resolvedSanitizedValue = sanitizedValue.raw;
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const pathResolver = createValuePathResolver(store, parametersMapping);
    if (!isNPath) {
      const valueResolution = pathResolver.resolvePathValue(leftValue);
      if (isValidValuePathResolution(valueResolution)) {
        if (isTargetingConcept) {
          if (!isSingleValueResolution(valueResolution)) {
            return false;
          }
          const instanceId = (valueResolution.value as StoreObject | undefined)?.id;
          return filterFunction(instanceId, resolvedSanitizedValue, parametersMapping);
        } else if (valueResolution && isSingleValueResolution(valueResolution)) {
          return filterFunction(valueResolution.value, resolvedSanitizedValue, parametersMapping);
        }
      }
    } else if (isNPath && isTargetingConcept) {
      const valueResolution = pathResolver.resolvePathValue(leftValue);
      if (valueResolution && isMultiValueResolution(valueResolution)) {
        return filterFunction(
          (valueResolution.values as (StoreObject | undefined)[] | undefined)
            ?.filter(filterNullOrUndefined)
            ?.map(({ id }) => id) ?? [],
          resolvedSanitizedValue,
          parametersMapping
        );
      }
    }
    return false;
  };

  const evaluateSingleFilter = (filterCondition: FilterCondition): FilterFunction => {
    if (store.hasPropertyFunction(Concept_GetFilterConditionContext)) {
      const filterConditionKey = JSON.stringify(filterCondition);
      const filterConditionExecutionContext = store.getObject(filterConditionKey, true)[Concept_GetFilterConditionContext] as FilterConditionExecutionContext | undefined;
      if (!filterConditionExecutionContext) {
        return undefined;
      }
      if (!store.hasPropertyFunction(filterConditionKey)) {
        store.registerPropertyFunction(filterConditionKey, (dimensionalIds) => {
          const dimensionsMapping = dimensionsMappingFromDimensionalIds(dimensionalIds);
          return executeFilterCondition(filterConditionExecutionContext, dimensionsMappingToParametersMapping(dimensionsMapping));
        });
      }
      return (parameters) => store.getObject(buildFilterId(parameters), true)[filterConditionKey] as boolean;
    } else {
      const filterConditionContext = getFilterConditionContext(store, filterCondition);
      if (!filterConditionContext) {
        return undefined;
      }
      return (parameters) => executeFilterCondition(filterConditionContext, parameters);
    }
  };
  if (isFilterNode(filters)) {
    const nodeCondition = filters.condition;
    const filterGroupFunctions = filters.children?.map(
      (filter): FilterFunction => {
        if (isFilterNode(filter)) {
          return getFilterFunctionHandler(store, filter);
        } else {
          return evaluateSingleFilter(filter);
        }
      }
    ).filter((filterFunction): filterFunction is NonNullable<FilterFunction> => Boolean(filterFunction));

    if (!filterGroupFunctions || filterGroupFunctions.length === 0) {
      return undefined;
    } else if (filterGroupFunctions.length === 1) {
      return filterGroupFunctions[0];
    } else if (nodeCondition === FilterConditionOperators.AND) {
      return (parameters) => filterGroupFunctions.every((filterFunction) => filterFunction(parameters));
    } else {
      return (parameters) => filterGroupFunctions.some((filterFunction) => filterFunction(parameters));
    }
  } else {
    return evaluateSingleFilter(filters);
  }
};

export const isMultiplePath = (store: ObjectStoreWithTimeseries, path: PathStep[]): boolean => {
  let compiledPath;
  try {
    compiledPath = compilePath(store, path);
    if (!compiledPath) {
      return false;
    }
    const returnType = getCompiledPathReturnType(store, compiledPath, 'value');
    return returnType ? isArrayFormulaType(returnType) : false;
  } catch {
    return false;
  }
};

export const getPathReturnedType = (
  store: ObjectStoreWithTimeseries,
  path: PathStep[],
  mode: 'value' | 'timeseries' | 'timeseriesIterator'
): { type: 'resolvable', returnType: FormulaType, timeseriesMode: 'none' | 'implicit' | 'explicit' } | { type: 'unresolvable', reason: string, data: Record<string, unknown> } => {
  let compiledPath;
  try {
    compiledPath = compilePath(store, path);
    if (!compiledPath) {
      return { type: 'unresolvable', reason: 'Unable to compile path', data: { path } };
    }
    const returnType = getCompiledPathReturnType(store, compiledPath, mode);
    if (!returnType) {
      return { type: 'unresolvable', reason: 'Missing return type', data: { path, compiledPath } };
    }
    let timeseriesMode: 'none' | 'implicit' | 'explicit';
    if (isRhythmicTimeseriesFormulaType(returnType) || isArrayFormulaTypeOfType(returnType, rhythmicTimeseriesOf(anyType))) {
      timeseriesMode = 'explicit';
    } else if (isArrhythmicTimeseriesFormulaType(returnType) || isArrayFormulaTypeOfType(returnType, arrhythmicTimeseriesOf(anyType))) {
      timeseriesMode = 'implicit';
    } else {
      timeseriesMode = 'none';
    }
    return { type: 'resolvable', returnType, timeseriesMode };
  } catch (e) {
    return { type: 'unresolvable', reason: 'Error at path resolution', data: { path, error: errorToObject(e) } };
  }
};

export const isPathTargetingConcept = (store: ObjectStoreReadOnly, path: PathStep[]): boolean => {
  let result = path.length > 0;
  for (let i = 0; i < path.length; i += 1) {
    const nStep = path[i];
    if (isGlobalDimensionStep(nStep)) {
      result = false;
    } else if (isFieldStep(nStep)) {
      const stepField = store.getObjectOrNull(nStep.fieldId);
      if (stepField && !isRelationalType(stepField[Instance_Of] as string)) {
        result = false;
      } else {
        result = !!stepField;
      }
    }
  }
  return result;
};

const resolveConceptStep = (
  store: ObjectStoreWithTimeseries,
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
  step: CompiledConceptStep
): ConceptResolution | undefined => {
  const conceptDefinition = store.getObjectOrNull<ConceptDefinitionStoreObject>(step.conceptDefinitionId);
  if (!conceptDefinition) {
    throw new StepResolutionError(
      { type: StepResolutionErrorStepType.dimension, conceptDefinitionId: step.conceptDefinitionId },
      { resolutionError: ResolutionError.invalidDimension }
    );
  }
  if (!step.restriction) {
    return {
      type: 'multiple',
      instances: conceptDefinition.navigateBack<ConceptStoreObject>(Instance_Of) ?? [],
      resolutionType: arrayOf(conceptType(step.conceptDefinitionId)),
    };
  }
  const { singleParametersMapping, multipleParametersMapping } = segregateParametersMapping(parametersMapping);
  switch (step.restriction.type) {
    case 'instance': {
      const instance = store.getObjectOrNull<ConceptStoreObject>(step.restriction.id);
      if (!instance || !isInstanceOf(instance, step.conceptDefinitionId)) {
        throw new StepResolutionError({ type: StepResolutionErrorStepType.mapping }, { resolutionError: ResolutionError.invalidMapping });
      }
      return { type: 'single', instance, resolutionType: conceptType(step.conceptDefinitionId) };
    }
    case 'parameter': {
      const mappedParameterInstanceId = singleParametersMapping[step.restriction.id]?.id;
      if (mappedParameterInstanceId === UnsetDashboardParameterOption) {
        return { type: 'single', instance: undefined, resolutionType: conceptType(step.conceptDefinitionId) };
      } else {
        const instance = mappedParameterInstanceId ? store.getObjectOrNull<ConceptStoreObject>(mappedParameterInstanceId) ?? undefined : undefined;
        if (!instance || !isInstanceOf(instance, step.conceptDefinitionId)) {
          return { type: 'single', instance: undefined, resolutionType: conceptType(step.conceptDefinitionId) };
        }
        return { type: 'single', instance, resolutionType: conceptType(step.conceptDefinitionId) };
      }
    }
    case 'parameterMultiple':
    case 'filters': {
      let instances: ConceptStoreObject[] | undefined;
      if (step.restriction.type === 'filters') {
        instances = conceptDefinition.navigateBack<ConceptStoreObject>(Instance_Of) ?? [];
      } else {
        const mappedParameterInstanceIds = multipleParametersMapping[step.restriction.id]?.ids;
        instances = mappedParameterInstanceIds
          ? mappedParameterInstanceIds
            .map((mappedParameterInstanceId) => store.getObjectOrNull<ConceptStoreObject>(mappedParameterInstanceId))
            .filter(filterNullOrUndefined)
            .filter((instance) => isInstanceOf(instance, step.conceptDefinitionId))
          : [];
      }
      let filterFunction;
      if (!step.restriction.filters) {
        filterFunction = undefined;
      } else if (store.hasPropertyFunction(Concept_GetFilterFunction)) {
        filterFunction = store.getObject(JSON.stringify(step.restriction.filters), true)[Concept_GetFilterFunction] as FilterFunction;
      } else {
        filterFunction = getFilterFunctionHandler(store, step.restriction.filters);
      }
      if (filterFunction) {
        return {
          type: 'multiple',
          instances: instances
            .filter((instance) => filterFunction(joinObjects(singleParametersMapping, { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: instance.id } }))),
          resolutionType: arrayOf(conceptType(step.conceptDefinitionId)),
        };
      } else {
        return { type: 'multiple', instances, resolutionType: arrayOf(conceptType(step.conceptDefinitionId)) };
      }
    }
  }
};

interface SingleDimensionResolution extends DimensionResolution {
  type: DimensionResolutionType.single,
  instance: ConceptStoreObject | undefined,
  resolutionType: FormulaType,
}

interface MultiDimensionResolution extends DimensionResolution {
  type: DimensionResolutionType.multi,
  instances: ConceptStoreObject[],
  resolutionType: FormulaType,
}

interface ValuePathResolver {
  resolvePathValue: <T = unknown>(
    valuePath: PathStep[], resolutionStack?: ResolutionStack
  ) => SingleValueResolution<T> | MultiValueResolution<T> | StepResolutionError | Error | undefined,
  resolvePathTimeseries: <T = unknown>(
    valuePath: PathStep[], dateRange: TimeRange | undefined, resolutionStack?: ResolutionStack
  ) => SingleValueResolution<T> | MultiValueResolution<T> | StepResolutionError | Error | undefined,
  resolvePathField: (valuePath: PathStep[]) => SingleFieldResolution | MultiFieldResolution | StepResolutionError | Error | undefined,
  resolvePathDimension: (valuePath: PathStep[]) => SingleDimensionResolution | MultiDimensionResolution | StepResolutionError | Error | undefined,
}

const getMappedDimensions = (dimension: DimensionsMapping, step: CompiledFieldStep): DimensionsMapping => (
  Object.fromEntries(Object.entries(step.mapping).map(([key, value]) => ([key, dimension[value]])))
);

const resolveFieldStepDimensions = (
  store: ObjectStoreWithTimeseries,
  step: CompiledFieldStep,
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
  dimensionsResolution: DimensionsResolution,
  resolutionStack: ResolutionStack
): ConceptResolution | undefined => {
  if (store.getObjectOrNull(step.fieldId) === null) {
    throw newError('Cannot resolve field step dimensions, field is invalid.');
  }
  const resolver = getFieldUtilsHandler(store, step.fieldId).resolvePathStepConfiguration(step.configuration);
  if (!resolver.hasData) {
    throw fromError(resolver, 'Cannot resolve field step dimensions, invalid field step, no data.', { step, dimensionsResolution });
  } else if (resolver.timeseriesMode === 'explicit') {
    throw fromError(resolver, 'Cannot resolve field step dimensions, invalid field step, timeseries field.', { step, dimensionsResolution });
  }
  const { resolveDimension, getValueResolutionType } = resolver;
  const resolutionType = getValueResolutionType();
  if (!resolveDimension) {
    throw newError('Cannot resolve field step dimensions, field cannot be used to resolve a dimension.', { step, dimensionsResolution });
  } else if (!isConceptType(resolutionType) && !isArrayFormulaTypeOfType(resolutionType, conceptType(Concept))) {
    throw newError('Cannot resolve field step dimensions, field does not return a concept type.', { step, dimensionsResolution });
  } else if (dimensionsResolution.type === 'single') {
    const resolution = resolveDimension(getMappedDimensions(dimensionsResolution.dimension, step), parametersMapping, resolutionStack);
    if (resolution.type === 'single' && isConceptType(resolutionType)) {
      return { type: 'single', instance: resolution.instance, resolutionType };
    } else if (resolution.type === 'multiple' && isArrayFormulaTypeOfType(resolutionType, conceptType(Concept))) {
      return { type: 'multiple', instances: resolution.instances, resolutionType };
    } else {
      throw newError('Cannot resolve field step dimensions, mismatch between resolveDimension and field resolution type.');
    }
  } else {
    return {
      type: 'multiple',
      instances: dimensionsResolution.dimensions.flatMap((dimension) => {
        const resolvedDimensions = resolveDimension(getMappedDimensions(dimension, step), parametersMapping, resolutionStack);
        if (resolvedDimensions.type === 'single') {
          return resolvedDimensions.instance ? [resolvedDimensions.instance] : [];
        } else {
          return resolvedDimensions.instances;
        }
      }),
      resolutionType: isConceptType(resolutionType) ? arrayOf(resolutionType) : resolutionType,
    };
  }
};

const resolveDimensions = (
  store: ObjectStoreWithTimeseries,
  dimensions: Dimensions,
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
  resolutionStack: ResolutionStack
): DimensionsResolution | undefined => {
  const dimensionEntries = Object.entries(dimensions);
  if (dimensionEntries.length === 0) { // global use case
    return { type: 'single', dimension: {} };
  }
  const dimensionsResolution: Map<string, ConceptResolution> = new Map();
  dimensionEntries.forEach(([dimensionId, { isOptional, path }]) => {
    if (isConceptPath(path)) {
      const conceptResolution = resolveConceptStep(store, parametersMapping, path.step);
      if (conceptResolution?.type === 'single' && (conceptResolution.instance !== undefined || isOptional)) {
        dimensionsResolution.set(dimensionId, conceptResolution);
      } else if (conceptResolution?.type === 'multiple') {
        dimensionsResolution.set(dimensionId, conceptResolution);
      }
    } else if (isFieldPath(path)) {
      const resolvedDimensions = resolveDimensions(store, path.dimensions, parametersMapping, resolutionStack);
      const resolvedFieldDimensions = resolvedDimensions ? resolveFieldStepDimensions(store, path.step, parametersMapping, resolvedDimensions, resolutionStack) : undefined;
      if (resolvedFieldDimensions?.type === 'single' && (resolvedFieldDimensions.instance !== undefined || isOptional)) {
        dimensionsResolution.set(dimensionId, resolvedFieldDimensions);
      } else if (resolvedFieldDimensions?.type === 'multiple') {
        dimensionsResolution.set(dimensionId, resolvedFieldDimensions);
      }
    }
  });
  if (dimensionsResolution.size === 0) {
    return undefined;
  }
  const type = [...dimensionsResolution.values()].some((stepDimensionsResolution) => stepDimensionsResolution.type === 'multiple') ? 'multiple' : 'single';
  let values: DimensionsMapping[] = [];
  dimensionsResolution.forEach((stepDimensionsResolution, dimensionId) => {
    if (!values.length) {
      if (stepDimensionsResolution.type === 'single') {
        values = stepDimensionsResolution.instance ? [{ [dimensionId]: stepDimensionsResolution.instance.id }] : [];
      } else {
        values = stepDimensionsResolution.instances.map((value) => ({ [dimensionId]: value.id }));
      }
    } else if (stepDimensionsResolution.type === 'single') {
      values = values.map((value) => (joinObjects({ [dimensionId]: stepDimensionsResolution.instance?.id }, value)));
    } else if (stepDimensionsResolution.type === 'multiple') {
      values = values.flatMap((value) => stepDimensionsResolution.instances.map(({ id }) => (joinObjects({ [dimensionId]: id }, value))));
    }
  });
  if (type === 'multiple') {
    return { type: 'multiple', dimensions: values };
  } else if (type === 'single') {
    return { type: 'single', dimension: values.at(0) ?? {} };
  }
  return undefined;
};

const resolveFieldStepValue = (
  store: ObjectStoreWithTimeseries,
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
  step: CompiledFieldStep,
  dimensions: DimensionsResolution,
  resolutionStack: ResolutionStack
): PathResolution => {
  const field = store.getObjectOrNull(step.fieldId);
  if (!field) {
    throw new StepResolutionError({ type: StepResolutionErrorStepType.field, fieldId: step.fieldId }, { resolutionError: ResolutionError.invalidField });
  }
  const resolver = getFieldUtilsHandler(store, step.fieldId).resolvePathStepConfiguration(step.configuration);
  if (!resolver.hasData) {
    throw newError('Cannot resolve field step dimensions, invalid field step, no data.', { step, dimensions });
  } else if (resolver.timeseriesMode === 'explicit') {
    if (dimensions.type === 'single') {
      checkDimensionsValidity(store, step.fieldId, getMappedDimensions(dimensions.dimension, step), Boolean(field[Field_Formula]));
      return {
        value: resolver.resolveTimeseries(getMappedDimensions(dimensions.dimension, step), parametersMapping, resolutionStack, undefined),
        resolutionType: resolver.getTimeseriesResolutionType(),
      };
    } else {
      return {
        value: dimensions.dimensions.map((dimension) => {
          const mappedDimensions = getMappedDimensions(dimension, step);
          checkDimensionsValidity(store, step.fieldId, mappedDimensions, Boolean(field[Field_Formula]));
          return resolver.resolveTimeseries(getMappedDimensions(dimension, step), parametersMapping, resolutionStack, undefined);
        }),
        resolutionType: arrayOf(resolver.getTimeseriesResolutionType()),
      };
    }
  } else {
    const { resolveValue } = resolver;
    const resolutionType = resolver.getValueResolutionType();
    if (dimensions.type === 'single') {
      checkDimensionsValidity(store, step.fieldId, getMappedDimensions(dimensions.dimension, step), Boolean(field[Field_Formula]));
      return { value: resolveValue(getMappedDimensions(dimensions.dimension, step), parametersMapping, resolutionStack), resolutionType };
    } else if (isArrayFormulaType(resolutionType)) {
      return {
        value: dimensions.dimensions.flatMap((dimension) => {
          const mappedDimensions = getMappedDimensions(dimension, step);
          checkDimensionsValidity(store, step.fieldId, mappedDimensions, Boolean(field[Field_Formula]));
          return resolveValue(getMappedDimensions(dimension, step), parametersMapping, resolutionStack) as unknown[];
        }),
        resolutionType,
      };
    } else {
      return {
        value: dimensions.dimensions.map((dimension) => {
          const mappedDimensions = getMappedDimensions(dimension, step);
          checkDimensionsValidity(store, step.fieldId, mappedDimensions, Boolean(field[Field_Formula]));
          return resolveValue(mappedDimensions, parametersMapping, resolutionStack);
        }),
        resolutionType: arrayOf(resolutionType),
      };
    }
  }
};

const resolveFieldStepTimeseriesValue = (
  store: ObjectStoreWithTimeseries,
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
  step: CompiledFieldStep,
  dimensions: DimensionsResolution,
  resolutionStack: ResolutionStack,
  dateRange: TimeRange | undefined
): PathResolution => {
  const field = store.getObjectOrNull(step.fieldId);
  if (field === null) {
    throw newError('Cannot resolve field step timeseries, field is invalid.');
  }
  const resolver = getFieldUtilsHandler(store, step.fieldId).resolvePathStepConfiguration(step.configuration);
  if (!resolver.hasData) {
    throw fromError(resolver, 'Cannot resolve field step timeseries, no data available on this field.', { step, dimensions });
  } else if (resolver.timeseriesMode === 'none') {
    throw fromError(resolver, 'Cannot resolve field step timeseries, no implicit timeseries available on this field.', { step, dimensions });
  }
  const { resolveTimeseries, getTimeseriesResolutionType } = resolver;
  if (!resolveTimeseries) {
    throw newError('resolveFieldStepTimeseriesValue cannot be called on this field step', { dimensions, step });
  }
  const resolutionType = getTimeseriesResolutionType();
  if (dimensions.type === 'single') {
    checkDimensionsValidity(store, step.fieldId, getMappedDimensions(dimensions.dimension, step), Boolean(field[Field_Formula]));
    return {
      value: resolveTimeseries(getMappedDimensions(dimensions.dimension, step), parametersMapping, resolutionStack, dateRange),
      resolutionType,
    };
  } else {
    return {
      value: dimensions.dimensions.map((dimension) => {
        const mappedDimensions = getMappedDimensions(dimension, step);
        checkDimensionsValidity(store, step.fieldId, mappedDimensions, Boolean(field[Field_Formula]));
        return resolveTimeseries(mappedDimensions, parametersMapping, resolutionStack, dateRange);
      }),
      resolutionType: arrayOf(resolutionType),
    };
  }
};

const resolveTimeseriesPath = (
  store: ObjectStoreWithTimeseries,
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
  path: PathStep[],
  dateRange: TimeRange | undefined,
  resolutionStack: ResolutionStack
): PathResolution | undefined => {
  const compiledPath = compilePath(store, path);
  if (isConceptPath(compiledPath)) {
    throw newError('resolveTimeseriesPath cannot be called on a dimension path', { path });
  } else if (isFieldPath(compiledPath)) {
    const dimensions = resolveDimensions(store, compiledPath.dimensions, parametersMapping, resolutionStack);
    return dimensions ? resolveFieldStepTimeseriesValue(store, parametersMapping, compiledPath.step, dimensions, resolutionStack, dateRange) : undefined;
  } else {
    throw newError('resolveTimeseriesPath cannot be called on an invalid path', { path, compiledPath });
  }
};

const resolvePathTimeseries = <T = unknown>(
  store: ObjectStoreWithTimeseries,
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
  path: PathStep[],
  dateRange: TimeRange | undefined,
  resolutionStack?: ResolutionStack
): SingleValueResolution<T> | MultiValueResolution<T> | StepResolutionError | Error | undefined => {
  try {
    const resolution = resolveTimeseriesPath(store, parametersMapping, path, dateRange, resolutionStack ?? createResolutionStack());
    if (resolution) {
      const { resolutionType, value } = resolution;
      if (isArrayFormulaType(resolutionType)) {
        return {
          type: ValueResolutionType.multi,
          values: value as T[],
          isArrhythmicTimeseries: resolutionType.isAssignableFrom(arrayOf(arrhythmicTimeseriesOf(anyType))),
          isTimeseries: resolutionType.isAssignableFrom(arrayOf(rhythmicTimeseriesOf(anyType))) || resolutionType.isAssignableFrom(arrayOf(arrhythmicTimeseriesOf(anyType))),
          resolutionType,
        };
      } else {
        return {
          type: ValueResolutionType.single,
          value: value as T,
          isArrhythmicTimeseries: resolutionType.isAssignableFrom(arrhythmicTimeseriesOf(anyType)),
          isTimeseries: resolutionType.isAssignableFrom(rhythmicTimeseriesOf(anyType)) || resolutionType.isAssignableFrom(arrhythmicTimeseriesOf(anyType)),
          resolutionType,
        };
      }
    } else {
      return undefined;
    }
  } catch (e) {
    if (e instanceof StepResolutionError) {
      return e;
    }
    return fromError(e, 'Resolution error');
  }
};

const resolvePathField = (
  store: ObjectStoreWithTimeseries,
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
  path: PathStep[]
): ReturnType<ValuePathResolver['resolvePathField']> => {
  try {
    if (path[path.length - 1]?.type !== 'field') {
      return undefined;
    }
    const compiledPath = compilePath(store, path);
    if (isFieldPath(compiledPath)) {
      const { dimensions, step: { fieldId } } = compiledPath;
      const dimensionsResolution = resolveDimensions(store, dimensions, parametersMapping, createResolutionStack());
      if (dimensionsResolution) {
        const field = store.getObjectOrNull(compiledPath.step.fieldId);
        if (field === null) {
          return new StepResolutionError({ type: StepResolutionErrorStepType.field, fieldId: compiledPath.step.fieldId }, { resolutionError: ResolutionError.invalidField });
        }
        const resolver = getFieldUtilsHandler(store, compiledPath.step.fieldId).resolvePathStepConfiguration(compiledPath.step.configuration);
        if (dimensionsResolution.type === 'multiple') {
          return {
            type: FieldResolutionType.multi,
            resolutions: dimensionsResolution.dimensions.map((dimensionsMapping) => {
              let resolvedDimensionsMapping = getMappedDimensions(dimensionsMapping, compiledPath.step);
              checkDimensionsValidity(store, compiledPath.step.fieldId, resolvedDimensionsMapping, Boolean(field[Field_Formula]));
              if (resolver.hasData && resolver.getFieldResolutionDimensions) {
                resolvedDimensionsMapping = resolver.getFieldResolutionDimensions(resolvedDimensionsMapping, parametersMapping);
              }
              return { type: FieldResolutionType.single, dimensionsMapping: resolvedDimensionsMapping, fieldId };
            }),
            fieldId,
          };
        } else {
          let resolvedDimensionsMapping = getMappedDimensions(dimensionsResolution.dimension, compiledPath.step);
          checkDimensionsValidity(store, compiledPath.step.fieldId, resolvedDimensionsMapping, Boolean(field[Field_Formula]));
          if (resolver.hasData && resolver.getFieldResolutionDimensions) {
            resolvedDimensionsMapping = resolver.getFieldResolutionDimensions(resolvedDimensionsMapping, parametersMapping);
          }
          return { type: FieldResolutionType.single, dimensionsMapping: resolvedDimensionsMapping, fieldId };
        }
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  } catch (e) {
    if (e instanceof StepResolutionError) {
      return e;
    }
    return fromError(e, 'Resolution error');
  }
};

const resolveValuePath = (
  store: ObjectStoreWithTimeseries,
  path: PathStep[],
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
  resolutionStack: ResolutionStack
): PathResolution | undefined => {
  const compiledPath = compilePath(store, path);
  if (compiledPath && isConceptPath(compiledPath)) {
    const conceptResolution = resolveConceptStep(store, parametersMapping, compiledPath.step);
    if (!conceptResolution) {
      return undefined;
    } else if (conceptResolution.type === 'single') {
      return { value: conceptResolution.instance, resolutionType: conceptResolution.resolutionType };
    } else {
      return { value: conceptResolution.instances, resolutionType: conceptResolution.resolutionType };
    }
  } else if (isFieldPath(compiledPath)) {
    const dimensions = resolveDimensions(store, compiledPath.dimensions, parametersMapping, resolutionStack);
    return dimensions ? resolveFieldStepValue(store, parametersMapping, compiledPath.step, dimensions, resolutionStack) : undefined;
  } else if (!compiledPath) {
    return undefined;
  } else {
    throw newError('Path is empty');
  }
};

const resolvePathDimension = (
  store: ObjectStoreWithTimeseries,
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
  path: PathStep[]
): ReturnType<ValuePathResolver['resolvePathDimension']> => {
  try {
    const resolution = resolveValuePath(store, path, parametersMapping, createResolutionStack());
    if (resolution) {
      const { value, resolutionType } = resolution;
      if (!resolutionType) {
        throw newError('Missing resolutionType');
      } else if (isArrayFormulaTypeOfType(resolutionType, conceptType(Concept))) {
        return { type: DimensionResolutionType.multi, instances: (value as ConceptStoreObject[]).filter(filterNullOrUndefined), resolutionType };
      } else if (isConceptType(resolutionType)) {
        return { type: DimensionResolutionType.single, instance: value as ConceptStoreObject | undefined, resolutionType };
      }
    }
    return undefined;
  } catch (e) {
    if (e instanceof StepResolutionError) {
      return e;
    }
    return fromError(e, 'Cannot resolve path dimension');
  }
};

const resolvePathValue = <T = unknown>(
  store: ObjectStoreWithTimeseries,
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
  valuePath: PathStep[],
  resolutionStack?: ResolutionStack
): SingleValueResolution<T> | MultiValueResolution<T> | Error | undefined => {
  try {
    const resolution = resolveValuePath(store, valuePath, parametersMapping, resolutionStack ?? createResolutionStack());
    if (!resolution) {
      return undefined;
    } else {
      const { resolutionType, value } = resolution;
      if (!resolutionType) {
        throw newError('Missing resolutionType');
      } else if (isArrayFormulaType(resolutionType)) {
        return {
          type: ValueResolutionType.multi,
          values: value as T[],
          isArrhythmicTimeseries: resolutionType.isAssignableFrom(arrayOf(arrhythmicTimeseriesOf(anyType))),
          isTimeseries: resolutionType.isAssignableFrom(arrayOf(rhythmicTimeseriesOf(anyType))) || resolutionType.isAssignableFrom(arrayOf(arrhythmicTimeseriesOf(anyType))),
          resolutionType,
        };
      } else {
        return {
          type: ValueResolutionType.single,
          value: value as T,
          isArrhythmicTimeseries: resolutionType.isAssignableFrom(arrhythmicTimeseriesOf(anyType)),
          isTimeseries: resolutionType.isAssignableFrom(rhythmicTimeseriesOf(anyType)) || resolutionType.isAssignableFrom(arrhythmicTimeseriesOf(anyType)),
          resolutionType,
        };
      }
    }
  } catch (e) {
    if (e instanceof StepResolutionError) {
      return e;
    }
    return fromError(e, 'Cannot resolve path value');
  }
};

export const createValuePathResolver = (
  store: ObjectStoreWithTimeseries,
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>
): ValuePathResolver => ({
  resolvePathValue: (path, resolutionStack) => resolvePathValue(store, parametersMapping, path, resolutionStack),
  resolvePathTimeseries: (path, dateRange, resolutionStack) => resolvePathTimeseries(store, parametersMapping, path, dateRange, resolutionStack),
  resolvePathField: (path) => resolvePathField(store, parametersMapping, path),
  resolvePathDimension: (path) => resolvePathDimension(store, parametersMapping, path),
});
