import type { FunctionComponent } from 'react';
import { v4 as uuid } from 'uuid';
import type { AutomationActionEmailRaw, AutomationRuleRaw } from 'yooi-modules/modules/automationModule';
import { AutomationAction_Rule, AutomationActionEmail, AutomationActionEmail_Recipients, AutomationRule, AutomationRule_UserId } from 'yooi-modules/modules/automationModule/ids';
import { InstanceReferenceType, PathStepType } from 'yooi-modules/modules/conceptModule';
import { User } from 'yooi-modules/modules/conceptModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import useAuth from '../../../store/useAuth';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import AutomationRuleList from './AutomationRuleList';

const PersonalNotificationRuleList: FunctionComponent = () => {
  const store = useStore();
  const { loggedUserId } = useAuth();

  return (
    <AutomationRuleList
      subtitle={i18n`Only you can manage and use the rules below.`}
      ruleIds={
        store.getObject(AutomationRule)
          .navigateBack(Class_Instances)
          .filter((rule) => rule[AutomationRule_UserId] === loggedUserId)
          .map(({ id }) => id)
      }
      onCreate={() => {
        const ruleId = store.createObject<AutomationRuleRaw>({ [Instance_Of]: AutomationRule, [AutomationRule_UserId]: loggedUserId });
        store.createObject<AutomationActionEmailRaw>({
          [Instance_Of]: AutomationActionEmail,
          [AutomationAction_Rule]: ruleId,
          [AutomationActionEmail_Recipients]: [
            {
              id: uuid(),
              type: 'path',
              value: [
                { type: PathStepType.dimension, conceptDefinitionId: User },
                { type: PathStepType.mapping, mapping: { id: loggedUserId, type: InstanceReferenceType.instance } },
              ],
            },
          ],
        });
        return ruleId;
      }}
    />
  );
};

export default PersonalNotificationRuleList;
