import type { FunctionComponent } from 'react';
import { spacingRem } from '../../../theme/spacingDefinition';
import makeStyles from '../../../utils/makeStyles';
import { approximateNumber } from '../../../utils/numberUtils';
import Icon, { IconName } from '../../atoms/Icon';
import Tooltip from '../../atoms/Tooltip';
import Typo, { TypoVariant } from '../../atoms/Typo';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    marginRight: spacingRem.xs,
  },
}, 'percentageEvolution');

interface PercentageEvolutionProps {
  value: number,
}

const PercentageEvolution: FunctionComponent<PercentageEvolutionProps> = ({ value }) => {
  const classes = useStyles();

  const renderEvolutionIcon = () => {
    if (value > 0) {
      return <div className={classes.iconContainer}><Icon name={IconName.expand_less} /></div>;
    } else if (value < 0) {
      return <div className={classes.iconContainer}><Icon name={IconName.expand_more} /></div>;
    } else if (value === 0) {
      return <div className={classes.iconContainer}><Icon name={IconName.keyboard_arrow_right} /></div>;
    } else {
      return null;
    }
  };

  if (value === null) {
    return null;
  } else {
    const showedNumber = `${value >= 0 ? '+' : ''}${approximateNumber(value)}%`;
    return (
      <div className={classes.container}>
        {renderEvolutionIcon()}
        <Typo variant={TypoVariant.small} maxLine={1}>
          <Tooltip title={showedNumber}>
            <span>{showedNumber}</span>
          </Tooltip>
        </Typo>
      </div>
    );
  }
};

export default PercentageEvolution;
