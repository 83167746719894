import type { FunctionComponent } from 'react';
import { useDebounce } from 'use-debounce';
import useResizeObserver from 'use-resize-observer';
import type { ParametersMapping } from 'yooi-modules/modules/conceptModule';
import type { CardsViewStoredDefinition, ViewDimension } from 'yooi-modules/modules/dashboardModule';
import BlockContent from '../../../../components/templates/BlockContent';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import useDeepMemo from '../../../../utils/useDeepMemo';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { useViewFilters } from '../../filter/useViewFilters';
import BlockViewError from '../common/BlockViewError';
import { getViewDefinitionHandler } from '../viewDsl';
import { isResolutionError } from '../viewResolutionUtils';
import CardsView from './CardsView';
import type { CardsViewDefinitionHandler } from './cardsViewDefinitionHandler';

const useStyles = makeStyles((theme) => ({
  paddedContainer: {
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
    paddingLeft: spacingRem.s,
  },
}), 'cardsViewBlock');

interface CardsViewBlockProps {
  viewDefinition: CardsViewStoredDefinition,
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  parametersMapping: ParametersMapping,
  readOnly: boolean | undefined,
}

const CardsViewBlock: FunctionComponent<CardsViewBlockProps> = ({
  viewDefinition,
  viewDimensions,
  viewFilters,
  parametersMapping,
  readOnly,
}) => {
  const classes = useStyles();

  const store = useStore();
  const aclHandler = useAcl();
  const { loggedUserId } = useAuth();

  const [availableWidth, setAvailableWidth] = useDebounce(0, 25);
  const { ref: containerRef } = useResizeObserver({
    onResize: ({ width }) => {
      setAvailableWidth(width ?? 0);
    },
  });

  const filterConfiguration = useViewFilters(viewFilters, viewDimensions);

  const viewHandler = getViewDefinitionHandler(viewDefinition) as CardsViewDefinitionHandler;

  const viewResolvedDefinition = viewHandler.getDefinition(store, viewDimensions);

  const viewResolution = useDeepMemo(
    () => viewHandler.resolveView(store, { viewDimensions, aclHandler, parametersMapping, userId: loggedUserId, readOnly, filterConfiguration }),
    [store.getSerial(), viewDimensions, viewDefinition, parametersMapping, filterConfiguration]
  );

  if (isResolutionError(viewResolution)) {
    return (<BlockViewError error={viewResolution.error} />);
  } else {
    return (
      <div className={classes.paddedContainer} ref={containerRef}>
        <BlockContent>
          {availableWidth > 0 ? (
            <CardsView
              viewDefinition={viewResolvedDefinition}
              viewDimensions={viewDimensions}
              viewResolution={viewResolution}
              parametersMapping={parametersMapping}
              filterKey={viewFilters.filterKey}
              availableWidth={availableWidth}
            />
          ) : null}
        </BlockContent>
      </div>
    );
  }
};

export default CardsViewBlock;
