import { OriginSources, ValidationStatus } from 'yooi-store';
import type { RichText } from 'yooi-utils';
import { emailRegex, richTextToText } from 'yooi-utils';
import { CommonAsType } from '../../common/fields/commonPropertyType';
import type { BusinessRuleRegistration } from '../../common/types/TypeModuleDslType';
import { Integration } from '../../integrationModule/ids';
import { Class_Instances, Instance_Of } from '../../typeModule/ids';
import {
  Association,
  Association_Role_Definition,
  Association_Role_Role1TypeInstance,
  Association_Role_Role2TypeInstance,
  AssociationField,
  AssociationField_Definition,
  AssociationField_SourceRole,
  AssociationFieldDefinition,
  AssociationFieldDefinition_Role1Type,
  AssociationFieldDefinition_Role2Type,
  BooleanField,
  BooleanField_FalseValue,
  BooleanField_TrueValue,
  Concept,
  Concept_Name,
  ConceptCapabilityCreate,
  ConceptCapabilityEdit,
  ConceptCollaborationFieldDimension,
  ConceptCreatedAtDimension,
  ConceptDefinition,
  ConceptDefinition_IsCore,
  ConceptGroupCapability,
  ConceptGroupCapability_Role_ConceptCapability,
  ConceptGroupCapability_Role_ConceptDefinition,
  ConceptGroupCapability_Role_ConceptGroup,
  ConceptNameDimension,
  ConceptStakeholdersGroupsDimension,
  ConceptStakeholdersRolesDimension,
  ConceptStakeholdersUsersDimension,
  ConceptUpdatedAtDimension,
  ExternalKeyField,
  ExternalKeyField_IsImmutable,
  ExternalKeyField_IsRequired,
  ExternalKeyField_PreventKeyStealing,
  ExternalKeyField_RegexValidation,
  Field_ApiAlias,
  Field_IsCore,
  Field_Title,
  FieldDimension,
  FieldDimension_Field,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  Group,
  Group_GrantedOnCreation,
  Group_Users,
  GroupMembershipAssociationDefinition,
  GroupMembershipDimension,
  PlatformCapabilityAdmin,
  PlatformCapabilityInviteUser,
  PlatformCapabilityUpdateEmail,
  User,
  User_AuthenticationId,
  User_Email,
  User_GroupIds,
  User_Groups,
  User_IsEnabled,
  User_Type,
  UserAuthenticationIdDimension,
  UserEmailDimension,
  UserGroupMembershipDimension,
  UserIsEnabledDimension,
} from '../ids';
import { hasConceptCapability, registerModel } from '../module';
import { adminOnlyAcl, hasOnePlatformCapabilitiesOf, hasPlatformCapability } from '../utils/userUtils';

const { type, instance, associate, propertyFunction } = registerModel;

const updateGroupGranted: BusinessRuleRegistration = ({ getObject, getObjectOrNull }) => (_, { id }) => {
  const user = getObjectOrNull(id);
  if (!user) {
    return {
      rule: 'user.groupGrantsOnCreation.accepted',
      status: ValidationStatus.ACCEPTED,
      generateSystemEvent: ({ withAssociation }) => {
        const grantedGroups = getObject(Group)
          .navigateBack(Class_Instances)
          .filter((group) => group[Group_GrantedOnCreation]);
        grantedGroups.forEach((group) => withAssociation(Association)
          .withRole(Association_Role_Definition, GroupMembershipAssociationDefinition)
          .withRole(Association_Role_Role1TypeInstance, group.id)
          .withRole(Association_Role_Role2TypeInstance, id[0])
          .updateObject({}));
      },
    };
  }
  return undefined;
};

const setDefaultPropertiesOnCreation: BusinessRuleRegistration = (objectStore) => (origin, { id, properties }) => {
  const { getObjectOrNull } = objectStore;
  // Deletion ? Don't care
  if (!properties) {
    return undefined;
  }
  // Existing object ? Don't care
  const currentObject = getObjectOrNull(id);
  if (currentObject) {
    return undefined;
  }

  const defaultProperties: Record<string, unknown> = {};

  if (properties[User_Type] == null) {
    defaultProperties[User_Type] = 'internal';
  }

  if (properties[User_IsEnabled] == null) {
    defaultProperties[User_IsEnabled] = origin.userId
      ? hasOnePlatformCapabilitiesOf(objectStore, origin.userId, [PlatformCapabilityAdmin, PlatformCapabilityInviteUser])
      : false;
  }

  if (Object.keys(defaultProperties).length > 0) {
    return {
      rule: 'user.setUserTypeOnCreation.accepted',
      status: ValidationStatus.ACCEPTED,
      generateSystemEvent: ({ updateObject }) => {
        updateObject(id, defaultProperties);
      },
    };
  } else {
    return undefined;
  }
};

const preventFieldUpdateFromNotAdmin = (propertyId: string, propertyName: string): BusinessRuleRegistration => (store) => ({ userId, source }, { id, properties }) => {
  const { getObjectOrNull } = store;
  const user = getObjectOrNull(id);
  if (user) {
    if (source
      && [OriginSources.USER_SERVICE, OriginSources.AUTHENTICATION, OriginSources.MIGRATION, OriginSources.API, OriginSources.SYSTEM, OriginSources.YOOI_TOOLS].includes(source)) {
      return {
        rule: `user.${propertyName}.update.source.accepted`,
        status: ValidationStatus.ACCEPTED,
      };
    }
    if (
      properties?.[propertyId] !== undefined // property updated
      && user[propertyId] !== properties?.[propertyId] // property value change
      && (!userId || !hasPlatformCapability(store, userId, PlatformCapabilityAdmin))
    ) {
      return {
        rule: `user.${propertyName}.update.rejected.notAdmin`,
        status: ValidationStatus.REJECTED,
      };
    }
  }
  return undefined;
};

const preventSelfDeactivation: BusinessRuleRegistration = () => ({ userId, source }, { id, properties }) => {
  if (source === OriginSources.CLIENT && id[0] === userId && (properties === null || properties?.[User_IsEnabled] === false || properties?.[User_IsEnabled] === null)) {
    return {
      rule: 'user.rejected.preventSelfDeactivation',
      status: ValidationStatus.REJECTED,
    };
  } else {
    return undefined;
  }
};

type({
  label: 'User',
  extends: Concept,
  instanceOf: ConceptDefinition,
  objectDebugLabel: ({ getObjectOrNull }) => (objectId) => getObjectOrNull(objectId)?.[User_Email] as string,
  accessControlList: {
    CREATE: (store) => (origin, objectId, { newProperties }, readAcl) => {
      if (origin.source === OriginSources.API && origin.userId && store.getObjectOrNull(origin.userId)?.[Instance_Of] === Integration) {
        // Integration can create users
        return hasConceptCapability(store, origin, objectId[0], newProperties, ConceptCapabilityCreate, 'CREATE', readAcl);
      } else {
        return adminOnlyAcl(store, origin.userId, 'user', 'CREATE');
      }
    },
    READ: () => () => ({ rule: 'user.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => (origin, objectId, { newProperties }, readAcl) => {
      const user = store.getObjectOrNull(objectId);
      if (user !== null && user[User_Email] !== undefined && newProperties && newProperties[User_Email] !== undefined) {
        // Updating the email ?
        if (origin.userId === undefined || !hasPlatformCapability(store, origin.userId, PlatformCapabilityUpdateEmail)) {
          return { rule: 'user.write.email.missingUpdateEmailCapability', status: ValidationStatus.REJECTED };
        }
      }
      if (origin.source === OriginSources.API && origin.userId && store.getObjectOrNull(origin.userId)?.[Instance_Of] === Integration) {
        // Integration can create users
        return hasConceptCapability(store, origin, objectId[0], newProperties, ConceptCapabilityEdit, 'WRITE', readAcl);
      } else if (origin.userId && hasOnePlatformCapabilitiesOf(store, origin.userId, [PlatformCapabilityAdmin, PlatformCapabilityInviteUser])) {
        return { rule: 'user.write.PlatformCapabilityInviteUser.allow', status: ValidationStatus.ACCEPTED };
      } else if (origin.userId === objectId[0]) {
        return { rule: 'user.write.self.allow', status: ValidationStatus.ACCEPTED };
      } else {
        return adminOnlyAcl(store, origin.userId, 'user', 'WRITE');
      }
    },
    DELETE: (store, isFeatureEnabled) => (origin, objectId) => {
      if (origin.source === OriginSources.YOOI_TOOLS) {
        return { rule: 'user.delete.allowed', status: ValidationStatus.ACCEPTED };
      } else if (origin.userId === objectId[0]) {
        return { rule: 'user.delete.self.forbidden', status: ValidationStatus.REJECTED };
      } else if (isFeatureEnabled('admin', 'deleteUsers')) {
        return adminOnlyAcl(store, origin.userId, 'user', 'DELETE');
      } else {
        return { rule: 'user.delete.forbidden', status: ValidationStatus.REJECTED };
      }
    },
  },
  extraProperties: {
    [ConceptDefinition_IsCore]: true,
  },
  businessRules: [
    updateGroupGranted,
    setDefaultPropertiesOnCreation,
    preventFieldUpdateFromNotAdmin(Concept_Name, 'name'),
    preventFieldUpdateFromNotAdmin(User_IsEnabled, 'isEnabled'),
    preventSelfDeactivation,
  ],
})
  .property({
    label: 'User_Type',
    as: CommonAsType.string, // sso or internal
    businessRules: [preventFieldUpdateFromNotAdmin(User_Type, 'type')],
  })
  .propertyFunction({
    label: 'User_GroupIds',
    computeFunction: ({ withAssociation }) => ([id]) => (
      withAssociation(Association)
        .withRole(Association_Role_Definition, GroupMembershipAssociationDefinition)
        .withRole(Association_Role_Role2TypeInstance, id)
        .list()
        .map((association) => association.role(Association_Role_Role1TypeInstance))
    ),
  });

instance({
  typeId: ExternalKeyField,
  label: 'User_Email',
  extraProperties: {
    [Field_IsCore]: true,
    [ExternalKeyField_PreventKeyStealing]: true,
    [ExternalKeyField_IsRequired]: true,
    [ExternalKeyField_RegexValidation]: emailRegex.source,
    [Field_Title]: 'Email',
    [Field_ApiAlias]: 'email',
  },
});
instance({ typeId: FieldDimension, label: 'UserEmailDimension', extraProperties: { [FieldDimension_Field]: User_Email } });
associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, User).withRole(FieldDimensionTypes_Role_FieldDimension, UserEmailDimension),
});

instance({
  typeId: BooleanField,
  label: 'User_IsEnabled',
  extraProperties: {
    [Field_IsCore]: true,
    [Field_Title]: 'Is enabled',
    [Field_ApiAlias]: 'is_enabled',
    [BooleanField_TrueValue]: { icon: 'toggle_on', color: '#48B164', tooltip: 'Enabled' },
    [BooleanField_FalseValue]: { icon: 'toggle_off', color: '#FF5B4C', tooltip: 'Disabled' },
  },
});
instance({ typeId: FieldDimension, label: 'UserIsEnabledDimension', extraProperties: { [FieldDimension_Field]: User_IsEnabled } });
associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, User).withRole(FieldDimensionTypes_Role_FieldDimension, UserIsEnabledDimension),
});

instance({
  typeId: ExternalKeyField,
  label: 'User_AuthenticationId',
  extraProperties: {
    [Field_Title]: 'Authentication id',
    [Field_IsCore]: true,
    [Field_ApiAlias]: 'authenticationId',
    [ExternalKeyField_IsImmutable]: true,
    [ExternalKeyField_PreventKeyStealing]: true,
  },
});
instance({ typeId: FieldDimension, label: 'UserAuthenticationIdDimension', extraProperties: { [FieldDimension_Field]: User_AuthenticationId } });
associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, User).withRole(FieldDimensionTypes_Role_FieldDimension, UserAuthenticationIdDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, User).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptNameDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, User).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCreatedAtDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, User).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptUpdatedAtDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, User).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCollaborationFieldDimension),
});

type({
  label: 'Group',
  extends: Concept,
  instanceOf: ConceptDefinition,
  objectDebugLabel: ({ getObjectOrNull }) => (objectId) => richTextToText(getObjectOrNull(objectId)?.[Concept_Name] as RichText),
  extraProperties: {
    [ConceptDefinition_IsCore]: true,
  },
})
  .property({
    label: 'Group_GrantedOnCreation',
    as: CommonAsType.boolean,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  });

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Group).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptNameDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Group).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCreatedAtDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Group).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptUpdatedAtDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Group).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptStakeholdersRolesDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Group).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptStakeholdersUsersDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Group).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptStakeholdersGroupsDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Group).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCollaborationFieldDimension),
});

instance({
  typeId: AssociationFieldDefinition,
  label: 'GroupMembershipAssociationDefinition',
  extraProperties: {
    [AssociationFieldDefinition_Role1Type]: Group,
    [AssociationFieldDefinition_Role2Type]: User,
  },
});
instance({
  typeId: AssociationField,
  label: 'Group_Users',
  extraProperties: {
    [Field_Title]: 'Users',
    [Field_IsCore]: true,
    [Field_ApiAlias]: 'users',
    [AssociationField_Definition]: GroupMembershipAssociationDefinition,
    [AssociationField_SourceRole]: 1,
  },
});
instance({ typeId: FieldDimension, label: 'GroupMembershipDimension', extraProperties: { [FieldDimension_Field]: Group_Users } });
associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, Group).withRole(FieldDimensionTypes_Role_FieldDimension, GroupMembershipDimension),
});

instance({
  typeId: AssociationField,
  label: 'User_Groups',
  extraProperties: {
    [Field_Title]: 'Groups',
    [Field_IsCore]: true,
    [Field_ApiAlias]: 'groups',
    [AssociationField_Definition]: GroupMembershipAssociationDefinition,
    [AssociationField_SourceRole]: 2,
  },
});
instance({ typeId: FieldDimension, label: 'UserGroupMembershipDimension', extraProperties: { [FieldDimension_Field]: User_Groups } });
associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, User).withRole(FieldDimensionTypes_Role_FieldDimension, UserGroupMembershipDimension),
});

propertyFunction({
  label: 'ConceptDefinition_ConceptCapability_User_HasCapability',
  computeFunction: ({ getObject, withAssociation }) => ([conceptDefinitionId, conceptCapabilityId, userId]) => (
    (getObject(userId)[User_GroupIds] as string[])
      .some((groupId) => (
        withAssociation(ConceptGroupCapability)
          .withRole(ConceptGroupCapability_Role_ConceptDefinition, conceptDefinitionId)
          .withRole(ConceptGroupCapability_Role_ConceptCapability, conceptCapabilityId)
          .withRole(ConceptGroupCapability_Role_ConceptGroup, groupId)
          .getOrNull()
        !== null
      ))
  ),
});
