import type { FunctionComponent } from 'react';
import type { ScheduledTrigger } from 'yooi-modules/modules/automationModule';
import { RepeatType } from 'yooi-modules/modules/automationModule';
import { joinObjects } from 'yooi-utils';
import Typo from '../../../components/atoms/Typo';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../components/molecules/SpacingLine';
import i18n from '../../../utils/i18n';
import useTheme from '../../../utils/useTheme';
import type { Option } from '../../_global/modelTypeUtils';
import timezoneList from './timezoneList';

interface RepeatRuleTimesInputProps<T extends ScheduledTrigger> {
  scheduledTrigger: T,
  readOnly?: boolean,
  onChange?: (scheduledTrigger: T) => void,
}

export const hourToOption = (h: number): Option => {
  if (h === 0) {
    return { label: '12am', id: h.toString() };
  } else if (h < 12) {
    return { label: `${h}am`, id: h.toString() };
  } else if (h === 12) {
    return { label: '12pm', id: h.toString() };
  } else {
    return { label: `${h - 12}pm`, id: h.toString() };
  }
};

const updateRepeatRule = <T extends ScheduledTrigger>(scheduledTrigger: T, times: number): ScheduledTrigger => {
  const newRepeatRule = { ...scheduledTrigger };
  newRepeatRule.repeatRule = scheduledTrigger.repeatRule;
  newRepeatRule.repeatRule.times = times;
  return newRepeatRule;
};

const RepeatRuleTimesInput: FunctionComponent<RepeatRuleTimesInputProps<ScheduledTrigger>> = ({
  scheduledTrigger,
  readOnly,
  onChange = () => {},
}) => {
  const theme = useTheme();

  const repeatTypeSentence: Record<RepeatType, string> = {
    [RepeatType.day]: i18n`day(s) at`,
    [RepeatType.week]: i18n`week(s) at`,
    [RepeatType.month]: i18n`month(s) at`,
    [RepeatType.year]: i18n`year(s) at`,
  };

  const days: Option[] = Array.from({ length: 99 }, (_, i): Option => ({ label: (i + 1).toString(), id: (i + 1).toString() }));
  const hours: number[] = Array.from({ length: 24 }, (_, i) => (i));

  return (
    <SpacingLine>
      <Typo color={theme.color.text.secondary}>{i18n`Every`}</Typo>
      <SearchAndSelect
        selectedOption={{ label: scheduledTrigger.repeatRule.times.toString(), id: scheduledTrigger.repeatRule.times.toString() }}
        computeOptions={() => days}
        onSelect={(v) => {
          if (v !== null) {
            const value = Number(v.id);
            onChange(updateRepeatRule(scheduledTrigger, value));
          }
        }}
        readOnly={readOnly}
        minWidth={6}
      />
      <Typo color={theme.color.text.secondary}>{repeatTypeSentence[scheduledTrigger.repeatRule.type]}</Typo>
      <SearchAndSelect
        selectedOption={scheduledTrigger.at.hour !== undefined ? hourToOption(scheduledTrigger.at.hour) : undefined}
        computeOptions={() => hours.map(hourToOption)}
        onSelect={(v) => {
          if (v !== null) {
            const value = Number(v.id);
            onChange(joinObjects(scheduledTrigger, { at: joinObjects(scheduledTrigger.at, { hour: value }) }));
          }
        }}
        readOnly={readOnly}
        minWidth={6}
      />
      <SearchAndSelect
        computeOptions={() => timezoneList.map((label) => ({ id: label, label }))}
        placeholder={i18n`Select a timezone`}
        selectedOption={{ id: scheduledTrigger.at.tz, label: scheduledTrigger.at.tz }}
        onSelect={(option) => {
          if (option) {
            onChange(joinObjects(scheduledTrigger, { at: joinObjects(scheduledTrigger.at, { tz: option.id }) }));
          }
        }}
        readOnly={readOnly}
      />
    </SpacingLine>
  );
};

export default RepeatRuleTimesInput;
