// This file is automatically updated by the generate-constants script. Do not update it manually.

export const DashboardModuleId = '194e017a-8ce1-5ee1-b666-3afc49ca0f1b';
export const CardField = '5f48c88f-43e3-493f-bf0d-c94b77fe46eb';
export const CardField_Button = '3caca72e-60b5-4376-82cb-09952f73c367';
export const CardField_ButtonPositionX = '854d6bdd-7542-45aa-b742-1d69fb8c3c8a';
export const CardField_ButtonPositionY = 'd0974292-c5cb-4f43-980b-53a74f5fee98';
export const CardField_Image = '80af9ec4-6bbd-46ba-a87c-7ab3b768b381';
export const CardField_ImageHeight = 'cf956833-3bb2-4924-8115-514687a65046';
export const CardField_ImageMode = '74271ce2-b5d1-4979-9e85-cf4376d70fa5';
export const CardField_ImagePositionX = 'f203ca92-59c3-45fd-8e29-f3db4d29cc72';
export const CardField_ImagePositionY = '10fb287e-64e9-4221-8706-a8895d8c6cba';
export const CardField_Subtitle = 'bd1bec14-1d1f-44dc-9cee-9b7fa08458b8';
export const CardField_Text = 'fa4a33ca-7d03-4257-ac3b-7aea35822dcc';
export const Dashboard = '23827473-ec4d-5471-8d97-7123b08fa947';
export const Dashboard_Icon = 'd14493b4-e5af-4558-8865-fb24234a7154';
export const DashboardIconDimension = 'c9c5949e-4980-4f55-8e5a-152ad059cf38';
export const DashboardParameter = 'eb330684-84ab-40a0-b437-6ba52d0fcbf5';
export const DashboardParameter_Dashboard = 'ab3e1efb-a97e-4a51-8cee-c447953c9162';
export const DashboardParameter_DefaultValue = 'c8bf0dd8-9160-4740-9368-c8d6b7106048';
export const DashboardParameter_EnableNotSetOption = 'c03b9a20-e390-4065-adc8-f3070bf5f1d1';
export const DashboardParameter_Filters = 'ccbd1817-ebd0-4387-aa8d-7e0448700ab6';
export const DashboardParameter_Label = 'f23bdb27-dfb1-48ad-ae0e-51f67c71a2e8';
export const DashboardParameter_Rank = '40122316-d931-4551-9ad5-8bc04e002e35';
export const DashboardParameter_Type = '9b4f0332-c28a-4f93-a4b6-4503ed7289a2';
export const DashboardParameter_UnsetLabel = '3c3d36be-c622-44a7-8ddd-e1ea9883956a';
export const DashboardParameterOption = '407e7db3-d919-4501-bbaf-a2c968433d76';
export const GraphChartField = 'b48841fc-b340-4611-b10b-9e6459c62c79';
export const GraphChartField_Configuration = '3bf2c41f-8785-40d7-bfda-5f89c8045a00';
export const MirrorField = '1cd5bd4c-4ef9-4f0e-ab9b-eca396a78466';
export const MirrorField_Path = '6ffd53dd-abaf-4313-bed7-c7aa827f518b';
export const MirrorField_TargetedFieldDefinition = '6ded7df0-49ab-45a9-8e2b-8cfd915a14ff';
export const RadarChartField = '120e9a4e-48d1-40ae-8feb-36bbeac44e82';
export const RadarChartField_Configuration = '693218d6-86ed-49d7-879c-6befbe871f12';
export const RadarChartField_ModelType = 'c66ddb6a-5c2a-4104-a722-72a6ae678fe3';
export const SubscriptionsField = '0151d1b1-818b-4d1d-8e4f-69d22d33307a';
export const UnsetDashboardParameterOption = 'b53e1632-2874-4492-bd82-26bec4deaf31';
export const ViewsField = 'c7829914-8637-423a-aba9-50735d84c794';
export const ViewsField_Definition = '78db1713-f0f8-4787-ac26-39d30f92d496';
export const Widget = '1b831978-0715-567f-b286-9c5500bf3e30';
export const Widget_Dashboard = 'd711fdbb-c4ef-4006-9e90-905e172d7f50';
export const Widget_DisplayMode = '9470a808-d972-4512-b138-4639aaee7104';
export const Widget_Field = 'a46091ae-26db-4066-8058-d3a04836e829';
export const Widget_HeaderBackgroundColor = 'b17dcde8-ebd8-4caf-bd46-77dac08ebe14';
export const Widget_Height = '974b302c-3904-446b-97da-44daa3ad34ec';
export const Widget_Title = 'a0e3c2eb-de20-5d8b-a364-7ff0ccdaa64b';
export const Widget_TitleMode = 'f1a3a868-20cd-4868-8d3f-0f5f20dab270';
export const Widget_Width = 'c89be954-839a-4630-912b-b666e488d59e';
export const Widget_X = 'e410cdb2-1887-4a86-8e72-822d0e6a64d7';
export const Widget_Y = '57a92ba8-ffc3-46a1-9753-ee06743d9bb9';
export const ConceptDefinition_DashboardParameterTargets = DashboardParameter_Type;
export const ConceptDefinition_RadarChartFields = RadarChartField_ModelType;
export const Dashboard_Parameters = DashboardParameter_Dashboard;
export const Dashboard_Widgets = Widget_Dashboard;
export const Field_Widget = Widget_Field;
export const FieldDefinition_MirroredByField = MirrorField_TargetedFieldDefinition;
