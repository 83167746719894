import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { Property } from 'csstype';
import type { FunctionComponent, ReactNode, RefCallback, RefObject } from 'react';
import { useLayoutEffect, useMemo, useRef } from 'react';
import { Overlay as ReactOverlay } from 'react-overlays';
import type { Modifier, Offset, Options, Placement, UsePopperOptions } from 'react-overlays/usePopper';
import { joinObjects } from 'yooi-utils';
import makeStyles from '../../utils/makeStyles';
import useBackdropClick from '../../utils/useBackdropClick';
import useOverlayContainerRef from '../../utils/useOverlayContainerRef';
import { computeStylesNoGpu, overlayMeasurements } from './internal/overlayUtils';

const overlayPreventOverflow: Modifier<string, Record<string, number>> = {
  name: 'preventOverflow',
  enabled: true,
  phase: 'main',
  fn: ({ state, options }) => {
    if (!state.modifiersData.popperOffsets) {
      return;
    }

    const { padding = 4 } = options;

    const { width: popperWidth } = state.rects.popper;
    const { clientWidth: scrollWidth } = state.scrollParents.popper[0] as Element;

    // Content larger than the scroll container
    if (popperWidth > scrollWidth) {
      Object.assign(state.modifiersData.popperOffsets, { x: padding });
      return;
    }

    const { width: referenceWidth } = state.rects.reference;
    const { x: popperX } = state.modifiersData.popperOffsets ?? { x: 0 };

    // If overlay is outside the scroll container
    if (popperX + popperWidth > scrollWidth - padding) {
      // Try to align the content with the right of the target
      const newXMatchingRight = popperX + referenceWidth - popperWidth;
      if (newXMatchingRight >= padding) {
        Object.assign(state.modifiersData.popperOffsets, { x: newXMatchingRight });
        return;
      }

      // Try to align the content with the right of the scroll container
      const newXScrollContainerRightAlign = scrollWidth - popperWidth - padding;
      if (newXScrollContainerRightAlign >= padding && newXScrollContainerRightAlign <= popperX) {
        Object.assign(state.modifiersData.popperOffsets, { x: newXScrollContainerRightAlign });
      }
    }
  },
};

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
  },
  noPointerEvents: {
    pointerEvents: 'none',
  },
}, 'overlay');

export type OverlayPlacement = Placement | 'overlay-down'; // standard Popper.js placements | overlay the target and down the target

interface OverlayProps {
  target: RefObject<HTMLElement> | HTMLElement,
  placement?: OverlayPlacement,
  sameWidth?: boolean,
  flip?: boolean,
  offset?: Offset,
  onBackdropClick: (event: MouseEvent) => void,
  onEscapeKeyDown?: () => void,
  onEnterKeyDown?: () => void,
  onOverlayVisible?: () => void,
  children: ReactNode,
  containerMarginBottom?: Property.MarginBottom,
  isBoundary?: boolean,
  zIndex?: number,
  disablePointerEvents?: boolean,
}

const Overlay: FunctionComponent<OverlayProps> = ({
  target,
  placement = 'overlay-down',
  sameWidth = false,
  flip = false,
  offset,
  onBackdropClick,
  onEscapeKeyDown,
  onEnterKeyDown,
  onOverlayVisible,
  children,
  containerMarginBottom,
  isBoundary = true,
  zIndex = 1,
  disablePointerEvents = false,
}) => {
  const classes = useStyles();
  const overlayContainerRef = useOverlayContainerRef();
  const popperUpdateRef = useRef<() => void>();
  const overlayVisibleRef = useRef(false);
  const backdropClickRef = useRef<HTMLDivElement>(null);

  useBackdropClick(backdropClickRef, onBackdropClick, isBoundary);

  // permit to track the target move (eg. for multiplayer: table row deletion, matrix point move, ...)
  // it may not work if the overlay rendering is not dependent of the target rendering (in current state of the app it doesn't seem to be possible)
  const targetPosition = useRef<{ x?: number, y?: number }>({});
  useLayoutEffect(() => {
    const targetElement = (target as RefObject<HTMLElement>).current ?? (target as HTMLElement);
    if (targetElement && targetElement.getBoundingClientRect) {
      const { x, y } = targetElement.getBoundingClientRect();
      const { x: previousX, y: previousY } = targetPosition.current;
      if (previousX && previousY && (previousX !== x || previousY !== y)) {
        popperUpdateRef.current?.();
      }
      targetPosition.current = { x, y };
    }
  });

  const overlayVisible = overlayVisibleRef.current;
  useLayoutEffect(() => {
    if (overlayVisible) {
      onOverlayVisible?.();
    }
  }, [onOverlayVisible, overlayVisible]);

  const popperConfig: UsePopperOptions = useMemo(() => {
    const modifiers: Options['modifiers'] = [computeStylesNoGpu];
    if (placement === 'overlay-down') {
      modifiers.push(overlayMeasurements);
      modifiers.push(overlayPreventOverflow);
    }
    return { modifiers };
  }, [placement]);

  return (
    <ReactOverlay
      show
      popperConfig={popperConfig}
      target={target}
      container={overlayContainerRef}
      placement={placement === 'overlay-down' ? 'bottom-start' : placement}
      flip={flip}
      offset={offset}
    >
      {({ state, update, props: { style, ref, ...props } }) => {
        popperUpdateRef.current = update;
        overlayVisibleRef.current = Boolean(state);

        const styleWithDimensions = joinObjects(style, { display: 'flex', zIndex });
        if (placement === 'overlay-down') {
          const targetElement = (target as RefObject<HTMLElement>).current ?? (target as HTMLElement);
          if (targetElement.getBoundingClientRect) {
            const { width, height } = targetElement.getBoundingClientRect();
            Object.assign(styleWithDimensions, sameWidth ? { width } : { minWidth: width });
            Object.assign(styleWithDimensions, { minHeight: height });
          }
        }
        return (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            {...props}
            className={classnames({ [classes.noPointerEvents]: disablePointerEvents })}
            ref={composeReactRefs(ref as RefCallback<HTMLDivElement>, backdropClickRef)}
            style={styleWithDimensions}
            onKeyDown={(e) => {
              if (onEscapeKeyDown && e.key === 'Escape') {
                onEscapeKeyDown();
                e.stopPropagation(); // close only one Overlay at a time
              } else if (onEnterKeyDown && e.key === 'Enter' && !e.shiftKey) {
                onEnterKeyDown();
                e.stopPropagation();
              }
            }}
          >
            <div className={classes.container} style={{ marginBottom: containerMarginBottom }}>
              {children}
            </div>
          </div>
        );
      }}
    </ReactOverlay>
  );
};

export default Overlay;
