export enum Opacity {
  five = 'five',
  ten = 'ten',
  fifteen = 'fifteen',
  twenty = 'twenty',
  twentyFive = 'twentyFive',
  fifty = 'fifty',
  eightyFive = 'eightyFive',
}

export enum Color {
  Blue = 'Blue',
  Purple = 'Purple',
  Yellow = 'Yellow',
  LightGreen = 'LightGreen',
  LightGrey = 'LightGrey',
  Pink = 'Pink',
  Red = 'Red',
  Green = 'Green',
  Grey = 'Grey',
}

export default {
  // Global tokens
  color: {
    brand: {
      100: '#E6F1ED',
      200: '#CCE3DA',
      300: '#99C7B6',
      400: '#66AB91',
      500: '#338F6D',
      600: '#19815A',
      700: '#007348',
      800: '#005C3A',
      900: '#00452B',
    },
    gray: {
      0: '#00000000',
      100: '#FFFFFF',
      200: '#F6F6F6',
      300: '#EDEDED',
      400: '#D5D5D5',
      500: '#B1B1B1',
      600: '#919191',
      700: '#6B6B6B',
      800: '#333333',
      900: '#101010',
    },
    cyan: {
      100: '#F5FAFA',
      200: '#E1F0F0',
      300: '#CCE5E5',
      400: '#C0D7D7',
      500: '#B4CACA',
      600: '#A7BCBC',
      700: '#9BAEAE',
      800: '#839393',
      900: '#6A7777',
    },
    peach: {
      100: '#FFF9EE',
      200: '#FFF1D4',
      300: '#FFE6B5',
      400: '#F0D8AA',
      500: '#E0CA9F',
      600: '#D1BD94',
      700: '#C2AF8A',
      800: '#A39374',
      900: '#85785E',
    },
    teagreen: {
      100: '#F0FFE8',
      200: '#E4FFD7',
      300: '#D1FFBA',
      400: '#C4F0AF',
      500: '#B8E0A4',
      600: '#ABD199',
      700: '#9FC28D',
      800: '#86A377',
      900: '#6D8561',
    },
    purple: {
      100: '#F6EFFF',
      200: '#E8D8FC',
      300: '#D7BBF9',
      400: '#CAB0EA',
      500: '#BDA5DB',
      600: '#B099CC',
      700: '#A38EBD',
      800: '#8A789F',
      900: '#706181',
    },
    red: {
      100: '#FFEDEB',
      200: '#FFDBD7',
      300: '#FFB6B0',
      400: '#FF9288',
      500: '#FF6D61',
      600: '#FF5B4D',
      700: '#FF4939',
      800: '#DA3C2F',
      900: '#B22C1F',
    },
    green: {
      100: '#EBF6EE',
      200: '#D6EEDD',
      300: '#AEDCBA',
      400: '#85CB98',
      500: '#5DB975',
      600: '#48B164',
      700: '#34A853',
      800: '#2A8642',
      900: '#1F6532',
    },
    blue: {
      100: '#EBF4FE',
      200: '#D7E9FD',
      300: '#AFD3FA',
      400: '#88BEF8',
      500: '#60A8F5',
      600: '#4C9DF4',
      700: '#3892F3',
      800: '#2D75C2',
      900: '#225892',
    },
    yellow: {
      100: '#FFF8EB',
      200: '#FFF2D7',
      300: '#FFE4B0',
      400: '#FFD788',
      500: '#FFC961',
      600: '#FFC34D',
      700: '#FFBC39',
      800: '#CC962E',
      900: '#997122',
    },
  },
  colorPalette: {
    default: [
      { code: Color.Blue, value: '#3892F3' },
      { code: Color.Purple, value: '#8C43D4' },
      { code: Color.Yellow, value: '#FFCB2E' },
      { code: Color.LightGreen, value: '#AAD402' },
      { code: Color.LightGrey, value: '#EDEDED' },
      { code: Color.Pink, value: '#F188E7' },
      { code: Color.Red, value: '#FF5B4C' },
      { code: Color.Green, value: '#48B164' },
      { code: Color.Grey, value: '#B1B1B1' },
    ],
    text: [
      { code: Color.Grey, value: '#B1B1B1' },
      { code: Color.Blue, value: '#60A8F5' },
      { code: Color.LightGreen, value: '#5DB975' },
      { code: Color.Red, value: '#FF6D61' },
      { code: Color.Yellow, value: '#FFC961' },
      { code: Color.Green, value: '#338F6D' },
      { code: Color.Purple, value: '#BDA5DB' },
    ],
  },
  shadowElevation: {
    low: '0 0 0.2rem rgba(0, 0, 0, 0.08), 0 0.1rem 0.3rem rgba(0, 0, 0, 0.08)',
    medium: '0 0 0.2rem rgba(0, 0, 0, 0.08), 0 0.3rem 0.4rem rgba(0, 0, 0, 0.03), 0 0.3rem 1.6rem rgba(0, 0, 0, 0.06)',
    high: '0 1.8rem 3.2rem rgba(0, 0, 0, 0.06), 0 1.2rem 1.6rem rgba(0, 0, 0, 0.08), 0 0.6rem 0.8rem rgba(0, 0, 0, 0.06), 0 0.3rem 0.4rem rgba(0, 0, 0, 0.06), 0 0 0.2rem rgba(0, 0, 0, 0.16)',
  },
  borderRadius: {
    small: '0.2rem',
    medium: '0.4rem',
    large: '0.8rem',
    extraLarge: '1rem',
    circle: '50%',
  },
  opacity: {
    [Opacity.five]: 0.05,
    [Opacity.ten]: 0.1,
    [Opacity.fifteen]: 0.15,
    [Opacity.twenty]: 0.2,
    [Opacity.twentyFive]: 0.25,
    [Opacity.fifty]: 0.5,
    [Opacity.eightyFive]: 0.85,
  },

  // Component-specific tokens
  button: {
    boxShadow: '0rem 0.1rem 0rem 0rem rgba(0, 0, 0, 0.05)',
    shadow: {
      pressed: '0rem 0.24rem 0.03rem 0rem rgba(16, 16, 16, 0.08) inset',
    },
  },
  icon: {
    size: {
      small: '1.4rem',
      main: '1.6rem',
      large: '2rem',
    },
  },
} as const;
