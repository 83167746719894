import type { FunctionComponent, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { Intention, Intention_Available } from 'yooi-modules/modules/collaborationModule/ids';
import type { PlatformConfigurationStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import { CurrentPlatformConfiguration, PlatformConfiguration_CollaborationMessage } from 'yooi-modules/modules/platformConfigurationModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { joinObjects } from 'yooi-utils';
import BaseLayout from '../../../components/templates/BaseLayout';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle from '../../../components/templates/BlockTitle';
import Header from '../../../components/templates/Header';
import HeaderTabs from '../../../components/templates/HeaderTabs';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import useNavigation, { NavigationElementContainer } from '../../../utils/useNavigation';
import HeaderStatic from '../../_global/HeaderStatic';
import StoreTextInputField from '../../_global/input/StoreTextInputField';
import type { NavigationFilter } from '../../_global/navigationUtils';
import TopBar from '../../_global/topBar/TopBar';
import IntentionList from './IntentionList';

const AdminCollaborationConfigurationPage: FunctionComponent = () => {
  const navigation = useNavigation<NavigationFilter>();
  const location = useLocation();
  const store = useStore();
  const platformConfiguration = store.getObject<PlatformConfigurationStoreObject>(CurrentPlatformConfiguration);

  // Keep in component for i18n
  const tabs: { key: string, hash: string, name: string, render: () => ReactElement | null }[] = [
    {
      key: 'intentions',
      hash: '#intentions',
      name: i18n`Intentions`,
      render: () => (
        <IntentionList
          intentionIds={
            store.getObject(Intention)
              .navigateBack(Class_Instances)
              .map(({ id }) => id)
          }
          onCreate={() => store.createObject({ [Instance_Of]: Intention, [Intention_Available]: 'all' })}
        />
      ),
    },
    {
      key: 'options',
      hash: '#options',
      name: i18n`Options`,
      render: () => (
        <VerticalBlock>
          <HorizontalBlock asBlockContent>
            <BlockTitle
              title={i18n`Disclaimer message`}
              infoTooltip={i18n`This message will be displayed as a tooltip where users can create new collaborations or answer to existing ones.`}
            />
            <BlockContent>
              <StoreTextInputField
                initialValue={platformConfiguration[PlatformConfiguration_CollaborationMessage]}
                onSubmit={(newValue) => {
                  store.updateObject(CurrentPlatformConfiguration, { [PlatformConfiguration_CollaborationMessage]: newValue });
                }}
                placeholder={i18n`Add text`}
                maxLine={5}
                withDropdown
              />
            </BlockContent>
          </HorizontalBlock>
        </VerticalBlock>
      ),
    },
  ];

  const tabIndex = tabs.findIndex((tab) => tab.hash === location.hash);
  const selectedTabIndex = tabIndex >= 0 ? tabIndex : 0;

  return (
    <NavigationElementContainer
      element={{ key: 'collaboration' }}
    >
      <BaseLayout
        topBar={(<TopBar />)}
        header={(
          <Header
            firstLine={(<HeaderStatic text={i18n`Collaboration configuration`} />)}
            tabsLine={(
              <HeaderTabs
                selectedTabIndex={selectedTabIndex}
                tabs={tabs}
                onSelectedIndexChanged={(index) => {
                  navigation.replace('collaboration', joinObjects(location, { hash: tabs[index].hash }));
                }}
              />
            )}
          />
        )}
        content={tabs[selectedTabIndex].render()}
      />
    </NavigationElementContainer>
  );
};

export default AdminCollaborationConfigurationPage;
