import classnames from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';
import type { GraphChartSubStepDisplay } from 'yooi-modules/modules/dashboardModule/fields/graphChartField';
import { joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../../components/atoms/IconOnlyButton';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo, { TypoVariant } from '../../../../components/atoms/Typo';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import ToggleButton from '../../../../components/molecules/ToggleButton';
import base, { Opacity } from '../../../../theme/base';
import { darken, generateColorFromOpacity } from '../../../../theme/colorUtils';
import { getSpacing, Spacing, spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeSelectorsClasses from '../../../../utils/makeSelectorsClasses';
import makeStyles from '../../../../utils/makeStyles';
import { remToPx } from '../../../../utils/sizeUtils';
import { formatOrUndef } from '../../../../utils/stringUtils';
import { SizeContextProvider, SizeVariant } from '../../../../utils/useSizeContext';
import useTheme from '../../../../utils/useTheme';
import type { GroupByFieldMetadata } from '../../groupByUtils';
import ArcherElement from './archer/ArcherElement';
import type { Group, GroupCollapseHandler, IsHighlighted, OnHighlight } from './GraphChart';
import GraphChartItemList, { getRelations } from './GraphChartItemList';
import { HighlightedType } from './graphChartUtils';

export const NO_GROUP_KEY = 'NO_GROUP_KEY';

const selectorsClasses = makeSelectorsClasses('visibilityHandler');

const useStyles = makeStyles((theme) => ({
  hidden: {
    visibility: 'hidden',
  },
  opacity: {
    opacity: 0.2,
  },
  groupItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: base.borderRadius.medium,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
    '&:hover, &:focus, &:focus-within': {
      [`& .${selectorsClasses.visibilityHandler}`]: {
        visibility: 'visible',
      },
    },
  },
  groupContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: spacingRem.s,
    padding: spacingRem.text,
    borderRadius: base.borderRadius.medium,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.transparent,
  },
}), 'graphChartItemGroup');

interface GraphChartItemGroupProps {
  blockLabel: string,
  blockId: string,
  group: Group,
  groupByFieldMetadata: GroupByFieldMetadata,
  display: GraphChartSubStepDisplay,
  isHighlighted: IsHighlighted,
  groupCollapseHandler: GroupCollapseHandler,
  onHighlight: OnHighlight,
  removeHighlight: () => void,
  hasHighlightedItem: boolean,
  width: number,
}

const GraphChartItemGroup: FunctionComponent<GraphChartItemGroupProps> = ({
  blockLabel,
  blockId,
  group,
  groupByFieldMetadata,
  display,
  isHighlighted,
  hasHighlightedItem,
  groupCollapseHandler,
  onHighlight,
  removeHighlight,
  width,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const isCollapsed = groupCollapseHandler.isGroupCollapsed(blockId, group.arrowKey);
  let option: { label: string, tooltip?: string, color?: string };
  if (group.key === NO_GROUP_KEY) {
    option = {
      label: i18n`No [${formatOrUndef(groupByFieldMetadata.getLabel())}]`,
      color: theme.color.border.hover,
      tooltip: i18n`No [${formatOrUndef(groupByFieldMetadata.getLabel())}]`,
    };
  } else {
    option = {
      label: formatOrUndef(groupByFieldMetadata.getGroupLabel(group.key)),
      color: groupByFieldMetadata.getGroupColor(group.key),
      tooltip: formatOrUndef(groupByFieldMetadata.getGroupLabel(group.key)),
    };
  }

  const injectArcherElement = (children: ReactElement) => {
    if (isCollapsed) {
      const relationsMap = new Map();
      getRelations(group.arrowKey, group.items, hasHighlightedItem, isHighlighted, theme, groupCollapseHandler.isGroupCollapsed, onHighlight)
        .forEach((relation) => relationsMap.set(relation.targetId, relation));
      return (
        <ArcherElement ids={[group.arrowKey]} relations={[...relationsMap.values()]}>
          {children}
        </ArcherElement>
      );
    } else {
      return children;
    }
  };

  const isActiveHighlight = isHighlighted(group.arrowKey)?.type === HighlightedType.group;
  const highlighted = Boolean(isHighlighted(group.arrowKey)) || group.items.some(({ arrowKey }) => Boolean(isHighlighted(arrowKey)));
  const faded = hasHighlightedItem && !highlighted;

  return (
    <>
      <Tooltip title={formatOrUndef(option.tooltip)}>
        {injectArcherElement(
          <div
            className={classnames({ [classes.groupItemContainer]: true, [classes.opacity]: faded })}
            style={joinObjects(
              highlighted && isCollapsed ? { borderColor: darken(option.color ?? theme.color.border.hover, 10) } : undefined,
              { backgroundColor: option.color ?? theme.color.border.hover }
            )}
          >
            <Typo variant={TypoVariant.blockSecondaryTitle} maxLine={1} color={theme.color.text.white}>{formatOrUndef(option.label)}</Typo>
            <div className={classnames(classes.hidden, selectorsClasses.visibilityHandler)}>
              <div style={{ visibility: faded ? 'hidden' : undefined }}>
                <SizeContextProvider sizeVariant={SizeVariant.small}>
                  <SpacingLine customColumnGap={getSpacing(Spacing.xs)}>
                    <ToggleButton
                      tooltip={isActiveHighlight ? i18n`Hide path` : i18n`Show path`}
                      icon={isActiveHighlight ? IconName.location_disabled : IconName.my_location}
                      onClick={isActiveHighlight ? () => removeHighlight() : () => onHighlight({ type: HighlightedType.group, key: group.arrowKey })}
                      active={isActiveHighlight}
                    />
                    <IconOnlyButton
                      variant={IconOnlyButtonVariants.secondary}
                      iconName={isCollapsed ? IconName.unfold_more : IconName.unfold_less}
                      onClick={() => {
                        if (isCollapsed) {
                          groupCollapseHandler.onExpand(group.arrowKey);
                        } else {
                          groupCollapseHandler.onCollapse(group.arrowKey);
                        }
                      }}
                      tooltip={isCollapsed ? i18n`Expand` : i18n`Collapse`}
                      sizeVariant={SizeVariant.small}
                    />
                  </SpacingLine>
                </SizeContextProvider>
              </div>
            </div>
          </div>
        )}
      </Tooltip>
      {!isCollapsed && (
        <div
          className={classes.groupContainer}
          style={{ backgroundColor: generateColorFromOpacity(option.color ?? theme.color.border.hover, theme.color.background.neutral.default, Opacity.fifteen) }}
        >
          <GraphChartItemList
            width={width - remToPx(spacingRem.splusplus)}
            blockLabel={blockLabel}
            blockId={blockId}
            isHighlighted={isHighlighted}
            items={group.items}
            groupCollapseHandler={groupCollapseHandler}
            onHighlight={onHighlight}
            removeHighlight={removeHighlight}
            hasHighlightedItem={hasHighlightedItem}
            display={display}
            borderColor={option.color}
          />
        </div>
      )}
    </>
  );
};

export default GraphChartItemGroup;
