import classnames from 'classnames';
import type { CSSProperties, ReactElement } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { joinObjects } from 'yooi-utils';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import useSizeContext, { buildInputSizeVariantClasses } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import type { ItemHandler } from './ItemList';

const useStyles = makeStyles((theme) => (joinObjects(
  {
    option: {
      display: 'flex',
      paddingLeft: spacingRem.s,
      paddingRight: spacingRem.s,
      cursor: 'pointer',
      '&:focus': {
        backgroundColor: theme.color.background.primarylight.default,
      },
      alignItems: 'center',
    },
  },
  buildInputSizeVariantClasses('optionSize', ['minHeight', 'height'])
)), 'itemListNavigableChipItem');

interface ItemListNavigableChipItemProps {
  style: CSSProperties,
  onSelect?: () => void,
  onHover: () => void,
  renderContent: () => ReactElement | undefined,
  isNavigable: boolean,
  initialIsActive: boolean,
  isSelected?: boolean,
}

const ItemListNavigableChipItem = forwardRef<ItemHandler, ItemListNavigableChipItemProps>(({
  style,
  onSelect,
  onHover,
  renderContent,
  isNavigable,
  isSelected,
  initialIsActive,
}, ref) => {
  const theme = useTheme();
  const classes = useStyles();
  const { sizeVariant } = useSizeContext();

  const divRef = useRef<HTMLDivElement | null>(null);

  const setNodeStyle = (node: HTMLDivElement | null, isActive: boolean) => {
    if (node && isNavigable) {
      if (isSelected) {
        node.style.setProperty('background-color', theme.color.background.primarylight.default);
      } else if (isActive) {
        node.style.setProperty('background-color', theme.color.background.primarylight.default);
      } else {
        node.style.removeProperty('background-color');
      }
    }
  };

  useImperativeHandle(ref, () => ({
    triggerActive: (isActive) => setNodeStyle(divRef.current, isActive),
    scrollIntoView: () => divRef.current?.scrollIntoView({ block: 'nearest' }),
  }));

  return (
    <div
      ref={(node) => {
        setNodeStyle(node, initialIsActive);
        divRef.current = node;
      }}
      style={style}
      className={classnames({
        [classes.option]: true,
        [classes[`optionSize_${sizeVariant}`]]: Boolean(sizeVariant),
      })}
      onClick={onSelect}
      onMouseEnter={onHover}
      aria-hidden="true"
    >
      {renderContent()}
    </div>
  );
});

export default ItemListNavigableChipItem;
