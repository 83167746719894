import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import icons from '../../assets/images/icons.svg';
import base from '../../theme/base';
import makeStyles from '../../utils/makeStyles';
import useSizeContext, { getIconSize } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import useTooltipRef from '../../utils/useTooltipRef';
import type { Icon as IconName } from './icons';

export { Icon as IconName } from './icons';

export enum IconColorVariant {
  primary = 'primary',
  secondary = 'secondary',
  alternative = 'alternative',
  light = 'light',
  disabled = 'disabled',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export enum IconSizeVariant {
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl',
  xxl = 'xxl',
  xxxl = 'xxxl',
}

const useStyles = makeStyles({
  xs: {
    width: '0.8rem',
    minWidth: '0.8rem',
    height: '0.8rem',
    minHeight: '0.8rem',
  },
  s: {
    width: base.icon.size.small,
    minWidth: base.icon.size.small,
    height: base.icon.size.small,
    minHeight: base.icon.size.small,
  },
  m: {
    width: base.icon.size.main,
    minWidth: base.icon.size.main,
    height: base.icon.size.main,
    minHeight: base.icon.size.main,
  },
  l: {
    width: base.icon.size.large,
    minWidth: base.icon.size.large,
    height: base.icon.size.large,
    minHeight: base.icon.size.large,
  },
  xl: {
    width: '3.2rem',
    minWidth: '3.2rem',
    height: '3.2rem',
    minHeight: '3.2rem',
  },
  xxl: {
    width: '4.8rem',
    minWidth: '4.8rem',
    height: '4.8rem',
    minHeight: '4.8rem',
  },
  xxxl: {
    width: '6.4rem',
    minWidth: '6.4rem',
    height: '6.4rem',
    minHeight: '6.4rem',
  },
}, 'icon');

interface IconProps {
  name: IconName,
  tooltip?: string,
  color?: string,
  colorVariant?: IconColorVariant,
  size?: IconSizeVariant,
}

const Icon: FunctionComponent<IconProps> = ({ name, tooltip, color, colorVariant, size }) => {
  const theme = useTheme();
  const classes = useStyles();

  const tooltipRef = useTooltipRef(tooltip);

  const { sizeVariant, hierarchyVariant } = useSizeContext();

  const iconColor: Record<IconColorVariant, string> = {
    primary: theme.color.text.primary,
    secondary: theme.color.icon.secondary,
    alternative: theme.color.text.primary,
    light: theme.color.text.white,
    disabled: theme.color.text.disabled,
    info: theme.color.background.info.default,
    warning: theme.color.background.warning.default,
    error: theme.color.background.danger.default,
  };

  let appliedSizeVariant = size;
  const iconSize = size === undefined ? getIconSize(sizeVariant, hierarchyVariant) : undefined;
  if (iconSize === base.icon.size.large) {
    appliedSizeVariant = IconSizeVariant.l;
  } else if (iconSize === base.icon.size.main) {
    appliedSizeVariant = IconSizeVariant.m;
  } else if (iconSize === base.icon.size.small) {
    appliedSizeVariant = IconSizeVariant.s;
  }

  let fill = 'currentColor';
  if (colorVariant && iconColor[colorVariant]) {
    fill = iconColor[colorVariant];
  } else if (color) {
    fill = color;
  }

  return (
    <svg
      ref={tooltipRef}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={classnames({
        [classes.xs]: appliedSizeVariant === IconSizeVariant.xs,
        [classes.s]: appliedSizeVariant === IconSizeVariant.s,
        [classes.m]: appliedSizeVariant === IconSizeVariant.m,
        [classes.l]: appliedSizeVariant === IconSizeVariant.l,
        [classes.xl]: appliedSizeVariant === IconSizeVariant.xl,
        [classes.xxl]: appliedSizeVariant === IconSizeVariant.xxl,
        [classes.xxxl]: appliedSizeVariant === IconSizeVariant.xxxl,
      })}
      style={{ fill }}
    >
      <use href={`${icons}#${name}`} />
    </svg>
  );
};

export default Icon;
