import { equals } from 'ramda';
import type { FunctionComponent } from 'react';
import { getCollaborationsForContext } from 'yooi-modules/modules/conceptModule';
import { CollaborationField } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { IconColorVariant, IconName } from '../../../../components/atoms/Icon';
import FieldCollaborationButton from '../../../../components/molecules/FieldCollaborationButton';
import ToggleButton from '../../../../components/molecules/ToggleButton';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { PanelState, useCollaborationPanelUpdater } from '../../../../utils/useCollaborationContext';
import { useHighlightNotify } from '../../../../utils/useHighlight';
import useTheme from '../../../../utils/useTheme';
import { usePageCollaborationContext } from '../../collaboration/usePageCollaborationContext';
import usePanelObserver from './usePanelObserver';
import { getCollaborationsUnreadMessageCount } from './utils/collaborationUtils';

interface CollaborationButtonProps {
  instanceId?: string,
  fieldId?: string,
  forceDisplay?: boolean,
}

const CollaborationButton: FunctionComponent<CollaborationButtonProps> = ({ instanceId, fieldId, forceDisplay = false }) => {
  const theme = useTheme();

  const store = useStore();
  const { loggedUserId } = useAuth();

  const highlight = useHighlightNotify();

  const collaborationPanel = useCollaborationPanelUpdater();
  const { state, pageContext, isOpen, context: panelSelectedContext } = usePanelObserver();
  usePageCollaborationContext();
  let queryContext: string[] = [];
  const field = fieldId && store.getObject(fieldId);
  if (instanceId && field && isInstanceOf(field, CollaborationField)) {
    queryContext = [instanceId];
  } else if (pageContext) {
    queryContext = fieldId ? [...pageContext[pageContext.length - 1], fieldId] : [...pageContext[pageContext.length - 1]];
  }

  const collaborationsForContext = getCollaborationsForContext(store, queryContext, false, 'OPEN');
  const unreadMessageCount = getCollaborationsUnreadMessageCount(store, collaborationsForContext, loggedUserId);
  const collaborationsCount = collaborationsForContext.length;

  const openDetail = (headerButton: boolean) => {
    if (isOpen && headerButton && equals(panelSelectedContext, queryContext)) {
      collaborationPanel.togglePanel();
      highlight(undefined, false, false);
    } else if ((!isOpen || !equals(panelSelectedContext, queryContext) || state !== PanelState.list)) {
      collaborationPanel.openList(queryContext, true);
      highlight(queryContext, false, false);
    } else {
      collaborationPanel.togglePanel();
      highlight(undefined, false, false);
    }
  };

  const getRestingIconColorVariant = () => {
    if (unreadMessageCount > 0) {
      return IconColorVariant.info;
    }
    if (collaborationsCount === 0) {
      return IconColorVariant.disabled;
    }
    return undefined;
  };

  const getFieldCollabOnHoverIcon = () => {
    if (isOpen && equals(panelSelectedContext, queryContext)) {
      return state === PanelState.list ? IconName.close : IconName.undo;
    }
    return unreadMessageCount > 0 ? IconName.mark_chat_unread : IconName.chat_bubble;
  };

  const getFieldCollabOnHoverTitle = () => {
    if ([IconName.close, IconName.undo].includes(getFieldCollabOnHoverIcon())) {
      return '';
    }
    return collaborationsCount < 100 ? collaborationsCount.toString() : '99+';
  };

  const getDisplayOnHoverOnly = () => {
    if (forceDisplay) {
      return false;
    }
    if (equals(panelSelectedContext, queryContext)) {
      return !isOpen && collaborationsCount === 0;
    } else {
      return collaborationsCount === 0;
    }
  };

  const getFieldCollabTooltip = () => {
    if (isOpen && equals(panelSelectedContext, queryContext)) {
      if (state === PanelState.list) {
        return i18n`Close collaboration thread`;
      } else {
        return i18n`Back to collaboration thread`;
      }
    }
    return i18n`Open collaboration thread`;
  };

  return fieldId ? (
    <FieldCollaborationButton
      onClick={() => openDetail(false)}
      restingTitle={collaborationsCount < 100 ? collaborationsCount.toString() : '99+'}
      restingIcon={unreadMessageCount > 0 ? IconName.mark_chat_unread : IconName.chat_bubble}
      onHoverIcon={getFieldCollabOnHoverIcon()}
      onHoverTitle={getFieldCollabOnHoverTitle()}
      restingIconColorVariant={getRestingIconColorVariant()}
      restingTitleColor={collaborationsCount === 0 ? theme.color.text.secondary : undefined}
      tooltip={getFieldCollabTooltip()}
      displayOnHoverOnly={getDisplayOnHoverOnly()}
      inCell={Boolean(instanceId)}
    />
  ) : (
    <ToggleButton
      key="collaborationHeaderButton"
      name={collaborationsCount < 100 ? collaborationsCount.toString() : '99+'}
      icon={unreadMessageCount > 0 ? IconName.mark_chat_unread : IconName.chat_bubble}
      iconColor={unreadMessageCount > 0 ? IconColorVariant.info : undefined}
      onClick={() => openDetail(true)}
      active={isOpen && equals(panelSelectedContext, queryContext)}
      tooltip={isOpen && equals(panelSelectedContext, queryContext) ? i18n`Close collaboration panel` : i18n`Open collaboration panel`}
    />
  );
};

export default CollaborationButton;
