import type { Migration } from 'yooi-store';
import { MigrationCollaboration, MigrationConceptCollaboration, MigrationConceptRoleCollaboration, MigrationDropNotifications } from '../../src/Migrations';
import {
  Concept,
  ConceptCapability,
  ConceptCapability_Description,
  ConceptCapability_Name,
  ConceptCapabilityAssignCollaborator,
  ConceptCapabilityOverrideCollaborationWorkflow,
  ConceptDefinition,
  ConceptRole,
  Field,
  FieldDefinition,
  FieldDefinition_Title,
  Group,
  User,
  Workflow,
  WorkflowTransition,
} from '../conceptModule/ids';
import { SubscriptionsField } from '../dashboardModule/ids';
import { LeftBarItem } from '../platformConfigurationModule/ids';
import { PlatformInstance } from '../platformModule/ids';
import type { TypeHelperRevision1 } from '../typeModule';
import { Class_Instances, Instance_Of, TypeModuleId } from '../typeModule/ids';
import {
  Collaboration,
  CollaborationRoles,
  ConceptIntentionGroups,
  ConceptIntentionRoles,
  ConceptIntentionTransitionGroups,
  ConceptIntentionTransitionRoles,
  ConceptIntentionUsers,
  Intention,
  Intention_Available,
  Intention_Description,
  Intention_Workflow,
  IntentionConcepts,
  IntentionGroups,
  IntentionStatusEntries,
  IntentionStatusEntries_Role_Entry,
  IntentionStatusEntries_Role_Intention,
  IntentionStatusIsClosedStatus,
  IntentionUsers,
} from './ids';

const migrations: Record<number, Migration> = {
  [MigrationCollaboration]: {
    dependencies: [TypeModuleId],
    handler: (store, helpers) => {
      const typeHelper = helpers.typeHelper as TypeHelperRevision1;
      const CollaborationClosedEntries = '22da0042-e1b1-4404-b1a4-f791c56f3974';
      const CollaborationClosedEntries_Role_Entry = 1;
      const CollaborationClosedEntries_Role_Platform = 0;
      const Platform_CollaborationWorkflow = '94ae345f-7201-4f61-9d3e-8b8b7d3f1ca6';
      typeHelper.property(Intention, Intention_Description);
      typeHelper.property(Intention, Intention_Available);
      typeHelper.relation(Intention, Intention_Workflow, Workflow);
      typeHelper.association(IntentionStatusEntries, [Intention, Concept]);
      typeHelper.association(IntentionUsers, [Intention, User]);
      typeHelper.association(IntentionGroups, [Intention, Group]);
      typeHelper.association(IntentionConcepts, [Intention, ConceptDefinition]);
      typeHelper.property(IntentionStatusEntries, IntentionStatusIsClosedStatus);

      const selectedWorkflow = store.getObject(PlatformInstance)[Platform_CollaborationWorkflow] as string | undefined;
      const closedStatus = store.withAssociation(CollaborationClosedEntries)
        .withRole(CollaborationClosedEntries_Role_Platform, PlatformInstance)
        .list()
        .map((assoc) => assoc.role(CollaborationClosedEntries_Role_Entry));
      if (selectedWorkflow) {
        store.getObject(Intention)
          .navigateBack(Instance_Of)
          .forEach((intention) => {
            store.updateObject(intention.id, {
              [Intention_Workflow]: selectedWorkflow, [Intention_Description]: '', [Intention_Available]: 'all',
            });

            if (closedStatus?.length > 0) {
              closedStatus.forEach((status) => {
                store.withAssociation(IntentionStatusEntries)
                  .withRole(IntentionStatusEntries_Role_Intention, intention.id)
                  .withRole(IntentionStatusEntries_Role_Entry, status)
                  .updateObject({ [IntentionStatusIsClosedStatus]: true });
              });
            }
          });
      }
      store.withAssociation(CollaborationClosedEntries).list().forEach((assoc) => store.deleteObject(assoc.object.id));
      store.updateObject(PlatformInstance, { [Platform_CollaborationWorkflow]: null });
    },
  },
  [MigrationConceptCollaboration]: {
    dependencies: [TypeModuleId],
    handler: (_store, helpers) => {
      const typeHelper = helpers.typeHelper as TypeHelperRevision1;
      typeHelper.association(ConceptIntentionUsers, [Intention, User, ConceptDefinition]);
      typeHelper.association(ConceptIntentionGroups, [Intention, Group, ConceptDefinition]);
    },
  },
  [MigrationDropNotifications]: {
    dependencies: [TypeModuleId],
    handler: (objectStore, helpers) => {
      const LeftBarItem_Bookmark = '814f0a42-9f0e-4eee-a570-a4e09295fab8';

      const typeHelper = helpers.typeHelper as TypeHelperRevision1;
      const NotificationsField = 'e9f3993e-9bec-4654-a1a5-0305bfbf3aab';
      if (objectStore.getObjectOrNull(NotificationsField)) {
        objectStore.deleteObject(NotificationsField);
        typeHelper.type(SubscriptionsField, { of: FieldDefinition, extends: Field });
        objectStore.updateObject(SubscriptionsField, { [FieldDefinition_Title]: 'Subscriptions' });
      }

      enum BookmarkType {
        concept = 'concept',
        custom = 'custom',
      }

      interface CustomBookmark {
        type: BookmarkType.custom,
        id: string,
      }

      const isCustomBookmark = (b: unknown): b is CustomBookmark => !!(b as CustomBookmark).id;

      const customNotificationsBookmarks = objectStore.getObject(LeftBarItem)
        .navigateBack(Class_Instances)
        .filter((lbi) => {
          const bookmark = lbi[LeftBarItem_Bookmark];
          if (bookmark && isCustomBookmark(bookmark) && bookmark.id === 'notifications') {
            return lbi;
          }
          return undefined;
        });

      customNotificationsBookmarks.forEach((lbi) => objectStore.deleteObject(lbi.id));
    },
  },
  [MigrationConceptRoleCollaboration]: {
    dependencies: [TypeModuleId],
    handler: (store, helpers) => {
      const typeHelper = helpers.typeHelper as TypeHelperRevision1;
      typeHelper.association(ConceptIntentionRoles, [Intention, ConceptRole, ConceptDefinition]);
      typeHelper.association(ConceptIntentionTransitionGroups, [Intention, ConceptDefinition, WorkflowTransition, Group]);
      typeHelper.association(ConceptIntentionTransitionRoles, [Intention, ConceptDefinition, WorkflowTransition, ConceptRole]);
      typeHelper.association(CollaborationRoles, [ConceptRole, Collaboration]);
      store.updateObject(ConceptCapabilityOverrideCollaborationWorkflow, {
        [Instance_Of]: ConceptCapability,
        [ConceptCapability_Name]: 'Override collaboration workflow',
        [ConceptCapability_Description]: 'Allows to override collaboration workflow and update statuses manually',
      });
      store.updateObject(ConceptCapabilityAssignCollaborator, {
        [ConceptCapability_Description]: 'Allows to assign external contributors to a collaboration. External contributors will be able to at least see the instance.',
      });
    },
  },
};

export default migrations;
