import type { ComponentProps, FunctionComponent } from 'react';
import type { RichText } from 'yooi-utils';
import RichTextInput from '../../../components/inputs/RichTextInput';
import StoreInput from './StoreInput';

interface StoreRichTextInputFieldProps extends Omit<ComponentProps<typeof RichTextInput>, 'value' | 'onSubmit' | 'onChange' | 'onCancel'> {
  initialValue: RichText | undefined,
  onSubmit: (value: RichText | null) => void,
}

const StoreRichTextInputField: FunctionComponent<StoreRichTextInputFieldProps> = ({ initialValue, onSubmit, ...richTextInputProps }) => (
  <StoreInput initialValue={initialValue} onSubmit={onSubmit}>
    {(formInputProps) => (
      <RichTextInput
        {...richTextInputProps}
        {...formInputProps}
      />
    )}
  </StoreInput>
);

export default StoreRichTextInputField;
