import * as fuzzysort from 'fuzzysort';
import { validate } from 'uuid';
import { isWrappedRichText } from 'yooi-modules/modules/conceptModule/fields/textField';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStore, ObjectStoreReadOnly, ProcessedEvent } from 'yooi-store';
// eslint-disable-next-line yooi/no-restricted-dependency
import { OriginSources } from 'yooi-store';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import { registerComputeFunction } from '../../../../utils/withAsyncTask';
import type { Context } from './GetHintContextProvider';
import type { ObjectEntry, ObjectKey, ObjectValue, Values } from './objectRenderType';
import { TagVariant, ValueType } from './objectRenderType';

export const EXPLORER_INSTANCE_FILTER_ID = 'explorerInstances';

const KEY_ID_SEP = '|';
export const keyToId = (key: string): string[] => key.split(KEY_ID_SEP);

const generateKey = (key: string) => ({ key, value: key });

const generateHintedKey = (
  { getObjectOrNull }: ObjectStoreReadOnly,
  getExplorerHint: (id: string) => string | undefined,
  key: string
): ObjectKey => {
  if (validate(key) && getObjectOrNull(key)) {
    return { key, value: key, hint: getExplorerHint(key), href: `/settings/explorer/instance/${key}` };
  } else {
    return { key, value: key };
  }
};

export const generateExportFromValues = (values: Values): unknown => {
  switch (values.type) {
    case ValueType.null:
      return null;
    case ValueType.array:
      return values.elements.map(generateExportFromValues);
    case ValueType.string:
      return values.value;
    case ValueType.number:
      return values.value;
    case ValueType.boolean:
      return values.value;
    case ValueType.date:
      return new Date(values.value);
    case ValueType.eventId:
      return values.value;
    case ValueType.object:
      return Object.fromEntries(values.entries.map((entry) => [entry.key.value, generateExportFromValues(entry.value)]));
  }
};

const generateValue = (value: unknown): Values => {
  if (value === null) {
    return { type: ValueType.null };
  } else if (Array.isArray(value)) {
    return { type: ValueType.array, elements: value.map(generateValue) };
  } else if (typeof value === 'string') {
    return { type: ValueType.string, value };
  } else if (typeof value === 'number') {
    return { type: ValueType.number, value };
  } else if (typeof value === 'boolean') {
    return { type: ValueType.boolean, value };
  } else if (value instanceof Date) {
    return { type: ValueType.date, value: value.getTime() };
  } else if (typeof value === 'object') {
    return { type: ValueType.object, entries: Object.entries(value).map(([k, v]) => ({ key: generateKey(k), value: generateValue(v) })) };
  } else {
    return { type: ValueType.string, value: JSON.stringify(value) };
  }
};

export const generateHintedValue = (
  objectStore: ObjectStoreReadOnly,
  getExplorerHint: (id: string) => (string | undefined),
  value: unknown,
  applyTagVariant = false
): Values => {
  if (Array.isArray(value)) {
    return { type: ValueType.array, elements: value.map((v) => generateHintedValue(objectStore, getExplorerHint, v, applyTagVariant)) };
  } else if (typeof value === 'string') {
    if (validate(value) && objectStore.getObjectOrNull(value)) {
      return {
        type: ValueType.string,
        value,
        hint: getExplorerHint(value),
        href: `/settings/explorer/instance/${value}`,
        variant: applyTagVariant ? TagVariant.value : undefined,
      };
    } else {
      return { type: ValueType.string, value };
    }
  } else if (isWrappedRichText(value)) {
    return generateHintedValue(objectStore, getExplorerHint, { type: 'WrappedRichText', value: value?.valueOf() }, applyTagVariant);
  } else if (value !== null && typeof value === 'object') {
    let collapsedLabel: string | undefined;
    const { id } = (value as Record<string, unknown>);
    if (id !== undefined) {
      if (typeof id === 'string' && validate(id)) {
        const hint = getExplorerHint(id);
        collapsedLabel = hint === undefined ? id : `${id} - ${hint}`;
      } else if (Array.isArray(id) && id.length > 0 && id.every((i) => (typeof i === 'string' && validate(i)))) {
        collapsedLabel = id.join('|');
      }
    }

    return {
      type: ValueType.object,
      entries: Object.entries(value).map(([k, v]) => ({
        key: joinObjects(generateHintedKey(objectStore, getExplorerHint, k), { variant: applyTagVariant ? TagVariant.key : undefined }),
        value: k === 'key' ? { type: ValueType.string, value: v } : generateHintedValue(objectStore, getExplorerHint, v, applyTagVariant),
      })),
      collapsedLabel,
    };
  } else {
    return generateValue(value);
  }
};

const collapseElement = (value: Values) => {
  switch (value.type) {
    case ValueType.object:
    case ValueType.array: {
      const newValue = { ...value };
      newValue.collapseByDefault = true;
      return newValue;
    }
    default:
      return value;
  }
};

export const formatEvent = (
  objectStore: ObjectStoreReadOnly,
  getExplorerHint: (id: string) => string | undefined,
  { eventId, version, status, origin, event, metadata, ...remainingProps }: ProcessedEvent
): ObjectValue => {
  const entries: ObjectEntry[] = [];
  entries.push(({ key: generateKey('version'), value: generateValue(version) }));
  entries.push({ key: generateKey('eventId'), value: { type: ValueType.eventId, value: eventId } });
  if (status !== undefined) {
    entries.push(({ key: generateKey('status'), value: generateValue(status) }));
  }

  const originValue = collapseElement(generateHintedValue(objectStore, getExplorerHint, origin));
  let originLabel;
  if (origin.userId !== undefined) {
    originLabel = `${origin.source} - ${getExplorerHint(origin.userId)}`;
  } else if (origin.source === OriginSources.AUTOMATION) {
    if (origin.automationRuleId !== undefined) {
      originLabel = `${origin.source} #${origin.depth} - ${getExplorerHint(origin.automationRuleId)}`;
    } else {
      originLabel = `${origin.source} #${origin.depth}`;
    }
  } else {
    originLabel = origin.source;
  }
  if (originValue.type === ValueType.object) {
    originValue.collapsedLabel = originLabel;
  }
  entries.push({ key: generateKey('origin'), value: originValue });
  entries.push(({ key: generateKey('event'), value: generateHintedValue(objectStore, getExplorerHint, event, true) }));
  if (metadata !== undefined) {
    entries.push({ key: generateKey('metadata'), value: collapseElement(generateHintedValue(objectStore, getExplorerHint, metadata)) });
  }
  entries.push(...Object.entries(remainingProps).map(([k, v]) => ({ key: { key: k, value: k }, value: generateValue(v) })));

  return {
    type: ValueType.object,
    collapsedLabel: [eventId, new Date(Number.parseInt(eventId.split('-')[0], 10)).toISOString(), status, originLabel].filter(filterNullOrUndefined).join(' - '),
    entries,
  };
};

export const getInstanceHint = (store: ObjectStore, getHint: Context, id: string | string[]): string | undefined => {
  if (Array.isArray(id)) {
    if (id.filter((i) => validate(i) && store.getObjectOrNull(i) && getHint(i)).length > 0) {
      return id.map((i) => (validate(i) && store.getObjectOrNull(i) ? getHint(i) ?? i : i)).join('|');
    }
  } else if (validate(id) && store.getObjectOrNull(id)) {
    return getHint(id);
  }
  return undefined;
};

const threshold = 1000;
const getScore = (search: string, value: string): number => {
  const score = fuzzysort.single(search, value)?.score;
  if (score === undefined || score < -threshold) {
    return 0;
  } else {
    return (score + threshold) / threshold;
  }
};

interface ExplorerItem {
  key: string,
  hint: string,
  type: string | undefined,
  typeHint: string | undefined,
  score: { global: number, key: number, hint: number },
}

export const getExplorerListComputeFunction = registerComputeFunction(
  async (yieldProcessor: () => Promise<void>, store: ObjectStoreReadOnly, getHint: (id: string) => string | undefined, search: string | undefined) => {
    const list: ExplorerItem[] = [];
    const storeObjects = store.listObjects();

    for (let i = 0; i < storeObjects.length; i += 1) {
      const { id, key, [Instance_Of]: instanceOf } = storeObjects[i];

      await yieldProcessor();

      const hint = (Array.isArray(id) ? id : [id]).map((aid) => getHint(aid) ?? aid).join('|');

      const keyScore = search === undefined || search === '' ? 0.001 : getScore(search, key);
      const hintScore = search === undefined || search === '' ? 0.001 : getScore(search, hint);

      const type = Array.isArray(id) ? id[0] : (instanceOf as string | undefined);
      list.push({
        key,
        hint,
        type: type ?? 'undefined',
        typeHint: type ? getHint(type) : undefined,
        score: { global: keyScore + hintScore, key: keyScore, hint: hintScore },
      });
    }

    return { search, list };
  }
);
