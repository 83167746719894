import type { FormulaError as FormulaErrorType, FormulaFunction, Parameter } from 'fast-formula-parser';
import FormulaParser from 'fast-formula-parser';
import { DateTime } from 'luxon';
import { computeEffectiveRangeForPeriodAndDates } from '../dateRangeUtils';
import { DATE_MAX_TIMESTAMP, DATE_MIN_TIMESTAMP, isDateFieldStoreValue, periodicities, PeriodicityType } from '../dateUtils';
import { filterNullOrUndefined } from '../filterUtils';
import { isFiniteNumber } from '../typeUtils';

const { FormulaError, FormulaHelpers, Types } = FormulaParser;

// Copied from fast-formula-parser
const WEEK_TYPES = [
  undefined,
  [1, 2, 3, 4, 5, 6, 7],
  [7, 1, 2, 3, 4, 5, 6],
  [6, 0, 1, 2, 3, 4, 5],
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  undefined,
  [7, 1, 2, 3, 4, 5, 6],
  [6, 7, 1, 2, 3, 4, 5],
  [5, 6, 7, 1, 2, 3, 4],
  [4, 5, 6, 7, 1, 2, 3],
  [3, 4, 5, 6, 7, 1, 2],
  [2, 3, 4, 5, 6, 7, 1],
  [1, 2, 3, 4, 5, 6, 7],
];

const notApplicableError = (message: string): FormulaErrorType => new FormulaError('#N/A', message);

// NB Custom functions need to handle missing params, otherwise "Should all defined function be supported" test might fail
const customFunctions = {
  GETOBJECT: (object) => {
    if ((Array.isArray(object) && object.length > 0) || object.isArray || object.isRangeRef) {
      return notApplicableError('GETOBJECT expects only one argument.');
    }
    if (!object.value) {
      return notApplicableError('GETOBJECT wrong input, need model type.');
    }
    return object.value;
  },
  GETOBJECTS: (...results) => {
    const flattenResult = results
      .flatMap(({ value, isRangeRef }) => (isRangeRef ? (value as unknown[][]).map((v) => v[0]) : value))
      .filter(filterNullOrUndefined)
      .filter((v): v is object => typeof v === 'object')
      .filter((v): v is { id: string, [k: string]: unknown } => typeof (v as Record<string, unknown>).id === 'string');

    return [...new Map(flattenResult.map((item) => [item.id, item])).values()];
  },
  NOW: () => Date.now(),
  DATESTR: (date) => DateTime.fromMillis(FormulaHelpers.accept(date, Types.NUMBER)).toFormat('yyyy/MM/dd HH:mm:ss'),
  SWITCH: (...params) => {
    if (params.length === 0) {
      return notApplicableError('SWITCH expects at least one argument.');
    }

    const switchValue = FormulaHelpers.accept(params[0]);
    const caseCount = Math.floor((params.length - 1) / 2);
    for (let i = 0; i < caseCount; i += 1) {
      const caseValue = FormulaHelpers.accept(params[(2 * i) + 1]);
      const valueIfMatch = FormulaHelpers.accept(params[(2 * i) + 2]);
      if (switchValue === caseValue) {
        return valueIfMatch;
      }
    }

    if (params.length === (2 * caseCount) + 2) {
      // switch default value
      return FormulaHelpers.accept(params[(2 * caseCount) + 1]);
    } else {
      return FormulaError.NA;
    }
  },
  COUNT: (...ranges) => {
    let nonBlank = 0;
    FormulaHelpers.flattenParams(
      ranges,
      null,
      true,
      (item) => {
        if (item != null && item.toString() !== '') {
          nonBlank += 1;
        }
      }
    );
    return nonBlank;
  },
  MAX: (...params) => {
    if (params.length === 0) {
      return FormulaError.NA;
    }

    let max: string | number | undefined;

    const computeMaxFromItem = (item: string | number | undefined) => {
      if (item != null && (max == null || item > max)) {
        max = item;
      }
    };

    const recursiveHandleOfItem = (item: unknown) => {
      if (Array.isArray(item)) {
        item.forEach((subItem) => recursiveHandleOfItem(subItem));
      } else if (typeof item === 'object' && Array.isArray((item as { value: unknown }).value)) {
        recursiveHandleOfItem((item as { value: unknown[] }).value);
      } else if (typeof item === 'object' && (item as { period: unknown }).period) {
        if ((item as { to?: { value: number } }).to) {
          computeMaxFromItem((item as { to: { value: number } }).to.value);
        } else {
          computeMaxFromItem((item as { date?: number }).date);
        }
      } else if (typeof item === 'number' || typeof item === 'string') {
        computeMaxFromItem(item);
      }
    };

    FormulaHelpers.flattenParams(params, null, true, (item) => recursiveHandleOfItem(item), undefined, 0);

    return max ?? FormulaError.NULL;
  },
  MIN: (...params) => {
    if (params.length === 0) {
      return FormulaError.NA;
    }

    let min: string | number | undefined;
    const computeMinFromItem = (item: string | number | undefined) => {
      if (item != null && (min == null || item < min)) {
        min = item;
      }
    };

    const recursiveHandleOfItem = (item: unknown) => {
      if (Array.isArray(item)) {
        item.forEach((subItem) => recursiveHandleOfItem(subItem));
      } else if (typeof item === 'object' && Array.isArray((item as { value: unknown }).value)) {
        recursiveHandleOfItem((item as { value: unknown[] }).value);
      } else if (typeof item === 'object' && (item as { period: unknown }).period) {
        if ((item as { from?: { value: number } }).from) {
          computeMinFromItem((item as { from: { value: number } }).from.value);
        } else {
          computeMinFromItem((item as { date?: number }).date);
        }
      } else if (typeof item === 'number' || typeof item === 'string') {
        computeMinFromItem(item);
      }
    };

    FormulaHelpers.flattenParams(params, null, true, (item) => recursiveHandleOfItem(item), undefined, 0);

    return min ?? FormulaError.NULL;
  },
  COMPLETENESS: (...ranges) => {
    let nonBlank = 0;
    let total = 0;
    ranges.forEach((item) => {
      if (item.value != null && item.value.toString() !== '' && !(item.isRangeRef && (item as { value?: unknown[] }).value?.length === 0)) {
        nonBlank += 1;
      }
      total += 1;
    });
    if (total === 0) {
      return FormulaError.NA;
    } else {
      return nonBlank / total;
    }
  },
  AVERAGEW: (...pairs) => {
    if (pairs.length === 0) {
      return FormulaError.NA;
    }

    let sumProduct = 0;
    let sumWeight = 0;
    pairs
      .flatMap(({ isArray, isRangeRef, value }) => {
        if (isArray) {
          return value;
        } else if (isRangeRef) {
          return value;
        } else {
          return [value];
        }
      })
      .forEach((item) => {
        const [scalar, weight] = item as [unknown, unknown];
        if (isFiniteNumber(scalar) && isFiniteNumber(weight)) {
          sumProduct += Number(scalar) * Number(weight);
        }
        if (isFiniteNumber(weight)) {
          sumWeight += Number(weight);
        }
      });
    if (sumWeight === 0) {
      return FormulaError.NULL;
    } else {
      return sumProduct / sumWeight;
    }
  },
  EARLIESTAFTER: (...params) => {
    if (params.length === 0) {
      return notApplicableError('EARLIESTAFTER expects at least one argument.');
    }
    let maxDate = 0;
    const ranges = params
      .flatMap(({ isArray, isRangeRef, value }) => {
        if (isArray) {
          return value;
        } else if (isRangeRef) {
          return value;
        } else {
          return [value];
        }
      });

    const computeMaxDate = (newValue: unknown) => {
      if ((newValue as { to?: { value?: number } })?.to?.value || (newValue as { from?: { value?: number } })?.from?.value) {
        const nv = newValue as { period: string, to?: { value?: number }, from?: { value?: number } };
        const { to } = computeEffectiveRangeForPeriodAndDates(nv?.period, nv?.from, nv?.to);
        maxDate = Math.max(maxDate, to as unknown as number);
      } else if ((newValue as { date?: number })?.date) {
        maxDate = Math.max(maxDate, (newValue as { date: number }).date);
      } else if (isFiniteNumber(newValue)) {
        maxDate = Math.max(maxDate, Number(newValue));
      }
    };

    FormulaHelpers.flattenParams(
      ranges,
      null,
      true,
      (item) => {
        if (Array.isArray(item)) {
          item.forEach((subItem) => computeMaxDate(subItem));
        } else {
          computeMaxDate(item);
        }
      },
      undefined,
      0
    );
    if (maxDate) {
      return periodicities.day.getPreviousDateInAmountOfPeriod(new Date(maxDate), -1).getTime();
    } else {
      return 0;
    }
  },
  DATECONVERT: (dateParameter, periodicityParameter) => {
    if (!dateParameter || !periodicityParameter) {
      return notApplicableError('DATECONVERT expects at least two argument.');
    }

    const periodicityKey = FormulaHelpers.accept(periodicityParameter, Types.STRING).toLowerCase();
    if (!Object.keys(periodicities).includes(periodicityKey)) {
      return notApplicableError('Invalid periodicity');
    }

    const date = FormulaHelpers.accept(dateParameter);
    if (date === null || date === undefined) {
      return FormulaError.NULL;
    } else if (typeof date === 'object') {
      if ((date as { date: unknown }).date === null || (date as { date: unknown }).date === undefined) {
        return FormulaError.NULL;
      } else if (typeof (date as { date: unknown }).date === 'number' || typeof (date as { date: unknown }).date === 'string') {
        return periodicities[periodicityKey as keyof typeof periodicities].getStartOfPeriod(new Date((date as { date: string | number }).date)).getTime();
      } else {
        return FormulaError.NA;
      }
    } else if (typeof date === 'number' || typeof date === 'string') {
      return periodicities[periodicityKey as keyof typeof periodicities].getStartOfPeriod(new Date(date)).getTime();
    } else {
      return FormulaError.NA;
    }
  },
  LASTVALUE: (timeseriesParameter, dateParameter, defaultValueParameter) => {
    const maxDate = (new Date(8640000000000000)).valueOf();

    let inputDate: number | undefined;
    const date = FormulaHelpers.accept(dateParameter, null, null);
    if (date !== null && typeof date === 'object') {
      if (typeof (date as { date: unknown }).date === 'number') {
        inputDate = (date as { date: number }).date;
      }
    } else if (typeof date === 'number') {
      inputDate = date;
    }

    const defaultValue = FormulaHelpers.accept(defaultValueParameter, null, FormulaError.NULL);

    if (timeseriesParameter.value === undefined || FormulaHelpers.accept(timeseriesParameter, null) === undefined) {
      return defaultValue;
    }

    const timeseries = FormulaHelpers.accept(timeseriesParameter, Types.ARRAY);
    const values = [...timeseries]
      .filter((t): t is { time: number, value: unknown } => t !== null && typeof t === 'object' && typeof (t as Record<string, unknown>).time === 'number')
      .sort(({ time: timeA }, { time: timeB }) => timeB - timeA);

    return values.find(({ time }) => (time <= (inputDate ?? maxDate)))?.value ?? defaultValue;
  },
  VALUEAT: (timeseriesParameter, dateParameter, defaultValueParameter) => {
    let inputDate: number | undefined;
    const date = FormulaHelpers.accept(dateParameter);
    if (date !== null && typeof date === 'object') {
      if (typeof (date as { date: unknown }).date === 'number') {
        inputDate = (date as { date: number }).date;
      }
    } else if (typeof date === 'number') {
      inputDate = date;
    }

    if (!inputDate) {
      return notApplicableError('Provided date parameter is invalid');
    }

    const defaultValue = FormulaHelpers.accept(defaultValueParameter, null, FormulaError.NULL);

    if (timeseriesParameter.value === undefined || FormulaHelpers.accept(timeseriesParameter, null) === undefined) {
      return defaultValue;
    }

    const timeseries = FormulaHelpers.accept(timeseriesParameter, Types.ARRAY, []);
    const values = [...timeseries]
      .filter((t): t is { time: number, value: unknown } => t !== null && typeof t === 'object' && typeof (t as Record<string, unknown>).time === 'number');

    return values.find(({ time }) => time === inputDate)?.value ?? defaultValue;
  },
  VALUESIN: (timeseriesParameter, startDateParameter, endDateParameter) => {
    if (timeseriesParameter.value === undefined || FormulaHelpers.accept(timeseriesParameter, null) === undefined) {
      return FormulaError.NULL;
    }

    let startTimestamp = DATE_MIN_TIMESTAMP;
    const startDate = FormulaHelpers.accept(startDateParameter, null, null);
    if (startDate !== null && typeof startDate === 'object') {
      if (typeof (startDate as { date: unknown }).date === 'number') {
        startTimestamp = (startDate as { date: number }).date;
      }
    } else if (typeof startDate === 'number') {
      startTimestamp = startDate;
    }
    let endTimestamp = DATE_MAX_TIMESTAMP;
    const endDate = FormulaHelpers.accept(endDateParameter, null, null);
    if (endDate !== null && typeof endDate === 'object') {
      if (typeof (endDate as { date: unknown }).date === 'number') {
        endTimestamp = (endDate as { date: number }).date;
      }
    } else if (typeof endDate === 'number') {
      endTimestamp = endDate;
    }

    const timeseries = FormulaHelpers.accept(timeseriesParameter, Types.ARRAY, []);

    return timeseries
      .filter((t): t is { time: number, value: unknown } => t !== null && typeof t === 'object' && typeof (t as Record<string, unknown>).time === 'number')
      .filter(({ time }) => time >= startTimestamp && time <= endTimestamp)
      .map(({ value }) => value);
  },
  INDEXOF: (stringParameter, subStringParameter, positionParameter) => {
    const string = FormulaHelpers.accept(stringParameter, Types.STRING, '');
    const subString = FormulaHelpers.accept(subStringParameter, Types.STRING, '');
    const position = positionParameter ? FormulaHelpers.accept(positionParameter, Types.NUMBER, undefined) : undefined;
    return string.indexOf(subString, position);
  },
  // UPPER is on an un-released version of fast-formula-parser
  UPPER: (parameter) => {
    const text = FormulaHelpers.accept(parameter, Types.STRING);
    return text.toUpperCase();
  },
  // FormulaHelpers.accept has a bug that don't properly deal with types (number might not be wrapped in a string)
  LEN: (parameter) => {
    const text = FormulaHelpers.accept(parameter, Types.STRING);
    return `${text}`.length;
  },
  // TEXTJOIN is left empty in fast-formula-parser
  TEXTJOIN: (delimiterParam, ignoreEmptyParam, ...params) => {
    const ignoreEmpty = FormulaHelpers.accept(ignoreEmptyParam, Types.BOOLEAN);

    const parts: string[] = [];
    // does not allow union
    FormulaHelpers.flattenParams(params, Types.STRING, false, (item) => {
      const p = FormulaHelpers.accept(item as Parameter<unknown>, Types.STRING);
      const part = `${p}`;
      if (!ignoreEmpty || part !== '') {
        parts.push(part);
      }
    });

    const delimiter = FormulaHelpers.accept(delimiterParam, Types.STRING);
    return parts.join(delimiter);
  },
  YEAR: (param) => {
    const value = FormulaHelpers.accept(param);
    if (value === null || value === undefined) {
      return FormulaError.NULL;
    } else if (typeof value === 'number') {
      return new Date(value).getFullYear();
    } else if (isDateFieldStoreValue(value)) {
      if (value.date === undefined) {
        return FormulaError.NULL;
      } else {
        return periodicities[value.period ?? PeriodicityType.day].getStartOfPeriod(new Date(value.date)).getFullYear();
      }
    } else {
      return FormulaError.VALUE;
    }
  },
  MONTH: (param) => {
    const value = FormulaHelpers.accept(param);
    if (value === null || value === undefined) {
      return FormulaError.NULL;
    } else if (typeof value === 'number') {
      return new Date(value).getMonth() + 1;
    } else if (isDateFieldStoreValue(value)) {
      if (value.date === undefined) {
        return FormulaError.NULL;
      } else {
        return periodicities[value.period ?? PeriodicityType.day].getStartOfPeriod(new Date(value.date)).getMonth() + 1;
      }
    } else {
      return FormulaError.VALUE;
    }
  },
  WEEK: (param) => {
    const value = FormulaHelpers.accept(param);
    if (value === null || value === undefined) {
      return FormulaError.NULL;
    } else if (typeof value === 'number') {
      return DateTime.fromJSDate(new Date(value)).weekNumber;
    } else if (isDateFieldStoreValue(value)) {
      if (value.date === undefined) {
        return FormulaError.NULL;
      } else {
        return DateTime.fromJSDate(periodicities[value.period ?? PeriodicityType.day].getStartOfPeriod(new Date(value.date))).weekNumber;
      }
    } else {
      return FormulaError.VALUE;
    }
  },
  DAY: (param) => {
    const value = FormulaHelpers.accept(param);
    if (value === null || value === undefined) {
      return FormulaError.NULL;
    } else if (typeof value === 'number') {
      return new Date(value).getDate();
    } else if (isDateFieldStoreValue(value)) {
      if (value.date === undefined) {
        return FormulaError.NULL;
      } else {
        return periodicities[value.period ?? PeriodicityType.day].getStartOfPeriod(new Date(value.date)).getDate();
      }
    } else {
      return FormulaError.VALUE;
    }
  },
  WEEKDAY: (param, returnTypeParam) => {
    const value = FormulaHelpers.accept(param);

    const returnType = FormulaHelpers.accept(returnTypeParam, Types.NUMBER, 1);
    const weekTypes = WEEK_TYPES[returnType];
    if (!weekTypes) {
      return FormulaError.NUM;
    }

    if (value === null || value === undefined) {
      return FormulaError.NULL;
    } else if (typeof value === 'number') {
      const day = new Date(value).getDay();
      return weekTypes[day];
    } else if (isDateFieldStoreValue(value)) {
      if (value.date === undefined) {
        return FormulaError.NULL;
      } else {
        const day = periodicities[value.period ?? PeriodicityType.day].getStartOfPeriod(new Date(value.date)).getDay();
        return weekTypes[day];
      }
    } else {
      return FormulaError.VALUE;
    }
  },
  COALESCE: (...params) => {
    for (let i = 0; i < params.length; i += 1) {
      const param = params[i];
      const acceptedValue = FormulaHelpers.accept(param);
      if (param.value !== undefined && param.value !== null && acceptedValue !== FormulaError.NULL) {
        return acceptedValue;
      }
    }
    return FormulaError.NULL;
  },
  ZN: (parameter) => {
    if (parameter.value === undefined || parameter.value === null) {
      return 0;
    } else {
      const acceptedValue = FormulaHelpers.accept(parameter);
      return acceptedValue === FormulaError.NULL ? 0 : acceptedValue;
    }
  },
} satisfies Record<string, FormulaFunction>;

export const createFunctionLibrary = (builtInFunctions: Record<string, FormulaFunction>, extraFunctions?: Record<string, FormulaFunction>): Record<string, FormulaFunction> => ({
  // custom functions
  ...(extraFunctions ?? {}),
  GETOBJECT: customFunctions.GETOBJECT,
  GETOBJECTS: customFunctions.GETOBJECTS,

  // Timeseries functions
  LASTVALUE: customFunctions.LASTVALUE,
  VALUEAT: customFunctions.VALUEAT,
  VALUESIN: customFunctions.VALUESIN,

  // date functions
  DATESTR: customFunctions.DATESTR,
  NOW: customFunctions.NOW,
  DATEVALUE: (param) => {
    const value = FormulaHelpers.accept(param);
    if (typeof value === 'number') {
      return builtInFunctions.DATEVALUE({ ...param, value: DateTime.fromMillis(value).toFormat('yyyy-MM-dd') });
    } else if (isDateFieldStoreValue(value) && value !== undefined) {
      return builtInFunctions.DATEVALUE({
        ...param,
        value: DateTime.fromJSDate(periodicities[value.period].getStartOfPeriod(new Date(value.date))).toFormat('yyyy-MM-dd'),
      });
    } else {
      return builtInFunctions.DATEVALUE(param);
    }
  },
  TODAY: builtInFunctions.TODAY,
  DATEDIF: builtInFunctions.DATEDIF,
  DAYS: builtInFunctions.DAYS,
  EARLIESTAFTER: customFunctions.EARLIESTAFTER,
  DATECONVERT: customFunctions.DATECONVERT,
  YEAR: customFunctions.YEAR,
  MONTH: customFunctions.MONTH,
  WEEK: customFunctions.WEEK,
  DAY: customFunctions.DAY,
  WEEKDAY: customFunctions.WEEKDAY,

  // logical functions
  NOT: builtInFunctions.NOT,
  AND: builtInFunctions.AND,
  OR: builtInFunctions.OR,
  XOR: builtInFunctions.XOR,
  IF: builtInFunctions.IF,
  IFS: builtInFunctions.IFS,
  ISBLANK: (param) => FormulaHelpers.accept(param) === FormulaError.NULL || builtInFunctions.ISBLANK(param),
  ISNUMBER: builtInFunctions.ISNUMBER,
  SWITCH: customFunctions.SWITCH,
  COALESCE: customFunctions.COALESCE,

  // math functions
  ABS: builtInFunctions.ABS,
  MOD: builtInFunctions.MOD,
  POWER: builtInFunctions.POWER,
  PRODUCT: builtInFunctions.PRODUCT,
  QUOTIENT: builtInFunctions.QUOTIENT,
  SUM: builtInFunctions.SUM,

  INT: builtInFunctions.INT,
  TRUNC: builtInFunctions.ROUNDDOWN, // TRUNC in Excel accept 2 parameters, the one in builtin only accept one. ROUNDDOWN is a synonym for TRUNC
  ROUND: builtInFunctions.ROUND,
  ROUNDDOWN: builtInFunctions.ROUNDDOWN,
  ROUNDUP: builtInFunctions.ROUNDUP,

  MROUND: builtInFunctions.MROUND,
  FLOOR: builtInFunctions.FLOOR,
  'FLOOR.MATH': builtInFunctions['FLOOR.MATH'],
  CEILING: builtInFunctions.CEILING,
  'CEILING.MATH': builtInFunctions['CEILING.MATH'],

  EXP: builtInFunctions.EXP,
  LN: builtInFunctions.LN,
  LOG: builtInFunctions.LOG,
  LOG10: builtInFunctions.LOG10,
  SQRT: builtInFunctions.SQRT,
  ZN: customFunctions.ZN,

  // stats functions
  AVERAGE: builtInFunctions.AVERAGE,
  AVERAGEW: customFunctions.AVERAGEW,
  COUNT: customFunctions.COUNT,
  COUNTA: customFunctions.COUNT,
  MAX: customFunctions.MAX,
  MIN: customFunctions.MIN,
  COMPLETENESS: customFunctions.COMPLETENESS,

  // text functions
  CLEAN: builtInFunctions.CLEAN,
  CONCAT: builtInFunctions.CONCAT,
  CONCATENATE: builtInFunctions.CONCAT,
  INDEXOF: customFunctions.INDEXOF,
  LEFT: builtInFunctions.LEFT,
  LEN: customFunctions.LEN,
  LOWER: builtInFunctions.LOWER,
  MID: builtInFunctions.MID,
  PROPER: builtInFunctions.PROPER,
  REPLACE: builtInFunctions.REPLACE,
  RIGHT: builtInFunctions.RIGHT,
  TEXT: builtInFunctions.TEXT,
  TEXTJOIN: customFunctions.TEXTJOIN,
  TRIM: builtInFunctions.TRIM,
  UPPER: customFunctions.UPPER,
});
