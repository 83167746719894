import type { ComponentProps, FunctionComponent } from 'react';
import type { ConceptDefinitionStoreObject } from 'yooi-modules/modules/conceptModule';
import { FILTER_PARAMETER_LOGGED_USER, InstanceReferenceType, isDimensionStep, PathStepType } from 'yooi-modules/modules/conceptModule';
import { ConceptDefinition_Name, User } from 'yooi-modules/modules/conceptModule/ids';
import { Dashboard } from 'yooi-modules/modules/dashboardModule/ids';
import type { ImageWithSize, LeftBarItemRaw, LeftBarItemStoreObject, PlatformConfigurationStoreObject } from 'yooi-modules/modules/platformConfigurationModule';
import {
  CurrentPlatformConfiguration,
  LeftBarItem,
  LeftBarItem_DisplayConditions,
  LeftBarItem_Path,
  LeftBarItem_Rank,
  PlatformConfiguration_LeftBarImage,
} from 'yooi-modules/modules/platformConfigurationModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareProperty, compareRank, ranker } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import RawImagePicker from '../../../components/inputs/RawImagePicker';
import OverflowMenu from '../../../components/molecules/OverflowMenu';
import SpacingLine from '../../../components/molecules/SpacingLine';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../components/templates/BlockTitle';
import DataTable from '../../../components/templates/DataTable';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { formatOrUndef } from '../../../utils/stringUtils';
import useNewLineFocus from '../../../utils/useNewLineFocus';
import FilterComposite from '../../_global/filter/filterComposite/FilterComposite';
import { getLoggedUserParameterDefinition } from '../../_global/filter/filterUtils';
import StoreInput from '../../_global/input/StoreInput';
import { leftBarItemValidator } from '../../_global/navigationUtils';
import PathStepsInput from '../../_global/path/PathStepsInput';
import { createPathConfigurationHandler } from '../../_global/pathConfigurationHandler';

const LeftBarTab: FunctionComponent = () => {
  const store = useStore();

  const [newLineFocus, setNewLineFocus] = useNewLineFocus();

  const platformConfiguration = store.getObject<PlatformConfigurationStoreObject>(CurrentPlatformConfiguration);
  const leftBarImage = platformConfiguration[PlatformConfiguration_LeftBarImage];
  const leftBarImageValue: ComponentProps<typeof RawImagePicker>['value'] = leftBarImage !== undefined
    ? {
      type: 'url',
      url: store.getAttachmentUrl(CurrentPlatformConfiguration, PlatformConfiguration_LeftBarImage)(leftBarImage.revisionId, true),
      size: { width: leftBarImage.width, height: leftBarImage.height },
    } : undefined;

  const list = store.getObject(LeftBarItem)
    .navigateBack<LeftBarItemStoreObject>(Class_Instances)
    .sort(compareProperty(LeftBarItem_Rank, compareRank));

  const rankedList = ranker.decorateList(list, (instance) => instance[LeftBarItem_Rank]);

  const parameterDefinitions = [getLoggedUserParameterDefinition()];

  return (
    <VerticalBlock>
      <HorizontalBlock asBlockContent>
        <BlockTitle title={i18n`Logo`} />
        <BlockContent padded={leftBarImageValue !== undefined}>
          <StoreInput<ComponentProps<typeof RawImagePicker>['value']>
            initialValue={leftBarImageValue}
            onSubmit={(newValue) => {
              if (newValue) {
                if (newValue.type === 'buffer') {
                  store.uploadAttachment(
                    {
                      objectId: CurrentPlatformConfiguration,
                      propertyId: PlatformConfiguration_LeftBarImage,
                      contentType: newValue.contentType,
                      data: newValue.data,
                    },
                    (revisionId) => {
                      store.updateObject(CurrentPlatformConfiguration, {
                        [PlatformConfiguration_LeftBarImage]: {
                          revisionId,
                          width: newValue.size.width,
                          height: newValue.size.height,
                        } satisfies ImageWithSize,
                      });
                    }
                  );
                }
              } else {
                store.updateObject(CurrentPlatformConfiguration, { [PlatformConfiguration_LeftBarImage]: null });
              }
            }}
          >
            {(props) => (
              <RawImagePicker
                exactSize={{ width: 20, height: 20 }}
                alt={i18n`Left bar image`}
                {...props}
              />
            )}
          </StoreInput>
        </BlockContent>
      </HorizontalBlock>
      <VerticalBlock asBlockContent compact>
        <BlockTitle
          title={i18n`Shortcuts`}
          variant={BlockTitleVariant.inline}
        />
        <DataTable
          list={list.map((item) => ({ key: item.id, type: 'item', item, color: undefined }))}
          columnsDefinition={[
            {
              propertyId: LeftBarItem_Path,
              name: i18n`Path`,
              width: 85,
              focusable: true,
              cellRender: ({ id, [LeftBarItem_Path]: path }, focusAndScrollOnMount) => (
                <PathStepsInput
                  focusOnMount={focusAndScrollOnMount}
                  initialPath={path ?? []}
                  parameterDefinitions={parameterDefinitions}
                  onSubmit={(newPath) => {
                    store.updateObject<LeftBarItemRaw>(id, { [LeftBarItem_Path]: newPath });
                  }}
                  valuePathHandler={createPathConfigurationHandler(store, parameterDefinitions, [leftBarItemValidator(store)])}
                  warning={
                    path?.length === 1 && isDimensionStep(path[0])
                      ? i18n`Libraries are deprecated. Please use "${formatOrUndef(store.getObject<ConceptDefinitionStoreObject>(Dashboard)[ConceptDefinition_Name])}" instead.`
                      : undefined
                  }
                />
              ),
            },
            {
              propertyId: LeftBarItem_DisplayConditions,
              name: i18n`Display conditions`,
              width: 15,
              cellRender: ({ id, [LeftBarItem_DisplayConditions]: displayConditions }) => (
                <FilterComposite
                  parameterDefinitions={parameterDefinitions}
                  filtersDefinition={{
                    updateFilters: (newFilters) => {
                      store.updateObject<LeftBarItemRaw>(id, { [LeftBarItem_DisplayConditions]: newFilters.at(0) ?? null });
                    },
                    definition: [{ filter: displayConditions, subtitle: i18n`Display when...` }],
                  }}
                  suggestedPaths={[{
                    label: i18n`Logged-in User`,
                    path: [
                      { type: PathStepType.dimension, conceptDefinitionId: User },
                      { type: PathStepType.mapping, mapping: { type: InstanceReferenceType.parameter, id: FILTER_PARAMETER_LOGGED_USER } },
                    ],
                  }]}
                />
              ),
            },
            {
              propertyId: 'MoveUp',
              action: true,
              cellRender: ({ id }, _, index) => (
                <SpacingLine>
                  <IconOnlyButton
                    disabled={index === 0}
                    onClick={() => store.updateObject(id, { [LeftBarItem_Rank]: rankedList[index].moveUpRank() })}
                    iconName={IconName.expand_less}
                    tooltip={i18n`Move Up`}
                    variant={IconOnlyButtonVariants.tertiary}
                  />
                </SpacingLine>
              ),
            },
            {
              propertyId: 'MoveDown',
              action: true,
              cellRender: ({ id }, _, index) => (
                <SpacingLine>
                  <IconOnlyButton
                    disabled={index === (list.length - 1)}
                    onClick={() => store.updateObject(id, { [LeftBarItem_Rank]: rankedList[index].moveDownRank() })}
                    iconName={IconName.expand_more}
                    tooltip={i18n`Move Down`}
                    variant={IconOnlyButtonVariants.tertiary}
                  />
                </SpacingLine>
              ),
            },
            {
              propertyId: 'Delete',
              action: true,
              cellRender: ({ id }, _, index) => (
                <SpacingLine>
                  <OverflowMenu
                    menuItems={[
                      {
                        key: 'add_before',
                        name: i18n`Add navigation link above`,
                        icon: IconName.input,
                        onClick: () => {
                          const newId = store.createObject({ [Instance_Of]: LeftBarItem, [LeftBarItem_Rank]: rankedList[index].insertBeforeRank() });
                          setNewLineFocus(newId);
                        },
                      },
                      {
                        key: 'add_after',
                        name: i18n`Add navigation link below`,
                        icon: IconName.input,
                        onClick: () => {
                          const newId = store.createObject({ [Instance_Of]: LeftBarItem, [LeftBarItem_Rank]: rankedList[index].insertAfterRank() });
                          setNewLineFocus(newId);
                        },
                      },
                      {
                        key: 'delete',
                        name: i18n`Delete`,
                        icon: IconName.delete,
                        onClick: () => store.deleteObject(id),
                        danger: true,
                      },
                    ]}
                  />
                </SpacingLine>
              ),
            },
          ]}
          newLineFocus={newLineFocus.current}
          newItemTitle={i18n`Add`}
          newItemIcon={IconName.add}
          onNewItem={() => {
            const newId = store.createObject({ [Instance_Of]: LeftBarItem, [LeftBarItem_Rank]: rankedList.insertAfterLastItemRank() });
            setNewLineFocus(newId);
          }}
        />
      </VerticalBlock>
    </VerticalBlock>
  );
};

export default LeftBarTab;
