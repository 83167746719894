import type {
  AccessControlListResult,
  BusinessRuleHandler,
  EventOrigin,
  GarbageCollectorRulesLibraryRegistration,
  ObjectDebugLabelHandler,
  ObjectRegistrationHandler,
  ObjectStoreReadOnly,
  ReadAcl,
  StoreObject,
} from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import type { RichText } from 'yooi-utils';
import { joinObjects, richTextToText } from 'yooi-utils';
import type { CollaborationStoreObject } from '../collaborationModule';
import {
  Collaboration,
  Collaboration_Intention,
  Collaboration_Status,
  CollaborationGroups,
  CollaborationGroups_Role_Collaboration,
  CollaborationGroups_Role_Group,
  CollaborationOnMultipleContext,
  CollaborationOnMultipleContext_Role_Collaboration,
  CollaborationOnMultipleContext_VarRoles_Contexts,
  CollaborationRoles,
  CollaborationRoles_Role_Collaboration,
  CollaborationRoles_Role_Role,
  CollaborationUsers,
  CollaborationUsers_Role_Collaboration,
  CollaborationUsers_Role_User,
  IntentionStatusEntries,
  IntentionStatusEntries_Role_Entry,
  IntentionStatusEntries_Role_Intention,
} from '../collaborationModule/ids';
import { registerCreationAndUpdateDateBusinessRules } from '../common/businessRules';
import { createFieldDsl } from '../common/fields/FieldModuleDsl';
import { createModule } from '../common/types/TypeModuleDsl';
import type { BusinessRuleRegistration } from '../common/types/TypeModuleDslType';
import {
  ConceptDefinitionLibraryTable,
  ConceptDefinitionLibraryTable_Role_ConceptDefinition,
  ConceptDefinitionLibraryTable_Role_Field,
  FieldRelevantFieldDisplay,
  FieldRelevantFieldDisplay_Role_ConceptDefinition,
  FieldRelevantFieldDisplay_Role_Field,
} from '../conceptLayoutModule/ids';
import type { WidgetStoreObject } from '../dashboardModule';
import { Field_Widget, Widget, Widget_Field } from '../dashboardModule/ids';
import { doExtends, isInstanceOf } from '../typeModule';
import { Class_Instances, Instance_Of, ModelAssociation, ModelProperty, ModelType } from '../typeModule/ids';
import * as ConceptModuleIds from './ids';
import {
  Association,
  Association_Role_Definition,
  Association_Role_Role1TypeInstance,
  Association_Role_Role2TypeInstance,
  AssociationField,
  AssociationField_Definition,
  AssociationFieldDefinition,
  AssociationFieldDefinition_Fields,
  AssociationFieldDefinition_Role1Type,
  AssociationFieldDefinition_Role2Type,
  Concept,
  Concept_CreatedAt,
  Concept_Name,
  Concept_UpdatedAt,
  ConceptCapabilityAssignUser,
  ConceptCapabilityCreate,
  ConceptCapabilityEdit,
  ConceptCapabilityRead,
  ConceptDefinition,
  ConceptDefinition_HasStakeholders,
  ConceptDefinition_Roles,
  ConceptGroupCapability,
  ConceptGroupCapability_Role_ConceptCapability,
  ConceptGroupCapability_Role_ConceptDefinition,
  ConceptGroupCapability_Role_ConceptGroup,
  ConceptModuleId,
  ConceptRole,
  ConceptRole_ConceptDefinition,
  ConceptRole_ForCollaboration,
  ConceptRoleGroupAssignation,
  ConceptRoleGroupAssignation_Role_Concept,
  ConceptRoleGroupAssignation_Role_ConceptRole,
  ConceptRoleGroupAssignation_Role_Group,
  ConceptRoleUserAssignation,
  ConceptRoleUserAssignation_Role_Concept,
  ConceptRoleUserAssignation_Role_ConceptRole,
  ConceptRoleUserAssignation_Role_User,
  DateField,
  EmbeddingField,
  EmbeddingField_FromType,
  EmbeddingField_ToType,
  Field,
  Field_FieldDimensions,
  Field_Formula,
  Field_IsCore,
  FieldDimension,
  FieldDimension_Field,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  Group,
  GroupMembershipAssociationDefinition,
  KinshipRelation,
  ModelType_TargetedByRelationMultipleFields,
  ModelType_TargetedByRelationSingleFields,
  NumberField,
  PlatformCapabilityAdmin,
  PlatformGroupCapability,
  RelationMultipleField,
  RelationMultipleField_ReverseField,
  RelationMultipleField_TargetType,
  RelationSingleField,
  RelationSingleField_TargetType,
  ReverseWorkflowField,
  ReverseWorkflowField_ReverseField,
  TimeseriesNumberField,
  User,
  User_IsEnabled,
  WorkflowField,
  WorkflowField_ReverseFields,
} from './ids';
import migrations from './migrations';
import type {
  AssociationFieldDefinitionStoreObject,
  AssociationFieldStoreObject,
  ConceptDefinitionStoreObject,
  ConceptRoleStoreObject,
  ConceptStoreObject,
  EmbeddingFieldStoreObject,
  FieldDimensionStoreObject,
  FieldStoreObject,
  RelationMultipleFieldStoreObject,
  RelationSingleFieldStoreObject,
  ReverseWorkflowFieldStoreObject,
  WorkflowFieldStoreObject,
} from './model';
import {
  adminOnlyAcl,
  canUserViewConcept,
  computeDenormalizedPermission,
  getKinshipFieldId,
  isAdminGroup,
  isCollaborationClosed,
  isConceptValid,
  isEmbeddedConceptInstance,
} from './utils';
import {
  getComputedPropertyId,
  registerPropertyFunctionForComputedField,
  registerPropertyFunctionForTimeseriesComputedField,
  unregisterPropertyFunctionForComputedField,
} from './utils/fieldValueResolver';

const hasPermission = (object: StoreObject): boolean => (
  !isEmbeddedConceptInstance(object) && object.navigate(Instance_Of)[ConceptDefinition_HasStakeholders] as boolean
);

const moduleDsl = createModule({ label: 'Concept' }, ConceptModuleIds);
const { initTypedModule, ...registerModelDsl } = moduleDsl;

export const { registerField } = createFieldDsl(moduleDsl);
export const registerModel = registerModelDsl;

export const hasConceptCapability = (
  objectStore: ObjectStoreReadOnly,
  origin: EventOrigin,
  objectId: string,
  newProperties: Record<string, unknown> | null | undefined,
  capability: string,
  delegateAction: string,
  readAcl: ReadAcl
): AccessControlListResult | undefined => {
  const { getObject, getObjectOrNull } = objectStore;
  const conceptInstance = getObjectOrNull(objectId);
  const { userId } = origin;
  if (!userId) {
    return undefined;
  }

  if (capability === ConceptCapabilityEdit && newProperties && Object.entries(newProperties).length === 1 && conceptInstance) {
    const [fieldId, targetedId] = Object.entries(newProperties)[0];
    if (fieldId) {
      const field = getObjectOrNull(fieldId);
      if (isInstanceOf<RelationSingleFieldStoreObject>(field, RelationSingleField)) {
        const targetedIdToCheck = targetedId as string | null ?? conceptInstance[fieldId] as string | undefined;
        if (targetedIdToCheck && readAcl('WRITE', targetedIdToCheck, origin)) {
          return {
            rule: 'relation.write.delegated',
            status: ValidationStatus.DELEGATED,
            targetId: [targetedIdToCheck],
            targetAction: 'WRITE',
          };
        }
      }
    }
  }

  const fromKinshipFieldId = conceptInstance?.[KinshipRelation] as string;
  const fromParentId = conceptInstance?.[fromKinshipFieldId] as string;
  const toKinshipFieldId = newProperties?.[KinshipRelation] as string;
  const toParentId = newProperties?.[toKinshipFieldId] as string;

  if (fromKinshipFieldId && getObjectOrNull(fromKinshipFieldId) && fromParentId && getObjectOrNull(fromParentId)) {
    if (capability === ConceptCapabilityAssignUser) {
      return { rule: 'concept.embedded.assignUser.reject', status: ValidationStatus.REJECTED };
    } else {
      return { rule: 'concept.delegatedToParent', status: ValidationStatus.DELEGATED, targetId: [fromParentId], targetAction: delegateAction };
    }
  }
  // Missing acl feature to delegate to multiple entries, we choose for now to first check the origin parent, then the to parent, but this does not protect us
  // from someone pushing children to a non-authorized parent if it already has a parent.
  if (toParentId && toKinshipFieldId && getObjectOrNull(toKinshipFieldId)) {
    if (capability === ConceptCapabilityAssignUser) {
      return { rule: 'concept.embedded.assignUser.reject', status: ValidationStatus.REJECTED };
    } else {
      return { rule: 'concept.delegatedToParent', status: ValidationStatus.DELEGATED, targetId: [toParentId], targetAction: delegateAction };
    }
  }

  const concepts = getObject(ConceptDefinition)
    .navigateBack(Class_Instances)
    .filter((conceptDef) => conceptDef[ConceptDefinition_HasStakeholders])
    .map((conceptDef) => conceptDef.id);
  if (!conceptInstance && [...concepts, ConceptDefinition].includes(newProperties?.[Instance_Of] as string)) {
    return { rule: 'concept.delegatedToCreation', status: ValidationStatus.DELEGATED, targetId: [newProperties?.[Instance_Of] as string], targetAction: 'CREATE' };
  }

  const conceptDefinitionId = conceptInstance?.[Instance_Of] as string ?? newProperties?.[Instance_Of] as string;
  const conceptDefinition = conceptDefinitionId && getObjectOrNull(conceptDefinitionId);
  if (conceptDefinition) {
    if (!conceptDefinition[ConceptDefinition_HasStakeholders]) {
      return { rule: 'concept.openBar', status: ValidationStatus.ACCEPTED };
    }
  }
  if (!conceptInstance && newProperties && ConceptCapabilityCreate === capability) {
    const permission = computeDenormalizedPermission(objectStore, userId, objectId, newProperties[Instance_Of] as string);
    if (permission && permission[capability]) {
      return { rule: 'concept.creation', status: ValidationStatus.ACCEPTED };
    }
  } else if (!conceptInstance && !newProperties) {
    return { rule: 'concept.noChanges', status: ValidationStatus.ACCEPTED };
  }

  if (conceptInstance) {
    const permission = computeDenormalizedPermission(objectStore, userId, conceptInstance.id, conceptInstance[Instance_Of] as string);
    if (permission && permission[capability] && isConceptValid(objectStore, conceptInstance.id)) {
      return { rule: `concept.${capability}.groupAssign`, status: ValidationStatus.ACCEPTED };
    }
  }

  return { rule: `concept.${capability}.forbidden`, status: ValidationStatus.REJECTED };
};

const getConceptDebugLabel = ({ getObjectOrNull }: ObjectStoreReadOnly): ObjectDebugLabelHandler => (objectId) => (
  richTextToText(getObjectOrNull(objectId)?.[Concept_Name] as RichText | undefined)
);

const getNumberOfActiveAdminUsers = (store: ObjectStoreReadOnly) => {
  const adminGroups = store.getObject(Group)
    .navigateBack(Instance_Of)
    .filter((group) => isAdminGroup(store, group.id));
  const adminUsers = adminGroups.flatMap((adminGroup) => store.withAssociation(Association)
    .withRole(Association_Role_Definition, GroupMembershipAssociationDefinition)
    .withRole(Association_Role_Role1TypeInstance, adminGroup.id)
    .list()
    .map((gm) => store.getObject(gm.role(Association_Role_Role2TypeInstance))));
  const activeAdminUsers = adminUsers.filter((user) => user[User_IsEnabled]);

  return activeAdminUsers.length;
};

const ensurePlatformHasAtLeastOneAdminAccess = (objectStore: ObjectStoreReadOnly): BusinessRuleHandler => (_, { id, properties }) => {
  const isUserRemovalFromGroup = id[0] === Association && id[1] === GroupMembershipAssociationDefinition && properties === null;
  const isUserEnabledSetToFalse = objectStore.getObjectOrNull(id[0]) && objectStore.getObject(id[0])[User_IsEnabled] === true && properties?.[User_IsEnabled] === false;
  const isAdminGroupDeletion = objectStore.getObjectOrNull(id[0])?.[Instance_Of] === Group && properties === null && isAdminGroup(objectStore, id[0]);
  const isPlatformCapabilityRemoval = id[0] === PlatformGroupCapability && id[2] === PlatformCapabilityAdmin && properties === null;
  if (isPlatformCapabilityRemoval) {
    const activeAdminsNumber = getNumberOfActiveAdminUsers(objectStore);
    const activeAdminsInCurrentGroup = objectStore.withAssociation(Association)
      .withRole(Association_Role_Definition, GroupMembershipAssociationDefinition)
      .withRole(Association_Role_Role1TypeInstance, id[1])
      .list()
      .map((gm) => objectStore.getObject(gm.role(Association_Role_Role2TypeInstance)))
      .filter((user) => user[User_IsEnabled])
      .length;
    if (activeAdminsNumber === activeAdminsInCurrentGroup) {
      return {
        rule: 'platform.shouldHaveAtLeastOneEnabledAdminUser',
        status: ValidationStatus.REJECTED,
      };
    }
  }
  if (isAdminGroupDeletion) {
    const activeAdminsNumber = getNumberOfActiveAdminUsers(objectStore);
    const activeAdminsInCurrentGroup = objectStore.withAssociation(Association)
      .withRole(Association_Role_Definition, GroupMembershipAssociationDefinition)
      .withRole(Association_Role_Role1TypeInstance, id[0])
      .list()
      .map((gm) => objectStore.getObject(gm.role(Association_Role_Role2TypeInstance)))
      .filter((user) => user[User_IsEnabled])
      .length;
    if (activeAdminsNumber === activeAdminsInCurrentGroup) {
      return {
        rule: 'platform.shouldHaveAtLeastOneEnabledAdminUser',
        status: ValidationStatus.REJECTED,
      };
    }
  }
  if (isUserRemovalFromGroup) {
    const groupIsAdmin = isAdminGroup(objectStore, id[2]);
    const deletedUserIsEnabled = objectStore.getObjectOrNull(id[3])?.[User_IsEnabled] === true;
    if (groupIsAdmin && getNumberOfActiveAdminUsers(objectStore) === 1 && deletedUserIsEnabled) {
      return {
        rule: 'platform.shouldHaveAtLeastOneEnabledAdminUser',
        status: ValidationStatus.REJECTED,
      };
    }
  }
  if (isUserEnabledSetToFalse) {
    const isAdminUser = objectStore.withAssociation(Association)
      .withRole(Association_Role_Definition, GroupMembershipAssociationDefinition)
      .withRole(Association_Role_Role2TypeInstance, id[0])
      .list()
      .map((gm) => objectStore.getObject(gm.role(Association_Role_Role1TypeInstance)))
      .some((group) => isAdminGroup(objectStore, group.id));
    if (isAdminUser && getNumberOfActiveAdminUsers(objectStore) === 1) {
      return {
        rule: 'platform.shouldHaveAtLeastOneEnabledAdminUser',
        status: ValidationStatus.REJECTED,
      };
    }
  }
  return undefined;
};

const invalidFieldDimensionsTypesGarbageRuleRegistration = (objectStore: ObjectStoreReadOnly): GarbageCollectorRulesLibraryRegistration => ({
  ruleName: 'invalidFieldDimensionsTypes',
  collect: ({ id, properties }) => {
    if (properties === null && typeof id === 'string') {
      const deletedObject = objectStore.getObjectOrNull(id);
      if (deletedObject && isInstanceOf<ConceptDefinitionStoreObject>(deletedObject, ConceptDefinition)) {
        return objectStore.withAssociation(FieldDimensionTypes).withRole(FieldDimensionTypes_Role_ConceptDefinition, id).listObjects().map(({ id: assocId }) => assocId);
      } else if (deletedObject && isInstanceOf<FieldDimensionStoreObject>(deletedObject, FieldDimension)) {
        return objectStore.withAssociation(FieldDimensionTypes).withRole(FieldDimensionTypes_Role_FieldDimension, id).listObjects().map(({ id: assocId }) => assocId);
      }
    }
    return [];
  },
  shouldDelete: (id) => {
    if (!Array.isArray(id)) {
      return false;
    }
    return id[0] === FieldDimensionTypes && (
      !objectStore.getObjectOrNull(id[FieldDimensionTypes_Role_FieldDimension + 1]) || !objectStore.getObjectOrNull(id[FieldDimensionTypes_Role_ConceptDefinition + 1])
    );
  },
});

const invalidConceptDefinitionLibraryTableGarbageRuleRegistration = (objectStore: ObjectStoreReadOnly): GarbageCollectorRulesLibraryRegistration => ({
  ruleName: 'invalidConceptDefinitionLibraryTable',
  collect: ({ id, properties }) => {
    if (properties === null && typeof id === 'string') {
      const deletedObject = objectStore.getObjectOrNull(id);
      if (deletedObject && isInstanceOf<ConceptDefinitionStoreObject>(deletedObject, ConceptDefinition)) {
        return objectStore.withAssociation(ConceptDefinitionLibraryTable)
          .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, id)
          .listObjects()
          .map(({ id: assocId }) => assocId);
      } else if (deletedObject && isInstanceOf<FieldStoreObject>(deletedObject, Field)) {
        return objectStore.withAssociation(ConceptDefinitionLibraryTable)
          .withRole(ConceptDefinitionLibraryTable_Role_Field, id)
          .listObjects()
          .map(({ id: assocId }) => assocId);
      }
    }
    return [];
  },
  shouldDelete: (id) => {
    if (!Array.isArray(id)) {
      return false;
    }
    return id[0] === ConceptDefinitionLibraryTable && (
      !objectStore.getObjectOrNull(id[ConceptDefinitionLibraryTable_Role_Field + 1]) || !objectStore.getObjectOrNull(id[ConceptDefinitionLibraryTable_Role_ConceptDefinition + 1])
    );
  },
});

const invalidFieldRelevantDisplayGarbageRuleRegistration = (objectStore: ObjectStoreReadOnly): GarbageCollectorRulesLibraryRegistration => ({
  ruleName: 'invalidFieldRelevantDisplay',
  collect: ({ id, properties }) => {
    if (properties === null && typeof id === 'string') {
      const deletedObject = objectStore.getObjectOrNull(id);
      if (deletedObject && isInstanceOf<ConceptDefinitionStoreObject>(deletedObject, ConceptDefinition)) {
        return objectStore.withAssociation(FieldRelevantFieldDisplay).withRole(FieldRelevantFieldDisplay_Role_ConceptDefinition, id).listObjects()
          .map(({ id: assocId }) => assocId);
      } else if (deletedObject && isInstanceOf<FieldStoreObject>(deletedObject, Field)) {
        return objectStore.withAssociation(FieldRelevantFieldDisplay).withRole(FieldRelevantFieldDisplay_Role_Field, id).listObjects().map(({ id: assocId }) => assocId);
      }
    }
    return [];
  },
  shouldDelete: (id) => {
    if (!Array.isArray(id)) {
      return false;
    }
    return id[0] === FieldRelevantFieldDisplay && (
      !objectStore.getObjectOrNull(id[FieldRelevantFieldDisplay_Role_Field + 1])
      || !objectStore.getObjectOrNull(id[FieldRelevantFieldDisplay_Role_ConceptDefinition + 1])
    );
  },
});

const invalidFieldDimensionsGarbageRuleRegistration = (objectStore: ObjectStoreReadOnly): GarbageCollectorRulesLibraryRegistration => ({
  ruleName: 'invalidFieldDimensions',
  collect: ({ id, properties }) => {
    if (properties === null) {
      if (typeof id === 'string') {
        const deletedObject = objectStore.getObjectOrNull(id);
        if (deletedObject && isInstanceOf<FieldStoreObject>(deletedObject, Field) && !deletedObject[Field_IsCore]) {
          return deletedObject.navigateBack(Field_FieldDimensions).map(({ id: fieldDimensionId }) => fieldDimensionId);
        }
      } else if (id[0] === FieldDimensionTypes) {
        return [id[FieldDimensionTypes_Role_FieldDimension + 1]];
      }
    }
    return [];
  },
  shouldDelete: (id) => {
    if (typeof id !== 'string') {
      return false;
    } else {
      const fieldDimension = objectStore.getObjectOrNull(id);
      if (fieldDimension && isInstanceOf<FieldDimensionStoreObject>(fieldDimension, FieldDimension)) {
        return !fieldDimension.navigateOrNull(FieldDimension_Field) || (
          !fieldDimension.navigate(FieldDimension_Field)[Field_IsCore]
          && objectStore.withAssociation(FieldDimensionTypes).withRole(FieldDimensionTypes_Role_FieldDimension, fieldDimension.id).list().length === 0
        );
      } else {
        return false;
      }
    }
  },
});

const invalidFieldGarbageRuleRegistration = (objectStore: ObjectStoreReadOnly): GarbageCollectorRulesLibraryRegistration => ({
  ruleName: 'invalidField',
  collect: ({ id, properties }) => {
    if (properties === null && typeof id === 'string') {
      const deletedObject = objectStore.getObjectOrNull(id);
      if (deletedObject) {
        if (isInstanceOf<FieldDimensionStoreObject>(deletedObject, FieldDimension)) {
          const field = deletedObject.navigateOrNull(FieldDimension_Field);
          return field && !field[Field_IsCore] ? [field.id] : [];
        } else if (isInstanceOf<WidgetStoreObject>(deletedObject, Widget)) {
          const field = deletedObject.navigateOrNull(Widget_Field);
          return field ? [field.id] : [];
        } else if (isInstanceOf<RelationMultipleFieldStoreObject>(deletedObject, RelationMultipleField)) {
          const reverseField = deletedObject.navigateOrNull(RelationMultipleField_ReverseField);
          return reverseField ? [reverseField.id] : [];
        } else if (isInstanceOf<RelationSingleFieldStoreObject>(deletedObject, RelationSingleField)) {
          return deletedObject.navigateBack(RelationMultipleField_ReverseField).map((field) => field.id);
        } else if (isInstanceOf<AssociationFieldStoreObject>(deletedObject, AssociationField)) {
          const associationFieldDefinition = deletedObject.navigateOrNull(AssociationField_Definition);
          return associationFieldDefinition ? [associationFieldDefinition.id] : [];
        } else if (isInstanceOf<AssociationFieldDefinitionStoreObject>(deletedObject, AssociationFieldDefinition)) {
          return deletedObject.navigateBack(AssociationFieldDefinition_Fields).map((field) => field.id);
        } else if (isInstanceOf<WorkflowFieldStoreObject>(deletedObject, WorkflowField)) {
          return deletedObject.navigateBack(WorkflowField_ReverseFields).map((field) => field.id);
        } else if (isInstanceOf<ReverseWorkflowFieldStoreObject>(deletedObject, ReverseWorkflowField)) {
          const reverseField = deletedObject.navigateOrNull(ReverseWorkflowField_ReverseField);
          return reverseField ? [reverseField.id] : [];
        } else if (isInstanceOf<ConceptDefinitionStoreObject>(deletedObject, ConceptDefinition)) {
          return [
            ...deletedObject.navigateBack(ModelType_TargetedByRelationMultipleFields).map((field) => field.id),
            ...deletedObject.navigateBack(ModelType_TargetedByRelationSingleFields).map((field) => field.id),
            ...deletedObject.navigateBack(EmbeddingField_ToType).map((field) => field.id),
            ...deletedObject.navigateBack(EmbeddingField_FromType).map((field) => field.id),
            ...deletedObject.navigateBack(AssociationFieldDefinition_Role1Type).map((field) => field.id),
            ...deletedObject.navigateBack(AssociationFieldDefinition_Role2Type).map((field) => field.id),
          ];
        }
        return deletedObject.navigateBack(KinshipRelation).map((embeddedConcept) => embeddedConcept.id);
      }
    }
    return [];
  },
  shouldDelete: (id) => {
    if (typeof id !== 'string') {
      return false;
    }
    const object = objectStore.getObject(id);
    if (isInstanceOf<AssociationFieldDefinitionStoreObject>(object, AssociationFieldDefinition)) {
      const associatedFields = object.navigateBack(AssociationFieldDefinition_Fields);
      const hasNoTargetType = object.navigateOrNull(AssociationFieldDefinition_Role2Type) === null;
      const hasNoSourceType = object.navigateOrNull(AssociationFieldDefinition_Role1Type) === null;
      return hasNoTargetType || hasNoSourceType || associatedFields.length === 0 || (associatedFields.length === 1 && !associatedFields[0][Field_Formula]);
    } else if (isInstanceOf<FieldStoreObject>(object, Field)) {
      if (object[Field_IsCore]) {
        return false;
      }
      // Delete field without dimensions (except computed fields) or without widget
      if (!object[Field_Formula] && object.navigateBack(Field_FieldDimensions).length === 0) {
        return object.navigateBack(Field_Widget).length === 0;
      } else if (isInstanceOf<RelationMultipleFieldStoreObject>(object, RelationMultipleField)) {
        const type = objectStore.getObjectOrNull(object[RelationMultipleField_TargetType]);
        const reverseField = object.navigateOrNull(RelationMultipleField_ReverseField);
        return !type || !reverseField;
      } else if (isInstanceOf<RelationSingleFieldStoreObject>(object, RelationSingleField)) {
        const type = objectStore.getObjectOrNull(object[RelationSingleField_TargetType]);
        const reverseField = object.navigateBack(RelationMultipleField_ReverseField);
        return !type || !reverseField.length;
      } else if (isInstanceOf<WorkflowFieldStoreObject>(object, WorkflowField)) {
        const reverseField = object.navigateBack(WorkflowField_ReverseFields);
        return reverseField.length === 0;
      } else if (isInstanceOf<ReverseWorkflowFieldStoreObject>(object, ReverseWorkflowField)) {
        const reverseField = object.navigateOrNull(ReverseWorkflowField_ReverseField);
        return reverseField === null;
      } else if (isInstanceOf<AssociationFieldStoreObject>(object, AssociationField)) {
        return !object.navigateOrNull(AssociationField_Definition);
      } else if (isInstanceOf<EmbeddingFieldStoreObject>(object, EmbeddingField)) {
        return !objectStore.getObjectOrNull(object[EmbeddingField_ToType]) || !objectStore.getObjectOrNull(object[EmbeddingField_FromType]);
      }
    }
    return false;
  },
});

const allowDeletionOfFieldAsProperty = (propertyId: string): BusinessRuleHandler => (_, { properties }) => {
  if (properties === null || properties?.[propertyId] === null) {
    return { rule: 'field.deletion.allow', status: ValidationStatus.ACCEPTED };
  }
  return undefined;
};

const acceptAnyPropertyDeletionOnConcepts: BusinessRuleRegistration = ({ getObjectOrNull, objectEntries }) => (_, { id, properties }) => {
  if (properties === undefined) {
    return undefined;
  }
  if (properties) {
    const propertyIds = Object.entries(properties)
      .filter(([, value]) => value === null)
      .map(([key]) => key);
    if (propertyIds.length > 0) {
      return {
        rule: 'concept.property.reset.accept',
        status: ValidationStatus.ACCEPTED,
        propertyIds,
      };
    }
    return undefined;
  } else {
    const currentObject = getObjectOrNull(id);
    if (currentObject) {
      return {
        rule: 'concept.deletion.accept',
        status: ValidationStatus.ACCEPTED,
        propertyIds: objectEntries(currentObject).map(([key]) => key),
      };
    }
  }
  return undefined;
};

export const initModule = initTypedModule(() => ({
  id: ConceptModuleId,
  migrations,
  registerGarbageCollectorRules: (objectStore, { register }) => {
    register(invalidFieldRelevantDisplayGarbageRuleRegistration(objectStore));
    register(invalidConceptDefinitionLibraryTableGarbageRuleRegistration(objectStore));
    register(invalidFieldGarbageRuleRegistration(objectStore));
    register(invalidFieldDimensionsGarbageRuleRegistration(objectStore));
    register(invalidFieldDimensionsTypesGarbageRuleRegistration(objectStore));
    register({
      ruleName: 'invalidAssociation',
      collect: ({ id, properties }) => {
        if (properties === null && typeof id === 'string') {
          const deletedObject = objectStore.getObjectOrNull(id);
          if (deletedObject && isInstanceOf<ConceptStoreObject>(deletedObject, Concept)) {
            return [
              ...objectStore.withAssociation(Association)
                .withRole(Association_Role_Role1TypeInstance, id)
                .listObjects()
                .map(({ id: associationId }) => associationId),
              ...objectStore.withAssociation(Association)
                .withRole(Association_Role_Role2TypeInstance, id)
                .listObjects()
                .map(({ id: associationId }) => associationId),
            ];
          } else if (deletedObject && isInstanceOf<AssociationFieldDefinitionStoreObject>(deletedObject, AssociationFieldDefinition)) {
            return objectStore.withAssociation(Association)
              .withRole(Association_Role_Definition, id)
              .listObjects()
              .map(({ id: associationId }) => associationId);
          }
        }
        return [];
      },
      shouldDelete: (id) => {
        if (!Array.isArray(id)) {
          return false;
        } else {
          return id[0] === Association
            && (
              objectStore.getObjectOrNull(id[Association_Role_Definition + 1]) === null
              || objectStore.getObjectOrNull(id[Association_Role_Role1TypeInstance + 1]) === null
              || objectStore.getObjectOrNull(id[Association_Role_Role2TypeInstance + 1]) === null
            );
        }
      },
    });
    register({
      ruleName: 'invalidConceptRoleUserAssignation',
      collect: ({ id, properties }) => {
        if (properties === null && typeof id === 'string') {
          const deletedObject = objectStore.getObjectOrNull(id);
          if (deletedObject && isInstanceOf<ConceptStoreObject>(deletedObject, Concept)) {
            return [
              ...objectStore.withAssociation(ConceptRoleUserAssignation)
                .withRole(ConceptRoleUserAssignation_Role_Concept, id)
                .listObjects()
                .map(({ id: associationId }) => associationId),
              ...objectStore.withAssociation(ConceptRoleUserAssignation)
                .withRole(ConceptRoleUserAssignation_Role_ConceptRole, id)
                .listObjects()
                .map(({ id: associationId }) => associationId),
              ...objectStore.withAssociation(ConceptRoleUserAssignation)
                .withRole(ConceptRoleUserAssignation_Role_User, id)
                .listObjects()
                .map(({ id: associationId }) => associationId),
            ];
          }
        }
        return [];
      },
      shouldDelete: (id) => {
        if (!Array.isArray(id)) {
          return false;
        } else {
          return id[0] === ConceptRoleUserAssignation
            && (
              objectStore.getObjectOrNull(id[ConceptRoleUserAssignation_Role_Concept + 1]) === null
              || objectStore.getObjectOrNull(id[ConceptRoleUserAssignation_Role_ConceptRole + 1]) === null
              || objectStore.getObjectOrNull(id[ConceptRoleUserAssignation_Role_User + 1]) === null
            );
        }
      },
    });
    register({
      ruleName: 'invalidConceptRoleGroupAssignation',
      collect: ({ id, properties }) => {
        if (properties === null && typeof id === 'string') {
          const deletedObject = objectStore.getObjectOrNull(id);
          if (deletedObject && isInstanceOf<ConceptStoreObject>(deletedObject, Concept)) {
            return [
              ...objectStore.withAssociation(ConceptRoleGroupAssignation)
                .withRole(ConceptRoleGroupAssignation_Role_Concept, id)
                .listObjects()
                .map(({ id: associationId }) => associationId),
              ...objectStore.withAssociation(ConceptRoleGroupAssignation)
                .withRole(ConceptRoleGroupAssignation_Role_ConceptRole, id)
                .listObjects()
                .map(({ id: associationId }) => associationId),
              ...objectStore.withAssociation(ConceptRoleGroupAssignation)
                .withRole(ConceptRoleGroupAssignation_Role_Group, id)
                .listObjects()
                .map(({ id: associationId }) => associationId),
            ];
          }
        }
        return [];
      },
      shouldDelete: (id) => {
        if (!Array.isArray(id)) {
          return false;
        } else {
          return id[0] === ConceptRoleGroupAssignation
            && (
              objectStore.getObjectOrNull(id[ConceptRoleGroupAssignation_Role_Concept + 1]) === null
              || objectStore.getObjectOrNull(id[ConceptRoleGroupAssignation_Role_ConceptRole + 1]) === null
              || objectStore.getObjectOrNull(id[ConceptRoleGroupAssignation_Role_Group + 1]) === null
            );
        }
      },
    });
    register({
      ruleName: 'invalidReverseWorkflowField',
      collect: ({ id, properties }) => {
        if (properties === null && typeof id === 'string') {
          const deletedObject = objectStore.getObjectOrNull(id);
          if (isInstanceOf<WorkflowFieldStoreObject>(deletedObject, WorkflowField)) {
            return deletedObject.navigateBack(WorkflowField_ReverseFields).map((reverseWorkflowField) => reverseWorkflowField.id);
          }
        }
        return [];
      },
      shouldDelete: (id) => {
        if (typeof id === 'string') {
          const object = objectStore.getObject(id);
          return isInstanceOf(object, ReverseWorkflowField) && object.navigateOrNull(ReverseWorkflowField_ReverseField) === null;
        } else {
          return false;
        }
      },
    });
  },
  registerBusinessRules: (objectStore, registerBusinessRules) => {
    registerCreationAndUpdateDateBusinessRules(objectStore, registerBusinessRules, Concept, Concept_CreatedAt, Concept_UpdatedAt);
    const { onObject, onProperty } = registerBusinessRules;
    onObject('*').validate(ensurePlatformHasAtLeastOneAdminAccess(objectStore));
    onObject('*').validate((_, { id }) => {
      if (id.length === 1 || !id[0].includes(':')) {
        return undefined;
      }
      const dimensions = id[0].split(':');
      if (dimensions.some((dimensionId) => !objectStore.getObjectOrNull(dimensionId))) {
        return { rule: 'concept.multidimensional.unknownDimensions', status: ValidationStatus.REJECTED };
      } else if (id.slice(1).some((conceptId) => !objectStore.getObjectOrNull(conceptId))) {
        return { rule: 'concept.multidimensional.unknownConcept', status: ValidationStatus.REJECTED };
      } else {
        return { rule: 'concept.multidimensional.valid', status: ValidationStatus.ACCEPTED };
      }
    });
    onObject(Field).register(([propertyId]) => onProperty(propertyId).validate(allowDeletionOfFieldAsProperty(propertyId)));
    onObject(Concept).validate(acceptAnyPropertyDeletionOnConcepts(objectStore));
  },
  registerAccessControlList: (objectStore, { onObject }) => {
    const MULTIDIMENSIONAL_CONCEPT = 'MULTIDIMENSIONAL_CONCEPT';
    onObject('*').allow('ASSIGN_STAKEHOLDERS', () => ({ rule: 'assignStakeholders.allow', status: ValidationStatus.ACCEPTED }));
    onObject('*').allow('ASSIGN_COLLABORATOR', () => ({ rule: 'assignCollaborator.allow', status: ValidationStatus.ACCEPTED }));

    onObject(ModelType).allow('WRITE', ({ userId }) => adminOnlyAcl(objectStore, userId, 'modelType', 'WRITE'));
    onObject(ModelProperty).allow('WRITE', ({ userId }) => adminOnlyAcl(objectStore, userId, 'modelProperty', 'WRITE'));
    onObject(ModelAssociation).allow('WRITE', ({ userId }) => adminOnlyAcl(objectStore, userId, 'modelAssociation', 'WRITE'));

    onObject(MULTIDIMENSIONAL_CONCEPT).allow('READ', (origin, id, __, readAcl) => {
      if (id.slice(1).every((i) => readAcl('READ', i, origin))) {
        return { rule: 'multidimensional.field', status: ValidationStatus.ACCEPTED };
      } else {
        return undefined;
      }
    });

    onObject(MULTIDIMENSIONAL_CONCEPT).allow('WRITE', (origin, id, __, readAcl) => {
      if (id.slice(1).some((i) => readAcl('WRITE', i, origin))) {
        return { rule: 'multidimensional.field', status: ValidationStatus.ACCEPTED };
      } else {
        return undefined;
      }
    });

    return {
      getACLLibraryKeys: ({ getObjectOrNull }, { id }) => {
        if (id.length === 1 || !id[0].includes(':')) {
          return [];
        } else {
          const ids = id[0].split(':');
          if (ids.every((i) => isInstanceOf<FieldDimensionStoreObject>(getObjectOrNull(i), FieldDimension))) {
            return [MULTIDIMENSIONAL_CONCEPT];
          } else {
            return [];
          }
        }
      },
    };
  },
  registerObjectDebugLabel: (objectStore, { onObject }) => {
    onObject(Concept).register(getConceptDebugLabel(objectStore));
  },
  registerMetadataGenerators: (objectStore) => ({
    invalidatedRightUsers: () => {
      const { getObject, getObjectOrNull, withAssociation } = objectStore;

      const createdConceptDefinitionsIds = new Set<string>();
      const createdConceptsIds = new Set<string>();
      const invalidatedUsersIds = new Set<string>();
      const usersCrossConceptsACLCheck: Record<string, Record<string, boolean>> = {};
      const addAccessRightToCheck = (userId: string, conceptId?: string, forceCanView?: boolean): void => {
        if (!conceptId) {
          invalidatedUsersIds.add(userId);
        } else if (!usersCrossConceptsACLCheck[userId]?.[conceptId] && !createdConceptsIds.has(conceptId) && !invalidatedUsersIds.has(userId)) {
          usersCrossConceptsACLCheck[userId] = joinObjects(
            usersCrossConceptsACLCheck[userId] ?? {},
            { [conceptId]: forceCanView || canUserViewConcept(objectStore, userId, conceptId) }
          );
        }
      };

      const getUsersIdsFromGroup = (groupId: string) => withAssociation(Association)
        .withRole(Association_Role_Definition, GroupMembershipAssociationDefinition)
        .withRole(Association_Role_Role1TypeInstance, groupId)
        .list()
        .map((gm) => gm.role(Association_Role_Role2TypeInstance));

      const getUsersIdsFromRole = (conceptId: string, roleId: string) => withAssociation(ConceptRoleUserAssignation)
        .withRole(ConceptRoleUserAssignation_Role_Concept, conceptId)
        .withRole(Association_Role_Role1TypeInstance, roleId)
        .list()
        .map((assignation) => assignation.role(ConceptRoleUserAssignation_Role_User));
      const getCollaborationContextsIds = (collaborationId: string) => withAssociation(CollaborationOnMultipleContext)
        .withRole(CollaborationOnMultipleContext_Role_Collaboration, collaborationId)
        .list()
        .filter((assoc) => assoc.varRoles(CollaborationOnMultipleContext_VarRoles_Contexts).length === 1)
        .map((assoc) => assoc.varRole(CollaborationOnMultipleContext_VarRoles_Contexts, 0));

      return {
        interceptUpdate: (id, { newProperties }) => {
          if (id.length === 1) {
            const object = getObjectOrNull(id[0]);
            if (createdConceptsIds.has(id[0]) || createdConceptDefinitionsIds.has(id[0])) { // don't care about created concept (no invalidation)
              return;
            }
            const typeId = (newProperties?.[Instance_Of] ?? object?.[Instance_Of]) as string | undefined;
            if (!typeId || !getObjectOrNull(typeId)) {
              return;
            }
            const objectType = getObject(typeId);
            if (!object) { // on object creation
              if (newProperties) {
                if (doExtends(objectType, Concept)) {
                  createdConceptsIds.add(id[0]);
                } else if (newProperties[Instance_Of] === ConceptDefinition) {
                  createdConceptDefinitionsIds.add(id[0]);
                }
              }
            } else if (
              isInstanceOf<ConceptStoreObject>(object, Concept)
              && isConceptValid(objectStore, id[0])
              && isEmbeddedConceptInstance(object)
              && newProperties?.[getKinshipFieldId(object)] !== undefined
            ) { // on parent change (!) works for parent change only (this not support new embedded concept, or new parent type)
              objectStore.getObject(User)
                .navigateBack(Class_Instances)
                .forEach((user) => {
                  addAccessRightToCheck(user.id, object.id);
                });
            } else if (isInstanceOf<ConceptRoleStoreObject>(object, ConceptRole) && newProperties === null) { // on concept role deletion
              objectStore.getObject(User)
                .navigateBack(Class_Instances)
                .forEach((user) => {
                  addAccessRightToCheck(user.id);
                });
            } else if (
              isInstanceOf<ConceptRoleStoreObject>(object, ConceptRole)
              && newProperties?.[ConceptRole_ForCollaboration] !== undefined
            ) { // on concept role for collaboration change
              if (!getObject(id[0]).navigate(ConceptRole_ConceptDefinition).navigateBack(ConceptDefinition_Roles)
                .filter((role) => role.id !== id[0])
                .some((role) => role[ConceptRole_ForCollaboration])) {
                objectStore.getObject(User)
                  .navigateBack(Class_Instances)
                  .forEach((user) => {
                    addAccessRightToCheck(user.id);
                  });
              }
            } else if (isInstanceOf<CollaborationStoreObject>(object, Collaboration) && (!newProperties || newProperties[Collaboration_Status])) {
              // on collaboration status update or deletion
              const collaboration = getObjectOrNull<CollaborationStoreObject>(id[0]);
              const collaborationClosedEntries = collaboration && collaboration[Collaboration_Intention]
                ? withAssociation(IntentionStatusEntries).withRole(IntentionStatusEntries_Role_Intention, collaboration[Collaboration_Intention])
                  .list()
                  .map((assoc) => assoc.role(IntentionStatusEntries_Role_Entry))
                : [];
              const isOpenCollaboration = collaboration && !isCollaborationClosed(objectStore, collaboration);
              const willBeOpenCollaboration = !newProperties ? false : !collaborationClosedEntries.includes(newProperties[Collaboration_Status] as string);
              if (isOpenCollaboration !== willBeOpenCollaboration) {
                withAssociation(CollaborationGroups).withRole(CollaborationGroups_Role_Collaboration, id[0]).list().forEach((assoc) => {
                  const usersIds = getUsersIdsFromGroup(assoc.role(CollaborationGroups_Role_Group));
                  getCollaborationContextsIds(id[0]).forEach((conceptId) => {
                    const concept = getObjectOrNull(conceptId);
                    if (concept && isInstanceOf<ConceptStoreObject>(concept, Concept) && isConceptValid(objectStore, concept.id) && hasPermission(concept)) {
                      usersIds.forEach((userId) => {
                        addAccessRightToCheck(userId, conceptId);
                      });
                    }
                  });
                });
                withAssociation(CollaborationUsers).withRole(CollaborationUsers_Role_Collaboration, id[0]).list().forEach((assoc) => {
                  const userId = assoc.role(CollaborationUsers_Role_User);
                  getCollaborationContextsIds(id[0]).forEach((conceptId) => {
                    const concept = getObjectOrNull(conceptId);
                    if (concept && isInstanceOf<ConceptStoreObject>(concept, Concept) && isConceptValid(objectStore, concept.id) && hasPermission(concept)) {
                      addAccessRightToCheck(userId, conceptId);
                    }
                  });
                });
                withAssociation(CollaborationRoles).withRole(CollaborationRoles_Role_Collaboration, id[0]).list().forEach((assoc) => {
                  getCollaborationContextsIds(id[0]).forEach((conceptId) => {
                    const concept = getObjectOrNull(conceptId);
                    if (concept && isInstanceOf<ConceptStoreObject>(concept, Concept) && isConceptValid(objectStore, concept.id) && hasPermission(concept)) {
                      const usersIds = getUsersIdsFromRole(concept.id, assoc.role(CollaborationRoles_Role_Role));
                      usersIds.forEach((userId) => {
                        addAccessRightToCheck(userId, conceptId);
                      });
                    }
                  });
                });
              }
            }
          } else if (id[0] === ConceptRoleUserAssignation) { // on concept role user assignation or de-assignation
            const userId = id[ConceptRoleUserAssignation_Role_User + 1];
            const conceptId = id[ConceptRoleUserAssignation_Role_Concept + 1];
            addAccessRightToCheck(userId, conceptId);
          } else if (id[0] === ConceptRoleGroupAssignation) { // on concept role group assignation or de-assignation
            const usersIds = getUsersIdsFromGroup(id[ConceptRoleGroupAssignation_Role_Group + 1]);
            const conceptId = id[ConceptRoleGroupAssignation_Role_Concept + 1];
            usersIds.forEach((userId) => {
              addAccessRightToCheck(userId, conceptId);
            });
          } else if (id[0] === ConceptGroupCapability && id[ConceptGroupCapability_Role_ConceptCapability + 1] === ConceptCapabilityRead) {
            if (!createdConceptDefinitionsIds.has(id[ConceptGroupCapability_Role_ConceptDefinition + 1])) {
              // on concept definition role group assignation or de-assignation (on a concept that we did not just create)
              const usersIds = getUsersIdsFromGroup(id[ConceptGroupCapability_Role_ConceptGroup + 1]);
              // We choose to invalidate all users belonging to the group as it requires complex algo to handle every user specifically
              // This action is unusual enough to do that
              usersIds.forEach((userId) => {
                addAccessRightToCheck(userId);
              });
            }
          } else if (id[0] === CollaborationUsers) { // on collaboration role user assignation or de-assignation
            const collaborationId = id[CollaborationUsers_Role_Collaboration + 1];
            const collaboration = getObjectOrNull<CollaborationStoreObject>(collaborationId);
            const isOpenCollaboration = collaboration && !isCollaborationClosed(objectStore, collaboration);
            if (isOpenCollaboration) {
              const userId = id[CollaborationUsers_Role_User + 1];
              getCollaborationContextsIds(collaborationId).forEach((conceptId) => {
                const concept = getObjectOrNull(conceptId);
                if (concept && isInstanceOf<ConceptStoreObject>(concept, Concept) && isConceptValid(objectStore, concept.id) && hasPermission(concept)) {
                  addAccessRightToCheck(userId, conceptId);
                }
              });
            }
          } else if (id[0] === CollaborationGroups) { // on collaboration role group assignation or de-assignation
            const collaborationId = id[CollaborationGroups_Role_Collaboration + 1];
            const collaboration = getObjectOrNull<CollaborationStoreObject>(collaborationId);
            const isOpenCollaboration = collaboration && !isCollaborationClosed(objectStore, collaboration);
            if (isOpenCollaboration) {
              const usersIds = getUsersIdsFromGroup(id[CollaborationGroups_Role_Group + 1]);
              getCollaborationContextsIds(collaborationId).forEach((conceptId) => {
                const concept = getObjectOrNull(conceptId);
                if (concept && isInstanceOf<ConceptStoreObject>(concept, Concept) && isConceptValid(objectStore, concept.id) && hasPermission(concept)) {
                  usersIds.forEach((userId) => {
                    addAccessRightToCheck(userId, conceptId);
                  });
                }
              });
            }
          } else if (id[0] === Association && id[1] === GroupMembershipAssociationDefinition) {
            addAccessRightToCheck(id[Association_Role_Role2TypeInstance + 1]);
          }
        },
        generate: () => [
          ...Array.from(invalidatedUsersIds),
          ...Object.entries(usersCrossConceptsACLCheck)
            .filter(([userId]) => !invalidatedUsersIds.has(userId))
            .filter(([userId, conceptIdsReadRight]) => Object.entries(conceptIdsReadRight)
              .some(([conceptId, canRead]) => !createdConceptsIds.has(conceptId) && canUserViewConcept(objectStore, userId, conceptId) !== canRead))
            .map(([userId]) => userId),
        ],
      };
    },
  }),
  registerPropertyFunctionsWithTimeseries: (cacheObjectStore, { onObject }) => {
    const fieldRegistrationHandler: ObjectRegistrationHandler = (fieldId, properties) => {
      if (properties === null || properties[Field_Formula] === null) {
        // on field removal or field formulas unregister
        unregisterPropertyFunctionForComputedField(cacheObjectStore, fieldId);
      } else {
        registerPropertyFunctionForComputedField(cacheObjectStore, fieldId);
      }
    };
    onObject(NumberField).register(fieldRegistrationHandler);
    onObject(AssociationField).register(fieldRegistrationHandler);
    onObject(DateField).register(fieldRegistrationHandler);

    const timeseriesFieldRegistrationHandler: ObjectRegistrationHandler = (fieldId, properties) => {
      if (properties === null || properties[Field_Formula] === null) {
        // on field removal or field formulas reset
        cacheObjectStore.unregisterPropertyFunction(getComputedPropertyId(fieldId));
      } else if (properties[Field_Formula] !== null && properties[Field_Formula] !== undefined && cacheObjectStore.getObjectOrNull(fieldId)?.[Field_Formula] === undefined) {
        // on field creation/update with a field formulas. We do not register on field formulas update.
        registerPropertyFunctionForTimeseriesComputedField(cacheObjectStore, fieldId);
      }
    };
    onObject(TimeseriesNumberField).register(timeseriesFieldRegistrationHandler);
  },
}));

export const testables = { acceptAnyPropertyDeletionOnConcepts };
