import type { FunctionComponent, ReactNode } from 'react';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import type { IntentionStoreObject } from 'yooi-modules/modules/collaborationModule';
import {
  Intention,
  Intention_Available,
  IntentionConcepts,
  IntentionConcepts_Role_Intention,
} from 'yooi-modules/modules/collaborationModule/ids';
import { getParentConceptInstance } from 'yooi-modules/modules/conceptModule';
import { ConceptDefinition } from 'yooi-modules/modules/conceptModule/ids';
import { getUnreadNotificationsForUser } from 'yooi-modules/modules/notificationModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { joinObjects } from 'yooi-utils';
import { IconName } from '../../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../../components/atoms/IconOnlyButton';
import type { NavigationElements } from '../../../components/molecules/NavigationTitle';
import TopBarTemplate from '../../../components/templates/TopBarTemplate';
import useAuth from '../../../store/useAuth';
import type { FrontObjectStore } from '../../../store/useStore';
import useStore from '../../../store/useStore';
import { getSpacingAsNumber, Spacing } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import { remToPx } from '../../../utils/sizeUtils';
import type { NavigationLocation } from '../../../utils/useNavigation';
import { SessionStorageKeys, useSessionStorageState } from '../../../utils/useSessionStorage';
import { getNavigationElement, getNavigationElements } from '../navigationUtils';
import NotificationsPopover from '../notifications/NotificationsPopover';
import { collaborationAvailableFilter } from '../rightPanel/collaboration/utils/collaborationUtils';
import { share } from '../shareUtils';

interface TopBarProps {
  actions?: ReactNode,
}

const computeEmbeddingNavigationElements = (
  store: FrontObjectStore,
  navigationElements: NavigationElements
): NavigationElements => (
  navigationElements.map((element, index) => {
    const instance = store.getObjectOrNull(element.key);
    if (instance) {
      const parentInstance = getParentConceptInstance(instance);
      if (parentInstance && parentInstance.id === navigationElements[index - 1]?.key) {
        return (joinObjects(
          element,
          { isEmbedded: true }
        ));
      }
    }
    return (joinObjects(
      element,
      { isEmbedded: false }
    ));
  }) as NavigationElements
);

const TopBar: FunctionComponent<TopBarProps> = ({ actions }) => {
  const store = useStore();
  const { loggedUserId } = useAuth();
  const notificationsButtonRef = useRef<HTMLButtonElement>(null);
  const [isNotificationsPopoverOpened, setIsNotificationsPopoverOpened] = useSessionStorageState<boolean>(SessionStorageKeys.notifications, false);
  const unreadNotifications = getUnreadNotificationsForUser(store, loggedUserId).filter((notificationAssociation) => collaborationAvailableFilter(store, notificationAssociation));

  const location = useLocation() as NavigationLocation;
  const computedNavigationElements = getNavigationElements(store, location).map((el) => (getNavigationElement(store, el))) as NavigationElements;
  const extendedNavigationElements = computeEmbeddingNavigationElements(store, computedNavigationElements);
  const numberOfConcepts = store.getObject(ConceptDefinition).navigateBack<IntentionStoreObject>(Instance_Of).length;

  const isCollabActivated = store.getObject(Intention).navigateBack<IntentionStoreObject>(Instance_Of).some((intention) => {
    const intentionAvailableAssoc = store.withAssociation(IntentionConcepts)
      .withRole(IntentionConcepts_Role_Intention, intention.id)
      .list();
    return intention[Intention_Available] === 'all'
        || (intention[Intention_Available] === 'all_except' && intentionAvailableAssoc.length < numberOfConcepts)
        || (intention[Intention_Available] === 'only' && intentionAvailableAssoc.length > 0);
  });
  const notificationsButton: ReactNode = (
    <IconOnlyButton
      ref={notificationsButtonRef}
      tooltip={i18n`Notifications`}
      iconName={IconName.notifications}
      variant={IconOnlyButtonVariants.secondary}
      onClick={(event) => {
        event.preventDefault();
        setIsNotificationsPopoverOpened(!isNotificationsPopoverOpened);
      }}
      outOfBoundChip={unreadNotifications.length > 0}
    />
  );

  const notificationsOverlay: ReactNode | null = isNotificationsPopoverOpened ? (
    <NotificationsPopover
      anchorRef={notificationsButtonRef}
      onBackdropClick={(event) => {
        if (event.target instanceof Node && notificationsButtonRef.current?.contains(event.target)) {
          event.stopPropagation();
        }
        setIsNotificationsPopoverOpened(false);
      }}
      onClose={() => setIsNotificationsPopoverOpened(false)}
      placement="bottom-end"
      offset={[0, remToPx(getSpacingAsNumber(Spacing.xs))]}
    />
  ) : null;

  const notificationsActions: ReactNode = (
    <>
      {notificationsButton}
      {notificationsOverlay}
    </>
  );

  return (
    <TopBarTemplate
      navigationElements={extendedNavigationElements}
      actions={(
        <>
          {actions}
          {isCollabActivated && notificationsActions}
        </>
      )}
      onShare={() => share(store, { to: { pathname: location.pathname, hash: location.hash }, state: location.state })}
    />
  );
};

export default TopBar;
