import type { FunctionComponent } from 'react';
import type { ConditionFilterStoreObject, Filters } from 'yooi-modules/modules/conceptModule';
import { ConditionFilter_Filters } from 'yooi-modules/modules/conceptModule/ids';
import { joinObjects } from 'yooi-utils';
import ToggleButton from '../../../components/molecules/ToggleButton';
import useStore from '../../../store/useStore';
import { formatOrUndef } from '../../../utils/stringUtils';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import { getQuickFilterLabel } from './quickFiltersUtils';
import { FilterParams, useFilterStorage } from './useFilterSessionStorage';

interface ConditionFilterToggleProps {
  conditionFilterId: string,
  filterKey: string,
}

const ConditionFilterToggle: FunctionComponent<ConditionFilterToggleProps> = ({ conditionFilterId, filterKey }) => {
  const store = useStore();
  const [filterSessionStorageState = {}, setFilterSessionStorageState] = useFilterStorage(filterKey, FilterParams.filters);
  const filterFromSessionStorage = filterSessionStorageState[conditionFilterId];
  const conditionFilter = store.getObject<ConditionFilterStoreObject>(conditionFilterId);

  const isActive = Boolean(filterFromSessionStorage);

  return (
    <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
      <ToggleButton
        name={formatOrUndef(getQuickFilterLabel(store, conditionFilterId, []))}
        active={isActive}
        onClick={() => setFilterSessionStorageState((current) => {
          if (isActive) {
            const { [conditionFilterId]: activeFilter, ...otherFilters } = current ?? {};
            return otherFilters;
          } else {
            return joinObjects(
              current,
              { [conditionFilterId]: conditionFilter[ConditionFilter_Filters] as Filters }
            );
          }
        })}
      />
    </SizeContextProvider>
  );
};

export default ConditionFilterToggle;
