import classnames from 'classnames';
import type { FunctionComponent, ReactNode } from 'react';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import useBlockContext from '../../utils/useBlockContext';

const useStyles = makeStyles((theme) => ({
  hideOverflowX: {
    overflowX: 'hidden',
  },
  verticalFullWidthPadded: {
    gridColumnStart: 2,
    gridColumnEnd: 'last',
  },
  verticalFullWidth: {
    gridColumnStart: 1,
    gridColumnEnd: 'last',
  },
  horizontalFullWidthPadded: {
    gridColumnStart: 4,
    gridColumnEnd: 'last',
  },
  horizontalFullWidth: {
    gridColumnStart: 3,
    gridColumnEnd: 'last',
  },
  padded: {
    paddingLeft: spacingRem.s,
    borderLeftWidth: '0.1rem',
    borderLeftStyle: 'solid',
    borderLeftColor: theme.color.transparent,
  },
  action: {
    minWidth: spacingRem.blockLeftColumnSpacing,
    width: spacingRem.blockLeftColumnSpacing,
    display: 'flex',
  },
  compactAction: {
    marginRight: spacingRem.xs,
  },
}), 'blockContent');

interface BlockContentProps {
  action?: ReactNode,
  children?: ReactNode,
  fullWidth?: boolean,
  padded?: boolean,
  hideOverflowX?: boolean,
  compactAction?: boolean,
}

const BlockContent: FunctionComponent<BlockContentProps> = ({ action, children, fullWidth = false, padded = false, hideOverflowX = false, compactAction = false }) => {
  const classes = useStyles();

  const { isVertical } = useBlockContext();

  if (fullWidth) {
    return (
      <span
        className={classnames({
          [classes.padded]: padded,
          [classes.verticalFullWidthPadded]: isVertical && padded,
          [classes.verticalFullWidth]: isVertical && !padded,
          [classes.horizontalFullWidth]: !isVertical && !padded,
          [classes.horizontalFullWidthPadded]: !isVertical && padded,
        })}
      >
        {children}
      </span>
    );
  }

  return (
    <>
      {action ? (
        <span
          className={classnames({
            [classes.action]: true,
            [classes.compactAction]: compactAction,
          })}
          style={isVertical ? { gridColumnStart: 1 } : undefined}
        >
          {action}
        </span>
      ) : null}
      <span
        className={classnames({
          [classes.padded]: padded,
          [classes.hideOverflowX]: hideOverflowX,
        })}
        style={{ gridColumnStart: isVertical ? 2 : 4 }}
      >
        {children}
      </span>
    </>
  );
};

export default BlockContent;
