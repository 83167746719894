import type { TimeRange, TimeseriesUpdate } from 'yooi-store';
import { newError, sleep } from 'yooi-utils';
import { reportClientMetric, reportClientTrace } from '../utils/clientReporterUtils';
import { isNetworkCallSuccessful } from './networkClientUtils';
import type { NetworkTimeseriesClient } from './networkTimeseriesClient';

const MAX_RETRY = 5;

export interface TimeseriesSnapshotRequest {
  propertyId: string,
  timeseries: {
    objectId: string | string[],
    timeRange: TimeRange,
  }[],
}

export enum TimeseriesSnapshotStatus {
  complete = 'complete',
  partial = 'partial',
  abortedDueToMaxRetry = 'abortedDueToMaxRetry',
}

export interface TimeseriesSnaphsotHandler {
  downloadTimeseriesSnapshot: (requests: TimeseriesSnapshotRequest[]) => Promise<{ status: TimeseriesSnapshotStatus, updates: TimeseriesUpdate[] }>,
  resetRetry: () => void,
}

const createTimeseriesSnapshotHandler = (
  networkTimeseriesClient: NetworkTimeseriesClient,
  clientId: string
): TimeseriesSnaphsotHandler => {
  const { getTimeseriesPropertyValues } = networkTimeseriesClient;

  // {propertyId: numberOfRetry}
  const retryCountMap = new Map();
  let retryNumber = 0;

  const getTimeseriesSnapshots = async (requests: TimeseriesSnapshotRequest[]): Promise<{ status: TimeseriesSnapshotStatus, updates: TimeseriesUpdate[] }> => {
    let callFailCount = 0;
    if (retryNumber > 0) {
      // Wait between 1 and 3s to retry * retry Number
      await sleep(Math.round(1_000 + 2_000 * Math.random()));
    }
    const updates = await Promise.all(requests.map(async ({ propertyId, timeseries }) => {
      const result = await getTimeseriesPropertyValues({ timeseries, propertyId });
      if (!isNetworkCallSuccessful(result)) {
        const count = retryCountMap.get(propertyId) ?? 0;
        retryCountMap.set(propertyId, count + 1);
        callFailCount += 1;
        return [];
      } else {
        return result.answer.timeseries
          .map(({ propertyId: timeseriesPropertyId, objectId: timeseriesObjectId, timeRange: timeseriesTimeRange, data }) => ({
            id: timeseriesObjectId,
            propertyId: timeseriesPropertyId,
            values: data.map(({ time, value }) => ({ time, value })),
            timeRange: timeseriesTimeRange,
          }));
      }
    }));
    if (callFailCount === 0) {
      retryNumber = 0;
      return { status: TimeseriesSnapshotStatus.complete, updates: updates.flatMap((u) => u) };
    } else {
      retryNumber += 1;
      return { status: TimeseriesSnapshotStatus.partial, updates: updates.flatMap((u) => u) };
    }
  };

  return {
    downloadTimeseriesSnapshot: async (requests) => {
      if (retryNumber >= MAX_RETRY) {
        reportClientTrace(newError('Max retry when downloading timeseries', { clientId, retryCountMap: [...retryCountMap.entries()] }));
        return { status: TimeseriesSnapshotStatus.abortedDueToMaxRetry, updates: [] };
      } else {
        const startingSnapshot = new Date().getTime();
        const snapshot = await getTimeseriesSnapshots(requests);
        const endingSnapshot = new Date().getTime();
        reportClientMetric(clientId, 'timeseries-snapshot-generation', { duration: Math.round(endingSnapshot - startingSnapshot) });
        return snapshot;
      }
    },
    resetRetry: () => {
      retryNumber = 0;
    },
  };
};

export default createTimeseriesSnapshotHandler;
