import type { FunctionComponent, ReactElement } from 'react';
import type { DimensionsMapping } from 'yooi-modules/modules/conceptModule';
import {
  dimensionsMappingToParametersMapping,
  canWrite,
  createValuePathResolver,
  isFieldStep,
  isSingleValueResolution,
  ParsedDimensionType,
  parseDimensionMapping,
  timelineFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import type { DateFieldStoreValue, DateRange as DateRangeStoreValue } from 'yooi-utils';
import DateRange from '../../../../components/inputs/datePickers/DateRange';
import Chip from '../../../../components/molecules/Chip';
import useAcl from '../../../../store/useAcl';
import useStore from '../../../../store/useStore';
import useUpdateActivity from '../../../../store/useUpdateActivity';
import i18n from '../../../../utils/i18n';
import { getLastUpdateBy } from '../../historyUtils';
import InCompositeInput from '../../input/InCompositeInput';
import StoreDateRangeInput from '../../input/StoreDateRangeInput';
import { getChipOptions, getUnknownChip } from '../../modelTypeUtils';

interface TimelineFieldRendererProps {
  fieldId: string,
  dimensionsMapping: DimensionsMapping,
  readOnly: boolean,
  focusOnMount: boolean,
  value?: DateRangeStoreValue | undefined,
  onSubmit?: (value: DateRangeStoreValue | null) => void,
}

const TimelineFieldRenderer: FunctionComponent<TimelineFieldRendererProps> = ({ fieldId, dimensionsMapping, readOnly, focusOnMount, value, onSubmit }) => {
  const store = useStore();
  const { canWriteObject } = useAcl();

  const updateActivity = useUpdateActivity();

  const fieldHandler = timelineFieldHandler(store, fieldId);
  const configuration = fieldHandler.resolveConfiguration();

  const isReadOnly = readOnly || configuration.integrationOnly || !canWrite(dimensionsMapping, canWriteObject);

  let startConstraintValue: number | undefined;
  let startConstraint: ReactElement | undefined;

  const parsedDimensions = parseDimensionMapping(dimensionsMapping);
  let onEditionStart: (() => void) | undefined;
  let onEditionStop: (() => void) | undefined;
  let restingTooltip: (() => Promise<string>) | undefined;
  if (parsedDimensions.type === ParsedDimensionType.MonoDimensional) {
    const { objectId } = parsedDimensions;
    onEditionStart = () => updateActivity.onEnterEdition(objectId, fieldId);
    onEditionStop = () => updateActivity.onExitEdition(objectId, fieldId);
    restingTooltip = () => getLastUpdateBy(store, objectId, fieldId, undefined);
  }

  if (configuration.startConstraint) {
    const lastElement = configuration.startConstraint.at(-1);
    if (isFieldStep(lastElement)) {
      const valuePathResolver = createValuePathResolver(store, dimensionsMappingToParametersMapping(dimensionsMapping));
      const resolution = valuePathResolver.resolvePathValue<number | DateFieldStoreValue | undefined>(configuration.startConstraint);
      if (isSingleValueResolution(resolution)) {
        startConstraintValue = typeof resolution.value === 'number' || resolution.value === undefined ? resolution.value : resolution.value.date;
      }

      const chipOptions = getChipOptions(store, lastElement.fieldId) ?? getUnknownChip(lastElement.fieldId);
      startConstraint = (
        <Chip
          tooltip={chipOptions.tooltip}
          color={chipOptions.color}
          text={chipOptions.label}
          icon={chipOptions.icon}
        />
      );
    }
  }

  if (onSubmit) {
    return (
      <InCompositeInput
        initialValue={value}
        setInputValue={(newDates) => onSubmit(newDates)}
      >
        {(props) => (
          <DateRange
            readOnly={isReadOnly}
            placeholder={i18n`Add dates`}
            startConstraintChip={startConstraint}
            startConstraintValue={startConstraintValue}
            onEditionStart={onEditionStart}
            onEditionStop={onEditionStop}
            focusOnMount={focusOnMount}
            defaultPeriodicity={configuration.defaultPeriod}
            {...props}
          />
        )}
      </InCompositeInput>
    );
  } else {
    return (
      <StoreDateRangeInput
        initialValue={fieldHandler.getStoreValue(dimensionsMapping)}
        onSubmit={(date) => fieldHandler.updateValue(dimensionsMapping, date)}
        placeholder={i18n`Add dates`}
        readOnly={isReadOnly}
        defaultPeriodicity={configuration.defaultPeriod}
        startConstraintChip={startConstraint}
        startConstraintValue={startConstraintValue}
        onEditionStart={onEditionStart}
        onEditionStop={onEditionStop}
        focusOnMount={focusOnMount}
        restingTooltip={restingTooltip}
      />
    );
  }
};

export default TimelineFieldRenderer;
