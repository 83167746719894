import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type {
  AssociationFilterRaw,
  AssociationFilterStoreObject,
  ConditionFilterRaw,
  ConditionFilterStoreObject,
  FieldStoreObject,
  SingleParameterDefinition,
  ViewFilterStoreObject,
} from 'yooi-modules/modules/conceptModule';
import { dimensionsMappingToParametersMapping, getAllFieldDimensionsLinkedConceptDefinitionIds } from 'yooi-modules/modules/conceptModule';
import {
  AssociationFilter,
  AssociationFilter_Path,
  ConditionFilter,
  Field_Documentation,
  Field_IsDocumentationInline,
  Field_Title,
  ViewFilter_Rank,
  ViewFilter_ViewsField,
  ViewsField_ViewFilters,
} from 'yooi-modules/modules/conceptModule/ids';
import type { DashboardParameterStoreObject, ViewsFieldDefinition, ViewsFieldRaw, ViewsFieldStoreObject, WidgetStoreObject } from 'yooi-modules/modules/dashboardModule';
import {
  isGaugeViewDefinition,
  isLineChartViewDefinition,
  isStructuralBarChartViewDefinition,
  isTemporalBarChartViewDefinition,
  viewsFieldHandler,
} from 'yooi-modules/modules/dashboardModule';
import {
  Dashboard_Parameters,
  DashboardParameter_Label,
  DashboardParameter_Type,
  Field_Widget,
  ViewsField,
  ViewsField_Definition,
  Widget_Dashboard, Widget_Title,
} from 'yooi-modules/modules/dashboardModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStoreReadOnly } from 'yooi-store';
import { filterError, joinObjects, newError } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import i18n from '../../../../utils/i18n';
import { getLoggedUserParameterDefinition } from '../../filter/filterUtils';
import { QuickFilterType } from '../../filter/QuickFiltersEditor';
import { getViewTypeSummary } from '../../views/common/viewUtils';
import { getViewDefinitionHandler } from '../../views/viewDsl';
import { getDocumentationFieldEditionSection } from '../_global/editionHandlerUtils';
import type { WidgetDisplay } from '../_global/widgetUtils';
import { defaultWidgetDisplay, getWidgetDisplayTypeLabel, getWidgetEditionOptionSection } from '../_global/widgetUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  duplicateFieldDimensionWithNewField,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
  submitDimensionUpdate,
} from '../fieldDimensionUtils';
import type { FieldEditionOption, FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, GetFieldDefinitionHandler } from '../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldEditionVariant } from '../FieldLibraryTypes';
import { ViewLoadingStateContextProvider } from './useViewLoadingState';
import ViewSaveMessage from './ViewSaveMessage';
import ViewsFieldDefinitionOptions, { ViewsFieldDefinitionOptionsVariant } from './ViewsFieldDefinitionOptions';
import ViewsGroupBlock from './ViewsGroupBlock';
import ViewsGroupCell from './ViewsGroupCell';
import ViewsGroupWidget from './ViewsGroupWidget';

const DEFAULT_CONFIGURATION: ViewsFieldDefinition = {
  data: [],
  views: [],
};

interface ViewsFieldBlockDisplayOptions extends FieldBlockDisplayOptions {
  widgetDisplay?: WidgetDisplay,
}

const getDisplayOptions = (objectStore: ObjectStoreReadOnly, fieldBlockDisplayId: string): ViewsFieldBlockDisplayOptions => {
  const fieldBlockDisplay = objectStore.getObjectOrNull(fieldBlockDisplayId);
  if (fieldBlockDisplay?.[FieldBlockDisplay_FieldDisplayConfiguration]) {
    return fieldBlockDisplay[FieldBlockDisplay_FieldDisplayConfiguration] as ViewsFieldBlockDisplayOptions;
  } else {
    return defaultWidgetDisplay;
  }
};

export interface ViewsFieldConfigurationState {
  fieldId: string | undefined,
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
  [ViewsField_Definition]: ViewsFieldDefinition | null | undefined,
}

type GetViewsFieldDefinition = GetFieldDefinitionHandler<typeof viewsFieldHandler, ViewsFieldConfigurationState, never, ViewsFieldBlockDisplayOptions>;

export const viewsFieldDefinition: GetViewsFieldDefinition = registerFieldDefinition(viewsFieldHandler, {
  configuration: {
    typeIcon: IconName.layers,
    getTypeLabel: () => i18n`Views`,
    asWidget: true,
    getFieldTitle: (store) => (editionHandler) => {
      const viewsDefinition = editionHandler.getValue(ViewsField_Definition) ?? DEFAULT_CONFIGURATION;
      return i18n`Views (${getViewTypeSummary(viewsDefinition.views.map((v) => getViewDefinitionHandler(v).getDefinition(store, viewsDefinition.data)).filter(filterError))})`;
    },
    getEditionOptions: (store) => ({ mode, editionHandler, dashboardParameterDefinitions, readOnly, variant, isEdition }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode, FieldEditionOptionMode.Widget].includes(mode)) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      if ([FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        sections.push(getDocumentationFieldEditionSection(editionHandler));
      }

      const displayOptions: FieldEditionOption[] = [];

      const fieldEditionDimensions: FieldEditionDimensions = editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {};
      const parameterDefinitions: SingleParameterDefinition[] = [
        ...dashboardParameterDefinitions ?? [],
        ...Object.entries(fieldEditionDimensions).map(([id, { typeId }]): SingleParameterDefinition => ({ id, typeId, label: i18n`Dimension`, type: 'dimension' })),
        getLoggedUserParameterDefinition(),
      ];
      const viewsDefinition = editionHandler.getValue(ViewsField_Definition) ?? DEFAULT_CONFIGURATION;

      const data = viewsDefinition?.data;

      if (variant === FieldEditionVariant.composite && !isEdition) {
        displayOptions.push({
          key: 'View',
          title: ' ',
          hasValue: () => true,
          clearValue: () => {},
          type: EditionOptionTypes.custom,
          props: {
            render: () => (<ViewSaveMessage />),
          },
        });
      }

      const fieldId = editionHandler.getValue('fieldId');
      const field = fieldId ? store.getObjectOrNull(fieldId) : undefined;
      if (variant !== FieldEditionVariant.composite && field) {
        const widget = field.navigateBack<WidgetStoreObject>(Field_Widget)[0] as WidgetStoreObject | undefined;
        displayOptions.push({
          key: 'View',
          hasValue: () => Boolean(data?.length),
          clearValue: () => {},
          type: EditionOptionTypes.custom,
          skipBlockContent: true,
          isVertical: true,
          padded: true,
          props: {
            render: () => (
              <ViewsFieldDefinitionOptions
                variant={mode === FieldEditionOptionMode.Widget ? ViewsFieldDefinitionOptionsVariant.secondary : ViewsFieldDefinitionOptionsVariant.primary}
                viewsDefinition={viewsDefinition}
                updateViewsDefinition={(newDefinition) => editionHandler.updateValues({
                  [ViewsField_Definition]: newDefinition,
                })}
                getNavigationData={() => {
                  if (widget) {
                    return {
                      navigationKey: widget.id,
                      viewUrl: `/widget/${widget.id}`,
                    };
                  } else {
                    const conceptDefinitionId = getAllFieldDimensionsLinkedConceptDefinitionIds(store, field.id)[0];
                    if (conceptDefinitionId) {
                      return {
                        navigationKey: field.id,
                        viewUrl: `/settings/organization/${conceptDefinitionId}/field/${field.id}`,
                      };
                    }
                  }
                  return undefined;
                }}
                getQuickFilters={() => store.getObject(field.id).navigateBack<AssociationFilterStoreObject | ConditionFilterStoreObject>(ViewsField_ViewFilters)}
                onNewQuickFilter={(type, rank) => {
                  if (type === QuickFilterType.association) {
                    return store.createObject<AssociationFilterRaw>({
                      [Instance_Of]: AssociationFilter,
                      [ViewFilter_ViewsField]: editionHandler.getValue('fieldId'),
                      [ViewFilter_Rank]: rank,
                      [AssociationFilter_Path]: [],
                    });
                  } else {
                    return store.createObject<ConditionFilterRaw>({
                      [Instance_Of]: ConditionFilter,
                      [ViewFilter_ViewsField]: editionHandler.getValue('fieldId'),
                      [ViewFilter_Rank]: rank,
                    });
                  }
                }}
                readOnly={readOnly}
                parameterDefinitions={parameterDefinitions}
                isWidget={mode === FieldEditionOptionMode.Widget}
                widgetName={widget?.[Widget_Title]}
              />
            ),
          },
        });
      }

      if (displayOptions.length > 0) {
        sections.push({
          key: 'display',
          type: 'section',
          options: displayOptions,
        });
      }

      return sections;
    },
    isCreationEnabled: () => () => true,
    onWidgetCreate: (store) => () => store.createObject({
      [Instance_Of]: ViewsField,
      [ViewsField_Definition]: DEFAULT_CONFIGURATION,
    }),
    onCreate: (store) => (editionHandler) => {
      const fieldId = store.createObject<ViewsFieldRaw>({
        [Instance_Of]: ViewsField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [ViewsField_Definition]: editionHandler.getValue(ViewsField_Definition) ?? DEFAULT_CONFIGURATION,
      });
      linkFieldToFieldDimensions(store, fieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});
      return fieldId;
    },
    ofField: (store, fieldId) => ({
      getIcon: () => {
        const field = store.getObject<ViewsFieldStoreObject>(fieldId);
        const viewsField = field[ViewsField_Definition];
        if (viewsField?.views.length === 1) {
          return getViewDefinitionHandler(viewsField.views[0]).icon;
        } else {
          return IconName.layers;
        }
      },
      getInitialState: (conceptDefinitionId) => {
        const field = store.getObject<ViewsFieldStoreObject>(fieldId);
        return {
          [Field_Title]: field[Field_Title],
          fieldId: field.id,
          [Field_Documentation]: field[Field_Documentation],
          [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
          [ViewsField_Definition]: field[ViewsField_Definition],
          [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(store, fieldId, conceptDefinitionId),
        };
      },
      submitFieldUpdate: (stateToSubmit, conceptDefinitionId) => {
        store.updateObject(fieldId, {
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [ViewsField_Definition]: stateToSubmit[ViewsField_Definition],
        });
        submitDimensionUpdate(store, fieldId, conceptDefinitionId, stateToSubmit[FIELD_EDITION_DIMENSIONS] ?? {});
      },
      duplicateFieldDefinition: ({ parameterMap }) => {
        const fieldInstance = store.getObject<ViewsFieldStoreObject>(fieldId);

        const fieldDimensions = generateDuplicatedFieldDimensionId(store, fieldId);
        let newConfiguration = JSON.stringify(fieldInstance[ViewsField_Definition] ?? {});
        Object.entries(joinObjects(parameterMap ?? {}, fieldDimensions)).forEach(([pid, npid]) => {
          newConfiguration = newConfiguration.replaceAll(pid, npid);
        });

        const newFieldId = store.createObject({
          [Instance_Of]: fieldInstance[Instance_Of],
          [Field_Title]: `${fieldInstance[Field_Title]} (copy)`,
          [Field_Documentation]: fieldInstance[Field_Documentation],
          [Field_IsDocumentationInline]: fieldInstance[Field_IsDocumentationInline],
          [ViewsField_Definition]: JSON.parse(newConfiguration),
        });

        fieldInstance.navigateBack<ViewFilterStoreObject>(ViewsField_ViewFilters).forEach((oldFilter) => {
          store.createObject(joinObjects(
            oldFilter.asRawObject(),
            { [ViewFilter_ViewsField]: newFieldId }
          ));
        });

        duplicateFieldDimensionWithNewField(store, newFieldId, fieldDimensions);
        return newFieldId;
      },
    }),
  },
  renderField: (_, fieldId) => ({ dimensionsMapping }) => (<ViewsGroupCell fieldId={fieldId} parametersMapping={dimensionsMappingToParametersMapping(dimensionsMapping)} />),
  renderBlockField: (store, fieldId) => (dimensionsMapping, displayOptions, blockFieldProps) => {
    const viewsFieldDefinitionStoreObject = store.getObject<ViewsFieldStoreObject>(fieldId)[ViewsField_Definition];
    if (!viewsFieldDefinitionStoreObject) {
      throw newError(i18n`views definition is missing for widget`);
    }
    const { data: viewDimensions, views: viewDefinitions, hasFilters } = viewsFieldDefinitionStoreObject;
    return (
      <ViewLoadingStateContextProvider>
        <ViewsGroupBlock
          fieldId={fieldId}
          viewDimensions={viewDimensions}
          viewFilters={{
            filterKey: fieldId,
            hasFilters,
            getViewFilters: () => store.getObject<FieldStoreObject>(fieldId).navigateBack<AssociationFilterStoreObject | ConditionFilterStoreObject>(ViewsField_ViewFilters),
          }}
          viewDefinitions={viewDefinitions}
          layoutParametersMapping={dimensionsMappingToParametersMapping(dimensionsMapping)}
          blockFieldProps={blockFieldProps}
          widgetDisplay={displayOptions?.widgetDisplay ?? defaultWidgetDisplay}
        />
      </ViewLoadingStateContextProvider>
    );
  },
  getColumnDefinition: (store, fieldId) => {
    const viewsField = store.getObject<ViewsFieldStoreObject>(fieldId)[ViewsField_Definition];
    const view = (viewsField?.views.filter((v) => isLineChartViewDefinition(v) || isTemporalBarChartViewDefinition(v)))?.[0];
    if (view) {
      return (): ColumnDefinition => ({
        key: fieldId,
        propertyId: fieldId,
        sortable: false,
      });
    } else {
      return undefined;
    }
  },
  renderWidget: (store, fieldId) => ({ parametersMapping, isPreview }) => {
    const widget = store.getObject(fieldId).navigateBack<WidgetStoreObject>(Field_Widget)[0];
    const widgetDashBoard = widget[Widget_Dashboard];
    let dashboardParameterDefinitions: SingleParameterDefinition[] = [];
    if (widgetDashBoard) {
      dashboardParameterDefinitions = store.getObject(widgetDashBoard).navigateBack<DashboardParameterStoreObject>(Dashboard_Parameters)
        .filter((dashboardParameter) => Boolean(dashboardParameter[DashboardParameter_Type]))
        .map((dashboardParameter): SingleParameterDefinition => ({
          id: dashboardParameter.id,
          typeId: dashboardParameter[DashboardParameter_Type] as string,
          label: dashboardParameter[DashboardParameter_Label] ?? i18n`Parameter`,
          type: 'parameter',
        }));
    }
    const viewsField = store.getObject<ViewsFieldStoreObject>(fieldId);
    const viewsFieldDefinitionObject = viewsField[ViewsField_Definition];
    if (!viewsFieldDefinitionObject) {
      throw newError(i18n`views definition is missing for widget`);
    }
    const { data: viewDimensions, views: viewDefinitions, hasFilters } = viewsFieldDefinitionObject;
    return (
      <ViewLoadingStateContextProvider>
        <ViewsGroupWidget
          widgetId={widget.id}
          viewDimensions={viewDimensions}
          viewFilters={{
            filterKey: widget.id,
            hasFilters,
            getViewFilters: () => viewsField.navigateBack<AssociationFilterStoreObject | ConditionFilterStoreObject>(ViewsField_ViewFilters),
          }}
          viewDefinitions={viewDefinitions}
          parametersMapping={parametersMapping}
          parameterDefinitions={dashboardParameterDefinitions}
          isPreview={Boolean(isPreview)}
        />
      </ViewLoadingStateContextProvider>
    );
  },
  blockDisplayOptionsHandler: (store, fieldId) => (fieldBlockDisplayId) => {
    const field = store.getObject<ViewsFieldStoreObject>(fieldId);
    const viewsField = field[ViewsField_Definition];
    let displaySizeChartOption = true;
    if (viewsField) {
      displaySizeChartOption = viewsField.views.some((view) => (
        isLineChartViewDefinition(view) || isStructuralBarChartViewDefinition(view) || isTemporalBarChartViewDefinition(view) || isGaugeViewDefinition(view)
      ));
    }
    return ({
      getDisplayOptions: () => getDisplayOptions(store, fieldBlockDisplayId),
      renderSummary: (state) => [displaySizeChartOption ? getWidgetDisplayTypeLabel((state.widgetDisplay ?? defaultWidgetDisplay).type) : i18n`Widget`],
      getBlockEditionOptionSections: (state: ViewsFieldBlockDisplayOptions, setState: (newState: ViewsFieldBlockDisplayOptions) => void) => (displaySizeChartOption ? [
        getWidgetEditionOptionSection(
          state.widgetDisplay ?? defaultWidgetDisplay,
          (newWidgetDisplay) => setState(joinObjects(state, { widgetDisplay: newWidgetDisplay }))
        ),
      ] : []),
      onSubmit: (state) => {
        store.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
      },
    });
  },
});
