import classnames from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { joinObjects } from 'yooi-utils';
import base from '../../theme/base';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import useFilterPanelSessionStorage from '../../utils/useFilterPanelSessionStorage';
import { OverlayContextProvider } from '../../utils/useOverlayContainerRef';
import Icon, { IconColorVariant, IconName } from '../atoms/Icon';

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    position: 'relative',
    display: 'flex',
    flexShrink: 0,
    flexGrow: 0,
    alignItems: 'stretch',
    flexFlow: 'row nowrap',
  },
  floatingContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row-reverse',
    top: 0,
    left: 0,
    height: '100%',
    zIndex: 2,
  },
  contentAnimator: {
    display: 'flex',
    backgroundColor: theme.color.background.neutral.default,
    overflowX: 'hidden',
    borderRightWidth: '0.1rem',
    borderRightStyle: 'solid',
    borderRightColor: theme.color.border.default,
    boxShadow: 'rgba(0, 0, 0, 0.02) -0.4rem 0 0 0 inset',
  },
  content: {
    overflowY: 'visible',
    flexGrow: 1,
  },
  buttonHidden: {
    opacity: 0,
  },
  button: {
    width: '2.4rem',
    height: '2.4rem',
    borderRadius: base.borderRadius.circle,
    boxShadow: base.button.boxShadow,
    borderWidth: '0.1rem',
    borderStyle: 'solid',
    borderColor: theme.color.border.default,
    backgroundColor: theme.color.background.neutral.default,
    cursor: 'pointer',
    outline: 0,
    position: 'absolute',
    top: '3rem',
    right: '-1.2rem',
    transition: 'opacity 300ms cubic-bezier(0.2, 0, 0, 1) 0s',
    '&:hover': {
      backgroundColor: theme.color.background.neutral.subtle,
    },
    '&:focus': {
      backgroundColor: theme.color.background.neutral.subtle,
      borderColor: theme.color.border.default,
      opacity: '100%',
    },
  },
  buttonZone: {
    top: '-1rem',
    bottom: '-1rem',
    left: '-1rem',
    right: '-1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
}), 'leftPanel');

interface LeftPanelProps {
  width?: string | number,
  barWidth?: string,
  children: ReactElement | null,
}

const LeftPanel: FunctionComponent<LeftPanelProps> = ({ width = '34.9rem', barWidth = '2rem', children }) => {
  const classes = useStyles();

  const withAnimation = useRef(false);
  const renderNumber = useRef(0);
  renderNumber.current += 1;
  withAnimation.current = renderNumber.current > 1;

  const [filterPanel, setFilterPanel] = useFilterPanelSessionStorage();

  const [isOver, setOver] = useState(false);

  const spacerRef = useRef<HTMLDivElement>(null);
  const contentAnimatorRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const isPanelVisible = useRef(false);
  const displayTimeout = useRef<ReturnType<typeof setTimeout>>();
  const onOverTimeout = useRef<ReturnType<typeof setTimeout>>();

  const prepareAnimations = useCallback(() => {
    if (spacerRef.current) {
      spacerRef.current.style.transition = 'width 300ms cubic-bezier(0.2, 0, 0, 1) 0s';
    }
    if (contentAnimatorRef.current) {
      contentAnimatorRef.current.style.transition = 'width 300ms cubic-bezier(0.2, 0, 0, 1) 0s';
    }
  }, []);

  const showPanel = useCallback((isFloating: boolean) => {
    isPanelVisible.current = true;
    if (withAnimation.current) {
      prepareAnimations();
    }
    if (displayTimeout.current) {
      clearTimeout(displayTimeout.current);
      displayTimeout.current = undefined;
    }
    if (contentRef.current && contentAnimatorRef.current) {
      contentRef.current.style.display = 'flex';
      contentAnimatorRef.current.style.width = typeof width === 'number' ? `${width}px` : width;
      if (!isFloating && spacerRef.current) {
        spacerRef.current.style.width = typeof width === 'number' ? `${width}px` : width;
      }
    }
  }, [prepareAnimations, width]);

  const hidePanel = useCallback(() => {
    isPanelVisible.current = false;
    if (withAnimation.current) {
      prepareAnimations();
    }
    if (contentAnimatorRef.current) {
      contentAnimatorRef.current.style.width = '2rem';
    }
    if (spacerRef.current) {
      spacerRef.current.style.width = '2rem';
    }

    if (displayTimeout.current) {
      clearTimeout(displayTimeout.current);
    }
    displayTimeout.current = setTimeout(() => {
      if (contentRef.current) {
        contentRef.current.style.display = 'none';
      }
      displayTimeout.current = undefined;
    }, 200);
  }, [prepareAnimations, withAnimation]);

  const toggleOpen = useCallback(() => {
    if (filterPanel.open) {
      hidePanel();
      setFilterPanel(joinObjects(filterPanel, { open: false }));
    } else {
      showPanel(false);
      setFilterPanel(joinObjects(filterPanel, { open: true }));
    }
  }, [filterPanel, hidePanel, setFilterPanel, showPanel]);

  const onEnterPanel = useCallback(() => {
    setOver(true);
    if (!filterPanel.open && !onOverTimeout.current) {
      onOverTimeout.current = setTimeout(() => {
        showPanel(true);
        onOverTimeout.current = undefined;
      }, 300);
    }
  }, [filterPanel, showPanel]);

  const onLeavePanel = useCallback(() => {
    setOver(false);
    if (!filterPanel.open) {
      hidePanel();
    }
    if (onOverTimeout.current) {
      clearTimeout(onOverTimeout.current);
      onOverTimeout.current = undefined;
    }
  }, [hidePanel, filterPanel]);

  useEffect(() => {
    if (filterPanel.open) {
      showPanel(false);
    } else if (isPanelVisible.current && !isOver) {
      hidePanel();
    }
  });

  return (
    <div className={classes.panelContainer}>
      <div ref={spacerRef} style={{ width: barWidth }} />
      <div className={classes.floatingContainer} onMouseLeave={onLeavePanel}>
        <button
          type="button"
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          onKeyUp={(e) => {
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();
            toggleOpen();
          }}
          className={classnames({
            [classes.buttonHidden]: filterPanel.open && !isOver,
            [classes.button]: true,
          })}
          onMouseEnter={() => setOver(true)}
          title={filterPanel.open ? i18n`Close` : i18n`Open`}
          aria-label={filterPanel.open ? i18n`Close` : i18n`Open`}
        >
          <div className={classes.buttonZone}>
            <Icon name={filterPanel.open ? IconName.keyboard_arrow_left : IconName.keyboard_arrow_right} colorVariant={IconColorVariant.alternative} />
          </div>
        </button>

        <div ref={contentAnimatorRef} className={classes.contentAnimator} style={{ width: barWidth }} onMouseEnter={onEnterPanel}>
          <div ref={contentRef} className={classes.content} style={{ minWidth: width, display: filterPanel.open ? 'flex' : 'none' }}>
            <OverlayContextProvider containerRef={contentRef}>
              {children}
            </OverlayContextProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftPanel;
