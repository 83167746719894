import type {
  ConceptRaw,
  ConceptStoreObject,
  FieldStoreObject,
  ParametersMapping,
  RelationSingleFieldStoreObject,
  WorkflowEntryStoreObject,
  WorkflowFieldStoreObject,
  WorkflowTransitionStoreObject,
} from 'yooi-modules/modules/conceptModule';
import {
  associationFieldHandler,
  BLOCK_PARAMETER_CURRENT,
  createValuePathResolver, dimensionsMappingToParametersMapping,
  FILTER_PARAMETER_OPTION,
  getFieldDimensionOfModelType,
  getInstanceLabel,
  getInstanceLabelOrUndefined,
  getPathLastFieldInformation, getPathReturnedConceptDefinitionId,
  isFieldStep,
  isMappingStep,
  isMultiValueResolution,
  isSingleFieldResolution,
  isSingleValueResolution,
  relationMultipleFieldHandler,
  relationSingleFieldHandler,
  workflowFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import {
  AssociationField,
  Concept_SwimlaneRank,
  Field_Formula,
  Field_IntegrationOnly,
  KinshipRelationField,
  RelationMultipleField,
  RelationSingleField,
  Workflow_Transitions,
  WorkflowEntry,
  WorkflowEntry_Rank,
  WorkflowEntry_Role_Concept,
  WorkflowEntry_Role_Workflow,
  WorkflowField,
  WorkflowField_Workflow,
} from 'yooi-modules/modules/conceptModule/ids';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { ViewType } from 'yooi-modules/modules/dashboardModule';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { StoreObject } from 'yooi-store';
import { buildComparatorChain, compareArray, compareRank, compareString, extractAndCompareValue, filterNullOrUndefined, joinObjects, pushUndefinedToEnd } from 'yooi-utils';
import type { IconName } from '../../../../components/atoms/Icon';
import type { ACLHandler } from '../../../../store/useAcl';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { getFieldDefinitionHandler } from '../../fields/FieldLibrary';
import { getFieldFilterFunction, isFilteredField } from '../../fieldUtils';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { getViewFilterFunction } from '../../listFilterFunctions';
import { getWorkflowFieldFirstEntryId } from '../../workflow/workflowUtils';
import type { CardEntry, CardBooleanField, CardIconField, ColorColorField, ColorInstance } from '../cards/cardsViewResolution';
import { resolveBody, resolveColor, resolveBoolean, resolveHeader, resolveIcon } from '../cards/cardsViewResolution';
import { getPathLabel, getSeriesLabel } from '../common/series/viewWithSeriesFeatureUtils';
import { getCreateAndLinkOptions } from '../common/viewUtils';
import type { ConceptViewResolution } from '../common/viewWithConceptResolutionUtils';
import { resolveConcept } from '../common/viewWithConceptResolutionUtils';
import { getDimensionLabel } from '../data/dataResolution';
import type { ViewResolutionError } from '../viewResolutionUtils';
import { isResolutionError } from '../viewResolutionUtils';
import type { SwimlaneViewResolvedDefinition } from './swimlaneViewDefinitionHandler';

export interface SwimlaneViewResolution extends ConceptViewResolution {
  type: ViewType.Swimlane,
  filterFunction: (item: ConceptStoreObject) => boolean,
  getItemColumnValue: (item: ConceptStoreObject) => StoreObject | undefined,
  getItemGroupValue: (item: ConceptStoreObject, groupById: string) => StoreObject[],
  columns: (ConceptStoreObject | undefined)[],
  cardHeader: CardEntry[],
  cardBody: CardEntry[],
  cardColor: ColorInstance | ColorColorField | undefined,
  cardIcon: CardIconField | undefined,
  cardBoolean: CardBooleanField | undefined,
  groupBy: { id: string, fieldId?: string, label: string, icon: IconName | undefined, comparator: (a: StoreObject[], b: StoreObject[]) => number, isDefault: boolean }[],
  moveItem: (instanceId: string, rank: string, columnId: string | undefined, groupsIds: string[], groupById: string | undefined) => void,
  canDrop: (instanceId: string, columnId: string | undefined, groupIds: string[], groupById: string | undefined) => { result: false, message: string } | { result: true },
  getRelations: (instanceId: string) => string[],
  generateOnCreate: (columnId: string | undefined, groupIds: string[], groupById: string | undefined) => ((() => void) | undefined),
}

export const getSwimlaneViewColumns = (store: FrontObjectStore, viewDefinition: SwimlaneViewResolvedDefinition, conceptDefinitionId: string): {
  columns: (ConceptStoreObject | undefined)[],
  getItemColumnValue: (item: ConceptStoreObject) => (StoreObject | undefined),
  error?: string,
} => {
  let columns: (ConceptStoreObject | undefined)[] = [];
  let getItemColumnValue: (item: ConceptStoreObject) => (StoreObject | undefined) = () => undefined;

  const columnField = viewDefinition.columnBy.fieldId ? store.getObjectOrNull(viewDefinition.columnBy.fieldId) : undefined;
  if (!columnField) {
    return { columns, getItemColumnValue, error: i18n`Column by field is invalid` };
  }
  const columnDimension = columnField ? getFieldDimensionOfModelType(store, columnField?.id, conceptDefinitionId) : undefined;
  if (isInstanceOf<RelationSingleFieldStoreObject>(columnField, RelationSingleField)) {
    const handler = relationSingleFieldHandler(store, columnField.id);
    const targetType = handler.getTargetType?.();
    if (targetType) {
      columns = targetType.navigateBack<ConceptStoreObject>(Instance_Of)
        .sort(extractAndCompareValue((instance) => getInstanceLabelOrUndefined(store, instance), compareString));
    }
    getItemColumnValue = (item) => {
      if (columnDimension) {
        const fieldResolution = handler.getValueResolution({ [columnDimension]: item.id });
        return fieldResolution.value;
      }
      return undefined;
    };
  } else if (isInstanceOf<WorkflowFieldStoreObject>(columnField, WorkflowField)) {
    const mainWorkflowId = columnField[WorkflowField_Workflow];
    if (mainWorkflowId) {
      columns = store.withAssociation(WorkflowEntry)
        .withRole(WorkflowEntry_Role_Workflow, mainWorkflowId)
        .list<WorkflowEntryStoreObject>()
        .sort(extractAndCompareValue((entry) => entry.object[WorkflowEntry_Rank], compareRank))
        .map((entry) => entry.navigateRole<ConceptStoreObject>(WorkflowEntry_Role_Concept));
    }
    getItemColumnValue = (item) => {
      if (columnDimension) {
        const fieldResolution = workflowFieldHandler(store, columnField.id).getValueResolution({ [columnDimension]: item.id });
        return fieldResolution.value.value;
      }
      return undefined;
    };
  } else {
    return { columns, getItemColumnValue, error: i18n`Column by field is invalid` };
  }
  columns.push(undefined);
  return { columns, getItemColumnValue };
};

export const getSwimlaneViewGroupBy = (
  store: FrontObjectStore,
  viewDefinition: SwimlaneViewResolvedDefinition,
  viewDimensions: ViewDimension[],
  parametersMapping: ParametersMapping
): { id: string, fieldId?: string, label: string, icon: IconName | undefined, comparator: (a: StoreObject[], b: StoreObject[]) => number, isDefault: boolean }[] => {
  const dimensionId = viewDimensions.at(0)?.id;
  if (!dimensionId) {
    return [];
  }
  const groupBy: { id: string, fieldId?: string, label: string, icon: IconName | undefined, comparator: (a: StoreObject[], b: StoreObject[]) => number, isDefault: boolean }[] = [];
  for (let i = 0; i < viewDefinition.groupBy.length; i += 1) {
    const currentGroup = viewDefinition.groupBy[i];
    const { fieldId } = getPathLastFieldInformation(currentGroup.path) ?? {};
    const field = fieldId ? store.getObjectOrNull<FieldStoreObject>(fieldId) : undefined;
    if (field) {
      groupBy.push({
        id: currentGroup.id,
        fieldId: currentGroup.path.length === 3 ? fieldId : undefined,
        label: getPathLabel(store, currentGroup.label, i, currentGroup.path, viewDimensions, [...Object.keys(parametersMapping), dimensionId], i18n`Group By`),
        icon: getFieldDefinitionHandler(store, field[Instance_Of])?.typeIcon,
        comparator: (a, b) => {
          if (field[Instance_Of] === WorkflowField) {
            const mainWorkflowId = field[WorkflowField_Workflow] as string | undefined;
            const getRank = (list: StoreObject[]) => (
              mainWorkflowId && a.length === 1
                ? store.withAssociation(WorkflowEntry)
                  .withRole(WorkflowEntry_Role_Workflow, mainWorkflowId)
                  .withRole(WorkflowEntry_Role_Concept, list[0].id)
                  .getObjectOrNull<WorkflowEntryStoreObject>()?.[WorkflowEntry_Rank]
                : undefined
            );
            return compareRank(getRank(a), getRank(b));
          } else {
            const getValueLabelArray = (list: StoreObject[]) => list.map((instance) => getInstanceLabelOrUndefined(store, instance));
            return buildComparatorChain<string[]>([pushUndefinedToEnd, compareArray])(getValueLabelArray(a), getValueLabelArray(b));
          }
        },
        isDefault: currentGroup.isDefault,
      });
    }
  }
  return groupBy;
};

export const resolveSwimlaneView = (
  store: FrontObjectStore,
  viewDimensions: ViewDimension[],
  viewDefinition: SwimlaneViewResolvedDefinition,
  parametersMapping: ParametersMapping,
  aclHandler: ACLHandler,
  filterConfiguration: FilterConfiguration | undefined
): SwimlaneViewResolution | ViewResolutionError => {
  const conceptResolution = resolveConcept(store, viewDimensions, parametersMapping);
  if (isResolutionError(conceptResolution)) {
    return conceptResolution;
  }

  const dimensionId = viewDimensions[0].id;

  const columnField = viewDefinition.columnBy.fieldId ? store.getObjectOrNull(viewDefinition.columnBy.fieldId) : undefined;

  if (!columnField) {
    return { type: 'error', error: i18n`Column by field is invalid` };
  }
  const columnDimension = columnField ? getFieldDimensionOfModelType(store, columnField?.id, conceptResolution.conceptDefinitionId) : undefined;

  const { error, columns, getItemColumnValue } = getSwimlaneViewColumns(store, viewDefinition, conceptResolution.conceptDefinitionId);
  if (error) {
    return { type: 'error', error };
  }

  const getItemGroupValue = (item: ConceptStoreObject, groupById: string): ConceptStoreObject[] => {
    const groupByPath = viewDefinition.groupBy.find(({ id }) => id === groupById)?.path ?? [];
    const pathResolution = createValuePathResolver(store, joinObjects(parametersMapping, {
      [dimensionId]: { type: 'single', id: item.id },
    } as ParametersMapping)).resolvePathValue(groupByPath);
    if (isSingleValueResolution(pathResolution)) {
      return pathResolution.value !== undefined ? [pathResolution.value as ConceptStoreObject] : [];
    } else if (isMultiValueResolution(pathResolution)) {
      return (pathResolution.values as ConceptStoreObject[])
        .sort(extractAndCompareValue((instance) => getInstanceLabel(store, instance), buildComparatorChain([pushUndefinedToEnd, compareString])));
    }
    return [];
  };

  const hasSameGroupValue = (instance: ConceptStoreObject, groupById: string, groupsIds: string[]) => {
    const groupValue = getItemGroupValue(instance, groupById).map(({ id }) => id);
    return groupValue.length === groupsIds.length && groupValue.every((id) => groupsIds.includes(id));
  };

  const canDrop = (instanceId: string, columnId: string | undefined, groupsIds: string[], groupById: string | undefined): { result: false, message: string } | { result: true } => {
    const instance = store.getObjectOrNull<ConceptStoreObject>(instanceId);
    if (!instance) {
      return { result: false, message: i18n`Instance is invalid` };
    }

    const value = getItemColumnValue(instance)?.id;
    if (value !== columnId) {
      if (!columnDimension) {
        return { result: false, message: i18n`Field is invalid` };
      } else if (columnField[Instance_Of] === KinshipRelationField) {
        return { result: false, message: i18n`Embedding field is not editable` };
      } else if (columnField[Instance_Of] === WorkflowField && columnId === undefined) {
        return { result: false, message: i18n`Workflow cannot be empty` };
      } else if (columnField[Field_Formula] !== undefined) {
        return { result: false, message: i18n`Field is computed` };
      } else if (columnField[Field_IntegrationOnly]) {
        return { result: false, message: i18n`Field is not editable` };
      } else if (columnField[Instance_Of] === WorkflowField
        && !aclHandler.canOverrideWorkflow(instance.id)
        && columnField.navigate(WorkflowField_Workflow)
          .navigateBack<WorkflowTransitionStoreObject>(Workflow_Transitions)
          .length > 0) {
        return { result: false, message: i18n`Workflow cannot be overridden` };
      } else if (isFilteredField(store, columnField.id)) {
        const fieldFilter = getFieldFilterFunction(columnField, store);
        if (columnId && fieldFilter
          && !fieldFilter({
            [BLOCK_PARAMETER_CURRENT]: { type: 'single' as const, id: instance.id },
            [FILTER_PARAMETER_OPTION]: { type: 'single' as const, id: columnId },
          })) {
          return { result: false, message: i18n`Instance not authorised by filters on field.` };
        }
      }
    }

    if (groupById && !hasSameGroupValue(instance, groupById, groupsIds)) {
      const currentGroupByPath = viewDefinition.groupBy.find(({ id }) => id === groupById)?.path ?? [];
      const groupField = isFieldStep(currentGroupByPath[2]) ? store.getObjectOrNull(currentGroupByPath[2].fieldId) : undefined;
      if (currentGroupByPath.length !== 3 || !isMappingStep(currentGroupByPath[1]) || currentGroupByPath[1].mapping.id !== dimensionId) {
        return { result: false, message: i18n`Group path doesn't allow drag & drop` };
      } else if (groupField?.[Instance_Of] === KinshipRelationField) {
        return { result: false, message: i18n`Embedding field is not editable` };
      } else if (groupField?.[Instance_Of] === WorkflowField && groupsIds.length === 0) {
        return { result: false, message: i18n`Workflow cannot be empty` };
      } else if (groupField?.[Field_Formula] !== undefined) {
        return { result: false, message: i18n`Field is computed` };
      } else if (groupField?.[Field_IntegrationOnly]) {
        return { result: false, message: i18n`Field is not editable` };
      } else if (groupField?.[Instance_Of] === WorkflowField
        && !aclHandler.canOverrideWorkflow(instance.id)
        && groupField.navigate(WorkflowField_Workflow)
          .navigateBack<WorkflowTransitionStoreObject>(Workflow_Transitions)
          .length > 0) {
        return { result: false, message: i18n`Workflow cannot be overridden` };
      } else if (groupField && isFilteredField(store, groupField.id)) {
        const fieldFilter = getFieldFilterFunction(groupField, store);
        if (fieldFilter
          && !fieldFilter({
            [BLOCK_PARAMETER_CURRENT]: { type: 'single' as const, id: instance.id },
            [FILTER_PARAMETER_OPTION]: { type: 'single' as const, id: groupField.id },
          })) {
          return { result: false, message: i18n`Instance not authorised by filters on field.` };
        }
      }
    }

    return { result: true };
  };

  const moveItem = (instanceId: string, rank: string, columnId: string | undefined, groupsIds: string[], groupById: string | undefined) => {
    const instance = store.getObjectOrNull<ConceptStoreObject>(instanceId);
    if (!instance) {
      return;
    }

    if (instance[Concept_SwimlaneRank] !== rank) {
      store.updateObject<ConceptRaw>(instanceId, { [Concept_SwimlaneRank]: rank });
    }

    const value = getItemColumnValue(instance);
    if (columnDimension && value?.id !== columnId) {
      if (columnField[Instance_Of] === WorkflowField && columnId !== undefined) {
        workflowFieldHandler(store, columnField.id).updateValue({ [columnDimension]: instanceId }, { action: 'set', objectId: columnId });
      } else if (columnField[Instance_Of] === RelationSingleField) {
        relationSingleFieldHandler(store, columnField.id).updateValue({ [columnDimension]: instanceId }, columnId ?? null);
      }
    }
    if (groupById && !hasSameGroupValue(instance, groupById, groupsIds)) {
      const currentGroupByPath = viewDefinition.groupBy.find(({ id }) => id === groupById)?.path ?? [];
      const fieldResolution = createValuePathResolver(store, joinObjects(parametersMapping, {
        [dimensionId]: { type: 'single', id: instanceId },
      } as ParametersMapping)).resolvePathField(currentGroupByPath);
      if (isSingleFieldResolution(fieldResolution)) {
        const field = store.getObjectOrNull(fieldResolution.fieldId);
        switch (field?.[Instance_Of]) {
          case RelationSingleField: {
            if (groupsIds.length < 2) {
              relationSingleFieldHandler(store, field.id).updateValue(fieldResolution.dimensionsMapping ?? {}, groupsIds.at(0) ?? null);
            }
            break;
          }
          case WorkflowField: {
            if (groupsIds.length === 1) {
              workflowFieldHandler(store, field.id).updateValue(fieldResolution.dimensionsMapping ?? {}, { action: 'set', objectId: groupsIds[0] });
            }
            break;
          }
          case RelationMultipleField: {
            relationMultipleFieldHandler(store, field.id).updateValue(fieldResolution.dimensionsMapping ?? {}, { action: 'set', objectIds: groupsIds });
            break;
          }
          case AssociationField: {
            associationFieldHandler(store, field.id).updateValue(fieldResolution.dimensionsMapping ?? {}, { action: 'set', objectIds: groupsIds });
            break;
          }
        }
      }
    }
  };

  const getRelations = (instanceId: string): string[] => {
    const resolution = createValuePathResolver(store, joinObjects(parametersMapping, {
      [dimensionId]: { type: 'single', id: instanceId },
    } as ParametersMapping)).resolvePathValue(viewDefinition.dependencies);
    if (isMultiValueResolution(resolution)) {
      return (resolution.values as StoreObject[]).map(({ id }) => id);
    } else {
      return [];
    }
  };

  const { createOptions } = getCreateAndLinkOptions(store, aclHandler, parametersMapping, viewDimensions, [], () => true);
  const generateOnCreate = (columnId: string | undefined, groupsIds: string[], groupById: string | undefined) => {
    if (createOptions === undefined || !aclHandler.canCreateObject(createOptions.targetConceptDefinitionId)) {
      return undefined;
    }

    if (columnField[Field_Formula] !== undefined || columnField[Field_IntegrationOnly]) {
      return undefined;
    }

    if (columnField[Instance_Of] === WorkflowField) {
      const firstEntry = getWorkflowFieldFirstEntryId(store, columnField.id);
      if (firstEntry === undefined || columnId !== firstEntry) {
        return undefined;
      }
    }

    const groupByPath = viewDefinition.groupBy.find(({ id }) => id === groupById)?.path ?? [];
    const { fieldId, conceptDefinitionId } = getPathLastFieldInformation(groupByPath) ?? {};
    const groupByField = fieldId ? store.getObjectOrNull(fieldId) : undefined;

    if (groupsIds.length > 0 && groupById && groupByField && conceptDefinitionId) {
      if (groupByField[Field_Formula] !== undefined || groupByField[Field_IntegrationOnly]) {
        return undefined;
      }
      if (groupByField[Instance_Of] === RelationSingleField && groupsIds.length > 1) {
        return undefined;
      }
      if (groupByField[Instance_Of] === WorkflowField) {
        const firstEntry = getWorkflowFieldFirstEntryId(store, groupByField.id);
        if (groupsIds.length > 1 || firstEntry === undefined || groupsIds.at(0) !== firstEntry) {
          return undefined;
        }
      }
    }

    return () => {
      const createdInstanceId = createOptions.onCreate();
      if (columnId) {
        if (columnDimension !== undefined) {
          switch (columnField[Instance_Of]) {
            case WorkflowField: {
              workflowFieldHandler(store, columnField.id).updateValue({ [columnDimension]: createdInstanceId }, { action: 'set', objectId: columnId });
              break;
            }
            case RelationSingleField: {
              relationSingleFieldHandler(store, columnField.id).updateValue({ [columnDimension]: createdInstanceId }, columnId);
              break;
            }
          }
        }
        store.updateObject(createdInstanceId, { [columnField.id]: columnId });
      }
      if (groupsIds.length > 0 && groupById && groupByField && conceptDefinitionId) {
        const dimension = getFieldDimensionOfModelType(store, groupByField.id, conceptDefinitionId);
        if (dimension) {
          switch (groupByField[Instance_Of]) {
            case RelationSingleField: {
              if (groupsIds.length < 2) {
                relationSingleFieldHandler(store, groupByField.id).updateValue({ [dimension]: createdInstanceId }, groupsIds.at(0) ?? null);
              }
              break;
            }
            case WorkflowField: {
              if (groupsIds.length === 1) {
                workflowFieldHandler(store, groupByField.id).updateValue({ [dimension]: createdInstanceId }, { action: 'set', objectId: groupsIds[0] });
              }
              break;
            }
            case RelationMultipleField: {
              relationMultipleFieldHandler(store, groupByField.id).updateValue({ [dimension]: createdInstanceId }, { action: 'set', objectIds: groupsIds });
              break;
            }
            case AssociationField: {
              associationFieldHandler(store, groupByField.id).updateValue({ [dimension]: createdInstanceId }, { action: 'set', objectIds: groupsIds });
              break;
            }
          }
        }
      }
    };
  };

  const filterFunction = getViewFilterFunction(
    store,
    viewDimensions.map((dimension, index) => {
      const dimConceptDefinitionId = getPathReturnedConceptDefinitionId(store, dimension.path);
      return dimConceptDefinitionId ? {
        id: dimension.id,
        label: getDimensionLabel(store, dimension.label, index, dimension.path),
        typeId: dimConceptDefinitionId,
      } : undefined;
    }).filter(filterNullOrUndefined),
    filterConfiguration,
    parametersMapping
  );

  return joinObjects(
    conceptResolution,
    {
      type: ViewType.Swimlane,
      getItemColumnValue,
      getItemGroupValue,
      columns,
      cardHeader: resolveHeader(
        store,
        viewDefinition.cardHeader,
        (index, path) => getSeriesLabel(store, undefined, index, path, viewDimensions, Object.keys(parametersMapping))
      ),
      cardBody: resolveBody(
        store,
        viewDefinition.cardBody,
        (label, index, path) => getSeriesLabel(store, label, index, path, viewDimensions, Object.keys(parametersMapping))
      ),
      cardColor: resolveColor(store, viewDefinition.cardColor),
      cardIcon: resolveIcon(store, viewDefinition.cardIcon),
      cardBoolean: resolveBoolean(store, viewDefinition.cardBoolean),
      groupBy: getSwimlaneViewGroupBy(store, viewDefinition, viewDimensions, parametersMapping),
      moveItem,
      canDrop,
      getRelations,
      generateOnCreate,
      filterFunction: (item: StoreObject) => !filterFunction || filterFunction(dimensionsMappingToParametersMapping({ [dimensionId]: item.id })),
    } as const
  );
};
