import type { ConceptDefinitionLibraryTableRaw } from 'yooi-modules/modules/conceptLayoutModule';
import {
  Block,
  Block_ConceptDefinition,
  Block_Name,
  Block_Rank,
  Block_Type,
  BlockType_Tab,
  ConceptDefinitionLibraryTable,
  ConceptDefinitionLibraryTable_Rank,
  ConceptDefinitionLibraryTable_Role_ConceptDefinition,
  ConceptDefinitionLibraryTable_Role_Field,
  FieldBlockDisplay,
  FieldBlockDisplay_Block,
  FieldBlockDisplay_FieldPath,
  FieldBlockDisplay_Rank,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { ConceptDefinitionInstanceAdministrationColumnRaw } from 'yooi-modules/modules/conceptModule';
import { BLOCK_PARAMETER_CURRENT, createConceptRole, InstanceReferenceType, PathStepType } from 'yooi-modules/modules/conceptModule';
import {
  Concept,
  Concept_Name,
  ConceptCapabilityAssignCollaborator,
  ConceptCapabilityAssignUser,
  ConceptCapabilityCreate,
  ConceptCapabilityDelete,
  ConceptCapabilityEdit,
  ConceptCapabilityRead,
  ConceptChipDisplay,
  ConceptChipDisplay_Rank,
  ConceptChipDisplay_Role_ConceptDefinition,
  ConceptChipDisplay_Role_Field,
  ConceptCollaborationFieldDimension,
  ConceptCreatedAtDimension,
  ConceptDefinition,
  ConceptDefinition_ChipBackgroundColor,
  ConceptDefinition_LibraryItemPerPage,
  ConceptDefinition_Name,
  ConceptDefinition_ShowMasterDetail,
  ConceptDefinitionInstanceAdministrationColumn,
  ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition,
  ConceptDefinitionInstanceAdministrationColumn_Field,
  ConceptDefinitionInstanceAdministrationColumn_Rank,
  ConceptFunctionalIdDimension,
  ConceptGroupCapability,
  ConceptGroupCapability_Role_ConceptCapability,
  ConceptGroupCapability_Role_ConceptDefinition,
  ConceptGroupCapability_Role_ConceptGroup,
  ConceptNameDimension,
  ConceptStakeholdersGroupsDimension,
  ConceptStakeholdersRolesDimension,
  ConceptStakeholdersUsersDimension,
  ConceptUpdatedAtDimension,
  Field_Title,
  Group,
  Group_GrantedOnCreation,
  KinshipRelationDimension,
  TextField,
} from 'yooi-modules/modules/conceptModule/ids';
import { Class_Extend, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { ObjectStore } from 'yooi-store';
import { ranker } from 'yooi-utils';
import base from '../../theme/base';
import i18n from '../../utils/i18n';
import { createAndLinkFieldToConceptDefinitions, linkFieldDimensionToConceptDefinition } from './fields/fieldDimensionUtils';

export const createConceptDefinition = (store: ObjectStore, name: string | undefined): string => {
  const conceptDefinitionId = store.createObject({
    [Class_Extend]: Concept,
    [Instance_Of]: ConceptDefinition,
    [ConceptDefinition_Name]: name,
    [ConceptDefinition_ChipBackgroundColor]: base.color.gray['300'],
    [ConceptDefinition_LibraryItemPerPage]: 50,
    [ConceptDefinition_ShowMasterDetail]: true,
  });

  createConceptRole(store, conceptDefinitionId, 'Leader', true, false, [ConceptCapabilityEdit, ConceptCapabilityAssignUser, ConceptCapabilityDelete, ConceptCapabilityAssignCollaborator]);
  createConceptRole(store, conceptDefinitionId, 'Viewer', false, true, []);

  store.getObject(Group).navigateBack(Instance_Of).forEach((group) => {
    store.withAssociation(ConceptGroupCapability)
      .withRole(ConceptGroupCapability_Role_ConceptDefinition, conceptDefinitionId)
      .withRole(ConceptGroupCapability_Role_ConceptGroup, group.id)
      .withRole(ConceptGroupCapability_Role_ConceptCapability, ConceptCapabilityCreate)
      .updateObject({});
  });

  store.getObject(Group)
    .navigateBack(Instance_Of)
    .filter((group) => Boolean(group[Group_GrantedOnCreation]))
    .forEach((group) => {
      store.withAssociation(ConceptGroupCapability)
        .withRole(ConceptGroupCapability_Role_ConceptDefinition, conceptDefinitionId)
        .withRole(ConceptGroupCapability_Role_ConceptGroup, group.id)
        .withRole(ConceptGroupCapability_Role_ConceptCapability, ConceptCapabilityRead)
        .updateObject({});
    });

  const blockGenerateNextRank = ranker.createNextRankGenerator();
  const summaryBlock = store.createObject({
    [Instance_Of]: Block,
    [Block_ConceptDefinition]: conceptDefinitionId,
    [Block_Rank]: blockGenerateNextRank(),
    [Block_Type]: BlockType_Tab,
    [Block_Name]: i18n`Summary`,
  });
  const descriptionFieldInstanceId = store.createObject({
    [Instance_Of]: TextField,
    [Field_Title]: i18n`Description`,
  });

  createAndLinkFieldToConceptDefinitions(store, descriptionFieldInstanceId, [conceptDefinitionId]);
  [
    ConceptNameDimension,
    KinshipRelationDimension,
    ConceptCreatedAtDimension,
    ConceptCollaborationFieldDimension,
    ConceptUpdatedAtDimension,
    ConceptFunctionalIdDimension,
    ConceptStakeholdersRolesDimension,
    ConceptStakeholdersUsersDimension,
    ConceptStakeholdersGroupsDimension,
  ].forEach((fieldDimensionId) => {
    linkFieldDimensionToConceptDefinition(store, fieldDimensionId, conceptDefinitionId);
  });

  const libraryTableRanker = ranker.createNextRankGenerator();
  store.withAssociation(ConceptDefinitionLibraryTable)
    .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, conceptDefinitionId)
    .withRole(ConceptDefinitionLibraryTable_Role_Field, Concept_Name)
    .updateObject<ConceptDefinitionLibraryTableRaw>({ [ConceptDefinitionLibraryTable_Rank]: libraryTableRanker() });

  const administrationFieldRanker = ranker.createNextRankGenerator();
  store.createObject<ConceptDefinitionInstanceAdministrationColumnRaw>({
    [Instance_Of]: ConceptDefinitionInstanceAdministrationColumn,
    [ConceptDefinitionInstanceAdministrationColumn_ConceptDefinition]: conceptDefinitionId,
    [ConceptDefinitionInstanceAdministrationColumn_Field]: Concept_Name,
    [ConceptDefinitionInstanceAdministrationColumn_Rank]: administrationFieldRanker(),
  });

  store.withAssociation(ConceptChipDisplay)
    .withRole(ConceptChipDisplay_Role_ConceptDefinition, conceptDefinitionId)
    .withRole(ConceptChipDisplay_Role_Field, Concept_Name)
    .updateObject({ [ConceptChipDisplay_Rank]: ranker.createNextRankGenerator()() });

  const inBlockGenerateNextRank = ranker.createNextRankGenerator();

  const getFieldPath = (fieldId: string) => [{ type: PathStepType.dimension, conceptDefinitionId },
    { type: PathStepType.mapping, mapping: { id: BLOCK_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
    { type: PathStepType.field, fieldId }];

  store.createObject({
    [Instance_Of]: FieldBlockDisplay,
    [FieldBlockDisplay_Block]: summaryBlock,
    [FieldBlockDisplay_FieldPath]: getFieldPath(descriptionFieldInstanceId),
    [FieldBlockDisplay_Rank]: inBlockGenerateNextRank(),
  });

  return conceptDefinitionId;
};
