const SwimlaneConstants = {
  labelHeightRem: 2.6,
  entryMarginTopBottomRem: 0.4,
  parentBottomMarginRem: 0.6,
  xAxisHeightRem: 5,
  minColumnWidthRem: 24.5,
  minViewColumnWidthRem: 14.5,
  labelGapRem: 1.6,
  svgHeightMarginRem: 1.9,
  lineGapRem: 0.4,
  lineOffsetRem: 1,
  conceptWidthPaddingRem: 0.8,
  conceptHeightRem: 3.2,
  conceptGroupWidthOffsetRem: 2,
  conceptGapRem: 1,
  indicatorBox: { width: 2, height: 2.5, leftOffset: -3.3, topOffset: 1.6, topOffsetChild: 1.2 },
  activityWidthOffsetRem: 3.4,
  lineWidthRem: 0.1,
  conceptMarginRem: 0.2,
  labelToSwimlaneMargin: 1.2,
};

export default SwimlaneConstants;
