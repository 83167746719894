import type { FunctionComponent } from 'react';
import type { ParametersMapping, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import type { ViewDimension, ViewStoredDefinition, WidgetStoreObject } from 'yooi-modules/modules/dashboardModule';
import { Widget_DisplayMode, Widget_Title, Widget_TitleMode } from 'yooi-modules/modules/dashboardModule/ids';
import useStore from '../../../../store/useStore';
import { getSpacing, Spacing } from '../../../../theme/spacingDefinition';
import { remToPx } from '../../../../utils/sizeUtils';
import useWidgetSizeContext from '../../../../utils/useWidgetSizeContext';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import ViewsGroup from './ViewsGroup';

const containerPaddingTop = Spacing.splus;
const containerPaddingBottom = Spacing.xs;

interface ViewsGroupWidgetProps {
  widgetId: string,
  viewDimensions: ViewDimension[],
  viewFilters: ViewFilters,
  viewDefinitions: ViewStoredDefinition[],
  parametersMapping: ParametersMapping,
  parameterDefinitions: SingleParameterDefinition[],
  isPreview?: boolean,
}

const ViewsGroupWidget: FunctionComponent<ViewsGroupWidgetProps> = ({
  widgetId, viewDimensions, viewFilters, viewDefinitions, parametersMapping, parameterDefinitions, isPreview,
}) => {
  const store = useStore();
  const { width, height } = useWidgetSizeContext();

  const widget = store.getObject<WidgetStoreObject>(widgetId);
  const isBorderless = widget?.[Widget_DisplayMode]?.type === 'borderless';

  const displayTitle = widget[Widget_TitleMode] !== 'hide' && (widget[Widget_TitleMode] === 'always' || (widget[Widget_Title] !== undefined && widget[Widget_Title] !== ''));
  const isPaddedTop = !isBorderless || displayTitle;
  const containerPadding = (isPaddedTop ? remToPx(getSpacing(containerPaddingTop)) : 0) + remToPx(getSpacing(containerPaddingBottom));
  const widgetHeight = height - containerPadding;

  return (
    <ViewsGroup
      widgetId={widgetId}
      viewDimensions={viewDimensions}
      viewFilters={viewFilters}
      viewDefinitions={viewDefinitions}
      parametersMapping={parametersMapping}
      parameterDefinitions={parameterDefinitions}
      width={width}
      height={widgetHeight}
      isPreview={isPreview}
    />
  );
};

export default ViewsGroupWidget;
