const ENDPOINT_PATTERN = /^(?<prefix>.+\.)?s3[.-](?<region>[a-z0-9-]+)\./;
const REGION_PATTERN = /([a-zA-Z]+)-([a-zA-Z]+)-([0-9a-z]+)/;

/**
 * A URI wrapper that can parse out information about an S3 URI
 *
 * Directly taken from https://github.com/aws/aws-sdk-java/blob/master/aws-java-sdk-s3/src/main/java/com/amazonaws/services/s3/AmazonS3URI.java
 *
 * @param {String} rawUri - the URI to parse
 */
const parseAmazonS3URI = (rawUri: string) => {
  const uri = new URL(rawUri);

  if (uri.protocol === 's3:') {
    const isPath = true;
    const bucket = uri.host;
    if (!bucket) {
      return undefined;
    }
    let key;
    if (!uri.pathname || uri.pathname.length <= 1) {
      // s3://bucket or s3://bucket/
      key = '';
    } else {
      // s3://bucket/key
      // Remove the leading '/'.
      key = uri.pathname.substring(1);
    }
    try {
      key = decodeURIComponent(key);
    } catch (_) {
      return undefined;
    }
    return { key, bucket, isPath };
  } else if (uri.protocol === 'https:') {
    if (!uri.host) {
      return undefined;
    }
    const matches = uri.host.match(ENDPOINT_PATTERN);
    if (!matches) {
      return undefined;
    }
    const isPath = false;
    const matchingGroups = matches.groups;
    let bucket = '';
    let key = '';
    const { prefix, region } = matchingGroups ?? {};
    if (!prefix && uri.pathname && uri.pathname !== '/') {
      if (uri.pathname !== '/') {
        const index = uri.pathname.indexOf('/', 1);
        if (index === -1) {
          // https://s3.amazonaws.com/bucket
          bucket = uri.pathname.substring(1);
        } else if (index === uri.pathname.length - 1) {
          // https://s3.amazonaws.com/bucket/
          bucket = uri.pathname.substring(1, index);
        } else {
          // https://s3.amazonaws.com/bucket/key
          bucket = uri.pathname.substring(1, index);
          key = uri.pathname.substring(index + 1);
        }
      } else {
        return undefined;
      }
    } else {
      // Remove the trailing '.' from the prefix to get the bucket.
      bucket = prefix.substring(0, prefix.length - 1);

      if (uri.pathname && uri.pathname !== '/') {
        // Remove the leading '/'.
        key = uri.pathname.substring(1);
      }
    }
    const versionId = uri.searchParams.get('versionId') ?? undefined;
    if (!region || region === 'amazonaws' || !uri.host.match(REGION_PATTERN)) {
      return undefined;
    }
    try {
      key = decodeURIComponent(key);
    } catch (_) {
      return undefined;
    }
    return { key, bucket, region, versionId, isPath };
  } else {
    return undefined;
  }
};

const match = (url: string): {
  type: string,
  assetKey: { key: string, bucket: string, versionId: string | string[] | undefined },
  generateUrl: () => string | undefined,
} | undefined => {
  const parsedUrl = parseAmazonS3URI(url);
  if (parsedUrl) {
    const { key, bucket, region, versionId } = parsedUrl;
    if (bucket && key) {
      return {
        assetKey: {
          key,
          bucket,
          versionId,
        },
        generateUrl: () => (region ? `https://${encodeURIComponent(bucket)}.s3-${encodeURIComponent(region)}.amazonaws.com/${key}${versionId ? `?versionId=${versionId}` : ''}` : undefined),
        type: 'S3',
      };
    }
  }
  return undefined;
};

export default {
  name: 'S3',
  match,
};
