import type { FunctionComponent, ReactElement } from 'react';
import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { useLocation } from 'react-router-dom';
import type { ConceptDefinitionLibraryTableStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import {
  ConceptDefinitionLibraryTable,
  ConceptDefinitionLibraryTable_Rank,
  ConceptDefinitionLibraryTable_Role_ConceptDefinition,
  ConceptDefinitionLibraryTable_Role_Field,
  ConceptDefinitionLibraryTable_Width,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { ConceptStoreObject, GlobalFilter } from 'yooi-modules/modules/conceptModule';
import { displayInstanceFieldAsText, getFieldDimensionOfModelType, getFieldUtilsHandler, isConceptValid } from 'yooi-modules/modules/conceptModule';
import {
  Concept_FunctionalId,
  Concept_Name,
  ConceptDefinition_LibraryItemPerPage,
  ConceptDefinition_LibraryShowMatrix,
  ConceptDefinition_LibraryShowSwimlane,
  ConceptDefinition_LibraryShowTable,
  ConceptDefinition_LibraryShowTimeline,
  ConceptDefinition_TableGroupBy,
  ConceptRole,
  Field_IntegrationOnly,
  StakeholdersField,
} from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import type { StoreObject } from 'yooi-store';
import { compareRank, extractAndCompareValue, filterNullOrUndefined, joinObjects, newError } from 'yooi-utils';
import { ButtonVariant } from '../../../../components/atoms/Button';
import { IconName } from '../../../../components/atoms/Icon';
import Typo from '../../../../components/atoms/Typo';
import Chooser from '../../../../components/molecules/Chooser';
import type { InlineCreationInline, InlineCreationTransactional } from '../../../../components/molecules/inlineCreationTypes';
import SearchAndSelect from '../../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../../../components/molecules/Table';
import BlockContent from '../../../../components/templates/BlockContent';
import type { ColumnDefinition, GroupEntry, ItemEntry } from '../../../../components/templates/DataTable';
import type { Pagination } from '../../../../components/templates/PageSelector';
import useAcl from '../../../../store/useAcl';
import useAuth from '../../../../store/useAuth';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import type { SessionStorageHash } from '../../../../utils/historyUtils';
import { createHash } from '../../../../utils/historyUtils';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { useFocusNewLineNotify } from '../../../../utils/useNewLineFocus';
import { SessionStorageKeys, useSessionStorageState } from '../../../../utils/useSessionStorage';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../../utils/useSizeContext';
import useTheme from '../../../../utils/useTheme';
import { UsageContextProvider, UsageVariant } from '../../../../utils/useUsageContext';
import { buildEmbeddingFieldFilterId } from '../../conceptFilterIdUtils';
import ConceptListExportButton from '../../ConceptListExportButton';
import ConceptViewTopBar, { DisplayedLine } from '../../ConceptViewTopBar';
import type { Chip } from '../../fieldUtils';
import { getFieldColumnComparator } from '../../fieldUtils';
import { ConceptDefinitionFavoriteFiltersBar } from '../../filter/FavoriteFiltersBar';
import { composeFilters } from '../../filter/filterUtils';
import type { FilterConfiguration } from '../../filter/useFilterSessionStorage';
import { getNavigationFilter } from '../../FrontFilterRenderers';
import { getFieldGroupByHandler } from '../../groupByUtils';
import { getConceptFilterFunction, getConceptFilters } from '../../listFilterFunctions';
import { getChipOptions, getTableGroupByField, listGroupByFieldOptions } from '../../modelTypeUtils';
import type { TableConfiguration } from '../../sessionStorageTypes';
import useFilterAndSort from '../../useFilterAndSort';
import BlockField from '../_global/BlockField';
import { BlockFieldDisplayStatus } from '../_global/blockFieldUtils';
import ConceptTable from '../_global/ConceptTable';
import { dndManager } from '../_global/dndUtils';
import ConceptMatrix from '../_global/matrix/ConceptMatrix';
import ConceptSwimlane from '../_global/swimlane/ConceptSwimlane';
import ConceptTimeline from '../_global/timeline/ConceptTimeline';
import { getFieldHandler } from '../FieldLibrary';
import type { BlockFieldProps } from '../FieldLibraryTypes';

const useStyles = makeStyles({
  configurationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: spacingRem.s,
    marginBottom: spacingRem.xs,
  },
  filtersContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: spacingRem.s,
    marginBottom: spacingRem.xs,
  },
}, 'conceptTableBlockField');

interface ConceptTableBlockFieldProps {
  fieldId: string,
  conceptId: string,
  value: ConceptStoreObject[],
  error?: string,
  // Used when showing search and select (when onLink is set)
  getInlineCreation?: () => (InlineCreationInline | InlineCreationTransactional),
  // Used when directly creating instance (when only is not set)
  createNewInstance?: () => string,
  onLink?: (conceptId: string) => void,
  onUnlink?: (conceptId: string) => void,
  actions?: ReactElement | null,
  blockFieldProps: BlockFieldProps,
  globalFilter?: GlobalFilter,
  lineContext?: (item: StoreObject) => string[],
}

const ConceptTableBlockField: FunctionComponent<ConceptTableBlockFieldProps> = ({
  fieldId,
  conceptId,
  value,
  error,
  createNewInstance,
  blockFieldProps,
  lineContext,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();
  const { canWriteObject } = useAcl();
  const { loggedUserId } = useAuth();

  const field = store.getObject(fieldId);
  const isFieldReadOnly = blockFieldProps.readOnly || field[Field_IntegrationOnly] as boolean | undefined || false;
  const areFieldAndInstanceReadOnly = isFieldReadOnly || !canWriteObject(conceptId);

  const location = useLocation();

  const [displayedLine, setDisplayedLine] = useState<DisplayedLine | undefined>(undefined);

  const targetConceptDefinition = getFieldUtilsHandler(store, fieldId)?.getTargetType?.();

  if (!targetConceptDefinition) {
    throw newError('Target type is missing for field', { fieldId });
  }

  const displayedFields = store.withAssociation(ConceptDefinitionLibraryTable)
    .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, targetConceptDefinition.id)
    .list<ConceptDefinitionLibraryTableStoreObject>()
    .sort(extractAndCompareValue(({ object }) => object[ConceptDefinitionLibraryTable_Rank], compareRank))
    .map((conceptDefinitionLibraryTable) => {
      const columnFieldId = conceptDefinitionLibraryTable.role(ConceptDefinitionLibraryTable_Role_Field);
      return ({ fieldId: columnFieldId, dimensionId: getFieldDimensionOfModelType(store, columnFieldId, targetConceptDefinition.id) });
    })
    .filter((entry): entry is { fieldId: string, dimensionId: string } => Boolean(entry.dimensionId) && store.getObjectOrNull(entry.fieldId) !== null);

  const columnsDefinitions = displayedFields
    .map(({ fieldId: id, dimensionId }): ColumnDefinition<StoreObject> | undefined => {
      const displayedField = store.getObject(id);
      const fieldHandler = getFieldHandler(store, id);
      if (fieldHandler?.getColumnDefinition) {
        const width: number | string | undefined = store.withAssociation(ConceptDefinitionLibraryTable)
          .withRole(ConceptDefinitionLibraryTable_Role_ConceptDefinition, targetConceptDefinition.id)
          .withRole(ConceptDefinitionLibraryTable_Role_Field, id)
          .getObject<ConceptDefinitionLibraryTableStoreObject>()[ConceptDefinitionLibraryTable_Width];

        return joinObjects(
          fieldHandler.getColumnDefinition(),
          {
            width: width === undefined ? fieldHandler.estimatedColumnWidth?.(targetConceptDefinition.id) : width,
            cellRender: ((instance, focusOnMount) => (
              fieldHandler.renderField?.({
                dimensionsMapping: isInstanceOf(displayedField, StakeholdersField) ? { n1InstanceId: instance.id, n2InstanceId: conceptId } : { [dimensionId]: instance.id },
                readOnly: false,
                focusOnMount,
              }) ?? null
            )) satisfies ColumnDefinition<StoreObject>['cellRender'],
          } as const
        );
      } else {
        return undefined;
      }
    })
    .filter(filterNullOrUndefined);

  const filterId = buildEmbeddingFieldFilterId(targetConceptDefinition.id, fieldId);

  const [filtersConfiguration] = useSessionStorageState<FilterConfiguration | undefined>(filterId, undefined);

  const focusNewLineNotify = useFocusNewLineNotify();

  const hasTable = targetConceptDefinition[ConceptDefinition_LibraryShowTable];
  const hasTimeline = targetConceptDefinition[ConceptDefinition_LibraryShowTimeline];
  const hasMatrix = targetConceptDefinition[ConceptDefinition_LibraryShowMatrix];
  const hasSwimlane = targetConceptDefinition[ConceptDefinition_LibraryShowSwimlane];

  const [tableConfig, , updateTableConfig] = useSessionStorageState<TableConfiguration | undefined>(`${SessionStorageKeys.tableConfig}_${filterId}`, undefined);

  const filterFunctions: ((item: StoreObject) => boolean)[] = [];
  const conceptFilterFunction = getConceptFilterFunction(store, targetConceptDefinition.id, filtersConfiguration, loggedUserId);
  if (conceptFilterFunction) {
    filterFunctions.push(conceptFilterFunction);
  }

  const tableGroupByField = getTableGroupByField(store, targetConceptDefinition.id, tableConfig);
  const { generateGroupedList, generateGroupedPageList, doSort, sortCriteria, forceFollowingIds, forceShowId, generateList } = useFilterAndSort(
    filterId,
    value.filter((instance) => isConceptValid(store, instance.id)),
    filterFunctions.length > 0 ? (item) => filterFunctions.every((filterFunction) => filterFunction(item)) : undefined,
    {
      getComparatorHandler: getFieldColumnComparator(store),
      initial: displayedFields.some(({ fieldId: id }) => id === Concept_FunctionalId)
        ? { key: Concept_FunctionalId, direction: TableSortDirection.desc }
        : { key: Concept_Name, direction: TableSortDirection.asc },
    },
    tableGroupByField ? getFieldGroupByHandler(store, tableGroupByField.id) : undefined,
    [conceptId]
  );

  const itemPerPage = store.getObject(targetConceptDefinition.id)[ConceptDefinition_LibraryItemPerPage] as number | undefined;
  let data: { list: (ItemEntry<StoreObject> | GroupEntry)[], pagination?: Pagination | undefined };
  if (itemPerPage !== undefined) {
    data = generateGroupedPageList(itemPerPage);
  } else {
    data = generateGroupedList();
  }

  let onAdd: (() => (string | undefined)) | undefined;
  if (!areFieldAndInstanceReadOnly && targetConceptDefinition.id !== ConceptRole) {
    if (createNewInstance) {
      onAdd = () => {
        const instanceId = createNewInstance();
        forceShowId(instanceId);
        focusNewLineNotify(instanceId);
        return instanceId;
      };
    }
  }

  const addLabel = onAdd === undefined ? i18n`Create` : undefined;

  const navigationFilters = getNavigationFilter(
    store,
    fieldId,
    targetConceptDefinition.id,
    conceptId,
    composeFilters([getConceptFilters(store, targetConceptDefinition.id, filtersConfiguration)])
  );

  const viewTabs: { key: string, name: string, component: ReactElement, hash: string }[] = [];
  if (hasTable) {
    viewTabs.push({
      key: 'list',
      name: i18n`List`,
      component: (
        <>
          {displayedLine === DisplayedLine.options && (
            <BlockContent padded>
              <UsageContextProvider usageVariant={UsageVariant.inForm}>
                <div className={classes.configurationContainer}>
                  <SizeContextProvider sizeVariant={SizeVariant.small}>
                    <Typo maxLine={1} color={theme.color.text.secondary}>{i18n`Group by`}</Typo>
                    <SearchAndSelect<Chip>
                      clearable
                      computeOptions={() => listGroupByFieldOptions(store, targetConceptDefinition.id, true)}
                      selectedOption={tableGroupByField ? getChipOptions(store, tableGroupByField.id) : undefined}
                      onSelect={(groupBy) => {
                        updateTableConfig({ [ConceptDefinition_TableGroupBy]: groupBy?.id ?? null });
                      }}
                    />
                  </SizeContextProvider>
                </div>
              </UsageContextProvider>
            </BlockContent>
          )}
          {displayedLine === DisplayedLine.filters && (
            <BlockContent padded>
              <div className={classes.filtersContainer}>
                <ConceptDefinitionFavoriteFiltersBar filterKey={filterId} conceptDefinitionId={targetConceptDefinition.id} />
              </div>
            </BlockContent>
          )}
          <ConceptTable
            navigationFilters={navigationFilters}
            columnsDefinition={columnsDefinitions}
            readOnly={isFieldReadOnly}
            conceptDefinitionId={targetConceptDefinition.id}
            newItemTitle={addLabel}
            newItemIcon={IconName.add}
            newItemButtonVariant={ButtonVariant.tertiary}
            onNewItem={onAdd}
            doSort={doSort}
            sortCriteria={sortCriteria}
            forceFollowingIds={forceFollowingIds}
            forceShowId={forceShowId}
            lineContext={lineContext}
            list={data.list}
            pagination={data.pagination}
          />
        </>
      ),
      hash: '#list',
    });
  }
  if (hasTimeline) {
    viewTabs.push({
      key: 'timeline',
      name: i18n`Timeline`,
      component: (
        <ConceptTimeline
          filterKey={filterId}
          generateList={generateList}
          conceptDefinitionId={targetConceptDefinition.id}
          readOnly={isFieldReadOnly}
        />
      ),
      hash: '#timeline',
    });
  }
  if (hasSwimlane) {
    viewTabs.push({
      key: 'swimlane',
      name: i18n`Swimlane`,
      component: (
        <DndProvider manager={dndManager}>
          <ConceptSwimlane
            filterKey={filterId}
            generateList={() => generateList().list.map(({ item }) => item)}
            conceptDefinitionId={targetConceptDefinition.id}
            readOnly={isFieldReadOnly}
            displayedLine={displayedLine}
            fieldId={field?.id}
          />
        </DndProvider>
      ),
      hash: '#swimlane',
    });
  }
  if (hasMatrix) {
    viewTabs.push({
      key: 'matrix',
      name: i18n`Matrix`,
      component: (
        <ConceptMatrix
          filterKey={filterId}
          generateList={() => generateList().list.map(({ item }) => item)}
          conceptDefinitionId={targetConceptDefinition.id}
          displayedLine={displayedLine}
          readOnly={isFieldReadOnly}
        />
      ),
      hash: '#matrix',
    });
  }

  const [{ hash: tabState }, setTabState] = useSessionStorageState<SessionStorageHash>(`${filterId}-state-hash`, { hash: null });

  let currentIndex = 0;
  if (location.hash.split('_')[1]) {
    currentIndex = viewTabs.findIndex(({ hash }) => createHash(location.hash.split('_')[1]) === hash);
  } else if (tabState) {
    currentIndex = viewTabs.findIndex(({ hash }) => (tabState) === hash);
  }
  if (currentIndex === -1) {
    currentIndex = 0;
  }

  const handleViewChange = (selectedIndex: number | undefined) => {
    if (selectedIndex !== undefined) {
      setTabState({ hash: createHash(viewTabs[selectedIndex].hash) });
    }
  };

  const viewTabComponent = viewTabs[currentIndex]?.component;
  return (
    <BlockField
      fieldId={fieldId}
      {...blockFieldProps}
      errorTooltip={error ?? (viewTabComponent ? undefined : i18n`No view has been configured`)}
      rightActions={(
        <SpacingLine>
          {blockFieldProps.displayStatus !== BlockFieldDisplayStatus.hidden && (
            <ConceptViewTopBar
              filterKey={filterId}
              onFiltersClick={() => {
                setDisplayedLine((current) => (current !== DisplayedLine.filters ? DisplayedLine.filters : undefined));
              }}
              onOptionsClick={() => {
                setDisplayedLine((current) => (current !== DisplayedLine.options ? DisplayedLine.options : undefined));
              }}
              activeFilters={displayedLine === DisplayedLine.filters}
              activeOptions={displayedLine === DisplayedLine.options}
              renderExportButton={() => (
                <SizeContextProvider sizeVariant={SizeVariant.small} hierarchyVariant={HierarchyVariant.content}>
                  <ConceptListExportButton
                    typeId={targetConceptDefinition.id}
                    generateList={() => generateList().list.map(({ item }) => item)}
                    displayedFieldsIds={displayedFields.map(({ fieldId: id }) => id)}
                    parentName={displayInstanceFieldAsText(store, conceptId, Concept_Name)}
                  />
                </SizeContextProvider>
              )}
            />
          )}
          {blockFieldProps.displayStatus !== BlockFieldDisplayStatus.hidden && viewTabs.length > 1 && (
            <>
              <div />
              <Chooser actions={viewTabs} onClick={handleViewChange} selectedIndexes={[currentIndex]} />
            </>
          )}
        </SpacingLine>
      )}
      isVertical
    >
      {viewTabComponent}
    </BlockField>
  );
};

export default ConceptTableBlockField;
