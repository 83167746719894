import type { FieldStoreObject, ParametersMapping } from 'yooi-modules/modules/conceptModule';
import { getPathLastFieldInformation } from 'yooi-modules/modules/conceptModule';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { ViewType } from 'yooi-modules/modules/dashboardModule';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { filterNullOrUndefined, joinObjects } from 'yooi-utils';
import type { ACLHandler } from '../../../../store/useAcl';
import type { FrontObjectStore } from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { formatErrorForUser } from '../../errorUtils';
import type { CreateAndLinkOptions } from '../common/viewUtils';
import { buildFilterFunctionFromDimensions, CreateAndLinkOptionsVariants, getCreateAndLinkOptions, removeFiltersFromDimensions } from '../common/viewUtils';
import { computeDimension, DataResolutionError, getDimensionLabel } from '../data/dataResolution';
import type { ViewResolutionError } from '../viewResolutionUtils';
import type { ChipViewResolvedDefinition } from './chipViewDefinitionHandler';
import { chipViewSupportedFieldTypeIds } from './chipViewUtils';

export interface ChipViewResolution extends CreateAndLinkOptions {
  type: ViewType.Chip,
  values: string[],
  fieldId: string,
  filterFunction: (id: string) => boolean,
}

export const resolveChipView = (
  store: FrontObjectStore,
  aclHandler: ACLHandler,
  viewDefinition: ChipViewResolvedDefinition,
  viewDimensions: ViewDimension[],
  parametersMapping: ParametersMapping,
  readOnly?: boolean
): ChipViewResolution | ViewResolutionError => {
  if (viewDimensions.length !== 1) {
    return { type: 'error', error: i18n`Invalid configuration. This view is not compatible with multiple dimension.` };
  }
  const dimensionPath = viewDimensions[0]?.path;
  if (!dimensionPath) {
    return { type: 'error', error: i18n`Invalid configuration. This view is not compatible with empty dimensions.` };
  }
  const lastFieldInformation = getPathLastFieldInformation(dimensionPath);
  if (!lastFieldInformation) {
    return {
      type: 'error',
      error: formatErrorForUser(
        store,
        new DataResolutionError(
          getDimensionLabel(store, viewDimensions[0].label, 0, dimensionPath),
          new Error(i18n`Invalid configuration. The data for this type of view must be the result of a relational field`)
        )
      ),
    };
  }

  const field = store.getObject<FieldStoreObject>(lastFieldInformation.fieldId);
  if (chipViewSupportedFieldTypeIds.some((typeId) => isInstanceOf(field, typeId))) {
    const filterFunction = buildFilterFunctionFromDimensions(store, viewDimensions, parametersMapping);
    const parsedViewDimensions = removeFiltersFromDimensions(viewDimensions);
    const result = computeDimension(store, parametersMapping).mapHandler(parsedViewDimensions[0], 0);
    if (result instanceof Error) {
      return {
        type: 'error',
        error: formatErrorForUser(
          store,
          new DataResolutionError(getDimensionLabel(store, parsedViewDimensions[0].label, 0, dimensionPath), result)
        ),
      };
    } else {
      const instances = result.instances.filter(filterNullOrUndefined);
      const idFilterFunction = (id: string) => filterFunction({ [viewDimensions[0].id]: { type: 'single', id } });
      return joinObjects(
        {
          type: ViewType.Chip,
          fieldId: lastFieldInformation.fieldId,
          values: instances.map(({ id }) => id),
          filterFunction: idFilterFunction,
        } satisfies ChipViewResolution,
        viewDefinition.readOnly || readOnly ? {}
          : getCreateAndLinkOptions(
            store,
            aclHandler,
            parametersMapping,
            parsedViewDimensions,
            instances.map(({ id }) => id),
            idFilterFunction,
            CreateAndLinkOptionsVariants.inlineCreationOnly
          )
      );
    }
  } else {
    return {
      type: 'error',
      error: formatErrorForUser(
        store,
        new DataResolutionError(
          getDimensionLabel(store, viewDimensions[0].label, 0, dimensionPath),
          new Error(i18n`Invalid configuration. The data for this type of view must be an association or embedded items on a single instance`)
        )
      ),
    };
  }
};
