import type { ObjectStoreReadOnly, ObjectStoreWithTimeseries, StoreObject, TimeRange } from 'yooi-store';
import type { FormulaType } from 'yooi-utils';
import { arrayOf, compareString, extractAndCompareValue, filterNullOrUndefined, fromError, isArrayFormulaType, joinObjects, newError } from 'yooi-utils';
import { UnsetDashboardParameterOption } from '../../../dashboardModule/ids';
import { isInstanceOf } from '../../../typeModule';
import { Instance_Of } from '../../../typeModule/ids';
import { WORKFLOW_FIELD_PARAMETER_SUB_FIELD_DIMENSION } from '../../fields/workflowField/constants';
import {
  Concept,
  Concept_GetFilterConditionContext,
  Concept_GetFilterFunction,
  Field,
  KinshipRelation,
  NumberField,
  StakeholdersField,
  Workflow_TargetedConceptDefinition,
  WorkflowField,
  WorkflowField_Workflow,
} from '../../ids';
import type { ConceptStoreObject, FieldStoreObject, StakeholdersFieldStoreObject, WorkflowFieldStoreObject } from '../../model';
import type { FieldStep, FilterCondition, Filters, FilterValue, PathStep } from '../../moduleType';
import { FilterConditionOperators, PathStepType } from '../../moduleType';
import { getAllFieldDimensionsIds, getFieldDimensionOfModelType } from '../fieldUtils';
import { getFieldUtilsHandler } from '../fieldUtilsHandler';
import type { BackendFilterCondition, BackendFilterConditions, FieldFilterConditions, InstanceReferenceValue } from '../filters/filters';
import { InstanceReferenceType, isFilterValuePath, isFilterValueRaw } from '../filters/filters';
import { FILTER_PARAMETER_CURRENT, getEndOfPathFieldStep, getInstanceFilterConditions, getMultipleInstanceFilterConditions, isFilterNode } from '../filters/filterUtils';
import { conceptType } from '../formula/modelFunctions';
import { rhythmicTimeseriesOf } from '../formula/timeseriesFunctions';
import type { DimensionsMapping, MultipleParameterValue, ParametersMapping, SingleParameterValue } from '../parametersUtils';
import { dimensionsMappingFromDimensionalIds, dimensionsMappingToParametersMapping, isParameter, segregateParametersMapping } from '../parametersUtils';
import { isMultipleRelationalType, isRelationalType } from '../relationFieldUtils';
import type { ResolutionStack } from '../resolutionStackUtils';
import type {
  DimensionResolution,
  FilterConditionExecutionContext,
  FilterFunction,
  MultiDimensionResolution,
  MultiFieldResolution,
  MultiValueResolution,
  SingleDimensionResolution,
  SingleFieldResolution,
  SingleValueResolution,
  StepResolutionErrorType,
} from './common';
import {
  DimensionResolutionType,
  FieldResolutionType,
  isGlobalDimensionResolution,
  isMultiValueResolution,
  isSingleDimensionResolution,
  isSingleValueResolution,
  ResolutionError,
  StepResolutionError,
  StepResolutionErrorStepType,
  ValueResolutionType,
} from './common';
import { isDimensionStep, isFieldStep, isFilterStep, isGlobalDimensionStep, isMappingStep, isMultipleMappingStep } from './pathUtils';

const getResolutionErrorType = (step: PathStep): StepResolutionErrorType => {
  if (isGlobalDimensionStep(step)) {
    return { type: StepResolutionErrorStepType.globalDimension };
  } else if (isDimensionStep(step)) {
    return { type: StepResolutionErrorStepType.dimension, conceptDefinitionId: step.conceptDefinitionId };
  } else if (isFilterStep(step)) {
    return { type: StepResolutionErrorStepType.filter };
  } else if (isMappingStep(step)) {
    return { type: StepResolutionErrorStepType.mapping };
  } else if (isFieldStep(step)) {
    return { type: StepResolutionErrorStepType.field, fieldId: step.fieldId };
  } else {
    return { type: StepResolutionErrorStepType.unknown };
  }
};

export interface GlobalDimensionResolution extends DimensionResolution {
  type: DimensionResolutionType.global,
}

const isValidPathResolution = (pathResolution: ResolvedStep[] | Error | undefined): pathResolution is ResolvedStep[] => (
  !(pathResolution instanceof Error) && Array.isArray(pathResolution)
);

interface PreviousResolvedDimensionsStep {
  step: PathStep,
  dimensionResolution: SingleDimensionResolution | MultiDimensionResolution | GlobalDimensionResolution,
}

interface ResolvedStep {
  dimensionResolution?: SingleDimensionResolution | MultiDimensionResolution | GlobalDimensionResolution,
  valueResolution?: SingleValueResolution | MultiValueResolution,
  fieldResolution?: SingleFieldResolution | MultiFieldResolution,
}

interface ValuePathResolver {
  resolvePathValue: <T = unknown>(
    valuePath: PathStep[], resolutionStack?: ResolutionStack, dateRange?: TimeRange, isTimeseriesValue?: boolean
  ) => SingleValueResolution<T> | MultiValueResolution<T> | StepResolutionError | Error | undefined,
  resolvePathField: (valuePath: PathStep[]) => SingleFieldResolution | MultiFieldResolution | StepResolutionError | Error | undefined,
  resolvePathDimension: (valuePath: PathStep[]) => SingleDimensionResolution | MultiDimensionResolution | GlobalDimensionResolution | StepResolutionError | Error | undefined,
  resolvePathStackValue: (valuePath: PathStep[]) => { compiledPath: PathStep[], pathResolution: ResolvedStep[] | StepResolutionError | Error | undefined },
}

export const compilePath = (store: ObjectStoreReadOnly, valuePath: PathStep[]): PathStep[] => (
  valuePath.reduce<PathStep[]>((accumulator, step) => {
    if (isFieldStep(step) && isInstanceOf(store.getObjectOrNull(step.fieldId), StakeholdersField)) {
      const prevStep = accumulator.at(-1);
      const prev2Step = accumulator.at(-2);
      const prev3Step = accumulator.at(-3);
      if (
        isMappingStep(prevStep)
        && isDimensionStep(prev2Step)
        && isFieldStep(prev3Step) && isInstanceOf(store.getObjectOrNull(prev3Step.fieldId), StakeholdersField)
      ) {
        return [
          ...accumulator.slice(0, -3),
          { type: PathStepType.field, fieldId: step.fieldId, mapping: { n1InstanceId: prevStep.mapping } } satisfies FieldStep,
        ];
      }
    }

    accumulator.push(step);
    return accumulator;
  }, [])
);

export const isMultiplePath = (store: ObjectStoreReadOnly, path: PathStep[]): boolean => {
  const compiledPath = compilePath(store, path);
  let result = compiledPath.length > 0;
  for (let i = 0; i < compiledPath.length; i += 1) {
    const nStep = compiledPath[i];
    if (isGlobalDimensionStep(nStep)) {
      result = false;
    } else if (isMappingStep(nStep)) {
      result = false;
    } else if (isFieldStep(nStep)) {
      const stepField = store.getObjectOrNull<FieldStoreObject>(nStep.fieldId);
      if (stepField && isMultipleRelationalType(stepField[Instance_Of])) {
        result = true;
      }
    }
  }
  return result;
};

export const isPathTargetingConcept = (store: ObjectStoreReadOnly, path: PathStep[]): boolean => {
  let result = path.length > 0;
  for (let i = 0; i < path.length; i += 1) {
    const nStep = path[i];
    if (isGlobalDimensionStep(nStep)) {
      result = false;
    } else if (isFieldStep(nStep)) {
      const stepField = store.getObjectOrNull(nStep.fieldId);
      if (stepField && !isRelationalType(stepField[Instance_Of] as string)) {
        result = false;
      } else {
        result = !!stepField;
      }
    }
  }
  return result;
};

const getFilterConditionsFromFieldFilterPath = (store: ObjectStoreWithTimeseries, filterPath: PathStep[]): FieldFilterConditions<unknown> | undefined => {
  const { step: fieldStep } = getEndOfPathFieldStep(filterPath);
  const lastFieldId = fieldStep?.fieldId;
  if (lastFieldId && store.getObjectOrNull(lastFieldId) !== null) {
    return getFieldUtilsHandler(store, lastFieldId)?.filterConditions;
  }
  return undefined;
};

export const getFilterConditionsFromFilterPath = (store: ObjectStoreWithTimeseries, filterPath: PathStep[]): BackendFilterConditions | undefined => {
  const isNPath = isMultiplePath(store, filterPath);
  const isTargetingConcept = isPathTargetingConcept(store, filterPath);
  if (isNPath && isTargetingConcept) {
    return getMultipleInstanceFilterConditions(store);
  } else if (!isNPath && isTargetingConcept) {
    return getFilterConditionsFromFieldFilterPath(store, filterPath) ?? getInstanceFilterConditions(store);
  } else if (!isNPath && !isTargetingConcept) {
    return getFilterConditionsFromFieldFilterPath(store, filterPath);
  }
  return undefined;
};

export const getFilterState = (store: ObjectStoreWithTimeseries, filter: FilterCondition): undefined | BackendFilterCondition<FilterValue<unknown>, unknown, unknown> => {
  const { leftValue, operator, rightValue } = filter;
  const isTransitiveCondition = (cond: string) => !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(cond);
  let filterCondition: BackendFilterCondition<FilterValue<unknown>, unknown, unknown> | null | undefined = null;
  if (!operator) {
    return undefined;
  }
  if (!leftValue || !leftValue[0]) {
    return undefined;
  }

  const filterConditions = getFilterConditionsFromFilterPath(store, leftValue);
  filterCondition = filterConditions?.[operator];

  if (!filterCondition) {
    return undefined;
  }

  const { sanitizeValue } = filterCondition;
  const sanitizedValue = sanitizeValue(rightValue as FilterValue<unknown>);
  if (isTransitiveCondition(operator)) {
    let valueContent;
    if (isFilterValueRaw(sanitizedValue)) {
      valueContent = sanitizedValue.raw;
    } else if (isFilterValuePath(sanitizedValue)) {
      valueContent = sanitizedValue.path;
    }
    if (Array.isArray(valueContent) && valueContent.length === 0) {
      return undefined;
    } else if (valueContent == null || valueContent === '') {
      return undefined;
    }
  }

  return filterCondition;
};

export const getPathReturnedType = (
  store: ObjectStoreWithTimeseries,
  parameters: { id: string, typeIds: string[] }[],
  path: PathStep[],
  mode: 'value' | 'timeseries' | 'timeseriesIterator'
): { type: 'resolvable', returnType: FormulaType, timeseriesMode: 'none' | 'implicit' | 'explicit' } | { type: 'unresolvable', reason: string, data: Record<string, unknown> } => {
  const compiledPath = compilePath(store, path);
  let isArray = false;
  let valueType: FormulaType | undefined;
  let timeseriesMode: 'none' | 'implicit' | 'explicit' = 'none';

  for (let i = 0; i < compiledPath.length; i += 1) {
    const step = compiledPath[i];

    switch (step.type) {
      case PathStepType.global: {
        isArray = false;
        break;
      }
      case PathStepType.dimension: {
        valueType = conceptType(step.conceptDefinitionId);
        const conceptDefinition = store.getObjectOrNull(step.conceptDefinitionId);
        if (conceptDefinition === null) {
          return { type: 'unresolvable', reason: 'Concept definition does not exists.', data: { conceptDefinitionId: step.conceptDefinitionId, path, stepIndex: i } };
        } else if (i === 0) {
          // Only inject a list of instance when first, otherwise, it only act as a type restriction
          isArray = true;
        }
        break;
      }
      case PathStepType.mapping: {
        isArray = false;
        switch (step.mapping.type) {
          case InstanceReferenceType.instance: {
            const concept = store.getObjectOrNull(step.mapping.id);
            if (concept === null) {
              return { type: 'unresolvable', reason: 'Mapped instance does not exists.', data: { conceptId: step.mapping.id, path, stepIndex: i } };
            }
            break;
          }
          case InstanceReferenceType.parameter: {
            const parameter = parameters.find(({ id }) => id === step.mapping.id);
            if (parameter === undefined) {
              return { type: 'unresolvable', reason: 'Parameter does not exists.', data: { parameterId: step.mapping.id, path, stepIndex: i } };
            }
            break;
          }
        }
        break;
      }
      case PathStepType.multipleMapping: {
        const parameter = parameters.find(({ id }) => id === step.id);
        if (parameter === undefined) {
          return { type: 'unresolvable', reason: 'Parameter does not exists.', data: { parameterId: step.id, path, stepIndex: i } };
        }
        break;
      }
      case PathStepType.filter: {
        // Filter step don't change anything
        break;
      }
      case PathStepType.field: {
        const field = store.getObjectOrNull<FieldStoreObject>(step.fieldId);
        if (field === null) {
          return { type: 'unresolvable', reason: 'Field does not exists.', data: { fieldId: step.fieldId, path, stepIndex: i } };
        } else {
          const isNField = isMultipleRelationalType(field[Instance_Of]);
          isArray = isArray || isNField;
          const fieldDescription = getFieldUtilsHandler(store, step.fieldId).describe();
          if (!fieldDescription.hasData) {
            return { type: 'unresolvable', reason: 'Field does not have data.', data: { fieldId: step.fieldId, path, stepIndex: i } };
          } else {
            switch (i === (compiledPath.length - 1) ? mode : 'value') {
              case 'value': {
                if (isNField && isArrayFormulaType(fieldDescription.returnType)) {
                  // N field array are flattened at resolution
                  valueType = fieldDescription.returnType.array.elementType;
                } else if (isInstanceOf(field, WorkflowField)) {
                  // Path resolved contains a magic case to read the value props (and return a concept)
                  valueType = conceptType(field.navigateOrNull(WorkflowField_Workflow)?.navigateOrNull(Workflow_TargetedConceptDefinition)?.id ?? Concept);
                } else if (fieldDescription.timeseriesMode === 'explicit') {
                  valueType = rhythmicTimeseriesOf(fieldDescription.returnType);
                } else {
                  valueType = fieldDescription.returnType;
                }
                break;
              }
              case 'timeseries': {
                switch (fieldDescription.timeseriesMode) {
                  case 'none':
                    return { type: 'unresolvable', reason: 'Field does not support timeseries.', data: { fieldId: step.fieldId, path, stepIndex: i } };
                  case 'implicit':
                  case 'explicit': {
                    timeseriesMode = fieldDescription.timeseriesMode;
                    valueType = rhythmicTimeseriesOf(fieldDescription.returnType);
                    break;
                  }
                }
                break;
              }
              case 'timeseriesIterator': {
                switch (fieldDescription.timeseriesMode) {
                  case 'none':
                    return { type: 'unresolvable', reason: 'Field does not support timeseries.', data: { fieldId: step.fieldId, path, stepIndex: i } };
                  case 'implicit':
                  case 'explicit': {
                    timeseriesMode = fieldDescription.timeseriesMode;
                    valueType = fieldDescription.returnType;
                    break;
                  }
                }
                break;
              }
            }
          }
        }
        break;
      }
    }
  }

  if (valueType === undefined) {
    return { type: 'unresolvable', reason: 'Path is empty.', data: { path } };
  } else {
    return { type: 'resolvable', returnType: isArray ? arrayOf(valueType) : valueType, timeseriesMode };
  }
};

const resolveFilterValue = <T>(
  store: ObjectStoreWithTimeseries,
  value: FilterValue<T>,
  parameters: ParametersMapping,
  shouldBeMultiple: boolean,
  shouldBeInstanceReferences: boolean
): T => {
  if (isFilterValuePath(value)) {
    let resolvedSanitizedValue: unknown;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const pathResolver = createValuePathResolver(store, parameters);
    const valueResolution = pathResolver.resolvePathValue(value.path ?? []);
    if (isSingleValueResolution(valueResolution)) {
      const result = (valueResolution.value as StoreObject | undefined)?.id;
      resolvedSanitizedValue = shouldBeMultiple ? [result].filter(filterNullOrUndefined) : result;
    } else if (isMultiValueResolution(valueResolution)) {
      resolvedSanitizedValue = (valueResolution.values as StoreObject[] | undefined)?.map(({ id }) => id) ?? [];
    }
    if (shouldBeInstanceReferences) {
      if (Array.isArray(resolvedSanitizedValue)) {
        resolvedSanitizedValue = resolvedSanitizedValue.map((resolvedValue) => ({ id: resolvedValue, type: InstanceReferenceType.instance }));
      } else {
        resolvedSanitizedValue = resolvedSanitizedValue ? { id: resolvedSanitizedValue, type: InstanceReferenceType.instance } : undefined;
      }
    }
    return resolvedSanitizedValue as T;
  } else {
    return value.raw;
  }
};

const executeFilterCondition = (
  store: ObjectStoreWithTimeseries,
  { sanitizedValue, leftValue, filterFunction, valueShouldBeMultiple, isNPath, isTargetingConcept }: FilterConditionExecutionContext,
  parametersMapping: ParametersMapping = {}
): boolean => {
  const resolvedSanitizedValue = resolveFilterValue(store, sanitizedValue, parametersMapping, valueShouldBeMultiple, isTargetingConcept);
  // Recursive call but no loop as filter paths do not call filters
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const pathResolver = createValuePathResolver(store, parametersMapping);
  if (!isNPath) {
    const { pathResolution } = pathResolver.resolvePathStackValue(leftValue);
    if (isValidPathResolution(pathResolution)) {
      const { valueResolution } = pathResolution[pathResolution.length - 1];
      if (isTargetingConcept) {
        if (!isSingleValueResolution(valueResolution)) {
          return false;
        }
        const instanceId = (valueResolution.value as StoreObject | undefined)?.id;
        return filterFunction(instanceId, resolvedSanitizedValue, parametersMapping);
      } else if (valueResolution && isSingleValueResolution(valueResolution)) {
        return filterFunction(valueResolution.value, resolvedSanitizedValue, parametersMapping);
      }
    }
  } else if (isNPath && isTargetingConcept) {
    const valueResolution = pathResolver.resolvePathValue(leftValue);
    if (valueResolution && isMultiValueResolution(valueResolution)) {
      return filterFunction((valueResolution.values as StoreObject[] | undefined)?.map(({ id }) => id) ?? [], resolvedSanitizedValue, parametersMapping);
    }
  }
  return false;
};

export const getFilterConditionContext = (store: ObjectStoreWithTimeseries, filter: FilterCondition): FilterConditionExecutionContext | undefined => {
  const filterCondition = getFilterState(store, filter);
  if (!filterCondition) {
    return undefined;
  }
  const { sanitizeValue, filterFunction, isFilterApplicable } = filterCondition;
  const { leftValue = [], operator, rightValue } = filter;
  const sanitizedValue = sanitizeValue(rightValue as FilterValue<unknown>);
  if (!isFilterApplicable(sanitizedValue)) {
    return undefined;
  }
  const isTargetingConcept = isPathTargetingConcept(store, leftValue);
  const isNPath = isMultiplePath(store, leftValue);
  const valueShouldBeMultiple = Boolean(operator) && isTargetingConcept && ['IN', 'NOT_IN', 'CONTAINS', 'CONTAINS_SOME', 'DOES_NOT_CONTAIN'].includes(operator ?? '');
  return { sanitizedValue, leftValue, filterFunction, valueShouldBeMultiple, isNPath, isTargetingConcept };
};

const buildFilterId = (parametersMapping: ParametersMapping): string[] => {
  const ids: string[] = [];
  const parametersValue: string[] = [];
  Object.entries(parametersMapping)
    .sort(extractAndCompareValue(([id]) => id, compareString))
    .forEach(([id, parameterValue]) => {
      if (parameterValue?.id) {
        ids.push(id);
        parametersValue.push(parameterValue.id.toString());
      }
    });
  return [ids.join(':'), ...parametersValue];
};

const evaluateSingleFilter = (store: ObjectStoreWithTimeseries, filterCondition: FilterCondition): FilterFunction => {
  if (store.hasPropertyFunction(Concept_GetFilterConditionContext)) {
    const filterConditionKey = JSON.stringify(filterCondition);
    const filterConditionExecutionContext = store.getObject(filterConditionKey, true)[Concept_GetFilterConditionContext] as FilterConditionExecutionContext | undefined;
    if (!filterConditionExecutionContext) {
      return undefined;
    }
    if (!store.hasPropertyFunction(filterConditionKey)) {
      store.registerPropertyFunction(filterConditionKey, (dimensionalIds) => {
        const dimensionsMapping = dimensionsMappingFromDimensionalIds(dimensionalIds);
        return executeFilterCondition(store, filterConditionExecutionContext, dimensionsMappingToParametersMapping(dimensionsMapping));
      });
    }
    return (parameters) => store.getObject(buildFilterId(parameters), true)[filterConditionKey] as boolean;
  } else {
    const filterConditionContext = getFilterConditionContext(store, filterCondition);
    if (!filterConditionContext) {
      return undefined;
    }
    return (parameters) => executeFilterCondition(store, filterConditionContext, parameters);
  }
};

export const getFilterFunctionHandler = (store: ObjectStoreWithTimeseries, filters: Filters): FilterFunction => {
  if (isFilterNode(filters)) {
    const nodeCondition = filters.condition;
    const filterGroupFunctions = filters.children?.map(
      (filter): FilterFunction => {
        if (isFilterNode(filter)) {
          return getFilterFunctionHandler(store, filter);
        } else {
          return evaluateSingleFilter(store, filter);
        }
      }
    ).filter((filterFunction): filterFunction is NonNullable<FilterFunction> => Boolean(filterFunction));

    if (!filterGroupFunctions || filterGroupFunctions.length === 0) {
      return undefined;
    } else if (filterGroupFunctions.length === 1) {
      return filterGroupFunctions[0];
    } else if (nodeCondition === FilterConditionOperators.AND) {
      return (parameters) => filterGroupFunctions.every((filterFunction) => filterFunction(parameters));
    } else {
      return (parameters) => filterGroupFunctions.some((filterFunction) => filterFunction(parameters));
    }
  } else {
    return evaluateSingleFilter(store, filters);
  }
};

const getFilterFunction = (store: ObjectStoreWithTimeseries, filters: Filters | undefined): FilterFunction => {
  if (!filters) {
    return undefined;
  } else if (store.hasPropertyFunction(Concept_GetFilterFunction)) {
    return store.getObject(JSON.stringify(filters), true)[Concept_GetFilterFunction] as FilterFunction;
  } else {
    return getFilterFunctionHandler(store, filters);
  }
};

const getFieldMapping = (store: ObjectStoreWithTimeseries, fieldStep: FieldStep, instance: StoreObject): DimensionsMapping => {
  if (fieldStep.embeddingFieldId && instance[KinshipRelation] !== fieldStep.embeddingFieldId) {
    throw new StepResolutionError(getResolutionErrorType(fieldStep), { resolutionError: ResolutionError.invalidEmbeddingField });
  }
  const field = store.getObject(fieldStep.fieldId);
  if (isInstanceOf<StakeholdersFieldStoreObject>(field, StakeholdersField)) {
    if (fieldStep.mapping && fieldStep.mapping.n1InstanceId) {
      return { n1InstanceId: fieldStep.mapping.n1InstanceId.id, n2InstanceId: instance.id };
    }
  } else if (isInstanceOf<WorkflowFieldStoreObject>(field, WorkflowField)) {
    const dimensionId = getFieldDimensionOfModelType(store, field.id, instance[Instance_Of] as string);
    if (dimensionId) {
      if (fieldStep.workflowSubfieldId) {
        return { [WORKFLOW_FIELD_PARAMETER_SUB_FIELD_DIMENSION]: fieldStep.workflowSubfieldId, [dimensionId]: instance.id };
      } else {
        return { [dimensionId]: instance.id };
      }
    } else {
      return {};
    }
  }
  const dimensionId = getFieldDimensionOfModelType(store, field.id, instance[Instance_Of] as string);
  return dimensionId ? { [dimensionId]: instance.id } : {};
};

const getFieldMappings = (store: ObjectStoreWithTimeseries, fieldStep: FieldStep, instances: StoreObject[]): DimensionsMapping[] => (
  instances.map((instance) => getFieldMapping(store, fieldStep, instance))
);

const getFieldValue = (
  store: ObjectStoreWithTimeseries,
  fieldStep: FieldStep,
  mapping: DimensionsMapping,
  resolutionStack?: ResolutionStack,
  timeRange?: TimeRange,
  isTimeseries?: boolean
): { isTimeseries: boolean, value: unknown } => {
  const field = store.getObject(fieldStep.fieldId);
  if (isInstanceOf<WorkflowFieldStoreObject>(field, WorkflowField)) {
    const valueResolution = getFieldUtilsHandler(store, field.id).getValueResolution(mapping, resolutionStack, timeRange, isTimeseries);
    if (valueResolution.error !== undefined) {
      throw new StepResolutionError(getResolutionErrorType(fieldStep), { cause: valueResolution.error });
    } else {
      return { value: (valueResolution.value as { value: StoreObject | undefined }).value, isTimeseries: valueResolution.isTimeseries };
    }
  } else {
    const valueResolution = getFieldUtilsHandler(store, field.id).getValueResolution(mapping, resolutionStack, timeRange, isTimeseries);
    if (valueResolution.error !== undefined) {
      throw new StepResolutionError(getResolutionErrorType(fieldStep), { cause: valueResolution.error });
    } else {
      return { value: valueResolution.value, isTimeseries: valueResolution.isTimeseries };
    }
  }
};

const getRelationalFieldValue = (
  store: ObjectStoreWithTimeseries,
  fieldStep: FieldStep,
  mapping: DimensionsMapping,
  resolutionStack?: ResolutionStack
): ConceptStoreObject[] | ConceptStoreObject | undefined => (
  getFieldValue(store, fieldStep, mapping, resolutionStack).value as ConceptStoreObject[] | ConceptStoreObject | undefined
);

const getFieldValues = (
  store: ObjectStoreWithTimeseries,
  fieldStep: FieldStep,
  mappings: DimensionsMapping[],
  resolutionStack?: ResolutionStack,
  timeRange?: TimeRange,
  isTimeseries?: boolean
): { isTimeseries: boolean, values: unknown[] } => {
  let isTimeseriesValue = false;
  const values: unknown[] = [];
  mappings.forEach((mapping) => {
    const resolution = getFieldValue(store, fieldStep, mapping, resolutionStack, timeRange, isTimeseries);
    values.push(resolution.value);
    if (resolution.isTimeseries) {
      isTimeseriesValue = resolution.isTimeseries;
    }
  });
  return { values, isTimeseries: isTimeseriesValue };
};

const getRelationalFieldValues = (
  store: ObjectStoreWithTimeseries,
  fieldStep: FieldStep,
  mappings: DimensionsMapping[],
  resolutionStack?: ResolutionStack
): ConceptStoreObject[] => {
  const values: ConceptStoreObject[] = [];
  mappings.forEach((mapping) => {
    const resolution = getRelationalFieldValue(store, fieldStep, mapping, resolutionStack);
    if (Array.isArray(resolution)) {
      values.push(...resolution);
    } else if (resolution !== undefined) {
      values.push(resolution);
    }
  });
  return values;
};

export const createValuePathResolver = (
  store: ObjectStoreWithTimeseries,
  parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>
): ValuePathResolver => {
  const { singleParametersMapping, multipleParametersMapping } = segregateParametersMapping(parametersMapping);

  const getMappingValue = (value: InstanceReferenceValue | undefined): string | undefined => {
    if (value && value.type === InstanceReferenceType.parameter) {
      return singleParametersMapping[value.id]?.id;
    } else if (value) {
      return value.id;
    }
    return undefined;
  };

  const getMappingValues = (id: string): string[] => multipleParametersMapping[id]?.ids ?? [];

  const resolveStep = (
    path: PathStep[],
    stepIndex: number,
    previousPathResolutionStack: PreviousResolvedDimensionsStep[] = [],
    resolutionStack?: ResolutionStack,
    dateRange?: TimeRange,
    isTimeseriesValue = false,
    fieldResolutionOnly = false
  ): ResolvedStep => {
    const step = path.at(stepIndex);
    if (!step) {
      throw newError('Invalid step index provided', { path, stepIndex });
    }

    const n1DimensionResolution = previousPathResolutionStack.at(-1)?.dimensionResolution;

    if (isGlobalDimensionStep(step)) {
      // whatever is before, we do nothing
      return {
        dimensionResolution: { type: DimensionResolutionType.global },
      };
    } else if (isDimensionStep(step)) {
      if (fieldResolutionOnly) {
        return {};
      }
      // Nothing before, we start by injecting the dimension instances
      if (!n1DimensionResolution) {
        const conceptDefinition = store.getObjectOrNull(step.conceptDefinitionId);
        if (!conceptDefinition) {
          throw new StepResolutionError(getResolutionErrorType(step), { resolutionError: ResolutionError.invalidDimension });
        }
        const nextStep = path.at(stepIndex + 1);
        if (nextStep && isMappingStep(nextStep)) {
          // optimization to avoid costly find when starting with a mapping
          const mappingId = getMappingValue(nextStep.mapping);
          if (!mappingId || mappingId === UnsetDashboardParameterOption || store.getObjectOrNull(mappingId) === null
            || store.getObject(mappingId)[Instance_Of] !== step.conceptDefinitionId) {
            return {
              dimensionResolution: { type: DimensionResolutionType.multi, instances: [] },
              valueResolution: { type: ValueResolutionType.multi, isArrhythmicTimeseries: false, isTimeseries: false, values: [] },
            };
          }
          const instance = store.getObject<ConceptStoreObject>(mappingId);
          return {
            dimensionResolution: { type: DimensionResolutionType.multi, instances: [instance] },
            valueResolution: { type: ValueResolutionType.multi, isArrhythmicTimeseries: false, isTimeseries: false, values: [instance] },
          };
        } else if (nextStep && isMultipleMappingStep(nextStep)) {
          // optimization to avoid costly find when starting with a mapping
          const instances = getMappingValues(nextStep.id)
            .filter((mappingId) => mappingId !== UnsetDashboardParameterOption
              || store.getObjectOrNull(mappingId) !== null
              || store.getObject(mappingId)[Instance_Of] === step.conceptDefinitionId)
            .map((id) => store.getObject<ConceptStoreObject>(id));
          const filterFunction = getFilterFunction(store, nextStep.filters);
          if (filterFunction) {
            const filteredInstances = instances
              .filter((instance) => filterFunction(joinObjects(singleParametersMapping, { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: instance.id } })));
            return {
              dimensionResolution: { type: DimensionResolutionType.multi, instances: filteredInstances },
              valueResolution: { type: ValueResolutionType.multi, isArrhythmicTimeseries: false, isTimeseries: false, values: filteredInstances },
            };
          } else {
            return {
              dimensionResolution: { type: DimensionResolutionType.multi, instances },
              valueResolution: { type: ValueResolutionType.multi, isArrhythmicTimeseries: false, isTimeseries: false, values: instances },
            };
          }
        } else {
          const instances = conceptDefinition.navigateBack(Instance_Of);
          return {
            dimensionResolution: {
              type: DimensionResolutionType.multi,
              instances: conceptDefinition.navigateBack(Instance_Of),
            },
            valueResolution: { type: ValueResolutionType.multi, isArrhythmicTimeseries: false, isTimeseries: false, values: instances },
          };
        }
        // We just map the last instance to the good type, return nothing if we can't map
      } else if (isGlobalDimensionResolution(n1DimensionResolution)) {
        return {};
      } else if (isSingleDimensionResolution(n1DimensionResolution)) {
        const previousInstance = n1DimensionResolution.instance;
        if (!previousInstance) {
          return {
            dimensionResolution: n1DimensionResolution,
            valueResolution: { type: ValueResolutionType.single, isArrhythmicTimeseries: false, isTimeseries: false, value: undefined },
          };
        } else if (previousInstance && previousInstance[Instance_Of] === step.conceptDefinitionId) {
          return {
            dimensionResolution: { type: DimensionResolutionType.single, instance: previousInstance },
            valueResolution: { type: ValueResolutionType.single, isArrhythmicTimeseries: false, isTimeseries: false, value: previousInstance },
          };
        } else {
          return {
            dimensionResolution: { type: DimensionResolutionType.single, instance: undefined },
            valueResolution: { type: ValueResolutionType.single, isArrhythmicTimeseries: false, isTimeseries: false, value: undefined },
          };
        }
      } else {
        // We filter previous dimension resolution with the dimension type
        const values = n1DimensionResolution.instances.filter((instance) => instance[Instance_Of] === step.conceptDefinitionId);
        return {
          dimensionResolution: { type: DimensionResolutionType.multi, instances: values },
          valueResolution: { type: ValueResolutionType.multi, isArrhythmicTimeseries: false, isTimeseries: false, values },
        };
      }
    } else if (isFilterStep(step)) {
      if (fieldResolutionOnly) {
        return {};
      }
      if (!n1DimensionResolution || isGlobalDimensionResolution(n1DimensionResolution)) {
        throw new StepResolutionError(getResolutionErrorType(step), { resolutionError: ResolutionError.invalidFilter });
      } else if (isSingleDimensionResolution(n1DimensionResolution)) {
        if (n1DimensionResolution.instance === undefined) {
          return {
            dimensionResolution: n1DimensionResolution,
            valueResolution: { type: ValueResolutionType.single, isArrhythmicTimeseries: false, isTimeseries: false, value: undefined },
          };
        }
        if (!step.filters) {
          return {
            dimensionResolution: n1DimensionResolution,
            valueResolution: { type: ValueResolutionType.single, isArrhythmicTimeseries: false, isTimeseries: false, value: n1DimensionResolution.instance },
          };
        }
        const filterFunction = getFilterFunction(store, step.filters);
        const filteredDimensionResolution = !filterFunction
        || filterFunction(joinObjects(singleParametersMapping, { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: n1DimensionResolution.instance.id } }))
          ? n1DimensionResolution.instance
          : undefined;
        return {
          dimensionResolution: { type: DimensionResolutionType.single, instance: filteredDimensionResolution },
          valueResolution: { type: ValueResolutionType.single, isArrhythmicTimeseries: false, isTimeseries: false, value: filteredDimensionResolution },
        };
      } else if (!step.filters) {
        const values = n1DimensionResolution.instances.map((instance) => instance);
        return {
          dimensionResolution: n1DimensionResolution,
          valueResolution: { type: ValueResolutionType.multi, isArrhythmicTimeseries: false, isTimeseries: false, values },
        };
      } else {
        const filterFunction = getFilterFunction(store, step.filters);
        const filteredInstances = n1DimensionResolution.instances
          .filter((instance) => !filterFunction
            || filterFunction(joinObjects(singleParametersMapping, { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: instance.id } })));
        return {
          dimensionResolution: { type: DimensionResolutionType.multi, instances: filteredInstances },
          valueResolution: { type: ValueResolutionType.multi, isArrhythmicTimeseries: false, isTimeseries: false, values: filteredInstances.map((instance) => instance) },
        };
      }
    } else if (isMappingStep(step)) {
      if (fieldResolutionOnly) {
        return {};
      }
      const mappingId = getMappingValue(step.mapping);
      if (!mappingId || mappingId === UnsetDashboardParameterOption) {
        return { dimensionResolution: { type: DimensionResolutionType.single, instance: undefined } };
      } else if (!n1DimensionResolution || isGlobalDimensionResolution(n1DimensionResolution) || store.getObjectOrNull(mappingId) === null) {
        throw new StepResolutionError(getResolutionErrorType(step), { resolutionError: ResolutionError.invalidMapping });
      } else if (isSingleDimensionResolution(n1DimensionResolution)) {
        if (n1DimensionResolution.instance === undefined) {
          return {
            dimensionResolution: { type: DimensionResolutionType.single, instance: undefined },
            valueResolution: { type: ValueResolutionType.single, isArrhythmicTimeseries: false, isTimeseries: false, value: undefined },
          };
        } else {
          return {
            dimensionResolution: {
              type: DimensionResolutionType.single,
              instance: mappingId === n1DimensionResolution.instance.id ? n1DimensionResolution.instance : undefined,
            },
            valueResolution: mappingId === n1DimensionResolution.instance.id
              ? { type: ValueResolutionType.single, isArrhythmicTimeseries: false, isTimeseries: false, value: n1DimensionResolution.instance }
              : { type: ValueResolutionType.single, isArrhythmicTimeseries: false, isTimeseries: false, value: undefined },
          };
        }
      } else {
        const instance = n1DimensionResolution.instances.find((n1DimensionResolutionInstance) => n1DimensionResolutionInstance.id === mappingId);
        return {
          dimensionResolution: { type: DimensionResolutionType.single, instance },
          valueResolution: instance
            ? { type: ValueResolutionType.single, isArrhythmicTimeseries: false, isTimeseries: false, value: instance }
            : { type: ValueResolutionType.single, isArrhythmicTimeseries: false, isTimeseries: false, value: undefined },
        };
      }
    } else if (isMultipleMappingStep(step)) {
      const previousStep = path[stepIndex - 1];
      if (isDimensionStep(previousStep)) {
        let mappingIds = getMappingValues(step.id).filter((mappingId) => mappingId !== UnsetDashboardParameterOption);
        if (!mappingIds.length) {
          return { dimensionResolution: { type: DimensionResolutionType.single, instance: undefined } };
        }
        mappingIds = mappingIds.filter((mappingId) => store.getObjectOrNull(mappingId) !== null
          || store.getObject(mappingId)[Instance_Of] === previousStep.conceptDefinitionId);
        if (!mappingIds.length || !n1DimensionResolution || isSingleDimensionResolution(n1DimensionResolution) || isGlobalDimensionResolution(n1DimensionResolution)) {
          throw new StepResolutionError(getResolutionErrorType(step), { resolutionError: ResolutionError.invalidMapping });
        } else {
          return {
            dimensionResolution: { type: DimensionResolutionType.multi, instances: n1DimensionResolution.instances },
            valueResolution: { type: ValueResolutionType.multi, isArrhythmicTimeseries: false, isTimeseries: false, values: n1DimensionResolution.instances },
          };
        }
      } else {
        throw new StepResolutionError(getResolutionErrorType(step), { resolutionError: ResolutionError.invalidMapping });
      }
    } else if (isFieldStep(step)) {
      const field = store.getObjectOrNull(step.fieldId);
      if (!field || !isInstanceOf<FieldStoreObject>(field, Field)) {
        throw new StepResolutionError(getResolutionErrorType(step), { resolutionError: ResolutionError.invalidField });
      } else if (!n1DimensionResolution) {
        throw new StepResolutionError(getResolutionErrorType(step), { resolutionError: ResolutionError.cannotNavigatePath });
      } else if (isGlobalDimensionResolution(n1DimensionResolution) || isSingleDimensionResolution(n1DimensionResolution)) {
        // (1)instance|global -> field
        let dimensionsMapping: DimensionsMapping;
        if (isGlobalDimensionResolution(n1DimensionResolution)) {
          // (1)global -> field
          const fieldDimensionIds = getAllFieldDimensionsIds(store, field.id);
          const mappings = Object.fromEntries(Object.entries(step.mapping ?? {})
            .filter(([dimensionId]) => fieldDimensionIds.includes(dimensionId))
            .map(([dim, value]) => [dim, getMappingValue(value)]));
          dimensionsMapping = Object.fromEntries(Object.entries(mappings ?? {})
            .map(([dim, value]) => [dim, value && isParameter(store, value, singleParametersMapping) ? singleParametersMapping?.[value].id : value]));
        } else {
          // (1)instance -> field
          if (n1DimensionResolution.instance === undefined) {
            return {};
          }
          dimensionsMapping = getFieldMapping(store, step, n1DimensionResolution.instance);
        }
        const fieldResolution: SingleFieldResolution = { type: FieldResolutionType.single, dimensionsMapping, fieldId: step.fieldId };
        if (fieldResolutionOnly) { // Stop here as we will never need more than the fieldResolution
          return { fieldResolution };
        }
        if (isRelationalType(field[Instance_Of])) {
          // (1)instance|global -> relational field
          const value = getRelationalFieldValue(store, step, dimensionsMapping, resolutionStack);
          if (Array.isArray(value)) {
            // (1)instance|global -> relational(n) field -> values
            return {
              dimensionResolution: {
                type: DimensionResolutionType.multi,
                instances: value.map((instance) => instance),
              },
              valueResolution: {
                type: ValueResolutionType.multi,
                isArrhythmicTimeseries: isInstanceOf(field, NumberField) && isTimeseriesValue,
                values: value,
                isTimeseries: false,
              },
              fieldResolution,
            };
          } else if (value !== undefined) {
            // (1)instance|global -> relational(1) field -> value
            return {
              dimensionResolution: { type: DimensionResolutionType.single, instance: value },
              valueResolution: { type: ValueResolutionType.single, isArrhythmicTimeseries: isInstanceOf(field, NumberField) && isTimeseriesValue, value, isTimeseries: false },
              fieldResolution,
            };
          } else if (isMultipleRelationalType(field[Instance_Of])) {
            // (1)instance|global -> relational(n) field -> not-set
            return {
              dimensionResolution: { type: DimensionResolutionType.multi, instances: [] },
              valueResolution: { type: ValueResolutionType.multi, isArrhythmicTimeseries: isInstanceOf(field, NumberField) && isTimeseriesValue, values: [], isTimeseries: false },
              fieldResolution,
            };
          } else {
            // (1)instance|global -> relational(1) field -> not-set
            return {
              dimensionResolution: { type: DimensionResolutionType.single, instance: undefined },
              valueResolution: {
                type: ValueResolutionType.single,
                isArrhythmicTimeseries: isInstanceOf(field, NumberField) && isTimeseriesValue,
                value: undefined,
                isTimeseries: false,
              },
              fieldResolution,
            };
          }
        } else {
          // (1)instance|global -> non-relational field
          const { value, error, isTimeseries } = getFieldUtilsHandler(store, step.fieldId).getValueResolution(dimensionsMapping, resolutionStack, dateRange, isTimeseriesValue);
          if (error !== undefined) {
            throw new StepResolutionError(getResolutionErrorType(step), { cause: error });
          }
          return {
            valueResolution: { type: ValueResolutionType.single, value, isArrhythmicTimeseries: isInstanceOf(field, NumberField) && isTimeseriesValue, isTimeseries },
            fieldResolution,
          };
        }
      } else {
        // (n)instances -> field
        const mappings = getFieldMappings(store, step, n1DimensionResolution.instances);
        const fieldResolution: MultiFieldResolution = {
          type: FieldResolutionType.multi,
          fieldId: step.fieldId,
          resolutions: mappings.map((dimensionsMapping) => ({ type: FieldResolutionType.single, dimensionsMapping, fieldId: step.fieldId })),
        };
        if (fieldResolutionOnly) { // Stop here as we will never need more than the fieldResolution
          return { fieldResolution };
        }
        if (isRelationalType(field[Instance_Of])) {
          // (n)instances -> relational field
          const instances = getRelationalFieldValues(store, step, mappings, resolutionStack);
          return {
            dimensionResolution: { type: DimensionResolutionType.multi, instances },
            valueResolution: {
              type: ValueResolutionType.multi,
              isArrhythmicTimeseries: isInstanceOf(field, NumberField) && isTimeseriesValue,
              isTimeseries: instances.some((value) => value.isTimeseries),
              values: instances,
            },
            fieldResolution,
          };
        } else {
          // (n)instances -> non-relational field
          const resolution = getFieldValues(store, step, mappings, resolutionStack, dateRange, isTimeseriesValue);
          return {
            dimensionResolution: undefined,
            valueResolution: {
              type: ValueResolutionType.multi,
              values: resolution.values,
              isArrhythmicTimeseries: isInstanceOf(field, NumberField) && isTimeseriesValue,
              isTimeseries: resolution.isTimeseries,
            },
            fieldResolution,
          };
        }
      }
    } else {
      throw newError('Unknown step');
    }
  };

  const resolvePathValue = <T = unknown>(
    valuePath: PathStep[], resolutionStack?: ResolutionStack, dateRange?: TimeRange, isTimeseriesValue?: boolean
  ): SingleValueResolution<T> | MultiValueResolution<T> | StepResolutionError | Error | undefined => {
    const compiledPath = compilePath(store, valuePath);

    let nStep;
    try {
      const resolvedStepsStack: PreviousResolvedDimensionsStep[] = [];
      for (let i = 0; i < compiledPath.length; i += 1) {
        const isLastStep = i === compiledPath.length - 1;
        nStep = compiledPath[i];
        const resolution = resolveStep(compiledPath, i, resolvedStepsStack, resolutionStack, dateRange, isTimeseriesValue);
        if (isLastStep) {
          return resolution.valueResolution as SingleValueResolution<T> | MultiValueResolution<T> | undefined;
        } else if (!resolution.dimensionResolution) {
          return undefined;
        } else {
          resolvedStepsStack.push({ step: nStep, dimensionResolution: resolution.dimensionResolution });
        }
      }
      return undefined;
    } catch (e) {
      if (e instanceof StepResolutionError) {
        return e;
      } else if (nStep) {
        return new StepResolutionError(getResolutionErrorType(nStep), e instanceof Error ? { cause: e } : { resolutionError: ResolutionError.unknownResolverError });
      } else {
        return fromError(e, 'Unknown Error');
      }
    }
  };

  const resolvePathField: ValuePathResolver['resolvePathField'] = (valuePath) => {
    const compiledPath = compilePath(store, valuePath);

    let nStep;
    try {
      const resolvedStepsStack: PreviousResolvedDimensionsStep[] = [];
      const lastFieldIndex = compiledPath.map((step) => isFieldStep(step)).lastIndexOf(true);
      for (let i = 0; i <= lastFieldIndex; i += 1) {
        const isLastStep = i === compiledPath.length - 1;
        nStep = compiledPath[i];
        const resolution = resolveStep(compiledPath, i, resolvedStepsStack, undefined, undefined, undefined, isLastStep ? true : undefined);
        if (isLastStep) {
          return resolution.fieldResolution;
        } else if (!resolution.dimensionResolution) {
          return undefined;
        } else {
          resolvedStepsStack.push({ step: nStep, dimensionResolution: resolution.dimensionResolution });
        }
      }
      return undefined;
    } catch (e) {
      if (e instanceof StepResolutionError) {
        return e;
      } else if (nStep) {
        return new StepResolutionError(getResolutionErrorType(nStep), e instanceof Error ? { cause: e } : { resolutionError: ResolutionError.unknownResolverError });
      } else {
        return fromError(e, 'Unknown Error');
      }
    }
  };

  const resolvePathDimension: ValuePathResolver['resolvePathDimension'] = (valuePath) => {
    const compiledPath = compilePath(store, valuePath);

    let nStep;
    try {
      const resolvedStepsStack: PreviousResolvedDimensionsStep[] = [];
      for (let i = 0; i < compiledPath.length; i += 1) {
        const isLastStep = i === compiledPath.length - 1;
        nStep = compiledPath[i];
        const resolution = resolveStep(compiledPath, i, resolvedStepsStack);
        if (isLastStep) {
          return resolution.dimensionResolution;
        } else if (!resolution.dimensionResolution) {
          return undefined;
        } else {
          resolvedStepsStack.push({ step: nStep, dimensionResolution: resolution.dimensionResolution });
        }
      }
      return undefined;
    } catch (e) {
      if (e instanceof StepResolutionError) {
        return e;
      } else if (nStep) {
        return new StepResolutionError(getResolutionErrorType(nStep), e instanceof Error ? { cause: e } : { resolutionError: ResolutionError.unknownResolverError });
      } else {
        return fromError(e, 'Unknown Error');
      }
    }
  };

  const resolvePathStackValue: ValuePathResolver['resolvePathStackValue'] = (valuePath) => {
    const compiledPath = compilePath(store, valuePath);

    let nStep;
    const resolvedStepsStack: PreviousResolvedDimensionsStep[] = [];
    const result: ResolvedStep[] = [];
    try {
      for (let i = 0; i < compiledPath.length; i += 1) {
        const isLastStep = i === compiledPath.length - 1;
        nStep = compiledPath[i];
        const resolution = resolveStep(compiledPath, i, resolvedStepsStack, undefined, undefined, undefined);
        if (isLastStep) {
          result.push(resolution);
          return { compiledPath, pathResolution: result };
        } else if (!resolution.dimensionResolution) {
          return { compiledPath, pathResolution: undefined };
        } else {
          result.push(resolution);
          resolvedStepsStack.push({ step: nStep, dimensionResolution: resolution.dimensionResolution });
        }
      }
      return { compiledPath, pathResolution: undefined };
    } catch (e) {
      if (e instanceof StepResolutionError) {
        return { compiledPath, pathResolution: e };
      } else if (nStep) {
        return {
          compiledPath,
          pathResolution: new StepResolutionError(
            getResolutionErrorType(nStep),
            e instanceof Error ? { cause: e } : { resolutionError: ResolutionError.unknownResolverError }
          ),
        };
      } else {
        return { compiledPath, pathResolution: fromError(e, 'Unknown Error') };
      }
    }
  };

  return {
    resolvePathValue,
    resolvePathField,
    resolvePathDimension,
    resolvePathStackValue,
  };
};
