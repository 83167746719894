import type { FunctionComponent } from 'react';
import { useRef, useState } from 'react';
import { getConceptUrl, isConceptValid } from 'yooi-modules/modules/conceptModule';
import { FieldDefinition, NumberField } from 'yooi-modules/modules/conceptModule/ids';
import type { WidgetDisplayMode, WidgetRaw, WidgetStoreObject, WidgetTitleMode } from 'yooi-modules/modules/dashboardModule';
import {
  Dashboard,
  Dashboard_Parameters,
  Dashboard_Widgets,
  DashboardParameter,
  DashboardParameter_Dashboard,
  DashboardParameter_Rank,
  MirrorField,
  MirrorField_TargetedFieldDefinition,
  Widget_Dashboard,
  Widget_DisplayMode,
  Widget_Field,
  Widget_HeaderBackgroundColor,
  Widget_Height,
  Widget_Title,
  Widget_TitleMode,
  Widget_Width,
} from 'yooi-modules/modules/dashboardModule/ids';
import { Class_Instances, Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareRank, extractAndCompareValue, filterNullOrUndefined, joinObjects, ranker } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../components/atoms/Button';
import { IconName } from '../../../components/atoms/Icon';
import Tooltip from '../../../components/atoms/Tooltip';
import Typo from '../../../components/atoms/Typo';
import DashboardGrid from '../../../components/molecules/DashboardGrid';
import MasterDetailList from '../../../components/molecules/MasterDetailList';
import OverflowMenu from '../../../components/molecules/OverflowMenu';
import SearchAndSelect from '../../../components/molecules/SearchAndSelect';
import SpacingLine from '../../../components/molecules/SpacingLine';
import { TableSortDirection } from '../../../components/molecules/Table';
import BaseLayout from '../../../components/templates/BaseLayout';
import BlockContent from '../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../components/templates/BlockTitle';
import Header from '../../../components/templates/Header';
import HorizontalBlock from '../../../components/templates/HorizontalBlock';
import LeftPanel from '../../../components/templates/LeftPanel';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useAcl from '../../../store/useAcl';
import useStore from '../../../store/useStore';
import base from '../../../theme/base';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import { notifySuccess } from '../../../utils/notify';
import { ObjectNotFoundError } from '../../../utils/ObjectNotFoundError';
import { formatOrUndef } from '../../../utils/stringUtils';
import { ChipBlockContextProvider } from '../../../utils/useChipBlockContext';
import useNavigation, { NavigationElementContainer } from '../../../utils/useNavigation';
import { duplicateWidget, getWidgetUrl } from '../../_global/dashboardUtils';
import { getFieldDefinitionHandler } from '../../_global/fields/FieldLibrary';
import HeaderFromStore, { HeaderFromStoreVariant } from '../../_global/HeaderFromStore';
import StoreColorPickerInput from '../../_global/input/StoreColorPickerInput';
import { searchFilterFunction } from '../../_global/listFilterFunctions';
import type { OptionRecord } from '../../_global/modelTypeUtils';
import { defaultOptionComparator, getChipOptions } from '../../_global/modelTypeUtils';
import type { NavigationFilter } from '../../_global/navigationUtils';
import TopBar from '../../_global/topBar/TopBar';
import useFilterAndSort, { buildStringColumnComparatorHandler } from '../../_global/useFilterAndSort';
import { getColorPalette } from '../../utils/standardColorsUtils';
import DashboardParameterLine from './DashboardParameterLine';
import DashboardWidget from './DashboardWidget';
import useDashboardParameterState from './useDashboardParameterState';
import WidgetFieldOptions from './WidgetFieldOptions';

const useStyles = makeStyles({
  parametersContainer: {
    display: 'flex',
    alignItems: 'center',
    gridColumnStart: 2,
    paddingTop: spacingRem.s,
    paddingBottom: spacingRem.s,
  },
}, 'dashboardWidgetPage');

interface DashboardWidgetPageProps {
  widgetId: string,
}

const DashboardWidgetPageInner: FunctionComponent<DashboardWidgetPageProps> = ({ widgetId }) => {
  const classes = useStyles();

  const store = useStore();
  const { canWriteObject } = useAcl();

  const navigation = useNavigation<NavigationFilter>();

  const lastCreatedParameterIdRef = useRef<string>();
  const [showPreview, setShowPreview] = useState(false);

  const widget = store.getObject<WidgetStoreObject>(widgetId);
  const dashboard = widget.navigate(Widget_Dashboard);

  const [parameters, selectedParameter] = useDashboardParameterState(dashboard.id);

  const readOnly = !canWriteObject(widgetId);

  const [search, setSearch] = useState<string | undefined>(undefined);
  const widgetsMasterDetailList = widget.navigate(Widget_Dashboard).navigateBack<WidgetStoreObject>(Dashboard_Widgets);
  const { generateList } = useFilterAndSort(
    widget[Widget_Dashboard] as string, // Cast is safe, parent component already checked it
    widgetsMasterDetailList,
    searchFilterFunction(store, search, [Widget_Title]),
    {
      getComparatorHandler: (key, direction) => {
        switch (key) {
          case Widget_Title:
            return buildStringColumnComparatorHandler(key, direction);
          default:
            return undefined;
        }
      },
      initial: { key: Widget_Title, direction: TableSortDirection.asc },
    },
    undefined,
    [search]
  );

  const doDelete = () => {
    navigation.push(dashboard.id, { pathname: getConceptUrl(store, dashboard.id) });
    store.deleteObject(widgetId);
    notifySuccess(i18n`Widget successfully deleted`);
  };

  const headerBackgroundColorHex = widget[Widget_HeaderBackgroundColor];

  const fieldDefinitionsOptions = store
    .getObject(FieldDefinition)
    .navigateBack(Class_Instances)
    .filter((fieldDefinition) => getFieldDefinitionHandler(store, fieldDefinition.id).asWidget)
    .map((fieldDefinition) => getChipOptions(store, fieldDefinition.id))
    .filter(filterNullOrUndefined)
    .sort(defaultOptionComparator);

  let widgetTypeSelectedOption;
  if (widget[Widget_Field]) {
    const widgetField = widget.navigate(Widget_Field);
    if (typeof widgetField[MirrorField_TargetedFieldDefinition] === 'string') {
      widgetTypeSelectedOption = getChipOptions(store, widgetField[MirrorField_TargetedFieldDefinition]);
    } else if (typeof widgetField[Instance_Of] === 'string') {
      widgetTypeSelectedOption = getChipOptions(store, widgetField[Instance_Of]);
    }
  }

  const backgroundOptions: OptionRecord<WidgetDisplayMode['type']> = {
    card: { id: 'card', label: i18n`Card` },
    borderless: { id: 'borderless', label: i18n`Borderless` },
    color: { id: 'color', label: i18n`Colored` },
  };

  const titleModeOptions: OptionRecord<WidgetTitleMode> = {
    auto: { id: 'auto', label: i18n`Auto` },
    always: { id: 'always', label: i18n`Always show` },
    hide: { id: 'hide', label: i18n`Hide` },
  };

  const colorPalette = getColorPalette(store);

  return (
    <BaseLayout
      topBar={(<TopBar />)}
      leftPanel={(
        <LeftPanel>
          <MasterDetailList
            list={
              generateList().list
                .map(({ item, ...entry }) => (
                  joinObjects(
                    entry,
                    {
                      render: () => (
                        <Typo maxLine={1}>
                          <Tooltip title={formatOrUndef(item[Widget_Title])}>
                            <span>{formatOrUndef(item[Widget_Title])}</span>
                          </Tooltip>
                        </Typo>
                      ),
                      to: () => ({ pathname: getWidgetUrl(item.id) }),
                    }
                  )
                ))
            }
            search={{ value: search, setValue: setSearch }}
          />
        </LeftPanel>
      )}
      header={(
        <Header
          firstLine={(
            <HeaderFromStore
              instanceId={widgetId}
              propertyId={Widget_Title}
              placeholder={i18n`Click to edit name`}
              variant={HeaderFromStoreVariant.title}
              actions={[
                (
                  <Button
                    key="addParameter"
                    iconName={IconName.add}
                    variant={ButtonVariant.secondary}
                    title={i18n`Add parameter`}
                    onClick={() => {
                      const allParameters = dashboard
                        .navigateBack(Dashboard_Parameters)
                        .sort(extractAndCompareValue((parameter) => parameter[DashboardParameter_Rank] as string, compareRank));

                      const lastRank = allParameters.length === 0 ? undefined : allParameters[allParameters.length - 1][DashboardParameter_Rank] as string;
                      const nextRank = ranker.createNextRankGenerator(lastRank)();

                      lastCreatedParameterIdRef.current = store.createObject({
                        [Instance_Of]: DashboardParameter,
                        [DashboardParameter_Dashboard]: dashboard.id,
                        [DashboardParameter_Rank]: nextRank,
                      });
                    }}
                  />
                ),
                (
                  <OverflowMenu
                    key="actions"
                    menuItems={[
                      {
                        key: 'duplicate',
                        name: i18n`Duplicate`,
                        icon: IconName.content_copy_outline,
                        hidden: readOnly,
                        onClick: () => {
                          const copiedWidgetId = duplicateWidget(store, widgetId);
                          navigation.push(copiedWidgetId, { pathname: getWidgetUrl(copiedWidgetId) });
                        },
                      },
                      {
                        key: 'delete',
                        name: i18n`Delete`,
                        icon: IconName.delete,
                        hidden: readOnly,
                        onClick: doDelete,
                        danger: true,
                      },
                    ]}
                  />
                ),
              ]}
            />
          )}
          secondLine={(
            <div className={classes.parametersContainer}>
              <DashboardParameterLine
                dashboardId={dashboard.id}
                parametersMapping={parameters}
                selectParameter={selectedParameter}
                shouldFocusParameterEditionOnMount={(id) => {
                  if (lastCreatedParameterIdRef.current === id) {
                    lastCreatedParameterIdRef.current = undefined;
                    return true;
                  } else {
                    return false;
                  }
                }}
                readOnly={readOnly}
              />
            </div>
          )}
        />
      )}
      content={(
        <VerticalBlock>
          <VerticalBlock asBlockContent withSeparation>
            <BlockTitle title={i18n`Widget`} />
            <HorizontalBlock asBlockContent>
              <BlockTitle
                title={i18n`Title display mode`}
                variant={BlockTitleVariant.inline}
              />
              <BlockContent>
                <SearchAndSelect
                  selectedOption={titleModeOptions[widget[Widget_TitleMode] ?? 'auto']}
                  computeOptions={() => Object.values(titleModeOptions)}
                  onSelect={(option) => {
                    if (option) {
                      store.updateObject<WidgetRaw>(widgetId, { [Widget_TitleMode]: option.id });
                    }
                  }}
                />
              </BlockContent>
            </HorizontalBlock>
            <HorizontalBlock asBlockContent>
              <BlockTitle
                title={i18n`Title background color`}
                variant={BlockTitleVariant.inline}
              />
              <BlockContent>
                <StoreColorPickerInput
                  initialValue={headerBackgroundColorHex}
                  onSubmit={(newColor) => {
                    store.updateObject(widgetId, { [Widget_HeaderBackgroundColor]: newColor ?? null });
                  }}
                  colorPalette={colorPalette}
                />
              </BlockContent>
            </HorizontalBlock>
            <HorizontalBlock asBlockContent>
              <BlockTitle
                title={i18n`Mode`}
                variant={BlockTitleVariant.inline}
              />
              <BlockContent>
                <SpacingLine>
                  <SearchAndSelect
                    selectedOption={backgroundOptions[widget[Widget_DisplayMode]?.type ?? 'card']}
                    computeOptions={() => Object.values(backgroundOptions)}
                    onSelect={(option) => {
                      if (option) {
                        store.updateObject<WidgetRaw>(widgetId, {
                          [Widget_DisplayMode]: option.id === 'color' ? { type: 'color', color: base.color.gray['300'] } : { type: option.id },
                        });
                      }
                    }}
                  />
                  {
                    widget[Widget_DisplayMode]?.type === 'color' ? (
                      <StoreColorPickerInput
                        initialValue={widget[Widget_DisplayMode].color}
                        onSubmit={(newColor) => {
                          if (newColor) {
                            store.updateObject<WidgetRaw>(widgetId, { [Widget_DisplayMode]: { type: 'color', color: newColor } });
                          }
                        }}
                        colorPalette={colorPalette}
                        defaultValue={base.color.gray['300']}
                      />
                    ) : null
                  }
                </SpacingLine>
              </BlockContent>
            </HorizontalBlock>
          </VerticalBlock>
          <VerticalBlock asBlockContent withSeparation>
            <BlockTitle title={i18n`Content`} />
            <HorizontalBlock asBlockContent>
              <BlockTitle title={i18n`Type`} />
              <BlockContent>
                <SearchAndSelect
                  clearable
                  placeholder={i18n`Select Widget Type`}
                  computeOptions={() => fieldDefinitionsOptions}
                  selectedOption={widgetTypeSelectedOption}
                  onSelect={(result) => {
                    const previousFieldId = store.getObject(widgetId)[Widget_Field] as string;
                    if (previousFieldId) {
                      store.deleteObject(previousFieldId);
                    }
                    if (result?.id) {
                      if (result.id === NumberField) {
                        const newFieldId = store.createObject({ [Instance_Of]: MirrorField, [MirrorField_TargetedFieldDefinition]: result.id });
                        store.updateObject(widgetId, { [Widget_Field]: newFieldId });
                      } else {
                        const creationHandler = getFieldDefinitionHandler(store, result.id)?.onWidgetCreate;
                        let newFieldId;
                        if (creationHandler) {
                          newFieldId = creationHandler();
                        } else {
                          newFieldId = store.createObject({ [Instance_Of]: result.id });
                        }
                        store.updateObject(widgetId, { [Widget_Field]: newFieldId });
                      }
                    } else {
                      store.updateObject(widgetId, { [Widget_Field]: null });
                    }
                  }}
                  readOnly={readOnly}
                />
              </BlockContent>
            </HorizontalBlock>
          </VerticalBlock>
          {widget[Widget_Field] ? (<WidgetFieldOptions widgetId={widgetId} />) : null}
          <VerticalBlock asBlockContent>
            <BlockTitle
              title={i18n`Preview`}
              buttonIcon={{
                tooltip: showPreview ? i18n`Hide` : i18n`Show`,
                iconName: showPreview ? IconName.expand_more : IconName.keyboard_arrow_right,
                onClick: () => setShowPreview((current) => !current),
              }}
              isGreyed={!showPreview}
            />
            {showPreview ? (
              <BlockContent>
                <DashboardGrid
                  layout={[
                    {
                      id: widgetId,
                      x: 0,
                      y: 0,
                      w: widget[Widget_Width] ?? 3,
                      h: widget[Widget_Height] ?? 3,
                      borderless: widget[Widget_DisplayMode]?.type === 'borderless' || widget[Widget_DisplayMode]?.type === 'color',
                      padded: widget[Widget_DisplayMode]?.type !== 'borderless',
                      backgroundColor: widget[Widget_DisplayMode]?.type === 'color' ? widget[Widget_DisplayMode].color : undefined,
                      renderWidget: (dragHandlerClassName) => (
                        <DashboardWidget
                          widgetId={widgetId}
                          dragHandlerClassName={dragHandlerClassName}
                          parametersMapping={parameters}
                          readOnly
                          isPreview
                        />
                      ),
                    },
                  ]}
                />
              </BlockContent>
            ) : null}
          </VerticalBlock>
        </VerticalBlock>
      )}
    />
  );
};

const DashboardWidgetPage: FunctionComponent<DashboardWidgetPageProps> = ({ widgetId }) => {
  const store = useStore();
  const widget = store.getObject(widgetId);

  const dashboardId = widget[Widget_Dashboard] as string;
  if (!isConceptValid(store, dashboardId)) {
    throw new ObjectNotFoundError('Instance is not valid', i18n`Widget`, widgetId);
  }

  return (
    <NavigationElementContainer element={{ key: widgetId }}>
      <ChipBlockContextProvider context={Dashboard}>
        <ChipBlockContextProvider context={dashboardId}>
          <ChipBlockContextProvider context={widgetId}>
            <DashboardWidgetPageInner widgetId={widgetId} />
          </ChipBlockContextProvider>
        </ChipBlockContextProvider>
      </ChipBlockContextProvider>
    </NavigationElementContainer>
  );
};

export default DashboardWidgetPage;
