import type { FunctionComponent, ReactElement } from 'react';
import { getFieldDimensionOfModelType, getFieldUtilsHandler } from 'yooi-modules/modules/conceptModule';
import type { WrappedRichText } from 'yooi-modules/modules/conceptModule/fields/textField';
import { Field } from 'yooi-modules/modules/conceptModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { RichText } from 'yooi-utils';
import HeaderTitleLine, { HeaderTitleLineVariant as HeaderFromStoreVariant } from '../../components/templates/HeaderTitleLine';
import useAcl from '../../store/useAcl';
import useStore from '../../store/useStore';
import useUpdateActivity from '../../store/useUpdateActivity';
import ActivityIndicator from './multiplayer/ActivityIndicator';

export { HeaderTitleLineVariant as HeaderFromStoreVariant } from '../../components/templates/HeaderTitleLine';

interface HeaderFromStoreProps {
  instanceId: string,
  propertyId: string,
  editionIndicatorAdditionalPropertyIds?: string[],
  placeholder: string,
  restingTooltip?: string | (() => Promise<string>),
  actions?: (ReactElement | null)[],
  readOnly?: boolean,
  isRich?: boolean,
  variant?: HeaderFromStoreVariant,
}

const HeaderFromStore: FunctionComponent<HeaderFromStoreProps> = ({
  instanceId,
  propertyId,
  editionIndicatorAdditionalPropertyIds,
  placeholder,
  restingTooltip,
  actions,
  readOnly = false,
  isRich = false,
  variant = HeaderFromStoreVariant.title,
}) => {
  const store = useStore();
  const updateActivity = useUpdateActivity();
  const { canWriteObject } = useAcl();

  const object = store.getObjectOrNull(instanceId);

  let valueResolution;
  if (isInstanceOf(store.getObject(propertyId), Field)) {
    const dimension = object ? getFieldDimensionOfModelType(store, propertyId, object[Instance_Of] as string) : undefined;
    valueResolution = getFieldUtilsHandler(store, propertyId).getValueResolution?.(dimension ? { [dimension]: instanceId } : {});
  }

  let initialValue = (valueResolution?.getDisplayValue() as WrappedRichText | string | undefined)?.valueOf();
  if (!initialValue) {
    initialValue = object?.[propertyId] as RichText | string | undefined;
  }

  return (
    <HeaderTitleLine
      key={instanceId}
      placeholder={placeholder}
      restingTooltip={restingTooltip}
      value={initialValue}
      readOnly={!canWriteObject(instanceId) || readOnly}
      onEditValue={(conceptName) => store.updateObject(instanceId, { [propertyId]: conceptName })}
      onEditionStart={() => updateActivity.onEnterEdition(instanceId, propertyId)}
      onEditionStop={() => updateActivity.onExitEdition(instanceId, propertyId)}
      variant={variant}
      isRich={isRich}
      left={(<ActivityIndicator propertyIds={[propertyId, ...(editionIndicatorAdditionalPropertyIds ?? [])]} instanceIds={instanceId} padding={{}} />)}
      actions={actions}
    />
  );
};

export default HeaderFromStore;
