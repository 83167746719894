import type { FunctionComponent, ReactElement } from 'react';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import {
  FILTER_PARAMETER_LOGGED_USER,
  getFieldDimensionOfModelType,
  hasPlatformCapability,
  iconFieldHandler,
  isEmbeddedConceptInstance,
  textFieldHandler,
} from 'yooi-modules/modules/conceptModule';
import {
  Concept_Name,
  Concept_StakeholdersGroups,
  Concept_StakeholdersUsers,
  ConceptDefinition_MainIconField,
  ConceptStakeholdersGroupsDimension,
  ConceptStakeholdersUsersDimension,
  Field_IntegrationOnly,
  KinshipRelation,
  PlatformCapabilityAdmin,
} from 'yooi-modules/modules/conceptModule/ids';
import {
  Dashboard,
  Dashboard_Parameters,
  DashboardParameter,
  DashboardParameter_Dashboard,
  DashboardParameter_Rank,
  Widget,
  Widget_Dashboard,
  Widget_Height,
  Widget_Width,
  Widget_X,
  Widget_Y,
} from 'yooi-modules/modules/dashboardModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { StoreObject } from 'yooi-store';
import { compareRank, extractAndCompareValue, ranker } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../components/atoms/Button';
import { IconName } from '../../../components/atoms/Icon';
import Chooser from '../../../components/molecules/Chooser';
import type { MenuItem } from '../../../components/molecules/Menu';
import OverflowMenu from '../../../components/molecules/OverflowMenu';
import SpacingLine from '../../../components/molecules/SpacingLine';
import BaseLayout from '../../../components/templates/BaseLayout';
import Header from '../../../components/templates/Header';
import HeaderActions from '../../../components/templates/HeaderActions';
import HeaderLine from '../../../components/templates/HeaderLine';
import VerticalBlock from '../../../components/templates/VerticalBlock';
import useAcl from '../../../store/useAcl';
import useAuth from '../../../store/useAuth';
import useStore from '../../../store/useStore';
import useUpdateActivity from '../../../store/useUpdateActivity';
import { spacingRem } from '../../../theme/spacingDefinition';
import i18n from '../../../utils/i18n';
import makeStyles from '../../../utils/makeStyles';
import useNavigation from '../../../utils/useNavigation';
import { HierarchyVariant, SizeContextProvider, SizeVariant } from '../../../utils/useSizeContext';
import { iconFieldDefinition } from '../../_global/fields/iconField/iconFieldDefinition';
import { stakeholdersFieldDefinition } from '../../_global/fields/stakeholdersField/stakeholdersFieldDefinition';
import { getLastUpdateBy } from '../../_global/historyUtils';
import StoreRichTextInputField from '../../_global/input/StoreRichTextInputField';
import ActivityBar from '../../_global/multiplayer/ActivityBar';
import ActivityIndicator from '../../_global/multiplayer/ActivityIndicator';
import type { NavigationFilter } from '../../_global/navigationUtils';
import CollaborationButton from '../../_global/rightPanel/collaboration/CollaborationButton';
import CollaborationRightPanel from '../../_global/rightPanel/collaboration/CollaborationRightPanel';
import { getIntentions } from '../../_global/rightPanel/collaboration/utils/collaborationUtils';
import TopBar from '../../_global/topBar/TopBar';
import useConceptDeleteModal from '../../_global/useConceptDeleteModal';
import { DashboardLayoutVariants, useDashboardLayoutMode, useToggleDashboardLayoutMode } from '../../utils/useDashboardLayoutMode';
import { getConceptHeaderMenuItems } from '../_global/conceptHeaderUtils';
import DashboardLayoutTab from './DashboardLayoutTab';
import DashboardParameterLine from './DashboardParameterLine';
import useDashboardParameterState from './useDashboardParameterState';

const useStyles = makeStyles({
  parametersContainer: {
    display: 'flex',
    alignItems: 'center',
    gridColumnStart: 2,
    paddingTop: spacingRem.s,
    paddingBottom: spacingRem.s,
  },
}, 'dashboardDetailPage');

interface DashboardDetailPageProps {
  dashboardId: string,
}

const DashboardDetailPage: FunctionComponent<DashboardDetailPageProps> = ({ dashboardId }) => {
  const classes = useStyles();

  const store = useStore();
  const { loggedUserId } = useAuth();
  const aclHandler = useAcl();

  const updateActivity = useUpdateActivity();

  const location = useLocation();
  const navigation = useNavigation<NavigationFilter>();

  const lastCreatedParameterIdRef = useRef<string>();
  const [parameters, selectedParameter] = useDashboardParameterState(dashboardId);

  const dashboard = store.getObject(dashboardId);

  const readOnly: boolean = !aclHandler.canWriteObject(dashboardId)
    || (isEmbeddedConceptInstance(dashboard) ? dashboard.navigate(KinshipRelation)[Field_IntegrationOnly] as boolean : false);

  const [doDelete, deleteModal] = useConceptDeleteModal(true);

  const mode = useDashboardLayoutMode();
  const toggleMode = useToggleDashboardLayoutMode();
  const isViewMode = mode === DashboardLayoutVariants.view || readOnly;

  const tabs = [
    {
      key: 'layout',
      name: i18n`Layout`,
      hash: '#layout',
      render: () => (<DashboardLayoutTab dashboardId={dashboardId} parametersMapping={parameters} readOnly={readOnly} isViewMode={isViewMode} />),
    }, {
      key: 'stakeholders',
      name: i18n`Stakeholders`,
      hash: '#stakeholders',
      render: () => {
        const fieldDefinition = stakeholdersFieldDefinition(store);
        const userFieldHandler = fieldDefinition.getHandler(Concept_StakeholdersUsers);
        const userIcon = userFieldHandler?.configuration.getIcon();

        const groupFieldHandler = fieldDefinition.getHandler(Concept_StakeholdersGroups);
        const groupIcon = groupFieldHandler?.configuration.getIcon();

        return (
          <VerticalBlock>
            {
              userFieldHandler?.renderBlockField?.(
                { [ConceptStakeholdersUsersDimension]: dashboardId },
                {},
                {
                  icon: typeof userIcon === 'object' ? userIcon.name : userIcon,
                  title: userFieldHandler.configuration.getTitle(),
                  documentation: userFieldHandler.configuration.getDocumentation(),
                  readOnly,
                },
                { [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: loggedUserId } },
                [],
                uuid()
              ) ?? null
            }
            {
              groupFieldHandler?.renderBlockField?.(
                { [ConceptStakeholdersGroupsDimension]: dashboardId },
                {},
                {
                  icon: typeof groupIcon === 'object' ? groupIcon.name : groupIcon,
                  title: groupFieldHandler.configuration.getTitle(),
                  documentation: groupFieldHandler.configuration.getDocumentation(),
                  readOnly,
                },
                { [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: loggedUserId } },
                [],
                uuid()
              ) ?? null
            }
          </VerticalBlock>
        );
      },
    },
  ];

  const hashIndex = tabs.findIndex((tab) => tab.hash === location.hash);
  const selectedTabIndex = hashIndex !== -1 ? hashIndex : 0;
  const selectedTab = tabs[selectedTabIndex];

  const getAction = () => {
    const actions = [];

    if (!isViewMode && selectedTab.key === 'layout') {
      actions.push((
        <Button
          key="addWidget"
          iconName={IconName.add}
          title={i18n`Add widget`}
          onClick={() => {
            store.createObject({
              [Instance_Of]: Widget,
              [Widget_Dashboard]: dashboardId,
              [Widget_Width]: 8,
              [Widget_Height]: 20,
              [Widget_X]: 0,
              [Widget_Y]: 0,
            });
          }}
        />
      ));
      actions.push((
        <Button
          key="addParameter"
          iconName={IconName.add}
          variant={ButtonVariant.secondary}
          title={i18n`Add parameter`}
          onClick={() => {
            const allParameters = dashboard
              .navigateBack(Dashboard_Parameters)
              .sort(extractAndCompareValue((parameter: StoreObject) => parameter[DashboardParameter_Rank] as string, compareRank));

            const lastRank = allParameters.length === 0 ? undefined : allParameters[allParameters.length - 1][DashboardParameter_Rank] as string;
            const nextRank = ranker.createNextRankGenerator(lastRank)();

            lastCreatedParameterIdRef.current = store.createObject({
              [Instance_Of]: DashboardParameter,
              [DashboardParameter_Dashboard]: dashboardId,
              [DashboardParameter_Rank]: nextRank,
            });
          }}
        />
      ));
    }

    if (!isViewMode || selectedTab.key !== 'layout') {
      actions.push((
        <Chooser
          key="tabs"
          actions={tabs}
          selectedIndexes={[selectedTabIndex]}
          onClick={(index) => {
            navigation.replace(dashboardId, { pathname: location.pathname, hash: tabs[index].hash });
          }}
        />
      ));
    }

    const menuItems: MenuItem[] = [];

    if (!readOnly) {
      menuItems.push({
        key: isViewMode ? 'edit' : 'view',
        name: isViewMode ? 'Edit mode' : 'View mode',
        icon: isViewMode ? IconName.edit : IconName.visibility,
        onClick: toggleMode,
      });
    }
    const isAdmin = hasPlatformCapability(store, loggedUserId, PlatformCapabilityAdmin);
    menuItems.push(...getConceptHeaderMenuItems(navigation, store, aclHandler, isAdmin, () => doDelete(dashboardId), dashboardId));
    if (getIntentions(store, Dashboard).length > 0) {
      actions.push((<CollaborationButton key="collaboration" />));
    }
    actions.push((<OverflowMenu key="overflowMenu" menuItems={menuItems} />));
    return actions;
  };

  const hasParameters = dashboard.navigateBack(Dashboard_Parameters).length > 0;

  const nameFieldHandler = textFieldHandler(store, Concept_Name);
  const nameDimensionId = getFieldDimensionOfModelType(store, Concept_Name, Dashboard);
  const nameInput = (
    <StoreRichTextInputField
      initialValue={nameDimensionId === undefined ? undefined : nameFieldHandler.getValueResolution({ [nameDimensionId]: dashboardId }).value?.valueOf()}
      onSubmit={(newName) => {
        if (nameDimensionId !== undefined) {
          nameFieldHandler.updateValue({ [nameDimensionId]: dashboardId }, newName);
        }
      }}
      placeholder={i18n`Click to edit name`}
      restingTooltip={() => getLastUpdateBy(store, dashboardId, Concept_Name, undefined)}
      readOnly={readOnly || isViewMode}
      onEditionStart={() => updateActivity.onEnterEdition(dashboardId, Concept_Name)}
      onEditionStop={() => updateActivity.onExitEdition(dashboardId, Concept_Name)}
      maxLine={1}
      dropdownMaxLine={5}
      dropdownSizes={{ sameWidth: false }}
    />
  );

  let iconInput: ReactElement | undefined;
  const mainIconField = store.getObject(Dashboard).navigateOrNull(ConceptDefinition_MainIconField);
  if (mainIconField !== null) {
    const iconDimensionId = getFieldDimensionOfModelType(store, mainIconField.id, Dashboard);
    if (iconDimensionId !== undefined && (!isViewMode || iconFieldHandler(store, mainIconField.id).getValueResolution({ [iconDimensionId]: dashboardId }).value !== undefined)) {
      iconInput = iconFieldDefinition(store)
        .getHandler(mainIconField.id)
        .renderField?.({ dimensionsMapping: { [iconDimensionId]: dashboardId }, readOnly: readOnly || isViewMode }) ?? undefined;
    }
  }

  return (

    <BaseLayout
      topBar={(<TopBar actions={(<ActivityBar objectId={dashboardId} />)} />)}
      header={(
        <Header
          firstLine={(
            <HeaderLine
              left={(<ActivityIndicator propertyIds={[Concept_Name]} instanceIds={dashboardId} />)}
              actions={<HeaderActions>{getAction()}</HeaderActions>}
              padded={iconInput !== undefined}
            >
              <SizeContextProvider sizeVariant={SizeVariant.title} hierarchyVariant={HierarchyVariant.content}>
                {
                  iconInput !== undefined
                    ? (
                      <SpacingLine fullWidth>
                        {iconInput}
                        {nameInput}
                      </SpacingLine>
                    )
                    : nameInput
                }
              </SizeContextProvider>
            </HeaderLine>
          )}
          secondLine={
            hasParameters
              ? (
                <div className={classes.parametersContainer}>
                  <DashboardParameterLine
                    dashboardId={dashboardId}
                    parametersMapping={parameters}
                    selectParameter={selectedParameter}
                    shouldFocusParameterEditionOnMount={(id) => {
                      if (lastCreatedParameterIdRef.current === id) {
                        lastCreatedParameterIdRef.current = undefined;
                        return true;
                      } else {
                        return false;
                      }
                    }}
                    readOnly={readOnly}
                  />
                </div>
              )
              : null
          }
        />
      )}
      content={(
        <>
          {selectedTab.render()}
          {deleteModal}
        </>
      )}
      rightPanel={(<CollaborationRightPanel />)}
    />
  );
};

export default DashboardDetailPage;
