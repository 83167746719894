import type { FunctionComponent } from 'react';
import type { StoreObject } from 'yooi-store';
import { compareString, comparing } from 'yooi-utils';
import { TableSortDirection } from '../../../../components/molecules/Table';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import { formatOrUndef } from '../../../../utils/stringUtils';
import type { ComparatorHandler } from '../../../_global/useFilterAndSort';
import { getInstanceHint } from '../_global/explorerUtils';
import { useExplorerHint } from '../_global/GetHintContextProvider';
import { ValueType } from '../_global/objectRenderType';
import StringRenderer from '../_global/StringRenderer';
import ValueRenderer from '../_global/ValueRenderer';
import InstanceTable from './InstanceTable';

const useStyles = makeStyles({
  cell: {
    display: 'flex',
    paddingLeft: spacingRem.s,
    paddingRight: spacingRem.s,
  },
}, 'instanceAssociationsTab');

interface InstanceAssociationsTabProps {
  instanceId: string,
}

const InstanceAssociationsTab: FunctionComponent<InstanceAssociationsTabProps> = ({ instanceId }) => {
  const classes = useStyles();

  const store = useStore();
  const getHint = useExplorerHint();

  const associations = store
    .listObjects()
    .filter((object): object is StoreObject<string[]> => Array.isArray(object.id) && object.id.indexOf(instanceId) !== -1)
    .map((object) => ({ id: object.key, key: object.key, hint: getInstanceHint(store, getHint, object.id), associationId: object.id[0], associationHint: getHint(object.id[0]) }));

  return (
    <InstanceTable
      filterId={`InstanceAssociationsTab_${instanceId}`}
      list={associations}
      searchStrings={(item) => [item.id, item.hint]}
      sort={{
        getComparatorHandler: (key, direction) => {
          switch (key) {
            case 'id':
              return {
                comparator: comparing(compareString, direction === TableSortDirection.desc),
                extractValue: (item) => item.hint ?? item.id,
              } satisfies ComparatorHandler<typeof associations[0], string | undefined>;
            case 'associationId':
              return {
                comparator: comparing(compareString, direction === TableSortDirection.desc),
                extractValue: (item) => item.associationHint ?? item.associationId,
              } satisfies ComparatorHandler<typeof associations[0], string | undefined>;
            default:
              return undefined;
          }
        },
        initial: { key: 'id', direction: TableSortDirection.asc },
      }}
      groupBy={{
        computeSelectedOption: (groupById) => (groupById ? ({ id: groupById, label: groupById }) : undefined),
        computeOptions: () => ['associationId', 'id'].map((groupById) => ({ id: groupById, label: groupById })),
        defaultGroupById: 'associationId',
        getGroupLabel: (key) => formatOrUndef(key ? (getHint(key) ?? key) : undefined),
      }}
      columnsDefinition={[
        {
          propertyId: 'id',
          name: i18n`Id`,
          sortable: true,
          width: 70,
          cellRender: ({ key, hint }) => (
            <div className={classes.cell}>
              <StringRenderer string={{ type: ValueType.string, value: key, hint, href: `/settings/explorer/instance/${key}`, maxLine: 1 }} />
            </div>
          ),
        },
        {
          propertyId: 'associationId',
          name: i18n`Association`,
          sortable: true,
          width: 30,
          cellRender: ({ associationId, associationHint }) => (
            <div className={classes.cell}>
              <ValueRenderer
                key={associationId}
                value={{ type: ValueType.string, value: associationId, hint: associationHint, href: `/settings/explorer/instance/${associationId}`, maxLine: 1 }}
              />
            </div>
          ),
        },
      ]}
    />
  );
};

export default InstanceAssociationsTab;
