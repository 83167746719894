import type { FunctionComponent, ReactElement, ReactNode } from 'react';
import { useState } from 'react';
import { WorkflowRelationDisplayType } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import { Field_Title } from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import Icon, { IconName } from '../../../../components/atoms/Icon';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import BlockContent from '../../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../../components/templates/BlockTitle';
import HorizontalBlock from '../../../../components/templates/HorizontalBlock';
import VerticalBlock from '../../../../components/templates/VerticalBlock';
import useStore from '../../../../store/useStore';
import i18n from '../../../../utils/i18n';
import { formatOrUndef } from '../../../../utils/stringUtils';
import CollaborationButton from '../../rightPanel/collaboration/CollaborationButton';
import { getIntentions } from '../../rightPanel/collaboration/utils/collaborationUtils';
import { hasTransitionAvailable, listFieldIdsOfWorkflowField } from '../../workflow/workflowUtils';
import WorkflowFieldRenderer from '../workflowField/WorkflowFieldRenderer';
import BlockFieldTooltip from './BlockFieldTooltip';
import { BlockFieldDisplayStatus } from './blockFieldUtils';

interface BlockFieldProps {
  fieldId: string,
  workflowFieldId?: string,
  workflowFieldDisplayType?: WorkflowRelationDisplayType,
  readOnly?: boolean,
  conceptId?: string,
  title?: string,
  documentation?: { value: string, isInline: boolean },
  icon?: IconName,
  children: ReactNode,
  errorTooltip?: string,
  context?: string,
  displayStatus?: BlockFieldDisplayStatus,
  updateDisplayStatus?: (displayStatus: BlockFieldDisplayStatus) => void,
  isVertical?: boolean,
  hideBlockTitle?: boolean,
  rightActions?: ReactElement | null,
  inColumn?: boolean,
}

const BlockField: FunctionComponent<BlockFieldProps> = ({
  fieldId,
  workflowFieldId,
  workflowFieldDisplayType = WorkflowRelationDisplayType.Value,
  readOnly = false,
  conceptId,
  title,
  documentation,
  icon,
  children,
  errorTooltip,
  context,
  displayStatus = BlockFieldDisplayStatus.locked,
  updateDisplayStatus,
  isVertical = false,
  hideBlockTitle = false,
  rightActions,
  inColumn = false,
}) => {
  const isVerticalDisplay = isVertical || inColumn;
  const store = useStore();
  const [isHovered, setIsHovered] = useState(false);

  const hasWorkflow = workflowFieldId && conceptId
    && listFieldIdsOfWorkflowField(store, workflowFieldId).includes(fieldId)
    && (workflowFieldDisplayType !== WorkflowRelationDisplayType.Button || hasTransitionAvailable(store, workflowFieldId, conceptId, fieldId));

  const hasDocumentation = !!documentation && !!documentation.value;
  const isInlineDocumentation = hasDocumentation && documentation.isInline && displayStatus !== BlockFieldDisplayStatus.hidden;

  const buttonIcon = updateDisplayStatus && displayStatus !== BlockFieldDisplayStatus.locked ? {
    tooltip: displayStatus === BlockFieldDisplayStatus.visible ? i18n`Hide` : i18n`Show`,
    iconName: displayStatus === BlockFieldDisplayStatus.visible ? IconName.remove : IconName.add,
    onClick: () => updateDisplayStatus(displayStatus === BlockFieldDisplayStatus.visible ? BlockFieldDisplayStatus.hidden : BlockFieldDisplayStatus.visible),
  } : undefined;

  const workflowField = workflowFieldId ? store.getObjectOrNull(workflowFieldId) : undefined;

  const intentions = getIntentions(store, conceptId ? store.getObject(conceptId)[Instance_Of] as string : undefined);
  const renderDocumentation = () => {
    if (isInlineDocumentation) {
      return (
        <BlockTitle
          variant={BlockTitleVariant.inline}
          title={documentation.value}
          iconName={icon}
          buttonIcon={buttonIcon}
        />
      );
    } else {
      return null;
    }
  };

  const renderTitle = () => {
    if (hideBlockTitle) {
      return null;
    }

    return (
      <BlockTitle
        variant={isVerticalDisplay ? BlockTitleVariant.inline : undefined}
        title={title}
        iconName={isInlineDocumentation ? undefined : icon}
        buttonIcon={isInlineDocumentation ? undefined : buttonIcon}
        infoTooltip={(hasDocumentation && !isInlineDocumentation) ? { render: () => (<BlockFieldTooltip documentation={documentation} />) } : undefined}
        errorTooltip={errorTooltip}
        actions={intentions.length > 0 ? (<CollaborationButton fieldId={fieldId} forceDisplay={isHovered} />) : undefined}
        isGreyed={displayStatus === BlockFieldDisplayStatus.hidden}
        rightActions={rightActions}
        context={inColumn ? undefined : (context ?? fieldId)}
        verticalSpan={hasWorkflow && workflowFieldDisplayType === WorkflowRelationDisplayType.ValueAndButton ? 2 : undefined}
        onTitleMouseEnter={() => setIsHovered(true)}
        onTitleMouseLeave={() => setIsHovered(false)}
      />
    );
  };

  const workflow = hasWorkflow && workflowFieldDisplayType === WorkflowRelationDisplayType.ValueAndButton ? (
    <BlockContent padded>
      <SpacingLine>
        <Icon name={IconName.subdirectory_arrow_right} tooltip={formatOrUndef(workflowField?.[Field_Title] as string | undefined)} />
        <WorkflowFieldRenderer
          fieldId={workflowFieldId}
          subFieldId={fieldId}
          conceptId={conceptId}
          displayType={workflowFieldDisplayType ?? WorkflowRelationDisplayType.Value}
          readOnly={readOnly}
        />
      </SpacingLine>
    </BlockContent>
  ) : null;

  const workflowChip = hasWorkflow && workflowFieldDisplayType !== WorkflowRelationDisplayType.ValueAndButton && displayStatus !== BlockFieldDisplayStatus.hidden ? (
    <WorkflowFieldRenderer
      fieldId={workflowFieldId}
      subFieldId={fieldId}
      conceptId={conceptId}
      displayType={WorkflowRelationDisplayType.Color}
      readOnly={readOnly}
    />
  ) : null;

  const field = displayStatus !== BlockFieldDisplayStatus.hidden ? children : null;

  if (isVerticalDisplay) {
    return (
      <VerticalBlock withHighlightBlock={context ?? fieldId} compact asBlockContent>
        {renderDocumentation()}
        {renderTitle()}
        {workflowChip}
        {field}
        {workflow}
      </VerticalBlock>
    );
  } else {
    let content;
    if (!hasWorkflow) {
      content = (
        <HorizontalBlock withHighlightBlock={isVerticalDisplay ? undefined : (context ?? fieldId)} asBlockContent>
          {renderTitle()}
          {field}
        </HorizontalBlock>
      );
    } else if (workflowFieldDisplayType === WorkflowRelationDisplayType.ValueAndButton) {
      content = (
        <HorizontalBlock withHighlightBlock={isVerticalDisplay ? undefined : (context ?? fieldId)} asBlockContent compact>
          {renderTitle()}
          {field}
          {workflow}
        </HorizontalBlock>
      );
    } else {
      content = (
        <HorizontalBlock withHighlightBlock={isVerticalDisplay ? undefined : (context ?? fieldId)} asBlockContent>
          {renderTitle()}
          {field}
          <span />
          {workflowChip}
        </HorizontalBlock>
      );
    }

    if (hasDocumentation && isInlineDocumentation) {
      return (
        <VerticalBlock withHighlightBlock={context ?? fieldId} compact asBlockContent>
          {renderDocumentation()}
          {content}
        </VerticalBlock>
      );
    } else {
      return content;
    }
  }
};

export default BlockField;
