import { equals } from 'ramda';
import type { ReactElement } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import useDerivedState from '../../../utils/useDerivedState';

interface FormInputChildrenProps<Value> {
  value: Value | undefined,
  onChange: (newValue: Value | undefined) => void,
  onSubmit: (value: Value | undefined) => void,
  onCancel: () => void,
}

interface FormInputProps<Value> {
  initialValue: Value | undefined,
  onSubmit?: (value: Value | null) => void,
  onChange?: (value: Value | null) => void,
  onChangeDebounced?: (value: Value | null) => void,
  children: (props: FormInputChildrenProps<Value>) => ReactElement | null,
}

const FormInput = <Value>({
  initialValue,
  onSubmit,
  onChange,
  onChangeDebounced,
  children,
}: FormInputProps<Value>): ReactElement | null => {
  const [initValue, setInitValue] = useDerivedState(() => initialValue, [initialValue]);
  const [value, setValue, resetValue] = useDerivedState(() => initValue, [initValue]);

  const onChangeDebouncedInner = useDebouncedCallback((v) => {
    onChangeDebounced?.(v);
  }, 300);

  const props: FormInputChildrenProps<Value> = {
    value,
    onChange: (newValue) => {
      setValue(newValue ?? undefined);
      onChange?.(newValue ?? null);
      onChangeDebouncedInner?.(newValue);
    },
    onSubmit: (valueToSubmit) => {
      setInitValue(valueToSubmit ?? undefined);
      setValue(valueToSubmit ?? undefined);
      if (!equals(initialValue, valueToSubmit)) {
        onSubmit?.(valueToSubmit ?? null);
      }
    },
    onCancel: () => {
      resetValue();
      onChange?.(initialValue ?? null);
      onChangeDebouncedInner(initialValue);
    },
  };

  return (
    children(props)
  );
};

export default FormInput;
