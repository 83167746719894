import type { FunctionComponent } from 'react';
import { useRef } from 'react';
import { getConceptDefinitionValidFields, getFieldDimensionOfModelType, getFieldUtilsHandler } from 'yooi-modules/modules/conceptModule';
import type { StoreObject } from 'yooi-store';
import { dateFormats, formatDisplayDate } from 'yooi-utils';
import { IconName } from '../../components/atoms/Icon';
import IconOnlyButton, { IconOnlyButtonVariants } from '../../components/atoms/IconOnlyButton';
import InlineLoading from '../../components/molecules/InlineLoading';
import useStore from '../../store/useStore';
import i18n from '../../utils/i18n';
import { formatOrUndef } from '../../utils/stringUtils';
import withAsyncTask from '../../utils/withAsyncTask';
import { getObjectName } from './modelTypeUtils';
import useExport from './useExport';

interface ConceptListExportButtonProps {
  generateList: () => StoreObject[],
  typeId: string,
  displayedFieldsIds: string[],
  parentName?: string,
}

interface Header {
  type: 'header',
  key: string,
}

type Line = { type: 'line', key: string, conceptId: string } | Header;

const ConceptListExportButton: FunctionComponent<ConceptListExportButtonProps> = withAsyncTask(({ executeAsyncTask, generateList, typeId, displayedFieldsIds, parentName }) => {
  const store = useStore();

  const modelType = store.getObject(typeId);

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const { exportToExcel: innerExportToExcel } = useExport<{ key: string, label: string, fieldId: string }, Line>(executeAsyncTask);

  const lines: Line[] = [{ type: 'header', key: 'header' }];
  const list = generateList();
  for (let i = 0; i < list.length; i += 1) {
    const instance = list[i];
    lines.push({ type: 'line', key: instance.id, conceptId: instance.id });
  }

  const exportToExcel = (allField?: boolean) => {
    const excelName = formatOrUndef(getObjectName(store, modelType));
    innerExportToExcel({
      fileName: parentName
        ? `${formatDisplayDate(new Date(), dateFormats.isoDateFormat)},${parentName}-${excelName}-export.xlsx`
        : `${formatDisplayDate(new Date(), dateFormats.isoDateFormat)},${excelName}-export.xlsx`,
      lines,
      columns: (allField
        ? getConceptDefinitionValidFields(store, modelType.id)
          .filter(({ id }, index, exportList) => exportList.findIndex(({ id: foundId }) => foundId === id) === index)
        : displayedFieldsIds.map((id) => store.getObject(id)))
        .map((f, index) => ({
          key: `${f.id}_${index}`,
          fieldId: f.id,
          label: formatOrUndef(getFieldUtilsHandler(store, f.id).resolveConfiguration().title),
        })),
      cellResolver: (asyncObjectStore, line, column) => {
        if (line.type === 'header') {
          return { format: 'string', value: column.label };
        }
        const dimension = getFieldDimensionOfModelType(asyncObjectStore, column.fieldId, typeId);
        const dimensionsMapping = dimension ? { [dimension]: line.conceptId } : {};
        const fieldUtilsHandler = getFieldUtilsHandler(asyncObjectStore, column.fieldId);
        return { format: 'string', value: fieldUtilsHandler.getValueAsText?.(dimensionsMapping ?? {}) };
      },
    });
  };

  return (
    <IconOnlyButton
      ref={buttonRef}
      tooltip={i18n`Export`}
      iconName={IconName.file_save_outline}
      variant={IconOnlyButtonVariants.secondary}
      onClick={() => {
        exportToExcel();
      }}
    />
  );
}, InlineLoading);

export default ConceptListExportButton;
