import { equals } from 'ramda';
import type { FunctionComponent } from 'react';
import { v4 as uuid } from 'uuid';
import { getConceptUrl } from 'yooi-modules/modules/conceptModule';
import { Concept_Name, Field_IntegrationOnly, KinshipRelation } from 'yooi-modules/modules/conceptModule/ids';
import {
  DataAsset,
  DataAsset_Type,
  DataAssetType,
  DataAssetType_DefaultResourceType,
  DataAssetType_IsDefaultType,
  DataAssetTypeResourceTypes,
  DataAssetTypeResourceTypes_Role_DataAssetType,
  DataAssetTypeResourceTypes_Role_ResourceType,
} from 'yooi-modules/modules/dataAssetModule/ids';
import { Resource_Image, Resource_Type, Resource_URL, ResourceType } from 'yooi-modules/modules/resourceModule/ids';
import { Class_Instances } from 'yooi-modules/modules/typeModule/ids';
import type { StoreObject } from 'yooi-store';
import { filterNullOrUndefined, joinObjects, textToRichText } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import useAcl from '../../../../store/useAcl';
import useStore from '../../../../store/useStore';
import useUpdateActivity from '../../../../store/useUpdateActivity';
import { formatOrUndef } from '../../../../utils/stringUtils';
import useDerivedState from '../../../../utils/useDerivedState';
import { defaultOptionComparator, getChipOptions } from '../../modelTypeUtils';
import type { ChipOption } from '../../modelTypeUtilsType';
import ResourceInputField from '../../ResourceInputField';
import { getOrCreateResource, getUrlMetadata, updateStoreForTransactionalResourceAndDataAsset } from '../../resourceUtils';

interface ConceptResourceFieldProps {
  conceptId: string,
  resourceFieldId: string,
  readOnly?: boolean,
}

const ConceptResourceField: FunctionComponent<ConceptResourceFieldProps> = ({ conceptId, resourceFieldId, readOnly = false }) => {
  const store = useStore();
  const updateActivity = useUpdateActivity();
  const { canWriteObject } = useAcl();

  const concept = store.getObject(conceptId);

  const [editedData, setEditedData] = useDerivedState<{
    resourceId?: string | null,
    dataAssetId?: string | null,
    dataAssetName?: string,
    dataAssetType?: string | null,
  }>(() => ({}), [concept[resourceFieldId]]);

  let storedResource: StoreObject | undefined;
  if (editedData.resourceId) {
    storedResource = store.getObjectOrNull(editedData.resourceId) ?? undefined;
  } else if (editedData.resourceId !== null && concept[resourceFieldId]) {
    storedResource = store.getObjectOrNull(concept[resourceFieldId] as string) ?? undefined;
  }

  let storedDataAsset: StoreObject | undefined;
  if (editedData.dataAssetId) {
    storedDataAsset = store.getObjectOrNull(editedData.dataAssetId) ?? undefined;
  } else if (editedData.dataAssetId !== null && storedResource?.[storedResource[KinshipRelation] as string]) {
    storedDataAsset = store.getObjectOrNull(storedResource[storedResource[KinshipRelation] as string] as string) ?? undefined;
  }

  const defaultDataAssetType = store.getObject(DataAssetType)
    .navigateBack(Class_Instances)
    .find((type) => type[DataAssetType_IsDefaultType])?.id;

  const storedData = {
    resourceId: storedResource?.id,
    resourceUrl: storedResource?.[Resource_URL] as string | undefined,
    resourceType: storedResource?.[Resource_Type] as string | undefined,
    resourceImage: storedResource?.[Resource_Image] ? {
      id: storedResource?.[Resource_Image] as string | undefined,
      url: storedResource?.[Resource_Image] ? store.getAttachmentUrl(storedResource?.id, Resource_Image)?.(storedResource[Resource_Image] as string, true) : undefined,
    } : undefined,
    dataAssetId: storedDataAsset?.id,
    dataAssetName: storedDataAsset?.[Concept_Name] as string | undefined,
    dataAssetType: storedDataAsset?.id ? storedDataAsset?.[DataAsset_Type] as string | undefined : defaultDataAssetType,
  };
  const mergedData = joinObjects(storedData, editedData);

  const resourceMetadata: {
    type?: string,
    assetKey?: Record<string, unknown>,
    generateUrl?: () => string | undefined,
  } = mergedData.resourceUrl ? getUrlMetadata(mergedData.resourceUrl) : {};

  let defaultResourceType: string | undefined;
  if (!storedResource && (mergedData.dataAssetType || defaultDataAssetType)) {
    defaultResourceType = store.getObject((mergedData.dataAssetType ?? defaultDataAssetType) as string)[DataAssetType_DefaultResourceType] as string | undefined;
  }
  const isReadOnly = readOnly || !!store.getObject(resourceFieldId)[Field_IntegrationOnly] || !canWriteObject(concept.id);

  let selectedDataAssetOption: ChipOption | undefined;
  if (mergedData.dataAssetName && !storedDataAsset) {
    const object = storedResource;
    selectedDataAssetOption = {
      id: mergedData.dataAssetId ?? '',
      icon: IconName.add,
      label: formatOrUndef(mergedData.dataAssetName),
      tooltip: mergedData.dataAssetName,
      getNavigationPayload: object?.[object[KinshipRelation] as string]
        ? () => ({ to: getConceptUrl(store, object[object[KinshipRelation] as string] as string) }) : undefined,
      object: { [Concept_Name]: textToRichText(mergedData.dataAssetName) } as unknown as StoreObject,
    };
  } else if (mergedData.dataAssetId) {
    selectedDataAssetOption = getChipOptions(store, mergedData.dataAssetId);
  }

  return (
    <ResourceInputField
      resourceReadOnly={!!mergedData.resourceId && Boolean(store.getObjectOrNull(mergedData.resourceId)) && !canWriteObject(mergedData.resourceId)}
      readOnly={isReadOnly}
      url={mergedData.resourceUrl}
      type={mergedData.resourceType === undefined ? defaultResourceType : mergedData.resourceType}
      href={resourceMetadata.generateUrl?.()}
      analyzer={resourceMetadata.type}
      image={mergedData.resourceImage}
      getResourceTypeOptions={() => (mergedData.dataAssetType
        ? store.withAssociation(DataAssetTypeResourceTypes)
          .withRole(DataAssetTypeResourceTypes_Role_DataAssetType, mergedData.dataAssetType)
          .list()
          .map((assoc) => assoc.role(DataAssetTypeResourceTypes_Role_ResourceType))
          .filter((id, index, arr) => arr.indexOf(id) === index)
          .map((id) => getChipOptions(store, id))
          .filter(filterNullOrUndefined)
        : store.getObject(ResourceType)
          .navigateBack(Class_Instances)
          .map(({ id }) => getChipOptions(store, id))
          .filter(filterNullOrUndefined))
        .sort(defaultOptionComparator)}
      dataAssetType={mergedData.dataAssetType ?? undefined}
      selectedDataAssetOption={selectedDataAssetOption}
      getDataAssetTypeOptions={() => store.getObject(DataAssetType)
        .navigateBack(Class_Instances)
        .map(({ id }) => getChipOptions(store, id))
        .filter(filterNullOrUndefined)
        .sort(defaultOptionComparator)}
      getDataAssetOptions={() => {
        let dataAssetOptions: ChipOption[] = store.getObject(DataAsset)
          .navigateBack(Class_Instances)
          .filter(({ id }) => canWriteObject(id))
          .map((d) => getChipOptions(store, d.id))
          .filter(filterNullOrUndefined)
          .sort(defaultOptionComparator);
        if (mergedData.dataAssetName && !storedDataAsset) {
          const object = storedResource;
          dataAssetOptions = [
            ...dataAssetOptions,
            {
              id: mergedData.dataAssetId ?? '',
              icon: IconName.add,
              label: mergedData.dataAssetName,
              tooltip: mergedData.dataAssetName,
              getNavigationPayload: object?.[object[KinshipRelation] as string]
                ? () => ({ to: getConceptUrl(store, object[object[KinshipRelation] as string] as string) }) : undefined,
              object: { [Concept_Name]: textToRichText(mergedData.dataAssetName) } as unknown as StoreObject,
            },
          ];
        }
        return dataAssetOptions;
      }}
      onEditionStart={() => updateActivity.onEnterEdition(concept.id, resourceFieldId)}
      onEditionStop={() => {
        updateActivity.onExitEdition(concept.id, resourceFieldId);
        setEditedData({});
      }}
      onDataAssetCreate={(value) => {
        const dataAssetId = uuid();
        setEditedData((prev) => (joinObjects(
          prev,
          {
            dataAssetId,
            dataAssetName: value,
            dataAssetType: defaultDataAssetType,
          }
        )));
        return dataAssetId;
      }}
      onDataAssetUpdate={(value) => {
        setEditedData((prev) => {
          const result = joinObjects(
            prev,
            { dataAssetId: value?.id }
          );
          delete result.dataAssetName;
          delete result.dataAssetType;
          return result;
        });
      }}
      onDataAssetTypeUpdate={(value) => {
        if (!value) {
          return;
        }
        setEditedData((prev) => (joinObjects(
          prev,
          { dataAssetType: value?.id ?? null }
        )));
        const newResourceTypes = store.withAssociation(DataAssetTypeResourceTypes)
          .withRole(DataAssetTypeResourceTypes_Role_DataAssetType, value.id)
          .withExternalRole(DataAssetTypeResourceTypes_Role_ResourceType)
          .list()
          .map((assoc) => assoc.role(DataAssetTypeResourceTypes_Role_ResourceType));
        if (value && !newResourceTypes.some((type) => type === mergedData.resourceType)) {
          defaultResourceType = store.getObject(value.id)[DataAssetType_DefaultResourceType] as string;
          setEditedData((prev) => (joinObjects(
            prev,
            { resourceType: defaultResourceType }
          )));
        }
      }}
      onResourceTypeUpdate={(value) => setEditedData((prev) => (joinObjects(
        prev,
        { resourceType: value?.id ?? null }
      )))}
      onImageLoad={({ data, type }) => setEditedData((prev) => (joinObjects(
        prev,
        { resourceImage: data && type ? { objectId: mergedData.resourceId, propertyId: Resource_Image, data, contentType: type } : null }
      )))}
      onUrlChange={(url) => {
        if (url) {
          const newResource = getOrCreateResource(store, url.trim());
          setEditedData((prev) => {
            const result = joinObjects(
              prev,
              {
                resourceId: newResource.id,
                resourceUrl: newResource[Resource_URL],
                resourceTypes: newResource[Resource_Type],
                resourceImage: newResource[Resource_Image] ? {
                  id: newResource?.[Resource_Image],
                  url: newResource?.[Resource_Image] ? store.getAttachmentUrl(newResource?.id, Resource_Image)?.(newResource[Resource_Image] as string, true) : undefined,
                } : undefined,
              }
            );
            if (newResource[newResource[KinshipRelation] as string]) {
              delete result.dataAssetId;
              delete result.dataAssetName;
              delete result.dataAssetType;
            }
            return result;
          });
        } else {
          setEditedData((prev) => (joinObjects(
            prev,
            { resourceId: null, resourceUrl: null }
          )));
        }
      }}
      onClearAll={() => setEditedData(() => ({
        resourceId: null,
        dataAssetId: null,
      }))}
      onOverlayClose={() => {
        if (!equals(editedData, {})) {
          const resourceInstance = {
            [Resource_Image]: mergedData.resourceImage === null ? null : mergedData.resourceImage?.id,
            [Resource_URL]: mergedData.resourceUrl,
            [Resource_Type]: mergedData.resourceType,
            id: mergedData.resourceId as string,
          };
          const dataAsset = { [DataAsset_Type]: mergedData.dataAssetType, [Concept_Name]: textToRichText(mergedData.dataAssetName), id: mergedData.dataAssetId as string };
          updateStoreForTransactionalResourceAndDataAsset(store, concept, resourceFieldId, { resourceInstance, dataAsset, image: mergedData.resourceImage });
        }
      }}
      onEscape={() => setEditedData({})}
    />
  );
};

export default ConceptResourceField;
