import type { Comparator } from './comparators';
import { newError } from './errorUtils';

export interface DecoratedListEntry<T> {
  i: number,
  item: T,
  itemRank: string,
  moveUpRank: () => string | undefined,
  moveDownRank: () => string | undefined,
  insertBeforeRank: () => string,
  insertAfterRank: () => string,
}

export interface DecoratedList<T> extends Array<DecoratedListEntry<T>> {
  insertBeforeFirstItemRank: () => string,
  insertAfterLastItemRank: () => string,
}

interface Ranker {
  computeRankBetween: (rank1: string, rank2: string) => string,
  decorateList: <T> (list: T[], itemToRank: (item: T) => string) => DecoratedList<T>,
  createNextRankGenerator: (previousRank?: string) => () => string,
}

export const compareRank: Comparator<string | undefined> = (rank1, rank2) => {
  if (rank1 === undefined && rank2 === undefined) {
    return 0;
  } else if (rank1 === undefined) {
    return 1;
  } else if (rank2 === undefined) {
    return -1;
  } else if (rank1 < rank2) {
    return -1;
  } else if (rank1 > rank2) {
    return 1;
  } else {
    return 0;
  }
};

/**
 * Create a ranker with a given first digit and a radix.
 * For sake of clarity a decimal base is considered for comments/variable names.
 *
 * @param firstDigit the ascii value of the first digit (eg 48 for decimal base)
 * @param radix the number of usable digits (eg 10 for decimal numbers)
 * @param minLength the minimal rank length to generate when injecting at end
 * @returns an object with functions to rank elements in the given base
 */
const createRanker = (firstDigit: number, radix: number, minLength: number): Ranker => {
  const lastDigit = firstDigit + radix; // last digit is excluded
  const lastDigitIncluded = lastDigit - 1;
  const firstChar = String.fromCharCode(firstDigit);

  /**
   * The algorithm generate a rank value than can be inserted between two other ranks.<p/>
   * It first try to minimize the length of the returned string, then try to be near the average value between the given ranks with both rank are provided).<p/>
   * If not rank2 are provided, it directly inject in the nearest next value.<p/>
   * Since it minimize the length of the returned string, it mean it cannot end with 0, and so it's expected than input ranks doesn't end with 0 digit too.
   *
   * @param {string} rank1 the lower rank value. If undefined or empty string it mean the minimal rank
   * @param {string} rank2 the higher rank value. If undefined or empty string it mean the minimal rank
   * @returns {string} a rank value which is guaranteed to be > rank1 and < rank2
   * @throws error when rank1>=rank2 or when one of the rank finish with a 0 digit
   */
  const computeRankBetween = (rank1 = '', rank2 = '') => {
    if (rank1.endsWith(firstChar) || rank2.endsWith(firstChar)) {
      throw newError('Invalid rank, invalid last char', { rank1, rank2, lastChar: firstChar });
    }

    if (rank2 === '') {
      const findLastIncrementableDigitIndex = () => {
        for (let i = Math.max(rank1.length, minLength) - 1; i >= 0; i -= 1) {
          const char = rank1.charCodeAt(i) || firstDigit;
          if (char !== lastDigitIncluded) {
            return i;
          }
        }
        return -1;
      };

      const lastIncrementableDigitIndex = findLastIncrementableDigitIndex();
      if (lastIncrementableDigitIndex === -1) {
        return rank1 + String.fromCharCode(firstDigit + 1);
      } else {
        const char = rank1.charCodeAt(lastIncrementableDigitIndex) || firstDigit;
        // Generate the next rank with the incrementable char incremented (string can be < minLength) (eg. with a range 0-9, 1099 -> 11)
        const nextRank = rank1.padEnd(minLength, firstChar).substring(0, lastIncrementableDigitIndex) + String.fromCharCode(char + 1);
        // Pad the rank if the dont have the minLength (eg. 11 -> 1101)
        return nextRank.padEnd(minLength - 1, firstChar).padEnd(minLength, String.fromCharCode(firstDigit + 1));
      }
    } else {
      let hasDiff = !(rank1 && rank2); // empty ranks are always different than other ranks
      for (let i = 0; ; i += 1) { // loop will always terminate at most when the two ranks have no more char by entering into the diff > 1 block
        const d1 = rank1.charCodeAt(i) || firstDigit; // charCodeAt return a NaN if index is out of range so use || instead of ??
        const d2 = rank2.charCodeAt(i) || lastDigit; // lastDigit occurs either with empty rank2 (high rank) or when previous diff was 1 and there was no extra chars for rank2
        const diff = d2 - d1;
        if (diff > 1) {
          if (!hasDiff && d2 === lastDigit) { // check than inputs where not equals or not badly ordered
            throw newError('Provided ranks are equals or not correctly ordered, rank1<rank2 condition is not fulfilled');
          }
          const prefix = rank1.substring(0, i).padEnd(i, firstChar);
          const digit = String.fromCharCode(Math.round((d1 + d2) / 2)); // since diff>1 it ensure than digit is in [1..9] range
          return prefix + digit;
        } else if ((diff === 1) && (rank2.length > i + 1)) {
          // digit are different but with no space between them. In case rank2 have extra digits they can be stripped to create a new rank between rank1 and rank2
          return rank2.substring(0, i + 1); // since d2-d1=1 then d2 (last digit of the returned substring) is ensured to be in [1..9] range
        }

        if (diff > 0) {
          hasDiff = true;
        } else if (diff < 0 && !hasDiff) {
          throw newError('Provided ranks are equals or not correctly ordered, rank1<rank2 condition is not fulfilled');
        }
      }
    }
  };

  const decorateList = <T>(list: T[], itemToRank: (item: T) => string): DecoratedList<T> => {
    const ranks = list.map(itemToRank);
    const computeInsertBefore = (i: number): string => computeRankBetween(ranks[i - 1], ranks[i]);

    const result = [] as unknown as DecoratedList<T>;
    for (let i = 0; i < list.length; i += 1) {
      if (i + 1 < ranks.length && compareRank(ranks[i + 1], ranks[i]) < 1) {
        throw newError('Error while decorating list with ranker, some items have invalid rank');
      }
      const item = list[i];

      result.push({
        i,
        item,
        itemRank: ranks[i],
        moveUpRank: () => (i > 0 ? computeInsertBefore(i - 1) : undefined),
        moveDownRank: () => (i + 1 < list.length ? computeInsertBefore(i + 2) : undefined),
        insertBeforeRank: () => computeInsertBefore(i),
        insertAfterRank: () => computeInsertBefore(i + 1),
      });
    }

    result.insertBeforeFirstItemRank = () => computeInsertBefore(0);
    result.insertAfterLastItemRank = () => computeInsertBefore(list.length);

    return result;
  };

  const createNextRankGenerator = (previousRank?: string) => {
    let lastRank = previousRank;
    return () => {
      lastRank = computeRankBetween(lastRank);
      return lastRank;
    };
  };

  return {
    computeRankBetween,
    decorateList,
    createNextRankGenerator,
  };
};

export const decimalRanker = createRanker(48, 10, 8);
export const printableAsciiRanker = createRanker(32, 95, 8);
export const ranker = printableAsciiRanker;
