import { Settings } from 'luxon';
import type { ReactElement } from 'react';
import { newError, updateDateFormatsLiterals } from 'yooi-utils';

let i18nLocale = 'en-US';
Settings.defaultLocale = 'en-US';
let i18nTranslations: Record<string, string> = {};
const generateKey = (parts: TemplateStringsArray) => parts
  .map((part, i) => {
    if (i < parts.length - 1) {
      return `${part}{{${i + 1}}}`;
    } else {
      return part;
    }
  }).join('');

const tokenSplitRegex = /({{[0-9]+}})/;
const tokenIndexRegex = /{{([0-9]+)}}/;
const computeTokens = <T extends unknown>(str: string, tokens: (string | number | T)[]): (string | T)[] => str
  .split(tokenSplitRegex)
  .map((part) => {
    const matcher = tokenIndexRegex.exec(part);
    if (matcher) {
      const index = Number(matcher[1]);
      const token = tokens[index - 1];
      if (index - 1 >= tokens.length) {
        throw newError('Unknown token');
      }
      switch (typeof token) {
        case 'number':
          return (token as number).toLocaleString(i18nLocale);
        default:
          return token;
      }
    } else {
      return part;
    }
  });

const processTag = <T extends unknown>(parts: TemplateStringsArray, tokens: (string | number | T)[]): (string | T)[] => {
  const enPhrase = generateKey(parts);
  const translatedPhrase = i18nTranslations[enPhrase] ?? enPhrase;
  return computeTokens(translatedPhrase, tokens);
};

const i18n = (parts: TemplateStringsArray, ...tokens: (string | number)[]): string => processTag(parts, tokens).join('');

i18n.jsx = (parts: TemplateStringsArray, ...tokens: (string | ReactElement)[]): ReactElement => (
  <>
    {processTag(parts, tokens)}
  </>
);

i18n.t = <T extends unknown>(key: string): (string | T) => i18nTranslations[key] ?? key;

i18n.locale = i18nLocale;

export const i18nConfigure = (locale: string, dateFormatLiterals: { w: string, q: string }, translations: Record<string, string>): void => {
  Settings.defaultLocale = locale;
  updateDateFormatsLiterals(dateFormatLiterals);
  i18n.locale = locale;
  i18nLocale = locale;
  i18nTranslations = translations;
};

export const i18nMap = <T extends string | undefined>(keyMap: { [key: string]: () => T }, defaultValue?: () => T) => (key: string): T => (keyMap[key] ?? defaultValue)();

export default i18n;
