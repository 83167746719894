import type { ObjectStoreWithTimeseries, StoreObject } from 'yooi-store';
import { isStoreObject } from 'yooi-store';
import { arrayOf, filterNullOrUndefined, isRichText, joinObjects, newError, richTextToText } from 'yooi-utils';
import type { GetDslFieldHandler } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import { PropertyMandatoryType } from '../../common/types/TypeModuleDslType';
import { Instance_Of } from '../../typeModule/ids';
import { formatOrUndef, isSaneAssociationValue } from '../common/commonFieldUtils';
import { Concept_Name, ReverseWorkflowField_ReverseField, WorkflowField } from '../ids';
import type { ConceptStoreObject, ReverseWorkflowFieldStoreObject } from '../model';
import { registerField } from '../module';
import type { Filters, PathStep } from '../moduleType';
import { PathStepType } from '../moduleType';
import type {
  ConceptReference,
  DimensionsMapping,
  MultipleParameterValue,
  MultipleRelationFieldExportConfiguration,
  ParametersMapping,
  ResolutionStack,
  SingleParameterValue,
} from '../utils';
import {
  conceptRefApiSchema,
  createValuePathResolver,
  FILTER_PARAMETER_CURRENT,
  getAllFieldDimensionsLinkedConceptDefinitionIds,
  getConceptInstanceProxy,
  getFieldDimensionOfModelType,
  getFilterFunction,
  getInstanceLabel,
  getMultipleRelationFieldExportColumnHeaders,
  handleProxyArrayProps,
  InstanceReferenceType,
  isSingleValueResolution,
  isValueResolutionOfType,
  ParsedDimensionType,
  parseDimensionMapping,
  resolveFieldValue,
  segregateParametersMapping,
  toConceptReference,
} from '../utils';
import { conceptType } from '../utils/formula/modelFunctions';
import type { ReverseWorkflowField } from './types';

const getValueResolution = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack) => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
  if (isValueResolutionOfType(valueResolution, (value): value is StoreObject[] => Array.isArray(value) && value.every(isStoreObject))) {
    return valueResolution;
  } else {
    return {
      value: [],
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['StoreObject[]'], typeof valueResolution.value),
      getDisplayValue: () => [],
      isTimeseries: valueResolution.isTimeseries,
    };
  }
};

const getTargetType = (store: ObjectStoreWithTimeseries, fieldId: string) => {
  const reverseWorkflowField = store.getObject<ReverseWorkflowFieldStoreObject>(fieldId);
  const conceptDefinitionIds = getAllFieldDimensionsLinkedConceptDefinitionIds(store, reverseWorkflowField[ReverseWorkflowField_ReverseField]);
  if (conceptDefinitionIds.length === 1) {
    return store.getObject(conceptDefinitionIds[0]);
  } else {
    throw newError('Unable to define target type of reverse workflow field', { fieldId, conceptDefinitionIds });
  }
};

type ReverseWorkflowFieldHandler = GetDslFieldHandler<
  ReverseWorkflowField,
  string[],
  undefined,
  StoreObject[],
  StoreObject[],
  ConceptReference[],
  undefined,
  undefined,
  { filters?: Filters },
  MultipleRelationFieldExportConfiguration
>;

export const reverseWorkflowFieldHandler: ReverseWorkflowFieldHandler = registerField({
  model: {
    label: 'ReverseWorkflowField',
    title: 'Workflow reverse',
    withApiAlias: true,
    relations: [
      { label: 'ReverseField', targetTypeId: WorkflowField, reverseLabel: 'WorkflowField_ReverseFields', mandatory: { type: PropertyMandatoryType.mandatory } },
    ],
  },
  handler: (objectStore, fieldId) => {
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => (
      (getValueResolution(objectStore, fieldId, dimensionsMapping).value ?? [])
        .map((instance) => formatOrUndef(getInstanceLabel(objectStore, instance)))
        .join(', ')
    );

    return {
      describe: () => ({ hasData: true, returnType: arrayOf(conceptType(getTargetType(objectStore, fieldId).id)), timeseriesMode: 'none' }),
      restApi: {
        returnTypeSchema: { type: 'array', items: conceptRefApiSchema },
        formatValue: (value, canRead) => (value ?? [])
          .filter((concept) => canRead([concept.id]))
          .map(toConceptReference)
          .filter(filterNullOrUndefined),
      },
      getStoreValue: (dimensionsMapping) => {
        const parsedDimensions = parseDimensionMapping(dimensionsMapping);
        if (parsedDimensions.type === ParsedDimensionType.MonoDimensional) {
          const field = objectStore.getObject<ReverseWorkflowFieldStoreObject>(fieldId);
          return objectStore.getObject(parsedDimensions.objectId)
            .navigateBack(field[ReverseWorkflowField_ReverseField])
            .map(({ id }) => id);
        } else {
          return [];
        }
      },
      getValueWithoutFormula: (dimensionsMapping) => {
        const parsedDimensions = parseDimensionMapping(dimensionsMapping);
        if (parsedDimensions.type === ParsedDimensionType.MonoDimensional) {
          const field = objectStore.getObject<ReverseWorkflowFieldStoreObject>(fieldId);
          return objectStore.getObject(parsedDimensions.objectId)
            .navigateBack(field[ReverseWorkflowField_ReverseField]);
        } else {
          return [];
        }
      },
      getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
      isEmpty: (dimensionsMapping) => getValueResolution(objectStore, fieldId, dimensionsMapping).value.length === 0,
      isSaneValue: (objectId) => {
        const instance = objectStore.getObjectOrNull(objectId);
        const dimensionId = instance ? getFieldDimensionOfModelType(objectStore, fieldId, instance[Instance_Of] as string) : undefined;

        if (dimensionId) {
          const isValid = isSaneAssociationValue(
            objectStore,
            objectId,
            getTargetType(objectStore, fieldId)?.id,
            getValueResolution(objectStore, fieldId, { [dimensionId]: objectId }).value
          );

          if (isValid) {
            return { isValid };
          } else {
            return { isValid, error: newError('Some selected elements are not allowed') };
          }
        } else {
          return { isValid: false, error: newError('Invalid field') };
        }
      },
      resolvePathStepConfiguration: (config) => {
        const resolveValue = (
          dimensionsMapping: DimensionsMapping,
          parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
          resolutionStack: ResolutionStack
        ): StoreObject[] => {
          const { value, error } = getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack);
          if (error) {
            throw error;
          } else {
            const { filters } = config;
            if (filters) {
              const { singleParametersMapping } = segregateParametersMapping(parametersMapping);
              const filterFunction = getFilterFunction(objectStore, filters);
              if (filterFunction) {
                return value.filter((v) => filterFunction(joinObjects(singleParametersMapping, { [FILTER_PARAMETER_CURRENT]: { type: 'single' as const, id: v.id } })));
              }
            }
            return value;
          }
        };
        return {
          hasData: true,
          timeseriesMode: 'none',
          getValueResolutionType: () => arrayOf(conceptType(getTargetType(objectStore, fieldId).id)),
          resolveValue,
          resolveDimension: (dimensionsMapping, parametersMapping, resolutionStack) => (
            { type: 'multiple' as const, instances: resolveValue(dimensionsMapping, parametersMapping, resolutionStack) as ConceptStoreObject[] }
          ),
        };
      },
      getValueAsText,
      getExportColumnHeaders: getMultipleRelationFieldExportColumnHeaders(objectStore),
      getExportValue: (dimensionsMapping, configurationProp) => {
        const configuration: MultipleRelationFieldExportConfiguration = configurationProp ?? { type: 'path', separator: ',' };
        const values = getValueResolution(objectStore, fieldId, dimensionsMapping);
        return {
          format: 'string',
          value: values.value.map((instance) => {
            if (configuration.type === 'path') {
              const defaultPath: PathStep[] = [
                { type: PathStepType.dimension, conceptDefinitionId: instance[Instance_Of] as string },
                { type: PathStepType.mapping, mapping: { id: FILTER_PARAMETER_CURRENT, type: InstanceReferenceType.parameter } },
                { type: PathStepType.field, fieldId: Concept_Name },
              ];
              const pathResolution = createValuePathResolver(objectStore, { [FILTER_PARAMETER_CURRENT]: { type: 'single', id: instance.id } })
                .resolvePathValue(configuration.path ?? defaultPath);
              if (isSingleValueResolution(pathResolution)) {
                return isRichText(pathResolution.value) ? richTextToText(pathResolution.value) : pathResolution.value;
              } else {
                return undefined;
              }
            } else {
              return instance.id;
            }
          }).filter(filterNullOrUndefined)
            .join(configuration.separator),
        };
      },
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else {
            const valueResolution = getValueResolution(objectStore, fieldId, dimensionsMapping);
            const value = valueResolution && !valueResolution.error ? valueResolution.value : [];
            return handleProxyArrayProps(prop, value, (v) => getConceptInstanceProxy(objectStore, v.id));
          }
        },
      }),
      getTargetType: () => getTargetType(objectStore, fieldId),
      filterConditions: undefined,
      updateValue: () => {
        throw newError('updateValue not supported');
      },
    };
  },
});
