import type { ChipViewStoredDefinition } from 'yooi-modules/modules/dashboardModule';
import { ViewType } from 'yooi-modules/modules/dashboardModule';
import { IconName } from '../../../../components/atoms/Icon';
import i18n from '../../../../utils/i18n';
import type { CommonViewResolvedDefinition } from '../common/commonViewResolvedDefinition';
import { registerView } from '../viewDsl';
import type { ViewDefinitionHandler } from '../viewDsl';
import ChipViewBlock from './ChipViewBlock';
import ChipViewDefinitionOptions from './ChipViewDefinitionOptions';
import type { ChipViewResolution } from './chipViewResolution';
import { resolveChipView } from './chipViewResolution';
import { getChipViewError } from './chipViewUtils';
import ChipViewWidget from './ChipViewWidget';

export interface ChipViewResolvedDefinition extends CommonViewResolvedDefinition {
  type: ViewType.Chip,
}

export type ChipViewDefinitionHandler = ViewDefinitionHandler<ChipViewStoredDefinition, ChipViewResolvedDefinition, ChipViewResolution>;

export const chipViewHandler = registerView<ChipViewStoredDefinition, ChipViewResolvedDefinition, ChipViewResolution>({
  type: ViewType.Chip,
  icon: IconName.label,
  getLabel: () => i18n`Chip`,
  optionType: undefined,
  withFilters: false,
  withExport: false,
  resolveView: (store, viewDefinition, { aclHandler, viewDimensions, parametersMapping, readOnly }) => (
    resolveChipView(store, aclHandler, viewDefinition, viewDimensions, parametersMapping, readOnly)
  ),
  renderBlock: (_, viewDefinition, { viewDimensions, viewFilters, layoutParametersMapping, readOnly }) => (
    <ChipViewBlock
      viewDimensions={viewDimensions}
      viewFilters={viewFilters}
      viewDefinition={viewDefinition}
      parametersMapping={layoutParametersMapping}
      readOnly={readOnly}
    />
  ),
  renderWidget: (_, viewDefinition, { viewDimensions, viewFilters, parametersMapping, width, height, readOnly }) => (
    <ChipViewWidget
      viewDimensions={viewDimensions}
      viewFilters={viewFilters}
      viewDefinition={viewDefinition}
      parametersMapping={parametersMapping}
      width={width}
      height={height}
      readOnly={readOnly}
    />
  ),
  renderDefinitionOptions: (_, viewDefinition, { viewDimensions, updateViewDefinition, readOnly, parameterDefinitions }) => (
    <ChipViewDefinitionOptions
      viewDimensions={viewDimensions}
      viewDefinition={viewDefinition}
      updateViewDefinition={updateViewDefinition}
      readOnly={readOnly}
      parameterDefinitions={parameterDefinitions}
    />
  ),
  getDefinitionErrors: (store, _, viewDimensions, parameters) => {
    const error = getChipViewError(store, viewDimensions, parameters);
    return error ? [error] : undefined;
  },
  resolveDefinition: (_, viewDefinition) => ({
    id: viewDefinition.id,
    type: viewDefinition.type,
    label: viewDefinition.label !== undefined && viewDefinition.label !== '' ? viewDefinition.label : i18n`Chip`,
    readOnly: viewDefinition.readOnly ?? false,
  }),
  getInitialStoredDefinition: (id) => ({ id, type: ViewType.Chip }),
});
