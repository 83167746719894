import type { FunctionComponent } from 'react';
import type { VictoryAxisProps } from 'victory';
import { VictoryAxis, VictoryLabel } from 'victory';
import { approximateNumber } from '../../../utils/numberUtils';
import useTheme from '../../../utils/useTheme';
import { chartAxisStyle } from './chartStylesUtils';

type DependentAxisProps = Omit<VictoryAxisProps, 'dependentAxis' | 'dx' | 'style' | 'tickLabelComponent' | 'tickFormat'>;
const DependentAxis: FunctionComponent<DependentAxisProps> = (props) => {
  const theme = useTheme();

  return (
    <VictoryAxis
      {...props}
      dependentAxis
      style={chartAxisStyle(theme, true, true)}
      tickLabelComponent={(<VictoryLabel dx={8} />)}
      tickFormat={(t: number) => approximateNumber(t)}
    />
  );
};

export default DependentAxis;
