import type { EventOrigin, ObjectStoreWithTimeseries } from 'yooi-store';
import { OriginSources } from 'yooi-store';
import type { RichText } from 'yooi-utils';
import { richTextToText } from 'yooi-utils';
import { formatOrUndef } from '../../conceptModule/common/commonFieldUtils';
import { Concept_Name, User_Email } from '../../conceptModule/ids';
import type { IntegrationStoreObject } from '../../integrationModule';
import { Integration_Name } from '../../integrationModule/ids';
import { AutomationRule_Name } from '../ids';
import type { AutomationRuleStoreObject } from '../modelTypes';
import type { MailActionQueryRecord } from './triggerHandler';

export const createActionQueryObject = <T extends MailActionQueryRecord>(get: (prop: 'toString' | symbol | keyof T) => unknown): { [K in keyof T]: unknown } => new Proxy({}, {
  get(_, prop) {
    return get(prop);
  },
}) as { [K in keyof T]: unknown };

export enum TriggerType {
  event = 'event',
  collaboration = 'collaboration',
  scheduled = 'scheduled',
}

export const formatEventOrigin = (store: ObjectStoreWithTimeseries, eventOrigin: EventOrigin): string => {
  const { source = OriginSources.SYSTEM } = eventOrigin;
  if (source === OriginSources.CLIENT && eventOrigin.userId) {
    const user = eventOrigin.userId ? store.getObjectOrNull(eventOrigin.userId) : undefined;
    if (user) {
      return formatOrUndef(richTextToText(user[Concept_Name] as RichText | undefined) ?? user[User_Email] as string | undefined);
    } else {
      return 'Unknown user';
    }
  } else if (source === OriginSources.API) {
    const integration = eventOrigin.userId ? store.getObjectOrNull<IntegrationStoreObject>(eventOrigin.userId) : undefined;
    if (integration?.[Integration_Name]) {
      return `Integration '${integration[Integration_Name]}'`;
    } else {
      return 'Integration';
    }
  } else if (source === OriginSources.AUTOMATION) {
    const automation = eventOrigin.automationRuleId === undefined ? undefined : store.getObjectOrNull<AutomationRuleStoreObject>(eventOrigin.automationRuleId);
    if (automation?.[AutomationRule_Name]) {
      return `Automation '${automation[AutomationRule_Name]}'`;
    } else {
      return 'Automation';
    }
  } else {
    return 'System';
  }
};
