import { equals } from 'ramda';
import type { ObjectStoreReadOnly, ObjectStoreWithTimeseries } from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import type { DateFieldStoreValue, DateRange, StoredDateObject } from 'yooi-utils';
import {
  compareRank,
  computeEffectiveRangeForPeriodAndDates,
  filterNullOrUndefined,
  getFormattedTextDateByPeriod,
  hasOwnProperty,
  isDateFieldStoreValue,
  isEffectiveDateIncluded,
  isValidDateFieldDateProperty,
  numberType,
  periodicities,
  PeriodicityType,
  ranker,
} from 'yooi-utils';
import { CommonAsType } from '../../common/fields/commonPropertyType';
import type { AsPropertyBusinessRuleRegistration, GetDslFieldHandler, UpdateOperationHandlers } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import type { BusinessRuleRegistration } from '../../common/types/TypeModuleDslType';
import { Class_Instances, Instance_Of } from '../../typeModule/ids';
import { validateFieldIdAsProperty, validateIntegrationOnlyPropertyUpdate } from '../common/commonFieldUtils';
import { DateField_Rank, Field_Formula } from '../ids';
import { registerField } from '../module';
import type { FilterValueRaw, PathStep } from '../moduleType';
import { FilterValueType } from '../moduleType';
import type {
  DimensionsMapping,
  FieldFilterCondition,
  FieldFilterConditions,
  MultipleParameterValue,
  ParametersMapping,
  ResolutionStack,
  SingleParameterValue,
  ValueResolution,
} from '../utils';
import {
  adminOnlyAcl,
  buildDimensionalId,
  createValuePathResolver,
  isFilterValueRaw,
  isSingleValueResolution,
  isValueResolutionOfType,
  ParsedDimensionType,
  parseDimensionMapping,
  resolveFieldValue,
} from '../utils';
import { dateType } from '../utils/formula/dateFunctions';
import type { DateField } from './types';

const checkDateFieldCreation: BusinessRuleRegistration = ({ getObjectOrNull }) => (_, { id, properties }) => {
  const object = getObjectOrNull(id);
  if (!object && properties) {
    if (!properties[DateField_Rank]) {
      return {
        rule: 'dateField.mandatoryField.missing.rank',
        status: ValidationStatus.REJECTED,
      };
    } else {
      return {
        rule: 'dateField.mandatoryField.present',
        status: ValidationStatus.ACCEPTED,
      };
    }
  }
  return undefined;
};

const assignDefaultRank: AsPropertyBusinessRuleRegistration = (objectStore, propertyId) => (_, { id, properties }) => {
  if (!properties || properties[propertyId] === null) {
    return undefined;
  } else {
    const dateField = objectStore.getObject(propertyId);
    const concept = objectStore.getObjectOrNull(id);

    if (concept?.[dateField[DateField_Rank] as string] !== undefined) {
      return undefined;
    }

    const conceptDefinitionId = concept?.[Instance_Of] as string | undefined ?? properties[Instance_Of] as string | undefined;
    if (!conceptDefinitionId) {
      return undefined;
    }

    return {
      rule: 'dateField.nextRank',
      status: ValidationStatus.ACCEPTED,
      generateSystemEvent: ({ updateObject }) => {
        const lastRank = objectStore.getObject(conceptDefinitionId)
          .navigateBack(Class_Instances)
          .map((c) => c[dateField[DateField_Rank] as string] as string | undefined)
          .filter(filterNullOrUndefined)
          .sort(compareRank)
          .at(-1);

        const nextRank = ranker.createNextRankGenerator(lastRank)();

        updateObject(id, { [dateField[DateField_Rank] as string]: nextRank });
      },
    };
  }
};

const preventDateFieldFieldReset = (fieldId: string, fieldName: string): BusinessRuleRegistration => () => (_, { properties }) => {
  if (properties && properties[fieldId] === null) {
    return {
      rule: `dateField.mandatoryField.reset.${fieldName}`,
      status: ValidationStatus.REJECTED,
    };
  }
  return undefined;
};

const validateDateField: AsPropertyBusinessRuleRegistration = (_, fieldId) => (__, { id, properties }) => {
  const newValue = properties?.[fieldId];
  if (newValue === undefined || newValue === null) {
    return undefined;
  }
  if (isValidDateFieldDateProperty(newValue) && (!hasOwnProperty(newValue, 'period') || newValue.period === undefined)) {
    const validDate = { date: newValue.date, period: PeriodicityType.day };
    return {
      rule: 'dateField.isValid.addPeriod',
      status: ValidationStatus.ACCEPTED,
      generateSystemEvent: ({ updateObject }) => {
        updateObject(id, { [fieldId]: validDate });
      },
    };
  } else if (isDateFieldStoreValue(newValue)) {
    return { rule: 'dateField.isValid', status: ValidationStatus.ACCEPTED };
  } else {
    return { rule: 'dateField.isNotValid', status: ValidationStatus.REJECTED };
  }
};

const getStoreValue = (objectStore: ObjectStoreReadOnly, fieldId: string, dimensionsMapping: DimensionsMapping): DateFieldStoreValue | undefined => {
  const parsedDimension = parseDimensionMapping(dimensionsMapping);
  if (parsedDimension.type === ParsedDimensionType.MonoDimensional) {
    return objectStore.getObject(parsedDimension.objectId)[fieldId] as DateFieldStoreValue | undefined;
  } else {
    return objectStore.getObject(buildDimensionalId(parsedDimension.dimensionsMapping), true)[fieldId] as DateFieldStoreValue | undefined;
  }
};

const getValueResolution = (
  objectStore: ObjectStoreWithTimeseries,
  fieldId: string,
  resolveConfiguration: () => DateField,
  dimensionsMapping: DimensionsMapping,
  resolutionStack?: ResolutionStack
): ValueResolution<DateFieldResolvedValue | undefined> => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
  if (isValueResolutionOfType(
    valueResolution,
    (value): value is DateFieldStoreValue | number | undefined => value === undefined || typeof value === 'number' || isDateFieldStoreValue(value)
  )) {
    let value: DateFieldStoreValue | undefined;
    if (typeof valueResolution.value === 'number') {
      const periodicityType = resolveConfiguration().defaultPeriod ?? PeriodicityType.day;
      value = {
        period: periodicityType,
        date: periodicities[periodicityType].getPreviousDateInAmountOfPeriod(new Date(valueResolution.value), 0).getTime(),
      };
    } else {
      value = valueResolution.value;
    }
    return {
      value,
      isComputed: valueResolution.isComputed,
      error: valueResolution.error,
      getDisplayValue: () => value,
      isTimeseries: valueResolution.isTimeseries,
    };
  } else {
    return {
      value: undefined,
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['DateFieldStoreValue', 'undefined'], typeof valueResolution.value),
      getDisplayValue: () => undefined,
      isTimeseries: false,
    };
  }
};

const isTimestampElementIncluded = (
  filterPeriodicity: string | undefined,
  filterStart: StoredDateObject | undefined,
  filterEnd: StoredDateObject | undefined
) => (element: DateFieldStoreValue | undefined) => {
  const {
    from: elementStart,
    to: elementEnd,
    error: elementError,
  } = computeEffectiveRangeForPeriodAndDates(element?.period, element?.date);
  return isEffectiveDateIncluded(filterPeriodicity, filterStart, filterEnd, elementStart, elementEnd, elementError);
};
const sanitizeDateRangeValue = (value: unknown) => (value ?? { type: FilterValueType.raw, raw: undefined }) as FilterValueRaw<DateRange | undefined>;
const isDateRangeFilterApplicable = (value: FilterValueRaw<DateRange | undefined>) => Boolean(value) && isFilterValueRaw<DateRange>(value);
const sanitizeDateValue = (value: unknown) => (value ?? { type: FilterValueType.raw, raw: undefined }) as FilterValueRaw<DateFieldStoreValue | undefined>;
const isDateFilterApplicable = (value: FilterValueRaw<DateFieldStoreValue | undefined>) => Boolean(value) && isFilterValueRaw<DateFieldStoreValue>(value);

type DateFieldResolvedValue = DateFieldStoreValue | undefined;
type DateFieldRestValue = DateFieldStoreValue | undefined;

interface DateFieldFilterConditions extends FieldFilterConditions<DateFieldResolvedValue> {
  EQUALS: FieldFilterCondition<DateFieldStoreValue | undefined, DateFieldResolvedValue>,
  NOT_EQUALS: FieldFilterCondition<DateFieldStoreValue | undefined, DateFieldResolvedValue>,
  INTERSECT: FieldFilterCondition<DateRange | undefined, DateFieldResolvedValue>,
  IS_EMPTY: FieldFilterCondition<undefined, DateFieldResolvedValue>,
  IS_NOT_EMPTY: FieldFilterCondition<undefined, DateFieldResolvedValue>,
}

interface DateUpdateOperationsTypes {
  INITIALIZE: { type: 'value', value: DateFieldStoreValue | undefined } | { type: 'path', path: PathStep[] },
  REPLACE: { type: 'value', value: DateFieldStoreValue | undefined } | { type: 'path', path: PathStep[] },
  CLEAR: undefined,
}

type DateFieldHandler = GetDslFieldHandler<
  DateField,
  DateFieldStoreValue | undefined,
  DateFieldStoreValue | null,
  DateFieldStoreValue | undefined,
  DateFieldResolvedValue,
  DateFieldRestValue,
  DateFieldFilterConditions,
  DateUpdateOperationsTypes,
  undefined,
  undefined
>;

export const dateFieldHandler: DateFieldHandler = registerField({
  model: {
    label: 'DateField',
    title: 'Date',
    withApiAlias: true,
    properties: [
      { label: 'DefaultPeriod', as: CommonAsType.PeriodicityType },
      { label: 'Rank', as: CommonAsType.string, businessRules: [preventDateFieldFieldReset(DateField_Rank, 'rank')] },
    ],
    businessRules: [checkDateFieldCreation],
    asPropertyBusinessRules: [
      validateIntegrationOnlyPropertyUpdate('dateField'),
      validateDateField,
      assignDefaultRank,
      validateFieldIdAsProperty('dateField'),
      ({ getObjectOrNull }, fieldId) => (_, { id, properties }) => {
        // ignore on instance deletion
        if (properties === null) {
          return undefined;
        }
        const object = getObjectOrNull(id);
        const field = getObjectOrNull(fieldId);
        if (!field) {
          return undefined;
        }
        const isComputed = field[Field_Formula] !== undefined;
        if (isComputed) {
          // On Date Field we can update Period
          const newDateValue = (properties?.[fieldId] as (DateFieldStoreValue | undefined))?.date;
          const previousDateValue = (object?.[fieldId] as (DateFieldStoreValue | undefined))?.date;
          if (previousDateValue !== newDateValue) {
            return { rule: 'field.DateField.rejectUpdateComputedField', status: ValidationStatus.REJECTED };
          }
        }
        return undefined;
      },
    ],
    extraModel: ({ registerCustomBusinessRules, registerCustomAccessControlList }) => {
      registerCustomBusinessRules((_, { onObject, onProperty }) => {
        onObject(DateField_Rank).register(([propertyId]) => onProperty(propertyId).validate(() => ({ rule: 'field.date.rank.accept', status: ValidationStatus.ACCEPTED })));
      });

      registerCustomAccessControlList((objectStore, { onObject }) => {
        onObject(DateField_Rank).allow('READ', (_, objectId) => {
          const dateFieldRank = objectStore.getObject(objectId);
          const dateField = dateFieldRank.navigateBack(DateField_Rank)[0];
          if (dateField) {
            return { rule: 'dateField.rank.instance.read', status: ValidationStatus.DELEGATED, targetId: [dateField.id], targetAction: 'READ' };
          } else {
            return { rule: 'dateField.rank.instance.missing', status: ValidationStatus.REJECTED };
          }
        });
        onObject(DateField_Rank).allow('WRITE', ({ userId }) => adminOnlyAcl(objectStore, userId, 'dateField.rank', 'WRITE'));
      });
    },
  },
  handler: (objectStore, fieldId, { resolveConfiguration }) => {
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => {
      const instanceId = Object.values(dimensionsMapping)[0] as string;
      if (instanceId) {
        const { value } = getValueResolution(objectStore, fieldId, resolveConfiguration, dimensionsMapping);
        const timestamp = value?.date;
        const periodicity = ((objectStore.getObject(instanceId)[fieldId] as DateRange)?.period ?? PeriodicityType.day);
        return (timestamp ? getFormattedTextDateByPeriod(new Date(timestamp), periodicity) : undefined);
      } else {
        return undefined;
      }
    };

    const extractOperationValueDate = (
      parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
      operationValue: { type: 'value', value: DateFieldStoreValue | undefined } | { type: 'path', path: PathStep[] }
    ): DateFieldStoreValue | undefined => {
      if (operationValue.type === 'path') {
        const { path } = operationValue;
        const resolution = createValuePathResolver(objectStore, parametersMapping).resolvePathValue(path);
        if (isSingleValueResolution(resolution)) {
          const resolvedValue = resolution.value;
          if (isDateFieldStoreValue(resolvedValue)) {
            return resolvedValue;
          }
        }
        return undefined;
      } else {
        return operationValue.value;
      }
    };

    return {
      describe: () => ({ hasData: true, returnType: dateType, extraAcceptedTypes: [numberType], timeseriesMode: 'none' }),
      restApi: {
        returnTypeSchema: {
          type: 'object',
          properties: {
            period: { type: 'string', enum: Object.values(PeriodicityType), nullable: true },
            date: { type: 'number' },
          },
          nullable: true,
        },
        formatValue: (value) => value ?? undefined,
      },
      getStoreValue: (dimensionsMapping) => getStoreValue(objectStore, fieldId, dimensionsMapping),
      getValueWithoutFormula: (dimensionsMapping) => getStoreValue(objectStore, fieldId, dimensionsMapping),
      getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, resolveConfiguration, dimensionsMapping, resolutionStack),
      resolvePathStepConfiguration: () => ({
        hasData: true,
        timeseriesMode: 'none',
        getValueResolutionType: () => dateType,
        resolveValue: (dimensionsMappings, _, resolutionStack) => {
          const { error, value } = getValueResolution(objectStore, fieldId, resolveConfiguration, dimensionsMappings, resolutionStack);
          if (error) {
            throw error;
          } else {
            return value;
          }
        },
      }),
      updateValue: (dimensionsMapping, value) => {
        const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
        if (parsedDimensionMapping.type === ParsedDimensionType.MonoDimensional) {
          objectStore.updateObject(parsedDimensionMapping.objectId, { [fieldId]: value });
        } else {
          objectStore.updateObject(buildDimensionalId(dimensionsMapping), { [fieldId]: value });
        }
      },
      isEmpty: (dimensionsMapping) => {
        const { value } = getValueResolution(objectStore, fieldId, resolveConfiguration, dimensionsMapping);
        return value?.date === undefined;
      },
      isSaneValue: () => ({ isValid: true }),
      getValueAsText,
      getExportColumnHeaders: (configuration, fieldLabel) => ({
        columnsNumber: 1,
        getHeaders: () => [{ format: 'string', value: fieldLabel }],
        getColumnConfiguration: () => configuration,
      }),
      getExportValue: (dimensionsMapping) => {
        const valueResolution = getValueResolution(objectStore, fieldId, resolveConfiguration, dimensionsMapping).value;
        return {
          format: 'date',
          value: valueResolution?.date,
          period: valueResolution?.period,
        };
      },
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else {
            return undefined;
          }
        },
      }),
      filterConditions: {
        EQUALS: {
          sanitizeValue: sanitizeDateValue,
          isFilterApplicable: isDateFilterApplicable,
          filterFunction: (leftValue, rightValue) => {
            if (rightValue === undefined) {
              return false;
            } else {
              return rightValue.period === leftValue?.period && rightValue.date === leftValue?.date;
            }
          },
        },
        NOT_EQUALS: {
          sanitizeValue: sanitizeDateValue,
          isFilterApplicable: isDateFilterApplicable,
          filterFunction: (leftValue, rightValue) => {
            if (rightValue === undefined) {
              return false;
            } else {
              return rightValue.period !== leftValue?.period || rightValue.date !== leftValue?.date;
            }
          },
        },
        INTERSECT: {
          sanitizeValue: sanitizeDateRangeValue,
          isFilterApplicable: isDateRangeFilterApplicable,
          filterFunction: (leftValue, rightValue) => {
            if (rightValue === undefined) {
              return false;
            } else {
              return Boolean(isTimestampElementIncluded(rightValue.period, rightValue.from, rightValue.to)(leftValue));
            }
          },
        },
        IS_EMPTY: {
          sanitizeValue: () => ({ type: FilterValueType.raw, raw: undefined }),
          isFilterApplicable: () => true,
          filterFunction: (leftValue) => leftValue === undefined,
        },
        IS_NOT_EMPTY: {
          sanitizeValue: () => ({ type: FilterValueType.raw, raw: undefined }),
          isFilterApplicable: () => true,
          filterFunction: (leftValue) => leftValue !== undefined,
        },
      },
      updateOperationHandlers: {
        INITIALIZE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            const fieldHandler = dateFieldHandler(objectStore, fieldId);
            const currentValue = fieldHandler.getStoreValue(dimensionsMapping);
            if (currentValue !== undefined && currentValue.date !== undefined) {
              return;
            }
            fieldHandler.updateValue(dimensionsMapping, extractOperationValueDate(parametersMapping, value) ?? null);
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: undefined },
        },
        REPLACE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            dateFieldHandler(objectStore, fieldId).updateValue(dimensionsMapping, extractOperationValueDate(parametersMapping, value) ?? null);
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: undefined },
        },
        CLEAR: {
          applyOperation: (dimensionsMapping) => {
            dateFieldHandler(objectStore, fieldId).updateValue(dimensionsMapping, null);
          },
          sanitizeOperation: () => undefined,
        },
      } satisfies UpdateOperationHandlers<DateUpdateOperationsTypes>,
    };
  },
  historyEventProducer: ({ getObjectOrNull }, fieldId) => ({
    value: {
      collectImpactedInstances: ({ id, properties }) => {
        if (
          properties?.[fieldId] !== undefined // A value is in the update for the current field
          || (properties === null && getObjectOrNull(id)?.[fieldId] !== undefined) // Object is deleted and store had a value for the field
        ) {
          return [id];
        } else {
          return [];
        }
      },
      getValue: (id) => ({ value: (getObjectOrNull(id)?.[fieldId] as number | undefined) ?? null, version: 1 }),
      areValuesEquals: (value1, value2) => equals(value1?.value, value2?.value),
    },
  }),
});
