import {
  ConceptCollaborationFieldDimension,
  ConceptCreatedAtDimension,
  ConceptFunctionalIdDimension,
  ConceptNameDimension,
  ConceptStakeholdersGroupsDimension,
  ConceptStakeholdersRolesDimension,
  ConceptStakeholdersUsersDimension,
  ConceptUpdatedAtDimension,
  Field_ApiAlias,
  Field_IsCore,
  Field_Title,
  FieldDimension,
  FieldDimension_Field,
  FieldDimensionTypes,
  FieldDimensionTypes_Role_ConceptDefinition,
  FieldDimensionTypes_Role_FieldDimension,
  KinshipRelationDimension,
  RelationMultipleField,
  RelationMultipleField_ReverseField,
  RelationMultipleField_TargetType,
  RelationSingleField,
  RelationSingleField_TargetType,
} from '../../conceptModule/ids';
import {
  DataAsset,
  DataAsset_Type,
  DataAssetHierarchicalUsageField,
  DataAssetHierarchicalUsageFieldInstance,
  DataAssetHierarchicalUsageFieldInstanceDimension,
  DataAssetType,
  DataAssetTypeDimension,
} from '../ids';
import { registerModel } from '../module';

const { associate, instance } = registerModel;

instance({
  typeId: DataAssetHierarchicalUsageField,
  label: 'DataAssetHierarchicalUsageFieldInstance',
  extraProperties: { [Field_Title]: 'Used in', [Field_ApiAlias]: 'used-in', [Field_IsCore]: true },
});

instance({
  typeId: FieldDimension,
  label: 'DataAssetHierarchicalUsageFieldInstanceDimension',
  extraProperties: {
    [FieldDimension_Field]: DataAssetHierarchicalUsageFieldInstance,
  },
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, DataAsset)
    .withRole(FieldDimensionTypes_Role_FieldDimension, DataAssetHierarchicalUsageFieldInstanceDimension),
});

instance({
  typeId: RelationSingleField,
  label: 'DataAsset_Type',
  extraProperties: {
    [Field_Title]: 'Type',
    [RelationSingleField_TargetType]: DataAssetType,
    [Field_IsCore]: true,
  },
});

instance({
  typeId: FieldDimension,
  label: 'DataAssetTypeDimension',
  extraProperties: {
    [FieldDimension_Field]: DataAsset_Type,
  },
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, DataAsset).withRole(FieldDimensionTypes_Role_FieldDimension, DataAssetTypeDimension),
});

instance({
  typeId: RelationMultipleField,
  label: 'DataAssetType_DataAssets',
  extraProperties: {
    [Field_Title]: 'Data assets',
    [RelationMultipleField_ReverseField]: DataAsset_Type,
    [RelationMultipleField_TargetType]: DataAsset,
    [Field_IsCore]: true,
  },
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, DataAsset).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptNameDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, DataAsset).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCreatedAtDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, DataAsset).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptUpdatedAtDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, DataAsset).withRole(FieldDimensionTypes_Role_FieldDimension, KinshipRelationDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, DataAsset).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptFunctionalIdDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, DataAsset).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptStakeholdersRolesDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, DataAsset).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptStakeholdersUsersDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, DataAsset).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptStakeholdersGroupsDimension),
});

associate({
  typeId: FieldDimensionTypes,
  roleBuilder: (withRole) => withRole(FieldDimensionTypes_Role_ConceptDefinition, DataAsset).withRole(FieldDimensionTypes_Role_FieldDimension, ConceptCollaborationFieldDimension),
});
