declare global {
  interface Window {
    enableReactProfiler?: () => void,
    disableReactProfiler?: () => void,
  }
}

window.enableReactProfiler = () => {
  document.cookie = 'yooiProfiler=true; path=/';
  window.location.reload();
};

window.disableReactProfiler = () => {
  document.cookie = 'yooiProfiler=false; path=/';
  window.location.reload();
};

export {};
