import type { FunctionComponent } from 'react';
import { v4 as uuid } from 'uuid';
import type { NumberColorStepsValue, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { NumberColorStepValueType } from 'yooi-modules/modules/conceptModule';
import type { DimensionDisplayOption, GaugeViewStoredDefinition, ViewDimension, ViewSeries } from 'yooi-modules/modules/dashboardModule';
import { ViewType } from 'yooi-modules/modules/dashboardModule';
import { Direction, joinObjects, moveElementInArray } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../../components/atoms/Button';
import { IconName } from '../../../../components/atoms/Icon';
import SpacingLine from '../../../../components/molecules/SpacingLine';
import BlockContent from '../../../../components/templates/BlockContent';
import BlockTitle, { BlockTitleVariant } from '../../../../components/templates/BlockTitle';
import DataTable from '../../../../components/templates/DataTable';
import VerticalBlock from '../../../../components/templates/VerticalBlock';
import useStore from '../../../../store/useStore';
import { spacingRem } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import makeStyles from '../../../../utils/makeStyles';
import NumberColorStepInput from '../../fields/_global/NumberColorStepInput';
import type { LineEditionOption } from '../../fields/FieldEditionOptionType';
import { EditionOptionTypes } from '../../fields/FieldEditionOptionType';
import type { UpdateViewDefinition } from '../../fields/viewsField/ViewsFieldDefinitionOptions';
import { getDimensionDisplayOptionsColumns } from '../common/dimensions/viewWithDimensionDisplayOptionsFeatureUtils';
import ViewDefinitionSeriesOptions from '../common/series/ViewDefinitionSeriesOptions';
import { addSeries, deleteSeries, getSeriesError, getSeriesMinMax, getViewDimensionsAsParameterDefinitions, updateSeries } from '../common/series/viewWithSeriesFeatureUtils';
import ViewOptionBlock from '../common/ViewOptionBlock';
import type { GaugeViewResolvedDefinition } from './gaugeViewDefinitionHandler';

const useStyles = makeStyles({
  rangeContainer: {
    display: 'grid',
    rowGap: spacingRem.s,
  },
}, 'gaugeViewDefinitionOptions');

interface GaugeViewDefinitionOptionsProps {
  viewDimensions: ViewDimension[],
  viewDefinition: GaugeViewResolvedDefinition,
  updateViewDefinition: UpdateViewDefinition<GaugeViewStoredDefinition>,
  readOnly: boolean,
  parameterDefinitions: SingleParameterDefinition[],
}

const GaugeViewDefinitionOptions: FunctionComponent<GaugeViewDefinitionOptionsProps> = ({
  viewDimensions, viewDefinition, updateViewDefinition, readOnly, parameterDefinitions,
}) => {
  const classes = useStyles();

  const store = useStore();

  const editionOptions: LineEditionOption[] = [];
  const viewParameterDefinitions: SingleParameterDefinition[] = [...parameterDefinitions, ...getViewDimensionsAsParameterDefinitions(store, viewDimensions)];

  editionOptions.push({
    key: 'minValue',
    title: i18n`Min value`,
    type: EditionOptionTypes.custom,
    props: {
      render: () => {
        const { series } = viewDefinition;
        const seriesMinMax = getSeriesMinMax(store, series);
        const generateTooltipLine = (seriesMinMaxValues: { min?: number, max?: number, label?: string }[], isMin: boolean | undefined) => seriesMinMaxValues.map(({
          label,
          min,
          max,
        }) => `- ${label} : ${isMin ? min : max}`).join('\n');
        const isMin = true;
        const infoTooltip = seriesMinMax.length > 0 ? i18n`To help you set a ${isMin ? 'minimum' : 'maximum'} value consistent with all the different series, here are their respective ${isMin ? 'minimum' : 'maximum'} :\n${generateTooltipLine(seriesMinMax, isMin)}` : undefined;

        return (
          <NumberColorStepInput
            value={
              viewDefinition.minValue
              ?? { type: NumberColorStepValueType.value, value: undefined, color: undefined }
            }
            parameterDefinitions={viewParameterDefinitions}
            onChange={(newValue) => updateViewDefinition((oldValue) => (
              joinObjects(oldValue, { minValue: newValue })
            ))}
            readOnly={readOnly}
            info={infoTooltip}
          />
        );
      },
    },
  });
  editionOptions.push({
    key: 'rangeValues',
    title: i18n`Ranges`,
    type: EditionOptionTypes.custom,
    props: {
      render: () => {
        const steps = viewDefinition.rangeValues
          ?? [{ id: uuid(), type: NumberColorStepValueType.value, value: undefined, color: undefined }];
        return (
          <div className={classes.rangeContainer}>
            {steps.map((step) => (
              <NumberColorStepInput<NumberColorStepsValue>
                key={step.id}
                value={step}
                parameterDefinitions={viewParameterDefinitions}
                onChange={(newValue) => updateViewDefinition((oldValue) => (
                  joinObjects(oldValue, { rangeValues: steps.map((s) => (s.id === step.id ? newValue : s)) })
                ))}
                onDelete={() => updateViewDefinition((oldValue) => (
                  joinObjects(oldValue, { rangeValues: steps.filter((s) => s.id !== step.id) })
                ))}
                readOnly={readOnly}
              />
            ))}
            {!readOnly && (
              <SpacingLine>
                <Button
                  title={i18n`Add range`}
                  iconName={IconName.add}
                  onClick={() => updateViewDefinition((oldValue) => (
                    joinObjects(oldValue, { rangeValues: [...steps, { id: uuid(), type: NumberColorStepValueType.value, color: undefined, value: undefined }] })
                  ))}
                  variant={ButtonVariant.secondary}
                />
              </SpacingLine>
            )}
          </div>
        );
      },
    },
  });
  editionOptions.push({
    key: 'maxValue',
    title: i18n`Max value`,
    type: EditionOptionTypes.custom,
    props: {
      render: () => {
        const { series } = viewDefinition;
        const seriesMinMax = getSeriesMinMax(store, series);
        const generateTooltipLine = (seriesMinMaxValues: { min?: number, max?: number, label?: string }[], isMin: boolean | undefined) => seriesMinMaxValues.map(({
          label,
          min,
          max,
        }) => `- ${label} : ${isMin ? min : max}`).join('\n');
        const isMin = false;
        const infoTooltip = seriesMinMax.length > 0 ? i18n`To help you set a ${isMin ? 'minimum' : 'maximum'} value consistent with all the different series, here are their respective ${isMin ? 'minimum' : 'maximum'} :\n${generateTooltipLine(seriesMinMax, isMin)}` : undefined;
        return (
          <NumberColorStepInput
            value={
              viewDefinition.maxValue
              ?? { type: NumberColorStepValueType.value, value: undefined, color: undefined }
            }
            parameterDefinitions={viewParameterDefinitions}
            onChange={(newValue) => updateViewDefinition((oldValue) => (
              joinObjects(oldValue, { maxValue: newValue })
            ))}
            readOnly={readOnly}
            info={infoTooltip}
          />
        );
      },
    },
  });

  editionOptions.push({
    key: 'Series',
    title: i18n`Series`,
    type: EditionOptionTypes.custom,
    isVertical: true,
    padded: true,
    error: getSeriesError(store, viewDefinition, viewParameterDefinitions, viewDefinition.type),
    props: {
      render: () => (
        <ViewDefinitionSeriesOptions
          viewType={ViewType.Gauge}
          series={viewDefinition.series ?? []}
          dimensions={viewDimensions}
          onCreateSeries={() => updateViewDefinition((oldValue) => addSeries(oldValue))}
          onDeleteSeries={(seriesId) => updateViewDefinition((oldValue) => deleteSeries(oldValue, seriesId))}
          onUpdateSeries={(seriesId, properties) => updateViewDefinition((oldValue) => updateSeries(oldValue, seriesId, properties))}
          onMoveUpSeries={(index) => updateViewDefinition((oldValue) => joinObjects(
            oldValue,
            { series: moveElementInArray<ViewSeries>(Direction.up, index, viewDefinition.series) }
          ))}
          onMoveDownSeries={(index) => updateViewDefinition((oldValue) => joinObjects(
            oldValue,
            { series: moveElementInArray<ViewSeries>(Direction.down, index, viewDefinition.series) }
          ))}
          readOnly={readOnly}
          parameterDefinitions={viewParameterDefinitions}
        />
      ),
    },
  });

  const dimensionDisplay = (
    <VerticalBlock asBlockContent>
      <BlockTitle
        title={i18n`Display options`}
        variant={BlockTitleVariant.inline}
      />
      <BlockContent padded>
        <DataTable<DimensionDisplayOption>
          columnsDefinition={getDimensionDisplayOptionsColumns(store, viewDimensions, updateViewDefinition)}
          list={viewDefinition.getDimensionsDisplay(viewDimensions).map((item) => ({ key: item.id, type: 'item', item, color: undefined }))}
          fullWidth
        />
      </BlockContent>
    </VerticalBlock>
  );

  return (
    <>
      {dimensionDisplay}
      {editionOptions.map((option) => (<ViewOptionBlock key={option.key} option={option} />))}
    </>
  );
};

export default GaugeViewDefinitionOptions;
