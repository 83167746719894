import type { FormulaType } from '../engine/formula';
import { isArrayFormulaType } from '../typeSystem';
import { FunctionInvalidArgumentTypeError, FunctionMissingArgumentError, FunctionUnexpectedArgumentError } from './errors';

export const flattenForEach = <T>(val: unknown[] | T, visit: (element: T) => void): void => {
  if (Array.isArray(val)) {
    val.forEach((e) => flattenForEach(e as unknown[] | T, visit));
  } else {
    visit(val);
  }
};

const testAssignableFrom = (expectedType: FormulaType | FormulaType[], argType: FormulaType) => {
  if (Array.isArray(expectedType)) {
    return expectedType.some((type) => type.isAssignableFrom(argType));
  } else {
    return expectedType.isAssignableFrom(argType);
  }
};

export const checkFlattenType = (functionName: string, elementType: FormulaType | FormulaType[], argTypes: FormulaType[]): Error | undefined => {
  const invalidIndex = argTypes.findIndex((argType) => !testAssignableFrom(elementType, isArrayFormulaType(argType) ? argType.array.elementType : argType));
  return invalidIndex !== -1 ? new FunctionInvalidArgumentTypeError(functionName, elementType, argTypes[invalidIndex], invalidIndex) : undefined;
};

export const checkArgumentType = (functionName: string, expectedType: FormulaType | FormulaType[], argTypes: FormulaType[], argIndex?: number): Error | undefined => {
  if (argIndex !== undefined) {
    if (testAssignableFrom(expectedType, argTypes[argIndex])) {
      return undefined;
    } else {
      return new FunctionInvalidArgumentTypeError(functionName, expectedType, argTypes[argIndex], argIndex);
    }
  } else {
    const i = argTypes.findIndex((argType) => !testAssignableFrom(expectedType, argType));
    if (i !== -1) {
      return new FunctionInvalidArgumentTypeError(functionName, expectedType, argTypes[i], i);
    } else {
      return undefined;
    }
  }
};

export const checkArgumentCount = (functionName: string, argTypes: FormulaType[], min: number, max?: number): Error | undefined => {
  const count = argTypes.length;
  if (count < min) {
    return new FunctionMissingArgumentError(functionName, count);
  } else if (max !== undefined && count > max) {
    return new FunctionUnexpectedArgumentError(functionName, max);
  } else {
    return undefined;
  }
};

export const checks = (...checksFn: (() => Error | undefined)[]): Error | undefined => {
  for (let i = 0; i < checksFn.length; i += 1) {
    const error = checksFn[i]();
    if (error) {
      return error;
    }
  }
  return undefined;
};
