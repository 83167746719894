import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import type { ValueResolution } from 'yooi-modules/modules/conceptModule';
import { getFieldDimensionOfModelType, numberFieldHandler } from 'yooi-modules/modules/conceptModule';
import { Field_IntegrationOnly } from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { joinObjects } from 'yooi-utils';
import Tooltip from '../../../../../components/atoms/Tooltip';
import Typo from '../../../../../components/atoms/Typo';
import SpacedContainer from '../../../../../components/molecules/SpacedContainer';
import SpacingLine from '../../../../../components/molecules/SpacingLine';
import TooltipPopover from '../../../../../components/molecules/TooltipPopover';
import useStore from '../../../../../store/useStore';
import { Spacing } from '../../../../../theme/spacingDefinition';
import makeStyles from '../../../../../utils/makeStyles';
import useDerivedState from '../../../../../utils/useDerivedState';
import { SessionStorageKeys, useSessionStorageState } from '../../../../../utils/useSessionStorage';
import useSizeContext, { SizeVariant } from '../../../../../utils/useSizeContext';
import useTheme from '../../../../../utils/useTheme';
import { getFieldLabel } from '../../../fieldUtils';
import { getColorField, getSwimlaneColumnByField, getSwimlaneGroupByField, getSwimlaneProgressField } from '../../../modelTypeUtils';
import type { NavigationFilter } from '../../../navigationUtils';
import type { SwimlaneConfiguration } from '../../../sessionStorageTypes';
import { getFieldHandler } from '../../FieldLibrary';
import NumberFieldRenderer from '../../numberField/NumberFieldRenderer';
import ConceptTooltip from '../ConceptTooltip';

const useStyles = makeStyles({
  firstColumnContainer: {
    width: '10rem',
  },
  container: {
    width: '41.2rem',
  },
  containerSmall: {
    width: '35.2rem',
  },
  inlineContainer: {
    minWidth: '15rem',
  },
}, 'conceptSwimlaneTooltip');

interface ConceptSwimlaneTooltipProps {
  conceptId: string,
  buttonView: boolean,
  editMode: boolean,
  currentAnchor: HTMLElement,
  handleClose: () => void,
  handleSubmit: (paramId: string, values: { coloration?: string | null, progress?: number | null, columnBy?: string | null, groupBy?: string | null }) => void,
  filterId: string,
  readOnly?: boolean,
  navigationFilters?: NavigationFilter,
}

const ConceptSwimlaneTooltip: FunctionComponent<ConceptSwimlaneTooltipProps> = ({
  conceptId,
  buttonView,
  editMode,
  currentAnchor,
  handleClose,
  handleSubmit,
  filterId,
  readOnly = false,
  navigationFilters,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const store = useStore();

  const concept = store.getObject(conceptId);
  const conceptDefinitionId = concept[Instance_Of] as string;
  const [swimlaneConfig] = useSessionStorageState<SwimlaneConfiguration | undefined>(`${SessionStorageKeys.swimlaneConfig}_${filterId}`, undefined);

  const colorField = getColorField(store, conceptDefinitionId, swimlaneConfig, undefined, false);
  const colorFieldHandler = colorField ? getFieldHandler(store, colorField.id) : undefined;
  const colorFieldDimensionId = colorField && colorFieldHandler ? getFieldDimensionOfModelType(store, colorField.id, conceptDefinitionId) : undefined;
  const initialColor = colorField ? concept[colorField.id] as string | undefined : undefined;
  const [color, setColor, resetColor] = useDerivedState<string | null | undefined>(() => initialColor, [initialColor]);

  const swimlaneColumnByField = getSwimlaneColumnByField(store, conceptDefinitionId, swimlaneConfig, undefined, false);
  const swimlaneColumnByFieldHandler = swimlaneColumnByField ? getFieldHandler(store, swimlaneColumnByField.id) : undefined;
  const swimlaneColumnByFieldDimensionId = swimlaneColumnByField && swimlaneColumnByFieldHandler
    ? getFieldDimensionOfModelType(store, swimlaneColumnByField.id, conceptDefinitionId)
    : undefined;
  const initialColumnBy = swimlaneColumnByField ? concept[swimlaneColumnByField.id] as string | undefined : undefined;
  const [columnBy, setColumnBy, resetColumnBy] = useDerivedState<string | null | undefined>(() => initialColumnBy, [initialColumnBy]);

  const swimlaneGroupByField = getSwimlaneGroupByField(store, conceptDefinitionId, swimlaneConfig, undefined, false);
  const swimlaneGroupByFieldHandler = swimlaneGroupByField ? getFieldHandler(store, swimlaneGroupByField.id) : undefined;
  const swimlaneGroupByFieldDimensionId = swimlaneGroupByField && swimlaneGroupByFieldHandler
    ? getFieldDimensionOfModelType(store, swimlaneGroupByField.id, conceptDefinitionId)
    : undefined;
  const initialGroupBy = swimlaneGroupByField ? concept[swimlaneGroupByField.id] as string | undefined : undefined;
  const [groupBy, setGroupBy, resetGroupBy] = useDerivedState<string | null | undefined>(() => initialGroupBy, [initialGroupBy]);

  const swimlaneProgressField = getSwimlaneProgressField(store, conceptDefinitionId, swimlaneConfig, undefined, false);
  const swimlaneProgressFieldHandler = swimlaneProgressField ? getFieldHandler(store, swimlaneProgressField.id) : undefined;
  const swimlaneProgressFieldDimensionId = swimlaneProgressField && swimlaneProgressFieldHandler
    ? getFieldDimensionOfModelType(store, swimlaneProgressField.id, conceptDefinitionId)
    : undefined;
  const [progress, setProgress, resetProgress] = useDerivedState<{ value: number | null | undefined, isComputed?: boolean }>(() => {
    if (swimlaneProgressField && swimlaneProgressFieldDimensionId) {
      return numberFieldHandler(
        store,
        swimlaneProgressField.id
      ).getValueResolution({ [swimlaneProgressFieldDimensionId]: concept.id }) as ValueResolution<number | null | undefined>;
    }
    return { value: undefined };
  }, [swimlaneProgressField ? concept[swimlaneProgressField.id] : undefined]);

  const { sizeVariant } = useSizeContext();

  const handleCloseAndSubmit = (conceptIdParam: string) => {
    handleClose();
    const columnByToSend = columnBy;
    const groupByToSend = groupBy;
    const progressToSend = progress;
    const colorationToSend = color;
    const progressSwimlaneFieldDimension = swimlaneProgressField
      ? getFieldDimensionOfModelType(store, swimlaneProgressField.id, conceptDefinitionId)
      : undefined;

    resetColumnBy();
    resetGroupBy();
    resetProgress();
    resetColor();
    const progressCurrentValue = swimlaneProgressField ? numberFieldHandler(store, swimlaneProgressField.id)
      .getValueResolution(progressSwimlaneFieldDimension ? { [progressSwimlaneFieldDimension]: concept.id } : {}).value as number | null | undefined : undefined;
    handleSubmit(conceptIdParam, joinObjects(
      colorationToSend !== initialColor ? { coloration: colorationToSend } : {},
      progressToSend && progressToSend.value !== progressCurrentValue && !progressToSend.isComputed ? { progress: progressToSend.value } : {},
      columnByToSend !== initialColumnBy ? { columnBy: columnByToSend } : {},
      groupByToSend !== initialGroupBy ? { groupBy: groupByToSend } : {}
    ));
  };

  return (
    <TooltipPopover
      focusOnMount={editMode}
      currentAnchor={currentAnchor}
      onClose={editMode ? () => {
        handleCloseAndSubmit(conceptId);
      } : () => handleClose()}
      onEscape={editMode ? () => {
        handleClose();
      } : undefined}
    >
      <div
        className={classnames({
          [classes.container]: true,
          [classes.containerSmall]: sizeVariant === SizeVariant.small,
        })}
      >
        <ConceptTooltip
          conceptId={concept.id}
          buttonView={buttonView}
          editMode={editMode}
          navigationFilters={navigationFilters}
        >
          <>
            {colorField && colorFieldHandler && colorFieldDimensionId ? (
              <SpacedContainer margin={{ bottom: Spacing.s }}>
                <SpacingLine>
                  <Tooltip title={getFieldLabel(store, colorField)}>
                    <div className={classes.firstColumnContainer}>
                      <Typo noWrap color={theme.color.text.secondary}>{getFieldLabel(store, colorField)}</Typo>
                    </div>
                  </Tooltip>
                  <div className={classes.inlineContainer}>
                    {colorFieldHandler.renderField?.({
                      dimensionsMapping: { [colorFieldDimensionId]: concept.id },
                      value: color,
                      onSubmit: (value) => setColor(value as string | null),
                      readOnly: readOnly || Boolean(colorField[Field_IntegrationOnly]),
                    })}
                  </div>
                </SpacingLine>
              </SpacedContainer>
            ) : undefined}
            {swimlaneProgressField && swimlaneProgressFieldHandler && swimlaneProgressFieldDimensionId ? (
              <SpacedContainer margin={{ bottom: Spacing.s }}>
                <SpacingLine>
                  <Tooltip title={getFieldLabel(store, swimlaneProgressField)}>
                    <div className={classes.firstColumnContainer}>
                      <Typo noWrap color={theme.color.text.secondary}>{getFieldLabel(store, swimlaneProgressField)}</Typo>
                    </div>
                  </Tooltip>
                  <div className={classes.inlineContainer}>
                    <NumberFieldRenderer
                      fieldId={swimlaneProgressField.id}
                      dimensionsMapping={{ [swimlaneProgressFieldDimensionId]: concept.id }}
                      initialValue={progress?.value ?? undefined}
                      readOnly={readOnly}
                      focusOnMount={false}
                      onSubmit={(v) => setProgress({ value: v })}
                    />
                  </div>
                </SpacingLine>
              </SpacedContainer>
            ) : undefined}
            {
              swimlaneColumnByField
              && swimlaneColumnByFieldHandler
              && swimlaneColumnByFieldDimensionId
              && (swimlaneColumnByField.id !== colorField?.id)
                ? (
                  <SpacedContainer margin={{ bottom: Spacing.s }}>
                    <SpacingLine>
                      <Tooltip title={getFieldLabel(store, swimlaneColumnByField)}>
                        <div className={classes.firstColumnContainer}>
                          <Typo noWrap color={theme.color.text.secondary}>{getFieldLabel(store, swimlaneColumnByField)}</Typo>
                        </div>
                      </Tooltip>
                      <div className={classes.inlineContainer}>
                        {swimlaneColumnByFieldHandler.renderField?.({
                          dimensionsMapping: { [swimlaneColumnByFieldDimensionId]: concept.id },
                          value: columnBy,
                          onSubmit: (value) => setColumnBy(value as string | null),
                          readOnly: readOnly || swimlaneColumnByField[Field_IntegrationOnly] as boolean,
                        })}
                      </div>
                    </SpacingLine>
                  </SpacedContainer>
                ) : undefined
            }
            {
              swimlaneGroupByField
              && swimlaneGroupByFieldHandler
              && swimlaneGroupByFieldDimensionId
              && (swimlaneGroupByField.id !== colorField?.id && swimlaneGroupByField.id !== swimlaneColumnByField?.id)
                ? (
                  <SpacedContainer margin={{ bottom: Spacing.s }}>
                    <SpacingLine>
                      <Tooltip title={getFieldLabel(store, swimlaneGroupByField)}>
                        <div className={classes.firstColumnContainer}>
                          <Typo noWrap color={theme.color.text.secondary}>{getFieldLabel(store, swimlaneGroupByField)}</Typo>
                        </div>
                      </Tooltip>
                      <div className={classes.inlineContainer}>
                        {swimlaneGroupByFieldHandler.renderField?.({
                          dimensionsMapping: { [swimlaneGroupByFieldDimensionId]: concept.id },
                          value: groupBy,
                          onSubmit: (value) => setGroupBy(value as string | null),
                          readOnly: readOnly || swimlaneGroupByField[Field_IntegrationOnly] as boolean,
                        })}
                      </div>
                    </SpacingLine>
                  </SpacedContainer>
                ) : undefined
            }
          </>
        </ConceptTooltip>
      </div>
    </TooltipPopover>
  );
};

export default ConceptSwimlaneTooltip;
