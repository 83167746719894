import { getPathReturnedConceptDefinitionId } from 'yooi-modules/modules/conceptModule';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { filterNullOrUndefined } from 'yooi-utils';
import useAuth from '../../../store/useAuth';
import useStore from '../../../store/useStore';
import useForceUpdate from '../../../utils/useForceUpdate';
import { useSessionStorageState } from '../../../utils/useSessionStorage';
import { getDimensionLabel } from '../views/data/dataResolution';
import { getLoggedUserParameterDefinition } from './filterUtils';
import { getDefaultFilterConfiguration } from './quickFiltersUtils';
import type { FilterConfiguration, ViewFilters } from './useFilterSessionStorage';
import { useOnFilterChanged } from './useFilterSessionStorage';

export const useViewFilters = (viewFilters: ViewFilters, viewDimensions: ViewDimension[]): FilterConfiguration | undefined => {
  const store = useStore();
  const { loggedUserId } = useAuth();
  const forceUpdate = useForceUpdate();

  const { getViewFilters, filterKey, hasFilters } = viewFilters;
  useOnFilterChanged(filterKey, forceUpdate);

  const defaultFilterConfiguration = getDefaultFilterConfiguration(
    store,
    loggedUserId,
    getViewFilters,
    [
      getLoggedUserParameterDefinition(),
      ...viewDimensions.map((dimension, index) => {
        const dimensionConceptDefinitionId = getPathReturnedConceptDefinitionId(store, dimension.path);
        return dimensionConceptDefinitionId ? {
          id: dimension.id,
          type: 'dimension' as const,
          typeId: dimensionConceptDefinitionId,
          label: getDimensionLabel(store, dimension.label, index, dimension.path),
        } : undefined;
      }).filter(filterNullOrUndefined),
    ]
  );

  const filterConfiguration = useSessionStorageState<FilterConfiguration | undefined>(filterKey, defaultFilterConfiguration)[0];

  if (!hasFilters) {
    return undefined;
  } else {
    return filterConfiguration;
  }
};
