// This file is automatically updated by the generate-constants script. Do not update it manually.

export const ConceptLayoutModuleId = 'e44e91a8-9dea-5a0b-997c-a2ac65b9a757';
export const Block = '73ec529f-c386-5c24-9b35-698782333156';
export const Block_ConceptDefinition = '139cf109-575c-46e2-a721-80592e0213db';
export const Block_Documentation = '6f874a03-b856-4204-87c8-92a11e40aec9';
export const Block_EditDisplayCondition = '12659aa4-abe6-4407-a8da-b9dfce509f08';
export const Block_IsDocumentationInline = '77a3853d-86b7-489d-9401-bd33a692928b';
export const Block_Name = 'ac026b6c-52d4-5636-9624-6bf1da729cd6';
export const Block_Parent = '8a6fea24-3d7f-4cc7-beda-a8428dcdc7da';
export const Block_Rank = '288b6f79-bd35-5848-bfcf-705454a07b0f';
export const Block_Type = '9e506421-7782-4dd7-9956-cc978204c1da';
export const Block_ViewDisplayCondition = '6cd1127f-3fb5-4060-aa29-f940b8ec3ed2';
export const Block_WorkflowField = '4ed8219d-689e-4e62-90ab-7318795e0891';
export const BlockType = '5efa8f50-8c6b-4fca-a04e-479a87eb4785';
export const BlockType_Column = '31272bc0-5a57-4da8-9f6c-d61267862be5';
export const BlockType_Name = 'b0794a61-4ff4-40f3-be80-44720b0861a3';
export const BlockType_Section = '5a4c4968-c9e7-4792-a059-b595abbd513f';
export const BlockType_Tab = '422ac8b5-3d4c-4429-ba1b-645cf1bc4fc0';
export const ConceptDefinitionLibraryTable = '1e6464e2-7e5d-4b30-b41e-a54dbb0a1526';
export const ConceptDefinitionLibraryTable_HideOnCard = 'dd4f7cd3-d838-43cc-bd2e-69fc1d1897aa';
export const ConceptDefinitionLibraryTable_Rank = 'f99fce9b-2146-43db-a266-f69cab3bf5f7';
export const ConceptDefinitionLibraryTable_Role_ConceptDefinition = 0;
export const ConceptDefinitionLibraryTable_Role_Field = 1;
export const ConceptDefinitionLibraryTable_Width = '455321f7-1571-4ac3-8b21-2113a08b1c01';
export const FieldBlockConceptInstanceDisplay = '2fa47153-96a2-4fa0-9ee6-b1ef0d2ba2e3';
export const FieldBlockConceptInstanceDisplay_DisplayOverride = '58dda0df-c5dc-4bd0-a286-0950eeba3b3f';
export const FieldBlockConceptInstanceDisplay_Role_Concept = 1;
export const FieldBlockConceptInstanceDisplay_Role_FieldBlockDisplay = 0;
export const FieldBlockDisplay = '41a32242-4db9-4c9c-ad82-aea5457fd373';
export const FieldBlockDisplay_Block = '5d9086fc-2682-43b9-8b32-9ebec028ba05';
export const FieldBlockDisplay_DocumentationOverride = '663e46d9-d6b8-4b4b-8db4-9596f5ab94c7';
export const FieldBlockDisplay_EditDisplayCondition = '49347e30-3401-40b2-a1b0-c3c6082af145';
export const FieldBlockDisplay_FieldDisplayConfiguration = '0697bfcb-e31d-43c7-a6fd-a4792445fecc';
export const FieldBlockDisplay_FieldPath = '1d16c87b-02c9-4953-8073-977da4bca8df';
export const FieldBlockDisplay_Rank = '2fadf351-b531-4e54-958c-a180757c384d';
export const FieldBlockDisplay_TitleOverride = '477706a1-ac4b-436c-9a9f-d4827bfadfd2';
export const FieldBlockDisplay_ViewDisplayCondition = '9774011b-1ec3-45ce-b6b5-dbbacecfae02';
export const FieldBlockDisplay_VisibleFromStatus = '76af72ef-c8c6-4ab7-a41d-fc530c9a759f';
export const FieldBlockDisplay_VisibleToStatus = '0c166d7e-0fd4-4a8f-9cca-6eeb44b27c62';
export const FieldBlockDisplay_WorkflowField = '406a8ad2-bd99-40df-bdcc-71b2e837cdea';
export const FieldBlockDisplay_WorkflowFieldDisplayType = '567e39ef-a47c-4ede-a29c-1ba6eb6430ca';
export const FieldRelevantFieldDisplay = 'e590c639-ab58-48e6-9047-bd60efd82fcb';
export const FieldRelevantFieldDisplay_Rank = '3dacc5e6-410c-48d9-b78f-72881de28f70';
export const FieldRelevantFieldDisplay_Role_ConceptDefinition = 0;
export const FieldRelevantFieldDisplay_Role_Field = 1;
export const FieldSuggestedDisplay = '04b3cfe4-8bcb-48f3-8c30-b79cc1271dba';
export const FieldSuggestedDisplay_Rank = 'b674d03e-ff69-4a16-897b-1383c1cea466';
export const FieldSuggestedDisplay_Role_ConceptDefinition = 0;
export const FieldSuggestedDisplay_Role_Field = 1;
export const IFrameField = '35fa23ca-c228-4c86-99fb-f5e408aa1acc';
export const IFrameField_Url = 'a9963c88-f6f8-4488-9ef2-1797af72692c';
export const SearchFieldDisplay = '65763d7f-674e-422a-bae8-2ad808b7d2bc';
export const SearchFieldDisplay_Role_ConceptDefinition = 0;
export const SearchFieldDisplay_Role_Field = 1;
export const TextConstantField = 'b17fd3a7-98a9-4462-abfc-a807630f612a';
export const TextConstantField_Text = 'a076c380-d00c-4c90-a071-50908afe1117';
export const Block_FieldDisplays = FieldBlockDisplay_Block;
export const BlockParent_Block = Block_Parent;
export const BlockType_Blocks = Block_Type;
export const ConceptDefinition_Blocks = Block_ConceptDefinition;
export const WorkflowField_Blocks = Block_WorkflowField;
export const WorkflowField_DisplayAsBlock = FieldBlockDisplay_WorkflowField;
