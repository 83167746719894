import { OriginSources, ValidationStatus } from 'yooi-store';
import { compareProperty, compareRank, ranker } from 'yooi-utils';
import { asLocal, CommonAsType } from '../common/fields/commonPropertyType';
import { PropertyMandatoryType } from '../common/types/TypeModuleDslType';
import { adminOnlyAcl } from '../conceptModule';
import { Class_Instances } from '../typeModule/ids';
import { Homepage, Homepage_Rank, IFrameAllowedUrl_Url, LeftBarItem, LeftBarItem_Rank, PlatformConfiguration_Language, PlatformConfiguration_MasterDetailMode } from './ids';
import type { HomepageStoreObject } from './modelTypes';
import { registerModel } from './module';

export interface ImageWithSize {
  revisionId: string,
  width: number,
  height: number,
}

export enum PlatformLanguage {
  fr = 'fr',
  en = 'en',
}

export enum MasterDetailMode {
  openedByDefault = 'openedByDefault',
  closedByDefault = 'closedByDefault',
}

const { type } = registerModel;

type({
  label: 'LeftBarItem',
  accessControlList: {
    READ: () => () => ({ rule: 'leftBarItem.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'leftBarItem', 'WRITE'),
    DELETE: () => (_, objectId) => ({ rule: 'leftBarItem.delete.delegate', status: ValidationStatus.DELEGATED, targetId: objectId, targetAction: 'WRITE' }),
  },
})
  .property({ label: 'LeftBarItem_Path', as: CommonAsType.Path })
  .property({ label: 'LeftBarItem_DisplayConditions', as: CommonAsType.Filters })
  .property({
    label: 'LeftBarItem_Rank',
    as: CommonAsType.string,
    mandatory: { type: PropertyMandatoryType.mandatory },
    businessRules: [
      ({ getObject }) => (origin, { id, properties }) => {
        if (!properties || origin.source !== OriginSources.CLIENT) {
          // You deleted the instances ? we don't care, we won't have any conflicts
          // The actions isn't from the client ? you are currently validating the fix (the store is probably in an unstable state)
          return undefined;
        }

        const leftBarItems = getObject(LeftBarItem)
          .navigateBack(Class_Instances)
          .sort((a, b) => compareRank(a[LeftBarItem_Rank] as string, b[LeftBarItem_Rank] as string));

        const decoratedItems = ranker.decorateList(
          leftBarItems,
          (instance) => instance[LeftBarItem_Rank] as string
        );

        const itemWithSameRankIndex = decoratedItems.findIndex(({ itemRank }) => itemRank === properties[LeftBarItem_Rank] as string);
        if (itemWithSameRankIndex !== -1) {
          // We have a conflict
          return {
            rule: 'leftBarItem.rank.conflict.managed',
            status: ValidationStatus.ACCEPTED,
            generateSystemEvent: ({ updateObject }) => {
              updateObject(id[0], { [LeftBarItem_Rank]: decoratedItems[itemWithSameRankIndex].insertBeforeRank() });
            },
          };
        }

        return undefined;
      },
    ],
  });

type({
  label: 'Homepage',
  accessControlList: {
    READ: () => () => ({ rule: 'homepage.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'homepage', 'WRITE'),
    DELETE: () => (_, objectId) => ({ rule: 'homepage.delete.delegate', status: ValidationStatus.DELEGATED, targetId: objectId, targetAction: 'WRITE' }),
  },
})
  .property({ label: 'Homepage_Path', as: CommonAsType.Path })
  .property({ label: 'Homepage_DisplayConditions', as: CommonAsType.Filters })
  .property({
    label: 'Homepage_Rank',
    as: CommonAsType.string,
    mandatory: { type: PropertyMandatoryType.mandatory },
    businessRules: [
      ({ getObject }) => (origin, { id, properties }) => {
        if (!properties || origin.source !== OriginSources.CLIENT) {
          // You deleted the instances ? we don't care, we won't have any conflicts
          // The actions isn't from the client ? you are currently validating the fix (the store is probably in an unstable state)
          return undefined;
        }

        const homepages = getObject(Homepage)
          .navigateBack<HomepageStoreObject>(Class_Instances)
          .sort(compareProperty(Homepage_Rank, compareRank));

        const decoratedItems = ranker.decorateList(homepages, (homepage) => homepage[Homepage_Rank]);

        const itemWithSameRankIndex = decoratedItems.findIndex(({ itemRank }) => itemRank === properties[Homepage_Rank]);
        if (itemWithSameRankIndex !== -1) {
          // We have a conflict
          return {
            rule: 'homepage.rank.conflict.managed',
            status: ValidationStatus.ACCEPTED,
            generateSystemEvent: ({ updateObject }) => {
              updateObject(id[0], { [Homepage_Rank]: decoratedItems[itemWithSameRankIndex].insertBeforeRank() });
            },
          };
        }

        return undefined;
      },
    ],
  });

export interface PaletteColor {
  label?: string,
  value: string,
}

export enum ConfigurationType {
  default = 'default',
  custom = 'custom',
}

type({
  label: 'PlatformConfiguration',
  accessControlList: {
    READ: () => () => ({ rule: 'homepageConfiguration.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'homepageConfiguration', 'WRITE'),
    DELETE: () => () => ({ rule: 'homepageConfiguration.delete.deny', status: ValidationStatus.REJECTED }),
  },
})
  .property({
    label: 'PlatformConfiguration_Logo',
    as: asLocal('ImageWithSize'),
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'PlatformConfiguration_Name',
    as: CommonAsType.string,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'PlatformConfiguration_Motto',
    as: CommonAsType.string,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'PlatformConfiguration_LeftBarImage',
    as: asLocal('ImageWithSize'),
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'PlatformConfiguration_Language',
    as: asLocal('PlatformLanguage'),
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
    businessRules: [
      () => (_, { properties }) => {
        if (!properties || properties[PlatformConfiguration_Language] === undefined) {
          return undefined;
        } else if (properties[PlatformConfiguration_Language] === null) {
          return {
            status: ValidationStatus.ACCEPTED,
            rule: 'PlatformConfiguration_Language.clear',
          };
        } else if (
          typeof properties[PlatformConfiguration_Language] === 'string'
          && Object.values(PlatformLanguage).includes(properties[PlatformConfiguration_Language] as PlatformLanguage)
        ) {
          return {
            status: ValidationStatus.ACCEPTED,
            rule: 'PlatformConfiguration_Language.validUpdate',
          };
        } else {
          return {
            status: ValidationStatus.REJECTED,
            rule: 'PlatformConfiguration_Language.invalidUpdate',
          };
        }
      },
    ],
  })
  .property({
    label: 'PlatformConfiguration_MasterDetailMode',
    as: asLocal('MasterDetailMode'),
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
    businessRules: [
      () => (_, { properties }) => {
        if (!properties || properties[PlatformConfiguration_MasterDetailMode] === undefined) {
          return undefined;
        } else if (properties[PlatformConfiguration_MasterDetailMode] === null) {
          return {
            status: ValidationStatus.ACCEPTED,
            rule: 'PlatformConfiguration_MasterDetailMode.clear',
          };
        } else if (
          typeof properties[PlatformConfiguration_MasterDetailMode] === 'string'
          && Object.values(MasterDetailMode).includes(properties[PlatformConfiguration_MasterDetailMode] as MasterDetailMode)
        ) {
          return {
            status: ValidationStatus.ACCEPTED,
            rule: 'PlatformConfiguration_MasterDetailMode.validUpdate',
          };
        } else {
          return {
            status: ValidationStatus.REJECTED,
            rule: 'PlatformConfiguration_MasterDetailMode.invalidUpdate',
          };
        }
      },
    ],
  })
  .property({
    label: 'PlatformConfiguration_AccentColorType',
    as: asLocal('ConfigurationType'),
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'PlatformConfiguration_AccentColor',
    as: CommonAsType.string,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'PlatformConfiguration_ColorPalette',
    as: asLocal('PaletteColor', true),
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'PlatformConfiguration_ColorPaletteType',
    as: asLocal('ConfigurationType'),
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'PlatformConfiguration_AutoLogoutTime',
    as: CommonAsType.number,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'PlatformConfiguration_IntegrationDefaultExpiration',
    as: CommonAsType.number,
    mandatory: { type: PropertyMandatoryType.mandatory },
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .property({
    label: 'PlatformConfiguration_CollaborationMessage',
    as: CommonAsType.string,
    initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
  })
  .instance({ label: 'CurrentPlatformConfiguration' });

type({
  label: 'IFrameAllowedUrl',
  objectDebugLabel: ({ getObjectOrNull }) => (objectId) => getObjectOrNull(objectId)?.[IFrameAllowedUrl_Url] as string,
  accessControlList: {
    READ: () => () => ({ rule: 'IFrameAllowedUrl.read.allow', status: ValidationStatus.ACCEPTED }),
    WRITE: (store) => ({ userId }) => adminOnlyAcl(store, userId, 'IFrameAllowedUrl', 'WRITE'),
    DELETE: () => (_, objectId) => ({ rule: 'IFrameAllowedUrl.delete.delegate', status: ValidationStatus.DELEGATED, targetId: objectId, targetAction: 'WRITE' }),
  },
})
  .property({ label: 'IFrameAllowedUrl_Url', as: CommonAsType.string });
