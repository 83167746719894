import { useCallback, useMemo } from 'react';
import { joinObjects } from 'yooi-utils';
import declareBus from './declareBus';
import { sessionStoragePrefix } from './sessionStorageUtils';
import useForceUpdate from './useForceUpdate';

export enum SessionStorageKeys {
  filterPanel = 'filterPanel',
  collaboration = 'collaboration',
  tableConfig = 'table-config',
  timelineConfig = 'timeline-config',
  matrixConfig = 'matrix-config',
  swimlaneConfig = 'swimlane-config',
  dashboardLayoutMode = 'dashboard-layout-mode',
  developerMode = 'developer-mode',
  sortConfig = 'sort-config',
  layoutCollapseConfig = 'layout-collapse-config',
  notifications = 'notifications',
}

const useSessionStorageBus = declareBus<{ key: string }>('sessionStorage').useBus;

const useSessionStorageChange = (componentKey: string, refreshCallback: () => void) => {
  const callback = useCallback(({ key }: { key: string }) => {
    if (key === componentKey) {
      refreshCallback();
    }
  }, [componentKey, refreshCallback]);
  const dispatchUpdated = useSessionStorageBus(callback);
  return useCallback((key: string) => dispatchUpdated({ key }), [dispatchUpdated]);
};

export const useSessionStorageState = <T>(key: string, initialValue: T, useNotify = true, resetTriggerFunction?: (sessionStorageItem: T) => boolean): [
  T,
  (value: T) => void,
  (value: Partial<T>) => void,
  () => void
] => {
  const forceUpdate = useForceUpdate();
  const notify = useSessionStorageChange(key, forceUpdate);

  let sessionStorageItem: T;
  const rawSessionStorageItem = sessionStorage.getItem(`${sessionStoragePrefix}${key}`);
  sessionStorageItem = useMemo(() => {
    try {
      if (!rawSessionStorageItem) {
        return undefined;
      }
      return JSON.parse(rawSessionStorageItem);
    } catch (_) {
      // do nothing
      return undefined;
    }
  }, [rawSessionStorageItem]);

  if ((sessionStorageItem === undefined && key && initialValue !== undefined) || (sessionStorageItem && resetTriggerFunction?.(sessionStorageItem))) {
    sessionStorageItem = initialValue;
    sessionStorage.setItem(`${sessionStoragePrefix}${key}`, JSON.stringify(sessionStorageItem));
    setImmediate(() => {
      if (useNotify) {
        notify(key);
      }
    });
  }

  const setSessionStorageItem = (value: T) => {
    sessionStorage.setItem(`${sessionStoragePrefix}${key}`, JSON.stringify(value));
    if (useNotify) {
      notify(key);
    }
  };

  const updateSessionStorageItem = (value: Partial<T>) => {
    sessionStorage.setItem(`${sessionStoragePrefix}${key}`, JSON.stringify(joinObjects(sessionStorageItem ?? {}, value)));
    if (useNotify) {
      notify(key);
    }
  };

  const resetSessionStorageItem = () => {
    sessionStorage.removeItem(`${sessionStoragePrefix}${key}`);
    if (useNotify) {
      notify(key);
    }
  };

  return [sessionStorageItem, setSessionStorageItem, updateSessionStorageItem, resetSessionStorageItem];
};

const useMultipleSessionStorageChange = (componentKeys: string[], refreshCallback: () => void) => {
  const callback = useCallback(({ key }: { key: string }) => {
    if (componentKeys.includes(key)) {
      refreshCallback();
    }
  }, [componentKeys, refreshCallback]);
  const dispatchUpdated = useSessionStorageBus(callback);
  return useCallback((key: string) => dispatchUpdated({ key }), [dispatchUpdated]);
};

export const useMultipleSessionStorageState = <T>(keys: string[], initialValue: T, useNotify = true, resetTriggerFunction?: (sessionStorageItem: T) => boolean): [
  { key: string, value: string | null }[],
  (key: string, value: T) => void,
  (key: string, value: T) => void
] => {
  const forceUpdate = useForceUpdate();
  const notify = useMultipleSessionStorageChange(keys, forceUpdate);

  const rawSessionStorageItems = keys.map((key) => ({ key, value: sessionStorage.getItem(`${sessionStoragePrefix}${key}`) }));

  rawSessionStorageItems.forEach((sessionStorageItem, index) => {
    if ((sessionStorageItem?.value === null && sessionStorageItem.key && initialValue !== undefined)
      || (sessionStorageItem.value && resetTriggerFunction?.(JSON.parse(sessionStorageItem.value)))) {
      rawSessionStorageItems[index].value = JSON.stringify(initialValue);
      sessionStorage.setItem(`${sessionStoragePrefix}${sessionStorageItem.key}`, JSON.stringify(initialValue));
    }
  });
  const setSessionStorageItem = (key: string, value: T) => {
    sessionStorage.setItem(`${sessionStoragePrefix}${key}`, JSON.stringify(value));
    if (useNotify) {
      notify(key);
    }
  };

  const updateSessionStorageItem = (key: string, value: Partial<T>) => {
    const sessionStorageItem = rawSessionStorageItems.find(({ key: itemKey }) => itemKey === `${sessionStoragePrefix}${key}`);
    let newValue;
    if (sessionStorageItem?.value) {
      newValue = joinObjects(JSON.parse(sessionStorageItem.value), value);
    } else {
      newValue = { ...value };
    }
    sessionStorage.setItem(`${sessionStoragePrefix}${key}`, JSON.stringify(newValue));
    if (useNotify) {
      notify(key);
    }
  };

  return [rawSessionStorageItems, setSessionStorageItem, updateSessionStorageItem];
};
