import { extractLinksFromText } from '../urlUtils';

export type RichText = CustomElement[];

export enum RichTextElementTypes {
  paragraph = 'paragraph',
  'block-quote' = 'block-quote',
  'bulleted-list' = 'bulleted-list',
  'list-item' = 'list-item',
  'numbered-list' = 'numbered-list',
  link = 'link',
}

export enum RichTextMarks {
  bold = 'bold',
  italic = 'italic',
  underline = 'underline',
  code = 'code',
  color = 'color',
}

export interface CustomText {
  text: string,
  [RichTextMarks.bold]?: boolean,
  [RichTextMarks.italic]?: boolean,
  [RichTextMarks.underline]?: boolean,
  [RichTextMarks.code]?: boolean,
  [RichTextMarks.color]?: string,
}

export interface CustomElement {
  type: RichTextElementTypes,
  children: (CustomElement | CustomText)[],
  url?: string,
}

export const isCustomText = (element: unknown): element is CustomText => element !== undefined && element !== null && typeof element === 'object'
  && (element as CustomText).text !== undefined && typeof (element as CustomText).text === 'string'
  && ((element as CustomText)[RichTextMarks.bold] === undefined || typeof (element as CustomText)[RichTextMarks.bold] === 'boolean')
  && ((element as CustomText)[RichTextMarks.italic] === undefined || typeof (element as CustomText)[RichTextMarks.italic] === 'boolean')
  && ((element as CustomText)[RichTextMarks.underline] === undefined || typeof (element as CustomText)[RichTextMarks.underline] === 'boolean')
  && ((element as CustomText)[RichTextMarks.code] === undefined || typeof (element as CustomText)[RichTextMarks.code] === 'boolean')
  && ((element as CustomText)[RichTextMarks.color] === undefined || typeof (element as CustomText)[RichTextMarks.color] === 'string');

const isCustomElement = (element: unknown): element is CustomElement => (
  element !== undefined
  && typeof element === 'object'
  && (element as CustomElement).type !== undefined
  && Object.values(RichTextElementTypes).includes((element as CustomElement).type)
  && Array.isArray((element as CustomElement).children)
  && (element as CustomElement).children.every((child: unknown) => isCustomElement(child) || isCustomText(child))
  && ((element as CustomElement).url === undefined || typeof (element as CustomElement).url === 'string')
);

export const isRichText = (richText: unknown): richText is (RichText | undefined) => (
  richText === undefined || (Array.isArray(richText) && richText.every((element) => isCustomElement(element)))
);

const elementToText = (element: CustomElement): string => {
  if (!element) {
    return '';
  }
  const { children, type } = element;
  const contentTextArray = children.map((c) => (isCustomText(c) ? c.text : elementToText(c)));
  if (type === RichTextElementTypes.paragraph) {
    return `${contentTextArray.join('') ?? ''}\n`;
  } else if (type === RichTextElementTypes['bulleted-list']) {
    return contentTextArray.map((text) => `- ${text}`).join('\n') ?? '';
  } else {
    return contentTextArray.join('') ?? '';
  }
};

export const textToRichText = (textToTransform: string | undefined): RichText | undefined => {
  if (textToTransform) {
    return [{
      type: RichTextElementTypes.paragraph,
      children: extractLinksFromText(textToTransform)
        .map(({ text, isLink }) => {
          if (isLink) {
            return { type: RichTextElementTypes.link, url: text, children: [{ text }] };
          } else {
            return { text };
          }
        }),
    }];
  } else {
    return undefined;
  }
};

export const richTextToText = (richText: RichText | undefined): string | undefined => (
  richText?.map((c) => (isCustomText(c) ? c.text : elementToText(c))).join('').trim()
);

export const urlToRichText = (url: string): RichText => [{
  type: RichTextElementTypes.paragraph,
  children: [{ type: RichTextElementTypes.link, url, children: [{ text: url }] }],
}];

export const sanitizeRichText = (richText: RichText | undefined): RichText | undefined => {
  if (!richText || (Array.isArray(richText) && richText.length === 0)) {
    return undefined;
  } else {
    return richText;
  }
};
