import type { Operation, PendingOperation, RawObjectStore, TimeseriesRepository, TimeseriesUpdate } from 'yooi-store';
import { createEventDecoder, createEventEncoder, OriginSources, ProcessedEventStatus } from 'yooi-store';
import { compareEventId, createHash, dateFormats, doYield, forgetAsyncPromise, formatDisplayDate, fromError, newError, sleep } from 'yooi-utils';
import { reportClientError } from '../utils/clientReporterUtils';
import i18n from '../utils/i18n';
import { clearNotification, notifyError, notifyWarning } from '../utils/notify';
import type { createBrowserSnapshotNetwork } from './browserSnapshotNetwork';
import type { BusinessRulesHandler } from './businessRulesHandler';
import { BusinessRulesStatus } from './businessRulesHandler';
import type { OnConnectionProgress } from './connectionProgress';
import { ConnectionPhase } from './connectionProgress';
import debounce, { DebounceMode } from './debounce';
import { isNetworkCallSuccessful } from './networkClientUtils';
import type { DownloadNetworkSnapshot, RemoteEvent, SendOutgoingEvent } from './networkEventClient';
import { ObjectStoreDesynchronizedError } from './ObjectStoreDesynchronizedError';
import { createSnapshotDownloader } from './snapshotDownloader';
import type { TimeseriesSnaphsotHandler, TimeseriesSnapshotRequest } from './timeseriesSnapshotHandler';
import { TimeseriesSnapshotStatus } from './timeseriesSnapshotHandler';

const SAVED_CHANGES_CHECK_INTERVAL_MS = 1_000;
const SAVED_CHANGES_DELAY_THRESHOLD_MS = 2_000;

export type SnapshotOperation = [string | string[], { [propertyId: string]: unknown }];

export interface Snapshot {
  eventId: string,
  checksum: string,
  operations: SnapshotOperation[],
  rollbackOperations: SnapshotOperation[],
}

interface CreateConcurrencyHandlerProps {
  clientId: string,
  bypassReadAcl: boolean,
  registerBrowserSnapshotNetwork: ReturnType<typeof createBrowserSnapshotNetwork>,
  downloadSnapshotFromNetwork: DownloadNetworkSnapshot,
  fetchTimeseries: TimeseriesSnaphsotHandler['downloadTimeseriesSnapshot'],
  hasPendingTimeseriesFetch: () => boolean,
  resetTimeseriesRepository: () => void,
  connectionNotificationHandler: { onConnection: () => void, onConnected: () => void },
  sendOutgoingEvent: SendOutgoingEvent,
  applyRemoteEvent: (id: string | string[], properties: Record<string, unknown> | null | undefined, isRollback?: boolean) => void,
  applyTimeseriesEvent: (eventId: string | undefined, event: PendingOperation[], isLocal: boolean) => TimeseriesUpdate[],
  validateAndApplyLocalEvent: BusinessRulesHandler['validateAndApplyLocalEvent'],
  updateTimeseries: TimeseriesRepository['updateTimeseries'],
  flushEvents: () => void,
  flushStore: RawObjectStore['flush'],
  reportMetric: (name: string, data: { duration: number }) => void,
  onDesynchronized: (cause: Error) => void,
  onUnauthorized: () => void,
  onPermissionUpdated: () => void,
  onConnectionProgress: OnConnectionProgress,
}

export interface ConcurrencyHandler {
  handleIncomingEvent: (remoteEvent: RemoteEvent) => void,
  handleOutgoingEvent: (event: PendingOperation[], rollbackEvent: PendingOperation[], rollbackTimeseriesEvent: TimeseriesUpdate[]) => void,
  handleTimeseriesInit: (request: TimeseriesSnapshotRequest[]) => Promise<TimeseriesSnapshotStatus>,
  handleTimeseriesInsertion: (lastInsertedEventId: string) => void,
  onConnected: (lastBroadcastedEventId: string, userId: string) => Promise<{
    metrics: {
      durationMs: number,
      snapshotDownloadDurationMs: number,
      snapshotApplyDurationMs: number,
      storeCatchupDurationMs: number,
      downloadSnapshot: unknown,
      omittedLocalEventIds: string[],
      catchupRemoteEventIds: string[],
    },
  } | undefined>,
  onTimeseriesConnected: (lastTimeseriesEventIdInserted: string, userId: string) => void,
  close: () => void,
  hasUnsavedChanges: () => boolean,
}

interface RebaseProps {
  localEventCleanup?: {
    type: 'unique' | 'followed',
    localEventToRemoveIndex: number,
  },
  remoteEventToApply?: RemoteEvent,
}

interface LocalEvent {
  creationTime: number,
  submissionTime?: number,
  submissionStatus: SubmissionsStatus,
  event: PendingOperation[],
  eventId?: string,
  rollbackEvent: PendingOperation[],
  rollbackTimeseriesEvent: TimeseriesUpdate[],
}

interface BroadcastedRemoteEvent {
  event: Operation[],
  eventId: string,
  isLocal: boolean,
}

enum SubmissionsStatus {
  queued = 'queued',
  submitting = 'submitting',
  rejected = 'rejected',
  accepted = 'accepted',
}

const isLocalEventUnSaved = ({ submissionStatus }: LocalEvent) => submissionStatus === SubmissionsStatus.queued || submissionStatus === SubmissionsStatus.submitting;

const createConcurrencyHandler = ({
  clientId,
  bypassReadAcl,
  registerBrowserSnapshotNetwork,
  downloadSnapshotFromNetwork,
  fetchTimeseries,
  hasPendingTimeseriesFetch,
  resetTimeseriesRepository,
  connectionNotificationHandler,
  sendOutgoingEvent,
  applyRemoteEvent,
  applyTimeseriesEvent,
  validateAndApplyLocalEvent,
  updateTimeseries,
  flushEvents,
  flushStore,
  reportMetric,
  onDesynchronized,
  onUnauthorized,
  onPermissionUpdated,
  onConnectionProgress,
}: CreateConcurrencyHandlerProps): ConcurrencyHandler => {
  const currentChecksum = createHash();
  const localEvents: LocalEvent[] = [];
  const broadcastedRemoteEvents: BroadcastedRemoteEvent[] = [];
  let lastReceivedEventId = '0-0';
  let lastInsertedTimeseriesEventId = '0-0';

  let broadcastedRemoteEventsCleanupLock = 0;

  let currentUserId: string;
  let dirtyStore: boolean = false;

  let currentConnectionAbortController: AbortController | undefined;
  let initializingCatchupRemoteEvents: RemoteEvent[] | undefined;
  const debouncedFlushEvents = debounce(flushEvents);

  let lostChangesToastId: string | undefined;
  const clearUnsavedChangesToast = () => {
    if (lostChangesToastId) {
      clearNotification(lostChangesToastId);
      lostChangesToastId = undefined;
    }
  };
  setInterval(() => {
    const firstUnsavedLocalEvent = localEvents.find(isLocalEventUnSaved);
    if (firstUnsavedLocalEvent && firstUnsavedLocalEvent.creationTime <= Date.now() - SAVED_CHANGES_DELAY_THRESHOLD_MS) {
      notifyWarning(
        i18n`Connection issue. We’re trying to save your changes...`,
        {
          persist: true,
          closeable: false,
          onToast: (toastId) => {
            lostChangesToastId = toastId;
          },
        }
      );
    } else {
      clearUnsavedChangesToast();
    }
  }, SAVED_CHANGES_CHECK_INTERVAL_MS);

  const applyDecodedRemoteEvent = (event: Operation<string | string[]>[]) => {
    createEventDecoder<string | string[]>((id, properties) => {
      applyRemoteEvent(id, properties);
    })(event);
  };

  const applyTimeseriesLocalEvents = () => {
    localEvents
      .forEach((localEvent) => {
        Object.assign(localEvent, { rollbackTimeseriesEvent: applyTimeseriesEvent(localEvent.eventId, localEvent.event, true) });
      });
  };

  const applyObjectLocalEvents = () => {
    localEvents
      .forEach((localEvent) => {
        const { status, audit, rollbackEvent } = validateAndApplyLocalEvent(localEvent.event);
        if (status === BusinessRulesStatus.rejected) {
          reportClientError(newError('Event rejected by the client during rebase', { audit }));
          notifyError(i18n`rejected`);
        }
        Object.assign(localEvent, { rollbackEvent });
      });
  };

  const applyAllLocalEvents = () => {
    localEvents
      .forEach((localEvent) => {
        const { status, audit, rollbackEvent } = validateAndApplyLocalEvent(localEvent.event);
        let rollbackTimeseriesEvent;
        if (status === BusinessRulesStatus.rejected) {
          reportClientError(newError('Event rejected by the client during rebase', { audit }));
          notifyError(i18n`rejected`);
        } else {
          rollbackTimeseriesEvent = applyTimeseriesEvent(localEvent.eventId, localEvent.event, true);
        }
        Object.assign(localEvent, { rollbackEvent, rollbackTimeseriesEvent });
      });
  };

  const applyTimeseriesRemoteEvents = () => {
    broadcastedRemoteEvents.forEach((event) => {
      const rollbackTimeseriesEvent = applyTimeseriesEvent(event.eventId, event.event, event.isLocal);
      Object.assign(event, { rollbackTimeseriesEvent });
    });
  };

  const cleanupTimeseriesRemoteEvents = () => {
    if (broadcastedRemoteEventsCleanupLock === 0) {
      const numberOfEventToRemove = broadcastedRemoteEvents.findIndex((event) => compareEventId(event.eventId, lastInsertedTimeseriesEventId) > 0);
      if (numberOfEventToRemove !== 0) {
        broadcastedRemoteEvents.splice(0, numberOfEventToRemove !== -1 ? numberOfEventToRemove : broadcastedRemoteEvents.length);
      }
    }
  };

  const applyTimeseriesSnapshot = (timeseriesSnapshotUpdates: TimeseriesUpdate[]) => {
    // rollback timeseries local events
    [...localEvents].reverse()
      .forEach(({ rollbackTimeseriesEvent }) => {
        if (rollbackTimeseriesEvent) {
          updateTimeseries(Array.from(rollbackTimeseriesEvent).reverse());
        }
      });

    // Insert new timeseries data fetched.
    if (timeseriesSnapshotUpdates.length) {
      updateTimeseries(timeseriesSnapshotUpdates);
    }

    // apply timeseries remote events
    applyTimeseriesRemoteEvents();

    // reapply timeseries local events
    applyTimeseriesLocalEvents();

    // remove event inserted in timeseries storage
    cleanupTimeseriesRemoteEvents();

    debouncedFlushEvents(hasPendingTimeseriesFetch() ? DebounceMode.Slow : DebounceMode.Immediate);
  };

  const rebase = ({ localEventCleanup, remoteEventToApply }: RebaseProps) => {
    // rollback all local events
    [...localEvents].reverse()
      .forEach(({ rollbackEvent, rollbackTimeseriesEvent }) => {
        if (rollbackEvent) {
          Array.from(rollbackEvent).reverse().forEach(({ id, properties }) => applyRemoteEvent(id, properties, true));
        }
        if (rollbackTimeseriesEvent) {
          updateTimeseries(Array.from(rollbackTimeseriesEvent).reverse());
        }
      });

    if (localEventCleanup?.type === 'unique') {
      localEvents.splice(localEventCleanup.localEventToRemoveIndex, 1);
    } else if (localEventCleanup?.type === 'followed') {
      localEvents.splice(localEventCleanup.localEventToRemoveIndex);
    }

    // apply timeseries remote events
    applyTimeseriesRemoteEvents();

    if (remoteEventToApply) {
      // apply the remote event
      const previousLocalChecksum = currentChecksum.value();
      const localChecksum = currentChecksum.update(remoteEventToApply.eventId);
      if (remoteEventToApply.checksum !== localChecksum) {
        dirtyStore = true;
        const details = { eventId: remoteEventToApply.eventId, checksum: remoteEventToApply.checksum, localChecksum, previousLocalChecksum };
        const error = new ObjectStoreDesynchronizedError('Abort rebase on checksum desynchronization', details);
        onDesynchronized(error);
        throw error;
      }
      applyDecodedRemoteEvent(remoteEventToApply.event);
    }

    // reapply all others local events
    applyAllLocalEvents();

    // remove event inserted in timeseries storage
    cleanupTimeseriesRemoteEvents();

    if (remoteEventToApply === undefined) {
      // Got local event feedback, flush now
      debouncedFlushEvents(DebounceMode.Immediate);
    } else if (remoteEventToApply.origin.source === OriginSources.API) {
      // API event, slow flush
      debouncedFlushEvents(DebounceMode.Slow);
    } else {
      // Other event, fast flush
      debouncedFlushEvents(DebounceMode.Fast);
    }
  };

  const findNextEventToSubmit = () => {
    for (let i = 0; i < localEvents.length; i += 1) {
      const localEvent = localEvents[i];
      if (localEvent.submissionStatus === SubmissionsStatus.submitting) {
        // an event is already being submitted, currently no other event can be submitted
        break;
      }

      if (localEvent.submissionStatus === SubmissionsStatus.queued) {
        // this is the first event that can be submitted
        return localEvent;
      }
    }
    return undefined;
  };

  const submitNextLocalEvent = () => {
    const eventToSubmit = findNextEventToSubmit();
    if (eventToSubmit) {
      eventToSubmit.submissionStatus = SubmissionsStatus.submitting;

      const doPost = async () => {
        const eventEncoder = createEventEncoder();
        eventToSubmit.event.forEach(({ id, properties, timeseries }) => eventEncoder.encodeObjectUpdate(id, properties, timeseries));
        eventToSubmit.submissionTime = Date.now();

        const result = await sendOutgoingEvent({ event: eventEncoder.getEncodedEvent() });
        // in case of reconnection that includes the first local event in the snapshot it's not a problem since we will update an object
        // that is no more referenced in the structures processed by submitNextLocalEvent()

        if (isNetworkCallSuccessful(result)
          && (result.answer.status === SubmissionsStatus.accepted && result.answer.eventId)) {
          eventToSubmit.submissionStatus = SubmissionsStatus.accepted;
          eventToSubmit.eventId = result.answer.eventId;
          if (!localEvents.some(isLocalEventUnSaved)) { // clear immediately
            clearUnsavedChangesToast();
          }
        } else {
          // because of the asynchronous call the index of the event to remove may have changed or even been removed from the local event list, so it has to be find again
          const firstUnsavedLocalEventIndex = localEvents.findIndex(isLocalEventUnSaved);
          if (firstUnsavedLocalEventIndex !== -1) {
            rebase({ localEventCleanup: { type: 'followed', localEventToRemoveIndex: firstUnsavedLocalEventIndex } });
          }

          if (result.httpStatus === 403) {
            notifyError(i18n`Session is read-only, your modification are lost`);
          } else {
            clearUnsavedChangesToast();
            notifyError(
              i18n`Changes made between ${formatDisplayDate(new Date(eventToSubmit.creationTime), dateFormats.hourWithSeconds)} and ${formatDisplayDate(new Date(), dateFormats.hourWithSeconds)} were discarded due to unstable internet connection.`,
              { persist: true }
            );

            const reportData = { clientId, bypassReadAcl, submissionTime: eventToSubmit.submissionTime, status: result.httpStatus, answer: result.answer };
            if (isNetworkCallSuccessful(result)) {
              reportClientError(newError('Failed to publish event: rejected', reportData));
            } else {
              reportClientError(fromError(result.error, 'Failed to publish event: network issue', reportData));
            }
          }
        }
        submitNextLocalEvent();
      };

      // don't wait for the POST answer synchronously, it's fire&forget from the submitNextLocalEvent() perspective
      doPost();
    }
  };

  const handleOutgoingEvent: ConcurrencyHandler['handleOutgoingEvent'] = (event, rollbackEvent, rollbackTimeseriesEvent) => {
    localEvents.push({ creationTime: Date.now(), event, rollbackEvent, rollbackTimeseriesEvent, submissionStatus: SubmissionsStatus.queued });
    submitNextLocalEvent();
  };

  const handleIncomingEvent: ConcurrencyHandler['handleIncomingEvent'] = (remoteEvent) => {
    const success = remoteEvent.status === ProcessedEventStatus.validated;
    // handleIncoming can be already played for event received during snapshot download, we don't want to add them again.
    if (success && !broadcastedRemoteEvents.some(({ eventId }) => eventId === remoteEvent.eventId)) {
      broadcastedRemoteEvents.push({
        eventId: remoteEvent.eventId,
        event: remoteEvent.event,
        isLocal: remoteEvent.feedback,
      });
    }
    if (initializingCatchupRemoteEvents) {
      initializingCatchupRemoteEvents.push(remoteEvent);
    } else {
      const { eventId, status, feedback, permissionUpdated } = remoteEvent;
      if (compareEventId(eventId, lastReceivedEventId) <= 0) {
        // discard event that can be sent by a slow broadcast server which is late in regard of snapshot server
        return;
      }
      lastReceivedEventId = eventId;

      if (permissionUpdated) {
        dirtyStore = true;
        onPermissionUpdated();
      }

      const isReceivedEventIdDesynchronized = feedback && (
        !localEvents[0] || (Boolean(localEvents[0].eventId) && localEvents[0].eventId !== eventId));
      const hasReceivedRejectionForNonFeedback = !feedback && !success;
      if (isReceivedEventIdDesynchronized || hasReceivedRejectionForNonFeedback) {
        dirtyStore = true;
        const details = {
          localEvents: localEvents.map((localEvent) => ({
            creationTime: localEvent.creationTime,
            submissionTime: localEvent.submissionTime,
            submissionStatus: localEvent.submissionStatus,
            eventId: localEvent.eventId,
          })),
          remoteEvent: {
            eventId: remoteEvent.eventId,
            checksum: remoteEvent.checksum,
            status: remoteEvent.status,
            feedback: remoteEvent.feedback,
            permissionUpdated: remoteEvent.permissionUpdated,
            origin: remoteEvent.origin,
          },
          isReceivedEventIdDesynchronized,
          hasReceivedRejectionForNonFeedback,
        };
        const error = new ObjectStoreDesynchronizedError('Abort handleIncomingEvent on desynchronization', details);
        onDesynchronized(error);
        throw error;
      }

      let eventSubmission;
      if (feedback) {
        eventSubmission = localEvents[0].submissionTime;
      }

      rebase({
        localEventCleanup: feedback ? { type: 'unique', localEventToRemoveIndex: 0 } : undefined,
        remoteEventToApply: success ? remoteEvent : undefined,
      });

      if (feedback) {
        if (eventSubmission) {
          reportMetric('request-round-trip', { duration: Date.now() - eventSubmission });
        }

        if (!success) {
          notifyError(status);
        }
      }
    }
  };

  const handleTimeseriesInit: ConcurrencyHandler['handleTimeseriesInit'] = async (request) => {
    broadcastedRemoteEventsCleanupLock += 1;
    const { status, updates: timeseriesSnapshotUpdates } = await fetchTimeseries(request);
    broadcastedRemoteEventsCleanupLock -= 1;
    if (status !== TimeseriesSnapshotStatus.abortedDueToMaxRetry) {
      applyTimeseriesSnapshot(timeseriesSnapshotUpdates);
    }
    return status;
  };

  const onTimeseriesConnected: ConcurrencyHandler['onTimeseriesConnected'] = (lastTimeseriesEventIdInserted) => {
    lastInsertedTimeseriesEventId = lastTimeseriesEventIdInserted;
  };

  const initTimeseriesRepository = async (onConnectedLastBroadcasterEventId: string, abortSignal: AbortSignal) => {
    while (!abortSignal.aborted) {
      if (onConnectedLastBroadcasterEventId && compareEventId(onConnectedLastBroadcasterEventId, lastInsertedTimeseriesEventId) <= 0) {
        resetTimeseriesRepository();
        break;
      } else {
        await sleep(Math.round(1_000 + 2_000 * Math.random()));
      }
    }
  };

  const {
    downloadBrowserSnapshot,
    broadcastBrowserDownloadedSnapshot,
    registerBrowserSnapshotDownloadedBroadcastHandler,
    close: closeBrowserSnapshotNetwork,
  } = registerBrowserSnapshotNetwork({
    getSnapshotDiscoveryInfo: () => (dirtyStore ? undefined : {
      userId: currentUserId,
      eventId: lastReceivedEventId,
    }),
    getSnapshotDownloadInfo: () => (dirtyStore ? undefined : {
      userId: currentUserId,
      eventId: lastReceivedEventId,
      dirtyStore,
      checksum: currentChecksum.value(),
      rollbackOperations: localEvents
        .flatMap(({ rollbackEvent }) => rollbackEvent)
        .filter((op): op is { id: SnapshotOperation[0], properties: SnapshotOperation[1] } => op.properties !== undefined)
        .reverse()
        .map(({ id, properties }) => [id, properties]),
    }),
  });
  const { downloadSnapshot } = createSnapshotDownloader({
    downloadBrowserSnapshot,
    broadcastBrowserDownloadedSnapshot,
    registerBrowserSnapshotDownloadedBroadcastHandler,
    downloadSnapshotFromNetwork,
    onConnectionProgress,
  });

  const onConnected: ConcurrencyHandler['onConnected'] = async (lastBroadcastedEventId, userId) => {
    const timeStart = performance.now();

    if (userId === currentUserId && lastBroadcastedEventId === lastReceivedEventId) {
      // We didn't miss any messages, no need to restore the store, no need to reset timeseries on connect
      return {
        metrics: {
          durationMs: 0,
          snapshotDownloadDurationMs: 0,
          downloadSnapshot: [],
          snapshotApplyDurationMs: 0,
          storeCatchupDurationMs: 0,
          omittedLocalEventIds: [],
          catchupRemoteEventIds: [],
        },
      };
    }
    currentUserId = userId;

    connectionNotificationHandler.onConnection();

    if (currentConnectionAbortController) {
      currentConnectionAbortController.abort();
    }
    const abortController = new AbortController();
    currentConnectionAbortController = abortController;
    const abortSignal = abortController.signal;

    forgetAsyncPromise(initTimeseriesRepository)(lastBroadcastedEventId, abortSignal);
    initializingCatchupRemoteEvents = [];

    const timeInitialized = performance.now();

    const { snapshot, metrics: downloadSnapshotMetrics, disconnected } = await downloadSnapshot(userId, lastBroadcastedEventId, abortSignal);
    if (!snapshot || disconnected || abortSignal.aborted) {
      if (!abortSignal.aborted) {
        abortController.abort();
      }

      if (disconnected) {
        dirtyStore = true;
        onUnauthorized();
      }

      return;
    }

    const timeSnapshotReceived = performance.now();

    onConnectionProgress(ConnectionPhase.ApplySnapshot);
    await doYield(true);

    // 1.1 - apply the snapshot on the store : flush and apply the store snapshot event
    flushStore(snapshot.operations);
    // 1.2 -rollback local events (download from browser case)
    snapshot.rollbackOperations.forEach(([id, properties]) => {
      applyRemoteEvent(id, properties, true);
    });

    currentChecksum.reset(snapshot.checksum);
    lastReceivedEventId = snapshot.eventId;
    const timeSnapshotApplied = performance.now();

    // 2 - reapply object local events, first destroy local before latest applied event
    const firstLocalEventToKeepIndex = localEvents.findIndex(({ eventId }) => !eventId || compareEventId(eventId, lastReceivedEventId) > 0);
    const localEventToDeleteCount = firstLocalEventToKeepIndex === -1 ? localEvents.length : firstLocalEventToKeepIndex;
    const omittedLocalEventIds: string[] = [];
    if (localEventToDeleteCount > 0) {
      const removedEvents = localEvents.splice(0, localEventToDeleteCount);
      omittedLocalEventIds.push(...removedEvents.map(({ eventId }) => eventId ?? 'pending'));
    }
    applyObjectLocalEvents();

    // 3 - process catchup events that may have been received during the snapshot download
    const catchupRemoteEvents = initializingCatchupRemoteEvents;
    initializingCatchupRemoteEvents = undefined; // next event will be directly processed in handleIncomingEvent
    catchupRemoteEvents.forEach((remoteEvent) => handleIncomingEvent(remoteEvent));

    dirtyStore = false;

    const timeRebaseCompleted = performance.now();

    // ensure useStore refresh
    debouncedFlushEvents(DebounceMode.Immediate);

    connectionNotificationHandler.onConnected();

    const timeCompleted = performance.now();

    return {
      metrics: {
        source: downloadSnapshotMetrics.find(({ status }) => status === 'success')?.source,
        durationMs: Math.ceil(timeCompleted - timeStart),
        snapshotDownloadDurationMs: Math.ceil(timeSnapshotReceived - timeInitialized),
        snapshotApplyDurationMs: Math.ceil(timeSnapshotApplied - timeSnapshotReceived),
        storeCatchupDurationMs: Math.ceil(timeRebaseCompleted - timeSnapshotApplied),
        downloadSnapshot: downloadSnapshotMetrics,
        omittedLocalEventIds,
        catchupRemoteEventIds: catchupRemoteEvents.map(({ eventId }) => eventId),
      },
    };
  };

  const handleTimeseriesInsertion: ConcurrencyHandler['handleTimeseriesInsertion'] = (lastInsertedEventId) => {
    lastInsertedTimeseriesEventId = lastInsertedEventId;
  };

  return {
    handleIncomingEvent,
    handleOutgoingEvent,
    handleTimeseriesInit,
    handleTimeseriesInsertion,
    onConnected,
    onTimeseriesConnected,
    close: () => closeBrowserSnapshotNetwork(),
    hasUnsavedChanges: () => localEvents.some(isLocalEventUnSaved),
  };
};

export default createConcurrencyHandler;
