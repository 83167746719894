import type { FunctionComponent, ReactNode } from 'react';
import { useRef } from 'react';
import type { Filters } from 'yooi-modules/modules/conceptModule';
import { joinObjects } from 'yooi-utils';
import Chip from '../../../../components/molecules/Chip';
import PageSelector from '../../../../components/templates/PageSelector';
import useStore from '../../../../store/useStore';
import { darken } from '../../../../theme/colorUtils';
import { buildMargins, Spacing, spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import { remToPx } from '../../../../utils/sizeUtils';
import useTheme from '../../../../utils/useTheme';
import { getChipOptionWithUnknown, getUnknownChip } from '../../modelTypeUtils';
import type { ColumnResolution, GroupCollapseHandler, IsHighlighted, OnHighlight } from './GraphChart';
import GraphChartColumnTopBar from './GraphChartColumnTopBar';
import GraphChartEmptyBlock from './GraphChartEmptyBlock';
import GraphChartInvalidBlock from './GraphChartInvalidBlock';
import GraphChartItemGroup from './GraphChartItemGroup';
import GraphChartItemList from './GraphChartItemList';

export const GRAPH_CHART_COLUMN_IN_REM = 32;

const useStyles = makeStyles({
  columnContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  blockAndTitleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: spacingRem.s,
  },
  blockContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: spacingRem.s,
  },
  topBarContainer: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacingRem.s,
  },
}, 'graphChartColumn');

interface GraphChartColumnProps {
  columnResolution: ColumnResolution | undefined,
  groupCollapseHandler: GroupCollapseHandler,
  onHighlight: OnHighlight,
  resetHighlight: (key?: string) => void,
  hasHighlightedItem: boolean,
  isHighlighted: IsHighlighted,
  onPage: (pageNumber: number) => void,
  onNext: () => void,
  onPrevious: () => void,
  getSearch: (blockId: string) => string | undefined,
  setSearch: (blockId: string, text: string | undefined) => void,
  getFilters: (blockId: string) => Record<string, Filters> | undefined,
  setFilters: (blockId: string, filters: Record<string, Filters> | undefined) => void,
  onResetFilters: (blockId: string) => void,
}

const GraphChartColumn: FunctionComponent<GraphChartColumnProps> = ({
  columnResolution,
  groupCollapseHandler,
  onHighlight,
  resetHighlight,
  hasHighlightedItem,
  isHighlighted,
  onPage,
  onNext,
  onPrevious,
  getSearch,
  setSearch,
  getFilters,
  setFilters,
  onResetFilters,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const store = useStore();
  const containerRef = useRef<HTMLDivElement>(null);

  if (!columnResolution) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      className={classes.columnContainer}
      aria-hidden="true"
      onClick={(e) => {
        if (e.target === containerRef.current) {
          resetHighlight();
        }
      }}
    >
      {columnResolution?.blocks
        .map((block, index) => {
          const option = block.type === 'valid' ? getChipOptionWithUnknown(store, block.conceptDefinitionId) : getUnknownChip(block.blockId);
          let innerBlock: ReactNode;
          if (block.type === 'valid' && block.items.length > 0) {
            const { groupByFieldMetadata } = block;
            if (groupByFieldMetadata) {
              innerBlock = (
                <div className={classes.blockContainer}>
                  {block.groups.map((group) => (
                    <GraphChartItemGroup
                      key={group.key}
                      blockLabel={block.blockLabel}
                      blockId={block.blockId}
                      group={group}
                      groupByFieldMetadata={groupByFieldMetadata}
                      isHighlighted={isHighlighted}
                      hasHighlightedItem={hasHighlightedItem}
                      groupCollapseHandler={groupCollapseHandler}
                      onHighlight={onHighlight}
                      removeHighlight={resetHighlight}
                      display={block.display}
                      width={remToPx(GRAPH_CHART_COLUMN_IN_REM)}
                    />
                  ))}
                </div>
              );
            } else {
              innerBlock = (
                <div className={classes.blockContainer}>
                  <GraphChartItemList
                    width={remToPx(GRAPH_CHART_COLUMN_IN_REM)}
                    blockLabel={block.blockLabel}
                    blockId={block.blockId}
                    isHighlighted={isHighlighted}
                    items={block.items}
                    groupCollapseHandler={groupCollapseHandler}
                    onHighlight={onHighlight}
                    removeHighlight={resetHighlight}
                    hasHighlightedItem={hasHighlightedItem}
                    display={block.display}
                    borderColor={darken(option.borderColor ?? theme.color.border.default, 10)}
                  />
                </div>
              );
            }
          } else if (block.type === 'valid') {
            innerBlock = (
              <GraphChartEmptyBlock
                blockLabel={block.blockLabel}
                borderColor={option.borderColor}
                onReset={(getSearch(block.blockId) !== undefined || getFilters(block.blockId) !== undefined) ? (() => {
                  onResetFilters(block.blockId);
                  setSearch(block.blockId, undefined);
                }) : undefined}
              />
            );
          } else {
            innerBlock = (<GraphChartInvalidBlock blockLabel={block.blockLabel} />);
          }

          return (
            <div
              key={block.blockId}
              className={classes.blockAndTitleContainer}
              style={index > 0 ? buildMargins({ top: Spacing.xxl }) : undefined}
            >
              <div className={classes.topBarContainer}>
                <Chip
                  icon={option.icon}
                  text={block.blockLabel}
                  color={option.color}
                  squareColor={option.squareColor}
                  tooltip={block.blockLabel}
                  borderStyle={option.borderStyle}
                  borderColor={option.borderColor}
                />
                {block.type === 'valid' ? (
                  <GraphChartColumnTopBar
                    search={getSearch(block.blockId)}
                    setSearch={(search) => setSearch(block.blockId, search)}
                    conceptDefinitionId={block.conceptDefinitionId}
                    filters={getFilters(block.blockId)}
                    setFilters={(filters) => setFilters(block.blockId, filters)}
                    onResetFilters={() => onResetFilters(block.blockId)}
                    showSearch={block.type !== 'valid' || block.items.length > 0}
                  />
                ) : null}
              </div>
              {innerBlock}
            </div>
          );
        })}
      <div className={classes.blockContainer} style={buildMargins({ top: Spacing.s })}>
        <PageSelector
          pageRangeDisplayed={2}
          pagination={columnResolution.pagination ? joinObjects(columnResolution.pagination, { onPage, onNext, onPrevious }) : undefined}
        />
      </div>
    </div>
  );
};

export default GraphChartColumn;
