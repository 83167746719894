import { equals } from 'ramda';
import type { FunctionComponent, ReactNode } from 'react';
import useDerivedState from '../../../utils/useDerivedState';

interface SimpleInputChildrenProps<T> {
  value: T,
  onChange: (newValue: T) => void,
  onSubmit: (value: T) => void,
  onCancel: () => void,
}

interface SimpleInputProps<T> {
  initialValue: T,
  onSubmit: (value: T) => unknown,
  children: FunctionComponent<SimpleInputChildrenProps<T>>,
}

const SimpleInput = <T>({ initialValue, onSubmit, children }: SimpleInputProps<T>): ReactNode => {
  const [value, setValue, resetValue] = useDerivedState(() => initialValue, [initialValue]);

  const onChangeInner = (newValue: T) => {
    setValue(newValue);
  };

  const onSubmitInner = (valueToSubmit: T) => {
    resetValue();
    if (!equals(initialValue, valueToSubmit)) {
      onSubmit?.(valueToSubmit);
    }
  };

  const onCancelInner = () => {
    resetValue();
  };

  const props = {
    value,
    onChange: onChangeInner,
    onSubmit: onSubmitInner,
    onCancel: onCancelInner,
  };

  return children(props);
};

export default SimpleInput;
