import composeReactRefs from '@seznam/compose-react-refs';
import classnames from 'classnames';
import type { FunctionComponent, KeyboardEvent, MouseEvent } from 'react';
import { useRef, useState } from 'react';
import { spacingRem } from '../../theme/spacingDefinition';
import makeStyles from '../../utils/makeStyles';
import useHideOnDisappearRef from '../../utils/useHideOnDisappearRef';
import { useTooltip } from '../../utils/useTooltip';
import type { IconColorVariant, IconName } from '../atoms/Icon';
import Icon from '../atoms/Icon';
import Typo from '../atoms/Typo';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: spacingRem.iconSize,
    minWidth: spacingRem.iconSize,
    maxWidth: spacingRem.blockRightColumnSpacing,
    padding: spacingRem.xs,
  },
  buttonContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: spacingRem.xs,
    cursor: 'pointer',
    '&:hover': {
      color: theme.color.text.info,
    },
  },
  cellSpacing: {
    paddingLeft: spacingRem.s,
  },
  iconContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    color: theme.color.text.secondary,
  },
  typoContainer: {
    minWidth: spacingRem.splusplus,
  },
}), 'fieldCollaborationButton');

interface FieldCollaborationButtonProps {
  restingIcon: IconName,
  onClick: (event: KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>) => void,
  restingTitle?: string,
  tooltip?: string,
  restingIconColorVariant?: IconColorVariant,
  restingTitleColor?: string,
  active?: boolean,
  displayOnHoverOnly?: boolean,
  onHoverIcon?: IconName,
  onHoverTitle?: string,
  inCell?: boolean,
}

const FieldCollaborationButton: FunctionComponent<FieldCollaborationButtonProps> = ({
  restingIcon,
  onClick,
  restingTitle,
  tooltip,
  restingIconColorVariant,
  restingTitleColor,
  active = false,
  displayOnHoverOnly = false,
  onHoverIcon,
  onHoverTitle,
  inCell = false,
}) => {
  const classes = useStyles();
  const { displayTooltip, hideTooltip } = useTooltip();
  const containerRef = useRef<HTMLDivElement>(null);
  const { monitorRef } = useHideOnDisappearRef<HTMLDivElement>(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  const onMouseOver = () => {
    setIsButtonHovered(true);
    if (tooltip && containerRef.current) {
      displayTooltip({
        element: containerRef.current,
        text: tooltip,
      });
    }
  };

  const onMouseOut = () => {
    setIsButtonHovered(false);
    hideTooltip();
  };

  return (
    <div
      ref={composeReactRefs<HTMLDivElement>(monitorRef, containerRef)}
      className={classes.container}
    >
      {(!displayOnHoverOnly)
        && (
          <div
            className={
              classnames({
                [classes.buttonContainer]: true,
                [classes.cellSpacing]: inCell,
              })
            }
            tabIndex={0}
            onClick={(event) => {
              onClick(event);
              hideTooltip();
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                onClick(event);
                hideTooltip();
              }
            }}
            onMouseOver={onMouseOver}
            onFocus={onMouseOver}
            onMouseOut={onMouseOut}
            onBlur={onMouseOut}
            role="option"
            aria-selected={active}
          >
            <div className={classes.iconContainer}>
              <Icon name={onHoverIcon && isButtonHovered ? onHoverIcon : restingIcon} colorVariant={!isButtonHovered ? restingIconColorVariant : undefined} />
            </div>
            <div className={classes.typoContainer}>
              <Typo maxLine={1} color={!isButtonHovered ? restingTitleColor : undefined}>{isButtonHovered ? onHoverTitle : restingTitle}</Typo>
            </div>
          </div>
        )}
    </div>
  );
};
export default FieldCollaborationButton;
