import type { FunctionComponent } from 'react';
import type { FieldBlockDisplayStoreObject } from 'yooi-modules/modules/conceptLayoutModule';
import { BlockFieldLayoutOption } from 'yooi-modules/modules/conceptLayoutModule';
import {
  FieldBlockDisplay_DocumentationOverride,
  FieldBlockDisplay_FieldDisplayConfiguration,
  FieldBlockDisplay_FieldPath,
  FieldBlockDisplay_TitleOverride,
  FieldBlockDisplay_WorkflowField,
  FieldBlockDisplay_WorkflowFieldDisplayType,
} from 'yooi-modules/modules/conceptLayoutModule/ids';
import { OverridableDisplayType } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import type { FieldStoreObject, ParametersMapping, PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import {
  BLOCK_PARAMETER_CURRENT,
  createValuePathResolver,
  FILTER_PARAMETER_LOGGED_USER,
  getFieldDimensionOfModelType,
  isSingleFieldResolution,
  ParsedDimensionType,
  parseDimensionMapping,
} from 'yooi-modules/modules/conceptModule';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { joinObjects } from 'yooi-utils';
import BlockContent from '../../../components/templates/BlockContent';
import useAuth from '../../../store/useAuth';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import { getBlockParameterDefinitionCurrent } from '../../_global/conceptFilterIdUtils';
import BlockField from '../../_global/fields/_global/BlockField';
import type { BlockFieldDisplayStatus } from '../../_global/fields/_global/blockFieldUtils';
import { getFieldBlockDisplayDimensions, getFiledBlockDisplay } from '../../_global/fields/_global/blockFieldUtils';
import { getFieldHandler } from '../../_global/fields/FieldLibrary';
import { getFieldLabel } from '../../_global/fieldUtils';
import { getLoggedUserParameterDefinition } from '../../_global/filter/filterUtils';
import ActivityIndicator from '../../_global/multiplayer/ActivityIndicator';
import { createPathConfigurationHandler } from '../../_global/pathConfigurationHandler';
import FieldConfigurationOverride from './FieldConfigurationOverride';

interface FieldBlockRendererProps {
  fieldBlockDisplayId: string,
  conceptId: string,
  displayStatus: BlockFieldDisplayStatus,
  updateDisplayStatus: (displayStatus: BlockFieldDisplayStatus) => void,
  readOnly: boolean,
  inColumn: boolean,
}

const FieldBlockRenderer: FunctionComponent<FieldBlockRendererProps> = ({
  fieldBlockDisplayId,
  conceptId,
  displayStatus,
  updateDisplayStatus,
  readOnly,
  inColumn,
}) => {
  const store = useStore();
  const { loggedUserId } = useAuth();

  const fieldBlockDisplay = store.getObject<FieldBlockDisplayStoreObject>(fieldBlockDisplayId);
  const conceptDefinitionId = store.getObject(conceptId)[Instance_Of] as string;

  const path: PathStep[] = fieldBlockDisplay[FieldBlockDisplay_FieldPath] ?? [];

  const parameterDefinitions: SingleParameterDefinition[] = [getBlockParameterDefinitionCurrent(conceptDefinitionId), getLoggedUserParameterDefinition()];
  const pathConfigurationHandler = createPathConfigurationHandler(store, parameterDefinitions);
  const lastField = pathConfigurationHandler.getLastFieldInformation(path);
  if (!lastField || store.getObjectOrNull(lastField.fieldId) === null) {
    return null;
  }

  const fieldHandler = getFieldHandler(store, lastField.fieldId);
  const field = store.getObjectOrNull<FieldStoreObject>(lastField.fieldId);
  if (!fieldHandler || !field) {
    return null;
  }

  const layoutParametersMapping: ParametersMapping = {
    [BLOCK_PARAMETER_CURRENT]: { type: 'single', id: conceptId },
    [FILTER_PARAMETER_LOGGED_USER]: { type: 'single', id: loggedUserId },
  };
  const valuePathResolver = createValuePathResolver(store, layoutParametersMapping);
  const lastFieldResolved = valuePathResolver.resolvePathField(lastField.pathToField);
  if (lastFieldResolved !== undefined && !(lastFieldResolved instanceof Error) && !isSingleFieldResolution(lastFieldResolved)) {
    return null;
  }

  const icon = fieldHandler.configuration.getIcon();
  const title = fieldBlockDisplay[FieldBlockDisplay_TitleOverride]?.trim()
    ? fieldBlockDisplay[FieldBlockDisplay_TitleOverride] : getFieldLabel(store, field);

  const documentation = fieldBlockDisplay[FieldBlockDisplay_DocumentationOverride] ?? fieldHandler.configuration.getDocumentation();

  const blockFieldStoredLayoutOption = getFiledBlockDisplay(store, fieldBlockDisplayId);
  const blockFieldLayoutOption = inColumn && blockFieldStoredLayoutOption === BlockFieldLayoutOption.auto ? BlockFieldLayoutOption.column : blockFieldStoredLayoutOption;

  // If we weren't able to reach a field, we are dealing with a long path
  // If we don't have any mapping, we end up on a different instance (or global)
  if (!lastFieldResolved || lastFieldResolved instanceof Error || lastFieldResolved.dimensionsMapping === undefined) {
    return (
      <BlockField
        fieldId={lastField.fieldId}
        documentation={documentation}
        icon={typeof icon === 'object' ? icon.name : icon}
        title={title}
        displayStatus={displayStatus}
        updateDisplayStatus={updateDisplayStatus}
        inColumn={blockFieldLayoutOption === BlockFieldLayoutOption.column}
      >
        <BlockContent />
      </BlockField>
    );
  }

  const parsedDimensionMapping = parseDimensionMapping(lastFieldResolved.dimensionsMapping);

  const additionalBlockFieldProps = fieldHandler.getAdditionalBlockFieldProps?.() ?? {};

  const dimensionId = lastField.conceptDefinitionId ? getFieldDimensionOfModelType(store, lastField.fieldId, lastField.conceptDefinitionId) : undefined;
  const fieldDisplayOption = fieldHandler.blockDisplayOptionsHandler?.(
    fieldBlockDisplayId,
    dimensionId && lastField?.conceptDefinitionId ? [
      ...parameterDefinitions,
      { id: dimensionId, typeId: lastField.conceptDefinitionId, label: i18n`Current`, type: 'dimension' },
    ] : parameterDefinitions
  )?.getDisplayOptions() ?? fieldBlockDisplay[FieldBlockDisplay_FieldDisplayConfiguration] ?? {};
  const isConfigurationOverridden = fieldDisplayOption?.displayType === OverridableDisplayType.Configuration;

  const displayWorkflowField = fieldBlockDisplay.navigateOrNull(FieldBlockDisplay_WorkflowField);
  const workflowFieldDisplayType = fieldBlockDisplay[FieldBlockDisplay_WorkflowFieldDisplayType];

  if (!isConfigurationOverridden && fieldHandler.renderBlockField) {
    const blockFieldRendered = fieldHandler.renderBlockField(
      lastFieldResolved.dimensionsMapping,
      fieldDisplayOption,
      joinObjects(
        additionalBlockFieldProps,
        {
          icon: typeof icon === 'object' ? icon.name : icon,
          title,
          documentation,
          displayStatus,
          updateDisplayStatus,
          readOnly,
          layoutDisplayMode: blockFieldLayoutOption,
          workflowFieldId: displayWorkflowField?.id,
          workflowFieldDisplayType,
          conceptId: parsedDimensionMapping.type === ParsedDimensionType.MonoDimensional ? parsedDimensionMapping.objectId : undefined,
        }
      ),
      layoutParametersMapping,
      getFieldBlockDisplayDimensions(store, fieldBlockDisplay.id),
      fieldBlockDisplayId
    );

    if (blockFieldRendered) {
      return blockFieldRendered;
    }
  }

  let content;
  if (isConfigurationOverridden) {
    if (parsedDimensionMapping.type === ParsedDimensionType.MonoDimensional) {
      content = (
        <BlockContent>
          <FieldConfigurationOverride conceptId={parsedDimensionMapping.objectId} fieldId={lastField.fieldId} readOnly={readOnly} />
        </BlockContent>
      );
    }
  } else {
    const activityProperties = fieldHandler.getActivityProperties?.();
    content = (
      <BlockContent
        fullWidth={additionalBlockFieldProps.fullWidth}
        hideOverflowX={additionalBlockFieldProps.hideOverflowX}
        action={
          parsedDimensionMapping.type === ParsedDimensionType.MonoDimensional && activityProperties
            ? (<ActivityIndicator propertyIds={activityProperties} instanceIds={parsedDimensionMapping.objectId} />) : null
        }
      >
        {fieldHandler.renderField?.({
          dimensionsMapping: lastFieldResolved.dimensionsMapping,
          readOnly,
          fieldDisplayOptions: fieldDisplayOption as Record<string, unknown>,
          path,
          parametersMapping: layoutParametersMapping,
        })}
      </BlockContent>
    );
  }

  return (
    <BlockField
      {...additionalBlockFieldProps}
      fieldId={lastField.fieldId}
      icon={typeof icon === 'object' ? icon.name : icon}
      title={title}
      documentation={documentation}
      displayStatus={displayStatus}
      updateDisplayStatus={updateDisplayStatus}
      inColumn={blockFieldLayoutOption === BlockFieldLayoutOption.column}
      workflowFieldId={displayWorkflowField?.id}
      workflowFieldDisplayType={workflowFieldDisplayType}
      readOnly={readOnly}
      conceptId={parsedDimensionMapping.type === ParsedDimensionType.MonoDimensional ? parsedDimensionMapping.objectId : undefined}
    >
      {content}
    </BlockField>
  );
};

export default FieldBlockRenderer;
