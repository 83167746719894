import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule';
import { BlockFieldLayoutOption } from 'yooi-modules/modules/conceptLayoutModule';
import { FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { ExternalKeyFieldRaw, ExternalKeyFieldStoreObject, FilterValueRaw } from 'yooi-modules/modules/conceptModule';
import {
  checkExternalKeyFieldValidValue,
  externalKeyFieldHandler,
  FilterValueType,
  InvalidFieldError,
  ParsedDimensionType,
  parseDimensionMapping,
} from 'yooi-modules/modules/conceptModule';
import {
  ExternalKeyField,
  ExternalKeyField_IsImmutable,
  ExternalKeyField_IsRequired,
  ExternalKeyField_PreventKeyStealing,
  ExternalKeyField_RegexValidation,
  Field_ApiAlias,
  Field_Documentation,
  Field_IntegrationOnly,
  Field_IsCore,
  Field_IsDocumentationInline,
  Field_Title,
} from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import { compareString, comparing, joinObjects } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import TextInputString from '../../../../components/inputs/TextInputString';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import { TableSortDirection } from '../../../../components/molecules/Table';
import { Spacing } from '../../../../theme/spacingDefinition';
import i18n from '../../../../utils/i18n';
import { formatErrorForUser } from '../../errorUtils';
import StoreTextInputField from '../../input/StoreTextInputField';
import { getBlockFieldLayoutOption, getDefaultDisplayOptions, getLayoutDisplayOption } from '../_global/blockFieldUtils';
import { getApiAliasInitialState, getDocumentationFieldEditionSection, getIntegrationFieldEditionSection } from '../_global/editionHandlerUtils';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  createAndLinkFieldToConceptDefinitions,
  duplicateFieldDimensionWithNewField,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
} from '../fieldDimensionUtils';
import type { FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, FieldComparatorHandler, GetFieldDefinitionHandler, RenderFilter } from '../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldIntegrationOnlyDisabled } from '../FieldLibraryTypes';
import ExternalKeyRenderer from './ExternalKeyRenderer';

interface ExternalKeyFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [Field_Title]: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
  [Field_IsCore]: boolean | null | undefined,
  [ExternalKeyField_IsImmutable]: boolean | null | undefined,
  [ExternalKeyField_IsRequired]: boolean | null | undefined,
  [ExternalKeyField_RegexValidation]: string | null | undefined,
  [ExternalKeyField_PreventKeyStealing]: boolean | null | undefined,
  [Field_IntegrationOnly]: boolean | null | undefined,
  [FieldIntegrationOnlyDisabled]: boolean | undefined,
}

type ExternalKeyFieldDefinition = GetFieldDefinitionHandler<typeof externalKeyFieldHandler, ExternalKeyFieldConfigurationState, never, FieldBlockDisplayOptions>;

export const externalKeyFieldDefinition: ExternalKeyFieldDefinition = registerFieldDefinition(externalKeyFieldHandler, {
  configuration: {
    typeIcon: IconName.pin,
    getTypeLabel: () => i18n`External key`,
    asWidget: false,
    getEditionOptions: (objectStore) => ({ mode, editionHandler }) => {
      if (mode !== FieldEditionOptionMode.Field && mode !== FieldEditionOptionMode.FieldDeveloperMode) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      sections.push({
        key: 'data',
        type: 'section',
        title: i18n`Data`,
        options: [
          {
            key: ExternalKeyField_PreventKeyStealing,
            title: i18n`Prevent key stealing`,
            hasValue: () => editionHandler.getValue(ExternalKeyField_PreventKeyStealing) !== undefined,
            clearValue: () => {},
            type: EditionOptionTypes.checkbox,
            padded: true,
            props: {
              checked: editionHandler.getValueOrDefault(ExternalKeyField_PreventKeyStealing) ?? false,
              onChange: (newValue) => {
                editionHandler.updateValues({ [ExternalKeyField_PreventKeyStealing]: newValue });
              },
              disabled: editionHandler.getValueOrDefault(Field_IsCore),
            },
          },
          {
            key: ExternalKeyField_IsImmutable,
            title: i18n`Only editable when empty`,
            info: i18n`This field will be presented as read only when it has correctly been filled once.`,
            hasValue: () => editionHandler.getValue(ExternalKeyField_IsImmutable) !== undefined,
            clearValue: () => {},
            type: EditionOptionTypes.checkbox,
            padded: true,
            props: {
              checked: editionHandler.getValueOrDefault(ExternalKeyField_IsImmutable) ?? false,
              onChange: () => {},
              disabled: true,
            },
          },
          {
            key: ExternalKeyField_IsRequired,
            title: i18n`Warn user if field is empty`,
            info: i18n`This field is necessary for platform usage.`,
            hasValue: () => editionHandler.getValue(ExternalKeyField_IsRequired) !== undefined,
            clearValue: () => {},
            type: EditionOptionTypes.checkbox,
            padded: true,
            props: {
              checked: editionHandler.getValueOrDefault(ExternalKeyField_IsRequired) ?? false,
              onChange: () => {},
              disabled: true,
            },
          },
          {
            key: ExternalKeyField_RegexValidation,
            title: i18n`Accepted format`,
            info: i18n`Use a regex to define accepted format for this External Key`,
            hasValue: () => editionHandler.getValue(ExternalKeyField_RegexValidation) !== undefined,
            clearValue: () => {},
            type: EditionOptionTypes.text,
            props: {
              maxLine: 1,
              onChange: (value) => editionHandler.updateValues({ [ExternalKeyField_RegexValidation]: value }),
              value: editionHandler.getValue(ExternalKeyField_RegexValidation),
              placeholder: i18n`Add format regex`,
            },
          },
        ],
      });

      sections.push(getDocumentationFieldEditionSection(editionHandler));
      sections.push(getIntegrationFieldEditionSection(objectStore, editionHandler, mode));

      return sections;
    },
    isCreationEnabled: () => () => true,
    onCreate: (objectStore) => (editionHandler) => {
      const fieldId = objectStore.createObject<ExternalKeyFieldRaw>({
        [Instance_Of]: ExternalKeyField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [Field_ApiAlias]: editionHandler.getValue(Field_ApiAlias),
        [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
        [ExternalKeyField_IsImmutable]: editionHandler.getValue(ExternalKeyField_IsImmutable),
        [ExternalKeyField_RegexValidation]: editionHandler.getValue(ExternalKeyField_RegexValidation),
        [ExternalKeyField_PreventKeyStealing]: editionHandler.getValue(ExternalKeyField_PreventKeyStealing),
      });
      linkFieldToFieldDimensions(objectStore, fieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});
      return fieldId;
    },
    inlineCreate: (objectStore) => (conceptDefinitionId, extraFieldOptions) => ({
      type: 'inline',
      onCreate: (title) => {
        const newFieldId = objectStore.createObject<ExternalKeyFieldRaw>(joinObjects(
          extraFieldOptions,
          { [Instance_Of]: ExternalKeyField, [Field_Title]: title }
        ));
        createAndLinkFieldToConceptDefinitions(objectStore, newFieldId, [conceptDefinitionId]);
        return newFieldId;
      },
    }),
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<ExternalKeyFieldStoreObject>(fieldId);
        return joinObjects(
          getApiAliasInitialState(objectStore, fieldId),
          {
            [Field_IsCore]: field[Field_IsCore],
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            [Field_IntegrationOnly]: field[Field_IntegrationOnly],
            [FieldIntegrationOnlyDisabled]: field[Field_IsCore],
            [ExternalKeyField_IsImmutable]: field[ExternalKeyField_IsImmutable],
            [ExternalKeyField_IsRequired]: field[ExternalKeyField_IsRequired],
            [ExternalKeyField_RegexValidation]: field[ExternalKeyField_RegexValidation],
            [ExternalKeyField_PreventKeyStealing]: field[ExternalKeyField_PreventKeyStealing],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
          }
        );
      },
      submitFieldUpdate: (stateToSubmit) => {
        objectStore.updateObject<ExternalKeyFieldRaw>(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: stateToSubmit[Field_IntegrationOnly],
          [ExternalKeyField_IsImmutable]: stateToSubmit[ExternalKeyField_IsImmutable],
          [ExternalKeyField_IsRequired]: stateToSubmit[ExternalKeyField_IsRequired],
          [ExternalKeyField_RegexValidation]: stateToSubmit[ExternalKeyField_RegexValidation],
          [ExternalKeyField_PreventKeyStealing]: stateToSubmit[ExternalKeyField_PreventKeyStealing],
        });
      },
      duplicateFieldDefinition: () => {
        const field = objectStore.getObject(fieldId);
        const fieldDimensionMapping = generateDuplicatedFieldDimensionId(objectStore, fieldId);
        const newFieldId = objectStore.createObject({
          [Instance_Of]: field[Instance_Of],
          [Field_Title]: `${field[Field_Title]} (copy)`,
          [Field_Documentation]: field[Field_Documentation],
          [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: field[Field_IntegrationOnly],
          [ExternalKeyField_IsImmutable]: field[ExternalKeyField_IsImmutable],
          [ExternalKeyField_RegexValidation]: field[ExternalKeyField_RegexValidation],
          [ExternalKeyField_PreventKeyStealing]: field[ExternalKeyField_PreventKeyStealing],
        });
        duplicateFieldDimensionWithNewField(objectStore, newFieldId, fieldDimensionMapping);
        return newFieldId;
      },
    }),
  },
  renderExportConfiguration: () => () => (
    <SpacedContainer margin={{ x: Spacing.splus }}>
      <Tooltip title={i18n`Text`}>
        <Typo maxLine={1}>{i18n`Text`}</Typo>
      </Tooltip>
    </SpacedContainer>
  ),
  getActivityProperties: (_, fieldId) => () => [fieldId],
  renderField: (_, fieldId) => ({ dimensionsMapping, readOnly, focusOnMount }) => {
    const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
    if (parsedDimensionMapping.type !== ParsedDimensionType.MonoDimensional) {
      return null;
    }

    return (
      <ExternalKeyRenderer
        fieldId={fieldId}
        objectId={parsedDimensionMapping.objectId}
        placeholder={i18n`Add text`}
        readOnly={readOnly}
        focusOnMount={focusOnMount}
      />
    );
  },
  valueValidation: (store, fieldId) => (value) => checkExternalKeyFieldValidValue(store, fieldId, value as string, true),
  renderSuggestedField: (store, fieldId) => (value, setValue) => {
    const { error } = checkExternalKeyFieldValidValue(store, fieldId, value as string, true);
    let sanityError;
    if (error) {
      sanityError = formatErrorForUser(store, new InvalidFieldError(fieldId, error));
    }
    return (
      <TextInputString
        value={value as string}
        placeholder={i18n`Add text`}
        error={sanityError}
        onChange={(v) => setValue(v ?? undefined)}
      />
    );
  },
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({
    propertyId: fieldId,
    sortable: true,
    scrollOnMount: true,
    focusable: true,
  }),
  getComparatorHandler: (_, __, { getValueResolution }) => (direction) => ({
    comparator: comparing(compareString, direction === TableSortDirection.desc),
    extractValue: (dimensionsMapping) => getValueResolution(dimensionsMapping).value,
  }) satisfies FieldComparatorHandler<string | undefined>,
  filterConditions: () => {
    const renderTextFilter: RenderFilter<FilterValueRaw<string>> = (value, setValue, _, readOnly) => (
      <StoreTextInputField initialValue={value.raw} onSubmit={(v) => setValue({ type: FilterValueType.raw, raw: v ?? '' })} readOnly={readOnly} placeholder={i18n`Value`} />
    );

    return {
      CONTAINS: {
        name: i18n`Contains`,
        renderFilter: renderTextFilter,
      },
      DOES_NOT_CONTAIN: {
        name: i18n`Does not contain`,
        renderFilter: renderTextFilter,
      },
      EQUALS: {
        name: i18n`Is`,
        renderFilter: renderTextFilter,
      },
      NOT_EQUALS: {
        name: i18n`Is not`,
        renderFilter: renderTextFilter,
      },
      STARTS_WITH: {
        name: i18n`Starts with`,
        renderFilter: renderTextFilter,
      },
      ENDS_WITH: {
        name: i18n`Ends with`,
        renderFilter: renderTextFilter,
      },
      IS_EMPTY: {
        name: i18n`Is empty`,
      },
      IS_NOT_EMPTY: {
        name: i18n`Is not empty`,
      },
    };
  },
  blockDisplayOptionsHandler: (objectStore) => (fieldBlockDisplayId) => ({
    getDisplayOptions: () => getDefaultDisplayOptions(objectStore, fieldBlockDisplayId),
    renderSummary: ({ layoutDisplayType }) => ([getBlockFieldLayoutOption()[layoutDisplayType ?? BlockFieldLayoutOption.auto].label]),
    getBlockEditionOptionSections: (state, setState) => [getLayoutDisplayOption(state, setState)],
    onSubmit: (state) => {
      objectStore.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
});
