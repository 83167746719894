import type { FunctionComponent } from 'react';
import { useRef } from 'react';
import { v4 as uuid } from 'uuid';
import type { AutomationActionGenerateDataStoreObject, AutomationRuleStoreObject } from 'yooi-modules/modules/automationModule';
import { getForEachOutputParameterDefinitions } from 'yooi-modules/modules/automationModule';
import { AutomationActionGenerateData, AutomationActionGenerateData_Operations, AutomationRule_Actions } from 'yooi-modules/modules/automationModule/ids';
import { isInstanceOf } from 'yooi-modules/modules/typeModule';
import { joinObjects } from 'yooi-utils';
import Button, { ButtonVariant } from '../../../components/atoms/Button';
import { IconName } from '../../../components/atoms/Icon';
import SpacingLine from '../../../components/molecules/SpacingLine';
import BlockContent from '../../../components/templates/BlockContent';
import useStore from '../../../store/useStore';
import i18n from '../../../utils/i18n';
import InstanceOperationCard from './InstanceOperationCard';

interface GenerateDataActionProps {
  ruleId: string,
}

const GenerateDataAction: FunctionComponent<GenerateDataActionProps> = ({ ruleId }) => {
  const store = useStore();

  const automationRule = store.getObject<AutomationRuleStoreObject>(ruleId);
  const action = automationRule.navigateBack<AutomationActionGenerateDataStoreObject>(AutomationRule_Actions)[0];

  const newInstanceOperationIdRef = useRef<string | undefined>();

  if (!isInstanceOf(action, AutomationActionGenerateData)) {
    return null;
  }

  const parameterDefinitions = getForEachOutputParameterDefinitions(store, ruleId);
  const operations = action[AutomationActionGenerateData_Operations] ?? [];

  return (
    <>
      {operations.map((operation, index, self) => {
        const focusOnMount = newInstanceOperationIdRef.current === operation.id;
        if (focusOnMount) {
          newInstanceOperationIdRef.current = undefined;
        }
        return (
          <InstanceOperationCard
            key={operation.id}
            instanceOperation={operation}
            parameterDefinitions={parameterDefinitions}
            onChange={(newOperation) => {
              const newOperations = [...action[AutomationActionGenerateData_Operations] ?? []];
              newOperations.splice(index, 1, joinObjects({ id: operation.id }, newOperation));
              store.updateObject(action.id, { [AutomationActionGenerateData_Operations]: newOperations });
            }}
            onDelete={() => {
              const newOperations = [...action[AutomationActionGenerateData_Operations] ?? []];
              newOperations.splice(index, 1);
              store.updateObject(action.id, { [AutomationActionGenerateData_Operations]: newOperations });
            }}
            onDuplicate={() => {
              const newOperations = [...action[AutomationActionGenerateData_Operations] ?? []];
              newOperations.splice(index, 0, joinObjects(operation, { id: uuid() }));
              store.updateObject(action.id, { [AutomationActionGenerateData_Operations]: newOperations });
            }}
            onMoveUp={index !== 0 ? () => {
              const newOperations = [...action[AutomationActionGenerateData_Operations] ?? []];
              newOperations.splice(index - 1, 0, newOperations.splice(index, 1)[0]);
              store.updateObject(action.id, { [AutomationActionGenerateData_Operations]: newOperations });
            } : undefined}
            onMoveDown={index + 1 !== self.length ? () => {
              const newOperations = [...action[AutomationActionGenerateData_Operations] ?? []];
              newOperations.splice(index + 1, 0, newOperations.splice(index, 1)[0]);
              store.updateObject(action.id, { [AutomationActionGenerateData_Operations]: newOperations });
            } : undefined}
            focusOnMount={focusOnMount}
          />
        );
      })}
      <BlockContent padded>
        <SpacingLine>
          <Button
            title={i18n`Create an instance`}
            iconName={IconName.add}
            onClick={() => {
              const oldOperations = action[AutomationActionGenerateData_Operations] ?? [];
              newInstanceOperationIdRef.current = uuid();
              store.updateObject(action.id, {
                [AutomationActionGenerateData_Operations]: [
                  ...oldOperations,
                  { id: newInstanceOperationIdRef.current, path: [], type: 'create', fieldOperations: [{ id: uuid() }] },
                ],
              });
            }}
            variant={ButtonVariant.primary}
            maxLine={1}
          />
          <Button
            title={i18n`Update an instance`}
            iconName={IconName.add}
            onClick={() => {
              const oldOperations = action[AutomationActionGenerateData_Operations] ?? [];
              newInstanceOperationIdRef.current = uuid();
              store.updateObject(action.id, {
                [AutomationActionGenerateData_Operations]: [
                  ...oldOperations,
                  { id: newInstanceOperationIdRef.current, path: [], type: 'update', fieldOperations: [{ id: uuid() }] },
                ],
              });
            }}
            variant={ButtonVariant.primary}
            maxLine={1}
          />
        </SpacingLine>
      </BlockContent>
    </>
  );
};

export default GenerateDataAction;
