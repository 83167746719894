import type { ComponentProps, FunctionComponent } from 'react';
import type { DateRange as DateRangeType } from 'yooi-utils';
import DateRange from '../../../components/inputs/datePickers/DateRange';
import type { StoreInputExposedProps } from './StoreInput';
import StoreInput from './StoreInput';

interface StoreDateRangeInputProps
  extends StoreInputExposedProps<DateRangeType | undefined, DateRangeType | undefined>, Omit<ComponentProps<typeof DateRange>, 'value' | 'onChange' | 'onSubmit' | 'onCancel'> {
}

const StoreDateRangeInput: FunctionComponent<StoreDateRangeInputProps> = ({ initialValue, onSubmit, ...dateRangeProps }) => (
  <StoreInput initialValue={initialValue} onSubmit={onSubmit}>
    {(formInputProps) => (
      <DateRange
        {...dateRangeProps}
        {...formInputProps}
      />
    )}
  </StoreInput>
);

export default StoreDateRangeInput;
