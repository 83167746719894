import type { FunctionComponent, RefObject } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { notifyError } from '../../utils/notify';
import { getFileSizeLimit } from '../../utils/options';

const SIZE_LIMIT = getFileSizeLimit() * 1024 * 1024;

const useStyles = makeStyles({
  form: {
    display: 'none',
  },
}, 'uploadFileForm');

interface UploadFileFormProps {
  value: { type: 'url', url: string } | { type: 'buffer', data: ArrayBuffer, contentType: string } | undefined,
  onChange: (data: { type: 'buffer', data: ArrayBuffer, contentType: string, name: string }) => void,
  typeRestriction?: { accept: string, isAccepted: (type: string) => void, errorMessage: string },
  inputRef: RefObject<HTMLInputElement>,
}

const UploadFileForm: FunctionComponent<UploadFileFormProps> = ({ value, onChange, typeRestriction, inputRef }) => {
  const classes = useStyles();

  const [fileKey, setFileKey] = useState<string | undefined>(undefined);

  const formRef = useRef<HTMLFormElement>(null);

  useLayoutEffect(() => {
    if (value?.type !== 'buffer') {
      setFileKey(undefined);
      formRef.current?.reset();
    }
  }, [value]);

  return (
    <form ref={formRef} className={classes.form}>
      <input
        hidden
        ref={inputRef}
        type="file"
        key={fileKey}
        accept={typeRestriction?.accept}
        onClick={() => {
          document.body.onfocus = () => {
            document.body.onfocus = null;
          };
        }}
        onChange={(event) => {
          const reader = new FileReader();
          const file = event.target.files?.[0];
          if (!file) {
            return;
          } else if (!file.type) {
            notifyError(i18n`Missing file extension`);
            return;
          }
          const isAccepted = file && (typeRestriction?.isAccepted(file.type) ?? true);
          if (file.size <= SIZE_LIMIT && isAccepted) {
            setFileKey(file.name); // Chrome won't allow same file twice otherwise
            reader.readAsArrayBuffer(file);
            reader.onload = () => {
              onChange({ type: 'buffer', data: reader.result as ArrayBuffer, contentType: file.type, name: file.name });
            };
          } else if (!isAccepted) {
            notifyError(typeRestriction?.errorMessage ?? i18n`Invalid file format`);
          } else {
            notifyError(i18n`File size limit exceeded. Max size ${getFileSizeLimit()}MB`);
          }
          formRef.current?.reset();
        }}
      />
    </form>
  );
};

export default UploadFileForm;
