import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import type { MultipleParameterDefinition, PathStep, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { spacingRem } from '../../../theme/spacingDefinition';
import makeStyles from '../../../utils/makeStyles';
import { getFiltersPath } from '../filter/filterUtils';
import type { PathConfigurationHandler } from '../pathConfigurationHandler';
import PathMappingAndFiltersInput from './PathMappingAndFiltersInput';
import PathStepsInput from './PathStepsInput';

const useStyles = makeStyles({
  container: {
    display: 'grid',
    gridAutoFlow: 'column',
    columnGap: spacingRem.s,
    gridTemplateColumns: '1fr fit-content(100%)',
  },
}, 'pathInput');

interface PathInputProps {
  path: PathStep[],
  onChange?: (newPath: PathStep[]) => void,
  showEndOnly?: boolean,
  readOnly?: boolean,
  placeholder?: string,
  focusOnMount?: boolean,
  parameterDefinitions?: (SingleParameterDefinition | MultipleParameterDefinition)[],
  valuePathHandler?: PathConfigurationHandler,
  suggestedBasePaths?: { label: string, path: PathStep[] }[],
  withSearchInId?: boolean,
}

const PathInput: FunctionComponent<PathInputProps> = ({
  path,
  onChange,
  readOnly,
  showEndOnly,
  placeholder,
  focusOnMount,
  parameterDefinitions = [],
  valuePathHandler,
  suggestedBasePaths,
  withSearchInId = false,
}) => {
  const classes = useStyles();

  return (
    <span
      className={classnames({
        [classes.container]: getFiltersPath(path).length > 0,
      })}
    >
      <PathStepsInput
        parameterDefinitions={parameterDefinitions}
        onSubmit={onChange}
        initialPath={path}
        valuePathHandler={valuePathHandler}
        readOnly={readOnly}
        showEndOnly={showEndOnly}
        placeholder={placeholder}
        focusOnMount={focusOnMount}
        suggestedBasePaths={suggestedBasePaths}
        withSearchInId={withSearchInId}
      />
      <PathMappingAndFiltersInput
        path={path}
        parameterDefinitions={parameterDefinitions}
        onChange={onChange}
        readOnly={readOnly}
        suggestedBasePaths={suggestedBasePaths}
        withSearchInId={withSearchInId}
      />
    </span>
  );
};

export default PathInput;
