import classnames from 'classnames';
import { forwardRef } from 'react';
import type { ParametersMapping, SingleParameterDefinition } from 'yooi-modules/modules/conceptModule';
import { getPathReturnedConceptDefinitionId } from 'yooi-modules/modules/conceptModule';
import type { ViewDimension } from 'yooi-modules/modules/dashboardModule';
import { filterNullOrUndefined } from 'yooi-utils';
import useStore from '../../../../store/useStore';
import { getSpacing, Spacing, spacingRem } from '../../../../theme/spacingDefinition';
import makeStyles from '../../../../utils/makeStyles';
import { FavoriteFiltersBar } from '../../filter/FavoriteFiltersBar';
import type { ViewFilters } from '../../filter/useFilterSessionStorage';
import { getDimensionLabel } from '../../views/data/dataResolution';

const useStyles = makeStyles({
  bar: {
    flexGrow: 1,
  },
  padded: {
    paddingRight: spacingRem.s,
    paddingBottom: spacingRem.s,
  },
  widgetPadded: {
    paddingLeft: spacingRem.splus,
  },
  blockPadded: {
    paddingLeft: getSpacing(Spacing.s, 0.1 /* border width */),
  },
  borderless: {
    paddingBottom: spacingRem.s,
  },
}, 'viewsWidgetFavoriteFilterBar');

interface ViewsWidgetFavoriteFilterBarProps {
  viewFilters: ViewFilters,
  viewDimensions: ViewDimension[],
  isBorderless: boolean,
  inBlock: boolean,
  parameterDefinitions: SingleParameterDefinition[],
  parametersMapping: ParametersMapping,
}

const ViewsWidgetFavoriteFilterBar = forwardRef<HTMLDivElement, ViewsWidgetFavoriteFilterBarProps>(
  ({ viewFilters, viewDimensions, isBorderless, inBlock, parametersMapping, parameterDefinitions }, ref) => {
    const classes = useStyles();

    const store = useStore();

    const { filterKey, getViewFilters } = viewFilters;

    const dimensionsParameters: SingleParameterDefinition[] = viewDimensions
      .map((dim, index): SingleParameterDefinition | undefined => {
        const dimConceptDefinition = getPathReturnedConceptDefinitionId(store, dim.path);
        if (dimConceptDefinition !== undefined) {
          return {
            id: dim.id,
            type: 'dimension',
            typeId: dimConceptDefinition,
            label: getDimensionLabel(store, dim.label, index, dim.path),
          };
        } else {
          return undefined;
        }
      }).filter(filterNullOrUndefined);

    return (
      <div
        ref={ref}
        className={classnames({
          [classes.bar]: true,
          [classes.padded]: !isBorderless,
          [classes.blockPadded]: !isBorderless && inBlock,
          [classes.widgetPadded]: !isBorderless && !inBlock,
          [classes.borderless]: isBorderless,
        })}
      >
        <FavoriteFiltersBar
          parameterDefinitions={[...parameterDefinitions, ...dimensionsParameters]}
          dimensionParameters={dimensionsParameters}
          parametersMapping={parametersMapping}
          filterKey={filterKey}
          getViewFilters={getViewFilters}
        />
      </div>
    );
  }
);

export default ViewsWidgetFavoriteFilterBar;
