import { FieldBlockDisplay_FieldDisplayConfiguration } from 'yooi-modules/modules/conceptLayoutModule/ids';
import type { FieldBlockDisplayOptions } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import { BlockFieldLayoutOption } from 'yooi-modules/modules/conceptLayoutModule/moduleType';
import type { FilterValueRaw, PathStep, SingleParameterDefinition, TimelineFieldRaw, TimelineFieldStoreObject } from 'yooi-modules/modules/conceptModule';
import { FilterValueType, getPathLastFieldInformation, InstanceReferenceType, PathStepType, timelineFieldHandler } from 'yooi-modules/modules/conceptModule';
import {
  DateField,
  ExternalKeyField,
  Field_ApiAlias,
  Field_Documentation,
  Field_IntegrationOnly,
  Field_IsCore,
  Field_IsDocumentationInline,
  Field_Title,
  TimelineField,
  TimelineField_DefaultPeriod,
  TimelineField_Period,
  TimelineField_Rank,
  TimelineField_StartConstraint,
} from 'yooi-modules/modules/conceptModule/ids';
import { Instance_Of } from 'yooi-modules/modules/typeModule/ids';
import type { DateRange as DateRangeStoreValue } from 'yooi-utils';
import { compareDate, comparing, computeEffectiveRangeForPeriodAndDates, joinObjects, PeriodicityType } from 'yooi-utils';
import { IconName } from '../../../../components/atoms/Icon';
import Tooltip from '../../../../components/atoms/Tooltip';
import Typo from '../../../../components/atoms/Typo';
import DateRange from '../../../../components/inputs/datePickers/DateRange';
import SimpleInput from '../../../../components/inputs/strategy/SimpleInput';
import Chip from '../../../../components/molecules/Chip';
import SpacedContainer from '../../../../components/molecules/SpacedContainer';
import { TableSortDirection } from '../../../../components/molecules/Table';
import { Spacing } from '../../../../theme/spacingDefinition';
import { getPeriodicityOption, getPeriodicityOptions } from '../../../../utils/dateUtilsFront';
import i18n from '../../../../utils/i18n';
import { formatOrUndef } from '../../../../utils/stringUtils';
import InCompositeInput from '../../input/InCompositeInput';
import { getChipOptions, getConceptDefinitionNameOrEntity } from '../../modelTypeUtils';
import { createPathConfigurationHandler } from '../../pathConfigurationHandler';
import { getFieldTypeValidator } from '../../pathConfigurationHandlerUtils';
import { getBlockFieldLayoutOption, getDefaultDisplayOptions, getLayoutDisplayOption } from '../_global/blockFieldUtils';
import { getApiAliasInitialState, getDimensionsEditionOption, getDocumentationFieldEditionSection, getIntegrationFieldEditionSection } from '../_global/editionHandlerUtils';
import { getGenericOperationMetadata } from '../_global/updateOperationHandlerUtils';
import UpdateOperationInput from '../_global/UpdateOperationInput';
import UpdateOperationSelector from '../_global/UpdateOperationSelector';
import type { FieldEditionDimensions } from '../fieldDimensionUtils';
import {
  createAndLinkFieldToConceptDefinitions,
  duplicateFieldDimensionWithNewField,
  FIELD_DIMENSIONS_READONLY,
  FIELD_EDITION_DIMENSIONS,
  generateDuplicatedFieldDimensionId,
  getFieldDimensionsEditionHandlerValue,
  linkFieldToFieldDimensions,
  submitDimensionUpdate,
} from '../fieldDimensionUtils';
import type { FieldEditionSection, FieldEditionSectionGroup } from '../FieldEditionOptionType';
import { EditionOptionTypes } from '../FieldEditionOptionType';
import { registerFieldDefinition } from '../FieldLibrary';
import type { ColumnDefinition, FieldComparatorHandler, GetFieldDefinitionHandler, RenderFilter } from '../FieldLibraryTypes';
import { FieldEditionOptionMode, FieldIntegrationOnlyDisabled } from '../FieldLibraryTypes';
import TimelineFieldRenderer from './TimelineFieldRenderer';

interface TimelineFieldConfigurationState {
  [FIELD_EDITION_DIMENSIONS]: FieldEditionDimensions | undefined,
  [FIELD_DIMENSIONS_READONLY]: boolean | undefined,
  [Field_Title]: string | null | undefined,
  [Field_ApiAlias]: string | null | undefined,
  [Field_Documentation]: string | null | undefined,
  [Field_IsDocumentationInline]: boolean | null | undefined,
  [Field_IntegrationOnly]: boolean | null | undefined,
  [FieldIntegrationOnlyDisabled]: boolean | undefined,
  [TimelineField_DefaultPeriod]: PeriodicityType | null | undefined,
  [TimelineField_StartConstraint]: PathStep[] | null | undefined,
}

type TimelineFieldDefinition = GetFieldDefinitionHandler<typeof timelineFieldHandler, TimelineFieldConfigurationState, never, FieldBlockDisplayOptions>;

export const timelineFieldDefinition: TimelineFieldDefinition = registerFieldDefinition(timelineFieldHandler, {
  configuration: {
    typeIcon: IconName.today,
    getTypeLabel: () => i18n`Date Range`,
    asWidget: false,
    getEditionOptions: (objectStore) => ({ mode, editionHandler, readOnly }) => {
      if (![FieldEditionOptionMode.Field, FieldEditionOptionMode.FieldDeveloperMode].includes(mode)) {
        return [];
      }

      const sections: (FieldEditionSection | FieldEditionSectionGroup)[] = [];

      const fieldEditionDimensions: FieldEditionDimensions = editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {};
      const parameterDefinitions: SingleParameterDefinition[] = Object.entries(fieldEditionDimensions)
        .map(([id, { typeId }]) => ({ id, typeId, label: i18n`Dimension`, type: 'dimension' }));

      sections.push({
        key: 'data',
        type: 'section',
        title: i18n`Data`,
        options: [
          getDimensionsEditionOption(editionHandler, false, readOnly || Boolean(editionHandler.getValue(FIELD_DIMENSIONS_READONLY))),
          {
            key: TimelineField_DefaultPeriod,
            title: i18n`Default periodicity`,
            hasValue: () => editionHandler.getValue(TimelineField_DefaultPeriod) !== undefined,
            clearValue: () => editionHandler.updateValues({ [TimelineField_DefaultPeriod]: null }),
            type: EditionOptionTypes.select,
            props: {
              selectedOption: getPeriodicityOption(editionHandler.getValueOrDefault(TimelineField_DefaultPeriod) ?? PeriodicityType.day),
              computeOptions: () => getPeriodicityOptions(),
              onChange: (value) => editionHandler.updateValues({ [TimelineField_DefaultPeriod]: (value?.id as PeriodicityType | undefined) ?? null }),
            },
          },
          {
            key: TimelineField_StartConstraint,
            title: i18n`Start constraint`,
            info: i18n`The value from the selected Date field will be the default value of this Date range field.`,
            hasValue: () => editionHandler.getValue(TimelineField_StartConstraint) !== undefined,
            clearValue: () => editionHandler.updateValues({ [TimelineField_StartConstraint]: null }),
            type: EditionOptionTypes.path,
            props: {
              placeholder: i18n`Add start constraint`,
              parameterDefinitions,
              initialPath: editionHandler.getValueOrDefault(TimelineField_StartConstraint) ?? [],
              onSubmit: (value) => {
                editionHandler.updateValues({ [TimelineField_StartConstraint]: value });
              },
              valuePathHandler: createPathConfigurationHandler(
                objectStore,
                parameterDefinitions ?? [],
                [getFieldTypeValidator(objectStore, [DateField], i18n`Input should end with a date.`)]
              ),
              suggestedBasePaths: parameterDefinitions.length === 1 ? [
                {
                  label: i18n`Current (${formatOrUndef(getConceptDefinitionNameOrEntity(objectStore, parameterDefinitions[0].typeId))})`,
                  path: [
                    { type: PathStepType.dimension, conceptDefinitionId: parameterDefinitions[0].typeId },
                    { type: PathStepType.mapping, mapping: { id: parameterDefinitions[0].id, type: InstanceReferenceType.parameter } },
                  ],
                },
              ] : undefined,
            },
          },
        ],
      });

      sections.push(getDocumentationFieldEditionSection(editionHandler));
      sections.push(getIntegrationFieldEditionSection(objectStore, editionHandler, mode));

      return sections;
    },
    isCreationEnabled: () => () => true,
    onCreate: (objectStore) => (editionHandler) => {
      const timelineFieldRank = objectStore.createObject({ [Instance_Of]: TimelineField_Rank });
      const timelineFieldPeriod = objectStore.createObject({ [Instance_Of]: TimelineField_Period });
      const fieldId = objectStore.createObject<TimelineFieldRaw>({
        [Instance_Of]: TimelineField,
        [Field_Title]: editionHandler.getValue(Field_Title),
        [Field_Documentation]: editionHandler.getValue(Field_Documentation),
        [Field_IsDocumentationInline]: editionHandler.getValue(Field_IsDocumentationInline),
        [Field_ApiAlias]: editionHandler.getValue(Field_ApiAlias),
        [Field_IntegrationOnly]: editionHandler.getValue(Field_IntegrationOnly),
        [TimelineField_DefaultPeriod]: editionHandler.getValue(TimelineField_DefaultPeriod),
        [TimelineField_StartConstraint]: editionHandler.getValue(TimelineField_StartConstraint),
        [TimelineField_Period]: timelineFieldPeriod,
        [TimelineField_Rank]: timelineFieldRank,
      });
      linkFieldToFieldDimensions(objectStore, fieldId, editionHandler.getValue(FIELD_EDITION_DIMENSIONS) ?? {});
      return fieldId;
    },
    inlineCreate: (objectStore) => (conceptDefinitionId, extraFieldOptions) => ({
      type: 'inline',
      onCreate: (title) => {
        const timelineFieldRank = objectStore.createObject({ [Instance_Of]: TimelineField_Rank });
        const timelineFieldPeriod = objectStore.createObject({ [Instance_Of]: TimelineField_Period });
        const newFieldId = objectStore.createObject<TimelineFieldRaw>(joinObjects(
          extraFieldOptions,
          {
            [Instance_Of]: ExternalKeyField,
            [Field_Title]: title,
            [TimelineField_Period]: timelineFieldPeriod,
            [TimelineField_Rank]: timelineFieldRank,
          }
        ));
        createAndLinkFieldToConceptDefinitions(objectStore, newFieldId, [conceptDefinitionId]);
        return newFieldId;
      },
    }),
    ofField: (objectStore, fieldId) => ({
      getInitialState: (conceptDefinitionId) => {
        const field = objectStore.getObject<TimelineFieldStoreObject>(fieldId);
        return joinObjects(
          getApiAliasInitialState(objectStore, fieldId),
          {
            [Field_Documentation]: field[Field_Documentation],
            [Field_IsDocumentationInline]: field[Field_IsDocumentationInline],
            [Field_IntegrationOnly]: field[Field_IntegrationOnly],
            [FieldIntegrationOnlyDisabled]: field[Field_IsCore],
            [TimelineField_DefaultPeriod]: field[TimelineField_DefaultPeriod],
            [TimelineField_StartConstraint]: field[TimelineField_StartConstraint],
            [FIELD_EDITION_DIMENSIONS]: getFieldDimensionsEditionHandlerValue(objectStore, fieldId, conceptDefinitionId),
            [FIELD_DIMENSIONS_READONLY]: field[Field_IsCore],
          }
        );
      },
      submitFieldUpdate: (stateToSubmit, conceptDefinitionId) => {
        objectStore.updateObject<TimelineFieldRaw>(fieldId, {
          [Field_ApiAlias]: stateToSubmit[Field_ApiAlias],
          [Field_Documentation]: stateToSubmit[Field_Documentation],
          [Field_IsDocumentationInline]: stateToSubmit[Field_IsDocumentationInline],
          [Field_IntegrationOnly]: stateToSubmit[Field_IntegrationOnly],
          [TimelineField_DefaultPeriod]: stateToSubmit[TimelineField_DefaultPeriod],
          [TimelineField_StartConstraint]: stateToSubmit[TimelineField_StartConstraint],
        });
        submitDimensionUpdate(objectStore, fieldId, conceptDefinitionId, stateToSubmit[FIELD_EDITION_DIMENSIONS] ?? {});
      },
      duplicateFieldDefinition: () => {
        const fieldInstance = objectStore.getObject(fieldId);
        const timelineFieldRank = objectStore.createObject({
          [Instance_Of]: TimelineField_Rank,
        });
        const timelineFieldPeriod = objectStore.createObject({
          [Instance_Of]: TimelineField_Period,
        });
        const fieldDimensionMapping = generateDuplicatedFieldDimensionId(objectStore, fieldId);
        const newFieldId = objectStore.createObject({
          [Instance_Of]: fieldInstance[Instance_Of],
          [Field_Title]: `${fieldInstance[Field_Title]} (copy)`,
          [Field_Documentation]: fieldInstance[Field_Documentation],
          [Field_IsDocumentationInline]: fieldInstance[Field_IsDocumentationInline],
          [TimelineField_DefaultPeriod]: fieldInstance[TimelineField_DefaultPeriod],
          [TimelineField_StartConstraint]: fieldInstance[TimelineField_StartConstraint],
          [TimelineField_Period]: timelineFieldPeriod,
          [TimelineField_Rank]: timelineFieldRank,
        });
        duplicateFieldDimensionWithNewField(objectStore, newFieldId, fieldDimensionMapping);
        return newFieldId;
      },
    }),
  },
  renderField: (_, fieldId) => ({ dimensionsMapping, readOnly, onSubmit, value, focusOnMount }) => (
    <TimelineFieldRenderer
      fieldId={fieldId}
      dimensionsMapping={dimensionsMapping}
      readOnly={readOnly}
      focusOnMount={focusOnMount ?? false}
      onSubmit={onSubmit}
      value={value}
    />
  ),
  input: (objectStore, __, handler) => ({
    render: ({ value, onSubmit }) => {
      const timelineField = handler.resolveConfiguration();
      const startConstraintFieldId = getPathLastFieldInformation(timelineField.startConstraint ?? [])?.fieldId;
      const startConstraintFieldChipOption = startConstraintFieldId ? getChipOptions(objectStore, startConstraintFieldId) : undefined;
      return (
        <SimpleInput initialValue={value} onSubmit={onSubmit}>
          {(props) => (
            <DateRange
              value={props.value}
              onSubmit={(newValue) => props.onSubmit(newValue)}
              onChange={(newValue) => props.onChange(newValue)}
              onCancel={props.onCancel}
              placeholder={i18n`Add dates`}
              defaultPeriodicity={timelineField.defaultPeriod}
              allowEmptyConstraint
              withStartConstraint={Boolean(startConstraintFieldId)}
              startConstraintChip={startConstraintFieldChipOption ? (
                <Chip
                  tooltip={startConstraintFieldChipOption.tooltip}
                  color={startConstraintFieldChipOption.color}
                  text={startConstraintFieldChipOption.label}
                  icon={startConstraintFieldChipOption.icon}
                />
              ) : undefined}
            />
          )}
        </SimpleInput>
      );
    },
    getInitialState: () => undefined,
    persistStateOnConcept: (dimension, value) => handler.updateValue(dimension, value ?? null),
  }),
  getUpdateOperationInput: (store, fieldId, { updateOperationHandlers: { INITIALIZE, REPLACE, CLEAR } }) => (operation) => {
    const { title, getIcon } = getGenericOperationMetadata(store, operation, fieldId);
    return {
      title,
      getIcon,
      render: ({ onSubmit, parameterDefinitions }) => {
        const operationSelectorLine = (
          <UpdateOperationSelector<typeof timelineFieldHandler>
            selectedOperationAction={operation?.action}
            operationOptions={[
              { id: 'INITIALIZE', label: i18n`Initialize`, onSelect: () => onSubmit({ action: 'INITIALIZE', payload: INITIALIZE.sanitizeOperation(operation) }) },
              { id: 'REPLACE', label: i18n`Replace`, onSelect: () => onSubmit({ action: 'REPLACE', payload: REPLACE.sanitizeOperation(operation) }) },
              { id: 'CLEAR', label: i18n`Clear`, onSelect: () => onSubmit({ action: 'CLEAR', payload: CLEAR.sanitizeOperation(operation) }) },
            ]}
          />
        );

        let operationInputsLines = null;
        const { input } = timelineFieldDefinition(store).getHandler(fieldId);
        if (input && operation && operation.action !== 'CLEAR' && operation.payload) {
          operationInputsLines = (
            <UpdateOperationInput<typeof timelineFieldHandler>
              valueInput={input}
              initialValue={operation.payload}
              onTypeChange={(newType) => {
                onSubmit({ action: operation.action, payload: newType === 'value' ? { type: 'value', value: undefined } : { type: 'path', path: [] } });
              }}
              onValueSubmit={(newValue) => onSubmit({ action: operation.action, payload: newValue })}
              parameterDefinitions={parameterDefinitions}
              valuePathHandler={createPathConfigurationHandler(store, parameterDefinitions, [getFieldTypeValidator(store, [TimelineField], i18n`Input should end with a date range.`)])}
            />
          );
        }

        return (
          <>
            {operationSelectorLine}
            {operationInputsLines}
          </>
        );
      },
    };
  },
  renderExportConfiguration: () => () => (
    <SpacedContainer margin={{ x: Spacing.splus }}>
      <Tooltip title={i18n`Dates - 2 columns`}>
        <Typo maxLine={1}>{i18n`Dates - 2 columns`}</Typo>
      </Tooltip>
    </SpacedContainer>
  ),
  getActivityProperties: ({ getObject }, fieldId) => () => [fieldId, getObject(fieldId)[TimelineField_Period] as string],
  getColumnDefinition: (_, fieldId) => (): ColumnDefinition => ({
    propertyId: fieldId,
    sortable: true,
    focusable: true,
  }),
  getComparatorHandler: (_, __, { getValueResolution }) => (direction) => ({
    comparator: comparing(compareDate, direction === TableSortDirection.desc),
    extractValue: (dimensionsMapping) => {
      const { value } = getValueResolution(dimensionsMapping);
      const { from, to, error } = computeEffectiveRangeForPeriodAndDates(value?.period ?? PeriodicityType.day, value?.from, value?.to);
      if (error) {
        return undefined;
      } else if (direction === TableSortDirection.desc) {
        return from ? to : undefined;
      } else {
        return to ? from : undefined;
      }
    },
  } satisfies FieldComparatorHandler<Date | undefined>),
  filterConditions: () => {
    const renderDateRangeFilter: RenderFilter<FilterValueRaw<DateRangeStoreValue>> = (value, setValue, _, readOnly) => (
      <InCompositeInput
        initialValue={value.raw}
        setInputValue={(v) => setValue({ type: FilterValueType.raw, raw: v ?? {} })}
      >
        {(props) => (
          <DateRange
            readOnly={readOnly}
            placeholder={i18n`Add dates`}
            withLastAndNext
            {...props}
          />
        )}
      </InCompositeInput>
    );

    return {
      EQUALS: {
        name: i18n`Is`,
        renderFilter: renderDateRangeFilter,
      },
      NOT_EQUALS: {
        name: i18n`Is not`,
        renderFilter: renderDateRangeFilter,
      },
      INTERSECT: {
        name: i18n`Is within`,
        renderFilter: renderDateRangeFilter,
      },
      IS_EMPTY: {
        name: i18n`Is empty`,
      },
      IS_NOT_EMPTY: {
        name: i18n`Is not empty`,
      },
    };
  },
  blockDisplayOptionsHandler: (objectStore) => (fieldBlockDisplayId) => ({
    getDisplayOptions: () => getDefaultDisplayOptions(objectStore, fieldBlockDisplayId),
    renderSummary: ({ layoutDisplayType }) => ([getBlockFieldLayoutOption()[layoutDisplayType ?? BlockFieldLayoutOption.auto].label]),
    getBlockEditionOptionSections: (state, setState) => [getLayoutDisplayOption(state, setState)],
    onSubmit: (state) => {
      objectStore.updateObject(fieldBlockDisplayId, { [FieldBlockDisplay_FieldDisplayConfiguration]: state });
    },
  }),
});
