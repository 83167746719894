import { equals } from 'ramda';
import type { ObjectStoreWithTimeseries } from 'yooi-store';
import { textType } from 'yooi-utils';
import { CommonAsType } from '../../common/fields/commonPropertyType';
import type { GetDslFieldHandler, UpdateOperationHandlers } from '../../common/fields/FieldModuleDslType';
import { ResolutionTypeError } from '../../common/typeErrorUtils';
import { preventComputedFieldUpdate, validateFieldIdAsProperty, validateIntegrationOnlyPropertyUpdate } from '../common/commonFieldUtils';
import { registerField } from '../module';
import type { PathStep } from '../moduleType';
import type { DimensionsMapping, MultipleParameterValue, ParametersMapping, ResolutionStack, SingleParameterValue } from '../utils';
import {
  buildDimensionalId,
  createValuePathResolver,
  isSingleValueResolution,
  isValueResolutionOfType,
  ParsedDimensionType,
  parseDimensionMapping,
  resolveFieldValue,
} from '../utils';
import type { ColorField } from './types';

const getValueResolution = (objectStore: ObjectStoreWithTimeseries, fieldId: string, dimensionsMapping: DimensionsMapping, resolutionStack?: ResolutionStack) => {
  const valueResolution = resolveFieldValue(objectStore, fieldId, dimensionsMapping, resolutionStack);
  if (isValueResolutionOfType(valueResolution, (value): value is string | undefined => value === undefined || typeof value === 'string')) {
    return valueResolution;
  } else {
    return {
      value: undefined,
      isComputed: valueResolution.isComputed,
      error: valueResolution.error ?? new ResolutionTypeError(['string', 'undefined'], typeof valueResolution.value),
      getDisplayValue: () => undefined,
      isTimeseries: false,
    };
  }
};

interface ColorUpdateOperationTypes {
  INITIALIZE: { type: 'value', value: string | undefined } | { type: 'path', path: PathStep[] },
  REPLACE: { type: 'value', value: string | undefined } | { type: 'path', path: PathStep[] },
  CLEAR: undefined,
}

type ColorFieldHandler = GetDslFieldHandler<
  ColorField,
  string | undefined,
  string | null,
  string | undefined,
  string | undefined,
  string | undefined,
  undefined,
  ColorUpdateOperationTypes,
  undefined,
  undefined
>;

export const colorFieldHandler: ColorFieldHandler = registerField({
  model: {
    label: 'ColorField',
    title: 'Color',
    withApiAlias: true,
    properties: [
      {
        label: 'DefaultColor',
        as: CommonAsType.string,
        initialStateValidationHandler: () => ({ validated: true }), // Ignore any updates on this field
      },
    ],
    asPropertyBusinessRules: [
      validateIntegrationOnlyPropertyUpdate('colorField'),
      validateFieldIdAsProperty('colorField'),
      preventComputedFieldUpdate('colorField'),
    ],
  },
  handler: (objectStore, fieldId, { resolveConfiguration }) => {
    const getValueAsText = (dimensionsMapping: DimensionsMapping) => getValueResolution(objectStore, fieldId, dimensionsMapping).value;

    const extractOperationValueColor = (
      parametersMapping: ParametersMapping<SingleParameterValue | MultipleParameterValue>,
      operationValue: { type: 'value', value: string | undefined } | { type: 'path', path: PathStep[] }
    ): string | undefined => {
      if (operationValue.type === 'path') {
        const { path } = operationValue;
        const resolution = createValuePathResolver(objectStore, parametersMapping).resolvePathValue(path);
        if (isSingleValueResolution(resolution)) {
          const resolvedValue = resolution.value;
          if (typeof resolvedValue === 'string') {
            return resolvedValue;
          }
        }
        return undefined;
      } else {
        return operationValue.value;
      }
    };

    return {
      describe: () => ({ hasData: true, returnType: textType, timeseriesMode: 'none' }),
      restApi: {
        returnTypeSchema: { type: 'string', nullable: true },
        formatValue: (value) => value ?? undefined,
      },
      getStoreValue: (dimensionsMapping) => {
        const parsedMapping = parseDimensionMapping(dimensionsMapping);
        if (parsedMapping.type === ParsedDimensionType.MonoDimensional) {
          return objectStore.getObject(parsedMapping.objectId)[fieldId] as string | undefined;
        } else {
          return objectStore.getObject(buildDimensionalId(dimensionsMapping))[fieldId] as string | undefined;
        }
      },
      getValueWithoutFormula: (dimensionsMapping) => {
        const configuration = resolveConfiguration();
        const parsedMapping = parseDimensionMapping(dimensionsMapping);
        if (parsedMapping.type === ParsedDimensionType.MonoDimensional) {
          return objectStore.getObject(parsedMapping.objectId)[fieldId] as string | undefined ?? configuration.defaultColor;
        } else {
          return objectStore.getObject(buildDimensionalId(dimensionsMapping))[fieldId] as string | undefined ?? configuration.defaultColor;
        }
      },
      getValueResolution: (dimensionsMapping, resolutionStack) => getValueResolution(objectStore, fieldId, dimensionsMapping, resolutionStack),
      resolvePathStepConfiguration: () => ({
        hasData: true,
        timeseriesMode: 'none',
        getValueResolutionType: () => textType,
        resolveValue: (dimensionsMappings, _, resolutionStack) => {
          const { error, value } = getValueResolution(objectStore, fieldId, dimensionsMappings, resolutionStack);
          if (error) {
            throw error;
          } else {
            return value;
          }
        },
      }),
      updateValue: (dimensionsMapping, value) => {
        const parsedDimensionMapping = parseDimensionMapping(dimensionsMapping);
        if (parsedDimensionMapping.type === ParsedDimensionType.MonoDimensional) {
          objectStore.updateObject(parsedDimensionMapping.objectId, { [fieldId]: value });
        } else {
          objectStore.updateObject(buildDimensionalId(dimensionsMapping), { [fieldId]: value });
        }
      },
      isEmpty: (dimensionsMapping) => !getValueResolution(objectStore, fieldId, dimensionsMapping).value,
      isSaneValue: () => ({ isValid: true }),
      getValueAsText,
      getExportColumnHeaders: (configuration, fieldLabel) => ({
        columnsNumber: 1,
        getHeaders: () => [{ format: 'string', value: fieldLabel }],
        getColumnConfiguration: () => configuration,
      }),
      getExportValue: (dimensionsMapping) => ({
        format: 'string',
        value: getValueAsText(dimensionsMapping),
      }),
      getValueProxy: (dimensionsMapping) => new Proxy({}, {
        get(_, prop) {
          if (prop === 'toString' || prop === Symbol.toStringTag) {
            return () => getValueAsText(dimensionsMapping) ?? '';
          } else {
            return undefined;
          }
        },
      }),
      filterConditions: undefined,
      updateOperationHandlers: {
        INITIALIZE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            const fieldHandler = colorFieldHandler(objectStore, fieldId);
            if (fieldHandler.getStoreValue(dimensionsMapping) !== undefined) {
              return;
            }
            fieldHandler.updateValue(dimensionsMapping, extractOperationValueColor(parametersMapping, value) ?? null);
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: undefined },
        },
        REPLACE: {
          applyOperation: (dimensionsMapping, parametersMapping, value) => {
            colorFieldHandler(objectStore, fieldId)
              .updateValue(dimensionsMapping, extractOperationValueColor(parametersMapping, value) ?? null);
          },
          sanitizeOperation: (oldOperation) => oldOperation?.payload ?? { type: 'value', value: undefined },
        },
        CLEAR: {
          applyOperation: (dimensionsMapping) => {
            colorFieldHandler(objectStore, fieldId).updateValue(dimensionsMapping, null);
          },
          sanitizeOperation: () => undefined,
        },
      } satisfies UpdateOperationHandlers<ColorUpdateOperationTypes>,
    };
  },
  historyEventProducer: ({ getObjectOrNull }, fieldId) => ({
    value: {
      collectImpactedInstances: ({ id, properties }) => {
        if (
          properties?.[fieldId] !== undefined // A value is in the update for the current field
          || (properties === null && getObjectOrNull(id)?.[fieldId] !== undefined) // Object is deleted and store had a value for the field
        ) {
          return [id];
        } else {
          return [];
        }
      },
      getValue: (id) => ({ value: (getObjectOrNull(id)?.[fieldId] as number | undefined) ?? null, version: 1 }),
      areValuesEquals: (value1, value2) => equals(value1?.value, value2?.value),
    },
  }),
});
