import { newFunctionLibrary } from '../engine/functionLibraryBuilder';
import { anyType, isArrayFormulaType, numberType } from '../typeSystem';
import { FunctionInvalidArgumentTypeError, FunctionMissingArgumentError } from './errors';
import { checkArgumentCount, checkFlattenType, checks, flattenForEach } from './utils';

export const statisticalFunctions = newFunctionLibrary()
  .addFunction(
    'COUNT',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checkArgumentCount(name, argTypes, 1) ?? {
          type: numberType,
          jsFunction: (...args: unknown[]) => {
            let count = 0;
            flattenForEach(args, (e: unknown) => {
              if (e !== undefined && `${e}` !== '') {
                count += 1;
              }
            });
            return count;
          },
        }
      ),
    })
  )
  .addFunction(
    'MAX',
    (name) => ({
      isDeterminist: true,
      jsFunction: Math.max,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1),
          () => checkFlattenType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (...args: unknown[]) => {
            let max: number | undefined;
            flattenForEach(args, (e: number | undefined) => {
              if (e !== undefined && (max === undefined || e > max)) {
                max = e;
              }
            });
            return max;
          },
        }),
    })
  )
  .addFunction(
    'MIN',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1),
          () => checkFlattenType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (...args: unknown[]) => {
            let min: number | undefined;
            flattenForEach(args, (e: number | undefined) => {
              if (e !== undefined && (min === undefined || e < min)) {
                min = e;
              }
            });
            return min;
          },
        }),
    })
  )
  .addFunction(
    'AVERAGE',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1),
          () => checkFlattenType(name, numberType, argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (...args: unknown[]) => {
            let sum: number = 0;
            let count: number = 0;
            flattenForEach(args, (e: number | undefined) => {
              if (e !== undefined) {
                sum += e;
                count += 1;
              }
            });
            return sum / count;
          },
        }),
    })
  )
  .addFunction(
    'AVERAGEW',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => (argTypes.length === 0 || argTypes.length % 2 !== 0 ? new FunctionMissingArgumentError(name, argTypes.length) : undefined),
          () => {
            for (let i = 0; i < argTypes.length; i += 2) {
              const valueType = argTypes[i];
              const weightType = argTypes[i + 1];

              if (!numberType.isAssignableFrom(isArrayFormulaType(valueType) ? valueType.array.elementType : valueType)) {
                return new FunctionInvalidArgumentTypeError(name, numberType, argTypes[i], i);
              } else if (!numberType.isAssignableFrom(weightType)) {
                return new FunctionInvalidArgumentTypeError(name, numberType, argTypes[i + 1], i + 1);
              }
            }
            return undefined;
          }
        ) ?? {
          type: numberType,
          jsFunction: (...args: unknown[]) => {
            let sum: number = 0;
            let count: number = 0;

            for (let i = 0; i < args.length; i += 2) {
              const value = args[i] as number | number[] | undefined;
              const weight = args[i + 1] as number | undefined;
              // eslint-disable-next-line yooi/number-is-nan-call
              if (Number.isNaN(weight)) {
                return Number.NaN;
              } else if (weight !== undefined && weight > 0) {
                // eslint-disable-next-line @typescript-eslint/no-loop-func
                flattenForEach(value, (e: number | undefined) => {
                  if (e !== undefined) {
                    sum += (e * weight);
                    count += weight;
                  }
                });
              }
            }
            return count === 0 ? undefined : sum / count;
          },
        }),
    })
  )
  .addFunction(
    'COMPLETENESS',
    (name) => ({
      isDeterminist: true,
      resolve: (argTypes) => (
        checks(
          () => checkArgumentCount(name, argTypes, 1),
          () => checkFlattenType(name, anyType, argTypes)
        ) ?? {
          type: numberType,
          jsFunction: (...args: unknown[]) => args.filter((a) => !(a === undefined || a === null || a.toString() === '' || (Array.isArray(a) && a.length === 0))).length / args.length,
        }),
    })
  )
  .build();
