import { v5 as uuidV5 } from 'uuid';
import type { BusinessRuleHandler, InitModuleFunction, ObjectDebugLabelHandler } from 'yooi-store';
import { ValidationStatus } from 'yooi-store';
import { newError } from 'yooi-utils';

const YOOI_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';

const DEBUG_NS = uuidV5('debug', YOOI_NAMESPACE);
const DebugModuleId = DEBUG_NS;

// Deprecated ids
const DEBUG_LABEL = uuidV5('label', DEBUG_NS);

const modules = new Set(['debug']);
const moduleIds = new Set([DEBUG_NS]);
const debugLabelPerId: Record<string, string> = {};

const allowDebugDeletion: BusinessRuleHandler = (_, { properties }) => {
  if (!properties) {
    return { rule: 'debugLabel.deletion.allow', status: ValidationStatus.ACCEPTED };
  } else if (!properties[DEBUG_LABEL]) {
    return { rule: 'debugLabel.cleanup.allow', status: ValidationStatus.ACCEPTED };
  }
  return undefined;
};

const getDebugLabel: ObjectDebugLabelHandler = (objectId) => {
  if (Array.isArray(objectId)) {
    if (objectId.length === 1) {
      return debugLabelPerId[objectId[0]];
    } else {
      return undefined;
    }
  } else {
    return debugLabelPerId[objectId];
  }
};

export const createDebugInitializer = (module: string, moduleId?: string): {
  id: (historicalLabel: string, label?: string, specificId?: string) => string,
  moduleId: string,
} => {
  if (modules.has(module)) {
    throw newError('Module is already defined', { module });
  }

  const moduleNS = moduleId ?? uuidV5(module, YOOI_NAMESPACE);
  if (moduleIds.has(moduleNS)) {
    throw newError('ModuleId is already defined', { moduleNS });
  }

  modules.add(module);
  moduleIds.add(moduleNS);

  const ids = new Set<string>();
  const debugLabels: Record<string, string> = {};

  return {
    id: (historicalLabel, label, specificId) => {
      const debugLabel = label || historicalLabel;
      if (ids.has(historicalLabel)) {
        throw newError('id is already defined', { id: historicalLabel });
      }
      if (debugLabels[debugLabel]) {
        throw newError('debugLabel is already used', { debugLabel });
      }
      const newId = specificId ?? uuidV5(historicalLabel, moduleNS);
      if (Object.values(debugLabels).includes(newId)) {
        throw newError('id is already used', { id: newId });
      }
      ids.add(historicalLabel);
      debugLabels[debugLabel] = newId;

      debugLabelPerId[newId] = `${module}:${debugLabel}`;
      return newId;
    },
    moduleId: moduleNS,
  };
};

export const initModule: InitModuleFunction = () => ({
  id: DebugModuleId,
  registerBusinessRules: (_, { onProperty }) => {
    onProperty(DEBUG_LABEL).validate(allowDebugDeletion);
  },
  registerObjectDebugLabel: (_, { onObject }) => {
    onObject('*').register(getDebugLabel);
  },
});
