import type { FunctionComponent } from 'react';
import Typo from '../../../../components/atoms/Typo';
import i18n from '../../../../utils/i18n';
import useTheme from '../../../../utils/useTheme';

const ViewSaveMessage: FunctionComponent = () => {
  const theme = useTheme();

  return (
    <Typo color={theme.color.text.disabled} fullWidth>
      {i18n`-> Validate the creation of this field then open its details to create and configure views.`}
    </Typo>
  );
};

export default ViewSaveMessage;
