import type { RefCallback } from 'react';
import { useCallback, useRef } from 'react';
import { observe } from 'react-intersection-observer';

const useInViewCallback = <Element extends HTMLElement>(callback: (inView: boolean, node: HTMLElement) => void, showMonitor: boolean): RefCallback<Element> => {
  const callbackRef = useRef<typeof callback>(callback);
  callbackRef.current = callback;

  const unobserveRef = useRef<ReturnType<typeof observe>>();

  return useCallback<RefCallback<Element>>((node) => {
    if (unobserveRef.current !== undefined) {
      unobserveRef.current();
      unobserveRef.current = undefined;
    }

    if (node !== null && showMonitor) {
      unobserveRef.current = observe(node, (isInView) => {
        callbackRef.current(isInView, node);
      });
    }
  }, [showMonitor]);
};

export default useInViewCallback;
