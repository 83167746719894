import type { FunctionComponent } from 'react';
import { useCallback } from 'react';
import Icon, { IconColorVariant, IconName } from '../../components/atoms/Icon';
import Typo from '../../components/atoms/Typo';
import type { CompositeLine } from '../../components/molecules/CompositeField';
import CompositeField, { CompositeFieldCloseReasons, DropdownSectionTitleVariants } from '../../components/molecules/CompositeField';
import SpacingLine from '../../components/molecules/SpacingLine';
import TableInnerCellContainer, { TableInnerCellContainerVariants } from '../../components/molecules/TableInnerCellContainer';
import { buildPadding, Spacing, spacingRem } from '../../theme/spacingDefinition';
import i18n from '../../utils/i18n';
import makeStyles from '../../utils/makeStyles';
import { formatOrUndef } from '../../utils/stringUtils';
import { SizeContextProvider, SizeVariant } from '../../utils/useSizeContext';
import useTheme from '../../utils/useTheme';
import useUsageContext, { UsageVariant } from '../../utils/useUsageContext';
import { getFieldEditionOptionRender } from './fields/fieldEditionOptionsUtils';
import type { FieldEditionOption, FieldEditionSection, FieldEditionSectionGroup } from './fields/FieldEditionOptionType';

const useStyles = makeStyles({
  inputSpacingContainer: {
    width: '45rem',
  },
  iconSpacer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: spacingRem.xs,
  },
  rightActionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}, 'fieldTypeOptions');

interface FieldTypeOptionsProps {
  title: string,
  subTitle: string | undefined,
  icon: IconName | undefined,
  isMultidimensional: boolean,
  isComputing: boolean,
  sections: (FieldEditionSection | FieldEditionSectionGroup)[],
  onSubmit?: () => void,
  onCancel?: () => void,
  isFiltered?: boolean,
}

const FieldTypeOptions: FunctionComponent<FieldTypeOptionsProps> = ({
  title,
  subTitle,
  icon,
  isMultidimensional = false,
  isComputing = false,
  sections,
  onSubmit,
  onCancel,
  isFiltered = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const contextUsageVariant = useUsageContext();

  const closeDropdown = useCallback((reason: CompositeFieldCloseReasons) => {
    if (reason !== CompositeFieldCloseReasons.cancel) {
      onSubmit?.();
    } else {
      onCancel?.();
    }
  }, [onCancel, onSubmit]);

  const titleContent = (
    <SpacingLine>
      {icon ? (<Icon name={icon} />) : null}
      <SizeContextProvider sizeVariant={SizeVariant.small}>
        {isMultidimensional || isComputing ? (
          <span className={classes.iconSpacer}>
            {isMultidimensional ? (<Icon name={IconName.pivot_table_chart} tooltip={i18n`Multidimensional`} colorVariant={IconColorVariant.info} />) : null}
            {isFiltered ? (<Icon name={IconName.filter_list} tooltip={i18n`Display filters applied on field.`} colorVariant={IconColorVariant.info} />) : null}
            {isComputing ? (<Icon name={IconName.fx} tooltip={i18n`Computed`} colorVariant={IconColorVariant.info} />) : null}
          </span>
        ) : null}
      </SizeContextProvider>
      <Typo maxLine={1}>{formatOrUndef(i18n.t(title))}</Typo>
    </SpacingLine>
  );

  if (!sections || !sections.length) {
    if (contextUsageVariant === UsageVariant.inTable) {
      return (
        <TableInnerCellContainer variant={TableInnerCellContainerVariants.centeredFlex} padding={buildPadding({ x: Spacing.splus })}>
          {titleContent}
        </TableInnerCellContainer>
      );
    } else {
      return titleContent;
    }
  }

  const optionsToLines = (options: FieldEditionOption[]) => (
    options.map((option) => ({
      id: option.key,
      title: option.title,
      isVertical: option.isVertical,
      info: option.info,
      render: (() => {
        if (option.title && !option.isVertical && !option.fullWidth) {
          return (
            <div className={classes.inputSpacingContainer}>
              {getFieldEditionOptionRender(option)}
            </div>
          );
        } else {
          return getFieldEditionOptionRender(option);
        }
      })(),
    }))
  );

  return (
    <CompositeField
      getDropdownSectionDefinitions={
        sections ? () => sections.map((section) => ({
          id: section.key,
          title: section.title,
          rightAction: section.type === 'group' ? section.rightActions : undefined,
          lines: (
            section.type === 'section'
              ? optionsToLines(section.options)
              : section.sections.flatMap((subSection): CompositeLine[] => {
                const lines = optionsToLines(subSection.options);
                if (subSection.title) {
                  return [
                    {
                      id: `${subSection.key}_title`,
                      title: subSection.title,
                      titleVariant: DropdownSectionTitleVariants.secondary,
                      info: subSection.info,
                      render: (<span className={classes.rightActionContainer}>{subSection.rightActions}</span>),
                    },
                    ...lines,
                  ];
                } else {
                  return lines;
                }
              })
          ),
        })) : undefined
      }
      maxBodyHeight="45rem"
      onCloseDropdown={closeDropdown}
      headerLinesRenderers={[
        {
          id: 'title',
          render: () => titleContent,
        },
        ...subTitle ? [{
          id: 'subtitle',
          render: () => (
            <SizeContextProvider sizeVariant={SizeVariant.small}>
              <SpacingLine>
                <Typo maxLine={1} color={theme.color.text.disabled}>{subTitle}</Typo>
              </SpacingLine>
            </SizeContextProvider>
          ),
        }] : [],
      ]}
      withCompactLabel
    />
  );
};

export default FieldTypeOptions;
