import classnames from 'classnames';
import type { FunctionComponent } from 'react';
import makeStyles from '../../../utils/makeStyles';

export enum TickVariant {
  black = 'black',
  grey = 'grey',
}

export enum TickDirection {
  left = 'left',
  right = 'right',
  bottom = 'bottom',
  top = 'top',
}

const useStyles = makeStyles((theme) => ({
  tickLine: {
    strokeWidth: 1,
  },
  tickLine_grey: {
    stroke: theme.color.border.default,
  },
  tickLine_black: {
    stroke: theme.color.text.primary,
  },
  tickText: {
    fontSize: '1.1rem',
    pointerEvents: 'none',
  },
  tickText_grey: {
    fill: theme.color.text.secondary,
  },
  tickText_black: {
    fill: theme.color.text.primary,
  },
}), 'tick');

const getTickLineCoordinates = (direction: TickDirection, size: number) => {
  if (direction === TickDirection.left || direction === TickDirection.right) {
    return {
      x1: 0,
      y1: 0,
      x2: direction === TickDirection.left ? -size : size,
      y2: 0,
    };
  } else {
    return {
      x1: 0,
      y1: 0,
      x2: 0,
      y2: direction === TickDirection.top ? -size : size,
    };
  }
};

const getTickGroupTranslation = (direction: TickDirection, initialCoordinate: number, position: number) => {
  if (direction === TickDirection.left || direction === TickDirection.right) {
    return `translate(${initialCoordinate}px, ${position}px)`;
  } else {
    return `translate(${position}px, ${initialCoordinate}px)`;
  }
};

const getTickTextPosition = (direction: TickDirection, size: number, margin: number) => {
  if (direction === TickDirection.left || direction === TickDirection.right) {
    return {
      textAnchor: direction === TickDirection.left ? 'end' : 'start',
      x: direction === TickDirection.left ? -(size + 3 + margin) : size + 3 + margin,
      y: 3,
    };
  } else {
    return {
      textAnchor: 'middle',
      x: 0,
      y: direction === TickDirection.top ? -(size + 3 + margin) : size + 10 + margin,
    };
  }
};

interface TickProps {
  id: string,
  position: number,
  label: string,
  initialCoordinate: number,
  direction: TickDirection,
  size?: number,
  variant?: TickVariant,
  margin?: number,
  ellipsed?: boolean,
}

const Tick: FunctionComponent<TickProps> = ({ id, position, label, initialCoordinate, direction, size = 8, variant = TickVariant.black, margin = 0, ellipsed = false }) => {
  const classes = useStyles();

  const line = getTickLineCoordinates(direction, size);
  const transform = getTickGroupTranslation(direction, initialCoordinate, position);
  const textPosition = getTickTextPosition(direction, size, margin);

  return (
    <g
      key={`group${id}`}
      style={{ transform }}
    >
      <line
        key={`line-${id}`}
        className={classnames(classes.tickLine, classes[`tickLine_${variant}`])}
        x1={line.x1}
        x2={line.x2}
        y1={line.y1}
        y2={line.y2}
      />
      <text
        key={`label-${label}`}
        className={classnames(classes.tickText, classes[`tickText_${variant}`])}
        textAnchor={textPosition.textAnchor}
        x={textPosition.x}
        y={textPosition.y}
      >
        {label.length > 4 && ellipsed ? `${label.slice(0, 3)}…` : label}
      </text>
    </g>
  );
};

export default Tick;
