import { newError } from 'yooi-utils';

interface TypedBroadcastChannel<MessageContent> {
  readonly registerMessageHandler: (handler: (message: MessageContent) => (void | Promise<void>)) => void,
  readonly unregisterMessageHandler: () => void,
  readonly postMessage: (message?: MessageContent) => void,
  readonly close: () => void,
}

export const createBroadcastChannel = <MessageContent = unknown>(broadcastChannel: BroadcastChannel): TypedBroadcastChannel<MessageContent> => ({
  registerMessageHandler: (handler) => {
    if (broadcastChannel.onmessage) {
      throw newError('Broadcast channel message handler is already registered');
    } else {
      // eslint-disable-next-line no-param-reassign
      broadcastChannel.onmessage = ({ data }: MessageEvent<MessageContent>) => handler(data);
    }
  },
  unregisterMessageHandler: () => {
    // eslint-disable-next-line no-param-reassign
    broadcastChannel.onmessage = null;
  },
  postMessage: (message) => broadcastChannel.postMessage(message),
  close: () => broadcastChannel.close(),
});
