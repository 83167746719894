import type { ObjectStoreReadOnly } from 'yooi-store';
import { ConceptDefinition_Prefix, IdFieldAssociation, IdFieldAssociation_NextId, IdFieldAssociation_Role_ConceptDefinition, IdFieldAssociation_Role_Field } from '../../ids';

const getNextFunctionalId = ({ withAssociation }: ObjectStoreReadOnly, conceptDefinitionId: string, fieldId: string): string => (
  withAssociation(IdFieldAssociation)
    .withRole(IdFieldAssociation_Role_Field, fieldId)
    .withRole(IdFieldAssociation_Role_ConceptDefinition, conceptDefinitionId)
    .getObjectOrNull()?.[IdFieldAssociation_NextId] as string | undefined
  ?? '1'
);
type BuildFunctionalIdFormatter = (store: ObjectStoreReadOnly, conceptDefinitionId: string, fieldId: string) => (functionalId: string | undefined) => (string | undefined);
export const buildFunctionalIdFormatter: BuildFunctionalIdFormatter = (store, conceptDefinitionId, fieldId) => {
  const nextFunctionalId = getNextFunctionalId(store, conceptDefinitionId, fieldId);
  const conceptDef = store.getObject(conceptDefinitionId);

  return (functionalId) => {
    const functionalIdLength = (parseInt(nextFunctionalId, 10) - 1).toString().length;
    const formattedId = functionalId ? functionalId.padStart(functionalIdLength, '0') : '?'.padStart(functionalIdLength, '?');
    return `${conceptDef[ConceptDefinition_Prefix] ? `${conceptDef[ConceptDefinition_Prefix]}-` : ''}${formattedId}`;
  };
};
